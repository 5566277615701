import { get, sumBy } from 'lodash'

import { DepreciationType } from 'shared/types/costApproachTypes'

export const getMvsDirectCost = (values: any) => {
  const siteImprovementCosts = getMvsSiteImprovementsCost(values)
  const totalBuildingComponentCost = getMvsBuildingCost(values)

  const totalReplacementCost = totalBuildingComponentCost + siteImprovementCosts
  return totalReplacementCost
}

export const getMvsBuildingCost = (values: any) => {
  const buildingComponents = get(values, 'buildingComponents', [])

  const totalBuildingComponentCost = sumBy(buildingComponents, 'replacementCostNew') || 0
  return totalBuildingComponentCost
}

export const getMvsSiteImprovementsCost = (values: any) => {
  const siteImprovementCostsPerSf = get(values, 'marshallValuationService.siteImprovementsCost', 0)
  const siteArea = get(values, 'siteArea', 0)
  const siteImprovementCosts = siteImprovementCostsPerSf * siteArea
  return siteImprovementCosts
}

export const getMvsTotalReplacementCostNew = (values: any) => {
  const mvsDirectCost = getMvsDirectCost(values)
  const demolitionCosts = get(values, 'subjectBudget.constructionBudget.demolitionCosts', 0)

  const indirectCosts = get(values, 'marshallValuationService.indirectCosts', 0)
  const otherCosts = get(values, 'marshallValuationService.otherCosts', 0)

  const totalReplacementCosts = demolitionCosts + mvsDirectCost + indirectCosts + otherCosts
  return totalReplacementCosts
}

export const getTotalDepreciatedCost = (
  depreciation: DepreciationType,
  totalSiteImprovements: number,
  mvsReplacementCostNew: number
) => {
  const replacementCostDepreciationPercentage = get(
    depreciation,
    'replacementCostDepreciations[0].totalDepreciation',
    0
  )
  const siteCostDepreciationPercentage = get(depreciation, 'siteImprovementDepreciation.totalDepreciation', 0)

  const replacementCostDepreciation = replacementCostDepreciationPercentage * mvsReplacementCostNew
  const totalDepreciatedReplacementCost = mvsReplacementCostNew - replacementCostDepreciation

  const siteCostDepreciation = siteCostDepreciationPercentage * totalSiteImprovements
  const totalDepreciatedSiteCost = totalSiteImprovements - siteCostDepreciation
  const totalDepreciatedCost = totalDepreciatedReplacementCost + totalDepreciatedSiteCost
  return { totalDepreciatedReplacementCost, totalDepreciatedSiteCost, totalDepreciatedCost }
}

export const PAPER_WIDTH = 1300
