import React from 'react'
import { compose } from 'recompose'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import { isEmpty, get } from 'lodash'
import { withStyles } from '@material-ui/core'

import { updateAreFilesUploading } from 'client-shared/redux/actions/areFilesUploading'

import * as Api from '../../../core/api'
import Dropzone from '../Dropzone'
import ImageItem from '../Image/ImageItem'
import { SUPPORTED_IMAGE_FORMATS } from '../../constants/imageCategories'

const styles = theme => ({
  root: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
  },
  emptyRoot: {
    backgroundColor: theme.palette.secondary[50],
  },
})

class ImageUpload extends React.Component {
  static propTypes = {
    previewWidth: PropTypes.number,
    updateAreFilesUploading: PropTypes.func,
    input: PropTypes.shape({
      name: PropTypes.string,
      value: PropTypes.any,
      onChange: PropTypes.func,
      onFocus: PropTypes.func,
      onBlur: PropTypes.func,
    }).isRequired,
  }

  static defaultProps = {
    previewWidth: 800,
  }

  constructor(props) {
    super(props)
    this.dropZoneRef = React.createRef()
  }

  uploadImage = async image => {
    this.props.updateAreFilesUploading({ areFilesUploading: true })

    const response = await Api.uploadImages({ images: [image] })

    const { images } = response
    this.props.input.onChange(images[0])

    this.props.updateAreFilesUploading({ areFilesUploading: false })
    this.props.input.onFocus()
    this.props.input.onBlur()
  }

  onDrop = acceptedImages => {
    if (!isEmpty(acceptedImages)) {
      const [image] = acceptedImages
      this.props.input.onChange({ isLoading: true, cdnUrl: image.preview })
      this.uploadImage(image)
    }
  }

  onRotate = ({ newUrl }) => {
    this.props.input.onChange({ ...this.props.input.value, cdnUrl: newUrl })
    this.props.input.onFocus()
    this.props.input.onBlur()
  }

  onUpload = () => {
    this.dropZoneRef.current.open()
    this.props.input.onFocus()
    this.props.input.onBlur()
  }

  onDelete = () => {
    this.props.input.onChange({ cdnUrl: '' })
    this.props.input.onFocus()
    this.props.input.onBlur()
  }

  render() {
    const { classes, previewWidth } = this.props
    const imageUrl = get(this.props, 'input.value.cdnUrl', '')
    const hasImage = !isEmpty(imageUrl)
    return (
      <div
        className={classnames(classes.root, {
          [classes.emptyRoot]: !hasImage,
        })}
        data-qa={`${this.props.input.name}-image-upload`}
      >
        <Dropzone
          dropZoneRef={this.dropZoneRef}
          multiple={false}
          accept={SUPPORTED_IMAGE_FORMATS}
          style={{ display: !hasImage ? 'flex' : 'none' }}
          onDrop={this.onDrop}
        />
        {hasImage && (
          <ImageItem
            url={get(this.props, 'input.value.cdnUrl')}
            isLoading={get(this.props, 'input.value.isLoading')}
            onDelete={this.onDelete}
            onRotate={this.onRotate}
            onUpload={this.onUpload}
            previewWidth={previewWidth}
          />
        )}
      </div>
    )
  }
}

export default compose(
  connect(null, {
    updateAreFilesUploading,
  }),
  withStyles(styles)
)(ImageUpload)
