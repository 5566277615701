import { GeneratedText, gtLocked, gtTemplate, addConditionals } from '../../../narrative/GeneratedText'
import { simplePluralize } from '../..'

import { mapDTO, mapDataModel } from './mappings'

const generate = values => {
  const gt = new GeneratedText()

  const floorsGt = gtLocked('floors', 'formatNaturalNumber')
  const buildingTypeGt = gtLocked('buildingType', 'formatBuilding')
  const propertyTypeGt = gtLocked('propertyType')
  const residentialUnitCountGt = gtLocked('residentialUnitCount', 'formatNaturalNumber')
  const residentialUnitWord = simplePluralize('unit', values.residentialUnitCount)
  const commercialUnitCountGt = gtLocked('commercialUnitCount', 'formatNaturalNumber')
  const commercialUnitWord = simplePluralize('unit', values.commercialUnitCount)
  const grossBuildingAreaGt = gtLocked('grossBuildingArea', 'formatInt')
  const basisForSFAnalysisWord = gtLocked('basisForSFAnalysis')
  const basisForSFAnalysisValueGt = gtLocked('basisForSFAnalysisValue', 'formatInt')

  if (values.valueAsComplete) {
    gt.t`The subject is currently `
  } else {
    gt.t`The subject is `
  }

  gt.t`a ${floorsGt}-story`
  addConditionals(gt, {
    isMultiStory: [...gtTemplate`, ${buildingTypeGt}`],
  })
  gt.t`, ${propertyTypeGt} building consisting of`

  addConditionals(gt, {
    isMultiFamilyUse: [...gtTemplate` ${residentialUnitCountGt} residential ${residentialUnitWord}`],
    isPropertyMixedUse: [
      ...gtTemplate` ${residentialUnitCountGt} residential ${residentialUnitWord}
        and ${commercialUnitCountGt} commercial ${commercialUnitWord}`,
    ],
    isCommercialUse: [...gtTemplate` ${commercialUnitCountGt} commercial ${commercialUnitWord}`],
  })

  gt.t` with ${grossBuildingAreaGt} square feet of gross building area`

  addConditionals(gt, {
    nonDefaultBasisSelected: [
      ...gtTemplate` and ${basisForSFAnalysisValueGt} square feet of ${basisForSFAnalysisWord}`,
    ],
  })

  gt.t`.`

  return gt.getParts()
}

export default { generate, mapDTO, mapDataModel }
