import React from 'react'
import PropTypes from 'prop-types'

import BaseMaterialSwitch from '@mui/material/Switch'

import { Field } from 'react-final-form'

const SwitchComponent = ({ input: { value, onChange }, ...props }) => {
  return <BaseMaterialSwitch checked={value} onChange={onChange} {...props} />
}

export function Switch({ name, label, ...props }) {
  return <Field name={name} label={label} component={SwitchComponent} {...props} />
}

Switch.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string,
}
