import React from 'react'

import { OnChange } from 'react-final-form-listeners'

import { Paper, Typography } from '@mui/material'

import { CheckboxWithLabel } from 'client-shared/components/Checkbox'

import { getFeatureFlagValue } from '@bowery-valuation/feature-flagger-client'
import { ENABLE_AUTOMATED_INSPECTION_PREFILL } from 'shared/constants/featureFlags'
import AutomationStatus from 'client-shared/components/AutomationStatus'

import { AmenitiesOutdoorSpaces } from '../../../constants'

import BuildingAmenitiesCommercial from './BuildingAmenitiesCommercial'
import BuildingAmenitiesCommon from './BuildingAmenitiesCommon'
import BuildingAmenitiesResidential from './BuildingAmenitiesResidential'

const BuildingAmenities = ({ form }) => {
  const onOutdoorSpace = React.useCallback(
    value => {
      if (value === AmenitiesOutdoorSpaces.NO_OUTDOOR_SPACE) {
        form.change('outdoorSpaceImages', [])
      }
    },
    [form]
  )

  const onBuildingAmenitiesChange = React.useCallback(
    value => {
      if (value) {
        form.change('building', {})
      }
    },
    [form]
  )

  const {
    values: { building, hasNoAmenities, automationMetadata },
  } = form
  const automatedInspectionPrefillFlag = getFeatureFlagValue(ENABLE_AUTOMATED_INSPECTION_PREFILL)
  return (
    <>
      <Paper data-qa="hasNoAmenities-section-tile">
        <Typography variant="h6">Building Amenities</Typography>
        {automatedInspectionPrefillFlag && automationMetadata && (
          <AutomationStatus
            formPaths={['automationMetadata']}
            message="We automated the information below from Mobile Inspection app"
            sx={{ marginTop: '16px', marginBottom: '16px' }}
          />
        )}
        <OnChange name="hasNoAmenities">{onBuildingAmenitiesChange}</OnChange>
        <CheckboxWithLabel name="hasNoAmenities" label="The subject property has no building amenities." />
        {!hasNoAmenities && (
          <>
            <BuildingAmenitiesCommon building={building} />
            <BuildingAmenitiesCommercial building={building} />
            <BuildingAmenitiesResidential building={building} onOutdoorSpace={onOutdoorSpace} />
          </>
        )}
      </Paper>
    </>
  )
}

export default BuildingAmenities
