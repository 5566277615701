import React from 'react'
import PropTypes from 'prop-types'
import { compose, onlyUpdateForKeys } from 'recompose'
import { Grid, Typography } from '@material-ui/core'
import { withStyles, MuiThemeProvider } from '@material-ui/core/styles'
import Close from '@mui/icons-material/Close'
import Add from '@mui/icons-material/Add'

import ImageCarousel from '../../../../../../../shared/components/ImageCarousel'
import AmenitiesList from '../AmenitiesList'
import { formatCurrencyInt } from '../../../../../../../shared/utils/numberFormatters'
import { IconButton } from '../../../../../../../shared/components'
import noImage from '../../../../../../images/no_image_available_stand_in.svg'
import getTheme from '../../../../../../../core/theme'

const styles = theme => ({
  root: {
    padding: 0,
    maxHeight: 180,
    overflow: 'hidden',
  },
  contentContainer: {
    padding: theme.spacing.unit,
    paddingLeft: theme.spacing.unit * 2,
    paddingTop: theme.spacing.unit * 2,
  },
  content: {
    height: '100%',
  },
  address: {
    color: theme.palette.primary[600],
    lineHeight: 1.3,
  },
  description: {
    margin: 0,
    fontWeight: 400,
    lineHeight: 1.4,
  },
  squareButton: {
    borderRadius: 0,
    margin: theme.spacing.unit / 4,
    padding: 4,
  },
  bottomContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'flex-end',
  },
})

class PropertyDetails extends React.PureComponent {
  static propTypes = {
    id: PropTypes.string.isRequired,
    address: PropTypes.string.isRequired,
    units: PropTypes.number.isRequired,
    bedroomCounts: PropTypes.string.isRequired,
    subType: PropTypes.string.isRequired,
    amenities: PropTypes.object.isRequired,
    imageUrl: PropTypes.string,
    isSelected: PropTypes.bool,
    onAddProperty: PropTypes.func.isRequired,
    onRemoveProperty: PropTypes.func.isRequired,
  }

  static defaultProps = {
    imageUrl: noImage,
  }

  onAddProperty = () => {
    this.props.onAddProperty(this.props.id)
  }

  onRemoveProperty = () => {
    this.props.onRemoveProperty(this.props.id, true)
  }

  render() {
    const { address, units, averageRent, bedroomCounts, subType, amenities, images, classes, isSelected } = this.props
    return (
      <MuiThemeProvider theme={getTheme()}>
        <Grid container spacing={0} className={classes.root}>
          <Grid item>
            <ImageCarousel images={images} fallbackUrl={noImage} width={150} height={180} />
          </Grid>
          <Grid item xs className={classes.contentContainer}>
            <Grid
              container
              className={classes.content}
              spacing={0}
              justify="space-between"
              direction="column"
              wrap="nowrap"
            >
              <Grid item>
                <Typography className={classes.address} variant="body1">
                  {address}
                </Typography>
                <Typography variant="subtitle1">
                  <b>
                    Avg Rent {formatCurrencyInt(averageRent || 0)}
                    /month
                  </b>
                </Typography>
              </Grid>
              <Grid item>
                {bedroomCounts && (
                  <Typography variant="body1" className={classes.description}>
                    {bedroomCounts}
                  </Typography>
                )}
                <Typography variant="body1" className={classes.description}>
                  {units} Units
                </Typography>
                {subType && (
                  <Typography variant="body1" className={classes.description}>
                    {subType}
                  </Typography>
                )}
              </Grid>
              <Grid item>
                <div className={classes.bottomContainer}>
                  <AmenitiesList amenities={amenities} maxInRow={6} />
                  {isSelected ? (
                    <IconButton color="error" className={classes.squareButton} onClick={this.onRemoveProperty}>
                      <Close />
                    </IconButton>
                  ) : (
                    <IconButton className={classes.squareButton} onClick={this.onAddProperty}>
                      <Add />
                    </IconButton>
                  )}
                </div>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </MuiThemeProvider>
    )
  }
}

export default compose(onlyUpdateForKeys(['isSelected', 'id']), withStyles(styles))(PropertyDetails)
