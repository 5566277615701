import { RentTypesUnitsOfMeasure } from '../../../../shared/constants/report/incomeApproach'

export const Labels = {
  GROSS: 'Gross',
  MODIFIED_GROSS: 'Modified Gross',
  TRIPLE_NET: 'Triple Net',
  OWNER: 'Owner',
  SHARED: 'Shared',
  TENANT: 'Tenant',
  SIDE_STREET: 'Side Street',
  AVENUE: 'Avenue',
  CORNER: 'Corner',
  MID_BLOCK: 'Mid-Block',
  THROUGH_LOT: 'Through Lot',
  BELOW_GRADE: 'Below Grade',
  GROUND_FLOOR_BASEMENT: 'Ground Floor Basement',
  GROUND_FLOOR: 'Ground Floor',
  UPPER_FLOOR: 'Upper Floor',
  RETAIL: 'Retail',
  OFFICE: 'Office',
  MEDICAL: 'Medical Office',
  COMMUNITY: 'Community Facility',
  UNDETERMINED: 'Undetermined',
  LISTING: 'Listing',
}

export const RentTypesUnitsOfMeasureLabels = {
  MONTHLY: 'Monthly',
  ANNUAL: 'Annually',
  PER_SF_PER_YEAR: 'Per Square Foot',
  PER_SF_PER_MONTH: 'Per Square Foot Per Month',
}

export const LeaseTerms = {
  GROSS: 'gross',
  MODIFIED_GROSS: 'modified gross',
  TRIPLE_NET: 'triple net',
  LISTING: 'listing',
}

export const LEASE_TERMS_LIST = [
  { value: LeaseTerms.GROSS, label: Labels.GROSS },
  { value: LeaseTerms.MODIFIED_GROSS, label: Labels.MODIFIED_GROSS },
  { value: LeaseTerms.TRIPLE_NET, label: Labels.TRIPLE_NET },
  { value: LeaseTerms.LISTING, label: Labels.LISTING },
]

export const OwnerTypes = {
  OWNER: 'owner',
  TENANT: 'tenant',
  SHARED: 'shared',
}

export const OWNER_TYPES_LIST = [
  { value: OwnerTypes.OWNER, label: Labels.OWNER },
  { value: OwnerTypes.TENANT, label: Labels.TENANT },
  { value: OwnerTypes.SHARED, label: Labels.SHARED },
]
export const UnitTypes = {
  RETAIL: 'retail',
  OFFICE: 'office',
  MEDICAL: 'medical',
  COMMUNITY: 'community',
  INDUSTRIAL: 'industrial',
  UNDETERMINED: 'undetermined',
}

export const UnitTypesLabel = {
  retail: 'retail',
  office: 'office',
  medical: 'medical office',
  community: 'community facility',
  industrial: 'industrial',
  undetermined: 'undetermined',
}

export const RENT_TYPES_LIST = [
  { value: RentTypesUnitsOfMeasure.ANNUAL, label: RentTypesUnitsOfMeasureLabels.ANNUAL },
  { value: RentTypesUnitsOfMeasure.MONTHLY, label: RentTypesUnitsOfMeasureLabels.MONTHLY },
  { value: RentTypesUnitsOfMeasure.PER_SF_PER_YEAR, label: RentTypesUnitsOfMeasureLabels.PER_SF_PER_YEAR },
  { value: RentTypesUnitsOfMeasure.PER_SF_PER_MONTH, label: RentTypesUnitsOfMeasureLabels.PER_SF_PER_MONTH },
]

export const StreetTypes = {
  SIDE_STREET: 'side street',
  AVENUE: 'avenue',
}

export const Locations = {
  CORNER: 'corner',
  MID_BLOCK: 'mid-block',
  THROUGH_LOT: 'through lot',
}
