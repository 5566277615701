export const LABEL_FROM_BEDROOMS = ['Studio', '1 BR', '2 BR', '3 BR', '4 BR', '5 BR', '6 BR', '7 BR', '8 BR', '9+ BR']

export const RentTypesUnitsOfMeasure = {
  ANNUAL: 'annually',
  PER_SF_PER_YEAR: 'per square foot per year',
  PER_SF_PER_MONTH: 'per square foot per month',
  MONTHLY: 'monthly',
}

export const rentPSFLabels = {
  MONTHLY: { INAPP: 'Rent PSF/Month', EXPORT: 'Rent PSF/Month' },
  ANNUALLY: { INAPP: 'Rent/SF', EXPORT: 'Rent/SF' },
}

export const TIME_PERIODS = {
  ANNUALLY: 'annually',
  MONTHLY: 'monthly',
}

export const DEFAULT_RENT_PSF_TIME_PERIOD = TIME_PERIODS.ANNUALLY
export const RENT_PSF_TIME_PERIODS = [TIME_PERIODS.ANNUALLY, TIME_PERIODS.MONTHLY]
