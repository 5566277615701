export const INCOME_POTENTIAL_LOOKUP = {
  Significant: {
    Inferior:
      'Overall, the comparables offer inferior opportunities for net income spikes than the subject property, with rents closer to market rates.',
    Similar:
      'The comparables also include a mix of market oriented and below market, rent stabilized and rent controlled units. Overall, the cap rate comparables offer similar opportunities for net income spikes as the subject property, as units are renovated and converted to market rate.',
    Superior:
      'The comparables also include a mix of market oriented and below market, rent stabilized and rent controlled units. Overall, however, the cap rate comparables offer superior opportunities for net income spikes than the subject property, with rents further below market rates than those of the subject.',
  },
  Typical: {
    Inferior:
      'Overall, the comparables offer inferior opportunities for net income spikes than the subject property, with rents closer to market rates.',
    Similar:
      'The comparables also include a mix of market oriented and below market, rent stabilized and rent controlled units. Overall, the cap rate comparables offer similar opportunities for net income spikes as the subject property, as units are renovated and converted to market rate.',
    Superior:
      'The comparables also include a mix of market oriented and below market, rent stabilized and rent controlled units. Overall, however, the cap rate comparables offer superior opportunities for net income spikes than the subject property, with rents further below market than the subject.',
  },
  Moderate: {
    Inferior:
      'Overall, the comparables offer inferior opportunities for net income spikes than the subject property, with rents closer to market rates.',
    Similar:
      'The comparables also include a mix of market oriented and below market, rent stabilized and rent controlled units. Overall, the cap rate comparables offer similar opportunities for net income spikes as the subject property, as units are renovated and converted to market rate.',
    Superior:
      'The comparables also include a mix of market oriented and below market, rent stabilized and rent controlled units. Overall, however, the cap rate comparables offer superior opportunities for net income spikes than the subject property, with rents further below market than the subject.',
  },
  Limited: {
    Inferior:
      'The comparables include primarily market rate units. Overall, the cap rate comparables offer inferior opportunities for net income spikes to the subject property, with rents closer to market rates.',
    Similar:
      'The comparables also include primarily market rate units. Overall, the cap rate comparables offer similar opportunities for net income spikes as the subject property.',
    Superior:
      'The comparables include a mix of market oriented and below market, rent stabilized and rent controlled units. Overall, however, the cap rate comparables offer superior opportunities for net income spikes than the subject property with rents further below market than those of the subject.',
  },
}
