import React from 'react'
import PropTypes from 'prop-types'

import { get, isFinite } from 'lodash'

import { Paper, Stack, Table, TableBody, TableCell, TableHead, TableRow, Typography } from '@mui/material'

import {
  CalloutButton,
  CheckboxGroup,
  CheckboxWithLabel,
  DismissibleCallout,
  GeneratedComment,
  Number,
} from 'client-shared/components'

import { formatCurrencyInt } from 'client-shared/utils/numberFormatters'
import { keyValuePairsToCheckboxValues } from 'client-shared/utils/ui/checkboxHelper'
import { shortDateFormat } from 'client-shared/utils/dateHelper'
import { toPercents } from 'client-shared/utils/numberOperations'

import { REASONS_OF_VALUE_CHANGE_OPTIONS, WARNING_TEXT } from './constants'

import { generateContractOfSaleReconcilliation, generateSalesHistoryReconcilliation } from './writeupHelper'

const reasonsOfValueChange = keyValuePairsToCheckboxValues(REASONS_OF_VALUE_CHANGE_OPTIONS)

class PropertySalesConclusion extends React.PureComponent {
  static defaultProps = {
    finalValue: 0,
  }

  generateContractOfSaleReconcilliationCommentary = () => {
    const {
      form: { values },
    } = this.props

    const isAssignedContract = !!get(values, 'recentValues.assignedContract', false)
    const isPriceConsistentWithAsIs = get(values, 'isConsistentWithAIValueConclusion', false)
    const isAssignmentPriceConsistentWithAsIs = get(values, 'isAssignmentPriceConsistent', true)
    return generateContractOfSaleReconcilliation(
      isAssignedContract,
      isPriceConsistentWithAsIs,
      isAssignmentPriceConsistentWithAsIs
    )
  }

  getChangeInValue = (price, finalValue) => {
    return isFinite(price) && finalValue && !isNaN(parseFloat(finalValue))
      ? `${toPercents((finalValue - price) / price, 2)}%`
      : 'N/A'
  }

  generateSalesHistoryReconcilliationCommentary = () => {
    const {
      form: { values },
      finalValue,
    } = this.props
    const recentSalePrice = get(values, 'recentValues.sale.price', 0)
    const { contract, assignedContract } = get(values, 'recentValues', {})

    return generateSalesHistoryReconcilliation(
      contract,
      assignedContract,
      recentSalePrice,
      finalValue,
      values.reasonsOfValueChange,
      values.numberOfTurnovers,
      this.getChangeInValue
    )
  }

  get recentValues() {
    const {
      form: { values },
      finalValue,
    } = this.props
    const contract = get(values, 'recentValues.contract')
    const assignedContract = get(values, 'recentValues.assignedContract')
    const sale = get(values, 'recentValues.sale')
    const contractPrice = get(contract, 'price')
    const assignedContractPrice = get(assignedContract, 'price')

    const salePrice = get(sale, 'price')
    const changeInSaleValue = this.getChangeInValue(salePrice, finalValue)
    const changeInContractValue = this.getChangeInValue(contractPrice, finalValue)
    const changeInAssignedContractValue = this.getChangeInValue(assignedContractPrice, finalValue)
    return (
      <>
        {!!assignedContract && (
          <TableRow>
            <TableCell>Assigned Contract</TableCell>
            <TableCell>{formatCurrencyInt(assignedContract.price)}</TableCell>
            <TableCell>{shortDateFormat(assignedContract.date)}</TableCell>
            <TableCell>{changeInAssignedContractValue}</TableCell>
          </TableRow>
        )}
        {!!contract && (
          <TableRow>
            <TableCell>Contract</TableCell>
            <TableCell>{formatCurrencyInt(contract.price)}</TableCell>
            <TableCell>{shortDateFormat(contract.date)}</TableCell>
            <TableCell>{changeInContractValue}</TableCell>
          </TableRow>
        )}
        {!!sale && (
          <TableRow>
            <TableCell>Sale</TableCell>
            <TableCell>{formatCurrencyInt(sale.price)}</TableCell>
            <TableCell>{shortDateFormat(sale.date)}</TableCell>
            <TableCell>{changeInSaleValue}</TableCell>
          </TableRow>
        )}
      </>
    )
  }

  render() {
    const {
      form: { values },
    } = this.props

    const isAssignedContract = !!get(values, 'recentValues.assignedContract', false)
    const hasRecentSale = !!get(values, 'recentValues.sale', false)
    const hasSoldWithinThreeYears = values.hasSoldWithinThreeYears
    const currentlyInContract = !!get(values, 'recentValues.contract', false)
    const hasTurnedOverUnits = get(values, 'reasonsOfValueChange.unitTurnover', false)
    const reconcileSalesHistoryLater = values.reconcileSalesHistoryLater

    return (
      <Paper sx={{ maxWidth: 800 }}>
        <Stack spacing={2}>
          <Typography variant="h6">Property Sales Conclusion</Typography>
          <DismissibleCallout
            showInfoOutlined={false}
            text="USPAP requires an analysis of the subject sales, agreements of sales, options, and listings: most notably sales within the last three years."
          />
          {hasSoldWithinThreeYears || (hasRecentSale && reconcileSalesHistoryLater) || currentlyInContract ? (
            <Stack spacing={2}>
              <Typography variant="subtitle1">Recent Values</Typography>
              <Table size="small">
                <TableHead sx={{ '& .MuiTableCell-head': { fontSize: 12, fontWeight: 700 } }}>
                  <TableRow>
                    <TableCell>Value</TableCell>
                    <TableCell>Price</TableCell>
                    <TableCell>Date</TableCell>
                    <TableCell>Change in Value for the Final Value</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>{this.recentValues}</TableBody>
              </Table>
              {hasRecentSale && (
                <Stack>
                  <CheckboxGroup
                    header="How do you explain the change in value?"
                    groupName="reasonsOfValueChange"
                    items={reasonsOfValueChange}
                  />
                  {!!hasTurnedOverUnits && <Number name="numberOfTurnovers" label="Turned Over Units" />}
                  <GeneratedComment
                    title="Generated Commentary"
                    label="Sales History Reconciliation"
                    tooltipText="The following generated text will appear in the Introduction of the report"
                    getGeneratedText={this.generateSalesHistoryReconcilliationCommentary}
                    dataPath="salesHistoryReconcilliation"
                    isDynamicContent
                  />
                </Stack>
              )}
              {currentlyInContract && (
                <Stack>
                  <CheckboxWithLabel
                    name="isConsistentWithAIValueConclusion"
                    label="Contract price is generally consistent with the As Is value conclusion"
                  />
                  {isAssignedContract && (
                    <CheckboxWithLabel
                      name="isAssignmentPriceConsistent"
                      label="Assignment price is generally consistent with the As Is value conclusion"
                    />
                  )}
                  <GeneratedComment
                    title="Generated Commentary"
                    label="Contract of Sale Reconciliation"
                    tooltipText="The following generated text will appear in the Introduction of the report"
                    getGeneratedText={this.generateContractOfSaleReconcilliationCommentary}
                    dataPath="contractOfSaleReconcilliation"
                    isDynamicContent
                  />
                </Stack>
              )}
            </Stack>
          ) : (
            <CalloutButton
              link="./property-history"
              linkText="Change"
              qa="no-recent-sale-or-contract-callout-btn"
              sx={{ mb: 1 }}
              text={WARNING_TEXT}
              variant="warn"
            />
          )}
        </Stack>
      </Paper>
    )
  }
}

PropertySalesConclusion.propTypes = {
  finalValue: PropTypes.number,
}

export default PropertySalesConclusion
