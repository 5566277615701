import React from 'react'
import PropTypes from 'prop-types'

import NarrativeComponent from 'client-shared/components/NarrativeComponent'
import sourceInformation from 'shared/utils/textGeneration/final/sourceInformation'

const TOOLTIP_TEXT = 'The following text will appear in the Introduction of your report.'

const SourceInfoGeneratedText = ({ appraisers, inspectionDate, locationIdentifier, reportTemplate }) => (
  <NarrativeComponent
    data={sourceInformation.mapDTO({ appraisers, inspectionDate, locationIdentifier, reportTemplate })}
    generatedText={sourceInformation.generate}
    name="sourceInfoDiscussion"
    title="Data Sources Description Export"
    tooltipText={TOOLTIP_TEXT}
  />
)

SourceInfoGeneratedText.propTypes = {
  appraisers: PropTypes.arrayOf(PropTypes.object).isRequired,
  inspectionDate: PropTypes.string,
  locationIdentifier: PropTypes.string.isRequired,
  reportTemplate: PropTypes.string,
}

SourceInfoGeneratedText.defaultProps = {
  inspectionDate: '',
}

export default SourceInfoGeneratedText
