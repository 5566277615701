import { isEmpty, isEqual, toLower } from 'lodash'
import arrayToSentence from 'array-to-sentence'

import { UNDER_RENOVATION } from 'shared/constants/report/buildingDescription'
import { GENERAL_CONDITIONS } from 'shared/constants/property/description'
import { SUBJECT_IS_INSPECTED_ITEM, SUBJECT_IS_NOT_INSPECTED_ITEM } from 'shared/constants/report/appraisalScope'

export const getInspectionsTextForAppraisalScope = (
  personallyInspected,
  inspectedLocations = [],
  inspectedUnits = []
) => {
  if (!inspectedLocations.length) {
    if (personallyInspected) {
      return SUBJECT_IS_INSPECTED_ITEM
    }
    return SUBJECT_IS_NOT_INSPECTED_ITEM
  }
  if (inspectedUnits.length) {
    return `Inspected the ${arrayToSentence(
      inspectedLocations.map(toLower)
    )} of the subject property, and the following units we were granted access to: ${arrayToSentence(inspectedUnits)}.`
  }
  return `Inspected the ${arrayToSentence(inspectedLocations.map(toLower))} of the subject property.`
}

export const getRoofAndStairsConditionPhrase = roofCondition => {
  if (roofCondition === UNDER_RENOVATION) {
    return toLower(roofCondition)
  }

  return `in ${toLower(roofCondition)} condition`
}

export const getPropertyConditionSentencePart = generalPropertyCondition => {
  if (!generalPropertyCondition) {
    return ''
  }

  const { value: condition, other: otherCondition } = generalPropertyCondition

  if ([GENERAL_CONDITIONS.vacantLand, GENERAL_CONDITIONS.underRenovation].includes(condition)) {
    return toLower(condition)
  }

  if (condition === GENERAL_CONDITIONS.other) {
    return otherCondition || ''
  }

  return `in ${toLower(condition)} condition`
}

export const formatPropertyCondition = condition => {
  if (isEmpty(condition)) {
    return null
  }

  if (isEqual(condition, { other: null })) {
    return null
  }

  return getPropertyConditionSentencePart(condition)
}
