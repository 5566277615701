import React from 'react'
import PropTypes from 'prop-types'

import { get } from 'lodash'

import {
  selectUnitStats,
  selectUnitCompStats,
  selectBuildingCompStats,
  selectConclusionPerSFAvailability,
} from '../selectors'

import ResidentialRentRollReconciliationItem from './ResidentialRentRollReconciliationItem'

class ResidentialRentRollReconciliationItemContainer extends React.PureComponent {
  static propTypes = {
    useBuildingComps: PropTypes.bool.isRequired,
    buildingComps: PropTypes.array,
    unitCompGroups: PropTypes.array,
    showPerUnitSF: PropTypes.bool,
    fieldNamePrefix: PropTypes.string.isRequired,
    valueConclusionType: PropTypes.string.isRequired,
  }

  static defaultProps = {
    buildingComps: [],
    unitCompGroups: [],
  }

  render() {
    const {
      formValues,
      fieldNamePrefix,
      valueConclusionType,
      useBuildingComps,
      buildingComps,
      unitCompGroups,
      neighborhood,
      showPerUnitSF,
    } = this.props

    const rentReconciliationGroup = get(formValues, fieldNamePrefix)
    const unitRentPSFTimePeriod = get(formValues, 'unitRentPSFTimePeriod')

    const unitStats = selectUnitStats({
      rentReconciliationGroup,
      showDevelopersForecast: false,
      unitRentPSFTimePeriod,
    })
    const developersUnitStats = selectUnitStats({
      rentReconciliationGroup,
      showDevelopersForecast: true,
      unitRentPSFTimePeriod,
    })

    const compStats = useBuildingComps
      ? selectBuildingCompStats({
          rentReconciliationGroup,
          buildingComps,
          unitRentPSFTimePeriod,
        })
      : selectUnitCompStats({
          rentReconciliationGroup,
          unitCompGroups,
          unitRentPSFTimePeriod,
        })

    const conclusionPerSFAvailability = selectConclusionPerSFAvailability({
      rentReconciliationGroup,
      unitCompGroups,
      showPerUnitSF,
    })

    return (
      <ResidentialRentRollReconciliationItem
        formValues={formValues}
        fieldNamePrefix={fieldNamePrefix}
        useBuildingComps={useBuildingComps}
        buildingComps={buildingComps}
        unitCompGroups={unitCompGroups}
        unitStats={unitStats}
        developersUnitStats={developersUnitStats}
        compStats={compStats}
        conclusionPerSFAvailability={conclusionPerSFAvailability}
        neighborhood={neighborhood}
        valueConclusionType={valueConclusionType}
        showMarketOrientationWarning={rentReconciliationGroup.compsChangedAfterMarketBreakdown}
      />
    )
  }
}

export default ResidentialRentRollReconciliationItemContainer
