import React from 'react'
import CsvParser from 'papaparse'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'

import { Typography, Button, Card, CardActions, CardContent, CircularProgress } from '@material-ui/core'

import FileInput from '../../../../../../shared/components/FileInput'

import { parseUnits } from '../tools'
import { CSV_PARSER_CONFIG } from '../constants'

const styles = theme => ({
  csvLoadingSpinnerContainer: {
    position: 'relative',
    width: 40,
    height: 40,
    margin: '0 auto 15px',
  },
  button: {
    marginLeft: 5,
  },
  title: {
    marginBottom: 16,
    fontSize: 14,
    color: theme.palette.text.secondary,
  },
  link: {
    color: theme.palette.text.primary,
    fontWeight: 600,
  },
})

class RentRollCsvImporter extends React.PureComponent {
  static propTypes = {
    classes: PropTypes.object.isRequired,
    onParsingComplete: PropTypes.func.isRequired,
    csvExampleLink: PropTypes.string,
  }

  state = {
    csvFile: null,
    isCSVLoading: false,
  }

  onCSVFileChange = files => {
    this.setState({ csvFile: files[0] })
  }

  startLoading = () => {
    this.setState({ isCSVLoading: true })
  }

  processCSVData = result => {
    const { onParsingComplete } = this.props
    const parsedUnits = parseUnits(result)
    if (parsedUnits) {
      onParsingComplete(parsedUnits)
    }
    this.endLoading()
  }

  endLoading = () => {
    this.setState({ csvFile: null, isCSVLoading: false })
  }

  uploadCSVFile = () => {
    const { csvFile } = this.state

    CsvParser.parse(csvFile, {
      ...CSV_PARSER_CONFIG,
      beforeFirstChunk: this.startLoading,
      complete: this.processCSVData,
      error: this.endLoading,
    })
  }

  render() {
    const { classes, csvExampleLink } = this.props
    const { csvFile, isCSVLoading } = this.state

    const files = csvFile ? [csvFile] : []

    return (
      <Card>
        <CardContent>
          <Typography className={classes.title}>Import Rent Roll via CSV</Typography>
          <Typography component="p">
            Skip manual rent roll entry.
            <a href={csvExampleLink} target="_blank" rel="noopener noreferrer" className={classes.link}>
              Upload a CSV file.
            </a>
            <br />
          </Typography>
          <FileInput files={files} onChange={this.onCSVFileChange} accept=".csv" data-qa="upload-data-control" />
        </CardContent>
        {csvFile && !isCSVLoading && (
          <CardActions>
            <Button
              className={classes.button}
              variant="contained"
              color="primary"
              onClick={this.uploadCSVFile}
              size="small"
              data-qa="import-data-btn"
            >
              Import Data
            </Button>
          </CardActions>
        )}
        {isCSVLoading && (
          <div className={classes.csvLoadingSpinnerContainer}>
            <CircularProgress />
          </div>
        )}
      </Card>
    )
  }
}

export default withStyles(styles)(RentRollCsvImporter)
