import React, { FC } from 'react'
import { Button, Dialog, DialogActions, DialogContent, Stack, Typography } from '@mui/material'

import DialogHeader from '../DialogHeader'

type Props = {
  opened?: boolean
  onSave: () => void
  onClose: () => void
}

export const OverwriteNarrativeModal: FC<Props> = ({ opened = false, onSave, onClose }) => {
  return (
    <Dialog open={opened} onClose={onSave} fullWidth maxWidth="sm" data-qa="overwrite-narrative-modal">
      <DialogHeader onClose={onClose}>Update Template</DialogHeader>

      <DialogContent>
        <Stack direction="column" spacing={1}>
          <Typography variant="body1">
            Loading new content will overwrite unsaved changes because there is modified content in this component.
          </Typography>
        </Stack>
      </DialogContent>

      <DialogActions>
        <Button variant="outlined" onClick={onClose}>
          Cancel
        </Button>
        <Button variant="contained" color="primary" type="submit" onClick={onSave}>
          Overwrite
        </Button>
      </DialogActions>
    </Dialog>
  )
}
