import { DEFAULT_EXPENSES_KEYS } from '../../../../../../shared/constants/expenses'

export const EXPENSE_COMP_KEYS_ORDER = [
  DEFAULT_EXPENSES_KEYS.realEstateTaxes,
  DEFAULT_EXPENSES_KEYS.insurance,
  DEFAULT_EXPENSES_KEYS.electricity,
  DEFAULT_EXPENSES_KEYS.fuel,
  DEFAULT_EXPENSES_KEYS.waterAndSewer,
  DEFAULT_EXPENSES_KEYS.repairsAndMaintenance,
  DEFAULT_EXPENSES_KEYS.payrollAndBenefits,
  DEFAULT_EXPENSES_KEYS.generalAndAdministrative,
  DEFAULT_EXPENSES_KEYS.legalAndProfessionalFees,
  DEFAULT_EXPENSES_KEYS.miscellaneous,
  DEFAULT_EXPENSES_KEYS.management,
  DEFAULT_EXPENSES_KEYS.reserves,
]
