import { Editor, Value } from 'slate'
import { PluginOrPlugins } from 'slate-react'
import { get } from 'lodash'

import { EVENT_HANDLED } from 'client-shared/components/NarrativeComponent/plugins/constants'
import { CHIP_PLUGIN_DATA } from 'client-shared/components/NarrativeComponent/plugins/Locked/ChipPlugin'

export const addTextToValue = (value: Value, text: string): Value => {
  const editor = new Editor({ value })
  editor.moveToEndOfDocument().insertText(text)
  return editor.value
}

export const textToValue = (text: string): Value => {
  return Value.fromJSON({
    document: {
      nodes: [
        {
          object: 'block',
          type: 'paragraph',
          nodes: [
            {
              object: 'text',
              text,
            },
          ],
        },
      ],
    },
  })
}

type getTableCellEditorOptions = {
  stopGridEditing: () => void
}

export const getTableCellEditor = ({ stopGridEditing }: getTableCellEditorOptions) => {
  const TableCellEditor: PluginOrPlugins = {
    onBlur(event, editor, next) {
      // Prevent the ag-grid blur
      event.stopPropagation()

      next()
    },
    onKeyDown(event, editor, next) {
      const editorData = editor.value.data.toJS()
      const inSearchMode = get(editorData, [CHIP_PLUGIN_DATA, 'inSearchMode'], false)

      // Prevent a new line
      if (event.key === 'Enter') {
        if (!inSearchMode) {
          stopGridEditing()
        }
        return EVENT_HANDLED
      }
      next()
    },
  }

  return TableCellEditor
}
