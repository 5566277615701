import { CustomFormApi } from 'final-form'
import { FormRenderProps } from 'react-final-form'

export type ExtendedFormApi<FormValues = any> = Pick<
  FormRenderProps<FormValues>,
  'values' | 'invalid' | 'errors' | 'touched'
> &
  CustomFormApi<FormValues>

export type FormChange = (formPath: string, formValue: any) => void
export type FormBatch = (callBackFunction: () => void) => void

export const getExtendedFormApi = <FormValues = any>({
  values,
  invalid,
  errors,
  touched,
  form,
}: Pick<
  FormRenderProps<FormValues>,
  'values' | 'invalid' | 'errors' | 'touched' | 'form'
>): ExtendedFormApi<FormValues> => {
  return { values, invalid, errors, touched, ...form } as ExtendedFormApi<FormValues>
}
