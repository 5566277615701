import OrganizationInfo from './Information'
import OrganizationUploads from './Uploads'
import Settings from './Settings'
import OrganizationNewUser from './users/CreateNewUser'
import OrganizationUsers from './users/Users'
import InsurableValues from './InsurableValues'

export default {
  organizationInfo: {
    name: 'Organization Info',
    color: '#ae0808',
    formComponent: OrganizationInfo,
  },
  organizationUploads: {
    name: 'Organization Uploads',
    color: '#ae0808',
    formComponent: OrganizationUploads,
  },
  organizationUsers: {
    name: 'Organization Users',
    color: '#ae0808',
    formComponent: OrganizationUsers,
  },
  organizationNewUser: {
    name: 'Create New User',
    color: '#ae0808',
    formComponent: OrganizationNewUser,
    permission: 'user.create',
  },
  settings: {
    name: 'Settings',
    color: '#ae0808',
    formComponent: Settings,
    permission: 'organizationSettings.view',
  },
  insurableValues: {
    name: 'Insurable Values',
    color: '#ae0808',
    formComponent: InsurableValues,
  },
}
