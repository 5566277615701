import React, { FC } from 'react'

import { Field, Form } from 'react-final-form'
import { get, isEmpty } from 'lodash'

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Select,
  MenuItem,
  FormControl,
  Typography,
} from '@mui/material'

import { Close } from '@mui/icons-material'

import {
  ADDITIONAL_EXPENSE_CATEGORIES,
  DEFAULT_EXPENSES_KEYS,
  ELECTRICITY_EXPENSE,
  ELECTRICITY_FUEL_EXPENSE,
  FUEL_EXPENSE,
  GROSS_REVENUE,
  STRICT_EXPENSE_CATEGORIES,
  SUBCATEGORIES_MAP,
  WATER_AND_SEWER_EXPENSE,
} from 'shared/constants/expenses'
import { formatExpenseId } from 'shared/helpers/incomeApproach/expenses/helpers'

import { Auto } from '@ui/Field'

import { required } from 'client-shared/utils/validation'

import { FlattenedExpenseCategory } from '../types'

type EditExpenseSubcategoryDialogProps = {
  existingCategories: FlattenedExpenseCategory[]
  subcategory?:
    | {
        id: string
        name: string
        parentId: string
      }
    | undefined
  onCancel: () => void
  onSave: () => void
}

const ALL_DEFAULT_SUBCATEGORIES = {
  ...ADDITIONAL_EXPENSE_CATEGORIES,
  [DEFAULT_EXPENSES_KEYS.electricity]: ELECTRICITY_EXPENSE.name,
  [DEFAULT_EXPENSES_KEYS.fuel]: FUEL_EXPENSE.name,
  [DEFAULT_EXPENSES_KEYS.waterAndSewer]: WATER_AND_SEWER_EXPENSE.name,
  [ELECTRICITY_FUEL_EXPENSE.key]: ELECTRICITY_FUEL_EXPENSE.name,
}

const validateExpenseName = (
  value: string,
  expenseCategories: FlattenedExpenseCategory[] = [],
  targetExpenseCategory?: FlattenedExpenseCategory
): string | null => {
  const isEmptyValue = required(value)
  if (isEmptyValue) {
    return 'Subcategory name is required'
  }

  // TODO: Everything toLowerCase? - Grossrevenue currently is valid
  const formattedAsId = formatExpenseId(value)
  const allExistingExpenseIds = [...Object.keys(STRICT_EXPENSE_CATEGORIES), ...expenseCategories.map(({ id }) => id)]

  const isAlreadyExists = allExistingExpenseIds.includes(formattedAsId)
  if (isAlreadyExists) {
    return 'Expense subcategory already exists'
  }

  if (
    [ELECTRICITY_EXPENSE.key, FUEL_EXPENSE.key].includes(formattedAsId) &&
    allExistingExpenseIds.filter(id => id !== targetExpenseCategory?.id).some(id => id === ELECTRICITY_FUEL_EXPENSE.key)
  ) {
    return `${ELECTRICITY_FUEL_EXPENSE.name} category already exists`
  }

  if (
    [ELECTRICITY_FUEL_EXPENSE.key].includes(formattedAsId) &&
    allExistingExpenseIds
      .filter(id => id !== targetExpenseCategory?.id)
      .some(id => [ELECTRICITY_EXPENSE.key, FUEL_EXPENSE.key].includes(id))
  ) {
    return `${FUEL_EXPENSE.name} or ${ELECTRICITY_EXPENSE.name} already exist`
  }

  return null
}

const EditExpenseSubcategoryDialog: FC<EditExpenseSubcategoryDialogProps> = ({
  existingCategories = [],
  subcategory,
  onCancel,
  onSave,
}) => {
  const [selectedCategoryId, setSelectedCategoryId] = React.useState(subcategory?.parentId || '')
  const [subcategoryName, setSubcategoryName] = React.useState(subcategory?.name || '')

  const getSubcategoriesSuggestions = React.useCallback(
    () =>
      Object.entries(SUBCATEGORIES_MAP)
        .filter(([key, value]) => value === selectedCategoryId)
        .map(([key]) => ALL_DEFAULT_SUBCATEGORIES[key])
        .filter(Boolean),
    [selectedCategoryId]
  )

  const onCategoryChange = React.useCallback((event, input) => {
    const nextValue = get(event, 'target.value', '')
    input.onChange(nextValue)
    setSelectedCategoryId(nextValue)
  }, [])

  const renderCategoriesMenuItems = React.useCallback(() => {
    return existingCategories
      .filter(({ id, parentId }) => id !== GROSS_REVENUE.key && parentId === undefined)
      .map(({ id, name }) => (
        <MenuItem key={id} value={id}>
          {name}
        </MenuItem>
      ))
  }, [existingCategories])

  const validateOnlyDirty = React.useCallback(
    (value, _values, { dirty }) => dirty && validateExpenseName(value, existingCategories, subcategory),
    [existingCategories, subcategory]
  )
  const parse = React.useCallback(value => value ?? '', [])

  const onChange = React.useCallback(([_event, currentValue, _reason, _details], onChangeField) => {
    const nextValue = get(currentValue, 'value', currentValue || '')
    onChangeField(nextValue)
    setSubcategoryName(nextValue)
  }, [])

  const onInputChange = React.useCallback((event, onChangeField) => {
    const nextValue = get(event, 'target.value', '')
    onChangeField(nextValue)
    setSubcategoryName(nextValue)
  }, [])

  const getOptionDisabled = React.useCallback(
    data => {
      if (data?.value === null) {
        return true
      }
      const formattedExpenseId = formatExpenseId(data)
      const isEditMode = !!subcategory

      if ([ELECTRICITY_EXPENSE.name, FUEL_EXPENSE.name, ELECTRICITY_FUEL_EXPENSE.name].includes(data)) {
        const targetKeys =
          ELECTRICITY_FUEL_EXPENSE.name === data
            ? [ELECTRICITY_EXPENSE.key, FUEL_EXPENSE.key]
            : [ELECTRICITY_FUEL_EXPENSE.key]

        return (
          formattedExpenseId === subcategory?.id ||
          (isEditMode ? existingCategories.filter(({ id }) => id !== subcategory.id) : existingCategories).some(
            ({ id }) => id === formattedExpenseId || targetKeys.includes(id)
          )
        )
      }

      return existingCategories.some(({ id }) => id === formattedExpenseId)
    },
    [existingCategories, subcategory]
  )
  const normalizeStringValue = React.useCallback(value => value?.toLowerCase()?.trim(), [])
  const isOptionEqualToValue = React.useCallback(
    (option, value) => normalizeStringValue(option?.label || option) === normalizeStringValue(value),
    [normalizeStringValue]
  )

  const filterOptions = React.useCallback(
    options => {
      const availableOptions = options.filter((option: string) =>
        normalizeStringValue(option).includes(normalizeStringValue(subcategoryName))
      )
      const canOnlyCreate = isEmpty(availableOptions) && subcategoryName
      const selectedExisted = availableOptions.map(normalizeStringValue).includes(normalizeStringValue(subcategoryName))
      if (selectedExisted) {
        return [
          { value: null, label: `${subcategoryName} selected from existed` },
          { value: null, label: `Enter new category name${!isEmpty(options) ? ' or select category below' : ''}` },
          ...availableOptions,
        ]
      }
      if (canOnlyCreate) {
        return [{ value: subcategoryName, label: `${subcategoryName} will be created` }]
      }
      if (subcategoryName) {
        return [
          { value: subcategoryName, label: `${subcategoryName} will be created` },
          { value: null, label: `...or select category below` },
          ...availableOptions,
        ]
      }
      return [
        { value: null, label: `Enter new category name${!isEmpty(options) ? ' or select category below' : ''}` },
        ...availableOptions,
      ]
    },
    [subcategoryName, normalizeStringValue]
  )

  return (
    <Form
      initialValues={{ categoryId: subcategory?.parentId || '', subcategoryName: subcategory?.name || '' }}
      onSubmit={onSave}
    >
      {({ handleSubmit, invalid, pristine }) => (
        <Dialog fullWidth maxWidth="sm" open onClose={onCancel}>
          <DialogTitle>{`${subcategory ? 'Edit' : 'Add'} Custom Expense Subcategory`}</DialogTitle>
          <Close
            onClick={onCancel}
            sx={{
              position: 'absolute',
              width: 24,
              cursor: 'pointer',
              right: 16,
              top: 18,
              color: '#979797',
            }}
          />
          <DialogContent>
            <Typography variant="body1">Category</Typography>
            <FormControl fullWidth sx={{ mt: '8px', mb: '24px' }} size="small">
              <Field name="categoryId">
                {({ input }) => (
                  <Select
                    data-qa="parent-category-select"
                    value={selectedCategoryId}
                    onChange={event => onCategoryChange(event, input)}
                    MenuProps={{
                      sx: { '& .MuiMenu-paper': { padding: 0 } },
                    }}
                    sx={{
                      '& .MuiSelect-select .notranslate::after': {
                        content: `"Select a category"`,
                        opacity: 0.42,
                      },
                    }}
                  >
                    {renderCategoriesMenuItems()}
                  </Select>
                )}
              </Field>
            </FormControl>

            <Typography variant="body1">Subcategory Name</Typography>
            <Auto.FreeSolo
              data-qa="subcategory-name-input"
              autoSelect
              size="small"
              name="subcategoryName"
              options={getSubcategoriesSuggestions()}
              placeholder="Enter Subcategory Name"
              validate={validateOnlyDirty}
              disabled={!selectedCategoryId}
              sx={{ mb: '8px' }}
              {...{
                initialValue: subcategoryName,
                getOptionDisabled,
                filterOptions,
                inputValue: subcategoryName,
                isOptionEqualToValue,
                onChange,
                onInputChange,
                parse,
                value: subcategoryName,
              }}
            />
          </DialogContent>
          <DialogActions sx={{ padding: '2px 24px 20px 24px' }}>
            <Button onClick={onCancel}>Cancel</Button>
            <Button
              data-qa="submit-changes-btn"
              disabled={pristine || invalid || !subcategoryName}
              onClick={handleSubmit}
              variant="contained"
            >
              {subcategory ? 'Save' : 'Add'}
            </Button>
          </DialogActions>
        </Dialog>
      )}
    </Form>
  )
}

export default EditExpenseSubcategoryDialog
