import React, { FC } from 'react'
import { Button, Menu } from '@mui/material'
import MoreIcon from '@mui/icons-material/MoreVert'

// TODO: make it skinnier
const MoreButton: FC = ({ children }) => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)
  const isOpen = !!anchorEl
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget)
  }
  const handleClose = () => {
    setAnchorEl(null)
  }

  return (
    <>
      <Button
        variant="outlined"
        aria-controls={isOpen ? 'basic-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={isOpen ? 'true' : undefined}
        onClick={handleClick}
      >
        <MoreIcon />
      </Button>
      <Menu anchorEl={anchorEl} open={isOpen} onClose={handleClose} onClick={handleClose}>
        {children}
      </Menu>
    </>
  )
}

export default MoreButton
