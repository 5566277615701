import React from 'react'
import PropTypes from 'prop-types'

import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'

import get from 'lodash/get'

import { EXPORT_MESSAGE_COLUMNS } from './constants'

const getMessageItemCellContent = ({ column, message }) => {
  switch (column.name) {
    case 'templateName':
      return message.documentName
    case 'details':
      return message.message
    default:
      return get(message, column.name)
  }
}

const MessagesTable = ({ messages }) => (
  <Table size="small">
    <TableHead>
      <TableRow>
        {EXPORT_MESSAGE_COLUMNS.map(({ name, title }) => (
          <TableCell key={name}>{title}</TableCell>
        ))}
      </TableRow>
    </TableHead>
    <TableBody>
      {messages.map((message, index) => (
        <TableRow data-qa={`row-${index}`}>
          {EXPORT_MESSAGE_COLUMNS.map(column => (
            <TableCell key={column.name} data-qa={`col-${column.name}`}>
              {getMessageItemCellContent({ column, message })}
            </TableCell>
          ))}
        </TableRow>
      ))}
    </TableBody>
  </Table>
)

MessagesTable.propTypes = {
  messages: PropTypes.arrayOf(
    PropTypes.shape({
      documentName: PropTypes.string,
      message: PropTypes.string,
    })
  ),
}

MessagesTable.defaultProps = {
  messages: [],
}

export default MessagesTable
