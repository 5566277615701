import { get, isEmpty, isNil, keyBy, maxBy, mean, meanBy, minBy } from 'lodash'
import { extractGroupingParameters } from 'shared/helpers/incomeApproach'

import { calculateRentPSFByTimePeriod } from '../../../../../../shared/calculations/rentRoll'
import { TIME_PERIODS } from '../../../../../../shared/constants/report/incomeApproach'

import { RENTROLL_COLUMN_NAMES } from '../../constants'

export const calculateRentPerRoom = (roomCount, rent) => {
  return rent / (roomCount || 1)
}

export const roundToIntOrHalfNumber = value => {
  return Math.round(value * 2) / 2
}

export const calculateRentPerRoomFromUnit = unit => {
  const totalRooms = unit.roomCount ? unit.roomCount : unit.bedroomCount + (unit.bathroomCount || 0)
  return calculateRentPerRoom(totalRooms, unit.rent)
}

export const calculateRentPerSFFromUnit = timePeriod => unit => {
  return calculateRentPSFByTimePeriod(unit.rent, unit.sqft, timePeriod)
}

export const getUnitMixItemWithCalculatedAverages = unitMixItem => ({
  ...unitMixItem,
  avgRentPerRoom: calculateRentPerRoom(unitMixItem.avgRooms, unitMixItem.avgRent) || null,
  avgRentPerSquareFeetPerMonth:
    calculateRentPSFByTimePeriod(unitMixItem.avgRent, unitMixItem.avgSquareFeet, TIME_PERIODS.MONTHLY) || null,
  avgRentPerSquareFeetPerYear:
    calculateRentPSFByTimePeriod(unitMixItem.avgRent, unitMixItem.avgSquareFeet, TIME_PERIODS.ANNUALLY) || null,
})

const getGroupingParameters = (unit, unitGroupingType) => {
  const mappedUnit = {
    bedrooms: unit.bedroomCount,
    unitLayout: unit.unitLayout,
    outdoor: unit.outdoorSpace,
    bathrooms: unit.bathroomCount,
  }
  return extractGroupingParameters(mappedUnit, unitGroupingType)
}

const findMatchingUnits = (rentRollUnits, groupingKey, unitGroupingType) => {
  return rentRollUnits.filter(unit => {
    const { key } = getGroupingParameters(unit, unitGroupingType)
    return key === groupingKey
  })
}

export const rebuildUnitMix = (units, currentUnitMix, unitGroupingType) => {
  const rebuiltUnitMix = []

  for (const unit of units) {
    if (isNil(unit.bedroomCount) || !unit.isIncluded) {
      continue
    }

    const { key, name, parameters } = getGroupingParameters(unit, unitGroupingType)
    const unitMixByItemKey = keyBy(currentUnitMix, 'groupingKey')
    if (!unitMixByItemKey[key]) {
      currentUnitMix.push({
        groupingKey: key,
        groupingName: name,
        groupingParameters: parameters,
      })
    }
  }

  currentUnitMix.forEach(unitMixItem => {
    const includedUnits = units.filter(u => u.isIncluded)
    const matchingUnits = findMatchingUnits(includedUnits, unitMixItem.groupingKey, unitGroupingType)

    if (isEmpty(matchingUnits)) {
      return rebuiltUnitMix.push(getUnitMixItemWithCalculatedAverages(unitMixItem))
    }

    const unitsSquareFeet = matchingUnits.map(unit => (unit.isNotReportedSquareFeet ? 0 : unit.sqft))

    const computedAverage = {
      groupingName: unitMixItem.groupingName,
      groupingKey: unitMixItem.groupingKey,
      groupingParameters: unitMixItem.groupingParameters,
      units: matchingUnits.length || null,
      avgBathrooms: roundToIntOrHalfNumber(meanBy(matchingUnits, RENTROLL_COLUMN_NAMES.BATHROOMS)) || null,
      avgRooms: meanBy(matchingUnits, RENTROLL_COLUMN_NAMES.ROOMS) || null,
      avgRent: meanBy(matchingUnits, RENTROLL_COLUMN_NAMES.RENT) || null,
      avgSquareFeet: mean(unitsSquareFeet) || null,
      minRent: get(minBy(matchingUnits, RENTROLL_COLUMN_NAMES.RENT), 'rent', null),
      maxRent: get(maxBy(matchingUnits, RENTROLL_COLUMN_NAMES.RENT), 'rent', null),
      avgRentPerRoom: mean(matchingUnits.map(calculateRentPerRoomFromUnit)) || null,
      avgRentPerSquareFeetPerMonth: mean(matchingUnits.map(calculateRentPerSFFromUnit(TIME_PERIODS.MONTHLY))) || null,
      avgRentPerSquareFeetPerYear: mean(matchingUnits.map(calculateRentPerSFFromUnit(TIME_PERIODS.ANNUALLY))) || null,
    }

    rebuiltUnitMix.push(computedAverage)
  })

  return rebuiltUnitMix
}
