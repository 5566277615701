import { SALES_APPROACH_TYPES } from '../salesApproach'

export const DATA_COLLECTIONS_KEY = 'dataCollections'
export const DATA_COLLECTIONS_PATH = [DATA_COLLECTIONS_KEY]
export const REPORT_KEY = 'report'
export const REPORT_PATH = [REPORT_KEY]
export const PROPERTY_INFO_KEY = 'propertyInformation'
export const PROPERTY_INFO_PATH = [PROPERTY_INFO_KEY]
export const INCOME_APPROACH_KEY = 'incomeApproach'
export const INCOME_APPROACH_PATH = [INCOME_APPROACH_KEY]
export const SALES_APPROACH_KEY = 'salesApproach'
export const SALES_APPROACH_PATH = [SALES_APPROACH_KEY]
export const COST_APPROACH_KEY = 'costApproach'
export const COST_APPROACH_PATH = [COST_APPROACH_KEY]
export const SUBJECT_BUDGET_KEY = 'subjectBudget'
export const SUBJECT_BUDGET_PATH = [...COST_APPROACH_PATH, SUBJECT_BUDGET_KEY]
export const MVS_KEY = 'marshallValuationService'
export const MVS_PATH = [...COST_APPROACH_PATH, MVS_KEY]
export const CONSTRUCTION_COST_COMPS_KEY = 'constructionCostComps'
export const CONSTRUCTION_COST_COMPS_PATH = [...COST_APPROACH_PATH, CONSTRUCTION_COST_COMPS_KEY]
export const RECONCILIATION_KEY = 'reconciliation'
export const RECONCILIATION_PATH = [...COST_APPROACH_PATH, RECONCILIATION_KEY]
export const FFE_KEY = 'furnitureFixturesAndEquipment'
export const FFE_PATH = [...COST_APPROACH_PATH, FFE_KEY]
export const EI_KEY = 'entrepreneurialIncentive'
export const EI_PATH = [...COST_APPROACH_PATH, EI_KEY]
export const DEPRECIATION_KEY = 'depreciation'
export const DEPRECIATION_PATH = [...COST_APPROACH_PATH, DEPRECIATION_KEY]
export const CONCLUSION_KEY = 'conclusion'
export const CONCLUSION_PATH = [...COST_APPROACH_PATH, CONCLUSION_KEY]
export const FINAL_KEY = 'final'
export const FINAL_PATH = [FINAL_KEY]
export const GENERIC_SUBSECTION_KEY = 'genericSubsection'
export const GENERIC_SUBSECTION_PATH = [GENERIC_SUBSECTION_KEY]
export const PREVIEW_AND_EDIT_KEY = 'previewAndEdit'
export const PREVIEW_AND_EDIT_PATH = [PREVIEW_AND_EDIT_KEY]
export const REVIEW_AND_EXPORT_KEY = 'reviewAndExport'
export const REVIEW_AND_EXPORT_PATH = [REVIEW_AND_EXPORT_KEY]

export const JOB_DETAILS_KEY = 'jobDetails'
export const JOB_DETAILS_PATH = [...DATA_COLLECTIONS_PATH, JOB_DETAILS_KEY]
export const SUBJECT_PROPERTY_KEY = 'subjectProperty'
export const SUBJECT_PROPERTY_PATH = [...DATA_COLLECTIONS_PATH, SUBJECT_PROPERTY_KEY]

export const REPORT_INFORMATION_KEY = 'reportInformation'
export const REPORT_INFORMATION_PATH = [...REPORT_PATH, REPORT_INFORMATION_KEY]
export const APPRAISERS_KEY = 'appraisers'
export const APPRAISERS_PATH = [...REPORT_PATH, APPRAISERS_KEY]
export const CLIENT_KEY = 'client'
export const CLIENT_PATH = [...REPORT_PATH, CLIENT_KEY]

export const PROPERTY_SUMMARY_KEY = 'propertySummary'
export const PROPERTY_SUMMARY_PATH = [...PROPERTY_INFO_PATH, PROPERTY_SUMMARY_KEY]
export const PROPERTY_MARKET_KEY = 'propertyMarket'
export const PROPERTY_MARKET_PATH = [...PROPERTY_INFO_PATH, PROPERTY_MARKET_KEY]
export const PROPERTY_HISTORY_KEY = 'propertyHistory'
export const PROPERTY_HISTORY_PATH = [...PROPERTY_INFO_PATH, PROPERTY_HISTORY_KEY]
export const GENERAL_PROPERTY_DESCRIPTION_KEY = 'generalPropertyDescription'
export const GENERAL_PROPERTY_DESCRIPTION_PATH = [...PROPERTY_INFO_PATH, GENERAL_PROPERTY_DESCRIPTION_KEY]
export const SITE_DESCRIPTION_KEY = 'siteDescription'
export const SITE_DESCRIPTION_PATH = [...PROPERTY_INFO_PATH, SITE_DESCRIPTION_KEY]
export const COMMERCIAL_UNITS_KEY = 'commercialUnits'
export const COMMERCIAL_UNITS_PATH = [...PROPERTY_INFO_PATH, COMMERCIAL_UNITS_KEY]
export const UTILITIES_KEY = 'utilities'
export const UTILITIES_PATH = [...PROPERTY_INFO_PATH, UTILITIES_KEY]
export const AMENITIES_KEY = 'amenities'
export const AMENITIES_PATH = [...PROPERTY_INFO_PATH, AMENITIES_KEY]
export const PROPERTY_MAPS_KEY = 'propertyMaps'
export const PROPERTY_MAPS_PATH = [...PROPERTY_INFO_PATH, PROPERTY_MAPS_KEY]
export const COMPARABLE_MAPS_KEY = 'comparableMaps'
export const COMPARABLE_MAPS_PATH = [...PROPERTY_INFO_PATH, COMPARABLE_MAPS_KEY]
export const PROPERTY_PHOTOS_KEY = 'propertyPhotos'
export const PROPERTY_PHOTOS_PATH = [...PROPERTY_INFO_PATH, PROPERTY_PHOTOS_KEY]
export const ZONING_KEY = 'zoning'
export const ZONING_PATH = [...PROPERTY_INFO_PATH, ZONING_KEY]
export const RENOVATIONS_KEY = 'renovations'
export const RENOVATIONS_PATH = [...PROPERTY_INFO_PATH, RENOVATIONS_KEY]
export const TYPICAL_RESIDENTIAL_CONDITIONS_KEY = 'typicalResidentialConditions'
export const TYPICAL_RESIDENTIAL_CONDITIONS_PATH = [...PROPERTY_INFO_PATH, TYPICAL_RESIDENTIAL_CONDITIONS_KEY]
export const INSURABLE_VALUE_KEY = 'insurableValue'
export const INSURABLE_VALUE_PATH = [...PROPERTY_INFO_PATH, INSURABLE_VALUE_KEY]

export const RESIDENTIAL_INCOME_KEY = 'residentialIncome'
export const RESIDENTIAL_INCOME_PATH = [...INCOME_APPROACH_PATH, RESIDENTIAL_INCOME_KEY]

export const RESIDENTIAL_RENT_ROLL_KEY = 'residentialRentRoll'
export const RESIDENTIAL_RENT_ROLL_PATH = [...RESIDENTIAL_INCOME_PATH, RESIDENTIAL_RENT_ROLL_KEY]
export const RESIDENTIAL_UNIT_GROUPS_KEY = 'residentialUnitGroups'
export const RESIDENTIAL_UNIT_GROUPS_PATH = [...RESIDENTIAL_INCOME_PATH, RESIDENTIAL_UNIT_GROUPS_KEY]
export const RESIDENTIAL_RENT_COMPS_KEY = 'residentialRentComps'
export const RESIDENTIAL_RENT_COMPS_PATH = [...RESIDENTIAL_INCOME_PATH, RESIDENTIAL_RENT_COMPS_KEY]
export const RESIDENTIAL_RENT_COMPS_MAP_KEY = 'residentialRentCompsMap'
export const RESIDENTIAL_RENT_COMPS_MAP_PATH = [...RESIDENTIAL_INCOME_PATH, RESIDENTIAL_RENT_COMPS_MAP_KEY]
export const RESIDENTIAL_BUILDING_RENT_COMPS_KEY = 'residentialBuildingRentCompsMap'
export const RESIDENTIAL_BUILDING_RENT_COMPS_PATH = [...RESIDENTIAL_INCOME_PATH, RESIDENTIAL_BUILDING_RENT_COMPS_KEY]
export const RESIDENTIAL_RENT_RECONCILIATION_KEY = 'residentialRentReconciliation'
export const RESIDENTIAL_RENT_RECONCILIATION_PATH = [...RESIDENTIAL_INCOME_PATH, RESIDENTIAL_RENT_RECONCILIATION_KEY]
export const RESIDENTIAL_PROJECTED_RENT_ROLL_KEY = 'residentialProjectedRentRoll'
export const RESIDENTIAL_PROJECTED_RENT_ROLL_PATH = [...RESIDENTIAL_INCOME_PATH, RESIDENTIAL_PROJECTED_RENT_ROLL_KEY]
export const RESIDENTIAL_PROJECTED_RENT_ROLL_SUMMARY_KEY = 'residentialProjectedRentRollSummary'
export const RESIDENTIAL_PROJECTED_RENT_ROLL_SUMMARY_PATH = [
  ...RESIDENTIAL_INCOME_PATH,
  RESIDENTIAL_PROJECTED_RENT_ROLL_SUMMARY_KEY,
]
export const RESIDENTIAL_EXPENSE_STRUCTURE_KEY = 'residentialExpenseStructure'
export const RESIDENTIAL_EXPENSE_STRUCTURE_PATH = [...RESIDENTIAL_INCOME_PATH, RESIDENTIAL_EXPENSE_STRUCTURE_KEY]

export const COMMERCIAL_INCOME_KEY = 'commercialIncome'
export const COMMERCIAL_INCOME_PATH = [...INCOME_APPROACH_PATH, COMMERCIAL_INCOME_KEY]

export const COMMERCIAL_RENT_ROLL_KEY = 'commercialRentRoll'
export const COMMERCIAL_RENT_ROLL_PATH = [...COMMERCIAL_INCOME_PATH, COMMERCIAL_RENT_ROLL_KEY]
export const COMMERCIAL_STABILIZED_LEASE_STRUCTURE_KEY = 'commercialStabilizedLeaseStructure'
export const COMMERCIAL_STABILIZED_LEASE_STRUCTURE_PATH = [
  ...COMMERCIAL_INCOME_PATH,
  COMMERCIAL_STABILIZED_LEASE_STRUCTURE_KEY,
]
export const COMMERCIAL_COMP_GROUPS_KEY = 'commercialCompGroups'
export const COMMERCIAL_COMP_GROUPS_PATH = [...COMMERCIAL_INCOME_PATH, COMMERCIAL_COMP_GROUPS_KEY]
export const COMMERCIAL_REIMBURSEMENT_SUMMARY_KEY = 'commercialReimbursementSummary'
export const COMMERCIAL_REIMBURSEMENT_SUMMARY_PATH = [...COMMERCIAL_INCOME_PATH, COMMERCIAL_REIMBURSEMENT_SUMMARY_KEY]
export const COMMERCIAL_RENT_COMPS_KEY = 'commercialRentComps'
export const COMMERCIAL_RENT_COMPS_PATH = [...COMMERCIAL_INCOME_PATH, COMMERCIAL_RENT_COMPS_KEY]
export const COMMERCIAL_RENT_COMPS_MAP_KEY = 'commercialRentCompsMap'
export const COMMERCIAL_RENT_COMPS_MAP_PATH = [...COMMERCIAL_INCOME_PATH, COMMERCIAL_RENT_COMPS_MAP_KEY]
export const COMMERCIAL_RENT_RECONCILIATION_KEY = 'commercialRentReconciliation'
export const COMMERCIAL_RENT_RECONCILIATION_PATH = [...COMMERCIAL_INCOME_PATH, COMMERCIAL_RENT_RECONCILIATION_KEY]
export const COMMERCIAL_COMP_GROUPS_DISCUSSION_KEY = 'commercialCompGroupsDiscussion'
export const COMMERCIAL_COMP_GROUPS_DISCUSSION_PATH = [...COMMERCIAL_INCOME_PATH, COMMERCIAL_COMP_GROUPS_DISCUSSION_KEY]
export const COMMERCIAL_PROJECTED_RENT_ROLL_KEY = 'commercialProjectedRentRoll'
export const COMMERCIAL_PROJECTED_RENT_ROLL_PATH = [...COMMERCIAL_INCOME_PATH, COMMERCIAL_PROJECTED_RENT_ROLL_KEY]

export const MISCELLANEOUS_INCOME_KEY = 'miscellaneousIncome'
export const MISCELLANEOUS_INCOME_PATH = [...INCOME_APPROACH_PATH, MISCELLANEOUS_INCOME_KEY]

export const LAUNDRY_INCOME_KEY = 'laundryIncome'
export const LAUNDRY_INCOME_PATH = [...MISCELLANEOUS_INCOME_PATH, LAUNDRY_INCOME_KEY]
export const STORAGE_INCOME_KEY = 'storageIncome'
export const STORAGE_INCOME_PATH = [...MISCELLANEOUS_INCOME_PATH, STORAGE_INCOME_KEY]
export const ANTENNA_INCOME_KEY = 'antennaIncome'
export const ANTENNA_INCOME_PATH = [...MISCELLANEOUS_INCOME_PATH, ANTENNA_INCOME_KEY]
export const PARKING_INCOME_KEY = 'parkingIncome'
export const PARKING_INCOME_PATH = [...MISCELLANEOUS_INCOME_PATH, PARKING_INCOME_KEY]
export const OTHER_INCOME_KEY = 'otherIncome'
export const OTHER_INCOME_PATH = [...MISCELLANEOUS_INCOME_PATH, OTHER_INCOME_KEY]

export const POTENTIAL_GROSS_INCOME_KEY = 'potentialGrossIncome'
export const POTENTIAL_GROSS_INCOME_PATH = [...INCOME_APPROACH_PATH, POTENTIAL_GROSS_INCOME_KEY]
export const TAX_INFORMATION_KEY = 'taxInformation'
export const TAX_INFORMATION_PATH = [...INCOME_APPROACH_PATH, TAX_INFORMATION_KEY]
export const EXPENSE_HISTORY_KEY = 'expenseHistory'
export const EXPENSE_HISTORY_PATH = [...INCOME_APPROACH_PATH, EXPENSE_HISTORY_KEY]
export const COMPARABLE_EXPENSES_KEY = 'comparableExpenses'
export const COMPARABLE_EXPENSES_PATH = [...INCOME_APPROACH_PATH, COMPARABLE_EXPENSES_KEY]
export const EXPENSE_FORECAST_KEY = 'expenseForecast'
export const EXPENSE_FORECAST_PATH = [...INCOME_APPROACH_PATH, EXPENSE_FORECAST_KEY]
export const PRO_FORMA_KEY = 'proForma'
export const PRO_FORMA_PATH = [...INCOME_APPROACH_PATH, PRO_FORMA_KEY]
export const SUPPORTING_CAP_RATES_KEY = 'supportingCapRates'
export const SUPPORTING_CAP_RATES_PATH = [...INCOME_APPROACH_PATH, SUPPORTING_CAP_RATES_KEY]
export const CAP_RATE_CONCLUSION_KEY = 'capRateConclusion'
export const CAP_RATE_CONCLUSION_PATH = [...INCOME_APPROACH_PATH, CAP_RATE_CONCLUSION_KEY]

export const SALES_COMPARISON_APPROACH_KEY = SALES_APPROACH_TYPES.IMPROVED
export const SALES_COMPARISON_APPROACH_PATH = [...SALES_APPROACH_PATH, SALES_COMPARISON_APPROACH_KEY]
export const LAND_VALUATION_KEY = SALES_APPROACH_TYPES.LAND
export const LAND_VALUATION_PATH = [...SALES_APPROACH_PATH, LAND_VALUATION_KEY]

export const SALES_COMPS_SEARCH_KEY = 'salesCompsSearch'
export const SALES_COMPS_MAP_KEY = 'salesCompsMap'
export const SALES_ADJUSTMENT_GRID_KEY = 'salesAdjustmentGrid'
export const SALE_VALUE_CONCLUSION_KEY = 'saleValueConclusion'
export const SALE_CONTRACT_KEY = 'saleContract'
export const SELECTED_COMPS_KEY = 'selectedComps'
export const ADJUSTMENTS_COMPS_KEY = 'compAdjustments'
export const ADJUSTMENTS_TYPE_KEY = 'adjustmentAutomationType'

export const IMPROVED_SALES_COMPS_SEARCH_PATH = [...SALES_COMPARISON_APPROACH_PATH, SALES_COMPS_SEARCH_KEY]
export const IMPROVED_SALES_COMPS_MAP_PATH = [...SALES_COMPARISON_APPROACH_PATH, SALES_COMPS_MAP_KEY]
export const IMPROVED_SALES_ADJUSTMENT_GRID_PATH = [...SALES_COMPARISON_APPROACH_PATH, SALES_ADJUSTMENT_GRID_KEY]
export const IMPROVED_SALE_VALUE_CONCLUSION_PATH = [...SALES_COMPARISON_APPROACH_PATH, SALE_VALUE_CONCLUSION_KEY]
export const IMPROVED_SALE_CONTRACT_PATH = [...SALES_COMPARISON_APPROACH_PATH, SALE_CONTRACT_KEY]
export const IMPROVED_SALES_COMPS_SELECTED_COMPS_PATH = [...IMPROVED_SALES_COMPS_SEARCH_PATH, SELECTED_COMPS_KEY]
export const IMPROVED_SALES_COMP_ADJUSTMENTS_PATH = [...IMPROVED_SALES_ADJUSTMENT_GRID_PATH, ADJUSTMENTS_COMPS_KEY]
export const IMPROVED_SALES_COMP_ADJUSTMENTS_TYPE_PATH = [...IMPROVED_SALES_ADJUSTMENT_GRID_PATH, ADJUSTMENTS_TYPE_KEY]

export const LAND_COMPS_SEARCH_PATH = [...LAND_VALUATION_PATH, SALES_COMPS_SEARCH_KEY]
export const LAND_COMPS_MAP_PATH = [...LAND_VALUATION_PATH, SALES_COMPS_MAP_KEY]
export const LAND_ADJUSTMENT_GRID_PATH = [...LAND_VALUATION_PATH, SALES_ADJUSTMENT_GRID_KEY]
export const LAND_VALUE_CONCLUSION_PATH = [...LAND_VALUATION_PATH, SALE_VALUE_CONCLUSION_KEY]
export const LAND_CONTRACT_PATH = [...LAND_VALUATION_PATH, SALE_CONTRACT_KEY]

export const FINAL_VALUES_AND_RECONCILIATION_KEY = 'finalValuesAndReconciliation'
export const FINAL_VALUES_AND_RECONCILIATION_PATH = [...FINAL_PATH, FINAL_VALUES_AND_RECONCILIATION_KEY]
export const PROPERTY_SALES_CONCLUSION_KEY = 'propertySalesConclusion'
export const PROPERTY_SALES_CONCLUSION_PATH = [...FINAL_PATH, PROPERTY_SALES_CONCLUSION_KEY]
export const ASSUMPTIONS_AND_CONDITIONS_KEY = 'assumptionsConditions'
export const ASSUMPTIONS_AND_CONDITIONS_PATH = [...FINAL_PATH, ASSUMPTIONS_AND_CONDITIONS_KEY]
export const SWOT_ANALYSIS_KEY = 'swotAnalysis'
export const SWOT_ANALYSIS_PATH = [...FINAL_PATH, SWOT_ANALYSIS_KEY]
export const HIGHEST_AND_BEST_USE_KEY = 'highestAndBestUse'
export const HIGHEST_AND_BEST_USE_PATH = [...FINAL_PATH, HIGHEST_AND_BEST_USE_KEY]
export const UNIT_INSPECTION_KEY = 'unitInspection'
export const UNIT_INSPECTION_PATH = [...FINAL_PATH, UNIT_INSPECTION_KEY]
export const APPRAISAL_SCOPE_KEY = 'appraisalScope'
export const APPRAISAL_SCOPE_PATH = [...FINAL_PATH, APPRAISAL_SCOPE_KEY]
export const SOURCE_INFO_KEY = 'sourceInfo'
export const SOURCE_INFO_PATH = [...FINAL_PATH, SOURCE_INFO_KEY]
export const CAP_RATE_COMPS_KEY = 'capRateComps'
export const CAP_RATE_COMPS_PATH = [...FINAL_PATH, CAP_RATE_COMPS_KEY]
export const CAP_RATE_DISCUSSION_KEY = 'capRateDiscussion'
export const CAP_RATE_DISCUSSION_PATH = [...FINAL_PATH, CAP_RATE_DISCUSSION_KEY]

export const COVER_PAGE_KEY = 'coverPage'
export const COVER_PAGE_PATH = [...PREVIEW_AND_EDIT_PATH, COVER_PAGE_KEY]
export const LETTER_OF_TRANSMITTAL_KEY = 'letterOfTransmittal'
export const LETTER_OF_TRANSMITTAL_PATH = [...PREVIEW_AND_EDIT_PATH, LETTER_OF_TRANSMITTAL_KEY]
export const INTRODUCTION_KEY = 'introduction'
export const INTRODUCTION_PATH = [...PREVIEW_AND_EDIT_PATH, INTRODUCTION_KEY]
export const CERTIFICATION_KEY = 'certification'
export const CERTIFICATION_PATH = [...PREVIEW_AND_EDIT_PATH, CERTIFICATION_KEY]
export const GLOSSARY_OF_TERMS_KEY = 'glossaryOfTerms'
export const GLOSSARY_OF_TERMS_PATH = [...PREVIEW_AND_EDIT_PATH, GLOSSARY_OF_TERMS_KEY]
