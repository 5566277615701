import { STATE_NAMES } from '../geography'

export const REPORT_STRUCTURE_TYPES = {
  SECTION: 'section',
  ONE_PAGER_SECTION: 'onePagerSection',
  GROUP: 'group',
  ONE_PAGER_GROUP: 'onePagerGroup',
}

export const CONDITIONS_OPTIONS = {
  1: 'Fair',
  2: 'Average',
  3: 'Satisfactory',
  4: 'Good',
  5: 'Excellent',
}

export const CONDITIONS_LIST = ['Poor', 'Satisfactory', 'Good', 'Excellent']

export const ROOF_AND_STAIRS_CONDITION_OPTIONS = {
  0: 'Unable to inspect the roof',
  1: 'Poor',
  2: 'Fair',
  3: 'Satisfactory',
  4: 'Average',
  5: 'Good',
  6: 'Excellent',
  7: 'Under Renovation',
}

export const TAX_CLASSES = ['2A', '2B', '2C']

export const REPORT_REVIEW_STATES = {
  Draft: 'Draft',
  Review: 'Review',
  Approved: 'Approved',
  Submitted: 'Submitted',
}

export const INTENDED_USES = [
  'loan collateral valuation',
  'loan underwriting',
  'asset management',
  'asset sale purposes',
]

export const OTHER_INTENDED_USE = 'Other'

export const COMP_TYPES = {
  PER_UNIT: 'Per Unit',
  PER_BUILDING: 'Per Building',
}

export const BUILDING_MAP_TYPE = 'building'
export const HEATING_SYSTEMS_LIST = ['Owner Paid', 'Tenant Paid', 'Owner/Tenant Paid']

export const ITEMIZED_CAPITAL_EXPENDITURE = 'Itemized Capital Expenditure'
export const TOTAL_CAPITAL_EXPENDITURE = 'Total Capital Expenditure'
export const UNKNOWN_CAPITAL_EXPENDITURE = 'Unknown capital expenditure'

export const ReportAppraisersRoles = {
  LEAD_APPRAISER: 'leadAppraiser',
  APPRAISER: 'appraiser',
}

export const REPORT_APPRAISER_STATUSES = [ReportAppraisersRoles.LEAD_APPRAISER, ReportAppraisersRoles.APPRAISER]

export const UNCATEGORIZED_UNIT_COMP_GROUP_KEY = {
  name: 'Uncategorized',
  key: 'uncategorized',
}

export const DEFAULT_LEAD_APPRAISER_FIELDS = {
  appraiserRole: ReportAppraisersRoles.LEAD_APPRAISER,
  signReport: true,
}

export const DEFAULT_APPRAISER_FIELDS = {
  appraiserRole: ReportAppraisersRoles.APPRAISER,
  signReport: true,
}

// include leading and trailing spaces to ensure things export correctly
export const LICENSE_NUM_LABEL_BY_STATE = {
  DEFAULT: ' License No. ',
  [STATE_NAMES.FL]: ' Cert Gen ',
}
