import React from 'react'
import { withStyles } from '@mui/styles'

import { Stack } from '@mui/material'
import FormatBoldOutlinedIcon from '@mui/icons-material/FormatBoldOutlined'
import FormatListBulletedOutlinedIcon from '@mui/icons-material/FormatListBulletedOutlined'
import classnames from 'classnames'

import { toolbarStyles } from './styles'

import { FORMATTING_VALUES } from './constants'

const ToolbarButtonUnstyled = ({ children, classes, formatting, active, formatter }) => {
  const handleClick = event => {
    event.preventDefault()
    formatter(formatting)
  }

  return (
    <div onPointerDown={handleClick} className={classnames(classes.toolbarButton, { [classes.toolbarToggle]: active })}>
      {children}
    </div>
  )
}

const ToolbarButton = withStyles(toolbarStyles)(ToolbarButtonUnstyled)

const Toolbar = ({ activeToolbarButtons, formatter, blocker }) => {
  const { bold, unorderedList } = FORMATTING_VALUES
  return (
    <Stack direction="row" spacing={1} alignItems="center" borderBottom="1px lightgray solid">
      <ToolbarButton formatting={bold} active={activeToolbarButtons[bold]} formatter={formatter}>
        <FormatBoldOutlinedIcon />
      </ToolbarButton>
      <ToolbarButton formatting={unorderedList} active={activeToolbarButtons[unorderedList]} formatter={blocker}>
        <FormatListBulletedOutlinedIcon />
      </ToolbarButton>
    </Stack>
  )
}

export default Toolbar
