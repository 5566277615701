import React from 'react'
import PropTypes from 'prop-types'
import { FieldArray } from 'react-final-form-arrays'
import { Paper, Typography, Stack } from '@mui/material'
import { withStyles } from '@material-ui/core'

import { Select } from '@ui/Field'

import { ANCHOR_APPRAISERS } from 'shared/constants/report/dataCollections'

import { arrayToKeyValuePairs } from 'client-shared/utils/ui/checkboxHelper'

import AppraisersTable from '../../reportInformation/Appraisers/AppraisersTableContainer'
import { styles } from '../styles'

const Appraisers = ({ form, fields, classes }) => {
  const { appraisers, isFreddieMac, boweryOffices } = form.values

  return (
    <Paper id={ANCHOR_APPRAISERS} elevation={0} className={classes.noBackground}>
      <Typography variant="h6" className={classes.cardSpacing}>
        Appraisers
      </Typography>
      <Stack spacing={2}>
        <AppraisersTable form={form} fields={fields} appraisers={appraisers} isJobDetails isFreddieMac={isFreddieMac} />
        <Select.Single
          data-qa="primary-office-select"
          name="primaryOffice"
          label="Primary Office"
          options={arrayToKeyValuePairs(boweryOffices)}
          hint="Selection will appear in the LoT header as office address."
          helperTextSx={{ bottom: -18, position: 'absolute', margin: 0 }}
          fullWidth={false}
          className={classes.dropdown}
        />
      </Stack>
    </Paper>
  )
}

Appraisers.propTypes = {
  form: PropTypes.object.isRequired,
  fields: PropTypes.object,
  classes: PropTypes.object.isRequired,
}

const AppraisersContainer = props => <FieldArray name="selectedAppraisers" component={Appraisers} {...props} />

export default withStyles(styles)(AppraisersContainer)
