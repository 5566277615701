import React, { useCallback, useEffect, useState } from 'react'
import { get, cloneDeep } from 'lodash'
import { connect } from 'react-redux'
import { Box, Grid } from '@mui/material'
import PropTypes from 'prop-types'

import { Button } from 'client-shared/components/_mui5'
import { DropDown } from 'client-shared/components'

import {
  createTaxableAssessedValueConfiguration,
  fetchTaxableAssessedValueConfigurations,
} from '../../../../../core/api'
import useFieldValue from '../../../../utils/useFieldValue'
import SaveDialog from '../../../property/ComparableMaps/SaveDialog'

import { DEFAULT_OPTION_VALUE, DEFAULT_OPTION_LABEL } from '../constants'

import LoadTableConfirmDialog from './LoadTableConfirmDialog'
import { validateTaxConfigurationName } from './helpers'

const calculateRowsToAdd = (configuration, existingRows) => {
  const existingRowsClone = cloneDeep(existingRows)
  const rowsToAdd = []

  configuration.rows.forEach(configurationRow => {
    if (existingRowsClone.includes(configurationRow)) {
      /**
       * Scenario: Table contains a record called "Tax Item". User wants to load a configuration that contains
       * 3 items called "Tax Item".
       *
       * This code keeps track of how many rows called "Tax Item" exist in the table already, so that it knows how
       * many it needs to add.
       */
      const existingRowIndex = existingRowsClone.indexOf(configurationRow)
      existingRowsClone.splice(existingRowIndex, 1)

      return
    }

    rowsToAdd.push(configurationRow)
  })

  return rowsToAdd
}

const calculateRowsToRemove = (existingRows, configuration) => {
  return existingRows.filter(configurationRow => !configuration.rows.includes(configurationRow))
}

const TaxItemConfiguration = ({
  assessedValueTable,
  columns,
  addTaxItemRow,
  deleteRow,
  formApi,
  columnsApi,
  hasTransitionalAssessedValue,
  userId,
  reportId,
}) => {
  const selectedConfigurationValue = useFieldValue('taxableAssessedValueConfiguration')

  const [allConfigurations, setAllConfigurations] = useState([])
  const [allConfigurationOptions, setAllConfigurationOptions] = useState([])
  const [showSaveDialog, setShowSaveDialog] = useState(false)
  const [showConfirmDialog, setShowConfirmDialog] = useState(false)

  const refreshTaxableAssessedValueConfigurationOptions = useCallback(
    async (name = DEFAULT_OPTION_LABEL) => {
      const results = await fetchTaxableAssessedValueConfigurations(userId)
      setAllConfigurations(results)

      const userConfigurationOptions = results
        .map(result => {
          return {
            label: result.name,
            value: result._id,
          }
        })
        .reverse()

      const defaultConfigurationOptions = [{ label: DEFAULT_OPTION_LABEL, value: DEFAULT_OPTION_VALUE }]
      const refreshedConfigurationOptions = [...defaultConfigurationOptions, ...userConfigurationOptions]
      setAllConfigurationOptions(refreshedConfigurationOptions)

      const updatedSelectedConfigurationOption = refreshedConfigurationOptions.find(element => element.label === name)

      formApi.change('taxableAssessedValueConfiguration', updatedSelectedConfigurationOption.value)
    },
    [formApi, userId]
  )

  useEffect(() => {
    refreshTaxableAssessedValueConfigurationOptions()
  }, [refreshTaxableAssessedValueConfigurationOptions])

  const updateRowsFromConfiguration = configuration => {
    const existingRows = assessedValueTable.filter(row => row.item !== 'Total').map(row => row.item)

    const rowsToAdd = calculateRowsToAdd(configuration, existingRows)

    rowsToAdd.forEach(row => addTaxItemRow({ item: row }))

    const rowsToRemove = calculateRowsToRemove(existingRows, configuration)

    rowsToRemove.forEach(item => {
      // The row IDs change every time we remove one, so we need to refresh our knowledge of them.
      const currentAssessedValueTable = formApi.getState().values.assessedValueTable

      // There may be multiple rows with the same label, and we would want to remove all of them (the forEach)
      const rowsToRemoveForItem = currentAssessedValueTable.filter(row => row.item === item)
      rowsToRemoveForItem.forEach(row => deleteRow(row.id))
    })
  }

  const updateColumnsFromConfiguration = configuration => {
    const defaultColumnNames = ['item', 'actual', 'transitional']
    const existingColumnNames = columns
      .filter(column => !defaultColumnNames.includes(column.name))
      .map(column => column.name)

    const columnsToAdd = configuration.columns.filter(
      configurationColumnItem => !existingColumnNames.includes(configurationColumnItem.value)
    )

    columnsToAdd.forEach(column => columnsApi.addColumn(column.value))

    const columnNamesToRemove = existingColumnNames.filter(
      existingColumnName => !configuration.columns.map(column => column.value).includes(existingColumnName)
    )

    columnNamesToRemove.forEach(columnName => columnsApi.deleteColumn(columnName))

    configuration.columns.forEach(columnConfiguration => {
      columnsApi.updateColumn(columnConfiguration.value, { label: columnConfiguration.label })
    })
  }

  const loadTaxConfiguration = ({ confirm }) => {
    let selectedConfiguration = undefined

    if (selectedConfigurationValue === DEFAULT_OPTION_VALUE) {
      selectedConfiguration = {
        rows: ['Land', 'Building'],
        columns: [],
        hasTransitionalAssessedValue: false,
      }
    } else {
      selectedConfiguration = allConfigurations.find(
        element => element._id === selectedConfigurationValue
      ).configuration
    }

    const existingRows = assessedValueTable.filter(row => row.item !== 'Total').map(row => row.item)
    const rowsToRemove = calculateRowsToRemove(existingRows, selectedConfiguration)

    if (rowsToRemove.length && !confirm) {
      setShowConfirmDialog(true)
      return
    }

    updateRowsFromConfiguration(selectedConfiguration)
    updateColumnsFromConfiguration(selectedConfiguration)

    formApi.change('hasTransitionalAssessedValue', selectedConfiguration.hasTransitionalAssessedValue)
  }

  const saveTaxConfiguration = ({ label }) => {
    closeSaveDialog()

    const configuration = {
      rows: assessedValueTable.map(row => row.item).filter(row => row !== 'Total'),
      columns: columns.map(column => ({ label: column.label, value: column.name })),
      hasTransitionalAssessedValue,
    }

    const createAndRefreshOptions = async () => {
      await createTaxableAssessedValueConfiguration({
        name: label,
        userId: userId,
        reportId: reportId,
        configuration,
      })

      await refreshTaxableAssessedValueConfigurationOptions(label)
    }

    createAndRefreshOptions()
  }

  const openSaveDialog = () => setShowSaveDialog(true)
  const closeSaveDialog = () => setShowSaveDialog(false)

  const validate = validateTaxConfigurationName(allConfigurationOptions)

  function handleConfirmLoad(confirm) {
    setShowConfirmDialog(false)
    if (confirm) {
      loadTaxConfiguration({ confirm: true })
    }
  }

  return (
    <Grid container spacing={2}>
      <Grid item xs={8}>
        <DropDown size="small" name="taxableAssessedValueConfiguration" items={allConfigurationOptions} />
      </Grid>
      <Grid item xs={4}>
        <Box display="flex" justifyContent="center" alignItems="center" sx={{ paddingTop: '14px' }}>
          <Button onClick={loadTaxConfiguration}>Load</Button>
          &nbsp;&nbsp;
          <Button onClick={openSaveDialog}>Save</Button>
          <SaveDialog
            open={showSaveDialog}
            placeholder="Enter a table name"
            title="Save Taxable Assessed Value Table"
            label="Table Name"
            description="Create a unique name for the table. Name the table in a way that is easily found by you and others."
            saveButtonText="Save Table"
            onSave={saveTaxConfiguration}
            onCancel={closeSaveDialog}
            validate={validate}
          />
          <LoadTableConfirmDialog open={showConfirmDialog} onConfirm={handleConfirmLoad} />
        </Box>
      </Grid>
    </Grid>
  )
}

TaxItemConfiguration.propTypes = {
  assessedValueTable: PropTypes.array.isRequired,
  columns: PropTypes.array.isRequired,
  addTaxItemRow: PropTypes.func.isRequired,
  deleteRow: PropTypes.func.isRequired,
  formApi: PropTypes.object.isRequired,
  columnsApi: PropTypes.object.isRequired,
  hasTransitionalAssessedValue: PropTypes.bool.isRequired,
  userId: PropTypes.string.isRequired,
  reportId: PropTypes.string.isRequired,
}

const mapStateToProps = state => {
  const authentication = get(state, 'authentication')
  const user = get(authentication, 'user', {})

  return { userId: user.id, reportId: get(state, 'report.reportData._id') }
}

export default connect(mapStateToProps)(TaxItemConfiguration)
