import { partition, isFinite, camelCase, cloneDeep, mean, isNumber, sum } from 'lodash'

import {
  SUBCATEGORIES_MAP,
  STRICT_EXPENSE_CATEGORIES,
  DEFAULT_EXPENSES_KEYS,
  EXPENSE_CATEGORIES,
  UTILITIES_EXPENSES_MODE,
  UTILITIES_EXPENSE,
  OTHER_EXPENSE,
  ELECTRICITY_FUEL_EXPENSE,
  WATER_AND_SEWER_EXPENSE,
  GROSS_REVENUE,
} from 'shared/constants/expenses'

type ExpenseCategory = {
  id: string
  name: string
  average?: number | null
}

type FlattenedExpenseCategory = {
  hasSubcategories?: boolean
  parentId?: string
} & ExpenseCategory

type ExpenseValue = {
  id: string
  value?: number | null
  sf?: number | null
  unit?: number | null
  room?: number | null
}

type ExpenseHistoryYear = {
  expenses: ExpenseValue[]
  hiddenExpenses: ExpenseValue[]
  grossRevenue: ExpenseValue
  total: number
  totalExcludingTaxes: number
  noi: number
}

export type StrictExpenseHistory = {
  expenseHistory: ExpenseHistoryYear[]
  expenseCategories: FlattenedExpenseCategory[]
}

export type CustomExpenseHistory = {
  expenseHistory: ExpenseHistoryYear[]
  expenseCategories: ExpenseCategory[]
}

export type UtilitiesMode =
  | typeof UTILITIES_EXPENSES_MODE.BROKEN_OUT
  | typeof UTILITIES_EXPENSES_MODE.COMBINED_ELECTRICITY_AND_FUEL
  | typeof UTILITIES_EXPENSES_MODE.COMBINED_ALL

export type CustomExpenseHistoryModeValues = {
  expenseHistory: CustomExpenseHistory
  utilitiesExpensesMode: UtilitiesMode
}

export const getExpenseValuesForCategory = (categoryId: string, expenseHistory: ExpenseHistoryYear[] = []) => {
  if (!categoryId) {
    return []
  }

  return expenseHistory
    .map(({ expenses }) => ((expenses || []).find(({ id }) => id === categoryId) || {}).value)
    .filter(isNumber)
}

const findSubcategoryIndex = (categories: FlattenedExpenseCategory[], parentId: string): number => {
  let subcategoryIndex: number = -1
  categories.forEach((category, index) => {
    if (category.id === parentId || category.parentId === parentId) {
      subcategoryIndex = index
    }
  })
  return subcategoryIndex
}

const putSubcategoryToMiscellaneous = (subcategory: ExpenseCategory, categories: FlattenedExpenseCategory[]) => {
  const miscellaneousCategoryIndex = categories.findIndex(category => {
    return (
      category.id === DEFAULT_EXPENSES_KEYS.miscellaneous || category.parentId === DEFAULT_EXPENSES_KEYS.miscellaneous
    )
  })
  if (miscellaneousCategoryIndex < 0) {
    categories.push({
      id: DEFAULT_EXPENSES_KEYS.miscellaneous,
      name: EXPENSE_CATEGORIES[DEFAULT_EXPENSES_KEYS.miscellaneous],
    })
  }

  categories.splice(miscellaneousCategoryIndex + 1, 0, {
    ...subcategory,
    parentId: DEFAULT_EXPENSES_KEYS.miscellaneous,
  })
  categories[miscellaneousCategoryIndex].hasSubcategories = true
}

// checks if there are any values for customCategory and, if so, then adds otherSubcategory
const addOtherSubcategory = (
  expenseHistory: ExpenseHistoryYear[],
  categories: FlattenedExpenseCategory[],
  customCategory: ExpenseCategory,
  otherSubcategory: { key: string; name: string }
) => {
  const strictCategoryIndex = categories.findIndex(existedCategory => existedCategory.id === customCategory.id)
  if (!strictCategoryIndex) {
    return
  }

  const categoryExpenseValues = getExpenseValuesForCategory(customCategory.id, expenseHistory)

  if (categoryExpenseValues.some(expense => isFinite(expense))) {
    const otherSubcategoryIndex = findSubcategoryIndex(categories, customCategory.id)
    if (otherSubcategoryIndex >= 0) {
      categories.splice(otherSubcategoryIndex + 1, 0, {
        id: otherSubcategory.key,
        name: otherSubcategory.name,
        average: customCategory.average,
        parentId: customCategory.id,
      })
      categories[strictCategoryIndex].hasSubcategories = true
    }
  }

  expenseHistory.forEach(unit => {
    // take existing values for category
    let categoryExpense = unit.expenses.find(expense => expense.id === customCategory.id)
    // if there is no such, create empty expense
    if (!categoryExpense) {
      categoryExpense = {
        id: customCategory.id,
        value: null,
        sf: null,
        unit: null,
        room: null,
      }
      unit.expenses.push(categoryExpense)
    }
    // push new expense for 'other' subcategory with current values of category
    unit.expenses.push({
      ...categoryExpense,
      id: otherSubcategory.key,
    })
    // set zeros to category as they live in other now
    categoryExpense.value = 0
    categoryExpense.sf = 0
    categoryExpense.unit = 0
    categoryExpense.room = 0
  })
}

const getParentCategory = (subcategory: ExpenseCategory) => {
  if (/^other.*$/.test(subcategory.id)) {
    const parentCategoryKey = camelCase(subcategory.id.replace(/^other/, ''))

    return STRICT_EXPENSE_CATEGORIES[parentCategoryKey] ? parentCategoryKey : null
  }
  return SUBCATEGORIES_MAP[subcategory.id]
}

export const toggleCustomToStrictStructure = (
  customExpenseHistory: CustomExpenseHistory,
  utilitiesMode: UtilitiesMode
): StrictExpenseHistory => {
  // take gross revenue and all other rows separately
  const [[grossRevenueCategory], expenseCategoriesWithoutGrossRevenue] = partition(
    customExpenseHistory.expenseCategories,
    expenseCategory => expenseCategory.id === GROSS_REVENUE.key
  )
  // take all current rows that are not categories in strict mode
  const [, subcategories] = partition(
    expenseCategoriesWithoutGrossRevenue,
    category => !!STRICT_EXPENSE_CATEGORIES[category.id]
  )

  // gross revenue + categories
  const newExpenseCategories: FlattenedExpenseCategory[] = [grossRevenueCategory].concat(
    ...Object.entries(STRICT_EXPENSE_CATEGORIES).map(([id, name]) => ({
      id,
      name,
      hasSubcategories: false,
      isOpen: true,
    }))
  )
  const newExpenseHistory = cloneDeep(customExpenseHistory.expenseHistory)

  subcategories.forEach(subcategory => {
    subcategory.average = mean(getExpenseValuesForCategory(subcategory.id, newExpenseHistory)) || null

    const parentCategoryKey = getParentCategory(subcategory)
    // make a row to be a subcategory of parent category if possible to map or put into miscellaneous
    // rows list is plane, so we need to set parentId, but also a proper index
    if (parentCategoryKey) {
      const parentCategoryIndex = newExpenseCategories.findIndex(category => category.id === parentCategoryKey)
      const subcategoryIndex = findSubcategoryIndex(newExpenseCategories, parentCategoryKey)
      if (parentCategoryIndex >= 0 && subcategoryIndex >= 0) {
        newExpenseCategories.splice(subcategoryIndex + 1, 0, { ...subcategory, parentId: parentCategoryKey })
        newExpenseCategories[parentCategoryIndex].hasSubcategories = true
      } else {
        putSubcategoryToMiscellaneous(subcategory, newExpenseCategories)
      }
    } else {
      putSubcategoryToMiscellaneous(subcategory, newExpenseCategories)
    }
  })

  // add 'other' subcategory if needed
  newExpenseCategories.forEach(defaultCategory => {
    if (!STRICT_EXPENSE_CATEGORIES[defaultCategory.id] || !defaultCategory.hasSubcategories) {
      return
    }

    if (defaultCategory.id === UTILITIES_EXPENSE.key) {
      if (utilitiesMode === UTILITIES_EXPENSES_MODE.COMBINED_ELECTRICITY_AND_FUEL) {
        addOtherSubcategory(newExpenseHistory, newExpenseCategories, defaultCategory, ELECTRICITY_FUEL_EXPENSE)
      }
    } else {
      addOtherSubcategory(newExpenseHistory, newExpenseCategories, defaultCategory, {
        key: camelCase(`${OTHER_EXPENSE.key} ${defaultCategory.id}`),
        name: `${OTHER_EXPENSE.name} ${defaultCategory.name}`,
      })
    }
  })

  newExpenseCategories.forEach(category => {
    newExpenseHistory.forEach(({ expenses }) => {
      const isAlreadyExists = expenses.some(({ id }) => id === category.id)
      if (!isAlreadyExists) {
        expenses.push({ id: category.id })
      }
    })
  })

  // recalculate parent categories averages
  newExpenseCategories.forEach(category => {
    if (category.parentId) {
      return
    }

    category.average =
      (category.hasSubcategories
        ? sum(
            newExpenseCategories
              .filter(({ parentId, average }) => average && isNumber(average) && parentId === category.id)
              .map(({ average }) => average)
          )
        : mean(getExpenseValuesForCategory(category.id, newExpenseHistory))) || null
  })

  const strictExpenseHistory: StrictExpenseHistory = {
    expenseHistory: newExpenseHistory,
    expenseCategories: newExpenseCategories,
  }

  return strictExpenseHistory
}

const toggleWaterAndSewerFromStrictToCustom = (
  waterAndSewerCategory: FlattenedExpenseCategory,
  expenseCategories: ExpenseCategory[],
  expenseHistory: ExpenseHistoryYear[]
): CustomExpenseHistoryModeValues => {
  const newExpenseCategories = [...expenseCategories]
  const newExpenseHistory = cloneDeep(expenseHistory)
  const newUtilitiesExpensesMode = UTILITIES_EXPENSES_MODE.COMBINED_ELECTRICITY_AND_FUEL

  newExpenseCategories.push({ id: UTILITIES_EXPENSE.key, name: UTILITIES_EXPENSE.name, average: null })
  newExpenseCategories.push({
    id: WATER_AND_SEWER_EXPENSE.key,
    name: WATER_AND_SEWER_EXPENSE.name,
    average: waterAndSewerCategory.average,
  })
  newExpenseHistory.forEach(unit => {
    unit.expenses = unit.expenses.map(expense => {
      if (expense.id === UTILITIES_EXPENSE.key) {
        return {
          ...expense,
          value: null,
          sf: null,
          unit: null,
          room: null,
        }
      }
      return expense
    })
    unit.hiddenExpenses = [
      { id: DEFAULT_EXPENSES_KEYS.electricity, value: null, sf: null, unit: null, room: null },
      { id: DEFAULT_EXPENSES_KEYS.fuel, value: null, sf: null, unit: null, room: null },
    ]
  })

  return {
    expenseHistory: {
      expenseCategories: newExpenseCategories,
      expenseHistory: newExpenseHistory,
    },
    utilitiesExpensesMode: newUtilitiesExpensesMode,
  }
}

const toggleElectricityAndFuelFromStrictToCustom = (
  electricityFuelCategory: FlattenedExpenseCategory,
  expenseCategories: ExpenseCategory[],
  expenseHistory: ExpenseHistoryYear[]
): CustomExpenseHistoryModeValues => {
  const newExpenseCategories = [...expenseCategories]
  const newExpenseHistory = cloneDeep(expenseHistory)
  const newUtilitiesExpensesMode = UTILITIES_EXPENSES_MODE.COMBINED_ELECTRICITY_AND_FUEL

  newExpenseCategories.push({
    id: UTILITIES_EXPENSE.key,
    name: UTILITIES_EXPENSE.name,
    average: electricityFuelCategory.average,
  })
  newExpenseCategories.push({
    id: WATER_AND_SEWER_EXPENSE.key,
    name: WATER_AND_SEWER_EXPENSE.name,
    average: null,
  })
  newExpenseHistory.forEach(unit => {
    const updatedExpenses = unit.expenses.filter(expense => expense.id !== ELECTRICITY_FUEL_EXPENSE.key)
    updatedExpenses.push({
      id: WATER_AND_SEWER_EXPENSE.key,
      value: null,
      sf: null,
      unit: null,
      room: null,
    })
    unit.expenses = updatedExpenses
    unit.hiddenExpenses = [
      { id: DEFAULT_EXPENSES_KEYS.electricity, value: null, sf: null, unit: null, room: null },
      { id: DEFAULT_EXPENSES_KEYS.fuel, value: null, sf: null, unit: null, room: null },
    ]
  })

  return {
    expenseHistory: {
      expenseCategories: newExpenseCategories,
      expenseHistory: newExpenseHistory,
    },
    utilitiesExpensesMode: newUtilitiesExpensesMode,
  }
}

const toggleCombinedElectricityAndFuelFromStrictToCustom = (
  electricityFuelCategory: FlattenedExpenseCategory,
  waterAndSewerCategory: FlattenedExpenseCategory,
  expenseCategories: ExpenseCategory[],
  expenseHistory: ExpenseHistoryYear[]
): CustomExpenseHistoryModeValues => {
  const newExpenseCategories = [...expenseCategories]
  const newExpenseHistory = cloneDeep(expenseHistory)
  const newUtilitiesExpensesMode = UTILITIES_EXPENSES_MODE.COMBINED_ELECTRICITY_AND_FUEL

  newExpenseCategories.push({
    id: UTILITIES_EXPENSE.key,
    name: UTILITIES_EXPENSE.name,
    average: electricityFuelCategory.average,
  })
  newExpenseCategories.push({
    id: WATER_AND_SEWER_EXPENSE.key,
    name: WATER_AND_SEWER_EXPENSE.name,
    average: waterAndSewerCategory.average,
  })

  newExpenseHistory.forEach(unit => {
    // eslint-disable-next-line prefer-const
    let [[electricityFuelExpense], updatedExpenses] = partition(
      unit.expenses,
      expense => expense.id === ELECTRICITY_FUEL_EXPENSE.key
    )

    updatedExpenses = updatedExpenses.map(expense => {
      if (expense.id === UTILITIES_EXPENSE.key) {
        return {
          ...expense,
          value: electricityFuelExpense.value,
          sf: electricityFuelExpense.sf,
          unit: electricityFuelExpense.unit,
          room: electricityFuelExpense.room,
        }
      }
      return expense
    })
    unit.expenses = updatedExpenses
    unit.hiddenExpenses = [
      { id: DEFAULT_EXPENSES_KEYS.electricity, value: null, sf: null, unit: null, room: null },
      { id: DEFAULT_EXPENSES_KEYS.fuel, value: null, sf: null, unit: null, room: null },
    ]
  })

  return {
    expenseHistory: {
      expenseCategories: newExpenseCategories,
      expenseHistory: newExpenseHistory,
    },
    utilitiesExpensesMode: newUtilitiesExpensesMode,
  }
}

const toggleCustomSubcategoriesFromStrictToCustom = (
  customSubcategories: FlattenedExpenseCategory[],
  expenseCategories: ExpenseCategory[],
  expenseHistory: ExpenseHistoryYear[]
) => {
  const newExpenseCategories = [...expenseCategories]
  const newExpenseHistory = cloneDeep(expenseHistory)

  newExpenseCategories.push({ id: UTILITIES_EXPENSE.key, name: UTILITIES_EXPENSE.name, average: null })
  newExpenseCategories.push(...customSubcategories.map(({ id, name, average }) => ({ id, name, average })))
  newExpenseHistory.forEach(unit => {
    unit.hiddenExpenses = [
      { id: DEFAULT_EXPENSES_KEYS.electricity, value: null, sf: null, unit: null, room: null },
      { id: DEFAULT_EXPENSES_KEYS.fuel, value: null, sf: null, unit: null, room: null },
      { id: DEFAULT_EXPENSES_KEYS.waterAndSewer, value: null, sf: null, unit: null, room: null },
    ]
  })
  return {
    expenseHistory: {
      expenseCategories: newExpenseCategories,
      expenseHistory: newExpenseHistory,
    },
    utilitiesExpensesMode: UTILITIES_EXPENSES_MODE.COMBINED_ALL,
  }
}

const toggleOnlyUtilitiesFromStrictToCustom = (
  utilities: FlattenedExpenseCategory,
  expenseCategories: ExpenseCategory[],
  expenseHistory: ExpenseHistoryYear[]
) => {
  const newExpenseCategories = [...expenseCategories]
  const newExpenseHistory = cloneDeep(expenseHistory)

  newExpenseCategories.push({ id: utilities.id, name: utilities.name, average: utilities.average })
  newExpenseHistory.forEach(unit => {
    unit.hiddenExpenses = [
      { id: DEFAULT_EXPENSES_KEYS.electricity, value: null, sf: null, unit: null, room: null },
      { id: DEFAULT_EXPENSES_KEYS.fuel, value: null, sf: null, unit: null, room: null },
      { id: DEFAULT_EXPENSES_KEYS.waterAndSewer, value: null, sf: null, unit: null, room: null },
    ]
  })
  return {
    expenseHistory: {
      expenseCategories: newExpenseCategories,
      expenseHistory: newExpenseHistory,
    },
    utilitiesExpensesMode: UTILITIES_EXPENSES_MODE.COMBINED_ALL,
  }
}

const toggleElectricityOrFuelFromStrictToCustom = (
  electricitySubcategory: any,
  fuelSubcategory: any,
  waterAndSewerSubcategory: any,
  customSubcategories: FlattenedExpenseCategory[],
  expenseCategories: ExpenseCategory[],
  expenseHistory: ExpenseHistoryYear[]
) => {
  const newExpenseCategories = [...expenseCategories]
  const newExpenseHistory = cloneDeep(expenseHistory)

  newExpenseCategories.push({
    id: DEFAULT_EXPENSES_KEYS.electricity,
    name: EXPENSE_CATEGORIES[DEFAULT_EXPENSES_KEYS.electricity],
    average: (electricitySubcategory || {}).average || 0,
  })
  newExpenseCategories.push({
    id: DEFAULT_EXPENSES_KEYS.fuel,
    name: EXPENSE_CATEGORIES[DEFAULT_EXPENSES_KEYS.fuel],
    average: (fuelSubcategory || {}).average || 0,
  })
  newExpenseCategories.push({
    id: DEFAULT_EXPENSES_KEYS.waterAndSewer,
    name: EXPENSE_CATEGORIES[DEFAULT_EXPENSES_KEYS.waterAndSewer],
    average: (waterAndSewerSubcategory || {}).average || 0,
  })
  newExpenseCategories.push(...customSubcategories.map(({ id, name, average }) => ({ id, name, average })))

  newExpenseHistory.forEach(unit => {
    const updatedExpenses = unit.expenses.filter(expense => expense.id !== UTILITIES_EXPENSE.key)
    if (!electricitySubcategory) {
      updatedExpenses.push({
        id: DEFAULT_EXPENSES_KEYS.electricity,
        value: null,
        sf: null,
        unit: null,
        room: null,
      })
    }
    if (!fuelSubcategory) {
      updatedExpenses.push({
        id: DEFAULT_EXPENSES_KEYS.fuel,
        value: null,
        sf: null,
        unit: null,
        room: null,
      })
    }
    updatedExpenses.push({
      id: DEFAULT_EXPENSES_KEYS.waterAndSewer,
      value: null,
      sf: null,
      unit: null,
      room: null,
    })
    unit.expenses = updatedExpenses
    unit.hiddenExpenses = [{ id: UTILITIES_EXPENSE.key, value: null, sf: null, unit: null, room: null }]
  })

  return {
    expenseHistory: {
      expenseCategories: newExpenseCategories,
      expenseHistory: newExpenseHistory,
    },
    utilitiesExpensesMode: UTILITIES_EXPENSES_MODE.BROKEN_OUT,
  }
}

const toggleUtilitiesFromStrictToCustom = (
  utilities: FlattenedExpenseCategory,
  subcategories: FlattenedExpenseCategory[],
  expenseCategories: ExpenseCategory[],
  expenseHistory: ExpenseHistoryYear[]
): CustomExpenseHistoryModeValues => {
  const electricitySubcategory = subcategories.find(subcategory => subcategory.id === DEFAULT_EXPENSES_KEYS.electricity)
  const fuelSubcategory = subcategories.find(subcategory => subcategory.id === DEFAULT_EXPENSES_KEYS.fuel)
  const electricityFuelSubcategory = subcategories.find(subcategory => subcategory.id === ELECTRICITY_FUEL_EXPENSE.key)
  const waterAndSewerSubcategory = subcategories.find(subcategory => subcategory.id === WATER_AND_SEWER_EXPENSE.key)
  const customSubcategories = subcategories.filter(
    ({ id }) =>
      id !== DEFAULT_EXPENSES_KEYS.electricity &&
      id !== DEFAULT_EXPENSES_KEYS.fuel &&
      id !== DEFAULT_EXPENSES_KEYS.waterAndSewer &&
      id !== ELECTRICITY_FUEL_EXPENSE.key
  )

  // there is no subcategories, move as it is to 'combinedAll'
  if (!subcategories.length) {
    return toggleOnlyUtilitiesFromStrictToCustom(utilities, expenseCategories, expenseHistory)
  }
  // there is any of electricity or fuel subcategories, then should be 'brokenOut'
  if (electricitySubcategory || fuelSubcategory) {
    return toggleElectricityOrFuelFromStrictToCustom(
      electricitySubcategory,
      fuelSubcategory,
      waterAndSewerSubcategory,
      customSubcategories,
      expenseCategories,
      expenseHistory
    )
  }
  // electricity and fuel are combined and should stay the same in custom mode, so 'combinedElectricityAndFuel'
  if (electricityFuelSubcategory && !waterAndSewerSubcategory) {
    const {
      expenseHistory: { expenseCategories: updateExpenseCategories, expenseHistory: updatedExpenseHistory },
      utilitiesExpensesMode,
    } = toggleElectricityAndFuelFromStrictToCustom(electricityFuelSubcategory, expenseCategories, expenseHistory)

    return {
      expenseHistory: {
        expenseCategories: [
          ...updateExpenseCategories,
          ...customSubcategories.map(({ id, name, average }) => ({ id, name, average })),
        ],
        expenseHistory: updatedExpenseHistory,
      },
      utilitiesExpensesMode,
    }
  }
  // there are no electricity, fuel or electricityAndFuel, only waterAndSewer and/or custom ones,
  // so it's 'combinedElectricityAndFuel', but with 0 values for utilities
  if (!electricityFuelSubcategory && waterAndSewerSubcategory) {
    const {
      expenseHistory: { expenseCategories: updateExpenseCategories, expenseHistory: updatedExpenseHistory },
      utilitiesExpensesMode,
    } = toggleWaterAndSewerFromStrictToCustom(waterAndSewerSubcategory, expenseCategories, expenseHistory)

    return {
      expenseHistory: {
        expenseCategories: [
          ...updateExpenseCategories,
          ...customSubcategories.map(({ id, name, average }) => ({ id, name, average })),
        ],
        expenseHistory: updatedExpenseHistory,
      },
      utilitiesExpensesMode,
    }
  }
  // definitely 'combinedElectricityAndFuel' with both waterAndSewer and utilities values
  if (electricityFuelSubcategory && waterAndSewerSubcategory) {
    const {
      expenseHistory: { expenseCategories: updateExpenseCategories, expenseHistory: updatedExpenseHistory },
      utilitiesExpensesMode,
    } = toggleCombinedElectricityAndFuelFromStrictToCustom(
      electricityFuelSubcategory,
      waterAndSewerSubcategory,
      expenseCategories,
      expenseHistory
    )

    return {
      expenseHistory: {
        expenseCategories: [
          ...updateExpenseCategories,
          ...customSubcategories.map(({ id, name, average }) => ({ id, name, average })),
        ],
        expenseHistory: updatedExpenseHistory,
      },
      utilitiesExpensesMode,
    }
  }

  // we haven't found any standard utilities subcategories so far,
  // then just transfer custom ones and set 'combinedAll' with 0 for utilities
  return toggleCustomSubcategoriesFromStrictToCustom(customSubcategories, expenseCategories, expenseHistory)
}

const toggleNonUtilitiesFromStrictToCustom = (
  category: FlattenedExpenseCategory,
  subcategories: FlattenedExpenseCategory[],
  expenseCategories: ExpenseCategory[],
  expenseHistory: ExpenseHistoryYear[]
): CustomExpenseHistory => {
  const newExpenseCategories = [...expenseCategories]
  const newExpenseHistory = cloneDeep(expenseHistory)

  if (subcategories.length) {
    let hasOtherSubcategory = false
    const newExpenseCategory: ExpenseCategory = { id: category.id, name: category.name, average: null }
    const newExpenseSubcategories: ExpenseCategory[] = []
    subcategories.forEach(subcategory => {
      if (subcategory.id === camelCase(`${OTHER_EXPENSE.key} ${category.id}`)) {
        hasOtherSubcategory = true
        newExpenseCategory.average = subcategory.average
      } else {
        newExpenseSubcategories.push({ id: subcategory.id, name: subcategory.name, average: subcategory.average })
      }
    })
    newExpenseCategories.push(newExpenseCategory)
    newExpenseCategories.push(...newExpenseSubcategories)

    newExpenseHistory.forEach(unit => {
      const updatedExpenses: ExpenseValue[] = []
      let otherSubcategoryExpense: ExpenseValue | undefined
      if (hasOtherSubcategory) {
        otherSubcategoryExpense = unit.expenses.find(
          expense => expense.id === camelCase(`${OTHER_EXPENSE.key} ${category.id}`)
        )
      }
      unit.expenses.forEach(expense => {
        if (expense.id === category.id) {
          updatedExpenses.push({
            ...expense,
            value: hasOtherSubcategory && otherSubcategoryExpense ? otherSubcategoryExpense.value : null,
            sf: hasOtherSubcategory && otherSubcategoryExpense ? otherSubcategoryExpense.sf : null,
            unit: hasOtherSubcategory && otherSubcategoryExpense ? otherSubcategoryExpense.unit : null,
            room: hasOtherSubcategory && otherSubcategoryExpense ? otherSubcategoryExpense.room : null,
          })
          return
        }
        if (expense.id === camelCase(`${OTHER_EXPENSE.key} ${category.id}`)) {
          return
        }
        updatedExpenses.push(expense)
      })
      unit.expenses = updatedExpenses
    })
  } else {
    newExpenseCategories.push({ id: category.id, name: category.name, average: category.average })
  }

  return {
    expenseCategories: newExpenseCategories,
    expenseHistory: newExpenseHistory,
  }
}

export const toggleStrictToCustomStructure = (
  strictExpenseHistory: StrictExpenseHistory
): CustomExpenseHistoryModeValues => {
  let newExpenseCategories: ExpenseCategory[] = []
  const grossRevenue = strictExpenseHistory.expenseCategories.find(category => category.id === GROSS_REVENUE.key)
  if (grossRevenue) {
    newExpenseCategories.push({ id: grossRevenue.id, name: grossRevenue.name, average: grossRevenue.average })
  }
  let newExpenseHistory: ExpenseHistoryYear[] = strictExpenseHistory.expenseHistory
  let newUtilitiesExpensesMode: UtilitiesMode = UTILITIES_EXPENSES_MODE.COMBINED_ALL

  // get all default categories
  Object.keys(STRICT_EXPENSE_CATEGORIES).forEach(categoryId => {
    const category = strictExpenseHistory.expenseCategories.find(expenseCategory => expenseCategory.id === categoryId)
    if (!category) {
      return
    }
    // and its subcategories
    const subcategories = strictExpenseHistory.expenseCategories.filter(
      expenseCategory => expenseCategory.parentId === categoryId
    )
    if (categoryId === UTILITIES_EXPENSE.key) {
      const {
        expenseHistory: { expenseCategories, expenseHistory },
        utilitiesExpensesMode,
      } = toggleUtilitiesFromStrictToCustom(category, subcategories, newExpenseCategories, newExpenseHistory)
      newExpenseCategories = expenseCategories
      newExpenseHistory = expenseHistory
      newUtilitiesExpensesMode = utilitiesExpensesMode
    }
    // if it's not a utilities category, then check subcategories
    // if it has some, then transfer only subcategories and their values
    // otherwise, push category itself
    else {
      const { expenseCategories, expenseHistory } = toggleNonUtilitiesFromStrictToCustom(
        category,
        subcategories,
        newExpenseCategories,
        newExpenseHistory
      )
      newExpenseCategories = expenseCategories
      newExpenseHistory = expenseHistory
    }
  })

  const customExpenseHistoryModeValues: CustomExpenseHistoryModeValues = {
    expenseHistory: {
      expenseHistory: newExpenseHistory,
      expenseCategories: newExpenseCategories,
    },
    utilitiesExpensesMode: newUtilitiesExpensesMode,
  }

  return customExpenseHistoryModeValues
}
