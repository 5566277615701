import { ApolloClient, ApolloLink, gql, InMemoryCache } from '@apollo/client'
import { createUploadLink } from 'apollo-upload-client'
import { unionBy } from 'lodash'

const uri = process.env.REACT_APP_COMPPLEX_GRAPHQL_URI || 'http://localhost:3000/graphql'

// todo: move to a separate file
// todo: turn it into a React hook?
// todo: seriously consider NOT embedding the API URL during build time
const uploadLink = createUploadLink({ uri })

const defaultOptions = {
  watchQuery: {
    fetchPolicy: 'no-cache',
    errorPolicy: 'ignore',
  },
  query: {
    fetchPolicy: 'no-cache',
    errorPolicy: 'all',
  },
}

export const apolloClient = new ApolloClient({
  uri,
  cache: new InMemoryCache({ addTypename: false }),
  link: ApolloLink.from([uploadLink]),
  defaultOptions,
})

export const fullSearchFragment = `
id
version
isLatestVersion
pricePerSF
pricePerUnit
saleDate
status
deletedAt
saleInformation {
  saleStatus
  salePrice
  adjustedSalePrice
  salePriceAdjustmentReason
  salePriceAdjustment
  propertyRights
  seller
  buyer
  uniqueSaleIdentifier
  saleCondition
  netOperatingIncome
  effectiveGrossIncomeMultiplier
  potentialGrossIncome
  potentialGrossIncomeMultiplier
  effectiveGrossIncome
  expenseRatio
  capRate
  propertyRights
}
verificationInformation {
  confirmed
  verified
  contact {
    name
    company
    role
    email
    phoneNumber
  }
  confirmedDocuments {
    fileName
    namespace
    fileId
    createdDate
  }
  verifiedDate
  status
}
commentary {
  internalNotes
  additionalCommentary
  isOverridden
  commentary
}
propertyInformation {
  displayStreetAddress,
  displayCity,
  zoning
  siteArea
  siteAreaUnit
  buildingAmenities
  market
  submarket
  exposure
  tenancy
  parking
  neighborhood
  access
  propertyName
  photo {
    cdnUrl
    isLandscape
  }
  propertyType
  grossBuildingArea
  yearBuilt
  isYearBuiltEstimate
  yearRenovated
  numberOfFloors
  buildingType
  residentialUnits
  commercialUnits
  commercialArea
  condition
  averageUnitSize
  landToBuildingRatio
  occupancy
  buildingClass
  netRentableArea
  unitTypes
  censusTract
}
address {
  coords {
    longitude
    latitude
  }
  streetAddress
  city
  state
  postalCode
  county
  propertyIdentification {
    propertyIdentifierType
    propertyIdentifier
  }
}
resourceInformation {
  sources {
    type
    url
    document {
      fileName
      namespace
      fileId
      createdDate
    }
  }
}`

export const partialSearchFragment = `
  id
  version
  isLatestVersion
  pricePerSF
  pricePerUnit
  saleDate
  status
  deletedAt
  saleInformation {
    saleStatus
    salePrice
    adjustedSalePrice
    salePriceAdjustmentReason
    salePriceAdjustment
    propertyRights
    seller
    buyer
    uniqueSaleIdentifier
    saleCondition
    netOperatingIncome
    effectiveGrossIncomeMultiplier
    potentialGrossIncome
    potentialGrossIncomeMultiplier
    effectiveGrossIncome
    expenseRatio
    capRate
    propertyRights
  }
  verificationInformation {
    confirmed
    verified
    contact {
      name
      company
      role
      email
      phoneNumber
    }
    confirmedDocuments {
      fileName
      namespace
      fileId
      createdDate
    }
    verifiedDate
    status
  }
  commentary {
    internalNotes
    additionalCommentary
    isOverridden
    commentary
  }
  propertyInformation {
    displayStreetAddress
    displayCity
  }
  address {
    coords {
      longitude
      latitude
    }
    streetAddress
    city
    state
    postalCode
    county
    propertyIdentification {
      propertyIdentifierType
      propertyIdentifier
    }
  }
  resourceInformation {
    sources {
      type
      url
      document {
        fileName
        namespace
        fileId
        createdDate
      }
    }
  }
`

export const updateAppraisal = async input => {
  const result = await apolloClient.mutate({
    mutation: gql`
      mutation updateJob($input: UpdateJobInput!) {
        updateJob(input: $input) {
          jobNumber
        }
      }
    `,
    variables: {
      input,
    },
  })

  return result.data.updateJob
}

export const findCompBySaleDateAndAddress = async (input, excludeDeleted) => {
  const result = await apolloClient.query({
    query: gql`
      query findTransactionBySaleDateAndAddress(
        $input: SalesTransactionSearchByDateAddressInput!,
        $excludeDeleted: Boolean,
      ) {
        findTransactionBySaleDateAndAddress(input: $input, excludeDeleted: $excludeDeleted) {
          ${fullSearchFragment}
        }
      }
    `,
    variables: {
      input,
      excludeDeleted,
    },
    fetchPolicy: 'no-cache',
  })

  return result.data?.findTransactionBySaleDateAndAddress
}

export const checkIfLatestSalesCompVersion = async input => {
  const result = await apolloClient.query({
    query: gql`
      query findTransactionByIdAndVersion($input: SalesTransactionSearchByVersionInput!) {
        findTransactionByIdAndVersion(input: $input) {
          isLatestVersion
          deletedAt
        }
      }
    `,
    variables: {
      input,
    },
    fetchPolicy: 'no-cache',
  })

  return result.data?.findTransactionByIdAndVersion || { isLatestVersion: false }
}

export const getCompByIdAndVersion = async input => {
  const result = await apolloClient.query({
    query: gql`
      query findTransactionByIdAndVersion($input: SalesTransactionSearchByVersionInput!) {
        findTransactionByIdAndVersion(input: $input) {
          ${fullSearchFragment}
        }
      }
    `,
    variables: {
      input,
    },
    fetchPolicy: 'no-cache',
  })

  return result.data?.findTransactionByIdAndVersion || { isLatestVersion: false }
}

export const searchByAddresses = async (input, excludeDeleted) => {
  const result = await apolloClient.query({
    query: gql`
      query searchTransactionsByAddresses($input: SalesTransactionSearchByAddressesInput!, $excludeDeleted: Boolean) {
        searchTransactionsByAddresses(input: $input, excludeDeleted: $excludeDeleted) {
          ${partialSearchFragment}
        }
      }
    `,
    variables: {
      input,
      excludeDeleted,
    },
    fetchPolicy: 'no-cache',
  })

  return result.data.searchTransactionsByAddresses
}

const getUniqueSalesComps = (salesCompsFromAddresses, salesCompFromPin) => {
  return unionBy(salesCompsFromAddresses, salesCompFromPin, 'salesTransactionId')
}

export const searchByAddressesAndPin = async (input, excludeDeleted) => {
  const result = await apolloClient.query({
    query: gql`
      query searchTransactionsByAddressesAndPropertyIdentification(
        $addressesInput: SalesTransactionSearchByAddressesInput!,
        $propertyIdentificationInput: SalesTransactionSearchByPropertyIdentificationInput!,
        $excludeDeleted: Boolean
      ) {
        searchTransactionsByAddresses(
          input: $addressesInput,
          excludeDeleted: $excludeDeleted,
        ) {
          ${partialSearchFragment}
        }
         searchTransactionsByPropertyIdentification(
           input: $propertyIdentificationInput,
           excludeDeleted: $excludeDeleted,
         ) {
          ${partialSearchFragment}
        }
      }
    `,
    variables: {
      addressesInput: input.addresses,
      propertyIdentificationInput: input.propertyIdentification,
      excludeDeleted,
    },
    fetchPolicy: 'no-cache',
  })

  return result.data
    ? getUniqueSalesComps(
        result.data.searchTransactionsByAddresses,
        result.data.searchTransactionsByPropertyIdentification
      )
    : []
}
export const propertySearchFragment = `
id
version
isLatestVersion
address {
  coords {
    longitude
    latitude
  }
  streetAddress
  city
  state
  postalCode
  county
  propertyIdentification {
    propertyIdentifierType
    propertyIdentifier
  }
}
propertyInformation {
  displayStreetAddress
  displayCity
  zoning
  siteArea
  siteAreaUnit
  buildingAmenities
  market
  submarket
  exposure
  tenancy
  parking
  neighborhood
  access
  propertyName
  photo {
    cdnUrl
    isLandscape
  }
  propertyType
  grossBuildingArea
  yearBuilt
  isYearBuiltEstimate
  yearRenovated
  numberOfFloors
  buildingType
  residentialUnits
  rentStabilizedUnits
  commercialUnits
  commercialArea
  condition
  averageUnitSize
  landToBuildingRatio
  occupancy
  buildingClass
  netRentableArea
  unitTypes
  censusTract
}
requiredFields
activityLog {
  date
  user
  changeDetails
}
updatedBy
createdBy
`

export const findOrCreateProperty = async input => {
  const result = await apolloClient.mutate({
    mutation: gql`
      mutation findOrCreateProperty($input: PropertyInput!) {
        findOrCreateProperty(input: $input) {
        ${propertySearchFragment}
        }
      }
    `,
    variables: {
      input,
    },
    fetchPolicy: 'no-cache',
  })
  return result.data?.findOrCreateProperty
}

export const checkIfLatestResidentialRentCompVersion = async input => {
  const result = await apolloClient.query({
    query: gql`
      query findResidentialLeaseByIdAndVersion($input: ResidentialLeaseSearchByVersionInput!) {
        findResidentialLeaseByIdAndVersion(input: $input) {
          isLatestVersion
          deletedAt
        }
      }
    `,
    variables: {
      input,
    },
    fetchPolicy: 'no-cache',
  })

  return result.data?.findResidentialLeaseByIdAndVersion || { isLatestVersion: false }
}
