import { get } from 'lodash'

import {
  MASTER_METERS_TYPE,
  ROOF_LOCATION,
  OUTSIDE_LOCATION,
  UNIT_LOCATION,
  OTHER_TYPE,
} from '../../../../constants/report/utilities'

export const mapData = (location, otherLocation, type, inspected, system) => {
  const isOtherLocation = location === OTHER_TYPE

  let systemLocation
  if (location === ROOF_LOCATION) {
    systemLocation = 'on the roof'
  } else if (location === UNIT_LOCATION) {
    systemLocation = 'in the unit'
  } else if (location === OUTSIDE_LOCATION) {
    systemLocation = 'outside'
  } else if (location) {
    systemLocation = `in the ${location}`
  }

  const meterType = type
  const systemType = system
  const meterTense = meterType === MASTER_METERS_TYPE ? 'meter is' : 'meters are'

  const hasNoTypeAndNoLocation = !type && !location

  return {
    inspected,
    system,
    meterType: type,
    location,
    otherLocation,
    isOtherLocation,
    systemLocation,
    systemType,
    meterTense,
    hasNoTypeAndNoLocation,
  }
}

export const mapDTO = formValues => {
  const { location, otherLocation, type, inspected } = formValues.gasMeters

  return mapData(location, otherLocation, type, inspected, 'gas')
}

export const mapDataModel = report => {
  const gasMeters = get(report, 'buildingDescription.utilities.gasMeters', {})
  const { location, otherLocation, type, inspected } = gasMeters

  return mapData(location, otherLocation, type, inspected, 'gas')
}
