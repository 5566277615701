import { Field } from 'react-final-form'
import React from 'react'

import { CheckboxList } from '../../_mui5'

const CheckboxListField = ({ items, name, ...restProps }) => (
  <Field
    name={name}
    {...restProps}
    render={({ input, meta, ...restInnerProps }) => {
      return (
        <CheckboxList
          {...restInnerProps}
          value={input.value}
          onChange={input.onChange}
          onBlur={input.onBlur}
          onFocus={input.onFocus}
          items={items}
        />
      )
    }}
  />
)

export default CheckboxListField
