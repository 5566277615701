import React from 'react'
import PropTypes from 'prop-types'

import { eq } from 'lodash'
import { FieldArray } from 'react-final-form-arrays'

import { Table, TableBody, TableCell, TableHead } from '@mui/material'

import Text from 'client-shared/components/Table/TableText'
import { Number as NumberField } from 'client-shared/components'
import { TableBodyRow, TableHeadRow } from 'client-shared/components/TableHelpers'

import LabelWithTooltip from './LabelWithTooltip'

class ConstantsTable extends React.PureComponent {
  renderCell = ({ column, index }) => {
    const { name } = this.props
    const { name: fieldName, title, isTitle, ...otherColumnProps } = column

    const cellName = `${name}[${index}].${fieldName}`

    if (isTitle) {
      return (
        <TableCell>
          <Text name={cellName} disabled />
        </TableCell>
      )
    }

    return (
      <TableCell>
        <NumberField name={cellName} decimalScale={2} {...otherColumnProps} />
      </TableCell>
    )
  }

  render() {
    const { label, labelTooltip, headerColumns, name } = this.props

    return (
      <>
        <LabelWithTooltip label={label} tooltipText={labelTooltip} />
        <Table size="small">
          <TableHead
            sx={{
              '& .MuiTableCell-head': { fontSize: 12, fontWeight: 700, border: 'none', padding: '10px 16px 4px 0' },
            }}
          >
            <TableHeadRow columns={headerColumns} />
          </TableHead>
          <TableBody
            sx={{
              '& .MuiTableCell-root': {
                border: 'none',
                fontSize: 13,
                height: 'auto',
                margin: 0,
                padding: '4px 16px 4px 0',
              },
            }}
          >
            <FieldArray name={name} isEqual={eq}>
              {({ fields }) =>
                fields.map((field, index) => (
                  <TableBodyRow index={index} key={index} columns={headerColumns} cellComponent={this.renderCell} />
                ))
              }
            </FieldArray>
          </TableBody>
        </Table>
      </>
    )
  }
}

ConstantsTable.propTypes = {
  label: PropTypes.string,
  labelTooltip: PropTypes.string,
  headerColumns: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      title: PropTypes.string,
    })
  ).isRequired,
  name: PropTypes.string.isRequired,
}

ConstantsTable.defaultProps = {
  label: 'Local Multipliers',
  labelTooltip: 'Latest values can be found in Section 99, Page 6 of Marshal & Swift',
}

export default ConstantsTable
