import React, { FC, memo, useMemo } from 'react'

import { get, noop } from 'lodash'
import { ColumnDataTypeEnum, RowBasedTable } from '@bowery-valuation/ui-components'

import { formatCurrencyFloat, formatCurrencyInt, formatInt } from 'client-shared/utils/numberFormatters'

import { CELL_EDITOR_SELECTORS } from 'report/forms/income/TaxInformation/constants'

import { getCustomColumnConfig, RowBasedTableColumn } from 'client-shared/utils/rowBasedTable'
import { fromCurrency } from 'client-shared/utils/numberOperations'
import { FormChange } from 'client-shared/utils/form'
import { MarshallValuationServiceType } from 'shared/types/costApproachTypes'

type SiteImprovementsTableOwnProps = {
  change: FormChange
  marshallValuationService: MarshallValuationServiceType
  siteArea: number
}

const SiteImprovementsTable: FC<SiteImprovementsTableOwnProps> = ({ marshallValuationService, change, siteArea }) => {
  const siteImprovementsCost = get(marshallValuationService, 'siteImprovementsCost', 0)

  const getColumns = (): RowBasedTableColumn[] => {
    return [
      {
        name: 'label',
        label: 'Item',
        type: ColumnDataTypeEnum.text,
        permanent: true,
        align: 'left',
        editable: false,
        suppressMovable: true,
      },
      {
        name: 'siteArea',
        label: 'Site Area',
        type: ColumnDataTypeEnum.text,
        permanent: true,
        align: 'right',
        editable: false,
        suppressMovable: true,
      },
      {
        name: 'costPerSf',
        label: 'Cost / SF',
        type: ColumnDataTypeEnum.text,
        permanent: true,
        align: 'right',
        editable: params => {
          return params.data.id === 'siteImprovements'
        },
        cellEditorSelector: () => {
          return CELL_EDITOR_SELECTORS.moneyTwoDecimals
        },
        suppressMovable: true,
      },

      {
        name: 'total',
        label: 'Total',
        type: ColumnDataTypeEnum.text,
        permanent: true,
        align: 'right',
        editable: false,
        cellEditorSelector: () => {
          return CELL_EDITOR_SELECTORS.moneyTwoDecimals
        },
        suppressMovable: true,
      },
    ]
  }
  const rows = [
    {
      readOnly: false,
      suppressMovable: true,
      permanent: true,
      type: ColumnDataTypeEnum.text,
      id: 'siteImprovements',
      label: 'Site Improvements',
      siteArea: formatInt(siteArea),
      costPerSf: formatCurrencyFloat(siteImprovementsCost),
      total: null,
      rowDef: { summary: false, hideAction: true },
    },
    {
      readOnly: true,
      suppressMovable: true,
      permanent: true,
      type: ColumnDataTypeEnum.text,
      id: 'total',
      label: 'Total Site Improvements',
      siteArea: null,
      costPerSf: null,
      total: formatCurrencyInt(siteArea * siteImprovementsCost),
      rowDef: { summary: true, hideAction: true },
    },
  ]

  const handleRowUpdate = (row: any) => {
    if (row.id === 'siteImprovements') {
      change('marshallValuationService.siteImprovementsCost', fromCurrency(row.costPerSf))
    }
  }
  const columns = useMemo(() => getColumns(), [])

  return (
    <RowBasedTable
      id="site-improvements-mvs-table"
      columns={columns}
      rows={rows}
      onRowUpdate={handleRowUpdate}
      onManyRowsUpdate={noop}
      getCustomColumnConfig={getCustomColumnConfig}
      actionCellHidden
      hideIndexColumn
      onColumnDragEnd={noop}
      onRowsDragEnd={noop}
      onColumnDelete={noop}
      onColumnUpdate={noop}
      onRowDelete={noop}
    />
  )
}

export default memo(SiteImprovementsTable)
