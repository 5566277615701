import React from 'react'
import PropTypes from 'prop-types'
import { Typography, Table, TableBody, TableHead, TableRow, TableCell } from '@mui/material'

import { formatCurrencyFloat } from 'client-shared/utils/numberFormatters'

class SalesCompsTableSummary extends React.PureComponent {
  render() {
    const { adjustedCompSuperlatives, unadjustedCompSuperlatives } = this.props

    return (
      <Table size="small" sx={{ '& .MuiTableCell-root': { padding: '13px' } }}>
        <TableHead sx={{ '& .MuiTableCell-head': { fontSize: 12, fontWeight: 700 } }}>
          <TableRow>
            <TableCell colSpan={5}>
              <Typography variant="body2">SALES COMPARABLES PRICE SUMMARY</Typography>
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell />
            <TableCell>Comparables Price (Unadjusted)</TableCell>
            <TableCell>Comparables Price (Adjusted) </TableCell>
          </TableRow>
        </TableHead>
        <TableBody sx={{ '& .MuiTableCell-root': { fontSize: 13 } }}>
          <TableRow>
            <TableCell>Min:</TableCell>
            <TableCell data-qa="unadjusted-price-min">{formatCurrencyFloat(unadjustedCompSuperlatives.min)}</TableCell>
            <TableCell data-qa="adjusted-price-min">{formatCurrencyFloat(adjustedCompSuperlatives.min)}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell>Avg:</TableCell>
            <TableCell data-qa="unadjusted-price-avg">{formatCurrencyFloat(unadjustedCompSuperlatives.avg)}</TableCell>
            <TableCell data-qa="adjusted-price-avg">{formatCurrencyFloat(adjustedCompSuperlatives.avg)}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell>Max:</TableCell>
            <TableCell data-qa="unadjusted-price-max">{formatCurrencyFloat(unadjustedCompSuperlatives.max)}</TableCell>
            <TableCell data-qa="adjusted-price-max">{formatCurrencyFloat(adjustedCompSuperlatives.max)}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell>Median:</TableCell>
            <TableCell data-qa="unadjusted-price-median">
              {formatCurrencyFloat(unadjustedCompSuperlatives.median)}
            </TableCell>
            <TableCell data-qa="adjusted-price-median">
              {formatCurrencyFloat(adjustedCompSuperlatives.median)}
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    )
  }
}

SalesCompsTableSummary.propTypes = {
  adjustedCompSuperlatives: PropTypes.object.isRequired,
  classes: PropTypes.object.isRequired,
  unadjustedCompSuperlatives: PropTypes.object.isRequired,
}

export default SalesCompsTableSummary
