import { njDistrictCountyMap as districtCountyMap } from 'shared/constants/property/geography'

import { capitalCase } from '../../../../shared/utils/formatters/textFormatters'

export const allDistrictInfo = Object.entries(districtCountyMap).map(([key, value]) => ({
  city: capitalCase(value.cityName),
  zip: value.zip,
  districtCode: value.districtCode,
  county: capitalCase(value.countyName),
}))

export const cityNames = Object.keys(districtCountyMap).map(capitalCase)

export const OCEAN_COUNTY = 'OCEAN'

export const formatDistrict = cityInfo => {
  const formattedCityName = capitalCase(cityInfo.cityName)
  const formattedCountyname = capitalCase(cityInfo.countyName)
  return `${formattedCityName} (${cityInfo.zip})/${formattedCountyname} County`
}

export const cityZipCountySuggestion = Object.values(districtCountyMap).reduce((suggestions, cityInfo) => {
  suggestions.push(formatDistrict(cityInfo))
  return suggestions
}, [])

/** @deprecated Prefer importing this from the shared folder */
export { districtCountyMap }
