import { createAction } from 'redux-actions'

import { LOGOUT_USER, LOGOUT_USER_WITH_CONFIRMATION, LOGIN_USER, FETCH_USER, AUTH_CLEAR } from './actionTypes'

export const fetchUser = createAction(FETCH_USER.ACTION)
export const fetchUserRequest = createAction(FETCH_USER.REQUEST)
export const fetchUserReceive = createAction(FETCH_USER.RECEIVE)
export const fetchUserFail = createAction(FETCH_USER.FAIL)

export const authClearError = createAction(AUTH_CLEAR.ACTION)

export const loginUser = createAction(LOGIN_USER.ACTION)
export const loginUserRequest = createAction(LOGIN_USER.REQUEST)
export const loginUserReceive = createAction(LOGIN_USER.RECEIVE)
export const loginUserFail = createAction(LOGIN_USER.FAIL)

export const logoutUser = createAction(LOGOUT_USER.ACTION)
export const logoutUserRequest = createAction(LOGOUT_USER.REQUEST)
export const logoutUserReceive = createAction(LOGOUT_USER.RECEIVE)
export const logoutUserFail = createAction(LOGOUT_USER.FAIL)
export const logoutUserWithConfirmation = createAction(LOGOUT_USER_WITH_CONFIRMATION.ACTION)
