import * as React from 'react'
import PropTypes from 'prop-types'
import carto from '@carto/carto.js'

class MapLayer extends React.Component {
  static contextTypes = {
    map: PropTypes.object, // Leaflet map
  }

  componentDidMount() {
    const { client, source, style } = this.props

    const layers = []
    for (const map of source) {
      const curStyle = !map.isCustom ? style[map.style] : map.style
      const css = new carto.style.CartoCSS(curStyle)
      const mapData = new carto.source.Dataset(map.name)
      const layer = new carto.layer.Layer(mapData, css)
      layers.push(layer)
    }

    client.addLayers(layers)

    client.getLeafletLayer().addTo(this.context.map)
  }

  render() {
    return null
  }
}

export default MapLayer
