import React, { FC } from 'react'
import { get } from 'lodash'
import arrayMutators from 'final-form-arrays'
import { Stack } from '@mui/material'

import { CalloutButton } from 'client-shared/components'
import { INSURABLE_VALUE_PATH } from 'shared/constants/report/keysAndDataPaths'

import wrapForm from '../../wrapForm'

import MvsBuildingContainer from './MvsBuildingContainer'
import { mvsDecorators } from './decorators'

export const DATA_PATH = INSURABLE_VALUE_PATH

const heading = 'Insurable Value'

type InsurableValueOwnProps = {
  form: any
}

const InsurableReplacementCost: FC<InsurableValueOwnProps> = ({ form }) => {
  const { isMvsFileAvailable } = form.values

  return (
    <>
      <Stack spacing={2}>
        <CalloutButton
          link={
            isMvsFileAvailable
              ? '/downloadMvsPdf/mvs.pdf'
              : 'https://www.dropbox.com/home/Valuation/Textbooks%20and%20Manuals/Marshall%20Valuation%20Services'
          }
          linkText="Open MVS PDF"
          target="_blank"
          text="Use this link to reference the most recent copy of MVS"
          variant="link"
        />
        <MvsBuildingContainer form={form} />
      </Stack>
    </>
  )
}

const mapStateToProps = (state: any) => {
  const grossBuildingArea = get(state, 'report.reportData.propertyInformation.propertySummary.grossBuildingArea', 0)
  const numberOfFloors = get(state, 'report.reportData.propertyInformation.propertySummary.floors', 1)
  const roundingFactor = get(state, 'report.reportData.incomeApproach.capRateConclusion.roundingFactor')
  return {
    grossBuildingArea,
    numberOfFloors,
    roundingFactor,
  }
}

export default wrapForm(
  DATA_PATH,
  {
    heading,
    decorators: [...mvsDecorators],
    mutators: {
      ...arrayMutators,
    },
  },
  mapStateToProps
)(InsurableReplacementCost)
