import React from 'react'
import PropTypes from 'prop-types'

import ArrowCircleDown from '@mui/icons-material/ArrowCircleDown'
import { DragDropContext } from 'react-beautiful-dnd'
import {
  IconButton,
  Stack,
  TableBody,
  TableCell,
  TableFooter,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
} from '@mui/material'

import DropDown from 'client-shared/components/Table/TableDropDown'
import TableNumberField from 'client-shared/components/Table/TableNumber'
import { DroppableTable, DraggableTableRowV2 } from 'client-shared/components/DragDropTable'
import { formatCurrencyFloat } from 'client-shared/utils/numberFormatters'

import { PARKING_TYPE_OPTIONS, PARKING_OPERATION_OPTIONS } from './constants'

const CopyToAllButton = ({ action, fieldName }) => (
  <Tooltip title="Copy Down" placement="top">
    <IconButton data-qa={`copy-${fieldName}-to-all`} onClick={() => action('spaces', fieldName)} size="small">
      <ArrowCircleDown color="info" fontSize="small" />
    </IconButton>
  </Tooltip>
)

const SpaceRentalTableRow = ({ copyAction, index, monthlyRent }) => {
  const annualAmount = monthlyRent * 12

  return (
    <DraggableTableRowV2 key={index} id={`space[${index}]`} index={index} singleValue={false} canDelete={false}>
      <TableCell>{index + 1}</TableCell>
      <TableCell>
        <Stack alignItems="center" direction="row" spacing={1}>
          <DropDown
            disablePortal
            displayEmpty
            items={PARKING_OPERATION_OPTIONS}
            name={`spaces[${index}].operation`}
            openOnFocus
            placeholder="Select Operation"
          />
          {index === 0 && <CopyToAllButton fieldName="operation" action={copyAction} />}
        </Stack>
      </TableCell>
      <TableCell>
        <Stack alignItems="center" direction="row" spacing={1}>
          <DropDown
            disablePortal
            displayEmpty
            items={PARKING_TYPE_OPTIONS}
            name={`spaces[${index}].type`}
            openOnFocus
            placeholder="Select Type"
          />
          {index === 0 && <CopyToAllButton fieldName="type" action={copyAction} />}
        </Stack>
      </TableCell>
      <TableCell>
        <Stack alignItems="center" direction="row" maxWidth={200} spacing={1}>
          <TableNumberField
            adornment="$"
            adornmentPosition="start"
            allowNegative={false}
            decimalScale={2}
            name={`spaces[${index}].monthlyRent`}
            placeholder="0.00"
            thousandSeparator
          />
          {index === 0 && <CopyToAllButton fieldName="monthlyRent" action={copyAction} />}
        </Stack>
      </TableCell>
      <TableCell>{formatCurrencyFloat(annualAmount)}</TableCell>
    </DraggableTableRowV2>
  )
}

class SpaceRentalTable extends React.Component {
  onDragEnd = ({ source, destination }) => {
    if (destination) {
      this.props.fields.move(source.index, destination.index)
    }
  }

  render() {
    const { fields, totalAnnualParkingIncome } = this.props

    return (
      <Stack spacing={2}>
        <Typography variant="h6">Space rental price</Typography>
        <DragDropContext onDragEnd={this.onDragEnd}>
          <DroppableTable id="parking-spaces">
            <TableHead sx={{ '& .MuiTableCell-head': { fontSize: 14, fontWeight: 700 } }}>
              <TableRow>
                <TableCell />
                <TableCell>#</TableCell>
                <TableCell>Parking Operation</TableCell>
                <TableCell>Parking Type</TableCell>
                <TableCell>Monthly Rent</TableCell>
                <TableCell>Annual Rent Per Space</TableCell>
              </TableRow>
            </TableHead>
            <TableBody data-qa="parking-spaces-table-body">
              {fields.value.map((item, index) => (
                <SpaceRentalTableRow
                  index={index}
                  monthlyRent={item.monthlyRent}
                  copyAction={this.props.copyFieldToAll}
                />
              ))}
            </TableBody>
            <TableFooter>
              <TableRow sx={{ '& .MuiTableCell-root': { color: 'text.primary', fontSize: 14, fontWeight: 700 } }}>
                <TableCell />
                <TableCell colSpan="4">Total Annual Parking Income</TableCell>
                <TableCell data-qa="total-annual-amount">{formatCurrencyFloat(totalAnnualParkingIncome)}</TableCell>
              </TableRow>
            </TableFooter>
          </DroppableTable>
        </DragDropContext>
      </Stack>
    )
  }
}

SpaceRentalTable.propTypes = {
  totalAnnualParkingIncome: PropTypes.number.isRequired,
  copyFieldToAll: PropTypes.func.isRequired,
  fields: PropTypes.shape({
    value: PropTypes.array,
  }).isRequired,
}

export default SpaceRentalTable
