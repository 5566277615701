import React from 'react'

import AddCircle from '@mui/icons-material/AddCircle'
import Remove from '@mui/icons-material/Remove'
import RemoveCircle from '@mui/icons-material/RemoveCircle'
import AutoModeIcon from '@mui/icons-material/AutoModeRounded'
import {
  Avatar,
  Box,
  Button,
  IconButton,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TableSortLabel,
  Tooltip,
  Typography,
} from '@mui/material'

import { SourceInformationKeys } from 'shared/constants/report'
import { getSalePriceToUse } from 'shared/report-calculations/sales-approach/helpers'

import PropertyTypeIcon from 'client-shared/components/PropertyTypeIcon'
import { Area, Template } from 'client-shared/components/Template'
import { CLEAR_ALL_LABEL, HIDE_REMOVED_LABEL, NOT_AVAILABLE, SHOW_REMOVED_LABEL } from 'report/constants'
import { calculateDistance } from 'client-shared/utils/geography'
import { forEach, get, isNil, isNumber, startCase } from 'lodash'
import {
  formatCurrencyFloat,
  formatCurrencyInt,
  formatInt,
  toPercentageString,
} from 'client-shared/utils/numberFormatters'

import { divide } from 'shared/utils/numberOperations'

import Badge from './Badge'
import { HEADER_COLUMNS, SALES_COMP_STOCK_PHOTO } from './constants'
import { getSaleCompFullAddress, getSorting, stableSort } from './tools'
import { getFormattedSaleDate } from './helpers'

export const getSourceOfInformation = comp => {
  const { resourceInformation } = comp
  const { sources } = resourceInformation
  const firstSource = sources[0]

  if (!firstSource) {
    return ''
  }

  const type = firstSource.type

  if (['costar', SourceInformationKeys.externalDatabase].includes(type) && !!firstSource.url) {
    const { url } = firstSource

    const urlToUse = url.indexOf('://') === -1 ? `https://${url}` : url
    return (
      <a href={urlToUse} target="_blank" rel="noopener noreferrer">
        {startCase(type)}
      </a>
    )
  }

  return startCase(type)
}

class MaterialTableHeader extends React.Component {
  createSortHandler = property => event => {
    this.props.onRequestSort(event, property)
  }

  render() {
    const { order, orderBy } = this.props

    return (
      <TableHead>
        <TableRow>
          {HEADER_COLUMNS.map(row => {
            return (
              <TableCell key={row.id} sortDirection={orderBy === row.id ? order : false}>
                <TableSortLabel active={orderBy === row.id} direction={order} onClick={this.createSortHandler(row.id)}>
                  {row.label}
                </TableSortLabel>
              </TableCell>
            )
          }, this)}
          <TableCell>Actions</TableCell>
        </TableRow>
      </TableHead>
    )
  }
}

const EnhancedTableHead = MaterialTableHeader

class RemovedSalesCompsTable extends React.Component {
  state = {
    isRemovedVisible: true,
    order: 'asc',
    orderBy: 'distance',
    selected: [],
  }

  fillTableRowTemplate = ({
    actions,
    address,
    compId,
    compIndex,
    displaySourceOfInformation,
    distance,
    propertyInformation,
    photoElement,
    saleInformation,
    sourceIcon,
    status,
    isDeleted,
  }) => {
    const fillerIcon = <Remove />

    const { grossBuildingArea, propertyType, yearBuilt, residentialUnits, commercialUnits } = propertyInformation
    const totalUnits = residentialUnits + (commercialUnits || 0)
    const { capRate, saleDate, saleStatus } = saleInformation
    const salePrice = getSalePriceToUse(saleInformation)
    const pricePerUnit = divide(salePrice, totalUnits)
    const pricePerSquareFoot = divide(salePrice, grossBuildingArea)
    const formattedGBA = !isNil(grossBuildingArea) ? `${formatInt(grossBuildingArea)}` : fillerIcon
    return (
      <TableRow
        data-qa={isNumber(compIndex) ? `selected-comps-row-${compIndex}` : 'property-row'}
        key={`selected-comps-${compId}`}
        selected={false}
      >
        <TableCell data-qa="sourceIcon">{sourceIcon}</TableCell>
        <TableCell data-qa="photo-element">{photoElement}</TableCell>
        <TableCell data-qa="property-type">
          <PropertyTypeIcon propertyType={propertyType} />
        </TableCell>
        <TableCell data-qa="distance">{distance || fillerIcon}</TableCell>
        <TableCell data-qa="address">
          {getSaleCompFullAddress(address)}

          {isDeleted && (
            <Tooltip placement="top" title="This comp was deleted from our database by a user.">
              <Box component="span" sx={{ position: 'relative', top: 4, left: 8 }}>
                <RemoveCircle color="error" fontSize="small" />
              </Box>
            </Tooltip>
          )}
        </TableCell>
        <TableCell data-qa="status">{status ? <Badge type={status} /> : fillerIcon}</TableCell>
        <TableCell data-qa="sale-date">{getFormattedSaleDate(saleDate, saleStatus, fillerIcon)}</TableCell>
        <TableCell data-qa="year-built">{yearBuilt || fillerIcon}</TableCell>
        <TableCell data-qa="total-units">{totalUnits || fillerIcon}</TableCell>
        <TableCell data-qa="formatted-gba">{formattedGBA}</TableCell>
        <TableCell data-qa="sale-price">{formatCurrencyInt(salePrice) || fillerIcon}</TableCell>
        <TableCell data-qa="price-per-unit">{formatCurrencyInt(pricePerUnit) || fillerIcon}</TableCell>
        <TableCell data-qa="price-per-square-foot">{formatCurrencyFloat(pricePerSquareFoot) || fillerIcon}</TableCell>
        <TableCell data-qa="cap-rate">{toPercentageString(capRate) || fillerIcon}</TableCell>
        <TableCell data-qa="source-of-information">{displaySourceOfInformation || fillerIcon}</TableCell>
        <TableCell data-qa="removed-comp-actions">{actions || ''}</TableCell>
      </TableRow>
    )
  }

  renderTableBodyContent = () => {
    const { addComp, removeComp, removedComps, subjectCoordinates } = this.props
    const salesCompsTableRows = []

    const salesCompsForSorting = removedComps.map(salesComp => {
      const { propertyInformation, saleInformation, salesTransactionId, address } = salesComp
      const { residentialUnits, commercialUnits, grossBuildingArea } = propertyInformation
      const { capRate, saleDate } = saleInformation
      const salePrice = getSalePriceToUse(saleInformation)
      const totalUnits = residentialUnits + (commercialUnits || 0)
      return {
        ...salesComp,
        addComp: () => addComp(salesComp),
        capRate: capRate ? capRate : NOT_AVAILABLE,
        compId: get(salesComp, '_id') || salesTransactionId,
        distance: calculateDistance(subjectCoordinates, address.coords),
        photoUrl: get(propertyInformation, 'photo.cdnUrl') || SALES_COMP_STOCK_PHOTO,
        pricePerSquareFoot: divide(salePrice, grossBuildingArea),
        pricePerUnit: divide(salePrice, totalUnits),
        removeComp: () => removeComp(salesComp),
        saleDate,
        totalUnits,
      }
    })

    const sortedSalesComps = stableSort(salesCompsForSorting, getSorting(this.state.order, this.state.orderBy))

    forEach(sortedSalesComps, ({ addComp, removeComp, ...salesComp }, index) => {
      salesCompsTableRows.push(this.renderTableRow({ salesComp, index, removeComp, addComp }))
    })
    return salesCompsTableRows
  }

  renderTableRow = ({ addComp, index, removeComp, salesComp }) => {
    const actions = (
      <Stack alignItems="center" direction="row" spacing={1}>
        <Tooltip placement="top" title="Add">
          <IconButton aria-label="Add" data-qa="removed-comp-add-btn" onClick={addComp} size="small">
            <AddCircle color="success" />
          </IconButton>
        </Tooltip>
        <Tooltip placement="top" title="Remove">
          <IconButton aria-label="Remove" data-qa="removed-comp-remove-btn" onClick={removeComp} size="small">
            <RemoveCircle color="error" />
          </IconButton>
        </Tooltip>
      </Stack>
    )

    const sourceIcon = salesComp.isAutoComp ? (
      <Tooltip placement="top" title="This Sales Comp was added automatically.">
        <Stack color="#673AB7">
          <AutoModeIcon />
        </Stack>
      </Tooltip>
    ) : null

    const photoTooltip = (
      <Avatar
        alt="Sales Comp"
        src={salesComp.photoUrl}
        sx={{ width: 100, height: 100, objectFit: 'contain' }}
        variant="square"
      />
    )

    const photoElement = (
      <Tooltip placement="left" title={photoTooltip}>
        <Avatar
          alt="Sales Comp"
          src={salesComp.photoUrl}
          sx={{ width: 36, height: 36, objectFit: 'cover' }}
          variant="square"
        />
      </Tooltip>
    )

    const displaySourceOfInformation = getSourceOfInformation(salesComp)

    return this.fillTableRowTemplate({
      actions,
      address: salesComp.address,
      compId: salesComp.compId || index,
      compIndex: index,
      displaySourceOfInformation,
      distance: `${salesComp.distance} mi`,
      propertyInformation: salesComp.propertyInformation,
      photoElement,
      saleInformation: salesComp.saleInformation,
      sourceIcon,
      status: salesComp.status,
      isDeleted: !!salesComp.deletedAt,
    })
  }

  handleRequestSort = (event, property) => {
    const orderBy = property
    let order = 'desc'

    if (this.state.orderBy === property && this.state.order === 'desc') {
      order = 'asc'
    }

    this.setState({ order, orderBy })
  }

  toggleRemovedVisibility = () => {
    this.setState(prevState => ({
      isRemovedVisible: !prevState.isRemovedVisible,
    }))
  }

  render() {
    const { clearRemovedTable, disabled, title } = this.props
    const { isRemovedVisible } = this.state

    return (
      <Paper>
        <Template
          is={`
            'title tools'
            'table table'
            /auto  1fr
          `}
          gap={2}
        >
          <Area is="title">
            <Typography variant="h6" gutterBottom>
              {title}
            </Typography>
          </Area>
          <Area is="tools" gap={2} placeContent="flex-end" placeItems="center">
            <Button size="small" color="primary" disabled={disabled} onClick={this.toggleRemovedVisibility}>
              {isRemovedVisible ? HIDE_REMOVED_LABEL : SHOW_REMOVED_LABEL}
            </Button>
            <Button size="small" color="primary" disabled={disabled} onClick={clearRemovedTable}>
              {CLEAR_ALL_LABEL}
            </Button>
          </Area>
          <Area is="table">
            {!disabled && isRemovedVisible && (
              <Table size="small" data-qa="removed-sales-comps-table">
                <EnhancedTableHead
                  onRequestSort={this.handleRequestSort}
                  order={this.state.order}
                  orderBy={this.state.orderBy}
                />
                <TableBody>{this.renderTableBodyContent()}</TableBody>
              </Table>
            )}
          </Area>
        </Template>
      </Paper>
    )
  }
}

export default RemovedSalesCompsTable
