import { get } from 'lodash'

function mapData(
  physicalDepreciation,
  functionalObsolescence,
  externalObsolescence,
  includeDepreciation,
  neighborhood,
  asCompleteYear
) {
  return {
    physicalDepreciation,
    functionalObsolescence,
    externalObsolescence,
    includeDepreciation,
    neighborhood,
    asCompleteYear,
  }
}

export const mapDTO = formValues => {
  const includeDepreciation = get(formValues, 'depreciation.includeDepreciation')
  const physicalDepreciation = get(formValues, 'depreciation.replacementCostDepreciations[0].physical')
  const functionalObsolescence = get(formValues, 'depreciation.replacementCostDepreciations[0].functionalObsolescence')
  const externalObsolescence = get(formValues, 'depreciation.replacementCostDepreciations[0].externalObsolescence')
  const neighborhood = get(formValues, 'neighborhood')
  const asCompleteYear = get(formValues, 'asCompleteYear')
  return mapData(
    physicalDepreciation,
    functionalObsolescence,
    externalObsolescence,
    includeDepreciation,
    neighborhood,
    asCompleteYear
  )
}

export const mapDataModel = report => {
  const includeDepreciation = get(report, 'costApproach.depreciation.includeDepreciation')
  const physicalDepreciation = get(report, 'costApproach.depreciation.replacementCostDepreciations[0].physical')
  const functionalObsolescence = get(
    report,
    'costApproach.depreciation.replacementCostDepreciations[0].functionalObsolescence'
  )
  const externalObsolescence = get(
    report,
    'costApproach.depreciation.replacementCostDepreciations[0].externalObsolescence'
  )
  const neighborhood = get(report, 'property_information.neighborhood')

  const asCompleteDateOfValue = get(report, 'costApproach.conclusion.asCompleteDateOfValue')
  const asCompleteYear = asCompleteDateOfValue?.getFullYear() || null
  return mapData(
    physicalDepreciation,
    functionalObsolescence,
    externalObsolescence,
    includeDepreciation,
    neighborhood,
    asCompleteYear
  )
}
