import { get, last } from 'lodash'
import moment from 'moment'

import { formatFormName, isSupportedGeography } from '../../../../shared/utils/analytics'

export const mapReportContext = (report, reportSettings) => {
  return {
    reportExportCount: report.exportCount,
    reportState: get(report, 'reviewAndExport.state'),
    reportPropertyAddress: report.address,
    reportPropertyCity: report.city,
    reportPropertyState: report.state,
    reportCreatedAt: moment(report.createdAt).toISOString(),
    reportValueConclusionType: reportSettings.valueConclusionType,
    reportPropertyIncomeType: reportSettings.incomeType,
    reportTemplateType: reportSettings.templateType,
    reportSupportedGeography: isSupportedGeography(report.state),
    jobID: report.report_number,
  }
}

export const mapNewReportContext = newReport => {
  return {
    reportExportCount: 0,
    reportState: 'Draft',
    reportPropertyAddress: get(newReport, 'property_information.address'),
    reportPropertyCity: get(newReport, 'property_information.city'),
    reportPropertyState: get(newReport, 'property_information.state'),
    reportCreatedAt: moment(newReport.createdAt).toISOString(),
    reportValueConclusionType: newReport.valueConclusionType,
    reportPropertyIncomeType: get(newReport, 'property_information.type'),
    reportTemplateType: newReport.templateType,
    reportSupportedGeography: isSupportedGeography(get(newReport, 'property_information.state')),
    jobID: newReport.report_number,
  }
}

export const getFormFromState = state => {
  const formPath = get(state, 'shared.location.form.formPath')
  if (formPath) {
    return formatFormName(last(formPath.split('.')))
  }
}
