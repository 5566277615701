import React, { FC, memo, useMemo } from 'react'

import { get, noop } from 'lodash'
import { ColumnDataTypeEnum, RowBasedTable } from '@bowery-valuation/ui-components'

import { formatCurrencyFloat, formatCurrencyInt, formatPercentageString } from 'client-shared/utils/numberFormatters'
import { getCustomColumnConfig, RowBasedTableColumn } from 'client-shared/utils/rowBasedTable'
import { divide } from 'shared/utils/numberOperations'
import { fromCurrency } from 'client-shared/utils/numberOperations'
import { FormChange } from 'client-shared/utils/form'
import { MarshallValuationServiceType } from 'shared/types/costApproachTypes'

type MvsConclusionTableOwnProps = {
  change: FormChange
  marshallValuationService: MarshallValuationServiceType
  mvsDirectCost: number
  grossBuildingArea: number
  numberOfResidentialUnits: number
  demolitionCosts: number
}

const MvsConclusionTable: FC<MvsConclusionTableOwnProps> = ({
  marshallValuationService,
  change,
  mvsDirectCost,
  grossBuildingArea,
  numberOfResidentialUnits,
  demolitionCosts,
}) => {
  const indirectCosts = get(marshallValuationService, 'indirectCosts', 0)
  const otherCosts = get(marshallValuationService, 'otherCosts', 0)

  const totalReplacementCosts = demolitionCosts + mvsDirectCost + indirectCosts + otherCosts

  const getColumns = (): RowBasedTableColumn[] => {
    return [
      {
        name: 'label',
        label: 'Cost Item',
        type: ColumnDataTypeEnum.text,
        permanent: true,
        align: 'left',
        editable: false,
        suppressMovable: true,
      },
      {
        name: 'total',
        label: 'Total',
        type: ColumnDataTypeEnum.text,
        permanent: true,
        align: 'right',
        editable: params => {
          return params.data.id === 'indirect' || params.data.id === 'other'
        },
        suppressMovable: true,
      },
      {
        name: 'percentOfTotal',
        label: '% of Total',
        type: ColumnDataTypeEnum.text,
        permanent: true,
        align: 'right',
        editable: false,
        suppressMovable: true,
      },
      {
        name: 'perSquareFoot',
        label: 'Per SF',
        type: ColumnDataTypeEnum.text,
        permanent: true,
        align: 'right',
        editable: false,
        suppressMovable: true,
      },
      {
        name: 'perUnit',
        label: 'Per Unit',
        type: ColumnDataTypeEnum.text,
        permanent: true,
        align: 'right',
        editable: false,
        suppressMovable: true,
      },
    ]
  }
  const rows = [
    {
      readOnly: false,
      suppressMovable: true,
      permanent: true,
      type: ColumnDataTypeEnum.text,
      id: 'mvs',
      label: 'MVS Direct Costs',
      total: formatCurrencyInt(mvsDirectCost),
      percentOfTotal: formatPercentageString(divide(mvsDirectCost, totalReplacementCosts), 2),
      perSquareFoot: formatCurrencyFloat(divide(mvsDirectCost, grossBuildingArea)),
      perUnit: formatCurrencyInt(divide(mvsDirectCost, numberOfResidentialUnits)),
      rowDef: { summary: false, hideAction: true },
    },
    {
      readOnly: false,
      suppressMovable: true,
      permanent: true,
      type: ColumnDataTypeEnum.text,
      id: 'demolition',
      label: 'Demolition Costs',
      total: formatCurrencyInt(demolitionCosts),
      percentOfTotal: formatPercentageString(divide(demolitionCosts, totalReplacementCosts), 2),
      perSquareFoot: formatCurrencyFloat(divide(demolitionCosts, grossBuildingArea)),
      perUnit: formatCurrencyInt(divide(demolitionCosts, numberOfResidentialUnits)),
      rowDef: { summary: false, hideAction: true },
    },
    {
      readOnly: false,
      suppressMovable: true,
      permanent: true,
      type: ColumnDataTypeEnum.text,
      id: 'indirect',
      label: 'Indirect Costs',
      total: formatCurrencyInt(indirectCosts),
      percentOfTotal: formatPercentageString(divide(indirectCosts, totalReplacementCosts), 2),
      perSquareFoot: formatCurrencyFloat(divide(indirectCosts, grossBuildingArea)),
      perUnit: formatCurrencyInt(divide(indirectCosts, numberOfResidentialUnits)),
      rowDef: { summary: false, hideAction: true },
    },
    {
      readOnly: false,
      suppressMovable: true,
      permanent: true,
      type: ColumnDataTypeEnum.text,
      id: 'other',
      label: 'Other Costs',
      total: formatCurrencyInt(otherCosts),
      percentOfTotal: formatPercentageString(divide(otherCosts, totalReplacementCosts), 2),
      perSquareFoot: formatCurrencyFloat(divide(otherCosts, grossBuildingArea)),
      perUnit: formatCurrencyInt(divide(otherCosts, numberOfResidentialUnits)),
      rowDef: { summary: false, hideAction: true },
    },
    {
      readOnly: false,
      suppressMovable: true,
      permanent: true,
      type: ColumnDataTypeEnum.text,
      id: 'total',
      label: 'MVS Total Replacement Costs',
      total: formatCurrencyInt(totalReplacementCosts),
      percentOfTotal: formatPercentageString(divide(totalReplacementCosts, totalReplacementCosts)),
      perSquareFoot: formatCurrencyFloat(divide(totalReplacementCosts, grossBuildingArea)),
      perUnit: formatCurrencyInt(divide(totalReplacementCosts, numberOfResidentialUnits)),
      rowDef: { summary: true, hideAction: true },
    },
  ]

  const handleRowUpdate = (row: any) => {
    if (row.id === 'indirect') {
      change('marshallValuationService.indirectCosts', fromCurrency(row.total))
    }
    if (row.id === 'other') {
      change('marshallValuationService.otherCosts', fromCurrency(row.total))
    }
  }
  const columns = useMemo(() => getColumns(), [])

  return (
    <RowBasedTable
      id="mvs-conclusion-table"
      columns={columns}
      rows={rows}
      onRowUpdate={handleRowUpdate}
      onManyRowsUpdate={noop}
      getCustomColumnConfig={getCustomColumnConfig}
      actionCellHidden
      hideIndexColumn
      onColumnDragEnd={noop}
      onRowsDragEnd={noop}
      onColumnDelete={noop}
      onColumnUpdate={noop}
      onRowDelete={noop}
    />
  )
}

export default memo(MvsConclusionTable)
