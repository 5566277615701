import React from 'react'
import PropTypes from 'prop-types'

import { IconButton, TableCell, TableRow } from '@mui/material'
import DragHandle from '@mui/icons-material/DragHandle'

import { Draggable } from 'react-beautiful-dnd'

import { ChildrenLock } from './helpers'

const DraggableRow = ({ index, id, children, isDragDisabled }) => (
  <Draggable draggableId={id} index={index} isDragDisabled={isDragDisabled}>
    {({ innerRef, draggableProps, dragHandleProps }, { isDragging }) => (
      <TableRow
        data-qa={`row-${index}`}
        ref={innerRef}
        sx={isDragging && { bgcolor: 'white', boxShadow: 5 }}
        {...draggableProps}
      >
        <ChildrenLock>
          {!isDragDisabled ? (
            <TableCell data-qa="drag-cell">
              <span {...dragHandleProps}>
                <IconButton>
                  <DragHandle {...dragHandleProps} />
                </IconButton>
              </span>
            </TableCell>
          ) : (
            <TableCell data-qa="drag-cell" />
          )}
          {children}
        </ChildrenLock>
      </TableRow>
    )}
  </Draggable>
)

DraggableRow.propTypes = {
  children: PropTypes.node.isRequired,
  id: PropTypes.string.isRequired,
  index: PropTypes.number.isRequired,
  isDragDisabled: PropTypes.bool,
}

DraggableRow.defaultProps = {
  isDragDisabled: false,
}

export default DraggableRow
