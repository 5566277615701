import { PROPERTY_SEARCH_STRATEGY, GEOGRAPHY_OPTIONS, SEARCH_OPTIONS } from '../../../constants/properties'
import { STATE_NAMES, STATE_NAMES_SHORT } from '../../../../../../shared/constants/states'

export const queryBuilder = address => {
  if (address.state === STATE_NAMES_SHORT.NY && address.borough.long) {
    return {
      locationIdentifier: GEOGRAPHY_OPTIONS.NY,
      locationType: SEARCH_OPTIONS.ADDRESS,
      strategy: PROPERTY_SEARCH_STRATEGY.NY_ADDRESS,
      address: address.address || address.shortAddress,
      zip: address.zip,
      city: address.city,
    }
  } else if (address.state === STATE_NAMES_SHORT.NJ) {
    return {
      locationIdentifier: GEOGRAPHY_OPTIONS.NJ,
      locationType: SEARCH_OPTIONS.ADDRESS,
      strategy: PROPERTY_SEARCH_STRATEGY.NJ_ADDRESS,
      address: address.shortAddress,
      zip: address.zip,
      district: address.city,
    }
  } else if (STATE_NAMES_SHORT[address.state]) {
    return {
      locationIdentifier: GEOGRAPHY_OPTIONS.OTHER,
      locationType: SEARCH_OPTIONS.UNIQUE_IDENTIFER,
      strategy: PROPERTY_SEARCH_STRATEGY.OTHER_ADDRESS,
      state: STATE_NAMES[address.state],
      address: address.shortAddress,
      zip: address.zip,
      city: address.city,
    }
  } else {
    return null
  }
}
