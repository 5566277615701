import React, { FC } from 'react'
import { Grid, Paper, Stack, Typography } from '@mui/material'

import wrapForm from 'report/forms/wrapForm'
import { RECONCILIATION_PATH } from 'shared/constants/report/keysAndDataPaths'
import { NarrativeComponent } from 'client-shared/components'
import reconciliationTableSummary from 'shared/utils/textGeneration/costApproach/reconciliation'
import { ExtendedFormApi } from 'client-shared/utils/form'

import { PAPER_WIDTH } from '../../helpers'

import ReconciliationTable from './ReconciliationTable'

type ReconciliationOwnProps = {
  form: ExtendedFormApi
}

export const Reconciliation: FC<ReconciliationOwnProps> = ({ form }) => {
  const { batch, change, values } = form
  const { reconciliation = {}, grossBuildingArea, subjectBudgetTotal, mvsTotalReplacementCostNew } = values

  return (
    <Paper id="reconciliation" sx={{ maxWidth: PAPER_WIDTH }}>
      <Stack spacing={5}>
        <Typography variant="h5">Reconciliation</Typography>
        <NarrativeComponent
          title="Reconciliation Table Summary"
          generatedText={reconciliationTableSummary.generate}
          name="reconciliation.reconciliationTableSummary"
        />
        <Grid>
          <Typography variant="subtitle1" sx={{ paddingBottom: '8px' }}>
            Replacement Cost New Conclusion
          </Typography>
          <ReconciliationTable
            change={change}
            batch={batch}
            grossBuildingArea={grossBuildingArea}
            reconciliation={reconciliation}
            mvsTotalReplacementCostNew={mvsTotalReplacementCostNew}
            subjectBudgetTotal={subjectBudgetTotal}
          />
        </Grid>
      </Stack>
    </Paper>
  )
}
export default wrapForm(RECONCILIATION_PATH, {
  heading: 'Reconciliation',
})(Reconciliation)
