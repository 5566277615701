import React, { createContext } from 'react'
import { get } from 'lodash'
import { Form } from 'react-final-form'
import { Dialog, Grid, Typography, IconButton, withStyles } from '@material-ui/core'
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft'

import DialogHeader from '../DialogHeader'
import { WIZARD_STEPS } from '../../constants/properties'
import { SiteAreaValues } from '../../../report/constants'

import BasicSearch from './Search/BasicSearch'
import AdvancedSearch from './Search/AdvancedSearch/AdvancedSearch'
import SearchResults from './SearchResults/SearchResults'
import StepFooter from './StepFooter'
const { Provider, Consumer } = createContext({})

const styles = theme => {
  return {
    backButton: {
      fontSize: 14,
      fontWeight: 600,
      letterSpacing: 0.4,
      cursor: 'pointer',
      color: theme.palette.primary.main,
      '&:hover': {
        textDecoration: 'underline',
        backgroundColor: 'transparent',
      },
    },
  }
}

class StepNoStyles extends React.PureComponent {
  static defaultProps = {
    defaultTitle: (title, values, handleClose) => {
      return (
        title && (
          <DialogHeader onClose={handleClose} data-qa="dialog-title">
            {title}
          </DialogHeader>
        )
      )
    },
    renderTitle: (title, values, defaultTitle, handleClose) => defaultTitle(title, values, handleClose),
  }

  render() {
    const { step, children, title, defaultTitle, renderTitle, classes } = this.props
    return (
      <Consumer>
        {({
          activeStep,
          openNextStep,
          openPreviousStep,
          values,
          invalid,
          submit,
          previousStep,
          handleClose,
          handleBack,
          form,
          stepSpecific,
        }) =>
          activeStep === step && (
            <React.Fragment>
              {handleBack && (
                <Typography>
                  <IconButton className={classes.backButton} aria-label="Back" onClick={handleBack}>
                    <ChevronLeftIcon /> Back
                  </IconButton>
                </Typography>
              )}
              {renderTitle(title, values, defaultTitle, handleClose)}
              {children({
                openNextStep,
                openPreviousStep,
                values,
                submit,
                invalid,
                previousStep,
                handleClose,
                form,
                stepSpecific,
              })}
            </React.Fragment>
          )
        }
      </Consumer>
    )
  }
}

const Step = withStyles(styles)(StepNoStyles)

class PropertyWizard extends React.PureComponent {
  static defaultProps = {
    values: {},
    customSteps: {},
    initialStep: WIZARD_STEPS.BASIC_SEARCH,
  }

  state = {
    activeStep: this.props.initialStep,
    selectedPropertyIndex: null,
    steps: [],
  }

  openNextStep = (step, { properties, ...updates } = {}) => {
    if (properties) {
      this.props.form.change('properties', properties)
    }
    this.setState(state => ({
      activeStep: step,
      steps: [state.activeStep, ...state.steps],
      ...updates,
    }))
  }

  openPreviousStep = ({ step } = {}) => {
    this.setState(state => {
      const [last, ...steps] = state.steps
      return { activeStep: step || last, steps: steps }
    })
  }

  onPropertySelect = index => {
    this.setState({ selectedPropertyIndex: index })
  }

  onSearchComplete = ({ properties, step = WIZARD_STEPS.SEARCH_RESULTS, locationIdentifier }) => {
    this.props.form.change('search.locationIdentifier', locationIdentifier)
    this.openNextStep(step, { properties, selectedPropertyIndex: null })
  }

  onSubmit = property => {
    const { form, values, nextStep, handleSubmit } = this.props
    const { search, properties, ...otherValues } = values
    const propertyValues = {
      siteAreaUnit: SiteAreaValues.SF,
      ...property,
      coords: property.coords || get(values, 'search.location.coords'),
    }
    if (nextStep) {
      form.reset({ search, ...search, ...propertyValues, ...otherValues })
      this.openNextStep(nextStep)
    } else {
      form.reset({ ...values, ...search, ...propertyValues })
      handleSubmit()
    }
  }

  renderSearchResultsTitle = () => {
    const { handleClose, values } = this.props
    const { properties = [] } = values
    const resultsMessage = !!properties.length && `${properties.length} Result${properties.length > 1 ? 's' : ''} Found`
    return (
      <DialogHeader onClose={handleClose}>
        <Grid container justify="space-between" alignItems="center">
          <Grid item>
            <Typography variant="h6">Search Results</Typography>
          </Grid>
          <Grid item>
            <Typography variant="body1">{resultsMessage}</Typography>
          </Grid>
        </Grid>
      </DialogHeader>
    )
  }

  renderStep = (step, defaultComponent) => {
    return this.props.customSteps[step] || defaultComponent
  }

  render() {
    const { children, values, handleSubmit, invalid, title, renderTitle, additional, handleClose, handleBack, form } =
      this.props
    const { activeStep, steps, selectedPropertyIndex } = this.state
    const { properties = [] } = values
    return (
      <Provider
        value={{
          activeStep,
          previousStep: steps[0],
          openNextStep: this.openNextStep,
          openPreviousStep: this.openPreviousStep,
          submit: handleSubmit,
          invalid,
          values,
          handleClose,
          handleBack,
          form,
          stepSpecific:
            activeStep === WIZARD_STEPS.SEARCH_RESULTS || activeStep === WIZARD_STEPS.COMPPLEX_ADDRESS_SEARCH
              ? { properties, selectedPropertyIndex, onPropertySelect: this.onPropertySelect }
              : {},
        }}
      >
        {this.renderStep(
          WIZARD_STEPS.BASIC_SEARCH,
          <Step title={title} renderTitle={renderTitle} step={WIZARD_STEPS.BASIC_SEARCH}>
            {({ openNextStep, values }) => (
              <BasicSearch
                values={values}
                openNextStep={openNextStep}
                onSearchComplete={this.onSearchComplete}
                invalid={invalid}
              />
            )}
          </Step>
        )}

        {this.renderStep(
          WIZARD_STEPS.ADVANCED_SEARCH,
          <Step title={title} renderTitle={renderTitle} step={WIZARD_STEPS.ADVANCED_SEARCH}>
            {({ openNextStep, values }) => (
              <AdvancedSearch
                values={values}
                invalid={invalid}
                openNextStep={openNextStep}
                onLocationChange={this.onLocationChange}
                onSearchComplete={this.onSearchComplete}
                onSubmit={this.onSubmit}
              />
            )}
          </Step>
        )}

        {this.renderStep(
          WIZARD_STEPS.COMPPLEX_ADDRESS_SEARCH,
          <Step
            defaultTitle={this.renderSearchResultsTitle}
            renderTitle={renderTitle}
            step={WIZARD_STEPS.COMPPLEX_ADDRESS_SEARCH}
          >
            {({ openNextStep, openPreviousStep, values }) => (
              <SearchResults
                selectedPropertyIndex={selectedPropertyIndex}
                openNextStep={openNextStep}
                additional={additional}
                openPreviousStep={openPreviousStep}
                onSubmit={this.onSubmit}
                onPropertySelect={this.onPropertySelect}
                values={values}
              />
            )}
          </Step>
        )}

        {this.renderStep(
          WIZARD_STEPS.SEARCH_RESULTS,
          <Step
            defaultTitle={this.renderSearchResultsTitle}
            renderTitle={renderTitle}
            step={WIZARD_STEPS.SEARCH_RESULTS}
          >
            {({ openNextStep, openPreviousStep, values }) => (
              <SearchResults
                properties={properties}
                selectedPropertyIndex={selectedPropertyIndex}
                openNextStep={openNextStep}
                additional={additional}
                openPreviousStep={openPreviousStep}
                onSubmit={this.onSubmit}
                onPropertySelect={this.onPropertySelect}
                values={values}
              />
            )}
          </Step>
        )}
        {children}
      </Provider>
    )
  }
}

export default class extends React.Component {
  static Step = Step
  static Footer = StepFooter

  render() {
    const { open, handleClose, onSubmit, initialValues, dialogProps, ...props } = this.props
    return (
      open && (
        <Dialog
          {...dialogProps}
          disableBackdropClick
          open
          onClose={handleClose}
          maxWidth="md"
          data-qa="propertySearch-modal"
        >
          <Form
            initialValues={initialValues}
            onSubmit={onSubmit}
            render={({ values, form, handleSubmit, invalid }) => (
              <PropertyWizard
                values={values}
                form={form}
                handleSubmit={handleSubmit}
                invalid={invalid}
                handleClose={handleClose}
                {...props}
              />
            )}
          />
        </Dialog>
      )
    )
  }
}
