import React, { useState, useEffect, SyntheticEvent } from 'react'
import TextField from '@mui/material/TextField'
import Autocomplete from '@mui/material/Autocomplete'
import Chip from '@mui/material/Chip'
import { Field } from 'react-final-form'

const MultiSelectChip = (props: any) => {
  const { options, input, label, meta, displayError, helperText } = props
  const [inputValue, setInputValue] = useState<string>('')
  const [selectedValues, setSelectedValues] = useState<string[]>([])

  const ADD_STRING = 'Add '

  const inErrorState = displayError && meta.touched && !!meta.error
  const errorText = inErrorState ? meta.error : ''

  useEffect(() => {
    if (input.value) {
      setSelectedValues(input.value)
    }
  }, [input.value])

  const handleInputChange = (event: SyntheticEvent, value: string) => {
    setInputValue(value)
  }

  const handleChipDelete = (chipToDelete: string) => () => {
    const newSelectedValues = selectedValues.filter(value => value !== chipToDelete)
    setSelectedValues(newSelectedValues)
    input.onChange(newSelectedValues)
  }

  const handleSelection = (event: SyntheticEvent, values: string[]) => {
    const newValues = values.map(value => (value.startsWith(ADD_STRING) ? value.slice(5, -1) : value))
    setSelectedValues(newValues)
    setInputValue('')
    input.onChange(newValues)
  }

  const handleKeyPress = (event: React.KeyboardEvent) => {
    if (event.key === 'Enter' && inputValue) {
      const newSelectedValues = [...selectedValues, inputValue]
      setSelectedValues(newSelectedValues)
      setInputValue('')
      input.onChange(newSelectedValues)
    }
  }

  return (
    <div data-qa={input.name}>
      <Autocomplete
        multiple
        freeSolo
        disableClearable
        options={options}
        value={input.value || []}
        inputValue={inputValue}
        onInputChange={handleInputChange}
        onChange={handleSelection}
        onFocus={input.onFocus}
        onBlur={input.onBlur}
        renderTags={() => null}
        filterOptions={(options, params) => {
          const filtered = options.filter(option => option.toLowerCase().includes(params.inputValue.toLowerCase()))
          if (params.inputValue !== '' && !filtered.includes(params.inputValue)) {
            filtered.push(`${ADD_STRING}"${params.inputValue}"`)
          }

          return filtered
        }}
        renderInput={params => (
          <TextField
            {...params}
            label={label}
            onKeyDown={handleKeyPress}
            error={inErrorState}
            helperText={errorText || helperText}
            InputProps={{
              ...params.InputProps,
              startAdornment: (
                <>
                  {Array.isArray(input.value) &&
                    input.value.map((value: string) => (
                      <Chip
                        key={value}
                        label={value}
                        onDelete={handleChipDelete(value)}
                        variant="outlined"
                        data-qa="multiselect-chip"
                      />
                    ))}
                  {params.InputProps.startAdornment}
                </>
              ),
            }}
            InputLabelProps={{
              shrink: inputValue.length > 0 || selectedValues.length > 0 || meta.active,
            }}
          />
        )}
      />
    </div>
  )
}

export const MultiSelectChipField = (props: any) => {
  const { name, label } = props
  return <Field name={name} label={label} component={MultiSelectChip} {...props} />
}
