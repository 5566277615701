import { GeneratedText, gtLocked } from '../../../narrative/GeneratedText'

import { mapDTO, mapDataModel } from './mappings'

const generate = () => {
  const gt = new GeneratedText()
  const asCompleteEntrepreneurialIncentive = gtLocked('asCompleteEntrepreneurialIncentive')

  // eslint-disable-next-line max-len
  gt.t`Entrepreneurial incentive represents the expected return or reward to the developer and investors and is separate from contractor's overhead and profit. It is the financial reward that a developer would expect to receive in addition to recovering all direct, indirect and site costs.It is the expected compensation that would motivate a developer to undertake a project.\nBased on the location, asset type and expected developer's profit for similar properties, we have estimated a developer's profit of ${asCompleteEntrepreneurialIncentive} of the reported direct, indirect and site costs. This is in addition to the contingency / interest reserve which is already incorporated into the developer's budget.`

  return gt.getParts()
}

export default { generate, mapDTO, mapDataModel }
