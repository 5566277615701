import createDecorator from 'final-form-calculate'
import { find } from 'lodash'

import { getSalePriceToUse } from 'shared/report-calculations/sales-approach/helpers'
import { IncomeAdjustmentKeys } from 'shared/constants/report/sales/salesAdjustment'

import { getNoiAdjustment } from '../calculations'

export const salesAdjustmentDecorators = [
  createDecorator({
    field: ['incomeAdjustmentLevel'],
    updates: (value, name, allValues) => {
      const {
        selectedComps,
        unitOfComparison,
        netOperatingIncome,
        compAdjustments,
        subjectPropertyInformation,
        temper,
      } = allValues
      for (const [, compAdjustment] of compAdjustments.entries()) {
        const { adjustments, compId } = compAdjustment
        let adjustmentValue = 0
        if (value === IncomeAdjustmentKeys.income && temper) {
          const selectedComp = find(selectedComps, comp => comp._id === compId)
          adjustmentValue = getNoiAdjustment(
            selectedComp.saleInformation.capRate,
            getSalePriceToUse(selectedComp.saleInformation),
            selectedComp.propertyInformation,
            unitOfComparison,
            temper,
            netOperatingIncome.total,
            subjectPropertyInformation
          )
        }
        const stabilizationLevelAdjustment = find(adjustments, adj => adj.name === 'stabilizationLevel')
        if (stabilizationLevelAdjustment) {
          stabilizationLevelAdjustment.value = adjustmentValue
        }
        compAdjustment.adjustments = [...adjustments]
      }
      return {
        compAdjustments: [...compAdjustments],
      }
    },
  }),
  createDecorator({
    field: ['temper'],
    updates: (value, name, allValues) => {
      const { selectedComps, unitOfComparison, netOperatingIncome, compAdjustments, subjectPropertyInformation } =
        allValues
      const temper = value
      for (const [, compAdjustment] of compAdjustments.entries()) {
        const { compId, adjustments } = compAdjustment
        const selectedComp = find(selectedComps, comp => comp._id === compId)
        const noiAdjustment = getNoiAdjustment(
          selectedComp.saleInformation.capRate,
          getSalePriceToUse(selectedComp.saleInformation),
          selectedComp.propertyInformation,
          unitOfComparison,
          temper,
          netOperatingIncome.total,
          subjectPropertyInformation
        )
        const adjustment = find(adjustments, adj => adj.name === 'stabilizationLevel')

        if (!adjustment) {
          adjustments.push({
            groupName: 'other',
            name: 'stabilizationLevel',
            value: noiAdjustment,
          })
        } else {
          adjustment.value = noiAdjustment
        }
        compAdjustment.adjustments = [...adjustments]
      }

      return {
        compAdjustments: [...compAdjustments],
      }
    },
  }),
]
