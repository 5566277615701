import { GeneratedText, gtLocked } from '../../../narrative/GeneratedText'

import { mapDTO, mapDataModel } from './mappings'

const generate = () => {
  const gt = new GeneratedText()
  gt.t`A survey of active lenders in the subject property's influencing market indicates that 25-year and 
  30-year mortgage commitments are typically ${gtLocked('suggestedMortgageMinBasisPoints')} to ${gtLocked(
    'suggestedMortgageMaxBasisPoints'
  )} basis points above 10-year treasuries. 
  Currently, 10-year treasuries are trading at ${gtLocked(
    'tenYearTreasuryBondRate',
    'formatPercentageString'
  )} suggesting mortgage rates of roughly `
  gt.t`${gtLocked('suggestedMortgageMinRate', 'formatPercentageString')} to ${gtLocked(
    'suggestedMortgageMaxRate',
    'formatPercentageString'
  )}. The current macro environment has resulted in higher 
  interest rates, expanding the cost of debt, and weakening demand in the mortgage market.`
  return gt.getParts()
}

export default { generate, mapDTO, mapDataModel }
