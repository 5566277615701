/* eslint-disable max-len */
import { get, isNil, forIn, lowerCase, capitalize } from 'lodash'
import arrayToSentence from 'array-to-sentence'

import { EXPENSE_HISTORY_TYPE_KEYS } from 'shared/constants/expenses/expenseHistory'
import { DEFAULT_EXPENSES_KEYS, NOT_REPORTED_STRING } from 'shared/constants/expenses'
import { getExpense } from 'shared/utils/textGeneration/incomeApproach/expenses'
import { formatCurrencyInt, formatCurrencyFloat } from 'shared/utils/formatters/numberFormatters'
import { toPercents } from 'shared/utils/numberOperations'

import { buildSlateWriteup } from '../writeupBuilder'

import { BasisTypes, perBasisStub } from '../../../constants/report/incomeApproach/expenses'
import ExpensesCalculations from '../../../report-calculations/income-approach/expenses/expenses-calculations'

export const formatCurrencyByBasis = (value, basis) => {
  if (isNil(value)) {
    return '$0'
  }
  return basis === BasisTypes.PER_UNIT ? formatCurrencyInt(value) : formatCurrencyFloat(value)
}

export const buildOrGenerateWriteup = (commentary, generateNewCommentary) => {
  if (commentary) {
    return commentary.modified ? buildSlateWriteup(commentary) : generateNewCommentary()
  }
  return ''
}

export const generateExpenseForecastSentence = (values, isCustomExpense) => {
  const basis = get(values, 'basis', 'sf')
  const forecastedExpense = get(values, 'concludedValue', null)
  const unitsCount = get(values, 'unitsCount')
  const squareFootage = get(values, 'squareFootage')
  const numberOfRooms = get(values, 'totalRoomCount')

  const forecastedAnnualExpense = ExpensesCalculations.calculateTotalFromPerBasis(forecastedExpense, basis, {
    numberOfUnits: unitsCount,
    squareFootage,
    numberOfRooms,
  })

  return `${
    isCustomExpense ? `W` : `Based on the information above, w`
  }e have projected this expense at ${formatCurrencyByBasis(forecastedExpense, basis)} ${
    perBasisStub[basis]
  }, or ${formatCurrencyInt(forecastedAnnualExpense)} annually.`
}

export const generateInsuranceWriteup = values => {
  const basis = get(values, 'basis', 'sf')

  return (
    `Insurance costs vary by the type of coverage. Costs are generally lower ` +
    `(on a ${
      perBasisStub[basis]
    } basis) for larger buildings and for multi-building policies. ${generateExpenseForecastSentence(values)}`
  )
}

export const generateElectricityWriteup = generateExpenseForecastSentence
export const generateWaterAndSewerWriteup = generateExpenseForecastSentence

export const generateFuelWriteup = values => {
  const basis = get(values, 'basis', 'sf')
  const forecastedExpense = get(values, 'concludedValue', null)

  return `Based on the information above, our forecast of heating fuel expense is ${formatCurrencyByBasis(
    forecastedExpense,
    basis
  )} ${perBasisStub[basis]} which reflects investor expectations.`
}

export const generateRepairsAndMaintenanceWriteup = values => {
  return (
    `This expense varies depending on building age, management philosophy, services provided, and accounting methodology. ` +
    `Some management companies expense items that are normally included as capital costs. ` +
    `In addition, repair and maintenance costs may change from year to year; in some cases, repairs that require attention may be postponed due to cash flow considerations. ${generateExpenseForecastSentence(
      values
    )}`
  )
}

export const generatePayrollAndBenefitsWriteup = values => {
  return (
    `Payroll costs will cover building staff whose duties will include trash removal, common area cleaning and general maintenance. ` +
    `Payroll taxes and fringes cover state and federal taxes as well as benefits that building employees receive. ${generateExpenseForecastSentence(
      values
    )}`
  )
}

export const generateGeneralAndAdministrativeWriteup = values => {
  return (
    `This expense allows for any expenditure not included in the above categories including general administrative costs, ` +
    `accounting/legal, permits and dues, miscellaneous charges, office expense, etc. ${generateExpenseForecastSentence(
      values
    )}`
  )
}

export const generateLegalAndProfessionalFeesWriteup = generateExpenseForecastSentence
export const generateMiscellaneousWriteup = generateExpenseForecastSentence
export const generateUtilitiesWriteup = generateExpenseForecastSentence

export const generateManagementWriteup = (values, expenseForecastValues) => {
  let { percentOfEgi } = values
  percentOfEgi = percentOfEgi ? `${toPercents(percentOfEgi)}%` : `${NOT_REPORTED_STRING}%`
  const basis = get(values, 'basis', 'sf')
  const { formattedConclusion, formattedAnnual } = expenseForecastValues

  return (
    `Typically, management fees for similar properties range from 2% to 6% of effective gross income. ` +
    `We have projected this expense at ${percentOfEgi} of effective gross income, ` +
    `which equates to ${formattedConclusion} ${perBasisStub[basis]} ` +
    `or ${formattedAnnual} annually.`
  )
}

export const generateReservesWriteup = values => {
  const basis = get(values, 'basis', 'sf')
  const forecastedExpense = get(values, 'concludedValue', null)
  const numberOfUnits = get(values, 'unitsCount')
  const squareFootage = get(values, 'squareFootage')
  const expenseHistory = get(values, 'expenseHistory.units', [])

  const projection = expenseHistory.find(({ period }) => period === EXPENSE_HISTORY_TYPE_KEYS.PROJECTION)
  const projectedExpenses = get(projection, 'expenses') || []
  const projectedReserves = projectedExpenses.find(expense => expense.id === DEFAULT_EXPENSES_KEYS.reserves) || {}
  const reservesIsProjected = projectedReserves.reported
  const projectedReservesValue = projectedReserves[basis]

  const ownerDidProjectExpense = values.expenseDataSource === 'Owner' && reservesIsProjected

  const forecastedAnnualExpense = ExpensesCalculations.calculateTotalFromPerBasis(forecastedExpense, basis, {
    numberOfUnits,
    squareFootage,
  })

  return (
    'This expense provides for the periodic replacement of building components that wear out more rapidly ' +
    "than the building itself and that must be replaced periodically during the building's economic life. " +
    `${
      ownerDidProjectExpense
        ? `The owner projected this expense at ${formatCurrencyByBasis(projectedReservesValue, basis)} ${
            perBasisStub[basis]
          }. `
        : `We note the owner did not indicate this expense. `
    }` +
    `We have projected this expense at ${formatCurrencyByBasis(forecastedExpense, basis)} ${perBasisStub[basis]}, ` +
    `or ${formatCurrencyInt(forecastedAnnualExpense)} annually.`
  )
}

export const getResponsibilitySentence = expenseHistory => {
  const { expenseStructure, expenseStructureByRentTypes, rentTypes } = expenseHistory

  const tenantExpenses = []
  const ownerExpenses = []
  const tenantBothExpenses = {}
  const ownerBothExpenses = {}

  forIn(expenseStructure, (value, key) => {
    if (['fuel', 'gas', 'electricity', 'commonElectricity'].includes(key)) {
      if (value === 'tenant') {
        tenantExpenses.push(getExpense(key))
      } else if (value === 'owner') {
        ownerExpenses.push(getExpense(key))
      } else if (value === 'varies by rent type') {
        forIn(expenseStructureByRentTypes, (item, rentType) => {
          if (item[key] && item[key] === 'tenant') {
            if (tenantBothExpenses[lowerCase(rentType)]) {
              tenantBothExpenses[lowerCase(rentType)].push(getExpense(key))
            } else {
              tenantBothExpenses[lowerCase(rentType)] = [getExpense(key)]
            }
          } else if (item[key] && item[key] === 'owner') {
            if (ownerBothExpenses[lowerCase(rentType)]) {
              ownerBothExpenses[lowerCase(rentType)].push(getExpense(key))
            } else {
              ownerBothExpenses[lowerCase(rentType)] = [getExpense(key)]
            }
          }
        })
      }
    }
  })

  let tenantSentence = ''
  let ownerSentence = ''
  let displayTenantAlso = ''
  let displayOwnerAlso = ''
  if (tenantExpenses.length > 0) {
    tenantSentence = `The tenant is responsible for ${arrayToSentence(tenantExpenses)}. `
    displayTenantAlso = ' also'
  }

  if (ownerExpenses.length > 0) {
    ownerSentence = `The owner is responsible for ${arrayToSentence(ownerExpenses)}. `
    displayOwnerAlso = ' also'
  }

  let tenantBothSentence = ''
  let ownerBothSentence = ''

  rentTypes.forEach(rentType => {
    if (tenantBothExpenses[rentType]) {
      tenantBothSentence += `${capitalize(
        rentType
      )} tenants are${displayTenantAlso} responsible for their own ${arrayToSentence(tenantBothExpenses[rentType])}. `
    }
    if (ownerBothExpenses[rentType]) {
      ownerBothSentence += `The owner is${displayOwnerAlso} responsible for ${rentType} units' ${arrayToSentence(
        ownerBothExpenses[rentType]
      )}. `
    }
  })
  return `${tenantSentence}${tenantBothSentence}${ownerSentence}${ownerBothSentence}`
}

export const generateElectricityFuelWriteup = ({
  fuelExpenses,
  electricityExpenses,
  expenseHistory,
  squareFootage,
  unitsCount,
}) => {
  const fuelAnnualTotal = ExpensesCalculations.calculateTotalFromPerBasis(
    fuelExpenses.concludedValue,
    fuelExpenses.basis,
    { numberOfUnits: unitsCount, squareFootage }
  )
  const electricityAnnualTotal = ExpensesCalculations.calculateTotalFromPerBasis(
    electricityExpenses.concludedValue,
    electricityExpenses.basis,
    { numberOfUnits: unitsCount, squareFootage }
  )

  const forecastedAnnualExpense = fuelAnnualTotal + electricityAnnualTotal
  const formattedForecastedAnnualExpense = formatCurrencyInt(forecastedAnnualExpense)

  const responsibilitySentence = getResponsibilitySentence(expenseHistory)

  return `${responsibilitySentence}Based on the information above, we have projected this expense at ${formattedForecastedAnnualExpense} annually.`
}
