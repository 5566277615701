import React from 'react'

import { Paper, Grid, Typography } from '@mui/material'

import rentStabilizedDiscussion from 'shared/utils/textGeneration/cms/rentStabilization/rentStabilizedDiscussion'
import { Number as NumberField } from 'client-shared/components'
import { fromPercents, toPercents, precisionRound } from 'client-shared/utils/numberOperations'
import { required } from 'client-shared/utils/validation'

import { get, set } from 'lodash'

import NarrativeComponent from '../../shared/components/NarrativeComponent'

import {
  NYC_RENT_TYPE_ANNUAL_INCREASES,
  DEFAULT_RENT_TYPE_ANNUAL_INCREASES,
} from '../../../../libs/bowery-libs/constants'

import withCMSForm from './withCMSForm'

const GRID_CONTAINER_GUTTER = 2

const RentStabilization = props => {
  const { initialValues, values } = props

  NYC_RENT_TYPE_ANNUAL_INCREASES.forEach(type => {
    set(
      initialValues,
      `rentStabilization.increasesNYC.${type.rentType}`,
      get(initialValues, `rentStabilization.increasesNYC.${type.rentType}`, type.increase)
    )
  })
  DEFAULT_RENT_TYPE_ANNUAL_INCREASES.forEach(type => {
    set(
      initialValues,
      `rentStabilization.increasesDefault.${type.rentType}`,
      get(initialValues, `rentStabilization.increasesDefault.${type.rentType}`, type.increase)
    )
  })

  const calculateEffectiveIncrease = newValue => {
    const yr1 =
      props.active === 'rentStabilization.stabilizedIncreaseTwoYearOnePct'
        ? newValue
        : get(values, 'rentStabilization.stabilizedIncreaseTwoYearOnePct', 0)
    const yr2 =
      props.active === 'rentStabilization.stabilizedIncreaseTwoYearTwoPct'
        ? newValue
        : get(values, 'rentStabilization.stabilizedIncreaseTwoYearTwoPct', 0)
    const value = precisionRound((1 + yr1) * (1 + yr2) - 1, 4)
    console.info(yr1, yr2, value)
    set(values, 'rentStabilization.stabilizedIncreaseTwoYearEffectivePct', value)
  }

  const calculateStabilizedIncreaseAvg = newValue => {
    const yr1 =
      props.active === 'rentStabilization.stabilizedIncreaseOneYearPct'
        ? newValue
        : get(values, 'rentStabilization.stabilizedIncreaseOneYearPct', 0)
    const yr2 =
      props.active === 'rentStabilization.stabilizedIncreaseTwoYearEffectivePct'
        ? newValue
        : get(values, 'rentStabilization.stabilizedIncreaseTwoYearEffectivePct', 0)
    const blendedYr1 = yr1 / 2
    const blendedYr2 = yr2 / 4
    const value = precisionRound(blendedYr1 + blendedYr2, 4)
    set(values, 'rentStabilization.blendedIncreaseOneYear', precisionRound(blendedYr1, 4))
    set(values, 'rentStabilization.blendedIncreaseTwoYear', precisionRound(blendedYr2, 4))
    set(values, 'rentStabilization.stabilizedIncreaseAvgPct', value)
  }

  const normalize = value => {
    const newValue = fromPercents(value)
    calculateEffectiveIncrease(newValue)
    calculateStabilizedIncreaseAvg(newValue)
    return newValue
  }

  return (
    <Paper>
      <Typography variant="h6">Rent Stabilized Increases</Typography>
      <Grid container spacing={GRID_CONTAINER_GUTTER}>
        <Grid item xs={4}>
          <NumberField
            adornment="%"
            decimalScale={2}
            format={toPercents}
            label="One Year Increase"
            name="rentStabilization.stabilizedIncreaseOneYearPct"
            normalize={normalize}
            validate={required}
          />
        </Grid>
        <Grid item xs={4}>
          <NumberField
            adornment="%"
            decimalScale={2}
            format={toPercents}
            label="Two Year Increase - Yr 1"
            name="rentStabilization.stabilizedIncreaseTwoYearOnePct"
            normalize={normalize}
            validate={required}
          />
        </Grid>
        <Grid item xs={4}>
          <NumberField
            adornment="%"
            decimalScale={2}
            format={toPercents}
            label="Two Year Increase - Yr 2"
            name="rentStabilization.stabilizedIncreaseTwoYearTwoPct"
            normalize={normalize}
            validate={required}
          />
        </Grid>
        <Grid item xs={4}>
          <NumberField
            adornment="%"
            decimalScale={2}
            format={toPercents}
            label="Effective Two Year Increase (Calculated)"
            name="rentStabilization.stabilizedIncreaseTwoYearEffectivePct"
            normalize={fromPercents}
            validate={required}
          />
        </Grid>
        <Grid item xs={4}>
          <NumberField
            adornment="%"
            decimalScale={2}
            fixedDecimalScale={true}
            format={toPercents}
            label="Blended Increase - 1 Yr (Calculated)"
            name="rentStabilization.blendedIncreaseOneYear"
            normalize={fromPercents}
            validate={required}
          />
        </Grid>
        <Grid item xs={4}>
          <NumberField
            adornment="%"
            decimalScale={2}
            fixedDecimalScale={true}
            format={toPercents}
            label="Blended Increase - 2 Yr (Calculated)"
            name="rentStabilization.blendedIncreaseTwoYear"
            normalize={fromPercents}
            validate={required}
          />
        </Grid>
        <Grid item xs={4}>
          <NumberField
            adornment="%"
            decimalScale={2}
            fixedDecimalScale={true}
            format={toPercents}
            label="Avg Increase (Calculated)"
            name="rentStabilization.stabilizedIncreaseAvgPct"
            normalize={fromPercents}
            validate={required}
          />
        </Grid>
        <Grid item xs={12}>
          <NarrativeComponent
            data={rentStabilizedDiscussion.mapDataFromFormValues(props.values)}
            generatedText={rentStabilizedDiscussion.generate}
            name="rentStabilization.rentStabilizedDiscussion"
            title="Rent Stabilized Discussion"
          />
        </Grid>
      </Grid>
      <Typography variant="h6">NY Rent Increase Factor</Typography>
      <Grid container spacing={GRID_CONTAINER_GUTTER}>
        {NYC_RENT_TYPE_ANNUAL_INCREASES.map(type => (
          <Grid item xs={3} key={type.rentType}>
            <NumberField
              decimalScale={5}
              label={type.rentType}
              name={`rentStabilization.increasesNYC.${type.rentType}`}
              validate={required}
              initialValue={get(initialValues, `rentStabilization.increasesNYC.${type.rentType}`)}
            />
          </Grid>
        ))}
      </Grid>
      <Typography variant="h6">Default Rent Increase Factor</Typography>
      <Grid container spacing={GRID_CONTAINER_GUTTER}>
        {DEFAULT_RENT_TYPE_ANNUAL_INCREASES.map(type => (
          <Grid item xs={3} key={type.rentType}>
            <NumberField
              decimalScale={4}
              label={type.rentType}
              name={`rentStabilization.increasesDefault.${type.rentType}`}
              validate={required}
              initialValue={get(initialValues, `rentStabilization.increasesDefault.${type.rentType}`)}
            />
          </Grid>
        ))}
      </Grid>
    </Paper>
  )
}

export default withCMSForm(RentStabilization, 'Rent Stabilization')
