import { defineAction } from 'redux-define'

import { DEFAULT_API_ACTION_STATES } from '../../../shared/constants'

export const ORGANIZATION_UPDATE = defineAction('ORGANIZATION_UPDATE', DEFAULT_API_ACTION_STATES)
export const ORGANIZATION_FETCH = defineAction('ORGANIZATION_FETCH', DEFAULT_API_ACTION_STATES)
export const ORGANIZATION_CLEAR = defineAction('ORGANIZATION_CLEAR', DEFAULT_API_ACTION_STATES)

export const ORGANIZATION_USERS_FETCH = defineAction('ORGANIZATION_USERS_FETCH', DEFAULT_API_ACTION_STATES)
export const ORGANIZATION_USERS_CLEAR = defineAction('ORGANIZATION_USERS_CLEAR', DEFAULT_API_ACTION_STATES)
export const ORGANIZATION_USERS_CREATE = defineAction('ORGANIZATION_USERS_CREATE', DEFAULT_API_ACTION_STATES)
export const ORGANIZATION_USERS_UPDATE = defineAction('ORGANIZATION_USERS_UPDATE', DEFAULT_API_ACTION_STATES)
export const ORGANIZATION_USERS_DELETE = defineAction('ORGANIZATION_USERS_DELETE', DEFAULT_API_ACTION_STATES)

export const ORGANIZATION_FORMS_FETCH = defineAction('ORGANIZATION_FORMS', DEFAULT_API_ACTION_STATES)
