import React, { FC } from 'react'
import { Box, Grid, Paper, Stack, Typography } from '@mui/material'

import { CheckboxWithLabel, NarrativeComponent } from 'client-shared/components'
import useFieldValue from 'report/utils/useFieldValue'
import wrapForm from 'report/forms/wrapForm'
import { DEPRECIATION_PATH } from 'shared/constants/report/keysAndDataPaths'
import CostApproachCalculations from 'shared/report-calculations/cost-approach/cost-approach-calculations'

import { formatInt } from 'client-shared/utils/numberFormatters'
import depreciationIntroduction from 'shared/utils/textGeneration/costApproach/depreciation/introduction'
import physicalDepreciation from 'shared/utils/textGeneration/costApproach/depreciation/physical'
import functionalObsolescence from 'shared/utils/textGeneration/costApproach/depreciation/functionalObsolescence'
import externalObsolescence from 'shared/utils/textGeneration/costApproach/depreciation/externalObsolescence'
import estimatedDepreciated from 'shared/utils/textGeneration/costApproach/depreciation/estimatedDepreciation'
import noDepreciation from 'shared/utils/textGeneration/costApproach/depreciation/conclusion'
import totalDepreciation from 'shared/utils/textGeneration/costApproach/depreciation/totalDepreciation'
import { mvsDecorators } from 'report/forms/property/InsurableValue/decorators'

import { ExtendedFormApi } from 'client-shared/utils/form'

import { PAPER_WIDTH, getMvsSiteImprovementsCost } from '../../helpers'

import EstimatedDepreciationTable from './EstimatedDepreciationTable'
import TotalDepreciationTable from './TotalDepreciationTable'
import depreciationDecorators from './decorators'

type DepreciationOwnProps = {
  form: ExtendedFormApi
}

export const Depreciation: FC<DepreciationOwnProps> = ({ form }) => {
  const { values, change, batch } = form
  const { depreciation, asCompleteYear, grossBuildingArea, siteArea, buildingComponents } = values

  const includeDepreciation = useFieldValue('depreciation.includeDepreciation')
  const { replacementCostDepreciations, siteImprovementDepreciation } = depreciation

  const mvsTotalSiteImprovementsCosts = getMvsSiteImprovementsCost(values)

  const buildingComponentValues = buildingComponents?.map(
    (buildingComponent: any) => buildingComponent.replacementCostNew
  )
  const totalMvsConclusionValue = CostApproachCalculations.calculateTotalMvsConclusionValue(buildingComponentValues)

  return (
    <Paper id="depreciation" sx={{ maxWidth: PAPER_WIDTH }}>
      <Stack spacing={5}>
        <Typography variant="h5">Depreciation</Typography>
        <NarrativeComponent
          title="Introduction"
          generatedText={depreciationIntroduction.generate}
          data={depreciationIntroduction.mapDTO({ depreciation, asCompleteYear })}
          name="depreciation.introductionParagraph"
        />
        <Box id="physical-deterioration">
          <NarrativeComponent
            title="Physical Deterioration / Economic Life"
            generatedText={physicalDepreciation.generate}
            data={physicalDepreciation.mapDTO({ depreciation, asCompleteYear })}
            name="depreciation.physicalDepreciationParagraph"
          />
        </Box>
        <Box id="functional">
          <NarrativeComponent
            title="Functional Obsolescence"
            generatedText={functionalObsolescence.generate}
            data={functionalObsolescence.mapDTO({ depreciation, asCompleteYear })}
            name="depreciation.functionalObsolescenceParagraph"
          />
        </Box>
        <Box id="external">
          <NarrativeComponent
            title="External Obsolescence"
            generatedText={externalObsolescence.generate}
            data={externalObsolescence.mapDTO({ depreciation, asCompleteYear })}
            name="depreciation.externalObsolescenceParagraph"
          />
        </Box>
        <Grid>
          <CheckboxWithLabel label="Include Depreciation and Obsolescence?" name="depreciation.includeDepreciation" />
          {includeDepreciation ? (
            <Grid sx={{ marginTop: 2 }}>
              <Grid container spacing={5} direction="row">
                <Grid item xs={12}>
                  <NarrativeComponent
                    title="Estimated Depreciation"
                    name="depreciation.estimatedDepreciationParagraph"
                    generatedText={estimatedDepreciated.generate}
                    data={estimatedDepreciated.mapDTO({ depreciation, asCompleteYear })}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Typography variant="subtitle1">Estimated Replacement Cost Depreciation</Typography>
                  <Typography variant="body1">Gross Building Area: {formatInt(grossBuildingArea)} SF</Typography>
                  <EstimatedDepreciationTable
                    change={change}
                    batch={batch}
                    depreciation={{ replacementCostDepreciations }}
                    field="replacementCostDepreciations[0]"
                    startingValue={totalMvsConclusionValue}
                    squareFeet={grossBuildingArea}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Typography sx={{ paddingTop: '12px' }} variant="subtitle1">
                    Estimated Site Improvement Depreciation
                  </Typography>
                  <Typography variant="body1">Site Area: {formatInt(siteArea)} SF</Typography>
                  <EstimatedDepreciationTable
                    change={change}
                    batch={batch}
                    depreciation={{ siteImprovementDepreciation }}
                    field="siteImprovementDepreciation"
                    startingValue={mvsTotalSiteImprovementsCosts}
                    squareFeet={siteArea}
                  />
                </Grid>
                <Grid item xs={12}>
                  <NarrativeComponent
                    title="Total Depreciation and Obsolescence"
                    generatedText={totalDepreciation.generate}
                    name="depreciation.totalDepreciationParagraph"
                  />
                </Grid>
                <Grid item xs={12}>
                  <TotalDepreciationTable
                    depreciation={{ siteImprovementDepreciation, replacementCostDepreciations }}
                    grossBuildingArea={grossBuildingArea}
                    mvsTotalSiteImprovementsCosts={mvsTotalSiteImprovementsCosts}
                    totalMvsConclusionValue={totalMvsConclusionValue}
                  />
                </Grid>
              </Grid>
            </Grid>
          ) : (
            <Grid sx={{ marginTop: 2 }}>
              <NarrativeComponent
                title="Depreciation Conclusion"
                generatedText={noDepreciation.generate}
                data={noDepreciation.mapDTO({ depreciation, asCompleteYear })}
                name="depreciation.depreciationConclusionParagraph"
              />
            </Grid>
          )}
        </Grid>
      </Stack>
    </Paper>
  )
}

export default wrapForm(DEPRECIATION_PATH, {
  heading: 'Depreciation',
  decorators: [...depreciationDecorators, ...mvsDecorators],
})(Depreciation)
