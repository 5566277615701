import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import { toUpper, isNil, startCase, get, partition, forEach, find, min, max, mean } from 'lodash'
import { compose, mapProps, pure } from 'recompose'
import { withStyles } from '@mui/styles'
import { Grid, Typography, Paper, IconButton, Tooltip } from '@mui/material'

import EditIcon from '@mui/icons-material/Edit'
import DeleteIcon from '@mui/icons-material/Delete'
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined'

import { FeatureToggle } from '@bowery-valuation/feature-flagger-client'

import AutomationStatus from 'client-shared/components/AutomationStatus'
import { EXPENSE_HISTORY_TYPE_TITLES } from 'shared/constants/expenses/expenseHistory'
import { FEATURE_FLAG_AUTOMATED_EXPENSE_FORECAST } from 'shared/constants/featureFlags'
import { formatExpenseId } from 'shared/helpers/incomeApproach/expenses/helpers'

import { toPercents, fromPercents } from '../../../../shared/utils/numberOperations'
import { Number, RadioButtonList, CheckboxWithLabel } from '../../../../shared/components'
import NarrativeComponent from '../../../../shared/components/NarrativeComponent'

import { getNonSelectedBasisForecast } from '../../../../../../shared/utils/report/incomeApproach/expenses'

import { BasisTypes } from '../../../../../../shared/constants/report/incomeApproach/expenses'

import { DEFAULT_EXPENSES_KEYS, ADDITIONAL_EXPENSES_KEYS } from '../../../../../../shared/constants/expenses'

import { DEFAULT_BASIS_OPTIONS, ExpenseForecastWriteupGenerators } from './constants'
import ForecastItemLines from './ForecastItemLines'
import { getForecastDiscussionChipValues, generateCustomExpenseForecastWriteup } from './getExpenseForecastWriteup'

import { styles } from './styles'
import { formatCurrencyByBasis } from './tools'

export const getExpensePeriodLabel = (expenseYear, expensePeriod, expenseDataSource) => {
  switch (expensePeriod) {
    case EXPENSE_HISTORY_TYPE_TITLES.ACTUAL:
    case EXPENSE_HISTORY_TYPE_TITLES.ANNUALIZED_HISTORICAL:
      return `${expenseYear} ${startCase(expensePeriod)}`
    case EXPENSE_HISTORY_TYPE_TITLES.ACTUAL_T_12:
      const displayYear = expenseYear ? expenseYear - 1 : ''
      return `${displayYear} - ${expenseYear} Actual T12`
    case EXPENSE_HISTORY_TYPE_TITLES.PROJECTION:
      return `${startCase(expenseDataSource)}'s Projection`
    default:
      return ''
  }
}

const getExpense = (expenses, category) => {
  return find(expenses, exp => {
    return exp.id === category
  })
}

const ForecastItem = ({
  form,
  classes,
  category,
  categoryName,
  basis,
  basisOptions,
  percentOfEgi,
  percentOfEgiEnabled,
  isManagementItem,
  isFuelItem,
  concludedValue,
  expenseDataSource,
  expenseHistory,
  totalRoomCount,
  numberOfUnits,
  isCustom,
  handleUpdate,
  forecastItemIndex,
  handleDelete,
  automationMetadata,
  onAutomationSourceLinkClick,
}) => {
  const historicalExpenses = useMemo(() => {
    const getRecentHistoricalExpenses = (history, catName, basis) => {
      const recentHistoricalExpenses = []
      const expHistory = history.expenseHistory
      forEach(expHistory, ({ expenseYear, expensePeriod, expenses }) => {
        const expense = getExpense(expenses, catName)
        const value = get(expense, `${basis}`)
        if (value && expenseYear) {
          recentHistoricalExpenses.push({
            year: expenseYear,
            expense: value,
            period: expensePeriod,
          })
        }
      })
      return recentHistoricalExpenses
    }
    return getRecentHistoricalExpenses(expenseHistory, formatExpenseId(categoryName), basis).sort(
      (expenseA, expenseB) => expenseA.year - expenseB.year
    )
  }, [basis, categoryName, expenseHistory])

  const comparableExpenses = form.values.mappedComparableExpenses
  const { compMin, compAvg, compMax } = useMemo(() => {
    const comparableExpenseBasis = comparableExpenses
      .filter(comp => {
        const compExpenses = get(comp, 'expenses', [])
        const compExpense = getExpense(compExpenses, formatExpenseId(categoryName))
        if (!compExpense) {
          return false
        }
        const notNil = !isNil(get(compExpense, `${basis}`))
        return compExpense.reported && notNil
      })
      .map(comp => {
        const compExpenses = get(comp, 'expenses', [])
        const compExpense = getExpense(compExpenses, formatExpenseId(categoryName))
        return get(compExpense, `${basis}`)
      })

    return {
      compMin: min(comparableExpenseBasis),
      compAvg: mean(comparableExpenseBasis),
      compMax: max(comparableExpenseBasis),
    }
  }, [basis, categoryName, comparableExpenses])

  const formatCurrencyBySelectedBasis = formatCurrencyByBasis(basis)

  const [[selectedBasis], nonselectedBases] = partition(basisOptions, ({ value }) => value === basis)

  const forecastDiscussionChipsValues = getForecastDiscussionChipValues(category, form.values, expenseHistory)
  const forecastDiscussionGenerator = ExpenseForecastWriteupGenerators[category] || generateCustomExpenseForecastWriteup

  const tooltipText = 'Unchecking this box will hide the expense from showing up on the Pro Forma.'

  const squareFootage = form.values.squareFootage

  const getContentLibraryTemplateTitle = () => {
    if (isCustom) {
      return 'expense_forecast_' + categoryName
    }

    return null
  }

  const automationMessage = useMemo(() => {
    const recentSimilarReports = get(automationMetadata, 'sourceLinks', [])
    const expenseHistoryUnits = get(expenseHistory, 'expenseHistory', [])
    if (recentSimilarReports.length && expenseHistoryUnits.length) {
      return 'We automated the information below from Expense History and similar Bowery appraisals'
    }
    if (recentSimilarReports.length && !expenseHistoryUnits.length) {
      return 'We automated the information below from similar Bowery appraisals'
    }
    if (!recentSimilarReports.length && expenseHistoryUnits.length) {
      return 'We automated the information below using Expense History'
    }
    return 'There is no data to automate from'
  }, [automationMetadata, expenseHistory])

  return (
    <Paper sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
      <FeatureToggle featureFlag={FEATURE_FLAG_AUTOMATED_EXPENSE_FORECAST}>
        {automationMetadata && (
          <AutomationStatus
            formPaths={['expenseForecastAutomationMetadata']}
            message={automationMessage}
            onSourceLinkClick={onAutomationSourceLinkClick}
            sx={{ marginTop: '16px', marginBottom: '16px' }}
          />
        )}
      </FeatureToggle>
      <Grid container spacing={2} data-qa={`${isCustom ? categoryName : category}-forecast-item`}>
        <Grid item container md={4} sm={12}>
          <Grid item xs={12}>
            <Typography variant="subtitle1">{toUpper(categoryName)}</Typography>
          </Grid>
          {!isCustom && (
            <Grid item xs={12} sx={{ display: 'flex', alignItems: 'center' }}>
              <CheckboxWithLabel
                fullWidth={false}
                name={`${category}.includeInProForma`}
                label="Include Expense on Pro Forma"
              />
              <Tooltip title={tooltipText} placement="right" sx={{ ml: -1 }}>
                <InfoOutlinedIcon fontSize="inherit" />
              </Tooltip>
            </Grid>
          )}
          <Grid item xs={12}>
            <RadioButtonList name={`${category}.basis`} items={basisOptions} horizontal />
          </Grid>
          <Grid item xs={12} mb={2}>
            {historicalExpenses &&
              historicalExpenses.map((historicalExpense, index) => {
                const yearLabel = getExpensePeriodLabel(
                  historicalExpense.year,
                  historicalExpense.period,
                  expenseDataSource
                )
                return (
                  <Typography key={index} variant="body2" data-qa={`${historicalExpense.year}-historical`}>
                    {`${yearLabel}: `}
                    {formatCurrencyBySelectedBasis(historicalExpense.expense)}
                  </Typography>
                )
              })}
            <Typography variant="body2" data-qa="comp-min">
              Comp Min: {formatCurrencyBySelectedBasis(compMin)}
            </Typography>
            <Typography variant="body2" data-qa="comp-avg">
              Comp Avg: {formatCurrencyBySelectedBasis(compAvg)}
            </Typography>
            <Typography variant="body2" data-qa="comp-max">
              Comp Max: {formatCurrencyBySelectedBasis(compMax)}
            </Typography>
          </Grid>
          {isManagementItem && (
            <Grid item xs={12}>
              <CheckboxWithLabel name={`${category}.percentOfEgiEnabled`} label="Input % of EGI" />
              {percentOfEgiEnabled && (
                <Number
                  name={`${category}.percentOfEgi`}
                  label="% of EGI"
                  className={classes.basisValue}
                  format={toPercents}
                  normalize={fromPercents}
                  adornment="%"
                  decimalScale={2}
                />
              )}
            </Grid>
          )}
          <Grid item xs={12}>
            <Number
              name={`${category}.concludedValue`}
              label="Appraiser's Forecast"
              className={classes.basisValue}
              prefix="$"
              disabled={percentOfEgiEnabled}
              decimalScale={2}
              thousandSeparator
              data-qa="concluded-value"
            />
            {nonselectedBases.map(nonselectedBasis => {
              return (
                <Typography variant="body2" data-qa="basis">
                  {nonselectedBasis.label}:{' '}
                  {formatCurrencyByBasis(nonselectedBasis.value)(
                    getNonSelectedBasisForecast(
                      selectedBasis.value,
                      nonselectedBasis.value,
                      concludedValue,
                      totalRoomCount,
                      squareFootage,
                      numberOfUnits
                    )
                  )}
                </Typography>
              )
            })}
            {isManagementItem && !percentOfEgiEnabled && (
              <Typography variant="caption" data-qa="effective-gross-income">
                {toPercents(percentOfEgi)}% of Effective Gross Income
              </Typography>
            )}
          </Grid>
        </Grid>
        <Grid item container wrap="nowrap" direction="column" md={7} sm={11}>
          <Grid item>
            <Typography align="center" variant="subtitle1" mb={2}>{`${toUpper(categoryName)} ($/${
              toUpper(basis) || ''
            })`}</Typography>
          </Grid>
          <Grid item>
            <ForecastItemLines
              formatter={formatCurrencyBySelectedBasis}
              basisValue={concludedValue}
              recentHistoricalExpenses={historicalExpenses}
              compMin={compMin}
              compAvg={compAvg}
              compMax={compMax}
              expenseDataSource={expenseDataSource}
            />
          </Grid>
        </Grid>
        {isCustom && (
          <Grid item container wrap="nowrap" direction="column" xs={1}>
            <Grid container item direction="row" justifyContent="flex-end">
              <IconButton onClick={() => handleUpdate(forecastItemIndex)}>
                <EditIcon fontSize="small" />
              </IconButton>
              <IconButton onClick={() => handleDelete(forecastItemIndex)}>
                <DeleteIcon fontSize="small" />
              </IconButton>
            </Grid>
          </Grid>
        )}
      </Grid>

      <NarrativeComponent
        title={`${categoryName} Forecast Discussion`}
        generatedText={forecastDiscussionGenerator}
        data={forecastDiscussionChipsValues}
        name={`${category}.discussion`}
        contentLibraryTemplateTitle={getContentLibraryTemplateTitle()}
      />

      {isFuelItem && (
        <NarrativeComponent
          title="Electricity & Fuel Forecast Discussion"
          generatedText={ExpenseForecastWriteupGenerators[ADDITIONAL_EXPENSES_KEYS.electricityFuel]}
          data={getForecastDiscussionChipValues(ADDITIONAL_EXPENSES_KEYS.electricityFuel, form.values, expenseHistory)}
          name={`${ADDITIONAL_EXPENSES_KEYS.electricityFuel}.discussion`}
        />
      )}
    </Paper>
  )
}

ForecastItem.propTypes = {
  form: PropTypes.object,
  basis: PropTypes.string,
  compMin: PropTypes.number,
  compAvg: PropTypes.number,
  compMax: PropTypes.number,
  basisValue: PropTypes.number,
  percentOfEgi: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  concludedValue: PropTypes.number,
  percentOfEgiEnabled: PropTypes.bool,
  category: PropTypes.string.isRequired,
  categoryName: PropTypes.string.isRequired,
  recentHistoricalExpenses: PropTypes.arrayOf(
    PropTypes.shape({
      expense: PropTypes.number.isRequired,
      period: PropTypes.string.isRequired,
      year: PropTypes.number.isRequired,
    })
  ),
  basisOptions: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      value: PropTypes.string.isRequired,
    })
  ).isRequired,
  expenseHistory: PropTypes.object,
  isCustom: PropTypes.bool,
  handleUpdate: PropTypes.func,
  handleDelete: PropTypes.func,
  automationMetadata: PropTypes.object,
  onAutomationSourceLinkClick: PropTypes.func,
}

ForecastItem.defaultProps = {
  basis: null,
  compMin: null,
  compAvg: null,
  compMax: null,
  basisValue: null,
  percentOfEgi: null,
  concludedValue: null,
  percentOfEgiEnabled: false,
  recentHistoricalExpenses: [],
  isCustom: false,
}

export default compose(
  pure,
  withStyles(styles),
  mapProps(({ category, totalRoomCount, numberOfUnits, selectedExpenseForecast, expenseForecastBasis, ...other }) => {
    const { basis: selectedBasis } = selectedExpenseForecast
    const basisOptions = [...DEFAULT_BASIS_OPTIONS]

    if (category === DEFAULT_EXPENSES_KEYS.fuel) {
      basisOptions.push({ value: BasisTypes.PER_ROOM, label: 'Per Room' })
    }

    return {
      ...other,
      ...selectedExpenseForecast,
      ...get(expenseForecastBasis, selectedBasis, {}),
      category,
      basisOptions,
      totalRoomCount,
      numberOfUnits,
      isManagementItem: category === DEFAULT_EXPENSES_KEYS.management,
      isFuelItem: category === DEFAULT_EXPENSES_KEYS.fuel,
    }
  })
)(ForecastItem)
