import React from 'react'
import { connect } from 'react-redux'

import {
  Box,
  Card,
  Typography,
  Stack,
  Button,
  Chip,
  MenuItem,
  ListItemIcon,
  ListItemText,
  Popper,
  ClickAwayListener,
  Fade,
  Paper,
} from '@mui/material'
import { Value } from 'slate'
import { cloneDeep, get, truncate } from 'lodash'
import classNames from 'classnames'
import ArchiveOutlinedIcon from '@mui/icons-material/ArchiveOutlined'
import UnarchiveOutlinedIcon from '@mui/icons-material/UnarchiveOutlined'
import ModeEditOutlineOutlinedIcon from '@mui/icons-material/ModeEditOutlineOutlined'
import { makeStyles } from '@mui/styles'

import { buildSlateWriteup } from 'shared/utils/textGeneration/writeupBuilder'
import { HighlightSubstrings } from 'client-shared/components/NarrativeComponent/helpers'
import Locked from 'client-shared/components/NarrativeComponent/plugins/Locked/Locked'
import { CreateNarrativeModal } from 'client-shared/components/NarrativeComponent/CreateNarrativeModal'
import MoreButton from 'client-shared/components/_mui5/MoreButton'
import { contentReuseTemplate } from 'client-shared/types'

import HighlightSearchQuery from 'client-shared/components/NarrativeComponent/HighlightSearchQuery'
import { Theme } from 'core/theme'

import { selectNarrativeTemplate, updateNarrativeTemplate } from 'client-shared/redux/actions/contentLibraryData'

import { LayoutZIndexes } from '../../../stylesConstants'

import AvatarWithText from './AvatarWithTextComponent'
import { formatTime } from './helpers'

const useStyles = makeStyles<Theme>(({ palette }) => ({
  activeTemplate: {
    border: '2px solid rgba(78, 86, 100, 1)',
  },
  activeTemplateChip: {
    background: '#4E5664',
    width: 'fit-content',
    height: '26px',
    borderRadius: '4px',
    color: palette.common.white,
    marginBottom: '4px',
  },
  viewMore: {
    color: palette.primary.main,
  },
}))

const mapStateToProps = (state: any) => {
  return {
    username: get(state, 'authentication.user.username', ''),
    activeTemplateName: get(state, 'shared.contentLibraryData.activeTemplateName'),
  }
}

const mapDispatchToProps = { updateNarrativeTemplate, selectNarrativeTemplate }

type OwnProps = {
  template: contentReuseTemplate
  searchQuery: string
}

type Props = OwnProps & ReturnType<typeof mapStateToProps> & typeof mapDispatchToProps

const narrativeChipRegex = /\[[-0-9a-z\s]+\]/gi
const narrativeTrimRegex = / (?![^[]*\])/g // match all spaces, except the ones that are in-between square brackets.

export const ContentCard: React.FC<Props> = ({
  template,
  searchQuery,
  username,
  activeTemplateName,
  selectNarrativeTemplate,
  updateNarrativeTemplate,
}) => {
  const narrative = Value.fromJSON(template.data)
  const narrativeString = buildSlateWriteup({ narrative }).join(' ')

  const isCurrentAuthorsTemplate = username === template.author.username
  const isArchivedTemplate = !!template.metadata.isArchived
  const archiveActionTitle = isArchivedTemplate ? 'Restore' : 'Archive'
  const isActiveTemplate = activeTemplateName === template.name

  const [editModalIsOpen, setEditModalIsOpen] = React.useState(false)
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)
  const [popperIsOpened, setPopperIsOpened] = React.useState(false)
  const cardRef = React.useRef<HTMLDivElement>(null)
  const openEditModal = () => setEditModalIsOpen(true)
  const closeEditModal = () => setEditModalIsOpen(false)

  const handleClickIn = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(cardRef.current)
    setPopperIsOpened(true)
  }

  const handleClickOut = () => {
    setAnchorEl(null)
    setPopperIsOpened(false)
  }

  const trimmedText = truncate(narrativeString, { length: 140, separator: narrativeTrimRegex })

  const classes = useStyles()

  const updateTemplate = (newTitle: string) => {
    const updatedTemplate = {
      ...template,
      title: newTitle,
    }

    updateNarrativeTemplate(updatedTemplate)
  }

  const archiveTemplate = () => {
    const updatedTemplate = cloneDeep(template)
    updatedTemplate.metadata.isArchived = !isArchivedTemplate

    updateNarrativeTemplate(updatedTemplate)
  }

  return (
    <>
      <Card
        ref={cardRef}
        className={classNames({ [classes.activeTemplate]: isActiveTemplate })}
        sx={{ padding: 3, marginLeft: 3, marginRight: 3, marginBottom: 2 }}
      >
        <Stack direction="column" spacing={1}>
          <Box style={{ opacity: isArchivedTemplate ? 0.5 : 1 }}>
            {isActiveTemplate && (
              <Chip
                className={classes.activeTemplateChip}
                label={<Typography variant="caption">Currently Used</Typography>}
              />
            )}
            <Typography data-qa="content-library-card-title" variant="subtitle2">
              <HighlightSearchQuery text={template.title} searchQuery={searchQuery} />
            </Typography>
            <Typography variant="caption">Updated {formatTime(template.updatedAt)}</Typography>
            <Typography
              variant="body1"
              pb={2}
              onClick={handleClickIn}
              style={{
                display: '-webkit-box',
                WebkitLineClamp: 3,
                WebkitBoxOrient: 'vertical',
                overflow: 'hidden',
                cursor: 'pointer',
              }}
            >
              {searchQuery ? (
                <HighlightSearchQuery text={trimmedText} searchQuery={searchQuery} />
              ) : (
                <HighlightSubstrings
                  text={trimmedText}
                  search={narrativeChipRegex}
                  renderMatch={match => <Locked attributes={{ displayName: match }}>{match}</Locked>}
                />
              )}
              <span className={classes.viewMore}> View More</span>
            </Typography>
            <Popper
              id="text-popper"
              open={popperIsOpened}
              anchorEl={anchorEl}
              placement="left"
              style={{ zIndex: LayoutZIndexes.NAVIGATION_SIDE_BAR, maxWidth: '25vw' }}
              transition
            >
              {({ TransitionProps }) => (
                <ClickAwayListener onClickAway={handleClickOut}>
                  <Fade {...TransitionProps} timeout={350}>
                    <Paper>
                      <Typography variant="body1" pb={2}>
                        <HighlightSubstrings
                          text={narrativeString}
                          search={narrativeChipRegex}
                          renderMatch={match => <Locked attributes={{ displayName: match }}>{match}</Locked>}
                        />
                      </Typography>
                    </Paper>
                  </Fade>
                </ClickAwayListener>
              )}
            </Popper>
            <Chip label="Narrative" />
          </Box>

          <Stack direction="row" justifyContent="space-between" alignItems="center">
            <AvatarWithText
              text={<HighlightSearchQuery text={template.author.fullName} searchQuery={searchQuery} />}
              src={template.author.avatarUrl}
              initials={template.author.initials}
            />
            <Stack direction="row" spacing={1}>
              <Button
                variant="outlined"
                disabled={isArchivedTemplate}
                onClick={() => selectNarrativeTemplate(template)}
              >
                Use Block
              </Button>
              <MoreButton>
                <MenuItem disabled={!isCurrentAuthorsTemplate || isArchivedTemplate} onClick={openEditModal}>
                  <ListItemIcon>
                    <ModeEditOutlineOutlinedIcon />
                  </ListItemIcon>
                  <ListItemText>Edit Content Name</ListItemText>
                </MenuItem>
                <MenuItem disabled={!isCurrentAuthorsTemplate} onClick={archiveTemplate}>
                  <ListItemIcon>
                    {isArchivedTemplate ? <UnarchiveOutlinedIcon /> : <ArchiveOutlinedIcon />}
                  </ListItemIcon>
                  <ListItemText>{archiveActionTitle} Content</ListItemText>
                </MenuItem>
              </MoreButton>
            </Stack>
          </Stack>
        </Stack>
      </Card>

      <CreateNarrativeModal
        opened={editModalIsOpen}
        initialData={{ title: template.title }}
        onSave={updateTemplate}
        onClose={closeEditModal}
      />
    </>
  )
}

export default connect(mapStateToProps, mapDispatchToProps)(ContentCard)
