import { handleActions } from 'redux-actions'
import { get, mapValues, isObject } from 'lodash'

import {
  cmsOrganizationFetchRequest,
  cmsOrganizationFetchReceive,
  cmsOrganizationFetchFail,
  cmsOrganizationUpdateReceive,
  cmsOrganizationUpdateFail,
} from '../actions/cms'

const initialState = {
  organizationData: {},
  cmsStructure: {},
}

const getOrganizationStatusInfo = cmsStructure => {
  return mapValues(cmsStructure, value => {
    if (isObject(value)) {
      return getOrganizationStatusInfo(value)
    } else {
      return {
        showForm: value,
        isValid: false,
        isCompleted: false,
      }
    }
  })
}

export const reducer = handleActions(
  {
    [cmsOrganizationFetchRequest](state) {
      return state
    },
    [cmsOrganizationFetchReceive](state, { payload }) {
      const organizationInfo = getOrganizationStatusInfo(payload.cmsStructure)
      const organization = get(payload, 'organizationData')

      return {
        ...state,
        organizationData: organization,
        cmsStructure: organizationInfo,
      }
    },
    [cmsOrganizationFetchFail](state) {
      return state
    },
    [cmsOrganizationUpdateReceive](state, { payload }) {
      return {
        ...state,
        organizationData: payload,
      }
    },
    [cmsOrganizationUpdateFail](state) {
      return state
    },
  },
  initialState
)
