import { mapProps } from 'recompose'

import { ExportStatuses } from '../../../../../../shared/constants/export'

import { PROGRESS_BAR_COLORS } from './constants'

import { getApproximateReportGenerationTime } from './selectors'

import ExportItemProgressBar from './ExportItemProgressBar'

export default mapProps(({ exportItem, ...props }) => ({
  ...props,
  ...getApproximateReportGenerationTime(exportItem),
  isPendingStatus: exportItem.status === ExportStatuses.PENDING,
  progressBarColor: PROGRESS_BAR_COLORS[exportItem.status],
}))(ExportItemProgressBar)
