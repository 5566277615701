import { ADJUSTMENT_GROUPS } from 'shared/helpers/salesApproach/salesCompAdjustmentConstants'
import { mapDataModelForAdjustmentsNarrative } from 'shared/utils/textGeneration/salesApproach'

export const mapDTO = formValues => {
  return mapDataModelForAdjustmentsNarrative(formValues, 'financingTerms', ADJUSTMENT_GROUPS.MARKET)
}

export const mapDataModel = (report, parent) => {
  if (!parent.parent()) {
    return undefined
  }

  const salesApproach = parent.parent()
  const { salesComps = {}, salesCompsAdjustments = {} } = salesApproach
  const { selectedComps = [] } = salesComps
  const { compAdjustments = [] } = salesCompsAdjustments
  return mapDataModelForAdjustmentsNarrative(
    { selectedComps, compAdjustments },
    'financingTerms',
    ADJUSTMENT_GROUPS.MARKET
  )
}
