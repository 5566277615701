import React from 'react'
import PropTypes from 'prop-types'

import { get } from 'lodash'

import { Paper, Stack, Typography } from '@mui/material'

import { Template } from 'client-shared/components/Template'
import { CheckboxWithLabel } from 'client-shared/components'

import { PRO_FORMA_PATH } from 'shared/constants/report/keysAndDataPaths'

import wrapForm from '../../wrapForm'

import { ProFormaTable } from './ProFormaTable'

export const DATA_PATH = PRO_FORMA_PATH

const heading = 'Pro Forma'

const ProForma = ({ form, propertyType }) => {
  const { includePercentageOfPgiAndEgi } = form.values

  return (
    <Stack spacing={2} sx={{ maxWidth: 1400 }}>
      <Paper>
        <Template is="auto-flow / auto auto" placeContent="center start" gap={2}>
          <Stack spacing={1}>
            <Typography variant="h6" gutterBottom>
              NOI Comparison Table
            </Typography>
            <CheckboxWithLabel label="Include NOI Comparison Table in Export" name="includeNOIComparison" />
          </Stack>
          <Stack spacing={1}>
            <Typography variant="h6" gutterBottom>
              % of PGI and EGI Columns
            </Typography>
            <CheckboxWithLabel label="Include % of PGI and EGI Columns in Export" name="includePercentageOfPgiAndEgi" />
          </Stack>
        </Template>
      </Paper>
      <Paper>
        <ProFormaTable propertyType={propertyType} includePercentageOfPgiAndEgi={includePercentageOfPgiAndEgi} />
      </Paper>
    </Stack>
  )
}

ProForma.propTypes = {
  form: PropTypes.object.isRequired,
  propertyType: PropTypes.string.isRequired,
}

export default wrapForm(DATA_PATH, { heading }, state => {
  return {
    propertyType: get(state, 'report.reportData.propertyType'),
  }
})(ProForma)
