import React from 'react'

import {
  Box,
  Card,
  CardContent,
  CardHeader,
  IconButton,
  Stack,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Tooltip,
} from '@mui/material'
import ErrorOutline from '@mui/icons-material/ErrorOutline'
import { DragDropContext } from 'react-beautiful-dnd'
import Cancel from '@mui/icons-material/Cancel'
import Edit from '@mui/icons-material/Edit'

import { ChildrenLock } from 'client-shared/components/DragDropTable/helpers'
import { DraggableTableRow, DroppableTable } from 'client-shared/components/DragDropTable'
import { formatCurrencyFloat, formatInt } from 'client-shared/utils/numberFormatters'
import { getRentPerSF } from 'shared/calculations/commercial'
import { getSourceOfInformation } from 'client-shared/components/SourceOfInformation/SourceOfInformation'
import { handleOtherUseLabel } from 'shared/utils/formatters/textFormatters'
import { shortDateFormat } from 'client-shared/utils/dateHelper'

import { UNIT_USES_MAP } from 'report/constants'

import Badge from 'report/forms/sales/SalesCompsSearch/Badge'

import { Labels } from './constants'

const RentCompGroupsView = ({
  compGroups,
  onDragEnd,
  onDragStart,
  onRemoveComp,
  openEditModal,
  rentPsfLabel,
  rentRollRentBasis,
}) => (
  <DragDropContext onDragStart={onDragStart} onDragEnd={onDragEnd}>
    <Stack spacing={2}>
      {compGroups.map(compGroup => (
        <Card key={compGroup._id} data-qa={`${compGroup.name}-group-panel`}>
          <CardHeader title={compGroup.name} />
          <CardContent>
            <DroppableTable
              emptyTableMessage={Labels.DROP_ANY_RENT_ROLL_UNIT_HERE}
              id={compGroup._id}
              isEmpty={!compGroup.units.length}
            >
              <TableHead>
                <TableRow>
                  <ChildrenLock>
                    <TableCell>Move</TableCell>
                    <TableCell>Unit Address</TableCell>
                    <TableCell>Status</TableCell>
                    <TableCell>Use</TableCell>
                    <TableCell>Tenant Name</TableCell>
                    <TableCell>Date Signed</TableCell>
                    <TableCell>Square Feet</TableCell>
                    <TableCell>{rentPsfLabel}</TableCell>
                    <TableCell>Source of Information</TableCell>
                    <TableCell>Actions</TableCell>
                  </ChildrenLock>
                </TableRow>
              </TableHead>
              <TableBody>
                {compGroup.units.map((unit, unitIndex) => {
                  const draggableId = unit.leaseId
                  const displayOutdated = !unit.isLatestVersion
                  const status = unit.status
                  return (
                    <DraggableTableRow key={unit._id} id={draggableId} index={unitIndex}>
                      <TableCell data-qa="address-cell">
                        {unit.fullAddressWithZip}{' '}
                        {displayOutdated && (
                          <Tooltip placement="top" title="Outdated">
                            <Box component="span" sx={{ position: 'relative', top: 4, left: 8 }}>
                              <ErrorOutline color="error" fontSize="small" />
                            </Box>
                          </Tooltip>
                        )}
                      </TableCell>
                      <TableCell data-qa="status-cell">{status && <Badge type={status} />}</TableCell>
                      <TableCell data-qa="use-cell">
                        {handleOtherUseLabel(UNIT_USES_MAP, unit.use === 'other' ? unit.otherUse : unit.use)}
                      </TableCell>
                      <TableCell data-qa="tenantName-cell">{unit.tenantName}</TableCell>
                      <TableCell data-qa="dateSigned-cell">{shortDateFormat(unit.dateSigned)}</TableCell>
                      <TableCell data-qa="squareFeet-cell">{formatInt(unit.squareFeet)}</TableCell>
                      <TableCell data-qa="rentPerSF-cell">
                        {formatCurrencyFloat(getRentPerSF(unit, rentRollRentBasis))}
                      </TableCell>
                      <TableCell data-q="sourceOfInfo-cell">{getSourceOfInformation(unit)}</TableCell>
                      <TableCell data-qa="actions-cell">
                        <Stack direction="row" spacing={1}>
                          <IconButton onClick={() => openEditModal(unit)}>
                            <Edit />
                          </IconButton>
                          <IconButton data-qa="remove-comp-btn" onClick={() => onRemoveComp(unit)}>
                            <Cancel color="error" />
                          </IconButton>
                        </Stack>
                      </TableCell>
                    </DraggableTableRow>
                  )
                })}
              </TableBody>
            </DroppableTable>
          </CardContent>
        </Card>
      ))}
    </Stack>
  </DragDropContext>
)

export default RentCompGroupsView
