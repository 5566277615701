import { startCase } from 'lodash'
import ObjectID from 'bson-objectid'

import { SourceInformationLabels, SourceInformationKeys } from 'shared/constants/report'
import { getSiteName } from 'shared/utils'

import { ResidentialLeaseOutput } from '../../server/app/core/infrastructure/compplex/types'

const mapAmenity = (amenity: string): string => {
  if (amenity === 'inUnitLaundry') {
    return 'unitLaundry'
  }
  return amenity
}

const getSourceOfInformation = (
  resourceInformation: any
): {
  sourceName: string
  sourceOfInformation: string
  sourceUrl: string
} => {
  const { sources = [] } = resourceInformation
  const firstSource = sources[0] || {}
  let sourceName = ''
  let sourceUrl = ''
  if (!firstSource) {
    return { sourceName, sourceOfInformation: sourceName, sourceUrl }
  }
  if (firstSource.type === SourceInformationKeys.externalDatabase) {
    const { type, url } = firstSource
    if (!type && !url) {
      sourceName = SourceInformationLabels.externalDatabase
    } else if (type && !url) {
      sourceName = startCase(type)
    } else if (type && url) {
      const urlToUse = url.indexOf('://') === -1 ? `https://${url}` : url
      sourceName = startCase(type)
      sourceUrl = urlToUse
    } else if (!type && url) {
      const urlToUse = url.indexOf('://') === -1 ? `https://${url}` : url
      sourceName = getSiteName(urlToUse) || SourceInformationLabels.externalDatabase
      sourceUrl = urlToUse
    }
  } else if (firstSource.type === SourceInformationKeys.other) {
    sourceName = firstSource.type ? startCase(firstSource.type) : SourceInformationLabels.other
  } else if (firstSource.type === SourceInformationKeys.bowerySubject) {
    sourceName = SourceInformationLabels.bowerySubject
  } else {
    sourceName = startCase(firstSource.type)
  }
  return {
    sourceName,
    sourceOfInformation: sourceName,
    sourceUrl,
  }
}

export const mapCompPlexResidentialLeaseToRentComp = (
  residentialRentComp: ResidentialLeaseOutput,
  editLeaseId?: string
) => {
  const {
    id,
    version,
    isLatestVersion,
    address,
    leaseInformation,
    propertyInformation,
    resourceInformation,
    leaseDate,
    unitNumber,
    numberOfBedrooms,
    status,
  } = residentialRentComp
  const { state, postalCode, coords } = address
  const { sourceName, sourceOfInformation, sourceUrl } = getSourceOfInformation(resourceInformation)
  return {
    address: propertyInformation.displayStreetAddress,
    amenities: (leaseInformation.unitAmenities || []).map(mapAmenity),
    bathrooms: leaseInformation.numberOfBathrooms,
    bedrooms: numberOfBedrooms,
    city: propertyInformation.displayCity,
    dateOfValue: leaseDate,
    photo: propertyInformation.photo,
    position: coords && { lat: coords.latitude, lng: coords.longitude },
    rent: leaseInformation.monthlyRent,
    sourceName,
    sourceOfInformation,
    sourceUrl,
    sqft: leaseInformation.unitSquareFootage || null,
    state,
    unitType: leaseInformation.unitType || null,
    unitLayout: leaseInformation.unitType || null,
    zip: postalCode,
    unitNumber,
    leaseId: id,
    leaseVersionNumber: version,
    _id: editLeaseId || new ObjectID().toString(),
    isLatestVersion,
    status,
  }
}

export const getCapRateSourceName = (resourceInformation: any) => {
  if (!resourceInformation) {
    return null
  }
  // eslint-disable-next-line prettier/prettier
  if (!resourceInformation.sources?.[0]) {
    return null
  }
  if (resourceInformation.sources[0].type === 'costar') {
    return 'CoStar'
  }
  if (resourceInformation.sources[0].type === 'externalDatabase') {
    return 'External Database'
  }
  if (resourceInformation.sources[0].type === 'bowerySubject' && resourceInformation.sources[0].url) {
    return 'Bowery Subject'
  }
  return resourceInformation.sources[0].type
}

export const getCapRateSourceOfInformation = (resourceInformation: any) => {
  if (!resourceInformation) {
    return null
  }
  // eslint-disable-next-line prettier/prettier
  if (resourceInformation.sources?.[0] && resourceInformation.sources?.[0].url) {
    return 'externalDatabase'
  }

  if (!resourceInformation.sources?.[0]) {
    return null
  }

  if (resourceInformation.sources?.[0].type === 'bowerySubject' || resourceInformation.sources?.[0].type === 'externalDatabase') {
    return resourceInformation.sources?.[0].type
  }

  return 'other'
}
