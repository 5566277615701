import React from 'react'
import PropTypes from 'prop-types'
import { isNil, merge } from 'lodash'
import { SaleStatus } from 'shared/constants/report/sales/salesStatus'

import * as Api from '../../../../../../../core/api'
import { Loading } from '../../../../../../../shared/components'
import { STATE_NAMES } from '../../../../../../../../../shared/constants/states'
import { getPropertyType } from '../../../../../../../shared/utils/propertyHelper'
import { searchByAddresses, searchByAddressesAndPin } from '../../../../../../../shared/utils/compPlex.gql'
import { GEOGRAPHY_OPTIONS } from '../../../../../../../shared/constants/properties'
import { queryBuilder } from '../../../../../../../shared/components/PropertyWizard/Search/queryBuilder'

export const SEARCH_TYPE = {
  ADDRESS: 'address',
  PIN: 'parcelIdentificationNumber',
}

const getPropertyIdentifierBoroughBlockLot = (borough, block, lot) => {
  const boroughStr = borough == null ? '' : borough
  const blockStr = block == null ? '' : block
  const lotStr = lot == null ? '' : lot
  return `${boroughStr}_${blockStr}_${lotStr}`.toUpperCase()
}

const getPropertyIdentifierBlockLotQualifier = (block, lot, qualifier) => {
  const blockStr = block == null ? '' : block
  const lotStr = lot == null ? '' : lot
  const qualifierStr = qualifier == null ? '' : qualifier
  return `${blockStr}_${lotStr}_${qualifierStr}`.toUpperCase()
}

const getPropertyIdentifier = locationInfo => {
  switch (locationInfo.locationIdentifier) {
    case GEOGRAPHY_OPTIONS.NY:
      return getPropertyIdentifierBoroughBlockLot(locationInfo.borough, locationInfo.block, locationInfo.lot)
    case GEOGRAPHY_OPTIONS.NJ:
      return getPropertyIdentifierBlockLotQualifier(locationInfo.block, locationInfo.lot, locationInfo.qualifier)
    case GEOGRAPHY_OPTIONS.OTHER: // not used
    default:
      return ''
  }
}

const getPostalCodeSearch = locationInfo => {
  if (locationInfo.locationIdentifier !== GEOGRAPHY_OPTIONS.NJ) {
    return undefined
  }
  const { district } = locationInfo
  const ZIP_REGEX = /\d{5}/
  const [zip] = String(district).match(ZIP_REGEX) || []
  return zip
}

const searchForComps = (locationInfo, properties, searchType) => {
  const query = {}
  query.addresses = [...properties.map(property => mapPropertyToAddress(property))]

  if (locationInfo.location?.addressInfo && searchType === SEARCH_TYPE.ADDRESS) {
    const addressInfo = locationInfo.location.addressInfo
    query.addresses.push({
      streetAddress: addressInfo.shortAddress,
      /* TODO: Improve handling of city name, esp in Queens. Can we use the first 'political' address component? */
      city: addressInfo.city === 'Manhattan' && addressInfo.state === 'NY' ? 'New York' : addressInfo.city,
      state: STATE_NAMES[addressInfo.state],
      postalCode: addressInfo.zip,
    })

    return searchByAddresses(query, true)
  }

  query.propertyIdentification = {}
  query.propertyIdentification.propertyIdentification = {
    propertyIdentifierType: {
      [GEOGRAPHY_OPTIONS.NY]: 'BoroughBlockLot',
      [GEOGRAPHY_OPTIONS.NJ]: 'BlockLotQualifier',
      [GEOGRAPHY_OPTIONS.OTHER]: 'Custom',
    }[locationInfo.locationIdentifier],
    propertyIdentifier: getPropertyIdentifier(locationInfo),
    postalCode: getPostalCodeSearch(locationInfo),
  }
  query.addresses = { addresses: query.addresses }
  return searchByAddressesAndPin(query, true)
}

// TODO: move these mappers to transformers helper?

const mapPropertyToAddress = property => {
  return {
    streetAddress: property.address,
    city: property.city,
    state: property.state,
    postalCode: property.zip,
    coords: property.coords,
    county: property.county,
  }
}

const geographySpecificTransformation = property => {
  if (property.BBL /* NYC */) {
    return {
      address: {
        propertyIdentification: {
          propertyIdentifier: getPropertyIdentifierBoroughBlockLot(property.borough, property.Block, property.Lot),
          propertyIdentifierType: 'BoroughBlockLot',
        },
        county: {
          // TODO: for display in search results only; don't submit
          Manhattan: 'New York County',
          Brooklyn: 'Kings County',
          Bronx: 'Bronx County',
          Queens: 'Queens County',
          'Staten Island': 'Richmond County',
        }[property.borough],
      },
      propertyInformation: {
        siteArea: property.LotArea,
        siteAreaUnit: 'sf',
        propertyType: getPropertyType(property),
        residentialUnits: property.residentialUnits,
        commercialUnits: property.commercialUnits,
        grossBuildingArea: property.BldgArea,
        yearBuilt: property.YearBuilt,
        isYearBuiltEstimate: true, // How can we tell
        yearRenovated: property.yearRenovated,
        buildingType: property.hasElevator ? 'elevator' : 'walk-up',
        numberOfFloors: property.NumFloors, // TODO: How many records do we have where NumbBldgs !== 1
        neighborhood: property.neighborhood,
      },
    }
  }
  if (!isNil(property.acreage) /* NJ */) {
    return {
      address: {
        propertyIdentification: {
          propertyIdentifier: getPropertyIdentifierBlockLotQualifier(property.block, property.lot, property.qual),
          propertyIdentifierType: 'BlockLotQualifier',
        },
        county: property.county, // TODO: for display in search results only; don't submit
      },
      propertyInformation: {
        siteArea: property.acreage,
        siteAreaUnit: 'acre',
        yearBuilt: property.yearBuilt ? Number(property.yearBuilt) : undefined,
        grossBuildingArea: Number(property.gba),
        numberOfFloors: property.storyHeight, // Ocean County only
      },
    }
  }
}

const transformPropertyToComp = property => {
  return merge(
    {
      address: mapPropertyToAddress(property),
      saleDate: property.saleDate,
      saleInformation: {
        salePrice: property.salePrice || 0,
        uniqueSaleIdentifier: property.uniqueSaleIdentifier ? String(property.uniqueSaleIdentifier) : undefined,
        saleStatus: SaleStatus.TRANSACTION, // How to confirm?
        seller: property.grantor, // TODO what does this affect
        buyer: property.grantee,
      },
      taxInfoLink: property.taxInfoLink, // TODO: for display in search results only; don't submit
    },
    geographySpecificTransformation(property)
  )
}

export const compplexAddressSearch = async addressInfo => {
  const query = queryBuilder(addressInfo)
  try {
    let properties = []
    if (query) {
      properties = await Api.searchProperties(query)
    }

    const foundSalesComps = await searchForComps({ location: { addressInfo } }, properties || [], SEARCH_TYPE.ADDRESS)
    return {
      properties: [...foundSalesComps, ...properties.map(transformPropertyToComp)],
      locationIdentifier: query.locationIdentifier,
    }
  } catch (error) {
    console.error(error)
    return {
      properties: [],
      locationIdentifier: GEOGRAPHY_OPTIONS.OTHER,
    }
  }
}

export const withSearch = (Component, searchType) => {
  return class extends React.PureComponent {
    static propTypes = {
      values: PropTypes.object,
      onSearchComplete: PropTypes.func.isRequired,
    }

    static defaultProps = {
      values: {},
    }

    state = {
      isLoading: false,
    }

    searchProperties = async () => {
      const { values, onSearchComplete } = this.props
      this.setState({ isLoading: true })

      const query = Component.buildQuery(values)

      try {
        let properties = []
        if (query) {
          properties = await Api.searchProperties(query)
        }

        const foundSalesComps = await searchForComps(values.search, properties, searchType)

        onSearchComplete({
          properties: [...foundSalesComps, ...properties.map(transformPropertyToComp)],
          locationIdentifier: query.locationIdentifier,
        })
      } catch (error) {
        console.log(error)
      }
      this.setState({ isLoading: false })
    }

    render() {
      const { isLoading } = this.state
      return (
        <React.Fragment>
          {isLoading && <Loading />}
          <Component {...this.props} searchProperties={this.searchProperties} />
        </React.Fragment>
      )
    }
  }
}
