import React from 'react'

import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Button,
  Stack,
  Typography,
} from '@mui/material'
import { Link } from 'react-router-dom'

import { SUBJECT_FIELDS } from '../constants'

const FieldsMissingErrorModal = ({ open, onConfirm, missingFields, reportId }) => {
  return (
    <Dialog fullWidth open={open} data-qa="missing-fields-error-dialog">
      <DialogTitle>
        <Typography variant="h6" component="span">
          Missing data
        </Typography>
      </DialogTitle>
      <DialogContent>
        <DialogContentText>
          The following pieces of data are required to submit this subject property to the Sales Comparable database:
        </DialogContentText>
        {missingFields.map(fieldName => {
          return (
            <Link sx={{ color: 'red' }} to={`/report/${reportId}/${SUBJECT_FIELDS[fieldName]?.link}`} key={fieldName}>
              <DialogContentText sx={{ color: 'red' }}>{SUBJECT_FIELDS[fieldName]?.label}</DialogContentText>
            </Link>
          )
        })}
        <DialogContentText>
          Please update the required fields in order to submit this subject property.
        </DialogContentText>
      </DialogContent>
      <Stack flexDirection="row" justifyContent="end">
        <DialogActions>
          <Button onClick={onConfirm} data-qa="missing-fields-error-confirm-btn">
            Ok
          </Button>
        </DialogActions>
      </Stack>
    </Dialog>
  )
}

export default FieldsMissingErrorModal
