export const base = {
  fontSize: 14,
}
export const tableHeaderRow = {
  ...base,
  fontWeight: 'bold',
  color: 'black',
}

const numberStyles = {
  '& div': {
    paddingLeft: 0,
    height: 32,
  },
  '& fieldset': {
    display: 'none',
  },
  height: 32,
  margin: 0,
}

export const numberField = {
  '& input': {
    fontSize: 14,
    paddingTop: 0,
    paddingLeft: 0,
    height: 32,
    textAlign: 'right',
  },
  ...numberStyles,
}

const totalTitleCell = {
  borderLeft: '1px solid rgba(224, 224, 224, 1)',
  ...base,
  fontWeight: 'bold',
  color: 'black',
}

const styles = theme => ({
  root: {
    width: '100%',
    marginTop: 16,
  },
  table: {
    minWidth: 650,
  },

  paper: { ...theme.paper, marginBottom: 20 },
  paper_table: { padding: '24px 24px 24px', marginBottom: '24px', marginTop: '16px' },
  paper_header: {
    marginBottom: 16,
  },
  addButton: {
    marginBottom: 24,
  },
  totalTitleCell,
  totalCell: {
    ...totalTitleCell,
    textAlign: 'right',
    paddingRight: '12px !important',
  },

  dashTotal: {
    ...totalTitleCell,
    textAlign: 'right',
    paddingRight: '14px !important',
  },

  numberField,
  tableHeaderRow,
  headerTableCell: {
    borderLeft: '1px solid rgba(224, 224, 224, 1)',
    minWidth: 250,
    textAlign: 'right',
  },
  tableCell: {
    borderLeft: '1px solid rgba(224, 224, 224, 1)',
    minWidth: 250,
    textAlign: 'right',
  },
  numberFormat: {
    textAlign: 'right',
  },
  location: {
    fontSize: 14,
    paddingRight: 14,
  },

  dropDown: {
    textAlign: 'right',
    paddingRight: 32,
  },
  datePickerInput: {
    '& div': {
      '& input': {
        textAlign: 'right',
        paddingRight: 14,
      },
    },
  },
  textField: {
    ...numberField,
    paddingTop: 8,
    width: 'auto',
  },
  iconButton: {
    padding: 6,
  },
})

export default styles
