import React, { memo, useCallback, useMemo } from 'react'
import { Grid, Stack, Link } from '@mui/material'
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined'
import { KeyboardArrowDown } from '@mui/icons-material'
import LinkIcon from '@mui/icons-material/Launch'
import { makeStyles } from '@mui/styles'
import { Link as ReactRouterLink } from 'react-router-dom'
import { camelCase, cloneDeep, get, noop, startCase } from 'lodash'
import { Field } from 'react-final-form'
import { CELL_COLUMN_CONFIGURATIONS, ColumnDataTypeEnum, RowBasedTable } from '@bowery-valuation/ui-components'
import ObjectID from 'bson-objectid'

import { Button } from 'client-shared/components/_mui5'
import { getCustomColumnConfig } from 'client-shared/utils/rowBasedTable'
import RefHolder from 'client-shared/utils/RefHolder'

import { fromCurrency } from 'shared/utils/numberOperations'
import {
  ADJUSTED_BASE_COST_ID,
  ADJUSTED_REPLACEMENT_COST_ID,
  AREA_ID,
  BASE_UNIT_COST_ID,
  LABELS,
  MULTIPLIER_IDS,
  REFINEMENT_IDS,
  REPLACEMENT_COST_NEW_ID,
  TOTAL_MULTIPLIER_ID,
  BUILDING_INFO_IDS,
  DESCRIPTION_ID,
} from 'shared/constants/insurableValue/mvs'
import { formatCurrencyFloat, formatFloat, formatInt } from 'shared/utils/formatters/numberFormatters'

import { GRADE_OPTIONS, editableRows } from './constants'
import { getType } from './helpers'
import TableStyles from './styles'

const useStyles = makeStyles(() => ({
  alignRight: {
    marginLeft: 'auto',
    paddingTop: '6px',
  },
  top5: {
    marginTop: '5px',
  },
}))

const renderSelect = (params, classes) => {
  if (params.colDef.editable !== undefined && !params.colDef.editable(params)) {
    return params.value ?? null
  }

  return (
    <Grid container>
      <Grid item xs={11} className={classes.alignRight}>
        {params.value ?? null}
      </Grid>
      <Grid item xs={1} className={classes.top5}>
        <KeyboardArrowDown />
      </Grid>
    </Grid>
  )
}

export const getColumns = (buildingComponents, editable, formApi, classes) => {
  const columns = [
    {
      name: 'label',
      label: 'Building Name',
      type: ColumnDataTypeEnum.text,
      permanent: true,
      align: 'left',
      suppressMovable: true,
      minWidth: 305,
      editable: params => {
        return editable && params.data.id.includes('custom')
      },
      cellStyle: ({ data }) => {
        const { id } = data
        if (editable && (id === 'addRefinement' || id === 'addMultiplier' || id.includes('custom'))) {
          return TableStyles.lineItemRow
        }
        return {
          'background-color': 'rgba(34, 34, 34, 0.04)',
          borderRight: 'none',
          borderLeft: 'none',
        }
      },
      cellRendererFramework: ({ value, data }) => {
        const { id, type } = data
        if (id === 'addRefinement' || id === 'addMultiplier') {
          const { inputProps } = type
          return (
            <Link
              variant="text"
              size="small"
              disabled={!editable}
              sx={{
                textTransform: 'none',
                fontWeight: 400,
                textDecoration: 'underline',
                color: 'rgba(3, 24, 110, 1)',
                paddingLeft: 0,
                '&:hover': {
                  cursor: 'pointer',
                },
                '&[disabled]': {
                  cursor: 'inherit',
                },
              }}
              onClick={editable ? inputProps.onClick : noop}
            >
              {inputProps.label}
            </Link>
          )
        }
        return value ?? null
      },
    },
  ]
  buildingComponents.forEach((buildingComponent, index) => {
    columns.push({
      name: buildingComponent.id,
      id: new ObjectID().toString(),
      label: `Building ${index + 1}`,
      type: ColumnDataTypeEnum.text,
      suppressMovable: true,
      align: 'right',
      editable: params => {
        if (params.data.id === BUILDING_INFO_IDS.finished) {
          // `buildingComponent` here is captured from the state of the form at the time the callback was created. We
          // need to get the current value of `buildingComponent` from the state of the form at call time.
          const buildingComponentsFromState = get(formApi.getState().values, 'buildingComponents', [])
          const currentBuildingComponent = buildingComponentsFromState.find(
            stateComponent => stateComponent.id === buildingComponent.id
          )
          const isAboveGradeComponent = currentBuildingComponent?.grade === GRADE_OPTIONS.aboveGrade
          return editable && !isAboveGradeComponent
        }
        return editable && (editableRows.includes(params.data.id) || params.data.id.includes('custom'))
      },
      cellEditorSelector: ({ data }) => {
        const { type } = data
        if (type?.rowType === ColumnDataTypeEnum.select) {
          const { inputProps } = type
          return {
            component: 'selectCellEditor',
            params: {
              ...CELL_COLUMN_CONFIGURATIONS.select.params,
              inputProps,
            },
          }
        }
        if (type?.rowType === ColumnDataTypeEnum.autosuggest) {
          const { inputProps } = type
          return {
            component: 'autosuggestCellEditor',
            params: {
              ...CELL_COLUMN_CONFIGURATIONS.autosuggest.params,
              inputProps,
            },
          }
        }
      },
      cellRendererFramework: params => {
        const { data } = params

        if (data.type?.rowType === ColumnDataTypeEnum.select) {
          return renderSelect(params, classes)
        }

        return params.value ?? null
      },
      cellStyle: ({ value, data }) => {
        const { id } = data
        if (editable && (id === 'addRefinement' || id === 'addMultiplier')) {
          return TableStyles.lineItemRow
        }
        return TableStyles.tableRow
      },
    })
  })
  return columns
}

export const MvsTable = ({
  buildingComponents,
  customRefinements,
  customMultipliers,
  push,
  remove,
  update,
  editable = true,
  focus,
  formApi,
}) => {
  const classes = useStyles()

  const handleAddMultiplier = useCallback(() => {
    const newMultiplier = {
      id: new ObjectID().toString(),
      label: 'Custom Multiplier',
    }
    push('customMultipliers', newMultiplier)
    focus('buildingComponents')
  }, [push, focus])
  const handleAddRefinement = useCallback(() => {
    const newRefinement = {
      id: new ObjectID().toString(),
      label: 'Custom Refinement',
    }
    push('customRefinements', newRefinement)
    focus('buildingComponents')
  }, [push, focus])

  const handleRowDelete = useCallback(
    rowId => {
      const rowIdParts = rowId.split('_')
      const isCustomMultiplier = rowIdParts[0].includes('customMultiplier')
      if (isCustomMultiplier) {
        const customMultipliers = get(formApi.getState().values, 'customMultipliers', [])
        const multiplierId = rowIdParts[1]
        const index = customMultipliers.findIndex(multiplier => multiplier.id === multiplierId)
        remove('customMultipliers', index)
      } else {
        const customRefinements = get(formApi.getState().values, 'customRefinements', [])
        const refinementId = rowIdParts[1]
        const index = customRefinements.findIndex(refinement => refinement.id === refinementId)
        remove('customRefinements', index)
      }
    },
    [remove, formApi]
  )

  const rows = useMemo(() => {
    const rows = []
    const getSectionRows = (rowIds, formatter, defaultValue, summary = false) => {
      return rowIds.map(rowId => {
        const rowType = getType(rowId)
        const rowLabel = LABELS[rowId]

        const row = {
          readOnly: false,
          suppressMovable: true,
          permanent: false,
          type: rowType,
          label: rowLabel,
          id: rowId,
          rowDef: { hideAction: true, summary },
        }
        buildingComponents?.forEach(buildingComponent => {
          const rowValueIndex = buildingComponent.id
          const rowValue = get(buildingComponent, rowId, defaultValue)
          let formattedValue = rowValue
          if (formatter) {
            formattedValue = formatter(formattedValue)
          }
          if (rowId === BUILDING_INFO_IDS.finished) {
            const isAboveGradeComponent = buildingComponent.grade === GRADE_OPTIONS.aboveGrade
            row[rowValueIndex] = isAboveGradeComponent ? 'N/A' : formattedValue
          } else {
            row[rowValueIndex] = formattedValue
          }
        })
        return row
      })
    }
    const getCustomRefinementRows = () => {
      return customRefinements.map(customRefinement => {
        const customRefinementRow = {
          readOnly: false,
          isEditableRow: editable,
          suppressMovable: true,
          permanent: false,
          type: ColumnDataTypeEnum.money,
          id: `customRefinement_${customRefinement.id}`,
          label: customRefinement.label,
        }
        buildingComponents.forEach(buildingComponent => {
          const rowValue = customRefinement[buildingComponent.id] || 0
          customRefinementRow[buildingComponent.id] = formatCurrencyFloat(rowValue)
        })
        return customRefinementRow
      })
    }

    const getCustomMultiplierRows = () => {
      return customMultipliers.map(customMultiplier => {
        const customMultiplierRow = {
          readOnly: false,
          isEditableRow: editable,
          suppressMovable: true,
          permanent: false,
          type: ColumnDataTypeEnum.numeric,
          id: `customMultiplier_${customMultiplier.id}`,
          label: customMultiplier.label,
        }
        buildingComponents.forEach(buildingComponent => {
          const rowValue = customMultiplier[buildingComponent.id] || 1
          customMultiplierRow[buildingComponent.id] = formatFloat(rowValue, 3)
        })
        return customMultiplierRow
      })
    }

    const descriptionRow = getSectionRows([DESCRIPTION_ID], null, null)
    rows.push(...descriptionRow)
    const buildingInfoRows = getSectionRows(Object.values(BUILDING_INFO_IDS), startCase)
    rows.push(...buildingInfoRows)
    const baseUnitCostRow = getSectionRows([BASE_UNIT_COST_ID], formatCurrencyFloat, 0)
    rows.push(...baseUnitCostRow)
    rows.push({
      readOnly: false,
      suppressMovable: true,
      permanent: false,
      type: ColumnDataTypeEnum.text,
      label: 'Refinements',
      id: 'refinements',
      rowDef: { hideAction: true, summary: true },
    })
    const refinementRows = getSectionRows(Object.values(REFINEMENT_IDS), formatCurrencyFloat, 0)
    rows.push(...refinementRows)
    const customRefinementsRows = getCustomRefinementRows()
    rows.push(...customRefinementsRows)
    if (editable) {
      rows.push({
        readOnly: false,
        suppressMovable: true,
        permanent: false,
        type: {
          rowType: 'button',
          inputProps: {
            onClick: handleAddRefinement,
            label: 'Add a Refinement',
          },
        },
        id: 'addRefinement',
        rowDef: { hideAction: true },
      })
    }
    const adjustedBaseCostRow = getSectionRows([ADJUSTED_BASE_COST_ID], formatCurrencyFloat, 0, true)
    rows.push(...adjustedBaseCostRow)
    rows.push({
      readOnly: false,
      suppressMovable: true,
      permanent: false,
      type: ColumnDataTypeEnum.text,
      label: 'Multipliers',
      id: 'multipliers',
      rowDef: { hideAction: true, summary: true },
    })
    const multiplierRows = getSectionRows(Object.values(MULTIPLIER_IDS), value => formatFloat(value, 3), 1)
    rows.push(...multiplierRows)
    const customMultiplierRows = getCustomMultiplierRows()
    rows.push(...customMultiplierRows)
    if (editable) {
      rows.push({
        readOnly: false,
        suppressMovable: true,
        permanent: false,
        type: {
          rowType: 'button',
          inputProps: {
            onClick: handleAddMultiplier,
            label: 'Add a Multiplier',
          },
        },
        id: 'addMultiplier',
        rowDef: { hideAction: true },
      })
    }
    const totalMultiplierRow = getSectionRows([TOTAL_MULTIPLIER_ID], value => formatFloat(value, 3), 1, true)
    rows.push(...totalMultiplierRow)
    const adjustedReplacementRow = getSectionRows([ADJUSTED_REPLACEMENT_COST_ID], formatCurrencyFloat, 0)
    rows.push(...adjustedReplacementRow)
    const areaRow = getSectionRows([AREA_ID], formatInt, 0)
    rows.push(...areaRow)
    const replacementCostNewRow = getSectionRows([REPLACEMENT_COST_NEW_ID], formatCurrencyFloat, 0, true)
    rows.push(...replacementCostNewRow)
    return rows
  }, [handleAddMultiplier, handleAddRefinement, buildingComponents, customMultipliers, customRefinements, editable])

  const columns = useMemo(() => {
    return getColumns(buildingComponents, editable, formApi, classes)
  }, [buildingComponents, editable, formApi, classes])

  const handleRowUpdate = row => {
    const { label } = row
    const fieldName = row.id
    const updatedBuildingComponents = cloneDeep(buildingComponents)
    const updatedCustomMultipliers = cloneDeep(customMultipliers)
    const updatedCustomRefinements = cloneDeep(customRefinements)
    if (fieldName.includes('customRefinement')) {
      const customRefinementId = fieldName.split('_')[1]
      const customRefinementIndex = updatedCustomRefinements.findIndex(
        customRefinement => customRefinement.id === customRefinementId
      )
      const updatedCustomRefinement = { label, id: customRefinementId }
      updatedBuildingComponents.forEach(buildingComponent => {
        updatedCustomRefinement[buildingComponent.id] = fromCurrency(row[buildingComponent.id])
      })
      update('customRefinements', customRefinementIndex, updatedCustomRefinement)
    } else if (fieldName.includes('customMultiplier')) {
      const customMultiplierId = fieldName.split('_')[1]
      const customMultiplierIndex = updatedCustomMultipliers.findIndex(
        customMultiplier => customMultiplier.id === customMultiplierId
      )
      const updatedCustomMultiplier = { label, id: customMultiplierId }
      updatedBuildingComponents.forEach(buildingComponent => {
        updatedCustomMultiplier[buildingComponent.id] = fromCurrency(row[buildingComponent.id])
      })
      update('customMultipliers', customMultiplierIndex, updatedCustomMultiplier)
    } else {
      updatedBuildingComponents.forEach((buildingComponent, index) => {
        const rowValue = get(row, buildingComponent.id, null)
        if (row.type === ColumnDataTypeEnum.numeric || row.type === ColumnDataTypeEnum.money) {
          buildingComponent[fieldName] = fromCurrency(rowValue)
        } else if (fieldName === BUILDING_INFO_IDS.grade) {
          const isAboveGradeComponent = buildingComponent.grade === GRADE_OPTIONS.aboveGrade
          const finished = get(buildingComponent, 'finished', null)
          buildingComponent[BUILDING_INFO_IDS.finished] = isAboveGradeComponent ? null : finished
          buildingComponent[fieldName] = camelCase(rowValue)
        } else if (fieldName === DESCRIPTION_ID) {
          buildingComponent[fieldName] = rowValue
        } else {
          buildingComponent[fieldName] = camelCase(rowValue)
        }
        update('buildingComponents', index, buildingComponent)
      })
    }
    focus('buildingComponents')
  }

  const handleAddBuildingComponent = () => {
    const newBuildingComponent = {
      id: new ObjectID().toString(),
    }
    push('buildingComponents', newBuildingComponent)
  }

  const handleDeleteBuildingComponent = useCallback(
    colDef => {
      const buildingComponents = get(formApi.getState().values, 'buildingComponents', [])
      const buildingComponentId = colDef?.field
      const index = buildingComponents.findIndex(buildingComponent => buildingComponent.id === buildingComponentId)

      remove('buildingComponents', index)
    },
    [remove, formApi]
  )

  const tableRenderers = useMemo(
    () => ({
      columnEditor: params => {
        return (
          <>
            {editable && (
              <CloseOutlinedIcon
                sx={{
                  paddingTop: '4px',
                  fontSize: '24px',
                  color: 'rgba(34, 34, 34, 0.54)',
                  '&:hover': {
                    cursor: 'pointer',
                  },
                }}
                fixedWidth
                onClick={() => handleDeleteBuildingComponent(params.colDef)}
              />
            )}
          </>
        )
      },
    }),
    [handleDeleteBuildingComponent, editable]
  )

  const handleManyRowsUpdate = rows => {
    rows.forEach(row => {
      handleRowUpdate(row)
    })
  }
  const rowStyle = {
    backgroundColor: 'rgba(255, 255, 255, 0)',
  }

  const Link = RefHolder(ReactRouterLink)

  return (
    <Stack spacing={1}>
      <Grid container justifyContent="flex-end">
        {editable ? (
          <Button variant="outlined" color="primary" onClick={handleAddBuildingComponent}>
            Add Building
          </Button>
        ) : (
          <Link
            to={{
              pathname: 'insurable-value',
              state: { returnPath: 'cost-approach-page' },
            }}
          >
            <Button variant="outlined" color="primary" onClick={handleAddBuildingComponent}>
              EDIT MVS DATA
              <LinkIcon fontSize="24px" sx={{ paddingLeft: '4px' }} />
            </Button>
          </Link>
        )}
      </Grid>
      <Field name="buildingComponents" render={() => null} key="buildingComponents" />
      <RowBasedTable
        id="mvs-table"
        columns={columns}
        rows={rows}
        onRowUpdate={handleRowUpdate}
        onRowDelete={handleRowDelete}
        onManyRowsUpdate={handleManyRowsUpdate}
        getCustomColumnConfig={getCustomColumnConfig}
        actionCellHidden={!editable}
        hideIndexColumn
        onRowsDragEnd={noop}
        onColumnDelete={noop}
        onColumnUpdate={noop}
        onColumnDragEnd={noop}
        renderers={tableRenderers}
        rowStyle={rowStyle}
        disableVirtualization
      />
    </Stack>
  )
}

export default memo(MvsTable)
