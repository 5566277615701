import { GeneratedText, gtLocked } from '../../../narrative/GeneratedText'

import { mapDTO, mapDataModel } from './mappings'

const generate = () => {
  const gt = new GeneratedText()

  gt.t`It is our opinion that given the current economic conditions,`
  gt.t` an exposure time for the subject property is between ${gtLocked('exposureTimeMin', null, 'Exposure Time Min')}`
  gt.t` months and ${gtLocked('exposureTimeMax', null, 'Exposure Time Max')} months.`
  gt.t` This conclusion is predicated on interviews with local brokers and other real estate industry sources, on`
  gt.t` information obtained in the verification process of recent sale transactions for similar properties, and`
  gt.t` our analysis of supply and demand forces in the local market. The value reported herein presumes such`
  gt.t` an exposure time.`

  return gt.getParts()
}

export default { generate, mapDTO, mapDataModel }
