import { LeaseStatusTypes } from 'shared/constants/incomeApproach/commercialIncome'

import { objectToKeyValuePairs } from '../../../../../shared/utils/ui/checkboxHelper'

export const Labels = {
  COMMERCIAL_RENT_ROLL: 'In-Place Rent Roll',
}

export const LEASE_STATUS_OPTIONS = objectToKeyValuePairs(LeaseStatusTypes)

export { LeaseStatusTypes }
