import React from 'react'
import { FormControl, FormHelperText, InputLabel } from '@mui/material'
import { makeStyles } from '@mui/styles'

import { Theme } from 'core/theme'

import Tooltip from '../Tooltip'
import { Information } from '../Icons'

export type Props = {
  children: React.ReactNode
  label?: React.ReactNode
  helperText?: React.ReactNode
  tooltipText?: React.ReactChild
  required?: boolean
  disabled?: boolean
  error?: boolean
  extraTitleElements?: React.ReactNode
}

const useStyles = makeStyles<Theme>(theme => {
  return {
    label: {
      fontFamily: 'Nunito Sans',
      fontStyle: 'normal',
      fontWeight: 'bold',
      fontSize: '14px',
      lineHeight: '20px',
      color: theme.palette.grey[900],
      paddingBottom: '2px',

      '&& svg': {
        width: '18px',
        height: '18px',
        paddingLeft: '8px',
        marginBottom: '-2px',
      },
    },

    disabledLabel: {
      color: theme.palette.grey[400],
      '&& svg': {
        width: '18px',
        height: '18px',
      },
    },

    tooltipIcon: {
      color: theme.palette.primary.main,
    },

    helperText: {
      ...theme.typography.caption,
      marginTop: '0px',
      marginBottom: '16px',
    },

    inputElementMarkup: {
      '&& svg': {
        width: '18px',
        height: '18px',
      },
    },
  }
})

const InputWrapper: React.FC<Props> = ({
  label,
  disabled,
  tooltipText,
  error,
  required,
  helperText,
  extraTitleElements,
  children: inputElementMarkup,
  ...restProps
}) => {
  const classes = useStyles()

  return (
    <>
      {label && (
        <InputLabel disabled={disabled} classes={{ root: classes.label, disabled: classes.disabledLabel }}>
          {label}
          {tooltipText && (
            <Tooltip title={tooltipText} placement="right">
              {/* @ts-expect-error */}
              <Information className={classes.tooltipIcon} />
            </Tooltip>
          )}
          <span>{extraTitleElements}</span>
        </InputLabel>
      )}
      <FormControl disabled={disabled} error={error} required={required} {...restProps}>
        <span className={classes.inputElementMarkup}>{inputElementMarkup}</span>
        <FormHelperText className={classes.helperText}>{helperText}</FormHelperText>
      </FormControl>
    </>
  )
}

export default React.memo(InputWrapper)
