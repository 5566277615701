import React from 'react'
import PropTypes from 'prop-types'
import { Field } from 'react-final-form'
import ReactAutosuggest from 'react-autosuggest'
import SearchIcon from '@mui/icons-material/Search'
import { noop, truncate, get, isNil } from 'lodash'
import {
  MenuItem,
  withStyles,
  Paper,
  FormControl,
  InputAdornment,
  CircularProgress,
  FormHelperText,
  RootRef,
  TextField,
} from '@material-ui/core'
import PlacesAutocomplete, { geocodeByAddress, getLatLng } from 'react-places-autocomplete'

import Portal from '../Portal'

import { MAX_OPTION_LENGTH, AUTOSUGGEST_CONTAINER_Z_INDEX, GOOGLE_PLACE_SEARCH_OPTIONS } from './constants'

const styles = theme => ({
  container: {
    width: '100%',
    flexGrow: 1,
    position: 'relative',
    height: 84,
  },
  suggestionsList: {
    margin: 0,
    padding: 0,
    listStyleType: 'none',
  },
  suggestionsContainer: {
    marginTop: 4,
  },
  formLabel: {
    display: 'flex',
    alignItems: 'center',
  },
  formHelper: {
    margin: '8px 12px 0',
  },
  suggestion: {
    '& span': {
      whiteSpace: 'pre',
    },
  },
  highlightedPart: {
    fontWeight: 500,
  },
  searchIcon: {
    color: 'rgba(0, 0, 0, 0.54)',
    width: 20,
    height: 20,
  },
  suggestionPart: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  focusedInput: {
    '& $searchIcon': {
      color: theme.palette.primary[900],
    },
  },
  input: {
    padding: '15px 0',
  },
  errorMessage: {
    margin: '8px 12px 0',
    color: '#f44336',
  },
})

class GooglePlacesAutocompleteComponent extends React.PureComponent {
  constructor(props) {
    super(props)
    this.inputRef = React.createRef()
  }

  static propTypes = {
    label: PropTypes.node,
    placeholder: PropTypes.string,
    autoFocus: PropTypes.bool,
    disabled: PropTypes.bool,
    showCoordinates: PropTypes.bool,
    required: PropTypes.bool,
  }

  static defaultProps = {
    label: null,
    placeholder: 'Search Address',
    autoFocus: false,
    disabled: false,
    showCoordinates: false,
    required: false,
  }

  state = {
    shouldFetch: true,
    isLoading: false,
  }

  get suggestionsContainerWidth() {
    const { current: inputRef } = this.inputRef
    if (inputRef) {
      return inputRef.clientWidth
    }
    return 0
  }

  setAddressInfo = async address => {
    const { input, addressTransformer } = this.props
    this.setState({ isLoading: true })

    try {
      const addressInfoOptions = await geocodeByAddress(address)
      let addressInfo = addressInfoOptions[0]

      if (addressInfoOptions.length > 1) {
        for (const index in addressInfoOptions) {
          const addressInfoOption = addressInfoOptions[index]
          const streetName = addressInfoOption.address_components[1]?.long_name || ''
          if (address.includes(streetName)) {
            addressInfo = addressInfoOption
          }
        }
      }

      const latLng = await getLatLng(addressInfo)
      if (!latLng) {
        return
      }
      const coords = {
        latitude: latLng.lat,
        longitude: latLng.lng,
      }
      if (addressTransformer) {
        addressInfo = addressTransformer({ ...addressInfo, googlePlace: address })
      }

      input.onChange({
        googlePlace: address,
        addressInfo,
        coords,
      })
    } catch (error) {}

    this.setState({ isLoading: false })
  }

  onSelectChange = address => {
    const { input } = this.props
    input.onChange({ googlePlace: address })
    this.setAddressInfo(address)
  }

  renderSuggestion = getSuggestionProps => opts => {
    const { classes } = this.props
    const text = truncate(opts.description, { length: MAX_OPTION_LENGTH })
    const { offset, length } = get(opts, 'matchedSubstrings.0', {})
    return (
      <MenuItem
        {...getSuggestionProps(opts)}
        selected={opts.active}
        className={classes.suggestion}
        component="div"
        title={opts.description}
      >
        <span> {text.slice(0, offset)}</span>
        <span className={classes.highlightedPart}>{text.slice(offset, length + offset)}</span>
        <span className={classes.suggestionPart}>{text.slice(length + offset)}</span>
      </MenuItem>
    )
  }

  renderSuggestionsContainer = options => {
    const { classes } = this.props
    const { containerProps, children } = options
    return (
      <Portal zIndex={AUTOSUGGEST_CONTAINER_Z_INDEX}>
        <Paper
          {...containerProps}
          className={classes.suggestionsContainer}
          style={{ width: this.suggestionsContainerWidth }}
          square
        >
          {children}
        </Paper>
      </Portal>
    )
  }

  getCoordinates = () => {
    const { classes, input, meta } = this.props
    const { touched, error } = meta
    const { coords } = input.value

    const inErrorState = touched && !!error
    const errorText = inErrorState ? error : ''

    if (inErrorState) {
      return <FormHelperText className={classes.errorMessage}>{errorText}</FormHelperText>
    }

    if (!coords) {
      return null
    }

    return (
      <FormHelperText data-qa="google-autocomplete-coords" className={classes.formHelper}>
        {Math.trunc(coords.latitude)}
        &deg;N and {Math.trunc(coords.longitude)}
        &deg;E
      </FormHelperText>
    )
  }

  renderInputComponent =
    loading =>
    ({ ref, ...inputProps }) => {
      const { classes, label, autoFocus, disabled, placeholder, input, meta, required, showCoordinates } = this.props
      const { touched, error } = meta

      const inErrorState = touched && !!error

      return (
        <RootRef rootRef={this.inputRef}>
          <FormControl margin="dense" data-qa={`google-autocomplete-${input.name}`} fullWidth required={required}>
            <TextField
              {...inputProps}
              inputRef={ref}
              variant="outlined"
              onKeyDown={event => {
                this.handleInputKeyDown(event, inputProps)
              }}
              label={label}
              autoFocus={autoFocus}
              disabled={disabled}
              placeholder={placeholder}
              required={required}
              error={inErrorState}
              InputProps={{
                'data-qa': `google-autocomplete-${input.name}-input`,
                classes: { focused: classes.focusedInput, input: classes.input },
                startAdornment: (
                  <InputAdornment position="start">
                    {loading ? (
                      <CircularProgress data-qa={`google-autocomplete-${input.name}-loader`} size={20} />
                    ) : (
                      <SearchIcon className={classes.searchIcon} />
                    )}
                  </InputAdornment>
                ),
              }}
            />
            {showCoordinates && this.getCoordinates()}
          </FormControl>
        </RootRef>
      )
    }

  onChange = value => {
    const { input } = this.props
    if (!isNil(value)) {
      input.onChange({ googlePlace: value })
    }
  }

  onError = status => {
    const { input } = this.props
    console.warn(
      '[GooglePlacesAutocompleteComponent]: error happened when fetching data from Google Maps API.\nPlease check the docs here (https://developers.google.com/maps/documentation/javascript/places#place_details_responses)\nStatus: ',
      status,
      '\nCurrent search value:',
      get(input, 'value.googlePlace', '')
    )
  }

  handleInputKeyDown = (event, inputProps) => {
    event.persist()
    switch (event.key) {
      case 'ArrowDown':
      case 'ArrowUp':
        setTimeout(() => {
          inputProps.onKeyDown(event)
        }, 0)
        this.setState({ shouldFetch: false })
        break
      default:
        inputProps.onKeyDown(event)
        this.setState({ shouldFetch: true })
        break
    }
  }

  getSuggestionValue = suggestion => suggestion && suggestion.description

  mergeInputProps = getInputProps => {
    const { input } = this.props
    const autosuggestInputProps = getInputProps()

    const mergedInputProps = {
      ...autosuggestInputProps,
      onBlur: event => {
        input.onBlur(event)
        autosuggestInputProps.onBlur(event)
      },
    }

    return mergedInputProps
  }

  render() {
    const { classes, input } = this.props
    const googlePlace = get(input, 'value.googlePlace', '')
    return (
      <PlacesAutocomplete
        value={googlePlace}
        onChange={this.onChange}
        onError={this.onError}
        searchOptions={GOOGLE_PLACE_SEARCH_OPTIONS}
        onSelect={this.onSelectChange}
        shouldFetchSuggestions={this.state.shouldFetch}
      >
        {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => {
          return (
            <ReactAutosuggest
              theme={{
                container: classes.container,
                suggestionsList: classes.suggestionsList,
                suggestion: classes.suggestion,
              }}
              suggestions={suggestions}
              getSuggestionValue={this.getSuggestionValue}
              renderInputComponent={this.renderInputComponent(this.state.isLoading)}
              renderSuggestionsContainer={this.renderSuggestionsContainer}
              renderSuggestion={this.renderSuggestion(getSuggestionItemProps)}
              inputProps={this.mergeInputProps(getInputProps)}
              onSuggestionsClearRequested={noop}
              onSuggestionsFetchRequested={noop}
              highlightFirstSuggestion
            />
          )
        }}
      </PlacesAutocomplete>
    )
  }
}

export const GooglePlacesAutocomplete = withStyles(styles)(GooglePlacesAutocompleteComponent)
const FinalGooglePlacesAutocomplete = props => <Field {...props} component={GooglePlacesAutocomplete} />
export default FinalGooglePlacesAutocomplete
