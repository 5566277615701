import React from 'react'
import { get } from 'lodash'

import { Box, Stack, Typography } from '@mui/material'

import PreviewAndEdit from '../PreviewAndEdit'
import wrapForm from '../../wrapForm'
import { VALUE_CONCLUSION_TYPES } from '../../../constants/settings'

import CoverPageEdit from './Edit'
import CoverPagePreview from './Preview'
import { FORM_HEADING, DATA_PATH } from './constants'

const CoverPage = ({ form, coverPage, title, section, organizationClientLink, dateSectionName }) => {
  const { values } = form

  const getClients = (clients, hideClientName) => {
    return (
      <Stack spacing={2} direction="row">
        {clients.map(client => {
          return (
            <Box key={client.id}>
              {!hideClientName && (
                <Typography variant="body1" gutterBottom data-qa="client-name">{`${client.firstName} ${
                  client.lastName
                }${client.suffix ? `, ${client.suffix}` : ''}`}</Typography>
              )}
              <Typography variant="body1" gutterBottom data-qa="title">
                {client.title}
              </Typography>
              <Typography variant="body1" gutterBottom data-qa="client">
                {client.client}
              </Typography>
              <Typography variant="body1" gutterBottom data-qa="address">
                {client.address}
              </Typography>
              <Typography
                variant="body1"
                gutterBottom
                data-qa="city"
              >{`${client.city}, ${client.abbr} ${client.zip}`}</Typography>
            </Box>
          )
        })}
      </Stack>
    )
  }

  return (
    <PreviewAndEdit title={title} section={section}>
      {isEdit =>
        isEdit ? (
          <CoverPageEdit
            {...values}
            {...coverPage}
            dateSectionName={dateSectionName}
            organizationClientLink={organizationClientLink}
            getClients={getClients}
          />
        ) : (
          <CoverPagePreview {...values} {...coverPage} dateSectionName={dateSectionName} getClients={getClients} />
        )
      }
    </PreviewAndEdit>
  )
}

export default wrapForm(
  DATA_PATH,
  {
    heading: FORM_HEADING,
    registeredFields: ['address', 'city'],
  },
  state => {
    const { formValues, valueConclusionType, ...otherValues } = get(
      state,
      'report.reportData.previewAndEdit.coverPage',
      {}
    )

    return {
      coverPage: otherValues,
      initialValues: formValues,
      organizationClientLink: `organization/${state.authentication.user._id}/organization-clients`,
      dateSectionName: valueConclusionType === VALUE_CONCLUSION_TYPES.AS_IS ? 'DATE OF VALUE' : 'DATES OF VALUE',
    }
  }
)(CoverPage)
