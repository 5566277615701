import React from 'react'
import PropTypes from 'prop-types'

import { withStyles } from '@mui/styles'
import { first, get, noop } from 'lodash'
import { connect } from 'react-redux'
import { Redirect, Route, Switch } from 'react-router'
import { compose } from 'recompose'
import { withRouter } from 'react-router-dom'

import LanguageOutlinedIcon from '@mui/icons-material/LanguageOutlined'
import DnsOutlinedIcon from '@mui/icons-material/DnsOutlined'

import { Stack } from '@mui/system'

import { ICON_LABELS } from 'cms/constants/cms'

import Confirmation from '../../shared/components/Confirmation'
import Notifications from '../../shared/components/Notifications'
import Header from '../../shared/layout/Header'
import Sidebar from '../../shared/layout/Sidebar'

import SidebarMenu from '../../admin/layout/SidebarMenu'
import MenuIcon from '../../shared/layout/MenuIcon'

const topNavigationHeight = 60

const styles = {
  notifications: {
    position: 'fixed',
    top: topNavigationHeight,
    right: 0,
    zIndex: 3000,
  },
  headerContent: {
    fontSize: 20,
    fontWeight: 600,
    color: '#404858',
  },
  pageContent: {},
  layoutContainer: {
    width: '100%',
    display: 'flex',
  },
  contentContainer: {
    flex: 1,
    padding: 20,
  },
}

class PageLayout extends React.PureComponent {
  static propTypes = {
    classes: PropTypes.object.isRequired,
    location: PropTypes.string.isRequired,
    children: PropTypes.node,
    header: PropTypes.string,
    forms: PropTypes.arrayOf(
      PropTypes.shape({
        title: PropTypes.string.isRequired,
        formComponent: PropTypes.func.isRequired,
        path: PropTypes.string.isRequired,
      })
    ).isRequired,
    itemKey: PropTypes.string.isRequired,
    itemId: PropTypes.string.isRequired,
  }

  componentDidUpdate(prevProps) {
    if (prevProps.location !== this.props.location) {
      window.scrollTo(0, 0)
    }
  }

  renderMenu = () => {
    const { itemKey, itemId, forms } = this.props
    return <SidebarMenu title="Global" itemKey={itemKey} itemId={itemId} forms={forms} />
  }

  openChipBank = () => {
    const { history } = this.props
    history.push(`/cms/chip-bank`, { skipSave: true })
  }

  render() {
    const { classes, forms, itemKey, itemId, header } = this.props

    const firstForm = first(forms)

    return (
      <React.Fragment>
        <Header classes={{ headerContent: classes.headerContent }} title={header} />

        <div className={classes.notifications}>
          <Notifications />
        </div>

        <Confirmation />

        <div className={classes.layoutContainer}>
          <Sidebar renderMenu={this.renderMenu}>
            <Stack spacing={2}>
              <MenuIcon
                onClick={noop}
                icon={LanguageOutlinedIcon}
                tooltipTitle="Global"
                isActive
                label={ICON_LABELS.cms}
              />
              <MenuIcon
                onClick={this.openChipBank}
                icon={DnsOutlinedIcon}
                tooltipTitle="Chip bank"
                label={ICON_LABELS.chipBank}
              />
            </Stack>
          </Sidebar>
          <div className={classes.contentContainer}>
            <Switch>
              {forms.map(form => (
                <Route key={form.path} path={`/${itemKey}/:id/${form.path}`} component={form.formComponent} />
              ))}
              {firstForm && <Redirect exact to={`/${itemKey}/${itemId}/${firstForm.path}`} />}
            </Switch>
          </div>
        </div>
      </React.Fragment>
    )
  }
}

export default compose(
  connect(state => ({ location: get(state, 'router.location.pathname') })),
  withStyles(styles, { name: 'PageLayout' }),
  withRouter
)(PageLayout)
