import React from 'react'
import PropTypes from 'prop-types'

import { startCase } from 'lodash'

import { Button, Paper, Typography, TableBody, Stack } from '@mui/material'
import { DroppableTable } from 'client-shared/components/DragDropTable'
import { Template } from 'client-shared/components/Template'

import SubjectPropertyClearAllModal from './SubjectPropertyClearAllModal'
import SubjectPropertyDraggableRow from './SubjectPropertyDraggableRow'

const SubjectPropertyCategory = ({
  changeField,
  disabled,
  dragging,
  fields,
  onAddProperty,
  onFocusInput,
  onInsertProperty,
  setInputRef,
  values,
}) => {
  const [isOpenModal, setOpenModal] = React.useState(false)
  const { name: propertyCategory } = fields

  const closeModal = React.useCallback(() => {
    setOpenModal(false)
  }, [])
  const openModal = React.useCallback(() => {
    setOpenModal(true)
  }, [])

  const onRemoveProperty = index => {
    const updatedValues = values.filter((_value, key) => key !== index)
    changeField(propertyCategory, updatedValues)
    if (fields.length === 1) {
      fields.push('')
    }
  }

  const onClearAllProperties = () => {
    changeField(propertyCategory, [''])
    closeModal()
  }

  const getLastSegment = input => {
    const parts = input.split('.')
    return parts[parts.length - 1]
  }

  const singleValue = fields.length === 1

  return (
    <Paper data-qa={`${propertyCategory}-container`}>
      <Stack spacing={1}>
        <SubjectPropertyClearAllModal
          onCancel={closeModal}
          onSubmit={onClearAllProperties}
          propertyCategory={propertyCategory}
          clearModalOpened={isOpenModal}
        />
        <Template is="auto-flow / 1fr auto">
          <Typography variant="h6" disabled={disabled}>
            {startCase(getLastSegment(propertyCategory))}
          </Typography>
          <Button data-qa="clear-all-btn" disabled={disabled} onClick={openModal}>
            CLEAR ALL
          </Button>
        </Template>
        <DroppableTable id={propertyCategory} type={propertyCategory}>
          <TableBody data-qa="swot-table-body">
            {values.map((_value, index) => (
              <SubjectPropertyDraggableRow
                key={index}
                index={index}
                dragging={dragging}
                disabled={disabled}
                onFocus={onFocusInput}
                setInputRef={setInputRef}
                singleValue={singleValue}
                propertyCategory={propertyCategory}
                onInsertProperty={onInsertProperty}
                onRemoveProperty={onRemoveProperty}
                name={`${propertyCategory}[${index}]`}
              />
            ))}
          </TableBody>
        </DroppableTable>
        <Button
          data-qa="add-btn"
          disabled={disabled}
          onClick={onAddProperty}
          sx={{ alignSelf: 'end' }}
          variant="contained"
        >
          ADD
        </Button>
      </Stack>
    </Paper>
  )
}

SubjectPropertyCategory.propTypes = {
  changeField: PropTypes.func.isRequired,
  disabled: PropTypes.bool.isRequired,
  dragging: PropTypes.bool.isRequired,
  onAddProperty: PropTypes.func.isRequired,
  onFocusInput: PropTypes.func.isRequired,
  onInsertProperty: PropTypes.func.isRequired,
  onRemoveProperty: PropTypes.func.isRequired,
  propertyCategory: PropTypes.string.isRequired,
  setInputRef: PropTypes.func.isRequired,
  singleValue: PropTypes.bool.isRequired,
  values: PropTypes.arrayOf(PropTypes.string.isRequired),
}

export default SubjectPropertyCategory
