import React from 'react'
import PropTypes from 'prop-types'
import { isEmpty, get, valuesIn } from 'lodash'
import { Paper, Typography, Grid } from '@mui/material'
import { Link } from 'react-router-dom'

import 'leaflet/dist/leaflet.css'
import 'react-leaflet-markercluster/dist/styles.min.css'

import { SubjectAndCompsMapWizard } from 'client-shared/components/MapWizard'
import { RESIDENTIAL_RENT_COMPS_MAP_PATH } from 'shared/constants/report/keysAndDataPaths'

import CartoDBConfig from '../../../../../../shared/utils/cartodbConfig'

import { Labels } from '../constants'
import wrapForm from '../../../../wrapForm'
import { subjectPropertyAddressSelector } from '../../shared/redux/selectors'

const DATA_PATH = RESIDENTIAL_RENT_COMPS_MAP_PATH

class ResidentialUnitRentCompsMap extends React.PureComponent {
  get mapOptions() {
    const { subjectPropertyCoords } = this.props
    return {
      baseMap: CartoDBConfig.baseMap,
      isZoomOn: true,
      displayCenterButton: true,
      maxZoom: CartoDBConfig.subjectAndComps.maxZoom,
      initialZoom: CartoDBConfig.subjectAndComps.initialZoom,
      defaultLabelOffset: CartoDBConfig.subjectAndComps.defaultLabelOffset,
      width: CartoDBConfig.subjectAndComps.width,
      height: CartoDBConfig.subjectAndComps.height,
      initialCoordinates: subjectPropertyCoords,
    }
  }

  getMapWizard(unitGroupKey, unitGroupName, index, units = []) {
    const { subjectPropertyAddress, subjectPropertyCoords } = this.props

    const comps = units.map(({ coords, address = '', cityStateZip }) => {
      const streetName = get(address.split(','), '[0]')
      return {
        coords,
        address: { addressOne: streetName, addressTwo: cityStateZip },
      }
    })
    const subject = {
      coords: valuesIn(subjectPropertyCoords),
      ...subjectPropertyAddress,
    }

    return (
      <SubjectAndCompsMapWizard
        clearAvailable
        comps={comps}
        label={`${unitGroupName} ${Labels.COMPARABLES_MAP}`}
        mapOptions={this.mapOptions}
        mapType={unitGroupKey}
        name={`maps[${index}].mapUrl`}
        subject={subject}
      />
    )
  }

  render() {
    const { form } = this.props
    const { maps } = form.values

    return (
      <Grid container spacing={2} direction="column">
        {isEmpty(maps) ? (
          <Grid item>
            <Paper sx={{ display: 'inline-block' }}>
              <Typography>
                You need to
                <Link to="residential-rent-comps">
                  <u> add Rent Comps </u>
                </Link>
                before you can complete this step
              </Typography>
            </Paper>
          </Grid>
        ) : (
          maps.map(({ unitGroupKey, unitGroupName, units }, index) => (
            <Grid item key={unitGroupKey} data-qa={`${unitGroupKey}-rent-comps-map-container`}>
              {this.getMapWizard(unitGroupKey, unitGroupName, index, units)}
            </Grid>
          ))
        )}
      </Grid>
    )
  }
}

ResidentialUnitRentCompsMap.propTypes = {
  subjectPropertyAddress: PropTypes.shape({
    addressOne: PropTypes.string.isRequired,
    addressTwo: PropTypes.string.isRequired,
  }).isRequired,
  subjectPropertyCoords: PropTypes.shape({
    lat: PropTypes.number.isRequired,
    lng: PropTypes.number.isRequired,
  }).isRequired,
}

export default wrapForm(DATA_PATH, { heading: Labels.RESIDENTIAL_RENT_COMPARABLES_MAP }, state => {
  return {
    ...subjectPropertyAddressSelector(state),
  }
})(ResidentialUnitRentCompsMap)
