import { startCase } from 'lodash'

import BoweryDate from '@bowery-valuation/bowery-date'

export const formatFormName = formName => startCase(formName)

export const isSupportedGeography = propertyState => {
  return propertyState === 'New York' || propertyState === 'New Jersey'
}

export const wrapAmplitudeEvent = (event = {}) => {
  const renamedProperties = {}

  for (const prop of Object.keys(event.properties)) {
    renamedProperties[startCase(prop)] = event.properties[prop]
  }

  const amplitudeEvent = {
    type: event.label,
    properties: {
      Timestamp: new BoweryDate(event.timestamp).toISOString(),
      'Report ID': event.reportId,
      ...renamedProperties,
    },
    unix_timestamp_ms: new BoweryDate(event.timestamp).value,
  }

  if (event.path) {
    amplitudeEvent.properties.Path = event.path
  }

  return amplitudeEvent
}
