import { fromPercents, toPercents } from '../../../../shared/utils/numberOperations'

export const HEADER = 'Insurable Values'

export const TabKeys = {
  CONSTANTS: 'constants',
  NEW_YORK: 'newYork',
  NEW_JERSEY: 'newJersey',
}

export const TABS = [
  { label: 'Constants', value: TabKeys.CONSTANTS },
  { label: 'New York', value: TabKeys.NEW_YORK },
  { label: 'New Jersey', value: TabKeys.NEW_JERSEY },
]

export const TITLES_COLUMN_NAME = 'name'

export const LOCAL_MULTIPLIERS_COLUMNS = [
  { name: TITLES_COLUMN_NAME, title: '', isTitle: true },
  { name: 'A', title: 'Class A' },
  { name: 'B', title: 'Class B' },
  { name: 'C', title: 'Class C' },
  { name: 'D', title: 'Class D - Masonry' },
]

export const COST_MULTIPLIERS_COLUMNS = [
  { name: 'A', title: 'Class A' },
  { name: 'B', title: 'Class B' },
  { name: 'C', title: 'Class C' },
  { name: 'D', title: 'Class D - Masonry' },
]

export const FOUNDATIONS_FACTOR_COLUMNS = [
  {
    name: 'A',
    title: 'Class A',
    format: toPercents,
    normalize: fromPercents,
    adornment: '%',
  },
  {
    name: 'B',
    title: 'Class B',
    format: toPercents,
    normalize: fromPercents,
    adornment: '%',
  },
  {
    name: 'C',
    title: 'Class C',
    format: toPercents,
    normalize: fromPercents,
    adornment: '%',
  },
]

export const SPRINKLERS_COLUMNS = [
  { name: 'key', title: 'Sprinklers' },
  { name: 'value', title: 'AVG' },
]

export const ARCHITECTS_FEES_COLUMNS = [
  { name: 'key', title: `Architect's Fees` },
  { name: 'value', title: 'Factor' },
]
