import React from 'react'
import PropTypes from 'prop-types'

import { Table, TableBody, TableCell, TableRow } from '@mui/material'
import { Droppable } from 'react-beautiful-dnd'

const DroppableTable = ({ children, emptyTableMessage, id, isDropDisabled, isEmpty, type }) => {
  return (
    <Droppable type={type} droppableId={id} isDropDisabled={isDropDisabled}>
      {(droppableProvided, { isDraggingOver }) =>
        isEmpty ? (
          <Table data-qa={id}>
            {children}
            <TableBody {...droppableProvided.droppableProps} ref={droppableProvided.innerRef}>
              {!isDraggingOver && (
                <TableRow>
                  <TableCell align="center" colSpan="100%">
                    {emptyTableMessage}
                  </TableCell>
                </TableRow>
              )}
              {droppableProvided.placeholder}
            </TableBody>
          </Table>
        ) : (
          <Table {...droppableProvided.droppableProps} data-qa={id} ref={droppableProvided.innerRef} size="small">
            {children}
            {droppableProvided.placeholder && <TableBody>{droppableProvided.placeholder}</TableBody>}
          </Table>
        )
      }
    </Droppable>
  )
}

DroppableTable.propTypes = {
  children: PropTypes.node,
  emptyTableMessage: PropTypes.string,
  id: PropTypes.string.isRequired,
  isDropDisabled: PropTypes.bool,
  type: PropTypes.string,
}

DroppableTable.defaultProps = {
  emptyTableMessage: 'Drop items here',
  isDropDisabled: false,
}

export const DroppableTableRow = ({ children, canDelete = true, ...props }) => (
  <TableRow {...props}>
    <TableCell />
    {children}
    {canDelete && <TableCell />}
  </TableRow>
)

export default DroppableTable
