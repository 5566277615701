import { useState } from 'react'
import { removeByIndex } from 'client-shared/utils/immutability'

const useStack = () => {
  const [stack, setStack] = useState<any[]>([])

  const push = (value: any) => {
    setStack(prevState => {
      const newStack = [...prevState, value]

      return newStack
    })
  }

  const pop = () => {
    const index = stack.length - 1
    if (index > -1) {
      const value = stack[index]
      const newStack = removeByIndex(stack, index)
      setStack(newStack)
      return value
    }
  }

  const clear = () => {
    setStack([])
  }

  return {
    stack,
    push,
    pop,
    clear,
  }
}

export default useStack
