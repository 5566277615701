import React from 'react'
import PropTypes from 'prop-types'
import { get } from 'lodash'

import { Divider, Grid } from '@material-ui/core'

import Text from '../../../Text'
import GooglePlacesAutocomplete from '../../../GooglePlacesAutocomplete'
import { hasSelectedGooglePlace } from '../../../GooglePlacesAutocomplete/validation'
import { required } from '../../../../utils/validation'
import { STATE_NAMES } from '../../../../../../../shared/constants/states'

import { getLocationInfoFromAddress } from '../../../../utils/propertyHelper'

import { LABELS } from '../../../../constants/properties'

import StepFooter from '../../StepFooter'

class AdvancedSearchOther extends React.PureComponent {
  static propTypes = {
    openBasicSearch: PropTypes.func.isRequired,
    onSubmit: PropTypes.func.isRequired,
    invalid: PropTypes.bool.isRequired,
  }

  onPropertySubmit = () => {
    const { onSubmit, values } = this.props
    const address = get(values, 'search.location', {})
    const addressInfo = get(address, 'addressInfo', {})
    const shortAddress = addressInfo.shortAddress
    const property = {
      address: shortAddress,
      propertyIdentifier: get(values, 'search.propertyIdentifier'),
      coords: get(values, 'search.location.coords', {}),
      city: addressInfo.city,
      state: STATE_NAMES[addressInfo.state],
      county: addressInfo.county,
      zip: addressInfo.zip,
    }

    onSubmit(property)
  }

  render() {
    const { openBasicSearch, invalid } = this.props
    return (
      <React.Fragment>
        <Divider light />
        <br />
        <Grid container spacing={8}>
          <Grid item xs={12}>
            <GooglePlacesAutocomplete
              showCoordinates
              validate={hasSelectedGooglePlace}
              addressTransformer={getLocationInfoFromAddress}
              required
              label="Address"
              name="search.location"
              data-qa="address-text-input"
            />
          </Grid>
          <Grid container direction="row" justify="space-between" spacing={8}>
            <Grid item xs={6}>
              <Text
                label="Unique Property Identifier Type"
                name="search.propertyIdentifierType"
                data-qa="identifier-type-text-input"
                helperText="e.g. Block/Lot, APN, Parcel ID"
              />
            </Grid>
            <Grid item xs={6}>
              <Text
                validate={required}
                required
                label="Unique Property Identifier"
                name="search.propertyIdentifier"
                data-qa="identifier-text-input"
              />
            </Grid>
          </Grid>
        </Grid>
        <StepFooter
          previous={{
            text: LABELS.STANDARD_SEARCH,
            onClick: openBasicSearch,
          }}
          next={{ text: LABELS.SELECT_COMP, onClick: this.onPropertySubmit, isDisabled: invalid }}
        />
      </React.Fragment>
    )
  }
}

export default AdvancedSearchOther
