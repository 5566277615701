export const CORNER = 'Corner'
export const MID_BLOCK = 'Mid-Block'
export const THROUGH_LOT = 'Through-Lot'

export const SITE_AREA_SF_UNIT_OF_MEASURE = 'sf'
export const SITE_AREA_ACRE_UNIT_OF_MEASURE = 'acre'

export const SF_BASIS_OPTIONS = {
  GBA: 'Gross Building Area',
  GLA: 'Gross Leasable Area',
  NRA: 'Net Rentable Area',
  NLA: 'Net Leasable Area',
}

export const DEFAULT_SF_ANALYSIS_BASIS = 'GBA'

export const SourceInformationKeys = {
  bowerySubject: 'bowerySubject',
  externalDatabase: 'externalDatabase',
  other: 'other',
}

export const SourceInformationLabels = {
  bowerySubject: 'Bowery Subject',
  externalDatabase: 'External Database',
  other: 'Other',
}

export const APPROACH_TYPES = {
  INCOME: 'income',
  COST: 'cost',
  IMPROVED_SALES: 'improvedSales',
  LAND: 'land',
}

export const TEMPLATE_TYPES = {
  FREDDIE_MAC: 'freddie-mac',
  BOWERY_WAY: 'bowery-way',
  BLOCKS: 'blocks',
}

export const DEFAULT_APPROACH_SETTINGS = { income: true, improvedSales: true, land: false, cost: false }
