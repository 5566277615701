import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'

import { withStyles } from '@material-ui/core/styles'
import { TableRow, TableCell } from '@material-ui/core'
import { grey } from '@material-ui/core/colors'

import { columnShape } from './propTypes'

const styles = {
  disabled: {
    pointerEvents: 'none',
    background: grey[200],
  },
}

class TableBodyRowNew extends React.PureComponent {
  static propTypes = {
    columns: PropTypes.arrayOf(columnShape).isRequired,
    getCellContent: PropTypes.func.isRequired,
    rowClass: PropTypes.string,
    cellClass: PropTypes.string,
    disabled: PropTypes.bool,
    commercialUnits: PropTypes.array,
  }

  static defaultProps = {
    disabled: false,
  }

  render() {
    const { classes, columns, index, getCellContent, rowClass, cellClass, commercialUnits, disabled, ...restProps } =
      this.props
    const use = commercialUnits ? commercialUnits[index].use : ''

    return (
      <TableRow data-qa={`row-${index}`} className={classnames(rowClass, { [classes.disabled]: disabled })}>
        {columns.map(column => (
          <TableCell
            key={column.name}
            data-qa={`col-${column.name}`}
            style={{ width: column.width }}
            className={classnames(cellClass, column.className)}
          >
            {getCellContent({ column, index, use, ...restProps })}
          </TableCell>
        ))}
      </TableRow>
    )
  }
}

export default withStyles(styles)(TableBodyRowNew)
