import React from 'react'
import PropTypes from 'prop-types'

import { Box, Button, Stack, Tooltip, Typography } from '@mui/material'
import Refresh from '@mui/icons-material/Refresh'
import InfoOutlined from '@mui/icons-material/InfoOutlined'

import { SOURCE_INFO_HEADER_TOOLTIP } from './constants'

const SourceInfoHeader = ({ resetDataSources }) => (
  <Stack alignItems="center" direction="row" spacing={2}>
    <Typography variant="h6">Data Sources</Typography>
    <Tooltip title={SOURCE_INFO_HEADER_TOOLTIP} placement="right">
      <InfoOutlined />
    </Tooltip>
    <Box sx={{ mx: 'auto !important' }} />
    <Button data-qa="revert-to-default-btn" onClick={resetDataSources} startIcon={<Refresh />} variant="outlined">
      REVERT TO DEFAULT
    </Button>
  </Stack>
)

SourceInfoHeader.propTypes = {
  resetDataSources: PropTypes.func.isRequired,
}

export default SourceInfoHeader
