import { createAction } from 'redux-actions'
import { defineAction } from 'redux-define'

import { DEFAULT_API_ACTION_STATES } from '../../../shared/constants'

/*
 * Action types
 */
const BUILDING_COMP_FETCH = defineAction('BUILDING_COMP_FETCH', DEFAULT_API_ACTION_STATES)
const BUILDING_COMP_CLEAR = defineAction('BUILDING_COMP_CLEAR', DEFAULT_API_ACTION_STATES)
const SET_BUILDING_COMPARABLES_INFO = defineAction('SET_BUILDING_COMPARABLES_INFO')

const BUILDING_COMP_RENT_ROLL_UPLOAD_CSV = defineAction('BUILDING_COMP_RENT_ROLL_UPLOAD_CSV', DEFAULT_API_ACTION_STATES)
const BUILDING_COMP_UPDATE = defineAction('BUILDING_COMP_UPDATE', DEFAULT_API_ACTION_STATES)

const BUILDING_COMPS_PROPERTIES_SEARCH = defineAction('BUILDING_COMPS_PROPERTIES_SEARCH', DEFAULT_API_ACTION_STATES)
const BUILDING_COMPS_SET_MAP_POLYGON = defineAction('BUILDING_COMPS_SET_MAP_POLYGON')

const BUILDING_COMPS_EDIT_SELECTED = defineAction('BUILDING_COMPS_EDIT_SELECTED')

const BUILDING_COMPS_UPDATE_FILTERS = defineAction('BUILDING_COMPS_UPDATE_FILTERS')

const CLEAR_FULL_BUILDING_PROPERTIES = defineAction('CLEAR_FULL_BUILDING_PROPERTIES')
const SET_SELECTED_BUILDING_COMP = defineAction('SET_SELECTED_BUILDING_COMP')
const SEARCH_NEIGHBORHOOD_BY_BOROUGH = defineAction('SEARCH_NEIGHBORHOOD_BY_BOROUGH ', DEFAULT_API_ACTION_STATES)
const CLEAR_NEIGHBORHOOD = defineAction('CLEAR_NEIGHBORHOOD')
const CLEAR_BUILDING_COMP = defineAction('CLEAR_BUILDING_COMP', DEFAULT_API_ACTION_STATES)
const CLEAR_SEARCH_BUILDING_COMP_PROPERTIES = defineAction(
  'CLEAR_SEARCH_BUILDING_COMP_PROPERTIES',
  DEFAULT_API_ACTION_STATES
)

/*
 * Actions
 */
export const buildingCompFetch = createAction(BUILDING_COMP_FETCH.ACTION)
export const buildingCompFetchRequest = createAction(BUILDING_COMP_FETCH.REQUEST)
export const buildingCompFetchReceive = createAction(BUILDING_COMP_FETCH.RECEIVE)
export const buildingCompFetchFail = createAction(BUILDING_COMP_FETCH.FAIL)

export const updateBuildingComp = createAction(BUILDING_COMP_UPDATE.ACTION)

export const buildingCompUpdateRequest = createAction(BUILDING_COMP_UPDATE.REQUEST)
export const buildingCompUpdateReceive = createAction(BUILDING_COMP_UPDATE.RECEIVE)
export const buildingCompUpdateFail = createAction(BUILDING_COMP_UPDATE.FAIL)

export const searchProperties = createAction(BUILDING_COMPS_PROPERTIES_SEARCH.ACTION)

export const searchPropertiesRequest = createAction(BUILDING_COMPS_PROPERTIES_SEARCH.REQUEST)

export const searchPropertiesReceive = createAction(BUILDING_COMPS_PROPERTIES_SEARCH.RECEIVE)

export const searchPropertiesFail = createAction(BUILDING_COMPS_PROPERTIES_SEARCH.FAIL)

export const setBuildingCompsMapPolygon = createAction(BUILDING_COMPS_SET_MAP_POLYGON.ACTION)

export const editSelectedBuildingComps = createAction(BUILDING_COMPS_EDIT_SELECTED.ACTION)

export const updateBuildingCompsFilters = createAction(BUILDING_COMPS_UPDATE_FILTERS.ACTION)

export const buildingCompClear = createAction(BUILDING_COMP_CLEAR.ACTION)
export const setBuildingComparablesInfo = createAction(SET_BUILDING_COMPARABLES_INFO.ACTION)

export const buildingCompRentRollUploadCsv = createAction(BUILDING_COMP_RENT_ROLL_UPLOAD_CSV.ACTION)
export const buildingCompRentRollUploadCsvRequest = createAction(BUILDING_COMP_RENT_ROLL_UPLOAD_CSV.REQUEST)
export const buildingCompRentRollUploadCsvReceive = createAction(BUILDING_COMP_RENT_ROLL_UPLOAD_CSV.RECEIVE)
export const buildingCompRentRollUploadCsvFail = createAction(BUILDING_COMP_RENT_ROLL_UPLOAD_CSV.FAIL)
export const clearProperties = createAction(CLEAR_FULL_BUILDING_PROPERTIES.ACTION)

export const searchNeighborhoodByBorough = createAction(SEARCH_NEIGHBORHOOD_BY_BOROUGH.ACTION)
export const searchNeighborhoodByBoroughRequest = createAction(SEARCH_NEIGHBORHOOD_BY_BOROUGH.REQUEST)
export const searchNeighborhoodByBoroughReceive = createAction(SEARCH_NEIGHBORHOOD_BY_BOROUGH.RECEIVE)
export const searchNeighborhoodByBoroughFail = createAction(SEARCH_NEIGHBORHOOD_BY_BOROUGH.FAIL)
export const clearNeighborhood = createAction(CLEAR_NEIGHBORHOOD.ACTION)
export const clearBuildingComp = createAction(CLEAR_BUILDING_COMP.ACTION)

export const clearSearchBuildingCompProperties = createAction(CLEAR_SEARCH_BUILDING_COMP_PROPERTIES.ACTION)

export const setSelectedBuildingComp = createAction(SET_SELECTED_BUILDING_COMP.ACTION)
