import { GeneratedText, gtConditional, gtIfElse, gtLocked, gtTemplate } from 'shared/utils/narrative/GeneratedText'

import { mapDTO, mapDataModel } from './mappings'

const generate = () => {
  const downwardCompsSentence = gtLocked('downwardCompsSentence')
  const upwardCompsSentence = gtLocked('upwardCompsSentence')
  const interestAppraised = gtLocked('interestAppraised')

  const downwardsMultiples = gtConditional('hasMultipleDownwardAdjustments', gtTemplate`s`)
  const downwardsAdjustmentSentence = gtConditional(
    'hasDownwardAdjustments',
    gtTemplate` Comparable${downwardsMultiples} ${downwardCompsSentence} ${gtIfElse(
      'hasMultipleDownwardAdjustments',
      gtTemplate`have`,
      gtTemplate`has a`
    )} superior property rights, and thus required ${gtIfElse(
      'hasMultipleDownwardAdjustments',
      gtTemplate``,
      gtTemplate` a`
    )} downward adjustment${downwardsMultiples}.`
  )

  const upwardsMultiples = gtConditional('hasMultipleUpwardAdjustments', gtTemplate`s`)

  const upwardsAdjustmentSentence = gtConditional(
    'hasUpwardAdjustments',
    gtTemplate` Comparable${upwardsMultiples} ${upwardCompsSentence} ${gtIfElse(
      'hasMultipleUpwardAdjustments',
      gtTemplate`have`,
      gtTemplate`has`
    )} inferior property rights, and thus required ${gtIfElse(
      'hasMultipleUpwardAdjustments',
      gtTemplate``,
      gtTemplate` an`
    )} upward adjustment${upwardsMultiples}.`
  )

  const containsAdjustmentsDiscussion = gtTemplate`${upwardsAdjustmentSentence}${downwardsAdjustmentSentence}`
  const doesNotContainAdjustmentsDiscussion = gtTemplate` We are valuing the ${interestAppraised} 
  in the subject property, as reflected by all of the comparables. Thus, no adjustments were required.`

  const discussion = gtIfElse(
    'shouldGenerateWriteup',
    gtTemplate`${containsAdjustmentsDiscussion}`,
    gtTemplate`${doesNotContainAdjustmentsDiscussion}`
  )

  const gt = new GeneratedText()
  gt.t`The purpose of this adjustment is to account for differences in the property rights transferred 
  with the sale.${discussion}`

  return gt.getParts()
}

export default { generate, mapDTO, mapDataModel }
