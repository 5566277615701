import { INTRODUCTION_PATH } from 'shared/constants/report/keysAndDataPaths'

const FORM_HEADING = 'Introduction'
const DATA_PATH = INTRODUCTION_PATH

export const LIST_TYPE = {
  UL: 'ul',
  OL: 'ol',
}

export const PAGE_HEADER = 'Preview & Edit Report Introduction'
export const PAGE_SECTION_HEADER = 'Introduction'

export const PURPOSE_AND_DATE_OF_VALUE_HEADER = 'Purpose & Date of Value'
export const CLIENT_IDENTIFICATION_HEADER = 'Identification of the Client'
export const DEFINITION_OF_MARKET_VALUE_HEADER = 'Definition of Market Value'
export const DEFINITION_OF_MARKET_VALUE_LIST = [
  'buyer and seller are typically motivated;',
  'both parties are well informed or well advised, and each acting in what he or she considers his or her own best interest;',
  'a reasonable time is allowed for exposure in the open market;',
  'payment is made in terms of cash in U.S. dollars or in terms of financial arrangements comparable thereto; and',
  'the price represents the normal consideration for the property sold unaffected by special or creative financing or sales concessions granted by anyone associated with the sale.',
]
export const INTENDED_USES_HEADER = 'Intended Use & User'
export const PROPERTY_HISTORY_HEADER = 'Property History'
export const EXPOSURE_TIME_HEADER = 'Exposure Time'
export const MARKETING_TIME_HEADER = 'Marketing Time'
export const SCOPE_OF_THE_APPRAISAL_HEADER = 'Scope of the appraisal'
export const SCOPE_OF_THE_APPRAISAL_INTRODUCTION = 'Within the course of this assignment, we have:'
export const GENERAL_ASSUMPTION_HEADER = 'General Assumptions'
export const GENERAL_ASSUMPTION_DISCUSSION_HEADER = 'General Assumptions Discussion'
export const GENERAL_ASSUMPTIONS_INTRODUCTION = 'We note that our appraisers are not experts in the following domains:'
export const DATA_SOURCES_HEADER = 'Data Sources'
export const PROPERTY_RIGHTS_APPRAISED_HEADER = 'Property Rights Appraised'

export { FORM_HEADING, DATA_PATH }
