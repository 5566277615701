import React from 'react'
import { Node, Value, ValueJSON } from 'slate'
import { escapeRegExp } from 'lodash'
import { isValueJSON } from 'shared/utils/narrative'

import Plain from './plainSerializer'

const CAPTURE_REGEX = /=([a-zA-Z0-9\s]*)$/

type ValueLike = Value | ValueJSON

export const isModified = (prevNarrative: Value | null, nextNarrative: Value) => {
  if (!prevNarrative) {
    return true
  }

  let prevNodes = prevNarrative.document.nodes.toJS()
  let nextNodes = nextNarrative.document.nodes.toJS()
  const prevSerialized = Plain.serialize(prevNarrative)
  const nextSerialized = Plain.serialize(nextNarrative)
  if (prevSerialized !== nextSerialized) {
    return true
  }

  while (prevNodes.length && nextNodes.length) {
    let cachedPrevNodes: any[] = []
    let cachedNextNodes: any[] = []
    if (areNodesModified(prevNodes, nextNodes)) {
      return true
    } else {
      for (let i = 0; i < prevNodes.length; i++) {
        if (prevNodes[i].type !== nextNodes[i].type) {
          return true
        }

        if (prevNodes[i].marks && prevNodes[i].marks.length !== nextNodes[i].marks.length) {
          return true
        }

        if (prevNodes[i].nodes && nextNodes[i].nodes) {
          cachedPrevNodes = [...cachedPrevNodes, ...prevNodes[i].nodes]
          cachedNextNodes = [...cachedNextNodes, ...nextNodes[i].nodes]
        }
      }
      prevNodes = cachedPrevNodes
      nextNodes = cachedNextNodes
    }
  }

  return false
}

const areNodesModified = (prevNodes: any[], nextNodes: any[]) => prevNodes.length !== nextNodes.length

export const areNarrativesEqual = (narrativeA: ValueLike, narrativeB: ValueLike) => {
  const valueA = narrativeA instanceof Value ? narrativeA : Value.fromJS(narrativeA || {})
  const valueB = narrativeB instanceof Value ? narrativeB : Value.fromJS(narrativeB || {})

  return !isModified(valueA, valueB)
}

export const isNarrativeValue = (value: unknown): value is ValueLike => {
  if (value instanceof Value) {
    return true
  }

  return isValueJSON(value)
}

export const isConditional = (node?: Node): node is Node => !!node && 'type' in node && node.type === 'conditional'

export const isHiddenConditional = (node?: Node) => isConditional(node) && !node.get('data').get('display')

const getPreviousDisplayedText = (value: Value, startNode: Node): string | undefined => {
  const { document } = value

  if (!startNode) {
    return
  }

  const previousText = document.getPreviousText(startNode.key)
  if (!previousText) {
    return
  }

  const ancestors = document.getAncestors(previousText.key) || []
  const ancestorIsHidden = ancestors.some(isHiddenConditional)

  if (ancestorIsHidden) {
    return getPreviousDisplayedText(value, previousText)
  }

  return previousText.text
}

export const getSuggestedInput = (value: Value) => {
  if (!value.startText) {
    return null
  }
  const startOffset = value.selection.start.offset

  let textBefore = value.startText.text.slice(0, startOffset)

  if (startOffset === 0) {
    const { document } = value
    const startNode = document.getDescendant(value.startText.key)

    if (startNode) {
      textBefore = getPreviousDisplayedText(value, startNode) || ''
    }
  }

  return CAPTURE_REGEX.exec(textBefore)
}

/**
 * Like String.split, but the returned array includes the separator as well.
 */
export const splitAndKeepSeparators = (target: string, search: string | RegExp): string[] => {
  const escapedSearch = search instanceof RegExp ? search : escapeRegExp(search)
  const regex = new RegExp(escapedSearch, 'gi')
  const matches = target.match(regex) || []
  const result = []

  let lastIndex = 0

  matches.forEach(match => {
    const index = target.indexOf(match, lastIndex)
    const before = target.slice(lastIndex, index)

    result.push(before)
    result.push(match)

    lastIndex = index + match.length
  })

  result.push(target.slice(lastIndex))
  return result
}

export const HighlightSubstrings = ({
  text,
  search,
  renderMatch,
}: {
  text: string
  search: string | RegExp
  renderMatch: (match: string) => JSX.Element
}) => {
  const parts = splitAndKeepSeparators(text, search)

  return (
    <>
      {parts.map((part, index) => {
        const isMatch = index % 2 === 1

        if (!isMatch) {
          return part
        }

        return renderMatch(part)
      })}
    </>
  )
}
