import {
  MISCELLANEOUS_INCOME_KEY,
  LAUNDRY_INCOME_KEY,
  STORAGE_INCOME_KEY,
  PARKING_INCOME_KEY,
  OTHER_INCOME_KEY,
} from 'shared/constants/report/keysAndDataPaths'

import LaundryIncome from '../../../forms/income/miscellaneous/LaundryIncome'
import StorageIncome from '../../../forms/income/miscellaneous/StorageIncome'
import ParkingIncome from '../../../forms/income/miscellaneous/ParkingIncome'
import OtherIncome from '../../../forms/income/miscellaneous/OtherIncome'

export default [
  {
    key: MISCELLANEOUS_INCOME_KEY,
    name: 'Miscellaneous',
  },
  {
    key: LAUNDRY_INCOME_KEY,
    name: 'Laundry',
    formComponent: LaundryIncome,
  },
  {
    key: STORAGE_INCOME_KEY,
    name: 'Storage',
    formComponent: StorageIncome,
  },
  {
    key: PARKING_INCOME_KEY,
    name: 'Parking',
    formComponent: ParkingIncome,
  },
  {
    key: OTHER_INCOME_KEY,
    name: 'Other',
    formComponent: OtherIncome,
  },
]
