import React from 'react'
import PropTypes from 'prop-types'

import { Avatar, Tooltip } from '@mui/material'
import { withStyles } from '@mui/styles'
import { get } from 'lodash'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import { compose } from 'recompose'

import { getUserInitials } from 'shared/helpers/userHelper'

import { logoutUser } from '../../../../authentication/redux/actions'
import layoutStyles from '../../styles'
import { UserMenuItems } from '../constants'

import PopupMenu from './PopupMenu'

const styles = theme => ({
  link: layoutStyles.menuPopupItem,
  avatar: {
    width: 32,
    height: 32,
    fontSize: 17,
  },
  avatarWrapper: {
    '& button': {
      padding: 8,
    },
  },
})

class UserMenu extends React.PureComponent {
  static propTypes = {
    avatarUrl: PropTypes.string,
    logoutUser: PropTypes.func.isRequired,
    userInitials: PropTypes.string.isRequired,
  }

  static defaultProps = {
    avatarUrl: null,
  }

  renderMenuItem = item => {
    const { classes, logoutUser } = this.props

    if (item === UserMenuItems.PROFILE) {
      return (
        <Link className={classes.link} to="/user">
          {item}
        </Link>
      )
    }

    if (item === UserMenuItems.ORGANIZATION) {
      return (
        <Link className={classes.link} to="/organization">
          {item}
        </Link>
      )
    }

    if (item === UserMenuItems.LOG_OUT) {
      return (
        <span className={classes.link} onClick={logoutUser}>
          {item}
        </span>
      )
    }
  }

  renderMenuIcon = () => {
    const { classes, avatarUrl, userInitials } = this.props

    return (
      <Tooltip title="Profile & Organization" placement="right">
        <Avatar src={avatarUrl} alt={userInitials} className={classes.avatar}>
          {userInitials}
        </Avatar>
      </Tooltip>
    )
  }

  render() {
    const { classes } = this.props

    return (
      <div className={classes.avatarWrapper}>
        <PopupMenu
          renderMenuIcon={this.renderMenuIcon}
          renderMenuItem={this.renderMenuItem}
          menuItems={[UserMenuItems.PROFILE, UserMenuItems.ORGANIZATION, UserMenuItems.LOG_OUT]}
          placement="right-end"
        />
      </div>
    )
  }
}

export default compose(
  connect(
    state => {
      const user = get(state, 'authentication.user', {})

      return {
        avatarUrl: get(user, 'avatar.cdnUrl'),
        userInitials: getUserInitials(user),
      }
    },
    dispatch => ({
      logoutUser: () => dispatch(logoutUser()),
    })
  ),
  withStyles(styles)
)(UserMenu)
