import PropTypes from 'prop-types'

export const propertySearchResultsPropTypes = PropTypes.shape({
  id: PropTypes.string.isRequired,
  isSelected: PropTypes.bool,
  address: PropTypes.string.isRequired,
  units: PropTypes.number.isRequired,
  averageRent: PropTypes.number,
  subType: PropTypes.string,
  imageUrl: PropTypes.string,
  amenities: PropTypes.object,
  unitMix: PropTypes.arrayOf(
    PropTypes.shape({
      groupingKey: PropTypes.string.isRequired,
      groupingName: PropTypes.string.isRequired,
      units: PropTypes.number,
      avgBathrooms: PropTypes.number,
      avgRooms: PropTypes.number,
      minRent: PropTypes.number,
      avgRent: PropTypes.number,
      maxRent: PropTypes.number,
    })
  ),
})
