export const STATE_NAMES_SHORT = {
  NY: 'NY',
  AL: 'AL',
  AK: 'AK',
  AZ: 'AZ',
  AR: 'AR',
  CA: 'CA',
  CO: 'CO',
  CT: 'CT',
  DE: 'DE',
  DC: 'DC',
  FL: 'FL',
  GA: 'GA',
  HI: 'HI',
  ID: 'ID',
  IL: 'IL',
  IN: 'IN',
  IA: 'IA',
  KS: 'KS',
  KY: 'KY',
  LA: 'LA',
  ME: 'ME',
  MD: 'MD',
  MA: 'MA',
  MI: 'MI',
  MN: 'MN',
  MS: 'MS',
  MO: 'MO',
  MT: 'MT',
  NE: 'NE',
  NV: 'NV',
  NH: 'NH',
  NJ: 'NJ',
  NM: 'NM',
  NC: 'NC',
  ND: 'ND',
  OH: 'OH',
  OK: 'OK',
  OR: 'OR',
  PA: 'PA',
  PR: 'PR',
  RI: 'RI',
  SC: 'SC',
  SD: 'SD',
  TN: 'TN',
  TX: 'TX',
  UT: 'UT',
  VT: 'VT',
  VA: 'VA',
  WA: 'WA',
  WV: 'WV',
  WI: 'WI',
  WY: 'WY',
}

export const STATE_NAMES = {
  NY: 'New York',
  AL: 'Alabama',
  AK: 'Alaska',
  AZ: 'Arizona',
  AR: 'Arkansas',
  CA: 'California',
  CO: 'Colorado',
  CT: 'Connecticut',
  DE: 'Delaware',
  DC: 'District Of Columbia',
  FL: 'Florida',
  GA: 'Georgia',
  HI: 'Hawaii',
  ID: 'Idaho',
  IL: 'Illinois',
  IN: 'Indiana',
  IA: 'Iowa',
  KS: 'Kansas',
  KY: 'Kentucky',
  LA: 'Louisiana',
  ME: 'Maine',
  MD: 'Maryland',
  MA: 'Massachusetts',
  MI: 'Michigan',
  MN: 'Minnesota',
  MS: 'Mississippi',
  MO: 'Missouri',
  MT: 'Montana',
  NE: 'Nebraska',
  NV: 'Nevada',
  NH: 'New Hampshire',
  NJ: 'New Jersey',
  NM: 'New Mexico',
  NC: 'North Carolina',
  ND: 'North Dakota',
  OH: 'Ohio',
  OK: 'Oklahoma',
  OR: 'Oregon',
  PA: 'Pennsylvania',
  PR: 'Puerto Rico',
  RI: 'Rhode Island',
  SC: 'South Carolina',
  SD: 'South Dakota',
  TN: 'Tennessee',
  TX: 'Texas',
  UT: 'Utah',
  VT: 'Vermont',
  VA: 'Virginia',
  WA: 'Washington',
  WV: 'West Virginia',
  WI: 'Wisconsin',
  WY: 'Wyoming',
}

export const STATE_OPTIONS = [
  { name: STATE_NAMES.NY, abbr: STATE_NAMES_SHORT.NY, id: 1 },
  { name: STATE_NAMES.AL, abbr: STATE_NAMES_SHORT.AL, id: 2 },
  { name: STATE_NAMES.AK, abbr: STATE_NAMES_SHORT.AK, id: 3 },
  { name: STATE_NAMES.AZ, abbr: STATE_NAMES_SHORT.AZ, id: 4 },
  { name: STATE_NAMES.AR, abbr: STATE_NAMES_SHORT.AR, id: 5 },
  { name: STATE_NAMES.CA, abbr: STATE_NAMES_SHORT.CA, id: 6 },
  { name: STATE_NAMES.CO, abbr: STATE_NAMES_SHORT.CO, id: 7 },
  { name: STATE_NAMES.CT, abbr: STATE_NAMES_SHORT.CT, id: 8 },
  { name: STATE_NAMES.DE, abbr: STATE_NAMES_SHORT.DE, id: 9 },
  { name: STATE_NAMES.DC, abbr: STATE_NAMES_SHORT.DC, id: 10 },
  { name: STATE_NAMES.FL, abbr: STATE_NAMES_SHORT.FL, id: 11 },
  { name: STATE_NAMES.GA, abbr: STATE_NAMES_SHORT.GA, id: 12 },
  { name: STATE_NAMES.HI, abbr: STATE_NAMES_SHORT.HI, id: 13 },
  { name: STATE_NAMES.ID, abbr: STATE_NAMES_SHORT.ID, id: 14 },
  { name: STATE_NAMES.IL, abbr: STATE_NAMES_SHORT.IL, id: 15 },
  { name: STATE_NAMES.IN, abbr: STATE_NAMES_SHORT.IN, id: 16 },
  { name: STATE_NAMES.IA, abbr: STATE_NAMES_SHORT.IA, id: 17 },
  { name: STATE_NAMES.KS, abbr: STATE_NAMES_SHORT.KS, id: 18 },
  { name: STATE_NAMES.KY, abbr: STATE_NAMES_SHORT.KY, id: 19 },
  { name: STATE_NAMES.LA, abbr: STATE_NAMES_SHORT.LA, id: 20 },
  { name: STATE_NAMES.ME, abbr: STATE_NAMES_SHORT.ME, id: 21 },
  { name: STATE_NAMES.MD, abbr: STATE_NAMES_SHORT.MD, id: 22 },
  { name: STATE_NAMES.MA, abbr: STATE_NAMES_SHORT.MA, id: 23 },
  { name: STATE_NAMES.MI, abbr: STATE_NAMES_SHORT.MI, id: 24 },
  { name: STATE_NAMES.MN, abbr: STATE_NAMES_SHORT.MN, id: 25 },
  { name: STATE_NAMES.MS, abbr: STATE_NAMES_SHORT.MS, id: 26 },
  { name: STATE_NAMES.MO, abbr: STATE_NAMES_SHORT.MO, id: 27 },
  { name: STATE_NAMES.MT, abbr: STATE_NAMES_SHORT.MT, id: 28 },
  { name: STATE_NAMES.NE, abbr: STATE_NAMES_SHORT.NE, id: 29 },
  { name: STATE_NAMES.NV, abbr: STATE_NAMES_SHORT.NV, id: 30 },
  { name: STATE_NAMES.NH, abbr: STATE_NAMES_SHORT.NH, id: 31 },
  { name: STATE_NAMES.NJ, abbr: STATE_NAMES_SHORT.NJ, id: 32 },
  { name: STATE_NAMES.NM, abbr: STATE_NAMES_SHORT.NM, id: 33 },
  { name: STATE_NAMES.NC, abbr: STATE_NAMES_SHORT.NC, id: 34 },
  { name: STATE_NAMES.ND, abbr: STATE_NAMES_SHORT.ND, id: 35 },
  { name: STATE_NAMES.OH, abbr: STATE_NAMES_SHORT.OH, id: 36 },
  { name: STATE_NAMES.OK, abbr: STATE_NAMES_SHORT.OK, id: 37 },
  { name: STATE_NAMES.OR, abbr: STATE_NAMES_SHORT.OR, id: 38 },
  { name: STATE_NAMES.PA, abbr: STATE_NAMES_SHORT.PA, id: 39 },
  { name: STATE_NAMES.PR, abbr: STATE_NAMES_SHORT.PR, id: 40 },
  { name: STATE_NAMES.RI, abbr: STATE_NAMES_SHORT.RI, id: 41 },
  { name: STATE_NAMES.SC, abbr: STATE_NAMES_SHORT.SC, id: 42 },
  { name: STATE_NAMES.SD, abbr: STATE_NAMES_SHORT.SD, id: 43 },
  { name: STATE_NAMES.TN, abbr: STATE_NAMES_SHORT.TN, id: 44 },
  { name: STATE_NAMES.TX, abbr: STATE_NAMES_SHORT.TX, id: 45 },
  { name: STATE_NAMES.UT, abbr: STATE_NAMES_SHORT.UT, id: 46 },
  { name: STATE_NAMES.VT, abbr: STATE_NAMES_SHORT.VT, id: 47 },
  { name: STATE_NAMES.VA, abbr: STATE_NAMES_SHORT.VA, id: 48 },
  { name: STATE_NAMES.WA, abbr: STATE_NAMES_SHORT.WA, id: 49 },
  { name: STATE_NAMES.WV, abbr: STATE_NAMES_SHORT.WV, id: 51 },
  { name: STATE_NAMES.WY, abbr: STATE_NAMES_SHORT.WY, id: 52 },
]
