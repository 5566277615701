import { get, lowerCase, some } from 'lodash'
import indefiniteArticle from 'indefinite-article'

import {
  generateCompsIndicesSentence,
  getCompsFromFormValues,
  mapDataModelForAdjustmentsNarrative,
} from 'shared/utils/textGeneration/salesApproach'
import { SALES_APPROACH_TYPES } from 'shared/constants/salesApproach'
import { PropertyTypes } from 'shared/constants'
import { PROPERTY_TYPES_PROPER_CASE, COMMERCIAL_SUBTYPES_PROPER_CASE } from 'shared/constants/property/propertyTypes'
import { ADJUSTMENT_GROUPS } from 'shared/helpers/salesApproach/salesCompAdjustmentConstants'
import { getCustomRowNames, hasAdjustments } from 'shared/helpers/salesApproach/salesCompAdjustments'
import { getLastWordSeparator } from 'shared/utils/sentenceBuilder'
import { isCornerLot } from 'shared/utils/report'
import { getConditionValue } from 'shared/utils/report/propertyInformation'
import { mapBuildingAmenities, mapSalesAdjustmentAmenities, mapUnitAmenities } from 'shared/helpers/amenities'

const generateUtilityAdjustmentText = compAdjustments => {
  const hasUtilityAdjustments = some(compAdjustments, compAdjustment => {
    return some(
      compAdjustment.adjustments,
      adjustment => adjustment?.groupName === ADJUSTMENT_GROUPS.UTILITY && !!adjustment?.value
    )
  })
  return hasUtilityAdjustments
    ? 'Based on these factors, the following adjustments were made to the comparables,' +
        ' while other minor differences were considered qualitatively in the sale value conclusion.'
    : 'All comparables used in this analysis have similar utility; therefore, no adjustments were required.'
}

const mapAmenitiesData = values => {
  const {
    hasDownwardAdjustments: hasAmenitiesDownwardAdjustments,
    hasMultipleDownwardAdjustments: hasMultipleAmenitiesDownwardAdjustments,
    hasUpwardAdjustments: hasAmenitiesUpwardAdjustments,
    hasMultipleUpwardAdjustments: hasMultipleAmenitiesUpwardAdjustments,
    downwardCompsSentence: amenitiesDownwardCompsSentence,
    upwardCompsSentence: amenitiesUpwardCompsSentence,
    shouldGenerateWriteup: generateAmenitiesWriteup,
  } = mapDataModelForAdjustmentsNarrative(values, 'amenities', ADJUSTMENT_GROUPS.UTILITY)

  return {
    generateAmenitiesWriteup,
    hasAmenitiesDownwardAdjustments,
    hasMultipleAmenitiesDownwardAdjustments,
    amenitiesDownwardCompsSentence,
    hasAmenitiesUpwardAdjustments,
    hasMultipleAmenitiesUpwardAdjustments,
    amenitiesUpwardCompsSentence,
  }
}

const mapCornerData = values => {
  const {
    hasDownwardAdjustments: hasCornerDownwardAdjustments,
    hasMultipleDownwardAdjustments: hasMultipleCornerDownwardAdjustments,
    hasUpwardAdjustments: hasCornerUpwardAdjustments,
    hasMultipleUpwardAdjustments: hasMultipleCornerUpwardAdjustments,
    downwardCompsSentence: cornerDownwardCompsSentence,
    upwardCompsSentence: cornerUpwardCompsSentence,
    shouldGenerateWriteup: generateCornerWriteup,
  } = mapDataModelForAdjustmentsNarrative(values, 'corner', ADJUSTMENT_GROUPS.UTILITY)

  return {
    isCornerLot: isCornerLot(values.corner),
    generateCornerWriteup,
    hasCornerDownwardAdjustments,
    hasMultipleCornerDownwardAdjustments,
    cornerDownwardCompsSentence,
    hasCornerUpwardAdjustments,
    hasMultipleCornerUpwardAdjustments,
    cornerUpwardCompsSentence,
  }
}

const mapAirRightsData = values => {
  const { subjectUnusedAirRights } = values
  const hasUnusedAirRights = subjectUnusedAirRights === 0
  const {
    hasDownwardAdjustments: hasAirRightsDownwardAdjustments,
    hasMultipleDownwardAdjustments: hasMultipleAirRightsDownwardAdjustments,
    hasUpwardAdjustments: hasAirRightsUpwardAdjustments,
    hasMultipleUpwardAdjustments: hasMultipleAirRightsUpwardAdjustments,
    downwardCompsSentence: airRightsDownwardCompsSentence,
    upwardCompsSentence: airRightsUpwardCompsSentence,
    hasMultipleCompAdjustments,
    shouldGenerateWriteup: generateAirRightsWriteup,
  } = mapDataModelForAdjustmentsNarrative(values, 'airRights', ADJUSTMENT_GROUPS.UTILITY)

  return {
    hasUnusedAirRights,
    generateAirRightsWriteup,
    hasAirRightsDownwardAdjustments,
    hasMultipleAirRightsDownwardAdjustments,
    airRightsDownwardCompsSentence,
    hasAirRightsUpwardAdjustments,
    hasMultipleAirRightsUpwardAdjustments,
    airRightsUpwardCompsSentence,
    hasMultipleCompAdjustments,
  }
}

const mapElevatorData = values => {
  const { isElevator } = values
  const {
    hasDownwardAdjustments: hasElevatorDownwardAdjustments,
    hasMultipleDownwardAdjustments: hasMultipleElevatorDownwardAdjustments,
    hasUpwardAdjustments: hasElevatorUpwardAdjustments,
    hasMultipleUpwardAdjustments: hasMultipleElevatorUpwardAdjustments,
    downwardCompsSentence: elevatorDownwardCompsSentence,
    upwardCompsSentence: elevatorUpwardCompsSentence,
    hasMultipleCompAdjustments,
    shouldGenerateWriteup: generateElevatorWriteup,
  } = mapDataModelForAdjustmentsNarrative(values, 'elevator', ADJUSTMENT_GROUPS.UTILITY)

  return {
    generateElevatorWriteup,
    hasElevatorDownwardAdjustments,
    hasMultipleElevatorDownwardAdjustments,
    elevatorDownwardCompsSentence,
    hasElevatorUpwardAdjustments,
    hasMultipleElevatorUpwardAdjustments,
    elevatorUpwardCompsSentence,
    hasMultipleCompAdjustments,
    subjectElevator: isElevator ? 'has an elevator' : 'is a walk-up',
  }
}

const mapFinishesData = values => {
  const {
    hasDownwardAdjustments: hasFinishesDownwardAdjustments,
    hasMultipleDownwardAdjustments: hasMultipleFinishesDownwardAdjustments,
    hasUpwardAdjustments: hasFinishesUpwardAdjustments,
    hasMultipleUpwardAdjustments: hasMultipleFinishesUpwardAdjustments,
    downwardCompsSentence: finishesDownwardCompsSentence,
    upwardCompsSentence: finishesUpwardCompsSentence,
    hasMultipleCompAdjustments,
    shouldGenerateWriteup: generateFinishesWriteup,
  } = mapDataModelForAdjustmentsNarrative(values, 'finishes', ADJUSTMENT_GROUPS.UTILITY)

  return {
    generateFinishesWriteup,
    hasFinishesDownwardAdjustments,
    hasMultipleFinishesDownwardAdjustments,
    finishesDownwardCompsSentence,
    hasFinishesUpwardAdjustments,
    hasMultipleFinishesUpwardAdjustments,
    finishesUpwardCompsSentence,
    hasMultipleCompAdjustments,
  }
}

const mapOtherUtilityAdjustmentData = values => {
  const { compAdjustments = [] } = values
  const rowTitles = compAdjustments.length ? getCustomRowNames(compAdjustments, ADJUSTMENT_GROUPS.UTILITY) : []

  return rowTitles.map(rowTitle => {
    const { upwardComps, downwardComps } = getCompsFromFormValues(values, rowTitle, ADJUSTMENT_GROUPS.UTILITY)

    return {
      adjustmentTitle: rowTitle,
      shouldGenerateWriteup: hasAdjustments(compAdjustments, rowTitle, ADJUSTMENT_GROUPS.UTILITY),
      hasUpwardAdjustments: upwardComps.length > 0,
      hasMultipleUpwardAdjustments: upwardComps.length > 1,
      hasDownwardAdjustments: downwardComps.length > 0,
      hasMultipleDownwardAdjustments: downwardComps.length > 1,
      downwardCompsSentence: generateCompsIndicesSentence(downwardComps, getLastWordSeparator),
      upwardCompsSentence: generateCompsIndicesSentence(upwardComps, getLastWordSeparator),
    }
  })
}

const mapData = formValues => {
  const {
    propertyType,
    propertySubtype,
    corner,
    isElevator,
    typicalPropertyConditions = {},
    amenities = [],
    compAdjustments = [],
    type,
    useCommercialSubtype,
  } = formValues

  const isLandValue = type === SALES_APPROACH_TYPES.LAND

  const elevatored = isElevator ? 'elevatored' : 'walk-up'

  let propertyTypeWithArticleText = `a ${lowerCase(PROPERTY_TYPES_PROPER_CASE[propertyType])}`
  const isCommercial = propertyType === PropertyTypes.COMMERCIAL
  if (isCommercial && useCommercialSubtype && propertySubtype) {
    const propertySubtypeLowerCase = lowerCase(COMMERCIAL_SUBTYPES_PROPER_CASE[propertySubtype])
    propertyTypeWithArticleText = `${indefiniteArticle(propertySubtypeLowerCase)} ${propertySubtypeLowerCase}`
  }

  const cornerText = lowerCase(corner)
  const kitchenCondition = lowerCase(typicalPropertyConditions.kitchen?.condition)
  const livingCondition = lowerCase(typicalPropertyConditions.living?.condition)
  const bedroomCondition = lowerCase(typicalPropertyConditions.bedroom?.condition)
  const bathroomCondition = lowerCase(typicalPropertyConditions.bathroom?.condition)
  const amenitiesText = amenities.join(', ')
  const hasAmenities = amenities.length > 0
  const utilityAdjustmentText = generateUtilityAdjustmentText(compAdjustments)
  const isMultifamily = propertyType === PropertyTypes.MULTI_FAMILY
  const amenitiesData = mapAmenitiesData(formValues)
  const elevatorData = mapElevatorData(formValues)
  const cornerData = mapCornerData(formValues)
  const finishesData = mapFinishesData(formValues)
  const airRightsData = mapAirRightsData(formValues)
  const otherUtilityAdjustmentData = mapOtherUtilityAdjustmentData(formValues)

  return {
    isLandValue,
    elevatored,
    propertyTypeWithArticleText,
    cornerText,
    kitchenCondition,
    livingCondition,
    bedroomCondition,
    bathroomCondition,
    amenitiesText,
    hasAmenities,
    utilityAdjustmentText,
    isCommercial,
    isMultifamily,
    ...elevatorData,
    ...amenitiesData,
    ...cornerData,
    ...finishesData,
    ...airRightsData,
    otherUtilityAdjustmentData,
  }
}

export const mapDTO = formValues => {
  return mapData(formValues)
}

export const mapDataModel = (report, parent) => {
  if (!parent.parent()) {
    return undefined
  }

  const salesApproach = parent.parent()
  const { type, salesCompsAdjustments = {}, salesComps = {} } = salesApproach
  const { compAdjustments = [] } = salesCompsAdjustments
  const { selectedComps = [] } = salesComps

  const propertyInformation = get(report, 'property_information')
  if (!propertyInformation) {
    return undefined
  }

  const { type: propertyType, propertySubtype, corner, elevator } = propertyInformation

  const isElevator = !!elevator

  const typicalUnitDescription = get(report, 'residential.typicalUnitDescription', {})
  const kitchenCondition = getConditionValue(typicalUnitDescription, 'kitchen.condition', null)
  const livingCondition = getConditionValue(typicalUnitDescription, 'living.condition', null)
  const bedroomCondition = getConditionValue(typicalUnitDescription, 'bedroom.condition', null)
  const bathroomCondition = getConditionValue(typicalUnitDescription, 'bathroom.condition', null)

  const buildingAmenities = mapBuildingAmenities(report)
  const unitAmenities = mapUnitAmenities(report)

  const amenities = mapSalesAdjustmentAmenities({ ...buildingAmenities, ...unitAmenities })

  const useCommercialSubtype = get(report, 'featureFlags.use-commercial-subtype')

  // todo - add bug ticket for missing field `subjectUnusedAirRights`
  // todo - add bug for missing "elevator" field on comps

  return mapData({
    propertyType,
    propertySubtype,
    corner,
    type,
    isElevator,
    compAdjustments,
    selectedComps,
    amenities,
    typicalPropertyConditions: {
      kitchen: { condition: kitchenCondition },
      living: { condition: livingCondition },
      bedroom: { condition: bedroomCondition },
      bathroom: { condition: bathroomCondition },
    },
    useCommercialSubtype,
  })
}
