import React from 'react'
import PropTypes from 'prop-types'

import { connect } from 'react-redux'
import { get } from 'lodash'

import { buildSlateWriteup } from 'shared/utils/textGeneration/writeupBuilder'

import Callout from '../../../../../../shared/components/Callout'
import GeneratedComment from '../../../../../../shared/components/GeneratedComment/EditableGeneratedCommentWithAppraiserComment'

const TOOLTIP_TEXT =
  "The following generated text and appraiser commentary will appear in the Income Approach's Rent Adjustments section of your report."

class RentStabilizedDiscussion extends React.PureComponent {
  static propTypes = {
    isNYReport: PropTypes.bool.isRequired,
    rentStabilizedDiscussion: PropTypes.any,
  }

  generateRentStabilizedDiscussion = () => {
    const { rentStabilizedDiscussion } = this.props
    const writeup = buildSlateWriteup(rentStabilizedDiscussion)
    if (Array.isArray(writeup)) {
      return writeup.join('\n')
    }
    return writeup
  }

  render() {
    const { isNYReport } = this.props
    const content =
      'We do not have the specific Rent Controlled Increases Discussion for your geography. Type it in the Appraiser Commentary field below.'

    if (isNYReport) {
      return (
        <GeneratedComment
          tooltipText={TOOLTIP_TEXT}
          title="Generated Commentary"
          label="Rent Stabilized Increases Discussion"
          getGeneratedText={this.generateRentStabilizedDiscussion}
          dataPath="rentStabilizedDiscussion"
          isDynamicContent
        />
      )
    } else {
      return (
        <React.Fragment>
          <Callout variant="info" content={content} />
          <GeneratedComment
            tooltipText={TOOLTIP_TEXT}
            label="Rent Stabilized Increases Discussion"
            dataPath="rentStabilizedDiscussion"
            isDynamicContent
          />
        </React.Fragment>
      )
    }
  }
}

export default connect(state => {
  const rentStabilizedDiscussion = get(
    state,
    'report.reportData.organizationSettings.rentStabilization.rentStabilizedDiscussion',
    {}
  )

  return { rentStabilizedDiscussion }
})(RentStabilizedDiscussion)
