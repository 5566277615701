import { get, forIn, kebabCase, isEmpty, find, findLast } from 'lodash'

import { createSelector } from 'reselect'

import { getReportForms } from '../constants/forms/reportFormsInstance'

import { REPORT_STRUCTURE_TYPES } from '../../../../libs/bowery-libs/constants/report'

import { getFormName } from './helpers'

type ReportForm = {
  key: string
  name: string
  link?: string
  anchor?: string
  formComponent?: Function
}

type Descendant = {
  showForm: boolean
  descendants?: Descendants
  type?: string
  anchor?: string
}

type Descendants = {
  [key: string]: Descendant
}

type Form = {
  key: string
  section: string
  path: string
  descendants: Descendants
  showForm?: boolean
  anchor?: string
  type?: string
}

export const sectionNameSelector = createSelector(
  state => get(state, 'shared.drawer.sectionName'),
  state => get(state, 'shared.drawer.isDrawerOpen'),
  (sectionName, isDrawerOpen) => {
    return { sectionName, isDrawerOpen }
  }
)

const generateFormsList = (
  reportStructure: Record<string, any>,
  section?: string,
  parentPath?: string | null
): Form[] => {
  const items: Form[] = []

  forIn(reportStructure, (value, key) => {
    const path = kebabCase(key)
    const currentSection = section || path

    let targetPath = null
    if (value.showForm || value.type || !isEmpty(value.descendants)) {
      const defaultFormValues = getReportForms().find((reportForm: ReportForm) => reportForm.key === key) || {}
      const formPath = defaultFormValues.link ? defaultFormValues.link : path

      if ([REPORT_STRUCTURE_TYPES.ONE_PAGER_SECTION].includes(value.type)) {
        targetPath = formPath
      }

      items.push({
        ...value,
        key,
        section: currentSection,
        path: parentPath || formPath,
        anchor: defaultFormValues.anchor,
      })
    }

    if (!isEmpty(value.descendants)) {
      const nestedItems = generateFormsList(value.descendants, currentSection, targetPath)
      items.push(...nestedItems)
    }
  })

  return items
}

const getPrevFormPath = (forms: Form[], index: number) => {
  const form = findLast(forms, 'showForm', index - 1) || findLast(forms, 'showForm')
  return get(form, 'path')
}

const getNextFormPath = (forms: Form[], index: number, currentFormPath: string) => {
  const form =
    find(
      forms,
      form => {
        return form.showForm && form.path !== currentFormPath
      },
      index + 1
    ) || find(forms, 'showForm')
  return get(form, 'path')
}

export const formsListSelector = createSelector(
  state => get(state, 'report.reportStructure') || {},
  state => get(state, 'report.reportData._id'),
  state => get(state, 'report.reportSettings'),
  (reportStructure, reportId, reportSettings) => {
    const formsList = generateFormsList(reportStructure)

    return formsList.map(({ descendants, ...form }, index) => {
      const defaultFormValues = getReportForms().find((reportForm: ReportForm) => reportForm.key === form.key)
      const title = getFormName(defaultFormValues, reportSettings)

      if (!isEmpty(descendants)) {
        return {
          ...form,
          id: form.section,
          title,
          ...(form.type &&
            [REPORT_STRUCTURE_TYPES.ONE_PAGER_SECTION].includes(form.type) && {
              path: `/report/${reportId}/${form.path}`,
            }),
        }
      }

      return {
        ...form,
        ...defaultFormValues,
        id: form.path,
        path: `/report/${reportId}/${form.path}`,
        title,
        nextFormPath: getNextFormPath(formsList, index, form.path),
        prevFormPath: getPrevFormPath(formsList, index),
      }
    })
  }
)
