import RentCompsActionHandlers from '../../forms/income/residential/ResidentialRentComps/redux/sagas'
import CommercialRentCompsActionHandlers from '../../forms/income/commercial/CommercialRentComps/redux/sagas'
import ComparableExpensesActionHandler from '../../forms/income/ComparableExpenses/redux/sagas'
import ExpenseCompsActionHandler from '../../forms/income/ComparableExpenses/ExpenseCompsModal/redux/sagas'
import TaxCompsActionHandlers from '../../forms/income/TaxInformation/TaxComps/redux/sagas'
import ExportActionHandlers from '../../forms/ReviewAndExport/redux/sagas'
import ZoningActionHandlers from '../../forms/property/Zoning/redux/sagas'

import ReportSettingsActionHandlers from '../../../reportsDashboard/components/CreateReportModal/ReportSettings/redux/sagas'

import ReportActionHandlers from './report'
import BookmarksActionHandlers from './bookmarks'

export default [
  ...ReportActionHandlers,
  ...RentCompsActionHandlers,
  ...TaxCompsActionHandlers,
  ...ExportActionHandlers,
  ...ExpenseCompsActionHandler,
  ...ComparableExpensesActionHandler,
  ...BookmarksActionHandlers,
  ...CommercialRentCompsActionHandlers,
  ...ZoningActionHandlers,
  ...ReportSettingsActionHandlers,
]
