import React, { useEffect, useCallback } from 'react'
import { connect } from 'react-redux'
import { useForm } from 'react-final-form'
import PropTypes from 'prop-types'
import { get } from 'lodash'
import { v4 as uuid } from 'uuid'

import { clientsFetch } from '../../../../admin/redux/actions/clients'

import { CalloutButton, CheckboxWithLabel } from '../../../../shared/components'

import ReportClient from './ReportClient'

const ClientList = ({ form, clients, clientsFetch, loadingClients, loadedClients }) => {
  const { reportClients } = form.values
  const newClientFormLink =
    `${global.env.salesforceUrl}/lightning` +
    `/o/Contact/new?count=2&nooverride=1&useRecordTypeCheck=1&navigationLocation=LIST_VIEW&uid=170057701870149384&` +
    `backgroundContext=%2Flightning%2Fo%2FContact%2Flist%3FfilterName%3D00B1U000007z8e2UAA`

  const formApi = useForm()

  const addClient = useCallback(() => {
    const newClient = { id: uuid() }
    formApi.mutators.push('reportClients', newClient)
  }, [formApi.mutators])

  useEffect(() => {
    reportClients.length === 0 && addClient()
  }, [addClient, reportClients.length])

  useEffect(() => {
    if (!loadedClients && !loadingClients) {
      clientsFetch()
    }
  })

  const removeClient = id => {
    const index = formApi.getState().values.reportClients.findIndex(client => client.id === id)

    formApi.mutators.remove('reportClients', index)
  }

  return (
    <>
      <CalloutButton
        link={newClientFormLink}
        linkText="Add"
        text="Add new clients in Salesforce."
        variant="info"
        target="_blank"
      />
      {reportClients[0]?.client?.first_name && (
        <CheckboxWithLabel name="hideClientName" label={`Use "To Whom It May Concern" instead of Client name`} />
      )}
      {reportClients.map((client, idx) => (
        <ReportClient
          key={client.id}
          clients={clients}
          client={client}
          arrayIndex={idx}
          reportClients={reportClients}
          addClient={addClient}
          removeClient={removeClient}
          formApi={formApi}
        />
      ))}
    </>
  )
}

ClientList.propTypes = {
  form: PropTypes.object.isRequired,
  clients: PropTypes.arrayOf(
    PropTypes.shape({
      first_name: PropTypes.string.isRequired,
      last_name: PropTypes.string.isRequired,
      middle_name: PropTypes.string,
      client: PropTypes.string,
      city: PropTypes.string,
      prefix: PropTypes.string,
      streetAddress: PropTypes.string,
      title: PropTypes.string,
      zip: PropTypes.string,
      fullName: PropTypes.string,
    })
  ),
}

export default connect(
  state => {
    return {
      clients: get(state, 'clients.clients', []),
      loadingClients: get(state, 'clients.isLoading', false),
      loadedClients: get(state, 'clients.isLoaded', false),
    }
  },
  {
    clientsFetch,
  }
)(ClientList)
