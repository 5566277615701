import React from 'react'
import { MuiThemeProvider } from '@material-ui/core/styles'
import { StyledEngineProvider, ThemeProvider } from '@mui/material/styles'
import { createGenerateClassName, StylesProvider } from '@mui/styles'
import { MuiPickersUtilsProvider } from 'material-ui-pickers'

import theme from '../shared/components/_mui5/_theme'

import MomentUtils from './MomentUtils'
import getTheme from './theme'

const UIProvider = ({ children }) => (
  <StyledEngineProvider injectFirst>
    <StylesProvider generateClassName={createGenerateClassName({ seed: 'mui', productionPrefix: 'new' })}>
      <MuiThemeProvider theme={getTheme()}>
        <ThemeProvider theme={theme}>
          <MuiPickersUtilsProvider utils={MomentUtils}>{children}</MuiPickersUtilsProvider>
        </ThemeProvider>
      </MuiThemeProvider>
    </StylesProvider>
  </StyledEngineProvider>
)

export default UIProvider
