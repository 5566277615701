import { toNumber } from 'lodash'

import { UNIT_OF_COMPARISON_TYPES } from 'shared/constants/salesApproach'
import { formatFloat } from 'shared/utils/formatters/numberFormatters'
import { divide } from 'shared/utils/numberOperations'

export const getParkingRatio = (propertyInformation, unitOfComparison) => {
  const { parking, residentialUnits = 0, commercialUnits = 0, grossBuildingArea } = propertyInformation
  const parkingSpaces = toNumber(parking) || 0
  if (!parkingSpaces) {
    return null
  }

  if (unitOfComparison === UNIT_OF_COMPARISON_TYPES.RESIDENTIAL_UNITS) {
    return `${formatFloat(divide(parkingSpaces, residentialUnits), 2)} per unit`
  }
  if (unitOfComparison === UNIT_OF_COMPARISON_TYPES.TOTAL_UNITS) {
    const totalUnits = residentialUnits + commercialUnits
    return `${formatFloat(divide(parkingSpaces, totalUnits), 2)} per unit`
  }
  const gbaPerThousand = divide(grossBuildingArea, 1000)
  return `${formatFloat(divide(parkingSpaces, gbaPerThousand), 2)} per 1,000 SF`
}
