import React, { SyntheticEvent } from 'react'
import { Field } from 'react-final-form'
import TextField from '@mui/material/TextField'
import Autocomplete from '@mui/material/Autocomplete'
import Chip from '@mui/material/Chip'
import OutlinedInput from '@mui/material/OutlinedInput'
import InputLabel from '@mui/material/InputLabel'
import FormControl from '@mui/material/FormControl'

function SingleSelectChip(props: any) {
  const { options, label, input, meta, displayError, helperText } = props
  const [inputValue, setInputValue] = React.useState<string>('')
  const [selectedValue, setSelectedValue] = React.useState<string | null>(null)

  const ADD_STRING = 'Add '

  const inErrorState = displayError && meta.touched && !!meta.error
  const errorText = inErrorState ? meta.error : ''

  React.useEffect(() => {
    if (input.value) {
      setSelectedValue(input.value)
    }
  }, [input.value])

  const handleInputChange = (event: SyntheticEvent, value: string) => {
    setInputValue(value)
  }

  const handleChipDelete = () => {
    setSelectedValue(null)
    setInputValue('')
    input.onChange(null)
  }

  const handleSelection = (event: SyntheticEvent, value: string | null) => {
    const newValue = value && value.startsWith(ADD_STRING) ? value.slice(5, -1) : value
    setSelectedValue(newValue)
    setInputValue('')
    input.onChange(newValue)
  }

  const handleKeyPress = (event: React.KeyboardEvent) => {
    if (event.key === 'Enter' && inputValue) {
      setSelectedValue(inputValue)
      setInputValue('')
      input.onChange(inputValue)
    }
  }

  return (
    <div data-qa={input.name}>
      <FormControl fullWidth variant="outlined">
        {selectedValue ? (
          <>
            <InputLabel shrink>{label}</InputLabel>
            <OutlinedInput
              value=""
              readOnly
              label={label}
              startAdornment={
                <Chip
                  label={selectedValue}
                  onDelete={handleChipDelete}
                  variant="outlined"
                  data-qa="singleselect-chip"
                />
              }
            />
          </>
        ) : (
          <Autocomplete
            freeSolo
            disableClearable
            options={options}
            value={inputValue}
            onInputChange={handleInputChange}
            onChange={handleSelection}
            onBlur={input.onBlur}
            filterOptions={(options, params) => {
              const filtered = options.filter(option => option.toLowerCase().includes(params.inputValue.toLowerCase()))

              if (params.inputValue !== '' && !filtered.includes(params.inputValue)) {
                filtered.push(`${ADD_STRING}"${params.inputValue}"`)
              }

              return filtered
            }}
            renderOption={(props, option) => <li {...props}>{option}</li>}
            renderInput={params => (
              <TextField
                {...params}
                label={label}
                onKeyDown={handleKeyPress}
                error={inErrorState}
                helperText={errorText || helperText}
                InputProps={{
                  ...params.InputProps,
                  endAdornment: <>{params.InputProps.endAdornment}</>,
                }}
              />
            )}
          />
        )}
      </FormControl>
    </div>
  )
}

export const SingleSelectChipField = (props: any) => {
  const { name, label } = props
  return <Field name={name} label={label} component={SingleSelectChip} {...props} />
}
