import React, { FC } from 'react'
import { Paper, Stack, Typography } from '@mui/material'
import { get } from 'lodash'

import { NarrativeComponent } from 'client-shared/components'
import { ExtendedFormApi } from 'client-shared/utils/form'
import wrapForm from 'report/forms/wrapForm'

import conclusionSummary from 'shared/utils/textGeneration/costApproach/conclusionSummary'
import { CONCLUSION_PATH } from 'shared/constants/report/keysAndDataPaths'

import { PAPER_WIDTH } from '../../helpers'

import ValueOpinions from './ValueOpinions'
import conclusionDecorators from './decorators'

type ConclusionOwnProps = {
  form: ExtendedFormApi
}

export const Conclusion: FC<ConclusionOwnProps> = ({ form }) => {
  const { change } = form
  const values = get(form.getState(), 'values', {})
  const { conclusion, landValue, subjectBudget = {}, depreciation = {}, totalLeaseUpCosts, reconciledValue } = values

  return (
    <Paper id="conclusion" sx={{ maxWidth: PAPER_WIDTH }}>
      <Stack spacing={5}>
        <Typography variant="h5">Conclusion</Typography>
        <NarrativeComponent
          title="Conclusion Summary"
          generatedText={conclusionSummary.generate}
          data={conclusionSummary.mapDTO({ conclusion })}
          name="conclusion.summary"
        />
        <ValueOpinions
          conclusion={conclusion}
          subjectBudget={subjectBudget}
          depreciation={depreciation}
          totalLeaseUpCosts={totalLeaseUpCosts}
          reconciledValue={reconciledValue}
          landValue={landValue}
          change={change}
        />
      </Stack>
    </Paper>
  )
}

export default wrapForm(CONCLUSION_PATH, {
  heading: 'Conclusion',
  decorators: [...conclusionDecorators],
})(Conclusion)
