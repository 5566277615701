export const MAP_SIZE = { height: '400px', width: '640px' }

export const HERE_HYBRID_BASEMAP_CONFIG = {
  baseMap: 'https://{s}.aerial.maps.ls.hereapi.com/maptile/2.1/maptile/newest/hybrid.day/{z}/{x}/{y}/256/jpg',
  subdomains: ['1', '2', '3', '4'],
  subject: {
    maxZoom: 18,
    minZoom: 12,
    initialZoom: 17,
  },
}
