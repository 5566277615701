import React from 'react'

import { isUndefined } from 'lodash'

const firstDefined = (array, defaultValue) => array.find(chip => !isUndefined(chip)) ?? defaultValue

const useOptions = options =>
  React.useMemo(
    () =>
      options.map(option => {
        const { id, label, value } = option
        return {
          id: firstDefined([id, value, label], option),
          label: firstDefined([label, value], option),
          value: firstDefined([value], option),
        }
      }),
    [options]
  )

export { useOptions }
