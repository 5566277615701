import { GeneratedText, gtConditional, gtLocked, gtTemplate } from '../../../../narrative/GeneratedText'

import { mapDTO, mapDataModel } from './mappings'

const generate = values => {
  const gt = new GeneratedText()

  const { appraisersWithInspectionString, appraisersWithoutInspectionString } = values

  const withInspectionLocked = gtLocked('appraisersWithInspectionString', null, appraisersWithInspectionString)
  const withoutInspectionLocked = gtLocked('appraisersWithoutInspectionString', null, appraisersWithoutInspectionString)

  const withVerbLocked = gtLocked('appraisersWithInspectionVerb')
  const withoutVerbLocked = gtLocked('appraisersWithoutInspectionVerb')
  const inspectionDateGt = gtLocked('inspectionDate', 'shortDateWithZeroesFormat', 'Inspection Date')

  gt.addPart(
    gtConditional(
      'isAppraisersWithInspectionGreaterThanZero',
      gtTemplate`${withInspectionLocked}${withVerbLocked} made a personal inspection of the property 
      that is the subject of this report on ${inspectionDateGt}. `
    )
  )
  gt.addPart(
    gtConditional(
      'isAppraisersWithoutInspectionGreaterThanZero',
      gtTemplate`${withoutInspectionLocked}${withoutVerbLocked} made a personal inspection of the property 
      that is the subject of this report.`
    )
  )

  return gt.getParts()
}

export default { generate, mapDTO, mapDataModel }
