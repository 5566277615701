import React from 'react'
import { get, isEmpty } from 'lodash'
import arrayMutators from 'final-form-arrays'

import wrapForm from '../../wrapForm'

import * as Socket from '../../../../shared/utils/socket'

import CategoriesWrapper from '../PropertyPhotos/CategoriesWrapper'
import DefaultCategoriesList from '../PropertyPhotos/DefaultCategoriesList'
import CustomCategoriesList from '../PropertyPhotos/CustomCategoriesList'
import DragDropWrapper from '../PropertyPhotos/DragDropWrapper'

import { EXPORT_PHOTOS_LABEL } from '../../../constants'

import { DATA_PATH as BASE_DATA_PATH } from '..'
const DATA_PATH = [...BASE_DATA_PATH, 'exportPropertyPhotos']

class ExportPropertyPhotos extends React.PureComponent {
  componentDidMount() {
    // defaulting to none so the event id always has a proper format
    const reportId = get(this.props, 'reportId', 'none')
    Socket.on(`propertyPhotos:uploaded:${reportId}`, this.onUploadedPhotos)
  }

  componentWillUnmount() {
    // defaulting to none so the event id always has a proper format
    const reportId = get(this.props, 'reportId', 'none')
    Socket.off(`propertyPhotos:uploaded:${reportId}`, this.onUploadedPhotos)
  }

  onUploadedPhotos = data => {
    const { form } = this.props

    const { defaultCategories, customCategories } = data

    form.batch(() => {
      form.change('exportDefaultCategories', defaultCategories)
      form.change('exportCustomCategories', customCategories)
    })
  }

  getDefaultCategories = () => {
    const categories = get(
      this.props,
      'form.values.exportDefaultCategories',
      get(this.props, 'properties.defaultCategories', [])
    )

    return categories.map((category, index) => ({
      isVisible: category.isVisible,
      category,
      hasImages: !isEmpty(category.photos),
      index,
    }))
  }

  getCustomCategories = () => {
    const categories = get(
      this.props,
      'form.values.exportCustomCategories',
      get(this.props, 'properties.customCategories', [])
    )

    return categories.map(({ category, photos }, index) => ({
      category,
      hasImages: !isEmpty(photos),
      index,
    }))
  }

  render() {
    const { form } = this.props

    const defaultCategories = this.getDefaultCategories()
    const customCategories = this.getCustomCategories()

    return (
      <>
        <DragDropWrapper formMutators={form.mutators}>
          <CategoriesWrapper title="DEFAULT PHOTOS">
            <DefaultCategoriesList
              changeCategory={form.change}
              name="exportDefaultCategories"
              categories={defaultCategories}
            />
          </CategoriesWrapper>
          <CategoriesWrapper title="PROPERTY PHOTOS">
            <CustomCategoriesList
              name="exportCustomCategories"
              categories={customCategories}
              addCategory={form.mutators.push}
              removeCategory={form.mutators.remove}
              changeCategory={form.change}
            />
          </CategoriesWrapper>
        </DragDropWrapper>
      </>
    )
  }
}

export default wrapForm(DATA_PATH, {
  heading: EXPORT_PHOTOS_LABEL,
  ignoreLocation: true,
  mutators: {
    ...arrayMutators,
  },
})(ExportPropertyPhotos)
