import React from 'react'
import PropTypes from 'prop-types'
import { get } from 'lodash'
import { Divider, Grid } from '@material-ui/core'

import AdvancedSearch from '../../../../../../shared/components/PropertyWizard/Search/AdvancedSearch/AdvancedSearch'
import AdvancedSearchDefault from '../../../../../../shared/components/PropertyWizard/Search/AdvancedSearch/AdvancedSearchDefault'
import { AdvancedSearchNJ } from '../../../../../../shared/components/PropertyWizard/Search/AdvancedSearch/AdvancedSearchNJ'
import { AdvancedSearchNYC } from '../../../../../../shared/components/PropertyWizard/Search/AdvancedSearch/AdvancedSearchNYC'
import { GEOGRAPHY_OPTIONS } from '../../../../../../shared/constants/properties'

import Text from '../../../../../../shared/components/Text'
import GooglePlacesAutocomplete from '../../../../../../shared/components/GooglePlacesAutocomplete'
import { hasSelectedGooglePlace } from '../../../../../../shared/components/GooglePlacesAutocomplete/validation'
import { required } from '../../../../../../shared/utils/validation'
import { STATE_NAMES } from '../../../../../../../../shared/constants/states'
import { getLocationInfoFromAddress } from '../../../../../../shared/utils/propertyHelper'
import StepFooter from '../../../../../../shared/components/PropertyWizard/StepFooter'

import { withSearch, SEARCH_TYPE } from './helpers/withSearch'

const DRMAdvancedSearchNYC = withSearch(AdvancedSearchNYC, SEARCH_TYPE.PIN)
const DRMAdvancedSearchNJ = withSearch(AdvancedSearchNJ, SEARCH_TYPE.PIN)

class DRMAdvancedSearchOther extends React.PureComponent {
  static propTypes = {
    openBasicSearch: PropTypes.func.isRequired,
    onSubmit: PropTypes.func.isRequired,
    invalid: PropTypes.bool.isRequired,
  }

  onPropertySubmit = () => {
    const { onSubmit, values } = this.props
    const address = get(values, 'search.location', {})
    const addressInfo = get(address, 'addressInfo', {})
    const coords = get(values, 'search.location.coords', {})
    const { propertyIdentifierType, propertyIdentifier } = get(values, 'search', {})

    // TODO: move to transformers helper?
    const comp = {
      address: {
        streetAddress: addressInfo.shortAddress,
        city: addressInfo.city,
        state: STATE_NAMES[addressInfo.state],
        postalCode: addressInfo.zip,
        coords,
        propertyIdentification: {
          propertyIdentifierType,
          propertyIdentifier,
        },
      },
    }

    onSubmit(comp)
  }

  render() {
    const { openBasicSearch, invalid } = this.props
    return (
      <React.Fragment>
        <Divider light />
        <br />
        <Grid container spacing={8}>
          <Grid item xs={12}>
            <GooglePlacesAutocomplete
              name="search.location"
              label="Address"
              showCoordinates
              required
              validate={hasSelectedGooglePlace}
              addressTransformer={getLocationInfoFromAddress}
            />
          </Grid>
          <Grid container direction="row" justify="space-between" spacing={8}>
            <Grid item xs={6}>
              <Text
                name="search.propertyIdentifierType"
                label="Unique Property Identifier Type"
                required
                validate={required}
                helperText="e.g. Block/Lot, APN, Parcel ID"
              />
            </Grid>
            <Grid item xs={6}>
              <Text name="search.propertyIdentifier" label="Unique Property Identifier" required validate={required} />
            </Grid>
          </Grid>
        </Grid>
        <StepFooter
          previous={{
            text: 'Standard Search',
            onClick: openBasicSearch,
          }}
          next={{ text: 'Select', onClick: this.onPropertySubmit, isDisabled: invalid }}
        />
      </React.Fragment>
    )
  }
}

const selectGeography = ({ locationIdentifier, ...restProps }) => {
  switch (locationIdentifier) {
    case GEOGRAPHY_OPTIONS.NY:
      return <DRMAdvancedSearchNYC {...restProps} />
    case GEOGRAPHY_OPTIONS.NJ:
      return <DRMAdvancedSearchNJ {...restProps} />
    case GEOGRAPHY_OPTIONS.OTHER:
      return <DRMAdvancedSearchOther {...restProps} />
    default:
      return <AdvancedSearchDefault {...restProps} />
  }
}

export const DRMAdvancedSearch = props => {
  return <AdvancedSearch {...props} Geography={selectGeography} />
}
