import React from 'react'

import { connect } from 'react-redux'
import { get } from 'lodash'

import { calculateDistance } from 'client-shared/utils/geography'
import { formatFloat } from 'client-shared/utils/numberFormatters'

import AdvancedSearchModal from './AdvancedSearchModal'
import { searchTaxComps as searchTaxCompsAction } from './redux/actions'

const getNewCompDistance = (coords, comp) => {
  if (!comp.COORDS || !coords) {
    return null
  }

  const source = {
    latitude: parseFloat(coords.latitude),
    longitude: parseFloat(coords.longitude),
  }

  return calculateDistance(source, comp.COORDS)
}

const AdvancedSearchModalContainer = props => <AdvancedSearchModal {...props} />

export default connect(
  state => {
    const borough = get(state, 'report.reportData.borough', '')
    const coords = get(state.report, 'reportData.propertyInformation.coords', null)
    const taxClass = get(state, 'report.reportData.incomeApproach.taxInformation.taxClass.lookupValue')
    const taxCompsSearchResults = state.taxComps

    taxCompsSearchResults.comps = get(taxCompsSearchResults, 'comps', [])
      .filter(comp => !!comp.COORDS)
      .map(comp => {
        return {
          ...comp,
          distance: formatFloat(getNewCompDistance(coords, comp)),
        }
      })
      .sort((compA, compB) => parseFloat(compA.distance) - parseFloat(compB.distance))

    return {
      borough,
      taxClass,
      taxCompsSearchResults,
    }
  },
  { searchTaxComps: searchTaxCompsAction }
)(AdvancedSearchModalContainer)
