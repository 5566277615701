export default {
  getMortgageConstant({ mortgageRate = 0, amortizationTerm = 0 }) {
    if (!mortgageRate || !amortizationTerm) {
      return 0
    }
    const monthlyMortgageRate = mortgageRate / 12
    const durationOfTermInMonths = amortizationTerm * 12

    return (12 * monthlyMortgageRate) / (1 - 1 / (1 + monthlyMortgageRate) ** durationOfTermInMonths)
  },
  getMortgageFunds({ mortgageConstant = 0, loanValueRatio = 0 }) {
    if (mortgageConstant && loanValueRatio) {
      return loanValueRatio * mortgageConstant
    }
    return 0
  },
  getEquityRatio({ loanValueRatio = 0 }) {
    return loanValueRatio ? 1 - loanValueRatio : 1
  },
  getEquityFunds({ equityDividendRate = 0, loanValueRatio = 0 }) {
    const equityRatio = this.getEquityRatio({ loanValueRatio })
    if (equityRatio && equityDividendRate) {
      return equityRatio * equityDividendRate
    }
    return 0
  },
  getBandOfInvestmentCapRate({ loanValueRatio = 0, equityDividendRate = 0, mortgageFunds = 0 }) {
    const equityFunds = this.getEquityFunds({ equityDividendRate, loanValueRatio })
    if (!mortgageFunds) {
      return equityFunds || 0
    }
    return equityFunds + mortgageFunds
  },
  getDebtCoverageCapRate({ mortgageConstant = 0, debtCoverageRatio = 0, loanValueRatio = 0 }) {
    if (mortgageConstant && debtCoverageRatio && loanValueRatio) {
      return mortgageConstant * debtCoverageRatio * loanValueRatio
    }
    return 0
  },
  getNumberOfPayments({ loanValueRatio = 0 }) {
    return loanValueRatio ? loanValueRatio * 12 : 0
  },
}
