import React from 'react'
import { isEmpty } from 'lodash'
import PropTypes from 'prop-types'
import { Grid, Typography } from '@material-ui/core'

import ConditionGroup from '../ConditionGroup'
import { ImageUploadListField as ImageUploadList } from '../ImageList'
import AutomationStatus from '../AutomationStatus'

export default class PropertyDescription extends React.PureComponent {
  static propTypes = {
    label: PropTypes.string,
    images: PropTypes.shape({
      category: PropTypes.shape({
        categoryName: PropTypes.string,
        categoryTitle: PropTypes.string,
      }),
      name: PropTypes.string.isRequired,
    }),
    conditions: PropTypes.arrayOf(
      PropTypes.shape({
        name: PropTypes.string.isRequired,
        label: PropTypes.string,
        items: PropTypes.arrayOf(
          PropTypes.shape({
            label: PropTypes.string.isRequired,
            value: PropTypes.string.isRequired,
          }).isRequired
        ).isRequired,
      })
    ),
    children: PropTypes.node,
    readOnlyImages: PropTypes.bool,
  }

  static defaultProps = {
    conditions: [],
    label: '',
    children: null,
    readOnlyImages: false,
  }

  render() {
    const { children, label, images, conditions, readOnlyImages, automatedInspectionPrefillFlag, automationMetadata } =
      this.props

    return (
      <Grid container wrap="nowrap" direction="column" spacing={8}>
        <Grid item xs={12}>
          {label && <Typography variant="h6">{label}</Typography>}
          {images && <ImageUploadList name={images.name} category={images.category} readOnly={readOnlyImages} />}
          {automatedInspectionPrefillFlag && automationMetadata && (
            <Grid item>
              <AutomationStatus
                formPaths={['automationMetadata']}
                message="We automated the information below from Mobile Inspection app"
                sx={{ marginTop: '8px', marginBottom: '8px' }}
              />
            </Grid>
          )}
          {!isEmpty(conditions) && <ConditionGroup conditions={conditions} />}
        </Grid>
        {children}
      </Grid>
    )
  }
}
