import React from 'react'
import { Table, TableBody, TableCell, TableHead, TableRow } from '@material-ui/core'
import InfoIcon from '@mui/icons-material/InfoOutlined'
import { FieldArray } from 'react-final-form-arrays'

import TableCheckbox from '../../../../../../shared/components/Table/TableCheckbox'
import TableNumber from '../../../../../../shared/components/Table/TableNumber'
import { Tooltip } from '../../../../../../shared/components'
import { formatCurrencyFloat } from '../../../../../../shared/utils/numberFormatters'
import { positive } from '../../../../../../shared/utils/validation'

const UnitReimbursementRow = ({
  unitReimbursementPath,
  index,
  baseValueFieldName,
  percentageFieldName,
  percentageCellClassName,
  hasAdminFee,
  onFieldChange,
  getFieldValue,
  classes,
}) => {
  return (
    <TableRow key={index}>
      <TableCell className={classes.enabledColumn}>
        <TableCheckbox
          name={`${unitReimbursementPath}.enabled`}
          handleChange={() => onFieldChange('enabled', unitReimbursementPath)}
        />
      </TableCell>

      <TableCell>{getFieldValue(`${unitReimbursementPath}.unit.tenantName`)}</TableCell>
      <TableCell>{formatCurrencyFloat(getFieldValue(`${unitReimbursementPath}.${baseValueFieldName}`, 0))}</TableCell>
      <TableCell className={classes[`${percentageCellClassName}`]}>
        <TableNumber
          name={`${unitReimbursementPath}.${percentageFieldName}`}
          decimalScale={2}
          allowNegative={false}
          adornment="%"
          validate={positive}
          thousandSeparator
          handleChange={() => onFieldChange(percentageFieldName, unitReimbursementPath)}
        />
      </TableCell>
      {hasAdminFee && (
        <React.Fragment>
          <TableCell className={classes.annualLessAdminFeeCell}>
            {formatCurrencyFloat(getFieldValue(`${unitReimbursementPath}.annualLessAdminFee`, 0))}
          </TableCell>
          <TableCell className={classes.adminFeePercentageCell}>
            <TableNumber
              name={`${unitReimbursementPath}.adminFeePercentage`}
              decimalScale={2}
              allowNegative={false}
              adornment="%"
              validate={positive}
              thousandSeparator
              handleChange={() => onFieldChange('adminFeePercentage', unitReimbursementPath)}
            />
          </TableCell>
          <TableCell>{formatCurrencyFloat(getFieldValue(`${unitReimbursementPath}.adminFee`, 0))}</TableCell>
        </React.Fragment>
      )}
      <TableCell className={classes.annualCell}>
        {formatCurrencyFloat(getFieldValue(`${unitReimbursementPath}.annual`, 0))}
      </TableCell>
    </TableRow>
  )
}

const UnitsTableForPercentageReimbursement = ({
  pathPrefix,
  reimbursement,
  hasAdminFee,
  baseValueFieldName,
  baseValueColumnTitle,
  baseValueColumnTooltip,
  percentageFieldName,
  percentageColumnTitle,
  percentageCellClassName,
  onFieldChange,
  getFieldValue,
  classes,
  children,
}) => {
  return (
    <React.Fragment>
      {children}
      <Table className={classes.reimbursementTable}>
        <TableHead>
          <TableRow>
            <TableCell className={classes.enabledColumn} />
            <TableCell>Tenant Name</TableCell>
            <TableCell className={classes.headerWithTooltip}>
              {baseValueColumnTitle}
              <Tooltip text={baseValueColumnTooltip} placement="top">
                <InfoIcon fontSize="small" />
              </Tooltip>
            </TableCell>
            <TableCell>{percentageColumnTitle}</TableCell>
            {hasAdminFee && (
              <React.Fragment>
                <TableCell>{`Gross ${percentageColumnTitle}`}</TableCell>
                <TableCell>Admin Fee %</TableCell>
                <TableCell>Gross Admin Fee %</TableCell>
              </React.Fragment>
            )}
            <TableCell>Annual Reimbursement</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          <FieldArray name={`${pathPrefix}.unitReimbursements`}>
            {({ fields }) => {
              return fields.map((field, index) => (
                <UnitReimbursementRow
                  unitReimbursementPath={field}
                  index={index}
                  baseValueFieldName={baseValueFieldName}
                  percentageFieldName={percentageFieldName}
                  percentageCellClassName={percentageCellClassName}
                  hasAdminFee={hasAdminFee}
                  onFieldChange={onFieldChange}
                  getFieldValue={getFieldValue}
                  classes={classes}
                />
              ))
            }}
          </FieldArray>
          <TableRow>
            <TableCell colSpan={hasAdminFee ? 6 : 3} />
            <TableCell>Total:</TableCell>
            <TableCell>{formatCurrencyFloat(reimbursement.totalAnnual)}</TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </React.Fragment>
  )
}

export default UnitsTableForPercentageReimbursement
