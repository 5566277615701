import React from 'react'
import { compose } from 'recompose'
import classnames from 'classnames'
import { withStyles, MenuItem, Table, TableCell, TableBody, TableHead, TableRow, Typography } from '@material-ui/core'
import { invert, startCase } from 'lodash'

import { BOROUGH_LOOKUP } from 'shared/constants/boroughs'

import MoreButton from '../../../../../../../shared/components/PropertyWizard/SearchResults/MoreButton'
import { shortDateWithZeroesFormat } from '../../../../../../../shared/utils/dateHelper'
import { formatCurrencyInt } from '../../../../../../../shared/utils/numberFormatters'
import styles from '../../../../../../../shared/components/PropertyWizard/SearchResults/styles'
import Link from '../../../../../../../shared/components/Link'

import { BOROUGH_NUMBER } from '../../../../../../../shared/constants/borough'

import Badge from '../../../Badge'

import { Logo } from './Logo'
import { formatFullAddress, getStatus, searchResultDataQA } from './helpers'

const NY_TAX_LINK_TEXT = 'ZoLa'

export class NewYorkCityResultsTable extends React.PureComponent {
  handleRowClick = index => () => {
    const { onPropertySelect, properties } = this.props
    if (!properties[index].id) {
      onPropertySelect(index)
    }
  }

  getTaxLink = property => {
    if (property.taxInfoLink) {
      return property.taxInfoLink
    }

    const { borough, block, lot } = this.getBoroughBlockLot(property.address.propertyIdentification?.propertyIdentifier)
    const startCasedBorough = startCase(borough)
    const boroughAbbreviation = invert(BOROUGH_LOOKUP)[startCasedBorough]
    const boroughNumber = BOROUGH_NUMBER[boroughAbbreviation]

    return `https://zola.planning.nyc.gov/lot/${boroughNumber}/${block}/${lot}`
  }

  getOtherLinks = propertyOrComp => {
    if (!propertyOrComp.id) {
      return []
    }

    return [
      <MenuItem
        onClick={() => {
          this.props.openDetails(propertyOrComp)
        }}
        key="details-modal"
      >
        <Typography>Comp Details</Typography>
      </MenuItem>,
    ]
  }

  getBoroughBlockLot = propertyIdentifier => {
    if (propertyIdentifier == null) {
      return {}
    }
    const parts = propertyIdentifier.split('_')
    if (parts.length !== 3) {
      return {}
    }
    return {
      borough: parts[0],
      block: parts[1],
      lot: parts[2],
    }
  }

  render() {
    const { properties, classes, selectedPropertyIndex, onSubmit } = this.props
    const hasComp = properties.some(property => !!property.id)

    return (
      <Table className={classes.table}>
        <TableHead>
          <TableRow>
            <TableCell className={classes.leadCell} />
            <TableCell className={classes.tableCell}>Address</TableCell>
            <TableCell className={classes.tableCell}>Status</TableCell>
            <TableCell className={classes.tableCell}>State</TableCell>
            <TableCell className={classes.tableCell}>Borough</TableCell>
            <TableCell className={classes.tableCell}>Block</TableCell>
            <TableCell className={classes.tableCell}>Lot</TableCell>
            {hasComp && (
              <>
                <TableCell className={classes.tableCell}>Sale Price</TableCell>
                <TableCell className={classes.tableCell}>Transaction Date</TableCell>
                <TableCell className={classes.selectCompCell}></TableCell>
              </>
            )}
          </TableRow>
        </TableHead>
        <TableBody>
          {properties.map((row, index) => {
            const status = getStatus(row)

            const { borough, block, lot } = this.getBoroughBlockLot(
              row.address.propertyIdentification?.propertyIdentifier
            )

            return (
              <TableRow
                onClick={this.handleRowClick(index)}
                className={classnames(classes.row, {
                  [classes.selectedRow]: index === selectedPropertyIndex,
                  [classes.compRow]: !!row.id,
                })}
                key={index}
                index={index}
                data-qa={searchResultDataQA(row.id)}
              >
                <TableCell data-qa={searchResultDataQA('more-btn')} className={classes.leadCell}>
                  <MoreButton
                    taxLink={this.getTaxLink(row)}
                    taxLinkText={NY_TAX_LINK_TEXT}
                    fullAddress={formatFullAddress(row)}
                    otherLinks={this.getOtherLinks(row)}
                  />
                </TableCell>
                <TableCell data-qa={searchResultDataQA('street-address')} className={classes.tableCell}>
                  {row.id && <Logo></Logo>}
                  {row.propertyInformation?.displayStreetAddress || row.address.streetAddress || '-'}
                </TableCell>
                <TableCell data-qa={searchResultDataQA('status')} className={classes.tableCell}>
                  {status ? <Badge type={status} /> : '-'}
                </TableCell>
                <TableCell data-qa={searchResultDataQA('city')} className={classes.tableCell}>
                  NY
                </TableCell>
                <TableCell data-qa={searchResultDataQA('borough')} className={classes.tableCell}>
                  {startCase(borough) || '-'}
                </TableCell>
                <TableCell data-qa={searchResultDataQA('block')} className={classes.tableCell}>
                  {block || '-'}
                </TableCell>
                <TableCell data-qa={searchResultDataQA('lot')} className={classes.tableCell}>
                  {lot || '-'}
                </TableCell>
                {hasComp && (
                  <>
                    <TableCell data-qa={searchResultDataQA('sale-price')} className={classes.tableCell}>
                      {row.saleInformation?.salePrice ? formatCurrencyInt(row.saleInformation.salePrice) : '-'}
                    </TableCell>
                    <TableCell data-qa={searchResultDataQA('sale-date')} className={classes.tableCell}>
                      {row.saleDate?.toString() ? shortDateWithZeroesFormat(row.saleDate) : '-'}
                    </TableCell>
                    <TableCell className={classes.selectCompCell}>
                      {row.id ? (
                        <Link
                          data-qa="search-result-select-comp-link"
                          onClick={() => {
                            onSubmit(row)
                          }}
                        >
                          Select Comp
                        </Link>
                      ) : (
                        ''
                      )}
                    </TableCell>
                  </>
                )}
              </TableRow>
            )
          })}
        </TableBody>
      </Table>
    )
  }
}

NewYorkCityResultsTable = compose(withStyles(styles))(NewYorkCityResultsTable)
