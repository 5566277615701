import createDecorator from 'final-form-calculate'

import { OTHER_INTENDED_USE } from '../../../../../../libs/bowery-libs/constants/report'

const DEFAULT_OTHER_INTENDED_USE_TEXT = 'The intended use is for '

const otherIntendedUse = createDecorator({
  field: 'intendedUse',
  updates: {
    otherIntendedUse: (value, allValues) => {
      if (value === OTHER_INTENDED_USE) {
        return allValues.otherIntendedUse || DEFAULT_OTHER_INTENDED_USE_TEXT
      }
      return null
    },
  },
})

export { otherIntendedUse }
