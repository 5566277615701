import React, { useMemo, useRef } from 'react'
import { divide, find, get, isNil, max, mean, min, noop, some } from 'lodash'

import { ColumnBasedTable, ColumnDataTypeEnum } from '@bowery-valuation/ui-components'
import '@bowery-valuation/ui-components/dist/style.css'

import { formatCurrencyFloat, formatCurrencyInt } from 'shared/utils/formatters/numberFormatters'
import { getNonSelectedBasisForecast } from 'shared/utils/report/incomeApproach/expenses'

import { DEFAULT_EXPENSES_KEYS, STRICT_EXPENSE_CATEGORIES } from '../../../../../../shared/constants/expenses'

import { rowManagedRenderer } from '../helpers'

import { BASIS_MAP } from './constants'

const ExpenseForecastSummaryTable = ({
  basis,
  expenseHistory,
  expenseForecast,
  expenseComps,
  residentialUnitCount,
  grossBuildingArea,
}) => {
  const ref = useRef()
  const items = expenseHistory || []
  const hasExpenseForecast = !!some(Object.keys(STRICT_EXPENSE_CATEGORIES), category => {
    if (expenseForecast[category] && expenseForecast[category].concludedValue) {
      return true
    }
  })
  const columnDefinitions = [
    {
      readOnly: true,
      name: 'name',
      label: 'Expense Category',
      type: ColumnDataTypeEnum.text,
      suppressMovable: true,
      width: 50,
      height: 50,
      permanent: true,
    },
  ]
  if (hasExpenseForecast) {
    expenseHistory?.forEach(expenseHistorical => {
      const { expenseYear, expensePeriod, key } = expenseHistorical
      columnDefinitions.push({
        readOnly: true,
        name: key,
        label: `${expenseYear} ${expensePeriod}`,
        type: ColumnDataTypeEnum.text,
        suppressMovable: true,
        width: 25,
        height: 50,
        permanent: true,
        align: 'right',
      })
    })
    columnDefinitions.push(
      {
        readOnly: true,
        name: 'compMin',
        label: 'Comp Min',
        type: ColumnDataTypeEnum.text,
        suppressMovable: true,
        width: 25,
        height: 50,
        permanent: true,
        align: 'right',
      },
      {
        readOnly: true,
        name: 'compAvg',
        label: 'Comp Average',
        type: ColumnDataTypeEnum.text,
        suppressMovable: true,
        width: 25,
        height: 50,
        permanent: true,
        align: 'right',
      },
      {
        readOnly: true,
        name: 'compMax',
        label: 'Comp Max',
        type: ColumnDataTypeEnum.text,
        suppressMovable: true,
        width: 25,
        height: 50,
        permanent: true,
        align: 'right',
      },
      {
        readOnly: true,
        name: 'appraiserForecast',
        label: 'Appraiser Forecast',
        type: ColumnDataTypeEnum.text,
        suppressMovable: true,
        width: 25,
        height: 50,
        permanent: true,
        align: 'right',
      }
    )
  }

  const basisValue = BASIS_MAP[basis]
  const currencyFormatter = basisValue === 'sf' ? formatCurrencyFloat : formatCurrencyInt

  let appraiserTotalOperatingExpenses = 0
  const tableRows = useMemo(() => {
    const rows = Object.keys(STRICT_EXPENSE_CATEGORIES)
      .filter(key => key !== DEFAULT_EXPENSES_KEYS.realEstateTaxes)
      .map(key => {
        const category = STRICT_EXPENSE_CATEGORIES[key]
        let appraiserForecast = expenseForecast[key]?.concludedValue || 0
        const appraiserForecastBasis = expenseForecast[key]?.basis || 0
        if (basisValue !== appraiserForecastBasis) {
          appraiserForecast = getNonSelectedBasisForecast(
            appraiserForecastBasis,
            basisValue,
            appraiserForecast,
            null,
            grossBuildingArea,
            residentialUnitCount
          )
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
        appraiserTotalOperatingExpenses += appraiserForecast

        const comparableExpenseBasis = expenseComps
          .filter(comp => {
            const compExpenses = get(comp, 'expenses', [])
            const compExpense = find(compExpenses, expense => expense.id === key)
            if (!compExpense) {
              return false
            }
            const notNil = !isNil(get(compExpense, `${basisValue}`))
            return compExpense.reported && notNil
          })
          .map(comp => {
            const compExpenses = get(comp, 'expenses', [])

            const compExpense = find(compExpenses, expense => expense.id === key)
            return get(compExpense, `${basisValue}`)
          })
        const compMin = min(comparableExpenseBasis)
        const compAvg = mean(comparableExpenseBasis)
        const compMax = max(comparableExpenseBasis)

        const row = {
          id: category,
          name: category,
          editable: false,
          appraiserForecast: currencyFormatter(appraiserForecast),
          compMin: compMin ? currencyFormatter(compMin) : '-',
          compAvg: compAvg ? currencyFormatter(compAvg) : '-',
          compMax: compMax ? currencyFormatter(compMax) : '-',
        }

        expenseHistory?.forEach(expenseHistorical => {
          const expenseHistoricalKey = get(expenseHistorical, 'key')
          const expenseHistoricalExpenses = get(expenseHistorical, 'expenses')
          const compExpense = find(expenseHistoricalExpenses, expense => expense.id === key)
          if (!compExpense) {
            return false
          }
          const expenseHistoricalValue = get(compExpense, `${basisValue}`)
          row[expenseHistoricalKey] = currencyFormatter(expenseHistoricalValue)
        })
        return row
      })
    return rows
  }, [expenseComps, basisValue])

  const totalRow = {
    id: 'total',
    name: 'Total Operating Expenses',
    editable: false,
    appraiserForecast: currencyFormatter(appraiserTotalOperatingExpenses),
    compMin: '',
    compAvg: '',
    compMax: '',
  }
  expenseHistory?.forEach(expenseHistorical => {
    const { totalExcludingTaxes } = expenseHistorical
    switch (basisValue) {
      case 'sf':
        totalRow[expenseHistorical.key] = currencyFormatter(divide(totalExcludingTaxes, grossBuildingArea))
        break
      case 'unit':
        totalRow[expenseHistorical.key] = currencyFormatter(divide(totalExcludingTaxes, residentialUnitCount))
        break
      default:
        totalRow[expenseHistorical.key] = currencyFormatter(totalExcludingTaxes)
        break
    }
  })

  tableRows.push(totalRow)

  return (
    <>
      <ColumnBasedTable
        id="expense-forecast-summary-table"
        gridApiRef={ref}
        rowManagedRenderer={rowManagedRenderer}
        columns={columnDefinitions}
        hideIndexColumn
        actionCellHidden
        rows={tableRows}
        items={items}
        onRowsDragEnd={noop}
        onRowUpdate={noop}
        onRowDelete={noop}
        onColumnDelete={noop}
        onColumnUpdate={noop}
        onColumnDragEnd={noop}
        onManyRowsUpdate={noop}
      />
    </>
  )
}

export default React.memo(ExpenseForecastSummaryTable)
