import React from 'react'
import PropTypes from 'prop-types'
import { get, noop, isNil } from 'lodash'
import { Field } from 'react-final-form'
import { FormControl } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'
import { InlineDatePicker } from 'material-ui-pickers'

import BoweryDate from '@bowery-valuation/bowery-date'

import { INPUT_DATE_MASK, INPUT_DATE_FORMAT, INVALID_DATE_FORMAT } from '../../constants'

const styles = theme => ({
  root: {},
  formControl: {},
  formLabel: {
    display: 'flex',
    alignItems: 'center',
  },
  error: {
    color: theme.palette.error[600],
    marginTop: 4,
  },
  inlineDatePicker: {
    '& div': {
      padding: 0,
    },
  },
})

class MaterialDatePicker extends React.PureComponent {
  static propTypes = {
    input: PropTypes.object.isRequired,
    label: PropTypes.string,
    dateFormat: PropTypes.string,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(Date)]),
    error: PropTypes.string,
    warning: PropTypes.string,
    invalidDateMessage: PropTypes.string,
    inputDateMask: PropTypes.array,
    onInputChange: PropTypes.func,
    required: PropTypes.bool,
    disablePast: PropTypes.bool,
    fullWidth: PropTypes.bool,
    minDate: PropTypes.instanceOf(Date),
    maxDate: PropTypes.instanceOf(Date),
  }

  static defaultProps = {
    label: '',
    dateFormat: INPUT_DATE_FORMAT,
    inputDateMask: INPUT_DATE_MASK,
    invalidDateMessage: INVALID_DATE_FORMAT,
    onInputChange: noop,
    required: false,
    disablePast: false,

    fullWidth: true,
  }

  state = {
    inputValue: this.props.input.value,
  }

  onInputChange = event => {
    const { input, onInputChange } = this.props
    const inputValue = get(event, 'target.value') || ''

    this.setState({ inputValue })

    if (inputValue === '') {
      input.onChange({ target: { value: null } })
      onInputChange({ value: event, valid: false })
      return
    }
    const date = new BoweryDate(inputValue)
    const isValidDate = date.isValidDate()
    onInputChange({ value: inputValue, valid: isValidDate })

    if (isValidDate) {
      input.onBlur()
      input.onChange(event)
    } else if (input.value) {
      const value = new BoweryDate(input.value)
      value.isValidDate() && input.onChange({ target: { value } })
    }
  }

  onChange = event => {
    const { input, onInputChange } = this.props
    input.onChange(event)
    onInputChange({ value: event, valid: true })
    this.setState({ inputValue: event })
  }

  getError = () => {
    const { meta, invalidDateMessage } = this.props
    const { touched, error } = meta
    const { inputValue } = this.state

    const hasValidationError = !!error
    const isValid = inputValue === '' ? true : new BoweryDate(inputValue).isValidDate()

    const inErrorState = touched && (hasValidationError || !isValid)
    const errorMessage = inErrorState && isValid ? error : invalidDateMessage

    return { inErrorState, errorMessage }
  }

  render() {
    const {
      dateFormat,
      input,
      inputDateMask,
      classes,
      fullWidth,
      label,
      disablePast,
      required,
      children,
      onInputChange,
      ...otherProps
    } = this.props

    const { inErrorState, errorMessage } = this.getError()

    return (
      <FormControl className={classes.formControl} data-qa={`${input.name}-date-picker`} fullWidth={fullWidth}>
        <input type="hidden" value={input.value} />
        <InlineDatePicker
          keyboard
          label={label}
          variant="outlined"
          margin="dense"
          value={input.value || null}
          format={dateFormat}
          mask={inputDateMask}
          placeholder={dateFormat}
          onInputChange={this.onInputChange}
          onChange={this.onChange}
          onFocus={input.onFocus}
          onBlur={input.onBlur}
          onOpen={input.onFocus}
          onClose={input.onBlur}
          error={inErrorState}
          fullWidth={fullWidth}
          InputLabelProps={{ shrink: true }}
          disablePast={disablePast}
          className={classes.inlineDatePicker}
          required={required}
          helperText={
            inErrorState && (
              <span className={classes.error} id="component-error-text">
                {errorMessage}
              </span>
            )
          }
          {...otherProps}
        />
        {children}
      </FormControl>
    )
  }
}

const format = dateString => {
  if (dateString === '') {
    return null
  }
  if (!isNil(dateString)) {
    return new BoweryDate(dateString).format(INPUT_DATE_FORMAT)
  }
  return dateString
}

const parseDate = inputDate => {
  if (isNil(inputDate)) {
    return null
  }

  const date = new BoweryDate(inputDate.toString())

  if (!date.isValidDate()) {
    return null
  }

  return date.date
}

export const DatePickerField = ({ name, type, validate, ...props }) => {
  return (
    <Field
      name={name}
      format={format}
      parse={parseDate}
      component={MaterialDatePicker}
      validate={validate}
      {...props}
    />
  )
}

const StyledDatePicker = withStyles(styles)(DatePickerField)
StyledDatePicker.displayName = 'DatePicker'

StyledDatePicker.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string,
  dateFormat: PropTypes.string,
  error: PropTypes.string,
  warning: PropTypes.string,
  invalidDateMessage: PropTypes.string,
  inputDateMask: PropTypes.array,
  onInputChange: PropTypes.func,
  required: PropTypes.bool,
  disablePast: PropTypes.bool,
  fullWidth: PropTypes.bool,
  minDate: PropTypes.instanceOf(Date),
  maxDate: PropTypes.instanceOf(Date),
}

export default StyledDatePicker
