import { GeneratedText, gtLocked } from '../../../narrative/GeneratedText'

import { mapDTO, mapDataModel } from './mappings'

const generate = () => {
  const gt = new GeneratedText()

  gt.addPart(gtLocked('hotWaterSystemsSentence'))

  return gt.getParts()
}

export default { generate, mapDTO, mapDataModel }
