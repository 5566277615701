import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import LinkIcon from '@mui/icons-material/Launch'
import { Typography, withStyles, Table, TableHead, TableBody, TableRow, TableCell, Grid } from '@material-ui/core'

import { GeneratedComment } from '../../../../../shared/components'
import TableThemeProvider from '../../../../components/TableThemeProvider'
import { getCapRateCompsDiscussion } from '../discussions'

import { CAP_RATE_DISCUSSION_TOOLTIP } from '../../constants'

const styles = theme => ({
  title: {
    lineHeight: 1,
    marginBottom: 20,
  },
  tableTitle: {
    color: 'rgba(0, 0, 0, 0.87)',
    lineHeight: 1.33,
    letterSpacing: 0.4,
    marginBottom: 8,
    display: 'flex',
    alignItems: 'center',
  },
  icon: {
    width: 18,
    height: 18,
    fill: 'rgba(0, 0, 0, 0.54)',
    '&:hover': {
      fill: theme.palette.primary[900],
    },
    marginLeft: 8,
  },
  iconLink: {
    width: 18,
    height: 18,
    display: 'inline-block',
  },
})

class CapRateComps extends React.PureComponent {
  static propTypes = {
    comparableIncomePotential: PropTypes.string,
    comparablePropertyConditions: PropTypes.string,
    comparablePropertyLocations: PropTypes.string,
  }

  static defaultProps = {
    comparableIncomePotential: 'N/A',
    comparablePropertyConditions: 'N/A',
    comparablePropertyLocations: 'N/A',
  }

  render() {
    const { classes, comparableIncomePotential, comparablePropertyConditions, comparablePropertyLocations } = this.props

    return (
      <div data-qa="cap-rate-comparables">
        <Typography className={classes.title} variant="h6">
          Cap Rate Comparables
        </Typography>
        <Grid container spacing={16}>
          <Grid item xs={12}>
            <Typography component="div" className={classes.tableTitle} variant="caption">
              Cap Rate Comps
              <Link className={classes.iconLink} to="cap-rate-comps">
                <LinkIcon className={classes.icon} />
              </Link>
            </Typography>

            <TableThemeProvider>
              <Table data-qa="cap-rate-comps" padding="checkbox">
                <TableHead>
                  <TableRow>
                    <TableCell>Income Potential</TableCell>
                    <TableCell>Property Conditions</TableCell>
                    <TableCell>Location</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow>
                    <TableCell data-qa="income-potential">{comparableIncomePotential}</TableCell>
                    <TableCell data-qa="property-conditions">{comparablePropertyConditions}</TableCell>
                    <TableCell data-qa="location">{comparablePropertyLocations}</TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableThemeProvider>
          </Grid>
          <Grid item xs={12}>
            <GeneratedComment
              isDynamicContent
              label="Cap Rate Comparables Discussion"
              title="Generated Commentary"
              dataPath="capRateCompsDiscussion"
              tooltipText={CAP_RATE_DISCUSSION_TOOLTIP}
              getGeneratedText={getCapRateCompsDiscussion}
            />
          </Grid>
        </Grid>
      </div>
    )
  }
}

export default withStyles(styles)(CapRateComps)
