import { OTHER } from '../../../../client/src/report/constants'

import { FLOOR_MATERIALS } from './materials'

export const FLOORS = [
  FLOOR_MATERIALS.CERAMIC_TILED,
  FLOOR_MATERIALS.LAMINATE_TILED,
  FLOOR_MATERIALS.HARDWOOD,
  FLOOR_MATERIALS.LINOLEUM,
  FLOOR_MATERIALS.STONE_TILED,
  FLOOR_MATERIALS.VINYL_PLANK,
  OTHER,
]

export const COUNTERTOPS = ['Laminate', 'Granite', 'Marble', 'Man-made Stone', 'Stainless Steel', 'Concrete', OTHER]

export const STOVETOPS = ['Standard Oven/Range', 'Stainless Steel Oven/Range', 'Professional Grade Oven/Range', OTHER]

export const REFRIGERATORS = ['Standard', 'Stainless Steel', 'Professional Grade', OTHER]

export const CABINETS = ['White Laminate', 'Natural Wood', 'Custom Cabinetry', OTHER]
