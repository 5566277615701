import React from 'react'

import { get, merge, transform } from 'lodash'

const sxFactory = ({ modifiers, custom, sx }) =>
  transform(
    modifiers,
    (result, value, key) => {
      const option = get(custom, key, null)
      if (option !== null) {
        merge(result, value(option))
      }
    },
    sx
  )

const useSx = ({ modifiers, custom, sx }) => {
  const modifiedSx = React.useRef(sxFactory({ modifiers, custom, sx }))
  return modifiedSx.current
}

const useMeta = ({ meta, hint }) => {
  const error = meta?.invalid
  const helperText = meta?.error || hint
  const metaModified = React.useMemo(() => ({ error, helperText }), [error, helperText])
  return metaModified
}

export { useSx, useMeta }
