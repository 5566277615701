import React, { useCallback, useEffect, useMemo, useRef } from 'react'
import { noop, without, lowerFirst, pickBy, mean, isFinite } from 'lodash'
import ObjectID from 'bson-objectid'
import { validate } from 'uuid'
import { ColumnBasedTable, useColumnBasedGridRows } from '@bowery-valuation/ui-components'
import '@bowery-valuation/ui-components/dist/style.css'

import {
  EXPENSE_RATIO,
  EXPENSE_COMPARABLES_INFO,
  EXPENSE_COMPARABLES_CATEGORIES,
  TOTAL_OPERATING_EXPENSES_CATEGORIES,
} from '../../../../../../shared/constants/expenses/expenseComparables'
import { BASIS_OF_COMPARISON_OPTIONS } from '../../../../../../shared/constants/expenses'

import { showSection, SECTIONS } from '../../../../../../shared/helpers/propertyType'

import { rowManagedRenderer } from '../helpers'

import { useColumnDefinitions } from './useColumnDefinitions'
import { getType, getValuePerBasis, getCellPath } from './helpers'

const setName = (row, basis, protectedRowIds) => {
  const { id, name } = row
  const setCustomName = () => {
    const customTitle = name.includes(lowerFirst(basis)) ? name : `${name} ${lowerFirst(basis)}`
    return basis !== BASIS_OF_COMPARISON_OPTIONS.GROSS ? customTitle : name
  }
  return protectedRowIds.includes(id) ? name : setCustomName()
}

const ComparableExpensesTable = ({
  propertyType,
  deleteColumn,
  rowsApi,
  basis,
  expenseCategories,
  comparableExpenses,
  showExpenseRatio,
  expenseRatio,
  totalOperatingExpenses,
  totalOperatingExpensesPerSF,
  totalOperatingExpensesPerUnit,
  shouldRefreshTable,
  deleteRow,
  changeCompModalMode,
  latestCompVersions,
  classes,
}) => {
  const ref = useRef()
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const rows = expenseCategories || []
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const items = comparableExpenses || []

  useEffect(() => {
    // TODO: This is a hack to make the grid render correctly.
    setTimeout(() => {
      return ref?.current?.refreshCells({ suppressFlash: true, enableCellChangeFlash: true, force: true })
    })
  }, [basis])

  useEffect(() => {
    // A hack to make the grid update styles for cells with invalid data without updating cell values.
    if (shouldRefreshTable) {
      return ref?.current?.refreshCells({ force: true })
    }
  }, [shouldRefreshTable])

  const protectedRowIds = useMemo(
    () =>
      rows
        .filter(row =>
          without(Object.keys(EXPENSE_COMPARABLES_INFO), EXPENSE_COMPARABLES_CATEGORIES.egi).includes(row.id)
        )
        .map(row => row.id),
    [rows]
  )

  // comps from comp-plex
  const disabledColumnIds = useMemo(() => items.filter(comp => comp.boweryId).map(comp => comp.key), [items])

  const typeSelector = useCallback(row => {
    return getType(row.id)
  }, [])

  const valueFormatter = useCallback(
    ({ data, value, ...props }) => {
      const { id, basisValue } = data
      const protectedCategories = [...protectedRowIds, ...TOTAL_OPERATING_EXPENSES_CATEGORIES]
      if (protectedCategories.includes(id)) {
        return value
      }
      const valuePerBasis = getValuePerBasis(basisValue, value, props?.column?.getUserProvidedColDef())
      return valuePerBasis
    },
    [protectedRowIds]
  )

  const averageFormatter = useCallback(
    ({ data, value, ...props }) => {
      const { id, basisValue } = data
      if (protectedRowIds.includes(id)) {
        return value
      }
      if (TOTAL_OPERATING_EXPENSES_CATEGORIES.includes(id)) {
        return null
      }
      const values = pickBy(data, (__, key) => ObjectID.isValid(key) || validate(key))
      const valuesPerBasis = Object.entries(values)
        .map(([key, itemValue]) => {
          return getValuePerBasis(basisValue, itemValue, props?.columnApi?.getColumn(key)?.getUserProvidedColDef())
        })
        .filter(item => typeof item === 'number')
      const averageValue = mean(valuesPerBasis)
      const formattedValue = isFinite(averageValue) ? averageValue : null
      return formattedValue
    },
    [protectedRowIds]
  )
  const columnDefinitions = useColumnDefinitions({
    valueFormatter,
    averageValueFormatter: averageFormatter,
    openCompModal: changeCompModalMode,
    latestCompVersions,
    classes,
  })

  const valueForRow = (item, row) => {
    return item?.expenses?.find(expense => expense.id === row.id)?.value
  }

  const cellEditable = useCallback(
    ({ row, colDef, ...params }) => {
      if (disabledColumnIds.includes(colDef.field)) {
        return false
      }
      if (row.id === EXPENSE_COMPARABLES_CATEGORIES.expenseMonth) {
        const row = params.node.rowModel.nodeManager.getRowNode(EXPENSE_COMPARABLES_CATEGORIES.expensePeriod)
        const columnExpensePeriod = row.data[colDef.field]
        return !(columnExpensePeriod === 'Actual' || columnExpensePeriod === 'Projection')
      }
      if (basis === BASIS_OF_COMPARISON_OPTIONS.GROSS) {
        return true
      } else {
        return protectedRowIds.includes(row.id)
      }
    },
    [basis, protectedRowIds, disabledColumnIds]
  )

  const { columnBasedGridRows: expensesRows, rowFields } = useColumnBasedGridRows(
    {
      rows: rows.map(row => ({
        ...row,
        name: setName(row, basis, protectedRowIds),
        rowDef: {
          ...row?.rowDef,
          hideAction: [
            ...Object.keys(EXPENSE_COMPARABLES_INFO),
            EXPENSE_COMPARABLES_CATEGORIES.realEstateTaxes,
          ].includes(row.id),
        },
        basisValue: basis,
      })),
      items,
      editable: cellEditable,
      itemValueForRow: valueForRow,
      itemKey: value => value.key,
      path: getCellPath,
      type: typeSelector,
    },
    [rows, items, basis, protectedRowIds]
  )

  const { rowFields: summaryFields, columnBasedGridRows: summaryRows } = useColumnBasedGridRows(
    {
      rows: [
        ...(showExpenseRatio ? [expenseRatio] : []),
        totalOperatingExpenses,
        totalOperatingExpensesPerSF,
        ...(showSection(SECTIONS.HAS_RESIDENTIAL, propertyType) ? [totalOperatingExpensesPerUnit] : []),
      ].map(row => ({
        ...row,
        rowDef: {
          summary: true,
          path: (row, itemIndex) => {
            return `comparableExpenses[${itemIndex}].${row.id}`
          },
        },
        basisValue: basis,
      })),
      items,
      type: row => (row.id === EXPENSE_RATIO.key ? 'percent' : 'money'),
      editable: () => false,
      itemKey: value => value.key,
    },
    [
      basis,
      items,
      showExpenseRatio,
      expenseRatio,
      totalOperatingExpenses,
      totalOperatingExpensesPerSF,
      propertyType,
      totalOperatingExpensesPerUnit,
    ]
  )

  const tableRows = useMemo(() => [...expensesRows, ...summaryRows], [expensesRows, summaryRows])

  return (
    <>
      {rowFields}
      {summaryFields}
      <ColumnBasedTable
        id="comparable-expenses-table"
        parseRowBasedOnType
        gridApiRef={ref}
        disableVirtualization
        rowManagedRenderer={rowManagedRenderer}
        columns={columnDefinitions}
        hideIndexColumn
        rows={tableRows}
        legacyComponentRendering
        disableStaticMarkup
        items={items}
        onRowsDragEnd={noop}
        onRowUpdate={rowsApi.updateRow}
        onRowDelete={deleteRow}
        onColumnDelete={deleteColumn}
        onColumnUpdate={noop}
        onColumnDragEnd={noop}
        onManyRowsUpdate={rowsApi.updateManyRows}
      />
    </>
  )
}

export default React.memo(ComparableExpensesTable)
