import { handleActions } from 'redux-actions'

import { userOfficesFetchReceive } from '../actions/user'

const initialState = {
  userOffices: [],
}

export const reducer = handleActions(
  {
    [userOfficesFetchReceive](state, { payload }) {
      return {
        ...state,
        userOffices: payload,
      }
    },
  },
  initialState
)
