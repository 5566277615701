import { toNumber, isInteger, isNaN } from 'lodash'

export const isValidBathroomCount = bathroomCount => {
  const parsedBathroomCount = toNumber(bathroomCount)

  if (isNaN(parsedBathroomCount)) {
    return false
  }

  if (isInteger(parsedBathroomCount)) {
    return parsedBathroomCount >= 0
  }

  if (parsedBathroomCount % 0.5 === 0) {
    return parsedBathroomCount >= 0
  }

  return false
}
