import React from 'react'
import { Field } from 'react-final-form'

import SwitchList from './SwitchList'

const SwitchListWrapper = ({ input: { value, onChange }, meta, label, maxRowWidth, items, ...props }) => {
  return <SwitchList values={value} label={label} items={items} onChange={onChange} maxRowWidth={maxRowWidth} />
}

export const SwitchListField = ({ name, onChange, ...props }) => {
  return <Field name={name} component={SwitchListWrapper} onChange={onChange} {...props} />
}
