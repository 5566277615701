import { GeneratedText, gtIfElse, gtLocked, gtTemplate } from '../../../narrative/GeneratedText'

import { mapDTO, mapDataModel } from './mappings'

const generate = () => {
  const showAdjustmentsFragment = gtIfElse('showAdjustments', gtTemplate`After adjustments, t`, gtTemplate`T`)
  const adjustedMin = gtLocked('adjustedMin')
  const adjustedMax = gtLocked('adjustedMax')
  const adjustedMedian = gtLocked('adjustedMedian')
  const adjustedAvg = gtLocked('adjustedAvg')
  const valueConclusion = gtLocked('valueConclusion')
  const calculationUnits = gtLocked('calculationUnits')
  const deductionSentence = gtIfElse(
    'noAdjustments',
    gtTemplate``,
    gtTemplate` We make the same deductions as in the Income Approach.`
  )
  const gt = new GeneratedText()

  gt.t`${showAdjustmentsFragment}he comparable sales exhibited a range between ${adjustedMin} per ${calculationUnits} 
  and ${adjustedMax} per ${calculationUnits} with an average of ${adjustedAvg} per ${calculationUnits} and 
  a median of ${adjustedMedian} per ${calculationUnits}. Thus, considering the elements of comparison noted 
  above, our opinion of market value is ${valueConclusion} per ${calculationUnits}.${deductionSentence}`

  return gt.getParts()
}

export default { generate, mapDTO, mapDataModel }
