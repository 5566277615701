import {
  GeneratedText,
  gtConditional,
  gtLocked,
  gtLockedSentence,
  gtTemplate,
} from 'shared/utils/narrative/GeneratedText'

import { mapDTO, mapDataModel } from './mappings'

const generate = () => {
  const lastSentence = 'the residential rent roll is summarized by unit type and rent regulation status.'

  const addressGt = gtLocked('address')
  const willOrDoesContainGt = gtLockedSentence('willOrDoesContain')
  const residentialUnitWord = gtLocked('residentialUnitWords', null, 'Residential Unit Count')
  const asStabilizedSubsentenceGt = gtConditional('valueAsStabilized', gtTemplate` upon stabilization`)

  const gt = new GeneratedText()

  gt.t`${addressGt} ${willOrDoesContainGt} ${residentialUnitWord}${asStabilizedSubsentenceGt}; ${lastSentence}`

  return gt.getParts()
}

export default { generate, mapDTO, mapDataModel }
