import { Theme } from 'core/theme'

export const textAndNumberStyles = (theme: Theme) => {
  const borderRadius = 4

  return {
    outlineInput: {
      borderRadius: borderRadius,
      height: '36px',
      '&&:hover fieldset': {
        borderColor: theme.palette.primary.main,
      },
      '&&:focus-within fieldset': {
        border: `1px solid ${theme.palette.primary.main}`,
      },
    },

    nativeInput: {
      height: '15px',
      lineHeight: '15px',
    },

    error: {
      '&&:hover fieldset': {
        borderColor: theme.palette.error.main,
      },
      '&&:focus-within fieldset': {
        borderColor: theme.palette.error.main,
      },
    },

    disabled: {
      backgroundColor: theme.palette.grey[100],
      borderRadius: borderRadius,
      '&&& fieldset': {
        borderColor: theme.palette.grey[100],
      },
    },
  }
}
