import React from 'react'
import PropTypes from 'prop-types'

import { withStyles } from '@mui/styles'
import MaterialTooltip from '@mui/material/Tooltip'

const styles = theme => ({
  root: {
    color: theme.palette.common.white,
    background: '#404858',
    boxShadow: '0px 2px 8px rgba(0, 15, 68, 0.12)',
    fontSize: 12,
    fontFamily: 'Nunito Sans',
  },
  childrenWrapper: {
    display: 'inline-block',
  },
  popper: {},
})

class Tooltip extends React.PureComponent {
  static propTypes = {
    classes: PropTypes.object.isRequired,
    text: PropTypes.node.isRequired,
    children: PropTypes.node.isRequired,
    placement: PropTypes.string,
  }

  render() {
    const { classes, text, placement, ...props } = this.props
    return (
      <MaterialTooltip
        title={text}
        placement={placement}
        classes={{ tooltip: classes.root, popper: classes.popper }}
        {...props}
      >
        <span className={classes.childrenWrapper}>{this.props.children}</span>
      </MaterialTooltip>
    )
  }
}

export default withStyles(styles)(Tooltip)
