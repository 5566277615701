import React from 'react'
import PropTypes from 'prop-types'
import { DialogContent, withStyles } from '@material-ui/core'

import DialogHeader from '../DialogHeader'

import { LABELS } from '../../constants/properties'
import SourceOfInformation from '../SourceOfInformation'

import StepFooter from './StepFooter'
const styles = theme => ({
  content: {
    minWidth: 450,
  },
})

class AdditionalInformation extends React.PureComponent {
  static propTypes = {
    submit: PropTypes.func.isRequired,
    cancel: PropTypes.func.isRequired,
    invalid: PropTypes.bool.isRequired,
    submitText: PropTypes.string,
    cancelText: PropTypes.string,
    handleClose: PropTypes.func.isRequired,
  }

  static defaultProps = {
    submitText: LABELS.NEXT,
    cancelText: LABELS.CANCEL,
  }

  onCancel = () => {
    this.props.cancel()
  }

  render() {
    const { handleClose, submit, invalid, submitText, cancelText, classes } = this.props

    return (
      <React.Fragment>
        <DialogHeader onClose={handleClose}>Additional Information</DialogHeader>
        <DialogContent className={classes.content} data-qa="additional-information-modal">
          <SourceOfInformation />
          <StepFooter
            previous={{ text: cancelText, onClick: this.onCancel }}
            next={{ text: submitText, onClick: submit, isDisabled: invalid }}
          />
        </DialogContent>
      </React.Fragment>
    )
  }
}

export default withStyles(styles)(AdditionalInformation)
