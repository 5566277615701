import { isArray, find, valuesIn, findIndex, keyBy, isNil, filter } from 'lodash'

import { UNCATEGORIZED_UNIT_COMP_GROUP_KEY } from 'shared/constants/incomeApproach'
import { extractGroupingParameters } from 'shared/helpers/incomeApproach'
import { getResidentialUnitSummary } from 'shared/helpers/incomeApproach/residential'

import { LeaseStatusTypes } from '../../../../../../../shared/constants/report/incomeApproach/residential'

import { oneOf } from '../../../../../shared/utils/validation'
import { RentRollOutdoorSpaces, RENT_ROLL_OUTDOOR_SPACES, RENT_TYPES, UNIT_LAYOUTS } from '../../../../constants'
import { formatCurrencyInt, formatCurrencyFloat } from '../../../../../shared/utils/numberFormatters'

import { calculateRentPSFByTimePeriod } from '../../../../../../../shared/calculations/rentRoll'

import {
  TrueOrFalseValues,
  CSVFileColumns,
  REQUIRED_COLUMNS,
  BATHROOMS_ERROR_MESSAGE,
  SUBJECT_UNIT_GROUPING_TYPES,
} from './constants'

const NON_NUMBERS_REGEX = /[^-0-9.]/g
export const parseToFloat = value => {
  if (Number.isFinite(value)) {
    return parseFloat(value)
  }
  if (typeof value !== 'string') {
    return 0
  }
  return parseFloat(value.replace(NON_NUMBERS_REGEX, '')) || 0
}

export const parseToInt = value => {
  if (Number.isFinite(value)) {
    return parseInt(value)
  }
  if (typeof value !== 'string') {
    return 0
  }
  return parseInt(value.replace(NON_NUMBERS_REGEX, '')) || 0
}

const getOutdoorSpaceValue = value => (RENT_ROLL_OUTDOOR_SPACES.includes(value) ? value : RentRollOutdoorSpaces.NONE)

const getUnitLayout = value => (UNIT_LAYOUTS.includes(value) ? value : null)

const getRentTypeValue = value => (RENT_TYPES.includes(value) ? value : null)

export const parseUnits = ({ data, errors, meta }) => {
  if (!isArray(data)) {
    return null
  }

  const { fields } = meta
  if (!REQUIRED_COLUMNS.every(column => fields.includes(column))) {
    return null
  }

  data.shift()
  const units = data.map((item = {}) => {
    let leaseStatus
    if (item[CSVFileColumns.VACANT] === TrueOrFalseValues.YES) {
      leaseStatus = LeaseStatusTypes.VACANT
    } else if (getRentTypeValue(item[CSVFileColumns.RENT_TYPE])) {
      leaseStatus = LeaseStatusTypes.OCCUPIED
    } else {
      leaseStatus = LeaseStatusTypes.EMPLOYEE
    }
    return {
      number: item[CSVFileColumns.NUMBER],
      bedrooms: parseToInt(item[CSVFileColumns.BEDROOMS]),
      rooms: parseToInt(item[CSVFileColumns.ROOMS]),
      bathrooms: parseToFloat(item[CSVFileColumns.BATHROOMS]),
      squareFootage: parseToFloat(item[CSVFileColumns.SQUARE_FOOTAGE]),
      outdoor: getOutdoorSpaceValue(item[CSVFileColumns.OUTDOOR_SPACE]),
      unitLayout: getUnitLayout(item[CSVFileColumns.UNIT_LAYOUT]),
      rentType: getRentTypeValue(item[CSVFileColumns.RENT_TYPE]),
      rent: parseToFloat(item[CSVFileColumns.RENT]),
      rentForecast: parseToFloat(item[CSVFileColumns.RENT_FORECAST]),
      isVacant: item[CSVFileColumns.VACANT] === TrueOrFalseValues.YES,
      leaseStatus,
      isInspected: item[CSVFileColumns.INSPECTED] === TrueOrFalseValues.YES,
    }
  })

  return units
}

export const validateBathrooms = value => {
  if (isNil(value)) {
    return
  }
  return value % 0.5 !== 0 ? BATHROOMS_ERROR_MESSAGE : undefined
}

export const validateUnitLayout = value => oneOf(UNIT_LAYOUTS)(value)

export const validateOutdoor = value => oneOf(RENT_ROLL_OUTDOOR_SPACES)(value)

export const getUnitRoomsCount = unit => {
  return isNil(unit.rooms) ? (unit.bedrooms || 0) + 2 : unit.rooms
}

export const getUnitRentRoom = unit => {
  return formatCurrencyInt(unit.rent / getUnitRoomsCount(unit))
}

export const getUnitRentPSF = (unit, timePeriod) => {
  if (!unit.squareFootage) {
    return '-'
  }

  const rentPSFPerTimePeriod = calculateRentPSFByTimePeriod(unit.rent, unit.squareFootage, timePeriod)
  return formatCurrencyFloat(rentPSFPerTimePeriod)
}

export const getUnitRentForecastPSF = (unit, timePeriod) => {
  if (!unit.squareFootage) {
    return '-'
  }

  const rentPSFPerTimePeriod = calculateRentPSFByTimePeriod(unit.rentForecast, unit.squareFootage, timePeriod)
  return formatCurrencyFloat(rentPSFPerTimePeriod)
}

export const generateGroupingKeyAndName = (groupingType, groupingParameters, forExport = false) => {
  const groupingLogic = SUBJECT_UNIT_GROUPING_TYPES[groupingType]
  const nameParts = []
  const keyParts = []

  if (groupingLogic) {
    for (const parameter of groupingLogic) {
      const parameterValue = find(groupingParameters, grParameter => grParameter.path === parameter.path).value

      if (typeof parameter.label === 'function') {
        nameParts.push(parameter.label(parameterValue, forExport))
      } else {
        nameParts.push(parameter.label.replace('{0}', parameterValue))
      }

      keyParts.push(`${parameter.path}_${parameterValue}`)
    }
  }
  return {
    name: nameParts.join(' '),
    key: keyParts.join('_'),
  }
}

export const findUnitCompGroupAndAddComps = (unitCompGroups, groupingType, units = [], unitCompsById = {}) => {
  const unitCompGroupsByKey = keyBy(unitCompGroups, group => group.unitGroupKey)
  const uncategorizedCompGroup = unitCompGroupsByKey[UNCATEGORIZED_UNIT_COMP_GROUP_KEY.key] || {
    unitGroupName: UNCATEGORIZED_UNIT_COMP_GROUP_KEY.name,
    unitGroupKey: UNCATEGORIZED_UNIT_COMP_GROUP_KEY.key,
    units: [],
  }

  for (const unit of units) {
    if (unitCompsById[unit._id]) {
      continue
    }

    const groupingParameters = extractGroupingParameters(unit, groupingType)
    const unitCompGroup = unitCompGroupsByKey[groupingParameters.key] || uncategorizedCompGroup
    if (!unitCompGroup.units.includes(unit)) {
      unitCompGroup.units.push(unit)
    }
  }

  if (uncategorizedCompGroup.units.length > 0) {
    unitCompGroupsByKey[UNCATEGORIZED_UNIT_COMP_GROUP_KEY.key] = uncategorizedCompGroup
  } else {
    delete unitCompGroupsByKey[UNCATEGORIZED_UNIT_COMP_GROUP_KEY.key]
  }

  return valuesIn(unitCompGroupsByKey)
}

export const findUnitCompGroupAndRemoveComp = (unitCompGroups, groupingType, unit, unitCompsById) => {
  if (!unitCompsById[unit._id]) {
    return unitCompGroups
  }

  const unitCompGroupsByKey = keyBy(unitCompGroups, group => group.unitGroupKey)
  const uncategorizedCompGroup = unitCompGroupsByKey[UNCATEGORIZED_UNIT_COMP_GROUP_KEY.key]

  for (const unitCompGroup of unitCompGroups) {
    if (find(unitCompGroup.units, groupedUnit => groupedUnit._id === unit._id)) {
      unitCompGroup.units = filter(unitCompGroup.units, groupedUnit => groupedUnit._id !== unit._id)
    }
  }

  if (uncategorizedCompGroup && uncategorizedCompGroup.units.length === 0) {
    delete unitCompGroupsByKey[UNCATEGORIZED_UNIT_COMP_GROUP_KEY.key]
  }

  return valuesIn(unitCompGroupsByKey)
}

// TODO: combine with the add comp method?
export const findUnitCompGroupAndUpdateComp = (unitCompGroups, groupingType, unit, unitCompsById) => {
  if (!unitCompsById[unit._id]) {
    return unitCompGroups
  }

  for (const unitCompGroup of unitCompGroups) {
    const unitIndex = findIndex(unitCompGroup.units, groupedUnit => groupedUnit._id === unit._id)
    if (unitIndex > -1) {
      unitCompGroup.units.splice(unitIndex, 1, unit)
    }
  }

  return [...unitCompGroups]
}

/** @deprecated Prefer importing this from the shared folder */
export { getResidentialUnitSummary }
