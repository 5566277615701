import arrayToSentence from 'array-to-sentence'

import { toValidPercents } from 'shared/utils/numberOperations'

const AnalysesMethods = {
  BAND_OF_INVESTMENT: 'Band of Investment',
  DEBT_COVERAGE_RATIO: 'Debt Coverage Ratio',
  COMPARABLE_CAPITALIZATION_RATES: 'Comparable Capitalization Rates',
  NATIONAL_SURVEY_RESPONSES: 'National Survey Responses',
  PERSONAL_SURVEYS: 'Personal Surveys',
}

const DEFAULT_ANALYSES_METHODS = [
  AnalysesMethods.COMPARABLE_CAPITALIZATION_RATES,
  AnalysesMethods.NATIONAL_SURVEY_RESPONSES,
]

const FREDDIE_MAC_ANALYSES_METHODS = [
  AnalysesMethods.DEBT_COVERAGE_RATIO,
  AnalysesMethods.COMPARABLE_CAPITALIZATION_RATES,
  AnalysesMethods.NATIONAL_SURVEY_RESPONSES,
]

export const generateMortgageComponentDiscussion = (
  loanValueRatio,
  mortgageRate,
  amortizationTerm,
  mortgageConstant
) => {
  return (
    `After surveying several commercial mortgage lenders, it is our opinion that a typical creditworthy ` +
    `purchaser could obtain financing from a lending source in an amount equal to ${toValidPercents(
      loanValueRatio
    )} of value at an annual interest rate of ${toValidPercents(
      mortgageRate
    )} and a ${amortizationTerm}-year payout. Therefore, the mortgage constant is ${
      mortgageConstant ? mortgageConstant.toFixed(4) : 'N/A'
    }.`
  )
}

export const generateBandOfInvestmentDiscussion = (includeBandOfInvestmentInExport, dividendRate) => {
  if (includeBandOfInvestmentInExport) {
    return (
      `An investor would likely accept an initial annual return of ${toValidPercents(
        dividendRate
      )} in anticipation of ` +
      `a stable income flow and property appreciation over time. It should be emphasized that the ` +
      `equity dividend rate is not necessarily the same as an equity yield rate or true rate of return on equity ` +
      `capital. The equity dividend rate is an equity capitalization that reflects all benefits that can be ` +
      `recognized by the equity investor as of the date of purchase. We selected this rate based on the ` +
      `subject's location in a good residential area, and its good access and visibility. We summarize the mortgage ` +
      `and equity parameters utilized in our derivation of an overall capitalization rate.`
    )
  }
  return ``
}

export const generateDebtCoverageRatioDiscussion = debtCoverageCapRate => {
  return `The Debt Coverage Ratio Analysis suggests a capitalization rate of ${toValidPercents(debtCoverageCapRate)}.`
}

export const generateIncomeCapitalizationIntroduction = ({ isFreddieMac, bandOfInvestment, personalSurveys }) => {
  const generatedText =
    'In developing an opinion of the overall capitalization rate required by an investor, we will apply several methods of analyses:'

  let methods = isFreddieMac ? FREDDIE_MAC_ANALYSES_METHODS : DEFAULT_ANALYSES_METHODS
  const hasPersonalSurveys = personalSurveys.includeInExport
  const hasBandOfInvestment = bandOfInvestment.includeInExport

  if (hasBandOfInvestment) {
    methods = methods.concat(AnalysesMethods.BAND_OF_INVESTMENT)
  }
  if (hasPersonalSurveys) {
    methods = methods.concat(AnalysesMethods.PERSONAL_SURVEYS)
  }

  const methodsList = arrayToSentence(methods.map((method, index) => `(${index + 1}) ${method}`), {
    separator: '; ',
    lastSeparator: '; and ',
  })

  return `${generatedText} ${methodsList}.`
}
