import { getPartsFromString } from '../../narrative/GeneratedText'

export const APPRAISAL_VALUATION_PROCESS = {
  costApproach: `The Cost Approach is based on market participants relating value of improvements to associated costs \
to build. In the Cost Approach, the cost to build a new or substitute property is used as a comparison to the \
subject. The replacement cost or reproduction cost estimate is then adjusted for all applicable depreciation. \
This approach reflects a good indicator of value when the improvements are new, nearly new, close to fully \
depreciated, or when the property has unique or specialized improvements.`,
  incomeApproach: `The Income Capitalization Approach reflects the analysis of a property’s capacity to generate  \
future income and capitalizes the income into an indication of present value. This approach reflects the \
relationship between a property’s potential income and its market value and is a strong indicator of value when \
there is reliable market data to derive market rents, vacancy rates, stabilized expenses, and \
capitalization/discount rates. The two most common valuation techniques are direct capitalization and the discounted \
cash flow (DCF) analysis, with one or both methods applied as appropriate. This approach is widely used in \
appraising income producing properties.`,
  salesApproach: `The Sales Comparison Approach assumes that an informed purchaser would pay no more for a property \
than the cost of acquiring another existing property with similar utility. This approach is reliable in an active \
market with sufficient sales data where few differences exist between the comparable sales and the subject, and the \
sales data collected is credible and accurate. Similar property types in competitive locations tend to sell within \
a consistent range, and this factor makes valuation on a per square foot and per unit basis a strong predictor of \
value. The Sales Comparison Approach is often relied upon for owner-user properties and/or in markets where leasing \
activity is sparse. For leased properties, this approach is more often considered as secondary support for the \
Income Approach.`,
  descriptionOfMethodologies: `Each approach applied is then reconciled to a final value conclusion after weighing \
the quantity and quality of data analyzed and the applicability of each approach to the subject property type.`,
}

export const getCostApproach = () => getPartsFromString(APPRAISAL_VALUATION_PROCESS.costApproach)
export const getIncomeApproach = () => getPartsFromString(APPRAISAL_VALUATION_PROCESS.incomeApproach)
export const getSalesCompApproach = () => getPartsFromString(APPRAISAL_VALUATION_PROCESS.salesApproach)
export const getDescriptionOfMethodologies = () =>
  getPartsFromString(APPRAISAL_VALUATION_PROCESS.descriptionOfMethodologies)
