export const styles = theme => ({
  dropDownFormControl: {
    height: 'auto',
    minWidth: 64,
    '& > div': {
      margin: 0,
    },
    '& svg': {
      paddingRight: 12,
    },
    backgroundColor: 'white',
  },
  dropDownSelect: {
    marginRight: -14,
    height: 53,
    '& fieldset': {
      borderRadius: '0px 4px 4px 0px',
    },
  },
  dropDownAdornedEnd: {
    paddingRight: 0,
  },
  warningIcon: {
    color: '#FFB800',
  },
  subtitle: {
    marginTop: 12,
  },
  tooltip: {
    maxWidth: 'none',
  },
  cardSpacing: {
    marginBottom: '16px',
  },
  noBackground: {
    background: 'none',
  },
  propertyTypeDropDown: {
    marginBottom: 27,
  },
  currentPropertyTypeDropDown: {
    marginTop: 8,
    marginBottom: 22,
  },
})
