import React from 'react'
import { FieldArray } from 'react-final-form-arrays'
import { withStyles } from '@material-ui/core/styles'
import { compose } from 'recompose'
import { get, isEqual } from 'lodash'
import flowFP from 'lodash/fp/flow'
import toLowerFP from 'lodash/fp/toLower'
import startCaseFP from 'lodash/fp/startCase'
import classnames from 'classnames'

import { Grid, Paper, TableCell } from '@material-ui/core'

import styles from '../styles'

import { GeneratedComment } from '../../../../../shared/components'

import RemovedCompsTable from '../../../../components/RemovedCompsTable'
import { getSourceOfInformation } from '../../../../../shared/components/SourceOfInformation/SourceOfInformation'
import { formatCurrencyFloat, formatInt } from '../../../../../shared/utils/numberFormatters'
import { getCheckboxCellContent, getRemovedCompActions } from '../../../../utils/columnHelpers'

import SelectedTaxCompsTable from './SelectedTaxCompsTable'
import { getSelectedTaxCompColumns } from './constants'

const TAX_COMPS_TOOLTIP =
  'The following generated commentary will appear in the Assessed Value & Real Estate Taxes section of your report.'

const getTaxCompsGeneratedText = values => {
  if (get(values, 'projected.includedInExport')) {
    return 'In order to support the forecasted real estate tax liability, we surveyed those of comparable buildings in the area.'
  }
  return 'In order to support the current real estate tax liability, we surveyed those of comparable buildings in the area.'
}
class TaxComps extends React.PureComponent {
  removedCompsTableRowCell = column => {
    const {
      comp,
      column: { name, defaultValue },
    } = column
    const { classes } = this.props
    let cellContent
    let classNames = [classes.disabledTextCell, classes.removedCompTableCell]

    switch (name) {
      case 'address':
        cellContent = flowFP([toLowerFP, startCaseFP])(get(comp, name, defaultValue))
        break
      case 'isYearBuiltEstimate':
        cellContent = getCheckboxCellContent(column, { disabled: true })
        classNames = [classes.disabledTextCell, classes.removedCompCheckboxCell]
        break
      case 'basis':
        cellContent = formatInt(get(comp, name, defaultValue))
        classNames.push(classes.numberCell)
        break
      case 'taxesPerBasis':
        cellContent = formatCurrencyFloat(get(comp, name, defaultValue))
        classNames.push(classes.numberCell)
        break
      case 'sourceOfInformation':
        cellContent = getSourceOfInformation(comp)
        break
      case 'actions':
        cellContent = getRemovedCompActions(column, classes, this.unremoveComp, this.deleteRemovedComp)
        break
      case 'yearBuilt':
      case 'taxYear':
        classNames.push(classes.numberCell)
        cellContent = get(comp, name, defaultValue)
        break
      default:
        cellContent = get(comp, name, defaultValue)
    }

    return (
      <TableCell className={classnames(...classNames)} data-qa={`removed-comps-table-${name}-cell`} padding="none">
        {cellContent}
      </TableCell>
    )
  }

  deleteRemovedComp = compIndex => {
    const { form } = this.props
    const { removedTaxComps } = form.values
    const newRemovedTaxComps = removedTaxComps.filter((_value, index) => index !== compIndex)

    form.change('removedTaxComps', newRemovedTaxComps)
  }

  unremoveComp = compIndex => {
    const { form } = this.props
    const { removedTaxComps } = form.values
    const newRemovedTaxComps = removedTaxComps.filter((_value, index) => index !== compIndex)

    form.batch(() => {
      form.mutators.push('taxComps', removedTaxComps[compIndex])
      form.change('removedTaxComps', newRemovedTaxComps)
    })
  }

  render() {
    const { form, classes } = this.props
    return (
      <Grid container>
        <FieldArray classes={classes} component={SelectedTaxCompsTable} form={form} isEqual={isEqual} name="taxComps" />
        <RemovedCompsTable
          cellComponent={this.removedCompsTableRowCell}
          classes={classes}
          columns={getSelectedTaxCompColumns(get(form, 'values.basis'), classes)}
          fieldName="removedTaxComps"
          form={form}
          title="Removed Comp"
        />
        <Grid item xs={12}>
          <Paper className={classes.paper}>
            <Grid item xl={12} className={classes.discussionContainer}>
              <GeneratedComment
                className={classes.title}
                dataPath="taxCompsDiscussion"
                getGeneratedText={getTaxCompsGeneratedText}
                isDynamicContent
                label="Comparable Tax Liabilities Discussion"
                title="Generated Commentary"
                tooltipText={TAX_COMPS_TOOLTIP}
              />
            </Grid>
          </Paper>
        </Grid>
      </Grid>
    )
  }
}

export default compose(withStyles(styles))(TaxComps)
