import { select, put, takeLatest } from 'redux-saga/effects'

import * as CoreApi from '../../../../../core/api'

import { SEARCH_OPTIONS, GEOGRAPHY_OPTIONS } from '../../../../../shared/constants/properties'

import {
  expenseAddressesFetch,
  expenseAddressesFetchRequest,
  expenseAddressesFetchReceive,
  expenseAddressesFetchFail,
} from './actions'

function* expenseAddressesFetchHandler({ payload }) {
  const { address, location, zip } = payload
  try {
    const expensesAddressesLoading = yield select(state => state.expenseAddresses.isLoading)
    if (!expensesAddressesLoading) {
      yield put(expenseAddressesFetchRequest())
      const expensesAddresses = yield CoreApi.searchProperties({
        strategy: 'NY_ADDRESS',
        city: location,
        address,
        zip,
        locationIdentifier: GEOGRAPHY_OPTIONS.NY,
        locationType: SEARCH_OPTIONS.BBL,
      })
      yield put(expenseAddressesFetchReceive(expensesAddresses))
    }
  } catch (err) {
    yield put(expenseAddressesFetchFail(err))
  }
}

export default [takeLatest(expenseAddressesFetch, expenseAddressesFetchHandler)]
