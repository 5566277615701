export const TYPICAL_OUTDOOR_SPACE = {
  categoryName: 'outdoorSpace',
  categoryTitle: 'Outdoor Space Photos',
}

export const TYPICAL_PARKING = {
  categoryName: 'parking',
  categoryTitle: 'Typical Parking Photos',
}

export const TYPICAL_RECREATION_ROOM = {
  categoryName: 'recreationRoom',
  categoryTitle: 'Shared Recreation Room Photos',
}

export const TYPICAL_POOL = {
  categoryName: 'pool',
  categoryTitle: 'Pool Photos',
}

export const TYPICAL_GYM = { categoryName: 'gym', categoryTitle: 'Gym' }
export const TYPICAL_BIKE_ROOM = {
  categoryName: 'bikeRoom',
  categoryTitle: 'Bike Room Photos',
}

export const TYPICAL_COMMON_LOUNGE_SPACE = {
  categoryName: 'commonLoungeSpace',
  categoryTitle: 'Common Lounge Space Photos',
}

export const TYPICAL_LAUNDRY_ROOM = {
  categoryName: 'laundryRoom',
  categoryTitle: 'Laundry Space',
}

export const TYPICAL_STAIRWAY = {
  categoryName: 'typicalStairway',
  categoryTitle: 'Stairs',
}

export const TYPICAL_KITCHEN = {
  categoryName: 'typicalKitchen',
  categoryTitle: 'Typical Kitchen',
}

export const TYPICAL_LIVING_ROOM = {
  categoryName: 'typicalLivingRoom',
  categoryTitle: 'Typical Living Room',
}

export const TYPICAL_BEDROOM = {
  categoryName: 'typicalBedroom',
  categoryTitle: 'Typical Bedroom',
}

export const TYPICAL_BATHROOM = {
  categoryName: 'typicalBathroom',
  categoryTitle: 'Typical Bathroom',
}

export const TYPICAL_ELECTRIC_METERS = {
  categoryName: 'electricMeters',
  categoryTitle: 'Typical Electrical Meter Photos',
}
export const TYPICAL_GAS_METERS = {
  categoryName: 'gasMeters',
  categoryTitle: 'Typical Gas Meter Photos',
}
export const TYPICAL_HEATING_SYSTEM = {
  categoryName: 'heatingSystem',
  categoryTitle: 'Typical Heating System Photos',
}

export const TYPICAL_COMMERCIAL_UNIT_INTERIOR = {
  categoryName: 'interior',
  categoryTitle: 'Interior Images',
}

export const TYPICAL_COMMERCIAL_UNIT_EXTERIOR = {
  categoryName: 'exterior',
  categoryTitle: 'Exterior Images',
}

export const BUILDING_PHOTO = {
  categoryName: 'building',
  categoryTitle: 'Building Photo',
}

export const BUILDING_FACADE = {
  categoryName: 'buildingFacade',
  categoryTitle: 'Building Facade',
}

export const TYPICAL_COURTYARD = {
  categoryName: 'courtyard',
  categoryTitle: 'Typical Courtyard Photos',
}

export const TYPICAL_LOADING = {
  categoryName: 'loading',
  categoryTitle: 'Typical Loading Photos',
}

export const TYPICAL_STOREFRONT_EXPOSURE = {
  categoryName: 'storeFrontExposure',
  categoryTitle: 'Typical Storefront Exposure Photos',
}

export const TYPICAL_WAREHOUSE_SPACE = {
  categoryName: 'warehouseSpace',
  categoryTitle: 'Typical Warehouse Space Photos',
}

export const TYPICAL_OUTDOOR_SEATING = {
  categoryName: 'outdoorSeating',
  categoryTitle: 'Typical Outdoor Seating Photos',
}

export const TYPICAL_DRIVE_THRU = {
  categoryName: 'driveThru',
  categoryTitle: 'Typical Drive Thru Photos',
}

export const TYPICAL_OTHER = {
  categoryName: 'otherAmenities',
  categoryTitle: 'Typical Other Amenities Photos',
}

export const SALES_COMPS_IMAGES_CATEGORY = 'salesCompsCategory'

export const SUPPORTED_IMAGE_FORMATS =
  'image/tif,image/tiff,image/pjp,image/pjpeg,image/svgz,image/jpg,image/jpeg,image/gif,image/svg,image/bmp,image/png,image/jfif,image/webp'
