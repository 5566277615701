import React from 'react'
import { useFormState } from 'react-final-form'

import CreateReportRadioButtons from '../../../report/components/ReportSettings/ReportSettings'

import { BlocksReportButtons, ToolSelectionCheckboxes } from './BlocksReportFlow'

const ReportSettingsFieldsContainer = ({ blocksConfiguration, showBlocksToggle = true }) => {
  const { values } = useFormState()

  let showRadios = true
  let showBlocks = false
  if (values.settings && values.settings.isBlocks) {
    showRadios = false
    showBlocks = true
  }

  return (
    <>
      {showBlocksToggle && <BlocksReportButtons name="settings.isBlocks" />}
      {showBlocks && <ToolSelectionCheckboxes blocksConfiguration={blocksConfiguration} />}
      {showRadios && <CreateReportRadioButtons />}
    </>
  )
}

export default ReportSettingsFieldsContainer
