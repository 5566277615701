import PropTypes from 'prop-types'
import React from 'react'

class SparklinesLine extends React.Component {
  static propTypes = {
    color: PropTypes.string,
    style: PropTypes.object,
    accentColor: PropTypes.string,
  }

  static defaultProps = {
    style: {},
    accentColor: 'red',
  }

  render() {
    const { points, color, style, data, height } = this.props

    const linePoints = points.map(point => [point.x, height - point.y])

    const lines = linePoints
      .map((lp, index, array) => index + 1 < array.length && [...lp, ...array[index + 1]])
      .filter(Boolean)

    const defaultStyle = {
      stroke: color || style.stroke || 'slategray',
      strokeWidth: style.strokeWidth || '1',
      strokeLinejoin: style.strokeLinejoin || 'round',
      strokeLinecap: style.strokeLinecap || 'round',
      fill: 'none',
    }

    const accentStyle = {
      ...defaultStyle,
      stroke: this.props.accentColor,
    }

    const lineComponents = lines.map((line, index) => {
      const isZeroLine = data[index] === 0 && data[index + 1] === 0

      return <polyline key={index} points={line.join(' ')} style={isZeroLine ? accentStyle : defaultStyle} />
    })

    return <g>{lineComponents}</g>
  }
}

export default SparklinesLine
