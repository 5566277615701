import React from 'react'
import { findIndex, round } from 'lodash'
import { Paper } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'

import BoweryDate from '@bowery-valuation/bowery-date'
import { Grid, Typography } from '@mui/material'

import { DataDisplay } from 'client-shared/components/DataDisplay'
import { Number as NumberField } from 'client-shared/components'

import { SALES_APPROACH_TYPE_DISPLAY, UNIT_OF_COMPARISON_DISPLAY } from 'shared/constants/salesApproach'

import { VALUE_CONCLUSION_TYPES } from 'shared/constants/settings'

import {
  INCOME_ADJUSTMENT_TYPES,
  IncomeAdjustmentKeys,
} from '../../../../../../shared/constants/report/sales/salesAdjustment'

import { toPercents, fromPercents } from '../../../../shared/utils/numberOperations'
import { RadioButtonList } from '../../../../shared/components'
import { arrayToKeyValuePairs } from '../../../../shared/utils/ui/checkboxHelper'
import { Information } from '../../../../shared/components/_mui5/Icons'
import Tooltip from '../../../../shared/components/_mui5/Tooltip'
import Button from '../../../../shared/components/_mui5/Button'

import { MARKET_CONDITION_ADJUSTMENT_TOOLTIP } from './constants'

const styles = theme => ({
  paper: { ...theme.paper, marginBottom: 16 },
  marketConditionAdjustmentFormControl: {
    height: 'auto',
  },
})

class SalesComparableSetup extends React.PureComponent {
  applyMarketConditionAdjustment = () => {
    const { change, batch, formValues, valueConclusionType, dateOfValue, dateOfStabilization } = this.props
    const { marketConditionAdjustment, selectedComps, compAdjustments } = formValues
    const isAsIsConclusionType = valueConclusionType === VALUE_CONCLUSION_TYPES.AS_IS

    batch(() => {
      for (const [index, compAdjustment] of compAdjustments.entries()) {
        const { compId, adjustments } = compAdjustment
        const subjectValuationDate = isAsIsConclusionType ? dateOfValue : dateOfStabilization
        const salesComp = selectedComps.find(comp => comp._id === compId)
        const datesDiff = new BoweryDate(subjectValuationDate).getDifferenceBetweenDates(
          salesComp.saleInformation.saleDate,
          'day'
        )
        const marketConditionsPercents = ((datesDiff / 365) * (marketConditionAdjustment || 0) || 0) * 100
        const roundedMarketConditions = round(marketConditionsPercents) / 100

        const adjustmentIndex = findIndex(adjustments, adj => adj.name === 'marketConditions')
        const path = `compAdjustments.${index}.adjustments.${adjustmentIndex}.value`

        change(path, roundedMarketConditions)
      }
    })
  }

  render() {
    const { classes, formValues } = this.props
    const { incomeAdjustmentLevel, type, unitOfComparison } = formValues
    const incomeLevel = incomeAdjustmentLevel === IncomeAdjustmentKeys.income
    const unitOfComparisonDisplay = UNIT_OF_COMPARISON_DISPLAY[unitOfComparison]
    const typeDisplay = SALES_APPROACH_TYPE_DISPLAY[type]

    return (
      <Grid container>
        <Grid item xs={8}>
          <Paper className={classes.paper}>
            <Grid container direction="column" wrap="nowrap" spacing={2}>
              <Grid item>
                <Typography variant="h6">Sale Comparables Setup</Typography>
              </Grid>
              <Grid item container direction="row" spacing={2}>
                <Grid item xs={4}>
                  <DataDisplay title="Sales Approach Type" data={typeDisplay} />
                </Grid>
                <Grid item xs={6}>
                  <DataDisplay title="Basis for Comparison" data={unitOfComparisonDisplay} />
                </Grid>
              </Grid>
            </Grid>
            <Grid item sx={{ paddingTop: '16px' }}>
              <Typography variant="subtitle2">Income Adjustment Type</Typography>
              <RadioButtonList
                name="incomeAdjustmentLevel"
                items={arrayToKeyValuePairs(INCOME_ADJUSTMENT_TYPES)}
                horizontal
                sx={{ marginTop: '4px' }}
              />
            </Grid>
            {incomeLevel && (
              <Grid container direction="row" alignItems="center" spacing={2}>
                <Grid item xs={12}>
                  <Typography variant="subtitle2">Income Level Adjustment Temper Factor</Typography>
                </Grid>
                <Grid item xs={12} container sx={{ marginTop: '-24px' }} spacing={1}>
                  <Grid item xs={4}>
                    <NumberField
                      name="temper"
                      adornment="%"
                      format={toPercents}
                      parse={fromPercents}
                      decimalScale={2}
                      size="small"
                    />
                  </Grid>
                </Grid>
              </Grid>
            )}
            <Grid container direction="row" alignItems="center" spacing={2}>
              <Grid item container xs={12} spacing={1}>
                <Grid item>
                  <Typography variant="subtitle2">Market Condition Adjustment</Typography>
                </Grid>
                <Grid item>
                  <Tooltip title={MARKET_CONDITION_ADJUSTMENT_TOOLTIP} placement="top">
                    <Information />
                  </Tooltip>
                </Grid>
              </Grid>
              <Grid item xs={12} container sx={{ marginTop: '-24px' }} spacing={1}>
                <Grid item xs={4}>
                  <NumberField
                    name="marketConditionAdjustment"
                    adornment="%"
                    allowNegative={true}
                    decimalScale={2}
                    format={toPercents}
                    parse={fromPercents}
                    size="small"
                  />
                </Grid>
                <Grid item xs={4} sx={{ marginTop: '8px !important' }}>
                  <Button
                    variant="contained"
                    onClick={this.applyMarketConditionAdjustment}
                    data-qa="apply-market-condition-adjustment"
                  >
                    Apply
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </Paper>
        </Grid>
      </Grid>
    )
  }
}

export default withStyles(styles)(SalesComparableSetup)
