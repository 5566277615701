import React from 'react'
import PropTypes from 'prop-types'

import { CardMedia, IconButton, Tooltip, Typography } from '@mui/material'
import { withStyles } from '@mui/styles'
import { noop } from 'lodash'
import { Link } from 'react-router-dom'
import { ReactSVG } from 'react-svg'
import { compose, pure } from 'recompose'

import internalLinkIcon from '../../../../images/internal_link_icon.svg'
import EmptySection from '../../EmptySection'
import SectionWrapper from '../../SectionWrapper'

const styles = theme => ({
  content: {
    boxSizing: 'border-box',
    padding: `${theme.spacing(1)} ${theme.spacing(2)}`,
  },
  sourceInfo: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  tooltip: {
    fontSize: 12,
    color: theme.palette.common.white,
    backgroundColor: theme.palette.grey[600],
  },
  iconButton: {
    width: 45,
    height: 45,
    padding: '10px 10px 5px 5px',
  },
  mainTitle: {
    fontSize: theme.spacing(2),
    color: '#212121',
  },
  title: {
    letterSpacing: 0.25,
    color: '#212121',
    padding: '5px 0',
  },
  mapImageWrapper: {
    width: 450,
    height: 285,
    marginBottom: theme.spacing(2),
  },
  mapImage: {
    height: 0,
    paddingTop: 288,
    borderRadius: 4,
  },
  emptySection: {
    marginLeft: 32,
    justifyContent: 'normal',
  },
})

const MapGallerySection = ({ classes, mapsSections }) => (
  <SectionWrapper title="Map Gallery">
    {mapsSections.map((mapSection, index) => {
      const { title, link, maps } = mapSection
      const isEmptySection = maps.every(map => !map.image)

      return (
        <div key={index} className={classes.content}>
          <div className={classes.sourceInfo}>
            <Typography className={classes.mainTitle}>{title}</Typography>
            <Link to={link}>
              <Tooltip title={`Go To ${title}`} placement="left" classes={{ tooltip: classes.tooltip }}>
                <IconButton onClick={noop} className={classes.iconButton}>
                  <ReactSVG src={internalLinkIcon} />
                </IconButton>
              </Tooltip>
            </Link>
          </div>
          {isEmptySection ? (
            <EmptySection
              classes={{ emptyContent: classes.emptySection }}
              title={`This report has no ${title.toLowerCase()}`}
            />
          ) : (
            maps.map(({ title, image }) =>
              image ? (
                <React.Fragment key={title}>
                  <Typography className={classes.title}>{title}</Typography>
                  <div className={classes.mapImageWrapper}>
                    <CardMedia className={classes.mapImage} image={image} />
                  </div>
                </React.Fragment>
              ) : null
            )
          )}
        </div>
      )
    })}
  </SectionWrapper>
)

MapGallerySection.propTypes = {
  reportId: PropTypes.string.isRequired,
  mapsSections: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string.isRequired,
      link: PropTypes.string.isRequired,
      maps: PropTypes.arrayOf({
        title: PropTypes.string.isRequired,
        image: PropTypes.string.isRequired,
      }),
    })
  ).isRequired,
}

export default compose(pure, withStyles(styles))(MapGallerySection)
