import React from 'react'
import { isEmpty, get } from 'lodash'

import { sortUnitGroups } from 'shared/helpers/incomeApproach'

import { generateGroupingKeyAndName } from '../../../../report/forms/income/residential/ResidentialRentRoll/tools'
import { BUILDING_LABEL_VALUE_PAIR, UNIT_MIX_COLUMNS } from '../../constants'

import { UnitMixTable } from './UnitMixTable'
import { rebuildUnitMix } from './helpers'

export const DATA_PATH = [BUILDING_LABEL_VALUE_PAIR.UnitMix.menuItem]

class UnitMixTableContainer extends React.PureComponent {
  addUnitMixItem = (groupingType, groupingParameters) => {
    const {
      fields,
      form: { values },
    } = this.props

    const unitMix = get(values, 'unitMix', [])
    const { name, key } = generateGroupingKeyAndName(groupingType, groupingParameters)

    const unitMixItemExists = unitMix.find(unitMixItem => unitMixItem.groupingKey === key)
    if (!unitMixItemExists) {
      fields.push({
        groupingKey: key,
        groupingName: name,
        groupingParameters,
      })
    }
  }

  resetGroupingParameters = () => {
    const { form } = this.props
    form.batch(() => {
      form.change('bedrooms', null)
      form.change('bathrooms', null)
      form.change('outdoor', null)
      form.change('unitLayout', null)
    })
  }

  calculateAverages = () => {
    const {
      form: { values, change },
    } = this.props
    const units = get(values, 'units', [])
    const unitGroupingType = get(values, 'unitGroupingType')
    const unitMix = get(values, 'unitMix', [])
    const rebuiltUnitMix = rebuildUnitMix(units, unitMix, unitGroupingType)
    const updatedUnitMix = sortUnitGroups(rebuiltUnitMix)
    change('unitMix', updatedUnitMix)
  }

  onRemoveUnit = index => {
    const { form } = this.props
    const { unitMix } = form.values

    form.change(
      'unitMix',
      unitMix.filter((item, key) => key !== index)
    )
  }

  render() {
    const {
      fields,
      form: { values },
    } = this.props
    const bedrooms = get(values, 'bedrooms')
    const bathrooms = get(values, 'bathrooms')
    const outdoor = get(values, 'outdoor')
    const unitLayout = get(values, 'unitLayout')
    const units = get(values, 'units') || []
    const unitGroupingType = get(values, 'unitGroupingType', 'bedroom')
    const unitMix = get(values, 'unitMix') || []
    const disabledCalculation = units.findIndex(unit => unit.isIncluded) === -1 && isEmpty(unitMix)

    return (
      <UnitMixTable
        addUnitMixItem={this.addUnitMixItem}
        bathrooms={bathrooms}
        bedrooms={bedrooms}
        calculateAverages={this.calculateAverages}
        columns={UNIT_MIX_COLUMNS}
        disabledCalculation={disabledCalculation}
        name={fields.name}
        outdoor={outdoor}
        removeUnit={this.onRemoveUnit}
        resetGroupingParameters={this.resetGroupingParameters}
        unitGroupingType={unitGroupingType}
        unitLayout={unitLayout}
        unitMix={unitMix}
      />
    )
  }
}

export default UnitMixTableContainer
