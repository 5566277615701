import React from 'react'
import PropTypes from 'prop-types'

import { isNil } from 'lodash'
import { Checkbox, Paper, Stack, TableBody, TableCell, TableHead, TableRow, Typography } from '@mui/material'

import { DragDropContext } from 'react-beautiful-dnd'

import { DroppableTable, DraggableTableRow } from 'client-shared/components/DragDropTable'
import { formatCurrencyFloat } from 'client-shared/utils/numberFormatters'
import { insertByIndex, setIn } from 'client-shared/utils/immutability'

class ResidentialUnitGroupsTable extends React.PureComponent {
  moveCard = ({ source, destination }) => {
    const { unitGroups, onReGroup } = this.props

    if (!destination) {
      return
    }

    const unitGroupIndex = unitGroups.findIndex(group => group.key === source.droppableId)
    const unitGroup = unitGroups[unitGroupIndex]

    const [removed] = unitGroup.units.splice(source.index, 1)
    const updatedGroupUnits = insertByIndex(unitGroup.units, removed, destination.index)
    const updatedUnitGroups = setIn(unitGroups, [unitGroupIndex, 'units'], updatedGroupUnits)
    onReGroup(updatedUnitGroups)
  }

  getGroupUnitId = (unitGroupKey, unitId) => {
    return `${unitGroupKey}-group-${unitId}`
  }

  getTotalRooms(unit) {
    return isNil(unit.rooms) ? (unit.bedrooms || 0) + 2 : unit.rooms
  }

  getRent(unit) {
    return formatCurrencyFloat(unit.rent)
  }

  getRentPerRoom(unit) {
    const rentPerRoom = unit.rent / this.getTotalRooms(unit)
    return formatCurrencyFloat(rentPerRoom)
  }

  render() {
    const { showDevelopersForecast, showPerRoomAnalysis, showUnitLayout, unitGroups } = this.props

    return (
      <DragDropContext onDragEnd={this.moveCard}>
        <Stack spacing={1}>
          {unitGroups.map((unitGroup, index) => (
            <Paper key={unitGroup.key} data-qa={`rent-comps-type-${unitGroup.key}`}>
              <Typography variant="h6">{unitGroup.name}</Typography>
              <DroppableTable type={`TYPE_${unitGroup.key}`} id={unitGroup.key}>
                <TableHead>
                  <TableRow>
                    <TableCell>Move</TableCell>
                    <TableCell align="center">Unit #</TableCell>
                    {showPerRoomAnalysis && <TableCell align="center">Rooms</TableCell>}
                    <TableCell align="center">Bedrooms</TableCell>
                    {showUnitLayout && <TableCell align="center">Unit Layout</TableCell>}
                    <TableCell align="center">Rent Type</TableCell>
                    <TableCell align="center">Monthly Rent</TableCell>
                    {showPerRoomAnalysis && <TableCell align="center">Rent/Room</TableCell>}
                    {showDevelopersForecast && <TableCell align="center">Forecasted Rent</TableCell>}
                    <TableCell align="center">Vacant</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {unitGroup.units.map((unit, index) => (
                    <DraggableTableRow
                      key={this.getGroupUnitId(unitGroup.key, unit._id)}
                      id={this.getGroupUnitId(unitGroup.key, unit._id)}
                      index={index}
                    >
                      <TableCell align="center">{unit.number}</TableCell>
                      {showPerRoomAnalysis && <TableCell align="center">{this.getTotalRooms(unit)}</TableCell>}
                      <TableCell align="center">{unit.bedrooms}</TableCell>
                      {showUnitLayout && <TableCell align="center">{unit.unitLayout}</TableCell>}
                      <TableCell align="center">{unit.rentType}</TableCell>
                      <TableCell align="center">{formatCurrencyFloat(unit.rent)}</TableCell>
                      {showPerRoomAnalysis && <TableCell align="center">{this.getRentPerRoom(unit)}</TableCell>}
                      {showDevelopersForecast && (
                        <TableCell align="center">{formatCurrencyFloat(unit.rentForecast)}</TableCell>
                      )}
                      <TableCell align="center">
                        <Checkbox checked={unit.isVacant} disabled />
                      </TableCell>
                    </DraggableTableRow>
                  ))}
                </TableBody>
              </DroppableTable>
            </Paper>
          ))}
        </Stack>
      </DragDropContext>
    )
  }
}

ResidentialUnitGroupsTable.propTypes = {
  onReGroup: PropTypes.func.isRequired,
  showDevelopersForecast: PropTypes.bool.isRequired,
  showPerRoomAnalysis: PropTypes.bool.isRequired,
  showUnitLayout: PropTypes.bool.isRequired,
  unitGroups: PropTypes.arrayOf(PropTypes.object),
}

ResidentialUnitGroupsTable.defaultProps = {
  unitGroups: [],
}

export default ResidentialUnitGroupsTable
