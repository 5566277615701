import React from 'react'
import arrayMutators from 'final-form-arrays'
import PropTypes from 'prop-types'

import { Stack, Grid } from '@mui/material'

import { JOB_DETAILS_PATH } from 'shared/constants/report/keysAndDataPaths'
import { otherIntendedUse } from 'report/forms/dataCollections/jobDetails/decorators'

import wrapForm from '../../wrapForm'

import AppraisersContainer from './Appraisers'
import ReportDetails from './ReportDetails'
import Clients from './Clients'

export const DATA_PATH = JOB_DETAILS_PATH

class JobDetailsContainer extends React.PureComponent {
  static propTypes = {
    form: PropTypes.object.isRequired,
  }

  render() {
    const { form } = this.props

    return (
      <Grid container xs={10} spacing={1.5}>
        <Stack spacing={2}>
          <ReportDetails form={form} />
          <AppraisersContainer form={form} />
          <Clients form={form} />
        </Stack>
      </Grid>
    )
  }
}

export default wrapForm(DATA_PATH, {
  heading: 'Job Details Data',
  mutators: {
    ...arrayMutators,
  },
  decorators: [otherIntendedUse],
  registeredFields: ['externalInspectors'],
})(JobDetailsContainer)
