import { pick, omit } from 'lodash'

import { CompPropertyTypes } from '../../property/propertyTypes'

export const GROSS_REVENUE = 'grossRevenue'
export const EXPENSE_COMPARABLES_CATEGORIES = {
  address: 'address',
  sourceOfInformation: 'sourceOfInformation',
  city: 'city',
  state: 'state',
  expensePeriod: 'expensePeriod',
  expenseYear: 'expenseYear',
  expenseMonth: 'expenseMonth',
  squareFeet: 'squareFeet',
  residentialUnits: 'residentialUnits',
  egi: 'egi',
  propertyType: 'propertyType',
  yearBuilt: 'yearBuilt',
  realEstateTaxes: 'realEstateTaxes',
  insurance: 'insurance',
  utilities: 'utilities',
  electricity: 'electricity',
  fuel: 'fuel',
  waterAndSewer: 'waterAndSewer',
  repairsAndMaintenance: 'repairsAndMaintenance',
  payrollAndBenefits: 'payrollAndBenefits',
  generalAndAdministrative: 'generalAndAdministrative',
  legalAndProfessionalFees: 'legalAndProfessionalFees',
  miscellaneous: 'miscellaneous',
  management: 'management',
  reserves: 'reserves',
}

export const EXPENSE_COMPARABLES_LABELED_CATEGORIES = {
  [EXPENSE_COMPARABLES_CATEGORIES.address]: 'Address',
  [EXPENSE_COMPARABLES_CATEGORIES.sourceOfInformation]: 'Source Of Information',
  [EXPENSE_COMPARABLES_CATEGORIES.city]: 'City',
  [EXPENSE_COMPARABLES_CATEGORIES.state]: 'State',
  [EXPENSE_COMPARABLES_CATEGORIES.expensePeriod]: 'Expense Period',
  [EXPENSE_COMPARABLES_CATEGORIES.expenseYear]: 'Expense Year',
  [EXPENSE_COMPARABLES_CATEGORIES.expenseMonth]: 'Expense Month',
  [EXPENSE_COMPARABLES_CATEGORIES.squareFeet]: 'SF',
  [EXPENSE_COMPARABLES_CATEGORIES.residentialUnits]: 'Residential Units',
  [EXPENSE_COMPARABLES_CATEGORIES.egi]: 'EGI',
  [EXPENSE_COMPARABLES_CATEGORIES.propertyType]: 'Property Type',
  [EXPENSE_COMPARABLES_CATEGORIES.yearBuilt]: 'Year Built',
  [EXPENSE_COMPARABLES_CATEGORIES.realEstateTaxes]: 'Real Estate Taxes',
  [EXPENSE_COMPARABLES_CATEGORIES.insurance]: 'Insurance',
  [EXPENSE_COMPARABLES_CATEGORIES.utilities]: 'Utilities',
  [EXPENSE_COMPARABLES_CATEGORIES.electricity]: 'Electricity',
  [EXPENSE_COMPARABLES_CATEGORIES.fuel]: 'Fuel',
  [EXPENSE_COMPARABLES_CATEGORIES.waterAndSewer]: 'Water & Sewer',
  [EXPENSE_COMPARABLES_CATEGORIES.repairsAndMaintenance]: 'Repairs & Maintenance',
  [EXPENSE_COMPARABLES_CATEGORIES.payrollAndBenefits]: 'Payroll & Benefits',
  [EXPENSE_COMPARABLES_CATEGORIES.generalAndAdministrative]: 'General & Administrative',
  [EXPENSE_COMPARABLES_CATEGORIES.legalAndProfessionalFees]: 'Legal & Professional Fees',
  [EXPENSE_COMPARABLES_CATEGORIES.miscellaneous]: 'Miscellaneous',
  [EXPENSE_COMPARABLES_CATEGORIES.management]: 'Management Fees',
  [EXPENSE_COMPARABLES_CATEGORIES.reserves]: 'Replacement Reserves',
}

export const EXPENSE_COMPARABLE_NUMERIC_INFO = pick(EXPENSE_COMPARABLES_LABELED_CATEGORIES, [
  EXPENSE_COMPARABLES_CATEGORIES.squareFeet,
  EXPENSE_COMPARABLES_CATEGORIES.residentialUnits,
  EXPENSE_COMPARABLES_CATEGORIES.egi,
])

export const EXPENSE_COMPARABLES_INFO = pick(EXPENSE_COMPARABLES_LABELED_CATEGORIES, [
  EXPENSE_COMPARABLES_CATEGORIES.address,
  EXPENSE_COMPARABLES_CATEGORIES.sourceOfInformation,
  EXPENSE_COMPARABLES_CATEGORIES.city,
  EXPENSE_COMPARABLES_CATEGORIES.state,
  EXPENSE_COMPARABLES_CATEGORIES.expensePeriod,
  EXPENSE_COMPARABLES_CATEGORIES.expenseYear,
  EXPENSE_COMPARABLES_CATEGORIES.expenseMonth,
  EXPENSE_COMPARABLES_CATEGORIES.squareFeet,
  EXPENSE_COMPARABLES_CATEGORIES.residentialUnits,
  EXPENSE_COMPARABLES_CATEGORIES.egi,
  EXPENSE_COMPARABLES_CATEGORIES.propertyType,
  EXPENSE_COMPARABLES_CATEGORIES.yearBuilt,
])

export const EXPENSE_COMPARABLES_EXPENSES = pick(EXPENSE_COMPARABLES_LABELED_CATEGORIES, [
  EXPENSE_COMPARABLES_CATEGORIES.realEstateTaxes,
  EXPENSE_COMPARABLES_CATEGORIES.insurance,
  EXPENSE_COMPARABLES_CATEGORIES.utilities,
  EXPENSE_COMPARABLES_CATEGORIES.electricity,
  EXPENSE_COMPARABLES_CATEGORIES.fuel,
  EXPENSE_COMPARABLES_CATEGORIES.waterAndSewer,
  EXPENSE_COMPARABLES_CATEGORIES.repairsAndMaintenance,
  EXPENSE_COMPARABLES_CATEGORIES.payrollAndBenefits,
  EXPENSE_COMPARABLES_CATEGORIES.generalAndAdministrative,
  EXPENSE_COMPARABLES_CATEGORIES.legalAndProfessionalFees,
  EXPENSE_COMPARABLES_CATEGORIES.miscellaneous,
  EXPENSE_COMPARABLES_CATEGORIES.management,
  EXPENSE_COMPARABLES_CATEGORIES.reserves,
])

export const EXPENSE_RATIO = { name: 'Expense Ratio', key: 'expenseRatio' }
export const TOTAL_OPERATING_EXPENSES = {
  name: 'Total Operating Expenses',
  key: 'total',
}
export const TOTAL_OPERATING_EXPENSES_PER_SF = {
  name: 'Total Operating Expenses Per SF',
  key: 'totalPerSF',
}
export const TOTAL_OPERATING_EXPENSES_PER_UNIT = {
  name: 'Total Operating Expenses Per Unit',
  key: 'totalPerUnit',
}

export const DEFAULT_EXPENSE_COMPS_CATEGORIES = Object.entries(
  omit(EXPENSE_COMPARABLES_LABELED_CATEGORIES, [EXPENSE_COMPARABLES_CATEGORIES.utilities])
).map(([key, value]) => ({
  name: value,
  id: key,
  isDefault: true,
}))

export const NON_CALCULATABLE_CATEGORIES = omit(EXPENSE_COMPARABLES_INFO, Object.keys(EXPENSE_COMPARABLE_NUMERIC_INFO))

export const PROPERTY_TYPES = Object.values(CompPropertyTypes)

export const TOTAL_OPERATING_EXPENSES_CATEGORIES = [
  TOTAL_OPERATING_EXPENSES.key,
  TOTAL_OPERATING_EXPENSES_PER_SF.key,
  TOTAL_OPERATING_EXPENSES_PER_UNIT.key,
]

export const SOURCE_TYPE_LABELS = {
  costar: 'CoStar',
  bowerySubject: 'Bowery Subject',
  externalDatabase: 'External Database',
  marketingMaterials: 'Marketing Materials',
  newsOfSaleOrListing: 'News of Sale or Listing',
  operatingStatement: 'Operating Statement',
}
