import React from 'react'

import { Grid } from '@mui/material'

import 'moment-timezone'

import {
  getLetterOfTransmittalComplianceParagraph,
  getLetterOfTransmittalCovidLanguage,
  getLetterOfTransmittalOpinionOfValue,
  getLetterOfTransmittalValuationPrefix,
  getLetterOfTransmittalThankYou,
} from 'shared/utils/textGeneration/reportInformation/letterOfTransmittal'

import NarrativeComponent from '../../shared/components/NarrativeComponent'

import withCMSForm from './withCMSForm'

const GRID_CONTAINER_GUTTER = 2

const LetterOfTransmittal = () => {
  return (
    <Grid container spacing={GRID_CONTAINER_GUTTER}>
      <Grid item xs={12}>
        <NarrativeComponent
          title="Compliance Paragraph"
          generatedText={getLetterOfTransmittalComplianceParagraph}
          name="letterOfTransmittal.complianceParagraph"
        />
      </Grid>
      <Grid item xs={12}>
        <NarrativeComponent
          title="Valuation Prefix"
          generatedText={getLetterOfTransmittalValuationPrefix}
          name="letterOfTransmittal.valuationPrefix"
        />
      </Grid>
      <Grid item xs={12}>
        <NarrativeComponent
          title="Covid Language"
          generatedText={getLetterOfTransmittalCovidLanguage}
          name="letterOfTransmittal.covidLanguage"
        />
      </Grid>
      <Grid item xs={12}>
        <NarrativeComponent
          title="Opinion of Value"
          generatedText={getLetterOfTransmittalOpinionOfValue}
          name="letterOfTransmittal.opinionOfValue"
        />
      </Grid>
      <Grid item xs={12}>
        <NarrativeComponent
          title="Thank You"
          generatedText={getLetterOfTransmittalThankYou}
          name="letterOfTransmittal.thankYou"
        />
      </Grid>
    </Grid>
  )
}

export default withCMSForm(LetterOfTransmittal, 'Letter Of Transmittal')
