import { isNaN, reduce } from 'lodash'

import { UNIT_OF_COMPARISON_TYPES } from 'shared/constants/salesApproach'

import { divide } from '../../utils/numberOperations'
import { getTotalUnitCount } from '../helpers'

import { getSiteAreaSfAndAcres } from './helpers'
import { CompPropertyInformation, LandDeduction } from './types'

export default {
  calculateSalePricePerBasis(
    salePrice: number,
    unitOfComparison: string,
    propertyInformation: CompPropertyInformation
  ) {
    return this.calculateNumberPerBasis(salePrice, unitOfComparison, propertyInformation)
  },
  calculateSalePriceFromBasis(
    salePricePerBasis: number,
    unitOfComparison: string,
    propertyInformation: CompPropertyInformation
  ) {
    if (!salePricePerBasis || !propertyInformation) {
      return 0
    }
    const {
      grossBuildingArea = 0,
      residentialUnits = 0,
      commercialUnits = 0,
      siteArea = 0,
      siteAreaUnit = 'sf',
      buildableUnits = 0,
      buildableArea = 0,
    } = propertyInformation
    const totalUnits = getTotalUnitCount(residentialUnits, commercialUnits)
    const { siteAreaSf, siteAreaAcres } = getSiteAreaSfAndAcres(siteArea, siteAreaUnit)
    let salePrice = 0
    switch (unitOfComparison) {
      case UNIT_OF_COMPARISON_TYPES.SF:
        salePrice = salePricePerBasis * grossBuildingArea
        break
      case UNIT_OF_COMPARISON_TYPES.RESIDENTIAL_UNITS:
        salePrice = salePricePerBasis * residentialUnits
        break
      case UNIT_OF_COMPARISON_TYPES.TOTAL_UNITS:
        salePrice = salePricePerBasis * totalUnits
        break
      case UNIT_OF_COMPARISON_TYPES.APPROVED_BUILDABLE_UNITS:
        salePrice = salePricePerBasis * buildableUnits
        break
      case UNIT_OF_COMPARISON_TYPES.MAX_BUILDABLE_AREA:
        salePrice = salePricePerBasis * buildableArea
        break
      case UNIT_OF_COMPARISON_TYPES.SITE_AREA_SF:
        salePrice = salePricePerBasis * siteAreaSf
        break
      case UNIT_OF_COMPARISON_TYPES.SITE_AREA_ACRES:
        salePrice = salePricePerBasis * siteAreaAcres
        break
      case UNIT_OF_COMPARISON_TYPES.LOT:
        salePrice = salePricePerBasis
        break
      default:
        break
    }
    return isNaN(salePrice) ? 0 : salePrice
  },
  calculateNetOperatingIncomePerBasis(
    netOperatingIncome: number,
    unitOfComparison: string,
    propertyInformation: CompPropertyInformation
  ) {
    return this.calculateNumberPerBasis(netOperatingIncome, unitOfComparison, propertyInformation)
  },
  calculateNumberPerBasis(
    numberToConvert: number,
    unitOfComparison: string,
    propertyInformation: CompPropertyInformation
  ) {
    if (!numberToConvert || !propertyInformation) {
      return 0
    }
    const {
      grossBuildingArea = 0,
      residentialUnits = 0,
      commercialUnits = 0,
      siteArea = 0,
      siteAreaUnit = 'sf',
      buildableUnits = 0,
      buildableArea = 0,
    } = propertyInformation
    const totalUnits = getTotalUnitCount(residentialUnits, commercialUnits)
    const { siteAreaSf, siteAreaAcres } = getSiteAreaSfAndAcres(siteArea, siteAreaUnit)

    switch (unitOfComparison) {
      case UNIT_OF_COMPARISON_TYPES.SF:
        return divide(numberToConvert, grossBuildingArea)
      case UNIT_OF_COMPARISON_TYPES.RESIDENTIAL_UNITS:
        return divide(numberToConvert, residentialUnits)
      case UNIT_OF_COMPARISON_TYPES.TOTAL_UNITS:
        return divide(numberToConvert, totalUnits)
      case UNIT_OF_COMPARISON_TYPES.APPROVED_BUILDABLE_UNITS:
        return divide(numberToConvert, buildableUnits)
      case UNIT_OF_COMPARISON_TYPES.MAX_BUILDABLE_AREA:
        return divide(numberToConvert, buildableArea)
      case UNIT_OF_COMPARISON_TYPES.SITE_AREA_SF:
        return divide(numberToConvert, siteAreaSf)
      case UNIT_OF_COMPARISON_TYPES.SITE_AREA_ACRES:
        return divide(numberToConvert, siteAreaAcres)
      case UNIT_OF_COMPARISON_TYPES.LOT:
        return numberToConvert
      default:
        return 0
    }
  },
  calculateAsIsLandValue(landValue: number, deductions: LandDeduction[]) {
    const totalDeductions = reduce(deductions, (sum, deduction) => sum + deduction.value, 0)

    return landValue - totalDeductions
  },
}
