import React from 'react'

import NarrativeComponent from '../../../../shared/components/NarrativeComponent'
import { DebouncedFormValuesSpy } from '../../../../shared/components/helpers'
import commercialUnitsDiscussion from '../../../../../../shared/utils/textGeneration/property/commercialUnitsDiscussion'

const CommercialUnitSFDiscussion = ({ form }) => {
  return (
    <DebouncedFormValuesSpy
      form={form}
      render={updatedForm => (
        <NarrativeComponent
          name="commercialUnitsDiscussion"
          title="Commercial Unit SF Discussion"
          tooltipText="The following generated text will appear in the Description of Improvements of your report."
          generatedText={commercialUnitsDiscussion.generate}
          data={commercialUnitsDiscussion.mapDTO(updatedForm.values)}
        />
      )}
    />
  )
}

export default CommercialUnitSFDiscussion
