import { createAction } from 'redux-actions'

import { USER_UPDATE, USER_OFFICES_FETCH } from '../actionTypes/user'

export const userUpdate = createAction(USER_UPDATE.ACTION)
export const userUpdateRequest = createAction(USER_UPDATE.REQUEST)
export const userUpdateReceive = createAction(USER_UPDATE.RECEIVE)
export const userUpdateFail = createAction(USER_UPDATE.FAIL)

export const userOfficesFetch = createAction(USER_OFFICES_FETCH.ACTION)
export const userOfficesFetchRequest = createAction(USER_OFFICES_FETCH.REQUEST)
export const userOfficesFetchReceive = createAction(USER_OFFICES_FETCH.RECEIVE)
export const userOfficesFetchFail = createAction(USER_OFFICES_FETCH.FAIL)
