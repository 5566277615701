import React from 'react'
import PropTypes from 'prop-types'
import { Field } from 'react-final-form'
import { get, camelCase } from 'lodash'
import { compose, setPropTypes, defaultProps } from 'recompose'

import { Tooltip, Box } from '@mui/material'

import { IconButton, TextField, InputAdornment, FormControl, withStyles, Grid } from '@material-ui/core'
import FileUploadIcon from '@mui/icons-material/CloudUpload'
import DeleteOutline from '@mui/icons-material/DeleteOutline'
import { connect } from 'react-redux'

const styles = theme => ({
  formControl: {
    height: 84,
    paddingLeft: '5px',
  },
  button: {
    marginTop: 8,
  },
  textField: {
    width: '99%',
  },
})

const toolTipText = 'Replace File'

class MaterialFieldInput extends React.PureComponent {
  constructor(props) {
    super(props)
    this.inputFileRef = React.createRef()
  }

  get inputDescription() {
    const { name, fileName } = get(this.props.input, 'value') || {}
    return fileName || name || ''
  }

  getInputAdornment = acceptedFileTypes => (
    <>
      <input
        type="file"
        id="file"
        ref={this.inputFileRef}
        style={{ display: 'none' }}
        accept={acceptedFileTypes}
        onChange={this.handleChange}
      />

      <InputAdornment position="end">
        <IconButton size="small" onClick={this.handleClick} disabled={this.props.isDisabled}>
          <Tooltip title={toolTipText} placement="top">
            <Box>
              <FileUploadIcon />
            </Box>
          </Tooltip>
        </IconButton>
      </InputAdornment>
    </>
  )

  handleChange = event => {
    const { replaceFile, index } = this.props
    const file = event.target.files[0]
    replaceFile(file, index)
  }

  handleClick = () => {
    this.inputFileRef.current.click()
  }

  onRemoveFile = () => {
    const { onDelete, index } = this.props
    if (onDelete) {
      onDelete(index)
    }
  }

  render() {
    const {
      label,
      classes,
      uploadEndpoint,
      cancelEndpoint,
      parentId,
      acceptedFileTypes,
      helperText,
      errorText,
      children,
      onDelete,
      fileName,
      template,
      isDisabled,
      areFilesUploading,
      ...other
    } = this.props

    const error = !!errorText
    return (
      <React.Fragment>
        <FormControl className={classes.formControl} fullWidth>
          <Grid container xs={12}>
            <Grid item xs={onDelete ? 11 : 12}>
              <TextField
                value={this.inputDescription}
                label={label}
                InputLabelProps={{ shrink: true }}
                InputProps={{ endAdornment: this.getInputAdornment(acceptedFileTypes) }}
                helperText={error ? errorText : helperText}
                error={error}
                type="text"
                disabled
                variant="outlined"
                margin="dense"
                data-qa={`file-selection-${camelCase(label)}-input`}
                className={classes.textField}
                {...other}
              />
            </Grid>
            {onDelete && (
              <Grid item xs={1}>
                <IconButton
                  variant="contained"
                  color="textSecondary"
                  onClick={() => this.onRemoveFile(fileName)}
                  className={classes.button}
                  disabled={isDisabled || areFilesUploading}
                >
                  <DeleteOutline className={classes.icon} />
                </IconButton>
              </Grid>
            )}
            {children}
          </Grid>
        </FormControl>
      </React.Fragment>
    )
  }
}

const FileSelectionInputField = ({
  name,
  label,
  uploadEndpoint,
  cancelEndpoint,
  parentId,
  acceptedFileTypes,
  ...other
}) => (
  <Field
    name={name}
    label={label}
    uploadEndpoint={uploadEndpoint}
    cancelEndpoint={cancelEndpoint}
    parentId={parentId}
    component={MaterialFieldInput}
    acceptedFileTypes={acceptedFileTypes}
    fileName={name}
    {...other}
  />
)

export default compose(
  connect(state => {
    return {
      areFilesUploading: get(state, 'shared.areFilesUploading'),
    }
  }),
  withStyles(styles),
  setPropTypes({
    name: PropTypes.string.isRequired,
    parentId: PropTypes.string,
    uploadEndpoint: PropTypes.string.isRequired,
    cancelEndpoint: PropTypes.string,
    label: PropTypes.string,
    helperText: PropTypes.string,
    errorText: PropTypes.string,
    acceptedFileTypes: PropTypes.array,
    replaceFile: PropTypes.func,
    onDelete: PropTypes.func,
    template: PropTypes.string,
    isDisabled: PropTypes.bool,
  }),
  defaultProps({
    isDisabled: false,
  })
)(FileSelectionInputField)
