import * as htmlToImage from 'html-to-image'
import * as React from 'react'
import { green } from '@material-ui/core/colors'
import { withStyles } from '@material-ui/core/styles'
import carto from '@carto/carto.js'
import { Button, Card, CardActions, CardContent, CardMedia, Typography } from '@material-ui/core'
import { get, isEmpty } from 'lodash'
import DivIcon from 'react-leaflet-div-icon'
import { divIcon } from 'leaflet'

import StarRoundedIcon from '@mui/icons-material/StarRounded'

import Dropzone from '../Dropzone'
import * as Api from '../../../core/api'

import { SUPPORTED_IMAGE_FORMATS } from '../../constants/imageCategories'

const CARTO_API_KEY = global.env.cartoConfig.apiKey
const CARTO_USERNAME = global.env.cartoConfig.username

const IMAGE_WIDTH = 655
const IMAGE_HEIGHT = 380

export default function wrapMap(WrappedMap, styleOverrides) {
  class Map extends React.Component {
    state = {
      isModalOpen: false,
      isLoading: false,
      isZoomOn: true,
      displayCenterButton: true,
    }

    constructor(props) {
      super(props)
      this.dropZoneRef = React.createRef()
    }

    fillerImage = () => {
      const { classes, fillerImageContent } = this.props

      return (
        <div className={classes.media} data-qa="map-image">
          {fillerImageContent}
        </div>
      )
    }

    toggleZoomOptions = () => {
      return new Promise(resolve => {
        this.setState(
          ({ isZoomOn, displayCenterButton }) => ({ isZoomOn: !isZoomOn, displayCenterButton: !displayCenterButton }),
          resolve
        )
      })
    }

    openImageUpload = () => {
      this.dropZoneRef.current.open()
      this.props.onFocus()
    }

    clearImage = () => {
      const { handleChange } = this.props
      handleChange(null)
      this.props.onFocus()
    }

    captureMap = async () => {
      const { id } = this.props

      this.setState({ isLoading: true })

      const targetElement = document.getElementById(id)

      await this.toggleZoomOptions()
      // We don't use html2Canvas because it causes hardly reproducable and unfixable bug
      // https://bowery.atlassian.net/browse/WEB-5138
      const canvas = await htmlToImage.toCanvas(targetElement)
      await this.toggleZoomOptions()

      canvas.toBlob(this.uploadImage, 'image/png')
    }

    uploadImage = async image => {
      const { handleChange } = this.props
      this.setState({ isLoading: true })
      const response = await Api.uploadImages({ images: [image] })
      const imageUrl = get(response, 'images.0.cdnUrl')

      handleChange(imageUrl)

      this.setState({ isLoading: false, isModalOpen: false })
    }

    onDrop = acceptedImages => {
      const { handleChange } = this.props
      if (!isEmpty(acceptedImages)) {
        handleChange(acceptedImages[0].preview)
        this.uploadImage(acceptedImages[0])
      }
    }

    openMapWizard = () => {
      this.props.onFocus()
      this.setState({ isModalOpen: true })
    }

    closeMapWizard = () => {
      this.setState({ isModalOpen: false })
      this.props.onBlur()
    }

    getSubjectLabel = labelContent => {
      const { classes } = this.props
      const { addressOne, addressTwo } = labelContent
      const html = `
      <div class="${classes.markerLabelWrapper}">
        <div class="${classes.markerLabel}">
          <div class="${classes.title}">Subject</div>
          <div class="${classes.addressOne}">${addressOne}</div>
          <div class="${classes.addressTwo}">${addressTwo}</div>
        </div>
      </div>
    `
      return divIcon({
        className: '',
        html,
      })
    }

    getSubjectIcon = (coords, iconColor) => {
      const { classes } = this.props
      return (
        <DivIcon
          className={classes.subjectIconContainer}
          position={{
            lat: coords[0],
            lng: coords[1],
          }}
        >
          <StarRoundedIcon className={classes.subjectIcon} style={{ color: iconColor ?? '#000' }}></StarRoundedIcon>
        </DivIcon>
      )
    }

    render() {
      const props = {
        ...this.props,
        captureMap: this.captureMap,
        isImageSaving: this.state.isLoading,
        toggleZoomOptions: this.toggleZoomOptions,
        openMapWizard: this.openMapWizard,
        closeMapWizard: this.closeMapWizard,
        uploadImage: this.uploadImage,
        isModalOpen: this.state.isModalOpen,
        isZoomOn: this.state.isZoomOn,
        displayCenterButton: this.state.displayCenterButton,
        fillerImage: this.fillerImage,
        cartoClient: new carto.Client({
          apiKey: CARTO_API_KEY,
          username: CARTO_USERNAME,
        }),
      }

      const { classes, label, clearAvailable, renderCustomActions, imageVerifiedCallout } = props
      const value = get(this.props, 'value', '')
      const hasImage = isEmpty(value) === false

      const h5 = hasImage ? label : `Please Add ${label} Image`
      const showActionButton = hasImage && clearAvailable

      return (
        <div>
          <Card className={classes.card} data-qa="map-tile">
            {hasImage && <CardMedia className={classes.media} image={value} title={value} data-qa="map-image" />}
            {!hasImage && this.fillerImage()}
            {imageVerifiedCallout}
            {h5 && (
              <CardContent>
                <Typography variant="h5" component="h3">
                  {h5}
                </Typography>
              </CardContent>
            )}
            <CardActions>
              {renderCustomActions ? (
                renderCustomActions({ hasImage, openMapWizard: this.openMapWizard })
              ) : (
                <>
                  <Button color="primary" onClick={this.openMapWizard} data-qa="open-btn">
                    Open Wizard
                  </Button>
                  <Button color="primary" onClick={this.openImageUpload} data-qa="file-choose-btn">
                    Choose a file
                  </Button>
                  {showActionButton && (
                    <Button color="primary" onClick={this.clearImage} data-qa="clear-btn">
                      Clear
                    </Button>
                  )}
                  <Dropzone
                    dropZoneRef={this.dropZoneRef}
                    multiple={false}
                    accept={SUPPORTED_IMAGE_FORMATS}
                    style={{ display: 'none' }}
                    onDrop={this.onDrop}
                  />
                </>
              )}
            </CardActions>
            {this.props.children}
          </Card>
          <WrappedMap getSubjectLabel={this.getSubjectLabel} getSubjectIcon={this.getSubjectIcon} {...props} />
        </div>
      )
    }
  }

  const styles = theme => ({
    button: {
      margin: theme.spacing.unit,
    },
    leftIcon: {
      marginRight: theme.spacing.unit,
    },
    rightIcon: {
      marginLeft: theme.spacing.unit,
    },
    wrapper: {
      margin: theme.spacing.unit,
      position: 'relative',
    },
    buttonProgress: {
      color: green[500],
      position: 'absolute',
      top: '50%',
      left: '50%',
      marginTop: -12,
      marginLeft: -12,
    },
    card: {
      maxWidth: IMAGE_WIDTH,
      minWidth: 300,
      paddingTop: 20,
    },
    media: {
      backgroundSize: 'contain',
      height: IMAGE_HEIGHT,
      margin: 16,
      marginBottom: 16,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
    markerLabelWrapper: {
      width: 165,
      position: 'relative',
    },
    markerLabel: {
      position: 'absolute',
      width: 165,
      transform: 'translate(-50%, -50%)',
      margin: ' 7px auto auto 7px',
      padding: 12,
      borderRadius: 8,
      backgroundColor: 'white',
      fontFamily: 'sans-serif',
      fontSize: 12,
      color: theme.palette.grey[900],
    },
    addressOne: {
      fontSize: 12,
      fontWeight: 600,
    },
    title: {
      color: '#3F65AB',
      fontWeight: 600,
      fontSize: 12,
    },
    addressTwo: {
      fontSize: 12,
      fontWeight: 300,
      color: '#757575',
    },
    subjectIcon: {
      margin: '10px auto auto 10px',
      transform: 'translate(-70%, -70%)',
    },
    compIcon: {
      width: 12,
      height: 12,
      backgroundColor: theme.palette.grey[900],
      borderRadius: '50%',
      margin: '8px auto auto 8px',
      transform: 'translate(-80%, -80%)',
    },

    centerButton: {
      border: '2px solid rgba(0,0,0,0.2)',
      backgroundClip: 'padding-box',
      borderRadius: 3,
      backgroundColor: 'white',
      width: 30,
      height: 30,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      cursor: 'pointer',
      '&:hover': {
        backgroundColor: '#f4f4f4',
      },
    },
    centerButtonIcon: {
      fontSize: 18,
      zIndex: 9999,
    },
    centerIcon: {
      color: '#FFB800',
      fontSize: 32,
    },
    ...styleOverrides,
  })
  return withStyles(styles)(Map)
}
