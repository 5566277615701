import React from 'react'
import PropTypes from 'prop-types'
import { compose, pure } from 'recompose'
import { withStyles } from '@mui/styles'

import { Text } from '../../../../shared/components'

const styles = {
  formControl: {
    paddingTop: 8,
    height: 'auto',
    display: 'block',
    marginBottom: 16,
  },
}

const EditText = ({ classes, name, label, ...other }) => (
  <Text
    data-qa={`${name}-text`}
    classes={{ formControl: classes.formControl }}
    name={name}
    label={label}
    margin="none"
    {...other}
  />
)

EditText.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
}

export default compose(withStyles(styles), pure)(EditText)
