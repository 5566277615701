import React, { FC, useState, BaseSyntheticEvent } from 'react'

import MenuIcon from '@mui/icons-material/MoreHoriz'
import { IconButton, ListItemIcon, ListItemText, Menu, MenuItem } from '@mui/material'

import ModeEditOutlinedIcon from '@mui/icons-material/ModeEditOutlined'
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined'

type ColumnActionMenuProps = {
  onModifyOptionClick: () => void
  onDeleteOptionClick: () => void
}

const ColumnActionMenu: FC<ColumnActionMenuProps> = ({ onModifyOptionClick, onDeleteOptionClick }) => {
  const [anchorEl, setAnchorEl] = useState(null)

  const onMenuClick = (event: BaseSyntheticEvent) => setAnchorEl(event?.currentTarget)
  const handleClose = () => setAnchorEl(null)
  const onActionClick = (event: BaseSyntheticEvent) => {
    event.stopPropagation()
    handleClose()
  }

  return (
    <>
      <IconButton data-qa="column-action-menu-icon" aria-haspopup="true" onClick={onMenuClick}>
        <MenuIcon />
      </IconButton>
      <Menu
        id="externalLinkMenu"
        anchorEl={anchorEl}
        open={!!anchorEl}
        onClose={handleClose}
        onClick={onActionClick}
        transformOrigin={{ horizontal: 'right', vertical: 'center' }}
        sx={{ '.MuiMenu-paper': { padding: 0 } }}
      >
        <MenuItem onClick={onModifyOptionClick}>
          <ListItemIcon>
            <ModeEditOutlinedIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText data-qa="modify-column-action-menu-item">Modify Period</ListItemText>
        </MenuItem>

        <MenuItem onClick={onDeleteOptionClick}>
          <ListItemIcon>
            <DeleteOutlinedIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText data-qa="delete-column-action-menu-item">Delete Column</ListItemText>
        </MenuItem>
      </Menu>
    </>
  )
}

export default ColumnActionMenu
