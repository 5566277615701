import { GeneratedText, gtConditional, gtIfElse, gtLocked, gtTemplate } from '../../../narrative/GeneratedText'

import { mapDTO, mapDataModel } from './mappings'

const getAmenitiesGeneratedText = () => {
  const writeUp = [
    ...gtTemplate`Amenities: `,
    gtConditional('hasAmenitiesUpwardAdjustments', [
      ...gtTemplate`Comparable${gtIfElse('hasMultipleAmenitiesUpwardAdjustments', gtTemplate`s`, gtTemplate``)} `,
      gtLocked('amenitiesUpwardCompsSentence'),
      ...gtTemplate` feature${gtIfElse(
        'hasMultipleAmenitiesUpwardAdjustments',
        gtTemplate``,
        gtTemplate`s`
      )} inferior amenities, and thus required `,
      ...gtIfElse('hasMultipleAmenitiesUpwardAdjustments', gtTemplate``, gtTemplate`an `),
      ...gtTemplate`upward adjustment${gtIfElse(
        'hasMultipleAmenitiesUpwardAdjustments',
        gtTemplate`s`,
        gtTemplate``
      )}.`,
    ]),
    gtConditional('hasAmenitiesDownwardAdjustments', [
      ...gtTemplate`${gtIfElse('hasAmenitiesUpwardAdjustments', gtTemplate` `, gtTemplate``)}Comparable${gtIfElse(
        'hasMultipleAmenitiesDownwardAdjustments',
        gtTemplate`s`,
        gtTemplate``
      )} `,
      gtLocked('amenitiesDownwardCompsSentence'),
      ...gtTemplate` feature${gtIfElse(
        'hasMultipleAmenitiesDownwardAdjustments',
        gtTemplate``,
        gtTemplate`s`
      )} superior amenities, and thus required `,
      ...gtIfElse('hasMultipleAmenitiesDownwardAdjustments', gtTemplate``, gtTemplate`an `),
      ...gtTemplate`downward adjustment${gtIfElse(
        'hasMultipleAmenitiesDownwardAdjustments',
        gtTemplate`s`,
        gtTemplate``
      )}.`,
    ]),
  ]

  return writeUp
}

const getCornerGeneratedText = () => {
  const writeUp = [
    ...gtTemplate`Corner: Corners provide superior exposure. `,
    gtConditional('hasCornerDownwardAdjustments', [
      ...gtTemplate`Comparable${gtIfElse('hasMultipleCornerDownwardAdjustments', gtTemplate`s`, gtTemplate``)} `,
      gtLocked('cornerDownwardCompsSentence'),
      ...gtTemplate` required `,
      ...gtIfElse('hasMultipleCornerDownwardAdjustments', gtTemplate``, gtTemplate`a `),
      ...gtTemplate`downward adjustment${gtIfElse(
        'hasMultipleCornerDownwardAdjustments',
        gtTemplate`s`,
        gtTemplate``
      )}.`,
    ]),
    gtConditional('hasCornerUpwardAdjustments', [
      ...gtTemplate` Comparable${gtIfElse('hasMultipleCornerUpwardAdjustments', gtTemplate`s`, gtTemplate``)} `,
      gtLocked('cornerUpwardCompsSentence'),
      ...gtTemplate` required `,
      ...gtIfElse('hasMultipleCornerUpwardAdjustments', gtTemplate``, gtTemplate`an `),
      ...gtTemplate`upward adjustment${gtIfElse('hasMultipleCornerUpwardAdjustments', gtTemplate`s`, gtTemplate``)}.`,
    ]),
  ]

  return writeUp
}

const getFinishesGeneratedText = () => {
  const writeUp = [
    ...gtTemplate`Finishes: `,
    gtConditional('hasFinishesUpwardAdjustments', [
      ...gtTemplate`Comparable${gtIfElse('hasMultipleFinishesUpwardAdjustments', gtTemplate`s`, gtTemplate``)} `,
      gtLocked('finishesUpwardCompsSentence'),
      ...gtTemplate` feature${gtIfElse(
        'hasMultipleFinishesUpwardAdjustments',
        gtTemplate``,
        gtTemplate`s`
      )} inferior interior finishes, and thus required `,
      ...gtIfElse('hasMultipleFinishesUpwardAdjustments', gtTemplate``, gtTemplate`an `),
      ...gtTemplate`upward adjustment${gtIfElse('hasMultipleFinishesUpwardAdjustments', gtTemplate`s`, gtTemplate``)}.`,
    ]),
    gtConditional('hasFinishesDownwardAdjustments', [
      ...gtTemplate`${gtIfElse('hasFinishesUpwardAdjustments', gtTemplate` `, gtTemplate``)}Comparable${gtIfElse(
        'hasMultipleFinishesDownwardAdjustments',
        gtTemplate`s`,
        gtTemplate``
      )} `,
      gtLocked('finishesDownwardCompsSentence'),
      ...gtTemplate` feature${gtIfElse(
        'hasMultipleFinishesDownwardAdjustments',
        gtTemplate``,
        gtTemplate`s`
      )} superior interior finishes, and thus required `,
      ...gtIfElse('hasMultipleFinishesDownwardAdjustments', gtTemplate``, gtTemplate`an `),
      ...gtTemplate`downward adjustment${gtIfElse(
        'hasMultipleFinishesDownwardAdjustments',
        gtTemplate`s`,
        gtTemplate``
      )}.`,
    ]),
  ]

  return writeUp
}

const getElevatorGeneratedText = () => {
  const writeUp = [
    ...gtTemplate`Elevator: The subject property ${gtLocked(
      'subjectElevator'
    )}. Elevatored buildings typically command a higher price per square foot.`,
    gtConditional('hasElevatorDownwardAdjustments', [
      ...gtTemplate` Comparable${gtIfElse('hasMultipleElevatorDownwardAdjustments', gtTemplate`s`, gtTemplate``)} `,
      gtLocked('elevatorDownwardCompsSentence'),
      ...gtTemplate` required ${gtIfElse(
        'hasMultipleElevatorDownwardAdjustments',
        gtTemplate``,
        gtTemplate`a `
      )}downward adjustment${gtIfElse('hasMultipleElevatorDownwardAdjustments', gtTemplate`s`, gtTemplate``)}.`,
    ]),
    gtConditional('hasElevatorUpwardAdjustments', [
      ...gtTemplate` Comparable${gtIfElse('hasMultipleElevatorUpwardAdjustments', gtTemplate`s`, gtTemplate``)} `,
      gtLocked('elevatorUpwardCompsSentence'),
      ...gtTemplate` required ${gtIfElse(
        'hasMultipleElevatorUpwardAdjustments',
        gtTemplate``,
        gtTemplate`an `
      )}upward adjustment${gtIfElse('hasMultipleElevatorUpwardAdjustments', gtTemplate`s`, gtTemplate``)}.`,
    ]),
  ]

  return writeUp
}

const getAirRightsGeneratedText = () => {
  const writeUp = [
    ...gtTemplate`Air Rights: The subject property ${gtIfElse(
      'hasUnusedAirRights',
      gtTemplate`contains`,
      gtTemplate`does not contain`
    )} unused air rights.`,
    gtConditional('hasAirRightsUpwardAdjustments', [
      ...gtTemplate` Comparable${gtIfElse('hasMultipleAirRightsUpwardAdjustments', gtTemplate`s`, gtTemplate``)} `,
      gtLocked('airRightsUpwardCompsSentence'),
      ...gtTemplate` ${gtIfElse('hasMultipleAirRightsUpwardAdjustments', gtTemplate`do`, gtTemplate`does`)}${gtIfElse(
        'hasUnusedAirRights',
        gtTemplate` not`,
        gtTemplate``
      )} contain significantly more additional air rights, and thus required${gtIfElse(
        'hasMultipleAirRightsUpwardAdjustments',
        gtTemplate``,
        gtTemplate` an`
      )} upward adjustment${gtIfElse('hasMultipleAirRightsUpwardAdjustments', gtTemplate`s`, gtTemplate``)}.`,
    ]),
    gtConditional('hasAirRightsDownwardAdjustments', [
      ...gtTemplate` Comparable${gtIfElse('hasMultipleAirRightsDownwardAdjustments', gtTemplate`s`, gtTemplate``)} `,
      gtLocked('airRightsDownwardCompsSentence'),
      ...gtTemplate` ${gtIfElse('hasMultipleAirRightsDownwardAdjustments', gtTemplate`do`, gtTemplate`does`)}${gtIfElse(
        'hasUnusedAirRights',
        gtTemplate``,
        gtTemplate` not`
      )} contain significantly more additional air rights, and thus required${gtIfElse(
        'hasMultipleAirRightsDownwardAdjustments',
        gtTemplate``,
        gtTemplate` a`
      )} downward adjustment${gtIfElse('hasMultipleAirRightsDownwardAdjustments', gtTemplate`s`, gtTemplate``)}.`,
    ]),
  ]

  return writeUp
}

const getOtherUtilityGeneratedText = index => {
  const dataIndex = `otherUtilityAdjustmentData[${index}]`

  return [
    gtLocked(`${dataIndex}.adjustmentTitle`),
    ...gtTemplate`: `,
    gtConditional(`${dataIndex}.hasUpwardAdjustments`, [
      ...gtTemplate`Comparable${gtIfElse(`${dataIndex}.hasMultipleUpwardAdjustments`, gtTemplate`s`, gtTemplate``)} `,
      gtLocked(`${dataIndex}.upwardCompsSentence`),
      ...gtTemplate` required ${gtIfElse(`${dataIndex}.hasMultipleUpwardAdjustments`, gtTemplate``, gtTemplate`an `)}`,
      ...gtTemplate`upward adjustment${gtIfElse(
        `${dataIndex}.hasMultipleUpwardAdjustments`,
        gtTemplate`s`,
        gtTemplate``
      )}.`,
    ]),
    gtConditional(`${dataIndex}.hasDownwardAdjustments`, [
      ...gtTemplate`${gtIfElse(
        `${dataIndex}.hasAmenitiesUpwardAdjustments`,
        gtTemplate` `,
        gtTemplate``
      )}Comparable${gtIfElse(`${dataIndex}.hasMultipleDownwardAdjustments`, gtTemplate`s`, gtTemplate``)} `,
      gtLocked(`${dataIndex}.downwardCompsSentence`),
      ...gtTemplate` required ${gtIfElse(
        `${dataIndex}.hasMultipleDownwardAdjustments`,
        gtTemplate``,
        gtTemplate`an `
      )}`,
      ...gtTemplate`downward adjustment${gtIfElse(
        `${dataIndex}.hasMultipleDownwardAdjustments`,
        gtTemplate`s`,
        gtTemplate``
      )}.`,
    ]),
  ]
}

const generate = ({ otherUtilityAdjustmentData }) => {
  const propertyTypeVariable = gtLocked('propertyTypeWithArticleText')
  const cornerVariable = gtLocked('cornerText')
  const elevatored = gtLocked('elevatored')
  const kitchenCondition = gtLocked('kitchenCondition')
  const livingCondition = gtLocked('livingCondition')
  const bedroomCondition = gtLocked('bedroomCondition')
  const bathroomCondition = gtLocked('bathroomCondition')

  const improvedSalesGt = new GeneratedText()

  improvedSalesGt.t`The subject is ${propertyTypeVariable}, ${cornerVariable}, ${elevatored} building. 
    It features a ${kitchenCondition} quality kitchen, ${livingCondition} living, ${bedroomCondition} bedroom, 
    and ${bathroomCondition} bathroom area finishes.`

  const amenitiesGt = gtIfElse(
    'hasAmenities',
    gtTemplate` The subject property features the following amenities: ${gtLocked('amenitiesText')}.`,
    gtTemplate``
  )

  improvedSalesGt.t`${amenitiesGt}`

  improvedSalesGt.addNewLine()

  const utilityAdjustmentText = gtLocked('utilityAdjustmentText')
  const considerationSentencesGt = gtIfElse(
    'isCommercial',
    gtTemplate`We have considered corner vs. mid-block location, unit and property finishes, onsite parking, tenant 
      mix, signage, and floor area ratio within the scope of the utility adjustment.${utilityAdjustmentText}`,
    gtTemplate`We have considered corner vs. mid-block location, unit and property finishes, walk-up vs. elevator, 
      unit and building amenities, and commercial space within the scope of the utility 
      adjustment.${utilityAdjustmentText}`
  )
  improvedSalesGt.t`${considerationSentencesGt}`

  const cornerGeneratedText = getCornerGeneratedText()
  improvedSalesGt.addPart(gtConditional('generateCornerWriteup', [...gtTemplate`\n\n`, ...cornerGeneratedText]))

  const elevatorGeneratedText = getElevatorGeneratedText()
  improvedSalesGt.addPart(gtConditional('generateElevatorWriteup', [...gtTemplate`\n\n`, ...elevatorGeneratedText]))

  const finishesGeneratedText = getFinishesGeneratedText()
  improvedSalesGt.addPart(gtConditional('generateFinishesWriteup', [...gtTemplate`\n\n`, ...finishesGeneratedText]))

  const amenitiesGeneratedText = getAmenitiesGeneratedText()
  improvedSalesGt.addPart(gtConditional('generateAmenitiesWriteup', [...gtTemplate`\n\n`, ...amenitiesGeneratedText]))

  const airRightsGeneratedText = getAirRightsGeneratedText()
  improvedSalesGt.addPart(gtConditional('generateAirRightsWriteup', [...gtTemplate`\n\n`, ...airRightsGeneratedText]))

  otherUtilityAdjustmentData.forEach((_, index) => {
    const otherUtilityGeneratedText = getOtherUtilityGeneratedText(index)
    improvedSalesGt.addPart(
      gtConditional(`otherUtilityAdjustmentData[${index}].shouldGenerateWriteup`, [
        ...gtTemplate`\n\n`,
        ...otherUtilityGeneratedText,
      ])
    )
  })

  const utilityDiscussionGt = gtIfElse('isLandValue', gtTemplate``, improvedSalesGt.getParts())

  const gt = new GeneratedText()

  gt.t`${utilityDiscussionGt}`

  return gt.getParts()
}

export default { generate, mapDTO, mapDataModel }
