import { handleActions } from 'redux-actions'

import { showConfirmation, noConfirmation, yesConfirmation } from '../actions/confirmation'

const initialState = {
  show: false,
  title: '',
  message: '',
}

export const reducer = handleActions(
  {
    [showConfirmation](state, { payload: { title, message } }) {
      return {
        ...state,
        show: true,
        title,
        message,
      }
    },
    [yesConfirmation](state) {
      return {
        ...state,
        show: false,
        title: '',
        message: '',
      }
    },
    [noConfirmation](state) {
      return {
        ...state,
        show: false,
        title: '',
        message: '',
      }
    },
  },
  initialState
)
