import React from 'react'
import PropTypes from 'prop-types'
import { isEmpty } from 'lodash'
import { compose } from 'redux'

import BoweryDate from '@bowery-valuation/bowery-date'
import { Typography, Box, Stack } from '@mui/material'
import { withStyles } from '@mui/styles'
import { withFeatureFlag } from '@bowery-valuation/feature-flagger-client'

import { longDateFormat } from 'client-shared/utils/dateHelper'
import { USE_COMMERCIAL_SUBTYPE } from 'shared/constants/featureFlags'
import { getCommercialPropertyType } from 'shared/helpers/incomeApproach/commercial'

import { UneditableSection } from 'client-shared/components'

import { EditText, PreviewSection, AppraisersList } from '../components'

import { EXTRAORDINARY_ASSUMPTIONS_SECTION, HYPOTHETICAL_CONDITIONS_SECTION, SINCERELY_TEXT } from './constants'

import FreddieMacLanguage from './FreddieMacLanguage'

const styles = {
  clientSection: {
    width: 'fit-content',
    minWidth: 284,
  },
  generatedTextRoot: {
    marginTop: 0,
    backgroundColor: 'rgba(235, 235, 235, 0.54)',
  },
  generatedText: {
    opacity: 0.54,
  },
}

class Edit extends React.PureComponent {
  static propTypes = {
    clientFileNumbers: PropTypes.array,
    reportClients: [
      PropTypes.shape({
        abbr: PropTypes.string.isRequired,
        address: PropTypes.string.isRequired,
        city: PropTypes.string.isRequired,
        client: PropTypes.string.isRequired,
        firstName: PropTypes.string.isRequired,
        lastName: PropTypes.string.isRequired,
        title: PropTypes.string.isRequired,
        prefix: PropTypes.string.isRequired,
      }),
    ],
    assumptions: PropTypes.arrayOf(PropTypes.string),
    conditions: PropTypes.arrayOf(PropTypes.string),
    address: PropTypes.string,
    city: PropTypes.string.isRequired,
    propertyType: PropTypes.string.isRequired,
    propertySubtype: PropTypes.string,
    buildingDescriptionAsIs: PropTypes.shape({
      commentary: PropTypes.string.isRequired,
    }),
    buildingDescriptionAsComplete: PropTypes.shape({
      commentary: PropTypes.string.isRequired,
    }),
    locationDescription: PropTypes.string,
    legalPropertyIdentification: PropTypes.string,
    conclusionAsImproved: PropTypes.shape({
      commentary: PropTypes.string,
      additionalCommentary: PropTypes.string,
    }),
    clientGuidelinesDiscussionText: PropTypes.string,
    occupancyRateDiscussion: PropTypes.string,
    reportNumber: PropTypes.string.isRequired,
    organizationClientLink: PropTypes.string.isRequired,
    freddieMacLanguage: PropTypes.array.isRequired,
    isFreddieCompliant: PropTypes.bool.isRequired,
    reportState: PropTypes.string.isRequired,
    isFreddieMac: PropTypes.bool.isRequired,
  }

  static defaultProps = {
    clientFileNumbers: [],
    reportClients: [],
    buildingDescriptionAsComplete: null,
    buildingDescriptionAsIs: null,
    occupancyRateDiscussion: null,
  }

  render() {
    const {
      classes,
      city,
      reportClients,
      address,
      appraisers,
      reportNumber,
      propertyType,
      propertySubtype,
      letterOfTransmittalPurpose,
      letterOfTransmittalComplianceParagraph,
      letterOfTransmittalValuationPrefix,
      letterOfTransmittalCovidLanguage,
      letterOfTransmittalOpinionOfValue,
      letterOfTransmittalThankYou,
      locationDescription,
      conclusionAsImproved,
      clientGuidelinesDiscussionText,
      assumptions,
      conditions,
      clientLink,
      buildingDescriptionAsIs,
      legalPropertyIdentification,
      buildingDescriptionAsComplete,
      freddieMacLanguage,
      isFreddieCompliant,
      clientFileNumbers,
      occupancyRateDiscussion,
      reportState,
      isFreddieMac,
      getClient,
      hideClientName,
      salutation,
      useCommercialSubtype,
    } = this.props

    const propertyTypeToDisplay = getCommercialPropertyType(propertyType, propertySubtype, useCommercialSubtype)

    return (
      <>
        <PreviewSection disabled>
          <Typography variant="body1" gutterBottom>
            {longDateFormat(new BoweryDate())}
          </Typography>
        </PreviewSection>
        <PreviewSection classes={{ root: classes.clientSection }}>
          <UneditableSection formName="Client" name="Client Information" link={clientLink}>
            <Stack spacing={2} direction="row">
              {reportClients.map(client => {
                return <Box key={client.id}>{getClient(client, hideClientName)}</Box>
              })}
            </Stack>
          </UneditableSection>
        </PreviewSection>
        {clientFileNumbers && (
          <PreviewSection classes={{ root: classes.clientSection }}>
            <UneditableSection formName="Client" name="Client" link="client">
              <Typography variant="body1" gutterBottom>
                <span>Re: &nbsp;&nbsp;&nbsp;</span>
                {clientFileNumbers
                  .map(number => {
                    return `Project Number ${number}`
                  })
                  .join(', ')}
              </Typography>
            </UneditableSection>
          </PreviewSection>
        )}
        <PreviewSection disabled>
          <Typography variant="body1" gutterBottom>
            {isEmpty(clientFileNumbers) && <span>Re: &nbsp;&nbsp;&nbsp;</span>} Appraisal File No. {reportNumber}
          </Typography>
          <Typography variant="body1" gutterBottom>
            {propertyTypeToDisplay} Building
          </Typography>
          <Typography variant="body1" gutterBottom>
            {address}
          </Typography>
          <Typography variant="body1" gutterBottom>
            {city}
          </Typography>
        </PreviewSection>
        {hideClientName ? (
          <PreviewSection disabled>
            <Typography variant="body1" gutterBottom>
              {salutation}
            </Typography>
          </PreviewSection>
        ) : (
          <EditText name="salutation" label="Salutation" />
        )}
        <PreviewSection>
          <UneditableSection formName="Key Info" name="Purpose" link="report-information">
            <Typography variant="body1" gutterBottom>
              {letterOfTransmittalPurpose}
            </Typography>
          </UneditableSection>
        </PreviewSection>
        {buildingDescriptionAsIs && (
          <PreviewSection>
            <UneditableSection formName="Property Summary" name="As Is Building Description" link="property-summary">
              <Typography variant="body1" gutterBottom>
                {buildingDescriptionAsIs.commentary}
              </Typography>
            </UneditableSection>
          </PreviewSection>
        )}
        {buildingDescriptionAsComplete && (
          <PreviewSection>
            <UneditableSection
              formName="Property Summary"
              name="As Complete Building Description"
              link="property-summary"
            >
              <Typography variant="body1" gutterBottom>
                {buildingDescriptionAsComplete.commentary}
              </Typography>
            </UneditableSection>
          </PreviewSection>
        )}
        {occupancyRateDiscussion && (
          <PreviewSection>
            <UneditableSection
              formName="Stabilized Rent Roll"
              name="Occupancy Rate Discussion"
              link="residential-projected-rent-roll"
            >
              <Typography variant="body1" gutterBottom>
                {occupancyRateDiscussion}
              </Typography>
            </UneditableSection>
          </PreviewSection>
        )}
        {legalPropertyIdentification && (
          <PreviewSection>
            <UneditableSection formName="Zoning" name="Legal Property Identification" link="zoning">
              <Typography variant="body1" gutterBottom>
                {legalPropertyIdentification}
              </Typography>
            </UneditableSection>
          </PreviewSection>
        )}
        <PreviewSection>
          <UneditableSection formName="Property Market" name="Neighborhood" link="property-market">
            <Typography variant="body1" gutterBottom>
              {locationDescription}
            </Typography>
          </UneditableSection>
        </PreviewSection>
        <PreviewSection>
          <UneditableSection formName="Highest & Best Use" name="Conclusion As Improved" link="highest-and-best-use">
            <Typography variant="body1" gutterBottom>
              {conclusionAsImproved.commentary} {conclusionAsImproved.additionalCommentary}
            </Typography>
          </UneditableSection>
        </PreviewSection>
        <PreviewSection>
          <UneditableSection formName="Client" name="Client Guidelines Discussion" link="client">
            <Typography variant="body1" gutterBottom>
              {clientGuidelinesDiscussionText}
            </Typography>
          </UneditableSection>
        </PreviewSection>
        <PreviewSection disabled>
          <Typography variant="body1" gutterBottom>
            {letterOfTransmittalComplianceParagraph.map(txt => (
              <p>{txt}</p>
            ))}
          </Typography>
        </PreviewSection>
        <PreviewSection disabled>
          <Typography variant="body1" gutterBottom>
            {letterOfTransmittalValuationPrefix.map(txt => (
              <p>{txt}</p>
            ))}
            <p>[Final Value Conclusion Table Here]</p>
          </Typography>
        </PreviewSection>
        <PreviewSection disabled>
          <Typography variant="body1" gutterBottom>
            {letterOfTransmittalCovidLanguage.map(txt => (
              <p>{txt}</p>
            ))}
          </Typography>
        </PreviewSection>
        <PreviewSection disabled>
          <Typography variant="body1" gutterBottom>
            {EXTRAORDINARY_ASSUMPTIONS_SECTION}
          </Typography>
        </PreviewSection>
        <PreviewSection>
          <UneditableSection
            formName="Assumptions & Conditions"
            name="Extraordinary Assumption"
            link="assumptions-conditions"
          >
            <ul>
              {assumptions.map((assumption, index) => (
                <li key={index}>{assumption} </li>
              ))}
            </ul>
          </UneditableSection>
        </PreviewSection>
        <PreviewSection disabled>
          <Typography variant="body1" gutterBottom>
            {HYPOTHETICAL_CONDITIONS_SECTION}
          </Typography>
        </PreviewSection>
        <PreviewSection>
          <UneditableSection
            formName="Assumptions & Conditions"
            name="Hypothetical Conditions"
            link="assumptions-conditions"
          >
            <ul>
              {conditions.map((condition, index) => (
                <li key={index}>
                  <Typography variant="body1" gutterBottom>
                    {condition}
                  </Typography>
                </li>
              ))}
            </ul>
          </UneditableSection>
        </PreviewSection>
        <PreviewSection disabled>
          <Typography variant="body1" gutterBottom>
            {letterOfTransmittalOpinionOfValue.map(txt => (
              <p>{txt}</p>
            ))}
          </Typography>
        </PreviewSection>
        {isFreddieCompliant && (
          <PreviewSection disabled>
            <FreddieMacLanguage items={freddieMacLanguage} />
          </PreviewSection>
        )}
        <PreviewSection disabled>
          <Typography variant="body1" gutterBottom>
            {letterOfTransmittalThankYou.map(txt => (
              <p>{txt}</p>
            ))}
          </Typography>
        </PreviewSection>
        <PreviewSection disabled>
          <Typography variant="body1" gutterBottom>
            {SINCERELY_TEXT}
          </Typography>
        </PreviewSection>
        <UneditableSection formName="Appraisers" name="Appraisers" link="appraisers">
          <AppraisersList appraisers={appraisers} reportState={reportState} isFreddieMac={isFreddieMac} />
        </UneditableSection>
      </>
    )
  }
}

export default compose(withFeatureFlag(USE_COMMERCIAL_SUBTYPE, 'useCommercialSubtype'), withStyles(styles))(Edit)
