import React from 'react'
import PropTypes from 'prop-types'

import { Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material'
import { Field } from 'react-final-form'
import { Map as LeafletMap, TileLayer as BaseMap, ZoomControl } from 'react-leaflet'

import { BOROUGH_MAP_STYLE_LOOKUP } from '../../constants/borough'

import { COLOR_RED, COLOR_WHITE, mapStyles, taxLayerLabel } from './CartoStyles'
import { MAP_SIZE } from './constants'
import Layer from './MapLayer'
import wrapMap from './wrapMap'

const BOROUGH_MAP_NAMES = Object.values(BOROUGH_MAP_STYLE_LOOKUP)
const propTypesMaterial = {
  isZoomOn: PropTypes.bool.isRequired,
  isImageSaving: PropTypes.bool.isRequired,
  isModalOpen: PropTypes.bool.isRequired,

  captureMap: PropTypes.func.isRequired,
  closeMapWizard: PropTypes.func.isRequired,
  cartoClient: PropTypes.object.isRequired,

  classes: PropTypes.object.isRequired,
  label: PropTypes.string,
  id: PropTypes.string.isRequired,
}

const propTypesMapOptions = {
  mapOptions: PropTypes.shape({
    maxZoom: PropTypes.number.isRequired,
    initialZoom: PropTypes.number.isRequired,
    initialCoordinates: PropTypes.shape({
      lat: PropTypes.number.isRequired,
      lng: PropTypes.number.isRequired,
    }),
    zoomDelta: PropTypes.number.isRequired,
    zoomSnap: PropTypes.number.isRequired,
    wheelPxPerZoomLevel: PropTypes.number.isRequired,
    baseMap: PropTypes.string.isRequired,
    mapLayers: PropTypes.arrayOf(
      PropTypes.shape({
        name: PropTypes.string.isRequired,
        style: PropTypes.string.isRequired,
        isCustom: PropTypes.bool.isRequired,
      })
    ).isRequired,
    customLayerName: PropTypes.oneOf(BOROUGH_MAP_NAMES).isRequired,
    block: PropTypes.number.isRequired,
    lot: PropTypes.string.isRequired,
  }).isRequired,
}

const applyLayerFilters = (mapLayers, customLayerName, block, lot) => {
  const blockLotLayer = `
    #${customLayerName} {polygon-fill: ${COLOR_WHITE}; polygon-opacity: 0.7;}
    #${customLayerName}[block=${block}][lot=${lot}] { polygon-fill: ${COLOR_RED}; }
    #${customLayerName}::labels {
       ${taxLayerLabel}
    }`

  return mapLayers.map(layer => {
    if (layer.name === customLayerName) {
      layer = { name: customLayerName, isCustom: true, style: blockLotLayer }
    }
    return layer
  })
}

class MaterialMapWizard extends React.PureComponent {
  static propTypes = {
    ...propTypesMaterial,
    ...propTypesMapOptions,
  }

  static defaultProps = {
    zoomDelta: 0.3,
    zoomSnap: 0.1,
    wheelPxPerZoomLevel: 20,
  }

  render() {
    const {
      isZoomOn,
      isImageSaving,
      isModalOpen,
      captureMap,
      closeMapWizard,
      cartoClient,
      zoomDelta,
      zoomSnap,
      wheelPxPerZoomLevel,
      classes,
      label,
      id,
      mapOptions: { initialCoordinates, baseMap, initialZoom, maxZoom, mapLayers, customLayerName, block, lot },
    } = this.props

    return (
      <div>
        <Dialog maxWidth="md" open={isModalOpen} onClose={closeMapWizard} data-qa="tax-map">
          <DialogTitle>{label}</DialogTitle>
          <DialogContent>
            <LeafletMap
              id={id}
              className={id}
              style={MAP_SIZE}
              center={initialCoordinates}
              zoom={initialZoom}
              maxZoom={maxZoom}
              zoomControl={false}
              zoomDelta={zoomDelta}
              zoomSnap={zoomSnap}
              wheelPxPerZoomLevel={wheelPxPerZoomLevel}
              data-qa="tax-map"
            >
              <BaseMap attribution="" url={baseMap} />
              {isZoomOn && <ZoomControl />}

              <Layer
                source={applyLayerFilters(mapLayers, customLayerName, block, lot)}
                style={mapStyles}
                client={cartoClient}
              />
            </LeafletMap>
          </DialogContent>
          <DialogActions>
            <Button onClick={closeMapWizard} data-qa="tax-map-close-btn">
              Close
            </Button>
            <div className={classes.wrapper}>
              <Button disabled={isImageSaving} onClick={captureMap} data-qa="tax-map-capture-screen-btn">
                Capture Screen
              </Button>
              {isImageSaving && <CircularProgress size={24} className={classes.buttonProgress} />}
            </div>
          </DialogActions>
        </Dialog>
      </div>
    )
  }
}

MaterialMapWizard = wrapMap(MaterialMapWizard)

export function TaxMapWizard({ name, ...otherProps }) {
  return (
    <Field
      name={name}
      render={({ input }) => (
        <MaterialMapWizard
          {...otherProps}
          id={name}
          value={input.value}
          handleChange={input.onChange}
          onFocus={input.onFocus}
          onBlur={input.onBlur}
        />
      )}
    />
  )
}

TaxMapWizard.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string,
  ...propTypesMapOptions,
}
