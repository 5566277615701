import { combineReducers } from 'redux'
import { routerReducer } from 'react-router-redux'

import authReducer from '../authentication/redux/reducer'

import { default as reportReducer } from '../report/redux/reducers'

import { reducer as organization } from '../admin/redux/reducers/organization'

import { reducer as clients } from '../admin/redux/reducers/clients'

import { reducer as insurableValues } from '../admin/redux/reducers/insurableValues'

import { default as buildingCompReducer } from '../etc/buildingComparables/redux/reducers'

import { reducer as reports } from '../reportsDashboard/redux/reducer'

import sharedReducer from '../shared/redux/reducers'

import { reducer as analyticsReducer } from '../analytics/redux/reducer'

import { reducer as user } from '../admin/redux/reducers/user'

import { reducer as cms } from '../cms/redux/reducers/cms'

const reducer = combineReducers({
  authentication: authReducer,
  shared: sharedReducer,
  router: routerReducer,
  analytics: analyticsReducer,
  reports,
  organization,
  clients,
  user,
  insurableValues,
  ...reportReducer,
  ...buildingCompReducer,
  cms,
})

export default reducer
