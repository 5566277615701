import { get } from 'lodash'
import { push } from 'react-router-redux'

import { put, takeLatest, select } from 'redux-saga/effects'

import { successNotification, errorNotification } from '../../../shared/redux/actions/notifications'

import { userUpdateReceive } from '../actions/user'

import {
  ORGANIZATION_FETCH,
  ORGANIZATION_UPDATE,
  ORGANIZATION_USERS_FETCH,
  ORGANIZATION_USERS_CREATE,
  ORGANIZATION_USERS_UPDATE,
  ORGANIZATION_USERS_DELETE,
} from '../actionTypes/organization'
import {
  organizationFetchRequest,
  organizationFetchReceive,
  organizationFetchFail,
  organizationUpdateRequest,
  organizationUpdateReceive,
  organizationUpdateFail,
  organizationUsersFetchRequest,
  organizationUsersFetchReceive,
  organizationUsersFetchFail,
  organizationUsersCreateRequest,
  organizationUsersCreateReceive,
  organizationUsersCreateFail,
  organizationUsersUpdateRequest,
  organizationUsersUpdateReceive,
  organizationUsersUpdateFail,
  organizationUsersDeleteRequest,
  organizationUsersDeleteReceive,
  organizationUsersDeleteFail,
} from '../actions/organization'
import * as Api from '../../../core/api'

const CREATING_ERROR = 'Can not create user!'
const SAVING_ERROR = 'There was an error while trying to save this user: '
const DELETING_ERROR = 'There was an error while trying to delete this user, please contact us quoting User Id'

function* fetchOrganization(action) {
  const organizationId = get(action, 'payload')

  if (organizationId) {
    try {
      yield put(organizationFetchRequest(organizationId))
      const organizationData = yield Api.fetchOrganization(organizationId)
      const organizationStructure = yield Api.fetchOrganizationForms(organizationId)

      yield put(
        organizationFetchReceive({
          organizationData,
          organizationStructure,
        })
      )
    } catch (err) {
      yield put(organizationFetchFail(err))
    }
  } else {
    try {
      yield put(organizationFetchRequest())
      const organizationData = yield Api.fetchOrganization()
      const organizationStructure = yield Api.fetchOrganizationForms()

      yield put(push(`/organization/${organizationData._id}`))

      yield put(
        organizationFetchReceive({
          organizationData,
          organizationStructure,
        })
      )
    } catch (err) {
      yield put(organizationFetchFail(err))
    }
  }
}

function* updateOrganization({ payload }) {
  const organizationId = yield select(state => state.organization.organizationData._id)
  const { formDataPath, values } = payload
  try {
    yield put(organizationUpdateRequest(payload))

    const response = yield Api.updateOrganization(organizationId, formDataPath, values)

    yield put(organizationUpdateReceive(response))
    yield put(successNotification({ message: 'Organization info was updated' }))
  } catch (err) {
    yield put(organizationUpdateFail(err))
    yield put(
      errorNotification({
        message:
          `There was an error while trying to save organization info, please contact us quoting ` +
          `Organization Id '${organizationId}' and form '${formDataPath}' for assistance. ` +
          `You can continue working on other forms while this issue is resolved.`,
      })
    )
  }
}

function* organizationUsersFetch() {
  try {
    const usersLoaded = yield select(state => state.organization.usersLoaded)
    if (!usersLoaded) {
      yield put(organizationUsersFetchRequest())
      const users = yield Api.fetchUsers()
      yield put(organizationUsersFetchReceive(users))
    }
  } catch (err) {
    yield put(organizationUsersFetchFail(err))
  }
}

function* organizationUsersCreate(action) {
  try {
    const { user } = action.payload
    yield put(organizationUsersCreateRequest(user))
    const creatingInfo = yield Api.usersCreate(user)
    const { user: newUser, err } = creatingInfo || {}
    yield put(organizationUsersCreateReceive())
    if (!newUser._id || err) {
      yield put(
        errorNotification({
          message: get(err, 'msg.message') || CREATING_ERROR,
        })
      )
    } else {
      yield put(successNotification({ message: 'User was created' }))
    }
  } catch (err) {
    yield put(organizationUsersCreateFail(err))
    yield put(errorNotification({ message: CREATING_ERROR }))
  }
}

function* organizationUsersUpdate({ payload }) {
  const { formDataPath, values } = payload
  const { _id: userId } = values

  try {
    yield put(organizationUsersUpdateRequest(payload))
    const response = yield Api.updateUser(userId, formDataPath, values)

    yield put(organizationUsersUpdateReceive(response))
    yield put(userUpdateReceive(response))
    yield put(successNotification({ message: 'User was updated' }))
  } catch (err) {
    yield put(organizationUsersUpdateFail(err))
    yield put(
      errorNotification({
        message: `${SAVING_ERROR} '${err}'`,
      })
    )
  }
}

function* organizationUsersDelete(action) {
  const userId = action.payload
  try {
    yield put(organizationUsersDeleteRequest(action))
    const status = yield Api.usersDelete(userId)
    const allUsers = yield select(state => state.organization.organizationUsers)
    const users = allUsers.filter(item => item._id !== userId)
    yield put(organizationUsersDeleteReceive({ status, users }))
    yield put(successNotification({ message: 'User was removed' }))
  } catch (err) {
    yield put(organizationUsersDeleteFail(err))
    yield put(
      errorNotification({
        message: `${DELETING_ERROR} '${userId}'`,
      })
    )
  }
}

export default [
  takeLatest(ORGANIZATION_FETCH.ACTION, fetchOrganization),
  takeLatest(ORGANIZATION_UPDATE.ACTION, updateOrganization),
  takeLatest(ORGANIZATION_USERS_FETCH.ACTION, organizationUsersFetch),
  takeLatest(ORGANIZATION_USERS_CREATE.ACTION, organizationUsersCreate),
  takeLatest(ORGANIZATION_USERS_UPDATE.ACTION, organizationUsersUpdate),
  takeLatest(ORGANIZATION_USERS_DELETE.ACTION, organizationUsersDelete),
]
