import React, { useState, useEffect, FC } from 'react'
import { Stack, Box, Button, Typography, Card } from '@mui/material'

import { DropDown, Text } from 'client-shared/components'
import { ExtendedFormApi } from 'client-shared/utils/form'

import { get, isString } from 'lodash'

import { connect } from 'react-redux'

import { EXPORT_SECTIONS, CREATE_NEW, SUBSECTION_NAME, SUBSECTION_TITLE, SUBSECTION_LOCATION } from '../constants'
import { FormValues, Subsection } from '../types'
import { Classes } from '../styles'

import AtomicUnits from './AtomicUnit/AtomicUnits'

const exportSectionsOptions = (Object.keys(EXPORT_SECTIONS) as (keyof typeof EXPORT_SECTIONS)[]).map(key => {
  return {
    label: EXPORT_SECTIONS[key],
    value: key,
  }
})

type CreateGenericSubsectionProps = {
  classes: Classes
  onSave: () => void
  onCancel: () => void
  subsections: Subsection[]
  form: ExtendedFormApi<FormValues>
  username: string
}

const CreateGenericSubsection: FC<CreateGenericSubsectionProps> = ({
  classes,
  onSave,
  onCancel,
  subsections,
  form,
  username,
}) => {
  const [validName, setValidName] = useState(false)
  const [valid, setValid] = useState(false)

  useEffect(() => {
    if (validName) {
      setValid(true)
    } else {
      setValid(false)
    }
  }, [validName])

  const VALID_NAME = /^[a-z0-9 ._+-]+$/i

  const validateName = (value: unknown) => {
    setValidName(false)
    if (isString(value) && value.length) {
      if (value.length >= 35) {
        setValidName(false)
        return "Field 'name' must be 35 characters or less."
      }

      if (!VALID_NAME.test(value)) {
        setValidName(false)
        return "Field 'name' is limited to alphanumeric characters, spaces and the following special characters: _ . + -"
      }

      if (!subsections.some(subsection => subsection.name === `${username}-${value}`)) {
        setValidName(true)
        return
      } else {
        return "Field 'name' must be unique."
      }
    } else {
      setValidName(false)
      return "Field 'name' is required."
    }
  }

  return (
    <Card elevation={3}>
      <Stack className={classes.createFields} direction="column">
        <Typography variant="h6">Create New</Typography>
        <Typography variant="body1">{CREATE_NEW}</Typography>
        <Box className={classes.createField}>
          <Typography variant="subtitle1" sx={{ marginTop: '8px' }}>
            * Content Name
          </Typography>
          <Typography>{SUBSECTION_NAME}</Typography>
          <Text placeholder="Enter Content Name" name="newGenericSubsection.name" validate={validateName} />
        </Box>
        <Box className={classes.createField}>
          <Typography variant="subtitle1" sx={{ marginTop: '8px' }}>
            Export Header Title
          </Typography>
          <Typography>{SUBSECTION_TITLE}</Typography>
          <Text placeholder="Enter Export Header Title (optional)" name="newGenericSubsection.title" />
        </Box>
        <Box className={classes.createModal}>
          <AtomicUnits form={form} />
        </Box>
        <Box className={classes.createField}>
          <Typography variant="subtitle1">Report Section</Typography>
          <DropDown
            fullWidth
            placeholder="Select Report Section (optional)"
            name="newGenericSubsection.location"
            items={exportSectionsOptions}
            helperText={SUBSECTION_LOCATION}
          />
        </Box>
      </Stack>

      <Box className={classes.create_buttons}>
        <Button data-qa="form-cancel-btn" onClick={onCancel} variant="text">
          Cancel
        </Button>
        <Button data-qa="form-save-btn" onClick={onSave} variant="contained" disabled={!valid}>
          Save
        </Button>
      </Box>
    </Card>
  )
}

export default connect(state => ({
  username: get(state, 'authentication.user.username', ''),
}))(CreateGenericSubsection)
