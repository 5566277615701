import moment from 'moment'

export const getHasSoldWithinThreeYears = orderedSalesHistory => {
  if (!orderedSalesHistory || !orderedSalesHistory[0]) {
    return false
  }
  const threeYearsAgo = new Date().getFullYear() - 3
  const dateThreeYearsAgo = new Date()
  dateThreeYearsAgo.setFullYear(threeYearsAgo)
  if (new Date().getMonth() === 1 && new Date().getDate() === 29 && !moment(threeYearsAgo).isLeapYear()) {
    dateThreeYearsAgo.setMonth(1, 28)
  }
  const hasSoldWithinThreeYears = orderedSalesHistory[0].date >= moment(dateThreeYearsAgo)
  return hasSoldWithinThreeYears
}
