import React from 'react'
import { FormSpy } from 'react-final-form'

import { getExtendedFormApi } from 'client-shared/utils/form'

const withExtendedFormApi = Component => props => {
  return (
    <FormSpy subscription={{ values: true }}>
      {({ form, values }) => {
        const extendedFormApi = getExtendedFormApi({ values, form })

        return <Component form={extendedFormApi} {...props} />
      }}
    </FormSpy>
  )
}

export default withExtendedFormApi
