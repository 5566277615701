import { mapValues } from 'lodash'

import { ASSESSED_VALUE_COLUMN_NAMES } from './constants'

export const addAssessedValueColumn = (assessedValueColumns, newColumnName) => {
  return mapValues(assessedValueColumns, column => {
    if (column.name === newColumnName) {
      return {
        ...column,
        isVisible: true,
      }
    }
    return column
  })
}

export const updateAssessedValueColumn = (assessedValueColumns, columnName, update) => {
  return mapValues(assessedValueColumns, column => {
    if (column.name === columnName) {
      return {
        ...column,
        ...update,
      }
    }
    return column
  })
}

export const removeAssessedValueColumn = (assessedValueColumns, columnName) => {
  return mapValues(assessedValueColumns, column => {
    if (column.name === columnName) {
      return {
        ...column,
        isVisible: false,
      }
    }
    return column
  })
}

export const getNewAssessedValueColumns = (assessedValueColumns, hasTransitional) => {
  const TRANSITIONAL_COLUMN_NAMES = [
    ASSESSED_VALUE_COLUMN_NAMES.transitional,
    ASSESSED_VALUE_COLUMN_NAMES.transitionalPsf,
    ASSESSED_VALUE_COLUMN_NAMES.transitionalPerUnit,
  ]

  if (!hasTransitional) {
    return mapValues(assessedValueColumns, column => {
      if (TRANSITIONAL_COLUMN_NAMES.includes(column.name)) {
        return { ...column, isVisible: false }
      }
      return column
    })
  } else {
    return addAssessedValueColumn(assessedValueColumns, ASSESSED_VALUE_COLUMN_NAMES.transitional)
  }
}
