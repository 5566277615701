import React from 'react'
import PropTypes from 'prop-types'

import { Box, Stack, Typography } from '@mui/material'
import Remove from '@mui/icons-material/Remove'
import Add from '@mui/icons-material/Add'
import { setPropTypes, compose, onlyUpdateForKeys } from 'recompose'

import { Number, IconButton, FileUploadButton } from '../../../../../shared/components'

const MAX_RESIDENTIAL_UNIT_COUNT = 100

const RentRollToolbar = ({
  csvFile,
  onAddUnit,
  onBlurResidentialCount,
  onFocusResidentialUnitsCount,
  onImportCsv,
  onRemoveUnit,
  onResidentialUnitCountChange,
  onResidentialUnitCountKeyPress,
  residentialUnitsCount,
  unitCount,
}) => {
  return (
    <Stack alignItems="center" direction="row" sx={{ my: 2 }}>
      <Stack alignItems="center" direction="row" spacing={2} flexShrink={0}>
        <Typography sx={{ whiteSpace: 'nowrap' }} variant="subtitle1">
          # Residential Units
        </Typography>
        <Number
          inputProps={{ value: residentialUnitsCount }}
          max={MAX_RESIDENTIAL_UNIT_COUNT}
          name="residentialUnitCount"
          onBlur={onBlurResidentialCount}
          onChange={onResidentialUnitCountChange}
          onFocus={onFocusResidentialUnitsCount}
          onKeyPress={onResidentialUnitCountKeyPress}
        />
        <IconButton disabled={unitCount >= MAX_RESIDENTIAL_UNIT_COUNT} onClick={onAddUnit}>
          <Add />
        </IconButton>
        <IconButton onClick={onRemoveUnit} disabled={!unitCount}>
          <Remove />
        </IconButton>
      </Stack>
      <Box sx={{ ml: 'auto' }}>
        <FileUploadButton title="IMPORT CSV" files={csvFile ? [csvFile] : []} onChange={onImportCsv} accept=".csv" />
      </Box>
    </Stack>
  )
}

export default compose(
  onlyUpdateForKeys(['csvFile', 'residentialUnitsCount', 'unitCount']),
  setPropTypes({
    csvFile: PropTypes.string,
    onAddUnit: PropTypes.func.isRequired,
    onBlurResidentialCount: PropTypes.func.isRequired,
    onFocusResidentialUnitsCount: PropTypes.func.isRequired,
    onImportCsv: PropTypes.func.isRequired,
    onRemoveUnit: PropTypes.func.isRequired,
    onResidentialUnitCountChange: PropTypes.func.isRequired,
    onResidentialUnitCountKeyPress: PropTypes.func.isRequired,
    residentialUnitsCount: PropTypes.number.isRequired,
    unitCount: PropTypes.number.isRequired,
  })
)(RentRollToolbar)
