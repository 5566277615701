import { find, findIndex, get } from 'lodash'

import ACASCalculations from 'shared/report-calculations/income-approach/acas/acas-calculations'

import { mapValuesToDisplay } from 'shared/report-calculations/income-approach/acas/helpers'

import { SalesApproachCalculations } from '../../../libs/bowery-libs/services/report-calculations/sales-approach'

export const getSalesApproach = (salesApproaches, type) => {
  if (salesApproaches.length === 1) {
    return salesApproaches[0]
  }
  return find(salesApproaches, salesApproach => salesApproach.type === type)
}

export const getSalesApproachIndex = (salesApproaches, type) => {
  return findIndex(salesApproaches, salesApproach => salesApproach.type === type)
}

export const getSalesApproachFormPath = sectionFormPath => {
  if (!sectionFormPath) return null
  let formPath = sectionFormPath
  if (typeof sectionFormPath === 'string') {
    formPath = sectionFormPath.split('.')
  }
  return formPath.slice(0, -1).join('.')
}

export const getFinalDatesAndValues = (salesApproach, report) => {
  const valueConclusionInformation = get(salesApproach, 'valueConclusionInformation', {})
  const { asIsValueInformation, asCompleteValueInformation, asStabilizedValueInformation } = valueConclusionInformation

  const capRateConclusion = get(report, 'incomeApproachSection.capRateConclusion') || {}
  const asStabilizedDiscountPercentage = get(capRateConclusion, 'asStabilizedDiscountPercentage', 0)
  const asCompleteDiscountPercentage = get(capRateConclusion, 'asCompleteDiscountPercentage', 0)

  const asStabilizedMonthsDiscounted = get(capRateConclusion, 'asStabilizedMonthsDiscounted', 0)
  const asCompleteMonthsDiscounted = get(capRateConclusion, 'asCompleteMonthsDiscounted', 0)

  const { asCompleteDiscountRate, asStabilizedDiscountRate } = ACASCalculations.calculateDiscountedRates({
    asStabilizedDiscountPercentage,
    asCompleteDiscountPercentage,
    asStabilizedMonthsDiscounted,
    asCompleteMonthsDiscounted,
  })

  const indicatedValue = SalesApproachCalculations.getSalesApproachIndicatedValue(report)
  const dateOfValuation = get(report, 'inspection.dateOfValuation')

  const roundingFactor = get(report, 'cap_rate.rounding_factor')

  const squareFootage = get(report, 'property_information.squareFootageForCalculations', 0)
  const residentialUnitCount = get(report, 'property_information.resUnits', 0)

  const valueAsComplete = get(report, 'valueAsComplete')
  const valueAsStabilized = get(report, 'valueAsStabilized')

  const finalDates = ACASCalculations.calculateDatesOfFinalValue({
    dateOfValuation,
    asCompleteMonthsOfRentLoss: get(asCompleteValueInformation, 'monthsOfRentLoss', 0),
    asStabilizedMonthsOfRentLoss: get(asStabilizedValueInformation, 'monthsOfRentLoss', 0),
  })

  const finalValues = ACASCalculations.calculateFinalValues({
    indicatedValue,
    npvAdjustments: get(asIsValueInformation, 'npvAdjustments'),
    asStabilizedNpvAdjustments: get(asStabilizedValueInformation, 'npvAdjustments'),
    asCompleteNpvAdjustments: get(asCompleteValueInformation, 'npvAdjustments'),
    asStabilizedResRentLossItems: get(asStabilizedValueInformation, 'residentialRentLossItems'),
    asCompleteResRentLossItems: get(asCompleteValueInformation, 'residentialRentLossItems'),
    asStabilizedCommercialRentLossItems: get(asStabilizedValueInformation, 'commercialRentLossItems'),
    asCompleteCommercialRentLossItems: get(asCompleteValueInformation, 'commercialRentLossItems'),
    asStabilizedLossItems: get(asStabilizedValueInformation, 'lossItems'),
    asCompleteLossItems: get(asCompleteValueInformation, 'lossItems'),
    roundingFactor,
    squareFootage,
    unitCount: residentialUnitCount,
    includeValueAsComplete: valueAsComplete,
    includeValueAsStabilized: valueAsStabilized,
    asCompleteDiscountRate,
    asStabilizedDiscountRate,
  })

  const finalValuesDisplay = mapValuesToDisplay(finalValues)

  return {
    ...finalDates,
    ...finalValuesDisplay,
  }
}
