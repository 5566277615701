import React from 'react'
import PropTypes from 'prop-types'
import { get, min, max, mean, isNumber, sumBy } from 'lodash'

import {
  Grid,
  Paper,
  Table,
  TableBody,
  TableRow,
  TableCell,
  Card,
  TableHead,
  withStyles,
  Typography,
} from '@material-ui/core'

import { getTaxLiabilityLabel } from 'shared/report-calculations/income-approach/tax/helpers'

import TaxCalculations from 'shared/report-calculations/income-approach/tax/tax-calculations'
import { CONCLUDED_LIABILITY_TYPES } from 'shared/constants/incomeApproach'

import {
  formatCurrencyFloat,
  formatCurrencyInt,
  formatPercentageString,
} from '../../../../shared/utils/numberFormatters'
import { toPercents } from '../../../../shared/utils/numberOperations'
import { positive } from '../../../../shared/utils/validation'

import { Number, RadioButtonList, GeneratedComment } from '../../../../shared/components'

import { arrayToKeyValuePairs } from '../../../../shared/utils/ui/checkboxHelper'

import { BASIS_TYPES, LABELS } from '../../../../../../shared/constants/report/incomeApproach/taxes'

import { generateTaxSummaryDiscussion } from '../../../../../../shared/utils/textGeneration/incomeApproach/taxInfo'

import styles from './styles'

const TAX_SUMMARY_TOOLTIP =
  'The following generated commentary will appear in the Assessed Value & Real Estate Taxes section of your report.'

class SummaryTable extends React.PureComponent {
  static propTypes = {
    taxRate: PropTypes.object,
    effectiveGrossIncome: PropTypes.number,
  }

  static defaultProps = {
    taxRate: { value: 0 },
  }

  getProjectedLiabilityTableData(projectedLiabilities) {
    const tableRowsValues = []

    if (projectedLiabilities.includedInExport) {
      const { opinion } = projectedLiabilities
      tableRowsValues.push({
        label: 'Opinion Provided',
        tav: opinion.taxableAssessedValue || 0,
        ...(opinion.taxableAssessedValue != null && {
          additionalSpecialAssessments: opinion.additionalSpecialAssessments,
        }),
      })
      tableRowsValues.push({
        label: 'Percent of Renovations',
        tav: projectedLiabilities.percentOfRenovations.taxableAssessedValue,
      })
      tableRowsValues.push({
        label: `Percent of Income (${projectedLiabilities.percentOfIncome.incomeType})`,
        tav: projectedLiabilities.percentOfIncome.taxableAssessedValue,
      })
      tableRowsValues.push({
        label: 'Equalized Market Value',
        tav: projectedLiabilities.equalization.taxableAssessedValue,
      })
    }

    return tableRowsValues
  }

  getCompSummaryTableData(taxComps) {
    const tableRowsValues = []

    if (taxComps.length > 0) {
      // N.B. it's a workaround for an unknown issue in final form where an undefined value is added to the array
      const filteredTaxComps = taxComps.filter(comp => comp !== undefined)

      const compTaxLiabilityValues = filteredTaxComps.map(comp => {
        return comp.taxesPerBasis * comp.basis
      })
      const compTaxTaxesPerBasis = filteredTaxComps.map(comp => comp.taxesPerBasis)

      tableRowsValues.push({
        label: 'Comp Min.',
        taxLiability: min(compTaxLiabilityValues) || 0,
        taxLiabilityPerBasis: min(compTaxTaxesPerBasis) || 0,
      })
      tableRowsValues.push({
        label: 'Comp Max.',
        taxLiability: max(compTaxLiabilityValues) || 0,
        taxLiabilityPerBasis: max(compTaxTaxesPerBasis) || 0,
      })
      tableRowsValues.push({
        label: 'Comp Avg.',
        taxLiability: mean(compTaxLiabilityValues) || 0,
        taxLiabilityPerBasis: mean(compTaxTaxesPerBasis) || 0,
      })
    }

    return tableRowsValues
  }

  getConcludedTaxLiabilityHelperText() {
    const { form, effectiveGrossIncome } = this.props
    const { squareFootage } = form.values

    if (!effectiveGrossIncome) {
      return null
    }

    const concludedLiabilityType = get(form, 'values.projected.concludedLiabilityType', CONCLUDED_LIABILITY_TYPES.PSF)
    const concludedLiabilityPerBasis = get(form, 'values.projected.concludedLiabilityPerBasis')
    const taxLiability = get(form, 'values.projected.taxLiability')

    let value = null

    if (concludedLiabilityType === CONCLUDED_LIABILITY_TYPES.PSF) {
      value = (concludedLiabilityPerBasis * squareFootage) / effectiveGrossIncome
    }

    if (concludedLiabilityType === CONCLUDED_LIABILITY_TYPES.TOTAL) {
      value = taxLiability / effectiveGrossIncome
    }

    return isNumber(value) ? `${toPercents(value)}% of Effective Gross Income` : null
  }

  renderTableRow(
    label,
    taxableAssessedValue,
    squareFootage,
    taxRate,
    additionalSpecialAssessments = [],
    rowIndex = null
  ) {
    const { classes, form } = this.props
    const { basis, residentialUnitCount } = form.values
    const totalAdditionalSpecialAssessments = sumBy(additionalSpecialAssessments, 'value')
    const taxLiability = TaxCalculations.calculateTaxLiability(
      taxableAssessedValue,
      taxRate,
      totalAdditionalSpecialAssessments
    )
    const taxLiabilityPerBasis = TaxCalculations.calculateTaxesPerBasis(
      basis,
      taxLiability,
      squareFootage,
      residentialUnitCount
    )

    const formattedTaxRate = formatPercentageString(taxRate, 10)

    return (
      <TableRow data-qa={`${label}-row`} key={rowIndex}>
        <TableCell className={classes.disabledTextCell} data-qa={`${label}-label-cell`}>
          {label}
        </TableCell>
        <TableCell className={classes.disabledNumberCell} data-qa={`${label}-taxableAssessedValue-value-cell`}>
          {formatCurrencyInt(taxableAssessedValue)}
        </TableCell>
        <TableCell className={classes.disabledNumberCell} data-qa={`${label}-taxRate-value-cell`}>
          {formattedTaxRate}
        </TableCell>
        <TableCell className={classes.disabledNumberCell} data-qa={`${label}-taxLiability-value-cell`}>
          {formatCurrencyInt(taxLiability)}
        </TableCell>
        <TableCell className={classes.disabledNumberCell} data-qa={`${label}-taxLiabilityPerBasis-value-cell`}>
          {formatCurrencyFloat(taxLiabilityPerBasis)}
        </TableCell>
      </TableRow>
    )
  }

  renderTotalTableRow(label, taxableAssessedValue, taxRate, taxLiability, taxLiabilityPerBasis) {
    const { classes } = this.props
    const formattedTaxRate = formatPercentageString(taxRate, 10)
    return (
      <TableRow data-qa={`${label}-row`} className={classes.totalRow}>
        <TableCell className={classes.totalDisabledLabel} data-qa={`${label}-label-cell`}>
          {label}
        </TableCell>
        <TableCell className={classes.totalDisabled} data-qa={`${label}-taxableAssessedValue-value-cell`}>
          {formatCurrencyFloat(taxableAssessedValue)}
        </TableCell>
        <TableCell className={classes.totalDisabled} data-qa={`${label}-taxRate-value-cell`}>
          {formattedTaxRate}
        </TableCell>
        <TableCell className={classes.totalDisabled} data-qa={`${label}-taxLiability-value-cell`}>
          {formatCurrencyFloat(taxLiability)}
        </TableCell>
        <TableCell className={classes.totalDisabled} data-qa={`${label}-taxLiabilityPerBasis-value-cell`}>
          {formatCurrencyFloat(taxLiabilityPerBasis)}
        </TableCell>
      </TableRow>
    )
  }

  renderCompSummaryTableRow(label, taxLiability, taxLiabilityPerBasis, rowIndex = null) {
    const { classes } = this.props

    return (
      <TableRow data-qa={`${label}-row`} key={rowIndex}>
        <TableCell className={classes.disabledTextCell} data-qa={`${label}-label-cell`}>
          {label}
        </TableCell>
        <TableCell className={classes.disabledNumberCell}>-</TableCell>
        <TableCell className={classes.disabledNumberCell}>-</TableCell>
        <TableCell className={classes.disabledNumberCell} data-qa={`${label}-taxLiability-value-cell`}>
          {formatCurrencyInt(taxLiability)}
        </TableCell>
        <TableCell className={classes.disabledNumberCell} data-qa={`${label}-taxLiabilityPerBasis-value-cell`}>
          {formatCurrencyFloat(taxLiabilityPerBasis)}
        </TableCell>
      </TableRow>
    )
  }

  renderAppraiserConclusion() {
    const { form, taxRate } = this.props
    const { basis, residentialUnitCount, squareFootage } = form.values
    const selectedConcludedLiability = get(form.values, 'projected.concludedLiabilityType', '')

    let taxLiabilityPerBasis, taxLiability
    if (selectedConcludedLiability !== CONCLUDED_LIABILITY_TYPES.TOTAL) {
      taxLiabilityPerBasis = get(form, 'values.projected.concludedLiabilityPerBasis', 0)
      taxLiability = TaxCalculations.calculateLiabilityFromPerBasisValue(
        basis,
        taxLiabilityPerBasis,
        squareFootage,
        residentialUnitCount
      )
    } else {
      taxLiability = get(form, 'values.projected.taxLiability', 0)
      const basisValue = basis === BASIS_TYPES.PER_UNIT ? residentialUnitCount : squareFootage
      taxLiabilityPerBasis = TaxCalculations.calculateTaxesPerBasisValue(basisValue, taxLiability)
    }

    const taxableAssessedValue = TaxCalculations.calculateTaxableAssessedValue(taxLiability, taxRate.value)
    return this.renderTotalTableRow(
      "Appraiser's Opinion",
      taxableAssessedValue,
      taxRate.value,
      taxLiability,
      taxLiabilityPerBasis
    )
  }

  renderCurrentTotalRow() {
    const { form, taxRate } = this.props
    const { basis, squareFootage, residentialUnitCount, taxLiability } = form.values
    const currentTaxableAssessedValue = get(form, 'values.taxableAssessedValue', 0)
    const taxLiabilityPerBasis = TaxCalculations.calculateTaxesPerBasis(
      basis,
      taxLiability,
      squareFootage,
      residentialUnitCount
    )

    return this.renderTotalTableRow(
      'Current',
      currentTaxableAssessedValue,
      taxRate.value,
      taxLiability,
      taxLiabilityPerBasis
    )
  }

  render() {
    const { form, taxRate, classes } = this.props

    const { basis, squareFootage } = form.values
    const projectedLiabilities = form.values.projected
    const taxComps = get(form, 'values.taxComps', [])
    const projectedLiabilityTableData = this.getProjectedLiabilityTableData(projectedLiabilities)
    const compSummaryTableData = this.getCompSummaryTableData(taxComps)
    const conclusionOptions = arrayToKeyValuePairs([basis, 'Total'])
    const selectedConcludedLiability = get(form.values, 'projected.concludedLiabilityType', '')

    const taxesPerBasisLabel = getTaxLiabilityLabel(basis)

    return (
      <Grid container>
        <Grid item xs={12}>
          <Paper className={classes.paper}>
            {projectedLiabilities.includedInExport && (
              <Grid item xs={5}>
                <Typography className={classes.title} variant="subtitle1">
                  Appraisers Concluded Tax Liability
                </Typography>
                <RadioButtonList name="projected.concludedLiabilityType" items={conclusionOptions} horizontal />
                {selectedConcludedLiability !== CONCLUDED_LIABILITY_TYPES.TOTAL ? (
                  <Number
                    name="projected.concludedLiabilityPerBasis"
                    decimalScale={2}
                    prefix="$"
                    adornment={selectedConcludedLiability}
                    adornmentPosition="end"
                    validate={positive}
                    thousandSeparator
                    classes={{ formControl: classes.concludedTaxLiabilityFormControl }}
                  />
                ) : (
                  <Number
                    name="projected.taxLiability"
                    decimalScale={2}
                    prefix="$"
                    adornment={selectedConcludedLiability}
                    adornmentPosition="end"
                    validate={positive}
                    thousandSeparator
                    classes={{ formControl: classes.concludedTaxLiabilityFormControl }}
                  />
                )}
                <Typography
                  variant="caption"
                  data-qa="percent-of-effective-gross-income"
                  className={classes.concludedTaxLiabilityHelperText}
                >
                  {this.getConcludedTaxLiabilityHelperText()}
                </Typography>
              </Grid>
            )}
            <Grid item xs={12}>
              <Card elevation={0} className={classes.tableContainer}>
                <Table>
                  <TableHead>
                    <TableRow className={classes.header} data-qa="taxRate-row">
                      <TableCell className={classes.leadHeaderCell} data-qa="taxRate-label-cell">
                        Item
                      </TableCell>
                      <TableCell className={classes.headerCell} data-qa="taxRate-value-cell">
                        {LABELS.TAV}
                      </TableCell>
                      <TableCell className={classes.headerCell} data-qa="taxRate-value-cell">
                        {LABELS.TAX_RATE}
                      </TableCell>
                      <TableCell className={classes.headerCell} data-qa="taxRate-value-cell">
                        {LABELS.TAX_LIABILITY_TOTAL}
                      </TableCell>
                      <TableCell className={classes.headerCell} data-qa="taxRate-value-cell">
                        {taxesPerBasisLabel}
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {projectedLiabilityTableData.map((rowValues, rowIndex) => {
                      return this.renderTableRow(
                        rowValues.label,
                        rowValues.tav,
                        squareFootage,
                        taxRate.value,
                        rowValues.additionalSpecialAssessments,
                        rowIndex
                      )
                    })}
                    {compSummaryTableData.map((rowValues, rowIndex) => {
                      return this.renderCompSummaryTableRow(
                        rowValues.label,
                        rowValues.taxLiability,
                        rowValues.taxLiabilityPerBasis,
                        rowIndex
                      )
                    })}

                    {projectedLiabilities.includedInExport
                      ? this.renderAppraiserConclusion()
                      : this.renderCurrentTotalRow()}
                  </TableBody>
                </Table>
              </Card>
            </Grid>
          </Paper>
        </Grid>
        <Grid item xs={12}>
          <Paper className={classes.paper}>
            <Grid item xl={12} className={classes.discussionContainer}>
              <GeneratedComment
                isDynamicContent
                className={classes.title}
                label="Tax Summary Discussion"
                title="Generated Commentary"
                dataPath="taxSummaryDiscussion"
                tooltipText={TAX_SUMMARY_TOOLTIP}
                getGeneratedText={generateTaxSummaryDiscussion}
              />
            </Grid>
          </Paper>
        </Grid>
      </Grid>
    )
  }
}

export default withStyles(styles)(SummaryTable)
