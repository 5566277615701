import React from 'react'
import { get, isNil } from 'lodash'
import PropTypes from 'prop-types'
import arrayMutators from 'final-form-arrays'

import { RESIDENTIAL_RENT_ROLL_PATH } from 'shared/constants/report/keysAndDataPaths'

import wrapForm from '../../../../wrapForm'

import decorators from '../decorators'

import { isValidBathroomCount } from '../rentRollValidators'

import RentRollData from './RentRollData'

const DATA_PATH = RESIDENTIAL_RENT_ROLL_PATH

export class ResidentialRentRoll extends React.PureComponent {
  static propTypes = {
    valueAsStabilized: PropTypes.bool,
    isSubmitting: PropTypes.bool,
    clientPortalJobLink: PropTypes.string,
    reportNumber: PropTypes.string,
    reportId: PropTypes.string,
  }

  render() {
    const { form, valueAsStabilized, isSubmitting, reportNumber, reportId, clientPortalJobLink } = this.props

    return (
      <RentRollData
        form={form}
        valueAsStabilized={valueAsStabilized}
        isSubmitting={isSubmitting}
        clientPortalJobLink={clientPortalJobLink}
        reportNumber={reportNumber}
        reportId={reportId}
      />
    )
  }
}

const formOptions = {
  mutators: { ...arrayMutators },
  decorators: decorators,
  validate: formValues => {
    const errors = {}
    formValues.units.forEach((unit, index) => {
      if (!unit.rent && unit.rent !== 0) {
        errors[`units[${index}].rent`] = 'Required'
      }
      if (formValues.showDevelopersForecast && isNil(unit.rentForecast)) {
        errors[`units[${index}].rentForecast`] = 'Required'
      }

      if (formValues.bathrooms && !isValidBathroomCount(unit.bathrooms)) {
        errors[`units[${index}].bathrooms`] = 'Required'
      }
    })
    return errors
  },
  validationErrorMessage: 'Please make sure that all the required fields are filled in',
}

export default wrapForm(DATA_PATH, formOptions, state => ({
  valueAsStabilized: get(state, 'report.reportSettings.valueAsStabilized'),
  isSubmitting: get(state, 'report.submitting'),
  clientPortalJobLink: get(state, 'report.reportData.clientPortalJobLink'),
  reportNumber: get(state, 'report.reportData.number'),
  reportId: get(state, 'report.reportData._id'),
}))(ResidentialRentRoll)
