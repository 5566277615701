import React from 'react'
import PropTypes from 'prop-types'

import {
  Button,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  Tooltip,
  Stack,
} from '@mui/material'
import { find, map } from 'lodash'

import { Link } from 'react-router-dom'
import OpenIcon from '@mui/icons-material/OpenInNewRounded'
import DismissibleCallout from 'client-shared/components/DismissibleCallout'
import { formatCurrencyInt, formatCurrencyFloat } from 'client-shared/utils/numberFormatters'
import TableThemeProvider from 'report/components/TableThemeProvider'
import { precisionRound } from 'client-shared/utils/numberOperations'
import { MarketConclusionUnits, RentTypes } from 'report/constants'
import { LEASE_STATUSES } from 'shared/constants/report/property'
import { TIME_PERIODS } from 'shared/constants/report/incomeApproach'
import { RESIDENTIAL_PROJECTED_RENT_ROLL_PATH } from 'shared/constants/report/keysAndDataPaths'
import { extractGroupingParameters } from 'shared/helpers/incomeApproach'

import ProjectedRentRollTable from './ProjectedRentRollTable'

export const DATA_PATH = RESIDENTIAL_PROJECTED_RENT_ROLL_PATH

const MARKET_RATE_HEADING = 'Market Rate Conclusions'
const AUTO_FILL_BUTTON = 'AUTO FILL VACANT UNITS'
const NUMBER_OF_MONTHS = 12

class ResidentialProjectedRentRoll extends React.PureComponent {
  calculateRentPerUnit = (unit, rentConclusion, unitRentPSFTimePeriod) => {
    if (rentConclusion.marketConclusionUnit === MarketConclusionUnits.SF) {
      if (unitRentPSFTimePeriod === TIME_PERIODS.MONTHLY) {
        return precisionRound(rentConclusion.marketConclusion * unit.squareFootage, 2)
      }
      return precisionRound((rentConclusion.marketConclusion * unit.squareFootage) / NUMBER_OF_MONTHS, 2)
    }
    return precisionRound(rentConclusion.marketConclusion, 2)
  }

  fillInVacantRents = () => {
    const { form, rentConclusions, groupingType } = this.props
    const { unitRentPSFTimePeriod } = form.values

    const updatedUnits = map(form.values.units, unit => {
      if (unit.leaseStatus !== LEASE_STATUSES.VACANT) {
        return unit
      }

      if (unit.rentType !== RentTypes.MARKET_RATE) {
        return unit
      }

      const { key } = extractGroupingParameters(unit, groupingType)
      const rentConclusion = find(rentConclusions, conclusion => conclusion.unitGroupKey === key)
      if (rentConclusion) {
        unit.rent = this.calculateRentPerUnit(unit, rentConclusion, unitRentPSFTimePeriod)
      }
      return unit
    })

    form.change('units', updatedUnits)
  }

  getMarketConclusionDisplayValue = (marketConclusion, marketConclusionUnit, unitRentPSFTimePeriod) => {
    const formattedConclusion =
      marketConclusionUnit === MarketConclusionUnits.SF
        ? formatCurrencyFloat(marketConclusion)
        : formatCurrencyInt(marketConclusion)
    const conclusionUnit =
      marketConclusionUnit === MarketConclusionUnits.SF && unitRentPSFTimePeriod === TIME_PERIODS.MONTHLY
        ? '/SF/Month'
        : `/${marketConclusionUnit}`

    return `${formattedConclusion} ${conclusionUnit}`
  }

  renderRentReconciliationSummary = () => {
    const { rentConclusions, form } = this.props
    const { unitRentPSFTimePeriod } = form.values

    return (
      <Table data-qa="rent-reconciliation-summary-table">
        <TableHead>
          <TableRow>
            <TableCell>Unit Type</TableCell>
            <TableCell>Forecasted Rent</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rentConclusions.map(rentConclusion => {
            const { marketConclusion, marketConclusionUnit } = rentConclusion

            return (
              <TableRow key={rentConclusion.unitGroupName}>
                <TableCell data-qa="unit-group-name">{rentConclusion.unitGroupName}</TableCell>
                <TableCell data-qa="unit-group-market-rent-conclusion">
                  {this.getMarketConclusionDisplayValue(marketConclusion, marketConclusionUnit, unitRentPSFTimePeriod)}
                </TableCell>
              </TableRow>
            )
          })}
        </TableBody>
      </Table>
    )
  }

  getCalloutText = () => {
    return `Click ${AUTO_FILL_BUTTON} to use Market Rate Conclusions for vacant units. Final value calculations will
      be based on this stabilized rent roll.`
  }

  render() {
    const { form } = this.props
    return (
      <Stack spacing={2}>
        <Paper>
          <Stack spacing={2}>
            <Stack alignItems="center" direction="row" spacing={2}>
              <Typography variant="subtitle1">{MARKET_RATE_HEADING}</Typography>
              <Tooltip title="Go To Residential Rent Reconciliation" placement="top">
                <span>
                  <Link to="./residential-rent-reconciliation">
                    <IconButton>
                      <OpenIcon fontSize="small" />
                    </IconButton>
                  </Link>
                </span>
              </Tooltip>
            </Stack>
            <TableThemeProvider>{this.renderRentReconciliationSummary()}</TableThemeProvider>
          </Stack>
        </Paper>
        <Paper>
          <Stack spacing={2}>
            <DismissibleCallout text={this.getCalloutText()} showInfoOutlined={true} />
            <Button
              color="primary"
              data-qa="autofill-button"
              onClick={this.fillInVacantRents}
              sx={{ alignSelf: 'start' }}
              variant="contained"
            >
              {AUTO_FILL_BUTTON}
            </Button>
            <ProjectedRentRollTable form={form} />
          </Stack>
        </Paper>
      </Stack>
    )
  }
}

ResidentialProjectedRentRoll.propTypes = {
  groupingType: PropTypes.string.isRequired,
  rentConclusions: PropTypes.array,
  valueAsStabilized: PropTypes.bool,
}

ResidentialProjectedRentRoll.defaultProps = {
  rentConclusions: [],
}

export default ResidentialProjectedRentRoll
