import React, { useCallback } from 'react'
import { Button } from '@mui/material'
import { cloneDeep } from 'lodash'
import { FormApi } from 'final-form'

import useUndo from 'client-shared/hooks/useUndo'

export const useTableUndo = (form: FormApi) => {
  const getValuesToPush = useCallback(() => {
    const formValues = form.getState().values
    return {
      expenseCategories: cloneDeep(formValues.expenseCategories),
      expenseHistory: cloneDeep(formValues.expenseHistory),
    }
  }, [form])

  const setTableValues = (value: any) => {
    if (value.expenseCategories && value.expenseHistory) {
      form.batch(() => {
        form.change('expenseCategories', value.expenseCategories)
        form.change('expenseHistory', value.expenseHistory)
      })
    }
  }

  const { canUndo, undo, makeUndoable, reset } = useUndo(getValuesToPush, setTableValues)

  const UndoButton = () => (
    <Button
      disabled={!canUndo}
      variant="outlined"
      onClick={() => {
        undo()
      }}
    >
      Undo Edit
    </Button>
  )

  return { UndoButton, resetUndo: reset, makeUndoable }
}
