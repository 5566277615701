import { PropertyTypes as PT } from 'shared/constants'

export const TEMPLATE_TYPES = {
  FREDDIE_MAC: 'freddie-mac',
  BOWERY_WAY: 'bowery-way',
  BLOCKS: 'blocks',
}

export const VALUE_CONCLUSION_TYPES = {
  AS_IS: 'AS_IS',
  AS_STABILIZED: 'AS_STABILIZED',
  AS_COMPLETE: 'AS_COMPLETE',
}

export const VALUE_CONCLUSION_DESCRIPTIONS = {
  [VALUE_CONCLUSION_TYPES.AS_IS]:
    'The subject property is stabilized, or fully leased, and does not require prospective renovations.',
  [VALUE_CONCLUSION_TYPES.AS_STABILIZED]:
    'The subject property is not currently stabilized, or fully leased. There are no plans for prospective renovations.',
  [VALUE_CONCLUSION_TYPES.AS_COMPLETE]:
    'The subject property is not currently stabilized, or fully leased. It will undergo prospective renovations.',
}

export const TEMPLATE_TYPE_AFFECTED_PAGES = [
  'Property > Zoning',
  'Income > Supporting Cap Rates',
  'Income > Cap Rate Conclusion',
]

export const INCOME_TYPE_AFFECTED_PAGES = {
  [PT.MULTI_FAMILY]: [
    'Property > Commercial Units',
    'Property > Certain Amenities',
    'Income > Commercial',
    'Sales > Adjust Comps',
    'Sales > Value Conclusion',
  ],
  [PT.MIXED_USE]: ['Property > Certain Amenities'],
  [PT.COMMERCIAL]: [
    'Property > Residential Units',
    'Property > Certain Amenities',
    'Income > Residential',
    'Sales > Adjust Comps',
    'Sales > Value Conclusion',
  ],
}

export const AS_COMPLETE_AFFECTED_PAGES = [
  'Report > Key Info > Interest Appraised ',
  'Property > Summary > As Complete Building Description',
  'Property > Renovations > Prospective Renovations',
  'Property > Description',
  'Property > Residential Units',
  'Income > Residential',
  'Income > Expense Forecast',
  'Income > Cap Rate Conclusion',
  'Sales > Value Conclusion',
  'Sales > Scope',
]
export const AS_STABILIZED_AFFECTED_PAGES = [
  'Report > Key Info',
  'Income > Cap Rate Conclusion',
  'Sales > Value Conclusion',
  'Sales > Scope',
]

export const DEFAULT_TEMPLATE_TYPE = TEMPLATE_TYPES.BOWERY_WAY
export const DEFAULT_VALUE_CONCLUSION_TYPE = VALUE_CONCLUSION_TYPES.AS_IS
