import React from 'react'

import { Stack } from '@mui/material'

import wrapForm from '../wrapForm'

import { BUILDING_LABEL_VALUE_PAIR } from '../../constants'

import { PropertySummary, PropertyConditions, UnitMix } from './components'
export const DATA_PATH = [BUILDING_LABEL_VALUE_PAIR.CompSummary.menuItem]

const CompSummary = () => (
  <Stack spacing={1}>
    <PropertySummary />
    <PropertyConditions />
    <UnitMix />
  </Stack>
)

export default wrapForm(DATA_PATH, {
  heading: BUILDING_LABEL_VALUE_PAIR.CompSummary.heading,
})(CompSummary)
