import { GeneratedText, gtParagraph } from '../../../../narrative/GeneratedText'

import { mapDTO, mapDataModel } from './mappings'

export const DIRECT_COSTS_INTRO =
  'The MVS base unit cost estimates include the following direct costs, as well as other costs typically considered site costs and soft costs (indirect costs):\n' +
  '\u2022 Materials, sales taxes on materials, and labor costs\n' +
  '\u2022 Typical site preparation including finish grading and excavation for foundation and backfill;\n' +
  '\u2022 Utilities from structure to lot line\n' +
  "\u2022 Typical architect's and engineer's fees for plans, plan check, building permits and survey\n" +
  "\u2022 Typical contractor's overhead and profit, including job supervision, workmen's compensation, fire and liability insurance, equipment, temporary facilities, security, etc.\n" +
  '\u2022 Normal interest in building funds during the period of construction plus processing fees or service charges'

const generate = () => {
  const gt = new GeneratedText()

  gt.addPart(gtParagraph(DIRECT_COSTS_INTRO))
  gt.addPart(
    gtParagraph(
      "Base unit building costs in MVS are adjusted to reflect the physical characteristics of the subject. Making these adjustments, including the appropriate local and current cost multipliers, reflect the total basic structure cost estimate for the subject property. In order to test the subject's actual/budgeted construction costs, we have compared the MVS cost estimate to the subject’s adjusted hard costs, inclusive of the above typical site and soft costs included within the MVS basic structure cost estimate."
    )
  )

  return gt.getParts()
}

export default { generate, mapDTO, mapDataModel }
