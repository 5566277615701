import React from 'react'

import { Button, IconButton, Stack, TableCell } from '@mui/material'
import DisabledByDefault from '@mui/icons-material/DisabledByDefault'
import { get } from 'lodash'

import { formatFloat } from 'client-shared/utils/numberFormatters'
import { shortDateWithZeroesFormat } from 'client-shared/utils/dateHelper'

import { BuildingCompColumnNames } from '../../constants'

import AverageRentCell from './AverageRentCell'

const BuildingCompCell = ({
  column: { name },
  isExpanded,
  isSubject,
  item,
  onAddCompItem,
  onEditCompItem,
  onRemoveCompItem,
  order,
  toggleDetailsExpand,
}) => {
  switch (name) {
    case BuildingCompColumnNames.INDEX:
      return (
        <TableCell data-qa={`${name}-cell`} padding="dense">
          {isSubject ? 'Subj.' : order}
        </TableCell>
      )

    case BuildingCompColumnNames.ADDRESS:
      return (
        <TableCell data-qa={`${name}-cell`} padding="dense">
          {item.fullAddressWithZip || '-'}
        </TableCell>
      )

    case BuildingCompColumnNames.ELEVATOR:
      return (
        <TableCell data-qa={`${name}-cell`} padding="dense">
          {item[name] ? 'Yes' : 'No'}
        </TableCell>
      )
    case BuildingCompColumnNames.AVERAGE_RENT:
      return (
        <TableCell data-qa="avg-rent-cell" padding="dense">
          <AverageRentCell
            averageRent={item[name]}
            totals={get(item, 'residentialUnits.totals')}
            averageBucketIndex={get(item, 'residentialUnits.averageBucketIndex')}
          />
        </TableCell>
      )

    case BuildingCompColumnNames.DISTANCE:
      const value = formatFloat(item[name], 1)
      const distance = item[name] ? `${value}m` : '-'
      return (
        <TableCell data-qa={`${name}-cell`} padding="dense">
          {distance}
        </TableCell>
      )
    case BuildingCompColumnNames.ACTIONS:
      const detailsLabel = isExpanded ? 'HIDE DETAILS' : 'SHOW DETAILS'
      return (
        <TableCell>
          <Stack alignItems="center" direction="row" spacing={1}>
            <Button onClick={toggleDetailsExpand}>{detailsLabel}</Button>
            {onAddCompItem && (
              <Button onClick={onAddCompItem} color="info" variant="contained">
                Add
              </Button>
            )}

            {onEditCompItem && (
              <Button onClick={onEditCompItem} variant="contained">
                Edit
              </Button>
            )}

            {onRemoveCompItem && (
              <IconButton onClick={onRemoveCompItem} size="small">
                <DisabledByDefault color="error" fontSize="large" />
              </IconButton>
            )}
          </Stack>
        </TableCell>
      )
    case BuildingCompColumnNames.AS_OF_DATE:
      const date = item[name] ? shortDateWithZeroesFormat(item[name]) : '-'
      return (
        <TableCell data-qa={`${name}-cell`} padding="dense">
          {date}
        </TableCell>
      )
    default:
      return (
        <TableCell data-qa={`${name}-cell`} padding="dense">
          {item[name] || '-'}
        </TableCell>
      )
  }
}

export default BuildingCompCell
