import React from 'react'

import { min, max, isEmpty } from 'lodash'
import { Paper, Stack, Table, TableBody, TableCell, TableHead, TableRow, Typography } from '@mui/material'
import { Number as NumberField, CalloutButton } from 'client-shared/components'
import { fromPercents, toPercents } from 'client-shared/utils/numberOperations'

import { WARNING_TEXT } from './constants'

const CapRateConclusion = ({
  bandOfInvestments,
  capRateComps,
  debtCoverageRatio,
  isFreddieMac,
  personalSurvey,
  pwc,
  situsRERC,
}) => {
  const hasCapRateComps = !isEmpty(capRateComps)
  const hasPersonalSurvey = personalSurvey.isCapRateRange
    ? !!personalSurvey.capRate.min || !!personalSurvey.capRate.max
    : !!personalSurvey.capRate.value
  const minCapRateComp = min(capRateComps.map(comp => comp.capRate))
  const maxCapRateComp = max(capRateComps.map(comp => comp.capRate))
  const capRateCompsRange =
    minCapRateComp === maxCapRateComp
      ? `${toPercents(minCapRateComp)}%`
      : `${toPercents(minCapRateComp)}-${toPercents(maxCapRateComp)}%`
  return (
    <Paper data-qa="capRateConclusion-section">
      <Stack spacing={2}>
        <Stack spacing={1}>
          <Typography variant="subtitle1">Cap Rate Conclusion</Typography>
          <Table size="small">
            <TableHead sx={{ '& .MuiTableCell-head': { fontSize: 12, fontWeight: 700 } }}>
              <TableRow>
                <TableCell>Survey</TableCell>
                <TableCell>Overall Cap Rate</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow>
                <TableCell>Band of Investment</TableCell>
                <TableCell data-qa="band-of-investments-cell">
                  {!!bandOfInvestments ? `${toPercents(bandOfInvestments)}%` : '-'}
                </TableCell>
              </TableRow>
              {!!isFreddieMac && (
                <TableRow>
                  <TableCell>Debt Coverage Ratio</TableCell>
                  <TableCell data-qa="debt-coverage-ratio-cell">
                    {!!debtCoverageRatio ? `${toPercents(debtCoverageRatio)}%` : '-'}
                  </TableCell>
                </TableRow>
              )}
              {!!hasPersonalSurvey && (
                <TableRow>
                  <TableCell>Personal Survey</TableCell>
                  <TableCell data-qa="personal-survey-cell">
                    {!!personalSurvey.isCapRateRange
                      ? `${toPercents(personalSurvey.capRate.min)}-${toPercents(personalSurvey.capRate.max)}%`
                      : `${toPercents(personalSurvey.capRate.value)}%`}
                  </TableCell>
                </TableRow>
              )}
              <TableRow>
                <TableCell>PwC</TableCell>
                <TableCell data-qa="pwc-cell">{`${toPercents(pwc.min)}-${toPercents(pwc.max)}%`}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Situs - RERC</TableCell>
                <TableCell data-qa="situs-rerc-cell">{`${toPercents(situsRERC.min)}-${toPercents(
                  situsRERC.max
                )}%`}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Comparable Cap Rates</TableCell>
                <TableCell data-qa="comparable-sales-cell">{hasCapRateComps ? capRateCompsRange : '-'}</TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </Stack>
        <Stack spacing={1}>
          <Typography variant="subtitle2">Appraiser's Conclusion</Typography>
          {!hasCapRateComps && (
            <CalloutButton
              link="./cap-rate-comps"
              linkText="Change"
              qa="no-cap-rate-comps-callout-btn"
              text={WARNING_TEXT}
              variant="info"
            />
          )}
          <NumberField
            adornment="%"
            allowNegative={false}
            decimalScale={2}
            format={toPercents}
            fullWidth
            name="concludedCapRate"
            normalize={fromPercents}
          />
        </Stack>
      </Stack>
    </Paper>
  )
}

export default CapRateConclusion
