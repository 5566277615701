import { GeneratedText, gtParagraph, gtText } from '../../../narrative/GeneratedText'

export const INTRODUCTION_TEXT =
  'In estimating the replacement cost new for the subject, the following data sources have been utilized:'
export const FIRST_BULLET = "\u2022 The subject's actual/budgeted construction costs, provided by the developer.\n"
export const SECOND_BULLET =
  '\u2022 The comparative unit method, utilizing the Marshall Valuation Service (MVS) cost guide; a nationally recognized cost service.\n'
export const THIRD_BULLET = '\u2022 Budgets of comparable developments\n'

const generate = () => {
  const gt = new GeneratedText()
  const bulletGt = new GeneratedText()

  bulletGt.addPart(gtParagraph(FIRST_BULLET))
  bulletGt.addPart(gtText(SECOND_BULLET))
  bulletGt.addPart(gtText(THIRD_BULLET))

  gt.addPart(gtParagraph(INTRODUCTION_TEXT))
  gt.addParts(bulletGt.getParts())

  return gt.getParts()
}

export default { generate }
