import React, { useCallback, useEffect, useMemo, useRef } from 'react'
import { noop, get, pickBy, mean, cloneDeep } from 'lodash'
import PropTypes from 'prop-types'

import { BASIS_OF_COMPARISON_OPTIONS } from 'shared/constants/expenses'

import {
  ColumnBasedTable,
  useColumnBasedRowsApi,
  useColumnBasedColumnsApi,
  useColumnBasedGridRows,
} from '@bowery-valuation/ui-components'
import '@bowery-valuation/ui-components/dist/style.css'

import ObjectID from 'bson-objectid'

import { Button, Paper, Typography } from '@mui/material'

import { FeatureToggle } from '@bowery-valuation/feature-flagger-client'

import { FEATURE_FLAG_AUTOMATED_EXPENSE_HISTORY } from 'shared/constants/featureFlags'

import useBoolean from 'client-shared/hooks/useBoolean'
import AutomationStatus from 'client-shared/components/AutomationStatus'
import ExpenseDetails from 'report/components/ExpenseDetailsTable'
import Layout from '@ui/Layout'
import AddExpenseDialog from 'report/components/AddExpenseDialog'
import { formatExpenseId } from 'shared/helpers/incomeApproach/expenses/helpers'

import { formatRowPath, setName, normalizeIndex, valueBasedOnBasis } from '../helpers'
import { useColumnDefinitions } from '../useColumnDefinitions'
import { EXPENSE_MODAL_NAME } from '../constants'
import { defineReservedNames, rowManagedRenderer, validateName } from '../../helpers'

const valueForRow = (item, row) => {
  return item?.expenses?.find(expense => expense.id === row.id)?.value
}

const ExpenseHistoryTable = ({
  form,
  basis,
  setBasis,
  undoButtonComponent: UndoButton,
  makeUndoable,
  resetUndo,
  expenseCategoriesFields,
}) => {
  const [isModalOpen, openAddExpenseModal, closeAddExpenseModal] = useBoolean(false)

  const formValues = get(form, 'values', {})
  const {
    expenseCategories,
    expenseDataSource,
    expenseHistory,
    noi,
    total,
    totalExcludingTaxes,
    numberOfResidentialUnits,
    grossBuildingArea,
    incomeType,
    basisOfComparison,
    utilitiesExpensesMode,
  } = formValues

  const rowsApi = useColumnBasedRowsApi({
    rowDefinitionsPath: 'expenseCategories',
    columnItemsPath: 'expenseHistory',
    valuesPaths: 'expenses',
  })

  useEffect(() => {
    // TODO: This is a hack to make the grid render correctly.
    setTimeout(() => {
      return ref?.current?.refreshCells({ suppressFlash: true, enableCellChangeFlash: true, force: true })
    })
  }, [basis])
  const ref = useRef()

  const cellEditable = useCallback(
    ({ colDef }) => {
      return basis === BASIS_OF_COMPARISON_OPTIONS.GROSS && colDef.headerName !== 'Operating Expenses'
    },
    [basis]
  )
  const columnValueFormatter = useCallback(
    ({ data, value }) => valueBasedOnBasis({ data, value, incomeType, grossBuildingArea, numberOfResidentialUnits }),
    [grossBuildingArea, incomeType, numberOfResidentialUnits]
  )
  const averageValueFormatter = useCallback(
    ({ data }) => {
      const values = pickBy(data, (__, key) => ObjectID.isValid(key))
      const result = Object.entries(values)
        .map(([_, itemValue]) =>
          valueBasedOnBasis({ data, value: itemValue, incomeType, grossBuildingArea, numberOfResidentialUnits })
        )
        .filter(item => typeof item === 'number')
      return mean(result) || null
    },
    [grossBuildingArea, incomeType, numberOfResidentialUnits]
  )
  const columnDefinitions = useColumnDefinitions({
    expenseDataSource,
    columnValueFormatter,
    averageValueFormatter,
  })

  const { rowFields, columnBasedGridRows } = useColumnBasedGridRows({
    rows: expenseCategories.map(row => {
      return {
        ...row,
        name: setName(row, basis),
        rowDef: {
          ...row?.rowDef,
          hideAction: ['grossRevenue'].includes(row.id),
        },
        basisValue: basis,
      }
    }),
    items: expenseHistory,
    editable: cellEditable,
    itemValueForRow: valueForRow,
    itemKey: value => value.key,
    path: formatRowPath,
    type: () => 'money',
  })

  const { rowFields: summaryFields, columnBasedGridRows: summaryRows } = useColumnBasedGridRows(
    {
      rows: [total, totalExcludingTaxes, noi].map(row => {
        return {
          ...row,
          name: setName(row, basis),
          rowDef: {
            summary: true,
            path: (row, itemIndex) => {
              return `expenseHistory[${itemIndex}].${row.id}`
            },
            hideAction: true,
          },
          basisValue: basis,
        }
      }),
      items: expenseHistory,
      editable: () => false,
      itemValueForRow: (value, row) => {
        return get(value, row.id) || 0
      },
      itemKey: value => value.key,
      type: () => 'money',
    },
    [total, totalExcludingTaxes, noi, expenseHistory, basis]
  )

  const columnsApi = useColumnBasedColumnsApi({ columnItemsPath: 'expenseHistory', normalizeIndex })

  const tableRows = useMemo(() => [...columnBasedGridRows, ...summaryRows], [columnBasedGridRows, summaryRows])

  const reservedNames = defineReservedNames(utilitiesExpensesMode)
  const validateExpenseName = value => {
    return validateName(value, expenseCategoriesFields.value, reservedNames)
  }
  const excludedExpenses = [...expenseCategoriesFields.value.map(field => field.name), ...reservedNames]

  const addRow = modalFormValues => {
    const { expenseName } = modalFormValues
    const newId = formatExpenseId(expenseName)
    const updatedExpenseHistory = expenseHistory.map(expensePeriodUnit => {
      const updatedExpensePeriodUnit = cloneDeep(expensePeriodUnit)

      updatedExpensePeriodUnit.expenses = [
        ...expensePeriodUnit.expenses,
        { id: newId, reported: false, sf: 0, unit: 0, room: 0 },
      ]
      return updatedExpensePeriodUnit
    })

    expenseCategoriesFields.push({ id: newId, name: expenseName })

    form.change('expenseHistory', updatedExpenseHistory)

    closeAddExpenseModal()
    resetUndo()
  }

  return (
    <>
      <Paper>
        <Layout.Crab sx={{ mb: 2 }}>
          <Layout.HorizontalRow gap={1}>
            <Typography variant="h6">Expense History</Typography>
            <ExpenseDetails title="Expense History Details" setBasis={setBasis} formValue={basisOfComparison} />
          </Layout.HorizontalRow>
          <Layout.HorizontalRow>
            <UndoButton />
            <Button color="primary" data-qa="add-category-btn" onClick={openAddExpenseModal} variant="contained">
              ADD CUSTOM EXPENSE CATEGORY +
            </Button>
          </Layout.HorizontalRow>
        </Layout.Crab>
        <FeatureToggle featureFlag={FEATURE_FLAG_AUTOMATED_EXPENSE_HISTORY}>
          <div style={{ marginBottom: '16px' }}>
            <AutomationStatus formPaths={['expenseHistoryAutomationMetadata', 'proFormaAutomationMetadata']} />
          </div>
        </FeatureToggle>
        {rowFields}
        {summaryFields}
        <ColumnBasedTable
          columns={columnDefinitions}
          disableVirtualization
          hideIndexColumn
          items={expenseHistory}
          gridApiRef={ref}
          legacyComponentRendering
          disableStaticMarkup
          rowManagedRenderer={rowManagedRenderer}
          onColumnDelete={makeUndoable(columnsApi.deleteColumn)}
          onColumnDragEnd={makeUndoable(columnsApi.moveColumn)}
          onColumnUpdate={noop}
          onManyRowsUpdate={makeUndoable(rowsApi.updateManyRows)}
          onRowDelete={makeUndoable(rowsApi.deleteRow)}
          onRowUpdate={makeUndoable(rowsApi.updateRow)}
          onRowsDragEnd={makeUndoable(rowsApi.moveRows)}
          rows={tableRows}
        />
      </Paper>
      {isModalOpen && (
        <AddExpenseDialog
          validate={validateExpenseName}
          onSave={addRow}
          onCancel={closeAddExpenseModal}
          name={EXPENSE_MODAL_NAME}
          excludedExpenses={excludedExpenses}
        />
      )}
    </>
  )
}

ExpenseHistoryTable.propTypes = {
  form: PropTypes.object.isRequired,
  basis: PropTypes.string.isRequired,
  setBasis: PropTypes.func.isRequired,
  undoButtonComponent: PropTypes.elementType,
  makeUndoable: PropTypes.func.isRequired,
  resetUndo: PropTypes.func.isRequired,
  expenseCategoriesFields: PropTypes.object.isRequired,
}

export default ExpenseHistoryTable
