import { get, startCase, uniqBy } from 'lodash'

import { CommercialUnit } from './types'

export const RentTypesUnitsOfMeasure = {
  ANNUAL: 'annually',
  PER_SF_PER_YEAR: 'per square foot per year',
  PER_SF_PER_MONTH: 'per square foot per month',
  MONTHLY: 'monthly',
}

export const getUniqueUses = (units: CommercialUnit[]): string[] => {
  return uniqBy(units, 'description.effectiveUse').map(unit => unit.description.effectiveUse)
}

export const getVCLossPercentageByUse = (use: string, potentialGrossIncomeInfo: any) => {
  const vcLossPercentageByUseName = `commercialVc${startCase(use)}`

  const vcLossPercentageByUse = get(potentialGrossIncomeInfo, vcLossPercentageByUseName)
  return vcLossPercentageByUse
}
