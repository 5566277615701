import { arrayToKeyValuePairs } from '../../../../shared/utils/ui/checkboxHelper'

import { RECONCILIATION_APPROACH } from '../../../../shared/constants'

export const FINAL_VALUES_AND_RECONCILIATION_HEADING = 'Final Values and Reconciliation'
export const FINAL_VALUE_AND_RECONCILIATION_TOOLTIP_TEXT =
  'The following text will appear in the Appraisal Valuation Process and the Reconciliation & Final Value sections of your report.'

export const SALES_APPROACH_METHODOLOGY_TITLE = 'Sales Comparison Approach Methodology'
export const INCOME_APPROACH_METHODOLOGY_TITLE = 'Income Capitalization Approach Methodology'
export const COST_APPROACH_METHODOLOGY_TITLE = 'Cost Approach Methodology'

export const APPROACH_LABELS = arrayToKeyValuePairs([
  RECONCILIATION_APPROACH.COST,
  RECONCILIATION_APPROACH.INCOME,
  RECONCILIATION_APPROACH.SALES,
  RECONCILIATION_APPROACH.LAND,
])

export const PRIMARY_APPROACH = {
  Cost:
    `We have utilized the Cost Approach in our analysis because there is sufficient market data to develop a ` +
    `credible opinion of land value and develop an opinion of the constructions costs, while any depreciation of the ` +
    `improvements can be estimated. Therefore, we give the Cost Approach primary weight in our final value opinion.`,
  Income:
    `Similar assets are generally acquired for their capacity to generate a return on and of capital, ` +
    `which is why this is the methodology primarily applied by investors. Balancing the previously noted ` +
    `factors, and available points of analysis, most weight is placed on the opinion developed by the ` +
    `Income Capitalization Approach.`,
  Sales:
    `Because the most probable buyer of the subject and similarly sized/located properties is an ` +
    `owner/occupant, primary reliance has been placed on the Sales Comparison Approach.`,
}

export const ADDITIONAL_APPROACH = {
  Cost:
    `The difficulty in credibly isolating the influence of physical depreciation and obsolescence affects the ` +
    `reliability of the Cost Approach. Investors typically give nominal weight to this analysis once a property is ` +
    `operating on a stabilized basis as its cost bears little relationship to the value; therefore, we give it ` +
    `little weight in our final value opinion.`,
  Income:
    `In this case, data is somewhat limited for uses similar to that of the subject, reducing credibility to ` +
    `the results of the approach. In addition, as the most probable buyer is an owner/occupant, secondary ` +
    `reliance has been placed on the Income Capitalization Approach.`,
  Sales:
    `The Sales Comparison Approach is largely used as a secondary support for our opinion developed in the ` +
    `application of other approaches.`,
}

export const APPROACH_NOT_USED = {
  Cost:
    `The difficulty in credibly isolating the influence of physical and economic depreciation on value affects the ` +
    `reliability of the Cost Approach. Investors typically give nominal weight to this analysis if the asset is ` +
    `operating on a stabilized basis and its cost bears little relationship to the value, therefore it was not found ` +
    `to be appropriate in our value development.`,
  Income:
    `The Income Capitalization Approach is not used in this assignment due to the lack of sufficient data to support ` +
    `an analysis of the investment market for this specific asset class. More specifically, this asset ` +
    `class/type is typically acquired for owner/operator use, rather than for investment purposes. As such, ` +
    `there is limited data from which to derive market rental rates, forecast stabilized occupancy levels, ` +
    `project operating expenses incurred by a typical investor, or to estimate a market-oriented ` +
    `capitalization rate. The exclusion of this approach is not considered to impact the reliability of ` +
    `the appraisal.`,
  Sales:
    `The Sales Comparison Approach relies heavily on recent sales data, and was not found to be appropriate ` +
    `in our value development due to the uniqueness of the subject and the lack of recent sales of this ` +
    `specific type of asset within the subject's market.`,
}
