import React from 'react'
import PropTypes from 'prop-types'
import { startCase } from 'lodash'
import { Typography, Paper } from '@mui/material'

import Table from './Table'
class Group extends React.PureComponent {
  static defaultProps = {
    children: null,
  }

  render() {
    const {
      listName,
      placeholder,
      fields,
      description,
      onAddItem,
      onOpenClearModal,
      setInputRef,
      onInsertItem,
      onRemoveItem,
      isDragging,
      canRemoveItem,
      revertButton,
      children,
    } = this.props

    return (
      <Paper sx={{ padding: 0, paddingBottom: 2 }}>
        <Typography variant="h6" paragraph sx={{ margin: 0, padding: 2, paddingBottom: 1 }}>
          {startCase(listName)}
        </Typography>

        {children}

        <Table
          listName={listName}
          fields={fields}
          description={description}
          placeholder={placeholder}
          onAddItem={onAddItem}
          onOpenClearModal={onOpenClearModal}
          setInputRef={setInputRef}
          onInsertItem={onInsertItem}
          onRemoveItem={onRemoveItem}
          isDragging={isDragging}
          canRemoveItem={canRemoveItem}
          revertButton={revertButton}
        />
      </Paper>
    )
  }
}

Group.propTypes = {
  listName: PropTypes.string.isRequired,
  placeholder: PropTypes.string.isRequired,
  fields: PropTypes.object.isRequired,
  description: PropTypes.string.isRequired,
  onAddItem: PropTypes.func.isRequired,
  onOpenClearModal: PropTypes.func.isRequired,
  setInputRef: PropTypes.func.isRequired,
  onInsertItem: PropTypes.func.isRequired,
  onRemoveItem: PropTypes.func.isRequired,
  isDragging: PropTypes.bool.isRequired,
  canRemoveItem: PropTypes.func,
  children: PropTypes.node,
}

export default Group
