import React from 'react'
import PropTypes from 'prop-types'

import { isNumber, map, debounce, camelCase, replace, toLower } from 'lodash'

export const arrayToKeyValuePairs = (values, shouldLowerCastValue = false) => {
  const arr = []
  for (const value of values) {
    let formattedValue = value
    if (shouldLowerCastValue && !isNumber(value)) {
      formattedValue = value.toLocaleLowerCase()
    }
    arr.push({ value: formattedValue, label: value.toString() })
  }
  return arr
}

export const objectToKeyValuePairs = (object, shouldLowerCastKey = false) => {
  return map(object, (value, key) => {
    let formattedKey = key
    if (shouldLowerCastKey && !isNumber(key)) {
      formattedKey = key.toLocaleLowerCase()
    }
    return { value: formattedKey, label: value.toString() }
  })
}

export class DebouncedFormValuesSpy extends React.PureComponent {
  constructor(props) {
    super(props)

    this.handleFormStateChange = debounce(formState => {
      this.setState({ currentValues: formState.values })
    }, props.debounceBy || 500)
  }

  static props = {
    form: PropTypes.object.isRequired,
    debounceBy: PropTypes.number,
    render: PropTypes.func.isRequired,
  }

  state = {
    currentValues: null,
  }

  componentWillMount() {
    const { form } = this.props
    this.unsubscribe = form.subscribe(this.handleFormStateChange, { values: true })
  }
  componentWillUnmount() {
    if (this.unsubscribe) {
      this.unsubscribe()
    }
  }

  render() {
    const { form, render } = this.props
    const { currentValues } = this.state

    if (!currentValues) {
      return null
    }

    return render({
      ...form,
      values: currentValues,
    })
  }
}

export const formatId = name => {
  return camelCase(toLower(replace(name, '&', 'And')))
}
