import React from 'react'

import AddCircle from '@mui/icons-material/AddCircle'
import Remove from '@mui/icons-material/Remove'
import RemoveCircle from '@mui/icons-material/RemoveCircle'
import {
  Avatar,
  Box,
  Button,
  IconButton,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TableSortLabel,
  Tooltip,
  Typography,
} from '@mui/material'

import { getSourceOfInformation } from 'client-shared/components/SourceOfInformation/SourceOfInformation'

import PropertyTypeIcon from 'client-shared/components/PropertyTypeIcon'
import { Area, Template } from 'client-shared/components/Template'
import { CLEAR_ALL_LABEL, HIDE_REMOVED_LABEL, NOT_AVAILABLE, SHOW_REMOVED_LABEL } from 'report/constants'
import { calculateDistance } from 'client-shared/utils/geography'
import { forEach, get, isNil, isNumber } from 'lodash'
import {
  formatCurrencyFloat,
  formatCurrencyInt,
  formatInt,
  toPercentageString,
} from 'client-shared/utils/numberFormatters'

import Badge from '../../sales/SalesCompsSearch/Badge'
import { SALES_COMP_STOCK_PHOTO } from '../../sales/SalesCompsSearch/constants'
import { getSorting, stableSort } from '../../sales/SalesCompsSearch/tools'

import { getFormattedSaleDate, getSaleCompFullAddress } from './helpers'
import { REMOVED_COMP_HEADERS } from './constants'

class MaterialTableHeader extends React.Component {
  createSortHandler = property => event => {
    this.props.onRequestSort(event, property)
  }

  render() {
    const { order, orderBy } = this.props

    return (
      <TableHead>
        <TableRow>
          {REMOVED_COMP_HEADERS.map(row => {
            return (
              <TableCell key={row.id} sortDirection={orderBy === row.id ? order : false}>
                <TableSortLabel active={orderBy === row.id} direction={order} onClick={this.createSortHandler(row.id)}>
                  {row.label}
                </TableSortLabel>
              </TableCell>
            )
          }, this)}
          <TableCell>Actions</TableCell>
        </TableRow>
      </TableHead>
    )
  }
}

const EnhancedTableHead = MaterialTableHeader

class RemovedSalesCompsTable extends React.Component {
  state = {
    isRemovedVisible: true,
    order: 'asc',
    orderBy: 'distance',
    selected: [],
  }

  fillTableRowTemplate = ({
    actions,
    streetAddress,
    address,
    capRate,
    city,
    compId,
    compIndex,
    displaySourceOfInformation,
    distance,
    gba,
    inContract,
    isSelected = false,
    listing,
    photoElement,
    pricePerSquareFoot,
    pricePerUnit,
    saleDate,
    salePrice,
    state,
    status,
    totalUnits,
    type,
    yearBuilt,
    zip,
    isDeleted,
  }) => {
    const fillerIcon = <Remove />

    const formattedGBA = !isNil(gba) ? `${formatInt(gba)}` : fillerIcon
    return (
      <TableRow
        data-qa={isNumber(compIndex) ? `selected-comps-row-${compIndex}` : 'property-row'}
        key={`selected-comps-${compId}`}
        selected={isSelected}
      >
        <TableCell data-qa="photo-element">{photoElement}</TableCell>
        <TableCell data-qa="property-type">
          <PropertyTypeIcon propertyType={type} />
        </TableCell>
        <TableCell data-qa="distance">{distance || fillerIcon}</TableCell>
        <TableCell data-qa="address">
          {getSaleCompFullAddress({ streetAddress, state, city, zip, address })}

          {isDeleted && (
            <Tooltip placement="top" title="This comp was deleted from our database by a user.">
              <Box component="span" sx={{ position: 'relative', top: 4, left: 8 }}>
                <RemoveCircle color="error" fontSize="small" />
              </Box>
            </Tooltip>
          )}
        </TableCell>
        <TableCell data-qa="status">{status ? <Badge type={status} /> : fillerIcon}</TableCell>
        <TableCell data-qa="sale-date">{getFormattedSaleDate(listing, inContract, saleDate, fillerIcon)}</TableCell>
        <TableCell data-qa="year-built">{yearBuilt || fillerIcon}</TableCell>
        <TableCell data-qa="total-units">{totalUnits || fillerIcon}</TableCell>
        <TableCell data-qa="formatted-gba">{formattedGBA}</TableCell>
        <TableCell data-qa="sale-price">{formatCurrencyInt(salePrice) || fillerIcon}</TableCell>
        <TableCell data-qa="price-per-unit">{formatCurrencyInt(pricePerUnit) || fillerIcon}</TableCell>
        <TableCell data-qa="price-per-square-foot">{formatCurrencyFloat(pricePerSquareFoot) || fillerIcon}</TableCell>
        <TableCell data-qa="cap-rate">{toPercentageString(capRate) || fillerIcon}</TableCell>
        <TableCell data-qa="source-of-information">{displaySourceOfInformation || fillerIcon}</TableCell>
        <TableCell data-qa="removed-comp-actions">{actions || ''}</TableCell>
      </TableRow>
    )
  }

  renderTableBodyContent = () => {
    const { addComp, removeComp, salesComps = [], subjectCoordinates } = this.props
    const salesCompsTableRows = []
    const salesCompsForSorting = salesComps.map(salesComp => {
      const totalUnits = salesComp.residentialUnits + (salesComp.commercialUnits || 0)
      return {
        ...salesComp,
        addComp: () => addComp(salesComp),
        capRate: salesComp.capRate ? salesComp.capRate : NOT_AVAILABLE,
        compId: get(salesComp, '_id') || salesComp.salesEventId,
        distance: calculateDistance(subjectCoordinates, salesComp.coords),
        photoUrl: get(salesComp, 'photo.cdnUrl') || SALES_COMP_STOCK_PHOTO,
        pricePerSquareFoot: salesComp.salePrice / salesComp.gba,
        pricePerUnit: salesComp.salePrice / totalUnits,
        removeComp: () => removeComp(salesComp),
        saleDate: salesComp.saleDate,
        totalUnits,
      }
    })

    const sortedSalesComps = stableSort(salesCompsForSorting, getSorting(this.state.order, this.state.orderBy))

    forEach(sortedSalesComps, ({ addComp, removeComp, ...salesComp }, index) => {
      salesCompsTableRows.push(this.renderTableRow({ salesComp, index, removeComp, addComp }))
    })
    return salesCompsTableRows
  }

  renderTableRow = ({ addComp, index, removeComp, salesComp }) => {
    const actions = (
      <Stack alignItems="center" direction="row" spacing={1}>
        <Tooltip placement="top" title="Add">
          <IconButton aria-label="Add" data-qa="removed-comp-add-btn" onClick={addComp} size="small">
            <AddCircle color="success" />
          </IconButton>
        </Tooltip>
        <Tooltip placement="top" title="Remove">
          <IconButton aria-label="Remove" data-qa="removed-comp-remove-btn" onClick={removeComp} size="small">
            <RemoveCircle color="error" />
          </IconButton>
        </Tooltip>
      </Stack>
    )

    const photoTooltip = (
      <Avatar
        alt="Sales Comp"
        src={salesComp.photoUrl}
        sx={{ width: 100, height: 100, objectFit: 'contain' }}
        variant="square"
      />
    )

    const photoElement = (
      <Tooltip placement="left" title={photoTooltip}>
        <Avatar
          alt="Sales Comp"
          src={salesComp.photoUrl}
          sx={{ width: 36, height: 36, objectFit: 'cover' }}
          variant="square"
        />
      </Tooltip>
    )

    const displaySourceOfInformation = getSourceOfInformation(salesComp)

    return this.fillTableRowTemplate({
      actions,
      streetAddress: salesComp.streetAddress,
      address: salesComp.address,
      capRate: salesComp.capRate,
      city: salesComp.city,
      compId: salesComp.compId || index,
      compIndex: index,
      displaySourceOfInformation,
      distance: `${salesComp.distance} mi`,
      gba: formatInt(salesComp.gba),
      inContract: salesComp.inContract,
      listing: salesComp.listing,
      photoElement,
      photoTooltip,
      pricePerSquareFoot: salesComp.pricePerSquareFoot,
      pricePerUnit: salesComp.pricePerUnit,
      saleDate: salesComp.saleDate,
      salePrice: formatCurrencyInt(salesComp.salePrice),
      state: salesComp.state,
      status: salesComp.status,
      totalUnits: salesComp.totalUnits,
      type: salesComp.type,
      yearBuilt: salesComp.yearBuilt,
      zip: salesComp.zip,
      isDeleted: !!salesComp.deletedAt,
    })
  }

  handleRequestSort = (event, property) => {
    const orderBy = property
    let order = 'desc'

    if (this.state.orderBy === property && this.state.order === 'desc') {
      order = 'asc'
    }

    this.setState({ order, orderBy })
  }

  toggleRemovedVisibility = () => {
    this.setState(prevState => ({
      isRemovedVisible: !prevState.isRemovedVisible,
    }))
  }

  render() {
    const { clearRemovedTable, disabled, title } = this.props
    const { isRemovedVisible } = this.state

    return (
      <Paper>
        <Template
          is={`
            'title tools'
            'table table'
            /auto  1fr
          `}
          gap={2}
        >
          <Area is="title">
            <Typography variant="h6" gutterBottom>
              {title}
            </Typography>
          </Area>
          <Area is="tools" gap={2} placeContent="flex-end" placeItems="center">
            <Button size="small" color="primary" disabled={disabled} onClick={this.toggleRemovedVisibility}>
              {isRemovedVisible ? HIDE_REMOVED_LABEL : SHOW_REMOVED_LABEL}
            </Button>
            <Button size="small" color="primary" disabled={disabled} onClick={clearRemovedTable}>
              {CLEAR_ALL_LABEL}
            </Button>
          </Area>
          <Area is="table">
            {!disabled && isRemovedVisible && (
              <Table size="small" data-qa="removed-sales-comps-table">
                <EnhancedTableHead
                  onRequestSort={this.handleRequestSort}
                  order={this.state.order}
                  orderBy={this.state.orderBy}
                />
                <TableBody>{this.renderTableBodyContent()}</TableBody>
              </Table>
            )}
          </Area>
        </Template>
      </Paper>
    )
  }
}

export default RemovedSalesCompsTable
