import filterFP from 'lodash/fp/filter'
import flowFP from 'lodash/fp/flow'
import mapFP from 'lodash/fp/map'
import orderByFP from 'lodash/fp/orderBy'

import BoweryDate from '@bowery-valuation/bowery-date'

export const orderSalesHistoryByDate = salesHistory =>
  flowFP([
    filterFP(sale => !!sale.date),
    mapFP(sale => ({
      ...(sale.toObject ? sale.toObject() : sale),
      date: new BoweryDate(sale.date).date,
    })),
    orderByFP(['date'], ['desc']),
  ])(salesHistory)
