import { handleActions } from 'redux-actions'

import {
  expenseCompsFetchRequest,
  expenseCompsFetchReceive,
  expenseCompsFetchFail,
  expenseCompsClear,
  selectExpenseComp,
  deselectExpenseComp,
  setSelectedExpenseComps,
} from './actions'

const initialState = {
  expenseComps: [],
  totalCount: 0,
  isLoading: false,
  isLoaded: false,
  selectedExpenseComps: [],
  availableLocations: [],
}

export const reducer = handleActions(
  {
    [expenseCompsFetchRequest](state) {
      return {
        ...state,
        isLoading: true,
      }
    },
    [expenseCompsFetchReceive](state, { payload: { expenseComps, totalCount, availableLocations } }) {
      return {
        ...state,
        availableLocations,
        expenseComps,
        totalCount,
        isLoading: false,
        isLoaded: true,
      }
    },
    [expenseCompsFetchFail](state) {
      return {
        ...state,
        isLoading: false,
      }
    },
    [expenseCompsClear]() {
      return {
        ...initialState,
      }
    },
    [selectExpenseComp](state, { payload }) {
      const { expenseComp } = payload
      const { selectedExpenseComps } = state

      return {
        ...state,
        selectedExpenseComps: [...selectedExpenseComps, expenseComp],
      }
    },
    [deselectExpenseComp](state, { payload }) {
      const { id } = payload
      const { selectedExpenseComps } = state

      return {
        ...state,
        selectedExpenseComps: selectedExpenseComps.filter(expenseComp => expenseComp._id !== id),
      }
    },
    [setSelectedExpenseComps](state, { payload }) {
      return {
        ...state,
        expenseComps: payload,
      }
    },
  },
  initialState
)
