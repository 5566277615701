export const timeSpentEvent = (key, startTime, completionTime, data, user) => {
  const timeSpentMillis = completionTime - startTime
  const event = {
    name: 'timeSpent',
    label: `Time Spent`,
    timestamp: new Date(),
    userId: user._id,
    properties: {
      ...data,
      startTime,
      completionTime,
      timeSpentMillis,
      timeSpent: timeSpentMillis / 1000,
      key,
      user: {
        fullName: user.fullName,
        firstName: user.name?.first,
        lastName: user.name?.last,
        username: user.username,
      },
    },
  }

  return event
}
