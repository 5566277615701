import React from 'react'
import PropTypes from 'prop-types'

import { get } from 'lodash'
import { Button, Grid, Paper } from '@mui/material'

import * as Socket from 'client-shared/utils/socket'
import { ExportStatuses } from 'shared/constants/export'

import { PHOTOGRID_TEMPLATE } from '../constants'

import ExportPhotoGridView from './ExportPhotoGridView'

class ExportPhotoGrid extends React.PureComponent {
  state = {
    baseTemplateFileName: PHOTOGRID_TEMPLATE,
  }

  componentDidMount() {
    Socket.on('report:export', this.onReportExport)
    const exportItems = get(this, 'props.exportItems', [])
    const { json } = this.props
    if (
      exportItems.length === 0 ||
      JSON.stringify(json) !== JSON.stringify(exportItems[0].data) ||
      (exportItems[0].status !== ExportStatuses.PENDING && exportItems[0].status !== ExportStatuses.COMPLETE)
    ) {
      this.generateReportFromJSON()
    }
  }

  componentWillUnmount() {
    Socket.off('report:export', this.onReportExport)
  }

  onReportExport = exportStatus => {
    const { updateStatus } = this.props
    updateStatus(exportStatus)
  }

  generateReportFromJSON = () => {
    const { baseTemplateFileName } = this.state
    const { reportId, json, generateReportFromJSON } = this.props

    generateReportFromJSON({ reportId, json, baseTemplateFileName })
  }

  generateXML = () => {
    const { generateXML } = this.props
    generateXML()
  }

  render() {
    const { lastCompletedExportItemIndex, allowReExportPendingAfter, allowReExportAfter } = this.props
    const exportItems = get(this, 'props.exportItems', [])
    // show re-export button if there is a stuck pending export
    const now = new Date()
    const showExportButton =
      exportItems.length > 0 &&
      ((exportItems[0].status === ExportStatuses.PENDING &&
        now - new Date(exportItems[0].createdAt) > allowReExportPendingAfter * 1000) ||
        now - new Date(exportItems[0].createdAt) > allowReExportAfter * 1000)
    return (
      <Paper>
        <Grid container direction="row" justify="space-between" spacing={1}>
          {showExportButton && (
            <Grid item container direction="row" xs={12}>
              <Grid item xs={12}>
                <Button variant="contained" data-qa="generate-report-btn" onClick={this.generateReportFromJSON}>
                  Re-Export Photo Grid
                </Button>
              </Grid>
            </Grid>
          )}

          <ExportPhotoGridView
            {...{
              exportItems,
              lastCompletedExportItemIndex,
            }}
          />
        </Grid>
      </Paper>
    )
  }
}

ExportPhotoGrid.propTypes = {
  exportItems: PropTypes.arrayOf(PropTypes.object.isRequired),
  reportId: PropTypes.string.isRequired,
  json: PropTypes.object.isRequired,
  lastCompletedExportItemIndex: PropTypes.number,
  generateReportFromJSON: PropTypes.func.isRequired,
  updateStatus: PropTypes.func.isRequired,
  allowReExportPendingAfter: PropTypes.number,
  allowReExportAfter: PropTypes.number,
}

ExportPhotoGrid.defaultProps = {
  exportItems: [],
  lastCompletedExportItemIndex: null,
  allowReExportPendingAfter: 300, // seconds
  allowReExportAfter: 3600, // seconds
}

export default ExportPhotoGrid
