import React from 'react'
import PropTypes from 'prop-types'
import { startCase } from 'lodash'
import { Field } from 'react-final-form'
import { withProps, mapProps, compose } from 'recompose'
import { Typography, Stack, Button, Box } from '@mui/material'

import BoweryDate from '@bowery-valuation/bowery-date'

import { Callout } from 'client-shared/components'

import { AppraisalInstituteDesignationTypes } from '../../constants/organization'

import AppraisalInstituteDesignationModal from './AppraisalInstituteDesignationModal'
import AppraisalEducationStatusText from './AppraisalEducationStatusText'

export class AppraisalInstituteDesignation extends React.PureComponent {
  state = {
    open: false,
  }

  handleModalOpen = () => {
    this.setState({ open: true })
  }

  handleModalClose = () => {
    this.setState({ open: false })
  }

  handleConfirm = ({ designation, validUntil, educationRequirementsCompleted }) => {
    const educationRequirements =
      designation === AppraisalInstituteDesignationTypes.NONE ? null : educationRequirementsCompleted
    this.props.onChange({
      designation,
      validUntil: educationRequirementsCompleted ? validUntil : null,
      educationRequirementsCompleted: educationRequirements,
    })
    this.handleModalClose()
  }

  render() {
    const { designation, validUntil, educationRequirementsCompleted } = this.props
    const showAdditionalInfo = designation !== AppraisalInstituteDesignationTypes.NONE
    return (
      <Stack spacing={2}>
        <Typography variant="h6">Appraisal Institute (AI) Membership</Typography>
        <Stack direction="row" justifyContent="space-between" sx={{ maxWidth: 500 }}>
          <Typography variant="body2">
            AI Designation:{' '}
            <Typography variant="body1" component="span">
              {startCase(designation)}
            </Typography>
          </Typography>
          {showAdditionalInfo && validUntil && (
            <Typography variant="body2">
              Valid Until:{' '}
              <Typography variant="body1" component="span">
                {new BoweryDate(validUntil).formatShortDateWithZeroes()}
              </Typography>
            </Typography>
          )}
        </Stack>
        {showAdditionalInfo && (
          <Callout
            variant={educationRequirementsCompleted ? 'success' : 'warn'}
            content={
              <AppraisalEducationStatusText
                designation={designation}
                educationRequirementsCompleted={educationRequirementsCompleted}
              />
            }
          />
        )}
        <Box>
          <Button variant="contained" color="primary" onClick={this.handleModalOpen}>
            Change
          </Button>
        </Box>
        <AppraisalInstituteDesignationModal
          validUntil={validUntil}
          designation={designation}
          educationRequirementsCompleted={educationRequirementsCompleted}
          onConfirm={this.handleConfirm}
          onCancel={this.handleModalClose}
          opened={this.state.open}
        />
      </Stack>
    )
  }
}

AppraisalInstituteDesignation.propTypes = {
  designation: PropTypes.string,
  educationRequirementsCompleted: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  validUntil: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
}

AppraisalInstituteDesignation.defaultProps = {
  designation: AppraisalInstituteDesignationTypes.NONE,
  educationRequirementsCompleted: null,
  validUntil: null,
}

const AppraisalInstituteDesignationMappedProps = compose(
  mapProps(({ input = {}, meta, ...props }) => ({
    ...(input.value || {}),
    onChange: input.onChange,
    ...props,
  }))
)(AppraisalInstituteDesignation)

export default withProps(props => ({
  component: AppraisalInstituteDesignationMappedProps,
  name: 'appraisalInstituteDesignation',
  ...props,
}))(Field)
