import React from 'react'
import PropTypes from 'prop-types'

import { withStyles } from '@mui/styles'
import { compose } from 'recompose'

import { HEADER_HEIGHT, HEADER_Z_INDEX } from '../constants'
import layoutStyles from '../styles'

import Logo from './Logo'

const styles = theme => ({
  root: {
    top: 0,
    display: 'flex',
    position: 'sticky',
    zIndex: HEADER_Z_INDEX,
    height: HEADER_HEIGHT,
    backgroundColor: theme.palette.common.white,
    borderBottom: layoutStyles.border,
    boxSizing: 'border-box',
  },
  title: {
    fontSize: 20,
    fontWeight: 600,
    fontFamily: 'Nunito Sans',
    padding: '18px 24px',
    color: '#222',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
  },
  headerContent: {
    flexGrow: 1,
    display: 'flex',
    alignItems: 'center',
    paddingLeft: theme.spacing(1),
  },
})

const Header = ({ classes, title, children }) => (
  <header className={classes.root}>
    <Logo />
    <span className={classes.title}>{title}</span>
    <div className={classes.headerContent}>{children}</div>
  </header>
)

Header.propTypes = {
  title: PropTypes.string,
  children: PropTypes.node,
}

Header.defaultProps = {
  children: null,
}

export default compose(React.memo, withStyles(styles))(Header)
