import React, { useState } from 'react'
import { useForm } from 'react-final-form'
import { get } from 'lodash'
import { makeStyles } from '@mui/styles'

import * as Api from '../../../../core/api'
import { Loading } from '../../../../shared/components'
import { DIALOG_Z_INDEX } from '../../../../shared/constants'
import useFieldValue from '../../../utils/useFieldValue'

import SaveDialog from './SaveDialog'
import MapWizard from './MapWizard'

const useStyles = makeStyles({
  loading: {
    position: 'fixed',
    zIndex: DIALOG_Z_INDEX + 1,
  },
})

const ComparableMap = ({ name, renderCustomActions }) => {
  const [isLoading, setIsLoading] = useState(false)
  const [stagedMapInfo, setStagedMapInfo] = useState({})
  const [showSaveDialog, setShowSaveDialog] = useState(false)

  const classes = useStyles()
  const map = useFieldValue(name)
  const formApi = useForm()

  const handleMapSave = ({ canvas, properties, closeMapWizard }) => {
    setStagedMapInfo({ canvas, properties, closeMapWizard })
    setShowSaveDialog(true)
  }

  const uploadImage = async image => {
    const response = await Api.uploadImages({ images: [image] })
    return get(response, 'images.0.cdnUrl')
  }

  const handleSave = ({ label }) => {
    const { canvas, properties, closeMapWizard } = stagedMapInfo

    setShowSaveDialog(false)
    setIsLoading(true)

    canvas.toBlob(async image => {
      const imageUrl = await uploadImage(image)

      formApi.batch(() => {
        formApi.change(`${name}.image`, imageUrl)
        formApi.change(`${name}.label`, label)
        formApi.change(`${name}.properties`, properties)
      })

      setIsLoading(false)
      setStagedMapInfo({})
      closeMapWizard()
    }, 'image/png')
  }

  const handleCancel = () => {
    setShowSaveDialog(false)
    setStagedMapInfo({})
  }

  return (
    <>
      <MapWizard
        name={`${name}.image`}
        label={map.label}
        initialProperties={map.properties}
        renderCustomActions={renderCustomActions}
        onSave={handleMapSave}
      />
      <SaveDialog
        open={showSaveDialog}
        initialLabel={map.label}
        onSave={handleSave}
        onCancel={handleCancel}
        title="Save Map"
      />
      {isLoading && <Loading classes={{ root: classes.loading }} />}
    </>
  )
}

export default ComparableMap
