import React from 'react'
import { isNil } from 'lodash'
import { Block, Data, Inline, Text } from 'slate'
import { Editor, Plugin, Plugins } from 'slate-react'

import { getSuggestedInput } from 'client-shared/components/NarrativeComponent/helpers'
import Locked from 'client-shared/components/NarrativeComponent/plugins/Locked'
import ConditionalInline from 'client-shared/components/NarrativeComponent/plugins/ConditionalInline'

import { formatChipValue, searchChipOptions } from './helpers'
import { ChipOption } from './types'
import ChipOptions from './ChipOptions'

type getChipPluginOptions = {
  onOptionsHidden?: () => void
  onOptionSelected?: (chip: ChipOption) => void
  chipOptions: ChipOption[]
  targetElement?: HTMLElement
}

export const CHIP_PLUGIN_DATA = 'CHIP_PLUGIN_DATA'

const getChipPlugin = ({
  chipOptions,
  onOptionsHidden,
  onOptionSelected,
  targetElement,
}: getChipPluginOptions): Plugins<Editor> => {
  const ChipPlugin: Plugin<Editor> = {
    onChange(editor, next) {
      const suggestedInputValue = getSuggestedInput(editor.value)

      if (suggestedInputValue) {
        const searchQuery = suggestedInputValue[1]
        const searchResults = searchChipOptions(searchQuery, chipOptions)

        if (searchResults.length === 1) {
          if (searchResults[0].displayName === searchQuery) {
            editor.insertChip(searchResults[0])
            editor.setData(
              Data.create({
                [CHIP_PLUGIN_DATA]: {
                  inSearchMode: false,
                  searchResults: chipOptions,
                  searchQuery: '',
                },
              })
            )
          } else {
            editor.setData(Data.create({ [CHIP_PLUGIN_DATA]: { inSearchMode: true, searchResults, searchQuery } }))
          }
        } else if (searchResults.length > 1) {
          editor.setData(
            Data.create({
              [CHIP_PLUGIN_DATA]: {
                inSearchMode: true,
                searchResults,
                searchQuery,
              },
            })
          )
        } else {
          // Hide the auto suggest if there are no results
          editor.setData(
            Data.create({
              [CHIP_PLUGIN_DATA]: {
                inSearchMode: false,
                searchResults,
                searchQuery,
              },
            })
          )
        }
      } else {
        editor.setData(
          Data.create({
            [CHIP_PLUGIN_DATA]: {
              inSearchMode: false,
              searchResults: chipOptions,
              searchQuery: '',
            },
          })
        )
      }

      next()
    },

    renderEditor(props, editor, next) {
      const children = next()

      const editorData = editor.value.data.toJS()
      const { inSearchMode, searchResults, searchQuery } = editorData[CHIP_PLUGIN_DATA] || {}

      return (
        <ChipOptions
          inSearchMode={inSearchMode}
          searchResults={searchResults}
          searchQuery={searchQuery}
          select={editor._selectChip}
          hide={editor._hideChipOptions}
          targetElement={targetElement}
        >
          {children}
        </ChipOptions>
      )
    },
    commands: {
      insertChip(editor, suggestion: ChipOption) {
        const value = editor.value

        let inputValue = getSuggestedInput(value)
        if (!inputValue) {
          console.warn('No input value in insertSuggestion', value)

          inputValue = getSuggestedInput(editor.value)
          if (!inputValue) {
            console.warn('No input value in insertSuggestion with editor value', editor.value)
            return editor
          }
        }

        // TODO: Conditional should handle delete so this plugin doesn't need to know about it.
        // For reasons I don't understand, sometimes the user's text is to the left of a hidden conditional while the
        // selection is to the right, so we're potentially moving the selection left before deleting the user's text
        editor.moveFocusBeforeConditional()
        editor.deleteBackward(inputValue[1].length + 1)
        const selectedRange = editor.value.selection

        if (!suggestion.dataPath) {
          if (suggestion.text) {
            editor.insertTextAtRange(selectedRange, suggestion.text)
          }

          return editor
        }

        let suggestedValue = suggestion.value

        if (isNil(suggestedValue)) {
          suggestedValue = suggestion.displayName
        } else if (suggestion.formatter) {
          suggestedValue = formatChipValue(suggestion) ?? suggestion.displayName
        }

        if (Array.isArray(suggestedValue)) {
          editor.insertBlockAtRange(
            selectedRange,
            Block.create({
              data: {
                fieldName: suggestion.dataPath,
                format: suggestion.formatter,
                error: false,
              },
              nodes: suggestedValue.map(value =>
                Block.create({ type: 'paragraph', nodes: [Text.create({ text: String(value) })] })
              ),
              type: 'locked',
            })
          )
        } else {
          editor.insertTextAtRange(selectedRange, '')
          editor.insertInlineAtRange(
            selectedRange,
            Inline.create({
              data: {
                fieldName: suggestion.dataPath,
                format: suggestion.formatter,
                error: suggestion.displayName === suggestedValue,
              },
              nodes: [Text.create({ text: String(suggestedValue) })],
              type: 'locked',
            })
          )
          editor.insertTextAtRange(selectedRange, '')
        }

        return editor
      },

      clearChip(editor, shouldMoveAnchor = false) {
        const suggestedInput = getSuggestedInput(editor.value)
        const regexp = /(=)(\w+)|(=)/
        const chip = regexp.exec(editor.value.startText.text)

        const deleteChip = (index: number, range: number) => {
          editor.moveTo(index)
          editor.deleteForward(range)
        }

        if (shouldMoveAnchor && chip) {
          deleteChip(chip.index, chip[0]?.length)
        } else if (!isNil(suggestedInput)) {
          deleteChip(suggestedInput.index, suggestedInput[1].length + 1)
        }

        return editor
      },

      _selectChip(editor, chipOption: ChipOption) {
        const editorData = editor.value.data.toJS()
        const { searchResults, searchQuery } = editorData[CHIP_PLUGIN_DATA] || {}
        editor.insertChip(chipOption)
        editor.setData(
          Data.create({
            [CHIP_PLUGIN_DATA]: {
              inSearchMode: false,
              searchResults,
              searchQuery,
            },
          })
        )
        editor.focus()
        onOptionSelected?.(chipOption)
        return editor
      },
      _hideChipOptions(editor) {
        const editorData = editor.value.data.toJS()

        const { searchResults, searchQuery } = editorData[CHIP_PLUGIN_DATA] || {}

        editor.clearChip(true)
        editor.setData(
          Data.create({
            [CHIP_PLUGIN_DATA]: {
              inSearchMode: false,
              searchResults,
              searchQuery,
            },
          })
        )
        onOptionsHidden?.()
        return editor
      },
    },
  }

  return [ChipPlugin, ConditionalInline, Locked]
}

export default getChipPlugin

declare module 'slate' {
  interface Editor {
    insertChip(suggestion: ChipOption): Editor
    clearChip(shouldMoveAnchor?: boolean): Editor
  }
}

declare module 'slate-react' {
  interface Editor {
    insertChip(suggestion: ChipOption): Editor
    clearChip(shouldMoveAnchor?: boolean): Editor
    _selectChip(chipOption: ChipOption): Editor
    _hideChipOptions(): Editor
  }
}
