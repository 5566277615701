import React from 'react'
import PropTypes from 'prop-types'

import { startCase } from 'lodash'

import WarningDialog from 'client-shared/components/WarningDialog'

const SubjectPropertyClearAllModal = ({ clearModalOpened, onSubmit, onCancel, propertyCategory }) => (
  <WarningDialog
    onCancel={onCancel}
    onSubmit={onSubmit}
    open={clearModalOpened}
    text={`If you clear all ${propertyCategory} your data will be lost. This action cannot be undone.`}
    title={`Clear ALL SWOT ${startCase(propertyCategory)}`}
  />
)

SubjectPropertyClearAllModal.propTypes = {
  clearModalOpened: PropTypes.bool.isRequired,
  onCancel: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  propertyCategory: PropTypes.string.isRequired,
}

export default SubjectPropertyClearAllModal
