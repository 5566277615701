import { get, isEmpty, invert } from 'lodash'

import { ROOF_AND_STAIRS_CONDITION_OPTIONS } from '../../../../libs/bowery-libs/constants/report'
import { GENERAL_CONDITIONS } from '../../../constants/property/description'
import { TEMPLATE_TYPES } from '../../../../client/src/report/constants/settings'

export const normalizePropertyCondition = condition => {
  if (condition.value === GENERAL_CONDITIONS.other) {
    return condition.other
  }

  return condition.value
}

export const getRoofAndStairsConditionValue = (object, accessor, defaultValue = 1) => {
  const value = get(object, accessor, defaultValue)
  return ROOF_AND_STAIRS_CONDITION_OPTIONS[value]
}

export const getRoofConditionValue = (object, accessor) => {
  const value = get(object, accessor)
  if (value === 0) {
    return 'Unable to inspect the roof'
  }

  return getRoofAndStairsConditionValue(object, accessor)
}

export const getUpdateRoofAndStairsConditionValue = (object, accessor, defaultValue = 'Poor') => {
  const value = get(object, accessor, defaultValue)
  return invert(ROOF_AND_STAIRS_CONDITION_OPTIONS)[value]
}

export const getSiteInspector = report => {
  const potentialInspectors = [
    ...get(report, 'organizationInfo.appraisers', []).map(appraiserInfo => {
      return {
        fullName: appraiserInfo.appraiser.fullName,
        isInspected: appraiserInfo.performedInspection,
        isExternal: false,
        appraisalCertifications: appraiserInfo.appraiser.appraisalCertifications,
        certification: appraiserInfo.certification,
      }
    }),
    ...get(report, 'external_inspectors', []).map(inspector => {
      return {
        fullName: inspector.fullName,
        isInspected: inspector.isInspected,
        isExternal: inspector.isExternal,
      }
    }),
  ]

  const isFreddieMac = get(report, 'templateType') === TEMPLATE_TYPES.FREDDIE_MAC
  const reportState = get(report, 'property_information.state')

  const appraiserIsCertifiedInState = appraiser => {
    const appraiserCerts = get(appraiser, 'appraisalCertifications') || []
    if (isEmpty(appraiserCerts)) {
      return false
    }
    const selectedCertificationState = get(appraiser, 'certification.state')
    return selectedCertificationState === reportState
  }

  return (
    potentialInspectors.find(potentialInspector =>
      isFreddieMac
        ? appraiserIsCertifiedInState(potentialInspector) && potentialInspector.isInspected
        : !!potentialInspector.isInspected
    ) || {}
  )
}
