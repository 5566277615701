import { connect } from 'react-redux'

import Notifications from './Notifications'

function mapStateToProps(state) {
  return {
    notifications: state.shared.notifications,
  }
}

export default connect(mapStateToProps)(Notifications)
