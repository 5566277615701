import React from 'react'

import { Grid } from '@mui/material'

import {
  getCostApproach,
  getIncomeApproach,
  getSalesCompApproach,
  getDescriptionOfMethodologies,
} from 'shared/utils/textGeneration/appraisalValuationProcess'

import NarrativeComponent from '../../shared/components/NarrativeComponent'

import withCMSForm from './withCMSForm'

const GRID_CONTAINER_GUTTER = 2

const AppraisalValuationProcess = () => {
  return (
    <Grid container spacing={GRID_CONTAINER_GUTTER}>
      <Grid item xs={12}>
        <NarrativeComponent
          title="Cost Approach"
          generatedText={getCostApproach}
          name="appraisalValuationProcess.costApproach"
        />
      </Grid>
      <Grid item xs={12}>
        <NarrativeComponent
          title="Income Approach"
          generatedText={getIncomeApproach}
          name="appraisalValuationProcess.incomeApproach"
        />
      </Grid>
      <Grid item xs={12}>
        <NarrativeComponent
          title="Sales Comparison Approach"
          generatedText={getSalesCompApproach}
          name="appraisalValuationProcess.salesApproach"
        />
      </Grid>
      <Grid item xs={12}>
        <NarrativeComponent
          title="Description of Methodologies"
          generatedText={getDescriptionOfMethodologies}
          name="appraisalValuationProcess.descriptionOfMethodologies"
        />
      </Grid>
    </Grid>
  )
}

export default withCMSForm(AppraisalValuationProcess, 'Appraisal Valuation Process')
