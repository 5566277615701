import React from 'react'
import arrayMutators from 'final-form-arrays'
import { useFieldArray } from 'react-final-form-arrays'
import { invert } from 'lodash'
import ObjectID from 'bson-objectid'
import { Grid } from '@material-ui/core'
import { makeStyles } from '@mui/styles'

import { COMPARABLE_MAPS_PATH } from 'shared/constants/report/keysAndDataPaths'

import wrapForm from '../../wrapForm'
import useFieldValue from '../../../utils/useFieldValue'
import { Button } from '../../../../shared/components'
import { STATE_NAMES } from '../../../../shared/constants/geography'

import ComparableMap from './ComparableMap'
import ComparableMapActions from './ComparableMapActions'

import 'leaflet/dist/leaflet.css'
import 'react-leaflet-markercluster/dist/styles.min.css'

export const DATA_PATH = COMPARABLE_MAPS_PATH

const heading = 'Comparable Maps'

const stateAbbreviations = invert(STATE_NAMES)

const useStyles = makeStyles({
  addMapButton: {
    marginLeft: 0,
    marginBottom: 25,
  },
})

const getNewMap = ({ address, city, state, zip, country, coords }) => {
  const stateAbbreviation = stateAbbreviations[state]
  const correctedCity = city === 'New York' && stateAbbreviation === 'NY' ? 'Manhattan' : city

  const subjectProperty = {
    id: new ObjectID().toString(),
    streetAddress: address,
    city: correctedCity,
    state: stateAbbreviation,
    country,
    zip,
    coords: [coords.latitude, coords.longitude],
    isSubject: true,
    compIndex: null,
  }

  return {
    label: 'Map',
    properties: [subjectProperty],
    image: '',
    id: new ObjectID().toString(),
  }
}

const ComparableMaps = () => {
  const classes = useStyles()
  const maps = useFieldArray('maps', {})
  const subjectProperty = useFieldValue('subjectProperty')

  const addMap = () => {
    maps.fields.unshift(getNewMap(subjectProperty))
  }

  const renderCustomActions =
    index =>
    ({ hasImage, openMapWizard }) => {
      return <ComparableMapActions mapIndex={index} hasImage={hasImage} openMapWizard={openMapWizard} />
    }

  return (
    <>
      <Button color="primary" variant="contained" onClick={addMap} className={classes.addMapButton}>
        Add New Comparable Map
      </Button>

      <Grid container spacing={16}>
        {maps.fields.map((name, index) => {
          const map = maps.fields.value[index]
          return (
            <Grid item xs={12} lg={6} key={map.id}>
              <ComparableMap name={name} renderCustomActions={renderCustomActions(index)} />
            </Grid>
          )
        })}
      </Grid>
    </>
  )
}

export default wrapForm(DATA_PATH, { heading, mutators: { ...arrayMutators } })(ComparableMaps)
