import PlaceOutlinedIcon from '@mui/icons-material/PlaceOutlined'
import MapOutlinedIcon from '@mui/icons-material/MapOutlined'
import InsertPhotoOutlinedIcon from '@mui/icons-material/InsertPhotoOutlined'
import BookmarkBorderOutlinedIcon from '@mui/icons-material/BookmarkBorderOutlined'
import StickyNote2OutlinedIcon from '@mui/icons-material/StickyNote2Outlined'
import GMobiledataOutlinedIcon from '@mui/icons-material/GMobiledataOutlined'

export const Layout = {
  DRAWER_WIDTH: 560,
  MENU_WIDTH: 64,
  DRAWER_HEADER_HEIGHT: 56,
}

export const DrawerSections = {
  GOOGLE_MAPS: 'googleMaps',
  MAP_GALLERY: 'mapGallery',
  PHOTO_GALLERY: 'photoGallery',
  BOOKMARKS: 'bookmarks',
  NOTES: 'notes',
  CONTENT_LIBRARY: 'contentLibrary',
  GERI: 'geri',
}

export const DrawerMenuItems = [
  { name: DrawerSections.GOOGLE_MAPS, icon: PlaceOutlinedIcon, tooltip: 'Google Maps' },
  { name: DrawerSections.MAP_GALLERY, icon: MapOutlinedIcon, tooltip: 'Map Gallery' },
  { name: DrawerSections.PHOTO_GALLERY, icon: InsertPhotoOutlinedIcon, tooltip: 'Photo Gallery' },
  { name: DrawerSections.BOOKMARKS, icon: BookmarkBorderOutlinedIcon, tooltip: 'Bookmarked Pages' },
  { name: DrawerSections.NOTES, icon: StickyNote2OutlinedIcon, tooltip: 'Notes' },
  { name: DrawerSections.GERI, icon: GMobiledataOutlinedIcon, tooltip: 'Geri' },
]

export const GOOGLE_MAPS_SHORTCUTS = ['ctrl+1', 'command+1']
export const MAP_GALLERY_SHORTCUTS = ['ctrl+2', 'command+2']
export const PHOTO_GALLERY_SHORTCUTS = ['ctrl+3', 'command+3']
export const BOOKMARKS_SHORTCUTS = ['ctrl+4', 'command+4']
export const NOTES_SHORTCUTS = ['ctrl+5', 'command+5']
export const GERI_SHORTCUTS = ['ctrl+6', 'command+6']
