import { Link } from '@mui/material'
import { uniq } from 'lodash'
import React, { useCallback, useEffect, useState } from 'react'

import PropTypes from 'prop-types'

import { connect } from 'react-redux'

import { withStyles } from '@mui/styles'

import { compose } from 'recompose'

import { mapSubjectProperty } from '../helpers'

import { adjustmentDefinitions, groupAdjustmentWarningsByAddress } from './adjustments'

const styles = theme => {
  return {
    warningsList: {
      '& li:has(ul)': {
        listStyleType: 'none',
      },
      '& li li': {
        listStyleType: 'disc',
      },
    },
  }
}

const AdjustmentAutomationWarning = ({ selectedComps, reportId, classes, showEditModal, subjectProperty }) => {
  const [warnings, setWarnings] = useState([])

  const updateWarnings = useCallback(async () => {
    const updatedCompWarningsUnflattened = await Promise.all(
      adjustmentDefinitions.map(adjustmentDefinition => {
        return adjustmentDefinition.getWarnings(selectedComps, subjectProperty)
      })
    )

    setWarnings(updatedCompWarningsUnflattened.flat())
  }, [selectedComps, subjectProperty])

  useEffect(() => {
    updateWarnings()
  }, [updateWarnings])

  if (!warnings || warnings.length === 0) {
    return null
  }
  const { subjectWarning, compWarnings } = groupAdjustmentWarningsByAddress(warnings)

  return (
    <div className="automation-warning">
      <ul className={classes.warningsList}>
        {subjectWarning && (
          <>
            <li>Subject is missing:</li>
            <li>
              <ul>
                <li>
                  {subjectWarning.warningLinks
                    .map(field => {
                      const href = field.href.replace(':reportId', reportId)
                      return (
                        <Link key={href} style={{ cursor: 'pointer' }} href={href}>
                          Edit {field.fields}
                        </Link>
                      )
                    })
                    .reduce((list, link) => {
                      if (list === null) {
                        return link
                      }

                      return (
                        <>
                          {list} | {link}
                        </>
                      )
                    }, null)}
                </li>
              </ul>
            </li>
          </>
        )}
        {compWarnings.length > 0 && (
          <>
            <li>Sales comparables are missing:</li>
            <li>
              <ul>
                {compWarnings.map(compWarningGroup => {
                  const allFields = uniq(
                    compWarningGroup.adjustmentWarnings.flatMap(adjustmentWarning => adjustmentWarning.fields)
                  )
                  return (
                    <li key={compWarningGroup.address}>
                      <b>{compWarningGroup.address}</b> -{' '}
                      <Link
                        style={{ cursor: 'pointer' }}
                        onClick={() => {
                          showEditModal(selectedComps[compWarningGroup.index])
                        }}
                      >
                        Edit {allFields.join(', ')}
                      </Link>
                    </li>
                  )
                })}
              </ul>
            </li>
          </>
        )}
      </ul>
    </div>
  )
}

AdjustmentAutomationWarning.propTypes = {
  warnings: PropTypes.array,
  selectedComps: PropTypes.array,
  reportId: PropTypes.string,
  classes: PropTypes.object,
  showEditModal: PropTypes.func,
  subjectProperty: PropTypes.object,
}

const mapStateToProps = state => {
  return {
    subjectProperty: mapSubjectProperty(state),
  }
}

export default compose(connect(mapStateToProps), withStyles(styles))(AdjustmentAutomationWarning)
