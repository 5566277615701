import { defaultProps, compose } from 'recompose'
import { withStyles } from '@material-ui/core'

import DropDown from '../DropDown/NativeDropDownField'

export default compose(
  withStyles(theme => ({
    formControl: {
      maxHeight: 32,
      '& fieldset': {
        borderColor: 'transparent !important',
        padding: '0 5px',
      },
      '& div[role="button"]:focus': {
        backgroundColor: 'transparent',
      },
      justifyContent: 'center',
    },
    select: {
      maxHeight: 32,
      fontSize: '14px',
    },
    menuPaper: {
      marginTop: 12,
      marginLeft: -12,
      padding: 0,
      paddingLeft: 12,
      '& ul': {
        marginLeft: -12,
        width: 'calc(100% + 12px)',
      },
    },
    menuOption: {
      fontSize: '14px',
    },
  })),
  defaultProps({
    margin: 'none',
    disablePortal: true,
  })
)(DropDown)
