import React from 'react'

import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  Button,
  Stack,
  Typography,
  List,
  ListItem,
  Link,
  Box,
} from '@mui/material'

import DialogHeader from 'client-shared/components/DialogHeader'

type SourceLinksModalProps = {
  open: boolean
  sourceLinks: {
    report: {
      id: string
      address: string
    }
    comps: string[]
  }[]
  generateSourceLink: (reportId: string) => string
  onClose: () => void
}

const SourceLinksModal: React.FC<SourceLinksModalProps> = ({ open, sourceLinks, generateSourceLink, onClose }) => {
  return (
    <Dialog fullWidth open={open} data-qa="expense-comps-source-links-dialog" onClose={onClose}>
      <DialogHeader onClose={onClose}>
        <Typography variant="h6" component="span">
          Bowery Appraisal Reports
        </Typography>
      </DialogHeader>
      <DialogContent sx={{ paddingTop: 1, paddingBottom: 2 }}>
        <DialogContentText sx={{ color: 'rgba(34, 34, 34, 0.87)' }} pb={2}>
          Below is the list of Bowery appraisal reports and comparables used to inform expense comparables.
        </DialogContentText>
        <DialogContentText sx={{ color: 'rgba(34, 34, 34, 0.87)' }}>
          <Stack direction="column">
            {sourceLinks.map(sourceLink => (
              <Box pb={2}>
                <Stack direction="row" key={sourceLink.report.id}>
                  <Typography variant="subtitle1" mr={1}>
                    [Report]
                  </Typography>
                  <Link style={{ cursor: 'pointer' }} href={generateSourceLink(sourceLink.report.id)} target="_blank">
                    {sourceLink.report.address}
                  </Link>
                </Stack>
                <List sx={{ listStyleType: 'disc', listStylePosition: 'inside', paddingTop: 0, paddingBottom: 0 }}>
                  {sourceLink.comps.map(comp => (
                    <ListItem sx={{ display: 'list-item', paddingTop: 0, paddingBottom: 0 }} key={comp}>
                      {comp}
                    </ListItem>
                  ))}
                </List>
              </Box>
            ))}
          </Stack>
        </DialogContentText>
      </DialogContent>
      <Stack flexDirection="row" justifyContent="end" sx={{ paddingLeft: '24px' }}>
        <DialogActions>
          <Button variant="contained" onClick={onClose} data-qa="export-confirm-submit-btn">
            CLOSE
          </Button>
        </DialogActions>
      </Stack>
    </Dialog>
  )
}

export default React.memo(SourceLinksModal)
