import { get, isNumber, sum } from 'lodash'

import {
  AREA_ID,
  BASE_UNIT_COST_ID,
  EXCLUSION_TABLE_IDS,
  MULTIPLIER_IDS,
  REFINEMENT_IDS,
} from 'shared/constants/insurableValue/mvs'

import { roundToDecimal } from 'shared/utils/numberOperations'

import InsurableValueCalculations from '../insurable-value/insurable-value-calculations'

export default {
  calculateTotalRefinement(refinements: number[]) {
    return sum(refinements)
  },
  calculateTotalMultiplier(multipliers: number[]) {
    let total = 1
    multipliers.forEach(multiplier => {
      const multiplierToUse = isNumber(multiplier) ? multiplier : 1
      total *= multiplierToUse
    })
    return roundToDecimal(total, 3)
  },
  calculateAdjustedBaseUnitCost(baseUnitCost: number, refinements: any[]) {
    const totalRefinement = this.calculateTotalRefinement(refinements)
    return baseUnitCost + totalRefinement
  },
  calculateAdjustedReplacementCostNew(adjustedBaseUnitCost: number, multipliers: any[]) {
    const totalMultiplier = this.calculateTotalMultiplier(multipliers)
    return roundToDecimal(adjustedBaseUnitCost * totalMultiplier, 2)
  },
  calcualteReplacementCostNew(adjustedReplacementCostNew: number, squareFeet: number) {
    return adjustedReplacementCostNew * squareFeet
  },
  calculateBuildingComponentTotals(
    buildingComponent: any,
    customMultipliers: any[],
    customRefinements: any[],
    customExclusions: any[]
  ) {
    const baseUnitCostPsf = get(buildingComponent, BASE_UNIT_COST_ID, 0)
    const hvacAdjustment = get(buildingComponent, REFINEMENT_IDS.hvacClimate, 0)
    const elevatorAdjustment = get(buildingComponent, REFINEMENT_IDS.elevator, 0)
    const sprinklersAdjustment = get(buildingComponent, REFINEMENT_IDS.sprinklers, 0)
    let customRefinementsTotal = 0
    customRefinements.forEach(refinement => {
      customRefinementsTotal += refinement[buildingComponent.id] || 0
    })

    const refinements = [hvacAdjustment, elevatorAdjustment, sprinklersAdjustment, customRefinementsTotal]
    const storiesMultiplier = get(buildingComponent, MULTIPLIER_IDS.stories, 1)
    const heightMultiplier = get(buildingComponent, MULTIPLIER_IDS.height, 1)
    const floorAreaMultiplier = get(buildingComponent, MULTIPLIER_IDS.floorArea, 1)
    const currentCostMultiplier = get(buildingComponent, MULTIPLIER_IDS.currentCost, 1)
    const localMultiplier = get(buildingComponent, MULTIPLIER_IDS.local, 1)
    const complexSitesMultiplier = get(buildingComponent, MULTIPLIER_IDS.complexSites, 1)
    let customMultipliersTotal = 1
    customMultipliers.forEach(multiplier => {
      customMultipliersTotal *= multiplier[buildingComponent.id] || 1
    })
    const multipliers = [
      storiesMultiplier,
      heightMultiplier,
      floorAreaMultiplier,
      currentCostMultiplier,
      localMultiplier,
      complexSitesMultiplier,
      customMultipliersTotal,
    ]

    const area = get(buildingComponent, AREA_ID, 0)
    const adjustedBaseCost = this.calculateAdjustedBaseUnitCost(baseUnitCostPsf, refinements)
    const totalMultiplier = this.calculateTotalMultiplier(multipliers)
    const adjustedReplacementCostNew = this.calculateAdjustedReplacementCostNew(adjustedBaseCost, multipliers)

    const replacementCostNew = this.calcualteReplacementCostNew(adjustedReplacementCostNew, area)

    const totalExclusionPercentageInput = get(buildingComponent, EXCLUSION_TABLE_IDS.totalExclusionPercentageInput, 0)

    const customExclusionsForBuildingComponent = customExclusions.map(exclusion => exclusion[buildingComponent.id] || 0)
    const exclusionPercentage = InsurableValueCalculations.calculateTotalExclusionPercentage(
      customExclusionsForBuildingComponent,
      totalExclusionPercentageInput
    )
    const totalExclusionsValue = InsurableValueCalculations.calculateTotalExclusionsValue(
      replacementCostNew,
      exclusionPercentage
    )
    const indicatedInsurableReplacementCost = InsurableValueCalculations.calculateIndicatedInsurableReplacementCost(
      buildingComponent.replacementCostNew,
      totalExclusionsValue
    )
    return {
      adjustedBaseCost,
      totalMultiplier,
      adjustedReplacementCostNew,
      replacementCostNew,
      totalExclusionPercentageInput: exclusionPercentage,
      exclusionPercentage,
      totalExclusionsValue,
      indicatedInsurableReplacementCost: indicatedInsurableReplacementCost.value,
      roundedIndicatedInsurableReplacementCost: indicatedInsurableReplacementCost.rounded,
    }
  },
}
