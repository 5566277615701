import { cloneDeep, differenceBy, find, findIndex, partition, uniqBy, setWith } from 'lodash'

import { calculateExpensesTotal } from '../../calculations/expenses'

import { PropertyTypes } from '../../constants'
import { UTILITIES_EXPENSES_MODE, DEFAULT_EXPENSES_KEYS, UTILITIES_EXPENSE } from '../../constants/expenses'
import {
  EXPENSE_COMPARABLES_CATEGORIES,
  EXPENSE_COMPARABLES_INFO,
  EXPENSE_COMPARABLES_LABELED_CATEGORIES,
  GROSS_REVENUE,
  NON_CALCULATABLE_CATEGORIES,
} from '../../constants/expenses/expenseComparables'

const mapUtilitiesExpenses = (record, utilitiesExpensesMode) => {
  let combinedUtilitiesExpenses = []
  switch (utilitiesExpensesMode) {
    case UTILITIES_EXPENSES_MODE.COMBINED_ELECTRICITY_AND_FUEL: {
      combinedUtilitiesExpenses = record.expenses.filter(expense => {
        return expense.id === DEFAULT_EXPENSES_KEYS.electricity || expense.id === DEFAULT_EXPENSES_KEYS.fuel
      })

      break
    }
    case UTILITIES_EXPENSES_MODE.COMBINED_ALL: {
      combinedUtilitiesExpenses = record.expenses.filter(expense => {
        return (
          expense.id === DEFAULT_EXPENSES_KEYS.electricity ||
          expense.id === DEFAULT_EXPENSES_KEYS.fuel ||
          expense.id === DEFAULT_EXPENSES_KEYS.waterAndSewer
        )
      })
      break
    }
    default: {
      break
    }
  }

  let combinedUtilitiesValue = 0
  const hiddenExpenses = record.hiddenExpenses || []
  combinedUtilitiesExpenses.forEach(expense => {
    hiddenExpenses.push(expense)
    combinedUtilitiesValue += expense.value || 0
  })

  let updatedExpenses = record.expenses.filter(
    expense => !hiddenExpenses.find(hiddenExpense => hiddenExpense.id === expense.id)
  )
  let utilitiesExpensesExist = false
  if (combinedUtilitiesValue) {
    updatedExpenses = updatedExpenses.map(expense => {
      if (expense.id === UTILITIES_EXPENSE.key) {
        utilitiesExpensesExist = true
        return {
          ...expense,
          value: expense.value + combinedUtilitiesValue,
          sf: expense.sf + combinedUtilitiesValue / record.squareFeet,
          unit: expense.unit + combinedUtilitiesValue / record.residentialUnits,
        }
      }
      return expense
    })
    if (!utilitiesExpensesExist) {
      updatedExpenses.push({
        id: UTILITIES_EXPENSE.key,
        value: combinedUtilitiesValue,
        sf: combinedUtilitiesValue / record.squareFeet,
        unit: combinedUtilitiesValue / record.residentialUnits,
      })
    }
  }

  return { ...record, expenses: updatedExpenses, hiddenExpenses }
}

const calculateRecordTotals = (calculate, expenses, record, getResUnitsAndSqft) => {
  const { resUnits, squareFeet } = getResUnitsAndSqft(record, expenses)

  const totals = calculate(
    expenses.filter(expense => expense.id !== GROSS_REVENUE && !EXPENSE_COMPARABLES_INFO[expense.id]),
    squareFeet,
    resUnits
  )
  return {
    total: totals.total || null,
    totalPerSF: totals.totalPerSf || null,
    totalPerUnit: totals.totalPerUnit || null,
  }
}

export class ComparableExpensesRecords {
  static from({
    records,
    categories,
    propertyType,
    utilitiesExpensesMode,
    // eslint-disable-next-line no-unused-vars
    getResUnitsAndSqft = (record = {}, expenses = []) => {
      return {
        resUnits: record.res_units,
        squareFeet: record.sqft,
      }
    },
    includeEGI = true,
    includeRealEstateTaxes = true,
  }) {
    const expenses = new ComparableExpensesRecords()
    expenses.utilitiesExpensesMode = utilitiesExpensesMode
    expenses.records = cloneDeep(records)
    expenses.categories = categories
      .map(category => ({
        ...category,
        isDefault: ComparableExpensesRecords.isDefaultCategory(category.id),
      }))
      .filter(category => {
        if (category.id === EXPENSE_COMPARABLES_CATEGORIES.realEstateTaxes && !includeRealEstateTaxes) {
          return false
        }
        if (category.id === EXPENSE_COMPARABLES_CATEGORIES.egi && !includeEGI) {
          return false
        }
        if (
          category.id === EXPENSE_COMPARABLES_CATEGORIES.residentialUnits &&
          propertyType === PropertyTypes.COMMERCIAL
        ) {
          return false
        }
        return true
      })

    expenses.getResUnitsAndSqft = getResUnitsAndSqft
    return expenses
  }

  static isDefaultCategory(categoryId) {
    return !!EXPENSE_COMPARABLES_CATEGORIES[categoryId] || categoryId === GROSS_REVENUE
  }

  addCategory(categoryId, categoryName) {
    const category = {
      id: categoryId,
      name: categoryName || EXPENSE_COMPARABLES_LABELED_CATEGORIES[categoryId] || categoryId,
      isDefault: ComparableExpensesRecords.isDefaultCategory(categoryId),
    }
    this.categories.push(category)

    const updatedRecords = this.records.map(record => {
      if (record.expenses.find(expense => expense.id === category.id)) {
        return record
      }
      const [[expense] = [], hiddenExpenses] = partition(record.hiddenExpenses, { id: category.id })

      const expenses = record.expenses.concat(expense || { id: category.id, reported: false })
      const totals = calculateRecordTotals(calculateExpensesTotal, expenses, record, this.getResUnitsAndSqft)
      return {
        ...record,
        expenses,
        hiddenExpenses,
        ...totals,
      }
    })

    this.records = updatedRecords

    return this
  }

  normalizeCategories() {
    const [defaultCategories, customCategories] = partition(this.categories, { isDefault: true })

    this.categories = Object.entries({ [GROSS_REVENUE]: GROSS_REVENUE, ...EXPENSE_COMPARABLES_LABELED_CATEGORIES })
      .map(([id]) => {
        return defaultCategories.find(category => category.id === id)
      })
      .filter(Boolean)
      .concat(customCategories)
      .map(category => {
        if (NON_CALCULATABLE_CATEGORIES[category.id]) {
          return category
        }

        const expenses = this.records
          .map(record => record.expenses.find(expense => expense.id === category.id))
          .filter(Boolean)

        const { totalPerSf: categoryAverage } = calculateExpensesTotal(expenses, expenses.length)
        if (categoryAverage) {
          category.average = categoryAverage
        }
        return category
      })

    this.records = this.records.map(record => {
      return {
        ...record,
        expenses: this.categories.map(category => {
          const expense = find(record.expenses, { id: category.id })
          return expense || { id: category.id }
        }),
      }
    })
    return this
  }

  removeCategory(categoryId) {
    const category = {
      id: categoryId,
    }
    this.categories = this.categories.filter(cat => cat.id !== categoryId)

    this.records = this.records.map(record => {
      const [removedExpense, expenses] = partition(record.expenses, expense => expense.id === category.id)
      const totals = calculateRecordTotals(calculateExpensesTotal, expenses, record, this.getResUnitsAndSqft)
      return {
        ...record,
        ...totals,
        expenses,
        hiddenExpenses: removedExpense.concat(...(record.hiddenExpenses || [])),
      }
    })

    return this
  }

  addNewCategories(newCategories) {
    newCategories
      .filter(category => {
        if (
          (category.id === DEFAULT_EXPENSES_KEYS.electricity || category.id === DEFAULT_EXPENSES_KEYS.fuel) &&
          (this.utilitiesExpensesMode === UTILITIES_EXPENSES_MODE.COMBINED_ELECTRICITY_AND_FUEL ||
            this.utilitiesExpensesMode === UTILITIES_EXPENSES_MODE.COMBINED_ALL)
        ) {
          return false
        }
        if (
          category.id === DEFAULT_EXPENSES_KEYS.waterAndSewer &&
          this.utilitiesExpensesMode === UTILITIES_EXPENSES_MODE.COMBINED_ALL
        ) {
          return false
        }
        return true
      })
      .forEach(newCategory => {
        this.addCategory(newCategory.id)
      })

    return this
  }

  addRecords(records = [], includeRealEstateTaxes = false, includeEGI = false) {
    const importedCategories = uniqBy(records.flatMap(record => record.expenses), 'id')
    const newCategories = differenceBy(importedCategories, this.categories, 'id').filter(newCategory => {
      if (newCategory.id === EXPENSE_COMPARABLES_CATEGORIES.realEstateTaxes && !includeRealEstateTaxes) {
        return false
      }
      if (newCategory.id === EXPENSE_COMPARABLES_CATEGORIES.egi && !includeEGI) {
        return false
      }

      const unreportedInAllRecords = records.every(record => {
        const category = record.expenses.find(expense => expense.id === newCategory.id)
        return !category || (!category.reported && !category.value)
      })
      if (unreportedInAllRecords) {
        return false
      }

      return true
    })
    const newRecords = records.map(record => {
      return mapUtilitiesExpenses(record, this.utilitiesExpensesMode)
    })

    if (!includeEGI || !includeRealEstateTaxes) {
      newRecords.forEach(newRecord => {
        const newRecordExpenses = newRecord.expenses || []
        const egiExpense = find(newRecordExpenses, expense => expense.id === EXPENSE_COMPARABLES_CATEGORIES.egi)
        const taxesExpense = find(
          newRecordExpenses,
          expense => expense.id === EXPENSE_COMPARABLES_CATEGORIES.realEstateTaxes
        )
        if (!includeEGI && egiExpense) {
          newRecord.hiddenExpenses.push(egiExpense)
        }
        if (!includeRealEstateTaxes && taxesExpense) {
          newRecord.hiddenExpenses.push(taxesExpense)
        }
      })
    }
    this.records = this.records.concat(...newRecords)

    this.addNewCategories(newCategories)

    return this
  }

  // TODO: update to follow the same rules as addRecords: take into account utilitiesExpensesMode and hidden categories
  updateRecord(record, includeRealEstateTaxes = false, includeEGI = false) {
    const newCategories = differenceBy(record.expenses, this.categories, 'id').filter(({ id }) => {
      if (id === EXPENSE_COMPARABLES_CATEGORIES.realEstateTaxes && !includeRealEstateTaxes) {
        return false
      }
      if (id === EXPENSE_COMPARABLES_CATEGORIES.egi && !includeEGI) {
        return false
      }
      return true
    })

    const recordIndex = findIndex(this.records, { boweryId: record.boweryId })

    if (recordIndex > -1) {
      const recordWithMappedUtilities = mapUtilitiesExpenses(record, this.utilitiesExpensesMode)

      setWith(this.records, recordIndex, recordWithMappedUtilities)

      this.addNewCategories(newCategories)
    }

    return this
  }

  mergeRemovedCategoriesIntoUtilities(from) {
    this.records = this.records.map(record => {
      const hiddenExpenses = record.hiddenExpenses.filter(expense => from.includes(expense.id))

      const expenses = record.expenses.map(expense => {
        if (expense.id !== UTILITIES_EXPENSE.key) {
          return expense
        }

        const { resUnits, squareFeet } = this.getResUnitsAndSqft(record, record.expenses)
        const hiddenTotals = calculateExpensesTotal(hiddenExpenses, squareFeet, resUnits)
        const value = (expense.value || 0) + hiddenTotals.total
        const sf = (expense.sf || 0) + hiddenTotals.totalPerSf
        const unit = (expense.unit || 0) + hiddenTotals.totalPerUnit

        return {
          ...expense,
          value,
          sf,
          unit,
          reported: true,
        }
      })
      const totals = calculateRecordTotals(calculateExpensesTotal, expenses, record, this.getResUnitsAndSqft)
      return {
        ...record,
        ...totals,
        expenses,
      }
    })

    return this
  }

  splitUtilitiesTo(to) {
    let shouldAddUtilitiesCategory = false
    this.records = this.records.map(record => {
      const expensesToRemoveFromUtilities = record.expenses.filter(expense => to.includes(expense.id))

      // check hidden expenses for utilities in case we move from some sort of combined to broken out
      // so we need to update utilities expense that was visible and now hidden
      const hiddenExpenses = record.hiddenExpenses.map(expense => {
        if (expense.id === UTILITIES_EXPENSE.key) {
          const utilitiesExpenses = expensesToRemoveFromUtilities.reduce((utilities, subtrahend) => {
            return {
              ...utilities,
              value: utilities.value - (subtrahend.value || 0),
              sf: utilities.sf - (subtrahend.sf || 0),
              unit: utilities.unit - (subtrahend.unit || 0),
            }
          }, expense)

          if (this.utilitiesExpensesMode === UTILITIES_EXPENSES_MODE.BROKEN_OUT && !!utilitiesExpenses.value) {
            shouldAddUtilitiesCategory = true
          }

          return utilitiesExpenses
        }

        return expense
      })

      // check expenses for utilities in case we changed from combined all to combined electricity and fuel
      // so we need to update utilities expense that was not hidden and remains the same
      const expenses = record.expenses.map(expense => {
        if (expense.id === UTILITIES_EXPENSE.key) {
          const utilitiesExpenses = expensesToRemoveFromUtilities.reduce((utilities, subtrahend) => {
            return {
              ...utilities,
              value: utilities.value - (subtrahend.value || 0),
              sf: utilities.sf - (subtrahend.sf || 0),
              unit: utilities.unit - (subtrahend.unit || 0),
            }
          }, expense)

          if (this.utilitiesExpensesMode === UTILITIES_EXPENSES_MODE.BROKEN_OUT && !!utilitiesExpenses.value) {
            shouldAddUtilitiesCategory = true
          }

          return utilitiesExpenses
        }

        return expense
      })

      return {
        ...record,
        expenses,
        hiddenExpenses,
      }
    })

    if (shouldAddUtilitiesCategory) {
      this.addCategory(UTILITIES_EXPENSE.key)
    }

    return this
  }
}
