import React, { Component } from 'react'
import { connect } from 'react-redux'
import { get, forIn, kebabCase } from 'lodash'

import { Loading } from 'client-shared/components'

import { hasPermission } from 'shared/helpers/permissions'

import PageLayout from '../layout/PageLayout'
import { cmsOrganizationFetch } from '../redux/actions/cms'

import CmsForms from './cmsStructure'

class CmsContainer extends Component {
  componentWillMount() {
    const { cmsOrganizationLoaded, cmsOrganizationFetch, organizationIdParam } = this.props

    if (!cmsOrganizationLoaded) {
      cmsOrganizationFetch(organizationIdParam)
    }
  }

  generateUserForms = () => {
    const items = []
    const { userPermissions } = this.props

    forIn(CmsForms, ({ name, formComponent, permission = null }, key) => {
      if (!permission || hasPermission(permission, userPermissions)) {
        items.push({
          title: name,
          formComponent,
          path: kebabCase(key),
        })
      }
    })

    return items
  }

  render() {
    const { cmsOrganizationLoaded, organizationData } = this.props

    const forms = this.generateUserForms()

    const organizationId = get(organizationData, '_id')

    return cmsOrganizationLoaded ? (
      <PageLayout header="Content Management System" itemKey="cms" itemId={organizationId} forms={forms} />
    ) : (
      <Loading />
    )
  }
}

export default connect(
  (state, ownProps) => ({
    cmsOrganizationLoaded: !!get(state, 'cms.organizationData._id'),
    organizationData: get(state, 'cms.organizationData'),
    organizationIdParam: get(ownProps, 'match.params.id'),
    userPermissions: get(state, 'authentication.user.permissions', []),
  }),
  {
    cmsOrganizationFetch,
  }
)(CmsContainer)
