import { Link } from '@mui/material'
import { filter } from 'lodash'
import React from 'react'

const AutomationDisabledMessage = ({ rentReconciliationUnitGroups, reportId }) => {
  const hasNoRentRoll = !rentReconciliationUnitGroups.length
  const hasNoMarketConclusions = !!filter(rentReconciliationUnitGroups, unitGroup => !unitGroup.marketConclusion)
    ?.length
  const showDisabledMessage = hasNoRentRoll || hasNoMarketConclusions
  return (
    showDisabledMessage && (
      <div>
        Some values are missing:
        <ul>
          {hasNoRentRoll && (
            <li>
              In-Place Rent Roll table is missing -
              <Link style={{ cursor: 'pointer' }} href={`/report/${reportId}/residential-rent-roll`}>
                {' '}
                Edit In-Place Rent Roll
              </Link>
            </li>
          )}
          {hasNoMarketConclusions && (
            <li>
              Market Conclusion is missing -
              <Link style={{ cursor: 'pointer' }} href={`/report/${reportId}/residential-rent-reconciliation`}>
                {' '}
                Edit Market Conclusion
              </Link>
            </li>
          )}
        </ul>
      </div>
    )
  )
}

export default AutomationDisabledMessage
