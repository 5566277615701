import React from 'react'

import { CustomFormApi } from 'final-form'

import { makeStyles } from '@mui/styles'
import { Box } from '@mui/material'

import { AtomicUnit, FormValues } from '../../types'

import styles from '../../styles'

import CreateAtomicUnit from './CreateAtomicUnit'
import AtomicUnitComponent from './AtomicUnit'

// @ts-ignore makeStyles doesn't like the strings - it wants enums
const useStyles = makeStyles(styles)

type AtomicUnitsProps = {
  form: CustomFormApi<FormValues>
}

const AtomicUnits = ({ form }: AtomicUnitsProps) => {
  const atomicUnits: AtomicUnit[] = form.getState().values.newGenericSubsection.data.atomicUnits || []
  const classes = useStyles()

  return (
    <>
      {atomicUnits.map((atomicUnit, index) => (
        <Box className={classes.atomicUnit}>
          <AtomicUnitComponent key={index} formPath={`newGenericSubsection.data.atomicUnits[${index}]`} form={form} />
        </Box>
      ))}
      <Box className={classes.createField}>
        <CreateAtomicUnit form={form} />
      </Box>
    </>
  )
}

export default AtomicUnits
