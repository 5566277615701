const testPermission = (permission, userPermissions) => {
  if (userPermissions.includes('*') || userPermissions.includes(permission)) {
    return true
  }
  // check for wildcards at each level
  const categories = permission.split('.')
  let path = ''
  // eslint-disable-next-line id-length
  for (let i = 0, l = categories.length; i < l; i++) {
    path = `${path}.${categories[i]}`.replace(/^\./, '')
    if (userPermissions.includes(`${path}.*`)) {
      return true
    }
  }
  return false
}

const NOT = /^!/

export const hasPermission = (permission, userPermissions) => {
  if (!permission) {
    return true
  }
  if (permission === '!') {
    return false
  }
  if (!userPermissions) {
    return NOT.test(permission)
  }
  if (Array.isArray(permission)) {
    return permission.reduce((result, perm) => result && hasPermission(perm, userPermissions), true)
  }
  if (NOT.test(permission)) {
    return !testPermission(permission.replace(NOT, ''), userPermissions)
  }
  return testPermission(permission, userPermissions)
}
