import { handleActions } from 'redux-actions'

import { reportZoningFetchRequest, reportZoningFetchReceive, reportZoningFetchFail } from './actions'

const initialState = {
  isLoading: false,
  isLoaded: false,
  importedZoning: null,
}

export const reducer = handleActions(
  {
    [reportZoningFetchRequest](state) {
      return {
        ...state,
        isLoading: true,
      }
    },
    [reportZoningFetchReceive](state, { payload }) {
      return {
        ...state,
        importedZoning: payload.importedZoning,
        isLoading: false,
        isLoaded: true,
      }
    },
    [reportZoningFetchFail](state) {
      return {
        ...state,
        isLoading: false,
      }
    },
  },
  initialState
)
