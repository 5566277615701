import * as React from 'react'
import PropTypes from 'prop-types'

import { Checkbox, FormControl, FormControlLabel, FormGroup, FormLabel } from '@mui/material'
import { Field } from 'react-final-form'
import { kebabCase } from 'lodash'

const CheckboxGroupItem = ({ disabled, input, label }) => {
  const { checked, name, onChange, onBlur, onFocus } = input
  const qaAttribute = `${name}-checkbox${checked ? '-checked' : ''}`
  return (
    <FormControlLabel
      control={
        <Checkbox
          checked={checked}
          data-qa={qaAttribute}
          disabled={disabled}
          name={name}
          onChange={onChange}
          value={checked}
          onBlur={onBlur}
          onFocus={onFocus}
        />
      }
      label={label}
      sx={{ '& .MuiFormControlLabel-label': { fontSize: 14 }, my: 1 / 2 }}
    />
  )
}

const CheckboxGroup = ({ disableGroup = false, groupName, header, horizontal, items }) => (
  <FormControl component="fieldset" data-qa={`${kebabCase(header)}-checkbox-group`} sx={{ my: 1 }}>
    {header && <FormLabel component="legend">{header}</FormLabel>}
    <FormGroup row={horizontal}>
      {items.map(({ name, label, ...restProps }) => {
        return (
          <Field
            component={CheckboxGroupItem}
            disabled={disableGroup}
            key={name}
            label={label}
            name={groupName ? `${groupName}.${name}` : name}
            type="checkbox"
            {...restProps}
          />
        )
      })}
    </FormGroup>
  </FormControl>
)

CheckboxGroup.displayName = 'CheckboxGroup'

CheckboxGroup.propTypes = {
  disableGroup: PropTypes.bool,
  groupName: PropTypes.string,
  header: PropTypes.string,
  horizontal: PropTypes.bool,
  items: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
    })
  ).isRequired,
}

CheckboxGroup.defaultProps = {
  disableGroup: false,
  groupName: '',
  header: '',
  horizontal: false,
}

export default CheckboxGroup
