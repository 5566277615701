import React from 'react'
import { Button, Paper, Table, TableBody, TableHead, Toolbar, Typography } from '@mui/material'
import { withStyles } from '@mui/styles'
import { get, isEqual } from 'lodash'
import { FieldArray } from 'react-final-form-arrays'

import { CLEAR_ALL_LABEL } from '../../constants'
import { TableHeadRow, TableBodyRow } from '../../../shared/components/TableHelpers'
import { simplePluralize } from '../../../../../shared/utils/textGeneration'

const styles = {
  removedCompsContainer: {
    marginTop: 16,
    padding: '22px 24px 38px',
  },
  removedCompsToolbar: {
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'space-between',
    paddingLeft: 0,
  },
  tableCell: {
    padding: '4px 16px !important',
    fontSize: '14px',
    fontFamily: 'Nunito Sans',
  },
  tableHeaderRow: {
    padding: '0 16px',
  },
}

const RemovedCompsTable = ({ title = '', cellComponent, classes, columns, form, fieldName = '' }) => {
  const getTitle = count => {
    const tableTitle = simplePluralize(title, count)
    return count > 0 ? `${count} ${tableTitle}` : tableTitle
  }

  const clearAll = () => form.change(fieldName, [])

  return (
    <Paper className={classes.removedCompsContainer}>
      <Toolbar className={classes.removedCompsToolbar}>
        <Typography variant="h6" gutterBottom>
          {getTitle(get(form, `values[${fieldName}]`, []).length)}
        </Typography>
        <Button size="small" color="primary" onClick={() => clearAll()}>
          {CLEAR_ALL_LABEL}
        </Button>
      </Toolbar>
      <Table>
        <TableHead>
          <TableHeadRow cellClass={classes.tableCell} columns={columns} rowClass={classes.tableHeaderRow} />
        </TableHead>
        <TableBody>
          <FieldArray name={fieldName} isEqual={isEqual}>
            {({ fields }) =>
              fields.map((comp, index) => (
                <TableBodyRow
                  index={index}
                  key={index}
                  columns={columns}
                  cellComponent={cellComponent}
                  order={index + 1}
                  comp={get(form.values, comp, {})}
                  compKey={comp}
                  rowClass={classes.tableRow}
                />
              ))
            }
          </FieldArray>
        </TableBody>
      </Table>
    </Paper>
  )
}

export default withStyles(styles)(RemovedCompsTable)
