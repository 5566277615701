import React from 'react'
import PropTypes from 'prop-types'
import MUITooltip from '@mui/material/Tooltip'

const placements = [
  'bottom-end',
  'bottom-start',
  'bottom',
  'left-end',
  'left-start',
  'left',
  'right-end',
  'right-start',
  'right',
  'top-end',
  'top-start',
  'top',
]

const Tooltip = ({ title, placement, children, className, onOpen }) => (
  <MUITooltip title={title} placement={placement} className={className} onOpen={onOpen}>
    <span>{children}</span>
  </MUITooltip>
)

Tooltip.propTypes = {
  title: PropTypes.node.isRequired,
  children: PropTypes.node.isRequired,
  placement: PropTypes.oneOf(placements).isRequired,
  className: PropTypes.string,
  onOpen: PropTypes.func,
}

export default React.memo(Tooltip)
