import React from 'react'
import PropTypes from 'prop-types'

import { get } from 'lodash'
import { OnChange } from 'react-final-form-listeners'

import { Stack, Typography } from '@mui/material'

import { capitalizeFirstLetter } from '../../../../../../shared/utils/formatters/textFormatters'
import {
  CheckboxListField as CheckboxList,
  ImageUploadListField as ImageUploadList,
  RadioListField as RadioList,
  TextField as Text,
} from '../../../../shared/components/Fields'
import { objectToKeyValuePairs as objectToKeyValuePairsV2 } from '../../../../shared/components/helpers'

import {
  TYPICAL_COMMERCIAL_UNIT_EXTERIOR,
  TYPICAL_COMMERCIAL_UNIT_INTERIOR,
} from '../../../../shared/constants/imageCategories'
import { arrayToKeyValuePairs, objectToKeyValuePairs } from '../../../../shared/utils/ui/checkboxHelper'
import { required } from '../../../../shared/utils/validation'
import {
  CEILING_HEIGHTS,
  FRONTAGE,
  UNIT_STATES,
} from '../../../../../../libs/bowery-libs/constants/report/commercial-unit'
import {
  UNIT_FACADE_LIST,
  UNIT_FLOORS,
  UNIT_GRADES,
  UNIT_LOCATIONS,
  UNIT_STREET_TYPES,
  UNIT_USES_MAP,
} from '../../../constants'

const unitUsages = UNIT_USES_MAP
const unitStates = arrayToKeyValuePairs(UNIT_STATES, true)
const unitLocations = arrayToKeyValuePairs(UNIT_LOCATIONS, true)
const streetTypes = arrayToKeyValuePairs(UNIT_STREET_TYPES, true)
const ceilingHeightOptions = objectToKeyValuePairs(CEILING_HEIGHTS, true)
const frontageOptions = objectToKeyValuePairs(FRONTAGE, true)

const floors = objectToKeyValuePairsV2(UNIT_FLOORS)
const grades = objectToKeyValuePairsV2(UNIT_GRADES)

const OTHER_OPTION = 'other'

class CommercialUnit extends React.PureComponent {
  constructor(props) {
    super(props)

    const values = props.form.getState().values
    const unitPath = `units[${props.index}]`

    const { floor, grade, state, facade, ceilingHeight, frontage } = get(values, unitPath)
    const floorRequired = !!floor.other
    const gradeRequired = !!grade.other

    const stateRequired = state === OTHER_OPTION
    const facadeRequired = facade === OTHER_OPTION
    const ceilingHeightRequired = ceilingHeight === OTHER_OPTION
    const frontageRequired = frontage === OTHER_OPTION

    this.state = {
      floorRequired,
      stateRequired,
      gradeRequired,
      facadeRequired,
      ceilingHeightRequired,
      frontageRequired,
    }
  }

  onCheckboxChange = fieldName => (value, prevValue) => {
    const { form, index } = this.props
    const otherFieldName = `other${capitalizeFirstLetter(fieldName)}`
    const stateFieldName = `${fieldName}Required`

    if (prevValue.other && !value.other) {
      form.change(`units[${index}].${otherFieldName}`, '')
      this.setState({ [stateFieldName]: false })
    } else if (!prevValue.other && value.other) {
      this.setState({ [stateFieldName]: true })
    }
  }

  onRadioChange = fieldName => (value, prevValue) => {
    const stateFieldName = `${fieldName}Required`
    if (prevValue === OTHER_OPTION) {
      this.setState({ [stateFieldName]: false })
    } else if (value === OTHER_OPTION) {
      this.setState({ [stateFieldName]: true })
    }
  }

  get labels() {
    const { floorRequired, stateRequired, gradeRequired, facadeRequired, ceilingHeightRequired, frontageRequired } =
      this.state
    return {
      floor: floorRequired ? 'Floor *' : 'Floor',
      state: stateRequired ? 'State *' : 'State',
      grade: gradeRequired ? 'Grade *' : 'Grade',
      facade: facadeRequired ? 'Facade *' : 'Facade',
      ceilingHeight: ceilingHeightRequired ? 'Ceiling Height *' : 'Ceiling Height',
      frontage: frontageRequired ? 'Frontage *' : 'Frontage',
    }
  }

  render() {
    const { index } = this.props
    const unitPath = `units[${index}]`

    return (
      <Stack spacing={2}>
        <Typography variant="subtitle1">{`Commercial Unit ${index + 1} Description`}</Typography>
        <Stack spacing={1}>
          <ImageUploadList
            name={`${unitPath}.interiorImages`}
            label={TYPICAL_COMMERCIAL_UNIT_INTERIOR.categoryTitle}
            category={TYPICAL_COMMERCIAL_UNIT_INTERIOR.categoryName}
          />
          <ImageUploadList
            name={`${unitPath}.exteriorImages`}
            label={TYPICAL_COMMERCIAL_UNIT_EXTERIOR.categoryTitle}
            category={TYPICAL_COMMERCIAL_UNIT_EXTERIOR.categoryName}
          />
        </Stack>
        <RadioList
          label="Use"
          name={`${unitPath}.use`}
          items={unitUsages}
          horizontal
          required
          validate={required}
          renderAtEnd={(key, checked) => {
            if (key === OTHER_OPTION && checked) {
              return <Text name={`${unitPath}.otherUse`} required validate={required} />
            }
          }}
        />
        <RadioList
          label={this.labels.state}
          name={`${unitPath}.state`}
          items={unitStates}
          renderAtEnd={(key, checked) => {
            if (key === OTHER_OPTION && checked) {
              return <Text name={`${unitPath}.otherState`} required validate={required} />
            }
          }}
          horizontal
        />
        <OnChange name={`${unitPath}.state`}>{this.onRadioChange('state')}</OnChange>
        <RadioList label="Location" name={`${unitPath}.location`} items={unitLocations} horizontal />
        <RadioList label="Street Type" name={`${unitPath}.streetType`} items={streetTypes} horizontal />
        <CheckboxList
          label={this.labels.floor}
          name={`${unitPath}.floor`}
          items={floors}
          renderAtEnd={(key, checked) => {
            if (key === OTHER_OPTION && checked) {
              return <Text name={`${unitPath}.otherFloor`} required validate={required} />
            }
          }}
          horizontal
        />
        <OnChange name={`${unitPath}.floor`}>{this.onCheckboxChange('floor')}</OnChange>

        <CheckboxList
          label={this.labels.grade}
          name={`${unitPath}.grade`}
          items={grades}
          renderAtEnd={(key, checked) => {
            if (key === OTHER_OPTION && checked) {
              return <Text name={`${unitPath}.otherGrade`} required validate={required} />
            }
          }}
          horizontal
        />
        <OnChange name={`${unitPath}.grade`}>{this.onCheckboxChange('grade')}</OnChange>

        <RadioList
          label={this.labels.facade}
          name={`${unitPath}.facade`}
          items={UNIT_FACADE_LIST}
          renderAtEnd={(key, checked) => {
            if (key === OTHER_OPTION && checked) {
              return <Text name={`${unitPath}.otherFacade`} required validate={required} />
            }
          }}
          horizontal
        />
        <OnChange name={`${unitPath}.facade`}>{this.onRadioChange('facade')}</OnChange>
        <RadioList
          label={this.labels.ceilingHeight}
          name={`${unitPath}.ceilingHeight`}
          items={ceilingHeightOptions}
          renderAtEnd={(key, checked) => {
            if (key === OTHER_OPTION && checked) {
              return <Text name={`${unitPath}.otherCeilingHeight`} required validate={required} />
            }
          }}
          horizontal
        />
        <OnChange name={`${unitPath}.ceilingHeight`}>{this.onRadioChange('ceilingHeight')}</OnChange>
        <RadioList
          label={this.labels.frontage}
          name={`${unitPath}.frontage`}
          items={frontageOptions}
          renderAtEnd={(key, checked) => {
            if (key === OTHER_OPTION && checked) {
              return <Text name={`${unitPath}.otherFrontage`} required validate={required} />
            }
          }}
          horizontal
        />
        <OnChange name={`${unitPath}.frontage`}>{this.onRadioChange('frontage')}</OnChange>
      </Stack>
    )
  }
}

CommercialUnit.propTypes = {
  index: PropTypes.number.isRequired,
}

export default CommercialUnit
