import { omit } from 'lodash'

export const EXPENSES_MODE = {
  CUSTOM: 'custom',
  STRICT: 'strict',
}

export const DEFAULT_EXPENSES_KEYS = {
  realEstateTaxes: 'realEstateTaxes',
  insurance: 'insurance',
  electricity: 'electricity',
  fuel: 'fuel',
  waterAndSewer: 'waterAndSewer',
  repairsAndMaintenance: 'repairsAndMaintenance',
  payrollAndBenefits: 'payrollAndBenefits',
  generalAndAdministrative: 'generalAndAdministrative',
  legalAndProfessionalFees: 'legalAndProfessionalFees',
  miscellaneous: 'miscellaneous',
  management: 'management',
  reserves: 'reserves',
}

export const ELECTRICITY_EXPENSE = {
  key: DEFAULT_EXPENSES_KEYS.electricity,
  name: 'Electricity',
}

export const FUEL_EXPENSE = {
  key: DEFAULT_EXPENSES_KEYS.fuel,
  name: 'Fuel',
}

export const ELECTRICITY_FUEL_EXPENSE = {
  key: 'electricityFuel',
  name: 'Electricity & Fuel',
}

export const WATER_AND_SEWER_EXPENSE = {
  key: DEFAULT_EXPENSES_KEYS.waterAndSewer,
  name: 'Water & Sewer',
}

export const UTILITIES_EXPENSE = {
  key: 'utilities',
  name: 'Utilities',
}

export const ADDITIONAL_EXPENSES_KEYS = {
  electricityFuel: ELECTRICITY_FUEL_EXPENSE.key,
  total: 'total',
}

export const OTHER_EXPENSE = {
  key: 'other',
  name: 'Other',
}

export const GROSS_REVENUE = {
  key: 'grossRevenue',
  name: 'Gross Revenue',
}

export const REAL_ESTATE_TAXES = { name: 'Real Estate Taxes', key: DEFAULT_EXPENSES_KEYS.realEstateTaxes }

export const EXPENSE_CATEGORIES = {
  [DEFAULT_EXPENSES_KEYS.realEstateTaxes]: 'Real Estate Taxes',
  [DEFAULT_EXPENSES_KEYS.insurance]: 'Insurance',
  [UTILITIES_EXPENSE.key]: UTILITIES_EXPENSE.name,
  [DEFAULT_EXPENSES_KEYS.electricity]: ELECTRICITY_EXPENSE.name,
  [DEFAULT_EXPENSES_KEYS.fuel]: FUEL_EXPENSE.name,
  [DEFAULT_EXPENSES_KEYS.waterAndSewer]: WATER_AND_SEWER_EXPENSE.name,
  [DEFAULT_EXPENSES_KEYS.repairsAndMaintenance]: 'Repairs & Maintenance',
  [DEFAULT_EXPENSES_KEYS.payrollAndBenefits]: 'Payroll & Benefits',
  [DEFAULT_EXPENSES_KEYS.generalAndAdministrative]: 'General & Administrative',
  [DEFAULT_EXPENSES_KEYS.legalAndProfessionalFees]: 'Legal & Professional Fees',
  [DEFAULT_EXPENSES_KEYS.miscellaneous]: 'Miscellaneous',
  [DEFAULT_EXPENSES_KEYS.management]: 'Management Fees',
  [DEFAULT_EXPENSES_KEYS.reserves]: 'Replacement Reserves',
}

export const DEFAULT_EXPENSE_CATEGORIES = omit(EXPENSE_CATEGORIES, [UTILITIES_EXPENSE.key])

export const STRICT_EXPENSE_CATEGORIES = {
  [DEFAULT_EXPENSES_KEYS.realEstateTaxes]: 'Real Estate Taxes',
  [DEFAULT_EXPENSES_KEYS.insurance]: 'Insurance',
  [UTILITIES_EXPENSE.key]: UTILITIES_EXPENSE.name,
  [DEFAULT_EXPENSES_KEYS.repairsAndMaintenance]: 'Repairs & Maintenance',
  [DEFAULT_EXPENSES_KEYS.payrollAndBenefits]: 'Payroll & Benefits',
  [DEFAULT_EXPENSES_KEYS.generalAndAdministrative]: 'General & Administrative',
  [DEFAULT_EXPENSES_KEYS.management]: 'Management Fees',
  [DEFAULT_EXPENSES_KEYS.reserves]: 'Replacement Reserves',
  [DEFAULT_EXPENSES_KEYS.miscellaneous]: 'Miscellaneous',
}

export const ADDITIONAL_EXPENSE_CATEGORIES_KEYS = {
  groundskeeping: 'groundskeeping',
  security: 'security',
  nonRevenueUnits: 'nonRevenueUnits',
  advertisingPromotion: 'advertisingPromotion',
  groundLease: 'groundLease',
  condoAssociationFee: 'condoAssociationFee',
  coOpMaintenanceCharges: 'coOpMaintenanceCharges',
  commonAreaMaintenance: 'commonAreaMaintenance',
  cleaningAndJanitorial: 'cleaningAndJanitorial',
}

export const ADDITIONAL_EXPENSE_CATEGORIES = {
  [ADDITIONAL_EXPENSE_CATEGORIES_KEYS.groundskeeping]: 'Groundskeeping',
  [ADDITIONAL_EXPENSE_CATEGORIES_KEYS.security]: 'Security',
  [ADDITIONAL_EXPENSE_CATEGORIES_KEYS.nonRevenueUnits]: 'Non-Revenue Units',
  [ADDITIONAL_EXPENSE_CATEGORIES_KEYS.advertisingPromotion]: 'Advertising/Promotion',
  [ADDITIONAL_EXPENSE_CATEGORIES_KEYS.groundLease]: 'Ground Lease',
  [ADDITIONAL_EXPENSE_CATEGORIES_KEYS.condoAssociationFee]: 'Condo Association Fee',
  [ADDITIONAL_EXPENSE_CATEGORIES_KEYS.coOpMaintenanceCharges]: 'Co-op Maintenance Charges',
  [ADDITIONAL_EXPENSE_CATEGORIES_KEYS.commonAreaMaintenance]: 'Common Area Maintenance',
  [ADDITIONAL_EXPENSE_CATEGORIES_KEYS.cleaningAndJanitorial]: 'Cleaning and Janitorial',
}

export const ADDITIONAL_EXPENSES_CATEGORIES_LIST = Object.values(ADDITIONAL_EXPENSE_CATEGORIES)

export const COMMON_EXPENSE_SUBCATEGORIES_KEYS = {
  [DEFAULT_EXPENSES_KEYS.repairsAndMaintenance]: [
    ADDITIONAL_EXPENSE_CATEGORIES_KEYS.groundskeeping,
    ADDITIONAL_EXPENSE_CATEGORIES_KEYS.security,
    ADDITIONAL_EXPENSE_CATEGORIES_KEYS.cleaningAndJanitorial,
  ],
  [DEFAULT_EXPENSES_KEYS.miscellaneous]: [
    ADDITIONAL_EXPENSE_CATEGORIES_KEYS.nonRevenueUnits,
    ADDITIONAL_EXPENSE_CATEGORIES_KEYS.groundLease,
    ADDITIONAL_EXPENSE_CATEGORIES_KEYS.condoAssociationFee,
    ADDITIONAL_EXPENSE_CATEGORIES_KEYS.coOpMaintenanceCharges,
  ],
  [DEFAULT_EXPENSES_KEYS.generalAndAdministrative]: [
    ADDITIONAL_EXPENSE_CATEGORIES_KEYS.advertisingPromotion,
    ADDITIONAL_EXPENSE_CATEGORIES_KEYS.commonAreaMaintenance,
  ],
}

export const SUBCATEGORIES_MAP = {
  [ADDITIONAL_EXPENSE_CATEGORIES_KEYS.groundskeeping]: DEFAULT_EXPENSES_KEYS.repairsAndMaintenance,
  [ADDITIONAL_EXPENSE_CATEGORIES_KEYS.security]: DEFAULT_EXPENSES_KEYS.repairsAndMaintenance,
  [ADDITIONAL_EXPENSE_CATEGORIES_KEYS.nonRevenueUnits]: DEFAULT_EXPENSES_KEYS.miscellaneous,
  [ADDITIONAL_EXPENSE_CATEGORIES_KEYS.advertisingPromotion]: DEFAULT_EXPENSES_KEYS.generalAndAdministrative,
  [ADDITIONAL_EXPENSE_CATEGORIES_KEYS.groundLease]: DEFAULT_EXPENSES_KEYS.miscellaneous,
  [ADDITIONAL_EXPENSE_CATEGORIES_KEYS.condoAssociationFee]: DEFAULT_EXPENSES_KEYS.miscellaneous,
  [ADDITIONAL_EXPENSE_CATEGORIES_KEYS.coOpMaintenanceCharges]: DEFAULT_EXPENSES_KEYS.miscellaneous,
  [ADDITIONAL_EXPENSE_CATEGORIES_KEYS.commonAreaMaintenance]: DEFAULT_EXPENSES_KEYS.generalAndAdministrative,
  [ADDITIONAL_EXPENSE_CATEGORIES_KEYS.cleaningAndJanitorial]: DEFAULT_EXPENSES_KEYS.repairsAndMaintenance,
  [DEFAULT_EXPENSES_KEYS.electricity]: UTILITIES_EXPENSE.key,
  [DEFAULT_EXPENSES_KEYS.fuel]: UTILITIES_EXPENSE.key,
  [DEFAULT_EXPENSES_KEYS.waterAndSewer]: UTILITIES_EXPENSE.key,
  [ELECTRICITY_FUEL_EXPENSE.key]: UTILITIES_EXPENSE.key,
}

export const UTILITIES_EXPENSES_MODE = {
  BROKEN_OUT: 'brokenOut',
  COMBINED_ELECTRICITY_AND_FUEL: 'combinedElectricityAndFuel',
  COMBINED_ALL: 'combinedAll',
}

export const UTILITIES_EXPENSES_MODE_OPTIONS = [
  { value: UTILITIES_EXPENSES_MODE.BROKEN_OUT, label: 'Broken Out' },
  { value: UTILITIES_EXPENSES_MODE.COMBINED_ELECTRICITY_AND_FUEL, label: 'Combined Electricity & Fuel' },
  { value: UTILITIES_EXPENSES_MODE.COMBINED_ALL, label: 'Combined Electricity, Fuel, Water & Sewer' },
]

export const HIDDEN_HISTORICAL_EXPENSES = {
  [UTILITIES_EXPENSES_MODE.BROKEN_OUT]: [UTILITIES_EXPENSE.key],
  [UTILITIES_EXPENSES_MODE.COMBINED_ELECTRICITY_AND_FUEL]: [
    DEFAULT_EXPENSES_KEYS.electricity,
    DEFAULT_EXPENSES_KEYS.fuel,
  ],
  [UTILITIES_EXPENSES_MODE.COMBINED_ALL]: [
    DEFAULT_EXPENSES_KEYS.electricity,
    DEFAULT_EXPENSES_KEYS.fuel,
    DEFAULT_EXPENSES_KEYS.waterAndSewer,
  ],
}

export const BASIS_OF_COMPARISON_OPTIONS = {
  GROSS: 'Gross',
  PER_SF: 'Per Square Foot',
  PER_UNIT: 'Per Unit',
}
export const BASIS_OF_COMPARISON_ITEMS = [
  { value: BASIS_OF_COMPARISON_OPTIONS.GROSS, label: BASIS_OF_COMPARISON_OPTIONS.GROSS },
  { value: BASIS_OF_COMPARISON_OPTIONS.PER_SF, label: BASIS_OF_COMPARISON_OPTIONS.PER_SF },
  { value: BASIS_OF_COMPARISON_OPTIONS.PER_UNIT, label: BASIS_OF_COMPARISON_OPTIONS.PER_UNIT },
]

export const NOT_COMPUTABLE_VALUE = '-'
export const NOT_REPORTED_STRING = '-'

export const EXPENSES_IN_ORDER = [
  {
    category: DEFAULT_EXPENSES_KEYS.insurance,
    name: DEFAULT_EXPENSE_CATEGORIES.insurance,
  },
  {
    category: UTILITIES_EXPENSE.key,
    name: UTILITIES_EXPENSE.name,
  },
  {
    category: DEFAULT_EXPENSES_KEYS.electricity,
    name: DEFAULT_EXPENSE_CATEGORIES.electricity,
  },
  { category: DEFAULT_EXPENSES_KEYS.fuel, name: DEFAULT_EXPENSE_CATEGORIES.fuel },
  {
    category: DEFAULT_EXPENSES_KEYS.waterAndSewer,
    name: DEFAULT_EXPENSE_CATEGORIES.waterAndSewer,
  },
  {
    category: DEFAULT_EXPENSES_KEYS.repairsAndMaintenance,
    name: DEFAULT_EXPENSE_CATEGORIES.repairsAndMaintenance,
  },
  {
    category: DEFAULT_EXPENSES_KEYS.payrollAndBenefits,
    name: DEFAULT_EXPENSE_CATEGORIES.payrollAndBenefits,
  },
  {
    category: DEFAULT_EXPENSES_KEYS.generalAndAdministrative,
    name: DEFAULT_EXPENSE_CATEGORIES.generalAndAdministrative,
  },
  {
    category: DEFAULT_EXPENSES_KEYS.legalAndProfessionalFees,
    name: DEFAULT_EXPENSE_CATEGORIES.legalAndProfessionalFees,
  },
  {
    category: DEFAULT_EXPENSES_KEYS.miscellaneous,
    name: DEFAULT_EXPENSE_CATEGORIES.miscellaneous,
  },
  {
    category: DEFAULT_EXPENSES_KEYS.management,
    name: DEFAULT_EXPENSE_CATEGORIES.management,
  },
  {
    category: DEFAULT_EXPENSES_KEYS.reserves,
    name: DEFAULT_EXPENSE_CATEGORIES.reserves,
  },
]

export const MONTHS = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
]
