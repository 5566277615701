import React from 'react'
import PropTypes from 'prop-types'
import { Field } from 'react-final-form'

import { getFieldValue } from './helpers'

import Table from './Table'

class TableField extends React.PureComponent {
  static propTypes = {
    form: PropTypes.object.isRequired,
    dataPath: PropTypes.string.isRequired,
    additionalData: PropTypes.arrayOf(PropTypes.object),
    rerenderOnChangeOf: PropTypes.arrayOf(PropTypes.string),
    columns: PropTypes.arrayOf(PropTypes.object).isRequired,
    cells: PropTypes.func.isRequired,
    initTable: PropTypes.func,
    beforeChange: PropTypes.func,
  }

  static defaultPropTypes = {
    additionalData: null,
    rerenderOnChangeOf: [],
  }

  initTable = ref => {
    const { rerenderOnChangeOf, initTable } = this.props
    if (!ref || !ref.hotInstance) {
      return
    }

    this.hotInstance = ref.hotInstance

    // this is equivalent to afterInit hook which for some reason doesn't work
    this.hotInstance.addHookOnce('afterRenderer', this.afterInit)

    this.rerenderOnChangeOf(rerenderOnChangeOf)

    if (initTable) {
      initTable(ref)
    }
  }

  /**
   * Validators are registered in this lifecycle
   * TODO: don't forget to unsubscribe when the component is destroyed
   */
  afterInit = () => {
    const { form, columns, dataPath } = this.props

    // register the main data set for instantaneous access
    form.registerField(dataPath, () => {}, { value: true }, {})

    const data = getFieldValue(form, dataPath)
    if (!data) {
      throw new Error('Form data path is invalid!')
    }

    // register all known data set fields
    data.forEach((dataRow, rowIndex) => {
      columns.forEach((column, columnIndex) => {
        const fieldName = `${dataPath}[${rowIndex}].${column.name}`
        const fieldConfig = column.fieldConfig
          ? {
              ...column.fieldConfig,
            }
          : {}

        if (fieldConfig.getValidator) {
          fieldConfig.getValidator = () => {
            const cellMetadata = this.hotInstance.getCellMeta(rowIndex, columnIndex)
            if (!cellMetadata.readOnly) {
              return column.fieldConfig.getValidator()
            }
          }
        }

        form.registerField(fieldName, () => {}, { value: true }, fieldConfig)
      })
    })
  }

  mergeCellProps = (row, col, prop) => {
    const { cells, form, columns, dataPath } = this.props
    const column = columns[col]
    const cellConfig = cells ? cells(row, col, prop) || {} : {}

    /*
     * Validation
     */
    if (column.fieldConfig) {
      const fieldPath = `${dataPath}[${row}].${column.name}`
      const fieldState = form.getFieldState(fieldPath)

      if (fieldState) {
        cellConfig.hasError = fieldState.error && fieldState.touched
      }
    }

    return cellConfig
  }

  /**
   * Force table rerender when specified form field values change
   * @param fieldNames
   */
  rerenderOnChangeOf = (fieldNames = []) => {
    const { form } = this.props

    // TODO: don't forget to unsubscribe when the component is destroyed
    fieldNames.forEach(fieldName => {
      form.registerField(
        fieldName,
        (...args) => {
          if (this.hotInstance) {
            this.hotInstance.render()
          }
        },
        {
          value: true,
        }
      )
    })
  }

  render() {
    const {
      classes,
      cells,
      columns,
      data,
      form,
      dataPath,
      additionalData,
      rerenderOnChangeOf,
      initTable,
      ...restProps
    } = this.props
    const tableColumns = columns.map(col => ({ ...col, value: col.title, data: col.name }))

    return (
      <Field
        name={dataPath}
        subscription={{ value: true }}
        render={({ input }) => {
          const tableData = additionalData ? [...input.value, ...additionalData] : input.value

          return (
            <Table
              data={tableData}
              columns={tableColumns}
              initTable={this.initTable}
              cells={this.mergeCellProps}
              data-qa={`${dataPath}-table`}
              {...restProps}
            />
          )
        }}
      />
    )
  }
}

export default TableField
