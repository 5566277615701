import { invert } from 'lodash'
import capitalizeFP from 'lodash/fp/capitalize'
import flowFP from 'lodash/fp/flow'
import lowerCaseFP from 'lodash/fp/lowerCase'
import { point } from '@turf/helpers'
import distance from '@turf/distance'

import { BOROUGH_COUNTY_LOOKUP, BOROUGH_LOOKUP } from 'shared/constants/boroughs'

import { allDistrictInfo as njDistricts } from '../constants/njDistricts'

import { STATE_NAMES, STATE_NAMES_SHORT } from '../../../../shared/constants/states'

import { precisionRound } from './numberOperations'

export const DISTANCE_UNITS = {
  MILES: 'miles',
  KILOMETERS: 'kilometers',
}

export const calculateDistance = (source = {}, target = {}, unit = DISTANCE_UNITS.MILES, precision = 2) => {
  try {
    const from = point([source.longitude || source.lng, source.latitude || source.lat])
    const to = point([target.longitude || target.lng, target.latitude || target.lat])
    const result = distance(from, to, { units: unit })
    return precisionRound(result, precision)
  } catch (error) {
    return NaN
  }
}

export const getAddress = (streetAddress, city, propertyState, zip) => {
  const stateNamesLongHand = invert(STATE_NAMES)
  const subjectState = stateNamesLongHand[propertyState] || propertyState

  let formattedAddress
  if (subjectState === STATE_NAMES_SHORT.NY && city === BOROUGH_LOOKUP.MN) {
    const propertyCity = BOROUGH_COUNTY_LOOKUP.MN
    formattedAddress = `${propertyCity}, ${subjectState} ${zip}`
  } else {
    formattedAddress = `${city}, ${subjectState} ${zip}`
  }

  return {
    addressOne: streetAddress,
    addressTwo: formattedAddress,
  }
}

export const getCounty = ({ city, state, borough }) => {
  switch (state) {
    case STATE_NAMES.NY:
      return BOROUGH_COUNTY_LOOKUP[borough] || null
    case STATE_NAMES.NJ:
      const countyInfo = njDistricts.find(districtInfo => districtInfo.city.toUpperCase() === city.toUpperCase())
      return countyInfo ? flowFP([lowerCaseFP, capitalizeFP])(countyInfo.county) : null
    default:
      return null
  }
}
