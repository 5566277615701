import { GeneratedText, gtLocked, gtLockedSentence } from '../../../../../narrative/GeneratedText'

import { mapDTO, mapDataModel } from './mappings'

const generate = ({ includeRETaxesInOperatingExpenses }: { includeRETaxesInOperatingExpenses: boolean }) => {
  const totalForecastedValuePerSF = gtLocked('totalForecastedValue.sf', 'formatCurrencyFloat')
  const totalForecastedValuePerUnit = gtLocked('totalForecastedValue.unit', 'formatCurrencyInt')
  const minComparablePerSF = gtLocked('compTotalsMap.psfMin', 'formatCurrencyFloat')
  const maxComparablePerSF = gtLocked('compTotalsMap.psfMax', 'formatCurrencyFloat')
  const minComparablePerUnit = gtLocked('compTotalsMap.unitMin', 'formatCurrencyInt')
  const maxComparablePerUnit = gtLocked('compTotalsMap.unitMax', 'formatCurrencyInt')
  const totalExpenseRangeSentence = gtLockedSentence('totalExpenseRangeSentence')
  const compRangeIntro = includeRETaxesInOperatingExpenses
    ? 'Including real estate taxes'
    : 'Excluding real estate taxes'
  const operatingExpensesIntro = includeRETaxesInOperatingExpenses
    ? 'inclusive of real estate taxes'
    : 'exclusive of real estate taxes'

  const gt = new GeneratedText()

  gt.t`Operating expenses, ${operatingExpensesIntro}, were forecasted `
  gt.t`at ${totalForecastedValuePerSF} per square foot and ${totalForecastedValuePerUnit} per unit. `
  gt.t`${compRangeIntro}, the comparables ranged from ${minComparablePerSF} to ${maxComparablePerSF} `
  gt.t`per square foot and ${minComparablePerUnit} to ${maxComparablePerUnit} per unit. ${totalExpenseRangeSentence}`

  return gt.getParts()
}

export default { generate, mapDTO, mapDataModel }
