import React from 'react'
import PropTypes from 'prop-types'
import { compose } from 'recompose'
import CloseIcon from '@mui/icons-material/Close'
import { IconButton, DialogTitle, withStyles } from '@material-ui/core'

const styles = theme => {
  return {
    root: {
      marginRight: theme.spacing.unit * 4,
    },
    closeButton: {
      position: 'absolute',
      right: theme.spacing.unit / 2,
      top: theme.spacing.unit / 2,
      color: theme.palette.grey[500],
    },
  }
}

const DialogHeader = ({ children, onClose, classes, ...otherProps }) => (
  <DialogTitle classes={{ root: classes.root }} {...otherProps}>
    {children}
    {onClose && (
      <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
        <CloseIcon />
      </IconButton>
    )}
  </DialogTitle>
)

DialogHeader.propTypes = {
  onClose: PropTypes.func,
}

DialogHeader.defaultProps = {
  onClose: null,
}

export default compose(withStyles(styles), React.memo)(DialogHeader)
