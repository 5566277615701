import { connect } from 'react-redux'
import { get, forIn, isNil } from 'lodash'
import { createSelector } from 'reselect'

import NavigationSideBar from './NavigationSideBar'

const generateNavigationStructure = (reportStructure, formsList) => {
  const items = []

  forIn(reportStructure, (value, key) => {
    const form = formsList.find(form => form.key === key)
    if (isNil(form)) {
      return
    }

    if (isNil(value.showForm)) {
      form.forms = generateNavigationStructure(value.descendants, formsList)
    }

    items.push(form)
  })
  return items
}

const formsSelector = createSelector(
  state => get(state, 'report.reportStructure') || {},
  (state, ownProps) => get(ownProps, 'forms', []),
  generateNavigationStructure
)

const currentFormSelector = createSelector(
  state => get(state, 'router.location.pathname'),
  (state, ownProps) => get(ownProps, 'forms', []),
  (currentPath, formsList) => formsList.find(form => form.path === currentPath) || {}
)

export default connect((state, ownProps) => ({
  currentForm: currentFormSelector(state, ownProps),
  sections: formsSelector(state, ownProps),
  isBlocks: get(state, 'report.reportSettings.isBlocks'),
}))(NavigationSideBar)
