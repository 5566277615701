import React from 'react'

import { useFormState } from 'react-final-form'
import { get, map } from 'lodash'

import { EXPENSE_COMPARABLES_LABELED_CATEGORIES } from 'shared/constants/expenses/expenseComparables'
import { SECTIONS, showSection } from 'shared/helpers/propertyType'
import { titleCase } from 'shared/utils/formatters/textFormatters'
import { divide } from 'shared/utils/numberOperations'
import { getCommercialIncomeLabel, getCommercialLossLabel, getUsesInOrder } from 'shared/helpers/commercialUses'
import { toPercentageString } from 'client-shared/utils/numberFormatters'

export const useProFormaRows = propertyType => {
  const form = useFormState()
  const {
    commercialReimbursementSummary,
    commercialVCLossAmountByType = {},
    commercialVCPercentageByType = {},
    effectiveGrossIncome = {},
    expenses,
    includedUses,
    laundryIncome = {},
    laundryVCLossAmount = {},
    laundryVCRate,
    netOperatingIncome = {},
    operatingExpenseRatio,
    otherIncome = [],
    otherVCLossAmount = [],
    parkingIncome = {},
    parkingVCLossAmount = {},
    parkingVCRate,
    potentialCommercialIncomeByType = {},
    potentialGrossIncome = {},
    potentialResidentialIncome = {},
    resVCRate,
    residentialVCLossAmount = {},
    storageIncome = {},
    storageVCLossAmount = {},
    storageVCRate,
    totalOperatingExpenses = {},
    totalOperatingExpensesNetTaxes = {},
  } = form.values
  const showCommercialIncome = showSection(SECTIONS.HAS_COMMERCIAL, propertyType)

  const commercialUses = showCommercialIncome ? getUsesInOrder(includedUses) : []
  const commercialReimbursementSummaryRows = showCommercialIncome ? commercialReimbursementSummary : []

  const incomeItems = [
    ...otherIncome.map(otherIncomeItem => otherIncomeItem.total),
    potentialResidentialIncome.total,
    laundryIncome.total,
    storageIncome.total,
    parkingIncome.total,
    ...map(potentialCommercialIncomeByType, 'total'),
  ]

  const shouldShowSeparateOtherLine = incomeItems.reduce((isAnyIncomeItemEqualToPGI, currentValue) => {
    return isAnyIncomeItemEqualToPGI || currentValue === potentialGrossIncome.total
  }, false)

  const toRow = React.useCallback(
    (label, row = {}, pgiEgi = potentialGrossIncome.total) => {
      const total = get(row, 'total')
      return {
        label,
        ...row,
        percentageOfPgiAndEgi: divide(total, pgiEgi),
      }
    },
    [potentialGrossIncome.total]
  )
  const toSummaryRow = (label, row, pgiEgi) => {
    return toRow(label, { ...row, rowDef: { summary: true } }, pgiEgi)
  }

  const expensesRows = expenses.map(expense => {
    return toRow(
      EXPENSE_COMPARABLES_LABELED_CATEGORIES[expense.category] || expense.category,
      expense,
      effectiveGrossIncome.total
    )
  })
  const incomeRows = [
    potentialResidentialIncome.total && toRow('Potential Residential Income', potentialResidentialIncome),
    ...commercialUses.map(use => {
      const label = getCommercialIncomeLabel(use)
      const income = potentialCommercialIncomeByType[use]

      return toRow(label, income)
    }),
    ...commercialReimbursementSummaryRows.map((reimbursement, index) => {
      return toRow(`Potential ${reimbursement.reimbursementLabel} Reimbursement`, reimbursement.totalAnnual)
    }),
    parkingIncome.total && toRow('Parking Income', parkingIncome),
    storageIncome.total && toRow('Storage Income', storageIncome),
    laundryIncome.total && toRow('Laundry Income', laundryIncome),

    ...otherIncome.map(incomeItem => toRow(`${titleCase(incomeItem.category)} Income`, incomeItem)),
    shouldShowSeparateOtherLine && toRow('Other Income', {}),
    toSummaryRow('Potential Gross Income', potentialGrossIncome),
  ]

  const lossRows = [
    residentialVCLossAmount.total &&
      toRow(`Less Residential V/C Loss @ ${toPercentageString(resVCRate)}`, residentialVCLossAmount),
    ...commercialUses.map(use => {
      const lossPercentage = commercialVCPercentageByType[use]
      const lossAmount = commercialVCLossAmountByType[use]
      const label = getCommercialLossLabel(use, lossPercentage)

      return toRow(label, lossAmount)
    }),
    ...commercialReimbursementSummaryRows
      .filter(reimbursement => reimbursement.vcLoss > 0)
      .map((reimbursement, index) =>
        toRow(
          `Less ${reimbursement.reimbursementLabel} Reimbursement V/C Loss @ ${toPercentageString(
            reimbursement.vcLoss
          )}`,
          reimbursement.grossVcLoss
        )
      ),
    parkingVCLossAmount.total &&
      toRow(`Less Parking V/C Loss @ ${toPercentageString(parkingVCRate)}`, parkingVCLossAmount),
    laundryVCLossAmount.total &&
      toRow(`Less Laundry V/C Loss @ ${toPercentageString(laundryVCRate)}`, laundryVCLossAmount),
    storageVCLossAmount.total &&
      toRow(`Less Storage V/C Loss @ ${toPercentageString(storageVCRate)}`, storageVCLossAmount),
    ...otherVCLossAmount.map(loss =>
      toRow(`Less ${titleCase(`${loss.category}`)} V/C Loss @ ${toPercentageString(loss.vc)}`, loss)
    ),
  ]

  const summaryRows = [
    toSummaryRow('Effective Gross Income', effectiveGrossIncome),
    toSummaryRow('Operating Expenses', {}),
    ...expensesRows,
    toSummaryRow('Total Operating Expenses', totalOperatingExpenses, effectiveGrossIncome.total),
    toSummaryRow(
      'Total Operating Expenses (Excl. RE Taxes)',
      totalOperatingExpensesNetTaxes,
      effectiveGrossIncome.total
    ),
    toSummaryRow('Net Operating Income', netOperatingIncome, effectiveGrossIncome.total),
    toSummaryRow('Operating Expense Ratio', { total: operatingExpenseRatio }, NaN),
  ]

  return [...incomeRows, ...lossRows, ...summaryRows]
    .filter(Boolean)
    .map((row, index) => ({ ...row, id: `${row.label}_${index}` }))
}
