import React from 'react'

import { get } from 'lodash'

import { Grid } from '@mui/material'

import ShowSection from 'client-shared/components/ShowSection'
import { CheckboxWithLabel } from 'client-shared/components/Checkbox'
import { ImageUploadListField as ImageUploadList } from 'client-shared/components/ImageList'
import { Text } from 'client-shared/components'
import {
  TYPICAL_COURTYARD,
  TYPICAL_DRIVE_THRU,
  TYPICAL_LOADING,
  TYPICAL_OTHER,
  TYPICAL_OUTDOOR_SEATING,
  TYPICAL_STOREFRONT_EXPOSURE,
  TYPICAL_WAREHOUSE_SPACE,
} from 'client-shared/constants/imageCategories'
import { SECTIONS } from 'shared/helpers/propertyType'
import { notEmpty } from 'client-shared/utils/validation'

const BuildingAmenitiesCommercial = ({
  building: {
    hasCourtyard,
    hasDriveThru,
    hasLoading,
    hasOtherAmenities,
    hasOutdoorSeating,
    hasStorefrontExposure,
    hasWarehouseSpace,
  },
}) => {
  const validateOtherAmenities = React.useCallback((value, allValues) => {
    const isOtherAmenity = get(allValues, 'building.hasOtherAmenities', false)
    return isOtherAmenity ? notEmpty(value) : null
  }, [])

  return (
    <ShowSection section={SECTIONS.HAS_COMMERCIAL}>
      <Grid container paddingTop={2} data-qa="courtyard-section-tile">
        <CheckboxWithLabel name="building.hasCourtyard" label="Courtyard" />
        {hasCourtyard && <ImageUploadList name="courtyardImages" category={TYPICAL_COURTYARD} />}
      </Grid>
      <Grid container paddingTop={2} data-qa="loading-section-tile">
        <CheckboxWithLabel name="building.hasLoading" label="Loading" />
        {hasLoading && <ImageUploadList name="loadingImages" category={TYPICAL_LOADING} />}
      </Grid>
      <Grid container paddingTop={2} data-qa="storeFrontExposure-section-tile">
        <CheckboxWithLabel name="building.hasStorefrontExposure" label="Storefront/Exposure" />
        {hasStorefrontExposure && (
          <ImageUploadList name="storefrontExposureImages" category={TYPICAL_STOREFRONT_EXPOSURE} />
        )}
      </Grid>
      <Grid container paddingTop={2} data-qa="warehouseSpace-section-tile">
        <CheckboxWithLabel name="building.hasWarehouseSpace" label="Warehouse Space" />
        {hasWarehouseSpace && <ImageUploadList name="warehouseSpaceImages" category={TYPICAL_WAREHOUSE_SPACE} />}
      </Grid>
      <Grid container paddingTop={2} data-qa="outdoorSeating-section-tile">
        <CheckboxWithLabel name="building.hasOutdoorSeating" label="Outdoor Seating" />
        {hasOutdoorSeating && <ImageUploadList name="outdoorSeatingImages" category={TYPICAL_OUTDOOR_SEATING} />}
      </Grid>
      <Grid container paddingTop={2} data-qa="driveThru-section-tile">
        <CheckboxWithLabel name="building.hasDriveThru" label="Drive Thru" />
        {hasDriveThru && <ImageUploadList name="driveThruImages" category={TYPICAL_DRIVE_THRU} />}
      </Grid>
      <Grid container paddingTop={2} data-qa="other-section-tile">
        <CheckboxWithLabel name="building.hasOtherAmenities" label="Other" />
        {hasOtherAmenities && (
          <Text
            fullWidth
            label="Which other amenities does this building have?"
            margin="normal"
            name="building.otherBuildingAmenity"
            placeholder=""
            required={hasOtherAmenities}
            validate={validateOtherAmenities}
          />
        )}
        {hasOtherAmenities && <ImageUploadList name="otherAmenitiesImages" category={TYPICAL_OTHER} />}
      </Grid>
    </ShowSection>
  )
}

export default BuildingAmenitiesCommercial
