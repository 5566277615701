import * as React from 'react'
import PropTypes from 'prop-types'

import { Checkbox as MuiCheckbox } from '@mui/material'
import { get, noop } from 'lodash'
import { Field } from 'react-final-form'

function MaterialCheckbox({ disabled, handleChange, input, label, meta, ...props }) {
  const checked = get(input, 'checked', false)
  const value = !!checked
  const { name, onBlur, onFocus } = input

  const onChange = (event, value) => {
    input.onChange(event, value)
    handleChange(event, value)
  }

  return (
    <MuiCheckbox
      {...props}
      checked={checked}
      data-qa={`${name}${checked ? '-checked' : ''}`}
      disabled={disabled}
      label={label}
      onChange={onChange}
      value={value.toString()}
      onFocus={onFocus}
      onBlur={onBlur}
    />
  )
}

MaterialCheckbox.propTypes = {
  checkboxClasses: PropTypes.object,
  disabled: PropTypes.bool,
  handleChange: PropTypes.func,
  input: PropTypes.shape({
    checked: PropTypes.any,
    name: PropTypes.string,
    onChange: PropTypes.func,
    value: PropTypes.any,
  }).isRequired,
}

MaterialCheckbox.defaultProps = {
  fullWidth: true,
  handleChange: noop,
}

export const CheckboxComponent = MaterialCheckbox

export class Checkbox extends React.PureComponent {
  static propTypes = {
    name: PropTypes.string.isRequired,
    label: PropTypes.node,
    disabled: PropTypes.bool,
    fullWidth: PropTypes.bool,
  }

  static defaultProps = {
    disabled: false,
    label: '',
  }

  render() {
    const { name, label, disabled, ...props } = this.props
    return (
      <Field name={name} label={label} disabled={disabled} component={CheckboxComponent} {...props} type="checkbox" />
    )
  }
}
