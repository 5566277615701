import { GeneratedText, gtLocked } from '../../../narrative/GeneratedText'

import { mapDTO, mapDataModel } from './mappings'

const generate = () => {
  const gt = new GeneratedText()
  const siteAreaSqFtGt = gtLocked('siteAreaSqFt')
  const zoneStringGt = gtLocked('zoneString')
  const identificationSentenceGt = gtLocked('identificationSentence')
  const blockLotStringGt = gtLocked('blockLotString')
  gt.t`The subject is situated on a ${siteAreaSqFtGt} parcel in ${zoneStringGt}`
  gt.t` and is identified in ${identificationSentenceGt} tax maps ${blockLotStringGt}`

  return gt.getParts()
}

export default { generate, mapDTO, mapDataModel }
