import { GeneratedText, gtLocked, addConditionals, gtTemplate, gtConditional } from '../../../narrative/GeneratedText'

import { mapDTO, mapDataModel } from './mappings'

const generate = () => {
  const gt = new GeneratedText()

  const inspectionDateGt = gtLocked('inspectionDate', 'longDateFormatWithoutLeadingZero', 'Inspection Date')
  const inspectorGt = gtLocked('inspector', null, 'Inspector Name')
  const locationsListGt = gtLocked('locationsList', null, 'Locations')
  const stairsConditionGt = gtLocked('stairsCondition', null, 'Stairs Condition')
  const roofConditionGt = gtLocked('roofCondition', null, 'Roof Condition')
  const propertyConditionGt = gtLocked('propertyCondition', null, 'Property Condition')

  gt.t`On ${inspectionDateGt}, ${inspectorGt} conducted an interior and exterior inspection of the subject property.
    The inspection included a tour of the ${locationsListGt}.`

  addConditionals(gt, {
    stairsInspected: gtTemplate` We were able to inspect the stairs and they are ${stairsConditionGt}.`,
    stairsNotInspected: ' We were unable to inspect the stairs, but assume they are in average condition.',
  })

  addConditionals(gt, {
    roofInspected: gtTemplate` We were able to inspect the roof and it is ${roofConditionGt}.`,
    roofNotInspected: ' We were unable to inspect the roof, but assume it is in average condition.',
  })
  gt.t` Nevertheless, we recommend a roof inspection by a qualified professional.`

  addConditionals(gt, {
    adverseConditionsAndHazardousSubstances:
      ' We observed hazardous substances on the improvements as well as adverse environmental and physical conditions.',
    noAdverseConditionsOrHazardousSubstances:
      ' Moreover, we did not observe any hazardous substances on the improvements or adverse environmental or physical conditions.',
    noAdverseConditions: ' Moreover, we did not observe any adverse environmental or physical conditions.',
    noHazardousSubstances: ' Moreover, we did not observe any hazardous substances on the improvements.',
  })

  gt.addPart(
    gtConditional(
      'valueAsComplete',
      gtTemplate` It is assumed that the subject property will be ${propertyConditionGt}.`
    )
  )

  return gt.getParts()
}

export default { generate, mapDTO, mapDataModel }
