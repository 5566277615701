import React from 'react'

import NumberFormat from 'react-number-format'

import { createComposed } from '../../tools'

import Default from './Input'

const Text = createComposed(Default, {
  format: { autoComplete: 'off' },
  custom: {
    helper: { bottom: -21, position: 'absolute' },
  },
})

const NumberFormatRefRouted = React.forwardRef((props, ref) => <NumberFormat getInputRef={ref} {...props} />)

const Numbers = createComposed(Text, {
  InputProps: { inputComponent: NumberFormatRefRouted },
})

const DollarAmount = createComposed(Numbers, {
  format: {
    decimalScale: 2,
    fixedDecimalScale: true,
    prefix: '$',
    thousandSeparator: true,
  },
})

const TableNumbers = createComposed(Numbers, {
  custom: {
    border: 'none',
    input: { padding: 0, textAlign: 'right' },
  },
})

const SmallTableNumbers = createComposed(TableNumbers, {
  custom: {
    helper: { bottom: -9, position: 'absolute', right: -12 },
  },
})

const TableDollarAmount = createComposed([DollarAmount, TableNumbers])

const SmallTabletDollarAmount = createComposed([DollarAmount, SmallTableNumbers])

export { DollarAmount, Numbers, SmallTableNumbers, SmallTabletDollarAmount, TableDollarAmount, TableNumbers, Text }
