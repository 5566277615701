import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import arrayToSentence from 'array-to-sentence'
import { get, startsWith, isEmpty, sumBy, filter } from 'lodash'

import { simplePluralize } from '../../../../../../shared/utils/textGeneration'

import { formatCurrencyFloat, formatCurrencyInt } from '../../../../shared/utils/numberFormatters'
import { GeneratedComment } from '../../../../shared/components'

import { RenovationCostTypes, ProspectiveRenovationTypes } from './constants'

const getExpenseCosts = items => {
  const hardFinancingItems = filter(items, item => startsWith(item.expenseType, 'Hard'))
  const softFinancingItems = filter(items, item => startsWith(item.expenseType, 'Soft'))

  const costs = []
  if (!isEmpty(hardFinancingItems)) {
    const hardFinancingTotal = sumBy(hardFinancingItems, 'amount') || 0
    costs.push(`${formatCurrencyFloat(hardFinancingTotal)} in direct costs`)
  }

  if (!isEmpty(softFinancingItems)) {
    const softFinancingTotal = sumBy(softFinancingItems, 'amount') || 0
    costs.push(`${formatCurrencyFloat(softFinancingTotal)} in indirect costs`)
  }

  const costsPart = arrayToSentence(costs)
  return costsPart && `, which includes ${costsPart}`
}

const generateComment = ({ renovationCostsType, prospectiveRenovationType, squareFootage, ...values }) => {
  const renovationValues = get(values, renovationCostsType.toLowerCase())
  const total = get(renovationValues, 'total.amount', 0)
  const budgetPerSqft = get(renovationValues, 'total.amount', 0) / squareFootage
  const lessAmountSpent = get(renovationValues, 'total.lessAmountSpent', 0)
  const renovationItems = get(renovationValues, 'items', [])
  const monthsUntilComplete = get(renovationValues, 'total.renovationPeriod', 0)
  const isGutRenovation = prospectiveRenovationType === ProspectiveRenovationTypes.GUT_RENOVATION
  const formattedTotal = formatCurrencyInt(total)
  const renovationsItemsList = arrayToSentence(renovationItems.filter(item => item.item).map(item => item.item))
  const renovationsList =
    renovationCostsType === RenovationCostTypes.TOTAL ? '' : `, which includes ${renovationsItemsList}`
  const summarySentence = `The owner reported a total prospective${
    isGutRenovation ? ' gut' : ''
  } renovation budget of ${formattedTotal}${renovationsList}.`

  const spentToDateSentence =
    lessAmountSpent === 0 || lessAmountSpent === ''
      ? ' To date, none of this budget has been spent.'
      : ` To date, ${formatCurrencyInt(lessAmountSpent)} of this budget has been spent.`

  const expenseCostsList = getExpenseCosts(renovationItems)
  const formattedBudgetPerSqft = formatCurrencyFloat(budgetPerSqft)
  const expenseCostsSentence = ` This budget equates to ${formattedBudgetPerSqft} per square foot${expenseCostsList}.`

  const timeUntilCompleteSentence = monthsUntilComplete
    ? ` Renovations are expected to take ${monthsUntilComplete} ${simplePluralize(
        'month',
        monthsUntilComplete
      )} to complete.`
    : ''

  return `${summarySentence}${spentToDateSentence}${expenseCostsSentence}${timeUntilCompleteSentence}`
}

const RenovationsGeneratedComment = ({ values, squareFootage }) => (
  <GeneratedComment
    dataPath="prospectiveRenovationsDiscussion"
    getGeneratedText={values => generateComment({ squareFootage, ...values })}
    title="Generated Commentary"
    label="Prospective Renovations Discussion"
    isDynamicContent
  />
)

RenovationsGeneratedComment.propTypes = {
  values: PropTypes.object.isRequired,
  squareFootage: PropTypes.number.isRequired,
}

export default connect((state, ownProps) => ({
  ...ownProps,
  squareFootage: get(state, 'report.reportData.propertyInformation.propertySummary.squareFootage', 1),
}))(RenovationsGeneratedComment)
