export default theme => ({
  root: {
    marginRight: 8,
    marginBottom: theme.spacing.unit,
    width: '100%',
    '& .Select': {
      margin: theme.spacing.unit / 2,
    },
    '& .Select-menu-outer': {
      width: '100%',
    },
  },
  denseRoot: {
    margin: 0,
    '& .Select-menu-outer': {
      marginTop: 5,
      width: '100%',
    },
  },
})
