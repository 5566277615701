import { get } from 'lodash'

import { formatPropertyCondition } from '../description'

export const mapDTO = formValues => {
  const { yearBuilt, condition } = formValues

  const formattedCondition = formatPropertyCondition(condition)
  return { yearBuilt, condition: formattedCondition }
}

export const mapDataModel = report => {
  const yearBuilt = get(report, 'property_information.yearBuilt')
  const condition = get(report, 'buildingDescription.generalCondition')

  const formattedCondition = formatPropertyCondition(condition)
  return {
    yearBuilt,
    condition: formattedCondition,
  }
}
