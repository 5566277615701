import React from 'react'
import PropTypes from 'prop-types'
import { map, isEqual, get, uniqBy } from 'lodash'
import { shouldUpdate, compose } from 'recompose'
import { Grid, withStyles } from '@material-ui/core'

import { connect } from 'react-redux'

import * as Api from '../../../../../../../etc/buildingComparables/api'

import NewCompsSelectionModal from '../../../../../../../shared/components/NewCompsSelectionModal'
import LeafletMap from '../../../../../../../shared/components/Map/LeafletMap'
import { Loading } from '../../../../../../../shared/components'

import { selectPropertiesDetails, selectSelectedBuildingCompsFromFormValues } from '../../redux/selectors'
import {
  searchProperties,
  clearProperties,
  setBuildingCompsMapPolygon,
} from '../../../../../../../etc/buildingComparables/redux/actions'

import { subjectPropertyAddressSelector } from '../../../shared/redux/selectors'

import PropertyDetails from './PropertyDetails'

const styles = theme => ({
  mapWrapper: {
    position: 'relative',
  },
  popup: {
    backgroundColor: 'white',
    boxShadow:
      '0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12)',
    '& .leaflet-popup-content-wrapper': {
      boxShadow: 'none',
      padding: 0,
    },
    '& .leaflet-popup-content': {
      padding: 0,
      margin: 0,
    },
  },
})

class PropertySearchMap extends React.PureComponent {
  static propTypes = {
    isLoading: PropTypes.bool,
    isCustomSelection: PropTypes.bool,
    subjectPropertyCoords: PropTypes.shape({
      lat: PropTypes.number,
      lng: PropTypes.number,
    }),
    properties: PropTypes.arrayOf(PropTypes.object),
    buildingCompProperties: PropTypes.arrayOf(PropTypes.object),
    setMapPolygon: PropTypes.func.isRequired,
    searchProperties: PropTypes.func.isRequired,
    onAddProperty: PropTypes.func.isRequired,
    onRemoveProperty: PropTypes.func.isRequired,
    form: PropTypes.object,
  }

  static defaultProps = {
    isLoading: false,
    isCustomSelection: false,
    properties: [],
  }

  componentWillUnmount() {
    this.props.clearProperties()
  }

  onViewportChanged = polygon => {
    this.props.setMapPolygon(polygon)
    this.props.searchProperties({
      polygon: polygon.coords,
    })
  }

  updateProperties = async newProperties => {
    const newBuildingComps = await Api.addMultipleBuildingCompsToReport(this.props.reportId, newProperties)
    const { selectedComps, form } = this.props
    const newSelectedProperties = uniqBy([...selectedComps, ...newBuildingComps], comp => comp._id)
    form.change('selectedBuildingComps', newSelectedProperties)
  }

  getClusterGroup = () => {
    const { properties, classes } = this.props
    return map(properties, ({ meta: { id, position, isMarked }, item }) => {
      return {
        hasPopup: true,
        key: id,
        position,
        isMarked,
        popup: (
          <PropertyDetails
            {...item}
            onAddProperty={this.props.onAddProperty}
            onRemoveProperty={this.props.onRemoveProperty}
            isSelected={isMarked}
          />
        ),
        isRaised: false,
        hideCloseButton: true,
        popupProps: {
          className: classes.popup,
          maxWidth: 400,
          minWidth: 400,
        },
      }
    })
  }

  render() {
    const { classes, isLoading, isCustomSelection, buildingCompProperties } = this.props
    return (
      <Grid className={classes.mapWrapper}>
        <NewCompsSelectionModal
          isCustomSelection={isCustomSelection}
          items={buildingCompProperties}
          updateItems={this.updateProperties}
        />
        <LeafletMap
          height={600}
          center={this.props.subjectPropertyCoords}
          onViewportChanged={this.onViewportChanged}
          clusterGroup={this.getClusterGroup()}
          shouldShowCenterItem={true}
        />
        {isLoading && <Loading />}
      </Grid>
    )
  }
}

const PropertySearchMapComponent = compose(
  withStyles(styles),
  shouldUpdate((props, nextProps) => {
    const result = !isEqual(
      nextProps.properties.map(property => property.meta),
      props.properties.map(property => property.meta)
    )
    return result || props.isLoading !== nextProps.isLoading
  })
)(PropertySearchMap)

const mapStateToProps = (state, ownProps) => {
  const reportId = get(state, 'report.reportData._id')
  return {
    reportId,
    ...subjectPropertyAddressSelector(state),
    isLoading: get(state, 'buildingComp.isLoading', false),
    isCustomSelection: get(state, 'buildingComp.isCustomSelection', false),
    buildingCompProperties: get(state, 'buildingComp.properties', []),
    selectedComps: selectSelectedBuildingCompsFromFormValues(state, ownProps),
    properties: selectPropertiesDetails(state, ownProps),
  }
}

export default connect(mapStateToProps, {
  setMapPolygon: setBuildingCompsMapPolygon,
  searchProperties,
  clearProperties,
})(PropertySearchMapComponent)
