export const UNIT_TYPE = 'Unit'
export const SF_TYPE = 'Square Foot'

export const ELEVATOR = 'elevator'
export const WALK_UP = 'walk-up'
export const BOTH = 'both'

export const UNIT_OF_COMPARISON_TYPES = {
  SF: 'sf',
  RESIDENTIAL_UNITS: 'residentialUnits',
  TOTAL_UNITS: 'totalUnits',
  APPROVED_BUILDABLE_UNITS: 'approvedBuildableUnits',
  MAX_BUILDABLE_AREA: 'maxBuildableArea',
  SITE_AREA_SF: 'siteAreaSf',
  SITE_AREA_ACRES: 'siteAreaAcres',
  LOT: 'lot',
}

export const UNIT_OF_COMPARISON_LABELS = {
  SF: 'Per Gross Building Area',
  RESIDENTIAL_UNITS: 'Per Residential Units',
  TOTAL_UNITS: 'Per Total Units',
  APPROVED_BUILDABLE_UNITS: 'Per Approved Builadable Units',
  MAX_BUILDABLE_AREA: 'Per Maximum Builadable Area',
  SITE_AREA_SF: 'Per Site Area (SF)',
  SITE_AREA_ACRES: 'Per Site Area (Acres)',
  LOT: 'Per Lot (Gross Value of the Sale)',
}

export const UNIT_OF_COMPARISON_DISPLAY = {
  sf: UNIT_OF_COMPARISON_LABELS.SF,
  residentialUnits: UNIT_OF_COMPARISON_LABELS.RESIDENTIAL_UNITS,
  totalUnits: UNIT_OF_COMPARISON_LABELS.TOTAL_UNITS,
  approvedBuildableUnits: UNIT_OF_COMPARISON_LABELS.APPROVED_BUILDABLE_UNITS,
  maxBuildableArea: UNIT_OF_COMPARISON_LABELS.MAX_BUILDABLE_AREA,
  siteAreaSf: UNIT_OF_COMPARISON_LABELS.SITE_AREA_SF,
  siteAreaAcres: UNIT_OF_COMPARISON_LABELS.SITE_AREA_ACRES,
  lot: UNIT_OF_COMPARISON_LABELS.LOT,
}

export const UNIT_OF_COMPARISON_ITEMS = [
  { value: UNIT_OF_COMPARISON_TYPES.SF, label: UNIT_OF_COMPARISON_LABELS.SF },
  { value: UNIT_OF_COMPARISON_TYPES.RESIDENTIAL_UNITS, label: UNIT_OF_COMPARISON_LABELS.RESIDENTIAL_UNITS },
  { value: UNIT_OF_COMPARISON_TYPES.TOTAL_UNITS, label: UNIT_OF_COMPARISON_LABELS.TOTAL_UNITS },
  {
    value: UNIT_OF_COMPARISON_TYPES.APPROVED_BUILDABLE_UNITS,
    label: UNIT_OF_COMPARISON_LABELS.APPROVED_BUILDABLE_UNITS,
  },
  { value: UNIT_OF_COMPARISON_TYPES.MAX_BUILDABLE_AREA, label: UNIT_OF_COMPARISON_LABELS.MAX_BUILDABLE_AREA },
  { value: UNIT_OF_COMPARISON_TYPES.SITE_AREA_SF, label: UNIT_OF_COMPARISON_LABELS.SITE_AREA_SF },
  { value: UNIT_OF_COMPARISON_TYPES.SITE_AREA_ACRES, label: UNIT_OF_COMPARISON_LABELS.SITE_AREA_ACRES },
  { value: UNIT_OF_COMPARISON_TYPES.LOT, label: UNIT_OF_COMPARISON_LABELS.LOT },
]

export const SALES_APPROACH_TYPE_ITEMS = [
  { value: 'improved', label: 'Sales Comparison Approach' },
  { value: 'land', label: 'Land Valuation' },
]

export const SALES_APPROACH_TYPES = {
  LAND: 'land',
  IMPROVED: 'improved',
}

export const SALES_APPROACH_TYPE_DISPLAY = { improved: 'Sales Comparison Approach', land: 'Land Valuation' }

export const EXPORT_IN_VALUES = {
  ADDENDA: 'Addenda',
  REPORT_BODY: 'Report Body',
}

export const SALES_ADJUSTMENT_AUTOMATION_TYPES = {
  NONE: 'none',
  DYNAMIC: 'dynamic',
  STATIC: 'static',
}

export const SALES_ADJUSTMENT_AUTOMATION_VALUES = [
  SALES_ADJUSTMENT_AUTOMATION_TYPES.NONE,
  SALES_ADJUSTMENT_AUTOMATION_TYPES.DYNAMIC,
  SALES_ADJUSTMENT_AUTOMATION_TYPES.STATIC,
]
