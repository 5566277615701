import React from 'react'
import PropTypes from 'prop-types'

import filter from 'lodash/filter'
import get from 'lodash/get'
import isEmpty from 'lodash/isEmpty'
import kebabCase from 'lodash/kebabCase'
import map from 'lodash/map'

import BoweryDate from '@bowery-valuation/bowery-date'

import Stack from '@mui/material/Stack'

import { approachesDecorators } from 'report/components/ReportSettings/decorators'
import { REVIEW_AND_EXPORT_KEY, REVIEW_AND_EXPORT_PATH } from 'shared/constants/report/keysAndDataPaths'

import { ExportStatuses } from '../../../../../shared/constants/export'
import HideIfIsBlocks from '../../../../src/shared/hocs/HideIfIsBlocks'
import { Loading } from '../../../shared/components'
import wrapForm from '../wrapForm'

import { init } from './redux/actions'

import { getDownloadUrl } from './helpers'

import ExportReportContainer from './ExportReportContainer'
import ReportSettingsSection from './ReportSettingsSection'
import ReportStatusContainer from './ReportStatusContainer'

const DATA_PATH = REVIEW_AND_EXPORT_PATH
const HEADING = 'Review and Export'

class ReviewAndExport extends React.PureComponent {
  componentWillMount() {
    const { getExportItems } = this.props
    getExportItems()
  }

  render() {
    const { areExportsLoading, exportItems } = this.props

    return (
      <Stack maxWidth={950} padding={2} spacing={2}>
        <ReportSettingsSection />
        <HideIfIsBlocks>
          <ReportStatusContainer nextFormPath={kebabCase(REVIEW_AND_EXPORT_KEY)} />
        </HideIfIsBlocks>
        {areExportsLoading ? <Loading /> : <ExportReportContainer exportItems={exportItems} />}
      </Stack>
    )
  }
}

ReviewAndExport.propTypes = {
  exportItems: PropTypes.arrayOf(PropTypes.object.isRequired),
  getExportItems: PropTypes.func.isRequired,
  hasExportItems: PropTypes.bool,
  nextFormPath: PropTypes.string.isRequired,
}

ReviewAndExport.defaultProps = {
  exportItems: [],
  hasExportItems: false,
}

const mapStateToProps = state => {
  const address = get(state, 'report.reportData.address', '').trim()
  const borough = get(state, 'report.reportData.borough', '').trim()
  const city = get(state, 'report.reportData.city', '').trim()
  const number = get(state, 'report.reportData.number', '').trim()
  const reportId = get(state, 'report.reportData._id')
  const reportState = get(state, 'report.reportData.state', '').trim()
  const username = get(state, 'authentication.user.username')
  const zip = get(state, 'report.reportData.zip', '').trim()

  const downloadFolderUrl = get(state, 'export.downloadFolderUrl')
  const exportItems = get(state, 'export.exportItems')

  const mapDocuments = items => {
    return map(
      filter(items, item => {
        return !item.hasOwnProperty('report') || item.report === reportId
      }),
      item => {
        const extension = (item.baseTemplateFileName.match(/\.[^/]+$/)?.[0] || '').trim()
        const generatedAt = new BoweryDate(item.updatedAt).toLocalTime().format('MM.DD.YY')
        const downloadUrl = getDownloadUrl({
          downloadFolderUrl,
          queryParams: {
            extension,
            generatedAt,
            location: { address, city, borough, state: reportState, zip },
            number,
            username,
          },
          reportId,
        })

        item.isLastItemCompleted =
          item.status === ExportStatuses.ERROR ||
          item.status === ExportStatuses.COMPLETE ||
          item.status === ExportStatuses.WARNING
        item.downloadUrl = downloadUrl

        return item
      }
    )
  }

  return {
    areExportsLoading: get(state, 'export.areExportsLoading', false),
    exportItems: mapDocuments(exportItems),
    hasExportItems: !isEmpty(exportItems),
    reportId,
  }
}

export default wrapForm(
  DATA_PATH,
  {
    heading: HEADING,
    decorators: approachesDecorators,
  },
  mapStateToProps,
  { getExportItems: init }
)(ReviewAndExport)
