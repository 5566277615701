import React, { FC } from 'react'

import { Grid } from '@mui/material'

import { Subsection } from '../types'

import { GenericSubsectionCard } from './GenericSubsectionCard'

type ViewGenericSubsectionsProps = {
  classes: any
  subsections: Subsection[]
  editSubsection: (subsection: Subsection) => void
}

const ViewGenericSubsections: FC<ViewGenericSubsectionsProps> = ({ classes, subsections, editSubsection }) => {
  return (
    <Grid className={classes.subsectionGrid} container spacing={2}>
      {subsections.map(subsection => {
        if (!subsection.error) {
          return (
            <GenericSubsectionCard
              key={subsection.name}
              subsection={subsection}
              classes={classes}
              editSubsection={() => editSubsection(subsection)}
            />
          )
        }
        return null
      })}
    </Grid>
  )
}

export default ViewGenericSubsections
