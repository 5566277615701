export const STREET_TYPES = ['side street', 'avenue']
export const LEASE_TERMS = ['gross', 'modified gross', 'triple-net']
export const RENT_TYPES = ['annually', 'per square foot per year', 'monthly', 'per square foot per month']
export const OWNER_TYPE = ['owner', 'tenant', 'shared']
export const USES_VALUES = {
  RETAIL: 'retail',
  OFFICE: 'office',
  MEDICAL: 'medical',
  COMMUNITY: 'community',
  INDUSTRIAL: 'industrial',
  UNDETERMINED: 'undetermined',
  OTHER: 'other',
}

export const USES = Object.values(USES_VALUES)
export const FACADE = ['plate glass']
export const LOCATIONS = ['corner', 'mid-block']

export const FLOORS = ['below grade', 'ground floor/basement', 'ground floor', 'upper floor']

export const WELL_SUITED = 'well suited'
export const LOCATION_COMMERCIAL_SPACE = ['excellent', WELL_SUITED, 'average', 'below average']

export const CEILING_HEIGHTS = {
  LOW: 'Low',
  NORMAL: 'Normal',
  HIGH: 'High',
  OTHER: 'Other',
}
export const FRONTAGE = {
  SMALL: 'Small',
  MEDIUM: 'Medium',
  LARGE: 'Large',
  OTHER: 'Other',
}
export const UNIT_STATES = ['Finished', 'Unfinished', 'Vanilla Box', 'Other']
