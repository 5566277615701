import * as React from 'react'
import PropTypes from 'prop-types'
import { Grid, Paper, withStyles } from '@material-ui/core'
import arrayToSentence from 'array-to-sentence'

import { arrayToKeyValuePairs } from '../../../../shared/utils/ui/checkboxHelper'
import { Number, RadioButtonList, GeneratedComment, CheckboxWithLabel } from '../../../../shared/components'
import { required } from '../../../../shared/utils/validation'

import { formatInt, formatCurrencyInt } from '../../../../shared/utils/numberFormatters'

import { RECENT_RENOVATIONS, RECENT_GUT_RENOVATION, DO_NOT_DISCUSS } from './constants'
import { paperStyles } from './styles'

const styles = theme => ({
  paper: { ...theme.paper, ...paperStyles },
})

const RENOVATION_TYPES = [RECENT_RENOVATIONS, RECENT_GUT_RENOVATION, DO_NOT_DISCUSS]
const renovationTypes = arrayToKeyValuePairs(RENOVATION_TYPES)

const ITEMIZED_CAPITAL_EXPENDITURE = 'Itemized Capital Expenditure'
const TOTAL_CAPITAL_EXPENDITURE = 'Total Capital Expenditure'
const UNKNOWN_CAPITAL_EXPENDITURE = 'Unknown capital expenditure'
const RENOVATION_INFO = [ITEMIZED_CAPITAL_EXPENDITURE, TOTAL_CAPITAL_EXPENDITURE, UNKNOWN_CAPITAL_EXPENDITURE]
const renovationInfoArr = arrayToKeyValuePairs(RENOVATION_INFO)

class Renovations extends React.PureComponent {
  static propTypes = {
    typeOfRenovation: PropTypes.string.isRequired,
    renovationInfo: PropTypes.string.isRequired,
  }

  getGeneratedText = values => {
    const {
      renovationInfo,
      typeOfRenovation,
      yearOfRenovation,
      hasRenovatingUnits,
      hasHeatingSystem,
      hasNewFacade,
      hasNewRoof,
      hasCommonInteriorSpace,
      totalCapitalExpenditure,
      renovatingUnitsExpense,
      newFacade,
      newRoof,
      heating,
      commonInteriorSpace,
    } = values

    function mapRenovatedItems({
      hasRenovatingUnits,
      hasHeatingSystem,
      hasNewFacade,
      hasNewRoof,
      hasCommonInteriorSpace,
      withExpenses,
      renovatingUnitsExpense,
      newFacade,
      newRoof,
      heating,
      commonInteriorSpace,
    }) {
      const renovatedItems = []
      if (hasRenovatingUnits) {
        renovatedItems.push(
          `${
            withExpenses
              ? `${formatCurrencyInt(renovatingUnitsExpense)} for interior remodeling of units`
              : 'interior remodeling of units'
          }`
        )
      }
      if (hasHeatingSystem) {
        renovatedItems.push(`${withExpenses ? `${formatCurrencyInt(heating)} for the` : 'a'} heating system`)
      }
      if (hasNewFacade) {
        renovatedItems.push(`${withExpenses ? `${formatCurrencyInt(newFacade)} for a` : 'a'} new facade`)
      }
      if (hasNewRoof) {
        renovatedItems.push(`${withExpenses ? `${formatCurrencyInt(newRoof)} for a` : 'a'} new roof`)
      }
      if (hasCommonInteriorSpace) {
        renovatedItems.push(
          `${withExpenses ? `${formatCurrencyInt(commonInteriorSpace)} for the common areas` : 'common areas'}`
        )
      }
      return renovatedItems
    }

    const renovatedItems = mapRenovatedItems({
      hasRenovatingUnits,
      hasHeatingSystem,
      hasNewFacade,
      hasNewRoof,
      hasCommonInteriorSpace,
    })

    const renovatedItemsSentence = arrayToSentence(renovatedItems)
    const gutRenovationsWord = typeOfRenovation === RECENT_GUT_RENOVATION ? 'gut ' : ''
    const renovatedItemsStart = renovatedItems.length > 1 ? 'The renovations were' : 'The renovation was'
    return `The subject was most recently ${gutRenovationsWord}renovated in ${yearOfRenovation || '[Year Renovated]'}.${
      renovationInfo === 'Itemized Capital Expenditure'
        ? ` ${renovatedItemsStart} primarily comprised of ${
            arrayToSentence(
              mapRenovatedItems({
                hasRenovatingUnits,
                hasHeatingSystem,
                hasNewFacade,
                hasNewRoof,
                hasCommonInteriorSpace,
                totalCapitalExpenditure,
                renovatingUnitsExpense,
                newFacade,
                newRoof,
                heating,
                commonInteriorSpace,
                withExpenses: true,
              })
            ) || '[checkbox]'
          }.`
        : ''
    }${
      renovationInfo === 'Total Capital Expenditure'
        ? ` ${renovatedItemsStart} primarily comprised of ${
            renovatedItemsSentence || '[checkbox]'
          }. The owner reported a total budget of $${
            formatInt(totalCapitalExpenditure) || '[Total Capital Expenditure]'
          }.`
        : ''
    }${
      renovationInfo === 'Unknown capital expenditure'
        ? ` ${renovatedItemsStart} primarily comprised of ${
            renovatedItemsSentence || '[checkbox]'
          }. We have not been provided with the costs expended during this renovation.`
        : ''
    }`
  }

  render() {
    const { classes, typeOfRenovation, renovationInfo } = this.props
    const isInRecentRenovationState = typeOfRenovation === RECENT_RENOVATIONS
    const isInGutRenovationState = typeOfRenovation === RECENT_GUT_RENOVATION
    const isInRenovationState = isInRecentRenovationState || isInGutRenovationState

    const showItemizedExpenses = renovationInfo === ITEMIZED_CAPITAL_EXPENDITURE

    return (
      <React.Fragment>
        <Grid component={Paper} item className={classes.paper} data-qa="type-of-recent-renovation-section-tile">
          <RadioButtonList
            items={renovationTypes}
            name="typeOfRenovation"
            horizontal={true}
            label="What types of renovations were performed?"
          />
        </Grid>
        {isInRenovationState && (
          <Grid component={Paper} item className={classes.paper}>
            <GeneratedComment
              title="Generated Commentary"
              label="Past Renovations Discussion"
              tooltipText="The following generated text will appear in the Description of Improvement's Condition section of your report."
              getGeneratedText={this.getGeneratedText}
              dataPath="pastRenovationsDiscussion"
              isDynamicContent
            />
          </Grid>
        )}

        {isInRenovationState && (
          <Grid component={Paper} item className={classes.paper} data-qa="recent-renovation-section-tile">
            <Number name="yearOfRenovation" label="Year of Renovation" margin="normal" />
            <div>
              <RadioButtonList
                items={renovationInfoArr}
                name="renovationInfo"
                horizontal={true}
                label="What information do you have about recent renovations?"
              />

              {showItemizedExpenses && (
                <div>
                  <Number
                    name="renovatingUnitsExpense"
                    label="Renovating Units Expense"
                    decimalScale={2}
                    prefix="$"
                    allowNegative={false}
                    thousandSeparator
                    margin="normal"
                  />
                  <Number
                    name="newFacade"
                    label="New Facade"
                    decimalScale={2}
                    prefix="$"
                    allowNegative={false}
                    thousandSeparator
                    margin="normal"
                  />
                  <Number
                    name="newRoof"
                    label="New Roof"
                    decimalScale={2}
                    prefix="$"
                    allowNegative={false}
                    thousandSeparator
                    margin="normal"
                  />
                  <Number
                    name="heating"
                    label="Heating System"
                    decimalScale={2}
                    prefix="$"
                    allowNegative={false}
                    thousandSeparator
                    margin="normal"
                  />
                  <Number
                    name="commonInteriorSpace"
                    label="Common Interior Space"
                    decimalScale={2}
                    prefix="$"
                    allowNegative={false}
                    thousandSeparator
                    margin="normal"
                  />
                </div>
              )}
            </div>
            {isInRenovationState && renovationInfo === TOTAL_CAPITAL_EXPENDITURE && (
              <Number
                name="totalCapitalExpenditure"
                label="Total Capital Expenditure"
                decimalScale={2}
                validate={required}
                prefix="$"
                allowNegative={false}
                thousandSeparator
                required
              />
            )}
            <div>
              <div>
                <CheckboxWithLabel name="hasRenovatingUnits" label="Renovation Units" />
              </div>
              <div>
                <CheckboxWithLabel name="hasHeatingSystem" label="Heating System" />
              </div>
              <div>
                <CheckboxWithLabel name="hasNewFacade" label="New Facade" />
              </div>
              <div>
                <CheckboxWithLabel name="hasNewRoof" label="New Roof" />
              </div>
              <div>
                <CheckboxWithLabel name="hasCommonInteriorSpace" label="Common Interior Space" />
              </div>
            </div>
          </Grid>
        )}
      </React.Fragment>
    )
  }
}

export default withStyles(styles)(Renovations)
