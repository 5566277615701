import React from 'react'
import { get } from 'lodash'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

import { searchNeighborhoodByBorough, clearNeighborhood } from '../../redux/actions'
import { AutosuggestField as Autosuggest } from '../../../../shared/components/Autosuggest'

class NeighborhoodAutosuggest extends React.PureComponent {
  static propTypes = {
    neighborhoodByBorough: PropTypes.array,
    searchNeighborhoodByBorough: PropTypes.func.isRequired,
  }

  static defaultProps = {
    neighborhoodByBorough: [],
  }

  onSuggestionsFetchRequested = ({ value }) => {
    const { searchNeighborhoodByBorough } = this.props

    searchNeighborhoodByBorough({ neighborhood: value })
  }

  onSuggestionsClearRequested = () => {
    const { clearNeighborhood } = this.props

    clearNeighborhood()
  }

  render() {
    const { neighborhoodByBorough } = this.props
    return (
      <Autosuggest
        name="neighborhood"
        label="Neighborhood"
        usePropSuggestionsDirectly
        suggestions={neighborhoodByBorough}
        onSuggestionsFetchRequested={this.onSuggestionsFetchRequested}
        onSuggestionsClearRequested={this.onSuggestionsClearRequested}
      />
    )
  }
}

const mapStateToProps = state => {
  const neighborhoodByBorough = get(state, 'buildingComp.neighborhoodByBorough')
  return {
    neighborhoodByBorough,
  }
}

export default connect(mapStateToProps, { searchNeighborhoodByBorough, clearNeighborhood })(NeighborhoodAutosuggest)
