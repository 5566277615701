import React from 'react'
import * as ReactDOM from 'react-dom'
import PropTypes from 'prop-types'

import { MapControl } from 'react-leaflet'
import L from 'leaflet'

import { overlayColors } from './CartoStyles'

export default class LegendControl extends MapControl {
  static PropTypes = {
    items: PropTypes.arrayOf(
      PropTypes.shape({
        name: PropTypes.string.isRequired,
        value: PropTypes.string.isRequired,
      })
    ).isRequired,
  }

  componentWillMount() {
    const legend = L.control({ position: 'bottomright' })

    const { items } = this.props

    const style = {
      legend: {
        borderRadius: '4px',
        border: '1px solid #999',
        background: '#fff',
        zIndex: '105',
        cursor: 'text',
        boxShadow: 'rgba(0,0,0,.2) 0 0 4px 2px',
        boxSizing: 'border-box',
        listStyle: 'none',
        padding: '13px 15px 14px',
      },

      row: {
        fontSize: '12px',
        color: '#666',
        fontWeight: 200,
        fontFamily: 'Helvetica,Arial',
        textTransform: 'uppercase',
        textAlign: 'left',
        height: '10px',
        lineHeight: '10px',
        marginBottom: '12px',
      },
      bullet: {
        float: 'left',
        margin: '0 7px 0 0',
        width: '3px',
        height: '3px',
        border: '1px solid rgba(0,0,0,.2)',
        zIndex: 1000,
        padding: '2px',
        borderRadius: '50%',
      },
      ...overlayColors,
    }

    const jsx = (
      <div>
        <ul style={style.legend}>
          {items.map((value, index) => {
            const rowStyle = Object.assign({}, style.row, items.length - 1 === index ? { marginBottom: 'none' } : {})
            return (
              <li key={value.name} style={rowStyle}>
                <div style={Object.assign({}, style.bullet, { background: style[value.name] })} />
                {value.name}
              </li>
            )
          })}
        </ul>
      </div>
    )

    legend.onAdd = function (map) {
      const div = L.DomUtil.create('div', '')
      ReactDOM.render(jsx, div)
      return div
    }

    this.leafletElement = legend
  }
}
