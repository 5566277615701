import React from 'react'
import { head } from 'lodash'
import PropTypes from 'prop-types'
import { compose, pure, mapProps } from 'recompose'
import { withStyles, Avatar } from '@material-ui/core'

import Tooltip from '../../../shared/components/Tooltip'
import { getResizedImageUrl } from '../../../shared/utils/cloudinary'

import { AVATAR_IMAGE_WIDTH, AVATAR_ICON_SIZE, DISPLAYED_APPRAISERS_NUMBER } from './constants'

const styles = {
  root: {
    display: 'flex',
  },
  avatar: {
    cursor: 'pointer',
    fontSize: 12,
    lineHeight: 1.33,
    letterSpacing: 0.4,
    margin: '0 2px',
    width: AVATAR_ICON_SIZE,
    height: AVATAR_ICON_SIZE,
    backgroundColor: '#979797',
    boxShadow: '0 0 1px 0 rgba(0, 0, 0, 0.5)',
  },
  tooltip: {
    padding: '0 2px 0 2px',
  },
}

const renderCollapsedAppraisers = collapsedAppraisers => (
  <div>
    {collapsedAppraisers.map(({ fullName }, index) => (
      <div key={index}> {fullName} </div>
    ))}
  </div>
)
const AppraisersList = ({ classes, displayedAppraisers, collapsedAppraisers }) => (
  <div className={classes.root}>
    {displayedAppraisers.map((appraiser, index) => (
      <Tooltip key={index} text={appraiser.fullName} placement="top-end">
        <Avatar src={appraiser.avatar} alt={appraiser.fullName} className={classes.avatar}>
          {appraiser.initials}
        </Avatar>
      </Tooltip>
    ))}
    {collapsedAppraisers.length > 0 && (
      <Tooltip key="collapsed-appraisers" placement="top-end" text={renderCollapsedAppraisers(collapsedAppraisers)}>
        <Avatar className={classes.avatar}>{`+${collapsedAppraisers.length}`}</Avatar>
      </Tooltip>
    )}
  </div>
)

AppraisersList.propTypes = {
  displayedAppraisers: PropTypes.arrayOf(
    PropTypes.shape({
      avatar: PropTypes.string,
      fullName: PropTypes.string.isRequired,
      initials: PropTypes.string.isRequired,
    })
  ),
  collapsedAppraisers: PropTypes.arrayOf(
    PropTypes.shape({
      avatar: PropTypes.string,
      fullName: PropTypes.string.isRequired,
      initials: PropTypes.string.isRequired,
    })
  ),
}

export default compose(
  pure,
  mapProps(({ appraisers = [] }) => {
    const mappedAppraisers = appraisers.map(({ fullName = '', avatar }) => ({
      fullName,
      avatar: getResizedImageUrl({ url: avatar, width: AVATAR_IMAGE_WIDTH }),
      initials: fullName.split(' ').map(head).join(''),
    }))

    const displayedAppraisers =
      mappedAppraisers.length > DISPLAYED_APPRAISERS_NUMBER
        ? mappedAppraisers.slice(0, DISPLAYED_APPRAISERS_NUMBER - 1)
        : mappedAppraisers

    const collapsedAppraisers = mappedAppraisers.slice(displayedAppraisers.length)

    return {
      displayedAppraisers,
      collapsedAppraisers,
    }
  }),
  withStyles(styles)
)(AppraisersList)
