import React from 'react'
import { Field } from 'react-final-form'

import MapMaker from 'client-utils/MapMaker'

import wrapMap from '../../../../shared/components/MapWizard/wrapMap'

const MapMakerWizard = ({ isModalOpen, closeMapWizard, initialProperties, onSave }) => {
  return (
    <MapMaker
      open={isModalOpen}
      onClose={closeMapWizard}
      initialProperties={initialProperties}
      onSave={mapInfo => {
        onSave({ ...mapInfo, closeMapWizard })
      }}
    />
  )
}

const WrappedMap = wrapMap(MapMakerWizard)

const MapWizard = ({ name, ...otherProps }) => {
  return (
    <Field
      name={name}
      render={({ input }) => (
        <WrappedMap
          {...otherProps}
          id={name}
          value={input.value}
          handleChange={input.onChange}
          onFocus={input.onFocus}
          onBlur={input.onBlur}
        />
      )}
    />
  )
}

export default MapWizard
