export const styles = theme => ({
  basisValue: {
    width: 250,
    marginTop: theme.spacing.unit * 3,
  },
  radioButtons: {
    height: 'auto',
  },
  radioButtonsGroup: {
    marginTop: 0,
  },
  checkboxControlRoot: {
    height: 'auto',
    width: 'auto',
  },
  checkboxFormControlLabel: {
    marginTop: 0,
    marginRight: 6,
    width: 'auto',
  },
})
