import numeral from 'numeral'
import { isNumber } from 'lodash'

import { SITE_AREA_ACRE_UNIT_OF_MEASURE } from '../constants/report'

export const SITE_AREA_LABEL_TYPE = {
  SHORT: 0,
  LONG: 1,
  LEGAL: 2,
}

const SITE_AREA_LABELS_SHORT = {
  sf: 'sq. ft.',
  acre: 'acres',
}
const SITE_AREA_LABELS_LONG = {
  sf: 'square feet',
  acre: 'acres',
}
const SITE_AREA_LABELS_LEGAL = {
  sf: 'square foot',
  acre: 'acre',
}

const SITE_AREA_NUMBER_FORMAT = '0,0.[0000]'

const getSiteAreaLabel = (siteAreaMeasure, siteArea, labelType, labelTypeOverride) => {
  if (siteArea <= 1 && siteAreaMeasure === SITE_AREA_ACRE_UNIT_OF_MEASURE) {
    return 'acre'
  }

  if (labelTypeOverride) {
    return labelTypeOverride[siteAreaMeasure]
  }

  switch (labelType) {
    case SITE_AREA_LABEL_TYPE.SHORT: {
      return SITE_AREA_LABELS_SHORT[siteAreaMeasure]
    }
    case SITE_AREA_LABEL_TYPE.LONG: {
      return SITE_AREA_LABELS_LONG[siteAreaMeasure]
    }
    case SITE_AREA_LABEL_TYPE.LEGAL: {
      return SITE_AREA_LABELS_LEGAL[siteAreaMeasure]
    }
    default: {
      return SITE_AREA_LABELS_LONG[siteAreaMeasure]
    }
  }
}

export const formatSiteArea = ({
  siteArea,
  siteAreaMeasure,
  displayInAcresAndSF,
  altSiteArea,
  altSiteAreaMeasure,
  labelType = SITE_AREA_LABEL_TYPE.LONG,
  labelTypeOverride,
}) => {
  return `${numeral(siteArea).format(SITE_AREA_NUMBER_FORMAT)} ${getSiteAreaLabel(
    siteAreaMeasure,
    siteArea,
    labelType,
    labelTypeOverride
  )}${
    displayInAcresAndSF
      ? ` / ${numeral(altSiteArea).format(SITE_AREA_NUMBER_FORMAT)} ${getSiteAreaLabel(
          altSiteAreaMeasure,
          altSiteArea,
          labelType,
          labelTypeOverride
        )}`
      : ''
  }`
}

export const arrayToKeyValuePairs = (values, shouldLowerCastValue = false) => {
  const arr = []
  for (const value of values) {
    let formattedValue = value
    if (shouldLowerCastValue && !isNumber(value)) {
      formattedValue = value.toLocaleLowerCase()
    }
    arr.push({ value: formattedValue, label: value.toString() })
  }
  return arr
}
