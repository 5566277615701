import React from 'react'
import PropTypes from 'prop-types'

import { DragDropContext } from 'react-beautiful-dnd'

import { Button, IconButton, Stack, TableBody, TableCell, TableHead, Tooltip } from '@mui/material'
import Undo from '@mui/icons-material/Undo'

import WarningDialog from 'client-shared/components/WarningDialog'
import { DroppableTable, DroppableTableRow } from 'client-shared/components/DragDropTable'

import TableRow from './TableRow'

class Table extends React.PureComponent {
  state = {
    modalOpen: false,
  }

  onRevert = () => {
    this.props.onRevertToDefault()
    this.closeModal()
  }

  openModal = () => {
    this.setState({ modalOpen: true })
  }

  closeModal = () => {
    this.setState({ modalOpen: false })
  }

  render() {
    const { canDelete, draggingState, fields, onAddItem, onEndMoveItem, onRemoveItem, onStartMoveItem } = this.props
    const { modalOpen } = this.state

    return (
      <Stack spacing={2} sx={{ mt: 2 }}>
        <WarningDialog
          text="Revert Table To Default"
          title="If you revert table to default all data entry will be lost. This action cannot be undone."
          onCancel={this.closeModal}
          onSubmit={this.onRevert}
          open={modalOpen}
        />
        <Stack alignItems="center" direction="row">
          <Button data-qa="add-btn" variant="contained" onClick={onAddItem} sx={{ mr: 'auto' }}>
            Add
          </Button>
          <Tooltip title="Revert to Default" placement="top">
            <IconButton onClick={this.openModal} data-qa="revert-to-default">
              <Undo fontSize="small" />
            </IconButton>
          </Tooltip>
        </Stack>
        <DragDropContext onDragStart={onStartMoveItem} onDragEnd={onEndMoveItem}>
          <DroppableTable id="table">
            <TableHead>
              <DroppableTableRow>
                <TableCell>Item</TableCell>
                <TableCell>Description</TableCell>
              </DroppableTableRow>
            </TableHead>
            <TableBody data-qa={`${fields.name}-table-body`}>
              {fields.map((name, index) => (
                <TableRow
                  key={name}
                  draggingState={draggingState}
                  dragDisabled={fields.value.length === 1}
                  index={index}
                  name={name}
                  onRemoveItem={onRemoveItem}
                  canDelete={canDelete}
                />
              ))}
            </TableBody>
          </DroppableTable>
        </DragDropContext>
      </Stack>
    )
  }
}

Table.propTypes = {
  canDelete: PropTypes.bool.isRequired,
  draggingState: PropTypes.bool.isRequired,
  onAddItem: PropTypes.func.isRequired,
  onEndMoveItem: PropTypes.func.isRequired,
  onRemoveItem: PropTypes.func.isRequired,
  onRevertToDefault: PropTypes.func.isRequired,
  onStartMoveItem: PropTypes.func.isRequired,
}

export default Table
