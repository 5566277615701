import { get, isNumber, isString } from 'lodash'

import { NON_COMPLYING_STATUS, ParagraphSiteAreaMeasureLabels } from 'shared/constants/property/zoning'

import { getLocationIdentifier } from 'shared/utils/report/propertyInformation'
import { TEMPLATE_TYPES } from 'shared/constants/report'

import { GEOGRAPHY_OPTIONS } from 'shared/constants/property/geography'
import { formatFloat, formatInt } from 'shared/utils/formatters/numberFormatters'

type FormValues = {
  isNYCReport: boolean
  isFreddieMacReport: boolean
  isAsComplete: boolean
  regulations: any[]
  isComplyingWithRegardToBulk: boolean
  floorAreaRatio: any
  grossBuildingArea: number
  siteArea: number
  siteAreaMeasure: 'sf' | 'acre'
  mechanicalDeductions: number
  zoningFloorArea: number
  maxBuildableArea: number
}

const mapData = ({
  isFreddieMacReport,
  isAsComplete,
  isNYCReport,
  floorAreaRatio,
  grossBuildingArea,
  siteArea,
  siteAreaMeasure,
  mechanicalDeductions,
  isComplyingWithRegardToBulk,
  zoningFloorArea,
  maxBuildableArea,
  regulations,
}: FormValues) => {
  const siteAreaMeasureLabel = ParagraphSiteAreaMeasureLabels[siteAreaMeasure] || ParagraphSiteAreaMeasureLabels.sf
  const siteAreaText = siteArea ? formatInt(siteArea) : 0
  const zoningFloorAreaText = formatInt(zoningFloorArea || 0)
  const maxBuildableAreaText = `${formatInt(maxBuildableArea)} ${siteAreaMeasureLabel}`

  const hasMechanicalDeductions = mechanicalDeductions > 0

  let floorAreaRatioText
  if (isString(floorAreaRatio)) {
    floorAreaRatioText = floorAreaRatio
  }
  if (isNumber(floorAreaRatio)) {
    floorAreaRatioText = formatFloat(floorAreaRatio)
  }
  let isComplying = isComplyingWithRegardToBulk
  if (!isNYCReport) {
    isComplying = !regulations.find(regulation => regulation.conformingStatus === NON_COMPLYING_STATUS)
  }

  return {
    siteArea: siteAreaText,
    siteAreaMeasureLabel,
    zoningFloorArea: zoningFloorAreaText,
    maxBuildableArea: maxBuildableAreaText,
    floorAreaRatio: floorAreaRatioText || 0,
    isFreddieMacReport,
    isAsComplete,
    grossBuildingArea: formatInt(grossBuildingArea),
    isNYCReport,
    isComplying,
    hasMechanicalDeductions,
    mechanicalDeductions,
  }
}

export const mapDTO = (formValues: FormValues) => {
  return mapData(formValues)
}

export const mapDataModel = (report: any) => {
  const zoning = get(report, 'zoning', {})
  const isAsComplete = get(report, 'valueAsComplete')
  const templateType = report.templateType
  const isFreddieMacReport = templateType === TEMPLATE_TYPES.FREDDIE_MAC

  const state = get(report, 'property_information.state')
  const zip = get(report, 'property_information.zip')
  const locationIdentifier = getLocationIdentifier(state, zip)
  const isNYCReport = locationIdentifier === GEOGRAPHY_OPTIONS.NY
  const mechanicalDeductions = zoning.mechanicalDeductions || 0
  const floorAreaRatio = get(zoning, 'floor_area_ratio', 0)
  const grossBuildingArea = get(report, 'property_information.grossBuildingArea')
  const siteArea = get(report, 'property_information.lotArea')
  const maxBuildableArea = siteArea * floorAreaRatio
  const zoningFloorArea = grossBuildingArea * (1 - mechanicalDeductions / 100)

  return mapData({
    isFreddieMacReport,
    isAsComplete,
    isNYCReport,
    floorAreaRatio: zoning.floor_area_ratio,
    grossBuildingArea,
    siteArea,
    siteAreaMeasure: get(report, 'property_information.siteAreaMeasure'),
    mechanicalDeductions,
    isComplyingWithRegardToBulk: zoning.isComplyingWithRegardToBulk,
    zoningFloorArea,
    maxBuildableArea,
    regulations: zoning.regulations || [],
  })
}
