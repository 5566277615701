import React from 'react'
import MUISwitch, { SwitchProps as MUISwitchProps } from '@mui/material/Switch'
import MUIFormControlLabel, { FormControlLabelProps as MUIFormControlLabelProps } from '@mui/material/FormControlLabel'

export type SwitchProps = MUISwitchProps & {
  labelProps: Omit<MUIFormControlLabelProps, 'control'>
}

const Switch: React.FC<SwitchProps> = ({ labelProps, ...switchProps }) => {
  return <MUIFormControlLabel control={<MUISwitch {...switchProps} />} {...labelProps} />
}

export default React.memo(Switch)
