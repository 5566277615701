import { get, partition, sortBy } from 'lodash'
import React from 'react'
import { connect } from 'react-redux'

import { Box, Button, Stack, Typography } from '@mui/material'
import PersonOutlineIcon from '@mui/icons-material/PersonOutline'
import PeopleOutlineIcon from '@mui/icons-material/PeopleOutline'
import CloseIcon from '@mui/icons-material/Close'

import { SearchBox } from 'client-shared/components/_mui5'
import { contentReuseTemplate, user as userType } from 'client-shared/types'
import { State as contentLibraryData } from 'client-shared/redux/reducers/contentLibraryData'
import { changeDrawerSection } from 'client-shared/redux/actions/drawer'
import { useDispatch } from 'client-shared/hooks/redux'

import usePrevious from 'client-shared/hooks/usePrevious'

import SectionWrapper from '../../SectionWrapper'

import { searchTemplates } from './helpers'
import ContentCard from './ContentCard'
import ContentHeaderSectionComponent from './ContentHeaderComponent'

interface ContentLibrarySectionProps extends ReturnType<typeof mapStateToProps> {}

const ContentLibrarySection: React.FC<ContentLibrarySectionProps> = ({ user, templates, activeTemplateName }) => {
  const [searchQuery, setSearchQuery] = React.useState('')
  const matchingTemplates = searchTemplates(searchQuery, templates)

  const sortedByIsArchived = sortBy(matchingTemplates, ({ metadata: { isArchived } }) => (isArchived ? 1 : 0))

  // When content library is reloaded, reset the search
  const [searchBoxKey, setSearchBoxKey] = React.useState(0)
  const previousTemplates = usePrevious(templates)
  React.useEffect(() => {
    if (
      templates.length > 0 &&
      previousTemplates &&
      previousTemplates.length > 0 &&
      templates[0].metadata.form !== previousTemplates[0].metadata.form
    ) {
      setSearchQuery('')
      setSearchBoxKey(prevKey => prevKey + 1)
    }
  }, [previousTemplates, templates])

  const partitionByAuthor = (template: contentReuseTemplate) => {
    return user.username === template.author.username
  }

  const [loggedInUsersTemplates, otherUsersTemplates] = partition(sortedByIsArchived, partitionByAuthor)

  const dispatch = useDispatch()

  const closeDrawer = () => {
    dispatch(changeDrawerSection(undefined))
  }

  const NoContentMessage = (
    <Typography variant="body1" p={3}>
      Content Blocks are unavailable. <br />
      <br />
      To access, choose a section that includes an "open library" link. If you don't see any, it means none are
      presently available for use.
    </Typography>
  )

  return (
    <SectionWrapper
      title="Content Library"
      rightElement={
        <Stack direction="row" alignItems="center" justifyContent="flex-end" spacing={1}>
          <Button onClick={closeDrawer}>
            <CloseIcon />
          </Button>
        </Stack>
      }
    >
      <Box bgcolor="grey.50" display="flex" flexDirection="column" minHeight="100%">
        {templates.length > 0 ? (
          <SearchBox
            key={searchBoxKey}
            onChange={setSearchQuery}
            placeholder="Search by content, title or person's name"
          />
        ) : (
          NoContentMessage
        )}

        {loggedInUsersTemplates.length > 0 && (
          <>
            <ContentHeaderSectionComponent title="My Content" icon={PersonOutlineIcon} />
            {loggedInUsersTemplates.map(template => (
              <ContentCard key={template.name} template={template} searchQuery={searchQuery} />
            ))}
          </>
        )}

        {otherUsersTemplates.length > 0 && (
          <>
            <ContentHeaderSectionComponent title="Other Appraiser Content" icon={PeopleOutlineIcon} />
            {otherUsersTemplates.map(template => (
              <ContentCard key={template.name} template={template} searchQuery={searchQuery} />
            ))}
          </>
        )}
      </Box>
    </SectionWrapper>
  )
}

const mapStateToProps = (state: any) => {
  const contentLibraryData = get(state, 'shared.contentLibraryData') as contentLibraryData
  return {
    templates: get(contentLibraryData, 'templates'),
    activeTemplateName: get(contentLibraryData, 'activeTemplateName'),
    user: get(state, 'authentication.user', {}) as userType,
  }
}

export default connect(mapStateToProps)(ContentLibrarySection)
