import { GeneratedText, gtLocked } from '../../../narrative/GeneratedText'

import { mapDTO, mapDataModel } from './mappings'

const generate = () => {
  const gt = new GeneratedText()

  const neighborhoodGt = gtLocked('neighborhood')
  const submarketGt = gtLocked('submarket')

  gt.t`The subject property is located in the ${neighborhoodGt} neighborhood of ${submarketGt}.`

  return gt.getParts()
}

export default { generate, mapDTO, mapDataModel }
