import React from 'react'
import PropTypes from 'prop-types'
import { get } from 'lodash'
import createDecorator from 'final-form-calculate'
import indefiniteArticle from 'indefinite-article'

import { Grid, Typography, withStyles } from '@material-ui/core'

import { getProperArticleForNumber, simplePluralize as pluralize } from '../../../../../../shared/utils/textGeneration'
import { getLabelFromValue } from '../../../../../../shared/utils/formatters/textFormatters'

import { formatInt } from '../../../../shared/utils/numberFormatters'
import { formatList } from '../../../../shared/utils/textHelpers'
import { arrayToKeyValuePairs } from '../../../../shared/utils/ui/checkboxHelper'

import { RadioButtonList, GeneratedComment, CheckboxWithLabel } from '../../../../shared/components'
import MultipleDropDown from '../../../../shared/components/DropDown/MultipleDropDownField'

import wrapForm from '../wrapForm'
import { BUILDING_LABEL_VALUE_PAIR } from '../../constants'
import { AMENITY_OPTIONS } from '../../constants/markup'
import { HEATING_SYSTEM } from '../../../../report/constants'

export const DATA_PATH = [BUILDING_LABEL_VALUE_PAIR.PropertyConditions.menuItem]

const PROPERTY_CONDITIONS = ['Poor', 'Satisfactory', 'Average', 'Good', 'Excellent']

const conditionOptions = arrayToKeyValuePairs(PROPERTY_CONDITIONS)
const heatingOptions = arrayToKeyValuePairs(HEATING_SYSTEM)

const decorator = createDecorator({
  field: 'hasAmenities',
  updates: {
    amenities: (hasAmenities, allValues) => {
      if (!hasAmenities) {
        return []
      }
      return allValues.amenities
    },
  },
})

const styles = theme => ({
  radioTitle: {
    fontWeight: 410,
    margin: theme.spacing.unit,
  },
  buildingDescriptionCommentary: {
    marginTop: 20,
  },
})

const RadioButtonTitle = withStyles(styles)(({ classes, title }) => (
  <Typography className={classes.radioTitle} variant="subtitle1">
    {title}
  </Typography>
))

class PropertyConditions extends React.PureComponent {
  static propTypes = {
    type: PropTypes.string.isRequired,
    floors: PropTypes.number.isRequired,
    residentialUnitCount: PropTypes.number.isRequired,
    grossBuildingArea: PropTypes.number.isRequired,
    hasElevator: PropTypes.bool.isRequired,
    yearBuilt: PropTypes.number.isRequired,
  }

  getAmenityLabel = value => getLabelFromValue(AMENITY_OPTIONS, value)

  generateCompDescription = values => {
    const { amenities } = values
    const { type, floors, residentialUnitCount, grossBuildingArea, hasElevator, yearBuilt } = this.props
    const formattedUnitCount = formatInt(residentialUnitCount)
    const formattedGBA = formatInt(grossBuildingArea)
    const floorsArticle = getProperArticleForNumber(floors)
    const verticalTransportationType = hasElevator ? 'elevatored' : 'walk-up'
    const unitsWord = pluralize('unit', residentialUnitCount)

    const amenitiesLabels = amenities.map(value => {
      const amenity = AMENITY_OPTIONS.find(option => option.value === value)
      const amenityLabel = get(amenity, 'label', '')
      return amenity ? `${indefiniteArticle(amenityLabel)} ${amenityLabel.toLowerCase()}` : ''
    })
    const formattedAmenities = amenitiesLabels.length
      ? ` The building includes amenities such as ${formatList(amenitiesLabels)}.`
      : ''

    return (
      `This building rental comparable is ${floorsArticle} ${floors}-story, ${verticalTransportationType},` +
      ` ${type} apartment building. It features ${formattedUnitCount} residential ${unitsWord},` +
      ` and has ${formattedGBA} square feet of gross building area. The property was constructed in ${yearBuilt}.` +
      `${formattedAmenities}`
    )
  }

  render() {
    const { classes, form } = this.props
    const hasAmenities = get(form, 'values.hasAmenities')

    return (
      <Grid container>
        <Grid item xs={9} sm={4}>
          <RadioButtonTitle title="General Property Condition" />
          <RadioButtonList name="conditions.generalPropertyCondition" items={conditionOptions} />
          <RadioButtonTitle title=" General Bathroom Condition" />
          <RadioButtonList name="conditions.generalBathroomCondition" items={conditionOptions} />
          <RadioButtonTitle title="General Kitchen Condition" />
          <RadioButtonList name="conditions.generalKitchenCondition" items={conditionOptions} />
          <RadioButtonTitle title="General Living/Bedroom Description" />
          <RadioButtonList name="conditions.generalLivingAndBedroomCondition" items={conditionOptions} />
          <RadioButtonTitle title="Heating Systems" />
          <RadioButtonList name="conditions.heatingSystems" items={heatingOptions} />

          <CheckboxWithLabel
            name="hasAmenities"
            label={<RadioButtonTitle title="Does this building have amenities?" />}
          />
          {hasAmenities && (
            <MultipleDropDown
              label="Amenities"
              name="amenities"
              items={AMENITY_OPTIONS}
              itemLabelSelector={this.getAmenityLabel}
              withIcons
            />
          )}

          <GeneratedComment
            isDynamicContent
            title="Generated Commentary"
            label="Building Description"
            tooltipText="The following generated text will appear in the Addenda of the report."
            dataPath="compDiscussion"
            className={classes.buildingDescriptionCommentary}
            getGeneratedText={this.generateCompDescription}
          />
        </Grid>
      </Grid>
    )
  }
}

export default wrapForm(
  DATA_PATH,
  { heading: BUILDING_LABEL_VALUE_PAIR.PropertyConditions.heading, styles, decorators: [decorator] },
  state => {
    const { type, floors, residentialUnitCount, grossBuildingArea, hasElevator, yearBuilt } =
      get(state, 'buildingComp.propertySummary') || {}

    return { type, floors, residentialUnitCount, grossBuildingArea, hasElevator, yearBuilt }
  }
)(PropertyConditions)
