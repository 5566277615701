import React from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'

const styles = theme => ({
  cell: {
    boxSizing: 'border-box',
    fontSize: 14,
    padding: 0,
    color: theme.palette.text.primary,
    margin: 0,
    border: 0,
    textTransform: 'none',
  },
  cellWrapper: {
    width: '100%',
    height: '100%',
    padding: '4px 8px 4px 16px',
    display: 'flex',
    flexDirection: 'column',
    boxSizing: 'border-box',
    justifyContent: 'center',
  },
})

const SearchResultsCell = ({ children, width, classes, ...props }) => {
  return (
    <div className={classes.cellWrapper} style={{ width }}>
      <div className={classes.cell}>{children}</div>
    </div>
  )
}

SearchResultsCell.propTypes = {
  width: PropTypes.number,
}

SearchResultsCell.defaultProps = {
  width: 100,
}

export default withStyles(styles)(SearchResultsCell)
