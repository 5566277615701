import React from 'react'
import PropTypes from 'prop-types'

import { Box, Tooltip, Typography } from '@mui/material'
import { makeStyles } from '@mui/styles'

import createIcon from '../components/_mui5/Icons/createIcon'

const useStyles = makeStyles(({ palette }) => ({
  active: {
    color: palette.primary.main,
  },
  default: {
    color: palette.text.primary,
    '&:hover': {
      color: palette.primary.main,
    },
  },
}))

const MenuIcon = ({ onClick, icon, tooltipTitle, tooltipPosition, isActive, label }) => {
  const classes = useStyles()

  const IconElement = React.useMemo(() => createIcon(icon), [icon])
  const iconElement = React.useMemo(
    () => <IconElement onClick={onClick} sx={{ paddingBottom: '0px', fontSize: '18px' }} />,
    [onClick]
  )

  return tooltipTitle ? (
    <Tooltip title={tooltipTitle} placement={tooltipPosition}>
      <Box
        sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', cursor: 'pointer' }}
        className={isActive ? classes.active : classes.default}
      >
        {iconElement}
        {label && (
          <Typography sx={{ fontSize: 8, textAlign: 'center' }} variant="caption" onClick={onClick}>
            {label}
          </Typography>
        )}
      </Box>
    </Tooltip>
  ) : (
    iconElement
  )
}

MenuIcon.propTypes = {
  onClick: PropTypes.func,
  icon: PropTypes.elementType.isRequired,
  tooltipTitle: PropTypes.string,
  isActive: PropTypes.bool,
  tooltipPosition: PropTypes.string,
  label: PropTypes.string,
}

MenuIcon.defaultProps = {
  onClick: () => {},
  isActive: false,
  tooltipPosition: 'right',
}

export default MenuIcon
