import React from 'react'
import PropTypes from 'prop-types'

import { Button } from '@mui/material'

import { CompPlexEvents } from '../../../../../shared/constants/compPlex'

import CreateSalesCompWizard from './drm/CreateSalesCompWizard'

class CreateSalesComp extends React.PureComponent {
  state = {
    open: false,
    googleAddressSearched: null,
  }

  componentDidMount() {
    const { compPlexRef } = this.props
    if (compPlexRef && compPlexRef.current) {
      compPlexRef.current.addEventListener(CompPlexEvents.GOOGLE_ADDRESS_SEARCHED, this.handleAddressSearch)
    }
  }

  componentWillUnmount() {
    const { compPlexRef } = this.props
    if (compPlexRef && compPlexRef.current) {
      compPlexRef.current.removeEventListener(CompPlexEvents.GOOGLE_ADDRESS_SEARCHED, this.handleAddressSearch)
    }
  }

  openModal = evt => {
    evt.stopPropagation()
    this.setState({ open: true, googleAddressSearched: null })
  }

  closeModal = () => {
    this.setState({ open: false, googleAddressSearched: null })
  }

  addComp = newComp => {
    this.props.addComp(newComp)
    this.closeModal()
  }

  handleAddressSearch = event => {
    const { addressInfo } = event.detail
    this.setState({ open: true, googleAddressSearched: addressInfo })
  }

  render() {
    const { open, googleAddressSearched } = this.state
    const { compPlexRef, editCompProps, showEditModal, hideEditModal } = this.props

    return (
      <React.Fragment>
        <Button
          color="primary"
          data-qa="create-sales-comps-btn"
          onClick={this.openModal}
          sx={{ height: 30 }}
          variant="contained"
        >
          ADD NEW
        </Button>
        {open && (
          <CreateSalesCompWizard
            addComp={this.addComp}
            compPlexRef={compPlexRef}
            editCompProps={editCompProps}
            showEditModal={showEditModal}
            hideEditModal={hideEditModal}
            onClose={this.closeModal}
            googleAddress={googleAddressSearched}
          />
        )}
      </React.Fragment>
    )
  }
}

CreateSalesComp.propTypes = {
  addComp: PropTypes.func.isRequired,
  compPlexRef: PropTypes.any.isRequired,
  editCompProps: PropTypes.object,
  hideEditModal: PropTypes.func.isRequired,
  showEditModal: PropTypes.func.isRequired,
}

export default CreateSalesComp
