import { get, sumBy, uniq } from 'lodash'

import { toWords } from 'number-to-words'

import { LeaseStatusTypes, OCCUPIED_UNIT_TYPES } from 'shared/constants/report/incomeApproach/residential'
import { formatList } from 'shared/utils/textHelpers'

import { formatCurrencyInt } from 'shared/utils/formatters/numberFormatters'

type FormValues = {
  showDevelopersForecast: boolean
  address: string
  residentialUnits: any[]
  valueAsStabilized: boolean
}

type Report = {
  // eslint-disable-next-line camelcase
  property_information: {
    address: string
  }
  residential: {
    units: any[]
    showDevelopersForecast: boolean
  }
  valueAsStabilized: boolean
}

type NarrativeValues = {
  showDevelopersForecast: boolean
  address: string
  unitsCount?: number
  hasMultipleUnits?: boolean
  vacantUnitsCount?: number
  hasVacantUnits?: boolean
  hasMultipleVacantUnits?: boolean
  occupiedUnitsCount?: number
  hasMultipleOccupiedUnits?: boolean
  valueAsStabilized?: boolean
  occupiedUnitsTotalAnnualIncome: number
  unitBedrooms?: string
  areAllUnitsVacant?: boolean
}

const MAX_BEDROOMS_VALUE = 9

const getUnitBedroomList = (residentialUnits: any[]) => {
  const bedrooms: number[] = residentialUnits.map(unit =>
    unit.bedrooms > MAX_BEDROOMS_VALUE ? MAX_BEDROOMS_VALUE : unit.bedrooms
  )
  const uniqBedrooms = uniq(bedrooms)

  return uniqBedrooms.map(bedroomsCount => {
    const count =
      bedroomsCount === MAX_BEDROOMS_VALUE
        ? bedrooms.filter(value => value >= bedroomsCount).length
        : bedrooms.filter(value => value === bedroomsCount).length

    if (bedroomsCount === 0) {
      return `${count} studio${count > 1 ? 's' : ''}`
    }

    return `${count} ${toWords(bedroomsCount)}-bedroom unit${count > 1 ? 's' : ''}`
  })
}

export const mapDTO = (formValues: FormValues): NarrativeValues => {
  const { showDevelopersForecast, address, residentialUnits, valueAsStabilized } = formValues

  const unitsCount = residentialUnits.length
  const vacantUnitsCount = residentialUnits.filter(unit => unit.leaseStatus === LeaseStatusTypes.VACANT).length
  const occupiedUnits = residentialUnits.filter(unit => OCCUPIED_UNIT_TYPES.includes(unit.leaseStatus))
  const occupiedUnitsTotalMonthlyIncome = sumBy(occupiedUnits, unit => unit.rent || 0) || 0
  const occupiedUnitsTotalAnnualIncome = occupiedUnitsTotalMonthlyIncome * 12
  const occupiedUnitsCount = occupiedUnits.length
  const unitBedroomArray = getUnitBedroomList(residentialUnits)
  const unitBedrooms = formatList(unitBedroomArray)
  const areAllUnitsVacant = vacantUnitsCount === residentialUnits.length

  if (showDevelopersForecast) {
    return {
      showDevelopersForecast,
      address,
      unitsCount,
      hasMultipleUnits: unitsCount > 1,
      occupiedUnitsCount,
      hasMultipleOccupiedUnits: occupiedUnitsCount > 1,
      valueAsStabilized,
      occupiedUnitsTotalAnnualIncome: formatCurrencyInt(occupiedUnitsTotalAnnualIncome),
    }
  }
  return {
    showDevelopersForecast,
    address,
    vacantUnitsCount,
    hasVacantUnits: vacantUnitsCount > 0,
    hasMultipleVacantUnits: vacantUnitsCount > 1,
    occupiedUnitsCount,
    occupiedUnitsTotalAnnualIncome: formatCurrencyInt(occupiedUnitsTotalAnnualIncome),
    unitBedrooms,
    areAllUnitsVacant,
  }
}

export const mapDataModel = (report: Report): NarrativeValues => {
  const showDevelopersForecast = get(report, 'residential.showDevelopersForecast', false)
  const residentialUnits = get(report, 'residential.units', [])
  const address = get(report, 'property_information.address')
  const valueAsStabilized = get(report, 'valueAsStabilized', false)

  return mapDTO({ showDevelopersForecast, residentialUnits, address, valueAsStabilized })
}
