import { handleActions } from 'redux-actions'

import { searchTaxCompsFail, searchTaxCompsReceive, searchTaxCompsRequest } from './actions'

const initialState = {
  comps: [],
  isLoading: false,
}

export const reducer = handleActions(
  {
    [searchTaxCompsReceive](state, { payload }) {
      return {
        ...state,
        comps: payload || [],
        isLoading: false,
      }
    },
    [searchTaxCompsRequest](state) {
      return {
        ...state,
        isLoading: true,
      }
    },
    [searchTaxCompsFail](state) {
      return {
        ...state,
        isLoading: false,
      }
    },
  },
  initialState
)
