import { PropertyTypes as PT } from '../constants'
import { CompPropertyTypes } from '../constants/property/propertyTypes'

const hasCommercialTypes = [PT.MIXED_USE, PT.COMMERCIAL]
const hasResidentialTypes = [PT.MIXED_USE, PT.MULTI_FAMILY]

export const SECTIONS = {
  HAS_COMMERCIAL: 'hasCommercial',
  HAS_RESIDENTIAL: 'hasResidential',
  COMMERCIAL_UNIT_COUNT: 'commercialUnitCount',
  RESIDENTIAL_UNIT_COUNT: 'residentialUnitCount',
  COMMERCIAL_VACANCY_LOSS: 'commercialVacancyLoss',
  RESIDENTIAL_VACANCY_LOSS: 'residentialVacancyLoss',
}

const sections = {
  [SECTIONS.HAS_COMMERCIAL]: hasCommercialTypes,
  [SECTIONS.HAS_RESIDENTIAL]: hasResidentialTypes,
  [SECTIONS.COMMERCIAL_UNIT_COUNT]: hasCommercialTypes,
  [SECTIONS.RESIDENTIAL_UNIT_COUNT]: hasResidentialTypes,
  [SECTIONS.COMMERCIAL_VACANCY_LOSS]: hasCommercialTypes,
  [SECTIONS.RESIDENTIAL_VACANCY_LOSS]: hasResidentialTypes,
}

export const showSection = (section, type) => {
  if (!sections.hasOwnProperty(section)) {
    return false
  }
  return sections[section].includes(type)
}

export const typeHasResidential = type => {
  return showSection(SECTIONS.HAS_RESIDENTIAL, type)
}

export const typeHasCommercial = type => {
  return showSection(SECTIONS.HAS_COMMERCIAL, type)
}

export const formatPropertySubtype = propertySubtype => {
  return CompPropertyTypes[propertySubtype]
}
