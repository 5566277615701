import React from 'react'
import PropTypes from 'prop-types'

import { Button, TextField, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material'

import { Labels } from '../constants'

export default class GroupNameDialog extends React.PureComponent {
  static propTypes = {
    title: PropTypes.string.isRequired,
    submitButtonText: PropTypes.string.isRequired,
    value: PropTypes.string.isRequired,
    onCancel: PropTypes.func.isRequired,
    onSubmit: PropTypes.func.isRequired,
  }

  state = {
    currentValue: this.props.value,
    showError: false,
  }

  onCurrentValueChange = event => {
    this.setState({ currentValue: event.target.value })
  }

  onKeyPress = event => {
    if (event.key === 'Enter') {
      this.onSubmitClick()
    }
  }

  onSubmitClick = () => {
    const { onSubmit } = this.props
    const { currentValue } = this.state

    if (!currentValue) {
      this.setState({ showError: true })
      return
    }

    onSubmit(currentValue)
  }

  render() {
    const { title, submitButtonText, onCancel } = this.props
    const { currentValue, showError } = this.state

    return (
      <Dialog open onKeyPress={this.onKeyPress} aria-labelledby="rename-group-dialog" data-qa="add-comp-group-dialog">
        <DialogTitle id="rename-group-dialog">{title}</DialogTitle>
        <DialogContent>
          <TextField
            variant="standard"
            value={currentValue}
            label={Labels.GROUP_NAME}
            onChange={this.onCurrentValueChange}
            error={showError}
            helperText={showError && Labels.GROUP_NAME_IS_REQUIRED}
            InputLabelProps={{
              shrink: true,
            }}
            data-qa="group-name-text-field"
          />
        </DialogContent>
        <DialogActions>
          <Button data-qa="cancel-comp-group-btn" onClick={onCancel}>
            {Labels.CANCEL}
          </Button>
          <Button data-qa="add-comp-group-btn" onClick={this.onSubmitClick}>
            {submitButtonText}
          </Button>
        </DialogActions>
      </Dialog>
    )
  }
}
