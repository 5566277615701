import React, { useCallback } from 'react'
import PropTypes from 'prop-types'
import useMousetrap from 'react-hook-mousetrap'
import { connect } from 'react-redux'

import { Box, Slide, Stack } from '@mui/material'
import { TransitionGroup } from 'react-transition-group'

import { CLEAR_SHORTCUTS } from 'client-shared/components/Notifications/constants'
import { removeNotification as removeNotificationAction } from 'client-shared/redux/actions/notifications'

import { NotificationTypes } from '../../constants/notificationsTypes'

import Notification from './Notification'

const Notifications = ({ notifications, removeNotification, Component = Notification }) => {
  useMousetrap(
    CLEAR_SHORTCUTS,
    useCallback(() => {
      if (notifications.length > 0) {
        removeNotification(notifications[0].id)
      }
    }, [notifications, removeNotification])
  )

  return (
    <Stack spacing={1} sx={{ padding: notifications.length ? 1 : 0, overflow: 'hidden' }} width={350}>
      <TransitionGroup component={null}>
        {notifications.map(notification => (
          <Slide key={notification.id} direction="left" timeout={500}>
            <Box>
              <Component notification={notification} />
            </Box>
          </Slide>
        ))}
      </TransitionGroup>
    </Stack>
  )
}

Notifications.propTypes = {
  notifications: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      message: PropTypes.string.isRequired,
      type: PropTypes.oneOf([NotificationTypes.SUCCESS, NotificationTypes.WARNING, NotificationTypes.ERROR]).isRequired,
    })
  ).isRequired,
  removeNotification: PropTypes.func.isRequired,
}

export default connect(() => ({}), {
  removeNotification: removeNotificationAction,
})(Notifications)
