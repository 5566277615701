import React from 'react'
import PropTypes from 'prop-types'

import CreateSalesComp from 'report/forms/sales/SalesCompsSearch/CreateSalesComp'

const CreateComp = ({ addComp, compPlexRef, editCompProps, setEditCompProps }) => {
  const showEditModal = editCompProps => {
    setEditCompProps(editCompProps)
  }

  const hideEditModal = () => {
    setEditCompProps(null)
  }

  return (
    <>
      {compPlexRef.current && (
        <CreateSalesComp
          addComp={addComp}
          compPlexRef={compPlexRef}
          editCompProps={editCompProps}
          showEditModal={showEditModal}
          hideEditModal={hideEditModal}
        />
      )}
    </>
  )
}

CreateComp.propTypes = {
  addComp: PropTypes.func.isRequired,
  compPlexRef: PropTypes.any.isRequired,
  editCompProps: PropTypes.object,
  setEditCompProps: PropTypes.func.isRequired,
}

export default CreateComp
