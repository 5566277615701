import { Theme } from 'client-shared/components/_mui5/_theme'

const HIGHLIGHT_CHIP = {
  background: 'rgba(240, 242, 252)',
  borderRadius: 4,
  fontFamily: 'Nunito Sans',
}

export const hoverSuggestStyles = ({ palette }: Theme) => ({
  suggest: {
    background: palette.colors.common.white,
    boxShadow: '0px 2px 8px rgba(0, 15, 68, 0.12)',
    borderRadius: 4,
    width: 304,
    position: 'absolute' as const,
    zIndex: -1000,
    top: -10000,
    left: -10000,
    marginTop: -6,
    fontFamily: 'Nunito Sans',
    fontSize: 14,
  },
  valuesList: {
    listStyleType: 'none',
    padding: 0,
    margin: 0,
    width: '100%',
    '&:hover': { cursor: 'pointer' },
    maxHeight: 400,
    overflowY: 'scroll' as const,
    '&::-webkit-scrollbar': {
      '-webkit-appearance': 'none',
      width: 7,
    },
    '&::-webkit-scrollbar-track': {
      position: 'absolute',
    },
    '&::-webkit-scrollbar-thumb': {
      borderRadius: 4,
      backgroundColor: 'rgba(0,0,0,.5)',
      boxShadow: '0 0 1px rgba(255,255,255,.5)',
    },
  },
  listItems: {
    padding: '8px 16px',
    lineHeight: '20px',
    background: '#FFFFFF',
    '&:hover': { ...HIGHLIGHT_CHIP, background: palette.action.hover },
  },
  highlightSuggestion: HIGHLIGHT_CHIP,
  primaryText: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap' as const,
    maxWidth: '100%',
  },
  secondaryText: {
    fontWeight: 'bold',
  },
  notFoundText: {
    color: palette.error.main,
  },
  groupName: {
    textAlign: 'right' as const,
  },
})

export const boldStyles = () => ({
  bold: {
    fontWeight: '700',
  },
})

export const paragraphStyles = () => ({
  paragraph: {
    lineHeight: '26px',
    marginTop: 0,
  },
})

export const toolbarStyles = () => ({
  toolbarButton: {
    padding: '5px',
    cursor: 'pointer',
    '&:hover': {
      color: '#3F51B5',
      backgroundColor: '#3F51B514',
    },
  },
  toolbarToggle: {
    color: '#3F51B5',
  },
})

const styles = (theme: Theme) => {
  const { palette } = theme
  return {
    textEditor: {
      width: '100%',
      minHeight: 136,
      boxSizing: 'border-box' as const,
      padding: 8,
      borderRadius: 4,
      color: palette.colors.grey[900],
      transitionDuration: '0.3s',
      transitionTimingFunction: 'ease',
      fontFamily: 'Nunito Sans',
      //WEB-6859: Cursor jumps around with read-write-plaintext-only style so overrided it using !important
      '-webkit-user-modify': 'read-write !important',
    },
    textEditorWrapper: {
      border: '1px solid lightgray',
      '&:focus-within': {
        borderColor: '#4260D3',
        borderWidth: 2,
        borderStyle: 'solid',
      },
    },
    equalsChip: {
      backgroundColor: palette.colors.grey[200],
      borderRadius: 4,
      paddingLeft: 4,
      paddingRight: 4,
    },
    toolTipInformation: {
      color: palette.text.secondary,
      marginTop: '1px',
      marginBottom: '8px',
    },
  }
}

export default styles
