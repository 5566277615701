export const PERIOD_TYPES = {
  year: 'Annual',
  month_unit: 'Monthly Per Unit',
}
export const PERIOD_BEDROOM_TYPES = {
  year: 'Annual',
  month_unit: 'Monthly Per Unit',
  month_total: 'Total Monthly Income',
}

export const STORAGE_LOSS_TYPES = {
  residential: 'Residential',
  storage: 'Storage V/C',
}

export const LAUNDRY_LOSS_TYPES = {
  residential: 'Residential',
  laundry: 'Laundry V/C',
}

export const PARKING_LOSS_TYPES = {
  residential: 'Residential',
  parking: 'Parking',
}
