import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import { compose, pure } from 'recompose'

import { Typography } from '@mui/material'
import { withStyles } from '@mui/styles'

import { PreviewSection } from '../components'

const styles = theme => ({
  root: {},
  title: {
    marginBottom: 24,
  },
  list: {
    fontSize: 12,
    paddingLeft: 16,
    '& li': {
      color: 'rgba(0, 0, 0, 0.6)',
      marginBottom: 32,
    },
  },
  text: {
    color: 'rgba(0, 0, 0, 0.6)',
  },
})

const CertificationRequirements = ({ classes, title, requirements, hasOnlyOneAppraiser }) => (
  <div className={classes.root}>
    <Typography className={classNames(classes.text, classes.title)} gutterBottom variant="body1">
      {`${hasOnlyOneAppraiser ? 'I' : 'We'} certify to the best of ${hasOnlyOneAppraiser ? 'my' : 'our'} knowledge:`}
    </Typography>
    {title && (
      <Typography variant="body1" className={classes.title} gutterBottom>
        {title}
      </Typography>
    )}
    <PreviewSection>
      <ul className={classes.list}>
        {requirements.map((requirement, index) => (
          <li key={index}>
            <Typography className={classes.text} gutterBottom variant="body1" key={index}>
              {requirement}
            </Typography>
          </li>
        ))}
      </ul>
    </PreviewSection>
  </div>
)

CertificationRequirements.propTypes = {
  title: PropTypes.string,
  requirements: PropTypes.arrayOf(PropTypes.string.isRequired).isRequired,
  hasOnlyOneAppraiser: PropTypes.bool.isRequired,
}

CertificationRequirements.defaultProps = {
  title: null,
}

export default compose(withStyles(styles), pure)(CertificationRequirements)
