import { min, max, mean, get, find, sumBy } from 'lodash'

import { ADJUSTMENT_GROUPS } from 'shared/helpers/salesApproach/salesCompAdjustmentConstants'
import { getSalePriceToUse } from 'shared/report-calculations/sales-approach/helpers'
import { roundToNearest } from 'shared/utils/numberOperations'

import { IncomeAdjustmentKeys } from '../../../../../shared/constants/report/sales/salesAdjustment'
import SalesApproachCalculations from '../../../../../shared/report-calculations/sales-approach/sales-approach-calculations'
import {
  getAdjustmentGroup,
  getAdjustmentValue,
} from '../../../../../shared/helpers/salesApproach/salesCompAdjustments'

import { RentTypes } from '../../constants'

export function getTotalLocationAdjustment(adjustments) {
  if (adjustments) {
    return 0
  }

  return calculateTotalGroupAdjustments(adjustments, ADJUSTMENT_GROUPS.LOCATION)
}

export function getTrendedPriceAdjustment(comp, adjustments) {
  // price adjustment order matters:
  // propertyRights -> financingTerms -> conditionsOfSale -> marketConditions

  const propertyRights = getAdjustmentValue(adjustments, 'propertyRights')
  const financingTerms = getAdjustmentValue(adjustments, 'financingTerms')
  const conditionsOfSale = getAdjustmentValue(adjustments, 'conditionsOfSale')
  const marketConditions = getAdjustmentValue(adjustments, 'marketConditions')

  const orderedAdjustments = [
    parseFloat(propertyRights) || 0,
    parseFloat(financingTerms) || 0,
    parseFloat(conditionsOfSale) || 0,
    parseFloat(marketConditions) || 0,
  ]
  const salePrice = getSalePriceToUse(comp.saleInformation)
  let trendedPrice = parseFloat(salePrice || 0)

  orderedAdjustments.forEach(adjustment => (trendedPrice = trendedPrice * (1 + adjustment)))

  return trendedPrice
}

export function getTrendAdjustedPricePerBasis(comp, adjustments, unitOfComparison) {
  if (!adjustments) {
    return 0
  }
  const { propertyInformation } = comp
  const sumProperties = getTrendedPriceAdjustment(comp, adjustments)
  return SalesApproachCalculations.calculateSalePricePerBasis(sumProperties, unitOfComparison, propertyInformation)
}

export function getTotalUtilityAdjustment(adjustments) {
  return calculateTotalGroupAdjustments(adjustments, ADJUSTMENT_GROUPS.UTILITY)
}

export function getSubjectUnusedAirRights(zoningAirRights) {
  if (zoningAirRights && zoningAirRights > 0) {
    return zoningAirRights
  }

  return 0
}

export function getStabilizationLevel(rentRollUnits) {
  const unitsCount = rentRollUnits.length

  return rentRollUnits.filter(unit => unit.rentType === RentTypes.RENT_STABILIZED).length / unitsCount
}

export function getNoiAdjustment(
  capRate,
  salesPrice,
  propertyInformation,
  unitOfComparison,
  temper,
  subjectNetOperatingIncome,
  subjectPropertyInformation
) {
  if (!capRate || !subjectNetOperatingIncome) {
    return 0
  }
  const netOperatingIncome = capRate * salesPrice
  const netOperatingIncomePerBasis = SalesApproachCalculations.calculateNetOperatingIncomePerBasis(
    netOperatingIncome,
    unitOfComparison,
    propertyInformation
  )
  const subjectNetOperatingIncomePerBasis = SalesApproachCalculations.calculateNetOperatingIncomePerBasis(
    subjectNetOperatingIncome,
    unitOfComparison,
    subjectPropertyInformation
  )

  const percentAdjustment =
    -1 * ((netOperatingIncomePerBasis - subjectNetOperatingIncomePerBasis) / netOperatingIncomePerBasis) * temper

  // need to round netOperatingIncome adjustments to nearest 5%
  const roundedPercentage = 0.05 * Math.round(percentAdjustment / 0.05)
  return roundedPercentage
}

export function getTotalAdjustedPercentages(
  comp,
  unitOfComparison,
  temper,
  subjectNetOperatingIncome,
  subjectPropertyInformation,
  incomeAdjustmentLevel,
  adjustments
) {
  const { propertyInformation, saleInformation } = comp
  const { capRate } = saleInformation
  const salePrice = getSalePriceToUse(saleInformation)
  const noiAdjustment = getNoiAdjustment(
    capRate,
    salePrice,
    propertyInformation,
    unitOfComparison,
    temper,
    subjectNetOperatingIncome,
    subjectPropertyInformation
  )

  const location = calculateTotalGroupAdjustments(adjustments, ADJUSTMENT_GROUPS.LOCATION)

  const utility = calculateTotalGroupAdjustments(adjustments, ADJUSTMENT_GROUPS.UTILITY)

  const stabilizationLevel =
    get(
      find(adjustments, adjustment => adjustment?.name === 'stabilizationLevel'),
      'value'
    ) || 0

  const otherAdjustmentsWithoutStabilizationLevel =
    calculateTotalGroupAdjustments(adjustments, ADJUSTMENT_GROUPS.OTHER) - stabilizationLevel

  let incomeAdjustment = 0
  if (incomeAdjustmentLevel === IncomeAdjustmentKeys.income) {
    incomeAdjustment = noiAdjustment
  } else if (incomeAdjustmentLevel === IncomeAdjustmentKeys.rentStabilization) {
    incomeAdjustment = stabilizationLevel
  }
  return location + utility + incomeAdjustment + otherAdjustmentsWithoutStabilizationLevel
}

export function getAdjustedSalesPricePerBasis(
  comp,
  unitOfComparison,
  temper,
  subjectNetOperatingIncome,
  subjectPropertyInformation,
  incomeAdjustmentLevel,
  adjustments
) {
  const percentage = getTotalAdjustedPercentages(
    comp,
    unitOfComparison,
    temper,
    subjectNetOperatingIncome,
    subjectPropertyInformation,
    incomeAdjustmentLevel,
    adjustments
  )

  if (!adjustments) {
    return 0
  }
  const adjustedPrice = getTrendAdjustedPricePerBasis(comp, adjustments, unitOfComparison)

  return (1 + percentage) * adjustedPrice
}

export const getPriceDataForComps = (
  comps,
  shouldReturnAdjustedValues,
  unitOfComparison,
  temper,
  subjectNetOperatingIncome,
  subjectPropertyInformation,
  incomeAdjustmentLevel,
  adjustments
) => {
  const adjustedComps = comps.map(comp => {
    const compAdjustments =
      find(adjustments, adjustment => adjustment?.compId.toString() === comp.id)?.adjustments || []
    if (shouldReturnAdjustedValues) {
      return getAdjustedSalesPricePerBasis(
        comp,
        unitOfComparison,
        temper,
        subjectNetOperatingIncome,
        subjectPropertyInformation,
        incomeAdjustmentLevel,
        compAdjustments
      )
    }
    const { propertyInformation, saleInformation } = comp
    const salePrice = parseFloat(getSalePriceToUse(saleInformation))

    return SalesApproachCalculations.calculateSalePricePerBasis(salePrice, unitOfComparison, propertyInformation)
  })

  const getMedianCompRent = compRents => {
    compRents.sort((compA, compB) => compA - compB)
    const middleIndex = Math.floor((compRents.length - 1) / 2)

    if (compRents.length % 2) {
      return compRents[middleIndex]
    }
    return mean([compRents[middleIndex], compRents[middleIndex + 1]])
  }

  return {
    min: min(adjustedComps),
    avg: mean(adjustedComps),
    median: getMedianCompRent(adjustedComps),
    max: max(adjustedComps),
  }
}

export const calculateTotalGroupAdjustments = (adjustments, group) => {
  const allGroupAdjustments = getAdjustmentGroup(adjustments, group)
  return sumBy(allGroupAdjustments, 'value')
}

export const autoCalculateSaleValueConclusion = (compMedian, incomeApproachValue) => {
  const upperRange = incomeApproachValue * 1.1
  let saleValueConclusion = 0
  if (compMedian <= upperRange && compMedian >= incomeApproachValue) {
    saleValueConclusion = compMedian
  } else if (compMedian > upperRange) {
    saleValueConclusion = upperRange
  } else {
    saleValueConclusion = incomeApproachValue
  }

  return roundToNearest(saleValueConclusion, 5000)
}

export const caluclateAdjustedCompMedian = (
  salesComps,
  unitOfComparison,
  temper,
  netOperatingIncome,
  incomeAdjustmentLevel,
  compAdjustments
) => {
  const adjustedCompSuperlatives = getPriceDataForComps(
    salesComps,
    true,
    unitOfComparison,
    temper,
    get(netOperatingIncome, 'perUnit', 0),
    get(netOperatingIncome, 'psf', 0),
    incomeAdjustmentLevel,
    compAdjustments
  )
  return adjustedCompSuperlatives.median
}
