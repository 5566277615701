export const LOSS_PERIOD = {
  AS_COMPLETE: 0,
  AS_STABILIZED: 1,
  ALL: 2,
}

export const LOSS_ITEM_VALUE_TYPE = {
  COMPUTED_VALUE: 0,
  USER_ENTERED: 1,
  STATIC_VALUE: 2,
  UNKNOWN: 3,
}

export const RENOVATIONS_COST_TYPE = {
  ITEMIZED: 'Itemized',
  TOTAL: 'Total',
}

export const RENOVATIONS_TYPE = {
  NO_RENOVATIONS: 'No Renovations',
  GUT_RENOVATION: 'Gut Renovation',
}

export const DATE_FORMAT = 'MM/DD/YYYY'

export const VALUE_NOT_CALCULABLE = 'N/A'
export const LOSS_ITEM_KEYS = {
  COMMERCIAL_RETAIL_RENT_LOSS: 'Retail Rent Loss',
  COMMERCIAL_OFFICE_RENT_LOSS: 'Office Rent Loss',
  COMMERCIAL_MEDICAL_RENT_LOSS: 'Medical Office Rent Loss',
  COMMERCIAL_COMMUNITY_RENT_LOSS: 'Community Facility Rent Loss',
  COMMERCIAL_UNDETERMINED_RENT_LOSS: 'Undetermined Commercial Rent Loss',
  LAUNDRY_RENT_LOSS: 'Laundry Rent Loss',
  STORAGE_RENT_LOSS: 'Storage Rent Loss',
  PARKING_RENT_LOSS: 'Parking Rent Loss',
  OTHER_RENT_LOSS: 'Other Rent Loss',
  COMMISSION_FEE: 'Commission Fee',
  RENOVATION_BUDGET: 'Renovation Budget',
  CONSTRUCTION_BUDGET: 'Construction Budget',
  BUYOUT_COST: 'Buyout Cost',
  ENTREPRENEURIAL_PROFIT: 'Entrepreneurial Profit*',
}

export const VALUE_CONCLUSION_TYPES = {
  AS_IS: 'AS_IS',
  AS_STABILIZED: 'AS_STABILIZED',
  AS_COMPLETE: 'AS_COMPLETE',
}

export const RENT_LOSS_AMOUNT_FIELD_NAMES = {
  RESIDENTIAL: 'rent',
  COMMERCIAL: 'monthlyRent',
}

export const RENT_LOSS_TYPE = {
  COMMERCIAL: 2,
  RESIDENTIAL: 1,
}
