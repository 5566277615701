import React from 'react'
import PropTypes from 'prop-types'

import { Form } from 'react-final-form'

import { approachesDecorators } from 'report/components/ReportSettings/decorators'

import ReportSettingsModal from './ReportSettingsModal'

class ReportSettingsModalContainer extends React.PureComponent {
  onSubmit = ({ settings }) => {
    const { onClose, updateSettings } = this.props
    updateSettings(settings)
    onClose()
  }

  get initialValues() {
    const { blocksConfiguration, incomeType, isBlocks, templateType, valueConclusionType, approaches } = this.props
    return {
      settings: { blocksConfiguration, incomeType, isBlocks, templateType, valueConclusionType, approaches },
    }
  }

  render() {
    const { open, onClose } = this.props

    return (
      <Form
        initialValues={this.initialValues}
        onSubmit={this.onSubmit}
        decorators={approachesDecorators}
        render={({ form, initialValues, pristine, handleSubmit, values, dirtyFields }) =>
          open && (
            <ReportSettingsModal
              dirtyFields={dirtyFields}
              initialValues={initialValues}
              onClose={onClose}
              onReset={form.reset}
              onSubmit={handleSubmit}
              pristine={pristine}
              values={values}
            />
          )
        }
      />
    )
  }
}

ReportSettingsModalContainer.propTypes = {
  blocksConfiguration: PropTypes.object.isRequired,
  incomeType: PropTypes.string.isRequired,
  isBlocks: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  templateType: PropTypes.string.isRequired,
  updateSettings: PropTypes.func.isRequired,
  valueConclusionType: PropTypes.string.isRequired,
}

export default ReportSettingsModalContainer
