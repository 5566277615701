import React, { useEffect, useRef } from 'react'
import ReactDOM from 'react-dom'
import classNames from 'classnames'
import { makeStyles } from '@mui/styles'
import { Chip, Divider, Typography } from '@mui/material'

import { ChipOption } from './plugins/Locked/types'
import { formatChipValue } from './plugins/Locked/helpers'
import HighlightSearchQuery from './HighlightSearchQuery'
import { hoverSuggestStyles } from './styles'

const getSuggestions = (searchResults: any[]) => {
  return searchResults.map((result, index) => index)
}

type SuggestProps = React.HTMLAttributes<HTMLDivElement>

const Suggest = React.forwardRef<HTMLDivElement, SuggestProps>(({ className, ...props }, ref) => {
  return <div data-qa="narrative-suggestions-list" {...props} ref={ref} className={className} />
})

const useStyles = makeStyles(hoverSuggestStyles)

type HoverSuggestProps = {
  className?: string
  searchQuery: string
  searchResults: ChipOption[]
  select: (suggestion: any) => void
  selectedSuggestion: number
  style?: React.CSSProperties
}

export const HoverSuggest = React.forwardRef<HTMLDivElement, HoverSuggestProps>(
  ({ className, searchQuery, searchResults, select, selectedSuggestion, style }, ref) => {
    const classes = useStyles()

    const root = window.document.getElementById('root')
    const suggestRef = useRef<HTMLUListElement>(null)

    useEffect(() => {
      if (suggestRef.current && selectedSuggestion !== -1) {
        suggestRef.current.children[selectedSuggestion].scrollIntoView({
          behavior: 'smooth',
          block: 'nearest',
          inline: 'start',
        })
      }
    }, [selectedSuggestion])

    if (!root) {
      console.warn('No root element found for HoverSuggest')
      return null
    }

    const suggestionsToShow = getSuggestions(searchResults)

    return ReactDOM.createPortal(
      <Suggest ref={ref} className={classNames(classes.suggest, className)} style={style}>
        <ul className={classes.valuesList} ref={suggestRef}>
          {suggestionsToShow.map(index => {
            const result = searchResults[index]
            if (!result) {
              return null
            }

            const className = classNames(
              { [classes.highlightSuggestion]: index === selectedSuggestion },
              classes.listItems
            )

            const formattedValue = formatChipValue(result)

            return (
              <li key={result.displayName}>
                <div className={className} onClick={() => select(result)}>
                  <div>
                    {formattedValue ? (
                      <Typography variant="body1" className={classes.primaryText}>
                        <HighlightSearchQuery text={formattedValue} searchQuery={searchQuery} />
                      </Typography>
                    ) : (
                      <span className={classes.notFoundText}>Information not found</span>
                    )}
                  </div>
                  <div>
                    <Typography variant="body2" className={classes.secondaryText}>
                      <HighlightSearchQuery text={result.displayName} searchQuery={searchQuery} />
                    </Typography>
                  </div>
                  <div className={classes.groupName}>
                    <Chip
                      label={<HighlightSearchQuery text={result.groupName} searchQuery={searchQuery} />}
                      size="small"
                    />
                    {result.tags && result.tags.length > 0 && (
                      <Chip
                        label={<HighlightSearchQuery text={result.tags[0]} searchQuery={searchQuery} />}
                        size="small"
                      />
                    )}
                  </div>
                </div>
                <Divider variant="middle" />
              </li>
            )
          })}
        </ul>
      </Suggest>,
      root
    )
  }
)
