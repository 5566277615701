import { handleActions, combineActions } from 'redux-actions'
import { get } from 'lodash'

import { dispatchUserAuthenticatedEvent } from '../../analytics'

import { userUpdateReceive } from '../../admin/redux/actions/user'

import {
  loginUserReceive,
  logoutUserReceive,
  loginUserFail,
  fetchUserRequest,
  fetchUserReceive,
  fetchUserFail,
  authClearError,
} from './actions'

const initialState = {
  authenticationError: null,
  isLoaded: false,
  isLoading: true,
  isAuthenticated: false,
  user: {},
  roles: [],
}

export default handleActions(
  {
    [combineActions(authClearError, logoutUserReceive)]() {
      return { ...initialState, isLoading: false }
    },
    [loginUserReceive](state, { payload }) {
      const { user, roles } = payload
      dispatchUserAuthenticatedEvent(user)
      return {
        ...state,
        authenticationError: null,
        isLoaded: true,
        isAuthenticated: true,
        user,
        roles,
      }
    },
    [loginUserFail](state, { payload }) {
      const errorData = get(payload, 'data')
      return {
        ...state,
        authenticationError: errorData.errorMessage,
      }
    },
    [fetchUserRequest](state, { payload }) {
      return {
        ...state,
        isLoading: true,
        isLoaded: false,
      }
    },
    [fetchUserReceive](state, { payload }) {
      dispatchUserAuthenticatedEvent(payload?.user)
      return {
        ...state,
        user: payload.user,
        roles: payload.roles,
        isAuthenticated: true,
        isLoading: false,
        isLoaded: true,
      }
    },
    [fetchUserFail](state) {
      return {
        ...state,
        isLoading: false,
        isLoaded: false,
        isAuthenticated: false,
      }
    },
    [userUpdateReceive](state, { payload }) {
      const currentUser = get(state, 'user')
      const user = get(payload, 'user')
      if (user._id === currentUser._id) {
        return { ...state, user: user }
      } else {
        return state
      }
    },
  },
  initialState
)
