import React from 'react'
import PropTypes from 'prop-types'
import { Popover } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'
import classnames from 'classnames'
import { keys, includes } from 'lodash'

import AmenitiesPopoverWrapper from './AmenitiesPopoverWrapper'
const AMENITY_WIDTH = 28

const styles = theme => ({
  amenity: {
    backgroundColor: theme.palette.secondary[400],
    color: 'white',
    margin: theme.spacing.unit / 4,
    padding: theme.spacing.unit / 4,
    borderRadius: '50%',
    display: 'inline-block',
    textAlign: 'center',
    fontWeight: 400,
    lineHeight: '20px',
    width: 20,
    height: 20,
    fontFamily: 'Monospace',
    textTransform: 'capitalize',
  },
  showAmenities: {
    '&:hover': {
      backgroundColor: theme.palette.primary[700],
    },
    cursor: 'pointer',
    backgroundColor: theme.palette.primary[600],
  },
  popover: {
    pointerEvents: 'none',
  },
})

const TWO_LETTER_ID_AMENITIES = ['pool', 'parking']

class AmenitiesList extends React.PureComponent {
  state = {
    anchorElement: null,
  }

  static propTypes = {
    amenities: PropTypes.objectOf(PropTypes.bool),
    maxInRow: PropTypes.number,
  }

  static defaultProps = {
    maxInRow: 3,
  }

  openPopover = event => {
    event.preventDefault()
    this.setState({
      anchorElement: event.target,
    })
  }

  closePopover = event => {
    event.preventDefault()
    this.setState(prevState => ({
      anchorElement: null,
    }))
  }

  renderAmenitiesPopover(remainingAmenities) {
    const { classes } = this.props
    const { anchorElement } = this.state

    return (
      <React.Fragment key="amenities-popup">
        <span
          key="amenities-popup-button"
          className={classnames(classes.amenity, classes.showAmenities)}
          onMouseOver={this.openPopover}
          onMouseOut={this.closePopover}
        >
          {`+${remainingAmenities.length}`}
        </span>
        <Popover
          key="amenities-popover"
          className={classes.popover}
          open={!!anchorElement}
          anchorEl={anchorElement}
          onClose={this.closePopover}
          PaperProps={{
            component: ({ children, ...props }) => {
              return (
                <AmenitiesPopoverWrapper {...props} left={(remainingAmenities.length * AMENITY_WIDTH) / 2}>
                  {children}
                </AmenitiesPopoverWrapper>
              )
            },
          }}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}
          disableRestoreFocus
        >
          {remainingAmenities}
        </Popover>
      </React.Fragment>
    )
  }

  renderAmenity = amenity => (
    <span key={amenity} className={this.props.classes.amenity}>
      {amenity}
    </span>
  )

  render() {
    const { amenities, maxInRow } = this.props

    const amenitiesLetters = keys(amenities)
      .filter(key => amenities[key])
      .map(amenity => (includes(TWO_LETTER_ID_AMENITIES, amenity) ? amenity.slice(0, 2) : amenity[0]))
      .filter(key => key !== '_')

    const amenitiesList = amenitiesLetters.map(this.renderAmenity)

    return (
      <div style={{ width: AMENITY_WIDTH * maxInRow }}>
        {amenitiesList.length > maxInRow
          ? [...amenitiesList.slice(0, maxInRow - 1), this.renderAmenitiesPopover(amenitiesList.slice(maxInRow - 1))]
          : amenitiesList}
      </div>
    )
  }
}

export default withStyles(styles)(AmenitiesList)
