import Handsontable from 'handsontable'
import maskInput from 'vanilla-text-mask'
import { isEmpty } from 'lodash'

import BoweryDate from '@bowery-valuation/bowery-date'

const INPUT_DATE_MASK = [/\d/, /\d/, '/', /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/]

class DateEditor extends Handsontable.editors.TextEditor {
  createElements() {
    super.createElements()

    this.TEXTAREA = this.hot.rootDocument.createElement('input')
    this.TEXTAREA.setAttribute('type', 'text')
    this.TEXTAREA.className = 'handsontableInput'
    this.TEXTAREA.setAttribute('data-hot-input', true)
    this.textareaStyle = this.TEXTAREA.style
    this.textareaStyle.width = 0
    this.textareaStyle.height = 0
    maskInput({
      inputElement: this.TEXTAREA,
      mask: INPUT_DATE_MASK,
      guide: false,
    })

    Handsontable.dom.empty(this.TEXTAREA_PARENT)
    this.TEXTAREA_PARENT.appendChild(this.TEXTAREA)
  }

  focus() {
    this.TEXTAREA.select()
  }

  getValue() {
    const value = this.TEXTAREA.value

    if (isEmpty(value)) {
      return null
    }
    const date = new BoweryDate(value)
    return date.isValidDate() ? date.toString() : null
  }

  setValue(newValue) {
    const date = newValue ? new BoweryDate(newValue).formatShortDateWithZeroes() : ''
    this.TEXTAREA.value = date
  }
}

const dateRenderer = (instance, td, row, col, prop, value, cellProperties) => {
  Handsontable.renderers.TextRenderer.call(this, instance, td, row, col, prop, value, cellProperties)
  td.setAttribute('data-qa', `${prop}-${row}-cell`)

  const date = !isEmpty(value) ? new BoweryDate(value).formatShortDateWithZeroes() : ''
  td.innerHTML = date
  return td
}

const readOnlyRenderer = (instance, td, row, col, prop, value, cellProperties) => {
  Handsontable.cellTypes
    .getCellType('bowery.date')
    .renderer.call(this, instance, td, row, col, prop, value, cellProperties)
  td.innerHTML = td.innerHTML || '-'
  return td
}

export const registerDate = () =>
  Handsontable.cellTypes.registerCellType('bowery.date', {
    editor: DateEditor,
    renderer: dateRenderer,
    readOnlyRenderer: readOnlyRenderer,
    validator: (value, callback) => {
      if (value === null) {
        return callback(true)
      }

      callback(value && new BoweryDate(value).isValidDate())
    },
    allowInvalid: false,
  })
