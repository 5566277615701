import { camelCase, get, isEmpty, mean, replace, toLower } from 'lodash'
import BoweryDate from '@bowery-valuation/bowery-date'

import { EXPENSE_COMPARABLES_CATEGORIES, EXPENSE_COMPARABLES_INFO } from 'shared/constants/expenses/expenseComparables'
import { SourceInformationLabels } from 'shared/constants/report'

import { EXPENSE_IDS_MAP } from 'shared/constants/expenses/expenseForecast'

import { EXPENSE_HISTORY_TYPE_KEYS, EXPENSE_HISTORY_TYPE_TITLES } from 'shared/constants/expenses/expenseHistory'

export const isProjectionPeriod = expensePeriod =>
  [EXPENSE_HISTORY_TYPE_KEYS.PROJECTION, EXPENSE_HISTORY_TYPE_TITLES.PROJECTION].includes(expensePeriod)

export const getRecentHistoricalExpenses = (expenses, basisVal, currentExpenseCategories) => {
  let historicalExpenses = 0
  currentExpenseCategories.forEach(name => {
    const expense = expenses.filter(expenseValue => expenseValue.id === name)[0]
    historicalExpenses += isEmpty(expense) ? 0 : parseFloat(expense[basisVal])
  })
  return historicalExpenses
}

const formatId = name => {
  return camelCase(toLower(replace(name, '&', 'And')))
}

export const formatExpenseId = expenseName => {
  const id = formatId(expenseName)
  return EXPENSE_IDS_MAP[id] || id
}

export const calculateValuesMean = values => {
  const filledValues = values.map(value => value || 0)
  return mean(filledValues)
}

export const mapExpenseComp = expenseComp => {
  const _id = get(expenseComp, '_id')

  const information = Object.keys(EXPENSE_COMPARABLES_INFO).map(category => {
    let value = expenseComp[category]
    if (category === EXPENSE_COMPARABLES_CATEGORIES.expenseMonth) {
      const expenseDate = get(expenseComp, 'date', null)
      value = expenseDate ? new BoweryDate(expenseDate).format('MMMM') : get(expenseComp, 'expenseMonth', '')
    } else if (category === EXPENSE_COMPARABLES_CATEGORIES.expenseYear) {
      const expenseDate = get(expenseComp, 'date', null)
      value = expenseDate ? new BoweryDate(expenseDate).year : get(expenseComp, 'expenseYear', '')
    } else if (category === EXPENSE_COMPARABLES_CATEGORIES.expensePeriod) {
      value = get(expenseComp, 'period')
    } else if (category === EXPENSE_COMPARABLES_CATEGORIES.squareFeet) {
      value = get(expenseComp, 'sqft')
    } else if (category === EXPENSE_COMPARABLES_CATEGORIES.residentialUnits) {
      value = get(expenseComp, 'res_units')
    } else if (category === EXPENSE_COMPARABLES_CATEGORIES.sourceOfInformation) {
      value = SourceInformationLabels[get(expenseComp, 'sourceUrl')] || get(expenseComp, 'sourceUrl')
    }
    return { id: category, value }
  })

  return {
    _id,
    isAutoComp: expenseComp.isAutoComp,
    boweryId: expenseComp.boweryId,
    updatedAt: expenseComp.updatedAt,
    version: expenseComp.version,
    key: _id,
    expenseRatio: expenseComp.expenseRatio,
    total: expenseComp.total,
    totalNetTaxes: expenseComp.totalNetTaxes,
    totalPerSF: expenseComp.totalPerSF,
    totalPerSFNetTaxes: expenseComp.totalPerSFNetTaxes,
    totalPerUnit: expenseComp.totalPerUnit,
    totalPerUnitNetTaxes: expenseComp.totalPerUnitNetTaxes,
    expenses: [...information, ...get(expenseComp, 'expenses')],
    hiddenExpenses: get(expenseComp, 'hiddenExpenses'),
  }
}

export const mapExpenseComps = expenseComps => {
  return expenseComps.map(mapExpenseComp)
}
