import React from 'react'
import PropTypes from 'prop-types'

import { Button, TableBody, Typography } from '@mui/material'
import { DragDropContext } from 'react-beautiful-dnd'

import { DroppableTable } from '../../../../../shared/components/DragDropTable'
import DropDownButton from '../../../../../shared/components/DropDown/DropDownButton'
import WarningDialog from '../../../../../shared/components/WarningDialog'
import { Area, Template } from '../../../../../shared/components/Template'

import AdditionalRequirementRow from './AdditionalRequirementRow'
import { MODAL_TEXT, MODAL_TITLE, TABLE_HEADER } from './constants'

class AdditionalRequirements extends React.PureComponent {
  static propTypes = {
    form: PropTypes.object.isRequired,
    fields: PropTypes.object.isRequired,
  }

  state = {
    dragging: false,
    modalOpen: false,
  }

  constructor(props) {
    super(props)
    this.inputs = new Map()
  }

  get addItemButtonOptions() {
    const { form } = this.props
    const { values } = form

    return [
      { label: 'Add Blank Row', value: '' },
      {
        label: 'Add Client Language',
        value: `This appraisal abides by ${values.client}'s appraisal guidelines.`,
      },
      {
        label: 'Minimum Valuation Language',
        value:
          'The appraisal assignment was not based on a requested minimum valuation, a specific valuation, or the approval of a loan.',
      },
    ]
  }

  setInputRef = (index, ref) => {
    this.inputs.set(index, ref)
  }

  onInsertItem = index => {
    const { fields } = this.props
    const nextElementIndex = index + 1

    fields.insert(nextElementIndex, '')
    this.focusInput(nextElementIndex)
  }

  focusInput = index => {
    setTimeout(() => {
      const input = this.inputs.get(index)
      if (input) {
        input.focus()
      }
    })
  }

  openModal = () => {
    this.setState({ modalOpen: true })
  }

  closeModal = () => {
    this.setState({ modalOpen: false })
  }

  onDragStart = () => {
    this.setState({ dragging: true })
  }

  onDragEnd = ({ source, destination }) => {
    const { fields } = this.props
    this.setState({ dragging: false })
    if (!destination) {
      return
    }
    const input = this.inputs.get(destination.index)
    input.focus()

    fields.move(source.index, destination.index)
  }

  onRemoveItem = index => {
    const { form } = this.props
    const values = this.props.fields.value.filter((value, key) => key !== index)

    form.change('additionalRequirements', values)
  }

  onClearAllItems = () => {
    const { form } = this.props
    form.change('additionalRequirements', [])
    this.closeModal()
  }

  onItemAdd = ({ value }) => {
    const { fields } = this.props
    fields.push(value)
  }

  render() {
    const { fields } = this.props
    const { modalOpen, dragging } = this.state
    return (
      <Template
        is={`
          '   header      header'
          'addButton clearButton'
          '  content     content'
        `}
        gap={2}
      >
        <WarningDialog
          text={MODAL_TEXT}
          title={MODAL_TITLE}
          onCancel={this.closeModal}
          onSubmit={this.onClearAllItems}
          open={modalOpen}
        />
        <Area is="header">
          <Typography variant="body1" paragraph>
            {TABLE_HEADER}
          </Typography>
        </Area>
        <Area is="addButton">
          <DropDownButton
            data-qa="add-btn"
            variant="contained"
            onSelect={this.onItemAdd}
            options={this.addItemButtonOptions}
          >
            Add
          </DropDownButton>
        </Area>
        <Area is="clearButton" placeContent="center end">
          <Button variant="outlined" onClick={this.openModal} size="small" data-qa="clear-all-btn">
            CLEAR ALL
          </Button>
        </Area>
        <Area is="content">
          <DragDropContext onDragStart={this.onDragStart} onDragEnd={this.onDragEnd}>
            <DroppableTable id="scope-table" type="scope-table">
              <TableBody data-qa="scope-table-body">
                {fields.map((name, index) => (
                  <AdditionalRequirementRow
                    key={name}
                    name={name}
                    index={index}
                    dragging={dragging}
                    singleValue={false}
                    onRemoveItem={this.onRemoveItem}
                    setInputRef={this.setInputRef}
                    onInsertItem={this.onInsertItem}
                  />
                ))}
              </TableBody>
            </DroppableTable>
          </DragDropContext>
        </Area>
      </Template>
    )
  }
}

export default AdditionalRequirements
