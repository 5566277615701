import React, { memo } from 'react'
import { get } from 'lodash'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import { withStyles } from '@material-ui/core/styles'
import { FieldArray } from 'react-final-form-arrays'

import { Table, TableHead, TableBody, TableCell, Checkbox } from '@material-ui/core'

import { RESIDENTIAL_RENT_ROLL_KEY } from 'shared/constants/report/keysAndDataPaths'

import { TableHeadRow, TableBodyRow } from '../../../../../../shared/components/TableHelpers'
import { Number } from '../../../../../../shared/components'

import { required } from '../../../../../../shared/utils/validation'
import { formatCurrencyFloat } from '../../../../../../shared/utils/numberFormatters'

import {
  getUnitRoomsCount,
  getUnitRentRoom,
  getUnitRentPSF,
  getUnitRentForecastPSF,
} from '../../ResidentialRentRoll/tools'
import { getRentPSFLabel } from '../../../../../../../../shared/helpers/rentRoll'

import { DATA_PATH as BASE_DATA_PATH } from '../../index'
import { Labels } from '../../ResidentialRentRoll/constants'

//TODO: Keeping it as it is, but we need to investigate if this path is correct.
//It looks like RESIDENTIAL_PROJECTED_RENT_ROLL_KEY = 'residentialProjectedRentRoll' makes more sense here
export const DATA_PATH = [...BASE_DATA_PATH, RESIDENTIAL_RENT_ROLL_KEY]

const roomsWidth = 40

const styles = theme => ({
  cell: {
    width: 150,
    paddingLeft: theme.spacing.unit,
    paddingRight: theme.spacing.unit,
  },
  firstCell: {
    paddingLeft: theme.spacing.unit * 2,
  },
  roomsCell: {
    width: roomsWidth,
  },
  totalCell: theme.totalCell,
  button: {
    marginLeft: 5,
  },
  leftSidebar: {
    maxWidth: 350,
    '@media(min-width: 1530px)': {
      flexBasis: '100%',
      maxWidth: '100%',
    },
    '@media(min-width: 1920px)': {
      flexBasis: '25%',
      minWidth: 300,
      maxWidth: 350,
    },
  },
  form: {
    marginTop: 16,
    marginRight: 8,
  },
  grid: {
    '@media(min-width: 1530px)': {
      flexBasis: '100%',
      maxWidth: '100%',
    },
    '@media(min-width: 1920px)': {
      flexBasis: '75%',
      maxWidth: '75%',
    },
  },
  paper: theme.paper,
  copyDownContainer: {
    display: 'inline-flex',
    alignItems: 'center',
  },
})

const tableStyles = {
  tableCell: {
    fontSize: 13,
  },
}

const renderProjectedRentRollRowCell = ({ column, unitName, unit, index, form, classes }) => {
  const { unitRentPSFTimePeriod } = form.values

  const order = index + 1

  switch (column.name) {
    case 'isInspected':
      return (
        <TableCell data-qa="isInspected-cell" className={classnames(classes.cell, classes.firstCell)}>
          <Checkbox checked={unit.isInspected} disabled />
        </TableCell>
      )
    case '#':
      return (
        <TableCell data-qa="order-cell" className={classes.cell}>
          {order}
        </TableCell>
      )
    case 'number':
      return (
        <TableCell data-qa="unitNumber-cell" className={classes.cell}>
          {unit.number}
        </TableCell>
      )
    case 'rooms':
      return (
        <TableCell data-qa="rooms-cell" className={classes.roomsCell}>
          {getUnitRoomsCount(unit)}
        </TableCell>
      )
    case 'bedrooms':
      return (
        <TableCell data-qa="bedrooms-cell" className={classes.cell}>
          {unit.bedrooms}
        </TableCell>
      )
    case 'unitLayout':
      return (
        <TableCell data-qa="unitLayout-cell" className={classes.cell}>
          {unit.unitLayout}
        </TableCell>
      )
    case 'bathrooms':
      return (
        <TableCell data-qa="bathrooms-cell" className={classes.cell}>
          {unit.bathrooms}
        </TableCell>
      )
    case 'squareFootage':
      return (
        <TableCell data-qa="squareFootage-cell" className={classes.cell}>
          {unit.squareFootage}
        </TableCell>
      )
    case 'outdoor':
      return (
        <TableCell data-qa="outdoor-cell" className={classes.cell}>
          {unit.outdoor}
        </TableCell>
      )
    case 'rentType':
      return (
        <TableCell data-qa="rentType-cell" className={classes.cell}>
          {unit.rentType}
        </TableCell>
      )
    case 'rent':
      return (
        <TableCell data-qa="rent-cell" className={classes.cell}>
          <div className={classes.copyDownContainer}>
            <Number
              name={`${unitName}.rent`}
              allowNegative={false}
              disabled={!unit.leaseStatus}
              decimalScale={2}
              thousandSeparator
              style={tableStyles.tableCell}
              validate={required}
            />
          </div>
        </TableCell>
      )
    case 'rent/room':
      return (
        <TableCell data-qa="rentPerRoom-cell" className={classes.cell}>
          {getUnitRentRoom(unit)}
        </TableCell>
      )
    case 'rent/SF':
      return (
        <TableCell data-qa="rentPerSquareFootage-cell" className={classes.cell}>
          {getUnitRentPSF(unit, unitRentPSFTimePeriod)}
        </TableCell>
      )
    case 'leaseStatus':
      return (
        <TableCell data-qa="leaseStatus-cell" className={classes.cell}>
          {unit.leaseStatus}
        </TableCell>
      )
    case 'rentForecast':
      return (
        <TableCell data-qa="rentForecast-cell" className={classes.cell}>
          {formatCurrencyFloat(unit.rentForecast)}
        </TableCell>
      )
    case 'rentForecast/SF':
      return (
        <TableCell data-qa="rentForecastPerSquareFootage-cell" className={classes.cell}>
          {getUnitRentForecastPSF(unit, unitRentPSFTimePeriod)}
        </TableCell>
      )
    default:
      return (
        <TableCell data-qa={`${column.name}-cell`} className={classes.cell}>
          {get(unit, column.name)}
        </TableCell>
      )
  }
}
const getUnitsTableColumns = (
  perUnitSF,
  bathrooms,
  outdoorSpace,
  unitLayout,
  showDevelopersForecast,
  includePerRoomAnalysis,
  unitRentPSFTimePeriod,
  classes
) => {
  let columns = [
    {
      name: 'isInspected',
      title: 'Inspected',
      width: 40,
      className: classes.firstCell,
    },
    {
      name: '#',
      title: '#',
      width: 20,
    },
    {
      name: 'number',
      title: Labels.UNIT_NUMBER,
      width: 90,
    },
  ]

  if (includePerRoomAnalysis) {
    columns.push({
      name: 'rooms',
      title: Labels.ROOMS,
      width: roomsWidth,
    })
  }

  columns.push({ name: 'bedrooms', title: `# ${Labels.BEDROOMS}`, width: 100 })

  if (unitLayout) {
    columns.push({
      name: 'unitLayout',
      title: Labels.UNIT_LAYOUT,
      width: 100,
    })
  }

  if (bathrooms) {
    columns.push({
      name: 'bathrooms',
      title: `# ${Labels.BATHROOMS}`,
      width: 100,
    })
  }

  if (perUnitSF) {
    columns.push({ name: 'squareFootage', title: Labels.SQUARE_FOOTAGE })
  }

  if (outdoorSpace) {
    columns.push({ name: 'outdoor', title: Labels.OUTDOOR_SPACE })
  }

  columns = [
    ...columns,
    {
      name: 'rentType',
      title: Labels.RENT_TYPE,
      width: 180,
    },
    {
      name: 'rent',
      title: `${Labels.MONTHLY_RENT} ($)`,
      width: 110,
    },
  ]

  if (includePerRoomAnalysis) {
    columns.push({
      name: 'rent/room',
      title: Labels.RENT_PER_ROOM,
      width: 80,
    })
  }

  if (perUnitSF) {
    columns.push({
      name: 'rent/SF',
      title: getRentPSFLabel(unitRentPSFTimePeriod),
      width: 70,
    })
  }

  columns.push({
    name: 'leaseStatus',
    title: Labels.LEASE_STATUS,
    width: 40,
  })

  if (showDevelopersForecast) {
    columns.push({
      name: 'rentForecast',
      title: Labels.RENT_FORECAST,
      width: 40,
    })

    if (perUnitSF) {
      columns.push({
        name: 'rentForecast/SF',
        title: getRentPSFLabel(unitRentPSFTimePeriod),
        width: 70,
      })
    }
  }

  return columns
}

const renderUnitsTable = ({ fields, classes, form }) => {
  const {
    perUnitSF,
    bathrooms,
    outdoorSpace,
    unitLayout,
    showDevelopersForecast,
    includePerRoomAnalysis,
    unitRentPSFTimePeriod,
  } = form.values

  const units = get(fields, 'value', [])
  const columns = getUnitsTableColumns(
    perUnitSF,
    bathrooms,
    outdoorSpace,
    unitLayout,
    showDevelopersForecast,
    includePerRoomAnalysis,
    unitRentPSFTimePeriod,
    classes
  )

  const rentTotalMonthlyStabilized = units.reduce((total, unit) => {
    return total + (get(unit, 'rent') || 0)
  }, 0)
  const rentTotalAnnualStabilized = rentTotalMonthlyStabilized * 12

  const rentForecastTotalMonthly = units.reduce((total, unit) => total + unit.rentForecast, 0)
  const rentForecastTotalAnnual = rentForecastTotalMonthly * 12

  return (
    <Table data-qa="residential-rent-roll-table">
      <TableHead>
        <TableHeadRow columns={columns} cellClass={classes.cell} />
      </TableHead>
      <TableBody>
        {fields.map((name, index) => {
          return (
            <TableBodyRow
              key={index}
              columns={columns}
              unitName={name}
              index={index}
              fields={fields}
              unit={fields.value[index]}
              form={form}
              classes={classes}
              cellComponent={renderProjectedRentRollRowCell}
            />
          )
        })}
        <TableBodyRow
          data-qa="rentTotalMonthly-row"
          columns={columns}
          classes={classes}
          cellComponent={renderTotalMonthlyRentRowCell}
          rentTotalMonthly={rentTotalMonthlyStabilized}
          rentForecastTotalMonthly={rentForecastTotalMonthly}
          rentLabel="Rent Total (Monthly)"
        />
        <TableBodyRow
          data-qa="rentTotalAnnual-row"
          columns={columns}
          classes={classes}
          cellComponent={renderTotalAnnualRentRowCell}
          rentTotalAnnual={rentTotalAnnualStabilized}
          rentForecastTotalAnnual={rentForecastTotalAnnual}
          rentLabel="Rent Total (Annual)"
        />
      </TableBody>
    </Table>
  )
}
const renderTotalMonthlyRentRowCell = ({ column, rentTotalMonthly, rentForecastTotalMonthly, rentLabel, classes }) => {
  switch (column.name) {
    case 'rentType':
      return <TableCell className={classnames(classes.cell, classes.totalCell)}>{rentLabel}</TableCell>
    case 'rent':
      return (
        <TableCell data-qa="total-monthly-cell" className={classnames(classes.cell, classes.totalCell)}>
          {formatCurrencyFloat(rentTotalMonthly)}
        </TableCell>
      )
    case 'rentForecast':
      return (
        <TableCell data-qa="total-monthly-forecast-cell" className={classnames(classes.cell, classes.totalCell)}>
          {formatCurrencyFloat(rentForecastTotalMonthly)}
        </TableCell>
      )
    default:
      return <TableCell className={classnames(classes.cell, classes.totalCell)} />
  }
}

const renderTotalAnnualRentRowCell = ({ column, rentTotalAnnual, rentForecastTotalAnnual, rentLabel, classes }) => {
  switch (column.name) {
    case 'rentType':
      return <TableCell className={classnames(classes.cell, classes.totalCell)}>{rentLabel}</TableCell>
    case 'rent':
      return (
        <TableCell data-qa="total-annual-cell" className={classnames(classes.cell, classes.totalCell)}>
          {formatCurrencyFloat(rentTotalAnnual)}
        </TableCell>
      )
    case 'rentForecast':
      return (
        <TableCell data-qa="total-annual-forecast-cell" className={classnames(classes.cell, classes.totalCell)}>
          {formatCurrencyFloat(rentForecastTotalAnnual)}
        </TableCell>
      )
    default:
      return <TableCell className={classnames(classes.cell, classes.totalCell)} />
  }
}

const ProjectedRentRollTable = ({ classes, form }) => {
  return <FieldArray name="units" classes={classes} form={form} component={renderUnitsTable} />
}

ProjectedRentRollTable.propTypes = {
  classes: PropTypes.object.isRequired,
  form: PropTypes.object.isRequired,
}

export default withStyles(styles)(memo(ProjectedRentRollTable))
