import React, { useEffect, useMemo, useState } from 'react'
import AutoModeIcon from '@mui/icons-material/AutoModeRounded'
import { CircularProgress, Paper, Stack, Typography } from '@mui/material'
import LoadingButton from '@mui/lab/LoadingButton'
import { withErrorBoundary } from 'react-error-boundary'
import { get } from 'lodash'
import useBoolean from 'client-shared/hooks/useBoolean'
import { Callout } from 'client-shared/components'

import AutomationWarnings from 'client-shared/components/AutomationCTA/AutomationWarnings'
import { useDispatch, useSelector } from 'client-shared/hooks/redux'

import { saveReport as saveReportAction } from '../../../report/redux/actions/report'

import ConfirmationDialog from './ConfirmationDialog'

type status = 'none' | 'success' | 'error' | 'disabled' | 'warn'

type AutomationCTAProps = {
  CTAMessage: React.ReactNode
  errorMessage: React.ReactNode
  warningMessage?: React.ReactNode
  warningTitle?: React.ReactNode
  successMessage: React.ReactNode
  disableCTA?: boolean
  disabledMessage?: React.ReactNode
  areDocumentsImporting?: boolean
  onAutomationRun: (() => void) | (() => Promise<void>)
  onAutomationError?: (() => void) | (() => Promise<void>)
  showWarningsSeparateFromMainAlert?: boolean
  setGlobalAutomationSavedAfterSuccessfulAutomation?: string
}

const successAlertStyles = {
  backgroundColor: 'rgb(236, 248, 243)',
  color: 'rgb(32, 71, 55)',
}

const errorAlertStyles = {
  backgroundColor: 'rgb(248, 236, 236)',
  color: 'rgb(77, 30, 28)',
}

const warnAlertStyles = {
  backgroundColor: 'rgb(255, 249, 238)',
  color: 'rgb(102, 78, 34)',
}

const FallbackComponent = () => (
  <Callout
    variant="error"
    content="Something went wrong with Automation. Reach out to support team for help or try again later."
    sx={{ marginBottom: '22px', backgroundColor: errorAlertStyles.backgroundColor, color: errorAlertStyles.color }}
  />
)

const AutomationCTA: React.FC<AutomationCTAProps> = ({
  CTAMessage,
  errorMessage,
  warningMessage,
  warningTitle,
  successMessage,
  onAutomationRun,
  onAutomationError,
  disableCTA,
  disabledMessage,
  areDocumentsImporting,
  showWarningsSeparateFromMainAlert,
  setGlobalAutomationSavedAfterSuccessfulAutomation,
}) => {
  const [status, setStatus] = useState<status>('none')
  const [hasAutomationRun, setHasAutomationRun] = useState<boolean>(false)
  const [isAutomationInProgress, setIsAutomationInProgress] = React.useState(false)
  const [dialogIsOpen, openConfirmationDialog, closeConfirmationDialog] = useBoolean(false)
  useEffect(() => {
    if (disableCTA && disabledMessage) {
      setStatus('disabled')
    }
    // eslint-disable-next-line
  }, [])

  useEffect(() => {
    if (warningMessage && hasAutomationRun && !showWarningsSeparateFromMainAlert) {
      setStatus('warn')
    }
  }, [hasAutomationRun, warningMessage, showWarningsSeparateFromMainAlert])

  const alertProps = useMemo(() => {
    switch (status) {
      case 'success':
        return {
          severity: 'success' as const,
          message: successMessage,
          ...successAlertStyles,
        }

      case 'error':
        return {
          severity: 'error' as const,
          message: errorMessage,
          ...errorAlertStyles,
        }

      case 'disabled':
        return {
          severity: 'error' as const,
          message: disabledMessage,
          ...errorAlertStyles,
        }

      case 'warn':
        return {
          severity: 'warn' as const,
          message: warningMessage,
          ...warnAlertStyles,
        }

      default:
        return null
    }
  }, [status, successMessage, errorMessage, disabledMessage, warningMessage])

  const formDataPath = useSelector(state => get(state, 'shared.location.form.formPath'))
  const dispatch = useDispatch()
  const saveReport = () => {
    dispatch(saveReportAction(formDataPath))
  }

  const onSubmit = async () => {
    setStatus('none')
    setHasAutomationRun(false)
    setIsAutomationInProgress(true)

    try {
      await onAutomationRun()
      setStatus('success')
      if (setGlobalAutomationSavedAfterSuccessfulAutomation) {
        global.automationSaved = setGlobalAutomationSavedAfterSuccessfulAutomation
        saveReport()
      }
    } catch (error) {
      console.error(error)
      setStatus('error')

      if (typeof onAutomationError === 'function') {
        await onAutomationError()
      }
    } finally {
      setHasAutomationRun(true)
      closeConfirmationDialog()
      setIsAutomationInProgress(false)
    }
  }

  const isLoadingState = isAutomationInProgress || areDocumentsImporting
  const loadingStateContent = (
    <div style={{ display: 'flex', alignItems: 'center', whiteSpace: 'nowrap' }}>
      <CircularProgress color="inherit" size={16} />
      {areDocumentsImporting && <span style={{ marginLeft: 4 }}>&nbsp;Processing documents</span>}
    </div>
  )

  return (
    <>
      <ConfirmationDialog
        open={dialogIsOpen}
        onCancel={closeConfirmationDialog}
        onSubmit={onSubmit}
        isAutomationInProgress={isAutomationInProgress}
      />
      <Paper elevation={3} sx={{ padding: '24px', marginBottom: '8px', marginTop: '8px' }}>
        {alertProps && (
          <Callout
            variant={alertProps.severity}
            content={alertProps.message}
            sx={{ marginBottom: '22px', backgroundColor: alertProps.backgroundColor, color: alertProps.color }}
          />
        )}
        {status !== 'warn' && warningMessage && (
          <AutomationWarnings warningTitle={warningTitle} warningMessage={warningMessage} />
        )}

        <Stack direction="row" spacing={3} alignItems="center" color="#673AB7">
          <Stack direction="row" spacing={1} alignItems="center">
            <AutoModeIcon />
            <Typography variant="body2">{CTAMessage}</Typography>
          </Stack>
          <LoadingButton
            variant="contained"
            disabled={disableCTA}
            loading={isLoadingState}
            loadingIndicator={loadingStateContent}
            onClick={openConfirmationDialog}
            data-qa="run-automation-btn"
          >
            {/* "It's a hack. Needed to increase the width of the `LoadingButton` because it uses content of the 
            component to determine its width. The content of the 'loadingIndicator' is not used in the calculations. */}
            {areDocumentsImporting ? loadingStateContent : 'Run Automation'}
          </LoadingButton>
        </Stack>
      </Paper>
    </>
  )
}

export default withErrorBoundary(AutomationCTA, {
  FallbackComponent,
  onError: (error, info) => console.error({ error, info }),
})
