import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import { Alert, Box } from '@mui/material'
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline'

import Text from '../Text'
import TitleWithIcons from '../TitleWithIcons'
import VerifiedCallout from '../VerifiedCallout'

import EditableGeneratedComment from './EditableGeneratedComment'

const EditableGeneratedCommentWithAppraiserComment = props => {
  const {
    className,
    errorAlertText,
    isLoading,
    label,
    leftIcon,
    showErrorAlert,
    showSuccessAlert,
    successAlertText,
    sx,
    textFieldClasses,
    tooltipPosition,
    tooltipText,
    ...commentProps
  } = props
  const fieldName = `${commentProps.dataPath}.additionalCommentary`

  return (
    <Box data-qa={`${commentProps.dataPath}-generated-comment-wrapper`} className={classnames(className)} sx={sx}>
      {label && (
        <TitleWithIcons
          title={label}
          leftIcon={leftIcon}
          tooltipText={tooltipText}
          tooltipPosition={tooltipPosition}
          className={classnames(className)}
        />
      )}
      {showErrorAlert && (
        <Alert
          severity="error"
          icon={<ErrorOutlineIcon color="error" />}
          sx={{ width: 'fit-content', display: 'inline-flex', marginLeft: 1 }}
        >
          {errorAlertText}
        </Alert>
      )}

      {showSuccessAlert && <VerifiedCallout>{successAlertText}</VerifiedCallout>}
      {commentProps.getGeneratedText && <EditableGeneratedComment isLoading={isLoading} {...commentProps} />}
      <Text
        fullWidth
        name={fieldName}
        label="Appraiser Commentary"
        rows={5}
        classes={textFieldClasses}
        data-qa={`${fieldName}-text-field`}
      />
    </Box>
  )
}

EditableGeneratedCommentWithAppraiserComment.propTypes = {
  ...EditableGeneratedComment.propTypes,
  dataPath: PropTypes.string.isRequired,
  label: PropTypes.string,
  leftIcon: PropTypes.node,
  tooltipText: PropTypes.string,
  tooltipPosition: PropTypes.string,
  textFieldClasses: PropTypes.object,
  successAlertText: PropTypes.string,
  errorAlertText: PropTypes.string,
  showSuccessAlert: PropTypes.bool,
  showErrorAlert: PropTypes.bool,
  isLoading: PropTypes.bool,
}

EditableGeneratedCommentWithAppraiserComment.defaultProps = {
  label: null,
  successAlertText: null,
  errorAlertText: null,
  showSuccessAlert: false,
  showErrorAlert: false,
  isLoading: false,
}

export default EditableGeneratedCommentWithAppraiserComment
