import React from 'react'
import PropTypes from 'prop-types'

import SaveIcon from '@mui/icons-material/Save'
import DownloadIcon from '@mui/icons-material/SaveAlt'
import { Drawer, Fab } from '@mui/material'

import LocationSearch from './LocationSearch'
import SelectedCompsList from './SelectedCompsList'
class Sidebar extends React.PureComponent {
  static propTypes = {
    properties: PropTypes.arrayOf(PropTypes.object).isRequired,
    onNewProperty: PropTypes.func.isRequired,
    onReorder: PropTypes.func.isRequired,
    onDelete: PropTypes.func.isRequired,
    onImageCapture: PropTypes.func.isRequired,
    downloadImage: PropTypes.bool,
  }

  static defaultProps = {
    downloadImage: true,
  }

  renderButton() {
    const { downloadImage, onImageCapture } = this.props

    return (
      <Fab
        color="primary"
        data-qa="download-img-btn"
        onClick={onImageCapture}
        sx={{ position: 'fixed', bottom: 30, left: 333 }}
      >
        {downloadImage ? <DownloadIcon /> : <SaveIcon />}
      </Fab>
    )
  }

  render() {
    const { properties, onNewProperty, onReorder } = this.props
    return (
      <>
        <Drawer variant="permanent" anchor="left" sx={{ '& .MuiDrawer-paper': { width: 388, paddingTop: 10 } }}>
          <LocationSearch onSelect={onNewProperty} properties={properties} />
          <SelectedCompsList
            properties={properties}
            onReorder={onReorder}
            onRemove={this.props.setIndexToDelete}
            onEdit={this.props.setIndexToEdit}
            sx={{ overflowY: 'auto', height: 'calc(100% - 128px)' }}
          />
          {this.renderButton()}
        </Drawer>
      </>
    )
  }
}

export default Sidebar
