import Handsontable from 'handsontable'

const clickInput = event => {
  const td = event.target.closest('td')
  const input = td.querySelector('input')

  if (input && input !== event.target) {
    event.preventDefault()
    event.stopPropagation()
    input.click()
  }
}

export const checkboxRenderer = (instance, td, row, col, prop, value, cellProperties) => {
  Handsontable.renderers.CheckboxRenderer.call(this, instance, td, row, col, prop, value, cellProperties)
  td.setAttribute('data-qa', `${prop}-${row}-cell`)

  const span = instance.rootDocument.createElement('span')
  const label = instance.rootDocument.createElement('label')

  label.append(...td.childNodes, span)
  label.addEventListener('click', clickInput)

  Handsontable.dom.empty(td)
  td.appendChild(label)

  instance.addHookOnce('afterDestroy', () => {
    label.removeEventListener('click', clickInput)
  })

  return td
}

export const registerCheckbox = () =>
  Handsontable.cellTypes.registerCellType('checkbox', {
    editor: Handsontable.editors.CheckboxEditor,
    renderer: checkboxRenderer,
  })

export const styles = theme => ({
  root: {
    '& input[type="checkbox"]': {
      display: 'none',

      '& + span': {
        fontFamily: '"Font Awesome 5 Pro"',
        fontSize: 10,
        borderRadius: 4,
        display: 'inline-block',
        height: 14,
        width: 14,
        border: `1px solid ${theme.palette.grey[700]}`,
        marginBottom: -4,
      },

      '&:checked + span': {
        backgroundColor: theme.palette.primary.main,
        border: 'none',
        height: 16,
        width: 16,
        position: 'relative',
      },

      '&:checked + span::before': {
        color: theme.palette.common.white,
        content: '"\\f00c"',
        position: 'absolute',
        left: 4,
        top: -2,
      },
    },
  },
})
