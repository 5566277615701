import HttpClientManager from '../xhr/HttpClientManager'

import { getAuthorizationHeader } from '../core/api'

const httpClient = HttpClientManager.createHttpClient(
  {
    baseURL: `${global.env.apiUrl}`,
  },
  { showError: false, retries: 2 }
)

export async function logout(data) {
  await httpClient.post('/user/logout')
  return true
}

export async function login(username, password) {
  try {
    return await httpClient.post('/user/login', { username, password })
  } catch (err) {
    throw err.response
  }
}

export async function fetchUser() {
  const authCheckResult = await httpClient.get('/user/authenticated-user', {
    headers: getAuthorizationHeader(),
  })
  return authCheckResult.data
}

export async function fetchRoles() {
  const roleList = await httpClient.get('/role', {
    headers: getAuthorizationHeader(),
  })
  return roleList.data
}
