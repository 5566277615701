import React from 'react'
import PropTypes from 'prop-types'

import { DragDropContext } from 'react-beautiful-dnd'

class DragDropWrapper extends React.PureComponent {
  onDragStart = () => {}

  onDragEnd = ({ source, destination }) => {
    const { formMutators } = this.props

    if (destination === null) {
      return
    }

    const draggedToSameCategory = source.droppableId === destination.droppableId

    if (draggedToSameCategory) {
      formMutators.move(source.droppableId, source.index, destination.index)
    } else {
      const sourcePhoto = formMutators.remove(source.droppableId, source.index)
      formMutators.insert(destination.droppableId, destination.index, sourcePhoto)
    }
  }

  render() {
    const { children } = this.props

    return (
      <DragDropContext onDragStart={this.onDragStart} onDragEnd={this.onDragEnd}>
        {children}
      </DragDropContext>
    )
  }
}

DragDropWrapper.propTypes = {
  children: PropTypes.node.isRequired,
  formMutators: PropTypes.object.isRequired,
}

export default DragDropWrapper
