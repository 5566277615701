import ChangePassword from './ChangePassword'
import Settings from './Settings'

export default {
  changePassword: {
    name: 'Change Password',
    color: '#ae0808',
    formComponent: ChangePassword,
  },
  changeSettings: {
    name: 'Change Settings',
    color: '#ae0808',
    formComponent: Settings,
  },
}
