import { STATE_ABBREVIATIONS, STATE_NAMES_SHORT } from '../../constants/states'

export const getStateAbbreviation = stateName => {
  const stateNameIsFull = !!STATE_ABBREVIATIONS[stateName]
  const stateAbbreviation = stateNameIsFull ? STATE_ABBREVIATIONS[stateName] : STATE_NAMES_SHORT[stateName]

  return stateAbbreviation
}

export const getCityStateZip = (city, stateName, zip) => {
  const stateAbbreviation = STATE_ABBREVIATIONS[stateName]
  return `${city}, ${stateAbbreviation} ${zip}`
}
