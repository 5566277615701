import { cloneDeep } from 'lodash'

export const NO_PERFORMED_SERVICES_SENTENCE =
  'We have performed no services, as an appraiser or in any other capacity, regarding the property that is the subject of this report within the three-year period immediately preceding the agreement to perform this assignment.'

export const PERFORMED_SERVICES_SENTENCE =
  'We have performed services, as an appraiser, regarding the property that is the subject of this report within the three-year period immediately preceding the agreement to perform this assignment.'

export const DEFAULT_USPAP_REQUIREMENTS = {
  bulletPoint1: 'The statements of fact contained in this report are true and correct.',
  bulletPoint2:
    'The reported analyses, opinions, and conclusions are limited only by the reported assumptions and limiting conditions and are our personal, impartial, and unbiased professional analyses, opinions, and conclusions.',
  bulletPoint3:
    'We have no present or prospective interest in the property that is the subject of this report and no personal interest with respect to the parties involved.',
  bulletPoint4: NO_PERFORMED_SERVICES_SENTENCE,
  bulletPoint5:
    'We have no bias with respect to the property that is the subject of this report or to the parties involved with this assignment.',
  bulletPoint6:
    'Our engagement in this assignment was not contingent upon developing or reporting predetermined results.',
  bulletPoint7:
    'Our compensation for completing this assignment is not contingent upon the development or reporting of a predetermined value or direction in value that favors the cause of the client, the amount of the value opinion, the attainment of a stipulated result, or the occurrence of a subsequent event directly related to the intended use of this appraisal.',
  bulletPoint8:
    'Our analyses, opinions, and conclusions were developed, and this report has been prepared, in conformity with the requirements of the Code of Professional Ethics and Standard of Professional Practice of the Appraisal Institute, the Uniform Standards of Professional Appraisal Practice, and applicable state appraisal regulations.',
}

export const getDefaultUspapRequirements = hasPropertyBeenAppraised => {
  const defaultUspapRequirements = cloneDeep(DEFAULT_USPAP_REQUIREMENTS)
  if (hasPropertyBeenAppraised) {
    defaultUspapRequirements.bulletPoint4 = PERFORMED_SERVICES_SENTENCE
  }
  return defaultUspapRequirements
}
