import React from 'react'
import { connect } from 'react-redux'

import { get } from 'lodash'

import { showSection } from '../../../../../shared/helpers/propertyType'
import { hasPermission } from '../../../../../shared/helpers/permissions'

const ShowSection = ({ children, section, permission, propertyType, userPermissions }) => {
  if ((!section || showSection(section, propertyType)) && (!permission || hasPermission(permission, userPermissions))) {
    return <>{children}</>
  }

  return null
}

export default connect(state => {
  const propertyType = get(state, 'report.reportData.propertyType')
  const userPermissions = get(state, 'authentication.user.permissions', [])
  return {
    propertyType,
    userPermissions,
  }
})(ShowSection)
