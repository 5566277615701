// ADMIN and LEAD_APPRAISER are special, so we need to make sure they are defined even if an error occurs
// don't add constants for all roles ids, use RoleService / redux 'authentication.roles'
export const FALLBACK_ROLES = {
  isFallback: true,
  ADMIN: 0,
  LEAD_APPRAISER: 1,
  APPRAISER: 2,
}

export const AppraisalInstituteDesignationTypes = {
  NOT_SET: null,
  NONE: 'none',
  DESIGNATED_MEMBER: 'designated member',
  CANDIDATE: 'candidate',
  PRACTICING_AFFILIATE: 'practicing affiliate',
}

export const APPRAISAL_INSTITUTE_DESIGNATION_TYPES = [
  AppraisalInstituteDesignationTypes.NOT_SET,
  AppraisalInstituteDesignationTypes.NONE,
  AppraisalInstituteDesignationTypes.DESIGNATED_MEMBER,
  AppraisalInstituteDesignationTypes.CANDIDATE,
  AppraisalInstituteDesignationTypes.PRACTICING_AFFILIATE,
]

export const DEFAULT_APPRAISAL_INSTITUTE_DESIGNATION_TYPE = AppraisalInstituteDesignationTypes.NONE

export const MISSING_AVATAR_IMG_URL =
  'https://res.cloudinary.com/vikas-real-estate/image/upload/v1542396123/lxwko6u44ka6clkasmvi.png'
