import React from 'react'
import { Field } from 'react-final-form'

import { Text } from '../../_mui5'

const TextField = ({ name, ...restProps }) => (
  <Field
    name={name}
    {...restProps}
    render={({ input, meta, ...restInnerProps }) => {
      const { error, touched } = meta
      const inErrorState = touched && !!error
      return <Text {...restInnerProps} {...input} value={input.value} onChange={input.onChange} error={inErrorState} />
    }}
  />
)

export default TextField
