import { partition } from 'lodash'

import { simplePluralize as pluralize, pluralizeBeVerb } from '../../..'

const mapData = units => {
  const [vacantUnits, occupiedUnits] = partition(units, 'isVacant')

  const rentWord = pluralize('rent', units.length)
  const rentVerb = pluralizeBeVerb(units.length)
  const structureWord = pluralize('structure', units.length)

  return {
    rentWord,
    rentVerb,
    structureWord,
    occupiedOnly: occupiedUnits.length && !vacantUnits.length,
    vacantOnly: vacantUnits.length && !occupiedUnits.length,
    vacantAndOccupied: vacantUnits.length && occupiedUnits.length,
  }
}

export const mapDTO = formValues => {
  const { units } = formValues
  return mapData(units)
}

export const mapDataModel = dataModel => {
  const units = dataModel.commercial?.projectedRentRoll || []
  return mapData(units)
}
