import React from 'react'
import PropTypes from 'prop-types'

import { Divider, List as BaseList } from '@mui/material'
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd'

import ListItem from './SelectedCompListItem'

class List extends React.PureComponent {
  static propTypes = {
    properties: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string.isRequired,
        streetAddress: PropTypes.string.isRequired,
        city: PropTypes.string.isRequired,
        state: PropTypes.string.isRequired,
      })
    ).isRequired,
    onReorder: PropTypes.func.isRequired,
    onRemove: PropTypes.func.isRequired,
    onEdit: PropTypes.func.isRequired,
  }

  handleDragEnd = result => {
    if (!result.destination) {
      return
    }
    this.props.onReorder(result.source.index, result.destination.index)
  }

  handleRemove = index => () => {
    this.props.onRemove(index)
  }

  handleEdit = (index, property) => () => {
    this.props.onEdit(index, property)
  }

  render() {
    const { properties, onRemove, onReorder, onEdit, sx, ...restProps } = this.props

    if (properties.length === 0) {
      return null
    }

    return (
      <BaseList sx={sx} {...restProps}>
        <DragDropContext onDragEnd={this.handleDragEnd}>
          <Droppable droppableId="droppable">
            {droppableProvided => (
              <div ref={droppableProvided.innerRef}>
                {properties.map((property, index) => (
                  <Draggable key={property.id} draggableId={property.id} index={index}>
                    {draggableProvided => (
                      <div ref={draggableProvided.innerRef}>
                        <ListItem
                          {...property}
                          index={index}
                          onRemove={this.handleRemove(index)}
                          onEdit={this.handleEdit(index, property)}
                          {...draggableProvided.draggableProps}
                          dragHandleProps={draggableProvided.dragHandleProps}
                          style={draggableProvided.draggableProps.style}
                        />
                        <Divider />
                      </div>
                    )}
                  </Draggable>
                ))}
                {droppableProvided.placeholder}
              </div>
            )}
          </Droppable>
        </DragDropContext>
      </BaseList>
    )
  }
}

export default List
