import { handleActions } from 'redux-actions'

import { showJumpAhead, hideJumpAhead } from '../actions/jumpAhead'

const initialState = {
  isJumpAheadOpen: false,
}

export const reducer = handleActions(
  {
    [showJumpAhead](state) {
      return { ...state, isJumpAheadOpen: true }
    },
    [hideJumpAhead](state) {
      return { ...state, isJumpAheadOpen: false }
    },
  },
  initialState
)
