import { get } from 'lodash'

const formatSubmarket = submarket => {
  const stateRegex = /^[A-Z][A-Z]\s?-\s?/i
  return submarket ? submarket.replace(stateRegex, '') : ''
}

export const mapDTO = formValues => {
  const { neighborhood, submarket } = formValues
  return {
    neighborhood,
    submarket: formatSubmarket(submarket),
  }
}

export const mapDataModel = report => {
  const { marketNeighborhood, submarket } = get(report, 'property_information', {})

  return {
    neighborhood: marketNeighborhood,
    submarket: formatSubmarket(submarket),
  }
}
