import React from 'react'
import PropTypes from 'prop-types'

import ReactDropzone from 'react-dropzone'

import { Box, Stack, Typography } from '@mui/material'
import { makeStyles } from '@mui/styles'
import CloudUpload from '@mui/icons-material/CloudUpload'

const useStyles = makeStyles(({ palette }) => {
  const main = palette.primary.main
  const secondary = palette.grey.A400
  return {
    root: {
      border: `1px solid ${secondary}`,
      borderRadius: 4,
      boxSizing: 'border-box',
      color: secondary,
      height: '100%',
      padding: 8,
      transition: '500ms all',
      width: '100%',
      '&:hover': {
        border: `1px solid ${main}`,
        color: main,
        cursor: 'pointer',
      },
      '&:active': {
        border: `1px solid ${main}`,
        color: main,
      },
    },
  }
})

const ReactDropzoneStyled = ({ sx, ...props }) => {
  const classes = useStyles()
  return (
    <Box sx={{ flexShrink: 0, ...sx }}>
      <ReactDropzone className={classes.root} {...props} />
    </Box>
  )
}

const Dropzone = ({ dropZoneRef: ref, helperText, onDrop, sx, ...restProps }) => (
  <ReactDropzoneStyled {...{ onDrop, ref, sx }} {...restProps}>
    <Stack alignItems="center" height={1} justifyContent="center" spacing={1}>
      <CloudUpload fontSize="large" />
      <Typography align="center" variant="caption">
        {helperText}
      </Typography>
    </Stack>
  </ReactDropzoneStyled>
)

Dropzone.propTypes = {
  dropZoneRef: PropTypes.shape({ current: PropTypes.any }),
  helperText: PropTypes.string,
  onDrop: PropTypes.func.isRequired,
  sx: PropTypes.object,
}

Dropzone.defaultProps = {
  helperText: 'Click or drag files to upload',
  sx: { height: 120, width: 120 },
}

export default Dropzone
