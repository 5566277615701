import { useState, useEffect } from 'react'

export const SCRIPT_LOADING_STATUS = {
  IDLE: 'idle',
  READY: 'ready',
  LOADING: 'loading',
  ERROR: 'error',
}

/**
 * Insert a script tag into the body, and return its loading status.
 *
 * @param {string|boolean|null} src URL to load, false to return an error status,
 *   true to return a success status, or null to return an idle status.
 */
const useScript = src => {
  const [status, setStatus] = useState(src ? SCRIPT_LOADING_STATUS.LOADING : SCRIPT_LOADING_STATUS.IDLE)

  useEffect(() => {
    if (src === false) {
      setStatus(SCRIPT_LOADING_STATUS.ERROR)
      return
    }
    if (src === true) {
      setStatus(SCRIPT_LOADING_STATUS.READY)
      return
    }
    if (!src) {
      setStatus(SCRIPT_LOADING_STATUS.IDLE)
      return
    }

    let script = document.querySelector(`script[src="${src}"]`)
    if (!script) {
      script = document.createElement('script')
      script.src = src
      script.async = true
      script.setAttribute('data-status', SCRIPT_LOADING_STATUS.LOADING)

      document.body.appendChild(script)

      const setAttributeFromEvent = event => {
        script.setAttribute(
          'data-status',
          event.type === 'load' ? SCRIPT_LOADING_STATUS.READY : SCRIPT_LOADING_STATUS.ERROR
        )
      }

      script.addEventListener('load', setAttributeFromEvent)
      script.addEventListener('error', setAttributeFromEvent)
    } else {
      setStatus(script.getAttribute('data-status'))
    }

    const setStateFromEvent = event => {
      setStatus(event.type === 'load' ? SCRIPT_LOADING_STATUS.READY : SCRIPT_LOADING_STATUS.ERROR)
    }

    script.addEventListener('load', setStateFromEvent)
    script.addEventListener('error', setStateFromEvent)

    return () => {
      if (script) {
        script.removeEventListener('load', setStateFromEvent)
        script.removeEventListener('error', setStateFromEvent)
      }
    }
  }, [src])

  return status
}

export default useScript
