import { hasAdjustments } from 'shared/helpers/salesApproach/salesCompAdjustments'

import { ADJUSTMENT_GROUPS } from 'shared/helpers/salesApproach/salesCompAdjustmentConstants'

import { getLastWordSeparator } from 'shared/utils/sentenceBuilder'
import { getCompsFromFormValues, generateCompsIndicesSentence } from 'shared/utils/textGeneration/salesApproach'

export const getOtherGeneratedText = (values, name) => {
  const { upwardComps, downwardComps } = getCompsFromFormValues(values, name, ADJUSTMENT_GROUPS.OTHER)
  const generateWriteup = hasAdjustments(values.compAdjustments, name, ADJUSTMENT_GROUPS.OTHER)
  let writeUp = ''
  const getCommentary = (type, currComps) => {
    const getArticle = () => (type === 'upward' ? ' an' : ' a')
    writeUp += ` Comparable${currComps.length > 1 ? 's' : ''} ${generateCompsIndicesSentence(
      currComps,
      getLastWordSeparator
    )} required${currComps.length > 1 ? '' : getArticle()} ${type} adjustment${currComps.length > 1 ? 's' : ''}.`
  }
  if (generateWriteup) {
    upwardComps.length && getCommentary('upward', upwardComps)
    downwardComps.length && getCommentary('downward', downwardComps)
    return writeUp
  }
  return writeUp
}
