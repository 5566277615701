import { defineAction } from 'redux-define'

import { DEFAULT_API_ACTION_STATES } from '../../shared/constants'

export const AUTH_LOGIN = defineAction('AUTH_LOGIN', DEFAULT_API_ACTION_STATES)
export const FETCH_USER = defineAction('FETCH_USER', DEFAULT_API_ACTION_STATES)
export const AUTH_CLEAR = defineAction('AUTH_CLEAR', DEFAULT_API_ACTION_STATES)
export const LOGOUT_USER = defineAction('LOGOUT_USER', DEFAULT_API_ACTION_STATES)
export const LOGIN_USER = defineAction('LOGIN_USER', DEFAULT_API_ACTION_STATES)
export const LOGOUT_USER_WITH_CONFIRMATION = defineAction('LOGOUT_USER_WITH_CONFIRMATION')
