import React from 'react'
import { Typography } from '@mui/material'
import { OverridableComponent } from '@mui/material/OverridableComponent'
import { SvgIconTypeMap } from '@mui/material/SvgIcon'
import { grey } from '@mui/material/colors'

interface ContentHeaderComponentProps {
  title: string
  icon: OverridableComponent<SvgIconTypeMap>
}

const ContentHeaderComponent: React.FC<ContentHeaderComponentProps> = ({ title, icon: Icon }) => {
  return (
    <Typography
      color="grey.600"
      variant="caption"
      pl={3}
      pt={1}
      mb={1}
      sx={{ display: 'flex', alignItems: 'center', fontWeight: 700 }}
    >
      <Icon sx={{ marginRight: '8px', color: grey[600] }} />
      {title}
    </Typography>
  )
}

export default ContentHeaderComponent
