import { GeneratedText, gtLocked } from '../../../narrative/GeneratedText'

import { mapDTO, mapDataModel } from './mappings'

const generate = () => {
  const gt = new GeneratedText()

  const surroundingUsesGt = gtLocked('surroundingUsesString', null, 'Surrounding Uses')

  gt.t`It is located on a primarily ${surroundingUsesGt} block.`
  return gt.getParts()
}

export default { generate, mapDTO, mapDataModel }
