import React from 'react'

import AutoModeIcon from '@mui/icons-material/AutoModeRounded'
import { Stack, Tooltip } from '@mui/material'

const AutoCompIcon = ({ comp, tooltipMessage, ...otherProps }) => {
  const sourceIcon = comp.isAutoComp ? (
    <Tooltip placement="top" title={tooltipMessage}>
      <Stack color="#673AB7" {...otherProps}>
        <AutoModeIcon />
      </Stack>
    </Tooltip>
  ) : null

  return sourceIcon
}

export default AutoCompIcon
