import React from 'react'
import PropTypes from 'prop-types'
import { clamp, isNumber } from 'lodash'
import { Line } from 'rc-progress'
import Typography from '@mui/material/Typography'
import { blue, teal, green } from '@mui/material/colors'

const colorMap = [blue[300], teal[300], green[300]]

const DEFAULT_WIDTH = 12

export default class ProgressBar extends React.PureComponent {
  static propTypes = {
    percent: PropTypes.number.isRequired,
    strokeWidth: PropTypes.number,
    trailWidth: PropTypes.number,
    isStaticColor: PropTypes.bool,
    showPercent: PropTypes.bool,
  }

  static defaultProps = {
    strokeWidth: DEFAULT_WIDTH,
    trailWidth: DEFAULT_WIDTH,
    isStaticColor: true,
    showPercent: false,
  }

  render() {
    const { percent, showPercent, isStaticColor, ...props } = this.props

    const colorIndex = clamp(Math.floor((percent * colorMap.length) / 100), 0, colorMap.length - 1)

    return (
      <div>
        {this.props.showPercent && (
          <Typography align="right" type="body2">{`${isNumber(percent) ? percent : 0}%`}</Typography>
        )}
        <Line
          percent={percent}
          strokeWidth={this.props.strokeWidth}
          trailWidth={this.props.trailWidth}
          strokeColor={this.props.isStaticColor ? green[500] : colorMap[colorIndex]}
          {...props}
        />
      </div>
    )
  }
}
