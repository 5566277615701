export const REFINEMENT_IDS = {
  hvacClimate: 'hvacClimate',
  elevator: 'elevator',
  sprinklers: 'sprinklers',
}
export const MULTIPLIER_IDS = {
  stories: 'stories',
  height: 'height',
  floorArea: 'floorArea',
  currentCost: 'currentCost',
  local: 'local',
  complexSites: 'complexSites',
}

export const BUILDING_INFO_IDS = {
  grade: 'grade',
  buildingType: 'buildingType',
  buildingClass: 'buildingClass',
  classType: 'classType',
  finished: 'finished',
  mvsSection: 'mvsSection',
  mvsPage: 'mvsPage',
  manualDated: 'manualDated',
}

export const DESCRIPTION_ID = 'description'
export const BASE_UNIT_COST_ID = 'baseUnitCostNew'
export const ADJUSTED_BASE_COST_ID = 'adjustedBaseCost'
export const TOTAL_MULTIPLIER_ID = 'totalMultiplier'
export const ADJUSTED_REPLACEMENT_COST_ID = 'adjustedReplacementCostNew'
export const AREA_ID = 'area'
export const REPLACEMENT_COST_NEW_ID = 'replacementCostNew'

export const EXCLUSION_TABLE_IDS = {
  customExclusion: 'customExclusions',
  totalExclusionPercentageInput: 'totalExclusionPercentageInput',
  totalExclusionsValue: 'totalExclusionsValue',
  indicatedInsurableReplacementCost: 'indicatedInsurableReplacementCost',
  roundedIndicatedInsurableReplacementCost: 'roundedIndicatedInsurableReplacementCost',
}

export const LABELS = {
  [REFINEMENT_IDS.hvacClimate]: 'Add HVAC / Climate Adjustment',
  [REFINEMENT_IDS.elevator]: 'Elevator (Addition or Deduction)',
  [REFINEMENT_IDS.sprinklers]: 'Add Sprinklers',
  [MULTIPLIER_IDS.stories]: 'Number of Stories Multiplier',
  [MULTIPLIER_IDS.height]: 'Height/Story Multiplier',
  [MULTIPLIER_IDS.floorArea]: 'Floor Area/Perimeter Multiplier',
  [MULTIPLIER_IDS.currentCost]: 'Current Cost Multiplier',
  [MULTIPLIER_IDS.local]: 'Local Multiplier',
  [MULTIPLIER_IDS.complexSites]: 'Complex/Congested Sites Multiplier',
  [DESCRIPTION_ID]: 'Description',
  [BUILDING_INFO_IDS.grade]: 'Grade',
  [BUILDING_INFO_IDS.buildingType]: 'Building Type',
  [BUILDING_INFO_IDS.buildingClass]: 'Class',
  [BUILDING_INFO_IDS.classType]: 'Class Type',
  [BUILDING_INFO_IDS.finished]: 'Finished / Unfinished',
  [BUILDING_INFO_IDS.mvsSection]: 'MVS Section',
  [BUILDING_INFO_IDS.mvsPage]: 'MVS Page',
  [BUILDING_INFO_IDS.manualDated]: 'Manual Dated',
  [BASE_UNIT_COST_ID]: 'Base Unit Cost New PSF',
  [AREA_ID]: 'Area',
  [ADJUSTED_BASE_COST_ID]: 'Adjusted Base Unit Cost New PSF',
  [TOTAL_MULTIPLIER_ID]: 'Total Multiplier',
  [ADJUSTED_REPLACEMENT_COST_ID]: 'Adjusted Replacement Cost New PSF',
  [REPLACEMENT_COST_NEW_ID]: 'Replacement Cost New',
  [EXCLUSION_TABLE_IDS.totalExclusionPercentageInput]: 'Total Exclusions (%)',
  [EXCLUSION_TABLE_IDS.totalExclusionsValue]: 'Total Exclusions ($)',
  [EXCLUSION_TABLE_IDS.indicatedInsurableReplacementCost]: 'Indicated Insurable Replacement Cost',
  [EXCLUSION_TABLE_IDS.roundedIndicatedInsurableReplacementCost]: 'Rounded Indicated Insurable Replacement Cost',
}
