import Handsontable from 'handsontable'
import maskInput from 'vanilla-text-mask'
import { isEmpty } from 'lodash'

import BoweryDate from '@bowery-valuation/bowery-date'

const inputMask = rawValue => {
  const singleDigitRegex = /\d/
  const rawValueIsDigit = singleDigitRegex.test(rawValue)
  const mmddyyyyDateMask = [/\d/, /\d/, '/', /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/]
  const anythingButDigitMask = new Array(35).fill(/\D/)
  return rawValueIsDigit ? mmddyyyyDateMask : anythingButDigitMask
}

class ExpirationDateEditor extends Handsontable.editors.TextEditor {
  createElements() {
    super.createElements()

    this.TEXTAREA = this.hot.rootDocument.createElement('input')
    this.TEXTAREA.setAttribute('type', 'text')
    this.TEXTAREA.className = 'handsontableInput'
    this.TEXTAREA.setAttribute('data-hot-input', true)
    this.textareaStyle = this.TEXTAREA.style
    this.textareaStyle.width = 0
    this.textareaStyle.height = 0
    maskInput({
      inputElement: this.TEXTAREA,
      mask: inputMask,
      guide: false,
    })

    Handsontable.dom.empty(this.TEXTAREA_PARENT)
    this.TEXTAREA_PARENT.appendChild(this.TEXTAREA)
  }

  focus() {
    this.TEXTAREA.select()
  }

  getValue() {
    const value = this.TEXTAREA.value

    if (isEmpty(value)) {
      return null
    }

    const startsWithDigit = /\d/.test(value[0])
    if (!startsWithDigit) {
      return value
    }

    const date = new BoweryDate(value)
    if (date.isValidDate()) {
      return date.toString()
    }

    return null
  }

  setValue(newValue) {
    if (newValue) {
      const date = new BoweryDate(newValue)
      this.TEXTAREA.value = date.isValidDate() ? date.formatShortDateWithZeroes() : newValue
    } else {
      this.TEXTAREA.value = ''
    }
  }
}

const expirationDateRenderer = (instance, td, row, col, prop, value, cellProperties) => {
  Handsontable.renderers.TextRenderer.call(this, instance, td, row, col, prop, value, cellProperties)
  td.setAttribute('data-qa', `${prop}-${row}-cell`)

  let renderValue

  if (isEmpty(value)) {
    renderValue = ''
  } else {
    const date = new BoweryDate(value)
    renderValue = date.isValidDate() ? date.formatShortDateWithZeroes() : value
  }

  td.innerHTML = renderValue
  return td
}

const readOnlyRenderer = (instance, td, row, col, prop, value, cellProperties) => {
  Handsontable.cellTypes
    .getCellType('bowery.expirationDate')
    .renderer.call(this, instance, td, row, col, prop, value, cellProperties)
  td.innerHTML = td.innerHTML || '-'
  return td
}

export const registerExpirationDate = () =>
  Handsontable.cellTypes.registerCellType('bowery.expirationDate', {
    editor: ExpirationDateEditor,
    renderer: expirationDateRenderer,
    readOnlyRenderer: readOnlyRenderer,
  })
