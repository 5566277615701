import { OTHER } from '../../../../client/src/report/constants'

import { FLOOR_MATERIALS } from './materials'

export const LIVING_ROOM_FLOORS = [
  FLOOR_MATERIALS.HARDWOOD,
  FLOOR_MATERIALS.LAMINATE_HARDWOOD,
  FLOOR_MATERIALS.PREMIUM_HARDWOOD,
  FLOOR_MATERIALS.CARPET,
  FLOOR_MATERIALS.VINYL_TILED,
  FLOOR_MATERIALS.VINYL_PLANK,
  FLOOR_MATERIALS.CERAMIC_TILED,
  FLOOR_MATERIALS.NATURAL_STONE,
  OTHER,
]

export const BEDROOM_FLOORS = [...LIVING_ROOM_FLOORS]

export const WALLS = ['Sheet Rock', 'Plaster', 'Exposed Brick', 'Painted Brick', 'Wallpaper-covered Sheet Rock', OTHER]
