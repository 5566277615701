import { get } from 'lodash'

import { generateCompsIndicesSentence, getComps } from 'shared/utils/textGeneration/salesApproach'
import { ADJUSTMENT_GROUPS } from 'shared/helpers/salesApproach/salesCompAdjustmentConstants'
import { hasAdjustments } from 'shared/helpers/salesApproach/salesCompAdjustments'
import { getLastWordSeparator } from 'shared/utils/sentenceBuilder'
import { getPropertyConditionSentencePart } from 'shared/utils/textGeneration/property/description'

const mapData = (selectedComps, compAdjustments, yearBuilt, condition, asStabilizedCondition, valueAsComplete) => {
  const conditionSentencePart = getPropertyConditionSentencePart(condition)
  const asStabilizedConditionSentencePart = getPropertyConditionSentencePart(asStabilizedCondition)

  const shouldGenerateWriteup = hasAdjustments(compAdjustments, 'condition', ADJUSTMENT_GROUPS.OTHER)

  const { upwardComps, downwardComps } = getComps(compAdjustments, selectedComps, 'condition', ADJUSTMENT_GROUPS.OTHER)

  return {
    yearBuilt,
    conditionSentencePart,
    asStabilizedConditionSentencePart,
    valueAsComplete,
    hasAdjustments: shouldGenerateWriteup,
    hasUpwardAdjustments: upwardComps.length > 0,
    hasMultipleUpwardAdjustments: upwardComps.length > 1,
    hasDownwardAdjustments: downwardComps.length > 0,
    hasMultipleDownwardAdjustments: downwardComps.length > 1,
    downwardCompsSentence: generateCompsIndicesSentence(downwardComps, getLastWordSeparator),
    upwardCompsSentence: generateCompsIndicesSentence(upwardComps, getLastWordSeparator),
  }
}

export const mapDTO = formValues => {
  const {
    selectedComps,
    compAdjustments = [],
    yearBuilt,
    condition,
    asStabilizedCondition,
    valueAsComplete,
  } = formValues

  return mapData(selectedComps, compAdjustments, yearBuilt, condition, asStabilizedCondition, valueAsComplete)
}

export const mapDataModel = (report, parent) => {
  if (!parent.parent()) {
    return undefined
  }

  const salesApproach = parent.parent()
  const { salesComps = {}, salesCompsAdjustments = {} } = salesApproach
  const { selectedComps = [] } = salesComps
  const { compAdjustments = [] } = salesCompsAdjustments

  const propertyInformation = get(report, 'property_information', {})
  const { yearBuilt } = propertyInformation

  const buildingDescription = get(report, 'buildingDescription', {})
  const condition = buildingDescription.generalCondition
  const asStabilizedCondition = buildingDescription.generalAsStabilizedCondition
  return mapData(selectedComps, compAdjustments, yearBuilt, condition, asStabilizedCondition, report.valueAsComplete)
}
