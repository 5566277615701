import React from 'react'
import PropTypes from 'prop-types'
import { get, mean } from 'lodash'

import {
  getAdjustedRentPSF,
  getLeaseTermsSubTotalPSF,
  getDateSignedTrendedPricePSF,
  getTotalAdjustment,
} from '../../../../../shared/utils/calculations'
import { getRentPerSF } from '../../../../../../../shared/calculations/commercial'

import CommercialRentReconciliationItem from './CommercialRentReconciliationItem'

class CommercialRentReconciliationContainer extends React.PureComponent {
  static propTypes = {
    index: PropTypes.number.isRequired,
    reconciliationGroupData: PropTypes.object.isRequired,
    rentRollRentBasis: PropTypes.string.isRequired,
  }

  isGroupValid = reconciliationGroupData => {
    const baseUnit = get(reconciliationGroupData, 'summary.baseUnit')
    const rentComps = get(reconciliationGroupData, 'summary.rentComps')

    return baseUnit && rentComps && rentComps.length > 0
  }

  mapRentCompsData = reconciliationGroupData => {
    const { rentRollRentBasis } = this.props
    const reconciliationRentComps = get(reconciliationGroupData, 'summary.rentComps') || []
    const leaseTermsCalcType = get(reconciliationGroupData, 'summary.leaseTermsCalcType') || '%'
    return reconciliationRentComps.map(rentComp => {
      const psfRent = getRentPerSF(rentComp, rentRollRentBasis)
      const leaseTermsAdjustmentSubTotal = getLeaseTermsSubTotalPSF(rentComp, psfRent, leaseTermsCalcType)
      const dateSignedTrendedPricePerSF = getDateSignedTrendedPricePSF(rentComp, psfRent, leaseTermsCalcType)

      const totalAdjustment = getTotalAdjustment(rentComp)

      const adjustedRentPerSF = getAdjustedRentPSF(rentComp, psfRent, leaseTermsCalcType)

      return {
        ...rentComp,
        psfRent,
        leaseTermsAdjustmentSubTotal,
        dateSignedTrendedPricePerSF,
        totalAdjustment,
        adjustedRentPerSF,
      }
    })
  }

  getAverageAdjustedRentPSF = (rentCompsData, commercialUnits) => {
    const allAdjustedRents = [...rentCompsData, ...commercialUnits]
    return mean(allAdjustedRents.map(adjustedRent => adjustedRent.adjustedRentPerSF))
  }

  mapUnitsData = reconciliationGroupData => {
    const reconciliationCommercialUnits = get(reconciliationGroupData, 'summary.commercialUnits') || []
    const leaseTermsCalcType = get(reconciliationGroupData, 'summary.leaseTermsCalcType') || '%'

    return reconciliationCommercialUnits.map(unit => {
      const { _id, rentPsf, squareFeet, tenantName } = unit
      const { frontage, ceilingHeight, location } = unit.description
      const { dateSigned, leaseTerms } = unit.lease

      const totalAdjustment = getTotalAdjustment(unit)

      const leaseTermsAdjustmentSubTotal = getLeaseTermsSubTotalPSF(unit, rentPsf, leaseTermsCalcType)
      const dateSignedTrendedPricePerSF = getDateSignedTrendedPricePSF(unit, rentPsf, leaseTermsCalcType)

      const adjustedRentPerSF = getAdjustedRentPSF(totalAdjustment, dateSignedTrendedPricePerSF, leaseTermsCalcType)

      return {
        _id,
        squareFeet,
        tenantName,
        frontage,
        ceilingHeight,
        location,
        leaseTermsAdjustmentSubTotal,
        dateSignedTrendedPricePerSF,
        dateSigned,
        leaseTerms,
        psfRent: rentPsf,
        totalAdjustment,
        adjustedRentPerSF,
      }
    })
  }

  render() {
    const { index, reconciliationGroupData, rentRollRentBasis } = this.props

    const isGroupValid = this.isGroupValid(reconciliationGroupData)

    const includeInExport = get(reconciliationGroupData, 'summary.includeInExport', true)
    const baseUnit = get(reconciliationGroupData, 'summary.baseUnit')

    const baseUnitTenantName = get(baseUnit, 'tenantName')
    const baseUnitSquareFeet = get(baseUnit, 'squareFeet', 1)

    const baseUnitPerSFRent = get(baseUnit, 'rentPsf')

    const baseUnitDateSigned = get(baseUnit, 'lease.signedAt')
    const baseUnitLeaseTerms = get(baseUnit, 'lease.terms')
    const baseUnitFrontage = get(baseUnit, 'description.frontage')
    const baseUnitCeilingHeight = get(baseUnit, 'description.ceilingHeight')
    const baseUnitLocation = get(baseUnit, 'description.location')

    const rentCompsData = this.mapRentCompsData(reconciliationGroupData)

    const unitsData = this.mapUnitsData(reconciliationGroupData)

    const averageAdjustmentRentPSF = this.getAverageAdjustedRentPSF(rentCompsData, unitsData)

    const fieldPrefix = `reconciliationGroups.items[${index}].summary`

    return (
      <CommercialRentReconciliationItem
        fieldPrefix={fieldPrefix}
        reconciliationGroupData={reconciliationGroupData}
        baseUnitTenantName={baseUnitTenantName}
        baseUnitSquareFeet={baseUnitSquareFeet}
        baseUnitPerSFRent={baseUnitPerSFRent}
        baseUnitDateSigned={baseUnitDateSigned}
        baseUnitLeaseTerms={baseUnitLeaseTerms}
        baseUnitFrontage={baseUnitFrontage}
        baseUnitCeilingHeight={baseUnitCeilingHeight}
        baseUnitLocation={baseUnitLocation}
        units={unitsData}
        rentComps={rentCompsData}
        averageAdjustmentRentPSF={averageAdjustmentRentPSF}
        includeInExport={includeInExport}
        isGroupValid={isGroupValid}
        rentRollRentBasis={rentRollRentBasis}
      />
    )
  }
}

export default CommercialRentReconciliationContainer
