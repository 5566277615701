import React from 'react'
import PropTypes from 'prop-types'
import { TableRow } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'

import { LeaseTermsOptions } from 'shared/constants/report/incomeApproach/commercial'

import { Number, DropDown } from '../../../../../../shared/components'

import { Labels } from '../CommercialRentReconciliationConstants'
import { Cell, HeadingCell } from '../TableCells'

import { required } from '../../../../../../shared/utils/validation'

const styles = {
  input: {
    maxWidth: 200,
  },
}
class MarketRentConclusion extends React.PureComponent {
  static propTypes = {
    fieldPrefix: PropTypes.string.isRequired,
    units: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
    rentCompsCount: PropTypes.number.isRequired,
  }

  render() {
    const { fieldPrefix, units, rentCompsCount, classes } = this.props

    return (
      <React.Fragment>
        <TableRow>
          <HeadingCell colSpan={units.length + rentCompsCount + 2}>{Labels.MARKET_RENT_CONCLUSION}</HeadingCell>
        </TableRow>
        <TableRow data-qa="market-rent-conclusion-row">
          <Cell data-qa="row-label-cell">{Labels.MARKET_RENT_CONCLUSION}</Cell>
          <Cell data-qa="market-rent-conclusion-cell" colSpan={units.length + rentCompsCount + 1}>
            <Number
              name={`${fieldPrefix}.marketRentConclusion`}
              decimalScale={2}
              prefix="$"
              allowNegative={false}
              thousandSeparator
              validate={required}
              className={classes.input}
            />
          </Cell>
        </TableRow>
        <TableRow data-qa="lease-terms-row">
          <Cell data-qa="lease-terms-label-cell">{Labels.LEASE_TERMS}</Cell>
          <Cell data-qa="lease-terms-input-cell" colSpan={units.length + rentCompsCount + 1}>
            <DropDown name={`${fieldPrefix}.assumedLeaseTerms`} items={LeaseTermsOptions} className={classes.input} />
          </Cell>
        </TableRow>
      </React.Fragment>
    )
  }
}

export default withStyles(styles)(MarketRentConclusion)
