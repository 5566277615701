import React from 'react'

import { Box, Typography } from '@mui/material'
import { Text } from 'client-shared/components'
import { makeStyles } from '@mui/styles'

import styles from '../styles'

// @ts-ignore makeStyles doesn't like the strings - it wants enums
const useStyles = makeStyles(styles)

const EditTableTitle = ({ formPath }: { formPath?: string }) => {
  const classes = useStyles()

  return (
    <Box className={classes.createField}>
      <Typography variant="subtitle1">Table Name</Typography>
      <Text placeholder="Enter Table Name (optional)" name={`${formPath}.tableTitle`} />
      <Typography className={classes.tableTitle}>In this table you can:</Typography>
      <Typography className={classes.tableTitle}>
        <b>Create: Copy</b> (Ctrl+C) and <b>paste</b> (Ctrl+V) values from other tables or spreadsheets (pastes
        unformatted) or <b>enter directly into cells</b> below.
      </Typography>
      <Typography className={classes.tableTitle}>
        <b>Format:</b> Hover over table below and select any dropdown arrow to:
      </Typography>
      <ul className={classes.tableTitleList}>
        <Typography>
          <li>
            Change <b>column text alignment</b> and <b>row styling</b>
          </li>
          <li>
            <b>Add</b> or <b>Delete</b> columns and rows
          </li>
        </Typography>
      </ul>
    </Box>
  )
}

export default EditTableTitle
