import { handleActions } from 'redux-actions'

import { setNavigationScrolling } from '../actions/scroll'

const initialState = {
  navigationScrolling: null,
}

export const reducer = handleActions(
  {
    [setNavigationScrolling.toString()](state, { payload }) {
      return {
        ...initialState,
        ...payload,
      }
    },
  },
  initialState
)
