import React from 'react'
import { Grid, Paper, Fab, Typography } from '@mui/material'
import Person from '@mui/icons-material/Person'
import Save from '@mui/icons-material/Save'

import { Form } from 'react-final-form'

import { connect } from 'react-redux'

import { get } from 'lodash'

import { Text } from '../../../shared/components'

import FormHeader from '../../../shared/components/FormHeader'

import { userUpdate as userUpdateAction } from '../../redux/actions/user'
import { SSO_PASSWORD_PLACEHOLDER } from '../../../../../shared/constants/admin'

const FORM_NAME = 'changePassword'
const heading = 'Change Password'

const ChangePasswords = ({ userUpdate, section, authentication }) => {
  const userPassword = get(authentication, 'user.password', '')
  const handleSubmit = values => {
    userUpdate({ formDataPath: FORM_NAME, values })
  }

  return (
    <Grid container spacing={0.5}>
      <Grid item xs={12}>
        <FormHeader title={heading} icon={<Person />} section={section} />
      </Grid>
      <Grid item xs={6}>
        <Paper>
          <Typography variant="h6" mb={2}>
            Password Form
          </Typography>
          <Form onSubmit={handleSubmit}>
            {props => (
              <form onSubmit={props.handleSubmit}>
                {userPassword !== SSO_PASSWORD_PLACEHOLDER && (
                  <Text name="oldPassword" label="Old Password" margin="normal" fullWidth type="password" />
                )}
                <Text name="newPasswordOne" label="New Password" margin="normal" fullWidth type="password" />
                <Text
                  name="newPasswordTwo"
                  label="New Password Confirmation"
                  margin="normal"
                  fullWidth
                  type="password"
                />
                <Fab type="submit" color="success" sx={{ position: 'fixed', bottom: 40, right: 40 }}>
                  <Save sx={{ color: 'white' }} />
                </Fab>
              </form>
            )}
          </Form>
        </Paper>
      </Grid>
    </Grid>
  )
}

export default connect(state => state, { userUpdate: userUpdateAction })(ChangePasswords)
