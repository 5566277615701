import { handleActions } from 'redux-actions'

import { setPulledFloodData, clearPulledFloodData } from '../actions/pulledFloodData'

const initialState = {
  reportId: null,
  floodData: null,
  floodMap: null,
}

export const reducer = handleActions(
  {
    [setPulledFloodData](state, { payload }) {
      return {
        ...payload,
      }
    },
    [clearPulledFloodData](state) {
      return {
        ...initialState,
      }
    },
  },
  initialState
)
