import { get, toLower } from 'lodash'

import { PropertyTypes } from '../../../../constants'
import { ELEVATOR, WALK_UP } from '../../../../constants/salesApproach'
import { formatPropertySubtype } from '../../../../helpers/propertyType'
import { simplePluralize } from '../..'
import { DEFAULT_SF_ANALYSIS_BASIS, SF_BASIS_OPTIONS } from '../../../../constants/report'

export const mapValues = (values, valueAsComplete) => {
  const propertyType = get(values, 'propertyType', get(values, 'type', PropertyTypes.MIXED_USE))
  const propertySubtype = get(values, 'propertySubtype')
  const formattedPropertySubtype = toLower(formatPropertySubtype(propertySubtype))

  let buildingType = get(values, 'buildingType', null)
  if (buildingType === null && 'elevator' in values) {
    buildingType = values.elevator ? ELEVATOR : WALK_UP
  }

  const commercialUnitCount = get(values, 'commercialUnitCount', get(values, 'comUnits', 0))
  const residentialUnitCount = get(values, 'residentialUnitCount', get(values, 'resUnits', 0))

  const result = {
    commercialUnitCount,
    residentialUnitCount,
    commercialUnitWord: simplePluralize('unit', commercialUnitCount),
    residentialUnitWord: simplePluralize('unit', residentialUnitCount),
    propertyType: formattedPropertySubtype || propertyType,
    isPropertyMixedUse: propertyType === PropertyTypes.MIXED_USE,
    isMultiFamilyUse: propertyType === PropertyTypes.MULTI_FAMILY,
    isCommercialUse: propertyType === PropertyTypes.COMMERCIAL,
    floors: values.floors,
    showBuildingType: values.floors !== 1 && propertyType !== PropertyTypes.COMMERCIAL,
    buildingType,
    grossBuildingArea: values.grossBuildingArea,
    basisForSFAnalysis: toLower(SF_BASIS_OPTIONS[values.basisForSFAnalysis]),
    basisForSFAnalysisValue: values.basisForSFAnalysisValue,
    nonDefaultBasisSelected: values.basisForSFAnalysis !== DEFAULT_SF_ANALYSIS_BASIS,
  }

  if (valueAsComplete !== null) {
    result.valueAsComplete = valueAsComplete
    result.valueAsIs = !valueAsComplete
  }

  return result
}

export const mapDTO = formValues => {
  return mapValues(formValues, formValues.valueAsComplete)
}

export const mapDataModel = report => {
  return mapValues(get(report, 'property_information', {}), report.valueAsComplete)
}
