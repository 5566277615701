/* eslint-disable max-len */
import { GeneratedText, gtIfElse, gtLocked, gtTemplate } from '../../../../narrative/GeneratedText'

import { mapDTO, mapDataModel } from '../mappings'

const generate = () => {
  const gt = new GeneratedText()
  const neighborhoodGt = gtLocked('neighborhood', 'formatStartCase')
  const externalObsolescenceGt = gtLocked('externalObsolescence', 'formatPercentageString')

  gt.t`${gtIfElse(
    'includeDepreciation',
    gtTemplate`Based on a review of the location of the subject as well as local market conditions, external obsolescence is estimated at ${externalObsolescenceGt}.`,
    gtTemplate`The subject property has a good location within ${neighborhoodGt} in proximity to supporting residential services and employment centers and nearby access to larger metro centers. No elements of external obsolescence were observed.`
  )}`

  return gt.getParts()
}

export default { generate, mapDTO, mapDataModel }
