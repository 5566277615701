import React from 'react'

const defaultGetInputValue = value => value?.label || value

const useFieldChange = ({ getInputValue, input, onChange, onInputChange }) => {
  const { name, onChange: onChangeField, onFocus, onBlur, value } = input
  const handleEvent = React.useMemo(
    () => (onChangeField ? (...args) => onChange(args, onChangeField) : onChange),
    [onChange, onChangeField]
  )
  const handleInputEvent = React.useMemo(
    () => (onChangeField ? event => onInputChange(event, onChangeField) : onInputChange),
    [onChangeField, onInputChange]
  )
  const transformInputValue = React.useMemo(
    () => (getInputValue ? getInputValue : defaultGetInputValue),
    [getInputValue]
  )

  return {
    inputControl: {
      name,
      onChange: handleInputEvent,
      onFocus,
      onBlur,
      value,
    },
    selectControl: {
      inputValue: transformInputValue(value),
      onChange: handleEvent,
    },
  }
}

export { useFieldChange }
