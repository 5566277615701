import React from 'react'

import Typography from '@mui/material/Typography'

export const PageHeader = ({ children, title }) => (
  <Typography fontWeight={500} variant="h6">
    {title || children}
  </Typography>
)

export const TitleSectionHeader = ({ children, title }) => (
  <Typography color="text.secondary" variant="h5">
    {title || children}
  </Typography>
)
