import React from 'react'

import { Autocomplete, Paper } from '@mui/material'

import { Text } from '../Input'

import { useFieldChange } from './hooks'

const CustomPaper = props => {
  return <Paper {...props} sx={{ padding: 0 }} />
}

const Default = ({
  custom,
  getInputValue,
  hint,
  input,
  label,
  meta,
  onChange,
  onInputChange,
  placeholder,
  ...props
}) => {
  const { selectControl, inputControl } = useFieldChange({ getInputValue, input, onChange, onInputChange })

  return (
    <Autocomplete
      PaperComponent={CustomPaper}
      {...props}
      {...selectControl}
      renderInput={params => (
        <Text format={null} {...{ ...params, custom, hint, input: inputControl, label, meta, placeholder }} />
      )}
    />
  )
}

export default Default
