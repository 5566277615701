import React from 'react'
import { noop } from 'lodash'
import { connect } from 'react-redux'

import { errorNotification } from '../../shared/redux/actions/notifications'

export const ErrorNotificationContext = React.createContext(noop)

const withErrorNotificationContext = Component => {
  class ErrorNotificationContextProvider extends React.PureComponent {
    render() {
      const { reportError } = this.props

      return (
        <ErrorNotificationContext.Provider value={reportError}>
          <Component {...this.props} />
        </ErrorNotificationContext.Provider>
      )
    }
  }

  return connect(null, dispatch => ({
    reportError: error => dispatch(errorNotification({ message: error.message || error })),
  }))(ErrorNotificationContextProvider)
}

export default withErrorNotificationContext
