import React from 'react'
import { some } from 'lodash'
import PropTypes from 'prop-types'
import { compose } from 'recompose'
import { connect } from 'react-redux'
import { Field } from 'react-final-form'
import { Grid, Typography } from '@mui/material'
import { Paper, withStyles } from '@material-ui/core'

import historicDistrictsAndLandmarkBuildingsExport from 'shared/utils/textGeneration/property/historicDistrictsAndLandmarkBuildingsExport'
import complyingBulkDiscussion from 'shared/utils/textGeneration/property/complyingBulkDiscussion'
import reconstructionClauseDiscussion from 'shared/utils/textGeneration/property/reconstructionClauseDiscussion'
import AutomationStatus from 'client-shared/components/AutomationStatus'
import { withAutomatedZoningIsEnabledForReport } from 'report/forms/property/Zoning/Automation'

import { Callout, BooleanRadioButtonList, NarrativeComponent } from '../../../../shared/components'

import { complyingBulkSelector } from './selectors'
import ComplyingBulkInputsNYC from './ComplyingBulkInputsNYC'
import {
  TEXT_GENERATION_TOOLTIP,
  NJ_STATE_AUTHORITY_LINK,
  STATE_AUTHORITY,
  COMPLIANCE_CONFORMITY_OPTIONS,
  RECONSTRUCTION_CLAUSE_INFO,
  RECONSTRUCTION_CLAUSE_TOOLTIP,
  RECONSTRUCTION_CLAUSE_CALLOUT,
  HISTORIC_DISTRICTS_AND_LANDMARK_BUILDING_TEXT_GENERATION_TOOLTIP,
  DISTRICTS_AND_LANDMARKS_TEXT,
  NON_COMPLYING_STATUS,
} from './constants'

import { RegulationsTable } from './RegulationsTable'
import ComplyingBulkInputsOther from './ComplyingBulkInputsOther'

const styles = theme => ({
  paper: theme.paper,
  sectionTitle: {
    letterSpacing: 0.2,
    fontSize: 16,
  },
  callout: {
    backgroundColor: theme.palette.primary[50],
    margin: 0,
  },
  link: {
    color: theme.palette.primary.main,
  },
  reconstructionClauseCallout: {
    marginTop: 0,
  },
})

class ComplyingBulk extends React.PureComponent {
  static propTypes = {
    form: PropTypes.object.isRequired,
    classes: PropTypes.object.isRequired,
    isNYCReport: PropTypes.bool.isRequired,
    isAsComplete: PropTypes.bool.isRequired,
    grossBuildingArea: PropTypes.number.isRequired,
    siteArea: PropTypes.number.isRequired,
    mechanicalDeductions: PropTypes.number.isRequired,
    floorAreaRatio: PropTypes.string.isRequired,
    zoningFloorArea: PropTypes.number.isRequired,
    maxBuildableArea: PropTypes.number.isRequired,
    isComplyingWithRegardToBulk: PropTypes.bool.isRequired,
    historicDistrict: PropTypes.string,
    landmark: PropTypes.string,
    fullAddress: PropTypes.string,
    automatedZoningEnabled: PropTypes.bool.isRequired,
  }

  renderReconstructionClauseDiscussion = () => {
    const { isNYCReport } = this.props

    return (
      <Grid item xs={12}>
        {!isNYCReport && <Callout content={RECONSTRUCTION_CLAUSE_CALLOUT} sx={{ mb: 1 }} />}
        <NarrativeComponent
          title="Reconstruction Clause Discussion"
          generatedText={reconstructionClauseDiscussion.generate}
          data={reconstructionClauseDiscussion.mapDTO({ isNYCReport })}
          name="reconstructionClauseDiscussion"
          tooltipText={RECONSTRUCTION_CLAUSE_TOOLTIP}
        />
      </Grid>
    )
  }

  getCalloutContent = () => {
    const { classes, isNJReport } = this.props
    return isNJReport ? (
      <React.Fragment>
        Visit your
        <a href={NJ_STATE_AUTHORITY_LINK} target="_blank" rel="noopener noreferrer" className={classes.link}>
          {` ${STATE_AUTHORITY} `}
        </a>
        for information about allowable uses within your zone.
      </React.Fragment>
    ) : (
      `Visit your ${STATE_AUTHORITY} for information about allowable uses within your zone.`
    )
  }

  render() {
    const {
      classes,
      form,
      isNYCReport,
      regulations,
      isComplyingWithRegardToBulk,
      siteArea,
      historicDistrict,
      landmark,
      automatedZoningEnabled,
      maxBuildableArea,
      siteAreaMeasure,
      floorAreaRatio,
      zoningFloorArea,
      mechanicalDeductions,
    } = this.props

    const regulationsTableTitle = isNYCReport ? 'Additional Regulations' : 'Bulk Regulations'
    const hasNonComplyingRegulation = some(
      regulations,
      regulation => regulation.conformingStatus === NON_COMPLYING_STATUS
    )
    const shouldShowReconstructionClauseDiscussion = isNYCReport
      ? !isComplyingWithRegardToBulk || hasNonComplyingRegulation
      : hasNonComplyingRegulation

    return (
      <React.Fragment>
        <Paper className={classes.paper}>
          <Grid container spacing={2} data-qa="complying-bulk-section-tile">
            <Grid item xs={12}>
              <Typography variant="h6">Complying Bulk</Typography>
            </Grid>

            {!isNYCReport ? (
              <Grid item xs={12}>
                <Callout
                  content={<Typography variant="body1">{this.getCalloutContent()}</Typography>}
                  sx={{ mb: 1 }}
                  variant="link"
                />
                <ComplyingBulkInputsOther form={form} siteArea={siteArea} />
              </Grid>
            ) : (
              <React.Fragment>
                <Grid item xs={12}>
                  <Typography variant="subtitle2">Bulk Regulation</Typography>
                </Grid>

                <Grid item xs={12}>
                  <ComplyingBulkInputsNYC form={form} siteArea={siteArea} />
                </Grid>

                <Grid item xs={12}>
                  <BooleanRadioButtonList
                    label="Compliance"
                    name="isComplyingWithRegardToBulk"
                    items={COMPLIANCE_CONFORMITY_OPTIONS}
                  />
                </Grid>
              </React.Fragment>
            )}

            <Grid item xs={12}>
              <Typography className={classes.sectionTitle} variant="subtitle2">
                {regulationsTableTitle}
              </Typography>
              {automatedZoningEnabled && (
                <AutomationStatus formPaths={['automationMetadata']} sx={{ marginTop: '8px', marginBottom: '8px' }} />
              )}
              <RegulationsTable form={form} isNYCReport={isNYCReport} />
            </Grid>

            <Grid item xs={12}>
              <NarrativeComponent
                title="Complying Bulk Discussion"
                generatedText={complyingBulkDiscussion.generate}
                data={complyingBulkDiscussion.mapDTO({
                  ...form.values,
                  isNYCReport,
                  maxBuildableArea,
                  siteAreaMeasure,
                  floorAreaRatio,
                  zoningFloorArea,
                  mechanicalDeductions,
                })}
                name="complyingBulkDiscussion"
                tooltipText={TEXT_GENERATION_TOOLTIP}
              />
            </Grid>

            <Grid item xs={12}>
              <Callout content={RECONSTRUCTION_CLAUSE_INFO} sx={{ mb: 1 }} />
            </Grid>

            <Grid item xs={12}>
              <Field name="reconstructionClauseDiscussion">{() => null}</Field>
              {shouldShowReconstructionClauseDiscussion && this.renderReconstructionClauseDiscussion()}
            </Grid>
          </Grid>
        </Paper>
        {!!(historicDistrict || landmark) && (
          <Paper className={classes.paper}>
            <Grid container spacing={2} data-qa="historic-districts-and-landmark-buildings-section-tile">
              <Grid item xs={12}>
                <Typography variant="h6">Historic Districts and Landmark Buildings</Typography>
              </Grid>
              <Grid item xs={12}>
                <NarrativeComponent
                  title="Historic Districts and Landmark Buildings Export"
                  generatedText={historicDistrictsAndLandmarkBuildingsExport.generate}
                  data={historicDistrictsAndLandmarkBuildingsExport.mapDTO(form.values)}
                  name="historicDistrictsAndLandmarkBuildingsExport"
                  tooltipText={HISTORIC_DISTRICTS_AND_LANDMARK_BUILDING_TEXT_GENERATION_TOOLTIP}
                />
              </Grid>
              <Grid item xs={12}>
                <Typography>{DISTRICTS_AND_LANDMARKS_TEXT}</Typography>
              </Grid>
            </Grid>
          </Paper>
        )}
      </React.Fragment>
    )
  }
}

export default compose(
  withStyles(styles),
  connect(complyingBulkSelector),
  withAutomatedZoningIsEnabledForReport
)(ComplyingBulk)
