import React from 'react'

export const ChildrenLock = ({ children }) => React.Children.map(children, child => <Lock component={child} />)

const Lock = ({ component }) => {
  const ref = React.useRef()
  const [frozenWidth, setFrozenWidth] = React.useState(null)

  React.useLayoutEffect(() => {
    if (!frozenWidth) {
      const { width } = ref.current?.getBoundingClientRect?.() || {}
      width && setFrozenWidth(width)
    }
  }, [frozenWidth])

  const frozen = React.useMemo(() => {
    if (!component) {
      return null
    }
    return frozenWidth
      ? React.cloneElement(component, { style: { width: frozenWidth } })
      : React.cloneElement(component, { ref })
  }, [component, frozenWidth])

  return frozen
}
