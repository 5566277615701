import React from 'react'
import PropTypes from 'prop-types'
import { Typography, Paper, Table, TableHead, TableBody, TableRow, TableCell, withStyles } from '@material-ui/core'

import { Number } from '../../../../../../shared/components'
import { formatCurrencyFloat } from '../../../../../../shared/utils/numberFormatters'
import { Labels } from '../constants'

import styles from './styles'

const PotentialGrossResidentialIncomeTable = ({ classes, formValues }) => {
  const { rentTypeSummary, totalRent, totalPGI } = formValues

  return (
    <Paper className={classes.paper}>
      <Typography variant="subtitle1">Potential Gross Residential Income</Typography>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>{Labels.INCOME_TYPE}</TableCell>
            <TableCell>{Labels.ANNUAL_RENT}</TableCell>
            <TableCell>{Labels.INCREASE}</TableCell>
            <TableCell>{Labels.PGI}</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rentTypeSummary.map((summaryItem, index) => {
            return (
              !!summaryItem.rentType && (
                <TableRow key={summaryItem.rentType}>
                  <TableCell data-qa={`summaryTableData-rentType-${index}-cell`}>{summaryItem.rentType}</TableCell>
                  <TableCell data-qa={`summaryTableData-annualRent-${index}-cell`}>
                    {formatCurrencyFloat(summaryItem.annualRent)}
                  </TableCell>
                  <TableCell data-qa={`summaryTableData-increase-${index}-cell`}>
                    <Number name={`rentTypeSummary[${index}].increase`} decimalScale={10} allowNegative={false} />
                  </TableCell>
                  <TableCell data-qa={`summaryTableData-pgi-${index}-cell`}>
                    {formatCurrencyFloat(summaryItem.pgi)}
                  </TableCell>
                </TableRow>
              )
            )
          })}
          <TableRow>
            <TableCell className={classes.totalCell}>{Labels.TOTAL}</TableCell>
            <TableCell data-qa="summaryTableData-annualRent-total-cell" className={classes.totalCell} colSpan={2}>
              {formatCurrencyFloat(totalRent)}
            </TableCell>
            <TableCell data-qa="summaryTableData-pgi-total-cell" className={classes.totalCell}>
              {formatCurrencyFloat(totalPGI)}
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </Paper>
  )
}

PotentialGrossResidentialIncomeTable.propTypes = {
  classes: PropTypes.object.isRequired,
  formValues: PropTypes.shape({
    rentTypeSummary: PropTypes.arrayOf(
      PropTypes.shape({
        rentType: PropTypes.string.isRequired,
        annualRent: PropTypes.number.isRequired,
        increase: PropTypes.number,
        pgi: PropTypes.number.isRequired,
      })
    ).isRequired,
    totalRent: PropTypes.number.isRequired,
    totalPGI: PropTypes.number.isRequired,
  }),
}

export default withStyles(styles)(PotentialGrossResidentialIncomeTable)
