import { GeneratedText, gtLocked } from '../../../narrative/GeneratedText'

import { mapDTO, mapDataModel } from './mappings'

const generate = values => {
  const { isUnderContract, isAssignedContract } = values

  const gt = new GeneratedText()

  if (!isUnderContract) {
    gt.t`We are not aware of any current bids, offers, or options to purchase for this asset.`

    return gt.getParts()
  }

  const currentOwnerGt = gtLocked('currentOwner', null, 'Current owner')
  const buyerGt = gtLocked('contractDetails.grantee', null, 'Grantee')
  const salePriceGt = gtLocked('contractDetails.price', 'formatCurrencyInt', 'Sale Price')
  const saleDateGt = gtLocked('contractDetailsDate', null, 'Sale Date')

  gt.t`We are aware of the contract for ${buyerGt} to purchase this asset `
  gt.t`from ${currentOwnerGt} for ${salePriceGt}, dated ${saleDateGt}. `

  if (isAssignedContract) {
    const assigneeGt = gtLocked('assignedContractDetails.assignee', null, 'Assignee')
    const assignorGt = gtLocked('assignedContractDetails.assignor', null, 'Assignor')
    const assignmentPriceGt = gtLocked('assignedContractDetails.price', 'formatCurrencyInt', 'Assignment Price')
    const assignmentDateGt = gtLocked('assignedContractDetailsDate', null, 'Assignment Date')

    gt.t`We are also aware of an assignment for ${assignorGt} to assign this contract `
    gt.t`to ${assigneeGt} for ${assignmentPriceGt}, dated ${assignmentDateGt}. `
  }

  gt.t`We are not aware of any additional bids, offers, or options to purchase for this asset.`

  return gt.getParts()
}

export default { generate, mapDTO, mapDataModel }
