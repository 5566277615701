import { handleActions } from 'redux-actions'

import { expenseAddressesFetchRequest, expenseAddressesFetchReceive, expenseAddressesFetchFail } from './actions'

const initialState = {
  expenseAddresses: [],
  isLoading: false,
  isLoaded: false,
}

export const reducer = handleActions(
  {
    [expenseAddressesFetchRequest](state) {
      return {
        ...state,
        expenseAddresses: [],
        isLoading: true,
      }
    },
    [expenseAddressesFetchReceive](state, { payload }) {
      return {
        ...state,
        expenseAddresses: payload,
        isLoading: false,
        isLoaded: true,
      }
    },
    [expenseAddressesFetchFail](state) {
      return {
        ...state,
        isLoading: false,
      }
    },
  },
  initialState
)
