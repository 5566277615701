import React from 'react'
import PropTypes from 'prop-types'

import { Typography } from '@mui/material'
import { withStyles } from '@mui/styles'

import { GLOSSARY_INTRODUCTION } from '../../../../../../shared/constants/report/previewAndEdit/glossaryOfTerms'

const styles = () => ({
  paragraph: {
    margin: '25px 0',
    wordBreak: 'break-word',
    '&:first-child': {
      marginTop: 0,
    },
    '&:last-child': {
      marginBottom: 0,
    },
  },
  bulletPointParagraph: {
    margin: '0 0',
    wordBreak: 'break-word',
    '&:first-child': {
      marginTop: 0,
    },
    '&:last-child': {
      marginBottom: 0,
    },
  },
  bulletPoint: {
    margin: '8px 0 0 36px',
  },
})

const Preview = ({ terms, classes }) => (
  <React.Fragment>
    <Typography variant="body1" gutterBottom className={classes.paragraph}>
      {GLOSSARY_INTRODUCTION}
    </Typography>
    {terms.map((item, index) => {
      if (item.term === 'Liquidation Value (also known as Disposition Value)') {
        const bulletPoints = item.definition.split('\n')
        const definition = bulletPoints.splice(0, 1)[0]
        return (
          <>
            <Typography variant="body1" key={index} className={classes.bulletPointParagraph}>
              <b>{item.term}</b>: {definition}
            </Typography>
            {bulletPoints.map((bulletPoint, number) => {
              return (
                <Typography variant="body1" key={`bulletPoint${number}`} className={classes.bulletPoint}>
                  {bulletPoint}
                </Typography>
              )
            })}
          </>
        )
      }
      return (
        <Typography variant="body1" gutterBottom key={index} className={classes.paragraph}>
          <b>{item.term}</b>: {item.definition}
        </Typography>
      )
    })}
  </React.Fragment>
)

Preview.propTypes = {
  terms: PropTypes.arrayOf(
    PropTypes.shape({
      term: PropTypes.string.isRequired,
      definition: PropTypes.string.isRequired,
    })
  ),
}

Preview.defaultProps = {
  terms: [],
}

export default withStyles(styles)(Preview)
