import React from 'react'

import AddCircle from '@mui/icons-material/AddCircle'
import Remove from '@mui/icons-material/Remove'
import RemoveCircle from '@mui/icons-material/RemoveCircle'
import AutoModeIcon from '@mui/icons-material/AutoModeRounded'
import {
  Button,
  IconButton,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
} from '@mui/material'

import { Area, Template } from 'client-shared/components/Template'
import { CLEAR_ALL_LABEL, HIDE_REMOVED_LABEL, SHOW_REMOVED_LABEL } from 'report/constants'
import { find, forEach, get, isNumber } from 'lodash'
import { formatCurrencyFloat, formatCurrencyInt } from 'shared/utils/formatters/numberFormatters'
import { BASIS_OF_COMPARISON_OPTIONS } from 'shared/constants/expenses'

import { BASIS_MAP } from './constants'

const EXPENSE_COLUMNS = [
  'address',
  'insurance',
  'utilities',
  'repairsAndMaintenance',
  'payrollAndBenefits',
  'generalAndAdministrative',
  'management',
  'reserves',
]

const HEADER_COLUMNS = [
  { id: 'isAutoComp', label: '' },
  { id: 'address', label: 'Address' },
  { id: 'insurance', label: 'Insurance' },
  { id: 'utilities', label: 'Utilities' },
  { id: 'repairsAndMaintenance', label: 'Repairs & Maintenance' },
  { id: 'payrollAndBenefits', label: 'Payroll & Benefits' },
  { id: 'generalAndAdministrative', label: 'General & Administrative' },
  { id: 'management', label: 'Management Fees' },
  { id: 'reserves', label: 'Replacement Reserves' },
  { id: 'totalOperatingExpensesPsf', label: 'Total PSF' },
  { id: 'totalOperatingExpensesPerUnit', label: 'Total Per Unit' },
  { id: 'totalOperatingExpenses', label: 'Total Net Taxes' },
]

class MaterialTableHeader extends React.Component {
  render() {
    return (
      <TableHead>
        <TableRow>
          {HEADER_COLUMNS.map(row => {
            return (
              <TableCell key={`removed-${row.id}`} style={{ textAlign: 'center', fontWeight: 700, fontSize: '14px' }}>
                {row.label}
              </TableCell>
            )
          }, this)}
          <TableCell style={{ textAlign: 'center', fontWeight: 700, fontSize: '14px' }}>Actions</TableCell>
        </TableRow>
      </TableHead>
    )
  }
}

const EnhancedTableHead = MaterialTableHeader

class RemovedExpenseCompsTable extends React.Component {
  state = {
    isRemovedVisible: true,
    selected: [],
  }

  fillTableRowTemplate = ({
    actions,
    compIndex,
    compId,
    expenses,
    totalNetTaxes,
    totalPerUnit,
    totalPerSF,
    sourceIcon,
  }) => {
    const { basis } = this.props
    const currencyFormatter = basis === BASIS_OF_COMPARISON_OPTIONS.PER_SF ? formatCurrencyFloat : formatCurrencyInt
    const valueToGet = BASIS_MAP[basis]
    const fillerIcon = <Remove />

    return (
      <TableRow
        data-qa={isNumber(compIndex) ? `removed-comps-row-${compIndex}` : 'property-row'}
        key={`removed-comps-${compId}`}
        selected={false}
      >
        <TableCell data-qa="is-auto-comp">{sourceIcon}</TableCell>
        {EXPENSE_COLUMNS.map(expenseRow => {
          const foundExpense = find(expenses, expense => expense.id === expenseRow)
          let expenseValue
          if (expenseRow === 'address') {
            expenseValue = get(foundExpense, 'value', '')
          } else {
            expenseValue = currencyFormatter(get(foundExpense, valueToGet, 0))
          }
          return (
            <TableCell style={{ textAlign: 'center' }} data-qa={expenseRow}>
              {expenseValue || fillerIcon}
            </TableCell>
          )
        })}
        <TableCell style={{ textAlign: 'center' }} data-qa="totalOperatingExpensesPsf">
          {formatCurrencyFloat(totalPerSF)}
        </TableCell>
        <TableCell style={{ textAlign: 'center' }} data-qa="totalOperatingExpensesPerUnit">
          {formatCurrencyInt(totalPerUnit)}
        </TableCell>
        <TableCell style={{ textAlign: 'center' }} data-qa="totalOperatingExpenses">
          {formatCurrencyInt(totalNetTaxes)}
        </TableCell>
        <TableCell style={{ textAlign: 'center' }} data-qa="actions">
          {actions || ''}
        </TableCell>
      </TableRow>
    )
  }

  renderTableBodyContent = () => {
    const { addComp, removeComp, removedExpenseComps } = this.props
    const expenseCompTableRows = []
    forEach(removedExpenseComps, (expenseComp, index) => {
      expenseCompTableRows.push(
        this.renderTableRow({
          expenseComp,
          index,
          removeComp: () => removeComp(expenseComp),
          addComp: () => addComp(expenseComp),
        })
      )
    })
    return expenseCompTableRows
  }

  renderTableRow = ({ addComp, index, removeComp, expenseComp = {} }) => {
    const actions = (
      <Stack alignItems="center" direction="row" spacing={1}>
        <Tooltip placement="top" title="Add">
          <IconButton aria-label="Add" data-qa="removed-comp-add-btn" onClick={addComp} size="small">
            <AddCircle color="success" />
          </IconButton>
        </Tooltip>
        <Tooltip placement="top" title="Remove">
          <IconButton aria-label="Remove" data-qa="removed-comp-remove-btn" onClick={removeComp} size="small">
            <RemoveCircle color="error" />
          </IconButton>
        </Tooltip>
      </Stack>
    )

    const { expenses, totalNetTaxes, totalPerUnit, totalPerSF, _id } = expenseComp
    const sourceIcon = expenseComp.isAutoComp ? (
      <Tooltip placement="top" title="This Expense Comp was added automatically.">
        <Stack color="#673AB7">
          <AutoModeIcon />
        </Stack>
      </Tooltip>
    ) : null
    return this.fillTableRowTemplate({
      actions,
      expenses,
      totalNetTaxes,
      totalPerUnit,
      totalPerSF,
      compIndex: index,
      compId: _id,
      sourceIcon,
    })
  }

  toggleRemovedVisibility = () => {
    this.setState(prevState => ({
      isRemovedVisible: !prevState.isRemovedVisible,
    }))
  }

  render() {
    const { clearRemovedTable, disabled, removedExpenseComps } = this.props
    const { isRemovedVisible } = this.state
    const numberOfRemovedExpenseComps = removedExpenseComps.length || 0
    const title = `Removed Comps (${numberOfRemovedExpenseComps})`
    return (
      <Paper>
        <Template
          is={`
            'title tools'
            'table table'
            /auto  1fr
          `}
          gap={2}
        >
          <Area is="title">
            <Typography variant="h6" gutterBottom>
              {title}
            </Typography>
          </Area>
          <Area is="tools" gap={2} placeContent="flex-end" placeItems="center">
            <Button size="small" color="primary" disabled={disabled} onClick={this.toggleRemovedVisibility}>
              {isRemovedVisible ? HIDE_REMOVED_LABEL : SHOW_REMOVED_LABEL}
            </Button>
            <Button size="small" color="primary" disabled={disabled} onClick={clearRemovedTable}>
              {CLEAR_ALL_LABEL}
            </Button>
          </Area>
          <Area is="table">
            {!disabled && isRemovedVisible && (
              <Table size="small" data-qa="removed-sales-comps-table">
                <EnhancedTableHead
                  onRequestSort={this.handleRequestSort}
                  order={this.state.order}
                  orderBy={this.state.orderBy}
                />
                <TableBody>{this.renderTableBodyContent()}</TableBody>
              </Table>
            )}
          </Area>
        </Template>
      </Paper>
    )
  }
}

export default RemovedExpenseCompsTable
