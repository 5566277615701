import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { get, mean, max, min, map } from 'lodash'

import ComputedPanel from '../../../../../shared/components/ComputedPanel'
import { getRentPerSF } from '../../../../../../../shared/calculations/commercial'
import { RentTypesUnitsOfMeasure } from '../../../../../../../shared/constants/report/incomeApproach'
import { formatCurrencyInt, formatCurrencyFloat } from '../../../../../shared/utils/numberFormatters'

import { UNIT_TYPE_LABEL } from './constants'

const getFormattedValue = (value, rentRollRentBasis) => {
  if (rentRollRentBasis === RentTypesUnitsOfMeasure.PER_SF_PER_MONTH) {
    return formatCurrencyFloat(value || 0)
  }

  return formatCurrencyInt(value || 0)
}

const getComputedValues = (items, rentRollRentBasis) => {
  const minimum = min(items)
  const average = mean(items)
  const maximum = max(items)

  return [
    getFormattedValue(minimum, rentRollRentBasis),
    getFormattedValue(average, rentRollRentBasis),
    getFormattedValue(maximum, rentRollRentBasis),
  ]
}

const getUnitTypesRents = subjectUnitStats => {
  const { minPsf, maxPsf, avgPsf } = subjectUnitStats
  const computedValues = [formatCurrencyInt(minPsf), formatCurrencyInt(avgPsf), formatCurrencyInt(maxPsf)]

  return {
    items: computedValues,
    label: UNIT_TYPE_LABEL,
  }
}

const getCompsRents = (selectedRentComps, rentRollRentBasis) => {
  const items = map(selectedRentComps, comp => getRentPerSF(comp, rentRollRentBasis))
  const label = `Comps (${items.length} picked)`
  return {
    items: getComputedValues(items, rentRollRentBasis),
    label,
  }
}

class FilterComputedPanel extends React.PureComponent {
  static propTypes = {
    selectedRentComps: PropTypes.array,
    subjectUnitStats: PropTypes.object,
    rentRollRentBasis: PropTypes.string,
  }

  static defaultProps = {
    selectedRentComps: [],
    subjectUnitStats: {},
    rentRollRentBasis: RentTypesUnitsOfMeasure.PER_SF_PER_YEAR,
  }

  render() {
    const { selectedRentComps, subjectUnitStats, rentRollRentBasis } = this.props

    return (
      <ComputedPanel
        columns={[getUnitTypesRents(subjectUnitStats), getCompsRents(selectedRentComps, rentRollRentBasis)]}
      />
    )
  }
}

const mapStateToProps = state => {
  const { subjectUnitStats = {}, rentRollRentBasis } = get(
    state,
    'report.reportData.incomeApproach.commercialIncome.commercialRentComps',
    {}
  )

  return { subjectUnitStats, rentRollRentBasis }
}

export default connect(mapStateToProps)(FilterComputedPanel)
