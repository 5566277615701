import { Auto } from '../../Base'
import { withField } from '../tools'

const Fields = {
  ComboBox: withField(Auto.ComboBox),
  FreeSolo: withField(Auto.FreeSolo),
  Text: withField(Auto.Text),
}

export default { ...Fields }
