import React from 'react'
import PropTypes from 'prop-types'
import { Grid, withStyles, Typography } from '@material-ui/core'
import { times, get } from 'lodash'

import AutomationStatus from 'client-shared/components/AutomationStatus'

import { COOLING_SYSTEM_ITEMS, LOCATIONS, OTHER_TYPE } from '../../../../../../shared/constants/report/utilities'

import { arrayToKeyValuePairs } from '../../../../shared/utils/ui/checkboxHelper'

import { CheckboxWithLabel } from '../../../../shared/components/Checkbox'
import { DropDown } from '../../../../shared/components/DropDown'
import { Text, Number } from '../../../../shared/components'

const styles = theme => ({
  checkboxRoot: {
    height: 56,
  },
  checkBoxLabel: {
    marginTop: 0,
  },
})

class CoolingSystems extends React.PureComponent {
  static propTypes = {
    inspected: PropTypes.bool,
    systems: PropTypes.arrayOf(PropTypes.object),
    count: PropTypes.number,
    location: PropTypes.string,
  }

  static defaultProps = {
    count: 0,
    location: '',
    inspected: false,
  }

  get locationTypes() {
    return arrayToKeyValuePairs(LOCATIONS)
  }

  renderSystemRow = index => {
    const { systems } = this.props
    const isOtherType = get(systems, `${index}.type`) === OTHER_TYPE
    const isOtherLocation = get(systems, `${index}.location`) === OTHER_TYPE
    return (
      <Grid container spacing={16} key={index}>
        <Grid item xs={12}>
          <Typography variant="subtitle1">{`Cooling System ${index + 1}`}</Typography>
        </Grid>
        <Grid item xs={6}>
          <DropDown
            name={`coolingSystems.systems.${index}.type`}
            label="Select Cooling System Type"
            items={COOLING_SYSTEM_ITEMS}
          />
        </Grid>
        <Grid item xs={6}>
          <DropDown
            name={`coolingSystems.systems.${index}.location`}
            label="Select Cooling System Location"
            items={this.locationTypes}
          />
        </Grid>
        <Grid item xs={6}>
          {isOtherType && <Text name={`coolingSystems.systems.${index}.otherType`} label="Cooling System Type" />}
        </Grid>
        <Grid item xs={6}>
          {isOtherLocation && (
            <Text name={`coolingSystems.systems.${index}.otherLocation`} label="Cooling System Location" />
          )}
        </Grid>
      </Grid>
    )
  }

  render() {
    const { count, inspected, automationMetadata, automatedInspectionPrefillFlag } = this.props

    return (
      <Grid container direction="column">
        <Grid item>
          <Typography variant="subtitle1">Cooling System(s)</Typography>
        </Grid>
        <Grid item>
          {automatedInspectionPrefillFlag && automationMetadata && (
            <AutomationStatus
              formPaths={['automationMetadata']}
              message="We automated the information below from Mobile Inspection app"
              sx={{ marginTop: '8px', marginBottom: '8px' }}
            />
          )}
        </Grid>
        <Grid item>
          <CheckboxWithLabel name="coolingSystems.inspected" label="There is a cooling system(s)" />
        </Grid>
        {inspected && (
          <React.Fragment>
            <Grid item xs={3}>
              <Number label="Number of Cooling Systems" name="coolingSystems.count" allowNegative={false} />
            </Grid>
            <Grid item>{times(count, this.renderSystemRow)}</Grid>
          </React.Fragment>
        )}
      </Grid>
    )
  }
}

export default withStyles(styles)(CoolingSystems)
