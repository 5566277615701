import { get, sumBy, uniqueId } from 'lodash'

import { formatCurrencyFloat } from 'shared/utils/formatters/numberFormatters'

import { STATE_NAMES } from 'shared/constants/states'

import { LOSS_ITEM_KEYS, VALUE_CONCLUSION_TYPES } from 'shared/constants/acas'

import TaxCalculations from '../report-calculations/income-approach/tax/tax-calculations'

import { divide } from '../utils/numberOperations'

import {
  TAXABLE_ASSESSED_VALUE_TAX_ITEM_ROW_PREFIX,
  TAXABLE_ASSESSED_VALUE_TOTAL_ROW,
} from '../../libs/bowery-libs/constants/taxes'

export const buildTaxItemsAndTotals = (rawTaxItems, squareFootage, unitCount) => {
  const taxItems = []

  const taxItemTotals = {
    actualValue: 0,
    actualPsfValue: 0,
    actualPerUnitValue: 0,
    transitionalValue: 0,
    transitionalPsfValue: 0,
    transitionalPerUnitValue: 0,
  }

  rawTaxItems.forEach(rawTaxItem => {
    const newTaxItem = {
      id: rawTaxItem.id,
      label: rawTaxItem.label,
      actualValue: rawTaxItem.actualValue || 0,
      actualPsfValue: divide(rawTaxItem.actualValue, squareFootage),
      actualPerUnitValue: divide(rawTaxItem.actualValue, unitCount),
      transitionalValue: rawTaxItem.transitionalValue || 0,
      transitionalPsfValue: divide(rawTaxItem.transitionalValue, squareFootage),
      transitionalPerUnitValue: divide(rawTaxItem.transitionalValue, unitCount),
    }

    taxItems.push(newTaxItem)

    taxItemTotals.actualValue += newTaxItem.actualValue
    taxItemTotals.actualPsfValue += newTaxItem.actualPsfValue
    taxItemTotals.actualPerUnitValue += newTaxItem.actualPerUnitValue
    taxItemTotals.transitionalValue += newTaxItem.transitionalValue
    taxItemTotals.transitionalPsfValue += newTaxItem.transitionalPsfValue
    taxItemTotals.transitionalPerUnitValue += newTaxItem.transitionalPerUnitValue
  })

  return { taxItems, taxItemTotals }
}

export const formatTaxItemsAndTotalsAsCurrency = (taxItems, taxItemTotals) => {
  taxItems.forEach(taxItem => {
    taxItem.actualValue = formatCurrencyFloat(taxItem.actualValue)
    taxItem.actualPsfValue = formatCurrencyFloat(taxItem.actualPsfValue)
    taxItem.actualPerUnitValue = formatCurrencyFloat(taxItem.actualPerUnitValue)
    taxItem.transitionalValue = formatCurrencyFloat(taxItem.transitionalValue)
    taxItem.transitionalPsfValue = formatCurrencyFloat(taxItem.transitionalPsfValue)
    taxItem.transitionalPerUnitValue = formatCurrencyFloat(taxItem.transitionalPerUnitValue)
  })

  taxItemTotals.actualValue = formatCurrencyFloat(taxItemTotals.actualValue)
  taxItemTotals.actualPsfValue = formatCurrencyFloat(taxItemTotals.actualPsfValue)
  taxItemTotals.actualPerUnitValue = formatCurrencyFloat(taxItemTotals.actualPerUnitValue)
  taxItemTotals.transitionalValue = formatCurrencyFloat(taxItemTotals.transitionalValue)
  taxItemTotals.transitionalPsfValue = formatCurrencyFloat(taxItemTotals.transitionalPsfValue)
  taxItemTotals.transitionalPerUnitValue = formatCurrencyFloat(taxItemTotals.transitionalPerUnitValue)
}

export const generateTaxableAssessedValueTable = (rawTaxItems, squareFootage, unitCount) => {
  const { taxItems, taxItemTotals } = buildTaxItemsAndTotals(rawTaxItems, squareFootage, unitCount)

  const rowDefs = []
  const oneTaxItemExists = taxItems.length === 1

  taxItems.forEach(taxItem => {
    const id =
      taxItem.id && taxItem.id.startsWith(TAXABLE_ASSESSED_VALUE_TAX_ITEM_ROW_PREFIX)
        ? taxItem.id
        : uniqueId(TAXABLE_ASSESSED_VALUE_TAX_ITEM_ROW_PREFIX)
    rowDefs.push({
      id,
      item: taxItem.label,
      actual: taxItem.actualValue,
      actualPsf: taxItem.actualPsfValue,
      actualPerUnit: taxItem.actualPerUnitValue,
      transitional: taxItem.transitionalValue,
      transitionalPsf: taxItem.transitionalPsfValue,
      transitionalPerUnit: taxItem.transitionalPerUnitValue,
      rowDef: { hideAction: oneTaxItemExists },
    })
  })

  rowDefs.push({
    id: TAXABLE_ASSESSED_VALUE_TOTAL_ROW,
    item: 'Total',
    actual: taxItemTotals.actualValue,
    actualPsf: taxItemTotals.actualPsfValue,
    actualPerUnit: taxItemTotals.actualPerUnitValue,
    transitional: taxItemTotals.transitionalValue,
    transitionalPsf: taxItemTotals.transitionalPsfValue,
    transitionalPerUnit: taxItemTotals.transitionalPerUnitValue,
    rowDef: { summary: true },
  })

  return rowDefs
}

export const canUseCaliforniaTaxProjections = state => state === STATE_NAMES.CA

export const calculateTotalDeductions = valueInformation => {
  let entrepreneurialProfit = 0
  const { commercialRentLossItems, lossItems, residentialRentLossItems } = valueInformation || {}

  const totalDeductions = [
    ...(Array.isArray(commercialRentLossItems) ? commercialRentLossItems : []),
    ...(Array.isArray(lossItems) ? lossItems : []),
    ...(Array.isArray(residentialRentLossItems) ? residentialRentLossItems : []),
  ].reduce((sum, lossItem) => {
    if (!lossItem) {
      return sum
    }

    if (lossItem.name === LOSS_ITEM_KEYS.ENTREPRENEURIAL_PROFIT) {
      entrepreneurialProfit = lossItem.amount || 0
      return sum
    }

    return sum + (lossItem.amount || 0)
  }, 0)

  return totalDeductions + totalDeductions * entrepreneurialProfit
}

export const calculateCAOpinionTaxableAssessedValue = ({
  effectiveGrossIncome,
  totalExpensesNetTaxes,
  additionalSpecialAssessments,
  taxRate,
  concludedCapRate,
  valueConclusionType,
  asStabilizedValueInformation,
  asCompleteValueInformation,
  useAsStabilizedValueCA,
}) => {
  if (concludedCapRate == null || effectiveGrossIncome == null || totalExpensesNetTaxes == null) {
    return null
  }

  const effectiveTaxRate = taxRate || 0
  const sumOfAdditionalSpecialAssessments = sumBy(additionalSpecialAssessments, 'value')
  const ignoreDeductions = useAsStabilizedValueCA && valueConclusionType === VALUE_CONCLUSION_TYPES.AS_COMPLETE

  let sumOfDeductions = 0

  if (!ignoreDeductions) {
    if ([VALUE_CONCLUSION_TYPES.AS_COMPLETE, VALUE_CONCLUSION_TYPES.AS_STABILIZED].includes(valueConclusionType)) {
      sumOfDeductions += calculateTotalDeductions(asStabilizedValueInformation)
    }

    if ([VALUE_CONCLUSION_TYPES.AS_COMPLETE].includes(valueConclusionType)) {
      sumOfDeductions += calculateTotalDeductions(asCompleteValueInformation)
    }
  }

  return (
    divide(
      effectiveGrossIncome -
        totalExpensesNetTaxes -
        sumOfAdditionalSpecialAssessments +
        sumOfDeductions * effectiveTaxRate,
      concludedCapRate + effectiveTaxRate
    ) - sumOfDeductions
  )
}

export const parseTaxableAssessedValueTable = rows => {
  const taxItemRows = rows.filter(({ id }) => id !== TAXABLE_ASSESSED_VALUE_TOTAL_ROW)
  const totalRow = rows.find(({ id }) => id === TAXABLE_ASSESSED_VALUE_TOTAL_ROW) || {}

  const mappedTaxItems = []

  taxItemRows.forEach(taxItemRow => {
    const label = taxItemRow.item || taxItemRow.label
    mappedTaxItems.push({
      id: taxItemRow.id,
      label,
      actualValue: taxItemRow.actual,
      transitionalValue: taxItemRow.transitional,
    })
  })

  return {
    taxItems: mappedTaxItems,
    totalActual: totalRow.actual,
    totalTransitional: totalRow.transitional,
  }
}

export const calculateTaxInformation = (
  taxItems,
  taxRate,
  hasTransitionalAssessedValue,
  additionalTaxRates,
  additionalSpecialAssessments
) => {
  let totalActual = 0
  let totalTransitional = 0

  taxItems.forEach(item => {
    totalActual += item.actualValue || 0
    totalTransitional += item.transitionalValue || 0
  })

  const usesTransitionalAssessedValue = hasTransitionalAssessedValue && totalTransitional < totalActual
  const taxableAssessedValue = usesTransitionalAssessedValue ? totalTransitional : totalActual
  const effectiveTaxRate = get(taxRate, 'value', 0) + sumBy(additionalTaxRates, 'value')

  const taxLiability = TaxCalculations.calculateTaxLiability(
    taxableAssessedValue,
    effectiveTaxRate,
    sumBy(additionalSpecialAssessments, 'value')
  )
  return {
    totalActual,
    totalTransitional,
    taxLiability,
    taxableAssessedValue,
    usesTransitionalAssessedValue,
  }
}

export const generateTaxClassLookupValue = (userId, state, taxClassName) => {
  return `${userId}_${state}_${taxClassName}`
}
