import { FALLBACK_ROLES } from '../../../../libs/bowery-libs/constants/user'

export const getRoleLabelsById = roles =>
  roles
    ? roles.reduce((result, role) => {
        result[role.role] = role.label
        return result
      }, {})
    : {}

export const getRoleLabelsByConstant = roles =>
  roles
    ? roles.reduce((result, role) => {
        result[role.constant] = role.label
        return result
      }, {})
    : {}

export const getRoleIdsByConstant = roles => {
  if (!roles || !roles.length) {
    return FALLBACK_ROLES
  }
  return roles.reduce((result, role) => {
    result[role.constant] = role.role
    return result
  }, {})
}

export const getRoleOptions = roles =>
  roles
    ? roles
        .map(role => {
          return { value: role.role, label: role.label }
        })
        // eslint-disable-next-line id-length
        .sort((a, b) => a.value - b.value)
    : []

export const roleIdsToLabels = (roles, rolesById) =>
  roles
    .reduce((result, roleId) => {
      result += `${rolesById[roleId]}, `
      return result
    }, '')
    .slice(0, -2)
