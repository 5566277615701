import React from 'react'
import PropTypes from 'prop-types'

import { connect } from 'react-redux'
import { get } from 'lodash'
import filterFP from 'lodash/fp/filter'
import flowFP from 'lodash/fp/flow'
import mapFP from 'lodash/fp/map'

import { Grid, Typography } from '@material-ui/core'

import { extractGroupingParameters } from 'shared/helpers/incomeApproach'

import ComputedPanel from '../../../../../../shared/components/ComputedPanel'
import { RentTypes } from '../../../../../constants'
import { getComputedValues, hasMarketRateUnits, getComputedPanelLabel } from '../tools'
import { MARKET_RATE_LABEL, RENT_ROLL_LABEL, SUMMARY_LABEL } from '../constants'

const getUnitRents = (units, unitGroupKey, groupingType, unit_sqft) => {
  const rents = flowFP([
    filterFP(unit => {
      const { key } = extractGroupingParameters(unit, groupingType)
      return key === unitGroupKey && !unit.isVacant && unit.rentType === RentTypes.MARKET_RATE
    }),
    mapFP(unit => unit.rent),
  ])(units)

  const bedroomCount = get(units, '0.bedrooms')
  const computedValues = getComputedValues(rents)
  const hasMarketRate = hasMarketRateUnits(units, bedroomCount)
  return {
    items: computedValues,
    label: getComputedPanelLabel(hasMarketRate, unit_sqft),
  }
}

const getRents = (units, unitGroupKey, groupingType, rentType, rentField, columnLabel) => {
  const rents = flowFP([
    filterFP(unit => {
      const { key } = extractGroupingParameters(unit, groupingType)
      return key === unitGroupKey && unit.rentType === rentType
    }),
    mapFP(unit => unit[rentField]),
  ])(units)

  const computedValues = getComputedValues(rents)
  return {
    items: computedValues,
    label: columnLabel,
  }
}

const getRentRollPanelLabel = (units, unitGroupKey) => {
  const hasMarketRateUnitsFlag = hasMarketRateUnits(units, Number(unitGroupKey))
  return `${RENT_ROLL_LABEL}${hasMarketRateUnitsFlag ? ' ' + MARKET_RATE_LABEL : ''} ${SUMMARY_LABEL}`
}

class RentRollUnitGroupHeader extends React.PureComponent {
  static propTypes = {
    heading: PropTypes.string.isRequired,
    rentStatColumns: PropTypes.array.isRequired,
  }

  render() {
    const { rentStatColumns, heading } = this.props

    return (
      <React.Fragment>
        <Grid>
          <Typography>{heading}</Typography>
        </Grid>
        <ComputedPanel columns={rentStatColumns} />
      </React.Fragment>
    )
  }
}

const mapStateToProps = (state, ownProps) => {
  const { unitGroupKey, showDevelopersForecast } = ownProps
  const { units, unit_sqft } = get(state, 'report.reportData.incomeApproach.residentialIncome.residentialRentRoll')
  const { unitGroupingType } = get(state, 'report.reportData.incomeApproach.residentialIncome.residentialRentComps')

  const hasRentStabilizedItems = units.filter(unit => unit.rentType === RentTypes.RENT_STABILIZED).length

  const rentStatColumns = [getUnitRents(units, unitGroupKey, unitGroupingType, unit_sqft)]
  if (hasRentStabilizedItems) {
    rentStatColumns.push(
      getRents(units, unitGroupKey, unitGroupingType, RentTypes.RENT_STABILIZED, 'rent', 'Rent Stabilized')
    )
  }

  if (showDevelopersForecast) {
    rentStatColumns.push(
      getRents(
        units,
        unitGroupKey,
        unitGroupingType,
        RentTypes.MARKET_RATE,
        'rentForecast',
        "Subject Developer's Forecast"
      )
    )
  }

  const heading = getRentRollPanelLabel(units, unitGroupKey)

  return { rentStatColumns, heading }
}

export default connect(mapStateToProps, {})(RentRollUnitGroupHeader)
