import React from 'react'
import PropTypes from 'prop-types'
import { kebabCase, isEmpty } from 'lodash'
import { Grid, Button, Typography, withStyles } from '@material-ui/core'

const styles = theme => ({
  backLink: {
    fontSize: 12,
    letterSpacing: 0.4,
    cursor: 'pointer',
    color: theme.palette.primary.main,
    '&:hover': {
      textDecoration: 'underline',
    },
  },
  nextButton: {
    minWidth: 80,
  },
  disabledButton: {
    '&$nextButton': {
      color: '#fff',
    },
  },
  additionalButton: {
    fontWeight: 500,
    marginRight: 16,
    letterSpacing: '1.3px',
  },
})

const StepFooter = ({ classes, previous, next, additional }) => (
  <Grid container justify="space-between" alignItems="center">
    <Grid item>
      <Typography className={classes.backLink} onClick={previous.onClick} data-qa={`${kebabCase(previous.text)}-link`}>
        {previous.text}
      </Typography>
    </Grid>
    <Grid item>
      {!isEmpty(additional) && (
        <Button
          color="primary"
          disabled={!!additional.isDisabled}
          className={classes.additionalButton}
          onClick={additional.onClick}
          data-qa={`${kebabCase(additional.text)}-button`}
        >
          {additional.text}
        </Button>
      )}
      <Button
        color="primary"
        variant="contained"
        disabled={!!next.isDisabled}
        classes={{ root: classes.nextButton, disabled: classes.disabledButton }}
        onClick={next.onClick}
        data-qa="submit-button"
      >
        {next.text}
      </Button>
    </Grid>
  </Grid>
)

StepFooter.propTypes = {
  classes: PropTypes.object.isRequired,
  previous: PropTypes.shape({
    text: PropTypes.string,
    onClick: PropTypes.func,
  }).isRequired,
  next: PropTypes.shape({
    text: PropTypes.string,
    onClick: PropTypes.func,
    isDisabled: PropTypes.bool,
  }).isRequired,
  additional: PropTypes.shape({
    text: PropTypes.string,
    onClick: PropTypes.func,
    isDisabled: PropTypes.bool,
  }),
}

export default withStyles(styles)(StepFooter)
