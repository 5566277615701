import React from 'react'
import { get } from 'lodash'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import { Typography, Stack, Box } from '@mui/material'
import InfoIcon from '@mui/icons-material/InfoOutlined'

import Tooltip from '../../../../shared/components/Tooltip'

import { FORM_HEADING_TOOLTIP_TEXT, FORM_HEADING_TOOLTIP_LINK } from './constants'

const AppraisersTableHeadTooltip = ({ header, changeSettingsLink }) => {
  const getTooltipTitle = () => {
    return (
      <>
        {FORM_HEADING_TOOLTIP_TEXT}
        <Link to={changeSettingsLink}>
          <Box component="span" sx={{ textDecoration: 'underline', color: 'white', fontWeight: 400, fontSize: 12 }}>
            {FORM_HEADING_TOOLTIP_LINK}
          </Box>
        </Link>
      </>
    )
  }

  return (
    <Stack alignItems="center" direction="row" spacing={0.5}>
      <Typography variant="body2">{header}</Typography>
      <Tooltip placement="top-start" text={getTooltipTitle()}>
        <InfoIcon sx={{ width: 15, height: 15 }} />
      </Tooltip>
    </Stack>
  )
}

AppraisersTableHeadTooltip.propTypes = {
  header: PropTypes.string.isRequired,
  changeSettingsLink: PropTypes.string.isRequired,
}

export default connect(state => ({
  changeSettingsLink: `/user/${get(state, 'authentication.user.id')}/change-settings`,
}))(AppraisersTableHeadTooltip)
