import { isEmpty, cloneDeep, isNil, get } from 'lodash'

import BoweryDate from '@bowery-valuation/bowery-date'

export const shortDateFormat = value => {
  if (isNil(value)) {
    return value
  }

  const date = new BoweryDate(value)
  if (date.isValidDate()) {
    return date.format('l')
  }
  return value
}

export const shortDateWithZeroesFormat = value => {
  //we are returning the input value for now but would be probably be better to return empty string
  //but requires more testing and followup :()
  if (value === null || value === undefined || value === '') {
    return value
  }

  return new BoweryDate(value).format('L')
}

export const longDateFormat = value => {
  if (isEmpty(value)) {
    return value
  }
  return new BoweryDate(value).format('MMMM DD, YYYY')
}

export const longDateFormatWithoutLeadingZero = value => {
  if (isNil(value)) {
    return value
  }

  const date = new BoweryDate(value)
  if (date.isValidDate()) {
    return date.format('MMMM D, YYYY')
  }
  return value
}

export const dateTimeFormat = value => {
  if (isEmpty(value)) {
    return value
  }

  return new BoweryDate(value).format('lll')
}

export const shortDateTimeFormat = value => {
  if (isEmpty(value)) {
    return value
  }

  return new BoweryDate(value).format('MM/DD/YYYY hh:mm A')
}

export const dateSort = (array, dateVar, desc = true) => {
  const copiedArray = cloneDeep(array)
  const sortedArray = copiedArray.sort((objOne, objTwo) => {
    const dateOne = new BoweryDate(get(objOne, dateVar)).date
    const dateTwo = new BoweryDate(get(objTwo, dateVar)).date
    return desc ? dateTwo - dateOne : dateOne - dateTwo
  })
  return sortedArray
}
