import { generateCompsIndicesSentence, getComps } from 'shared/utils/textGeneration/salesApproach'
import { ADJUSTMENT_GROUPS } from 'shared/helpers/salesApproach/salesCompAdjustmentConstants'
import { hasAdjustments } from 'shared/helpers/salesApproach/salesCompAdjustments'
import { getUnitOfComparisonText } from 'shared/report-calculations/sales-approach/helpers'
import { getLastWordSeparator } from 'shared/utils/sentenceBuilder'

const mapData = (selectedComps, compAdjustments, unitOfComparison) => {
  const type = `per ${getUnitOfComparisonText(unitOfComparison)}`

  const shouldGenerateWriteup = hasAdjustments(compAdjustments, 'size', ADJUSTMENT_GROUPS.OTHER)

  const { upwardComps, downwardComps } = getComps(compAdjustments, selectedComps, 'size', ADJUSTMENT_GROUPS.OTHER)

  let upwardCompsSentence = ''
  let downwardCompsSentence = ''

  if (shouldGenerateWriteup) {
    if (upwardComps.length) {
      upwardCompsSentence = ` Comparable${upwardComps.length > 1 ? 's' : ''} ${generateCompsIndicesSentence(
        upwardComps,
        getLastWordSeparator
      )} warranted${upwardComps.length > 1 ? '' : ' an'} upward adjustment${upwardComps.length > 1 ? 's' : ''} as ${
        upwardComps.length > 1 ? 'they' : 'it'
      } ${upwardComps.length > 1 ? 'are' : 'is'} significantly larger than the subject.`
    }

    if (downwardComps.length) {
      downwardCompsSentence = ` Comparable${downwardComps.length > 1 ? 's' : ''} ${generateCompsIndicesSentence(
        downwardComps,
        getLastWordSeparator
      )} warranted${downwardComps.length > 1 ? '' : ' a'} downward adjustment${
        downwardComps.length > 1 ? 's' : ''
      } as ${downwardComps.length > 1 ? 'they' : 'it'} ${
        downwardComps.length > 1 ? 'are' : 'is'
      } significantly smaller than the subject.`
    }
  }
  return { upwardCompsSentence, downwardCompsSentence, type, hasAdjustments: shouldGenerateWriteup }
}

export const mapDTO = formValues => {
  const { selectedComps, compAdjustments, unitOfComparison } = formValues

  return mapData(selectedComps, compAdjustments, unitOfComparison)
}

export const mapDataModel = (report, parent) => {
  if (!parent.parent()) {
    return undefined
  }

  const salesApproach = parent.parent()

  const { salesComps = {}, salesCompsAdjustments = {}, unitOfComparison = 'sf' } = salesApproach
  const { selectedComps = [] } = salesComps
  const { compAdjustments = [] } = salesCompsAdjustments

  return mapData(selectedComps, compAdjustments, unitOfComparison)
}
