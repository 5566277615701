import React, { useCallback } from 'react'
import { get } from 'lodash'
import PhotoOutlinedIcon from '@mui/icons-material/PhotoOutlined'
import { Grid, Link } from '@mui/material'

import { ImageTooltip, Tooltip } from 'client-shared/components'
import { NO_IMAGE_AVAILABLE } from 'report/constants'

const ColumnHeader = ({ comp, showEditModal }) => {
  const address = get(comp, 'address.streetAddress')
  const photoUrl = get(comp, 'propertyInformation.photo.cdnUrl', NO_IMAGE_AVAILABLE)

  const onClick = useCallback(() => {
    showEditModal(comp)
  }, [showEditModal, comp])
  return (
    <Grid container spacing={1}>
      <Grid item xs={9}>
        <Tooltip text="Edit Comp" placement="top-start">
          <Link onClick={onClick}>{address}</Link>
        </Tooltip>
      </Grid>
      <Grid item xs={3}>
        <ImageTooltip
          placement="right"
          popperTemplate={
            <img style={{ height: '248px', width: '248px' }} src={photoUrl || NO_IMAGE_AVAILABLE} alt="Sales Comp" />
          }
        >
          <PhotoOutlinedIcon />
        </ImageTooltip>
      </Grid>
    </Grid>
  )
}

export default ColumnHeader
