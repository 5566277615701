import { isEmpty } from 'lodash'

import BoweryDate from '@bowery-valuation/bowery-date'

export const getAutomationFormattedDate = date => {
  return new Date(date).toLocaleDateString('en-US', {
    month: '2-digit',
    day: '2-digit',
    year: 'numeric',
  })
}

export const getAutomationFormattedTime = date => {
  return new Date(date)
    .toLocaleTimeString('en-US', {
      hour: 'numeric',
      minute: 'numeric',
      hour12: true,
      timeZoneName: 'short',
    })
    .replace(' AM', 'am')
    .replace(' PM', 'pm')
}

export const shortDateFormat = value => {
  if (isEmpty(value)) {
    return ''
  }

  return new BoweryDate(value).formatShortDate()
}

export const shortDateWithZeroesFormat = value => {
  if (isEmpty(value)) {
    return ''
  }

  return new BoweryDate(value).formatShortDateWithZeroes()
}

export const longDateFormat = value => {
  if (isEmpty(value)) {
    return ''
  }
  return new BoweryDate(value).formatLongDate()
}

export const dateTimeFormat = value => {
  if (isEmpty(value)) {
    return ''
  }

  return new BoweryDate(value).formatDateTime()
}

export const shortDateTimeFormat = value => {
  if (isEmpty(value)) {
    return ''
  }

  return new BoweryDate(value).formatShortDateTime()
}
