import { handleActions } from 'redux-actions'

import {
  importCommercialRentCompsRequest,
  importCommercialRentCompsReceive,
  importCommercialRentCompsFail,
} from './actions'

const initialState = {
  isImportingRentComps: false,
  importedRentComps: [],
}

export const reducer = handleActions(
  {
    [importCommercialRentCompsRequest](state) {
      return {
        ...state,
        isImportingRentComps: true,
      }
    },
    [importCommercialRentCompsReceive](state, { payload }) {
      return {
        ...state,
        isImportingRentComps: false,
        importedRentComps: payload.commercialRentComps,
      }
    },
    [importCommercialRentCompsFail](state) {
      return {
        ...state,
        isImportingRentComps: false,
      }
    },
  },
  initialState
)
