import { TEMPLATE_TYPES } from '../../constants/settings'

const BOWERY_TEMPLATE = 'bowery-way.docx'
const FREDDIE_MAC_TEMPLATE = 'freddie-mac.docx'
const BLOCKS_TEMPLATE = 'blocks.docx'
const OBJECTIVE_TEMPLATE = 'objective.docx'
export const PHOTOGRID_TEMPLATE = 'photo-grid-export.docx'

export const Templates = {
  [TEMPLATE_TYPES.FREDDIE_MAC]: FREDDIE_MAC_TEMPLATE,
  [TEMPLATE_TYPES.BOWERY_WAY]: BOWERY_TEMPLATE,
  [TEMPLATE_TYPES.BLOCKS]: BLOCKS_TEMPLATE,
}

export const TemplatesLists = {
  [TEMPLATE_TYPES.FREDDIE_MAC]: [
    { label: 'Stable', value: FREDDIE_MAC_TEMPLATE },
    { label: 'Experimental', value: OBJECTIVE_TEMPLATE },
  ],
  [TEMPLATE_TYPES.BOWERY_WAY]: [
    { label: 'Stable', value: BOWERY_TEMPLATE },
    { label: 'Experimental', value: OBJECTIVE_TEMPLATE },
  ],
  [TEMPLATE_TYPES.BLOCKS]: [{ label: 'Stable', value: BLOCKS_TEMPLATE }],
}

const pages = {
  propertySummary: 'property-summary',
  generalPropertyDescription: 'general-property-description',
  propertyHistory: 'property-history',
  propertyPhotos: 'property-photos',
}

export const SUBJECT_FIELDS = {
  streetAddress: {
    label: 'Street Address',
    link: pages.propertySummary,
  },
  city: {
    label: 'City',
    link: pages.propertySummary,
  },
  state: {
    label: 'State',
    link: pages.propertySummary,
  },
  zip: {
    label: 'ZIP',
    link: pages.propertySummary,
  },
  propertyId: {
    label: 'Property ID',
    link: pages.propertySummary,
  },
  comparableType: {
    label: 'Comparable Type',
    link: pages.propertySummary,
  },
  condition: {
    label: 'General Property Condition',
    link: pages.generalPropertyDescription,
  },
  yearBuilt: {
    label: 'Year Built',
    link: pages.propertySummary,
  },
  floors: {
    label: 'Floors',
    link: pages.propertySummary,
  },
  grossBuildingArea: {
    label: 'Gross Building Area',
    link: pages.propertySummary,
  },
  siteArea: {
    label: 'Site Area',
    link: pages.propertySummary,
  },
  residentialUnits: {
    label: 'Residential Units',
    link: pages.propertySummary,
  },
  commercialUnits: {
    label: 'Commercial Units',
    link: pages.propertySummary,
  },
  photo: {
    label: 'Photo',
    link: pages.propertyPhotos,
  },
  contractPrice: {
    label: 'Contract Price',
    link: pages.propertyHistory,
  },
  contractDate: {
    label: 'Contract Sale Date',
    link: pages.propertyHistory,
  },
  date: {
    label: 'Sale Date',
    link: pages.propertyHistory,
  },
  price: {
    label: 'Sale Price',
    link: pages.propertyHistory,
  },
  saleCondition: {
    label: 'Sale Condition',
    link: pages.propertyHistory,
  },
}
