import React from 'react'
import PropTypes from 'prop-types'
import { Paper, Stack, Typography } from '@mui/material'
import { withStyles } from '@material-ui/core'
import ClientList from 'report/forms/dataCollections/jobDetails/ClientList'

import { ANCHOR_CLIENTS } from 'shared/constants/report/dataCollections'

import { styles } from '../styles'

const Clients = ({ form, classes, organizationId }) => {
  return (
    <Stack spacing={2} minWidth="25rem" maxWidth="45rem">
      <Paper id={ANCHOR_CLIENTS} elevation={0} className={classes.noBackground}>
        <Stack spacing={2.5}>
          <Typography variant="h6" className={classes.cardSpacing}>
            Clients
          </Typography>
          <ClientList form={form} organizationId={organizationId} />
        </Stack>
      </Paper>
    </Stack>
  )
}

Clients.propTypes = {
  form: PropTypes.object.isRequired,
  classes: PropTypes.object.isRequired,
  organizationId: PropTypes.string.isRequired,
}

export default withStyles(styles)(Clients)
