import React from 'react'
import PropTypes from 'prop-types'

import { TableCell, TableRow } from '@material-ui/core'

import { TableBodyRow } from 'client-shared/components/TableHelpers'

import UnitMix from '../UnitMix'
import { BuildingCompUnitMixListColumns, UnitMixColumnNames } from '../../constants'

import BuildingCompCell from './BuildingCompCell'
import { buildingCompPropTypes } from './propTypes'

class BuildingCompItem extends React.PureComponent {
  state = {
    isExpanded: false,
  }

  toggleDetailsExpand = () => {
    this.setState(prevState => ({
      isExpanded: !prevState.isExpanded,
    }))
  }

  onEditCompItem = () => {
    this.props.onEditCompItem(this.props.item._id)
  }

  onRemoveCompItem = () => {
    this.props.onRemoveCompItem(this.props.item.id, this.props.index)
  }

  onAddCompItem = () => {
    this.props.onAddCompItem(this.props.item.id, this.props.index)
  }

  getUnitMixColumns = () => {
    const { showPerRoomAnalysis } = this.props

    if (showPerRoomAnalysis) {
      return BuildingCompUnitMixListColumns
    }

    const roomAnalysisColumnNames = [UnitMixColumnNames.AVG_ROOMS, UnitMixColumnNames.AVG_RENT_PER_ROOM]

    return BuildingCompUnitMixListColumns.filter(column => {
      return !roomAnalysisColumnNames.includes(column.name)
    })
  }

  render() {
    const { columns, index, isSubject, item } = this.props
    return (
      <>
        <TableBodyRow
          cellComponent={BuildingCompCell}
          columns={columns}
          index={index}
          isExpanded={this.state.isExpanded}
          isSubject={isSubject}
          item={item}
          key={item.id}
          label={isSubject ? 'subject-row' : undefined}
          onAddCompItem={this.props.onAddCompItem && this.onAddCompItem}
          onEditCompItem={this.props.onEditCompItem && this.onEditCompItem}
          onRemoveCompItem={this.props.onRemoveCompItem && this.onRemoveCompItem}
          order={index + 1}
          toggleDetailsExpand={this.toggleDetailsExpand}
        />
        {this.state.isExpanded && (
          <TableRow>
            <TableCell colSpan={columns.length}>
              <UnitMix columns={this.getUnitMixColumns()} items={item.unitMix} image={item.image} />
            </TableCell>
          </TableRow>
        )}
      </>
    )
  }
}

BuildingCompItem.propTypes = {
  columns: PropTypes.array,
  index: PropTypes.number,
  isSubject: PropTypes.bool,
  item: buildingCompPropTypes,
  onAddCompItem: PropTypes.func,
  onEditCompItem: PropTypes.func,
  onRemoveCompItem: PropTypes.func,
  showPerRoomAnalysis: PropTypes.bool.isRequired,
}

BuildingCompItem.defaultProps = {
  isSubject: false,
}

export default BuildingCompItem
