import React from 'react'
import PropTypes from 'prop-types'

import { Grid, DialogContent } from '@material-ui/core'
import { get } from 'lodash'

import GooglePlacesAutocomplete from '../../GooglePlacesAutocomplete'
import { hasSelectedGooglePlace } from '../../GooglePlacesAutocomplete/validation'
import { LABELS, WIZARD_STEPS } from '../../../constants/properties'

import StepFooter from '../StepFooter'
import { getLocationInfoFromAddress } from '../../../utils/propertyHelper'

import withSearch from './withSearch'
import { queryBuilder } from './queryBuilder'

export class BasicSearch extends React.Component {
  static propTypes = {
    searchProperties: PropTypes.func.isRequired,
    openNextStep: PropTypes.func.isRequired,
    invalid: PropTypes.bool.isRequired,
  }

  static buildQuery = ({ search }) => queryBuilder(get(search, 'location.addressInfo'))

  openAdvancedSearch = () => {
    this.props.openNextStep(WIZARD_STEPS.ADVANCED_SEARCH)
  }

  render() {
    const { searchProperties, invalid } = this.props
    return (
      <DialogContent data-qa="propertySearch-basic-form">
        <Grid container alignItems="center">
          <Grid item xs={12}>
            <GooglePlacesAutocomplete
              name="search.location"
              addressTransformer={getLocationInfoFromAddress}
              validate={hasSelectedGooglePlace}
            />
          </Grid>
          <StepFooter
            previous={{ text: LABELS.ADVANCED_SEARCH, onClick: this.openAdvancedSearch }}
            next={{ text: LABELS.SEARCH, onClick: searchProperties, isDisabled: invalid }}
          />
        </Grid>
      </DialogContent>
    )
  }
}

export default withSearch(BasicSearch)
