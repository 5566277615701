import React from 'react'
import PropTypes from 'prop-types'
import { get, sumBy, eq, noop } from 'lodash'
import arrayMutators from 'final-form-arrays'
import { FieldArray } from 'react-final-form-arrays'
import { Button, Divider, Paper, Table, TableBody, TableCell, TableHead, TableRow } from '@mui/material'

import otherIncomeDisscussion from 'shared/utils/textGeneration/incomeApproach/miscellaneous/other'

import { fromPercents, toPercents } from 'client-shared/utils/numberOperations'
import { formatCurrencyFloat } from 'client-shared/utils/numberFormatters'
import { required } from 'client-shared/utils/validation'
import { NumberComponent } from 'client-shared/components/Number'
import { PropertyTypes } from 'shared/constants'

import wrapForm from '../../../wrapForm'

import { MAX_VC_LOSS_PERCENTAGE_VALUE } from '../constants'

import { Text, Number, DropDown, NarrativeComponent } from '../../../../../shared/components'

import {
  DATA_PATH,
  HEADING,
  VCLossTypes,
  VC_LOSS_OPTIONS,
  NEW_INCOME_ITEM,
  INCOME_ITEMS_TABLE_COLUMNS,
  VC_LOSS_OPTIONS_COMMERCIAL,
} from './constants'

class OtherIncome extends React.PureComponent {
  addNewRow = () => {
    this.props.form.mutators.push('lineItems', NEW_INCOME_ITEM)
  }

  removeRow = index => {
    const { form } = this.props
    const { lineItems } = form.values

    form.change(
      'lineItems',
      lineItems.filter((value, key) => key !== index)
    )
  }

  renderRow = (rowName, index) => {
    const { form, residentialVc, propertyType } = this.props

    const item = get(form, `values.${rowName}`, {})
    const isResidentialIncomeType = item.vcLossType === VCLossTypes.RESIDENTIAL

    return (
      <TableRow key={index} data-qa={`otherIncomeLineItems-${index}-row`}>
        <TableCell sx={{ fontSize: 12 }}>{index + 1}</TableCell>
        <TableCell>
          <DropDown
            name={`lineItems[${index}].vcLossType`}
            items={propertyType === PropertyTypes.COMMERCIAL ? VC_LOSS_OPTIONS_COMMERCIAL : VC_LOSS_OPTIONS}
          />
        </TableCell>
        <TableCell>
          {isResidentialIncomeType ? (
            <NumberComponent value={residentialVc * 100} onChange={noop} disabled adornment="%" decimalScale={2} />
          ) : (
            <Number
              name={`lineItems[${index}].otherIncomeVc`}
              allowNegative={false}
              format={toPercents}
              normalize={fromPercents}
              validate={required}
              adornment="%"
              decimalScale={2}
              max={MAX_VC_LOSS_PERCENTAGE_VALUE}
            />
          )}
        </TableCell>
        <TableCell>
          <Text name={`lineItems[${index}].otherIncomeCategory`} placeholder="Billboard" />
        </TableCell>
        <TableCell>
          <Number
            name={`lineItems[${index}].otherIncomeAnnualAmount`}
            allowNegative={false}
            decimalScale={2}
            thousandSeparator
            adornment="$"
            adornmentPosition="start"
          />
        </TableCell>
        <TableCell>
          <Button data-qa="delete-btn" variant="contained" color="primary" onClick={() => this.removeRow(index)}>
            Delete
          </Button>
        </TableCell>
      </TableRow>
    )
  }

  render() {
    const { form } = this.props
    const lineItems = get(form, 'values.lineItems') || []
    const totalAnnualOtherIncome = sumBy(lineItems, 'otherIncomeAnnualAmount') || 0

    return (
      <Paper data-qa="otherIncome-panel">
        <Button data-qa="add-btn" variant="contained" color="primary" fullWidth onClick={this.addNewRow}>
          Add Other Income Item
        </Button>

        <Divider />

        <Table sx={{ mb: 6 }} size="small">
          <TableHead
            sx={{
              '& .MuiTableCell-head': {
                fontSize: 12,
              },
            }}
          >
            <TableRow>
              {INCOME_ITEMS_TABLE_COLUMNS.map(column => (
                <TableCell key={column} size="medium">
                  {column}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>

          <TableBody>
            <FieldArray name="lineItems" isEqual={eq}>
              {({ fields }) => fields.map(this.renderRow)}
            </FieldArray>
            <TableRow data-qa="total-row">
              <TableCell colSpan={3} />
              <TableCell>Total Annual Other Income:</TableCell>
              <TableCell data-qa="totalAnnualOtherIncome-cell" colSpan={2} size="medium">
                {formatCurrencyFloat(totalAnnualOtherIncome)}
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
        <NarrativeComponent
          generatedText={otherIncomeDisscussion.generate}
          data={otherIncomeDisscussion.mapDTO({ lineItems })}
          name="otherIncomeDiscussion"
          title="Other Income Discussion"
        />
      </Paper>
    )
  }
}

OtherIncome.propTypes = {
  classes: PropTypes.object.isRequired,
  form: PropTypes.object.isRequired,
}

const formOptions = {
  heading: HEADING,
  mutators: {
    ...arrayMutators,
  },
}

const mapStateToProps = state => {
  const residentialVc = get(
    state,
    'report.reportData.incomeApproach.potentialGrossIncome.residentialVCLossPercentage',
    0
  )
  return {
    residentialVc,
    propertyType: get(state, 'report.reportData.propertyInformation.propertySummary.propertyType'),
  }
}

export default wrapForm(DATA_PATH, formOptions, mapStateToProps)(OtherIncome)
