import { isFinite } from 'lodash'

import { roundToTwoDecimals } from '../utils/numberOperations'

export const calculateExpensesTotal = (expenses = [], sf = 0, units = 0) => {
  const expenseValues = expenses.map(expense => expense.value || expense).filter(isFinite)
  const totalValues = expenseValues.reduce(
    (totals, expense) => {
      return {
        total: totals.total + (isFinite(expense) ? expense : 0),
        totalPerSf: totals.totalPerSf + (isFinite(expense / sf) ? expense / sf : 0),
        totalPerUnit: totals.totalPerUnit + (isFinite(expense / units) ? expense / units : 0),
      }
    },
    { total: 0, totalPerSf: 0, totalPerUnit: 0 }
  )
  return {
    total: roundToTwoDecimals(totalValues.total),
    totalPerSf: roundToTwoDecimals(totalValues.totalPerSf),
    totalPerUnit: roundToTwoDecimals(totalValues.totalPerUnit),
  }
}
