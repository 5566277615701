import React, { FC } from 'react'

import { Field, Form } from 'react-final-form'
import { get, inRange, invert } from 'lodash'
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  TextField,
  Autocomplete,
  Paper,
} from '@mui/material'
import { Close } from '@mui/icons-material'
import NumberFormat from 'react-number-format'
import BoweryDate from '@bowery-valuation/bowery-date'
import { FormApi } from 'final-form'

import { EXPENSE_HISTORY_TYPES, EXPENSE_HISTORY_TYPE_KEYS } from 'shared/constants/expenses/expenseHistory'
import { isProjectionPeriod } from 'shared/helpers/incomeApproach/expenses/helpers'

import { MONTHS, MONTH_KEYS } from 'client-shared/utils/expenses/constants'

import { isProjectionOrActualPeriod } from '../helpers'

const NumberFormatRefRouted = React.forwardRef((props, ref) => (
  <NumberFormat getInputRef={ref} allowLeadingZeros={false} allowNegative={false} {...props} />
))

const validateExpenseMonth = (expenseMonth: string) => {
  if (!Object.values(MONTH_KEYS).includes(expenseMonth)) {
    return `Select a month`
  }

  return null
}

const validateExpenseYear = (expenseYear: number, form: FormApi) => {
  const currentYear = new BoweryDate().year
  const expensePeriod = get(form.getState(), 'values.expensePeriod', null)

  if (!inRange(expenseYear, 1970, 2100)) {
    return 'Enter a valid year'
  }

  if (
    [
      EXPENSE_HISTORY_TYPE_KEYS.ACTUAL,
      EXPENSE_HISTORY_TYPE_KEYS.ACTUAL_T_12,
      EXPENSE_HISTORY_TYPE_KEYS.ANNUALIZED_HISTORICAL,
    ].includes(expensePeriod)
  ) {
    return expenseYear > currentYear ? `Enter a year prior to ${currentYear}` : null
  }

  if (isProjectionPeriod(expensePeriod)) {
    return expenseYear < currentYear ? `Enter a year after ${currentYear - 1}` : null
  }

  return null
}

type EditExpenseYearDialogProps = {
  expenseRecord: Record<string, any>
  expenseYears: Record<string, any>[]
  onCancel: () => void
  onSave: () => void
}

const EditExpenseYearDialog: FC<EditExpenseYearDialogProps> = ({ expenseRecord, expenseYears, onCancel, onSave }) => {
  const initialExpensePeriod = invert(EXPENSE_HISTORY_TYPES)[expenseRecord?.expensePeriod]
  const initialExpenseMonth = isProjectionOrActualPeriod(initialExpensePeriod)
    ? MONTH_KEYS.DECEMBER
    : MONTHS[expenseRecord?.expenseMonth]
  const initialExpenseYear = new Date(expenseRecord.expenseDate).getUTCFullYear()

  const [expensePeriod, setExpensePeriod] = React.useState(initialExpensePeriod)
  const [expenseMonth, setExpenseMonth] = React.useState(initialExpenseMonth)
  const [expenseYear, setExpenseYear] = React.useState(initialExpenseYear)

  const renderExpensePeriodItems = React.useCallback(() => {
    return Object.entries(EXPENSE_HISTORY_TYPES).map(([key, value]) => (
      <MenuItem
        key={key}
        value={key}
        disabled={
          isProjectionPeriod(key) &&
          expenseYears.some(({ isProjected, period }) => isProjected || isProjectionPeriod(period))
        }
      >
        {value}
      </MenuItem>
    ))
  }, [expenseYears])

  const onExpensePeriodChange = React.useCallback((event, input, form) => {
    const nextValue = get(event, 'target.value', '')
    input.onChange(nextValue)
    setExpensePeriod(nextValue)

    if (isProjectionOrActualPeriod(nextValue)) {
      form.change('expenseMonth', MONTH_KEYS.DECEMBER)
      setExpenseMonth(MONTH_KEYS.DECEMBER)
    }

    if (isProjectionPeriod(nextValue)) {
      const newExpenseYear = new BoweryDate().addYearsToDate(1).year
      form.change('expenseYear', newExpenseYear)
      setExpenseYear(newExpenseYear)
    }
  }, [])

  const onExpenseMonthChange = React.useCallback((nextValue, input) => {
    input.onChange(nextValue)
    setExpenseMonth(nextValue)
  }, [])

  const onExpenseYearChange = React.useCallback((event, input) => {
    const nextValue = parseInt(get(event, 'target.value', ''))
    input.onChange(nextValue)
    setExpenseYear(nextValue)
  }, [])

  const disableExpenseMonth = isProjectionOrActualPeriod(expensePeriod)

  return (
    <Form
      initialValues={{
        expensePeriod: initialExpensePeriod,
        expenseMonth: initialExpenseMonth,
        expenseYear: initialExpenseYear,
      }}
      onSubmit={onSave}
    >
      {({ handleSubmit, invalid, pristine, form }) => (
        <Dialog fullWidth maxWidth="sm" open onClose={onCancel}>
          <DialogTitle>Modify Expense Period Column</DialogTitle>
          <Close
            onClick={onCancel}
            sx={{
              position: 'absolute',
              width: 24,
              cursor: 'pointer',
              right: 16,
              top: 18,
              color: '#979797',
            }}
          />
          <DialogContent sx={{ pt: '10px' }}>
            <FormControl fullWidth sx={{ mt: '8px', mb: '28px' }} size="small">
              <InputLabel>Expense Period</InputLabel>
              <Field name="expensePeriod" validateFields={['expenseYear']}>
                {({ input }) => (
                  <Select
                    data-qa="expense-period-input"
                    value={expensePeriod}
                    onChange={event => onExpensePeriodChange(event, input, form)}
                    label="Expense Period"
                    placeholder="Expense Period"
                    MenuProps={{
                      sx: { '& .MuiMenu-paper': { padding: 0 } },
                    }}
                  >
                    {renderExpensePeriodItems()}
                  </Select>
                )}
              </Field>
            </FormControl>

            <FormControl fullWidth sx={{ mt: '8px', mb: '4px' }} size="small">
              <Field name="expenseMonth" validate={data => validateExpenseMonth(data)}>
                {({ input, meta }) => (
                  <Autocomplete
                    data-qa="expense-month-input"
                    id="expenseMonth"
                    size="small"
                    value={expenseMonth}
                    disabled={disableExpenseMonth}
                    options={MONTHS}
                    onChange={(_, value) => onExpenseMonthChange(value, input)}
                    renderInput={params => (
                      <TextField
                        {...params}
                        error={!!meta.error}
                        helperText={meta.error || ' '}
                        label={
                          expensePeriod === EXPENSE_HISTORY_TYPE_KEYS.ANNUALIZED_HISTORICAL
                            ? 'Last Month Provided'
                            : 'Month'
                        }
                      />
                    )}
                    PaperComponent={props => (
                      <Paper sx={{ padding: 0 }} {...props}>
                        {props.children}
                      </Paper>
                    )}
                  />
                )}
              </Field>
            </FormControl>

            <FormControl fullWidth sx={{ mt: '8px', mb: 0 }} size="small">
              <Field name="expenseYear" validate={data => validateExpenseYear(data, form)}>
                {({ input, meta }) => (
                  <TextField
                    data-qa="expense-year-input"
                    value={expenseYear}
                    label="Year"
                    placeholder="Year"
                    size="small"
                    error={!!meta.error}
                    helperText={meta.error || ' '}
                    InputProps={{
                      // @ts-ignore
                      inputComponent: NumberFormatRefRouted,
                    }}
                    onChange={event => onExpenseYearChange(event, input)}
                  />
                )}
              </Field>
            </FormControl>
          </DialogContent>
          <DialogActions sx={{ padding: '2px 24px 20px 24px' }}>
            <Button onClick={onCancel}>Cancel</Button>
            <Button
              data-qa="update-period-button"
              disabled={pristine || invalid}
              onClick={handleSubmit}
              variant="contained"
            >
              Update Period
            </Button>
          </DialogActions>
        </Dialog>
      )}
    </Form>
  )
}

export default EditExpenseYearDialog
