import React from 'react'
import { Editor, PluginOrPlugins } from 'slate-react'

import { EVENT_HANDLED } from '../constants'

import Paragraph from './Paragraph'

export default {
  onKeyDown(event, editor, next) {
    if (event.key !== 'Enter') {
      return next()
    }

    editor.splitParagraph()
    return EVENT_HANDLED
  },
  renderBlock(props, editor, next) {
    const { attributes, node, children } = props
    if (node.type !== 'paragraph') {
      return next()
    }
    return <Paragraph attributes={attributes}>{children}</Paragraph>
  },
  commands: {
    splitParagraph(editor) {
      editor.splitBlock()
      return editor
    },
  },
} as PluginOrPlugins<Editor>

declare module 'slate-react' {
  interface Editor {
    splitParagraph(): Editor
  }
}
