import React from 'react'
import PropTypes from 'prop-types'

import { LABELS } from '../../../../constants/properties'
import StepFooter from '../../StepFooter'

class DefaultAdvancedSearch extends React.PureComponent {
  static propTypes = {
    openBasicSearch: PropTypes.func.isRequired,
  }

  render() {
    const { openBasicSearch } = this.props
    return (
      <StepFooter
        previous={{
          text: LABELS.STANDARD_SEARCH,
          onClick: openBasicSearch,
        }}
        next={{ text: LABELS.SEARCH, isDisabled: true }}
      />
    )
  }
}

export default DefaultAdvancedSearch
