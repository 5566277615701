import React, { useState, useEffect, useRef } from 'react'
import { Stack, Divider, IconButton, Box, Button, Tooltip, Typography } from '@mui/material'
import CancelIcon from '@mui/icons-material/Cancel'

import { Input as InputField } from '@ui/Field'

import ClientsSearch from './ClientsSearchField'
import { MAX_NUMBER_OF_CLIENTS, DELETE_CLIENT_DELAY_MS } from './constants'

const ReportClient = ({ clients, arrayIndex, reportClients, addClient, removeClient, formApi, client }) => {
  const [showUndoButton, setShowUndoButton] = useState(false)
  const [deleteClient, setDeleteClient] = useState(false)
  const deleteTimeoutId = useRef(null)

  const filledFirstClient = !!formApi.getState().values.reportClients[0].client?.id

  const handleRemoveClick = () => {
    setShowUndoButton(true)
    setDeleteClient(true)
  }

  const handleUndoClick = () => {
    setShowUndoButton(false)
    setDeleteClient(false)
  }

  useEffect(() => {
    if (deleteClient) {
      if (!deleteTimeoutId.current) {
        deleteTimeoutId.current = setTimeout(() => {
          removeClient(client.id)
        }, DELETE_CLIENT_DELAY_MS)
      }
    } else {
      if (deleteTimeoutId.current) {
        clearTimeout(deleteTimeoutId.current)
        deleteTimeoutId.current = null
      }
    }
  }, [client.id, deleteClient, removeClient])

  return (
    <Stack spacing={2.5}>
      {arrayIndex > 0 && reportClients.length > 1 && <Divider sx={{ borderColor: 'rgba(34, 34, 34, 0.42)' }} />}

      <Stack justifyContent="space-between" direction="row" alignItems="center" gap={1}>
        <ClientsSearch
          data-qa={`clientName[${arrayIndex}]`}
          clientSuggestions={clients}
          name={`reportClients[${arrayIndex}].client`}
          index={arrayIndex}
          disabled={showUndoButton || !clients.length}
        />
        {arrayIndex > 0 && (
          <>
            {!showUndoButton && (
              <Tooltip title="Remove" placement="top" arrow>
                <IconButton size="medium" variant="contained" color="error" onClick={handleRemoveClick}>
                  <CancelIcon fontSize="inherit" />
                </IconButton>
              </Tooltip>
            )}
            {showUndoButton && (
              <Button variant="outlined" data-qa="undoBtn" onClick={handleUndoClick}>
                Undo
              </Button>
            )}
          </>
        )}
      </Stack>
      <InputField.Text
        name={`reportClients[${arrayIndex}].clientFileNumber`}
        label="Client File Number"
        disabled={showUndoButton}
        InputLabelProps={{
          shrink: true,
        }}
      />
      <InputField.Text
        name={`reportClients[${arrayIndex}].applicationNumber`}
        label="NYCB Application Number"
        disabled={showUndoButton}
        InputLabelProps={{
          shrink: true,
        }}
      />
      {arrayIndex === reportClients.length - 1 && (
        <Box sx={{ textAlign: 'right' }}>
          <Button
            data-qa="addAdditionalClientBtn"
            variant="contained"
            sx={{ marginTop: 0.5 }}
            onClick={addClient}
            disabled={(arrayIndex === 0 && !filledFirstClient) || reportClients.length === MAX_NUMBER_OF_CLIENTS}
          >
            Add additional client
          </Button>
          {reportClients.length === MAX_NUMBER_OF_CLIENTS && (
            <Typography variant="caption" component="div" mt={1} data-qa="maxMessage">
              Max of two clients allowed
            </Typography>
          )}
        </Box>
      )}
    </Stack>
  )
}

export default ReportClient
