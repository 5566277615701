import { GeneratedText } from '../../../narrative/GeneratedText'

import { mapDTO, mapDataModel } from './mappings'

export const RECONCILIATION_SUMMARY =
  "We've considered the developer's budget, the estimate by Marshall Valuation Service (MVS) and the comparables analyzed.  For this analysis, we give the most weight to the provided developer's budget, given their experience in the market."

const generate = () => {
  const gt = new GeneratedText()

  gt.t`${RECONCILIATION_SUMMARY}`

  return gt.getParts()
}

export default { generate, mapDTO, mapDataModel }
