import { camelCase, values } from 'lodash'

import { LandCompatibilityOptions } from 'shared/constants/property/zoning'

import {
  EMPTY_REGULATION,
  OTHER_GEO_SPECIFIC_REGULATION_NAMES,
  OTHER_GEO_DEFAULT_REGULATION,
  OTHER_GEO_SPECIFIC_REGULATION,
  NY_FREDDIE_SPECIFIC_REGULATION_NAMES,
  NY_FREDDIE_SPECIFIC_REGULATION,
} from 'shared/constants/report/zoning'

import { arrayToKeyValuePairs, objectToKeyValuePairs } from '../../../../shared/utils/ui/checkboxHelper'

export const ONE_HUNDRED_PERCENT = 100
export const COMPLYING_TEXT = 'Your subject is complying.'
export const NON_COMPLYING_TEXT = 'Your subject is pre-existing non-complying.'
export const TEXT_GENERATION_TOOLTIP = 'Any changes to this text will be reflected in the report'
export const HISTORIC_DISTRICTS_AND_LANDMARK_BUILDING_TEXT_GENERATION_TOOLTIP =
  'The following text will appear in the Zoning Summary of your report.'
export const ZONE_NAMES = ['zoneOne', 'zoneTwo', 'zoneThree', 'zoneFour']
export const MAX_ZONES_COUNT = 4
export const ZONES = ZONE_NAMES.map((zone, index) => ({ name: zone, number: index + 1 }))

export const ZoningTabs = {
  INFORMATION: 'Information',
  USES: 'Uses',
  BULK: 'Bulk',
  PARKING: 'Parking',
}
export const ZONING_TABS = values(ZoningTabs).map(label => ({ label, value: label, key: camelCase(label) }))

export const NJ_REPORT_DISCUSSION_TOOLTIP =
  'This generated text and your appraiser commentary will appear in the Letter of Transmittal and Assessed Values & Real Estate Taxes of your report.'

export const FREDDIE_REPORT_DISCUSSION_TOOLTIP =
  'This generated text and your appraiser commentary will appear in the Letter of Transmittal and Site Description.'

export const NYC_PLANNING_LINK = 'https://www1.nyc.gov/site/planning/zoning/districts-tools/use-groups.page'
export const NJ_STATE_AUTHORITY_LINK = 'https://www.generalcode.com/resources/ecode360-library/#NJ'

export const NYC_PLANNING = 'NYC Planning'
export const STATE_AUTHORITY = 'State Authority'

export const USES_CONFORMITY_OPTIONS = [
  { value: true, label: 'The subject property conforms with regard to its allowable uses.' },
  { value: false, label: 'The subject property does not conform with regard to its allowable uses.' },
]

export const COMPLIANCE_CONFORMITY_OPTIONS = [
  { value: true, label: 'The subject property complies with regard to its bulk regulations.' },
  { value: false, label: 'The subject property does not comply with regard to its bulk regulations.' },
]

export const PARKING_CONFORMITY_OPTIONS = [
  { value: true, label: 'The subject property complies with regards to its parking regulations.' },
  { value: false, label: 'The subject property does not comply with regard to its parking regulations.' },
]

export const NO_REGULATIONS_MESSAGE = 'You have not added any additional regulations'

export const CONFORMING_STATUS_TYPES = ['Complying', 'Non-complying', 'N/A']
export const CONFORMING_STATUSES = arrayToKeyValuePairs(CONFORMING_STATUS_TYPES)
export const NON_COMPLYING_STATUS = 'Non-complying'

export const ZONING_FIELD_NAMES = [
  'propertyIdentificationDiscussion',
  'conformingUseDiscussion',
  'complyingBulkDiscussion',
  'parkingDiscussion',

  'isConformingWithAllowableUses',
  'isComplyingWithRegardToBulk',
  'isConformingWithParkingRequirements',

  'propertyUses',
  'currentUses',
  'permittedUses',
  'regulations',

  'zones',

  'requiredParkingPerUnit',
  'requiredParkingPerSF',
  'requiredParkingSpaces',
  'mechanicalDeductions',
  'floorAreaRatio',
  'areWaivedParkingRequirements',
  'waiverBasis',
]

export const ShortSiteAreaMeasureLabels = {
  sf: 'SF',
  acre: 'AC',
}

export const ParagraphSiteAreaMeasureLabels = {
  sf: 'square feet',
  acre: 'acres',
}

export const LegalPropertySiteAreaMeasureLabels = {
  sf: 'square foot',
  acre: 'acre',
}

export const RECONSTRUCTION_CLAUSE_INFO =
  'The Reconstruction Clause will only appear in your report if you have a non-complying bulk regulation.'

export const RECONSTRUCTION_CLAUSE_CALLOUT =
  'We do not have the Reconstruction Clause for your geography. Type it in the Appraiser Commentary field below.'

export const RECONSTRUCTION_CLAUSE_TOOLTIP = 'The following text will appear in the Zoning Summary of your report.'

export const DISTRICTS_AND_LANDMARKS_TEXT =
  'Historic districts are collections of landmark buildings that, together, create a distinct sense of place. ' +
  'Individual landmarks are standalone structures that have architectural, cultural, or historical significance. ' +
  'The regulatory process is the same for historic districts and individual landmarks, although certain features and ' +
  "sites may be identified as significant at the time of designation. Many of the City's individual landmarks are located in historic districts. " +
  'Owners of individual landmarks and buildings within historic districts are required to obtain permits from the Landmarks Commission for most types of alterations.'

export const LAND_COMPATIBILITY_OPTION_TYPES = objectToKeyValuePairs(LandCompatibilityOptions, true)

/** @deprecated Prefer importing this from the shared folder */
export {
  EMPTY_REGULATION,
  OTHER_GEO_SPECIFIC_REGULATION_NAMES,
  OTHER_GEO_DEFAULT_REGULATION,
  OTHER_GEO_SPECIFIC_REGULATION,
  NY_FREDDIE_SPECIFIC_REGULATION_NAMES,
  NY_FREDDIE_SPECIFIC_REGULATION,
}
