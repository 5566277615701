import React from 'react'

import wrapForm from 'report/forms/wrapForm'
import { AMENITIES_PATH } from 'shared/constants/report/keysAndDataPaths'

import Amenities from './Amenities'
import { decorators } from './decorators'

export const DATA_PATH = AMENITIES_PATH

const heading = 'Amenities Description'

const AmenitiesContainer = ({ form }) => <Amenities form={form} />

export default wrapForm(DATA_PATH, { heading, decorators })(AmenitiesContainer)
