import React from 'react'

import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Button,
  Stack,
  Typography,
} from '@mui/material'

const SuccessfulCreateCompModal = ({ open, onCancel }) => {
  return (
    <Dialog fullWidth open={open} data-qa="create-comp-dialog">
      <DialogTitle>
        <Typography variant="h6" component="span">
          Comparable created
        </Typography>
      </DialogTitle>
      <DialogContent>
        <DialogContentText>
          The subject property will be added to the Sales Comparable database once the report draft is submitted in
          Salesforce. Thank you for working to make Bowery's data better!
        </DialogContentText>
      </DialogContent>
      <Stack flexDirection="row" justifyContent="end">
        <DialogActions>
          <Button onClick={onCancel} data-qa="create-comp-close-btn">
            Close
          </Button>
        </DialogActions>
      </Stack>
    </Dialog>
  )
}

export default SuccessfulCreateCompModal
