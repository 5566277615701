import { get, intersectionWith, differenceWith } from 'lodash'

import { generateSystemsParagraph } from '../hotWaterSystemsDiscussion/mappings'
import { OIL_HEATING_TYPES, OTHER_TYPE } from '../../../../constants/report/utilities'

type System = { location: string; type: string; otherType: string; oilType: string; otherOilType: string }

const systemComparator = (systemA: System, systemB: System) => {
  return (
    systemA.location === systemB.location &&
    ((systemA.type === OTHER_TYPE && systemB.type === OTHER_TYPE && systemA.otherType === systemB.otherType) ||
      (systemA.type !== OTHER_TYPE && systemB.type !== OTHER_TYPE && systemA.type === systemB.type))
  )
}

const mapData = ({
  heatingSystems,
  coolingSystems,
}: {
  heatingSystems: { systems: System[]; inspected: boolean }
  coolingSystems: { systems: System[]; inspected: boolean }
}) => {
  const commonSystems = intersectionWith(heatingSystems.systems, coolingSystems.systems, systemComparator)

  const commonSystemsParagraph = generateSystemsParagraph({
    systems: commonSystems,
    type: 'heating and cooling',
    addition: true,
  })

  const heatingOnlySystems = differenceWith(heatingSystems.systems, commonSystems, systemComparator)

  const heatingSystemItems = heatingOnlySystems.map(system => {
    const normalizedOilType = system.oilType ? `(${system.oilType})` : ''
    const oilType = system.oilType === OTHER_TYPE ? system.otherOilType : normalizedOilType
    const type = OIL_HEATING_TYPES.includes(system.type) && oilType ? `${system.type} ${oilType}` : system.type
    return { ...system, type }
  })

  const heatingOnlySystemsParagraph = generateSystemsParagraph({
    notInspected: !heatingSystems.inspected,
    systems: heatingSystemItems,
    type: 'heating',
    addition: true,
  })

  const coolingOnlySystems = differenceWith(coolingSystems.systems, commonSystems, systemComparator)

  const coolingOnlySystemsParagraph = ` ${generateSystemsParagraph({
    notInspected: !coolingSystems.inspected,
    systems: coolingOnlySystems,
    type: 'cooling',
    addition: true,
  })}`

  return {
    commonSystemsParagraph,
    heatingOnlySystemsParagraph: commonSystemsParagraph
      ? ` ${heatingOnlySystemsParagraph}`
      : heatingOnlySystemsParagraph,
    coolingOnlySystemsParagraph,
  }
}

export const mapDTO = (formValues: { heatingSystems: any; coolingSystems: any }) => {
  const { heatingSystems, coolingSystems } = formValues

  return mapData({
    heatingSystems,
    coolingSystems,
  })
}

export const mapDataModel = (report: any) => {
  const utilities = get(report, 'buildingDescription.utilities', {})
  const heatingSystems = get(utilities, 'heatingSystems')
  const coolingSystems = get(utilities, 'coolingSystems')

  return mapData({
    heatingSystems: heatingSystems ? heatingSystems.toObject() : {},
    coolingSystems: coolingSystems ? coolingSystems.toObject() : {},
  })
}
