import { createMuiTheme } from '@material-ui/core/styles'
import { grey, red, green } from '@material-ui/core/colors'
import Spacing from '@material-ui/core/styles/spacing'

const MAIN_COLOR = '#4260D3'

const theme = createMuiTheme({
  palette: {
    primary: {
      50: '#E5F0F7',
      100: '#BFDAEC',
      200: '#9AC3DF',
      300: '#79ACD1',
      400: '#649CC8',
      500: '#538CC0',
      600: '#4A80B4',
      700: '#2E4394',
      800: '#385F90',
      900: MAIN_COLOR,
      A100: '#BFDAEC',
      A200: '#9AC3DF',
      A400: '#649CC8',
      A700: '#2E4394',
      // @ts-expect-error
      contrastDefaultColor: 'light',
      main: MAIN_COLOR,
    },
    secondary: grey,
    error: red,
    accent: green,
    text: {
      primary: '#2E4154',
      // @ts-expect-error
      success: '#008b7d',
    },
  },
  overrides: {
    MuiButton: {
      root: {
        fontWeight: 400,
      },
    },
  },
  paper: {
    padding: 16,
    marginBottom: 8,
  },
  totalCell: {
    fontWeight: 700,
  },
  brandColor: '#E4002B',
  secondaryColor: '#686B6B',
  secondaryBrandColor: '#60768b',
  spacing: Object.assign(Spacing, {
    desktopKeylineIncrement: 56,
  }),
  typography: {
    useNextVariants: true,
  },
})

export type Theme = typeof theme

export default function getTheme() {
  return theme
}
