import React from 'react'

import { get } from 'lodash'
import { connect } from 'react-redux'

import { errorNotification as errorNotificationAction } from 'client-shared/redux/actions/notifications'

import ResultsTable from './ResultsTable'
import { expenseCompsFetch, expenseCompsClear } from './redux/actions'

const ResultsTableContainer = props => <ResultsTable {...props} />

const mapStateToProps = (state, ownProps) => {
  const expenseComps = get(state, 'expenseComps', {})
  const locationIdentifier = get(state, 'report.reportData.locationIdentifier')
  const reportId = get(state, 'report.reportData._id')

  return {
    locationIdentifier,
    reportId,
    ...expenseComps,
    ...ownProps,
  }
}

const mapDispatchToProps = (dispatch, { filters, tableFilters }) => ({
  clearExpenseComps: () => dispatch(expenseCompsClear()),
  errorNotification: err => dispatch(errorNotificationAction({ message: `${err}` })),
  fetchExpenseComps: comparableExpenses => dispatch(expenseCompsFetch({ filters, tableFilters, comparableExpenses })),
})

export default connect(mapStateToProps, mapDispatchToProps)(ResultsTableContainer)
