import { isFinite, isNumber, mean } from 'lodash'
import {
  createRowDecorator,
  createColumnDecorator,
  createComputedValueDecorator,
} from '@bowery-valuation/ui-components'

import BoweryDate from '@bowery-valuation/bowery-date'

import { roundToTwoDecimals } from 'shared/utils/numberOperations'
import { calculateExpensesTotal } from 'shared/calculations/expenses'

import { EXPENSE_HISTORY_TYPE_TITLES } from 'shared/constants/expenses/expenseHistory'

import { MONTH_KEYS } from '../../../../shared/utils/expenses/constants'

import {
  EXPENSE_RATIO,
  TOTAL_OPERATING_EXPENSES,
  TOTAL_OPERATING_EXPENSES_PER_SF,
  TOTAL_OPERATING_EXPENSES_PER_UNIT,
  EXPENSE_COMPARABLES_CATEGORIES,
  NON_CALCULATABLE_CATEGORIES,
} from '../../../../../../shared/constants/expenses/expenseComparables'

import { calculateExpenseTotals } from './helpers'

const valueForRow = (item, row) => {
  return item?.expenses?.find(expense => expense.id === row.id)?.value
}

export const rowCalculations = createRowDecorator({
  path: 'comparableExpenses',
  rowDefinitionsPath: 'expenseCategories',
  updates: ({ value: updatedRow, params: rowItemValues }) => {
    if (NON_CALCULATABLE_CATEGORIES[updatedRow?.id]) {
      return {}
    }
    const average = mean(rowItemValues)
    return {
      average: isFinite(average) ? average : null,
    }
  },
})

const atLeastOneValueIsFinite = (oldValue, newValue) => {
  return Number.isFinite(oldValue) || Number.isFinite(newValue)
}

// Assuming all non-numbers are functional equal, don't replace one invalid value with another
// This is to prevent the form from being marked as dirty on load and triggering the save modal
const updateValue = (result, columnItem, key, value) => {
  if (atLeastOneValueIsFinite(columnItem[key], value)) {
    result[key] = value
  }
}

export const columnCalculations = createColumnDecorator({
  path: 'comparableExpenses',
  updates: ({ value: columnItem }) => {
    const filterRows = check => {
      const filteredRows = columnItem?.expenses?.filter(check) || []
      return filteredRows.map(row => valueForRow(columnItem, row)).filter(isNumber)
    }

    const [egi] = filterRows(row => row.id === EXPENSE_COMPARABLES_CATEGORIES.egi)

    const { total, totalPerSF, totalPerUnit } = calculateExpenseTotals(calculateExpensesTotal, columnItem?.expenses)

    const expenseRatio = roundToTwoDecimals(total / egi) || null

    const result = {}
    updateValue(result, columnItem, TOTAL_OPERATING_EXPENSES.key, total)
    updateValue(result, columnItem, TOTAL_OPERATING_EXPENSES_PER_SF.key, totalPerSF)
    updateValue(result, columnItem, TOTAL_OPERATING_EXPENSES_PER_UNIT.key, totalPerUnit)

    if (atLeastOneValueIsFinite(columnItem[EXPENSE_RATIO.key], expenseRatio)) {
      result[EXPENSE_RATIO.key] = expenseRatio || null
    }

    return result
  },
})

export const computedValueDecorator = createComputedValueDecorator({
  path: 'comparableExpenses',
  valuesPath: 'expenses',
  updates: ({ value: newValue, params: updatedRow }) => {
    if (updatedRow?.id !== EXPENSE_COMPARABLES_CATEGORIES.expensePeriod) {
      return {}
    }
    if (newValue === EXPENSE_HISTORY_TYPE_TITLES.ACTUAL || newValue === EXPENSE_HISTORY_TYPE_TITLES.PROJECTION) {
      const update = {}
      if (EXPENSE_HISTORY_TYPE_TITLES.PROJECTION === newValue) {
        update.expenseYear = new BoweryDate().addYearsToDate(1).year
      } else {
        update.expenseMonth = MONTH_KEYS.DECEMBER
      }
      return update
    }
  },
})
