import React from 'react'
import PropTypes from 'prop-types'
import { get, filter } from 'lodash'

import { COMMERCIAL_INCOME_PATH, COMMERCIAL_RENT_COMPS_MAP_PATH } from 'shared/constants/report/keysAndDataPaths'

import wrapForm from '../../../wrapForm'

import { getAddress } from '../../../../../shared/utils/geography'

import CommercialRentCompsMap from './CommercialRentCompsMap'
import { Labels } from './CommercialRentCompsMapConstants'

export const DATA_PATH = COMMERCIAL_RENT_COMPS_MAP_PATH

class CommercialRentCompsMapContainer extends React.PureComponent {
  static propTypes = {
    form: PropTypes.object.isRequired,
    subject: PropTypes.object.isRequired,
    areRentCompsLoaded: PropTypes.bool.isRequired,
    compGroups: PropTypes.arrayOf(
      PropTypes.shape({
        _id: PropTypes.string.isRequired,
        name: PropTypes.string.isRequired,
      })
    ).isRequired,
    commercialRentComps: PropTypes.arrayOf(
      PropTypes.shape({
        commercialCompGroupId: PropTypes.string,
      })
    ).isRequired,
  }

  getMapsWithComps = (maps, compGroups, commercialRentComps) => {
    return maps.map(mapData => ({
      ...mapData,
      name: compGroups.find(cg => cg._id === mapData.groupId).name,
      comps: commercialRentComps.filter(comp => comp.commercialCompGroupId === mapData.groupId),
    }))
  }

  render() {
    const { form, subject, areRentCompsLoaded, commercialRentComps, compGroups } = this.props

    const maps = get(form, 'values.maps', [])
    const mapsWithComps = this.getMapsWithComps(maps, compGroups, commercialRentComps)

    return <CommercialRentCompsMap subject={subject} isReady={areRentCompsLoaded} mapsWithComps={mapsWithComps} />
  }
}

const formOptions = { heading: Labels.COMMERCIAL_RENT_COMPARABLES_MAP }

const mapStateToProps = state => {
  const { selectedRentComps } = get(state, `report.reportData.${COMMERCIAL_INCOME_PATH.join('.')}.commercialRentComps`)
  const reportData = get(state, 'report.reportData')

  const compGroups = get(reportData, 'incomeApproach.commercialIncome.commercialCompGroups.compGroups', [])
  const commercialRentComps = filter(selectedRentComps, rentComp => {
    return !!rentComp.leaseId && !!rentComp.leaseVersionNumber
  })

  const streetAddress = get(reportData, 'propertyInformation.propertySummary.streetAddress')
  const city = get(reportData, 'propertyInformation.propertySummary.city')
  const propertyState = get(reportData, 'state')
  const zip = get(reportData, 'zip')

  const coords = get(state, 'report.reportData.propertyInformation.coords')

  const address = getAddress(streetAddress, city, propertyState, zip)
  const subject = {
    coords: [coords.latitude || coords.lat, coords.longitude || coords.lng],
    ...address,
  }
  return {
    areRentCompsLoaded: !!commercialRentComps,
    commercialRentComps,
    subject,
    compGroups,
  }
}

export default wrapForm(DATA_PATH, formOptions, mapStateToProps, undefined)(CommercialRentCompsMapContainer)
