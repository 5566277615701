import React, { FC, useCallback, useContext, useRef } from 'react'
import { Box } from '@mui/material'
import { makeStyles } from '@mui/styles'
import {
  RowBasedTable,
  useRowsApi,
  useColumnsApi,
  SimpleTableProps,
  ColumnAlignmentEnum,
} from '@bowery-valuation/ui-components'
import { get, noop, findIndex, cloneDeep } from 'lodash'
import { CustomFormApi } from 'final-form'
import ObjectID from 'bson-objectid'
import { Value } from 'slate'

import { isValueJSON } from 'shared/utils/narrative'
import { buildSlateWriteup } from 'shared/utils/textGeneration/writeupBuilder'
import { Callout } from 'client-shared/components'

import { ChipOptionsContext } from '../../ChipOptionsContext'
import { FormValues, TableAtomicUnit } from '../../types'
import EditTableTitle from '../EditTableTitle'
import styles from '../../styles'

import { TableCellSlateEditor } from './SlateEditor/TableCellSlateEditor'

// @ts-ignore makeStyles doesn't like the strings - it wants enums
const useStyles = makeStyles(styles)

type TableProps = {
  form: CustomFormApi<FormValues>
  formPath: string
}

const Table: FC<TableProps> = ({ form, formPath }) => {
  const { chipOptions } = useContext(ChipOptionsContext)
  const formValues = form.getState().values
  const ROWS_FIELD_PATH = `${formPath}.rowData`
  const COLUMNS_FIELD_PATH = `${formPath}.columnConfigurations`
  const tableRows: TableAtomicUnit['rowData'] = get(formValues, ROWS_FIELD_PATH, [])
  const tableColumns: TableAtomicUnit['columnConfigurations'] = get(formValues, COLUMNS_FIELD_PATH, [])

  const rowsApi = useRowsApi(ROWS_FIELD_PATH)
  const columnsApi = useColumnsApi(COLUMNS_FIELD_PATH)

  const gridRef: SimpleTableProps['gridApiRef'] = useRef(null)

  const classes = useStyles()

  const parsePastedData = (params: any) => {
    const { data: pastedData } = params

    const numPastedRows = pastedData.length
    const numPastedCols = pastedData[0].length

    const currentFormValues = form.getState().values

    const updatedTableRows = cloneDeep(get(currentFormValues, ROWS_FIELD_PATH, []))
    const updatedTableColumns = cloneDeep(get(currentFormValues, COLUMNS_FIELD_PATH, []))

    //the gridApi type does not have the getFocusedCell() function on it so it causes a TS error
    //@ts-ignore
    const selectedCell = gridRef.current.getFocusedCell()

    const pasteStartRowIndex = selectedCell?.rowIndex || 0
    const pasteStartColIndex = findIndex(updatedTableColumns, { name: selectedCell?.column?.getColId() }) || 0

    const pasteEndRowIndex = pasteStartRowIndex + numPastedRows
    const pasteEndColIndex = pasteStartColIndex + numPastedCols

    for (let rowIndex = pasteStartRowIndex; rowIndex < pasteEndRowIndex; rowIndex++) {
      let tableRowToUpdate = updatedTableRows[rowIndex]
      if (!tableRowToUpdate) {
        const newUniqueId = new ObjectID().toString()
        const newRow = { id: newUniqueId }

        updatedTableRows.push(newRow)
        tableRowToUpdate = newRow
      }

      for (let colIndex = pasteStartColIndex; colIndex < pasteEndColIndex; colIndex++) {
        let correspondingTableColumn = updatedTableColumns[colIndex]
        if (!correspondingTableColumn) {
          const newUniqueId = new ObjectID().toString()
          const newColumn = { label: '', name: newUniqueId, type: 'text' as const, align: ColumnAlignmentEnum.left }

          updatedTableColumns.push(newColumn)
          correspondingTableColumn = newColumn
        }

        tableRowToUpdate[correspondingTableColumn.name] =
          pastedData[rowIndex - pasteStartRowIndex][colIndex - pasteStartColIndex]
      }

      updatedTableRows[rowIndex] = tableRowToUpdate
    }

    form.change(COLUMNS_FIELD_PATH, updatedTableColumns)
    form.change(ROWS_FIELD_PATH, updatedTableRows)

    return null
  }

  const getCustomColumnConfig = useCallback(() => {
    const formatValueForDisplay = (value: unknown) => {
      if (value instanceof Value) {
        // When typing
        return buildSlateWriteup({ narrative: value })
      } else if (isValueJSON(value)) {
        // When value is loaded from database
        return buildSlateWriteup({ narrative: Value.fromJSON(value) })
      } else if (typeof value === 'string') {
        // In a newly created table
        return value
      } else if (!value) {
        // In a newly created cell
        return ''
      } else {
        console.warn('Unrecognized value type in table cell', value)
        return ''
      }
    }

    return {
      editable: true,
      cellEditorFramework: TableCellSlateEditor,
      cellEditorParams: {
        inputProps: { chipOptions },
      },
      formatValueForDisplay: formatValueForDisplay,
    }
  }, [chipOptions])

  return (
    <Box className={classes.tableBox}>
      <EditTableTitle formPath={formPath} />
      {tableColumns.length > 5 && (
        <Callout
          content="Table is more than 5 columns and may render incorrectly on the Word document."
          variant="error"
          className={classes.tableTooltip}
        />
      )}
      <RowBasedTable
        id="create-generic-table"
        columns={tableColumns}
        rows={tableRows}
        onRowDelete={rowsApi.deleteRow}
        onRowUpdate={rowsApi.updateRow}
        onManyRowsUpdate={rowsApi.updateManyRows}
        onColumnDragEnd={noop}
        onRowsDragEnd={noop}
        onColumnDelete={columnsApi.deleteColumn}
        onColumnUpdate={columnsApi.updateColumn}
        actionMenu={{ rowsApi, columnsApi }}
        getCustomColumnConfig={getCustomColumnConfig}
        hideHeader
        hideIndexColumn
        actionCellHidden
        processDataFromClipboard={parsePastedData}
        gridApiRef={gridRef}
      />
    </Box>
  )
}

export default Table
