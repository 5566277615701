import React from 'react'
import { useForm } from 'react-final-form'

import { Paper, Stack, Grid, Typography } from '@mui/material'

import { NarrativeComponent, UneditableSection, UneditableField } from 'client-shared/components'
import propertyRights from 'shared/utils/textGeneration/reportInformation/propertyRights'
import letterOfTransmittalPurpose from 'shared/utils/textGeneration/reportInformation/letterOfTransmittalPurpose'

import definitionOfMarketValue from 'shared/utils/textGeneration/reportInformation/definitionOfMarketValue'
import { ANCHOR_REPORT_DETAILS } from 'shared/constants/report/dataCollections'

import { OTHER_INTENDED_USE } from '../../../../../../libs/bowery-libs/constants/report'

const KeyInfoCommon = ({
  address,
  form,
  renderProvidedDocumentUpload,
  valueAsComplete,
  valueAsStabilized,
  currentPath,
}) => {
  const { intendedUse } = useForm().getState().values

  return (
    <Stack spacing={1}>
      <Paper data-qa="purpose-section-tile">
        <Stack spacing={2}>
          <Typography gutterBottom id="purpose" variant="h6">
            Purpose
          </Typography>
          <UneditableSection
            name="Found In: Job Details Data"
            formName="Job Details Data"
            link="job-details"
            anchor={ANCHOR_REPORT_DETAILS}
            returnDataPath={currentPath}
            editDataMessage
          >
            <Stack spacing={1}>
              <UneditableField
                label="Intended Use"
                name={intendedUse === OTHER_INTENDED_USE ? 'otherIntendedUse' : 'intendedUse'}
              />
              <UneditableField label="Interest Appraised As Is Market Value" name="interestAppraisedAsIsMarketValue" />
              {valueAsComplete && (
                <UneditableField label="Interest Appraised As Complete" name="interestAppraisedAsComplete" />
              )}
              {valueAsStabilized && (
                <UneditableField label="Interest Appraised As Stabilized" name="interestAppraisedAsStabilized" />
              )}
            </Stack>
          </UneditableSection>

          <NarrativeComponent
            title="Property Rights Appraised"
            generatedText={propertyRights.generate}
            data={propertyRights.mapDTO(form.values)}
            name="propertyRights"
          />
          <NarrativeComponent
            title="Letter of Transmittal Purpose"
            generatedText={letterOfTransmittalPurpose.generate}
            data={letterOfTransmittalPurpose.mapDTO({ ...form.values, address })}
            form={form}
            name="letterOfTransmittalPurpose"
          />
        </Stack>
      </Paper>
      <Paper data-qa="engagement-section-tile">
        <Typography gutterBottom variant="h6">
          Engagement
        </Typography>
        <UneditableSection
          name="Found In: Job Details Data"
          formName="Job Details Data"
          link="job-details"
          anchor={ANCHOR_REPORT_DETAILS}
          returnDataPath={currentPath}
          editDataMessage
        >
          <Grid container columnSpacing={3} rowSpacing={1}>
            <Grid item xs={6}>
              <UneditableField label="Due Date" name="dueDate" date />
            </Grid>
            <Grid item xs={6}>
              <UneditableField label="Date of Valuation (As Is)" name="dateOfValuation" date />
            </Grid>
            <Grid item xs={6}>
              <UneditableField label="Bowery Job #" name="reportNumber" prefix="JOB-" />
            </Grid>
            <Grid item xs={6}>
              <UneditableField label="Inspection Date" name="inspectionDate" date />
            </Grid>
            <Grid item xs={12}>
              <UneditableField label="Letter of Engagement" name="letterOfEngagement.fileName" />
            </Grid>
          </Grid>
        </UneditableSection>
      </Paper>

      {renderProvidedDocumentUpload()}

      <Paper data-qa="definition-of-market-value-tile">
        <NarrativeComponent
          title="Definition of Market Value"
          generatedText={definitionOfMarketValue.generate}
          name="definitionOfMarketValue"
          tooltipText={
            'The following text will appear in the Definition of Market Value in your export; ' +
            'the numbered list of definitions below this paragraph is not able to be edited here'
          }
        />
      </Paper>
    </Stack>
  )
}

export default KeyInfoCommon
