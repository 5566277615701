import React from 'react'
import { get, sumBy, isEqual } from 'lodash'
import arrayMutators from 'final-form-arrays'
import { Dialog, DialogContent, DialogTitle, DialogActions, Button, Paper, Grid, Typography } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'
import { TabContext, TabList } from '@mui/lab'
import { Tab } from '@mui/material'

import { calculateTaxInformation } from 'shared/helpers/taxes'
import { TAX_INFORMATION_PATH } from 'shared/constants/report/keysAndDataPaths'

import wrapForm from '../../wrapForm'
import { BASIS_TYPES } from '../../../../../../shared/constants/report/incomeApproach/taxes'
import { parseTaxableAssessedValueTable } from '../../../../../../shared/helpers/taxes'
import { INCOME_TYPES } from '../../../../shared/constants/income'
import { RENOVATION_COST_TYPE } from '../../../../shared/constants/costs'
import { RenovationCostTypes } from '../../property/Renovations/constants'
import { CalloutButton, RadioButtonList } from '../../../../shared/components'
import { arrayToKeyValuePairs } from '../../../../shared/utils/ui/checkboxHelper'
import { STATE_NAMES } from '../../../../../../shared/constants/states'
import { selectReportInformation } from '../../property/Zoning/selectors'

import styles from './styles'
import decorators from './decorators'
import SummaryTable from './SummaryTable'
import TaxComps from './TaxComps'
import CurrentLiability from './CurrentLiability'
import ProjectedLiability from './ProjectedLiability'
import { PER_BASIS_TYPES } from './constants'
import { TaxTabs, TAX_TABS } from './TaxComps/constants'

export const DATA_PATH = TAX_INFORMATION_PATH
const REFERENCE_GUIDE_LINK =
  'https://docs.google.com/spreadsheets/d/1lvchEM_rFCp6gpAb2rwcxjZBw0MsAoG14WGdyh-rIVQ/edit?usp=sharing'

class TaxInformation extends React.PureComponent {
  state = {
    basisSwitch: false,
    currentTab: TaxTabs.CURRENT,
  }

  onChangeTab = (event, value) => {
    this.setState({ currentTab: value })
  }

  deleteComps = event => {
    const { form } = this.props
    const basis = form.values.basis
    form.batch(() => {
      if (basis === BASIS_TYPES.PER_UNIT) {
        form.change('basis', BASIS_TYPES.PSF)
      } else {
        form.change('basis', BASIS_TYPES.PER_UNIT)
      }
      form.change('taxComps', [])
    })
    this.setState({ basisSwitch: !this.state.basisSwitch })
  }

  toggleBasis = () => {
    const { form } = this.props
    const { taxComps, basis } = form.values
    if (taxComps.length === 0) {
      if (basis === BASIS_TYPES.PER_UNIT) {
        form.change('basis', BASIS_TYPES.PSF)
      } else {
        form.change('basis', BASIS_TYPES.PER_UNIT)
      }
      this.setState({ basisSwitch: false })
    } else {
      this.setState({ basisSwitch: !this.state.basisSwitch })
    }
  }

  renderTabContent = () => {
    const { form, isNYCReport, basisForSFAnalysis } = this.props
    const { currentTab } = this.state
    const { effectiveGrossIncome, taxRate, projected } = form.values

    const includeProjectedTaxLiabilityInExport = projected.includedInExport

    let projectedTaxRate = taxRate
    if (includeProjectedTaxLiabilityInExport && projected.overrideTaxClass) {
      projectedTaxRate = projected.taxRate || taxRate
    }

    let tabComponent = null

    switch (currentTab) {
      case TaxTabs.PROJECTED: {
        tabComponent = <ProjectedLiability form={form} />
        break
      }
      case TaxTabs.COMPARABLES: {
        tabComponent = <TaxComps form={form} />
        break
      }
      case TaxTabs.SUMMARY: {
        tabComponent = (
          <SummaryTable effectiveGrossIncome={effectiveGrossIncome} taxRate={projectedTaxRate} form={form} />
        )
        break
      }
      default: {
        tabComponent = (
          <CurrentLiability form={form} isNYCReport={isNYCReport} basisForSFAnalysis={basisForSFAnalysis} />
        )
      }
    }

    return tabComponent
  }

  componentDidMount() {
    // TODO: Set default expansion panel state here
  }

  togglePanel = name => (event, expanded) => {
    this.setState(state => ({
      ...state,
      expansionPanels: {
        ...state.expansionPanels,
        [name]: expanded,
      },
    }))
  }

  render() {
    const { basisSwitch, currentTab } = this.state
    const { form, classes, isNYCReport } = this.props
    const { taxComps } = form.values

    const showReferenceGuideLink = !isNYCReport

    return (
      <div className={classes.wrapper}>
        <Grid container className={classes.gridContainer}>
          {showReferenceGuideLink && (
            <Grid item xs={12}>
              <CalloutButton
                link={REFERENCE_GUIDE_LINK}
                linkText="Visit"
                sx={{ mb: 1 }}
                target="_blank"
                text="Check the Bowery reference guide for data sources outside of NYC."
                variant="link"
              />
            </Grid>
          )}
          <Grid item xs={4}>
            <Paper className={classes.basisPaper}>
              <Typography variant="subtitle2" className={classes.title}>
                Concluded Liability Basis
              </Typography>
              <RadioButtonList
                name="basis"
                items={arrayToKeyValuePairs(PER_BASIS_TYPES)}
                onChange={this.toggleBasis}
                horizontal
              />
              {basisSwitch && taxComps.length > 0 && (
                <Dialog open>
                  <DialogTitle className={classes.dialogText}>Warning</DialogTitle>
                  <DialogContent className={classes.dialogText}>
                    Changing your unit of measurement will delete the comp values you have entered.
                  </DialogContent>
                  <DialogActions>
                    <Button className={classes.title} color="primary" variant="contained" onClick={this.toggleBasis}>
                      Cancel
                    </Button>
                    <Button className={classes.title} onClick={this.deleteComps}>
                      Okay
                    </Button>
                  </DialogActions>
                </Dialog>
              )}
            </Paper>
          </Grid>
        </Grid>
        <TabContext value={currentTab}>
          <TabList onChange={this.onChangeTab} variant="fullWidth" classes={{ root: classes.tabContainer }}>
            {TAX_TABS.map(({ label, value, key }) => (
              <Tab
                label={label}
                value={value}
                key={key}
                classes={{ labelContainer: classes.tabLabel }}
                data-qa={`${key}-tab`}
              />
            ))}
          </TabList>
        </TabContext>
        {this.renderTabContent()}
      </div>
    )
  }
}

const formOption = {
  heading: 'Tax Information',
  keepDirtyOnReinitialize: true,
  /**
   * We need to add a custom handler for initialValuesEqual because Final Form uses a shallow equality check by default.
   * In mapStateToProps (TaxInformation/index.jsx), we return an object containing initialValues, which creates a new
   * object on every re-render. The shallow copy sees this as an update to the original initialValues, and updates the
   * form with the "updated" initialValues. This causes form fields to revert to their initial value mid-save. It does
   * this only for unregistered fields, which is why we see some fields changing values halfway through the save
   * operation, but not others. Rather than making sure we know every unregistered field we would need to register to
   * get the expected functionality, we are overriding the equality check to a deep check.
   */
  initialValuesEqual: isEqual,
  mutators: { ...arrayMutators },
  decorators,
  registeredFields: ['jurisdictionTaxClasses', 'isPerBasisColumnAdded', 'isAssessedValueColumnAdded'],
}

const getIncomeTotals = state => {
  return {
    [INCOME_TYPES.PGI]: get(state, 'report.reportData.incomeApproach.potentialGrossIncome.potentialGrossIncome', 0),
    [INCOME_TYPES.EGI]: get(state, 'report.reportData.incomeApproach.taxInformation.effectiveGrossIncome', 0),
  }
}

const getRenovationsTotals = state => {
  const renovationCostsType = get(state, 'report.reportData.propertyInformation.renovations.renovationCostsType')
  const costTypeKey = renovationCostsType === RenovationCostTypes.TOTAL ? 'total' : 'itemized'
  const renovations = get(state, `report.reportData.propertyInformation.renovations.${costTypeKey}`)
  const hardItems = get(renovations, 'items', []).filter(item => item.expenseType === 'Hard')

  return {
    [RENOVATION_COST_TYPE.TOTAL]: get(renovations, 'total.amount', 0),
    [RENOVATION_COST_TYPE.HARD]: sumBy(hardItems, 'amount') || 'N/A',
  }
}

export default wrapForm(DATA_PATH, formOption, state => {
  const formValues = get(state, 'report.reportData.incomeApproach.taxInformation') || {}
  const { address, city, county, borough, block, lot, qualifier, final, incomeApproach, salesApproach } = get(
    state,
    'report.reportData'
  )

  const saleValueConclusionInformation = get(salesApproach, 'saleValueConclusion.valueConclusionInformation')
  const valueConclusionType = get(state, 'report.reportSettings.valueConclusionType')
  const concludedCapRate = get(final, 'capRateDiscussion.concludedCapRate')
  const totalExpensesNetTaxes = get(incomeApproach, 'proForma.totalOperatingExpensesNetTaxes.total')
  const subjectState = get(state, 'report.reportData.state')
  const isOther =
    (subjectState === STATE_NAMES.NY && !borough) ||
    (subjectState !== STATE_NAMES.NJ && subjectState !== STATE_NAMES.NY)
  const {
    taxRate,
    additionalTaxRates,
    hasTransitionalAssessedValue,
    assessedValueTable,
    additionalSpecialAssessments,
  } = formValues

  const incomeTotals = getIncomeTotals(state)
  const renovationsTotals = getRenovationsTotals(state)
  const basis = get(state, 'report.reportData.incomeApproach.taxInformation.basis', 'PSF')
  const projected = get(state, 'report.reportData.incomeApproach.taxInformation.projected', {})

  /* Get and set this value to make the default value
  whatever the basis that's been selected is (either 'Per Unit' or 'PSF')*/

  const concludedLiabilityType = get(projected, 'concludedLiabilityType', basis)
  const propertySummary = get(state, 'report.reportData.propertyInformation.propertySummary')

  const locationIdentifier = get(state, 'report.reportData.locationIdentifier')

  const { residentialUnitCount, basisForSFAnalysis } = propertySummary

  const reportInformation = selectReportInformation(state)

  const { taxItems } = parseTaxableAssessedValueTable(assessedValueTable)

  const taxInformation = calculateTaxInformation(
    taxItems,
    taxRate,
    hasTransitionalAssessedValue,
    additionalTaxRates,
    additionalSpecialAssessments
  )

  return {
    initialValues: {
      ...formValues,
      basis,
      projected: {
        ...projected,
        concludedLiabilityType,
      },
      incomeTotals,
      renovationsTotals,
      residentialUnitCount,
      subjectInfo: {
        address,
        state: subjectState,
        city,
        county,
        borough,
        block,
        lot,
        qualifier,
        isOther,
        propertyIdentifier: propertySummary.propertyIdentifier,
        propertyIdentifierType: propertySummary.propertyIdentifierType,
        residentialUnitCount: propertySummary.residentialUnitCount,
        commercialUnitCount: propertySummary.commercialUnitCount || 0,
        propertyType: propertySummary.propertyType,
        locationIdentifier,
      },
      ...taxInformation,
      valueConclusionType,
      concludedCapRate,
      totalExpensesNetTaxes,
      saleValueConclusionInformation,
    },
    isNYCReport: reportInformation.isNYCReport,
    basisForSFAnalysis,
  }
})(withStyles(styles)(TaxInformation))
