import BoweryDate from '@bowery-valuation/bowery-date'
import { concat, get, sumBy, filter } from 'lodash'

import { LOSS_ITEM_KEYS, RENOVATIONS_TYPE, RENOVATIONS_COST_TYPE, RENT_LOSS_TYPE } from '../../../constants/acas'

import { divide } from '../../../utils/numberOperations'
import IncomeApproachCalculations from '../income-approach-calculations'

import { DEFAULT_FINAL_DATES_OF_VALUE, DEFAULT_FINAL_VALUES } from './helpers'
import {
  DatesOfFinalValue,
  FinalValues,
  NpvAdjustment,
  LossItem,
  Renovation,
  DatesOfValueInputs,
  ValueConclusionInputs,
  DiscountRates,
  DiscountRatesInputs,
} from './types'

export default {
  calculateFinalValues({
    indicatedValue,
    npvAdjustments,
    asStabilizedNpvAdjustments,
    asCompleteNpvAdjustments,
    asStabilizedResRentLossItems,
    asCompleteResRentLossItems,
    asStabilizedCommercialRentLossItems,
    asCompleteCommercialRentLossItems,
    asStabilizedLossItems,
    asCompleteLossItems,
    roundingFactor,
    squareFootage,
    unitCount,
    includeValueAsComplete,
    includeValueAsStabilized,
    asCompleteDiscountRate,
    asStabilizedDiscountRate,
  }: ValueConclusionInputs): FinalValues {
    if (!indicatedValue) {
      return DEFAULT_FINAL_VALUES
    }
    const totalNpvAdjustment = this.calculateTotalNpvAdjustments(npvAdjustments)
    const finalValueAsStabilized = indicatedValue + totalNpvAdjustment
    const asStabilizedTotalLossItems =
      concat(asStabilizedLossItems, asStabilizedResRentLossItems, asStabilizedCommercialRentLossItems) || []
    const asStabilizedMarketValueWithDiscount = asStabilizedDiscountRate
      ? finalValueAsStabilized * asStabilizedDiscountRate
      : 0
    const finalValueAsComplete = includeValueAsStabilized
      ? (asStabilizedMarketValueWithDiscount || finalValueAsStabilized) -
        this.calculateCostOfLossItemsAndAdjustments(asStabilizedTotalLossItems, asStabilizedNpvAdjustments)
      : finalValueAsStabilized

    const asCompleteTotalLossItems =
      concat(asCompleteLossItems, asCompleteResRentLossItems, asCompleteCommercialRentLossItems) || []
    const asCompleteMarketValueWithDiscount = asCompleteDiscountRate ? finalValueAsComplete * asCompleteDiscountRate : 0
    const finalValueAsIs = includeValueAsComplete
      ? (asCompleteMarketValueWithDiscount || finalValueAsComplete) -
        this.calculateCostOfLossItemsAndAdjustments(asCompleteTotalLossItems, asCompleteNpvAdjustments)
      : finalValueAsComplete

    return {
      indicatedValue: this.calculateTotalPsfPerUnitAndRoundedValues(
        indicatedValue,
        squareFootage,
        unitCount,
        roundingFactor
      ),
      finalValueAsComplete: this.calculateTotalPsfPerUnitAndRoundedValues(
        finalValueAsComplete,
        squareFootage,
        unitCount,
        roundingFactor
      ),
      finalValueAsStabilized: this.calculateTotalPsfPerUnitAndRoundedValues(
        finalValueAsStabilized,
        squareFootage,
        unitCount,
        roundingFactor
      ),
      finalValueAsIs: this.calculateTotalPsfPerUnitAndRoundedValues(
        finalValueAsIs,
        squareFootage,
        unitCount,
        roundingFactor
      ),
    }
  },
  calculateCostOfLossItemsAndAdjustments(lossItems: LossItem[] = [], npvAdjustments: NpvAdjustment[] = []) {
    let entrepreneurialProfit = 0
    const totalLossItems = lossItems
      ? lossItems.reduce((sum, lossItem) => {
          const { amount, name } = lossItem || {}
          if (name === LOSS_ITEM_KEYS.ENTREPRENEURIAL_PROFIT) {
            entrepreneurialProfit = lossItem.amount
            return sum
          }
          if (amount) {
            return sum + amount
          }
          return sum
        }, 0)
      : 0
    let positiveAdjustments = 0
    const totalNpvAdjustments = npvAdjustments.reduce((sum, npvAdjustment) => {
      const { value } = npvAdjustment
      if (!value) {
        return sum
      }
      if (value >= 0) {
        positiveAdjustments += value
        return sum
      }
      // @ts-ignore
      return sum + Math.abs(value)
    }, 0)
    // @ts-ignore
    const totalDeductions = totalLossItems + totalNpvAdjustments
    const entrepreneurialProfitAmount = totalDeductions * entrepreneurialProfit

    return totalDeductions + entrepreneurialProfitAmount - positiveAdjustments
  },
  calculateTotalNpvAdjustments(npvAdjustments: NpvAdjustment[]) {
    if (!npvAdjustments || !npvAdjustments.length) {
      return 0
    }
    const totalNpvAdjustment = npvAdjustments.reduce((sum, npvAdjustment) => {
      const { value } = npvAdjustment
      return sum + (value || 0)
    }, 0)
    return totalNpvAdjustment
  },
  calculateDatesOfFinalValue({
    dateOfValuation,
    asCompleteMonthsOfRentLoss,
    asStabilizedMonthsOfRentLoss,
  }: DatesOfValueInputs): DatesOfFinalValue {
    if (!dateOfValuation) {
      return {
        ...DEFAULT_FINAL_DATES_OF_VALUE,
        dateOfValuation,
      }
    }
    const dateOfFinalValueAsIs = new BoweryDate(dateOfValuation)
    const dateOfFinalValueAsComplete = dateOfFinalValueAsIs.addMonthsToDate(asCompleteMonthsOfRentLoss)
    const dateOfFinalValueAsStabilized = dateOfFinalValueAsComplete.addMonthsToDate(asStabilizedMonthsOfRentLoss)

    return {
      dateOfFinalValueAsIs: dateOfFinalValueAsIs.formatShortDate(),
      dateOfFinalValueAsComplete: dateOfFinalValueAsComplete.formatShortDate(),
      dateOfFinalValueAsStabilized: dateOfFinalValueAsStabilized.formatShortDate(),
      dateOfValuation,
    }
  },
  calculateTotalPsfPerUnitAndRoundedValues(
    finalValue: number,
    squareFootage: number | undefined,
    numberOfResidentialUnits: number | undefined,
    roundingFactor: number
  ) {
    if (!finalValue) {
      return {
        total: 0,
        rounded: 0,
        perUnit: 0,
        psf: 0,
      }
    }
    const finalValueRounded = (Math.round(finalValue / roundingFactor) || 1) * roundingFactor
    return {
      total: finalValue,
      rounded: finalValueRounded,
      perUnit: divide(finalValueRounded, numberOfResidentialUnits),
      psf: divide(finalValueRounded, squareFootage),
    }
  },
  calculateTotalDeductions(lossItems: LossItem[], npvAdjustments: NpvAdjustment[]) {
    const lossItemsWithoutEntreprenueiralProfit = lossItems
      ? filter(lossItems, item => item.name !== LOSS_ITEM_KEYS.ENTREPRENEURIAL_PROFIT)
      : []
    const negativeAdjustments = filter(npvAdjustments, adjustment => adjustment.value < 0)

    return this.calculateCostOfLossItemsAndAdjustments(lossItemsWithoutEntreprenueiralProfit, negativeAdjustments)
  },
  calculateTotalRenovationBudget(renovations: Renovation): number {
    const typeOfRenovation = get(renovations, 'prospectiveRenovationType') || RENOVATIONS_TYPE.NO_RENOVATIONS
    if (typeOfRenovation === RENOVATIONS_TYPE.NO_RENOVATIONS) {
      return 0
    }

    const renovationCostsType = get(renovations, 'renovationCostsType') || RENOVATIONS_COST_TYPE.TOTAL
    if (renovationCostsType === RENOVATIONS_COST_TYPE.ITEMIZED) {
      return get(renovations, 'itemized.total.netRenovationBudget') || 0
    }

    return get(renovations, 'total.total.netRenovationBudget') || 0
  },
  addMonthsToDate(date: BoweryDate | string | Date, monthsToAdd: number): any {
    const boweryDate = new BoweryDate(date)
    if (!boweryDate.isValidDate() || !!date) {
      return null
    }
    if (!monthsToAdd) {
      return boweryDate
    }
    const result = boweryDate.addMonthsToDate(monthsToAdd)
    return result
  },
  calculateMonthlyRentLoss(residenitalOrCommercial: number, units: any, vcLossPercentage: number) {
    const lossPercentage = 1 - (vcLossPercentage || 0)
    if (residenitalOrCommercial === RENT_LOSS_TYPE.RESIDENTIAL) {
      return sumBy(units, 'rent') * lossPercentage
    }
    if (residenitalOrCommercial === RENT_LOSS_TYPE.COMMERCIAL) {
      return sumBy(units, 'monthlyRent') * lossPercentage
    }
    return 0
  },
  calculateDiscountedRates({
    asStabilizedDiscountPercentage,
    asStabilizedMonthsDiscounted,
    asCompleteDiscountPercentage,
    asCompleteMonthsDiscounted,
  }: DiscountRatesInputs): DiscountRates {
    const asStabilizedDiscountRate = IncomeApproachCalculations.calculateDiscountRate(
      asStabilizedDiscountPercentage,
      asStabilizedMonthsDiscounted
    )
    const asCompleteDiscountRate = IncomeApproachCalculations.calculateDiscountRate(
      asCompleteDiscountPercentage,
      asCompleteMonthsDiscounted
    )
    return {
      asStabilizedDiscountRate,
      asCompleteDiscountRate,
    }
  },
}
