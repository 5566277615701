import { routerMiddleware } from 'react-router-redux'
import { createBrowserHistory } from 'history'
import { applyMiddleware, createStore } from 'redux'
import { createMiddleware as createBeaconMiddleware } from 'redux-beacon'
import { composeWithDevTools } from 'redux-devtools-extension'
import createSagaMiddleware from 'redux-saga'

import { dispatchAnalyticsEvent } from '../analytics'
import eventMap from '../analytics/eventMap'
import { navigationConfirmation } from '../report/redux/actions/report'

import reducer from './reducer'
import sagas from './sagas'
import { initHistory } from './history'

let store = null

export function initStore() {
  const isDev = process.env.NODE_ENV !== 'production'

  const history = createBrowserHistory({
    getUserConfirmation: (message, callback) => {
      dispatch(navigationConfirmation({ callback }))
    },
  })
  initHistory(history)
  const sagaMiddleware = createSagaMiddleware()

  let middleware
  if (global.env.analyticsEnabled) {
    const analyticsMiddleware = createBeaconMiddleware(eventMap, dispatchAnalyticsEvent)
    middleware = applyMiddleware(routerMiddleware(history), sagaMiddleware, analyticsMiddleware)
  } else {
    middleware = applyMiddleware(routerMiddleware(history), sagaMiddleware)
  }

  store = createStore(reducer, isDev ? composeWithDevTools(middleware) : middleware)

  sagaMiddleware.run(sagas)
  return store
}

export function getStore() {
  return store
}

export function dispatch(action) {
  return store.dispatch(action)
}

export function getState() {
  return store.getState()
}
