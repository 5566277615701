import React from 'react'
import PropTypes from 'prop-types'
import { get } from 'lodash'

import { Divider, Grid } from '@material-ui/core'

import { PROPERTY_SEARCH_STRATEGY, GEOGRAPHY_OPTIONS, SEARCH_OPTIONS, LABELS } from '../../../../constants/properties'
import { DropDown } from '../../../DropDown'
import Text from '../../../Text'
import { BOROUGHS_FULL_NAME } from '../../../../constants/borough'
import { required } from '../../../../utils/validation'
import { arrayToKeyValuePairs } from '../../../../utils/ui/checkboxHelper'
import withSearch from '../withSearch'
import StepFooter from '../../StepFooter'

const boroughOptions = arrayToKeyValuePairs(BOROUGHS_FULL_NAME, true)

export class AdvancedSearchNYC extends React.PureComponent {
  static propTypes = {
    searchProperties: PropTypes.func.isRequired,
    openBasicSearch: PropTypes.func.isRequired,
    invalid: PropTypes.bool.isRequired,
  }

  static buildQuery = values => ({
    locationIdentifier: GEOGRAPHY_OPTIONS.NY,
    locationType: SEARCH_OPTIONS.BBL,
    strategy: PROPERTY_SEARCH_STRATEGY.BBL,
    city: get(values, 'search.borough'),
    block: get(values, 'search.block'),
    lot: get(values, 'search.lot'),
  })

  render() {
    const { searchProperties, openBasicSearch, invalid } = this.props
    return (
      <React.Fragment>
        <Divider light />
        <br />
        <Grid container spacing={8}>
          <Grid item xs={12}>
            <DropDown validate={required} required label="Borough" name="search.borough" items={boroughOptions} />
          </Grid>
          <Grid item xs={6}>
            <Text data-qa="block-text-input" validate={required} required label="Block" name="search.block" />
          </Grid>
          <Grid item xs={6}>
            <Text data-qa="lot-text-input" validate={required} required label="Lot" name="search.lot" />
          </Grid>
        </Grid>
        <StepFooter
          previous={{
            text: LABELS.STANDARD_SEARCH,
            onClick: openBasicSearch,
          }}
          next={{ text: LABELS.SEARCH, onClick: searchProperties, isDisabled: invalid }}
        />
      </React.Fragment>
    )
  }
}

export default withSearch(AdvancedSearchNYC)
