import arrayToSentence from 'array-to-sentence'
import { forIn, lowerCase, capitalize } from 'lodash'

import { getExpense } from 'shared/utils/textGeneration/incomeApproach/expenses'

export const getTenantObligationsDiscussion = values => {
  const { expenseStructure = {}, expenseStructureByRentTypes = {} } = values

  const tenantExpenses = []
  let bothExpenses = ''
  forIn(expenseStructure, (value, key) => {
    if (key !== 'rentTypes') {
      if (value === 'tenant') {
        tenantExpenses.push(getExpense(key))
      }
      if (value === 'varies by rent type') {
        forIn(expenseStructureByRentTypes, (item, rentType) => {
          if (item[key] && item[key] === 'tenant') {
            if (['electricity', 'gas', 'commonElectricity'].includes(key)) {
              bothExpenses += `Individually metered ${getExpense(key)} for ${lowerCase(rentType)} units. `
            } else {
              bothExpenses += `${capitalize(
                lowerCase(rentType)
              )} tenants are also responsible for their own ${getExpense(key)}. `
            }
          }
        })
      }
    }
  })
  const result = capitalize(arrayToSentence(tenantExpenses))
  return `${result}${result ? '. ' : ''}${bothExpenses}`.replace('water and sewer', 'water/sewer')
}

export const getOwnerObligationsDiscussion = values => {
  const { expenseStructure = {}, expenseStructureByRentTypes = {} } = values

  const ownerExpenses = []
  let bothExpenses = ''
  forIn(expenseStructure, (value, key) => {
    if (key !== 'rentTypes') {
      if (value === 'owner') {
        ownerExpenses.push(getExpense(key))
      }
      if (value === 'varies by rent type') {
        forIn(expenseStructureByRentTypes, (item, rentType) => {
          if (item[key] && item[key] === 'owner') {
            bothExpenses += `The owner is also responsible for ${lowerCase(rentType)} units' ${getExpense(key)}. `
          }
        })
      }
    }
  })
  const result = capitalize(arrayToSentence(ownerExpenses))
  return `${result}${result ? '. ' : ''}${bothExpenses}`.replace('water and sewer', 'water/sewer')
}
