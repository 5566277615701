const styles = theme => ({
  leadCell: {
    padding: '4px 0px 4px 6px',
  },
  tableCell: {
    padding: '4px 12px',
  },
  selectCompCell: {
    '&:last-child': {
      padding: '4px 16px 4px 0',
    },
  },
  table: {
    border: `1px solid ${theme.palette.grey[300]}`,
    borderRadius: theme.spacing.unit,
    marginBottom: theme.spacing.unit * 2,
  },
  row: {
    '&:hover': {
      cursor: 'pointer',
      boxShadow: theme.shadows[5],
    },
  },
  compRow: {
    '&:hover': {
      cursor: 'default',
    },
  },
  selectedRow: {
    backgroundColor: theme.palette.primary[50],
  },
  notFoundContainer: {
    marginBottom: 40,
  },
  link: {
    cursor: 'pointer',
    userSelect: 'none',
    fontSize: 12,
  },
})

export default styles
