import { get, isNumber } from 'lodash'

import { IncomeAdjustmentKeys } from 'shared/constants/report/sales/salesAdjustment'
import { getSalePriceToUse, getUnitOfComparisonText } from 'shared/report-calculations/sales-approach/helpers'
import SalesApproachCalculations from 'shared/report-calculations/sales-approach/sales-approach-calculations'
import { RentTypes } from 'shared/constants/buildingComparables'
import { ADJUSTMENT_GROUPS } from 'shared/helpers/salesApproach/salesCompAdjustmentConstants'
import { formatPercentageString } from 'shared/utils/formatters/numberFormatters'
import { mapDataModelForAdjustmentsNarrative } from 'shared/utils/textGeneration/salesApproach'

import SalesAdjustmentService from '../../../../../libs/bowery-libs/services/sales-adjustment'

const salesAdjustmentService = new SalesAdjustmentService()

const mapData = ({
  incomeAdjustmentLevel,
  temper,
  unitOfComparison,
  netOperatingIncome,
  selectedComps,
  subjectPropertyInformation,
  units,
  compAdjustments,
}) => {
  const noiAdjustments = selectedComps.map(salesComp =>
    salesAdjustmentService.getNoiAdjustment(
      salesComp.saleInformation.capRate,
      getSalePriceToUse(salesComp.saleInformation),
      salesComp.propertyInformation,
      unitOfComparison,
      temper,
      netOperatingIncome.total,
      subjectPropertyInformation
    )
  )
  const temperPercentage = formatPercentageString(temper)
  const netOperatingIncomeValue = SalesApproachCalculations.calculateNetOperatingIncomePerBasis(
    netOperatingIncome.total,
    unitOfComparison,
    subjectPropertyInformation
  )
  const type = getUnitOfComparisonText(unitOfComparison)
  const upwardNoiComps = []
  const downwardNoiComps = []
  noiAdjustments.forEach(adjustmentValue => {
    if (isNumber(adjustmentValue)) {
      if (adjustmentValue > 0) {
        upwardNoiComps.push(adjustmentValue)
      } else if (adjustmentValue < 0) {
        downwardNoiComps.push(adjustmentValue)
      }
    }
  })

  const residentialUnitCount = units.length
  const rentStabilizedUnits = units.filter(item => item.rentType === RentTypes.RENT_STABILIZED)
  const hasStabilizedUnits = rentStabilizedUnits.length !== 0
  const stabilizationLevelPercents = formatPercentageString(rentStabilizedUnits.length / residentialUnitCount, 0)

  const adjustmentData = mapDataModelForAdjustmentsNarrative(
    { compAdjustments, selectedComps },
    'stabilizationLevel',
    ADJUSTMENT_GROUPS.OTHER
  )
  return {
    rentStabilizationDiscussion: incomeAdjustmentLevel === IncomeAdjustmentKeys.rentStabilization,
    incomeLevelDiscussion: incomeAdjustmentLevel === IncomeAdjustmentKeys.income,
    temperPercentage,
    netOperatingIncome: netOperatingIncomeValue.toFixed(2),
    type,
    hasNoiAdjustments: !!noiAdjustments.length,
    hasUpwardNoiAdjustments: upwardNoiComps.length > 0,
    hasDownwardNoiAdjustments: downwardNoiComps.length > 0,
    stabilizationLevelPercents,
    hasNoStabilizedUnits: !hasStabilizedUnits,
    containsOneResidentialUnit: residentialUnitCount === 1,
    allRentStabilizedUnits: rentStabilizedUnits.length === units.length,
    numberOfRentStabilizedUnits: rentStabilizedUnits.length,
    rentStabilizedUnitsArticle: rentStabilizedUnits.length > 1 ? 'are' : 'is',
    residentialUnitCount,
    ...adjustmentData,
  }
}

export const mapDTO = formValues => {
  return mapData(formValues)
}

export const mapDataModel = (report, parent) => {
  if (!parent.parent()) {
    return undefined
  }

  const salesApproach = parent.parent()
  const netOperatingIncome = get(report, 'netOperatingIncome', {})
  const { salesComps = {}, salesCompsAdjustments = {}, unitOfComparison = 'sf' } = salesApproach
  const { selectedComps = [] } = salesComps
  const { compAdjustments = [], temper, incomeAdjustmentLevel } = salesCompsAdjustments
  const units = get(report, 'residential.units', [])

  const propertyInformation = get(report, 'property_information', {})
  const zoning = get(report, 'zoning', {})
  const { grossBuildingArea, comUnits, resUnits, lotArea, siteAreaMeasure } = propertyInformation
  const { buildableUnits } = zoning
  const floorAreaRatio = get(zoning, 'floor_area_ratio', 0)
  const buildableArea = lotArea * floorAreaRatio
  const subjectPropertyInformation = {
    grossBuildingArea,
    residentialUnits: resUnits,
    commercialUnits: comUnits,
    buildableUnits,
    siteArea: lotArea,
    siteAreaUnit: siteAreaMeasure,
    buildableArea,
  }

  return mapData({
    incomeAdjustmentLevel,
    temper,
    unitOfComparison,
    netOperatingIncome,
    selectedComps,
    subjectPropertyInformation,
    units,
    compAdjustments,
  })
}
