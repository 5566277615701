import { RentTypesUnitsOfMeasure } from '.'

export const COMMERCIAL_UNIT_RENT_FIELD_NAMES = {
  [RentTypesUnitsOfMeasure.PER_SF_PER_YEAR]: 'annualRentPsf',
  [RentTypesUnitsOfMeasure.PER_SF_PER_MONTH]: 'monthlyRentPsf',
  [RentTypesUnitsOfMeasure.MONTHLY]: 'monthlyRent',
  [RentTypesUnitsOfMeasure.ANNUAL]: 'annualRent',
}

export const LeaseTerms = {
  MODIFIED_GROSS: 'modified gross',
  GROSS: 'gross',
  TRIPLE_NET: 'triple net',
}

export const LeaseTermsOptions = [
  { label: 'Gross', value: LeaseTerms.GROSS },
  { label: 'Modified Gross', value: LeaseTerms.MODIFIED_GROSS },
  { label: 'Triple Net', value: LeaseTerms.TRIPLE_NET },
]
