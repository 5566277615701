import {
  PREVIEW_AND_EDIT_KEY,
  COVER_PAGE_KEY,
  LETTER_OF_TRANSMITTAL_KEY,
  INTRODUCTION_KEY,
  CERTIFICATION_KEY,
  GLOSSARY_OF_TERMS_KEY,
} from 'shared/constants/report/keysAndDataPaths'

import CoverPage from '../../../forms/previewAndEdit/CoverPage'
import Introduction from '../../../forms/previewAndEdit/Introduction'
import Certification from '../../../forms/previewAndEdit/Certification'
import GlossaryOfTerms from '../../../forms/previewAndEdit/GlossaryOfTerms'
import LetterOfTransmittal from '../../../forms/previewAndEdit/LetterOfTransmittal'

export default [
  {
    key: PREVIEW_AND_EDIT_KEY,
    name: 'Preview & Edit',
  },
  {
    key: COVER_PAGE_KEY,
    name: 'Cover Page',
    formComponent: CoverPage,
  },
  {
    key: LETTER_OF_TRANSMITTAL_KEY,
    name: 'Letter of Transmittal',
    formComponent: LetterOfTransmittal,
  },
  {
    key: INTRODUCTION_KEY,
    name: 'Introduction',
    formComponent: Introduction,
  },
  {
    key: CERTIFICATION_KEY,
    name: 'Certification',
    formComponent: Certification,
  },
  {
    key: GLOSSARY_OF_TERMS_KEY,
    name: 'Glossary of Terms',
    formComponent: GlossaryOfTerms,
  },
]
