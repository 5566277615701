/**
 * Overriding styles for Link
 */

const root = ({ theme: { palette } }) => ({
  fontFamily: 'Nunito Sans',
  color: palette.primary.main,
})

export default {
  styleOverrides: {
    root,
  },
}
