import React from 'react'
import { Value } from 'slate'
import { Plugin } from 'slate-react'

import { EVENT_HANDLED, UPDATE_INLINE_NODES_COMMAND } from '../constants'

import { updateLockedSentenceInlines } from '../../../../../../../shared/utils/narrative/updateNarrative'

import LockedSentence from './LockedSentence'

export default {
  onCommand(command, editor, next) {
    const { type, args } = command
    if (type === UPDATE_INLINE_NODES_COMMAND) {
      const [data] = args
      const updatedValue = updateLockedSentenceInlines(editor.value, data)
      editor.controller.setValue(updatedValue)
    }
    return next()
  },
  renderInline(props, editor, next) {
    const { attributes, node, readOnly, children } = props
    if (node.type !== 'lockedSentence') {
      return next()
    }

    return <LockedSentence attributes={{ ...attributes, readOnly }}>{children}</LockedSentence>
  },
  onKeyDown(event, editor, next) {
    if (editor.previousInlineLocked() && event.key === 'ArrowLeft') {
      event.preventDefault()
      editor.moveFocusBeforeInline()
      return EVENT_HANDLED
    }
    if (editor.nextInlineLocked() && event.key === 'ArrowRight') {
      event.preventDefault()
      editor.moveFocusAfterInline()
      return EVENT_HANDLED
    }
    return next()
  },
  commands: {
    moveFocusBeforeInline(editor) {
      const inline = editor.value.focusBlock.getPreviousText(editor.value.selection.focus.key)
      // @ts-ignore
      const nextTextNode = editor.value.focusBlock.getPreviousText(inline.key)
      // @ts-ignore
      editor.moveToEndOfNode(nextTextNode)
      return editor
    },
    moveFocusAfterInline(editor) {
      const inline = editor.value.focusBlock.getNextText(editor.value.selection.focus.key)
      // @ts-ignore
      const nextTextNode = editor.value.focusBlock.getNextText(inline.key)
      // @ts-ignore
      editor.moveToStartOfNode(nextTextNode)
      return editor
    },
    setEditorValueFromJS(editor, value) {
      editor.controller.setValue(Value.create(value), { normalize: true })
      return editor
    },
    setValue(editor, value) {
      editor.controller.setValue(value)
      return editor
    },
  },
} as Plugin

declare module 'slate-react' {
  interface Editor {
    moveFocusBeforeInline(): Editor
    moveFocusAfterInline(): Editor
    setEditorValueFromJS(value: Value): Editor
    setValue(value: Value): Editor
  }
}
