import React, { useState, useEffect } from 'react'
import { Box, FormControl, FormHelperText, FormLabel, RadioGroup } from '@mui/material'

import RadioListItem from './RadioListItem'

const RadioList = ({
  label,
  items,
  horizontal,
  error,
  helperText,
  value,
  defaultValue,
  onChange,
  onFocus,
  onBlur,
  renderAtEnd,
  required,
  disabled,
}) => {
  const initialValue = value || defaultValue
  const [currentValue, setCurrentValue] = useState(initialValue)

  useEffect(() => {
    if (initialValue !== currentValue) {
      setCurrentValue(initialValue)
    }
  }, [currentValue, initialValue])

  const handleChange = (event, value) => {
    setCurrentValue(value)
    onChange(value)
  }

  return (
    <FormControl component="fieldset" disabled={disabled} error={error} sx={{ display: 'block' }}>
      {label && <FormLabel required={required}>{label}</FormLabel>}
      <RadioGroup row={horizontal} onChange={handleChange} onFocus={onFocus} onBlur={onBlur} sx={{ gap: 1 }}>
        {items.map(({ label: itemLabel, value: itemValue }) => {
          const checked = itemValue === currentValue

          return (
            <Box key={itemValue}>
              <RadioListItem checked={checked} label={itemLabel} value={itemValue} />
              {renderAtEnd && <span>{renderAtEnd(itemValue, checked, currentValue)}</span>}
            </Box>
          )
        })}
      </RadioGroup>
      <FormHelperText>{helperText}</FormHelperText>
    </FormControl>
  )
}

export default RadioList
