import React from 'react'
import PropTypes from 'prop-types'
import { Alert, Box } from '@mui/material'
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline'

import VerifiedCallout from '../VerifiedCallout'

import NarrativeComponent from './NarrativeComponent'

const NarrativeComponentWithAlert = props => {
  const { showSuccessAlert, showErrorAlert, successAlertText, errorAlertText, isLoading, ...narrativeProps } = props
  return (
    <Box>
      {showErrorAlert && (
        <Alert
          severity="error"
          icon={<ErrorOutlineIcon color="error" />}
          sx={{ width: 'fit-content', display: 'inline-flex', marginLeft: 1 }}
        >
          {errorAlertText}
        </Alert>
      )}

      {showSuccessAlert && <VerifiedCallout>{successAlertText}</VerifiedCallout>}
      <NarrativeComponent {...narrativeProps} />
    </Box>
  )
}

NarrativeComponentWithAlert.propTypes = {
  classes: PropTypes.object.isRequired,
  title: PropTypes.string.isRequired,
  generatedText: PropTypes.func.isRequired,
  data: PropTypes.object,
  form: PropTypes.object.isRequired,
  name: PropTypes.string,
  regenerateOnChange: PropTypes.arrayOf(PropTypes.string),
  tooltipText: PropTypes.string,
  className: PropTypes.string,
  successAlertText: PropTypes.string,
  errorAlertText: PropTypes.string,
  showSuccessAlert: PropTypes.bool,
  showErrorAlert: PropTypes.bool,
  isLoading: PropTypes.bool,
}

NarrativeComponentWithAlert.defaultProps = {
  data: {},
  successAlertText: null,
  errorAlertText: null,
  showSuccessAlert: false,
  showErrorAlert: false,
  isLoading: false,
}

export default NarrativeComponentWithAlert
