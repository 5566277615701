import React from 'react'

import { get } from 'lodash'

import AddCircle from '@mui/icons-material/AddCircle'
import Cancel from '@mui/icons-material/Cancel'
import Edit from '@mui/icons-material/Edit'
import RemoveCircle from '@mui/icons-material/RemoveCircle'
import { IconButton, Stack, Tooltip } from '@mui/material'

import { CheckboxWithLabel, Number as NumberInput, Text } from 'client-shared/components'

export const getCheckboxCellContent = (column, checkboxProps) => {
  const {
    compKey,
    comp,
    column: { name },
  } = column

  return <CheckboxWithLabel checked={get(comp, name)} name={`${compKey}.${name}`} {...checkboxProps} />
}

export const getNumberCellContent = (column, classes, numberProps) => {
  const {
    compKey,
    column: { name },
  } = column

  return (
    <NumberInput
      name={`${compKey}.${name}`}
      className={classes.cellInput}
      classes={{ formControl: classes.compInput }}
      {...numberProps}
    />
  )
}

export const getTextCellContent = (column, classes, textProps) => {
  const {
    compKey,
    column: { name },
  } = column

  return (
    <Text
      name={`${compKey}.${name}`}
      className={classes.cellInput}
      classes={{ formControl: classes.compInput }}
      {...textProps}
    />
  )
}

export const getCompActions = (column, _classes, handleEdit, handleDelete) => {
  const { order } = column

  return (
    <Stack alignItems="center" direction="row">
      <IconButton onClick={() => handleEdit(order - 1)} size="small">
        <Tooltip placement="top" title="Edit">
          <Edit color="info" fontSize="large" />
        </Tooltip>
      </IconButton>
      <IconButton onClick={() => handleDelete(order - 1)} size="small">
        <Tooltip placement="top" title="Delete">
          <Cancel color="error" fontSize="large" />
        </Tooltip>
      </IconButton>
    </Stack>
  )
}

export const getRemovedCompActions = (column, _classes, handleAdd, handleRemove) => {
  const { order } = column

  return (
    <Stack alignItems="center" direction="row">
      <IconButton aria-label="Add" onClick={() => handleAdd(order - 1)} size="small">
        <AddCircle color="success" fontSize="large" />
      </IconButton>
      <IconButton aria-label="Remove" onClick={() => handleRemove(order - 1)} size="small">
        <RemoveCircle color="error" fontSize="large" />
      </IconButton>
    </Stack>
  )
}
