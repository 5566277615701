import {
  FINAL_KEY,
  FINAL_VALUES_AND_RECONCILIATION_KEY,
  PROPERTY_SALES_CONCLUSION_KEY,
  ASSUMPTIONS_AND_CONDITIONS_KEY,
  SWOT_ANALYSIS_KEY,
  HIGHEST_AND_BEST_USE_KEY,
  UNIT_INSPECTION_KEY,
  APPRAISAL_SCOPE_KEY,
  SOURCE_INFO_KEY,
  CAP_RATE_COMPS_KEY,
  CAP_RATE_DISCUSSION_KEY,
} from 'shared/constants/report/keysAndDataPaths'

import FinalValuesAndReconciliation from '../../../forms/final/FinalValuesAndReconciliation'
import PropertySalesConclusionContainer from '../../../forms/final/PropertySalesConclusion'
import HighestAndBestUse from '../../../forms/final/HighestAndBestUse'
import CapRateComps from '../../../forms/final/CapRateComps'
import CapRateDiscussion from '../../../forms/final/CapRateDiscussion'
import SwotAnalysis from '../../../forms/final/SwotAnalysis'
import AppraisalScope from '../../../forms/final/AppraisalScope'
import UnitInspection from '../../../forms/final/UnitInspection'
import SourceInfo from '../../../forms/final/SourceInfo'
import AssumptionsConditions from '../../../forms/final/AssumptionsConditions'

export default [
  {
    key: FINAL_KEY,
    name: 'Final',
  },

  {
    key: FINAL_VALUES_AND_RECONCILIATION_KEY,
    name: 'Final Values & Reconciliation',
    formComponent: FinalValuesAndReconciliation,
  },
  {
    key: PROPERTY_SALES_CONCLUSION_KEY,
    name: 'Property Sales Conclusion',
    formComponent: PropertySalesConclusionContainer,
  },
  {
    key: ASSUMPTIONS_AND_CONDITIONS_KEY,
    name: 'Assumptions & Conditions',
    formComponent: AssumptionsConditions,
  },
  {
    key: SWOT_ANALYSIS_KEY,
    name: 'SWOT Analysis',
    formComponent: SwotAnalysis,
  },
  {
    key: HIGHEST_AND_BEST_USE_KEY,
    name: 'Highest & Best Use',
    formComponent: HighestAndBestUse,
  },
  {
    key: UNIT_INSPECTION_KEY,
    name: 'Unit Inspection',
    formComponent: UnitInspection,
  },
  {
    key: APPRAISAL_SCOPE_KEY,
    name: 'Scope',
    formComponent: AppraisalScope,
  },
  {
    key: SOURCE_INFO_KEY,
    name: 'Source Information',
    formComponent: SourceInfo,
  },
  {
    key: CAP_RATE_COMPS_KEY,
    name: 'Cap Rate Comps',
    formComponent: CapRateComps,
  },
  {
    key: CAP_RATE_DISCUSSION_KEY,
    name: 'Cap Rate Discussion',
    formComponent: CapRateDiscussion,
  },
]
