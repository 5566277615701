// taken from https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Math/round
import { isNil, isNumber } from 'lodash'
export const precisionRound = (number, precision) => {
  const factor = Math.pow(10, precision)
  return Math.round(number * factor) / factor
}

export const roundToNearestFive = number => {
  return Math.ceil(number / 5) * 5
}

export const toPercents = (value, decimalPlaces = 2) => {
  if (isNil(value) || value === '') {
    return null
  }

  return precisionRound(value * 100, decimalPlaces)
}

export const fromPercents = value => {
  if (isNil(value) || value === '') {
    return null
  }

  return value / 100
}

export const fromCurrency = value => {
  if (isNil(value) || value === '') {
    return 0
  }
  if (isNumber(value)) {
    return value
  }

  return Number(value.replace(/[^0-9.-]+/g, '')) || 0
}
