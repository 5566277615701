import { get } from 'lodash'

import { DEFAULT_SF_ANALYSIS_BASIS, SF_BASIS_OPTIONS } from 'shared/constants/report'

type BasisForSFAnalysis = keyof typeof SF_BASIS_OPTIONS

type FormValues = {
  showPerUnitSF: boolean
  basisForSFAnalysis: string
  grossLeasableAreaPercentage: number
}

type Report = {
  // eslint-disable-next-line camelcase
  property_information: {
    basisForSFAnalysis: string
  }
  residential: {
    exportOptions: {
      perUnitSF: boolean
    }
  }
  grossLeasableAreaPercentage: number
}

type NarrativeValues = {
  showPerUnitSF: boolean
  usingDefaultBasisForSFAnalysis: boolean
  basisForSFAnalysis: string
  lossFactor: number
}

export const mapDTO = (formValues: FormValues): NarrativeValues => {
  const { showPerUnitSF, basisForSFAnalysis, grossLeasableAreaPercentage } = formValues
  const usingDefaultBasisForSFAnalysis = basisForSFAnalysis === DEFAULT_SF_ANALYSIS_BASIS
  const lossFactor = 1 - grossLeasableAreaPercentage

  return {
    showPerUnitSF,
    basisForSFAnalysis: SF_BASIS_OPTIONS[basisForSFAnalysis as BasisForSFAnalysis],
    usingDefaultBasisForSFAnalysis,
    lossFactor,
  }
}

export const mapDataModel = (report: Report): NarrativeValues => {
  const showPerUnitSF = get(report, 'residential.exportOptions.perUnitSF')
  const basisForSFAnalysis = get(report, 'property_information.basisForSFAnalysis')
  const usingDefaultBasisForSFAnalysis = basisForSFAnalysis === DEFAULT_SF_ANALYSIS_BASIS
  const grossLeasableAreaPercentage = get(report, 'grossLeasableAreaPercentage')
  const lossFactor = 1 - grossLeasableAreaPercentage

  return {
    showPerUnitSF,
    basisForSFAnalysis: SF_BASIS_OPTIONS[basisForSFAnalysis as BasisForSFAnalysis],
    usingDefaultBasisForSFAnalysis,
    lossFactor,
  }
}
