import React, { Component } from 'react'
import { connect } from 'react-redux'
import { get, forIn, kebabCase, isEmpty } from 'lodash'

import PageLayout from '../../layout/PageLayout'
import { Loading } from '../../../shared/components'

import userForms from './userStructure'

class User extends Component {
  generateUserForms = () => {
    const items = []
    forIn(userForms, ({ name, formComponent }, key) => {
      items.push({
        title: name,
        formComponent,
        path: kebabCase(key),
      })
    })
    return items
  }

  render() {
    const { user, userLoaded } = this.props

    const userId = get(user, '_id')
    const forms = this.generateUserForms()

    return userLoaded ? <PageLayout header="Profile" itemKey="user" itemId={userId} forms={forms} /> : <Loading />
  }
}

const mapStateToProps = state => {
  const authenticatedUser = get(state, 'authentication.user', {})
  return {
    userLoaded: !isEmpty(authenticatedUser),
    user: authenticatedUser,
  }
}

export default connect(mapStateToProps)(User)
