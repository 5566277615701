import { get } from 'lodash'

import CommercialCalculations from 'shared/report-calculations/income-approach/commercial/commercial-calculations'
import { RentTypesUnitsOfMeasure } from 'shared/constants/report/incomeApproach'
import { LeaseStatusTypes } from 'shared/constants/report/incomeApproach/residential'

export const updateRent = ([data], state, utils) => {
  const { unitPath, updatedFieldName } = data

  const unit = get(state, `formState.values.${unitPath}`, {})
  const squareFeet = unit.squareFeet ?? 0
  const baseRent = unit[updatedFieldName]
  const rentBasisUpdated =
    updatedFieldName === 'annualRentPsf'
      ? RentTypesUnitsOfMeasure.PER_SF_PER_YEAR
      : RentTypesUnitsOfMeasure.PER_SF_PER_MONTH

  const rentCalculationInfo = { rentType: rentBasisUpdated, baseRent, squareFeet }

  const { annualRent, annualRentPsf, monthlyRent, monthlyRentPsf } =
    CommercialCalculations.calculateRentsGivenRentType(rentCalculationInfo)

  utils.changeValue(state, `${unitPath}.annualRent`, () => annualRent)
  utils.changeValue(state, `${unitPath}.monthlyRent`, () => monthlyRent)
  utils.changeValue(state, `${unitPath}.annualRentPsf`, () => annualRentPsf)
  utils.changeValue(state, `${unitPath}.monthlyRentPsf`, () => monthlyRentPsf)
}

export const updateLeaseStatus = ([data], state, utils) => {
  const { unitPath } = data

  const unit = get(state, `formState.values.${unitPath}`, {})

  utils.changeValue(state, `${unitPath}.isVacant`, () => unit.leaseStatus === LeaseStatusTypes.VACANT)
}
