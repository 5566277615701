import React from 'react'
import { isEqual } from 'lodash'
import PropTypes from 'prop-types'
import { compose, shouldUpdate } from 'recompose'
import { GridList, GridListTile, withStyles } from '@material-ui/core'

import { Box } from '@mui/material'

import Dropzone from '../Dropzone'
import { ImageItem } from '../Image'

import { SUPPORTED_IMAGE_FORMATS } from '../../constants/imageCategories'

import withImageListModal from './withImageListModal'

const styles = theme => ({
  root: {
    display: 'flex-block',
    flexWrap: 'wrap',
    justifyContent: 'space-around',
    overflow: 'hidden',
  },
  gridList: {
    flexWrap: 'nowrap',
    transform: 'translateZ(0)',
  },
  listItem: {
    maxWidth: 200,
    minWidth: 200,
    height: '100%',
    marginRight: 10,
  },
})

const SingleLineImageList = ({
  classes,
  images,
  disabled,
  onDelete,
  onUpload,
  onRotate,
  category,
  readOnly,
  openImageModal,
}) => (
  <div className={classes.root}>
    <GridList className={classes.gridList} data-qa={`${category}-image-list`} cellHeight={200} cols={5}>
      {!readOnly && (
        <GridListTile className={classes.listItem}>
          <Box sx={{ height: 1 }}>
            <Dropzone
              disabled={disabled}
              accept={SUPPORTED_IMAGE_FORMATS}
              helperText="Click or drag files to upload."
              onDrop={onUpload}
            />
          </Box>
        </GridListTile>
      )}
      {images.map((image, index) => (
        <GridListTile className={classes.listItem} data-qa={`${index}-image`} key={image.cdnUrl}>
          <ImageItem
            index={index}
            url={image.cdnUrl}
            isLoading={image.isLoading}
            onDelete={onDelete}
            onRotate={onRotate}
            onClick={openImageModal}
            readOnly={readOnly}
          />
        </GridListTile>
      ))}
    </GridList>
  </div>
)

SingleLineImageList.propTypes = {
  images: PropTypes.arrayOf(
    PropTypes.shape({
      cdnUrl: PropTypes.string.isRequired,
      uuid: PropTypes.string,
      isLoading: PropTypes.bool,
    })
  ),
  onDelete: PropTypes.func.isRequired,
  onUpload: PropTypes.func.isRequired,
  onRotate: PropTypes.func.isRequired,
  openImageModal: PropTypes.func.isRequired,
  category: PropTypes.string,
  readOnly: PropTypes.bool,
}

SingleLineImageList.defaultProps = {
  category: '',
  images: [],
  readOnly: false,
}

export default compose(
  React.memo,
  withStyles(styles),
  shouldUpdate((prev, next) => !isEqual(prev.images, next.images)),
  withImageListModal
)(SingleLineImageList)
