import React from 'react'
import PropTypes from 'prop-types'

import { MenuItem, Typography } from '@material-ui/core'

const GOOGLE_MAPS_LINK = `https://www.google.com/maps/place/`
const GOOGLE_MAP_TEXT = 'Google Map'

export default class PropertySearchLinks extends React.PureComponent {
  static propTypes = {
    taxLink: PropTypes.string,
    taxLinkText: PropTypes.string,
    fullAddress: PropTypes.string.isRequired,
    otherLinks: PropTypes.arrayOf(PropTypes.node).isRequired,
  }

  getGoogleMapsLink = () => {
    const { fullAddress } = this.props
    const googleMapsLink = `${GOOGLE_MAPS_LINK}${fullAddress}`
    return this.getLinkMenuItem(googleMapsLink, GOOGLE_MAP_TEXT)
  }

  getLinkMenuItem = (link, text) => {
    return (
      <a href={link} target="_blank" rel="noopener noreferrer" key={text}>
        <MenuItem>
          <Typography>{text}</Typography>
        </MenuItem>
      </a>
    )
  }

  getLinks = () => {
    const { taxLink, taxLinkText, otherLinks } = this.props
    const externalLinkItems = [...otherLinks]
    externalLinkItems.push(this.getGoogleMapsLink())
    if (taxLink) {
      externalLinkItems.push(this.getLinkMenuItem(taxLink, taxLinkText))
    }
    return externalLinkItems
  }

  render() {
    return <React.Fragment>{this.getLinks()}</React.Fragment>
  }
}
