import ReactDOM from 'react-dom'
import { MapControl } from 'react-leaflet'
import L from 'leaflet'
import React from 'react'
import PropTypes from 'prop-types'
export default class LeafletMapControl extends MapControl {
  static propTypes = {
    position: PropTypes.oneOf(['topleft', 'topright', 'bottomleft', 'bottomright']),
  }

  componentWillMount() {
    const { position, children, ...restProps } = this.props
    const legend = L.control({ position })
    const jsx = <div {...restProps}>{children}</div>

    legend.onAdd = function (map) {
      const element = L.DomUtil.create('div', '')
      ReactDOM.render(jsx, element)
      return element
    }

    this.leafletElement = legend
  }
}
