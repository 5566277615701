import React from 'react'
import Stack from '@mui/material/Stack'

import Typography from '../Typography'

// TODO: implement this using CardHeader of MUI5
const CardHeader = ({ variant = 'subtitle1', title, children, sx }) => {
  return (
    <Stack direction="row" justifyContent="space-between" alignItems="baseline" width={1} sx={{ py: 2 }}>
      <Stack justifyContent="center" sx={sx}>
        <Typography variant={variant} component="div">
          {title}
        </Typography>
      </Stack>
      {children}
    </Stack>
  )
}

export default React.memo(CardHeader)
