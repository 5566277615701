import React from 'react'

import { COMMERCIAL_RENT_RECONCILIATION_PATH } from 'shared/constants/report/keysAndDataPaths'

import wrapForm from '../../../wrapForm'

import CommercialRentReconciliation from './CommercialRentReconciliation'

import { Labels } from './CommercialRentReconciliationConstants'

export const DATA_PATH = COMMERCIAL_RENT_RECONCILIATION_PATH

class CommercialRentReconciliationContainer extends React.PureComponent {
  render() {
    const { reconciliationGroups, rentRollRentBasis } = this.props.form.values
    return (
      <CommercialRentReconciliation
        commercialRentReconciliation={reconciliationGroups.items}
        rentRollRentBasis={rentRollRentBasis}
      />
    )
  }
}

export default wrapForm(DATA_PATH, { heading: Labels.COMMERCIAL_RENT_RECONCILIATION })(
  CommercialRentReconciliationContainer
)
