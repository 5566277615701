import { put, takeLatest, select } from 'redux-saga/effects'

import { USER_UPDATE, USER_OFFICES_FETCH } from '../actionTypes/user'
import {
  userUpdateRequest,
  userUpdateReceive,
  userUpdateFail,
  userOfficesFetchRequest,
  userOfficesFetchReceive,
  userOfficesFetchFail,
} from '../actions/user'
import { loginUser } from '../../../authentication/redux/actions'

import * as Api from '../../../core/api'

import { successNotification, warningNotification } from '../../../shared/redux/actions/notifications'

function* updateUser({ payload }) {
  try {
    const { formDataPath, values } = payload
    const { newPasswordOne: password } = values

    const userId = yield select(state => state.authentication.user._id)
    const username = yield select(state => state.authentication.user.username)

    yield put(userUpdateRequest(payload))
    const response = yield Api.updateUser(userId, formDataPath, values)

    yield put(userUpdateReceive(response))
    yield put(
      successNotification({
        message: `User updated`,
      })
    )
    if (password) {
      yield put(loginUser({ username, password }))
    }
  } catch (err) {
    yield put(userUpdateFail(err))
    yield put(warningNotification({ message: `${err}` }))
  }
}

function* fetchOffices() {
  try {
    yield put(userOfficesFetchRequest())

    const userOffices = yield Api.fetchOffices()
    yield put(userOfficesFetchReceive(userOffices))
  } catch (err) {
    yield put(userOfficesFetchFail(err))
  }
}

export default [takeLatest(USER_UPDATE.ACTION, updateUser), takeLatest(USER_OFFICES_FETCH.ACTION, fetchOffices)]
