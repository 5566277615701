import React from 'react'
import PropTypes from 'prop-types'

import numberConverter from 'number-to-words'
import { Grid, Paper, Stack } from '@mui/material'
import { isNumber } from 'lodash'

import { FLOOR_STARTS, STAIRS_LOCATION } from 'shared/constants/property/description'
import { GeneratedComment, Number as NumberField, RadioButtonList } from 'client-shared/components'
import { ImageUploadListField as ImageUploadList } from 'client-shared/components/ImageList'
import { TYPICAL_STAIRWAY } from 'client-shared/constants/imageCategories'
import { arrayToKeyValuePairs } from 'client-shared/utils/ui/checkboxHelper'
import AutomationStatus from 'client-shared/components/AutomationStatus'

const Stairs = ({ numberOfFloors, tooltip, getGeneratedText, automatedInspectionPrefillFlag, automationMetadata }) => {
  const floorStarts = arrayToKeyValuePairs(FLOOR_STARTS)

  const stairsLocation = arrayToKeyValuePairs(STAIRS_LOCATION)

  let displayFloors = numberOfFloors
  if (isNumber(numberOfFloors)) {
    displayFloors = numberConverter.toOrdinal(displayFloors)
  }

  const floorEnds = [
    {
      label: `${displayFloors} Floor`,
      value: `${displayFloors} floor`,
    },
    {
      label: 'Roof',
      value: 'Roof',
    },
  ]
  return (
    <Paper data-qa="stairs-section-tile">
      <Stack spacing={2}>
        <ImageUploadList category={TYPICAL_STAIRWAY} name="typicalStairwayImages" readOnly />
        {automatedInspectionPrefillFlag && automationMetadata && (
          <Grid sx={{ marginTop: '4px !important', marginBottom: '-8px !important' }}>
            <AutomationStatus
              formPaths={['automationMetadata']}
              message="We automated the information below from Mobile Inspection app"
            />
          </Grid>
        )}
        <NumberField fullWidth label="Number of Stairs" margin="normal" min={0} name="stairs.numberOfStairs" />
        <Stack>
          <RadioButtonList
            horizontal
            items={floorStarts}
            label="Where do the stairs start?"
            name="stairs.stairsStart"
          />
          <RadioButtonList horizontal items={floorEnds} label="Where do the stairs end?" name="stairs.stairsEnd" />
          <RadioButtonList
            horizontal
            items={stairsLocation}
            label="Stairs are interior or exterior?"
            name="stairs.stairsLocation"
          />
        </Stack>
        <GeneratedComment
          dataPath="stairs.writeup"
          getGeneratedText={getGeneratedText}
          isDynamicContent
          label="Stairs Description Export"
          title="Generated Commentary"
          tooltipText={tooltip}
        />
      </Stack>
    </Paper>
  )
}

Stairs.propTypes = {
  getGeneratedText: PropTypes.func.isRequired,
  tooltip: PropTypes.string,
}

Stairs.defaultProps = {
  tooltip: '',
}

export default Stairs
