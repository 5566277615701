import { Button, FormControl } from '@mui/material'
import CompsImportForm from 'client-shared/components/CompsImportForm'
import DEFAULT_EXPENSES_PROPERTIES from 'client-shared/utils/expenses/constants'
import { find, get } from 'lodash'
import React, { useState } from 'react'
import PropTypes from 'prop-types'

import * as Api from '../../../api'

import ComparableExpensesUnitsModal from './ExpenseCompsModal'

import { mapCompPlexExpenseComps } from './helpers'

const ComparableExpensesImport = props => {
  const { form, propertySummary, subjectExpenses } = props

  const formValues = get(form, 'values', {})
  const { totalOperatingExpenses } = formValues

  const [isOpenModal, setIsOpenModal] = useState(false)
  const [isReportExpensesCompsLoading, setIsReportExpensesCompsLoading] = useState(false)

  const openModal = () => {
    setIsOpenModal(true)
  }

  const closeModal = () => {
    setIsOpenModal(false)
  }

  const onAddExpenseComps = (expenseComps = []) => {
    const { organizationId, addExpenseComparables } = props

    const comps = mapCompPlexExpenseComps(expenseComps)
    const newComps = comps.map(expenseComp => ({
      isImmutable: false,
      organization: {
        _id: organizationId,
      },
      key: expenseComp.id,
      ...expenseComp,
    }))
    addExpenseComparables(newComps)

    closeModal()
  }

  const fetchExpenseCompsFromReport = async reportId => {
    try {
      const { form, addExpenseComparables } = props
      setIsReportExpensesCompsLoading(true)
      const { expenseComparables: reportComps } = await Api.fetchExpenseCompsByReportId(reportId)
      const comparableExpenses = get(form, 'values.comparableExpenses') || []
      const uniqComps = reportComps.filter(
        importedComp => !find(comparableExpenses, comp => comp._id === importedComp._id)
      )
      addExpenseComparables(uniqComps)
    } catch (error) {
      console.log(error)
    } finally {
      setIsReportExpensesCompsLoading(false)
    }
  }
  return (
    <FormControl sx={{ flexDirection: 'row', gap: 1 }}>
      <CompsImportForm
        import={fetchExpenseCompsFromReport}
        isLoading={isReportExpensesCompsLoading}
        openDialogButtonProps={{
          variant: 'outlined',
          label: 'Import',
          sx: { minWidth: 90, height: 36 },
        }}
      />
      <Button variant="outlined" onClick={openModal} data-qa="add-expense-btn" sx={{ height: 36 }}>
        Search by filter
      </Button>
      {isOpenModal && (
        <ComparableExpensesUnitsModal
          handleClose={closeModal}
          onAddExpenseComps={onAddExpenseComps}
          filters={DEFAULT_EXPENSES_PROPERTIES}
          propertySummary={propertySummary}
          subjectExpenses={subjectExpenses}
          totalOperatingExpenses={totalOperatingExpenses}
          addedExpenseComps={formValues.comparableExpenses}
        />
      )}
    </FormControl>
  )
}

ComparableExpensesImport.propTypes = {
  addExpenseComparables: PropTypes.func.isRequired,
  importedExpenseComps: PropTypes.array,
  isReportExpensesCompsLoading: PropTypes.bool,
  organizationId: PropTypes.string.isRequired,
  propertySummary: PropTypes.object.isRequired,
  subjectExpenses: PropTypes.object.isRequired,
}

export default ComparableExpensesImport
