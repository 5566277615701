import React from 'react'
import PropTypes from 'prop-types'
import { Form } from 'react-final-form'
import { Dialog, Grid, DialogContent, Collapse, DialogActions } from '@material-ui/core'
import ExpandLess from '@mui/icons-material/ExpandLess'
import ExpandMore from '@mui/icons-material/ExpandMore'
import { Button, Typography, Stack } from '@mui/material'

import DialogHeader from 'client-shared/components/DialogHeader'
import { required } from 'client-shared/utils/validation'
import { DropDown, DatePicker, Text, Number, Callout } from 'client-shared/components'
import { SALE_STATUS_OPTIONS } from 'client-shared/constants/compPlex'
import { fromPercents, toPercents } from 'client-shared/utils/numberOperations'

import { STATES_LIST } from 'shared/constants/states'
import { SaleStatus } from 'shared/constants/report/sales/salesStatus'
import { COMPARABLE_TYPE_OPTIONS } from 'shared/constants/property/propertyTypes'

import { GENERAL_CONDITIONS } from 'shared/constants/property/description'

import { SOURCE_DROPDOWN_ITEMS } from '../../../../constants'

const fullGeneralConditionsOptions = Object.keys(GENERAL_CONDITIONS).map(key => {
  return {
    label: GENERAL_CONDITIONS[key],
    value: key,
  }
})
const generalConditionsOptions = fullGeneralConditionsOptions.filter(option => option.label !== 'Other')

const FIELD_SOURCE_TYPE = 'resourceInformation.sources[0].type'
const FIELD_SOURCE_URL = 'resourceInformation.sources[0].url'

const SOURCE_TYPE_OPTION_EXTERNAL_DATABASE = 'externalDatabase'

const validateBuyerAndSeller = (value, allValues) => {
  if (allValues.saleInformation?.saleStatus === SaleStatus.TRANSACTION) {
    return required(value)
  }

  return undefined
}

class LocalCapRateCompModal extends React.PureComponent {
  static propTypes = {
    onSubmit: PropTypes.func.isRequired,
    onClose: PropTypes.func.isRequired,
    initialValues: PropTypes.object,
  }

  state = {
    expanded: true,
  }

  toggleExpanded = () => {
    this.setState({ expanded: !this.state.expanded })
  }

  getParcelIDTypeOptions = values => {
    const propertyIdentifierType = values?.address?.propertyIdentification?.propertyIdentifierType

    const BBL = 'BoroughBlockLot'
    const BLQ = 'BlockLotQualifier'
    const propertyIDTypes = [BBL, BLQ]

    const isCustomPropertyIDType = !propertyIDTypes.includes(propertyIdentifierType)

    const parcelIDTypeOptions = [
      { value: BBL, label: 'Borough Block Lot' },
      { value: BLQ, label: 'Block Lot Qualifier' },
    ]

    if (isCustomPropertyIDType) {
      parcelIDTypeOptions.push({ value: propertyIdentifierType, label: 'Custom' })
    }

    return parcelIDTypeOptions
  }

  render() {
    const { initialValues, open, onClose, onSubmit } = this.props

    return (
      <>
        {open && (
          <Dialog disableBackdropClick open maxWidth="lg" onClose={onClose} aria-labelledby="form-dialog-title">
            <Form
              onSubmit={onSubmit}
              initialValues={initialValues}
              render={({ form, handleSubmit, invalid, values }) => {
                const shouldDisplayUrlField =
                  values.resourceInformation?.sources[0].type === SOURCE_TYPE_OPTION_EXTERNAL_DATABASE
                const saleIsTransaction = values.saleInformation?.saleStatus === SaleStatus.TRANSACTION

                const onSourceChange = event => {
                  const updatedValue = event.target.value
                  form.change(FIELD_SOURCE_TYPE, updatedValue)

                  if (updatedValue !== SOURCE_TYPE_OPTION_EXTERNAL_DATABASE) {
                    form.change(FIELD_SOURCE_URL, undefined)
                  }
                }

                return (
                  <>
                    <DialogHeader onClose={onClose} disableTypography>
                      <Grid container spacing={3}>
                        <Grid item xs={2}></Grid>
                        <Grid item xs={8}>
                          <Callout
                            content={
                              <Typography>
                                The comparable will be saved to <b>this report</b> and not to a <b>Bowery Database.</b>
                              </Typography>
                            }
                          />
                        </Grid>
                        <Grid item xs={2}></Grid>
                      </Grid>
                      <Typography variant="h6">{initialValues.address.streetAddress}</Typography>
                      <Typography variant="subtitle2">Cap Rate Information</Typography>
                    </DialogHeader>
                    <DialogContent>
                      <Grid container spacing={24}>
                        <Grid item xs={3}>
                          <Text name="address.streetAddress" label="Address" disabled required />
                        </Grid>
                        <Grid item xs={3}>
                          <Text name="address.city" label="City" disabled required />
                        </Grid>
                        <Grid item xs={3}>
                          <DropDown name="address.state" items={STATES_LIST} label="State" disabled required />
                        </Grid>
                        <Grid item xs={3}>
                          <Text name="address.postalCode" label="Zip" disabled required />
                        </Grid>
                        <Grid item xs={3}>
                          <DropDown
                            name="propertyInformation.propertyType"
                            items={COMPARABLE_TYPE_OPTIONS}
                            label="Comparable Type"
                            required
                            validate={required}
                          />
                        </Grid>
                        <Grid item xs={3}>
                          <Number
                            name="propertyInformation.yearBuilt"
                            label="Year Built"
                            allowNegative={false}
                            required
                            validate={required}
                          />
                        </Grid>
                        <Grid item xs={3}>
                          <DropDown
                            name="address.propertyIdentification.propertyIdentifierType"
                            items={this.getParcelIDTypeOptions(values)}
                            label="Property ID Type"
                            required
                            disabled
                          />
                        </Grid>
                        <Grid item xs={3}>
                          <Text
                            name="address.propertyIdentification.propertyIdentifier"
                            label="Property ID"
                            disabled
                            required
                          />
                        </Grid>
                        <Grid item xs={3}>
                          <Number
                            name="propertyInformation.grossBuildingArea"
                            label="GBA"
                            allowNegative={false}
                            thousandSeparator
                            data-qa="unit-sqft-input"
                            required
                            validate={required}
                          />
                        </Grid>
                        <Grid item xs={3}>
                          <Number
                            name="propertyInformation.grossLeasableArea"
                            label="GLA"
                            allowNegative={false}
                            thousandSeparator
                            data-qa="unit-sqft-input"
                          />
                        </Grid>
                        <Grid item xs={3}>
                          <Number
                            name="propertyInformation.netRentableArea"
                            label="NRA"
                            allowNegative={false}
                            thousandSeparator
                            data-qa="unit-sqft-input"
                          />
                        </Grid>
                        <Grid item xs={3}>
                          <Number
                            name="propertyInformation.netLeasableArea"
                            label="NLA"
                            allowNegative={false}
                            thousandSeparator
                            data-qa="unit-sqft-input"
                          />
                        </Grid>
                        <Grid item xs={3}>
                          <Number
                            name="propertyInformation.residentialUnits"
                            label="# of Residential Units"
                            allowNegative={false}
                            thousandSeparator
                            data-qa="unit-sqft-input"
                            required
                            validate={required}
                          />
                        </Grid>
                        <Grid item xs={3}>
                          <Number
                            name="propertyInformation.commercialUnits"
                            label="# of Commercial Units"
                            allowNegative={false}
                            thousandSeparator
                            data-qa="unit-sqft-input"
                            required
                            validate={required}
                          />
                        </Grid>
                        <Grid item xs={3}>
                          <DropDown
                            name="saleInformation.saleStatus"
                            items={SALE_STATUS_OPTIONS}
                            label="Sale Status"
                            required
                            validate={required}
                          />
                        </Grid>
                        <Grid item xs={3}>
                          <DatePicker name="saleDate" label="Sale Date" required validate={required} />
                        </Grid>
                        <Grid item xs={3}>
                          <Number
                            name="saleInformation.capRate"
                            label="Cap Rate"
                            required
                            validate={required}
                            adornment="%"
                            allowNegative={false}
                            decimalScale={2}
                            format={toPercents}
                            normalize={fromPercents}
                          />
                        </Grid>
                        <Grid item xs={3}>
                          <DropDown
                            name={FIELD_SOURCE_TYPE}
                            items={SOURCE_DROPDOWN_ITEMS}
                            label="Source Type"
                            required
                            validate={required}
                            onChange={onSourceChange}
                          />
                        </Grid>
                        {shouldDisplayUrlField && (
                          <Grid item xs={6}>
                            <Text name={FIELD_SOURCE_URL} label="Source URL" />
                          </Grid>
                        )}
                        <Grid item xs={12}>
                          <Stack
                            alignItems="center"
                            direction="row"
                            sx={{ color: '#4260D3' }}
                            onClick={this.toggleExpanded}
                          >
                            <Typography variant="subtitle2">Additional Information</Typography>
                            {this.state.expanded ? <ExpandLess /> : <ExpandMore />}
                          </Stack>
                        </Grid>
                        <Grid item xs={12}>
                          <Collapse in={this.state.expanded} timeout="auto" unmountOnExit>
                            <Grid container spacing={24}>
                              <Grid item xs={3}>
                                <DropDown
                                  name="propertyInformation.condition"
                                  items={generalConditionsOptions}
                                  label="Condition"
                                />
                              </Grid>
                              <Grid item xs={3}>
                                <Number
                                  name="propertyInformation.numberOfFloors"
                                  label="# of Floors"
                                  allowNegative={false}
                                  thousandSeparator
                                  data-qa="unit-sqft-input"
                                />
                              </Grid>
                              <Grid item xs={3}>
                                <Number
                                  name="saleInformation.salePrice"
                                  label="Deed Sale Price"
                                  allowNegative={false}
                                  thousandSeparator
                                  data-qa="unit-sqft-input"
                                  adornment="$"
                                  adornmentPosition="start"
                                />
                              </Grid>
                              <Grid item xs={3}>
                                <Text
                                  name="saleInformation.buyer"
                                  label="Buyer (Grantee)"
                                  required={saleIsTransaction}
                                  validate={validateBuyerAndSeller}
                                />
                              </Grid>
                              <Grid item xs={3}>
                                <Text
                                  name="saleInformation.seller"
                                  label="Seller (Grantor)"
                                  required={saleIsTransaction}
                                  validate={validateBuyerAndSeller}
                                />
                              </Grid>
                            </Grid>
                          </Collapse>
                        </Grid>
                      </Grid>
                    </DialogContent>
                    <DialogActions>
                      <Button onClick={onClose}>CANCEL</Button>
                      <Button disabled={invalid} variant="contained" onClick={handleSubmit}>
                        SAVE COMP
                      </Button>
                    </DialogActions>
                  </>
                )
              }}
            />
          </Dialog>
        )}
      </>
    )
  }
}

export default LocalCapRateCompModal
