import { createComposed } from '../../tools'

import Default from './Select'

const Single = createComposed(Default, {
  fullWidth: true,
  helperTextSx: { bottom: -18, position: 'absolute' },
})

const Embed = createComposed(Single, {
  custom: {
    border: 'none',
  },
})

export { Single, Embed }
