import { get } from 'lodash'

function mapData(historicDistrict, landmark, fullAddress) {
  const isLandmarked = historicDistrict || landmark

  const historicDistrictPhrase = historicDistrict
    ? `It is located in the ${historicDistrict}.`
    : 'It is not located in a historic district.'

  return { fullAddress, isLandmarked, historicDistrictPhrase }
}

export const mapDTO = formValues => {
  const { fullAddress, historicDistrict, landmark } = formValues

  return mapData(historicDistrict, landmark, fullAddress)
}

export const mapDataModel = report => {
  const fullAddress = get(report, 'property_information.address')
  const historicDistrict = get(report, 'property_information.historicDistrict')
  const landmark = get(report, 'property_information.landmark')

  return mapData(historicDistrict, landmark, fullAddress)
}
