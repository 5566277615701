import React from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import { FormControl, FormGroup, FormLabel, IconButton, Paper, TextField } from '@material-ui/core'
import OpenIcon from '@mui/icons-material/OpenInNewRounded'
import { Link } from 'react-router-dom'

import { RESIDENTIAL_RENT_ROLL_PATH } from 'shared/constants/report/keysAndDataPaths'

import { BooleanRadioButtonList, RadioButtonList, Tooltip } from '../../../../../../shared/components'
import { CheckboxWithLabel } from '../../../../../../shared/components/Checkbox'
import { Labels, PER_UNIT_SF_OPTIONS, RENT_PSF_TIME_PERIODS_OPTIONS } from '../constants'

export const DATA_PATH = RESIDENTIAL_RENT_ROLL_PATH

const styles = theme => ({
  form: {
    marginTop: 16,
    marginRight: 8,
  },
  formRentRoll: {
    marginTop: 16,
    marginRight: 8,
  },
  rentRollOptionHeader: {
    marginBottom: 8,
  },
  paper: theme.paper,
  includePSFAnalysisCheckboxWidth: {
    maxWidth: 400,
  },
})

class ResidentialRentRollOptions extends React.PureComponent {
  render() {
    const { classes, showTableLayoutOptions, showRentRollSizer } = this.props
    const { units, perUnitSF } = this.props.form.values

    return (
      <Paper className={classes.paper}>
        {showRentRollSizer && (
          <FormControl className={classes.form}>
            <FormLabel>{Labels.NUMBER_OF_RESIDENTIAL_UNITS}</FormLabel>
            <div>
              <TextField data-qa="number-of-items-input" type="number" value={units.length} margin="normal" disabled />
              <Tooltip text="Go To Subject Property Data" placement="top">
                <Link to="./subject-property#property-description">
                  <IconButton className={classes.icon}>
                    <OpenIcon fontSize="small" />
                  </IconButton>
                </Link>
              </Tooltip>
            </div>
          </FormControl>
        )}
        {showTableLayoutOptions && (
          <React.Fragment>
            <FormControl className={classes.formRentRoll}>
              <FormLabel className={classes.rentRollOptionHeader}>Rent Roll Options</FormLabel>
              <FormGroup className={classes.includePSFAnalysisCheckboxWidth}>
                <CheckboxWithLabel
                  name="includePSFAnalysis"
                  label={Labels.INCLUDE_PSF_IN_MARKET_RATE_COMPARISON_DISCUSSION_AND_TABLE_IN_REPORT}
                />
              </FormGroup>
              <FormGroup>
                <CheckboxWithLabel name="includePerRoomAnalysis" label={Labels.PER_ROOM_ANALYSIS} />
              </FormGroup>
              <FormGroup>
                <CheckboxWithLabel name="shouldSummarizeCurrentRentRoll" label={Labels.SUMMARIZE_CURRENT_RENT_ROLL} />
              </FormGroup>
              <FormGroup>
                <CheckboxWithLabel name="showDevelopersForecast" label={Labels.DEVELOPERS_FORECAST} />
              </FormGroup>
            </FormControl>
            <FormControl className={classes.form}>
              <FormLabel>{Labels.DO_YOU_KNOW_PER_UNIT_SQUARE_FOOTAGE}</FormLabel>
              <BooleanRadioButtonList items={PER_UNIT_SF_OPTIONS} name="perUnitSF" horizontal />
              {perUnitSF && (
                <>
                  <FormLabel>What time period should rent PSF be based on?</FormLabel>
                  <RadioButtonList name="unitRentPSFTimePeriod" items={RENT_PSF_TIME_PERIODS_OPTIONS} horizontal />
                </>
              )}
            </FormControl>
            <FormControl className={classes.form}>
              <FormLabel>{Labels.OPTIONAL_COLUMNS}</FormLabel>
              <FormGroup>
                <CheckboxWithLabel name="bathrooms" label={Labels.BATHROOMS} />
                <CheckboxWithLabel name="outdoorSpace" label={Labels.OUTDOOR_SPACE} />
                <CheckboxWithLabel name="unitLayout" label={Labels.UNIT_LAYOUT} />
              </FormGroup>
            </FormControl>
          </React.Fragment>
        )}
      </Paper>
    )
  }
}

ResidentialRentRollOptions.propTypes = {
  classes: PropTypes.object.isRequired,
  showTableLayoutOptions: PropTypes.bool,
  showRentRollSizer: PropTypes.bool,
}

ResidentialRentRollOptions.defaultProps = {
  showTableLayoutOptions: true,
  showRentRollSizer: true,
}

export default withStyles(styles)(ResidentialRentRollOptions)
