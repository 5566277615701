const FORM_HEADING = 'Appraisers'
const APPRAISERS_ROLES = {
  LEAD_APPRAISER: 'Lead Appraiser',
  APPRAISER: 'Appraiser',
}
const FORM_HEADING_TOOLTIP_TEXT = `Appraiser information can be edited within that user's personal profile. `
const FORM_HEADING_TOOLTIP_LINK = 'Update your information here.'
const FULL_NAME_TABLE_COLUMN_KEY = 'fullName'
const STATE_CERTIFICATION_COLUMN_KEY = 'stateCertification'
const APPRAISERS_TABLE_COLUMNS = [
  { name: 'Role', key: 'role' },
  { name: 'Full Name', key: FULL_NAME_TABLE_COLUMN_KEY },
  { name: 'Suffix', key: 'suffix' },
  { name: 'State Certification', key: 'stateCertification' },
  { name: 'AI Membership', key: 'AIMembership' },
  { name: 'AI Training Completed', key: 'AITrainingCompleted' },
  { name: 'Personally Inspected', key: 'personallyInspected' },
  { name: 'Sign Report', key: 'signReport' },
  { name: 'Remove', key: 'remove' },
]
const NONE_OPTION = {
  label: 'None',
  value: {},
}

const INSPECTOR_TYPE = {
  BOWERY: 'bowery',
  EXTERNAL: 'external',
}

export {
  FORM_HEADING,
  FORM_HEADING_TOOLTIP_TEXT,
  STATE_CERTIFICATION_COLUMN_KEY,
  FORM_HEADING_TOOLTIP_LINK,
  APPRAISERS_ROLES,
  APPRAISERS_TABLE_COLUMNS,
  FULL_NAME_TABLE_COLUMN_KEY,
  NONE_OPTION,
  INSPECTOR_TYPE,
}
