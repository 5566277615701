import React from 'react'
import PropTypes from 'prop-types'
import { compose } from 'recompose'
import { connect } from 'react-redux'
import { Grid, withStyles } from '@material-ui/core'
import DragHandleOutlinedIcon from '@mui/icons-material/DragHandleOutlined'
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined'
import { DataDisplay } from 'client-shared/components/DataDisplay'
import { formatInt } from 'client-shared/utils/numberFormatters'
import AutomationStatus from 'client-shared/components/AutomationStatus'
import { withAutomatedZoningIsEnabledForReport } from 'report/forms/property/Zoning/Automation'

import { DropDown } from '../../../../shared/components'
import Number from '../../../../shared/components/Number/Number'

import { complyingBulkInputsSelector } from './selectors'
import { complyingBulkInputStyles } from './helpers'
import { LAND_COMPATIBILITY_OPTION_TYPES, ShortSiteAreaMeasureLabels } from './constants'

class ComplyingBulkInputsOther extends React.PureComponent {
  static propTypes = {
    classes: PropTypes.object.isRequired,
    form: PropTypes.object.isRequired,
    floorAreaRatio: PropTypes.string,
    maxBuildableArea: PropTypes.number,
    siteArea: PropTypes.number,
    siteAreaDecimalPartLength: PropTypes.number.isRequired,
    automatedZoningEnabled: PropTypes.bool.isRequired,
  }

  static defaultProps = {
    floorAreaRatio: null,
    maxBuildableArea: 0,
    calculatedFloorAreaRatio: 0,
  }

  render() {
    const { classes, siteAreaMeasure, maxBuildableArea, siteArea, automatedZoningEnabled } = this.props

    const siteAreaMeasureLabel = ShortSiteAreaMeasureLabels[siteAreaMeasure]

    return (
      <React.Fragment>
        <Grid container justify="space-between" space={2}>
          {automatedZoningEnabled && (
            <Grid item xs={12}>
              <AutomationStatus formPaths={['automationMetadata']} sx={{ marginTop: '8px', marginBottom: '16px' }} />
            </Grid>
          )}
          <Grid item xs={3}>
            <DataDisplay title="Site Area" data={`${formatInt(siteArea)} ${siteAreaMeasureLabel}`} />
          </Grid>
          <Grid item xs={0.5}>
            <CloseOutlinedIcon fontSize="14px" fixedWidth className={classes.formulaIcon} />
          </Grid>
          <Grid item container justify="center" xs={4}>
            <Grid item xs={9}>
              <Number
                name="floorAreaRatio"
                size="small"
                label="Floor Area Ratio (FAR)"
                placeholder="0"
                thousandSeparator
                decimalScale={2}
                helperText={this.FARHelperText}
                adornment={this.FARInputAdornment}
                data-qa="floorAreaRatio-input"
              />
            </Grid>
          </Grid>
          <Grid item xs={0.5}>
            <DragHandleOutlinedIcon fontSize="14px" fixedWidth className={classes.formulaIcon} />
          </Grid>
          <Grid item xs={3}>
            <DataDisplay
              title="Maximum Buildable Area"
              data={`${formatInt(maxBuildableArea) || 0} ${siteAreaMeasureLabel}`}
            />
          </Grid>
        </Grid>
        <Grid container>
          <Grid item xs={3}>
            <Number
              name="buildableUnits"
              label="Buildable Units"
              size="small"
              placeholder="0"
              decimalScale={0}
              adornment={
                <DropDown
                  name="buildableUnitsType"
                  items={LAND_COMPATIBILITY_OPTION_TYPES}
                  size="small"
                  classes={{
                    formControl: classes.dropDownFormControl,
                    select: classes.dropDownSelect,
                  }}
                />
              }
              adornmentPosition="end"
              data-qa="buildableUnits-input"
              classes={{ adornedEnd: classes.dropDownAdornedEnd }}
            />
          </Grid>
        </Grid>
      </React.Fragment>
    )
  }
}

export default compose(
  withStyles(complyingBulkInputStyles),
  connect(complyingBulkInputsSelector),
  withAutomatedZoningIsEnabledForReport
)(ComplyingBulkInputsOther)
