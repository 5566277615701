import Handsontable from 'handsontable'

export const createTextRenderer = valueGetter => {
  return (instance, td, row, col, prop, value, cellProperties) => {
    Handsontable.renderers.TextRenderer.call(this, instance, td, row, col, prop, value, cellProperties)
    td.innerHTML = valueGetter ? valueGetter(row, col, prop, value, cellProperties) : value
    td.setAttribute('data-qa', `${prop}-${row}-cell`)
    return td
  }
}

export const defaultRenderer = (instance, td, row, col, prop, value, cellProperties) => {
  Handsontable.renderers.TextRenderer.call(this, instance, td, row, col, prop, value, cellProperties)
  td.setAttribute('data-qa', `${prop}-${row}-cell`)
  return td
}

export const KEY_CODES = {
  ENTER: 13,
  SPACE: 32,
  ARROW_UP: 38,
  ARROW_DOWN: 40,
}

export const getFieldValue = (form, name) => {
  return form.getFieldState(name)?.value
}
