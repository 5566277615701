import React from 'react'
import { Route, Switch } from 'react-router'

import OrganizationContainer from './forms/organization/OrganizationContainer'
import User from './forms/user/UserContainer'

class Router extends React.Component {
  render() {
    return (
      <React.Fragment>
        <Switch>
          <Route path="/user" component={User} />
          <Route path="/organization/:id?/" component={OrganizationContainer} />
        </Switch>
      </React.Fragment>
    )
  }
}
export default Router
