import React, { useCallback, useState } from 'react'
import MuiToggleButton from '@mui/material/ToggleButton'
import MuiToggleButtonGroup from '@mui/material/ToggleButtonGroup'

const ToggleButton = ({ options, value, onChange, ...restProps }) => {
  const [currentValue, setCurrentValue] = useState(value)

  const handleChange = useCallback(
    (_event, newValue) => {
      if (newValue !== null) {
        setCurrentValue(newValue)
        onChange(newValue)
      }
    },
    [onChange]
  )

  return (
    <MuiToggleButtonGroup exclusive value={currentValue} onChange={handleChange} {...restProps}>
      {options.map(({ value, label }) => (
        <MuiToggleButton value={value} key={value}>
          {label}
        </MuiToggleButton>
      ))}
    </MuiToggleButtonGroup>
  )
}

export default React.memo(ToggleButton)
