import React from 'react'
import { makeStyles } from '@mui/styles'

const useStyles = makeStyles(theme => {
  const padding = 16

  return {
    default: {
      paddingTop: padding,
      paddingBottom: padding,
    },
    fluid: {
      marginLeft: -padding,
      marginRight: -padding,
    },
  }
})

// TODO: implement this using CardContent of MUI5
const CardBody = ({ children, fluid = false }) => {
  const classes = useStyles()
  return <div className={fluid ? classes.fluid : classes.default}>{children}</div>
}

export default React.memo(CardBody)
