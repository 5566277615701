export const INTEREST_APPRAISED_AS_IS_MARKET_VALUE = [
  'Leased Fee Interest',
  'Fee Simple Interest',
  'Leasehold Interest',
]

export const INTEREST_APPRAISED_AS_COMPLETE = ['Leased Fee Interest', 'Fee Simple Interest', 'Leasehold Interest']

export const INTEREST_APPRAISED_AS_STABILIZED = ['Leased Fee Interest', 'Fee Simple Interest', 'Leasehold Interest']

export const ReportStates = {
  DRAFT: 'Draft',
  REVIEW: 'Review',
  APPROVED: 'Approved',
  SUBMITTED: 'Submitted',
  AUTOMATED: 'Automated',
}

export { PROPERTY_TYPES_PROPER_CASE, PropertyTypes } from './property/propertyTypes'

export const RECONCILIATION_APPROACH = {
  INCOME: 'Income',
  SALES: 'Sales',
  COST: 'Cost',
  LAND: 'Land',
}
