import { ColumnDataTypeEnum } from '@bowery-valuation/ui-components'
import { SALES_APPROACH_TYPES } from 'shared/constants/salesApproach'
import {
  ADDITIONAL_COMP_INFO_ROW_IDS,
  ADJUSTMENT_GROUPS,
  COMP_INFO_ROW_IDS_TO_LABEL,
  COMP_INFO_ROW_IDS_TO_PROPERTY,
  COMP_INFO_ROW_PROPERTY_TO_IDS,
  DEFAULT_COMP_INFO_ROW_IDS,
} from 'shared/helpers/salesApproach/salesCompAdjustmentConstants'
import { getCompInfoRowFormatter, getCompInfoSubjectValue } from 'shared/helpers/salesApproach/salesCompAdjustments'

export const EXPANDED_TABLE_TITLES = {
  saleDate: 'Sale Date',
  salePrice: 'Sale Price',
  capRate: 'Cap Rate',
  pricePerUnit: 'Price per Unit',
  pricePerSF: 'Price per SF',
  propertyDescription: 'Property Description',
  propertyRights: 'Property Rights',
  neighborhood: 'Neighborhood',
  buildingType: 'Building Type',
  corner: 'Corner?',
  elevatored: 'Elevatored',
  airRights: 'Unused Air Rights',
  commercialUnits: '# of Commercial Units',
  commercialAreaRatio: 'Commercial Area/SF',
  squareFootage: 'SF',
  residentialUnits: '# of Residential Units',
  incomeLevel: 'Income Level Adjusted',
  yearBuilt: 'Year Built',
  city: 'City',
  condition: 'Condition',
}

export const ExpandedMarketRows = [
  'saleDate',
  'salePrice',
  'capRate',
  'pricePerUnit',
  'pricePerSF',
  'propertyRights',
  'propertyDescription',
]

export const ExpandedLocationRows = ['neighborhood', 'city']

export const ExpandedUtilityRows = [
  'buildingType',
  'corner',
  'elevatored',
  'airRights',
  'commercialUnits',
  'commercialAreaRatio',
]

export const ExpandedOtherRows = {
  [SALES_APPROACH_TYPES.IMPROVED]: ['squareFootage', 'residentialUnits', 'incomeLevel', 'condition', 'yearBuilt'],
  [SALES_APPROACH_TYPES.LAND]: ['squareFootage', 'residentialUnits'],
}

export const ExpandedRowKeys = {
  saleDate: 'saleDate',
  salePrice: 'salePrice',
  capRate: 'capRate',
  pricePerUnit: 'pricePerUnit',
  pricePerSF: 'pricePerSF',
  propertyRights: 'propertyRights',
  propertyDescription: 'propertyDescription',
  neighborhood: 'neighborhood',
  buildingType: 'buildingType',
  corner: 'corner',
  elevatored: 'elevatored',
  airRights: 'airRights',
  commercialUnits: 'commercialUnits',
  commercialAreaRatio: 'commercialAreaRatio',
  squareFootage: 'squareFootage',
  residentialUnits: 'residentialUnits',
  incomeLevel: 'incomeLevel',
  yearBuilt: 'yearBuilt',
  city: 'city',
  condition: 'condition',
}

export const EXPANSION_PANELS = {
  market: ADJUSTMENT_GROUPS.MARKET,
  location: ADJUSTMENT_GROUPS.LOCATION,
  utility: ADJUSTMENT_GROUPS.UTILITY,
  other: ADJUSTMENT_GROUPS.OTHER,
}

export const TOOLTIP_TEXT = {
  MARKET: 'View Market Details',
  LOCATION: 'View Location Details',
  UTILITY: 'View Utility Details',
  OTHER: 'View Other Details',
}

export const MARKET_CONDITION_ADJUSTMENT_TOOLTIP =
  'For As Is properties this will calculate a market condition adjustment based on (Subject Date of Value - Comp Date of Sale) / 365 x % Market Condition Adjustment. For Stabilized properties, this will calculate a market condition adjustment based on (Subject Date of Stabilization - Comp Date of Sale) / 365 x % Market Condition Adjustment.'

export const CONDITION_ADJUSTMENT_MAP = {
  excellent: 10,
  good: 9,
  average: 8,
  satisfactory: 7,
  fair: 6,
  poor: 5,
  underRenovation: 4,
  shell: 3,
}

const DEFAULT_COMP_INFO_ROW_PROPERTIES = {
  readOnly: false,
  suppressMovable: true,
  permanent: true,
  type: ColumnDataTypeEnum.text,
  rowDef: { hideAction: true },
}

const ADDITIONAL_COMP_INFO_ROW_PROPERTIES = {
  ...DEFAULT_COMP_INFO_ROW_PROPERTIES,
  rowDef: { hideAction: false },
}

export const getCompInformationRows = (subjectPropertyInformation, showInExport, unitOfComparison) => {
  const additionalIds = Object.values(ADDITIONAL_COMP_INFO_ROW_IDS)
  const additionalRowIds = []
  Object.entries(showInExport).forEach(([key]) => {
    const rowId = COMP_INFO_ROW_PROPERTY_TO_IDS[key]
    if (additionalIds.includes(rowId)) {
      additionalRowIds.push(rowId)
    }
  })

  const defaultRows = Object.keys(DEFAULT_COMP_INFO_ROW_IDS).map(rowId => ({
    ...DEFAULT_COMP_INFO_ROW_PROPERTIES,
    id: COMP_INFO_ROW_IDS_TO_PROPERTY[rowId],
    label: COMP_INFO_ROW_IDS_TO_LABEL[rowId],
    ...getCompInfoRowFormatter(rowId),
    ...getCompInfoSubjectValue(rowId, subjectPropertyInformation, unitOfComparison),
  }))

  const additionalRows = []
  additionalRowIds.forEach(rowId => {
    additionalRows.push({
      ...ADDITIONAL_COMP_INFO_ROW_PROPERTIES,
      id: COMP_INFO_ROW_IDS_TO_PROPERTY[rowId],
      label: COMP_INFO_ROW_IDS_TO_LABEL[rowId],
      ...getCompInfoRowFormatter(rowId),
      ...getCompInfoSubjectValue(rowId, subjectPropertyInformation, unitOfComparison),
    })
  })

  return [...defaultRows, ...additionalRows]
}
