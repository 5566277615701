import React from 'react'
import { upperFirst, lowerCase } from 'lodash'
import { Value } from 'slate'
import { Plugin } from 'slate-react'

import { EVENT_HANDLED, UPDATE_INLINE_NODES_COMMAND } from '../constants'

import { updateLockedInlines } from '../../../../../../../shared/utils/narrative/updateNarrative'

import Locked from './Locked'

export default {
  onCommand(command, editor, next) {
    const { type, args } = command
    if (type === UPDATE_INLINE_NODES_COMMAND) {
      const [data, formatters] = args
      const updatedValue = updateLockedInlines(editor.value, data, formatters)
      editor.controller.setValue(updatedValue)
    }
    return next()
  },
  renderBlock(props, editor, next) {
    const { attributes, node, isFocused, readOnly, children } = props
    if (node.type !== 'locked') {
      return next()
    }

    const fieldName = node.get('data').get('fieldName')
    const displayName = node.get('data').get('displayName') || upperFirst(lowerCase(fieldName))
    const error = node.get('data').get('error') || false
    return (
      <Locked focused={isFocused} error={error} inline={false} attributes={{ ...attributes, displayName, readOnly }}>
        {children}
      </Locked>
    )
  },
  renderInline(props, editor, next) {
    const { attributes, node, isFocused, readOnly, children } = props
    if (node.type !== 'locked') {
      return next()
    }

    const fieldName = node.get('data').get('fieldName')
    const displayName = node.get('data').get('displayName') || upperFirst(lowerCase(fieldName))
    const error = node.get('data').get('error') || false
    return (
      <Locked focused={isFocused} error={error} attributes={{ ...attributes, displayName, readOnly }}>
        {children}
      </Locked>
    )
  },
  onKeyDown(event, editor, next) {
    if (editor.previousInlineLocked()) {
      switch (event.key) {
        case 'ArrowLeft':
          event.preventDefault()
          editor.moveFocusBeforeInline()
          return EVENT_HANDLED
        case 'Backspace':
          event.preventDefault()
          editor.focusPreviousLocked()
          return EVENT_HANDLED
        default:
          break
      }
    }

    if (editor.nextInlineLocked()) {
      switch (event.key) {
        case 'ArrowRight':
          event.preventDefault()
          editor.moveFocusAfterInline()
          return EVENT_HANDLED
        case 'Delete':
          event.preventDefault()
          editor.focusNextLocked()
          return EVENT_HANDLED
        default:
          break
      }
    }

    if (editor.lockedInlineFocused() && (event.key === 'Backspace' || event.key === 'Delete')) {
      editor.removeFocusedLocked()
      return EVENT_HANDLED
    }
    return next()
  },
  commands: {
    moveFocusAfterInline(editor) {
      const inline = editor.value.focusBlock.getNextText(editor.value.selection.focus.key)
      // @ts-ignore
      const nextTextNode = editor.value.focusBlock.getNextText(inline.key)
      // @ts-ignore
      editor.moveToStartOfNode(nextTextNode)

      return editor
    },
    moveFocusBeforeInline(editor) {
      const inline = editor.value.focusBlock.getPreviousText(editor.value.selection.focus.key)
      // @ts-ignore
      const nextTextNode = editor.value.focusBlock.getPreviousText(inline.key)
      // @ts-ignore
      editor.moveToEndOfNode(nextTextNode)

      return editor
    },
    removeFocusedLocked(editor) {
      editor.removeNodeByKey(editor.value.focusInline.key)

      return editor
    },
    focusPreviousLocked(editor) {
      const { value } = editor
      const inline = value.focusBlock.getPreviousNode(value.selection.focus.key)
      // @ts-ignore
      editor.moveFocusTo(inline.key)

      return editor
    },
    focusNextLocked(editor) {
      const { value } = editor
      const inline = value.focusBlock.getNextNode(value.selection.focus.key)
      // @ts-ignore
      editor.moveFocusTo(inline.key)

      return editor
    },
    setEditorValueFromJS(editor, value) {
      editor.controller.setValue(Value.create(value), { normalize: true })

      return editor
    },
    setValue(editor, value) {
      editor.controller.setValue(value)

      return editor
    },
  },
  queries: {
    lockedInlineFocused(editor) {
      const { value } = editor
      return value.focusInline && value.focusInline.type === 'locked'
    },
    nextInlineLocked(editor) {
      const { value } = editor
      const isAtTheEndOfText = value.selection.focus.isAtEndOfNode(value.focusText)

      const nextBlockType =
        value.focusBlock &&
        value.focusBlock.getNextNode(value.selection.focus.key) &&
        // @ts-ignore
        value.focusBlock.getNextNode(value.selection.focus.key).type
      return isAtTheEndOfText && nextBlockType === 'locked'
    },
    previousInlineLocked(editor) {
      const { value } = editor
      const isAtTheStartOfText = value.selection.focus.isAtStartOfNode(value.focusText)
      const previousBlockType =
        value.focusBlock &&
        value.focusBlock.getPreviousNode(value.selection.focus.key) &&
        // @ts-ignore
        value.focusBlock.getPreviousNode(value.selection.focus.key).type
      return isAtTheStartOfText && previousBlockType === 'locked'
    },
  },
} as Plugin

declare module 'slate-react' {
  interface Editor {
    moveFocusAfterInline(): Editor
    moveFocusBeforeInline(): Editor
    removeFocusedLocked(): Editor
    focusPreviousLocked(): Editor
    focusNextLocked(): Editor
    setEditorValueFromJS(value: Value): Editor
    setValue(value: Value): Editor
    lockedInlineFocused(): boolean
    nextInlineLocked(): boolean
    previousInlineLocked(): boolean
  }
}
