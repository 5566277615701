import React from 'react'
import PropTypes from 'prop-types'
import { shouldUpdate, compose } from 'recompose'
import { withStyles } from '@material-ui/core/styles'

import UnitMix from '../UnitMix'
import { PropertiesSearchUnitMixColumns, UnitMixColumnNames } from '../../constants'

import { propertySearchResultsPropTypes } from './propTypes'
import { PropertySearchResultCell } from './PropertySearchResultCell'
import SearchResultsRow from './SearchResultsRow'

const styles = theme => ({
  imageContainer: {
    padding: theme.spacing.unit * 2,
    minHeight: 150,
    maxHeight: 150,
    maxWidth: 200,
  },
  unitMixHeaderCell: {
    fontSize: 14,
  },
  unitMixHeaderRow: {
    height: 42,
  },
  unitMixRow: {
    height: 38,
  },
  subjectRow: {
    display: 'flex',
    paddingLeft: 10,
    backgroundColor: theme.palette.primary[50],
  },
  row: {
    display: 'flex',
    paddingLeft: 10,
  },
})

const DEFAULT_ROW_HEIGHT = 50
const MIX_UNITS_HEADER_HEIGHT = 42
const MIX_UNITS_ITEM_HEIGHT = 40

const DEFAULT_MIX_UNITS_HEIGHT = 200 + DEFAULT_ROW_HEIGHT

class PropertySearchResultItem extends React.Component {
  static propTypes = {
    onRemoveCompItem: PropTypes.func,
    onAddCompItem: PropTypes.func,
    onToggleDetails: PropTypes.func.isRequired,
    isExpanded: PropTypes.bool,
    isSubject: PropTypes.bool,
    showPerRoomAnalysis: PropTypes.bool.isRequired,
    item: propertySearchResultsPropTypes,
    columns: PropTypes.array,
  }

  toggleDetailsExpand = () => {
    const mixTableCalculatedHeight =
      MIX_UNITS_HEADER_HEIGHT + DEFAULT_ROW_HEIGHT + this.props.item.unitMix.length * MIX_UNITS_ITEM_HEIGHT
    const calculatedHeight =
      mixTableCalculatedHeight > DEFAULT_MIX_UNITS_HEIGHT ? mixTableCalculatedHeight : DEFAULT_MIX_UNITS_HEIGHT

    const height = this.props.isExpanded ? DEFAULT_ROW_HEIGHT : calculatedHeight
    this.props.onToggleDetails(this.props.index, height)
  }

  onRemoveCompItem = () => {
    this.props.onRemoveCompItem(this.props.item._id)
  }

  onAddCompItem = () => {
    this.props.onAddCompItem(this.props.item._id)
  }

  getUnitMixColumns = () => {
    const { showPerRoomAnalysis } = this.props

    if (showPerRoomAnalysis) {
      return PropertiesSearchUnitMixColumns
    }

    const roomAnalysisColumnNames = [UnitMixColumnNames.AVG_ROOMS, UnitMixColumnNames.AVG_RENT_PER_ROOM]

    return PropertiesSearchUnitMixColumns.filter(column => {
      return !roomAnalysisColumnNames.includes(column.name)
    })
  }

  render() {
    const { item, columns, classes, isSubject } = this.props
    return (
      <React.Fragment>
        <SearchResultsRow isSubject={isSubject}>
          {columns.map(({ title, name, width }) => {
            return (
              <PropertySearchResultCell
                key={name}
                width={width}
                cellData={item[name]}
                dataKey={name}
                toggleDetailsExpand={this.toggleDetailsExpand}
                isExpanded={this.props.isExpanded}
                isSubject={isSubject}
                isSelected={item.isSelected}
                onAddCompItem={this.onAddCompItem}
                onRemoveCompItem={this.onRemoveCompItem}
              />
            )
          })}
        </SearchResultsRow>

        {this.props.isExpanded ? (
          <UnitMix
            columns={this.getUnitMixColumns()}
            items={item.unitMix}
            image={item.image}
            images={item.images}
            imageWidth={150}
            imageHeight={120}
            classes={{
              imageContainer: classes.imageContainer,
              headerCell: classes.unitMixHeaderCell,
              headerRowClass: classes.unitMixHeaderRow,
              row: classes.unitMixRow,
            }}
          />
        ) : null}
      </React.Fragment>
    )
  }
}

export default compose(
  withStyles(styles),
  shouldUpdate((props, nextProps) => {
    return (
      props.item.address !== nextProps.item.address ||
      props.isExpanded !== nextProps.isExpanded ||
      props.item.isSelected !== nextProps.item.isSelected
    )
  })
)(PropertySearchResultItem)
