import React from 'react'
import { get } from 'lodash'
import PropTypes from 'prop-types'
import { compose } from 'recompose'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import Cancel from '@mui/icons-material/Cancel'
import OpenInNew from '@mui/icons-material/OpenInNew'

import { Grid, withStyles, Paper, Table, TableBody, TableHead, TableRow, TableCell } from '@material-ui/core'

import { IconButton, Button, Typography } from '@mui/material'

import AutomationStatus from 'client-shared/components/AutomationStatus'
import { withAutomatedZoningIsEnabledForReport } from 'report/forms/property/Zoning/Automation'

import Callout from '../../../../shared/components/Callout'
import Text from '../../../../shared/components/Table/TableText'
import Number from '../../../../shared/components/Table/TableNumber'
import { ImageWithLabel } from '../../../../shared/components/Image'

import ImportFromReportDialog from '../../../../shared/components/ImportFromReportDialog'

import { MAX_ZONES_COUNT } from './constants'
import { zoningInformationSelector } from './selectors'
import PropertyIdentificationSection from './PropertyIdentificationSection'

const WARNING_TEXT = 'Total zone percentages must add up to 100.'

const styles = theme => ({
  paper: theme.paper,
  mapPanel: {
    '& img': {
      width: '100%',
    },
  },
  sectionTitle: {
    letterSpacing: 0.2,
    fontSize: 16,
  },
  mapLink: {
    display: 'flex',
    alignItems: 'center',
  },
  linkIcon: {
    color: theme.palette.secondary[500],
    height: 18,
    width: 18,
    marginLeft: 8,
  },
  table: {
    borderRadius: 5,
    overflow: 'hidden',
    borderCollapse: 'inherit',
    border: `1px solid ${theme.palette.secondary[300]}`,
    '& th, & td, & input': {
      textAlign: 'right',
    },
  },
  tableHeader: {
    backgroundColor: theme.palette.secondary[600],
  },
  tableHeaderCell: {
    color: theme.palette.common.white,
    '&:last-child': {
      paddingRight: 10,
    },
  },
  actionCell: {
    width: 32,
    padding: '8px 0',
  },
  warning: {
    marginTop: 15,
    boxShadow:
      '0px 1px 5px 0px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 3px 1px -2px rgba(0, 0, 0, 0.12)',
    color: theme.palette.secondary[600],
  },
})

class Information extends React.PureComponent {
  static propTypes = {
    classes: PropTypes.object.isRequired,
    form: PropTypes.object.isRequired,
    reportId: PropTypes.string.isRequired,
    zones: PropTypes.array.isRequired,
    isValidPercentage: PropTypes.bool.isRequired,
    siteArea: PropTypes.number.isRequired,
    city: PropTypes.string.isRequired,
    county: PropTypes.string,
    isFreddieMacReport: PropTypes.bool.isRequired,
    isNYCReport: PropTypes.bool.isRequired,
    block: PropTypes.number.isRequired,
    lot: PropTypes.string.isRequired,
    importZoning: PropTypes.func.isRequired,
    isLoading: PropTypes.bool.isRequired,
    automatedZoningEnabled: PropTypes.bool.isRequired,
  }

  addZone = () => {
    const { form } = this.props

    const zones = get(form, 'values.zones') || []
    const newZones = [...zones, {}]

    form.change('zones', newZones)
  }

  removeZone = index => {
    const { form } = this.props

    const zones = get(form, 'values.zones') || []
    const newZones = zones.filter((zone, zoneIndex) => zoneIndex !== index)

    form.change('zones', newZones)
  }

  renderZoningTable = () => {
    const { classes, zones, isNYCReport, isValidPercentage } = this.props

    return (
      <Table data-qa="subjectInformation-table" classes={{ root: classes.table }}>
        <TableHead classes={{ root: classes.tableHeader }}>
          <TableRow>
            <TableCell classes={{ root: classes.tableHeaderCell }}>#</TableCell>
            <TableCell classes={{ root: classes.tableHeaderCell }}>Zone</TableCell>
            {isNYCReport && <TableCell classes={{ root: classes.tableHeaderCell }}>Zone FAR</TableCell>}
            <TableCell classes={{ root: classes.tableHeaderCell }}>Commercial Overlay</TableCell>
            <TableCell classes={{ root: classes.tableHeaderCell }}>Percentage in Zone</TableCell>
            <TableCell classes={{ root: classes.actionCell }} />
          </TableRow>
        </TableHead>
        <TableBody>
          {zones.map(({ floorAreaRatio }, index) => {
            const number = index + 1
            return (
              <TableRow key={index} data-qa={`row-${number}`}>
                <TableCell data-qa={`zone-${number}-number-cell`}>
                  <Typography>{number}</Typography>
                </TableCell>
                <TableCell data-qa={`zone-${number}-zone-cell`}>
                  <Text name={`zones[${index}].zone`} placeholder="Zone Name" />
                </TableCell>
                {isNYCReport && (
                  <TableCell data-qa={`zone-${number}-floorAreaRatio-cell`}>
                    <Typography>{floorAreaRatio || '-'}</Typography>
                  </TableCell>
                )}
                <TableCell data-qa={`zone-${number}-commercialOverlay-cell`}>
                  <Text name={`zones[${index}].commercialOverlay`} placeholder="0" />
                </TableCell>
                <TableCell data-qa={`zone-${number}-percentage-cell`}>
                  <Number
                    name={`zones[${index}].percentage`}
                    suffix="%"
                    decimalScale={2}
                    placeholder="0%"
                    error={!isValidPercentage}
                  />
                </TableCell>
                <TableCell data-qa="remove-cell" classes={{ root: classes.actionCell }}>
                  <IconButton onClick={() => this.removeZone(index)}>
                    <Cancel color="error" fontSize="small" />
                  </IconButton>
                </TableCell>
              </TableRow>
            )
          })}
        </TableBody>
      </Table>
    )
  }

  render() {
    const {
      classes,
      form,
      zones,
      reportId,
      isValidPercentage,
      isNYCReport,
      isLoading,
      importZoning,
      automatedZoningEnabled,
      currentPath,
    } = this.props
    const numberOfZones = zones.length

    return (
      <Paper className={classes.paper}>
        <Grid container spacing={16} data-qa="information-section-tile">
          <Grid item xs={12}>
            <Typography variant="h6">Zoning Information</Typography>
          </Grid>
          <Grid item xs={12}>
            <Link to={`/report/${reportId}/property-maps`} className={classes.mapLink}>
              <Typography variant="subtitle2" className={classes.sectionTitle}>
                Map
              </Typography>
              <OpenInNew className={classes.linkIcon} />
            </Link>
          </Grid>
          <Grid item xs={12} className={classes.mapPanel}>
            <ImageWithLabel name="zoningImage" />
          </Grid>

          <Grid item xs={12}>
            <Grid container direction="row" justify="space-between" alignItems="center">
              <Grid item>
                <Typography className={classes.sectionTitle} variant="subtitle2">
                  Subject Zoning
                </Typography>
              </Grid>
              <Grid item>
                <Grid container spacing={8}>
                  {!isNYCReport && (
                    <Grid item>
                      <ImportFromReportDialog
                        isLoading={isLoading}
                        openDialogButtonProps={{
                          variant: 'outlined',
                          label: 'Import Zoning',
                        }}
                        import={importZoning}
                        dialogContent="Input the ID of the report you would like to import zoning from"
                      />
                    </Grid>
                  )}
                  <Grid item>
                    <Button
                      variant="outlined"
                      disabled={numberOfZones === MAX_ZONES_COUNT}
                      color="primary"
                      onClick={this.addZone}
                      data-qa="addZone-btn"
                    >
                      Add Zone
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          {automatedZoningEnabled && (
            <Grid item xs={12}>
              <AutomationStatus formPaths={['automationMetadata']} />
            </Grid>
          )}
          <Grid item xs={12}>
            {this.renderZoningTable()}
            {!isValidPercentage && <Callout variant="error" sx={{ my: 2 }} content={WARNING_TEXT} />}
          </Grid>
          <PropertyIdentificationSection form={form} currentPath={currentPath} />
        </Grid>
      </Paper>
    )
  }
}

export default compose(
  withStyles(styles),
  connect(zoningInformationSelector),
  withAutomatedZoningIsEnabledForReport
)(Information)
