import { get } from 'lodash'
import { connect } from 'react-redux'

import { CompTypes } from '../../../../constants'
import { PropertyTypes } from '../../../../../../../shared/constants'

import MapGallerySection from './MapGallerySection'

const getPropertyMapsSection = (reportId, propertyMaps) => {
  const { zoningMapImage, floodMapImage, taxMapImage, subjectMapImage } = propertyMaps
  return {
    title: 'Property Maps',
    link: `/report/${reportId}/property-maps`,
    maps: [
      { title: 'Zoning Map', image: zoningMapImage },
      { title: 'Flood Map', image: floodMapImage },
      { title: 'Tax Map', image: taxMapImage },
      { title: 'Subject Map', image: subjectMapImage },
    ],
  }
}

const getResidentialRentCompsMapSection = (
  reportId,
  residentialRentCompsMap,
  residentialBuildingRentCompsMap,
  compType
) => {
  if (compType === CompTypes.BUILDING) {
    return {
      title: 'Residential Rent Comps Map',
      link: `/report/${reportId}/residential-building-rent-comps-map`,
      maps: [{ title: 'Building Comparables Map', image: residentialBuildingRentCompsMap }],
    }
  }

  if (compType === CompTypes.UNIT) {
    return {
      title: 'Residential Rent Comps Map',
      link: `/report/${reportId}/residential-rent-comps-map`,
      maps: residentialRentCompsMap.map(compMap => ({
        title: compMap.unitGroupName,
        image: compMap.mapUrl,
      })),
    }
  }
}

const getCommercialRentCompsSection = (reportId, commercialRentCompsMap, commercialCompGroups, incomeType) => {
  if (incomeType === PropertyTypes.MULTI_FAMILY) {
    return null
  }

  return {
    title: 'Commercial Rent Comps Map',
    link: `/report/${reportId}/commercial-rent-comps-map`,
    maps: commercialRentCompsMap.map(compMap => {
      const compGroup = commercialCompGroups.find(cg => cg._id === compMap.groupId) || {}

      return {
        title: `${compGroup.name} Map`,
        image: compMap.mapUrl,
      }
    }),
  }
}

const getSalesCompsMapSection = (reportId, salesCompsMap) => ({
  title: 'Sales Comparables Map',
  link: `/report/${reportId}/sales-comps-map`,
  maps: [{ title: 'Sales Comparables Map', image: salesCompsMap }],
})

const mapStateToProps = state => {
  const reportId = get(state, 'report.reportData._id')

  const propertyMaps = get(state, 'report.reportData.propertyInformation.propertyMaps')

  const residentialRentCompsMap = get(
    state,
    'report.reportData.incomeApproach.residentialIncome.residentialRentCompsMap.maps'
  )
  const residentialBuildingRentCompsMap = get(
    state,
    'report.reportData.incomeApproach.residentialIncome.residentialBuildingRentCompsMap.mapUrl'
  )

  const { compType } = get(state, 'report.reportData.incomeApproach.residentialIncome.residentialRentComps')

  const salesCompsMap = get(state, 'report.reportData.salesApproach.salesCompsMap.mapUrl')

  const commercialRentCompsMap = get(
    state,
    'report.reportData.incomeApproach.commercialIncome.commercialRentCompsMap.maps'
  )
  const commercialCompGroups = get(
    state,
    'report.reportData.incomeApproach.commercialIncome.commercialCompGroups.compGroups',
    []
  )

  const incomeType = get(state, 'report.reportSettings.incomeType')

  const mapsSections = [
    getPropertyMapsSection(reportId, propertyMaps),
    getResidentialRentCompsMapSection(reportId, residentialRentCompsMap, residentialBuildingRentCompsMap, compType),
    getCommercialRentCompsSection(reportId, commercialRentCompsMap, commercialCompGroups, incomeType),
    getSalesCompsMapSection(reportId, salesCompsMap),
  ]

  return {
    mapsSections: mapsSections.filter(mapSection => mapSection),
  }
}

export default connect(mapStateToProps)(MapGallerySection)
