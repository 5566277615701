import React from 'react'

import { Grid } from '@mui/material'

import ShowSection from 'client-shared/components/ShowSection'
import { CheckboxWithLabel } from 'client-shared/components/Checkbox'
import { ImageUploadListField as ImageUploadList } from 'client-shared/components/ImageList'
import { Number as NumberField } from 'client-shared/components'
import { SECTIONS } from 'shared/helpers/propertyType'
import { TYPICAL_LAUNDRY_ROOM, TYPICAL_PARKING } from 'client-shared/constants/imageCategories'

const MAX_PARKING_SPACE_COUNT = 5000
const MAX_STORAGE_UNIT_COUNT = 1000

const BuildingAmenitiesCommon = ({ building: { hasLaundryRoom, hasParking, hasStorageUnits } }) => (
  <>
    <ShowSection section={SECTIONS.HAS_RESIDENTIAL}>
      <Grid container paddingTop={2} data-qa="laundryRoom-section-tile">
        <CheckboxWithLabel name="building.hasLaundryRoom" label="Laundry Room" />
        {hasLaundryRoom && <ImageUploadList name="laundryRoomImages" category={TYPICAL_LAUNDRY_ROOM} />}
      </Grid>
    </ShowSection>
    <Grid container paddingTop={2} data-qa="storageUnit-section-tile">
      <CheckboxWithLabel name="building.hasStorageUnits" label="Storage Units" />
      {hasStorageUnits && (
        <NumberField
          name="building.storageUnitCount"
          label="Number of Storage Units"
          fullWidth
          margin="normal"
          max={MAX_STORAGE_UNIT_COUNT}
          allowNegative={false}
        />
      )}
    </Grid>
    <Grid container paddingTop={2} data-qa="parking-section-tile">
      <CheckboxWithLabel name="building.hasParking" label="Parking" />
      {hasParking && (
        <NumberField
          name="building.parkingSpaceCount"
          label="Parking Spaces"
          fullWidth
          margin="normal"
          max={MAX_PARKING_SPACE_COUNT}
        />
      )}
      {hasParking && <ImageUploadList name="parkingImages" category={TYPICAL_PARKING} />}
    </Grid>
  </>
)

export default BuildingAmenitiesCommon
