import { get, find, invert } from 'lodash'

import { EXPENSE_HISTORY_TYPES } from '../../../constants/expenses/expenseHistory'
import {
  SOURCE_TYPE_LABELS,
  EXPENSE_COMPARABLES_CATEGORIES,
  EXPENSE_COMPARABLES_INFO,
} from '../../../constants/expenses/expenseComparables'
import { EXPENSE_CATEGORIES } from '../../../constants/expenses'
import { CompPropertyTypes } from '../../../constants/property/propertyTypes'

import { getStateAbbreviation } from '../../property'

const getSourceTypeName = sources => {
  const sourceWithType = find(sources, source => !!source.type)
  const sourceOfInformation = sourceWithType?.type || ''

  return SOURCE_TYPE_LABELS[sourceOfInformation] || sourceOfInformation
}

const getValueBasedOnUnitOfMeasure = (value, unitOfMeasure, gba) => {
  switch (unitOfMeasure) {
    case 'annually':
      return value
    case 'monthly':
      return value * 12
    case 'perSquareFoot':
      return value * gba
    case 'perSquareFootPerMonth':
      return value * gba * 12
    default:
      return value
  }
}

export const getExpenseValueByIdFromBoweryCoreProperty = (expenseId, property) => {
  switch (expenseId) {
    case EXPENSE_COMPARABLES_CATEGORIES.address:
      return property.address
    case EXPENSE_COMPARABLES_CATEGORIES.buildingType:
      return property.buildingType
    case EXPENSE_COMPARABLES_CATEGORIES.city:
      return get(property, 'location')
    case EXPENSE_COMPARABLES_CATEGORIES.state: {
      const state = get(property, 'state')
      return getStateAbbreviation(state) || state
    }
    case EXPENSE_COMPARABLES_CATEGORIES.expenseMonth:
      return get(property, 'expenseMonth')
    case EXPENSE_COMPARABLES_CATEGORIES.expenseYear:
      return get(property, 'expenseYear')
    case EXPENSE_COMPARABLES_CATEGORIES.expensePeriod:
      return property.expensePeriod || EXPENSE_HISTORY_TYPES[property.expensePeriod]
    case EXPENSE_COMPARABLES_CATEGORIES.sourceOfInformation:
      return get(property, 'sourceOfInformation', '')
    case EXPENSE_COMPARABLES_CATEGORIES.yearBuilt:
      return get(property, 'yearBuilt')
    case EXPENSE_COMPARABLES_CATEGORIES.squareFeet:
      return get(property, 'squareFeet')
    case EXPENSE_COMPARABLES_CATEGORIES.propertyType:
      return CompPropertyTypes[get(property, 'propertyType')]
    case EXPENSE_COMPARABLES_CATEGORIES.residentialUnits:
      return get(property, 'residentialUnits')
    case EXPENSE_COMPARABLES_CATEGORIES.egi:
      return get(property, 'effectiveGrossIncome')
    default:
      return null
  }
}

export const mapExpenseCompPlex = (comp, oldLocalCopy = {}) => {
  const unitOfMeasure = comp.unitOfMeasure
  const gba = comp.propertyInformation.grossBuildingArea

  const comparable = {
    _id: comp.id,
    id: comp.id,
    key: comp.id,
    boweryId: comp.id,
    version: comp.version,
    sourceUrl: get(comp, 'resourceInformation.sources[0].url'),
    sourceOfInformation: getSourceTypeName(get(comp, 'resourceInformation.sources')),
    buildingType: get(comp, 'propertyInformation.propertyType'),
    propertyType: get(comp, 'propertyInformation.propertyType'),
    state: get(comp, 'address.state'),
    location: get(comp, 'propertyInformation.displayCity'),
    expenseMonth: get(comp, 'expensePeriod.month'),
    expenseYear: get(comp, 'expensePeriod.year'),
    expensePeriod: get(comp, 'expensePeriod.periodType'),
    expenseDate: get(comp, 'expensePeriod.date'),
    effectiveGrossIncome: get(comp, 'effectiveGrossIncome.gross'),
    yearBuilt: get(comp, 'propertyInformation.yearBuilt'),
    squareFeet: get(comp, 'propertyInformation.grossBuildingArea'),
    residentialUnits: get(comp, 'propertyInformation.residentialUnits'),
    expenseRatio: comp.expenseRatio,
    total: get(comp, 'totalOperatingExpensesGross.gross'),
    totalNetTaxes: get(comp, 'totalOperatingExpensesNet.gross'),
    totalPerSF: get(comp, 'totalOperatingExpensesGross.sf'),
    totalPerSFNetTaxes: get(comp, 'totalOperatingExpensesNet.sf'),
    totalPerUnit: get(comp, 'totalOperatingExpensesGross.unit'),
    totalPerUnitNetTaxes: get(comp, 'totalOperatingExpensesNet.unit'),
    address: get(comp, 'propertyInformation.displayStreetAddress'),
    isAutoComp: !!oldLocalCopy.isAutoComp,
  }
  const infoExpenses = Object.keys(EXPENSE_COMPARABLES_INFO).map(id => ({
    id,
    value: getExpenseValueByIdFromBoweryCoreProperty(id, comparable),
  }))

  const operatingExpenses = comp?.operatingExpenses?.map(expense => {
    const value = getValueBasedOnUnitOfMeasure(expense.value.gross, unitOfMeasure, gba)
    const expenseCategory = invert(EXPENSE_CATEGORIES)[expense.category] || expense.category
    return {
      id: expenseCategory,
      value,
      sf: value / comp.propertyInformation.grossBuildingArea,
      unit: value / (comp.propertyInformation.residentialUnits || 0 + comp.propertyInformation.commercialUnits || 0),
    }
  })

  const mappedComp = {
    ...comparable,
    expenses: [...infoExpenses, ...operatingExpenses],
  }

  return mappedComp
}
