import React, { useEffect, useState } from 'react'

import { Box, FormControl, FormGroup, FormHelperText, FormLabel } from '@mui/material'

import CheckboxListItem from './CheckboxListItem'

const CheckboxList = ({
  disabled,
  error,
  helperText,
  horizontal,
  items,
  label,
  onChange,
  onBlur,
  onFocus,
  renderAtEnd,
  value,
}) => {
  const initialValue = value
  const [currentValue, setCurrentValue] = useState(initialValue)

  useEffect(() => {
    if (initialValue !== currentValue) {
      setCurrentValue(initialValue)
    }
  }, [currentValue, initialValue])

  const handleChange = (itemKey, checked) => {
    const newValue = {
      ...(currentValue || {}),
      [itemKey]: checked,
    }
    setCurrentValue(newValue)
    onChange(newValue)
  }

  return (
    <FormControl error={error} disabled={disabled} sx={{ display: 'block' }}>
      {label && <FormLabel>{label}</FormLabel>}
      <FormGroup row={horizontal} sx={{ gap: 1 }}>
        {items.map(({ label: itemLabel, value: itemKey }) => {
          itemKey = itemKey ?? itemLabel
          const checked = !!(currentValue || {})[itemKey]

          return (
            <Box key={itemKey}>
              <CheckboxListItem
                value={itemKey}
                label={itemLabel}
                checked={checked}
                onChange={(event, checked) => handleChange(itemKey, checked)}
                onBlur={onBlur}
                onFocus={onFocus}
              />
              {renderAtEnd && <span>{renderAtEnd(itemKey, checked, currentValue)}</span>}
            </Box>
          )
        })}
      </FormGroup>
      <FormHelperText>{helperText}</FormHelperText>
    </FormControl>
  )
}

export default CheckboxList
