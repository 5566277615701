import { get } from 'lodash'

import { RENOVATIONS_TYPE, RENT_LOSS_AMOUNT_FIELD_NAMES } from 'shared/constants/acas'
import ACASCalculations from 'shared/report-calculations/income-approach/acas/acas-calculations'
import { mapValuesToDisplay } from 'shared/report-calculations/income-approach/acas/helpers'
import IncomeApproachCalculations from 'shared/report-calculations/income-approach/income-approach-calculations'

import { makeDefaultRentLossItemArray } from 'shared/utils/acas/helpers'

export const getCapRateConclusionValues = state => {
  const capRateOperands = getCapRateOperands(state)

  const formData = capRateOperands.formData
  const reportInformation = capRateOperands.reportInformation

  const netOperatingIncome = capRateOperands.netOperatingIncome
  const concludedCapRate = formData.concludedCapRate
  const indicatedValue = IncomeApproachCalculations.calculateIndicatedValue(netOperatingIncome.total, concludedCapRate)

  const npvAdjustments = get(capRateOperands, 'npvAdjustments')
  const asCompleteNpvAdjustments = get(capRateOperands, 'asCompleteNpvAdjustments')
  const asStabilizedNpvAdjustments = get(capRateOperands, 'asStabilizedNpvAdjustments')

  const asCompleteDiscountRate = get(capRateOperands, 'asCompleteDiscountRate', 0)
  const asStabilizedDiscountRate = get(capRateOperands, 'asStabilizedDiscountRate', 0)

  const asStabilizedResRentLossItems = capRateOperands.asStabilizedResRentLossItems
  const asCompleteResRentLossItems = capRateOperands.asCompleteResRentLossItems

  const asStabilizedCommercialRentLossItems = capRateOperands.asStabilizedCommercialRentLossItems
  const asCompleteCommercialRentLossItems = capRateOperands.asCompleteCommercialRentLossItems

  const asStabilizedLossItems = capRateOperands.asStabilizedLossItems
  const asCompleteLossItems = capRateOperands.asCompleteLossItems

  const asCompleteMonthsOfRentLoss = capRateOperands.asCompleteMonthsOfRentLoss
  const asStabilizedMonthsOfRentLoss = capRateOperands.asStabilizedMonthsOfRentLoss

  const { squareFootage, totalUnitCount, valueAsComplete, valueAsStabilized } = capRateOperands
  const roundingFactor = formData.roundingFactor
  const dateOfValuation = reportInformation.dateOfValuation

  const finalDates = ACASCalculations.calculateDatesOfFinalValue({
    dateOfValuation,
    asCompleteMonthsOfRentLoss,
    asStabilizedMonthsOfRentLoss,
  })

  const finalValues = ACASCalculations.calculateFinalValues({
    indicatedValue,
    npvAdjustments,
    asCompleteNpvAdjustments,
    asStabilizedNpvAdjustments,
    asStabilizedResRentLossItems,
    asCompleteResRentLossItems,
    asStabilizedCommercialRentLossItems,
    asCompleteCommercialRentLossItems,
    asStabilizedLossItems,
    asCompleteLossItems,
    roundingFactor,
    squareFootage,
    unitCount: totalUnitCount,
    includeValueAsComplete: valueAsComplete,
    includeValueAsStabilized: valueAsStabilized,
    asCompleteDiscountRate,
    asStabilizedDiscountRate,
  })
  const finalDisplayValues = mapValuesToDisplay(finalValues)

  return {
    ...formData,
    ...capRateOperands,
    ...finalDates,
    ...finalDisplayValues,
  }
}

const getCapRateOperands = state => {
  const reportInformation = get(state, 'report.reportData.report.reportInformation')
  const formData = get(state, 'report.reportData.incomeApproach.capRateConclusion')
  const concludedCapRate = get(formData, 'concludedCapRate') || 0

  const npvAdjustments = get(formData, 'npvAdjustments') || []
  const asCompleteNpvAdjustments = get(formData, 'asCompleteNpvAdjustments') || []
  const asStabilizedNpvAdjustments = get(formData, 'asStabilizedNpvAdjustments') || []

  const asStabilizedMarketValueWithDiscount = get(formData, 'asStabilizedMarketValueWithDiscount') || 0
  const asCompleteMarketValueWithDiscount = get(formData, 'asCompleteMarketValueWithDiscount') || 0
  const asStabilizedDiscountRate = get(formData, 'asStabilizedDiscountRate', 0)
  const asCompleteDiscountRate = get(formData, 'asCompleteDiscountRate', 0)

  const residentialUnits = get(
    state,
    'report.reportData.incomeApproach.residentialIncome.residentialProjectedRentRoll.units',
    []
  )
  const residentialVCLossPercentage = get(
    state,
    'report.reportData.incomeApproach.potentialGrossIncome.residentialVCLossPercentage',
    0
  )

  const commercialUnits = get(
    state,
    'report.reportData.incomeApproach.commercialIncome.commercialProjectedRentRoll.units',
    []
  )
  const commercialVCLossPercentageAggregate = get(
    state,
    'report.reportData.incomeApproach.potentialGrossIncome.commercialVCLossPercentageAggregate',
    0
  )

  const defaultResidentialRentLossItemArray = makeDefaultRentLossItemArray(
    residentialUnits,
    residentialVCLossPercentage,
    RENT_LOSS_AMOUNT_FIELD_NAMES.RESIDENTIAL
  )

  const defaultCommercialRentLossItemArray = makeDefaultRentLossItemArray(
    commercialUnits,
    commercialVCLossPercentageAggregate,
    RENT_LOSS_AMOUNT_FIELD_NAMES.COMMERCIAL
  )

  const asStabilizedResRentLossItems =
    get(formData, 'asStabilizedResRentLossItems') || defaultResidentialRentLossItemArray

  const asCompleteResRentLossItems = get(formData, 'asCompleteResRentLossItems') || defaultResidentialRentLossItemArray

  const asStabilizedCommercialRentLossItems =
    get(formData, 'asStabilizedCommercialRentLossItems') || defaultCommercialRentLossItemArray

  const asCompleteCommercialRentLossItems =
    get(formData, 'asCompleteCommercialRentLossItems') || defaultCommercialRentLossItemArray

  const asCompleteMonthsOfRentLoss = get(formData, 'asCompleteMonthsOfRentLoss')
  const asStabilizedMonthsOfRentLoss = get(formData, 'asStabilizedMonthsOfRentLoss')

  const proForma = get(state, `report.reportData.incomeApproach.proForma`)
  const netOperatingIncome = get(proForma, 'netOperatingIncome')

  const propertyInformation = get(state, 'report.reportData.propertyInformation')

  const renovations = get(propertyInformation, 'renovations')
  const typeOfRenovation = get(renovations, 'prospectiveRenovationType', RENOVATIONS_TYPE.NO_RENOVATIONS)

  const propertySummary = get(propertyInformation, 'propertySummary') || 0
  const squareFootage = get(propertySummary, 'squareFootage') || 0

  const totalUnitCount = get(formData, 'totalUnits') || 0

  const asStabilizedLossItems = get(formData, 'asStabilizedLossItems') || []
  const asCompleteLossItems = get(formData, 'asCompleteLossItems') || []

  const reportSettings = get(state, 'report.reportSettings')
  const valueAsComplete = get(reportSettings, 'valueAsComplete')
  const valueAsStabilized = get(reportSettings, 'valueAsStabilized')

  return {
    reportInformation,
    formData,
    concludedCapRate,
    netOperatingIncome,
    renovations,
    typeOfRenovation,
    npvAdjustments,
    asCompleteNpvAdjustments,
    asStabilizedNpvAdjustments,
    asStabilizedResRentLossItems,
    asCompleteResRentLossItems,
    asStabilizedLossItems,
    asCompleteLossItems,
    asCompleteMonthsOfRentLoss,
    asStabilizedMonthsOfRentLoss,
    squareFootage,
    valueAsComplete,
    valueAsStabilized,
    asStabilizedCommercialRentLossItems,
    asCompleteCommercialRentLossItems,
    totalUnitCount,
    asStabilizedDiscountRate,
    asCompleteDiscountRate,
    asStabilizedMarketValueWithDiscount,
    asCompleteMarketValueWithDiscount,
  }
}
