import React from 'react'

import CloseConfirmDialog from '../../../../shared/components/CloseConfirmDialog'

const DeleteExpenseForecastDialog = ({ open, onClose, onConfirm }) => {
  return (
    <CloseConfirmDialog
      open={open}
      onClose={onClose}
      onConfirm={onConfirm}
      title="Delete Expense Category"
      text="Are you sure you want to delete this expense? This action cannot be undone. All data will be lost."
      confirmButtonText="Confirm"
    />
  )
}

export default DeleteExpenseForecastDialog
