import React from 'react'
import { Checkbox, ListItemText } from '@mui/material'

import Select from '../select'

const MultiSelect = props => {
  const { value, options, renderValue } = props

  const getOptionLabel = value => {
    return options.find(option => option.value === value)?.label || ''
  }
  const defaultRenderValue = selected => {
    return Array.isArray(selected) ? selected.map(getOptionLabel).join(', ') : ''
  }

  return (
    <Select
      multiple
      renderValue={renderValue || defaultRenderValue}
      MenuProps={{
        PaperProps: {
          style: {
            padding: 0,
          },
        },
      }}
      renderOption={option => {
        return (
          <>
            <Checkbox
              sx={{ paddingLeft: 0 }}
              checked={
                value?.indexOf(option.value) !== -1 || (option.checked ? option.checked(option.value, value) : false)
              }
            />
            <ListItemText primary={option.label} sx={{ paddingLeft: 0, paddingRight: 0 }} />
          </>
        )
      }}
      {...props}
    ></Select>
  )
}

export default React.memo(MultiSelect)
