import React from 'react'

import { SECTIONS, showSection } from 'shared/helpers/propertyType'
import { SimpleTable } from '@bowery-valuation/ui-components'
import { formatCurrencyInt, formatPercentageString, toPercentageString } from 'client-shared/utils/numberFormatters'

import { useColumnDefinitions } from './useColumnDefinitions'
import { useProFormaRows } from './useProFormaRows'

export const ProFormaTable = ({ includePercentageOfPgiAndEgi, propertyType }) => {
  const showPerUnit = showSection(SECTIONS.HAS_RESIDENTIAL, propertyType)

  const columns = useColumnDefinitions(includePercentageOfPgiAndEgi, showPerUnit)
  const rows = useProFormaRows(propertyType)
  const getCustomColumnConfig = React.useCallback(column => {
    if (column.name === 'perUnit') {
      return {
        cellEditorParams: {
          inputProps: '$0,0',
        },
      }
    } else if (column.name === 'total') {
      return {
        cellEditorParams: {
          inputProps: '$0,0',
        },
        formatValueForDisplay: (value, _inputProps, otherOptions) => {
          if (otherOptions?.data?.label === 'Operating Expense Ratio') {
            return toPercentageString(value)
          }
          return formatCurrencyInt(value)
        },
      }
    } else if (column.name === 'percentageOfPgiAndEgi') {
      return {
        formatValueForDisplay: (value, _inputProps, otherOptions) => {
          if (otherOptions?.data?.label === 'Operating Expenses') {
            return '% of EGI'
          }
          return isFinite(value) ? formatPercentageString(value, 2) : ''
        },
      }
    }
    return {}
  }, [])

  return (
    <SimpleTable.Readonly
      actionCellHidden
      columns={columns}
      disableVirtualization
      getCustomColumnConfig={getCustomColumnConfig}
      hideIndexColumn
      id="proforma"
      rows={rows}
    />
  )
}
