import React from 'react'
import { compose, pure } from 'recompose'
import { withStyles } from '@mui/styles'
import { FieldArray } from 'react-final-form-arrays'

import CertificationRequirements from '../CertificationRequirements'

import AdditionalRequirements from './AdditionalRequirements'

const styles = {
  certificationRequirements: {
    paddingLeft: 16,
    paddingRight: 16,
  },
}

const Edit = ({ classes, requirements, appraisers, ...props }) => (
  <React.Fragment>
    <FieldArray name="additionalRequirements" component={AdditionalRequirements} {...props} />
    <CertificationRequirements
      title="USPAP Requirements"
      requirements={requirements}
      classes={{ root: classes.certificationRequirements }}
      hasOnlyOneAppraiser={appraisers.length === 1}
    />
  </React.Fragment>
)

Edit.propTypes = {}

Edit.defaultProps = {}

export default compose(withStyles(styles), pure)(Edit)
