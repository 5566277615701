import React from 'react'

import { withRouter } from 'react-router-dom'

import { Button } from '@mui/material'

const aggregateFns = (...fns) => fns.forEach(fn => (typeof fn === 'function' ? fn() : void 0))

const RouterButton = React.forwardRef(
  ({ children, color = 'inherit', history, link, linkText, location, onClick, target, ...props }, ref) => {
    const handleClick = React.useCallback(
      event => {
        if (typeof onClick === 'function') {
          onClick(event, { history, location })
        }
      },
      [history, location, onClick]
    )
    const changeLocation = React.useCallback(() => {
      if (typeof link === 'string') {
        history.push(link)
      }
    }, [history, link])
    const onClickRoutine = React.useCallback(
      event => aggregateFns(handleClick(event), changeLocation),
      [changeLocation, handleClick]
    )

    if (target) {
      return <Button children={children || linkText} href={link} {...{ ...props, color, target, onClick, ref }} />
    }

    return <Button children={children || linkText} onClick={onClickRoutine} {...{ ...props, color, ref }} />
  }
)

export default withRouter(RouterButton)
