import { get } from 'lodash'

import { formatCurrencyInt } from 'shared/utils/formatters/numberFormatters'

function mapData({ lineItems }) {
  const moreThanOneItem = lineItems.length > 1
  let itemsParagraph = ''
  const mappedItems = lineItems?.map((item, index) => {
    const category = item.otherIncomeCategory || '[INCOME CATEGORY]'
    const amount = formatCurrencyInt(item.otherIncomeAnnualAmount || 0)
    if (index === 0) {
      itemsParagraph += `We note that ${category} is providing ${amount} of additional annual income for the subject. `
    } else {
      itemsParagraph += `${category} is also providing ${amount} of annual income for the subject. `
    }
    return {
      category,
      amount,
      index,
    }
  })
  return {
    includeDiscussion: lineItems && lineItems.length,
    moreThanOneItem,
    items: mappedItems,
    itemsParagraph,
  }
}

export const mapDTO = formValues => {
  return mapData(formValues)
}

export const mapDataModel = report => {
  const lineItems = get(report, 'potential_gross_income.otherIncome', [])
  const mappedReportToFormLineItems = lineItems.map(lineItem => {
    return {
      otherIncomeCategory: lineItem.category,
      otherIncomeAnnualAmount: lineItem.amount,
    }
  })
  return mapData({ lineItems: mappedReportToFormLineItems })
}
