import React, { FC, useMemo } from 'react'
import { Grid, Paper, Stack, Typography } from '@mui/material'
import arrayMutators from 'final-form-arrays'

import wrapForm from 'report/forms/wrapForm'

import { NarrativeComponent, RadioButtonList } from 'client-shared/components'

import subjectBudgetIntroduction from 'shared/utils/textGeneration/costApproach/subjectBudgetIntroduction'
import subjectBudgetSummary from 'shared/utils/textGeneration/costApproach/subjectBudgetSummary'
import costApproachIntroduction from 'shared/utils/textGeneration/costApproach/introduction'
import { ExtendedFormApi } from 'client-shared/utils/form'

import { remainingCostOptions } from 'report/forms/cost/SubjectBudget'
import { SUBJECT_BUDGET_PATH } from 'shared/constants/report/keysAndDataPaths'

import { PAPER_WIDTH } from '../../helpers'

import SubjectBudgetTable from './SubjectBudgetTable'
import SubjectAdjustedDirectCosts from './SubjectAdjustedDirectCosts'
import subjectBudgetDecorators from './decorators'

type SubjectBudgetOwnProps = {
  form: ExtendedFormApi
}

export const SubjectBudget: FC<SubjectBudgetOwnProps> = ({ form }) => {
  const { values, batch, change } = form
  const { grossBuildingArea, subjectBudget, asCompleteYear, numberOfResidentialUnits } = values

  const constructionBudget = useMemo(() => {
    return { constructionBudget: subjectBudget?.constructionBudget }
  }, [subjectBudget?.constructionBudget])
  const costsToDate = useMemo(() => {
    return { costsToDate: subjectBudget?.costsToDate }
  }, [subjectBudget?.costsToDate])
  const costsToComplete = useMemo(() => {
    return { costsToComplete: subjectBudget?.costsToComplete }
  }, [subjectBudget?.costsToComplete])
  return (
    <>
      <Paper sx={{ maxWidth: PAPER_WIDTH }}>
        <Stack spacing={2}>
          <NarrativeComponent
            name="introductionParagraph"
            title="Cost Approach Introduction"
            generatedText={costApproachIntroduction.generate}
          />
        </Stack>
      </Paper>
      <Paper sx={{ maxWidth: PAPER_WIDTH, marginTop: '24px' }}>
        <Typography id="subject-budget" variant="h5">
          Subject Budget
        </Typography>
        <Stack spacing={5}>
          <Grid>
            <Typography variant="subtitle1" sx={{ marginTop: 2 }}>
              Remaining Cost Type
            </Typography>
            {/* @ts-ignore */}
            <RadioButtonList name="subjectBudget.remainingCostType" items={remainingCostOptions} horizontal />
            <NarrativeComponent
              title="Subject Budget Introduction"
              generatedText={subjectBudgetIntroduction.generate}
              data={subjectBudgetIntroduction.mapDTO({ subjectBudget, asCompleteYear })}
              name="subjectBudget.subjectBudgetIntroduction"
            />
          </Grid>

          <SubjectBudgetTable
            change={change}
            batch={batch}
            subjectBudget={constructionBudget}
            grossBuildingArea={grossBuildingArea}
            numberOfResidentialUnits={numberOfResidentialUnits}
            field="constructionBudget"
            title="Budget / Developer's Costs"
          />

          <SubjectBudgetTable
            change={change}
            batch={batch}
            subjectBudget={costsToDate}
            grossBuildingArea={grossBuildingArea}
            numberOfResidentialUnits={numberOfResidentialUnits}
            field="costsToDate"
            title="Costs to Date"
          />

          <SubjectBudgetTable
            change={change}
            batch={batch}
            subjectBudget={costsToComplete}
            grossBuildingArea={grossBuildingArea}
            numberOfResidentialUnits={numberOfResidentialUnits}
            field="costsToComplete"
            title="Costs to Complete"
            isEditable={false}
          />

          <NarrativeComponent
            title="Subject Budget Summary"
            generatedText={subjectBudgetSummary.generate}
            name="subjectBudget.subjectBudgetSummary"
          />

          <SubjectAdjustedDirectCosts subjectBudget={constructionBudget} />
        </Stack>
      </Paper>
    </>
  )
}

export default wrapForm(SUBJECT_BUDGET_PATH, {
  heading: 'Subject Budget',
  decorators: [...subjectBudgetDecorators],
  mutators: {
    ...arrayMutators,
  },
})(SubjectBudget)
