import React from 'react'
import PropTypes from 'prop-types'
import { pick } from 'lodash'

import { DialogContent, DialogTitle, FormControl, withStyles, IconButton, Grid } from '@material-ui/core'

import Close from '@mui/icons-material/Close'

import Dropzone from '../../Dropzone'

const FileTypeMimeTypeMap = {
  doc: 'application/msword',
  docx: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  pdf: 'application/pdf',
}

const styles = () => ({
  paper: {
    marginTop: 10,
  },
  clickArea: {
    height: 150,
    width: 500,
  },
  formControl: {
    width: '100%',
  },
  cancelButton: {
    margin: 0,
    padding: '0 2px',
  },
})

class SelectFile extends React.Component {
  static propTypes = {
    label: PropTypes.string.isRequired,
    onDrop: PropTypes.func.isRequired,
    fileTypesRestriction: PropTypes.array,
    handleClose: PropTypes.func,
  }

  getAcceptedFileTypes = () => {
    let accepted = Object.values(FileTypeMimeTypeMap).join(',')
    const { fileTypesRestriction } = this.props
    if (fileTypesRestriction.length > 0) {
      const restrictedAcceptsCollection = pick(FileTypeMimeTypeMap, fileTypesRestriction)
      accepted = Object.values(restrictedAcceptsCollection).join(',')
    }
    return accepted
  }

  render() {
    const { classes, onDrop, label, onCancel } = this.props
    return (
      <>
        <DialogTitle id="simple-dialog-title">
          <Grid container>
            <Grid item xs={11}>{`Select ${label}`}</Grid>
            {onCancel && (
              <Grid container item xs={1} justify="flex-end">
                <IconButton className={classes.cancelButton} aria-label="close">
                  <Close className={classes.cancelButton} onClick={onCancel} />
                </IconButton>
              </Grid>
            )}
          </Grid>
        </DialogTitle>
        <DialogContent>
          <FormControl className={classes.formControl}>
            <div data-qa="dropzone-container">
              <Dropzone
                onDrop={onDrop}
                accept={this.getAcceptedFileTypes()}
                classes={{
                  clickArea: classes.clickArea,
                }}
              />
            </div>
          </FormControl>
        </DialogContent>
      </>
    )
  }
}

export default withStyles(styles)(SelectFile)
