import { handleActions } from 'redux-actions'

import { updateAreFilesUploading } from '../actions/areFilesUploading'

const initialState = false

export const reducer = handleActions(
  {
    [updateAreFilesUploading](state, { payload }) {
      return payload.areFilesUploading
    },
  },
  initialState
)
