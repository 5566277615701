const FORM_HEADING = 'Source Information'
const SOURCE_INFO_HEADER_TOOLTIP = 'Describe where you found the information that will be included in this report.'

const DATA_SOURCES = [
  'Public Record',
  'Tax Map',
  'Owner',
  'Inspection',
  "Appraiser's Estimate",
  'Market Forecast',
  'Expense Forecast',
  'Expense Comparables',
  "Architect's Plans",
  'Client',
  'Purchasing Party',
  'Buyer',
  'CoStar',
  'Primary Source',
  'streeteasy.com',
  "Owner's Representative",
  'Bureau of Labor Statistics',
  'Borrower',
  'Developer',
]

const UNRECOGNIZED_SOURCE_TOOLTIP = 'is not a recognized data source.'

const NO_DATA_SOURCE_SUGGESTIONS_MESSAGE = 'No source under that name.'

export {
  DATA_SOURCES,
  FORM_HEADING,
  NO_DATA_SOURCE_SUGGESTIONS_MESSAGE,
  SOURCE_INFO_HEADER_TOOLTIP,
  UNRECOGNIZED_SOURCE_TOOLTIP,
}
