import React from 'react'
import PropTypes from 'prop-types'
import { compose } from 'redux'
import classNames from 'classnames'
import ReactAutosuggest from 'react-autosuggest'
import match from 'autosuggest-highlight/match'
import parse from 'autosuggest-highlight/parse'
import { TextField, MenuItem, Paper, Typography, withStyles, withTheme } from '@material-ui/core'
import { assignWith, isFunction, isNil, includes, get } from 'lodash'

import Portal from '../Portal'

const styles = theme => ({
  container: {
    flexGrow: 1,
    position: 'relative',
  },
  suggestionsList: {
    margin: 0,
    padding: 0,
    listStyleType: 'none',
  },
  noSuggestionsMessageContainer: {
    padding: 10,
  },
  suggestionsContainer: {
    marginTop: 4,
    maxHeight: 230,
    overflowY: 'auto',
  },
})

class Autosuggest extends React.PureComponent {
  static propTypes = {
    classes: PropTypes.object.isRequired,
  }

  state = {
    currentSuggestions: [],
  }

  constructor(props) {
    super(props)
    this.reactAutosuggest = React.createRef()
  }

  componentDidMount() {
    if (this.props.focusOnMount) {
      const input = get(this.reactAutosuggest, 'current.input', null)
      if (input !== null) {
        input.focus()
      }
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.usePropSuggestionsDirectly && this.props.suggestions !== prevProps.suggestions) {
      this.setState({ currentSuggestions: this.props.suggestions })
    }
  }

  get suggestionsContainerWidth() {
    return get(this.reactAutosuggest, 'current.input.clientWidth', 0)
  }

  renderInput = autosuggestInputProps => {
    const { inputProps, fullWidth, autoFocus, textFieldProps } = this.props
    const { ref, ...otherAutosuggestInputProps } = autosuggestInputProps

    const mergedInputProps = assignWith(
      {},
      { onBlur: this.onInputBlur },
      inputProps,
      otherAutosuggestInputProps,
      (objValue, srcValue) => {
        if (isFunction(objValue) && isFunction(srcValue)) {
          return (...parameters) => {
            objValue(...parameters)
            srcValue(...parameters)
          }
        }
      }
    )

    return (
      <TextField
        data-qa="autosuggest-text-input-field"
        inputRef={ref}
        InputProps={mergedInputProps}
        autoFocus={autoFocus}
        fullWidth={fullWidth}
        {...textFieldProps}
      />
    )
  }

  getMatches = (value, query) => {
    const { caseSensitive } = this.props

    return caseSensitive ? match(value, query) : match(value.toUpperCase(), query.toUpperCase())
  }

  renderSuggestion = (suggestion, { query: inputQuery, isHighlighted }) => {
    const { getSuggestionValue, renderMenuItem } = this.props

    const query = inputQuery.trim()
    const value = getSuggestionValue(suggestion)

    const matches = this.getMatches(value, query)
    const parts = parse(value, matches)

    return renderMenuItem ? (
      renderMenuItem(value, isHighlighted, parts)
    ) : (
      <MenuItem selected={isHighlighted} component="div" data-value={`${value}`}>
        <div>
          {parts.map((part, index) => {
            return part.highlight ? <strong key={index}>{part.text}</strong> : <span key={index}>{part.text}</span>
          })}
        </div>
      </MenuItem>
    )
  }

  renderNoSuggestionsMessage = () => {
    const { classes, value: inputValue, noSuggestionsMessage } = this.props
    const { currentSuggestions } = this.state
    const showNoSuggestionsMessage = noSuggestionsMessage && inputValue && currentSuggestions.length === 0
    return (
      showNoSuggestionsMessage && (
        <div className={classes.noSuggestionsMessageContainer}>
          <Typography variant="caption">{noSuggestionsMessage}</Typography>
        </div>
      )
    )
  }

  renderSuggestionsContainer = ({ containerProps, children }) => {
    const { theme, classes } = this.props
    const paperClasses = classNames(containerProps.className, classes.suggestionsContainer)
    return (
      <Portal zIndex={theme.zIndex.modal + 1} width={this.suggestionsContainerWidth}>
        <Paper {...containerProps} className={paperClasses} square>
          {children}
          {this.renderNoSuggestionsMessage()}
        </Paper>
      </Portal>
    )
  }

  getCurrentSuggestions = inputQuery => {
    const { suggestions, getSuggestionValue } = this.props

    const query = inputQuery.trim()
    const isExactMatch = includes(suggestions, query)
    if (isExactMatch) {
      return [query]
    }

    let currentSuggestions = suggestions
    if (query.length !== 0) {
      currentSuggestions = suggestions.filter(suggestion => {
        const value = getSuggestionValue(suggestion)
        return this.getMatches(value, query).length > 0
      })
    }

    return currentSuggestions.sort((suggestionA, suggestionB) => {
      const matchesLeft = this.getMatches(getSuggestionValue(suggestionA), query)
      const matchesRight = this.getMatches(getSuggestionValue(suggestionB), query)
      return matchesRight.length - matchesLeft.length
    })
  }

  onInputBlur = event => {
    const { getSuggestionValue, onChange, selectOnBlur } = this.props
    const { currentSuggestions } = this.state
    const { current } = this.reactAutosuggest
    if (current) {
      const { highlightedSuggestionIndex } = current.state
      if (selectOnBlur && !isNil(highlightedSuggestionIndex) && currentSuggestions[highlightedSuggestionIndex]) {
        event.target.value = getSuggestionValue(currentSuggestions[highlightedSuggestionIndex])
        onChange(event)
      }
    }
  }

  getRefreshedSuggestions = ({ value }) => {
    const { modifyCurrentSearchSet, usePropSuggestionsDirectly } = this.props
    if (usePropSuggestionsDirectly) {
      return
    }
    const currentSuggestions = this.getCurrentSuggestions(value)
    this.setState({
      currentSuggestions,
    })
    modifyCurrentSearchSet(currentSuggestions)
  }

  handleSuggestionsClearRequested = () => {
    const { modifyCurrentSearchSet, usePropSuggestionsDirectly } = this.props
    if (usePropSuggestionsDirectly) {
      return
    }
    this.setState({
      currentSuggestions: [],
    })
    modifyCurrentSearchSet([])
  }

  render() {
    const {
      classes,
      getSuggestionValue,
      onSuggestionSelected,
      renderSuggestionsContainer,
      value,
      onChange,
      highlightFirstSuggestion,
      selectOnBlur,
      onFocus,
      onBlur,
    } = this.props
    const { currentSuggestions } = this.state

    return (
      <ReactAutosuggest
        ref={this.reactAutosuggest}
        theme={{
          container: classes.container,
          suggestionsList: classes.suggestionsList,
        }}
        suggestions={currentSuggestions}
        getSuggestionValue={getSuggestionValue}
        renderInputComponent={this.renderInput}
        renderSuggestionsContainer={renderSuggestionsContainer || this.renderSuggestionsContainer}
        renderSuggestion={this.renderSuggestion}
        inputProps={{
          value,
          onChange,
          onFocus,
          onBlur,
        }}
        highlightFirstSuggestion={highlightFirstSuggestion || selectOnBlur}
        onSuggestionSelected={onSuggestionSelected}
        onSuggestionsFetchRequested={this.getRefreshedSuggestions}
        onSuggestionsClearRequested={this.handleSuggestionsClearRequested}
      />
    )
  }
}

const StyledAutosuggest = compose(withStyles(styles), withTheme())(Autosuggest)

StyledAutosuggest.displayName = 'Autosuggest'

StyledAutosuggest.propTypes = {
  suggestions: PropTypes.array.isRequired,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.string,
  getSuggestionValue: PropTypes.func,
  onSuggestionSelected: PropTypes.func,
  modifyCurrentSearchSet: PropTypes.func,
  selectOnBlur: PropTypes.bool,
  inputProps: PropTypes.object,
  textFieldProps: PropTypes.object,
  caseSensitive: PropTypes.bool,
  focusOnMount: PropTypes.bool,
  highlightFirstSuggestion: PropTypes.bool,
  noSuggestionsMessage: PropTypes.string,
  renderSuggestionsContainer: PropTypes.func,
  renderMenuItem: PropTypes.func,
  fullWidth: PropTypes.bool,
  autoFocus: PropTypes.bool,
  usePropSuggestionsDirectly: PropTypes.bool,
}

StyledAutosuggest.defaultProps = {
  value: '',
  getSuggestionValue: value => value,
  modifyCurrentSearchSet: () => {},
  selectOnBlur: true,
  inputProps: {},
  textFieldProps: {},
  caseSensitive: false,
  focusOnMount: false,
  highlightFirstSuggestion: true,
  noSuggestionsMessage: null,
  renderSuggestionsContainer: null,
  renderMenuItem: null,
  fullWidth: null,
  autoFocus: false,
  usePropSuggestionsDirectly: false,
}

export default StyledAutosuggest
