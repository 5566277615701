/**
 * Takes an object and flattens it by one level and removes all duplicate values
 * @param obj
 * @returns {*[]}
 */
export const flattenUniqueValues = obj => {
  const rows = []
  for (const key of Object.keys(obj)) {
    rows.push(...obj[key])
  }
  // remove dupes
  return [...new Set(rows)]
}

export const getSiteName = url => {
  const urlData = new URL(url)
  const hostnameParts = urlData.hostname.split('.')

  if (hostnameParts.length === 2) {
    return hostnameParts[0]
  }

  return hostnameParts
    .slice(0, -1)
    .join('.')
    .replace(/^www\./, '')
}
