import PropTypes from 'prop-types'
import React, { useEffect, useState } from 'react'
import { CircularProgress } from '@mui/material'
import { useForm, useField } from 'react-final-form'
import CheckCircleOutline from '@mui/icons-material/CheckCircleOutline'

import { JOB_NUMBER_MESSAGES } from '../constants'

import JobInput from './JobInput'
import { parseJobNumber, validateJobNumber } from './utils'

const JobSyncInputField = ({ syncState, onSyncRequested, onUnsyncRequested }) => {
  const formApi = useForm()
  const { input, meta } = useField('reportNumber', {
    parse: parseJobNumber,
    validate: validateJobNumber,
  })

  const [lastSyncValue, setLastSyncValue] = useState(null)

  useEffect(() => {
    const fieldState = formApi.getFieldState('reportNumber')
    const initialValue = fieldState.value

    const parsedValue = parseJobNumber(initialValue)
    if (initialValue !== parsedValue) {
      formApi.change('reportNumber', parsedValue)
    }
  }, [formApi, onSyncRequested])

  useEffect(() => {
    const isValueChanged = lastSyncValue !== input.value

    if (isValueChanged && meta.visited && meta.valid && !meta.active) {
      onSyncRequested(input.value)
      setLastSyncValue(input.value)
    }
  }, [input.value, lastSyncValue, meta.active, meta.valid, meta.visited, onSyncRequested])

  useEffect(() => {
    if (!meta.valid) {
      onUnsyncRequested()
      setLastSyncValue(null)
    }
  }, [meta.valid, onUnsyncRequested, input.value])

  const isValidationError = meta.touched && !!meta.error
  const isInputInErrorState = isValidationError || syncState.failed

  let helperText

  if (isValidationError) {
    helperText = JOB_NUMBER_MESSAGES.PULL_EXTERNAL_VALIDATION_ERROR
  }
  if (syncState.failed) {
    helperText = JOB_NUMBER_MESSAGES.PULL_EXTERNAL_NOT_FOUND
  }
  if (syncState.completed) {
    helperText = JOB_NUMBER_MESSAGES.PULL_EXTERNAL_VALID
  }
  helperText = helperText ?? JOB_NUMBER_MESSAGES.PULL_EXTERNAL_HELPER_TEXT

  let endAdornment

  if (syncState.inProgress) {
    endAdornment = <CircularProgress sx={{ display: 'flex' }} size={18} />
  }
  if (syncState.completed) {
    endAdornment = <CheckCircleOutline color="success" sx={{ display: 'flex' }} size={18} />
  }

  return (
    <JobInput
      active={meta.active}
      value={input.value}
      onBlur={input.onBlur}
      helperText={helperText}
      onFocus={input.onFocus}
      onChange={input.onChange}
      endAdornment={endAdornment}
      error={isInputInErrorState}
      disabled={syncState.inProgress}
    />
  )
}

JobSyncInputField.propTypes = {
  syncState: PropTypes.shape({
    syncCompleted: PropTypes.bool.isRequired,
    syncFailed: PropTypes.bool.isRequired,
    syncInProgress: PropTypes.bool.isRequired,
  }).isRequired,
  onSyncRequested: PropTypes.func.isRequired,
  onUnsyncRequested: PropTypes.func.isRequired,
}

export default JobSyncInputField
