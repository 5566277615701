import React from 'react'
import PropTypes from 'prop-types'
import { Grid, Paper } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'

import { generateCommercialRentCompsReconciliationDiscussion } from '../../../../../../../shared/utils/textGeneration/incomeApproach/commercialRentReconciliation'

import { GeneratedComment } from '../../../../../shared/components'

import CommercialRentReconciliationItemContainer from './CommercialRentReconciliationItemContainer'

const styles = theme => ({
  loadingContainer: {
    position: 'relative',
    height: 200,
  },
  paper: theme.paper,
})

class CommercialRentReconciliation extends React.PureComponent {
  static propTypes = {
    classes: PropTypes.object.isRequired,
    commercialRentReconciliation: PropTypes.arrayOf(
      PropTypes.shape({
        _id: PropTypes.string.isRequired,
        name: PropTypes.string.isRequired,
      })
    ).isRequired,
    rentRollRentBasis: PropTypes.string.isRequired,
  }

  getCommercialRentCompsReconciliationDiscussion = () => {
    return generateCommercialRentCompsReconciliationDiscussion()
  }

  render() {
    const { commercialRentReconciliation, rentRollRentBasis, classes } = this.props

    return (
      <Grid item container wrap="nowrap" direction="column" spacing={16}>
        {commercialRentReconciliation.length ? (
          <>
            {commercialRentReconciliation.map((reconciliationGroupData, index) => (
              <Grid key={reconciliationGroupData._id} data-qa={`${index}-grid`} item>
                <CommercialRentReconciliationItemContainer
                  reconciliationGroupData={reconciliationGroupData}
                  rentRollRentBasis={rentRollRentBasis}
                  index={index}
                />
              </Grid>
            ))}
            <Grid item data-qa="discussion-paper">
              <Paper className={classes.paper}>
                <Grid container spacing={16}>
                  <Grid item xs={12}>
                    <GeneratedComment
                      isDynamicContent
                      dataPath="commercialRentCompsReconciliationDiscussion"
                      title="Generated Commentary"
                      label="Commercial Rent Comparables Reconciliation Discussion"
                      getGeneratedText={this.getCommercialRentCompsReconciliationDiscussion}
                      tooltipText="The following generated text will appear in the Income Approach section of your report."
                    />
                  </Grid>
                </Grid>
              </Paper>
            </Grid>
          </>
        ) : (
          <Grid item data-qa="no-items-panel">
            <Paper>You need to have one or more Comp Groups to complete a rent reconciliation</Paper>
          </Grid>
        )}
      </Grid>
    )
  }
}

export default withStyles(styles)(CommercialRentReconciliation)
