import { createComposed } from '@ui/tools'

import Default from './Auto'

const defaultOnChange = ([_event, value, _reason, _details], cb) => cb(value)
const defaultOnInputChange = (event, cb) => cb(event.target.value)

const Text = createComposed(Default, {
  fullWidth: true,
  onChange: defaultOnChange,
  onInputChange: defaultOnInputChange,
})

const ComboBox = createComposed(Text, {
  autoHighlight: true,
  clearOnEscape: true,
})

const FreeSolo = createComposed(Default, {
  freeSolo: true,
})

export { ComboBox, FreeSolo, Text }
