import React from 'react'
import { connect } from 'react-redux'
import { get } from 'lodash'

const HideIfIsBlocks = ({ isBlocks, children }) => {
  if (isBlocks) {
    return null
  }
  return <>{children}</>
}

const mapStateToProps = state => {
  const isBlocks = get(state, 'report.reportSettings.isBlocks')

  return {
    isBlocks,
  }
}

export default connect(mapStateToProps)(HideIfIsBlocks)
