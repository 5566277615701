import { get } from 'lodash'

import { GeneratedText, gtLocked } from '../../../../narrative/GeneratedText'

const generate = () => {
  const gt = new GeneratedText()

  gt.t`The Housing Stability & Tenant Protection Act of 2019 was signed into Law in New York on June 14, 2019, 
  severely limiting the future potential increases in rent for vacant rent stabilized units. Individual Apartment 
  Increases (IAI) limits have been greatly reduced as are the allowable annual increases due to Major Capital 
  Improvements (MCI). Also, landlords are now unable to take a vacancy allowance bonus on any vacated rent stabilized 
  unit and must maintain the last legal rent as recorded on the DHCR rent roll, and are thus limited to the one-year 
  or two-year allowed annual increase per the Rent Stabilization Guidelines board.`
  gt.t`\n`
  gt.t`For one-year leases, allowable increases for units subject to rent stabilization are 
  ${gtLocked('oneYearIncrease', 'formatPercentageString')} and for two-year leases allowable increases 
  are set for ${gtLocked('twoYearIncreaseOne', 'formatPercentageString')} in the first year and 
  ${gtLocked('twoYearIncreaseTwo', 'formatPercentageString')} in the second year based on the previous 
  year's rent (an effective increase of 
  ${gtLocked('twoYearIncreaseEffective', 'formatPercentageString')}). Given that most rent stabilized 
  units will turnover during our forecast period, it is necessary to reflect this in our pro forma. After applying 
  the average allowable rent growth to the assumption that 50% of tenants sign one-year leases and 50% of tenants 
  sign two-year leases, the adjusted weighted average increase for rent stabilized units amounts to 
  ${gtLocked('avgIncrease', 'formatPercentageString')}.`

  return gt.getParts()
}

const getStabilizedValues = rentStabilization => {
  return {
    oneYearIncrease: get(rentStabilization, 'stabilizedIncreaseOneYearPct'),
    twoYearIncreaseOne: get(rentStabilization, 'stabilizedIncreaseTwoYearOnePct'),
    twoYearIncreaseTwo: get(rentStabilization, 'stabilizedIncreaseTwoYearTwoPct'),
    twoYearIncreaseEffective: get(rentStabilization, 'stabilizedIncreaseTwoYearEffectivePct'),
    avgIncrease: get(rentStabilization, 'stabilizedIncreaseAvgPct'),
    blendedIncreaseOneYear: get(rentStabilization, 'blendedIncreaseOneYear'),
    blendedIncreaseTwoYear: get(rentStabilization, 'blendedIncreaseTwoYear'),
  }
}

const mapDataFromFormValues = values => {
  const rentStabilization = get(values, 'rentStabilization', {})
  return getStabilizedValues(rentStabilization)
}

const mapDataFromModel = organization => {
  const rentStabilization = get(organization, 'rentStabilization', {})
  return getStabilizedValues(rentStabilization)
}

export default { generate, mapDataFromFormValues, mapDataFromModel }
