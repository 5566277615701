import { GeneratedText } from '../../../narrative/GeneratedText'

import { mapDTO, mapDataModel } from './mappings'

export const NO_FFE =
  "There is not an amount allocated for FF&E in the developer's direct cost budget. The cost of appliances is included in the construction budget; however, developers and investors typically do not view these costs as a separate FF&E cost."

const generate = () => {
  const gt = new GeneratedText()

  gt.t`${NO_FFE}`

  return gt.getParts()
}

export default { generate, mapDTO, mapDataModel }
