import React from 'react'
import PropTypes from 'prop-types'
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Slider,
  Typography,
} from '@mui/material'
import { withStyles } from '@mui/styles'
import Cropper from 'react-easy-crop'
import RefreshOutlined from '@mui/icons-material/RefreshOutlined'
import Close from '@mui/icons-material/Close'

import Loading from 'client-shared/components/Loading/Loading'

import { FULL_ROTATION, QUARTER_ROTATION } from 'client-shared/constants/images'

const INITIAL_STATE = {
  crop: { x: 0, y: 0 },
  rotation: 0,
  angle: 0,
  zoom: 1,
}

class AvatarEditorModal extends React.PureComponent {
  state = { ...INITIAL_STATE }

  componentDidUpdate(prevProps) {
    if (this.props.imageUrl !== prevProps.imageUrl) {
      this.setState({ ...INITIAL_STATE })
    }
  }

  onCropChange = crop => {
    this.setState({ crop })
  }

  onZoomChange = (event, zoom) => {
    this.setState({ zoom })
  }

  onAngleChange = (event, rotation) => {
    this.setState({ rotation: rotation % FULL_ROTATION })
  }

  onCropComplete = (croppedArea, cropOptions) => {
    this.setState({ cropOptions, croppedArea })
  }

  onRotate = () => {
    this.setState(state => ({
      angle:
        (QUARTER_ROTATION * Math.round((state.rotation + state.angle) / QUARTER_ROTATION) + QUARTER_ROTATION) %
        FULL_ROTATION,
      rotation: 0,
    }))
  }

  onSubmit = () => {
    const { onSubmit } = this.props
    const { cropOptions, rotation, angle, croppedArea } = this.state
    onSubmit({ cropOptions, angle, rotation, croppedArea })
  }

  render() {
    const { classes, handleClose, onChangePhoto, imageUrl, isLoading } = this.props
    const { zoom, crop, rotation, angle } = this.state
    return (
      <Dialog disableBackdropClick maxWidth="md" fullWidth open={true} aria-labelledby="avatar-dialog">
        {isLoading && <Loading />}
        <DialogTitle>
          Edit Photo
          <IconButton
            aria-label="close"
            onClick={handleClose}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
            }}
          >
            <Close />
          </IconButton>
        </DialogTitle>
        <DialogContent className={classes.disableOverflow}>
          <div className={classes.cropper}>
            <Cropper
              image={imageUrl}
              crop={crop}
              zoom={zoom}
              rotation={angle + rotation}
              aspect={1}
              cropShape="round"
              onCropChange={this.onCropChange}
              onCropComplete={this.onCropComplete}
            />
            <div className={classes.toolbar}>
              <div>
                <Typography className={classes.label} id="Zoom">
                  Zoom
                  <span>{zoom}</span>
                </Typography>
                <Slider
                  classes={{
                    container: classes.slider,
                    track: classes.track,
                    thumb: classes.thumb,
                  }}
                  value={zoom}
                  min={1}
                  max={3}
                  aria-labelledby="Zoom"
                  onChange={this.onZoomChange}
                />
              </div>
              <div>
                <Typography className={classes.label} id="Zoom">
                  <span>Straighten</span>
                  <span>{rotation}</span>
                </Typography>
                <Typography></Typography>
                <Slider
                  classes={{
                    container: classes.slider,
                    track: classes.track,
                    thumb: classes.thumb,
                  }}
                  value={rotation}
                  step={1}
                  min={-45}
                  max={45}
                  aria-labelledby="Zoom"
                  onChange={this.onAngleChange}
                />
              </div>
              <IconButton aria-label="rotate" onClick={this.onRotate} className={classes.button}>
                <RefreshOutlined />
              </IconButton>
            </div>
          </div>
        </DialogContent>

        <DialogActions className={classes.dialogActions}>
          <Button onClick={onChangePhoto}>Change Photo</Button>
          <Button variant="contained" color="primary" onClick={this.onSubmit}>
            Save Photo
          </Button>
        </DialogActions>
      </Dialog>
    )
  }
}
AvatarEditorModal.propTypes = {
  handleClose: PropTypes.func.isRequired,
  imageUrl: PropTypes.string.isRequired,
  isLoading: PropTypes.bool,
  onChangePhoto: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
}

AvatarEditorModal.defaultProps = {
  isLoading: false,
}

const styles = theme => ({
  toolbar: {
    paddingLeft: 24,
    paddingRight: 24,
    backgroundColor: 'rgba(0,0,0,0.2)',
    width: 'calc(100% - 48px)',
    position: 'absolute',
    bottom: 0,
    display: 'flex',
    justifyContent: 'space-between',
  },
  slider: {
    padding: '20px 0 24px',
  },
  cropper: {
    backgroundColor: '#000000',
    display: 'flex',
    justifyContent: 'center',
    height: 550,
    padding: 0,
    position: 'relative',
    color: '#FFFFFF',
  },
  label: { color: '#FFFFFF', width: 300, display: 'flex', justifyContent: 'space-between' },
  track: { backgroundColor: '#FFFFFF' },
  thumb: { backgroundColor: '#4160D3', top: 1 },
  button: {
    margin: 0,
    color: '#FFFFFF',
    paddingTop: 22,
  },
  dialogActions: { margin: '0 24px 24px' },
  disableOverflow: { overflowX: 'hidden' },
})

export default withStyles(styles)(AvatarEditorModal)
