import { GeneratedText, gtIfElse, gtLocked, gtTemplate } from '../../../narrative/GeneratedText'

import { mapDTO, mapDataModel } from './mappings'

const generate = () => {
  const unitOfComparisonText = gtLocked('unitOfComparisonText', null, 'Unit of Comparison Text')
  const landOrBuildingText = gtIfElse('isLandValue', gtTemplate`land`, gtTemplate`similar buildings`)

  const gt = new GeneratedText()
  gt.t`In the Sales Comparison Approach, an opinion of market value is provided by comparing the subject property to 
        transactions of competitive assets. A major premise is the principle of substitution which holds market value 
        is directly related to the prices of comparable properties as a knowledgeable investor will pay no more for 
        a substitute property.`

  gt.addNewLine()

  gt.t`The steps taken to apply this approach include the following: (a) research, confirm and verify all pertinent 
        data for the most relevant sales within the defined market area; (b) analyze the sales considering appropriate 
        adjustments for material difference in comparison to the subject property; and (c) reconcile the range of 
        adjusted sale data into an opinion of value.`

  gt.addNewLine()

  gt.t`In order to analyze comparable sales, it is necessary to convert the sale prices to an appropriate unit of 
  comparison, a process which facilitates price comparisons between properties of different sizes, and it also 
  enables adjustment for qualitative differences. Since investors typically purchase ${landOrBuildingText} in the 
  subject's area in terms of value per ${unitOfComparisonText}, we have applied this unit of comparison.`

  return gt.getParts()
}

export default { generate, mapDTO, mapDataModel }
