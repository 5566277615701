import React from 'react'
import { makeStyles } from '@mui/styles'

const useStyles = makeStyles(({ palette }) => ({
  icon: {
    color: palette.text.main,
    cursor: 'pointer',
    fontSize: 24,
    '&:hover': {
      color: palette.primary.main,
    },
  },
}))

const createIcon = Icon =>
  React.forwardRef((props, ref) => {
    const classes = useStyles()
    return <Icon className={classes.icon} {...props} />
  })

export default createIcon
