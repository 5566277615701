import {
  SALES_ADJUSTMENT_GRID_KEY,
  SALES_APPROACH_KEY,
  SALES_COMPS_MAP_KEY,
  SALES_COMPS_SEARCH_KEY,
  SALE_VALUE_CONCLUSION_KEY,
} from 'shared/constants/report/keysAndDataPaths'

import SalesCompsMap from '../../../forms/sales/SalesCompsMap/'
import SalesAdjustmentGrid from '../../../forms/sales/SalesAdjustmentGrid/'
import SaleValueConclusion from '../../../forms/sales/SaleValueConclusion/'
import SalesCompsSearch from '../../../forms/sales/SalesCompsSearch/'

import LandValuation from './landValuation'
import ImprovedSales from './improvedSales'

const salesApproach = [
  {
    key: SALES_APPROACH_KEY,
    name: 'Sales',
  },
  ...ImprovedSales,
  ...LandValuation,

  // maintaining legacy keys for feature flags
  {
    key: SALES_COMPS_SEARCH_KEY,
    name: 'Find Comps',
    formComponent: SalesCompsSearch,
  },
  {
    key: SALES_ADJUSTMENT_GRID_KEY,
    name: 'Adjust Comps',
    formComponent: SalesAdjustmentGrid,
  },
  {
    key: SALES_COMPS_MAP_KEY,
    name: 'Create Comp Map',
    formComponent: SalesCompsMap,
  },

  {
    key: SALE_VALUE_CONCLUSION_KEY,
    name: 'Value Conclusion',
    formComponent: SaleValueConclusion,
  },
]
export default salesApproach
