import { defineAction } from 'redux-define'

import { DEFAULT_API_ACTION_STATES } from '../../constants'

export const SET_CONTENT_LIBRARY_DATA = defineAction('SET_CONTENT_LIBRARY_DATA', DEFAULT_API_ACTION_STATES)

export const CREATE_NARRATIVE_TEMPLATE = defineAction('CREATE_NARRATIVE_TEMPLATE', DEFAULT_API_ACTION_STATES)

export const UPDATE_NARRATIVE_TEMPLATE = defineAction('UPDATE_NARRATIVE_TEMPLATE', DEFAULT_API_ACTION_STATES)

export const SELECT_NARRATIVE_TEMPLATE = defineAction('SELECT_NARRATIVE_TEMPLATE')

export const CLEAR_CONTENT_LIBRARY_DATA = defineAction('CLEAR_CONTENT_LIBRARY_DATA')

export const RELOAD_CONTENT_LIBRARY = defineAction('RELOAD_CONTENT_LIBRARY')
