import { identity, includes, isNil, isNumber } from 'lodash'

import { OTHER } from '../../../client/src/report/constants'

export const simplePluralize = (word, count) => `${word}${count === 1 ? '' : 's'}`

export const pluralizeBeVerb = count => (count === 1 ? 'is' : 'are')

export const getProperArticleForNumber = num => {
  const curNumber = parseInt(num, 10)
  if (!isNumber(curNumber)) {
    return ''
  }

  if (includes([8, 11, 18, 80, 81, 82, 83, 84, 85, 86, 87, 88, 89], curNumber)) {
    return 'an'
  }
  return 'a'
}

export const numberToWords = numericValue => {
  if (isNil(numericValue)) {
    return numericValue
  }
  if (typeof numericValue === 'number') {
    return numberToWords(String(numericValue))
  }
  if (numericValue === '0') {
    return 'zero'
  }

  const arr = value => Array.from(value)
  const num = value => Number(value) || 0
  const isEmpty = xs => xs.length === 0
  const take = value => xs => xs.slice(0, value)
  const drop = value => xs => xs.slice(value)
  const reverse = xs => xs.slice(0).reverse()
  const comp = func => funcTwo => value => func(funcTwo(value))
  const not = value => !value
  const chunk = value => xs => (isEmpty(xs) ? [] : [take(value)(xs), ...chunk(value)(drop(value)(xs))])

  const NUMERIC_NAMES_UNDER_TWENTY = [
    '',
    'one',
    'two',
    'three',
    'four',
    'five',
    'six',
    'seven',
    'eight',
    'nine',
    'ten',
    'eleven',
    'twelve',
    'thirteen',
    'fourteen',
    'fifteen',
    'sixteen',
    'seventeen',
    'eighteen',
    'nineteen',
  ]

  const TENS_PLACE_NAMES = ['', '', 'twenty', 'thirty', 'forty', 'fifty', 'sixty', 'seventy', 'eighty', 'ninety']

  const NUMERIC_DENOMINATIONS = [
    '',
    'thousand',
    'million',
    'billion',
    'trillion',
    'quadrillion',
    'quintillion',
    'sextillion',
    'septillion',
    'octillion',
    'nonillion',
  ]

  const DECIMAL_DENOMINATIONS = [' and a Half']

  // this part is really nasty still
  const makeGroup = ([ones, tens, huns]) =>
    [
      num(huns) === 0 ? '' : `${NUMERIC_NAMES_UNDER_TWENTY[huns]} hundred `,
      num(ones) === 0 ? TENS_PLACE_NAMES[tens] : (TENS_PLACE_NAMES[tens] && `${TENS_PLACE_NAMES[tens]}-`) || '',
      NUMERIC_NAMES_UNDER_TWENTY[tens + ones] || NUMERIC_NAMES_UNDER_TWENTY[ones],
    ].join('')

  const thousand = (group, i) => (group === '' ? group : `${group} ${NUMERIC_DENOMINATIONS[i]}`)

  const numericArray = numericValue.split('.')
  const numberInWords = comp(chunk(3))(reverse)(arr(numericArray[0]))
    .map(makeGroup)
    .map(thousand)
    .filter(comp(not)(isEmpty))
    .reverse()
    .join(' ')
    .trim()

  if (numericArray.length === 1) {
    return numberInWords
  }

  switch (numericArray[1]) {
    case '5':
      return numberInWords + DECIMAL_DENOMINATIONS[0]
    default:
      return numberInWords
  }
}

export const getPossiblyOtherValue = (field, options = {}) => {
  const { formatValue = identity, formatOther = identity } = options

  if (!field) {
    return ''
  }

  if (field.value === OTHER) {
    return formatOther(field.other)
  }

  return formatValue(field.value)
}
