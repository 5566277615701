import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import get from 'lodash/get'
import { connect } from 'react-redux'
import { useForm } from 'react-final-form'

import { floodDataShape } from '../../propTypes'
import { FEMA_PULLED_ERROR_ALERT_TEXT, FEMA_PULLED_SUCCESSFULLY_ALERT_TEXT } from '../../../../../shared/constants'

const withPulledFloodData = WrappedComponent => {
  const Container = ({ form, pulledFloodData, ...otherProps }) => {
    const formApi = useForm()

    const floodWriteupProps = {
      showSuccessAlert:
        form.values.floodData.isPulled &&
        !form.values.floodData.isError &&
        !form.values.floodData.isLoading &&
        !form.values.floodHazardDiscussion.modified,
      showErrorAlert:
        form.values.floodData.isError &&
        !form.values.floodData.isLoading &&
        !form.values.floodHazardDiscussion.modified,
      successAlertText: FEMA_PULLED_SUCCESSFULLY_ALERT_TEXT,
      errorAlertText: FEMA_PULLED_ERROR_ALERT_TEXT,
      isLoading: form.values.floodData.isLoading,
    }

    useEffect(() => {
      const { floodData } = pulledFloodData

      if (floodData) {
        formApi.change('floodData', floodData)
      }
    }, [formApi, pulledFloodData])

    return <WrappedComponent form={form} {...otherProps} floodWriteupProps={floodWriteupProps} />
  }

  Container.propTypes = {
    form: PropTypes.object.isRequired,
    pulledFloodData: floodDataShape.isRequired,
  }

  return connect(state => {
    const pulledFloodData = get(state, 'shared.pulledFloodData')
    return {
      pulledFloodData,
    }
  })(Container)
}

export default withPulledFloodData
