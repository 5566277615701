import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'

import { FormControl, TextField } from '@mui/material'
import { withStyles } from '@mui/styles'
import classNames from 'classnames'
import { identity } from 'lodash'
import { Field } from 'react-final-form'

const styles = theme => ({
  root: {},
  input: { padding: '13px 14px', background: theme.palette.common.white },
  formControl: {},
  multiline: {},
})

class BaseText extends PureComponent {
  static propTypes = {
    input: PropTypes.shape({
      name: PropTypes.string.isRequired,
      value: PropTypes.string.isRequired,
    }),
    handleChange: PropTypes.func,
    label: PropTypes.string,
    tooltiptext: PropTypes.string,
    helperText: PropTypes.string,
    autoComplete: PropTypes.oneOf(['on', 'off']),
    // Types inherited from <TextField />
    disabled: PropTypes.bool,
  }

  static defaultProps = {
    displayError: true,
    handleChange: () => {},
    shrink: true,
    fullWidth: true,
    autoComplete: 'on',
  }

  render() {
    const {
      input,
      input: { name, onBlur, onFocus, value, type },
      meta = {},
      classes,
      displayError,
      shrink,
      rows,
      label,
      handleChange,
      helperText,
      rootRef,
      fullWidth,
      autoComplete,
      ...otherProps
    } = this.props

    const onChange = event => {
      input.onChange(event)
      handleChange(event)
    }

    const inErrorState = displayError && meta.touched && !!meta.error
    const errorText = inErrorState ? meta.error : ''
    const shouldLabelShrink = shrink ? true : undefined

    const isMultiline = rows ? true : undefined

    const { formControl, multiline, ...inputClasses } = classes
    return (
      <FormControl
        className={classNames(classes.formControl, { [classes.multiline]: isMultiline })}
        fullWidth={fullWidth}
      >
        <input type="hidden" value={value} />
        <TextField
          label={label}
          name={name}
          InputLabelProps={{
            shrink: shouldLabelShrink,
          }}
          InputProps={{
            classes: inputClasses,
            autoComplete,
          }}
          inputRef={rootRef}
          variant="outlined"
          onChange={onChange}
          onBlur={onBlur}
          onFocus={onFocus}
          value={value}
          type={type}
          error={inErrorState}
          helperText={errorText || helperText}
          rows={rows}
          multiline={isMultiline}
          margin="dense"
          fullWidth={fullWidth}
          {...otherProps}
        />
      </FormControl>
    )
  }
}

export const Text = withStyles(styles)(BaseText)

export default class extends PureComponent {
  static displayName = 'Text'

  static propTypes = {
    name: PropTypes.string.isRequired,
    label: PropTypes.string,
    rows: PropTypes.number,
    tooltiptext: PropTypes.string,
    helperText: PropTypes.string,
    parse: PropTypes.func,
    validate: PropTypes.func,
    displayError: PropTypes.bool,
    shrink: PropTypes.bool,
    required: PropTypes.bool,
  }

  static defaultProps = {
    displayError: true,
    shrink: true,
    parse: identity,
    required: false,
  }

  render() {
    const { name, label, displayError, shrink, rows, validate, ...props } = this.props
    return (
      <Field
        name={name}
        label={label}
        component={Text}
        shrink={shrink}
        validate={validate}
        displayError={displayError}
        rows={rows}
        {...props}
      />
    )
  }
}
