import { PropertyTypes } from 'shared/constants'
import { COMMERCIAL_SUBTYPES_PROPER_CASE, PROPERTY_TYPES_PROPER_CASE } from 'shared/constants/property/propertyTypes'
import { RentTypesUnitsOfMeasure } from 'shared/constants/report/incomeApproach'

export const isRentPsfMonthly = rentBasis => rentBasis === RentTypesUnitsOfMeasure.PER_SF_PER_MONTH

export const getRentPsfLabel = (rentBasis, monthlyLabel, annualLabel) => {
  const rentPsfIsMonthly = isRentPsfMonthly(rentBasis)
  return rentPsfIsMonthly ? monthlyLabel : annualLabel
}

export const getCommercialPropertyType = (propertyType, propertySubtype, useCommercialSubtype = false) => {
  const usePropertySubtype = propertyType === PropertyTypes.COMMERCIAL && propertySubtype && useCommercialSubtype
  return usePropertySubtype
    ? COMMERCIAL_SUBTYPES_PROPER_CASE[propertySubtype]
    : PROPERTY_TYPES_PROPER_CASE[propertyType]
}
