import { GeneratedText, gtConditional, gtLocked, gtTemplate } from '../../../narrative/GeneratedText'

import { mapDTO, mapDataModel } from './mappings'

const generate = () => {
  const gt = new GeneratedText()
  const oneCompany = gtConditional('oneCompany')
  const manyCompanies = gtConditional('manyCompanies')

  oneCompany.children.push(
    ...gtTemplate`${gtLocked(
      'clientCompany',
      null,
      'Client Company'
    )} has engaged Bowery Valuation and is Bowery Valuation’s client for this assignment. `
  )
  gt.addPart(oneCompany)

  manyCompanies.children.push(
    ...gtTemplate`${gtLocked(
      'clientCompany',
      null,
      'Client Companies'
    )} have engaged Bowery Valuation and are Bowery Valuation’s clients for this assignment. `
  )
  gt.addPart(manyCompanies)
  return gt.getParts()
}

export default { generate, mapDTO, mapDataModel }
