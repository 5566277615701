import React from 'react'
import PropTypes from 'prop-types'
import { get } from 'lodash'
import { Grid, Paper, Typography, Stack } from '@mui/material'

import buildingDescription from 'shared/utils/textGeneration/property/buildingDescription'
import currentBuildingDescription from 'shared/utils/textGeneration/property/currentBuildingDescription'

import { required } from 'client-shared/utils/validation'

import { PropertyTypes } from 'shared/constants'

import { SITE_AREA_SF_UNIT_OF_MEASURE, SF_BASIS_OPTIONS, DEFAULT_SF_ANALYSIS_BASIS } from 'shared/constants/report'

import { formatPropertySubtype } from 'shared/helpers/propertyType'

import { Text, CalloutButton, UneditableSection, UneditableField, NarrativeComponent } from 'client-shared/components'

import { ANCHOR_PROPERTY_DESCRIPTION, ANCHOR_SITE_DETAILS } from 'shared/constants/report/dataCollections'
import { PROPERTY_SUMMARY_PATH } from 'shared/constants/report/keysAndDataPaths'
import { GEOGRAPHY_OPTIONS } from 'shared/constants/property/geography'

import wrapForm from '../../wrapForm'
import { SiteAreaTypes } from '../../../constants'

import { selectReportInformation } from '../Zoning/selectors'

import { CensusTractNumber } from './CensusTractNumber'

export const DATA_PATH = PROPERTY_SUMMARY_PATH

const HEADING = 'Summary'
const REFERENCE_GUIDE_LINK =
  'https://docs.google.com/spreadsheets/d/1lvchEM_rFCp6gpAb2rwcxjZBw0MsAoG14WGdyh-rIVQ/edit?usp=sharing'
const AS_COMPLETE_CURRENT_BUILDING_DESCRIPTION_TITLE = 'As Complete Building Description'
const AS_IS_CURRENT_BUILDING_DESCRIPTION_TITLE = 'As Is Building Description'

class PropertySummary extends React.PureComponent {
  static propTypes = {
    currentPath: PropTypes.string,
  }

  get buildingDescriptionTitle() {
    const { valueAsComplete } = this.props

    return valueAsComplete
      ? `${AS_COMPLETE_CURRENT_BUILDING_DESCRIPTION_TITLE} Export`
      : `${AS_IS_CURRENT_BUILDING_DESCRIPTION_TITLE} Export `
  }

  get buildingDescriptionData() {
    const { form, valueAsComplete } = this.props

    return buildingDescription.mapDTO({ valueAsComplete, ...form.values })
  }

  render() {
    const { valueAsComplete, form, isNYCReport, currentPath, locationIdentifier } = this.props
    const { displayInAcresAndSF, basisForSFAnalysis, siteAreaMeasure, altSiteAreaMeasure, isYearBuiltEstimate } =
      form.values

    const defaultBasisSelected = basisForSFAnalysis === DEFAULT_SF_ANALYSIS_BASIS
    const isSupportedGeography = locationIdentifier !== GEOGRAPHY_OPTIONS.OTHER
    const showReferenceGuideLink = !isNYCReport

    const currentPropertyType = get(this.props, 'form.values.current.propertyType')
    const asIsOrAsCompletePropertyType = get(this.props, 'form.values.propertyType')

    const buildingDescriptionToolTipText =
      'The following text will appear in the Letter of Transmittal, Summary of Salient Facts, and the Description of Improvements'

    return (
      <Grid container xs={10} spacing={1.5}>
        {showReferenceGuideLink && (
          <Grid item xs={12}>
            <CalloutButton
              link={REFERENCE_GUIDE_LINK}
              linkText="Visit"
              target="_blank"
              text="Check the Bowery reference guide for data sources outside of NYC."
              variant="link"
            />
          </Grid>
        )}
        <Grid item xs={12}>
          <Paper elevation={2} data-qa="site-details-section-v2-tile">
            <Typography variant="h6">Site Details</Typography>
            <UneditableSection
              name="Found In: Subject Property Data"
              formName="Subject Property Data"
              link="subject-property"
              anchor={ANCHOR_SITE_DETAILS}
              returnDataPath={currentPath}
              editDataMessage
            >
              <Grid container columnSpacing={3} rowSpacing={1}>
                <Grid item xs={6}>
                  <Stack spacing={1}>
                    <UneditableField label="Building Name" name="buildingName" />
                    <UneditableField label="Street Address" name="streetAddress" />
                    <UneditableField label="City" name="city" />
                    <UneditableField label="State" name="state" />
                    <UneditableField label="Zip Code" name="zip" />
                  </Stack>
                </Grid>
                <Grid item xs={6}>
                  <Stack spacing={1}>
                    <UneditableField label="Building Class" name="buildingClass" />
                    <UneditableField label="Street Name" name="streetName" />
                    <UneditableField label="Neighborhood/District" name="neighborhood" />
                    <UneditableField
                      label={`Site Area (${SiteAreaTypes[siteAreaMeasure.toUpperCase()]})`}
                      name="siteArea"
                      thousandSeparator
                    />
                    {displayInAcresAndSF && (
                      <UneditableField
                        label={`Site Area (${SiteAreaTypes[altSiteAreaMeasure.toUpperCase()]})`}
                        name="altSiteArea"
                        thousandSeparator
                        decimalScale={4}
                      />
                    )}
                    <Stack direction="row" spacing={1}>
                      <UneditableField label="Year Built" name="yearBuilt" />
                      {isYearBuiltEstimate && (
                        <Typography sx={{ fontWeight: 'bold' }}>(this is an estimate)</Typography>
                      )}
                    </Stack>
                  </Stack>
                </Grid>
              </Grid>
            </UneditableSection>
            {!isSupportedGeography && (
              <Stack direction="row" spacing={3}>
                <Text
                  label="Unique Property Identifier Type"
                  name="propertyIdentifierType"
                  data-qa="identifier-type-text-input"
                  helperText="e.g. Block/Lot, APN, Parcel ID"
                  margin="normal"
                />
                <Text
                  validate={required}
                  required
                  label="Unique Property Identifier"
                  name="propertyIdentifier"
                  data-qa="identifier-text-input"
                  margin="normal"
                />
              </Stack>
            )}
            <Stack direction="row" spacing={3}>
              <CensusTractNumber name="censusTract" label="Census Tract" margin="normal" />
              <Text label="Building Descriptor" name="buildingDescriptor" margin="normal" />
            </Stack>
          </Paper>
        </Grid>
        <Grid item xs={12}>
          <Paper elevation={2} data-qa="building-description-section-tile">
            {valueAsComplete && <Typography variant="h6">{AS_COMPLETE_CURRENT_BUILDING_DESCRIPTION_TITLE}</Typography>}
            {!valueAsComplete && <Typography variant="h6">{AS_IS_CURRENT_BUILDING_DESCRIPTION_TITLE}</Typography>}
            <UneditableSection
              name="Found In: Subject Property Data"
              formName="Subject Property Data"
              link="subject-property"
              anchor={ANCHOR_PROPERTY_DESCRIPTION}
              returnDataPath={currentPath}
              editDataMessage
            >
              <Grid container columnSpacing={3} rowSpacing={1}>
                <Grid item xs={6}>
                  <Stack spacing={1}>
                    {!defaultBasisSelected && (
                      <UneditableField
                        label={SF_BASIS_OPTIONS[basisForSFAnalysis]}
                        name="basisForSFAnalysisValue"
                        thousandSeparator
                      />
                    )}
                    <UneditableField label="Gross Building Area" name="grossBuildingArea" thousandSeparator />
                    <UneditableField label="Floors" name="floors" />
                    <UneditableField label="Use" name="propertyType" />
                  </Stack>
                </Grid>
                <Grid item xs={6}>
                  <Grid>
                    <Stack spacing={1}>
                      <UneditableField label="Residential Units" name="residentialUnitCount" />
                      <UneditableField label="Commercial Units" name="commercialUnitCount" />
                      <UneditableField label="Type" name="buildingType" />
                      {asIsOrAsCompletePropertyType === PropertyTypes.COMMERCIAL && (
                        <UneditableField
                          label="Property Subtype"
                          name="propertySubtype"
                          formatter={formatPropertySubtype}
                        />
                      )}
                    </Stack>
                  </Grid>
                </Grid>
              </Grid>
            </UneditableSection>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <NarrativeComponent
                  title={this.buildingDescriptionTitle}
                  generatedText={buildingDescription.generate}
                  data={this.buildingDescriptionData}
                  name="buildingDescription"
                  tooltipText={buildingDescriptionToolTipText}
                />
              </Grid>
            </Grid>
          </Paper>
        </Grid>
        {valueAsComplete && (
          <Grid item xs={12}>
            <Paper elevation={2} data-qa="current-building-description-section-tile">
              <Typography variant="h6">Current Building Description</Typography>
              <UneditableSection
                name="Found In: Subject Property Data"
                formName="Subject Property Data"
                link="subject-property"
                anchor={ANCHOR_PROPERTY_DESCRIPTION}
                returnDataPath={currentPath}
                editDataMessage
              >
                <Grid container columnSpacing={3} rowSpacing={1}>
                  <Grid item xs={6}>
                    <Stack spacing={1}>
                      {!defaultBasisSelected && (
                        <UneditableField
                          label={SF_BASIS_OPTIONS[basisForSFAnalysis]}
                          name="current.basisForSFAnalysisValue"
                          thousandSeparator
                        />
                      )}
                      <UneditableField label="Gross Building Area" name="current.grossBuildingArea" thousandSeparator />
                      <UneditableField label="Floors" name="current.floors" />
                      <UneditableField label="Use" name="current.propertyType" />
                    </Stack>
                  </Grid>
                  <Grid item xs={6}>
                    <Stack spacing={1}>
                      <UneditableField label="Residential Units" name="current.residentialUnitCount" />
                      <UneditableField label="Commercial Units" name="current.commercialUnitCount" />
                      <UneditableField label="Type" name="current.buildingType" />
                      {currentPropertyType === PropertyTypes.COMMERCIAL && (
                        <UneditableField
                          label="Property Subtype"
                          name="current.propertySubtype"
                          formatter={formatPropertySubtype}
                        />
                      )}
                    </Stack>
                  </Grid>
                </Grid>
              </UneditableSection>
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <NarrativeComponent
                    title="Current Building Description Export"
                    generatedText={currentBuildingDescription.generate}
                    data={currentBuildingDescription.mapDTO({ valueAsComplete, ...form.values })}
                    name="current.buildingDescription"
                    regenerateOnChange={['commercialUnitCount', 'residentialUnitCount']}
                  />
                </Grid>
              </Grid>
            </Paper>
          </Grid>
        )}
      </Grid>
    )
  }
}

export default wrapForm(
  DATA_PATH,
  {
    heading: HEADING,
    initialValues: {
      siteAreaMeasure: SITE_AREA_SF_UNIT_OF_MEASURE,
    },
  },
  state => {
    const valueAsComplete = get(state, 'report.reportSettings.valueAsComplete')
    const reportInformation = selectReportInformation(state)
    const currentPath = get(state, 'shared.location.form.id')

    return {
      valueAsComplete,
      isNYCReport: reportInformation.isNYCReport,
      locationIdentifier: reportInformation.locationIdentifier,
      currentPath,
    }
  }
)(PropertySummary)
