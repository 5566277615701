import { createAction } from 'redux-actions'
import { defineAction } from 'redux-define'

import { DEFAULT_API_ACTION_STATES } from '../../../../../../shared/constants'

const SEARCH_TAX_COMPS = defineAction('SEARCH_TAX_COMPS', DEFAULT_API_ACTION_STATES)

export const searchTaxComps = createAction(SEARCH_TAX_COMPS.ACTION)
export const searchTaxCompsRequest = createAction(SEARCH_TAX_COMPS.REQUEST)
export const searchTaxCompsReceive = createAction(SEARCH_TAX_COMPS.RECEIVE)
export const searchTaxCompsFail = createAction(SEARCH_TAX_COMPS.FAIL)
