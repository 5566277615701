import React from 'react'
import PropTypes from 'prop-types'
import { TableRow } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'

import { Number } from '../../../../../../shared/components'
import { toPercents, fromPercents } from '../../../../../../shared/utils/numberOperations'

import { Labels } from '../CommercialRentReconciliationConstants'
import { Cell, TotalCell, BaseUnitCell, SubjectUnitCell, HeadingCell } from '../TableCells'

const styles = theme => ({
  center: {
    display: 'block',
    marginLeft: 'auto',
    marginRight: 'auto',
  },
})

class LocationAdjustments extends React.PureComponent {
  static propTypes = {
    classes: PropTypes.object.isRequired,
    colsCount: PropTypes.number.isRequired,
    fieldPrefix: PropTypes.string.isRequired,
    reconciliationGroupData: PropTypes.object.isRequired,
    units: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
    rentComps: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
    disableInputs: PropTypes.bool.isRequired,
  }

  render() {
    const { classes, colsCount, fieldPrefix, reconciliationGroupData, units, rentComps, disableInputs } = this.props

    return (
      <React.Fragment>
        <TableRow>
          <HeadingCell>{Labels.LOCATION_ADJUSTMENTS}</HeadingCell>
        </TableRow>
        <TableRow data-qa="map-row">
          <Cell colSpan={colsCount}>
            <img className={classes.center} src={reconciliationGroupData.rentCompsMapUrl} alt="map" />
          </Cell>
        </TableRow>
        <TableRow data-qa="better-worse-neighborhood-row">
          <TotalCell data-qa="row-label-cell">{Labels.BETTER_WORSE_NEIGHBORHOOD}</TotalCell>
          <BaseUnitCell data-qa="base-unit-cell">-</BaseUnitCell>
          {units.map((unit, index) => (
            <SubjectUnitCell data-qa={`subject-unit-${index}-cell`} key={unit._id}>
              <Number
                decimalScale={2}
                name={`${fieldPrefix}.commercialUnits[${index}].adjustments.locationBWNeighborhood`}
                adornment="%"
                format={toPercents}
                normalize={fromPercents}
                disabled={disableInputs}
              />
            </SubjectUnitCell>
          ))}
          {rentComps.map((rentComp, index) => (
            <Cell data-qa={`rent-comp-${index}-cell`} key={rentComp._id}>
              <Number
                name={`${fieldPrefix}.rentComps[${index}].adjustments.locationBWNeighborhood`}
                decimalScale={2}
                adornment="%"
                format={toPercents}
                normalize={fromPercents}
                disabled={disableInputs}
              />
            </Cell>
          ))}
        </TableRow>
        <TableRow data-qa="location-within-neighborhood-row">
          <TotalCell data-qa="row-label-cell">{Labels.LOCATION_WITHIN_NEIGHBORHOOD}</TotalCell>
          <BaseUnitCell data-qa="base-unit-cell">-</BaseUnitCell>
          {units.map((unit, index) => (
            <SubjectUnitCell data-qa={`subject-unit-${index}-cell`} key={unit._id}>
              <Number
                name={`${fieldPrefix}.commercialUnits[${index}].adjustments.locationWithNeighborhood`}
                adornment="%"
                format={toPercents}
                normalize={fromPercents}
                disabled={disableInputs}
              />
            </SubjectUnitCell>
          ))}
          {rentComps.map((rentComp, index) => (
            <Cell data-qa={`rent-comp-${index}-cell`} key={rentComp._id}>
              <Number
                name={`${fieldPrefix}.rentComps[${index}].adjustments.locationWithNeighborhood`}
                adornment="%"
                format={toPercents}
                normalize={fromPercents}
                disabled={disableInputs}
              />
            </Cell>
          ))}
        </TableRow>
      </React.Fragment>
    )
  }
}

export default withStyles(styles)(LocationAdjustments)
