import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { eq } from 'lodash'
import { Grid } from '@mui/material'

import { DragDropContext } from 'react-beautiful-dnd'

import { FieldArray } from 'react-final-form-arrays'

import { getExtendedFormApi } from '../../shared/utils/form'
import { SUBJECT_PROPERTY_CATEGORIES } from '../../report/forms/final/SwotAnalysis/constants'
import SubjectPropertyCategory from '../../report/forms/final/SwotAnalysis/SubjectPropertyCategoryContainer'
import { DEFAULT_SWOT_ANALYSIS } from '../../../../libs/bowery-libs/constants/report/swotAnalysis'

import withCMSForm from './withCMSForm'

const GRID_CONTAINER_GUTTER = 2

const SWOTAnalysis = ({ values, errors, touched, form }) => {
  const [dragging, setDragging] = useState(false)
  const extendedFormApi = getExtendedFormApi({ values, errors, touched, form })

  const onDragStart = () => setDragging(true)

  const onDragEnd = ({ source, destination, type }) => {
    setDragging(false)

    if (!destination) {
      return
    }

    form.mutators.move(type, source.index, destination.index)
  }

  return (
    <Grid container spacing={GRID_CONTAINER_GUTTER}>
      <DragDropContext onDragStart={onDragStart} onDragEnd={onDragEnd}>
        {SUBJECT_PROPERTY_CATEGORIES.map((category, index) => {
          if (!('swotAnalysis' in extendedFormApi.values)) {
            extendedFormApi.values.swotAnalysis = DEFAULT_SWOT_ANALYSIS
          }
          if (!(category in extendedFormApi.values.swotAnalysis)) {
            extendedFormApi.values.swotAnalysis[category] =
              category in DEFAULT_SWOT_ANALYSIS ? DEFAULT_SWOT_ANALYSIS[category] : ['']
          }
          const values = extendedFormApi.values.swotAnalysis[category]

          return (
            <Grid item xs={12}>
              <FieldArray
                key={index}
                isEqual={eq}
                name={`swotAnalysis.${category}`}
                values={values}
                dragging={dragging}
                changeField={extendedFormApi.change}
                component={SubjectPropertyCategory}
              />
            </Grid>
          )
        })}
      </DragDropContext>
    </Grid>
  )
}

SWOTAnalysis.propTypes = {
  values: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired,
  touched: PropTypes.object.isRequired,
  form: PropTypes.object.isRequired,
}

export default withCMSForm(SWOTAnalysis, 'SWOT Analysis')
