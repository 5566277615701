import { withStyles } from '@material-ui/core'

import { CheckboxWithLabel } from '../Checkbox'

export default withStyles({
  root: {
    height: 'auto',
    alignItems: 'center',
  },
  formControlLabel: {
    margin: 0,
  },
})(CheckboxWithLabel)
