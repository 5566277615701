import { get } from 'lodash'

import { formatPercentageString } from 'shared/utils/formatters/numberFormatters'

const mapData = asCompleteEntrepreneurialIncentive => {
  return {
    asCompleteEntrepreneurialIncentive: formatPercentageString(asCompleteEntrepreneurialIncentive),
  }
}

export const mapDTO = formValues => {
  const asCompleteEntrepreneurialIncentive = get(formValues, 'conclusion.asCompleteEntrepreneurialIncentive')

  return mapData(asCompleteEntrepreneurialIncentive)
}

export const mapDataModel = report => {
  const asCompleteEntrepreneurialIncentive = get(report, 'costApproach.conclusion.asCompleteEntrepreneurialIncentive')

  return mapData(asCompleteEntrepreneurialIncentive)
}
