import React from 'react'
import PropTypes from 'prop-types'

import { camelCase, isUndefined, lowerCase } from 'lodash'
import { Field, FormSpy } from 'react-final-form'
import { FormControl, FormControlLabel, FormHelperText, FormLabel, Radio, RadioGroup } from '@mui/material'
import { OnChange } from 'react-final-form-listeners'

import { required as requiredValidation } from '../../utils/validation'
import Text from '../Text'

const OTHER_OPTION = 'Other'

const isOtherOption = value => lowerCase(value) === lowerCase(OTHER_OPTION)

export class MaterialRadioButtons extends React.PureComponent {
  get otherInput() {
    const { input, otherInputProps, otherName } = this.props
    const { name: radioInputName, value: selectedValue } = input ?? {}
    const otherInputName = otherName ? otherName : camelCase(`${OTHER_OPTION} ${radioInputName}`)

    /**
     The only purpose of using FormSpy here is getting access to form API to change otherInput value. Field component is
     used as it somehow memorizes the validation result regardless of other Text input rendered or not, it means that
     that even if Text input is hidden, validation of the Field will still work and won't let us save the form.
     **/

    return (
      <>
        {isOtherOption(selectedValue) && (
          <Text
            label={OTHER_OPTION}
            name={otherInputName}
            required
            validate={requiredValidation}
            {...otherInputProps}
          />
        )}
        <FormSpy subscription={{}}>
          {({ form }) => (
            <OnChange name={radioInputName}>
              {(__, prevSelectedValue) => isOtherOption(prevSelectedValue) && form.change(otherInputName, null)}
            </OnChange>
          )}
        </FormSpy>
      </>
    )
  }

  get hasOtherOption() {
    const { items } = this.props
    return !!items.find(({ value }) => isOtherOption(value))
  }

  render() {
    const {
      disabled,
      helperText,
      horizontal,
      input,
      items,
      label,
      labelPlacement,
      name,
      onBlur,
      onChange,
      onFocus,
      required,
      sx,
      value,
    } = this.props
    const {
      value: inputValue,
      onChange: inputOnChange,
      name: inputName,
      onFocus: inputOnFocus,
      onBlur: inputOnBlur,
    } = input ?? {}
    return (
      <FormControl disabled={disabled} required={required} data-qa={`${name || inputName}-radio-group`}>
        {label && <FormLabel disabled={disabled}>{label}</FormLabel>}
        <RadioGroup
          name={name || inputName}
          onChange={onChange || inputOnChange}
          row={horizontal}
          sx={{ minHeight: 40, mt: 0 }}
          value={value || inputValue}
          onFocus={onFocus || inputOnFocus}
          onBlur={onBlur || inputOnBlur}
        >
          {items.map(({ value, label, disabled }) => {
            const attributes = {}
            if (value === inputValue) {
              attributes['data-qa'] = 'checked'
            }
            return (
              <FormControlLabel
                control={<Radio {...attributes} />}
                key={value}
                labelPlacement={labelPlacement}
                sx={{ mb: 3 / 4, ...sx }}
                {...{ disabled, label, value }}
              />
            )
          })}
        </RadioGroup>
        {this.hasOtherOption && this.otherInput}
        {helperText && <FormHelperText margin="dense">{helperText}</FormHelperText>}
      </FormControl>
    )
  }
}

export function RadioButtonList({ label, name, items, format, helperText, parse, onChange, ...props }) {
  const formattedItems = React.useMemo(
    () =>
      items.map(({ value, ...rest }) => ({
        value: format(value),
        ...rest,
      })),
    [format, items]
  )
  return (
    <Field
      component={MaterialRadioButtons}
      format={format}
      helperText={helperText}
      items={formattedItems}
      label={label}
      name={name}
      onChange={onChange}
      parse={parse}
      {...props}
    />
  )
}

RadioButtonList.propTypes = {
  format: PropTypes.func,
  horizontal: PropTypes.bool,
  items: PropTypes.arrayOf(
    PropTypes.shape({
      disabled: PropTypes.bool,
      label: PropTypes.node.isRequired,
      value: PropTypes.any.isRequired,
    })
  ).isRequired,
  label: PropTypes.string,
  name: PropTypes.string.isRequired,
  otherInputProps: PropTypes.object,
  otherName: PropTypes.string,
  parse: PropTypes.func,
}

RadioButtonList.defaultProps = {
  format: value => value,
  horizontal: false,
  otherInputProps: {},
  parse: value => value,
  useArrayNaming: false,
}

export function NumberRadioButtonList(props) {
  return (
    <RadioButtonList
      {...props}
      format={value => (!isUndefined(value) ? value.toString() : value)}
      parse={value => (!isUndefined(value) ? parseFloat(value) : value)}
    />
  )
}

NumberRadioButtonList.protoTypes = {
  format: PropTypes.func,
  horizontal: PropTypes.bool,
  items: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.any.isRequired,
      label: PropTypes.string.isRequired,
    })
  ).isRequired,
  label: PropTypes.string,
  name: PropTypes.string.isRequired,
  parse: PropTypes.func,
}

export const BooleanRadioButtonList = React.memo(({ format, parse, ...props }) => (
  <RadioButtonList format={format} parse={parse} {...props} />
))

BooleanRadioButtonList.defaultProps = {
  format: value => (!isUndefined(value) ? value.toString() : value),
  parse: value => (!isUndefined(value) ? value === 'true' : value),
}
