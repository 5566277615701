import React from 'react'

import { Stack } from '@mui/material'

import MarketInformation from './MarketInformation'
import InsertedDocuments from './InsertedDocuments'

const PropertyMarket = ({
  areaItems,
  authenticatedUser,
  canInsertFromDropbox,
  form,
  getErrorText,
  getMarketReports,
  getNeighborhoodAndMarkets,
  includeMarketingTime,
  isLoading,
  marketAnalysesUseItems,
  marketAnalysisUses,
  marketItems,
  neighborhoodItems,
  removeFile,
  submarketItems,
  usesForAnalysis,
}) => (
  <Stack spacing={2}>
    <MarketInformation form={form} includeMarketingTime={includeMarketingTime} />
    <InsertedDocuments
      {...{
        areaItems,
        authenticatedUser,
        canInsertFromDropbox,
        getErrorText,
        getMarketReports,
        getNeighborhoodAndMarkets,
        isLoading,
        marketAnalysesUseItems,
        marketAnalysisUses,
        marketItems,
        neighborhoodItems,
        removeFile,
        submarketItems,
        usesForAnalysis,
      }}
    />
  </Stack>
)

PropertyMarket.displayName = 'PropertyMarket'

export default PropertyMarket
