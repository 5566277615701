import React from 'react'
import PropTypes from 'prop-types'
import { get, head } from 'lodash'
import { connect } from 'react-redux'
import { Route, Switch, Redirect } from 'react-router'

import SideBar from '../layout/sidebar'
import { Loading } from '../../../shared/components'
import buildingCompForms from '../constants/buildingCompForms'
import { buildingCompFetch as fetchBuildingComp, clearBuildingComp } from '../redux/actions'

import PageLayout from './PageLayout'

class BuildingComp extends React.PureComponent {
  static propTypes = {
    isLoaded: PropTypes.bool.isRequired,
    fetchBuildingComp: PropTypes.func.isRequired,
    clearBuildingComp: PropTypes.func.isRequired,
  }

  componentWillMount() {
    const { match, isLoaded, fetchBuildingComp } = this.props

    const { reportId, buildingCompId } = match.params

    if (!isLoaded) {
      fetchBuildingComp({ reportId, buildingCompId })
    }
  }

  componentWillUnmount() {
    const { clearBuildingComp } = this.props

    clearBuildingComp()
  }

  get initialRoute() {
    const { match } = this.props
    const { reportId, buildingCompId } = match.params

    const firstForm = head(buildingCompForms)

    return (
      <Redirect
        exact
        from={`/report/${reportId}/building-comp/${buildingCompId}`}
        to={`/report/${reportId}/building-comp/${buildingCompId}/${firstForm.path}`}
      />
    )
  }

  get routes() {
    const routes = []

    this.generateRoutes(routes, buildingCompForms)
    return routes
  }

  generateRoutes = (routes, forms) => {
    forms.forEach(form => {
      if (form.forms) {
        return this.generateRoutes(form.forms, routes)
      } else {
        if (form.formComponent) {
          routes.push(
            <Route
              key={form.path}
              path={`/report/:reportId/building-comp/:buildingCompId/${form.path}`}
              render={props => (
                <form.formComponent
                  {...props}
                  nextFormPath={form.nextFormPath}
                  prevFormPath={form.prevFormPath}
                  nextFormName={form.nextFormName}
                  prevFormName={form.prevFormName}
                />
              )}
            />
          )
        }
      }
    })
  }

  render() {
    const { isLoaded } = this.props

    if (!isLoaded) {
      return <Loading />
    }

    return (
      <PageLayout>
        <SideBar forms={buildingCompForms} />
        <Switch>
          {this.routes}
          {this.initialRoute}
        </Switch>
      </PageLayout>
    )
  }
}

export default connect(
  state => ({
    isLoaded: get(state, 'buildingComp.isLoaded'),
  }),
  {
    fetchBuildingComp,
    clearBuildingComp,
  }
)(BuildingComp)
