import { get, inRange } from 'lodash'

import { CONCLUDED_LIABILITY_TYPES } from 'shared/constants/incomeApproach'

import TaxCalculations from 'shared/report-calculations/income-approach/tax/tax-calculations'

export const inRangePsf = (totalExpensesPsf, expenseComps) => {
  const minSf = get(expenseComps, 'psfMin')
  const maxSf = get(expenseComps, 'psfMax')

  if (inRange(totalExpensesPsf, minSf, maxSf)) {
    return '2'
  }

  if (totalExpensesPsf >= maxSf) {
    return '3'
  }

  if (totalExpensesPsf <= minSf) {
    return '1'
  }
  return 'Cannot generate language.'
}

export const inRangePerUnit = (totalExpensesPerUnit, expenseComps) => {
  const minUnit = get(expenseComps, 'unitMin')
  const maxUnit = get(expenseComps, 'unitMax')

  if (inRange(totalExpensesPerUnit, minUnit, maxUnit)) {
    return '2'
  }
  if (totalExpensesPerUnit >= maxUnit) {
    return '3'
  }
  if (totalExpensesPerUnit <= minUnit) {
    return '1'
  }
  return 'Cannot generate language.'
}

export const expenseReconciliationLookup = {
  111: 'Our forecast is near the comparable range on both a per square foot and per unit basis, as well as logically placed in relation to the historical performance of the asset. Thus, this forecast is reasonable and will be applied in our valuation analysis.',
  112: 'Our forecast is near the comparable range on both a per square foot and per unit basis, as well as logically placed in relation to the projected performance of the asset. Thus, this forecast is reasonable and will be applied in our valuation analysis.',
  113: 'Our forecast is near the comparable range on both a per square foot and per unit basis, as well as logically placed in relation to both the historical and projected performance of the asset. Thus, this forecast is reasonable and will be applied in our valuation analysis.',
  114: 'Our forecast is near the comparable range on both a per square foot and per unit basis. Thus, this forecast is reasonable and will be applied in our valuation analysis.',
  121: "Our forecast is within the comparable range on a per square foot basis, but below the range on a per unit basis, which is appropriate due to the relatively small sizes of the subject's units. Further, our forecast is logically placed in relation to the historical performance of the asset. Thus, this forecast is reasonable and will be applied in our valuation analysis.",
  122: "Our forecast is within the comparable range on a per square foot basis, but below the range on a per unit basis, which is appropriate due to the relatively small sizes of the subject's units. Further, our forecast is logically placed in relation to the projected performance of the asset. Thus, this forecast is reasonable and will be applied in our valuation analysis.",
  123: "Our forecast is within the comparable range on a per square foot basis, but below the range on a per unit basis, which is appropriate due to the relatively small sizes of the subject's units. Further, our forecast is logically placed in relation to both the historical and projected performance of the asset. Thus, this forecast is reasonable and will be applied in our valuation analysis.",
  124: "Our forecast is within the comparable range on a per square foot basis, but below the range on a per unit basis, which is appropriate due to the relatively small sizes of the subject's units. Thus, this forecast is reasonable and will be applied in our valuation analysis.",
  131: "Our forecast is below comparable range on a per unit basis and above the range on a per square foot basis; this disparity is appropriate due to the relatively small sizes of the subject's units. Further, our forecast is logically placed in relation to the historical performance of the asset. Thus, this forecast is reasonable and will be applied in our valuation analysis.",
  132: "Our forecast is below comparable range on a per unit basis and above the range on a per square foot basis; this disparity is appropriate due to the relatively small sizes of the subject's units. Further, our forecast is logically placed in relation to the projected performance of the asset. Thus, this forecast is reasonable and will be applied in our valuation analysis.",
  133: "Our forecast is below comparable range on a per unit basis and above the range on a per square foot basis; this disparity is appropriate due to the relatively small sizes of the subject's units. Further, our forecast is logically placed in relation to both the historical and projected performance of the asset.",
  134: "Our forecast is below comparable range on a per unit basis and above the range on a per square foot basis; this disparity is appropriate due to the relatively small sizes of the subject's units. Thus, this forecast is reasonable and will be applied in our valuation analysis.",
  211: "Our forecast is within the comparable range on a per unit basis, but below the range on a per square foot basis, which is appropriate due to the relatively large sizes of the subject's units. Further, our forecast is logically placed in relation to the historical performance of the asset. Thus, this forecast is reasonable and will be applied in our valuation analysis.",
  212: "Our forecast is within the comparable range on a per unit basis, but below the range on a per square foot basis, which is appropriate due to the relatively large sizes of the subject's units. Further, our forecast is logically placed in relation to the projected performance of the asset. Thus, this forecast is reasonable and will be applied in our valuation analysis.",
  213: "Our forecast is within the comparable range on a per unit basis, but below the range on a per square foot basis, which is appropriate due to the relatively large sizes of the subject's units. Further, our forecast is logically placed in relation to both the historical and projected performance of the asset. Thus, this forecast is reasonable and will be applied in our valuation analysis.",
  214: "Our forecast is within the comparable range on a per unit basis, but below the range on a per square foot basis, which is appropriate due to the relatively large sizes of the subject's units. Thus, this forecast is reasonable and will be applied in our valuation analysis.",
  221: 'Our forecast is within the comparable range on both a per square foot and per unit basis, as well as logically placed in relation to the historical performance of the asset. Thus, this forecast is reasonable and will be applied in our valuation analysis.',
  222: 'Our forecast is within the comparable range on both a per square foot and per unit basis, as well as logically placed in relation to the projected performance of the asset. Thus, this forecast is reasonable and will be applied in our valuation analysis.',
  223: 'Our forecast is within the comparable range on both a per square foot and per unit basis, as well as logically placed in relation to both the historical and projected performance of the asset. Thus, this forecast is reasonable and will be applied in our valuation analysis.',
  224: 'Our forecast is within the comparable range on both a per square foot and per unit basis. Thus, this forecast is reasonable and will be applied in our valuation analysis.',
  231: "Our forecast is within the comparable range on a per unit basis, but above the range on a per square foot basis, which is appropriate due to the relatively small sizes of the subject's units. Further, our forecast is logically placed in relation to the historical performance of the asset. Thus, this forecast is reasonable and will be applied in our valuation analysis.",
  232: "Our forecast is within the comparable range on a per unit basis, but above the range on a per square foot basis, which is appropriate due to the relatively small sizes of the subject's units. Further, our forecast is logically placed in relation to the projected performance of the asset. Thus, this forecast is reasonable and will be applied in our valuation analysis.",
  233: "Our forecast is within the comparable range on a per unit basis, but above the range on a per square foot basis, which is appropriate due to the relatively small sizes of the subject's units. Further, our forecast is logically placed in relation to both the historical and projected performance of the asset. Thus, this forecast is reasonable and will be applied in our valuation analysis.",
  234: "Our forecast is within the comparable range on a per unit basis, but above the range on a per square foot basis, which is appropriate due to the relatively small sizes of the subject's units. Thus, this forecast is reasonable and will be applied in our valuation analysis.",
  311: "Our forecast is above comparable range on a per unit basis, but below the range on a per square foot basis; this disparity is appropriate due to the relatively large sizes of the subject's units. Further, our forecast is logically placed in relation to the historical performance of the asset. Thus, this forecast is reasonable and will be applied in our valuation analysis.",
  312: "Our forecast is above comparable range on a per unit basis, but below the range on a per square foot basis; this disparity is appropriate due to the relatively large sizes of the subject's units. Further, our forecast is logically placed in relation to the projected performance of the asset. Thus, this forecast is reasonable and will be applied in our valuation analysis.",
  313: "Our forecast is above comparable range on a per unit basis, but below the range on a per square foot basis; this disparity is appropriate due to the relatively large sizes of the subject's units. Further, our forecast is logically placed in relation to both the historical and projected performance of the asset. Thus, this forecast is reasonable and will be applied in our valuation analysis.",
  314: "Our forecast is above comparable range on a per unit basis, but below the range on a per square foot basis; this disparity is appropriate due to the relatively large sizes of the subject's units. Thus, this forecast is reasonable and will be applied in our valuation analysis.",
  321: "Our forecast is within the comparable range on a per square foot basis, but above the range on a per unit basis, which is appropriate due to the relatively large sizes of the subject's units. Further, our forecast is logically placed in relation to the historical performance of the asset. Thus, this forecast is reasonable and will be applied in our valuation analysis.",
  322: "Our forecast is within the comparable range on a per square foot basis, but above the range on a per unit basis, which is appropriate due to the relatively large sizes of the subject's units. Further, our forecast is logically placed in relation to the projected performance of the asset. Thus, this forecast is reasonable and will be applied in our valuation analysis.",
  323: "Our forecast is within the comparable range on a per square foot basis, but above the range on a per unit basis, which is appropriate due to the relatively large sizes of the subject's units. Further, our forecast is logically placed in relation to both the historical and projected performance of the asset. Thus, this forecast is reasonable and will be applied in our valuation analysis.",
  324: "Our forecast is within the comparable range on a per square foot basis, but above the range on a per unit basis, which is appropriate due to the relatively large sizes of the subject's units. Thus, this forecast is reasonable and will be applied in our valuation analysis.",
  331: 'Our forecast is near the comparable range on both a per square foot and per unit basis, as well as logically placed in relation to the historical performance of the asset. Thus, this forecast is reasonable and will be applied in our valuation analysis.',
  332: 'Our forecast is near the comparable range on both a per square foot and per unit basis, as well as logically placed in relation to the projected performance of the asset. Thus, this forecast is reasonable and will be applied in our valuation analysis.',
  333: 'Our forecast is near the comparable range on both a per square foot and per unit basis, as well as logically placed in relation to both the historical and projected performance of the asset. Thus, this forecast is reasonable and will be applied in our valuation analysis.',
  334: 'Our forecast is near the comparable range on both a per square foot and per unit basis. Thus, this forecast is reasonable and will be applied in our valuation analysis.',
}

export const generateTotalExpenseRangeSentence = (
  expenseHistory,
  expenseComps,
  expenseForecastTotal = { sf: 0, unit: 0 }
) => {
  if (!expenseHistory || !expenseComps) return ''

  const inRangeSf = inRangePsf(expenseForecastTotal.sf, expenseComps)
  const inRangeUnit = inRangePerUnit(expenseForecastTotal.unit, expenseComps)

  const hasOwnerProForma = expenseHistory.length && expenseHistory.some(item => item.isProjected)

  const hasHistoricalExpense = expenseHistory.some(item => {
    return !item.isProjected && item.expense > 0
  })

  let dataSources = ''
  if (hasHistoricalExpense && hasOwnerProForma) {
    dataSources = '3'
  } else if (hasHistoricalExpense && !hasOwnerProForma) {
    dataSources = '1'
  } else if (!hasHistoricalExpense && hasOwnerProForma) {
    dataSources = '2'
  } else {
    dataSources = '4'
  }
  const lookupId = `${inRangeUnit}${inRangeSf}${dataSources}`

  // eslint-disable-next-line prettier/prettier
  return expenseReconciliationLookup[lookupId] ?? ''
}

const getProjectedTaxLiability = report => {
  const {
    tax_info: { projected, basis },
  } = report

  const residentialUnits = get(report, 'property_information.resUnits', 0)
  const squareFootage = get(report, 'property_information.squareFootageForCalculations', 0)
  const { concludedLiabilityType, concludedLiabilityPerBasis, taxLiability } = projected

  let projectedTaxLiability
  if (concludedLiabilityType === CONCLUDED_LIABILITY_TYPES.TOTAL) {
    projectedTaxLiability = taxLiability
  } else {
    projectedTaxLiability = TaxCalculations.calculateLiabilityFromPerBasisValue(
      basis,
      concludedLiabilityPerBasis,
      squareFootage,
      residentialUnits
    )
  }
  return projectedTaxLiability
}
export const getRealEstateTaxes = report => {
  if (get(report, 'tax_info.projected.includedInExport', false)) {
    return getProjectedTaxLiability(report)
  }

  return get(report, 'tax_info.tax_liability', 0)
}
