const EIGHTY_PERCENT = 80
const ONE_HUNDRED_PERCENT = 100

const TICK_INTERVAL = 1000

const SECONDS_IN_MINUTE = 60

const PREPARING_REPORT_MESSAGE = 'Finishing up...'

const PROGRESS_BAR_COLORS = {
  CriticalError: '#D74F4F',
  Error: '#D74F4F',
  Complete: '#2c877c',
  Warning: '#FFB800',
  Pending: '#4260D3',
}

export {
  TICK_INTERVAL,
  SECONDS_IN_MINUTE,
  ONE_HUNDRED_PERCENT,
  EIGHTY_PERCENT,
  PREPARING_REPORT_MESSAGE,
  PROGRESS_BAR_COLORS,
}
