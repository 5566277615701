import React from 'react'
import { get } from 'lodash'
import PropTypes from 'prop-types'
import ObjectID from 'bson-objectid'
import { connect } from 'react-redux'

import { GEOGRAPHY_OPTIONS } from '../../../../shared/constants/properties'
import { Button } from '../../../../shared/components'

import { reportSettingsSelector } from './selectors'

const AddTaxRateButton = ({ form, path, reportSettings, displayUnconditionally }) => {
  const { locationIdentifier } = reportSettings
  const formPath = path ? `${path}.additionalTaxRates` : 'additionalTaxRates'
  const addTaxRate = () => {
    const { values, change, focus } = form
    const additionalTaxRates = get(values, formPath, [])

    const id = new ObjectID().toJSON()
    const updatedTaxRates = [...additionalTaxRates, { label: 'Additional Tax Rate', value: 0, _id: id, id }]

    change(formPath, updatedTaxRates)
    focus(formPath)
  }

  if (!displayUnconditionally && locationIdentifier === GEOGRAPHY_OPTIONS.NY) {
    return null
  }

  return (
    <Button variant="contained" onClick={addTaxRate}>
      Add Additional Tax Rate +
    </Button>
  )
}

AddTaxRateButton.propTypes = {
  path: PropTypes.string,
  form: PropTypes.object.isRequired,
  reportSettings: PropTypes.shape({
    locationIdentifier: PropTypes.string.isRequired,
  }).isRequired,
  displayUnconditionally: PropTypes.bool,
}

export default connect(state => ({ reportSettings: reportSettingsSelector(state) }))(AddTaxRateButton)
