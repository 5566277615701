import { useState } from 'react'

const useBoolean = (initialValue: boolean) => {
  const [value, setValue] = useState(initialValue)
  const setTrue = () => setValue(true)
  const setFalse = () => setValue(false)

  return [value, setTrue, setFalse] as const
}

export default useBoolean
