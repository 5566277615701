import arrayToSentence from 'array-to-sentence'

import { get, uniq } from 'lodash'

import {
  generateCompsIndicesSentence,
  generateDownwardAdjustmentSentence,
  generateUpwardAdjustmentSentence,
  getAdjustCompsIndices,
  getCompsFromFormValues,
} from 'shared/utils/textGeneration/salesApproach'
import { ADJUSTMENT_GROUPS } from 'shared/helpers/salesApproach/salesCompAdjustmentConstants'
import { hasAdjustments } from 'shared/helpers/salesApproach/salesCompAdjustments'
import { getLastWordSeparator } from 'shared/utils/sentenceBuilder'

const getLocationWithinNeighborhoodGeneratedText = values => {
  const { upwardComps, downwardComps } = getCompsFromFormValues(
    values,
    'locationInNeighborhood',
    ADJUSTMENT_GROUPS.LOCATION
  )
  const generateWriteup = hasAdjustments(values.compAdjustments, 'locationInNeighborhood', ADJUSTMENT_GROUPS.LOCATION)

  const { neighborhood } = values
  let writeUp = ''

  if (generateWriteup) {
    if (upwardComps.length) {
      writeUp += `${writeUp ? ' ' : ''}Comparable${upwardComps.length > 1 ? 's' : ''} ${generateCompsIndicesSentence(
        upwardComps,
        getLastWordSeparator
      )} ${upwardComps.length > 1 ? 'are' : 'is'} located in${upwardComps.length > 1 ? '' : ' an'} inferior area${
        upwardComps.length > 1 ? 's' : ''
      } within ${neighborhood} ${generateUpwardAdjustmentSentence(upwardComps)}`
    }

    if (downwardComps.length) {
      writeUp += `${writeUp ? ' ' : ''}Comparable${downwardComps.length > 1 ? 's' : ''} ${generateCompsIndicesSentence(
        downwardComps,
        getLastWordSeparator
      )} ${downwardComps.length > 1 ? 'are' : 'is'} located in${downwardComps.length > 1 ? '' : ' a'} superior area${
        downwardComps.length > 1 ? 's' : ''
      } within ${neighborhood}, ${generateDownwardAdjustmentSentence(downwardComps)}`
    }
  }
  return writeUp
}

const getNeighborhoodGeneratedText = values => {
  const { upwardComps, downwardComps } = getCompsFromFormValues(values, 'neighborhood', ADJUSTMENT_GROUPS.LOCATION)

  const generateWriteup = hasAdjustments(values.compAdjustments, 'neighborhood', ADJUSTMENT_GROUPS.LOCATION)
  const { selectedComps } = values
  let writeUp = ''

  if (generateWriteup) {
    if (upwardComps.length) {
      const salesCompsNeighborhoods = uniq(
        selectedComps
          .filter(comp => {
            const adjustCompsIndices = getAdjustCompsIndices(upwardComps)
            return adjustCompsIndices.includes(comp.index)
          })
          .map(comp => comp.propertyInformation.neighborhood)
      )

      writeUp += `${writeUp ? ' ' : ''}Comparable${upwardComps.length > 1 ? 's' : ''} ${generateCompsIndicesSentence(
        upwardComps,
        getLastWordSeparator
      )} ${upwardComps.length > 1 ? 'are' : 'is'} located in ${arrayToSentence(
        salesCompsNeighborhoods,
        getLastWordSeparator
      )}, which ${salesCompsNeighborhoods.length > 1 ? 'are' : 'is'}${
        salesCompsNeighborhoods.length > 1 ? '' : ' an'
      } inferior neighborhood${salesCompsNeighborhoods.length > 1 ? 's' : ''}, ${generateUpwardAdjustmentSentence(
        upwardComps
      )}`
    }

    if (downwardComps.length) {
      const salesCompsNeighborhoods = uniq(
        selectedComps
          .filter(comp => {
            const adjustCompsIndices = getAdjustCompsIndices(downwardComps)
            return adjustCompsIndices.includes(comp.index)
          })
          .map(comp => comp.propertyInformation.neighborhood)
      )
      writeUp += `${writeUp ? ' ' : ''}Comparable${downwardComps.length > 1 ? 's' : ''} ${generateCompsIndicesSentence(
        downwardComps,
        getLastWordSeparator
      )} ${downwardComps.length > 1 ? 'are' : 'is'} located in ${arrayToSentence(
        salesCompsNeighborhoods,
        getLastWordSeparator
      )}, which ${salesCompsNeighborhoods.length > 1 ? 'are' : 'is'}${
        salesCompsNeighborhoods.length > 1 ? '' : ' a'
      } superior neighborhood${salesCompsNeighborhoods.length > 1 ? 's' : ''}, ${generateDownwardAdjustmentSentence(
        downwardComps
      )}`
    }
  }

  return writeUp
}

const mapData = (selectedComps, compAdjustments, neighborhood, city, state) => {
  const hasLocationWithinNeighborhoodAdjustment = hasAdjustments(
    compAdjustments,
    'locationInNeighborhood',
    ADJUSTMENT_GROUPS.LOCATION
  )
  const hasNeighborhoodAdjustment = hasAdjustments(compAdjustments, 'neighborhood', ADJUSTMENT_GROUPS.LOCATION)
  const hasNoNeighborhoodAdjustments = !hasLocationWithinNeighborhoodAdjustment && !hasNeighborhoodAdjustment

  let locationSentence = ''
  if (hasLocationWithinNeighborhoodAdjustment) {
    locationSentence = getLocationWithinNeighborhoodGeneratedText({ selectedComps, compAdjustments, neighborhood })
  }

  let neighborhoodSentence = ''
  if (hasNeighborhoodAdjustment) {
    neighborhoodSentence = getNeighborhoodGeneratedText({ selectedComps, compAdjustments })
  }

  return {
    neighborhood,
    city,
    state,
    hasLocationWithinNeighborhoodAdjustment,
    locationSentence,
    hasNeighborhoodAdjustment,
    neighborhoodSentence,
    hasNoNeighborhoodAdjustments,
  }
}

export const mapDTO = formValues => {
  const { neighborhood, city, state, selectedComps, compAdjustments } = formValues
  return mapData(selectedComps, compAdjustments, neighborhood, city, state)
}

export const mapDataModel = (report, parent) => {
  if (!parent.parent()) {
    return undefined
  }

  const salesApproach = parent.parent()
  const { salesCompsAdjustments = {}, salesComps = {} } = salesApproach
  const { compAdjustments = [] } = salesCompsAdjustments
  const { selectedComps = [] } = salesComps

  const propertyInformation = get(report, 'property_information', {})
  const { city, state, marketNeighborhood: neighborhood } = propertyInformation

  return mapData(selectedComps, compAdjustments, neighborhood, city, state)
}
