import { STATE_NAMES } from '../states'

export const GOOGLE_ADDRESS_BOROUGH = {
  'New York': 'Manhattan',
  Manhattan: 'Manhattan',
  Brooklyn: 'Brooklyn',
  'The Bronx': 'Bronx',
  Bronx: 'Bronx',
  Queens: 'Queens',
  'Staten Island': 'Staten Island',
}

export const GEOGRAPHY_OPTIONS = {
  NY: STATE_NAMES.NY,
  NJ: STATE_NAMES.NJ,
  OTHER: 'Other',
}

export const NYC_ZIP_CODES = [
  '10453',
  '10457',
  '10460',
  '10458',
  '10467',
  '10468',
  '10451',
  '10452',
  '10456',
  '10454',
  '10455',
  '10459',
  '10474',
  '10463',
  '10471',
  '10466',
  '10469',
  '10470',
  '10475',
  '10461',
  '10462',
  '10464',
  '10465',
  '10472',
  '10473',
  '11212',
  '11213',
  '11216',
  '11233',
  '11238',
  '11209',
  '11214',
  '11228',
  '11204',
  '11218',
  '11219',
  '11230',
  '11234',
  '11236',
  '11239',
  '11223',
  '11224',
  '11229',
  '11235',
  '11201',
  '11205',
  '11215',
  '11217',
  '11231',
  '11203',
  '11210',
  '11225',
  '11226',
  '11207',
  '11208',
  '11211',
  '11222',
  '11220',
  '11232',
  '11206',
  '11221',
  '11237',
  '10026',
  '10027',
  '10030',
  '10037',
  '10039',
  '10001',
  '10011',
  '10018',
  '10019',
  '10020',
  '10036',
  '10029',
  '10035',
  '10010',
  '10016',
  '10017',
  '10022',
  '10012',
  '10013',
  '10014',
  '10004',
  '10005',
  '10006',
  '10007',
  '10038',
  '10280',
  '10002',
  '10003',
  '10009',
  '10021',
  '10028',
  '10044',
  '10065',
  '10075',
  '10128',
  '10023',
  '10024',
  '10025',
  '10031',
  '10032',
  '10033',
  '10034',
  '10040',
  '11361',
  '11362',
  '11363',
  '11364',
  '11354',
  '11355',
  '11356',
  '11357',
  '11358',
  '11359',
  '11360',
  '11365',
  '11366',
  '11367',
  '11412',
  '11423',
  '11432',
  '11433',
  '11434',
  '11435',
  '11436',
  '11101',
  '11102',
  '11103',
  '11104',
  '11105',
  '11106',
  '11374',
  '11375',
  '11379',
  '11385',
  '11691',
  '11692',
  '11693',
  '11694',
  '11695',
  '11697',
  '11004',
  '11005',
  '11411',
  '11413',
  '11422',
  '11426',
  '11427',
  '11428',
  '11429',
  '11414',
  '11415',
  '11416',
  '11417',
  '11418',
  '11419',
  '11420',
  '11421',
  '11368',
  '11369',
  '11370',
  '11372',
  '11373',
  '11377',
  '11378',
  '10302',
  '10303',
  '10310',
  '10306',
  '10307',
  '10308',
  '10309',
  '10312',
  '10301',
  '10304',
  '10305',
  '10314',
]

export const QUEENS_NEIGHBORHOODS = [
  'Arverne',
  'Astoria',
  'Bayside',
  'Bellerose',
  'Breezy Point',
  'Cambria Heights',
  'College Point',
  'Corona',
  'East Elmhurst',
  'Elmhurst',
  'Far Rockaway',
  'Floral Park',
  'Flushing',
  'Forest Hills',
  'Fresh Meadows',
  'Glen Oaks',
  'Hollis',
  'Howard Beach',
  'Jackson Heights',
  'Jamaica',
  'Kew Gardens',
  'Little Neck',
  'Long Island City',
  'Maspeth',
  'Middle Village',
  'Oakland Gardens',
  'Ozone Park',
  'Queens Village',
  'Rego Park',
  'Richmond Hill',
  'Ridgewood',
  'Rockaway Park',
  'Rosedale',
  'Saint Albans',
  'South Ozone Park',
  'South Richmond Hill',
  'Springfield Gardens',
  'Sunnyside',
  'Whitestone',
  'Woodhaven',
  'Woodside',
]

export const NYC_BOROUGHS = ['New York', 'Manhattan', 'The Bronx', 'Bronx', 'Brooklyn', 'Staten Island', 'Queens']

export const njDistrictCountyMap = {
  HAMILTON_08330: { cityName: 'HAMILTON', districtCode: '0112', countyName: 'ATLANTIC', zip: '08330' },
  ABSECON_08205: { cityName: 'ABSECON', districtCode: '0101', countyName: 'ATLANTIC', zip: '08205' },
  ATLANTIC_CITY_08401: { cityName: 'ATLANTIC CITY', districtCode: '0102', countyName: 'ATLANTIC', zip: '08401' },
  ATLANTIC_CITY_08404: {
    cityName: 'ATLANTIC CITY',
    districtCode: '0102',
    countyName: 'ATLANTIC',
    recordType: 'PO BOX',
    zip: '08404',
  },
  ATLANTIC_CITY_08405: {
    cityName: 'ATLANTIC CITY',
    districtCode: '0102',
    countyName: 'ATLANTIC',
    recordType: 'UNIQUE',
    zip: '08405',
  },
  LONGPORT_08403: { cityName: 'LONGPORT', districtCode: '0115', countyName: 'ATLANTIC', zip: '08403' },
  WEYMOUTH_08330: {
    cityName: 'WEYMOUTH',
    districtCode: '0123',
    countyName: 'ATLANTIC',
    recordType: 'WEYMOUTH, NJ is NOT ACCEPTABLE - USE MAYS LANDING',
    zip: '08330',
  },
  MAYS_LANDING_08330: {
    cityName: 'MAYS LANDING',
    districtCode: '0123',
    countyName: 'ATLANTIC',
    zip: '08330',
  },
  ABSECON_08201: { cityName: 'ABSECON', districtCode: '0101', countyName: 'ATLANTIC', zip: '08201' },
  MARGATE_CITY_08402: { cityName: 'MARGATE CITY', districtCode: '0116', countyName: 'ATLANTIC', zip: '08402' },
  VENTNOR_CITY_08406: { cityName: 'VENTNOR CITY', districtCode: '0122', countyName: 'ATLANTIC', zip: '08406' },
  HAMMONTON_08037: { cityName: 'HAMMONTON', districtCode: '0113', countyName: 'ATLANTIC', zip: '08037' },
  MULLICA_08037: { cityName: 'MULLICA', districtCode: '0117', countyName: 'ATLANTIC', zip: '08037' },
  MULLICA_08215: { cityName: 'MULLICA', districtCode: '0117', countyName: 'ATLANTIC', zip: '08215' },
  MULLICA_08217: { cityName: 'MULLICA', districtCode: '0117', countyName: 'ATLANTIC', zip: '08217' },
  BRIGANTINE_08203: { cityName: 'BRIGANTINE', districtCode: '0103', countyName: 'ATLANTIC', zip: '08203' },
  LINWOOD_08221: { cityName: 'LINWOOD', districtCode: '0114', countyName: 'ATLANTIC', zip: '08221' },
  HAMILTON_08234: { cityName: 'HAMILTON', districtCode: '0112', countyName: 'ATLANTIC', zip: '08234' },
  HAMILTON_08037: { cityName: 'HAMILTON', districtCode: '0112', countyName: 'ATLANTIC', zip: '08037' },
  BUENA_08310: { cityName: 'BUENA', districtCode: '0104', countyName: 'ATLANTIC', zip: '08310' },
  BUENA_VISTA_TOWNSHIP_08310: {
    cityName: 'BUENA VISTA TOWNSHIP',
    districtCode: '0105',
    countyName: 'ATLANTIC',
    recordType: 'BUENA VISTA TOWNSHIP, NJ is NOT ACCEPTABLE - USE BUENA',
    zip: '08310',
  },
  GALLOWAY_08205: { cityName: 'GALLOWAY', districtCode: '0111', countyName: 'ATLANTIC', zip: '08205' },
  GALLOWAY_08201: {
    cityName: 'GALLOWAY',
    districtCode: '0111',
    countyName: 'ATLANTIC',
    recordType: 'GALLOWAY, NJ is NOT ACCEPTABLE - USE ABSECON',
    zip: '08201',
  },
  NORTHFIELD_08225: { cityName: 'NORTHFIELD', districtCode: '0118', countyName: 'ATLANTIC', zip: '08225' },
  SOMERS_POINT_08244: { cityName: 'SOMERS POINT', districtCode: '0121', countyName: 'ATLANTIC', zip: '08244' },
  FOLSOM_08037: {
    cityName: 'FOLSOM',
    districtCode: '0110',
    countyName: 'ATLANTIC',
    recordType: 'FOLSOM, NJ is NOT ACCEPTABLE - USE HAMMONTON',
    zip: '08037',
  },
  CORBIN_CITY_08270: { cityName: 'CORBIN CITY', districtCode: '0106', countyName: 'ATLANTIC', zip: '08270' },
  ESTELL_MANOR_08319: { cityName: 'ESTELL MANOR', districtCode: '0109', countyName: 'ATLANTIC', zip: '08319' },
  PORT_REPUBLIC_08241: { cityName: 'PORT REPUBLIC', districtCode: '0120', countyName: 'ATLANTIC', zip: '08241' },
  PLEASANTVILLE_08232: { cityName: 'PLEASANTVILLE', districtCode: '0119', countyName: 'ATLANTIC', zip: '08232' },
  EGG_HARBOR_CITY_08215: { cityName: 'EGG HARBOR CITY', districtCode: '0107', countyName: 'ATLANTIC', zip: '08215' },
  EGG_HARBOR_08215: {
    cityName: 'EGG HARBOR',
    districtCode: '0108',
    countyName: 'ATLANTIC',
    recordType: 'EGG HARBOR, NJ is NOT ACCEPTABLE - USE EGG HARBOR CITY',
    zip: '08215',
  },
  MIDLAND_PARK_07432: { cityName: 'MIDLAND PARK', districtCode: '0235', countyName: 'BERGEN', zip: '07432' },
  MONTVALE_07645: { cityName: 'MONTVALE', districtCode: '0236', countyName: 'BERGEN', zip: '07645' },
  MOONACHIE_07074: { cityName: 'MOONACHIE', districtCode: '0237', countyName: 'BERGEN', zip: '07074' },
  ALPINE_07620: { cityName: 'ALPINE', districtCode: '0202', countyName: 'BERGEN', recordType: 'PO BOX', zip: '07620' },
  BOGOTA_07603: { cityName: 'BOGOTA', districtCode: '0204', countyName: 'BERGEN', zip: '07603' },
  UPPER_SADDLE_RIV_07458: { cityName: 'UPPER SADDLE RIV', districtCode: '0263', countyName: 'BERGEN', zip: '07458' },
  LITTLE_FERRY_07643: { cityName: 'LITTLE FERRY', districtCode: '0230', countyName: 'BERGEN', zip: '07643' },
  LODI_07644: { cityName: 'LODI', districtCode: '0231', countyName: 'BERGEN', zip: '07644' },
  TETERBORO_07699: {
    cityName: 'TETERBORO',
    districtCode: '0262',
    countyName: 'BERGEN',
    recordType: 'UNIQUE',
    zip: '07699',
  },
  TETERBORO_07608: { cityName: 'TETERBORO', districtCode: '0262', countyName: 'BERGEN', zip: '07608' },
  TENAFLY_07670: { cityName: 'TENAFLY', districtCode: '0261', countyName: 'BERGEN', zip: '07670' },
  TEANECK_07666: { cityName: 'TEANECK', districtCode: '0260', countyName: 'BERGEN', zip: '07666' },
  LEONIA_07605: { cityName: 'LEONIA', districtCode: '0229', countyName: 'BERGEN', zip: '07605' },
  LYNDHURST_07071: { cityName: 'LYNDHURST', districtCode: '0232', countyName: 'BERGEN', zip: '07071' },
  WYCKOFF_07481: { cityName: 'WYCKOFF', districtCode: '0270', countyName: 'BERGEN', zip: '07481' },
  MAHWAH_07430: { cityName: 'MAHWAH', districtCode: '0233', countyName: 'BERGEN', zip: '07430' },
  MAHWAH_07495: { cityName: 'MAHWAH', districtCode: '0233', countyName: 'BERGEN', zip: '07495' },
  HOHOKUS_07423: { cityName: 'HOHOKUS', districtCode: '0228', countyName: 'BERGEN', zip: '07423' },
  ALLENDALE_07401: { cityName: 'ALLENDALE', districtCode: '0201', countyName: 'BERGEN', zip: '07401' },
  HILLSDALE_07642: { cityName: 'HILLSDALE', districtCode: '0227', countyName: 'BERGEN', zip: '07642' },
  HACKENSACK_07602: {
    cityName: 'HACKENSACK',
    districtCode: '0223',
    countyName: 'BERGEN',
    recordType: 'PO BOX',
    zip: '07602',
  },
  HACKENSACK_07601: { cityName: 'HACKENSACK', districtCode: '0223', countyName: 'BERGEN', zip: '07601' },
  WOOD_RIDGE_07075: { cityName: 'WOOD RIDGE', districtCode: '0269', countyName: 'BERGEN', zip: '07075' },
  GARFIELD_07026: { cityName: 'GARFIELD', districtCode: '0221', countyName: 'BERGEN', zip: '07026' },
  BERGENFIELD_07621: { cityName: 'BERGENFIELD', districtCode: '0203', countyName: 'BERGEN', zip: '07621' },
  WOODCLIFF_LAKE_07677: { cityName: 'WOODCLIFF LAKE', districtCode: '0268', countyName: 'BERGEN', zip: '07677' },
  CARLSTADT_07072: { cityName: 'CARLSTADT', districtCode: '0205', countyName: 'BERGEN', zip: '07072' },
  FRANKLIN_LAKES_07417: { cityName: 'FRANKLIN LAKES', districtCode: '0220', countyName: 'BERGEN', zip: '07417' },
  NEW_MILFORD_07646: { cityName: 'NEW MILFORD', districtCode: '0238', countyName: 'BERGEN', zip: '07646' },
  SOUTH_HACKENSACK_07606: { cityName: 'SOUTH HACKENSACK', districtCode: '0259', countyName: 'BERGEN', zip: '07606' },
  HAWORTH_07641: { cityName: 'HAWORTH', districtCode: '0226', countyName: 'BERGEN', zip: '07641' },
  NORTHVALE_07647: { cityName: 'NORTHVALE', districtCode: '0240', countyName: 'BERGEN', zip: '07647' },
  NORTH_ARLINGTON_07031: { cityName: 'NORTH ARLINGTON', districtCode: '0239', countyName: 'BERGEN', zip: '07031' },
  NORWOOD_07648: { cityName: 'NORWOOD', districtCode: '0241', countyName: 'BERGEN', zip: '07648' },
  WESTWOOD_07677: { cityName: 'WESTWOOD', districtCode: '0267', countyName: 'BERGEN', zip: '07677' },
  OAKLAND_07436: { cityName: 'OAKLAND', districtCode: '0242', countyName: 'BERGEN', zip: '07436' },
  CLIFFSIDE_PARK_07010: { cityName: 'CLIFFSIDE PARK', districtCode: '0206', countyName: 'BERGEN', zip: '07010' },
  FORT_LEE_07024: { cityName: 'FORT LEE', districtCode: '0219', countyName: 'BERGEN', zip: '07024' },
  HASBROUCK_HGHTS_07604: { cityName: 'HASBROUCK HGHTS', districtCode: '0225', countyName: 'BERGEN', zip: '07604' },
  SADDLE_RIVER_07458: { cityName: 'SADDLE RIVER', districtCode: '0258', countyName: 'BERGEN', zip: '07458' },
  SADDLE_BROOK_07663: { cityName: 'SADDLE BROOK', districtCode: '0257', countyName: 'BERGEN', zip: '07663' },
  RUTHERFORD_07070: { cityName: 'RUTHERFORD', districtCode: '0256', countyName: 'BERGEN', zip: '07070' },
  CLOSTER_07624: { cityName: 'CLOSTER', districtCode: '0207', countyName: 'BERGEN', zip: '07624' },
  OLD_TAPPAN_07675: { cityName: 'OLD TAPPAN', districtCode: '0243', countyName: 'BERGEN', zip: '07675' },
  ORADELL_07649: { cityName: 'ORADELL', districtCode: '0244', countyName: 'BERGEN', zip: '07649' },
  FAIRVIEW_07022: { cityName: 'FAIRVIEW', districtCode: '0218', countyName: 'BERGEN', zip: '07022' },
  FAIRLAWN_07410: {
    cityName: 'FAIRLAWN',
    districtCode: '0217',
    countyName: 'BERGEN',
    recordType: 'FAIRLAWN, NJ is NOT ACCEPTABLE - USE FAIR LAWN',
    zip: '07410',
  },
  FAIR_LAWN_07410: {
    cityName: 'FAIR LAWN',
    districtCode: '0217',
    countyName: 'BERGEN',
    zip: '07410',
  },
  WESTWOOD_07675: { cityName: 'WESTWOOD', districtCode: '0267', countyName: 'BERGEN', zip: '07675' },
  E_RUTHERFORD_07073: { cityName: 'E RUTHERFORD', districtCode: '0212', countyName: 'BERGEN', zip: '07073' },
  ROCKLEIGH_07647: { cityName: 'ROCKLEIGH', districtCode: '0255', countyName: 'BERGEN', zip: '07647' },
  CRESSKILL_07626: { cityName: 'CRESSKILL', districtCode: '0208', countyName: 'BERGEN', zip: '07626' },
  ROCHELLE_PARK_07662: { cityName: 'ROCHELLE PARK', districtCode: '0254', countyName: 'BERGEN', zip: '07662' },
  PALISADES_PARK_07650: { cityName: 'PALISADES PARK', districtCode: '0245', countyName: 'BERGEN', zip: '07650' },
  RIVERVALE_07675: { cityName: 'RIVERVALE', districtCode: '0253', countyName: 'BERGEN', zip: '07675' },
  DEMAREST_07627: { cityName: 'DEMAREST', districtCode: '0209', countyName: 'BERGEN', zip: '07627' },
  PARAMUS_07652: { cityName: 'PARAMUS', districtCode: '0246', countyName: 'BERGEN', zip: '07652' },
  PARAMUS_07653: {
    cityName: 'PARAMUS',
    districtCode: '0246',
    countyName: 'BERGEN',
    recordType: 'PO BOX',
    zip: '07653',
  },
  HARRINGTON_PARK_07640: { cityName: 'HARRINGTON PARK', districtCode: '0224', countyName: 'BERGEN', zip: '07640' },
  PARK_RIDGE_07656: { cityName: 'PARK RIDGE', districtCode: '0247', countyName: 'BERGEN', zip: '07656' },
  RIVEREDGE_07661: { cityName: 'RIVEREDGE', districtCode: '0252', countyName: 'BERGEN', zip: '07661' },
  RIDGEWOOD_07451: {
    cityName: 'RIDGEWOOD',
    districtCode: '0251',
    countyName: 'BERGEN',
    recordType: 'PO BOX',
    zip: '07451',
  },
  RIDGEWOOD_07450: { cityName: 'RIDGEWOOD', districtCode: '0251', countyName: 'BERGEN', zip: '07450' },
  RIDGEFIELD_PARK_07660: { cityName: 'RIDGEFIELD PARK', districtCode: '0250', countyName: 'BERGEN', zip: '07660' },
  ENGLEWOOD_CLIFFS_07632: { cityName: 'ENGLEWOOD CLIFFS', districtCode: '0216', countyName: 'BERGEN', zip: '07632' },
  ENGLEWOOD_07631: { cityName: 'ENGLEWOOD', districtCode: '0215', countyName: 'BERGEN', zip: '07631' },
  ENGLEWOOD_CITY_07632: { cityName: 'ENGLEWOOD CITY', districtCode: '0215', countyName: 'BERGEN', zip: '07632' },
  EMERSON_07630: { cityName: 'EMERSON', districtCode: '0214', countyName: 'BERGEN', zip: '07630' },
  ELMWOOD_PARK_07407: { cityName: 'ELMWOOD PARK', districtCode: '0211', countyName: 'BERGEN', zip: '07407' },
  DUMONT_07628: { cityName: 'DUMONT', districtCode: '0210', countyName: 'BERGEN', zip: '07628' },
  RIDGEFIELD_07657: { cityName: 'RIDGEFIELD', districtCode: '0249', countyName: 'BERGEN', zip: '07657' },
  RAMSEY_07446: { cityName: 'RAMSEY', districtCode: '0248', countyName: 'BERGEN', zip: '07446' },
  WASHINGTON_07676: { cityName: 'WASHINGTON', districtCode: '0266', countyName: 'BERGEN', zip: '07676' },
  WALLINGTON_07057: { cityName: 'WALLINGTON', districtCode: '0265', countyName: 'BERGEN', zip: '07057' },
  WALDWICK_07463: { cityName: 'WALDWICK', districtCode: '0264', countyName: 'BERGEN', zip: '07463' },
  MAYWOOD_07607: { cityName: 'MAYWOOD', districtCode: '0234', countyName: 'BERGEN', zip: '07607' },
  EDGEWATER_07020: { cityName: 'EDGEWATER', districtCode: '0213', countyName: 'BERGEN', zip: '07020' },
  GLEN_ROCK_07452: { cityName: 'GLEN ROCK', districtCode: '0222', countyName: 'BERGEN', zip: '07452' },
  CINNAMINSON_08077: { cityName: 'CINNAMINSON', districtCode: '0308', countyName: 'BURLINGTON', zip: '08077' },
  NO_HANOVER_08562: {
    cityName: 'NO HANOVER',
    districtCode: '0326',
    countyName: 'BURLINGTON',
    recordType: 'NORTH HANOVER, NJ is NOT ACCEPTABLE - USE WRIGHTSTOWN',
    zip: '08562',
  },
  MT_HOLLY_08060: { cityName: 'MT HOLLY', districtCode: '0323', countyName: 'BURLINGTON', zip: '08060' },
  MT_LAUREL_08054: { cityName: 'MT LAUREL', districtCode: '0324', countyName: 'BURLINGTON', zip: '08054' },
  WILLINGBORO_08046: { cityName: 'WILLINGBORO', districtCode: '0338', countyName: 'BURLINGTON', zip: '08046' },
  SHAMONG_08088: { cityName: 'SHAMONG', districtCode: '0332', countyName: 'BURLINGTON', zip: '08088' },
  WOODLAND_08019: { cityName: 'WOODLAND', districtCode: '0339', countyName: 'BURLINGTON', zip: '08019' },
  WRIGHTSTOWN_08562: { cityName: 'WRIGHTSTOWN', districtCode: '0340', countyName: 'BURLINGTON', zip: '08562' },
  WESTAMPTON_08060: { cityName: 'WESTAMPTON', districtCode: '0337', countyName: 'BURLINGTON', zip: '08060' },
  BASS_RIVER_08019: { cityName: 'BASS RIVER', districtCode: '0301', countyName: 'BURLINGTON', zip: '08019' },
  WASHINGTON_08224: { cityName: 'WASHINGTON', districtCode: '0336', countyName: 'BURLINGTON', zip: '08224' },
  FLORENCE_08518: { cityName: 'FLORENCE', districtCode: '0315', countyName: 'BURLINGTON', zip: '08518' },
  FIELDSBORO_08505: { cityName: 'FIELDSBORO', districtCode: '0314', countyName: 'BURLINGTON', zip: '08505' },
  WASHINGTON_08215: { cityName: 'WASHINGTON', districtCode: '0336', countyName: 'BURLINGTON', zip: '08215' },
  TABERNACLE_08088: { cityName: 'TABERNACLE', districtCode: '0335', countyName: 'BURLINGTON', zip: '08088' },
  WASHINGTON_08019: { cityName: 'WASHINGTON', districtCode: '0336', countyName: 'BURLINGTON', zip: '08019' },
  BASS_RIVER_08087: { cityName: 'BASS RIVER', districtCode: '0301', countyName: 'BURLINGTON', zip: '08087' },
  BASS_RIVER_08215: { cityName: 'BASS RIVER', districtCode: '0301', countyName: 'BURLINGTON', zip: '08215' },
  BASS_RIVER_08224: { cityName: 'BASS RIVER', districtCode: '0301', countyName: 'BURLINGTON', zip: '08224' },
  WOODLAND_08015: { cityName: 'WOODLAND', districtCode: '0339', countyName: 'BURLINGTON', zip: '08015' },
  BURLINGTON_08016: {
    cityName: 'BURLINGTON',
    districtCode: '0300',
    countyName: 'BURLINGTON',
    zip: '08016',
    note: 'inclusive search',
  },
  MEDFORD_08055: { cityName: 'MEDFORD', districtCode: '0320', countyName: 'BURLINGTON', zip: '08055' },
  DELANCO_08075: { cityName: 'DELANCO', districtCode: '0309', countyName: 'BURLINGTON', zip: '08075' },
  HAINESPORT_08036: { cityName: 'HAINESPORT', districtCode: '0316', countyName: 'BURLINGTON', zip: '08036' },
  DELRAN_08075: { cityName: 'DELRAN', districtCode: '0310', countyName: 'BURLINGTON', zip: '08075' },
  MEDFORD_LAKES_08055: { cityName: 'MEDFORD LAKES', districtCode: '0321', countyName: 'BURLINGTON', zip: '08055' },
  PALMYRA_08065: { cityName: 'PALMYRA', districtCode: '0327', countyName: 'BURLINGTON', zip: '08065' },
  BEVERLY_08010: { cityName: 'BEVERLY', districtCode: '0302', countyName: 'BURLINGTON', zip: '08010' },
  MANSFIELD_08022: {
    cityName: 'MANSFIELD',
    districtCode: '0318',
    countyName: 'BURLINGTON',
    recordType: 'MANSFIELD, NJ is NOT ACCEPTABLE - USE COLUMBUS',
    zip: '08022',
  },
  COLUMBUS_08022: {
    cityName: 'COLUMBUS',
    districtCode: '0318',
    countyName: 'BURLINGTON',
    zip: '08022',
  },
  EVESHAM_08053: { cityName: 'EVESHAM', districtCode: '0313', countyName: 'BURLINGTON', zip: '08053' },
  JOBSTOWN_08041: { cityName: 'JOBSTOWN', districtCode: '0334', countyName: 'BURLINGTON', zip: '08041' },
  JULIUSTOWN_08042: { cityName: 'JULIUSTOWN', districtCode: '0334', countyName: 'BURLINGTON', zip: '08042' },
  RIVERTON_08077: { cityName: 'RIVERTON', districtCode: '0331', countyName: 'BURLINGTON', zip: '08077' },
  RIVERTON_08076: {
    cityName: 'RIVERTON',
    districtCode: '0331',
    countyName: 'BURLINGTON',
    recordType: 'PO BOX',
    zip: '08076',
  },
  RIVERSIDE_08075: { cityName: 'RIVERSIDE', districtCode: '0330', countyName: 'BURLINGTON', zip: '08075' },
  MAPLE_SHADE_08052: { cityName: 'MAPLE SHADE', districtCode: '0319', countyName: 'BURLINGTON', zip: '08052' },
  MAPLE_SHADE_TOWNSHIP_08052: {
    cityName: 'MAPLE SHADE TOWNSHIP',
    districtCode: '0319',
    countyName: 'BURLINGTON',
    zip: '08052',
  },
  NEW_HANOVER_08511: { cityName: 'NEW HANOVER', districtCode: '0325', countyName: 'BURLINGTON', zip: '08511' },
  NEW_HANOVER_08533: { cityName: 'NEW HANOVER', districtCode: '0325', countyName: 'BURLINGTON', zip: '08533' },
  NEW_HANOVER_08562: { cityName: 'NEW HANOVER', districtCode: '0325', countyName: 'BURLINGTON', zip: '08562' },
  NEW_HANOVER_08640: { cityName: 'NEW HANOVER', districtCode: '0325', countyName: 'BURLINGTON', zip: '08640' },
  WOODLAND_08759: { cityName: 'WOODLAND', districtCode: '0339', countyName: 'BURLINGTON', zip: '08759' },
  LUMBERTON_08048: { cityName: 'LUMBERTON', districtCode: '0317', countyName: 'BURLINGTON', zip: '08048' },
  PEMBERTON_08011: { cityName: 'PEMBERTON', districtCode: '0329', countyName: 'BURLINGTON', zip: '08011' },
  NEW_HANOVER_08641: { cityName: 'NEW HANOVER', districtCode: '0325', countyName: 'BURLINGTON', zip: '08641' },
  MOORESTOWN_08057: { cityName: 'MOORESTOWN', districtCode: '0322', countyName: 'BURLINGTON', zip: '08057' },
  BORDENTOWN_08505: { cityName: 'BORDENTOWN', districtCode: '0304', countyName: 'BURLINGTON', zip: '08505' },
  BORDENTOWN_CITY_08505: { cityName: 'BORDENTOWN CITY', districtCode: '0303', countyName: 'BURLINGTON', zip: '08505' },
  EASTAMPTON_08060: { cityName: 'EASTAMPTON', districtCode: '0311', countyName: 'BURLINGTON', zip: '08060' },
  BORDENTOWN_CITY_08610: { cityName: 'BORDENTOWN CITY', districtCode: '0303', countyName: 'BURLINGTON', zip: '08610' },
  BORDENTOWN_CITY_08611: { cityName: 'BORDENTOWN CITY', districtCode: '0303', countyName: 'BURLINGTON', zip: '08611' },
  PEMBERTON_08015: { cityName: 'PEMBERTON', districtCode: '0329', countyName: 'BURLINGTON', zip: '08015' },
  PEMBERTON_08022: { cityName: 'PEMBERTON', districtCode: '0329', countyName: 'BURLINGTON', zip: '08022' },
  BORDENTOWN_CITY_08620: { cityName: 'BORDENTOWN CITY', districtCode: '0303', countyName: 'BURLINGTON', zip: '08620' },
  CHESTERFIELD_08515: { cityName: 'CHESTERFIELD', districtCode: '0307', countyName: 'BURLINGTON', zip: '08515' },
  PEMBERTON_08042: { cityName: 'PEMBERTON', districtCode: '0329', countyName: 'BURLINGTON', zip: '08042' },
  PEMBERTON_08060: { cityName: 'PEMBERTON', districtCode: '0329', countyName: 'BURLINGTON', zip: '08060' },
  PEMBERTON_08064: { cityName: 'PEMBERTON', districtCode: '0329', countyName: 'BURLINGTON', zip: '08064' },
  PEMBERTON_BOROUGH_08068: {
    cityName: 'PEMBERTON BOROUGH',
    districtCode: '0328',
    countyName: 'BURLINGTON',
    zip: '08068',
  },
  SOUTHAMPTON_08088: { cityName: 'SOUTHAMPTON', districtCode: '0333', countyName: 'BURLINGTON', zip: '08088' },
  EDGEWATER_PARK_08010: { cityName: 'EDGEWATER PARK', districtCode: '0312', countyName: 'BURLINGTON', zip: '08010' },
  PEMBERTON_08640: { cityName: 'PEMBERTON', districtCode: '0329', countyName: 'BURLINGTON', zip: '08640' },
  WOODLAND_08088: { cityName: 'WOODLAND', districtCode: '0339', countyName: 'BURLINGTON', zip: '08088' },
  PEMBERTON_08068: { cityName: 'PEMBERTON', districtCode: '0329', countyName: 'BURLINGTON', zip: '08068' },
  PEMBERTON_08088: { cityName: 'PEMBERTON', districtCode: '0329', countyName: 'BURLINGTON', zip: '08088' },
  PENNSAUKEN_08109: { cityName: 'PENNSAUKEN', districtCode: '0427', countyName: 'CAMDEN', zip: '08109' },
  PINE_HILL_08021: { cityName: 'PINE HILL', districtCode: '0428', countyName: 'CAMDEN', zip: '08021' },
  WOODLYNNE_08107: { cityName: 'WOODLYNNE', districtCode: '0437', countyName: 'CAMDEN', zip: '08107' },
  LAUREL_SPRINGS_08021: { cityName: 'LAUREL SPRINGS', districtCode: '0420', countyName: 'CAMDEN', zip: '08021' },
  LAWNSIDE_08045: { cityName: 'LAWNSIDE', districtCode: '0421', countyName: 'CAMDEN', zip: '08045' },
  PINE_VALLEY_08021: { cityName: 'PINE VALLEY', districtCode: '0429', countyName: 'CAMDEN', zip: '08021' },
  LINDENWOLD_08021: { cityName: 'LINDENWOLD', districtCode: '0422', countyName: 'CAMDEN', zip: '08021' },
  WINSLOW_08095: {
    cityName: 'WINSLOW',
    districtCode: '0436',
    countyName: 'CAMDEN',
    recordType: 'PO BOX',
    zip: '08095',
  },
  AUDUBON_08106: { cityName: 'AUDUBON', districtCode: '0401', countyName: 'CAMDEN', zip: '08106' },
  AUDUBON_PARK_08106: {
    cityName: 'AUDUBON PARK',
    districtCode: '0402',
    countyName: 'CAMDEN',
    recordType: 'AUDUBON PARK, NJ is NOT ACCEPTABLE - USE AUDUBON',
    zip: '08106',
  },
  MAGNOLIA_08049: { cityName: 'MAGNOLIA', districtCode: '0423', countyName: 'CAMDEN', zip: '08049' },
  BARRINGTON_08007: { cityName: 'BARRINGTON', districtCode: '0403', countyName: 'CAMDEN', zip: '08007' },
  HI_NELLA_08083: { cityName: 'HI NELLA', districtCode: '0419', countyName: 'CAMDEN', zip: '08083' },
  BELLMAWR_08031: { cityName: 'BELLMAWR', districtCode: '0404', countyName: 'CAMDEN', zip: '08031' },
  BELLMAWR_08099: {
    cityName: 'BELLMAWR',
    districtCode: '0404',
    countyName: 'CAMDEN',
    recordType: 'PO BOX',
    zip: '08099',
  },
  BERLIN_08009: { cityName: 'BERLIN', districtCode: '0405', countyName: 'CAMDEN', zip: '08009' },
  WATERFORD_08089: {
    cityName: 'WATERFORD',
    districtCode: '0435',
    countyName: 'CAMDEN',
    recordType: 'WATERFORD, NJ is NOT ACCEPTABLE - USE WATERFORD WORKS',
    zip: '08089',
  },
  WATERFORD_WORKS_08089: {
    cityName: 'WATERFORD WORKS',
    districtCode: '0435',
    countyName: 'CAMDEN',
    zip: '08089',
  },
  VOORHEES_08043: { cityName: 'VOORHEES', districtCode: '0434', countyName: 'CAMDEN', zip: '08043' },
  MERCHANTVILLE_08109: { cityName: 'MERCHANTVILLE', districtCode: '0424', countyName: 'CAMDEN', zip: '08109' },
  MOUNT_EPHRAIM_08059: { cityName: 'MOUNT EPHRAIM', districtCode: '0425', countyName: 'CAMDEN', zip: '08059' },
  TAVISTOCK_08033: {
    cityName: 'TAVISTOCK',
    districtCode: '0433',
    countyName: 'CAMDEN',
    recordType: 'TAVISTOCK, NJ is NOT ACCEPTABLE - USE HADDONFIELD',
    zip: '08033',
  },
  HADDONFIELD_08033: {
    cityName: 'TAVISTOCK',
    districtCode: '0433',
    countyName: 'CAMDEN',
    zip: '08033',
  },
  BROOKLAWN_08030: { cityName: 'BROOKLAWN', districtCode: '0407', countyName: 'CAMDEN', zip: '08030' },
  STRATFORD_08084: { cityName: 'STRATFORD', districtCode: '0432', countyName: 'CAMDEN', zip: '08084' },
  HADDON_TOWNSHIP_08108: { cityName: 'HADDON TOWNSHIP', districtCode: '0416', countyName: 'CAMDEN', zip: '08108' },
  HADDON_TOWNSHIP_08107: { cityName: 'HADDON TOWNSHIP', districtCode: '0416', countyName: 'CAMDEN', zip: '08107' },
  HADDON_TOWNSHIP_08104: { cityName: 'HADDON TOWNSHIP', districtCode: '0416', countyName: 'CAMDEN', zip: '08104' },
  HADDON_HEIGHTS_08035: { cityName: 'HADDON HEIGHTS', districtCode: '0418', countyName: 'CAMDEN', zip: '08035' },
  PENNSAUKEN_08110: { cityName: 'PENNSAUKEN', districtCode: '0427', countyName: 'CAMDEN', zip: '08110' },
  GLOUCESTER_CITY_08030: { cityName: 'GLOUCESTER CITY', districtCode: '0414', countyName: 'CAMDEN', zip: '08030' },
  GLOUCESTER_08031: {
    cityName: 'GLOUCESTER',
    districtCode: '0415',
    countyName: 'CAMDEN',
    recordType: 'GLOUCESTER, NJ is NOT ACCEPTABLE - USE BELLMAWR',
    zip: '08031',
  },
  GLOUCESTER_TOWNSHIP_08012: {
    cityName: 'GLOUCESTER TOWNSHIP',
    districtCode: '0415',
    countyName: 'CAMDEN',
    zip: '08012',
  },
  RUNNEMEDE_08078: { cityName: 'RUNNEMEDE', districtCode: '0430', countyName: 'CAMDEN', zip: '08078' },
  COLLINGSWOOD_08108: { cityName: 'COLLINGSWOOD', districtCode: '0412', countyName: 'CAMDEN', zip: '08108' },
  GLOUCESTER_08030: {
    cityName: 'GLOUCESTER',
    districtCode: '0415',
    countyName: 'CAMDEN',
    recordType: 'GLOUCESTER, NJ is NOT ACCEPTABLE - USE GLOUCESTER CITY',
    zip: '08030',
  },
  COLLINGSWOOD_08107: { cityName: 'COLLINGSWOOD', districtCode: '0412', countyName: 'CAMDEN', zip: '08107' },
  OAKLYN_08107: { cityName: 'OAKLYN', districtCode: '0426', countyName: 'CAMDEN', zip: '08107' },
  GIBBSBORO_08026: { cityName: 'GIBBSBORO', districtCode: '0413', countyName: 'CAMDEN', zip: '08026' },
  CAMDEN_08101: { cityName: 'CAMDEN', districtCode: '0408', countyName: 'CAMDEN', recordType: 'PO BOX', zip: '08101' },
  CLEMENTON_08021: { cityName: 'CLEMENTON', districtCode: '0411', countyName: 'CAMDEN', zip: '08021' },
  CAMDEN_08102: { cityName: 'CAMDEN', districtCode: '0408', countyName: 'CAMDEN', zip: '08102' },
  SOMERDALE_08083: { cityName: 'SOMERDALE', districtCode: '0431', countyName: 'CAMDEN', zip: '08083' },
  CHESILHURST_08089: { cityName: 'CHESILHURST', districtCode: '0410', countyName: 'CAMDEN', zip: '08089' },
  CAMDEN_08103: { cityName: 'CAMDEN', districtCode: '0408', countyName: 'CAMDEN', zip: '08103' },
  CAMDEN_08104: { cityName: 'CAMDEN', districtCode: '0408', countyName: 'CAMDEN', zip: '08104' },
  CHERRY_HILL_08034: {
    cityName: 'CHERRY HILL',
    districtCode: '0409',
    countyName: 'CAMDEN',
    zip: '08034',
  },
  CHERRY_HILL_08003: {
    cityName: 'CHERRY HILL',
    districtCode: '0409',
    countyName: 'CAMDEN',
    zip: '08003',
  },
  CHERRY_HILL_08002: {
    cityName: 'CHERRY HILL',
    districtCode: '0409',
    countyName: 'CAMDEN',
    zip: '08002',
  },
  CAMDEN_08105: { cityName: 'CAMDEN', districtCode: '0408', countyName: 'CAMDEN', zip: '08105' },
  WOODBINE_08270: { cityName: 'WOODBINE', districtCode: '0516', countyName: 'CAPE MAY', zip: '08270' },
  NORTH_WILDWOOD_08260: { cityName: 'NORTH WILDWOOD', districtCode: '0507', countyName: 'CAPE MAY', zip: '08260' },
  SEA_ISLE_CITY_08243: { cityName: 'SEA ISLE CITY', districtCode: '0509', countyName: 'CAPE MAY', zip: '08243' },
  OCEAN_CITY_08226: { cityName: 'OCEAN CITY', districtCode: '0508', countyName: 'CAPE MAY', zip: '08226' },
  UPPER_08223: { cityName: 'UPPER', districtCode: '0511', countyName: 'CAPE MAY', zip: '08223' },
  UPPER_08230: { cityName: 'UPPER', districtCode: '0511', countyName: 'CAPE MAY', zip: '08230' },
  UPPER_08248: { cityName: 'UPPER', districtCode: '0511', countyName: 'CAPE MAY', zip: '08248' },
  UPPER_08270: { cityName: 'UPPER', districtCode: '0511', countyName: 'CAPE MAY', zip: '08270' },
  STONE_HARBOR_08247: { cityName: 'STONE HARBOR', districtCode: '0510', countyName: 'CAPE MAY', zip: '08247' },
  MIDDLE_TOWNSHIP_08270: { cityName: 'MIDDLE TOWNSHIP', districtCode: '0506', countyName: 'CAPE MAY', zip: '08270' },
  MIDDLE_TOWNSHIP_08260: { cityName: 'MIDDLE TOWNSHIP', districtCode: '0506', countyName: 'CAPE MAY', zip: '08260' },
  MIDDLE_TOWNSHIP_08251: { cityName: 'MIDDLE TOWNSHIP', districtCode: '0506', countyName: 'CAPE MAY', zip: '08251' },
  MIDDLE_TOWNSHIP_08247: { cityName: 'MIDDLE TOWNSHIP', districtCode: '0506', countyName: 'CAPE MAY', zip: '08247' },
  MIDDLE_TOWNSHIP_08242: { cityName: 'MIDDLE TOWNSHIP', districtCode: '0506', countyName: 'CAPE MAY', zip: '08242' },
  MIDDLE_TOWNSHIP_08210: { cityName: 'MIDDLE TOWNSHIP', districtCode: '0506', countyName: 'CAPE MAY', zip: '08210' },
  MIDDLE_TOWNSHIP_08204: { cityName: 'MIDDLE TOWNSHIP', districtCode: '0506', countyName: 'CAPE MAY', zip: '08204' },
  MIDDLE_TOWNSHIP_08202: { cityName: 'MIDDLE TOWNSHIP', districtCode: '0506', countyName: 'CAPE MAY', zip: '08202' },
  CAPE_MAY_CITY_08212: {
    cityName: 'CAPE MAY CITY',
    districtCode: '0502',
    countyName: 'CAPE MAY',
    recordType: 'PO BOX',
    zip: '08212',
  },
  DENNIS_TOWNSHIP_08202: { cityName: 'DENNIS TOWNSHIP', districtCode: '0504', countyName: 'CAPE MAY', zip: '08202' },
  DENNIS_TOWNSHIP_08210: { cityName: 'DENNIS TOWNSHIP', districtCode: '0504', countyName: 'CAPE MAY', zip: '08210' },
  DENNIS_TOWNSHIP_08230: { cityName: 'DENNIS TOWNSHIP', districtCode: '0504', countyName: 'CAPE MAY', zip: '08230' },
  WEST_CAPE_MAY_08204: { cityName: 'WEST CAPE MAY', districtCode: '0512', countyName: 'CAPE MAY', zip: '08204' },
  DENNIS_TOWNSHIP_08243: { cityName: 'DENNIS TOWNSHIP', districtCode: '0504', countyName: 'CAPE MAY', zip: '08243' },
  DENNIS_TOWNSHIP_08246: { cityName: 'DENNIS TOWNSHIP', districtCode: '0504', countyName: 'CAPE MAY', zip: '08246' },
  DENNIS_TOWNSHIP_08270: { cityName: 'DENNIS TOWNSHIP', districtCode: '0504', countyName: 'CAPE MAY', zip: '08270' },
  WEST_WILDWOOD_08260: { cityName: 'WEST WILDWOOD', districtCode: '0513', countyName: 'CAPE MAY', zip: '08260' },
  WILDWOOD_CITY_08260: {
    cityName: 'WILDWOOD CITY',
    districtCode: '0514',
    countyName: 'CAPE MAY',
    recordType: 'WILDWOOD CITY, NJ is NOT ACCEPTABLE - USE WILDWOOD',
    zip: '08260',
  },
  WILDWOOD_08260: {
    cityName: 'WILDWOOD',
    districtCode: '0514',
    countyName: 'CAPE MAY',
    zip: '08260',
  },
  AVALON_08202: { cityName: 'AVALON', districtCode: '0501', countyName: 'CAPE MAY', zip: '08202' },
  LOWER_08260: { cityName: 'LOWER', districtCode: '0505', countyName: 'CAPE MAY', zip: '08260' },
  LOWER_08251: { cityName: 'LOWER', districtCode: '0505', countyName: 'CAPE MAY', zip: '08251' },
  LOWER_08242: { cityName: 'LOWER', districtCode: '0505', countyName: 'CAPE MAY', zip: '08242' },
  LOWER_08212: { cityName: 'LOWER', districtCode: '0505', countyName: 'CAPE MAY', zip: '08212' },
  LOWER_08204: { cityName: 'LOWER', districtCode: '0505', countyName: 'CAPE MAY', zip: '08204' },
  WILDWOOD_CREST_08260: { cityName: 'WILDWOOD CREST', districtCode: '0515', countyName: 'CAPE MAY', zip: '08260' },
  CAPE_MAY_POINT_08212: {
    cityName: 'CAPE MAY POINT',
    districtCode: '0503',
    countyName: 'CAPE MAY',
    recordType: 'PO BOX',
    zip: '08212',
  },
  FAIRFIELD_08302: { cityName: 'FAIRFIELD', districtCode: '0605', countyName: 'CUMBERLAND', zip: '08302' },
  VINELAND_08361: { cityName: 'VINELAND', districtCode: '0614', countyName: 'CUMBERLAND', zip: '08361' },
  VINELAND_08362: {
    cityName: 'VINELAND',
    districtCode: '0614',
    countyName: 'CUMBERLAND',
    recordType: 'PO BOX',
    zip: '08362',
  },
  BRIDGETON_08302: { cityName: 'BRIDGETON', districtCode: '0601', countyName: 'CUMBERLAND', zip: '08302' },
  LAWRENCE_08648: { cityName: 'LAWRENCE', districtCode: '0608', countyName: 'CUMBERLAND', zip: '08648' },
  LAWRENCE_TOWNSHIP_08311: {
    cityName: 'LAWRENCE TOWNSHIP',
    districtCode: '0608',
    countyName: 'CUMBERLAND',
    zip: '08311',
  },
  MAURICE_RIVER_08348: { cityName: 'MAURICE RIVER', districtCode: '0609', countyName: 'CUMBERLAND', zip: '08348' },
  MAURICE_RIVER_08340: { cityName: 'MAURICE RIVER', districtCode: '0609', countyName: 'CUMBERLAND', zip: '08340' },
  STOW_CREEK_08079: { cityName: 'STOW CREEK', districtCode: '0612', countyName: 'CUMBERLAND', zip: '08079' },
  MAURICE_RIVER_08332: { cityName: 'MAURICE RIVER', districtCode: '0609', countyName: 'CUMBERLAND', zip: '08332' },
  MAURICE_RIVER_08327: { cityName: 'MAURICE RIVER', districtCode: '0609', countyName: 'CUMBERLAND', zip: '08327' },
  MAURICE_RIVER_08324: { cityName: 'MAURICE RIVER', districtCode: '0609', countyName: 'CUMBERLAND', zip: '08324' },
  MAURICE_RIVER_08316: { cityName: 'MAURICE RIVER', districtCode: '0609', countyName: 'CUMBERLAND', zip: '08316' },
  MAURICE_RIVER_08314: { cityName: 'MAURICE RIVER', districtCode: '0609', countyName: 'CUMBERLAND', zip: '08314' },
  MAURICE_RIVER_08270: { cityName: 'MAURICE RIVER', districtCode: '0609', countyName: 'CUMBERLAND', zip: '08270' },
  SHILOH_08353: {
    cityName: 'SHILOH',
    districtCode: '0611',
    countyName: 'CUMBERLAND',
    recordType: 'PO BOX',
    zip: '08353',
  },
  COMMERCIAL_08329: { cityName: 'COMMERCIAL', districtCode: '0602', countyName: 'CUMBERLAND', zip: '08329' },
  UPPER_DEERFIELD_08302: {
    cityName: 'UPPER DEERFIELD',
    districtCode: '0613',
    countyName: 'CUMBERLAND',
    recordType: 'UPPER DEERFIELD TWP, NJ is NOT ACCEPTABLE - USE BRIDGETON',
    zip: '08302',
  },
  GREENWICH_08302: { cityName: 'GREENWICH', districtCode: '0606', countyName: 'CUMBERLAND', zip: '08302' },
  MILLVILLE_08332: { cityName: 'MILLVILLE', districtCode: '0610', countyName: 'CUMBERLAND', zip: '08332' },
  DOWNE_08311: { cityName: 'DOWNE', districtCode: '0604', countyName: 'CUMBERLAND', zip: '08311' },
  VINELAND_08360: { cityName: 'VINELAND', districtCode: '0614', countyName: 'CUMBERLAND', zip: '08360' },
  GREENWICH_08323: { cityName: 'GREENWICH', districtCode: '0606', countyName: 'CUMBERLAND', zip: '08323' },
  HOPEWELL_08302: { cityName: 'HOPEWELL', districtCode: '0607', countyName: 'CUMBERLAND', zip: '08302' },
  HOPEWELL_08323: { cityName: 'HOPEWELL', districtCode: '0607', countyName: 'CUMBERLAND', zip: '08323' },
  COMMERCIAL_08332: { cityName: 'COMMERCIAL', districtCode: '0602', countyName: 'CUMBERLAND', zip: '08332' },
  COMMERCIAL_08349: { cityName: 'COMMERCIAL', districtCode: '0602', countyName: 'CUMBERLAND', zip: '08349' },
  FAIRFIELD_08332: { cityName: 'FAIRFIELD', districtCode: '0605', countyName: 'CUMBERLAND', zip: '08332' },
  FAIRFIELD_08320: { cityName: 'FAIRFIELD', districtCode: '0605', countyName: 'CUMBERLAND', zip: '08320' },
  STOW_CREEK_08302: { cityName: 'STOW CREEK', districtCode: '0612', countyName: 'CUMBERLAND', zip: '08302' },
  DOWNE_08321: { cityName: 'DOWNE', districtCode: '0604', countyName: 'CUMBERLAND', zip: '08321' },
  DOWNE_08332: { cityName: 'DOWNE', districtCode: '0604', countyName: 'CUMBERLAND', zip: '08332' },
  STOW_CREEK_08323: { cityName: 'STOW CREEK', districtCode: '0612', countyName: 'CUMBERLAND', zip: '08323' },
  DOWNE_08345: { cityName: 'DOWNE', districtCode: '0604', countyName: 'CUMBERLAND', zip: '08345' },
  DOWNE_08349: { cityName: 'DOWNE', districtCode: '0604', countyName: 'CUMBERLAND', zip: '08349' },
  FAIRFIELD_08311: { cityName: 'FAIRFIELD', districtCode: '0605', countyName: 'CUMBERLAND', zip: '08311' },
  DEERFIELD_08313: {
    cityName: 'DEERFIELD',
    districtCode: '0603',
    countyName: 'CUMBERLAND',
    recordType: 'DEERFIELD, NJ is NOT ACCEPTABLE - USE DEERFIELD STREET',
    zip: '08313',
  },
  NEWARK_07189: { cityName: 'NEWARK', districtCode: '0714', countyName: 'ESSEX', recordType: 'UNIQUE', zip: '07189' },
  NEWARK_07188: { cityName: 'NEWARK', districtCode: '0714', countyName: 'ESSEX', recordType: 'UNIQUE', zip: '07188' },
  SOUTH_ORANGE_07079: { cityName: 'SOUTH ORANGE', districtCode: '0719', countyName: 'ESSEX', zip: '07079' },
  NUTLEY_07110: { cityName: 'NUTLEY', districtCode: '0716', countyName: 'ESSEX', zip: '07110' },
  NEWARK_07104: { cityName: 'NEWARK', districtCode: '0714', countyName: 'ESSEX', zip: '07104' },
  NEWARK_07103: { cityName: 'NEWARK', districtCode: '0714', countyName: 'ESSEX', zip: '07103' },
  NEWARK_07102: { cityName: 'NEWARK', districtCode: '0714', countyName: 'ESSEX', zip: '07102' },
  WEST_CALDWELL_07006: { cityName: 'WEST CALDWELL', districtCode: '0721', countyName: 'ESSEX', zip: '07006' },
  CEDAR_GROVE_07009: { cityName: 'CEDAR GROVE', districtCode: '0704', countyName: 'ESSEX', zip: '07009' },
  NEWARK_07184: { cityName: 'NEWARK', districtCode: '0714', countyName: 'ESSEX', recordType: 'UNIQUE', zip: '07184' },
  NORTH_CALDWELL_07006: { cityName: 'NORTH CALDWELL', districtCode: '0715', countyName: 'ESSEX', zip: '07006' },
  NEWARK_07101: { cityName: 'NEWARK', districtCode: '0714', countyName: 'ESSEX', recordType: 'PO BOX', zip: '07101' },
  NEWARK_07175: { cityName: 'NEWARK', districtCode: '0714', countyName: 'ESSEX', recordType: 'UNIQUE', zip: '07175' },
  FAIRFIELD_07004: { cityName: 'FAIRFIELD', districtCode: '0707', countyName: 'ESSEX', zip: '07004' },
  WEST_CALDWELL_07007: {
    cityName: 'WEST CALDWELL',
    districtCode: '0721',
    countyName: 'ESSEX',
    recordType: 'PO BOX',
    zip: '07007',
  },
  GLEN_RIDGE_07028: { cityName: 'GLEN RIDGE', districtCode: '0708', countyName: 'ESSEX', zip: '07028' },
  MAPLEWOOD_07040: { cityName: 'MAPLEWOOD', districtCode: '0711', countyName: 'ESSEX', zip: '07040' },
  NEWARK_07199: { cityName: 'NEWARK', districtCode: '0714', countyName: 'ESSEX', recordType: 'UNIQUE', zip: '07199' },
  NEWARK_07198: { cityName: 'NEWARK', districtCode: '0714', countyName: 'ESSEX', recordType: 'UNIQUE', zip: '07198' },
  ESSEX_FELLS_07021: { cityName: 'ESSEX FELLS', districtCode: '0706', countyName: 'ESSEX', zip: '07021' },
  BELLEVILLE_07109: { cityName: 'BELLEVILLE', districtCode: '0701', countyName: 'ESSEX', zip: '07109' },
  WEST_ORANGE_07052: { cityName: 'WEST ORANGE', districtCode: '0722', countyName: 'ESSEX', zip: '07052' },
  ROSELAND_07068: { cityName: 'ROSELAND', districtCode: '0718', countyName: 'ESSEX', zip: '07068' },
  NEWARK_07195: { cityName: 'NEWARK', districtCode: '0714', countyName: 'ESSEX', recordType: 'UNIQUE', zip: '07195' },
  ORANGE_07050: { cityName: 'ORANGE', districtCode: '0717', countyName: 'ESSEX', zip: '07050' },
  VERONA_07044: { cityName: 'VERONA', districtCode: '0720', countyName: 'ESSEX', zip: '07044' },
  BLOOMFIELD_07003: { cityName: 'BLOOMFIELD', districtCode: '0702', countyName: 'ESSEX', zip: '07003' },
  MONTCLAIR_07043: { cityName: 'MONTCLAIR', districtCode: '0713', countyName: 'ESSEX', zip: '07043' },
  NEWARK_07193: { cityName: 'NEWARK', districtCode: '0714', countyName: 'ESSEX', recordType: 'UNIQUE', zip: '07193' },
  NEWARK_07192: { cityName: 'NEWARK', districtCode: '0714', countyName: 'ESSEX', recordType: 'UNIQUE', zip: '07192' },
  MILLBURN_07041: { cityName: 'MILLBURN', districtCode: '0712', countyName: 'ESSEX', zip: '07041' },
  NEWARK_07114: { cityName: 'NEWARK', districtCode: '0714', countyName: 'ESSEX', zip: '07114' },
  NEWARK_07112: { cityName: 'NEWARK', districtCode: '0714', countyName: 'ESSEX', zip: '07112' },
  NEWARK_07108: { cityName: 'NEWARK', districtCode: '0714', countyName: 'ESSEX', zip: '07108' },
  NEWARK_07107: { cityName: 'NEWARK', districtCode: '0714', countyName: 'ESSEX', zip: '07107' },
  NEWARK_07106: { cityName: 'NEWARK', districtCode: '0714', countyName: 'ESSEX', zip: '07106' },
  LIVINGSTON_07039: { cityName: 'LIVINGSTON', districtCode: '0710', countyName: 'ESSEX', zip: '07039' },
  CALDWELL_07007: {
    cityName: 'CALDWELL',
    districtCode: '0703',
    countyName: 'ESSEX',
    recordType: 'PO BOX',
    zip: '07007',
  },
  CALDWELL_07006: { cityName: 'CALDWELL', districtCode: '0703', countyName: 'ESSEX', zip: '07006' },
  NEWARK_07105: { cityName: 'NEWARK', districtCode: '0714', countyName: 'ESSEX', zip: '07105' },
  ORANGE_07051: { cityName: 'ORANGE', districtCode: '0717', countyName: 'ESSEX', recordType: 'PO BOX', zip: '07051' },
  NEWARK_07191: { cityName: 'NEWARK', districtCode: '0714', countyName: 'ESSEX', recordType: 'UNIQUE', zip: '07191' },
  EAST_ORANGE_07017: { cityName: 'EAST ORANGE', districtCode: '0705', countyName: 'ESSEX', zip: '07017' },
  IRVINGTON_07111: { cityName: 'IRVINGTON', districtCode: '0709', countyName: 'ESSEX', zip: '07111' },
  EAST_ORANGE_07018: { cityName: 'EAST ORANGE', districtCode: '0705', countyName: 'ESSEX', zip: '07018' },
  EAST_ORANGE_07019: {
    cityName: 'EAST ORANGE',
    districtCode: '0705',
    countyName: 'ESSEX',
    recordType: 'PO BOX',
    zip: '07019',
  },
  MONTCLAIR_07042: { cityName: 'MONTCLAIR', districtCode: '0713', countyName: 'ESSEX', zip: '07042' },
  DEPTFORD_08096: { cityName: 'DEPTFORD', districtCode: '0802', countyName: 'GLOUCESTER', zip: '08096' },
  DEPTFORD_08097: { cityName: 'DEPTFORD', districtCode: '0802', countyName: 'GLOUCESTER', zip: '08097' },
  PAULSBORO_08066: { cityName: 'PAULSBORO', districtCode: '0814', countyName: 'GLOUCESTER', zip: '08066' },
  WEST_DEPTFORD_08066: { cityName: 'WEST DEPTFORD', districtCode: '0820', countyName: 'GLOUCESTER', zip: '08066' },
  CLAYTON_08312: { cityName: 'CLAYTON', districtCode: '0801', countyName: 'GLOUCESTER', zip: '08312' },
  WOODBURY_HEIGHTS_08097: {
    cityName: 'WOODBURY HEIGHTS',
    districtCode: '0823',
    countyName: 'GLOUCESTER',
    zip: '08097',
  },
  WESTVILLE_08093: { cityName: 'WESTVILLE', districtCode: '0821', countyName: 'GLOUCESTER', zip: '08093' },
  WILLIAMSTOWN_08094: { cityName: 'WILLIAMSTOWN', districtCode: '0811', countyName: 'GLOUCESTER', zip: '08094' },
  NATIONAL_PARK_08063: { cityName: 'NATIONAL PARK', districtCode: '0812', countyName: 'GLOUCESTER', zip: '08063' },
  Mickleton_08056: { cityName: 'Mickleton', districtCode: '0803', countyName: 'GLOUCESTER', zip: '08056' },
  WENONAH_08090: { cityName: 'WENONAH', districtCode: '0819', countyName: 'GLOUCESTER', zip: '08090' },
  WOODBURY_CITY_08097: {
    cityName: 'WOODBURY CITY',
    districtCode: '0822',
    countyName: 'GLOUCESTER',
    recordType: 'WOODBURY HGTS, NJ is NOT ACCEPTABLE - USE WOODBURY HEIGHTS',
    zip: '08097',
  },
  SOUTH_HARRISON_TOWNSHIP_08062: {
    cityName: 'SOUTH HARRISON TOWNSHIP',
    districtCode: '0816',
    countyName: 'GLOUCESTER',
    zip: '08062',
  },
  GLASSBORO_08028: { cityName: 'GLASSBORO', districtCode: '0806', countyName: 'GLOUCESTER', zip: '08028' },
  WEST_DEPTFORD_08063: { cityName: 'WEST DEPTFORD', districtCode: '0820', countyName: 'GLOUCESTER', zip: '08063' },
  FRANKLINVILLE_08322: { cityName: 'FRANKLINVILLE', districtCode: '0805', countyName: 'GLOUCESTER', zip: '08322' },
  WEST_DEPTFORD_08051: { cityName: 'WEST DEPTFORD', districtCode: '0820', countyName: 'GLOUCESTER', zip: '08051' },
  FRANKLIN_08094: { cityName: 'FRANKLIN', districtCode: '0805', countyName: 'GLOUCESTER', zip: '08094' },
  FRANKLIN_08328: { cityName: 'FRANKLIN', districtCode: '0805', countyName: 'GLOUCESTER', zip: '08328' },
  FRANKLIN_08343: { cityName: 'FRANKLIN', districtCode: '0805', countyName: 'GLOUCESTER', zip: '08343' },
  GREENWICH_08020: { cityName: 'GREENWICH', districtCode: '0807', countyName: 'GLOUCESTER', zip: '08020' },
  GREENWICH_08027: { cityName: 'GREENWICH', districtCode: '0807', countyName: 'GLOUCESTER', zip: '08027' },
  GREENWICH_08056: { cityName: 'GREENWICH', districtCode: '0807', countyName: 'GLOUCESTER', zip: '08056' },
  GREENWICH_08066: { cityName: 'GREENWICH', districtCode: '0807', countyName: 'GLOUCESTER', zip: '08066' },
  GREENWICH_08085: { cityName: 'GREENWICH', districtCode: '0807', countyName: 'GLOUCESTER', zip: '08085' },
  FRANKLIN_08344: { cityName: 'FRANKLIN', districtCode: '0805', countyName: 'GLOUCESTER', zip: '08344' },
  FRANKLIN_08360: { cityName: 'FRANKLIN', districtCode: '0805', countyName: 'GLOUCESTER', zip: '08360' },
  MANTUA_08051: { cityName: 'MANTUA', districtCode: '0810', countyName: 'GLOUCESTER', zip: '08051' },
  WASHINGTON_07882: { cityName: 'WASHINGTON', districtCode: '0818', countyName: 'GLOUCESTER', zip: '07882' },
  LOGAN_08085: {
    cityName: 'LOGAN',
    districtCode: '0809',
    countyName: 'GLOUCESTER',
    recordType: 'LOGAN, NJ is NOT ACCEPTABLE - USE SWEDESBORO',
    zip: '08085',
  },
  SWEDESBORO_08085: {
    cityName: 'SWEDESBORO',
    districtCode: '0809',
    countyName: 'GLOUCESTER',
    zip: '08085',
  },
  WOOLWICH_08085: {
    cityName: 'WOOLWICH',
    districtCode: '0824',
    countyName: 'GLOUCESTER',
    recordType: 'WOOLWICH, NJ is NOT ACCEPTABLE - USE SWEDESBORO',
    zip: '08085',
  },
  WEST_DEPTFORD_08096: { cityName: 'WEST DEPTFORD', districtCode: '0820', countyName: 'GLOUCESTER', zip: '08096' },
  WEST_DEPTFORD_08093: { cityName: 'WEST DEPTFORD', districtCode: '0820', countyName: 'GLOUCESTER', zip: '08093' },
  WEST_DEPTFORD_08086: { cityName: 'WEST DEPTFORD', districtCode: '0820', countyName: 'GLOUCESTER', zip: '08086' },
  ELK_08028: { cityName: 'ELK', districtCode: '0804', countyName: 'GLOUCESTER', zip: '08028' },
  ELK_08062: { cityName: 'ELK', districtCode: '0804', countyName: 'GLOUCESTER', zip: '08062' },
  ELK_08312: { cityName: 'ELK', districtCode: '0804', countyName: 'GLOUCESTER', zip: '08312' },
  NEWFIELD_08344: { cityName: 'NEWFIELD', districtCode: '0813', countyName: 'GLOUCESTER', zip: '08344' },
  HARRISON_07029: { cityName: 'HARRISON', districtCode: '0808', countyName: 'GLOUCESTER', zip: '07029' },
  ELK_08343: { cityName: 'ELK', districtCode: '0804', countyName: 'GLOUCESTER', zip: '08343' },
  PITMAN_08071: { cityName: 'PITMAN', districtCode: '0815', countyName: 'GLOUCESTER', zip: '08071' },
  WEST_NEW_YORK_07093: { cityName: 'WEST NEW YORK', districtCode: '0912', countyName: 'HUDSON', zip: '07093' },
  NORTH_BERGEN_07047: { cityName: 'NORTH BERGEN', districtCode: '0908', countyName: 'HUDSON', zip: '07047' },
  JERSEY_CITY_07305: { cityName: 'JERSEY CITY', districtCode: '0906', countyName: 'HUDSON', zip: '07305' },
  JERSEY_CITY_07306: { cityName: 'JERSEY CITY', districtCode: '0906', countyName: 'HUDSON', zip: '07306' },
  JERSEY_CITY_07307: { cityName: 'JERSEY CITY', districtCode: '0906', countyName: 'HUDSON', zip: '07307' },
  JERSEY_CITY_07308: {
    cityName: 'JERSEY CITY',
    districtCode: '0906',
    countyName: 'HUDSON',
    recordType: 'PO BOX',
    zip: '07308',
  },
  JERSEY_CITY_07310: { cityName: 'JERSEY CITY', districtCode: '0906', countyName: 'HUDSON', zip: '07310' },
  JERSEY_CITY_07311: { cityName: 'JERSEY CITY', districtCode: '0906', countyName: 'HUDSON', zip: '07311' },
  JERSEY_CITY_07395: {
    cityName: 'JERSEY CITY',
    districtCode: '0906',
    countyName: 'HUDSON',
    recordType: 'UNIQUE',
    zip: '07395',
  },
  JERSEY_CITY_07399: {
    cityName: 'JERSEY CITY',
    districtCode: '0906',
    countyName: 'HUDSON',
    recordType: 'UNIQUE',
    zip: '07399',
  },
  KEARNY_07032: { cityName: 'KEARNY', districtCode: '0907', countyName: 'HUDSON', zip: '07032' },
  KEARNY_07099: { cityName: 'KEARNY', districtCode: '0907', countyName: 'HUDSON', recordType: 'UNIQUE', zip: '07099' },
  BAYONNE_07002: { cityName: 'BAYONNE', districtCode: '0901', countyName: 'HUDSON', zip: '07002' },
  JERSEY_CITY_07304: { cityName: 'JERSEY CITY', districtCode: '0906', countyName: 'HUDSON', zip: '07304' },
  WEEHAWKEN_07086: { cityName: 'WEEHAWKEN', districtCode: '0911', countyName: 'HUDSON', zip: '07086' },
  JERSEY_CITY_07303: {
    cityName: 'JERSEY CITY',
    districtCode: '0906',
    countyName: 'HUDSON',
    recordType: 'PO BOX',
    zip: '07303',
  },
  UNION_CITY_07087: { cityName: 'UNION CITY', districtCode: '0910', countyName: 'HUDSON', zip: '07087' },
  UNION_07088: { cityName: 'UNION', districtCode: '2019', countyName: 'UNION', zip: '07088' },
  HOBOKEN_07030: { cityName: 'HOBOKEN', districtCode: '0905', countyName: 'HUDSON', zip: '07030' },
  JERSEY_CITY_07097: {
    cityName: 'JERSEY CITY',
    districtCode: '0906',
    countyName: 'HUDSON',
    recordType: 'UNIQUE',
    zip: '07097',
  },
  HARRISON_TOWN_08062: {
    cityName: 'HARRISON TOWN',
    districtCode: '0904',
    countyName: 'HUDSON',
    recordType: 'HARRISON TOWNSHIP, NJ is NOT ACCEPTABLE - USE MULLICA HILL',
    zip: '08062',
  },
  MULLICA_HILL_08062: {
    cityName: 'MULLICA HILL',
    districtCode: '0904',
    countyName: 'HUDSON',
    zip: '08062',
  },
  JERSEY_CITY_07302: { cityName: 'JERSEY CITY', districtCode: '0906', countyName: 'HUDSON', zip: '07302' },
  EAST_NEWARK_07029: { cityName: 'EAST NEWARK', districtCode: '0902', countyName: 'HUDSON', zip: '07029' },
  SECAUCUS_07096: {
    cityName: 'SECAUCUS',
    districtCode: '0909',
    countyName: 'HUDSON',
    recordType: 'PO BOX',
    zip: '07096',
  },
  SECAUCUS_07094: { cityName: 'SECAUCUS', districtCode: '0909', countyName: 'HUDSON', zip: '07094' },
  GUTTENBERG_07093: { cityName: 'GUTTENBERG', districtCode: '0903', countyName: 'HUDSON', zip: '07093' },
  BETHLEHEM_08827: { cityName: 'BETHLEHEM', districtCode: '1002', countyName: 'HUNTERDON', zip: '08827' },
  LEBANON_07830: { cityName: 'LEBANON', districtCode: '1019', countyName: 'HUNTERDON', zip: '07830' },
  LEBANON_07865: { cityName: 'LEBANON', districtCode: '1019', countyName: 'HUNTERDON', zip: '07865' },
  LEBANON_07882: { cityName: 'LEBANON', districtCode: '1019', countyName: 'HUNTERDON', zip: '07882' },
  LEBANON_08809: { cityName: 'LEBANON', districtCode: '1019', countyName: 'HUNTERDON', zip: '08809' },
  LEBANON_08826: { cityName: 'LEBANON', districtCode: '1019', countyName: 'HUNTERDON', zip: '08826' },
  LEBANON_08827: { cityName: 'LEBANON', districtCode: '1019', countyName: 'HUNTERDON', zip: '08827' },
  LEBANON_08833: { cityName: 'LEBANON', districtCode: '1018', countyName: 'HUNTERDON', zip: '08833' },
  WEST_AMWELL_08530: {
    cityName: 'WEST AMWELL',
    districtCode: '1026',
    countyName: 'HUNTERDON',
    recordType: 'WEST AMWELL, NJ is NOT ACCEPTABLE - USE LAMBERTVILLE',
    zip: '08530',
  },
  LAMERTVILLE_08530: {
    cityName: 'LAMBERTVILLE',
    districtCode: '1026',
    countyName: 'HUNTERDON',
    zip: '08530',
  },
  ALEXANDRIA_08804: { cityName: 'ALEXANDRIA', districtCode: '1001', countyName: 'HUNTERDON', zip: '08804' },
  BETHLEHEM_08802: { cityName: 'BETHLEHEM', districtCode: '1002', countyName: 'HUNTERDON', zip: '08802' },
  BETHLEHEM_08804: { cityName: 'BETHLEHEM', districtCode: '1002', countyName: 'HUNTERDON', zip: '08804' },
  BETHLEHEM_08809: { cityName: 'BETHLEHEM', districtCode: '1002', countyName: 'HUNTERDON', zip: '08809' },
  BETHLEHEM_08826: { cityName: 'BETHLEHEM', districtCode: '1002', countyName: 'HUNTERDON', zip: '08826' },
  KINGWOOD_08559: { cityName: 'KINGWOOD', districtCode: '1016', countyName: 'HUNTERDON', zip: '08559' },
  BLOOMSBURY_08804: { cityName: 'BLOOMSBURY', districtCode: '1003', countyName: 'HUNTERDON', zip: '08804' },
  KINGWOOD_08822: { cityName: 'KINGWOOD', districtCode: '1016', countyName: 'HUNTERDON', zip: '08822' },
  UNION_08867: { cityName: 'UNION', districtCode: '1025', countyName: 'HUNTERDON', zip: '08867' },
  UNION_08848: { cityName: 'UNION', districtCode: '1025', countyName: 'HUNTERDON', zip: '08848' },
  UNION_08827: { cityName: 'UNION', districtCode: '1025', countyName: 'HUNTERDON', zip: '08827' },
  UNION_08809: { cityName: 'UNION', districtCode: '1025', countyName: 'HUNTERDON', zip: '08809' },
  UNION_08802: { cityName: 'UNION', districtCode: '1025', countyName: 'HUNTERDON', zip: '08802' },
  HOLLAND_08865: { cityName: 'HOLLAND', districtCode: '1015', countyName: 'HUNTERDON', zip: '08865' },
  HOLLAND_08848: { cityName: 'HOLLAND', districtCode: '1015', countyName: 'HUNTERDON', zip: '08848' },
  HOLLAND_08804: { cityName: 'HOLLAND', districtCode: '1015', countyName: 'HUNTERDON', zip: '08804' },
  KINGWOOD_08825: { cityName: 'KINGWOOD', districtCode: '1016', countyName: 'HUNTERDON', zip: '08825' },
  HIGH_BRIDGE_08829: { cityName: 'HIGH BRIDGE', districtCode: '1014', countyName: 'HUNTERDON', zip: '08829' },
  KINGWOOD_08867: { cityName: 'KINGWOOD', districtCode: '1016', countyName: 'HUNTERDON', zip: '08867' },
  ALEXANDRIA_08825: { cityName: 'ALEXANDRIA', districtCode: '1001', countyName: 'HUNTERDON', zip: '08825' },
  ALEXANDRIA_08827: { cityName: 'ALEXANDRIA', districtCode: '1001', countyName: 'HUNTERDON', zip: '08827' },
  MILFORD_08848: { cityName: 'MILFORD', districtCode: '1020', countyName: 'HUNTERDON', zip: '08848' },
  TEWKSBURY_TOWNSHIP_07830: {
    cityName: 'TEWKSBURY TOWNSHIP',
    districtCode: '1024',
    countyName: 'HUNTERDON',
    zip: '07830',
  },
  ALEXANDRIA_08848: { cityName: 'ALEXANDRIA', districtCode: '1001', countyName: 'HUNTERDON', zip: '08848' },
  CALIFON_07830: { cityName: 'CALIFON', districtCode: '1004', countyName: 'HUNTERDON', zip: '07830' },
  STOCKTON_08559: { cityName: 'STOCKTON', districtCode: '1023', countyName: 'HUNTERDON', zip: '08559' },
  HAMPTON_08827: { cityName: 'HAMPTON', districtCode: '1013', countyName: 'HUNTERDON', zip: '08827' },
  FLEMINGTON_08822: { cityName: 'FLEMINGTON', districtCode: '1009', countyName: 'HUNTERDON', zip: '08822' },
  EAST_AMWELL_08551: {
    cityName: 'EAST AMWELL',
    districtCode: '1008',
    countyName: 'HUNTERDON',
    recordType: 'EAST AMWELL, NJ is NOT ACCEPTABLE - USE RINGOES',
    zip: '08551',
  },
  RINGOES_08551: {
    cityName: 'EAST AMWELL',
    districtCode: '1008',
    countyName: 'HUNTERDON',
    zip: '08551',
  },
  ALEXANDRIA_08867: { cityName: 'ALEXANDRIA', districtCode: '1001', countyName: 'HUNTERDON', zip: '08867' },
  LAMBERTVILLE_08530: { cityName: 'LAMBERTVILLE', districtCode: '1017', countyName: 'HUNTERDON', zip: '08530' },
  CLINTON_08809: { cityName: 'CLINTON', districtCode: '1006', countyName: 'HUNTERDON', zip: '08809' },
  FRANKLIN_08825: { cityName: 'FRANKLIN', districtCode: '1010', countyName: 'HUNTERDON', zip: '08825' },
  DELAWARE_07833: {
    cityName: 'DELAWARE',
    districtCode: '1007',
    countyName: 'HUNTERDON',
    recordType: 'PO BOX',
    zip: '07833',
  },
  GLEN_GARDNER_08826: { cityName: 'GLEN GARDNER', districtCode: '1012', countyName: 'HUNTERDON', zip: '08826' },
  FRENCHTOWN_08825: { cityName: 'FRENCHTOWN', districtCode: '1011', countyName: 'HUNTERDON', zip: '08825' },
  READINGTON_08870: {
    cityName: 'READINGTON',
    districtCode: '1022',
    countyName: 'HUNTERDON',
    recordType: 'PO BOX',
    zip: '08870',
  },
  RARITAN_TOWNSHIP_08869: { cityName: 'RARITAN TOWNSHIP', districtCode: '1021', countyName: 'HUNTERDON', zip: '08869' },
  ALEXANDRIA_08802: { cityName: 'ALEXANDRIA', districtCode: '1001', countyName: 'HUNTERDON', zip: '08802' },
  FRANKLIN_08801: { cityName: 'FRANKLIN', districtCode: '1010', countyName: 'HUNTERDON', zip: '08801' },
  FRANKLIN_08809: { cityName: 'FRANKLIN', districtCode: '1010', countyName: 'HUNTERDON', zip: '08809' },
  FRANKLIN_08822: { cityName: 'FRANKLIN', districtCode: '1010', countyName: 'HUNTERDON', zip: '08822' },
  FRANKLIN_08867: { cityName: 'FRANKLIN', districtCode: '1010', countyName: 'HUNTERDON', zip: '08867' },
  TRENTON_08605: {
    cityName: 'TRENTON',
    districtCode: '1111',
    countyName: 'MERCER',
    recordType: 'PO BOX',
    zip: '08605',
  },
  TRENTON_08629: { cityName: 'TRENTON', districtCode: '1111', countyName: 'MERCER', zip: '08629' },
  TRENTON_08628: { cityName: 'TRENTON', districtCode: '1111', countyName: 'MERCER', zip: '08628' },
  TRENTON_08625: {
    cityName: 'TRENTON',
    districtCode: '1111',
    countyName: 'MERCER',
    recordType: 'PO BOX',
    zip: '08625',
  },
  HOPEWELL_08558: { cityName: 'HOPEWELL', districtCode: '1106', countyName: 'MERCER', zip: '08558' },
  TRENTON_08620: { cityName: 'TRENTON', districtCode: '1111', countyName: 'MERCER', zip: '08620' },
  TRENTON_08619: { cityName: 'TRENTON', districtCode: '1111', countyName: 'MERCER', zip: '08619' },
  TRENTON_08618: { cityName: 'TRENTON', districtCode: '1111', countyName: 'MERCER', zip: '08618' },
  TRENTON_08611: { cityName: 'TRENTON', districtCode: '1111', countyName: 'MERCER', zip: '08611' },
  TRENTON_08610: { cityName: 'TRENTON', districtCode: '1111', countyName: 'MERCER', zip: '08610' },
  EWING_08618: { cityName: 'EWING', districtCode: '1102', countyName: 'MERCER', zip: '08618' },
  EWING_08628: { cityName: 'EWING', districtCode: '1102', countyName: 'MERCER', zip: '08628' },
  TRENTON_08609: { cityName: 'TRENTON', districtCode: '1111', countyName: 'MERCER', zip: '08609' },
  TRENTON_08608: { cityName: 'TRENTON', districtCode: '1111', countyName: 'MERCER', zip: '08608' },
  TRENTON_08607: {
    cityName: 'TRENTON',
    districtCode: '1111',
    countyName: 'MERCER',
    recordType: 'PO BOX',
    zip: '08607',
  },
  TRENTON_08606: {
    cityName: 'TRENTON',
    districtCode: '1111',
    countyName: 'MERCER',
    recordType: 'PO BOX',
    zip: '08606',
  },
  HOPEWELL_08540: { cityName: 'HOPEWELL', districtCode: '1106', countyName: 'MERCER', zip: '08540' },
  TRENTON_08604: {
    cityName: 'TRENTON',
    districtCode: '1111',
    countyName: 'MERCER',
    recordType: 'PO BOX',
    zip: '08604',
  },
  TRENTON_08603: {
    cityName: 'TRENTON',
    districtCode: '1111',
    countyName: 'MERCER',
    recordType: 'PO BOX',
    zip: '08603',
  },
  TRENTON_08602: {
    cityName: 'TRENTON',
    districtCode: '1111',
    countyName: 'MERCER',
    recordType: 'PO BOX',
    zip: '08602',
  },
  TRENTON_08601: {
    cityName: 'TRENTON',
    districtCode: '1111',
    countyName: 'MERCER',
    recordType: 'PO BOX',
    zip: '08601',
  },
  HOPEWELL_08534: { cityName: 'HOPEWELL', districtCode: '1106', countyName: 'MERCER', zip: '08534' },
  EWING_08638: { cityName: 'EWING', districtCode: '1102', countyName: 'MERCER', zip: '08638' },
  HOPEWELL_08530: { cityName: 'HOPEWELL', districtCode: '1106', countyName: 'MERCER', zip: '08530' },
  HOPEWELL_08525: { cityName: 'HOPEWELL', districtCode: '1105', countyName: 'MERCER', zip: '08525' },
  HOPEWELL_08560: { cityName: 'HOPEWELL', districtCode: '1106', countyName: 'MERCER', zip: '08560' },
  TRENTON_08695: {
    cityName: 'TRENTON',
    districtCode: '1111',
    countyName: 'MERCER',
    recordType: 'UNIQUE',
    zip: '08695',
  },
  TRENTON_08691: { cityName: 'TRENTON', districtCode: '1111', countyName: 'MERCER', zip: '08691' },
  TRENTON_08690: { cityName: 'TRENTON', districtCode: '1111', countyName: 'MERCER', zip: '08690' },
  TRENTON_08666: {
    cityName: 'TRENTON',
    districtCode: '1111',
    countyName: 'MERCER',
    recordType: 'UNIQUE',
    zip: '08666',
  },
  PRINCETON_08540: { cityName: 'PRINCETON', districtCode: '1114', countyName: 'MERCER', zip: '08540' },
  PRINCETON_08541: {
    cityName: 'PRINCETON',
    districtCode: '1114',
    countyName: 'MERCER',
    recordType: 'UNIQUE',
    zip: '08541',
  },
  PRINCETON_08542: { cityName: 'PRINCETON', districtCode: '1114', countyName: 'MERCER', zip: '08542' },
  PRINCETON_08543: {
    cityName: 'PRINCETON',
    districtCode: '1114',
    countyName: 'MERCER',
    recordType: 'PO BOX',
    zip: '08543',
  },
  PENNINGTON_08534: { cityName: 'PENNINGTON', districtCode: '1108', countyName: 'MERCER', zip: '08534' },
  EAST_WINDSOR_08520: { cityName: 'EAST WINDSOR', districtCode: '1101', countyName: 'MERCER', zip: '08520' },
  PRINCETON_08544: {
    cityName: 'PRINCETON',
    districtCode: '1114',
    countyName: 'MERCER',
    recordType: 'UNIQUE',
    zip: '08544',
  },
  PRINCETON_BOROUGH_08540: { cityName: 'PRINCETON BOROUGH', districtCode: '1109', countyName: 'MERCER', zip: '08540' },
  EAST_WINDSOR_08512: { cityName: 'EAST WINDSOR', districtCode: '1101', countyName: 'MERCER', zip: '08512' },
  HAMILTON_08691: { cityName: 'HAMILTON', districtCode: '1103', countyName: 'MERCER', zip: '08691' },
  HAMILTON_08690: { cityName: 'HAMILTON', districtCode: '1103', countyName: 'MERCER', zip: '08690' },
  HAMILTON_08650: {
    cityName: 'HAMILTON',
    districtCode: '1103',
    countyName: 'MERCER',
    recordType: 'PO BOX',
    zip: '08650',
  },
  HAMILTON_08629: { cityName: 'HAMILTON', districtCode: '1103', countyName: 'MERCER', zip: '08629' },
  HAMILTON_08620: { cityName: 'HAMILTON', districtCode: '1103', countyName: 'MERCER', zip: '08620' },
  HAMILTON_08619: { cityName: 'HAMILTON', districtCode: '1103', countyName: 'MERCER', zip: '08619' },
  HAMILTON_08611: { cityName: 'HAMILTON', districtCode: '1103', countyName: 'MERCER', zip: '08611' },
  HAMILTON_08610: { cityName: 'HAMILTON', districtCode: '1103', countyName: 'MERCER', zip: '08610' },
  HAMILTON_08609: { cityName: 'HAMILTON', districtCode: '1103', countyName: 'MERCER', zip: '08609' },
  HAMILTON_TOWNSHIP_08691: { cityName: 'HAMILTON TOWNSHIP', districtCode: '1103', countyName: 'MERCER', zip: '08691' },
  HAMILTON_TOWNSHIP_08690: { cityName: 'HAMILTON TOWNSHIP', districtCode: '1103', countyName: 'MERCER', zip: '08690' },
  HAMILTON_TOWNSHIP_08650: {
    cityName: 'HAMILTON',
    districtCode: '1103',
    countyName: 'MERCER',
    recordType: 'PO BOX',
    zip: '08650',
  },
  HAMILTON_TOWNSHIP_08629: { cityName: 'HAMILTON TOWNSHIP', districtCode: '1103', countyName: 'MERCER', zip: '08629' },
  HAMILTON_TOWNSHIP_08620: { cityName: 'HAMILTON TOWNSHIP', districtCode: '1103', countyName: 'MERCER', zip: '08620' },
  HAMILTON_TOWNSHIP_08619: { cityName: 'HAMILTON TOWNSHIP', districtCode: '1103', countyName: 'MERCER', zip: '08619' },
  HAMILTON_TOWNSHIP_08611: { cityName: 'HAMILTON TOWNSHIP', districtCode: '1103', countyName: 'MERCER', zip: '08611' },
  HAMILTON_TOWNSHIP_08610: { cityName: 'HAMILTON TOWNSHIP', districtCode: '1103', countyName: 'MERCER', zip: '08610' },
  HAMILTON_TOWNSHIP_08609: { cityName: 'HAMILTON TOWNSHIP', districtCode: '1103', countyName: 'MERCER', zip: '08609' },
  PRINCETON_BOROUGH_08541: {
    cityName: 'PRINCETON BOROUGH',
    districtCode: '1109',
    countyName: 'MERCER',
    recordType: 'UNIQUE',
    zip: '08541',
  },
  TRENTON_08650: {
    cityName: 'TRENTON',
    districtCode: '1111',
    countyName: 'MERCER',
    recordType: 'PO BOX',
    zip: '08650',
  },
  ROBBINSVILLE_TOWNSHIP_08691: {
    cityName: 'ROBBINSVILLE TOWNSHIP',
    districtCode: '1112',
    countyName: 'MERCER',
    zip: '08691',
  },
  ROBBINSVILLE_TWP_08691: {
    cityName: 'ROBBINSVILLE TWP',
    districtCode: '1112',
    countyName: 'MERCER',
    zip: '08691',
  },
  TRENTON_08648: { cityName: 'TRENTON', districtCode: '1111', countyName: 'MERCER', zip: '08648' },
  PRINCETON_BOROUGH_08542: { cityName: 'PRINCETON BOROUGH', districtCode: '1109', countyName: 'MERCER', zip: '08542' },
  TRENTON_08647: {
    cityName: 'TRENTON',
    districtCode: '1111',
    countyName: 'MERCER',
    recordType: 'UNIQUE',
    zip: '08647',
  },
  WEST_WINDSOR_08550: { cityName: 'WEST WINDSOR', districtCode: '1113', countyName: 'MERCER', zip: '08550' },
  TRENTON_08646: {
    cityName: 'TRENTON',
    districtCode: '1111',
    countyName: 'MERCER',
    recordType: 'UNIQUE',
    zip: '08646',
  },
  TRENTON_08645: {
    cityName: 'TRENTON',
    districtCode: '1111',
    countyName: 'MERCER',
    recordType: 'UNIQUE',
    zip: '08645',
  },
  PRINCETON_TOWNSHIP_08544: {
    cityName: 'PRINCETON TOWNSHIP',
    districtCode: '1110',
    countyName: 'MERCER',
    recordType: 'UNIQUE',
    zip: '08544',
  },
  PRINCETON_TOWNSHIP_08543: {
    cityName: 'PRINCETON TOWNSHIP',
    districtCode: '1110',
    countyName: 'MERCER',
    recordType: 'PO BOX',
    zip: '08543',
  },
  PRINCETON_BOROUGH_08543: {
    cityName: 'PRINCETON BOROUGH',
    districtCode: '1109',
    countyName: 'MERCER',
    recordType: 'PO BOX',
    zip: '08543',
  },
  PRINCETON_BOROUGH_08544: {
    cityName: 'PRINCETON BOROUGH',
    districtCode: '1109',
    countyName: 'MERCER',
    recordType: 'UNIQUE',
    zip: '08544',
  },
  PRINCETON_TOWNSHIP_08540: {
    cityName: 'PRINCETON TOWNSHIP',
    districtCode: '1110',
    countyName: 'MERCER',
    zip: '08540',
  },
  TRENTON_08641: { cityName: 'TRENTON', districtCode: '1111', countyName: 'MERCER', zip: '08641' },
  TRENTON_08638: { cityName: 'TRENTON', districtCode: '1111', countyName: 'MERCER', zip: '08638' },
  HOPEWELL_08648: { cityName: 'HOPEWELL', districtCode: '1106', countyName: 'MERCER', zip: '08648' },
  HIGHTSTOWN_08520: { cityName: 'HIGHTSTOWN', districtCode: '1104', countyName: 'MERCER', zip: '08520' },
  PRINCETON_TOWNSHIP_08542: {
    cityName: 'PRINCETON TOWNSHIP',
    districtCode: '1110',
    countyName: 'MERCER',
    zip: '08542',
  },
  HOPEWELL_08638: { cityName: 'HOPEWELL', districtCode: '1106', countyName: 'MERCER', zip: '08638' },
  EWING_08560: { cityName: 'EWING', districtCode: '1102', countyName: 'MERCER', zip: '08560' },
  PRINCETON_TOWNSHIP_08541: {
    cityName: 'PRINCETON TOWNSHIP',
    districtCode: '1110',
    countyName: 'MERCER',
    recordType: 'UNIQUE',
    zip: '08541',
  },
  OLD_BRIDGE_08857: { cityName: 'OLD BRIDGE', districtCode: '1215', countyName: 'MIDDLESEX', zip: '08857' },
  OLD_BRIDGE_TOWNSHIP_08857: {
    cityName: 'OLD BRIDGE TOWNSHIP',
    districtCode: '1215',
    countyName: 'MIDDLESEX',
    zip: '08857',
  },
  NEW_BRUNSWICK_08989: {
    cityName: 'NEW BRUNSWICK',
    districtCode: '1213',
    countyName: 'MIDDLESEX',
    recordType: 'UNIQUE',
    zip: '08989',
  },
  NEW_BRUNSWICK_08906: {
    cityName: 'NEW BRUNSWICK',
    districtCode: '1213',
    countyName: 'MIDDLESEX',
    recordType: 'PO BOX',
    zip: '08906',
  },
  NEW_BRUNSWICK_08904: { cityName: 'NEW BRUNSWICK', districtCode: '1213', countyName: 'MIDDLESEX', zip: '08904' },
  NORTH_BRUNSWICK_08902: { cityName: 'NORTH BRUNSWICK', districtCode: '1214', countyName: 'MIDDLESEX', zip: '08902' },
  NEW_BRUNSWICK_08903: {
    cityName: 'NEW BRUNSWICK',
    districtCode: '1213',
    countyName: 'MIDDLESEX',
    recordType: 'PO BOX',
    zip: '08903',
  },
  NEW_BRUNSWICK_08902: { cityName: 'NEW BRUNSWICK', districtCode: '1213', countyName: 'MIDDLESEX', zip: '08902' },
  NEW_BRUNSWICK_08901: { cityName: 'NEW BRUNSWICK', districtCode: '1213', countyName: 'MIDDLESEX', zip: '08901' },
  DUNELLEN_08812: { cityName: 'DUNELLEN', districtCode: '1203', countyName: 'MIDDLESEX', zip: '08812' },
  CRANBURY_08512: { cityName: 'CRANBURY', districtCode: '1202', countyName: 'MIDDLESEX', zip: '08512' },
  SAYREVILLE_08871: {
    cityName: 'SAYREVILLE',
    districtCode: '1219',
    countyName: 'MIDDLESEX',
    recordType: 'PO BOX',
    zip: '08871',
  },
  SAYREVILLE_08872: { cityName: 'SAYREVILLE', districtCode: '1219', countyName: 'MIDDLESEX', zip: '08872' },
  SOUTH_AMBOY_08879: { cityName: 'SOUTH AMBOY', districtCode: '1220', countyName: 'MIDDLESEX', zip: '08879' },
  SOUTH_BRUNSWICK_08810: {
    cityName: 'SOUTH BRUNSWICK',
    districtCode: '1221',
    countyName: 'MIDDLESEX',
    recordType: 'SOUTH BRUNSWICK, NJ is NOT ACCEPTABLE - USE DAYTON',
    zip: '08810',
  },
  DAYTON_08810: {
    cityName: 'DAYTON',
    districtCode: '1221',
    countyName: 'MIDDLESEX',
    zip: '08810',
  },
  CARTERET_07008: { cityName: 'CARTERET', districtCode: '1201', countyName: 'MIDDLESEX', zip: '07008' },
  SOUTH_PLAINFIELD_07080: { cityName: 'SOUTH PLAINFIELD', districtCode: '1222', countyName: 'MIDDLESEX', zip: '07080' },
  SOUTH_RIVER_08882: { cityName: 'SOUTH RIVER', districtCode: '1223', countyName: 'MIDDLESEX', zip: '08882' },
  SPOTSWOOD_08884: { cityName: 'SPOTSWOOD', districtCode: '1224', countyName: 'MIDDLESEX', zip: '08884' },
  EAST_BRUNSWICK_08816: { cityName: 'EAST BRUNSWICK', districtCode: '1204', countyName: 'MIDDLESEX', zip: '08816' },
  PLAINSBORO_08536: { cityName: 'PLAINSBORO', districtCode: '1218', countyName: 'MIDDLESEX', zip: '08536' },
  NEW_BRUNSWICK_08933: {
    cityName: 'NEW BRUNSWICK',
    districtCode: '1213',
    countyName: 'MIDDLESEX',
    recordType: 'UNIQUE',
    zip: '08933',
  },
  PISCATAWAY_08855: {
    cityName: 'PISCATAWAY',
    districtCode: '1217',
    countyName: 'MIDDLESEX',
    recordType: 'PO BOX',
    zip: '08855',
  },
  PISCATAWAY_08854: { cityName: 'PISCATAWAY', districtCode: '1217', countyName: 'MIDDLESEX', zip: '08854' },
  MONROE_08831: { cityName: 'MONROE', districtCode: '1212', countyName: 'MIDDLESEX', zip: '08831' },
  MILLTOWN_08850: { cityName: 'MILLTOWN', districtCode: '1211', countyName: 'MIDDLESEX', zip: '08850' },
  MIDDLESEX_08846: { cityName: 'MIDDLESEX', districtCode: '1210', countyName: 'MIDDLESEX', zip: '08846' },
  METUCHEN_08840: { cityName: 'METUCHEN', districtCode: '1209', countyName: 'MIDDLESEX', zip: '08840' },
  HELMETTA_08828: { cityName: 'HELMETTA', districtCode: '1206', countyName: 'MIDDLESEX', zip: '08828' },
  HIGHLAND_PARK_08904: { cityName: 'HIGHLAND PARK', districtCode: '1207', countyName: 'MIDDLESEX', zip: '08904' },
  PERTH_AMBOY_08863: { cityName: 'PERTH AMBOY', districtCode: '1216', countyName: 'MIDDLESEX', zip: '08863' },
  PERTH_AMBOY_08862: {
    cityName: 'PERTH AMBOY',
    districtCode: '1216',
    countyName: 'MIDDLESEX',
    recordType: 'PO BOX',
    zip: '08862',
  },
  PERTH_AMBOY_08861: { cityName: 'PERTH AMBOY', districtCode: '1216', countyName: 'MIDDLESEX', zip: '08861' },
  WOODBRIDGE_07095: { cityName: 'WOODBRIDGE', districtCode: '1225', countyName: 'MIDDLESEX', zip: '07095' },
  WOODBRIDGE_TOWNSHIP_07067: {
    cityName: 'WOODBRIDGE TOWNSHIP',
    districtCode: '1225',
    countyName: 'MIDDLESEX',
    zip: '07095',
  },
  WOODBRIDGE_TOWNSHIP_08830: {
    cityName: 'WOODBRIDGE TOWNSHIP',
    districtCode: '1225',
    countyName: 'MIDDLESEX',
    zip: '08830',
  },
  AVENEL_07095: { cityName: 'AVENEL', districtCode: '1225', countyName: 'MIDDLESEX', zip: '07095' },
  JAMESBURG_08831: { cityName: 'JAMESBURG', districtCode: '1208', countyName: 'MIDDLESEX', zip: '08831' },
  EDISON_08817: { cityName: 'EDISON', districtCode: '1205', countyName: 'MIDDLESEX', zip: '08817' },
  EDISON_08818: {
    cityName: 'EDISON',
    districtCode: '1205',
    countyName: 'MIDDLESEX',
    recordType: 'PO BOX',
    zip: '08818',
  },
  EDISON_08820: { cityName: 'EDISON', districtCode: '1205', countyName: 'MIDDLESEX', zip: '08820' },
  EDISON_08837: { cityName: 'EDISON', districtCode: '1205', countyName: 'MIDDLESEX', zip: '08837' },
  EDISON_08899: { cityName: 'EDISON', districtCode: '1205', countyName: 'MIDDLESEX', zip: '08899' },
  EDISON_08906: {
    cityName: 'EDISON',
    districtCode: '1205',
    countyName: 'MIDDLESEX',
    recordType: 'PO BOX',
    zip: '08906',
  },
  TINTON_FALLS_07712: { cityName: 'TINTON FALLS', districtCode: '1349', countyName: 'MONMOUTH', zip: '07712' },
  WALL_07727: {
    cityName: 'WALL',
    districtCode: '1352',
    countyName: 'MONMOUTH',
    recordType: 'WALL, NJ is NOT ACCEPTABLE - USE FARMINGDALE',
    zip: '07727',
  },
  FARMINGDALE_07727: {
    cityName: 'FARMINGDALE',
    districtCode: '1352',
    countyName: 'MONMOUTH',
    zip: '07727',
  },
  TINTON_FALLS_07701: { cityName: 'TINTON FALLS', districtCode: '1349', countyName: 'MONMOUTH', zip: '07701' },
  FREEHOLD_BOROUGH_07728: { cityName: 'FREEHOLD BOROUGH', districtCode: '1316', countyName: 'MONMOUTH', zip: '07728' },
  FREEHOLD_08535: { cityName: 'FREEHOLD', districtCode: '1317', countyName: 'MONMOUTH', zip: '08535' },
  FREEHOLD_08527: { cityName: 'FREEHOLD', districtCode: '1317', countyName: 'MONMOUTH', zip: '08527' },
  WALL_07719: { cityName: 'WALL', districtCode: '1352', countyName: 'MONMOUTH', zip: '07719' },
  MONMOUTH_BEACH_07750: { cityName: 'MONMOUTH BEACH', districtCode: '1334', countyName: 'MONMOUTH', zip: '07750' },
  MARLBORO_07746: { cityName: 'MARLBORO', districtCode: '1330', countyName: 'MONMOUTH', zip: '07746' },
  BRIELLE_08730: { cityName: 'BRIELLE', districtCode: '1309', countyName: 'MONMOUTH', zip: '08730' },
  MATAWAN_07747: { cityName: 'MATAWAN', districtCode: '1331', countyName: 'MONMOUTH', zip: '07747' },
  KEYPORT_07735: { cityName: 'KEYPORT', districtCode: '1324', countyName: 'MONMOUTH', zip: '07735' },
  SPRING_LAKE_HEIGHTS_BOR_07762: {
    cityName: 'SPRING LAKE HEIGHTS BOR',
    districtCode: '1348',
    countyName: 'MONMOUTH',
    recordType: 'SPRING LAKE HEIGHTS, NJ is NOT ACCEPTABLE - USE SPRING LAKE',
    zip: '07762',
  },
  SPRING_LAKE_07762: { cityName: 'SPRING LAKE', districtCode: '1347', countyName: 'MONMOUTH', zip: '07762' },
  FREEHOLD_07746: { cityName: 'FREEHOLD', districtCode: '1317', countyName: 'MONMOUTH', zip: '07746' },
  FREEHOLD_07728: { cityName: 'FREEHOLD', districtCode: '1317', countyName: 'MONMOUTH', zip: '07728' },
  FREEHOLD_07726: { cityName: 'FREEHOLD', districtCode: '1317', countyName: 'MONMOUTH', zip: '07726' },
  HIGHLANDS_07732: { cityName: 'HIGHLANDS', districtCode: '1319', countyName: 'MONMOUTH', zip: '07732' },
  ASBURY_PARK_07712: { cityName: 'ASBURY PARK', districtCode: '1304', countyName: 'MONMOUTH', zip: '07712' },
  HAZLET_07730: { cityName: 'HAZLET', districtCode: '1318', countyName: 'MONMOUTH', zip: '07730' },
  ATLANTIC_HIGHLANDS_07716: {
    cityName: 'ATLANTIC HIGHLANDS',
    districtCode: '1305',
    countyName: 'MONMOUTH',
    zip: '07716',
  },
  AVON_BY_THE_SEA_07717: { cityName: 'AVON BY THE SEA', districtCode: '1306', countyName: 'MONMOUTH', zip: '07717' },
  HOWELL_07731: { cityName: 'HOWELL', districtCode: '1321', countyName: 'MONMOUTH', zip: '07731' },
  SOUTH_BELMAR_07719: {
    cityName: 'SOUTH BELMAR',
    districtCode: '1346',
    countyName: 'MONMOUTH',
    recordType: 'SOUTH BELMAR, NJ is NOT ACCEPTABLE - USE BELMAR',
    zip: '07719',
  },
  BELMAR_07719: {
    cityName: 'BELMAR',
    districtCode: '1346',
    countyName: 'MONMOUTH',
    zip: '07719',
  },
  LITTLE_SILVER_07739: { cityName: 'LITTLE SILVER', districtCode: '1325', countyName: 'MONMOUTH', zip: '07739' },
  SHREWSBURY_07724: { cityName: 'SHREWSBURY', districtCode: '1345', countyName: 'MONMOUTH', zip: '07724' },
  SHREWSBURY_07702: { cityName: 'SHREWSBURY', districtCode: '1344', countyName: 'MONMOUTH', zip: '07702' },
  KEANSBURG_07734: { cityName: 'KEANSBURG', districtCode: '1323', countyName: 'MONMOUTH', zip: '07734' },
  LOCH_ARBOUR__07711: {
    cityName: 'LOCH ARBOUR',
    districtCode: '1326',
    countyName: 'MONMOUTH',
    zip: '07711',
  },
  SEA_GIRT_08750: { cityName: 'SEA GIRT', districtCode: '1343', countyName: 'MONMOUTH', zip: '08750' },
  SEA_BRIGHT_07760: { cityName: 'SEA BRIGHT', districtCode: '1342', countyName: 'MONMOUTH', zip: '07760' },
  HOLMDEL_07733: { cityName: 'HOLMDEL', districtCode: '1320', countyName: 'MONMOUTH', zip: '07733' },
  ABERDEEN_07747: { cityName: 'ABERDEEN', districtCode: '1301', countyName: 'MONMOUTH', zip: '07747' },
  ALLENHURST_07711: { cityName: 'ALLENHURST', districtCode: '1302', countyName: 'MONMOUTH', zip: '07711' },
  WEST_LONG_BRANCH_07764: { cityName: 'WEST LONG BRANCH', districtCode: '1353', countyName: 'MONMOUTH', zip: '07764' },
  BELMAR_07715: {
    cityName: 'BELMAR',
    districtCode: '1307',
    countyName: 'MONMOUTH',
    recordType: 'UNIQUE',
    zip: '07715',
  },
  MIDDLETOWN_07748: { cityName: 'MIDDLETOWN', districtCode: '1332', countyName: 'MONMOUTH', zip: '07748' },
  MANALAPAN_07726: { cityName: 'MANALAPAN', districtCode: '1328', countyName: 'MONMOUTH', zip: '07726' },
  OCEANPORT_07757: { cityName: 'OCEANPORT', districtCode: '1338', countyName: 'MONMOUTH', zip: '07757' },
  OCEAN_07712: { cityName: 'OCEAN', districtCode: '1337', countyName: 'MONMOUTH', zip: '07712' },
  MANASQUAN_08736: { cityName: 'MANASQUAN', districtCode: '1329', countyName: 'MONMOUTH', zip: '08736' },
  LONG_BRANCH_07740: { cityName: 'LONG BRANCH', districtCode: '1327', countyName: 'MONMOUTH', zip: '07740' },
  UPPER_FREEHOLD_08501: {
    cityName: 'UPPER FREEHOLD',
    districtCode: '1351',
    countyName: 'MONMOUTH',
    recordType: 'UPPER FREEHOLD, NJ is NOT ACCEPTABLE - USE ALLENTOWN',
    zip: '08501',
  },
  BRADLEY_BEACH_07720: { cityName: 'BRADLEY BEACH', districtCode: '1308', countyName: 'MONMOUTH', zip: '07720' },
  NEPTUNE_07753: { cityName: 'NEPTUNE', districtCode: '1335', countyName: 'MONMOUTH', zip: '07753' },
  NEPTUNE_07754: {
    cityName: 'NEPTUNE',
    districtCode: '1335',
    countyName: 'MONMOUTH',
    recordType: 'PO BOX',
    zip: '07754',
  },
  FAIR_HAVEN_07704: { cityName: 'FAIR HAVEN', districtCode: '1314', countyName: 'MONMOUTH', zip: '07704' },
  NEPTUNE_CITY_07753: { cityName: 'NEPTUNE CITY', districtCode: '1336', countyName: 'MONMOUTH', zip: '07753' },
  COLTS_NECK_07722: { cityName: 'COLTS NECK', districtCode: '1310', countyName: 'MONMOUTH', zip: '07722' },
  RUMSON_07760: { cityName: 'RUMSON', districtCode: '1341', countyName: 'MONMOUTH', zip: '07760' },
  UNION_BEACH_07735: { cityName: 'UNION BEACH', districtCode: '1350', countyName: 'MONMOUTH', zip: '07735' },
  ENGLISHTOWN_07726: { cityName: 'ENGLISHTOWN', districtCode: '1313', countyName: 'MONMOUTH', zip: '07726' },
  ROOSEVELT_08555: {
    cityName: 'ROOSEVELT',
    districtCode: '1340',
    countyName: 'MONMOUTH',
    recordType: 'PO BOX',
    zip: '08555',
  },
  DEAL_07723: { cityName: 'DEAL', districtCode: '1311', countyName: 'MONMOUTH', zip: '07723' },
  INTERLAKEN_07712: { cityName: 'INTERLAKEN', districtCode: '1322', countyName: 'MONMOUTH', zip: '07712' },
  ALLENTOWN_08501: { cityName: 'ALLENTOWN', districtCode: '1303', countyName: 'MONMOUTH', zip: '08501' },
  WALL_07753: {
    cityName: 'WALL',
    districtCode: '1352',
    countyName: 'MONMOUTH',
    recordType: 'WALL, NJ is NOT ACCEPTABLE - USE NEPTUNE',
    zip: '07753',
  },
  EATONTOWN_07724: { cityName: 'EATONTOWN', districtCode: '1312', countyName: 'MONMOUTH', zip: '07724' },
  TINTON_FALLS_07753: { cityName: 'TINTON FALLS', districtCode: '1349', countyName: 'MONMOUTH', zip: '07753' },
  EATONTOWN_07799: { cityName: 'EATONTOWN', districtCode: '1312', countyName: 'MONMOUTH', zip: '07799' },
  RED_BANK_07704: { cityName: 'RED BANK', districtCode: '1339', countyName: 'MONMOUTH', zip: '07704' },
  TINTON_FALLS_07727: { cityName: 'TINTON FALLS', districtCode: '1349', countyName: 'MONMOUTH', zip: '07727' },
  RED_BANK_07703: { cityName: 'RED BANK', districtCode: '1339', countyName: 'MONMOUTH', zip: '07703' },
  TINTON_FALLS_07724: { cityName: 'TINTON FALLS', districtCode: '1349', countyName: 'MONMOUTH', zip: '07724' },
  RED_BANK_07702: { cityName: 'RED BANK', districtCode: '1339', countyName: 'MONMOUTH', zip: '07702' },
  RED_BANK_07701: { cityName: 'RED BANK', districtCode: '1339', countyName: 'MONMOUTH', zip: '07701' },
  RIVERDALE_07457: { cityName: 'RIVERDALE', districtCode: '1433', countyName: 'MORRIS', zip: '07457' },
  JEFFERSON_TOWNSHIP_07438: {
    cityName: 'JEFFERSON TOWNSHIP',
    districtCode: '1414',
    countyName: 'MORRIS',
    recordType: 'JEFFERSON TOWNSHIP, NJ is NOT ACCEPTABLE - USE OAK RIDGE',
    zip: '07438',
  },
  OAK_RIDGE_07438: {
    cityName: 'OAK RIDGE',
    districtCode: '1414',
    countyName: 'MORRIS',
    zip: '07438',
  },
  KINNELON_07405: { cityName: 'KINNELON', districtCode: '1415', countyName: 'MORRIS', zip: '07405' },
  MONTVILLE_07045: { cityName: 'MONTVILLE', districtCode: '1421', countyName: 'MORRIS', zip: '07045' },
  HANOVER_07927: { cityName: 'HANOVER', districtCode: '1412', countyName: 'MORRIS', zip: '07927' },
  BUTLER_07405: { cityName: 'BUTLER', districtCode: '1403', countyName: 'MORRIS', zip: '07405' },
  MORRISTOWN_07960: { cityName: 'MORRISTOWN', districtCode: '1424', countyName: 'MORRIS', zip: '07960' },
  WHARTON_07885: { cityName: 'WHARTON', districtCode: '1439', countyName: 'MORRIS', zip: '07885' },
  CHATHAM_07928: { cityName: 'CHATHAM', districtCode: '1405', countyName: 'MORRIS', zip: '07928' },
  PEQUANNOCK_07440: { cityName: 'PEQUANNOCK', districtCode: '1431', countyName: 'MORRIS', zip: '07440' },
  PEQUANNOCK_TOWNSHIP_07444: {
    cityName: 'PEQUANNOCK',
    districtCode: '1431',
    countyName: 'MORRIS',
    zip: '07444',
  },
  POMPTON_PLAINS_07444: {
    cityName: 'POMPTON PLAINS',
    districtCode: '1431',
    countyName: 'MORRIS',
    zip: '07444',
  },
  CHATHAM_BOROUGH_07928: { cityName: 'CHATHAM BOROUGH', districtCode: '1404', countyName: 'MORRIS', zip: '07928' },
  MORRISTOWN_07961: {
    cityName: 'MORRISTOWN',
    districtCode: '1424',
    countyName: 'MORRIS',
    recordType: 'PO BOX',
    zip: '07961',
  },
  BROOKSIDE_07926: { cityName: 'BROOKSIDE', districtCode: '1419', countyName: 'MORRIS', zip: '07926' },
  PARSIPPANY_TR_HLS_07054: {
    cityName: 'PARSIPPANY TR HLS',
    districtCode: '1429',
    countyName: 'MORRIS',
    recordType: 'PARSIPPANY TROY HILLS, NJ is NOT ACCEPTABLE - USE PARSIPPANY',
    zip: '07054',
  },
  PARSIPPANY_07054: {
    cityName: 'PARSIPPANY',
    districtCode: '1429',
    countyName: 'MORRIS',
    zip: '07054',
  },
  MORRISTOWN_07962: {
    cityName: 'MORRISTOWN',
    districtCode: '1424',
    countyName: 'MORRIS',
    recordType: 'PO BOX',
    zip: '07962',
  },
  EAST_HANOVER_07936: { cityName: 'EAST HANOVER', districtCode: '1410', countyName: 'MORRIS', zip: '07936' },
  MORRISTOWN_07963: {
    cityName: 'MORRISTOWN',
    districtCode: '1424',
    countyName: 'MORRIS',
    recordType: 'PO BOX',
    zip: '07963',
  },
  CHESTER_BOROUGH_07930: { cityName: 'CHESTER BOROUGH', districtCode: '1407', countyName: 'MORRIS', zip: '07930' },
  CHESTER_TOWNSHIP_07930: { cityName: 'CHESTER TOWNSHIP', districtCode: '1406', countyName: 'MORRIS', zip: '07930' },
  MORRIS_07869: { cityName: 'MORRIS', districtCode: '1422', countyName: 'MORRIS', zip: '07869' },
  MORRIS_07940: { cityName: 'MORRIS', districtCode: '1422', countyName: 'MORRIS', zip: '07940' },
  LINCOLN_PARK_07035: { cityName: 'LINCOLN PARK', districtCode: '1416', countyName: 'MORRIS', zip: '07035' },
  MORRIS_07950: { cityName: 'MORRIS', districtCode: '1422', countyName: 'MORRIS', zip: '07950' },
  MORRIS_07960: { cityName: 'MORRIS', districtCode: '1422', countyName: 'MORRIS', zip: '07960' },
  MORRIS_PLAINS_07950: { cityName: 'MORRIS PLAINS', districtCode: '1423', countyName: 'MORRIS', zip: '07950' },
  MINE_HILL_07803: { cityName: 'MINE HILL', districtCode: '1420', countyName: 'MORRIS', zip: '07803' },
  MOUNTAIN_LAKES_07046: { cityName: 'MOUNTAIN LAKES', districtCode: '1425', countyName: 'MORRIS', zip: '07046' },
  FLORHAM_PARK_07932: { cityName: 'FLORHAM PARK', districtCode: '1411', countyName: 'MORRIS', zip: '07932' },
  VICTORY_GARDENS_07801: {
    cityName: 'VICTORY GARDENS',
    districtCode: '1437',
    countyName: 'MORRIS',
    recordType: 'VICTORY GARDENS, NJ is NOT ACCEPTABLE - USE DOVER',
    zip: '07801',
  },
  MOUNT_ARLINGTON_07856: { cityName: 'MOUNT ARLINGTON', districtCode: '1426', countyName: 'MORRIS', zip: '07856' },
  BOONTON_07005: { cityName: 'BOONTON', districtCode: '1402', countyName: 'MORRIS', zip: '07005' },
  LONG_HILL_07059: { cityName: 'LONG HILL', districtCode: '1430', countyName: 'MORRIS', zip: '07059' },
  BOONTON_TOWN_07005: { cityName: 'BOONTON TOWN', districtCode: '1401', countyName: 'MORRIS', zip: '07005' },
  LONG_HILL_07920: { cityName: 'LONG HILL', districtCode: '1430', countyName: 'MORRIS', zip: '07920' },
  LONG_HILL_07928: { cityName: 'LONG HILL', districtCode: '1430', countyName: 'MORRIS', zip: '07928' },
  LONG_HILL_07933: { cityName: 'LONG HILL', districtCode: '1430', countyName: 'MORRIS', zip: '07933' },
  LONG_HILL_07946: { cityName: 'LONG HILL', districtCode: '1430', countyName: 'MORRIS', zip: '07946' },
  LONG_HILL_07980: { cityName: 'LONG HILL', districtCode: '1430', countyName: 'MORRIS', zip: '07980' },
  LONG_VALLEY_07853: { cityName: 'LONG VALLEY', districtCode: '1438', countyName: 'MORRIS', zip: '07853' },
  HANOVER_07936: { cityName: 'HANOVER', districtCode: '1412', countyName: 'MORRIS', zip: '07936' },
  HANOVER_07950: { cityName: 'HANOVER', districtCode: '1412', countyName: 'MORRIS', zip: '07950' },
  MADISON_07940: { cityName: 'MADISON', districtCode: '1417', countyName: 'MORRIS', zip: '07940' },
  MOUNT_OLIVE_07828: {
    cityName: 'MOUNT OLIVE',
    districtCode: '1427',
    countyName: 'MORRIS',
    recordType: 'MOUNT OLIVE, NJ is NOT ACCEPTABLE - USE BUDD LAKE',
    zip: '07828',
  },
  BUDD_LAKE_07828: {
    cityName: 'BUDD LAKE',
    districtCode: '1427',
    countyName: 'MORRIS',
    zip: '07828',
  },
  RANDOLPH_07869: { cityName: 'RANDOLPH', districtCode: '1432', countyName: 'MORRIS', zip: '07869' },
  NETCONG_07857: { cityName: 'NETCONG', districtCode: '1428', countyName: 'MORRIS', zip: '07857' },
  ROXBURY_TOWNSHIP_07836: { cityName: 'ROXBURY TOWNSHIP', districtCode: '1436', countyName: 'MORRIS', zip: '07836' },
  HANOVER_07960: { cityName: 'HANOVER', districtCode: '1412', countyName: 'MORRIS', zip: '07960' },
  HANOVER_07981: { cityName: 'HANOVER', districtCode: '1412', countyName: 'MORRIS', zip: '07981' },
  MENDHAM_07945: { cityName: 'MENDHAM', districtCode: '1418', countyName: 'MORRIS', zip: '07945' },
  HARDING_07976: { cityName: 'HARDING', districtCode: '1413', countyName: 'MORRIS', zip: '07976' },
  DOVER_TOWN_08753: {
    cityName: 'DOVER TOWN',
    districtCode: '1409',
    countyName: 'MORRIS',
    recordType: 'DOVER TWP, NJ is NOT ACCEPTABLE - USE TOMS RIVER',
    zip: '08753',
  },
  TOMS_RIVER_08753: {
    cityName: 'TOMS RIVER',
    districtCode: '1508',
    countyName: 'OCEAN',
    zip: '08753',
  },
  TOMS_RIVER_08755: {
    cityName: 'TOMS RIVER',
    districtCode: '1508',
    countyName: 'OCEAN',
    zip: '08755',
  },
  TOMS_RIVER_08754: {
    cityName: 'TOMS RIVER',
    districtCode: '1508',
    countyName: 'OCEAN',
    zip: '08754',
  },
  TOMS_RIVER_08756: {
    cityName: 'TOMS RIVER',
    districtCode: '1508',
    countyName: 'OCEAN',
    zip: '08756',
  },
  TOMS_RIVER_08757: {
    cityName: 'TOMS RIVER',
    districtCode: '1508',
    countyName: 'OCEAN',
    zip: '08757',
  },
  HARDING_07920: { cityName: 'HARDING', districtCode: '1413', countyName: 'MORRIS', zip: '07920' },
  HARDING_07933: { cityName: 'HARDING', districtCode: '1413', countyName: 'MORRIS', zip: '07933' },
  ROCKAWAY_TOWNSHIP_07885: { cityName: 'ROCKAWAY TOWNSHIP', districtCode: '1434', countyName: 'MORRIS', zip: '07885' },
  ROCKAWAY_TOWNSHIP_07869: { cityName: 'ROCKAWAY TOWNSHIP', districtCode: '1434', countyName: 'MORRIS', zip: '07869' },
  ROCKAWAY_TOWNSHIP_07866: { cityName: 'ROCKAWAY TOWNSHIP', districtCode: '1434', countyName: 'MORRIS', zip: '07866' },
  ROCKAWAY_TOWNSHIP_07842: { cityName: 'ROCKAWAY TOWNSHIP', districtCode: '1434', countyName: 'MORRIS', zip: '07842' },
  DENVILLE_07834: { cityName: 'DENVILLE', districtCode: '1408', countyName: 'MORRIS', zip: '07834' },
  HARDING_07960: { cityName: 'HARDING', districtCode: '1413', countyName: 'MORRIS', zip: '07960' },
  HARDING_07945: { cityName: 'HARDING', districtCode: '1413', countyName: 'MORRIS', zip: '07945' },
  HARDING_07935: { cityName: 'HARDING', districtCode: '1413', countyName: 'MORRIS', zip: '07935' },
  ROCKAWAY_TOWNSHIP_07005: { cityName: 'ROCKAWAY TOWNSHIP', districtCode: '1434', countyName: 'MORRIS', zip: '07005' },
  ROCKAWAY_TOWNSHIP_07435: { cityName: 'ROCKAWAY TOWNSHIP', districtCode: '1434', countyName: 'MORRIS', zip: '07435' },
  ROCKAWAY_TOWNSHIP_07438: { cityName: 'ROCKAWAY TOWNSHIP', districtCode: '1434', countyName: 'MORRIS', zip: '07438' },
  ROCKAWAY_TOWNSHIP_07801: { cityName: 'ROCKAWAY TOWNSHIP', districtCode: '1434', countyName: 'MORRIS', zip: '07801' },
  ROCKAWAY_TOWNSHIP_07834: { cityName: 'ROCKAWAY TOWNSHIP', districtCode: '1434', countyName: 'MORRIS', zip: '07834' },
  HANOVER_07054: { cityName: 'HANOVER', districtCode: '1412', countyName: 'MORRIS', zip: '07054' },
  DOVER_07801: { cityName: 'DOVER', districtCode: '1508', countyName: 'OCEAN', zip: '07801' },
  DOVER_07803: { cityName: 'DOVER', districtCode: '1508', countyName: 'OCEAN', zip: '07803' },
  JACKSON_08527: { cityName: 'JACKSON', districtCode: '1512', countyName: 'OCEAN', zip: '08527' },
  DOVER_07806: { cityName: 'DOVER', districtCode: '1508', countyName: 'OCEAN', zip: '07806' },
  DOVER_07869: { cityName: 'DOVER', districtCode: '1508', countyName: 'OCEAN', zip: '07869' },
  HARVEY_CEDARS_08008: { cityName: 'HARVEY CEDARS', districtCode: '1510', countyName: 'OCEAN', zip: '08008' },
  MANTOLOKING_08738: { cityName: 'MANTOLOKING', districtCode: '1520', countyName: 'OCEAN', zip: '08738' },
  MANCHESTER_08759: { cityName: 'MANCHESTER', districtCode: '1519', countyName: 'OCEAN', zip: '08759' },
  MANCHESTER_08733: {
    cityName: 'MANCHESTER',
    districtCode: '1519',
    countyName: 'OCEAN',
    recordType: 'MANCHESTER, NJ is NOT ACCEPTABLE - USE LAKEHURST',
    zip: '08733',
  },
  LAKEHURST_08733: {
    cityName: 'LAKEHURST',
    districtCode: '1519',
    countyName: 'OCEAN',
    zip: '08733',
  },
  EAGLESWOOD_08092: { cityName: 'EAGLESWOOD', districtCode: '1509', countyName: 'OCEAN', zip: '08092' },
  PT_PLEASANT_BEACH_08742: { cityName: 'PT PLEASANT BEACH', districtCode: '1526', countyName: 'OCEAN', zip: '08742' },
  TUCKERTON_08087: { cityName: 'TUCKERTON', districtCode: '1533', countyName: 'OCEAN', zip: '08087' },
  LONG_BEACH_08008: { cityName: 'LONG BEACH', districtCode: '1518', countyName: 'OCEAN', zip: '08008' },
  POINT_PLEASANT_08742: {
    cityName: 'POINT PLEASANT',
    districtCode: '1525',
    countyName: 'OCEAN',
    recordType: 'POINT PLEASANT, NJ is NOT ACCEPTABLE - USE POINT PLEASANT BEACH',
    zip: '08742',
  },
  LITTLE_EGG_HARBOR_08087: { cityName: 'LITTLE EGG HARBOR', districtCode: '1517', countyName: 'OCEAN', zip: '08087' },
  SEASIDE_HEIGHTS_08751: { cityName: 'SEASIDE HEIGHTS', districtCode: '1527', countyName: 'OCEAN', zip: '08751' },
  SEASIDE_PARK_08752: { cityName: 'SEASIDE PARK', districtCode: '1528', countyName: 'OCEAN', zip: '08752' },
  BRICK_TOWNSHIP_08724: { cityName: 'BRICK TOWNSHIP', districtCode: '1507', countyName: 'OCEAN', zip: '08724' },
  BRICK_TOWNSHIP_08723: { cityName: 'BRICK TOWNSHIP', districtCode: '1507', countyName: 'OCEAN', zip: '08723' },
  BRICK_08724: { cityName: 'BRICK', districtCode: '1507', countyName: 'OCEAN', zip: '08724' },
  BRICK_08723: { cityName: 'BRICK', districtCode: '1507', countyName: 'OCEAN', zip: '08723' },
  WATERTOWN_08758: { cityName: 'WATERTOWN', districtCode: '1521', countyName: 'OCEAN', zip: '08758' },
  DOVER_07802: { cityName: 'DOVER', districtCode: '1508', countyName: 'OCEAN', recordType: 'PO BOX', zip: '07802' },
  OCEAN_GATE_08740: {
    cityName: 'OCEAN GATE',
    districtCode: '1522',
    countyName: 'OCEAN',
    recordType: 'PO BOX',
    zip: '08740',
  },
  SHIP_BOTTOM_08008: { cityName: 'SHIP BOTTOM', districtCode: '1529', countyName: 'OCEAN', zip: '08008' },
  BERKELEY_08757: {
    cityName: 'BERKELEY',
    districtCode: '1506',
    countyName: 'OCEAN',
    recordType: 'BERKELEY, NJ is NOT ACCEPTABLE - USE TOMS RIVER',
    zip: '08757',
  },
  BERKELEY_08753: {
    cityName: 'BERKELEY',
    districtCode: '1506',
    countyName: 'OCEAN',
    recordType: 'BERKELEY, NJ is NOT ACCEPTABLE - USE TOMS RIVER',
    zip: '08753',
  },
  BEACH_HAVEN_08008: { cityName: 'BEACH HAVEN', districtCode: '1504', countyName: 'OCEAN', zip: '08008' },
  SURF_CITY_08008: { cityName: 'SURF CITY', districtCode: '1532', countyName: 'OCEAN', zip: '08008' },
  BEACHWOOD_08722: { cityName: 'BEACHWOOD', districtCode: '1505', countyName: 'OCEAN', zip: '08722' },
  BAY_HEAD_08742: { cityName: 'BAY HEAD', districtCode: '1503', countyName: 'OCEAN', zip: '08742' },
  LAVALLETTE_08735: { cityName: 'LAVALLETTE', districtCode: '1516', countyName: 'OCEAN', zip: '08735' },
  PINE_BEACH_08741: { cityName: 'PINE BEACH', districtCode: '1523', countyName: 'OCEAN', zip: '08741' },
  BARNEGAT_LIGHT_08006: {
    cityName: 'BARNEGAT LIGHT',
    districtCode: '1502',
    countyName: 'OCEAN',
    recordType: 'PO BOX',
    zip: '08006',
  },
  ISLAND_HEIGHTS_08732: {
    cityName: 'ISLAND HEIGHTS',
    districtCode: '1511',
    countyName: 'OCEAN',
    recordType: 'PO BOX',
    zip: '08732',
  },
  BARNEGAT_08005: { cityName: 'BARNEGAT', districtCode: '1501', countyName: 'OCEAN', zip: '08005' },
  LAKEWOOD_08701: { cityName: 'LAKEWOOD', districtCode: '1515', countyName: 'OCEAN', zip: '08701' },
  LAKEWOOD_TOWNSHIP_08701: { cityName: 'LAKEWOOD TOWNSHIP', districtCode: '1515', countyName: 'OCEAN', zip: '08701' },
  LAKEHURST_08759: { cityName: 'LAKEHURST', districtCode: '1514', countyName: 'OCEAN', zip: '08759' },
  SOUTH_TOMS_RIVER_08757: {
    cityName: 'SOUTH TOMS RIVER',
    districtCode: '1530',
    countyName: 'OCEAN',
    recordType: 'SOUTH TOMS RIVER, NJ is NOT ACCEPTABLE - USE TOMS RIVER',
    zip: '08757',
  },
  LACEY_08731: {
    cityName: 'LACEY',
    districtCode: '1513',
    countyName: 'OCEAN',
    recordType: 'LACEY, NJ is NOT ACCEPTABLE - USE FORKED RIVER',
    zip: '08731',
  },
  LACEY_TOWNSHIP_08731: {
    cityName: 'LACEY',
    districtCode: '1513',
    countyName: 'OCEAN',
    recordType: 'LACEY, NJ is NOT ACCEPTABLE - USE FORKED RIVER',
    zip: '08731',
  },
  FORKED_RIVER_08731: {
    cityName: 'FORKED RIVER',
    districtCode: '1513',
    countyName: 'OCEAN',
    zip: '08731',
  },
  PLUMSTED_08533: {
    cityName: 'PLUMSTED',
    districtCode: '1524',
    countyName: 'OCEAN',
    recordType: 'PLUMSTED, NJ is NOT ACCEPTABLE - USE NEW EGYPT',
    zip: '08533',
  },
  NEW_EGYPT_08533: {
    cityName: 'NEW EGYPT',
    districtCode: '1524',
    countyName: 'OCEAN',
    zip: '08533',
  },
  WAYNE_07474: { cityName: 'WAYNE', districtCode: '1614', countyName: 'PASSAIC', recordType: 'PO BOX', zip: '07474' },
  PATERSON_07533: {
    cityName: 'PATERSON',
    districtCode: '1608',
    countyName: 'PASSAIC',
    recordType: 'PO BOX',
    zip: '07533',
  },
  PATERSON_07538: {
    cityName: 'PATERSON',
    districtCode: '1608',
    countyName: 'PASSAIC',
    recordType: 'PO BOX',
    zip: '07538',
  },
  TOTOWA_07512: { cityName: 'TOTOWA', districtCode: '1612', countyName: 'PASSAIC', zip: '07512' },
  WAYNE_07470: { cityName: 'WAYNE', districtCode: '1614', countyName: 'PASSAIC', zip: '07470' },
  PATERSON_07543: {
    cityName: 'PATERSON',
    districtCode: '1608',
    countyName: 'PASSAIC',
    recordType: 'PO BOX',
    zip: '07543',
  },
  CLIFTON_07015: {
    cityName: 'CLIFTON',
    districtCode: '1602',
    countyName: 'PASSAIC',
    recordType: 'PO BOX',
    zip: '07015',
  },
  CLIFTON_07014: { cityName: 'CLIFTON', districtCode: '1602', countyName: 'PASSAIC', zip: '07014' },
  TOTOWA_07511: { cityName: 'TOTOWA', districtCode: '1612', countyName: 'PASSAIC', recordType: 'PO BOX', zip: '07511' },
  TOTOWA_07502: { cityName: 'TOTOWA', districtCode: '1612', countyName: 'PASSAIC', zip: '07502' },
  HAWTHORNE_07507: {
    cityName: 'HAWTHORNE',
    districtCode: '1604',
    countyName: 'PASSAIC',
    recordType: 'PO BOX',
    zip: '07507',
  },
  HAWTHORNE_07506: { cityName: 'HAWTHORNE', districtCode: '1604', countyName: 'PASSAIC', zip: '07506' },
  WANAQUE_07465: { cityName: 'WANAQUE', districtCode: '1613', countyName: 'PASSAIC', zip: '07465' },
  PATERSON_07502: { cityName: 'PATERSON', districtCode: '1608', countyName: 'PASSAIC', zip: '07502' },
  PATERSON_07501: { cityName: 'PATERSON', districtCode: '1608', countyName: 'PASSAIC', zip: '07501' },
  POMPTON_LAKES_07442: { cityName: 'POMPTON LAKES', districtCode: '1609', countyName: 'PASSAIC', zip: '07442' },
  LITTLE_FALLS_07424: { cityName: 'LITTLE FALLS', districtCode: '1605', countyName: 'PASSAIC', zip: '07424' },
  PASSAIC_07055: { cityName: 'PASSAIC', districtCode: '1607', countyName: 'PASSAIC', zip: '07055' },
  RINGWOOD_07456: { cityName: 'RINGWOOD', districtCode: '1611', countyName: 'PASSAIC', zip: '07456' },
  CLIFTON_07013: { cityName: 'CLIFTON', districtCode: '1602', countyName: 'PASSAIC', zip: '07013' },
  PATERSON_07544: {
    cityName: 'PATERSON',
    districtCode: '1608',
    countyName: 'PASSAIC',
    recordType: 'PO BOX',
    zip: '07544',
  },
  BLOOMINGDALE_07403: { cityName: 'BLOOMINGDALE', districtCode: '1601', countyName: 'PASSAIC', zip: '07403' },
  CLIFTON_07012: { cityName: 'CLIFTON', districtCode: '1602', countyName: 'PASSAIC', zip: '07012' },
  CLIFTON_07011: { cityName: 'CLIFTON', districtCode: '1602', countyName: 'PASSAIC', zip: '07011' },
  PATERSON_07522: { cityName: 'PATERSON', districtCode: '1608', countyName: 'PASSAIC', zip: '07522' },
  PATERSON_07514: { cityName: 'PATERSON', districtCode: '1608', countyName: 'PASSAIC', zip: '07514' },
  PATERSON_07513: { cityName: 'PATERSON', districtCode: '1608', countyName: 'PASSAIC', zip: '07513' },
  WEST_PATERSON_07424: { cityName: 'WEST PATERSON', districtCode: '1616', countyName: 'PASSAIC', zip: '07424' },
  PATERSON_07512: { cityName: 'PATERSON', districtCode: '1608', countyName: 'PASSAIC', zip: '07512' },
  WEST_MILFORD_07480: { cityName: 'WEST MILFORD', districtCode: '1615', countyName: 'PASSAIC', zip: '07480' },
  NORTH_HALEDON_07538: {
    cityName: 'NORTH HALEDON',
    districtCode: '1606',
    countyName: 'PASSAIC',
    recordType: 'NORTH HALEDON, NJ is NOT ACCEPTABLE - USE HALEDON',
    zip: '07538',
  },
  NORTH_HALEDON_07508: { cityName: 'NORTH HALEDON', districtCode: '1606', countyName: 'PASSAIC', zip: '07508' },
  PATERSON_07511: {
    cityName: 'PATERSON',
    districtCode: '1608',
    countyName: 'PASSAIC',
    recordType: 'PO BOX',
    zip: '07511',
  },
  PATERSON_07510: { cityName: 'PATERSON', districtCode: '1608', countyName: 'PASSAIC', zip: '07510' },
  PATERSON_07509: {
    cityName: 'PATERSON',
    districtCode: '1608',
    countyName: 'PASSAIC',
    recordType: 'PO BOX',
    zip: '07509',
  },
  PATERSON_07508: { cityName: 'PATERSON', districtCode: '1608', countyName: 'PASSAIC', zip: '07508' },
  PATERSON_07505: { cityName: 'PATERSON', districtCode: '1608', countyName: 'PASSAIC', zip: '07505' },
  PROSPECT_PARK_07508: { cityName: 'PROSPECT PARK', districtCode: '1610', countyName: 'PASSAIC', zip: '07508' },
  PROSPECT_PARK_07538: {
    cityName: 'PROSPECT PARK',
    districtCode: '1610',
    countyName: 'PASSAIC',
    recordType: 'PROSPECT PARK, NJ is NOT ACCEPTABLE - USE HALEDON',
    zip: '07538',
  },
  PATERSON_07524: { cityName: 'PATERSON', districtCode: '1608', countyName: 'PASSAIC', zip: '07524' },
  PATERSON_07504: { cityName: 'PATERSON', districtCode: '1608', countyName: 'PASSAIC', zip: '07504' },
  PATERSON_07503: { cityName: 'PATERSON', districtCode: '1608', countyName: 'PASSAIC', zip: '07503' },
  HALEDON_07508: { cityName: 'HALEDON', districtCode: '1603', countyName: 'PASSAIC', zip: '07508' },
  HALEDON_07538: {
    cityName: 'HALEDON',
    districtCode: '1603',
    countyName: 'PASSAIC',
    recordType: 'PO BOX',
    zip: '07538',
  },
  PILESGROVE_08098: { cityName: 'PILESGROVE', districtCode: '1710', countyName: 'SALEM', zip: '08098' },
  UPPER_PITTSGROVE_08344: { cityName: 'UPPER PITTSGROVE', districtCode: '1714', countyName: 'SALEM', zip: '08344' },
  WOODSTOWN_08098: { cityName: 'WOODSTOWN', districtCode: '1715', countyName: 'SALEM', zip: '08098' },
  PEDRICKTOWN_08067: { cityName: 'PEDRICKTOWN', districtCode: '1707', countyName: 'SALEM', zip: '08067' },
  ALLOWAY_08001: { cityName: 'ALLOWAY', districtCode: '1701', countyName: 'SALEM', recordType: 'PO BOX', zip: '08001' },
  SALEM_08079: { cityName: 'SALEM', districtCode: '1713', countyName: 'SALEM', zip: '08079' },
  ELSINBORO_08038: { cityName: 'ELSINBORO', districtCode: '1704', countyName: 'SALEM', zip: '08038' },
  ELMER_08318: { cityName: 'ELMER', districtCode: '1703', countyName: 'SALEM', zip: '08318' },
  AUBURN_08085: {
    cityName: 'AUBURN',
    districtCode: '1707',
    countyName: 'SALEM',
    recordType: 'AUBURN, NJ is NOT ACCEPTABLE - USE SWEDESBORO',
    zip: '08085',
  },
  PENNSVILLE_08070: { cityName: 'PENNSVILLE', districtCode: '1709', countyName: 'SALEM', zip: '08070' },
  PENNS_GROVE_08069: { cityName: 'PENNS GROVE', districtCode: '1708', countyName: 'SALEM', zip: '08069' },
  CARNEYS_POINT_08069: { cityName: 'CARNEYS POINT', districtCode: '1702', countyName: 'SALEM', zip: '08069' },
  PITTSGROVE_08318: { cityName: 'PITTSGROVE', districtCode: '1711', countyName: 'SALEM', zip: '08318' },
  ELSINBORO_08079: { cityName: 'ELSINBORO', districtCode: '1704', countyName: 'SALEM', zip: '08079' },
  MANNINGTON_08079: { cityName: 'MANNINGTON', districtCode: '1706', countyName: 'SALEM', zip: '08079' },
  QUINTON_08072: { cityName: 'QUINTON', districtCode: '1712', countyName: 'SALEM', recordType: 'PO BOX', zip: '08072' },
  LOWER_ALLOWAY_CREEK_08302: {
    cityName: 'LOWER ALLOWAY CREEK',
    districtCode: '1705',
    countyName: 'SALEM',
    zip: '08302',
  },
  LOWER_ALLOWAY_CREEK_08079: {
    cityName: 'LOWER ALLOWAY CREEK',
    districtCode: '1705',
    countyName: 'SALEM',
    zip: '08079',
  },
  LOWER_ALLOWAY_CREEK_08038: {
    cityName: 'LOWER ALLOWAY CREEK',
    districtCode: '1705',
    countyName: 'SALEM',
    zip: '08038',
  },
  UPPER_PITTSGROVE_08098: { cityName: 'UPPER PITTSGROVE', districtCode: '1714', countyName: 'SALEM', zip: '08098' },
  UPPER_PITTSGROVE_08318: { cityName: 'UPPER PITTSGROVE', districtCode: '1714', countyName: 'SALEM', zip: '08318' },
  UPPER_PITTSGROVE_08343: { cityName: 'UPPER PITTSGROVE', districtCode: '1714', countyName: 'SALEM', zip: '08343' },
  MANVILLE_08835: { cityName: 'MANVILLE', districtCode: '1811', countyName: 'SOMERSET', zip: '08835' },
  FRANKLIN_TOWNSHIP_08875: {
    cityName: 'FRANKLIN TOWNSHIP',
    districtCode: '1808',
    countyName: 'SOMERSET',
    zip: '08875',
  },
  FRANKLIN_TOWNSHIP_08873: {
    cityName: 'FRANKLIN TOWNSHIP',
    districtCode: '1808',
    countyName: 'SOMERSET',
    zip: '08873',
  },
  MILLSTONE_08510: {
    cityName: 'MILLSTONE',
    districtCode: '1812',
    countyName: 'SOMERSET',
    recordType: 'MILLSTONE, NJ is NOT ACCEPTABLE - USE MILLSTONE TOWNSHIP',
    zip: '08510',
  },
  MILLSTONE_TOWNSHIP_08510: {
    cityName: 'MILLSTONE',
    districtCode: '1812',
    countyName: 'SOMERSET',
    zip: '08510',
  },
  BEDMINSTER_07921: { cityName: 'BEDMINSTER', districtCode: '1801', countyName: 'SOMERSET', zip: '07921' },
  BERNARDSVILLE_07924: { cityName: 'BERNARDSVILLE', districtCode: '1803', countyName: 'SOMERSET', zip: '07924' },
  BERNARDS_07059: { cityName: 'BERNARDS', districtCode: '1802', countyName: 'SOMERSET', zip: '07059' },
  HILLSBOROUGH_08844: { cityName: 'HILLSBOROUGH', districtCode: '1810', countyName: 'SOMERSET', zip: '08844' },
  MONTGOMERY_08558: {
    cityName: 'MONTGOMERY',
    districtCode: '1813',
    countyName: 'SOMERSET',
    recordType: 'MONTGOMERY, NJ is NOT ACCEPTABLE - USE SKILLMAN',
    zip: '08558',
  },
  SKILLMAN_08558: {
    cityName: 'SKILLMNAN',
    districtCode: '1813',
    countyName: 'SOMERSET',
    zip: '08558',
  },
  BERNARDS_07920: { cityName: 'BERNARDS', districtCode: '1802', countyName: 'SOMERSET', zip: '07920' },
  BERNARDS_07921: { cityName: 'BERNARDS', districtCode: '1802', countyName: 'SOMERSET', zip: '07921' },
  ROCKY_HILL_08553: { cityName: 'ROCKY HILL', districtCode: '1817', countyName: 'SOMERSET', zip: '08553' },
  RARITAN_08869: { cityName: 'RARITAN', districtCode: '1816', countyName: 'SOMERSET', zip: '08869' },
  SOMERVILLE_08876: { cityName: 'SOMERVILLE', districtCode: '1818', countyName: 'SOMERSET', zip: '08876' },
  BRIDGEWATER_08807: { cityName: 'BRIDGEWATER', districtCode: '1806', countyName: 'SOMERSET', zip: '08807' },
  GREEN_BROOK_08812: { cityName: 'GREEN BROOK', districtCode: '1809', countyName: 'SOMERSET', zip: '08812' },
  BERNARDS_07924: { cityName: 'BERNARDS', districtCode: '1802', countyName: 'SOMERSET', zip: '07924' },
  WATCHUNG_07069: { cityName: 'WATCHUNG', districtCode: '1821', countyName: 'SOMERSET', zip: '07069' },
  SOUTH_BOUND_BROOK_08880: {
    cityName: 'SOUTH BOUND BROOK',
    districtCode: '1819',
    countyName: 'SOMERSET',
    zip: '08880',
  },
  BERNARDS_07931: { cityName: 'BERNARDS', districtCode: '1802', countyName: 'SOMERSET', zip: '07931' },
  BERNARDS_07939: { cityName: 'BERNARDS', districtCode: '1802', countyName: 'SOMERSET', zip: '07939' },
  NORTH_PLAINFIELD_07060: { cityName: 'NORTH PLAINFIELD', districtCode: '1814', countyName: 'SOMERSET', zip: '07060' },
  BRANCHBURG_08876: { cityName: 'BRANCHBURG', districtCode: '1805', countyName: 'SOMERSET', zip: '08876' },
  BRANCHBURG_08853: { cityName: 'BRANCHBURG', districtCode: '1805', countyName: 'SOMERSET', zip: '08853' },
  NORTH_PLAINFIELD_07062: { cityName: 'NORTH PLAINFIELD', districtCode: '1814', countyName: 'SOMERSET', zip: '07062' },
  BOUND_BROOK_08805: { cityName: 'BOUND BROOK', districtCode: '1804', countyName: 'SOMERSET', zip: '08805' },
  NORTH_PLAINFIELD_07063: { cityName: 'NORTH PLAINFIELD', districtCode: '1814', countyName: 'SOMERSET', zip: '07063' },
  PEAPACK_07977: {
    cityName: 'PEAPACK',
    districtCode: '1815',
    countyName: 'SOMERSET',
    recordType: 'PO BOX',
    zip: '07977',
  },
  MILLSTONE_08844: {
    cityName: 'MILLSTONE',
    districtCode: '1812',
    countyName: 'SOMERSET',
    recordType: 'MILLSTONE, NJ is NOT ACCEPTABLE - USE HILLSBOROUGH',
    zip: '08844',
  },
  MILLSTONE_08535: {
    cityName: 'MILLSTONE',
    districtCode: '1812',
    countyName: 'SOMERSET',
    recordType: 'MILLSTONE, NJ is NOT ACCEPTABLE - USE MILLSTONE TOWNSHIP',
    zip: '08535',
  },
  MILLSTONE_TOWNSHIP_08535: {
    cityName: 'MILLSTONE TOWNSHIP',
    districtCode: '1812',
    countyName: 'SOMERSET',
    zip: '08535',
  },
  FAR_HILLS_07931: { cityName: 'FAR HILLS', districtCode: '1807', countyName: 'SOMERSET', zip: '07931' },
  BERNARDS_07960: { cityName: 'BERNARDS', districtCode: '1802', countyName: 'SOMERSET', zip: '07960' },
  MONTGOMERY_08502: {
    cityName: 'MONTGOMERY',
    districtCode: '1813',
    countyName: 'SOMERSET',
    recordType: 'MONTGOMERY, NJ is NOT ACCEPTABLE - USE BELLE MEAD',
    zip: '08502',
  },
  BELLE_MEAD_08502: {
    cityName: 'BELLE MEAD',
    districtCode: '1813',
    countyName: 'SOMERSET',
    zip: '08502',
  },
  BERNARDS_08807: { cityName: 'BERNARDS', districtCode: '1802', countyName: 'SOMERSET', zip: '08807' },
  BERNARDS_08836: { cityName: 'BERNARDS', districtCode: '1802', countyName: 'SOMERSET', zip: '08836' },
  WARREN_07059: { cityName: 'WARREN', districtCode: '1820', countyName: 'SOMERSET', zip: '07059' },
  HAMPTON_07848: { cityName: 'HAMPTON', districtCode: '1910', countyName: 'SUSSEX', zip: '07848' },
  STILLWATER_07875: {
    cityName: 'STILLWATER',
    districtCode: '1920',
    countyName: 'SUSSEX',
    recordType: 'PO BOX',
    zip: '07875',
  },
  BRANCHVILLE_07890: {
    cityName: 'BRANCHVILLE',
    districtCode: '1903',
    countyName: 'SUSSEX',
    recordType: 'UNIQUE',
    zip: '07890',
  },
  BRANCHVILLE_07827: { cityName: 'BRANCHVILLE', districtCode: '1903', countyName: 'SUSSEX', zip: '07827' },
  BRANCHVILLE_07826: { cityName: 'BRANCHVILLE', districtCode: '1903', countyName: 'SUSSEX', zip: '07826' },
  HARDYSTON_07419: {
    cityName: 'HARDYSTON',
    districtCode: '1911',
    countyName: 'SUSSEX',
    recordType: 'HARDYSTON, NJ is NOT ACCEPTABLE - USE HAMBURG',
    zip: '07419',
  },
  HAMBURG_07419: {
    cityName: 'HAMBURG',
    districtCode: '1911',
    countyName: 'SUSSEX',
    zip: '07419',
  },
  NEWTON_07860: { cityName: 'NEWTON', districtCode: '1915', countyName: 'SUSSEX', zip: '07860' },
  FREDON_07860: { cityName: 'FREDON', districtCode: '1907', countyName: 'SUSSEX', zip: '07860' },
  LAFAYETTE_07848: { cityName: 'LAFAYETTE', districtCode: '1913', countyName: 'SUSSEX', zip: '07848' },
  FRANKLIN_07416: { cityName: 'FRANKLIN', districtCode: '1906', countyName: 'SUSSEX', zip: '07416' },
  HARDYSTON_07460: { cityName: 'HARDYSTON', districtCode: '1911', countyName: 'SUSSEX', zip: '07460' },
  SANDYSTON_07851: { cityName: 'SANDYSTON', districtCode: '1917', countyName: 'SUSSEX', zip: '07851' },
  BYRAM_TOWNSHIP_07821: { cityName: 'BYRAM TOWNSHIP', districtCode: '1904', countyName: 'SUSSEX', zip: '07821' },
  STANHOPE_07874: { cityName: 'STANHOPE', districtCode: '1919', countyName: 'SUSSEX', zip: '07874' },
  SPARTA_07871: { cityName: 'SPARTA', districtCode: '1918', countyName: 'SUSSEX', zip: '07871' },
  SANDYSTON_07827: { cityName: 'SANDYSTON', districtCode: '1917', countyName: 'SUSSEX', zip: '07827' },
  FRANKFORD_07860: { cityName: 'FRANKFORD', districtCode: '1905', countyName: 'SUSSEX', zip: '07860' },
  SANDYSTON_07826: { cityName: 'SANDYSTON', districtCode: '1917', countyName: 'SUSSEX', zip: '07826' },
  SUSSEX_07461: { cityName: 'SUSSEX', districtCode: '1921', countyName: 'SUSSEX', zip: '07461' },
  ANDOVER_07821: { cityName: 'ANDOVER', districtCode: '1901', countyName: 'SUSSEX', zip: '07821' },
  FRANKFORD_07848: { cityName: 'FRANKFORD', districtCode: '1905', countyName: 'SUSSEX', zip: '07848' },
  FRANKFORD_07826: { cityName: 'FRANKFORD', districtCode: '1905', countyName: 'SUSSEX', zip: '07826' },
  VERNON_07462: { cityName: 'VERNON', districtCode: '1922', countyName: 'SUSSEX', zip: '07462' },
  FRANKFORD_07822: { cityName: 'FRANKFORD', districtCode: '1905', countyName: 'SUSSEX', zip: '07822' },
  FRANKFORD_07461: { cityName: 'FRANKFORD', districtCode: '1905', countyName: 'SUSSEX', zip: '07461' },
  ANDOVER_07860: {
    cityName: 'ANDOVER',
    districtCode: '1902',
    countyName: 'SUSSEX',
    zip: '07860',
    note:
      "07860, as Andover Township does not have its own zip code, and uses Newton's zip code. 07821, which was shown, is actually Andover Borough.",
  },
  OGDENSBURG_07439: { cityName: 'OGDENSBURG', districtCode: '1916', countyName: 'SUSSEX', zip: '07439' },
  MONTAGUE_07827: { cityName: 'MONTAGUE', districtCode: '1914', countyName: 'SUSSEX', zip: '07827' },
  HAMPTON_07860: { cityName: 'HAMPTON', districtCode: '1910', countyName: 'SUSSEX', zip: '07860' },
  HOPATCONG_07843: { cityName: 'HOPATCONG', districtCode: '1912', countyName: 'SUSSEX', zip: '07843' },
  WANTAGE_07461: { cityName: 'WANTAGE', districtCode: '1924', countyName: 'SUSSEX', zip: '07461' },
  WALPACK_TOWNSHIP_07860: { cityName: 'WALPACK TOWNSHIP', districtCode: '1923', countyName: 'SUSSEX', zip: '07860' },
  WALPACK_TOWNSHIP_07851: { cityName: 'WALPACK TOWNSHIP', districtCode: '1923', countyName: 'SUSSEX', zip: '07851' },
  WALPACK_07881: { cityName: 'WALPACK', districtCode: '1923', countyName: 'SUSSEX', zip: '07881' },
  WALPACK_07851: { cityName: 'WALPACK', districtCode: '1923', countyName: 'SUSSEX', zip: '07851' },
  WALPACK_07860: { cityName: 'WALPACK', districtCode: '1923', countyName: 'SUSSEX', zip: '07860' },
  LINDEN_CITY_07036: { cityName: 'LINDEN CITY', districtCode: '2009', countyName: 'UNION', zip: '07036' },
  SPRINGFIELD_07081: { cityName: 'SPRINGFIELD', districtCode: '2017', countyName: 'UNION', zip: '07081' },
  LINDEN_CITY_07202: { cityName: 'LINDEN CITY', districtCode: '2009', countyName: 'UNION', zip: '07202' },
  LINDEN_07036: { cityName: 'LINDEN', districtCode: '2009', countyName: 'UNION', zip: '07036' },
  LINDEN_07202: { cityName: 'LINDEN', districtCode: '2009', countyName: 'UNION', zip: '07202' },
  NEW_PROVIDENCE_07974: { cityName: 'NEW PROVIDENCE', districtCode: '2011', countyName: 'UNION', zip: '07974' },
  UNION_07083: { cityName: 'UNION', districtCode: '2019', countyName: 'UNION', zip: '07083' },
  PLAINFIELD_07069: { cityName: 'PLAINFIELD', districtCode: '2012', countyName: 'UNION', zip: '07069' },
  HILLSIDE_07205: { cityName: 'HILLSIDE', districtCode: '2007', countyName: 'UNION', zip: '07205' },
  WESTFIELD_07090: { cityName: 'WESTFIELD', districtCode: '2020', countyName: 'UNION', zip: '07090' },
  WESTFIELD_07091: {
    cityName: 'WESTFIELD',
    districtCode: '2020',
    countyName: 'UNION',
    recordType: 'PO BOX',
    zip: '07091',
  },
  BERKELEY_HEIGHTS_07922: { cityName: 'BERKELEY HEIGHTS', districtCode: '2001', countyName: 'UNION', zip: '07922' },
  PLAINFIELD_07063: { cityName: 'PLAINFIELD', districtCode: '2012', countyName: 'UNION', zip: '07063' },
  PLAINFIELD_07062: { cityName: 'PLAINFIELD', districtCode: '2012', countyName: 'UNION', zip: '07062' },
  GARWOOD_07027: { cityName: 'GARWOOD', districtCode: '2006', countyName: 'UNION', zip: '07027' },
  PLAINFIELD_07061: {
    cityName: 'PLAINFIELD',
    districtCode: '2012',
    countyName: 'UNION',
    recordType: 'PO BOX',
    zip: '07061',
  },
  RAHWAY_07065: { cityName: 'RAHWAY', districtCode: '2013', countyName: 'UNION', zip: '07065' },
  PLAINFIELD_07060: { cityName: 'PLAINFIELD', districtCode: '2012', countyName: 'UNION', zip: '07060' },
  LINDEN_CITY_07065: { cityName: 'LINDEN CITY', districtCode: '2009', countyName: 'UNION', zip: '07065' },
  LINDEN_07065: { cityName: 'LINDEN', districtCode: '2009', countyName: 'UNION', zip: '07065' },
  FANWOOD_07023: { cityName: 'FANWOOD', districtCode: '2005', countyName: 'UNION', zip: '07023' },
  ELIZABETH_07206: { cityName: 'ELIZABETH', districtCode: '2004', countyName: 'UNION', zip: '07206' },
  ELIZABETH_07083: { cityName: 'ELIZABETH', districtCode: '2004', countyName: 'UNION', zip: '07083' },
  ELIZABETH_07202: { cityName: 'ELIZABETH', districtCode: '2004', countyName: 'UNION', zip: '07202' },
  ELIZABETH_07201: { cityName: 'ELIZABETH', districtCode: '2004', countyName: 'UNION', zip: '07201' },
  ELIZABETH_07203: { cityName: 'ELIZABETH', districtCode: '2004', countyName: 'UNION', zip: '07203' },
  ELIZABETH_07205: { cityName: 'ELIZABETH', districtCode: '2004', countyName: 'UNION', zip: '07205' },
  ELIZABETH_07208: { cityName: 'ELIZABETH', districtCode: '2004', countyName: 'UNION', zip: '07208' },
  CRANFORD_07016: { cityName: 'CRANFORD', districtCode: '2003', countyName: 'UNION', zip: '07016' },
  WINFIELD_07036: { cityName: 'WINFIELD', districtCode: '2021', countyName: 'UNION', zip: '07036' },
  WINFIELD_07066: { cityName: 'WINFIELD', districtCode: '2021', countyName: 'UNION', zip: '07066' },
  ROSELLE_07203: { cityName: 'ROSELLE', districtCode: '2014', countyName: 'UNION', zip: '07203' },
  ROSELLE_PARK_07204: { cityName: 'ROSELLE PARK', districtCode: '2015', countyName: 'UNION', zip: '07204' },
  SCOTCH_PLAINS_07076: { cityName: 'SCOTCH PLAINS', districtCode: '2016', countyName: 'UNION', zip: '07076' },
  MOUNTAINSIDE_07092: { cityName: 'MOUNTAINSIDE', districtCode: '2010', countyName: 'UNION', zip: '07092' },
  CLARK_07066: { cityName: 'CLARK', districtCode: '2002', countyName: 'UNION', zip: '07066' },
  SUMMIT_07902: { cityName: 'SUMMIT', districtCode: '2018', countyName: 'UNION', recordType: 'PO BOX', zip: '07902' },
  SUMMIT_07901: { cityName: 'SUMMIT', districtCode: '2018', countyName: 'UNION', zip: '07901' },
  KENILWORTH_07033: { cityName: 'KENILWORTH', districtCode: '2008', countyName: 'UNION', zip: '07033' },
  INDEPENDENCE_07874: { cityName: 'INDEPENDENCE', districtCode: '2112', countyName: 'WARREN', zip: '07874' },
  LIBERTY_07838: { cityName: 'LIBERTY', districtCode: '2114', countyName: 'WARREN', zip: '07838' },
  LIBERTY_07823: { cityName: 'LIBERTY', districtCode: '2114', countyName: 'WARREN', zip: '07823' },
  WASHINGTON_TOWNSHIP_07882: {
    cityName: 'WASHINGTON TOWNSHIP',
    districtCode: '2122',
    countyName: 'WARREN',
    zip: '07882',
  },
  INDEPENDENCE_07840: { cityName: 'INDEPENDENCE', districtCode: '2112', countyName: 'WARREN', zip: '07840' },
  FRELINGHUYSEN_07821: { cityName: 'FRELINGHUYSEN', districtCode: '2106', countyName: 'WARREN', zip: '07821' },
  HARMONY_08865: { cityName: 'HARMONY', districtCode: '2110', countyName: 'WARREN', zip: '08865' },
  POHATCONG_08865: { cityName: 'POHATCONG', districtCode: '2120', countyName: 'WARREN', zip: '08865' },
  HARMONY_07882: { cityName: 'HARMONY', districtCode: '2110', countyName: 'WARREN', zip: '07882' },
  INDEPENDENCE_07880: { cityName: 'INDEPENDENCE', districtCode: '2112', countyName: 'WARREN', zip: '07880' },
  POHATCONG_08804: { cityName: 'POHATCONG', districtCode: '2120', countyName: 'WARREN', zip: '08804' },
  OXFORD_07863: { cityName: 'OXFORD', districtCode: '2117', countyName: 'WARREN', zip: '07863' },
  GREENWICH_08804: { cityName: 'GREENWICH', districtCode: '2107', countyName: 'WARREN', zip: '08804' },
  PHILLIPSBURG_08865: { cityName: 'PHILLIPSBURG', districtCode: '2119', countyName: 'WARREN', zip: '08865' },
  HARDWICK_07825: { cityName: 'HARDWICK', districtCode: '2109', countyName: 'WARREN', zip: '07825' },
  GREENWICH_08865: { cityName: 'GREENWICH', districtCode: '2107', countyName: 'WARREN', zip: '08865' },
  WHITE_07823: { cityName: 'WHITE', districtCode: '2123', countyName: 'WARREN', zip: '07823' },
  WHITE_07832: { cityName: 'WHITE', districtCode: '2123', countyName: 'WARREN', zip: '07832' },
  WHITE_07863: { cityName: 'WHITE', districtCode: '2123', countyName: 'WARREN', zip: '07863' },
  WHITE_07882: { cityName: 'WHITE', districtCode: '2123', countyName: 'WARREN', zip: '07882' },
  WHITE_08865: { cityName: 'WHITE', districtCode: '2123', countyName: 'WARREN', zip: '08865' },
  WHITE_18343: { cityName: 'WHITE', districtCode: '2123', countyName: 'WARREN', zip: '18343' },
  FRELINGHUYSEN_07825: { cityName: 'FRELINGHUYSEN', districtCode: '2106', countyName: 'WARREN', zip: '07825' },
  GREENWICH_08886: { cityName: 'GREENWICH', districtCode: '2107', countyName: 'WARREN', zip: '08886' },
  HACKETTSTOWN_07840: { cityName: 'HACKETTSTOWN', districtCode: '2108', countyName: 'WARREN', zip: '07840' },
  INDEPENDENCE_07838: { cityName: 'INDEPENDENCE', districtCode: '2112', countyName: 'WARREN', zip: '07838' },
  FRELINGHUYSEN_07838: { cityName: 'FRELINGHUYSEN', districtCode: '2106', countyName: 'WARREN', zip: '07838' },
  POHATCONG_18042: { cityName: 'POHATCONG', districtCode: '2120', countyName: 'WARREN', zip: '18042' },
  PORT_MURRAY_07865: {
    cityName: 'PORT MURRAY',
    districtCode: '2116',
    countyName: 'WARREN',
    recordType: 'MANSFIELD, NJ is NOT ACCEPTABLE - USE COLUMBUS',
    zip: '07865',
  },
  COLUMBUS_07865: {
    cityName: 'COLUMBUS',
    districtCode: '2116',
    countyName: 'WARREN',
    zip: '07865',
  },
  BLAIRSTOWN_07825: { cityName: 'BLAIRSTOWN', districtCode: '2104', countyName: 'WARREN', zip: '07825' },
  FRELINGHUYSEN_07846: { cityName: 'FRELINGHUYSEN', districtCode: '2106', countyName: 'WARREN', zip: '07846' },
  ALPHA_08865: { cityName: 'ALPHA', districtCode: '2102', countyName: 'WARREN', zip: '08865' },
  FRELINGHUYSEN_07860: { cityName: 'FRELINGHUYSEN', districtCode: '2106', countyName: 'WARREN', zip: '07860' },
  POHATCONG_08886: { cityName: 'POHATCONG', districtCode: '2120', countyName: 'WARREN', zip: '08886' },
  KNOWLTON_18343: { cityName: 'KNOWLTON', districtCode: '2113', countyName: 'WARREN', zip: '18343' },
  ALLAMUCHY_07820: {
    cityName: 'ALLAMUCHY',
    districtCode: '2101',
    countyName: 'WARREN',
    recordType: 'PO BOX',
    zip: '07820',
  },
  KNOWLTON_07833: { cityName: 'KNOWLTON', districtCode: '2113', countyName: 'WARREN', zip: '07833' },
  KNOWLTON_07832: { cityName: 'KNOWLTON', districtCode: '2113', countyName: 'WARREN', zip: '07832' },
  KNOWLTON_07825: { cityName: 'KNOWLTON', districtCode: '2113', countyName: 'WARREN', zip: '07825' },
  LIBERTY_07863: { cityName: 'LIBERTY', districtCode: '2114', countyName: 'WARREN', zip: '07863' },
  LOPATCONG_08865: {
    cityName: 'LOPATCONG',
    districtCode: '2115',
    countyName: 'WARREN',
    recordType: 'LOPATCONG, NJ is NOT ACCEPTABLE - USE PHILLIPSBURG',
    zip: '08865',
  },
  WASHINGTON_BOROUGH_07882: {
    cityName: 'WASHINGTON BOROUGH',
    districtCode: '2121',
    countyName: 'WARREN',
    zip: '07882',
  },
  BELVIDERE_07823: { cityName: 'BELVIDERE', districtCode: '2103', countyName: 'WARREN', zip: '07823' },
  HOPE_07844: { cityName: 'HOPE', districtCode: '2111', countyName: 'WARREN', recordType: 'PO BOX', zip: '07844' },
}
