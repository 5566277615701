import { checkIfLatestResidentialRentCompVersion } from 'client-shared/utils/compPlex.gql'

export const checkIfResidentialRentCompsAreLatestVersion = async selectedComps => {
  const updates = selectedComps.map(async residentialRentComp => {
    const { leaseId, leaseVersionNumber } = residentialRentComp
    const { isLatestVersion, deletedAt } = leaseVersionNumber
      ? await checkIfLatestResidentialRentCompVersion({
          version: leaseVersionNumber,
          leaseId,
        })
      : { isLatestVersion: false }

    return {
      ...residentialRentComp,
      isLatestVersion,
      deletedAt,
    }
  })

  const updatedComps = await Promise.all(updates)
  return updatedComps
}
