import React from 'react'
import PropTypes from 'prop-types'

import SubjectPropertyCategory from './SubjectPropertyCategory'

class SubjectPropertyCategoryContainer extends React.PureComponent {
  constructor(props) {
    super(props)
    this.inputs = new Map()
  }

  state = {
    focusedInputIndex: null,
  }

  componentDidUpdate() {
    const { dragging } = this.props

    if (dragging) {
      this.blurCurrentInput()
    }
  }

  setInputRef = (index, ref) => {
    this.inputs.set(index, ref)
  }

  onAddProperty = () => {
    const { fields } = this.props
    fields.push('')
  }

  onInsertProperty = index => {
    const { fields } = this.props
    const nextElementIndex = fields.value ? fields.value.length : 1

    fields.push('')

    this.focusInput(nextElementIndex)
  }

  focusInput = index => {
    setTimeout(() => {
      const input = this.inputs.get(index)
      if (input) {
        input.focus()
      }
    }, 0)
  }

  blurCurrentInput = () => {
    const { focusedInputIndex } = this.state
    const input = this.inputs.get(focusedInputIndex)

    if (input) {
      input.blur()
    }

    this.setState({ focusedInputIndex: null })
  }

  onFocusInput = index => this.setState({ focusedInputIndex: index })

  render() {
    const { disabled, values, dragging } = this.props

    return (
      <SubjectPropertyCategory
        changeField={this.props.changeField}
        disabled={disabled}
        dragging={dragging}
        fields={this.props.fields}
        onAddProperty={this.onAddProperty}
        onFocusInput={this.onFocusInput}
        onInsertProperty={this.onInsertProperty}
        setInputRef={this.setInputRef}
        values={values}
      />
    )
  }
}

SubjectPropertyCategoryContainer.propTypes = {
  changeField: PropTypes.func.isRequired,
  disabled: PropTypes.bool.isRequired,
  dragging: PropTypes.bool.isRequired,
  singleValue: PropTypes.bool.isRequired,
  values: PropTypes.arrayOf(PropTypes.string.isRequired),
}

export default SubjectPropertyCategoryContainer
