import React from 'react'
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid } from '@mui/material'
import { get } from 'lodash'
import { DropDown } from 'client-shared/components/DropDown'
import { Text, Number as NumberField, CheckboxWithLabel } from 'client-shared/components'

import { SourceInformationKeys } from 'shared/constants/report'
import { BASIS_TYPES, BASIS_LABELS } from 'shared/constants/report/incomeApproach/taxes'

import GooglePlacesAutocomplete from 'client-shared/components/GooglePlacesAutocomplete'
import { hasSelectedGooglePlace } from 'client-shared/components/GooglePlacesAutocomplete/validation'

import { SOURCE_DROPDOWN_ITEMS } from '../../../../constants'

class NewTaxCompModal extends React.PureComponent {
  addNewTaxComp = () => {
    const { onAdd, form, onClose } = this.props

    const values = form.values
    const addressComponents = get(values, 'newTaxComp.address.addressInfo.address_components') || []
    const location = {}

    for (const part of addressComponents) {
      part.types.forEach(type => {
        location[type] = { short: part.short_name, long: part.long_name }
      })
    }
    const streetNumber = get(location, 'street_number.long')
    const streetName = get(location, 'route.long')
    // This is a temporary fix until WEB-9619 is implemented
    let city = get(location, 'locality.long') || get(location, 'sublocality.long')
    if (city === 'Queens') {
      city = get(location, 'neighborhood.long')
    }
    const state = get(location, 'administrative_area_level_1.long')
    const postalCode = get(location, 'postal_code.long')
    const address = `${streetNumber} ${streetName}`
    const yearBuilt = get(values, 'newTaxComp.yearBuilt')
    const isYearBuiltEstimate = get(values, 'newTaxComp.isYearBuiltEstimate')
    const basis = get(values, 'newTaxComp.basis')
    const taxesPerBasis = get(values, 'newTaxComp.taxesPerBasis')
    const sourceOfInformation = get(values, 'newTaxComp.sourceOfInformation')
    const sourceName = get(values, 'newTaxComp.sourceName')
    const sourceUrl = get(values, 'newTaxComp.sourceUrl')
    const taxYear = get(values, 'newTaxComp.taxYear')

    const newComp = {
      address,
      city,
      state,
      postalCode,
      basis,
      isYearBuiltEstimate,
      sourceName,
      sourceOfInformation,
      sourceUrl,
      taxYear,
      taxesPerBasis,
      yearBuilt,
    }
    onAdd([newComp])
    onClose()
  }

  validateTaxYear = taxYear => {
    return 1000 % taxYear > 999 && 10000 % taxYear < 10000
  }

  validateAddress = address => {
    return hasSelectedGooglePlace(address) !== 'Required'
  }

  componentDidMount() {
    const { form } = this.props
    form.change('newTaxComp', {})
  }

  render() {
    const {
      form: { values },
      onClose,
    } = this.props
    const { basis } = values
    const address = get(values, 'newTaxComp.address')
    const yearBuilt = get(values, 'newTaxComp.yearBuilt')
    const taxCompBasis = get(values, 'newTaxComp.basis')
    const taxesPerBasis = get(values, 'newTaxComp.taxesPerBasis')
    const sourceOfInformation = get(values, 'newTaxComp.sourceOfInformation')
    const taxYear = get(values, 'newTaxComp.taxYear')
    const disableAdd = !(
      this.validateAddress(address) &&
      yearBuilt &&
      taxCompBasis &&
      taxesPerBasis &&
      sourceOfInformation &&
      this.validateTaxYear(taxYear)
    )
    let basisLabel = BASIS_LABELS.SQUARE_FEET
    let taxesPerBasisLabel = BASIS_LABELS.TAXES_PSF
    if (basis === BASIS_TYPES.PER_UNIT) {
      basisLabel = BASIS_LABELS.UNITS
      taxesPerBasisLabel = BASIS_LABELS.TAXES_PER_UNIT
    }
    return (
      <Dialog
        aria-describedby="alert-dialog-description"
        aria-labelledby="alert-dialog-title"
        data-qa="new-tax-comp-modal"
        disableBackdropClick
        maxWidth="md"
        onClose={() => onClose()}
        open
      >
        <DialogTitle id="alert-dialog-title">ADD NEW TAX COMP</DialogTitle>
        <div data-qa="new-tax-com-modal-content">
          <DialogContent>
            <Grid item xs={12} alignItems="center" container spacing={1}>
              <Grid item height={68} xs={6}>
                <GooglePlacesAutocomplete
                  name="newTaxComp.address"
                  value={address}
                  variant="outlined"
                  label="Address"
                  required
                />
              </Grid>
              <Grid item xs={3}>
                <NumberField
                  name="newTaxComp.yearBuilt"
                  required
                  value={yearBuilt}
                  label="Year Built"
                  data-qa="yearBuilt"
                />
              </Grid>
              <Grid item xs={3}>
                <CheckboxWithLabel
                  data-qa="is-year-built-estimate"
                  label="This is an estimate."
                  name="newTaxComp.isYearBuiltEstimate"
                />
              </Grid>
              <Grid item xs={12} container alignItems="center" spacing={1}>
                <Grid item xs={6}>
                  <NumberField
                    name="newTaxComp.basis"
                    required
                    value={taxCompBasis}
                    label={basisLabel}
                    data-qa="basis"
                  />
                </Grid>
                <Grid item xs={6}>
                  <NumberField
                    data-qa="taxesPerBasis"
                    decimalScale={2}
                    label={taxesPerBasisLabel}
                    name="newTaxComp.taxesPerBasis"
                    prefix="$"
                    required
                    value={taxesPerBasis}
                  />
                </Grid>
              </Grid>
              <Grid item xs={12} container alignItems="center" spacing={1}>
                <Grid item xs={6}>
                  <DropDown
                    items={SOURCE_DROPDOWN_ITEMS}
                    label="Source of Information"
                    name="newTaxComp.sourceOfInformation"
                  />
                </Grid>
                <Grid item xs={6}>
                  <NumberField name="newTaxComp.taxYear" required value={taxYear} label="Tax Year" data-qa="taxYear" />
                </Grid>
              </Grid>

              {sourceOfInformation === SourceInformationKeys.other && (
                <Grid item xs={12} container alignItems="center" spacing={1}>
                  <Grid item xs={6}>
                    <Text name="newTaxComp.sourceName" label="Other" />
                  </Grid>
                </Grid>
              )}
              {sourceOfInformation === SourceInformationKeys.externalDatabase && (
                <Grid item xs={12} container alignItems="center" spacing={1}>
                  <Grid item xs={6}>
                    <Text name="newTaxComp.sourceName" variant="outlined" label="Source Name" />
                  </Grid>
                  <Grid item xs={6}>
                    <Text name="newTaxComp.sourceUrl" label="Source URL" />
                  </Grid>
                </Grid>
              )}
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button data-qa="add-btn" disabled={disableAdd} onClick={this.addNewTaxComp}>
              Add
            </Button>
            <Button onClick={() => onClose()} data-qa="close-btn">
              Cancel
            </Button>
          </DialogActions>
        </div>
      </Dialog>
    )
  }
}

export default NewTaxCompModal
