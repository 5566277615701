import { GeneratedText, gtIfElse, gtLocked, gtTemplate, gtConditional } from 'shared/utils/narrative/GeneratedText'

import { mapDTO, mapDataModel } from './mappings'

const generate = () => {
  const gt = new GeneratedText()

  const upwardCompsSentence = gtLocked('upwardCompsSentence')
  const downwardCompsSentence = gtLocked('downwardCompsSentence')

  gt.t`${gtIfElse(
    'hasMarketConditionsAdjustments',
    gtTemplate`The purpose of this adjustment is to account for changes in market 
      conditions.${gtConditional('hasUpwardComps', gtTemplate`${upwardCompsSentence}`)}${gtConditional(
      'hasDownwardComps',
      gtTemplate`${downwardCompsSentence}`
    )}`,
    gtTemplate`The purpose of this adjustment is to account for changes in market conditions. All comparables used in 
      this analysis sold at a similar time; therefore, no adjustments were required.`
  )}`

  return gt.getParts()
}

export default { generate, mapDTO, mapDataModel }
