import React, { ReactElement, SyntheticEvent, useCallback, useEffect, useMemo, useRef, useState } from 'react'

import { Button, Chip, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, Typography } from '@mui/material'
import { ClassNameMap, withStyles } from '@mui/styles'
import CloseIcon from '@mui/icons-material/Close'
import { get, isEqual, isNil } from 'lodash'
import { Field } from 'react-final-form'
// @ts-ignore
import ReactMouseTrap from 'react-mousetrap'
import { connect } from 'react-redux'
import { compose } from 'redux'
import { Block, Mark, Node, Value, Editor as SlateEditor } from 'slate'
import { Editor, EditorProps } from 'slate-react'

import Lists from '@convertkit/slate-lists'

import usePrevious from 'client-shared/hooks/usePrevious'
import useDebounce from 'client-shared/hooks/useDebounce'
import withExtendedFormApi from 'client-shared/hocs/withExtendedFormApi'
import { ExtendedFormApi } from 'client-shared/utils/form'
import { generatedTextToValue } from 'shared/utils/narrative/GeneratedText'

import { updateConditionalInlines } from 'shared/utils/narrative/updateNarrative'
import { isValueJSON } from 'shared/utils/narrative'

import { DrawerSections } from 'report/layout/drawer/constants'
import { contentReuseTemplate } from 'client-shared/types'

import { narrativeFormatters } from '../../utils/narrative'
import { Stack } from '../_mui5'

import { fetchChipOptions, getNarrativeByTemplateName } from '../../../core/api'

import { isModified, areNarrativesEqual } from './helpers'
import ConditionalInline from './plugins/ConditionalInline'
import Locked from './plugins/Locked'
import Paragraph from './plugins/Paragraph'
import UpdateInlineNodes from './plugins/UpdateInlineNodes'
import LockedSentence from './plugins/LockedSentence'
import Bold from './plugins/Bold'
import getChipPlugin from './plugins/Locked/ChipPlugin'
import { ChipOption } from './plugins/Locked/types'

import Toolbar from './Toolbar'
import { useContentLibrary } from './useContentLibrary'

import styles from './styles'

import { FORMATTING_VALUES, NON_PRINTABLE_CHARACTERS_REGEX, OPEN_CONTENT_LIBRARY_SHORTCUTS } from './constants'

const defaultPlugins = [Paragraph, UpdateInlineNodes, ConditionalInline, Locked, LockedSentence]

// This contains the various narrative component Mods that we call types
// create: create mode for generic subsection
// edit: Expanded edit to show rich text components top bar like adding Bold / italic and lists
// simple-edit: standard NC edit mode that is used across application
// gs-edit: the mode for editing subsections and almost same as create
const types = {
  simpleEdit: 'simple-edit',
  edit: 'edit',
  create: 'create',
  genericSubsectionEdit: 'gs-edit',
} as const

type Props = {
  classes: ClassNameMap<keyof ReturnType<typeof styles>>
  title?: string | ReactElement
  generatedText: (data?: any) => any
  data?: any
  form: ExtendedFormApi
  name: string
  regenerateOnChange?: string[]
  tooltipText?: string
  type?: (typeof types)[keyof typeof types]
  reportId?: string
  chipBank?: ChipOption[]
  qa?: string
  contentLibraryTemplateTitle?: string
  hideContentLibraryButtons?: boolean
  isContentLibraryOpen?: boolean
  bindShortcut: any
  unbindShortcut: any
}

const NarrativeComponent = ({
  classes,
  title = '',
  generatedText: generateText,
  data = {},
  form,
  name,
  regenerateOnChange,
  tooltipText,
  type = types.simpleEdit,
  reportId,
  chipBank,
  qa,
  contentLibraryTemplateTitle,
  hideContentLibraryButtons = false,
  isContentLibraryOpen = false,
  bindShortcut,
  unbindShortcut,
}: Props) => {
  const [shouldShowWarningDialog, setShouldShowWarningDialog] = useState(false)
  const [narrative, setNarrative] = useState(generatedTextToValue([]))
  const [initialNarrative, setInitialNarrative] = useState<Value | null>(null)
  const [canRevert, setCanRevert] = useState(false)
  const [isFocused, setIsFocused] = useState(false)
  const [plugins, setPlugins] = useState(defaultPlugins)
  const [chipOptionsAndValues, setChipOptionsAndValues] = useState({})
  const editorRef = useRef<Editor | null>(null)
  const buttonRef = useRef<HTMLButtonElement>(null)
  const updateButtonRef = useRef<HTMLButtonElement>(null)
  const saveTemplateButtonRef = useRef<HTMLButtonElement>(null)
  const editor = editorRef.current

  const previousData = usePrevious(data)

  const modified = useMemo(() => {
    return isModified(initialNarrative, narrative)
  }, [initialNarrative, narrative])

  const getChipValue = useCallback((chip: ChipOption) => chip.value, [])

  const updateForm = useCallback(
    (value: Value, modified: boolean) => {
      form.batch(() => {
        form.change(`${name}.narrative`, value)
        !isNil(modified) && form.change(`${name}.modified`, modified)
      })
    },
    [form, name]
  )
  const updateFormDebounced = useDebounce(updateForm, 500)

  const onChange = useCallback(
    ({ value }: { value: Value }) => {
      // @ts-ignore
      const modified = isModified(initialNarrative, value)
      updateFormDebounced(value, modified)
      setNarrative(value)
      setCanRevert(!modified)
    },
    [initialNarrative, updateFormDebounced]
  )

  const ref = useCallback(
    (editor: Editor) => {
      if (!editor) {
        return
      }
      editorRef.current = editor
      const formValues = form.values

      if (type === types.edit) {
        const listPlugins = Lists()

        setPlugins(plugins => [...plugins, Bold, listPlugins])
      }

      fetchChipOptions(reportId, chipBank)
        .then(async (chipOptions: ChipOption[]) => {
          const chipOptionsAndValues = chipOptions.reduce((chipOptionsAndValues, value) => {
            chipOptionsAndValues[value.dataPath] = getChipValue(value)
            return chipOptionsAndValues
          }, {} as Record<string, unknown>)
          const chipOptionsWithUpdatedValues = chipOptions.map(chipOption => {
            const value = get(data, chipOption.dataPath) ?? getChipValue(chipOption)

            return { ...chipOption, value }
          })

          const chipPlugin = getChipPlugin({
            chipOptions: chipOptionsWithUpdatedValues,
          })

          setChipOptionsAndValues(chipOptionsAndValues)
          setPlugins(plugins => [...plugins, chipPlugin])

          const dataToUpdate = { ...chipOptionsAndValues, ...data }

          let loadedTemplate: contentReuseTemplate | undefined = undefined
          const templateName = get(formValues, `${name}.templateName`, undefined)
          if (templateName) {
            try {
              loadedTemplate = await getNarrativeByTemplateName(templateName)
            } catch (error) {
              console.error(error)
            }
          }

          form.batch(() => {
            const modified = get(formValues, `${name}.modified`)
            if (isNil(modified)) {
              form.change(`${name}.modified`, false)
            }

            let initialNarrative
            if (type === types.genericSubsectionEdit) {
              initialNarrative = Value.create(generateText())
            } else {
              initialNarrative = loadedTemplate
                ? Value.fromJSON(loadedTemplate.data)
                : generatedTextToValue(generateText(data))
            }
            initialNarrative = editor
              .setValue(initialNarrative)
              .updateInlineNodes(dataToUpdate, narrativeFormatters).value

            let narrative = get(formValues, `${name}.narrative`)
            if (isNil(narrative)) {
              narrative = initialNarrative
            } else {
              narrative = editor
                .setEditorValueFromJS(narrative)
                .updateInlineNodes(dataToUpdate, narrativeFormatters).value
            }
            const narrativeValue = modified ? narrative : initialNarrative
            form.change(`${name}.narrative`, narrativeValue)
            setNarrative(narrativeValue)
            setInitialNarrative(initialNarrative)
          })
        })
        .catch(error => console.error('Error fetching chip options', error))
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  )

  const dataToUpdate = useMemo(() => ({ ...chipOptionsAndValues, ...data }), [chipOptionsAndValues, data])

  useEffect(() => {
    if (!editor) {
      return
    }

    const oldData = previousData
    const haveFormValuesChanged = !isEqual(oldData, data)

    if (haveFormValuesChanged) {
      const shouldRegenerate =
        !modified &&
        regenerateOnChange?.length &&
        regenerateOnChange.some(fieldName => get(data, `${fieldName}`) !== get(oldData, `${fieldName}`))
      let narrative
      let newInitialNarrative = initialNarrative

      if (shouldRegenerate) {
        narrative = generatedTextToValue(generateText(data))
        narrative = editor.setValue(narrative).updateInlineNodes(dataToUpdate, narrativeFormatters).value
        newInitialNarrative = narrative
      } else {
        narrative = editor.updateInlineNodes(dataToUpdate, narrativeFormatters).value
      }
      form.change(`${name}.narrative`, narrative)
      setNarrative(narrative)
      setInitialNarrative(newInitialNarrative)
    }
  }, [
    chipOptionsAndValues,
    contentLibraryTemplateTitle,
    data,
    dataToUpdate,
    editor,
    form,
    generateText,
    initialNarrative,
    modified,
    name,
    onChange,
    previousData,
    regenerateOnChange,
  ])

  const showWarningDialog = () => {
    setShouldShowWarningDialog(true)
  }

  const closeWarningDialog = () => {
    setShouldShowWarningDialog(false)
  }

  const revertToOriginal = () => {
    const dataToUpdate = { ...chipOptionsAndValues, ...data }
    const narrative = editor!.setValue(initialNarrative!).updateInlineNodes(dataToUpdate, narrativeFormatters).value

    form.batch(() => {
      form.change(`${name}.narrative`, narrative)
      form.change(`${name}.modified`, false)
    })
    setNarrative(narrative)
    setInitialNarrative(narrative)
    setCanRevert(true)
    closeWarningDialog()
  }

  const replaceNonPrintable = (event: SyntheticEvent, data: string) => {
    const hasNonPrintable = NON_PRINTABLE_CHARACTERS_REGEX.test(data)
    if (hasNonPrintable) {
      const replaced = data.replaceAll(NON_PRINTABLE_CHARACTERS_REGEX, '')
      return {
        shouldReplace: true,
        replacedText: replaced,
      }
    }
    return { shouldReplace: false, replacedText: '' }
  }

  const onPaste: EditorProps['onPaste'] = (event, editor, next) => {
    const clipboardData = event.clipboardData
    const pastedText = clipboardData.getData('Text')
    const { shouldReplace, replacedText } = replaceNonPrintable(event, pastedText)
    if (shouldReplace) {
      event.preventDefault()
      editor.insertText(replacedText)
      return editor.value
    }
    return next()
  }

  const onBeforeInput: EditorProps['onBeforeInput'] = (event, editor, next) => {
    // @ts-ignore
    const inputData = event.data
    const { shouldReplace, replacedText } = replaceNonPrintable(event, inputData)
    if (shouldReplace) {
      event.preventDefault()
      editor.insertText(replacedText)
      return editor.value
    }
    return next()
  }

  const formatter = (mark: string) => {
    if (!editor) {
      return
    }
    editor.toggleMark(mark)
    onChange({ value: editor.value })
  }

  const blocker = (blockType: string) => {
    const { unorderedList } = FORMATTING_VALUES

    if (!editor) {
      return
    }

    const isActive = !!editor.value.blocks.some(node => node?.type === blockType)

    if (blockType === unorderedList) {
      editor.toggleList({ type: blockType })
    } else {
      editor.setBlocks(isActive ? 'paragraph' : blockType)
    }

    onChange({ value: editor.value })
  }

  const setActiveToolbarButtons = (editor: Editor) => {
    const { bold, unorderedList } = FORMATTING_VALUES
    const toolbarButtons = {
      [bold]: false,
      [unorderedList]: false,
    }

    editor.value.activeMarks.toJS().forEach((mark: Mark) => {
      if (Object.keys(toolbarButtons).includes(mark.type)) {
        toolbarButtons[mark.type] = true
      }
    })

    editor.value.blocks.toJS().forEach((block: Block) => {
      if (block.type === 'list-item-child') {
        const listItem = getListItem(editor, block)
        const list = getList(editor, listItem)
        if (list?.type === unorderedList) {
          toolbarButtons[unorderedList] = true
        }
      } else if (Object.keys(toolbarButtons).includes(block.type)) {
        toolbarButtons[block.type] = true
      }
    })

    return toolbarButtons
  }

  const onEnter: EditorProps['onKeyDown'] = (event, editor, next) => {
    const { selection, startBlock } = editor.value
    event.preventDefault()
    if (selection.isExpanded) {
      editor.delete()
    }
    if (selection.start.offset === 0 && startBlock.getText() === '') {
      const listItem = getListItem(editor, startBlock)
      const list = getList(editor, listItem)
      const parentListItem = getListItem(editor, list)

      if (parentListItem) {
        editor.decreaseListItemDepth()
        return next()
      }

      return next()
    }

    const listItem = getListItem(editor, startBlock)

    if (listItem) {
      editor.splitDescendantsByKey(listItem.key, selection.start.key, selection.start.offset)
    }
  }

  const isListItem = (block: Node | null): block is Block =>
    !!block && block.object === 'block' && block.type === 'list-item'

  const getListItem = (editor: Editor, block: Block | null) => {
    if (!block) {
      return null
    }
    const possibleListItem = editor.value.document.getParent(block.key)

    return isListItem(possibleListItem) ? possibleListItem : null
  }

  const isList = (block: Node | null): block is Block =>
    !!block && block.object === 'block' && block.type === FORMATTING_VALUES.unorderedList

  const getList = (editor: Editor, block: Block | null) => {
    if (!block) {
      return null
    }
    const possibleList = editor.value.document.getParent(block.key)
    return isList(possibleList) ? possibleList : null
  }

  const onKeyDown: EditorProps['onKeyDown'] = (event, editor, next) => {
    const { bold, unorderedList } = FORMATTING_VALUES

    if (type === types.edit) {
      if (event.metaKey || event.ctrlKey) {
        switch (event.key) {
          case 'b':
            event.preventDefault()
            formatter(bold)
            return next()
          case 'l':
            event.preventDefault()
            blocker(unorderedList)
            return next()
          default:
            return next()
        }
      }
      if (editor.value.blocks.some(block => !!block && block.type === 'list-item-child')) {
        if (event.key === 'Enter') {
          onEnter(event, editor, next)
          return next()
        } else if (event.key === 'Tab' && event.shiftKey) {
          event.preventDefault()
          const listItem = getListItem(editor, editor.value.startBlock)
          const list = getList(editor, listItem)
          const parentListItem = getListItem(editor, list)

          if (parentListItem) {
            editor.decreaseListItemDepth()
          }

          return next()
        } else if (event.key === 'Tab') {
          event.preventDefault()
          editor.increaseListItemDepth()
          return next()
        }
      }
    }
  }

  const getGeneratedTextValue = useCallback(() => {
    const generatedText = generateText(data)
    const generatedTextValue = isValueJSON(generatedText)
      ? Value.fromJSON(generatedText)
      : generatedTextToValue(generatedText)
    const updatedGeneratedTextValue = updateConditionalInlines(generatedTextValue, dataToUpdate)

    const normalizedValue = new SlateEditor({ value: updatedGeneratedTextValue })
    return normalizedValue.value
  }, [data, dataToUpdate, generateText])

  const {
    updateIsDisabled,
    updateTemplate,
    onOpenCreateNarrativeTemplate,
    onOpenContentLibrary,
    onLoadContentLibrary,
    Modals,
  } = useContentLibrary({
    contentLibraryTemplateTitle,
    name,
    form,
    editor,
    chipData: dataToUpdate,
    getGeneratedTextValue,
    modified,
    onChange,
    setInitialNarrative,
  })

  const focusHandler = (eventPropertyToCheck: 'target' | 'relatedTarget') => (event: FocusEvent) => {
    const elementToCheck = event[eventPropertyToCheck]
    // @ts-ignore
    const editorIsFocused = elementToCheck === editor?.el

    const buttonIsFocused =
      elementToCheck !== null &&
      (elementToCheck === buttonRef.current ||
        elementToCheck === saveTemplateButtonRef.current ||
        elementToCheck === updateButtonRef.current)

    setIsFocused(editorIsFocused || buttonIsFocused)

    // focus in
    if (eventPropertyToCheck === 'target' && editorIsFocused) {
      form.focus(`${name}.narrative`)
      onLoadContentLibrary()
      bindShortcuts()
    }
    // focus out
    if (eventPropertyToCheck === 'relatedTarget') {
      form.blur(`${name}.narrative`)
      unbindShortcuts()
    }
  }

  const bindShortcuts = () => {
    bindShortcut(OPEN_CONTENT_LIBRARY_SHORTCUTS, openContentLibraryShortcut)
  }

  const unbindShortcuts = () => {
    unbindShortcut(OPEN_CONTENT_LIBRARY_SHORTCUTS)
  }

  const openContentLibraryShortcut = (event: KeyboardEvent) => {
    event.preventDefault()
    onOpenContentLibrary()
  }

  const focusInHandler = focusHandler('target')

  const focusOutHandler = focusHandler('relatedTarget')

  useEffect(() => {
    document.addEventListener('focusin', focusInHandler)
    document.addEventListener('focusout', focusOutHandler)

    return () => {
      document.removeEventListener('focusin', focusInHandler)
      document.removeEventListener('focusout', focusOutHandler)
    }
  }, [focusInHandler, focusOutHandler])

  const enableRevert = !canRevert && modified
  const onClose = closeWarningDialog
  const isNotCreateAndGsEditType = !(type === types.create || type === types.genericSubsectionEdit)

  return (
    <>
      {shouldShowWarningDialog && isNotCreateAndGsEditType && (
        <Dialog open={shouldShowWarningDialog}>
          <DialogTitle>
            <Typography variant="h6">Changes will be lost.</Typography>
            <IconButton
              aria-label="close"
              onClick={onClose}
              sx={{
                position: 'absolute',
                right: 12,
                top: 12,
              }}
            >
              <CloseIcon />
            </IconButton>
          </DialogTitle>
          <DialogContent>
            <Typography>
              {[
                'All custom changes made to the',
                typeof title === 'string' ? title : title?.props.title,
                'will be deleted. Are you sure you want to revert this text?',
              ].join(' ')}
            </Typography>
          </DialogContent>
          <DialogActions>
            <Button variant="outlined" onClick={closeWarningDialog}>
              Cancel
            </Button>
            <Button variant="contained" onClick={revertToOriginal}>
              Yes, revert
            </Button>
          </DialogActions>
        </Dialog>
      )}
      <Editor
        plugins={plugins}
        value={Value.create(narrative)}
        ref={ref}
        onChange={onChange}
        className={classes.textEditor}
        onKeyDown={onKeyDown}
        onBeforeInput={onBeforeInput}
        onPaste={onPaste}
        placeholder="Enter text here"
        showModified={modified}
        enableRevert={enableRevert}
        isFocused={isFocused}
        updateIsDisabled={updateIsDisabled}
        ContentLibraryModals={Modals}
        onOpenContentLibrary={onOpenContentLibrary}
        isContentLibraryOpen={isContentLibraryOpen}
        renderEditor={(props, editor, next) => {
          const children = next()
          const showModified = props.showModified

          const enableRevert = props.enableRevert

          const showContentLibraryButtons = isNotCreateAndGsEditType && !hideContentLibraryButtons

          const showRevertToOriginalButton = isNotCreateAndGsEditType && hideContentLibraryButtons

          return (
            <Stack data-qa={qa} spacing={2} marginBottom={2}>
              <Stack direction="column">
                <Stack direction="row" alignItems="center" gap={1} sx={{ minWidth: 0, height: 32 }}>
                  {typeof title === 'string' ? (
                    <Typography noWrap variant="subtitle1">
                      {title}
                    </Typography>
                  ) : (
                    title
                  )}
                  {/* @ts-expect-error: `ui` is a prop we've added but haven't added types for yet */}
                  {isNotCreateAndGsEditType && showModified && <Chip ui="indicator" label="Modified" />}
                  {showContentLibraryButtons && (
                    <Button variant="text" onClick={props.onOpenContentLibrary}>
                      {props.isContentLibraryOpen ? 'Close' : 'Open'} Content Library
                    </Button>
                  )}
                </Stack>
                {tooltipText && (
                  <Typography variant="caption" className={classes.toolTipInformation} data-qa="tooltipMessage">
                    {tooltipText}
                  </Typography>
                )}
              </Stack>
              <Field name={`${name}.narrative`} isEqual={areNarrativesEqual}>
                {() => (
                  <Stack direction="column" className={classes.textEditorWrapper} borderRadius="5px">
                    {type === types.edit && (
                      <Toolbar
                        formatter={formatter}
                        blocker={blocker}
                        activeToolbarButtons={setActiveToolbarButtons(editor)}
                      />
                    )}
                    {children}
                  </Stack>
                )}
              </Field>
              <Stack
                direction="row"
                justifyContent="space-between"
                alignItems="flex-start"
                marginTop={1}
                sx={{ display: props.isFocused ? 'flex' : 'none' }}
              >
                <Typography variant="caption" sx={{ color: 'text.secondary' }}>
                  Type <span className={classes.equalsChip}>=</span> to quick select report data.
                </Typography>
                <>
                  {showRevertToOriginalButton && (
                    <Button variant="text" disabled={!enableRevert} ref={buttonRef} onClick={showWarningDialog}>
                      Revert to Original
                    </Button>
                  )}
                  {showContentLibraryButtons && (
                    <Stack direction="row">
                      <Button
                        variant="text"
                        disabled={props.updateIsDisabled}
                        onClick={updateTemplate}
                        ref={updateButtonRef}
                      >
                        Save Block
                      </Button>
                      <Button variant="outlined" ref={saveTemplateButtonRef} onClick={onOpenCreateNarrativeTemplate}>
                        Create New Block
                      </Button>
                      <Button variant="text" disabled={!enableRevert} ref={buttonRef} onClick={showWarningDialog}>
                        Undo Changes
                      </Button>
                      {props.ContentLibraryModals}
                    </Stack>
                  )}
                </>
              </Stack>
            </Stack>
          )
        }}
      />
    </>
  )
}

export default compose(
  withExtendedFormApi,
  ReactMouseTrap,
  connect(state => {
    const reportId = get(state, 'report.reportData._id')
    const chipBank = get(state, 'report.reportData.chipBank')
    const hideContentLibraryButtons = !get(state, 'shared.location.form')
    const isContentLibraryOpen = get(state, 'shared.drawer.sectionName') === DrawerSections.CONTENT_LIBRARY

    return {
      reportId,
      chipBank,
      hideContentLibraryButtons,
      isContentLibraryOpen,
    }
  }),
  withStyles(styles)
)(NarrativeComponent)

declare module 'slate-react' {
  interface BasicEditorProps {
    showModified?: boolean
    enableRevert?: boolean
    isFocused?: boolean
    updateIsDisabled?: boolean
    ContentLibraryModals?: React.ReactNode
    isContentLibraryOpen?: boolean
    onOpenContentLibrary?: () => void
  }
}
