import React from 'react'

import { Paper } from '@mui/material'
import { get } from 'lodash'

import arrayMutators from 'final-form-arrays'

import wrapForm from 'report/forms/wrapForm'

import { REPORT_INFORMATION_PATH } from 'shared/constants/report/keysAndDataPaths'

import KeyInfoBlocks from './KeyInfoBlocks'
import KeyInfoCommon from './KeyInfoCommon'
import ProvidedDocumentUpload from './components/ProvidedDocumentUpload'

export const DATA_PATH = REPORT_INFORMATION_PATH

const HEADING = 'Key Info'

const MAX_PROVIDED_DOCS_V2_SIZE_MB = 10

class ReportInformation extends React.PureComponent {
  renderProvidedDocumentUpload = () => {
    const { authenticatedUser } = this.props

    return (
      <Paper data-qa="provided-documents-tile">
        <ProvidedDocumentUpload
          parentId={authenticatedUser.organizationId}
          maxUploadSizeMB={MAX_PROVIDED_DOCS_V2_SIZE_MB}
        />
      </Paper>
    )
  }

  render() {
    const { address, form, isBlocks, valueAsComplete, valueAsStabilized, currentPath } = this.props
    const { renderProvidedDocumentUpload } = this

    return !isBlocks ? (
      <KeyInfoCommon
        {...{
          address,
          form,
          renderProvidedDocumentUpload,
          valueAsComplete,
          valueAsStabilized,
          currentPath,
        }}
      />
    ) : (
      <KeyInfoBlocks {...{ renderProvidedDocumentUpload }} />
    )
  }
}

export default wrapForm(
  DATA_PATH,
  {
    heading: HEADING,
    mutators: {
      ...arrayMutators,
    },
  },
  state => {
    const valueAsStabilized = get(state, 'report.reportSettings.valueAsStabilized')
    const valueAsComplete = get(state, 'report.reportSettings.valueAsComplete')
    const isBlocks = get(state, 'report.reportSettings.isBlocks')

    const authenticatedUser = get(state, 'authentication.user')
    const address = get(state, 'report.reportData.address')

    const currentPath = get(state, 'shared.location.form.id')

    return {
      address,
      authenticatedUser,
      isBlocks,
      valueAsComplete,
      valueAsStabilized,
      currentPath,
    }
  }
)(ReportInformation)
