import React from 'react'
import { ReactSVG } from 'react-svg'
import PropTypes from 'prop-types'
import { Grid, Typography, Divider, Chip, withStyles } from '@material-ui/core'

import LocationPin from '../../../shared/images/asterisk-location-icon.svg'

const textColor = 'rgba(0, 0, 0, 0.87)'

const styles = theme => ({
  title: {
    color: textColor,
    lineHeight: 'normal',
    letterSpacing: 0.3,
  },
  icon: {
    width: 16,
    fill: theme.palette.primary.main,
  },
  addressInfo: {
    margin: '10px 0',
  },
  address: {
    color: textColor,
    fontSize: 14,
    letterSpacing: 0.3,
    paddingLeft: 4,
    verticalAlign: 'top',
  },
  iconWrapper: {
    display: 'inline-block',
  },
  chip: {
    height: 20,
    borderRadius: 4,
    marginLeft: 4,
  },
  chipLabel: {
    fontSize: 12,
    letterSpacing: 0.4,
  },
})

const StepHeader = ({ classes, title, address, details }) => (
  <React.Fragment>
    <Typography variant="h6" className={classes.title}>
      {title}
    </Typography>
    {address && (
      <Grid container justify="space-between" alignItems="center" className={classes.addressInfo}>
        <Grid item>
          <ReactSVG
            beforeInjection={svg => svg.classList.add(classes.icon)}
            className={classes.iconWrapper}
            src={LocationPin}
          />
          <span className={classes.address} data-qa="property-address">
            {address}
          </span>
        </Grid>
        <Grid item>
          {details.map((detail, index) => (
            <Chip
              key={index}
              label={detail}
              data-qa={detail}
              classes={{ root: classes.chip, label: classes.chipLabel }}
            />
          ))}
        </Grid>
      </Grid>
    )}

    <Divider />
  </React.Fragment>
)

StepHeader.propTypes = {
  classes: PropTypes.object.isRequired,
  title: PropTypes.string.isRequired,
  address: PropTypes.string.isRequired,
  details: PropTypes.array.isRequired,
}

export default withStyles(styles)(StepHeader)
