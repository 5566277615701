import React from 'react'
import PropTypes from 'prop-types'
import { Box } from '@mui/material'

import { findIndex, partition } from 'lodash'

import { ImageUploadListField as ImageUploadList } from 'client-shared/components/ImageList'
import { UneditableSection } from 'client-shared/components'

import { ANCHOR_PROPERTY_PHOTOS } from 'shared/constants/report/dataCollections'

const styles = {
  placeholder: {
    width: '198px',
    height: '198px',
    textAlign: 'center',
    lineHeight: '190px',
    border: '1px dashed black',
    borderRadius: '3px',
    fontFamily: 'roboto',
    fontSize: '14px',
  },
}

export const DefaultCategoriesList = ({
  categories,
  changeCategory,
  classes,
  name,
  uneditableCategories = [],
  currentPath,
  ...props
}) => {
  const onCategorySave = ({ oldCategory, newCategory }) => {
    const categoryIndex = findIndex(categories, ({ category }) => category.categoryTitle === oldCategory)
    changeCategory(`${name}.${categoryIndex}.categoryTitle`, newCategory)
  }

  const visibleCategories = categories.filter(category => category.isVisible)
  const [lockedCategories, unlockedCategories] = partition(visibleCategories, category =>
    uneditableCategories.includes(category.category.categoryName)
  )
  return (
    <>
      {lockedCategories.length > 0 && (
        <UneditableSection
          name="Found In: Subject Property Data"
          formName="Subject Property Data"
          link="subject-property"
          anchor={ANCHOR_PROPERTY_PHOTOS}
          returnDataPath={currentPath}
          editDataMessage
        >
          {lockedCategories.map(({ category, index }) => {
            return (
              <>
                <ImageUploadList
                  category={category}
                  deletable={false}
                  editable={false}
                  key={category.categoryName}
                  name={`${name}.${index}.photos`}
                  onCategorySave={onCategorySave}
                  readOnly
                  {...props}
                />
                {!category.photos.length && (
                  <>
                    <Box component="div" sx={styles.placeholder}>
                      No photo provided.
                    </Box>
                  </>
                )}
              </>
            )
          })}
        </UneditableSection>
      )}
      {unlockedCategories.map(({ category, index }) => (
        <ImageUploadList
          category={category}
          deletable={false}
          draggable
          editable
          key={category.categoryName}
          name={`${name}.${index}.photos`}
          onCategorySave={onCategorySave}
          {...props}
        />
      ))}
    </>
  )
}

DefaultCategoriesList.propTypes = {
  categories: PropTypes.array.isRequired,
  changeCategory: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
  uneditableCategories: PropTypes.array,
  currentPath: PropTypes.string,
}

export default DefaultCategoriesList
