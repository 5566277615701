import createDecorator from 'final-form-calculate'

import { isBothResponsibleForExpenses } from '../../../../../../../shared/utils/report/incomeApproach/expenses'

export const expenseStructureDecorator = createDecorator(
  {
    field: 'expenseStructure.fuel',
    updates: {
      isBothResponsibleForFuelExpenses: isBothResponsibleForExpenses,
    },
  },
  {
    field: 'expenseStructure.electricity',
    updates: {
      isBothResponsibleForElectricalExpenses: isBothResponsibleForExpenses,
    },
  },
  {
    field: 'expenseStructure.commonElectricity',
    updates: {
      isBothResponsibleForCommonElectricalExpenses: isBothResponsibleForExpenses,
    },
  },
  {
    field: 'expenseStructure.gas',
    updates: {
      isBothResponsibleForGasExpenses: isBothResponsibleForExpenses,
    },
  },
  {
    field: 'expenseStructure.refuseRemoval',
    updates: {
      isBothResponsibleForRefuseRemovalExpenses: isBothResponsibleForExpenses,
    },
  },
  {
    field: 'expenseStructure.waterAndSewer',
    updates: {
      isBothResponsibleForWaterAndSewerExpenses: isBothResponsibleForExpenses,
    },
  },
  {
    field: 'expenseStructure.commonAreaMaintenance',
    updates: {
      isBothResponsibleForCommonAreaMaintenanceExpenses: isBothResponsibleForExpenses,
    },
  }
)
