/* eslint-disable id-length */
export const COLOR_WHITE = '#FFFFFF'
export const COLOR_BLACK = '#000000'
export const COLOR_RED = '#F84F40'

const zoningColors = {
  'C1-1': '#A6CEE3',
  'C1-2': '#1F78B4',
  'C1-3': '#B2DF8A',
  'C1-4': '#33A02C',
  'C1-5': '#FB9A99',
  'C2-1': '#E31A1C',
  'C2-2': '#FDBF6F',
  'C2-3': '#FF7F00',
  'C2-4': '#CAB2D6',
  'C2-5': '#6A3D9A',
}

export const floodColors = {
  '0.2 PCT ANNUAL CHANCE': '#FF9900',
  A: '#F84F40',
  AE: '#850200',
  AO: '#33A02C',
  AH: '#00E6FF',
  'OPEN WATER': 'rgba(255, 0, 0, 0.0)',
  VE: '#E31A1C',
  X: COLOR_WHITE,
  D: '#F2E673',
}

export const overlayColors = {
  ...zoningColors,
  ...floodColors,
}

const getZoneLayers = () => {
  const styles = []
  for (const [key, color] of Object.entries(zoningColors)) {
    styles.push(`#layer[overlay="${key}"] { polygon-fill: "${color}"; }`)
  }
  return styles.join('\n')
}

const getFloodLayers = () => {
  const styles = []
  for (const [key, color] of Object.entries(floodColors)) {
    styles.push(`#s_fld_haz_ar[fld_zone="${key}"] { polygon-fill: ${color};}`)
  }

  // replace x style given that last element in the list has to be style differently
  styles.splice(
    styles.length - 1,
    1,
    `#s_fld_haz_ar[fld_zone="X"] { polygon-fill: ${COLOR_WHITE}; polygon-opacity: 0.2;}`
  )
  return styles.join('\n')
}

export const taxLayerLabel = `
  text-name: [lot];
  text-face-name: 'DejaVu Sans Book';
  text-size: 14;
  text-fill: ${COLOR_BLACK};
  text-label-position-tolerance: 0;
  text-halo-radius: 1;
  text-halo-fill: ${COLOR_WHITE};
  text-dy: -10;
  text-allow-overlap: true;
  text-placement: point;
  text-placement-type: dummy;
`

const taxDtmLayer = `
  line-color: ${COLOR_BLACK};
  line-width: 2;
  line-opacity: 0.7;
  `

const taxDtmLabel = `
  text-name: [lot_face_l];
  text-face-name: 'DejaVu Sans Book';
  text-size: 10;
  text-label-position-tolerance: 0;
  text-fill: ${COLOR_BLACK};
  text-halo-fill: ${COLOR_WHITE};
  text-halo-radius: 1;
  text-dy: -10;
  text-allow-overlap: true;
  text-placement: line;
  text-placement-type: dummy;
  `

const taxBorough = `
  polygon-fill: ${COLOR_WHITE};
  polygon-opacity: 0.7;
  line-color: ${COLOR_WHITE};
  line-width: 0.5;
  line-opacity: 1;
  `
const taxLayerOutline = `
  line-width: 0.5;
  line-color: ${COLOR_WHITE};
  line-opacity: 0;
  `

/* eslint-disable max-len */
export const mapStyles = {
  'nyc.borough': `#bkmappluto{  polygon-fill: #FF6600;  polygon-opacity: 0; line-width: 0.5;  line-color: ${COLOR_WHITE}; line-opacity: 1;}`,
  'ny.zone': `
        #nyzd_2017{
          polygon-fill: #FF6600;
          polygon-opacity: 0;
          line-color: ${COLOR_BLACK};
          line-width: 2;
          line-opacity: 1;
        }

        #nyzd_2017::labels {
          text-name: [zonedist];
          text-face-name: 'DejaVu Sans Book';
          text-size: 17;
          text-label-position-tolerance: 10;
          text-fill: ${COLOR_BLACK};
          text-halo-fill: ${COLOR_WHITE};
          text-halo-radius: 1;
          text-dy: -10;
          text-allow-overlap: true;
          text-placement: point;
          text-placement-type: simple;
        }`,
  'ny.co': `
      #layer {
        polygon-opacity: 0.3;
        line-color: #081B47;
        line-width: 0.5;
        line-opacity: 1;
      }
      ${getZoneLayers()}
  `,
  'national.flood': `
    #s_fld_haz_ar {
        polygon-opacity: 0.7;
        line-color: #FFF;
        line-width: 0.5;
        line-opacity: 1;
    }
    ${getFloodLayers()}
  `,
  dtm_01: `     #dtm_01{ ${taxDtmLayer}}#dtm_01::labels { ${taxDtmLabel} } #dtm_01::labels[lot_face_l=0] { text-opacity:0; }`,
  'nyc.mn.tax': ` #mnmappluto { ${taxBorough} }#mnmappluto::labels { ${taxLayerLabel}} `,
  dtm_02: `     #dtm_02{ ${taxDtmLayer}} #dtm_02::labels { ${taxDtmLabel}} #dtm_02::labels[lot_face_l=0] { text-opacity:0; }`,
  'nyc.bx.tax': ` #bxmappluto{ ${taxBorough} } #bxmappluto::labels { ${taxLayerLabel} }`,
  dtm_04: `     #dtm_04{ ${taxDtmLayer}} #dtm_04::labels { ${taxDtmLabel} } #dtm_04::labels[lot_face_l=0] { text-opacity:0; }`,
  'nyc.qn.tax': ` #qnmappluto{ ${taxBorough} } #qnmappluto::labels { ${taxLayerLabel} } `,
  dtm_03: `     #dtm_03{ ${taxDtmLayer}} #dtm_03::labels { ${taxDtmLabel} }`,
  'nyc.si.tax': ` #qnmappluto{ ${taxBorough} } #qnmappluto::labels { ${taxLayerLabel} } `,
  dtm_05: `     #dtm_05{ ${taxDtmLayer}} #dtm_05::labels { ${taxDtmLabel} }`,
  'nyc.bk.tax': ` #layer { polygon-fill: ${COLOR_WHITE}; polygon-opacity: 0.7;} #layer::outline { ${taxLayerOutline} } #layer::labels {${taxLayerLabel}}`,
}
