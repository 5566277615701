import React, { FC } from 'react'
import { Button, Dialog, DialogActions, DialogContent, Stack, Typography } from '@mui/material'

import DialogHeader from '../DialogHeader'

type Props = {
  opened?: boolean
  onSave: () => void
  onClose: () => void
}

export const UpdateNarrativeModal: FC<Props> = ({ opened = false, onSave, onClose }) => {
  return (
    <Dialog open={opened} onClose={onSave} fullWidth maxWidth="sm" data-qa="update-narrative-modal">
      <DialogHeader onClose={onClose}>Update Template</DialogHeader>

      <DialogContent>
        <Stack direction="column" spacing={1}>
          <Typography variant="body1">
            You will be replacing its language that is saved in the content library. Are you sure you want to update?
          </Typography>
        </Stack>
      </DialogContent>

      <DialogActions>
        <Button variant="outlined" onClick={onClose}>
          No, Cancel
        </Button>
        <Button variant="contained" color="primary" type="submit" onClick={onSave}>
          Yes, Update
        </Button>
      </DialogActions>
    </Dialog>
  )
}
