const hasSelectedGooglePlace = (value = {}) => {
  const { addressInfo, coords, googlePlace } = value
  const hasSelectedGooglePlace = (addressInfo && coords) || (googlePlace && coords)

  return hasSelectedGooglePlace ? undefined : 'Required'
}
const notEmptyValue = (value = {}) => {
  const { googlePlace } = value
  const isEmptyValue = !googlePlace

  return isEmptyValue ? 'Required' : undefined
}

export { hasSelectedGooglePlace, notEmptyValue }
