import React from 'react'
import PropTypes from 'prop-types'

import AddCircleOutline from '@mui/icons-material/AddCircleOutline'
import InfoOutlined from '@mui/icons-material/InfoOutlined'
import Cancel from '@mui/icons-material/Cancel'
import { Chip, IconButton, TableCell, Tooltip, TableRow, Typography } from '@mui/material'
import { get, head, includes, isEmpty, toLower } from 'lodash'

import Autosuggest from 'client-shared/components/Autosuggest'
import TextField from 'client-shared/components/Table/TableText'

import { DATA_SOURCES, NO_DATA_SOURCE_SUGGESTIONS_MESSAGE, UNRECOGNIZED_SOURCE_TOOLTIP } from './constants'

class SourceInfoTableRow extends React.PureComponent {
  state = {
    inInAddState: false,
    renderedSuggestions: [],
    newSource: '',
  }

  getSourcesSuggestions = () => {
    const { dataSource } = this.props
    const { sources } = dataSource

    return DATA_SOURCES.filter(dataSource => !includes(sources, dataSource))
  }

  onAddSourceClick = () => {
    this.setState({
      newSource: '',
      isInAddState: true,
    })
  }

  onAutosuggestInputChange = event => {
    const newSource = event.target.value
    this.setState(prevState => ({
      ...prevState,
      newSource,
    }))
  }

  onSuggestionSelected = (_event, { suggestion }) => {
    const { onAddSource, dataSourceIndex } = this.props
    onAddSource(dataSourceIndex, suggestion)
    this.setState({
      newSource: '',
      isInAddState: false,
    })
  }

  onCustomSuggestionCreate = event => {
    const value = event.target.value
    if (isEmpty(value.trim())) {
      this.setState({
        newSource: '',
        isInAddState: false,
      })
      return
    }

    const sources = get(this.props, 'dataSource.sources', [])

    const duplicatedSuggestion = sources.some(dataSource => {
      return toLower(dataSource) === toLower(value)
    })

    if (!duplicatedSuggestion) {
      this.onSuggestionSelected(event, { suggestion: value })
    }
  }

  modifyCurrentSearchSet = renderedSuggestions => {
    this.setState(prevState => ({
      ...prevState,
      renderedSuggestions,
    }))
  }

  onAutosuggestInputKeyDown = event => {
    if (event.key === 'Enter' || event.key === 'Tab') {
      const { renderedSuggestions, newSource } = this.state
      const value = event.target.value
      if (!isEmpty(renderedSuggestions)) {
        const firstSuggestion = head(renderedSuggestions)
        event.target.value = includes(toLower(firstSuggestion), toLower(newSource)) ? firstSuggestion : value
      }
      this.onCustomSuggestionCreate(event)
    }
  }

  getUnrecognizedTooltipMarkup = source => {
    const { dataSource } = this.props
    const { sources } = dataSource

    const unrecognizedSources = sources.filter(dataSource => !includes(DATA_SOURCES, dataSource))
    const isUnrecognizedSource = includes(unrecognizedSources, source)

    return isUnrecognizedSource ? (
      <Tooltip placement="top" title={`"${source}" ${UNRECOGNIZED_SOURCE_TOOLTIP}`}>
        <InfoOutlined />
      </Tooltip>
    ) : null
  }

  onRemoveSource = sourceIndex => {
    const { onRemoveSource, dataSourceIndex } = this.props
    onRemoveSource(dataSourceIndex, sourceIndex)
  }

  getSourceMarkup = (source, sourceIndex) => {
    return (
      <Chip
        data-qa="source-label"
        icon={this.getUnrecognizedTooltipMarkup(source)}
        key={sourceIndex}
        label={source}
        onDelete={() => this.onRemoveSource(sourceIndex)}
      />
    )
  }

  onRemove = () => {
    const { onRemove, dataSourceIndex } = this.props
    onRemove(dataSourceIndex)
  }

  render() {
    const { dataSource, dataSourceIndex, expenseDataSource } = this.props
    const { newSource } = this.state
    const { data, sources, isCustom } = dataSource

    return (
      <TableRow data-qa={`${data}-row`}>
        <TableCell sx={{ width: 1 / 4 }}>
          {!!isCustom ? (
            <TextField name={`selectedDataSources[${dataSourceIndex}].data`} />
          ) : (
            <Typography variant="body1">{data}</Typography>
          )}
        </TableCell>
        <TableCell
          data-qa="sources-cell"
          sx={{
            alignItems: 'center',
            display: 'flex',
            flexWrap: 'wrap',
            gap: 1,
            minHeight: '48px',
          }}
        >
          {expenseDataSource && expenseDataSource !== 'Expense Data Not Provided' && (
            <Chip data-qa="source-label" label={expenseDataSource} sx={{ my: 1 / 4 }} />
          )}
          {sources.map((source, index) => this.getSourceMarkup(source, index))}
          {this.state.isInAddState ? (
            <Autosuggest
              focusOnMount
              getSuggestionValue={this.getSuggestionValue}
              inputProps={{
                onKeyDown: this.onAutosuggestInputKeyDown,
                disableUnderline: true,
              }}
              modifyCurrentSearchSet={this.modifyCurrentSearchSet}
              noSuggestionsMessage={NO_DATA_SOURCE_SUGGESTIONS_MESSAGE}
              onChange={this.onAutosuggestInputChange}
              onSuggestionSelected={this.onSuggestionSelected}
              selectOnBlur={false}
              suggestions={this.getSourcesSuggestions()}
              value={newSource}
              onBlur={this.onCustomSuggestionCreate}
            />
          ) : (
            <IconButton
              aria-label="Add"
              data-qa="add-source-btn"
              onClick={this.onAddSourceClick}
              size="small"
              sx={{ py: 1 / 2 }}
            >
              <AddCircleOutline />
            </IconButton>
          )}
        </TableCell>
        <TableCell data-qa="remove-cell" padding="none">
          {isCustom && (
            <IconButton onClick={this.onRemove}>
              <Cancel color="error" />
            </IconButton>
          )}
        </TableCell>
      </TableRow>
    )
  }
}

SourceInfoTableRow.propTypes = {
  dataSource: PropTypes.shape({
    data: PropTypes.string,
    sources: PropTypes.arrayOf(PropTypes.string),
    isCustom: PropTypes.bool,
  }).isRequired,
  dataSourceIndex: PropTypes.number.isRequired,
  onAddSource: PropTypes.func.isRequired,
  onRemove: PropTypes.func.isRequired,
  onRemoveSource: PropTypes.func.isRequired,
}

export default SourceInfoTableRow
