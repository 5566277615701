import React from 'react'
import PropTypes from 'prop-types'

import { get } from 'lodash'
import { Button, Paper, Stack } from '@mui/material'

import SourceInfoTable from './SourceInfoTable'
import SourceInfoHeader from './SourceInfoHeader'
import SourceInfoGeneratedText from './SourceInfoGeneratedText'

class SourceInfoTableContainer extends React.PureComponent {
  updateSources = (index, newSources) => {
    const { fields } = this.props

    const dataSource = fields.value[index]
    fields.update(index, { ...dataSource, sources: newSources })
  }

  onRemoveSource = (rowIndex, sourceIndex) => {
    const { fields } = this.props
    const { sources = [] } = fields.value[rowIndex]

    const newSources = sources.filter((_source, index) => sourceIndex !== index)

    this.updateSources(rowIndex, newSources)
  }

  onAddSource = (rowIndex, source) => {
    const { fields } = this.props
    const { sources = [] } = fields.value[rowIndex]

    const newSources = [...sources, source]

    this.updateSources(rowIndex, newSources)
  }

  onAddData = () => {
    const { fields } = this.props
    fields.push({ data: '', sources: [], isCustom: true })
  }

  onRemoveData = index => {
    const { fields } = this.props
    fields.remove(index)
  }

  render() {
    const {
      fields,
      resetDataSources,
      form,
      locationIdentifier,
      appraisers,
      inspectionDate,
      selectedDataSources,
      reportTemplate,
      ...props
    } = this.props
    const dataSources = selectedDataSources
    const expenseDataSource = get(form, 'values.expenseDataSource')
    return (
      <Paper data-qa="source-info-section-tile" sx={{ maxWidth: 900 }}>
        <Stack spacing={3}>
          <SourceInfoHeader resetDataSources={resetDataSources} />
          <SourceInfoTable
            {...props}
            dataSources={dataSources}
            expenseDataSource={expenseDataSource}
            onAddSource={this.onAddSource}
            onRemove={this.onRemoveData}
            onRemoveSource={this.onRemoveSource}
          />
          <Button data-qa="add-btn" onClick={this.onAddData} sx={{ alignSelf: 'end' }} variant="contained">
            ADD
          </Button>
          <SourceInfoGeneratedText
            appraisers={appraisers}
            inspectionDate={inspectionDate}
            locationIdentifier={locationIdentifier}
            reportTemplate={reportTemplate}
          />
        </Stack>
      </Paper>
    )
  }
}

SourceInfoTableContainer.propTypes = {
  resetDataSources: PropTypes.func.isRequired,
  selectedDataSources: PropTypes.arrayOf(
    PropTypes.shape({
      data: PropTypes.string.isRequired,
      id: PropTypes.string,
      isCustom: PropTypes.bool,
      sources: PropTypes.arrayOf(PropTypes.string),
    })
  ).isRequired,
}

export default SourceInfoTableContainer
