import React from 'react'

import { get } from 'lodash'
import PropTypes from 'prop-types'
import { Stack } from '@mui/material'
import ShowSection from 'client-shared/components/ShowSection'
import { CalloutButton } from 'client-shared/components'
import { getUsesInOrder } from 'shared/helpers/commercialUses'
import { showSection, SECTIONS } from 'shared/helpers/propertyType'
import CommercialCalculations from 'shared/report-calculations/income-approach/commercial/commercial-calculations'
import IncomeApproachCalculations from 'shared/report-calculations/income-approach/income-approach-calculations'
import { POTENTIAL_GROSS_INCOME_PATH } from 'shared/constants/report/keysAndDataPaths'
import { OCCUPIED_UNIT_TYPES } from 'shared/constants/report/incomeApproach/residential'
import { divide } from 'shared/utils/numberOperations'

import wrapForm from '../../wrapForm'
import { CoStarRates } from '../../../constants'

import { Labels } from './PotentialGrossIncomeConstants'
import ResidentialVacancyLoss from './ResidentialVacancyLoss'
import CommercialVacancyLoss from './CommercialVacancyLoss'
import SummaryTable from './SummaryTable'
import { commercialLossCalculator, residentialLossCalculator } from './decorators'

export const DATA_PATH = POTENTIAL_GROSS_INCOME_PATH

class PotentialGrossIncome extends React.PureComponent {
  static propTypes = {
    address: PropTypes.string,
    submarket: PropTypes.string,
    market: PropTypes.string,
    state: PropTypes.string,
    dateOfValue: PropTypes.string,
    reportId: PropTypes.string,
    form: PropTypes.object,
    hasCommercial: PropTypes.bool,
    residentialUnits: PropTypes.array,
    uniqueUses: PropTypes.array,
    valueAsStabilized: PropTypes.bool,
    occupancyRate: PropTypes.number,
  }

  render() {
    const {
      address,
      submarket,
      market,
      occupancyRate,
      state,
      dateOfValue,
      reportId,
      form,
      hasCommercial,
      residentialUnits,
      uniqueUses,
      valueAsStabilized,
    } = this.props

    return (
      <Stack spacing={1} sx={{ maxWidth: 900 }}>
        <CalloutButton
          link="https://docs.google.com/spreadsheets/d/1K4bGrSPj-E3q_0SMctIrw8PlMbJ_3BxGYklZ5F39p5Y/edit?usp=sharing"
          linkText="Visit"
          target="_blank"
          text="Check CoStar's metro and submarket data before continuing."
          variant="link"
        />
        <ShowSection section={SECTIONS.RESIDENTIAL_VACANCY_LOSS}>
          <ResidentialVacancyLoss
            address={address}
            residentialUnits={residentialUnits}
            valueAsStabilized={valueAsStabilized}
            state={state}
            market={market}
            submarket={submarket}
            dateOfValue={dateOfValue}
            occupancyRate={occupancyRate}
            reportId={reportId}
            form={form}
          />
        </ShowSection>
        <ShowSection section={SECTIONS.COMMERCIAL_VACANCY_LOSS}>
          <CommercialVacancyLoss form={form} uniqueUses={uniqueUses} />
        </ShowSection>
        <SummaryTable form={form} hasCommercial={hasCommercial} uniqueUses={uniqueUses} />
      </Stack>
    )
  }
}

const formOptions = {
  decorators: [...commercialLossCalculator, ...residentialLossCalculator],
  heading: Labels.POTENTIAL_GROSS_INCOME,
  keepDirtyOnReinitialize: true,
  registeredFields: ['commercialVCLossAmount', 'commercialVCLossAmountByType'],
}

export default wrapForm(
  DATA_PATH,
  formOptions,
  state => {
    const reportId = get(state, 'report.reportData')
    const potentialGrossIncomeInfo = get(state, 'report.reportData.incomeApproach.potentialGrossIncome', {})
    const commercialUnits = get(
      state,
      'report.reportData.incomeApproach.commercialIncome.commercialProjectedRentRoll.units',
      []
    )

    const { potentialCommercialIncomeByType, commercialVCLossPercentageByType } = potentialGrossIncomeInfo

    const includedUses = get(potentialGrossIncomeInfo, 'includedUses', {})
    const uniqueUses = getUsesInOrder(includedUses)
    const propertyType = get(state, 'report.reportData.propertyType')
    const hasCommercial = showSection(SECTIONS.HAS_COMMERCIAL, propertyType)
    const address = get(state, 'report.reportData.address')

    const commercialVCLossAmountByType = CommercialCalculations.calculateCommercialVCLossAmountsByType(
      commercialUnits,
      uniqueUses,
      commercialVCLossPercentageByType
    )

    const commercialVCLossAmount = CommercialCalculations.caculateTotalCommercialVCLoss(
      commercialUnits,
      [],
      commercialVCLossPercentageByType
    )
    const potentialCommercialIncome = get(potentialGrossIncomeInfo, 'potentialCommercialIncome')
    const commercialVCLossPercentageAggregate = CommercialCalculations.calculateVCLossPercentageAggregate(
      commercialVCLossAmount,
      potentialCommercialIncome
    )

    const valueAsStabilized = get(state, 'report.reportSettings.valueAsStabilized')
    const residentialUnits =
      get(state, 'report.reportData.incomeApproach.residentialIncome.residentialProjectedRentRoll.units') || []

    const occupiedUnitsCount = residentialUnits.filter(unit => OCCUPIED_UNIT_TYPES.includes(unit.leaseStatus)).length
    const occupancyRate = divide(occupiedUnitsCount, residentialUnits.length)

    const residentialVCLossPercentage = get(potentialGrossIncomeInfo, 'residentialVCLossPercentage', 0)
    const potentialResidentialIncome = get(potentialGrossIncomeInfo, 'potentialResidentialIncome', 0)
    const residentialVCLossAmount = IncomeApproachCalculations.calculateVCLossAmount(
      potentialResidentialIncome,
      residentialVCLossPercentage
    )

    const submarket = get(state, 'report.reportData.propertyInformation.propertyMarket.submarket')
    const market = get(state, 'report.reportData.propertyInformation.propertyMarket.macroMarket')
    const dateOfValue = get(state, 'report.reportData.report.reportInformation.dateOfValuation')
    const addressState = get(state, 'report.reportData.propertyInformation.propertySummary.state')

    return {
      address,
      hasCommercial,
      propertyType,
      residentialUnits,
      uniqueUses,
      valueAsStabilized,
      market,
      submarket,
      occupancyRate,
      dateOfValue,
      state: addressState,
      reportId,
      initialValues: {
        ...potentialGrossIncomeInfo,
        address,
        commercialVCLossAmount,
        commercialVCLossAmountByType,
        commercialVCLossPercentageAggregate,
        costarMetroRate: potentialGrossIncomeInfo.costarMetroRate || CoStarRates.METRO,
        costarSubmarketRate: potentialGrossIncomeInfo.costarSubmarketRate || CoStarRates.SUBMARKET,
        potentialCommercialIncomeByType,
        residentialVCLossAmount,
      },
    }
  },
  {}
)(PotentialGrossIncome)
