import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import { compose, pure } from 'recompose'
import { withStyles } from '@mui/styles'

const styles = () => ({
  root: {
    paddingBottom: 24,
    wordBreak: 'break-word',
  },
  heading: {
    fontSize: 12,
    fontWeight: 600,
    lineHeight: 1.33,
    letterSpacing: 2,
    color: '#212121',
    paddingBottom: 17,
  },
  disabled: {
    opacity: 0.54,
  },
})

const PreviewSection = ({ classes, name, disabled, children }) => {
  return (
    <div className={classnames(classes.root, { [classes.disabled]: disabled })}>
      {name && <div className={classes.heading}>{name}</div>}
      {children}
    </div>
  )
}

PreviewSection.propTypes = {
  name: PropTypes.string,
  disabled: PropTypes.bool,
  children: PropTypes.node,
}

PreviewSection.defaultProps = {
  name: null,
  disabled: false,
  children: null,
}

export default compose(pure, withStyles(styles))(PreviewSection)
