import React from 'react'
import PropTypes from 'prop-types'

import { Accordion, AccordionDetails, AccordionSummary, Box, Stack } from '@mui/material'
import ExpandMore from '@mui/icons-material/ExpandMore'
import { get } from 'lodash'

import { GeneratedComment, Number as NumberField } from 'client-shared/components'
import { fromPercents, toPercents } from 'client-shared/utils/numberOperations'
import { generateBandOfInvestmentDiscussion } from 'shared/utils/textGeneration/incomeApproach/supportingCapRates'

const MortgageComponent = () => (
  <Stack alignItems="center" direction="row" spacing={1}>
    <NumberField
      adornment="%"
      allowNegative={false}
      decimalScale={2}
      disabled
      format={toPercents}
      label="Loan To Value Ratio"
      name="loanValueRatio"
      normalize={fromPercents}
    />
    <Box>×</Box>
    <NumberField allowNegative={false} decimalScale={4} disabled label="Mortgage Constant" name="mortgageConstant" />
    <Box>=</Box>
    <NumberField
      adornment="%"
      allowNegative={false}
      decimalScale={2}
      disabled
      format={toPercents}
      label="Mortgage Component"
      name="mortgageFunds"
      normalize={fromPercents}
    />
  </Stack>
)

const InvestmentCapRateComponent = () => (
  <Stack alignItems="center" direction="row" spacing={1}>
    <NumberField
      adornment="%"
      allowNegative={false}
      decimalScale={2}
      format={toPercents}
      label="Equity Dividend Rate"
      name="equityDividendRate"
      normalize={fromPercents}
    />
    <Box>x</Box>
    <NumberField
      adornment="%"
      allowNegative={false}
      decimalScale={2}
      disabled
      format={toPercents}
      label="Equity Ratio"
      name="equityRatio"
      normalize={fromPercents}
    />
    <Box>=</Box>
    <NumberField
      adornment="%"
      allowNegative={false}
      decimalScale={2}
      disabled
      format={toPercents}
      label="Equity Component"
      name="equityComponent"
      normalize={fromPercents}
    />
  </Stack>
)

const BandOfInvestments = ({ form }) => {
  const [expanded, setExpanded] = React.useState(false)

  const handleClick = React.useCallback(() => setExpanded(state => !state), [])

  const disabled = !get(form, 'values.bandOfInvestment.includeInExport')

  const getBandOfInvestmentDiscussion = React.useCallback(() => {
    if (disabled) {
      return ''
    }
    const equityDividendRate = get(form, 'values.equityDividendRate')
    const includeInExport = get(form, 'values.bandOfInvestment.includeInExport', false)
    return generateBandOfInvestmentDiscussion(includeInExport, equityDividendRate)
  }, [form, disabled])

  return (
    <Accordion
      data-qa="band-of-investment-section"
      disabled={disabled}
      expanded={expanded && !disabled}
      onChange={handleClick}
    >
      <AccordionSummary data-qa="selected-loan-terms-section-title" expandIcon={<ExpandMore />}>
        Band of Investment
      </AccordionSummary>
      <AccordionDetails>
        <Stack spacing={2}>
          <MortgageComponent />
          <InvestmentCapRateComponent />
          <NumberField
            adornment="%"
            allowNegative={false}
            decimalScale={2}
            disabled
            format={toPercents}
            label="Band of Investment Indicated Overall Rate"
            name="bandOfInvestmentCapRate"
            normalize={fromPercents}
          />
          <GeneratedComment
            dataPath="bandOfInvestmentDiscussion"
            getGeneratedText={getBandOfInvestmentDiscussion}
            isDynamicContent
            label="Band of Investment Discussion"
            title="Generated Commentary"
            tooltipText="The following text and generated commentary will appear in the Income Approach of your report."
          />
        </Stack>
      </AccordionDetails>
    </Accordion>
  )
}

BandOfInvestments.propTypes = {
  form: PropTypes.object.isRequired,
}

export default BandOfInvestments
