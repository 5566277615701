import { handleActions } from 'redux-actions'

import { clientsFetchRequest, clientsFetchReceive, clientsFetchFail } from '../actions/clients'

const initialState = {
  clients: [],
  isLoading: false,
  isLoaded: false,
}

export const reducer = handleActions(
  {
    [clientsFetchRequest](state) {
      return {
        ...state,
        isLoading: true,
      }
    },
    [clientsFetchReceive](state, { payload }) {
      return {
        ...state,
        clients: payload,
        isLoading: false,
        isLoaded: true,
      }
    },
    [clientsFetchFail](state) {
      return {
        ...state,
        isLoading: false,
      }
    },
  },
  initialState
)
