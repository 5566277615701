import { GeneratedText, gtLocked } from '../../../narrative/GeneratedText'

import { mapDTO, mapDataModel } from './mappings'

const generate = () => {
  const gt = new GeneratedText()
  const zoningPhraseGt = gtLocked('zoningPhrase')

  gt.t`${zoningPhraseGt}`
  gt.t` Below is a summary of the subject property's compliance with regard to use and bulk regulations.`

  return gt.getParts()
}

export default { generate, mapDTO, mapDataModel }
