import React from 'react'
import PropTypes from 'prop-types'

import Cancel from '@mui/icons-material/Cancel'
import DragHandle from '@mui/icons-material/DragHandle'
import Edit from '@mui/icons-material/Edit'
import { Draggable } from 'react-beautiful-dnd'
import { IconButton, TableCell, TableRow } from '@mui/material'
import { noop } from 'lodash'

import { ChildrenLock } from './helpers'

const DraggableRowV2 = ({ canDelete, canEdit, children, dragDisabled, id, index, onEdit, onRemove, singleValue }) => {
  const [isHovered, setHovered] = React.useState(false)
  const visibility = React.useMemo(() => (isHovered ? 'visible' : 'hidden'), [isHovered])

  const handleRemove = React.useCallback(() => {
    onRemove(index)
  }, [index, onRemove])

  const handleEdit = React.useCallback(() => {
    onEdit(index)
  }, [index, onEdit])

  return (
    <Draggable draggableId={id} index={index} isDragDisabled={dragDisabled}>
      {({ innerRef, draggableProps, dragHandleProps }, { isDragging }) => (
        <TableRow
          data-qa={`row-${index}`}
          ref={innerRef}
          sx={{ bgcolor: 'white', boxShadow: isDragging ? 5 : 0 }}
          onMouseOver={event => {
            !isHovered && event?.relatedTarget?.tagName !== 'TABLE' && setHovered(true)
          }}
          onPointerLeave={() => setHovered(false)}
          {...draggableProps}
        >
          <ChildrenLock>
            {!dragDisabled && (
              <TableCell align="center" data-qa="drag-cell" key="dragCell" sx={{ width: 1 / 100 }}>
                <span {...dragHandleProps}>
                  <IconButton>
                    <DragHandle {...dragHandleProps} />
                  </IconButton>
                </span>
              </TableCell>
            )}
            {dragDisabled && <TableCell data-qa="drag-cell" />}
            {children}
            {canEdit && (
              <TableCell align="center" data-qa="edit-cell" key="editCell" sx={{ width: 1 / 100 }}>
                <IconButton disabled={singleValue} onClick={handleEdit} sx={{ visibility }}>
                  <Edit />
                </IconButton>
              </TableCell>
            )}
            {canDelete && (
              <TableCell align="center" data-qa="remove-cell" key="removeCell" sx={{ width: 1 / 100 }}>
                <IconButton disabled={singleValue} onClick={handleRemove} sx={{ visibility }}>
                  <Cancel color="error" />
                </IconButton>
              </TableCell>
            )}
          </ChildrenLock>
        </TableRow>
      )}
    </Draggable>
  )
}

DraggableRowV2.propTypes = {
  canDelete: PropTypes.bool,
  cellClass: PropTypes.string,
  children: PropTypes.node.isRequired,
  dragDisabled: PropTypes.bool,
  draggingState: PropTypes.bool,
  id: PropTypes.string.isRequired,
  index: PropTypes.number.isRequired,
  onRemove: PropTypes.func,
  singleValue: PropTypes.bool,
}

DraggableRowV2.defaultProps = {
  canDelete: true,
  canEdit: false,
  dragDisabled: false,
  draggingState: false,
  onRemove: noop,
  singleValue: false,
}

export { DraggableRowV2 }
