import React from 'react'
import PropTypes from 'prop-types'

import { TableCell } from '@mui/material'

import TableDatePicker from 'client-shared/components/Table/TableDatePicker'
import TableNumber from 'client-shared/components/Table/TableNumber'
import TableText from 'client-shared/components/Table/TableText'
import { DraggableTableRowV2 as DraggableTableRow } from 'client-shared/components/DragDropTable'
import { required } from 'client-shared/utils/validation'

const SaleRow = ({ block, id, index, lot, propertyIdentifier, removeSale, streetAddress }) => (
  <DraggableTableRow id={id} index={index} onRemove={() => removeSale(index)}>
    <TableCell data-qa="street-address-cell" key="streetAddress">
      {streetAddress}
    </TableCell>
    <TableCell key="propertyIdentifier" data-qa="property-identifier-cell">
      {propertyIdentifier || `${block}/${lot}`}
    </TableCell>
    <TableCell data-qa="unique-sale-id-cell" key="uniqueSaleId">
      <TableText autoComplete="off" name={`salesHistory.${index}.uniqueSaleId`} placeholder="N/A" />
    </TableCell>
    <TableCell data-qa="sale-date-cell" key="saleDate">
      <TableDatePicker
        clearable
        dateFormat="MM/DD/YYYY"
        disableFuture
        keyboard={true}
        name={`salesHistory.${index}.date`}
        variant="standard"
      />
    </TableCell>
    <TableCell data-qa="sale-price-cell" key="salePrice">
      <TableNumber
        adornment="$"
        adornmentPosition="start"
        allowNegative={false}
        autoComplete="off"
        decimalScale={2}
        defaultValue=""
        margin="none"
        name={`salesHistory.${index}.price`}
        placeholder="0.00"
        thousandSeparator
        validate={required}
      />
    </TableCell>
    <TableCell data-qa="seller-cell" key="seller">
      <TableText autoComplete="off" name={`salesHistory.${index}.seller`} placeholder="N/A" />
    </TableCell>
  </DraggableTableRow>
)

SaleRow.propTypes = {
  block: PropTypes.string,
  id: PropTypes.string.isRequired,
  lot: PropTypes.string,
  propertyIdentifier: PropTypes.string,
  removeSale: PropTypes.func.isRequired,
  streetAddress: PropTypes.string.isRequired,
}

export default SaleRow
