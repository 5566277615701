import React from 'react'
import { isNil } from 'lodash'
import { FieldArray } from 'react-final-form-arrays'
import EditIcon from '@mui/icons-material/Edit'
import CancelIcon from '@mui/icons-material/Cancel'
import { Stack, Typography, Button, IconButton, Box } from '@mui/material'

import AppraisalCertificationModal from './AppraisalCertificationModal'

class AppraisalCertification extends React.PureComponent {
  state = {
    modalOpen: false,
    modalInitials: null,
    editableCertificationIndex: null,
  }

  onModalOpen = () => {
    this.setState({ modalOpen: true })
  }

  onModalClose = () => {
    this.setState({ modalOpen: false, modalInitials: null, editableCertificationIndex: null })
  }

  renderCertification = certification => {
    const { state, level } = certification

    return `${level} - ${state}`
  }

  onCertificationSubmit = certification => {
    const { fields } = this.props
    const { editableCertificationIndex } = this.state

    if (isNil(editableCertificationIndex)) {
      fields.push(certification)
    } else {
      fields.update(editableCertificationIndex, certification)
    }

    this.onModalClose()
  }

  editCertification = (index, certification) => () => {
    this.setState({
      modalInitials: certification,
      editableCertificationIndex: index,
    })

    this.onModalOpen()
  }

  removeCertification = index => () => {
    const { fields } = this.props

    fields.remove(index)
  }

  render() {
    const { fields } = this.props
    const { modalOpen } = this.state

    const certifications = fields.value || []
    return (
      <Stack gap={1}>
        <Typography variant="h6">Appraisal Certification</Typography>
        {certifications.map((certification, index) => (
          <Stack alignItems="center" direction="row">
            <Typography variant="body1" sx={{ marginRight: '10px' }}>
              {this.renderCertification(certification)}
            </Typography>
            <IconButton
              variant="contained"
              color="primary"
              size="small"
              onClick={this.editCertification(index, certification)}
            >
              <EditIcon fontSize="inherit" />
            </IconButton>
            <IconButton size="small" variant="contained" color="error" onClick={this.removeCertification(index)}>
              <CancelIcon fontSize="inherit" />
            </IconButton>
          </Stack>
        ))}

        {!certifications.length && (
          <>
            <Typography variant="body2">
              State Certification:{' '}
              <Typography variant="body1" component="span">
                None
              </Typography>
            </Typography>
          </>
        )}

        <AppraisalCertificationModal
          open={modalOpen}
          onClose={this.onModalClose}
          onSubmit={this.onCertificationSubmit}
          initialValues={this.state.modalInitials}
        />
        <Box>
          <Button variant="contained" onClick={this.onModalOpen}>
            Add Certification
          </Button>
        </Box>
      </Stack>
    )
  }
}

export default props => <FieldArray name="appraisalCertifications" component={AppraisalCertification} {...props} />
