import React from 'react'
import PropTypes from 'prop-types'

import { TableCell } from '@mui/material'

import Text from 'client-shared/components/Table/TableText'
import { DraggableTableRowV2 } from 'client-shared/components/DragDropTable'

class AppraisalScopeItemRow extends React.PureComponent {
  onRemoveItem = () => {
    const { onRemoveItem, index } = this.props
    onRemoveItem(index)
  }

  onKeyPress = event => {
    const { onInsertItem, index } = this.props

    if (event.key === 'Enter') {
      onInsertItem(index)
      event.preventDefault()
    }
  }

  setInputRef = ref => {
    const { setInputRef, index } = this.props

    setInputRef(index, ref)
  }

  render() {
    const { name, dragging, disabled, index, singleValue } = this.props

    return (
      <DraggableTableRowV2
        draggingState={dragging}
        id={name}
        index={index}
        onRemove={this.onRemoveItem}
        singleValue={singleValue}
      >
        <TableCell>
          <Text
            data-qa={`input-${index}`}
            disabled={disabled}
            inputRef={this.setInputRef}
            margin="none"
            multiline
            name={`${name}`}
            onKeyPress={this.onKeyPress}
            placeholder="Write a project deliverable."
            rowsMax={Infinity}
          />
        </TableCell>
      </DraggableTableRowV2>
    )
  }
}

AppraisalScopeItemRow.propTypes = {
  name: PropTypes.string.isRequired,
  index: PropTypes.number.isRequired,
  dragging: PropTypes.bool.isRequired,
  singleValue: PropTypes.bool.isRequired,
  onRemoveItem: PropTypes.func.isRequired,
  onInsertItem: PropTypes.func.isRequired,
  setInputRef: PropTypes.func.isRequired,
}

export default AppraisalScopeItemRow
