import React from 'react'
import PropTypes from 'prop-types'

import { IconButton, Stack, TableCell, TableRow, Tooltip } from '@mui/material'
import Cancel from '@mui/icons-material/Cancel'

import TableNumberField from '../Table/TableNumberRightAligned'
import TextField from '../Table/TableText'

const IncomeAdjustmentRow = ({ collectionName, index, isEditable, onRemove, placeholder, valueType }) => {
  const [isHovering, setIsHovering] = React.useState(false)

  const onMouseEnter = React.useCallback(() => {
    setIsHovering(true)
  }, [])
  const onMouseLeave = React.useCallback(() => {
    setIsHovering(false)
  }, [])

  const handleRemove = React.useCallback(() => {
    onRemove(valueType, index)
  }, [index, onRemove, valueType])

  const fullName = `${collectionName}[${index}]`

  return (
    <TableRow id={fullName} onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave}>
      <TableCell data-qa={`${fullName}.item-cell`}>
        <TextField
          data-qa={`${fullName}-name-input`}
          fullWidth
          inputProps={{
            readOnly: !isEditable,
          }}
          name={`${fullName}.name`}
          placeholder={placeholder || 'Income Adjustment'}
        />
      </TableCell>
      <TableCell>&nbsp;</TableCell>
      <TableCell align="right" data-qa={`${fullName}.amount-cell`}>
        <TableNumberField
          allowNegative={true}
          data-qa={`${fullName}-amount-input`}
          decimalScale={2}
          disabled={!isEditable}
          fullWidth={false}
          margin="none"
          name={`${fullName}.value`}
          noPx
          noPy
          placeholder="0.00"
          prefix="$"
          right
          thousandSeparator
        />
      </TableCell>
      <TableCell />
      <TableCell>
        {isHovering && isEditable && (
          <Stack alignItems="center" direction="row" justifyContent="end" sx={{ my: -1 }}>
            <Tooltip title="Remove Adjustment" placement="top">
              <IconButton data-qa={`${fullName}.remove-adjustment`} onClick={handleRemove} size="small">
                <Cancel color="error" />
              </IconButton>
            </Tooltip>
          </Stack>
        )}
      </TableCell>
    </TableRow>
  )
}

IncomeAdjustmentRow.propTypes = {
  index: PropTypes.number.isRequired,
  isEditable: PropTypes.bool,
  placeholder: PropTypes.string,
}

export default IncomeAdjustmentRow
