import { GeneratedText, gtConditional, gtLocked, gtTemplate, gtIfElse } from 'shared/utils/narrative/GeneratedText'

import { mapDTO, mapDataModel } from './mappings'

const generate = () => {
  const addressGt = gtLocked('address')

  const gt = new GeneratedText()

  gt.t`${gtIfElse(
    'showDevelopersForecast',
    gtTemplate`${gtIfElse(
      'valueAsStabilized',
      gtTemplate`${addressGt} is expected to contain ${gtLocked('unitsCount')} unit${gtIfElse(
        'hasMultipleUnits',
        gtTemplate`s`,
        gtTemplate``
      )} upon completion of the construction works; the developer's 
       projected residential rent roll is presented below.`,
      gtTemplate`${addressGt} currently contains ${gtLocked('occupiedUnitsCount')} occupied unit${gtIfElse(
        'hasMultipleOccupiedUnits',
        gtTemplate`s`,
        gtTemplate``
      )} generating ${gtLocked(
        'occupiedUnitsTotalAnnualIncome'
      )} of total annual residential income. The developer's projected residential rent roll is presented below.`
    )}`,
    gtTemplate`${gtIfElse(
      'areAllUnitsVacant',
      gtTemplate`${addressGt} is currently 100% vacant. 
      We requested the developer's projected residential rent roll, but did not receive it. 
      The property will contain ${gtLocked('unitBedrooms')}.`,
      gtTemplate`${addressGt} currently contains ${gtLocked('occupiedUnitsCount')} occupied unit${gtIfElse(
        'hasMultipleOccupiedUnits',
        gtTemplate`s`,
        gtTemplate``
      )} ${gtConditional(
        'hasVacantUnits',
        gtTemplate`and ${gtLocked('vacantUnitsCount')} vacant unit${gtIfElse(
          'hasMultipleVacantUnits',
          gtTemplate`s`,
          gtTemplate``
        )}generating ${gtLocked('occupiedUnitsTotalAnnualIncome')} of total annual residential income.`
      )}`
    )}`
  )}`

  return gt.getParts()
}

export default { generate, mapDTO, mapDataModel }
