import React from 'react'
import PropTypes from 'prop-types'

import { withStyles } from '@mui/styles'
import { isEqual, noop } from 'lodash'
import { compose, shouldUpdate } from 'recompose'

import { SIDE_BAR_LABELS } from 'client-shared/layout/constants'

import MenuIcon from '../../../../shared/layout/MenuIcon'

const styles = theme => ({
  root: {
    marginBottom: theme.spacing(1.5),
    fontSize: '10px',
    cursor: 'pointer',
  },
  selected: {
    color: theme.palette.primary.main,
  },
})

class NavigationSideBarTab extends React.PureComponent {
  static propTypes = {
    id: PropTypes.string.isRequired,
    isSelected: PropTypes.bool.isRequired,
    title: PropTypes.string.isRequired,
    onClick: PropTypes.func,
  }

  static defaultProps = {
    title: '',
    onClick: noop,
  }

  handleClick = () => {
    const { id, title, onClick } = this.props
    onClick(id, title)
  }

  render() {
    const { classes, id, isSelected, title, icon } = this.props
    const label = SIDE_BAR_LABELS[id]

    return (
      <div id={id} className={classes.root}>
        <MenuIcon
          onClick={this.handleClick}
          sx={{ fontSize: '10px' }}
          icon={icon}
          label={label}
          tooltipTitle={title}
          isActive={isSelected}
        />
      </div>
    )
  }
}

export default compose(
  withStyles(styles),
  shouldUpdate((prev, next) => !isEqual(prev.isSelected, next.isSelected))
)(NavigationSideBarTab)
