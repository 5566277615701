import React from 'react'
import { makeStyles } from '@mui/styles'

import { get } from 'lodash'

export const BADGE_TYPES = {
  confirmed: 'Confirmed',
  draft: 'Draft',
  verified: 'Verified',
  doNotUse: 'Do Not Use',
}

const useStyles = makeStyles({
  confirmed: {
    background: 'linear-gradient(0deg, rgba(255, 255, 255, 0.9), rgba(255, 255, 255, 0.9)), #FFC355',
    width: '76px',
  },
  draft: {
    background: '#EEEEEE',
    width: '48px',
  },
  verified: {
    background: 'linear-gradient(0deg, rgba(255, 255, 255, 0.9), rgba(255, 255, 255, 0.9)), #20B586',
    width: '64px',
  },
  doNotUse: {
    background: 'rgba(211, 65, 65, 0.08)',
    width: '88px',
  },
  badge: {
    color: '#4E5664',
    fontFamily: 'Nunito Sans',
    fontWeight: 400,
    fontSize: '12px',
    lineHeight: '26px',
    height: '26px',
    borderRadius: '4px',
    textAlign: 'center',
    paddingTop: '2px',
    marginTop: '2px',
  },
})

const Badge = ({ type, className }) => {
  const classes = useStyles()
  const badgeString = BADGE_TYPES[type]
  return (
    <div className={`${classes.badge} ${type ? get(classes, type) : classes.draft} ${className}`}>{badgeString}</div>
  )
}

export default React.memo(Badge)
