import React from 'react'
import PropTypes from 'prop-types'
import InfoIcon from '@mui/icons-material/InfoOutlined'
import { TextField, Typography, Tooltip } from '@mui/material'

import { JOB_NUMBER_MESSAGES } from '../constants'

const JobInput = ({
  value,
  error,
  active,
  onBlur,
  onFocus,
  classes,
  onChange,
  helperText,
  endAdornment,
  sx = {},
  ...otherProps
}) => {
  const getInfoIconColor = theme => {
    if (error) {
      return theme.palette.error.main
    }

    if (active) {
      return theme.palette.primary.main
    }

    return theme.palette.grey[600]
  }

  return (
    <TextField
      fullWidth
      error={error}
      value={value}
      onBlur={onBlur}
      onFocus={onFocus}
      onChange={onChange}
      helperText={helperText}
      name="reportNumber"
      label="Bowery Job #"
      data-qa="job-number-text-input"
      placeholder="##########"
      sx={{ minHeight: '80px', ...sx }}
      InputProps={{
        startAdornment: <Typography sx={{ pr: '3px' }}>JOB-</Typography>,
        endAdornment:
          endAdornment === undefined ? (
            <Tooltip title={JOB_NUMBER_MESSAGES.TOOLTIP} placement="top-end">
              <InfoIcon
                sx={{
                  color: getInfoIconColor,
                  cursor: 'pointer',
                }}
              />
            </Tooltip>
          ) : (
            endAdornment
          ),
      }}
      {...otherProps}
    />
  )
}

JobInput.propTypes = {
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  onFocus: PropTypes.func.isRequired,
  onBlur: PropTypes.func.isRequired,
  error: PropTypes.bool.isRequired,
  active: PropTypes.bool.isRequired,
  helperText: PropTypes.string,
  endAdornment: PropTypes.node,
}

JobInput.defaultProps = {
  helperText: '',
}

export default JobInput
