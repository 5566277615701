import React from 'react'
import PropTypes from 'prop-types'
import { pure, compose } from 'recompose'
import { OnChange } from 'react-final-form-listeners'
import { withStyles, FormControl } from '@material-ui/core'

import {
  BELOW_GRADE_ACCESS_CHECKBOX_OPTIONS,
  BELOW_GRADE_STATE_OPTIONS,
} from '../../../../../../shared/constants/report/buildingDescription/index'
import { BELOW_GRADE_TYPES } from '../../../../../../shared/constants/property/description'
import belowGradeDescription from '../../../../../../shared/utils/textGeneration/property/belowGradeDescription'
import {
  keyValuePairsToCheckboxValues,
  arrayToKeyValuePairs,
  objectToKeyValuePairs,
} from '../../../../shared/utils/ui/checkboxHelper'
import { Text, RadioButtonList, NarrativeComponent, CheckboxGroup } from '../../../../shared/components'

const styles = theme => ({
  containsBelowGradeCheckbox: {
    height: 'auto',
  },
  formControl: {
    width: '100%',
  },
  radioButtonWrapper: {
    '& label': {
      transform: 'none',
    },
    '& div': {
      marginTop: theme.spacing.unit * 2,
    },
  },
})

const belowGradeAccessCheckboxOptions = keyValuePairsToCheckboxValues(BELOW_GRADE_ACCESS_CHECKBOX_OPTIONS)
const belowGradeStateOptions = arrayToKeyValuePairs(BELOW_GRADE_STATE_OPTIONS)
const belowGradeTypes = objectToKeyValuePairs(BELOW_GRADE_TYPES)

const onBelowGradeTypeChange = (change, currentBelowGrade) => () => {
  if (currentBelowGrade === BELOW_GRADE_TYPES.NONE) {
    change('belowGrade.access', {})
    change('belowGrade.state', null)
  }
}

const onOtherAccessChange = change => () => {
  change('belowGrade.otherAccess', null)
}

const BelowGrade = ({ classes, change, belowGradeType, belowGrade, otherAccess, valueAsComplete }) => {
  return (
    <FormControl className={classes.formControl}>
      <div className={classes.radioButtonWrapper}>
        <RadioButtonList name="belowGradeType" horizontal label="Basement or Cellar" items={belowGradeTypes} />
      </div>
      <OnChange name="belowGradeType">{onBelowGradeTypeChange(change, belowGradeType)}</OnChange>
      <OnChange name="belowGrade.access.other">{onOtherAccessChange(change)}</OnChange>
      {belowGradeType !== BELOW_GRADE_TYPES.NONE && (
        <React.Fragment>
          <CheckboxGroup
            horizontal
            header={belowGradeType + ' Access'}
            groupName="belowGrade.access"
            items={belowGradeAccessCheckboxOptions}
          />
          {otherAccess && <Text name="belowGrade.otherAccess" label="Other" />}
          <div className={classes.radioButtonWrapper}>
            <RadioButtonList
              name="belowGrade.state"
              horizontal
              label={belowGradeType + ' State'}
              items={belowGradeStateOptions}
            />
          </div>
        </React.Fragment>
      )}
      <NarrativeComponent
        name="belowGrade.description"
        title="Basement or Cellar Discussion"
        generatedText={belowGradeDescription.generate}
        data={belowGradeDescription.mapDTO({ belowGradeType, belowGrade, valueAsComplete })}
      />
    </FormControl>
  )
}

BelowGrade.propTypes = {
  change: PropTypes.func.isRequired,
  otherAccess: PropTypes.bool,
  belowGradeType: PropTypes.string,
  belowGrade: PropTypes.object,
  valueAsComplete: PropTypes.bool.isRequired,
}

BelowGrade.defaultProps = {
  otherAccess: false,
  belowGrade: {},
  belowGradeType: BELOW_GRADE_TYPES.NONE,
}

export default compose(withStyles(styles), pure)(BelowGrade)
