import { get, pick } from 'lodash'
import arrayMutators from 'final-form-arrays'

import { errorNotification } from 'client-shared/redux/actions/notifications'

import { COMMERCIAL_RENT_COMPS_PATH } from 'shared/constants/report/keysAndDataPaths'

import wrapForm from '../../../wrapForm'

import { importCommercialRentComps } from './redux/actions'

import CommercialRentComps from './CommercialRentComps'
import { Labels } from './constants'

export const DATA_PATH = COMMERCIAL_RENT_COMPS_PATH

const mapStateToProps = state => {
  const valueConclusionType = get(state, 'report.reportSettings.valueConclusionType')
  const commercialUnitCount = get(state, 'report.reportData.propertyInformation.propertySummary.commercialUnitCount', 0)
  const { isImportingRentComps, importedRentComps } = get(state, 'commercialRentComps', {})
  const propertyInformation = get(state, 'report.reportData.propertyInformation')
  const reportNumber = get(state, 'report.reportData.report.reportInformation.reportNumber')
  const reportId = get(state, 'report.reportData._id')

  const subjectCoordinates = {
    lat: get(propertyInformation, 'coords.latitude'),
    lng: get(propertyInformation, 'coords.longitude'),
  }

  return {
    valueConclusionType,
    commercialUnitCount,
    isImportingRentComps,
    importedRentComps,
    authenticatedUser: pick(get(state, 'authentication.user'), ['id', 'username', 'fullName']),
    subjectCoordinates,
    reportNumber,
    reportId,
  }
}

const mapDispatchToProps = dispatch => {
  return {
    importCommercialRentComps: reportId => dispatch(importCommercialRentComps({ reportId })),
    errorNotification: error => dispatch(errorNotification({ message: error })),
  }
}
const formOption = {
  heading: Labels.COMMERCIAL_RENT_COMPS,
  mutators: { ...arrayMutators },
}

export default wrapForm(DATA_PATH, formOption, mapStateToProps, mapDispatchToProps)(CommercialRentComps)
