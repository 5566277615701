import React from 'react'
import PropTypes from 'prop-types'
import { TableRow } from '@mui/material'
import { onlyUpdateForKeys } from 'recompose'

import { RENTROLL_COLUMN_NAMES } from '../../../constants'

import RentRollCell from './RentRollCell'

const REQUIRED_COLUMNS = [
  RENTROLL_COLUMN_NAMES.BEDROOMS,
  RENTROLL_COLUMN_NAMES.ROOMS,
  RENTROLL_COLUMN_NAMES.MONTHLY_RENT,
  RENTROLL_COLUMN_NAMES.SQUARE_FOOTAGE,
]

class RentRollRow extends React.Component {
  static propTypes = {
    columns: PropTypes.array,
    name: PropTypes.string,
    onRemoveUnit: PropTypes.func.isRequired,
  }

  render() {
    const {
      columns,
      name,
      index: rowIndex,
      onRemoveUnit,
      isDisabledInclude,
      rentPerRoom,
      isNotReportedSquareFeet,
    } = this.props
    const row = columns.map((column, index) => {
      const required = REQUIRED_COLUMNS.includes(column.name)
      return (
        <RentRollCell
          column={column.name}
          index={rowIndex}
          isDisabledInclude={isDisabledInclude}
          isNotReportedSquareFeet={isNotReportedSquareFeet}
          isRequired={required}
          key={index}
          onRemoveUnit={onRemoveUnit}
          rentPerRoom={rentPerRoom}
          unitName={`${name}[${rowIndex}].${column.name}`}
        />
      )
    })
    return <TableRow>{row}</TableRow>
  }
}

export default onlyUpdateForKeys(['rentPerRoom', 'index', 'isDisabledInclude', 'isNotReportedSquareFeet'])(RentRollRow)
