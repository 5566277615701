import React from 'react'

import { Box, Paper, Stack, Typography } from '@mui/material'

import InputRangeField from 'client-shared/components/Range/InputRanges'
import NarrativeComponent from 'client-shared/components/NarrativeComponent'
import exposureTimeDescription from 'shared/utils/textGeneration/property/exposureTimeDescription'
import marketingTimeDescription from 'shared/utils/textGeneration/property/marketingTimeDescription'
import { CheckboxWithLabel } from 'client-shared/components'

import { validateTimeRange } from './helpers'

const MarketInformation = ({ form, includeMarketingTime }) => (
  <Paper data-qa="market-analyses-information-section-tile">
    <Stack spacing={2}>
      <Typography variant="h6">Market Information</Typography>
      <Box sx={{ maxWidth: 600 }}>
        <Typography variant="subtitle2">Time on Market</Typography>
        <InputRangeField
          adornment="months"
          betweenLabel="to"
          maxLabel="Exposure Time Max"
          maxPlaceholder="9"
          minLabel="Exposure Time Min"
          minPlaceholder="6"
          name="exposureTime"
          validate={validateTimeRange('exposureTime')}
        />
      </Box>
      <NarrativeComponent
        data={exposureTimeDescription.mapDTO(form.values)}
        generatedText={exposureTimeDescription.generate}
        name="exposureTimeDescription"
        title="Exposure Time Description"
      />

      <CheckboxWithLabel
        label="Include Marketing Time in the report"
        name="includeMarketingTime"
        margin="normal"
        data-qa="include-marketing-time-checkbox"
      />

      {includeMarketingTime && (
        <Stack>
          <Box sx={{ maxWidth: 600 }}>
            <InputRangeField
              adornment="months"
              betweenLabel="to"
              maxLabel="Marketing Time Max"
              maxPlaceholder="12"
              minLabel="Marketing Time Min"
              minPlaceholder="6"
              name="marketingTime"
              validate={validateTimeRange('marketingTime')}
            />
          </Box>
          <NarrativeComponent
            data={marketingTimeDescription.mapDTO(form.values)}
            generatedText={marketingTimeDescription.generate}
            name="marketingTimeDescription"
            title="Marketing Time Description"
          />
        </Stack>
      )}
    </Stack>
  </Paper>
)

export default MarketInformation
