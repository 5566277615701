import { GeneratedText } from '../../../../narrative/GeneratedText'

import { mapDTO, mapDataModel } from '../mappings'

export const SITE_IMPROVEMENT_COST_COMPS_CONCLUSION =
  'Typically, site costs range from 1% - 2% of direct costs. The subject benefits from having existing infrastructure in place including roads, curbs and minimal grading and clearing requirements. We consider this in our reconciliation.'

const generate = () => {
  const gt = new GeneratedText()

  gt.t`${SITE_IMPROVEMENT_COST_COMPS_CONCLUSION}`

  return gt.getParts()
}

export default { generate, mapDTO, mapDataModel }
