import React from 'react'
import PropTypes from 'prop-types'
import { withStyles, Grid } from '@material-ui/core'

import styles from './styles'
import PotentialGrossResidentialIncomeTable from './PotentialGrossResidentialIncomeTable'

export const ResidentialUnitSummaryPropType = PropTypes.shape({
  noOfUnits: PropTypes.number.isRequired,
  minRent: PropTypes.number,
  maxRent: PropTypes.number,
  annualRent: PropTypes.number,
  averageRent: PropTypes.number,
  hasVacantUnits: PropTypes.bool.isRequired,
  hasRentStabilizedUnits: PropTypes.bool.isRequired,
  hasRentControlledUnits: PropTypes.bool.isRequired,
})

class ResidentialRentRollSummary extends React.PureComponent {
  static propTypes = {
    classes: PropTypes.object.isRequired,

    gbaHeading: PropTypes.string.isRequired,
    isNYReport: PropTypes.bool.isRequired,
    showPerUnitSF: PropTypes.bool.isRequired,

    formValues: PropTypes.shape({
      buildingSummary: ResidentialUnitSummaryPropType.isRequired,
    }).isRequired,
  }

  render() {
    const { formValues } = this.props

    return (
      <Grid container direction="column" wrap="nowrap" spacing={16}>
        <Grid item>
          <PotentialGrossResidentialIncomeTable formValues={formValues} />
        </Grid>
      </Grid>
    )
  }
}

export default withStyles(styles)(ResidentialRentRollSummary)
