import { createAction } from 'redux-actions'
import { defineAction } from 'redux-define'

import { DEFAULT_API_ACTION_STATES } from '../../../../../../shared/constants'

//ActionTypes
const IMPORT_COMMERCIAL_RENT_COMPS = defineAction('IMPORT_COMMERCIAL_RENT_COMPS', DEFAULT_API_ACTION_STATES)

//Actions
export const importCommercialRentComps = createAction(IMPORT_COMMERCIAL_RENT_COMPS.ACTION)
export const importCommercialRentCompsRequest = createAction(IMPORT_COMMERCIAL_RENT_COMPS.REQUEST)
export const importCommercialRentCompsReceive = createAction(IMPORT_COMMERCIAL_RENT_COMPS.RECEIVE)
export const importCommercialRentCompsFail = createAction(IMPORT_COMMERCIAL_RENT_COMPS.FAIL)
