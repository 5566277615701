import React from 'react'
import PropTypes from 'prop-types'
import { Grid, Typography } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'

import { FormInputLabel } from '../../../../../../shared/components'
import { formatCurrencyFloat } from '../../../../../../shared/utils/numberFormatters'

const styles = theme => ({
  label: {
    fontSize: 10,
    textTransform: 'uppercase',
    color: theme.palette.text.primary,
    margin: '4px 0',
  },
  value: {
    fontFamily: theme.typography.fontFamily,
    fontSize: 16,
    color: theme.secondaryColor,
    margin: '10px 0 0',
  },
  iconContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100%',
  },
})

class CommercialCompGroupsStatisticsPanel extends React.PureComponent {
  static propTypes = {
    icon: PropTypes.node.isRequired,
    min: PropTypes.number,
    max: PropTypes.number,
    avg: PropTypes.number,
    type: PropTypes.string,
  }

  items = [
    {
      label: 'Min',
      name: 'min',
    },
    {
      label: 'Max',
      name: 'max',
    },
    {
      label: 'Average',
      name: 'avg',
    },
  ]

  render() {
    const { classes, icon, type, ...data } = this.props

    return (
      <Grid container data-qa={`${type}-panel`}>
        <Grid item xs={3}>
          <div className={classes.iconContainer}>{icon}</div>
        </Grid>
        {this.items.map(({ name, label }) => (
          <Grid item xs={3} key={name}>
            <FormInputLabel className={classes.label} label={label} />
            <Typography data-qa={`${type}-panel-${name}`} className={classes.value}>
              {formatCurrencyFloat(data[name])}
            </Typography>
          </Grid>
        ))}
      </Grid>
    )
  }
}

export default withStyles(styles)(CommercialCompGroupsStatisticsPanel)
