import { gtIfElse, gtLocked, gtTemplate } from 'shared/utils/narrative/GeneratedText'

import { mapDTO, mapDataModel } from './mappings'

const generate = () => {
  const totalAverageRentGt = gtLocked('totalAverageRent', 'formatCurrencyInt', 'Average Rent')

  return gtIfElse(
    'isForecast',
    gtTemplate`The average forecasted monthly rent is ${totalAverageRentGt}.`,
    gtTemplate`The average in place monthly rent is ${totalAverageRentGt}.`
  )
}

export default { generate, mapDTO, mapDataModel }
