import { GeneratedText, gtLocked, gtIfElse, gtTemplate } from '../../../narrative/GeneratedText'

import { mapDTO, mapDataModel } from './mappings'

const generate = () => {
  const meterGt = new GeneratedText()
  const gt = new GeneratedText()

  const systemGt = gtLocked('system')

  const locationGt = gtIfElse('isOtherLocation', gtTemplate`${gtLocked('otherLocation', null, 'Location')}`, [
    gtLocked('systemLocation', 'formatLowerCase', 'Location'),
  ])

  meterGt.addParts(
    gtIfElse('meterType', gtTemplate`${gtLocked('meterType', null)} ${gtLocked('systemType', null)}`, [
      gtLocked('systemType', 'formatUpperFirst'),
    ])
  )

  meterGt.addParts(gtTemplate` ${gtLocked('meterTense', null, 'Meter')} located `)

  meterGt.addParts(locationGt)
  meterGt.t`.`

  gt.addParts(
    gtIfElse(
      'inspected',
      gtIfElse('hasNoTypeAndNoLocation', gtTemplate`There are ${systemGt} meters.`, meterGt.getParts()),
      gtTemplate`There are no ${systemGt} meters.`
    )
  )

  return gt.getParts()
}

export default { generate, mapDTO, mapDataModel }
