import { get } from 'lodash'
import arrayToSentence from 'array-to-sentence'

import { appraiserCertifiedInState } from '../../../../../../client/src/report/forms/reportInformation/Appraisers/appraisersHelper'
import { TemplateTypes } from '../../../../../../libs/bowery-libs/constants/report/settings'

const filterAppraiserAssistants = (appraisers, isFreddieMac, reportState) => {
  return appraisers
    .filter(appraiser => {
      return !appraiser.signReport || (isFreddieMac && !appraiserCertifiedInState(appraiser, reportState))
    })
    .map(appraiser => appraiser.fullName || appraiser.appraiser.fullName)
}

export const mapDTO = formValues => {
  const { appraisers, isFreddieMac, reportState } = formValues
  const appraiserAssistants = filterAppraiserAssistants(appraisers, isFreddieMac, reportState)

  const appraiserAssistantsPhrase = arrayToSentence(appraiserAssistants)
  const isAppraiserCountGreaterThanZero = appraiserAssistantsPhrase.length > 0
  const isAppraiserCountEqualToZero = !isAppraiserCountGreaterThanZero
  return {
    appraiserAssistantsPhrase,
    isAppraiserCountGreaterThanZero,
    isAppraiserCountEqualToZero,
  }
}

export const mapDataModel = report => {
  const templateType = get(report, 'templateType', '')
  const { state } = get(report, 'property_information', {})
  const { appraisers } = get(report, 'organizationInfo', { appraisers: [] })
  const externalInspectors = get(report, 'external_inspectors', [])
  const isFreddieMac = templateType === TemplateTypes.FREDDIE_MAC
  const appraiserAssistants = filterAppraiserAssistants([...appraisers, ...externalInspectors], isFreddieMac, state)
  const appraiserAssistantsPhrase = arrayToSentence(appraiserAssistants) || ''
  const isAppraiserCountGreaterThanZero = appraiserAssistantsPhrase.length > 0
  const isAppraiserCountEqualToZero = !isAppraiserCountGreaterThanZero
  return {
    appraiserAssistantsPhrase,
    isAppraiserCountGreaterThanZero,
    isAppraiserCountEqualToZero,
  }
}
