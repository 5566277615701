import React, { useState, useEffect } from 'react'
import { fetchMicrofrontendEntry } from 'core/api'

import useScript, { SCRIPT_LOADING_STATUS } from './useScript'

const entryPaths = {}

/**
 * Load a Webpack micro-frontend's main entrypoint, and return its status.
 *
 * @param {string} name identifier of microfrontend to load
 * @returns {string} loading status of entrypoint script
 */
export const useMicrofrontend = name => {
  const [url, setUrl] = useState(null)

  useEffect(() => {
    const fetchUrl = async () => {
      try {
        if (entryPaths[name] !== undefined) {
          setUrl(entryPaths[name])
        } else {
          const result = await fetchMicrofrontendEntry(name)
          setUrl(result.data || false)
          entryPaths[name] = result.data || false
        }
      } catch (error) {
        setUrl(false)
      }
    }
    fetchUrl()
  }, [name])

  const status = useScript(url)
  return status
}

/**
 * Load a Webpack micro-frontend's main entrypoint, and render wrapped component once loading succeeds or fails.
 *
 * @param {string} name identifier of microfrontend to load
 * @param {string} loadedProp name of boolean prop added to wrapped component, true if loading succeeded.
 */
export const withMicrofrontend = (name, loadedProp) => Component => {
  return function (props) {
    const status = useMicrofrontend(name)
    if (status === SCRIPT_LOADING_STATUS.READY || status === SCRIPT_LOADING_STATUS.ERROR) {
      return <Component {...{ [loadedProp]: status === SCRIPT_LOADING_STATUS.READY }} {...props} />
    }
    return null
  }
}
