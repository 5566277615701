import React from 'react'
import PropTypes from 'prop-types'
import { IconButton, UneditableSection, UneditableField } from 'client-shared/components'
import { get, toString, isNil, isEmpty } from 'lodash'
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  Checkbox,
  Paper,
  Box,
  Stack,
} from '@mui/material'
import { ANCHOR_APPRAISERS } from 'shared/constants/report/dataCollections'

import DropDown from 'client-shared/components/DropDown'

import NativeDropDown from 'client-shared/components/DropDown/NativeDropDownField'

import AppraisersTableHeadTooltip from './AppraisersTableHeadTooltip'

import {
  APPRAISERS_ROLES,
  APPRAISERS_TABLE_COLUMNS,
  FULL_NAME_TABLE_COLUMN_KEY,
  NONE_OPTION,
  STATE_CERTIFICATION_COLUMN_KEY,
} from './constants'

const AppraisersTable = ({ appraisers, form, currentPath, userInJobDetailsSection, ...props }) => {
  const renderCompletedEducationRequirements = appraiser => {
    if (isNil(appraiser.educationRequirementsCompleted)) {
      return '-'
    } else {
      return appraiser.educationRequirementsCompleted ? 'Yes' : 'No'
    }
  }

  const getAppraisalCertificationOptions = appraisalCertifications => {
    const appraisalCertificationOptions = appraisalCertifications.map(appraiser => {
      return {
        label: `${appraiser.level} - ${appraiser.state}`,
        value: appraiser,
      }
    })

    appraisalCertificationOptions.push(NONE_OPTION)

    return appraisalCertificationOptions
  }

  const renderAppraiser = (appraiser, index) => {
    const {
      leadAppraisersLabelValuePairs,
      onInspectedClick,
      onLeadAppraiserChange,
      onRemoveAppraiser,
      onSignReportClick,
    } = props

    const appraiserRole = appraiser.isLeadAppraiser ? APPRAISERS_ROLES.LEAD_APPRAISER : APPRAISERS_ROLES.APPRAISER
    const appraisalCertifications = get(appraiser, 'appraisalCertifications') || []
    const appraisalCertificationOptions = getAppraisalCertificationOptions(appraisalCertifications)

    return (
      <TableRow
        key={appraiser.id}
        data-qa={`role-${appraiserRole}`}
        sx={{
          '& .MuiTableCell-root': {
            fontSize: appraiser.isExternal ? 14 : 16,
          },
        }}
      >
        <TableCell>{appraiser.isExternal ? 'Inspector' : appraiserRole}</TableCell>
        <TableCell data-qa="fullName">
          {appraiser.isLeadAppraiser ? (
            <DropDown value={appraiser.id} items={leadAppraisersLabelValuePairs} onChange={onLeadAppraiserChange} />
          ) : (
            appraiser.fullName
          )}
        </TableCell>
        <TableCell data-qa="suffix">{appraiser.suffix || '-'}</TableCell>
        <TableCell data-qa="certification">
          {isEmpty(appraiser.appraisalCertifications) ? (
            '-'
          ) : (
            <NativeDropDown
              items={appraisalCertificationOptions}
              getValue={appraiser =>
                isEmpty(appraiser) ? NONE_OPTION.label : `${appraiser.level} - ${appraiser.state}`
              }
              name={`selectedAppraisers[${index}].certification`}
              placeholder="Select certification"
            />
          )}
        </TableCell>
        <TableCell data-qa="designation">{appraiser.designation || '-'}</TableCell>
        <TableCell data-qa="educationRequirementsCompleted">
          {renderCompletedEducationRequirements(appraiser)}
        </TableCell>
        <TableCell data-qa="inspected-checkbox">
          <Checkbox
            checked={appraiser.isInspected}
            onChange={() => onInspectedClick(appraiser.id)}
            disabled={appraiser.isExternal}
            value={toString(appraiser.isInspected)}
          />
        </TableCell>
        <TableCell data-qa="signReport-checkbox">
          <Checkbox
            checked={appraiser.signReport}
            value={toString(appraiser.signReport)}
            onChange={() => onSignReportClick(appraiser)}
          />
        </TableCell>
        <TableCell data-qa="remove-btn">
          {appraiser.isLeadAppraiser ? (
            <Box component="span" sx={{ marginLeft: 2 }}>
              -
            </Box>
          ) : (
            <IconButton onClick={() => onRemoveAppraiser(appraiser.id)} color="error">
              <Box component="span">-</Box>
            </IconButton>
          )}
        </TableCell>
      </TableRow>
    )
  }

  const renderHeaderColumn = column => {
    switch (column.key) {
      case FULL_NAME_TABLE_COLUMN_KEY:
      case STATE_CERTIFICATION_COLUMN_KEY:
        return <AppraisersTableHeadTooltip header={column.name} />
      default:
        return <Typography variant="body2">{column.name}</Typography>
    }
  }

  return (
    <Stack spacing={3}>
      {!userInJobDetailsSection ? (
        <UneditableSection
          name="Found In: Job Details Data"
          formName="Job Details Data"
          link="job-details"
          anchor={ANCHOR_APPRAISERS}
          returnDataPath={currentPath}
          editDataMessage
        >
          <Stack spacing={1}>
            {form.values.selectedAppraisers.map((appraiser, index) => {
              const label = appraiser.isLeadAppraiser ? 'Lead Appraiser' : 'Appraiser'

              return <UneditableField label={label} name={`selectedAppraisers[${index}].fullName`} />
            })}
            {form.values.externalInspectors.map((inspector, index) => (
              <UneditableField label="Inspector" name={`externalInspectors[${index}].fullName`} />
            ))}
          </Stack>
        </UneditableSection>
      ) : (
        <Paper sx={{ padding: 0 }}>
          <Table>
            <TableHead
              sx={{
                borderRadius: 2,
                '& .MuiTableCell-head': {
                  backgroundColor: '#E0E2E4',
                  color: '#000000',
                },
              }}
            >
              <TableRow>
                {APPRAISERS_TABLE_COLUMNS.map(column => (
                  <TableCell padding="normal" key={column.key}>
                    {renderHeaderColumn(column)}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>{appraisers.map(renderAppraiser)}</TableBody>
          </Table>
        </Paper>
      )}
    </Stack>
  )
}

AppraisersTable.propTypes = {
  appraisers: PropTypes.arrayOf(
    PropTypes.shape({
      appraisalCertifications: PropTypes.arrayOf(PropTypes.object),
      fullName: PropTypes.string.isRequired,
      educationRequirementsCompleted: PropTypes.bool,
      suffix: PropTypes.string,
      stateCertificationNumber: PropTypes.string,
      designation: PropTypes.string,
      isLeadAppraiser: PropTypes.bool,
      isInspected: PropTypes.bool,
      isExternal: PropTypes.bool,
    })
  ),
  leadAppraisersLabelValuePairs: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      value: PropTypes.string.isRequired,
    })
  ),
  onRemoveAppraiser: PropTypes.func.isRequired,
  onInspectedClick: PropTypes.func.isRequired,
  onSignReportClick: PropTypes.func.isRequired,
  onLeadAppraiserChange: PropTypes.func.isRequired,
  currentPath: PropTypes.string.isRequired,
  userInJobDetailsSection: PropTypes.bool,
}

AppraisersTable.defaultProp = {
  appraisers: [],
  leadAppraisersLabelValuePairs: [],
}

export default AppraisersTable
