import { get } from 'lodash'
import { connect } from 'react-redux'

import { ExportStatuses } from 'shared/constants/export'

import { TEMPLATE_TYPES } from '../../constants/settings'

import {
  generateReport,
  generateXML,
  updateStatus,
  getReportHierarchy,
  updateShouldGenerateSignatures,
} from './redux/actions'

import { TemplatesLists, Templates } from './constants'
import ExportReport from './ExportReport'

export default connect(
  (state, ownProps) => {
    const isBlocks = get(state, 'report.reportSettings.isBlocks')
    const reportId = get(state, 'report.reportData._id')
    const templateType = get(state, 'report.reportSettings.templateType') || TEMPLATE_TYPES.BOWERY_WAY
    const isUnderContract = get(state, 'report.reportData.propertyInformation.propertyHistory.isUnderContract')
    const salesHistory = get(state, 'report.reportData.propertyInformation.propertyHistory.salesHistory')

    const missingSubjectFields = get(state, 'report.reportData.subjectsAsSalesComps.missingFields')

    let exportItems = []
    if (ownProps.exportItems && ownProps.exportItems.length) {
      exportItems = ownProps.exportItems
    }

    const lastCompletedExportItemIndex = exportItems.findIndex(r =>
      [ExportStatuses.COMPLETE, ExportStatuses.WARNING].includes(r.status)
    )

    const exportItemsWithoutWarnings = exportItems.map(exportItem => {
      if (exportItem.status === ExportStatuses.WARNING) {
        return { ...exportItem, warnings: [], status: ExportStatuses.COMPLETE }
      }

      return exportItem
    })

    const defaultBaseTemplateFileName = Templates[templateType]

    const templates = TemplatesLists[templateType]

    return {
      defaultBaseTemplateFileName,
      exportItems: exportItemsWithoutWarnings,
      lastCompletedExportItemIndex,
      reportId,
      templates,
      templateType,
      isUnderContract,
      salesHistory,
      missingSubjectFields,
      isBlocks,
    }
  },
  {
    generateReport,
    generateXML,
    getReportHierarchy,
    updateStatus,
    updateShouldGenerateSignatures,
  }
)(ExportReport)
