import React, { useEffect } from 'react'
import { Route, Switch } from 'react-router'
import PropTypes from 'prop-types'
import { HEADER_HEIGHT } from 'client-shared/layout/constants'

import Report from '../report'
import ReportsDashboard from '../reportsDashboard'

import BuildingComp from '../etc/buildingComparables/Router'
import AdminRoutes from '../admin/Router'
import CmsRoutes from '../cms/Router'

const AppSwitch = ({ location }) => {
  AppSwitch.propTypes = {
    location: PropTypes.object.isRequired,
  }

  useEffect(() => {
    // Scroll for Report pages moved to wrapForm
    if (/^\/report\/[a-f\d]{24}/i.test(location.pathname)) {
      return
    }

    // if not a hash link, scroll to top
    if (location.hash === '') {
      window.scrollTo(0, 0)
    }
    // else scroll to id
    else {
      setTimeout(() => {
        const id = location.hash.replace('#', '')
        const element = document.getElementById(id)
        if (element) {
          const yOffset = HEADER_HEIGHT
          const y = element.getBoundingClientRect().top + window.pageYOffset - yOffset

          window.scrollTo({ top: y, behavior: 'smooth' })
        }
      }, 0)
    }
  }, [location.pathname, location.hash])

  return (
    <Switch location={location}>
      <Route exact path="/" component={ReportsDashboard} />
      <Route path="/reports" component={ReportsDashboard} />
      <Route path="/report/:reportId/building-comp/:buildingCompId" component={BuildingComp} />
      <Route path="/report/:id" component={Report} />
      <Route path="/(user|organization)/" component={AdminRoutes} />
      <Route path="/cms/" component={CmsRoutes} />
    </Switch>
  )
}

export default AppSwitch
