import React from 'react'

const RefHolder = Component =>
  React.forwardRef((props, ref) => (
    <span ref={ref}>
      <Component {...props} />
    </span>
  ))

export default RefHolder
