import React from 'react'
import PropTypes from 'prop-types'
import { Field } from 'react-final-form'
import { withStyles } from '@mui/styles'
import Box from '@mui/material/Box'
import SearchIcon from '@mui/icons-material/Search'

import { STATE_OPTIONS } from 'shared/constants/states'

import Autosuggest from '../../../shared/components/Autosuggest'

const styles = theme => ({
  inputRoot: {
    height: 49,
    position: 'static',
    boxSizing: 'border-box',
  },
  searchIcon: {
    height: 20,
    width: 20,
    marginRight: 8,
    color: 'inherit',
  },
  focusedInput: {
    '& $searchIcon': {
      color: theme.palette.primary[900],
    },
  },
  suggestionsContainer: {
    width: 'calc(100% + 27px)',
  },
})

class AppraisalStateSearch extends React.PureComponent {
  state = {
    stateName: this.props.stateName,
  }

  componentDidUpdate(prevProps, prevState) {
    const { stateName, onStateChange } = this.props
    if (stateName !== prevProps.stateName) {
      this.setState({ stateName: stateName })
    } else if (!this.state.stateName) {
      onStateChange('')
    }
  }

  get textFieldProps() {
    return {
      fullWidth: true,
      placeholder: 'Search States',
      label: 'Appraisal License State',
      variant: 'outlined',
      margin: 'dense',
      InputLabelProps: {
        shrink: true,
      },
    }
  }

  get inputProps() {
    const { classes } = this.props
    return {
      classes: {
        root: classes.inputRoot,
        focused: classes.focusedInput,
      },
      startAdornment: <SearchIcon className={classes.searchIcon} />,
      name: 'client',
    }
  }

  getSuggestionValue = state => state.name

  onChange = (event, meta = {}) => {
    const { newValue, method } = meta
    const { value } = event.target

    this.setState({
      stateName: method === 'enter' ? newValue : value,
    })
  }

  onSuggestionSelected = (event, { suggestion }) => {
    const { onStateChange } = this.props
    onStateChange(suggestion.name)
  }

  render() {
    const { classes } = this.props
    const { stateName } = this.state
    return (
      <Box sx={{ height: '84px' }} data-qa="autosuggest-input">
        <Autosuggest
          value={stateName}
          classes={{
            suggestionsContainer: classes.suggestionsContainer,
          }}
          selectOnBlur
          suggestions={STATE_OPTIONS}
          onChange={this.onChange}
          textFieldProps={this.textFieldProps}
          inputProps={this.inputProps}
          getSuggestionValue={this.getSuggestionValue}
          onSuggestionSelected={this.onSuggestionSelected}
        />
      </Box>
    )
  }
}

AppraisalStateSearch.propTypes = {
  onStateChange: PropTypes.func.isRequired,
  stateName: PropTypes.string,
}

AppraisalStateSearch.defaultProps = {
  stateName: '',
}

const AppraisalStateSearchField = props => (
  <Field name="state">
    {({ input }) => {
      return <AppraisalStateSearch onStateChange={input.onChange} stateName={input.value} {...props} />
    }}
  </Field>
)

export default withStyles(styles)(AppraisalStateSearchField)
