import { FormControlProps, InputAdornment, OutlinedInput, OutlinedInputProps } from '@mui/material'
import { makeStyles } from '@mui/styles'
import React from 'react'

import { Theme } from 'core/theme'

import InputWrapper, { Props as InputWrapperProps } from '../InputWrapper/InputWrapper'
import { textAndNumberStyles } from '../shared'

const useStyles = makeStyles<Theme>(theme => {
  return textAndNumberStyles(theme)
})

type Props = Omit<FormControlProps, 'disabled' | 'error' | 'required'> &
  Pick<OutlinedInputProps, 'name' | 'type' | 'value' | 'onBlur' | 'onChange' | 'onFocus' | 'placeholder'> &
  Pick<
    InputWrapperProps,
    'label' | 'disabled' | 'tooltipText' | 'error' | 'required' | 'helperText' | 'extraTitleElements'
  > & {
    startAdornment?: React.ReactNode
    endAdornment?: React.ReactNode
    classes?: {
      outlineInput?: string
      error?: string
      disabled?: string
      nativeInput?: string
    }
  }

const Text: React.FC<Props> = ({
  name,
  type,
  label,
  helperText,
  placeholder,
  tooltipText,
  value,
  onChange,
  onBlur,
  onFocus,
  startAdornment,
  endAdornment,
  required = false,
  disabled = false,
  error = false,
  classes: providedClasses,
  extraTitleElements,
  ...restProps
}) => {
  const classes = useStyles({ classes: providedClasses })

  let startAdornmentMarkup = null
  if (startAdornment) {
    startAdornmentMarkup = <InputAdornment position="start">{startAdornment}</InputAdornment>
  }

  let endAdornmentMarkup = null
  if (endAdornment) {
    endAdornmentMarkup = <InputAdornment position="end">{endAdornment}</InputAdornment>
  }

  const inputElementMarkup = (
    <OutlinedInput
      name={name}
      type={type}
      value={value}
      onBlur={onBlur}
      onChange={onChange}
      onFocus={onFocus}
      placeholder={placeholder}
      startAdornment={startAdornmentMarkup}
      endAdornment={endAdornmentMarkup}
      classes={{
        root: classes.outlineInput,
        error: classes.error,
        disabled: classes.disabled,
        input: classes.nativeInput,
      }}
      margin="dense"
    />
  )

  return (
    <InputWrapper
      label={label}
      disabled={disabled}
      tooltipText={tooltipText}
      error={error}
      required={required}
      helperText={helperText}
      extraTitleElements={extraTitleElements}
      {...restProps}
    >
      {inputElementMarkup}
    </InputWrapper>
  )
}

export default React.memo(Text)
