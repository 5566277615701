import React from 'react'
import PropTypes from 'prop-types'

import BookmarkBorderOutlinedIcon from '@mui/icons-material/BookmarkBorderOutlined'
import BookmarkOutlinedIcon from '@mui/icons-material/BookmarkOutlined'

import { withStyles } from '@mui/styles'
import classnames from 'classnames'
import { get } from 'lodash'
import { connect } from 'react-redux'
import { compose, pure } from 'recompose'

import { addPageBookmark, removePageBookmark } from '../redux/actions/bookmarks'

const styles = {
  bookmark: {
    paddingRight: 16,
    cursor: 'pointer',
    fontSize: 24,
  },
  bookedBookmark: {
    color: '#F68750',
  },
  bookmarkBorder: {
    '&:hover': {
      cursor: 'pointer',
    },
  },
}

const BookmarkIndicator = ({ classes, onAddBookmark, onRemoveBookmark, isBookmarked }) => {
  return isBookmarked ? (
    <BookmarkOutlinedIcon onClick={onRemoveBookmark} className={classnames(classes.bookmark, classes.bookedBookmark)} />
  ) : (
    <BookmarkBorderOutlinedIcon
      onClick={onAddBookmark}
      className={classnames(classes.bookmark, classes.bookmarkBorder)}
    />
  )
}

BookmarkIndicator.propTypes = {
  isBookmarked: PropTypes.bool,
  onAddBookmark: PropTypes.func.isRequired,
  onRemoveBookmark: PropTypes.func.isRequired,
}

BookmarkIndicator.defaultProps = {
  isBookmarked: false,
}

const mapStateToProps = (state, ownProps) => {
  const bookmarks = get(state, 'bookmarks.bookmarks', [])
  const isBookmarked = bookmarks.includes(ownProps.id)
  return {
    isBookmarked,
  }
}

const mergeProps = (props, dispatchProps, { classes, id }) => ({
  ...props,
  classes,
  onAddBookmark: event => {
    event.preventDefault()
    dispatchProps.addPageBookmark(id)
  },
  onRemoveBookmark: event => {
    event.preventDefault()
    dispatchProps.removePageBookmark(id)
  },
})

export default compose(
  pure,
  connect(mapStateToProps, { addPageBookmark, removePageBookmark }, mergeProps),
  withStyles(styles)
)(BookmarkIndicator)
