export const PROPERTY_TYPES = {
  multiFamily: 'Multifamily',
  residentialCondo: 'Residential Condo',
  industrialCondo: 'Industrial Condo',
  officeCondo: 'Office Condo',
  mixedUseCommercialApartment: 'Mixed-Use Commercial/Apartment',
  retailSpace: 'Retail',
  officeSpace: 'Office',
  industrialSpace: 'Industrial',
}
export const PROPERTY_TYPE_VALUES = Object.keys(PROPERTY_TYPES)

export const GENERAL_IMPROVEMENT_TYPES = {
  Residential: 'Residential',
  'Mixed-Use': 'Mixed-Use',
  Commercial: 'Commercial',
}
export const GENERAL_IMPROVEMENT_TYPE_VALUES = Object.keys(GENERAL_IMPROVEMENT_TYPES)

export const OWNER_USER = 'ownerUser'

export const MOST_PROBABLE_BUYERS = {
  local: 'Local Investor/Developer',
  regional: 'Regional Investor/Developer',
  national: 'National Investor/Developer',
  [OWNER_USER]: 'Owner-user',
}
export const MOST_PROBABLE_BUYER_VALUES = Object.keys(MOST_PROBABLE_BUYERS)
