import React from 'react'
import { Link } from 'react-router-dom'

import {
  Grid,
  IconButton,
  Paper,
  Table,
  TableCell,
  TableBody,
  TableHead,
  TableRow,
  Typography,
} from '@material-ui/core'
import { Stack } from '@mui/material'
import OpenIcon from '@mui/icons-material/OpenInNewRounded'

import {
  GENERAL_IMPROVEMENT_TYPES,
  MOST_PROBABLE_BUYERS,
  PROPERTY_TYPES,
} from 'shared/constants/report/final/highestAndBestUse'

import { MID_BLOCK, SITE_AREA_SF_UNIT_OF_MEASURE } from '../../../../../../../shared/constants/report'

import { keyValuePairsToCheckboxValues } from '../../../../../shared/utils/ui/checkboxHelper'
import { formatInt } from '../../../../../shared/utils/numberFormatters'

import { CheckboxWithLabel, CheckboxGroup, MultiSelectCheckboxGroup } from '../../../../../shared/components/Checkbox'
import MultipleDropDown from '../../../../../shared/components/DropDown/MultipleDropDownField'
import { RadioButtonList, GeneratedComment, Tooltip } from '../../../../../shared/components'

import { normalizePropertyCondition } from '../../../../../../../shared/utils/report/propertyInformation/description'
import { SECTIONS, showSection } from '../../../../../../../shared/helpers/propertyType'

import {
  generateAsVacantPhysicallyPossibleCommentary,
  generateAsImprovedPhysicallyPossibleCommentary,
  generateAsVacantFinanciallyFeasibleCommentary,
  generateAsImprovedFinanciallyFeasibleCommentary,
  generateAsVacantMaximallyProductiveCommentary,
  generateAsImprovedMaximallyProductiveCommentary,
  generateAsVacantConclusionCommentary,
  generateAsImprovedConclusionCommentary,
  generateMostProbableBuyerCommentary,
} from './commentary'

export {
  generateLegallyPermissibleAsVacantCommentary,
  generateLegallyPermissibleAsImprovedCommentary,
} from './commentary'

export const DEFAULT_TOOLTIP_TEXT = 'The following text will appear in the Highest & Best Use section of your report.'
const AS_IMPROVED_CONCLUSION_TOOLTIP_TEXT =
  'The following text will appear in the Letter of Transmittal and Highest & Best Use sections of your report.'

export const HEADING = 'Highest and Best Use'

export const PRECLUSIONS = {
  zoningOrdinance: 'Zoning Ordinance',
  easementsAndEncroachments: 'Easements & Encroachments',
  rentRegulatedTenants: 'Rent Regulated Tenants',
}

export const INITIAL_VALUES = {
  geographySpecific: {
    preclusionsExist: false,
    preclusions: [],
  },
}

export const styles = theme => ({
  paper: theme.paper,
  sectionTitle: {
    marginBottom: 16,
  },
  tableTitleContainer: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: 16,
  },
  icon: {
    width: 20,
    height: 20,
    padding: 0,
    marginLeft: 4,
  },
  tabs: {
    marginBottom: 16,
  },
  table: {
    backgroundColor: theme.palette.grey[100],
    border: `solid 1px ${theme.palette.grey[400]}`,
    borderRadius: 4,
    '& th, & td': {
      borderBottom: `solid 1px ${theme.palette.grey[400]}`,
    },
    '& td:lastChild': {
      borderBottom: `none`,
    },
    marginBottom: theme.spacing.unit * 2,
  },
  singleCheckbox: {
    height: 28,
    marginBottom: 16,
  },
  checkboxGroup: {
    marginTop: 0,
    marginBottom: 16,
  },
  multiSelectCheckboxGroup: {
    marginBottom: 16,
  },
  multiSelectCheckboxGroupCheckbox: {
    paddingTop: 4,
    paddingBottom: 4,
  },
})

const formatFrontage = frontage => {
  return frontage ? `${formatInt(frontage)} ft` : '-'
}

const formatStreetsFrontage = streetsFrontage => {
  const formattedFrontages = []

  if (streetsFrontage && streetsFrontage.street1Frontage && streetsFrontage.street1Name) {
    formattedFrontages.push(formatFrontage(streetsFrontage.street1Frontage))
  }
  if (streetsFrontage && streetsFrontage.street2Frontage && streetsFrontage.street2Name) {
    formattedFrontages.push(formatFrontage(streetsFrontage.street2Frontage))
  }
  if (streetsFrontage && streetsFrontage.street3Frontage && streetsFrontage.street3Name) {
    formattedFrontages.push(formatFrontage(streetsFrontage.street3Frontage))
  }

  return formattedFrontages.join(', ') || '-'
}

const feasiblePropertyTypeLabelSelector = value => PROPERTY_TYPES[value] || value

export const renderPhysically = props => {
  const {
    classes,
    form,
    isComplying,
    isConformingWithAllowableUses,
    isAsComplete,
    propertyCondition,
    asStabilizedPropertyCondition,
    siteArea,
    siteAreaMeasure,
    propertyType,
  } = props
  const { unitGroups, frontage, streetsFrontage, corner } = form.values
  const unitCounts = unitGroups.map(unitGroup => {
    return {
      unitGroupName: unitGroup.name,
      unitCount: unitGroup.units.length,
    }
  })

  const displayPropertyCondition = isAsComplete ? asStabilizedPropertyCondition : propertyCondition
  const frontageFormatted = corner === MID_BLOCK ? formatFrontage(frontage) : formatStreetsFrontage(streetsFrontage)
  const siteAreaFormatted = siteAreaMeasure === SITE_AREA_SF_UNIT_OF_MEASURE ? formatInt(siteArea) : siteArea

  return (
    <Paper className={classes.paper}>
      <Typography variant="h6" className={classes.sectionTitle}>
        Physically Possible
      </Typography>
      <Grid container spacing={32}>
        <Grid item sm={6}>
          <div className={classes.tableTitleContainer}>
            <Typography variant="subtitle1">Property Information</Typography>
            <Tooltip text="Go To Property Summary" placement="top">
              <Link to="./property-summary">
                <IconButton className={classes.icon}>
                  <OpenIcon fontSize="small" />
                </IconButton>
              </Link>
            </Tooltip>
          </div>
          <div className={classes.table}>
            <Table padding="dense" data-qa="property-information-table">
              <TableHead>
                <TableRow>
                  <TableCell>Site Area</TableCell>
                  <TableCell>Property Frontage</TableCell>
                  <TableCell>Property Condition</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow>
                  <TableCell data-qa="site-area">{siteAreaFormatted || '-'}</TableCell>
                  <TableCell data-qa="property-frontage">{frontageFormatted}</TableCell>
                  <TableCell data-qa="property-condition">
                    {normalizePropertyCondition(displayPropertyCondition) || '-'}
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </div>
        </Grid>
        <Grid item sm={6}>
          <div className={classes.tableTitleContainer}>
            <Typography variant="subtitle1">Zoning Information</Typography>
            <Tooltip text="Go To Zoning" placement="top">
              <Link to="./zoning">
                <IconButton className={classes.icon}>
                  <OpenIcon fontSize="small" />
                </IconButton>
              </Link>
            </Tooltip>
          </div>
          <div className={classes.table}>
            <Table padding="dense" data-qa="zoning-information-table">
              <TableHead>
                <TableRow>
                  <TableCell>Complying Bulk</TableCell>
                  <TableCell align="right">Conforming Use</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow>
                  <TableCell data-qa="complying-bulk">{isComplying ? 'Complying' : 'Non-complying'}</TableCell>
                  <TableCell data-qa="conforming-use" align="right">
                    {isConformingWithAllowableUses ? 'Conforming' : 'Non-conforming'}
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </div>
        </Grid>
      </Grid>

      {showSection(SECTIONS.HAS_RESIDENTIAL, propertyType) && (
        <React.Fragment>
          <div className={classes.tableTitleContainer}>
            <Typography variant="subtitle1">Residential Rent Information</Typography>
            <Tooltip text="Go To Residential Rent Roll" placement="top">
              <Link to="./residential-rent-roll">
                <IconButton className={classes.icon}>
                  <OpenIcon fontSize="small" />
                </IconButton>
              </Link>
            </Tooltip>
          </div>
          <div className={classes.table}>
            <Table padding="dense" data-qa="residential-units-table">
              <TableHead>
                <TableRow>
                  <TableCell>Unit Type</TableCell>
                  <TableCell align="right"># Of Units</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {unitCounts.map(({ unitGroupName, unitCount }) => (
                  <TableRow key={unitGroupName}>
                    <TableCell data-qa="unit-group-name">{unitGroupName || '-'}</TableCell>
                    <TableCell data-qa="unit-count" align="right">
                      {unitCount || '-'}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </div>
        </React.Fragment>
      )}

      <CheckboxGroup
        items={[
          {
            name: 'sizeWithinRange',
            label: 'Subject Property size falls within the range of improved sites within the area.',
          },
          {
            name: 'necessaryUtilitiesAvailable',
            label: 'All necessary utilities are available.',
          },
        ]}
        classes={{ root: classes.checkboxGroup }}
      />

      <GeneratedComment
        dataPath="commentaries.physicallyPossibleAsVacant"
        getGeneratedText={() => generateAsVacantPhysicallyPossibleCommentary(props)}
        isDynamicContent
        title="Generated Commentary"
        label="As Vacant Discussion"
        tooltipText={DEFAULT_TOOLTIP_TEXT}
      />
      <GeneratedComment
        dataPath="commentaries.physicallyPossibleAsImproved"
        getGeneratedText={() => generateAsImprovedPhysicallyPossibleCommentary(props)}
        isDynamicContent
        title="Generated Commentary"
        label="As Improved Discussion"
        tooltipText={DEFAULT_TOOLTIP_TEXT}
      />
    </Paper>
  )
}

export const renderFinancially = props => {
  const { classes } = props

  return (
    <Paper className={classes.paper}>
      <Stack>
        <Typography variant="h6" className={classes.sectionTitle}>
          Financially Feasible
        </Typography>
        <RadioButtonList
          name="subjectMarketCharacteristics"
          label="Subject Market Characteristics"
          items={keyValuePairsToCheckboxValues(GENERAL_IMPROVEMENT_TYPES, 'value')}
          horizontal
        />
        <RadioButtonList
          name="asVacantBestUsePropertyType"
          label="Highest and Best Use Property Type for As Vacant"
          items={keyValuePairsToCheckboxValues(GENERAL_IMPROVEMENT_TYPES, 'value')}
          horizontal
        />
        <MultipleDropDown
          name="asVacantFeasiblePropertyTypes"
          label="What are the most financially feasible property types for as vacant?"
          placeholder="Select all property types that apply"
          items={keyValuePairsToCheckboxValues(PROPERTY_TYPES, 'value')}
          itemLabelSelector={feasiblePropertyTypeLabelSelector}
          isNonPredefinedValueAllowable
        />
        <CheckboxWithLabel name="newConstructionFeasible" label="New construction is feasible." />

        <GeneratedComment
          dataPath="commentaries.financiallyFeasibleAsVacant"
          getGeneratedText={() => generateAsVacantFinanciallyFeasibleCommentary(props)}
          isDynamicContent
          title="Generated Commentary"
          label="As Vacant Discussion"
          tooltipText={DEFAULT_TOOLTIP_TEXT}
        />
        <br />
        <RadioButtonList
          name="asImprovedBestUsePropertyType"
          label="Highest and Best Use Property Type for As Improved"
          items={keyValuePairsToCheckboxValues(GENERAL_IMPROVEMENT_TYPES, 'value')}
          horizontal
        />
        <MultipleDropDown
          name="asImprovedFeasiblePropertyTypes"
          label="What are the most financially feasible property types for as improved?"
          placeholder="Select all property types that apply"
          items={keyValuePairsToCheckboxValues(PROPERTY_TYPES, 'value')}
          itemLabelSelector={feasiblePropertyTypeLabelSelector}
          isNonPredefinedValueAllowable
        />
        <CheckboxWithLabel name="demolitionFeasible" label="Demolition is an option." />
        <GeneratedComment
          dataPath="commentaries.financiallyFeasibleAsImproved"
          getGeneratedText={() => generateAsImprovedFinanciallyFeasibleCommentary(props)}
          isDynamicContent
          title="Generated Commentary"
          label="As Improved Discussion"
          tooltipText={DEFAULT_TOOLTIP_TEXT}
        />
      </Stack>
    </Paper>
  )
}

export const renderHighestUse = props => {
  const { classes, form } = props
  const {
    asVacantBestUsePropertyType,
    asImprovedBestUsePropertyType,
    asVacantFeasiblePropertyTypes,
    asImprovedFeasiblePropertyTypes,
  } = form.values

  const getPropertyTypeLabel = value => PROPERTY_TYPES[value]
  const asVacantFeasiblePropertyTypesFormatted = asVacantFeasiblePropertyTypes.map(getPropertyTypeLabel).join(', ')
  const asImprovedFeasiblePropertyTypesFormatted = asImprovedFeasiblePropertyTypes.map(getPropertyTypeLabel).join(', ')

  return (
    <Paper className={classes.paper}>
      <Typography variant="h6" className={classes.sectionTitle}>
        Highest & Best Use
      </Typography>
      <Typography variant="subtitle1">Financially Feasible As Vacant</Typography>
      <div className={classes.table}>
        <Table padding="dense" data-qa="highest-and-best-use-table">
          <TableHead>
            <TableRow>
              <TableCell>Highest & Best Use Property Type</TableCell>
              <TableCell>Most Financially Feasible Property Types</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow>
              <TableCell data-qa="as-vacant-best-use-type">{asVacantBestUsePropertyType || '-'}</TableCell>
              <TableCell data-qa="as-vacant-feasible-property-types">
                {asVacantFeasiblePropertyTypesFormatted || '-'}
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </div>

      <GeneratedComment
        dataPath="commentaries.maximallyProductiveAsVacant"
        getGeneratedText={() => generateAsVacantMaximallyProductiveCommentary(props)}
        isDynamicContent
        title="Generated Commentary"
        label="As Vacant Maximally Productive Discussion"
        tooltipText={DEFAULT_TOOLTIP_TEXT}
      />
      <GeneratedComment
        dataPath="commentaries.conclusionAsVacant"
        getGeneratedText={() => generateAsVacantConclusionCommentary(props)}
        isDynamicContent
        title="Generated Commentary"
        label="As Vacant Conclusion Discussion"
        tooltipText={DEFAULT_TOOLTIP_TEXT}
      />
      <Typography variant="subtitle1">Financially Feasible As Improved</Typography>
      <div className={classes.table}>
        <Table padding="dense" data-qa="as-improved-financially-feasible-table">
          <TableHead>
            <TableRow>
              <TableCell>Highest & Best Use Property Type</TableCell>
              <TableCell>Most Financially Feasible Property Types</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow>
              <TableCell data-qa="as-improved-best-use-type">{asImprovedBestUsePropertyType || '-'}</TableCell>
              <TableCell data-qa="as-improved-feasible-property-types">
                {asImprovedFeasiblePropertyTypesFormatted || '-'}
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </div>

      <GeneratedComment
        dataPath="commentaries.maximallyProductiveAsImproved"
        getGeneratedText={() => generateAsImprovedMaximallyProductiveCommentary(props)}
        isDynamicContent
        title="Generated Commentary"
        label="As Improved Maximally Productive Discussion"
        tooltipText={DEFAULT_TOOLTIP_TEXT}
      />

      <GeneratedComment
        dataPath="commentaries.conclusionAsImproved"
        getGeneratedText={() => generateAsImprovedConclusionCommentary(props)}
        isDynamicContent
        title="Generated Commentary"
        label="As Improved Conclusion Discussion"
        tooltipText={AS_IMPROVED_CONCLUSION_TOOLTIP_TEXT}
      />
    </Paper>
  )
}

export const renderProbableBuyer = props => {
  const { classes } = props

  return (
    <Paper className={classes.paper}>
      <Typography variant="h6" className={classes.sectionTitle}>
        Most Probable Buyer Discussion
      </Typography>
      <MultiSelectCheckboxGroup
        name="mostProbableBuyer"
        label="Who is the most probable buyer?"
        items={keyValuePairsToCheckboxValues(MOST_PROBABLE_BUYERS, 'value')}
        classes={{ root: classes.multiSelectCheckboxGroup, checkbox: classes.multiSelectCheckboxGroupCheckbox }}
      />

      <GeneratedComment
        dataPath="commentaries.probableBuyer"
        getGeneratedText={() => generateMostProbableBuyerCommentary(props)}
        isDynamicContent
        title="Generated Commentary"
        label="Most Probable Buyer Discussion"
        tooltipText={DEFAULT_TOOLTIP_TEXT}
      />
    </Paper>
  )
}
