import React from 'react'
import PropTypes from 'prop-types'

import {
  Button,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  withStyles,
  TextField,
} from '@material-ui/core'

import classnames from 'classnames'

import { calculateGrossLeasableAreaPercentage } from 'shared/calculations/rentRoll'

import { fromPercents, roundToNearestFive, toPercents } from '../../../../../../shared/utils/numberOperations'

import {
  calcTotalEstimatedAverageSF,
  calcTotalLeasableSF,
  getTotalBedroomCount,
} from '../../ResidentialProjectedRentRollSummary/tools'

import { Number } from '../../../../../../shared/components'
import { number, positive, required } from '../../../../../../shared/utils/validation'
import { formatCurrencyFloat, formatInt } from '../../../../../../shared/utils/numberFormatters'

import AlertInfo from '../../../../../../shared/components/AlertInfo'

import { Labels } from '../../ResidentialProjectedRentRollSummary/constants'

const GBA_THRESHOLD = 0.95

const Cell = withStyles(theme => ({
  cell: {
    padding: theme.spacing.unit,
  },
}))(({ classes, className, children, ...restProps }) => {
  return (
    <TableCell className={classnames(classes.cell, className)} {...restProps}>
      {children}
    </TableCell>
  )
})

const styles = theme => ({
  marginTop: {
    marginTop: theme.spacing.unit,
  },
  totalCell: theme.totalCell,
  totalCellLeading: { fontWeight: theme.totalCell.fontWeight, textAlign: 'left' },
  paper: theme.paper,
})

class RentRollAverageSizeCalculator extends React.PureComponent {
  static propTypes = {
    form: PropTypes.object.isRequired,
    units: PropTypes.arrayOf(
      PropTypes.shape({
        bedrooms: PropTypes.number,
        bathrooms: PropTypes.number,
        outdoor: PropTypes.string,
        number: PropTypes.string,
        rent: PropTypes.number,
        rentType: PropTypes.string,
        squareFootage: PropTypes.number,
      })
    ).isRequired,
    commercialArea: PropTypes.number.isRequired,
  }

  calculateUnitGroupSummaryAverageSF = () => {
    const { units, form } = this.props
    const { unitGroupSummary, grossLeasableArea, roomSizeDelta } = form.values

    const totalBedrooms = getTotalBedroomCount(units)

    const adjustedBedroomArea = totalBedrooms * roomSizeDelta
    const nonBedroomLeasableArea = grossLeasableArea - adjustedBedroomArea
    const noOfUnits = units.length
    const nonBedroomAreaPerUnit = nonBedroomLeasableArea / noOfUnits

    unitGroupSummary.forEach((groupSummary, index) => {
      const newEstimatedAverageSF =
        roundToNearestFive(nonBedroomAreaPerUnit) + groupSummary.groupingParameters.bedrooms.value * roomSizeDelta
      form.mutators.update(`unitGroupSummary`, index, {
        ...groupSummary,
        estimatedAverageSF: newEstimatedAverageSF,
      })
      form.focus(`unitGroupSummary[${index}].estimatedAverageSF`)
    })
  }

  renderUnitGroupSummary() {
    const { classes, commercialArea, form } = this.props
    const { grossLeasableArea, unitGroupSummary, buildingSummary, grossLeasableAreaPercentage } = form.values

    const totalEstimatedAverageSF = calcTotalEstimatedAverageSF(unitGroupSummary)

    const gbaHeading = commercialArea > 0 ? Labels.GRA : Labels.GLA

    return (
      <Grid container direction="column" wrap="nowrap" alignItems="flex-start">
        <Grid item xs={12}>
          <Paper className={classes.paper}>
            <Typography variant="h6">{Labels.AVERAGE_UNIT_SIZE_CALCULATOR}</Typography>
            <TextField
              name="grossResidentialArea"
              data-qa="gba-heading-input"
              label={gbaHeading}
              margin="normal"
              disabled
            />
            <Number
              name="grossLeasableAreaPercentage"
              label={Labels.GLA_PERCENTAGE}
              adornment="%"
              format={toPercents}
              normalize={fromPercents}
              decimalScale={2}
              min={0}
              max={100}
              validate={[required, number]}
              data-qa="gla-percentage-input"
            />
            <Number
              name="grossLeasableArea"
              thousandSeparator
              label={Labels.GLA}
              margin="normal"
              disabled
              data-qa="gla-input"
              suffix=" sqft"
            />
            <Number
              name="roomSizeDelta"
              label={Labels.ROOM_SIZE_DELTA}
              allowNegative={false}
              validate={[required, number, positive]}
              data-qa="room-size-delta-input"
            />
            <Button
              data-qa="calculate-averages-btn"
              variant="contained"
              color="default"
              className={classes.marginTop}
              onClick={this.calculateUnitGroupSummaryAverageSF}
            >
              {Labels.CALCULATE_AVERAGES}
            </Button>
          </Paper>
        </Grid>
        <Grid item xs={12}>
          <Paper className={classes.paper}>
            <Typography variant="h6">{Labels.RESIDENTIAL_UNIT_DISTRIBUTION_SUMMARY}</Typography>
            <Table>
              <TableHead>
                <TableRow>
                  <Cell>{Labels.UNIT_LAYOUT}</Cell>
                  <Cell>{Labels.NO_OF_UNITS}</Cell>
                  <Cell>{Labels.MIN_RENT}</Cell>
                  <Cell>{Labels.MAX_RENT}</Cell>
                  <Cell>{Labels.ANNUAL_RENT}</Cell>
                  <Cell>{Labels.AVG_RENT}</Cell>
                  <Cell>{Labels.AVG_SF}</Cell>
                </TableRow>
              </TableHead>
              <TableBody>
                {unitGroupSummary.map((unitGroup, index) => (
                  <TableRow key={unitGroup.key}>
                    <Cell data-qa={`averageUnitSFTableData-bedrooms-${index}-cell`}>{unitGroup.name}</Cell>
                    <Cell data-qa={`averageUnitSFTableData-noOfUnits-${index}-cell`}>{unitGroup.noOfUnits}</Cell>
                    <Cell data-qa={`averageUnitSFTableData-minRent-${index}-cell`}>
                      {formatCurrencyFloat(unitGroup.minRent)}
                    </Cell>
                    <Cell data-qa={`averageUnitSFTableData-maxRent-${index}-cell`}>
                      {formatCurrencyFloat(unitGroup.maxRent)}
                    </Cell>
                    <Cell data-qa={`averageUnitSFTableData-annualRent-${index}-cell`}>
                      {formatCurrencyFloat(unitGroup.annualRent)}
                    </Cell>
                    <Cell data-qa={`averageUnitSFTableData-averageRent-${index}-cell`}>
                      {formatCurrencyFloat(unitGroup.averageRent)}
                    </Cell>
                    <Cell data-qa={`averageUnitSFTableData-averageSqft-${index}-cell`}>
                      <Number
                        name={`unitGroupSummary[${index}].estimatedAverageSF`}
                        decimalScale={2}
                        allowNegative={false}
                        validate={positive}
                      />
                    </Cell>
                  </TableRow>
                ))}
                <TableRow>
                  <Cell className={classes.totalCell}>{Labels.TOTALS_AVERAGE}</Cell>
                  <Cell data-qa="averageUnitSFTableData-noOfUnits-total-cell" className={classes.totalCell} colSpan={3}>
                    {buildingSummary.noOfUnits}
                  </Cell>
                  <Cell data-qa="averageUnitSFTableData-annualRent-total-cell" className={classes.totalCell}>
                    {formatCurrencyFloat(buildingSummary.annual)}
                  </Cell>
                  <Cell data-qa="averageUnitSFTableData-averageRent-total-cell" className={classes.totalCell}>
                    {formatCurrencyFloat(buildingSummary.averageRent)}
                  </Cell>
                  <Cell data-qa="averageUnitSFTableData-averageSqft-total-cell" className={classes.totalCell}>
                    {formatInt(totalEstimatedAverageSF)} sqft
                  </Cell>
                </TableRow>
                <TableRow>
                  <Cell
                    data-qa="averageUnitSFTableData-grossLeasableAreaPercentage-cell"
                    className={classes.totalCell}
                    colSpan={6}
                  >{`${Labels.LEASABLE_AREA_FACTOR} @ ${toPercents(grossLeasableAreaPercentage)} %`}</Cell>
                  <Cell
                    data-qa="averageUnitSFTableData-grossLeasableArea-cell"
                    className={classes.totalCell}
                  >{`${formatInt(grossLeasableArea)} sqft`}</Cell>
                </TableRow>
              </TableBody>
            </Table>
          </Paper>
        </Grid>
      </Grid>
    )
  }

  renderLeasableResidentialSummary() {
    const { classes, form, units } = this.props
    const { grossResidentialArea } = form.values

    const totalLeasableSF = calcTotalLeasableSF(units)
    const grossLeasableAreaPercentage = calculateGrossLeasableAreaPercentage(totalLeasableSF, grossResidentialArea)

    return (
      <Grid container direction="column" wrap="nowrap" alignItems="flex-start">
        <Grid item xs={12}>
          <Paper className={classes.paper}>
            <Typography variant="h6">{Labels.LEASABLE_RESIDENTIAL_SUMMARY}</Typography>
            {grossLeasableAreaPercentage > GBA_THRESHOLD ? (
              <Grid item>
                <AlertInfo>
                  <Typography variant="body1">{Labels.LEASABLE_AREA_PERCENTAGE_IS_GREATER_THAN_95}</Typography>
                </AlertInfo>
              </Grid>
            ) : null}
            <Table>
              <TableHead>
                <TableRow>
                  <Cell>{Labels.LEASABLE_AREA_FACTOR}</Cell>
                  <Cell>{Labels.LEASABLE_SQUARE_FOOTAGE}</Cell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow>
                  <Cell>{`${toPercents(grossLeasableAreaPercentage)} %`}</Cell>
                  <Cell>{`${totalLeasableSF} ft`}</Cell>
                </TableRow>
              </TableBody>
            </Table>
          </Paper>
        </Grid>
      </Grid>
    )
  }

  render() {
    const { showPerUnitSF } = this.props

    return (
      <React.Fragment>
        {showPerUnitSF ? this.renderLeasableResidentialSummary() : this.renderUnitGroupSummary()}
      </React.Fragment>
    )
  }
}

export default withStyles(styles)(RentRollAverageSizeCalculator)
