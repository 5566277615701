import React from 'react'
import PropTypes from 'prop-types'
import { get } from 'lodash'
import { withStyles } from '@mui/styles'
import { Grid, Paper, Fab } from '@mui/material'
import Save from '@mui/icons-material/Save'
import { compose } from 'recompose'
import { connect } from 'react-redux'
import { Form } from 'react-final-form'

import { Typography } from 'client-shared/components/_mui5'

import arrayMutators from 'final-form-arrays'

import { cmsOrganizationUpdate } from '../redux/actions/cms'

import ShowSection from '../../shared/components/ShowSection'
import { VIEW_ONLY_TEXT } from '../../admin/constants/organization'

import styles from './styles.js'

const GRID_CONTAINER_GUTTER = 2

const withCMSForm =
  (CMSComponent, heading) =>
  ({ cmsOrganizationUpdate, classes, initialValues }) => {
    const handleSubmit = values => {
      // Remove empty entries from swot analysis
      if (values.swotAnalysis) {
        for (const property in values.swotAnalysis) {
          if (Array.isArray(values.swotAnalysis[property])) {
            values.swotAnalysis[property] = values.swotAnalysis[property].filter(elm => elm)
          }
        }
      }
      cmsOrganizationUpdate({
        formDataPath: 'settings',
        values,
      })
    }

    return (
      <React.Fragment>
        <Typography color="text.primary" className={classes.formHeader} variant="h5" sx={{ mb: 3 }}>
          {heading}
        </Typography>
        <Grid container spacing={GRID_CONTAINER_GUTTER} item xs={8} lg={8}>
          <Form onSubmit={handleSubmit} initialValues={initialValues} mutators={arrayMutators}>
            {props => (
              <form onSubmit={props.handleSubmit}>
                <ShowSection permission="cms.view">
                  <Paper className={classes.subsectionWrapper}>
                    <CMSComponent {...props} />
                  </Paper>
                </ShowSection>
                <ShowSection permission="cms.edit">
                  <Fab type="submit" className={classes.submitButton} disabled={props.invalid}>
                    <Save />
                  </Fab>
                </ShowSection>
                <ShowSection permission="!cms.edit">
                  <Fab type="submit" className={classes.submitButton} disabled="true">
                    {VIEW_ONLY_TEXT}
                  </Fab>
                </ShowSection>
              </form>
            )}
          </Form>
        </Grid>
      </React.Fragment>
    )
  }

withCMSForm.propTypes = {
  cmsOrganizationUpdate: PropTypes.func.isRequired,
  classes: PropTypes.object.isRequired,
  initialValues: PropTypes.object,
}

withCMSForm.defaultProps = {
  initialValues: null,
}

export default compose(
  connect(
    state => {
      return {
        initialValues: get(state, `cms.organizationData.settings`, {}),
      }
    },
    {
      cmsOrganizationUpdate,
    }
  ),
  withStyles(styles),
  withCMSForm
)
