import React from 'react'
import { useForm } from 'react-final-form'
import { OnChange } from 'react-final-form-listeners'
import { Typography } from '@material-ui/core'
import { getFeatureFlagValue } from '@bowery-valuation/feature-flagger-client'

import deferredMaintenance from 'shared/utils/textGeneration/property/deferredMaintenance'
import { ENABLE_AUTOMATED_INSPECTION_PREFILL } from 'shared/constants/featureFlags'
import AutomationStatus from 'client-shared/components/AutomationStatus'

import { Text, CheckboxGroup, CheckboxWithLabel, NarrativeComponent } from '../../../../shared/components'
import { keyValuePairsToCheckboxValues } from '../../../../shared/utils/ui/checkboxHelper'
import { DEFERRED_MAINTENANCE_OPTIONS } from '../../../constants'

const deferredMaintenanceOptions = keyValuePairsToCheckboxValues(DEFERRED_MAINTENANCE_OPTIONS)

const DeferredMaintenance = ({ hasDeferredMaintenance, hasOtherItems, change, validateOther, automationMetadata }) => {
  const form = useForm()
  const formValues = form.getState().values
  const automatedInspectionPrefillFlag = getFeatureFlagValue(ENABLE_AUTOMATED_INSPECTION_PREFILL)

  return (
    <React.Fragment>
      <Typography variant="h6">Deferred Maintenance</Typography>
      {automatedInspectionPrefillFlag && automationMetadata && (
        <AutomationStatus
          formPaths={['automationMetadata']}
          message="We automated the information below from Mobile Inspection app"
          sx={{ marginTop: '8px', marginBottom: '8px' }}
        />
      )}
      <CheckboxWithLabel
        label="There were items of deferred maintenance."
        name="hasDeferredMaintenance"
        margin="normal"
        data-qa="has-deferred-maintenance-checkbox"
      />

      {hasDeferredMaintenance && (
        <React.Fragment>
          <div data-qa="deferred-maintenance-checkbox-group">
            <CheckboxGroup
              header="Items of Deferred Maintenance"
              groupName="deferredMaintenanceItems"
              items={deferredMaintenanceOptions}
              horizontal
            />
            {hasOtherItems && (
              <Text
                name="otherDeferredMaintenanceItems"
                placeholder="Separate multiple items with a comma"
                label="Other"
                required
                validate={validateOther('deferredMaintenanceItems')}
              />
            )}
          </div>
          <OnChange name="deferredMaintenanceItems.other">
            {(value, prevValue) => prevValue && change('otherDeferredMaintenanceItems', null)}
          </OnChange>
        </React.Fragment>
      )}

      <NarrativeComponent
        title="Deferred Maintenance Discussion"
        name="deferredMaintenanceWriteup"
        tooltipText="The following text will appear in the Description of Improvements section of your report."
        generatedText={deferredMaintenance.generate}
        data={deferredMaintenance.mapDTO(formValues)}
      />
    </React.Fragment>
  )
}

export default DeferredMaintenance
