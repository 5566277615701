import React from 'react'

import { Grid, Paper, Typography } from '@mui/material'

import ShowSection from 'client-shared/components/ShowSection'
import { SECTIONS } from 'shared/helpers/propertyType'
import { CheckboxWithLabel } from 'client-shared/components/Checkbox'
import { Text } from 'client-shared/components'
import { getFeatureFlagValue } from '@bowery-valuation/feature-flagger-client'
import { ENABLE_AUTOMATED_INSPECTION_PREFILL } from 'shared/constants/featureFlags'
import AutomationStatus from 'client-shared/components/AutomationStatus'

const UnitAmenities = ({
  hasNoUnitAmenities,
  unit: {
    hasOtherUnitAmenity,
    hasPrivateBackyard,
    hasPrivateBalcony,
    hasPrivateDeck,
    hasPrivatePatio,
    hasPrivateRoofArea,
    hasPrivateTerrace,
    hasWasherDryer,
  },
  automationMetadata,
}) => {
  const automatedInspectionPrefillFlag = getFeatureFlagValue(ENABLE_AUTOMATED_INSPECTION_PREFILL)

  return (
    <ShowSection section={SECTIONS.HAS_RESIDENTIAL}>
      <Paper data-qa="hasNoUnitAmenities-section-tile">
        <Typography variant="h6">Unit Amenities</Typography>
        {automatedInspectionPrefillFlag && automationMetadata && (
          <AutomationStatus
            formPaths={['automationMetadata']}
            message="We automated the information below from Mobile Inspection app"
            sx={{ marginTop: '16px', marginBottom: '16px' }}
          />
        )}
        <CheckboxWithLabel name="hasNoUnitAmenities" label="The subject property has no unit amenities." />
        {!hasNoUnitAmenities && (
          <>
            <Grid container paddingTop={2}>
              <CheckboxWithLabel name="unit.hasWasherDryer" label="Washer/Dryer" />
              {hasWasherDryer && (
                <Text
                  fullWidth
                  label="Which Units Can Access This Amenity?"
                  margin="normal"
                  name="unit.washerDryerUnits"
                />
              )}
            </Grid>
            <Grid container paddingTop={2} data-qa="privatePatio-section-tile">
              <CheckboxWithLabel name="unit.hasPrivatePatio" label="Private Patio" />
              {hasPrivatePatio && (
                <Text
                  fullWidth
                  label="Which Units Can Access This Amenity?"
                  margin="normal"
                  name="unit.privatePatioUnits"
                />
              )}
            </Grid>
            <Grid container paddingTop={2} data-qa="privateBalcony-section-tile">
              <CheckboxWithLabel name="unit.hasPrivateBalcony" label="Private Balcony" />
              {hasPrivateBalcony && (
                <Text
                  fullWidth
                  label="Which Units Can Access This Amenity?"
                  margin="normal"
                  name="unit.privateBalconyUnits"
                />
              )}
            </Grid>
            <Grid container paddingTop={2} data-qa="privateTerrace-section-tile">
              <CheckboxWithLabel name="unit.hasPrivateTerrace" label="Private Terrace" />
              {hasPrivateTerrace && (
                <Text
                  fullWidth
                  label="Which Units Can Access This Amenity?"
                  margin="normal"
                  name="unit.privateTerraceUnits"
                />
              )}
            </Grid>
            <Grid container paddingTop={2} data-qa="privateRoofArea-section-tile">
              <CheckboxWithLabel name="unit.hasPrivateRoofArea" label="Private Roof Area" />
              {hasPrivateRoofArea && (
                <Text
                  fullWidth
                  label="Which Units Can Access This Amenity?"
                  margin="normal"
                  name="unit.privateRoofAreaUnits"
                />
              )}
            </Grid>
            <Grid container paddingTop={2} data-qa="privateDeck-section-tile">
              <CheckboxWithLabel name="unit.hasPrivateDeck" label="Private Deck" />
              {hasPrivateDeck && (
                <Text
                  fullWidth
                  label="Which Units Can Access This Amenity?"
                  margin="normal"
                  name="unit.privateDeckUnits"
                />
              )}
            </Grid>
            <Grid container paddingTop={2} data-qa="privateBackyard-section-tile">
              <CheckboxWithLabel name="unit.hasPrivateBackyard" label="Private Backyard" />
              {hasPrivateBackyard && (
                <Text
                  fullWidth
                  label="Which Units Can Access This Amenity?"
                  margin="normal"
                  name="unit.privateBackyardUnits"
                />
              )}
            </Grid>
            <Grid container paddingTop={2} data-qa="otherUnitAmenities-section-tile">
              <CheckboxWithLabel name="unit.hasOtherUnitAmenity" label="Other" />
              {hasOtherUnitAmenity && (
                <Text fullWidth label="Which Units Can Access This Amenity?" margin="normal" name="unit.otherUnits" />
              )}
            </Grid>
          </>
        )}
      </Paper>
    </ShowSection>
  )
}

export default UnitAmenities
