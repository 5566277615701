import React from 'react'
import PropTypes from 'prop-types'

import { Button, Grid, Typography } from '@mui/material'
import { withStyles } from '@mui/styles'
import { get, isEmpty, kebabCase, noop } from 'lodash'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import { compose, pure } from 'recompose'

import { removeAllBookmarks } from '../../../../report/redux/actions/bookmarks'
import BookmarkIndicator from '../../../components/BookmarkIndicator'
import { getReportForms } from '../../../constants/forms/reportFormsInstance'
import Bookmark from '../../../images/bookmark.png'
import { getFormName } from '../../helpers'
import EmptySection from '../EmptySection'
import SectionWrapper from '../SectionWrapper'

const styles = theme => ({
  content: {
    padding: theme.spacing(2),
  },
  item: {
    display: 'flex',
    borderRadius: 4,
    padding: '10px 12px',
    justifyContent: 'space-between',
    '&:hover': {
      backgroundColor: '#f4f4f4',
    },
  },
  title: {
    fontSize: 14,
    letterSpacing: 0.25,
    color: 'rgba(0, 0, 0, 0.54)',
  },
  icon: {
    fill: '#f57f17',
  },
  clearAllButton: {
    fontWeight: 500,
    letterSpacing: 1.25,
    color: 'rgba(0, 0, 0, 0.54)',
  },
  noBookmarksImage: {
    width: 419,
    height: 295,
    paddingRight: 36,
    paddingLeft: 36,
  },
  emptyContent: {
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
})

class BookmarksSection extends React.PureComponent {
  static propTypes = {
    bookmarkedPages: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string.isRequired,
        title: PropTypes.string.isRequired,
        link: PropTypes.string.isRequired,
      })
    ).isRequired,
    removeAllBookmarks: PropTypes.func,
  }

  static defaultProps = {
    removeAllBookmarks: noop,
  }

  render() {
    const { classes, bookmarkedPages, removeAllBookmarks } = this.props
    return (
      <SectionWrapper
        title="Bookmarked Pages"
        rightElement={
          <Button onClick={removeAllBookmarks} className={classes.clearAllButton}>
            Clear All
          </Button>
        }
      >
        {isEmpty(bookmarkedPages) ? (
          <Grid
            className={classes.emptyContent}
            container
            direction="column"
            justifyContent="center"
            alignItems="center"
          >
            <Grid item>
              <EmptySection title="This report has no bookmarked pages" classes={classes} />
            </Grid>
            <Grid item>
              <img src={Bookmark} className={classes.noBookmarksImage} alt="no-bookmarks" />
            </Grid>
          </Grid>
        ) : (
          <div className={classes.content}>
            {bookmarkedPages.map(({ id, title, link }) => (
              <Link to={link} key={title}>
                <div className={classes.item}>
                  <Typography variant="subtitle1" className={classes.title}>
                    {title}
                  </Typography>
                  <BookmarkIndicator id={id} />
                </div>
              </Link>
            ))}
          </div>
        )}
      </SectionWrapper>
    )
  }
}

export default compose(
  pure,
  connect(
    state => {
      const bookmarks = get(state, 'bookmarks.bookmarks', [])
      const reportId = get(state, 'report.reportData._id')
      const reportSettings = get(state, 'report.reportSettings')
      const bookmarkedPages = bookmarks.map(key => {
        const form = getReportForms().find(form => kebabCase(form.key) === key) || {}

        const title = getFormName(form, reportSettings)

        return { id: key, title, link: `/report/${reportId}/${key}` }
      })

      return { bookmarkedPages }
    },
    {
      removeAllBookmarks,
    }
  ),
  withStyles(styles)
)(BookmarksSection)
