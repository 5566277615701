import { getState, dispatch } from 'core/store'

export const useDispatch = () => {
  return dispatch
}

/** This does not rerun when state changes  */
export const useSelector = <T>(selector: (state: any) => T) => {
  return selector(getState())
}
