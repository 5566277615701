import { handleActions } from 'redux-actions'
import { get, mapValues, isObject } from 'lodash'

import {
  organizationFetchRequest,
  organizationFetchReceive,
  organizationFetchFail,
  organizationUpdateReceive,
  organizationUpdateFail,
  organizationClear,
  organizationFormsFetchReceive,
  organizationUsersFetchReceive,
  organizationUsersClear,
  organizationUsersUpdateReceive,
  organizationUsersDeleteReceive,
} from '../actions/organization'

const initialState = {
  usersLoaded: false,
  organizationUsers: [],
}

const getOrganizationStatusInfo = organizationStructure => {
  return mapValues(organizationStructure, value => {
    if (isObject(value)) {
      return getOrganizationStatusInfo(value)
    } else {
      return {
        showForm: value,
        isValid: false,
        isCompleted: false,
      }
    }
  })
}

export const reducer = handleActions(
  {
    [organizationFetchRequest](state) {
      return state
    },
    [organizationFetchReceive](state, { payload }) {
      const organizationInfo = getOrganizationStatusInfo(payload.organizationStructure)
      const organization = get(payload, 'organizationData')
      return {
        ...state,
        organizationData: organization,
        organizationStructure: organizationInfo,
      }
    },
    [organizationFetchFail](state) {
      return state
    },
    [organizationUpdateReceive](state, { payload }) {
      return {
        ...state,
        organizationData: payload,
      }
    },
    [organizationUpdateFail](state) {
      return state
    },
    [organizationClear](state) {
      return state
    },

    [organizationFormsFetchReceive](state, { payload }) {
      return {
        ...state,
        organizationForms: payload,
      }
    },
    [organizationUsersFetchReceive](state, { payload }) {
      return {
        ...state,
        organizationUsers: payload,
        usersLoaded: true,
      }
    },
    [organizationUsersClear](state) {
      return {
        ...state,
        organizationUsers: [],
        usersLoaded: false,
      }
    },
    [organizationUsersUpdateReceive](state, { payload }) {
      const user = get(payload, 'user', {})
      return {
        ...state,
        organizationUsers: state.organizationUsers.map(orgUser => (orgUser._id === user._id ? user : orgUser)),
      }
    },
    [organizationUsersDeleteReceive](state, { payload }) {
      return {
        ...state,
        organizationUsers: payload.users,
      }
    },
  },
  initialState
)
