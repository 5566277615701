import { createAction } from 'redux-actions'

import { CMS_ORGANIZATION_FETCH, CMS_ORGANIZATION_UPDATE } from '../actionTypes/cms'

export const cmsOrganizationFetch = createAction(CMS_ORGANIZATION_FETCH.ACTION)
export const cmsOrganizationFetchRequest = createAction(CMS_ORGANIZATION_FETCH.REQUEST)
export const cmsOrganizationFetchReceive = createAction(CMS_ORGANIZATION_FETCH.RECEIVE)
export const cmsOrganizationFetchFail = createAction(CMS_ORGANIZATION_FETCH.FAIL)

export const cmsOrganizationUpdate = createAction(CMS_ORGANIZATION_UPDATE.ACTION)
export const cmsOrganizationUpdateRequest = createAction(CMS_ORGANIZATION_UPDATE.REQUEST)
export const cmsOrganizationUpdateReceive = createAction(CMS_ORGANIZATION_UPDATE.RECEIVE)
export const cmsOrganizationUpdateFail = createAction(CMS_ORGANIZATION_UPDATE.FAIL)
