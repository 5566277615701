import React from 'react'
import PropTypes from 'prop-types'
import { Grid, withStyles } from '@material-ui/core'

import { RadioButtonList } from '../RadioButtons/RadioButtonList'

const styles = {
  formControl: {
    minHeight: 30,
    marginTop: 10,
  },
}

class ConditionGroup extends React.PureComponent {
  static propTypes = {
    classes: PropTypes.object.isRequired,
  }

  static defaultProps = {
    conditions: [],
  }

  render() {
    const { conditions, classes } = this.props

    return (
      <Grid container wrap="nowrap" direction="column" className={classes.root}>
        {conditions.map((condition, index) => {
          return (
            <Grid item xs={12} sm={12} key={index}>
              <RadioButtonList
                label={condition.label}
                name={condition.name}
                otherName={condition.otherName}
                items={condition.items}
                horizontal
              />
            </Grid>
          )
        })}
      </Grid>
    )
  }
}

const StyledConditionGroup = withStyles(styles)(ConditionGroup)

StyledConditionGroup.displayName = 'ConditionGroup'

StyledConditionGroup.propTypes = {
  conditions: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      label: PropTypes.string,
      items: PropTypes.arrayOf(
        PropTypes.shape({
          label: PropTypes.string.isRequired,
          value: PropTypes.string.isRequired,
        }).isRequired
      ).isRequired,
    })
  ),
}

export default StyledConditionGroup
