import { includes } from 'lodash'

import BoweryDate from '@bowery-valuation/bowery-date'

import { STATE_ABBREVIATIONS } from '../../../constants/states'

import { formatInt } from '../../../../client/src/shared/utils/numberFormatters'

import { LABELS, BASIS_TYPES, BASIS_LABELS } from '../../../constants/report/incomeApproach/taxes'

export const getTaxLiabilityLabel = (basis: string) => {
  return basis === BASIS_TYPES.PER_UNIT ? LABELS.TAX_LIABILITY_PER_UNIT : LABELS.TAX_LIABILITY_PSF
}

export const getBasisLabel = (basis: string, squareFootageLabel: string) => {
  return basis === BASIS_TYPES.PER_UNIT ? LABELS.NUMBER_OF_UNITS : squareFootageLabel
}

export const getPerBasisLabel = (basis: string) => {
  return basis === BASIS_TYPES.PER_UNIT ? BASIS_LABELS.PER_UNIT : BASIS_LABELS.PER_SQUARE_FOOT
}

export const has6To20PercentTaxLimitation = (taxClass: string) => taxClass === '1'
export const has8To30PercentTaxLimitation = (taxClass: string) => includes(['2A', '2B', '2C'], taxClass)

export const getTaxRateName = (taxRate: any, taxClass: any, jurisdictionName: string) => {
  if (!taxRate || !taxClass) {
    return ''
  }

  return taxClass.name
    ? `${jurisdictionName} - ${taxClass.name}, ${new BoweryDate(taxRate.effectiveDate).year}`
    : `${jurisdictionName}, ${new BoweryDate(taxRate.effectiveDate).year}`
}

export const getFullTaxClassName = (taxClass: any, jurisdictionName: string, jurisdictionState: string) => {
  if (!taxClass) {
    return ''
  }

  const formattedState = STATE_ABBREVIATIONS[taxClass.jurisdictionState || jurisdictionState]
  return `${taxClass.jurisdictionName || jurisdictionName} - ${taxClass.name}, ${formattedState}`
}

export const getActualTaxRate = (currentTaxRate: number, projectedTaxInfo: any) => {
  const includeProjectedTaxLiabilityInExport = projectedTaxInfo.includedInExport
  const overrideTaxClass = projectedTaxInfo.overrideTaxClass
  const projectedTaxRate = projectedTaxInfo.taxRate

  if (includeProjectedTaxLiabilityInExport && overrideTaxClass) {
    return projectedTaxRate || currentTaxRate
  }
  return currentTaxRate
}

export const getBasisValue = (basis: string, squareFootage: number, unitCount: number) => {
  return basis === BASIS_TYPES.PER_UNIT ? unitCount : squareFootage
}

export const getFormattedBasisValue = (basis: string, squareFootage: number, unitCount: number) => {
  return formatInt(getBasisValue(basis, squareFootage, unitCount))
}
