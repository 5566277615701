import React from 'react'
import PropTypes from 'prop-types'

import { withStyles } from '@mui/styles'
import { compose } from 'recompose'

import { HEADER_HEIGHT, SIDEBAR_MENU_WIDTH, SIDEBAR_WIDTH } from '../constants'
import layoutStyles from '../styles'

import Dashboard from './Dashboard'

const styles = theme => ({
  root: {
    display: 'flex',
    top: HEADER_HEIGHT,
    position: 'sticky',
    backgroundColor: theme.palette.common.white,
    height: `calc(100vh - ${HEADER_HEIGHT}px)`,
  },
  sidebarTabs: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    width: SIDEBAR_WIDTH,
    height: '100%',
    padding: `${theme.spacing(2)} 0 ${theme.spacing(2)} 0`,
    boxSizing: 'border-box',
    borderRight: layoutStyles.border,
  },
  sidebarMenu: {
    overflow: 'auto',
    width: SIDEBAR_MENU_WIDTH,
    borderRight: layoutStyles.border,
    scrollbarWidth: 'none',
    '&::-webkit-scrollbar': {
      width: '0',
    },
  },
})

const Sidebar = ({ classes, children, renderMenu }) => {
  const menu = renderMenu()

  return (
    <nav className={classes.root}>
      <div className={classes.sidebarTabs}>
        {children}
        <Dashboard />
      </div>
      {menu && <div className={classes.sidebarMenu}>{menu}</div>}
    </nav>
  )
}

Sidebar.propTypes = {
  renderMenu: PropTypes.func,
  children: PropTypes.node,
}

Sidebar.defaultProps = {
  renderMenu: () => null,
  children: null,
}

export default compose(React.memo, withStyles(styles))(Sidebar)
