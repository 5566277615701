import React from 'react'
import PropTypes from 'prop-types'
import { Field } from 'react-final-form'

import { DEFAULT_NUMBER_PROPS, NUMBER_PROPS, NumberFieldStyled } from '../../../../shared/components/Number/Number'

export class CensusTractNumber extends React.PureComponent {
  static propTypes = {
    ...NUMBER_PROPS,
    name: PropTypes.string.isRequired,
  }

  static defaultProps = { ...DEFAULT_NUMBER_PROPS }

  censusTractValidation = value => {
    if (!value) {
      return
    }

    const re = /^\d{4}\.\d{2}$/
    return !re.test(value) ? `Census tract should follow XXXX.XX format` : undefined
  }

  censusTractParse = value => {
    if (!value) {
      return null
    }

    return value
  }

  render() {
    const { name, ...otherProps } = this.props

    return (
      <Field
        name={name}
        parse={this.censusTractParse}
        validate={this.censusTractValidation}
        {...otherProps}
        render={props => {
          const { label, adornment, adornmentPosition, ...otherProps } = props

          return (
            <NumberFieldStyled
              {...otherProps}
              label={label}
              adornment={adornment}
              adornmentPosition={adornmentPosition}
              allowLeadingZeros
              allowNegative={false}
              decimalScale={2}
              fixedDecimalScale
              format="####.##"
              mask="_"
            />
          )
        }}
      />
    )
  }
}
