import { divide, roundToDecimal } from 'shared/utils/numberOperations'

export default {
  calculateIndicatedInsurableReplacementCost(
    replacementCostNew: number,
    exclusionsValue: number
  ): { value: number; rounded: number } {
    const value = replacementCostNew - exclusionsValue
    return {
      value,
      rounded: Math.round(value / 25000) * 25000,
    }
  },
  calculateTotalExclusionsValue(replacementCostNew: number, exclusionPercentage: number): number {
    return roundToDecimal(replacementCostNew * exclusionPercentage, 2) || 0
  },
  calculateTotalExclusionPercentage(customExclusions: number[] = [], exclusionPercentageInput: number = 0) {
    return customExclusions.length
      ? roundToDecimal(customExclusions.reduce((total, value) => total + value, 0), 2) || 0
      : exclusionPercentageInput || 0
  },
  calculateExclusionPercentage(exclusionValue: number, replacementCostNew: number): number {
    return roundToDecimal(divide(exclusionValue, replacementCostNew), 2) || 0
  },
}
