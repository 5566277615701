export const Labels = {
  RESIDENTIAL_UNIT_DISTRIBUTION_SUMMARY: 'Residential Unit Distribution Summary',
  UNIT_SUMMARY: 'UNIT SUMMARY',
  AVERAGE_UNIT_SIZE_CALCULATOR: 'Average Unit Size Calculator',
  GBA: 'GBA',
  GLA_PERCENTAGE: 'GLA %',
  GLA: 'GLA',
  GRA: 'Gross Residential Area',
  ROOM_SIZE_DELTA: 'Room Size Δ',
  CALCULATE_AVERAGES: 'Calculate Averages',
  UNIT_LAYOUT: 'Unit Type',
  NO_OF_UNITS: 'No of Units',
  MIN_RENT: 'Min. Rent',
  MAX_RENT: 'Max. Rent',
  ANNUAL_RENT: 'Annual Rent',
  AVG_RENT: 'Avg Rent',
  AVG_SF: 'Avg SF',
  TOTALS_AVERAGE: 'Totals/Average',
  LEASABLE_AREA_FACTOR: 'Leasable Area Factor',
  LEASABLE_SQUARE_FOOTAGE: 'Leasable Square Footage',
  MISSING_CONSTANT: 'Missing constant',
  GLA_IS_NOT_SET_MESSAGE:
    'You have not set a leasable residential area % constant. We use that value to calculate leasable area throughout the report.',
  GLA_SET_SUGGESTION: 'Set leasable area factor',
  INCOME_TYPE: 'Income Type',
  INCREASE: 'Increase',
  PGI: 'Potential Gross Residential Income',
  TOTAL: 'Total',
  LEASABLE_AREA_PERCENTAGE_IS_GREATER_THAN_95: 'Leasable Area percentage is greater than 95%',
  LEASABLE_RESIDENTIAL_SUMMARY: 'Leasable Residential Summary',
  SUBMIT_CONTINUE: 'Submit & Continue',
}

export const UnitGroupSummaryTableHeaders = [
  Labels.UNIT_TYPE,
  Labels.NO_OF_UNITS,
  Labels.MIN_RENT,
  Labels.MAX_RENT,
  Labels.ANNUAL_RENT,
  Labels.AVG_RENT,
  Labels.AVG_SF,
]

export const RentRollSummaryTabs = {
  SUMMARY_DATA: 'summaryData',
  DISCUSSION: 'summaryDiscussion',
}

export const RENT_ROLL_SUMMARY_TABS = [
  { label: 'Summary Data', value: RentRollSummaryTabs.SUMMARY_DATA },
  { label: 'Discussion', value: RentRollSummaryTabs.DISCUSSION },
]
