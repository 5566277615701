import { MarketConclusionUnits } from '../constants/report/incomeApproach/residential'

import { divide } from '../utils/numberOperations'
import { TIME_PERIODS } from '../constants/report/incomeApproach'

export const calculateRentPSFByTimePeriod = (monthlyRent, squareFootage, timePeriod) => {
  if (timePeriod === TIME_PERIODS.ANNUALLY) {
    const annualRent = monthlyRent * 12
    return divide(annualRent, squareFootage)
  }

  return divide(monthlyRent, squareFootage)
}

export const calculateRentsPerChosenBasis = (unit, marketRentConclusionUnit, unitRentPSFTimePeriod) => {
  if (marketRentConclusionUnit === MarketConclusionUnits.UNIT) {
    return unit.rent
  }
  return calculateRentPSFByTimePeriod(unit.rent, unit.sqft, unitRentPSFTimePeriod)
}

export const calculateGrossLeasableAreaPercentage = (totalLeasableSquareFootage, grossResidentialArea) => {
  return totalLeasableSquareFootage / grossResidentialArea
}
