import React from 'react'
import PropTypes from 'prop-types'
import { withStyles, Card, CardContent, CardHeader, Avatar, Typography } from '@material-ui/core'
import { isEmpty } from 'lodash'

import ImageUploadField from './ImageUploadField'

const styles = {
  card: {
    height: 'inherit',
    minHeight: 200,
    width: '100%',
  },
}

class ImageUploadCardField extends React.PureComponent {
  static propTypes = {
    name: PropTypes.string.isRequired,
    label: PropTypes.string,
    header: PropTypes.string,
    isRaised: PropTypes.bool,
  }
  static defaultProps = {
    label: '',
    header: '',
  }

  render() {
    const { name, classes, header, label, ...props } = this.props

    const hasHeader = !isEmpty(header)
    const hasLabel = !isEmpty(label)

    return (
      <Card className={classes.card}>
        {hasHeader && (
          <CardHeader avatar={<Avatar className={classes.avatar}>{header.charAt(0)}</Avatar>} title={header} />
        )}
        <ImageUploadField name={name} {...props} />
        {hasLabel && (
          <CardContent>
            <Typography variant="h5" component="h3">
              {label}
            </Typography>
          </CardContent>
        )}
      </Card>
    )
  }
}

export default withStyles(styles)(ImageUploadCardField)
