import { handleActions } from 'redux-actions'

import {
  buildingCompFetchRequest,
  buildingCompFetchReceive,
  buildingCompFetchFail,
  buildingCompUpdateRequest,
  buildingCompUpdateReceive,
  buildingCompUpdateFail,
  buildingCompClear,
  setBuildingComparablesInfo,
  buildingCompRentRollUploadCsvFail,
  buildingCompRentRollUploadCsvReceive,
  buildingCompRentRollUploadCsvRequest,
  clearProperties,
  searchPropertiesRequest,
  searchPropertiesReceive,
  searchPropertiesFail,
  searchNeighborhoodByBoroughReceive,
  clearNeighborhood,
  updateBuildingCompsFilters,
  setBuildingCompsMapPolygon,
  clearBuildingComp,
} from '../actions'
import { updateIn } from '../../../../shared/utils/immutability'

const initialState = {
  isLoaded: false,
  isLoading: false,
  isPropertiesLoaded: false,
  properties: [],
  filters: {
    amenities: [],
    bedroomCounts: [],
  },
  areReportBuildingCompsLoading: false,
}

export const reducer = handleActions(
  {
    [buildingCompFetchRequest](state, { payload }) {
      return {
        ...state,
        ...payload,
        isLoaded: false,
        isLoading: true,
      }
    },
    [buildingCompFetchReceive](state, { payload }) {
      return {
        ...state,
        ...payload,
        isLoaded: true,
        isLoading: false,
      }
    },
    [buildingCompFetchFail](state) {
      return {
        ...state,
        isLoading: false,
        isLoaded: false,
      }
    },
    [buildingCompUpdateRequest](state, { payload }) {
      return { ...state, isLoading: true }
    },
    [buildingCompUpdateReceive](state, { payload }) {
      return { ...state, ...payload, isLoaded: true, isLoading: false }
    },
    [buildingCompUpdateFail](state) {
      return { ...state, isLoaded: true, isLoading: false }
    },
    [buildingCompClear](state) {
      return state
    },
    [setBuildingComparablesInfo](state, { payload }) {
      return { ...state, ...payload }
    },
    [buildingCompRentRollUploadCsvRequest](state, { payload }) {
      return { ...state, isCSVLoading: true }
    },
    [buildingCompRentRollUploadCsvReceive](state, { payload: { dataPath, units } }) {
      const updatedState = { ...state, isCSVLoading: false }
      return updateIn(updatedState, [...dataPath, 'units'], currentUnits => {
        return [...units]
      })
    },
    [buildingCompRentRollUploadCsvFail](state, { payload }) {
      return { ...state, isCSVLoading: false }
    },
    [clearProperties](state) {
      return {
        ...state,
        properties: [],
        isLoaded: false,
        isPropertiesLoaded: false,
      }
    },
    [searchPropertiesRequest](state, { payload }) {
      return {
        ...state,
        isLoading: true,
      }
    },
    [searchPropertiesReceive](state, { payload }) {
      return {
        ...state,
        ...payload,
        isLoading: false,
      }
    },
    [searchPropertiesFail](state, { payload }) {
      return {
        ...state,
        isLoading: false,
      }
    },
    [searchNeighborhoodByBoroughReceive](state, { payload: { neighborhood } }) {
      return {
        ...state,
        neighborhoodByBorough: neighborhood,
      }
    },
    [clearNeighborhood](state) {
      return {
        ...state,
        neighborhoodByBorough: [],
      }
    },
    [updateBuildingCompsFilters](state, { payload }) {
      const { amenities, bedroomCounts } = payload
      return {
        ...state,
        filters: {
          amenities,
          bedroomCounts,
        },
      }
    },
    [setBuildingCompsMapPolygon](state, { payload }) {
      return {
        ...state,
        polygon: payload,
      }
    },
    [clearBuildingComp](state) {
      return { ...initialState }
    },
  },
  initialState
)
