const NYCClass1Rates = [
  {
    _id: '64ad62f8ff365d8bb69df181',
    effectiveDate: '2023-01-01T00:00:00.000Z',
    value: 0.20085,
  },
  {
    _id: '63068f817b6372661f7d1676',
    effectiveDate: '2022-01-01T00:00:00.000Z',
    value: 0.20309,
  },
  {
    _id: '613a6fde831347c0a723d813',
    effectiveDate: '2021-01-01T00:00:00.000Z',
    value: 0.19963,
  },
  {
    _id: '5eefd96aeb089ca4ab93b04b',
    effectiveDate: '2020-01-01T00:00:00.000Z',
    value: 0.21045,
  },
  {
    _id: '5ddc2a89e86073d45b467eee',
    effectiveDate: '2019-01-01T00:00:00.000Z',
    value: 0.21167,
  },
  {
    _id: '5cb61e70ec4823cd72512c2f',
    effectiveDate: '2018-01-01T00:00:00.000Z',
    value: 0.20919,
  },
  {
    _id: '5ceef88331e0750b58df0560',
    effectiveDate: '2017-01-01T00:00:00.000Z',
    value: 0.20385,
  },
  {
    _id: '5ceef88dd5ad22fa2d7e93a6',
    effectiveDate: '2016-01-01T00:00:00.000Z',
    value: 0.19991,
  },
  {
    _id: '5ceef892b6fac3f220a1aeb5',
    effectiveDate: '2015-01-01T00:00:00.000Z',
    value: 0.19554,
  },
  {
    _id: '5ceef89881c7f5f0b3d39272',
    effectiveDate: '2014-01-01T00:00:00.000Z',
    value: 0.19157,
  },
  {
    _id: '5ceef8a2b20607c14481f11f',
    effectiveDate: '2013-01-01T00:00:00.000Z',
    value: 0.19191,
  },
  {
    _id: '5ceef8ada4a00b743b879259',
    effectiveDate: '2012-01-01T00:00:00.000Z',
    value: 0.18569,
  },
  {
    _id: '5ceef8b995e4e24cd902d531',
    effectiveDate: '2011-01-01T00:00:00.000Z',
    value: 0.18205,
  },
  {
    _id: '5ceef8be0ceca4971675d182',
    effectiveDate: '2010-01-01T00:00:00.000Z',
    value: 0.17364,
  },
  {
    _id: '5ceef8ca712936f02a61139c',
    effectiveDate: '2009-01-01T00:00:00.000Z',
    value: 0.17088,
  },
  {
    _id: '5ceef8cf03bfa92b6a2c2b0d',
    effectiveDate: '2008-01-01T00:00:00.000Z',
    value: 0.16196,
  },
  {
    _id: '5ceef8d4ecb094e5328c70c1',
    effectiveDate: '2007-01-01T00:00:00.000Z',
    value: 0.15434,
  },
  {
    _id: '5ceef8d85a385a5f9a95cd98',
    effectiveDate: '2006-01-01T00:00:00.000Z',
    value: 0.16118,
  },
]

const NYCClass2Rates = [
  {
    _id: '64ad63b9ff365d8bb69df182',
    effectiveDate: '2023-01-01T00:00:00.000Z',
    value: 0.12502,
  },
  {
    _id: '63068fd47b6372661f7d1677',
    effectiveDate: '2022-01-01T00:00:00.000Z',
    value: 0.12267,
  },
  {
    _id: '613a6ff89048a31091e46b96',
    effectiveDate: '2021-01-01T00:00:00.000Z',
    value: 0.12235,
  },
  {
    _id: '5eefd9d15547cca4bccb4d21',
    effectiveDate: '2020-01-01T00:00:00.000Z',
    value: 0.12267,
  },
  {
    _id: '5ddc30b668b9d59454c3e22b',
    effectiveDate: '2019-01-01T00:00:00.000Z',
    value: 0.12473,
  },
  {
    _id: '5cb61e9141bc00d31d726c88',
    effectiveDate: '2018-01-01T00:00:00.000Z',
    value: 0.12612,
  },
  {
    _id: '5ceef8ed2ec6bb2e1b8f9562',
    effectiveDate: '2017-01-01T00:00:00.000Z',
    value: 0.12719,
  },
  {
    _id: '5ceef8f1b50635138d0a0b34',
    effectiveDate: '2016-01-01T00:00:00.000Z',
    value: 0.12892,
  },
  {
    _id: '5ceef8f5858c587f00448b3f',
    effectiveDate: '2015-01-01T00:00:00.000Z',
    value: 0.12883,
  },
  {
    _id: '5ceef8fa19e18ea4a0d045f7',
    effectiveDate: '2014-01-01T00:00:00.000Z',
    value: 0.12855,
  },
  {
    _id: '5ceef8fec5b8b883ae75823b',
    effectiveDate: '2013-01-01T00:00:00.000Z',
    value: 0.13145,
  },
  {
    _id: '5ceef902666c27409393e8e7',
    effectiveDate: '2012-01-01T00:00:00.000Z',
    value: 0.13181,
  },
  {
    _id: '5ceef90617736773dac1348d',
    effectiveDate: '2011-01-01T00:00:00.000Z',
    value: 0.13433,
  },
  {
    _id: '5ceef912745c88c19a910f69',
    effectiveDate: '2010-01-01T00:00:00.000Z',
    value: 0.13353,
  },
  {
    _id: '5ceef917d69bf23cf22ca351',
    effectiveDate: '2009-01-01T00:00:00.000Z',
    value: 0.13241,
  },
  {
    _id: '5ceef91cf6127a297d4dfcc6',
    effectiveDate: '2008-01-01T00:00:00.000Z',
    value: 0.12596,
  },
  {
    _id: '5ceef920d68437d096b8c1a6',
    effectiveDate: '2007-01-01T00:00:00.000Z',
    value: 0.11928,
  },
  {
    _id: '5ceef924ef5747e082ba9a4a',
    effectiveDate: '2006-01-01T00:00:00.000Z',
    value: 0.12737,
  },
]

const NYCClass3Rates = [
  {
    _id: '64ad63e4ff365d8bb69df183',
    effectiveDate: '2023-01-01T00:00:00.000Z',
    value: 0.12094,
  },
  {
    _id: '63068ffa7b6372661f7d1678',
    effectiveDate: '2022-01-01T00:00:00.000Z',
    value: 0.12755,
  },
  {
    _id: '613a702badc8b0b763154ca9',
    effectiveDate: '2021-01-01T00:00:00.000Z',
    value: 0.12289,
  },
  {
    _id: '5eefda098d3a5ca4c232255b',
    effectiveDate: '2020-01-01T00:00:00.000Z',
    value: 0.12826,
  },
  {
    _id: '5ddc3108cd455baee0cbb23b',
    effectiveDate: '2019-01-01T00:00:00.000Z',
    value: 0.12536,
  },
  {
    _id: '5cb61ea77a51bd60ea751e1d',
    effectiveDate: '2018-01-01T00:00:00.000Z',
    value: 0.12093,
  },
  {
    _id: '5ceef92cfbdc669e52055fa1',
    effectiveDate: '2017-01-01T00:00:00.000Z',
    value: 0.11891,
  },
  {
    _id: '5ceef930ebc9e8f4ccd46dec',
    effectiveDate: '2016-01-01T00:00:00.000Z',
    value: 0.10934,
  },
  {
    _id: '5ceef934ef6c26fb7f3da794',
    effectiveDate: '2015-01-01T00:00:00.000Z',
    value: 0.10813,
  },
  {
    _id: '5ceef9374894400da448d346',
    effectiveDate: '2014-01-01T00:00:00.000Z',
    value: 0.11125,
  },
  {
    _id: '5ceef93bda43f8e5c197c311',
    effectiveDate: '2013-01-01T00:00:00.000Z',
    value: 0.11902,
  },
  {
    _id: '5ceef94391578f90360ec595',
    effectiveDate: '2012-01-01T00:00:00.000Z',
    value: 0.12477,
  },
  {
    _id: '5ceef94c1d4b73bf7fb9a9cc',
    effectiveDate: '2011-01-01T00:00:00.000Z',
    value: 0.12473,
  },
  {
    _id: '5ceef951f7a9853b03c0b114',
    effectiveDate: '2010-01-01T00:00:00.000Z',
    value: 0.12631,
  },
  {
    _id: '5ceef955ea3233dcd58cf9d9',
    effectiveDate: '2009-01-01T00:00:00.000Z',
    value: 0.12743,
  },
  {
    _id: '5ceef95874054ad8eac24a00',
    effectiveDate: '2008-01-01T00:00:00.000Z',
    value: 0.12137,
  },
  {
    _id: '5ceef95c36c332f3bf79c33f',
    effectiveDate: '2007-01-01T00:00:00.000Z',
    value: 0.11577,
  },
  {
    _id: '5ceef95ff462317abeda30fd',
    effectiveDate: '2006-01-01T00:00:00.000Z',
    value: 0.12007,
  },
]

const NYCClass4Rates = [
  {
    _id: '64ad6407ff365d8bb69df184',
    effectiveDate: '2023-01-01T00:00:00.000Z',
    value: 0.10592,
  },
  {
    _id: '6306903e7b6372661f7d1679',
    effectiveDate: '2022-01-01T00:00:00.000Z',
    value: 0.10646,
  },
  {
    _id: '613a70407e065e7913389fb8',
    effectiveDate: '2021-01-01T00:00:00.000Z',
    value: 0.10755,
  },
  {
    _id: '5efe94dc9e2409041bbbc7b2',
    effectiveDate: '2020-01-01T00:00:00.000Z',
    value: 0.10694,
  },
  {
    _id: '5ddc311c1105d875b7a3c472',
    effectiveDate: '2019-01-01T00:00:00.000Z',
    value: 0.10537,
  },
  {
    _id: '5cb61eaecc6eacf0c10fd069',
    effectiveDate: '2018-01-01T00:00:00.000Z',
    value: 0.10514,
  },
  {
    _id: '5ceef969835d0f5e5d9ecfe9',
    effectiveDate: '2017-01-01T00:00:00.000Z',
    value: 0.10514,
  },
  {
    _id: '5ceef96dd0e1925468bb7fca',
    effectiveDate: '2016-01-01T00:00:00.000Z',
    value: 0.10574,
  },
  {
    _id: '5ceef97559decb240192042b',
    effectiveDate: '2015-01-01T00:00:00.000Z',
    value: 0.10656,
  },
  {
    _id: '5ceef979294352200b105eed',
    effectiveDate: '2014-01-01T00:00:00.000Z',
    value: 0.10684,
  },
  {
    _id: '5ceef97ed5efbf6133fc6ea0',
    effectiveDate: '2013-01-01T00:00:00.000Z',
    value: 0.10323,
  },
  {
    _id: '5ceef982c8bac7644200548f',
    effectiveDate: '2012-01-01T00:00:00.000Z',
    value: 0.10288,
  },
  {
    _id: '5ceef98dec4346d37ea64d1c',
    effectiveDate: '2011-01-01T00:00:00.000Z',
    value: 0.10152,
  },
  {
    _id: '5ceef9910f48e9166f25d11a',
    effectiveDate: '2010-01-01T00:00:00.000Z',
    value: 0.10312,
  },
  {
    _id: '5ceef998f6f73f17a0f4aa14',
    effectiveDate: '2009-01-01T00:00:00.000Z',
    value: 0.10426,
  },
  {
    _id: '5ceef99d3c59a41f1b41f370',
    effectiveDate: '2008-01-01T00:00:00.000Z',
    value: 0.10241,
  },
  {
    _id: '5ceef9a0a8195ec4712ce896',
    effectiveDate: '2007-01-01T00:00:00.000Z',
    value: 0.10059,
  },
  {
    _id: '5ceef9a52d88d33baaf1f756',
    effectiveDate: '2006-01-01T00:00:00.000Z',
    value: 0.10997,
  },
]

export const TAX_JURISDICTION_NYC = {
  state: 'New York',
  name: 'New York City',

  classes: [
    {
      name: 'Class 1',
      lookupValue: '1',
      rateHistory: NYCClass1Rates,
    },
    {
      name: 'Class 1A',
      lookupValue: '1A',
      rateHistory: NYCClass1Rates,
    },
    {
      name: 'Class 1B',
      lookupValue: '1B',
      rateHistory: NYCClass1Rates,
    },
    {
      name: 'Class 1C',
      lookupValue: '1C',
      rateHistory: NYCClass1Rates,
    },
    {
      name: 'Class 1D',
      lookupValue: '1D',
      rateHistory: NYCClass1Rates,
    },
    {
      name: 'Class 2',
      lookupValue: '2',
      rateHistory: NYCClass2Rates,
    },
    {
      name: 'Class 2A',
      lookupValue: '2A',
      rateHistory: NYCClass2Rates,
    },
    {
      name: 'Class 2B',
      lookupValue: '2B',
      rateHistory: NYCClass2Rates,
    },
    {
      name: 'Class 2C',
      lookupValue: '2C',
      rateHistory: NYCClass2Rates,
    },
    {
      name: 'Class 3',
      lookupValue: '3',
      rateHistory: NYCClass3Rates,
    },
    {
      name: 'Class 4',
      lookupValue: '4',
      rateHistory: NYCClass4Rates,
    },
  ],

  assessmentDistricts: [
    {
      name: 'Bronx',
      lookupValue: 'BX',
      lastReassessmentDate: '2019-02-26T00:00:00.000Z',
      plannedReassessment: false,
      equalizationRate: 0.2118,
    },
    {
      name: 'Kings (Brooklyn)',
      lookupValue: 'BK',
      lastReassessmentDate: '2019-02-26T00:00:00.000Z',
      plannedReassessment: false,
      equalizationRate: 0.1125,
    },
    {
      name: 'New York (Manhattan)',
      lookupValue: 'MN',
      lastReassessmentDate: '2019-02-26T00:00:00.000Z',
      plannedReassessment: false,
      equalizationRate: 0.3445,
    },
    {
      name: 'Queens',
      lookupValue: 'QN',
      lastReassessmentDate: '2019-02-26T00:00:00.000Z',
      plannedReassessment: false,
      equalizationRate: 0.1268,
    },
    {
      name: 'Richmond (Staten Island)',
      lookupValue: 'SI',
      lastReassessmentDate: '2019-02-26T00:00:00.000Z',
      plannedReassessment: false,
      equalizationRate: 0.949,
    },
    {
      name: 'New York City',
      lookupValue: null,
      lastReassessmentDate: '2019-02-26T00:00:00.000Z',
      plannedReassessment: false,
      equalizationRate: 0.2099,
    },
  ],
}
