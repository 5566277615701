import React from 'react'
import PropTypes from 'prop-types'
import { IconButton, CardMedia, CircularProgress, withStyles } from '@material-ui/core'
import DeleteOutline from '@mui/icons-material/DeleteOutline'
import Rotate from '@mui/icons-material/Rotate90DegreesCcwOutlined'
import CloudUpload from '@mui/icons-material/CloudUpload'

import { getResizedImageUrl, getRotatedImageUrl } from '../../utils/cloudinary'
import Tooltip from '../Tooltip'

const styles = theme => ({
  root: {
    backgroundColor: 'rgba(0,0,0,0.15)',
    flex: 1,
    display: 'flex',
    height: 'inherit',
    justifyContent: 'flex-end',
    '&:hover': {
      cursor: 'pointer',
      '& $buttonsContainer': {
        visibility: 'initial',
      },
    },
  },
  buttonsContainer: {
    visibility: 'hidden',
    alignSelf: 'flex-end',
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%',
    background: 'linear-gradient(to top, rgba(0,0,0,0.7) 0%, rgba(0,0,0,0.3) 70%, rgba(0,0,0,0) 100%)',
  },
  button: {
    margin: 0,
    color: theme.palette.common.white,
  },
  overImageIcon: {
    color: theme.palette.common.white,
  },
  loader: {
    flex: 1,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: 'rgba(0,0,0,0.15)',
  },
})

const ROTATION_TOOLTIP_TEXT = 'Rotate 90°'
const DELETE_TOOLTIP_TEXT = 'Delete'
const UPLOAD_TOOLTIP_TEXT = 'Upload'

class ImageItem extends React.PureComponent {
  static propTypes = {
    index: PropTypes.number,
    url: PropTypes.string,
    onDelete: PropTypes.func.isRequired,
    onClick: PropTypes.func,
    onRotate: PropTypes.func.isRequired,
    onUpload: PropTypes.func,
    isLoading: PropTypes.bool,
    previewWidth: PropTypes.number,
    readOnly: PropTypes.bool,
    shouldDisplayUploadIcon: PropTypes.bool,
  }

  static defaultProps = {
    previewWidth: 300,
    index: -1,
    url: '',
    isLoading: false,
    onClick: () => {},
    readOnly: false,
    shouldDisplayUploadIcon: true,
  }

  get previewUrl() {
    const { url, previewWidth } = this.props
    return getResizedImageUrl({ url, width: previewWidth })
  }

  onClick = event => {
    const { index } = this.props
    this.props.onClick({ index })
  }

  onRotate = event => {
    event.stopPropagation()
    const { url, index } = this.props
    this.props.onRotate({ newUrl: getRotatedImageUrl({ url }), index })
  }

  onDelete = event => {
    event.stopPropagation()
    const { index } = this.props
    this.props.onDelete({ index })
  }

  onUpload = event => {
    event.stopPropagation()
    const { index } = this.props
    this.props.onUpload({ index })
  }

  render() {
    const { index, classes, isLoading, onUpload, readOnly, shouldDisplayUploadIcon } = this.props

    return (
      <CardMedia onClick={this.onClick} className={classes.root} image={this.previewUrl}>
        {isLoading ? (
          <div className={classes.loader}>
            <CircularProgress className={classes.overImageIcon} />
          </div>
        ) : (
          <div className={classes.buttonsContainer}>
            {!readOnly && (
              <Tooltip placement="bottom" text={ROTATION_TOOLTIP_TEXT}>
                <IconButton
                  aria-label="rotate"
                  data-qa={`${index}-image-rotate-btn`}
                  onClick={this.onRotate}
                  className={classes.button}
                >
                  <Rotate />
                </IconButton>
              </Tooltip>
            )}
            {shouldDisplayUploadIcon && onUpload && !readOnly && (
              <Tooltip placement="bottom" text={UPLOAD_TOOLTIP_TEXT}>
                <IconButton
                  aria-label="upload"
                  data-qa={`${index}-image-upload-btn`}
                  onClick={this.onUpload}
                  className={classes.button}
                >
                  <CloudUpload />
                </IconButton>
              </Tooltip>
            )}
            {!readOnly && (
              <Tooltip placement="bottom" text={DELETE_TOOLTIP_TEXT}>
                <IconButton
                  aria-label="delete"
                  data-qa={`${index}-image-remove-btn`}
                  onClick={this.onDelete}
                  className={classes.button}
                >
                  <DeleteOutline />
                </IconButton>
              </Tooltip>
            )}
          </div>
        )}
      </CardMedia>
    )
  }
}

export default withStyles(styles)(ImageItem)
