import { LAUNDRY_INCOME_PATH } from 'shared/constants/report/keysAndDataPaths'

import { LaundryIncomeTypes } from '../../../../../../../shared/constants/report/incomeApproach/miscellaneous'

import { objectToKeyValuePairs } from '../../../../../shared/utils/ui/checkboxHelper'

export const DATA_PATH = LAUNDRY_INCOME_PATH
export const HEADING = 'Laundry'

export const WARNING_TEXT = `You've indicated that the subject has no laundry room.`
export const WARNING_LINK = './amenities'

export const LaundryVCLossTypes = {
  RESIDENTIAL: 'Residential',
  LAUNDRY_VC: 'Laundry V/C',
}
export const LAUNDRY_VC_LOSS_OPTIONS = objectToKeyValuePairs(LaundryVCLossTypes)

export const OwnerOrForecastTypes = {
  OWNER: 'Owner Reported',
  FORECAST: 'Forecast',
}
export const OWNER_OR_FORECAST_OPTIONS = objectToKeyValuePairs(OwnerOrForecastTypes)

export const LAUNDRY_INCOME_OPTIONS = objectToKeyValuePairs(LaundryIncomeTypes)
