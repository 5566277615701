import { isNil, isNumber } from 'lodash'

export const isNumeric = (value: unknown): boolean => {
  if (typeof value === 'number') {
    return !Number.isNaN(value)
  }

  if (typeof value === 'string') {
    return !Number.isNaN(Number(value)) && value.trim() !== ''
  }

  return false
}

export const isValidDivisor = (value: any) => isNumber(value) && value !== 0

/* Use when the divisor can be 0, null, or other value that may produce an invalid quotient */
export const divide = (dividend: number, divisor: any) => {
  if (isValidDivisor(divisor)) {
    return dividend / divisor
  }
  return 0
}

export const roundToTwoDecimals = (value: number) => Math.round((value + Number.EPSILON) * 100) / 100

export const roundToFourDecimals = (value: number) => Math.round((value + Number.EPSILON) * 10000) / 10000

export const roundToDecimal = (value: number, decimalPlaces: number) => {
  if (value === 0 || !decimalPlaces) {
    return 0
  }
  const multipleOfTen = 10 ** decimalPlaces
  return divide(Math.round((value + Number.EPSILON) * multipleOfTen), multipleOfTen)
}

export const roundToNearest = (value: number, nearestNumber: number) => {
  if (!value || !nearestNumber) {
    return 0
  }
  return Math.round(value / nearestNumber) * nearestNumber
}

export const roundDownToNearest = (value: number, nearestNumber: number) => {
  return Math.floor(divide(value, nearestNumber)) * nearestNumber
}

export const fromCurrency = (value: number | string) => {
  if (isNil(value) || value === '') {
    return 0
  }
  if (isNumber(value)) {
    return value
  }

  return Number(value.replace(/[^0-9.-]+/g, '')) || 0
}

export const precisionRound = (number: number, precision: number) => {
  const factor = 10 ** precision
  return Math.round(number * factor) / factor
}

export const toPercents = (value: number | '', decimalPlaces = 2) => {
  if (isNil(value) || value === '') {
    return null
  }

  return precisionRound(value * 100, decimalPlaces)
}

export const toValidPercents = (value: number | '', decimalPlaces = 2) => {
  const percents = toPercents(value, decimalPlaces)
  return percents ? `${percents}%` : 'N/A'
}
