import React from 'react'
import PropTypes from 'prop-types'
import { compose, pure } from 'recompose'
import SearchIcon from '@mui/icons-material/Search'
import { OutlinedInput, withStyles } from '@material-ui/core'

const styles = theme => ({
  inputRoot: {
    width: '100%',
    height: 49,
    '& $notchedOutline': {
      borderColor: 'transparent',
    },
    fontSize: 14,
    lineHeight: 1.43,
    letterSpacing: 0.3,
    color: 'rgba(0, 0, 0, 0.87)',
  },
  searchIcon: {
    height: 20,
    width: 20,
  },
  notchedOutline: {},
  focusedInput: {
    '& $searchIcon': {
      color: theme.palette.primary[900],
    },
  },
})

const TextFilterInput = ({ classes, name, value, onChange }) => {
  return (
    <OutlinedInput
      placeholder="All"
      value={value}
      name={name}
      startAdornment={<SearchIcon className={classes.searchIcon} />}
      classes={{ root: classes.inputRoot, notchedOutline: classes.notchedOutline, focused: classes.focusedInput }}
      onChange={onChange}
      margin="none"
      labelWidth={0}
    />
  )
}

TextFilterInput.propTypes = {
  name: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
}

export default compose(pure, withStyles(styles))(TextFilterInput)
