import * as React from 'react'
import PropTypes from 'prop-types'

import { Field as FinalFormField } from 'react-final-form'
import { FormLabel, FormControl, FormGroup } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'

const styles = theme => ({
  formControl: {
    width: '100%',
  },
  formLegend: {
    textAlign: 'left',
    paddingBottom: 8,
  },
})

function Img({ input, label, classes, ...props }) {
  return (
    <div className={classes.root}>
      <FormControl className={classes.formControl} disabled>
        <FormLabel className={classes.formLegend} component="legend">
          {label}
        </FormLabel>
        <FormGroup>
          <img className={classes.img} src={input.value} alt={input.name} />
        </FormGroup>
      </FormControl>
    </div>
  )
}

const ImageWithStyle = withStyles(styles)(Img)

export function ImageWithLabel({ name, label, ...props }) {
  return <FinalFormField name={name} label={label} component={ImageWithStyle} {...props} />
}

Image.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
}
