import React from 'react'
import PropTypes from 'prop-types'

import { FieldArray } from 'react-final-form-arrays'
import { isArray, isEmpty, uniqueId, get } from 'lodash'

import { Box, Card, CardContent, Grid, LinearProgress, Typography } from '@mui/material'
import InfoOutlined from '@mui/icons-material/InfoOutlined'

import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd'
import { connect } from 'react-redux'

import {
  DEFAULT_UPLOAD_TO_RESPONSE_TIME_RATIO,
  DEFAULT_FINISHING_THRESHOLD,
} from 'client-shared/components/FileSelection/constants'
import * as Socket from 'client-shared/utils/socket'
import FileSelectionV2 from 'client-shared/components/FileSelection/FileSelectionV2'
import ImageListCategory from 'client-shared/components/ImageList/ImageListCategory'
import { errorNotification } from 'client-shared/redux/actions/notifications'
import { updateAreFilesUploading } from 'client-shared/redux/actions/areFilesUploading'

import { PROVIDED_DOCUMENT_TYPE, PROVIDED_DOCUMENT_TEMPLATE } from 'shared/constants/files'
import { uploadFile } from 'core/api'

import Dropzone from './Dropzone'

const styles = {
  documentList: {
    padding: '10px 0px',
  },
  documentItem: {
    padding: '0px',
    marginTop: '10px',
    boxShadow: 2,
  },
  cardContent: {
    padding: '10px',
    height: '100px',
  },
  typography: {
    fontSize: '14px',
    paddingTop: '8px',
    paddingBottom: '12px',
    fontFamily: 'Nunito Sans',
  },
  errorDisplay: {
    fontFamily: 'Nunito Sans',
    paddingLeft: '5px',
    color: 'error.main',
    fontSize: '12px',
  },
}

const acceptedFileTypes = [
  'application/pdf',
  'application/msword',
  'application/vnd.ms-excel',
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  'application/vnd.ms-excel.sheet.macroEnabled.12',
  '.docx',
  'image/jpeg',
  'image/png',
]

const PDF_PROPS = {
  cancelEndpoint: 'pdfs/cancel',
  template: PROVIDED_DOCUMENT_TEMPLATE,
  uploadEndpoint: 'pdfs/convert',
}

const EXCEL_PROPS = {
  cancelEndpoint: 'excel/cancel',
  template: PROVIDED_DOCUMENT_TEMPLATE,
  uploadEndpoint: 'excel/convert',
}

const WORD_PROPS = {
  uploadEndpoint: 'files/upload',
}

const FileTypeMimeTypeMap = {
  doc: 'application/msword',
  docx: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  xls: 'application/vnd.ms-excel',
  xlsx: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  xlsm: 'application/vnd.ms-excel.sheet.macroEnabled.12',
  jpeg: 'image/jpeg',
  pdf: 'application/pdf',
  png: 'image/png',
}

const fileTypePropsEnum = {
  xls: EXCEL_PROPS,
  xlsx: EXCEL_PROPS,
  xlsm: EXCEL_PROPS,
  docx: WORD_PROPS,
  doc: WORD_PROPS,
  pdf: PDF_PROPS,

  // we're using PDF_PROPS because we want to convert images
  jpeg: PDF_PROPS,
  png: PDF_PROPS,
}

const progressBarSuccessDelay = 400
const fileExtensionRegex = /\.[^/.]+$/

class ProvidedDocumentUpload extends React.PureComponent {
  state = {
    filesForUpload: [],
  }

  progressIntervals = []

  componentDidMount() {
    Socket.on('uploadFile:providedDocs:success', this.onUploadFileSuccess)
    Socket.on('uploadFile:providedDocs:error', this.onUploadFileError)
  }

  componentWillUnmount() {
    Socket.off('uploadFile:providedDocs:success', this.onUploadFileSuccess)
    Socket.off('uploadFile:providedDocs:error', this.onUploadFileError)
  }

  onUploadFileSuccess = uploadedFile => {
    const index = this.getFileIndex(uploadedFile)
    const file = this.props.fields.value[index]

    this.props.fields.update(index, {
      ...file,
      percent: 100,
    })

    const mongoId = uploadedFile._id
    setTimeout(() => {
      const { fileName, name } = file
      this.props.fields.update(index, { fileName, id: mongoId, name })
    }, progressBarSuccessDelay)

    this.updateAreFilesUploading()
  }

  updateAreFilesUploading() {
    const { fields, updateAreFilesUploading } = this.props
    const numberOfFilesUploading = fields.value.filter(file => file.percent < 100).length

    if (numberOfFilesUploading === 0) {
      updateAreFilesUploading({ areFilesUploading: false })
    }
  }

  getFileIndex = file => this.props.fields.value.findIndex(element => element.id === file.id)

  onUploadFileError = (errorMessage, fileId) => {
    const errorMessageUndefined = !errorMessage
    if (errorMessageUndefined) {
      errorMessage = 'Replace or delete file to try again.'
    }
    const index = this.getFileIndex({ id: fileId })
    const file = this.props.fields.value[index]

    const nonMongoId = uniqueId()
    this.props.fields.update(index, {
      ...file,
      errorText: `File Upload Error. ${errorMessage}`,
      id: nonMongoId,
      percent: 100,
    })

    this.updateAreFilesUploading()
  }

  getFileType = fileExtension => {
    for (const key in FileTypeMimeTypeMap) {
      if (FileTypeMimeTypeMap[key] === fileExtension) {
        return key
      }
    }
  }

  getFileTypeProps = type => {
    if (fileTypePropsEnum[type]) {
      return fileTypePropsEnum[type]
    } else {
      return PDF_PROPS
    }
  }

  handleInsertion = file => {
    this.props.fields.push(file)
  }

  handleDelete = index => {
    const { fields } = this.props
    fields.remove(index)
  }

  handleCategorySave = ({ newCategory, id }) => {
    const { fields } = this.props
    const index = fields.value.findIndex(file => file.id === id)
    fields.update(index, { ...fields.value[index], name: newCategory })
  }

  exceedsMaxSize = file => {
    const { maxUploadSizeMB } = this.props
    if (!!maxUploadSizeMB && file.uploadFileInfo.size > maxUploadSizeMB * 1000000) {
      this.onUploadFileError(
        `File upload limit is ${maxUploadSizeMB} MB or 150 pages for PDF.` +
          ` To upload larger files, please reduce file size.`,
        file.id
      )
      return true
    }
  }

  onDragEnd = ({ source, destination }) => {
    if (destination) {
      this.props.fields.move(source.index, destination.index)
    }
  }

  onDrop = draggedFiles => {
    if (this.props.fields.length + draggedFiles.length > 10) {
      this.props.errorNotification('File limit exceeded. You can upload a maximum of 10 separate files.')
      return
    }
    if (isArray(draggedFiles) && !isEmpty(draggedFiles)) {
      const filesForUpload = draggedFiles
        .map(file => {
          const fileName = file.name
          const name = fileName.replace(fileExtensionRegex, '')
          const placeholderMongoId = uniqueId()

          const fileForUpload = {
            fileName,
            id: placeholderMongoId,
            name,
            percent: 0,
            progressBar: true,
            uploadFileInfo: file,
          }

          this.handleInsertion(fileForUpload)

          if (this.exceedsMaxSize(fileForUpload)) {
            return null
          }

          return fileForUpload
        })
        .filter(file => !!file)

      this.setState({
        filesForUpload,
      })

      if (filesForUpload?.length) {
        this.uploadFiles()
      }
    }
  }

  // Async function ensures state updates in the correct order. onDrop (above) works without async.
  replaceFile = async (file, index) => {
    const fileName = file.name
    const name = fileName.replace(fileExtensionRegex, '')
    const placeholderMongoId = uniqueId()

    const fileForUpload = {
      fileName,
      id: placeholderMongoId,
      name,
      percent: 0,
      progressBar: true,
      uploadFileInfo: file,
    }
    await this.props.fields.update(index, { ...fileForUpload })

    const tooLarge = this.exceedsMaxSize(fileForUpload)
    if (!tooLarge) {
      this.setState(
        () => ({ filesForUpload: [fileForUpload] }),
        () => {
          this.uploadFiles()
        }
      )
    }
  }

  uploadFiles = () => {
    const { filesForUpload } = this.state
    const { parentId, reportId } = this.props
    this.props.updateAreFilesUploading({ areFilesUploading: true })

    const promises = filesForUpload.map(async file => {
      const fileType = this.getFileType(file.uploadFileInfo.type)
      const { uploadEndpoint, template } = this.getFileTypeProps(fileType)

      const onUploadProgress = event => {
        this.onUploadProgress(event, file)
      }

      try {
        await uploadFile(file, PROVIDED_DOCUMENT_TYPE, uploadEndpoint, parentId, reportId, onUploadProgress, template)
      } catch (error) {
        console.error('error:', error)
        this.onUploadFileError(error.message, file.id)
      }
    })
    Promise.all(promises)
  }

  onUploadProgress = (event, file) => {
    const index = this.getFileIndex(file)
    const uploadProgress =
      (event.lengthComputable ? (event.loaded / event.total) * 100 : 0) * DEFAULT_UPLOAD_TO_RESPONSE_TIME_RATIO
    if (uploadProgress === 100 * DEFAULT_UPLOAD_TO_RESPONSE_TIME_RATIO && !this.progressIntervals[index]) {
      this.progressIntervals[index] = setInterval(() => this.waitForUploadFinish(index, file), 500)
    } else {
      this.props.fields.update(index, { ...file, percent: uploadProgress })
    }
  }

  waitForUploadFinish = (index, file) => {
    const uploadProgress = this.props.fields.value[index].percent
    const progressBar = this.props.fields.value[index].progressBar
    if (uploadProgress < DEFAULT_FINISHING_THRESHOLD && progressBar) {
      this.props.fields.update(index, { ...file, percent: uploadProgress + 1 })
    } else {
      clearInterval(this.progressIntervals[index])
      this.progressIntervals[index] = undefined
    }
  }

  render() {
    const { parentId, fields } = this.props
    const acceptedFileTypeString = 'PDF, JPEG, PNG, DOCX, XLS, XLSX, XLSM'

    return (
      <Grid>
        <Typography variant="h6">Provided Document Upload</Typography>
        <Grid>
          <Typography sx={styles.typography}>
            Any uploaded provided documents will export in the Addenda of the report, following the Rent Roll &
            Financial Statements. Drag and drop uploaded documents in the order they should appear in the addenda. You
            can upload a maximum of 10 separate files. File size cannot exceed 10MB or 150 pages for PDF.
          </Typography>
        </Grid>
        <Dropzone
          accept={acceptedFileTypes}
          acceptedFileTypeString={acceptedFileTypeString}
          multiple
          onDrop={this.onDrop}
        />
        <Grid sx={styles.documentList}>
          <DragDropContext onDragEnd={this.onDragEnd}>
            <Droppable droppableId="droppable">
              {provided => (
                <div {...provided.droppableProps} ref={provided.innerRef}>
                  {fields.value.map((document, index) => {
                    const fileExtension = document.fileName.split('.').slice(-1)
                    const fileType = this.getFileType(fileExtension)
                    const fileProps = this.getFileTypeProps(fileType)
                    const isError = !!document.errorText
                    const showProgressBar = !!document.progressBar
                    const isDisabled = showProgressBar && document.percent < 100
                    const opacity = isDisabled ? { opacity: 0.5 } : {}
                    const color = isError ? 'error' : 'primary'
                    return (
                      <Draggable
                        key={document.id}
                        draggableId={document.id}
                        index={index}
                        isDragDisabled={this.props.areFilesUploading}
                      >
                        {provided => (
                          <div ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
                            <Card sx={styles.documentItem}>
                              <CardContent sx={styles.cardContent} style={opacity}>
                                <Grid container direction="row" wrap="nowrap">
                                  <ImageListCategory
                                    name={`${fields.name}[${index}].name`}
                                    category={document.name}
                                    deletable={false}
                                    errorText="File Name is Required!"
                                    id={document.id}
                                    isDisabled={isDisabled}
                                    onCategorySave={this.handleCategorySave}
                                  />
                                  {document.errorText && (
                                    <Grid container direction="row" wrap="nowrap" alignItems="center">
                                      <InfoOutlined fontSize="small" color="error" />
                                      <Box component="span" sx={styles.errorDisplay}>
                                        {document.errorText}
                                      </Box>
                                    </Grid>
                                  )}
                                </Grid>
                                <FileSelectionV2
                                  acceptedFileTypes={acceptedFileTypes}
                                  cancelEndpoint={fileProps.cancelEndpoint}
                                  index={index}
                                  isDisabled={isDisabled}
                                  label="Document"
                                  name={`${fields.name}[${index}]`}
                                  onDelete={this.handleDelete}
                                  parentId={parentId}
                                  replaceFile={this.replaceFile}
                                  template={fileProps.template}
                                  uploadEndpoint={fileProps.uploadEndpoint}
                                />
                              </CardContent>
                              {showProgressBar && (
                                <LinearProgress color={color} value={document.percent} variant="determinate" />
                              )}
                            </Card>
                          </div>
                        )}
                      </Draggable>
                    )
                  })}
                  {provided.placeholder}
                </div>
              )}
            </Droppable>
          </DragDropContext>
        </Grid>
      </Grid>
    )
  }
}

const FinalFormProvidedDocumentUpload = props => {
  return <FieldArray name="providedDocuments" component={ProvidedDocumentUpload} {...props} />
}

FinalFormProvidedDocumentUpload.propTypes = {
  parentId: PropTypes.string.isRequired,
  maxUploadSizeMB: PropTypes.number,
  areFilesUploading: PropTypes.bool,
  updateAreFilesUploading: PropTypes.func.isRequired,
  errorNotification: PropTypes.func.isRequired,
}

ProvidedDocumentUpload.propTypes = {
  ...FinalFormProvidedDocumentUpload.propTypes,
  fields: PropTypes.shape({
    value: PropTypes.arrayOf(PropTypes.any).isRequired,
    update: PropTypes.func.isRequired,
    move: PropTypes.func.isRequired,
    remove: PropTypes.func.isRequired,
    length: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
  }),
}

const mapDispatchToProps = dispatch => ({
  errorNotification: error => dispatch(errorNotification({ message: error })),
  updateAreFilesUploading: areFilesUploading => dispatch(updateAreFilesUploading(areFilesUploading)),
})

export default connect(state => {
  return {
    areFilesUploading: get(state, 'shared.areFilesUploading'),
    reportId: get(state, 'report.reportData._id'),
  }
}, mapDispatchToProps)(FinalFormProvidedDocumentUpload)
