import React from 'react'
import PropTypes from 'prop-types'

import { get } from 'lodash'
import { FormLabel, Grid, Paper, Stack, Table, TableBody, TableCell, TableHead, TableRow } from '@mui/material'

import { CalloutButton, GeneratedComment, Number as NumberField, RadioButtonList } from 'client-shared/components'
import { generateStorageIncomeDiscussion } from 'shared/utils/textGeneration/incomeApproach/miscellaneous/storage'
import { toPercentageString, formatCurrencyFloat } from 'client-shared/utils/numberFormatters'

import { fromPercents, toPercents } from 'client-shared/utils/numberOperations'
import { PropertyTypes } from 'shared/constants'

import wrapForm from '../../../wrapForm'
import { ANNUAL_MULTIPLIER, MAX_VC_LOSS_PERCENTAGE_VALUE } from '../constants'

import {
  DATA_PATH,
  HEADING,
  INCOME_OPTIONS,
  IN_PLACE_OR_FORECAST_OPTIONS,
  InPlaceOrForecastTypes,
  StorageIncomeTypes,
  StorageVCLossTypes,
  VC_LOSS_OPTIONS,
  VC_LOSS_OPTIONS_COMMERCIAL,
  WARNING_TEXT,
} from './constants'
import { storageVCLossWatcher } from './decorators'

class StorageIncome extends React.PureComponent {
  calculateTableData = () => {
    const { form, unitCount } = this.props

    const { storageIncome = 0, storageIncomeType = StorageIncomeTypes.ANNUAL, vcLossPercentage = 0 } = form.values

    let annualStorageIncome = 0
    let vcLossValue = 0

    if (storageIncome) {
      annualStorageIncome = storageIncome
      if (storageIncomeType === StorageIncomeTypes.MONTHLY_PER_UNIT) {
        annualStorageIncome = storageIncome * unitCount * ANNUAL_MULTIPLIER
      }
      vcLossValue = vcLossPercentage * annualStorageIncome
    }

    const storageIncomeAfterVCLoss = annualStorageIncome - vcLossValue

    return { vcLossValue, storageIncomeAfterVCLoss, annualStorageIncome }
  }

  generateStorageIncomeDiscussion = () => {
    const { form, unitCount } = this.props
    const { inPlaceOrForecast, storageIncome, storageIncomeType } = form.values

    const isForecastType = inPlaceOrForecast && inPlaceOrForecast === InPlaceOrForecastTypes.FORECAST
    const isAnnual = storageIncomeType && storageIncomeType === StorageIncomeTypes.ANNUAL

    return generateStorageIncomeDiscussion({ unitCount, isForecastType, isAnnual, storageIncome })
  }

  render() {
    const { form, hasNoAmenities, hasStorageUnits, propertyType } = this.props
    const { storageVCLossType, vcLossPercentage } = form.values

    const isResidentialLossType = storageVCLossType === StorageVCLossTypes.RESIDENTIAL
    const { vcLossValue, storageIncomeAfterVCLoss, annualStorageIncome } = this.calculateTableData()
    const displaySettings = !hasNoAmenities && hasStorageUnits

    return (
      <Grid container spacing={4}>
        <Grid item xs={6}>
          {!displaySettings && (
            <CalloutButton
              link="./amenities"
              linkText="Change"
              qa="no-storage-room-callout-btn"
              text={WARNING_TEXT}
              variant="warn"
            />
          )}
          {displaySettings && (
            <Paper>
              <Stack spacing={1}>
                <RadioButtonList
                  horizontal
                  items={INCOME_OPTIONS}
                  label="Annually or Monthly Per Unit"
                  name="storageIncomeType"
                />
                <RadioButtonList
                  horizontal
                  items={IN_PLACE_OR_FORECAST_OPTIONS}
                  label="In Place or Forecast"
                  name="inPlaceOrForecast"
                />
                <NumberField
                  decimalScale={2}
                  label="Storage Income"
                  name="storageIncome"
                  prefix="$"
                  thousandSeparator
                />
                <RadioButtonList
                  horizontal
                  items={propertyType === PropertyTypes.COMMERCIAL ? VC_LOSS_OPTIONS_COMMERCIAL : VC_LOSS_OPTIONS}
                  label="Storage V/C Loss Type"
                  name="storageVCLossType"
                />
                <NumberField
                  adornment="%"
                  decimalScale={2}
                  disabled={isResidentialLossType}
                  format={toPercents}
                  label={`${storageVCLossType} Loss (%)`}
                  max={MAX_VC_LOSS_PERCENTAGE_VALUE}
                  name="vcLossPercentage"
                  normalize={fromPercents}
                />
                <GeneratedComment
                  dataPath="storageIncomeDiscussion"
                  getGeneratedText={this.generateStorageIncomeDiscussion}
                  isDynamicContent
                  title="Generated Commentary"
                  label="Storage Income Discussion"
                />
              </Stack>
            </Paper>
          )}
        </Grid>
        <Grid item xs={6} data-qa="storage-income-summary-panel">
          <FormLabel component="legend">Storage Income Totals</FormLabel>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Income Type</TableCell>
                <TableCell align="right">$</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow>
                <TableCell>Annual Storage Income</TableCell>
                <TableCell data-qa="annualStorageIncome-cell" align="right">
                  {formatCurrencyFloat(annualStorageIncome)}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell data-qa="storageVCLossType-cell">{`Less ${storageVCLossType} Loss @ ${toPercentageString(
                  vcLossPercentage
                )}`}</TableCell>
                <TableCell data-qa="vcLossValue-cell" align="right">
                  {formatCurrencyFloat(vcLossValue === 0 ? vcLossValue : -vcLossValue)}
                </TableCell>
              </TableRow>
              <TableRow sx={{ '& .MuiTableCell-root': { fontWeight: 700 } }}>
                <TableCell data-qa="totalCell-cell">Total</TableCell>
                <TableCell data-qa="storageIncomeAfterVCLoss-cell" align="right">
                  {formatCurrencyFloat(storageIncomeAfterVCLoss)}
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </Grid>
      </Grid>
    )
  }
}

StorageIncome.propTypes = {
  form: PropTypes.object.isRequired,
  hasNoAmenities: PropTypes.bool.isRequired,
  hasStorageUnits: PropTypes.bool.isRequired,
  unitCount: PropTypes.number.isRequired,
}

const formOptions = { heading: HEADING, decorators: [storageVCLossWatcher] }

const mapStateToProps = state => {
  const hasNoAmenities = get(state, 'report.reportData.propertyInformation.amenities.hasNoAmenities', true)
  const hasStorageUnits = get(state, 'report.reportData.propertyInformation.amenities.building.hasStorageUnits', true)
  const unitCount = get(state, 'report.reportData.propertyInformation.amenities.building.storageUnitCount', 0)
  const propertyType = get(state, 'report.reportData.propertyInformation.propertySummary.propertyType')

  return {
    hasNoAmenities,
    hasStorageUnits,
    unitCount,
    propertyType,
  }
}

export default wrapForm(DATA_PATH, formOptions, mapStateToProps)(StorageIncome)
