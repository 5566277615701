import React from 'react'
import PropTypes from 'prop-types'

import Typography from '@mui/material/Typography'

const Label = ({ children }) => {
  return (
    <Typography variant="subtitle2" sx={{ fontWeight: 600 }}>
      {children}
    </Typography>
  )
}

Label.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
}

export default React.memo(Label)
