import { GeneratedText } from '../../../../narrative/GeneratedText'

export const BASE_COST_CONCLUSION = "Overall the MVS calculations are similar to the developer's direct cost / budget."

const generate = () => {
  const gt = new GeneratedText()

  gt.t`${BASE_COST_CONCLUSION}`

  return gt.getParts()
}

export default { generate }
