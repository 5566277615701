import { fill } from 'lodash'

import { handleActions } from 'redux-actions'

import { ReportTabs } from '../constants'

import {
  fetchMoreReportsRequest,
  fetchMoreReportsReceive,
  fetchMoreReportsFail,
  reportsClear,
  createReportRequest,
  createReportReceive,
  createReportFail,
  setReportFilters,
  changeReportSorting,
  changeReportTab,
  clearReportFilters,
  clearReports,
  duplicateReportReceive,
  duplicateReportRequest,
  duplicateReportFail,
  changeReportVisibilityReceive,
  changeReportVisibilityRequest,
  changeReportVisibilityFail,
  setShowLoading,
} from './actions'

const initialState = {
  reports: [],
  totalCount: null,
  showLoading: false,
  newReport: {},
  isCreatingReport: false,
  currentTab: ReportTabs.MY_REPORTS,
  sorting: null,
  filters: {},
  isInitialFetchRequest: false,
}

export const reducer = handleActions(
  {
    [setShowLoading](state, { payload }) {
      return {
        ...state,
        showLoading: payload,
      }
    },
    [fetchMoreReportsRequest](state, { payload }) {
      return {
        ...state,
        isInitialFetchRequest: payload,
      }
    },
    [fetchMoreReportsReceive](state, { payload: { reports, totalCount, indexRange } }) {
      const { isInitialFetchRequest } = state
      const { startIndex, stopIndex } = indexRange

      const updatedReports = isInitialFetchRequest ? fill(Array(totalCount), null) : [...state.reports]

      for (let i = startIndex; i < stopIndex + 1; i++) {
        if (i < updatedReports.length) {
          updatedReports[i] = reports[i - startIndex]
        }
      }

      return {
        ...state,
        totalCount: totalCount,
        showLoading: false,
        reports: updatedReports,
        isInitialFetchRequest: false,
      }
    },
    [fetchMoreReportsFail](state) {
      return {
        ...state,
        showLoading: false,
      }
    },
    [reportsClear](state) {
      return {
        ...state,
        reports: [],
      }
    },
    [createReportRequest](state) {
      return {
        ...state,
        isCreatingReport: true,
      }
    },
    [createReportReceive](state, { payload }) {
      return {
        ...state,
        isCreatingReport: false,
        newReport: payload,
      }
    },
    [createReportFail](state) {
      return {
        ...state,
        isCreatingReport: false,
      }
    },
    [duplicateReportRequest](state) {
      return {
        ...state,
        isCreatingReport: true,
      }
    },
    [duplicateReportReceive](state, { payload }) {
      return {
        ...state,
        isCreatingReport: false,
        newReport: payload,
      }
    },
    [duplicateReportFail](state) {
      return {
        ...state,
        isCreatingReport: false,
      }
    },

    [changeReportVisibilityRequest](state) {
      return {
        ...state,
        showLoading: true,
      }
    },
    [changeReportVisibilityReceive](state, { payload }) {
      const { reportVisible, reportId, userId } = payload

      const reports = state.reports

      const updatedReports = reports.map(report => {
        if (report && report._id === reportId) {
          const updatedAppraisers = report.appraisers.map(appraiser => {
            if (appraiser.id === userId) {
              return {
                ...appraiser,
                reportVisible,
              }
            }

            return appraiser
          })

          return { ...report, appraisers: updatedAppraisers }
        }

        return report
      })

      return {
        ...state,
        reports: updatedReports,
        totalCount: state.totalCount - 1,
        showLoading: false,
      }
    },
    [changeReportVisibilityFail](state) {
      return {
        ...state,
        showLoading: false,
      }
    },

    [setReportFilters](state, { payload }) {
      return {
        ...state,
        filters: {
          ...state.filters,
          ...payload,
        },
      }
    },
    [changeReportSorting](state, { payload }) {
      return {
        ...state,
        sorting: payload,
      }
    },
    [changeReportTab](state, { payload }) {
      return {
        ...state,
        currentTab: payload,
      }
    },
    [clearReportFilters](state) {
      return {
        ...state,
        filters: {},
      }
    },
    [clearReports](state) {
      return {
        ...initialState,
      }
    },
  },
  initialState
)
