export const complyingBulkInputStyles = theme => ({
  row: {
    justifyContent: 'space-around',
    flexWrap: 'nowrap',
  },
  input: {
    paddingRight: 40,
    '&:focus-within $refreshFARButton:not($refreshFARButtonDisabled)': {
      color: theme.palette.primary.main,
    },
    '& svg': {
      fontSize: 20,
    },
  },
  refreshFARButton: {
    padding: 0,
  },
  refreshFARButtonDisabled: {},
  tooltipIcon: {
    marginTop: -8,
    marginLeft: 5,
    transform: 'translateY(5px)',
  },
  formulaIcon: {
    paddingTop: '18px',
  },
  formulaText: {
    paddingTop: '14px',
    fontSize: '14px',
  },
  disabledNumber: {
    '& fieldset': {
      backgroundColor: theme.palette.secondary[500],
      opacity: 0.1,
    },
    '& input, & p': {
      color: theme.palette.secondary[800],
    },
    '& label': {
      pointerEvents: 'initial',
      color: theme.palette.secondary[900],
    },
  },
  dropDownFormControl: {
    minWidth: 64,
    height: 38,
    '& > div': {
      margin: 0,
    },
    '& svg': {
      paddingRight: 12,
    },
  },
  dropDownSelect: {
    marginRight: -14,
    height: 38,
    '& fieldset': {
      borderRadius: '0px 4px 4px 0px',
    },
    backgroundColor: 'rgba(66, 96, 211, 0.08)',
  },
  dropDownAdornedEnd: {
    paddingRight: 0,
  },
})
