import React from 'react'
import PropTypes from 'prop-types'
import { get } from 'lodash'
import { compose } from 'recompose'

import {
  DialogActions,
  DialogContent,
  DialogTitle,
  Button,
  FormControl,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  withStyles,
} from '@material-ui/core'

import { connect } from 'react-redux'

import * as Socket from '../../../../shared/utils/socket'

import { uploadFile, cancelFileUpload } from '../../../../core/api'

import { successNotification, errorNotification } from '../../../redux/actions/notifications'

import ProgressBar from '../FileUploadProgressBar'

import { DEFAULT_UPLOAD_TO_RESPONSE_TIME_RATIO, DEFAULT_FINISHING_THRESHOLD, UPLOAD_STATUSES } from '../constants'

const styles = () => ({
  paper: {
    marginTop: 10,
  },
  statusCell: {
    width: 60,
  },
  formControl: {
    width: '100%',
  },
  buttonCell: {
    textAlign: 'center',
  },
})

class UploadFile extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      fileForUpload: props.fileForUpload,
      uploadId: null,
      uploadStarted: false,
      uploadProgress: 0,
      uploadedFile: null,
    }
  }

  static propTypes = {
    label: PropTypes.string.isRequired,
    fileForUpload: PropTypes.object.isRequired,
    onSelect: PropTypes.func.isRequired,
    onCancel: PropTypes.func.isRequired,
    fileType: PropTypes.string.isRequired,
    uploadEndpoint: PropTypes.string.isRequired,
    cancelEndpoint: PropTypes.string,
    parentId: PropTypes.string.isRequired,
    onUploaded: PropTypes.func.isRequired,
    maxUploadSize: PropTypes.number,
    template: PropTypes.string,
  }

  static defaultProps = {
    maxUploadSize: null,
  }

  componentDidMount() {
    Socket.on('uploadFile:success', this.onUploadFileSuccess)
    Socket.on('uploadFile:error', this.onUploadFileError)
  }

  async componentWillUnmount() {
    Socket.off('uploadFile:success', this.onUploadFileSuccess)
    Socket.off('uploadFile:error', this.onUploadFileError)

    const { cancelEndpoint } = this.props
    const { uploadId } = this.state
    if (uploadId && cancelEndpoint) {
      try {
        await cancelFileUpload(cancelEndpoint, uploadId)
      } catch (error) {
        errorNotification(error)
      }
    }
  }

  onUploadFileSuccess = uploadedFile => {
    const { fileForUpload } = this.state
    const { label, onUploaded, successNotification } = this.props
    this.setState({
      uploadId: null,
      uploadStatus: UPLOAD_STATUSES.COMPLETE,
      uploadedFile,
      uploadProgress: 100,
    })
    onUploaded(fileForUpload, uploadedFile)
    successNotification(`${label} uploaded.`)
  }

  onUploadFileError = error => {
    const { errorNotification } = this.props
    this.setState({
      uploadId: null,
      uploadStatus: UPLOAD_STATUSES.ERROR,
      uploadProgress: 100,
    })
    errorNotification(`Could not upload. ${error}`)
  }

  onUploadFileClick = async () => {
    const { fileType, uploadEndpoint, parentId, reportId, template } = this.props
    const { fileForUpload } = this.state

    this.setState({
      uploadStarted: true,
      uploadStatus: UPLOAD_STATUSES.PENDING,
    })

    try {
      const uploadId = await uploadFile(
        fileForUpload,
        fileType,
        uploadEndpoint,
        parentId,
        reportId,
        this.onUploadProgress,
        template
      )

      this.setState({ uploadId })
    } catch (error) {
      this.onUploadFileError(error)
    }
  }

  onCancelClick = async () => {
    const { cancelEndpoint, onCancel } = this.props
    const { uploadId } = this.state
    if (uploadId && cancelEndpoint) {
      try {
        await cancelFileUpload(cancelEndpoint, uploadId)
        this.setState({
          uploadId: null,
        })
        onCancel()
      } catch (error) {
        errorNotification(error)
      }
    } else {
      onCancel()
    }
  }

  onUploadProgress = event => {
    const uploadProgress =
      (event.lengthComputable ? (event.loaded / event.total) * 100 : 0) * DEFAULT_UPLOAD_TO_RESPONSE_TIME_RATIO
    if (uploadProgress === 100 * DEFAULT_UPLOAD_TO_RESPONSE_TIME_RATIO && !this.progressInterval) {
      this.progressInterval = setInterval(this.waitForUploadFinish, 500)
    } else {
      this.setState({
        uploadProgress,
      })
    }
  }

  waitForUploadFinish = () => {
    const { uploadProgress, uploadedFile } = this.state
    if (uploadProgress < DEFAULT_FINISHING_THRESHOLD && !uploadedFile) {
      this.setState({
        uploadProgress: uploadProgress + 1,
      })
    } else {
      clearInterval(this.progressInterval)
    }
  }

  getFileRow = () => {
    const { classes } = this.props
    const { uploadStarted, uploadStatus, fileForUpload, uploadedFile, uploadProgress } = this.state
    const isUploaded = !!uploadedFile
    const enableUploadButton = !isUploaded && uploadStatus !== UPLOAD_STATUSES.PENDING

    return (
      <TableRow>
        <TableCell>{fileForUpload.fileName}</TableCell>
        {!!uploadStarted && (
          <>
            <TableCell>
              <ProgressBar
                progress={uploadProgress}
                finishingThreshold={DEFAULT_FINISHING_THRESHOLD}
                uploadStatus={uploadStatus}
                progressBarProps={{ showPercent: false, strokeWidth: 4, trailWidth: 6 }}
              />
            </TableCell>
            <TableCell>{uploadStatus}</TableCell>
          </>
        )}
        <TableCell className={classes.buttonCell}>
          <div>
            <Button
              size="small"
              data-qa="upload-btn"
              color="primary"
              onClick={this.onUploadFileClick}
              disabled={!enableUploadButton}
            >
              Upload
            </Button>
          </div>
        </TableCell>
      </TableRow>
    )
  }

  render() {
    const { classes, label, onSelect } = this.props
    const { uploadStarted, uploadStatus, fileForUpload, uploadedFile } = this.state
    return (
      <>
        <DialogTitle id="simple-dialog-title">{`Upload ${label}`}</DialogTitle>
        <DialogContent>
          <FormControl className={classes.formControl}>
            <Paper className={classes.paper}>
              <Table className={classes.table}>
                <TableHead>
                  <TableRow>
                    <TableCell>File Name</TableCell>
                    {!!uploadStarted && (
                      <>
                        <TableCell>Progress</TableCell>
                        <TableCell className={classes.statusCell}>Status</TableCell>
                      </>
                    )}
                    <TableCell align="center">Actions</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>{this.getFileRow()}</TableBody>
              </Table>
            </Paper>
          </FormControl>
        </DialogContent>
        <DialogActions>
          {uploadStatus === UPLOAD_STATUSES.COMPLETE && !!uploadedFile && (
            <Button
              data-qa="insert-btn"
              onClick={() => onSelect(fileForUpload, uploadedFile)}
              color="primary"
              variant="contained"
            >
              Insert
            </Button>
          )}
          <Button data-qa="cancel-btn" onClick={this.onCancelClick} color="primary">
            Cancel
          </Button>
        </DialogActions>
      </>
    )
  }
}

const mapDispatchToProps = dispatch => ({
  errorNotification: message => dispatch(errorNotification({ message })),
  successNotification: message => dispatch(successNotification({ message })),
})

export default compose(
  withStyles(styles),
  connect(
    state => ({
      reportId: get(state, 'report.reportData._id'),
    }),
    mapDispatchToProps
  )
)(UploadFile)
