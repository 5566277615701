import React from 'react'

import { Stack } from '@mui/material'

import BuildingAmenities from './BuildingAmenities'
import UnitAmenities from './UnitAmenities'

const Amenities = ({ form }) => {
  const {
    values: { hasNoUnitAmenities, unit, automationMetadata },
  } = form
  return (
    <Stack spacing={2} sx={{ maxWidth: 1100 }}>
      <BuildingAmenities {...{ form }} />
      <UnitAmenities {...{ hasNoUnitAmenities, unit, automationMetadata }} />
    </Stack>
  )
}

export default Amenities
