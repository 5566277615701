import React from 'react'
import { Field } from 'react-final-form'

import { Number } from '../../_mui5'

const NON_NUMBERS_REGEX = /[^-0-9.]/g

const NumberField = ({ name, normalize, ...restProps }) => {
  const parse = value => {
    let number = value && parseFloat(value.replace(NON_NUMBERS_REGEX, ''))

    if (normalize) {
      number = normalize(number)
    }

    return number
  }

  return (
    <Field
      name={name}
      parse={parse}
      {...restProps}
      render={({ input, meta, ...restInnerProps }) => {
        const { error, touched } = meta
        const inErrorState = touched && !!error
        return (
          <Number {...restInnerProps} {...input} value={input.value} onChange={input.onChange} error={inErrorState} />
        )
      }}
    />
  )
}

export default NumberField
