import React from 'react'

import { OnChange } from 'react-final-form-listeners'
import { get } from 'lodash'

import { Grid } from '@mui/material'

import ShowSection from 'client-shared/components/ShowSection'
import { CheckboxWithLabel } from 'client-shared/components/Checkbox'
import { ImageUploadListField as ImageUploadList } from 'client-shared/components/ImageList'
import { RadioButtonList, Text } from 'client-shared/components'
import {
  TYPICAL_BIKE_ROOM,
  TYPICAL_COMMON_LOUNGE_SPACE,
  TYPICAL_GYM,
  TYPICAL_OUTDOOR_SPACE,
  TYPICAL_POOL,
  TYPICAL_RECREATION_ROOM,
} from 'client-shared/constants/imageCategories'
import { SECTIONS } from 'shared/helpers/propertyType'
import { Area, Template } from 'client-shared/components/Template'
import { arrayToKeyValuePairs } from 'client-shared/utils/ui/checkboxHelper'
import { notEmpty } from 'client-shared/utils/validation'

import { AMENITIES_DOORMAN } from '../../../constants'

const amenitiesDoorman = arrayToKeyValuePairs(AMENITIES_DOORMAN)

const BuildingAmenitiesResidential = ({
  building: {
    hasBikeRoom,
    hasCommonLoungeSpace,
    hasDoorman,
    hasGym,
    hasOutdoorSpace,
    hasPool,
    hasRecreationRoom,
    outdoorSpace,
  },
  onOutdoorSpace,
}) => {
  const isOtherSharedOutdoorSpaceChecked = get(outdoorSpace, 'other', false)

  const validateOtherSharedOutdoorSpaceType = React.useCallback((value, allValues) => {
    const isOtherType = get(allValues, 'building.outdoorSpace.other', false)
    return isOtherType ? notEmpty(value) : null
  }, [])

  return (
    <ShowSection section={SECTIONS.HAS_RESIDENTIAL}>
      <Grid container paddingTop={2} data-qa="outdoorSpace-section-tile">
        <CheckboxWithLabel name="building.hasOutdoorSpace" label="Shared Outdoor Space" />
        <OnChange name="building.hasOutdoorSpace">{onOutdoorSpace}</OnChange>
        {hasOutdoorSpace && (
          <Template
            is={`
              'tennisCourts garden   roofDeck'
              'terrace      backyard backyard'
              'other        other    other'
              'images       images   images'
              /1fr          1fr      1fr
            `}
          >
            <Area is="tennisCourts">
              <CheckboxWithLabel name="building.outdoorSpace.tennisCourts" label="Tennis Courts" />
            </Area>
            <Area is="garden">
              <CheckboxWithLabel name="building.outdoorSpace.garden" label="Shared Garden" />
            </Area>
            <Area is="roofDeck">
              <CheckboxWithLabel name="building.outdoorSpace.roofDeck" label="Shared Roof Deck" />
            </Area>
            <Area is="terrace">
              <CheckboxWithLabel name="building.outdoorSpace.terrace" label="Shared Terrace" />
            </Area>
            <Area is="backyard">
              <CheckboxWithLabel name="building.outdoorSpace.backyard" label="Shared Backyard" />
            </Area>
            <Area is="other" gap={1} sx={{ flexDirection: 'column' }}>
              <CheckboxWithLabel name="building.outdoorSpace.other" label="Other" />
              {isOtherSharedOutdoorSpaceChecked && (
                <Text
                  label="Other"
                  name="building.otherOutdoorSpace"
                  placeholder=""
                  required={isOtherSharedOutdoorSpaceChecked}
                  validate={validateOtherSharedOutdoorSpaceType}
                />
              )}
            </Area>
            <Area is="images">
              <ImageUploadList name="outdoorSpaceImages" category={TYPICAL_OUTDOOR_SPACE} />
            </Area>
          </Template>
        )}
      </Grid>
      <Grid container paddingTop={2} data-qa="doorman-section-tile">
        <CheckboxWithLabel name="building.hasDoorman" label="Doorman" />
        {hasDoorman && (
          <RadioButtonList
            horizontal={true}
            items={amenitiesDoorman}
            name="building.doorman"
            otherName="building.otherDoorman"
          />
        )}
      </Grid>
      <Grid container paddingTop={2} data-qa="bikeRoom-section-tile">
        <CheckboxWithLabel name="building.hasBikeRoom" label="Bike Room" />
        {hasBikeRoom && <ImageUploadList name="bikeRoomImages" category={TYPICAL_BIKE_ROOM} />}
      </Grid>
      <Grid container paddingTop={2} data-qa="gym-section-tile">
        <CheckboxWithLabel name="building.hasGym" label="Fitness Center" />
        {hasGym && <ImageUploadList name="gymImages" category={TYPICAL_GYM} />}
      </Grid>
      <Grid container paddingTop={2} data-qa="pool-section-tile">
        <CheckboxWithLabel name="building.hasPool" label="Pool" />
        {hasPool && <ImageUploadList name="poolImages" category={TYPICAL_POOL} />}
      </Grid>
      <Grid container paddingTop={2} data-qa="recreationRoom-section-tile">
        <CheckboxWithLabel name="building.hasRecreationRoom" label="Shared Recreation Room" />
        {hasRecreationRoom && <ImageUploadList name="recreationRoomImages" category={TYPICAL_RECREATION_ROOM} />}
      </Grid>
      <Grid container paddingTop={2} data-qa="loungeSpace-section-tile">
        <CheckboxWithLabel name="building.hasCommonLoungeSpace" label="Common Lounge Space" />
        {hasCommonLoungeSpace && (
          <ImageUploadList name="commonLoungeSpaceImages" category={TYPICAL_COMMON_LOUNGE_SPACE} />
        )}
      </Grid>
      <Grid container paddingTop={2} data-qa="onSiteSuperintendent-section-tile">
        <CheckboxWithLabel name="building.hasOnSiteSuperintendent" label="On-Site Superintendent" />
      </Grid>
    </ShowSection>
  )
}

export default BuildingAmenitiesResidential
