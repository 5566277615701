import { lowerCase, isNil, toLower, upperFirst, startCase } from 'lodash'
import numeral from 'numeral'

import { formatList } from 'shared/utils/textHelpers'

import { USES_VALUES } from '../../../../libs/bowery-libs/constants/report/commercial-unit'
import { ElevatorTypes, UNIT_GRADES } from '../../report/constants'
import { formatNaturalNumber } from '../../../../shared/utils/narrative/GeneratedText'
import { getPropertyConditionSentencePart } from '../../../../shared/utils/textGeneration/property/description'

import { BasisTypes, perBasisStub } from '../../../../shared/constants/report/incomeApproach/expenses'

import { formatInt, formatCurrencyInt, formatCurrencyFloat, formatPercentageString } from './numberFormatters'
import { shortDateFormat, longDateFormatWithoutLeadingZero, shortDateWithZeroesFormat } from './dateHelper'

export const narrativeFormatters = {
  formatInt,
  formatPercentageString,
  formatCurrencyInt,
  formatCurrencyFloat,
  shortDateFormat,
  shortDateWithZeroesFormat,
  longDateFormatWithoutLeadingZero,
  formatSquareFeet: squareFeet => numeral(squareFeet).format('0,0.[00]'),
  formatStateSentence: locationInfo => {
    if (locationInfo.isNYCLocation) {
      return 'the New York Department of Finance,'
    }

    if (locationInfo.isNJLocation) {
      return 'state and local tax records,'
    }

    return 'public record,'
  },
  formatNaturalNumber,
  formatBuilding: buildingType => {
    let formattedBuildingType = buildingType
    if (buildingType === ElevatorTypes.ELEVATOR) {
      formattedBuildingType = 'elevatored'
    }
    return formattedBuildingType
  },
  zoneJoin: array => {
    if (array.length < 1) {
      return array
    }
    if (array.length === 1) {
      return array[0].zone
    }
    if (array.length === 2) {
      return `${array[0].zone} and ${array[1].zone}`
    }
    if (array.length > 2) {
      const oxfordCommaEnd = `, and ${array[array.length - 1].zone}`
      let concatString = array
        .slice(0, array.length - 1)
        .map(element => element.zone)
        .join(', ')
      concatString += oxfordCommaEnd
      return concatString
    }
  },
  formatCommercialUnitUse: ({ use, otherUse }) => {
    if (use === USES_VALUES.OTHER && otherUse) {
      return toLower(otherUse)
    }
    return use
  },
  formatCommercialUnitGrade: ({ grade, otherGrade }) => {
    const selectedGrades = Object.entries(grade)
      .filter(([key, value]) => key !== 'other' && !!value)
      .map(([key, value]) => lowerCase(UNIT_GRADES[key]))
    if (otherGrade) {
      selectedGrades.push(otherGrade)
    }
    return formatList(selectedGrades)
  },
  formatCondition: value => {
    return getPropertyConditionSentencePart(value)
  },
  formatCurrencyByBasis: ({ value, basis }) => {
    if (isNil(value)) {
      return '$0'
    }
    return basis === BasisTypes.PER_UNIT ? formatCurrencyInt(value) : formatCurrencyFloat(value)
  },
  formatBasisToString: value => {
    return perBasisStub[value] || '[BASIS]'
  },
  formatLowerCase: value => {
    return lowerCase(value)
  },
  formatUpperFirst: value => {
    return upperFirst(value)
  },
  formatStartCase: value => {
    return startCase(value)
  },
}
