import React from 'react'
import PropTypes from 'prop-types'

import { withStyles } from '@mui/styles'
import { get, noop } from 'lodash'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { compose } from 'recompose'

import LanguageOutlinedIcon from '@mui/icons-material/LanguageOutlined'
import DnsOutlinedIcon from '@mui/icons-material/DnsOutlined'

import { Stack } from '@mui/system'
import { Button, Typography } from '@mui/material'

import { ICON_LABELS } from 'cms/constants/cms'

import Header from '../../../shared/layout/Header'
import Sidebar from '../../../shared/layout/Sidebar'
import MenuIcon from '../../../shared/layout/MenuIcon'

import ChipFormModal from '../forms/ChipFormModal'
import ChipTable from '../forms/ChipTable'
import { createChip, fetchChipDataPathOptions, fetchChipOptions, updateChip, validateChip } from '../../../core/api'
import ChipArchiveModal from '../forms/ChipArchiveModal'

const styles = {
  headerContent: {
    fontSize: 20,
    fontWeight: 600,
    color: '#404858',
  },
  pageContent: {},
  layoutContainer: {
    width: '100%',
    display: 'flex',
  },
  contentContainer: {
    flex: 1,
    padding: 20,
  },
}

class PageLayout extends React.PureComponent {
  constructor() {
    super()
    this.state = {
      chips: [],
      dataPathOptions: [],
      modalOpen: false,
      modalInitials: null,
      modalError: null,
      editableChipIndex: null,
      archiveModelOpen: false,
      archivedChip: null,
      tags: [],
    }
  }

  static propTypes = {
    classes: PropTypes.object.isRequired,
    location: PropTypes.object.isRequired,
    header: PropTypes.string,
  }

  componentDidUpdate(prevProps) {
    if (prevProps.location !== this.props.location) {
      window.scrollTo(0, 0)
    }
  }

  componentDidMount() {
    this.fetchChips()
    this.refreshChipDataPathOptions()
  }

  openCMS = () => {
    const { history } = this.props
    history.push(`/cms`, { skipSave: true })
  }

  onModalOpen = () => {
    this.setState({ modalOpen: true })
  }

  onModalClose = () => {
    this.setState({ modalOpen: false, modalInitials: null, editableChipIndex: null, modalError: null })
  }

  onArchiveModalOpen = chip => {
    this.setState({ archiveModelOpen: true, archivedChip: chip })
  }

  onArchiveModalClose = () => {
    this.setState({ archiveModelOpen: false, archivedChip: null })
  }

  onArchive = async chip => {
    this.onArchiveModalClose()
    try {
      await this.archiveChip(chip)
    } catch (error) {
      console.error('Error archiving chip', error)
    }
  }

  onArchiveFromEdit = chip => {
    this.setState({ modalOpen: false, modalInitials: null, editableChipIndex: null })
    this.onArchiveModalOpen(chip)
  }

  editChip = (index, chip) => {
    this.setState({
      modalInitials: chip,
      editableChipIndex: index,
    })

    this.onModalOpen()
  }

  onCreateChip = () => {
    this.setState({
      modalInitials: null,
      editableChipIndex: null,
    })

    this.onModalOpen()
  }

  fetchChips = async () => {
    try {
      const response = await fetchChipOptions()
      this.setState({ chips: response })
      this.updateTags(response)
    } catch (error) {
      console.error('Error fetching chips', error)
    }
  }

  refreshChipDataPathOptions = async () => {
    try {
      const chipDataPathOptions = await fetchChipDataPathOptions()
      this.setState({ chipDataPathOptions })
    } catch (error) {
      console.error('Error fetching chip data path options', error)
    }
  }

  archiveChip = async chip => {
    try {
      const updatedChip = { ...chip }
      updatedChip.isArchived = !updatedChip.isArchived

      await updateChip(updatedChip)
      await this.fetchChips()
    } catch (error) {
      console.error('Error archiving chip', error)
    }
  }

  attemptChipCreation = async modelData => {
    try {
      await this.validateAndUpsert(createChip, modelData)
    } catch (error) {
      console.error('Error creating chip', error)
    }
  }

  attemptChipUpdate = async modelData => {
    try {
      await this.validateAndUpsert(updateChip, modelData)
    } catch (error) {
      console.error('Error updating chip', error)
    }
  }

  validateAndUpsert = async (chipAction, modelData) => {
    const response = await validateChip(modelData)
    if (response.isValid) {
      await chipAction(modelData)
      await this.fetchChips()
      this.onModalClose()
    } else {
      this.setState({
        modalError: 'Chip already exists',
      })
    }
  }

  updateTags = chips => {
    const tags = chips.reduce((acc, chip) => {
      const { tags, groupName } = chip
      if (tags) {
        acc = acc.concat(tags)
      }
      if (groupName) {
        acc.push(groupName)
      }
      return acc
    }, [])
    const uniqueTags = [...new Set(tags)]
    const sortedTags = uniqueTags.sort()
    this.setState({ tags: sortedTags })
  }

  render() {
    const { classes, header } = this.props

    return (
      <>
        <Header classes={{ headerContent: classes.headerContent }} title={header} />

        <div className={classes.layoutContainer}>
          <Sidebar>
            <Stack spacing={2}>
              <MenuIcon
                onClick={this.openCMS}
                icon={LanguageOutlinedIcon}
                tooltipTitle="Global"
                label={ICON_LABELS.cms}
              />
              <MenuIcon
                onClick={noop}
                icon={DnsOutlinedIcon}
                tooltipTitle="Chip bank"
                isActive
                label={ICON_LABELS.chipBank}
              />
            </Stack>
          </Sidebar>
          <div className={classes.contentContainer}>
            <Stack direction="column" spacing={3}>
              <Stack flexDirection="row" justifyContent="space-between" alignItems="center">
                <Typography variant="h6">Chip Registration and Modification</Typography>
                <Button
                  variant="contained"
                  onClick={this.onCreateChip}
                  style={{ marginRight: 0, paddingLeft: 16, paddingRight: 16 }}
                >
                  Create a new chip
                </Button>
              </Stack>
              <ChipFormModal
                onSubmit={this.state.modalInitials ? this.attemptChipUpdate : this.attemptChipCreation}
                chipDataPathOptions={this.state.chipDataPathOptions}
                form={{
                  open: this.state.modalOpen,
                  onArchive: this.onArchiveFromEdit,
                  onClose: this.onModalClose,
                  initialValues: this.state.modalInitials,
                  errorMessage: this.state.modalError,
                  tags: this.state.tags,
                }}
              />
              <Stack flexDirection="row" justifyContent="space-between">
                <ChipTable
                  chips={this.state.chips}
                  onArchiveModalOpen={this.onArchiveModalOpen}
                  onEditModalOpen={this.editChip}
                />
              </Stack>
              <ChipArchiveModal
                open={this.state.archiveModelOpen}
                onClose={this.onArchiveModalClose}
                onArchive={this.onArchive}
                chip={this.state.archivedChip}
              />
            </Stack>
          </div>
        </div>
      </>
    )
  }
}

export default compose(
  connect(state => ({ location: get(state, 'router.location.pathname') })),
  withStyles(styles, { name: 'PageLayout' }),
  withRouter
)(PageLayout)
