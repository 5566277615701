import { get } from 'lodash'
import { createSelector } from 'reselect'

export const selectSyncState = createSelector(
  state => get(state, 'reportSettings') || {},
  reportSettings => {
    const { fetchedSettings, isLoading, isLoaded } = reportSettings

    return {
      completed: !!(fetchedSettings && isLoaded),
      failed: !fetchedSettings && isLoaded,
      inProgress: isLoading,
    }
  }
)
