import React, { memo } from 'react'
import { FormControl, FormHelperText, InputLabel, MenuItem, Select as MuiSelect } from '@mui/material'

const Select = ({
  options,
  value,
  label,
  helperText,
  renderOption,
  error,
  disabled,
  selectRef,
  className,
  size = 'small',
  width,
  ...otherSelectProps
}) => {
  return (
    <FormControl disabled={disabled} error={error} className={className} size={size} sx={{ width: '100%' }}>
      <InputLabel variant="outlined" shrink>
        {label}
      </InputLabel>
      <MuiSelect
        MenuProps={{
          anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'left',
          },
          transformOrigin: {
            vertical: 'top',
            horizontal: 'left',
          },
        }}
        ref={selectRef}
        value={value || []}
        label={label}
        {...otherSelectProps}
      >
        {options?.map(option => (
          <MenuItem
            key={option.key || option.value}
            value={option.value}
            disabled={option.disabled && option.disabled(option.value, value)}
          >
            {renderOption ? renderOption(option) : option.label}
          </MenuItem>
        ))}
      </MuiSelect>
      {helperText && <FormHelperText>{helperText}</FormHelperText>}
    </FormControl>
  )
}

export default memo(Select)
