import { Grid, IconButton } from '@mui/material'
import PropTypes from 'prop-types'
import React from 'react'
import { Link } from 'react-router-dom'
import get from 'lodash/get'
import { connect } from 'react-redux'

import { required } from 'client-shared/utils/validation'

import OpenIcon from '@mui/icons-material/OpenInNewRounded'

import { Number, Tooltip } from '../../../../shared/components'
import { MAX_UNITS_NUMBER } from '../../../constants'
import { showSection } from '../../../../../../shared/helpers/propertyType'

const UnitCountSection = ({ section, fieldName, fieldLabel, tooltipText, link, propertyType }) => {
  const shouldDisable = !(!section || showSection(section, propertyType))

  return (
    <Grid item xs={12} sx={{ display: 'flex' }}>
      <Number
        name={fieldName}
        label={fieldLabel}
        allowNegative={false}
        validate={required}
        max={MAX_UNITS_NUMBER}
        helperText=" "
        disabled={shouldDisable}
      />
      {!shouldDisable && (
        <Tooltip text={tooltipText} placement="top">
          <Link to={link}>
            <IconButton>
              <OpenIcon fontSize="small" />
            </IconButton>
          </Link>
        </Tooltip>
      )}
    </Grid>
  )
}

UnitCountSection.propTypes = {
  section: PropTypes.string.isRequired,
  fieldName: PropTypes.string.isRequired,
  fieldLabel: PropTypes.string.isRequired,
  tooltipText: PropTypes.string.isRequired,
  link: PropTypes.string.isRequired,
}

export default connect(state => {
  const propertyType = get(state, 'report.reportData.propertyType')

  return { propertyType }
})(UnitCountSection)
