import { TableData } from './TableData'

interface HeadCell {
  id: keyof TableData
  name: string
  title: string
}

const CHIP_TABLE_COLUMNS: readonly HeadCell[] = [
  {
    id: 'displayName',
    name: 'humanReadableName',
    title: 'Human Readable Name',
  },
  {
    id: 'dataPath',
    name: 'dataPath',
    title: 'Data Path',
  },
  {
    id: 'formatter',
    name: 'formatter',
    title: 'Formatter',
  },
  {
    id: 'groupName',
    name: 'group',
    title: 'Group',
  },
]

export { CHIP_TABLE_COLUMNS }
