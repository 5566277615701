import { get, reduce, round, sumBy } from 'lodash'

import { divide } from 'shared/utils/numberOperations'

import {
  ConclusionType,
  DepreciationType,
  MarshallValuationServiceType,
  SubjectBudgetType,
} from 'shared/types/costApproachTypes'

import { getSiteAreaSfAndAcres } from '../sales-approach/helpers'

export default {
  calculateTotalMvsConclusionValue(conclusionValues: number[]): number {
    const total = reduce(conclusionValues, (sum, value) => sum + (value || 0), 0)

    return round(total, 2)
  },
  calculateMvsDirectCost(
    siteImprovementCostPerSf: number,
    mvsBaseCost: number,
    siteArea: number,
    siteAreaMeasure: string
  ): number {
    const { siteAreaSf } = getSiteAreaSfAndAcres(siteArea, siteAreaMeasure)
    const siteImprovementCost = siteImprovementCostPerSf * siteAreaSf
    return siteImprovementCost + mvsBaseCost
  },
  calculateMvsBuildingCost(buildingComponents: any[]) {
    let totalReplacementCostNew = 0
    buildingComponents.forEach(buildingComponent => {
      totalReplacementCostNew += buildingComponent.replacementCostNew || buildingComponent.conclusionValue
    })
    return totalReplacementCostNew
  },
  calculateMvsSiteImprovementsCost(
    siteArea: number,
    siteAreaMeasure: string,
    marshallValuationService: MarshallValuationServiceType
  ) {
    const { siteAreaSf } = getSiteAreaSfAndAcres(siteArea, siteAreaMeasure)
    const siteImprovementCostPerSf = get(marshallValuationService, 'siteImprovementsCost', 0)
    return siteImprovementCostPerSf * siteAreaSf
  },
  calculateDepreciatedCost(depreciation: DepreciationType, mvsBuildingCost: number, mvsSiteImprovementCost: number) {
    const replacementCostDepreciationPercentage = get(
      depreciation,
      'replacementCostDepreciations[0].totalDepreciation',
      0
    )
    const siteCostDepreciationPercentage = get(depreciation, 'siteImprovementDepreciation.totalDepreciation', 0)

    const replacementCostDepreciation = replacementCostDepreciationPercentage * mvsBuildingCost
    const totalDepreciatedReplacementCost = mvsBuildingCost - replacementCostDepreciation

    const siteCostDepreciation = siteCostDepreciationPercentage * mvsSiteImprovementCost
    const totalDepreciatedSiteCost = mvsSiteImprovementCost - siteCostDepreciation
    const totalDepreciatedCost = totalDepreciatedReplacementCost + totalDepreciatedSiteCost
    return totalDepreciatedCost || 0
  },
  calculateCostApproachFinalValues({
    conclusion,
    subjectBudget,
    totalLeaseUpCosts,
    replacementCostNew,
    landValue,
  }: {
    conclusion: ConclusionType
    subjectBudget: SubjectBudgetType
    totalLeaseUpCosts: number
    replacementCostNew: number
    landValue: number
  }) {
    const demolitionCosts = get(subjectBudget, 'constructionBudget.demolitionCosts', 0)

    const subjectBudgetCostsToDate = get(subjectBudget, 'costsToDate', { totalCosts: 0, demolitionCosts: 0 })
    const subjectBudgetTotalCosts = get(subjectBudgetCostsToDate, 'totalCosts') || 0
    const subjectBudgetDemolitionCosts = get(subjectBudgetCostsToDate, 'demolitionCosts') || 0
    const constructionCostsSpentToDate = subjectBudgetTotalCosts - subjectBudgetDemolitionCosts
    const remainingConstructionCosts = replacementCostNew - constructionCostsSpentToDate
    const roundToTheNearest = get(conclusion, 'roundToTheNearest', 100000)

    const asIsEntrepreneurialIncentive = get(conclusion, 'asIsEntrepreneurialIncentive', 0)
    const asIsEntrepreneurialIncentiveValue = constructionCostsSpentToDate * asIsEntrepreneurialIncentive
    const asIsNpvAdjustments = get(conclusion, 'asIsNpvAdjustments', [])
    const asIsNpv = sumBy(asIsNpvAdjustments, 'value')

    const asIsMarketValue = landValue + constructionCostsSpentToDate + asIsEntrepreneurialIncentiveValue + asIsNpv
    const asIsMarketValueRounded = Math.round(divide(asIsMarketValue, roundToTheNearest)) * roundToTheNearest

    const asCompleteGrowthRate = get(conclusion, 'asCompleteGrowthRate', 10)
    const asCompleteMonthsAnnualizedFor = get(conclusion, 'asCompleteMonthsAnnualizedFor', 12)
    const asCompleteAnnualizedGrowthRate = (1 + asCompleteGrowthRate) ** (asCompleteMonthsAnnualizedFor / 12)
    const asIsMarketValueWithGrowth = asIsMarketValue * asCompleteAnnualizedGrowthRate

    const asCompleteNpvAdjustments = get(conclusion, 'asCompleteNpvAdjustments', [])
    const asCompleteNpv = sumBy(asCompleteNpvAdjustments, 'value')
    const asCompleteEntrepreneurialIncentive = get(conclusion, 'asCompleteEntrepreneurialIncentive', 0)
    const asCompleteEntrepreneurialIncentiveValue = asCompleteEntrepreneurialIncentive * remainingConstructionCosts
    const asCompleteMarketValue =
      asIsMarketValueWithGrowth + remainingConstructionCosts + asCompleteEntrepreneurialIncentiveValue + asCompleteNpv
    const asCompleteMarketValueRounded =
      Math.round(divide(asCompleteMarketValue, roundToTheNearest)) * roundToTheNearest

    const asStabilizedGrowthRate = get(conclusion, 'asStabilizedGrowthRate', 10)
    const asStabilizedMonthsAnnualizedFor = get(conclusion, 'asStabilizedMonthsAnnualizedFor', 12)
    const asStabilizedAnnualizedGrowthRate = (1 + asStabilizedGrowthRate) ** (asStabilizedMonthsAnnualizedFor / 12)
    const asCompleteMarketValueWithGrowth = asCompleteMarketValue * asStabilizedAnnualizedGrowthRate

    const asStabilizedEntrepreneurialIncentive = get(conclusion, 'asStabilizedEntrepreneurialIncentive', 0)
    const asStabilizedEntrepreneurialIncentiveValue = totalLeaseUpCosts * asStabilizedEntrepreneurialIncentive

    const asStabilizedNpvAdjustments = get(conclusion, 'asStabilizedNpvAdjustments', [])
    const asStabilizedNpv = sumBy(asStabilizedNpvAdjustments, 'value')
    const asStabilizedMarketValue =
      asCompleteMarketValueWithGrowth + totalLeaseUpCosts + asStabilizedEntrepreneurialIncentiveValue + asStabilizedNpv
    const asStabilizedMarketValueRounded = Math.round(asStabilizedMarketValue / roundToTheNearest) * roundToTheNearest

    const asCompleteDateOfValue = get(conclusion, 'asCompleteDateOfValue')
    const asStabilizedDateOfValue = get(conclusion, 'asStabilizedDateOfValue')

    return {
      demolitionCosts,
      constructionCostsSpentToDate,
      remainingConstructionCosts,

      asIsEntrepreneurialIncentive,
      asIsEntrepreneurialIncentiveValue,
      asIsMarketValue,
      asIsMarketValueRounded,
      asIsMarketValueWithGrowth,

      asCompleteDateOfValue,
      asCompleteGrowthRate,
      asCompleteMonthsAnnualizedFor,
      asCompleteAnnualizedGrowthRate,
      asCompleteEntrepreneurialIncentive,
      asCompleteEntrepreneurialIncentiveValue,
      asCompleteMarketValue,
      asCompleteMarketValueRounded,
      asCompleteMarketValueWithGrowth,

      asStabilizedDateOfValue,
      asStabilizedGrowthRate,
      asStabilizedMonthsAnnualizedFor,
      asStabilizedAnnualizedGrowthRate,
      asStabilizedEntrepreneurialIncentive,
      asStabilizedEntrepreneurialIncentiveValue,
      asStabilizedMarketValue,
      asStabilizedMarketValueRounded,
    }
  },
}
