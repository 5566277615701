import React from 'react'
import PropTypes from 'prop-types'

import { get, isEmpty } from 'lodash'

import { FeatureToggle } from '@bowery-valuation/feature-flagger-client'

import { Button, Paper, Stack, Typography, Checkbox, FormControlLabel } from '@mui/material'

import CheckCircleOutline from '@mui/icons-material/CheckCircleOutline'
import CloudDownload from '@mui/icons-material/CloudDownload'
import ErrorOutline from '@mui/icons-material/ErrorOutline'
import Warning from '@mui/icons-material/Warning'

import { updateReportAsSalesComp } from 'core/api'

import { ExportStatuses } from 'shared/constants/export'
import { shortDateTimeFormat } from 'client-shared/utils/dateHelper'
import * as Socket from 'client-shared/utils/socket'

import ReportStructureSelector from './ReportStructureSelector'
import ExportItemProgressBar from './ExportItemProgressBar'
import ExpansionExportItem from './ExpansionExportItem'
import ExportConfirmModal from './ReportSettingsSection/ExportConfirmModal'
import FieldsMissingErrorModal from './ReportSettingsSection/FieldsMissingErrorModal'
import SuccessfulCreateCompModal from './ReportSettingsSection/SuccessfulCreateCompModal'

const COLUMNS = [
  {
    name: 'createdAt',
    title: 'Date',
    width: '25%',
  },
  {
    name: 'progress',
    title: 'Progress',
    width: '25%',
  },
  {
    name: 'status',
    title: 'Status',
    width: '25%',
  },
  {
    name: 'actions',
    title: 'Actions',
    width: '25%',
  },
]

const StatusChip = ({ status }) => {
  const icon = React.useMemo(() => {
    const { COMPLETE, ERROR, WARNING } = ExportStatuses
    switch (status) {
      case COMPLETE:
        return <CheckCircleOutline color="success" fontSize="small" />
      case ERROR:
        return <ErrorOutline color="error" fontSize="small" />
      case WARNING:
        return <Warning color="warning" fontSize="small" />
      default:
        return null
    }
  }, [status])
  return (
    <Stack direction="row" spacing={1} alignItems="center">
      <Typography data-qa="status" variant="body2">
        {status}
      </Typography>
      {icon}
    </Stack>
  )
}

class ExportReport extends React.PureComponent {
  static propTypes = {
    defaultBaseTemplateFileName: PropTypes.string.isRequired,
    exportItems: PropTypes.arrayOf(PropTypes.object.isRequired),
    generateReport: PropTypes.func.isRequired,
    generateXML: PropTypes.func.isRequired,
    getReportHierarchy: PropTypes.func.isRequired,
    isBlocks: PropTypes.bool.isRequired,
    isUnderContract: PropTypes.bool.isRequired,
    lastCompletedExportItemIndex: PropTypes.number,
    missingSubjectFields: PropTypes.array.isRequired,
    reportId: PropTypes.string.isRequired,
    salesHistory: PropTypes.array.isRequired,
    templateType: PropTypes.string.isRequired,
    templates: PropTypes.arrayOf(
      PropTypes.shape({ label: PropTypes.string.isRequired, value: PropTypes.string.isRequired })
    ).isRequired,
    updateShouldGenerateSignatures: PropTypes.func.isRequired,
    updateStatus: PropTypes.func.isRequired,
  }

  static defaultProps = {
    exportItems: [],
    lastCompletedExportItemIndex: null,
  }

  componentWillMount() {
    const { getReportHierarchy, defaultBaseTemplateFileName } = this.props
    Socket.on('report:export', this.onGenerateReport)
    getReportHierarchy(defaultBaseTemplateFileName)
  }

  componentWillUnmount() {
    Socket.off('report:export', this.onGenerateReport)
  }

  onGenerateReport = exportStatus => {
    const { updateStatus } = this.props
    updateStatus(exportStatus)
  }

  generateReport = isRefreshable => {
    const { generateReport } = this.props
    generateReport({
      baseTemplateFileName: this.props.defaultBaseTemplateFileName,
      isRefreshable: isRefreshable,
    })
  }

  generateXML = () => {
    const { generateXML } = this.props
    generateXML()
  }

  download = exportItem => event => {
    event.stopPropagation()
    window.open(exportItem.downloadUrl)
  }

  getExportTableCellContent = ({ column, exportItem, showDownloadButton }) => {
    switch (column.name) {
      case 'createdAt':
        return (
          <Typography data-qa="created-at" variant="body2">
            {shortDateTimeFormat(get(exportItem, 'createdAt'))}
          </Typography>
        )
      case 'progress':
        return (
          <ExportItemProgressBar
            exportItem={exportItem}
            progressBarProps={{ showPercent: false, strokeWidth: 4, trailWidth: 6 }}
          />
        )

      case 'actions':
        if (!showDownloadButton) {
          return null
        }
        return (
          <Button
            color="inherit"
            data-qa="download-btn"
            disabled={!exportItem.isLastItemCompleted}
            endIcon={<CloudDownload />}
            id="download-report-btn"
            onClick={this.download(exportItem)}
            variant="contained"
          >
            Download
          </Button>
        )
      case 'status': {
        const status = exportItem.status === ExportStatuses.CRITICAL_ERROR ? ExportStatuses.ERROR : exportItem.status
        return <StatusChip status={status} />
      }
      default:
        return get(exportItem, column.name)
    }
  }

  state = {
    hasReportInLocalStorage: false,
    isOpenRequiredFieldsErrorModal: false,
    isOpenSuccessfulCreateCompModal: false,
    isSubmitModalChecked: false,
    isOpenSubmitSubjectModal: false,
  }

  openSubmitSubjectModal = isNeededConfirm => {
    if (isNeededConfirm) {
      this.setState({ isOpenSubmitSubjectModal: true })
    }
  }

  closeSubmitSubjectModal = () => {
    this.setState({ isOpenSubmitSubjectModal: false })
  }

  handleOnChange = event => {
    this.setState({
      isSubmitModalChecked: event.target.checked,
    })
  }

  addReportIdToLocalStorage = () => {
    const excludedReports = JSON.parse(localStorage.getItem('excludedReports')) || []
    if (!excludedReports.includes(this.props.reportId)) {
      excludedReports.push(this.props.reportId)
    }
    localStorage.setItem('excludedReports', JSON.stringify(excludedReports))
  }

  addModalShowException = () => {
    if (this.state.isSubmitModalChecked) {
      this.addReportIdToLocalStorage()
    }
  }

  componentDidUpdate() {
    this.setState({
      hasReportInLocalStorage: !!localStorage.getItem('excludedReports')?.includes(this.props.reportId),
    })
  }

  onSignatureCheckboxChange = event => {
    const { updateShouldGenerateSignatures } = this.props

    updateShouldGenerateSignatures({ shouldGenerateSignatures: event.target.checked })
  }

  openMissingFieldsErrorModal = () => {
    this.setState({
      isOpenRequiredFieldsErrorModal: true,
    })
  }

  closeMissingFieldsErrorModal = () => {
    this.setState({
      isOpenRequiredFieldsErrorModal: false,
    })
  }

  openSuccessfulCreateCompModal = () => {
    this.setState({
      isOpenSuccessfulCreateCompModal: true,
    })
  }
  closeSuccessfulCreateCompModal = () => {
    this.setState({
      isOpenSuccessfulCreateCompModal: false,
    })
  }

  preCreateCompCheck() {
    const { reportId, missingSubjectFields } = this.props

    if (missingSubjectFields.length) {
      this.openMissingFieldsErrorModal()
    } else {
      updateReportAsSalesComp(reportId, true)
      this.openSuccessfulCreateCompModal()
    }
  }

  confirmModalFunction = () => {
    this.closeSubmitSubjectModal()
    this.preCreateCompCheck()
    this.addModalShowException()
  }

  cancelModalFunction = () => {
    const { reportId } = this.props
    this.closeSubmitSubjectModal()
    updateReportAsSalesComp(reportId, false)
    this.addModalShowException()
  }

  render() {
    const { isBlocks, isUnderContract, lastCompletedExportItemIndex, missingSubjectFields, reportId, salesHistory } =
      this.props
    const exportItems = get(this, 'props.exportItems', [])
    const isNeededConfirm = isUnderContract || !isEmpty(salesHistory)

    return (
      <Paper>
        <Stack spacing={2}>
          <ReportStructureSelector />
          <Stack direction="row" spacing={1} justifyContent="space-between">
            <Stack direction="row" spacing={1}>
              <Button
                variant="contained"
                onClick={() => {
                  this.generateReport(true)
                  this.openSubmitSubjectModal(isNeededConfirm)
                }}
              >
                Generate Refreshable Report
              </Button>
              <Button
                data-qa="generate-report-btn"
                id="generate-report-btn"
                onClick={() => {
                  this.generateReport(false)
                  this.openSubmitSubjectModal(isNeededConfirm)
                }}
              >
                Generate Legacy Report
              </Button>
              <Button
                data-qa="generate-xml-btn"
                onClick={() => {
                  this.generateXML()
                  this.openSubmitSubjectModal(isNeededConfirm)
                }}
              >
                Generate XML
              </Button>
            </Stack>
            {!isBlocks && (
              <FeatureToggle featureFlag="signatures-export">
                <FormControlLabel
                  control={<Checkbox color="primary" onChange={this.onSignatureCheckboxChange} />}
                  label="Generate with Signatures"
                />
              </FeatureToggle>
            )}
          </Stack>
          <Stack direction="row" pl={2} pr={5}>
            {COLUMNS.map(({ name, title, width }) => (
              <Typography key={name} variant="caption" sx={{ px: 2, width }}>
                {title}
              </Typography>
            ))}
          </Stack>

          {exportItems.map((exportItem, index) => (
            <ExpansionExportItem
              columns={COLUMNS}
              exportItem={exportItem}
              getCellContent={this.getExportTableCellContent}
              index={index}
              key={exportItem._id}
              showDownloadButton={lastCompletedExportItemIndex === index}
            />
          ))}
        </Stack>
        {!this.state.hasReportInLocalStorage && (
          <ExportConfirmModal
            open={this.state.isOpenSubmitSubjectModal}
            onSave={this.confirmModalFunction}
            onCancel={this.cancelModalFunction}
            handleChange={this.handleOnChange}
          />
        )}
        <FieldsMissingErrorModal
          open={this.state.isOpenRequiredFieldsErrorModal}
          onConfirm={this.closeMissingFieldsErrorModal}
          missingFields={missingSubjectFields}
          reportId={reportId}
        />
        <SuccessfulCreateCompModal
          open={this.state.isOpenSuccessfulCreateCompModal}
          onCancel={this.closeSuccessfulCreateCompModal}
        />
      </Paper>
    )
  }
}

export default ExportReport
