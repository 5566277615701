import { GeneratedText, gtIfElse, gtLocked, gtTemplate } from '../../../../narrative/GeneratedText'

import { mapDTO, mapDataModel } from '../mappings'

export const NO_FUNCTIONAL_OBSOLESCENCE =
  "Based on a review of the architect's plans or our observations, no forms of curable functional obsolescence were noted. Because replacement cost considers the construction of the subject improvements utilizing modern materials and current standards, design and layout, functional incurable obsolescence is generally not applicable."

const generate = () => {
  const gt = new GeneratedText()
  const functionalObsolescenceGt = gtLocked('functionalObsolescence', 'formatPercentageString')

  gt.t`${gtIfElse(
    'includeDepreciation',
    gtTemplate`Functional Incurable Obsolescence is estimate at ${functionalObsolescenceGt}.`,
    gtTemplate`${NO_FUNCTIONAL_OBSOLESCENCE}`
  )}`

  return gt.getParts()
}

export default { generate, mapDTO, mapDataModel }
