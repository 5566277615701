import { toUpper, get, head } from 'lodash'

const checkUserHasInspectorRoleOnly = (inspectorRole, roles = []) => {
  return roles.length === 1 && head(roles) === inspectorRole
}

const getUserInitials = user => {
  return toUpper([get(user, 'name.first'), get(user, 'name.last')].map(head).join(''))
}

export { checkUserHasInspectorRoleOnly, getUserInitials }
