import React, { useState } from 'react'
import { sortBy, uniqBy } from 'lodash'
import { v4 as uuid } from 'uuid'
import { matchSorter } from 'match-sorter'
import parse from 'autosuggest-highlight/parse'
import match from 'autosuggest-highlight/match'
import {
  Dialog,
  DialogContent,
  DialogActions,
  DialogTitle,
  Button,
  RadioGroup,
  FormControlLabel,
  Radio,
  Autocomplete,
  TextField,
  Paper,
} from '@mui/material'

import PropTypes from 'prop-types'

import { INSPECTOR_TYPE } from './constants'
const noOptionsText = "This appraiser / inspector isn't in the system. Please select 'External Inspector' "

const CustomPaper = props => {
  return <Paper {...props} sx={{ padding: 0 }} />
}

const AddAppraiserModal = props => {
  const { isOpenModal, closeModal, onAddAppraiser, appraisersSuggestions } = props

  const [inspectorType, setInspectorType] = useState(INSPECTOR_TYPE.BOWERY)

  const [newAppraiser, setNewAppraiser] = useState('')
  const [enteredInspector, setEnteredInspector] = useState('')

  const changeInspectorType = event => {
    if (event.target.value === INSPECTOR_TYPE.EXTERNAL) {
      setNewAppraiser(enteredInspector)
    } else {
      setNewAppraiser('')
      setEnteredInspector('')
    }

    setInspectorType(event.target.value)
  }

  const capitalizeName = name => {
    return name.replace(/\b\w+/g, function (txt) {
      return txt.charAt(0).toUpperCase() + txt.substr(1)
    })
  }

  const addAppraiser = () => {
    if (inspectorType === INSPECTOR_TYPE.BOWERY) {
      onAddAppraiser(newAppraiser)
    } else {
      const externalInspector = {
        id: uuid(),
        fullName: capitalizeName(newAppraiser),
        roles: [2],
        signReport: false,
        isInspected: true,
        designation: 'None',
        isExternal: true,
      }
      onAddAppraiser(externalInspector)
    }
    closeModal()
  }

  const filterOptions = (options, { inputValue }) =>
    matchSorter(options, inputValue, {
      keys: ['fullName'],
      threshold: matchSorter.rankings.WORD_STARTS_WITH,
    })

  return (
    <Dialog open={isOpenModal} onClose={closeModal} data-qa="addAppraiserInspectorModal">
      <DialogTitle>Add Appraiser / Inspector</DialogTitle>
      <DialogContent sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
        <RadioGroup row value={inspectorType} onChange={changeInspectorType}>
          <FormControlLabel
            value={INSPECTOR_TYPE.BOWERY}
            control={<Radio />}
            data-qa="boweryAppraiserInspectorRadio"
            label="Bowery Appraiser / Inspector"
          />
          <FormControlLabel
            value={INSPECTOR_TYPE.EXTERNAL}
            control={<Radio />}
            data-qa="externalInspectorRadio"
            label="External Inspector"
          />
        </RadioGroup>

        {inspectorType === INSPECTOR_TYPE.BOWERY && (
          <Autocomplete
            PaperComponent={CustomPaper}
            popupIcon={null}
            noOptionsText={noOptionsText}
            onChange={(event, newValue) => {
              setNewAppraiser(newValue)
            }}
            onInputChange={event => {
              !!event.target.value ? setEnteredInspector(event.target.value) : setEnteredInspector('')
            }}
            options={sortBy(uniqBy(appraisersSuggestions, 'fullName'), ['fullName'])}
            filterOptions={filterOptions}
            getOptionLabel={option => option.fullName}
            renderInput={params => (
              <TextField {...params} data-qa="inspectorNameInput" label="Search Appraisers / Inspectors" />
            )}
            renderOption={(props, option, { inputValue }) => {
              const matches = match(option.fullName, inputValue)
              const parts = parse(option.fullName, matches)

              return (
                <li {...props}>
                  <div>
                    {parts.map((part, index) => (
                      <span
                        data-qa={`appraiser-${part.text}`}
                        key={index}
                        style={{
                          fontWeight: part.highlight ? 700 : 400,
                        }}
                      >
                        {part.text}
                      </span>
                    ))}
                  </div>
                </li>
              )
            }}
          />
        )}
        {inspectorType === INSPECTOR_TYPE.EXTERNAL && (
          <TextField
            data-qa="inspectorNameInput"
            label="Inspector name"
            variant="outlined"
            value={newAppraiser}
            onChange={event => setNewAppraiser(event.target.value)}
          />
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={closeModal} data-qa="cancelAppraiserModalBtn">
          Cancel
        </Button>
        <Button variant="contained" onClick={addAppraiser} disabled={!newAppraiser} data-qa="addAppraiserModalBtn">
          Add
        </Button>
      </DialogActions>
    </Dialog>
  )
}

AddAppraiserModal.propTypes = {
  appraisersSuggestions: PropTypes.array.isRequired,
  isOpenModal: PropTypes.bool.isRequired,
  closeModal: PropTypes.func.isRequired,
  onAddAppraiser: PropTypes.func.isRequired,
}

export default AddAppraiserModal
