import React from 'react'
import PropTypes from 'prop-types'

import { withStyles } from '@material-ui/core/styles'

import BoweryDate from '@bowery-valuation/bowery-date'

import { Number } from '../../../../shared/components'

import styles from './styles'

const yearToISOString = year => `${year}-01-01T00:00:00.000Z`

const dateToYear = date => {
  if (!date) {
    return ''
  }
  const boweryDate = new BoweryDate(date)
  if (!boweryDate.isValidDate()) {
    return ''
  }

  const fullYear = boweryDate.year
  return fullYear
}

const yearToDate = year => {
  const paddedYear = `${year}`.padStart(4, '0')
  return yearToISOString(paddedYear)
}

class TaxRateYearField extends React.PureComponent {
  static propTypes = {
    name: PropTypes.string,
    form: PropTypes.object,
    min: PropTypes.number,
    max: PropTypes.number,
  }
  static defaultProps = {
    min: null,
    max: null,
  }

  validate = value => {
    const { min, max } = this.props
    const year = parseInt(value)

    if (year === 0) {
      return undefined
    }

    if (min && year < min) {
      return `Min year is ${min}`
    }
    if (max && year > max) {
      return `Max year is ${max}`
    }
    return undefined
  }

  render() {
    const { name, label } = this.props

    return (
      <Number
        name={name}
        label={label}
        validate={this.validate}
        format={dateToYear}
        normalize={yearToDate}
        data-qa="projected-equalization-marketValue-input"
      />
    )
  }
}

export default withStyles(styles)(TaxRateYearField)
