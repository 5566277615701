import { get } from 'lodash'

export const mapBuildingAmenities = report => {
  const buildingInfo = get(report, 'buildingDescription.amenities.building', {})
  const parkingSpaceCount = get(report, 'potential_gross_income.parkingIncome.parkingSpaces', 0)
  return {
    hasOutdoorSpace: buildingInfo.hasOutdoorSpace,
    outdoorSpace: buildingInfo.outdoorSpace,
    otherOutdoorSpace: buildingInfo.otherOutdoorSpace,
    hasCommonLoungeSpace: buildingInfo.commonLoungeSpace,
    hasOnSiteSuperintendent: buildingInfo.onSiteSuperintendent,
    hasGym: buildingInfo.gym,
    hasLaundryRoom: buildingInfo.laundryRoom,
    hasStorageUnits: buildingInfo.storage,
    storageUnitCount: buildingInfo.storageUnits,
    hasBikeRoom: buildingInfo.bikeRoom,
    hasParking: buildingInfo.parking,
    hasRecreationRoom: buildingInfo.recreationRoom,
    doorman: buildingInfo.doorman,
    hasDoorman: buildingInfo.hasDoorman,
    otherDoorman: buildingInfo.otherDoorman,
    hasPool: buildingInfo.pool,
    hasCourtyard: buildingInfo.courtyard,
    hasLoading: buildingInfo.loading,
    hasStorefrontExposure: buildingInfo.storefrontExposure,
    hasWarehouseSpace: buildingInfo.warehouseSpace,
    hasOutdoorSeating: buildingInfo.outdoorSeating,
    hasDriveThru: buildingInfo.driveThru,
    hasOtherAmenities: buildingInfo.otherAmenities,
    otherBuildingAmenity: buildingInfo.otherBuildingAmenity,

    parkingSpaceCount,
  }
}

export const mapUnitAmenities = report => {
  const unitInfo = get(report, 'buildingDescription.amenities.unit', {})
  return {
    hasWasherDryer: unitInfo.washerDryer,
    washerDryerUnits: unitInfo.washerDryerUnits,

    hasPrivatePatio: unitInfo.privatePatio,
    privatePatioUnits: unitInfo.privatePatioUnits,

    hasPrivateBalcony: unitInfo.privateBalcony,
    privateBalconyUnits: unitInfo.privateBalconyUnits,

    hasPrivateTerrace: unitInfo.privateTerrace,
    privateTerraceUnits: unitInfo.privateTerraceUnits,

    hasPrivateRoofArea: unitInfo.privateRoofArea,
    privateRoofAreaUnits: unitInfo.privateRoofAreaUnits,

    hasPrivateBackyard: unitInfo.privateBackyard,
    privateBackyardUnits: unitInfo.privateBackyardUnits,

    hasPrivateDeck: unitInfo.privateDeck,
    privateDeckUnits: unitInfo.privateDeckUnits,

    hasOtherUnitAmenity: unitInfo.other,
    otherUnits: unitInfo.otherUnits,
  }
}

export const mapSalesAdjustmentAmenities = propertyAmenities => {
  const amenities = []
  if (propertyAmenities.hasNoAmenities) {
    return amenities
  }

  if (propertyAmenities.hasBikeRoom) {
    amenities.push('bikeroom')
  }
  if (propertyAmenities.hasGym) {
    amenities.push('gym')
  }
  if (propertyAmenities.hasLaundryRoom) {
    amenities.push('laundry room')
  }
  if (propertyAmenities.hasParking) {
    amenities.push('parking')
  }
  if (propertyAmenities.hasPool) {
    amenities.push('pool')
  }
  if (propertyAmenities.hasRecreationRoom) {
    amenities.push('recreation room')
  }
  if (propertyAmenities.hasStorageUnits) {
    amenities.push('storage units')
  }
  if (propertyAmenities.hasWasherDryer) {
    amenities.push('washer dryer')
  }
  return amenities
}
