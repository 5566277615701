import { useEffect, useState } from 'react'

export const useFormControlProps = (field, { originalHelperText = '' } = {}) => {
  const { error, touched, validating } = field.meta
  const [lastValidationResult, setLastValidationResult] = useState(error)

  useEffect(() => {
    if (!validating) {
      setLastValidationResult(error)
    }
  }, [error, validating])

  const hasError = !!(touched && lastValidationResult)
  const helperText = hasError ? lastValidationResult : originalHelperText

  return {
    hasError,
    helperText,
  }
}
