import React, { FC, useMemo } from 'react'
import { useForm } from 'react-final-form'
import { get } from 'lodash'
import { Box, Grid, Paper, Stack, Typography } from '@mui/material'
import arrayMutators from 'final-form-arrays'

import { NarrativeComponent } from 'client-shared/components'
import directCostsParagraph from 'shared/utils/textGeneration/costApproach/marshallValuationService/directCosts'
import baseCostSummary from 'shared/utils/textGeneration/costApproach/marshallValuationService/baseCostSummary'
import comparisonSummary from 'shared/utils/textGeneration/costApproach/marshallValuationService/comparisonTableSummary'
import baseCostConclusionParagraph from 'shared/utils/textGeneration/costApproach/marshallValuationService/baseCostConclusion'
import baseCostCalculationTableSummary from 'shared/utils/textGeneration/costApproach/marshallValuationService/baseCostCalculationTableSummary'
import indirectCostsParagraph from 'shared/utils/textGeneration/costApproach/marshallValuationService/indirectCosts'
import mvsFinalEstimateParagraph from 'shared/utils/textGeneration/costApproach/marshallValuationService/finalEstimate'
import { ExtendedFormApi } from 'client-shared/utils/form'
import MvsTable from 'report/forms/property/InsurableValue/MvsTable'

import wrapForm from 'report/forms/wrapForm'
import { MVS_PATH } from 'shared/constants/report/keysAndDataPaths'
import { mvsDecorators } from 'report/forms/property/InsurableValue/decorators'

import { PAPER_WIDTH, getMvsDirectCost, getMvsSiteImprovementsCost } from '../../helpers'

import SiteImprovementsTable from './SiteImprovementsTable'
import ComparisonTable from './ComparisonTable'
import MvsConclusionTable from './MvsConclusionTable'
import MvsBaseCostEstimateTable from './MvsBaseCostEstimateTable'

type MarshallValuationServiceOwnProps = {
  form: ExtendedFormApi
}

export const MarshallValuationService: FC<MarshallValuationServiceOwnProps> = ({ form }) => {
  const { values, focus, change } = form
  const formApi = useForm()
  const { remove, push, update } = formApi.mutators
  const {
    numberOfResidentialUnits,
    siteArea,
    subjectBudget = {},
    marshallValuationService = {},
    grossBuildingArea,
    buildingComponents = [],
    customMultipliers = [],
    customRefinements = [],
  } = values

  const siteImprovementsCost = useMemo(() => {
    return marshallValuationService.siteImprovementsCost
  }, [marshallValuationService.siteImprovementsCost])

  const demolitionCosts = useMemo(() => {
    return get(subjectBudget, 'constructionBudget.demolitionCosts', 0)
  }, [subjectBudget])

  const subjectBudgetDirectCost = useMemo(() => {
    const { constructionBudget } = subjectBudget
    const constructionDirectCosts = get(constructionBudget, 'directCosts', 0)
    const constructionDirectCostsAdjustment = get(constructionBudget, 'directCostsAdjustment', 0)
    const constructionSiteImprovementCosts = get(constructionBudget, 'siteImprovementCosts', 0)
    return constructionDirectCosts + constructionDirectCostsAdjustment + constructionSiteImprovementCosts
  }, [subjectBudget])

  const mvsTotalSiteImprovementsCosts = useMemo(() => {
    return getMvsSiteImprovementsCost({ marshallValuationService, siteArea })
  }, [marshallValuationService, siteArea])

  const mvsDirectCost = useMemo(() => {
    return getMvsDirectCost({ buildingComponents, marshallValuationService, siteArea })
  }, [buildingComponents, marshallValuationService, siteArea])

  return (
    <Paper id="marshall-valuation-service" sx={{ maxWidth: PAPER_WIDTH }}>
      <Typography variant="h5">Marshall Valuation Service</Typography>
      <Stack spacing={5}>
        <Grid pt={5}>
          <Box id="direct-costs">
            <NarrativeComponent
              name="marshallValuationService.directCostsParagraph"
              title="Direct Costs"
              generatedText={directCostsParagraph.generate}
            />
          </Box>
        </Grid>
        <Box id="mvs-base-cost">
          <NarrativeComponent
            name="marshallValuationService.baseCostCalculationTableSummary"
            title="MVS Base Costs"
            generatedText={baseCostCalculationTableSummary.generate}
          />
        </Box>
        <MvsTable
          buildingComponents={buildingComponents}
          customRefinements={customRefinements}
          customMultipliers={customMultipliers}
          remove={remove}
          push={push}
          update={update}
          formApi={formApi}
          focus={focus}
        />
        <Grid>
          <Typography variant="subtitle1">Site Improvements / Additions - Manual Dated [MVS Manual Date]</Typography>
          <SiteImprovementsTable
            change={change}
            marshallValuationService={{ siteImprovementsCost }}
            siteArea={siteArea}
          />
        </Grid>
        <NarrativeComponent
          name="marshallValuationService.baseCostConclusionTableSummary"
          title="MVS Total Estimate Summary"
          generatedText={baseCostSummary.generate}
        />
        <MvsBaseCostEstimateTable
          buildingComponents={buildingComponents}
          mvsDirectCost={mvsDirectCost}
          mvsTotalSiteImprovementsCosts={mvsTotalSiteImprovementsCosts}
        />
        <NarrativeComponent
          name="marshallValuationService.baseCostComparisonTableSummary"
          title="Comparison of MVS Direct Costs to Developer's Direct Cost / Budget"
          generatedText={comparisonSummary.generate}
        />
        <Grid>
          <Typography variant="subtitle1">
            Comparison of MVS Direct Costs to Developer's Direct Cost / Budget
          </Typography>
          <ComparisonTable
            grossBuildingArea={grossBuildingArea}
            numberOfResidentialUnits={numberOfResidentialUnits}
            mvsDirectCost={mvsDirectCost}
            subjectBudgetDirectCost={subjectBudgetDirectCost}
          />
        </Grid>
        <NarrativeComponent
          name="marshallValuationService.baseCostConclusionParagraph"
          title=""
          generatedText={baseCostConclusionParagraph.generate}
        />
        <NarrativeComponent
          name="marshallValuationService.indirectCostsIntroductionParagraph"
          title="Indirect Costs"
          generatedText={indirectCostsParagraph.generate}
        />
        <NarrativeComponent
          name="marshallValuationService.mvsFinalEstimateTableSummary"
          title=""
          generatedText={mvsFinalEstimateParagraph.generate}
        />
        <Grid>
          <Typography variant="subtitle1">MVS Direct Cost Estimate Plus Indirect Cost Estimate</Typography>
          <MvsConclusionTable
            demolitionCosts={demolitionCosts}
            marshallValuationService={marshallValuationService}
            change={change}
            mvsDirectCost={mvsDirectCost}
            grossBuildingArea={grossBuildingArea}
            numberOfResidentialUnits={numberOfResidentialUnits}
          />
        </Grid>
      </Stack>
    </Paper>
  )
}

export default wrapForm(MVS_PATH, {
  heading: 'Marshall Valuation Service',
  decorators: [...mvsDecorators],
  mutators: {
    ...arrayMutators,
  },
})(MarshallValuationService)
