import React from 'react'
import PropTypes from 'prop-types'
import { Paper, Grid } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'
import { camelCase, get } from 'lodash'

import 'leaflet/dist/leaflet.css'
import 'react-leaflet-markercluster/dist/styles.min.css'
import CartoDBConfig from '../../../../../shared/utils/cartodbConfig'

import { Loading } from '../../../../../shared/components'
import { SubjectAndCompsMapWizard } from '../../../../../shared/components/MapWizard'
import { getCityStateZip } from '../../../../../shared/utils/propertyHelper'

import { Labels } from './CommercialRentCompsMapConstants'

const styles = {
  loadingContainer: {
    position: 'relative',
    height: 200,
  },
}

class CommercialRentCompsMap extends React.PureComponent {
  static propTypes = {
    classes: PropTypes.object.isRequired,
    subject: PropTypes.shape({
      coords: PropTypes.array.isRequired,
      addressOne: PropTypes.string.isRequired,
      addressTwo: PropTypes.string,
    }).isRequired,
    isReady: PropTypes.bool.isRequired,
    mapsWithComps: PropTypes.arrayOf(
      PropTypes.shape({
        groupId: PropTypes.string.isRequired,
        name: PropTypes.string.isRequired,
        mapUrl: PropTypes.string,
        comps: PropTypes.arrayOf(
          PropTypes.shape({
            address: PropTypes.string.isRequired,
            coords: PropTypes.shape({
              latitude: PropTypes.number.isRequired,
              longitude: PropTypes.number.isRequired,
            }).isRequired,
          })
        ).isRequired,
      })
    ).isRequired,
  }

  getMapWizard(mapWithComps, index) {
    const { subject } = this.props
    const comps = mapWithComps.comps
      .sort((compA, compB) => compA.displayOrder - compB.displayOrder)
      .map(({ coords, address = '', city, state, zip }) => {
        const streetName = get(address.split(','), '[0]')
        const cityStateZip = getCityStateZip(city, state, zip)
        return {
          coords: [coords.latitude || coords.lat, coords.longitude || coords.lng],
          address: { addressOne: streetName, addressTwo: cityStateZip },
        }
      })

    return (
      <SubjectAndCompsMapWizard
        name={`maps[${index}].mapUrl`}
        mapType={camelCase(mapWithComps.name)}
        label={`'${mapWithComps.name}' ${Labels.COMPARABLES_MAP}`}
        clearAvailable
        mapOptions={{
          baseMap: CartoDBConfig.baseMap,
          isZoomOn: true,
          displayCenterButton: true,
          maxZoom: CartoDBConfig.subjectAndComps.maxZoom,
          initialZoom: CartoDBConfig.subjectAndComps.initialZoom,
          defaultLabelOffset: CartoDBConfig.subjectAndComps.defaultLabelOffset,
          width: CartoDBConfig.subjectAndComps.width,
          height: CartoDBConfig.subjectAndComps.height,
          initialCoordinates: {
            lat: subject.coords[0],
            lng: subject.coords[1],
          },
        }}
        subject={subject}
        comps={comps}
      />
    )
  }

  render() {
    const { classes, isReady, mapsWithComps } = this.props

    return (
      <Grid container wrap="nowrap" direction="column" spacing={16}>
        {isReady ? (
          mapsWithComps.map((mapWithComps, index) => (
            <Grid key={mapWithComps.groupId} item>
              {this.getMapWizard(mapWithComps, index)}
            </Grid>
          ))
        ) : (
          <Grid item className={classes.loadingContainer}>
            <Loading />
          </Grid>
        )}
        {isReady && mapsWithComps.length === 0 && (
          <Grid item className={classes.loadingContainer}>
            <Paper>You need to have one or more Comp Groups to create a comp map</Paper>
          </Grid>
        )}
      </Grid>
    )
  }
}

export default withStyles(styles)(CommercialRentCompsMap)
