import { get } from 'lodash'
import React from 'react'
import PropTypes from 'prop-types'
import ObjectId from 'bson-objectid/objectid'
import { Button, Stack } from '@mui/material'

import { CheckboxWithLabel } from 'client-shared/components'

import SaleCondition from './SaleCondition'

import SaleTable from './SalesTable'

class SalesHistory extends React.PureComponent {
  static propTypes = {
    block: PropTypes.string,
    lot: PropTypes.string,
    propertyIdentifier: PropTypes.string,
    propertyIdentifierType: PropTypes.string,
    salesHistory: PropTypes.array.isRequired,
    streetAddress: PropTypes.string.isRequired,
    form: PropTypes.object.isRequired,
  }

  addSale = () => {
    const { mutators } = this.props.form
    mutators.push('salesHistory', {
      _id: ObjectId().toString(),
    })
  }

  moveSale = ({ source, destination }) => {
    const { mutators } = this.props.form

    if (!destination) {
      return
    }

    const value = mutators.remove('salesHistory', source.index)

    mutators.insert('salesHistory', destination.index, value)
  }

  removeSale = index => {
    const { form } = this.props

    const salesHistory = get(form, 'values.salesHistory') || []
    const newSalesHistory = salesHistory.filter((saleItem, saleIndex) => saleIndex !== index)

    form.change('salesHistory', newSalesHistory)
  }

  render() {
    const { block, lot, propertyIdentifier, propertyIdentifierType, salesHistory, streetAddress } = this.props

    return (
      <>
        <Stack spacing={1}>
          <Button data-qa="add-sale-btn" onClick={this.addSale} variant="contained" sx={{ alignSelf: 'start' }}>
            ADD SALE
          </Button>
          <SaleTable
            block={block}
            lot={lot}
            moveSale={this.moveSale}
            propertyIdentifier={propertyIdentifier}
            propertyIdentifierType={propertyIdentifierType}
            removeSale={this.removeSale}
            salesHistory={salesHistory}
            streetAddress={streetAddress}
          />
        </Stack>
        {salesHistory
          .sort((first, second) => Date.parse(second.date) - Date.parse(first.date))
          .map((item, index) => {
            return (
              <SaleCondition
                salesHistoryItem={item}
                name={`salesHistory.${index}.saleCondition`}
                key={`salesHistoryCondition${index}`}
              />
            )
          })}
        <CheckboxWithLabel
          label="The property has not sold in the last three years, but I would still like to reconcile its sales history later"
          name="reconcileSalesHistoryLater"
        />
      </>
    )
  }
}

export default SalesHistory
