import React from 'react'
import PropTypes from 'prop-types'
import { Form } from 'react-final-form'

import { DialogActions, DialogContent, DialogTitle, Button, Box } from '@mui/material'
import { Dialog } from '@material-ui/core'

import Text from 'client-shared/components/Text/Text'
import DatePicker from 'client-shared/components/DatePicker/DatePicker'
import DropDown from 'client-shared/components/DropDown/DropDown'
import ImageUpload from 'client-shared/components/ImageUpload/ImageUploadField'

import { arrayToKeyValuePairs } from 'client-shared/utils/ui/checkboxHelper'

import { APPRAISAL_CERTIFICATION_LEVELS } from 'shared/constants/admin'

import AppraisalStateSearch from './AppraisalStateSearch'

const APPRAISAL_CERTIFICATION_LEVELS_OPTIONS = arrayToKeyValuePairs(APPRAISAL_CERTIFICATION_LEVELS)

class AppraisalCertificationModal extends React.PureComponent {
  render() {
    const { open, onSubmit, onClose, initialValues } = this.props

    const isUpdateMode = !!initialValues
    return (
      <Dialog open={open} onClose={onClose} aria-labelledby="add-certification-dialog">
        <Form
          initialValues={initialValues}
          onSubmit={onSubmit}
          render={({ handleSubmit, invalid }) => {
            return (
              <>
                <DialogTitle id="add-certification-dialog"> Certification </DialogTitle>
                <DialogContent>
                  <AppraisalStateSearch />
                  <DropDown
                    name="level"
                    label="State Appraisal Certification Level"
                    items={APPRAISAL_CERTIFICATION_LEVELS_OPTIONS}
                    fullWidth
                  />
                  <Text name="number" label="State Certified License #" />
                  <DatePicker name="receivedDate" label="Received" />
                  <Box sx={{ height: 300 }}>
                    <ImageUpload name="licenseImage" />
                  </Box>
                </DialogContent>
                <DialogActions>
                  <Button onClick={onClose}>CANCEL</Button>
                  <Button disabled={invalid} variant="contained" onClick={handleSubmit}>
                    {isUpdateMode ? 'SAVE' : 'ADD'}
                  </Button>
                </DialogActions>
              </>
            )
          }}
        />
      </Dialog>
    )
  }
}

AppraisalCertificationModal.propTypes = {
  initialValues: PropTypes.object,
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
}

AppraisalCertificationModal.defaultProps = {
  initialValues: null,
}

export default AppraisalCertificationModal
