import { get } from 'lodash'

import { mapData } from '../gasMetersDiscussion/mappings'

export const mapDTO = formValues => {
  const { location, otherLocation, type, inspected } = formValues.electricMeters

  return mapData(location, otherLocation, type, inspected, 'electric')
}

export const mapDataModel = report => {
  const electricMeters = get(report, 'buildingDescription.utilities.electricMeters', {})
  const { location, otherLocation, type, inspected } = electricMeters

  return mapData(location, otherLocation, type, inspected, 'electric')
}
