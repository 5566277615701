import React from 'react'

import { camelCase } from 'lodash'

import { Paper, Stack } from '@mui/material'

import { DismissibleCallout, GeneratedComment, RadioButtonList } from 'client-shared/components'
import { EXPENSE_STRUCTURES, EXPENSE_STRUCTURES_AFTER_BOTH } from 'client-shared/utils/expenses/constants'
import { arrayToKeyValuePairs } from 'client-shared/utils/ui/checkboxHelper'

import { getOwnerObligationsDiscussion, getTenantObligationsDiscussion } from './textGeneration'
import { OBLIGATIONS_GENERATED_TEXT_TOOLTIP } from './constants'

const expenseStructures = arrayToKeyValuePairs(EXPENSE_STRUCTURES, true)
const expenseStructuresAfterBoth = arrayToKeyValuePairs(EXPENSE_STRUCTURES_AFTER_BOTH, true)

const ResidentialExpenseStructure = ({ form }) => {
  const {
    isBothResponsibleForCommonAreaMaintenanceExpenses,
    isBothResponsibleForCommonElectricalExpenses,
    isBothResponsibleForElectricalExpenses,
    isBothResponsibleForFuelExpenses,
    isBothResponsibleForGasExpenses,
    isBothResponsibleForRefuseRemovalExpenses,
    isBothResponsibleForWaterAndSewerExpenses,
    rentTypes,
  } = form.values

  return (
    <Paper>
      <Stack maxWidth={900} spacing={2}>
        <Stack>
          <RadioButtonList
            horizontal
            items={expenseStructures}
            label="Who is responsible for heat expenses?"
            name="expenseStructure.fuel"
          />
          {isBothResponsibleForFuelExpenses &&
            rentTypes.map((item, index) => (
              <RadioButtonList
                horizontal
                items={expenseStructuresAfterBoth}
                key={index}
                label={`Who is responsible for the ${item} heat expenses?`}
                name={`expenseStructureByRentTypes.${camelCase(item)}.fuel`}
              />
            ))}
          {isBothResponsibleForFuelExpenses && rentTypes.length === 0 && (
            <DismissibleCallout text="Fill out Residential Rent Roll to specify obligations that vary by rent type." />
          )}
          <RadioButtonList
            horizontal
            items={expenseStructures}
            label="Who is responsible for in-unit electrical expenses?"
            name="expenseStructure.electricity"
          />
          {isBothResponsibleForElectricalExpenses &&
            rentTypes.map((item, index) => (
              <RadioButtonList
                horizontal
                items={expenseStructuresAfterBoth}
                key={index}
                label={`Who is responsible for the ${item} in-unit electrical expenses?`}
                name={`expenseStructureByRentTypes.${camelCase(item)}.electricity`}
              />
            ))}
          {isBothResponsibleForElectricalExpenses && rentTypes.length === 0 && (
            <DismissibleCallout text="Fill out Residential Rent Roll to specify obligations that vary by rent type." />
          )}
          <RadioButtonList
            horizontal
            items={expenseStructures}
            label="Who is responsible for common area electrical expenses?"
            name="expenseStructure.commonElectricity"
          />
          {isBothResponsibleForCommonElectricalExpenses &&
            rentTypes.map((item, index) => (
              <RadioButtonList
                horizontal
                items={expenseStructuresAfterBoth}
                key={index}
                label={`Who is responsible for the ${item} common area electrical expenses?`}
                name={`expenseStructureByRentTypes.${camelCase(item)}.commonElectricity`}
              />
            ))}
          {isBothResponsibleForCommonElectricalExpenses && rentTypes.length === 0 && (
            <DismissibleCallout text="Fill out Residential Rent Roll to specify obligations that vary by rent type." />
          )}
          <RadioButtonList
            horizontal
            items={expenseStructures}
            label="Who is responsible for cooking gas expenses?"
            name="expenseStructure.gas"
          />
          {isBothResponsibleForGasExpenses &&
            rentTypes.map((item, index) => (
              <RadioButtonList
                horizontal
                items={expenseStructuresAfterBoth}
                key={index}
                label={`Who is responsible for the ${item} cooking gas expenses?`}
                name={`expenseStructureByRentTypes.${camelCase(item)}.gas`}
              />
            ))}
          {isBothResponsibleForGasExpenses && rentTypes.length === 0 && (
            <DismissibleCallout text="Fill out Residential Rent Roll to specify obligations that vary by rent type." />
          )}
          <RadioButtonList
            horizontal
            items={expenseStructures}
            label="Who is responsible for refuse removal expenses?"
            name="expenseStructure.refuseRemoval"
          />
          {isBothResponsibleForRefuseRemovalExpenses &&
            rentTypes.map((item, index) => (
              <RadioButtonList
                horizontal
                items={expenseStructuresAfterBoth}
                key={index}
                label={`Who is responsible for the ${item} refuse removal expenses?`}
                name={`expenseStructureByRentTypes.${camelCase(item)}.refuseRemoval`}
              />
            ))}
          {isBothResponsibleForRefuseRemovalExpenses && rentTypes.length === 0 && (
            <DismissibleCallout text="Fill out Residential Rent Roll to specify obligations that vary by rent type." />
          )}
          <RadioButtonList
            horizontal
            items={expenseStructures}
            label="Who is responsible for water and sewer expenses?"
            name="expenseStructure.waterAndSewer"
          />
          {isBothResponsibleForWaterAndSewerExpenses &&
            rentTypes.map((item, index) => (
              <RadioButtonList
                horizontal
                items={expenseStructuresAfterBoth}
                key={index}
                label={`Who is responsible for the ${item} water and sewer expenses?`}
                name={`expenseStructureByRentTypes.${camelCase(item)}.waterAndSewer`}
              />
            ))}
          {isBothResponsibleForWaterAndSewerExpenses && rentTypes.length === 0 && (
            <DismissibleCallout text="Fill out Residential Rent Roll to specify obligations that vary by rent type." />
          )}
          <RadioButtonList
            horizontal
            items={expenseStructures}
            label="Who is responsible for common area maintenance expenses?"
            name="expenseStructure.commonAreaMaintenance"
          />
          {isBothResponsibleForCommonAreaMaintenanceExpenses &&
            rentTypes.map((item, index) => (
              <RadioButtonList
                horizontal
                items={expenseStructuresAfterBoth}
                key={index}
                label={`Who is responsible for the ${item} common area maintenance expenses?`}
                name={`expenseStructureByRentTypes.${camelCase(item)}.commonAreaMaintenance`}
              />
            ))}
          {isBothResponsibleForCommonAreaMaintenanceExpenses && rentTypes.length === 0 && (
            <DismissibleCallout text="Fill out Residential Rent Roll to specify obligations that vary by rent type." />
          )}
        </Stack>
        <GeneratedComment
          dataPath="tenantObligationsDiscussion"
          getGeneratedText={getTenantObligationsDiscussion}
          isDynamicContent
          label="Tenant Obligations Discussion"
          title="Generated Commentary"
          tooltipText={OBLIGATIONS_GENERATED_TEXT_TOOLTIP}
        />
        <GeneratedComment
          dataPath="ownerObligationsDiscussion"
          getGeneratedText={getOwnerObligationsDiscussion}
          isDynamicContent
          label="Owner Obligations Discussion"
          title="Generated Commentary"
          tooltipText={OBLIGATIONS_GENERATED_TEXT_TOOLTIP}
        />
      </Stack>
    </Paper>
  )
}

export default ResidentialExpenseStructure
