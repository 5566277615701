import React from 'react'
import PropTypes from 'prop-types'

import { TableCell } from '@mui/material'

import TextField from 'client-shared/components/Table/TableText'
import { DraggableTableRowV2 } from 'client-shared/components/DragDropTable'

const TableRow = ({ canDelete, dragDisabled, draggingState, index, name, onRemoveItem }) => (
  <DraggableTableRowV2
    canDelete={canDelete}
    draggingState={draggingState}
    id={name}
    index={index}
    onRemove={onRemoveItem}
    singleValue={dragDisabled}
  >
    <TableCell data-qa="name-cell">
      <TextField fullWidth name={`${name}.name`} />
    </TableCell>
    <TableCell data-qa="description-cell">
      <TextField fullWidth multiline name={`${name}.description`} />
    </TableCell>
  </DraggableTableRowV2>
)

TableRow.propTypes = {
  canDelete: PropTypes.bool.isRequired,
  dragDisabled: PropTypes.bool.isRequired,
  draggingState: PropTypes.bool.isRequired,
  index: PropTypes.number.isRequired,
  name: PropTypes.string.isRequired,
  onRemoveItem: PropTypes.func.isRequired,
}

export default TableRow
