import { GeneratedText, gtText, gtIfElse, gtLocked } from '../../../narrative/GeneratedText'

import { mapDTO, mapDataModel } from './mappings'

const generate = () => {
  const gt = new GeneratedText()
  gt.t`Per the Landmark Designation Committee, ${gtLocked('fullAddress', null, 'Address')} is a `
  gt.addParts(gtIfElse('isLandmarked', [gtText('landmarked')], [gtText('non-landmarked')]))
  gt.t` building. ${gtLocked('historicDistrictPhrase')}`

  return gt.getParts()
}

export default { generate, mapDTO, mapDataModel }
