import React from 'react'
import PropTypes from 'prop-types'

import { Grid } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'
import { get, sumBy } from 'lodash'
import {
  ColumnDataTypeEnum,
  RowBasedTable,
  CELL_COLUMN_CONFIGURATIONS,
  useRowsApi,
} from '@bowery-valuation/ui-components'

import { Field } from 'react-final-form'

import { getTaxLiabilityLabel } from 'shared/report-calculations/income-approach/tax/helpers'

import TaxCalculations from 'shared/report-calculations/income-approach/tax/tax-calculations'

import {
  formatCurrencyFloat,
  formatCurrencyInt,
  formatPercentageString,
  toPercentageString,
} from '../../../../shared/utils/numberFormatters'

import { GeneratedComment, CheckboxWithLabel } from '../../../../shared/components'

import { LABELS } from '../../../../../../shared/constants/report/incomeApproach/taxes'

import AddTaxRateButton from './AddTaxRateButton'
import {
  CELL_EDITOR_SELECTORS,
  valueCellRendererFramework,
  getCustomColumnConfig,
  additionalTaxRateToRow,
  projectedTabNoopFieldValues,
} from './constants'
import styles from './styles'
import { useRegisteredFields } from './hooks'

const getEqualizedMarketValueApproachWriteup = values => {
  const equalizationRatio = toPercentageString(get(values, 'projected.equalization.equalizationRatio'))
  return (
    `The equalized market value method takes an estimated market value multiplied by an equalization ratio` +
    ` to arrive at a projected taxable assessed value. We have applied a ${equalizationRatio} equalization ratio` +
    ` to project our assessed value and then applied the tax rate to arrive at our tax liability.`
  )
}

const ROW_IDS = {
  marketValue: 'marketValue',
  equalizationRatio: 'equalizationRatio',
}

const FIELD_PATHS = {
  additionalTaxRates: 'projected.equalization.additionalTaxRates',
  equalizationRatio: 'projected.equalization.equalizationRatio',
  marketValue: 'projected.equalization.marketValue',
}

const columns = [
  {
    name: 'label',
    label: 'Item',
    suppressMovable: true,
    itemIndex: 0,
    type: ColumnDataTypeEnum.text,
    permanent: true,
    align: 'left',
    editable: params => {
      return !!params.data.isEditableRow
    },
  },
  {
    name: 'value',
    label: 'Value',
    suppressMovable: true,
    itemIndex: 1,
    type: ColumnDataTypeEnum.text,
    permanent: true,
    align: 'right',
    editable: params => {
      return (
        !!params.data.isEditableRow ||
        params.data.id === ROW_IDS.marketValue ||
        params.data.id === ROW_IDS.equalizationRatio
      )
    },
    cellEditorSelector: ({ data }) => {
      if (data.id === ROW_IDS.equalizationRatio) {
        return CELL_EDITOR_SELECTORS.percentTwoDecimals
      }

      if (data.isAdditionalTaxRateRow) {
        return CELL_EDITOR_SELECTORS.taxRate
      }

      if (data.id === ROW_IDS.marketValue) {
        return CELL_EDITOR_SELECTORS.moneyNoDecimals
      }

      return CELL_COLUMN_CONFIGURATIONS[data.type]
    },

    cellRendererFramework: valueCellRendererFramework,
  },
]

const EqualizationApproach = ({ taxRate, taxRateName, form, classes }) => {
  const additionalTaxRatesRowsApi = useRowsApi(FIELD_PATHS.additionalTaxRates)

  const handleRowUpdate = row => {
    if (row.id === ROW_IDS.equalizationRatio) {
      form.change(FIELD_PATHS.equalizationRatio, row.value)
    }
    if (row.id === ROW_IDS.marketValue) {
      form.change(FIELD_PATHS.marketValue, row.value)
    }
    if (row.isAdditionalTaxRateRow) {
      additionalTaxRatesRowsApi.updateRow(row)
    }
  }
  const handleManyRowsUpdate = rows => {
    rows.forEach(row => {
      handleRowUpdate(row)
    })
  }
  const handleRowDelete = rowId => {
    const formValuesTemp = form.getState().values
    const taxRates = formValuesTemp.projected.equalization.additionalTaxRates || []
    const rowIsTaxRate = taxRates.some(row => row.id === rowId)

    if (rowIsTaxRate) {
      additionalTaxRatesRowsApi.deleteRow(rowId)
    }
  }
  const handleCellFocus = event => {
    const rowIndex = event.rowIndex
    const columnId = event.column.colId
    const row = equalizedMarketValueRows[rowIndex]

    if (row.isAdditionalTaxRateRow) {
      const additionalTaxRatesRowIndex = additionalTaxRates.findIndex(({ id }) => id === row.id)
      form.focus(`${FIELD_PATHS.additionalTaxRates}[${additionalTaxRatesRowIndex}].${columnId}`)
    } else if (row.id === ROW_IDS.marketValue) {
      form.focus(FIELD_PATHS.marketValue)
    } else if (row.id === ROW_IDS.equalizationRatio) {
      form.focus(FIELD_PATHS.equalizationRatio)
    }
  }

  const taxableAssessedValue = get(form, 'values.projected.equalization.taxableAssessedValue', 0)
  const marketValue = get(form, 'values.projected.equalization.marketValue', 0)
  const equalizationRatio = get(form, 'values.projected.equalization.equalizationRatio')

  const { basis, residentialUnitCount, projected, squareFootage } = form.values

  const additionalTaxRates = get(projected, 'equalization.additionalTaxRates', [])
  const effectiveTaxRate = (taxRate.value || 0) + sumBy(additionalTaxRates, 'value')
  const taxLiability = TaxCalculations.calculateTaxLiability(taxableAssessedValue, effectiveTaxRate, 0)
  const basisLabel = getTaxLiabilityLabel(basis)
  const taxesPerBasis = TaxCalculations.calculateTaxesPerBasis(basis, taxLiability, squareFootage, residentialUnitCount)
  const includedInExport = get(projected, 'equalization.includedInExport', false)
  const taxRateRegisteredFields = useRegisteredFields(
    'projected.equalization.additionalTaxRates',
    columns,
    additionalTaxRates
  )

  const equalizedMarketValueRows = [
    {
      readOnly: false,
      suppressMovable: true,
      permanent: false,
      type: ColumnDataTypeEnum.text,
      label: LABELS.PROJECTED_MARKET_VALUE,
      id: ROW_IDS.marketValue,
      value: marketValue ? formatCurrencyInt(marketValue) : '$0',
      rowDef: { hideAction: true },
    },
    {
      readOnly: false,
      suppressMovable: true,
      permanent: false,
      type: ColumnDataTypeEnum.text,
      id: ROW_IDS.equalizationRatio,
      label: LABELS.EQUALIZATION_RATIO,
      value: equalizationRatio ? toPercentageString(equalizationRatio) : '0.00%',
      rowDef: { hideAction: true },
    },
    {
      readOnly: false,
      suppressMovable: true,
      permanent: false,
      type: ColumnDataTypeEnum.text,
      label: LABELS.IMPLIED_TAXABLE_ASSESSED_VALUE,
      id: 'impliedTaxableAssessedValue',
      value: formatCurrencyInt(taxableAssessedValue),
      rowDef: { hideAction: true },
    },
    {
      readOnly: true,
      suppressMovable: true,
      permanent: true,
      type: ColumnDataTypeEnum.text,
      id: 'taxRateValue',
      label: `${LABELS.TAX_RATE} (${taxRateName})`,
      value: formatPercentageString(taxRate.value, 10),
      rowDef: { hideAction: true },
    },
    ...additionalTaxRates.map(additionalTaxRateToRow),
    {
      readOnly: true,
      suppressMovable: true,
      permanent: true,
      type: ColumnDataTypeEnum.text,
      id: 'taxLiabilityTotal',
      label: LABELS.TAX_LIABILITY_TOTAL,
      value: formatCurrencyInt(taxLiability),
      rowDef: { summary: true, hideAction: true },
    },
    {
      readOnly: true,
      suppressMovable: true,
      permanent: true,
      type: ColumnDataTypeEnum.text,
      id: 'taxesPerBasis',
      label: basisLabel,
      value: formatCurrencyFloat(taxesPerBasis),
      rowDef: { hideAction: true },
    },
  ]

  return (
    <Grid container justify="space-between">
      <Grid item xs={6}>
        <Field name={FIELD_PATHS.marketValue}>{() => null}</Field>
        <Field name={FIELD_PATHS.equalizationRatio}>{() => null}</Field>
        <CheckboxWithLabel name="projected.equalization.includedInExport" label="Include in export" />
      </Grid>
      {includedInExport && (
        <>
          <Grid item>
            <AddTaxRateButton form={form} path="projected.equalization" />
          </Grid>
          <Grid item xs={12}>
            {taxRateRegisteredFields}
            <RowBasedTable
              id="equalized-market-value"
              columns={columns}
              rows={equalizedMarketValueRows}
              {...projectedTabNoopFieldValues}
              onRowDelete={handleRowDelete}
              onRowUpdate={handleRowUpdate}
              onManyRowsUpdate={handleManyRowsUpdate}
              getCustomColumnConfig={getCustomColumnConfig}
              onCellFocused={handleCellFocus}
            />

            <Grid item xl={12} className={classes.discussionContainer}>
              <GeneratedComment
                className={classes.title}
                isDynamicContent
                dataPath="projected.equalization.discussion"
                title="Generated Commentary"
                label="Equalized Market Value Discussion"
                getGeneratedText={getEqualizedMarketValueApproachWriteup}
              />
            </Grid>
          </Grid>
        </>
      )}
    </Grid>
  )
}
EqualizationApproach.propTypes = {
  taxableAssessedValue: PropTypes.number,
  taxRate: PropTypes.shape({
    value: PropTypes.number,
    effectiveYear: PropTypes.string,
  }).isRequired,
  taxRateName: PropTypes.string,
}

EqualizationApproach.defaultProps = {
  taxableAssessedValue: 0,
}
export default withStyles(styles)(EqualizationApproach)
