import { get, noop } from 'lodash'
import React, { PureComponent } from 'react'

import { Field } from 'react-final-form'

import { CHANGE_EVENT_INDEX } from 'client-shared/components/Table/constants'

import { getTotals } from '../rentRollHelpers'
import { calculateRentPSFByTimePeriod } from '../../../../../../../../shared/calculations/rentRoll'

import Table from '../../../../../../shared/components/Table/TableComponent'
import {
  currencyFloatReadOnly,
  currencyFloatRight,
} from '../../../../../../shared/components/TableHelpers/cellRenderers'

import { showConfirmModal } from '../../../../../redux/actions/report.js'
import { parseToFloat, parseToInt } from '../tools'
import { totalRowRenderer, totalRowTitleRenderer } from '../rentRollRenderers'

import { LeaseStatusTypes } from '../../../../../../../../shared/constants/report/incomeApproach/residential'

const WINDOW_WIDTH = 1920
const MENU_WIDTH = 455
const MENU_AND_COMPS_TABLE_WIDTH = 825

class RentRollTable extends PureComponent {
  constructor(props) {
    super(props)
    const formUnits = get(props.form.values, 'units', [])
    const units = this.getTableUnits(formUnits)
    this.tableRef = React.createRef()
    this.state = { width: 0, units }
  }

  get unitRentPSFTimePeriod() {
    return this.props.form.values.unitRentPSFTimePeriod
  }

  componentDidMount() {
    const { hotInstance } = this.tableRef
    hotInstance.addHook('afterRenderer', this.registerField)
    hotInstance.addHookOnce('afterRender', () => hotInstance.removeHook('afterRenderer', this.registerField))
    this.getWindowWidth()
    window.addEventListener('resize', this.getWindowWidth)
  }

  componentDidUpdate(prevProps) {
    if (
      prevProps.form.values.units !== this.props.form.values.units ||
      prevProps.form.values.unitRentPSFTimePeriod !== this.props.form.values.unitRentPSFTimePeriod
    ) {
      const formUnits = get(this.props.form.values, 'units', [])
      this.setState({ units: this.getTableUnits(formUnits) })
    }
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.getWindowWidth)
  }

  getWindowWidth = () => {
    this.setState({ width: window.innerWidth })
  }

  get tableUnits() {
    const { units } = this.state
    const { rentTotalMonthly, rentTotalAnnual, forecastTotalAnnual, forecastTotalMonthly } = getTotals(
      get(this.props.form.values, 'units', [])
    )
    return [
      ...units,
      { '#': 'Monthly Total', rent: rentTotalMonthly, rentForecast: forecastTotalMonthly },
      { '#': 'Annual Total', rent: rentTotalAnnual, rentForecast: forecastTotalAnnual },
    ]
  }

  registerField = (td, row, col, prop, value, cellProperties) => {
    const { form } = this.props
    const fieldName = `units[${row}].${prop}`
    form.registerField(fieldName, noop, {})
  }

  registerFieldsForUnit = unitIndex => {
    const { columns } = this.props

    columns.forEach(({ data }) => {
      this.registerField(null, unitIndex, null, data)
    })
  }

  getTableUnits = units => {
    const { columns } = this.props
    const tableUnits = []
    units.forEach((unit, unitIndex) => {
      const tableUnit = {}
      columns.forEach(column => {
        const columnName = column.data
        let newValue = unit[columnName]
        switch (columnName) {
          case 'isInspected':
            newValue = this.getInspected(newValue)
            break
          case 'number':
            tableUnit['#'] = unitIndex + 1
            break
          case 'bedrooms':
          case 'rooms':
            newValue = parseInt(newValue) || 0
            break
          case 'rent':
            newValue = parseFloat(newValue) || 0
            tableUnit['rent/room'] = newValue / unit.rooms
            tableUnit['rent/SF'] = calculateRentPSFByTimePeriod(
              newValue,
              unit.squareFootage,
              this.unitRentPSFTimePeriod
            )
            break
          case 'rent/room':
          case 'rent/SF':
          case '#':
            newValue = tableUnit[columnName]
            break
          default:
            break
        }
        tableUnit[columnName] = newValue
      })

      tableUnits.push(tableUnit)
    })
    return tableUnits
  }

  getInspected = newValue => {
    if (newValue === 'TRUE' || newValue === true || newValue === 'true') {
      return true
    }
    return false
  }

  beforeChange = (changes = []) => {
    const {
      form: { values, change },
    } = this.props
    const { units } = this.state
    const formUnits = get(values, 'units', []).map(unit => {
      return Object.assign({}, unit)
    })
    const tableUnits = units
    changes.forEach(unitChange => {
      const unitIndex = unitChange[CHANGE_EVENT_INDEX.row]
      const columnName = unitChange[CHANGE_EVENT_INDEX.column]
      let newValue = unitChange[CHANGE_EVENT_INDEX.newValue]
      if (!formUnits[unitIndex]) {
        formUnits.push({
          index: unitIndex,
        })
        this.registerFieldsForUnit(unitIndex)
      }
      if (!tableUnits[unitIndex]) {
        tableUnits.push({
          index: unitIndex,
          '#': unitIndex + 1,
        })
      }
      switch (columnName) {
        case 'isInspected':
          newValue = this.getInspected(newValue)
          break
        case 'number':
          tableUnits[unitIndex]['#'] = unitIndex + 1
          tableUnits[unitIndex]['index'] = unitIndex
          break
        case 'bedrooms':
          newValue = parseToInt(newValue) || 0
          const rooms = newValue + 2
          tableUnits[unitIndex]['rooms'] = rooms
          formUnits[unitIndex]['rooms'] = rooms
          tableUnits[unitIndex]['rent/room'] = (formUnits[unitIndex].rent || 0) / rooms
          break
        case 'rooms':
          newValue = parseToInt(newValue) || 0
          tableUnits[unitIndex]['rent/room'] = (formUnits[unitIndex].rent || 0) / newValue
          break
        case 'bathrooms':
          newValue = parseToFloat(newValue)
          break
        case 'rent':
          newValue = parseToFloat(newValue) || 0
          tableUnits[unitIndex]['rent/room'] = newValue / formUnits[unitIndex].rooms
          tableUnits[unitIndex]['rent/SF'] = calculateRentPSFByTimePeriod(
            newValue,
            formUnits[unitIndex].squareFootage,
            this.unitRentPSFTimePeriod
          )
          break
        case 'rentForecast':
          newValue = parseToFloat(newValue) || 0
          break
        case 'squareFootage':
          newValue = parseToFloat(newValue) || 0
          tableUnits[unitIndex]['rent/SF'] = calculateRentPSFByTimePeriod(
            formUnits[unitIndex].rent || 0,
            newValue,
            this.unitRentPSFTimePeriod
          )
          break
        default:
          break
      }
      formUnits[unitIndex][columnName] = newValue
      tableUnits[unitIndex][columnName] = newValue
    })
    this.setState({ units: tableUnits })
    change('units', [...formUnits])
    showConfirmModal()
    return false
  }
  setTableRef = ref => {
    this.tableRef = ref
  }

  getUnitCellSettings = (row, col, prop) => {
    const { form } = this.props
    const { units } = this.state

    if (row + 1 > units.length) {
      const totalRowValue = this.tableUnits[row]
      return this.getTotalCellSettings(row, col, prop, totalRowValue)
    }
    const unitPath = `units[${row}].${prop}`
    const cellSettings = {}

    if (units[row] && prop === 'rent' && units[row]['leaseStatus'] === LeaseStatusTypes.VACANT) {
      cellSettings.readOnly = true
      cellSettings.className = 'readOnly'
      cellSettings.renderer = currencyFloatReadOnly
    } else if (units[row] && prop === 'rent' && units[row]['leaseStatus'] !== LeaseStatusTypes.VACANT) {
      cellSettings.readOnly = false
      cellSettings.className = 'alignRight'
      cellSettings.renderer = currencyFloatRight
    }
    if (form.errors[unitPath]) {
      cellSettings.className = `${cellSettings.className || ''} invalid`
    }

    return cellSettings
  }

  getColumnHeader = index => {
    const { columns } = this.props
    return columns[index]?.value
  }

  getTotalCellSettings = (row, col, prop, rowValue) => {
    const index = this.props.columns.findIndex(column => column.data === 'rent')
    const cellSettings = {}
    if (col === 0) {
      cellSettings.colSpan = index + 1
      cellSettings.renderer = totalRowTitleRenderer(rowValue)
    } else {
      cellSettings.renderer = totalRowRenderer(rowValue)
    }

    return {
      ...cellSettings,
      readOnly: true,
      className: 'readOnly',
    }
  }

  render() {
    const { columns } = this.props
    const { width } = this.state

    const calculateTableWidth = () => {
      return width >= WINDOW_WIDTH ? width - MENU_AND_COMPS_TABLE_WIDTH : width - MENU_WIDTH
    }

    return (
      <div>
        <Field name="units">{() => null}</Field>
        <Table
          setTableRef={this.setTableRef}
          data={this.tableUnits}
          colHeaders={this.getColumnHeader}
          columns={columns}
          fixedRowsBottom={2}
          width={calculateTableWidth()}
          beforeChange={this.beforeChange}
          cells={this.getUnitCellSettings}
        />
      </div>
    )
  }
}

export default RentRollTable
