import createDecorator from 'final-form-calculate'
import { get, sumBy } from 'lodash'

import CommercialCalculations from 'shared/report-calculations/income-approach/commercial/commercial-calculations'

export const commercialLossCalculator = [
  createDecorator({
    field: /commercialVCLossPercentageByType\..+/,
    updates: (value, fieldName, allValues) => {
      const {
        includedUses,
        commercialVCLossAmountByType,
        commercialVCLossPercentageByType,
        potentialCommercialIncome,
        potentialCommercialIncomeByType,
      } = allValues
      const modifiedUse = fieldName.split('.')[1]

      const lossPercentage = commercialVCLossPercentageByType[modifiedUse] || 0
      const rent = potentialCommercialIncomeByType[modifiedUse] || 0
      const lossAmount = rent * lossPercentage

      const commercialVCLossAmount = sumBy(Object.keys(includedUses), use => {
        if (use === modifiedUse) {
          return lossAmount
        }

        return commercialVCLossAmountByType[use] || 0
      })

      return {
        commercialVCLossAmount,
        [`commercialVCLossAmountByType.${modifiedUse}`]: lossAmount,
        commercialVCLossPercentageAggregate: CommercialCalculations.calculateVCLossPercentageAggregate(
          commercialVCLossAmount,
          potentialCommercialIncome
        ),
      }
    },
  }),
]

export const residentialLossCalculator = [
  createDecorator({
    field: ['residentialVCLossPercentage'],
    updates: (value, fieldName, allValues) => {
      const potentialResidentialIncome = get(allValues, 'potentialResidentialIncome') || 0
      const percentage = get(allValues, 'residentialVCLossPercentage') || 0
      return {
        residentialVCLossAmount: percentage * potentialResidentialIncome,
      }
    },
  }),
]
