import React from 'react'

import { InputAdornment } from '@mui/material'
import { get, merge, transform } from 'lodash'

const adornmentFactory = adornment =>
  transform(
    {
      startAdornment: 'start',
      endAdornment: 'end',
    },
    (result, value, key) => {
      const element = get(adornment, value)
      element &&
        merge(result, {
          [key]: <InputAdornment position={value}>{element}</InputAdornment>,
        })
    },
    {}
  )

const useAdornments = ({ InputProps, adornment }) => {
  const adornments = React.useRef(adornment ? merge(adornmentFactory(adornment), InputProps) : null)
  return adornments.current || InputProps
}

const useInputPropsModified = ({ InputProps, format }) => {
  const inputProps = React.useRef(format ? merge({ inputProps: format }, InputProps) : null)
  return inputProps.current || InputProps
}

export { useAdornments, useInputPropsModified }
