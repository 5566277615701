import React from 'react'
import PropTypes from 'prop-types'

import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import { Accordion, AccordionDetails, AccordionSummary, Stack, Typography } from '@mui/material'

import arrayMutators from 'final-form-arrays'
import createDecorator from 'final-form-calculate'
import { FieldArray } from 'react-final-form-arrays'
import { eq, get, sumBy } from 'lodash'

import NumberField, { NumberComponent } from 'client-shared/components/Number/Number'
import wrapForm from 'report/forms/wrapForm'
import { Area, Template } from 'client-shared/components/Template'
import { RadioButtonList, CalloutButton, GeneratedComment, CheckboxWithLabel } from 'client-shared/components'
import { formatCurrencyFloat } from 'client-shared/utils/numberFormatters'
import { fromPercents, toPercents } from 'client-shared/utils/numberOperations'
import { PropertyTypes } from 'shared/constants'

import { ANNUAL_MULTIPLIER, MAX_VC_LOSS_PERCENTAGE_VALUE } from '../constants'

import SpaceRentalTable from './SpaceRentalTable'
import ComparableParkingTable from './ComparableParkingTable'
import {
  DATA_PATH,
  HEADING,
  NUMBER_OF_MONTHS,
  PARKING_VC_LOSS_OPTIONS,
  ParkingVCLossTypes,
  VC_LOSS_OPTIONS_COMMERCIAL,
  WARNING_TEXT,
} from './constants'

class ParkingIncome extends React.PureComponent {
  getTotalAnnualParkingIncome = () => {
    const { spaces } = this.props.form.values
    const totalMonthlyRent = sumBy(spaces, space => space.monthlyRent || 0) || 0
    return totalMonthlyRent * ANNUAL_MULTIPLIER
  }

  getParkingSpaceCount = () => {
    const { spaces } = this.props.form.values
    return spaces.length
  }

  generateParkingIncomeDiscussion = () => {
    const { form } = this.props
    const { isParkingFree } = form.values

    const parkingSpaceCount = this.getParkingSpaceCount()

    const paragraph =
      `The subject property has ${parkingSpaceCount} ` +
      `parking space${parkingSpaceCount > 1 ? 's' : ''} available${
        !isParkingFree ? ' for lease' : '. Parking is a free amenity'
      }.`

    if (isParkingFree) {
      return paragraph
    }

    const totalAnnualParkingIncome = this.getTotalAnnualParkingIncome()
    const monthlyAmount = totalAnnualParkingIncome / parkingSpaceCount / NUMBER_OF_MONTHS

    return (
      `${paragraph} The owner reported this income at ${formatCurrencyFloat(totalAnnualParkingIncome)} annually, ` +
      `which equates to ${formatCurrencyFloat(monthlyAmount)} per space per month.`
    )
  }

  generateParkingComparablesDiscussion = () => {
    return (
      "We present comparable parking spaces in the subject's area in order to determine the reasonableness of the subject's rents. " +
      "Based on the parking comparables below, the subject's parking income is market oriented and will be applied in the stabilized pro forma."
    )
  }

  copyFieldToAll = (tableName, fieldName) => {
    const { form } = this.props
    const spaces = get(form, `values.${tableName}`, [])

    const updateSpaces = spaces.map(item => ({
      ...item,
      [fieldName]: get(spaces, `0.${fieldName}`),
    }))

    form.change(tableName, updateSpaces)
  }

  renderVCLossSection = () => {
    const { form, residentialVCLossPercentage, propertyType } = this.props
    const { parkingVCLossType, parkingVCLossPercentage = 0 } = form.values

    const totalAnnualParkingIncome = this.getTotalAnnualParkingIncome()
    const isResidentialVCLoss = parkingVCLossType === ParkingVCLossTypes.RESIDENTIAL
    const VCLossPercentageValue = isResidentialVCLoss ? residentialVCLossPercentage : parkingVCLossPercentage
    const grossParkingVCLoss = totalAnnualParkingIncome * VCLossPercentageValue
    const totalEffectiveGross = totalAnnualParkingIncome - grossParkingVCLoss

    const currencyArgs = {
      adornment: '$',
      adornmentPosition: 'start',
      decimalScale: 2,
      thousandSeparator: true,
    }

    return (
      <Template
        is={`
          'radio     radio'
          'annual    .'
          'vacancy   gross'
          'effective .'
          /auto auto
        `}
        gap={1}
      >
        <Area is="radio">
          <RadioButtonList
            horizontal
            items={propertyType === PropertyTypes.COMMERCIAL ? VC_LOSS_OPTIONS_COMMERCIAL : PARKING_VC_LOSS_OPTIONS}
            label="V/C Loss"
            name="parkingVCLossType"
          />
        </Area>
        <Area is="annual">
          <NumberComponent
            disabled
            label="Total Annual Parking Income"
            value={totalAnnualParkingIncome}
            {...currencyArgs}
          />
        </Area>
        <Area is="vacancy">
          {isResidentialVCLoss ? (
            <NumberComponent
              adornment="%"
              decimalScale={2}
              disabled
              label={`${parkingVCLossType} Vacancy and Collection Loss Percentage`}
              value={toPercents(residentialVCLossPercentage)}
            />
          ) : (
            <NumberField
              adornment="%"
              decimalScale={2}
              format={toPercents}
              label={`${parkingVCLossType} Vacancy and Collection Loss Percentage`}
              max={MAX_VC_LOSS_PERCENTAGE_VALUE}
              name="parkingVCLossPercentage"
              normalize={fromPercents}
              placeholder="0.00"
              sx={{ '& .MuiOutlinedInput-notchedOutline': { borderColor: 'text.primary' } }}
            />
          )}
        </Area>
        <Area is="gross">
          <NumberComponent
            disabled
            label="Gross Parking Vacancy and Collection Loss"
            value={grossParkingVCLoss}
            {...currencyArgs}
          />
        </Area>
        <Area is="effective">
          <NumberComponent
            disabled
            label="Total Effective Gross Parking Income"
            value={totalEffectiveGross}
            {...currencyArgs}
          />
        </Area>
      </Template>
    )
  }

  render() {
    const { form, hasParking, coords } = this.props
    const { isParkingFree, hasSupportForParkingComps, comparables, basisOfAnalysis } = form.values

    const parkingSpaceCount = this.getParkingSpaceCount()
    const totalAnnualParkingIncome = this.getTotalAnnualParkingIncome()

    const shouldParkingInfoBeShown = hasParking && parkingSpaceCount > 0
    const calloutText = `You indicated that your subject property has ${parkingSpaceCount} parking spaces.`

    return (
      <Stack maxWidth={1200} spacing={2}>
        {shouldParkingInfoBeShown ? (
          <>
            <CalloutButton
              link="./amenities"
              linkText="Change"
              qa="parking-spaces-count-callout-btn"
              text={calloutText}
            />
            <Accordion defaultExpanded={true}>
              <AccordionSummary expandIcon={<ExpandMoreIcon />} data-qa="parking-income-title">
                Parking Income
              </AccordionSummary>
              <AccordionDetails>
                <Stack spacing={2}>
                  <CheckboxWithLabel name="isParkingFree" label="Parking is a free amenity." />
                  {!isParkingFree && (
                    <Stack spacing={2} sx={{ mt: 1 }}>
                      <FieldArray
                        component={SpaceRentalTable}
                        copyFieldToAll={this.copyFieldToAll}
                        isEqual={eq}
                        name="spaces"
                        totalAnnualParkingIncome={totalAnnualParkingIncome}
                      />
                      {this.renderVCLossSection()}
                    </Stack>
                  )}
                  <GeneratedComment
                    dataPath="parkingIncomeDiscussion"
                    getGeneratedText={this.generateParkingIncomeDiscussion}
                    isDynamicContent
                    label="Parking Income Discussion"
                    title="Generated Commentary"
                  />
                </Stack>
              </AccordionDetails>
            </Accordion>
            <Accordion expanded={hasSupportForParkingComps}>
              <AccordionSummary data-qa="parking-income-title">
                <Stack>
                  <Typography> Parking Comparables</Typography>
                  <CheckboxWithLabel label="Support with parking income comparables" name="hasSupportForParkingComps" />
                </Stack>
              </AccordionSummary>
              <AccordionDetails>
                <Stack spacing={2}>
                  <ComparableParkingTable
                    addRow={form.mutators.push}
                    basisOfAnalysis={basisOfAnalysis}
                    comparables={comparables}
                    coords={coords}
                    copyFieldToAll={this.copyFieldToAll}
                    removeRow={form.mutators.remove}
                  />
                  <GeneratedComment
                    dataPath="parkingComparablesDiscussion"
                    getGeneratedText={this.generateParkingComparablesDiscussion}
                    isDynamicContent
                    label="Parking Comparables Discussion"
                    title="Generated Commentary"
                  />
                </Stack>
              </AccordionDetails>
            </Accordion>
          </>
        ) : (
          <CalloutButton
            link="./amenities"
            linkText="Change"
            qa="no-parking-facility-callout-btn"
            text={WARNING_TEXT}
            variant="warn"
          />
        )}
      </Stack>
    )
  }
}

ParkingIncome.propTypes = {
  form: PropTypes.object.isRequired,
  hasParking: PropTypes.bool.isRequired,
  coords: PropTypes.object.isRequired,
  residentialVCLossPercentage: PropTypes.number.isRequired,
}

const decorator = createDecorator(
  {
    field: /comparables\[\d]\.annualRate/,
    updates: (value, fieldName, allValues) => {
      const field = fieldName.replace('annualRate', 'monthlyRate')
      return {
        [field]: value / ANNUAL_MULTIPLIER,
      }
    },
  },
  {
    field: /comparables\[\d]\.monthlyRate/,
    updates: (value, fieldName, allValues) => {
      const field = fieldName.replace('monthlyRate', 'annualRate')
      return {
        [field]: value * ANNUAL_MULTIPLIER,
      }
    },
  },
  {
    field: /isParkingFree/,
    updates: (value, fieldName, allValues) => {
      if (value) {
        const { spaces } = allValues
        return {
          spaces: spaces.map(space => {
            return {
              monthlyRent: 0,
              type: null,
              operation: null,
            }
          }),
        }
      }
      return {}
    },
  }
)

const formOptions = {
  heading: HEADING,
  mutators: {
    ...arrayMutators,
  },
  decorators: [decorator],
}

export default wrapForm(DATA_PATH, formOptions, state => ({
  hasParking: get(state, 'report.reportData.propertyInformation.amenities.building.hasParking', false),
  coords: get(state, 'report.reportData.propertyInformation.coords'),
  residentialVCLossPercentage: get(
    state,
    'report.reportData.incomeApproach.potentialGrossIncome.residentialVCLossPercentage',
    0
  ),
  propertyType: get(state, 'report.reportData.propertyInformation.propertySummary.propertyType'),
}))(ParkingIncome)
