import React from 'react'

import { get, keyBy } from 'lodash'
import createDecorator from 'final-form-calculate'
import arrayMutators from 'final-form-arrays'

import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Grid,
  Paper,
  Typography,
} from '@material-ui/core'

import PropTypes from 'prop-types'

import { RESIDENTIAL_UNIT_GROUPS_PATH } from 'shared/constants/report/keysAndDataPaths'
import { regroupSubjectUnits } from 'shared/helpers/incomeApproach'

import wrapForm from '../../../../wrapForm'

import Button from '../../../../../../shared/components/Button'
import TitleWithIcons from '../../../../../../shared/components/TitleWithIcons'
import { MaterialRadioButtons as RadioButtonList } from '../../../../../../shared/components/RadioButtons/RadioButtonList'
import { SUBJECT_UNIT_GROUPING_LIST_OPTIONS } from '../../ResidentialRentRoll/constants'

import { getResidentialUnitSummary } from '../../ResidentialRentRoll/tools'

import ResidentialUnitGroupsTable from './ResidentialUnitGroupsTable'
import RentRollAverageSizeCalculator from './RentRollAverageSizeCalculator'

export const DATA_PATH = RESIDENTIAL_UNIT_GROUPS_PATH

const styles = theme => ({
  groupingLabel: {
    textAlign: 'left',
    fontSize: 24,
    marginBottom: 10,
  },
  paper: theme.paper,
  table: {
    '& thead tr, & tbody tr': {
      display: 'table-row',
    },
  },
  moveCell: {
    width: '5%',
  },
  totalRow: {
    '& td': {
      fontWeight: theme.typography.fontWeightMedium,
    },
  },
  actionsContainer: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  dialogContent: {
    width: 450,
    padding: theme.spacing.unit * 2,
    paddingTop: theme.spacing.unit * 4,
  },
  dialogText: {
    color: theme.palette.text.primary,
  },
  dialogTitle: {
    color: theme.palette.primary[900],
  },
})

const getGroupingOptions = (bathrooms, outdoorSpace, unitLayout) => {
  let options = Object.values(SUBJECT_UNIT_GROUPING_LIST_OPTIONS)
  if (!unitLayout) {
    options = options.filter(option => option.value !== 'bedroomAndUnitLayout')
  }
  if (!bathrooms) {
    options = options.filter(option => option.value !== 'bedroomAndBathroom')
  }
  if (!outdoorSpace) {
    options = options.filter(option => option.value !== 'bedroomAndOutdoorSpace')
  }
  return options
}

class ResidentialUnitGroups extends React.PureComponent {
  static propTypes = {
    commercialArea: PropTypes.number.isRequired,
    includePerRoomAnalysis: PropTypes.bool.isRequired,
  }
  static defaultProps = {}

  state = {
    isWarningModalOpen: false,
    nextGroupingType: '',
  }

  onGroupingTypeChange = (event, value) => {
    this.setState({
      isWarningModalOpen: true,
      nextGroupingType: value,
    })
  }

  handleCancel = () => {
    this.setState({ isWarningModalOpen: false, nextGroupingType: '' })
  }

  handleChange = () => {
    const { form } = this.props
    const { nextGroupingType } = this.state
    const { groups, units } = form.values
    const unitGroups = regroupSubjectUnits(nextGroupingType, groups, units)

    form.batch(() => {
      form.change('groupingType', nextGroupingType)
      form.change('groups', unitGroups)
    })

    this.setState({
      isWarningModalOpen: false,
      nextGroupingType: '',
    })
  }

  onReGroup = updatedGroups => {
    const { form } = this.props
    form.change('groups', updatedGroups)
  }

  render() {
    const {
      form,
      classes,
      unitLayout,
      commercialArea,
      perUnitSF: showPerUnitSF,
      includePerRoomAnalysis,
      groupingOptions,
    } = this.props
    const { groupingType, groups, units, showDevelopersForecast } = form.values

    return (
      <Grid container>
        <Grid item xs={12} md={8}>
          <Paper className={classes.paper}>
            <Typography variant="h6">Residential Rent Comp Groups</Typography>
            <RadioButtonList
              name="groupingType"
              value={groupingType}
              onChange={this.onGroupingTypeChange}
              label={
                <TitleWithIcons
                  title="Select Comp Group"
                  tooltipText="This selection will set the unit categories for the following pages: Residential Rent Comps, Residential Rent Summary, Residential Rent Reconciliation, Projected Rent Roll."
                  leftIcon={null}
                />
              }
              items={groupingOptions}
              horizontal={true}
            />
            <ResidentialUnitGroupsTable
              unitGroups={groups}
              onReGroup={this.onReGroup}
              showUnitLayout={unitLayout}
              showDevelopersForecast={showDevelopersForecast}
              showPerRoomAnalysis={includePerRoomAnalysis}
            />
            <Dialog open={this.state.isWarningModalOpen} aria-labelledby="form-dialog-title">
              <div className={classes.dialogContent}>
                <DialogTitle id="form-dialog-title" disableTypography>
                  <Typography className={classes.dialogTitle} variant="h6">
                    Change Unit Group Selection
                  </Typography>
                </DialogTitle>
                <DialogContent>
                  <DialogContentText className={classes.dialogText}>
                    Changing unit group selection will alter calculations or delete added comps on the following pages:
                  </DialogContentText>
                  <ul>
                    <li>{'Income > Residential > Rent Roll Summary'}</li>
                    <li>{'Income > Residential > Rent Comps'}</li>
                    <li>{'Income > Residential > Rent Comps Map'}</li>
                    <li>{'Income > Residential > Rent Reconciliation'}</li>
                    <li>{'Income > Residential > Projected Rent Roll'}</li>
                  </ul>
                </DialogContent>
                <DialogActions classes={{ root: classes.actionsContainer }}>
                  <Button onClick={this.handleCancel} color="primary">
                    Cancel
                  </Button>
                  <Button wide variant="contained" onClick={this.handleChange} color="error">
                    Change
                  </Button>
                </DialogActions>
              </div>
            </Dialog>
          </Paper>
        </Grid>
        <Grid item xs={12}>
          <RentRollAverageSizeCalculator
            commercialArea={commercialArea}
            form={form}
            units={units}
            showPerUnitSF={showPerUnitSF}
          />
        </Grid>
      </Grid>
    )
  }
}

const decorator = createDecorator(
  {
    field: 'grossLeasableAreaPercentage',
    updates: {
      grossLeasableArea: (percentage, allValues) => {
        const { grossResidentialArea } = allValues
        if (percentage >= 0 && grossResidentialArea >= 0) {
          return percentage * grossResidentialArea
        } else {
          return null
        }
      },
    },
  },
  {
    field: 'groups',
    updates: {
      unitGroupSummary: (groups, allValues) => {
        const { showDevelopersForecast } = allValues

        return groups.map(group => {
          const units = group.units
          return {
            ...group,
            groupingParameters: keyBy(group.groupingParameters, 'path'),
            units,
            ...getResidentialUnitSummary(units, showDevelopersForecast),
          }
        })
      },
    },
  }
)

const formOptions = {
  styles,
  heading: 'Unit Groups',
  keepDirtyOnReinitialize: true,
  mutators: { ...arrayMutators },
  decorators: [decorator],
  registeredFields: ['groups', 'groupingType'],
}

export default wrapForm(DATA_PATH, formOptions, state => {
  const formValues = get(state, 'report.reportData.incomeApproach.residentialIncome.residentialUnitGroups', {})
  const { units, bathrooms, outdoorSpace, unitLayout, includePerRoomAnalysis, perUnitSF } = get(
    state,
    'report.reportData.incomeApproach.residentialIncome.residentialRentRoll',
    {}
  )

  return {
    unitLayout,
    includePerRoomAnalysis,
    perUnitSF,
    groupingOptions: getGroupingOptions(bathrooms, outdoorSpace, unitLayout),
    commercialArea: get(state, 'report.reportData.propertyInformation.commercialUnits.commercialSquareFootage', 0),
    initialValues: {
      units,
      ...formValues,
    },
  }
})(ResidentialUnitGroups)
