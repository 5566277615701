import React from 'react'
import PropTypes from 'prop-types'
import { Typography, Grid, Popover } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'
import { compose, pure } from 'recompose'

import { Button } from '../../../../../../shared/components'
import { FILTERS_BUTTON_LABEL, SEARCH_RESULTS_LABEL, CLOSE_LABEL, CANCEL_LABEL, SEARCH_LABEL } from '../constants'

const FiltersToolbarComponent = ({ classes, buttonColor, buttonLabel, searchResultsCount, onClick }) => {
  return (
    <Grid container alignItems="center" spacing={0}>
      <Grid item>
        <Button variant="contained" wide className={classes.button} color={buttonColor} onClick={onClick}>
          {buttonLabel}
        </Button>
      </Grid>
      <Grid item>
        <Typography type="subtitle1" color="primary" className={classes.searchResultText}>
          {searchResultsCount} {SEARCH_RESULTS_LABEL}
        </Typography>
      </Grid>
    </Grid>
  )
}
const toolbarStyles = theme => ({
  button: {
    margin: theme.spacing.unit / 2,
    width: 80,
  },
  searchResultText: {
    marginLeft: theme.spacing.unit * 2,
    color: theme.palette.primary.main,
  },
})

const FiltersToolbar = compose(withStyles(toolbarStyles), pure)(FiltersToolbarComponent)

const styles = theme => ({
  popoverContainer: {
    marginTop: theme.spacing.unit * 2,
    padding: theme.spacing.unit * 2,
  },
})

class FiltersPanel extends React.PureComponent {
  static propTypes = {
    children: PropTypes.oneOfType([PropTypes.element, PropTypes.arrayOf(PropTypes.element)]).isRequired,
    searchResultsCount: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    onCancel: PropTypes.func.isRequired,
    onSearch: PropTypes.func.isRequired,
  }

  state = {
    anchorElement: null,
  }

  openFilterPopover = event => {
    event.persist()
    this.setState({
      anchorElement: event.currentTarget,
    })
  }

  closeFilterPopover = () => {
    this.setState(prevState => ({
      anchorElement: null,
    }))
  }

  handleOnSearch = () => {
    this.closeFilterPopover()
    this.props.onSearch()
  }

  handleOnCancel = () => {
    this.props.onCancel()
  }

  render() {
    const { classes, children, searchResultsCount } = this.props
    const { anchorElement } = this.state
    const isPopoverOpened = !!anchorElement
    const buttonColor = isPopoverOpened ? 'error' : ''
    const buttonLabel = isPopoverOpened ? CLOSE_LABEL : FILTERS_BUTTON_LABEL

    return (
      <React.Fragment>
        <FiltersToolbar
          buttonColor={buttonColor}
          buttonLabel={buttonLabel}
          searchResultsCount={searchResultsCount}
          onClick={this.openFilterPopover}
        />
        <Popover
          keepMounted
          open={isPopoverOpened}
          anchorEl={anchorElement}
          onClose={this.closeFilterPopover}
          classes={{ paper: classes.popoverContainer }}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
        >
          {children}
          <Grid container justify="flex-end" spacing={0}>
            <Grid item>
              <Button onClick={this.handleOnCancel}>{CANCEL_LABEL}</Button>
              <Button onClick={this.handleOnSearch} variant="contained">
                {SEARCH_LABEL}
              </Button>
            </Grid>
          </Grid>
        </Popover>
      </React.Fragment>
    )
  }
}

export default withStyles(styles)(FiltersPanel)
