import React from 'react'

import { Typography } from '@mui/material'

import { PropertyTypes } from 'shared/constants'

import { APPROACH_TYPES } from 'shared/constants/report'

import { TEMPLATE_TYPES, VALUE_CONCLUSION_TYPES } from '../../constants/settings'

const Label = ({ html }) => (html ? <Typography variant="body1" dangerouslySetInnerHTML={{ __html: html }} /> : null)

const templateTypes = {
  [TEMPLATE_TYPES.FREDDIE_MAC]: 'This report <b>must</b> adhere to Freddie Mac specifications.',
  default: 'This report does not need to adhere to Freddie Mac specifications.',
}

const valueConclusionTypes = {
  [VALUE_CONCLUSION_TYPES.AS_COMPLETE]:
    ' We value the subject property <b>As Is</b>, <b>As Complete</b>, and <b>As Stabilized</b>.',
  [VALUE_CONCLUSION_TYPES.AS_IS]: 'We value the subject property <b>As Is</b>.',
  [VALUE_CONCLUSION_TYPES.AS_STABILIZED]: 'We value the subject property <b>As Is</b> and <b>As Stabilized</b>.',
}

const incomeTypes = {
  [PropertyTypes.COMMERCIAL]: 'The subject property has <b>only</b> commercial income.',
  [PropertyTypes.MIXED_USE]: 'The subject property has <b>both</b> residential and commercial income.',
  [PropertyTypes.MULTI_FAMILY]: 'The subject property has <b>only</b> residential income.',
}

const approachTypeLabels = {
  [APPROACH_TYPES.INCOME]: '<b>Income</b> Approach',
  [APPROACH_TYPES.IMPROVED_SALES]: '<b>Sales</b> Approach',
  [APPROACH_TYPES.LAND]: '<b>Land</b> Valuation',
  [APPROACH_TYPES.COST]: '<b>Cost</b> Approach',
}

const getApproachesLabel = ({ approaches }) => {
  let approachesString = ``

  const selectedApproaches = Object.entries(approaches)
    .filter(([, val]) => val === true)
    .map(val => val[0])
  for (const [index, selectedApproach] of selectedApproaches.entries()) {
    approachesString += `${approachTypeLabels[selectedApproach]}${index !== selectedApproaches.length - 1 ? ', ' : '.'}`
  }

  return approachesString
}

const labelFactory =
  labels =>
  ({ value }) =>
    <Label html={labels[value] || labels.default} />

export const IncomeTypeLabel = labelFactory(incomeTypes)
export const TemplateTypeLabel = labelFactory(templateTypes)
export const ValueConclusionTypeLabel = labelFactory(valueConclusionTypes)
export const ApproachesLabel = approaches => <Label html={getApproachesLabel(approaches)} />
