import React from 'react'
import PropTypes from 'prop-types'

import { mean, get, isFinite } from 'lodash'
import {
  Button,
  Paper,
  Stack,
  Table,
  TableCell,
  TableFooter,
  TableHead,
  TableRow,
  Typography,
  Box,
} from '@mui/material'

import Remove from '@mui/icons-material/Remove'
import Dialpad from '@mui/icons-material/Dialpad'
import { formatCurrencyFloat, formatFloat, formatInt } from 'client-shared/utils/numberFormatters'
import { IconButton, Text, Number as NumberField, CheckboxWithLabel } from 'client-shared/components'

import { TableBody } from '../../components/Table'
import { UNIT_MIX_COLUMN_NAMES } from '../../constants'

import { maxRentValidation, minRentValidation, avgRentValidation, bathroomsValidation } from './validation'
import NewUnitMixItemPanel from './NewUnitMixItemPanel'

class UnitMixTableComponent extends React.PureComponent {
  constructor(props) {
    super(props)
    this.validations = {
      [UNIT_MIX_COLUMN_NAMES.MAX_RENT]: maxRentValidation,
      [UNIT_MIX_COLUMN_NAMES.AVG_RENT]: avgRentValidation,
      [UNIT_MIX_COLUMN_NAMES.MIN_RENT]: minRentValidation,
      [UNIT_MIX_COLUMN_NAMES.AVG_BATHROOMS]: bathroomsValidation,
    }
  }

  renderTableCell = name => {
    const { disabled } = this.props
    const validations = this.validations[name]

    switch (name) {
      case UNIT_MIX_COLUMN_NAMES.AVG_SQUARE_FEET:
        return (column, unitName, index, unitMix) => (
          <TableCell align="center">
            {disabled ? (
              <>
                {get(unitMix, `${index}.isEstimated`) ? 'Est ' : ''}
                {formatFloat(get(unitMix, `${index}.${column}`))}
              </>
            ) : (
              <Stack alignItems="center" direction="row" spacing={1}>
                <NumberField
                  adornment="SF"
                  decimalScale={2}
                  disabled={get(unitMix, `${index}.isNotReportedSquareFeet`)}
                  name={`${unitName}.${column}`}
                />
                <CheckboxWithLabel
                  fullWidth={false}
                  label="Est"
                  name={`${unitName}.${UNIT_MIX_COLUMN_NAMES.IS_ESTIMATED}`}
                />
              </Stack>
            )}
          </TableCell>
        )
      case UNIT_MIX_COLUMN_NAMES.UNIT_GROUP_NAME:
        return (column, unitName, index, units) => {
          return (
            <TableCell align="center">
              {disabled ? (
                <span>{get(units, `${index}.${column}`)}</span>
              ) : (
                <Text dense disabled name={`${unitName}.${column}`} />
              )}
            </TableCell>
          )
        }
      case UNIT_MIX_COLUMN_NAMES.REMOVE:
        return (_column, _unitName, index) => (
          <TableCell align="center">
            <IconButton
              onClick={() => {
                this.props.removeUnit(index)
              }}
            >
              <Remove />
            </IconButton>
          </TableCell>
        )
      case UNIT_MIX_COLUMN_NAMES.AVG_BATHROOMS:
        return (column, unitName, index, units) => (
          <TableCell align="center">
            {disabled ? (
              <span>{formatFloat(get(units, `${index}.${column}`))}</span>
            ) : (
              <NumberField
                allowNegative={false}
                decimalScale={1}
                name={`${unitName}.${column}`}
                validate={validations}
              />
            )}
          </TableCell>
        )
      case UNIT_MIX_COLUMN_NAMES.MIN_RENT:
      case UNIT_MIX_COLUMN_NAMES.AVG_RENT:
      case UNIT_MIX_COLUMN_NAMES.MAX_RENT:
        return (column, unitName, index, units) => (
          <TableCell align="center">
            {disabled ? (
              <span>{formatCurrencyFloat(get(units, `${index}.${column}`))}</span>
            ) : (
              <NumberField
                adornment="$"
                adornmentPosition="start"
                decimalScale={2}
                name={`${unitName}.${column}`}
                thousandSeparator
                warn={validations}
              />
            )}
          </TableCell>
        )
      case UNIT_MIX_COLUMN_NAMES.AVG_RENT_PER_ROOM:
      case UNIT_MIX_COLUMN_NAMES.AVG_RENT_PER_SQUARE_FEET_PER_MONTH:
      case UNIT_MIX_COLUMN_NAMES.AVG_RENT_PER_SQUARE_FEET_PER_YEAR:
        return (column, unitName, index, units) => (
          <TableCell align="center">
            {disabled ? (
              <span>{formatCurrencyFloat(get(units, `${index}.${column}`))}</span>
            ) : (
              <NumberField
                adornment="$"
                adornmentPosition="start"
                decimalScale={2}
                disabled
                name={`${unitName}.${column}`}
                thousandSeparator
              />
            )}
          </TableCell>
        )
      default:
        return (column, unitName, index, units) => (
          <TableCell align="center">
            {disabled ? (
              <span>{get(units, `${index}.${column}`)}</span>
            ) : (
              <NumberField name={`${unitName}.${column}`} />
            )}
          </TableCell>
        )
    }
  }

  renderUnitMixTable = () => {
    const { columns, disabled, name, unitMix } = this.props

    const averages = this.calculateAverages(columns, unitMix)

    const averageCells = this.renderAverages(averages)
    const tableColumns = disabled ? columns.filter(column => column.name !== UNIT_MIX_COLUMN_NAMES.REMOVE) : columns

    return (
      <Table size="small">
        <TableHead sx={{ '& .MuiTableCell-head': { fontSize: 12, fontWeight: 700 } }}>
          <TableRow>
            {tableColumns.map(({ name: columnName, title: columnTitle }) => (
              <TableCell align="center" key={columnName}>
                {columnTitle}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody name={name} columns={tableColumns} units={unitMix} cellRenderer={this.cellRenderer} />
        <TableFooter>
          <TableRow>{averageCells}</TableRow>
        </TableFooter>
      </Table>
    )
  }

  cellRenderer = props => {
    const { column, units, unitName, index } = props
    return this.renderTableCell(column.name)(column.name, unitName, index, units)
  }

  calculateAverages = (columns, unitMix) => {
    return columns.map(column => {
      const allValues = unitMix.map(data => get(data, column.name))
      const filledValues = allValues.filter(value => isFinite(value))
      return {
        value: mean(filledValues) || 0,
        name: column.name,
      }
    })
  }

  renderAverages(averages) {
    return averages.map(column => {
      switch (column.name) {
        case UNIT_MIX_COLUMN_NAMES.UNIT_GROUP_NAME:
          return (
            <TableCell align="center" key={column.name}>
              Average
            </TableCell>
          )
        case UNIT_MIX_COLUMN_NAMES.UNITS:
        case UNIT_MIX_COLUMN_NAMES.AVG_BATHROOMS:
        case UNIT_MIX_COLUMN_NAMES.AVG_ROOMS:
          return (
            <TableCell align="center" key={column.name}>
              {column.value ? formatInt(column.value) : '-'}
            </TableCell>
          )
        case UNIT_MIX_COLUMN_NAMES.MIN_RENT:
        case UNIT_MIX_COLUMN_NAMES.AVG_RENT:
        case UNIT_MIX_COLUMN_NAMES.MAX_RENT:
        case UNIT_MIX_COLUMN_NAMES.AVG_RENT_PER_ROOM:
        case UNIT_MIX_COLUMN_NAMES.AVG_RENT_PER_SQUARE_FEET_PER_MONTH:
        case UNIT_MIX_COLUMN_NAMES.AVG_RENT_PER_SQUARE_FEET_PER_YEAR:
          return (
            <TableCell align="center" key={column.name}>
              {column.value ? formatCurrencyFloat(column.value) : '-'}
            </TableCell>
          )
        case UNIT_MIX_COLUMN_NAMES.AVG_SQUARE_FEET:
          return (
            <TableCell align="center" key={column.name}>
              {this.props.disabled ? (
                <>
                  {this.props.isEstimatedUnitMixSquareFootage ? 'Est ' : ''}
                  {column.value ? `${formatFloat(column.value)}` : '-'} {' SF'}
                </>
              ) : (
                <Stack direction="row" spacing={1} alignItems="center" justifyContent="right">
                  <Box>
                    {column.value ? `${formatFloat(column.value)}` : '-'} {' SF'}
                  </Box>
                  <Box mr="-20px !important">
                    <CheckboxWithLabel fullWidth={false} label="Est" name="isEstimatedUnitMixSquareFootage" />
                  </Box>
                </Stack>
              )}
            </TableCell>
          )
        default:
          return <TableCell align="center" key={column.name} />
      }
    })
  }

  render() {
    const {
      unitGroupingType,
      bedrooms,
      bathrooms,
      outdoor,
      unitLayout,
      resetGroupingParameters,
      calculateAverages,
      disabled,
      disabledCalculation,
      addUnitMixItem,
    } = this.props

    if (disabled) {
      return this.renderUnitMixTable()
    }

    return (
      <Paper>
        <Stack spacing={2}>
          <Typography variant="h6">Unit Mix</Typography>
          <Stack alignItems="center" direction="row">
            <NewUnitMixItemPanel
              addUnitMixItem={addUnitMixItem}
              bathrooms={bathrooms}
              bedrooms={bedrooms}
              outdoor={outdoor}
              resetGroupingParameters={resetGroupingParameters}
              unitGroupingType={unitGroupingType}
              unitLayout={unitLayout}
            />
            <Button
              disabled={disabledCalculation}
              onClick={calculateAverages}
              startIcon={<Dialpad />}
              sx={{ ml: 'auto' }}
              variant="contained"
            >
              CALCULATE
            </Button>
          </Stack>
          {this.renderUnitMixTable()}
        </Stack>
      </Paper>
    )
  }
}

UnitMixTableComponent.propTypes = {
  addUnitMixItem: PropTypes.func,
  calculateAverages: PropTypes.func,
  disabled: PropTypes.bool,
  disabledCalculation: PropTypes.bool,
  isEstimatedUnitMixSquareFootage: PropTypes.bool,
  removeUnit: PropTypes.func,
  unitGroupingType: PropTypes.string,
  unitMix: PropTypes.arrayOf(PropTypes.object),
  units: PropTypes.arrayOf(PropTypes.object),
}

export const UnitMixTable = UnitMixTableComponent
