export default class ListBuilder {
  constructor(arr) {
    Object.assign(this, { arr })
  }

  compile() {
    switch (this.arr.length) {
      case 0: {
        return undefined
      }
      case 1: {
        return this.arr[0]
      }
      case 2: {
        return `${this.arr[0]} and ${this.arr[1]}`
      }
      default: {
        const listArr = []

        while (this.arr.length > 1) {
          listArr.push(`${this.arr.shift()},`)
        }
        listArr.push('and')
        listArr.push(`${this.arr[0]}`)
        return listArr.join(' ')
      }
    }
  }

  static createFrom(arr) {
    const lb = new ListBuilder(Array.from(arr))

    return lb.compile()
  }
}
