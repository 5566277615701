import { get, isFinite } from 'lodash'
import { createSelector } from 'reselect'

import { GEOGRAPHY_OPTIONS } from 'shared/constants/property/geography'

import { getCounty } from '../../../../shared/utils/geography'
import getFARForZone, { calculateFloorAreaRatio } from '../../../../shared/utils/zoningHelper'
import { getDisplayAddress } from '../../../../shared/utils/propertyHelper'
import { createDeepEqualSelector } from '../../../../shared/utils/selectors'
import { TEMPLATE_TYPES, VALUE_CONCLUSION_TYPES } from '../../../../report/constants/settings'

import { formatFloat } from '../../../../shared/utils/numberFormatters'
import { zoningFloorArea as zoningFloorAreaCalculator } from '../../../../shared/utils/calculations'

import { ONE_HUNDRED_PERCENT, ZONE_NAMES } from './constants'

const formValuesSelector = (state, props) => {
  return get(props, 'form.values', {})
}

export const selectReportInformation = state => {
  const locationIdentifier = get(state, 'report.reportData.locationIdentifier')
  return {
    reportId: get(state, 'report.reportData._id'),
    propertyType: get(state, 'report.reportData.propertyType'),
    fullAddress: get(state, 'report.reportData.address'),
    isNYCReport: locationIdentifier === GEOGRAPHY_OPTIONS.NY,
    isNJReport: locationIdentifier === GEOGRAPHY_OPTIONS.NJ,
    locationIdentifier,
    isFreddieMacReport: get(state, 'report.reportSettings.templateType') === TEMPLATE_TYPES.FREDDIE_MAC,
    isAsComplete: get(state, 'report.reportSettings.valueConclusionType') === VALUE_CONCLUSION_TYPES.AS_COMPLETE,
  }
}

const selectAddress = state => {
  const address = get(state, 'report.reportData.address')
  const block = get(state, 'report.reportData.block')
  const lot = get(state, 'report.reportData.lot')
  const borough = get(state, 'report.reportData.borough')
  const city = get(state, 'report.reportData.propertyInformation.propertySummary.city')
  const reportState = get(state, 'report.reportData.state')
  const zip = get(state, 'report.reportData.zip')
  const county = get(state, 'report.reportData.county') || getCounty({ city, state: reportState, borough })

  const propertyIdentifier = get(state, 'report.reportData.propertyIdentifier')
  const propertyIdentifierType = get(
    state,
    'report.reportData.propertyInformation.propertySummary.propertyIdentifierType'
  )
  const locationIdentifier = get(state, 'report.reportData.locationIdentifier')
  return {
    address,
    state: reportState,
    borough,
    city,
    county,
    zip,
    block,
    lot,
    propertyIdentifier,
    propertyIdentifierType,
    locationIdentifier,
  }
}

const siteAreaMeasureSelector = state =>
  get(state, 'report.reportData.propertyInformation.propertySummary.siteAreaMeasure')

const propertyInformationSelector = createSelector(
  selectAddress,
  selectReportInformation,
  (address, reportInformation) => {
    return { ...address, ...reportInformation }
  }
)

export const subjectAddressSelector = createSelector(selectAddress, address => {
  return getDisplayAddress({
    address: address.address,
    city: address.city,
    state: address.state,
    zip: address.zip,
  })
})

export const conformingUseSelector = createDeepEqualSelector(
  selectReportInformation,
  (state, ownProps) => {
    const formValues = formValuesSelector(state, ownProps)
    return {
      permittedUses: formValues.permittedUses,
      propertyUses: formValues.propertyUses,
      currentUses: formValues.currentUses,
      zones: formValues.zones,
      isConformingWithAllowableUses: formValues.isConformingWithAllowableUses,
      yearBuilt: get(state, 'report.reportData.propertyInformation.propertySummary.yearBuilt'),
      reportType: get(state, 'report.reportData.propertyType'),
      fullAddress: get(state, 'report.reportData.address'),
    }
  },
  (
    reportInformation,
    {
      permittedUses,
      propertyUses,
      currentUses,
      zones,
      isConformingWithAllowableUses,
      yearBuilt,
      fullAddress,
      reportType,
    }
  ) => {
    const zoneNames = zones.map((zoneItem, index) => zoneItem.zone || `[${ZONE_NAMES[index]}]`)
    const commercialOverlays = zones.flatMap(zone => zone.commercialOverlay || [])

    return {
      ...reportInformation,
      reportType,
      propertyUses,
      currentUses,
      permittedUses,
      zoneNames,
      isConformingWithAllowableUses,
      commercialOverlays,
      yearBuilt,
      fullAddress,
    }
  }
)

export const complyingBulkSelector = createSelector(
  formValuesSelector,
  selectReportInformation,
  siteAreaMeasureSelector,
  (formValues = {}, reportInformation, siteAreaMeasure) => {
    const historicDistrict = get(formValues, 'historicDistrict')
    const landmark = get(formValues, 'landmark')
    const floorAreaRatio = formatFloat(get(formValues, 'floorAreaRatio', 0))
    const mechanicalDeductions = get(formValues, 'mechanicalDeductions', 0)
    const grossBuildingArea = get(formValues, 'grossBuildingArea', 0)
    const siteArea = get(formValues, 'siteArea', 0)
    const isComplyingWithRegardToBulk = get(formValues, 'isComplyingWithRegardToBulk', true)
    const regulations = get(formValues, 'regulations') || []

    let totalZonePercentage = 0
    const zones = get(formValues, 'zones') || []
    zones.forEach(zone => {
      const zoneFAR = getFARForZone(zone.zone)
      const zonePercentage = zone.percentage || 0
      totalZonePercentage += zonePercentage
      zone.floorAreaRatio = zoneFAR
    })

    const hasValidZonePercentages = totalZonePercentage === ONE_HUNDRED_PERCENT
    const calculatedFloorAreaRatio = calculateFloorAreaRatio(formValues)
    const zoningFloorArea = zoningFloorAreaCalculator(grossBuildingArea, mechanicalDeductions)
    const maxBuildableArea = Math.round(siteArea * floorAreaRatio)

    return {
      ...reportInformation,
      grossBuildingArea,
      siteArea,
      siteAreaMeasure,
      mechanicalDeductions,
      floorAreaRatio,
      calculatedFloorAreaRatio,
      zoningFloorArea,
      maxBuildableArea,
      hasValidZonePercentages,
      isComplyingWithRegardToBulk,
      regulations,
      historicDistrict,
      landmark,
    }
  }
)

export const zoningInformationSelector = createSelector(
  formValuesSelector,
  propertyInformationSelector,
  (formValues = {}, propertyInformation) => {
    const zones = get(formValues, 'zones') || []
    const siteArea = get(formValues, 'siteArea', 0)
    const generatedCommentary = get(formValues, 'generatedCommentary', '')

    let totalPercentage = 0
    zones.forEach(zone => {
      if (zone.zone) {
        const zoneName = zone.zone.split('/')[0]
        const zoneFloorAreaRating = getFARForZone(zoneName)
        zone.floorAreaRatio = zoneFloorAreaRating ? zoneFloorAreaRating.toFixed(2) : null
      }

      if (isFinite(zone.percentage)) {
        totalPercentage += zone.percentage
      }
    })

    return {
      zones,
      isValidPercentage: totalPercentage === ONE_HUNDRED_PERCENT,
      ...propertyInformation,
      siteArea,
      generatedCommentary,
    }
  }
)

export const parkingConformitySelector = createSelector(
  formValuesSelector,
  selectReportInformation,
  selectAddress,
  (formValues = {}, reportInformation, address) => {
    const {
      requiredParkingPerUnit,
      requiredParkingPerSF,
      requiredParkingSpaces,
      isConformingWithParkingRequirements,
      residentialUnits,
      actualParkingSpaces,
      areWaivedParkingRequirements,
      waiverBasis,
      grossBuildingArea,
    } = formValues

    return {
      ...reportInformation,
      address,
      residentialUnits,
      grossBuildingArea,
      actualParkingSpaces,
      requiredParkingSpaces,
      requiredParkingPerUnit,
      requiredParkingPerSF,
      isConformingWithParkingRequirements,
      areWaivedParkingRequirements,
      waiverBasis,
    }
  }
)

export const propertyIdentificationSelector = createSelector(
  formValuesSelector,
  selectReportInformation,
  selectAddress,
  siteAreaMeasureSelector,
  (formValues = {}, reportInformation, address, siteAreaMeasure) => {
    const siteArea = get(formValues, 'siteArea', 0)
    const zones = get(formValues, 'zones') || []
    return { siteArea, ...reportInformation, address, zones, siteAreaMeasure }
  }
)

export const complyingBulkInputsSelector = createSelector(
  formValuesSelector,
  siteAreaMeasureSelector,
  (formValues = {}, siteAreaMeasure) => {
    const floorAreaRatio = formatFloat(get(formValues, 'floorAreaRatio'))
    const mechanicalDeductions = get(formValues, 'mechanicalDeductions', 0)
    const grossBuildingArea = get(formValues, 'grossBuildingArea', 0)
    const siteArea = get(formValues, 'siteArea', 0)
    const zones = get(formValues, 'zones') || []

    const maxBuildableArea = siteArea * floorAreaRatio
    const zoningFloorArea = zoningFloorAreaCalculator(grossBuildingArea, mechanicalDeductions)

    let calculatedFloorAreaRatio = 0
    let totalZonePercentage = 0
    zones.forEach(zone => {
      const zoneFAR = getFARForZone(zone.zone)
      const zonePercentage = zone.percentage || 0
      totalZonePercentage += zonePercentage
      calculatedFloorAreaRatio += (zoneFAR * zonePercentage) / 100
    })

    const hasValidZonePercentages = totalZonePercentage === ONE_HUNDRED_PERCENT
    const siteAreaParts = String(siteArea).split('.')
    const siteAreaDecimalPartLength = get(siteAreaParts, '1.length', 0)

    return {
      floorAreaRatio,
      siteAreaDecimalPartLength,
      maxBuildableArea,
      zoningFloorArea,
      calculatedFloorAreaRatio,
      hasValidZonePercentages,
      siteAreaMeasure,
    }
  }
)
