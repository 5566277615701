import React from 'react'
import PropTypes from 'prop-types'
import { compose, pure } from 'recompose'
import { MenuItem, Select, OutlinedInput, withStyles } from '@material-ui/core'

const styles = theme => {
  const text = {
    fontSize: 14,
    lineHeight: 1.43,
    letterSpacing: 0.3,
    color: 'rgba(0, 0, 0, 0.87)',
  }

  return {
    inputRoot: {
      width: '100%',
      height: 49,
      '& $notchedOutline': {
        borderColor: 'transparent',
      },
      ...text,
    },
    placeholder: {
      color: theme.palette.secondary[400],
    },
    menuContainer: {},
    notchedOutline: {},
    menuItemRoot: {
      height: 32,
      paddingTop: 0,
      paddingBottom: 0,
      ...text,
    },
    icon: {},
    focusedInput: {
      '& $icon': {
        color: theme.palette.primary[900],
      },
    },
    select: {
      '&:focus': {
        backgroundColor: 'transparent',
      },
    },
  }
}

const DropDownFilterInput = ({ classes, name, options, value, onChange, renderOption }) => {
  const renderValue = value => {
    return value || <span className={classes.placeholder}>Any</span>
  }

  return (
    <Select
      margin="none"
      placeholder="Any"
      value={value}
      onChange={onChange}
      classes={{ icon: classes.icon, select: classes.select }}
      input={
        <OutlinedInput
          classes={{ root: classes.inputRoot, notchedOutline: classes.notchedOutline, focused: classes.focusedInput }}
          name={name}
          labelWidth={0}
        />
      }
      displayEmpty={true}
      renderValue={renderValue}
      MenuProps={{
        classes: {
          paper: classes.menuContainer,
        },
        getContentAnchorEl: null,
        anchorOrigin: {
          vertical: 'bottom',
          horizontal: 'left',
        },
        disablePortal: true,
        disableAutoFocus: true,
      }}
    >
      <MenuItem classes={{ root: classes.menuItemRoot }} key="any" value={null}>
        Any
      </MenuItem>
      {options.map(option => (
        <MenuItem classes={{ root: classes.menuItemRoot }} key={option.value} value={option.value}>
          {renderOption(option)}
        </MenuItem>
      ))}
    </Select>
  )
}

DropDownFilterInput.propTypes = {
  name: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.string.isRequired,
    })
  ).isRequired,
  onChange: PropTypes.func.isRequired,
  renderOption: PropTypes.func.isRequired,
}

export default compose(pure, withStyles(styles))(DropDownFilterInput)
