import React from 'react'

import { FormHelperText, Stack, Typography, Box } from '@mui/material'

import { analyzeDocumentText } from '../../core/api'
import { Loading } from '../../shared/components'
import FileInputButton from '../../shared/components/FileInput/FileInputButton'

const initialState = {
  isLoading: false,
  excelWorkbook: '',
  txtLink: '',
}

const reducer = (state, { type, payload }) => {
  switch (type) {
    case 'loading':
      return { ...state, isLoading: true }
    case 'loaded':
      return { ...state, isLoading: false, ...payload }
    case 'reset':
      return initialState
    default:
      throw new Error('Wrong action type passed')
  }
}

const DataExtractionTool = () => {
  const [state, dispatch] = React.useReducer(reducer, initialState)

  const processFile = React.useCallback(async files => {
    const [file] = files
    dispatch({ type: 'loading' })

    try {
      console.log('Data Extraction Tool analyzing document...')

      const response = await analyzeDocumentText(file)
      dispatch({ type: 'loaded', payload: { excelWorkbook: response.excelWorkbook, txtLink: response.txtLink } })
    } catch {
      console.error('Data Extraction Tool failed to analyze...')

      dispatch({ type: 'reset' })
    }
  }, [])

  const { isLoading, txtLink, excelWorkbook } = state
  return (
    <>
      {isLoading && <Loading />}
      <Stack direction="row" pt={10} px={3} spacing={2}>
        <Stack spacing={1}>
          <Typography variant="h6" marginBottom={2}>
            Data Extraction Tool
          </Typography>
          <Box>
            <FileInputButton text="Upload File" onChange={processFile} accept=".png,.jpg,.jpeg,.pdf" />
          </Box>
          <FormHelperText>Upload PNG, JPEG, or PDF files.</FormHelperText>
        </Stack>
        <Stack spacing={1} p={1} alignSelf="end">
          {excelWorkbook && (
            <a href={excelWorkbook} target="_blank" rel="noopener noreferrer">
              Download Workbook With Data
            </a>
          )}
          {txtLink && (
            <a href={txtLink} target="_blank" rel="noopener noreferrer">
              Download Raw Text File
            </a>
          )}
        </Stack>
      </Stack>
    </>
  )
}

export default React.memo(DataExtractionTool)
