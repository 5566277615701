import {
  PROPERTY_INFO_KEY,
  PROPERTY_SUMMARY_KEY,
  PROPERTY_MARKET_KEY,
  PROPERTY_HISTORY_KEY,
  GENERAL_PROPERTY_DESCRIPTION_KEY,
  SITE_DESCRIPTION_KEY,
  TYPICAL_RESIDENTIAL_CONDITIONS_KEY,
  COMMERCIAL_UNITS_KEY,
  UTILITIES_KEY,
  AMENITIES_KEY,
  PROPERTY_MAPS_KEY,
  COMPARABLE_MAPS_KEY,
  PROPERTY_PHOTOS_KEY,
  ZONING_KEY,
  RENOVATIONS_KEY,
  INSURABLE_VALUE_KEY,
} from 'shared/constants/report/keysAndDataPaths'

import PropertySummary from '../../../forms/property/PropertySummary'
import PropertyMarket from '../../../forms/property/PropertyMarket'
import PropertyHistory from '../../../forms/property/PropertyHistory'
import CommercialUnits from '../../../forms/property/CommercialUnits'
import Utilities from '../../../forms/property/Utilities'
import Zoning from '../../../forms/property/Zoning'
import GeneralPropertyDescription from '../../../forms/property/GeneralPropertyDescription'
import SiteDescription from '../../../forms/property/SiteDescription'
import PropertyMaps from '../../../forms/property/PropertyMaps'
import ComparableMaps from '../../../forms/property/ComparableMaps'
import PropertyPhotos from '../../../forms/property/PropertyPhotos'
import Renovations from '../../../forms/property/Renovations'
import Amenities from '../../../forms/property/Amenities'
import TypicalResidentialConditions from '../../../forms/property/TypicalResidentialConditions'
import InsurableValue from '../../../forms/property/InsurableValue/InsurableValue'

export default [
  {
    key: PROPERTY_INFO_KEY,
    name: 'Property',
  },
  {
    key: PROPERTY_SUMMARY_KEY,
    name: 'Summary',
    formComponent: PropertySummary,
  },
  {
    key: PROPERTY_MARKET_KEY,
    name: 'Market',
    formComponent: PropertyMarket,
  },
  {
    key: PROPERTY_HISTORY_KEY,
    name: 'Property History',
    formComponent: PropertyHistory,
  },
  {
    key: GENERAL_PROPERTY_DESCRIPTION_KEY,
    name: 'Property Description',
    formComponent: GeneralPropertyDescription,
  },
  {
    key: SITE_DESCRIPTION_KEY,
    name: 'Site Description',
    formComponent: SiteDescription,
  },
  {
    key: TYPICAL_RESIDENTIAL_CONDITIONS_KEY,
    name: 'Residential Units',
    formComponent: TypicalResidentialConditions,
  },
  {
    key: COMMERCIAL_UNITS_KEY,
    name: 'Commercial Units',
    formComponent: CommercialUnits,
  },
  {
    key: UTILITIES_KEY,
    name: 'Utilities',
    formComponent: Utilities,
  },
  {
    key: AMENITIES_KEY,
    name: 'Amenities',
    formComponent: Amenities,
  },
  {
    key: PROPERTY_MAPS_KEY,
    name: 'Maps',
    formComponent: PropertyMaps,
  },
  {
    key: COMPARABLE_MAPS_KEY,
    name: 'Comparable Maps',
    formComponent: ComparableMaps,
  },
  {
    key: PROPERTY_PHOTOS_KEY,
    name: 'Photos',
    formComponent: PropertyPhotos,
  },
  {
    key: ZONING_KEY,
    name: 'Zoning',
    formComponent: Zoning,
  },
  {
    key: RENOVATIONS_KEY,
    name: 'Renovations',
    formComponent: Renovations,
  },
  {
    key: INSURABLE_VALUE_KEY,
    name: 'Insurable Value',
    formComponent: InsurableValue,
  },
]
