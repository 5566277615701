import React from 'react'
import PropTypes from 'prop-types'

import { isNil, castArray, noop, isUndefined } from 'lodash'
import { FormControl, InputAdornment, TextField } from '@mui/material'
import { Field } from 'react-final-form'
import NumberFormat from 'react-number-format'

import { minNumber, maxNumber } from '../../utils/validation'

const NON_NUMBERS_REGEX = /[^-0-9.]/g

export const NUMBER_PROPS = {
  decimalScale: PropTypes.number,
  fixedDecimalScale: PropTypes.bool,
  allowNegative: PropTypes.bool,
  prefix: PropTypes.string,
  suffix: PropTypes.string,
  adornment: PropTypes.node,
  adornmentPosition: PropTypes.oneOf(['start', 'end']),
  fullWidth: PropTypes.bool,
  handleChange: PropTypes.func,
  maxLength: PropTypes.number,
  helperText: PropTypes.string,
  autoComplete: PropTypes.oneOf(['on', 'off']),
  testId: PropTypes.string,
}

export const DEFAULT_NUMBER_PROPS = {
  allowNegative: true,
  decimalScale: 0,
  fixedDecimalScale: false,
  adornmentPosition: 'end',
  fullWidth: true,
  handleChange: noop,
  autoComplete: 'on',
}

class NumberField extends React.PureComponent {
  static propTypes = {
    ...NUMBER_PROPS,
    format: PropTypes.string,
    mask: PropTypes.string,
  }

  static defaultProps = DEFAULT_NUMBER_PROPS

  constructor(props) {
    super(props)
    this.numberFormat = React.createRef()
  }

  getNumberFormatCustom = innerProps => {
    const {
      allowNegative,
      thousandSeparator,
      prefix,
      suffix,
      decimalScale,
      fixedDecimalScale,
      inputRef,
      value,
      format,
      mask,
      ...other
    } = innerProps

    const newValue = isUndefined(value) ? null : value

    return (
      <NumberFormat
        prefix={prefix}
        decimalScale={decimalScale}
        fixedDecimalScale={fixedDecimalScale}
        suffix={suffix}
        getInputRef={inputRef || this.numberFormat}
        allowNegative={allowNegative}
        thousandSeparator={thousandSeparator}
        value={newValue}
        format={format}
        mask={mask}
        {...other}
      />
    )
  }

  onChange = event => {
    const { handleChange, input } = this.props
    input.onChange(event)
    handleChange(event)
  }

  onFocus = event => {
    event.target.select()
    this.props.input.onFocus(event)
  }

  onKeyDown = event => {
    const { input } = this.props
    if (event.key === 'Backspace') {
      input.onChange(event)
    }
  }

  render() {
    const {
      adornment,
      adornmentPosition,
      allowNegative,
      autoComplete,
      bare,
      decimalScale,
      fixedDecimalScale,
      format,
      fullWidth,
      handleChange,
      helperText,
      input,
      inputRef,
      label,
      mask,
      maxLength,
      meta: { touched, error },
      noPx,
      noPy,
      normalize,
      noFocus,
      prefix,
      right,
      suffix,
      thousandSeparator,
      testId,
      ...otherProps
    } = this.props
    const inErrorState = touched && !!error
    const errorText = inErrorState ? error : ''

    const inputProps = {
      inputComponent: this.getNumberFormatCustom,
      onChange: this.onChange,
      onKeyDown: this.onKeyDown,
      onFocus: this.onFocus,
      onBlur: input.onBlur,
      inputProps: {
        allowNegative,
        autoComplete,
        decimalScale,
        fixedDecimalScale,
        format,
        mask,
        maxLength,
        prefix,
        sx: { textAlign: right && 'right' },
        suffix,
        thousandSeparator,
      },
    }

    if (testId) {
      inputProps['data-qa'] = testId
    }

    if (!isNil(adornment)) {
      const position = adornmentPosition === 'start' ? 'startAdornment' : 'endAdornment'
      inputProps[position] = <InputAdornment position={adornmentPosition}>{adornment}</InputAdornment>
    }
    return (
      <FormControl fullWidth={fullWidth}>
        <input type="hidden" value={input.value} />
        <TextField
          InputLabelProps={{ shrink: true }}
          InputProps={inputProps}
          error={inErrorState}
          helperText={errorText || helperText}
          inputRef={inputRef}
          label={label}
          margin="dense"
          name={input.name}
          sx={{
            '&': {
              '.MuiInputBase-input': {
                fontSize: 14,
                px: noPx && 0,
                py: noPy && 0,
                background: 'white',
              },
              '.MuiOutlinedInput-root': {
                background: 'white',
              },
              '.MuiOutlinedInput-notchedOutline': bare && {
                border: 0,
              },
              '.MuiTypography-root': { fontSize: 14 },
              '.Mui-focused': noFocus && {
                border: 'none',
                '& .MuiOutlinedInput-notchedOutline': {
                  border: 'none',
                },
              },
            },
          }}
          value={input.value}
          variant="outlined"
          {...otherProps}
        />
      </FormControl>
    )
  }
}

export const NumberFieldStyled = NumberField

export const NumberComponent = ({ value, onChange, onBlur, ...props }) => {
  return <NumberFieldStyled input={{ value, onChange, onBlur }} {...props} />
}

NumberComponent.propTypes = {
  meta: PropTypes.object,
  onBlur: PropTypes.func,
  onChange: PropTypes.func,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
}

NumberComponent.defaultProps = {
  meta: { touched: false, error: null },
  onBlur: noop,
  onChange: noop,
  value: '',
}

class Number extends React.PureComponent {
  static propTypes = {
    name: PropTypes.string.isRequired,
    ...NUMBER_PROPS,
  }

  static defaultProps = DEFAULT_NUMBER_PROPS

  normalize = value => {
    if (value === '-') {
      return '-'
    }
    const { normalize } = this.props
    let num = value && parseFloat(value.replace(NON_NUMBERS_REGEX, ''))

    if (normalize) {
      num = normalize(num)
    }
    return num
  }

  //The reason for overriding this function is to prevent unnecessary renders when certain attributes do not change
  validate = (value, allValues, meta) => {
    const { min, max, validate } = this.props
    const validations = []
    const formattedValue = this.format(value)
    if (isFinite(min)) {
      validations.push(minNumber(min))
    }

    if (isFinite(max)) {
      validations.push(maxNumber(max))
    }

    if (validate) {
      validations.push(...castArray(validate))
    }
    return validations.reduce((prev, next) => prev || next(formattedValue, allValues, meta), undefined)
  }

  format = value => {
    const { format } = this.props
    if (format) {
      value = format(value)
    }
    return value
  }

  render() {
    const { adornment, adornmentPosition, allowNegative, format, label, name, thousandSeparator, ...otherProps } =
      this.props

    return (
      <Field
        adornment={adornment}
        adornmentPosition={adornmentPosition}
        allowNegative={allowNegative}
        component={NumberFieldStyled}
        format={this.format}
        label={label}
        name={name}
        parse={this.normalize}
        thousandSeparator={thousandSeparator}
        // {...otherProps} should be exactly here to prevent validate-prop shadowing
        {...otherProps}
        onFocus={otherProps.onFocus}
        validate={this.validate}
      />
    )
  }
}

export default Number
