import React from 'react'

const SquareFootageTooltipText = (
  <div>
    GBA: Gross Building Area - The total building area inclusive of everything whether its rented/leased or not
    (elevators, stairs, hallways etc.)
    <br /> GLA: Gross Leasable Area - The total enclosed floor area designed for the exclusive use of an occupant,
    including any basements, mezzanines, or upper floors.
    <br /> NRA: Net Rentable Area - The gross area of the full floor less the area of all vertical penetrations
    (elevator shafts, stairwells, mechanical shafts etc.)
    <br /> NLA: Net Leasable Area - The floor space that may be rented to tenants. Generally excludes common areas and
    space devoted to the heating, cooling, and other equipment of a building.
  </div>
)

export default SquareFootageTooltipText
