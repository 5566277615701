import { isArray, map, merge } from 'lodash'

const createComposed = (components, extraProps) => {
  const allComponents = isArray(components) ? components : [components]
  const [parent] = allComponents
  const extended = parent.bind({})
  const allDefaultProps = map(allComponents, 'defaultProps')
  extended.defaultProps = merge({}, ...allDefaultProps, extraProps)
  return extended
}

export { createComposed }
