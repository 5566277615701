import { get } from 'lodash'

import { simplePluralize as pluralize } from '../..'

export const mapDTO = formValues => {
  const { valueAsComplete, yearBuilt, effectiveAge, totalEconomicLife } = formValues

  const remainingEconomicLife = totalEconomicLife - effectiveAge || 0

  const effectiveAgePhrase = `${effectiveAge} ${pluralize('year', effectiveAge)}`
  const totalEconomicLifePhrase = ` ${totalEconomicLife} ${pluralize('year', totalEconomicLife)}`
  const remainingEconomicLifePhrase = `${remainingEconomicLife} ${pluralize('year', remainingEconomicLife)}`

  return {
    yearBuilt,
    effectiveAge: effectiveAgePhrase,
    totalEconomicLife: totalEconomicLifePhrase,
    remainingEconomicLife: remainingEconomicLifePhrase,
    valueAsComplete,
  }
}

export const mapDataModel = report => {
  const valueAsComplete = get(report, 'valueAsComplete')
  const yearBuilt = get(report, 'property_information.yearBuilt')
  const { totalLife, effectiveAge } = get(report, 'buildingDescription', {})

  const remainingEconomicLife = totalLife - effectiveAge || 0

  const effectiveAgePhrase = `${effectiveAge} ${pluralize('year', effectiveAge)}`
  const totalEconomicLifePhrase = ` ${totalLife} ${pluralize('year', totalLife)}`
  const remainingEconomicLifePhrase = `${remainingEconomicLife} ${pluralize('year', remainingEconomicLife)}`

  return {
    yearBuilt,
    effectiveAge: effectiveAgePhrase,
    totalEconomicLife: totalEconomicLifePhrase,
    remainingEconomicLife: remainingEconomicLifePhrase,
    valueAsComplete,
  }
}
