import React from 'react'
import PropTypes from 'prop-types'

import { ListItem, ListItemText } from '@mui/material'
import { withStyles } from '@mui/styles'
import classnames from 'classnames'
import { NavLink } from 'react-router-dom'

import BookmarkIndicator from '../../../components/BookmarkIndicator'
import { NAV_SIDE_BAR_MENU_ITEM_HEIGHT } from '../../stylesConstants'
import RefHolder from '../../../../shared/utils/RefHolder'

const hoverMenuItemStyles = {
  backgroundColor: 'rgba(65, 96, 211, 0.08)',
  borderRadius: 4,
}

const styles = theme => ({
  root: {
    boxSizing: 'border-box',
    padding: '5px 0 5px 12px',
    height: NAV_SIDE_BAR_MENU_ITEM_HEIGHT,
    marginBottom: theme.spacing(1),
    color: 'rgba(0, 0, 0, 0.54)',
    cursor: 'pointer',
    '&:hover': {
      ...hoverMenuItemStyles,

      '& $bookmarkBorder': {
        opacity: 1,
      },
    },
  },
  bookmarkBorder: {
    opacity: 0,
  },
  itemText: {
    fontSize: 14,
    fontFamily: 'Nunito Sans',
  },
  nested: {
    '&:hover': hoverMenuItemStyles,
  },
  active: {
    color: theme.palette.primary.main,
  },
})

const NavigationSideBarMenuItem = ({
  nested,
  id,
  path,
  currentPath,
  title,
  classes,
  isActive,
  anchor,
  onItemClick,
}) => {
  return (
    <ListItem
      id={id}
      component={RefHolder(NavLink)}
      to={{
        pathname: path,
        hash: anchor,
        state: {
          skipSave: path === currentPath,
        },
      }}
      onClick={onItemClick}
      className={classnames(classes.root, { [classes.nested]: nested, [classes.active]: isActive })}
    >
      <ListItemText className={classes.itemText} disableTypography primary={title} />
      {!anchor && <BookmarkIndicator classes={{ bookmarkBorder: classes.bookmarkBorder }} id={id} />}
    </ListItem>
  )
}

NavigationSideBarMenuItem.defaultProps = {
  nested: false,
  anchor: '',
}

NavigationSideBarMenuItem.propTypes = {
  id: PropTypes.string.isRequired,
  path: PropTypes.string.isRequired,
  currentPath: PropTypes.string.isRequired,
  nested: PropTypes.bool,
  title: PropTypes.string.isRequired,
  isActive: PropTypes.bool.isRequired,
  anchor: PropTypes.string,
  onItemClick: PropTypes.func.isRequired,
}

export default withStyles(styles, {
  name: 'NavigationSideBarMenuItem',
})(NavigationSideBarMenuItem)
