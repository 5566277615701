import { Input } from '../../Base'
import { defaultNumberParse as parse, withField } from '../tools'

const Fields = {
  Text: withField(Input.Text),
}

const NumberFields = {
  DollarAmount: withField(Input.DollarAmount),
  Numbers: withField(Input.Numbers),
  SmallTableNumbers: withField(Input.SmallTableNumbers),
  SmallTabletDollarAmount: withField(Input.SmallTabletDollarAmount),
  TableDollarAmount: withField(Input.TableDollarAmount),
  TableNumbers: withField(Input.TableNumbers),
}

Object.keys(NumberFields).forEach(key => {
  NumberFields[key].defaultProps = { parse }
})

export default { ...NumberFields, ...Fields }
