import React from 'react'
import PropTypes from 'prop-types'

import Cancel from '@mui/icons-material/Cancel'
import Edit from '@mui/icons-material/Edit'
import { Field } from 'react-final-form'
import { IconButton, Stack, TableCell, TableRow, Tooltip } from '@mui/material'
import { Template } from 'client-shared/components/Template'

import TableNumberField from '../Table/TableNumberRightAligned'

const RentLossTableRow = ({
  collectionName,
  index,
  isEditable,
  lossItem,
  name,
  onEditItem,
  onRemoveItem,
  rentLossType,
}) => {
  const [isHovering, setIsHovering] = React.useState(false)

  const onMouseEnter = React.useCallback(() => {
    setIsHovering(true)
  }, [])
  const onMouseLeave = React.useCallback(() => {
    setIsHovering(false)
  }, [])

  const onRemove = React.useCallback(() => {
    onRemoveItem(index, collectionName, rentLossType)
  }, [collectionName, index, onRemoveItem, rentLossType])

  const onEdit = React.useCallback(() => {
    onEditItem(index, collectionName, rentLossType)
  }, [collectionName, index, onEditItem, rentLossType])

  const amountPrefix = lossItem.amount !== 0 ? '-$' : '$'

  return (
    <TableRow id={name} onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave}>
      <Field name={`${name}.units`} render={() => null} />
      <TableCell data-qa={`${name}.item-cell`}>Less {lossItem.name}</TableCell>
      <TableCell align="right" data-qa={`${name}.renovation-period-cell`}>
        <Template is="auto-flow / 120px" placeItems="center end">
          <TableNumberField
            adornment="months"
            allowNegative={false}
            autoComplete="off"
            disabled={!isEditable}
            margin="none"
            maxLength={3}
            name={`${name}.months`}
            noPx
            noPy
            placeholder="0"
            right
          />
        </Template>
      </TableCell>
      <TableCell align="right" data-qa={`${name}.amount-cell`}>
        <TableNumberField
          allowNegative={false}
          autoComplete="off"
          decimalScale={2}
          disabled={true}
          margin="none"
          name={`${name}.amount`}
          noPx
          noPy
          normalize={Math.abs}
          placeholder="$0.00"
          prefix={amountPrefix}
          right
          thousandSeparator
        />
      </TableCell>
      <TableCell />
      <TableCell>
        {isHovering && isEditable && (
          <Stack alignItems="center" direction="row" justifyContent="end" spacing={2} sx={{ my: -1 }}>
            <Tooltip title="Edit Loss Item" placement="top">
              <IconButton onClick={onEdit} size="small">
                <Edit color="info" />
              </IconButton>
            </Tooltip>
            <Tooltip title="Remove Loss Item" placement="top">
              <IconButton data-qa={`${name}.copy-to-all`} onClick={onRemove} size="small">
                <Cancel color="error" />
              </IconButton>
            </Tooltip>
          </Stack>
        )}
      </TableCell>
    </TableRow>
  )
}

RentLossTableRow.propTypes = {
  collectionName: PropTypes.string.isRequired,
  index: PropTypes.number.isRequired,
  isEditable: PropTypes.bool,
  name: PropTypes.string.isRequired,
  onEditItem: PropTypes.func.isRequired,
  onRemoveItem: PropTypes.func.isRequired,
  rentLossType: PropTypes.number.isRequired,
}

export default RentLossTableRow
