import { lowerCase, isEmpty, isArray, groupBy } from 'lodash'
import arrayToSentence from 'array-to-sentence'

import { toPercents } from 'shared/utils/numberOperations'
import { formatCurrencyFloat } from 'shared/utils/formatters/numberFormatters'
import { formatList } from 'shared/utils/textHelpers'

import { getLowerCasedUseLabel } from '../../../../helpers/commercialUses'
import { LOSS_ITEM_KEYS } from '../../../../constants/acas'
import { getCommercialLossItemName } from '../../../../../libs/bowery-libs/services/report-calculations/income-approach/acas/helpers'

export const getUnitsInOrder = (residentialUnits, lossItemUnits) => {
  return residentialUnits.flatMap(unit => {
    if (lossItemUnits.includes(unit._id.toString())) {
      return unit
    }
    return []
  })
}

export const getUnitsList = units => {
  if (units.length > 4) {
    return ''
  }
  const unitNumbers = units.map(unit => (unit ? unit.number : ''))
  return ` ${formatList(unitNumbers)}`
}

export const getUnitWord = (uses, commercialUnitsByUse) => {
  const multipleUsesExist = uses.length > 1
  const multipleUnitsWithUseExist = commercialUnitsByUse[uses[0]].length > 1
  const pluralizeUnit = multipleUsesExist || multipleUnitsWithUseExist
  return pluralizeUnit ? 'units' : 'unit'
}

export const generateAsStabilizedDeductions = ({
  asStabilizedLossItems,
  asStabilizedResRentLossItems,
  commercialUnits,
  valueAsComplete,
  residentialUnits,
}) => {
  let lossGeneratedText = ''
  let commercialLossesSentence = ''
  let generatedText = ''
  const commercialUsesByPeriod = {}
  const miscIncomeItems = []

  asStabilizedResRentLossItems?.forEach(lossItem => {
    const unitsInOrder = getUnitsInOrder(residentialUnits, lossItem.units)
    lossGeneratedText += ` We deduct ${lossItem.months} month${
      lossItem.months === 1 ? '' : 's'
    } of rent loss during the lease-up period for the residential unit${
      unitsInOrder.length === 1 ? '' : 's'
    }${getUnitsList(unitsInOrder)}.`
  })
  const commercialUnitsByUse = groupBy(commercialUnits, 'use')
  asStabilizedLossItems?.forEach(lossItem => {
    if (!lossItem.amount || !lossItem.name) {
      return
    }

    switch (lossItem.name) {
      case LOSS_ITEM_KEYS.COMMERCIAL_RETAIL_RENT_LOSS:
      case LOSS_ITEM_KEYS.COMMERCIAL_OFFICE_RENT_LOSS:
      case LOSS_ITEM_KEYS.COMMERCIAL_MEDICAL_RENT_LOSS:
      case LOSS_ITEM_KEYS.COMMERCIAL_COMMUNITY_RENT_LOSS:
      case LOSS_ITEM_KEYS.COMMERCIAL_UNDETERMINED_RENT_LOSS:
      case getCommercialLossItemName(lossItem.commercialUse): {
        if (!isArray(commercialUsesByPeriod[lossItem.months])) {
          commercialUsesByPeriod[lossItem.months] = []
        }
        commercialUsesByPeriod[lossItem.months].push(lossItem.commercialUse)
        break
      }
      case LOSS_ITEM_KEYS.STORAGE_RENT_LOSS:
      case LOSS_ITEM_KEYS.PARKING_RENT_LOSS:
      case LOSS_ITEM_KEYS.OTHER_RENT_LOSS:
      case LOSS_ITEM_KEYS.LAUNDRY_RENT_LOSS: {
        const lossType = lowerCase(lossItem.name.split(' ')[0])

        miscIncomeItems.push(`${lossItem.months} month${lossItem.months === 1 ? '' : 's'} of ${lossType} rent loss`)
        break
      }
      case LOSS_ITEM_KEYS.COMMISSION_FEE:
        generatedText += ` We deduct ${formatCurrencyFloat(lossItem.amount)} to account for the commission fee.`
        break
      case LOSS_ITEM_KEYS.ENTREPRENEURIAL_PROFIT:
        generatedText += ` We apply an entrepreneurial profit of ${toPercents(lossItem.amount)}%.`
        break
      default:
        break
    }
  })

  if (!isEmpty(commercialUsesByPeriod)) {
    commercialLossesSentence = ` We deduct ${arrayToSentence(
      Object.entries(commercialUsesByPeriod).map(([months, uses]) => {
        return `${months} month${
          months > 1 ? 's' : ''
        } of rent loss during the lease-up period for the ${arrayToSentence(
          uses.map(getLowerCasedUseLabel)
        )} ${getUnitWord(uses, commercialUnitsByUse)}`
      })
    )}.`
  }

  const miscIncomesSentence = isEmpty(miscIncomeItems) ? '' : ` We deduct ${arrayToSentence(miscIncomeItems)}.`
  return `${`We make various deductions to get from the As Stabilized value to the As ${
    valueAsComplete ? 'Complete' : 'Is'
  } one.`}${lossGeneratedText}${commercialLossesSentence}${miscIncomesSentence}${
    generatedText ? `${generatedText}` : ''
  }`
}

export const generateAsCompleteDeductions = ({
  asCompleteLossItems,
  asCompleteResRentLossItems,
  commercialUnits,
  residentialUnits,
}) => {
  let lossGeneratedText = ''
  let commercialLossesSentence = ''
  let generatedText = ''
  const commercialUsesByPeriod = {}
  const miscIncomeItems = []

  asCompleteResRentLossItems?.forEach((lossItem, index) => {
    const units = getUnitsInOrder(residentialUnits, lossItem.units)
    lossGeneratedText += ` We deduct ${lossItem.months} month${
      lossItem.months === 1 ? '' : 's'
    } of rent loss during the renovation period for the residential unit${units.length === 1 ? '' : 's'}${getUnitsList(
      units
    )}${index === asCompleteResRentLossItems.length - 1 ? '' : '.'}`
  })

  const commercialUnitsByUse = groupBy(commercialUnits, 'use')
  asCompleteLossItems?.forEach(lossItem => {
    if (!lossItem.amount || !lossItem.name) {
      return
    }

    switch (lossItem.name) {
      case LOSS_ITEM_KEYS.COMMERCIAL_RETAIL_RENT_LOSS:
      case LOSS_ITEM_KEYS.COMMERCIAL_OFFICE_RENT_LOSS:
      case LOSS_ITEM_KEYS.COMMERCIAL_MEDICAL_RENT_LOSS:
      case LOSS_ITEM_KEYS.COMMERCIAL_COMMUNITY_RENT_LOSS:
      case LOSS_ITEM_KEYS.COMMERCIAL_UNDETERMINED_RENT_LOSS:
      case getCommercialLossItemName(lossItem.commercialUse): {
        if (!isArray(commercialUsesByPeriod[lossItem.months])) {
          commercialUsesByPeriod[lossItem.months] = []
        }
        commercialUsesByPeriod[lossItem.months].push(lossItem.commercialUse)
        break
      }
      case LOSS_ITEM_KEYS.STORAGE_RENT_LOSS:
      case LOSS_ITEM_KEYS.PARKING_RENT_LOSS:
      case LOSS_ITEM_KEYS.OTHER_RENT_LOSS:
      case LOSS_ITEM_KEYS.LAUNDRY_RENT_LOSS: {
        const lossType = lowerCase(lossItem.name.split(' ')[0])

        miscIncomeItems.push(`${lossItem.months} month${lossItem.months === 1 ? '' : 's'} of ${lossType} rent loss`)
        break
      }
      case LOSS_ITEM_KEYS.RENOVATION_BUDGET:
        generatedText += ` Moreover, we deduct the renovation costs.`
        break
      case LOSS_ITEM_KEYS.CONSTRUCTION_BUDGET:
        generatedText += ` Moreover, we deduct the construction costs.`
        break
      case LOSS_ITEM_KEYS.ENTREPRENEURIAL_PROFIT:
        generatedText += ` We have also applied an entrepreneurial profit of ${toPercents(lossItem.amount)}%.`
        break
      case LOSS_ITEM_KEYS.BUYOUT_COST:
        generatedText += ` Finally, we deducted the buyout cost of ${formatCurrencyFloat(lossItem.amount)}.`
        break
      default:
        break
    }
  })

  if (!isEmpty(commercialUsesByPeriod)) {
    commercialLossesSentence = `${lossGeneratedText ? ', as well as' : ' We deduct'} ${arrayToSentence(
      Object.entries(commercialUsesByPeriod).map(([months, uses]) => {
        return `${months} month${
          months > 1 ? 's' : ''
        } of rent loss during the renovation period for the ${arrayToSentence(
          uses.map(getLowerCasedUseLabel)
        )} ${getUnitWord(uses, commercialUnitsByUse)}`
      })
    )}.`
  }

  const miscIncomesSentence = isEmpty(miscIncomeItems)
    ? ''
    : ` We deduct ${arrayToSentence(miscIncomeItems)} during the renovation period.`
  return (
    `We make various deductions to get from the As Complete value to the As Is one.${lossGeneratedText}` +
    `${commercialLossesSentence || '.'}${miscIncomesSentence}${generatedText ? `${generatedText}` : ''}`
  )
}
