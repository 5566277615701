import currency from '../../../formatters/currencyFormatter'

const MONTHS_IN_YEAR = 12
const WEEKS_IN_YEAR = 52

const getLaundryInfoSentence = ({
  isLaundryLeased,
  lessee,
  isForecastType,
  isAnnual,
  annualLaundryIncome,
  unitCount,
}) => {
  if (isLaundryLeased) {
    return (
      `The laundry room is currently leased to and operated by ${lessee || '[LESSEE]'} ` +
      `with an annual income of ${currency.format(annualLaundryIncome)}.`
    )
  }

  if (isForecastType) {
    return 'The laundry room is owner managed; however, we were not supplied with laundry income information.'
  }

  const units = isAnnual ? 'an annual' : 'a monthly per unit'
  const monthLaundryIncome = annualLaundryIncome / unitCount / MONTHS_IN_YEAR
  const appraiserConcluded = isAnnual ? annualLaundryIncome : monthLaundryIncome

  return (
    `The laundry room is owner managed and the owner reported ${units} income of` +
    ` ${currency.format(appraiserConcluded)}.`
  )
}

const getLaundryEquatingSentence = ({ isLaundryLeased, annualLaundryIncome, unitCount }) => {
  const incomePerUnit = annualLaundryIncome / unitCount
  const sentence = `This income equates to ${currency.format(incomePerUnit)} per unit per annum`

  return isLaundryLeased
    ? `${sentence}.`
    : `${sentence}, which is ${currency.format(incomePerUnit / WEEKS_IN_YEAR)} per unit per week, and is reasonable.`
}

const getLaundryAssetsKnowledgeSentence = ({
  isLaundryLeased,
  isForecastType,
  isAnnual,
  annualLaundryIncome,
  unitCount,
}) => {
  if (isLaundryLeased || !isForecastType) {
    return 'Based on comparable assets in the marketplace, this income is reasonable and will be applied in the stabilized pro forma.'
  }

  const units = isAnnual ? 'annually' : 'per unit per month'
  const monthLaundryIncome = annualLaundryIncome / unitCount / MONTHS_IN_YEAR
  const appraiserConcluded = isAnnual ? annualLaundryIncome : monthLaundryIncome

  return (
    `Based on comparable assets in the marketplace, we have forecasted laundry income ` +
    `at ${currency.format(appraiserConcluded)} ${units}.`
  )
}

export const generateLaundryIncomeDiscussion = ({
  lessee,
  isLaundryLeased,
  isForecastType,
  isAnnual,
  annualLaundryIncome,
  unitCount,
}) => {
  const paragraph = ['The subject property derives additional income from washers and dryers in the laundry room.']

  const laundryInfoSentence = getLaundryInfoSentence({
    isLaundryLeased,
    lessee,
    isForecastType,
    isAnnual,
    annualLaundryIncome,
    unitCount,
  })
  const laundryEquatingSentence = getLaundryEquatingSentence({ isLaundryLeased, annualLaundryIncome, unitCount })
  const laundryAssetsKnowledgeSentence = getLaundryAssetsKnowledgeSentence({
    isLaundryLeased,
    isForecastType,
    isAnnual,
    annualLaundryIncome,
    unitCount,
  })

  paragraph.push(laundryInfoSentence)

  if (isLaundryLeased || !!isForecastType) {
    paragraph.push(laundryEquatingSentence)
    paragraph.push(laundryAssetsKnowledgeSentence)
  } else {
    paragraph.push(laundryAssetsKnowledgeSentence)
    paragraph.push(laundryEquatingSentence)
  }

  return paragraph.join(' ')
}
