import { get, mapValues, isObject, omit } from 'lodash'
import { push } from 'react-router-redux'
import { select, put, take, takeLatest, race, fork } from 'redux-saga/effects'

import { sectionNameSelector } from 'report/layout/selectors'

import { changeDrawerSection } from 'client-shared/redux/actions/drawer'

import { DrawerSections } from 'report/layout/drawer/constants'

import { REVIEW_AND_EXPORT_KEY } from 'shared/constants/report/keysAndDataPaths'

import { REPORT_ID_NOT_FOUND, REPORT_NOT_FOUND } from '../../../shared/constants/errors'

import {
  REPORT_FETCH,
  UPDATE_REPORT_STATE,
  HIDE_CONFIRM_MODAL,
  REPORT_SETTINGS_UPDATE,
  NAVIGATION_CONFIRMATION,
  REPORT_SAVE,
  UPDATE_NOTES,
} from '../actionTypes'

import {
  reportFetchRequest,
  reportFetchReceive,
  reportFetchFail,
  reportUpdate,
  reportUpdateRequest,
  reportUpdateReceive,
  reportUpdateFail,
  reportUpdateComplete,
  reportSettingsUpdateRequest,
  reportSettingsUpdateReceive,
  reportSettingsUpdateFail,
  updateReportStateRequest,
  updateReportStateReceive,
  updateReportStateFail,
  showConfirmModal,
  reportStructureUpdate,
  updateNotesRequest,
  updateNotesReceive,
  updateNotesFail,
  changeReportCompType,
} from '../actions/report'

import { fetchBookmarks, removePageBookmark } from '../actions/bookmarks'

import { successNotification, errorNotification } from '../../../shared/redux/actions/notifications'

import * as Api from '../../api'

import { ReportStates, BLOCK_NAVIGATION, CompTypesNextForm } from '../../constants'

import { submit as submitFinalForm, willShowSaveModal, isFormInvalid } from '../../utils/reportFormSubmitHelpers'

import { getHistory } from '../../../core/history'
import { locationSet } from '../../../shared/redux/actions/location'

const getFormKey = (parentKey, formKey) => (parentKey ? `${parentKey}.${formKey}` : formKey)

const getReportFormsStructure = ({ structure, data, parentKey = '' }) => {
  return mapValues(structure, (value, key) => {
    const currentKey = getFormKey(parentKey, key)

    if (isObject(value)) {
      const descendants = getReportFormsStructure({ structure: omit(value, ['_type']), data, parentKey: currentKey })
      return {
        descendants,
        ...(value._type && { type: value._type }),
      }
    } else {
      return {
        showForm: value,
      }
    }
  })
}

function* updateReportStructure({ reportData, reportStructure }) {
  const structure = getReportFormsStructure({
    structure: reportStructure,
    data: reportData,
  })

  yield put(reportStructureUpdate(structure))
}

function* fetchReport(action) {
  try {
    const reportId = get(action, 'payload')
    if (reportId) {
      yield put(reportFetchRequest(action))

      const { reportStructure, reportSettings, reportData } = yield Api.fetchReport(reportId)
      yield put(fetchBookmarks(reportId))

      yield updateReportStructure({ reportStructure, reportData })
      yield put(reportFetchReceive({ reportData, reportSettings }))
    } else {
      const err = new Error(REPORT_ID_NOT_FOUND)
      yield put(reportFetchFail(err))
    }
  } catch (err) {
    yield put(reportFetchFail(err))
    yield put(
      errorNotification({
        message: REPORT_NOT_FOUND,
      })
    )
    yield put(push(`/reports`))
  }
}

function* goToForm(reportId, formPath) {
  yield put(push(`/report/${reportId}/${formPath}`))
}

function* updateReportHandler({ payload }) {
  let reportId = ''
  try {
    reportId = yield select(state => state.report.reportData._id)
  } catch (err) {
    // no report id
    return
  }
  const reportStatus = yield select(state => state.report.reportData.reviewAndExport.state)
  const { formDataPath, values } = payload
  try {
    if (
      global.env.allowEditingApprovedReports ||
      (reportStatus !== ReportStates.APPROVED && reportStatus !== ReportStates.SUBMITTED)
    ) {
      if (
        (global.env.allowEditingApprovedReports && reportStatus === ReportStates.APPROVED) ||
        reportStatus === ReportStates.SUBMITTED
      ) {
        yield put(
          successNotification({
            message: `${reportStatus} report saved successfully`,
          })
        )
      }
      yield put(reportUpdateRequest(payload))
      const { reportStructure, reportSettings, reportData } = yield Api.updateReport(reportId, formDataPath, values)
      yield updateReportStructure({ reportStructure, reportData })
      yield put(reportUpdateReceive({ reportData, reportSettings }))
    } else {
      yield put(
        errorNotification({
          message: `${reportStatus} reports cannot be modified`,
        })
      )
    }
  } catch (err) {
    yield put(reportUpdateFail(err))
    let message
    if (err?.response?.status === 400) {
      message = `Invalid Input (${err?.response?.data?.message}). If you need further assistance, please contact us 
quoting Report Id '${reportId}' and form '${formDataPath[formDataPath.length - 1]}' for assistance.`
    } else {
      message =
        `There was an error while trying to save this report, please contact us quoting Report Id` +
        ` '${reportId}' and form '${
          formDataPath[formDataPath.length - 1]
        }' for assistance. You can continue working on other forms while this issue is resolved.`
    }
    yield put(
      errorNotification({
        message,
      })
    )
  } finally {
    yield put(reportUpdateComplete())
  }
}

function* changeReportCompTypeHandler({ payload }) {
  const { formDataPath, compType } = payload
  const formPath = formDataPath.join('.')
  const form = yield select(state => get(state, 'shared.location.form'))
  const values = yield select(state => get(state, `report.reportData.${formPath}`) || {})

  yield put(
    reportUpdate({
      formDataPath,
      values: {
        ...values,
        compType,
      },
    })
  )

  yield put(removePageBookmark(form.nextFormPath))
  yield take(reportUpdateReceive)

  yield put(
    locationSet({
      form: { ...form, nextFormPath: CompTypesNextForm[compType] },
    })
  )
}

function* locationSetHandler() {
  const { sectionName } = yield select(sectionNameSelector)

  if (sectionName === DrawerSections.CONTENT_LIBRARY) {
    yield put(changeDrawerSection(undefined))
  }
}

function* updateReportSettings({ payload }) {
  const { incomeType, valueConclusionType, templateType, blocksConfiguration, isBlocks, approaches } = payload

  const reportId = yield select(state => get(state, 'report.reportData._id'))

  yield put(reportSettingsUpdateRequest({ reportId }))
  try {
    const response = yield Api.updateReportSettings(reportId, {
      incomeType,
      valueConclusionType,
      templateType,
      blocksConfiguration,
      isBlocks,
      approaches,
    })
    const { reportStructure, reportSettings, reportData } = response
    yield put(fetchBookmarks(reportId))

    yield updateReportStructure({ reportStructure, reportData })

    yield put(reportSettingsUpdateReceive({ reportSettings, reportData }))
  } catch (error) {
    yield put(reportSettingsUpdateFail(error))
  }
}

function* updateReportState({ payload }) {
  const { currentState, nextState, nextFormPath } = payload

  const reportId = yield select(state => get(state, 'report.reportData._id'))

  yield put(updateReportStateRequest({ reportId, currentState, nextState }))
  try {
    const response = yield Api.updateReportState(reportId, currentState, nextState)
    yield put(updateReportStateReceive(response))
    yield put(
      successNotification({
        message: `Report is marked as "${response.state}"`,
      })
    )
    yield goToForm(reportId, nextFormPath)
  } catch (error) {
    yield put(updateReportStateFail(error))
    yield put(errorNotification({ message: `Error during change report state` }))
  }
}

function* locationChangeInterceptor() {
  while (true) {
    // we need to block navigation to check if the state of the from is dirty or not, similar to prevent default.
    let skipConfirmation, skipSave
    const unblockNavigation = getHistory().block(location => {
      skipConfirmation = get(location, 'state.skipConfirmation', false)
      skipSave = get(location, 'state.skipSave', false)
      return BLOCK_NAVIGATION
    })

    // wait for getUserConfirmation to be called from stores history
    const action = yield take(NAVIGATION_CONFIRMATION.ACTION)
    const { callback } = action.payload
    let navigate = true
    if (!skipSave) {
      navigate = yield submitFormWithConfirmation({ skipConfirmation })
    }
    unblockNavigation()
    callback(navigate)
  }
}

export function* submitFormWithConfirmation({ skipConfirmation, loggingOut = false }) {
  const { formPath } = yield select(state => get(state, 'shared.location.form') || {})

  const leavingReviewAndExport = formPath === REVIEW_AND_EXPORT_KEY
  const showSaveModal = willShowSaveModal(formPath)
  const isInvalid = isFormInvalid(formPath)

  if (isInvalid) {
    yield submitForm(formPath)
    return false
  }

  if (leavingReviewAndExport) {
    return true
  }

  if (skipConfirmation) {
    return yield submitForm(formPath)
  }

  if (!showSaveModal) {
    return true
  }

  yield put(showConfirmModal({ loggingOut }))
  const action = yield take(HIDE_CONFIRM_MODAL.ACTION)
  const { navigate, submit } = action.payload
  if (submit) {
    yield submitForm(formPath)
  }

  return navigate
}

function* saveReport({ payload: formPath }) {
  yield submitForm(formPath)
}

function* submitForm(formPath) {
  submitFinalForm(formPath)
  const [complete] = yield race([take(reportUpdateComplete), take(reportUpdateFail)])
  return Boolean(complete)
}

function* updateNotes({ payload }) {
  const { htmlContent, showSuccessNotification = true } = payload
  const reportId = yield select(state => get(state, 'report.reportData._id'))

  yield put(updateNotesRequest({ reportId }))
  try {
    const response = yield Api.updateNotes(reportId, htmlContent)
    yield put(updateNotesReceive(response))

    if (showSuccessNotification) {
      yield put(
        successNotification({
          message: `Notes are saved`,
        })
      )
    }
  } catch (error) {
    yield put(updateNotesFail(error))
    yield put(errorNotification({ message: `Error during saving report notes` }))
  }
}

export default [
  fork(locationChangeInterceptor),
  takeLatest(UPDATE_NOTES.ACTION, updateNotes),
  takeLatest(REPORT_SAVE.ACTION, saveReport),
  takeLatest(REPORT_FETCH.ACTION, fetchReport),
  takeLatest(reportUpdate, updateReportHandler),
  takeLatest(UPDATE_REPORT_STATE.ACTION, updateReportState),
  takeLatest(changeReportCompType, changeReportCompTypeHandler),
  takeLatest(REPORT_SETTINGS_UPDATE.ACTION, updateReportSettings),
  takeLatest(locationSet, locationSetHandler),
]
