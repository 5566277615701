import React, { PureComponent } from 'react'
import ReactDOM from 'react-dom'
import PropTypes from 'prop-types'
import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Checkbox,
  ListItemText,
  OutlinedInput,
  FormHelperText,
} from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'
import { Field } from 'react-final-form'

const styles = theme => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  formControl: {
    height: 84,
    marginTop: 9,
  },
})

const ITEM_HEIGHT = 48
const ITEM_PADDING_TOP = 8

const MenuProps = {
  PaperProps: {
    role: 'menubar',
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
}

class MaterialCheckboxMultiselect extends PureComponent {
  state = {
    labelWidth: 0,
  }

  componentDidMount() {
    this.setState({
      labelWidth: ReactDOM.findDOMNode(this.InputLabelRef).offsetWidth,
    })
  }

  render() {
    const {
      input,
      label,
      items,
      classes,
      helperText,
      meta: { pristine, error },
    } = this.props

    const inErrorState = !pristine && !!error
    return (
      <FormControl variant="outlined" className={classes.formControl} fullWidth>
        <InputLabel
          shrink
          ref={ref => {
            this.InputLabelRef = ref
          }}
          htmlFor={`{outlined-${label}-simple`}
        >
          {label}
        </InputLabel>
        <Select
          fullWidth
          multiple
          value={input.value}
          onChange={input.onChange}
          input={
            <OutlinedInput labelWidth={this.state.labelWidth} notched name={label} id={`outlined-${label}-simple`} />
          }
          renderValue={selected => {
            return selected
              .map(index => items[index].label)
              .sort()
              .join(', ')
          }}
          MenuProps={MenuProps}
          margin="dense"
        >
          {items.map(item => (
            <MenuItem key={item.value} value={item.value}>
              <Checkbox checked={input.value.indexOf(item.value) > -1} />
              <ListItemText key={item.value} primary={item.label} />
            </MenuItem>
          ))}
        </Select>
        {inErrorState ? <FormHelperText error={error}>{error}</FormHelperText> : null}
        {!inErrorState && helperText ? <FormHelperText>{helperText}</FormHelperText> : null}
      </FormControl>
    )
  }
}

const MaterialCheckboxMultiselectWithStyle = withStyles(styles)(MaterialCheckboxMultiselect)

export default function CheckBoxMultiselect({ name, label, ...props }) {
  return <Field name={name} label={label} component={MaterialCheckboxMultiselectWithStyle} {...props} />
}

CheckBoxMultiselect.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string,
  validate: PropTypes.func,
  items: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.number.isRequired,
      label: PropTypes.string.isRequired,
    })
  ).isRequired,
  helperText: PropTypes.string,
}
