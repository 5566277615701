function serialize(value) {
  return serializeNode(value.document)
}

function serializeNode(node) {
  if (node.object === 'document' || node.object === 'block') {
    return node.nodes.map(serializeNode).join('')
  } else {
    if (node.type === 'locked' || node.type === 'lockedSentence') {
      return `[${node.get('data').get('fieldName')}]`
    }
    if (node.type === 'conditional') {
      return node.nodes.map(serializeNode).join('')
    }
    return node.text || node.fieldName
  }
}

export default {
  serialize,
}
