import React from 'react'
import PropTypes from 'prop-types'
import { get } from 'lodash'

import { Cell, SubjectUnitCell } from '../../TableCells'
import { Labels } from '../../CommercialRentReconciliationConstants'
import { formatCurrencyFloat } from '../../../../../../../shared/utils/numberFormatters'
import { shortDateFormat } from '../../../../../../../shared/utils/dateHelper'

const formatChild = (cellType, item, fieldType) => {
  let child

  switch (cellType) {
    case 'currency':
      child = formatCurrencyFloat(get(item, fieldType))
      break
    case 'date':
      child = shortDateFormat(get(item, fieldType))
      break
    default:
      child = get(item, fieldType) || Labels.NA
      break
  }
  return child
}

const FieldCells = ({ units, rentComps, fieldType, cellType }) => (
  <>
    {units.map((unit, index) => {
      const child = formatChild(cellType, unit, fieldType)

      return (
        <SubjectUnitCell data-qa={`subject-unit-${index}-cell`} key={unit._id}>
          {child}
        </SubjectUnitCell>
      )
    })}
    {rentComps.map((rentComp, index) => {
      const child = formatChild(cellType, rentComp, fieldType)

      return (
        <Cell data-qa={`subject-unit-${index}-cell`} key={rentComp._id}>
          {child}
        </Cell>
      )
    })}
  </>
)

FieldCells.propTypes = {
  units: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  rentComps: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  fieldType: PropTypes.string.isRequired,
  cellType: PropTypes.string,
}

FieldCells.defaultProps = {
  cellType: '',
}

export default FieldCells
