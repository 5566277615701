import React from 'react'
import PropTypes from 'prop-types'

import StarRounded from '@mui/icons-material/StarRounded'
import { Button } from '@mui/material'
import { withStyles } from '@mui/styles'
import classNames from 'classnames'
import { divIcon } from 'leaflet'
import 'leaflet/dist/leaflet.css'
import { Map, TileLayer as BaseMap } from 'react-leaflet'
import DivIcon from 'react-leaflet-div-icon'

import LeafletMapControl from '../../shared/components/Map/LeafletMapControl'
import { DIALOG_Z_INDEX } from '../../shared/constants'
import cartoDbConfig from '../../shared/utils/cartodbConfig'
import { getAddress } from '../../shared/utils/geography'

import ConnectedIconLabel from './ConnectedIconLabel'
import { APP_BAR_HEIGHT, DRAWER_WIDTH, MAP_ID, SUBJECT_ICON_ID } from './constants'
import './MapMaker.css'
import RoundedStar from './round_star_black_24dp.png'

const { baseMap, subjectAndComps } = cartoDbConfig

const styles = theme => ({
  container: {
    position: 'absolute',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    top: APP_BAR_HEIGHT,
    left: DRAWER_WIDTH,
    right: 0,
    bottom: 0,
    backgroundColor: theme.palette.background.default,
  },
  root: {
    position: 'absolute',
    height: '100%',
    width: '100%',
    maxHeight: subjectAndComps.height * 2,
    maxWidth: subjectAndComps.width * 2,
  },
  leafletIcon: {
    border: 'none',
  },
  centerButton: {
    border: '2px solid rgba(0,0,0,0.2)',
    backgroundClip: 'padding-box',
    borderRadius: 3,
    backgroundColor: 'white',
    width: 30,
    height: 30,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: '#f4f4f4',
    },
  },
  centerButtonIcon: {
    fontSize: 18,
  },
  subjectIcon: {
    fontSize: 12,
    color: theme.palette.grey[900],
    margin: '8px auto auto 8px',
    transform: 'translate(-55%, -55%)',
  },
  markerLabelWrapper: {
    width: 165,
    position: 'relative',
  },
  markerLabel: {
    position: 'absolute',
    width: 165,
    transform: 'translate(-50%, -50%)',
    margin: ' 7px auto auto 7px',
    padding: 16,
    borderRadius: 8,
    backgroundColor: 'white',
    fontFamily: 'sans-serif',
    fontSize: 12,
    color: theme.palette.grey[900],
  },
  markerLabelTitle: {
    fontWeight: 600,
    color: '#3F65AB',
  },
  address: {
    fontSize: 12,
    fontWeight: 300,
    color: '#757575',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  compIcon: {
    width: 12,
    height: 12,
    backgroundColor: theme.palette.grey[900],
    borderRadius: '50%',
    margin: '8px auto auto 8px',
    transform: 'translate(-65%, -70%)',
  },
  customPinButton: {
    color: '#FFFFFF',
    zIndex: DIALOG_Z_INDEX,
    height: '40px',
    position: 'absolute',
    right: '5%',
    top: '7%',
  },
})

class MaterialSubjectMapWizard extends React.PureComponent {
  static propTypes = {
    properties: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string.isRequired,
        coords: PropTypes.arrayOf(PropTypes.number).isRequired,
        streetAddress: PropTypes.string.isRequired,
        city: PropTypes.string.isRequired,
        state: PropTypes.string.isRequired,
        country: PropTypes.string,
        isSubject: PropTypes.bool.isRequired,
        compIndex: PropTypes.number,
      })
    ).isRequired,
    mapOptions: PropTypes.shape({
      maxZoom: PropTypes.number.isRequired,
      zoomDelta: PropTypes.number.isRequired,
      zoomSnap: PropTypes.number.isRequired,
      wheelPxPerZoomLevel: PropTypes.number.isRequired,
      initialZoom: PropTypes.number.isRequired,
      initialCoordinates: PropTypes.shape({
        lat: PropTypes.number.isRequired,
        lng: PropTypes.number.isRequired,
      }),
      baseMap: PropTypes.string.isRequired,
    }),
    showAddCustomPinButton: PropTypes.bool.isRequired,
    onNewProperty: PropTypes.func.isRequired,
  }

  static defaultProps = {
    mapOptions: {
      initialCoordinates: subjectAndComps.initialCoordinates,
      initialZoom: subjectAndComps.initialZoom,
      maxZoom: subjectAndComps.maxZoom,
      baseMap: baseMap,
      zoomDelta: 0.3,
      zoomSnap: 0.1,
      wheelPxPerZoomLevel: 20,
    },
    showAddCustomPinButton: false,
  }
  state = { shouldDropPin: false }

  componentDidMount() {
    const { map } = this

    map.leafletElement.on('click', this.onMapClick)
  }

  componentWillUnmount() {
    const { map } = this

    map.leafletElement.off('click', this.onMapClick)
  }

  onMapClick = eventLeaflet => {
    if (this.state.shouldDropPin) {
      const property = {
        location: {
          lat: eventLeaflet.latlng.lat,
          lng: eventLeaflet.latlng.lng,
        },
      }
      this.props.onNewProperty(property)
      this.setState({ shouldDropPin: false })
    }
  }

  getMapRef = node => {
    this.map = node
  }

  getSubjectIcon = () => {
    return divIcon({
      className: '',
      html: `<div class="leaflet-subject-icon"></div>`,
    })
  }

  getCompIcon = () => {
    const { classes } = this.props
    return divIcon({
      className: '',
      html: `<div class="${classes.compIcon}"></div>`,
    })
  }

  getLabel = (title, property) => {
    const address = getAddress(property.streetAddress, property.city, property.state, property.zip)

    const { classes } = this.props
    const html = `
      <div data-qa="label-wrapper" class="${classes.markerLabelWrapper}">
        <div class="${classes.markerLabel}">
          <div data-qa="title" class="${classes.markerLabelTitle}">${title}</div>
          <div data-qa="address-one" class="${classes.address}">${address.addressOne}</div>
          <div data-qa="address-two" class="${classes.address}">${address.addressTwo}</div>
        </div>
      </div>
    `
    return divIcon({
      className: '',
      html,
    })
  }

  centerSubject = event => {
    event.preventDefault()
    const { map } = this
    const { properties } = this.props
    const { coords } = properties[0]
    map.leafletElement.setView(coords, 15)
  }

  render() {
    const {
      classes,
      mapOptions: { initialCoordinates, initialZoom, maxZoom, baseMap, zoomDelta, zoomSnap, wheelPxPerZoomLevel },
      properties,
      showAddCustomPinButton,
    } = this.props

    const { shouldDropPin } = this.state

    return (
      <div className={classes.container}>
        <Map
          id={MAP_ID}
          center={initialCoordinates}
          ref={this.getMapRef}
          zoom={initialZoom}
          maxZoom={maxZoom}
          zoomDelta={zoomDelta}
          zoomSnap={zoomSnap}
          wheelPxPerZoomLevel={wheelPxPerZoomLevel}
          className={classNames(classes.root, { 'leaflet-container-custom-cursor': shouldDropPin })}
        >
          {showAddCustomPinButton && (
            <Button
              variant="contained"
              className={classes.customPinButton}
              id="custom-pin-button"
              onClick={() => {
                this.setState({ shouldDropPin: true })
              }}
              sx={{ position: 'absolute' }}
            >
              ADD CUSTOM PIN
            </Button>
          )}
          <BaseMap attribution="" url={baseMap} />
          {properties.length > 0 && (
            <React.Fragment>
              <LeafletMapControl className={classes.centerButton} onClick={this.centerSubject} position="topleft">
                <StarRounded className={classes.centerButtonIcon} />
              </LeafletMapControl>
              {properties.map(property => {
                if (property.isSubject) {
                  return (
                    <DivIcon className={classes.leafletIcon} position={property.coords}>
                      <img className={classes.subjectIcon} src={RoundedStar} alt="subject" id={SUBJECT_ICON_ID} />
                    </DivIcon>
                  )
                } else {
                  return null
                }
              })}
            </React.Fragment>
          )}
          {properties.map((property, index) => {
            const isSubject = property.isSubject
            const icon = isSubject ? this.getSubjectIcon() : this.getCompIcon()
            const labelName = isSubject ? 'Subject' : `Comparable ${property.compIndex}`
            const labelIcon = this.getLabel(labelName, property)
            return (
              <ConnectedIconLabel
                key={property.id}
                icon={{
                  position: property.coords,
                  icon,
                }}
                label={{
                  icon: labelIcon,
                  draggable: true,
                }}
              />
            )
          })}
        </Map>
      </div>
    )
  }
}

export default withStyles(styles)(MaterialSubjectMapWizard)
