export const FLOOR_MATERIALS = {
  VINYL_TILED: 'Vinyl Tile',
  VINYL_PLANK: 'Vinyl Plank',
  CERAMIC_TILED: 'Ceramic Tile',
  MARBLE_TILED: 'Marble Tile',
  LAMINATE_TILED: 'Laminate Tile',
  PORCELAIN_TILED: 'Porcelain Tile',
  STONE_TILED: 'Stone Tile',
  NATURAL_STONE_TILED: 'Natural Stone Tile',
  HARDWOOD: 'Hardwood',
  LAMINATE_HARDWOOD: 'Laminate Hardwood',
  PREMIUM_HARDWOOD: 'Premium Hardwood',
  NATURAL_STONE: 'Natural Stone',
  CARPET: 'Carpet',
  LINOLEUM: 'Linoleum',
}
