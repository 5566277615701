import React, { ReactNode } from 'react'
import { Avatar, Typography } from '@mui/material'

interface AvatarWithTextProps {
  text: ReactNode
  src: string
  initials: string
}

const AvatarWithText: React.FC<AvatarWithTextProps> = ({ text, src, initials }) => {
  return (
    <div style={{ display: 'flex', alignItems: 'center' }}>
      <Avatar src={src} alt={initials}>
        {initials}
      </Avatar>
      <Typography variant="subtitle2" ml={1}>
        {text}
      </Typography>
    </div>
  )
}

export default AvatarWithText
