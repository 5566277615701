import React from 'react'
import PropTypes from 'prop-types'
import { get, min, max, toUpper, uniq } from 'lodash'
import { compose, mapProps, pure } from 'recompose'
import { withStyles } from '@mui/styles'
import { Grid, Typography, Card, CardContent } from '@mui/material'

import {
  formatExpenseId,
  getRecentHistoricalExpenses,
  isProjectionPeriod,
  calculateValuesMean,
} from 'shared/helpers/incomeApproach/expenses/helpers'
import { DEFAULT_EXPENSES_KEYS, EXPENSES_IN_ORDER, REAL_ESTATE_TAXES } from 'shared/constants/expenses'
import totalExpensesWriteup from 'shared/utils/textGeneration/incomeApproach/expenses/expenseForecast/total'
import { PropertyTypes } from 'shared/constants'

import { getForecastExpenseTotalsPerBasis } from '../../../../../../shared/utils/report/incomeApproach/expenses'
import { RadioButtonList } from '../../../../shared/components'
import NarrativeComponent from '../../../../shared/components/NarrativeComponent'
import { SwitchField } from '../../../../shared/components/_mui5/Switch'
import { formatCurrencyFloat } from '../../../../shared/utils/numberFormatters'
import { BasisTypes } from '../../../../../../shared/constants/report/incomeApproach/expenses'
import { DEFAULT_EXPENSES } from '../../../../shared/utils/expenses/constants'

import { NOT_AVAILABLE } from '../../../constants'

import { getTotalForecastedValue } from '../../../../../../shared/utils/report/incomeApproach'

import { DEFAULT_BASIS_OPTIONS } from './constants'

import ForecastItemLines from './ForecastItemLines'
import { getExpensePeriodLabel } from './ForecastItem'

import { formatCurrencyByBasis } from './tools'
import { styles } from './styles'

const TOOLTIP_TEXT = 'The following text will appear in the Estimated Operating Expenses sections of your report.'

const getCompTotalByBasis =
  (formatBasis = BasisTypes.PER_SF) =>
  (compTotalsMap, type) => {
    const prefix = formatBasis === BasisTypes.PER_SF ? 'psf' : 'unit'
    return get(compTotalsMap, `${prefix}${type}`)
  }

const TotalForecastItem = ({
  compTotalsMap,
  totalForecastedValue,
  expenseDataSource,
  basis,
  recentHistoricalExpenses,
  form,
}) => {
  const getCompTotal = getCompTotalByBasis(basis)
  const compMin = getCompTotal(compTotalsMap, 'Min')
  const compMax = getCompTotal(compTotalsMap, 'Max')
  const compAvg = getCompTotal(compTotalsMap, 'Avg')

  return (
    <Card>
      <CardContent>
        <Grid container>
          <Grid item container wrap="nowrap" direction="column" md={4} sm={12}>
            <Grid item>
              <Typography variant="subtitle1">TOTAL OPERATING EXPENSES</Typography>
            </Grid>
            <Grid item>
              <SwitchField
                name="total.includeRETaxesInOperatingExpenses"
                labelProps={{ label: 'Include taxes in Total Operating Expenses' }}
              />
            </Grid>
            <Grid item>
              <RadioButtonList name="total.basis" items={DEFAULT_BASIS_OPTIONS} horizontal />
            </Grid>
            <Grid item>
              {recentHistoricalExpenses.map((historicalExpense, index) => {
                const yearLabel = getExpensePeriodLabel(
                  historicalExpense.year,
                  historicalExpense.period,
                  expenseDataSource
                )
                return (
                  <Typography key={index} variant="body2" data-qa={`${historicalExpense.year}-historical`}>
                    {`${yearLabel}: `}
                    {historicalExpense.expense ? formatCurrencyFloat(historicalExpense.expense) : NOT_AVAILABLE}
                  </Typography>
                )
              })}
              <Typography variant="body2" data-qa="comp-min">
                Comp Min: {formatCurrencyFloat(compMin)}
              </Typography>
              <Typography variant="body2" data-qa="comp-avg">
                Comp Avg: {formatCurrencyFloat(compAvg)}
              </Typography>
              <Typography variant="body2" data-qa="comp-max">
                Comp Max: {formatCurrencyFloat(compMax)}
              </Typography>
              <Typography variant="body2" data-qa="appraisers-total-conclusion">
                Appraiser's Forecast: {formatCurrencyFloat(totalForecastedValue[basis] || 0)}
              </Typography>
            </Grid>
          </Grid>
          <Grid item container wrap="nowrap" direction="column" md={7} sm={11}>
            <Grid item>
              <Typography align="center" variant="subtitle1" mb={2}>
                TOTAL OPERATING EXPENSES ($/{toUpper(basis)})
              </Typography>
            </Grid>
            <Grid item>
              <ForecastItemLines
                formatter={formatCurrencyByBasis(basis)}
                basisValue={totalForecastedValue[basis]}
                recentHistoricalExpenses={recentHistoricalExpenses}
                compMin={compMin}
                compAvg={compAvg}
                compMax={compMax}
                expenseDataSource={expenseDataSource}
              />
            </Grid>
          </Grid>
        </Grid>
        <NarrativeComponent
          title="Generated Commentary"
          name="total.discussion"
          tooltipText={TOOLTIP_TEXT}
          generatedText={totalExpensesWriteup.generate}
          regenerateOnChange={['includeRETaxesInOperatingExpenses']}
          data={totalExpensesWriteup.mapDTO({ ...form.values, recentHistoricalExpenses })}
        />
      </CardContent>
    </Card>
  )
}

TotalForecastItem.propTypes = {
  form: PropTypes.object.isRequired,
  basis: PropTypes.string,
}

TotalForecastItem.defaultProps = { values: {} }

export default compose(
  pure,
  withStyles(styles),
  mapProps(({ form, expenseCategoriesIds = [], ...other }) => {
    const {
      numberOfResidentialUnits,
      numberOfCommercialUnits,
      propertyType,
      expenseHistoryTotals,
      expenseDataSource,
      total,
      customExpenses,
      mappedComparableExpenses,
      squareFootage,
      totalRoomCount,
      realEstateTaxes,
    } = form.values

    const { basis, includeRETaxesInOperatingExpenses } = total || {}

    const forecastedExpenses = getForecastExpenseTotalsPerBasis(form.values)

    const numberOfUnits = propertyType === PropertyTypes.COMMERCIAL ? numberOfCommercialUnits : numberOfResidentialUnits
    const totalForecastedValue = getTotalForecastedValue(
      forecastedExpenses,
      squareFootage,
      numberOfUnits,
      totalRoomCount,
      includeRETaxesInOperatingExpenses,
      realEstateTaxes
    )

    // categories
    const customExpensesNames = customExpenses.map(cat => formatExpenseId(cat.name))
    const defaultCategories = EXPENSES_IN_ORDER.map(expense => expense.category)
    const currentExpenseCategories = uniq([
      ...customExpensesNames,
      ...defaultCategories,
      formatExpenseId(DEFAULT_EXPENSES.management),
      formatExpenseId(DEFAULT_EXPENSES.reserves),
    ])
    if (includeRETaxesInOperatingExpenses) {
      currentExpenseCategories.push(REAL_ESTATE_TAXES.key)
    }

    // comps
    const getTotalComps = (basisValue, includeRETaxes) => {
      const totalComps = []
      const basisToFieldMap = {
        total: 'value',
        totalPerSF: 'sf',
        totalPerUnit: 'unit',
      }
      mappedComparableExpenses.forEach(comp => {
        let totalPerBasisValue = comp[basisValue]
        if (!includeRETaxes) {
          const realEstateTaxes = comp.expenses.find(expense => expense.id === DEFAULT_EXPENSES_KEYS.realEstateTaxes)
          const realEstateTaxesPerBasisValue = get(realEstateTaxes, basisToFieldMap[basisValue]) || 0
          totalPerBasisValue = totalPerBasisValue - realEstateTaxesPerBasisValue
        }
        totalComps.push(totalPerBasisValue)
      })
      return totalComps
    }

    const compTotals = getTotalComps('total', includeRETaxesInOperatingExpenses)
    const compTotalsPsf = getTotalComps('totalPerSF', includeRETaxesInOperatingExpenses)
    const compTotalsUnit = getTotalComps('totalPerUnit', includeRETaxesInOperatingExpenses)

    const compTotalsMap = {
      totalAvg: calculateValuesMean(compTotals),
      totalMin: min(compTotals),
      totalMax: max(compTotals),
      psfAvg: calculateValuesMean(compTotalsPsf),
      psfMin: min(compTotalsPsf),
      psfMax: max(compTotalsPsf),
      unitAvg: calculateValuesMean(compTotalsUnit),
      unitMin: min(compTotalsUnit),
      unitMax: max(compTotalsUnit),
    }

    const recentHistoricalExpenses = expenseHistoryTotals.map(item => {
      // In Strict mode we need to exclude subcategories because Parent categories stores sum of all subcategories
      const expenses = item.expenses.filter(({ id }) => expenseCategoriesIds.includes(id))
      const expenseInfo = {
        year: item.expenseYear,
        expense: getRecentHistoricalExpenses(expenses, basis, currentExpenseCategories) || null,
        period: item.expensePeriod,
        isProjected: isProjectionPeriod(item.expensePeriod),
      }
      return expenseInfo
    })

    return {
      ...other,
      totalForecastedValue,
      expenseDataSource,
      compTotalsMap,
      recentHistoricalExpenses,
      basis,
      form,
    }
  })
)(TotalForecastItem)
