import React from 'react'
import PropTypes from 'prop-types'
import { Typography, Grid, Table, TableHead, TableBody, TableRow, TableCell } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'

import { connect } from 'react-redux'

import { get, find } from 'lodash'

import {
  SELECTED_BUILDING_COMP_LIST_LABEL,
  REMOVED_BUILDING_COMP_LIST_LABEL,
  HIDE_REMOVED_LABEL,
  CLEAR_ALL_LABEL,
  SHOW_REMOVED_LABEL,
} from '../../constants'
import { Button } from '../../../../../../../shared/components'

import { selectRemovedBuildingComps } from '../../redux/selectors'
import * as Api from '../../../../../../../etc/buildingComparables/api'
import TableThemeProvider from '../../../../../../components/TableThemeProvider'
import { selectAllBuildingCompStats, selectFullSubjectStats } from '../../../ResidentialRentReconciliation/selectors'
import { formatCurrencyFloat } from '../../../../../../../shared/utils/numberFormatters'

import CompsImportForm from './BuildingCompsImportForm'

import SelectedBuildingCompList from './SelectedBuildingCompList'
import RemovedBuildingCompList from './RemovedBuildingCompList'

import CreateComp from './CreateComp'

const styles = theme => ({
  tableHeading: {
    marginTop: theme.spacing.unit * 3.5,
    marginBottom: theme.spacing.unit,
  },
  buildingListContainer: {
    marginTop: theme.spacing.unit * 2,
  },
  emptyTableLine: {
    marginTop: theme.spacing.unit * 2,
    backgroundColor: theme.palette.secondary[200],
    height: 2,
    border: 0,
  },
})

class BuildingCompPanel extends React.Component {
  static propTypes = {
    form: PropTypes.object.isRequired,
    clearAllRemoved: PropTypes.func.isRequired,
    disabled: PropTypes.bool,
    removeComp: PropTypes.func.isRequired,
    addRemovedBuildingComps: PropTypes.func.isRequired,
    removeRemovedBuildingComps: PropTypes.func.isRequired,
    createBuildingComp: PropTypes.func.isRequired,
    addImportedBuildingComps: PropTypes.func.isRequired,
  }

  state = {
    isRemovedVisible: true,
  }

  toggleRemovedVisibility = () => {
    this.setState(prevState => ({
      isRemovedVisible: !prevState.isRemovedVisible,
    }))
  }

  importComps = async (reportId, sourceReportId) => {
    const { addImportedBuildingComps } = this.props
    const importedComps = await Api.importBuildingComps(reportId, sourceReportId)
    addImportedBuildingComps(importedComps)
  }

  render() {
    const {
      classes,
      form,
      clearAllRemoved,
      disabled,
      removeComp,
      addRemovedBuildingComps,
      removeRemovedBuildingComps,
      createBuildingComp,
      reportId,
      subjectUnits,
    } = this.props
    const { selectedBuildingComps, unitGroups, showDevelopersForecast = false, unitRentPSFTimePeriod } = form.values
    const { isRemovedVisible } = this.state

    return (
      <div>
        <Grid container spacing={0} className={classes.tableHeading} justify="space-between">
          <Grid item>
            <Typography variant="h6">{SELECTED_BUILDING_COMP_LIST_LABEL}</Typography>
          </Grid>
          <Grid item>
            <Grid container>
              <Grid item>
                <CreateComp addComp={createBuildingComp} />
              </Grid>
              <Grid item>
                <CompsImportForm
                  reportId={reportId}
                  tooltipText="Use this to copy over Full Building Rent comps saved to another report."
                  tooltipPosition="top-end"
                  import={this.importComps}
                  dialogContent="Enter the ID of the report you would like to import comps from"
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <div className={classes.buildingListContainer}>
          <SelectedBuildingCompList removeComp={removeComp} form={form} />
        </div>

        <Grid container spacing={0} className={classes.tableHeading} justify="space-between" alignItems="center">
          <Grid item>
            <Typography variant="h6">{REMOVED_BUILDING_COMP_LIST_LABEL}</Typography>
          </Grid>
          <Grid item>
            <Button disabled={disabled} onClick={this.toggleRemovedVisibility}>
              {isRemovedVisible ? HIDE_REMOVED_LABEL : SHOW_REMOVED_LABEL}
            </Button>
            <Button disabled={disabled} wide color="error" onClick={clearAllRemoved} variant="contained">
              {CLEAR_ALL_LABEL}
            </Button>
          </Grid>
        </Grid>
        {!disabled && isRemovedVisible ? (
          <div className={classes.buildingListContainer}>
            <RemovedBuildingCompList
              addComp={addRemovedBuildingComps}
              removeComp={removeRemovedBuildingComps}
              form={form}
            />
          </div>
        ) : (
          <hr className={classes.emptyTableLine} />
        )}
        {unitGroups.map(unitGroup => {
          const rentConclusion = unitGroup.rentConclusion || {}
          const marketConclusionUnit = rentConclusion.market_conclusion_unit
          const units = unitGroup.units.map(unitId => find(subjectUnits, subjectUnit => subjectUnit._id === unitId))
          const rentReconciliationGroup = {
            unitGroupKey: unitGroup.key,
            unitGroupName: unitGroup.name,
            units,
            marketRentCompDiscussion: rentConclusion.market_rent_comp_discussion,
            marketConclusionUnit,
            marketConclusion: rentConclusion.market_conclusion,
            marketBreakdown: rentConclusion.market_breakdown,
          }
          const subjectStats = selectFullSubjectStats({
            rentReconciliationGroup,
            showDevelopersForecast,
            unitRentPSFTimePeriod,
          })
          const compStats = selectAllBuildingCompStats({
            rentReconciliationGroup,
            selectedBuildingComps,
            unitRentPSFTimePeriod,
          })

          return (
            <Grid container>
              <Grid item>
                <Typography variant="h6">{unitGroup.name} Range</Typography>
              </Grid>
              <TableThemeProvider>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell></TableCell>
                      <TableCell align="right">Subject Market Rate per SF</TableCell>
                      <TableCell align="right">Comparables per SF</TableCell>
                      <TableCell align="right">Subject Market Rate per month</TableCell>
                      <TableCell align="right">Comparables per month</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    <TableRow>
                      <TableCell align="left">Min</TableCell>
                      <TableCell align="right">{formatCurrencyFloat(subjectStats.min.sf)}</TableCell>
                      <TableCell align="right">{formatCurrencyFloat(compStats.min.sf)}</TableCell>
                      <TableCell align="right">{formatCurrencyFloat(subjectStats.min.month)}</TableCell>
                      <TableCell align="right">{formatCurrencyFloat(compStats.min.month)}</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell align="left">Avg</TableCell>
                      <TableCell align="right">{formatCurrencyFloat(subjectStats.avg.sf)}</TableCell>
                      <TableCell align="right">{formatCurrencyFloat(compStats.avg.sf)}</TableCell>
                      <TableCell align="right">{formatCurrencyFloat(subjectStats.avg.month)}</TableCell>
                      <TableCell align="right">{formatCurrencyFloat(compStats.avg.month)}</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell align="left">Max</TableCell>
                      <TableCell align="right">{formatCurrencyFloat(subjectStats.max.sf)}</TableCell>
                      <TableCell align="right">{formatCurrencyFloat(compStats.max.sf)}</TableCell>
                      <TableCell align="right">{formatCurrencyFloat(subjectStats.max.month)}</TableCell>
                      <TableCell align="right">{formatCurrencyFloat(compStats.max.month)}</TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </TableThemeProvider>
            </Grid>
          )
        })}
      </div>
    )
  }
}

const mapStateToProps = (state, ownProps) => {
  const reportId = get(state, 'report.reportData._id')
  const subjectUnits = get(state, 'report.reportData.incomeApproach.residentialIncome.residentialRentRoll.units')
  return {
    reportId,
    subjectUnits,
    disabled: selectRemovedBuildingComps(state, ownProps).length === 0,
  }
}

export default connect(mapStateToProps)(withStyles(styles)(BuildingCompPanel))
