import React from 'react'
import { Field } from 'react-final-form'

import { RadioList } from '../../_mui5'

const RadioListField = ({ items, name, ...restProps }) => {
  return (
    <Field
      name={name}
      {...restProps}
      render={({ input, meta, ...restInnerProps }) => {
        return (
          <RadioList
            {...restInnerProps}
            value={input.value}
            onChange={input.onChange}
            onFocus={input.onFocus}
            onBlur={input.onBlur}
            items={items}
          />
        )
      }}
    />
  )
}

export default RadioListField
