import { GeneratedText, gtLocked, gtTemplate, gtIfElse } from '../../../narrative/GeneratedText'

import { mapDTO, mapDataModel } from './mappings'

const generate = () => {
  const gt = new GeneratedText()

  gt.t`Overall capitalization rates are influenced by numerous factors, of which the most influential are: 
  investors' perception of risk, the potential for net income growth, and the market for competitive assets. 
  As indicated by the local comparable sales, assets in the submarket tend to trade for going-in returns 
  ${gtLocked('capRateRelativeToMarket')} the national range.\n\nIn terms of its position within the market range, it is 
  our view that an investor would accept a return ${gtLocked('capRateRelativeToComps')} of the comparable range for the 
  subject property. Our opinion is based on the fact that with respect to income, the subject is ${gtLocked(
    'occupancyLevel'
  )} occupied while the rent roll is operating at ${gtLocked('percentageOfMarketRent')} of market rents${gtIfElse(
    'noAdditionalInfo',
    gtTemplate`.`,
    gtTemplate`,`
  )}`
  gt.t`${gtLocked('capRateRiskAndPotential')}`
  gt.t`${gtLocked('capRateAssetCondition')}`
  gt.t`${gtLocked('capRateSubjectMarketConditions')}`

  return gt.getParts()
}

export default { generate, mapDTO, mapDataModel }
