import React from 'react'
import PropTypes from 'prop-types'
import { get } from 'lodash'

import { Divider, Grid } from '@material-ui/core'

import { PROPERTY_SEARCH_STRATEGY, SEARCH_OPTIONS, GEOGRAPHY_OPTIONS, LABELS } from '../../../../constants/properties'
import { cityZipCountySuggestion } from '../../../../constants/njDistricts'
import { AutosuggestField as Autosuggest } from '../../../Autosuggest'
import Text from '../../../Text'
import { required } from '../../../../utils/validation'
import withSearch from '../withSearch'

import StepFooter from '../../StepFooter'

export class AdvancedSearchNJ extends React.PureComponent {
  static propTypes = {
    searchProperties: PropTypes.func.isRequired,
    openBasicSearch: PropTypes.func.isRequired,
    invalid: PropTypes.bool.isRequired,
  }

  static buildQuery = values => {
    const district = get(values, 'search.district')
    const result = district.match(/(\D+) \((\d+)/) || []
    const [, cityName, zip] = result
    return {
      locationIdentifier: GEOGRAPHY_OPTIONS.NJ,
      locationType: SEARCH_OPTIONS.BLQ,
      strategy: PROPERTY_SEARCH_STRATEGY.BLQ,
      district: cityName,
      zip,
      block: get(values, 'search.block'),
      lot: get(values, 'search.lot'),
      qualifier: get(values, 'search.qualifier'),
    }
  }

  render() {
    const { searchProperties, openBasicSearch, invalid } = this.props

    return (
      <React.Fragment>
        <Divider light />
        <br />
        <Grid container spacing={8}>
          <Grid item xs={12}>
            <Autosuggest
              validate={required}
              selectOnBlur={false}
              required
              displayedSuggestionsCount={10}
              label="City (Zip) / County"
              name="search.district"
              suggestions={cityZipCountySuggestion}
            />
          </Grid>
          <Grid item xs={4}>
            <Text validate={required} required label="Block" name="search.block" />
          </Grid>
          <Grid item xs={4}>
            <Text validate={required} required label="Lot" name="search.lot" />
          </Grid>
          <Grid item xs={4}>
            <Text label="Qualifier" name="search.qualifier" />
          </Grid>
        </Grid>
        <StepFooter
          previous={{
            text: LABELS.STANDARD_SEARCH,
            onClick: openBasicSearch,
          }}
          next={{ text: LABELS.SEARCH, onClick: searchProperties, isDisabled: invalid }}
        />
      </React.Fragment>
    )
  }
}

export default withSearch(AdvancedSearchNJ)
