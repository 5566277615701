import { get } from 'lodash'

import { GEOGRAPHY_OPTIONS } from 'shared/constants/property/geography'

import { formatList } from 'shared/utils/textHelpers'
import { getLocationIdentifier } from 'shared/utils/report/propertyInformation'

import { formatSiteArea, SITE_AREA_LABEL_TYPE } from '../../../formatters'
import { SITE_AREA_SF_UNIT_OF_MEASURE } from '../../../../constants/report'

function getIdentificationSentence(isNYCReport, address) {
  let identificationSentence
  if (address.county) {
    identificationSentence = isNYCReport ? address.county : `the city of ${address.city}, ${address.county}`
  } else {
    identificationSentence = `the city of ${address.city}`
  }
  return identificationSentence
}
function getZoneString(zones) {
  const zoneList = zones.map(zone => {
    let zoneInfo = `an ${zone.zone} zone`
    if (zone.commercialOverlay) {
      zoneInfo += ` with a ${zone.commercialOverlay} commercial overlay`
    }
    return zoneInfo
  })
  return formatList(zoneList)
}
function getBlockLotString(address, locationIdentifier) {
  const { block, lot, propertyIdentifier, propertyIdentifierType } = address
  let blockLot = `as Block ${block}, Lot ${lot}.`
  if (locationIdentifier === GEOGRAPHY_OPTIONS.OTHER) {
    const propertyIdentifierTypePart = propertyIdentifierType ? `${propertyIdentifierType} ` : ''
    blockLot = ` as ${propertyIdentifierTypePart}${propertyIdentifier}.`
  }
  return blockLot
}

export const mapDTO = formValues => {
  const { siteArea, siteAreaMeasure, locationIdentifier, address = {}, zones = [] } = formValues

  const siteAreaSqFt = formatSiteArea({
    siteArea,
    siteAreaMeasure,
    labelType: SITE_AREA_LABEL_TYPE.LEGAL,
  })
  const isNYCReport = locationIdentifier === GEOGRAPHY_OPTIONS.NY
  const zoneString = getZoneString(zones)
  const identificationSentence = getIdentificationSentence(isNYCReport, address)

  const blockLotString = getBlockLotString(address, locationIdentifier)

  return {
    siteAreaSqFt,
    zoneString,
    identificationSentence,
    blockLotString,
  }
}

export const mapDataModel = report => {
  const siteArea = get(report, 'property_information.lotArea') || 0
  const siteAreaMeasure = get(report, 'property_information.siteAreaMeasure') || SITE_AREA_SF_UNIT_OF_MEASURE
  const address = get(report, 'property_information') || {}

  const zones = get(report, 'zoning.zones') || []
  const locationIdentifier = getLocationIdentifier(address.state, address.zip)
  const isNYCReport = locationIdentifier === GEOGRAPHY_OPTIONS.NY
  const siteAreaSqFt = formatSiteArea({
    siteArea,
    siteAreaMeasure,
    labelType: SITE_AREA_LABEL_TYPE.LEGAL,
  })

  const zoneString = getZoneString(zones)
  const identificationSentence = getIdentificationSentence(isNYCReport, address)
  const blockLotString = getBlockLotString(address, locationIdentifier)

  return {
    siteAreaSqFt,
    zoneString,
    identificationSentence,
    blockLotString,
  }
}
