import React from 'react'
import PropTypes from 'prop-types'

import moment from 'moment'
import 'moment-timezone'

import arrayMutators from 'final-form-arrays'
import { Form } from 'react-final-form'
import { connect } from 'react-redux'
import { get } from 'lodash'

import Save from '@mui/icons-material/Save'
import SettingsRounded from '@mui/icons-material/SettingsRounded'
import Verified from '@mui/icons-material/Verified'
import { Grid, Paper, Stack, Fab, Tooltip, Typography } from '@mui/material'

import { FeatureToggle } from '@bowery-valuation/feature-flagger-client'

import { DatePicker, DropDown, Number as NumberField, Text } from 'client-shared/components'
import FormHeader from 'client-shared/components/FormHeader'
import { required } from 'client-shared/utils/validation'
import { fromPercents, toPercents } from 'client-shared/utils/numberOperations'

import NarrativeComponent from 'client-shared/components/NarrativeComponent'
import investorSurveyDiscussion from 'shared/utils/textGeneration/organization/investorSurveyDiscussion'
import mortgageComponentIntroduction from 'shared/utils/textGeneration/organization/mortgageComponentIntroduction'
import ShowSection from 'client-shared/components/ShowSection'

import { VIEW_ONLY_TEXT } from 'admin/constants/organization'

import {
  ENABLE_AUTO_RESI_RENT_COMP_PICKER,
  FEATURE_FLAG_AUTO_SALES_COMPS_RULE_EDIT,
  FEATURE_FLAG_AUTO_EXPENSE_COMPS,
} from 'shared/constants/featureFlags'

import { organizationUpdate } from '../../redux/actions/organization'

import { calculateSuggestedMortgageRates } from './decorators'

const GRID_CONTAINER_GUTTER = 2

const FORM_NAME = 'settings'
const heading = 'Settings'

const QUARTERS = [
  { value: 1, label: '1' },
  { value: 2, label: '2' },
  { value: 3, label: '3' },
  { value: 4, label: '4' },
]

const FRED_COMMENT = 'This info has been automatically updated from the Federal Reserve Bank of St. Louis'

const TOOLTIP_TEXT = 'The following text will appear in the Income Capitalization Approach section of your report.'

class Settings extends React.PureComponent {
  static propTypes = {
    organizationUpdate: PropTypes.func.isRequired,
    section: PropTypes.string.isRequired,
  }

  handleSubmit = values => {
    this.props.organizationUpdate({
      formDataPath: FORM_NAME,
      values,
    })
  }

  getFredComment = bondType => {
    const { initialValues } = this.props
    const syncedAt = initialValues.surveyOfCompetitiveRates[bondType]?.syncedAt
    if (!syncedAt) {
      return null
    }

    const checkTime = moment(syncedAt).tz('America/New_York').format('LT z')

    return (
      <Stack direction="row" spacing={1} alignItems="center">
        <Typography variant="body2" sx={{ color: 'success.dark' }}>
          {FRED_COMMENT}
        </Typography>
        <Tooltip title={`Checked from FRED at ${checkTime}`} placement="top" aria-labelledby="fred-comment-tooltip">
          <Verified sx={{ color: 'success.dark' }} />
        </Tooltip>
      </Stack>
    )
  }

  render() {
    const { initialValues } = this.props

    return (
      <React.Fragment>
        <FormHeader icon={<SettingsRounded />} section={this.props.section} title={heading} />
        <Form
          initialValues={initialValues}
          mutators={arrayMutators}
          onSubmit={this.handleSubmit}
          decorators={[calculateSuggestedMortgageRates]}
          render={props => {
            const { handleSubmit, invalid } = props

            const jsonPrettyPrint = value => {
              if (!value) {
                return ''
              }
              try {
                const json = typeof value === 'string' ? JSON.parse(value) : value
                if (!json) {
                  return value
                }
                return JSON.stringify(json, null, 2)
              } catch (err) {
                return value
              }
            }

            const jsonParse = value => {
              try {
                return typeof value === 'string' ? JSON.parse(value) : value
              } catch (error) {
                return value
              }
            }

            const jsonValidator = value => {
              try {
                const json = typeof value === 'string' ? JSON.parse(value) : value
                if (!Array.isArray(json)) {
                  return 'Top level element must be an array.'
                }
                return null
              } catch (err) {
                console.warn('invalid json', err)
                return 'Must be valid JSON'
              }
            }

            return (
              <form onSubmit={handleSubmit}>
                <Grid container spacing={GRID_CONTAINER_GUTTER}>
                  <Grid item xs={8} lg={8}>
                    <Stack spacing={2}>
                      <Paper>
                        <Typography variant="h6">Description Of Improvements</Typography>
                        <Grid container spacing={GRID_CONTAINER_GUTTER}>
                          <Grid item xs={6}>
                            <NumberField
                              adornment="%"
                              decimalScale={2}
                              format={toPercents}
                              label="Leasable Area Factor"
                              max={100}
                              min={0}
                              name="leasableAreaFactor"
                              normalize={fromPercents}
                              validate={required}
                            />
                          </Grid>
                          <Grid item xs={6}>
                            <NumberField
                              adornment="years"
                              label="Total Economic Life"
                              name="totalEconomicLife"
                              validate={required}
                            />
                          </Grid>
                        </Grid>
                      </Paper>
                      <Paper>
                        <Typography variant="h6">Survey of Competitive Rates</Typography>
                        <Grid container spacing={GRID_CONTAINER_GUTTER}>
                          <Grid item xs={12}>
                            <NarrativeComponent
                              generatedText={mortgageComponentIntroduction.generate}
                              name="surveyOfCompetitiveRates.mortgageComponentIntroduction"
                              title="Mortgage Component Introduction"
                            />
                          </Grid>
                          <Grid item xs={12}>
                            <Typography variant="subtitle2">Federal Fund Rates</Typography>
                          </Grid>
                          <Grid item xs={4}>
                            <NumberField
                              adornment="%"
                              decimalScale={2}
                              format={toPercents}
                              label="Min Rate"
                              name="surveyOfCompetitiveRates.federalFund.min"
                              normalize={fromPercents}
                              validate={required}
                            />
                          </Grid>
                          <Grid item xs={4}>
                            <NumberField
                              adornment="%"
                              decimalScale={2}
                              format={toPercents}
                              label="Max Rate"
                              name="surveyOfCompetitiveRates.federalFund.max"
                              normalize={fromPercents}
                              validate={required}
                            />
                          </Grid>
                          <Grid item xs={4}>
                            <DatePicker
                              label="Last Updated"
                              margin="dense"
                              name="surveyOfCompetitiveRates.federalFund.lastUpdated"
                            />
                          </Grid>
                          <Grid item xs={12}>
                            <Typography variant="subtitle2">5-Year CD</Typography>
                          </Grid>
                          <Grid item xs={4}>
                            <NumberField
                              adornment="%"
                              decimalScale={2}
                              format={toPercents}
                              label="Min Rate"
                              name="surveyOfCompetitiveRates.fiveYearCd.min"
                              normalize={fromPercents}
                              validate={required}
                            />
                          </Grid>
                          <Grid item xs={4}>
                            <NumberField
                              adornment="%"
                              decimalScale={2}
                              format={toPercents}
                              label="Max Rate"
                              name="surveyOfCompetitiveRates.fiveYearCd.max"
                              normalize={fromPercents}
                              validate={required}
                            />
                          </Grid>
                          <Grid item xs={4}>
                            <DatePicker
                              label="Last Updated"
                              margin="dense"
                              name="surveyOfCompetitiveRates.fiveYearCd.lastUpdated"
                            />
                          </Grid>
                          <Grid item xs={12}>
                            <Typography variant="subtitle2">10-Year Treasury Bond</Typography>
                          </Grid>
                          <Grid item xs={12}>
                            {this.getFredComment('tenYearTreasuryBond')}
                          </Grid>
                          <Grid item xs={6}>
                            <NumberField
                              adornment="%"
                              decimalScale={2}
                              format={toPercents}
                              label="Rate"
                              name="surveyOfCompetitiveRates.tenYearTreasuryBond.rate"
                              normalize={fromPercents}
                              validate={required}
                            />
                          </Grid>
                          <Grid item xs={6}>
                            <DatePicker
                              label="Last Updated"
                              margin="dense"
                              name="surveyOfCompetitiveRates.tenYearTreasuryBond.lastUpdated"
                            />
                          </Grid>
                          <Grid item xs={6}>
                            <NumberField
                              label="Suggested Mortgage Rate Min Basis Points"
                              name="surveyOfCompetitiveRates.suggestedMortgage.minBasisPoints"
                              validate={required}
                            />
                          </Grid>
                          <Grid item xs={6}>
                            <NumberField
                              label="Suggested Mortgage Rate Max Basis Points"
                              name="surveyOfCompetitiveRates.suggestedMortgage.maxBasisPoints"
                              validate={required}
                            />
                          </Grid>
                          <Grid item xs={4}>
                            <NumberField
                              adornment="%"
                              decimalScale={2}
                              format={toPercents}
                              label="Suggested Mortgage Rate Min"
                              name="surveyOfCompetitiveRates.suggestedMortgage.minRate"
                              normalize={fromPercents}
                              validate={required}
                              disabled
                            />
                          </Grid>
                          <Grid item xs={4}>
                            <NumberField
                              adornment="%"
                              decimalScale={2}
                              format={toPercents}
                              label="Suggested Mortgage Rate Max"
                              name="surveyOfCompetitiveRates.suggestedMortgage.maxRate"
                              normalize={fromPercents}
                              validate={required}
                              disabled
                            />
                          </Grid>
                          <Grid item xs={4}>
                            <DatePicker
                              label="Last Updated"
                              margin="dense"
                              name="surveyOfCompetitiveRates.suggestedMortgage.lastUpdated"
                            />
                          </Grid>
                          <Grid item xs={12}>
                            <Typography variant="subtitle2">30-Year Treasury Bond</Typography>
                          </Grid>
                          <Grid item xs={12}>
                            {this.getFredComment('thirtyYearTreasuryBond')}
                          </Grid>
                          <Grid item xs={6}>
                            <NumberField
                              adornment="%"
                              decimalScale={2}
                              format={toPercents}
                              label="Rate"
                              name="surveyOfCompetitiveRates.thirtyYearTreasuryBond.rate"
                              normalize={fromPercents}
                              validate={required}
                            />
                          </Grid>
                          <Grid item xs={6}>
                            <DatePicker
                              label="Last Updated"
                              margin="dense"
                              name="surveyOfCompetitiveRates.thirtyYearTreasuryBond.lastUpdated"
                            />
                          </Grid>
                          <Grid item xs={12}>
                            <Typography variant="subtitle2">Corporate Bonds (AAA)</Typography>
                          </Grid>
                          <Grid item xs={12}>
                            {this.getFredComment('corporateBonds')}
                          </Grid>
                          <Grid item xs={6}>
                            <NumberField
                              adornment="%"
                              decimalScale={2}
                              format={toPercents}
                              label="Rate"
                              name="surveyOfCompetitiveRates.corporateBonds.rate"
                              normalize={fromPercents}
                              validate={required}
                            />
                          </Grid>
                          <Grid item xs={6}>
                            <DatePicker
                              label="Last Updated"
                              margin="dense"
                              name="surveyOfCompetitiveRates.corporateBonds.lastUpdated"
                            />
                          </Grid>
                        </Grid>
                      </Paper>
                      <Paper>
                        <Typography variant="h6">Investor Survey</Typography>
                        <Grid container spacing={GRID_CONTAINER_GUTTER}>
                          <Grid item xs={12}>
                            <Typography variant="subtitle2">National Apartment Market Realty Rates</Typography>
                          </Grid>
                          <Grid item xs={3}>
                            <DropDown label="Quarter" name="investorSurvey.quarter" items={QUARTERS} />
                          </Grid>
                          <Grid item xs={3}>
                            <DatePicker name="investorSurvey.year" label="Year" margin="dense" />
                          </Grid>
                          <Grid item xs={2}>
                            <NumberField
                              adornment="%"
                              decimalScale={2}
                              format={toPercents}
                              label="Equity Yield Min"
                              name="investorSurvey.min"
                              normalize={fromPercents}
                              validate={required}
                            />
                          </Grid>
                          <Grid item xs={2}>
                            <NumberField
                              adornment="%"
                              decimalScale={2}
                              format={toPercents}
                              label="Equity Yield Avg"
                              name="investorSurvey.avg"
                              normalize={fromPercents}
                              validate={required}
                            />
                          </Grid>
                          <Grid item xs={2}>
                            <NumberField
                              adornment="%"
                              decimalScale={2}
                              format={toPercents}
                              label="Equity Yield Max"
                              name="investorSurvey.max"
                              normalize={fromPercents}
                              validate={required}
                            />
                          </Grid>
                          <Grid item xs={12}>
                            <NarrativeComponent
                              data={investorSurveyDiscussion.mapDataFromFormValues(props.values)}
                              generatedText={investorSurveyDiscussion.generate}
                              name="investorSurvey.commentary"
                              title="Investor Survey Discussion"
                              tooltipText={TOOLTIP_TEXT}
                            />
                          </Grid>
                        </Grid>
                      </Paper>
                      <Paper>
                        <Typography variant="h6">National Survey Responses</Typography>
                        <Grid container spacing={GRID_CONTAINER_GUTTER}>
                          <Grid item xs={12}>
                            <Typography variant="subtitle2">PwC</Typography>
                          </Grid>
                          <Grid item xs={3}>
                            <DropDown label="Quarter" name="nationalSurveyResponses.pwc.quarter" items={QUARTERS} />
                          </Grid>
                          <Grid item xs={3}>
                            <DatePicker name="nationalSurveyResponses.pwc.year" label="Year" margin="dense" />
                          </Grid>
                          <Grid item xs={2}>
                            <NumberField
                              adornment="%"
                              decimalScale={2}
                              format={toPercents}
                              label="Min"
                              name="nationalSurveyResponses.pwc.min"
                              normalize={fromPercents}
                              validate={required}
                            />
                          </Grid>
                          <Grid item xs={2}>
                            <NumberField
                              adornment="%"
                              decimalScale={2}
                              format={toPercents}
                              label="Avg"
                              name="nationalSurveyResponses.pwc.avg"
                              normalize={fromPercents}
                              validate={required}
                            />
                          </Grid>
                          <Grid item xs={2}>
                            <NumberField
                              adornment="%"
                              decimalScale={2}
                              format={toPercents}
                              label="Max"
                              name="nationalSurveyResponses.pwc.max"
                              normalize={fromPercents}
                              validate={required}
                            />
                          </Grid>
                          <Grid item xs={12}>
                            <Typography variant="subtitle2">Situs - RERC</Typography>
                          </Grid>
                          <Grid item xs={3}>
                            <DropDown label="Quarter" name="nationalSurveyResponses.rec.quarter" items={QUARTERS} />
                          </Grid>
                          <Grid item xs={3}>
                            <DatePicker name="nationalSurveyResponses.rec.year" label="Year" margin="dense" />
                          </Grid>
                          <Grid item xs={2}>
                            <NumberField
                              adornment="%"
                              decimalScale={2}
                              format={toPercents}
                              label="Min"
                              name="nationalSurveyResponses.rec.min"
                              normalize={fromPercents}
                              validate={required}
                            />
                          </Grid>
                          <Grid item xs={2}>
                            <NumberField
                              adornment="%"
                              decimalScale={2}
                              format={toPercents}
                              label="Avg"
                              name="nationalSurveyResponses.rec.avg"
                              normalize={fromPercents}
                              validate={required}
                            />
                          </Grid>
                          <Grid item xs={2}>
                            <NumberField
                              adornment="%"
                              decimalScale={2}
                              format={toPercents}
                              label="Max"
                              name="nationalSurveyResponses.rec.max"
                              normalize={fromPercents}
                              validate={required}
                            />
                          </Grid>
                        </Grid>
                      </Paper>
                      <FeatureToggle featureFlag={FEATURE_FLAG_AUTO_SALES_COMPS_RULE_EDIT}>
                        <Paper>
                          <Typography variant="h6">Auto Sales Comp Picker</Typography>
                          <Grid container spacing={GRID_CONTAINER_GUTTER}>
                            <Grid item xs={4}>
                              <NumberField
                                adornment="meters"
                                label="Max Distance"
                                name="autoSalesComps.maxDistance"
                                validate={required}
                                min={100}
                                max={50000}
                              />
                            </Grid>
                            <Grid item xs={4}>
                              <NumberField
                                adornment="meters"
                                label="Max Distance (Non-NYC)"
                                name="autoSalesComps.nonNycMaxDistance"
                                validate={required}
                                min={100}
                                max={100000}
                              />
                            </Grid>
                            <Grid item xs={4}>
                              <NumberField
                                adornment="+/- %"
                                label="PPU Range"
                                name="autoSalesComps.ppuRange"
                                validate={required}
                                max={100}
                                min={0}
                                format={toPercents}
                                normalize={fromPercents}
                              />
                            </Grid>
                            <Grid item xs={4}>
                              <NumberField
                                label="Num Comps"
                                name="autoSalesComps.numComps"
                                validate={required}
                                max={100}
                                min={1}
                              />
                            </Grid>
                            <Grid item xs={12}>
                              <Text
                                label="Rules"
                                name="autoSalesComps.rules"
                                validate={jsonValidator}
                                rows={20}
                                format={jsonPrettyPrint}
                                parse={jsonParse}
                              />
                            </Grid>
                          </Grid>
                        </Paper>
                        <FeatureToggle featureFlag={ENABLE_AUTO_RESI_RENT_COMP_PICKER}>
                          <Paper>
                            <Typography variant="h6">Auto Residential Rent Comp Picker</Typography>
                            <Grid container spacing={GRID_CONTAINER_GUTTER}>
                              <Grid item xs={6}>
                                <NumberField
                                  adornment="meters"
                                  label="Max Distance"
                                  name="autoResiRentComps.maxDistance"
                                  validate={required}
                                  min={100}
                                  max={50000}
                                />
                              </Grid>
                              <Grid item xs={6}>
                                <NumberField
                                  adornment="+/- %"
                                  label="Monthly Rent Range"
                                  name="autoResiRentComps.monthlyRentIncrement"
                                  validate={required}
                                  max={100}
                                  min={0}
                                  format={toPercents}
                                  normalize={fromPercents}
                                />
                              </Grid>
                              <Grid item xs={12}>
                                <Text
                                  label="Rules"
                                  name="autoResiRentComps.rules"
                                  validate={jsonValidator}
                                  rows={20}
                                  format={jsonPrettyPrint}
                                  parse={jsonParse}
                                />
                              </Grid>
                            </Grid>
                          </Paper>
                        </FeatureToggle>
                      </FeatureToggle>
                      <FeatureToggle featureFlag={FEATURE_FLAG_AUTO_EXPENSE_COMPS}>
                        <Paper>
                          <Typography variant="h6">Recent Similar Reports Query</Typography>
                          <Grid container spacing={GRID_CONTAINER_GUTTER}>
                            <Grid item xs={4}>
                              <NumberField
                                adornment="meters"
                                label="Max Distance"
                                name="expenseAutomation.maxDistance"
                                validate={required}
                                min={100}
                                max={50000}
                              />
                            </Grid>
                            <Grid item xs={4}>
                              <NumberField
                                label="Number of Reports"
                                name="expenseAutomation.numReports"
                                validate={required}
                                min={1}
                                max={100}
                              />
                            </Grid>
                            <Grid item xs={4}>
                              <NumberField
                                adornment="years"
                                label="Valuation Date Difference"
                                name="expenseAutomation.valuationDateDifferenceInYears"
                                validate={required}
                                min={1}
                                max={5}
                              />
                            </Grid>
                            <Grid item xs={4}>
                              <NumberField
                                adornment="+/- %"
                                label="Residential Units Target Range"
                                name="expenseAutomation.totalExpensesRange"
                                validate={required}
                                max={100}
                                min={0}
                                format={toPercents}
                                normalize={fromPercents}
                              />
                            </Grid>
                            <Grid item xs={12}>
                              <Text
                                label="Recent Similar Reports Comparison Rules"
                                name="expenseAutomation.recentSimilarReportsRules"
                                validate={jsonValidator}
                                rows={20}
                                format={jsonPrettyPrint}
                                parse={jsonParse}
                              />
                            </Grid>
                          </Grid>
                        </Paper>

                        <Paper>
                          <Typography variant="h6">Expense Comps Automation</Typography>
                          <Grid container spacing={GRID_CONTAINER_GUTTER}>
                            <Grid item xs={4}>
                              <NumberField
                                adornment="+/- %"
                                label="Total Expenses Range"
                                name="expenseAutomation.totalExpensesRange"
                                validate={required}
                                max={100}
                                min={0}
                                format={toPercents}
                                normalize={fromPercents}
                              />
                            </Grid>
                            <Grid item xs={4}>
                              <NumberField
                                label="Num Comps"
                                name="expenseAutomation.numComps"
                                validate={required}
                                max={100}
                                min={1}
                              />
                            </Grid>
                            <Grid item xs={12}>
                              <Text
                                label="Expense Comp Comparison Rules"
                                name="expenseAutomation.expenseCompAutomationRules"
                                validate={jsonValidator}
                                rows={20}
                                format={jsonPrettyPrint}
                                parse={jsonParse}
                              />
                            </Grid>
                          </Grid>
                        </Paper>
                      </FeatureToggle>
                    </Stack>
                  </Grid>
                  <ShowSection permission="organizationSettings.edit">
                    <Fab
                      color="success"
                      disabled={invalid}
                      sx={{ position: 'fixed', bottom: 40, right: 40 }}
                      type="submit"
                    >
                      <Save sx={{ color: 'white' }} />
                    </Fab>
                  </ShowSection>
                  <ShowSection permission="!organizationSettings.edit">
                    <Fab color="success" disabled sx={{ position: 'fixed', bottom: 40, right: 40 }} type="submit">
                      {VIEW_ONLY_TEXT}
                    </Fab>
                  </ShowSection>
                </Grid>
              </form>
            )
          }}
        />
      </React.Fragment>
    )
  }
}

export default connect(
  state => {
    return {
      initialValues: get(state, `organization.organizationData.settings`, {}),
    }
  },
  {
    organizationUpdate,
  }
)(Settings)
