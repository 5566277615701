import React from 'react'

import { TextField } from '@mui/material'

import { useMeta, useSx } from '../hooks'
import * as modifiers from '../sXmodifiers'

import { useAdornments, useInputPropsModified } from './hooks'

const Default = ({ InputProps, adornment, custom, format, hint, input, meta, sx, ...props }) => {
  const InputPropsDecorated = useAdornments({ InputProps, adornment })
  const InputPropsModified = useInputPropsModified({ InputProps: InputPropsDecorated, format })
  const sxModified = useSx({ custom, modifiers, sx })
  const metaModified = useMeta({ hint, meta })

  return <TextField {...props} {...input} {...metaModified} InputProps={InputPropsModified} sx={sxModified} />
}

export default Default
