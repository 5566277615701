import React from 'react'

import { Tooltip } from '@mui/material'
import { withStyles } from '@mui/styles'

import { capitalCase } from '../../../../../../../shared/utils/formatters/textFormatters'

const styles = theme => ({
  root: {
    color: '#617083',
    backgroundColor: '#F8FAFC',
    paddingLeft: 8,
    paddingRight: 8,
    paddingTop: 2,
    borderRadius: 4,
    paddingBottom: 2,
  },
  focused: {
    color: theme.palette.primary.main,
    paddingLeft: 8,
    paddingRight: 8,
    paddingTop: 2,
    paddingBottom: 2,
    borderRadius: 4,
  },
  error: {
    color: '#D24141',
    backgroundColor: '#FFE9E9',
    paddingLeft: 8,
    paddingRight: 8,
    paddingTop: 2,
    paddingBottom: 2,
    borderRadius: 4,
  },
  readOnly: {
    color: '#617083',
    '&:hover': {
      color: theme.palette.grey[800],
    },
    backgroundColor: '#F8FAFC',
  },
  readOnlyError: {
    backgroundColor: '#F8FAFC',
    color: '#D24141',
  },
})

const Locked = ({ classes, children, attributes, focused = false, error = false, inline = true }) => {
  const getClassName = (focused, readOnly, error) => {
    if (readOnly && error) {
      return classes.readOnlyError
    } else if (readOnly) {
      return classes.readOnly
    } else if (error) {
      return classes.error
    } else {
      return focused ? classes.focused : classes.root
    }
  }

  const Wrapper = inline ? 'span' : 'div'

  const { displayName, ...restAttributes } = attributes
  return (
    <Tooltip title={capitalCase(displayName)} placement="top">
      <Wrapper
        {...restAttributes}
        contentEditable={false}
        className={getClassName(focused, attributes.readOnly, error)}
      >
        {children}
      </Wrapper>
    </Tooltip>
  )
}

export default withStyles(styles)(Locked)
