import React from 'react'

import Typography from '@mui/material/Typography'

const SectionHeader = ({ children, title }) => (
  <Typography
    color="text.secondary"
    fontSize={15}
    fontWeight={700}
    letterSpacing={0.46}
    lineHeight={1.5}
    textTransform="uppercase"
    variant="h6"
  >
    {title || children}
  </Typography>
)

export default SectionHeader
