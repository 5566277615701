import React from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '@mui/styles'
//TODO: Revisit this component

import TableBase from '../../Table/TableComponent'

import styles from './styles'
import { registerCheckbox } from './checkbox'
import { registerDate } from './date'
import { registerSelect } from './select'
import { registerExpirationDate } from './expirationDate'

const registerCellTypes = () => {
  registerCheckbox()
  registerDate()
  registerExpirationDate()
  registerSelect()
}
registerCellTypes()

class Table extends React.PureComponent {
  static propTypes = {
    data: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.object), PropTypes.arrayOf(PropTypes.array)]).isRequired,
    columns: PropTypes.arrayOf(PropTypes.object).isRequired,
    cells: PropTypes.func,
    initTable: PropTypes.func,
    beforeChange: PropTypes.func,
    stretchHeaders: PropTypes.oneOf(['all', 'none', 'last']),
  }

  static defaultProps = {
    stretchHeaders: 'all',
  }

  hotInstance = React.createRef()

  /**
   * Merge statically specified cell settings with the generated ones
   * @param row
   * @param col
   * @param prop
   * @returns {{[p: string]: *}}
   */
  mergeCellProps = (row, col, prop) => {
    const { cells, classes, columns } = this.props
    const column = columns[col]

    const staticCellProps = column?.cellProps ?? {}
    const generatedCellProps = cells ? cells(row, col, prop) || {} : {}

    const cellConfig = {
      ...staticCellProps,
      ...generatedCellProps,
    }

    /*
     * Class Name
     * TODO: Use JSS
     */
    let className = staticCellProps.className || ''
    if (generatedCellProps.className) {
      className += ` ${classes[generatedCellProps.className]}`
    }
    if (generatedCellProps.readOnly || column.readOnly) {
      className += ` ${classes.readOnlyCell}`
    }
    if (cellConfig.hasError) {
      className += ` ${classes.errorCell}`
    }
    cellConfig.className = className

    /*
     * Renderer
     */
    if (generatedCellProps.readOnly || column.readOnly) {
      cellConfig.renderer = generatedCellProps.renderer || column.readOnlyRenderer
    } else {
      cellConfig.renderer = staticCellProps.renderer
    }

    return cellConfig
  }

  initTable = ref => {
    const { columns, beforeChange, initTable } = this.props
    if (!ref || !ref.hotInstance) {
      return
    }

    this.hotInstance = ref.hotInstance

    this.hotInstance.addHook('afterGetColHeader', (col, th) => {
      const className = columns[col]?.headerClassName
      if (className) {
        th.classList.add(className)
      }
    })

    this.hotInstance.addHook('afterBeginEditing', (row, col) => {
      const td = this.hotInstance.getCell(row, col)
      if (td) {
        td.style.position = 'initial'
        td.style['box-shadow'] = 'none'
      }
    })

    ref.updateCellValue = this.updateCellValue

    if (beforeChange) {
      this.hotInstance.addHook('beforeChange', beforeChange)
    }

    if (initTable) {
      initTable(ref)
    }
  }

  /**
   * Update table cell value
   * @param row
   * @param fieldName
   * @param value
   */
  updateCellValue = (row, fieldName, value) => {
    const { beforeChange } = this.props

    if (!beforeChange) {
      return
    }

    if (!Array.isArray(row)) {
      row = [[row, fieldName, value]]
    }

    row.forEach(update => {
      if (update[0] < 0) {
        update[0] = this.hotInstance.countSourceRows() + update[0]
      }
    })

    this.hotInstance.removeHook('beforeChange', beforeChange)
    this.hotInstance.setDataAtRowProp(row, fieldName, value)
    this.hotInstance.addHook('beforeChange', beforeChange)
  }

  render() {
    const {
      classes,
      children,
      cells,
      className,
      columns,
      data,
      stretchHeaders,
      initTable,
      beforeChange,
      ...restProps
    } = this.props

    return (
      <div className={classes.root}>
        <TableBase
          data={data}
          columns={columns}
          cells={this.mergeCellProps}
          setTableRef={this.initTable}
          stretchH={stretchHeaders}
          {...restProps}
        />
      </div>
    )
  }
}

export default withStyles(styles)(Table)
