import { GeneratedText } from '../../../../narrative/GeneratedText'

import { mapDTO, mapDataModel } from '../mappings'

export const ESTIMATED_DEPRECIATION = 'The estimated depreciation is summarized in the table below.'

const generate = () => {
  const gt = new GeneratedText()

  gt.t`${ESTIMATED_DEPRECIATION}`

  return gt.getParts()
}

export default { generate, mapDTO, mapDataModel }
