import React from 'react'
import { get } from 'lodash'
import { connect } from 'react-redux'

import { BOROUGH_LOOKUP } from 'shared/constants/boroughs'
import { GEOGRAPHY_OPTIONS } from 'shared/constants/property/geography'

import Header from './Header'

class ReportHeaderContainer extends React.PureComponent {
  get isSupportedGeography() {
    const { locationIdentifier } = this.props

    return locationIdentifier !== GEOGRAPHY_OPTIONS.OTHER
  }

  get borough() {
    const { borough, city } = this.props

    return BOROUGH_LOOKUP[borough] || city
  }

  get basisForSFAnalysis() {
    const { basisForSFAnalysis } = this.props

    return basisForSFAnalysis
  }

  get squareFootage() {
    const { squareFootage } = this.props

    return squareFootage
  }

  get propertyIdentifier() {
    const { propertyIdentifier, propertyIdentifierType } = this.props

    if (propertyIdentifierType && propertyIdentifier) {
      return `${propertyIdentifierType}: ${propertyIdentifier}`
    }

    return `${propertyIdentifier}`
  }

  get incomeValue() {
    const { valueAsStabilized, finalIncomeValues } = this.props

    return valueAsStabilized ? finalIncomeValues.finalValueAsStabilized : finalIncomeValues.finalValueAsIs
  }

  get salesValue() {
    const { valueAsStabilized, finalSalesValues } = this.props

    return valueAsStabilized ? finalSalesValues.finalValueAsStabilized : finalSalesValues.finalValueAsIs
  }

  render() {
    const { status, address, block, lot, commercialUnitCount, residentialUnitCount, concludedCapRate } = this.props

    return (
      <Header
        lot={lot}
        block={block}
        status={status}
        address={address}
        borough={this.borough}
        salesValue={this.salesValue}
        incomeValue={this.incomeValue}
        concludedCapRate={concludedCapRate}
        basisForSFAnalysis={this.basisForSFAnalysis}
        squareFootage={this.squareFootage}
        commercialUnitCount={commercialUnitCount}
        residentialUnitCount={residentialUnitCount}
        propertyIdentifier={this.propertyIdentifier}
        isSupportedGeography={this.isSupportedGeography}
      />
    )
  }
}

const mapFinalValues = finalValues => ({
  finalValueAsStabilized: finalValues?.finalValueAsStabilizedRoundedUnformatted,
  finalValueAsIs: finalValues?.finalValueAsIsRoundedUnformatted,
})

export default connect(state => {
  const valueAsStabilized = get(state, 'report.reportSettings.valueAsStabilized')
  const reportData = get(state, 'report.reportData') || {}
  const isBlocks = get(state, 'report.reportSettings.isBlocks')
  let status = get(reportData, 'reviewAndExport.state') || ''

  if (isBlocks) {
    status = 'BLOCKS'
  }

  const { incomeApproach, salesApproach } = get(reportData, 'acas') || {}
  const locationIdentifier = get(reportData, 'locationIdentifier')
  const {
    city,
    propertyIdentifier,
    propertyIdentifierType,
    commercialUnitCount,
    residentialUnitCount,
    basisForSFAnalysis,
    squareFootage,
  } = get(reportData, 'propertyInformation.propertySummary') || {}

  const concludedCapRate = get(reportData, 'incomeApproach.capRateConclusion.concludedCapRate')

  const finalIncomeValues = mapFinalValues(incomeApproach)
  const finalSalesValues = mapFinalValues(salesApproach)

  const { address, block, borough, lot } = reportData

  return {
    status,
    address,
    block,
    lot,
    borough,
    city,
    concludedCapRate,
    propertyIdentifier,
    propertyIdentifierType,
    basisForSFAnalysis,
    squareFootage,
    commercialUnitCount,
    residentialUnitCount,
    valueAsStabilized,
    finalIncomeValues,
    finalSalesValues,
    locationIdentifier,
  }
})(ReportHeaderContainer)
