import { createAction } from 'redux-actions'

import {
  REPORT_FETCH,
  REPORT_UPDATE,
  REPORT_SETTINGS_UPDATE,
  REPORT_CLEAR,
  UPDATE_REPORT_STATE,
  REPORT_CHANGE_COMP_TYPE,
  SHOW_CONFIRM_MODAL,
  HIDE_CONFIRM_MODAL,
  SHOW_FEEDBACK_MODAL,
  HIDE_FEEDBACK_MODAL,
  NAVIGATION_CONFIRMATION,
  REPORT_STRUCTURE_UPDATE,
  REPORT_SAVE,
  UPDATE_NOTES,
} from '../actionTypes'

export const reportFetch = createAction(REPORT_FETCH.ACTION)
export const reportFetchRequest = createAction(REPORT_FETCH.REQUEST)
export const reportFetchReceive = createAction(REPORT_FETCH.RECEIVE)
export const reportFetchFail = createAction(REPORT_FETCH.FAIL)

export const reportUpdate = createAction(REPORT_UPDATE.ACTION)
export const reportUpdateRequest = createAction(REPORT_UPDATE.REQUEST)
export const reportUpdateReceive = createAction(REPORT_UPDATE.RECEIVE)
export const reportUpdateFail = createAction(REPORT_UPDATE.FAIL)
export const reportUpdateComplete = createAction(REPORT_UPDATE.COMPLETE)

export const reportClear = createAction(REPORT_CLEAR.ACTION)
export const changeReportCompType = createAction(REPORT_CHANGE_COMP_TYPE.ACTION)

export const updateReportState = createAction(UPDATE_REPORT_STATE.ACTION)
export const updateReportStateRequest = createAction(UPDATE_REPORT_STATE.REQUEST)
export const updateReportStateReceive = createAction(UPDATE_REPORT_STATE.RECEIVE)
export const updateReportStateFail = createAction(UPDATE_REPORT_STATE.FAIL)

export const showConfirmModal = createAction(SHOW_CONFIRM_MODAL.ACTION)
export const hideConfirmModal = createAction(HIDE_CONFIRM_MODAL.ACTION)

export const showFeedbackModal = createAction(SHOW_FEEDBACK_MODAL.ACTION)
export const hideFeedbackModal = createAction(HIDE_FEEDBACK_MODAL.ACTION)

export const navigationConfirmation = createAction(NAVIGATION_CONFIRMATION.ACTION)

export const reportSettingsUpdate = createAction(REPORT_SETTINGS_UPDATE.ACTION)
export const reportSettingsUpdateRequest = createAction(REPORT_SETTINGS_UPDATE.REQUEST)
export const reportSettingsUpdateReceive = createAction(REPORT_SETTINGS_UPDATE.RECEIVE)
export const reportSettingsUpdateFail = createAction(REPORT_SETTINGS_UPDATE.FAIL)

export const reportStructureUpdate = createAction(REPORT_STRUCTURE_UPDATE.ACTION)
export const saveReport = createAction(REPORT_SAVE.ACTION)

export const updateNotes = createAction(UPDATE_NOTES.ACTION)
export const updateNotesRequest = createAction(UPDATE_NOTES.REQUEST)
export const updateNotesReceive = createAction(UPDATE_NOTES.RECEIVE)
export const updateNotesFail = createAction(UPDATE_NOTES.FAIL)
