import { get } from 'lodash'

export const mapDTO = formValues => ({
  marketingTimeMax: get(formValues, 'marketingTime.max'),
  marketingTimeMin: get(formValues, 'marketingTime.min'),
})

export const mapDataModel = formValues => ({
  marketingTimeMax: get(formValues, 'property_information.marketingTime.to'),
  marketingTimeMin: get(formValues, 'property_information.marketingTime.from'),
})
