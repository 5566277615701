import * as React from 'react'
import PropTypes from 'prop-types'
import { isEmpty, isArray } from 'lodash'
import { Dialog } from '@material-ui/core'
import { connect } from 'react-redux'

import { errorNotification } from '../../redux/actions/notifications'

import { SelectFile, UploadFile } from './Steps'

const STEPS = {
  SELECT: 'SELECT',
  UPLOAD: 'UPLOAD',
}

class FileSelectionModal extends React.Component {
  static propTypes = {
    onValueSelected: PropTypes.func.isRequired,
    handleClose: PropTypes.func.isRequired,
    fileType: PropTypes.string.isRequired,
    fileTypesRestriction: PropTypes.array,
    uploadEndpoint: PropTypes.string.isRequired,
    cancelEndpoint: PropTypes.string,
    parentId: PropTypes.string.isRequired,
    label: PropTypes.string,
    maxUploadSize: PropTypes.number,
    errorNotification: PropTypes.func.isRequired,
    template: PropTypes.string,
  }

  static defaultProps = {
    label: '',
    maxUploadSize: null,
  }

  state = {
    files: [],
    fileForUpload: null,
    step: STEPS.SELECT,
  }

  goToSelection = () => {
    this.setState({
      step: STEPS.SELECT,
    })
  }

  getFileId = (file, fileResponse) => {
    if (!fileResponse) {
      return file.id ? file.id : file._id
    } else {
      return fileResponse._id
    }
  }

  handleSelectFile = (selectedFile, fileResponse) => {
    const { onValueSelected } = this.props

    onValueSelected({
      id: this.getFileId(selectedFile, fileResponse),
      name: selectedFile.name,
      fileName: selectedFile.fileName,
    })
  }

  onFileUploaded = (selectedFile, fileResponse) => {
    const { files } = this.state
    if (isEmpty(selectedFile.name)) {
      selectedFile.name = fileResponse.name || selectedFile.fileName
    }
    this.setState({
      files: [{ ...selectedFile, id: this.getFileId(selectedFile, fileResponse) }, ...files],
    })
  }

  onDrop = draggedFiles => {
    const { maxUploadSize } = this.props
    if (isArray(draggedFiles) && !isEmpty(draggedFiles)) {
      const draggedFile = draggedFiles[0]

      if (!!maxUploadSize && draggedFile.size > maxUploadSize * 1024 * 1024) {
        this.props.errorNotification(
          `File upload limit is ${maxUploadSize} MB. To upload larger files, please optimize the PDF first.`
        )
        return
      }

      const fileForUpload = { id: null, fileName: draggedFile.name, name: '', uploadFileInfo: draggedFile }
      this.setState({
        fileForUpload,
        step: STEPS.UPLOAD,
      })
    }
  }

  getDialogContent = () => {
    const { label, fileTypesRestriction, fileType, uploadEndpoint, cancelEndpoint, parentId, template, handleClose } =
      this.props
    const { step, fileForUpload } = this.state
    switch (step) {
      case STEPS.SELECT:
        return (
          <SelectFile
            label={label}
            onDrop={this.onDrop}
            onSelect={this.handleSelectFile}
            fileTypesRestriction={fileTypesRestriction}
            onCancel={handleClose}
          />
        )
      case STEPS.UPLOAD:
        return (
          <UploadFile
            label={label}
            fileForUpload={fileForUpload}
            onSelect={this.handleSelectFile}
            onCancel={this.goToSelection}
            fileType={fileType}
            template={template}
            uploadEndpoint={uploadEndpoint}
            cancelEndpoint={cancelEndpoint}
            parentId={parentId}
            onUploaded={this.onFileUploaded}
          />
        )
      default:
        return (
          <SelectFile
            label={label}
            onDrop={this.onDrop}
            onSelect={this.handleSelectFile}
            fileTypesRestriction={fileTypesRestriction}
            onCancel={handleClose}
          />
        )
    }
  }

  render() {
    const { handleClose, label } = this.props
    const { step } = this.state
    return (
      <Dialog
        disableBackdropClick={step === STEPS.UPLOAD}
        open
        maxWidth="md"
        onClose={handleClose}
        aria-labelledby="simple-dialog-title"
        data-qa={`${label} modal`}
      >
        {this.getDialogContent()}
      </Dialog>
    )
  }
}

const mapDispatchToProps = dispatch => ({
  errorNotification: error => dispatch(errorNotification({ message: error })),
})

export default connect(null, mapDispatchToProps)(FileSelectionModal)
