import React from 'react'
import PropTypes from 'prop-types'

import ObjectID from 'bson-objectid'
import html2canvas from 'html2canvas'

import ConfirmDialog from './ConfirmDialog'

import { MAP_ID } from './constants'
import EditDialog from './EditDialog'
import Map from './Map'
import Sidebar from './Sidebar'

class MapMaker extends React.PureComponent {
  static propTypes = {
    onSave: PropTypes.func,
    initialProperties: PropTypes.arrayOf(PropTypes.object),
  }

  constructor(props) {
    super(props)
    const properties = props.initialProperties || []
    this.state = {
      properties,
      indexToDelete: -1,
      indexToEdit: -1,
    }
  }

  setIndexToDelete = propertyIndex => {
    this.setState({ indexToDelete: propertyIndex })
  }

  get deleteModal() {
    const { indexToDelete, properties } = this.state

    if (indexToDelete < 0) {
      return null
    }

    const removedProperty = properties[indexToDelete]

    const handleConfirm = confirmed => {
      if (confirmed) {
        this.handleDelete(indexToDelete)
      }
      this.setState({ indexToDelete: -1 })
    }

    return (
      <ConfirmDialog
        open={true}
        onConfirm={handleConfirm}
        title={`Delete ${removedProperty.streetAddress}?`}
        text={`${removedProperty.streetAddress} will no longer appear on map.`}
      />
    )
  }

  setIndexToEdit = propertyIndex => {
    this.setState({ indexToEdit: propertyIndex })
  }

  get editModal() {
    const { indexToEdit, properties } = this.state

    if (indexToEdit < 0) {
      return null
    }
    let isNew = false
    const selectedProperty = properties[indexToEdit]

    if (selectedProperty) {
      const { city, streetAddress, state, zip } = selectedProperty

      isNew = !(city || streetAddress || state || zip)
    } else {
      return null
    }

    const handleConfirm = (indexToEdit, editedProperty) => {
      this.handleEdit(indexToEdit, editedProperty)
      this.setIndexToEdit(-1)
    }

    return (
      <EditDialog
        open={true}
        onEdit={handleConfirm}
        onDelete={this.handleDelete}
        isNew={isNew}
        indexToEdit={indexToEdit}
        propertyToEdit={selectedProperty}
      />
    )
  }

  handleSelection = property => {
    const { properties } = this.state
    const transformedProperty = {
      streetAddress: property.address || '',
      city: property.city || '',
      state: property.state || '',
      country: property.country || '',
      zip: property.zip || '',
      coords: [property.location.lat, property.location.lng],
      id: new ObjectID().toString(),
      isSubject: false,
      compIndex: null,
    }
    const updatedProperties = [...properties, transformedProperty]
    this.updateCompIndex(updatedProperties)
  }

  handleReorder = (oldIndex, newIndex) => {
    const { properties } = this.state

    const updatedProperties = Array.from(properties)
    const [removed] = updatedProperties.splice(oldIndex, 1)
    updatedProperties.splice(newIndex, 0, removed)

    this.updateCompIndex(updatedProperties)
  }

  handleDelete = index => {
    const { properties } = this.state

    const updatedProperties = Array.from(properties)
    updatedProperties.splice(index, 1)

    this.updateCompIndex(updatedProperties)
  }

  handleEdit = (index, updatedProperty) => {
    const { properties } = this.state

    const updatedProperties = Array.from(properties)
    updatedProperties[index] = updatedProperty

    this.updateCompIndex(updatedProperties)
  }

  updateCompIndex = properties => {
    let compIndexCounter = 1
    const updatedProperties = properties.map((property, index) => {
      const newProperty = { ...property }

      if (!newProperty.isSubject) {
        newProperty.compIndex = compIndexCounter
        compIndexCounter++
      }

      return newProperty
    })
    this.setState({ properties: updatedProperties })
  }

  updateDocumentCloneStyles = documentClone => {
    const svgOriginals = document.getElementById(MAP_ID).querySelectorAll('svg.leaflet-zoom-animated')
    const svgClones = documentClone.getElementById(MAP_ID).querySelectorAll('svg.leaflet-zoom-animated')

    svgClones.forEach((clone, i) => {
      const styleAttribute = svgOriginals.item(i).getAttribute('style')
      const parentElement = clone.parentElement
      const svgWrapper = document.createElement('div')

      parentElement.removeChild(clone)
      svgWrapper.appendChild(clone)
      parentElement.appendChild(svgWrapper)

      svgWrapper.setAttribute('style', styleAttribute)
      clone.removeAttribute('style')
    })
  }

  captureImage = async () => {
    const { onSave } = this.props
    const { properties } = this.state

    // TODO: Migrate to html To Image since html2canvas causes hardly reproducible and unfixable bug.
    // https://bowery.atlassian.net/browse/WEB-5138
    const canvas = await html2canvas(document.getElementById(MAP_ID), {
      useCORS: true,
      removeContainer: true,
      logging: false,
      scale: 1,
      scrollX: 0,
      scrollY: 0,
      ignoreElements: element => element.classList.contains('leaflet-control') || element.id === 'custom-pin-button',
      onclone: this.updateDocumentCloneStyles,
      letterRendering: true,
    })

    if (onSave) {
      onSave({ canvas, properties })
      return
    }

    canvas.toBlob(blob => {
      const downloadUrl = URL.createObjectURL(blob)
      const aElement = document.createElement('a')
      aElement.href = downloadUrl
      aElement.download = 'map.png'
      document.body.appendChild(aElement)
      aElement.click()
      aElement.remove()
    }, 'image/png')
  }

  render() {
    const { onSave } = this.props
    const { indexToEdit, properties } = this.state

    return (
      <>
        {this.deleteModal}
        {this.editModal}
        <Sidebar
          properties={properties}
          indexToEdit={indexToEdit}
          onNewProperty={this.handleSelection}
          onReorder={this.handleReorder}
          onDelete={this.handleDelete}
          onEdit={this.handleEdit}
          onImageCapture={this.captureImage}
          setIndexToEdit={this.setIndexToEdit}
          setIndexToDelete={this.setIndexToDelete}
          downloadImage={!onSave}
        />
        <Map
          onNewProperty={property => {
            this.handleSelection(property)
            this.setIndexToEdit(properties.length)
          }}
          showAddCustomPinButton={true}
          properties={properties}
        />
      </>
    )
  }
}

export default MapMaker
