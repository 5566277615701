import React, { useEffect, useState } from 'react'
import { cloneDeepWith, get } from 'lodash'
import { makeStyles } from '@mui/styles'
import TextField from '@mui/material/TextField'
import Button from '@mui/material/Button'
import List from '@mui/material/List'
import Avatar from '@mui/material/Avatar'
import ListItem from '@mui/material/ListItem'
import ListItemAvatar from '@mui/material/ListItemAvatar'
import ListItemText from '@mui/material/ListItemText'
import GeriAvatar from 'client-shared/images/geri.png'

import * as Api from 'core/api'
import { HEADER_HEIGHT } from 'client-shared/layout/constants'
import { useSelector } from 'client-shared/hooks/redux'
import { buildSlateWriteup } from 'shared/utils/textGeneration/writeupBuilder'
import { isValueJSON } from 'shared/utils/narrative'
import { PROPERTY_INFO_KEY } from 'shared/constants/report/keysAndDataPaths'

const useStyles = makeStyles({
  root: {
    display: 'flex',
    flexDirection: 'column',
    height: `calc(100vh - ${HEADER_HEIGHT}px)`,
  },
  chatContainer: {
    flexGrow: 1,
    overflow: 'auto',
  },
  userMessage: {
    fontWeight: 'bold',
    whiteSpace: 'pre-wrap',
  },
  assistantMessage: {
    fontWeight: 'normal',
    whiteSpace: 'pre-wrap',
  },
  messageInput: {
    marginTop: 8,
    padding: 8,
    display: 'flex',
  },
  input: {
    flexGrow: 1,
  },
  sendButton: {
    marginLeft: 8,
  },
  managementButtons: {
    display: 'flex',
    justifyContent: 'space-around',
  },
})

type A2IChoice = {
  model: string
  content: string
  costInCents: number
}

type A2IResponse = {
  status: string
  messageId: string
  choices: Array<A2IChoice>
}

type ChatMessage = {
  text: string
  isUser: boolean
}

type Conversation = {
  conversationId: string
  messages: Array<ChatMessage>
}

const convertNarrative = (value: any) => {
  if (value && isValueJSON(value.narrative)) {
    return buildSlateWriteup(value).join(' ')
  }
}

const reportDataSelector = (state: any) => {
  const formPath = get(state, 'shared.location.form.formPath')
  const formValuesOnLoad = get(state, `report.reportData.${formPath}`)

  const basicData = formPath === PROPERTY_INFO_KEY ? {} : get(state, `report.reportData.propertyInformation`)

  return {
    ...cloneDeepWith(basicData, convertNarrative),
    ...cloneDeepWith(formValuesOnLoad, convertNarrative),
  }
}

const ChatInterface: React.FC = () => {
  const classes = useStyles()
  const [message, setMessage] = useState('')
  const [chatHistory, setChatHistory] = useState<ChatMessage[]>([])
  const [isLoading, setIsLoading] = useState(false)
  const reportId = useSelector(state => state.report.reportData._id)
  const [conversationId, setConversationId] = useState<string | undefined>()
  const chatContainerRef = React.useRef<HTMLDivElement>(null)
  const reportData = useSelector(reportDataSelector)

  useEffect(() => {
    const getConversation = async () => {
      try {
        setIsLoading(true)
        const conversation: Conversation = await Api.getConversation(reportId)
        if (conversation) {
          setConversationId(conversation.conversationId)
          setChatHistory(conversation.messages)
        }
      } catch (err) {
        console.error('Error getting initial conversation', err)
      }
      setIsLoading(false)
    }
    getConversation()
  }, [reportId])

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setMessage(event.target.value)
  }

  const scrollToBottom = () => {
    if (chatContainerRef.current) {
      chatContainerRef.current.scrollTop = chatContainerRef.current.scrollHeight
    }
  }

  const handleError = (err: any) => {
    setChatHistory(history => [
      ...history,
      {
        text: 'Geri lost its train of thought... Try again later.',
        isUser: false,
      },
    ])
    console.error(err)
    setIsLoading(false)
  }

  const pollStatus = async (messageId: string) => {
    try {
      const status = await Api.geriStatus(messageId)
      processResponse(status)
    } catch (err) {
      handleError(err)
    }
  }

  const processResponse = (response: A2IResponse) => {
    const status = get(response, 'status', '')

    if (status === 'Failed') {
      handleError(new Error('Status Failed'))
      return
    }

    const isStreaming = status === 'Streaming'
    const messageId = get(response, 'messageId', '')
    try {
      const copilotMessage = get(response, 'choices[0]', null)
      console.log('Handle recieved message', message, copilotMessage)
      if (!copilotMessage) {
        handleError(new Error('No Message Recieved'))
        return
      }

      if (!conversationId) {
        const convoId = copilotMessage?.conversationId
        if (convoId) {
          setConversationId(convoId)
        }
      }

      setChatHistory(history => {
        const newHistory = [...history]
        const last = newHistory.pop()!
        if (last?.isUser) {
          newHistory.push(last)
        }
        newHistory.push({ text: copilotMessage?.content, isUser: false })
        return newHistory
      })
    } catch (error) {
      handleError(error)
    }
    scrollToBottom()
    if (isStreaming && messageId) {
      setTimeout(
        (messageId: string) => {
          // ignore promise
          pollStatus(messageId)
        },
        1000,
        messageId
      )
    } else {
      setIsLoading(false)
    }
  }

  const sendMessage = async () => {
    if (message.trim() !== '') {
      setIsLoading(true)
      setChatHistory(history => [...history, { text: message, isUser: true }])
      setMessage('')
      const response = await Api.messageGeri(message, reportData, conversationId, reportId)
      processResponse(response)
    }
  }

  const saveConversation = async () => {
    try {
      await Api.saveConversation(chatHistory, reportId, conversationId)
    } catch (err) {
      console.error('Error saving conversation', err)
    }
  }

  const resetConversation = () => {
    setConversationId(undefined)
    setChatHistory([])
  }

  const handleKeyDown = (event: React.KeyboardEvent<HTMLDivElement>) => {
    if (event.key === 'Enter') {
      sendMessage()
    }
  }

  return (
    <div className={classes.root}>
      <div className={classes.chatContainer} ref={chatContainerRef}>
        <List>
          {chatHistory.map(msg => (
            <ListItem key={msg.text} className={msg.isUser ? classes.userMessage : classes.assistantMessage}>
              <ListItemAvatar>
                <Avatar src={msg.isUser ? '' : GeriAvatar} />
              </ListItemAvatar>
              <ListItemText primary={msg.text} disableTypography={true} />
            </ListItem>
          ))}
        </List>
      </div>
      <div className={classes.messageInput}>
        <TextField
          className={classes.input}
          label="Type a message"
          value={message}
          onChange={handleInputChange}
          onKeyDown={handleKeyDown}
        />
        <Button
          className={classes.sendButton}
          variant="contained"
          color="primary"
          onClick={sendMessage}
          disabled={isLoading}
        >
          {isLoading ? '...' : 'Send'}
        </Button>
      </div>
      <div className={classes.managementButtons}>
        <Button variant="outlined" onClick={saveConversation}>
          Save Conversation
        </Button>
        <Button variant="outlined" onClick={resetConversation}>
          Start New Conversation
        </Button>
      </div>
    </div>
  )
}

export default ChatInterface
