const ReportTabs = {
  ALL_REPORTS: 'ALL REPORTS',
  MY_REPORTS: 'MY REPORTS',
}
const REPORTS_DASHBOARD_MENU_WRAPPER_ID = 'REPORTS_DASHBOARD_MENU_WRAPPER_ID'

const DefaultLoadReportsIndexRange = {
  startIndex: 0,
  stopIndex: 200,
}

const REPORTS_FETCH_THROTTLE = 500

export { ReportTabs, REPORTS_DASHBOARD_MENU_WRAPPER_ID, DefaultLoadReportsIndexRange, REPORTS_FETCH_THROTTLE }
