import React from 'react'
import PropTypes from 'prop-types'
import { useField } from 'react-final-form'

import JobInput from './JobInput'
import { requiredJobNumber } from './utils'

const JobInputField = ({ endAdornment, ...otherProps }) => {
  const { input, meta } = useField('reportNumber', { validate: requiredJobNumber })

  return (
    <JobInput
      value={input.value}
      active={meta.active}
      onBlur={input.onBlur}
      onFocus={input.onFocus}
      onChange={input.onChange}
      error={!!meta.error && meta.touched}
      helperText={(meta.touched && meta.error) || ''}
      endAdornment={endAdornment}
      {...otherProps}
    />
  )
}

export default JobInputField

JobInputField.propTypes = {
  endAdornment: PropTypes.node,
}
