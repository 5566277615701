import React from 'react'
import { useField } from 'react-final-form'
import { Map, ZoomControl, TileLayer as BaseMap } from 'react-leaflet'
import { Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle } from '@material-ui/core'
import { Alert, Link } from '@mui/material'
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline'
import PropTypes from 'prop-types'

import { isNil } from 'lodash'

import { FEMA_FLOOD_MAP_CHANGES_VIEWER_LINK, FEMA_PULLED_ERROR_ALERT_TEXT } from '../../constants'
import VerifiedCallout from '../VerifiedCallout'

import { MAP_SIZE } from './constants'

import ConnectedIconLabel from './ConnectedIconLabel'
import LegendControl from './LeafletLegend'
import Layer from './MapLayer'
import { mapStyles } from './CartoStyles'

import wrapMap from './wrapMap'

const propTypesMaterial = {
  isZoomOn: PropTypes.bool.isRequired,
  isImageSaving: PropTypes.bool.isRequired,
  isModalOpen: PropTypes.bool.isRequired,

  captureMap: PropTypes.func.isRequired,
  closeMapWizard: PropTypes.func.isRequired,
  cartoClient: PropTypes.object.isRequired,

  classes: PropTypes.object.isRequired,
  label: PropTypes.string,
  id: PropTypes.string.isRequired,
}

const propTypesMapOptions = {
  mapOptions: PropTypes.shape({
    maxZoom: PropTypes.number.isRequired,
    initialZoom: PropTypes.number.isRequired,
    initialCoordinates: PropTypes.shape({
      lat: PropTypes.number.isRequired,
      lng: PropTypes.number.isRequired,
    }),
    zoomDelta: PropTypes.number.isRequired,
    zoomSnap: PropTypes.number.isRequired,
    wheelPxPerZoomLevel: PropTypes.number.isRequired,
    baseMap: PropTypes.string.isRequired,
    mapLayers: PropTypes.arrayOf(
      PropTypes.shape({
        name: PropTypes.string.isRequired,
        style: PropTypes.string.isRequired,
        isCustom: PropTypes.bool.isRequired,
      })
    ).isRequired,
    legendItems: PropTypes.arrayOf(
      PropTypes.shape({
        name: PropTypes.string.isRequired,
        value: PropTypes.string.isRequired,
      })
    ).isRequired,
    marker: PropTypes.shape({
      coords: PropTypes.arrayOf(PropTypes.number).isRequired,
      labelContent: PropTypes.shape({
        addressOne: PropTypes.string.isRequired,
        addressTwo: PropTypes.string.isRequired,
      }).isRequired,
    }).isRequired,
  }).isRequired,
}

class MaterialFloodMapWizard extends React.PureComponent {
  static propTypes = {
    ...propTypesMaterial,
    ...propTypesMapOptions,
  }

  static defaultProps = {
    zoomDelta: 0.3,
    zoomSnap: 0.1,
    wheelPxPerZoomLevel: 20,
  }

  render() {
    const {
      isZoomOn,
      isImageSaving,
      isModalOpen,

      captureMap,
      closeMapWizard,
      cartoClient,

      classes,
      label,
      zoomDelta,
      zoomSnap,
      wheelPxPerZoomLevel,
      id,
      mapOptions: {
        initialCoordinates,
        initialZoom,
        maxZoom,
        legendItems,
        baseMap,
        mapLayers,
        marker: { coords, labelContent },
      },
    } = this.props

    const hasLegend = !isNil(legendItems) && legendItems.length > 0
    return (
      <div>
        <Dialog maxWidth="md" open={isModalOpen} onClose={closeMapWizard} data-qa="flood-map">
          <DialogTitle>{label}</DialogTitle>
          <DialogContent>
            <Map
              id={id}
              className={id}
              style={MAP_SIZE}
              center={initialCoordinates}
              zoom={initialZoom}
              maxZoom={maxZoom}
              zoomControl={false}
              zoomDelta={zoomDelta}
              zoomSnap={zoomSnap}
              wheelPxPerZoomLevel={wheelPxPerZoomLevel}
            >
              <BaseMap attribution="" url={baseMap} />
              {isZoomOn && <ZoomControl />}

              <Layer source={mapLayers} style={mapStyles} client={cartoClient} />

              <ConnectedIconLabel
                position={coords}
                labelOffset={0.003}
                icon={this.props.getSubjectIcon(coords)}
                label={{
                  icon: this.props.getSubjectLabel(labelContent),
                  draggable: true,
                }}
              />

              {hasLegend && <LegendControl className="supportLegend" items={legendItems} />}
            </Map>
          </DialogContent>
          <DialogActions>
            <Button onClick={closeMapWizard} color="primary" data-qa="flood-map-close-btn">
              Close
            </Button>
            <div className={classes.wrapper}>
              <Button
                color="primary"
                disabled={isImageSaving}
                onClick={captureMap}
                data-qa="flood-map-capture-screen-btn"
              >
                Capture Screen
              </Button>
              {isImageSaving && <CircularProgress size={24} className={classes.buttonProgress} />}
            </div>
          </DialogActions>
        </Dialog>
      </div>
    )
  }
}

MaterialFloodMapWizard = wrapMap(MaterialFloodMapWizard)

export function FloodMapWizard({ name, label, mapOptions, ...otherProps }) {
  const floodMapField = useField('floodMap')
  const floodMapValue = floodMapField.input.value

  const imageVerifiedCallout = floodMapValue.isPulled &&
    !floodMapValue.isLoading &&
    !floodMapValue.isError &&
    !floodMapValue.isOverridden && (
      <VerifiedCallout sx={{ marginX: 3, marginY: 2, whiteSpace: 'pre-wrap' }}>
        {'This info was updated from National Flood Hazard Layer FIRMette.\nFor preliminary maps, click '}
        <Link href={FEMA_FLOOD_MAP_CHANGES_VIEWER_LINK} target="_blank">
          here
        </Link>
        &nbsp;to access the Flood Map Changes Viewer.
      </VerifiedCallout>
    )

  const fillerImageContent = (
    <>
      {floodMapValue.isLoading && <CircularProgress />}
      {floodMapValue.isError && !floodMapValue.isLoading && !floodMapValue.isOverridden && (
        <Alert
          severity="error"
          icon={<ErrorOutlineIcon color="error" />}
          sx={{ width: 'fit-content', display: 'inline-flex' }}
        >
          {FEMA_PULLED_ERROR_ALERT_TEXT}
        </Alert>
      )}
    </>
  )

  const handleChange = imageUrl => {
    floodMapField.input.onChange({
      ...floodMapValue,
      url: imageUrl,
      isOverridden: true,
    })
  }

  return (
    <MaterialFloodMapWizard
      id="floodMap.url"
      value={floodMapValue.url}
      label={label}
      mapOptions={mapOptions}
      imageVerifiedCallout={imageVerifiedCallout}
      fillerImageContent={fillerImageContent}
      handleChange={handleChange}
      onFocus={floodMapField.input.onFocus}
      onBlur={floodMapField.input.onBlur}
      {...otherProps}
    />
  )
}

FloodMapWizard.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string,
  ...propTypesMapOptions,
}
