import { invert } from 'lodash'

import { COMMERCIAL_UNIT_RENT_FIELD_NAMES } from '../../../../../../../../../shared/constants/report/incomeApproach/commercial'
import { getCapitalizedUseLabel } from '../../../../../../../../../shared/helpers/commercialUses'
import { createTextRenderer, defaultRenderer } from '../../../../../../../shared/components/_mui5/Table/helpers'
import { renderInt, renderCurrencyFloatWithDefault } from '../../../../../../../shared/components/_mui5/Table/renderers'
import { required } from '../../../../../../../shared/utils/validation'
import { LEASE_STATUS_OPTIONS } from '../../constants'

export const inPlaceColumns = [
  {
    name: '#',
    title: 'Unit',
    headerClassName: 'alignLeft',
    readOnly: true,
    readOnlyRenderer: createTextRenderer(row => row + 1),
  },
  {
    name: 'isInspected',
    title: 'Inspected',
    headerClassName: 'alignLeft',
    cellProps: {
      type: 'checkbox',
      className: 'alignCenter',
    },
  },
  {
    name: 'leaseStatus',
    title: 'Lease Status',
    headerClassName: 'alignLeft',
    cellProps: {
      type: 'bowery.select',
      source: LEASE_STATUS_OPTIONS.map(({ label }) => label),
      strict: true,
    },
    fieldConfig: {
      getValidator: () => required,
    },
  },
  {
    name: 'tenantName',
    title: 'Tenant',
    headerClassName: 'alignLeft',
    renderer: defaultRenderer,
    readOnlyWhenVacant: true,
    readOnlyRenderer: createTextRenderer(row => `Commercial Unit ${row + 1}`),
  },
  {
    name: 'use',
    title: 'Use',
    headerClassName: 'alignLeft',
    readOnly: true,
    readOnlyRenderer: createTextRenderer((row, col, prop, value) => getCapitalizedUseLabel(value)),
  },
  {
    name: 'leaseStartDate',
    title: 'Lease Start Date',
    headerClassName: 'alignLeft',
    readOnlyWhenVacant: true,
    readOnlyRenderer: createTextRenderer(row => '-'),
    cellProps: {
      type: 'bowery.date',
    },
  },
  {
    name: 'leaseExpiryDate',
    title: 'Lease Expiration Date',
    headerClassName: 'alignLeft',
    readOnlyWhenVacant: true,
    readOnlyRenderer: createTextRenderer(row => '-'),
    cellProps: {
      type: 'bowery.expirationDate',
    },
  },
  {
    name: 'squareFeet',
    title: 'SF',
    headerClassName: 'alignRight',
    readOnly: true,
    readOnlyRenderer: renderInt,
    cellProps: {
      type: 'numeric',
      className: 'alignRight',
      renderer: renderInt,
    },
  },
  {
    name: 'annualRent',
    title: 'Annual Rent',
    headerClassName: 'alignRight',
    readOnlyWhenVacant: true,
    readOnlyRenderer: createTextRenderer(row => '-'),
    cellProps: {
      type: 'numeric',
      className: 'alignRight',
      renderer: renderCurrencyFloatWithDefault(0),
    },
  },
  {
    name: 'monthlyRent',
    title: 'Monthly Rent',
    headerClassName: 'alignRight',
    readOnlyWhenVacant: true,
    readOnlyRenderer: createTextRenderer(row => '-'),
    cellProps: {
      type: 'numeric',
      className: 'alignRight',
      renderer: renderCurrencyFloatWithDefault(0),
    },
  },
  {
    name: 'annualRentPsf',
    title: 'Rent/SF',
    headerClassName: 'alignRight',
    readOnlyWhenVacant: true,
    readOnlyRenderer: createTextRenderer(row => '-'),
    cellProps: {
      type: 'numeric',
      className: 'alignRight',
      renderer: renderCurrencyFloatWithDefault(0),
    },
  },
  {
    name: 'monthlyRentPsf',
    title: 'Rent PSF/Month',
    headerClassName: 'alignRight',
    readOnlyWhenVacant: true,
    readOnlyRenderer: createTextRenderer(row => '-'),
    cellProps: {
      type: 'numeric',
      className: 'alignRight',
      renderer: renderCurrencyFloatWithDefault(0),
    },
  },
]
export const projectedColumns = [
  {
    name: '#',
    title: 'Unit',
    headerClassName: 'alignLeft',
    readOnly: true,
    readOnlyRenderer: createTextRenderer(row => row + 1),
  },
  {
    name: 'isInspected',
    title: 'Inspected',
    headerClassName: 'alignLeft',
    cellProps: {
      type: 'checkbox',
      className: 'alignCenter',
    },
  },
  {
    name: 'leaseStatus',
    title: 'Lease Status',
    headerClassName: 'alignLeft',
    cellProps: {
      type: 'bowery.select',
      source: LEASE_STATUS_OPTIONS.map(({ label }) => label),
      strict: true,
    },
    fieldConfig: {
      getValidator: () => required,
    },
  },
  {
    name: 'tenantName',
    title: 'Tenant',
    headerClassName: 'alignLeft',
    readOnly: true,
    readOnlyRenderer: createTextRenderer((row, col, prop, value) => value),
  },
  {
    name: 'use',
    title: 'Use',
    headerClassName: 'alignLeft',
    readOnly: true,
    readOnlyRenderer: createTextRenderer((row, col, prop, value) => getCapitalizedUseLabel(value)),
  },
  {
    name: 'leaseStartDate',
    title: 'Lease Start Date',
    headerClassName: 'alignLeft',
    readOnly: true,
    cellProps: {
      type: 'bowery.date',
    },
  },
  {
    name: 'leaseExpiryDate',
    title: 'Lease Expiration Date',
    headerClassName: 'alignLeft',
    readOnly: true,
    cellProps: {
      type: 'bowery.expirationDate',
    },
  },
  {
    name: 'squareFeet',
    title: 'SF',
    headerClassName: 'alignRight',
    readOnly: true,
    readOnlyRenderer: renderInt,
    cellProps: {
      type: 'numeric',
      className: 'alignRight',
    },
  },
  {
    name: 'annualRent',
    title: 'Annual Rent',
    headerClassName: 'alignRight',
    readOnly: true,
    readOnlyRenderer: renderCurrencyFloatWithDefault(0),
    cellProps: {
      type: 'numeric',
      className: 'alignRight',
    },
  },
  {
    name: 'monthlyRent',
    title: 'Monthly Rent',
    headerClassName: 'alignRight',
    readOnly: true,
    readOnlyRenderer: renderCurrencyFloatWithDefault(0),
    cellProps: {
      type: 'numeric',
      className: 'alignRight',
    },
  },
  {
    name: 'annualRentPsf',
    title: 'Rent/SF',
    headerClassName: 'alignRight',
    readOnlyRenderer: renderCurrencyFloatWithDefault(0),
    cellProps: {
      type: 'numeric',
      className: 'alignRight',
      renderer: renderCurrencyFloatWithDefault(0),
    },
  },
  {
    name: 'monthlyRentPsf',
    title: 'Rent PSF/Month',
    headerClassName: 'alignRight',
    readOnlyRenderer: renderCurrencyFloatWithDefault(0),
    cellProps: {
      type: 'numeric',
      className: 'alignRight',
      renderer: renderCurrencyFloatWithDefault(0),
    },
  },
]

export const RENT_FIELD_NAME_TO_RENT_BASIS = invert(COMMERCIAL_UNIT_RENT_FIELD_NAMES)
