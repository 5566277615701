import React from 'react'
import PropTypes from 'prop-types'
import { isEmpty, first, last, mean, sortBy } from 'lodash'

import { Grid, Typography } from '@material-ui/core'
import Icon from '@material-ui/core/Icon'
import { withStyles } from '@material-ui/core/styles'

import { ReactSVG } from 'react-svg'
import { divide } from 'shared/utils/numberOperations'
import { getSalePriceToUse } from 'shared/report-calculations/sales-approach/helpers'

import { median } from '../../../../shared/utils/math'
import { formatCurrencyFloat } from '../../../../shared/utils/numberFormatters'
import { UNIT_TYPE } from '../../../constants/salesComps'
import Tooltip from '../../../../shared/components/Tooltip'
import { FormInputLabel } from '../../../../shared/components'
import salesReferenceIconPath from '../../../images/sales_reference_icon.svg'

const iconSize = 42
const styles = theme => {
  return {
    root: {
      '& label': {
        'text-transform': 'uppercase',
      },
    },
    label: {
      fontSize: 10,
      margin: '4px 0',
      padding: 0,
    },
    value: {
      fontFamily: theme.typography.fontFamily,
      color: theme.palette.text.primary,
      fontSize: theme.typography.pxToRem(16),
      lineHeight: '1.1875em',
      minWidth: 112,
    },
    fillerIconContainer: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'left',
    },
    fillerIcon: {
      fontSize: 24,
      color: theme.palette.text.primary,
    },
    iconContainer: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'left',
      minWidth: 50,
    },
    icon: {
      width: iconSize,
      height: iconSize,
      fill: 'white',
    },
  }
}

const computeStatistics = (selectedComps, rentDenomination) => {
  const stats = {
    min: 0,
    max: 0,
    range: 0,
    average: 0,
    median: 0,
  }

  if (isEmpty(selectedComps)) {
    return stats
  }

  const prices = selectedComps.map(comp => {
    const { propertyInformation, saleInformation } = comp
    const { residentialUnits, commercialUnits, grossBuildingArea } = propertyInformation
    const totalUnitCount = residentialUnits + (commercialUnits || 0)
    const salePrice = getSalePriceToUse(saleInformation)
    return rentDenomination === UNIT_TYPE ? divide(salePrice, totalUnitCount) : divide(salePrice, grossBuildingArea)
  })

  const allPricesAreValid = prices.every(price => Number.isFinite(price))
  if (!allPricesAreValid) {
    return null
  }

  const sortedPrices = sortBy(prices)

  stats.min = first(sortedPrices)
  stats.max = last(sortedPrices)
  stats.range = stats.max - stats.min
  stats.average = mean(sortedPrices)
  stats.median = median(sortedPrices)

  return stats
}

class SelectedSalesCompsStatistics extends React.PureComponent {
  static propTypes = {
    selectedComps: PropTypes.arrayOf(PropTypes.object),
    rentDenomination: PropTypes.string,
  }

  render() {
    const { selectedComps, rentDenomination, classes } = this.props

    const fillerIcon = (
      <div className={classes.fillerIconContainer}>
        <Icon className={classes.fillerIcon}>remove</Icon>
      </div>
    )

    const stats = computeStatistics(selectedComps, rentDenomination)

    const statsMin = Number.isFinite(stats?.min) ? formatCurrencyFloat(stats.min) : fillerIcon
    const statsMax = Number.isFinite(stats?.max) ? formatCurrencyFloat(stats.max) : fillerIcon
    const statsRange = Number.isFinite(stats?.range) ? formatCurrencyFloat(stats.range) : fillerIcon
    const statsAverage = Number.isFinite(stats?.average) ? formatCurrencyFloat(stats.average) : fillerIcon
    const statsMedian = Number.isFinite(stats?.median) ? formatCurrencyFloat(stats.median) : fillerIcon

    return (
      <Grid container className={classes.root}>
        <Grid item lg={1}>
          <div className={classes.iconContainer}>
            <Tooltip placement="top" text="Selected Comparables Statistics">
              <ReactSVG
                src={salesReferenceIconPath}
                beforeInjection={svg => {
                  svg.classList.add(classes.icon)
                }}
              />
            </Tooltip>
          </div>
        </Grid>
        <Grid item lg={2}>
          <FormInputLabel className={classes.label} label="Min" />
          <Typography className={classes.value} data-qa="stats-min">
            {statsMin}
          </Typography>
        </Grid>
        <Grid item lg={2}>
          <FormInputLabel className={classes.label} label="Max" />
          <Typography className={classes.value} data-qa="stats-max">
            {statsMax}
          </Typography>
        </Grid>
        <Grid item lg={2}>
          <FormInputLabel className={classes.label} label="Range" />
          <Typography className={classes.value} data-qa="stats-range">
            {statsRange}
          </Typography>
        </Grid>
        <Grid item lg={2}>
          <FormInputLabel className={classes.label} label="Average" />
          <Typography className={classes.value} data-qa="stats-avg">
            {statsAverage}
          </Typography>
        </Grid>
        <Grid item lg={2}>
          <FormInputLabel className={classes.label} label="Median" />
          <Typography className={classes.value} data-qa="stats-median">
            {statsMedian}
          </Typography>
        </Grid>
      </Grid>
    )
  }
}

export default withStyles(styles)(SelectedSalesCompsStatistics)
