import React, { useState } from 'react'
import PropTypes from 'prop-types'

import { Stack, Typography, ToggleButtonGroup, ToggleButton } from '@mui/material'
import { grey } from '@mui/material/colors'
import { isFunction } from 'lodash'
import { Field } from 'react-final-form'

export const FinalButtonSwitch = ({ options, input, label, annotation, disabled }) => {
  const [value, setValue] = useState(input.value)

  const handleChange = (_event, newValue) => {
    if (newValue !== null) {
      setValue(newValue)
    }
  }

  return (
    <Stack spacing={0.5}>
      {label && (
        <Typography variant="helperText" sx={{ fontWeight: 700, color: grey[700] }}>
          {isFunction(label) ? label() : label}
        </Typography>
      )}
      <ToggleButtonGroup exclusive value={value} onChange={handleChange} onFocus={input.onFocus} onBlur={input.onBlur}>
        {options.map(option => (
          <ToggleButton
            data-qa={`${option.value}-btn`}
            value={option.value}
            disabled={disabled || option.disabled}
            onClick={() => input.onChange(option.value)}
          >
            {option.label}
          </ToggleButton>
        ))}
      </ToggleButtonGroup>
      {annotation && (
        <Typography
          variant="helperText"
          sx={{
            fontWeight: 700,
            color: grey[500],
          }}
        >
          {isFunction(annotation) ? annotation() : annotation}
        </Typography>
      )}
    </Stack>
  )
}

FinalButtonSwitch.propTypes = {
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
  annotation: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
  options: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.bool]).isRequired,
      label: PropTypes.string,
      disabled: PropTypes.bool,
    }).isRequired
  ).isRequired,
  disabled: PropTypes.bool,
  defaultValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.bool]),
}

const FinalButtonSwitchField = ({ name, onChange, ...props }) => {
  return <Field name={name} component={FinalButtonSwitch} onChange={onChange} {...props} />
}

export default FinalButtonSwitchField
