import { isEmpty } from 'lodash'

import BoweryDate from '@bowery-valuation/bowery-date'

import { shortDateFormat } from '../../../../shared/utils/dateHelper'

const getDueDateValue = (date, isReportSubmitted) => {
  const dueDate = date ? new BoweryDate(date) : null
  const currentDate = new BoweryDate()
  const daysDiff = dueDate ? currentDate.startOf('day').getDifferenceBetweenDates(dueDate.startOf('day'), 'day') : null

  let value = null
  let highlight = false

  if (isReportSubmitted) {
    return {
      value: shortDateFormat(dueDate),
      highlight,
    }
  }

  if (!daysDiff) {
    value = ''
  }

  if (daysDiff > 0) {
    if (daysDiff === 14) {
      value = '2 Weeks Late'
    }

    if (daysDiff > 7 && daysDiff < 14) {
      value = 'Over 1 Week Late'
    }

    if (daysDiff === 7) {
      value = '1 Week Late'
    }

    if (daysDiff > 14) {
      value = 'Over 2 Weeks Late'
    } else {
      value = `${daysDiff}${daysDiff === 1 ? ' Day' : ' Days'} Late`
    }

    highlight = true
  } else {
    if (dueDate.isSame(currentDate, 'week')) {
      value = dueDate.format('dddd') || ''
    } else {
      value = shortDateFormat(dueDate)
    }

    if (daysDiff === 0) {
      highlight = true
      value = 'Today'
    }

    if (daysDiff === -1) {
      value = 'Tomorrow'
    }
  }

  return {
    value,
    highlight,
  }
}

const getInspectionDateValue = date => {
  if (isEmpty(date)) {
    return ''
  }
  const currentDate = new BoweryDate()
  const inspectionDate = new BoweryDate(date)

  return inspectionDate.isSameOrAfter(currentDate) ? shortDateFormat(inspectionDate) : 'Completed'
}

export { getDueDateValue, getInspectionDateValue }
