import { GeneratedText, gtConditional, gtLocked, gtTemplate } from '../../../narrative/GeneratedText'

import { mapDTO, mapDataModel } from './mappings'

const generate = () => {
  const gt = new GeneratedText()

  const yearBuiltGt = gtLocked('yearBuilt')
  const effectiveAgeGt = gtLocked('effectiveAge')
  const totalEconomicLifeGt = gtLocked('totalEconomicLife')
  const pointInTimeQualifierGt = gtConditional(
    'valueAsComplete',
    gtTemplate` upon completion of the planned construction and renovations`
  )
  const remainingEconomicLifeGt = gtLocked('remainingEconomicLife')

  gt.t`While the improvements were originally constructed in ${yearBuiltGt}, we estimate the effective age to be
   ${effectiveAgeGt}${pointInTimeQualifierGt}; given a useful life of ${totalEconomicLifeGt},
   the remaining economic life of the building is estimated as ${remainingEconomicLifeGt}.`

  return gt.getParts()
}

export default { generate, mapDTO, mapDataModel }
