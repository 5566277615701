import React from 'react'
import { get } from 'lodash'
import PropTypes from 'prop-types'
import ObjectID from 'bson-objectid'
import { connect } from 'react-redux'

import { GEOGRAPHY_OPTIONS } from '../../../../shared/constants/properties'
import { Button } from '../../../../shared/components'

import { reportSettingsSelector } from './selectors'

const AddSpecialAssessmentButton = ({ form, path, reportSettings, displayUnconditionally }) => {
  const { locationIdentifier } = reportSettings
  const formPath = path ? `${path}.additionalSpecialAssessments` : 'additionalSpecialAssessments'

  const addSpecialAssessment = () => {
    const { values, change, focus } = form
    const additionalSpecialAssessments = get(values, formPath, [])

    const id = new ObjectID().toJSON()
    const updatedSpecialAssessments = [
      ...additionalSpecialAssessments,
      { label: 'Special Assessment Row', value: 0, _id: id, id },
    ]

    change(formPath, updatedSpecialAssessments)
    focus(formPath)
  }

  if (!displayUnconditionally && locationIdentifier === GEOGRAPHY_OPTIONS.NY) {
    return null
  }

  return (
    <Button variant="outlined" onClick={addSpecialAssessment} sx={{ boxShadow: 1 }}>
      ADD SPECIAL ASSESSMENT +
    </Button>
  )
}

AddSpecialAssessmentButton.propTypes = {
  path: PropTypes.string,
  form: PropTypes.object.isRequired,
  reportSettings: PropTypes.shape({
    locationIdentifier: PropTypes.string.isRequired,
  }).isRequired,
  displayUnconditionally: PropTypes.bool,
}

export default connect(state => ({ reportSettings: reportSettingsSelector(state) }))(AddSpecialAssessmentButton)
