import { GeneratedText, gtConditional, gtLocked, gtTemplate } from '../../../narrative/GeneratedText'

import { mapDTO, mapDataModel } from './mappings'

const generate = () => {
  const gt = new GeneratedText()

  const oneCompany = gtConditional('oneCompany')
  const manyCompanies = gtConditional('manyCompanies')

  oneCompany.children.push(
    ...gtTemplate`The Intended User is ${gtLocked('clientCompany', null, 'Client Organization')}. `
  )
  gt.addPart(oneCompany)

  manyCompanies.children.push(
    ...gtTemplate`The Intended Users are ${gtLocked('clientCompany', null, 'Client Organizations')}. `
  )
  gt.addPart(manyCompanies)

  gt.t`This appraisal is not intended for any other use or user. `
  gt.t`No party or parties other than the intended user may use or rely on the information, `
  gt.t`opinions and conclusions contained in this report.`

  return gt.getParts()
}

export default { generate, mapDTO, mapDataModel }
