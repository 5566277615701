import { handleActions } from 'redux-actions'

import { changeDrawerSection } from '../actions/drawer'

const initialState = {
  sectionName: undefined,
  isDrawerOpen: false,
}

export const reducer = handleActions(
  {
    [changeDrawerSection](state, { payload }) {
      const newSectionName = payload === state.sectionName ? undefined : payload
      const isDrawerOpen = newSectionName !== undefined

      return { ...state, sectionName: newSectionName, isDrawerOpen }
    },
  },
  initialState
)
