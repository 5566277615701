import React from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import { IconButton as Icon } from '@material-ui/core'
import classnames from 'classnames'

const styles = theme => ({
  roundButton: {
    margin: theme.spacing.unit / 2,
    padding: theme.spacing.unit / 2,
    width: 32,
    height: 32,
    color: 'white',
  },
  error: {
    backgroundColor: theme.palette.error[500],
    '&:hover': {
      backgroundColor: theme.palette.error[700],
    },
  },
  accent: {
    backgroundColor: theme.palette.accent[500],
    '&:hover': {
      backgroundColor: theme.palette.accent[700],
    },
  },
  primary: {
    backgroundColor: theme.palette.primary[900],
    '&:hover': {
      backgroundColor: theme.palette.primary[700],
    },
  },
})

const IconButtonComponent = ({ classes, children, color, className, iconClasses, ...props }) => {
  const colorClass = classes[color]
  return (
    <Icon classes={iconClasses} className={classnames([classes.roundButton, className, colorClass])} {...props}>
      {children}
    </Icon>
  )
}

const IconButtonWithStyles = withStyles(styles)(IconButtonComponent)

IconButtonWithStyles.displayName = 'IconButton'

IconButtonWithStyles.propTypes = {
  color: PropTypes.string,
  children: PropTypes.element.isRequired,
  className: PropTypes.string,
  iconClasses: PropTypes.object,
}

IconButtonWithStyles.defaultProps = {
  color: 'primary',
  iconClasses: {},
}

export default IconButtonWithStyles
