import React from 'react'
import PropTypes from 'prop-types'
import { filter, find, get } from 'lodash'
import arrayMutators from 'final-form-arrays'
import { Paper } from '@mui/material'

import {
  Grid,
  Typography,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  withStyles,
} from '@material-ui/core'

import { RESIDENTIAL_RENT_COMPS_PATH } from 'shared/constants/report/keysAndDataPaths'
import { DEFAULT_ALERT_MESSAGES } from 'shared/constants/automation/messages'
import { getAutomationFormattedDate, getAutomationFormattedTime } from 'shared/utils/formatters/dateHelper'
import rentCompsIntroduction from 'shared/utils/textGeneration/incomeApproach/residential/comparablesIntroduction'

import AutomationCTA from 'client-shared/components/AutomationCTA'
import { FeatureToggle } from '@bowery-valuation/feature-flagger-client'
import { ENABLE_AUTO_RESI_RENT_COMP_PICKER } from 'shared/constants/featureFlags'
import { mapCompPlexResidentialLeaseToRentComp } from 'shared/helpers/compplex'

import { CompTypes } from '../../../../../constants'
import { Button, Callout, CalloutButton, NarrativeComponent, SwitchButton } from '../../../../../../shared/components'
import { RentCompSearchTypes, CANCEL_LABEL } from '../constants'

import * as Api from '../../../../../api'

import wrapForm from '../../../../wrapForm'
import { changeReportCompType, saveReport } from '../../../../../redux/actions/report'

import PropertiesFilterPanel, { propertySearchFilterPanelDecorator } from './BuildingSearch/PropertySearchFilterPanel'
import ResidentialBuildingComps from './ResidentialBuildingComps'
import ResidentialUnitComps from './ResidentialUnitComps'
import AutomationDisabledMessage from './AutomationDisabledMessage'
import AutomationWarningMessage from './AutomationWarningMessage'

const DATA_PATH = RESIDENTIAL_RENT_COMPS_PATH
const GENERATED_TEXT_TOOLTIP =
  "The following generated text and appraiser commentary will appear in the Income Approach's Comparable Rentals section of your report."

const styles = theme => ({
  contentWrapper: {
    position: 'relative',
    '& $searchTypeWrapper': {
      padding: 0,
    },
  },
  searchTypeWrapper: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  rentComps: {
    padding: theme.spacing.unit / 2,
  },
  actionsContainer: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  dialogContent: {
    width: 450,
    padding: theme.spacing.unit * 2,
    paddingTop: theme.spacing.unit * 4,
  },
  dialogText: {
    color: theme.palette.text.primary,
  },
  dialogTitle: {
    color: theme.palette.primary[900],
  },
})

class ResidentialRentComps extends React.PureComponent {
  static propTypes = {
    onCompTypeChange: PropTypes.func.isRequired,
    subjectPropertyInfo: PropTypes.shape({
      residentialUnitCount: PropTypes.number,
      yearBuilt: PropTypes.number,
      coords: PropTypes.shape({
        latitude: PropTypes.number,
        longitude: PropTypes.number,
      }),
      unitGroups: PropTypes.array,
      city: PropTypes.string,
      state: PropTypes.string,
      dateOfValue: PropTypes.string,
    }).isRequired,
    classes: PropTypes.object.isRequired,
    form: PropTypes.object.isRequired,
    rentReconciliationUnitGroups: PropTypes.array.isRequired,
    reportId: PropTypes.string.isRequired,
    valueAsIs: PropTypes.bool.isRequired,
    hasVacantUnits: PropTypes.bool.isRequired,
  }

  state = {
    isWarningModalOpen: false,
    nextSearchType: '',
  }

  openWarningModal = type => {
    this.setState(prevState => ({
      isWarningModalOpen: !prevState.isWarningModalOpen,
      nextSearchType: type,
    }))
  }

  handleChange = () => {
    const { form, onCompTypeChange } = this.props
    this.setState(prevState => {
      form.change('compType', prevState.nextSearchType)
      onCompTypeChange(prevState.nextSearchType)
      return {
        isWarningModalOpen: false,
      }
    })
  }

  handleCancel = () => {
    this.setState({ isWarningModalOpen: false })
  }

  getAutoResiRentComps = async () => {
    const { subjectPropertyInfo, autoResiRentComps, rentReconciliationUnitGroups, form } = this.props
    const { unitCompGroups } = form.values
    const formDataPath = DATA_PATH.join('.')
    const updatedUnitCompsGroups = []

    for (const index in rentReconciliationUnitGroups) {
      const unitGroup = rentReconciliationUnitGroups[index]
      const unitGroupKey = get(unitGroup, 'unitGroupKey', null)
      let numberOfBedrooms = get(unitGroup, 'unitGroupBedroomNumber', 0)
      if (!numberOfBedrooms) {
        numberOfBedrooms = get(unitGroup, 'groupingParameters.bedrooms.value', 0)
      }
      const marketConclusion = get(unitGroup, 'marketConclusion', 0)

      subjectPropertyInfo.numberOfBedrooms = numberOfBedrooms
      subjectPropertyInfo.marketConclusion = marketConclusion

      const comps = await Api.getAutoResiRentComps(subjectPropertyInfo, autoResiRentComps)
      const updatedUnitCompGroup = find(unitCompGroups, unitCompGroup => unitCompGroup.unitGroupKey === unitGroupKey)
      const currentComps = updatedUnitCompGroup.units
      updatedUnitCompGroup.units = [
        ...currentComps,
        ...comps.map(comp => {
          const localComp = mapCompPlexResidentialLeaseToRentComp(comp)
          return {
            ...localComp,
            isAutoComp: true,
            score: comp.score,
          }
        }),
      ]
      updatedUnitCompsGroups.push(updatedUnitCompGroup)
    }
    form.change('unitCompGroups', updatedUnitCompsGroups)

    global.automationSaved = 'residentialRentCompsImport'
    this.props.saveReport(formDataPath)
  }

  render() {
    const { classes, form, rentReconciliationUnitGroups, reportId, subjectPropertyInfo, valueAsIs, hasVacantUnits } =
      this.props
    const { compType, automationMetadata } = form.values
    const { nextSearchType } = this.state
    const formattedDate = getAutomationFormattedDate(automationMetadata?.updatedAt)
    const formattedTime = getAutomationFormattedTime(automationMetadata?.updatedAt)

    const { residentialUnitCount, yearBuilt, coords, dateOfValue } = subjectPropertyInfo
    const missingSubjectInfo = !residentialUnitCount || !yearBuilt || !coords || !dateOfValue

    const disableAutomatedCompPicker = !!filter(rentReconciliationUnitGroups, unitGroup => !unitGroup.marketConclusion)
      ?.length
    const warningContent =
      'For vacant subject units always check to see if the unit is listed online. Your concluded market rent should not be higher than that list price.'

    return (
      <Grid container direction="column" className={classes.contentWrapper} spacing={8}>
        {automationMetadata && (
          <CalloutButton
            // eslint-disable-next-line max-len
            text={`We automated Rent Comps on this page from the In-Place Rent Roll on ${formattedDate} at ${formattedTime}.`}
          />
        )}
        <FeatureToggle featureFlag={ENABLE_AUTO_RESI_RENT_COMP_PICKER}>
          <Grid item>
            <AutomationCTA
              CTAMessage="Automate your selection of rent comparables through Bowery AI."
              successMessage={DEFAULT_ALERT_MESSAGES.SUCCESS}
              errorMessage={DEFAULT_ALERT_MESSAGES.ERROR}
              onAutomationRun={this.getAutoResiRentComps}
              disableCTA={disableAutomatedCompPicker}
              disabledMessage={
                <AutomationDisabledMessage
                  reportId={reportId}
                  rentReconciliationUnitGroups={rentReconciliationUnitGroups}
                />
              }
              showWarningsSeparateFromMainAlert={true}
              warningMessage={
                missingSubjectInfo && (
                  <AutomationWarningMessage reportId={reportId} subjectPropertyInfo={subjectPropertyInfo} />
                )
              }
            />
          </Grid>
        </FeatureToggle>
        <Callout content={warningContent} variant="warn" />
        <Grid item className={classes.searchTypeWrapper}>
          <SwitchButton value={compType} onChange={this.openWarningModal} items={RentCompSearchTypes} />
          <Dialog
            open={this.state.isWarningModalOpen}
            aria-labelledby="form-dialog-title"
            data-qa="search-switch-modal"
          >
            <div className={classes.dialogContent}>
              <DialogTitle id="form-dialog-title" disableTypography>
                <Typography className={classes.dialogTitle} variant="h6">
                  Are you sure you want to search {nextSearchType}?
                </Typography>
              </DialogTitle>
              <DialogContent>
                <DialogContentText className={classes.dialogText}>
                  Changing your comp type will cause you to lose all currently selected comparables.
                </DialogContentText>
              </DialogContent>
              <DialogActions classes={{ root: classes.actionsContainer }}>
                <Button onClick={this.handleCancel} color="primary" data-qa="search-cancel">
                  {CANCEL_LABEL}
                </Button>

                <Button
                  wide
                  variant="contained"
                  onClick={this.handleChange}
                  color="primary"
                  data-qa="switch-search-confirm"
                >
                  Search {nextSearchType}
                </Button>
              </DialogActions>
            </div>
          </Dialog>
        </Grid>
        {compType === CompTypes.BUILDING && (
          <Grid item>
            <PropertiesFilterPanel form={form} />
          </Grid>
        )}
        <Grid item>
          <div className={classes.rentComps}>
            {compType === CompTypes.BUILDING ? (
              <ResidentialBuildingComps history={this.props.history} form={form} />
            ) : (
              <ResidentialUnitComps form={form} />
            )}
          </div>
        </Grid>
        <Grid item>
          <Paper>
            <NarrativeComponent
              name="rentCompsIntroduction"
              title="Comparable Rentals Introduction"
              generatedText={rentCompsIntroduction.generate}
              data={rentCompsIntroduction.mapDTO({ valueAsIs, hasVacantUnits })}
              tooltipText={GENERATED_TEXT_TOOLTIP}
            />
          </Paper>
        </Grid>
      </Grid>
    )
  }
}

const formOption = {
  heading: 'Rent Comps',
  mutators: { ...arrayMutators },
  decorators: [propertySearchFilterPanelDecorator],
  registeredFields: ['selectedBuildingComps', 'removedBuildingComps', 'unitCompGroups'],
}

export default wrapForm(
  DATA_PATH,
  formOption,
  state => {
    const reportId = get(state, 'report.reportData._id')
    const valueAsStabilized = get(state, 'report.reportSettings.valueAsStabilized')
    const valueAsComplete = get(state, 'report.reportSettings.valueAsComplete')
    const coords = get(state, 'report.reportData.propertyInformation.coords')
    const propertySummary = get(state, 'report.reportData.propertyInformation.propertySummary')
    const rentReconciliationUnitGroups = get(
      state,
      'report.reportData.incomeApproach.residentialIncome.residentialRentReconciliation.rentReconciliationGroups',
      []
    )
    const { residentialUnitCount, yearBuilt, city } = propertySummary
    const subjectState = propertySummary.state
    const unitGroups = get(
      state,
      'report.reportData.incomeApproach.residentialIncome.residentialRentReconciliation.rentReconciliationGroups',
      []
    )
    const hasVacantUnits = get(
      state,
      'report.reportData.incomeApproach.residentialIncome.residentialProjectedRentRollSummary.buildingSummary.hasVacantUnits'
    )

    const autoResiRentComps = get(state, 'report.reportData.organizationSettings.autoResiRentComps')
    const dateOfValue = get(state, 'report.reportData.report.reportInformation.dateOfValuation')

    return {
      valueAsIs: !(valueAsStabilized || valueAsComplete),
      hasVacantUnits,
      rentReconciliationUnitGroups,
      autoResiRentComps,
      reportId,
      subjectPropertyInfo: {
        residentialUnitCount,
        yearBuilt,
        coords,
        unitGroups,
        city,
        state: subjectState,
        dateOfValue,
      },
    }
  },
  dispatch => ({
    onCompTypeChange: compType => {
      dispatch(changeReportCompType({ formDataPath: DATA_PATH, compType }))
    },
    saveReport: formPath => dispatch(saveReport(formPath)),
  })
)(withStyles(styles)(ResidentialRentComps))
