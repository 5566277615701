import React from 'react'
import PropTypes from 'prop-types'

import { get } from 'lodash'
import { Table, TableBody, TableCell, TableHead, TableRow } from '@mui/material'

import TableNumberField from 'client-shared/components/Table/TableNumberRightAligned'
import { TableHeadRow } from 'client-shared/components/TableHelpers'

import LabelWithTooltip from './LabelWithTooltip'

class ConstantsTable extends React.PureComponent {
  renderRow = (row, index) => {
    const { name, values, keyParser, valueProps } = this.props
    const prevKey = get(values, `[${index - 1}].key`)

    return (
      <TableRow key={index}>
        <TableCell>{keyParser(row.key, prevKey)}</TableCell>
        <TableCell>
          <TableNumberField decimalScale={2} name={`${name}[${index}].value`} right {...valueProps} />
        </TableCell>
      </TableRow>
    )
  }

  render() {
    const { label, labelTooltip, headerColumns, values } = this.props

    return (
      <React.Fragment>
        <LabelWithTooltip label={label} tooltipText={labelTooltip} />
        <Table
          size="small"
          sx={{
            border: '1px solid #e0e0e0',
            borderCollapse: 'inherit',
            borderRadius: '5px',
            overflow: 'hidden',
            width: 'auto',
          }}
        >
          <TableHead
            sx={{
              '& .MuiTableCell-head': {
                background: '#757575',
                color: '#FFFFFF',
                fontSize: 12,
                fontWeight: 500,
              },
            }}
          >
            <TableHeadRow columns={headerColumns} />
          </TableHead>
          <TableBody sx={{ '& .MuiTableCell-root': { border: 0, fontSize: 13 } }}>
            {values.map(this.renderRow)}
          </TableBody>
        </Table>
      </React.Fragment>
    )
  }
}

ConstantsTable.propTypes = {
  label: PropTypes.string.isRequired,
  labelTooltip: PropTypes.string.isRequired,
  headerColumns: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      title: PropTypes.string,
    })
  ).isRequired,
  keyParser: PropTypes.func,
  name: PropTypes.string.isRequired,
  valueProps: PropTypes.object,
  values: PropTypes.array.isRequired,
}

ConstantsTable.defaultProps = {
  keyParser: item => item,
  valueProps: {},
}

export default ConstantsTable
