const transform = 'translateY(-50%)'

const styles = theme => ({
  titleContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    marginBottom: 4,
    height: 36,
  },
  iconButton: {
    display: 'flex',
    padding: 0,
    marginLeft: 4,
    width: 36,
    height: 36,
  },
  calloutRoot: {
    padding: '8px 13px',
  },
  callout: {
    backgroundColor: theme.palette.primary[50],
  },
  calloutButton: {
    backgroundColor: theme.palette.primary.main,
    width: 96,
    height: 36,
    textAlign: 'center',
    borderRadius: 5,
  },
  calloutButtonText: {
    color: '#ffffff',
    margin: '8px 0',
  },

  reimbursementTable: {
    marginBottom: theme.spacing.unit * 2,
  },
  headerWithTooltip: {
    '& :last-child': {
      marginLeft: 2,
      verticalAlign: 'middle',
    },
  },
  enabledColumn: {
    width: 50,
    paddingLeft: 24,
    paddingRight: 24,
  },
  annualCell: {
    '&::before': {
      position: 'relative',
      right: '40%',
      content: '"="',
    },
  },
  baseYearCell: {
    minWidth: 100,
  },
  percentOfCurrentYearLiabilityCell: {
    position: 'relative',
    '&::before': {
      position: 'absolute',
      left: '-17%',
      top: '50%',
      transform,
      content: '"X"',
    },
  },
  annualLessAdminFeeCell: {
    '&::before': {
      position: 'relative',
      right: 60,
      content: '"="',
    },
  },
  adminFeePercentageCell: {
    position: 'relative',
    '&::before': {
      position: 'absolute',
      left: '-20%',
      top: '50%',
      transform,
      content: '"+"',
    },
  },
  percentOfGrossAppraisersForecastCell: {
    position: 'relative',
    '&::before': {
      position: 'absolute',
      left: '-30%',
      top: '50%',
      transform,
      content: '"X"',
    },
  },
  shareCell: {
    position: 'relative',
    '&::before': {
      position: 'absolute',
      left: '-9%',
      top: '50%',
      transform,
      content: '"X"',
    },
  },
})

export default styles
