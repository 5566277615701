import { DEFAULT_EXPENSES_KEYS, ELECTRICITY_FUEL_EXPENSE, UTILITIES_EXPENSE } from '..'

export const EXPENSE_FORECAST_ITEMS = [...Object.values(DEFAULT_EXPENSES_KEYS), UTILITIES_EXPENSE.key]

export const DEFAULT_EXPENSES = {
  realEstateTaxes: 'Real Estate Taxes',
  insurance: 'Insurance',
  electricity: 'Electricity',
  fuel: 'Fuel',
  waterAndSewer: 'Water & Sewer',
  repairsAndMaintenance: 'Repairs & Maintenance',
  payrollAndBenefits: 'Payroll & Benefits',
  generalAndAdministrative: 'General & Administrative',
  legalAndProfessionalFees: 'Legal & Professional Fees',
  miscellaneous: 'Miscellaneous',
  management: 'Management Fees',
  reserves: 'Replacement Reserves',
}

export const EXPENSE_IDS_MAP = {
  managementFees: DEFAULT_EXPENSES_KEYS.management,
  replacementReserves: DEFAULT_EXPENSES_KEYS.reserves,
  electricityAndFuel: ELECTRICITY_FUEL_EXPENSE.key,
}
