import React from 'react'
import PropTypes from 'prop-types'

import { camelCase } from 'lodash'
import { Box, Stack, Typography } from '@mui/material'
import BrokenImageOutlinedIcon from '@mui/icons-material/BrokenImageOutlined'

import { SIDE_BAR_ICONS } from '../../layout/constants'

const FormHeader = ({ children, icon, section, title, iconSize }) => {
  const headerIcon = React.useMemo(() => {
    if (icon) {
      return icon
    }

    if (section in SIDE_BAR_ICONS) {
      const SidebarIcon = SIDE_BAR_ICONS[section]
      return <SidebarIcon fontSize="inherit" style={{ verticalAlign: 'top' }} />
    }

    return <BrokenImageOutlinedIcon fontSize="inherit" style={{ verticalAlign: 'top' }} />
  }, [icon, section])

  return (
    <Stack alignItems="center" data-qa={camelCase(title)} direction="row" pt={1 / 4} pr={8}>
      <Stack alignItems="center" direction="row" mr="auto" padding={2} spacing={2}>
        <Box fontSize={iconSize}>{headerIcon}</Box>
        <Typography color="text.primary" sx={{ fontWeight: 500 }} variant="h5">
          {title}
        </Typography>
      </Stack>
      {children}
    </Stack>
  )
}

FormHeader.propTypes = {
  children: PropTypes.node,
  section: PropTypes.string,
  title: PropTypes.string,
  iconSize: PropTypes.number,
}

FormHeader.defaultProps = {
  children: null,
  section: '',
  title: '',
  iconSize: 32,
}

export default FormHeader
