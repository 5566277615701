import React from 'react'
import PropTypes from 'prop-types'

import { TableCell, TableRow } from '@mui/material'
import { UNIT_OF_COMPARISON_TYPES } from 'shared/constants/salesApproach'
import { getTotalUnitCount } from 'shared/report-calculations/helpers'
import { getSiteAreaSfAndAcres } from 'shared/report-calculations/sales-approach/helpers'
import { formatInt } from 'client-shared/utils/numberFormatters'
import { CompPropertyInformation } from 'shared/report-calculations/sales-approach/types'
import { formatMaxDecimalFloat } from 'shared/utils/formatters/numberFormatters'

type SalesApproachBasisRowProps = {
  unitOfComparison: string
  propertyInformation: CompPropertyInformation
  onConcludedCapRateRowClick: () => void
}

const SalesApproachBasisRow = ({
  unitOfComparison,
  propertyInformation,
  onConcludedCapRateRowClick,
}: SalesApproachBasisRowProps) => {
  const {
    grossBuildingArea,
    residentialUnits,
    commercialUnits,
    siteArea,
    siteAreaUnit,
    buildableUnits,
    buildableArea,
  } = propertyInformation
  const totalUnits = getTotalUnitCount(residentialUnits, commercialUnits)
  const { siteAreaSf, siteAreaAcres } = getSiteAreaSfAndAcres(siteArea || 0, siteAreaUnit || 'sf')
  const unitOfComparisonLabel = () => {
    switch (unitOfComparison) {
      case UNIT_OF_COMPARISON_TYPES.SF:
        return 'Gross Building Area'
      case UNIT_OF_COMPARISON_TYPES.RESIDENTIAL_UNITS:
      case UNIT_OF_COMPARISON_TYPES.TOTAL_UNITS:
        return '# of Units'
      case UNIT_OF_COMPARISON_TYPES.SITE_AREA_SF:
        return 'Site Area (SF)'
      case UNIT_OF_COMPARISON_TYPES.SITE_AREA_ACRES:
        return 'Site Area (Acres)'
      case UNIT_OF_COMPARISON_TYPES.APPROVED_BUILDABLE_UNITS:
        return 'Buildable Units'
      case UNIT_OF_COMPARISON_TYPES.MAX_BUILDABLE_AREA:
        return 'Max Buildable Area'
      default:
        return ''
    }
  }
  const unitOfComparisonAmount = () => {
    switch (unitOfComparison) {
      case UNIT_OF_COMPARISON_TYPES.SF:
        return `${formatMaxDecimalFloat(grossBuildingArea, 5)} SF`
      case UNIT_OF_COMPARISON_TYPES.RESIDENTIAL_UNITS:
        return `${formatInt(residentialUnits)} Residential Units`
      case UNIT_OF_COMPARISON_TYPES.TOTAL_UNITS:
        return `${formatInt(totalUnits)} Units`
      case UNIT_OF_COMPARISON_TYPES.SITE_AREA_SF:
        return `${formatMaxDecimalFloat(siteAreaSf, 5)} SF`
      case UNIT_OF_COMPARISON_TYPES.SITE_AREA_ACRES:
        return `${formatMaxDecimalFloat(siteAreaAcres, 5)} Acres`
      case UNIT_OF_COMPARISON_TYPES.APPROVED_BUILDABLE_UNITS:
        return `${formatInt(buildableUnits)} Buildable Units`
      case UNIT_OF_COMPARISON_TYPES.MAX_BUILDABLE_AREA:
        return `${formatMaxDecimalFloat(buildableArea, 5)} ${siteAreaUnit === 'sf' ? 'SF' : 'Acres'}`
      default:
        return ''
    }
  }
  return (
    <TableRow onClick={onConcludedCapRateRowClick}>
      <TableCell>{unitOfComparisonLabel()}</TableCell>
      <TableCell />
      <TableCell align="right" data-qa="basisForSFAnalysis-amount-cell">
        {unitOfComparisonAmount()}
      </TableCell>
      <TableCell />
      <TableCell />
    </TableRow>
  )
}

SalesApproachBasisRow.propTypes = {
  unitOfComparison: PropTypes.string.isRequired,
  propertyInformation: PropTypes.any,
  basisForSFAnalysis: PropTypes.any,
  onConcludedCapRateRowClick: PropTypes.any,
}

export default SalesApproachBasisRow
