import React from 'react'
import PropTypes from 'prop-types'

import MenuIcon from '@mui/icons-material/MoreHoriz'
import { Menu, IconButton } from '@material-ui/core'

import PropertySearchLinks from './PropertySearchLinks'

export default class MoreButton extends React.PureComponent {
  static propTypes = {
    taxLink: PropTypes.string,
    taxLinkText: PropTypes.string,
    fullAddress: PropTypes.string.isRequired,
    otherLinks: PropTypes.arrayOf(PropTypes.node),
  }

  static defaultProps = {
    otherLinks: [],
  }

  state = {
    anchorEl: null,
  }

  onClickButton = event => {
    event.stopPropagation()
    const { currentTarget } = event
    this.setState({
      anchorEl: currentTarget,
    })
  }

  onClickMenu = event => {
    event.stopPropagation()
    this.handleClose()
  }

  handleClose = () => {
    this.setState({ anchorEl: null })
  }

  render() {
    const { anchorEl } = this.state
    const { taxLink, taxLinkText, fullAddress, otherLinks } = this.props

    return (
      <React.Fragment>
        <IconButton
          aria-owns={anchorEl ? 'externalLinkMenu' : null}
          aria-haspopup="true"
          onClick={this.onClickButton}
          children={<MenuIcon />}
        />
        <Menu
          id="externalLinkMenu"
          anchorEl={anchorEl}
          open={!!anchorEl}
          onClose={this.handleClose}
          onClick={this.onClickMenu}
          transformOrigin={{ horizontal: 'right', vertical: 'center' }}
        >
          <PropertySearchLinks
            taxLink={taxLink}
            taxLinkText={taxLinkText}
            otherLinks={otherLinks}
            fullAddress={fullAddress}
          />
        </Menu>
      </React.Fragment>
    )
  }
}
