import arrayToSentence from 'array-to-sentence'
import { find, isNumber, upperFirst } from 'lodash'

import { toPercents } from 'shared/utils/numberOperations'
import {
  getAdjustmentGroup,
  getAdjustmentValue,
  hasAdjustments,
} from 'shared/helpers/salesApproach/salesCompAdjustments'
import { getLastWordSeparator } from 'shared/utils/sentenceBuilder'

export const getComps = (compAdjustments, selectedComps, category, group) => {
  const upwardComps = []
  const downwardComps = []
  const compsIndices = []

  const mapping = (adjustmentValue, compIndex) => {
    if (adjustmentValue > 0) {
      upwardComps.push({ index: compIndex, adjustmentValue: toPercents(adjustmentValue) })
    } else if (adjustmentValue < 0) {
      downwardComps.push({ index: compIndex, adjustmentValue: toPercents(adjustmentValue) })
    }
    if (adjustmentValue) {
      compsIndices.push({ index: compIndex, adjustmentValue: toPercents(adjustmentValue) })
    }
  }

  compAdjustments?.forEach(compAdjustment => {
    const { adjustments, compId } = compAdjustment
    const adjustmentValue = getAdjustmentValue(getAdjustmentGroup(adjustments, group), category)
    const foundComp = find(selectedComps, comp => comp._id.toString() === compId)
    const compIndex = foundComp?.index
    if (isNumber(adjustmentValue)) {
      mapping(adjustmentValue, compIndex)
    }
  })
  return {
    upwardComps,
    downwardComps,
    compsIndices,
  }
}

export const getCompsFromFormValues = (values, category, group) => {
  const { compAdjustments = [], selectedComps = [] } = values
  return getComps(compAdjustments, selectedComps, category, group)
}

export const getAdjustCompsIndices = adjustComps => adjustComps.map(adjustComp => adjustComp.index)

export const generateCompsIndicesSentence = (adjustComps, lastWordSeparator) => {
  const adjustCompsIndices = getAdjustCompsIndices(adjustComps)

  return arrayToSentence(adjustCompsIndices.sort(), lastWordSeparator)
}

export const generateAdjustmentSentence = (salesCompsAdjustments, adjustmentType, isOtherAdjustment) => {
  const isSingleAdjustedComp = salesCompsAdjustments.length === 1

  if (isSingleAdjustedComp) {
    const [salesCompAdjustment] = salesCompsAdjustments

    return `${isOtherAdjustment ? '' : 'and thus '}required a ${
      adjustmentType === 'downward'
        ? Math.abs(salesCompAdjustment.adjustmentValue)
        : salesCompAdjustment.adjustmentValue
    }% ${adjustmentType} adjustment.`
  }

  const salesAdjustmentArraySentence = salesCompsAdjustments.map(
    salesCompAdjustment =>
      `comparable ${salesCompAdjustment.index} received a ${salesCompAdjustment.adjustmentValue}% adjustment`
  )

  const salesAdjustmentSentence = upperFirst(arrayToSentence(salesAdjustmentArraySentence, getLastWordSeparator))

  if (isOtherAdjustment) {
    return `required ${adjustmentType} adjustments. ${salesAdjustmentSentence}.`
  }

  return `and thus required ${adjustmentType} adjustments. ${salesAdjustmentSentence}.`
}

export const generateUpwardAdjustmentSentence = (upwardSalesCompsAdjustments, isOtherAdjustment = false) => {
  return generateAdjustmentSentence(upwardSalesCompsAdjustments, 'upward', isOtherAdjustment)
}

export const generateDownwardAdjustmentSentence = (downwordSalesCompsAdjustments, isOtherAdjustment = false) => {
  return generateAdjustmentSentence(downwordSalesCompsAdjustments, 'downward', isOtherAdjustment)
}

export const mapDataModelForAdjustmentsNarrative = (formValues, fieldName, group) => {
  const { compAdjustments, selectedComps } = formValues
  const { upwardComps, downwardComps, compsIndices } = getCompsFromFormValues(
    { compAdjustments, selectedComps },
    fieldName,
    group
  )
  const shouldGenerateWriteup = hasAdjustments(compAdjustments, fieldName, group)

  return {
    hasUpwardAdjustments: upwardComps.length > 0,
    hasMultipleUpwardAdjustments: upwardComps.length > 1,
    hasDownwardAdjustments: downwardComps.length > 0,
    hasMultipleDownwardAdjustments: downwardComps.length > 1,
    downwardCompsSentence: generateCompsIndicesSentence(downwardComps, getLastWordSeparator),
    upwardCompsSentence: generateCompsIndicesSentence(upwardComps, getLastWordSeparator),
    hasMultipleCompAdjustments: compsIndices.length > 1,
    shouldGenerateWriteup,
  }
}
