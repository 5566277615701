import { filterOutVacantUnitsWithZeroRent, getBuildingRentSummary } from 'shared/helpers/incomeApproach/residential'

export const getTotalBedroomCount = rentRollUnits => {
  return rentRollUnits.reduce((accum, unit) => accum + (unit.bedrooms || 0), 0)
}
export const calcTotalEstimatedAverageSF = unitGroupSummary => {
  return unitGroupSummary.reduce((accum, { units, estimatedAverageSF }) => {
    if (estimatedAverageSF) {
      accum += units.length * estimatedAverageSF
    }
    return accum
  }, 0)
}
export const calcTotalLeasableSF = rentRollUnits => {
  return rentRollUnits.reduce((accum, unit) => accum + (unit.squareFootage || 0), 0)
}

/** @deprecated Prefer importing this from the shared folder */
export { filterOutVacantUnitsWithZeroRent, getBuildingRentSummary }
