import React from 'react'
import PropTypes from 'prop-types'

import { Grid, Typography } from '@mui/material'

import { DatePicker } from 'client-shared/components/DatePicker'
import { formatInt } from 'client-shared/utils/numberFormatters'
import { fromPercents, toPercents } from 'client-shared/utils/numberOperations'

import ConstantsTable from './ConstantsTable'
import OutlinedConstantsTable from './OutlinedConstantsTable'
import {
  ARCHITECTS_FEES_COLUMNS,
  COST_MULTIPLIERS_COLUMNS,
  FOUNDATIONS_FACTOR_COLUMNS,
  SPRINKLERS_COLUMNS,
} from './constants'

const architectsFeesKeyParser = (currentKey, previousKey) => {
  if (currentKey === 'more') {
    const parsedPreviousKey = architectsFeesKeyParser(previousKey)
    return `${parsedPreviousKey}+`
  }

  return `$${formatInt(currentKey)}`
}

const architectsFeesValueProps = {
  adornment: '%',
  format: toPercents,
  normalize: fromPercents,
}

const ConstantsTab = ({ sprinklers, architectsFees }) => (
  <Grid container spacing={2}>
    <Grid item xs={12}>
      <Typography variant="h6">Marshall & Swift Values</Typography>
    </Grid>
    <Grid item xs={12}>
      <Typography variant="body2">Insurable Values as of Date</Typography>
      <DatePicker
        fullWidth={false}
        helperText="Values should be reconciled monthly from Marshall & Swift"
        name="valuesDate"
      />
    </Grid>
    <Grid item xs={12}>
      <ConstantsTable
        headerColumns={COST_MULTIPLIERS_COLUMNS}
        label="Cost Multipliers"
        labelTooltip="Latest values can be found in Section 11 of Marshall & Swift"
        name="costMultipliers"
      />
    </Grid>
    <Grid item xs={12}>
      <ConstantsTable
        headerColumns={FOUNDATIONS_FACTOR_COLUMNS}
        label="Foundations Factor"
        labelTooltip="Latest values can be found in Section 11 of Marshall & Swift"
        name="foundationsFactor"
      />
    </Grid>
    <Grid item xs={6}>
      <OutlinedConstantsTable
        headerColumns={SPRINKLERS_COLUMNS}
        keyParser={formatInt}
        label="Sprinklers (Wet System)"
        labelTooltip="Latest values can be found in Section 16, Page 25 of Marshall & Swift"
        name="sprinklers"
        values={sprinklers}
      />
    </Grid>
    <Grid item xs={6}>
      <OutlinedConstantsTable
        headerColumns={ARCHITECTS_FEES_COLUMNS}
        keyParser={architectsFeesKeyParser}
        label="Architect's Fees"
        labelTooltip="Latest values can be found in Section 99, Page 2, Table IV of Marshall & Swift"
        name="architectsFees"
        valueProps={architectsFeesValueProps}
        values={architectsFees}
      />
    </Grid>
  </Grid>
)

ConstantsTab.propTypes = {
  architectsFees: PropTypes.object.isRequired,
  sprinklers: PropTypes.object.isRequired,
}

export default ConstantsTab
