import React, { FC, memo, useMemo } from 'react'

import { get, noop } from 'lodash'
import { ColumnDataTypeEnum, RowBasedTable } from '@bowery-valuation/ui-components'

import { formatCurrencyFloat, formatCurrencyInt } from 'client-shared/utils/numberFormatters'

import { CELL_EDITOR_SELECTORS } from 'report/forms/income/TaxInformation/constants'

import { divide } from 'shared/utils/numberOperations'
import { getCustomColumnConfig, RowBasedTableColumn } from 'client-shared/utils/rowBasedTable'
import { DepreciationType } from 'shared/types/costApproachTypes'

type TotalDepreciationTableOwnProps = {
  depreciation: DepreciationType
  grossBuildingArea: number
  mvsTotalSiteImprovementsCosts: number
  totalMvsConclusionValue: number
}

const TotalDepreciationTable: FC<TotalDepreciationTableOwnProps> = ({
  depreciation,
  grossBuildingArea,
  mvsTotalSiteImprovementsCosts,
  totalMvsConclusionValue,
}) => {
  const replacementCostDepreciationPercentage = get(
    depreciation,
    'replacementCostDepreciations[0].totalDepreciation',
    0
  )
  const siteCostDepreciationPercentage = get(depreciation, 'siteImprovementDepreciation.totalDepreciation', 0)

  const replacementCostDepreciation = replacementCostDepreciationPercentage * totalMvsConclusionValue
  const totalDepreciatedReplacementCost = totalMvsConclusionValue - replacementCostDepreciation

  const siteCostDepreciation = siteCostDepreciationPercentage * mvsTotalSiteImprovementsCosts
  const totalDepreciatedSiteCost = mvsTotalSiteImprovementsCosts - siteCostDepreciation

  const getColumns = (): RowBasedTableColumn[] => {
    return [
      {
        name: 'label',
        label: '',
        type: ColumnDataTypeEnum.text,
        permanent: true,
        align: 'left',
        editable: false,
        suppressMovable: true,
      },
      {
        name: 'total',
        label: 'Total',
        type: ColumnDataTypeEnum.text,
        permanent: true,
        align: 'right',
        editable: false,
        cellEditorSelector: () => {
          return CELL_EDITOR_SELECTORS.moneyNoDecimals
        },
        suppressMovable: true,
      },
      {
        name: 'totalPerSf',
        label: 'Per SF',
        type: ColumnDataTypeEnum.text,
        permanent: true,
        align: 'right',
        editable: false,
        cellEditorSelector: () => {
          return CELL_EDITOR_SELECTORS.moneyTwoDecimals
        },
        suppressMovable: true,
      },
    ]
  }
  const rows = [
    {
      readOnly: true,
      suppressMovable: true,
      permanent: true,
      type: ColumnDataTypeEnum.text,
      id: 'depreciatedReplacementCost',
      label: 'Depreciated Cost of Building(s)',
      total: formatCurrencyInt(totalDepreciatedReplacementCost),
      totalPerSf: formatCurrencyFloat(divide(totalDepreciatedReplacementCost, grossBuildingArea)),
      rowDef: { summary: false, hideAction: true },
    },
    {
      readOnly: true,
      suppressMovable: true,
      permanent: true,
      type: ColumnDataTypeEnum.text,
      id: 'depreciatedSiteCost',
      label: 'Depreciated Cost of Site Improvements',
      total: formatCurrencyInt(totalDepreciatedSiteCost),
      totalPerSf: null,
      rowDef: { summary: false, hideAction: true },
    },
    {
      readOnly: true,
      suppressMovable: true,
      permanent: true,
      type: ColumnDataTypeEnum.text,
      id: 'totalDeprecation',
      label: 'Total Depreciated Costs',
      total: formatCurrencyFloat(totalDepreciatedReplacementCost + totalDepreciatedSiteCost),
      totalPerSf: null,
      rowDef: { summary: true, hideAction: true },
    },
  ]
  const columns = useMemo(() => getColumns(), [])

  return (
    <RowBasedTable
      id="total-depreciation-table"
      columns={columns}
      rows={rows}
      onRowUpdate={noop}
      onManyRowsUpdate={noop}
      getCustomColumnConfig={getCustomColumnConfig}
      actionCellHidden
      hideIndexColumn
      onColumnDragEnd={noop}
      onRowsDragEnd={noop}
      onColumnDelete={noop}
      onColumnUpdate={noop}
      onRowDelete={noop}
    />
  )
}

export default memo(TotalDepreciationTable)
