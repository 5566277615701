import React from 'react'
import PropTypes from 'prop-types'
import { get } from 'lodash'
import { Grid, Paper, withStyles, Typography } from '@material-ui/core'

import { arrayToKeyValuePairs } from 'client-shared/utils/ui/checkboxHelper'
import { Text, DropDown, CheckboxWithLabel } from 'client-shared/components'
import NarrativeComponent from 'client-shared/components/NarrativeComponent'
import AutomationStatus from 'client-shared/components/AutomationStatus'

import gasMetersDiscussion from 'shared/utils/textGeneration/property/gasMetersDiscussion'
import { LOCATIONS, METERS_TYPES, OTHER_TYPE } from 'shared/constants/report/utilities'

import { METERS_TOOLTIP } from './constants'

const styles = theme => ({
  paper: theme.paper,
  checkboxRoot: {
    height: 48,
  },
  checkBoxLabel: {
    marginTop: 0,
  },
})

class GasMeters extends React.PureComponent {
  static propTypes = {
    form: PropTypes.object,
  }

  get utilityTypes() {
    return arrayToKeyValuePairs(METERS_TYPES)
  }

  get locationTypes() {
    return arrayToKeyValuePairs(LOCATIONS)
  }

  render() {
    const { classes, form, automatedInspectionPrefillFlag } = this.props
    const { automationMetadata } = form.values
    const gasMeters = get(form.values, 'gasMeters')
    const { inspected, location } = gasMeters

    return (
      <Paper className={classes.paper}>
        <Grid container spacing={8}>
          <Grid item xs={12}>
            <Typography variant="h6">Gas Meters</Typography>
          </Grid>
          <Grid item xs={12}>
            {automatedInspectionPrefillFlag && automationMetadata && (
              <AutomationStatus
                formPaths={['automationMetadata']}
                message="We automated the information below from Mobile Inspection app"
              />
            )}
          </Grid>
          <Grid item xs={12}>
            <CheckboxWithLabel name="gasMeters.inspected" label="There are gas meters" />
          </Grid>
          {inspected && (
            <React.Fragment>
              <Grid item xs={6}>
                <DropDown name="gasMeters.type" label="Select Gas Meters type" items={this.utilityTypes} />
              </Grid>
              <Grid item xs={6}>
                <DropDown name="gasMeters.location" label="Select Gas Meters location" items={this.locationTypes} />
              </Grid>
              {location === OTHER_TYPE && (
                <React.Fragment>
                  <Grid item xs={6} />
                  <Grid item xs={6}>
                    <Text name="gasMeters.otherLocation" label="Gas Meters Location" />
                  </Grid>
                </React.Fragment>
              )}
            </React.Fragment>
          )}
          <Grid item xs={12}>
            <NarrativeComponent
              title="Gas Meters Discussion"
              generatedText={gasMetersDiscussion.generate}
              data={gasMetersDiscussion.mapDTO(form.values)}
              name="gasMetersDiscussion"
              tooltipText={METERS_TOOLTIP}
            />
          </Grid>
        </Grid>
      </Paper>
    )
  }
}

export default withStyles(styles)(GasMeters)
