import React from 'react'
import PropTypes from 'prop-types'

import { Number, DropDown, CheckboxWithLabel } from '../../../../shared/components'
import { SITE_AREA_OPTIONS } from '../../../constants'

const SiteArea = ({ displayInAcresAndSF, classes }) => {
  return (
    <>
      <Number
        name="siteArea"
        label="Site Area"
        margin="normal"
        decimalScale={5}
        thousandSeparator
        adornmentPosition="end"
        adornment={
          <DropDown
            name="siteAreaMeasure"
            items={SITE_AREA_OPTIONS}
            classes={{
              formControl: classes.dropDownFormControl,
              select: classes.dropDownSelect,
            }}
          />
        }
        classes={{ adornedEnd: classes.dropDownAdornedEnd }}
      />
      <CheckboxWithLabel name="displayInAcresAndSF" label="Display Site Area in both SF and Acres" />
      {displayInAcresAndSF && (
        <Number
          name="altSiteArea"
          label="Secondary Site Area"
          margin="normal"
          decimalScale={4}
          thousandSeparator
          adornmentPosition="end"
          adornment={
            <DropDown
              name="altSiteAreaMeasure"
              items={SITE_AREA_OPTIONS}
              classes={{ formControl: classes.dropDownFormControl, select: classes.dropDownSelect }}
              disabled
            />
          }
          disabled
          classes={{ adornedEnd: classes.dropDownAdornedEnd }}
        />
      )}
    </>
  )
}

SiteArea.propTypes = {
  displayInAcresAndSF: PropTypes.bool,
  classes: PropTypes.object.isRequired,
}

export default SiteArea
