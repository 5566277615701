import { get, isEqual } from 'lodash'

import MvsCalculations from 'shared/report-calculations/mvs/mvs-calculations'
import createDecorator from 'final-form-calculate'

const getUpdatedBuildingComponents = allValues => {
  const buildingComponents = get(allValues, 'buildingComponents', [])
  const customMultipliers = get(allValues, 'customMultipliers', [])
  const customRefinements = get(allValues, 'customRefinements', [])
  const customExclusions = get(allValues, 'customExclusions', [])

  const updatedBuildingComponents = buildingComponents?.map(buildingComponent => {
    const calculatedFields = MvsCalculations.calculateBuildingComponentTotals(
      buildingComponent,
      customMultipliers,
      customRefinements,
      customExclusions
    )
    return {
      ...buildingComponent,
      ...calculatedFields,
    }
  })

  return updatedBuildingComponents
}

const buildingComponentsDecorator = createDecorator({
  field: 'buildingComponents',
  updates: (value, name, allValues, prevValues) => {
    const prevBuildingComponents = get(prevValues, 'buildingComponents', [])
    const updatedBuildingComponents = getUpdatedBuildingComponents(allValues)
    if (!isEqual(prevBuildingComponents, updatedBuildingComponents)) {
      return {
        buildingComponents: [...updatedBuildingComponents],
      }
    }
    return {}
  },
})

const customRefinementsDecorator = createDecorator({
  field: 'customRefinements',
  updates: (value, name, allValues, prevValues) => {
    const updatedBuildingComponents = getUpdatedBuildingComponents(allValues)
    return {
      buildingComponents: [...updatedBuildingComponents],
    }
  },
})

const customMultipliersDecorator = createDecorator({
  field: 'customMultipliers',
  updates: (value, name, allValues, prevValues) => {
    const updatedBuildingComponents = getUpdatedBuildingComponents(allValues)
    return {
      buildingComponents: [...updatedBuildingComponents],
    }
  },
})

const customItemsDecorator = createDecorator({
  field: 'customExclusions',
  updates: (value, name, allValues, prevValues) => {
    const updatedBuildingComponents = getUpdatedBuildingComponents(allValues)
    return {
      buildingComponents: [...updatedBuildingComponents],
    }
  },
})

export const mvsDecorators = [
  buildingComponentsDecorator,
  customMultipliersDecorator,
  customRefinementsDecorator,
  customItemsDecorator,
]
