import { reducer as rentComps } from '../../forms/income/residential/ResidentialRentComps/redux/reducer'
import { reducer as commercialRentComps } from '../../forms/income/commercial/CommercialRentComps/redux/reducer'
import { reducer as expenseAddresses } from '../../forms/income/ComparableExpenses/redux/reducer'
import { reducer as expenseComps } from '../../forms/income/ComparableExpenses/ExpenseCompsModal/redux/reducer'
import { reducer as taxComps } from '../../forms/income/TaxInformation/TaxComps/redux/reducer'
import { reducer as exportReducer } from '../../forms/ReviewAndExport/redux/reducer'
import { reducer as zoningReducer } from '../../forms/property/Zoning/redux/reducer'

import { reducer as reportSettings } from '../../../reportsDashboard/components/CreateReportModal/ReportSettings/redux/reducer'

import { reducer as report } from './report'

import { reducer as bookmarks } from './bookmarks'

export default {
  report,
  rentComps,
  commercialRentComps,
  taxComps,
  export: exportReducer,
  expenseComps,
  expenseAddresses,
  bookmarks,
  zoning: zoningReducer,
  reportSettings,
}
