import React from 'react'
import PropTypes from 'prop-types'

import isEmpty from 'lodash/isEmpty'

import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'
import withProps from 'recompose/withProps'

const MessagesContainer = ({ children, hasMessages, icon, messagesType }) => {
  if (!hasMessages) {
    return null
  }
  return (
    <Stack>
      <Stack alignItems="center" direction="row" spacing={1}>
        <Typography variant="subtitle1">{messagesType}</Typography>
        {icon}
      </Stack>
      {children}
    </Stack>
  )
}

MessagesContainer.propTypes = {
  children: PropTypes.node.isRequired,
  hasMessages: PropTypes.bool,
  icon: PropTypes.element.isRequired,
  messagesType: PropTypes.string.isRequired,
}

MessagesContainer.defaultProps = {
  hasMessages: false,
}

export default withProps(({ messages }) => ({
  hasMessages: !isEmpty(messages),
}))(MessagesContainer)
