import React, { FC } from 'react'
import { Chip } from '@mui/material'

import { ATOMIC_UNIT_TYPES } from 'shared/constants/report/genericSubsection'

import { Subsection } from '../types'

type AtomicUnitBadgeProps = {
  subsection: Subsection
}
export const AtomicUnitBadge: FC<AtomicUnitBadgeProps> = ({ subsection }) => {
  const { atomicUnits } = subsection.data

  const foundTypes = atomicUnits.map(unit => unit.type)

  const labels = {
    [ATOMIC_UNIT_TYPES.NARRATIVE]: 'Paragraph',
    [ATOMIC_UNIT_TYPES.TABLE]: 'Table',
  }

  if (foundTypes.includes(ATOMIC_UNIT_TYPES.NARRATIVE) && foundTypes.includes(ATOMIC_UNIT_TYPES.TABLE)) {
    return (
      <>
        <Chip label={labels[ATOMIC_UNIT_TYPES.NARRATIVE]} />
        <Chip label={labels[ATOMIC_UNIT_TYPES.TABLE]} />
      </>
    )
  }

  const label = labels[foundTypes[0]] || 'Paragraph'

  return <Chip label={label} />
}
