import React from 'react'
import { isString } from 'lodash'
import PropTypes from 'prop-types'
import { Form } from 'react-final-form'
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  DialogContentText,
  withStyles,
} from '@material-ui/core'
import InfoOutlined from '@mui/icons-material/InfoOutlined'

import Text from '../../../../../../../shared/components/Text'
import Tooltip from '../../../../../../../shared/components/Tooltip'
import Loading from '../../../../../../../shared/components/Loading'

const styles = theme => ({
  formControl: {
    height: 'auto',
  },
  tooltipIcon: {
    fontSize: 18,
    color: theme.palette.secondary[800],
    marginLeft: 8,
    marginBottom: -2,
    letterSpacing: 0.2,
  },
})

const REPORT_ID_REGEXP = /\/(report)\/(\w{24})/
const REPORT_ID_INDEX = 2
const SLASH = '/'

const parseReportSource = source => {
  if (!isString(source)) {
    return ''
  }

  if (source.includes(SLASH)) {
    const matches = source.match(REPORT_ID_REGEXP)

    if (matches && matches[REPORT_ID_INDEX]) {
      return matches[REPORT_ID_INDEX]
    }

    return ''
  }

  return source
}

class CompsImportForm extends React.PureComponent {
  state = {
    isModalOpen: false,
  }

  openModal = () => {
    this.setState({ isModalOpen: true })
  }

  closeModal = () => {
    this.setState({ isModalOpen: false })
  }

  import = (values, form) => {
    const { reportId } = this.props
    const sourceReportId = parseReportSource(values.sourceReport)
    if (sourceReportId) {
      this.props.import(reportId, sourceReportId)
    }

    setTimeout(form.reset)
    this.closeModal()
  }

  render() {
    const { classes, tooltipText, tooltipPosition, dialogContent, isLoading } = this.props
    const { isModalOpen } = this.state
    const showModal = isModalOpen || isLoading

    return (
      <Form
        onSubmit={this.import}
        render={({ handleSubmit }) => (
          <React.Fragment>
            <Button variant="contained" color="primary" onClick={this.openModal} data-qa="import-btn">
              Import Comps
            </Button>
            {tooltipText && (
              <Tooltip text={tooltipText} placement={tooltipPosition}>
                <InfoOutlined className={classes.tooltipIcon} />
              </Tooltip>
            )}
            {showModal && (
              <Dialog
                open
                disableBackdropClick
                maxWidth="lg"
                onClose={this.closeModal}
                aria-labelledby="simple-dialog-title"
                data-qa="import-modal"
              >
                {isLoading && <Loading />}
                <DialogTitle id="simple-dialog-title">Enter Report Unique ID</DialogTitle>
                <DialogContent>
                  <DialogContentText id="alert-dialog-description">{dialogContent}</DialogContentText>
                  <Text
                    name="sourceReport"
                    placeholder="e.g. 5d7ffcfb3005a300143711e6"
                    classes={{ formControl: classes.formControl }}
                  />
                </DialogContent>
                <DialogActions>
                  <Button onClick={this.closeModal} color="default" data-qa="cancel-btn">
                    Close
                  </Button>
                  <Button type="submit" onClick={handleSubmit} variant="contained" color="primary" data-qa="import-btn">
                    Import
                  </Button>
                </DialogActions>
              </Dialog>
            )}
          </React.Fragment>
        )}
      />
    )
  }
}

CompsImportForm.propTypes = {
  import: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
  tooltipText: PropTypes.string,
  tooltipPosition: PropTypes.string,
  dialogContent: PropTypes.string,
}

CompsImportForm.defaultProps = {
  tooltipText: null,
  tooltipPosition: 'right',
  dialogContent: 'Input the ID of the report you would like to import comps from',
}

export default withStyles(styles)(CompsImportForm)
