/**
 * Add some 'variants' for MuiChip
 */

const variant = ({ ownerState: { ui }, theme: { palette } }) => ({
  ...(ui === 'indicator' && {
    backgroundColor: palette.warning.light,
    borderRadius: '4px',
    color: palette.text.secondary,
  }),
})

export default {
  styleOverrides: {
    root: [{ fontFamily: 'Nunito Sans' }, variant],
  },
}
