import React from 'react'
import PropTypes from 'prop-types'
import { get } from 'lodash'
import { connect } from 'react-redux'

import { Dialog, DialogActions, DialogContent, DialogTitle, Button, Typography } from '@material-ui/core'

import { yesConfirmation, noConfirmation } from '../../redux/actions/confirmation'

class Confirmation extends React.PureComponent {
  static propTypes = {
    show: PropTypes.bool.isRequired,
    title: PropTypes.string,
    message: PropTypes.string.isRequired,
    onYes: PropTypes.func.isRequired,
    onNo: PropTypes.func.isRequired,
  }

  static defaultProps = {
    title: 'Confirmation',
  }

  render() {
    const { show, title, message, onYes, onNo } = this.props

    return (
      <Dialog
        open={show}
        disableBackdropClick
        disableEscapeKeyDown
        maxWidth="xs"
        aria-labelledby="confirmation-dialog-title"
      >
        <DialogTitle id="confirmation-dialog-title">{title}</DialogTitle>
        <DialogContent>
          <Typography variant="body2">{message}</Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={onNo} color="primary">
            Cancel
          </Button>
          <Button onClick={onYes} color="primary">
            Ok
          </Button>
        </DialogActions>
      </Dialog>
    )
  }
}

class ConfirmationContainer extends React.PureComponent {
  static propTypes = {
    show: PropTypes.bool.isRequired,
    title: PropTypes.string,
    message: PropTypes.string.isRequired,
    yesConfirmation: PropTypes.func.isRequired,
    noConfirmation: PropTypes.func.isRequired,
  }

  render() {
    const { show, title, message, yesConfirmation, noConfirmation } = this.props

    return <Confirmation show={show} title={title} message={message} onYes={yesConfirmation} onNo={noConfirmation} />
  }
}

const mapStateToProps = state => ({
  show: get(state, 'shared.confirmation.show'),
  title: get(state, 'shared.confirmation.title'),
  message: get(state, 'shared.confirmation.message'),
})

const mapDispatchToProps = {
  yesConfirmation,
  noConfirmation,
}

export default connect(mapStateToProps, mapDispatchToProps)(ConfirmationContainer)
