import { GeneratedText, gtIfElse, gtLocked, gtTemplate } from '../../../../narrative/GeneratedText'

import { mapDTO, mapDataModel } from './mappings'

const generate = () => {
  const basisForSFAnalysisText = gtLocked('basisForSFAnalysisText')

  const basisForAnalysisSentence = gtIfElse(
    'isCommercialProperty',
    gtTemplate`The data, analyzed in terms of ${basisForSFAnalysisText}, is presented below.`,
    gtTemplate`The data, analyzed in terms of residential units and ${basisForSFAnalysisText}, is presented below.`
  )
  const expenseDataSourceSentence = gtIfElse(
    'expenseDataNotProvided',
    gtTemplate`We were not provided with historical expenses or an owner's pro forma. 
    Therefore, we analyzed expense reports of comparable properties in developing our forecast of operating expenses.`,
    gtTemplate`We were provided with the following summarized operating expense information for the subject property. 
    In developing our forecast of operating expenses, we considered the provided expense information as well as expense 
    reports of comparable properties. ${basisForAnalysisSentence}`
  )

  const gt = new GeneratedText()

  gt.t`${expenseDataSourceSentence}`
  return gt.getParts()
}

export default { generate, mapDTO, mapDataModel }
