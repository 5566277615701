import { COMMERCIAL_COMP_GROUPS_DISCUSSION_PATH } from 'shared/constants/report/keysAndDataPaths'

export const DATA_PATH = COMMERCIAL_COMP_GROUPS_DISCUSSION_PATH

export const Labels = {
  COMMERCIAL_COMP_GROUPS_DISCUSSION: 'Commercial Comp Groups Discussion',
  THERE_ARE_NO_COMMERCIAL_GROUPS: 'There are no commercial groups',
  THERE_ARE_NO_COMMERCIAL_UNITS: 'There are no commercial units',
  UNIT_NUMBER: 'Unit #',
  UNIT_ADDRESS: 'Unit address',
  TENANT_NAME: 'Tenant name',
  DATE_SIGNED: 'Date signed',
  SQUARE_FEET: 'Square feet',
  RENT_PER_SF: 'Rent/SF',
  RENT_PER_SF_PER_MONTH: 'Rent/SF/Month',
  USE: 'Use',
}

export const DELAY = 500
