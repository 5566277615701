import React from 'react'
import PropTypes from 'prop-types'
import { isNil, get } from 'lodash'
import { DialogContent, Typography, withStyles, Grid } from '@material-ui/core'

import { LABELS, GEOGRAPHY_OPTIONS, WIZARD_STEPS } from '../../../constants/properties'
import StepFooter from '../StepFooter'

import styles from './styles'
import ResultsTableNYC from './ResultsTableNYC'
import ResultsTableNJ from './ResultsTableNJ'

class SearchResults extends React.PureComponent {
  static propTypes = {
    GeographyTable: PropTypes.elementType,
    footer: PropTypes.element,
  }

  static GeographyTable = ({ locationIdentifier, ...props }) => {
    switch (locationIdentifier) {
      case GEOGRAPHY_OPTIONS.NY:
        return <ResultsTableNYC {...props} />
      case GEOGRAPHY_OPTIONS.NJ:
        return <ResultsTableNJ {...props} />
      default:
        return null
    }
  }

  renderNoResults = () => {
    const { classes } = this.props
    return (
      <div className={classes.notFoundContainer} data-qa="no-search-results">
        <Typography>No Results Found</Typography>
      </div>
    )
  }

  onSubmit = () => {
    const { onSubmit, properties, selectedPropertyIndex } = this.props
    onSubmit(properties[selectedPropertyIndex])
  }

  onAdditionalSubmit = () => {
    const { onSubmit, properties, selectedPropertyIndex } = this.props
    onSubmit({ ...properties[selectedPropertyIndex], additional: true })
  }

  openAdvancedSearch = () => {
    this.props.openPreviousStep({ step: WIZARD_STEPS.ADVANCED_SEARCH })
  }

  get nextStepLabel() {
    const { properties } = this.props

    return properties.length > 0 ? LABELS.SELECT_COMP : LABELS.ADVANCED_SEARCH
  }

  render() {
    const {
      properties,
      values,
      openPreviousStep,
      additional,
      onPropertySelect,
      selectedPropertyIndex,
      footer,
      GeographyTable,
    } = this.props
    const hasResults = !!properties.length
    const isDisabled = hasResults && isNil(selectedPropertyIndex)

    let additionalProps = {}

    if (hasResults && additional) {
      additionalProps = {
        ...additional,
        onClick: this.onAdditionalSubmit,
        isDisabled: !hasResults || isNil(selectedPropertyIndex),
      }
    }

    return (
      <DialogContent data-qa="search-result-form">
        <Grid container alignItems="center">
          <Grid item xs={12}>
            {hasResults ? (
              <GeographyTable
                locationIdentifier={get(values, 'search.locationIdentifier')}
                onPropertySelect={onPropertySelect}
                selectedPropertyIndex={selectedPropertyIndex}
                properties={properties}
              />
            ) : (
              this.renderNoResults()
            )}
          </Grid>
          {footer || (
            <StepFooter
              previous={{ text: LABELS.BACK_TO_SEARCH, onClick: openPreviousStep }}
              additional={additionalProps}
              next={{
                text: this.nextStepLabel,
                onClick: hasResults ? this.onSubmit : this.openAdvancedSearch,
                isDisabled,
              }}
            />
          )}
        </Grid>
      </DialogContent>
    )
  }
}

SearchResults.defaultProps = {
  GeographyTable: SearchResults.GeographyTable,
}

export default withStyles(styles)(SearchResults)
