import Inventory2OutlinedIcon from '@mui/icons-material/Inventory2Outlined'
import CloudUploadOutlinedIcon from '@mui/icons-material/CloudUploadOutlined'
import EditOutlinedIcon from '@mui/icons-material/EditOutlined'
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined'
import RepeatOutlinedIcon from '@mui/icons-material/RepeatOutlined'
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined'
import AutoAwesomeOutlinedIcon from '@mui/icons-material/AutoAwesomeOutlined'

import createIcon from './createIcon'

const Archive = createIcon(Inventory2OutlinedIcon)
const Cloud = createIcon(CloudUploadOutlinedIcon)
const Edit = createIcon(EditOutlinedIcon)
const Information = createIcon(InfoOutlinedIcon)
const QuickNav = createIcon(RepeatOutlinedIcon)
const Rotate = createIcon(RepeatOutlinedIcon)
const Trash = createIcon(DeleteOutlinedIcon)
const Sparkles = createIcon(AutoAwesomeOutlinedIcon)

export { Archive, Cloud, Edit, Information, QuickNav, Rotate, Sparkles, Trash }
