import React from 'react'
import PropTypes from 'prop-types'

import potentialGrossResidentialIncomeDiscussion from 'shared/utils/textGeneration/incomeApproach/residential/potentialGrossResidentialIncomeDiscussion/client'

import { NarrativeComponent } from '../../../../../../shared/components'

const TOOLTIP_TEXT =
  "The following text will appear in the Income Approach's Base Residential Income section of your report."

class PotentialGrossResidentialIncomeDiscussion extends React.PureComponent {
  static propTypes = {
    valueAsIs: PropTypes.bool.isRequired,
    hasVacantUnits: PropTypes.bool,
    percentageOfMarketRent: PropTypes.number.isRequired,
  }

  static defaultProps = {
    hasVacantUnits: false,
  }

  render() {
    const { valueAsIs, hasVacantUnits, percentageOfMarketRent } = this.props
    return (
      <NarrativeComponent
        name="potentialGrossResidentialIncomeDiscussion"
        title="Potential Gross Residential Income Discussion"
        generatedText={potentialGrossResidentialIncomeDiscussion.generate}
        data={potentialGrossResidentialIncomeDiscussion.mapDTO({ valueAsIs, hasVacantUnits, percentageOfMarketRent })}
        tooltipText={TOOLTIP_TEXT}
      />
    )
  }
}

export default PotentialGrossResidentialIncomeDiscussion
