import React from 'react'
import PropTypes from 'prop-types'

import { isNil } from 'lodash'

import { Box, Stack } from '@mui/material'
import Add from '@mui/icons-material/Add'

import { IconButton, DropDown } from 'client-shared/components'

import {
  SUBJECT_UNIT_GROUPING_LIST_OPTIONS,
  UNIT_MIX_GROUPING_TYPE_OPTIONS,
} from '../../../../report/forms/income/residential/ResidentialRentRoll/constants'

class NewUnitMixItemPanel extends React.PureComponent {
  addUnitMixItem = () => {
    const { unitGroupingType, resetGroupingParameters } = this.props
    const groupingParameters = this.getGroupingParameters()
    this.props.addUnitMixItem(unitGroupingType, groupingParameters)

    resetGroupingParameters()
  }

  getGroupingParameters = () => {
    const { unitGroupingType, bedrooms, bathrooms, outdoor, unitLayout } = this.props
    const groupingParameters = [
      {
        path: 'bedrooms',
        value: bedrooms,
      },
    ]

    switch (unitGroupingType) {
      case SUBJECT_UNIT_GROUPING_LIST_OPTIONS.bedroomAndBathroom.value:
        groupingParameters.push({
          path: 'bathrooms',
          value: bathrooms,
        })
        break
      case SUBJECT_UNIT_GROUPING_LIST_OPTIONS.bedroomAndOutdoorSpace.value:
        groupingParameters.push({
          path: 'outdoor',
          value: outdoor,
        })
        break
      case SUBJECT_UNIT_GROUPING_LIST_OPTIONS.bedroomAndUnitLayout.value:
        groupingParameters.push({
          path: 'unitLayout',
          value: unitLayout,
        })
        break
      default:
        break
    }

    return groupingParameters
  }

  isSelectionValid = () => {
    const groupingParameters = this.getGroupingParameters()
    return groupingParameters.every(parameter => !isNil(parameter.value))
  }

  render() {
    const { unitGroupingType } = this.props

    const disableAddButton = !this.isSelectionValid()

    return (
      <Stack alignItems="center" direction="row" spacing={2}>
        <Box sx={{ minWidth: 256 }}>
          <DropDown
            items={UNIT_MIX_GROUPING_TYPE_OPTIONS.bedroom}
            name="bedrooms"
            placeholder={SUBJECT_UNIT_GROUPING_LIST_OPTIONS.bedroom.label}
          />
          {unitGroupingType === SUBJECT_UNIT_GROUPING_LIST_OPTIONS.bedroomAndBathroom.value && (
            <DropDown
              items={UNIT_MIX_GROUPING_TYPE_OPTIONS.bedroomAndBathroom}
              name="bathrooms"
              placeholder={SUBJECT_UNIT_GROUPING_LIST_OPTIONS.bedroomAndBathroom.label}
            />
          )}
          {unitGroupingType === SUBJECT_UNIT_GROUPING_LIST_OPTIONS.bedroomAndOutdoorSpace.value && (
            <DropDown
              items={UNIT_MIX_GROUPING_TYPE_OPTIONS.bedroomAndOutdoorSpace}
              name="outdoor"
              placeholder={SUBJECT_UNIT_GROUPING_LIST_OPTIONS.bedroomAndOutdoorSpace.label}
            />
          )}
          {unitGroupingType === SUBJECT_UNIT_GROUPING_LIST_OPTIONS.bedroomAndUnitLayout.value && (
            <DropDown
              items={UNIT_MIX_GROUPING_TYPE_OPTIONS.bedroomAndUnitLayout}
              name="unitLayout"
              placeholder={SUBJECT_UNIT_GROUPING_LIST_OPTIONS.bedroomAndUnitLayout.label}
            />
          )}
        </Box>

        <IconButton disabled={disableAddButton} onClick={this.addUnitMixItem}>
          <Add />
        </IconButton>
      </Stack>
    )
  }
}

NewUnitMixItemPanel.propTypes = {
  addUnitMixItem: PropTypes.func,
  unitGroupingType: PropTypes.string,
}

export default NewUnitMixItemPanel
