import React from 'react'
import { pure } from 'recompose'

import { PropertiesSearchResultsListColumns } from '../../constants'

import SearchResultsCell from './SearchResultsCell'
import SearchResultsRow from './SearchResultsRow'

const PropertySearchResultListHeader = ({ width }) => {
  return (
    <div style={{ width }}>
      <SearchResultsRow isHeader>
        {PropertiesSearchResultsListColumns.map(({ title, name, width }) => {
          return (
            <SearchResultsCell key={name} width={width}>
              {title}
            </SearchResultsCell>
          )
        })}
      </SearchResultsRow>
    </div>
  )
}

export default pure(PropertySearchResultListHeader)
