const textElements = '& td, input, span, div, p, li'
const numberStyles = {
  '& div': {
    paddingLeft: 0,
    height: 'auto',
  },
  '& fieldset': {
    display: 'none',
  },
  height: 48,
  marginTop: 0,
}

const numberCell = {
  '& input': {
    paddingLeft: 1,
    paddingRight: 0,
    textAlign: 'center',
  },
  ...numberStyles,
  '& div:first-child': {
    'margin-top': 0,
  },
  textAlign: 'center',
}

export default theme => {
  const grey = theme.palette.secondary

  return {
    marginTop: {
      marginTop: theme.spacing.unit,
    },
    totalCell: theme.totalCell,
    totalCellLeading: { fontWeight: theme.totalCell.fontWeight, textAlign: 'left' },
    rentStabilizedTotalCell: { fontWeight: theme.totalCell.fontWeight, textAlign: 'center' },
    discussionContainer: {
      marginTop: 16,
    },
    paper: theme.paper,
    rentControlledTableContainer: {
      borderColor: grey[700],
      borderWidth: '1px',
      borderStyle: 'solid',
      overflow: 'hidden',
      border: `1px solid ${grey[300]}`,
      borderRadius: theme.spacing.unit / 2,
      [textElements]: {
        fontSize: '14px',
      },
      '& tfoot': {
        [textElements]: {
          fontWeight: 500,
        },
      },
    },
    rentControlledHeaderCellLeading: {
      backgroundColor: grey[700],
      color: theme.palette.common.white,
    },
    rentControlledHeaderCell: {
      backgroundColor: grey[700],
      color: theme.palette.common.white,
      textAlign: 'center',
    },
    numberCell,
    numberDisplayCell: {
      ...numberCell,
      color: grey[500],
      textAlign: 'center',
    },
    totalRow: {
      borderTop: `2px solid ${grey[700]}`,
      fontWeight: 'bold',
    },
    callout: {
      backgroundColor: theme.palette.primary[50],
      padding: '8px 13px',
      fontSize: '12px',
    },
  }
}
