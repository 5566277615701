import React from 'react'
import PropTypes from 'prop-types'

import { isNil } from 'lodash'

import { Paper, Table, TableBody, TableCell, TableFooter, TableHead, TableRow, Typography } from '@mui/material'
import { RENT_TYPES } from 'shared/constants/buildingComparables'
import { formatCurrencyFloat } from 'client-shared/utils/numberFormatters'

import { RENT_ROLL_OUTDOOR_SPACES } from '../../../../../report/constants'

import RentRollRow from './RentRollRow'
import RentRollToolbar from './RentRollToolbar'

export const isDisabledInclude = unit => {
  const disabled =
    !isNil(unit.bedroomCount) &&
    !isNil(unit.roomCount) &&
    !isNil(unit.rent) &&
    (!isNil(unit.sqft) || unit.isNotReportedSquareFeet)
  return disabled
}

class RentRollTableComponent extends React.PureComponent {
  constructor(props) {
    super(props)
    this.state = {
      isEditing: false,
      residentialUnitsCount: 0,
    }
  }

  componentWillReceiveProps = nextProps => {
    if (!this.state.isEditing && this.state.residentialUnitsCount !== nextProps.units.length) {
      this.setState({
        residentialUnitsCount: nextProps.units.length,
      })
    }
  }

  renderTableFooterRow = ({ label, value }) => {
    return (
      <TableRow>
        <TableCell colSpan="9" numeric>
          {label}
        </TableCell>
        <TableCell>
          <span>{formatCurrencyFloat(value)}</span>
        </TableCell>
      </TableRow>
    )
  }

  onResidentialUnitCountKeyPress = event => {
    this.setState({
      isEditing: true,
    })
    if (event.key === 'Enter') {
      const value = parseInt(event.target.value, 10) || 0

      this.props.onChangeUnitsCount(value)
      event.preventDefault()
    }
  }

  onFocusResidentialUnitsCount = () => {
    this.setState({
      isEditing: true,
    })
  }

  onBlurResidentialCount = () => {
    this.setState({
      isEditing: false,
      residentialUnitsCount: this.props.units.length,
    })
  }

  onResidentialUnitCountChange = event => {
    if (event.target.value < this.props.maxUnits) {
      this.setState({
        residentialUnitsCount: event.target.value,
      })
    }
  }

  render() {
    const {
      columns,
      csvFile,
      getUnitRentPerRoom,
      name,
      onAddUnit,
      onImportCsv,
      onRemoveUnit,
      rentTotalAnnual,
      rentTotalMonthly,
      units,
    } = this.props
    const { residentialUnitsCount } = this.state
    const tableRows = units.map((unit, index) => (
      <RentRollRow
        columns={columns}
        index={index}
        isDisabledInclude={isDisabledInclude(unit)}
        isNotReportedSquareFeet={unit.isNotReportedSquareFeet}
        key={index}
        name={name}
        onRemoveUnit={onRemoveUnit}
        rentPerRoom={getUnitRentPerRoom(unit)}
        units={units}
      />
    ))

    return (
      <Paper>
        <Typography variant="h6">Itemized Unit Info</Typography>
        <RentRollToolbar
          csvFile={csvFile}
          onAddUnit={onAddUnit}
          onBlurResidentialCount={this.onBlurResidentialCount}
          onFocusResidentialUnitsCount={this.onFocusResidentialUnitsCount}
          onImportCsv={onImportCsv}
          onRemoveUnit={onRemoveUnit}
          onResidentialUnitCountChange={this.onResidentialUnitCountChange}
          onResidentialUnitCountKeyPress={this.onResidentialUnitCountKeyPress}
          residentialUnitsCount={residentialUnitsCount}
          unitCount={units.length}
        />
        <Table size="small">
          <TableHead sx={{ '& .MuiTableCell-head': { fontSize: 12, fontWeight: 700 } }}>
            <TableRow>
              {columns.map(({ name: columnName, title: columnTitle }) => (
                <TableCell align="center" key={columnName}>
                  {columnTitle}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>{tableRows}</TableBody>
          <TableFooter>
            {this.renderTableFooterRow({
              label: 'Total Monthly Rent',
              value: rentTotalMonthly,
            })}
            {this.renderTableFooterRow({
              label: 'Total Annual Rent',
              value: rentTotalAnnual,
            })}
          </TableFooter>
        </Table>
      </Paper>
    )
  }
}

RentRollTableComponent.propTypes = {
  classes: PropTypes.object.isRequired,
  columns: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      title: PropTypes.string.isRequired,
    })
  ).isRequired,
  csvFile: PropTypes.string,
  getUnitRentPerRoom: PropTypes.func.isRequired,
  maxUnits: PropTypes.number,
  name: PropTypes.string.isRequired,
  onAddUnit: PropTypes.func.isRequired,
  onChangeUnitsCount: PropTypes.func.isRequired,
  onImportCsv: PropTypes.func.isRequired,
  onRemoveUnit: PropTypes.func.isRequired,
  rentTotalAnnual: PropTypes.number.isRequired,
  rentTotalMonthly: PropTypes.number.isRequired,
  units: PropTypes.arrayOf(
    PropTypes.shape({
      bathroomCount: PropTypes.number,
      bedroomCount: PropTypes.number,
      isIncluded: PropTypes.bool,
      outdoorSpace: PropTypes.oneOf(RENT_ROLL_OUTDOOR_SPACES),
      rent: PropTypes.number,
      rentType: PropTypes.oneOf(RENT_TYPES),
      roomCount: PropTypes.number,
      sqft: PropTypes.number,
      unitNumber: PropTypes.string,
    })
  ),
}

RentRollTableComponent.defaultProps = {
  maxUnits: 100,
}

export const RentRollTable = RentRollTableComponent
