import React from 'react'

import { isEmpty } from 'lodash'

import { Stack, Typography } from '@mui/material'

import * as Api from 'core/api'
import { SUPPORTED_IMAGE_FORMATS } from 'client-shared/constants/imageCategories'
import { connect } from 'react-redux'
import { getRotatedImageUrl } from 'client-shared/utils/cloudinary'
import { removeByIndex, replaceByIndex } from 'client-shared/utils/immutability'
import { updateAreFilesUploading as updateAreFilesUploadingAction } from 'client-shared/redux/actions/areFilesUploading'

import Dropzone from './Dropzone'
import ImagePreview from './ImagePreview'

const ImageUploadList = ({ category, label, onChange, value, updateAreFilesUploading }) => {
  const [imageList, setImageList] = React.useState(value)
  const setUploadingState = React.useCallback(
    state => updateAreFilesUploading({ areFilesUploading: state }),
    [updateAreFilesUploading]
  )

  React.useEffect(() => {
    setImageList(value)
  }, [value])

  const handleDrop = React.useCallback(
    async droppedImages => {
      if (isEmpty(droppedImages)) {
        return
      }

      const imageListInLoadingState = [
        ...droppedImages.map(image => ({ isUploading: true, cdnUrl: image.preview })),
        ...(imageList || []),
      ]
      setImageList(imageListInLoadingState)

      setUploadingState(true)
      const response = await Api.uploadImages({ images: droppedImages })
      const { images } = response
      setUploadingState(false)

      const imagesWithCategory = images.map(image => ({ ...image, category }))
      const updatedValue = [...imagesWithCategory, ...(imageList || [])]

      setImageList(updatedValue)
      onChange(updatedValue)
    },
    [category, imageList, onChange, setUploadingState]
  )

  const handleRotate = React.useCallback(
    index => {
      const { cdnUrl: url } = imageList[index]
      const newUrl = getRotatedImageUrl({ url })
      const updatedValue = replaceByIndex(imageList, { ...imageList[index], cdnUrl: newUrl }, index)

      setImageList(updatedValue)
      onChange(updatedValue)
    },
    [imageList, onChange]
  )

  const handleDelete = React.useCallback(
    index => {
      const updatedValue = removeByIndex(imageList, index)

      setImageList(updatedValue)
      onChange(updatedValue)
    },
    [imageList, onChange]
  )

  return (
    <Stack width={1}>
      {label && <Typography>{label}</Typography>}
      <Stack direction="row" flexWrap="wrap" gap={1}>
        <Dropzone accept={SUPPORTED_IMAGE_FORMATS} data-qa={`${category}-images-image-upload`} onDrop={handleDrop} />
        {(imageList || []).map((image, index) =>
          image.isUploading ? (
            <ImagePreview isLoading={true} key={image.cdnUrl} url={image.cdnUrl} />
          ) : (
            <ImagePreview
              category={image.category}
              key={image.cdnUrl}
              onDelete={() => handleDelete(index)}
              onRotate={() => handleRotate(index)}
              url={image.cdnUrl}
              index={index}
            />
          )
        )}
      </Stack>
    </Stack>
  )
}
export default connect(null, {
  updateAreFilesUploading: updateAreFilesUploadingAction,
})(ImageUploadList)
