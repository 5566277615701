import React from 'react'
import PropTypes from 'prop-types'

import ConfirmDialog from 'client-utils/MapMaker/ConfirmDialog'

const LoadTableConfirmDialog = ({ open, onConfirm }) => {
  return (
    <ConfirmDialog
      open={open}
      onConfirm={onConfirm}
      title="Load Selected Table?"
      text="Loading this table may modify existing rows, columns and/or values."
      confirmText="Load"
    />
  )
}

LoadTableConfirmDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  onConfirm: PropTypes.func.isRequired,
}

export default LoadTableConfirmDialog
