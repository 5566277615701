import { getPartsFromString } from '../../narrative/GeneratedText'

export const LETTER_OF_TRANSMITTAL_TEXT = {
  complianceParagraph: `This appraisal is also prepared in compliance with Title XI (with amendments) of the \
Financial Institutions Reform, Recovery and Enforcement Act of 1989 (FIRREA), as well as the Interagency Appraisal \
and Evaluation Guidelines dated December 2, 2010.`,
  valuationPrefix: 'After carefully considering all available information and factors affecting value, our opinion is:',
  covidLanguage: `The global outbreak of the “novel coronavirus,” which has resulted in the COVID-19 pandemic, is \
presently affecting the US population and economy. The extent and magnitude of the direct or indirect effects of this \
event on the national and local economy or real estate markets, vary depending upon the geographic location and \
property type. As the pandemic has progressed, there has been increased clarity regarding the effects through more \
recent analytical and transactional data, as well as via market participant information and expectations. Our analysis \
of these and related issues is presented in the attached report. The reader is cautioned and reminded that the \
conclusions presented in this appraisal report are based on information available as of the effective date(s) \
of valuation indicated. Although we have made reasonable efforts to estimate the impact, the uncertainty in \
the real estate and financial markets creates the potential for a more significant change in income and value \
over a relatively short period of time.`,
  opinionOfValue: `The opinion of value expressed herein is subject to the certification, assumptions and limiting \
conditions, and all other information contained in the following written appraisal report.`,
  thankYou: 'Thank you for the opportunity to serve you.',
}

export const getLetterOfTransmittalComplianceParagraph = () => {
  return getPartsFromString(LETTER_OF_TRANSMITTAL_TEXT.complianceParagraph)
}

export const getLetterOfTransmittalValuationPrefix = () => {
  return getPartsFromString(LETTER_OF_TRANSMITTAL_TEXT.valuationPrefix)
}

export const getLetterOfTransmittalCovidLanguage = () => {
  return getPartsFromString(LETTER_OF_TRANSMITTAL_TEXT.covidLanguage)
}

export const getLetterOfTransmittalOpinionOfValue = () => {
  return getPartsFromString(LETTER_OF_TRANSMITTAL_TEXT.opinionOfValue)
}

export const getLetterOfTransmittalThankYou = () => {
  return getPartsFromString(LETTER_OF_TRANSMITTAL_TEXT.thankYou)
}
