import React from 'react'

import { AppBar, Dialog, IconButton, Slide, Toolbar, Typography } from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'

import CloseConfirmDialog from '../../shared/components/CloseConfirmDialog'

const Transition = React.forwardRef((props, ref) => <Slide direction="up" {...props} ref={ref} />)

const FullScreenDialog = ({ children, confirm, content, dataQAAttributes, open }) => {
  const [promptToClose, setPromptToClose] = React.useState(false)
  const handleClose = React.useCallback(() => setPromptToClose(true), [])
  const closeConfirmDialog = React.useCallback(() => setPromptToClose(false), [])
  const handleConfirm = React.useCallback(() => {
    closeConfirmDialog()
    confirm()
  }, [closeConfirmDialog, confirm])

  const { dialog, closeIcon } = dataQAAttributes
  const { dialogTitle, confirmDialogTitle, confirmDialogContent } = content
  return (
    <Dialog
      data-qa={dialog}
      fullScreen
      disableEnforceFocus
      onClose={handleClose}
      open={open}
      TransitionComponent={Transition}
    >
      <AppBar sx={{ padding: 0, zIndex: 1201 }}>
        <Toolbar>
          <Typography variant="h6" color="inherit" sx={{ flexGrow: 1 }}>
            {dialogTitle}
          </Typography>
          <IconButton data-qa={closeIcon} color="inherit" onClick={handleClose} aria-label="Close">
            <CloseIcon />
          </IconButton>
        </Toolbar>
      </AppBar>
      {children}
      <CloseConfirmDialog
        open={!!promptToClose}
        onClose={closeConfirmDialog}
        onConfirm={handleConfirm}
        title={confirmDialogTitle}
        text={confirmDialogContent}
      />
    </Dialog>
  )
}

export default FullScreenDialog
