import { get } from 'lodash'
import React, { memo, useCallback } from 'react'
import arrayMutators from 'final-form-arrays'
import PropTypes from 'prop-types'
import { Grid, Stack, Typography } from '@mui/material'
import OpenInNewRounded from '@mui/icons-material/OpenInNewRounded'
import { useFeatureFlag } from '@bowery-valuation/feature-flagger-client'

import {
  siteAreaDecorator,
  propertyTypeDecorator,
  hasSoldWithinThreeYearsDecorator,
  orderedSalesHistoryDecorator,
} from 'report/forms/dataCollections/SubjectProperty/decorators'

import { SUBJECT_PROPERTY_PATH } from 'shared/constants/report/keysAndDataPaths'
import AutomationCTA from 'client-shared/components/AutomationCTA'

import { DEFAULT_ALERT_MESSAGES } from 'shared/constants/automation/messages'

import { saveReport } from '../../../redux/actions/report'

import wrapForm from '../../wrapForm'
import CalloutButton from '../../../../shared/components/CalloutButton'
import * as Api from '../../../api'

import { ATTOM_CALLOUT_LINK, ATTOM_CALLOUT_FEATURE_FLAG, AUTOMATION_FEATURE_FLAG } from '../constants'

import { styles } from './styles'
import { SiteDetails } from './SiteDetails'
import { PropertyDescription } from './PropertyDescription'
import PropertyHistory from './PropertyHistory'
import PropertyPhotos from './PropertyPhotos'

export const DATA_PATH = SUBJECT_PROPERTY_PATH

const SubjectPropertyContainer = memo(({ classes, form, valueAsComplete, reportId, saveReport, formPath }) => {
  const showAttomCallout = useFeatureFlag(ATTOM_CALLOUT_FEATURE_FLAG)
  const showAutomation = useFeatureFlag(AUTOMATION_FEATURE_FLAG)

  const onAutomationRun = useCallback(async () => {
    const { streetAddress, city, state, zip } = form.getState().values
    const data = await Api.runPropertyDataAutomation({
      reportId,
      address: streetAddress || '',
      city: city || '',
      state: state || '',
      zip: zip || '',
    })
    form.batch(() => {
      for (const key in data) {
        form.change(key, data[key])
      }
    })

    global.automationSaved = 'propertyData'
    saveReport(formPath)
  }, [form, reportId, saveReport, formPath])

  return (
    <Grid container xs={10} spacing={1.5}>
      {showAttomCallout && (
        <CalloutButton
          variant="link"
          text={
            <Typography variant="body1">
              You may notice some newly automated fields in this report from a public record database known as{' '}
              <b>ATTOM</b>.
            </Typography>
          }
          linkText="Learn More"
          linkIcon={<OpenInNewRounded fontSize="small" />}
          link={ATTOM_CALLOUT_LINK}
          target="_blank"
        />
      )}
      {showAutomation && (
        <AutomationCTA
          CTAMessage="Automate the Subject Property Data"
          successMessage={DEFAULT_ALERT_MESSAGES.SUCCESS}
          errorMessage={DEFAULT_ALERT_MESSAGES.ERROR}
          onAutomationRun={onAutomationRun}
        />
      )}
      <Stack spacing={2}>
        <SiteDetails classes={classes} form={form} reportId={reportId} />

        <PropertyDescription classes={classes} form={form} valueAsComplete={valueAsComplete} />

        <PropertyHistory form={form} classes={classes} />

        <PropertyPhotos form={form} classes={classes} />
      </Stack>
    </Grid>
  )
})

SubjectPropertyContainer.propTypes = {
  classes: PropTypes.object.isRequired,
  form: PropTypes.object.isRequired,
  valueAsComplete: PropTypes.bool.isRequired,
  reportId: PropTypes.string.isRequired,
}

export default wrapForm(
  DATA_PATH,
  {
    styles,
    heading: 'Subject Property Data',
    decorators: [
      siteAreaDecorator,
      propertyTypeDecorator,
      hasSoldWithinThreeYearsDecorator,
      orderedSalesHistoryDecorator,
    ],
    mutators: { ...arrayMutators },
  },
  state => {
    const valueAsComplete = get(state, 'report.reportSettings.valueAsComplete')
    const reportId = get(state, 'report.reportData')
    const { formPath } = get(state, 'shared.location.form', {})

    return { valueAsComplete, reportId, formPath }
  },
  dispatch => ({
    saveReport: formPath => dispatch(saveReport(formPath)),
  })
)(SubjectPropertyContainer)
