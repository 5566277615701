import { GeneratedText } from '../../../narrative/GeneratedText'

const generate = () => {
  const gt = new GeneratedText()
  gt.t`A survey of active lenders in the subject property's influencing market indicates that 25-year and `
  gt.t`30-year mortgage commitments are typically 175 to 450 basis points above 10-year treasuries.`
  return gt.getParts()
}

export default { generate }
