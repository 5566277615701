import React from 'react'
import { Field } from 'react-final-form'
import PropTypes from 'prop-types'
import { Map, ZoomControl, TileLayer as BaseMap } from 'react-leaflet'

import { Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle } from '@material-ui/core'

import { isNil } from 'lodash'

import Layer from './MapLayer'
import LegendControl from './LeafletLegend'
import { COLOR_RED, mapStyles } from './CartoStyles'

import wrapMap from './wrapMap'
import { MAP_SIZE } from './constants'

const propTypesMaterial = {
  isZoomOn: PropTypes.bool.isRequired,
  isImageSaving: PropTypes.bool.isRequired,
  isModalOpen: PropTypes.bool.isRequired,

  captureMap: PropTypes.func.isRequired,
  closeMapWizard: PropTypes.func.isRequired,
  cartoClient: PropTypes.object.isRequired,

  classes: PropTypes.object.isRequired,
  label: PropTypes.string,
  id: PropTypes.string.isRequired,
}

const propTypesMapOptions = {
  mapOptions: PropTypes.shape({
    maxZoom: PropTypes.number.isRequired,

    initialZoom: PropTypes.number.isRequired,
    initialCoordinates: PropTypes.shape({
      lat: PropTypes.number.isRequired,
      lng: PropTypes.number.isRequired,
    }),
    zoomDelta: PropTypes.number.isRequired,
    zoomSnap: PropTypes.number.isRequired,
    wheelPxPerZoomLevel: PropTypes.number.isRequired,
    baseMap: PropTypes.string.isRequired,
    mapLayers: PropTypes.arrayOf(
      PropTypes.shape({
        name: PropTypes.string.isRequired,
        style: PropTypes.string.isRequired,
        isCustom: PropTypes.bool.isRequired,
      })
    ).isRequired,
    legendItems: PropTypes.arrayOf(
      PropTypes.shape({
        name: PropTypes.string.isRequired,
        value: PropTypes.string.isRequired,
      })
    ).isRequired,
  }).isRequired,
}

const applyLayerFilters = (mapLayers, customLayerName, block, lot) => {
  const blockLotLayer = `
    #${customLayerName}[block=${block}][lot=${lot}] { polygon-fill: ${COLOR_RED}; }
 `

  return mapLayers.map(layer => {
    if (layer.name === customLayerName) {
      layer = { name: customLayerName, isCustom: true, style: blockLotLayer }
    }
    return layer
  })
}

class MaterialZoningMapWizard extends React.PureComponent {
  static propTypes = {
    ...propTypesMaterial,
    ...propTypesMapOptions,
  }

  static defaultProps = {
    zoomDelta: 0.3,
    zoomSnap: 0.1,
    wheelPxPerZoomLevel: 20,
  }

  render() {
    const {
      isZoomOn,
      isImageSaving,
      isModalOpen,

      captureMap,
      closeMapWizard,
      cartoClient,
      zoomDelta,
      zoomSnap,
      wheelPxPerZoomLevel,
      label,
      classes,
      id,
      mapOptions: {
        initialCoordinates,
        initialZoom,
        maxZoom,
        legendItems,
        baseMap,
        mapLayers,
        customLayerName,
        block,
        lot,
      },
    } = this.props

    const hasLegend = !isNil(legendItems) && legendItems.length > 0

    return (
      <Dialog maxWidth="md" open={isModalOpen} onClose={closeMapWizard} data-qa="zoning-map">
        <DialogTitle>{label}</DialogTitle>
        <DialogContent>
          <Map
            id={id}
            className={id}
            style={MAP_SIZE}
            center={initialCoordinates}
            zoom={initialZoom}
            maxZoom={maxZoom}
            zoomControl={false}
            zoomDelta={zoomDelta}
            zoomSnap={zoomSnap}
            wheelPxPerZoomLevel={wheelPxPerZoomLevel}
          >
            <BaseMap attribution="" url={baseMap} />
            {isZoomOn && <ZoomControl />}

            <Layer
              source={applyLayerFilters(mapLayers, customLayerName, block, lot)}
              style={mapStyles}
              client={cartoClient}
            />

            {hasLegend && <LegendControl className="supportLegend" items={legendItems} />}
          </Map>
        </DialogContent>
        <DialogActions>
          <Button onClick={closeMapWizard} color="primary" data-qa="zoning-map-close-btn">
            Close
          </Button>
          <div className={classes.wrapper}>
            <Button
              color="primary"
              disabled={isImageSaving}
              onClick={captureMap}
              data-qa="zoning-map-capture-screen-btn"
            >
              Capture Screen
            </Button>
            {isImageSaving && <CircularProgress size={24} className={classes.buttonProgress} />}
          </div>
        </DialogActions>
      </Dialog>
    )
  }
}

MaterialZoningMapWizard = wrapMap(MaterialZoningMapWizard)

export function ZoningMapWizard({ name, ...otherProps }) {
  return (
    <Field
      name={name}
      render={({ input }) => (
        <MaterialZoningMapWizard
          {...otherProps}
          id={name}
          value={input.value}
          handleChange={input.onChange}
          onFocus={input.onFocus}
          onBlur={input.onBlur}
        />
      )}
    />
  )
}

ZoningMapWizard.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string,
  ...propTypesMapOptions,
}
