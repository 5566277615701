import { put, takeLatest } from 'redux-saga/es/effects'

import * as Api from '../../../../../api'

import { importRentComps, importRentCompsRequest, importRentCompsReceive, importRentCompsFail } from './actions'

function* fetchReportRentComps({ payload }) {
  try {
    const { reportId } = payload
    yield put(importRentCompsRequest())
    const rentComps = yield Api.fetchRentComps(reportId)
    yield put(importRentCompsReceive({ rentComps }))
  } catch (err) {
    yield put(importRentCompsFail(err))
  }
}

export default [takeLatest(importRentComps, fetchReportRentComps)]
