import React from 'react'
import { ReactSVG } from 'react-svg'
import PropTypes from 'prop-types'
import { Typography } from '@material-ui/core/'
import InfoOutlined from '@mui/icons-material/InfoOutlined'
import { withStyles } from '@material-ui/core/styles'

import reportPreviewIconPath from '../../images/report_preview_icon.svg'

import Tooltip from '../Tooltip'

const styles = theme => ({
  root: {
    display: 'inline-flex',
    alignItems: 'center',
  },
  title: {
    fontWeight: 400,
  },
  leftIconWrapper: {
    '& svg': {
      width: 24,
      height: 24,
      paddingRight: theme.spacing.unit,
      paddingTop: 2,
      '& path': {
        fill: theme.palette.primary[900],
      },
    },
  },
  tooltipIcon: {
    fontSize: 18,
    color: theme.palette.secondary[800],
    marginLeft: 8,
    marginBottom: -2,
    letterSpacing: 0.2,
  },
})

const TitleWithIcons = ({ classes, className, title, leftIcon, tooltipText, tooltipPosition }) => (
  <div className={classes.root}>
    <div className={classes.leftIconWrapper}>{leftIcon}</div>
    <Typography variant="subtitle1" className={className || classes.title}>
      {title}
    </Typography>
    {tooltipText && (
      <Tooltip text={tooltipText} placement={tooltipPosition}>
        <InfoOutlined className={classes.tooltipIcon} />
      </Tooltip>
    )}
  </div>
)

const StyledTitleWithIcons = withStyles(styles)(TitleWithIcons)

StyledTitleWithIcons.displayName = 'TitleWithIcons'

StyledTitleWithIcons.propTypes = {
  title: PropTypes.string,
  leftIcon: PropTypes.node,
  tooltipText: PropTypes.string,
  tooltipPosition: PropTypes.string,
}

StyledTitleWithIcons.defaultProps = {
  title: null,
  tooltipText: null,
  leftIcon: <ReactSVG src={reportPreviewIconPath} />,
  tooltipPosition: 'top-start',
}

export default StyledTitleWithIcons
