import React, { useEffect, useState } from 'react'
import { Provider } from 'react-redux'
import { ConnectedRouter } from 'react-router-redux'

import { initAnalytics } from '../analytics'
import AuthSwitch from '../authentication/AuthSwitch'
import 'client-shared/styles/fonts.css'
import HttpClientManager from '../xhr/HttpClientManager'

import AuthedUser from './AuthedUser'
import { getHistory } from './history'
import { getStore, initStore } from './store'
import UIProvider from './UIProvider'

const App = () => {
  const [isLoading, setLoading] = useState(true)
  useEffect(() => {
    const store = initStore()
    HttpClientManager.setDispatch(store.dispatch)
    initAnalytics()
    setLoading(false)
  }, [])

  if (isLoading) {
    return null
  }

  return (
    <div id="main">
      <Provider store={getStore()}>
        <UIProvider>
          <ConnectedRouter history={getHistory()}>
            <AuthSwitch>
              <AuthedUser />
            </AuthSwitch>
          </ConnectedRouter>
        </UIProvider>
      </Provider>
    </div>
  )
}

export default App
