import React from 'react'
import PropTypes from 'prop-types'

import FullScreenDialog from '../shared/FullScreenDialog'

import DataExtractionTool from './DataExtractionTool'

const DIALOG_TITLE = 'Data Extraction Tool'
const COMFIRM_DIALOG_TITLE = 'Close Data Extraction Tool?'
const CONFIRM_DIALOG_CONTENT =
  'Data Extraction Tool does not save changes. If you close it you will need to start over.'
const DATA_QA_CLOSE_ICON = 'data-extraction-tool-close-btn'
const DATA_QA_DIALOG = 'data-extraction-tool'

const DataExtractionToolDialog = ({ open, onClose }) => (
  <FullScreenDialog
    confirm={onClose}
    content={{
      dialogTitle: DIALOG_TITLE,
      confirmDialogTitle: COMFIRM_DIALOG_TITLE,
      confirmDialogContent: CONFIRM_DIALOG_CONTENT,
    }}
    dataQAAttributes={{ closeIcon: DATA_QA_CLOSE_ICON, dialog: DATA_QA_DIALOG }}
    open={open}
  >
    <DataExtractionTool />
  </FullScreenDialog>
)

DataExtractionToolDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
}

export default DataExtractionToolDialog
