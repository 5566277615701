import React from 'react'
import { FieldArray } from 'react-final-form-arrays'
import arrayMutators from 'final-form-arrays'

import { Stack } from '@mui/material'

import wrapForm from '../wrapForm'

import { buildingCompRentRollUploadCsv } from '../../redux/actions'

import { BUILDING_LABEL_VALUE_PAIR } from '../../constants'

import RentRollTableContainer from './RentRollTableContainer'
import UnitMixTableContainer from './UnitMixTableContainer'

export const DATA_PATH = [BUILDING_LABEL_VALUE_PAIR.UnitMix.menuItem]

const UnitMixContainer = ({ ...props }) => {
  return (
    <Stack spacing={2}>
      <FieldArray name="units" {...props} component={RentRollTableContainer} />
      <FieldArray name="unitMix" {...props} component={UnitMixTableContainer} />
    </Stack>
  )
}

export default wrapForm(
  DATA_PATH,
  {
    heading: BUILDING_LABEL_VALUE_PAIR.UnitMix.heading,
    enableReinitialize: true,
    mutators: {
      ...arrayMutators,
    },
  },
  state => {},
  {
    buildingCompRentRollUploadCsv,
  }
)(UnitMixContainer)
