import { createAction } from 'redux-actions'

import { FETCH_LATEST_INSURABLE_VALUES, UPDATE_INSURABLE_VALUES } from '../actionTypes/insurableValues'

export const fetchLatestInsurableValues = createAction(FETCH_LATEST_INSURABLE_VALUES.ACTION)
export const fetchLatestInsurableValuesRequest = createAction(FETCH_LATEST_INSURABLE_VALUES.REQUEST)
export const fetchLatestInsurableValuesReceive = createAction(FETCH_LATEST_INSURABLE_VALUES.RECEIVE)
export const fetchLatestInsurableValuesFail = createAction(FETCH_LATEST_INSURABLE_VALUES.FAIL)

export const updateInsurableValues = createAction(UPDATE_INSURABLE_VALUES.ACTION)
export const updateInsurableValuesReceive = createAction(UPDATE_INSURABLE_VALUES.RECEIVE)
export const updateInsurableValuesFail = createAction(UPDATE_INSURABLE_VALUES.FAIL)
