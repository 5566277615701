import { LETTER_OF_TRANSMITTAL_PATH } from 'shared/constants/report/keysAndDataPaths'

import { Descriptions } from '../../final/AssumptionsConditions/constants'

const FORM_HEADING = 'Letter of Transmittal'
const DATA_PATH = LETTER_OF_TRANSMITTAL_PATH

const PREVIEW_SECTIONS = {
  PURPOSE_SECTION: 'purposeSection',
}

const EXTRAORDINARY_ASSUMPTIONS_SECTION = Descriptions.EXTRAORDINARY_ASSUMPTIONS

const HYPOTHETICAL_CONDITIONS_SECTION = Descriptions.HYPOTHETICAL_CONDITIONS

const SINCERELY_TEXT = 'Sincerely,'

const FREDDIE_MAC_LANGUAGE = [
  {
    value: 'independent auditors, accountants, attorneys, and other professionals acting on behalf of Lender;',
    subItems: [],
  },
  {
    value: 'governmental agencies having regulatory authority over Lender;',
    subItems: [],
  },
  {
    value: 'designated persons pursuant to an order or legal process of any court or governmental agency;',
    subItems: [],
  },
  {
    value: 'prospective purchasers of the Mortgage; and',
    subItems: [],
  },
  {
    value:
      'with respect to any debt (or portion thereof) and/or securities secured, directly or indirectly, by the Property, which is the subject of this report, the following parties and their respective successors and assigns:',
    subItems: [
      'any placement agent or broker/dealer and any of their respective affiliates, agents, and advisors;',
      'any initial purchaser or subsequent holder of such debt and/or securities;',
      'any Servicer of other agent acting on behalf of the holders of such debt and/or securities;',
      'any indenture trustee;',
      'any rating agency;',
      'any institutional provider from time to time of any liquidity facility or credit support for such financings',
    ],
  },
]

export {
  FORM_HEADING,
  DATA_PATH,
  PREVIEW_SECTIONS,
  EXTRAORDINARY_ASSUMPTIONS_SECTION,
  HYPOTHETICAL_CONDITIONS_SECTION,
  SINCERELY_TEXT,
  FREDDIE_MAC_LANGUAGE,
}
