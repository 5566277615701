import React, { FC } from 'react'

import { Box, Button, Card, CardContent, Chip, Grid, Stack, Tooltip, Typography } from '@mui/material'

import { Classes } from '../styles'
import { Subsection } from '../types'

import { removeUserName } from '../utils'
import { EXPORT_SECTIONS } from '../constants'

import { AtomicUnitBadge } from './AtomicUnitBadge'

type GenericSubsectionCardProps = {
  classes: Classes
  subsection: Subsection
  editSubsection: () => void
}
export const GenericSubsectionCard: FC<GenericSubsectionCardProps> = ({ classes, subsection, editSubsection }) => {
  return (
    <Grid item>
      <Card className={classes.subsectionCard} variant="outlined" data-qa="generic-subsection-card">
        <CardContent className={classes.subsectionCardContent}>
          <Stack direction="column">
            <Box gap={2} className={classes.cardBody}>
              <Tooltip title={removeUserName(subsection.name)} placement="top" className={classes.subsectionTooltip}>
                <Typography className={classes.cardTextTruncate} variant="h5" component="div">
                  {removeUserName(subsection.name)}
                </Typography>
              </Tooltip>
              <Tooltip title={subsection.title} placement="top" className={classes.subsectionTooltip}>
                <Typography className={classes.cardTextTruncate} pb={2} variant="subtitle1" component="div">
                  {subsection.title}
                </Typography>
              </Tooltip>
              {subsection.location && (
                <Chip label={EXPORT_SECTIONS[subsection.location]} className={classes.atomicTypeChipLabel} />
              )}
              <AtomicUnitBadge subsection={subsection} />
            </Box>
            <Box className={classes.cardButtons}>
              <Button variant="outlined" onClick={editSubsection}>
                Edit
              </Button>
            </Box>
          </Stack>
        </CardContent>
      </Card>
    </Grid>
  )
}
