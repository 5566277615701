import { get } from 'lodash'

import { STATE_ABBREVIATIONS } from '../../../../constants/states'

import { GEOGRAPHY_OPTIONS } from '../../../../constants/property/geography'

import { getLocationIdentifier } from '../../../report/propertyInformation'

import { getProperArticleForNumber } from '../../index'

import NeighborhoodLanguages from './neighborhood-language.json'

const getNYCTransitSentence = transitData => {
  if (!get(transitData, 'station.time') || !get(transitData, 'station.name')) {
    return ''
  }

  const walkingTime = transitData.station.time

  const appropriateArticle = getProperArticleForNumber(walkingTime)
  const stationName = transitData.station.name
    .replace(/[Ss]tation/, '')
    .replace(/[Ss]ubway/, '')
    .trim()
  return `The ${stationName} subway station is ${appropriateArticle} ${walkingTime} minute walk away.`
}

const generateNYCGenericTransportationLanguage = transitData => {
  const hasTransit = transitData.length > 0

  if (hasTransit) {
    return transitData.map(getNYCTransitSentence).join(' ')
  }

  return 'There are no subway stations within a half of a mile.'
}

const mapData = ({ reportStateShort, locationIdentifier, neighborhood, transitData, hasImage }) => {
  const isNycReport = locationIdentifier === GEOGRAPHY_OPTIONS.NY
  try {
    const language = NeighborhoodLanguages.find(
      lang => lang.State === reportStateShort && lang.Neighborhood === neighborhood
    )

    let generatedText = ''

    if (language) {
      const carLanguage = language['Car Language'] || ''
      const trainLanguage = language['Train Language'] || ''
      const busLanguage = language['Bus Language'] || ''
      const plainLanguage = language['Plane Language'] || ''

      generatedText = [carLanguage, trainLanguage, busLanguage, plainLanguage].join(' ')
    } else if (isNycReport) {
      generatedText = generateNYCGenericTransportationLanguage(transitData)
    }

    if (hasImage) {
      generatedText += generatedText ? ' ' : ''
      generatedText += 'A transportation summary is provided below:'
    }

    return {
      generatedText,
      hasGeneratedText: !!generatedText,
    }
  } catch (error) {
    console.error(`Error on trying to update transportation discussion: ${error}`)
    return {
      generatedText: '',
      hasGeneratedText: false,
    }
  }
}

export const mapDTO = formValues => {
  const { reportState, locationIdentifier, neighborhood } = formValues
  const reportStateShort = STATE_ABBREVIATIONS[reportState]
  const transitData = get(formValues, 'geographySpecific.transitInfo', [])
  const hasImage = get(formValues, 'transportationImage.cdnUrl')

  return mapData({ reportStateShort, locationIdentifier, neighborhood, transitData, hasImage })
}

export const mapDataModel = report => {
  const reportState = get(report, 'property_information.state')
  const reportStateShort = STATE_ABBREVIATIONS[reportState]
  const neighborhood = get(report, 'property_information.marketNeighborhood')
  const transitData = get(report, 'siteDescription.geographySpecific.transitInfo', [])
  const hasImage = get(report, 'siteDescription.transportationImage.cdnUrl')

  const state = get(report, 'property_information.state')
  const zip = get(report, 'property_information.zip')
  const locationIdentifier = getLocationIdentifier(state, zip)

  return mapData({ reportStateShort, locationIdentifier, neighborhood, transitData, hasImage })
}
