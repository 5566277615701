import BoweryDate from '@bowery-valuation/bowery-date'

import currency from '../../../utils/formatters/currencyFormatter'

export const DEFAULT_GENERAL_ASSUMPTIONS = [
  'Technical Environmental Inspections: No Environmental Site Assessment report was provided in conjunction with this appraisal. ' +
    'If a report is commissioned and there are any environmental issues uncovered, they could affect our opinion of value reported. ' +
    'We recommend the services of a professional engineer for this purpose.',
  'Zoning Ordinances: We recommend an appropriately qualified land use attorney if a definitive determination of compliance is required.',
  'Building Inspections: We recommend an inspection by a building engineer or professional property inspector if a more thorough ' +
    'examination of the subject’s improvements is required. Any immediate expenditures that a trained professional may determine are needed, ' +
    'could affect our opinion of value reported.',
  'Easements, Encroachments, and Restrictions: Within our research and analysis we will examine the tax map, deed, legal description, and survey ' +
    '(if available) to determine the existence of any easements, encroachments or restrictions impacting the subject property. ' +
    'However, further research required to determine whether or not such restrictions exist is beyond the scope of this appraisal assignment. ' +
    'Deed restrictions are a legal matter and only a title examination by an attorney or title company can uncover such restrictive covenants. ' +
    'Thus, we recommend a title search to definitively determine if any such restrictions do exist.',
  'Building Health and Fire Codes: Our valuation assumes there are no known code violations.',
]

export const DEFAULT_INSPECTION_DATE_ASSUMPTION =
  "We assume no significant changes to the property and have updated the date of value to today's date."

export function getDefaultInspectionDateAssumption(inspectionDate) {
  if (inspectionDate) {
    return `We last inspected the property on ${new BoweryDate(
      inspectionDate
    ).formatLongDate()}. ${DEFAULT_INSPECTION_DATE_ASSUMPTION}`
  }
  return null
}

export const DISCLAIMER_EXTRAORDINARY_ASSUMPTION =
  'If the extraordinary assumptions employed in this appraisal are proved to be false, the values reported herein may be materially impacted.'

export const getDefaultExtraordinaryAssumptions = (
  valueAsComplete,
  valueAsStabilized,
  dateOfValuation,
  timeUntilCompletion,
  totalRenovationBudget
) => {
  if (valueAsComplete) {
    const dateOfCompletion = dateOfValuation
      ? new BoweryDate(dateOfValuation).addMonthsToDate(timeUntilCompletion).formatLongDate()
      : ''

    return [
      `Our opinion of the prospective market values upon completion and upon stabilization assume that the proposed` +
        ` improvements are completed on ${dateOfCompletion}, the prospective date of completion, in accordance with` +
        " the architectural plans and specifications cited within this report, in a good and workmanlike manner, and in conformance with the local planning authority’s zoning codes and building's department construction codes.",
      `We make the extraordinary assumption the buyer will complete the renovation within the estimated time frame of` +
        ` ${timeUntilCompletion} months and will not go over the estimated budget of ${currency.format(
          totalRenovationBudget
        )}.`,
      'Our opinion of the prospective market values upon completion and upon stabilization assume that there will be no significant changes in the applicable economic conditions that could impact the subject property as currently perceived between the current effective date and our prospective valuation date.  This extraordinary assumption considers the current expectations and perceptions of market participants.',
    ]
  }

  if (valueAsStabilized) {
    return [
      'Our opinion of the prospective market value upon stabilization assumes that there will be no significant changes in the applicable economic conditions that could impact the subject property as currently perceived between the current effective date and our prospective valuation date.  This extraordinary assumption considers the current expectations and perceptions of market participants.',
    ]
  }

  return []
}
