import React from 'react'
import { get } from 'lodash'
import PropTypes from 'prop-types'

import { compose } from 'recompose'
import { connect } from 'react-redux'
import { Button, Tab } from '@mui/material'
import FilterListIcon from '@mui/icons-material/FilterList'
import { TabContext, TabList } from '@mui/lab'
import { withStyles } from '@mui/styles'

import { HEADER_HEIGHT } from '../shared/layout/constants'
import { getDisplayAddress } from '../shared/utils/propertyHelper'

import PageLayout from './PageLayout'
import { ReportTabs } from './constants'
import ReportsTable from './components/ReportsTable'
import CreateReportModal from './components/CreateReportModal'
import DuplicateReportModal from './components/DuplicateReportModal'
import ReportsDashboardTopMenu from './ReportsDashboardTopMenu'
import { changeReportTab, clearReportFilters, clearReports } from './redux/actions'

const styles = () => {
  return {
    tabs: {
      borderBottom: '1px solid #e8e8e8',
      padding: `10px ${HEADER_HEIGHT}px 0`,
    },
    tab: {
      width: 127,
      minWidth: 127,
      padding: 0,
    },
    tabLabel: {
      fontSize: 14,
      lineHeight: 1.14,
      letterSpacing: 1.3,
    },
    filterIcon: {
      height: 24,
      width: 24,
      marginRight: 9,
    },
  }
}

class ReportsDashboard extends React.PureComponent {
  state = {
    duplicateReportModalOpen: false,
    reportModalOpen: false,
    showFilter: true,
    report: null,
  }

  componentWillUnmount() {
    const { clearReports } = this.props

    clearReports()
  }

  toggleFilter = () => {
    const { clearReportFilters } = this.props
    const { showFilter } = this.state

    if (showFilter) {
      clearReportFilters()
    }

    this.setState(prevState => ({
      showFilter: !prevState.showFilter,
    }))
  }

  openReportModal = event => this.setState({ reportModalOpen: true })

  openDuplicateReportModal = reportData => {
    const { address, city, state } = reportData
    this.setState({
      duplicateReportModalOpen: true,
      report: {
        settings: reportData.settings,
        id: reportData._id,
        fullAddress: getDisplayAddress({ address, city, state }),
      },
    })
  }

  closeReportModal = () => this.setState({ reportModalOpen: false, report: null })
  closeDuplicateReportModal = () => this.setState({ duplicateReportModalOpen: false, report: null })

  changeCurrentTab = (event, currentTab) => {
    const { changeReportTab } = this.props

    changeReportTab(currentTab)
  }

  render() {
    const { classes, currentTab } = this.props
    const { reportModalOpen, showFilter, report, duplicateReportModalOpen } = this.state

    return (
      <PageLayout openReportModal={this.openReportModal}>
        <DuplicateReportModal
          open={duplicateReportModalOpen}
          handleClose={this.closeDuplicateReportModal}
          initialValues={report}
        />
        {reportModalOpen && <CreateReportModal open={reportModalOpen} closeReportModal={this.closeReportModal} />}
        <ReportsDashboardTopMenu>
          <TabContext value={currentTab}>
            <TabList
              classes={{ root: classes.tabs }}
              onChange={this.changeCurrentTab}
              scrollButtons={false}
              variant="scrollable"
              visibleScrollbar
            >
              <Tab
                classes={{ root: classes.tab, labelContainer: classes.tabLabel }}
                label={ReportTabs.MY_REPORTS}
                value={ReportTabs.MY_REPORTS}
                disableRipple
              />
              <Tab
                classes={{ root: classes.tab, labelContainer: classes.tabLabel }}
                label={ReportTabs.ALL_REPORTS}
                value={ReportTabs.ALL_REPORTS}
                disableRipple
              />
            </TabList>
          </TabContext>
        </ReportsDashboardTopMenu>

        <Button
          color="primary"
          variant="outlined"
          data-qa="show-filter-btn"
          onClick={this.toggleFilter}
          startIcon={showFilter ? undefined : <FilterListIcon />}
        >
          {showFilter ? 'CLEAR FILTERS' : 'FILTERS'}
        </Button>

        <ReportsTable
          changeCurrentTab={this.changeCurrentTab}
          openReportModal={this.openDuplicateReportModal}
          currentTab={currentTab}
          showFilter={showFilter}
        />
      </PageLayout>
    )
  }
}

ReportsDashboard.propTypes = {
  currentTab: PropTypes.string.isRequired,
  changeReportTab: PropTypes.func.isRequired,
  clearReportFilters: PropTypes.func.isRequired,
  clearReports: PropTypes.func.isRequired,
}

export default compose(
  connect(
    state => ({
      currentTab: get(state, 'reports.currentTab'),
    }),
    { changeReportTab, clearReportFilters, clearReports }
  ),
  withStyles(styles)
)(ReportsDashboard)
