import React from 'react'
import OpenIcon from '@mui/icons-material/OpenInNewRounded'
import { withStyles } from '@material-ui/core'

import { CalloutButton } from '../../../../shared/components'

import { CALLOUT_BTN_LINK } from './constants'

const styles = theme => ({
  calloutRoot: {
    padding: '10px 17px',
    marginTop: '0px',
    background: 'linear-gradient(0deg, rgba(255, 255, 255, 0.9), rgba(255, 255, 255, 0.9)), #2196F3',
  },
  calloutIcon: {
    paddingTop: '8px',
    paddingRight: '0px',
    '& svg': {
      fill: '#2196F3',
      width: '20px',
      height: '20px',
    },
  },
  calloutText: {
    fontFamily: 'Nunito Sans',
    color: '#164367',
  },
  calloutLink: {
    color: '#164367',
    fontSize: '14px',
    marginRight: '11px',
  },
  calloutButton: {
    '& svg': {
      width: '18px',
      height: '18px',
    },
  },
})

const JobSyncCallout = () => {
  return (
    <CalloutButton
      link={global.env.isProduction ? CALLOUT_BTN_LINK.LINK_PROD : CALLOUT_BTN_LINK.LINK_REST}
      linkIcon={<OpenIcon fontSize="inherit" color="primary" />}
      linkText={CALLOUT_BTN_LINK.BTN_TEXT}
      sx={{ mb: 2, mt: -2 }}
      target="_blank"
      text={CALLOUT_BTN_LINK.TEXT}
      variant="link"
    />
  )
}
export default withStyles(styles)(JobSyncCallout)
