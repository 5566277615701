import React from 'react'
import PropTypes from 'prop-types'

import OpenInNewOutlinedIcon from '@mui/icons-material/OpenInNewOutlined'
import { Tooltip } from '@mui/material'
import { withStyles } from '@mui/styles'
import GoogleMap from 'google-map-react'
import { get } from 'lodash'
import { connect } from 'react-redux'
import { compose, pure } from 'recompose'

import { GREY_TEXT_COLOR } from 'client-shared/layout/constants'

import SectionWrapper from '../SectionWrapper'

const styles = theme => ({
  tooltip: {
    fontSize: 12,
    color: theme.palette.common.white,
    backgroundColor: theme.palette.grey[600],
  },
  linkIcon: {
    color: theme.palette.grey[600],
    width: 25,
    height: 25,
  },
  mapWrapper: {
    width: '100%',
    height: '100%',
  },
})

const GoogleMapSection = ({ classes, propertyPosition }) => {
  const googleMapsUrl = `https://maps.google.com/?q=${propertyPosition.lat},${propertyPosition.lng}`
  return (
    <SectionWrapper
      title="Google Maps"
      rightElement={
        <a href={googleMapsUrl} target="_blank" rel="noopener noreferrer">
          <Tooltip title="Open Google Maps in New Tab" placement="left" classes={{ tooltip: classes.tooltip }}>
            <OpenInNewOutlinedIcon color={GREY_TEXT_COLOR} />
          </Tooltip>
        </a>
      }
    >
      <div className={classes.mapWrapper}>
        <GoogleMap
          bootstrapURLKeys={global.env.googleApiKey}
          defaultZoom={10}
          defaultCenter={propertyPosition}
          yesIWantToUseGoogleMapApiInternals
          onGoogleApiLoaded={({ map, maps }) => {
            new maps.Marker({ position: propertyPosition, map })
          }}
        >
          {props => <div {...propertyPosition} />}
        </GoogleMap>
      </div>
    </SectionWrapper>
  )
}

GoogleMapSection.propTypes = {
  classes: PropTypes.object.isRequired,
  propertyPosition: PropTypes.shape({
    lat: PropTypes.number.isRequired,
    lng: PropTypes.number.isRequired,
  }).isRequired,
}

export default compose(
  pure,
  connect(state => {
    const coords = get(state, 'report.reportData.propertyInformation.coords')
    return {
      propertyPosition: { lat: coords.latitude, lng: coords.longitude },
    }
  }),
  withStyles(styles)
)(GoogleMapSection)
