import { filter, find, includes, map, get, isEmpty } from 'lodash'
import flowFP from 'lodash/fp/flow'
import mapFP from 'lodash/fp/map'
import orderByFP from 'lodash/fp/orderBy'

const sortAppraisers = (appraisers = []) =>
  appraisers.sort((prevAppraiser, nextAppraiser) => {
    if (prevAppraiser.isLeadAppraiser) {
      return -1
    }
    if (nextAppraiser.isLeadAppraiser) {
      return 1
    }
    return 0
  })

const getSearchAppraisersSuggestions = (allAppraisers, selectedAppraisers) => {
  const selectedAppraisersIds = map(selectedAppraisers, 'id')
  const filteredAppraisers = filter(allAppraisers, appraiser => !includes(selectedAppraisersIds, appraiser.id))

  return map(filteredAppraisers, appraiser => ({ ...appraiser, isLeadAppraiser: false }))
}

const getLeadAppraisersLabelValuePairs = (allAppraisers, selectedAppraisers) => {
  const leadAppraisers = getLeadAppraisers(allAppraisers, selectedAppraisers)

  return flowFP([
    orderByFP([appraiser => appraiser.lastName?.toLocaleLowerCase()], ['asc']),
    mapFP(appraiser => {
      return {
        label: appraiser.fullName,
        value: appraiser.id,
      }
    }),
  ])(leadAppraisers)
}

const getLeadAppraisers = (allAppraisers, selectedAppraisers) => {
  const selectedAppraisersIds = map(selectedAppraisers, 'id')
  const selectedLeadAppraiser = find(selectedAppraisers, 'isLeadAppraiser') || {}
  return filter(
    allAppraisers,
    appraiser =>
      appraiser.isLeadAppraiser &&
      (!includes(selectedAppraisersIds, appraiser.id) || appraiser.id === selectedLeadAppraiser.id)
  )
}

const getStateCertifiedAppraisers = (selectedAppraisers, reportState) => {
  return selectedAppraisers.filter(appraiser => appraiserCertifiedInState(appraiser, reportState))
}

const appraiserCertifiedInState = (appraiser, reportState) => {
  const appraiserCerts = get(appraiser, 'appraisalCertifications') || get(appraiser, 'certification') || []
  if (isEmpty(appraiserCerts)) {
    return false
  }

  const selectedCertificationState = get(appraiser, 'certification.state')

  return selectedCertificationState === reportState
}

export {
  sortAppraisers,
  getSearchAppraisersSuggestions,
  getLeadAppraisersLabelValuePairs,
  getLeadAppraisers,
  getStateCertifiedAppraisers,
  appraiserCertifiedInState,
}
