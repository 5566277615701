export const DEFAULT_SWOT_ANALYSIS = {
  includeInReport: true,
  strengths: [
    'The building is 100% occupied.',
    'The subject property has good freeway access and is in close proximity to schools, parks, shopping centers, and employment.',
  ],
  weaknesses: [
    'The subject is half a block to the elevated BQE Expressway and a nearby industrial area.',
    'The subject property does not currently benefit from a tax abatement.',
  ],
  opportunities: [
    'All units are rent stabilized, and there is potential upside upon tenant turnover.',
    'There has been increased demand for similar assets as investors from prime New York Metro submarkets seek higher returns increasing pricing for similar assets.',
  ],
  threats: [
    'Rent growth is limited by local rent control laws.',
    'The Federal Reserve Board has recently increased rates by 25-basis points and more rate hikes are expected this year.',
    'As the economy continues its recovery from the global pandemic, there remains uncertainty related to the speed and consistency of the recovery.',
    'Economic uncertainty and potential market instability related to the war in Ukraine.',
  ],
}
