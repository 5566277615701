import React from 'react'
import PropTypes from 'prop-types'

import { Button, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, TextField } from '@mui/material'

import { MaterialRadioButtons as RadioButtonList } from '../../shared/components/RadioButtons/RadioButtonList'
import { arrayToKeyValuePairs } from '../../shared/utils/ui/checkboxHelper'

import { COMPARABLE_TYPE } from './constants'

const comparableTypeLabels = arrayToKeyValuePairs([COMPARABLE_TYPE.SUBJECT, COMPARABLE_TYPE.COMPARABLE])

class EditDialog extends React.PureComponent {
  state = {
    ...this.props.propertyToEdit,
    disabled: false,
    open: this.props.open,
  }

  handleCancel = () => {
    const { indexToEdit, propertyToEdit, isNew, onDelete } = this.props
    if (isNew) {
      onDelete(indexToEdit)
    } else {
      this.props.onEdit(indexToEdit, propertyToEdit)
    }
  }

  handleSave = ev => {
    this.props.onEdit(this.props.indexToEdit, this.state)
  }

  handleChange = (ev, key) => {
    this.setState({ ...this.state, [key]: ev.target.value })
  }

  handleRadio = (ev, value) => {
    this.setState({ isSubject: value === COMPARABLE_TYPE.SUBJECT })
  }

  handleDisable = () => {
    const { city, streetAddress, state, zip } = this.state

    return [city, streetAddress, state, zip].some(({ length }) => !length)
  }

  render() {
    const { cancelText, confirmText, propertyToEdit } = this.props

    const { city, isSubject, open, streetAddress, state, zip } = this.state

    return (
      <Dialog
        aria-describedby="edit-dialog-description"
        aria-labelledby="edit-dialog-title"
        fullWidth
        maxWidth="sm"
        open={open}
      >
        <DialogTitle>Edit Comparable</DialogTitle>
        <DialogContent>
          <FormControl fullWidth sx={{ gap: 2 }}>
            <RadioButtonList
              horizontal={true}
              input={{
                value: isSubject ? COMPARABLE_TYPE.SUBJECT : COMPARABLE_TYPE.COMPARABLE,
                onChange: this.handleRadio,
                name: 'comparableType',
              }}
              items={comparableTypeLabels}
              label="Type"
            />
            <TextField
              defaultValue={propertyToEdit.streetAddress}
              error={streetAddress.length === 0}
              fullWidth
              label="Address"
              onChange={ev => this.handleChange(ev, 'streetAddress')}
              required
              variant="outlined"
            />
            <TextField
              defaultValue={propertyToEdit.city}
              error={city.length === 0}
              label="City"
              onChange={ev => this.handleChange(ev, 'city')}
              required
              variant="outlined"
            />
            <TextField
              defaultValue={propertyToEdit.state}
              error={state.length === 0}
              label="State"
              onChange={ev => this.handleChange(ev, 'state')}
              required
              variant="outlined"
            />
            <TextField
              defaultValue={propertyToEdit.zip}
              error={zip.length === 0}
              label="Zip"
              onChange={ev => this.handleChange(ev, 'zip')}
              required
              variant="outlined"
            />
          </FormControl>
        </DialogContent>
        <DialogActions>
          <Button onClick={this.handleCancel} autoFocus>
            {cancelText}
          </Button>
          <Button disabled={this.handleDisable()} onClick={this.handleSave} variant="contained">
            {confirmText}
          </Button>
        </DialogActions>
      </Dialog>
    )
  }
}

EditDialog.propTypes = {
  cancelText: PropTypes.string,
  classes: PropTypes.object.isRequired,
  confirmText: PropTypes.string,
  indexToEdit: PropTypes.number.isRequired,
  isNew: PropTypes.bool,
  onDelete: PropTypes.func.isRequired,
  onEdit: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  propertyToEdit: PropTypes.object.isRequired,
}

EditDialog.defaultProps = {
  cancelText: 'Cancel',
  confirmText: 'Save',
}

export default EditDialog
