import { connect } from 'react-redux'

import { get, map, filter } from 'lodash'

import { ExportStatuses } from 'shared/constants/export'

import { generateReportFromJSON, updateStatus } from '../redux/actions'

import ExportPhotoGrid from './ExportPhotoGrid'

export default connect(
  (state, ownProps) => {
    const reportId = get(ownProps, 'json._id')
    let exportItems = get(state, 'export.exportItems', [])
    const downloadFolderUrl = get(state, 'export.downloadFolderUrl', '/download')

    const mapDocuments = items => {
      return map(
        filter(items, item => {
          return item.report === reportId
        }),
        item => {
          const extension = item.baseTemplateFileName.match(/\.[^/]+$/)?.[0] || ''
          const downloadUrl = `${downloadFolderUrl}/${reportId}/?extension=${extension}`

          item.isLastItemCompleted =
            item.status === ExportStatuses.ERROR ||
            item.status === ExportStatuses.COMPLETE ||
            item.status === ExportStatuses.WARNING
          item.downloadUrl = downloadUrl

          return item
        }
      )
    }

    exportItems = mapDocuments(exportItems)

    if (exportItems && exportItems.length) {
      if (!global.env.isDevelopment) {
        exportItems = [exportItems[0]]
      }
    }

    const lastCompletedExportItemIndex = exportItems.findIndex(r =>
      [ExportStatuses.COMPLETE, ExportStatuses.WARNING].includes(r.status)
    )

    return {
      exportItems: exportItems,
      reportId,
      lastCompletedExportItemIndex,
    }
  },
  {
    generateReportFromJSON,
    updateStatus,
  }
)(ExportPhotoGrid)
