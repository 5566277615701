import React, { FunctionComponent } from 'react'
import classnames from 'classnames'
import { isEmpty } from 'lodash'
import { Stack, Box, Button, Card, Typography } from '@mui/material'

import { DropDown, NarrativeComponent, Text } from 'client-shared/components'

import { ExtendedFormApi } from 'client-shared/utils/form'

import { EXPORT_SECTIONS, SUBSECTION_TITLE, SUBSECTION_LOCATION, EDIT_CARD } from '../constants'
import { Classes } from '../styles'
import { FormValues, Subsection } from '../types'
import { removeUserName, isNarrativeAtomicUnit } from '../utils'
import Table from '../createGenericSubsection/AtomicUnit/Table'

type ViewGenericSubsectionProps = {
  classes: Classes
  onClose: () => void
  onSave: () => void
  form: ExtendedFormApi<FormValues>
  subsection: Subsection
}

const exportSectionsOptions = (Object.keys(EXPORT_SECTIONS) as (keyof typeof EXPORT_SECTIONS)[]).map(key => {
  return {
    label: EXPORT_SECTIONS[key],
    value: key,
  }
})

const ViewGenericSubsection: FunctionComponent<ViewGenericSubsectionProps> = ({
  classes,
  onSave,
  onClose,
  subsection,
  form,
}) => {
  const handleSave = () => {
    const name =
      form.values.newGenericSubsection.name.length === 0 ? subsection.name : form.values.newGenericSubsection.name

    const title =
      form.values.newGenericSubsection.title.length === 0 ? subsection.title : form.values.newGenericSubsection.title

    const location =
      form.values.newGenericSubsection.location && form.values.newGenericSubsection.location.length === 0
        ? subsection.location
        : form.values.newGenericSubsection.location

    const data = isEmpty(form.values.newGenericSubsection.data)
      ? subsection.data
      : form.values.newGenericSubsection.data

    const newGenericSubsection = {
      name: name.trim(),
      title: title.trim(),
      location,
      data,
      action: 'UPDATE',
      origName: subsection.name.trim(),
    }
    form.change('newGenericSubsection', newGenericSubsection)
    onSave()
    onClose()
  }

  return (
    <Card elevation={3}>
      <Stack className={classes.createFields} direction="column">
        <Typography variant="h6">Edit</Typography>
        <Typography variant="body1">{EDIT_CARD}</Typography>
        <Box className={classes.createField}>
          <Typography variant="subtitle1">Content Name</Typography>
          <Typography className={classes.cardTextTruncate} variant="h5" component="div">
            {removeUserName(subsection.name)}
          </Typography>
        </Box>
        <Box className={classes.createField}>
          <Typography variant="subtitle1">Export Header Title</Typography>
          <Typography>{SUBSECTION_TITLE}</Typography>
          <Text placeholder="Enter Title (optional)" name="newGenericSubsection.title" />
        </Box>
        <Box>
          {subsection.data.atomicUnits.map((atomicUnit, index) =>
            isNarrativeAtomicUnit(atomicUnit) ? (
              <Box className={classnames(classes.atomicUnit, classes.createField)}>
                <NarrativeComponent
                  title="Paragraph(s)"
                  generatedText={() => atomicUnit.narrative}
                  name={`newGenericSubsection.data.atomicUnits[${index}]`}
                  type="gs-edit"
                />
              </Box>
            ) : (
              <Box className={classnames(classes.atomicUnit, classes.tableField)}>
                <Table form={form} formPath={`newGenericSubsection.data.atomicUnits[${index}]`} />
              </Box>
            )
          )}
        </Box>
        <Box className={classes.createField}>
          <Typography variant="subtitle1">Report Section</Typography>
          <DropDown
            fullWidth
            placeholder="Select Report Section (optional)"
            name="newGenericSubsection.location"
            items={exportSectionsOptions}
            helperText={SUBSECTION_LOCATION}
          />
        </Box>
      </Stack>

      <Box className={classes.create_buttons}>
        <Button data-qa="form-cancel-btn" onClick={() => onClose()} variant="text">
          Cancel
        </Button>
        <Button data-qa="form-save-btn" onClick={handleSave} variant="contained">
          Save
        </Button>
      </Box>
    </Card>
  )
}

export default ViewGenericSubsection
