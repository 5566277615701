import React from 'react'
import PropTypes from 'prop-types'

import { Stack } from '@mui/material'

import Callout from '../Callout'
import RouterButton from '../RouterButton'

const CalloutButton = ({
  ButtonComponent = RouterButton,
  icon = undefined,
  linkIcon = undefined,
  qa = 'callout-btn',
  sx = undefined,
  text,
  variant,
  ...props
}) => {
  return (
    <Callout
      action={
        <Stack data-qa={qa} justifyContent="center" sx={{ mt: -1 / 4, ml: -2, height: '100%' }}>
          <ButtonComponent endIcon={linkIcon} {...props} />
        </Stack>
      }
      content={text}
      icon={icon}
      sx={sx}
      variant={variant}
    />
  )
}

CalloutButton.displayName = 'CalloutButton'

CalloutButton.propTypes = {
  icon: PropTypes.element,
  linkText: PropTypes.string.isRequired,
  qa: PropTypes.string,
  text: PropTypes.string.isRequired,
}

export default CalloutButton
