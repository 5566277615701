import React from 'react'
import PropTypes from 'prop-types'

import createDecorator from 'final-form-calculate'
import { FormGroup, FormLabel, Grid, Paper, Table, TableBody, TableCell, TableHead, TableRow } from '@mui/material'
import { get } from 'lodash'

import { CalloutButton, GeneratedComment, Number as NumberField, RadioButtonList, Text } from 'client-shared/components'
import { CheckboxWithLabel } from 'client-shared/components/Checkbox'
import { LaundryIncomeTypes } from 'shared/constants/report/incomeApproach/miscellaneous'
import { formatCurrencyFloat, toPercentageString } from 'client-shared/utils/numberFormatters'
import { fromPercents, toPercents } from 'client-shared/utils/numberOperations'
import { generateLaundryIncomeDiscussion } from 'shared/utils/textGeneration/incomeApproach/miscellaneous/laundry'
import MiscellaneousCalculations from 'shared/report-calculations/income-approach/miscellaneous/miscellaneous-calculations'

import wrapForm from '../../../wrapForm'
import { MAX_VC_LOSS_PERCENTAGE_VALUE } from '../constants'

import {
  DATA_PATH,
  HEADING,
  LAUNDRY_INCOME_OPTIONS,
  LAUNDRY_VC_LOSS_OPTIONS,
  LaundryVCLossTypes,
  OWNER_OR_FORECAST_OPTIONS,
  OwnerOrForecastTypes,
  WARNING_LINK,
  WARNING_TEXT,
} from './constants'

class LaundryIncome extends React.PureComponent {
  getLaundryVCRate = ({ laundryVCLossType, residentialVCLossPercentage, laundryVCLossPercentage }) => {
    return laundryVCLossType === LaundryVCLossTypes.RESIDENTIAL ? residentialVCLossPercentage : laundryVCLossPercentage
  }

  getLaundryVC = ({
    laundryVCLossType,
    residentialVCLossPercentage,
    laundryVCLossPercentage,
    laundryIncome,
    laundryIncomeType,
    unitCount,
  }) => {
    const rate = this.getLaundryVCRate({ laundryVCLossType, residentialVCLossPercentage, laundryVCLossPercentage })
    const annualLaundryIncome = MiscellaneousCalculations.calculateLaundryIncome(
      laundryIncome,
      laundryIncomeType,
      unitCount
    )
    return annualLaundryIncome * rate * -1
  }

  calculateTableData = () => {
    const { form, unitCount } = this.props
    const {
      laundryIncome = 0,
      laundryIncomeType = LaundryIncomeTypes.ANNUAL,
      laundryVCLossType = LaundryVCLossTypes.RESIDENTIAL,
      laundryVCLossPercentage = 0,
      residentialVCLossPercentage = 0,
    } = form.values

    const annualLaundryIncome = MiscellaneousCalculations.calculateLaundryIncome(
      laundryIncome,
      laundryIncomeType,
      unitCount
    )
    const laundryVc = this.getLaundryVC({
      laundryVCLossType,
      residentialVCLossPercentage,
      laundryVCLossPercentage,
      laundryIncome,
      laundryIncomeType,
      unitCount,
    })
    const laundryIncomeAfterVCLoss = laundryVc + annualLaundryIncome
    const laundryVcRate = this.getLaundryVCRate({
      laundryVCLossType,
      residentialVCLossPercentage,
      laundryVCLossPercentage,
    })

    return { annualLaundryIncome, laundryVc, laundryIncomeAfterVCLoss, laundryVcRate }
  }

  generateLaundryIncomeDiscussion = () => {
    const { form, unitCount } = this.props
    const { lessee, isLaundryLeased, laundryIncome, laundryIncomeType, ownerOrForecast } = form.values

    const isAnnual = laundryIncomeType && laundryIncomeType === LaundryIncomeTypes.ANNUAL
    const isForecastType = ownerOrForecast && ownerOrForecast === OwnerOrForecastTypes.FORECAST
    const annualLaundryIncome = MiscellaneousCalculations.calculateLaundryIncome(
      laundryIncome,
      laundryIncomeType,
      unitCount
    )

    return generateLaundryIncomeDiscussion({
      lessee,
      isLaundryLeased,
      isForecastType,
      isAnnual,
      annualLaundryIncome,
      unitCount,
    })
  }

  render() {
    const { form, hasNoAmenities, hasLaundryRoom } = this.props
    const { isLaundryLeased, laundryVCLossType = LaundryVCLossTypes.RESIDENTIAL } = form.values

    const { annualLaundryIncome, laundryVc, laundryIncomeAfterVCLoss, laundryVcRate } = this.calculateTableData()
    const isResidentialLossType = laundryVCLossType === LaundryVCLossTypes.RESIDENTIAL

    return (
      <Grid container spacing={4}>
        <Grid item xs={6}>
          {!hasNoAmenities && hasLaundryRoom && (
            <Paper>
              <div>
                <CheckboxWithLabel name="isLaundryLeased" label="Is the laundry room leased?" />
              </div>
              <Text name="lessee" label="Lessee" disabled={!isLaundryLeased} />
              <RadioButtonList
                horizontal
                items={LAUNDRY_INCOME_OPTIONS}
                label="Annually or Monthly"
                name="laundryIncomeType"
              />
              <RadioButtonList
                horizontal
                items={OWNER_OR_FORECAST_OPTIONS}
                label="Owner or Forecast"
                name="ownerOrForecast"
              />
              <NumberField
                adornment="$"
                adornmentPosition="start"
                allowNegative={false}
                decimalScale={2}
                label="Laundry Income"
                name="laundryIncome"
                thousandSeparator
              />
              <RadioButtonList
                horizontal
                items={LAUNDRY_VC_LOSS_OPTIONS}
                label="Laundry V/C Loss Type"
                name="laundryVCLossType"
              />
              {!isResidentialLossType && (
                <NumberField
                  adornment="%"
                  allowNegative={false}
                  decimalScale={2}
                  disabled={isResidentialLossType}
                  format={toPercents}
                  label={`${laundryVCLossType} Loss (%)`}
                  max={MAX_VC_LOSS_PERCENTAGE_VALUE}
                  name="laundryVCLossPercentage"
                  normalize={fromPercents}
                />
              )}
              <GeneratedComment
                dataPath="laundryIncomeDiscussion"
                getGeneratedText={this.generateLaundryIncomeDiscussion}
                isDynamicContent
                label="Laundry Income Discussion"
                title="Generated Commentary"
              />
            </Paper>
          )}
          {(hasNoAmenities || !hasLaundryRoom) && (
            <CalloutButton
              link={WARNING_LINK}
              linkText="Change"
              qa="no-laundry-room-callout-btn"
              text={WARNING_TEXT}
              variant="warn"
            />
          )}
        </Grid>
        <Grid item xs={6}>
          <FormGroup data-qa="laundry-income-summary-panel">
            <FormLabel component="legend">Income Summary</FormLabel>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Income Type</TableCell>
                  <TableCell align="right">$</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow>
                  <TableCell>Annual Laundry Income</TableCell>
                  <TableCell align="right" data-qa="annualLaundryIncome-cell">{`${formatCurrencyFloat(
                    annualLaundryIncome
                  )}`}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell data-qa="laundryVcRate-cell">{`Less ${laundryVCLossType} Loss @ ${toPercentageString(
                    laundryVcRate
                  )}`}</TableCell>
                  <TableCell align="right" data-qa="laundryVc-cell">
                    {formatCurrencyFloat(laundryVc)}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell data-qa="totalCell-cell" sx={{ fontWeight: 700 }}>
                    Total
                  </TableCell>
                  <TableCell align="right" data-qa="laundryIncomeAfterVCLoss-cell" sx={{ fontWeight: 700 }}>
                    {formatCurrencyFloat(laundryIncomeAfterVCLoss)}
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </FormGroup>
        </Grid>
      </Grid>
    )
  }
}

const isLaundryLeasedWatcher = createDecorator({
  field: ['isLaundryLeased'],
  updates: value => {
    if (!value) {
      return { lessee: null }
    }
    return {}
  },
})

const formOptions = { heading: HEADING, decorators: [isLaundryLeasedWatcher] }

LaundryIncome.propTypes = {
  form: PropTypes.object.isRequired,
  hasLaundryRoom: PropTypes.bool.isRequired,
  hasNoAmenities: PropTypes.bool.isRequired,
  unitCount: PropTypes.number.isRequired,
}

export default wrapForm(DATA_PATH, formOptions, state => {
  const hasLaundryRoom = get(state, 'report.reportData.propertyInformation.amenities.building.hasLaundryRoom', false)
  const hasNoAmenities = get(state, 'report.reportData.propertyInformation.amenities.hasNoAmenities', true)
  const unitCount = get(state, 'report.reportData.incomeApproach.residentialIncome.residentialRentRoll.units.length', 0)

  return {
    hasLaundryRoom,
    hasNoAmenities,
    unitCount,
  }
})(LaundryIncome)
