import React from 'react'
import classnames from 'classnames'

import { Table, TableCell, TableBody, TableHead, TableRow, withStyles } from '@material-ui/core'

import MoreButton from './MoreButton'

import styles from './styles'

const TAX_LINK_TEXT = 'Assessment Records'

class NewJerseyResultsTable extends React.PureComponent {
  handleRowClick = index => () => {
    const { onPropertySelect } = this.props
    onPropertySelect(index)
  }

  render() {
    const { properties, classes, selectedPropertyIndex } = this.props
    return (
      <Table className={classes.table} data-qa="propertySearch-search-results-table">
        <TableHead>
          <TableRow>
            <TableCell className={classes.leadCell} padding="none" />
            <TableCell className={classes.tableCell} padding="none">
              Address
            </TableCell>
            <TableCell className={classes.tableCell} padding="none">
              State
            </TableCell>
            <TableCell className={classes.tableCell} padding="none">
              County
            </TableCell>
            <TableCell className={classes.tableCell} padding="none">
              City/Town
            </TableCell>
            <TableCell className={classes.tableCell} padding="none">
              Block
            </TableCell>
            <TableCell className={classes.tableCell} padding="none">
              Lot
            </TableCell>
            <TableCell className={classes.tableCell} padding="none">
              Qualifier
            </TableCell>
            <TableCell className={classes.tableCell} padding="none">
              Owner
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {properties.map((row, index) => (
            <TableRow
              padding="none"
              onClick={this.handleRowClick(index)}
              className={classnames(classes.row, { [classes.selectedRow]: index === selectedPropertyIndex })}
              key={index}
              data-qa={`search-results-${index}-row`}
            >
              <TableCell className={classes.leadCell} padding="none" passing="checkbox">
                <MoreButton taxLink={row.taxInfoLink} taxLinkText={TAX_LINK_TEXT} fullAddress={row.fullAddress} />
              </TableCell>
              <TableCell className={classes.tableCell} padding="none" data-qa="search-results-table-address-cell">
                {row.address || '-'}
              </TableCell>
              <TableCell className={classes.tableCell} padding="none" data-qa="search-results-table-county-cell">
                NJ
              </TableCell>
              <TableCell className={classes.tableCell} padding="none" data-qa="search-results-table-state_short-cell">
                {row.county || '-'}
              </TableCell>
              <TableCell className={classes.tableCell} padding="none" data-qa="search-results-table-city-cell">
                {row.city || '-'}
              </TableCell>
              <TableCell className={classes.tableCell} padding="none" data-qa="search-results-table-block-cell">
                {row.block || '-'}
              </TableCell>
              <TableCell className={classes.tableCell} padding="none" data-qa="search-results-table-lot-cell">
                {row.lot || '-'}
              </TableCell>
              <TableCell className={classes.tableCell} padding="none" data-qa="search-results-table-qual-cell">
                {row.qual || '-'}
              </TableCell>
              <TableCell className={classes.tableCell} padding="none" data-qa="search-results-table-owner-cell">
                {row.owner || '-'}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    )
  }
}

export default withStyles(styles)(NewJerseyResultsTable)
