import { OTHER } from '../../../../client/src/report/constants'

import { FLOOR_MATERIALS } from './materials'

export const FLOORS = [
  FLOOR_MATERIALS.CERAMIC_TILED,
  FLOOR_MATERIALS.PORCELAIN_TILED,
  FLOOR_MATERIALS.VINYL_TILED,
  FLOOR_MATERIALS.VINYL_PLANK,
  FLOOR_MATERIALS.NATURAL_STONE_TILED,
  FLOOR_MATERIALS.MARBLE_TILED,
  OTHER,
]

export const TOILETS = ['Ceramic', 'Low Profile', 'Touch-Free', OTHER]

export const SINKS = [
  'Laminate-top set in cabinet',
  'Marble-top set in cabinet',
  'Double sink set in cabinet',
  'Wall-mounted',
  OTHER,
]

export const TUBS = ['Bathtub/Shower Combo', 'Shower-only', 'Jacuzzi Tub', 'Steam Shower', OTHER]
