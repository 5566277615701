import React, { useCallback, useEffect, useRef, useState } from 'react'
import PropTypes from 'prop-types'
import { compose } from 'recompose'
import { connect } from 'react-redux'
import { get, isArray, maxBy } from 'lodash'
import { withRouter } from 'react-router'

import { HEADER_HEIGHT } from 'client-shared/layout/constants'
import { setNavigationScrolling } from 'client-shared/redux/actions/scroll'

import { formsListSelector } from '../layout/selectors'

const FormScrollController = ({ children, history, forms, navigationScrolling, setNavigationScrolling }) => {
  const [observer, setObserver] = useState(null)
  const resetScrollNavigationRef = useRef(null)
  const prevScrollPositionRef = useRef(0)
  const sectionElementsRef = useRef([])

  /*
   * It's needed to get current value of `navigationScrolling` in `handleIntersection` callback
   * "It Just Works" @ Todd Howard
   */
  const navigationScrollingRef = useRef(null)
  navigationScrollingRef.current = navigationScrolling

  useEffect(() => {
    setupScroll()
    setupObserver()

    return () => {
      cleanupObserver()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const updatePageAnchor = useCallback(
    formId => {
      if (!history?.location?.pathname) {
        return
      }

      const targetForm = forms.find(form => form.anchor === `#${formId}`)
      if (!targetForm) {
        return
      }

      history.push(history.location.pathname + (targetForm.anchor ?? ''), { skipSave: true })
    },
    [history, forms]
  )

  /*
   * On page load scrolls to anchor on a page or resets hash
   */
  const setupScroll = useCallback(() => {
    if (!isArray(forms) || !history?.location?.pathname) {
      return
    }

    const { pathname, hash } = history.location
    if (hash) {
      const elementId = hash.replace(/^#/, '')
      const sectionToScrollTo = document.getElementById(elementId)
      if (sectionToScrollTo) {
        setNavigationScrolling({ navigationScrolling: elementId })

        setTimeout(() => {
          const y = sectionToScrollTo.getBoundingClientRect().top + window.scrollY - HEADER_HEIGHT
          window.scrollTo({ top: y, behavior: 'smooth' })
        }, 0)
      } else {
        setNavigationScrolling({ navigationScrolling: null })
        history.replace(pathname)
        window.scrollTo({ top: 0, behavior: 'smooth' })
      }
    } else {
      window.scrollTo({ top: 0, behavior: 'smooth' })
    }
  }, [forms, history, setNavigationScrolling])

  const handleIntersection = useCallback(
    entries => {
      // Workaround to disable InterceptorObserver to run when `window.scrollTo` is in progress
      if (navigationScrollingRef.current) {
        const scrollTarget = entries.find(entry => entry?.target?.getAttribute('id') === navigationScrollingRef.current)
        if (scrollTarget) {
          setNavigationScrolling({ navigationScrolling: null })
        } else {
          /*
           * Workaround to restore navigation updates when target element out of reach.
           * For example, `Property Photos` or `Clients` in `Data Collection` section
           */
          clearTimeout(resetScrollNavigationRef.current)
          resetScrollNavigationRef.current = setTimeout(() => {
            setNavigationScrolling({ navigationScrolling: null })
          }, 150)
        }

        return
      }

      /*
       *  IntersectionObserver groups together events that happened recently.
       *  We are only interested in the ones, that are the most recent.
       *  Determined by value of 'time' property on IntersectionObserverEntity object
       */
      const maxTimeValue = maxBy(entries, 'time')?.time
      const latestEvents = entries.filter(entry => entry.time === maxTimeValue)

      latestEvents.forEach(entry => {
        const id = entry?.target.getAttribute('id')
        if (entry?.isIntersecting) {
          prevScrollPositionRef.current = window.scrollY
          updatePageAnchor(id)

          return
        }

        const diff = prevScrollPositionRef.current - window.scrollY
        if (diff > 0) {
          const prevSectionId =
            sectionElementsRef.current[sectionElementsRef.current.findIndex(section => section.id === id) - 1]?.id
          prevScrollPositionRef.current = window.scrollY

          updatePageAnchor(prevSectionId)
        }
      })
    },
    [setNavigationScrolling, updatePageAnchor]
  )

  const setupObserver = useCallback(() => {
    if (!history?.location?.pathname) {
      return
    }

    const formSectionsIds = forms
      .filter(form => form.path === history.location.pathname && form.anchor)
      .map(form => form.anchor.replace(/^#/i, ''))

    sectionElementsRef.current = Array.from(
      document.querySelectorAll('div[data-qa="formContainer"] [id]') || []
    ).filter(section => !/^(ag-|mui-)/i.test(section?.id) && formSectionsIds.includes(section?.id))

    if (!sectionElementsRef.current.length) {
      return
    }

    const observer = new IntersectionObserver(handleIntersection, {
      rootMargin: `-${HEADER_HEIGHT}px 0% -85% 0%`,
      threshold: [0, 1],
    })

    sectionElementsRef.current.forEach(section => {
      observer.observe(section)
    })

    setObserver(observer)
  }, [forms, handleIntersection, history?.location?.pathname])

  const cleanupObserver = useCallback(() => {
    observer?.disconnect()
    setObserver(null)
  }, [observer])

  return <>{children}</>
}

FormScrollController.propTypes = {
  forms: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.string.isRequired,
      path: PropTypes.string,
      title: PropTypes.string.isRequired,
      isActive: PropTypes.bool,
    })
  ).isRequired,
  history: PropTypes.object.isRequired,
  navigationScrolling: PropTypes.string,
  setNavigationScrolling: PropTypes.func.isRequired,
  children: PropTypes.node,
}

export default compose(
  withRouter,
  connect(
    state => ({
      navigationScrolling: get(state, 'shared.scroll.navigationScrolling'),
      forms: formsListSelector(state),
    }),
    {
      setNavigationScrolling,
    }
  )
)(FormScrollController)
