import React from 'react'
import { find } from 'lodash'
import { compose, pure } from 'recompose'
import { withStyles } from '@mui/styles'
import InfoIcon from '@mui/icons-material/InfoOutlined'
import { Typography, Link, Tooltip } from 'client-shared/components/_mui5'

import AutoCompIcon from 'report/components/AutoCompIcon'

import { EXPENSE_COMPARABLES_CATEGORIES } from '../../../../../../shared/constants/expenses/expenseComparables'

import { CompModalModes, ColumnHeaderLinkLabel } from './constants'

const styles = theme => ({
  wrapper: {
    textAlign: 'right',
  },
  childrenWrapper: {
    lineHeight: 0,
  },
  link: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'end',
    marginTop: 6,
  },
  linkLabel: {
    marginLeft: 5,
    cursor: 'pointer',
  },
})

const ColumnHeader = ({ item, openCompModal, availableEditMode, classes }) => {
  const label = find(item.expenses, { id: EXPENSE_COMPARABLES_CATEGORIES.address })?.value || 'Comparable'

  if (!availableEditMode) {
    return (
      <div className={classes.wrapper}>
        {item.isAutoComp && (
          <AutoCompIcon comp={item} alignItems="end" tooltipMessage="This Expense Comp was added automatically." />
        )}
        {label}
      </div>
    )
  }

  const showOutdatedInfo = availableEditMode === CompModalModes.REVIEW

  const icon = showOutdatedInfo ? (
    <Tooltip title="Outdated" placement="top" classes={{ childrenWrapper: classes.childrenWrapper }}>
      <InfoIcon color="error" />
    </Tooltip>
  ) : null

  const openModal = () => {
    openCompModal(availableEditMode, item)
  }

  return (
    <div className={classes.wrapper}>
      {item.isAutoComp && (
        <AutoCompIcon comp={item} alignItems="end" tooltipMessage="This Expense Comp was added automatically." />
      )}
      {label}
      <br />
      <Link onClick={openModal} classes={{ root: classes.link }}>
        {icon}
        <Typography className={classes.linkLabel}>{ColumnHeaderLinkLabel[availableEditMode]}</Typography>
      </Link>
    </div>
  )
}

export default compose(pure, withStyles(styles))(ColumnHeader)
