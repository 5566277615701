import React from 'react'
import PropTypes from 'prop-types'
import { Grid, Typography } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'
import classNames from 'classnames'

const styles = theme => ({
  root: {
    padding: theme.spacing.unit,
  },
  column: {
    fontSize: 14,
    textAlign: 'left',
  },
  columnWeight: {
    fontWeight: 500,
  },
  columnContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
  },
})

const MAX_COLUMNS_COUNT = 12

const COMPUTED_PANELS_DEFAULT_LABELS = ['Min:', 'Avg:', 'Max:']

class ComputedPanel extends React.PureComponent {
  getLabelItem = (key, label, type, styleOptions = {}) => {
    const { classes } = this.props
    const style = classNames({
      [classes.column]: true,
      [classes.columnWeight]: styleOptions.isBold,
    })
    return (
      <Grid key={`${key}-grid`} data-qa={`${key}-cell`} item xs={12}>
        <Typography key={`${key}-text`} variant={type} className={style}>
          {label}
        </Typography>
      </Grid>
    )
  }

  getFirstColumn = () => {
    const { shouldShowFirstColumn, firstColumnLabels } = this.props
    if (!shouldShowFirstColumn) {
      return null
    }
    return (
      <Grid container spacing={0} direction="column">
        {firstColumnLabels.map(label => {
          return this.getLabelItem(label, label, 'subtitle1', {
            isBold: true,
          })
        })}
      </Grid>
    )
  }

  getColumns = () => {
    const { columns, classes } = this.props
    return (
      <Grid container>
        {columns.map((column, index) => {
          return (
            <Grid
              key={`column-${index}`}
              data-qa={`${index}-column`}
              item
              xs={this.getColumnSize()}
              className={classes.columnContainer}
            >
              <Typography variant="h6" className={classes.column}>
                {column.label}
              </Typography>
              <div>
                {column.items.map((item, colIndex) => {
                  const key = `${index}${colIndex}`
                  return this.getLabelItem(key, item, 'subtitle1')
                })}
              </div>
            </Grid>
          )
        })}
      </Grid>
    )
  }

  getColumnSize = () => {
    const {
      columns: { length },
    } = this.props
    return parseInt(MAX_COLUMNS_COUNT / length, 10)
  }

  render() {
    const { classes } = this.props
    return (
      <div className={classes.root}>
        <Grid container alignItems="flex-end" data-qa="computed-panel">
          <Grid item xs={2} className={classes.column}>
            {this.getFirstColumn()}
          </Grid>
          <Grid item xs={10} className={classes.column}>
            {this.getColumns()}
          </Grid>
        </Grid>
      </div>
    )
  }
}

const StyledComputedPanel = withStyles(styles)(ComputedPanel)

StyledComputedPanel.displayName = 'ComputedPanel'

StyledComputedPanel.propTypes = {
  columns: PropTypes.arrayOf(
    PropTypes.shape({
      items: PropTypes.arrayOf(PropTypes.string.isRequired).isRequired,
      label: PropTypes.string.isRequired,
    }).isRequired
  ).isRequired,
  firstColumnLabels: PropTypes.arrayOf(PropTypes.string.isRequired),
  shouldShowFirstColumn: PropTypes.bool,
}

StyledComputedPanel.defaultProps = {
  firstColumnLabels: COMPUTED_PANELS_DEFAULT_LABELS,
  shouldShowFirstColumn: true,
}

export default StyledComputedPanel
