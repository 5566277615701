import { get } from 'lodash'

import { toPercentageString } from 'shared/utils/formatters/numberFormatters'

import {
  mapDTO as occupancyRateDiscussionMapDTO,
  mapDataModel as occupancyRateDiscussionMapDataModel,
} from '../../residential/occupancyRateDiscussion/mappings'

type FormValues = {
  costarSubmarketRate: number
  costarMetroRate: number
  residentialVCLossPercentage: number
  address: string
  residentialUnits: { leaseStatus: string; rentType: string }[]
  valueAsStabilized: boolean
  propertyType: string
}

type Report = {
  residential: {
    units: { leaseStatus: string; rentType: string }[]
  }
  valueAsStabilized: boolean
  // eslint-disable-next-line camelcase
  property_information: {
    address: string
    type: string
  }
  // eslint-disable-next-line camelcase
  potential_gross_income: {
    residentialVcCoStarSubmarket: number
    residentialVcCoStarMetro: number
    residentialVc: number
  }
}

type NarrativeValues = {
  occupancyRate: string
  occupancyRateIs0: boolean
  vacantUnitSentence: string
  byRentTypeSentence: string | null
  submarketRate: number
  metroRate: number
  residentialVCLossPercentage: string
  address: string
}

export const mapDTO = (formValues: FormValues): NarrativeValues => {
  const {
    costarSubmarketRate,
    costarMetroRate,
    residentialVCLossPercentage,
    address,
    residentialUnits,
    valueAsStabilized,
    propertyType,
  } = formValues

  const occupancyRateDiscussionValues = occupancyRateDiscussionMapDTO({
    residentialUnits,
    valueAsStabilized,
    propertyType,
  })
  return {
    ...occupancyRateDiscussionValues,
    address,
    submarketRate: costarSubmarketRate,
    metroRate: costarMetroRate,
    residentialVCLossPercentage: toPercentageString(residentialVCLossPercentage),
  }
}

export const mapDataModel = (report: Report): NarrativeValues => {
  const { residentialVcCoStarSubmarket, residentialVcCoStarMetro, residentialVc } =
    get(report, 'potential_gross_income') || {}
  const { address } = get(report, 'property_information') || {}
  const occupancyRateDiscussionValues = occupancyRateDiscussionMapDataModel(report)

  return {
    ...occupancyRateDiscussionValues,
    address,
    submarketRate: residentialVcCoStarSubmarket,
    metroRate: residentialVcCoStarMetro,
    residentialVCLossPercentage: toPercentageString(residentialVc),
  }
}
