import React from 'react'
import PropTypes from 'prop-types'

import { Button, Grid, Paper, Table, TableBody, TableCell, TableHead, TableRow } from '@mui/material'
import { get, find } from 'lodash'
import Close from '@mui/icons-material/Close'
import Check from '@mui/icons-material/Check'
import SettingsRounded from '@mui/icons-material/SettingsRounded'

import { connect } from 'react-redux'

import {
  organizationUsersDelete,
  organizationUsersFetch,
  organizationUsersClear,
  organizationUsersUpdate,
} from '../../../redux/actions/organization'

import FormHeader from '../../../../shared/components/FormHeader'

import RemoveUserModalDialog from '../../../components/RemoveUserModalDialog'

import { getRoleIdsByConstant, getRoleOptions } from '../../../../shared/utils/roles'

import UsersModal from './EditUserModal'

const COLUMNS = [
  { name: 'number', title: '#' },
  { name: 'fullName', title: 'Name' },
  { name: 'email', title: 'Email' },
  { name: 'roles', title: 'Roles' },
  { name: 'activated', title: 'Activated' },
  { name: 'actions', title: 'Actions' },
]

export const DATA_PATH = 'organizationUsers'
const heading = 'Organization Users'

class OrganizationUsers extends React.PureComponent {
  state = {
    isOpenModal: false,
    isRemoveModalOpened: false,
    modalUser: null,
  }

  componentDidMount() {
    if (!this.props.usersLoaded) {
      this.props.organizationUsersFetch()
    }
  }

  componentWillUnmount() {
    this.props.organizationUsersClear()
  }

  openModal = modalUser => event => {
    this.setState({
      isOpenModal: true,
      modalUser,
    })
  }

  closeModal = () => {
    this.setState({
      isOpenModal: false,
      modalUser: {},
    })
  }

  onRemoveUserConfirm = () => {
    const { modalUser: user } = this.state
    this.props.organizationUsersDelete(user?._id)
    this.closeRemoveUserModal()
  }

  openRemoveUserModal = modalUser => event => {
    this.setState({
      isRemoveModalOpened: true,
      modalUser,
    })
  }

  closeRemoveUserModal = () => {
    this.setState({
      isRemoveModalOpened: false,
      modalUser: {},
    })
  }

  render() {
    const { organizationUsers, canEdit, roleTitles } = this.props
    const { isOpenModal, isRemoveModalOpened, isEdit, modalUser } = this.state
    return (
      <Grid container>
        <Grid item xs={12}>
          <FormHeader title={heading} icon={<SettingsRounded />} section={this.props.section} />
        </Grid>
        <Grid item xs={12}>
          <Paper>
            <Table size="small">
              <TableHead sx={{ '& .MuiTableCell-head': { fontSize: 12, fontWeight: 700 } }}>
                <TableRow>
                  {COLUMNS.map(column => (
                    <TableCell key={column.name}>{column.title}</TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody sx={{ '& .MuiTableCell-root': { fontSize: 13 } }}>
                {organizationUsers.map((user, index) => {
                  return (
                    <TableRow key={user.username}>
                      <TableCell>{index + 1}</TableCell>
                      <TableCell>{user.fullName}</TableCell>
                      <TableCell>{user.username}</TableCell>
                      <TableCell>
                        {user.roles.map(item => find(roleTitles, { value: item })?.label).join(', ')}
                      </TableCell>
                      <TableCell>{user.activated ? <Check /> : <Close />}</TableCell>
                      {canEdit && (
                        <TableCell>
                          <Button onClick={this.openModal(user)}>Edit</Button>
                          <Button onClick={this.openRemoveUserModal(user)}>Delete</Button>
                        </TableCell>
                      )}
                    </TableRow>
                  )
                })}
              </TableBody>
            </Table>
            {isRemoveModalOpened && (
              <RemoveUserModalDialog onConfirm={this.onRemoveUserConfirm} onCancel={this.closeRemoveUserModal} />
            )}
            {isOpenModal && <UsersModal isEdit={isEdit} user={modalUser} handleClose={this.closeModal} />}
          </Paper>
        </Grid>
      </Grid>
    )
  }
}

OrganizationUsers.propTypes = {
  organizationUsers: PropTypes.arrayOf(
    PropTypes.shape({
      _id: PropTypes.string.isRequired,
      roles: PropTypes.array.isRequired,
      username: PropTypes.string.isRequired,
      fullName: PropTypes.string.isRequired,
      activated: PropTypes.bool.isRequired,
    })
  ),
  organizationUsersFetch: PropTypes.func.isRequired,
  organizationUsersUpdate: PropTypes.func.isRequired,
  organizationUsersClear: PropTypes.func.isRequired,
  organizationUsersDelete: PropTypes.func.isRequired,
}

OrganizationUsers.defaultProps = {
  organizationUsers: [],
}

const mapStateToProps = state => {
  const user = get(state, 'authentication.user', {})
  const roles = get(state, 'authentication.roles', [])
  const rolesByConstant = getRoleIdsByConstant(roles)
  const roleTitles = getRoleOptions(roles)
  const userRoles = user.roles || []
  const { usersLoaded, organizationUsers } = get(state, 'organization', {})

  return {
    organizationUsers,
    usersLoaded,
    user,
    roleTitles,
    formValues: user,
    canEdit: userRoles.some(role => role === rolesByConstant.ADMIN || role === rolesByConstant.LEAD_APPRAISER),
  }
}

export default connect(mapStateToProps, {
  organizationUsersDelete,
  organizationUsersFetch,
  organizationUsersClear,
  organizationUsersUpdate,
})(OrganizationUsers)
