import { handleActions } from 'redux-actions'

import { locationSet } from '../actions/location'

const initialState = {
  form: '',
  hasLogo: true,
}

export const reducer = handleActions(
  {
    [locationSet](state, other) {
      const { payload } = other

      return {
        ...state,
        form: payload.form,
        hasLogo: payload.hasLogo,
      }
    },
  },
  initialState
)
