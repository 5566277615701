import React from 'react'
import { FormSpy } from 'react-final-form'

const withFormApi = Component => props => {
  return (
    <FormSpy subscription={{}}>
      {({ form }) => {
        return <Component form={form} {...props} />
      }}
    </FormSpy>
  )
}

export default withFormApi
