import BoweryDate from '@bowery-valuation/bowery-date'
import { camelCase, toLower, replace } from 'lodash'

export const getExpensePeriodDate = (expenseMonth, expenseYear) => {
  if (!expenseMonth || !expenseYear) {
    return null
  }
  return new BoweryDate(`${expenseYear}-${expenseMonth}-1`).endOf('month').toString()
}

export const formatId = name => {
  return camelCase(toLower(replace(name, '&', 'And')))
}
