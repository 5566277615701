import createDecorator from 'final-form-calculate'
import { get } from 'lodash'

import { getDepreciationRowDefinitions } from 'shared/constants/costApproach'

export const updateTotalDepreciation = (key, value, name, allValues) => {
  const physical = get(allValues, getDepreciationRowDefinitions(key).physical.fieldPath, 0)
  const externalObsolescence = get(allValues, getDepreciationRowDefinitions(key).externalObsolescence.fieldPath, 0)
  const functionalObsolescence = get(allValues, getDepreciationRowDefinitions(key).functionalObsolescence.fieldPath, 0)
  const totalDepreciation = physical + externalObsolescence + functionalObsolescence
  const fieldToUpdate = `depreciation.${key}.totalDepreciation`
  return {
    [fieldToUpdate]: totalDepreciation,
  }
}

const createDepreciationDecorators = key => {
  return [
    createDecorator({
      field: `depreciation.${key}.physical`,
      updates: (value, name, allValues) => {
        return updateTotalDepreciation(key, value, name, allValues)
      },
    }),
    createDecorator({
      field: `depreciation.${key}.externalObsolescence`,
      updates: (value, name, allValues) => {
        return updateTotalDepreciation(key, value, name, allValues)
      },
    }),

    createDecorator({
      field: `depreciation.${key}.functionalObsolescence`,
      updates: (value, name, allValues) => {
        return updateTotalDepreciation(key, value, name, allValues)
      },
    }),
  ]
}

const depreciationTotals = [
  ...createDepreciationDecorators('replacementCostDepreciations[0]'),
  ...createDepreciationDecorators('siteImprovementDepreciation'),
]

export default [...depreciationTotals]
