import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import { Button as BaseMaterialUIButton, FormHelperText } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'

const styles = theme => ({
  button: {
    margin: theme.spacing.unit,
    fontWeight: 500,
    lineHeight: '24px',
  },
  label: {
    '& svg': {
      marginRight: 5,
    },
  },
  roundButton: {
    borderRadius: 30,
    paddingLeft: 15,
    paddingRight: 15,
  },
  wideButton: {
    paddingLeft: theme.spacing.unit * 2,
    paddingRight: theme.spacing.unit * 2,
  },
  error: {
    backgroundColor: theme.palette.error[500],
    '&:hover': {
      backgroundColor: theme.palette.error[700],
    },
  },
  accent: {
    backgroundColor: theme.palette.accent[500],
    '&:hover': {
      backgroundColor: theme.palette.accent[700],
    },
  },
  helperTextContainer: {
    paddingBottom: theme.spacing.unit * 2,
  },
})

const Button = ({ classes, children, className, wide, color = '', round = false, helperText, disabled, ...props }) => {
  const colorClass = classes[color]
  return (
    <React.Fragment>
      <BaseMaterialUIButton
        disabled={disabled}
        size="small"
        color="primary"
        className={classnames([
          classes.button,
          className,
          colorClass,
          {
            [classes.roundButton]: round,
            [classes.wideButton]: wide,
          },
        ])}
        classes={{ label: classes.label }}
        {...props}
      >
        {children}
      </BaseMaterialUIButton>
      {helperText && (
        <FormHelperText
          classes={{
            root: classes.root,
          }}
        >
          <span className={classes.helperTextContainer}>{helperText}</span>
        </FormHelperText>
      )}
    </React.Fragment>
  )
}

const ButtonWithStyles = withStyles(styles, { name: 'Button' })(Button)

ButtonWithStyles.displayName = 'Button'

ButtonWithStyles.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.element, PropTypes.string])),
    PropTypes.element,
    PropTypes.string,
  ]).isRequired,
  disabled: PropTypes.bool,
  round: PropTypes.bool,
  className: PropTypes.string,
  color: PropTypes.string,
  wide: PropTypes.bool,
  variant: PropTypes.oneOf(['text', 'outlined', 'contained']),
  helperText: PropTypes.string,
}

ButtonWithStyles.defaultProps = {
  variant: 'text',
  disabled: false,
}

export default ButtonWithStyles
