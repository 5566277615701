/* eslint-disable max-len */
import { GeneratedText, gtLocked, gtTemplate, gtIfElse } from '../../../../narrative/GeneratedText'

import { mapDTO, mapDataModel } from '../mappings'

const generate = () => {
  const gt = new GeneratedText()
  const asCompleteYearGt = gtLocked('asCompleteYear', null, 'AS COMPLETE YEAR')

  gt.t`${gtIfElse(
    'includeDepreciation',
    gtTemplate`The following table summarizes our estimates at physical depreciation via the modified age-life method.`,
    gtTemplate`The subject is a newly built development completed to be completed in ${asCompleteYearGt} and thus we have assumed no physical deterioration.`
  )}`

  return gt.getParts()
}

export default { generate, mapDTO, mapDataModel }
