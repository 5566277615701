import React from 'react'
import { makeStyles } from '@mui/styles'
import classNames from 'classnames'

import { Theme } from 'core/theme'

const useStyles = makeStyles<Theme>(theme => {
  const shadowStyle = {
    boxShadow: '0px 0px 4px rgba(0, 15, 68, 0.12)',
    content: '""',
    position: 'absolute',
    left: 0,
    top: 0,
    right: 0,
    bottom: 0,
    zIndex: -1,
  }
  const borderRadius = 4
  const padding = 16

  return {
    card: {
      background: theme.palette.common.white,
      position: 'relative',
      borderRadius: 0, // MUI override
      paddingLeft: padding,
      paddingRight: padding,

      '&::before': shadowStyle,
      '&:first-of-type': {
        borderTopLeftRadius: borderRadius,
        borderTopRightRadius: borderRadius,
      },
      '&:first-of-type::before': {
        ...shadowStyle,
        borderTopLeftRadius: borderRadius,
        borderTopRightRadius: borderRadius,
      },
      '&:last-of-type': {
        borderBottomLeftRadius: borderRadius,
        borderBottomRightRadius: borderRadius,
      },
      '&:last-of-type::before': {
        ...shadowStyle,
        borderBottomLeftRadius: borderRadius,
        borderBottomRightRadius: borderRadius,
      },
    },

    cardNotPadded: {
      padding: 0,
    },
  }
})

type CardProps = {
  children: React.ReactNode
  className?: string
  padded?: boolean
}

// TODO: implement this using Card of MUI5
const Card: React.FC<CardProps> = ({ children, className, padded = true }) => {
  const classes = useStyles()
  return (
    <div className={classNames(className, { [classes.card]: !!padded }, { [classes.cardNotPadded]: !padded })}>
      {children}
    </div>
  )
}

export default React.memo(Card)
