import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'

import { TableCell, TableRow } from '@mui/material'

import { columnShape } from './propTypes'

export class TableHeadRow extends React.PureComponent {
  static propTypes = {
    columns: PropTypes.arrayOf(columnShape).isRequired,
    getCellContent: PropTypes.func,
    rowClass: PropTypes.string,
    cellClass: PropTypes.string,
    title: PropTypes.string,
    padding: PropTypes.string,
  }

  static defaultProps = {
    padding: 'dense',
  }

  renderCellContent = (column, restProps) => {
    const { getCellContent } = this.props

    if (getCellContent) {
      return getCellContent({ column, ...restProps })
    }

    return column.title
  }

  render() {
    const { columns, getCellContent, rowClass, cellClass, ...restProps } = this.props

    const CellComponent = TableCell

    return (
      <TableRow className={rowClass}>
        {columns.map(column => (
          <CellComponent
            align={column.align}
            key={column.name}
            className={classnames(cellClass, column.className)}
            style={{ width: column.width }}
            {...restProps}
          >
            {this.renderCellContent(column, restProps)}
          </CellComponent>
        ))}
      </TableRow>
    )
  }
}
