import { GeneratedText, gtConditional, gtIfElse, gtLocked, gtTemplate } from 'shared/utils/narrative/GeneratedText'

import { mapDTO, mapDataModel } from './mappings'

const getStabilizationLevelGeneratedText = () => {
  const stabilizationLevelPercents = gtLocked('stabilizationLevelPercents')
  const residentialUnitCount = gtLocked('residentialUnitCount')
  const numberOfRentStabilizedUnits = gtLocked('numberOfRentStabilizedUnits')
  const rentStabilizedUnitsArticle = gtLocked('rentStabilizedUnitsArticle')
  const downwardCompsSentence = gtLocked('downwardCompsSentence')
  const upwardCompsSentence = gtLocked('upwardCompsSentence')

  const downwardsMultiples = gtConditional('hasMultipleDownwardAdjustments', gtTemplate`s`)
  const downwardsAdjustmentSentence = gtConditional(
    'hasDownwardAdjustments',
    gtTemplate` Comparable${downwardsMultiples} ${downwardCompsSentence} ${gtIfElse(
      'hasMultipleDownwardAdjustments',
      gtTemplate`have`,
      gtTemplate`has a`
    )} lower level${downwardsMultiples} of rent stabilization, and thus required${gtIfElse(
      'hasMultipleDownwardAdjustments',
      gtTemplate``,
      gtTemplate` a`
    )} downward adjustment${downwardsMultiples}.`
  )

  const upwardsMultiples = gtConditional('hasMultipleUpwardAdjustments', gtTemplate`s`)

  const upwardsAdjustmentSentence = gtConditional(
    'hasUpwardAdjustments',
    gtTemplate` Comparable${upwardsMultiples} ${upwardCompsSentence} ${gtIfElse(
      'hasMultipleUpwardAdjustments',
      gtTemplate`have`,
      gtTemplate`has a`
    )} higher level${upwardsMultiples} of rent stabilization, and thus required${gtIfElse(
      'hasMultipleUpwardAdjustments',
      gtTemplate``,
      gtTemplate` an`
    )} upward adjustment${upwardsMultiples}.`
  )
  const residentialUnitCountSentence = gtIfElse(
    'containsOneResidentialUnit',
    gtTemplate` the 1 residential unit is rent stabilized.`,
    gtTemplate` of the ${residentialUnitCount} residential units, ${gtIfElse(
      'allRentStabilizedUnits',
      gtTemplate`all are`,
      gtTemplate`${numberOfRentStabilizedUnits} ${rentStabilizedUnitsArticle}`
    )} rent stabilized.`
  )
  const stabilizedUnitsSentence = gtTemplate`${gtIfElse(
    'hasNoStabilizedUnits',
    gtTemplate`The subject is not rent stabilized.`,
    gtTemplate`The subject is ${stabilizationLevelPercents} rent stabilized, 
    ${residentialUnitCountSentence}`
  )}${downwardsAdjustmentSentence}${upwardsAdjustmentSentence}`

  const noStabilizedUnitsSentence = gtIfElse(
    'hasNoStabilizedUnits',
    gtTemplate`The subject is not rent stabilized.  No adjustments were necessary as the 
    comparables have similar rent stabilization levels.`,
    gtTemplate`The subject is ${stabilizationLevelPercents} rent stabilized, ${gtIfElse(
      'containsOneResidentialUnit',
      gtTemplate`the 1 residential unit is`,
      gtTemplate`of the ${residentialUnitCount} residential units, ${gtIfElse(
        'allRentStabilizedUnits',
        gtTemplate`all are`,
        gtTemplate`${numberOfRentStabilizedUnits} ${rentStabilizedUnitsArticle}`
      )}`
    )} rent stabilized. No adjustments were necessary as the comparables have similar rent stabilization levels.`
  )

  const writeUpSentence = gtIfElse(
    'shouldGenerateWriteup',
    gtTemplate`${stabilizedUnitsSentence}`,
    gtTemplate`${noStabilizedUnitsSentence}`
  )

  return writeUpSentence
}

const getIncomeLevelGeneratedText = () => {
  const temperPercentage = gtLocked('temperPercentage')
  const netOperatingIncome = gtLocked('netOperatingIncome')
  const type = gtLocked('type')

  const upwardsAdjustmentSentence = gtConditional(
    'hasUpwardNoiAdjustments',
    gtTemplate`We have compared the subject's NOI with the comparables, 
    and then adjusted properties upward based on a ${temperPercentage} 
    factor to account for the value-add nature of investing in higher 
    NOI buildings, and the possibility of net income spikes due to de-stabilization. 
    The subject's NOI was calculated to be $${netOperatingIncome} per ${type}.`
  )
  const downwardsAdjustmentSentence = gtConditional(
    'hasDownwardNoiAdjustments',
    gtTemplate`We have compared the subject's NOI with the comparables, 
    and then adjusted properties downward based on a ${temperPercentage} 
    factor to account for the value-add nature of investing in lower NOI 
    buildings and the possibility of net income spikes due to de-stabilization. 
    The subject's NOI was calculated to be $${netOperatingIncome} per ${type}.`
  )
  const hasAdjustmentsSentence = gtTemplate`${upwardsAdjustmentSentence}${downwardsAdjustmentSentence}`

  const hasNoAdjustmentsSentence = gtTemplate`We have compared the subject's NOI with the comparables, 
  and then adjusted properties upward/downward based on a ${temperPercentage} factor to 
  account for the value-add nature of investing in higher/lower NOI buildings, and the possibility of net 
  income spikes due to de-stabilization. The subject's NOI was calculated to be $${netOperatingIncome} per ${type}.`

  return gtIfElse('hasNoiAdjustments', hasAdjustmentsSentence, hasNoAdjustmentsSentence)
}

const generate = () => {
  const rentStabilization = gtConditional('rentStabilizationDiscussion', getStabilizationLevelGeneratedText())
  const incomeLevel = gtConditional('incomeLevelDiscussion', getIncomeLevelGeneratedText())

  const gt = new GeneratedText()

  gt.t`${rentStabilization}${incomeLevel}`

  return gt.getParts()
}

export default { generate, mapDTO, mapDataModel }
