// Breaks dependency cycle
let history = null

export function initHistory(_history) {
  history = _history
}

export function getHistory() {
  if (history === null) {
    throw new Error('history not initialized.')
  }
  return history
}
