import React from 'react'
import ReactDOM from 'react-dom'
import PropTypes from 'prop-types'

import { compose } from 'recompose'
import { debounce } from 'lodash'
import { Field } from 'react-final-form'
import { withStyles } from '@mui/styles'

import asyncLoading from 'react-async-loader'

const styles = {
  streetViewContainer: {
    width: '100%',
    height: '450px',
    margin: '16px auto 0px auto',
    backgroundColor: '#eeeeee',
  },
}

/**
 * This code was borrowed
 * https://github.com/elcsiga/react-streetview/blob/master/src/ReactStreetview.js
 */
class ReactStreetView extends React.PureComponent {
  static propTypes = {
    coordinates: PropTypes.shape({
      latitude: PropTypes.number.isRequired,
      longitude: PropTypes.number.isRequired,
    }).isRequired,
    googleMaps: PropTypes.object.isRequired,
    withControls: PropTypes.bool,
    pov: PropTypes.shape({ heading: PropTypes.number, pitch: PropTypes.number }),
    zoom: PropTypes.number,
    onPovChange: PropTypes.func,
  }

  static defaultProps = {
    withControls: true,
    pov: { heading: 100, pitch: 0 },
    zoom: 1,
  }

  constructor() {
    super()

    this.state = {
      streetView: null,
    }
  }

  getStreetViewPanoramaOptions = (isInitialSet = false) => {
    const { withControls } = this.props

    const options = {
      addressControl: withControls,
      fullscreenControl: withControls,
      zoomControl: withControls,
      panControl: withControls,
      linksControl: withControls,
    }

    if (isInitialSet) {
      const { coordinates, zoom, pov } = this.props
      options.position = { lat: coordinates.latitude, lng: coordinates.longitude }
      options.zoom = zoom
      options.pov = pov
    }

    return options
  }

  handlePovChange = () => {
    const { streetView } = this.state
    const { onPovChange } = this.props
    if (onPovChange && streetView) {
      onPovChange({
        location: streetView.getLocation(),
        pov: streetView.getPov(),
        zoom: streetView.getZoom(),
      })
    }
  }

  initialize(canvas) {
    if (this.props.googleMaps && this.state.streetView === null) {
      const streetViewPanoramaOptions = this.getStreetViewPanoramaOptions(true)
      const streetView = new this.props.googleMaps.StreetViewPanorama(canvas, streetViewPanoramaOptions)

      const { coordinates } = this.props
      const streetViewService = new this.props.googleMaps.StreetViewService()
      streetViewService
        .getPanorama({
          location: { lat: coordinates.latitude, lng: coordinates.longitude },
          preference: 'nearest',
          radius: 1000,
          source: 'outdoor',
        })
        .then(({ data }) => {
          const location = data.location

          streetView.setPano(location.pano)
          streetView.setPov({
            heading: 270,
            pitch: 0,
          })
          streetView.setVisible(true)
        })
        .catch(error => console.error('Street View data not found for this location.'))

      this.setState({ streetView })
      if (this.props.onPovChange) {
        this.props.googleMaps.event.addListener(streetView, 'pov_changed', debounce(this.handlePovChange, 500))
      }
    }
  }

  componentDidMount() {
    this.initialize(ReactDOM.findDOMNode(this))
  }

  componentDidUpdate(prevProps) {
    if (prevProps.withControls !== this.props.withControls) {
      const { streetView } = this.state

      const currentPov = streetView.getPov()
      const currentPosition = streetView.getPosition()

      const newOptions = this.getStreetViewPanoramaOptions()
      streetView.setOptions(newOptions)
      streetView.setPov(currentPov)
      streetView.setPosition(currentPosition)
    }
  }

  componentWillUnmount() {
    if (this.state.streetView) {
      this.props.googleMaps.event.clearInstanceListeners(this.state.streetView)
    }
  }

  render() {
    return <div style={{ height: '100%' }} />
  }
}

const StreetViewWrapper = ({ classes, streetViewRef, ...props }) => (
  <div className={classes.streetViewContainer} ref={streetViewRef}>
    <ReactStreetView {...props} />
  </div>
)

const mapScriptsToProps = () => {
  const googleMapsApiKey = global.env.googleApiKey
  return {
    googleMaps: {
      globalPath: 'google.maps',
      url: `https://maps.googleapis.com/maps/api/js?v=${global.env.googleMapsVersion}&key=${googleMapsApiKey}`,
      jsonp: true,
    },
  }
}

const StyledStreetViewWrapper = compose(asyncLoading(mapScriptsToProps), withStyles(styles))(StreetViewWrapper)

export default function StreetView({ name, coordinates, ...props }) {
  return <Field name={name} component={StyledStreetViewWrapper} coordinates={coordinates} {...props} />
}

StreetView.propTypes = {
  name: PropTypes.string.isRequired,
  coordinates: PropTypes.shape({
    latitude: PropTypes.number.isRequired,
    longitude: PropTypes.number.isRequired,
  }).isRequired,
}
