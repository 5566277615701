import React from 'react'
import PropTypes from 'prop-types'
import { get, noop } from 'lodash'
import { Grid, Typography } from '@mui/material'
import { withStyles } from '@material-ui/core'
import { ColumnDataTypeEnum, RowBasedTable } from '@bowery-valuation/ui-components'
import { FeatureToggle } from '@bowery-valuation/feature-flagger-client'

import { ENABLE_RES_RENT_RECONCILIATION_AUTOMATION } from 'shared/constants/featureFlags'
import marketRentDiscussion from 'shared/utils/textGeneration/incomeApproach/residential/marketRentDiscussion'

import AutomationStatus from 'client-shared/components/AutomationStatus'

import MultipleDropDown from '../../../../../../shared/components/DropDown/MultipleDropDownField'
import {
  Callout,
  Number,
  DropDown,
  GeneratedComment,
  ButtonSwitch,
  CheckboxWithLabel,
  NarrativeComponent,
} from '../../../../../../shared/components'

import { formatCurrencyFloat } from '../../../../../../shared/utils/numberFormatters'
import { arrayToKeyValuePairs } from '../../../../../../shared/utils/ui/checkboxHelper'
import { TIME_PERIODS } from '../../../../../../../../shared/constants/report/incomeApproach'

import { MarketConclusionUnits, RentTypes } from '../../../../../constants'

import {
  Labels,
  MARKET_BREAKDOWN_OPTIONS,
  UNIT_CONDITIONS,
  SF_CONDITIONS,
  Conditions,
  LOCATION_DETAILS_OPTIONS,
  FINISHES_DETAILS_OPTIONS,
  AMENITIES_DETAILS_OPTIONS,
  GENERATED_TEXT_TOOLTIP,
} from '../constants'

import {
  generateLocationDiscussionCommentary,
  generateSizeDiscussionCommentary,
  generateConditionDiscussionCommentary,
  generateFinishesDiscussionCommentary,
  generateAmenitiesDiscussionCommentary,
} from '../writeupHelper'

const styles = theme => ({
  warning: {
    backgroundColor: theme.palette.primary[900],
    color: 'white',
  },
  checkBoxRoot: {
    height: 48,
  },
  checkBoxFormControlLabel: {
    marginTop: 0,
  },
  title: {
    margin: '5px 0 10px',
  },
})

const unitConditionOptions = arrayToKeyValuePairs(UNIT_CONDITIONS)
const SFConditionOptions = arrayToKeyValuePairs(SF_CONDITIONS)

const StatsPropType = PropTypes.shape({
  min: PropTypes.number,
  max: PropTypes.number,
  avg: PropTypes.number,
})

class ResidentialRentReconciliationItem extends React.PureComponent {
  static propTypes = {
    classes: PropTypes.object.isRequired,
    unitStats: StatsPropType.isRequired,
    developersUnitStats: StatsPropType.isRequired,
    compStats: StatsPropType.isRequired,
    conclusionPerSFAvailability: PropTypes.shape({
      isDisabled: PropTypes.bool.isRequired,
      reasons: PropTypes.arrayOf(PropTypes.string).isRequired,
    }).isRequired,
    fieldNamePrefix: PropTypes.string.isRequired,
    formValues: PropTypes.shape({
      rentReconciliationGroups: PropTypes.array,
      includePSFAnalysis: PropTypes.bool.isRequired,
    }),
    showMarketOrientationWarning: PropTypes.bool.isRequired,
  }

  static defaultProps = {
    includeDetails: false,
  }

  getRentReconciliationGroup = () => {
    const { formValues, fieldNamePrefix } = this.props
    return get(formValues, fieldNamePrefix)
  }

  getRentPSFLabel = unitRentPSFTimePeriod => {
    if (unitRentPSFTimePeriod === TIME_PERIODS.ANNUALLY) {
      return Labels.PER_SF_PER_YEAR
    }

    return Labels.PER_SF_PER_MONTH
  }

  getMarketConclusionUnitOptions = (conclusionPerSFAvailability, unitRentPSFTimePeriod) => {
    return [
      {
        label: Labels.PER_MONTH,
        value: MarketConclusionUnits.UNIT,
      },
      {
        label: this.getRentPSFLabel(unitRentPSFTimePeriod),
        value: MarketConclusionUnits.SF,
        disabled: conclusionPerSFAvailability.isDisabled,
      },
    ]
  }

  getConditionOptions = marketConclusionUnit => {
    return marketConclusionUnit === MarketConclusionUnits.SF ? SFConditionOptions : unitConditionOptions
  }

  renderLocationDiscussion = () => {
    const { classes, fieldNamePrefix } = this.props
    const { location } = this.getRentReconciliationGroup()

    const { general } = location
    const shouldShowLocationDiscussion = general && general !== Conditions.DO_NOT_DISCUSS
    const locationLabel = `${Labels.THE_SUBJECT_IS} "${general}" ${Labels.AS_COMPARED_TO_COMPS_IN_THE_FOLLOWING_WAYS}`

    return (
      <React.Fragment>
        <Typography variant="h6" className={classes.title}>
          Location
        </Typography>
        <DropDown
          name={`${fieldNamePrefix}.location.general`}
          label={Labels.HOW_DOES_SUBJECT_COMPARE_TO_RENT_COMPS_IN_TERMS_OF_LOCATION}
          placeholder={Labels.MARKET_COMPARISON_TO_APPLY_PLACEHOLDER}
          items={unitConditionOptions}
        />
        {shouldShowLocationDiscussion && (
          <React.Fragment>
            <Typography variant="subtitle1">{Labels.LOCATIONS_DETAILS_OPTIONAL}</Typography>
            <MultipleDropDown
              name={`${fieldNamePrefix}.location.details`}
              label={locationLabel}
              items={LOCATION_DETAILS_OPTIONS}
            />
            <GeneratedComment
              isDynamicContent
              label="Location Discussion"
              title="Generated commentary"
              tooltipText={GENERATED_TEXT_TOOLTIP}
              dataPath={`${fieldNamePrefix}.locationDiscussion`}
              getGeneratedText={this.generateLocationDiscussion}
            />
          </React.Fragment>
        )}
      </React.Fragment>
    )
  }

  renderSizeDiscussion = () => {
    const { classes, fieldNamePrefix } = this.props
    const { size, marketConclusionUnit } = this.getRentReconciliationGroup()

    const { general } = size
    const shouldShowSizeDiscussion = general && general !== Conditions.DO_NOT_DISCUSS

    return (
      <React.Fragment>
        <Typography variant="h6" className={classes.title}>
          Size
        </Typography>
        <DropDown
          name={`${fieldNamePrefix}.size.general`}
          label={Labels.HOW_DOES_SUBJECT_COMPARE_TO_RENT_COMPS_IN_TERMS_OF_SIZE}
          placeholder={Labels.MARKET_COMPARISON_TO_APPLY_PLACEHOLDER}
          items={this.getConditionOptions(marketConclusionUnit)}
        />
        {shouldShowSizeDiscussion && (
          <GeneratedComment
            isDynamicContent
            label="Size Discussion"
            title="Generated commentary"
            tooltipText={GENERATED_TEXT_TOOLTIP}
            dataPath={`${fieldNamePrefix}.sizeDiscussion`}
            getGeneratedText={this.generateSizeDiscussion}
          />
        )}
      </React.Fragment>
    )
  }

  renderBuildingConditionDiscussion = () => {
    const { classes, fieldNamePrefix } = this.props
    const { condition } = this.getRentReconciliationGroup()

    const { general } = condition
    const shouldShowConditionDiscussion = general && general !== Conditions.DO_NOT_DISCUSS

    return (
      <React.Fragment>
        <Typography variant="h6" className={classes.title}>
          Condition
        </Typography>
        <DropDown
          name={`${fieldNamePrefix}.condition.general`}
          label={Labels.HOW_DOES_SUBJECT_COMPARE_TO_RENT_COMPS_IN_TERMS_OF_BUILD_COND}
          placeholder={Labels.MARKET_COMPARISON_TO_APPLY_PLACEHOLDER}
          items={unitConditionOptions}
        />
        {shouldShowConditionDiscussion && (
          <GeneratedComment
            isDynamicContent
            label="Condition Discussion"
            title="Generated commentary"
            tooltipText={GENERATED_TEXT_TOOLTIP}
            dataPath={`${fieldNamePrefix}.conditionDiscussion`}
            getGeneratedText={this.generateConditionDiscussion}
          />
        )}
      </React.Fragment>
    )
  }

  renderFinishesDiscussion = () => {
    const { classes, fieldNamePrefix } = this.props
    const { finishes } = this.getRentReconciliationGroup()

    const { general } = finishes
    const shouldShowFinishesDiscussion = general && general !== Conditions.DO_NOT_DISCUSS
    const finishesLabel = `${Labels.THE_SUBJECT_IS} "${general}" ${Labels.AS_COMPARED_TO_COMPS_IN_THE_FOLLOWING_WAYS}`

    return (
      <React.Fragment>
        <Typography variant="h6" className={classes.title}>
          Finishes
        </Typography>
        <DropDown
          name={`${fieldNamePrefix}.finishes.general`}
          label={Labels.HOW_DOES_SUBJECT_COMPARE_TO_RENT_COMPS_IN_TERMS_OF_FINISHES}
          placeholder={Labels.MARKET_COMPARISON_TO_APPLY_PLACEHOLDER}
          items={unitConditionOptions}
        />

        {shouldShowFinishesDiscussion && (
          <React.Fragment>
            <Typography variant="subtitle1">{Labels.FINISHES_DETAILS_OPTIONAL}</Typography>
            <MultipleDropDown
              name={`${fieldNamePrefix}.finishes.details`}
              label={finishesLabel}
              items={FINISHES_DETAILS_OPTIONS}
            />

            <GeneratedComment
              isDynamicContent
              label="Finishes Discussion"
              title="Generated commentary"
              tooltipText={GENERATED_TEXT_TOOLTIP}
              dataPath={`${fieldNamePrefix}.finishesDiscussion`}
              getGeneratedText={this.generateFinishesDiscussion}
            />
          </React.Fragment>
        )}
      </React.Fragment>
    )
  }

  renderAmenitiesDiscussion = () => {
    const { classes, fieldNamePrefix } = this.props
    const { amenities } = this.getRentReconciliationGroup()

    const { general } = amenities
    const shouldShowAmenitiesDiscussion = general && general !== Conditions.DO_NOT_DISCUSS
    const amenitiesLabel = `${Labels.THE_SUBJECT_IS} "${general}" ${Labels.AS_COMPARED_TO_COMPS_IN_THE_FOLLOWING_WAYS}`

    return (
      <React.Fragment>
        <Typography variant="h6" className={classes.title}>
          Amenities
        </Typography>
        <DropDown
          name={`${fieldNamePrefix}.amenities.general`}
          label={Labels.HOW_DOES_SUBJECT_COMPARE_TO_RENT_COMPS_IN_TERMS_OF_AMENITIES}
          placeholder={Labels.MARKET_COMPARISON_TO_APPLY_PLACEHOLDER}
          items={unitConditionOptions}
        />

        {shouldShowAmenitiesDiscussion && (
          <React.Fragment>
            <Typography variant="subtitle1">{Labels.AMENITIES_DETAILS_OPTIONAL}</Typography>
            <MultipleDropDown
              name={`${fieldNamePrefix}.amenities.details`}
              label={amenitiesLabel}
              items={AMENITIES_DETAILS_OPTIONS}
            />
            <GeneratedComment
              isDynamicContent
              label="Amenities Discussion"
              title="Generated commentary"
              tooltipText={GENERATED_TEXT_TOOLTIP}
              dataPath={`${fieldNamePrefix}.amenitiesDiscussion`}
              getGeneratedText={this.generateAmenitiesDiscussion}
            />
          </React.Fragment>
        )}
      </React.Fragment>
    )
  }

  generateLocationDiscussion = () => {
    const { neighborhood } = this.props
    const { location } = this.getRentReconciliationGroup()
    return generateLocationDiscussionCommentary(neighborhood, location)
  }

  generateSizeDiscussion = () => {
    const { unitGroupName, size } = this.getRentReconciliationGroup()
    const generalSize = get(size, 'general')
    return generateSizeDiscussionCommentary(unitGroupName, generalSize)
  }

  generateConditionDiscussion = () => {
    const { unitGroupName, condition } = this.getRentReconciliationGroup()
    const generalCondition = get(condition, 'general')
    return generateConditionDiscussionCommentary(unitGroupName, generalCondition)
  }

  generateFinishesDiscussion = () => {
    const { unitGroupName, finishes } = this.getRentReconciliationGroup()
    return generateFinishesDiscussionCommentary(unitGroupName, finishes)
  }

  generateAmenitiesDiscussion = () => {
    const { unitGroupName, amenities } = this.getRentReconciliationGroup()
    return generateAmenitiesDiscussionCommentary(unitGroupName, amenities)
  }
  render() {
    const {
      compStats,
      conclusionPerSFAvailability,
      developersUnitStats,
      fieldNamePrefix,
      formValues,
      unitStats,
      showMarketOrientationWarning,
    } = this.props
    const rentReconciliationGroup = this.getRentReconciliationGroup()
    const unitRentPSFTimePeriod = get(formValues, 'unitRentPSFTimePeriod')

    const { unitGroupName, includeDetails } = rentReconciliationGroup
    const hasMarketRateUnits = rentReconciliationGroup.units.some(unit => unit.rentType === RentTypes.MARKET_RATE)
    const showDevelopersForecast = formValues.showDevelopersForecast

    const subjectTableCell = `${Labels.SUBJECT} ${hasMarketRateUnits ? Labels.MARKET_RATE : ''}`
    const noMarketRateUnitsLabel = `${Labels.SUBJECT_HAS_NO_MARKET_RATE} ${unitGroupName} ${Labels.UNITS}.`
    // eslint-disable-next-line max-len
    const marketRateUnitsRadioButtonLabel = `${Labels.HOW_SUBJECTS_MARKET_RATE} ${unitGroupName} ${Labels.UNITS_COMPARE_TO_THE_MARKET}`
    const conclusionPerSFAvailabilityReasons = `${
      Labels.CANNOT_CONCLUDE_PER_SF
    }: ${conclusionPerSFAvailability.reasons.join('. ')}.`
    const showMissingConclusionWarning = !rentReconciliationGroup.marketConclusion

    const subjectCompTableColumns = [
      {
        name: 'label',
        label: '',
        type: ColumnDataTypeEnum.text,
        permanent: true,
        align: 'left',
        editable: false,
      },
    ]
    if (showDevelopersForecast) {
      subjectCompTableColumns.push({
        name: 'developersForecast',
        label: "Developer Forecast's Forecast",
        type: ColumnDataTypeEnum.text,
        permanent: true,
        align: 'right',
        editable: false,
      })
    }
    subjectCompTableColumns.push(
      {
        name: 'subject',
        label: subjectTableCell,
        type: ColumnDataTypeEnum.text,
        permanent: true,
        align: 'right',
        editable: false,
      },
      {
        name: 'comp',
        label: 'Comparables',
        type: ColumnDataTypeEnum.text,
        permanent: true,
        align: 'right',
        editable: false,
      }
    )

    const subjectCompTableRows = [
      {
        readOnly: false,
        suppressMovable: true,
        permanent: false,
        type: ColumnDataTypeEnum.text,
        id: 'min',
        label: 'Min',
        subject: formatCurrencyFloat(unitStats.min) || Labels.N_A,
        comp: formatCurrencyFloat(compStats.min) || Labels.N_A,
        developersForecast: showDevelopersForecast
          ? formatCurrencyFloat(developersUnitStats.min) || Labels.N_A
          : Labels.N_A,
        rowDef: { hideAction: true },
      },
      {
        readOnly: false,
        suppressMovable: true,
        permanent: false,
        type: ColumnDataTypeEnum.text,
        id: 'avg',
        label: 'Avg',
        subject: formatCurrencyFloat(unitStats.avg) || Labels.N_A,
        comp: formatCurrencyFloat(compStats.avg) || Labels.N_A,
        developersForecast: showDevelopersForecast
          ? formatCurrencyFloat(developersUnitStats.avg) || Labels.N_A
          : Labels.N_A,
        rowDef: { hideAction: true },
      },
      {
        readOnly: false,
        suppressMovable: true,
        permanent: false,
        type: ColumnDataTypeEnum.text,
        id: 'max',
        label: 'Max',
        subject: formatCurrencyFloat(unitStats.max) || Labels.N_A,
        comp: formatCurrencyFloat(compStats.max) || Labels.N_A,
        developersForecast: showDevelopersForecast
          ? formatCurrencyFloat(developersUnitStats.max) || Labels.N_A
          : Labels.N_A,
        rowDef: { hideAction: true },
      },
    ]

    return (
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <RowBasedTable
            id="subject-comp-table"
            columns={subjectCompTableColumns}
            rows={subjectCompTableRows}
            onRowUpdate={noop}
            onManyRowsUpdate={noop}
            actionCellHidden
            hideIndexColumn
            onColumnDragEnd={noop}
            onRowsDragEnd={noop}
            onColumnDelete={noop}
            onColumnUpdate={noop}
            onRowDelete={noop}
          />
          {!hasMarketRateUnits && (
            <Typography component="p" variant="caption">
              {noMarketRateUnitsLabel}
            </Typography>
          )}
        </Grid>

        <Grid item xs={12}>
          <Typography variant="subtitle1">{`${unitGroupName} ${Labels.MARKET_CONCLUSION}`}</Typography>
          {conclusionPerSFAvailability.isDisabled && (
            <Callout variant="info" content={conclusionPerSFAvailabilityReasons} />
          )}
        </Grid>
        <Grid item xs={12}>
          <ButtonSwitch
            name={`${fieldNamePrefix}.marketConclusionUnit`}
            options={this.getMarketConclusionUnitOptions(conclusionPerSFAvailability, unitRentPSFTimePeriod)}
          />
        </Grid>
        <FeatureToggle featureFlag={ENABLE_RES_RENT_RECONCILIATION_AUTOMATION}>
          <Grid item xs={12}>
            {showMissingConclusionWarning && (
              <Callout variant="warn" content="Market conclusion value needed for Rent Comparables."></Callout>
            )}
            <AutomationStatus
              formPaths={[`${fieldNamePrefix}.automationMetadata`]}
              message="The market conclusion below was updated from the In-Place Rent Roll"
            />
          </Grid>
        </FeatureToggle>
        <Grid item xs={12}>
          <Number
            name={`${fieldNamePrefix}.marketConclusion`}
            label={Labels.MARKET_CONCLUSION}
            decimalScale={2}
            thousandSeparator
            adornmentPosition="start"
            adornment="$"
          />
        </Grid>
        {hasMarketRateUnits && (
          <>
            {showMarketOrientationWarning && (
              <Grid item xs={12}>
                <Callout variant="warn" content="Review the market orientation due to changes in Rent Comparables." />
              </Grid>
            )}
            <Grid item xs={12}>
              <DropDown
                label={marketRateUnitsRadioButtonLabel}
                placeholder={Labels.MARKET_COMPARISON_TO_APPLY_PLACEHOLDER}
                name={`${fieldNamePrefix}.marketBreakdown`}
                items={MARKET_BREAKDOWN_OPTIONS}
              />
            </Grid>
          </>
        )}
        <Grid item xs={12}>
          <NarrativeComponent
            title="Market Rent Comparison Discussion"
            tooltipText={GENERATED_TEXT_TOOLTIP}
            name={`${fieldNamePrefix}.marketRentCompDiscussion`}
            generatedText={marketRentDiscussion.generate}
            data={marketRentDiscussion.mapDTO({ rentReconciliationGroup, ...this.props })}
          />
        </Grid>
        <Grid item xs={12}>
          <Typography variant="subtitle1">{`${unitGroupName} ${Labels.MARKET_RENT_FORECAST_DETAILS}`}</Typography>
        </Grid>
        <Grid item xs={12}>
          <CheckboxWithLabel
            name={`${fieldNamePrefix}.includeDetails`}
            label={Labels.INCLUDE_MARKET_RENT_FORECAST_DISCUSSION}
          />
        </Grid>
        {includeDetails && (
          <Grid item container>
            {this.renderLocationDiscussion()}
            {this.renderSizeDiscussion()}
            {this.renderBuildingConditionDiscussion()}
            {this.renderFinishesDiscussion()}
            {this.renderAmenitiesDiscussion()}
          </Grid>
        )}
      </Grid>
    )
  }
}

export default withStyles(styles)(ResidentialRentReconciliationItem)
