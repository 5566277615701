import { get } from 'lodash'

import { VALUE_CONCLUSION_TYPES } from 'shared/constants/acas'

type FormValues = {
  valueAsIs: boolean
  hasVacantUnits: boolean
}

type Report = {
  valueConclusionType: string
  residential: {
    units: {
      isVacant: boolean
    }[]
  }
}

type NarrativeValues = {
  valueAsIs: boolean
  hasVacantUnits: boolean
}

export const mapDTO = (formValues: FormValues): NarrativeValues => {
  return formValues
}

export const mapDataModel = (report: Report): NarrativeValues => {
  const valueAsIs = report.valueConclusionType === VALUE_CONCLUSION_TYPES.AS_IS
  const residentialUnits = get(report, 'residential.units', [])
  const hasVacantUnits = residentialUnits.some(unit => unit.isVacant)

  return {
    valueAsIs,
    hasVacantUnits,
  }
}
