import React from 'react'
import PropTypes from 'prop-types'
import { Paper, Table, TableHead, TableBody } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'
import { isEqual } from 'lodash'
import { shouldUpdate, compose } from 'recompose'

import { TableHeadRow } from '../../../../../../../shared/components/TableHelpers'

import { BuildingCompListColumns } from '../../constants'

import BuildingCompItem from './BuildingCompItem'
import { buildingCompPropTypes } from './propTypes'

const styles = theme => ({
  cell: {
    fontWeight: 400,
    fontSize: 16,
    color: theme.palette.text.primary,
  },
  root: {
    overflowX: 'auto',
  },
})

class BuildingCompList extends React.Component {
  static propTypes = {
    subject: buildingCompPropTypes,
    items: PropTypes.arrayOf(buildingCompPropTypes),
    removeComp: PropTypes.func.isRequired,
    editComp: PropTypes.func,
    addComp: PropTypes.func,
    showPerRoomAnalysis: PropTypes.bool.isRequired,
  }

  static defaultProps = {
    items: [],
  }

  render() {
    const { classes, items, subject, addComp, editComp, removeComp, showPerRoomAnalysis } = this.props
    return (
      <Paper className={classes.root}>
        <Table>
          <TableHead>
            <TableHeadRow columns={BuildingCompListColumns} cellClass={classes.cell} />
          </TableHead>
          <TableBody data-qa="selected-building-comps-table">
            {subject && <BuildingCompItem isSubject columns={BuildingCompListColumns} item={subject} />}
            {items.map((item, index) => (
              <BuildingCompItem
                key={item.id}
                columns={BuildingCompListColumns}
                item={item}
                index={index}
                onAddCompItem={addComp}
                onEditCompItem={editComp}
                onRemoveCompItem={removeComp}
                showPerRoomAnalysis={showPerRoomAnalysis}
              />
            ))}
          </TableBody>
        </Table>
      </Paper>
    )
  }
}

export default compose(
  shouldUpdate((props, nextProps) => !isEqual(props.items, nextProps.items)),
  withStyles(styles)
)(BuildingCompList)
