import React from 'react'
import PropTypes from 'prop-types'
import { isNil } from 'lodash'
import { RadioGroup, Radio, FormControlLabel, Box } from '@mui/material'
import { Field } from 'react-final-form'

import { AppraisalInstituteEducationStatus } from '../../constants/organization'

import AppraisalEducationStatusText from './AppraisalEducationStatusText'

const format = value => {
  const notInitialized = isNil(value)
  if (notInitialized) {
    return null
  }
  return value ? AppraisalInstituteEducationStatus.COMPLETED : AppraisalInstituteEducationStatus.NOT_COMPLETED
}

const parse = value => {
  if (isNil(value)) {
    return value
  }
  return value === AppraisalInstituteEducationStatus.COMPLETED
}

const AppraisalEducationRequirements = ({ classes, designation }) => (
  <Field name="educationRequirementsCompleted" parse={parse} format={format}>
    {({ input }) => (
      <RadioGroup onChange={input.onChange} onBlur={input.onBlur} onFocus={input.onFocus} value={input.value}>
        <FormControlLabel
          value={AppraisalInstituteEducationStatus.COMPLETED}
          label={
            <Box sx={{ paddingTop: '12px' }}>
              <AppraisalEducationStatusText
                color="textSecondary"
                designation={designation}
                educationRequirementsCompleted={true}
              />
            </Box>
          }
          control={<Radio />}
        />
        <FormControlLabel
          value={AppraisalInstituteEducationStatus.NOT_COMPLETED}
          label={
            <Box sx={{ paddingTop: '12px' }}>
              <AppraisalEducationStatusText
                color="textSecondary"
                designation={designation}
                educationRequirementsCompleted={false}
                accented
              />
            </Box>
          }
          control={<Radio />}
        />
      </RadioGroup>
    )}
  </Field>
)

AppraisalEducationRequirements.propTypes = {
  designation: PropTypes.string.isRequired,
}

export default AppraisalEducationRequirements
