export default function getFARForZone(zone = '') {
  const zoningLookup = {
    'R1-1': {
      FAR: 0.5,
    },

    'R1-2': {
      FAR: 0.5,
    },

    'R1-2A': {
      FAR: 0.5,
    },

    R2: {
      FAR: 0.5,
    },

    R2A: {
      FAR: 0.5,
    },

    R2X: {
      FAR: 0.85,
    },

    'R3-1': {
      FAR: 0.5,
    },

    'R3-2': {
      FAR: 0.5,
    },

    R3A: {
      FAR: 0.5,
    },

    R3X: {
      FAR: 0.5,
    },

    R4: {
      FAR: 0.75,
    },

    'R4 Infill': {
      FAR: 1.35,
    },

    'R4-1': {
      FAR: 0.75,
    },

    R4A: {
      FAR: 0.75,
    },

    R4B: {
      FAR: 0.9,
    },

    R5: {
      FAR: 1.25,
    },

    'R5 Infill': {
      FAR: 1.65,
    },

    R5A: {
      FAR: 1.1,
    },

    R5B: {
      FAR: 1.35,
    },

    R5D: {
      FAR: 2,
    },

    R6: {
      FAR: 2.2,
    },

    R6A: {
      FAR: 3,
    },

    R6B: {
      FAR: 2,
    },

    'R7-1': {
      FAR: 3.44,
    },

    'R7-2': {
      FAR: 3.44,
    },

    R7A: {
      FAR: 4,
    },

    R7B: {
      FAR: 3,
    },

    R7D: {
      FAR: 4.2,
    },

    R7X: {
      FAR: 5,
    },

    R8: {
      FAR: 6.02,
    },

    R8A: {
      FAR: 6.02,
    },

    R8B: {
      FAR: 4,
    },

    R8X: {
      FAR: 6.02,
    },

    R9: {
      FAR: 7.52,
    },

    R9A: {
      FAR: 7.52,
    },

    R9D: {
      FAR: 9,
    },

    R9X: {
      FAR: 9,
    },

    R10: {
      FAR: 10,
    },

    R10A: {
      FAR: 10,
    },

    R10X: {
      FAR: 10,
    },
    'C1-6': {
      FAR: 3.44,
    },
    'C1-6A': {
      FAR: 4,
    },
    'C1-7': {
      FAR: 6.02,
    },
    'C1-7A': {
      FAR: 6.02,
    },
    'C1-8': {
      FAR: 7.52,
    },
    'C1-8A': {
      FAR: 7.52,
    },
    'C1-8X': {
      FAR: 9,
    },
    'C1-9': {
      FAR: 10,
    },
    'C1-9A': {
      FAR: 10,
    },
    'C2-6': {
      FAR: 3.44,
    },
    'C2-6A': {
      FAR: 4,
    },
    'C2-7': {
      FAR: 7.52,
    },
    'C2-7A': {
      FAR: 7.52,
    },
    'C2-7X': {
      FAR: 9,
    },
    'C2-8': {
      FAR: 10,
    },
    'C2-8A': {
      FAR: 10,
    },
    C3: {
      FAR: 0.55,
    },
    C3A: {
      FAR: 0.55,
    },
    'C4-1': {
      FAR: 1.25,
    },
    'C4-2': {
      FAR: 2.43,
    },
    'C4-2A': {
      FAR: 3,
    },
    'C4-2F': {
      FAR: 6.02,
    },
    'C4-3': {
      FAR: 2.43,
    },
    'C4-3A': {
      FAR: 3,
    },
    'C4-4': {
      FAR: 3.4,
    },
    'C4-4A': {
      FAR: 4,
    },
    'C4-4D': {
      FAR: 6.02,
    },
    'C4-4L': {
      FAR: 4.07,
    },
    'C4-5': {
      FAR: 3.44,
    },
    'C4-5A': {
      FAR: 4,
    },
    'C4-5D': {
      FAR: 4.2,
    },
    'C4-5X': {
      FAR: 5,
    },
    'C4-6': {
      FAR: 10,
    },
    'C4-6A': {
      FAR: 10,
    },
    'C4-7': {
      FAR: 10,
    },
    'C4-7A': {
      FAR: 10,
    },
    'C5-1': {
      FAR: 10,
    },
    'C5-1A': {
      FAR: 10,
    },
    'C5-2': {
      FAR: 10,
    },
    'C5-2A': {
      FAR: 10,
    },
    'C5-3': {
      FAR: 10,
    },
    'C5-4': {
      FAR: 10,
    },
    'C5-5': {
      FAR: 10,
    },
    'C6-1': {
      FAR: 3.44,
    },
    'C6-1A': {
      FAR: 2.43,
    },
    'C6-2': {
      FAR: 6.02,
    },
    'C6-2A': {
      FAR: 6.02,
    },
    'C6-3': {
      FAR: 7.52,
    },
    'C6-3A': {
      FAR: 7.52,
    },
    'C6-3D': {
      FAR: 9,
    },
    'C6-3X': {
      FAR: 9,
    },
    'C6-4': {
      FAR: 10,
    },
    'C6-4A': {
      FAR: 10,
    },
    'C6-4X': {
      FAR: 10,
    },
    'C6-5': {
      FAR: 10,
    },
    'C6-6': {
      FAR: 10,
    },
    'C6-7': {
      FAR: 10,
    },
    'C6-8': {
      FAR: 10,
    },
    'C6-9': {
      FAR: 10,
    },
    C7: {
      FAR: 2,
    },
    'C8-1': {
      FAR: 1,
    },
    'C8-2': {
      FAR: 2,
    },
    'C8-3': {
      FAR: 2,
    },
    'C8-4': {
      FAR: 5,
    },
    'M1-1': {
      FAR: 1,
    },
    'M1-2': {
      FAR: 2,
    },
    'M1-3': {
      FAR: 5,
    },
    'M1-4': {
      FAR: 2,
    },
    'M1-5': {
      FAR: 5,
    },
    'M1-5A': {
      FAR: 5,
    },
    'M1-5B': {
      FAR: 5,
    },
    'M1-5M': {
      FAR: 5,
    },
    'M1-6': {
      FAR: 10,
    },
    'M1-6M': {
      FAR: 10,
    },
    'M2-1': {
      FAR: 2,
    },
    'M2-2': {
      FAR: 5,
    },
    'M2-3': {
      FAR: 2,
    },
    'M2-4': {
      FAR: 5,
    },
    'M3-1': {
      FAR: 2,
    },
    'M3-2': {
      FAR: 2,
    },
  }

  const zoneObject = zoningLookup[zone.toUpperCase()]
  if (zoneObject) {
    return zoneObject.FAR
  }
  return 0
}

export const getCommercialOverlays = ({ zones }) => zones.map(zone => zone.commercialOverlay).filter(overlay => overlay)

export const calculateFloorAreaRatio = ({ zones }) => {
  let calculatedFloorAreaRatio = 0

  zones.forEach(({ zone, percentage }) => {
    const zoneFAR = getFARForZone(zone)
    const zonePercentage = percentage || 0
    calculatedFloorAreaRatio += (zoneFAR * zonePercentage) / 100
  })

  return calculatedFloorAreaRatio
}
