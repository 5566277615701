import React from 'react'
import PropTypes from 'prop-types'
import { get, startCase } from 'lodash'
import { FormSpy } from 'react-final-form'

import { getSiteName } from 'shared/utils'
import { SourceInformationKeys } from 'shared/constants/report'

import { SOURCE_DROPDOWN_ITEMS } from '../../../report/constants'
import { required } from '../../utils/validation'

import Text from '../Text'

import { DropDown } from '../DropDown'
import { SourceInformationLabels } from '../../../../../shared/constants/report'

export const getSourceOfInformation = comp => {
  if (!comp.sourceOfInformation) {
    return ''
  } else if (comp.sourceOfInformation === SourceInformationKeys.externalDatabase) {
    if (!comp.sourceName && !comp.sourceUrl) {
      return SourceInformationLabels.externalDatabase
    } else if (comp.sourceName && !comp.sourceUrl) {
      return comp.sourceName
    } else if (comp.sourceName && comp.sourceUrl) {
      const url = comp.sourceUrl.indexOf('://') === -1 ? `https://${comp.sourceUrl}` : comp.sourceUrl
      return (
        <a href={url} target="_blank" rel="noopener noreferrer">
          {comp.sourceName}
        </a>
      )
    } else if (!comp.sourceName && comp.sourceUrl) {
      const url = comp.sourceUrl.indexOf('://') === -1 ? `https://${comp.sourceUrl}` : comp.sourceUrl
      const sourceName = getSiteName(url) || SourceInformationLabels.externalDatabase
      return (
        <a href={url} target="_blank" rel="noopener noreferrer">
          {sourceName}
        </a>
      )
    }
  } else if (comp.sourceOfInformation === SourceInformationKeys.other) {
    return comp.sourceName ? comp.sourceName : SourceInformationLabels.other
  } else if (comp.sourceOfInformation === SourceInformationKeys.bowerySubject) {
    const sourceName = SourceInformationLabels.bowerySubject
    if (comp.sourceUrl) {
      const url = comp.sourceUrl.indexOf('://') === -1 ? `https://${comp.sourceUrl}` : comp.sourceUrl
      return (
        <a href={url} target="_blank" rel="noopener noreferrer">
          {sourceName}
        </a>
      )
    }
    return sourceName
  } else {
    if (comp.sourceName && !comp.sourceUrl) {
      return comp.sourceName
    } else if (comp.sourceName && comp.sourceUrl) {
      const url = comp.sourceUrl.indexOf('://') === -1 ? `https://${comp.sourceUrl}` : comp.sourceUrl
      return (
        <a href={url} target="_blank" rel="noopener noreferrer">
          {comp.sourceName}
        </a>
      )
    } else if (!comp.sourceName && comp.sourceUrl) {
      const url = comp.sourceUrl.indexOf('://') === -1 ? `https://${comp.sourceUrl}` : comp.sourceUrl
      const sourceName = getSiteName(url) || startCase(comp.sourceOfInformation)
      return (
        <a href={url} target="_blank" rel="noopener noreferrer">
          {sourceName}
        </a>
      )
    }
    return startCase(comp.sourceOfInformation)
  }
}
export default class SourceOfInformation extends React.PureComponent {
  static propTypes = {
    name: PropTypes.string,
    additionalSources: PropTypes.object,
  }

  getDropdownItems() {
    const { additionalSources } = this.props
    let dropdownItems
    if (additionalSources) {
      const otherIndex = SOURCE_DROPDOWN_ITEMS.length - 1
      dropdownItems = [
        ...SOURCE_DROPDOWN_ITEMS.slice(0, otherIndex),
        ...additionalSources,
        ...SOURCE_DROPDOWN_ITEMS.slice(otherIndex),
      ]
    } else {
      dropdownItems = SOURCE_DROPDOWN_ITEMS
    }
    return dropdownItems
  }

  render() {
    const { name } = this.props
    const namePrefix = name ? `${name}.` : ''

    const dropdownItems = this.getDropdownItems()

    return (
      <FormSpy
        subscription={{ values: true }}
        render={({ values }) => {
          const sourceOfInformation = get(values, `${namePrefix}sourceOfInformation`)
          return (
            <div>
              <DropDown
                name={`${namePrefix}sourceOfInformation`}
                label="Source of Information"
                items={dropdownItems}
                required={true}
                validate={required}
              />
              {sourceOfInformation === SourceInformationKeys.externalDatabase && (
                <React.Fragment>
                  <Text name={`${namePrefix}sourceName`} label="Source Name" />
                  <Text name={`${namePrefix}sourceUrl`} label="Source URL" />
                </React.Fragment>
              )}
              {sourceOfInformation === SourceInformationKeys.other && (
                <Text name={`${namePrefix}sourceName`} label="Source Name" />
              )}
            </div>
          )
        }}
      />
    )
  }
}
