import { put, takeLatest } from 'redux-saga/effects'

import * as Api from '../../../../../api'

import { searchTaxComps, searchTaxCompsFail, searchTaxCompsReceive, searchTaxCompsRequest } from './actions'

function* searchTaxCompsHandler(action) {
  try {
    yield put(searchTaxCompsRequest())
    const taxComps = yield Api.getTaxComps(action.payload)
    yield put(searchTaxCompsReceive(taxComps))
  } catch (err) {
    yield put(searchTaxCompsFail(err))
  }
}

export default [takeLatest(searchTaxComps, searchTaxCompsHandler)]
