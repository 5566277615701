import React from 'react'
import { Paper, Stack, Typography } from '@mui/material'
import { FFE_PATH } from 'shared/constants/report/keysAndDataPaths'
import wrapForm from 'report/forms/wrapForm'

import { NarrativeComponent } from 'client-shared/components'
import furnitureFixturesAndEquipment from 'shared/utils/textGeneration/costApproach/furnitureFixturesAndEquipment'

import { PAPER_WIDTH } from '../helpers'

export const FurnitureFixturesAndEquipment = () => {
  return (
    <Paper id="ffe" sx={{ maxWidth: PAPER_WIDTH }}>
      <Stack>
        <Typography variant="h5">Furniture, Fixtures, & Equipment</Typography>
        <NarrativeComponent
          title=""
          generatedText={furnitureFixturesAndEquipment.generate}
          name="furnitureFixturesAndEquipment"
        />
      </Stack>
    </Paper>
  )
}

export default wrapForm(FFE_PATH, {
  heading: 'Furniture, Fixtures, & Equipment',
})(FurnitureFixturesAndEquipment)
