import React from 'react'
import { useForm } from 'react-final-form'
import AutoModeIcon from '@mui/icons-material/AutoModeRounded'
import { Stack, SxProps, Typography, Link } from '@mui/material'
import { get } from 'lodash'

import { getAutomationFormattedDate, getAutomationFormattedTime } from 'shared/utils/formatters/dateHelper'

type AutomationStatusProps = {
  formPaths: string[]
  sx?: SxProps
  message?: string
  showSourceLinks?: boolean
  onSourceLinkClick?: React.MouseEventHandler
}

const FORM_PATH_SOURCE_MAP: Record<string, string> = {
  expenseHistoryAutomationMetadata: '3 Years of Income and Expenses',
  proFormaAutomationMetadata: 'Pro Forma Income and Expenses',
  comparableExpensesAutomationMetadata: 'Click here to view the Bowery Appraisals',
  expenseForecastAutomationMetadata: 'Click here to view the Bowery Appraisals',
}

const createSourceLink = (sourceName: string, sourceLink: string, onSourceLinkClick?: React.MouseEventHandler) => {
  return !!onSourceLinkClick ? (
    <Link onClick={onSourceLinkClick} sx={{ cursor: 'pointer' }}>
      {sourceName}
    </Link>
  ) : (
    <Link href={sourceLink} target="_blank" rel="noreferrer" sx={{ cursor: 'pointer' }}>
      {sourceName}
    </Link>
  )
}

const AutomationStatus: React.FC<AutomationStatusProps> = ({
  formPaths,
  sx,
  message,
  onSourceLinkClick,
  showSourceLinks = true,
}) => {
  const form = useForm()

  if (!Array.isArray(formPaths) || !formPaths.length) {
    return null
  }

  const automationMetadata: Record<string, any> = {}
  formPaths.forEach(formPath => {
    const meta = get(form.getState().values, formPath)

    if (meta && meta?.updatedAt) {
      automationMetadata[formPath] = meta
    }
  })

  if (!Object.keys(automationMetadata).length) {
    return null
  }

  const latestUpdatedAt = Math.max(...Object.values(automationMetadata).map(meta => +new Date(meta.updatedAt)))
  const fallbackLink = Object.values(automationMetadata)[0]?.sourceLinks?.[0]
  const sourceLinks: { sourceName: string; sourceLink: string }[] = []
  Object.entries(automationMetadata).forEach(([formPath, meta]) => {
    const sourceName = FORM_PATH_SOURCE_MAP[formPath]
    const sourceLink = !onSourceLinkClick ? meta?.sourceLinks?.[0] : null

    if (sourceName && (sourceLink || onSourceLinkClick)) {
      sourceLinks.push({ sourceName, sourceLink })
    }
  })

  const formattedDate = getAutomationFormattedDate(latestUpdatedAt)
  const formattedTime = getAutomationFormattedTime(latestUpdatedAt)

  const baseMessage = message ?? `We've updated the information below`
  const formattedMessage = `${baseMessage} on ${formattedDate} at ${formattedTime}`

  return (
    <Stack direction="row" spacing={1} alignItems="center" color="#673AB7" sx={sx}>
      <AutoModeIcon />
      <Typography variant="body2">
        {formattedMessage}
        {sourceLinks.length && showSourceLinks ? (
          <span>
            {' '}
            (
            {sourceLinks.map(({ sourceName, sourceLink }, index) => {
              return (
                <>
                  {createSourceLink(sourceName, sourceLink, onSourceLinkClick)}
                  {index < sourceLinks.length - 1 && ', '}
                </>
              )
            })}
            ).
          </span>
        ) : (
          fallbackLink && (
            <span>
              {' '}
              (
              <Link href={fallbackLink} target="_blank" rel="noreferrer" sx={{ cursor: 'pointer' }}>
                click here for source reference link
              </Link>
              ).
            </span>
          )
        )}
      </Typography>
    </Stack>
  )
}

export default AutomationStatus
