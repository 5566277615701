import * as TimingEvents from './timing'

class PropertySearchEvent {
  constructor(key, data, user) {
    this.name = 'propertySearch'
    this.label = `Property Search`
    this.timestamp = new Date()
    this.userId = user._id
    this.properties = {
      ...data,
      key,
      user: {
        fullName: user.fullName,
        firstName: user.name?.first,
        lastName: user.name?.last,
        username: user.username,
      },
    }
  }
}

export { TimingEvents, PropertySearchEvent }
