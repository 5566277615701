import { mean, max, min, filter, isEmpty, isArray } from 'lodash'

import BoweryDate from '@bowery-valuation/bowery-date'

import { formatCurrencyInt } from '../../../../../shared/utils/numberFormatters'

import { SUBJECT_LABEL, RENT_PER_SF_LABEL, MARKET_RATE_LABEL } from './constants'

export const getFormatedValue = (items, func) => {
  if (!func || !isArray(items)) {
    return 0
  }
  return formatCurrencyInt(func(items) || 0)
}

export const getComputedValues = items => {
  return [getFormatedValue(items, min), getFormatedValue(items, mean), getFormatedValue(items, max)]
}

export const hasMarketRateUnits = (units, bedrooms) => {
  const marketRateUnits = filter(units, unit => {
    const bedroomsCondition = bedrooms > -1 ? unit.bedrooms === bedrooms : true
    return unit.rentType === 'Market Rate' && bedroomsCondition
  })
  return !isEmpty(marketRateUnits)
}

export const getComputedPanelLabel = (hasMarketRateUnits, unitSqft) => {
  return `${SUBJECT_LABEL}${hasMarketRateUnits ? ' ' + MARKET_RATE_LABEL : ''}${
    unitSqft ? ' ' + RENT_PER_SF_LABEL : ''
  }`
}

export const getLeaseTermsOption = monthsToSubtract => {
  const min = new BoweryDate().subtractMonthsFromDate(monthsToSubtract).startOf('day').toISOString()

  const max = new BoweryDate().endOf('day').toISOString()

  return { min, max }
}

export const mapCoords = coords => {
  if (coords) {
    return {
      latitude: coords.lat || coords.latitude,
      longitude: coords.lng || coords.longitude,
    }
  }
  return null
}
