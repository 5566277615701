import React from 'react'
import { get } from 'lodash'

import { Button } from '@material-ui/core'
import { Stack, Box } from '@mui/material'

import { PropertyWizard } from '../../../../../../../../shared/components'
import { getLocationType } from '../../../../../../../../shared/utils/propertyHelper'

class CreateComp extends React.PureComponent {
  state = {
    open: false,
  }

  handleOpen = () => {
    this.setState({ open: true })
  }

  handleClose = () => {
    this.setState({ open: false })
  }

  addProperty = values => {
    const { addComp } = this.props
    const locationIdentifier = get(values, 'locationIdentifier')
    const locationType = getLocationType(locationIdentifier)
    addComp({ property: values, locationType, shouldRedirect: values.additional })
    this.handleClose()
  }

  render() {
    const { open } = this.state
    return (
      <Stack direction="row" spacing={1}>
        <Button variant="contained" color="primary" onClick={this.handleOpen} data-qa="create-rent-comp-button">
          Create Comp
        </Button>

        <Box>
          <PropertyWizard
            open={open}
            onSubmit={this.addProperty}
            additional={{
              text: 'ADD & EDIT',
            }}
            title="Find Building Comparable"
            handleClose={this.handleClose}
          />
        </Box>
      </Stack>
    )
  }
}

export default CreateComp
