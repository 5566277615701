import React from 'react'
import { withStyles } from '@mui/styles'

import { paragraphStyles } from '../../styles'

const Paragraph = ({ classes, children, attributes }) => {
  return (
    <p {...attributes} className={classes.paragraph}>
      {children}
    </p>
  )
}

export default withStyles(paragraphStyles)(Paragraph)
