import React, { FunctionComponent } from 'react'

import { Alert } from '@mui/material'
import { CheckCircleOutline, ErrorOutline } from '@mui/icons-material'

const variants = ['info', 'success', 'warn', 'error', 'standard', 'link'] as const

const variantToSeverity = {
  error: 'error',
  info: 'info',
  link: 'info',
  standard: 'success',
  success: 'success',
  warn: 'warning',
} as const

type CalloutProps = {
  content?: React.ReactNode
  variant?: (typeof variants)[number]
} & Omit<React.ComponentProps<typeof Alert>, 'children' | 'severity' | 'variant'>

const Callout: FunctionComponent<CalloutProps> = ({ content, icon, sx, variant, ...props }) => {
  return (
    <Alert
      icon={icon}
      iconMapping={{
        success: <CheckCircleOutline fontSize="inherit" sx={{ color: '#20B586' }} />,
        error: <ErrorOutline fontSize="inherit" sx={{ color: '#D34141' }} />,
      }}
      severity={variant && variantToSeverity[variant]}
      sx={{
        '.MuiAlert-message, .MuiAlert-icon': {
          display: 'flex',
        },
        ...sx,
      }}
      {...props}
    >
      {content}
    </Alert>
  )
}

Callout.displayName = 'Callout'

Callout.defaultProps = {
  content: '',
  variant: 'info',
}

export default Callout
