import React from 'react'
import PropTypes from 'prop-types'

import { FormSpy } from 'react-final-form'
import { OnChange } from 'react-final-form-listeners'
import { fromRenderProps } from 'recompose'
import { get } from 'lodash'

import { Stack } from '@mui/material'

import { DatePicker } from 'client-shared/components/DatePicker'
import { CheckboxWithLabel, Number as NumberField, Text } from 'client-shared/components'

class ContractHistory extends React.PureComponent {
  onUnderContractChange = value => {
    const { formBatch, changeValue } = this.props

    if (!value) {
      formBatch(() => {
        changeValue('contractDetails', null)
        changeValue('assignedContractDetails', null)
        changeValue('isAssignedContract', false)
      })
    }
  }

  onAssignedContractChange = value => {
    const { changeValue, buyer } = this.props

    if (value) {
      changeValue('assignedContractDetails.assignor', buyer)
    } else {
      changeValue('assignedContractDetails', null)
    }
  }

  render() {
    const { isAssignedContract, isUnderContract } = this.props

    return (
      <Stack spacing={1}>
        <OnChange name="isUnderContract">{this.onUnderContractChange}</OnChange>
        <OnChange name="isAssignedContract">{this.onAssignedContractChange}</OnChange>
        {isUnderContract && (
          <Stack spacing={1} maxWidth={400}>
            <Text data-qa="buyer" label="Buyer" name="contractDetails.grantee" />
            <DatePicker name="contractDetails.date" label="Date" />
            <NumberField
              adornment="$"
              allowNegative={false}
              data-qa="contract-price"
              decimalScale={2}
              label="Price"
              name="contractDetails.price"
              thousandSeparator
            />
            <CheckboxWithLabel name="isAssignedContract" label="The contract is to an assignee" />
            {isAssignedContract && (
              <Stack spacing={1}>
                <Text data-qa="assignee" label="Assignee" name="assignedContractDetails.assignee" />
                <Text data-qa="assignor" label="Assignor" name="assignedContractDetails.assignor" />
                <DatePicker label="Assignment Date" name="assignedContractDetails.date" />
                <NumberField
                  adornment="$"
                  allowNegative={false}
                  data-qa="assignment-price"
                  decimalScale={2}
                  label="Assignment Price"
                  name="assignedContractDetails.price"
                  thousandSeparator
                />
              </Stack>
            )}
          </Stack>
        )}
      </Stack>
    )
  }
}

ContractHistory.propTypes = {
  buyer: PropTypes.string,
  changeValue: PropTypes.func.isRequired,
  formBatch: PropTypes.func.isRequired,
  isAssignedContract: PropTypes.bool.isRequired,
  isUnderContract: PropTypes.bool.isRequired,
}

ContractHistory.defaultProps = {
  buyer: '',
}

export default fromRenderProps(FormSpy, ({ form, values }) => ({
  buyer: get(values, 'contractDetails.grantee'),
  changeValue: form.change,
  formBatch: form.batch,
  isAssignedContract: values.isAssignedContract,
  isUnderContract: values.isUnderContract,
}))(ContractHistory)
