import React from 'react'

import Box from '@mui/material/Box'

const Default = ({ container, item, gap, placeContent, placeItems, placeSelf, show = true, sx, ...otherProps }) =>
  show ? (
    <Box
      sx={{
        display: container ? 'grid' : 'flex',
        gap,
        grid: container,
        gridArea: item,
        placeContent,
        placeItems,
        placeSelf,
        ...sx,
      }}
      {...otherProps}
    />
  ) : null

export default Default
