import React, { memo, useEffect } from 'react'
import { ClickAwayListener } from '@mui/base'

import { HoverSuggest } from 'client-shared/components/NarrativeComponent/HoverSuggest'

import { getHoverSuggestStyles } from './helpers'
import { ChipOption } from './types'

type ChipOptionsProps = {
  children: React.ReactNode
  inSearchMode: boolean
  searchQuery: string
  searchResults: ChipOption[]
  select: (option: ChipOption) => void
  hide: () => void
  targetElement?: HTMLElement
}

export const ChipOptions: React.FC<ChipOptionsProps> = ({
  children,
  inSearchMode,
  searchQuery,
  select,
  hide,
  searchResults,
  targetElement,
}) => {
  const [selectedSuggestion, setSelectedSuggestion] = React.useState(0)
  const className = 'hover-suggest'

  useEffect(() => {
    if (!inSearchMode) {
      setSelectedSuggestion(0)
    }
  }, [inSearchMode])

  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      if (!inSearchMode) {
        return
      }

      switch (event.key) {
        case 'ArrowDown': {
          event.preventDefault()
          let newSuggestion = selectedSuggestion + 1
          if (newSuggestion > searchResults.length - 1) {
            newSuggestion = selectedSuggestion
          }
          setSelectedSuggestion(newSuggestion)
          break
        }
        case 'ArrowUp': {
          event.preventDefault()
          let newSuggestion = selectedSuggestion - 1
          if (newSuggestion < 0) {
            newSuggestion = selectedSuggestion
          }
          setSelectedSuggestion(newSuggestion)
          break
        }
        case 'Enter':
          event.preventDefault()
          if (searchResults[selectedSuggestion]) {
            select(searchResults[selectedSuggestion])
          }
          break
        case 'Escape':
          event.preventDefault()
          hide()
          break
        default:
          break
      }
    }

    document.addEventListener('keydown', handleKeyDown)

    return () => {
      document.removeEventListener('keydown', handleKeyDown)
    }
  }, [hide, inSearchMode, searchResults, select, selectedSuggestion])

  const hoverSuggestStyles = getHoverSuggestStyles({ isEditing: inSearchMode, targetElement })

  return (
    <div>
      {children}
      {inSearchMode && (
        <ClickAwayListener onClickAway={hide}>
          <HoverSuggest
            className={className}
            searchResults={searchResults}
            searchQuery={searchQuery}
            selectedSuggestion={selectedSuggestion}
            select={select}
            style={hoverSuggestStyles}
          />
        </ClickAwayListener>
      )}
    </div>
  )
}

export default memo(ChipOptions)
