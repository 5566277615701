import React from 'react'
import PropTypes from 'prop-types'
import { Input, IconButton } from '@mui/material'
import { withStyles } from '@mui/styles'
import CloudUpload from '@mui/icons-material/CloudUpload'

const styles = theme => ({
  root: {
    display: 'inline-block',
  },
  input: {
    display: 'none',
  },
  textField: {
    margin: theme.spacing(1),
  },
})

class FileInput extends React.PureComponent {
  static propTypes = {
    classes: PropTypes.object.isRequired,
  }

  componentDidUpdate() {
    if (!this.props.files.length) {
      this.input.value = ''
    }
  }

  onChooseFile = () => {
    this.input.click()
  }

  onChangeFiles = () => {
    this.props.onChange(this.input.files)
  }

  initInput = node => {
    this.input = node
  }

  getInputText = () => {
    const { files } = this.props

    switch (files.length) {
      case 0:
        return ''
      case 1:
        return files[0].name
      default:
        return `${files.length} files`
    }
  }

  render() {
    const { classes, files, placeholder, ...otherProps } = this.props
    const qa = otherProps['data-qa']
    return (
      <div className={classes.root} data-qa={qa}>
        <input
          type="file"
          className={classes.input}
          ref={this.initInput}
          files={files}
          onChange={this.onChangeFiles}
          data-qa="file-input"
        />
        <Input
          type="text"
          value={this.getInputText()}
          className={classes.textField}
          disabled
          fullWidth
          placeholder={placeholder}
          variant="outlined"
          endAdornment={
            <IconButton component="span" onClick={this.onChooseFile}>
              <CloudUpload />
            </IconButton>
          }
          data-qa="text-input"
        />
      </div>
    )
  }
}

const StyledFileInput = withStyles(styles)(FileInput)

StyledFileInput.propTypes = {
  files: PropTypes.arrayOf(PropTypes.objectOf(File)).isRequired,
  onChange: PropTypes.func.isRequired,
  placeholder: PropTypes.string,
}

StyledFileInput.defaultProps = {
  placeholder: 'Choose a File',
}

export default StyledFileInput
