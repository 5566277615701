import React from 'react'
import { compose } from 'redux'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { Field } from 'react-final-form'
import { Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle } from '@material-ui/core'
import { Map, ZoomControl, TileLayer as BaseMap } from 'react-leaflet'
import { isNil, isString } from 'lodash'
import { divIcon } from 'leaflet'
import StarRounded from '@mui/icons-material/StarRounded'

import DivIcon from 'react-leaflet-div-icon'

import { errorNotification } from 'client-shared/redux/actions/notifications'

import RoundedStar from '../../images/round_star_black_24dp.png'

import LeafletMapControl from '../Map/LeafletMapControl'

import ConnectedIconLabel from './ConnectedIconLabel'

import './markerLabel.css'
import wrapMap from './wrapMap'

const propTypesMaterial = {
  isZoomOn: PropTypes.bool.isRequired,
  displayCenterButton: PropTypes.bool.isRequired,
  isImageSaving: PropTypes.bool.isRequired,
  isModalOpen: PropTypes.bool.isRequired,
  captureMap: PropTypes.func.isRequired,
  closeMapWizard: PropTypes.func.isRequired,
  cartoClient: PropTypes.object.isRequired,
  classes: PropTypes.object.isRequired,
  label: PropTypes.string,
  id: PropTypes.string.isRequired,
}

const propTypesMapOptions = {
  zoomDelta: PropTypes.number.isRequired,
  zoomSnap: PropTypes.number.isRequired,
  wheelPxPerZoomLevel: PropTypes.number.isRequired,
  mapOptions: PropTypes.shape({
    baseMap: PropTypes.string.isRequired,
    maxZoom: PropTypes.number.isRequired,
    initialZoom: PropTypes.number.isRequired,
    defaultLabelOffset: PropTypes.number.isRequired,
    width: PropTypes.number.isRequired,
    height: PropTypes.number.isRequired,
    initialCoordinates: PropTypes.oneOfType([
      PropTypes.shape({
        lat: PropTypes.number.isRequired,
        lng: PropTypes.number.isRequired,
      }),
      PropTypes.arrayOf(PropTypes.number).isRequired,
    ]),
  }).isRequired,
}

class MaterialSubjectMapWizard extends React.PureComponent {
  static propTypes = {
    ...propTypesMaterial,
    ...propTypesMapOptions,
    subject: PropTypes.shape({
      coords: PropTypes.arrayOf(PropTypes.number).isRequired,
      addressOne: PropTypes.string.isRequired,
      addressTwo: PropTypes.string.isRequired,
    }).isRequired,
    comps: PropTypes.arrayOf(
      PropTypes.shape({
        coords: PropTypes.arrayOf(PropTypes.number).isRequired,
        address: PropTypes.shape({
          addressOne: PropTypes.string.isRequired,
          addressTwo: PropTypes.string,
        }).isRequired,
      })
    ).isRequired,
    errorNotification: PropTypes.func.isRequired,
    mapType: PropTypes.string,
  }

  static defaultProps = {
    zoomDelta: 0.3,
    zoomSnap: 0.1,
    wheelPxPerZoomLevel: 20,
  }

  state = {
    cleanedComps: [],
    invalidCompAddresses: [],
  }

  componentDidUpdate(prevProps) {
    const { isModalOpen, comps, errorNotification } = this.props
    let { invalidCompAddresses: currentInvalidCompAddresses } = this.state

    if (comps !== prevProps.comps) {
      const { cleanedComps, invalidCompAddresses } = this.cleanComps(comps)
      this.setState({ cleanedComps, invalidCompAddresses })
      currentInvalidCompAddresses = invalidCompAddresses
    }

    if (isModalOpen && !prevProps.isModalOpen && currentInvalidCompAddresses.length) {
      const addresses = currentInvalidCompAddresses.join(', ')
      const message =
        'Unable to show all comps on the map.' +
        ` The following comp(s) have invalid coordinates, please contact Support: ${addresses}`
      errorNotification({ message })
    }
  }

  cleanComps = comps => {
    const invalidCompAddresses = []
    const cleanedComps = comps.map(comp => {
      if (!comp.coords[0] && !comp.coords[1]) {
        invalidCompAddresses.push(comp.address.addressOne)
        return { ...comp, coords: [0, 0] }
      }
      return comp
    })

    return { cleanedComps, invalidCompAddresses }
  }

  getCompLabel = (number, address = {}) => {
    const { classes } = this.props
    const addressTwo = isString(address.addressTwo) ? address.addressTwo.replace('The ', '') : ''

    return divIcon({
      className: '',
      html: `
      <div class="${classes.markerLabelWrapper}">
        <div class="${classes.markerLabel}">
          <div class="${classes.title}">Comparable ${number}</div>
            <div class="${classes.addressOne}">${address.addressOne}</div>
            <div class="${classes.addressTwo}" style="display: ${
        isNil(address.addressTwo) ? 'none' : 'block'
      }">${addressTwo}</div>
        </div>
      </div>
    `,
    })
  }

  getMapRef = node => {
    this.map = node
  }

  getCompIcon = coords => {
    const { classes } = this.props
    return (
      <DivIcon
        className={classes.container}
        position={{
          lat: coords[0],
          lng: coords[1],
        }}
      >
        <img className={classes.compIcon} src={RoundedStar} alt="comp" />
      </DivIcon>
    )
  }

  onSubjectCenter = event => {
    const { map } = this
    const { subject } = this.props
    event.preventDefault()
    map.leafletElement.setView([subject.coords[0], subject.coords[1]], 15)
  }

  render() {
    const {
      isImageSaving,
      isModalOpen,
      captureMap,
      closeMapWizard,
      label,
      classes,
      id,
      isZoomOn,
      displayCenterButton,
      zoomDelta,
      zoomSnap,
      wheelPxPerZoomLevel,
      mapOptions: { baseMap, width, height, defaultLabelOffset = 0.003, initialCoordinates, initialZoom, maxZoom },
      subject,
      mapType,
    } = this.props
    const { cleanedComps } = this.state

    return (
      <div>
        <Dialog
          maxWidth="md"
          open={isModalOpen}
          onClose={closeMapWizard}
          data-qa={`subject-and-comp-${mapType || ''}-map`}
        >
          <DialogTitle>{label}</DialogTitle>
          <DialogContent>
            <Map
              id={id}
              ref={this.getMapRef}
              className={id}
              style={{ height, width }}
              center={initialCoordinates}
              zoom={initialZoom}
              maxZoom={maxZoom}
              zoomControl={false}
              zoomDelta={zoomDelta}
              zoomSnap={zoomSnap}
              wheelPxPerZoomLevel={wheelPxPerZoomLevel}
              data-qa="subject-and-comp-map"
            >
              <BaseMap attribution="" url={baseMap} />
              {isZoomOn && <ZoomControl />}
              <ConnectedIconLabel
                position={subject.coords}
                icon={this.props.getSubjectIcon(subject.coords)}
                label={{
                  icon: this.props.getSubjectLabel(subject),
                  draggable: true,
                }}
                labelOffset={defaultLabelOffset}
              />
              {cleanedComps.map((comp, index) => (
                <ConnectedIconLabel
                  key={index}
                  position={comp.coords}
                  icon={this.getCompIcon(comp.coords)}
                  label={{
                    icon: this.getCompLabel(index + 1, comp.address),
                    draggable: true,
                  }}
                  labelOffset={defaultLabelOffset}
                />
              ))}
              {displayCenterButton && (
                <LeafletMapControl className="supportLegend" position="topleft">
                  <div className={classes.centerButton} onClick={this.onSubjectCenter}>
                    <StarRounded className={classes.centerButtonIcon} />
                  </div>
                </LeafletMapControl>
              )}
            </Map>
          </DialogContent>
          <DialogActions>
            <Button onClick={closeMapWizard} color="primary" data-qa="subject-and-comp-map-close-btn">
              Close
            </Button>
            <div className={classes.wrapper}>
              <Button
                color="primary"
                disabled={isImageSaving}
                onClick={captureMap}
                data-qa="subject-and-comp-map-capture-screen-btn"
              >
                Capture Screen
              </Button>
              {isImageSaving && <CircularProgress size={24} className={classes.buttonProgress} />}
            </div>
          </DialogActions>
        </Dialog>
      </div>
    )
  }
}

MaterialSubjectMapWizard = compose(wrapMap, connect(null, { errorNotification }))(MaterialSubjectMapWizard)

export function SubjectAndCompsMapWizard({ name, ...otherProps }) {
  return (
    <Field
      name={name}
      render={({ input }) => (
        <MaterialSubjectMapWizard
          {...otherProps}
          id={name}
          value={input.value}
          handleChange={input.onChange}
          onFocus={input.onFocus}
          onBlur={input.onBlur}
        />
      )}
    />
  )
}

SubjectAndCompsMapWizard.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string,
  ...propTypesMapOptions,
}
