import React, { FC, useState } from 'react'
import AutomationCTA from 'client-shared/components/AutomationCTA'
import {
  IMPORT_STATUS_ERROR,
  IMPORT_STATUS_READY,
  IMPORT_STATUS_STARTED,
} from 'client-shared/components/AutomationCTA/constants'

import { connect } from 'react-redux'
import { get } from 'lodash'

import { ExtendedFormApi } from 'client-shared/utils/form'

import * as Api from '../../../../report/api'

import { CATEGORY_LABEL_MAP, EXPENSE_HISTORY_CATEGORY, PRO_FORMA_CATEGORY } from './constants'
import { flattenExpenseCategories } from './helpers'

const AUTOMATION_MESSAGES = {
  ERROR: `We're not able to complete the document processing. Please fill out this page as usual.`,
  SUCCESS: 'Your automation ran successfully. Verify the expense history data for accuracy.',
}

type AutomationContainerProps = {
  form: ExtendedFormApi
  makeUndoable: (...args: any[]) => any
  clientPortalJobLink: string
  reportId: string
  isStrictMode: boolean
}

const RUN_STATUS_SUCCESS = 'success'
const RUN_STATUS_FAIL = 'fail'

type RunStatus = typeof RUN_STATUS_SUCCESS | typeof RUN_STATUS_FAIL | null

const constructSuccessMessage = (clientPortalJobLink: string) => {
  return (
    <div style={{ display: 'flex', flexDirection: 'column' }}>
      <div>Your automation ran successfully. We were able to automate from the following sources;</div>
      <ul style={{ marginTop: 0, marginBottom: 0 }}>
        <li>
          <a href={clientPortalJobLink} target="_blank" rel="noreferrer">
            <u>{CATEGORY_LABEL_MAP[EXPENSE_HISTORY_CATEGORY]}</u>
          </a>
        </li>
        <li>
          <a href={clientPortalJobLink} target="_blank" rel="noreferrer">
            <u>{CATEGORY_LABEL_MAP[PRO_FORMA_CATEGORY]}</u>
          </a>
        </li>
      </ul>
    </div>
  )
}

const constructWarningMessage = (
  expenseHistoryRunStatus: RunStatus,
  proFormaRunStatus: RunStatus,
  clientPortalJobLink: string
) => {
  if (
    (!expenseHistoryRunStatus && !proFormaRunStatus) ||
    (!expenseHistoryRunStatus && proFormaRunStatus === RUN_STATUS_FAIL) ||
    (!proFormaRunStatus && expenseHistoryRunStatus === RUN_STATUS_FAIL) ||
    (proFormaRunStatus === RUN_STATUS_SUCCESS && expenseHistoryRunStatus === RUN_STATUS_SUCCESS)
  ) {
    return null
  }

  const MISSING_DOCUMENTS_MESSAGE =
    'Your automation was partially successful. The following categories did not contain accepted documents;'
  const ERROR_OCCURRED_MESSAGE =
    "Your automation was partially successful. We're not able to complete the document processing for the following categories;"

  const warningTitle =
    !expenseHistoryRunStatus || !proFormaRunStatus ? MISSING_DOCUMENTS_MESSAGE : ERROR_OCCURRED_MESSAGE

  return (
    <div style={{ display: 'flex', flexDirection: 'column' }}>
      <div>{warningTitle}</div>
      <ul style={{ marginTop: 0, marginBottom: 0 }}>
        {expenseHistoryRunStatus !== RUN_STATUS_SUCCESS && (
          <li>
            <a href={clientPortalJobLink} target="_blank" rel="noreferrer">
              <u>{CATEGORY_LABEL_MAP[EXPENSE_HISTORY_CATEGORY]}</u>
            </a>
          </li>
        )}
        {proFormaRunStatus !== RUN_STATUS_SUCCESS && (
          <li>
            <a href={clientPortalJobLink} target="_blank" rel="noreferrer">
              <u>{CATEGORY_LABEL_MAP[PRO_FORMA_CATEGORY]}</u>
            </a>
          </li>
        )}
      </ul>
    </div>
  )
}

const AutomationContainer: FC<AutomationContainerProps> = ({
  form,
  makeUndoable,
  clientPortalJobLink,
  reportId,
  isStrictMode,
}) => {
  const [expenseHistoryRunStatus, setExpenseHistoryRunStatus] = useState<RunStatus>(null)
  const [proFormaRunStatus, setProFormaRunStatus] = useState<RunStatus>(null)

  const formValues = get(form, 'values', {})
  const { expenseHistoryAutomationMetadata, proFormaAutomationMetadata } = formValues

  const expenseHistoryImportStatus = expenseHistoryAutomationMetadata?.importStatus
  const proFormaImportStatus = proFormaAutomationMetadata?.importStatus

  const runAutomation = makeUndoable(async (category: typeof PRO_FORMA_CATEGORY | typeof EXPENSE_HISTORY_CATEGORY) => {
    const automatedData = await Api.runAutomation(category, reportId)

    form.batch(() => {
      const expenseCategories = isStrictMode
        ? flattenExpenseCategories(automatedData.expenseCategories)
        : automatedData.expenseCategories

      form.change('expenseCategories', expenseCategories)
      form.change('expenseHistory', automatedData.expenseHistory)

      switch (category) {
        case EXPENSE_HISTORY_CATEGORY:
          form.change('expenseHistoryAutomationMetadata', automatedData.expenseHistoryAutomationMetadata)
          break
        case PRO_FORMA_CATEGORY:
          form.change('proFormaAutomationMetadata', automatedData.proFormaAutomationMetadata)
          break
        default:
          break
      }
    })
  })

  const onAutomationRun = async () => {
    setExpenseHistoryRunStatus(null)
    setProFormaRunStatus(null)

    let expenseHistoryRun: RunStatus = null
    let proFormaRun: RunStatus = null

    if (!expenseHistoryImportStatus && !proFormaImportStatus) {
      return
    }

    if (expenseHistoryImportStatus === IMPORT_STATUS_READY) {
      try {
        await runAutomation(EXPENSE_HISTORY_CATEGORY)
        expenseHistoryRun = RUN_STATUS_SUCCESS
      } catch (error) {
        expenseHistoryRun = RUN_STATUS_FAIL
      }
    }

    if (proFormaImportStatus === IMPORT_STATUS_READY) {
      try {
        await runAutomation(PRO_FORMA_CATEGORY)
        proFormaRun = RUN_STATUS_SUCCESS
      } catch (error) {
        proFormaRun = RUN_STATUS_FAIL
      }
    }

    setExpenseHistoryRunStatus(expenseHistoryRun)
    setProFormaRunStatus(proFormaRun)

    if (
      (!expenseHistoryRun && proFormaRun === 'fail') ||
      (!proFormaRun && expenseHistoryRun === 'fail') ||
      (proFormaRun === 'fail' && expenseHistoryRun === 'fail')
    ) {
      throw new Error('Automation failed')
    }
  }

  const areDocumentsImporting =
    expenseHistoryImportStatus === IMPORT_STATUS_STARTED || proFormaImportStatus === IMPORT_STATUS_STARTED

  const expenseHistoryImportError = expenseHistoryImportStatus === IMPORT_STATUS_ERROR
  const proFormaImportError = proFormaImportStatus === IMPORT_STATUS_ERROR

  const expenseHistoryImportReady = expenseHistoryImportStatus === IMPORT_STATUS_READY
  const proFormaImportReady = proFormaImportStatus === IMPORT_STATUS_READY

  /*
   * if both status is undefined, should be disabled
   * If `expenseHistoryImportStatus` or `proFormaImportStatus` in `IMPORT_STATUS_STARTED` state, should be disabled
   * If one of `expenseHistoryImportStatus` or `proFormaImportStatus` is missing,
   *    and another is NOT in `IMPORT_STATUS_READY` should be disabled
   * If one of `expenseHistoryImportStatus` or `proFormaImportStatus` in `IMPORT_STATUS_ERROR` state,
   *    and another is NOT in `IMPORT_STATUS_READY` should be disabled
   * If both in `IMPORT_STATUS_ERROR` should be disabled
   */
  const disableCTA =
    (!expenseHistoryImportStatus && !proFormaImportStatus) ||
    areDocumentsImporting ||
    ((expenseHistoryImportError || proFormaImportError) && !(expenseHistoryImportReady || proFormaImportReady)) ||
    (expenseHistoryImportError && proFormaImportError)

  return (
    <AutomationCTA
      CTAMessage={
        <>
          Automate the expense history table with approved documents from the{' '}
          <a href={clientPortalJobLink} target="_blank" rel="noreferrer">
            <u>Due Diligence Client Portal</u>
          </a>
          .
        </>
      }
      successMessage={constructSuccessMessage(clientPortalJobLink)}
      errorMessage={AUTOMATION_MESSAGES.ERROR}
      warningMessage={constructWarningMessage(expenseHistoryRunStatus, proFormaRunStatus, clientPortalJobLink)}
      areDocumentsImporting={areDocumentsImporting}
      disableCTA={disableCTA}
      onAutomationRun={onAutomationRun}
    />
  )
}

export default connect(state => ({
  clientPortalJobLink: get(state, 'report.reportData.clientPortalJobLink'),
  reportId: get(state, 'report.reportData._id'),
}))(AutomationContainer)
