import React from 'react'
import PropTypes from 'prop-types'

import FullScreenDialog from '../shared/FullScreenDialog'

import MapMaker from './MapMaker'

const DIALOG_TITLE = 'Create Map'
const COMFIRM_DIALOG_TITLE = 'Close Map Maker?'
const CONFIRM_DIALOG_CONTENT = 'Map Maker does not save changes. If you close it you will need to start over.'
const DATA_QA_CLOSE_ICON = 'map-maker-close-btn'
const DATA_QA_DIALOG = 'map-maker-dialog'

const MapMakerDialog = ({ initialProperties, open, onClose, onSave }) => (
  <FullScreenDialog
    confirm={onClose}
    content={{
      dialogTitle: DIALOG_TITLE,
      confirmDialogTitle: COMFIRM_DIALOG_TITLE,
      confirmDialogContent: CONFIRM_DIALOG_CONTENT,
    }}
    dataQAAttributes={{ closeIcon: DATA_QA_CLOSE_ICON, dialog: DATA_QA_DIALOG }}
    open={open}
  >
    <MapMaker initialProperties={initialProperties} onSave={onSave} />
  </FullScreenDialog>
)

MapMakerDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onSave: PropTypes.func,
  initialProperties: PropTypes.arrayOf(PropTypes.object),
}

export default MapMakerDialog
