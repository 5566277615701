import { GeneratedText, addConditionals, gtLockedSentence } from '../../../../narrative/GeneratedText'

import { mapDTO, mapDataModel } from './mappings'

const generate = () => {
  const rentWord = gtLockedSentence('rentWord')
  const rentVerb = gtLockedSentence('rentVerb')
  const structureWord = gtLockedSentence('structureWord')
  const gt = new GeneratedText()
  gt.t`The `

  addConditionals(gt, {
    occupiedOnly: 'contract',
    vacantOnly: 'forecasted',
    vacantAndOccupied: 'contract and forecasted',
  })

  gt.t` ${rentWord} ${rentVerb} based on the following expense ${structureWord}:`

  return gt.getParts()
}

export default { generate, mapDTO, mapDataModel }
