/* eslint-disable guard-for-in */
/* eslint-disable no-restricted-syntax */
import { find, get, keyBy, valuesIn } from 'lodash'

import { SUBJECT_UNIT_GROUPING_TYPES, UNCATEGORIZED_UNIT_COMP_GROUP_KEY } from 'shared/constants/incomeApproach'

export const sortUnitGroups = (unitGroups = []) => {
  function sortMethod(groupA, groupB) {
    for (let i = 0; i < groupA.groupingParameters.length; i++) {
      if (groupA.groupingParameters[i].value > groupB.groupingParameters[i].value) {
        return 1
      }
      if (groupA.groupingParameters[i].value < groupB.groupingParameters[i].value) {
        return -1
      }
    }
    return 0
  }
  return unitGroups.sort(sortMethod)
}
export const extractGroupingParameters = (unit, groupingType) => {
  const groupingLogic = SUBJECT_UNIT_GROUPING_TYPES[groupingType]
  const parameters = []
  const nameParts = []
  const keyParts = []

  for (const parameter of groupingLogic) {
    const parameterValue = get(unit, parameter.path, null)
    parameters.push({
      path: parameter.path,
      value: parameterValue,
    })

    if (typeof parameter.label === 'function') {
      nameParts.push(parameter.label(parameterValue))
    } else {
      nameParts.push(parameter.label.replace('{0}', parameterValue))
    }

    keyParts.push(`${parameter.path}_${parameterValue}`)
  }

  return {
    parameters,
    name: nameParts.join(' '),
    key: keyParts.join('_'),
  }
}

/*
 * Unit groups and unit comp groups
 */
export const regroupSubjectUnits = (groupingType, currentUnitGroups, subjectUnits) => {
  const groupsByKey = keyBy(currentUnitGroups || [], group => group.key)

  // TODO: Think of a better way to reset units
  for (const groupKey in groupsByKey) {
    groupsByKey[groupKey].units = []
  }

  subjectUnits.forEach(unit => {
    const groupingParameters = extractGroupingParameters(unit, groupingType)
    const groupKey = groupingParameters.key
    if (!groupsByKey.hasOwnProperty(groupKey)) {
      groupsByKey[groupKey] = {
        key: groupingParameters.key,
        name: groupingParameters.name,
        groupingParameters: groupingParameters.parameters,
        units: [],
      }
    }
    groupsByKey[groupKey].units.push(unit)
  })

  for (const groupKey in groupsByKey) {
    if (groupsByKey[groupKey].units.length === 0) {
      delete groupsByKey[groupKey]
    }
  }

  return sortUnitGroups(valuesIn(groupsByKey))
}

export const regroupUnitComps = (currentUnitCompGroups, regroupedUnitGroups) => {
  const unitGroupsByKey = keyBy(regroupedUnitGroups, group => group.key)
  const uncategorizedCompGroup = find(currentUnitCompGroups, {
    unitGroupKey: UNCATEGORIZED_UNIT_COMP_GROUP_KEY.key,
  }) || {
    unitGroupName: UNCATEGORIZED_UNIT_COMP_GROUP_KEY.name,
    unitGroupKey: UNCATEGORIZED_UNIT_COMP_GROUP_KEY.key,
    units: [],
  }

  const compGroups = []

  for (const unitGroup of regroupedUnitGroups) {
    const unitCompGroup = find(currentUnitCompGroups, { unitGroupKey: unitGroup.key })
    if (!unitCompGroup) {
      compGroups.push({
        unitGroupName: unitGroup.name,
        unitGroupKey: unitGroup.key,
        units: [],
      })
    } else {
      compGroups.push(unitCompGroup)
    }
  }

  for (const unitCompGroup of currentUnitCompGroups) {
    if (unitCompGroup === uncategorizedCompGroup) {
      continue
    }
    const unitGroup = unitGroupsByKey[unitCompGroup.unitGroupKey]
    if (unitGroup === undefined) {
      uncategorizedCompGroup.units.push(...unitCompGroup.units)
    }
  }

  if (uncategorizedCompGroup.units.length > 0) {
    compGroups.push(uncategorizedCompGroup)
  }

  return compGroups
}
