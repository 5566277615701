import React from 'react'
import PropTypes from 'prop-types'
import { startCase } from 'lodash'

import { Grid, TableCell, TableRow } from '@mui/material'

import { fromPercents, toPercents } from 'client-shared/utils/numberOperations'

import TableNumberField from '../Table/TableNumberRightAligned'

class DiscountRateRows extends React.PureComponent {
  render() {
    const { name, disabled } = this.props
    return (
      <>
        <TableRow>
          <TableCell>
            <Grid container>
              <Grid item xs={6}>
                Discount Rate of
              </Grid>
              <Grid item xs={3}>
                <TableNumberField
                  name={`${name}DiscountPercentage`}
                  disabled={disabled}
                  allowNegative={false}
                  autoComplete="off"
                  data-qa={`${name}-discount-rate-input`}
                  decimalScale={0}
                  format={toPercents}
                  normalize={fromPercents}
                  fullWidth={false}
                  margin="none"
                  noPx
                  noPy
                  placeholder="0"
                  adornment="%"
                  right
                />
              </Grid>
            </Grid>
          </TableCell>
          <TableCell>
            <Grid container>
              <Grid item xs={5}>
                annualized, for
              </Grid>
              <Grid item xs={4}>
                <TableNumberField
                  name={`${name}MonthsDiscounted`}
                  disabled={disabled}
                  data-qa={`${name}-discount-months-input`}
                  adornment="months"
                  allowNegative={false}
                  autoComplete="off"
                  margin="none"
                  noPx
                  noPy
                  placeholder="0"
                  right
                />
              </Grid>
            </Grid>
          </TableCell>
          <TableCell>
            <TableNumberField
              allowNegative={false}
              autoComplete="off"
              margin="none"
              name={`${name}DiscountRate`}
              decimalScale={4}
              disabled
              noPx
              noPy
              placeholder="0.00"
              right
            />
          </TableCell>
          <TableCell>&nbsp;</TableCell>
          <TableCell>&nbsp;</TableCell>
        </TableRow>
        <TableRow>
          <TableCell>{startCase(name)} Market Value with discounting applied</TableCell>
          <TableCell>&nbsp;</TableCell>
          <TableCell>
            <TableNumberField
              allowNegative={false}
              autoComplete="off"
              decimalScale={2}
              margin="none"
              disabled
              name={`${name}MarketValueWithDiscount`}
              noPx
              noPy
              normalize={Math.abs}
              placeholder="$0.00"
              prefix="$"
              right
              thousandSeparator
            />
          </TableCell>
          <TableCell>&nbsp;</TableCell>
          <TableCell>&nbsp;</TableCell>
        </TableRow>
      </>
    )
  }
}

DiscountRateRows.propTypes = {
  name: PropTypes.string.isRequired,
}

export default DiscountRateRows
