import SalesCompsMap from '../../../forms/sales/ImprovedSales/SalesCompsMap'
import SalesAdjustmentGrid from '../../../forms/sales/ImprovedSales/SalesAdjustmentGrid'
import SaleValueConclusion from '../../../forms/sales/ImprovedSales/SaleValueConclusion'
import SalesCompsSearch from '../../../forms/sales/ImprovedSales/SalesCompsSearch'

export const MENU_TITLE_IMPROVED_SALES = 'Sales Comparison Approach'

export default [
  {
    key: 'improvedSales',
    name: MENU_TITLE_IMPROVED_SALES,
  },
  {
    key: 'improvedSalesCompsSearch',
    name: 'Find Comps',
    formComponent: SalesCompsSearch,
  },
  {
    key: 'improvedSalesAdjustmentGrid',
    name: 'Adjust Comps',
    formComponent: SalesAdjustmentGrid,
  },
  {
    key: 'improvedSalesCompsMap',
    name: 'Create Comp Map',
    formComponent: SalesCompsMap,
  },
  {
    key: 'improvedSaleValueConclusion',
    name: 'Value Conclusion',
    formComponent: SaleValueConclusion,
  },
]
