import OrganizationActionHandlers from '../admin/redux/sagas/organization'
import ReportsActionHandlers from '../reportsDashboard/redux/sagas'
import ClientsActionHandlers from '../admin/redux/sagas/clients'
import User from '../admin/redux/sagas/user'
import BuildingComp from '../etc/buildingComparables/redux/sagas'
import InsurableValues from '../admin/redux/sagas/insurableValues'
import authentication from '../authentication/redux/sagas'
import CmsActionHandlers from '../cms/redux/sagas/cms'

import ReportSagas from '../report/redux/sagas'

import shared from '../shared/redux/sagas'

export default function* rootSaga() {
  yield [
    ...authentication,
    ...shared,
    ...ReportsActionHandlers,
    ...OrganizationActionHandlers,
    ...ClientsActionHandlers,
    ...User,
    ...BuildingComp,
    ...InsurableValues,
    ...ReportSagas,
    ...CmsActionHandlers,
  ]
}
