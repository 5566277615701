import React from 'react'
import PropTypes from 'prop-types'
import { isNil } from 'lodash'
import { Button, Grid, Typography } from '@material-ui/core'

import { Box } from '@mui/material'

import { GEOGRAPHY_OPTIONS, WIZARD_STEPS } from '../../../../../../shared/constants/properties'

import SearchResults from '../../../../../../shared/components/PropertyWizard/SearchResults/SearchResults'

import { Loading } from '../../../../../../shared/components'

import DialogHeader from '../../../../../../shared/components/DialogHeader'

import { compplexAddressSearch, withSearch } from './helpers/withSearch'
import { Logo } from './helpers/Logo'
import { NewYorkCityResultsTable } from './helpers/NewYorkCityResultsTable'
import { NewJerseyResultsTable } from './helpers/NewJerseyResultsTable'
import { OtherGeographyResultsTable } from './helpers/OtherGeographyResultsTable'

export class CompPlexSearchResults extends React.Component {
  static propTypes = {
    openDetails: PropTypes.func,
    onSubmit: PropTypes.func,
    selectedPropertyIndex: PropTypes.number,
    openPreviousStep: PropTypes.func,
    addressInfo: PropTypes.object,
  }

  state = {
    properties: [],
    locationIdentifier: null,
    isLoading: false,
  }

  componentDidMount() {
    this.getCompPlexAddressSearchResults()
  }

  getCompPlexAddressSearchResults = async () => {
    this.setState({ isLoading: true })
    const { addressInfo } = this.props
    const { properties, locationIdentifier } = await compplexAddressSearch(addressInfo)
    this.setState({ isLoading: false, properties, locationIdentifier })
  }

  openAdvancedSearch = () => {
    const { openPreviousStep } = this.props
    openPreviousStep({ step: WIZARD_STEPS.ADVANCED_SEARCH })
  }

  handleClick = () => {
    const { selectedPropertyIndex, onSubmit } = this.props
    const { properties } = this.state
    const property = properties[selectedPropertyIndex]
    delete property.taxInfoLink
    onSubmit(property)
  }

  getFooter = () => {
    const { selectedPropertyIndex, handleClose } = this.props
    const { properties } = this.state
    const noResultsFound = properties.length === 0
    const hasExistingComp = properties.some(property => !!property.id)

    if (noResultsFound) {
      return (
        <Grid container justify="space-between" alignItems="center">
          <Grid item>
            <Button color="primary" variant="outlined" onClick={handleClose}>
              Cancel
            </Button>
          </Grid>
          <Grid item xs={6}>
            <Button color="primary" variant="contained" onClick={this.openAdvancedSearch}>
              Advanced Search
            </Button>
          </Grid>
        </Grid>
      )
    }
    return (
      <Grid container justify="space-between" alignItems="center">
        {hasExistingComp && (
          <Grid item xs={6}>
            <Logo></Logo>
            <Typography variant="caption" inline>
              = Comp exists in Bowery database
            </Typography>
          </Grid>
        )}
        <Grid item xs container spacing={16} justify="flex-end">
          <Grid item>
            <Button
              color="primary"
              variant="contained"
              disabled={isNil(selectedPropertyIndex)}
              onClick={this.handleClick}
              data-qa="create-new-btn"
            >
              Create New
            </Button>
          </Grid>
          <Grid item>
            <Button color="primary" variant="outlined" onClick={handleClose} data-qa="cancel-btn">
              Cancel
            </Button>
          </Grid>
        </Grid>
      </Grid>
    )
  }
  renderSearchResultsTitle = () => {
    const { properties } = this.state
    const { handleClose } = this.props
    const resultsMessage =
      !!properties.length && `${properties.length} Possible Address${properties.length > 1 ? 'es' : ''} Found`

    return (
      <DialogHeader onClose={handleClose}>
        <Grid container justify="space-between" alignItems="center">
          <Grid item>
            <Typography variant="h6">Search Results</Typography>
          </Grid>
          <Grid item>
            <Typography variant="body1">{resultsMessage}</Typography>
          </Grid>
        </Grid>
      </DialogHeader>
    )
  }

  render() {
    const { properties, locationIdentifier, isLoading } = this.state
    const { onSubmit, openDetails, selectedPropertyIndex, openPreviousStep, ...restProps } = this.props
    const tableProps = {
      openDetails,
      onSubmit,
      selectedPropertyIndex,
    }

    return (
      <>
        {this.renderSearchResultsTitle()}
        {isLoading ? (
          <Box style={{ minWidth: '600px', height: '240px', padding: '13px 19px 13px 61px' }}>
            <Loading />
          </Box>
        ) : (
          <SearchResults
            {...restProps}
            onSubmit={onSubmit}
            properties={properties}
            selectedPropertyIndex={selectedPropertyIndex}
            openPreviousStep={openPreviousStep}
            footer={this.getFooter()}
            GeographyTable={({ ...restProps }) => {
              switch (locationIdentifier) {
                case GEOGRAPHY_OPTIONS.NY:
                  return <NewYorkCityResultsTable {...tableProps} {...restProps} />
                case GEOGRAPHY_OPTIONS.NJ:
                  return <NewJerseyResultsTable {...tableProps} {...restProps} />
                default:
                  return <OtherGeographyResultsTable {...tableProps} {...restProps} />
              }
            }}
          />
        )}
      </>
    )
  }
}

export default withSearch(CompPlexSearchResults)
