import React from 'react'
import PropTypes from 'prop-types'

import { Stack, Typography } from '@mui/material'

const CategoriesWrapper = ({ children, title }) => (
  <Stack spacing={2}>
    <Typography variant="h6">{title}</Typography>
    {children}
  </Stack>
)

CategoriesWrapper.propTypes = {
  children: PropTypes.node.isRequired,
  title: PropTypes.string.isRequired,
}

export default CategoriesWrapper
