import React from 'react'
import { useForm } from 'react-final-form'
import { Grid, Typography } from '@material-ui/core'

import economicLife from 'shared/utils/textGeneration/property/economicLife'

import { Number, NarrativeComponent } from '../../../../shared/components'

const EconomicLife = ({ valueAsComplete, yearBuilt }) => {
  const formApi = useForm()
  return (
    <React.Fragment>
      <Grid container spacing={16}>
        <Grid item xs={12}>
          <Typography variant="h6">{valueAsComplete ? 'As Complete' : 'As Is'} Remaining Economic Life</Typography>
        </Grid>
        <Grid item xs={4}>
          <Number name="totalEconomicLife" label="Total Economic Life" adornment="years" allowNegative={false} />
        </Grid>
        <Grid item xs={4}>
          <Number name="effectiveAge" label="Effective Age" adornment="years" allowNegative={false} />
        </Grid>
      </Grid>
      <NarrativeComponent
        name="economicLifeDiscussion"
        title={`${valueAsComplete ? 'As Complete' : 'As Is'} Remaining Economic Life Export`}
        data={economicLife.mapDTO({ ...formApi.getState().values, valueAsComplete, yearBuilt })}
        generatedText={economicLife.generate}
        tooltipText="The following text will appear in the Description of Improvements section of your report."
      />
    </React.Fragment>
  )
}

export default EconomicLife
