import ViewList from '@mui/icons-material/ViewList'
import Domain from '@mui/icons-material/Domain'
import AccountBox from '@mui/icons-material/AccountBox'
import ExitToApp from '@mui/icons-material/ExitToApp'
import Web from '@mui/icons-material/Web'

import { LOGIN_PATH } from '../shared/constants'

export default function getRoutes(user) {
  return [
    {
      path: '/',
      isLocation: true,
    },
    {
      path: '/reports',
      title: 'Reports',
      icon: ViewList,
    },

    {
      path: '/user',
      title: user ? user.fullName : 'User',
      icon: AccountBox,
    },
    {
      path: '/organization',
      title: 'Organization',
      icon: Domain,
    },
    {
      path: LOGIN_PATH,
      title: 'Logout',
      icon: ExitToApp,
      isLogoutAction: true,
    },
    {
      path: '/cms',
      title: 'Content Management',
      icon: Web,
    },
  ]
}

const REPORT_ROUTE = {
  path: '/reports',
  icon: ViewList,
}

const USER_ROUTE = {
  path: '/user',
  icon: AccountBox,
}

const ORGANIZATION_ROUTE = {
  path: '/organization',
  icon: Domain,
}

const LOGOUT_ROUTE = {
  path: LOGIN_PATH,
  icon: ExitToApp,
}

export { REPORT_ROUTE, USER_ROUTE, ORGANIZATION_ROUTE, LOGOUT_ROUTE }
