import React from 'react'
import PropTypes from 'prop-types'
import { compose, pure } from 'recompose'
import { withStyles } from '@material-ui/core'

import { REPORTS_DASHBOARD_MENU_WRAPPER_ID } from '../constants'

import Sidebar from '../../shared/layout/Sidebar'
import { HEADER_HEIGHT } from '../../shared/layout/constants'

import Notifications from '../../shared/components/Notifications'

import Header from './Header'

const topNavigationHeight = 60

const styles = {
  layoutContainer: {
    width: '100%',
    display: 'flex',
  },
  contentContainer: {
    flex: 1,
  },
  reportDashboard: {
    margin: `20px ${HEADER_HEIGHT}px 0`,
  },
  notifications: {
    position: 'fixed',
    top: topNavigationHeight,
    right: 0,
    zIndex: 3000,
  },
}

const PageLayout = ({ children, classes, openReportModal }) => (
  <React.Fragment>
    <Header openReportModal={openReportModal} />
    <div className={classes.notifications}>
      <Notifications />
    </div>
    <div className={classes.layoutContainer}>
      <Sidebar />
      <div className={classes.contentContainer}>
        <div id={REPORTS_DASHBOARD_MENU_WRAPPER_ID} />
        <div className={classes.reportDashboard}>{children}</div>
      </div>
    </div>
  </React.Fragment>
)

PageLayout.propTypes = {
  children: PropTypes.node.isRequired,
  openReportModal: PropTypes.func.isRequired,
}

export default compose(pure, withStyles(styles))(PageLayout)
