import { GeneratedText } from '../../../../narrative/GeneratedText'

export const COMPARISON_SUMMARY =
  "The following table illustrates the developer's total direct costs to the MVS calculations:"

const generate = () => {
  const gt = new GeneratedText()

  gt.t`${COMPARISON_SUMMARY}`

  return gt.getParts()
}

export default { generate }
