import { formatCurrencyFloat } from 'client-shared/utils/numberFormatters'

export const ADJUSTMENT_AND_LOSSES_DATA_SOURCE = {
  INCOME_APPROACH: 1,
  SALES_APPROACH: 2,
}

export const Labels = {
  ADD_ADJUSTMENT: '+ New Adjustment',
  ADD_COMMERCIAL_RENT_LOSS: '+ New Commercial Rent Loss',
  ADD_DEDUCTION: '+ New Deduction',
  ADD_RENT_LOSS: '+ New Residential Rent Loss',
  ROUND_TO_NEAREST: 'Round to Nearest',
}

export const ROUNDING_OPTIONS = [10000, 25000, 50000, 100000, 500000, 1000000, 5000000, 10000000, 50000000]

export const ROUNDING_ITEMS = ROUNDING_OPTIONS.map(value => ({
  label: formatCurrencyFloat(value),
  value,
}))

export const ADJUSTMENT_TYPES = {
  AS_COMPLETE: 'As Complete',
  AS_STABILIZED: 'As Stabilized',
  NPV: 'Net Present Value',
}

export const ADJUSTMENT_COLLECTIONS = {
  AS_COMPLETE: 'asCompleteNpvAdjustments',
  AS_STABILIZED: 'asStabilizedNpvAdjustments',
  NPV: 'npvAdjustments',
}

export const LOSS_ITEM_DATA_QA = {
  BUYOUT_COST: 'buyoutCost',
  COMMERCIAL_COMMUNITY_RENT_LOSS: 'communityFacilityRentLoss',
  COMMERCIAL_INDUSTRIAL_RENT_LOSS: 'industrialCommercialRentLoss',
  COMMERCIAL_MEDICAL_RENT_LOSS: 'medicalOfficeRentLoss',
  COMMERCIAL_OFFICE_RENT_LOSS: 'officeRentLoss',
  COMMERCIAL_RETAIL_RENT_LOSS: 'retailRentLoss',
  COMMERCIAL_UNDETERMINED_RENT_LOSS: 'undeterminedCommercialRentLoss',
  COMMISSION_FEE: 'commissionFee',
  ENTREPRENEURIAL_PROFIT: 'entrepreneurialProfit*',
  LAUNDRY_RENT_LOSS: 'laundryRentLoss',
  OTHER_RENT_LOSS: 'otherRentLoss',
  PARKING_RENT_LOSS: 'parkingRentLoss',
  RENOVATION_BUDGET: 'renovationBudget',
  STORAGE_RENT_LOSS: 'storageRentLoss',
}
