import React from 'react'
import PropTypes from 'prop-types'

import { Grid, withStyles } from '@material-ui/core'
import { get, sumBy } from 'lodash'
import {
  useRowsApi,
  ColumnDataTypeEnum,
  RowBasedTable,
  CELL_COLUMN_CONFIGURATIONS,
} from '@bowery-valuation/ui-components'

import { Field } from 'react-final-form'

import { getTaxLiabilityLabel } from 'shared/report-calculations/income-approach/tax/helpers'

import TaxCalculations from 'shared/report-calculations/income-approach/tax/tax-calculations'

import {
  formatCurrencyFloat,
  formatCurrencyInt,
  formatPercentageString,
  toPercentageString,
} from '../../../../shared/utils/numberFormatters'
import { GeneratedComment, ButtonSwitch, CheckboxWithLabel } from '../../../../shared/components'

import { LABELS } from '../../../../../../shared/constants/report/incomeApproach/taxes'

import AddTaxRateButton from './AddTaxRateButton'
import {
  RENOVATION_COST_TYPE_OPTIONS,
  CELL_EDITOR_SELECTORS,
  valueCellRendererFramework,
  getCustomColumnConfig,
  additionalTaxRateToRow,
  projectedTabNoopFieldValues,
} from './constants'
import styles from './styles'
import { useRegisteredFields } from './hooks'

const getPercentOfRenovationsApproachWriteup = values => {
  const assessmentRatio = toPercentageString(get(values, 'projected.percentOfRenovations.assessmentRatio'))
  const renovationCostType = get(values, 'projected.percentOfRenovations.renovationCostType').toLowerCase()
  return (
    `The percent of renovations method takes a percentage of the total or hard net renovations costs added` +
    ` to the current taxable assessed value to arrive at the projected taxable assessed value.` +
    ` We have applied ${assessmentRatio} to the ${renovationCostType} net renovation costs.`
  )
}

const ROW_IDS = {
  netRenovationCost: 'netRenovationCost',
  assessmentRatio: 'assessmentRatio',
}

const FIELD_PATHS = {
  additionalTaxRates: 'projected.percentOfRenovations.additionalTaxRates',
  assessmentRatio: 'projected.percentOfRenovations.assessmentRatio',
  netRenovationCost: 'projected.percentOfRenovations.netRenovationCost',
}

const columns = [
  {
    name: 'label',
    label: 'Item',
    suppressMovable: true,
    itemIndex: 0,
    type: ColumnDataTypeEnum.text,
    permanent: true,
    align: 'left',
    editable: params => {
      return !!params.data.isEditableRow
    },
  },
  {
    name: 'value',
    label: 'Value',
    suppressMovable: true,
    itemIndex: 1,
    type: ColumnDataTypeEnum.text,
    permanent: true,
    align: 'right',
    editable: ({ data }) => !!data.isEditableRow,
    cellEditorSelector: ({ data }) => {
      if (data.id === ROW_IDS.assessmentRatio) {
        return CELL_EDITOR_SELECTORS.percentTwoDecimals
      }

      if (data.id === ROW_IDS.netRenovationCost) {
        return CELL_EDITOR_SELECTORS.moneyNoDecimals
      }

      if (data.isAdditionalTaxRateRow) {
        return CELL_EDITOR_SELECTORS.taxRate
      }

      return CELL_COLUMN_CONFIGURATIONS[data.type]
    },
    cellRendererFramework: valueCellRendererFramework,
  },
]

const PercentOfRenovationsApproach = ({ taxRate, taxRateName, currentTaxableAssessedValue, form, classes }) => {
  const additionalTaxRatesRowsApi = useRowsApi(FIELD_PATHS.additionalTaxRates)

  const handleRowUpdate = row => {
    if (row.id === ROW_IDS.netRenovationCost) {
      form.change(FIELD_PATHS.netRenovationCost, row.value)
    } else if (row.id === ROW_IDS.assessmentRatio) {
      form.change(FIELD_PATHS.assessmentRatio, row.value)
    } else if (row.isAdditionalTaxRateRow) {
      additionalTaxRatesRowsApi.updateRow(row)
    }
  }

  const handleManyRowsUpdate = rows => {
    rows.forEach(row => {
      handleRowUpdate(row)
    })
  }

  const handleRowDelete = rowId => {
    const formValuesTemp = form.getState().values
    const taxRates = formValuesTemp.projected.percentOfRenovations.additionalTaxRates || []
    const rowIsTaxRate = taxRates.some(row => row.id === rowId)

    if (rowIsTaxRate) {
      additionalTaxRatesRowsApi.deleteRow(rowId)
    }
  }

  const handleCellFocus = event => {
    const rowIndex = event.rowIndex
    const columnId = event.column.colId
    const row = percentOfRenovationsRows[rowIndex]

    if (row.isAdditionalTaxRateRow) {
      const additionalTaxRatesRowIndex = additionalTaxRates.findIndex(({ id }) => id === row.id)
      form.focus(`${FIELD_PATHS.additionalTaxRates}[${additionalTaxRatesRowIndex}].${columnId}`)
    } else if (row.id === ROW_IDS.netRenovationCost) {
      form.focus(FIELD_PATHS.netRenovationCost)
    } else if (row.id === ROW_IDS.assessmentRatio) {
      form.focus(FIELD_PATHS.assessmentRatio)
    }
  }

  const { basis, residentialUnitCount, projected, squareFootage } = form.values
  const taxableAssessedValue =
    get(form, 'values.projected.percentOfRenovations.taxableAssessedValue') || currentTaxableAssessedValue

  const additionalTaxRates = get(form, 'values.projected.percentOfRenovations.additionalTaxRates')
  const netRenovationCost = get(form, 'values.projected.percentOfRenovations.netRenovationCost')
  const assessmentRatio = get(form, 'values.projected.percentOfRenovations.assessmentRatio')
  const increaseInTaxableAssessedValue = taxableAssessedValue - currentTaxableAssessedValue
  const effectiveTaxRate = (taxRate.value || 0) + sumBy(additionalTaxRates, 'value')

  const taxLiability = TaxCalculations.calculateTaxLiability(taxableAssessedValue, effectiveTaxRate, 0)

  const renovationCostType = get(form, 'values.projected.percentOfRenovations.renovationCostType')
  const renovationsTotals = get(form, 'values.renovationsTotals', {})

  const basisLabel = getTaxLiabilityLabel(basis)
  const taxesPerBasis = TaxCalculations.calculateTaxesPerBasis(basis, taxLiability, squareFootage, residentialUnitCount)
  const includedInExport = get(projected, 'percentOfRenovations.includedInExport', false)
  const taxRateRegisteredFields = useRegisteredFields(
    'projected.percentOfRenovations.additionalTaxRates',
    columns,
    additionalTaxRates
  )

  const percentOfRenovationsRows = [
    {
      readOnly: false,
      suppressMovable: true,
      permanent: false,
      type: ColumnDataTypeEnum.text,
      label: LABELS.NET_RENOVATIONS,
      id: ROW_IDS.netRenovationCost,
      value: netRenovationCost ? formatCurrencyInt(netRenovationCost) : '$0',
      isEditableRow: true,
      rowDef: { hideAction: true },
    },
    {
      readOnly: false,
      suppressMovable: true,
      permanent: false,
      type: ColumnDataTypeEnum.text,
      label: LABELS.ASSESSMENT_RATIO,
      value: assessmentRatio ? toPercentageString(assessmentRatio) : '0.00%',
      id: ROW_IDS.assessmentRatio,
      isEditableRow: true,
      rowDef: { hideAction: true },
    },
    {
      readOnly: true,
      suppressMovable: true,
      permanent: true,
      type: ColumnDataTypeEnum.text,
      label: LABELS.INCREASE_IN_TAV,
      value: formatCurrencyInt(increaseInTaxableAssessedValue),
      id: 'increaseInTav',
      rowDef: { hideAction: true },
    },
    {
      readOnly: true,
      suppressMovable: true,
      permanent: true,
      type: ColumnDataTypeEnum.text,
      id: 'currentTav',
      label: LABELS.CURRENT_TAV,
      value: formatCurrencyInt(currentTaxableAssessedValue),
      rowDef: { hideAction: true },
    },
    {
      readOnly: false,
      suppressMovable: true,
      permanent: false,
      type: ColumnDataTypeEnum.text,
      id: 'impliedTaxableAssessedValue',
      label: LABELS.IMPLIED_TAXABLE_ASSESSED_VALUE,
      value: taxableAssessedValue ? formatCurrencyInt(taxableAssessedValue) : '$0',
      rowDef: { hideAction: true },
    },
    {
      readOnly: true,
      suppressMovable: true,
      permanent: true,
      type: ColumnDataTypeEnum.text,
      id: 'taxRateValue',
      label: `${LABELS.TAX_RATE} (${taxRateName})`,
      value: formatPercentageString(taxRate.value, 10),
      rowDef: { hideAction: true },
    },
    ...additionalTaxRates.map(additionalTaxRateToRow),
    {
      readOnly: true,
      suppressMovable: true,
      permanent: true,
      type: ColumnDataTypeEnum.text,
      id: 'taxLiabilityTotal',
      label: LABELS.TAX_LIABILITY_TOTAL,
      value: formatCurrencyInt(taxLiability),
      rowDef: { summary: true },
    },
    {
      readOnly: true,
      suppressMovable: true,
      permanent: true,
      type: ColumnDataTypeEnum.text,
      id: 'taxesPerBasis',
      label: basisLabel,
      value: formatCurrencyFloat(taxesPerBasis),
      rowDef: { hideAction: true },
    },
  ]

  return (
    <Grid container justify="space-between" alignItems="center">
      <Grid item xs={12}>
        <Field name={FIELD_PATHS.netRenovationCost}>{() => null}</Field>
        <Field name={FIELD_PATHS.assessmentRatio}>{() => null}</Field>
        <CheckboxWithLabel name="projected.percentOfRenovations.includedInExport" label="Include in export" />
      </Grid>
      {includedInExport && (
        <>
          <Grid item xs={6} className={classes.buttonSwitch}>
            <ButtonSwitch
              name="projected.percentOfRenovations.renovationCostType"
              options={RENOVATION_COST_TYPE_OPTIONS}
              label={() => <span>Renovation Cost</span>}
              annotation={() => (
                <span>{`${renovationCostType} = ${formatCurrencyInt(renovationsTotals[renovationCostType])}`}</span>
              )}
            />
          </Grid>
          <Grid item>
            <AddTaxRateButton form={form} path="projected.percentOfRenovations" />
          </Grid>
          <Grid item xs={12}>
            {taxRateRegisteredFields}
            <RowBasedTable
              id="percent-of-renovation"
              columns={columns}
              rows={percentOfRenovationsRows}
              {...projectedTabNoopFieldValues}
              onRowDelete={handleRowDelete}
              onRowUpdate={handleRowUpdate}
              onManyRowsUpdate={handleManyRowsUpdate}
              getCustomColumnConfig={getCustomColumnConfig}
              onCellFocused={handleCellFocus}
            />

            <Grid item xl={12} className={classes.discussionContainer}>
              <GeneratedComment
                className={classes.title}
                isDynamicContent
                dataPath="projected.percentOfRenovations.discussion"
                title="Generated Commentary"
                label="Percent of Renovations Discussion"
                getGeneratedText={getPercentOfRenovationsApproachWriteup}
              />
            </Grid>
          </Grid>
        </>
      )}
    </Grid>
  )
}

PercentOfRenovationsApproach.propTypes = {
  currentTaxableAssessedValue: PropTypes.number,
  taxRate: PropTypes.shape({
    value: PropTypes.number,
    effectiveYear: PropTypes.string,
  }).isRequired,
  taxRateName: PropTypes.string,
}

PercentOfRenovationsApproach.defaultProps = {
  currentTaxableAssessedValue: 0,
}

export default withStyles(styles)(PercentOfRenovationsApproach)
