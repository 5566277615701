import React from 'react'

import { Dialog, DialogActions, DialogContent, DialogContentText, Button, Stack, Typography, Link } from '@mui/material'

import DialogHeader from 'client-shared/components/DialogHeader'

type SourceLinksModalProps = {
  open: boolean
  sourceReports: {
    id: string
    address: string
  }[]
  generateSourceLink: (reportId: string) => string
  onClose: () => {}
}

const SourceLinksModal: React.FC<SourceLinksModalProps> = ({ open, sourceReports, generateSourceLink, onClose }) => {
  return (
    <Dialog fullWidth open={open} data-qa="expense-comps-source-links-dialog" onClose={onClose}>
      <DialogHeader onClose={onClose}>
        <Typography variant="h6" component="span">
          Bowery Appraisal Reports
        </Typography>
      </DialogHeader>
      <DialogContent sx={{ paddingTop: 1, paddingBottom: 2 }}>
        <DialogContentText sx={{ color: 'rgba(34, 34, 34, 0.87)' }} pb={2}>
          Below is the list of Bowery appraisal reports used to inform expense comparables.
        </DialogContentText>
        <DialogContentText sx={{ color: 'rgba(34, 34, 34, 0.87)' }}>
          <Stack direction="column">
            {sourceReports.map(sourceReport => (
              <Stack direction="row" key={sourceReport.id}>
                <Typography variant="subtitle1" mr={1}>
                  [Report]
                </Typography>
                <Link style={{ cursor: 'pointer' }} href={generateSourceLink(sourceReport.id)} target="_blank">
                  {sourceReport.address}
                </Link>
              </Stack>
            ))}
          </Stack>
        </DialogContentText>
      </DialogContent>
      <Stack flexDirection="row" justifyContent="end" sx={{ paddingLeft: '24px' }}>
        <DialogActions>
          <Button variant="contained" onClick={onClose} data-qa="export-confirm-submit-btn">
            CLOSE
          </Button>
        </DialogActions>
      </Stack>
    </Dialog>
  )
}

export default React.memo(SourceLinksModal)
