import { get } from 'lodash'

import { GeneratedText, gtLocked } from '../../../narrative/GeneratedText'

const generate = () => {
  const gt = new GeneratedText()

  gt.t`Applying an appropriate equity dividend rate to the mortgage equity technique is an integral part`
  gt.t` of the valuation process. As previously stated, the equity rate of return is sensitive to the risk associated`
  gt.t` with the property, whether it be location, income flows, functional or physical obsolescence,`
  gt.t` and most important of all, the economic climate. First, we look at national surveys to understand appropriate`
  gt.t` equity dividend rates. The latest Realty Rates survey indicates an average equity dividend rate of`
  gt.t` ${gtLocked('avg', 'formatPercentageString')} and ranges from ${gtLocked(
    'min',
    'formatPercentageString'
  )} to ${gtLocked('max', 'formatPercentageString')}.`

  return gt.getParts()
}

const getInvestorSurveyValues = investorSurvey => {
  return {
    min: get(investorSurvey, 'min'),
    avg: get(investorSurvey, 'avg'),
    max: get(investorSurvey, 'max'),
  }
}

const mapDataFromFormValues = values => {
  const investorSurvey = get(values, 'investorSurvey', {})
  return getInvestorSurveyValues(investorSurvey)
}

const mapDataFromModel = organization => {
  const investorSurvey = get(organization, 'investorSurvey', {})
  return getInvestorSurveyValues(investorSurvey)
}

export default { generate, mapDataFromFormValues, mapDataFromModel }
