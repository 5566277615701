import React from 'react'
import classnames from 'classnames'
import { compose } from 'recompose'
import { withStyles, MenuItem, Table, TableCell, TableBody, TableHead, TableRow, Typography } from '@material-ui/core'

import MoreButton from '../../../../../../../shared/components/PropertyWizard/SearchResults/MoreButton'
import { shortDateWithZeroesFormat } from '../../../../../../../shared/utils/dateHelper'
import { formatCurrencyInt } from '../../../../../../../shared/utils/numberFormatters'
import styles from '../../../../../../../shared/components/PropertyWizard/SearchResults/styles'
import Link from '../../../../../../../shared/components/Link'

import Badge from '../../../Badge'

import { Logo } from './Logo'
import { formatFullAddress, getStatus, searchResultDataQA } from './helpers'

export class OtherGeographyResultsTable extends React.PureComponent {
  handleRowClick = index => () => {
    const { onPropertySelect, properties } = this.props
    if (!properties[index].id) {
      onPropertySelect(index)
    }
  }

  getOtherLinks = propertyOrComp => {
    if (!propertyOrComp.id) {
      return []
    }

    return [
      <MenuItem
        onClick={() => {
          this.props.openDetails(propertyOrComp)
        }}
        key="details-modal"
      >
        <Typography>Comp Details</Typography>
      </MenuItem>,
    ]
  }

  render() {
    const { properties, classes, selectedPropertyIndex, onSubmit } = this.props

    const hasComp = properties.some(property => !!property.id)
    return (
      <Table className={classes.table}>
        <TableHead>
          <TableRow>
            <TableCell className={classes.leadCell} />
            <TableCell className={classes.tableCell}>Address</TableCell>
            <TableCell className={classes.tableCell}>Status</TableCell>
            <TableCell className={classes.tableCell}>State</TableCell>
            <TableCell className={classes.tableCell}>City/Town</TableCell>
            <TableCell className={classes.tableCell}>Property Identifier Type</TableCell>
            <TableCell className={classes.tableCell}>Property Identifier</TableCell>
            {hasComp && (
              <>
                <TableCell className={classes.tableCell}>Sale Price</TableCell>
                <TableCell className={classes.tableCell}>Transaction Date</TableCell>
                <TableCell className={classes.selectCompCell}></TableCell>
              </>
            )}
          </TableRow>
        </TableHead>
        <TableBody>
          {properties.map((row, index) => {
            const status = getStatus(row)

            return (
              <TableRow
                onClick={this.handleRowClick(index)}
                className={classnames(classes.row, {
                  [classes.selectedRow]: index === selectedPropertyIndex,
                  [classes.compRow]: !!row.id,
                })}
                key={index}
                index={index}
                data-qa={searchResultDataQA(row.id)}
              >
                <TableCell data-qa={searchResultDataQA('more-btn')} className={classes.leadCell}>
                  <MoreButton fullAddress={formatFullAddress(row)} otherLinks={this.getOtherLinks(row)} />
                </TableCell>
                <TableCell data-qa={searchResultDataQA('street-address')} className={classes.tableCell}>
                  {row.id && <Logo></Logo>}
                  {row.propertyInformation?.displayStreetAddress || row.address.streetAddress || '-'}
                </TableCell>
                <TableCell data-qa={searchResultDataQA('status')} className={classes.tableCell}>
                  {status ? <Badge type={status} /> : '-'}
                </TableCell>
                <TableCell data-qa={searchResultDataQA('state')} className={classes.tableCell}>
                  {row.address.state || '-'}
                </TableCell>
                <TableCell data-qa={searchResultDataQA('city')} className={classes.tableCell}>
                  {row.propertyInformation?.displayCity || row.address.city || '-'}
                </TableCell>
                <TableCell data-qa={searchResultDataQA('property-identifier-type')} className={classes.tableCell}>
                  {row.address.propertyIdentification?.propertyIdentifierType || '-'}
                </TableCell>
                <TableCell data-qa={searchResultDataQA('property-identifier')} className={classes.tableCell}>
                  {row.address.propertyIdentification?.propertyIdentifier || '-'}
                </TableCell>
                {hasComp && (
                  <>
                    <TableCell data-qa={searchResultDataQA('sale-price')} className={classes.tableCell}>
                      {row.saleInformation?.salePrice ? formatCurrencyInt(row.saleInformation.salePrice) : '-'}
                    </TableCell>
                    <TableCell data-qa={searchResultDataQA('sale-date')} className={classes.tableCell}>
                      {row.saleDate?.toString() ? shortDateWithZeroesFormat(row.saleDate) : '-'}
                    </TableCell>
                    <TableCell className={classes.selectCompCell}>
                      {row.id ? (
                        <Link
                          data-qa="search-result-select-comp-link"
                          onClick={() => {
                            onSubmit(row)
                          }}
                        >
                          Select Comp
                        </Link>
                      ) : (
                        ''
                      )}
                    </TableCell>
                  </>
                )}
              </TableRow>
            )
          })}
        </TableBody>
      </Table>
    )
  }
}

OtherGeographyResultsTable = compose(withStyles(styles))(OtherGeographyResultsTable)
