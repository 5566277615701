import { get } from 'lodash'
import { push } from 'react-router-redux'
import { initFeatureFlagger, disposeFeatureFlagger } from '@bowery-valuation/feature-flagger-client'

import { call, put, takeLatest } from 'redux-saga/effects'

import { submitFormWithConfirmation } from '../../report/redux/sagas/report'
import * as Api from '../api'

import { BASE64URL_REGEX, JWT_STORAGE, LOGIN_PATH } from '../../shared/constants'

import {
  logoutUserReceive,
  logoutUserFail,
  loginUserReceive,
  loginUserRequest,
  loginUserFail,
  fetchUserRequest,
  fetchUserReceive,
  fetchUserFail,
} from './actions'

import { LOGOUT_USER, LOGOUT_USER_WITH_CONFIRMATION, LOGIN_USER, FETCH_USER } from './actionTypes'

const LAUNCHDARKLY_CLIENT_SIDE_ID = global.env.launchDarklyClientSideId
const DISABLE_LAUNCHDARKLY_CLIENT = String(global.env.disableLaunchDarklyClient)

function* logoutUser() {
  try {
    yield Api.logout()
  } catch (err) {
    yield put(logoutUserFail(err))
  } finally {
    localStorage.removeItem(JWT_STORAGE)
    yield disposeFeatureFlagger()
    yield put(logoutUserReceive())
    yield put(push(LOGIN_PATH))
  }
}

function* loginUser(action) {
  try {
    yield put(loginUserRequest(action))
    const { username, password } = get(action, 'payload')
    const result = yield Api.login(username, password)

    const userData = get(result, 'data', {})
    if (BASE64URL_REGEX.test(userData?.token) && userData?.user) {
      localStorage.setItem(JWT_STORAGE, userData.token)
      const roles = yield Api.fetchRoles()
      yield initFeatureFlagger(LAUNCHDARKLY_CLIENT_SIDE_ID, userData?.user?.username, DISABLE_LAUNCHDARKLY_CLIENT)
      yield put(loginUserReceive({ user: userData.user, roles }))
    } else {
      console.warn('Invalid Login Response', userData)
      yield put(loginUserFail('Invalid Login Response'))
    }
  } catch (err) {
    console.log(err)
    yield put(loginUserFail(err))
  }
}

function* fetchUser(action) {
  try {
    yield put(fetchUserRequest(action))

    const user = yield Api.fetchUser()
    const roles = yield Api.fetchRoles()
    yield initFeatureFlagger(LAUNCHDARKLY_CLIENT_SIDE_ID, user?.username, DISABLE_LAUNCHDARKLY_CLIENT)
    yield put(fetchUserReceive({ user, roles }))
  } catch (err) {
    yield put(fetchUserFail(err))
  }
}

function* logoutUserWithConfirmation() {
  const logout = yield call(submitFormWithConfirmation, { skipConfirmation: false, loggingOut: true })
  if (logout) {
    yield call(logoutUser)
  }
}

export default [
  takeLatest(LOGOUT_USER.ACTION, logoutUser),
  takeLatest(LOGIN_USER.ACTION, loginUser),
  takeLatest(FETCH_USER.ACTION, fetchUser),
  takeLatest(LOGOUT_USER_WITH_CONFIRMATION.ACTION, logoutUserWithConfirmation),
]
