import React from 'react'
import PropTypes from 'prop-types'
import { isEmpty, get } from 'lodash'
import { IconButton, Box, Stack } from '@mui/material'

import Edit from '@mui/icons-material/Edit'
import CloudUpload from '@mui/icons-material/CloudUpload'

import { Loading } from 'client-shared/components'

import { getCroppedImage, getOriginalImageUrl } from 'client-shared/utils/cloudinary'

import { AVATAR_WIDTH_LIMIT } from 'client-shared/constants/images'

import { SUPPORTED_IMAGE_FORMATS } from 'client-shared/constants/imageCategories'

import ReactDropzone from 'react-dropzone'

import * as Api from '../../../core/api'

import AvatarEditorModal from './AvatarEditorModal'

function loadImage(url) {
  return new Promise((resolve, reject) => {
    const img = new Image()
    img.src = url
    img.onload = function (event) {
      img.remove()
      resolve()
    }
    img.onerror = function (error) {
      img.remove()
      reject(error)
    }
  })
}

class AvatarEditor extends React.PureComponent {
  constructor(props) {
    super(props)
    this.dropzone = React.createRef()
  }

  state = {
    open: false,
    image: {
      cdnUrl: this.props.avatar.cdnUrl,
    },
    isLoading: false,
  }

  handleOpen = () => {
    this.setState({ open: true })
  }
  handleClose = () => {
    this.setState({ open: false, image: {} })
  }

  onDrop = async acceptedImages => {
    if (!isEmpty(acceptedImages)) {
      const [image] = acceptedImages
      this.setState({ isLoading: true })
      const response = await Api.uploadImages({
        images: [image],
        transformations: {
          width: AVATAR_WIDTH_LIMIT,
        },
      })

      this.setState({
        image: { cdnUrl: get(response, 'images.0.cdnUrl') },
        isLoading: false,
        open: true,
      })
    }
  }

  onImageSave = async transformOptions => {
    const { avatar, change } = this.props
    this.setState({
      isLoading: true,
      open: false,
    })

    const imageUrl = getCroppedImage({ url: this.originalImageUrl, options: transformOptions })

    await loadImage(imageUrl)

    this.setState({ isLoading: false, image: {} }, () => change('avatar', { ...avatar, cdnUrl: imageUrl }))
  }

  onChangePhoto = () => {
    this.dropzone.current.open()
  }

  get dropZoneComputedStyles() {
    return {
      borderRadius: '50%',
      position: 'absolute',
      backgroundImage: `url(${this.imageUrl})`,
      backgroundSize: 'cover',
      top: 0,
      bottom: 0,
      left: 0,
      right: 0,

      textAlign: 'center',
      width: '100%',
      display: 'flex',
      height: 'inherit',
      flex: 1,
    }
  }

  get imageUrl() {
    const { avatar } = this.props
    const { image } = this.state
    return image.cdnUrl || avatar.cdnUrl
  }

  get originalImageUrl() {
    return getOriginalImageUrl({ url: this.imageUrl })
  }

  render() {
    const { open, isLoading } = this.state
    return (
      <Box
        sx={{
          flex: 1,
          position: 'relative',
          maxWidth: 540,
          borderRadius: '50%',
          '&::after': {
            display: 'block',
            width: '100%',
            content: '" "',
            paddingTop: '100%',
          },
        }}
      >
        {!!this.imageUrl && (
          <IconButton onClick={this.handleOpen} sx={{ position: 'absolute', right: -1, top: -1 }}>
            <Edit />
          </IconButton>
        )}
        {isLoading && <Loading />}
        {open && (
          <AvatarEditorModal
            isLoading={isLoading}
            imageUrl={this.originalImageUrl}
            handleClose={this.handleClose}
            onSubmit={this.onImageSave}
            onChangePhoto={this.onChangePhoto}
          />
        )}
        <ReactDropzone
          ref={this.dropzone}
          accept={SUPPORTED_IMAGE_FORMATS}
          onDrop={this.onDrop}
          multiple={false}
          style={this.dropZoneComputedStyles}
        >
          <Box
            sx={{
              borderRadius: '50%',
              overflow: 'hidden',
              '&:hover': {
                border: '1px solid #41647d',
                backgroundColor: 'rgba(0,0,0,0.2)',
                cursor: 'pointer',
              },
              width: '100%',
              border: '1px dashed gray',
            }}
          >
            <Stack alignItems="center" justifyContent="center" sx={{ height: '100%' }}>
              <CloudUpload
                sx={{
                  color: 'white',
                  position: 'absolute',
                  zIndex: 1,

                  fontSize: 72,
                  width: 36,
                  height: 36,
                }}
              />
            </Stack>
          </Box>
        </ReactDropzone>
      </Box>
    )
  }
}

AvatarEditor.propTypes = {
  avatar: PropTypes.shape({
    cdnUrl: PropTypes.string.isRequired,
  }),
  change: PropTypes.func.isRequired,
}

AvatarEditor.defaultProps = {
  avatar: {},
}

export default AvatarEditor
