import React from 'react'
import PropTypes from 'prop-types'
import { compose } from 'recompose'
import { connect } from 'react-redux'
import { get, isFunction, identity } from 'lodash'
import { bindActionCreators } from 'redux'
import { Form, Field } from 'react-final-form'

import { withStyles } from '@material-ui/core/styles'

import {
  reportUpdate,
  reportUpdateComplete,
  reportUpdateFail,
  reportUpdateRequest,
  showFeedbackModal,
} from '../redux/actions/report'
import { locationSet } from '../../shared/redux/actions/location'
import { getReportForms } from '../constants/forms/reportFormsInstance'
import { getFormName } from '../layout/helpers'
import { warningNotification } from '../../shared/redux/actions/notifications'
import withErrorNotificationContext from '../../shared/utils/withErrorNotificationContext'
import { getExtendedFormApi } from '../../shared/utils/form'
import { isAnyFieldDirtyAndVisited } from '../utils/reportFormSubmitHelpers'
import * as Analytics from '../../analytics'
import { EXPORT_PHOTOS_LABEL } from '../constants'
import { mapReportContext } from '../../analytics/events/utilities'

import FormScrollController from './FormScrollController'

const DEFAULT_TITLE = 'Bowery RES'
const DEFAULT_INITIAL_VALUES = {}

/**
 * wrapForm adds form submit functionality to components
 * @param formDataPath
 * @param formOptions configuration object with the following properties:
 *   string heading form section label
 *   object styles styles to apply
 *   array registeredFields
 *   object initialValues default values for the form
 *   object feedbackModalProps: use to trigger the CSAT modal, define the modal's content, and route modal feedback data
 *   string validationErrorMessage (optional) message to display when validation fails
 *   function onPreSave (default: identity function) callback to modify values before they are saved
 *     signature: onPreSave(values, formApi, reportId, authenticatedUser, reportData) must return values
 *   boolean ignoreLocation (default: false) used when wrapForm is outside a report to prevent interacting
 *     with the browser location
 * boolean trackTimeSpent (default: false)
 * @param mapStateToProps {function}
 * @param mapDispatchToProps {function}
 * @returns {function(*): *}
 */
export default function wrapForm(
  formDataPath,
  formOptions = {},
  mapStateToProps = () => ({}),
  mapDispatchToProps = () => ({}),
  trackTimeSpent = false
) {
  return FormComponent => {
    const formPath = formDataPath.join('.')
    const { styles = {}, initialValues, onPreSave = identity, ignoreLocation = false } = formOptions

    class FormContainer extends React.Component {
      static propTypes = {
        nextFormPath: PropTypes.string,
        prevFormPath: PropTypes.string,
        id: PropTypes.string,
        initialValues: PropTypes.object,
        updateReport: PropTypes.func.isRequired,
        reportUpdateFail: PropTypes.func.isRequired,
        onSetLocation: PropTypes.func.isRequired,
        documentTitle: PropTypes.string,
        reportSettings: PropTypes.object,
        analyticsData: PropTypes.object,
        forms: PropTypes.array.isRequired,
        history: PropTypes.object.isRequired,
      }

      static defaultProps = {
        id: '',
        nextFormPath: null,
        prevFormPath: null,
        initialValues: DEFAULT_INITIAL_VALUES,
        documentTitle: DEFAULT_TITLE,
      }

      getName() {
        const { reportSettings } = this.props
        let formName = formOptions.heading

        if (!formName) {
          const formKey = formPath.split('.').pop()
          const form = getReportForms().find(form => form.key === formKey) || {}
          formName = getFormName(form, reportSettings)
        }

        return formName
      }

      componentDidMount() {
        const { onSetLocation, documentTitle } = this.props

        if (!ignoreLocation) {
          document.title = documentTitle

          const formData = this.getFormDataPayload()

          onSetLocation(formData)
        }

        this.arrivalTime = Date.now()
      }

      getFormDataPayload() {
        const { section, nextFormPath, prevFormPath, id } = this.props
        return {
          formName: this.getName(),
          formPath,
          prevFormPath: prevFormPath,
          nextFormPath: nextFormPath,
          id,
          section,
        }
      }

      componentWillUnmount() {
        const { onSetLocation, authenticatedUser, reportId, reportData, reportSettings } = this.props

        if (!ignoreLocation) {
          document.title = DEFAULT_TITLE
          onSetLocation(null)
        }

        if (trackTimeSpent) {
          const { analyticsData } = this.props

          const eventData = {
            ...this.getFormDataPayload(),
            ...mapReportContext(reportData, reportSettings),
            reportId,
            analyticsData,
          }

          Analytics.dispatchTimeSpentEvent(
            `timeSpentOnForm_${formPath}`,
            this.arrivalTime,
            Date.now(),
            eventData,
            authenticatedUser,
            ['amplitude']
          )
        }
      }

      get formId() {
        return `${formPath}-final-form`
      }

      saveForm = async (values, formApi) => {
        const { updateReport, reportId, reportData, authenticatedUser, reportUpdateRequest } = this.props

        if (this.getName() === EXPORT_PHOTOS_LABEL) {
          const event = new CustomEvent('saveForm', {
            detail: {
              name: this.getName(),
              reportId: reportId,
              values: values,
            },
          })
          document.dispatchEvent(event)
          return
        }
        let alteredValues = values

        try {
          reportUpdateRequest()
          alteredValues = await onPreSave(values, formApi, reportId, authenticatedUser, reportData)
        } catch (error) {
        } finally {
          reportUpdateComplete()
        }

        updateReport(formDataPath, alteredValues)
      }

      interceptHandleSubmit = ({
        event,
        handleSubmit,
        hasValidationErrors,
        feedbackModalProps = undefined,
        validationErrorMessage,
      }) => {
        const { reportUpdateFail, dispatchShowFeedbackModal, warningNotification } = this.props
        if (hasValidationErrors) {
          reportUpdateFail()
          validationErrorMessage && warningNotification(validationErrorMessage)
        }

        if (feedbackModalProps && feedbackModalProps.showWhen(this.props)) {
          //control the feedback modal via show conditions from its caller
          //this is used to only show the modal the first time you are on the page
          dispatchShowFeedbackModal({
            title: feedbackModalProps.title,
            content: feedbackModalProps.content,
            pageNameToTrack: this.getName(),
            csatKey: feedbackModalProps.csatKey,
          })
        }
        handleSubmit(event)
      }

      render() {
        const { initialValues } = this.props
        const {
          heading,
          styles,
          registeredFields = [],
          feedbackModalProps,
          validationErrorMessage,
          ...otherFormOptions
        } = formOptions

        let subscription = formOptions.subscription
        if (subscription) {
          subscription = { ...formOptions.subscription, dirtyFields: true, visited: true }
        }
        return (
          <div data-qa="formContainer" inspectlet-form-analytics={formPath || 'default'}>
            <FormScrollController>
              <Form
                onSubmit={this.saveForm}
                {...otherFormOptions}
                subscription={subscription}
                initialValues={initialValues}
                render={({
                  values,
                  form,
                  handleSubmit,
                  invalid,
                  hasValidationErrors,
                  errors,
                  touched,
                  dirtyFields,
                  visited,
                }) => {
                  const invalidStringified = invalid?.toString()
                  const showSaveModal = isAnyFieldDirtyAndVisited(
                    dirtyFields,
                    visited,
                    values || form.getState().values
                  )
                  const showSaveModalStringified = showSaveModal.toString()
                  return (
                    <form
                      id={this.formId}
                      onSubmit={event =>
                        this.interceptHandleSubmit({
                          event,
                          handleSubmit,
                          hasValidationErrors,
                          feedbackModalProps,
                          validationErrorMessage,
                        })
                      }
                      invalid={invalidStringified}
                      show-save-modal={showSaveModalStringified}
                    >
                      <FormComponent
                        {...this.props}
                        form={getExtendedFormApi({ values, invalid, errors, touched, form })}
                      />
                      {registeredFields.map(name => (
                        <Field key={name} name={name} render={() => null} />
                      ))}
                    </form>
                  )
                }}
              />
            </FormScrollController>
          </div>
        )
      }
    }

    const customMapStateToProps = (state, ownProps, ...otherParams) => {
      const mappedState = {
        initialValues: Object.assign({}, initialValues || {}, get(state, `report.reportData.${formPath}`)),
        documentTitle: get(state, 'report.reportData.address'),
        ...mapStateToProps(state, ownProps, ...otherParams),
        reportSettings: get(state, 'report.reportSettings'),
        reportId: ownProps.reportId ? ownProps.reportId : get(state, 'report.reportData._id'),
        reportData: get(state, 'report.reportData'),
        authenticatedUser: get(state, 'authentication.user'),
        analyticsData: get(state, 'analytics.metaData'),
      }
      return mappedState
    }

    const customMapDispatchToProps = (dispatch, ownProps) => {
      const customMap = isFunction(mapDispatchToProps)
        ? mapDispatchToProps(dispatch, ownProps)
        : bindActionCreators(mapDispatchToProps, dispatch)
      return {
        ...customMap,
        dispatchShowFeedbackModal: feedbackModalProps => dispatch(showFeedbackModal(feedbackModalProps)),
        onSetLocation: form => dispatch(locationSet({ form })),
        updateReport: (formDataPath, values) => dispatch(reportUpdate({ formDataPath, values })),
        reportUpdateRequest: () => dispatch(reportUpdateRequest()),
        reportUpdateComplete: () => dispatch(reportUpdateComplete()),
        reportUpdateFail: () => dispatch(reportUpdateFail()),
        warningNotification: error => dispatch(warningNotification({ message: error })),
      }
    }

    return compose(
      withStyles(styles),
      connect(customMapStateToProps, customMapDispatchToProps),
      withErrorNotificationContext
    )(FormContainer)
  }
}
