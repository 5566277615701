import { first, isArray } from 'lodash'

import * as Amplitude from './services/amplitude.service'

import { TimingEvents, PropertySearchEvent } from './events'

export const initAnalytics = () => {
  if (!global.env.analyticsEnabled) {
    return
  }

  if (global.env.amplitudeEnabled) {
    Amplitude.initAmplitude()
  }
}

export const dispatchTimeSpentEvent = (key, startTime, completionTime, data, user, targets) => {
  try {
    const event = TimingEvents.timeSpentEvent(key, startTime, completionTime, data, user)
    dispatchAnalyticsEvent(event, targets)
  } catch (exc) {
    console.error(`Analytics::dispatchTimeSpentOnPageEvent::Error!!!! ${exc}`)
  }
}

export const dispatchPropertySearchEvent = (key, data, user, targets) => {
  try {
    const event = new PropertySearchEvent(key, data, user)
    dispatchAnalyticsEvent(event, targets)
  } catch (exc) {
    console.error(`Analytics::dispatchPropertySearchEvent::Error!!!! ${exc}`)
  }
}

export const dispatchAnalyticsEvent = (event, targets = []) => {
  const eventToDispatch = isArray(event) ? first(event) : event
  if (targets.length) {
    Amplitude.dispatchEvent(eventToDispatch)
  }
}

//
//  User Tracking
//
export const dispatchUserAuthenticatedEvent = (user, targets = []) => {
  if (user && targets.length) {
    Amplitude.setAmplitudeUser(user)
  }
}
