import { toLower } from 'lodash'

import { MarketConclusionUnits } from 'shared/constants/report/incomeApproach/residential'

import { objectToKeyValuePairs } from '../../shared/utils/ui/checkboxHelper'

import { CORNER, MID_BLOCK, THROUGH_LOT } from '../../../../shared/constants/report'
import { GENERAL_CONDITIONS } from '../../../../shared/constants/property/description'
import { USES_VALUES } from '../../../../libs/bowery-libs/constants/report/commercial-unit'

/** @deprecated Prefer importing this from the shared folder */
export { MarketConclusionUnits }

export const CoStarRates = {
  SUBMARKET: 0.975,
  METRO: 0.985,
}

export const STOCK_PHOTO = {
  url: 'https://cdnassets.hw.net/dims4/GG/19ea1d3/2147483647/thumbnail/876x580%3E/quality/90/?url=https%3A%2F%2Fcdnassets.hw.net%2F0c%2Fba%2F6cb58ba94a3f99d83f876b1e4d0c%2F98reade-workac-exterior2.jpg',
  title: 'Stock Photo',
  width: '100%',
}
export const NO_IMAGE_AVAILABLE =
  'http://res.cloudinary.com/vikas-real-estate/image/upload/v1525960314/reooxlqrl1pqebvjafxt.png'
export const OTHER = 'Other'

export const OWNER_TENANT_PAID = 'Owner/Tenant Paid'
export const TENANT_PAID = 'Tenant Paid'
export const OWNER_PAID = 'Owner Paid'
export const ELECTRIC_METERS_LOCATION = ['Basement', 'Utility Room', 'Outside', 'Hallways', OWNER_PAID]
export const GAS_METERS_LOCATION = ['Basement', 'Utility Room', 'In Unit', 'Outside', 'None']
export const HEATING_SYSTEM_LOCATION = ['Basement', 'Utility Room', 'In Unit', 'Roof']
export const HEATING_SYSTEM = [OWNER_PAID, TENANT_PAID, OWNER_TENANT_PAID]

export const AmenitiesOutdoorSpaces = {
  NO_OUTDOOR_SPACE: 'No Outdoor Space',
  BACKYARD: 'Backyard',
  ROOF: 'Roof',
  TERRACE: 'Terrace',
}
export const AMENITIES_OUTDOOR_SPACES = [
  AmenitiesOutdoorSpaces.NO_OUTDOOR_SPACE,
  AmenitiesOutdoorSpaces.BACKYARD,
  AmenitiesOutdoorSpaces.ROOF,
  AmenitiesOutdoorSpaces.TERRACE,
]

export const MAX_UNITS_NUMBER = 5000

export const GAS_FIRED_BOILER = 'Gas Fired Boiler'
export const OIL_FIRED_BOILER = 'Oil Fired Boiler'
export const DUAL_FIRED_BOILER = 'Dual Fired Boiler'
export const STEAM = 'Steam Heating'
export const OWNER_PAID_HEATING_SYSTEM_TYPE = [GAS_FIRED_BOILER, OIL_FIRED_BOILER, DUAL_FIRED_BOILER, STEAM]
export const NUMBER_2 = '#2'
export const NUMBER_4 = '#4'
export const NUMBER_6 = '#6'
const ROOF = 'Roof'
const REAR = 'Rear'
export const HVAC_LOCATIONS = [ROOF, REAR]

export const DEFERRED_MAINTENANCE_OPTIONS = {
  leakingRoof: 'Leaking roof',
  brokenFlooring: 'Broken flooring',
  holeInTheWall: 'Hole in the wall',
  chippingPaint: 'Chipping paint',
  other: 'Other(s)',
}

export const AmenitiesDoorman = {
  VIRTUAL: 'Virtual',
  PART_TIME_DOORMAN: 'Part-Time',
  FULL_TIME_DOORMAN: '24/7',
  Other: 'Other',
}
export const AMENITIES_DOORMAN = [
  AmenitiesDoorman.VIRTUAL,
  AmenitiesDoorman.PART_TIME_DOORMAN,
  AmenitiesDoorman.FULL_TIME_DOORMAN,
  AmenitiesDoorman.Other,
]
export const OUTDOOR_SPACES = ['None', 'Backyard', 'Balcony', ROOF, 'Terrace']
export const OWNER_PAID_HEATING_SYSTEM_OIL_TYPE = [NUMBER_2, NUMBER_4, NUMBER_6, OTHER]
export const SORT_DIRECTIONS = {
  ASC: 'asc',
  DESC: 'desc',
}

export const UNDER_RENOVATION = 'Under Renovation'

export const CONDITION_OPTIONS_ARR = ['Fair', 'Average', 'Satisfactory', 'Good', 'Excellent']
export const ROOF_AND_STAIRS_CONDITIONS = [
  'Poor',
  'Fair',
  'Satisfactory',
  'Average',
  'Good',
  'Excellent',
  UNDER_RENOVATION,
]

export const GENERAL_CONDITIONS_OPTIONS = Object.values(GENERAL_CONDITIONS)

export const WINDOWS_TYPES_OPTIONS = {
  doubleHung: 'Double-hung',
  casement: 'Casement',
  awning: 'Awning',
  sliding: 'Sliding',
  bay: 'Bay',
  other: 'Other(s)',
}
export const SECURITY_OPTIONS = {
  camera: 'Security Cameras',
  buzzer: 'Buzzer',
  doorLocks: 'Door Locks',
  exteriorLighting: 'Exterior Lighting',
  guardedGate: 'Guarded Gate',
  individualUnitAlarms: 'Individual Unit Alarms',
  perimeterFence: 'Perimeter Fence',
  securityAlarms: 'Security Alarms / Patrol',
  other: 'Other',
}
export const ROOF_TYPE_OPTIONS = ['Flat built-up', 'Finished', 'Pitched', 'Other']
export const SPRINKLER_OPTIONS = ['Fully', 'Common Interior', 'None']

export const TENANT_PAID_HEATING_SYSTEM_TYPE = [
  'HVAC System',
  'Individual Boilers',
  'Individual Water Heaters',
  'Individual Boilers and Water Heaters',
]
export const UNIT_LAYOUTS = [
  'Typical',
  'Duplex',
  'Triplex',
  'Simplex',
  'Penthouse',
  'Loft',
  'Garden Style',
  'Basement',
  'Garage',
  'Townhome',
]
export const RentTypes = {
  MARKET_RATE: 'Market Rate',
  RENT_STABILIZED: 'Rent Stabilized',
  RENT_CONTROLLED: 'Rent Controlled',
  SECTION_8: 'Section 8',
}
export const RENT_TYPES = [
  RentTypes.MARKET_RATE,
  RentTypes.RENT_STABILIZED,
  RentTypes.RENT_CONTROLLED,
  RentTypes.SECTION_8,
]
export const RentRollOutdoorSpaces = {
  NONE: 'None',
  BACKYARD: 'Backyard',
  BALCONY: 'Balcony',
  ROOF: 'Roof',
  TERRACE: 'Terrace',
}
export const RENT_ROLL_OUTDOOR_SPACES = [
  RentRollOutdoorSpaces.NONE,
  RentRollOutdoorSpaces.BACKYARD,
  RentRollOutdoorSpaces.BALCONY,
  RentRollOutdoorSpaces.ROOF,
  RentRollOutdoorSpaces.TERRACE,
]
export const RentRollOutdoorSpacesList = [
  { label: RentRollOutdoorSpaces.NONE, value: RentRollOutdoorSpaces.NONE },
  {
    label: RentRollOutdoorSpaces.BACKYARD,
    value: RentRollOutdoorSpaces.BACKYARD,
  },
  {
    label: RentRollOutdoorSpaces.BALCONY,
    value: RentRollOutdoorSpaces.BALCONY,
  },
  { label: RentRollOutdoorSpaces.ROOF, value: RentRollOutdoorSpaces.ROOF },
  {
    label: RentRollOutdoorSpaces.TERRACE,
    value: RentRollOutdoorSpaces.TERRACE,
  },
]
export const UNIT_USES_MAP = [
  { value: USES_VALUES.RETAIL, label: 'Retail' },
  { value: USES_VALUES.OFFICE, label: 'Office' },
  { value: USES_VALUES.MEDICAL, label: 'Medical Office' },
  { value: USES_VALUES.COMMUNITY, label: 'Community Facility' },
  { value: USES_VALUES.INDUSTRIAL, label: 'Industrial' },
  { value: USES_VALUES.UNDETERMINED, label: 'Undetermined' },
  { value: USES_VALUES.OTHER, label: 'Other' },
]
export const UNIT_LOCATIONS = ['Corner', 'Mid-Block', 'Through-lot']
export const UNIT_STREET_TYPES = ['Side Street', 'Avenue']
export const UNIT_FLOORS = {
  belowGrade: 'Below Grade',
  groundFloor: 'Ground Floor',
  upperFloor: 'Upper Floor',
  other: 'Other',
}

export const UNIT_GRADES = {
  atGrade: 'At Grade',
  partiallyBelowGrade: 'Partially Below Grade',
  belowGrade: 'Below Grade',
  other: 'Other',
}

export const UNIT_FACADE_LIST = [
  { value: 'plate glass', label: 'Plate Glass' },
  { value: 'other', label: 'Other' },
]

export const BEDROOM_COUNTS = [
  'Studio',
  '1 Bedroom',
  '2 Bedroom',
  '3 Bedroom',
  '4 Bedroom',
  '5 Bedroom',
  '6 Bedroom',
  '7 Bedroom',
  '8 Bedroom',
  '9+ Bedroom',
]
export const BEDROOM_COUNT_LIST = BEDROOM_COUNTS.map((type, index) => ({ value: index, label: type }))

export const UNIT_TYPES = [
  'Typical',
  'Duplex',
  'Triplex',
  'Simplex',
  'Loft',
  'Garden Style',
  'Basement',
  'Garage',
  'Penthouse',
  'Townhome',
]

export const UNIT_TYPES_LIST = UNIT_TYPES.map(type => ({ value: toLower(type), label: type }))

export const SOURCE_OF_INFORMATION = ['Bowery Subject', 'External Database', 'Other']

export const SOURCE_OF_INFORMATION_TYPES = {
  BOWERY_SUBJECT: 'Bowery Subject',
  EXTERNAL_DATABASE: 'External Database',
  OTHER: 'Other',
}

export const SOURCE_OF_INFORMATION_LIST = SOURCE_OF_INFORMATION.map(type => ({
  value: toLower(type),
  label: type,
}))

export const UNIT_AMENITIES = ['None', 'Backyard', 'Balcony', 'Roof', 'Terrace', 'Building Laundry', 'In-Unit Laundry']

export const UNIT_AMENITIES_LIST = UNIT_AMENITIES.map(type => ({ value: toLower(type), label: type }))

export const STREET_ACCESS = ['Street 1', 'Street 2', 'Street 3']
export const CORNERS = [MID_BLOCK, CORNER, THROUGH_LOT]
export const STATE = 'NY'
export const REPORT_PURPOSE = [
  'Loan collateral valuation',
  'Loan underwriting',
  'Asset management',
  'Asset sale purposes',
  'Other',
]

export const INTEREST_APPRAISED_AS_IS_MARKET_VALUE = [
  'Leased Fee Interest',
  'Fee Simple Interest',
  'Leasehold Interest',
]

export const INTEREST_APPRAISED_AS_COMPLETE = ['Leased Fee Interest', 'Fee Simple Interest', 'Leasehold Interest']

export const INTEREST_APPRAISED_AS_STABILIZED = ['Leased Fee Interest', 'Fee Simple Interest', 'Leasehold Interest']

export const FILES_ENDPOINT = 'files/upload'
export const NUMBER_OF_ZONES = [1, 2, 3, 4]
export const COMP_TYPES = ['Per Unit', 'Per Building']
export const PropertySearchTypes = {
  BBL: 'BBL',
  ZIP: 'Zip/Address',
}
export const ZIP_TYPE = 'postal_code'
export const BOROUGH_TYPE = 'sublocality'
export const YES = 'Yes'
export const NO = 'No'
export const ReportStates = {
  DRAFT: 'Draft',
  REVIEW: 'Review',
  APPROVED: 'Approved',
  SUBMITTED: 'Submitted',
}

export const CompTypes = {
  BUILDING: 'Per Building',
  UNIT: 'Per Unit',
}

export const CompTypesNextForm = {
  [CompTypes.UNIT]: 'residential-rent-comps-map',
  [CompTypes.BUILDING]: 'residential-building-rent-comps-map',
}

export const MIN_ISO_DATE = '0001-01-01T00:00:00'
export const GRID_CONTAINER_GUTTER = 40

export const NOT_AVAILABLE = 'N/A'

export const BLOCK_NAVIGATION = 'BLOCK_NAVIGATION'

export const ElevatorTypes = {
  ELEVATOR: 'elevator',
  WALK_UP: 'walk-up',
}

export const SiteAreaTypes = { SF: 'SF', ACRE: 'Acre' }
export const SiteAreaValues = { SF: 'sf', ACRE: 'acre' }
export const SITE_AREA_OPTIONS = objectToKeyValuePairs(SiteAreaTypes, true)
export const SF_TO_ACRE_RATIO = 1 / 43560

export const SOURCE_DROPDOWN_ITEMS = [
  { label: 'Bowery Subject', value: 'bowerySubject' },
  { label: 'External Database', value: 'externalDatabase' },
  { label: 'Other', value: 'other' },
]

export const HIDE_REMOVED_LABEL = 'Hide Removed'
export const SHOW_REMOVED_LABEL = 'Show Removed'
export const CLEAR_ALL_LABEL = 'Clear All'

export const EXPORT_PHOTOS_LABEL = 'Export Photos'

export const LOSS_DATA_TOOLTIP = 'Modifying this input will result in loss of data.'

export const SFAnalysisHelperText = 'Income approach calculations will be based on this measurement.'

export const SITE_DESCRIPTOR_TOPOGRAPHY = 'Topography'

export const SUBJECT_AS_COMP_OPTIONS = [
  { value: true, label: 'Yes' },
  { value: false, label: 'No' },
]
