import { merge } from 'lodash'

import { styles as checkboxStyles } from './checkbox'
import { styles as selectStyles } from './select'

const tableStyles = theme => ({
  root: {
    '& thead th': {
      borderRight: `1px solid ${theme.palette.grey[300]}`, // Override Table/styles.css
    },

    '& th': {
      fontSize: 14,
      fontWeight: 700,
      color: theme.palette.grey[900],
      background: 'rgb(248, 250, 252)',
    },

    '& td': {
      ...theme.typography.body2,
      color: theme.palette.grey[900],
      background: theme.palette.common.white,

      '&:hover': {
        /*
        TODO
        border results in double border on left and top
        boxShadow and outline result in dobule border on right and bottom
        .current.highlight styles are managed by handsontable in a separate div
        Current solution results in a 1px gap in the bottom left and top right corners
        */
        borderRight: `1px solid ${theme.palette.primary.main}`,
        borderBottom: `1px solid ${theme.palette.primary.main}`,
        boxShadow: `-1px -1px 0 ${theme.palette.primary.main}`,
      },
    },

    '& tr:first-child > td:hover': {
      borderTopColor: `${theme.palette.primary.main} !important`,
    },

    '& td.readOnly': {
      textAlign: 'initial', // Override Table/styles.css
    },

    '& .handsontableInput': {
      borderRadius: 1,
      boxShadow: `inset 0 0 0 1px ${theme.palette.primary.main}`,
    },

    '& .htBorders div.wtBorder.current.corner': {
      backgroundColor: `${theme.palette.primary.main} !important`,
      border: `none !important`,
    },

    '& .htBorders div.wtBorder.current': {
      backgroundColor: `${theme.palette.primary.main} !important`,
    },
  },

  readOnlyCell: {
    '&&': {
      ...theme.typography.body,
      color: theme.palette.grey[700],
      background: theme.palette.grey[50],
    },
  },

  errorCell: {
    '&&': {
      position: 'relative',
      boxShadow: `0 0 0 1px ${theme.palette.error.main}`,
    },
  },

  totalCell: {
    // override '& td' rule
    '&&': {
      ...theme.typography.subtitle1,
      color: theme.palette.grey[700],
      background: theme.palette.grey[50],
    },
  },
})

const styleFunctions = [tableStyles, checkboxStyles, selectStyles]
export default theme => merge({}, ...styleFunctions.map(styles => styles(theme)))
