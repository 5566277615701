import { connect } from 'react-redux'

import { selectSelectedBuildingComps, selectSubject, selectShowPerRoomAnalysis } from '../../redux/selectors'
import { editSelectedBuildingComps } from '../../../../../../../etc/buildingComparables/redux/actions'

import BuildingCompList from './BuildingCompList'

const mapStateToProps = (state, ownProps) => {
  return {
    items: selectSelectedBuildingComps(state, ownProps),
    subject: selectSubject(state),
    showPerRoomAnalysis: selectShowPerRoomAnalysis(state),
  }
}

export default connect(mapStateToProps, { editComp: editSelectedBuildingComps })(BuildingCompList)
