import * as React from 'react'
import { Grid } from '@material-ui/core'
import arrayMutators from 'final-form-arrays'
import createDecorator from 'final-form-calculate'
import { maxBy, sumBy, get, isNumber } from 'lodash'

import { RENOVATIONS_PATH } from 'shared/constants/report/keysAndDataPaths'

import wrapForm from '../../wrapForm'

import {
  ProspectiveRenovationTypes,
  DEFAULT_TOTAL_ITEMS,
  DEFAULT_ITEMIZED_ITEMS,
  RenovationCostTypes,
  HEADING,
  DO_NOT_DISCUSS,
} from './constants'
import RecentRenovations from './RecentRenovations'
import ProspectiveRenovations from './ProspectiveRenovations'

export const DATA_PATH = RENOVATIONS_PATH

const updateProspectiveRenovationType = ([args], state, utils) => {
  const renovationType = args.target.value
  const shouldSetDefaults =
    utils.getIn(state, 'formState.values.prospectiveRenovationType') === ProspectiveRenovationTypes.NO_RENOVATIONS

  if (renovationType === ProspectiveRenovationTypes.NO_RENOVATIONS) {
    utils.changeValue(state, 'itemized', () => ({}))
    utils.changeValue(state, 'total', () => ({}))
    utils.changeValue(state, 'prospectiveRenovationsDiscussion.commentary', () => '')
    utils.changeValue(state, 'prospectiveRenovationsDiscussion.additionalCommentary', () => '')
    utils.changeValue(state, 'prospectiveRenovationsDiscussion.isGeneratedCommentaryOverridden', () => false)
    utils.changeValue(state, 'renovationCostsType', () => RenovationCostTypes.ITEMIZED)
  } else if (shouldSetDefaults) {
    utils.changeValue(state, 'itemized', () => ({ items: DEFAULT_ITEMIZED_ITEMS }))
    utils.changeValue(state, 'total', () => ({ items: DEFAULT_TOTAL_ITEMS }))
  }
  utils.changeValue(state, 'prospectiveRenovationType', () => renovationType)
}

const totalsCalculator = createDecorator(
  {
    field: /itemized.items\[\d*\]/,
    updates: {
      'itemized.total.renovationPeriod': (value, allValues) =>
        get(maxBy(allValues.itemized.items, 'renovationPeriod'), 'renovationPeriod', 0),
      'itemized.total.amount': (value, allValues) => {
        const totalAmount = sumBy(allValues.itemized.items, renovationItem => {
          if (!isNaN(renovationItem.amount) && isNumber(renovationItem.amount)) {
            return renovationItem.amount
          }

          return 0
        })
        return totalAmount
      },
    },
  },
  {
    field: ['itemized.total.amount', 'itemized.total.lessAmountSpent'],
    updates: {
      'itemized.total.netRenovationBudget': (value, allValues) =>
        get(allValues, 'itemized.total.amount', 0) - get(allValues, 'itemized.total.lessAmountSpent', 0),
    },
  },
  {
    field: ['total.total.amount', 'total.total.lessAmountSpent'],
    updates: {
      'total.total.netRenovationBudget': (value, allValues) =>
        get(allValues, 'total.total.amount', 0) - get(allValues, 'total.total.lessAmountSpent', 0),
    },
  }
)

const pastRenovationsDecorator = createDecorator({
  field: 'typeOfRenovation',
  updates: {
    pastRenovationsDiscussion: (value, allValues) => {
      if (value === DO_NOT_DISCUSS) {
        return {}
      }
      return allValues.pastRenovationsDiscussion
    },
  },
})

// The renovationCostsType component is rerendered and loses its field state (e.g. visited) unless registered here
const registeredFields = ['renovationCostsType']

class Renovations extends React.PureComponent {
  render() {
    const { form, valueAsComplete } = this.props
    const { typeOfRenovation, renovationInfo, renovationCostsType, prospectiveRenovationType } = form.values

    return (
      <Grid container>
        <RecentRenovations typeOfRenovation={typeOfRenovation} renovationInfo={renovationInfo} />
        {valueAsComplete && (
          <Grid item xs={12} sm={12}>
            <ProspectiveRenovations
              form={form}
              renovationCostsType={renovationCostsType}
              prospectiveRenovationType={prospectiveRenovationType}
            />
          </Grid>
        )}
      </Grid>
    )
  }
}

export default wrapForm(
  DATA_PATH,
  {
    heading: HEADING,
    mutators: { updateProspectiveRenovationType, ...arrayMutators },
    decorators: [totalsCalculator, pastRenovationsDecorator],
    registeredFields,
  },
  state => {
    const valueAsComplete = get(state, 'report.reportSettings.valueAsComplete')

    return {
      valueAsComplete,
    }
  }
)(Renovations)
