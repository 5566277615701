import React from 'react'

import { OnChange } from 'react-final-form-listeners'
import { Typography } from '@mui/material'

import { getFeatureFlagValue } from '@bowery-valuation/feature-flagger-client'

import { ENABLE_AUTOMATED_INSPECTION_PREFILL } from 'shared/constants/featureFlags'

import { EXTERNAL_WALL_OPTIONS, FOUNDATION_OPTIONS } from 'shared/constants/report/property'

import Layout from '@ui/Layout'
import AutomationStatus from 'client-shared/components/AutomationStatus'

import { CheckboxGroup } from 'client-shared/components/Checkbox'
import { Text, RadioButtonList } from 'client-shared/components'
import { arrayToKeyValuePairs, keyValuePairsToCheckboxValues } from 'client-shared/utils/ui/checkboxHelper'

import { ROOF_TYPE_OPTIONS, SECURITY_OPTIONS, SPRINKLER_OPTIONS, WINDOWS_TYPES_OPTIONS } from 'report/constants'

import BelowGrade from './BelowGrade'

const externalWallOptions = keyValuePairsToCheckboxValues(EXTERNAL_WALL_OPTIONS)
const foundationOptions = arrayToKeyValuePairs(FOUNDATION_OPTIONS)
const roofTypes = arrayToKeyValuePairs(ROOF_TYPE_OPTIONS)
const securityOptions = keyValuePairsToCheckboxValues(SECURITY_OPTIONS)
const sprinklerOptions = arrayToKeyValuePairs(SPRINKLER_OPTIONS)
const windowsTypesOptions = keyValuePairsToCheckboxValues(WINDOWS_TYPES_OPTIONS)

const DescriptionOfImprovements = ({
  belowGrade,
  belowGradeType,
  buildingSecurity,
  change,
  externalWalls,
  otherBelowGradeAccess,
  validateOther,
  valueAsComplete,
  windowTypes,
  automationMetadata,
}) => {
  const automatedInspectionPrefillFlag = getFeatureFlagValue(ENABLE_AUTOMATED_INSPECTION_PREFILL)

  return (
    <Layout.VerticalRow>
      <Typography variant="h6">Description of Improvements{valueAsComplete ? ' Upon Completion' : ''}</Typography>
      {automatedInspectionPrefillFlag && automationMetadata && (
        <AutomationStatus
          formPaths={['automationMetadata']}
          message="We automated the information below from Mobile Inspection app"
          sx={{ marginTop: '-4px' }}
        />
      )}
      <RadioButtonList label="Foundation" name="foundation" horizontal items={foundationOptions} />
      <div data-qa="external-walls-checkbox-group">
        <CheckboxGroup header="External Walls" groupName="externalWalls" items={externalWallOptions} horizontal />
        {externalWalls.other && (
          <Text name="otherExternalWall" label="Other" required validate={validateOther('externalWalls')} />
        )}
      </div>
      <OnChange name="externalWalls.other">
        {(_value, prevValue) => prevValue && change('otherExternalWall', null)}
      </OnChange>
      <RadioButtonList label="Roof Type" name="roofType" horizontal items={roofTypes} />
      <div data-qa="window-types-checkbox-group">
        <CheckboxGroup header="Windows" groupName="windowTypes" items={windowsTypesOptions} horizontal />
        {!!windowTypes.other && (
          <Text
            name="otherWindowsType"
            placeholder="Separate multiple window types with a comma"
            label="Other"
            required
            validate={validateOther('windowTypes')}
          />
        )}
      </div>
      <OnChange name="windowsTypes.other">
        {(_value, prevValue) => prevValue && change('otherWindowsType', null)}
      </OnChange>
      <RadioButtonList label="Sprinklers" name="sprinklers" horizontal items={sprinklerOptions} />
      <div data-qa="security-checkbox-group">
        <CheckboxGroup header="Security" groupName="buildingSecurity" items={securityOptions} horizontal />
        {!!buildingSecurity.other && (
          <Text
            label="Other"
            name="buildingSecurity.otherText"
            placeholder="Separate multiple security systems with a comma"
            validate={validateOther('buildingSecurity')}
            required
          />
        )}
      </div>
      <OnChange name="buildingSecurity.other">
        {(_value, prevValue) => prevValue && change('buildingSecurity.otherText', null)}
      </OnChange>
      <BelowGrade
        change={change}
        belowGradeType={belowGradeType}
        belowGrade={belowGrade}
        valueAsComplete={valueAsComplete}
        otherAccess={otherBelowGradeAccess}
      />
    </Layout.VerticalRow>
  )
}

export default DescriptionOfImprovements
