import { withMicrofrontend } from 'client-shared/hooks/useMicrofrontend'
import React, { createRef } from 'react'
import { compose } from 'redux'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { get, pick } from 'lodash'
import { getAuthorizationHeader } from 'core/api'
import { Grid } from '@mui/material'

import * as Analytics from '../../analytics'

class PropertySearch extends React.PureComponent {
  constructor(props) {
    super(props)
    this.compPlexRef = createRef()
  }

  componentDidMount() {
    const { authenticatedUser } = this.props
    Analytics.dispatchPropertySearchEvent(`property_search`, {}, authenticatedUser, ['amplitude'])
  }

  render() {
    const { authenticatedUser, compPlexLoaded } = this.props
    console.log('compplexLoaded: ', compPlexLoaded)
    console.log('authenticatedUser: ', authenticatedUser)
    return (
      <Grid>
        <comp-plex-properties
          ref={this.compPlexRef}
          auth-user={JSON.stringify(authenticatedUser)}
          webapp-auth-header={getAuthorizationHeader().Authorization}
          webapp-api-url={global.env.apiUrl}
        />
      </Grid>
    )
  }
}

PropertySearch.propTypes = {
  compPlexLoaded: PropTypes.bool.isRequired,
}

const mapStateToProps = state => {
  return {
    authenticatedUser: pick(get(state, 'authentication.user'), ['id', 'username', 'fullName', 'boweryOffice']),
  }
}

export default compose(connect(mapStateToProps), withMicrofrontend('compplex', 'compPlexLoaded'))(PropertySearch)
