import DescriptionOutlinedIcon from '@mui/icons-material/DescriptionOutlined'
import HomeOutlinedIcon from '@mui/icons-material/HomeOutlined'
import AttachMoneyOutlinedIcon from '@mui/icons-material/AttachMoneyOutlined'
import BalanceOutlinedIcon from '@mui/icons-material/BalanceOutlined'
import EmojiEventsOutlinedIcon from '@mui/icons-material/EmojiEventsOutlined'
import FindInPageOutlinedIcon from '@mui/icons-material/FindInPageOutlined'
import ContentPasteGoOutlinedIcon from '@mui/icons-material/ContentPasteGoOutlined'
import ViewModuleOutlinedIcon from '@mui/icons-material/ViewModuleOutlined'
import InventoryOutlinedIcon from '@mui/icons-material/InventoryOutlined'
import ConstructionIcon from '@mui/icons-material/Construction'
import LandscapeIcon from '@mui/icons-material/Landscape'

export const HEADER_HEIGHT = 64
export const FOOTER_HEIGHT = 64
export const SIDEBAR_WIDTH = 64
export const SIDEBAR_MENU_WIDTH = 260
export const HEADER_Z_INDEX = 1210

export const GREY_TEXT_COLOR = '#617083'
export const MAIN_BLUE_COLOR = '#4160D3'

export const SIDE_BAR_ICONS = {
  'review-and-export': ContentPasteGoOutlinedIcon,
  'preview-and-edit': FindInPageOutlinedIcon,
  'generic-subsection': InventoryOutlinedIcon,
  'sales-approach': BalanceOutlinedIcon,
  'income-approach': AttachMoneyOutlinedIcon,
  'cost-approach': ConstructionIcon,
  'land-valuation': LandscapeIcon,
  'property-information': HomeOutlinedIcon,
  'data-collections': ViewModuleOutlinedIcon,
  report: DescriptionOutlinedIcon,
  final: EmojiEventsOutlinedIcon,
}

export const SIDE_BAR_LABELS = {
  'review-and-export': 'Settings & Export',
  'preview-and-edit': 'Preview',
  'generic-subsection': 'Content Reuse',
  'sales-approach': 'Sales',
  'income-approach': 'Income',
  'cost-approach': 'Cost',
  'land-valuation': 'Land',
  'property-information': 'Property',
  'data-collections': 'Overview',
  report: 'Report',
  final: 'Final',
}
