import React, { Component } from 'react'

import PageLayout from '../layout/PageLayout'

class ChipBankContainer extends Component {
  render() {
    return <PageLayout header="Chip Bank" itemKey="chip-bank" />
  }
}

export default ChipBankContainer
