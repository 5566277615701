import { GeneratedText } from '../../narrative/GeneratedText'

const generate = () => {
  const gt = new GeneratedText()
  gt.t`Various estimates of gross building area/gross leasable area and net rentable area were provided by the \
owner/client and/or their agents. This opinion of value reported herein assumes that the data provided are the most \
recent and accurate.`
  return gt.getParts()
}

export default { generate }
