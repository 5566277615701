import React from 'react'
import { get, map, max, mean, min, isEqual, isNil } from 'lodash'
import PropTypes from 'prop-types'

import { withStyles } from '@material-ui/core/styles'
import ExpandMore from '@mui/icons-material/ExpandMore'
import {
  Grid,
  ExpansionPanelDetails,
  ExpansionPanelSummary,
  ExpansionPanel,
  Table,
  TableHead,
  TableBody,
} from '@material-ui/core'

import BoweryDate from '@bowery-valuation/bowery-date'

import { getRentPsfLabel } from '../../../../../../../../shared/helpers/incomeApproach/commercial'
import { handleOtherUseLabel } from '../../../../../../../../shared/utils/formatters/textFormatters'
import AlertInfo from '../../../../../../shared/components/AlertInfo'
import { formatCurrencyFloat } from '../../../../../../shared/utils/numberFormatters'
import { TableHeadRow, TableBodyRowNew } from '../../../../../../shared/components/TableHelpers'
import { UNIT_USES_MAP } from '../../../../../constants'
import { median } from '../../../../../../shared/utils/math'
import { Labels } from '../constants'
import NarrativeComponent from '../../../../../../shared/components/NarrativeComponent/NarrativeComponent'

import { GeneratedText, gtLocked } from '../../../../../../../../shared/utils/narrative/GeneratedText'

import { MONTH_DAY_YEAR_DATE_FORMAT } from '../../../../../../shared/constants'

const styles = theme => ({
  generalCommentContainer: {
    margin: 16,
  },
  titleRoot: {
    '&$titleExpanded': {
      minHeight: '48px',
    },
  },
  titleContent: {
    color: theme.palette.text.primary,
    fontFamily: theme.typography.fontFamily,
    '&$titleExpanded': {
      margin: '12px 0',
    },
  },
  titleExpanded: {},

  expansionPanelDetail: {
    padding: 0,
  },
  noResultsLabel: {
    margin: theme.spacing.unit * 2,
    width: '100%',
  },
  headCell: {
    borderBottom: 'none',
    padding: theme.spacing.unit,
  },
  cell: {
    fontSize: 16,
    padding: theme.spacing.unit,
  },
  firstCell: {
    paddingLeft: 24,
    width: '7%',
  },
  tenantCell: { width: '20%' },
  dateCell: { width: '15%' },
  squarefeetCell: { width: '10%' },
  rentCell: { width: '8%' },
  useCell: { width: '10%' },
})

class CommercialCompGroupDiscussionPanel extends React.PureComponent {
  static propTypes = {
    isExpanded: PropTypes.bool,
    form: PropTypes.object.isRequired,
    classes: PropTypes.object.isRequired,
    index: PropTypes.number.isRequired,
    onToggle: PropTypes.func.isRequired,
    groupName: PropTypes.string.isRequired,
    units: PropTypes.arrayOf(
      PropTypes.shape({
        _id: PropTypes.string.isRequired,
        address: PropTypes.string.isRequired,
        tenantName: PropTypes.string.isRequired,
        dateSigned: PropTypes.string.isRequired,
        squareFeet: PropTypes.number.isRequired,
        rentPerSf: PropTypes.number.isRequired,
        use: PropTypes.string,
      })
    ).isRequired,
    discussion: PropTypes.object.isRequired,
    marketRentConclusion: PropTypes.number.isRequired,
    assumedLeaseTerms: PropTypes.string.isRequired,
    rentRollRentBasis: PropTypes.string.isRequired,
  }

  static defaultProps = {
    isExpanded: true,
  }

  columns = [
    {
      name: '#',
      title: Labels.UNIT_NUMBER,
      className: this.props.classes.firstCell,
    },
    {
      name: 'address',
      title: Labels.UNIT_ADDRESS,
    },
    {
      name: 'use',
      title: Labels.USE,
      className: this.props.classes.useCell,
    },
    {
      name: 'tenantName',
      title: Labels.TENANT_NAME,
      className: this.props.classes.tenantCell,
    },
    {
      name: 'dateSigned',
      title: Labels.DATE_SIGNED,
      className: this.props.classes.dateCell,
    },
    {
      name: 'squareFeet',
      title: Labels.SQUARE_FEET,
      className: this.props.classes.squarefeetCell,
    },
    {
      name: 'rentPerSf',
      title: getRentPsfLabel(this.props.rentRollRentBasis, Labels.RENT_PER_SF_PER_MONTH, Labels.RENT_PER_SF),
      className: this.props.classes.rentCell,
    },
  ]

  calculateStatistic = psfValues => {
    return {
      minPsf: formatCurrencyFloat(min(psfValues)),
      maxPsf: formatCurrencyFloat(max(psfValues)),
      averagePsf: formatCurrencyFloat(mean(psfValues)),
      medianPsf: formatCurrencyFloat(median(psfValues)),
    }
  }

  getUnitsTableCellContent = ({ column, index, unit }) => {
    const data = get(unit, column.name)

    switch (column.name) {
      case '#':
        return <span data-qa="number-cell">{index + 1}</span>
      case 'use':
        return <span data-qa="use-cell">{handleOtherUseLabel(UNIT_USES_MAP, data)}</span>
      case 'address':
      case 'tenantName':
      case 'squareFeet':
        return <span data-qa={`${column.name}-cell`}>{data}</span>
      case 'dateSigned':
        return (
          <span data-qa="dateSigned-cell">
            {!isNil(data) ? new BoweryDate(data).formatShortDateWithZeroes() : MONTH_DAY_YEAR_DATE_FORMAT}
          </span>
        )
      case 'rentPerSf':
        return <span data-qa="rentPerSf-cell">{formatCurrencyFloat(data)}</span>
      default:
    }
  }

  onToggleCollapse = () => {
    const { onToggle, index } = this.props
    onToggle({ index })
  }

  generateText = values => {
    const { hasAdjustedValues } = values

    const gt = new GeneratedText()
    const marketRent = gtLocked('marketRentConclusion', 'formatCurrencyFloat')
    const assumedLeaseTerms = gtLocked('assumedLeaseTerms')
    const minPsf = gtLocked('rentPsfValues.minPsf')
    const maxPsf = gtLocked('rentPsfValues.maxPsf')
    const averagePsf = gtLocked('rentPsfValues.averagePsf')
    const medianPsf = gtLocked('rentPsfValues.medianPsf')
    const adjustedMinPsf = gtLocked('adjustedRentPsfValues.minPsf')
    const adjustedMaxPsf = gtLocked('adjustedRentPsfValues.maxPsf')
    const adjustedAveragePsf = gtLocked('adjustedRentPsfValues.averagePsf')
    const adjustedMedianPsf = gtLocked('adjustedRentPsfValues.medianPsf')

    if (hasAdjustedValues) {
      gt.t`The indicated unadjusted range of the comparable commercial units is from`
    } else {
      gt.t`The range of the comparable commercial units is from`
    }

    gt.t` ${minPsf} per square foot to ${maxPsf} per square foot,
    with an average of ${averagePsf} per square foot
    and a median of ${medianPsf} per square foot.`

    if (hasAdjustedValues) {
      gt.t` After adjustments, the comparable commercial units exhibited a range
      between ${adjustedMinPsf} per square foot to ${adjustedMaxPsf} per square foot,
      with an average of ${adjustedAveragePsf} per square foot
      and a median of ${adjustedMedianPsf} per square foot.`
    }

    gt.t` Based on the subject property's location, size and condition, we conclude to a market rent
    of ${marketRent} per square foot, ${assumedLeaseTerms}, for the subject's units.`

    return gt.getParts()
  }

  getTextData = formValues => {
    const { units, marketRentConclusion, assumedLeaseTerms } = this.props

    const rentPsfValues = this.calculateStatistic(map(units, 'rentPerSf'))
    const adjustedRentPsfValues = this.calculateStatistic(map(units, 'adjustedRentPerSF'))
    const hasAdjustedValues = !isEqual(rentPsfValues, adjustedRentPsfValues)

    return {
      rentPsfValues,
      adjustedRentPsfValues,
      marketRentConclusion,
      hasAdjustedValues,
      assumedLeaseTerms,
    }
  }

  render() {
    const { classes, isExpanded, groupName, units, index, form } = this.props

    return (
      <ExpansionPanel expanded={isExpanded} onChange={this.onToggleCollapse}>
        <ExpansionPanelSummary
          classes={{
            root: classes.titleRoot,
            content: classes.titleContent,
            expanded: classes.titleExpanded,
          }}
          expandIcon={<ExpandMore />}
          data-qa={`${groupName}-title`}
        >
          {groupName}
        </ExpansionPanelSummary>

        <ExpansionPanelDetails className={classes.expansionPanelDetail}>
          {units.length > 0 ? (
            <Grid container direction="column">
              <Grid item>
                <Table>
                  <TableHead>
                    <TableHeadRow columns={this.columns} cellClass={classes.headCell} />
                  </TableHead>
                  <TableBody>
                    {units.map((unit, index) => (
                      <TableBodyRowNew
                        key={index}
                        cellClass={classes.cell}
                        columns={this.columns}
                        getCellContent={this.getUnitsTableCellContent}
                        unit={unit}
                        index={index}
                        unitName={`units[${index}]`}
                        data-qa={`row-${index}`}
                      />
                    ))}
                  </TableBody>
                </Table>
              </Grid>

              <Grid item>
                <div className={classes.generalCommentContainer}>
                  <NarrativeComponent
                    title={`${groupName} Discussion Text Generator`}
                    generatedText={this.generateText}
                    data={this.getTextData(form.values)}
                    name={`items[${index}].discussion`}
                  />
                </div>
              </Grid>
            </Grid>
          ) : (
            <AlertInfo className={classes.noResultsLabel} data-qa="no-units">
              {Labels.THERE_ARE_NO_COMMERCIAL_UNITS}
            </AlertInfo>
          )}
        </ExpansionPanelDetails>
      </ExpansionPanel>
    )
  }
}

export default withStyles(styles)(CommercialCompGroupDiscussionPanel)
