import React from 'react'

import BoweryDate from '@bowery-valuation/bowery-date'
import { isEmpty } from 'lodash'
import { Table, TableBody, TableCell, TableFooter, TableHead, TableRow } from '@mui/material'

import { VALUE_NOT_CALCULABLE } from 'shared/constants/acas'
import { getConcludedSaleValueLabel } from 'shared/report-calculations/sales-approach/helpers'

import { Number as NumberField } from '..'

import { formatCurrencyFloat } from '../../utils/numberFormatters'

import IncomeAdjustmentRow from './IncomeAdjustmentRow'
import SalesApproachBasisRow from './SalesApproachBasisRow'

const LandAdjustmentsTableView = ({ removeDeduction, values }) => {
  const {
    asIsLandValue,
    dateOfFinalValueAsIs,
    landDeductions,
    landValue,
    roundingFactor,
    subjectPropertyInformation,
    unitOfComparison,
  } = values

  const renderDateOrString = React.useCallback(date => {
    if (isEmpty(date)) {
      return ''
    }
    const dateObj = new BoweryDate(date)
    return dateObj.isValidDate() ? dateObj.formatShortDate() : VALUE_NOT_CALCULABLE
  }, [])

  const concludedSaleValueLabel = React.useMemo(() => {
    return getConcludedSaleValueLabel(unitOfComparison)
  }, [unitOfComparison])

  const renderDeductions = () => {
    return landDeductions.map((deduction, index) => {
      return (
        <IncomeAdjustmentRow
          adjustment={deduction}
          collectionName="landDeductions"
          index={index}
          isEditable={true}
          key={deduction._id}
          name={index}
          onRemove={removeDeduction}
          placeholder="Deduction"
        />
      )
    })
  }

  return (
    <Table id="as-is-table">
      <TableHead sx={{ '& .MuiTableCell-head': { fontSize: 12, fontWeight: 700 } }}>
        <TableRow>
          <TableCell>Value</TableCell>
          <TableCell>Date</TableCell>
          <TableCell align="right">Amount</TableCell>
          <TableCell align="right">Final Value (RD)</TableCell>
          <TableCell sx={{ width: 96 }} />
        </TableRow>
      </TableHead>
      <TableBody data-qa="as-is-as-stabilized">
        <TableRow>
          <TableCell>{concludedSaleValueLabel}</TableCell>
          <TableCell />
          <TableCell align="right">
            <NumberField
              allowNegative={false}
              autoComplete="off"
              data-qa="saleValueConclusion-amount-input"
              decimalScale={2}
              fullWidth={false}
              margin="none"
              name="saleValueConclusion"
              placeholder="$0.00"
              prefix="$"
              thousandSeparator
            />
          </TableCell>
          <TableCell />
          <TableCell />
        </TableRow>
        <SalesApproachBasisRow unitOfComparison={unitOfComparison} propertyInformation={subjectPropertyInformation} />
        <TableRow data-qa="land-value-row" sx={{ '& .MuiTableCell-root': { fontWeight: 600 }, bgcolor: 'grey.200' }}>
          <TableCell>Land Value as Vacant:</TableCell>
          <TableCell data-qa="land-value-date-cell">{renderDateOrString(dateOfFinalValueAsIs)}</TableCell>
          <TableCell align="right" data-qa="land-value-cell">
            {formatCurrencyFloat(landValue)}
          </TableCell>
          <TableCell align="right" data-qa="land-value-final-value-cell">
            {formatCurrencyFloat((Math.round(landValue / roundingFactor) || 1) * roundingFactor)}
          </TableCell>
          <TableCell />
        </TableRow>
        {renderDeductions()}
      </TableBody>
      {!!landDeductions.length && (
        <TableFooter
          sx={{ '& .MuiTableCell-root': { bgcolor: 'grey.200', color: 'text.primary', fontSize: 14, fontWeight: 600 } }}
        >
          <TableRow data-qa="as-is-land-value-row">
            <TableCell>As Is Market Value of the Land:</TableCell>
            <TableCell data-qa="as-is-land-value-date-cell">{renderDateOrString(dateOfFinalValueAsIs)}</TableCell>
            <TableCell align="right" data-qa="as-is-land-value-cell">
              {formatCurrencyFloat(asIsLandValue)}
            </TableCell>
            <TableCell align="right" data-qa="as-is-land-final-value-cell">
              {formatCurrencyFloat((Math.round(asIsLandValue / roundingFactor) || 1) * roundingFactor)}
            </TableCell>
            <TableCell />
          </TableRow>
        </TableFooter>
      )}
    </Table>
  )
}

export default LandAdjustmentsTableView
