import _axios from 'axios'
import axiosRetry from 'axios-retry'
import axiosBetterStacktrace from 'axios-better-stacktrace'

// use it before any other interceptors

import { errorNotification } from '../shared/redux/actions/notifications'

import { BASE64URL_REGEX, JWT_STORAGE, LOGIN_PATH } from '../shared/constants'

import { NETWORK_ERROR_MSG, RESPONSE_ERROR_MSG } from './constants'

class HttpClientManager {
  static myInstance = null

  _socketId = null
  _dispatch = null

  static getInstance() {
    if (HttpClientManager.myInstance == null) {
      HttpClientManager.myInstance = new HttpClientManager()
    }

    return this.myInstance
  }

  setDispatch(dispatch) {
    this._dispatch = dispatch
  }

  setSocketId(socketId) {
    this._socketId = socketId
  }

  onRejectedInterceptor = (error, numRetries, showErrorNotification) => {
    const isLastAttempt = numRetries % (error.response?.config['axios-retry']?.retryCount + 1) === 0
    console.error('request error', error)

    const showError = showErrorNotification && axiosRetry.isRetryableError(error) && isLastAttempt
    const userFacingMessage = error.message !== 'Network Error' ? RESPONSE_ERROR_MSG : NETWORK_ERROR_MSG
    showError &&
      this._dispatch(
        errorNotification({
          message: userFacingMessage,
        })
      )
    error.stack = error.config?.topmostError?.stack
    error.message = `HttpClient Error on request to ${error.config.url}: ${error.message}`

    return Promise.reject(error)
  }
  createHttpClient = (configOverride = {}, options = { showError: true, retries: 2 }) => {
    const axiosInstance = _axios.create(configOverride)
    axiosBetterStacktrace(axiosInstance, { errorMsg: 'Http Error' })
    let numRetries = 0
    /** exponential retry on network failure **/
    // https://github.com/softonic/axios-retry/issues/87
    const retryDelay = (retryNumber = 0) => {
      const seconds = Math.pow(2, retryNumber) * 1000
      const randomMs = 1000 * Math.random()
      return seconds + randomMs
    }

    axiosRetry(axiosInstance, {
      retries: options.retries,
      retryDelay,
      // retry on Network Error & 5xx responses
      retryCondition: axiosRetry.isRetryableError,
    })

    /**
     * handle errors on request & response
     **/

    axiosInstance.interceptors.request.use(
      request => {
        request.headers['SocketId'] = this._socketId
        return request
      },
      error => this.onRejectedInterceptor(error, ++numRetries, options.showError)
    )
    // Add a response interceptor
    axiosInstance.interceptors.response.use(
      response => {
        // Any status code that lie within the range of 2xx cause this function to trigger
        if ('x-new-token' in response.headers) {
          const token = response.headers['x-new-token']
          if (token && BASE64URL_REGEX.test(token)) {
            console.debug('updated token', token)
            localStorage.setItem(JWT_STORAGE, token)
          } else {
            console.warn('Found invalid token in response headers.', token)
          }
        }

        return response
      },
      error => {
        // Any status codes that falls outside the range of 2xx cause this function to trigger
        if (error?.response?.status === 401 && window.location.pathname !== LOGIN_PATH) {
          console.info('Received 401 response. Redirecting to login...')
          window.location.href = window.location.origin + LOGIN_PATH
        }
        return this.onRejectedInterceptor(error, ++numRetries, options.showError)
      }
    )

    return axiosInstance
  }
}

export default HttpClientManager.getInstance()
