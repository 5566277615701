import { get, isNaN } from 'lodash'

import { DEFAULT_WAIVER_BASIS_SENTENCE, WaiverBasisCommentaryParts } from 'shared/constants/property/zoning'
import { TEMPLATE_TYPES } from 'shared/constants/report'

const mapData = ({
  actualParkingSpaces = 0,
  isFreddieMacReport,
  isConformingWithParkingRequirements,
  areWaivedParkingRequirements,
  waiverBasis,
  requiredParkingSpaces,
}) => {
  const waiverBasisSentence = areWaivedParkingRequirements
    ? `${WaiverBasisCommentaryParts[waiverBasis] || DEFAULT_WAIVER_BASIS_SENTENCE} `
    : ''

  return {
    actualParkingSpaces: actualParkingSpaces || 'none',
    isFreddieMacReport,
    isConformingWithParkingRequirements,
    areWaivedParkingRequirements,
    waiverBasis,
    requiredParkingSpaces: requiredParkingSpaces || 'no',
    isSingleRequiredParkingSpace: requiredParkingSpaces === 1,
    waiverBasisSentence,
  }
}

export const mapDTO = formValues => {
  const {
    actualParkingSpaces = 0,
    isFreddieMacReport,
    isConformingWithParkingRequirements,
    areWaivedParkingRequirements,
    waiverBasis,
  } = formValues

  let { requiredParkingSpaces = 0 } = formValues
  requiredParkingSpaces = Math.ceil(requiredParkingSpaces)

  return mapData({
    actualParkingSpaces,
    isFreddieMacReport,
    isConformingWithParkingRequirements,
    areWaivedParkingRequirements,
    waiverBasis,
    requiredParkingSpaces,
  })
}

export const mapDataModel = report => {
  const isConformingWithParkingRequirements = get(report, 'zoning.isConformingWithParkingRequirements')
  const areWaivedParkingRequirements = get(report, 'zoning.areWaivedParkingRequirements')
  const waiverBasis = get(report, 'zoning.waiverBasis')
  const actualParkingSpaces = get(report, 'potential_gross_income.parkingIncome.parkingSpaces', 0)
  const actualParkingSpacesValue = parseInt(actualParkingSpaces, 10)
  const requiredParkingSpaces = get(report, 'zoning.requiredParkingSpaces')
  const isFreddieMacReport = report.templateType === TEMPLATE_TYPES.FREDDIE_MAC

  return mapData({
    actualParkingSpaces: isNaN(actualParkingSpacesValue) ? null : actualParkingSpacesValue,
    isFreddieMacReport,
    isConformingWithParkingRequirements,
    areWaivedParkingRequirements,
    waiverBasis,
    requiredParkingSpaces,
  })
}
