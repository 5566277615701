import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import { kebabCase, get } from 'lodash'
import { FormSpy, Field } from 'react-final-form'
import Undo from '@mui/icons-material/Undo'
import Edit from '@mui/icons-material/Edit'
import { Paper, Grid, CircularProgress, withStyles, Fab, Typography } from '@material-ui/core'

import { compose } from 'redux'

import { connect } from 'react-redux'

import Tooltip from '../Tooltip'

import Text from '../Text'

const Mode = {
  READING: 1,
  EDITING: 2,
  REVERTING: 3,
}

const REVERTING_DELAY = 500
const UPDATING_DELAY = 10

const REVERT_BUTTON_TOOLTIP_TEXT = 'Revert to Generated'

const styles = theme => ({
  root: {
    padding: theme.spacing.unit * 2,
    borderRadius: theme.spacing.unit / 2,
    backgroundColor: '#F4F4F4',
    marginTop: 16,
    marginBottom: 16,
  },
  text: {},
  contentHeader: {
    marginTop: -14,
  },
  title: {
    fontSize: 12,
    letterSpacing: 0.4,
    color: '#000000',
  },
  button: {
    boxShadow: 'none',
    backgroundColor: '#F4F4F4',
    height: 34,
    width: 34,
    '&:hover': {
      backgroundColor: theme.palette.primary[100],
    },
  },
  editButton: {
    boxShadow: 'none',
    backgroundColor: '#F4F4F4',
    height: 34,
    width: 34,
    '&:hover': {
      backgroundColor: theme.palette.secondary[300],
    },
  },
  editIcon: {
    fontSize: 18,
    color: theme.palette.secondary[700],
  },
  revertIcon: {
    fontSize: 18,
  },
  progressIcon: {
    fontSize: 18,
    width: '18px!important',
    height: '18px!important',
  },
  textContent: {
    color: 'rgba(0, 0, 0, 0.6)',
    letterSpacing: 0.5,
    fontSize: 16,
    margin: 'auto',
    whiteSpace: 'pre-wrap',
  },
})

class EditableTextGeneratorForm extends React.PureComponent {
  static propTypes = {
    classes: PropTypes.object.isRequired,
    getGeneratedText: PropTypes.func.isRequired,
    change: PropTypes.func.isRequired,
    values: PropTypes.object.isRequired,
    readOnly: PropTypes.bool,
    isDynamicContent: PropTypes.bool,
    customClasses: PropTypes.object,
    readModeRender: PropTypes.func,
    editModeRender: PropTypes.func,
    title: PropTypes.string,
    dataPath: PropTypes.string.isRequired,
    propertyType: PropTypes.string.isRequired,
    isLoading: PropTypes.bool,
  }

  static defaultProps = {
    readOnly: false,
    isDynamicContent: false,
    customClasses: {},
    title: '',
    customContentFlagName: 'hasCustomContent',
    isLoading: false,
  }

  state = {
    mode: get(this.props.values, `${this.props.dataPath}.isGeneratedCommentaryOverridden`)
      ? Mode.EDITING
      : Mode.READING,
  }

  static async getDerivedStateFromProps(props, state) {
    const { isDynamicContent, dataPath, getGeneratedText, values, change, propertyType } = props
    const { mode } = state
    const isGeneratedCommentaryOverridden = get(values, `${dataPath}.isGeneratedCommentaryOverridden`)

    if (mode === Mode.READING && isDynamicContent && !isGeneratedCommentaryOverridden) {
      const fieldName = `${dataPath}.commentary`
      const newText = await getGeneratedText({ propertyType, ...values })
      const currentText = get(values, fieldName)

      if (newText !== currentText) {
        setTimeout(() => {
          change(fieldName, newText)
        }, UPDATING_DELAY)
      }
    }
    return state
  }

  get panelInReadMode() {
    const { classes, dataPath, values } = this.props
    const fieldName = `${dataPath}.commentary`

    return (
      <React.Fragment>
        <Field name={fieldName}>{() => null}</Field>
        <p className={classes.textContent} data-qa={`${fieldName}-generated-text`}>
          {get(values, fieldName)}
        </p>
      </React.Fragment>
    )
  }

  get panelInEditMode() {
    const { dataPath } = this.props
    const fieldName = `${dataPath}.commentary`
    return <Text rows={6} name={fieldName} />
  }

  get button() {
    const { mode } = this.state
    const { classes, title } = this.props

    const name = kebabCase(title)

    switch (mode) {
      case Mode.READING:
        return (
          <Fab className={classes.editButton} onClick={this.edit} data-qa={`${name}-edit-btn`}>
            <Edit className={classes.editIcon} />
          </Fab>
        )
      case Mode.REVERTING:
        return (
          <Fab className={classes.button} data-qa={`${name}-generating-btn`}>
            <CircularProgress className={classes.progressIcon} />
          </Fab>
        )
      default:
        return (
          <Tooltip text={REVERT_BUTTON_TOOLTIP_TEXT} placement="top-start">
            <Fab className={classes.button} onClick={this.revert} data-qa={`${name}-revert-btn`}>
              <Undo className={classes.revertIcon} />
            </Fab>
          </Tooltip>
        )
    }
  }

  get content() {
    const { readModeRender, editModeRender } = this.props
    const { mode } = this.state
    if (mode === Mode.READING) {
      return readModeRender ? readModeRender() : this.panelInReadMode
    }
    return editModeRender ? editModeRender() : this.panelInEditMode
  }

  edit = () => {
    this.toggleCustomContent()
    this.setState({ mode: Mode.EDITING })
  }

  toggleCustomContent = () => {
    const { dataPath, change, focus, readOnly, values } = this.props
    const customContentFlagName = `${dataPath}.isGeneratedCommentaryOverridden`

    if (!readOnly) {
      const currentCustomContentValue = !!get(values, customContentFlagName)
      change(customContentFlagName, !currentCustomContentValue)
    }
    focus(customContentFlagName)
  }

  revert = () => {
    const { dataPath, getGeneratedText, values, change, propertyType } = this.props
    this.setState({ mode: Mode.REVERTING })

    setTimeout(async () => {
      const text = await getGeneratedText({ propertyType, ...values })
      const fieldName = `${dataPath}.commentary`
      change(fieldName, text)
      this.toggleCustomContent()
      this.setState({ mode: Mode.READING })
    }, REVERTING_DELAY)
  }

  render() {
    const { classes, readOnly, title, customClasses, button, isLoading, dataPath } = this.props
    return (
      <Paper elevation={0} className={classNames(classes.root, customClasses.root)} data-qa="text-generation-wrapper">
        <Field name={`${dataPath}.isGeneratedCommentaryOverridden`}>{() => null}</Field>

        <Grid container spacing={8} className={classes.contentHeader}>
          <Grid item xs={12} container justify="space-between" alignItems="center">
            <Grid item>
              <Typography
                variant="body1"
                className={classNames(classes.title, customClasses.title)}
                data-qa="text-generation-title"
              >
                {title}
              </Typography>
            </Grid>
            {!readOnly && <Grid item>{button || this.button}</Grid>}
          </Grid>

          <Grid item xs={12}>
            {isLoading ? (
              <Grid container direction="row" justify="center" alignItems="center">
                <CircularProgress size={24} />
              </Grid>
            ) : (
              this.content
            )}
          </Grid>
        </Grid>
      </Paper>
    )
  }
}

const EditableTextGenerator = props => (
  <FormSpy subscription={{ values: true }}>
    {({ values, form }) => (
      <EditableTextGeneratorForm values={values} change={form.change} focus={form.focus} {...props} />
    )}
  </FormSpy>
)

export default compose(
  connect(state => {
    const propertyType = get(state, 'report.reportData.propertyType')
    return {
      propertyType,
    }
  }),
  withStyles(styles)
)(EditableTextGenerator)
