import * as React from 'react'
import PropTypes from 'prop-types'
import { Grid, Paper, Typography, withStyles } from '@material-ui/core'

import DropDown from '../../../../shared/components/DropDown/NativeDropDownField'
import { Callout, DismissibleCallout } from '../../../../shared/components'

import RenovationsGeneratedComment from './RenovationsGeneratedComment'
import {
  RenovationCostTypes,
  ProspectiveRenovationTypes,
  PROSPECTIVE_RENOVATION_TYPES,
  RENOVATION_COSTS_CALLOUT_TEXT,
  ITEMIZED_RENOVATION_LOCATION_CALLOUT_TEXT,
} from './constants'

import ItemizedTable from './ItemizedTable'
import TotalTable from './TotalTable'
import { paperStyles } from './styles'

const styles = theme => ({
  paper: {
    ...theme.paper,
    ...paperStyles,
  },
  title: {
    marginBottom: 24,
  },
  dropDownContainer: {
    width: 340,
  },
  dropDownMenu: {
    marginTop: 4,
  },
  root: {
    padding: 12,
  },
  callout: {
    marginTop: -16,
  },
})

class ProspectiveRenovations extends React.PureComponent {
  static propTypes = {
    prospectiveRenovationType: PropTypes.string.isRequired,
    renovationCostsType: PropTypes.string.isRequired,
    form: PropTypes.object.isRequired,
  }

  render() {
    const { classes, renovationCostsType, prospectiveRenovationType, form } = this.props
    return (
      <div className={classes.root}>
        <Grid
          component={Paper}
          container
          spacing={24}
          className={classes.paper}
          data-qa="prospective-renovations-section-tile"
        >
          <Grid item xs={12} sm={12}>
            <Typography className={classes.title} variant="h6">
              Prospective Renovations
            </Typography>
            <DropDown
              fullWidth
              classes={{
                menuPaper: classes.dropDownMenu,
                formControl: classes.dropDownContainer,
              }}
              name="prospectiveRenovationType"
              onChange={form.mutators.updateProspectiveRenovationType}
              items={PROSPECTIVE_RENOVATION_TYPES}
            />
          </Grid>
          {prospectiveRenovationType !== ProspectiveRenovationTypes.NO_RENOVATIONS && (
            <React.Fragment>
              <Grid item xs={12} sm={12}>
                {renovationCostsType === RenovationCostTypes.ITEMIZED && (
                  <Callout content={ITEMIZED_RENOVATION_LOCATION_CALLOUT_TEXT} sx={{ mb: 1 }} variant="warn" />
                )}
                <Typography variant="subtitle1">Renovation Costs</Typography>
                {renovationCostsType === RenovationCostTypes.TOTAL && <TotalTable form={this.props.form} />}
                {renovationCostsType === RenovationCostTypes.ITEMIZED && <ItemizedTable form={this.props.form} />}
              </Grid>
              <Grid item xs={12} sm={12}>
                <DismissibleCallout text={RENOVATION_COSTS_CALLOUT_TEXT} />
              </Grid>
              <Grid item xs={12} sm={12}>
                <RenovationsGeneratedComment values={form.values} />
              </Grid>
            </React.Fragment>
          )}
        </Grid>
      </div>
    )
  }
}

export default withStyles(styles)(ProspectiveRenovations)
