import React from 'react'
import { Grid, Paper, Stack, Typography } from '@mui/material'
import wrapForm from 'report/forms/wrapForm'

import useFieldValue from 'report/utils/useFieldValue'
import { CheckboxWithLabel, NarrativeComponent } from 'client-shared/components'
import constructionCostComps from 'shared/utils/textGeneration/costApproach/constructionCostComps'
import siteImprovementCostCompsIntroduction from 'shared/utils/textGeneration/costApproach/siteImprovementComps'
import siteImprovementCostCompsConclusion from 'shared/utils/textGeneration/costApproach/siteImprovementComps/conclusion'
import { CONSTRUCTION_COST_COMPS_PATH } from 'shared/constants/report/keysAndDataPaths'

import { PAPER_WIDTH } from '../../helpers'

const ConstructionCostComps = () => {
  const includeConstructionCostComps = useFieldValue('constructionCostComps.includeConstructionCostComps')
  const includeSiteImprovementCostComps = useFieldValue('constructionCostComps.includeSiteImprovementCostComps')

  return (
    <Paper id="construction-cost-comps" sx={{ maxWidth: PAPER_WIDTH }}>
      <Typography variant="h5">Construction Cost Comps</Typography>
      <Stack spacing={5}>
        <Grid spacing={2}>
          <CheckboxWithLabel
            id="constructionCostComps"
            label="Include Construction Cost Comps?"
            name="constructionCostComps.includeConstructionCostComps"
          />
          <CheckboxWithLabel
            id="siteImprovementCostComps"
            label="Include Site Improvement Cost Comps?"
            name="constructionCostComps.includeSiteImprovementCostComps"
          />
        </Grid>
        {includeConstructionCostComps && (
          <>
            <NarrativeComponent
              name="constructionCostComps.constructionCostCompsTableSummary"
              title="Construction Cost Comparables"
              generatedText={constructionCostComps.generate}
            />
          </>
        )}
        {includeSiteImprovementCostComps && (
          <>
            <NarrativeComponent
              name="constructionCostComps.siteImprovementCompsTableSummary"
              title="Site Improvement Cost Comparables"
              generatedText={siteImprovementCostCompsIntroduction.generate}
            />
            <NarrativeComponent
              name="constructionCostComps.siteImprovementCompsConclusionParagraph"
              title="Site Improvement Cost Comparables Conclusion"
              generatedText={siteImprovementCostCompsConclusion.generate}
            />
          </>
        )}
      </Stack>
    </Paper>
  )
}

export default wrapForm(CONSTRUCTION_COST_COMPS_PATH, {
  heading: 'Construction Cost Comps',
})(ConstructionCostComps)
