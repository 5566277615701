import { get } from 'lodash'

import { PropertyTypes } from 'shared/constants'
import { SF_BASIS_OPTIONS } from 'shared/constants/report'

type FormValues = {
  expenseDataSource: string
  propertyType: string
  basisForSFAnalysis: string
  expenseHistory: any
}

type NarrativeValues = {
  expenseDataNotProvided: boolean
  isCommercialProperty: boolean
  basisForSFAnalysisText: string
}

type ReportModel = {
  // eslint-disable-next-line camelcase
  property_information: {
    type: string
    basisForSFAnalysis: string
  }
  expenseDataSource: string
}

export const mapDTO = (formValues: FormValues): NarrativeValues => {
  const propertyType = get(formValues, 'propertyType')
  const basisForSFAnalysis = get(formValues, 'basisForSFAnalysis')
  const expenseHistory = get(formValues, 'expenseHistory', [])

  const expenseDataNotProvided = !expenseHistory || expenseHistory.length === 0
  const isCommercialProperty = propertyType === PropertyTypes.COMMERCIAL
  // @ts-ignore
  const basisForSFAnalysisText = SF_BASIS_OPTIONS[basisForSFAnalysis].toLowerCase()

  return {
    expenseDataNotProvided,
    isCommercialProperty,
    basisForSFAnalysisText,
  }
}

export const mapDataModel = (dataModel: ReportModel): NarrativeValues => {
  const propertyType = get(dataModel, 'property_information.type')
  const basisForSFAnalysis = get(dataModel, 'property_information.basisForSFAnalysis')
  const expenseHistory = get(dataModel, 'proforma_history.units', [])

  const expenseDataNotProvided = !expenseHistory || expenseHistory.length === 0
  const isCommercialProperty = propertyType === PropertyTypes.COMMERCIAL
  // @ts-ignore
  const basisForSFAnalysisText = SF_BASIS_OPTIONS[basisForSFAnalysis].toLowerCase()

  return {
    expenseDataNotProvided,
    isCommercialProperty,
    basisForSFAnalysisText,
  }
}
