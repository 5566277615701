import {
  NET_OPERATING_INCOME,
  TOTAL_OPERATING_EXPENSES,
  TOTAL_OPERATING_EXPENSES_WITHOUT_TAXES,
} from 'shared/constants/expenses/expenseHistory'

export const EXPENSE_MODAL_NAME = 'Add Custom Expense Category'
export const GROSS_REVENUE = 'grossRevenue'
export const REAL_ESTATE_TAXES = 'realEstateTaxes'

export const TOTAL_ROWS_DEFINITIONS = [
  {
    id: TOTAL_OPERATING_EXPENSES.key,
    label: TOTAL_OPERATING_EXPENSES.name,
    name: TOTAL_OPERATING_EXPENSES.name,
    rowDef: {
      summary: true,
    },
  },
  {
    id: TOTAL_OPERATING_EXPENSES_WITHOUT_TAXES.key,
    name: TOTAL_OPERATING_EXPENSES_WITHOUT_TAXES.name,
    rowDef: {
      summary: true,
    },
  },
  {
    id: NET_OPERATING_INCOME.key,
    name: NET_OPERATING_INCOME.name,
    rowDef: {
      summary: true,
    },
  },
]

export const EXPENSE_HISTORY_CATEGORY = 'expense-history'
export const PRO_FORMA_CATEGORY = 'pro-forma'

export const CATEGORY_LABEL_MAP = {
  [EXPENSE_HISTORY_CATEGORY]: '3 Years of Income and Expenses',
  [PRO_FORMA_CATEGORY]: 'Pro Forma Income and Expenses',
}
