import createDecorator from 'final-form-calculate'
import { divide, get } from 'lodash'

export const calculateSuggestedMortgageRates = createDecorator({
  field: [
    'surveyOfCompetitiveRates.tenYearTreasuryBond.rate',
    'surveyOfCompetitiveRates.suggestedMortgage.minBasisPoints',
    'surveyOfCompetitiveRates.suggestedMortgage.maxBasisPoints',
  ],
  updates: (value, name, allValues, prevValues) => {
    const tenYearTreasuryBondRate = get(allValues, 'surveyOfCompetitiveRates.tenYearTreasuryBond.rate', 0) * 100
    const minBasisPoints = get(allValues, 'surveyOfCompetitiveRates.suggestedMortgage.minBasisPoints', 175)
    const maxBasisPoints = get(allValues, 'surveyOfCompetitiveRates.suggestedMortgage.maxBasisPoints', 400)
    const prevMinSuggestedMortgageRate = get(prevValues, 'surveyOfCompetitiveRates.suggestedMortgage.minRate')
    const prevMaxSuggestedMortgageRate = get(prevValues, 'surveyOfCompetitiveRates.suggestedMortgage.maxRate')
    const minSuggestedMortgageRate = tenYearTreasuryBondRate + divide(minBasisPoints, 100)
    const maxSuggestedMortgageRate = tenYearTreasuryBondRate + divide(maxBasisPoints, 100)
    const currentDate = get(allValues, 'surveyOfCompetitiveRates.suggestedMortgage.lastUpdated')

    const shouldUpdateDate =
      (prevMinSuggestedMortgageRate && prevMinSuggestedMortgageRate !== minSuggestedMortgageRate) ||
      (prevMaxSuggestedMortgageRate && prevMaxSuggestedMortgageRate !== maxSuggestedMortgageRate)
    return {
      'surveyOfCompetitiveRates.suggestedMortgage.minRate': divide(minSuggestedMortgageRate, 100),
      'surveyOfCompetitiveRates.suggestedMortgage.maxRate': divide(maxSuggestedMortgageRate, 100),
      'surveyOfCompetitiveRates.suggestedMortgage.lastUpdated': shouldUpdateDate ? new Date() : currentDate,
    }
  },
})
