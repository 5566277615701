export const formatList = (list, { or = false } = {}) => {
  switch (list.length) {
    case 0: {
      return undefined
    }
    case 1: {
      return list[0]
    }
    case 2: {
      if (or) {
        return `${list[0]} or ${list[1]}`
      }
      return `${list[0]} and ${list[1]}`
    }
    default: {
      const listArr = []
      const listCopy = [...list]

      while (listCopy.length > 2) {
        listArr.push(`${listCopy.shift()}, `)
      }
      listArr.push(`${listCopy.shift()}`)
      if (or) {
        listArr.push(' or ')
      } else {
        listArr.push(', and ')
      }
      listArr.push(`${listCopy.shift()}`)
      return listArr.join('')
    }
  }
}

export const splitCamelCaseToString = string =>
  string
    .split(/(?=[A-Z])/)
    .join(' ')
    .toLowerCase()
