import React from 'react'

import { Grid } from '@mui/material'

import 'moment-timezone'

import {
  getIncomeApproachDescription1,
  getIncomeApproachDescription2,
} from 'shared/utils/textGeneration/incomeApproach'

import NarrativeComponent from '../../shared/components/NarrativeComponent'

import withCMSForm from './withCMSForm'

const GRID_CONTAINER_GUTTER = 2

const IncomeCapApproach = () => {
  return (
    <Grid container spacing={GRID_CONTAINER_GUTTER}>
      <Grid item xs={12}>
        <NarrativeComponent
          title="Description 1"
          generatedText={getIncomeApproachDescription1}
          name="incomeApproach.description1"
          type="edit"
        />
      </Grid>
      <Grid item xs={12}>
        <NarrativeComponent
          title="Description 2"
          generatedText={getIncomeApproachDescription2}
          name="incomeApproach.description2"
        />
      </Grid>
    </Grid>
  )
}

export default withCMSForm(IncomeCapApproach, 'Income Capitalization Approach')
