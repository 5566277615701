import { get, lowerCase } from 'lodash'

import { VALUE_CONCLUSION_TYPES } from '../../../../constants/acas'

export const mapDTO = formValues => {
  const conclusionType = get(formValues, 'reportValueConclusionType')
  const valueAsStabilized =
    conclusionType === VALUE_CONCLUSION_TYPES.AS_STABILIZED || conclusionType === VALUE_CONCLUSION_TYPES.AS_COMPLETE
  const valueAsComplete = conclusionType === VALUE_CONCLUSION_TYPES.AS_COMPLETE
  return {
    valueAsStabilized,
    valueAsComplete,
    interestAppraisedAsIsMarketValue: lowerCase(get(formValues, 'interestAppraisedAsIsMarketValue')),
    interestAppraisedAsComplete: lowerCase(get(formValues, 'interestAppraisedAsComplete')),
    interestAppraisedAsStabilized: lowerCase(get(formValues, 'interestAppraisedAsStabilized')),
  }
}

export const mapDataModel = formValues => {
  const conclusionType = get(formValues, 'valueConclusionType')
  const valueAsStabilized =
    conclusionType === VALUE_CONCLUSION_TYPES.AS_STABILIZED || conclusionType === VALUE_CONCLUSION_TYPES.AS_COMPLETE
  const valueAsComplete = conclusionType === VALUE_CONCLUSION_TYPES.AS_COMPLETE
  return {
    valueAsStabilized,
    valueAsComplete,
    interestAppraisedAsIsMarketValue: lowerCase(get(formValues, 'info.interestAppraisedAsIsMarketValue')),
    interestAppraisedAsComplete: lowerCase(get(formValues, 'info.interestAppraisedAsComplete')),
    interestAppraisedAsStabilized: lowerCase(get(formValues, 'info.interestAppraisedAsStabilized')),
  }
}
