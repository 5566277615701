import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'

import { connect } from 'react-redux'
import { get } from 'lodash'

import { Paper, Stack } from '@mui/material'

import { NarrativeComponent } from 'client-shared/components'
import occupancyRateDiscussion from 'shared/utils/textGeneration/incomeApproach/residential/occupancyRateDiscussion'
import rentRollDiscussion from 'shared/utils/textGeneration/incomeApproach/residential/rentRollDiscussion'

import { BASE_RENT_ROLL_DISCUSSION_TOOLTIP, OCCUPANCY_RATE_DISCUSSION_TOOLTIP } from '../constants'

class Discussion extends PureComponent {
  render() {
    const { valueAsStabilized, address, residentialUnits, propertyType } = this.props

    return (
      <Stack spacing={2}>
        <Paper>
          <NarrativeComponent
            title="Base Residential Income Rent Roll Discussion"
            name="rentRollDiscussion"
            generatedText={rentRollDiscussion.generate}
            data={rentRollDiscussion.mapDTO({
              address,
              residentialUnits,
              valueAsStabilized,
            })}
            tooltipText={BASE_RENT_ROLL_DISCUSSION_TOOLTIP}
          />
        </Paper>
        <Paper>
          <NarrativeComponent
            name="occupancyRateDiscussion"
            title="Occupancy Rate Discussion"
            generatedText={occupancyRateDiscussion.generate}
            data={occupancyRateDiscussion.mapDTO({
              residentialUnits,
              valueAsStabilized,
              propertyType,
            })}
            tooltipText={OCCUPANCY_RATE_DISCUSSION_TOOLTIP}
          />
        </Paper>
      </Stack>
    )
  }
}

Discussion.propTypes = {
  address: PropTypes.string.isRequired,
  residentialUnits: PropTypes.array.isRequired,
  valueAsStabilized: PropTypes.bool.isRequired,
  propertyType: PropTypes.string.isRequired,
}

export default connect(state => ({
  address: get(state, 'report.reportData.address'),
  valueAsStabilized: get(state, 'report.reportSettings.valueAsStabilized'),
  propertyType: get(state, 'report.reportData.propertyType'),
}))(Discussion)
