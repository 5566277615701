import React from 'react'

import BoweryDate from '@bowery-valuation/bowery-date'
import { concat, get, isEmpty, isNil, sumBy } from 'lodash'

import { Table, TableBody, TableCell, TableFooter, TableHead, TableRow } from '@mui/material'

import { LOSS_ITEM_VALUE_TYPE, VALUE_NOT_CALCULABLE, LOSS_ITEM_KEYS, RENT_LOSS_TYPE } from 'shared/constants/acas'
import { formatCurrencyFloat } from 'client-shared/utils/numberFormatters'
import { fromPercents, toPercents } from 'client-shared/utils/numberOperations'
import { getConcludedSaleValueLabel } from 'shared/report-calculations/sales-approach/helpers'

import TableNumberField from '../Table/TableNumberRightAligned'

import { Number as NumberField } from '../.'

import { ADJUSTMENT_AND_LOSSES_DATA_SOURCE, ADJUSTMENT_COLLECTIONS, ADJUSTMENT_TYPES } from './constants'

import LossItemTableRow from './LossItemTableRow'
import RentLossTableRow from './RentLossTableRow'
import SalesApproachBasisRow from './SalesApproachBasisRow'
import IncomeAdjustmentRow from './IncomeAdjustmentRow'
import DiscountRateRows from './DiscountRateRows'

const AdjustmentsAndLossesTableView = ({
  canEditControl,
  commercialUnits,
  concludedCapRate,
  dataSourceType,
  fillDownLossPeriod,
  getRentLossRowTitle,
  netOperatingIncome,
  onConcludedCapRateRowClick,
  onEditRentLoss,
  onRemoveRentLoss,
  removeAdjustmentOfType,
  residentialUnits,
  valueAsComplete,
  valueAsStabilized,
  values,
}) => {
  const {
    asCompleteCommercialRentLossItems,
    asCompleteLossItems,
    asCompleteResRentLossItems,
    asStabilizedCommercialRentLossItems,
    asStabilizedLossItems,
    asStabilizedResRentLossItems,
    asCompleteNpvAdjustments,
    asStabilizedNpvAdjustments,
    capitalizationConclusion,
    capitalizationConclusionRounded,
    dateOfFinalValueAsComplete,
    dateOfFinalValueAsIs,
    dateOfFinalValueAsStabilized,
    finalValueAsComplete,
    finalValueAsCompletePerSf,
    finalValueAsCompletePerUnit,
    finalValueAsCompleteRounded,
    finalValueAsIs,
    finalValueAsIsPerSf,
    finalValueAsIsPerUnit,
    finalValueAsIsRounded,
    finalValueAsStabilized,
    finalValueAsStabilizedPerSf,
    finalValueAsStabilizedPerUnit,
    finalValueAsStabilizedRounded,
    npvAdjustments,
    unitOfComparison,
    subjectPropertyInformation,
    includeAsCompleteDiscountRate = false,
    includeAsStabilizedDiscountRate = false,
  } = values

  const renderDateOrString = React.useCallback(date => {
    if (isEmpty(date)) {
      return ''
    }
    const dateObj = new BoweryDate(date)
    return dateObj.isValidDate() ? dateObj.formatShortDate() : VALUE_NOT_CALCULABLE
  }, [])

  const concludedSaleValueLabel = React.useMemo(() => {
    return getConcludedSaleValueLabel(unitOfComparison)
  }, [unitOfComparison])

  const renderAdjustmentsOfType = adjustmentType => {
    let adjustments = []
    let collectionName = null

    if (adjustmentType === ADJUSTMENT_TYPES.NPV) {
      adjustments = adjustments.concat(npvAdjustments)
      collectionName = ADJUSTMENT_COLLECTIONS.NPV
    }

    if (adjustmentType === ADJUSTMENT_TYPES.AS_COMPLETE) {
      adjustments = adjustments.concat(asCompleteNpvAdjustments)
      collectionName = ADJUSTMENT_COLLECTIONS.AS_COMPLETE
    }

    if (adjustmentType === ADJUSTMENT_TYPES.AS_STABILIZED) {
      adjustments = adjustments.concat(asStabilizedNpvAdjustments)
      collectionName = ADJUSTMENT_COLLECTIONS.AS_STABILIZED
    }

    return adjustments.map((adjustment, index) => {
      return (
        <IncomeAdjustmentRow
          adjustment={adjustment}
          collectionName={collectionName}
          index={index}
          isEditable={canEditControl()}
          key={`${collectionName}[${index}]`}
          name={`${collectionName}[${index}]`}
          onRemove={removeAdjustmentOfType}
          valueType={adjustmentType}
        />
      )
    })
  }

  const getEPTotal = (lossItems, rentLossItems, npvAdjustments) => {
    const lossTotal = sumBy(lossItems, item => {
      if (isNil(item) || isNil(item.amount)) {
        return 0
      }
      if (item.name === LOSS_ITEM_KEYS.ENTREPRENEURIAL_PROFIT) {
        return 0
      }
      return item.amount
    })
    const rentLossTotal = sumBy(rentLossItems, item => {
      if (isNil(item) || isNil(item.amount)) {
        return 0
      }
      return item.amount
    })
    const adjustmentsTotal = sumBy(npvAdjustments, adjustment => {
      if (isNil(adjustment) || isNil(adjustment.value)) {
        return 0
      }
      if (adjustment.value >= 0) {
        return 0
      }
      return Math.abs(adjustment.value)
    })
    return lossTotal + rentLossTotal + adjustmentsTotal
  }

  const asStabilizedEpTotal = getEPTotal(
    asStabilizedLossItems,
    concat(asStabilizedResRentLossItems, asStabilizedCommercialRentLossItems),
    asStabilizedNpvAdjustments
  )
  const asCompleteEpTotal = getEPTotal(
    asCompleteLossItems,
    concat(asCompleteResRentLossItems, asCompleteCommercialRentLossItems),
    asCompleteNpvAdjustments
  )

  return (
    <Table id="as-is-table">
      <TableHead sx={{ '& .MuiTableCell-head': { fontSize: 12, fontWeight: 700 } }}>
        <TableRow>
          <TableCell>Value</TableCell>
          <TableCell>Time Period</TableCell>
          <TableCell align="right">Amount</TableCell>
          <TableCell align="right">Final Value (RD)</TableCell>
          <TableCell sx={{ width: 96 }} />
        </TableRow>
      </TableHead>
      <TableBody data-qa="as-is-as-stabilized">
        {dataSourceType === ADJUSTMENT_AND_LOSSES_DATA_SOURCE.INCOME_APPROACH && (
          <>
            <TableRow>
              <TableCell>Net Operating Income (NOI)</TableCell>
              <TableCell />
              <TableCell align="right" data-qa="noi-amount-cell">
                {formatCurrencyFloat(netOperatingIncome.total)}
              </TableCell>
              <TableCell />
              <TableCell />
            </TableRow>
            <TableRow onClick={onConcludedCapRateRowClick}>
              <TableCell>Concluded Cap Rate</TableCell>
              <TableCell />
              <TableCell align="right" data-qa="concludedCapRate-amount-cell">
                <TableNumberField
                  allowNegative={false}
                  autoComplete="off"
                  data-qa="concludedCapRate-input"
                  decimalScale={2}
                  format={toPercents}
                  fullWidth={false}
                  inputRef={concludedCapRate}
                  margin="none"
                  name="concludedCapRate"
                  noPx
                  noPy
                  normalize={fromPercents}
                  placeholder="0.00"
                  right
                  suffix="%"
                />
              </TableCell>
              <TableCell />
              <TableCell />
            </TableRow>
          </>
        )}
        {dataSourceType === ADJUSTMENT_AND_LOSSES_DATA_SOURCE.SALES_APPROACH && (
          <>
            <TableRow>
              <TableCell>{concludedSaleValueLabel}</TableCell>
              <TableCell />
              <TableCell align="right">
                <NumberField
                  allowNegative={false}
                  autoComplete="off"
                  data-qa="saleValueConclusion-amount-input"
                  decimalScale={2}
                  fullWidth={false}
                  margin="none"
                  name="saleValueConclusion"
                  placeholder="$0.00"
                  prefix="$"
                  thousandSeparator
                />
              </TableCell>
              <TableCell />
              <TableCell />
            </TableRow>
            <SalesApproachBasisRow
              unitOfComparison={unitOfComparison}
              propertyInformation={subjectPropertyInformation}
              onConcludedCapRateRowClick={onConcludedCapRateRowClick}
            />
          </>
        )}
        {!isEmpty(npvAdjustments) && (
          <TableRow>
            <TableCell>Capitalization Conclusion</TableCell>
            <TableCell />
            <TableCell align="right" data-qa="capitalizationConclusion-amount-cell">
              {capitalizationConclusion}
            </TableCell>
            <TableCell align="right" data-qa="capitalizationConclusionRounded-final-value-cell">
              {capitalizationConclusionRounded}
            </TableCell>
            <TableCell />
          </TableRow>
        )}

        {renderAdjustmentsOfType(ADJUSTMENT_TYPES.NPV)}

        {valueAsStabilized && (
          <React.Fragment>
            <TableRow
              data-qa="prospective-market-value-as-stabilized-row"
              sx={{ '& .MuiTableCell-root': { fontWeight: 600 }, bgcolor: 'grey.200' }}
            >
              <TableCell>Prospective Market Value As Stabilized</TableCell>
              <TableCell data-qa="as-stabilized-period-cell">
                {renderDateOrString(dateOfFinalValueAsStabilized)}
              </TableCell>
              <TableCell align="right" data-qa="as-stabilized-amount-cell">
                {finalValueAsStabilized}
              </TableCell>
              <TableCell align="right" data-qa="as-stabilized-final-value-cell">
                {finalValueAsStabilizedRounded}
              </TableCell>
              <TableCell />
            </TableRow>
            {dataSourceType === ADJUSTMENT_AND_LOSSES_DATA_SOURCE.INCOME_APPROACH && (
              <>
                <TableRow sx={{ '& .MuiTableCell-root': { fontWeight: 600 }, bgcolor: 'grey.200' }}>
                  <TableCell>Prospective Market Value As Stabilized Per Unit</TableCell>
                  <TableCell />
                  <TableCell />
                  <TableCell align="right">{finalValueAsStabilizedPerUnit}</TableCell>
                  <TableCell />
                </TableRow>
                <TableRow sx={{ '& .MuiTableCell-root': { fontWeight: 600 }, bgcolor: 'grey.200' }}>
                  <TableCell>Prospective Market Value As Stabilized Per SF</TableCell>
                  <TableCell />
                  <TableCell />
                  <TableCell align="right">{finalValueAsStabilizedPerSf}</TableCell>
                  <TableCell />
                </TableRow>
              </>
            )}
            {includeAsStabilizedDiscountRate && <DiscountRateRows name="asStabilized" disabled={!canEditControl()} />}
            {renderAdjustmentsOfType(ADJUSTMENT_TYPES.AS_STABILIZED)}

            {asStabilizedResRentLossItems.map((rentLossItem, index) => {
              rentLossItem.name = getRentLossRowTitle(rentLossItem, residentialUnits, 'Residential Rent Loss')
              return (
                <RentLossTableRow
                  canDelete={asStabilizedResRentLossItems.length > 1}
                  collectionName="asStabilizedResRentLossItems"
                  fillDownLossPeriod={fillDownLossPeriod('asStabilizedResRentLossItems')}
                  index={index}
                  isEditable={canEditControl()}
                  key={`asStabilizedResRentLossItems[${index}]`}
                  lossItem={rentLossItem}
                  name={`asStabilizedResRentLossItems[${index}]`}
                  onEditItem={onEditRentLoss}
                  onRemoveItem={onRemoveRentLoss}
                  rentLossType={RENT_LOSS_TYPE.RESIDENTIAL}
                />
              )
            })}

            {asStabilizedCommercialRentLossItems.map((rentLossItem, index) => {
              rentLossItem.name = getRentLossRowTitle(rentLossItem, commercialUnits, 'Commercial Rent Loss')
              return (
                <RentLossTableRow
                  canDelete={asStabilizedCommercialRentLossItems.length > 1}
                  collectionName="asStabilizedCommercialRentLossItems"
                  fillDownLossPeriod={fillDownLossPeriod('asStabilizedCommercialRentLossItems')}
                  index={index}
                  isEditable={canEditControl()}
                  key={`asStabilizedCommercialRentLossItems[${index}]`}
                  lossItem={rentLossItem}
                  name={`asStabilizedCommercialRentLossItems[${index}]`}
                  onEditItem={onEditRentLoss}
                  onRemoveItem={onRemoveRentLoss}
                  rentLossType={RENT_LOSS_TYPE.COMMERCIAL}
                />
              )
            })}

            {asStabilizedLossItems.map((lossItem, index) => {
              const hasMonths = lossItem.type === LOSS_ITEM_VALUE_TYPE.COMPUTED_VALUE
              return (
                <LossItemTableRow
                  epTotal={asStabilizedEpTotal}
                  fillDownLossPeriod={fillDownLossPeriod('asStabilizedLossItems')}
                  hasMonths={hasMonths}
                  index={index}
                  isEditable={canEditControl()}
                  key={`asStabilizedLossItems[${index}]`}
                  lossItem={lossItem}
                  name={`asStabilizedLossItems[${index}]`}
                />
              )
            })}
          </React.Fragment>
        )}

        {valueAsComplete && (
          <>
            <TableRow
              data-qa="prospective-market-value-as-complete-row"
              sx={{ '& .MuiTableCell-root': { fontWeight: 600 }, bgcolor: 'grey.200' }}
            >
              <TableCell>Prospective Market Value As Complete</TableCell>
              <TableCell data-qa="as-complete-period-cell">{renderDateOrString(dateOfFinalValueAsComplete)}</TableCell>
              <TableCell align="right" data-qa="as-complete-amount-cell">
                {finalValueAsComplete}
              </TableCell>
              <TableCell align="right" data-qa="as-complete-final-value-cell">
                {finalValueAsCompleteRounded}
              </TableCell>
              <TableCell />
            </TableRow>
            {dataSourceType === ADJUSTMENT_AND_LOSSES_DATA_SOURCE.INCOME_APPROACH && (
              <>
                <TableRow sx={{ '& .MuiTableCell-root': { fontWeight: 600 }, bgcolor: 'grey.200' }}>
                  <TableCell>Prospective Market Value As Complete Per Unit</TableCell>
                  <TableCell />
                  <TableCell />
                  <TableCell align="right">{finalValueAsCompletePerUnit}</TableCell>
                  <TableCell />
                </TableRow>
                <TableRow sx={{ '& .MuiTableCell-root': { fontWeight: 600 }, bgcolor: 'grey.200' }}>
                  <TableCell>Prospective Market Value As Complete Per SF</TableCell>
                  <TableCell />
                  <TableCell />
                  <TableCell align="right">{finalValueAsCompletePerSf}</TableCell>
                  <TableCell />
                </TableRow>
              </>
            )}
            {includeAsCompleteDiscountRate && <DiscountRateRows name="asComplete" disabled={!canEditControl()} />}
            {renderAdjustmentsOfType(ADJUSTMENT_TYPES.AS_COMPLETE)}

            {asCompleteResRentLossItems.map((rentLossItem, index) => {
              rentLossItem.name = getRentLossRowTitle(rentLossItem, residentialUnits, 'Residential Rent Loss')
              return (
                <RentLossTableRow
                  canDelete={asCompleteResRentLossItems.length > 1}
                  collectionName="asCompleteResRentLossItems"
                  fillDownLossPeriod={fillDownLossPeriod('asCompleteResRentLossItems')}
                  index={index}
                  isEditable={canEditControl()}
                  key={`asCompleteResRentLossItems[${index}]`}
                  lossItem={rentLossItem}
                  name={`asCompleteResRentLossItems[${index}]`}
                  onEditItem={onEditRentLoss}
                  onRemoveItem={onRemoveRentLoss}
                  rentLossType={RENT_LOSS_TYPE.RESIDENTIAL}
                />
              )
            })}

            {asCompleteCommercialRentLossItems.map((rentLossItem, index) => {
              rentLossItem.name = getRentLossRowTitle(rentLossItem, commercialUnits, 'Commercial Rent Loss')
              return (
                <RentLossTableRow
                  canDelete={asCompleteCommercialRentLossItems.length > 1}
                  collectionName="asCompleteCommercialRentLossItems"
                  fillDownLossPeriod={fillDownLossPeriod('asCompleteCommercialRentLossItems')}
                  index={index}
                  isEditable={canEditControl()}
                  key={`asCompleteCommercialRentLossItems[${index}]`}
                  lossItem={rentLossItem}
                  name={`asCompleteCommercialRentLossItems[${index}]`}
                  onEditItem={onEditRentLoss}
                  onRemoveItem={onRemoveRentLoss}
                  rentLossType={RENT_LOSS_TYPE.COMMERCIAL}
                />
              )
            })}

            {asCompleteLossItems.map((lossItem, index) => {
              const hasMonths = !isNil(lossItem.type) && lossItem.type === LOSS_ITEM_VALUE_TYPE.COMPUTED_VALUE
              const firstItemMonth = get(values, 'asCompleteLossItems[0].months')
              return (
                <LossItemTableRow
                  epTotal={asCompleteEpTotal}
                  fillDownLossPeriod={fillDownLossPeriod('asCompleteLossItems')}
                  firstItemMonth={firstItemMonth}
                  hasMonths={hasMonths}
                  index={index}
                  isEditable={canEditControl()}
                  key={`asCompleteLossItems[${index}]`}
                  lossItem={lossItem}
                  name={`asCompleteLossItems[${index}]`}
                />
              )
            })}
          </>
        )}
      </TableBody>
      <TableFooter
        sx={{ '& .MuiTableCell-root': { bgcolor: 'grey.200', color: 'text.primary', fontSize: 14, fontWeight: 600 } }}
      >
        <TableRow data-qa="as-is-market-value-row">
          <TableCell>As Is Market Value:</TableCell>
          <TableCell data-qa="as-is-market-period-cell">{renderDateOrString(dateOfFinalValueAsIs)}</TableCell>
          <TableCell align="right" data-qa="as-is-market-amount-cell">
            {finalValueAsIs}
          </TableCell>
          <TableCell align="right" data-qa="as-is-market-final-value-cell">
            {finalValueAsIsRounded}
          </TableCell>
          <TableCell />
        </TableRow>
        {dataSourceType === ADJUSTMENT_AND_LOSSES_DATA_SOURCE.INCOME_APPROACH && (
          <>
            <TableRow>
              <TableCell>As Is Market Value Per Unit</TableCell>
              <TableCell />
              <TableCell />
              <TableCell align="right">{finalValueAsIsPerUnit}</TableCell>
              <TableCell />
            </TableRow>
            <TableRow>
              <TableCell>As Is Market Value Per SF</TableCell>
              <TableCell />
              <TableCell />
              <TableCell align="right">{finalValueAsIsPerSf}</TableCell>
              <TableCell />
            </TableRow>
          </>
        )}
      </TableFooter>
    </Table>
  )
}

export default AdjustmentsAndLossesTableView
