import { get } from 'lodash'

import { toValidPercents } from 'shared/utils/numberOperations'

type CapRate = {
  value: number
  min: number
  max: number
}

export type FormValues = {
  personalSurveys: {
    capRate: CapRate
    companyName: string
    contactName: string
    conversationDate: string
    isCapRateRange?: boolean
    propertyType: string
  }
}

const mapData = (
  capRate: CapRate,
  companyName?: string,
  contactName?: string,
  conversationDate?: string,
  isCapRateRange?: boolean,
  propertyType?: string
) => {
  const capRateText = isCapRateRange
    ? `range from ${toValidPercents(capRate.min)} to ${toValidPercents(capRate.max)}.`
    : `are roughly ${toValidPercents(capRate.value)}.`

  return { capRateText, companyName, contactName, conversationDate, propertyType }
}

export const mapDTO = (formValues: FormValues) => {
  const {
    capRate,
    companyName,
    contactName,
    conversationDate,
    isCapRateRange = false,
    propertyType,
  } = formValues.personalSurveys

  return mapData(capRate, companyName, contactName, conversationDate, isCapRateRange, propertyType)
}

type ReportType = {
  marketParticipantSurvey: {
    capRate: {
      min?: string
      max?: string
      value?: string
    }
    conversationDate?: string
    companyName?: string
    contactName?: string
    isCapRateRange?: boolean
    // eslint-disable-next-line camelcase
    property_information: {
      type?: string
    }
  }
}

export const mapDataModel = (report: ReportType) => {
  const capRateMin = get(report, 'marketParticipantSurvey.capRate.min', '0')
  const capRateMax = get(report, 'marketParticipantSurvey.capRate.max', '0')

  const conversationDate = get(report, 'marketParticipantSurvey.conversationDate')
  const companyName = get(report, 'marketParticipantSurvey.companyName')
  const contactName = get(report, 'marketParticipantSurvey.contactName')
  const isCapRateRange = get(report, 'marketParticipantSurvey.isCapRateRange')
  const capRate = {
    value: parseFloat(get(report, 'marketParticipantSurvey.capRate.value', '0')),
    min: parseFloat(capRateMin),
    max: parseFloat(capRateMax),
  }
  const propertyType = get(report, 'property_information.type')

  return mapData(capRate, companyName, contactName, conversationDate, isCapRateRange, propertyType)
}
