import { GeneratedText } from '../../../narrative/GeneratedText'

import { mapDTO, mapDataModel } from './mappings'

export const SUMMARY_TEXT =
  'We have categorized and summarized the budget provided to us and thus the subject’s budgeted direct costs may be adjusted to include standard site preparation costs, utilities from the structure to the lot line, architect and engineering fees, contractor’s overhead and incentive, and financing costs and fees, to align with what is similarly included in the MVS cost estimate (discussion to follow). The concluded MVS basic structure cost estimate will be compared to the subject’s adjusted direct cost estimate.  Demolition costs are separately broken out.'

const generate = () => {
  const gt = new GeneratedText()

  gt.t`${SUMMARY_TEXT}`

  return gt.getParts()
}

export default { generate, mapDTO, mapDataModel }
