import React from 'react'
import PropTypes from 'prop-types'

import Accordion from '@mui/material/Accordion'
import AccordionDetails from '@mui/material/AccordionDetails'
import AccordionPanelSummary from '@mui/material/AccordionSummary'
import Stack from '@mui/material/Stack'

import ExpandMore from '@mui/icons-material/ExpandMore'

import ExportItemDetails from './ExportItemDetails'

class ExpansionExportItem extends React.PureComponent {
  state = {
    isExpanded: false,
  }

  getExpansionPanelSummary = (columns, exportItem, getCellContent, showDownloadButton) => {
    return (
      <Stack alignItems="center" direction="row" width={1}>
        {columns.map((column, index) => {
          const { name, width } = column
          return (
            <Stack key={name} boxSizing="border-box" sx={{ px: 2, width }}>
              {getCellContent({ column, exportItem, index, showDownloadButton })}
            </Stack>
          )
        })}
      </Stack>
    )
  }

  render() {
    const { columns, exportItem, showDownloadButton, getCellContent } = this.props

    return (
      <Accordion data-qa="expansion-panel" sx={{ padding: 0, width: 1 }}>
        <AccordionPanelSummary expandIcon={<ExpandMore />}>
          {this.getExpansionPanelSummary(columns, exportItem, getCellContent, showDownloadButton)}
        </AccordionPanelSummary>
        <AccordionDetails>
          <ExportItemDetails errorsMessages={exportItem.errorMessages} warningsMessages={exportItem.warnings} />
        </AccordionDetails>
      </Accordion>
    )
  }
}

ExpansionExportItem.propTypes = {
  columns: PropTypes.arrayOf(PropTypes.object.isRequired),
  exportItem: PropTypes.object.isRequired,
  getCellContent: PropTypes.func.isRequired,
  index: PropTypes.number.isRequired,
  showDownloadButton: PropTypes.bool.isRequired,
}

ExpansionExportItem.displayName = 'ExpansionExportItem'

export default ExpansionExportItem
