export default class SentenceBuilder {
  constructor(arr, addPeriod = true) {
    this.addPeriod = addPeriod
    Object.assign(this, { arr })
  }

  push(element) {
    this.arr.push(element)
  }

  compile() {
    const period = this.addPeriod ? '.' : ''
    return `${this.arr.join(' ')}${period}`
  }

  static createFrom(arr, addPeriod = true) {
    const sb = new SentenceBuilder(Array.from(arr), addPeriod)

    return sb.compile()
  }
}
