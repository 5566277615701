import createDecorator from 'final-form-calculate'

export const approachesDecorators = [
  createDecorator({
    field: ['settings.approaches.cost'],
    updates: (value, name, allValues, prevValues) => {
      const prevApproachValues = prevValues?.settings?.approaches
      if (value && !prevApproachValues?.land) {
        return {
          'settings.approaches.land': true,
        }
      }
      return {}
    },
  }),
  createDecorator({
    field: ['settings.approaches.land'],
    updates: (value, name, allValues, prevValues) => {
      const prevApproachValues = prevValues?.settings?.approaches
      if (!value && prevApproachValues?.cost) {
        return {
          'settings.approaches.cost': false,
        }
      }
      return {}
    },
  }),
]
