import { put, takeLatest, call, select } from 'redux-saga/effects'
import { contentReuseTemplate } from 'client-shared/types'

import { saveReport } from 'report/redux/actions/report'

import { get } from 'lodash'

import * as Api from '../../../core/api'

import {
  setContentLibrary,
  setContentLibraryRequest,
  setContentLibraryReceive,
  setContentLibraryFail,
  createNarrativeTemplate as createNarrativeTemplateAction,
  createNarrativeTemplateRequest,
  createNarrativeTemplateReceive,
  createNarrativeTemplateFail,
  updateNarrativeTemplate as updateNarrativeTemplateAction,
  updateNarrativeTemplateRequest,
  updateNarrativeTemplateReceive,
  updateNarrativeTemplateFail,
  reloadContentLibrary,
} from '../actions/contentLibraryData'

import {
  CREATE_NARRATIVE_TEMPLATE,
  UPDATE_NARRATIVE_TEMPLATE,
  SET_CONTENT_LIBRARY_DATA,
} from '../actionTypes/contentLibraryData'

export function* setContentLibraryData(action: ReturnType<typeof setContentLibrary>): Generator {
  try {
    yield put(setContentLibraryRequest())
    const contentLibrary = yield call(Api.getNarrativeTexts, action.payload.name)
    yield put(setContentLibraryReceive(contentLibrary as contentReuseTemplate[]))
  } catch (err) {
    yield put(setContentLibraryFail(err))
  }
}

export function* createNarrativeTemplate(action: ReturnType<typeof createNarrativeTemplateAction>): Generator {
  try {
    yield put(createNarrativeTemplateRequest())
    const createdNarrative = yield call(Api.createNarrative, action.payload)
    const mappedNarrative = mapToReuseTemplate(action.payload, createdNarrative)
    const formPath = yield select(state => get(state, 'shared.location.form.formPath'))
    yield put(createNarrativeTemplateReceive(mappedNarrative))
    yield put(saveReport(formPath))
  } catch (err) {
    yield put(createNarrativeTemplateFail(err))
  }
}

export function* updateNarrativeTemplate(action: ReturnType<typeof updateNarrativeTemplateAction>): Generator {
  try {
    yield put(updateNarrativeTemplateRequest())
    const updatedNarrative = yield call(Api.updateNarrative, action.payload)
    const mappedNarrative = mapToReuseTemplate(action.payload, updatedNarrative)
    const formPath = yield select(state => get(state, 'shared.location.form.formPath'))
    yield put(updateNarrativeTemplateReceive(mappedNarrative))
    yield put(saveReport(formPath))
  } catch (err) {
    yield put(updateNarrativeTemplateFail(err))
  }
}

export function* reloadAndActivateTemplate(action: ReturnType<typeof reloadContentLibrary>): Generator {
  yield put(reloadContentLibrary(action.payload))
}

const mapToReuseTemplate = (
  localTemplate: Partial<contentReuseTemplate>,
  remoteTemplate: any
): contentReuseTemplate => {
  return {
    ...localTemplate,
    ...remoteTemplate,
  }
}

export default [
  takeLatest(SET_CONTENT_LIBRARY_DATA.ACTION, setContentLibraryData),
  takeLatest(CREATE_NARRATIVE_TEMPLATE.ACTION, createNarrativeTemplate),
  takeLatest(UPDATE_NARRATIVE_TEMPLATE.ACTION, updateNarrativeTemplate),
  takeLatest(CREATE_NARRATIVE_TEMPLATE.RECEIVE, reloadAndActivateTemplate),
  takeLatest(UPDATE_NARRATIVE_TEMPLATE.RECEIVE, reloadAndActivateTemplate),
]
