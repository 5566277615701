import { createAction } from 'redux-actions'
import { defineAction } from 'redux-define'

import { DEFAULT_API_ACTION_STATES } from '../../../../../../shared/constants'

//Action Types
const EXPENSE_COMPS_FETCH = defineAction('EXPENSE_COMPS_FETCH', DEFAULT_API_ACTION_STATES)
const EXPENSE_COMPS_CLEAR = defineAction('EXPENSE_COMPS_CLEAR')
const SELECT_EXPENSE_COMP = defineAction('SELECT_EXPENSE_COMP')
const DESELECT_EXPENSE_COMP = defineAction('DESELECT_EXPENSE_COMP')
const SET_SELECTED_EXPENSE_COMPS = defineAction('SET_SELECTED_EXPENSE_COMPS')

//Actions
export const expenseCompsFetch = createAction(EXPENSE_COMPS_FETCH.ACTION)
export const expenseCompsFetchRequest = createAction(EXPENSE_COMPS_FETCH.REQUEST)
export const expenseCompsFetchReceive = createAction(EXPENSE_COMPS_FETCH.RECEIVE)
export const expenseCompsFetchFail = createAction(EXPENSE_COMPS_FETCH.FAIL)

export const selectExpenseComp = createAction(SELECT_EXPENSE_COMP.ACTION)
export const deselectExpenseComp = createAction(DESELECT_EXPENSE_COMP.ACTION)
export const setSelectedExpenseComps = createAction(SET_SELECTED_EXPENSE_COMPS.ACTION)

export const expenseCompsClear = createAction(EXPENSE_COMPS_CLEAR.ACTION)
