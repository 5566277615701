import React from 'react'
import PropTypes from 'prop-types'

import { Typography, withStyles } from '@material-ui/core'

const styles = theme => ({
  root: {
    textDecoration: 'none',
    cursor: 'pointer',
    userSelect: 'none',
    '&:hover': {
      textDecoration: 'underline',
    },
  },
})

const Link = ({ classes, children, ...restProps }) => (
  <Typography color="primary" classes={classes} component="a" {...restProps}>
    {children}
  </Typography>
)

const StyledLink = withStyles(styles)(Link)

StyledLink.displayName = 'Link'

StyledLink.propTypes = {
  children: PropTypes.node.isRequired,
}

export default StyledLink
