import { get } from 'lodash'

import { GeneratedText, gtLocked } from '../../../../narrative/GeneratedText'

const generate = () => {
  const gt = new GeneratedText()

  gt.t`The PwC Real Estate and Real Estate Research Corporation's investment surveys summarize the expectations of`
  gt.t` institutional investors. As indicated, the going-in capitalization rates range from ${gtLocked(
    'totalMin',
    'formatPercentageString'
  )} to ${gtLocked('totalMax', 'formatPercentageString')},`
  gt.t` with an average between ${gtLocked('avgMin', 'formatPercentageString')} and ${gtLocked(
    'avgMax',
    'formatPercentageString'
  )}.`

  return gt.getParts()
}

const getNationalSurveyResponsesValues = nationalSurveyResponses => {
  const pwc = get(nationalSurveyResponses, 'pwc', {})
  const rerc = get(nationalSurveyResponses, 'rerc', {})
  let totalMin
  let totalMax
  let avgMin
  let avgMax
  if (pwc.min < rerc.min) {
    totalMin = pwc.min
  } else {
    totalMin = rerc.min
  }
  if (pwc.max > rerc.max) {
    totalMax = pwc.max
  } else {
    totalMax = rerc.max
  }
  if (pwc.avg < rerc.avg) {
    avgMin = pwc.avg
    avgMax = rerc.avg
  } else {
    avgMin = rerc.avg
    avgMax = pwc.avg
  }
  return {
    totalMin,
    totalMax,
    avgMin,
    avgMax,
  }
}

const mapDataFromFormValues = values => {
  const nationalSurveyResponses = get(values, 'nationalSurveyResponses', {})
  return getNationalSurveyResponsesValues(nationalSurveyResponses)
}

const mapDataFromModel = report => {
  const nationalSurveyResponses = get(report, 'cap_rate_recon.nationalSurveyResponses', {})
  return getNationalSurveyResponsesValues(nationalSurveyResponses)
}

export default { generate, mapDataFromFormValues, mapDataFromModel }
