import { get } from 'lodash'

import { formatCurrencyFloat } from '../../../../client/src/shared/utils/numberFormatters'
import { BASIS_TYPES } from '../../../constants/report/incomeApproach/taxes'

const getBasis = basis =>
  ({
    [BASIS_TYPES.PSF]: 'per square foot',
    [BASIS_TYPES.PER_UNIT]: 'per unit',
  }[basis || BASIS_TYPES.PSF])

const getProjectedTaxLiability = (projected, basis, unitCount) => {
  const concludedLiabilityType = get(projected, 'concludedLiabilityType')
  if (concludedLiabilityType === BASIS_TYPES.PER_UNIT && basis === BASIS_TYPES.PER_UNIT) {
    return get(projected, 'concludedLiabilityPerBasis', 0) * unitCount
  }
  return get(projected, 'taxLiability')
}

export const generateTaxSummaryDiscussion = taxInfo => {
  const { projected, taxLiability, basis, residentialUnitCount } = taxInfo
  const isProjection = get(projected, 'includedInExport')

  let generatedText = `The ${isProjection ? 'projection for the ' : ''}subject property’s taxes ${getBasis(
    basis
  )} fall${isProjection ? 's' : ''} within the market range and ${isProjection ? 'is' : 'are'} considered reasonable.`

  generatedText += ` Thus, we apply the ${
    isProjection ? 'projected' : 'current'
  } tax liability of ${formatCurrencyFloat(
    isProjection ? getProjectedTaxLiability(projected, basis, residentialUnitCount) : taxLiability
  )} in our analysis.`

  return generatedText
}
