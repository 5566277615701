import React from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'

import red from '@material-ui/core/colors/red'
import { Typography, DialogTitle, DialogContent, DialogContentText, DialogActions } from '@material-ui/core'

import { Button } from '../../../../../shared/components'

const styles = theme => ({
  dialogContent: { padding: 14 },
  dialogText: {
    display: 'flex',
    fontWeight: theme.typography.fontWeightMedium,
    flexDirection: 'column',
    width: 'auto',
  },
  dialogTitle: {
    padding: 10,
    paddingBottom: 0,
  },
  content: {
    padding: 10,
  },
  confirmButton: {
    backgroundColor: red[600],
    padding: '6px 16px',
    fontSize: 14,
    '&:hover': {
      backgroundColor: red[800],
    },
  },
  cancelButton: {
    fontSize: 14,
    padding: '6px 16px',
    color: theme.palette.text.secondary,
    fontWeight: theme.typography.fontWeightMedium,
    '&:hover': {
      color: theme.palette.primary.main,
    },
  },
  clearTitle: {
    color: theme.palette.text.primary,
  },
  actionsContainer: {
    justifyContent: 'flex-end',
  },
  list: {
    listStyleType: 'none',
    marginBottom: 0,
    paddingLeft: 24,
    '& li::before': {
      content: '"- "',
    },
  },
})

const ConfirmationStep = ({ classes, onSubmit, onCancel, affectedPages }) => (
  <div className={classes.dialogContent} data-qa="dialog-content">
    <DialogTitle className={classes.dialogTitle}>
      <Typography classes={{ root: classes.clearTitle }} component="span" variant="h6">
        Permanently Change Report Settings
      </Typography>
    </DialogTitle>
    <DialogContent className={classes.content}>
      <DialogContentText component="div" className={classes.dialogText}>
        <span>You will delete information from the following pages:</span>
        <ul className={classes.list}>
          {affectedPages.map((page, index) => (
            <li key={index}>{page}</li>
          ))}
        </ul>
      </DialogContentText>
    </DialogContent>
    <DialogActions classes={{ root: classes.actionsContainer }}>
      <Button onClick={onCancel} className={classes.cancelButton} data-qa="reject-btn">
        CANCEL
      </Button>
      <Button wide onClick={onSubmit} variant="contained" data-qa="submit-btn" className={classes.confirmButton}>
        CHANGE SETTINGS
      </Button>
    </DialogActions>
  </div>
)

ConfirmationStep.propTypes = {
  onCancel: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  affectedPages: PropTypes.arrayOf(PropTypes.string).isRequired,
}

export default withStyles(styles)(ConfirmationStep)
