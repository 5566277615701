import { GeneratedText, gtIfElse, gtLocked, gtTemplate } from '../../../../narrative/GeneratedText'

import { mapDTO, mapDataModel } from './mappings'

const generate = () => {
  const gt = new GeneratedText()

  const lastSentence = gtIfElse(
    'moreThanOneItem',
    gtTemplate`These forecasts have been applied to our analysis.`,
    gtTemplate`This forecast has been applied to our analysis.`
  )

  const itemsParagraph = gtLocked('itemsParagraph', 'ITEMS')
  const discussion = gtIfElse('includeDiscussion', gtTemplate`${itemsParagraph}${lastSentence}`, gtTemplate``)

  gt.t`${discussion}`

  return gt.getParts()
}

export default { generate, mapDTO, mapDataModel }
