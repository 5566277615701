import React from 'react'
import PropTypes from 'prop-types'

import { Button, Dialog, DialogContent, DialogTitle, Grid, InputLabel, withStyles } from '@material-ui/core'

import { Form } from 'react-final-form'

import { camelCase, noop, isNil, find } from 'lodash'

import { FORM_ERROR } from 'final-form'

import createDecorator from 'final-form-calculate'

import DropDown from '../../../../../../shared/components/DropDown/DropDown'

import { RadioButtonList, CheckboxWithLabel } from '../../../../../../shared/components'
import {
  BASE_YEAR_VALUE_TYPE_OPTIONS,
  DOLLAR_AMOUNT_TYPE_OPTIONS,
  OTHER_REIMBURSEMENT_TYPE_OPTIONS,
  REIMBURSEMENT_TYPES,
  TAX_REIMBURSEMENT_TYPE_OPTIONS,
} from '../constants'
import { REAL_ESTATE_TAXES } from '../../../../../../../../shared/constants/expenses'

const styles = theme => ({
  subSectionWrapper: {
    paddingBottom: 0,
    marginBottom: 16,
    width: 700,
  },
  gridContainer: {
    width: '100%',
  },
  saveDialog: {
    marginRight: 0,
    marginTop: 0,
    paddingRight: 24,
    paddingBottom: 16,
  },
  button: {
    textTransform: 'uppercase',
  },
  cancelButton: {
    marginRight: 16,
    textTransform: 'uppercase',
  },
  label: {
    color: `${theme.palette.secondary[600]}!important`,
  },
})

const decorators = [
  createDecorator({
    field: ['expenseType', 'type'],
    updates: (value, name, allValues, prevValues) => {
      const isInitialRender = Object.keys(prevValues).length === 0
      if (isInitialRender) {
        return { type: allValues.type, knownInformation: allValues.knownInformation }
      }
      return {
        type: allValues.expenseType === prevValues.expenseType ? allValues.type : null,
        knownInformation: {},
      }
    },
  }),
]

class ReimbursementEditModal extends React.PureComponent {
  static propTypes = {
    classes: PropTypes.object.isRequired,
    reimbursement: PropTypes.object,
    units: PropTypes.arrayOf(PropTypes.object).isRequired,
    expenseCategories: PropTypes.arrayOf(PropTypes.object).isRequired,
    onCancel: PropTypes.func,
    onSave: PropTypes.func.isRequired,
    isCreateModal: PropTypes.bool,
  }

  static defaultProps = {
    isCreateModal: false,
    reimbursement: {
      expenseType: null,
      type: null,
      knownInformation: {},
    },
    onCancel: noop,
  }

  onSubmit = (reimbursement, form, complete) => {
    const { onSave, isCreateModal, units, expenseCategories } = this.props

    const expenseTypeLabel = find(expenseCategories, { id: reimbursement.expenseType })?.name
    const reimbursementTypeLabel = REIMBURSEMENT_TYPES[reimbursement.type].label

    const name = `${expenseTypeLabel} (${reimbursementTypeLabel})`
    const key = `${camelCase(reimbursement.expenseType)}_${reimbursement.type}`

    if (isCreateModal) {
      return onSave({
        ...reimbursement,
        name,
        key,
        unitReimbursements: units.map(unit => ({
          unitId: unit._id,
          unit,
          annual: null,
          enabled: true,
        })),
      })
    }

    if (reimbursement.key === key) {
      onSave(reimbursement)
    } else {
      onSave({
        ...reimbursement,
        grossVcLoss: 0,
        effectiveAnnual: 0,
        totalAnnual: 0,
        name,
        key,
        unitReimbursements: reimbursement.unitReimbursements.map(unitReimbursement => ({
          ...(reimbursement?.key?.includes(reimbursement.type) ? unitReimbursement : {}),
          unitId: unitReimbursement.unitId,
          unit: unitReimbursement.unit,
          annual: null,
          enabled: unitReimbursement.enabled,
        })),
      })
    }
  }

  validate = ({ expenseType, type, knownInformation }) => {
    const errors = {}
    if (!expenseType || !type) {
      errors[FORM_ERROR] = 'Please specify the expense and reimbursement types'
    }

    if (type === REIMBURSEMENT_TYPES.dollarAmount.value && isNil(knownInformation.dollarAmountType)) {
      errors[FORM_ERROR] = 'Please specify dollar amount type'
    }
    if (type === REIMBURSEMENT_TYPES.increaseOverBaseYear.value && isNil(knownInformation.baseYearValueType)) {
      errors[FORM_ERROR] = 'Please specify base year value type'
    }

    return errors
  }

  renderKnownInformationSection(formValues) {
    const { classes } = this.props
    const { type, knownInformation } = formValues
    const { dollarAmountType, baseYearValueType, adminFee } = knownInformation

    switch (type) {
      case REIMBURSEMENT_TYPES.dollarAmount.value:
        return (
          <Grid container>
            <Grid item xs={12}>
              <InputLabel className={classes.label} shrink>
                Known Information
              </InputLabel>
            </Grid>
            <Grid item xs={12}>
              <RadioButtonList
                name="knownInformation.dollarAmountType"
                value={dollarAmountType}
                items={DOLLAR_AMOUNT_TYPE_OPTIONS}
                horizontal
                type="radio"
              />
            </Grid>
          </Grid>
        )

      case REIMBURSEMENT_TYPES.increaseOverBaseYear.value:
        return (
          <Grid container>
            <Grid item xs={12}>
              <InputLabel className={classes.label} shrink>
                Known Information
              </InputLabel>
            </Grid>
            <Grid item xs={12}>
              <RadioButtonList
                name="knownInformation.baseYearValueType"
                value={baseYearValueType}
                items={BASE_YEAR_VALUE_TYPE_OPTIONS}
                horizontal
                type="radio"
              />
            </Grid>
          </Grid>
        )

      case REIMBURSEMENT_TYPES.percentOfCurrentYearLiability.value:
      case REIMBURSEMENT_TYPES.percentOfAppraiserForecast.value:
        return (
          <Grid container>
            <Grid item xs={12}>
              <InputLabel className={classes.label} shrink>
                Known Information
              </InputLabel>
            </Grid>
            <Grid item xs={12}>
              <CheckboxWithLabel name="knownInformation.adminFee" label="Admin Fee" defaultValue={adminFee} />
            </Grid>
          </Grid>
        )

      default:
        return null
    }
  }

  render() {
    const { classes, onCancel, reimbursement, isCreateModal, expenseCategories } = this.props
    const expenseTypeOptions = expenseCategories.map(expenseCategory => ({
      value: expenseCategory.id,
      label: expenseCategory.name,
    }))

    return (
      <Dialog
        open
        disableBackdropClick
        maxWidth="md"
        onClose={onCancel}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        data-qa="reimbursement-edit-modal"
      >
        <DialogTitle id="alert-dialog-title">{isCreateModal ? 'Add' : 'Edit'} Reimbursement</DialogTitle>
        <div data-qa="reimbursement-edit-modal-content">
          <Form
            onSubmit={this.onSubmit}
            validate={this.validate}
            initialValues={{
              ...reimbursement,
            }}
            decorators={decorators}
            render={({ handleSubmit, form, submitting, pristine, values, invalid }) => (
              <form onSubmit={handleSubmit}>
                <DialogContent className={classes.subSectionWrapper}>
                  <Grid container justify="flex-start" alignItems="center">
                    <Grid item xs={12} style={{ marginRight: 16 }}>
                      <DropDown name="expenseType" label="Expense Type" items={expenseTypeOptions} />
                    </Grid>
                  </Grid>
                  {values.expenseType && (
                    <Grid container>
                      <Grid item xs={12}>
                        <RadioButtonList
                          name="type"
                          value={values.type}
                          label="Reimbursement Type"
                          items={
                            values.expenseType === REAL_ESTATE_TAXES.key
                              ? TAX_REIMBURSEMENT_TYPE_OPTIONS
                              : OTHER_REIMBURSEMENT_TYPE_OPTIONS
                          }
                          horizontal
                          type="radio"
                        />
                      </Grid>
                    </Grid>
                  )}
                  {this.renderKnownInformationSection(values)}
                </DialogContent>
                <div className={classes.saveDialog}>
                  <Grid container justify="flex-end" alignItems="center">
                    <Button color="primary" onClick={onCancel} data-qa="cancel-btn" className={classes.cancelButton}>
                      Cancel
                    </Button>
                    <Button
                      variant="contained"
                      className={classes.button}
                      type="submit"
                      color="primary"
                      autoFocus
                      data-qa="save-btn"
                      disabled={invalid}
                    >
                      {isCreateModal ? 'Add' : 'Save'}
                    </Button>
                  </Grid>
                </div>
              </form>
            )}
          />
        </div>
      </Dialog>
    )
  }
}

export default withStyles(styles)(ReimbursementEditModal)
