import React from 'react'
import PropTypes from 'prop-types'
import { get } from 'lodash'
import { DialogContent, Grid, withStyles } from '@material-ui/core'

import { DropDown } from '../../../DropDown'
import { GEOGRAPHY_OPTIONS_LABEL_VALUE_PAIR, GEOGRAPHY_OPTIONS, WIZARD_STEPS } from '../../../../constants/properties'

import AdvancedSearchNYC from './AdvancedSearchNYC'
import AdvancedSearchNJ from './AdvancedSearchNJ'

import AdvancedSearchOther from './AdvancedSearchOther'
import AdvancedSearchDefault from './AdvancedSearchDefault'

class AdvancedSearch extends React.PureComponent {
  static propTypes = {
    values: PropTypes.object.isRequired,
    openNextStep: PropTypes.func.isRequired,
    onSubmit: PropTypes.func.isRequired,
    onSearchComplete: PropTypes.func.isRequired,
    invalid: PropTypes.bool.isRequired,
    Geography: PropTypes.elementType,
  }

  static Geography = ({ locationIdentifier, ...props }) => {
    switch (locationIdentifier) {
      case GEOGRAPHY_OPTIONS.NY:
        return <AdvancedSearchNYC {...props} />
      case GEOGRAPHY_OPTIONS.NJ:
        return <AdvancedSearchNJ {...props} />
      case GEOGRAPHY_OPTIONS.OTHER:
        return <AdvancedSearchOther {...props} />
      default:
        return <AdvancedSearchDefault {...props} />
    }
  }

  openBasicSearch = () => {
    const { openNextStep } = this.props
    openNextStep(WIZARD_STEPS.BASIC_SEARCH)
  }

  render() {
    const { classes, onSearchComplete, values, onSubmit, invalid, Geography } = this.props

    return (
      <DialogContent className={classes.root} data-qa="propertySearch-advanced-form">
        <Grid container spacing={8} alignItems="center">
          <Grid item xs={12}>
            <DropDown
              label="State"
              name="search.locationIdentifier"
              items={GEOGRAPHY_OPTIONS_LABEL_VALUE_PAIR}
              required
              className={classes.dropDown}
            />
            <Geography
              locationIdentifier={get(values, 'search.locationIdentifier')}
              openBasicSearch={this.openBasicSearch}
              values={values}
              invalid={invalid}
              onSearchComplete={onSearchComplete}
              onSubmit={onSubmit}
            />
          </Grid>
        </Grid>
      </DialogContent>
    )
  }
}

AdvancedSearch.defaultProps = {
  Geography: AdvancedSearch.Geography,
}

export default withStyles({
  root: {
    maxWidth: 560,
    minWidth: 300,
  },
  dropDown: {
    marginTop: 8,
  },
})(AdvancedSearch)
