import React from 'react'
import { Field } from 'react-final-form'

import { ToggleButton } from '../../_mui5'

const ToggleButtonField = ({ name, children, ...restProps }) => {
  return (
    <Field
      name={name}
      {...restProps}
      render={({ input, ...restInnerProps }) => {
        return (
          <ToggleButton value={input.value} onChange={input.onChange} {...restInnerProps}>
            {children}
          </ToggleButton>
        )
      }}
    />
  )
}

export default ToggleButtonField
