export const Labels = {
  ANTENNA_INCOME: 'Antenna Income',
  COMMERCIAL_AREA: 'Commercial Area',
  COMMERCIAL_VC_LOSS: 'Commercial Vacancy and Collection Loss',
  EFFECTIVE_GROSS_INCOME: 'Effective Gross Income',
  INCOME_TYPE: 'Income Type',
  LAUNDRY_INCOME: 'Laundry Income',
  LESS: 'Less',
  LESS_ANTENNA_VC_LOSS: 'Less Antenna V/C Loss',
  LESS_COMMUNITY_VC_LOSS: 'Less Community Facility V/C Loss',
  LESS_INDUSTRIAL_VC_LOSS: 'Less Industrial Commercial V/C Loss',
  LESS_LAUNDRY_VC_LOSS: 'Less Laundry V/C Loss',
  LESS_MEDICAL_VC_LOSS: 'Less Medical Office V/C Loss',
  LESS_OFFICE_VC_LOSS: 'Less Office V/C Loss',
  LESS_OTHER_VC_LOSS: 'Less Other V/C Loss',
  LESS_PARKING_VC_LOSS: 'Less Parking V/C Loss',
  LESS_RESIDENTIAL_VC_LOSS: 'Less Residential V/C Loss',
  LESS_RETAIL_VC_LOSS: 'Less Retail V/C Loss',
  LESS_STORAGE_VC_LOSS: 'Less Storage V/C Loss',
  LESS_UNDETERMINED_VC_LOSS: 'Less Undetermined Commercial V/C Loss',
  OTHER_INCOME: 'Other Income',
  PARKING_INCOME: 'Parking Income',
  POTENTIAL_COMMUNITY_INCOME: 'Potential Community Facility Income',
  POTENTIAL_GROSS_INCOME: 'Potential Gross Income',
  POTENTIAL_INDUSTRIAL_INCOME: 'Potential Industrial Commercial Income',
  POTENTIAL_MEDICAL_INCOME: 'Potential Medical Office Income',
  POTENTIAL_OFFICE_INCOME: 'Potential Office Income',
  POTENTIAL_RESIDENTIAL_INCOME: 'Potential Residential Income',
  POTENTIAL_RETAIL_INCOME: 'Potential Retail Income',
  POTENTIAL_UNDETERMINED_INCOME: 'Potential Undetermined Commercial Income',
  REAL_ESTATE_TAX_REIMBURSEMENT: 'Real Estate Tax Reimbursement',
  RESIDENTIAL_VC_LOSS: 'Residential Vacancy and Collection Loss',
  STORAGE_INCOME: 'Storage Income',
  SUBJECT_AREA_COMMERCIAL_VACANCY: 'Current Commercial Vacancy in Subject Area',
  SUBJECT_LOCATION_SUITABILITY: "Suitability of subject's specific location for retail space",
  VC_LOSS: 'V/C Loss',
}

export const SUITABILITY_OPTIONS = ['Excellent', 'Well Suited', 'Average', 'Below Average']
