import { get, last } from 'lodash'

import { formatFormName } from '../../../../shared/utils/analytics'

import { mapReportContext, mapNewReportContext, getFormFromState } from './utilities'

export const registerOpenReportEvent = (action, prevState, nextState) => {
  try {
    return {
      name: 'openReport',
      label: 'Open Report',
      timestamp: new Date(),
      path: get(prevState, 'router.location.pathname'),
      userId: get(nextState, 'authentication.user._id'),
      reportId: get(action, 'payload.reportData._id'),
      properties: mapReportContext(get(action, 'payload.reportData'), get(action, 'payload.reportSettings')),
    }
  } catch (err) {
    console.error(err)
  }
}

export const registerCreateReportEvent = (action, prevState, nextState) => {
  try {
    return {
      name: 'createReport',
      label: 'Create Report',
      timestamp: new Date(),
      path: get(prevState, 'router.location.pathname'),
      userId: get(nextState, 'authentication.user._id'),
      reportId: get(action, 'payload._id'),
      properties: {
        ...mapNewReportContext(action.payload),
      },
    }
  } catch (err) {
    console.error(err)
  }
}

export const detectClickBackButtonEvent = (action, prevState, nextState) => {
  try {
    if (action.payload.goBack) {
      return {
        name: 'clickFormBackButton',
        label: 'Click Form Back Button',
        timestamp: new Date(),
        path: prevState.router.location.pathname,
        userId: prevState.authentication.user._id,
        reportId: prevState.report.reportData._id,
        properties: {
          nextForm: formatFormName(last(action.payload.pathname.split('/'))),
          prevForm: getFormFromState(prevState),
          ...mapReportContext(prevState.report.reportData, prevState.report.reportSettings),
        },
      }
    }
  } catch (err) {
    console.error(err)
  }
}

export const registerUploadBuildingCompRentRollCSVEvent = (action, prevState, nextState) => {
  try {
    return {
      name: 'uploadCSV',
      label: 'Upload CSV',
      timestamp: new Date(),
      path: prevState.router.location.pathname,
      userId: prevState.authentication.user._id,
      reportId: prevState.report.reportData._id,
      properties: {
        form: getFormFromState(prevState),
        ...mapReportContext(prevState.report.reportData, prevState.report.reportSettings),
      },
    }
  } catch (err) {
    console.error(err)
  }
}
