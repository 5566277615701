import React from 'react'
import { connect } from 'react-redux'
import { get } from 'lodash'

import { getLocationIdentifier } from 'shared/utils/report/propertyInformation'
import { GEOGRAPHY_OPTIONS } from 'shared/constants/property/geography'

import NewYorkCity from './geographies/NewYorkCity'
import NewJersey from './geographies/NewJersey'

export default connect(state => {
  const subjectState = get(state, 'report.reportData.state')
  const zip = get(state, 'report.reportData.zip')
  const locationIdentifier = getLocationIdentifier(subjectState, zip)
  return {
    locationIdentifier,
  }
})(({ locationIdentifier, ...restProps }) => {
  if (locationIdentifier === GEOGRAPHY_OPTIONS.NY) {
    return <NewYorkCity {...restProps} />
  }
  return <NewJersey {...restProps} />
})
