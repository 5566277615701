import { useCallback } from 'react'

import useStack from 'client-shared/hooks/useStack'

// TODO: Test this with saving the page. I think an extra value is pushed to stack.

const useUndo = (getValues: () => any, setValues: (value: any) => void) => {
  const { stack, push, pop, clear } = useStack()

  const updateUndoStack = useCallback(() => {
    push(getValues())
  }, [getValues, push])

  const makeUndoable = useCallback(
    callback => {
      return (...args: any[]) => {
        updateUndoStack()

        return callback(...args)
      }
    },
    [updateUndoStack]
  )

  const undo = useCallback(() => {
    const value = pop()
    if (value !== undefined) {
      setValues(value)
    }
  }, [pop, setValues])

  return {
    canUndo: stack.length > 0,
    undo,
    makeUndoable,
    reset: clear,
  }
}

export default useUndo
