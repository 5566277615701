import { GEOGRAPHY_OPTIONS } from 'shared/constants/property/geography'

import { STATE_NAMES } from '../../../../../constants/states'

import { gtLocked, gtTemplate, GeneratedText, gtConditional } from '../../../../narrative/GeneratedText'

import { mapDTO, mapDataModel } from './mappings'

const generate = data => {
  const { jurisdictionState, locationIdentifier } = data

  const gt = new GeneratedText()

  const NYSentence = gtTemplate`${gtLocked('address', null, 'Street Address')} is located in ${gtLocked(
    'county',
    null,
    'County'
  )} (borough of ${gtLocked('borough', null, 'Borough')}), ${gtLocked('city', null, 'City')}, ${gtLocked(
    'stateShorthand',
    null,
    'State'
  )}. It is designated on the tax maps as Block ${gtLocked('block', null, 'Block')}, Lot ${gtLocked(
    'lot',
    null,
    'Lot'
  )}. The property tax class is ${gtLocked('taxClass', null, 'Tax Class')}.`

  const NJSentence = gtTemplate`${gtLocked('address', null, 'Street Address')} is located in ${gtLocked(
    'city',
    null,
    'City'
  )}, ${gtLocked('county', null, 'County')}, ${gtLocked(
    'stateShorthand',
    null,
    'State'
  )}. It is designated on the tax maps as Block ${gtLocked('block', null, 'Block')}, Lot ${gtLocked(
    'lot',
    null,
    'Lot'
  )}${gtConditional('qualifier', [...gtTemplate`, Qualifier ${gtLocked('qualifier', null, 'Qualifier')}`])}.`

  const geographySentenceMap = {
    [STATE_NAMES.NY]: NYSentence,
    [STATE_NAMES.NJ]: NJSentence,
  }

  const countyPart = gtConditional('county', [...gtTemplate` ${gtLocked('county', null, 'County')},`])
  const propertyIdentifierTypePart = gtConditional('propertyIdentifierType', [
    ...gtTemplate`${gtLocked('propertyIdentifierType', null, 'Property Identifier Type')} `,
  ])
  const otherSentence = gtTemplate`${gtLocked('address', null, 'Street Address')} is located in ${gtLocked(
    'city',
    null,
    'City'
  )},${countyPart} ${gtLocked(
    'stateShorthand',
    null,
    'State'
  )}. It is designated on the tax maps as ${propertyIdentifierTypePart}${gtLocked(
    'propertyIdentifier',
    null,
    'Property Identifier'
  )}.`

  const geographySentence =
    locationIdentifier === GEOGRAPHY_OPTIONS.OTHER ? otherSentence : geographySentenceMap[jurisdictionState]

  const taxRateSentence = gtTemplate` We have applied the ${gtLocked(
    'taxYear',
    null,
    'Tax Year'
  )} tax rate of ${gtLocked(
    'taxRate',
    null,
    'Tax Rate'
  )} to the most recent assessed value of the property to determine its current tax liability.`

  const assessedValueSentenceIntro = ` The lower of the Actual or the Transitional Assessed Value is applied`

  const transitionalAssessedValueSentence = gtConditional('hasTransitionalAssessedValue', [
    ...gtTemplate`${assessedValueSentenceIntro}, in the subject's case, it is the ${gtLocked('lowerAssessedValue')}.`,
  ])

  gt.addParts([...(geographySentence || []), ...taxRateSentence, transitionalAssessedValueSentence])

  return gt.getParts()
}

export default { generate, mapDTO, mapDataModel }
