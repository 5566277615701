import React from 'react'
import PropTypes from 'prop-types'
import { compose } from 'redux'
import { matchPath } from 'react-router'
import { Link } from 'react-router-dom'
import { ListItem } from '@material-ui/core'
import { withStyles, withTheme } from '@material-ui/core/styles'
import { isNil } from 'lodash'

import NavigationSideBarItemText from './NavigationSideBarItemText'
import { NAV_ITEM_HEIGHT, NAV_ITEM_BORDER_SIZE } from './constants'

const styles = theme => ({
  root: {
    padding: 0,
    paddingLeft: theme.spacing.unit * 2 + NAV_ITEM_BORDER_SIZE,
    paddingRight: theme.spacing.unit,
    minHeight: NAV_ITEM_HEIGHT,
  },
})

class NavigationSideBarItem extends React.PureComponent {
  static propTypes = {
    path: PropTypes.string.isRequired,
    route: PropTypes.string.isRequired,
    currentLocationPath: PropTypes.string,
    color: PropTypes.string,
    title: PropTypes.string.isRequired,
    isNestedItem: PropTypes.bool,
  }

  get itemRoute() {
    const { path, route } = this.props
    return `${route}/${path}`
  }

  get listItemColor() {
    const { currentLocationPath, color, theme, isNestedItem } = this.props
    const match = matchPath(currentLocationPath, {
      path: this.itemRoute,
      exact: true,
      strict: false,
    })

    if (!isNil(match)) {
      return color
    }

    return isNestedItem ? theme.palette.text.secondary : theme.palette.text.primary
  }

  get listItemStyle() {
    const { isNestedItem, color } = this.props
    return {
      backgroundColor: isNestedItem ? '#f2f2f2' : 'inherit',
      borderLeft: !isNestedItem && `5px solid ${color}`,
    }
  }

  render() {
    const { classes, title } = this.props
    const { itemRoute, listItemStyle, listItemColor } = this
    return (
      <Link to={itemRoute}>
        <ListItem button className={classes.root} style={listItemStyle}>
          <NavigationSideBarItemText text={title} color={listItemColor} />
        </ListItem>
      </Link>
    )
  }
}

export default compose(withTheme(), withStyles(styles))(NavigationSideBarItem)
