import { startCase } from 'lodash'
import { toWords } from 'number-to-words'

export const getUnitType = unitGroupName => {
  const bedroomCount = parseInt(unitGroupName)
  return bedroomCount ? `${toWords(bedroomCount)}-bedroom` : 'studio'
}

export const getUnitTypeTitle = unitGroup => {
  const bedroomNumber = parseInt(unitGroup.unitGroupBedroomNumber)
  const type = unitGroup.unitGroupType ?? ''
  const groupName = unitGroup.unitGroupName ?? 'Studio'

  return bedroomNumber ? `${startCase(toWords(bedroomNumber))}-Bedroom ${type}` : groupName
}
