import { cloneDeep, differenceBy, find, findIndex, partition, uniqBy, setWith } from 'lodash'

import { DEFAULT_EXPENSES_KEYS, EXPENSE_CATEGORIES, GROSS_REVENUE } from 'shared/constants/expenses'

import { calculateExpensesTotal } from '../../calculations/expenses'

export class HistoricalExpensesRecords {
  static from({
    records,
    categories,
    calculateRecordTotals = (calculate, expenses = [], record = {}) => {
      const totals = calculate(
        expenses.filter(expense => expense.id !== GROSS_REVENUE.key),
        record.sqft,
        record.res_units
      )
      return {
        total: totals.total || null,
        totalPerSF: totals.totalPerSf || null,
        totalPerUnit: totals.totalPerUnit || null,
      }
    },
    includeRealEstateTaxes = true,
  }) {
    const expenses = new HistoricalExpensesRecords()
    expenses.records = cloneDeep(records)
    expenses.categories = categories.filter(category => {
      if (category.id === DEFAULT_EXPENSES_KEYS.realEstateTaxes && !includeRealEstateTaxes) {
        return false
      }
      return true
    })
    expenses.calculateRecordTotals = calculateRecordTotals
    return expenses
  }

  static isDefaultCategory(categoryId) {
    return !!EXPENSE_CATEGORIES[categoryId] || categoryId === GROSS_REVENUE.key
  }

  addCategory(categoryId, categoryName) {
    const category = {
      id: categoryId,
      name: categoryName || EXPENSE_CATEGORIES[categoryId] || categoryId,
    }
    this.categories.push(category)
    const updatedRecords = this.records.map(record => {
      if (record.expenses.find(expense => expense.id === category.id)) {
        return record
      }
      const [[expense] = [], hiddenExpenses] = partition(record.hiddenExpenses, { id: category.id })
      const expenses = record.expenses.concat(expense || { id: category.id, reported: false })
      const totals = this.calculateRecordTotals(calculateExpensesTotal, expenses, record)
      return {
        ...record,
        expenses,
        hiddenExpenses,
        ...totals,
      }
    })
    this.records = updatedRecords
    return this
  }

  normalizeCategories() {
    const [defaultCategories, customCategories] = partition(this.categories, category =>
      HistoricalExpensesRecords.isDefaultCategory(category.id)
    )
    this.categories = Object.entries({ [GROSS_REVENUE.key]: GROSS_REVENUE.name, ...EXPENSE_CATEGORIES })
      .map(([id]) => {
        return defaultCategories.find(category => category.id === id)
      })
      .filter(Boolean)
      .concat(customCategories)
      .map(category => {
        const expenses = this.records
          .map(record => record.expenses.find(expense => expense.id === category.id))
          .filter(Boolean)
        const { totalPerSf: categoryAverage } = calculateExpensesTotal(expenses, expenses.length)
        if (categoryAverage) {
          category.average = categoryAverage
        }
        return category
      })
    this.records = this.records.map(record => {
      return {
        ...record,
        expenses: this.categories.map(category => {
          const expense = find(record.expenses, { id: category.id })
          return expense || { id: category.id }
        }),
      }
    })
    return this
  }

  removeCategory(categoryId) {
    const category = {
      id: categoryId,
    }
    this.categories = this.categories.filter(cat => cat.id !== categoryId)
    this.records = this.records.map(record => {
      const [removedExpense, expenses] = partition(
        record.expenses,
        expense => expense.id === category.id && !!expense.value
      )
      const totals = this.calculateRecordTotals(calculateExpensesTotal, expenses, record)
      return {
        ...record,
        ...totals,
        expenses,
        hiddenExpenses: removedExpense.concat(...(record.hiddenExpenses || [])),
      }
    })
    return this
  }

  // It's no longer used as we have ComparableExpensesRecords separately now.
  // Kept in favor of further changes to Expense History
  addRecords(...records) {
    const importedCategories = uniqBy(records.flatMap(record => record.expenses), 'id')
    const newCategories = differenceBy(importedCategories, this.categories, 'id')
    this.records = this.records.concat(...records)
    newCategories.forEach(newCategory => {
      this.addCategory(newCategory.id)
    })

    return this
  }

  // It's no longer used as we have ComparableExpensesRecords separately now.
  // Kept in favor of further changes to Expense History
  updateRecord(record) {
    const newCategories = differenceBy(record.expenses, this.categories, 'id')
    const recordIndex = findIndex(this.records, { boweryId: record.boweryId })
    if (recordIndex > -1) {
      setWith(this.records, recordIndex, record)
    }
    newCategories.forEach(newCategory => {
      this.addCategory(newCategory.id)
    })
    return this
  }

  mergeRemovedCategoriesInto(from, to) {
    this.records = this.records.map(record => {
      const hiddenExpenses = record.hiddenExpenses.filter(expense => from.includes(expense.id))

      const expenses = record.expenses.map(expense => {
        if (expense.id !== to) {
          return expense
        }

        const total = calculateExpensesTotal(hiddenExpenses)?.total

        return {
          ...expense,
          value: total,
          reported: true,
        }
      })
      const totals = this.calculateRecordTotals(calculateExpensesTotal, expenses, record)
      return {
        ...record,
        ...totals,
        expenses,
      }
    })

    return this
  }
}
