import { find, sumBy } from 'lodash'

import { toCapitalCase } from '../../../../../../shared/utils/formatters/textFormatters'
import { LOSS_ITEM_KEYS, RENT_LOSS_AMOUNT_FIELD_NAMES } from '../../../../../../shared/constants/acas'

export const getCommercialLossItemName = use => {
  const defaultUseLabels = {
    retail: LOSS_ITEM_KEYS.COMMERCIAL_RETAIL_RENT_LOSS,
    office: LOSS_ITEM_KEYS.COMMERCIAL_OFFICE_RENT_LOSS,
    medical: LOSS_ITEM_KEYS.COMMERCIAL_MEDICAL_RENT_LOSS,
    community: LOSS_ITEM_KEYS.COMMERCIAL_COMMUNITY_RENT_LOSS,
    undetermined: LOSS_ITEM_KEYS.COMMERCIAL_UNDETERMINED_RENT_LOSS,
  }
  return defaultUseLabels[use] || `${toCapitalCase(use)} Rent Loss`
}

export const addMonthlyLossAmountsToRentLossItems = (
  lossUnits,
  vcLossPercentage,
  rentLossItems,
  rentFieldName = RENT_LOSS_AMOUNT_FIELD_NAMES.RESIDENTIAL
) => {
  rentLossItems.forEach(lossItem => {
    const { months, units } = lossItem

    const mappedUnits = units.map(unit => find(lossUnits, lossUnit => lossUnit._id?.toString() === unit))
    const monthlyLoss = sumBy(mappedUnits, rentFieldName) * (1 - vcLossPercentage) || 0

    lossItem.monthlyLoss = monthlyLoss
    lossItem.amount = monthlyLoss * months

    return lossItem
  })
}
