const SWOT_ANALYSIS_CALLOUT_TEXT =
  'A SWOT Analysis is used to identify strengths, weaknesses, opportunities, and threats associated with a subject property.'

const SWOT_ANALYSIS_LINK =
  'https://docs.google.com/spreadsheets/d/1bK42egUmxYf0BZzfjXsKmA51SUzu7VdeBGZ_tIW1x7Y/edit#gid=100698379'

const SWOT_ANALYSIS_LINK_TEXT = 'See here for Bowery Way SWOT Ideas'

const SUBJECT_PROPERTY_CATEGORIES = ['strengths', 'weaknesses', 'opportunities', 'threats']
const SUBJECT_PROPERTY_SINGULAR_CATEGORIES = {
  strengths: 'strength',
  weaknesses: 'weakness',
  opportunities: 'opportunity',
  threats: 'threat',
}

export {
  SUBJECT_PROPERTY_CATEGORIES,
  SUBJECT_PROPERTY_SINGULAR_CATEGORIES,
  SWOT_ANALYSIS_CALLOUT_TEXT,
  SWOT_ANALYSIS_LINK,
  SWOT_ANALYSIS_LINK_TEXT,
}
