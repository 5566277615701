import { isNumber } from 'lodash'

const validateTimeRange = name => (value, allValues, meta) => {
  if (!isNumber(value)) {
    return value
  }
  const timeRange = allValues[name]
  if (meta.name === `${name}.min` && isNumber(timeRange.max)) {
    const max = timeRange.max - 1
    return value > max ? `Max value is ${max}` : undefined
  }
  if (meta.name === `${name}.max` && isNumber(timeRange.min)) {
    const min = timeRange.min + 1
    return value < min ? `Min value is ${min}` : undefined
  }
}

export { validateTimeRange }
