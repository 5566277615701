import React from 'react'

import L from 'leaflet'
import { renderToStaticMarkup } from 'react-dom/server'

import { isEqual } from 'lodash'
import { Marker, Popup } from 'react-leaflet'

import LocationOn from '@mui/icons-material/LocationOn'

const locationItem = renderToStaticMarkup(<LocationOn />)
const POPUP_OPTIONS = {
  maxWidth: '370',
  minWidth: '370',
  className: 'custom',
  offset: L.point(6, -20),
}

const markedLocationIcon = L.divIcon({
  className: 'marked-location-icon',
  html: locationItem,
})
const locationIcon = L.divIcon({
  className: 'location-icon',
  html: locationItem,
})

export default class LeafletMarker extends React.Component {
  shouldComponentUpdate(nextProps) {
    return !isEqual(nextProps.position, this.props.position) || nextProps.isMarked !== this.props.isMarked
  }

  render() {
    const { hasPopup, position, isMarked, hideCloseButton, popup, popupProps } = this.props
    return hasPopup ? (
      <Marker position={position} icon={isMarked ? markedLocationIcon : locationIcon}>
        <Popup position={position} closeButton={!hideCloseButton} {...POPUP_OPTIONS} {...popupProps}>
          {popup}
        </Popup>
      </Marker>
    ) : (
      <Marker position={position} />
    )
  }
}
