import React from 'react'
import PropTypes from 'prop-types'
import { List, Drawer, Divider } from '@material-ui/core'
import { isArray, isEmpty, isEqual, get, map } from 'lodash'
import { compose } from 'redux'
import { shouldUpdate } from 'recompose'
import { withStyles } from '@material-ui/core/styles'
import { Link } from 'react-router-dom'
import { connect } from 'react-redux'

import ChevronLeft from '@mui/icons-material/ChevronLeft'

import { Button } from '../../../../shared/components'

import { NAV_HEADER_HEIGHT } from '../../constants/styles'

import NavigationSideBarCollapsedItem from './NavigationSideBarCollapsedItem'
import NavigationSideBarItem from './NavigationSideBarItem'
import NavigationSideBarSubheader from './NavigationSideBarSubheader'

const styles = theme => ({
  root: {
    width: '100%',
    zIndex: 1,
    overflow: 'hidden',
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
    backgroundColor: 'red',
  },
  drawerPaper: {
    overflowX: 'hidden',
    width: 230,
    top: 0,
    marginTop: NAV_HEADER_HEIGHT,
  },
  subheader: {
    padding: '12px 0px',
  },
  sidebarLogo: {
    height: theme.mixins.toolbar.minHeight,
  },
  sidebarTitle: {
    color: theme.brandColor,
    marginLeft: 10,
    marginTop: 6,
  },
})

const subHeaderStyles = {
  button: {
    width: 123,
    paddingLeft: 0,
    paddingTop: 5,
    fontWeight: 400,
  },
}

const formShape = {
  path: PropTypes.string,
  title: PropTypes.string.isRequired,
  color: PropTypes.string,
  isOpened: PropTypes.bool,
}
formShape.forms = PropTypes.arrayOf(PropTypes.shape(formShape))

class NavigationSideBar extends React.PureComponent {
  static propTypes = {
    additionalInfo: PropTypes.string,
    number: PropTypes.string,
    status: PropTypes.string,
    forms: PropTypes.arrayOf(PropTypes.shape(formShape)),
    currentLocationPath: PropTypes.string,
    route: PropTypes.string.isRequired,
    customSubheader: PropTypes.object,
  }

  static defaultProps = {
    type: '',
    forms: [],
    customSubheader: null,
  }

  state = { lastUrlPath: '' }

  getSubheader() {
    const { address, status, additionalInfo } = this.props
    return (
      this.props.customSubheader || (
        <NavigationSideBarSubheader address={address} status={status} additionalInfo={additionalInfo} />
      )
    )
  }

  generateNavigationSideBarItems(forms, isNestedItem, currentLocationPath, parentColor) {
    const { lastUrlPath } = this.state
    const { route } = this.props
    return forms.map((form, key) => {
      const hasChildNodes = isArray(form.forms) && !isEmpty(form.forms)

      const path = form.path || null
      const formColor = isNestedItem ? parentColor : form.color
      return (
        <div className={this.props.classes.navItem} key={`navigation-side-bar-form-${key}`} path={path}>
          {hasChildNodes ? (
            <NavigationSideBarCollapsedItem
              currentPath={lastUrlPath}
              color={formColor}
              isNested={isNestedItem}
              title={form.title}
              isOpened={form.isOpened}
            >
              {this.generateNavigationSideBarItems(form.forms, true, currentLocationPath, formColor)}
            </NavigationSideBarCollapsedItem>
          ) : (
            <NavigationSideBarItem
              path={path}
              title={form.title}
              color={formColor}
              isNestedItem={isNestedItem}
              currentLocationPath={currentLocationPath}
              route={route}
            />
          )}
        </div>
      )
    })
  }

  render() {
    const { classes } = this.props
    return (
      <div className={classes.root}>
        <Drawer
          classes={{
            paper: classes.drawerPaper,
          }}
          variant="permanent"
        >
          <Divider />
          <List subheader={this.getSubheader()}>
            {this.props.forms.length &&
              this.generateNavigationSideBarItems(this.props.forms, false, this.props.currentLocationPath)}
          </List>
        </Drawer>
      </div>
    )
  }
}

const NavigationSideBarStyled = compose(
  shouldUpdate((props, nextProps) => {
    return (
      !isEqual(props.forms, nextProps.forms) ||
      !isEqual(props.currentLocationPath, nextProps.currentLocationPath) ||
      !isEqual(props.additionalInfo, nextProps.additionalInfo)
    )
  }),
  withStyles(styles)
)(NavigationSideBar)

function getFormKey(parentKey, formKey) {
  return `${parentKey ? parentKey + '.' + formKey : formKey}`
}

function mapForms(state, forms, parentKey) {
  return map(forms, form => {
    const key = getFormKey(parentKey, form.key)

    return {
      path: form.path,
      color: form.color,
      title: form.name,
      showStatus: false,
      forms: mapForms(state, form.forms, key),
    }
  })
}

const getSubheader = reportId => {
  return (
    <Link to={`/report/${reportId}/residential-rent-comps`}>
      <Button style={subHeaderStyles.button} variant="contained" round>
        <ChevronLeft />
        TO REPORT
      </Button>
    </Link>
  )
}
const mapStateToProps = (state, ownProps) => {
  const currentLocationPath = get(state, 'router.location.pathname')
  const buildingCompId = get(state, 'buildingComp.id')
  const reportId = get(state, 'buildingComp.reportId')
  const route = `/report/${reportId}/building-comp/${buildingCompId}`
  return {
    forms: mapForms(state, ownProps.forms, ''),
    hasLogo: get(state, 'shared.location.hasLogo'),
    currentLocationPath,
    customSubheader: getSubheader(reportId),
    itemId: reportId,
    route,
  }
}
export default connect(mapStateToProps)(NavigationSideBarStyled)
