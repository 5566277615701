import React from 'react'
import PropTypes from 'prop-types'

import CloseIcon from '@mui/icons-material/Close'
import { AppBar, Dialog, IconButton, Slide, Toolbar, Typography } from '@mui/material'

import ExportPhotoGridContainer from '../../report/forms/ReviewAndExport/ExportPhotoGrid'

const Transition = props => <Slide direction="up" {...props} />
const DownloadDialog = ({ open, onClose, json }) => (
  <Dialog
    TransitionComponent={Transition}
    data-qa="photo-grid-export-dialog"
    maxWidth="md"
    onClose={onClose}
    open={open}
  >
    <AppBar position="relative">
      <Toolbar>
        <Typography variant="h6" color="inherit">
          Photo Grid Download
        </Typography>
        <IconButton
          aria-label="Close"
          color="inherit"
          data-qa="photo-grid-export-close-btn"
          onClick={onClose}
          sx={{ ml: 'auto' }}
        >
          <CloseIcon />
        </IconButton>
      </Toolbar>
    </AppBar>
    <ExportPhotoGridContainer json={json} />
  </Dialog>
)

DownloadDialog.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
}

export default DownloadDialog
