import React from 'react'
import arrayMutators from 'final-form-arrays'

import PreviewAndEdit from '../PreviewAndEdit'
import wrapForm from '../../wrapForm'

import Edit from './Edit'
import Preview from './Preview'
import { FORM_HEADING, DATA_PATH } from './constants'

const GlossaryOfTerms = ({ form, title, section }) => {
  const { values, change } = form

  return (
    <React.Fragment>
      <PreviewAndEdit title={title} section={section}>
        {isEdit =>
          isEdit ? <Edit change={change} removeTerm={form.mutators.remove} {...values} /> : <Preview {...values} />
        }
      </PreviewAndEdit>
    </React.Fragment>
  )
}

export default wrapForm(DATA_PATH, {
  heading: FORM_HEADING,
  mutators: {
    ...arrayMutators,
  },
})(GlossaryOfTerms)
