import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { noop, get, cloneDeep, sum, isNumber } from 'lodash'
import PropTypes from 'prop-types'

import { BASIS_OF_COMPARISON_OPTIONS, GROSS_REVENUE, STRICT_EXPENSE_CATEGORIES } from 'shared/constants/expenses'

import {
  ColumnBasedTable,
  useColumnBasedRowsApi,
  useColumnBasedColumnsApi,
  useColumnBasedGridRows,
} from '@bowery-valuation/ui-components'
import '@bowery-valuation/ui-components/dist/style.css'

import { Button, Paper, Typography } from '@mui/material'

import { FeatureToggle } from '@bowery-valuation/feature-flagger-client'

import { FEATURE_FLAG_AUTOMATED_EXPENSE_HISTORY } from 'shared/constants/featureFlags'

import useBoolean from 'client-shared/hooks/useBoolean'

import AutomationStatus from 'client-shared/components/AutomationStatus'

import ExpenseDetails from 'report/components/ExpenseDetailsTable'

import Layout from '@ui/Layout'

import { rowManagedRenderer } from '../../helpers'

import { useColumnDefinitions } from '../useColumnDefinitions'

import RestoreExpenseDialog from '../dialogs/RestoreExpenseDialog'

import EditExpenseSubcategoryDialog from '../dialogs/EditExpenseSubcategoryDialog'

import {
  formatRowPath,
  setName,
  normalizeIndex,
  renameSubcategory,
  moveSubcategory,
  valueBasedOnBasis,
  editExpenseYear,
  removeCategory,
  addSubcategory,
} from '../helpers'
import ColumnActionMenu from '../components/ColumnActionMenu'
import EditExpenseYearDialog from '../dialogs/EditExpenseYearDialog'

const valueForRow = (item, row) => {
  return row.hasSubcategories && row.isOpen ? null : item?.expenses?.find(expense => expense.id === row.id)?.value
}

const StrictExpenseHistoryTable = ({ form, basis, setBasis, undoButtonComponent: UndoButton, makeUndoable }) => {
  const [isRestoreCategoryModalOpen, openRestoreCategoryModal, closeRestoreCategoryModal] = useBoolean(false)
  const [editSubcategoryModalState, setEditSubcategoryModalState] = useState({
    open: false,
    subcategory: null,
  })

  const [editExpenseYearModalState, setEditExpenseYearModalState] = useState({
    open: false,
    expenseRecord: null,
  })

  const formValues = get(form.getState(), 'values', {})
  const {
    expenseCategories,
    expenseDataSource,
    expenseHistory,
    noi,
    total,
    totalExcludingTaxes,
    numberOfResidentialUnits,
    grossBuildingArea,
    incomeType,
    basisOfComparison,
  } = formValues

  const rowsApi = useColumnBasedRowsApi({
    rowDefinitionsPath: 'expenseCategories',
    columnItemsPath: 'expenseHistory',
    valuesPaths: 'expenses',
  })

  useEffect(() => {
    // TODO: This is a hack to make the grid render correctly.
    setTimeout(() => {
      return ref?.current?.refreshCells({ suppressFlash: true, enableCellChangeFlash: true, force: true })
    })
  }, [basis])

  const ref = useRef()
  const prevExpenseCategoriesRef = useRef(expenseCategories)

  const forceRefreshColumns = useCallback((rowIds = []) => {
    /*
     * Without it `Operating Expenses` and `Average` columns will not response to change of `isOpen`.
     * Icon for collapse/expend state will always be in one state, even if `isOpen` changes and
     * `cellRendererFramework` returns updated UI. Same goes for `Average`.
     * `rowNodes` - limits amount of refreshes to do. Should NOT be an empty array.
     */
    const rowNodesToRefresh = rowIds.map(rowId => ref?.current?.getRowNode(rowId))
    ref?.current?.refreshCells({
      columns: ['name', 'average'],
      ...(rowNodesToRefresh.length ? { rowNodes: rowNodesToRefresh } : {}),
      force: true,
      suppressFlash: true,
    })
  }, [])

  useEffect(() => {
    // Array of Default categories ids that changes `isOpen` or `hasSubcategories`
    const changedCategoriesIds = []
    expenseCategories
      .filter(({ parentId }) => !parentId)
      .forEach(category => {
        const prevVersion = (prevExpenseCategoriesRef.current || []).find(({ id }) => id === category.id)
        if (
          prevVersion &&
          (category.isOpen !== prevVersion.isOpen || category.hasSubcategories !== prevVersion.hasSubcategories)
        ) {
          changedCategoriesIds.push(category.id)
        }
      })

    if (changedCategoriesIds.length) {
      forceRefreshColumns(changedCategoriesIds)
    }

    prevExpenseCategoriesRef.current = cloneDeep(expenseCategories)
  }, [expenseCategories, forceRefreshColumns])

  const cellEditable = useCallback(
    ({ colDef, row }) => {
      return row.hasSubcategories
        ? false
        : basis === BASIS_OF_COMPARISON_OPTIONS.GROSS && colDef.headerName !== 'Operating Expenses'
    },
    [basis]
  )

  const columnValueFormatter = useCallback(
    ({ data, value }) => valueBasedOnBasis({ data, value, incomeType, grossBuildingArea, numberOfResidentialUnits }),
    [grossBuildingArea, incomeType, numberOfResidentialUnits]
  )

  const averageValueFormatter = useCallback(
    ({ data }) => {
      let { hasSubcategories, isOpen, average } = data

      if (data.form) {
        const targetCategory = get(data.form.getState().values, 'expenseCategories', []).find(
          ({ id }) => id === data.id
        )
        if (targetCategory) {
          ;({ hasSubcategories, isOpen, average } = targetCategory)
        }
      }

      if ((hasSubcategories && isOpen) || typeof average !== 'number') {
        return null
      }

      return valueBasedOnBasis({
        data,
        value: average,
        incomeType,
        grossBuildingArea,
        numberOfResidentialUnits,
      })
    },
    [grossBuildingArea, incomeType, numberOfResidentialUnits]
  )

  const onEditSubcategory = useCallback(
    subcategoryId => {
      const formValues = form.getState().values
      const { expenseCategories } = formValues
      const subcategory = expenseCategories.find(category => category.id === subcategoryId)

      setEditSubcategoryModalState({
        subcategory,
        open: true,
      })
    },
    [setEditSubcategoryModalState, form]
  )

  const onCategoryViewChange = useCallback(
    categoryId => {
      const formValues = form.getState().values
      const { expenseCategories, expenseHistory } = formValues

      // Categories without `parentId` is Default (Parent) categories
      const category = expenseCategories.find(({ parentId, id }) => !parentId && id === categoryId)
      if (category) {
        category.isOpen = !category.isOpen

        form.batch(() => {
          /*
           * Instead of rewriting `updateRow` and `updateManyRows` to include logic for `Parent` value updates
           * The only time we need to use `Parent` value - is when Parent category is in collapsed view
           */
          if (!category.isOpen) {
            const updatedExpenseHistory = [...expenseHistory]
            const subcategoryIds = expenseCategories
              .filter(({ parentId }) => parentId === categoryId)
              .map(({ id }) => id)

            updatedExpenseHistory.forEach(({ expenses }) => {
              const categoryExpense = expenses.find(({ id }) => id === categoryId)

              const values = expenses
                .filter(expense => subcategoryIds.includes(expense.id) && isNumber(expense.value))
                .map(({ value }) => value)

              categoryExpense.value = values.length ? sum(values) : null
            })

            form.change('expenseHistory', updatedExpenseHistory)
          }
          form.change('expenseCategories', [...expenseCategories])
        })
      }
    },
    [form]
  )

  const columnDefinitions = useColumnDefinitions({
    expenseDataSource,
    columnValueFormatter,
    averageValueFormatter,
    onEditSubcategory,
    onCategoryViewChange,
  })

  const shouldDisableAddCategory = useCallback(() => {
    const existingCategoriesIds = (expenseCategories || []).map(({ id }) => id).filter(id => id !== GROSS_REVENUE.key)
    const defaultCategoriesIds = Object.keys(STRICT_EXPENSE_CATEGORIES)

    return defaultCategoriesIds.every(existingId => existingCategoriesIds.includes(existingId))
  }, [expenseCategories])

  const shouldDisableAddSubcategory = useCallback(() => {
    const existingCategoriesIds = (expenseCategories || []).map(({ id }) => id).filter(id => id !== GROSS_REVENUE.key)

    return !existingCategoriesIds.length
  }, [expenseCategories])

  const restoreCategory = useCallback(
    modalFormValues => {
      const formValues = form.getState().values
      const { expenseCategories, expenseHistory } = formValues

      const { categoryId } = modalFormValues
      const expenseName = STRICT_EXPENSE_CATEGORIES[categoryId]

      const updatedExpenseHistory = expenseHistory.map(expensePeriodUnit => {
        const updatedExpensePeriodUnit = cloneDeep(expensePeriodUnit)
        updatedExpensePeriodUnit.expenses = [
          ...expensePeriodUnit.expenses,
          { id: categoryId, reported: false, sf: 0, unit: 0, room: 0 },
        ]
        return updatedExpensePeriodUnit
      })

      const updatedExpenseCategories = [
        ...expenseCategories,
        { id: categoryId, name: expenseName, hasSubcategories: false, isOpen: true },
      ]

      form.batch(() => {
        form.change('expenseCategories', updatedExpenseCategories)
        form.change('expenseHistory', updatedExpenseHistory)
      })

      closeRestoreCategoryModal()
    },
    [closeRestoreCategoryModal, form]
  )

  const createSubcategory = useCallback(
    modalFormValues => {
      const formValues = form.getState().values
      const { expenseCategories, expenseHistory } = formValues
      const { categoryId, subcategoryName } = modalFormValues

      const { updatedExpenseHistory, updatedExpenseCategories } = addSubcategory(
        categoryId,
        subcategoryName,
        expenseCategories,
        expenseHistory
      )

      form.batch(() => {
        form.change('expenseHistory', updatedExpenseHistory)
        form.change('expenseCategories', updatedExpenseCategories)
      })

      setEditSubcategoryModalState({
        open: false,
        subcategory: null,
      })
    },
    [form]
  )

  const editSubcategory = useCallback(
    modalFormValues => {
      const formValues = form.getState().values
      const { expenseCategories, expenseHistory } = formValues
      const { categoryId, subcategoryName } = modalFormValues

      let subcategoryId = editSubcategoryModalState.subcategory.id

      let updatedExpenseCategories = expenseCategories
      let updatedExpenseHistory = expenseHistory

      //change category name and id accordingly
      if (editSubcategoryModalState.subcategory.name !== subcategoryName) {
        const {
          updatedSubcategoryId,
          updatedExpenseCategories: expenseCategoriesAfterRename,
          updatedExpenseHistory: expenseHistoryAfterRename,
        } = renameSubcategory(subcategoryId, subcategoryName, updatedExpenseCategories, updatedExpenseHistory)

        subcategoryId = updatedSubcategoryId
        updatedExpenseCategories = expenseCategoriesAfterRename
        updatedExpenseHistory = expenseHistoryAfterRename
      }
      //move subcategory to another category
      if (editSubcategoryModalState.subcategory.parentId !== categoryId) {
        const { updatedExpenseCategories: expenseCategoriesAfterMove, updatedExpenseHistory: expenseHistoryAfterMove } =
          moveSubcategory(subcategoryId, categoryId, updatedExpenseCategories, updatedExpenseHistory)

        updatedExpenseCategories = expenseCategoriesAfterMove
        updatedExpenseHistory = expenseHistoryAfterMove
      }

      form.batch(() => {
        form.change('expenseHistory', updatedExpenseHistory)
        form.change('expenseCategories', updatedExpenseCategories)
      })

      setEditSubcategoryModalState({
        subcategoryId: null,
        open: false,
      })
    },
    [setEditSubcategoryModalState, form, editSubcategoryModalState]
  )

  const modifyExpenseYear = useCallback(
    modalFormValues => {
      const { expenseHistory } = form.getState().values
      const { expensePeriod, expenseMonth, expenseYear } = modalFormValues

      if (
        !editExpenseYearModalState.expenseRecord ||
        !expenseHistory.some(({ key }) => key === editExpenseYearModalState.expenseRecord.key)
      ) {
        return
      }

      const updatedExpenseHistory = editExpenseYear(expenseHistory, editExpenseYearModalState.expenseRecord, {
        expensePeriod,
        expenseMonth,
        expenseYear,
      })

      form.change('expenseHistory', updatedExpenseHistory)

      setEditExpenseYearModalState({
        expenseRecord: null,
        open: false,
      })
    },
    [editExpenseYearModalState, form]
  )

  const deleteRow = useCallback(
    rowId => {
      const formValues = form.getState().values
      const { expenseCategories, expenseHistory } = formValues

      const { updatedExpenseHistory, updatedExpenseCategories } = removeCategory(
        rowId,
        expenseCategories,
        expenseHistory
      )

      form.batch(() => {
        form.change('expenseHistory', updatedExpenseHistory)
        form.change('expenseCategories', updatedExpenseCategories)
      })
    },
    [form]
  )

  const collapsedCategoriesIds = React.useMemo(
    () =>
      expenseCategories
        .filter(({ isOpen, parentId }) => !parentId && isOpen !== undefined && !isOpen)
        .map(({ id }) => id),
    [expenseCategories]
  )

  const { rowFields, columnBasedGridRows } = useColumnBasedGridRows({
    rows: expenseCategories
      .filter(({ parentId }) => !parentId || !collapsedCategoriesIds.includes(parentId))
      .map(row => {
        return {
          ...row,
          name: setName(row, basis),
          rowDef: {
            ...row?.rowDef,
            hideAction: ['grossRevenue'].includes(row.id),
          },
          basisValue: basis,
          form,
        }
      }),
    items: expenseHistory,
    editable: cellEditable,
    itemValueForRow: (value, row) => valueForRow(value, row),
    itemKey: value => value.key,
    path: formatRowPath,
    type: () => 'money',
  })

  const { rowFields: summaryFields, columnBasedGridRows: summaryRows } = useColumnBasedGridRows(
    {
      rows: [total, totalExcludingTaxes, noi].map(row => {
        return {
          ...row,
          name: setName(row, basis),
          rowDef: {
            summary: true,
            path: (row, itemIndex) => {
              return `expenseHistory[${itemIndex}].${row.id}`
            },
            hideAction: true,
          },
          basisValue: basis,
          form,
        }
      }),
      items: expenseHistory,
      editable: () => false,
      itemValueForRow: (value, row) => {
        return get(value, row.id) || 0
      },
      itemKey: value => value.key,
      type: () => 'money',
    },
    [total, totalExcludingTaxes, noi, expenseHistory, basis]
  )

  const columnsApi = useColumnBasedColumnsApi({ columnItemsPath: 'expenseHistory', normalizeIndex })

  const tableRows = useMemo(() => [...columnBasedGridRows, ...summaryRows], [columnBasedGridRows, summaryRows])

  return (
    <>
      <Paper>
        <Layout.Crab sx={{ mb: 2 }}>
          <Layout.HorizontalRow gap={1}>
            <Typography variant="h6">Expense History</Typography>
            <ExpenseDetails title="Expense History Details" setBasis={setBasis} formValue={basisOfComparison} />
          </Layout.HorizontalRow>
          <Layout.HorizontalRow>
            <UndoButton />
            <Button
              color="primary"
              data-qa="add-category-btn"
              onClick={openRestoreCategoryModal}
              variant="contained"
              disabled={shouldDisableAddCategory()}
            >
              ADD CATEGORY
            </Button>
            <Button
              color="primary"
              data-qa="add-subcategory-btn"
              onClick={() => setEditSubcategoryModalState({ open: true, subcategory: null })}
              disabled={shouldDisableAddSubcategory()}
              variant="contained"
            >
              ADD SUBCATEGORY
            </Button>
          </Layout.HorizontalRow>
        </Layout.Crab>
        <FeatureToggle featureFlag={FEATURE_FLAG_AUTOMATED_EXPENSE_HISTORY}>
          <div style={{ marginBottom: '16px' }}>
            <AutomationStatus formPaths={['expenseHistoryAutomationMetadata', 'proFormaAutomationMetadata']} />
          </div>
        </FeatureToggle>
        {rowFields}
        {summaryFields}
        <ColumnBasedTable
          columns={columnDefinitions}
          disableVirtualization
          hideIndexColumn
          items={expenseHistory}
          gridApiRef={ref}
          legacyComponentRendering
          disableStaticMarkup
          enableCellChangeFlash={false}
          rowManagedRenderer={rowManagedRenderer}
          onColumnDelete={makeUndoable(columnsApi.deleteColumn)}
          onColumnDragEnd={makeUndoable(columnsApi.moveColumn)}
          onColumnUpdate={noop}
          onManyRowsUpdate={makeUndoable(rowsApi.updateManyRows)}
          onRowDelete={makeUndoable(deleteRow)}
          onRowUpdate={makeUndoable(rowsApi.updateRow)}
          onRowsDragEnd={makeUndoable(rowsApi.moveRows)}
          renderers={{
            columnEditor: data => (
              <ColumnActionMenu
                onDeleteOptionClick={makeUndoable(() => columnsApi.deleteColumn(data.colDef))}
                onModifyOptionClick={() => {
                  const expenseHistory = get(form.getState().values, 'expenseHistory', [])
                  const expenseRecord = expenseHistory.find(({ key }) => key === data.colDef.field)
                  if (!expenseRecord) {
                    return
                  }

                  setEditExpenseYearModalState({
                    open: true,
                    expenseRecord,
                  })
                }}
              />
            ),
          }}
          rows={tableRows}
        />
      </Paper>
      {isRestoreCategoryModalOpen && (
        <RestoreExpenseDialog
          onSave={makeUndoable(restoreCategory)}
          onCancel={closeRestoreCategoryModal}
          existingCategories={expenseCategories}
        />
      )}
      {editSubcategoryModalState.open && (
        <EditExpenseSubcategoryDialog
          onSave={makeUndoable(editSubcategoryModalState.subcategory ? editSubcategory : createSubcategory)}
          onCancel={() =>
            setEditSubcategoryModalState({
              open: false,
              subcategory: null,
            })
          }
          subcategory={editSubcategoryModalState.subcategory}
          existingCategories={expenseCategories}
        />
      )}
      {editExpenseYearModalState.open && (
        <EditExpenseYearDialog
          onSave={makeUndoable(modifyExpenseYear)}
          onCancel={() =>
            setEditExpenseYearModalState({
              open: false,
              expenseRecord: null,
            })
          }
          expenseRecord={editExpenseYearModalState.expenseRecord}
          expenseYears={expenseHistory}
        />
      )}
    </>
  )
}

StrictExpenseHistoryTable.propTypes = {
  form: PropTypes.object.isRequired,
  basis: PropTypes.string.isRequired,
  setBasis: PropTypes.func.isRequired,
  undoButtonComponent: PropTypes.elementType,
  makeUndoable: PropTypes.func.isRequired,
}

export default StrictExpenseHistoryTable
