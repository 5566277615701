import React from 'react'

import { get, findIndex } from 'lodash'

import { GeneratedComment, Callout } from '../../../../../../shared/components'
import { RentTypes } from '../../../../../constants'
import { formatPercentageFloat } from '../../../../../../shared/utils/numberFormatters'

const TOOLTIP_TEXT =
  "The following generated text and appraiser commentary will appear in the Income Approach's Rent Adjustments section of your report."

class RentControlledDiscussion extends React.PureComponent {
  generateRentControlledDiscussion = () => {
    const { isNYReport, values } = this.props
    if (isNYReport) {
      const rentTypeSummary = get(values, 'rentTypeSummary')
      const rentControlledIndex = findIndex(rentTypeSummary, summary => summary.rentType === RentTypes.RENT_CONTROLLED)
      const rentControlledIncrease = get(rentTypeSummary[rentControlledIndex], 'increase', 1) - 1
      return (
        'The maximum rent increases for rent-controlled tenants will be set at the average of the five most recent Rent Guidelines Board annual rent ' +
        `increases for one-year rent-stabilized renewals, which is currently ` +
        `${formatPercentageFloat(rentControlledIncrease)}%.`
      )
    }
  }

  render() {
    const { isNYReport } = this.props
    const content =
      'We do not have the specific Rent Controlled Increases Discussion for your geography. Type it in the Appraiser Commentary field below.'
    if (isNYReport) {
      return (
        <GeneratedComment
          tooltipText={TOOLTIP_TEXT}
          title="Generated Commentary"
          label="Rent Controlled Increases Discussion"
          getGeneratedText={this.generateRentControlledDiscussion}
          dataPath="rentControlledDiscussion"
          isDynamicContent
        />
      )
    } else {
      return (
        <React.Fragment>
          <Callout variant="info" content={content} />
          <GeneratedComment
            tooltipText={TOOLTIP_TEXT}
            label="Rent Controlled Increases Discussion"
            dataPath="rentControlledDiscussion"
            isDynamicContent
          />
        </React.Fragment>
      )
    }
  }
}

export default RentControlledDiscussion
