export const BOROUGH_LOOKUP = {
  MN: 'Manhattan', // County of New York
  BK: 'Brooklyn', // County of Kings
  BX: 'Bronx', // County of Bronx
  QN: 'Queens', // County of Queens
  SI: 'Staten Island', // County of Richmond
}

export const BOROUGH_COUNTY_LOOKUP = {
  MN: 'New York',
  BK: 'Kings',
  BX: 'Bronx',
  QN: 'Queens',
  SI: 'Richmond',
}

export const BOROUGH_CITY_LOOKUP = {
  Manhattan: 'New York',
  Brooklyn: 'Brooklyn',
  Bronx: 'Bronx',
  Queens: 'Queens',
  'Staten Island': 'Staten Island',
}

export const BOROUGH_LONG_CITY_LOOKUP = {
  'New York': 'MN',
  Manhattan: 'MN',
  Brooklyn: 'BK',
  Bronx: 'BX',
  Queens: 'QN',
  'Staten Island': 'SI',
}

export const BOROUGH_SHORT_CITY_LOOKUP = {
  MN: 'New York',
  BK: 'Brooklyn',
  BX: 'Bronx',
  QN: 'Queens',
  SI: 'Staten Island',
}

export const BOROUGH_NUMBER = {
  MN: '1',
  BK: '3',
  BX: '2',
  QN: '4',
  SI: '5',
}
