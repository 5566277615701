import React from 'react'

import { get } from 'lodash'
import { Grid } from '@mui/material'

import surveyOfCompetitiveRates from 'shared/utils/textGeneration/organization/surveyOfCompetitiveRates'

import NarrativeComponent from '../../shared/components/NarrativeComponent'

import withCMSForm from './withCMSForm'

const GRID_CONTAINER_GUTTER = 2

const SurveyOfCompetitiveRates = form => {
  const values = get(form, 'values', {})
  return (
    <Grid container spacing={GRID_CONTAINER_GUTTER}>
      <Grid item xs={12}>
        <NarrativeComponent
          title="Survey of Competitive Rates Language"
          generatedText={surveyOfCompetitiveRates.generate}
          data={surveyOfCompetitiveRates.mapDTO(values)}
          name="surveyOfCompetitiveRates.commentary"
        />
      </Grid>
    </Grid>
  )
}

export default withCMSForm(SurveyOfCompetitiveRates, 'Survey of Competitive Rates')
