import { Button, Dialog, DialogContentText, DialogTitle, DialogActions } from '@material-ui/core'

import React, { useState } from 'react'

const ClearAdjustmentsModal = ({ clearAllComps, classes }) => {
  const [isOpen, setIsOpen] = useState(false)

  const openModal = () => {
    setIsOpen(true)
  }

  const closeModal = () => {
    setIsOpen(false)
  }

  const clearAdjustments = () => {
    clearAllComps()
    closeModal()
  }

  return (
    <>
      <Button variant="text" className={classes.clear_text_button} onClick={openModal}>
        Clear Adjustments
      </Button>
      <Dialog open={isOpen} onClose={closeModal}>
        <DialogTitle disableTypography="true" classes={{ root: classes.clear_adjustments_modal_title }}>
          Clear All Adjustments
        </DialogTitle>
        <DialogContentText className={classes.clear_adjument_modal_dialog_text}>
          Are you sure you want to delete <strong>all</strong> adjustments from the table?
        </DialogContentText>
        <DialogActions className={classes.clear_adjustments_modal_actions}>
          <Button onClick={closeModal}>Cancel</Button>
          <Button variant="contained" color="primary" onClick={clearAdjustments}>
            Clear
          </Button>
        </DialogActions>
      </Dialog>
    </>
  )
}

export default ClearAdjustmentsModal
