import { generateCompsIndicesSentence, getComps } from 'shared/utils/textGeneration/salesApproach'
import { ADJUSTMENT_GROUPS } from 'shared/helpers/salesApproach/salesCompAdjustmentConstants'
import { hasAdjustments } from 'shared/helpers/salesApproach/salesCompAdjustments'
import { getLastWordSeparator } from 'shared/utils/sentenceBuilder'

const mapData = (selectedComps, compAdjustments) => {
  const shouldGenerateWriteup = hasAdjustments(compAdjustments, 'averageUnitSize', ADJUSTMENT_GROUPS.OTHER)

  const { upwardComps, downwardComps } = getComps(
    compAdjustments,
    selectedComps,
    'averageUnitSize',
    ADJUSTMENT_GROUPS.OTHER
  )

  return {
    hasAdjustments: shouldGenerateWriteup,
    hasUpwardAdjustments: upwardComps.length > 0,
    hasMultipleUpwardAdjustments: upwardComps.length > 1,
    hasDownwardAdjustments: downwardComps.length > 0,
    hasMultipleDownwardAdjustments: downwardComps.length > 1,
    downwardCompsSentence: generateCompsIndicesSentence(downwardComps, getLastWordSeparator),
    upwardCompsSentence: generateCompsIndicesSentence(upwardComps, getLastWordSeparator),
  }
}

export const mapDTO = formValues => {
  const { selectedComps, compAdjustments = [] } = formValues

  return mapData(selectedComps, compAdjustments)
}

export const mapDataModel = (report, parent) => {
  if (!parent.parent()) {
    return undefined
  }

  const salesApproach = parent.parent()
  const { salesComps = {}, salesCompsAdjustments = {} } = salesApproach
  const { selectedComps = [] } = salesComps
  const { compAdjustments = [] } = salesCompsAdjustments

  return mapData(selectedComps, compAdjustments)
}
