export const VARIES_BY_RENT_TYPE = 'varies by rent type'

export const BasisTypes = {
  PER_SF: 'sf',
  PER_UNIT: 'unit',
  PER_ROOM: 'room',
}

export const perBasisStub = {
  sf: 'per square foot',
  unit: 'per unit',
  room: 'per room',
}

export const DEFAULT_EXPENSE_STRUCTURE = {
  electricity: 'tenant',
  commonElectricity: 'owner',
  gas: 'tenant',
  fuel: 'owner',
}
