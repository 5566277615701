import { sortBy } from 'lodash'
/**
 * Following functions were borrowed from https://github.com/Delapouite/lodash.math
 * Due to poor implementation of the library we decided not to use it in our project,
 * but borrow and re-factor necessary functions
 *
 * If you need more functions, use the above mentioned repository as a reference
 */

/*
 * median([1,2,3,4])
 * => 2.5
 */
export const median = array => {
  const middle = (array.length + 1) / 2
  const sortedArray = sortBy(array)
  return sortedArray.length % 2 ? sortedArray[middle - 1] : (sortedArray[middle - 1.5] + sortedArray[middle - 0.5]) / 2
}
