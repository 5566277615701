import React from 'react'
import PropTypes from 'prop-types'
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft'
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight'
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, CardMedia } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'

import { getResizedImageUrl } from '../../utils/cloudinary'

const styles = {
  media: {
    width: 600,
    height: 400,
    backgroundColor: 'rgba(0,0,0,0.15)',
  },
  dialogActions: {
    margin: '4 auto',
  },
}

class ImageModal extends React.PureComponent {
  static propTypes = {
    images: PropTypes.arrayOf(
      PropTypes.shape({
        index: PropTypes.number,
        cdnUrl: PropTypes.string.isRequired,
      })
    ),
    openedImageIndex: PropTypes.number,
    category: PropTypes.string.isRequired,
    onHideModal: PropTypes.func.isRequired,
  }
  static defaultProps = {
    images: [],
    openedImageIndex: 0,
  }
  state = {
    currentIndex: this.props.openedImageIndex,
  }

  get currentImageUrl() {
    const { images } = this.props
    const { currentIndex } = this.state
    return getResizedImageUrl({ url: images[currentIndex].cdnUrl, width: 600 })
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.openedImageIndex !== this.state.currentIndex) {
      this.setState({
        currentIndex: nextProps.openedImageIndex,
      })
    }
  }

  onClickLeft = () => {
    const { currentIndex } = this.state
    if (currentIndex >= 1) {
      this.setState({ currentIndex: this.state.currentIndex - 1 })
    }
  }

  onClickRight = () => {
    const { currentIndex } = this.state
    const imageIndex = currentIndex + 1
    if (imageIndex < this.props.images.length) {
      this.setState({ currentIndex: imageIndex })
    }
  }

  render() {
    const { images, category, onHideModal, classes } = this.props
    const { currentIndex } = this.state
    const hasNextImage = currentIndex < images.length - 1
    const hasPreviousImage = currentIndex !== 0
    return (
      <Dialog maxWidth="md" open onClose={onHideModal}>
        <DialogTitle id="simple-dialog-title">Photos</DialogTitle>
        <DialogContent>
          <CardMedia className={classes.media} image={this.currentImageUrl} title={category} />
        </DialogContent>
        <DialogActions className={classes.dialogActions}>
          {hasPreviousImage && (
            <Button color="primary" onClick={this.onClickLeft}>
              <KeyboardArrowLeft />
              Previous Image
            </Button>
          )}
          {hasNextImage && (
            <Button color="primary" onClick={this.onClickRight}>
              Next Image
              <KeyboardArrowRight />
            </Button>
          )}
        </DialogActions>
      </Dialog>
    )
  }
}

export default withStyles(styles)(ImageModal)
