import React, { useCallback, useMemo } from 'react'
import { isEqual, noop, uniqueId } from 'lodash'
import { useField, useForm } from 'react-final-form'
import OpenInNewOutlinedIcon from '@mui/icons-material/OpenInNewOutlined'

import { RowBasedTable, useRowsApi } from '@bowery-valuation/ui-components'

import { Card, Grid, Paper, Typography, withStyles } from '@material-ui/core'

import MenuIcon from 'client-shared/layout/MenuIcon'
import CalloutButton from 'client-shared/components/CalloutButton'

import { ButtonSwitch, CheckboxWithLabel } from '../../../../shared/components'
import { Button } from '../../../../shared/components/_mui5'
import DropDownButton from '../../../../shared/components/DropDown/DropDownButton'

import useFieldValue from '../../../utils/useFieldValue'

import { TAXABLE_ASSESSED_VALUE_TAX_ITEM_ROW_PREFIX } from '../../../../../../libs/bowery-libs/constants/taxes'

import { ASSESSED_VALUE_COLUMN_OPTIONS, TAXABLE_ASSESSED_VALUE_TYPE_OPTIONS } from './constants'

import { useAssessedValueColumns, useAssessedValueColumnsApi, useRegisteredFields } from './hooks'

import styles from './styles'

import EditColumnForm from './edit-column-popover/edit-column-form'
import TaxItemConfiguration from './TaxTableReuse/TaxItemConfiguration'

import '@bowery-valuation/ui-components/dist/style.css'

const getNewTaxableAssessedValueItem = item => {
  return {
    id: uniqueId(TAXABLE_ASSESSED_VALUE_TAX_ITEM_ROW_PREFIX),
    actual: 0,
    item,
    transitional: 0,
    //TODO: other 4 fields?
  }
}

const TaxableAssessedValueTable = ({ classes, isNYCReport, isOther, has830 }) => {
  const assessedValueTableField = useField('assessedValueTable', {
    subscription: { value: true },
    allowNull: true,
    isEqual: isEqual,
  })
  const hasTransitionalAssessedValue = useFieldValue('hasTransitionalAssessedValue')
  const assessedValueTable = assessedValueTableField.input.value
  const rowsApi = useRowsApi('assessedValueTable')
  const formApi = useForm()

  const columns = useAssessedValueColumns()
  const columnsApi = useAssessedValueColumnsApi()

  const assessedValueTableRegisteredFields = useRegisteredFields('assessedValueTable', columns, assessedValueTable)
  const tableCellFields = assessedValueTableRegisteredFields.slice(1)

  const tableRenderers = useMemo(
    () => ({
      editColumnPopoverContent: EditColumnForm,
    }),
    []
  )

  const assessedValueColumnOptions = useMemo(() => {
    const columnOptions = hasTransitionalAssessedValue
      ? [...ASSESSED_VALUE_COLUMN_OPTIONS.actual, ...ASSESSED_VALUE_COLUMN_OPTIONS.transitional]
      : ASSESSED_VALUE_COLUMN_OPTIONS.actual

    return columnOptions.map(columnOption => {
      if (columns.find(column => column.name === columnOption.value)?.isVisible) {
        return { ...columnOption, disabled: true }
      }
      return columnOption
    })
  }, [columns, hasTransitionalAssessedValue])

  const addAssessedValueColumn = option => {
    columnsApi.addColumn(option.value)
    formApi.focus('assessedValueColumns')
  }

  const addTaxItemRow = ({ item = 'New Tax Item' }) => {
    const newTaxableAssessedValueItem = getNewTaxableAssessedValueItem(item)
    const penultimateIndex = assessedValueTable.length - 1

    rowsApi.insertRowAtIndex(newTaxableAssessedValueItem, penultimateIndex)
  }

  const deleteRow = useCallback(
    rowId => {
      const currentAssessedValueTable = formApi.getState().values.assessedValueTable

      if (currentAssessedValueTable.length > 2) {
        rowsApi.deleteRow(rowId)
      }
    },
    [formApi, rowsApi]
  )
  const getCustomColumnConfig = column => {
    return {
      cellEditorSelector: column.cellEditorSelector,
      cellEditorFramework: undefined,
    }
  }

  const handleCellFocus = event => {
    const rowIndex = event.rowIndex
    const columnId = event.column.colId
    formApi.focus(`assessedValueTable[${rowIndex}].${columnId}`)
  }

  const otherTaxInfoLink =
    'https://docs.google.com/spreadsheets/d/1Zi7PnALjsZE2ykHMLEUVu1oSwnHAxoByUlKBr8GeCJ4/edit?usp=sharing'

  return (
    <>
      {!isNYCReport && (
        <Grid container>
          <Grid item xs={8} />
          <Grid item xs={4}>
            <TaxItemConfiguration
              assessedValueTable={assessedValueTable}
              columns={columns}
              addTaxItemRow={addTaxItemRow}
              deleteRow={deleteRow}
              formApi={formApi}
              columnsApi={columnsApi}
              hasTransitionalAssessedValue={hasTransitionalAssessedValue}
            />
          </Grid>
        </Grid>
      )}

      <div style={{ marginBottom: '8px' }} />

      <Paper className={classes.paper}>
        <Grid container xl={12} direction="row" alignItems="center">
          <Typography className={classes.title} color="primary" variant="subtitle1">
            Taxable Assessed Value
          </Typography>
          {isOther && (
            <a className={classes.link} href={otherTaxInfoLink} target="_blank" rel="noopener noreferrer">
              <MenuIcon icon={OpenInNewOutlinedIcon} tooltipTitle="Tax Assessors Websites and Phone Numbers" />
            </a>
          )}
        </Grid>
        <Grid item xs={12}>
          {has830 && (
            <Grid item xl={12} className={classes.discussionContainer}>
              <CalloutButton
                link="https://a836-pts-access.nyc.gov/care/forms/htmlframe.aspx?mode=content/home.htm"
                linkText="Check"
                sx={{ mb: 1 }}
                target="_blank"
                text="We have noticed an issue with the Transitional and Actual Assessments for Class 2A/2B/2C data provided by NYC Dept. of Finance. Please check all assessments."
                variant="warn"
              />
            </Grid>
          )}
        </Grid>
        <Grid item xs={4}>
          <ButtonSwitch name="taxableAssessedValueType" options={TAXABLE_ASSESSED_VALUE_TYPE_OPTIONS} />
        </Grid>

        <Grid item xs={12}>
          <Card elevation={0}>
            <Grid container justify="space-between">
              <Grid item>
                <CheckboxWithLabel name="hasTransitionalAssessedValue" label="Include a transitional assessed value" />
              </Grid>

              {!isNYCReport && (
                <Grid item>
                  <DropDownButton
                    data-qa="taxable-assessed-value-add-column-btn"
                    variant="contained"
                    onSelect={addAssessedValueColumn}
                    options={assessedValueColumnOptions}
                  >
                    Add Column
                  </DropDownButton>
                  &nbsp;&nbsp;
                  <Button variant="outlined" onClick={addTaxItemRow} data-qa="taxable-assessed-value-add-row-btn">
                    Add Row +
                  </Button>
                </Grid>
              )}

              <Grid item xs={12}>
                {tableCellFields}
                <RowBasedTable
                  id="taxable-assessed-value"
                  columns={columns}
                  rows={assessedValueTable}
                  renderers={tableRenderers}
                  onRowDelete={deleteRow}
                  onRowUpdate={rowsApi.updateRow}
                  onManyRowsUpdate={rowsApi.updateManyRows}
                  onColumnDragEnd={noop}
                  onRowsDragEnd={noop}
                  onColumnDelete={columnsApi.deleteColumn}
                  onColumnUpdate={columnsApi.updateColumn}
                  getCustomColumnConfig={getCustomColumnConfig}
                  onCellFocused={handleCellFocus}
                />
              </Grid>
            </Grid>
          </Card>
        </Grid>
      </Paper>
    </>
  )
}

export default withStyles(styles)(TaxableAssessedValueTable)
