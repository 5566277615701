export const OTHER_TYPE = 'Other'

export const HEATING_SYSTEM_ITEMS = [
  {
    label: 'Gas Fired Boiler',
    value: 'gas fired boiler',
  },
  {
    label: 'Oil Fired Boiler',
    value: 'oil fired boiler',
  },
  {
    label: 'Dual Fired Boiler',
    value: 'dual fired boiler',
  },
  {
    label: 'Steam Heating',
    value: 'steam heating',
  },
  {
    label: 'Furnace',
    value: 'Furnace',
  },
  {
    label: 'HVAC System',
    value: 'HVAC system',
  },
  {
    label: 'PTAC System',
    value: 'PTAC system',
  },
  {
    label: OTHER_TYPE,
    value: OTHER_TYPE,
  },
]

export const HEATING_SYSTEM_OPTIONS = {
  GAS_FIRED_BOILER: 'Gas Fired Boiler',
  OIL_FIRED_BOILER: 'Oil Fired Boiler',
  DUAL_FIRED_BOILER: 'Dual Fired Boiler',
  STEAM_HEATING: 'Steam Heating',
  FURNACE: 'Furnace',
  HVAC_SYSTEM: 'HVAC System',
  PTAC_SYSTEM: 'PTAC System',
  OTHER: 'Other',
}

export const OIL_HEATING_TYPES = ['oil fired boiler', 'dual fired boiler']

export const OIL_TYPES = ['Oil #2', 'Oil #4', 'Oil #6', OTHER_TYPE]

export const COOLING_SYSTEM_ITEMS = [
  {
    label: 'Window A/C Unit',
    value: 'window A/C unit',
  },
  {
    label: 'Wall-Mounted A/C Unit',
    value: 'wall-mounted A/C unit',
  },

  {
    label: 'HVAC System',
    value: 'HVAC system',
  },
  {
    label: 'PTAC System',
    value: 'PTAC system',
  },
  {
    label: OTHER_TYPE,
    value: OTHER_TYPE,
  },
]

export const COOLING_SYSTEM_OPTIONS = {
  WINDOW_AC_UNIT: 'Window A/C Unit',
  WALL_MOUNTED_AC_UNIT: 'Wall-Mounted A/C Unit',
  HVAC_SYSTEM: 'HVAC System',
  PTAC_SYSTEM: 'PTAC System',
  OTHER: 'Other',
}

export const HOT_WATER_SYSTEM_ITEMS = [
  {
    label: 'Central Hot Water Heater System',
    value: 'central hot water heater system',
  },
  {
    label: 'Individual Tanks',
    value: 'individual tanks',
  },
  {
    label: OTHER_TYPE,
    value: OTHER_TYPE,
  },
]

export const HOT_WATER_SYSTEM_OPTIONS = {
  CENTRAL: 'Central Hot Water Heater System',
  INDIVIDUAL: 'Individual Tanks',
  OTHER: 'Other',
}

export const INDIVIDUAL_METERS_TYPE = 'Individual'
export const MASTER_METERS_TYPE = 'Master'
export const METERS_TYPES = ['Individual', 'Master']

export const ROOF_LOCATION = 'Roof'
export const OUTSIDE_LOCATION = 'Outside'
export const UNIT_LOCATION = 'In Unit'
export const LOCATIONS = [
  'Basement',
  'Utility Room',
  UNIT_LOCATION,
  ROOF_LOCATION,
  'Hallway',
  OUTSIDE_LOCATION,
  OTHER_TYPE,
]
