import React from 'react'
import { get, maxBy, isNumber, sumBy } from 'lodash'

import { Button, Stack } from '@mui/material'

import { Field } from 'react-final-form'

import Table from 'client-shared/components/Table/TableComponent'
import { ButtonSwitch } from 'client-shared/components'
import {
  totalRowRenderer,
  totalLabelRenderer,
  currencyIntRight,
} from 'client-shared/components/TableHelpers/cellRenderers'
import { formatInt } from 'client-shared/utils/numberFormatters'

import { EXPENSE_TYPES_LIST, DATA_SOURCE_TYPES_LIST, RENOVATION_COSTS_TYPE_OPTIONS } from './constants'

const renovationPeriodRenderer = (_instance, td, _row, _col, _prop, value, _cellProperties) => {
  if (value === 1) {
    td.innerHTML = `${formatInt(value || 0)} month`
  } else if (isNumber(value)) {
    td.innerHTML = `${formatInt(value || 0)} months`
  } else {
    td.innerHTML = ``
  }
  td.className = 'alignRight'
  return td
}

const renovationTotal = (_instance, td, _row, _col, _prop, value, _cellProperties) => {
  if (value === 1) {
    td.innerHTML = `${formatInt(value || 0)} month`
  } else if (isNumber(value)) {
    td.innerHTML = `${formatInt(value || 0)} months`
  } else {
    td.innerHTML = ``
  }
  td.className = 'totalRow'
  td.readOnly = true
  return td
}

class ItemizedBetaTable extends React.PureComponent {
  tableRef = React.createRef()
  getTableData = (itemizedItems, itemizedTotal) => {
    const lessAmountSpent = get(itemizedTotal, 'lessAmountSpent', 0)
    const totalAmount = sumBy(itemizedItems, 'amount')
    const renovationPeriod = itemizedTotal.renovationPeriod
    const data = []

    itemizedItems.forEach(item => {
      data.push(item)
    })
    data.push({
      item: 'Total: ',
      renovationPeriod: renovationPeriod,
      amount: totalAmount,
      totalRow: true,
    })
    data.push({ item: 'Less Total Amount Spent to Date: ', amount: lessAmountSpent })
    data.push({
      item: 'Net Total Renovation Budget: ',
      amount: totalAmount - lessAmountSpent,
    })

    return data
  }

  getTableColumns = () => {
    const columns = [
      { data: 'item', value: 'Item', width: 275, className: 'alignLeft' },
      {
        data: 'expenseType',
        value: 'Expense Type',
        width: 225,
        type: 'autocomplete',
        source: EXPENSE_TYPES_LIST,
        allowInvalid: false,
        strict: true,
      },
      {
        data: 'dataSource',
        value: 'Data Source',
        width: 225,
        type: 'autocomplete',
        source: DATA_SOURCE_TYPES_LIST,
        allowInvalid: false,
        strict: true,
      },
      { data: 'renovationPeriod', value: 'Time Until Completion', width: 140, type: 'numeric' },
      { data: 'amount', value: 'Amount', type: 'numeric', width: 200, renderer: currencyIntRight },
    ]
    return columns
  }

  beforeChange = (changes = [], source) => {
    const { form } = this.props
    const itemizedItems = get(form.values, 'itemized.items', [])
    const itemizedTotal = get(form.values, 'itemized.total', {})
    const numberOfItems = itemizedItems.length
    changes.forEach(change => {
      const [row, col, , value] = change
      if (row < numberOfItems) {
        if (col === 'amount' || col === 'renovationPeriod') {
          itemizedItems[row][col] = parseInt(value)
        } else {
          itemizedItems[row][col] = value
        }
      } else {
        if (col === 'amount') {
          if (row === numberOfItems + 1) {
            itemizedTotal.lessAmountSpent = parseInt(value)
          }
        }
      }
    })

    itemizedTotal.amount = itemizedItems.reduce((total, item) => total + parseInt(item.amount || 0), 0)
    itemizedTotal.renovationPeriod = get(maxBy(itemizedItems, 'renovationPeriod'), 'renovationPeriod', 0)
    itemizedTotal.netRenovationBudget = itemizedTotal.amount - (itemizedTotal.lessAmountSpent || 0)

    form.batch(() => {
      // On edit/paste, update form value with shallow copy to mark it dirty
      const changeSourceList = ['edit', 'CopyPaste.paste']
      if (changeSourceList.findIndex(changeSource => changeSource === source) >= 0) {
        form.change('itemized.items', [...itemizedItems])
      } else {
        form.change('itemized.items', itemizedItems)
      }
      form.change('itemized.total', itemizedTotal)
    })
  }

  addItemizedRenovation = () => {
    const { form } = this.props
    form.mutators.push('itemized.items', {})
  }

  render() {
    const { form } = this.props
    const itemizedItems = get(form.values, 'itemized.items', [{}])
    const itemizedTotal = get(form.values, 'itemized.total', {})

    const data = this.getTableData(itemizedItems, itemizedTotal)
    const columns = this.getTableColumns()
    const colHeaders = columns.map(column => column.value)
    return (
      <>
        <Stack spacing={4} alignItems="flex-start" mt={1} mb={3}>
          <Button data-qa="add-btn" variant="contained" onClick={this.addItemizedRenovation}>
            Add
          </Button>
          <ButtonSwitch name="renovationCostsType" options={RENOVATION_COSTS_TYPE_OPTIONS} />
        </Stack>
        <Field name="itemized.items">{() => null}</Field>
        <Table
          setTableRef={ref => {
            this.tableRef = ref
          }}
          colHeaders={colHeaders}
          data={data}
          columns={columns}
          formulas={true}
          beforeChange={this.beforeChange}
          contextMenu={{
            callback: (key, selection, clickEvent) => {
              const { start, end } = selection[0]
              const startRow = start.row
              const endRow = end.row
              if (key === 'row_above') {
                form.mutators.insert('itemized.items', startRow, {})
              } else if (key === 'row_below') {
                form.mutators.insert('itemized.items', endRow + 1, {})
              } else if (key === 'remove_row') {
                form.mutators.remove('itemized.items', startRow)
              }
            },
            items: {
              row_above: {
                name: 'Add row above',
                disabled: () => {
                  const row = this.tableRef.hotInstance.getSelectedLast()[0]
                  if (row > data.length - 3) {
                    return true
                  }
                },
              },
              row_below: {
                name: 'Add row below',
                disabled: () => {
                  const row = this.tableRef.hotInstance.getSelectedLast()[0]
                  if (row > data.length - 4) {
                    return true
                  }
                },
              },
              remove_row: {
                name: 'Remove row',
                disabled: () => {
                  const row = this.tableRef.hotInstance.getSelectedLast()[0]
                  if (row > data.length - 4) {
                    return true
                  }
                },
              },
            },
          }}
          minSpareRows={1}
          beforePaste={(data, coords) => {
            const { startRow } = coords
            const numberOfItems = itemizedItems.length
            const addedRows = data.length
            const rowsToDelete = numberOfItems - startRow - addedRows
            if (rowsToDelete > 0) {
              data.splice(rowsToDelete)
            }
          }}
          cells={(row, col, props) => {
            const cellProperties = {}
            const rowData = data[row]
            if (!rowData) {
              return cellProperties
            }
            const isTotalRow = row > data.length - 5
            if (isTotalRow) {
              cellProperties.readOnly = true
              cellProperties.className = 'readOnly'
              cellProperties.type = 'numeric'
              cellProperties.renderer = totalLabelRenderer
              const isLessAmountRow = row === data.length - 3
              if (props === 'amount' && isLessAmountRow) {
                cellProperties.renderer = currencyIntRight
                cellProperties.readOnly = false
              } else if (col === 4) {
                cellProperties.className = 'alignRight'
                cellProperties.renderer = totalRowRenderer
              } else if (props === 'renovationPeriod' && rowData.totalRow) {
                cellProperties.renderer = renovationTotal
              }
            } else if (props === 'renovationPeriod') {
              if (rowData.totalRow) {
                cellProperties.readOnly = true
              } else {
                cellProperties.renderer = renovationPeriodRenderer
                cellProperties.redOnly = false
              }
            }
            return cellProperties
          }}
          afterGetRowHeader={(row, TH) => {
            if (row === 0) {
              TH.style =
                'font-weight:normal;border-bottom:1px solid #d7deea;border-top:1px solid #d7deea;text-align: center;vertical-align: middle;border-radius: 0px!important;margin: none; '
            } else {
              TH.style =
                'font-weight:normal;border-bottom:1px solid #d7deea;border-top:1px solid #f8fafc; text-align: center;vertical-align: middle;border-radius: 0px!important;margin: none; '
            }
          }}
          columnSummary={[
            {
              destinationRow: data.length - 3,
              destinationColumn: 3,
              type: 'max',
            },
          ]}
        />
      </>
    )
  }
}

export default ItemizedBetaTable
