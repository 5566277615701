import { get, isEmpty, toLower } from 'lodash'

import { formatList } from 'shared/utils/textHelpers'

import {
  getSiteInspector,
  getRoofAndStairsConditionValue,
  getRoofConditionValue,
} from '../../../report/propertyInformation/description'
import { INSPECTED_LOCATIONS_OPTIONS } from '../../../../constants/property/description'
import { getRoofAndStairsConditionPhrase, formatPropertyCondition } from '../description'

const formatInspector = siteInspector => {
  if (!siteInspector || isEmpty(siteInspector)) {
    return null
  }

  return `${siteInspector.fullName || 'N/A'}${!siteInspector.isExternal ? ' of Bowery Valuation' : ''}`
}

const mapData = ({
  siteInspector,
  inspectedLocations,
  otherInspectedLocations,
  inspectionDate,
  stairsCondition,
  roofCondition,
  noAdverseConditions,
  noHazardousSubstances,
  valueAsComplete,
  generalAsStabilizedCondition,
}) => {
  const inspector = formatInspector(siteInspector)
  const locations = inspectedLocations
    ? Object.entries(INSPECTED_LOCATIONS_OPTIONS)
        .filter(([key]) => !!inspectedLocations[key])
        .map(([key, value]) => toLower(key === 'other' ? otherInspectedLocations || '' : value))
    : []

  const locationsList = formatList(locations) ?? ''
  const stairsInspected = locations.includes('stairs')
  const roofInspected = locations.includes('roof')

  return {
    inspectionDate,
    inspector,
    locationsList,
    stairsInspected,
    stairsNotInspected: !stairsInspected,
    roofInspected,
    roofNotInspected: !roofInspected,
    stairsCondition: stairsCondition ? getRoofAndStairsConditionPhrase(stairsCondition) : null,
    roofCondition: roofCondition ? getRoofAndStairsConditionPhrase(roofCondition) : null,

    adverseConditionsAndHazardousSubstances: !noAdverseConditions && !noHazardousSubstances,
    noAdverseConditionsOrHazardousSubstances: noAdverseConditions && noHazardousSubstances,
    noAdverseConditions: noAdverseConditions && !noHazardousSubstances,
    noHazardousSubstances: !noAdverseConditions && noHazardousSubstances,

    valueAsComplete,
    propertyCondition: formatPropertyCondition(generalAsStabilizedCondition),
  }
}

export const mapDTO = formValues => {
  const {
    siteInspector,
    inspectionDate,
    inspectedLocations,
    otherInspectedLocations,
    lobbyStairwellsCondition,
    roofCondition,
    observations: { noAdverseConditions, noHazardousSubstances } = {},
    valueAsComplete,
    generalAsStabilizedCondition,
  } = formValues

  return mapData({
    siteInspector,
    inspectedLocations,
    otherInspectedLocations,
    inspectionDate,
    stairsCondition: lobbyStairwellsCondition,
    roofCondition,
    noAdverseConditions,
    noHazardousSubstances,
    valueAsComplete,
    generalAsStabilizedCondition,
  })
}

export const mapDataModel = report => {
  const inspectionDate = get(report, 'inspection.inspectionDate')
  const valueAsComplete = get(report, 'valueAsComplete')
  const buildingDescription = get(report, 'buildingDescription', {})
  const siteInspector = getSiteInspector(report)
  const lobbyStairwellsCondition = getRoofAndStairsConditionValue(buildingDescription, 'lobbyStairwellsCondition')
  const roofCondition = getRoofConditionValue(buildingDescription, 'roofCondition')

  const {
    inspectedLocations,
    otherInspectedLocations,
    noHazardousSubstances,
    noAdverseConditions,
    generalAsStabilizedCondition,
  } = buildingDescription

  return mapData({
    siteInspector,
    inspectedLocations,
    otherInspectedLocations,
    inspectionDate,
    stairsCondition: lobbyStairwellsCondition,
    roofCondition,
    noAdverseConditions,
    noHazardousSubstances,
    valueAsComplete,
    generalAsStabilizedCondition,
  })
}
