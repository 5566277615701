import React from 'react'
import { OnChange } from 'react-final-form-listeners'
import { withStyles } from '@material-ui/core'
import { Typography, Stack } from '@mui/material'

import { INSPECTED_LOCATIONS_OPTIONS, OBSERVATIONS_OPTIONS } from 'shared/constants/property/description'
import { arrayToKeyValuePairs, keyValuePairsToCheckboxValues } from 'client-shared/utils/ui/checkboxHelper'
import { getFeatureFlagValue } from '@bowery-valuation/feature-flagger-client'

import { ENABLE_AUTOMATED_INSPECTION_PREFILL } from 'shared/constants/featureFlags'
import AutomationStatus from 'client-shared/components/AutomationStatus'
import siteInspection from 'shared/utils/textGeneration/property/siteInspection'
import { ANCHOR_PROPERTY_DESCRIPTION } from 'shared/constants/report/dataCollections'

import { Text, RadioButtonList, UneditableSection, UneditableField, NarrativeComponent } from 'client-shared/components'

import { CheckboxGroup } from 'client-shared/components/Checkbox'

import { ROOF_AND_STAIRS_CONDITIONS } from '../../../constants'

import { styles } from './styles'

const conditionOptionsArr = arrayToKeyValuePairs(ROOF_AND_STAIRS_CONDITIONS)
const inspectedLocationsOptions = keyValuePairsToCheckboxValues(INSPECTED_LOCATIONS_OPTIONS)
const observationOptions = keyValuePairsToCheckboxValues(OBSERVATIONS_OPTIONS)

const SiteInspection = ({
  valueAsComplete,
  inspectionDate,
  inspectedLocations,
  change,
  validateOther,
  classes,
  formValues,
  currentPath,
  automationMetadata,
}) => {
  const automatedInspectionPrefillFlag = getFeatureFlagValue(ENABLE_AUTOMATED_INSPECTION_PREFILL)

  return (
    <React.Fragment>
      <Typography variant="h6">Site Inspection</Typography>
      {automatedInspectionPrefillFlag && automationMetadata && (
        <AutomationStatus
          formPaths={['automationMetadata']}
          message="We automated the information below from Mobile Inspection app"
          sx={{ marginTop: '8px', marginBottom: '16px' }}
        />
      )}
      <UneditableSection
        name="Found In: Subject Property Data"
        formName="Subject Property Data"
        link="subject-property"
        anchor={ANCHOR_PROPERTY_DESCRIPTION}
        returnDataPath={currentPath}
        editDataMessage
      >
        <Stack spacing={1}>
          <UneditableField
            label="As Is General Property Condition"
            name={formValues.generalCondition.value === 'Other' ? 'generalCondition.other' : 'generalCondition.value'}
          />
          {valueAsComplete && (
            <>
              <UneditableField
                label="As Stabilized Assumed General Property Condition"
                name={
                  formValues.generalAsStabilizedCondition.value === 'Other'
                    ? 'generalAsStabilizedCondition.other'
                    : 'generalAsStabilizedCondition.value'
                }
              />
            </>
          )}
        </Stack>
      </UneditableSection>

      <div data-qa="inspected-locations-checkbox-group">
        <CheckboxGroup
          header="Locations Inspected"
          groupName="inspectedLocations"
          items={inspectedLocationsOptions}
          horizontal
        />
        {!!inspectedLocations.other && (
          <Text
            name="otherInspectedLocations"
            placeholder="Separate multiple locations with a comma"
            label="Other"
            required
            validate={validateOther('inspectedLocations')}
          />
        )}
      </div>
      {!!inspectedLocations.roof && (
        <div className={classes.optionsList}>
          <RadioButtonList label="Roof Condition" name="roofCondition" horizontal items={conditionOptionsArr} />
        </div>
      )}
      <OnChange name="inspectedLocations.roof">
        {(value, prevValue) => prevValue && change('roofCondition', null)}
      </OnChange>
      {!!inspectedLocations.interiorHallways && (
        <div className={classes.optionsList}>
          <RadioButtonList
            label="Entryway &amp; Interior Hallways Condition"
            name="interiorHallwaysCondition"
            horizontal
            items={conditionOptionsArr}
          />
        </div>
      )}
      <OnChange name="inspectedLocations.interiorHallways">
        {(value, prevValue) => prevValue && change('interiorHallwaysCondition', null)}
      </OnChange>
      {!!inspectedLocations.stairs && (
        <div className={classes.optionsList}>
          <RadioButtonList
            label="Stair Condition"
            name="lobbyStairwellsCondition"
            horizontal
            items={conditionOptionsArr}
          />
        </div>
      )}
      <OnChange name="inspectedLocations.stairs">
        {(value, prevValue) => prevValue && change('lobbyStairwellsCondition', null)}
      </OnChange>
      <CheckboxGroup header="Observations" groupName="observations" items={observationOptions} horizontal />
      <NarrativeComponent
        title="Site Inspection Discussion"
        generatedText={siteInspection.generate}
        data={siteInspection.mapDTO({ ...formValues, valueAsComplete, inspectionDate })}
        name="siteInspectionDiscussion"
        tooltipText="The following text will appear in the Description of Improvements section of your report."
      />
    </React.Fragment>
  )
}

export default withStyles(styles)(SiteInspection)
