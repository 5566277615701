import { grey } from '@material-ui/core/colors'

export default theme => {
  const textElements = '& td, input, span, div, p'
  return {
    itemCell: {
      width: 255,
      minWidth: 255,
    },
    expenseTypeCell: {
      width: 130,
      minWidth: 130,
      paddingRight: '26px !important',
    },
    dataSourceCell: {
      paddingTop: 4,
      width: 200,
      minWidth: 200,
      paddingRight: '26px !important',
    },
    renovationPeriodCell: {
      width: 150,
      minWidth: 150,
      position: 'relative',
    },
    amountCell: {
      width: 120,
      minWidth: 120,
      maxWidth: 120,
      overflowX: 'visible',
      wordSpacing: 5,
      whiteSpace: 'nowrap',
    },
    totalEmptyCell: {
      width: 670,
      minWidth: 670,
    },
    footerEmptyCell: {
      width: 851,
      minWidth: 851,
    },
    tableContainer: {
      overflow: 'hidden',
      border: `1px solid ${grey[300]}`,
      borderRadius: theme.spacing.unit / 2,
      [textElements]: {
        fontSize: '14px',
      },
      '& tfoot': {
        [textElements]: {
          fontWeight: 500,
        },
      },
    },
    tableFooterActiveRow: {
      [textElements]: {
        color: theme.palette.text.primary,
      },
      '& input::placeholder': {
        opacity: 0.8,
      },
    },
    disabledCellContent: {
      color: theme.palette.text.secondary,
    },
    icon: {
      width: 20,
      height: 20,
      '& g': {
        fill: theme.palette.primary.main,
        fillOpacity: 1,
      },
    },
    iconDisabled: {
      width: 20,
      height: 20,
    },
    iconButton: {
      position: 'absolute',
      top: 8,
      left: 110,
      padding: 6,
      width: 32,
      height: 32,
    },
    tooltip: {
      position: 'absolute',
      top: -46,
      left: -75,
      width: 56,
      backgroundColor: theme.palette.grey[600],
    },
    renovationPeriodTotal: {
      width: 32,
      display: 'inline-block',
    },
    renovationPeriodNumberField: {
      width: 24,
    },
  }
}

export const paperStyles = {
  width: 1175,
  marginBottom: 15,
}
