import { get } from 'lodash'
import { createSelector } from 'reselect'

import { ReportTabs } from '../../constants'

export const reportsSelector = createSelector(
  state => {
    const { reports, currentTab } = get(state, 'reports', [])
    const userId = get(state, 'authentication.user._id')

    if (currentTab === ReportTabs.ALL_REPORTS) {
      return reports
    }

    if (currentTab === ReportTabs.MY_REPORTS) {
      const userReports = reports.filter(report => {
        if (!report) {
          return true
        }

        return report.appraisers.some(appraiser => appraiser.id === userId && appraiser.reportVisible)
      })

      return userReports
    }

    return get(state, 'reports.reports', [])
  },
  reports => reports
)
