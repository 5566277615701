import { get, isString, isFunction } from 'lodash'
import { Document } from 'slate'

export const TYPE_TEXT = 'text'
export const TYPE_HEADING = 'heading'

const splitParagraphs = string => string.split('\n').filter(paragraph => !!paragraph)

export const serializeNode = (node, formatter, processOnly = false, listLevel = -1) => {
  const childNodes = get(node, 'nodes', [])
  let processedNodes = []
  if (node.object === 'text') {
    if (processOnly) {
      node.type = 'text'
      if (node.marks) {
        node.marks = node.marks.map(mark => mark.type)
      }
      if (formatter) {
        node.text = formatter(node.text)
      }
      return node
    }

    if (formatter) {
      return formatter(node.text)
    }

    return node.text
  }
  if (node.type === 'locked' && node.object !== 'block') {
    const format = get(node, 'data.format')
    const defaultFormatter = value => value || `[${get(node, 'data.displayName')}]`

    processedNodes = childNodes.flatMap(x =>
      serializeNode(x, isFunction(format) ? format : defaultFormatter, processOnly, listLevel)
    )
    return processedNodes
  }
  if (childNodes.length === 0) {
    return processOnly ? node : ''
  }
  if (node.object === 'inline' && node.type === 'text') {
    processedNodes = childNodes.flatMap(x => serializeNode(x, null, processOnly, listLevel))
    if (processOnly) {
      node.nodes = processedNodes
      return node
    }
    return processedNodes.join('')
  }
  if (node.object === 'inline' && node.type === 'conditional') {
    if (node.data.display) {
      processedNodes = childNodes.flatMap(x => serializeNode(x, null, processOnly, listLevel))
      if (processOnly) {
        node.nodes = processedNodes
        return node
      }
      return processedNodes.join('')
    }
    return ''
  }
  if (node.object === 'block' || node.object) {
    if (processOnly && node.type === 'unordered-list') {
      listLevel++
    }
    processedNodes = childNodes.flatMap(x => serializeNode(x, null, processOnly, listLevel))
    if (processOnly) {
      switch (node.type) {
        case 'unordered-list':
        case 'list-item':
          return processedNodes
        case 'list-item-child':
          node.type = 'list'
          node.nodes = processedNodes
          node.level = listLevel
          return node
        case 'locked':
          if (processedNodes.every(x => x.type === 'paragraph')) {
            return processedNodes
          }
          node.type = 'paragraph'
          node.nodes = processedNodes
          return node
        default:
          node.nodes = processedNodes
          return node
      }
    }
    return node.object === 'block' ? processedNodes.join('') : processedNodes
  }
  return ''
}

export const buildWriteup = generatedText => {
  let writeUp = get(generatedText, 'commentary') || ''
  const additional = get(generatedText, 'additionalCommentary')

  if (isString(additional) && additional.length > 0) {
    writeUp = `${writeUp} ${additional}`
  }

  return splitParagraphs(writeUp)
}

export const buildWriteupAsString = generatedText => {
  let writeUp = get(generatedText, 'commentary') || ''
  const additional = get(generatedText, 'additionalCommentary')

  if (isString(additional) && additional.length > 0) {
    writeUp = `${writeUp} ${additional}`
  }

  return writeUp
}

export const buildSlateWriteup = (generatedText, processOnly = false) => {
  const rootNode = get(generatedText, 'narrative.document')
  if (rootNode) {
    const serializedData = serializeNode(Document.isDocument(rootNode) ? rootNode.toJS() : rootNode, null, processOnly)

    if (isString(serializedData)) {
      return [serializedData]
    }

    if (processOnly) {
      return serializedData.nodes || []
    }

    if (!serializedData.length) {
      return []
    }

    const finalWriteup = []

    serializedData.forEach(line => {
      const brokenLines = line.split('\n\n')
      if (brokenLines.length) {
        finalWriteup.push(...brokenLines)
      }
    })

    return finalWriteup
  }
  return []
}

export const buildSlateWriteupWithDefault = (generatedText, defaultText, processOnly = false) => {
  const gt = buildSlateWriteup(generatedText, processOnly)
  if (gt.length === 0) {
    if (Array.isArray(defaultText)) {
      return buildSlateWriteup(defaultText, processOnly)
    }
    gt.push(defaultText)
  }
  return gt
}

export const wrapStringInSimplifiedSlate = (text, type = TYPE_TEXT) => {
  if (typeof text !== 'string') {
    return text
  }

  if (type.startsWith(TYPE_HEADING)) {
    const level = parseInt(type.slice(-1), 10)
    return {
      object: 'block',
      type: 'heading',
      level: Number.isNaN(level) ? 2 : level,
      nodes: [text],
    }
  }

  return {
    object: 'block',
    type: 'paragraph',
    data: {},
    nodes: [
      {
        object: 'text',
        type: 'text',
        text,
        marks: [],
      },
    ],
  }
}
