const DIALOG_FILTER_TITLE = 'Expense Comp Search'
const DIALOG_UNITS_TITLE = 'Expense Comp Results'

const EXPENSE_COMPS_DEFAULT_FILTERS = [
  {
    name: 'squareFeet',
    title: 'GBA',
    propertyName: 'grossBuildingArea',
    isNumeric: true,
  },
  {
    name: 'yearBuilt',
    title: 'Year Built',
    propertyName: 'yearBuilt',
  },
  {
    name: 'residentialUnits',
    title: 'No. of Residential Units',
    propertyName: 'residentialUnitCount',
    isNumeric: true,
  },
  {
    name: 'totalOperatingExpenses',
    title: 'Total Operating Expense/SF',
    propertyName: 'totalOperatingExpenses',
    isTotal: true,
  },
  {
    name: 'totalOperatingExpensesPerUnit',
    title: 'Total Operating Expense/Unit',
    propertyName: 'totalOperatingExpensesPerUnit',
    isTotal: true,
  },
  {
    name: 'expenseYear',
    title: 'Expense Year',
    propertyName: 'expenseYear',
  },
]
const ALLOWED_PAGE_SIZES = [5, 10, 15]

const EXPENSE_COMPS_TABLE_COLUMNS = [
  { name: 'id', title: 'Comp #' },
  { name: 'type', title: 'Type' },
  { name: 'address', title: 'Address' },
  { name: 'state', title: 'State' },
  { name: 'location', title: 'Location' },
  { name: 'squareFeet', title: 'GBA' },
  { name: 'yearBuilt', title: 'Year Built' },
  {
    name: 'totalOperatingExpenses.sf',
    title: '$/SF',
  },
  {
    name: 'totalOperatingExpenses.unit',
    title: '$/Unit',
  },
  {
    name: 'residentialUnits',
    title: 'No. Of Units',
  },
  { name: 'sourceOfInformation', title: 'Source Of Information' },
  { name: 'expenseYear', title: 'Expense Year' },
  { name: 'expensePeriod', title: 'Expense Period' },
]

const RESULTS_TABLE_COLUMN_HEADERS = {
  ID: 'id',
  TYPE: 'type',
  ADDRESS: 'address',
  STATE: 'state',
  LOCATION: 'location',
  SQFT: 'squareFeet',
  YEAR_BUILT: 'yearBuilt',
  OPERATING_EXPENSES_SF: 'totalOperatingExpenses.sf',
  OPERATING_EXPENSES_UNIT: 'totalOperatingExpenses.unit',
  RESIDENTIAL_UNITS: 'residentialUnits',
  EXPENSE_YEAR: 'expenseYear',
  EXPENSE_PERIOD: 'expensePeriod',
  SOURCE_OF_INFORMATION: 'sourceOfInformation',
}

const SQUARE_FEET_POSTFIX = ' ($/SF)'
const EXPENSE_COMPS_TABLE_EXTENSIONS = [
  { columnName: 'id', width: 100 },
  { columnName: 'address', width: 150 },
  { columnName: 'location', width: 180 },
  { columnName: 'type', width: 80 },
  { columnName: 'sourceOfInformation', width: 170 },
]
export {
  DIALOG_FILTER_TITLE,
  DIALOG_UNITS_TITLE,
  SQUARE_FEET_POSTFIX,
  EXPENSE_COMPS_DEFAULT_FILTERS,
  EXPENSE_COMPS_TABLE_COLUMNS,
  RESULTS_TABLE_COLUMN_HEADERS,
  EXPENSE_COMPS_TABLE_EXTENSIONS,
  ALLOWED_PAGE_SIZES,
}

export const ERROR_MESSAGE = 'Unable to load expense comps. For more information, please contact Cesar Devers.'
