import React from 'react'
import PropTypes from 'prop-types'

import InfoOutlined from '@mui/icons-material/InfoOutlined'
import { Stack, Tooltip, Typography } from '@mui/material'

const LabelWithTooltip = ({ label, tooltipText }) => (
  <Stack alignItems="center" flexDirection="row">
    <Typography variant="subtitle1" sx={{ paddingRight: 1 }}>
      {label}
    </Typography>
    <Tooltip title={tooltipText} placement="right" sx={{ margin: '0' }}>
      <InfoOutlined fontSize="inherit" />
    </Tooltip>
  </Stack>
)

LabelWithTooltip.propTypes = {
  label: PropTypes.string.isRequired,
  tooltipPosition: PropTypes.string,
  tooltipText: PropTypes.string,
}

LabelWithTooltip.propTypes = {
  tooltipPosition: 'right',
}

export default LabelWithTooltip
