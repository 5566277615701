import React from 'react'
import PropTypes from 'prop-types'
import { compose } from 'recompose'
import { connect } from 'react-redux'
import { Grid, Typography, Paper, withStyles } from '@material-ui/core'

import AutomationStatus from 'client-shared/components/AutomationStatus'
import { withAutomatedZoningIsEnabledForReport } from 'report/forms/property/Zoning/Automation'

import NarrativeComponent from 'client-shared/components/NarrativeComponent'

import conformingUseDiscussion from 'shared/utils/textGeneration/property/zoningConformingUseDiscussion'
import { PROPERTY_USES_OPTIONS, USES_OPTIONS } from 'shared/constants/property/zoning'

import MultipleDropDown from '../../../../shared/components/DropDown/MultipleDropDownField'
import { Callout, BooleanRadioButtonList } from '../../../../shared/components'

import { conformingUseSelector } from './selectors'
import {
  USES_CONFORMITY_OPTIONS,
  TEXT_GENERATION_TOOLTIP,
  NJ_STATE_AUTHORITY_LINK,
  NYC_PLANNING_LINK,
  STATE_AUTHORITY,
  NYC_PLANNING,
} from './constants'

const styles = theme => ({
  paper: theme.paper,
  sectionTitle: {
    letterSpacing: 0.2,
    fontSize: 16,
  },
  callout: {
    backgroundColor: theme.palette.primary[50],
    margin: 0,
  },
  checkboxGroupContainer: {
    flexDirection: 'row',
  },
  checkbox: {
    '&$checkedCheckbox': {
      color: `${theme.palette.primary.main}`,
    },
  },
  checkedCheckbox: {},
  link: {
    color: theme.palette.primary.main,
  },
})

class ConformingUse extends React.PureComponent {
  static propTypes = {
    classes: PropTypes.object.isRequired,
    isNYCReport: PropTypes.bool.isRequired,
    isFreddieMacReport: PropTypes.bool.isRequired,
    automatedZoningEnabled: PropTypes.bool.isRequired,
    reportType: PropTypes.string,
    zoneNames: PropTypes.arrayOf(PropTypes.string),
    propertyUses: PropTypes.arrayOf(PropTypes.string),
    permittedUses: PropTypes.arrayOf(PropTypes.string),
    isConformingWithAllowableUses: PropTypes.bool,
    yearBuilt: PropTypes.number,
  }

  getCalloutContent = () => {
    const { classes, isNYCReport, isNJReport } = this.props

    if (!isNYCReport && !isNJReport) {
      return `Visit your ${STATE_AUTHORITY} for information about allowable uses within your zone.`
    }

    const linkPath = isNYCReport ? NYC_PLANNING_LINK : NJ_STATE_AUTHORITY_LINK
    const linkText = isNYCReport ? NYC_PLANNING : STATE_AUTHORITY

    return (
      <React.Fragment>
        Visit your
        <a href={linkPath} target="_blank" rel="noopener noreferrer" className={classes.link}>
          {` ${linkText} `}
        </a>
        for information about allowable uses within your zone.
      </React.Fragment>
    )
  }

  getUsesLabel = value => {
    const commercialUse = USES_OPTIONS.find(option => option.value === value)
    return commercialUse ? commercialUse.label : value
  }

  getPropertyUses = () => {
    const { classes, isNYCReport, automatedZoningEnabled } = this.props

    return !isNYCReport ? (
      <React.Fragment>
        <Typography variant="subtitle2" className={classes.sectionTitle}>
          Property Use(s)
        </Typography>
        {automatedZoningEnabled && <AutomationStatus formPaths={['automationMetadata']} sx={{ margin: '10px 0' }} />}
        <MultipleDropDown
          label="Permitted Use(s)"
          name="permittedUses"
          placeholder="Select Permitted Use"
          items={USES_OPTIONS}
          itemLabelSelector={this.getUsesLabel}
          isNonPredefinedValueAllowable
        />
        <MultipleDropDown
          label="Current Use(s)"
          name="currentUses"
          placeholder="Select Current Use"
          items={USES_OPTIONS}
          itemLabelSelector={this.getUsesLabel}
          isNonPredefinedValueAllowable
        />
      </React.Fragment>
    ) : (
      <MultipleDropDown
        label="Property Use(s)"
        name="propertyUses"
        placeholder="Select Permitted Use"
        items={PROPERTY_USES_OPTIONS}
        itemLabelSelector={this.getUsesLabel}
        isNonPredefinedValueAllowable
      />
    )
  }

  render() {
    const { classes } = this.props

    return (
      <Paper className={classes.paper}>
        <Grid container spacing={16} data-qa="conforming-use-section-tile">
          <Grid item xs={12}>
            <Typography variant="h6">Conforming Use</Typography>
          </Grid>
          <Grid item xs={12}>
            <Callout
              variant="link"
              content={<Typography variant="body1">{this.getCalloutContent()}</Typography>}
              className={classes.callout}
            />
          </Grid>

          <Grid item xs={12}>
            {this.getPropertyUses()}
          </Grid>

          <Grid item xs={12}>
            <BooleanRadioButtonList
              label="Conformity"
              name="isConformingWithAllowableUses"
              items={USES_CONFORMITY_OPTIONS}
            />
          </Grid>

          <Grid item xs={12}>
            <NarrativeComponent
              title="Conforming Use Discussion"
              generatedText={conformingUseDiscussion.generate}
              data={conformingUseDiscussion.mapDTO(this.props)}
              name="conformingUseDiscussion"
              tooltipText={TEXT_GENERATION_TOOLTIP}
            />
          </Grid>
        </Grid>
      </Paper>
    )
  }
}

export default compose(
  withStyles(styles),
  connect(conformingUseSelector),
  withAutomatedZoningIsEnabledForReport
)(ConformingUse)
