import { GeneratedText, gtConditional, gtIfElse, gtLocked, gtTemplate } from 'shared/utils/narrative/GeneratedText'

import { mapDTO, mapDataModel } from './mappings'

const generate = () => {
  const downwardCompsSentence = gtLocked('downwardCompsSentence')
  const upwardCompsSentence = gtLocked('upwardCompsSentence')

  const downwardsMultiples = gtConditional('hasMultipleDownwardAdjustments', gtTemplate`s`)
  const downwardsAdjustmentSentence = gtConditional(
    'hasDownwardAdjustments',
    gtTemplate` Comparable${downwardsMultiples} ${downwardCompsSentence} required ${gtIfElse(
      'hasMultipleDownwardAdjustments',
      gtTemplate``,
      gtTemplate` a`
    )} downward adjustment${downwardsMultiples}.`
  )

  const upwardsMultiples = gtConditional('hasMultipleUpwardAdjustments', gtTemplate`s`)

  const upwardsAdjustmentSentence = gtConditional(
    'hasUpwardAdjustments',
    gtTemplate` Comparable${upwardsMultiples} ${upwardCompsSentence} required${gtIfElse(
      'hasMultipleUpwardAdjustments',
      gtTemplate``,
      gtTemplate` an`
    )} upward adjustment${upwardsMultiples}.`
  )

  const containsAdjustmentsDiscussion = gtTemplate`${upwardsAdjustmentSentence}${downwardsAdjustmentSentence}`
  const doesNotContainAdjustmentsDiscussion = gtTemplate` However, all comparables used in this analysis are 
  considered to be "arm's-length" market transactions between both knowledgeable buyers and sellers on the open 
  market. Therefore, no adjustments were required.`

  const discussion = gtIfElse(
    'shouldGenerateWriteup',
    gtTemplate`${containsAdjustmentsDiscussion}`,
    gtTemplate`${doesNotContainAdjustmentsDiscussion}`
  )

  const gt = new GeneratedText()
  gt.t`Condition of sale refers to the motivations of the buyer and seller 
  involved in a particular transaction.${discussion}`

  return gt.getParts()
}

export default { generate, mapDTO, mapDataModel }
