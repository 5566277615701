import accounting from 'accounting-js'
import { isNaN } from 'lodash'

export default {
  format(currency, fractionSize, symbol) {
    if (currency === undefined || currency === null) {
      return currency
    }

    const formatingCurrency = isNaN(currency) ? 0 : currency

    return accounting.formatMoney(formatingCurrency, {
      symbol: symbol || '$',
      precision: fractionSize || 0,
      thousand: ',',
      decimal: '.',
      format: {
        neg: '-%s%v',
        pos: '%s%v',
        zero: '%s%v',
      },
    })
  },
}
