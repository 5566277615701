import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import { TableCell, withStyles } from '@material-ui/core'
import { Column, AutoSizer, Table } from 'react-virtualized'
import { compose } from 'recompose'
import { toUpper } from 'lodash'

import withForwardingRef from '../../hocs/withForwardingRef'

import TableSortLabel from './TableSortLabel'

const styles = theme => ({
  table: {
    fontFamily: theme.typography.fontFamily,
    '& .ReactVirtualized__Table__headerColumn, .ReactVirtualized__Table__rowColumn': {
      margin: '0 !important',
      outline: 'none',
    },
  },
  flexContainer: {
    display: 'flex',
    alignItems: 'center',
    boxSizing: 'border-box',
  },
  tableRow: {
    margin: 0,
    cursor: 'pointer',
    paddingRight: '0 !important',
  },
  tableRowHover: {
    '&:hover': {
      backgroundColor: theme.palette.grey[200],
    },
  },
  tableCell: {
    flex: 1,
    margin: 0,
  },
  headerTableCell: {
    flex: 1,
    margin: 0,
  },
  noClick: {
    cursor: 'initial',
  },
})

class VirtualizedTable extends React.PureComponent {
  headerCellRenderer =
    columnIndex =>
    ({ label, dataKey }) => {
      const { classes, headerHeight, columns, sort, sortBy, sortDirection } = this.props

      const sortingSettings = {
        sort,
        dataKey,
        active: false,
      }
      if (dataKey === sortBy) {
        sortingSettings.active = true
        sortingSettings.direction = sortDirection
      }
      const tableLabel =
        !columns[columnIndex].disableSort && !!sort ? (
          <TableSortLabel {...sortingSettings}>{label}</TableSortLabel>
        ) : (
          label
        )

      return (
        <TableCell
          variant="head"
          component="div"
          style={{ height: headerHeight }}
          className={classNames(classes.headerTableCell, classes.flexContainer, classes.noClick)}
        >
          {tableLabel}
        </TableCell>
      )
    }

  cellRenderer = ({ cellData }) => {
    const { classes, rowHeight, onRowClick } = this.props

    return (
      <TableCell
        variant="body"
        component="div"
        style={{ height: rowHeight }}
        className={classNames(classes.tableCell, classes.flexContainer, {
          [classes.noClick]: !!onRowClick,
        })}
      >
        {cellData}
      </TableCell>
    )
  }

  getCellRenderer() {
    const { cellRenderer } = this.props

    if (cellRenderer) {
      return cellRendererProps =>
        this.cellRenderer({
          cellData: cellRenderer(cellRendererProps),
        })
    }

    return this.cellRenderer
  }

  getRowClassName = ({ index }) => {
    const { classes, rowClassName, onRowClick } = this.props

    return classNames(classes.tableRow, classes.flexContainer, rowClassName, {
      [classes.tableRowHover]: index !== -1 && !!onRowClick,
    })
  }

  render() {
    const { classes, columns, sort, sortBy, sortDirection, forwardedRef, ...tableProps } = this.props

    return (
      <AutoSizer>
        {({ height, width }) => (
          <Table
            width={width - 2}
            height={height}
            className={classes.table}
            rowClassName={this.getRowClassName}
            sortDirection={toUpper(sortDirection)}
            ref={forwardedRef}
            {...tableProps}
          >
            {columns.map(({ className, dataKey, ...columnProps }, index) => (
              <Column
                key={dataKey}
                dataKey={dataKey}
                cellRenderer={this.getCellRenderer()}
                headerRenderer={this.headerCellRenderer(index)}
                className={classNames(classes.flexContainer, className)}
                {...columnProps}
              />
            ))}
          </Table>
        )}
      </AutoSizer>
    )
  }
}

VirtualizedTable.propTypes = {
  sort: PropTypes.func,
  onRowClick: PropTypes.func,
  rowHeight: PropTypes.number,
  cellRenderer: PropTypes.func,
  headerHeight: PropTypes.number,
  rowClassName: PropTypes.string,
  columns: PropTypes.arrayOf(
    PropTypes.shape({
      cellContentRenderer: PropTypes.func,
      dataKey: PropTypes.string.isRequired,
      width: PropTypes.number.isRequired,
    })
  ).isRequired,
  forwardedRef: PropTypes.oneOfType([PropTypes.func, PropTypes.shape({ current: PropTypes.elementType })]),
}

VirtualizedTable.defaultProps = {
  sort: null,
  rowHeight: 56,
  cellRenderer: null,
  headerHeight: 56,
  onRowClick: () => {},
}

export default compose(withForwardingRef, withStyles(styles))(VirtualizedTable)
