import React from 'react'
import PropTypes from 'prop-types'

import { get } from 'lodash'

import { RESIDENTIAL_BUILDING_RENT_COMPS_PATH } from 'shared/constants/report/keysAndDataPaths'

import wrapForm from '../../../wrapForm'

import { getAddress } from '../../../../../shared/utils/geography'

import ResidentialBuildingRentCompsMap from './ResidentialBuildingRentCompsMap'
import { HEADING } from './ResidentialBuildingRentCompsMapConstants'

export const DATA_PATH = RESIDENTIAL_BUILDING_RENT_COMPS_PATH

class ResidentialBuildingRentCompsMapContainer extends React.PureComponent {
  static propTypes = {
    subject: PropTypes.shape({
      coords: PropTypes.arrayOf(PropTypes.number).isRequired,
      addressOne: PropTypes.string.isRequired,
      addressTwo: PropTypes.string.isRequired,
    }).isRequired,
  }

  render() {
    const { subject, form } = this.props
    const { buildings, mapUrl } = form.values

    const buildingComps = buildings.map(({ coords, address = '', cityStateZip }) => {
      const streetName = get(address.split(','), '[0]')

      return {
        coords,
        address: { addressOne: streetName, addressTwo: cityStateZip },
      }
    })

    return <ResidentialBuildingRentCompsMap subject={subject} buildings={buildingComps} mapUrl={mapUrl} />
  }
}

export default wrapForm(DATA_PATH, { heading: HEADING }, state => {
  const reportData = get(state, 'report.reportData')
  const streetAddress = get(reportData, 'propertyInformation.propertySummary.streetAddress')
  const city = get(reportData, 'propertyInformation.propertySummary.city')
  const zip = get(reportData, 'zip')
  const propertyState = get(reportData, 'state')

  const address = getAddress(streetAddress, city, propertyState, zip)
  const coords = get(reportData, 'propertyInformation.coords')
  return {
    subject: {
      coords: [coords.latitude, coords.longitude],
      ...address,
    },
  }
})(ResidentialBuildingRentCompsMapContainer)
