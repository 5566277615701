import React from 'react'
import PropTypes from 'prop-types'
import { compose } from 'recompose'
import { connect } from 'react-redux'
import { Typography, Table, TableHead, TableBody, TableRow, TableCell } from '@mui/material'
import { Grid, withStyles } from '@material-ui/core'

import { ANCHOR_SITE_DETAILS } from 'shared/constants/report/dataCollections'

import { NarrativeComponent, UneditableSection } from '../../../../shared/components'

import propertyIdentificationDiscussion from '../../../../../../shared/utils/textGeneration/property/propertyIdentificationDiscussion'
import zoningSummaryIntroduction from '../../../../../../shared/utils/textGeneration/property/zoningSummaryIntroduction'

import { propertyIdentificationSelector } from './selectors'
import { NJ_REPORT_DISCUSSION_TOOLTIP, FREDDIE_REPORT_DISCUSSION_TOOLTIP } from './constants'
import { getPropertyIdentificationTableData } from './tools'

const styles = theme => ({
  table: {
    border: `1px solid ${theme.palette.secondary[700]}`,
    backgroundColor: '#F4F4F4',
    borderCollapse: 'inherit',
    borderRadius: 5,
    '& th': {
      color: theme.palette.secondary[800],
    },
    '& td': {
      color: theme.palette.secondary[600],
      borderTop: `1px solid ${theme.palette.secondary[700]}`,
    },
    '& th, & td': {
      '&:last-child': {
        textAlign: 'right',
      },
    },
  },
  sectionTitle: {
    letterSpacing: 0.2,
    fontSize: 16,
  },
})

class PropertyIdentification extends React.PureComponent {
  static propTypes = {
    form: PropTypes.object.isRequired,
    classes: PropTypes.object.isRequired,
    isFreddieMacReport: PropTypes.bool.isRequired,
    address: PropTypes.shape({
      borough: PropTypes.string,
      county: PropTypes.string,
      city: PropTypes.string,
      block: PropTypes.number,
      lot: PropTypes.string,
      locationIdentifier: PropTypes.string.isRequired,
    }).isRequired,
    zones: PropTypes.array.isRequired,
    fullAddress: PropTypes.string.isRequired,
  }

  renderPropertyIdentificationTable = () => {
    const { classes, siteArea, address, siteAreaMeasure } = this.props

    const data = getPropertyIdentificationTableData({ siteArea, siteAreaMeasure, address })

    return (
      <Table data-qa="propertyIdentification-table" className={classes.table}>
        <TableHead>
          <TableRow>
            {data.order.map(key => (
              <TableCell key={key} data-qa={`${key}-cell`}>
                {data.headers[key]}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          <TableRow>
            {data.order.map(key => (
              <TableCell key={key} data-qa={`${key}-cell`}>
                {data.values[key]}
              </TableCell>
            ))}
          </TableRow>
        </TableBody>
      </Table>
    )
  }

  render() {
    const { form, classes, isFreddieMacReport, address, fullAddress, siteAreaMeasure, currentPath } = this.props
    const tooltipText = isFreddieMacReport ? FREDDIE_REPORT_DISCUSSION_TOOLTIP : NJ_REPORT_DISCUSSION_TOOLTIP
    return (
      <React.Fragment>
        <Grid item xs={12}>
          <Typography variant="subtitle2" className={classes.sectionTitle}>
            Property Identification
          </Typography>
        </Grid>

        <Grid item xs={12}>
          <UneditableSection
            name="Found In: Subject Property Data"
            formName="Subject Property Data"
            link="subject-property"
            anchor={ANCHOR_SITE_DETAILS}
            returnDataPath={currentPath}
            editDataMessage
          >
            {this.renderPropertyIdentificationTable()}
          </UneditableSection>
        </Grid>

        <Grid item xs={12}>
          <NarrativeComponent
            name="propertyIdentificationDiscussion"
            title="Legal Property Identification Discussion"
            generatedText={propertyIdentificationDiscussion.generate}
            data={propertyIdentificationDiscussion.mapDTO({ siteAreaMeasure, address, ...form.values })}
            tooltipText={tooltipText}
          />
        </Grid>
        <Grid item xs={12}>
          <NarrativeComponent
            name="zoningSummaryIntroduction"
            title="Zoning Summary Introduction"
            generatedText={zoningSummaryIntroduction.generate}
            data={zoningSummaryIntroduction.mapDTO({ fullAddress, ...form.values })}
            tooltipText="The generated text and your appraiser commentary will appear in the Zoning Summary of the report."
          />
        </Grid>
      </React.Fragment>
    )
  }
}

export default compose(withStyles(styles), connect(propertyIdentificationSelector))(PropertyIdentification)
