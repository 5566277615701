import * as colors from '@mui/material/colors'
import { alpha } from '@mui/material/styles'

const onHover = (color: string) => {
  return alpha(color, 0.08)
}

const onPress = (color: string) => {
  return alpha(color, 0.16)
}

const legacyEffects = { onHover, onPress }

const palette = {
  primary: {
    main: 'rgba(66, 96, 211, 1)',
    light: 'rgba(178, 195, 254, 1)',
    dark: 'rgba(3, 24, 110, 1)',
  },
  secondary: {
    main: 'rgba(46, 65, 84, 1)',
  },
  success: {
    main: 'rgba(32, 181, 134, 1)',
    light: 'rgba(197, 237, 224, 1)',
    dark: 'rgba(6, 116, 97, 1)',
  },
  info: {
    main: 'rgba(33, 150, 243, 1)',
    light: 'rgba(100, 182, 247, 1)',
    dark: 'rgba(11, 121, 208, 1)',
  },
  warning: {
    main: 'rgba(255, 195, 85, 1)',
    light: 'rgba(255, 228, 178, 1)',
    dark: 'rgba(229, 148, 35, 1)',
  },
  error: {
    main: 'rgba(211, 65, 65, 1)',
    light: 'rgba(255, 179, 178, 1)',
    dark: 'rgba(149, 27, 27, 1)',
  },
  other: {
    outerBorder: 'rgba(34, 34, 34, 0.23)',
    inputLine: 'rgba(34, 34, 34, 0.42)',
    backdropOverlay: 'rgba(34, 34, 34, 0.5)',
    ratingActive: 'rgba(255, 195, 85, 1)',
    snackbarBackground: 'rgba(34, 34, 34, 1)',
    card: 'rgba(34, 34, 34, 1)',
  },
  colors,
  ...legacyEffects,
}

export type Palette = typeof palette

export default palette
