import createDecorator from 'final-form-calculate'
import { get } from 'lodash'

export default [
  createDecorator({
    field: ['conclusion.asIsEntrepreneurialIncentive'],
    updates: (value, name, allValues) => {
      const constructionCostsSpentToDate = get(allValues, 'subjectBudget.costsToDate.totalCosts')
      const asIsEntrepreneurialIncentive = get(allValues, 'conclusion.asIsEntrepreneurialIncentive')

      return {
        'conclusion.entrepreneurialIncentiveValue': constructionCostsSpentToDate * asIsEntrepreneurialIncentive,
      }
    },
  }),
]
