import SalesCompsMap from '../../../forms/sales/LandValuation/SalesCompsMap'
import SalesAdjustmentGrid from '../../../forms/sales/LandValuation/SalesAdjustmentGrid'
import SaleValueConclusion from '../../../forms/sales/LandValuation/SaleValueConclusion'
import SalesCompsSearch from '../../../forms/sales/LandValuation/SalesCompsSearch'

export default [
  {
    key: 'landValuation',
    name: 'Land Valuation',
  },
  {
    key: 'landSalesCompsSearch',
    name: 'Find Comps',
    formComponent: SalesCompsSearch,
  },
  {
    key: 'landSalesAdjustmentGrid',
    name: 'Adjust Comps',
    formComponent: SalesAdjustmentGrid,
  },
  {
    key: 'landSalesCompsMap',
    name: 'Create Comp Map',
    formComponent: SalesCompsMap,
  },

  {
    key: 'landSaleValueConclusion',
    name: 'Value Conclusion',
    formComponent: SaleValueConclusion,
  },
]
