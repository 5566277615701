import { invert } from 'lodash'

import { PERIOD_BEDROOM_TYPES } from '../../../../controllers/report/incomeApproach/miscellaneousIncome/constants'

import { ParkingUnit, OtherIncome } from './types'

const ANNUAL_MULTIPLIER = 12
const INVERTED_PERIOD_BEDROOM_TYPES = invert(PERIOD_BEDROOM_TYPES)

export default {
  calculateMiscellaneousIncome(
    parkingIncome: number,
    laundryIncome: number,
    storageIncome: number,
    otherIncome: number,
    otherResidentialIncome: number
  ): number {
    return parkingIncome + laundryIncome + storageIncome + otherIncome + otherResidentialIncome
  },
  calculateMiscellaneousVCLoss(
    parkingVCLoss: number = 0,
    storageVCLoss: number = 0,
    laundryVCLoss: number = 0,
    totalOtherVCLoss: number = 0
  ): number {
    return parkingVCLoss + storageVCLoss + laundryVCLoss + totalOtherVCLoss
  },
  calculateParkingIncome(units: ParkingUnit[], parkingSpaces: number, perSpaceMonthlyRent: number): number {
    let totalParkingIncome = 0
    if (parkingSpaces) {
      if (units) {
        totalParkingIncome = units.reduce((sum, unit) => sum + (unit ? (unit.rent || 0) * 12 : 0), 0)
      } else if (perSpaceMonthlyRent) {
        totalParkingIncome = perSpaceMonthlyRent * parkingSpaces * 12
      }
    }
    return totalParkingIncome
  },
  calculateParkingVCRate(vcType: string, residentialVc: number, parkingVc: number): number {
    return vcType === 'residential' ? residentialVc : parkingVc
  },
  calculateParkingVCLoss(annualParkingIncome: number, parkingVCLossPercentage: number): number {
    return annualParkingIncome * parkingVCLossPercentage || 0
  },
  calculateStorageIncome(storageUnits: number, storageIncomePerYear: boolean, storageIncomeValue: number): number {
    if (!storageUnits || !storageIncomeValue) {
      return 0
    }
    return storageIncomePerYear ? storageIncomeValue : storageIncomeValue * storageUnits * 12
  },
  calculateStorageVCRate(vcType: string, residentialVc: number, storageVc: number): number {
    return vcType === 'residential' ? residentialVc : storageVc
  },
  calculateStorageVCLoss(storageIncome: number, storageVCLossPercentage: number): number {
    return storageIncome * storageVCLossPercentage || 0
  },
  calculateLaundryIncome(laundryIncome: number, laundryIncomeType: string, unitCount: number): number {
    if (laundryIncome) {
      /* PERIOD_BEDROOM_TYPES are the values while on client. INVERTED_PERIOD_BEDROOM_TYPES are from server */
      switch (laundryIncomeType) {
        case PERIOD_BEDROOM_TYPES.year:
        case INVERTED_PERIOD_BEDROOM_TYPES[PERIOD_BEDROOM_TYPES.year]:
          return laundryIncome

        case PERIOD_BEDROOM_TYPES.month_unit:
        case INVERTED_PERIOD_BEDROOM_TYPES[PERIOD_BEDROOM_TYPES.month_unit]:
          return laundryIncome * unitCount * ANNUAL_MULTIPLIER

        case PERIOD_BEDROOM_TYPES.month_total:
        case INVERTED_PERIOD_BEDROOM_TYPES[PERIOD_BEDROOM_TYPES.month_total]:
          return laundryIncome * ANNUAL_MULTIPLIER

        default:
          return laundryIncome
      }
    }
    return 0
  },
  calculateLaundryVCRate(vcType: string, residentialVc: number, laundryVc: number): number {
    return vcType === 'residential' ? residentialVc : laundryVc
  },
  calculateLaundryVCLoss(laundryIncome: number, laundyVCLossPercentage: number): number {
    return laundryIncome * laundyVCLossPercentage || 0
  },
  calculateTotalOtherIncome(otherIncome: number, otherResidentialIncome: number): number {
    return otherIncome + otherResidentialIncome
  },
  calculateTotalOtherVCLosses(
    otherIncome: OtherIncome[],
    residentialVCLossPercentage: number,
    otherResidentialIncome: number
  ): number {
    const otherVCLoss = otherIncome.reduce((totalOtherLoss, otherIncItem) => {
      if (otherIncItem.vcType === 'other') {
        const { amount, vcLoss } = otherIncItem
        const otherLoss = amount * vcLoss || 0
        return totalOtherLoss + otherLoss
      }
      return totalOtherLoss
    }, 0)

    const otherResidentialVCLoss = otherResidentialIncome * residentialVCLossPercentage
    return otherVCLoss + otherResidentialVCLoss
  },
  calculateOtherIncome(otherIncome: OtherIncome[]): number {
    return otherIncome.reduce((totalOtherIncome, otherIncItem) => {
      if (otherIncItem.vcType === 'other') {
        const totalAmount = otherIncItem.amount || 0
        return totalOtherIncome + totalAmount
      }
      return totalOtherIncome
    }, 0)
  },
  calculateOtherResidentialIncome(otherIncome: OtherIncome[]) {
    return otherIncome.reduce((totalOtherIncome, otherIncItem) => {
      if (otherIncItem.vcType === 'residential') {
        const totalAmount = otherIncItem.amount || 0
        return totalOtherIncome + totalAmount
      }
      return totalOtherIncome
    }, 0)
  },
}
