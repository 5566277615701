import { ATOMIC_UNIT_TYPES } from 'shared/constants/report/genericSubsection'

import { AtomicUnit, NarrativeAtomicUnit } from './types'

const NAME_REGEX = /^[a-z0-9_.+-]+@[a-z0-9_.+-]+?\.com-.+$/i

/**
 * Remove email address from lorem name.
 * NOTE: for simplicity only works with .com emails
 * @param {String} name
 * @returns {String}
 */
export const removeUserName = (name: string) => {
  // roughly *@*.com-* (see regex for actual allowed characters)
  if (NAME_REGEX.test(name)) {
    const parts = name.split('.com-')
    parts.shift()
    return parts.join('')
  }

  return name
}

export const isNarrativeAtomicUnit = (atomicUnit: AtomicUnit): atomicUnit is NarrativeAtomicUnit => {
  if (!atomicUnit.type) {
    return true
  }

  return atomicUnit.type === ATOMIC_UNIT_TYPES.NARRATIVE
}
