import React from 'react'

import { get } from 'lodash'

import wrapForm from 'report/forms/wrapForm'
import { RECONCILIATION_APPROACH } from 'client-shared/constants'
import { PROPERTY_SALES_CONCLUSION_PATH } from 'shared/constants/report/keysAndDataPaths'

import PropertySalesConclusion from './PropertySalesConclusion'

export const DATA_PATH = PROPERTY_SALES_CONCLUSION_PATH
const heading = 'Property Sales Conclusion'

const PropertySalesConclusionContainer = props => <PropertySalesConclusion {...props} />

export default wrapForm(DATA_PATH, { heading }, state => {
  const approachSelected = get(state, 'report.reportData.final.finalValuesAndReconciliation.approachSelected')
  const { acas } = get(state, 'report.reportData')
  let data = {}
  switch (approachSelected) {
    case RECONCILIATION_APPROACH.SALES:
      data = acas.salesApproach
      break
    case RECONCILIATION_APPROACH.INCOME:
      data = acas.incomeApproach
      break
    case RECONCILIATION_APPROACH.LAND:
      data = acas.landValuation
      break
    case RECONCILIATION_APPROACH.COST:
      data = acas.costApproach
      break
    default:
      break
  }

  return {
    finalValue: get(data, 'finalValueAsIsRoundedUnformatted', 0),
  }
})(PropertySalesConclusionContainer)
