import { put, takeLatest } from 'redux-saga/effects'

import * as Api from '../../../../../report/api'

import {
  pullReportSettings as pullReportSettingsAction,
  reportSettingsFetchRequest,
  reportSettingsFetchReceive,
  reportSettingsFetchFail,
} from './actions'

function* pullReportSettings({ payload }) {
  try {
    const { reportNumber } = payload

    yield put(reportSettingsFetchRequest())
    const fetchedSettings = yield Api.fetchReportSettingsByReportNumber(reportNumber)

    yield put(reportSettingsFetchReceive({ fetchedSettings }))
  } catch (err) {
    yield put(reportSettingsFetchFail(err))
  }
}

export default [takeLatest(pullReportSettingsAction, pullReportSettings)]
