import React from 'react'
import { get } from 'lodash'
import PropTypes from 'prop-types'
import { compose } from 'recompose'
import classnames from 'classnames'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import { withStyles } from '@material-ui/core/styles'
import { InfiniteLoader } from 'react-virtualized'

import { ReportStates } from 'shared/constants'

import { ReportTabs } from '../../constants'
import { Loading } from '../../../shared/components'
import Skeleton from '../../../shared/components/Skeleton'
import ReportStatus from '../../../shared/components/ReportStatus'
import VirtualizedTable from '../../../shared/components/VirtualizedTable'

import { fetchMoreReports, changeReportSorting, changeReportFilters, initialReportsFetch } from '../../redux/actions'

import PropertyTypeIcon from '../../../shared/components/PropertyTypeIcon'

import { reportsSelector } from './selectors'
import ReportActions from './ReportActions'

import AppraisersList from './AppraisersList'
import ReportsFiltersRow from './ReportsFiltersRow'
import { getDueDateValue, getInspectionDateValue } from './helpers/dateHelper'
import {
  TABLE_COLUMNS,
  TableColumnKeys,
  TABLE_ROW_HEIGHT,
  INFINITE_SCROLL_PREFETCH_THRESHOLD,
  MIN_LOADER_BATCH_SIZE,
} from './constants'

const styles = theme => ({
  root: {
    marginTop: 21,
    backgroundColor: theme.palette.common.white,
    height: 'calc(100vh - 212px)',
    '& .ReactVirtualized__Grid': {
      outline: 'none',
    },
    '& .ReactVirtualized__Grid__innerScrollContainer': {
      paddingBottom: 2,
    },
    '& .ReactVirtualized__Table $headerRow.ReactVirtualized__Table__headerRow': {
      paddingRight: 0,
    },
    '& [role=rowgroup]::-webkit-scrollbar': {
      width: 9,
    },
    '& [role=rowgroup]::-webkit-scrollbar-thumb': {
      height: 40,
      borderRadius: 5,
      backgroundColor: theme.palette.secondary[500],
      backgroundClip: 'padding-box',
      border: `2px solid ${theme.palette.secondary[200]}`,
    },
    '& [role=rowgroup]::-webkit-scrollbar-track': {
      backgroundColor: theme.palette.secondary[200],
      marginBottom: 2,
    },
    '& $grid::-webkit-scrollbar-track': {
      marginTop: TABLE_ROW_HEIGHT,
    },
    borderRadius: 5,
    border: 'solid 1px rgba(0, 0, 0, 0.12)',
  },
  grid: {
    paddingTop: TABLE_ROW_HEIGHT,
  },
  tableRowHover: {
    outline: 'none',
    '&:hover $reportActions': {
      visibility: 'visible',
    },
  },
  headerRow: {
    backgroundColor: '#f5f5f5',
    borderRadius: '5px 5px 0 0',
  },
  headerTableCell: {
    fontSize: 12,
    lineHeight: 1.33,
    overflow: 'hidden',
    letterSpacing: 0.4,
    textTransform: 'none',
    color: 'rgba(0, 0, 0, 0.6)',
  },
  tableCell: {
    cursor: 'pointer',
    fontSize: 14,
    lineHeight: 1.43,
    overflow: 'hidden',
    letterSpacing: 0.3,
    color: 'rgba(0, 0, 0, 0.87)',
  },
  cellText: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  propertyTypeIcon: {
    cursor: 'pointer',
    fill: 'rgba(0, 0, 0, 0.54)',
  },
  highlightedText: {
    color: 'red',
  },
  disabledText: {
    color: 'rgba(0, 0, 0, 0.38)',
  },
  tooltip: {
    top: '10px !important',
  },
  noRowsContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100%',
    fontSize: 14,
    lineHeight: 1.43,
    letterSpacing: 0.3,
    textAlign: 'center',
    color: 'rgba(0, 0, 0, 0.87)',
  },
  searchAllReports: {
    color: '#303f9f',
    cursor: 'pointer',
    marginLeft: 3,
  },
  reportActions: {
    visibility: 'hidden',
  },
  loading: {
    height: '100%',
  },
  loadingWithoutFilters: {
    marginTop: 50,
    height: 'calc(100% - 50px)',
  },
})

const headerRowStyles = {
  borderBottom: '1px solid rgba(0, 0, 0, 0.12)',
}
class ReportsTable extends React.PureComponent {
  static propTypes = {
    sortBy: PropTypes.string,
    reports: PropTypes.array,
    totalCount: PropTypes.number,
    sortDirection: PropTypes.string,
    shouldResetScroll: PropTypes.bool,
    showLoading: PropTypes.bool.isRequired,
    showFilter: PropTypes.bool.isRequired,
    fetchMoreReports: PropTypes.func.isRequired,
    currentTab: PropTypes.string.isRequired,
    changeCurrentTab: PropTypes.func.isRequired,
    initialReportsFetch: PropTypes.func.isRequired,
    changeReportFilters: PropTypes.func.isRequired,
    openReportModal: PropTypes.func.isRequired,
  }

  static defaultProps = {
    sortBy: null,
    reports: [],
    totalCount: 0,
    sortDirection: null,
    shouldResetScroll: null,
  }

  tableRef = React.createRef()

  componentDidMount() {
    const { initialReportsFetch } = this.props

    initialReportsFetch()
  }

  componentDidUpdate() {
    const { shouldResetScroll } = this.props

    if (shouldResetScroll) {
      this.tableRef.current.scrollToRow(0)
    }
  }

  headerRowRenderer = ({ className, columns, style }) => {
    const { classes, changeReportFilters, showFilter } = this.props

    return (
      <React.Fragment>
        <div role="row" className={classnames(className, classes.headerRow)} style={{ ...style, ...headerRowStyles }}>
          {columns}
        </div>
        {showFilter && <ReportsFiltersRow changeReportFilters={changeReportFilters} />}
      </React.Fragment>
    )
  }

  cellRenderer = ({ rowData, dataKey }) => {
    const { classes, openReportModal } = this.props

    switch (dataKey) {
      case TableColumnKeys.PROPERTY_TYPE: {
        return (
          <PropertyTypeIcon
            propertyType={rowData[dataKey]}
            tooltipPlacement="top-start"
            classes={{ typeIcon: classes.propertyTypeIcon }}
          />
        )
      }

      case TableColumnKeys.REPORT_CLIENTS: {
        if (!rowData[dataKey] || rowData[dataKey].length === 0) {
          return '-'
        }
        return (
          <span className={classes.cellText}>
            {rowData[dataKey]
              .filter(client => !!client.client)
              .map(client => {
                return client.client
              })
              .join(', ')}
          </span>
        )
      }

      case TableColumnKeys.INSPECTION_DATE: {
        if (!rowData[dataKey]) {
          return <span className={classes.highlightedText}> Not Scheduled </span>
        }

        return getInspectionDateValue(rowData[dataKey])
      }
      case TableColumnKeys.DUE_DATE: {
        const isReportSubmitted = rowData.status === ReportStates.SUBMITTED

        if (!rowData[dataKey]) {
          return '-'
        }

        const { highlight, value } = getDueDateValue(rowData[dataKey], isReportSubmitted)

        return (
          <span
            className={classnames({
              [classes.highlightedText]: highlight,
              [classes.disabledText]: isReportSubmitted,
            })}
          >
            {value}
          </span>
        )
      }
      case TableColumnKeys.STATUS: {
        let status = rowData[dataKey]
        if (status === ReportStates.DRAFT && rowData.isSystemGenerated) {
          status = 'Automated'
        }
        return <ReportStatus status={status} />
      }
      case TableColumnKeys.APPRAISERS: {
        return <AppraisersList appraisers={rowData[dataKey]} />
      }
      case TableColumnKeys.ACTIONS: {
        return (
          <ReportActions
            classes={{ reportActions: classes.reportActions, tooltip: classes.tooltip }}
            reportData={rowData}
            openReportModal={openReportModal}
          />
        )
      }
      default:
        return <span className={classes.cellText}> {rowData[dataKey] || '-'} </span>
    }
  }

  onSearchAllReportsClick = event => {
    const { changeCurrentTab } = this.props

    changeCurrentTab(event, ReportTabs.ALL_REPORTS)
  }

  noRowsRenderer = () => {
    const { classes, showLoading, currentTab } = this.props

    return (
      !showLoading && (
        <div className={classes.noRowsContainer}>
          <span> No results match your search.</span>
          {currentTab === ReportTabs.MY_REPORTS && (
            <span className={classes.searchAllReports} onClick={this.onSearchAllReportsClick}>
              Search All Reports.
            </span>
          )}
        </div>
      )
    )
  }

  sortReports = ({ sortBy, sortDirection }) => {
    const { changeReportSorting } = this.props

    changeReportSorting({ sortBy, sortDirection })
  }

  getRow = ({ index }) => {
    const { reports } = this.props

    return reports[index] || {}
  }

  rowRenderer = ({ className, columns, index, key, rowData, style }) => {
    const { reports } = this.props

    const a11yProps = { 'aria-rowindex': index + 1 }

    if (!reports[index]) {
      return (
        <div {...a11yProps} className={className} key={key} role="row" style={style}>
          <Skeleton variant="rect" width="100%" height={47} />
        </div>
      )
    }

    return (
      <Link to={`report/${rowData._id}`} {...a11yProps} className={className} key={key} role="row" style={style}>
        {columns}
      </Link>
    )
  }

  loadMoreRows = ({ startIndex, stopIndex }) => {
    const { fetchMoreReports } = this.props

    return new Promise((resolve, reject) => {
      fetchMoreReports({
        indexRange: {
          startIndex,
          stopIndex,
        },
        succeed: resolve,
        fail: reject,
      })
    })
  }

  isRowLoaded = ({ index }) => {
    const { reports } = this.props

    return index < reports.length && reports[index] !== null
  }

  render() {
    const { classes, showFilter, sortBy, sortDirection, showLoading, totalCount } = this.props

    return (
      <div className={classes.root}>
        {showLoading && (
          <Loading
            classes={{
              root: classnames({ [classes.loading]: showFilter, [classes.loadingWithoutFilters]: !showFilter }),
            }}
          />
        )}
        <InfiniteLoader
          rowCount={totalCount}
          threshold={INFINITE_SCROLL_PREFETCH_THRESHOLD}
          isRowLoaded={this.isRowLoaded}
          loadMoreRows={this.loadMoreRows}
          minimumBatchSize={MIN_LOADER_BATCH_SIZE}
        >
          {({ onRowsRendered, registerChild }) => (
            <VirtualizedTable
              sortBy={sortBy}
              ref={ref => {
                registerChild(ref)
                this.tableRef.current = ref
              }}
              rowGetter={this.getRow}
              sort={this.sortReports}
              columns={TABLE_COLUMNS}
              rowCount={totalCount}
              rowHeight={TABLE_ROW_HEIGHT}
              sortDirection={sortDirection}
              rowRenderer={this.rowRenderer}
              headerHeight={TABLE_ROW_HEIGHT}
              onRowsRendered={onRowsRendered}
              cellRenderer={this.cellRenderer}
              estimatedRowSize={TABLE_ROW_HEIGHT}
              noRowsRenderer={this.noRowsRenderer}
              headerRowRenderer={this.headerRowRenderer}
              gridClassName={classnames({ [classes.grid]: showFilter })}
              classes={{
                tableCell: classes.tableCell,
                tableRowHover: classes.tableRowHover,
                headerTableCell: classes.headerTableCell,
              }}
            />
          )}
        </InfiniteLoader>
      </div>
    )
  }
}

export default compose(
  connect(
    state => ({
      showLoading: get(state, 'reports.showLoading'),
      reports: reportsSelector(state),
      totalCount: get(state, 'reports.totalCount'),
      sortBy: get(state, 'reports.sorting.sortBy'),
      sortDirection: get(state, 'reports.sorting.sortDirection'),
      shouldResetScroll: get(state, 'reports.isInitialFetchRequest'),
    }),
    { fetchMoreReports, initialReportsFetch, changeReportSorting, changeReportFilters }
  ),
  withStyles(styles)
)(ReportsTable)
