import React from 'react'
import { compose } from 'recompose'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { withRouter } from 'react-router'
import { get } from 'lodash'
import { datadogLogs } from '@datadog/browser-logs'
import { datadogRum } from '@datadog/browser-rum'

import { errorNotification } from '../shared/redux/actions/notifications'
import { SCRIPT_LOADING_STATUS } from '../shared/hooks/useScript'

import useExternalApiScripts from './hooks/useExternalApiScripts'
import AppSwitch from './AppSwitch'

const AuthedUser = ({ errorNotification, location, user }) => {
  const externalApiScriptsStatus = useExternalApiScripts(errorNotification)

  datadogLogs.addLoggerGlobalContext('userId', user.id)
  datadogRum.setUser({
    id: user.id,
    name: user.fullName,
    email: user.username,
  })

  return (
    <>
      {(externalApiScriptsStatus === SCRIPT_LOADING_STATUS.READY ||
        externalApiScriptsStatus === SCRIPT_LOADING_STATUS.ERROR) && <AppSwitch location={location} />}
    </>
  )
}

AuthedUser.propTypes = {
  errorNotification: PropTypes.func.isRequired,
  location: PropTypes.object.isRequired,
}

const mapDispatchToProps = dispatch => ({
  errorNotification: error => dispatch(errorNotification({ message: error })),
})

const mapStateToProps = state => {
  const authentication = get(state, 'authentication')
  const user = get(authentication, 'user', {})

  return { user }
}

export default compose(
  // withRouter must be the first hoc composed here
  // https://github.com/remix-run/react-router/blob/v4.1.1/packages/react-router/docs/guides/blocked-updates.md
  withRouter,
  connect(mapStateToProps, mapDispatchToProps)
)(AuthedUser)
