import { arrayToKeyValuePairs } from '../../../../../shared/utils/ui/checkboxHelper'

export const SELECTED_BUILDING_COMP_LIST_LABEL = 'Selected Building Residential Comparables Summary'

export const REMOVED_BUILDING_COMP_LIST_LABEL = 'Removed Building Comparables'

export const FILTERS_BUTTON_LABEL = 'Filters'
export const SEARCH_RESULTS_LABEL = 'Search Results'
export const HIDE_REMOVED_LABEL = 'Hide Removed'
export const SHOW_REMOVED_LABEL = 'Show Removed'
export const CLEAR_ALL_LABEL = 'Clear All'
export const CLOSE_LABEL = 'Close'
export const CANCEL_LABEL = 'Cancel'
export const SEARCH_LABEL = 'Search'
export const NO_SEARCH_RESULTS_LABEL = 'No Search Results Available'
export const RESTRICTED_FILTER_LABEL = 'Your filters may be too restricted'
export const NO_UNIT_MIX_DATA_LABEL = 'No Unit Mix Data Available'
export const SUBJECT_LABEL = 'Subject'

export const MARKET_RATE_LABEL = 'Market Rate'

export const RENT_PER_SF_LABEL = 'Rent / SF'
export const SUMMARY_LABEL = 'Summary'
export const RENT_ROLL_LABEL = 'Rent Roll'

export const RENT_TYPES = ['unit', 'sf']

export const SORT_TYPES = ['Newest', 'Oldest', 'Smallest', 'Largest']
export const SORT_TYPES_LIST = arrayToKeyValuePairs(SORT_TYPES)
export const DEFAULT_SORT_TYPE = 'Newest'

export const FilterLabels = {
  UNIT_TYPES: 'Unit Type',
  UNIT_TYPES_PLACEHOLDER: 'Select Unit Types',
  BEDROOM_COUNTS: 'Bedrooms',
  BEDROOM_COUNTS_PLACEHOLDER: 'Select # of Bedrooms',
  SOURCE_OF_INFORMATION: 'Source of Information',
  SOURCE_OF_INFORMATION_PLACEHOLDER: 'Select',
  UNIT_AMENITIES: 'Amenities',
  UNIT_AMENITIES_PLACEHOLDER: 'Select Unit Amenities',
  MIN_RENT: 'Min Rent',
  MAX_RENT: 'Max Rent',
  MIN_SF: 'Min SF',
  MAX_SF: 'Max SF',
  MIN_RENT_PLACEHOLDER: '$0',
  MAX_RENT_PLACEHOLDER: '$5,000',
  MIN_SF_PLACEHOLDER: '0',
  MAX_SF_PLACEHOLDER: '5,000',
  LEASED_WITHING: 'Leased within',
  RESET_FILTERS: 'Reset filters',
  NO_UNIT_TYPES: 'No Options Available',
  NO_SOURCE_OF_INFORMATION: 'No Source Of Information Available',
  NO_BEDROOM_COUNTS: 'No Bedroom Counts Available',
  NO_UNIT_AMENITIES: 'No Unit Amenities Available',
}

export const RentCompSearchTypes = [
  { label: 'unit', value: 'Per Unit' },
  { label: 'building', value: 'Per Building' },
]

export const RentCompItemLabels = {
  ADD_COMP: 'Select',
  REMOVE_COMP: 'Remove',
}

export const ConditionTitles = {
  BEDROOMS: 'bed',
  BATHROOMS: 'bath',
  ELEVATOR: 'Elevator',
}

export const RentCompsListProps = {
  height: 669,
  width: 546,
  rowHeight: 158,
  overscanRowCount: 0,
}

export const PropertiesSearchResultsColumnNames = {
  ADDRESS: 'address',
  UNITS: 'units',
  BEDROOM_COUNTS: 'bedroomCounts',
  AVERAGE_RENT: 'averageRent',
  SUB_TYPE: 'subType',
  AMENITIES: 'amenities',
  ACTIONS: 'actions',
}

export const UnitMixColumnNames = {
  IMAGE: 'image',
  UNIT_GROUP_NAME: 'unitGroupName',
  UNIT_COUNT: 'units',
  AVG_ROOMS: 'avgRooms',
  AVG_BATHROOMS: 'avgBathrooms',
  MIN_RENT: 'minRent',
  AVG_RENT: 'avgRent',
  MAX_RENT: 'maxRent',
  AVG_RENT_PER_ROOM: 'avgRentPerRoom',
}

export const PropertiesSearchUnitMixColumns = [
  { name: UnitMixColumnNames.IMAGE, title: 'Image', width: 0 },
  { name: UnitMixColumnNames.UNIT_GROUP_NAME, title: 'Unit Mix Item', width: 110 },
  { name: UnitMixColumnNames.UNIT_COUNT, title: '# Units', width: 50 },
  { name: UnitMixColumnNames.AVG_ROOMS, title: 'Avg Rooms' },
  {
    name: UnitMixColumnNames.AVG_BATHROOMS,
    title: 'Avg Baths',
  },
  { name: UnitMixColumnNames.MIN_RENT, title: 'Min Rent' },
  { name: UnitMixColumnNames.AVG_RENT, title: 'Avg Rent' },
  { name: UnitMixColumnNames.MAX_RENT, title: 'Max Rent' },
]

export const BuildingCompUnitMixListColumns = [
  { name: UnitMixColumnNames.IMAGE, title: 'Image', width: 0 },
  { name: UnitMixColumnNames.UNIT_GROUP_NAME, title: 'Unit Mix Item', width: 110 },
  { name: UnitMixColumnNames.UNIT_COUNT, title: '# Units' },
  { name: UnitMixColumnNames.AVG_ROOMS, title: 'Avg Rooms' },
  {
    name: UnitMixColumnNames.AVG_BATHROOMS,
    title: 'Avg Baths',
  },
  { name: UnitMixColumnNames.MIN_RENT, title: 'Min Rent' },
  { name: UnitMixColumnNames.AVG_RENT, title: 'Avg Rent' },
  { name: UnitMixColumnNames.MAX_RENT, title: 'Max Rent' },
  { name: UnitMixColumnNames.AVG_RENT_PER_ROOM, title: 'Avg Rent/Room' },
]

export const PropertiesSearchResultsListColumns = [
  {
    name: PropertiesSearchResultsColumnNames.ADDRESS,
    title: 'Address',
    width: 200,
  },
  {
    name: PropertiesSearchResultsColumnNames.UNITS,
    title: '# Units',
    width: 84,
  },
  {
    name: PropertiesSearchResultsColumnNames.BEDROOM_COUNTS,
    title: 'Unit Types',
    width: 108,
  },
  {
    name: PropertiesSearchResultsColumnNames.AVERAGE_RENT,
    title: 'Avg Rent',
    width: 117,
  },
  {
    name: PropertiesSearchResultsColumnNames.SUB_TYPE,
    title: 'Sub-Type',
    width: 109,
  },
  {
    name: PropertiesSearchResultsColumnNames.AMENITIES,
    title: 'Amenities',
    width: 132,
  },
  {
    name: PropertiesSearchResultsColumnNames.ACTIONS,
    title: 'Actions',
    width: 155,
  },
]

export const BuildingCompColumnNames = {
  INDEX: 'index',
  ADDRESS: 'address',
  SUB_TYPE: 'subType',
  ELEVATOR: 'elevator',
  UNIT_COUNT: 'units',
  BEDROOM_COUNTS: 'bedroomCounts',
  AVERAGE_RENT: 'averageRent',
  DISTANCE: 'distance',
  AS_OF_DATE: 'asOfDate',
  ACTIONS: 'actions',
}
export const BuildingCompListColumns = [
  { name: BuildingCompColumnNames.INDEX, title: '#', width: 20 },
  { name: BuildingCompColumnNames.ADDRESS, title: 'Address' },
  { name: BuildingCompColumnNames.SUB_TYPE, title: 'Sub-Type', width: 90 },
  { name: BuildingCompColumnNames.ELEVATOR, title: 'Elevator', width: 50 },
  { name: BuildingCompColumnNames.UNIT_COUNT, title: '# Units', width: 100 },
  { name: BuildingCompColumnNames.BEDROOM_COUNTS, title: 'Unit Types', width: 100 },
  { name: BuildingCompColumnNames.AVERAGE_RENT, title: 'Avg Rent', width: 150 },
  { name: BuildingCompColumnNames.DISTANCE, title: 'Dist.', width: 50 },
  { name: BuildingCompColumnNames.AS_OF_DATE, title: 'As of Date', width: 100 },
  { name: BuildingCompColumnNames.ACTIONS, title: 'Actions', width: 220 },
]

export const REMOVED_RESIDENTIAL_COMPS_TABLE_COLUMNS = [
  { name: 'isAutoComp', title: '', align: 'left' },
  { name: 'address', title: 'Unit Address', align: 'left' },
  { name: 'bedrooms', title: '# Bedrooms', align: 'right', width: 150 },
  { name: 'rent', title: 'Monthly Rent', align: 'right', width: 250 },
  { name: '', title: '', width: 150 },
  { name: 'sourceName', title: 'Source', align: 'left' },
  { name: 'actions', title: 'Actions', width: 220 },
]

export const AUTOMATED_COMPS_TOOLTIP = 'This Rent Comp was added automatically.'
