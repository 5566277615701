import React from 'react'
import { Field } from 'react-final-form'
import { get, noop } from 'lodash'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import MenuItem from '@material-ui/core/MenuItem'
import { FormControl, Select, OutlinedInput, FormLabel, withStyles } from '@material-ui/core'

import withOtherField from '../../hocs/withOtherField'

const styles = theme => ({
  formControl: {},
  opened: {
    '& $selectIcon': {
      transform: 'rotate(180deg)',
    },
  },
  menuPaper: {},
  menuOption: {
    outline: 'none',
    cursor: 'pointer',
    backgroundColor: '#fff',
    height: 32,
    boxSizing: 'border-box',
    padding: '0px 12px',
    '&:hover': {
      backgroundColor: theme.palette.secondary[300],
    },
  },
  selectedMenuOption: {
    backgroundColor: theme.palette.secondary[300],
  },
  select: {
    backgroundColor: 'white',
    border: '1px solid #ced4da',
    borderRadius: 4,
  },
  selectIcon: {
    marginRight: 8,
  },
  selectRoot: {},
  placeholder: {
    color: theme.palette.secondary[400],
  },
  label: {
    color: `${theme.palette.secondary[600]}`,
    paddingBottom: theme.spacing.unit * 2,
  },
})

const TAB_KEY_PRESS_REASON = 'tabKeyDown'

class MaterialDropDown extends React.PureComponent {
  static propTypes = {
    classes: PropTypes.object.isRequired,
    input: PropTypes.shape({
      name: PropTypes.string,
      value: PropTypes.any,
      onChange: PropTypes.func,
      onFocus: PropTypes.func,
      onBlur: PropTypes.func,
    }).isRequired,
    meta: PropTypes.shape({
      touched: PropTypes.bool,
      error: PropTypes.string,
    }),
    label: PropTypes.string,
    variant: PropTypes.oneOf(['standard', 'outlined', 'filled']),
    margin: PropTypes.oneOf(['none', 'dense', 'normal']),
    multiple: PropTypes.bool,
    fullWidth: PropTypes.bool,
    disabled: PropTypes.bool,
    normalize: PropTypes.func,
    onChange: PropTypes.func,
    placeholder: PropTypes.string,
    inputClasses: PropTypes.object,
    disablePortal: PropTypes.bool,
    onTabChoose: PropTypes.func,
    openOnFocus: PropTypes.bool,
    getValue: PropTypes.func,
  }

  static defaultProps = {
    variant: 'standard',
    margin: 'dense',
    fullWidth: true,
    disabled: false,
    label: null,
    handleChange: noop,
    onTabChoose: noop,
    disablePortal: false,
    onChange: undefined,
    openOnFocus: false,
    getValue: value => value,
  }

  state = {
    close: false,
    isOpen: false,
    disableRestoreFocus: false,
    openOnFocus: this.props.openOnFocus,
  }

  onChange = event => {
    const { input, onChange } = this.props

    input.onChange(event)
    if (onChange) {
      onChange(event)
    }

    this.closeMenu()
  }

  closeMenu = (disableRestoreFocus = false) => {
    this.setState({ isOpen: false, disableRestoreFocus })
    setTimeout(() => {
      this.setState({ openOnFocus: this.props.openOnFocus })
    }, 10)
  }

  open = () => {
    this.setState({ openOnFocus: false, isOpen: true })
  }

  onMenuClose = (event, reason) => {
    const { input, onTabChoose, openOnFocus } = this.props

    if (reason === TAB_KEY_PRESS_REASON) {
      const value = get(event, 'target.dataset.value')
      input.onChange(value)
      this.closeMenu(true)
      return onTabChoose()
    }

    this.setState({ isOpen: false, disableRestoreFocus: true, openOnFocus })
  }

  renderValue = value => {
    const { classes, placeholder, getValue } = this.props

    return getValue(value) || <span className={classes.placeholder}>{placeholder}</span>
  }

  onFocus = event => {
    this.props.input.onFocus(event)
    if (this.state.openOnFocus) {
      this.open()
    }
  }

  render() {
    const {
      classes,
      input,
      meta,
      disabled,
      variant,
      margin,
      fullWidth,
      items,
      displayEmpty,
      disablePortal,
      inputRef,
      inputClasses,
      name,
      label,
    } = this.props
    const { isOpen, disableRestoreFocus } = this.state
    const inErrorState = meta.touched && !!meta.error

    return (
      <FormControl
        onFocus={this.onFocus}
        onBlur={input.onBlur}
        className={classNames(classes.formControl, {
          [classes.opened]: isOpen,
        })}
        fullWidth={fullWidth}
        error={inErrorState}
        data-qa={`${input.name}-form-control`}
      >
        {label && <FormLabel classes={{ root: classes.label }}>{label}</FormLabel>}
        <Select
          name={name}
          value={input.value}
          onChange={this.onChange}
          disabled={disabled}
          input={<OutlinedInput inputRef={inputRef} labelWidth={0} name={input.name} classes={inputClasses} />}
          variant={variant}
          margin={margin}
          open={isOpen}
          onOpen={this.open}
          onClose={noop}
          displayEmpty={displayEmpty}
          renderValue={this.renderValue}
          SelectDisplayProps={{ 'data-qa': 'select-value' }}
          classes={{
            select: classes.select,
            selectMenu: classes.selectMenu,
            icon: classes.selectIcon,
            root: classes.selectRoot,
          }}
          MenuProps={{
            getContentAnchorEl: null,
            anchorOrigin: {
              vertical: 'bottom',
              horizontal: 'left',
            },
            classes: {
              paper: classes.menuPaper,
            },
            disablePortal,
            disableRestoreFocus,
            onClose: this.onMenuClose,
          }}
        >
          {items.map((option, index) => (
            <MenuItem
              key={index}
              value={option.value}
              className={classNames(classes.menuOption, {
                [classes.selectedMenuOption]: option.value === input.value,
              })}
            >
              {option.label}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    )
  }
}

const MaterialDropDownWithStyles = withStyles(styles)(MaterialDropDown)

export const DropDownComponent = ({ value, onChange, ...otherProps }) => {
  const input = { value, onChange }
  const meta = {}
  return <MaterialDropDownWithStyles input={input} meta={meta} {...otherProps} />
}

class NativeDropDown extends React.PureComponent {
  static propTypes = {
    name: PropTypes.string.isRequired,
    items: PropTypes.arrayOf(
      PropTypes.shape({
        label: PropTypes.string,
        value: PropTypes.any,
      })
    ).isRequired,
    label: PropTypes.string,
    variant: PropTypes.oneOf(['standard', 'outlined', 'filled']),
    margin: PropTypes.oneOf(['none', 'dense', 'normal']),
    fullWidth: PropTypes.bool,
    multiple: PropTypes.bool,
  }

  static defaultProps = {
    variant: 'standard',
    fullWidth: true,
  }

  render() {
    const { name, label, ...props } = this.props
    return <Field name={name} label={label} component={MaterialDropDownWithStyles} {...props} />
  }
}

export const NativeDropdownWithOther = withOtherField(NativeDropDown)

export default NativeDropDown
