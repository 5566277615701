import React from 'react'
import { AlertTitle } from '@mui/material'

import Alert from '@mui/material/Alert'

type AutomationWarningsProps = {
  warningMessage?: React.ReactNode
  warningTitle?: React.ReactNode
}

const AutomationWarnings: React.FC<AutomationWarningsProps> = ({ warningMessage, warningTitle }) => {
  return (
    <>
      {warningMessage && (
        <Alert severity="warning" sx={{ marginBottom: '16px' }}>
          {warningTitle && <AlertTitle>{warningTitle}</AlertTitle>}
          {warningMessage}
        </Alert>
      )}
    </>
  )
}

export default AutomationWarnings
