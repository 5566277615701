import React, { FC, memo, useMemo } from 'react'

import { get, noop } from 'lodash'
import { ColumnDataTypeEnum, RowBasedTable } from '@bowery-valuation/ui-components'

import { formatCurrencyFloat } from 'client-shared/utils/numberFormatters'

// todo - these belong in a shared location
import { CELL_EDITOR_SELECTORS } from 'report/forms/income/TaxInformation/constants'

import { divide } from 'shared/utils/numberOperations'
import { getCustomColumnConfig, RowBasedTableColumn } from 'client-shared/utils/rowBasedTable'
import {
  getDepreciationRowDefinitions,
  RowDefinition,
  StartingValueLabel,
  TotalLabel,
} from 'shared/constants/costApproach'
import { FormBatch, FormChange } from 'client-shared/utils/form'
import { DepreciationType } from 'shared/types/costApproachTypes'

type EstimatedDepreciationTableOwnProps = {
  change: FormChange
  batch: FormBatch
  depreciation: DepreciationType
  field: string
  startingValue: number
  squareFeet: number
}

const EstimatedDepreciationTable: FC<EstimatedDepreciationTableOwnProps> = ({
  change,
  batch,
  depreciation,
  field,
  startingValue,
  squareFeet,
}) => {
  const rowDefinitions = getDepreciationRowDefinitions(field)
  const totalDepreciationPercentage = get(depreciation, `${field}.totalDepreciation`, 0)
  const totalDepreciation = totalDepreciationPercentage * startingValue
  const getColumns = (): RowBasedTableColumn[] => {
    return [
      {
        name: 'label',
        label: '',
        type: ColumnDataTypeEnum.text,
        permanent: true,
        align: 'left',
        editable: false,
        suppressMovable: true,
      },
      {
        name: 'percentage',
        label: 'Percentage',
        type: ColumnDataTypeEnum.percent,
        permanent: true,
        align: 'right',
        editable: params => {
          const physicalRow = params.data.id === `depreciation.${field}.physical`
          const functionalRow = params.data.id === `depreciation.${field}.functionalObsolescence`
          const externalRow = params.data.id === `depreciation.${field}.externalObsolescence`
          return physicalRow || functionalRow || externalRow
        },
        cellEditorSelector: () => {
          return CELL_EDITOR_SELECTORS.percentNoDecimals
        },
        suppressMovable: true,
      },
      {
        name: 'total',
        label: 'Total',
        type: ColumnDataTypeEnum.text,
        permanent: true,
        align: 'right',
        editable: false,
        cellEditorSelector: () => {
          return CELL_EDITOR_SELECTORS.moneyNoDecimals
        },
        suppressMovable: true,
      },
      {
        name: 'totalPerSf',
        label: 'Per SF',
        type: ColumnDataTypeEnum.text,
        permanent: true,
        align: 'right',
        editable: false,
        cellEditorSelector: () => {
          return CELL_EDITOR_SELECTORS.moneyNoDecimals
        },
        suppressMovable: true,
      },
    ]
  }

  const findRowDefinition = (row: any) => {
    for (const prop in rowDefinitions) {
      const rowDefinition = rowDefinitions[prop]
      if (rowDefinition.fieldPath === row.id) {
        return rowDefinition
      }
    }
    return null
  }

  const handleRowUpdate = (row: any) => {
    const rowDefinition = findRowDefinition(row)
    if (rowDefinition) {
      change(rowDefinition.fieldPath, row.percentage)
    }
  }
  const handleManyRowsUpdate = (rows: any) => {
    batch(() => {
      rows.forEach((row: any) => {
        handleRowUpdate(row)
      })
    })
  }

  const getLineItemRow = (rowDefinition: RowDefinition) => {
    const id = rowDefinition.fieldPath
    const label = rowDefinition.label
    const percentage = get(depreciation, rowDefinition.fieldPath.replace('depreciation.', ''), 0)

    return {
      readOnly: false,
      suppressMovable: true,
      permanent: false,
      type: ColumnDataTypeEnum.text,
      label,
      id,
      percentage,
      total: formatCurrencyFloat(percentage * (startingValue ?? 0)),
      totalPerSf: formatCurrencyFloat(divide(percentage * startingValue, squareFeet)),
      rowDef: { hideAction: true },
    }
  }

  const rows = [
    {
      readOnly: true,
      suppressMovable: true,
      permanent: true,
      type: ColumnDataTypeEnum.text,
      id: 'startingValue',
      label: StartingValueLabel[field],
      total: formatCurrencyFloat(startingValue),
      totalPerSf: formatCurrencyFloat(divide(startingValue, squareFeet)),
      percentage: null,
      rowDef: { summary: true, hideAction: true },
    },
    {
      readOnly: true,
      suppressMovable: true,
      permanent: true,
      type: ColumnDataTypeEnum.text,
      id: 'depreciation',
      label: 'Depreciation',
      total: null,
      totalPerSf: null,
      percentage: null,
      rowDef: { summary: true, hideAction: true },
    },
    getLineItemRow(rowDefinitions.physical),
    {
      readOnly: true,
      suppressMovable: true,
      permanent: true,
      type: ColumnDataTypeEnum.text,
      id: 'obsolescence',
      label: 'Obsolescence',
      total: null,
      totalPerSf: null,
      percentage: null,
      rowDef: { summary: true, hideAction: true },
    },
    getLineItemRow(rowDefinitions.functionalObsolescence),
    getLineItemRow(rowDefinitions.externalObsolescence),
    {
      readOnly: true,
      suppressMovable: true,
      permanent: true,
      type: ColumnDataTypeEnum.text,
      id: 'totalDeprecation',
      label: 'Total Depreciation',
      total: formatCurrencyFloat(totalDepreciation),
      totalPerSf: formatCurrencyFloat(divide(totalDepreciation, squareFeet)),
      percentage: null,
      rowDef: { summary: true, hideAction: true },
    },
    {
      readOnly: true,
      suppressMovable: true,
      permanent: true,
      type: ColumnDataTypeEnum.text,
      id: 'depreciatedReplacementCost',
      label: TotalLabel[field],
      total: formatCurrencyFloat(startingValue - totalDepreciation),
      totalPerSf: formatCurrencyFloat(divide(startingValue - totalDepreciation, squareFeet)),
      percentage: null,
      rowDef: { summary: true, hideAction: true },
    },
  ]
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const columns = useMemo(() => getColumns(), [])

  return (
    <RowBasedTable
      id="estimated-depreciation-table"
      columns={columns}
      rows={rows}
      onRowUpdate={handleRowUpdate}
      onManyRowsUpdate={handleManyRowsUpdate}
      getCustomColumnConfig={getCustomColumnConfig}
      actionCellHidden
      hideIndexColumn
      onColumnDragEnd={noop}
      onRowsDragEnd={noop}
      onColumnDelete={noop}
      onColumnUpdate={noop}
      onRowDelete={noop}
    />
  )
}

export default memo(EstimatedDepreciationTable)
