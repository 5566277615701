import HttpClientManager from '../../xhr/HttpClientManager'

import { getAuthorizationHeader } from '../../core/api'

const httpClient = HttpClientManager.createHttpClient({
  baseURL: `${global.env.apiUrl}`,
})

export async function fetchBuildingComp(reportId, buildingCompId) {
  const response = await httpClient.get(`/report/${reportId}/buildingComparables/${buildingCompId}`, {
    headers: getAuthorizationHeader(),
  })
  return response.data
}

export async function createBuildingComp(reportId, data) {
  return await httpClient.post(`/report/${reportId}/buildingComparables`, data, {
    headers: getAuthorizationHeader(),
  })
}

export async function addMultipleBuildingCompsToReport(reportId, data) {
  const response = await httpClient.post(`/report/${reportId}/buildingComparables/addMultiple`, data, {
    headers: getAuthorizationHeader(),
  })
  return response.data
}

export async function findNeighborhoodByBorough(neighborhood, borough) {
  const response = await httpClient.get(
    `/propertySearch/findNeighborhoodByBorough?borough=${borough}&neighborhood=${neighborhood}`,
    {
      headers: getAuthorizationHeader(),
    }
  )
  return response.data
}

export async function updateBuildingComp(reportId, buildingCompId, formDataPath, buildingComp) {
  const response = await httpClient.put(
    `/report/${reportId}/buildingComparables/${buildingCompId}`,
    {
      formDataPath,
      buildingComp,
    },
    {
      headers: getAuthorizationHeader(),
    }
  )

  return response.data
}

export async function addBuildingCompToReport(buildingCompId, reportId) {
  const response = await httpClient.post(
    `/report/${reportId}/buildingComparables/addToReport/${buildingCompId}`,
    {},
    {
      headers: getAuthorizationHeader(),
    }
  )

  return response.data
}

export async function removeBuildingCompFromReport(buildingCompIdList, reportId) {
  return await httpClient.post(
    `/report/${reportId}/buildingComparables/removeFromReport`,
    {
      buildingCompIdList,
    },
    {
      headers: getAuthorizationHeader(),
    }
  )
}

export async function searchProperties(reportId, filters) {
  const response = await httpClient.post(`report/${reportId}/buildingComparables/search`, filters, {
    headers: getAuthorizationHeader(),
  })

  return response.data
}

export async function importBuildingComps(reportId, sourceReportId) {
  const response = await httpClient.post(
    `report/${sourceReportId}/buildingComparables/import`,
    { reportId, sourceReportId },
    {
      headers: getAuthorizationHeader(),
    }
  )
  return response.data
}
