import React from 'react'

import Bold from './Bold'

export default {
  renderMark(props, _editor, next) {
    const { attributes, mark, children } = props
    if (mark.type !== 'bold') {
      return next()
    }

    return <Bold attributes={attributes}>{children}</Bold>
  },
}
