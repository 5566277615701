export const BUILDING_LABEL_VALUE_PAIR = {
  PropertySummary: {
    heading: 'Property Summary',
    menuItem: 'propertySummary',
  },
  RentRoll: {
    heading: 'Rent Roll',
    menuItem: 'rentRoll',
  },
  PropertyConditions: {
    heading: 'Property Conditions',
    menuItem: 'propertyConditions',
  },
  UnitMix: {
    heading: 'Unit Mix',
    menuItem: 'unitMix',
  },
  CompSummary: {
    heading: 'Comp Summary',
    menuItem: 'compSummary',
  },
}

export const BASE_PATH = 'buildingComp'

export const PROPERTY_SUMMARY_LABEL = {
  ADDRESS: 'Address',
  DISTANCE_FROM_SUBJECT: 'Distance from Subject',
  BLOCK: 'Block',
  LOT: 'Lot',
  TYPE: 'Type',
  NEIGHBORHOOD: 'Neighborhood',
  YEAR_BUILT: 'Year Built',
  YEAR_RENOVATED: 'Year Renovated',
  FLOORS: 'Floors',
  GROSS_BUILDING_AREA: 'Gross Building Area',
  DATE_AS_OF: 'Date as of',
  OCCUPANCY_LEVEL: 'Occupancy Level',
  RESIDENTIAL_UNITS: 'Residential Units',
  MANAGEMENT: 'Management',
  PROPERTY_SUB_TYPE: 'Property Sub-Type',
  PROPERTY_HAS_ELEVATOR: 'Property has Elevator',
  PRIMARY_SOURCE: 'Primary Source',
  CITY: 'City',
  STATE: 'State',
}

export const PROPERTY_CONDITION_LABEL = {
  GENERAL_PROPERTY_CONDITION: 'General Property Condition',
  GENERAL_BATHROOM_CONDITION: 'General Bathroom Condition',
  GENERAL_KITCHEN_CONDITION: 'General Kitchen Condition',
  GENERAL_BEDROOM_CONDITION: 'General Living/Bedroom Condition',
  HEATING_SYSTEMS: 'Heating Systems',
  AVAILABLE_AMENITIES: 'Available Amenities',
}
export const PROPERTY_SUMMARY_KEYS = [
  {
    label: PROPERTY_SUMMARY_LABEL.ADDRESS,
    key: 'streetAddress',
  },
  {
    label: PROPERTY_SUMMARY_LABEL.DISTANCE_FROM_SUBJECT,
    key: 'distanceFromSubject',
  },
  {
    label: PROPERTY_SUMMARY_LABEL.BLOCK,
    key: 'descriptor.block',
  },
  {
    label: PROPERTY_SUMMARY_LABEL.LOT,
    key: 'descriptor.lot',
  },
  {
    label: PROPERTY_SUMMARY_LABEL.TYPE,
    key: 'type',
  },
  {
    label: PROPERTY_SUMMARY_LABEL.NEIGHBORHOOD,
    key: 'neighborhood',
  },
  {
    label: PROPERTY_SUMMARY_LABEL.YEAR_BUILT,
    key: 'yearBuilt',
  },
  {
    label: PROPERTY_SUMMARY_LABEL.YEAR_RENOVATED,
    key: 'yearRenovated',
  },
  {
    label: PROPERTY_SUMMARY_LABEL.FLOORS,
    key: 'floors',
  },
  {
    label: PROPERTY_SUMMARY_LABEL.GROSS_BUILDING_AREA,
    key: 'grossBuildingArea',
  },
  {
    label: PROPERTY_SUMMARY_LABEL.DATE_AS_OF,
    key: 'asOfDate',
  },
  {
    label: PROPERTY_SUMMARY_LABEL.OCCUPANCY_LEVEL,
    key: 'occupancyLevel',
  },
  {
    label: PROPERTY_SUMMARY_LABEL.RESIDENTIAL_UNITS,
    key: 'residentialUnitCount',
  },
  {
    label: PROPERTY_SUMMARY_LABEL.MANAGEMENT,
    key: 'management',
  },
  {
    label: PROPERTY_SUMMARY_LABEL.PROPERTY_SUB_TYPE,
    key: 'management',
  },
  {
    label: PROPERTY_SUMMARY_LABEL.PROPERTY_HAS_ELEVATOR,
    key: 'hasElevator',
  },
  {
    label: PROPERTY_SUMMARY_LABEL.PRIMARY_SOURCE,
    key: 'primarySource',
  },
  {
    label: PROPERTY_SUMMARY_LABEL.CITY,
    key: 'city',
  },
  {
    label: PROPERTY_SUMMARY_LABEL.STATE,
    key: 'state',
  },
]

export const PropertiesConditionKeys = [
  {
    label: PROPERTY_CONDITION_LABEL.GENERAL_PROPERTY_CONDITION,
    key: 'generalPropertyCondition',
  },
  {
    label: PROPERTY_CONDITION_LABEL.GENERAL_BATHROOM_CONDITION,
    key: 'generalBathroomCondition',
  },
  {
    label: PROPERTY_CONDITION_LABEL.GENERAL_KITCHEN_CONDITION,
    key: 'generalKitchenCondition',
  },
  {
    label: PROPERTY_CONDITION_LABEL.GENERAL_BEDROOM_CONDITION,
    key: 'generalLivingAndBedroomCondition',
  },
  {
    label: PROPERTY_CONDITION_LABEL.HEATING_SYSTEMS,
    key: 'heatingSystems',
  },
  {
    label: PROPERTY_CONDITION_LABEL.AVAILABLE_AMENITIES,
    key: 'availableAmenities',
  },
]

export const RENTROLL_COLUMN_NAMES = {
  INCLUDE: 'isIncluded',
  INDEX: 'index',
  NUMBER: 'unitNumber',
  ROOMS: 'roomCount',
  BEDROOMS: 'bedroomCount',
  BATHROOMS: 'bathroomCount',
  SQUARE_FOOTAGE: 'sqft',
  OUTDOOR: 'outdoorSpace',
  RENT_TYPE: 'rentType',
  RENT: 'rent',
  RENT_PER_ROOM: 'rentPerRoom',
  REMOVE: 'remove',
  IS_NOT_REPORTED_SQUARE_FEET: 'isNotReportedSquareFeet',
}

export const UNIT_MIX_COLUMN_NAMES = {
  UNIT_GROUP_NAME: 'groupingName',
  UNIT_COUNT: 'units',
  AVG_BATHROOMS: 'avgBathrooms',
  AVG_ROOMS: 'avgRooms',
  MIN_RENT: 'minRent',
  AVG_RENT: 'avgRent',
  MAX_RENT: 'maxRent',
  AVG_SQUARE_FEET: 'avgSquareFeet',
  AVG_RENT_PER_ROOM: 'avgRentPerRoom',
  AVG_RENT_PER_SQUARE_FEET_PER_MONTH: 'avgRentPerSquareFeetPerMonth',
  AVG_RENT_PER_SQUARE_FEET_PER_YEAR: 'avgRentPerSquareFeetPerYear',
  IS_ESTIMATED: 'isEstimated',
  REMOVE: 'remove',
}

export const UNIT_MIX_COLUMNS = [
  { name: UNIT_MIX_COLUMN_NAMES.UNIT_GROUP_NAME, title: 'Unit Group' },
  { name: UNIT_MIX_COLUMN_NAMES.UNIT_COUNT, title: 'Units' },
  { name: UNIT_MIX_COLUMN_NAMES.AVG_BATHROOMS, title: 'Avg Baths' },
  { name: UNIT_MIX_COLUMN_NAMES.AVG_ROOMS, title: 'Avg Rooms' },
  { name: UNIT_MIX_COLUMN_NAMES.MIN_RENT, title: 'Min Rent' },
  { name: UNIT_MIX_COLUMN_NAMES.AVG_RENT, title: 'Avg Rent' },
  { name: UNIT_MIX_COLUMN_NAMES.MAX_RENT, title: 'Max Rent' },
  { name: UNIT_MIX_COLUMN_NAMES.AVG_SQUARE_FEET, title: 'Avg Square Feet' },
  { name: UNIT_MIX_COLUMN_NAMES.AVG_RENT_PER_ROOM, title: 'Avg Rent/Room' },
  {
    name: UNIT_MIX_COLUMN_NAMES.AVG_RENT_PER_SQUARE_FEET_PER_MONTH,
    title: 'Avg Rent/SF/Month',
  },
  {
    name: UNIT_MIX_COLUMN_NAMES.AVG_RENT_PER_SQUARE_FEET_PER_YEAR,
    title: 'Avg Rent/SF/Year',
  },
  { name: UNIT_MIX_COLUMN_NAMES.REMOVE, title: 'Remove' },
]

export const RentRollColumns = [
  { name: RENTROLL_COLUMN_NAMES.INCLUDE, title: 'Include' },
  { name: RENTROLL_COLUMN_NAMES.INDEX, title: '#' },
  { name: RENTROLL_COLUMN_NAMES.NUMBER, title: '# Unit' },
  { name: RENTROLL_COLUMN_NAMES.BEDROOMS, title: '# Bedrooms' },
  { name: RENTROLL_COLUMN_NAMES.BATHROOMS, title: '# Bathrooms' },
  { name: RENTROLL_COLUMN_NAMES.ROOMS, title: '# Rooms' },
  { name: RENTROLL_COLUMN_NAMES.SQUARE_FOOTAGE, title: 'Square Feet' },
  { name: RENTROLL_COLUMN_NAMES.OUTDOOR, title: 'Outdoor Space' },
  { name: RENTROLL_COLUMN_NAMES.RENT_TYPE, title: 'Rent Type' },
  { name: RENTROLL_COLUMN_NAMES.RENT, title: 'Monthly Rent' },
  { name: RENTROLL_COLUMN_NAMES.RENT_PER_ROOM, title: 'Rent/Room' },
  { name: RENTROLL_COLUMN_NAMES.REMOVE, title: 'Remove' },
]

export const PRIMARY_SOURCES = [
  { label: 'Co-Star', value: 'Co-Star' },
  { label: 'Street Easy', value: 'Street Easy' },
  { label: 'Broker', value: 'Broker' },
  { label: 'Rent Roll', value: 'Rent Roll' },
  { label: 'Not Reported', value: 'Not Reported' },
]
