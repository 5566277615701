import React from 'react'
import PropTypes from 'prop-types'
import { Link as ReactRouterLink } from 'react-router-dom'
import { compose, pure } from 'recompose'
import { withStyles } from '@mui/styles'
import LinkIcon from '@mui/icons-material/Launch'
import { Tooltip } from '@mui/material'

import RefHolder from 'client-shared/utils/RefHolder'

const styles = () => ({
  root: {
    borderRadius: 3,
    margin: '16px 0px',
    padding: '20px 16px',
    backgroundColor: '#F5F5F5',
  },
  heading: {
    opacity: 1,
    display: 'flex',
    justifyContent: 'space-between',
  },
  name: {
    fontSize: 12,
    color: '#212121',
    lineHeight: 1.33,
    letterSpacing: 0.4,
  },
  content: {
    marginTop: 8,
    opacity: 0.54,
    paddingRight: 15,
  },
  linkIcon: {
    verticalAlign: 'middle',
  },
  link: {
    color: 'inherit',
    opacity: 0.54,
  },
  editDataMessage: {
    marginRight: 10,
    verticalAlign: 'middle',
  },
})

const Link = RefHolder(ReactRouterLink)

const UneditableSection = ({ classes, name, link, anchor, returnDataPath, formName, children, editDataMessage }) => {
  return (
    <div className={classes.root}>
      <div className={classes.heading}>
        <span className={classes.name}>{name}</span>
        <Tooltip title={`Go To ${formName}`} placement="top">
          <Link
            className={classes.link}
            to={{
              pathname: link,
              hash: '#' + anchor,
              state: { returnPath: returnDataPath },
            }}
          >
            {editDataMessage && <span className={classes.editDataMessage}>EDIT DATA</span>}
            <LinkIcon className={classes.linkIcon} />
          </Link>
        </Tooltip>
      </div>
      <div className={classes.content}>{children}</div>
    </div>
  )
}

UneditableSection.propTypes = {
  name: PropTypes.string.isRequired,
  link: PropTypes.string.isRequired,
  anchor: PropTypes.string,
  returnDataPath: PropTypes.string,
  children: PropTypes.node.isRequired,
  formName: PropTypes.string.isRequired,
  editDataMessage: PropTypes.bool,
}

export default compose(pure, withStyles(styles))(UneditableSection)
