import React from 'react'
import { Grid } from '@material-ui/core'
import { compose, pure } from 'recompose'
import { withStyles } from '@material-ui/core/styles'

import { NAV_HEADER_HEIGHT } from '../constants/styles'
import Notifications from '../../../shared/components/Notifications'
import Confirmation from '../../../shared/components/Confirmation/Confirmation'

import TopNavigationMenu from './TopNavigationMenu'
import BuildingFormLocation from './BuildingFormLocation'

const styles = {
  topNavigation: {
    height: NAV_HEADER_HEIGHT,
  },
  notifications: {
    position: 'fixed',
    top: NAV_HEADER_HEIGHT,
    right: 0,
    zIndex: 3000,
  },
}

const PageLayout = ({ classes, children }) => (
  <React.Fragment>
    <TopNavigationMenu>
      <BuildingFormLocation />
    </TopNavigationMenu>
    <Notifications classes={{ root: classes.notifications }} />
    <Confirmation />
    <Grid>{children}</Grid>
  </React.Fragment>
)

export default compose(withStyles(styles), pure)(PageLayout)
