import { formatCurrencyFloat, formatCurrencyInt } from 'shared/utils/formatters/numberFormatters'

import { FinalValues } from './types'

export const DEFAULT_FINAL_VALUES = {
  indicatedValue: {
    total: 0,
    rounded: 0,
    perUnit: 0,
    psf: 0,
  },
  finalValueAsStabilized: {
    total: 0,
    rounded: 0,
    perUnit: 0,
    psf: 0,
  },
  finalValueAsComplete: {
    total: 0,
    rounded: 0,
    perUnit: 0,
    psf: 0,
  },
  finalValueAsIs: {
    total: 0,
    rounded: 0,
    perUnit: 0,
    psf: 0,
  },
}

export const DEFAULT_FINAL_DATES_OF_VALUE = {
  dateOfFinalValueAsIs: '',
  dateOfFinalValueAsComplete: '',
  dateOfFinalValueAsStabilized: '',
}

export const mapValuesToDisplay = (finalValues: FinalValues): any => {
  const { indicatedValue, finalValueAsComplete, finalValueAsStabilized, finalValueAsIs } = finalValues
  return {
    capitalizationConclusion: formatCurrencyInt(indicatedValue.total),
    capitalizationConclusionRounded: formatCurrencyInt(indicatedValue.rounded),
    finalValueAsStabilized: formatCurrencyInt(finalValueAsStabilized.total),
    finalValueAsStabilizedRoundedUnformatted: finalValueAsStabilized.rounded,
    finalValueAsStabilizedRounded: formatCurrencyInt(finalValueAsStabilized.rounded),
    finalValueAsStabilizedPerUnit: formatCurrencyInt(finalValueAsStabilized.perUnit),
    finalValueAsStabilizedPerSf: formatCurrencyFloat(finalValueAsStabilized.psf),
    finalValueAsComplete: formatCurrencyInt(finalValueAsComplete.total),
    finalValueAsCompleteRoundedUnformatted: finalValueAsComplete.rounded,
    finalValueAsCompleteRounded: formatCurrencyInt(finalValueAsComplete.rounded),
    finalValueAsCompletePerUnit: formatCurrencyInt(finalValueAsComplete.perUnit),
    finalValueAsCompletePerSf: formatCurrencyFloat(finalValueAsComplete.psf),
    finalValueAsIs: formatCurrencyInt(finalValueAsIs.total),
    finalValueAsIsRoundedUnformatted: finalValueAsIs.rounded,
    finalValueAsIsRounded: formatCurrencyInt(finalValueAsIs.rounded),
    finalValueAsIsPerUnit: formatCurrencyInt(finalValueAsIs.perUnit),
    finalValueAsIsPerSf: formatCurrencyFloat(finalValueAsIs.psf),
  }
}
