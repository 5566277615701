import moment from 'moment'
import { round, isNaN } from 'lodash'

const getDiffBetweenCurrentAndCreationDates = createdAt => {
  const diff = moment.utc().diff(moment(createdAt))
  return moment.duration(diff).asSeconds()
}

const getApproximateReportGenerationTime = ({ previousExportTime, exportTime, createdAt }) => {
  const averageReportGenerationTime = exportTime || previousExportTime

  const diffBetweenCurrentAndCreationDates = getDiffBetweenCurrentAndCreationDates(createdAt)
  const remainingReportGenerationTime = round(averageReportGenerationTime) - round(diffBetweenCurrentAndCreationDates)
  if (isNaN(remainingReportGenerationTime) || remainingReportGenerationTime < 0) {
    return {
      remainingReportGenerationTime: 0,
      averageReportGenerationTime: 0,
    }
  }

  return {
    remainingReportGenerationTime: Math.floor(remainingReportGenerationTime),
    averageReportGenerationTime: Math.floor(averageReportGenerationTime),
  }
}

export { getApproximateReportGenerationTime }
