import React from 'react'

import SaveIcon from '@mui/icons-material/SaveAlt'
import { Box, Fab, Tooltip } from '@mui/material'
import { generate } from 'bson-objectid'
import { get } from 'lodash'
import { connect } from 'react-redux'

import { EXPORT_PHOTOS_LABEL } from '../../report/constants'
import ExportPropertyPhotos from '../../report/forms/property/ExportPropertyPhotos'
import { warningNotification } from '../../shared/redux/actions/notifications'

import { DATE_SENTENCE, ERROR_NO_PHOTOS, ERROR_UPLOAD_INPROG } from './constants'
import DownloadDialog from './DownloadDialog'

class PhotoGridExport extends React.PureComponent {
  state = {
    // the generated report id will not be in the report DB, it's only used to create the export job
    reportId: generate(),
    downloadOpen: false,
    json: null,
    dateSentence: DATE_SENTENCE,
    properties: {
      defaultCategories: [],
      customCategories: [],
    },
  }

  onFormSubmit = event => {
    const name = get(event, 'detail.name', null)
    const values = get(event, 'detail.values', {})
    const { dateSentence, reportId } = this.state
    const { warningNotification } = this.props

    if (name === EXPORT_PHOTOS_LABEL) {
      // transform form data into report json
      // the generated report id will not be in the report DB, it's only used to create the export job
      const json = {
        _id: reportId,
        report_number: 1,
        letterOfTransmittalSection: { client: { prefix: '', last_name: '' } },
        subjectPropertyPhotosSection: {
          photoGrids: [],
        },
      }
      let i = 0
      if (values.hasOwnProperty('exportCustomCategories') && Array.isArray(values.exportCustomCategories)) {
        // use an every loop so we can break execution if we find an invalid image
        if (
          !values.exportCustomCategories.every(cat => {
            return cat.photos.every(img => {
              if (img.cdnUrl.startsWith('blob:')) {
                warningNotification(ERROR_UPLOAD_INPROG)
                console.warn(ERROR_NO_PHOTOS)
                return false
              }

              if (i % 6 === 0) {
                json.subjectPropertyPhotosSection.photoGrids.push({
                  dateSentence: dateSentence,
                  imageCouplet0: {
                    caption0: cat.category,
                    image0: img.cdnUrl,
                  },
                })
              } else if (i % 2 === 0) {
                json.subjectPropertyPhotosSection.photoGrids[Math.floor(i / 6)][
                  'imageCouplet' + Math.floor((i % 6) / 2)
                ] = {
                  caption0: cat.category,
                  image0: img.cdnUrl,
                }
              } else {
                json.subjectPropertyPhotosSection.photoGrids[Math.floor(i / 6)][
                  'imageCouplet' + Math.floor((i % 6) / 2)
                ].caption1 = cat.category
                json.subjectPropertyPhotosSection.photoGrids[Math.floor(i / 6)][
                  'imageCouplet' + Math.floor((i % 6) / 2)
                ].image1 = img.cdnUrl
              }
              i++
              return true
            })
          })
        ) {
          return false
        }
      }

      if (i === 0) {
        warningNotification(ERROR_NO_PHOTOS)
        console.warn(ERROR_NO_PHOTOS)
        return false
      }

      this.setState({ downloadOpen: true, json: json })
    }
  }

  downloadDocument = () => {
    const el = document.getElementById('propertyInformation.exportPropertyPhotos-final-form')
    return el ? el.dispatchEvent(new Event('submit', { cancelable: true, bubbles: true })) : null
  }

  closeDownload = (_event, reason) => {
    if (reason === 'backdropClick') {
      return
    }
    this.setState({ downloadOpen: false })
  }

  constructor(props) {
    super(props)

    document.addEventListener('saveForm', this.onFormSubmit)
  }

  componentWillUnmount() {
    document.removeEventListener('saveForm', this.onFormSubmit)
  }

  render() {
    const { properties, downloadOpen, json, reportId } = this.state

    return (
      <Box sx={{ padding: 2, pt: 5 }}>
        <ExportPropertyPhotos reportId={reportId} properties={properties} />
        <Tooltip placement="top" title="Download Document">
          <Fab
            color="primary"
            data-qa="download-img-btn"
            onClick={this.downloadDocument}
            sx={{ position: 'fixed', bottom: 30, right: 30 }}
          >
            <SaveIcon />
          </Fab>
        </Tooltip>
        <DownloadDialog open={downloadOpen} onClose={this.closeDownload} json={json} />
      </Box>
    )
  }
}

const mapDispatchToProps = dispatch => ({
  warningNotification: err => dispatch(warningNotification({ message: err })),
})

export default connect(null, mapDispatchToProps)(PhotoGridExport)
