import { lowerFirst, partition, cloneDeep, sum, subtract, isNumber } from 'lodash'

import { BASIS_OF_COMPARISON_OPTIONS, NOT_COMPUTABLE_VALUE } from 'shared/constants/expenses'

import { PropertyTypes } from 'shared/constants'

import {
  EXPENSE_HISTORY_TYPES,
  EXPENSE_HISTORY_TYPE_KEYS,
  EXPENSE_HISTORY_TYPE_TITLES,
} from 'shared/constants/expenses/expenseHistory'

import { MONTHS } from 'client-shared/utils/expenses/constants'

import { getExpensePeriodDate } from 'shared/helpers/expenseDate'

import { getExpenseValuesForCategory } from 'shared/utils/expenses/mappers'
import { formatExpenseId, isProjectionPeriod } from 'shared/helpers/incomeApproach/expenses/helpers'

import { FlattenedExpenseCategory, ParentExpenseCategory, ExpenseYear, Expense, UpdatedCategoriesData } from './types'

export const getZeroBasedMonth = (monthValue = '') => {
  const month = MONTHS.findIndex(value => value.toLowerCase() === monthValue.toLowerCase())

  return month !== -1 ? month : 11
}

export const isActualPeriod = (expensePeriod: string) =>
  [EXPENSE_HISTORY_TYPE_KEYS.ACTUAL, EXPENSE_HISTORY_TYPE_TITLES.ACTUAL].includes(expensePeriod)

export const isProjectionOrActualPeriod = (expensePeriod: string) =>
  isProjectionPeriod(expensePeriod) || isActualPeriod(expensePeriod)

export const flattenExpenseCategories = (
  expenseCategories: ParentExpenseCategory[] = []
): FlattenedExpenseCategory[] => {
  return expenseCategories.reduce<FlattenedExpenseCategory[]>((acc, category) => {
    const { id, subcategories = [], ...rest } = category

    return [
      ...acc,
      { id, ...rest, hasSubcategories: !!subcategories.length, isOpen: true },
      ...subcategories.map(subcategory => ({ ...subcategory, parentId: id })),
    ]
  }, [])
}

export const renameSubcategory = (
  oldSubcategoryId: string,
  newSubcategoryName: string,
  expenseCategories: FlattenedExpenseCategory[],
  expenseHistory: ExpenseYear[]
): UpdatedCategoriesData & { updatedSubcategoryId: string } => {
  const updatedSubcategoryId = formatExpenseId(newSubcategoryName)

  const updatedExpenseCategories = expenseCategories.map(category => {
    if (category.id === oldSubcategoryId) {
      return {
        ...category,
        id: updatedSubcategoryId,
        name: newSubcategoryName,
      }
    }
    return category
  })

  const updatedExpenseHistory = expenseHistory.map(expensePeriod => {
    return {
      ...expensePeriod,
      expenses: expensePeriod.expenses.map(expense => {
        if (expense.id === oldSubcategoryId) {
          return {
            ...expense,
            id: updatedSubcategoryId,
          }
        }
        return expense
      }),
    }
  })

  return { updatedSubcategoryId, updatedExpenseCategories, updatedExpenseHistory }
}

export const moveSubcategory = (
  subcategoryId: string,
  newParentId: string,
  expenseCategories: FlattenedExpenseCategory[],
  expenseHistory: ExpenseYear[]
): UpdatedCategoriesData => {
  const [[subcategoryToMove], updatedExpenseCategories] = partition<FlattenedExpenseCategory>(
    expenseCategories,
    ({ id }) => id === subcategoryId
  )

  const originCategory = updatedExpenseCategories.find(({ id }) => id === subcategoryToMove.parentId)
  const targetCategory = updatedExpenseCategories.find(({ id }) => id === newParentId)
  if (!originCategory || !targetCategory) {
    return { updatedExpenseCategories: expenseCategories, updatedExpenseHistory: expenseHistory }
  }

  const lastTargetCategoryItemIndex = updatedExpenseCategories.findLastIndex(
    ({ id, parentId }) => parentId === newParentId || id === newParentId
  )

  const shouldUpdateOriginCategory = !updatedExpenseCategories.some(
    ({ id, parentId }) => parentId === subcategoryToMove.parentId && id !== subcategoryToMove.id
  )
  if (shouldUpdateOriginCategory) {
    originCategory.hasSubcategories = false
  }

  const shouldUpdateTargetCategory = !updatedExpenseCategories.some(
    ({ id, parentId }) => parentId === newParentId && id !== subcategoryToMove.id
  )
  if (shouldUpdateTargetCategory) {
    targetCategory.hasSubcategories = true
  }

  const targetExpenseCategoryName = `Other ${targetCategory.name}`
  const targetExpenseCategoryId = formatExpenseId(targetExpenseCategoryName)

  const targetCategoryValues = getExpenseValuesForCategory(targetCategory.id, expenseHistory as any)

  const updatedExpenseHistory = expenseHistory.map(expensePeriodUnit => {
    const updatedExpensePeriodUnit = cloneDeep(expensePeriodUnit)

    const categoryExpenseToMove = updatedExpensePeriodUnit.expenses.find(({ id }) => id === subcategoryId) as Expense
    const originCategoryExpense = updatedExpensePeriodUnit.expenses.find(
      ({ id }) => id === originCategory.id
    ) as Expense
    const targetCategoryExpense = updatedExpensePeriodUnit.expenses.find(
      ({ id }) => id === targetCategory.id
    ) as Expense

    originCategoryExpense.value = shouldUpdateOriginCategory
      ? null
      : subtract(originCategoryExpense.value!, categoryExpenseToMove.value!)

    if (targetCategoryValues.length && shouldUpdateTargetCategory) {
      const targetCategoryExpense = updatedExpensePeriodUnit.expenses.find(
        ({ id }) => id === targetCategory.id
      ) as Expense

      updatedExpensePeriodUnit.expenses.push({
        ...targetCategoryExpense,
        id: targetExpenseCategoryId,
      })
    }

    targetCategoryExpense.value = sum([targetCategoryExpense.value, categoryExpenseToMove.value])

    return updatedExpensePeriodUnit
  })

  updatedExpenseCategories.splice(
    lastTargetCategoryItemIndex + 1,
    0,
    ...[
      ...(targetCategoryValues.length && shouldUpdateTargetCategory
        ? [
            {
              id: targetExpenseCategoryId,
              name: targetExpenseCategoryName,
              parentId: newParentId,
            },
          ]
        : []),
      {
        ...subcategoryToMove,
        parentId: newParentId,
      },
    ]
  )

  return { updatedExpenseCategories, updatedExpenseHistory }
}

export const addSubcategory = (
  categoryId: string,
  subcategoryName: string,
  expenseCategories: FlattenedExpenseCategory[],
  expenseHistory: ExpenseYear[]
): UpdatedCategoriesData => {
  const parentCategory = expenseCategories.find(expenseCategory => expenseCategory.id === categoryId)
  if (!parentCategory) {
    return { updatedExpenseCategories: expenseCategories, updatedExpenseHistory: expenseHistory }
  }

  const subcategoryId = formatExpenseId(subcategoryName)

  const updatedExpenseHistory = expenseHistory.map(expensePeriodUnit => {
    const updatedExpensePeriodUnit = cloneDeep(expensePeriodUnit)

    const categoryExpense = expensePeriodUnit.expenses.find(expense => expense.id === categoryId) as Expense

    updatedExpensePeriodUnit.expenses = [
      ...expensePeriodUnit.expenses,
      {
        id: subcategoryId,
        reported: categoryExpense?.reported ?? false,
        sf: 0,
        unit: 0,
        room: 0,
        value: categoryExpense.value && !parentCategory.hasSubcategories ? categoryExpense.value : null,
      },
    ]

    return updatedExpensePeriodUnit
  })

  const updatedExpenseCategories = [...expenseCategories]
  const lastCategoryItemIndex = updatedExpenseCategories.findLastIndex(
    ({ id, parentId }) => parentId === categoryId || id === categoryId
  )

  updatedExpenseCategories.splice(lastCategoryItemIndex + 1, 0, {
    id: subcategoryId,
    name: subcategoryName,
    parentId: categoryId,
  })

  parentCategory.hasSubcategories = true

  return { updatedExpenseCategories, updatedExpenseHistory }
}

export const removeCategory = (
  categoryId: string,
  expenseCategories: FlattenedExpenseCategory[],
  expenseHistory: ExpenseYear[]
): UpdatedCategoriesData => {
  const expenseCategoryToDelete = expenseCategories.find(({ id }) => id === categoryId)
  if (!expenseCategoryToDelete) {
    return { updatedExpenseCategories: expenseCategories, updatedExpenseHistory: expenseHistory }
  }

  const categoriesIdsToRemove = expenseCategoryToDelete.parentId
    ? [categoryId]
    : expenseCategories.filter(({ id, parentId }) => id === categoryId || parentId === categoryId).map(({ id }) => id)

  const parentCategory = expenseCategories.find(({ id }) => id === expenseCategoryToDelete.parentId)

  const shouldUpdateParentCategory =
    parentCategory &&
    expenseCategoryToDelete.parentId &&
    !expenseCategories.some(
      ({ id, parentId }) => parentId === expenseCategoryToDelete.parentId && id !== expenseCategoryToDelete.id
    )

  if (shouldUpdateParentCategory) {
    parentCategory.hasSubcategories = false
  }

  const updatedExpenseCategories = [...expenseCategories.filter(({ id }) => !categoriesIdsToRemove.includes(id))]
  const updatedExpenseHistory = expenseHistory.map(expensePeriodUnit => {
    const updatedExpensePeriodUnit = cloneDeep(expensePeriodUnit)

    const [subcategoriesToRemove, updatedExpenses] = partition<Expense>(updatedExpensePeriodUnit.expenses, ({ id }) =>
      categoriesIdsToRemove.includes(id)
    )

    if (parentCategory) {
      const parentExpense = updatedExpenses.find(({ id }) => id === parentCategory.id) as Expense
      if (parentExpense && subcategoriesToRemove.length) {
        parentExpense.value = shouldUpdateParentCategory
          ? null
          : subtract(parentExpense.value!, sum(subcategoriesToRemove.map(({ value }) => value).filter(isNumber)))
      }
    }

    updatedExpensePeriodUnit.expenses = updatedExpenses

    return updatedExpensePeriodUnit
  })

  return { updatedExpenseCategories, updatedExpenseHistory }
}

export const formatRowPath = (row: any, itemIndex: number) => {
  return `expenseHistory[${itemIndex}].expenses[${row.rowDef.index}].value`
}

export const normalizeIndex = (index: number) => index - 1

export const setName = (row: any, basis: string) => {
  const { name } = row
  const customTitle = name.includes(lowerFirst(basis)) ? name : `${name} ${lowerFirst(basis)}`
  return basis !== BASIS_OF_COMPARISON_OPTIONS.GROSS ? customTitle : name
}

export const valueBasedOnBasis = ({
  data,
  value,
  grossBuildingArea,
  incomeType,
  numberOfResidentialUnits,
}: {
  data: {
    basisValue?: string
  }
  value?: number
  grossBuildingArea?: number
  incomeType: string
  numberOfResidentialUnits: number
}) => {
  const { basisValue } = data
  let divider = null
  switch (basisValue) {
    case BASIS_OF_COMPARISON_OPTIONS.PER_SF:
      divider = grossBuildingArea
      break
    case BASIS_OF_COMPARISON_OPTIONS.PER_UNIT:
      if (incomeType !== PropertyTypes.COMMERCIAL) {
        divider = numberOfResidentialUnits
      }
      break
    case BASIS_OF_COMPARISON_OPTIONS.GROSS:
      divider = 1
      break
    default:
      return null
  }
  if (isNumber(divider) && divider !== 0 && isNumber(value)) {
    return value / divider
  }
  return isNumber(value) ? NOT_COMPUTABLE_VALUE : null
}

export const editExpenseYear = (
  expenseHistory: ExpenseYear[],
  expensePeriod: ExpenseYear,
  updatedData: {
    expensePeriod: string
    expenseMonth: string
    expenseYear: number
  }
) => {
  const [[targetExpenseYear], restYears] = partition(expenseHistory, ({ key }) => key === expensePeriod.key)
  if (!targetExpenseYear) {
    return expenseHistory
  }

  const updatedExpenseYear = {
    ...targetExpenseYear,
    expensePeriod: EXPENSE_HISTORY_TYPES[updatedData.expensePeriod],
    expenseYear: updatedData.expenseYear,
    expenseMonth: getZeroBasedMonth(updatedData.expenseMonth),
    expenseDate: getExpensePeriodDate(updatedData.expenseMonth, updatedData.expenseYear),
    isProjected: isProjectionPeriod(updatedData.expensePeriod),
  }

  const updatedExpenseHistory = [...restYears, updatedExpenseYear].sort(
    (periodA, periodB) => +new Date(periodA.expenseDate!) - +new Date(periodB.expenseDate!)
  )

  return updatedExpenseHistory
}
