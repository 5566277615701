import { GeneratedText } from '../../../narrative/GeneratedText'

import { mapDTO, mapDataModel } from './mappings'

export const SITE_IMPROVEMENT_COST_COMPS_TEXT =
  'For this assignment, we have estimated costs for any additional site improvement such as parking stalls or driveways, if applicable. We have also adjusted the base costs for any amenity package, walkways, streetlights, signage, landscaping, drainage or other miscellaneous site improvements, if applicable.'

const generate = () => {
  const gt = new GeneratedText()

  gt.t`${SITE_IMPROVEMENT_COST_COMPS_TEXT}`

  return gt.getParts()
}

export default { generate, mapDTO, mapDataModel }
