import React, { FC } from 'react'

import { Field, Form } from 'react-final-form'
import { get } from 'lodash'

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Select,
  MenuItem,
  FormControl,
  Typography,
} from '@mui/material'
import { GROSS_REVENUE, STRICT_EXPENSE_CATEGORIES } from 'shared/constants/expenses'
import { Close } from '@mui/icons-material'

import { FlattenedExpenseCategory } from '../types'

type RestoreExpenseDialogProps = {
  existingCategories: FlattenedExpenseCategory[]
  onCancel: () => void
  onSave: () => void
}

const RestoreExpenseDialog: FC<RestoreExpenseDialogProps> = ({ existingCategories = [], onCancel, onSave }) => {
  const [selectedCategoryId, setSelectedCategoryId] = React.useState('')

  const onChange = React.useCallback((event, input) => {
    const nextValue = get(event, 'target.value', '')
    input.onChange(nextValue)
    setSelectedCategoryId(nextValue)
  }, [])

  const renderMenuItems = React.useCallback(() => {
    const existingCategoriesIds = existingCategories.map(({ id }) => id).filter(id => id !== GROSS_REVENUE.key)

    return Object.entries(STRICT_EXPENSE_CATEGORIES).map(([id, name]) => {
      const isAlreadySelected = existingCategoriesIds.includes(id)

      return (
        <MenuItem key={id} value={id} disabled={isAlreadySelected}>
          {name}
        </MenuItem>
      )
    })
  }, [existingCategories])

  return (
    <Form initialValues={{ categoryId: '' }} onSubmit={onSave}>
      {({ handleSubmit, invalid, pristine }) => (
        <Dialog fullWidth maxWidth="sm" open onClose={onCancel}>
          <DialogTitle>Restore an Expense Category</DialogTitle>
          <Close
            onClick={onCancel}
            sx={{
              position: 'absolute',
              width: 24,
              cursor: 'pointer',
              right: 16,
              top: 18,
              color: '#979797',
            }}
          />
          <DialogContent>
            <Typography variant="body1">Category</Typography>
            <FormControl fullWidth size="small">
              <Field name="categoryId">
                {({ input }) => (
                  <Select
                    data-qa="restore-category-input"
                    value={selectedCategoryId}
                    onChange={event => onChange(event, input)}
                    MenuProps={{
                      sx: { '& .MuiMenu-paper': { padding: 0 } },
                    }}
                    sx={{
                      '& .MuiSelect-select .notranslate::after': {
                        content: `"Select a category"`,
                        opacity: 0.42,
                      },
                    }}
                  >
                    {renderMenuItems()}
                  </Select>
                )}
              </Field>
            </FormControl>
          </DialogContent>
          <DialogActions sx={{ padding: '2px 24px 20px 24px' }}>
            <Button onClick={onCancel}>Cancel</Button>
            <Button
              data-qa="restore-category-btn"
              disabled={pristine || invalid}
              onClick={handleSubmit}
              variant="contained"
            >
              Restore
            </Button>
          </DialogActions>
        </Dialog>
      )}
    </Form>
  )
}

export default RestoreExpenseDialog
