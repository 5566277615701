import React from 'react'
import PropTypes from 'prop-types'
import { isEmpty } from 'lodash'
import { Grid, Paper } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'
import { Link } from 'react-router-dom'

import 'leaflet/dist/leaflet.css'
import 'react-leaflet-markercluster/dist/styles.min.css'
import CartoDBConfig from '../../../../../shared/utils/cartodbConfig'

import { SubjectAndCompsMapWizard } from '../../../../../shared/components/MapWizard'

const styles = theme => ({
  paper: theme.paper,
})

class ResidentialBuildingRentCompsMap extends React.PureComponent {
  static propTypes = {
    subject: PropTypes.shape({
      coords: PropTypes.arrayOf(PropTypes.number).isRequired,
      addressOne: PropTypes.string.isRequired,
      addressTwo: PropTypes.string.isRequired,
    }).isRequired,
    mapUrl: PropTypes.string,
    buildings: PropTypes.arrayOf(
      PropTypes.shape({
        address: PropTypes.shape({
          addressOne: PropTypes.string.isRequired,
          addressTwo: PropTypes.string.isRequired,
        }).isRequired,
        coords: PropTypes.arrayOf(PropTypes.number).isRequired,
      })
    ),
  }

  get mapOptions() {
    const { subject } = this.props
    return {
      baseMap: CartoDBConfig.baseMap,
      isZoomOn: true,
      displayCenterButton: true,
      maxZoom: CartoDBConfig.subjectAndComps.maxZoom,
      initialZoom: CartoDBConfig.subjectAndComps.initialZoom,
      defaultLabelOffset: CartoDBConfig.subjectAndComps.defaultLabelOffset,
      width: CartoDBConfig.subjectAndComps.width,
      height: CartoDBConfig.subjectAndComps.height,
      initialCoordinates: subject.coords,
    }
  }

  render() {
    const { classes, subject, buildings } = this.props

    return (
      <Grid container wrap="nowrap" direction="column" spacing={16}>
        {isEmpty(buildings) ? (
          <Grid container spacing={8}>
            <Paper className={classes.paper}>
              You need to
              <Link to="residential-rent-comps">
                <u> add Building Rent Comps </u>
              </Link>
              before you can complete this step
            </Paper>
          </Grid>
        ) : (
          <SubjectAndCompsMapWizard
            name="mapUrl"
            mapType="building"
            label="Building Comparables Map"
            clearAvailable
            mapOptions={this.mapOptions}
            subject={subject}
            comps={buildings}
          />
        )}
      </Grid>
    )
  }
}

export default withStyles(styles)(ResidentialBuildingRentCompsMap)
