import React from 'react'
import PropTypes from 'prop-types'

import { Button } from '@mui/material'
import UploadFile from '@mui/icons-material/UploadFile'

class FileInputButton extends React.PureComponent {
  setInputRef = node => {
    this.input = node
  }

  handleClick = evt => {
    if (this.props.stopPropagation) {
      evt.stopPropagation()
    }

    this.input.value = ''
    this.input.click()
  }

  handleInputClick = evt => {
    if (this.props.stopPropagation) {
      evt.stopPropagation()
    }
  }

  handleFileChange = () => {
    this.props.onChange(this.input.files)
  }

  render() {
    const { accept, multiple, sx, text, useIcon, variant } = this.props

    return (
      <>
        <input
          accept={accept}
          data-qa="file-input"
          multiple={multiple}
          onChange={this.handleFileChange}
          onClick={this.handleInputClick}
          ref={this.setInputRef}
          style={{ display: 'none' }}
          type="file"
        />
        <Button
          color="primary"
          onClick={this.handleClick}
          startIcon={useIcon && <UploadFile />}
          sx={sx}
          variant={variant}
        >
          {text}
        </Button>
      </>
    )
  }
}

FileInputButton.propTypes = {
  accept: PropTypes.string,
  multiple: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  stopPropagation: PropTypes.bool,
  text: PropTypes.string,
  useIcon: PropTypes.bool,
  variant: PropTypes.string,
}

FileInputButton.defaultProps = {
  accept: '',
  multiple: false,
  stopPropagation: false,
  text: 'Upload Comps',
  useIcon: true,
  variant: 'contained',
}

export default FileInputButton
