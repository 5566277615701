import { get } from 'lodash'

import { simplePluralize } from 'shared/utils/textGeneration'

type FormValues = {
  address: string
  residentialUnits: unknown[]
  valueAsStabilized: boolean
}

type Report = {
  // eslint-disable-next-line camelcase
  property_information: {
    address: string
    resUnits: number
  }
  valueAsStabilized: boolean
}

type NarrativeValues = {
  address: string
  willOrDoesContain: string
  residentialUnitWords: string
  valueAsStabilized: boolean
}

export const mapDTO = (formValues: FormValues): NarrativeValues => {
  const { address, residentialUnits, valueAsStabilized } = formValues

  const willOrDoesContain = valueAsStabilized ? 'will contain' : 'contains'
  const residentialUnitCount = residentialUnits.length
  const residentialUnitWords = `${residentialUnitCount} residential ${simplePluralize('unit', residentialUnitCount)}`

  return {
    address,
    willOrDoesContain,
    residentialUnitWords,
    valueAsStabilized,
  }
}

export const mapDataModel = (report: Report): NarrativeValues => {
  const address = get(report, 'property_information.address')
  const valueAsStabilized = get(report, 'valueAsStabilized')
  const residentialUnitCount = get(report, 'property_information.resUnits')

  const willOrDoesContain = valueAsStabilized ? 'will contain' : 'contains'
  const residentialUnitWords = `${residentialUnitCount} residential ${simplePluralize('unit', residentialUnitCount)}`

  return {
    address,
    willOrDoesContain,
    residentialUnitWords,
    valueAsStabilized,
  }
}
