import { rentPSFLabels, TIME_PERIODS } from '../constants/report/incomeApproach'

export const getRentPSFLabel = timePeriod => {
  if (timePeriod === TIME_PERIODS.MONTHLY) {
    return rentPSFLabels.MONTHLY.INAPP
  }

  return rentPSFLabels.ANNUALLY.INAPP
}

export const getExportRentPSFLabel = timePeriod => {
  if (timePeriod === TIME_PERIODS.MONTHLY) {
    return rentPSFLabels.MONTHLY.EXPORT
  }

  return rentPSFLabels.ANNUALLY.EXPORT
}
