import React from 'react'
import PropTypes from 'prop-types'

import { Grid, Typography } from '@mui/material'
import { withStyles } from '@mui/styles'
import { includes, isEqual } from 'lodash'
import { compose, pure, shouldUpdate } from 'recompose'

import SwitchListItem from './SwitchListItem'

const styles = theme => ({
  itemsContainer: {
    maxHeight: 230,
    borderTop: `2px solid ${theme.palette.grey[200]}`,
  },
})

class SwitchListComponent extends React.PureComponent {
  handleSwitchChange = (event, checked) => {
    const { onChange, values } = this.props
    const nextValue = checked
      ? (values || []).concat([event.target.value])
      : (values || []).filter(value => value !== event.target.value)

    onChange(nextValue)
  }

  render() {
    const { items, classes, label, maxRowWidth, values } = this.props

    return (
      <Grid container direction="column" spacing={0}>
        <Grid item>
          <Typography type="subtitle1" gutterBottom>
            {label}
          </Typography>
        </Grid>
        <Grid
          item
          container
          direction="column"
          spacing={0}
          justifyContent="space-between"
          className={classes.itemsContainer}
        >
          {items.map(({ label, value }, index) => {
            return (
              <SwitchListItem
                key={`${index}-${value}`}
                checked={includes(values, value)}
                label={label}
                width={maxRowWidth}
                onChange={this.handleSwitchChange}
                value={value}
              />
            )
          })}
        </Grid>
      </Grid>
    )
  }
}

SwitchListComponent.propTypes = {
  label: PropTypes.string,
  maxRowWidth: PropTypes.number,
  items: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      value: PropTypes.string.isRequired,
    })
  ),
}

SwitchListComponent.defaultProps = {
  maxRowWidth: 160,
}
export default compose(
  pure,
  shouldUpdate((props, nextProps) => {
    return !isEqual(props.values, nextProps.values)
  }),
  withStyles(styles)
)(SwitchListComponent)
