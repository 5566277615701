import React from 'react'
import PropTypes from 'prop-types'
import { Typography, Divider, ListSubheader } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'
import { compose, onlyUpdateForPropTypes, setPropTypes } from 'recompose'

const styles = theme => ({
  header: {
    height: 48,
    display: 'flex',
    alignItems: 'center',
    color: '#bdbdbd',
  },
})

const NavigationSideBarSubHeader = ({ classes, additionalInfo }) => {
  return (
    <React.Fragment>
      <ListSubheader disableSticky className={classes.header}>
        <Typography variant="overline" align="left" component="span" color="inherit">
          {additionalInfo}
        </Typography>
      </ListSubheader>
      <Divider />
    </React.Fragment>
  )
}

export default compose(
  onlyUpdateForPropTypes,
  setPropTypes({
    additionalInfo: PropTypes.string,
  }),
  withStyles(styles)
)(NavigationSideBarSubHeader)
