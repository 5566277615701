import React from 'react'
import { isEqual } from 'lodash'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core'
import { compose, shouldUpdate } from 'recompose'
import { Draggable, Droppable } from 'react-beautiful-dnd'

import Dropzone from '../Dropzone'
import { ImageItem } from '../Image'

import { SUPPORTED_IMAGE_FORMATS } from '../../constants/imageCategories'

import withImageListModal from './withImageListModal'

const styles = {
  root: {
    display: 'flex',
    overflow: 'auto',
    width: '100%',
  },
  imageList: {
    display: 'flex',
    width: '100%',
    height: 200,
  },
  listItem: {
    maxWidth: 200,
    minWidth: 200,
    height: '100%',
    marginRight: 10,
  },
  placeholder: {
    width: 198,
    height: 198,
    marginRight: 10,
    background: 'rgba(0, 180, 255, 0.1)',
    'text-align': 'center',
    position: 'absolute',
    border: '1px dashed black',
    'z-index': 0,
    'border-radius': 5,
  },
}

const DraggableSingleImageList = ({
  images,
  classes,
  disabled,
  onDelete,
  onUpload,
  onRotate,
  category,
  fieldName,
  readOnly,
  categoryTitle,
  openImageModal,
}) => (
  <div className={classes.root}>
    <div data-qa={categoryTitle}>
      {!readOnly && (
        <Dropzone
          disabled={disabled}
          accept={SUPPORTED_IMAGE_FORMATS}
          helperText="Click or drag files to upload."
          onDrop={onUpload}
          classes={{ dropzone: classes.listItem }}
        />
      )}
    </div>
    <Droppable droppableId={fieldName} direction="horizontal">
      {(provided, snapshot) => (
        <div
          data-qa={`${categoryTitle}-image-list`}
          className={classes.imageList}
          ref={provided.innerRef}
          {...provided.droppableProps}
        >
          {snapshot.isDraggingOver && !images.length && <div className={classes.placeholder}>drop image here</div>}
          {images.map((image, index) => (
            <Draggable draggableId={`${category}-${index}`} index={index} type={category} key={image.cdnUrl}>
              {provided => {
                return (
                  <div
                    key={image.cdnUrl}
                    ref={provided.innerRef}
                    data-qa={`${index}-image`}
                    className={classes.listItem}
                    {...provided.draggableProps}
                    {...provided.dragHandleProps}
                  >
                    <ImageItem
                      index={index}
                      url={image.cdnUrl}
                      onDelete={onDelete}
                      onRotate={onRotate}
                      readOnly={readOnly}
                      isLoading={image.isLoading}
                      onClick={openImageModal}
                    />
                  </div>
                )
              }}
            </Draggable>
          ))}
        </div>
      )}
    </Droppable>
  </div>
)

DraggableSingleImageList.propTypes = {
  images: PropTypes.arrayOf(
    PropTypes.shape({
      cdnUrl: PropTypes.string.isRequired,
      uuid: PropTypes.string,
      isLoading: PropTypes.bool,
    })
  ),
  onDelete: PropTypes.func.isRequired,
  onUpload: PropTypes.func.isRequired,
  onRotate: PropTypes.func.isRequired,
  fieldName: PropTypes.string.isRequired,
  categoryTitle: PropTypes.string.isRequired,
  openImageModal: PropTypes.func.isRequired,
  category: PropTypes.string,
  readOnly: PropTypes.bool,
}

DraggableSingleImageList.defaultProps = {
  category: '',
  images: [],
  readOnly: false,
}

export default compose(
  React.memo,
  withStyles(styles),
  shouldUpdate((prev, next) => !isEqual(prev.images, next.images)),
  withImageListModal
)(DraggableSingleImageList)
