import React from 'react'
import PropTypes from 'prop-types'

import ObjectID from 'bson-objectid'
import { Form } from 'react-final-form'
import { connect } from 'react-redux'
import { isString } from 'lodash'

import { errorNotification } from '../../redux/actions/notifications'

import ImportFormReportDialogView from './ImportFromReportDialogView'

const REPORT_ID_REGEXP = /\/(report)\/(\w{24})/
const REPORT_ID_INDEX = 2
const SLASH = '/'

const parseReportSource = source => {
  if (!isString(source)) {
    return ''
  }

  if (source.includes(SLASH)) {
    const matches = source.match(REPORT_ID_REGEXP)

    if (matches && matches[REPORT_ID_INDEX]) {
      return matches[REPORT_ID_INDEX]
    }

    return ''
  }

  return source
}

class ImportFromReportDialog extends React.PureComponent {
  state = {
    isModalOpen: false,
  }

  openModal = () => {
    this.setState({ isModalOpen: true })
  }

  closeModal = () => {
    this.setState({ isModalOpen: false })
  }

  import = (values, form) => {
    const reportId = parseReportSource(values.reportSource)
    const isValidId = ObjectID.isValid(reportId)

    if (!isValidId) {
      const { errorNotification } = this.props
      return errorNotification('The report ID can not be parsed.')
    }

    if (reportId) {
      this.props.import(reportId)
    }

    setTimeout(form.reset)
    this.closeModal()
  }

  render() {
    const { openDialogButtonProps, tooltipText, tooltipPosition, dialogContent, isLoading } = this.props
    const { isModalOpen } = this.state
    const showModal = isModalOpen || isLoading

    return (
      <Form
        onSubmit={this.import}
        render={({ handleSubmit, form }) => {
          const closeModal = () => {
            setTimeout(form.reset)
            this.closeModal()
          }

          return (
            <ImportFormReportDialogView
              {...{
                closeModal,
                dialogContent,
                handleSubmit,
                isLoading,
                openModal: this.openModal,
                openDialogButtonProps,
                showModal,
                tooltipPosition,
                tooltipText,
              }}
            />
          )
        }}
      />
    )
  }
}

ImportFromReportDialog.propTypes = {
  openDialogButtonProps: PropTypes.shape({
    label: PropTypes.string.isRequired,
    variant: PropTypes.string,
  }),
  import: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
  dialogContent: PropTypes.string.isRequired,
  tooltipText: PropTypes.string,
  tooltipPosition: PropTypes.string,
}

ImportFromReportDialog.defaultProps = {
  tooltipText: null,
  tooltipPosition: 'right',
}

const mapDispatchToProps = dispatch => ({
  errorNotification: err => dispatch(errorNotification({ message: err })),
})

export default connect(null, mapDispatchToProps)(ImportFromReportDialog)
