import React from 'react'
import PropTypes from 'prop-types'

import { Grid, Typography, Paper, Stack } from '@mui/material'

import { Text, Number, DropDown, CheckboxWithLabel } from 'client-shared/components'

import { STATES_LIST_SHORT } from 'shared/constants/states'
import { ANCHOR_SITE_DETAILS } from 'shared/constants/report/dataCollections'

import { Link } from 'react-router-dom'

import { required } from 'client-shared/utils/validation'

import SiteArea from './SiteArea'

export class SiteDetails extends React.PureComponent {
  static propTypes = {
    classes: PropTypes.object.isRequired,
    form: PropTypes.object.isRequired,
    reportId: PropTypes.string.isRequired,
  }

  render() {
    const { classes, form, reportId } = this.props
    const { displayInAcresAndSF } = form.values

    return (
      <Paper id={ANCHOR_SITE_DETAILS} elevation={0} className={classes.noBackground}>
        <Typography variant="h6" className={classes.cardSpacing}>
          Site Details
        </Typography>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <Text name="streetAddress" label="Street Address" margin="normal" fullWidth />
            <Text name="streetName" label="Street Name" margin="normal" fullWidth />
            <Text name="neighborhood" label="Neighborhood/District" margin="normal" fullWidth />
            <Text name="city" label="City" margin="normal" fullWidth />
            <Text name="county" label="County" margin="normal" fullWidth />
            <Text name="zip" label="Zip Code" margin="normal" fullWidth />
            <DropDown label="State" name="state" items={STATES_LIST_SHORT} fullWidth={false} />
            <Stack direction="row" spacing={2}>
              <Number name="coords.latitude" label="Latitude" margin="normal" decimalScale={5} validate={required} />
              <Number name="coords.longitude" label="Longitude" margin="normal" decimalScale={5} validate={required} />
            </Stack>
            <Typography variant="caption">
              Modifying the coordinates won't automatically update the app. Visit{' '}
              <Link
                data-qa="maps-link"
                to={`/report/${reportId}/property-maps`}
                style={{ textDecoration: 'underline' }}
              >
                Maps
              </Link>{' '}
              to make changes.
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <SiteArea classes={classes} displayInAcresAndSF={displayInAcresAndSF} />
            <Number name="yearBuilt" label="Year Built" margin="normal" />
            <CheckboxWithLabel name="isYearBuiltEstimate" label="This is an estimate." />
          </Grid>
        </Grid>
      </Paper>
    )
  }
}
