import React from 'react'
import PropTypes from 'prop-types'
import {
  Typography,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
  Stack,
} from '@mui/material'

import Close from '@mui/icons-material/Close'

import WarningRoundedIcon from '@mui/icons-material/WarningRounded'

const DELETE_MESSAGE =
  "Are you sure you want to remove User? It's also removes all association this User from Reports. This cannot be undone."

class RemoveUserModalDialog extends React.PureComponent {
  render() {
    const { onConfirm, onCancel } = this.props
    return (
      <Dialog aria-labelledby="form-dialog-title" open={true} onEscapeKeyDown={onCancel} data-qa="form-confirm-dialog">
        <DialogTitle id="form-dialog-title" disableTypography>
          <Close
            onClick={onCancel}
            data-qa="form-confirm-cancel-btn"
            sx={{
              position: 'absolute',
              width: 24,
              cursor: 'pointer',
              right: 10,
              top: 18,
            }}
            color="primary"
          />
          <Stack direction="row" gap={0.5}>
            <WarningRoundedIcon color="warning" />
            <Typography variant="h6" color="primary">
              Remove User
            </Typography>
          </Stack>
        </DialogTitle>
        <DialogContent sx={{ width: 405 }}>
          <DialogContentText sx={{ paddingLeft: '20px' }}>{DELETE_MESSAGE}</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={onCancel} color="primary" data-qa="form-confirm-reject-btn">
            No
          </Button>
          <Button wide variant="contained" onClick={onConfirm} color="primary" data-qa="form-confirm-submit-btn">
            Yes
          </Button>
        </DialogActions>
      </Dialog>
    )
  }
}
RemoveUserModalDialog.propTypes = {
  onConfirm: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
}

export default RemoveUserModalDialog
