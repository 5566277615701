import React from 'react'
import PropTypes from 'prop-types'

import { withStyles } from '@mui/styles'
import classNames from 'classnames'

import InsertEmoticon from '../../../../images/insert_emoticon.svg'

const styles = theme => ({
  section: {
    marginBottom: 0,
    height: 36,
    position: 'relative',
  },
  optionWrapper: {},
  emojiModal: {
    overflow: 'auto',
    position: 'absolute',
    top: 35,
    display: 'flex',
    flexWrap: 'wrap',
    width: 235,
    height: 180,
    border: '1px solid #F1F1F1',
    padding: 15,
    borderRadius: 2,
    zIndex: 100,
    background: 'white',
    boxShadow: '3px 3px 5px #BFBDBD',
  },
  emojiIcon: {
    margin: 2.5,
    height: 24,
    width: 24,
    cursor: 'pointer',
    fontSize: 22,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  emojiOption: {
    fontSize: 18,
  },
})

class Emoji extends React.PureComponent {
  static propTypes = {
    expanded: PropTypes.bool,
    onExpandEvent: PropTypes.func,
    onChange: PropTypes.func,
    config: PropTypes.object,
  }

  onChange = event => {
    const { onChange } = this.props
    onChange(event.target.innerHTML)
  }

  stopPropagation = event => {
    event.stopPropagation()
  }

  onExpand = event => {
    const { onExpandEvent, onOpen } = this.props
    onExpandEvent(event)
    onOpen()
  }

  renderModal = () => {
    const {
      classes,
      config: { emojis },
    } = this.props
    return (
      <div className={classes.emojiModal} onClick={this.stopPropagation}>
        {emojis.map((emoji, index) => (
          <span key={index} className={classes.emojiIcon} alt="" onClick={this.onChange}>
            {emoji}
          </span>
        ))}
      </div>
    )
  }

  render() {
    const { classes, expanded } = this.props

    return (
      <div className={classes.section}>
        <div
          className={classNames(
            'rdw-option-wrapper',
            {
              'rdw-option-active': expanded,
            },
            classes.optionWrapper,
            classes.emojiOption
          )}
          onClick={this.onExpand}
        >
          {expanded ? '😃' : <img src={InsertEmoticon} alt="" />}
        </div>
        {expanded && this.renderModal()}
      </div>
    )
  }
}

export default withStyles(styles)(Emoji)
