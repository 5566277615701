import { min, max, mean, isNaN, find } from 'lodash'
import { createSelector } from 'reselect'

import { calculateRentPSFByTimePeriod } from '../../../../../../../shared/calculations/rentRoll'

import { MarketConclusionUnits, RentTypes } from '../../../../constants'

import { Labels } from './constants'

const filterOutVacantUnitsWithZeroRent = unit => !(unit.isVacant && !unit.rent)

export const selectFullSubjectStats = createSelector(
  props => props.rentReconciliationGroup,
  props => props.showDevelopersForecast,
  props => props.unitRentPSFTimePeriod,
  (rentReconciliationGroup, showDevelopersForecast, unitRentPSFTimePeriod) => {
    const hasMarketRateUnits = rentReconciliationGroup.units.some(unit => unit.rentType === RentTypes.MARKET_RATE)
    const units = hasMarketRateUnits
      ? rentReconciliationGroup.units.filter(unit => unit.rentType === RentTypes.MARKET_RATE)
      : rentReconciliationGroup.units

    const nonVacantUnits = units.filter(filterOutVacantUnitsWithZeroRent)
    const unitRentsPerSfPerTimePeriod = showDevelopersForecast
      ? units.map(unit => calculateRentPSFByTimePeriod(unit.rentForecast, unit.squareFootage, unitRentPSFTimePeriod))
      : nonVacantUnits.map(unit => calculateRentPSFByTimePeriod(unit.rent, unit.squareFootage, unitRentPSFTimePeriod))
    const annualUnitRents = showDevelopersForecast
      ? units.map(unit => unit.rentForecast)
      : nonVacantUnits.map(unit => unit.rent)
    const averageUnitRentPerSfPerTimePeriod = unitRentsPerSfPerTimePeriod.length
      ? mean(unitRentsPerSfPerTimePeriod)
      : null
    const averageUnitRent = annualUnitRents.length ? mean(annualUnitRents) : null
    return {
      min: { sf: min(unitRentsPerSfPerTimePeriod), month: min(annualUnitRents) },
      max: { sf: max(unitRentsPerSfPerTimePeriod), month: max(annualUnitRents) },
      avg: { sf: averageUnitRentPerSfPerTimePeriod, month: averageUnitRent },
    }
  }
)
export const selectUnitStats = createSelector(
  props => props.rentReconciliationGroup,
  props => props.showDevelopersForecast,
  props => props.unitRentPSFTimePeriod,
  (rentReconciliationGroup, showDevelopersForecast, unitRentPSFTimePeriod) => {
    const hasMarketRateUnits = rentReconciliationGroup.units.some(unit => unit.rentType === RentTypes.MARKET_RATE)

    const units = hasMarketRateUnits
      ? rentReconciliationGroup.units.filter(unit => unit.rentType === RentTypes.MARKET_RATE)
      : rentReconciliationGroup.units

    const nonVacantUnits = units.filter(filterOutVacantUnitsWithZeroRent)

    if (rentReconciliationGroup.marketConclusionUnit === MarketConclusionUnits.SF) {
      const unitRents = showDevelopersForecast
        ? units.map(unit => calculateRentPSFByTimePeriod(unit.rentForecast, unit.sqft, unitRentPSFTimePeriod))
        : nonVacantUnits.map(unit => calculateRentPSFByTimePeriod(unit.rent, unit.sqft, unitRentPSFTimePeriod))

      return {
        min: min(unitRents),
        max: max(unitRents),
        avg: unitRents.length ? mean(unitRents) : null,
      }
    }

    const unitRents = showDevelopersForecast
      ? units.map(unit => unit.rentForecast)
      : nonVacantUnits.map(unit => unit.rent)

    return {
      min: min(unitRents),
      max: max(unitRents),
      avg: unitRents.length ? mean(unitRents) : null,
    }
  }
)

export const selectUnitCompStats = createSelector(
  props => props.rentReconciliationGroup,
  props => props.unitCompGroups,
  props => props.unitRentPSFTimePeriod,
  (rentReconciliationGroup, unitCompGroups, unitRentPSFTimePeriod) => {
    const unitCompGroup = find(
      unitCompGroups,
      compGroup => compGroup.unitGroupKey === rentReconciliationGroup.unitGroupKey
    )

    if (rentReconciliationGroup.marketConclusionUnit === MarketConclusionUnits.SF) {
      const rentCompsWithSf = unitCompGroup.units.filter(comp => comp.sqft && comp.rent)
      const rentCompsRentPerSfPerTimePeriod = rentCompsWithSf.map(comp =>
        calculateRentPSFByTimePeriod(comp.rent, comp.sqft, unitRentPSFTimePeriod)
      )

      return {
        min: min(rentCompsRentPerSfPerTimePeriod),
        max: max(rentCompsRentPerSfPerTimePeriod),
        avg: rentCompsRentPerSfPerTimePeriod.length ? mean(rentCompsRentPerSfPerTimePeriod) : null,
      }
    }

    const rents = unitCompGroup.units.map(comp => comp.rent)
    return {
      min: min(rents),
      max: max(rents),
      avg: rents.length ? mean(rents) : null,
    }
  }
)

const formatBuildingGroupingKey = (unitMixItem, groupedByBathrooms) =>
  groupedByBathrooms ? `${unitMixItem.groupingKey}_bathrooms_${unitMixItem.avgBathrooms ?? 0}` : unitMixItem.groupingKey

export const selectAllBuildingCompStats = createSelector(
  props => props.rentReconciliationGroup,
  props => props.selectedBuildingComps,
  props => props.unitRentPSFTimePeriod,
  (rentReconciliationGroup, selectedBuildingComps, unitRentPSFTimePeriod) => {
    const groupedByBathrooms = rentReconciliationGroup.unitGroupKey?.includes('bathrooms')

    const rentsPerMonth = selectedBuildingComps
      .map(buildingComp => {
        const unitMixItem = find(
          buildingComp.unitMix,
          unitMixItem =>
            formatBuildingGroupingKey(unitMixItem, groupedByBathrooms) === rentReconciliationGroup.unitGroupKey
        )

        if (!unitMixItem) {
          return NaN
        }
        return unitMixItem.avgRent
      })
      .filter(rent => !isNaN(rent))
    const rentsPerSfPerTimePeriod = selectedBuildingComps
      .map(buildingComp => {
        const unitMixItem = find(
          buildingComp.unitMix,
          unitMixItem =>
            formatBuildingGroupingKey(unitMixItem, groupedByBathrooms) === rentReconciliationGroup.unitGroupKey
        )

        if (!unitMixItem) {
          return NaN
        }

        return calculateRentPSFByTimePeriod(unitMixItem.avgRent, unitMixItem.avgSquareFeet, unitRentPSFTimePeriod)
      })
      .filter(rent => !isNaN(rent))
    return {
      min: { sf: min(rentsPerSfPerTimePeriod), month: min(rentsPerMonth) },
      max: { sf: max(rentsPerSfPerTimePeriod), month: max(rentsPerMonth) },
      avg: { sf: mean(rentsPerSfPerTimePeriod), month: mean(rentsPerMonth) },
    }
  }
)

export const selectBuildingCompStats = createSelector(
  props => props.rentReconciliationGroup,
  props => props.buildingComps,
  props => props.unitRentPSFTimePeriod,
  (rentReconciliationGroup, buildingComps, unitRentPSFTimePeriod) => {
    const rents = buildingComps
      .map(buildingComp => {
        const unitMixItem = find(
          buildingComp.unitMix,
          unitMixItem => unitMixItem.groupingKey === rentReconciliationGroup.unitGroupKey
        )

        if (!unitMixItem) {
          return NaN
        }

        if (rentReconciliationGroup.marketConclusionUnit === MarketConclusionUnits.SF) {
          return calculateRentPSFByTimePeriod(unitMixItem.avgRent, unitMixItem.avgSquareFeet, unitRentPSFTimePeriod)
        }
        return unitMixItem.avgRent
      })
      .filter(rent => !isNaN(rent))

    return {
      min: min(rents),
      max: max(rents),
      avg: rents.length ? mean(rents) : null,
    }
  }
)

export const selectConclusionPerSFAvailability = createSelector(
  props => props.rentReconciliationGroup,
  props => props.unitCompGroups,
  props => props.showPerUnitSF,
  (rentReconciliationGroup, unitCompGroups, showPerUnitSF) => {
    const reasonsForBeingDisabled = []
    if (!showPerUnitSF) {
      reasonsForBeingDisabled.push(Labels.RENT_ROLL_SF_REQUIRED)
    }

    const unitCompGroup = find(
      unitCompGroups,
      compGroup => compGroup.unitGroupKey === rentReconciliationGroup.unitGroupKey
    )

    const rentComps = unitCompGroup.units
    const rentCompsWithDefinedArea = rentComps.filter(comp => comp.sqft && comp.rent)
    const allRentCompsHaveDefinedArea = rentComps.length === rentCompsWithDefinedArea.length

    if (!allRentCompsHaveDefinedArea) {
      reasonsForBeingDisabled.push(`${Labels.ALL} ${rentReconciliationGroup.unitGroupName} ${Labels.COMPS_NEED_SF}`)
    }

    return {
      isDisabled: reasonsForBeingDisabled.length > 0,
      reasons: reasonsForBeingDisabled,
    }
  }
)
