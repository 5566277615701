import React from 'react'

import { useSelector } from 'client-shared/hooks/redux'
import { GEOGRAPHY_OPTIONS } from 'client-shared/constants/properties'
import { reportSettingsSelector } from 'report/forms/income/TaxInformation/selectors'

export const useAutomatedZoningIsEnabledForReport = () => {
  const locationIdentifier = useSelector(reportSettingsSelector).locationIdentifier

  return locationIdentifier !== GEOGRAPHY_OPTIONS.NY
}

export const withAutomatedZoningIsEnabledForReport = (Component: React.ComponentType<any>) => {
  const WithAutomatedZoning = (props: any) => {
    const automatedZoningEnabled = useAutomatedZoningIsEnabledForReport()

    return <Component {...props} automatedZoningEnabled={automatedZoningEnabled} />
  }

  return WithAutomatedZoning
}
