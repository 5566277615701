import React from 'react'
import PropTypes from 'prop-types'
import { DialogContent } from '@mui/material'

import FullScreenDialog from '../shared/FullScreenDialog'

import PropertySearch from './PropertySearch'

const DIALOG_TITLE = 'Property Search'
const DATA_QA_CLOSE_ICON = 'property-search-close-btn'
const DATA_QA_DIALOG = 'property-search-dialog'

const PropertySearchDialog = ({ open, onClose, onSave }) => (
  <FullScreenDialog
    confirm={onClose}
    content={{
      dialogTitle: DIALOG_TITLE,
    }}
    dataQAAttributes={{ closeIcon: DATA_QA_CLOSE_ICON, dialog: DATA_QA_DIALOG }}
    open={open}
  >
    <DialogContent>
      <PropertySearch onSave={onSave} />
    </DialogContent>
  </FullScreenDialog>
)

PropertySearchDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onSave: PropTypes.func,
  initialProperties: PropTypes.arrayOf(PropTypes.object),
  compPlexLoaded: PropTypes.bool.isRequired,
}

export default PropertySearchDialog
