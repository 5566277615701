export const formatFullAddress = comp => {
  const { streetAddress, city, state, postalCode } = comp.address
  return `${streetAddress} ${city} ${state} ${postalCode}`
}

export const getStatus = comp => {
  return comp.status ? comp.status : comp.verificationInformation?.status
}

export const searchResultDataQA = attributeName => `search-result-${attributeName}`
