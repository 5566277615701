import React from 'react'
import { Form } from 'react-final-form'

import {
  Box,
  Button,
  Card,
  Dialog,
  IconButton,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'
import Edit from '@mui/icons-material/Edit'
import { differenceBy, get } from 'lodash'
import { connect } from 'react-redux'

import { formatCurrencyFloat, formatCurrencyInt } from 'client-shared/utils/numberFormatters'
import { getSourceOfInformation } from 'client-shared/components/SourceOfInformation'
import { errorNotification } from 'client-shared/redux/actions/notifications'
import { WIZARD_STEPS } from 'client-shared/constants/properties'
import { TextField } from 'client-shared/components/Fields'
import { STATES_LIST_SHORT } from 'shared/constants/states'
import SelectField from 'client-shared/components/_mui5/Select/select-field'

import { divide } from 'shared/utils/numberOperations'
import { getFormattedBasisValue } from 'shared/report-calculations/income-approach/tax/helpers'
import { BASIS_TYPES, BASIS_LABELS } from 'shared/constants/report/incomeApproach/taxes'
import TaxCalculations from 'shared/report-calculations/income-approach/tax/tax-calculations'

import { CheckboxWithLabel, Number as NumberField, PropertyWizard } from '../../../../../shared/components'
import AdditionalInformation from '../../../../../shared/components/PropertyWizard/AdditionalInformation'
import CompsImportForm from '../../../../../shared/components/CompsImportForm'
import * as Api from '../../../../api'

import DropDownButton from '../../../../../shared/components/DropDown/DropDownButton'

import AdvancedSearchModal from './AdvancedSearchModalContainer'
import NewTaxCompModal from './NewTaxCompModal'

class SelectedTaxCompsTable extends React.PureComponent {
  state = {
    compIndex: null,
    compToEdit: null,
    isAdvancedSearchOpen: false,
    isNewTaxCompModalOpen: false,
    isTaxCompsImporting: false,
    open: false,
  }

  toggleAdvancedSearch = () => {
    this.setState({ isAdvancedSearchOpen: !this.state.isAdvancedSearchOpen })
  }

  add = property => {
    const { form } = this.props
    form.mutators.push('taxComps', {
      address: property.address,
      city: property.city,
      state: property.state,
      postalCode: property.zip,
      yearBuilt: get(property, 'yearBuilt'),
      basis: 0,
      taxesPerBasis: 0,
      sourceOfInformation: get(property, 'sourceOfInformation'),
      sourceName: get(property, 'sourceName'),
      sourceUrl: get(property, 'sourceUrl'),
      taxableAssessedValue: 0,
      assessmentPerBasisValue: 0,
    })
    this.handleClose()
  }

  addNewTaxComp = taxCompsToAdd => {
    const { form } = this.props

    form.mutators.concat('taxComps', taxCompsToAdd)
  }

  addPerBasisColumn = () => {
    const { form } = this.props
    form.change('isPerBasisColumnAdded', true)
  }

  addAssessedValueColumn = () => {
    const { form } = this.props

    form.change('isAssessedValueColumnAdded', true)
  }

  remove = index => {
    const { form } = this.props
    const taxComps = get(form.values, 'taxComps', [])
    const removedTaxComp = taxComps[index]
    form.mutators.remove('taxComps', index)
    form.mutators.push('removedTaxComps', removedTaxComp)
  }

  addNew = () => {
    this.setState({ compToEdit: null })
    this.toggleNewTaxCompModal()
  }

  toggleNewTaxCompModal = () => {
    this.setState({ isNewTaxCompModalOpen: !this.state.isNewTaxCompModalOpen })
  }

  handleOpen = () => {
    this.setState({ open: true })
  }

  handleClose = () => {
    this.setState({ open: false })
  }

  toggleEdit = (comp, index) => {
    this.setState({
      compToEdit: comp,
      compIndex: index,
    })
  }

  closeEdit = () => {
    this.setState({
      compToEdit: null,
      compIndex: null,
    })
  }

  saveComp = ({ sourceOfInformation, sourceName, sourceUrl }) => {
    const { fields } = this.props
    const { compToEdit, compIndex } = this.state
    fields.value[compIndex] = { ...compToEdit, sourceOfInformation, sourceName, sourceUrl }
    this.closeEdit()
  }

  assessmentAndPerBasisToggle = option => {
    const { form } = this.props
    const isAssessedValueColumnAdded = get(form.values, 'isAssessedValueColumnAdded', false)
    const isPerBasisColumnAdded = get(form.values, 'isPerBasisColumnAdded', false)

    if (option.value === 'assessmentValue') {
      form.focus('isAssessedValueColumnAdded')
      if (isAssessedValueColumnAdded) {
        form.change('isAssessedValueColumnAdded', false)
      } else {
        this.addAssessedValueColumn()
      }
    } else {
      form.focus('isPerBasisColumnAdded')
      if (isPerBasisColumnAdded) {
        form.change('isPerBasisColumnAdded', false)
      } else {
        this.addPerBasisColumn()
      }
    }
  }

  importTaxComps = reportId => {
    const { form, errorNotification } = this.props
    const { basis, taxComps } = form.values

    this.setState({ isTaxCompsImporting: true }, async () => {
      try {
        const importedTaxComps = await Api.fetchTaxCompsByReportId(reportId, basis)
        const newTaxComps = differenceBy(importedTaxComps, taxComps, 'address')
        const mappedNewTaxComps = newTaxComps.map(({ taxes_basis, year, ...otherTaxCompsInfo }) => ({
          taxesPerBasis: taxes_basis,
          yearBuilt: year,
          ...otherTaxCompsInfo,
        }))

        form.mutators.concat('taxComps', mappedNewTaxComps)
      } catch (error) {
        errorNotification(error.response.data.error)
      }

      this.setState({ isTaxCompsImporting: false })
    })
  }

  render() {
    const { form } = this.props
    const {
      basis,
      grossBuildingArea,
      projected,
      residentialUnitCount,
      taxComps,
      taxLiability,
      taxYear,
      yearBuilt,
      taxableAssessedValue,
      isPerBasisColumnAdded,
      isAssessedValueColumnAdded,
    } = form.values
    const { isAdvancedSearchOpen, isNewTaxCompModalOpen, open, compToEdit, isTaxCompsImporting } = this.state
    const { concludedLiabilityPerBasis } = projected

    const hasSelectedTaxComps = taxComps.length > 0
    let basisLabel = BASIS_LABELS.SQUARE_FEET
    let taxesPerBasisLabel = BASIS_LABELS.TAXES_PSF
    let perBasisTaxLabel = BASIS_LABELS.ASSESSED_PSF
    let perUnitAndPsfPrecision = 2
    const subjectBasisValue = getFormattedBasisValue(basis, grossBuildingArea, residentialUnitCount)
    const subjectLiabilityPerBasis = TaxCalculations.calculateTaxesPerBasis(
      basis,
      taxLiability,
      grossBuildingArea,
      residentialUnitCount
    )
    let taxableAssessedValuePerBasis = divide(taxableAssessedValue, grossBuildingArea)
    if (basis === BASIS_TYPES.PER_UNIT) {
      basisLabel = BASIS_LABELS.NUMBER_OF_UNITS
      taxesPerBasisLabel = BASIS_LABELS.TAXES_PER_UNIT
      perBasisTaxLabel = BASIS_LABELS.ASSESSED_PER_UNIT
      perUnitAndPsfPrecision = 0
      taxableAssessedValuePerBasis = divide(taxableAssessedValue, residentialUnitCount)
    }
    const addColumnButtonOptions = [
      { label: 'Assessment Value', value: 'assessmentValue' },
      {
        label: 'Assessment Per Basis',
      },
    ]
    const subjectLiability = projected.includedInExport ? concludedLiabilityPerBasis : subjectLiabilityPerBasis
    return (
      <Paper sx={{ width: 1 }}>
        <PropertyWizard
          handleClose={this.handleClose}
          nextStep={WIZARD_STEPS.ADDITIONAL_INFO}
          onSubmit={this.add}
          open={open}
          title="Find Tax Comparable"
        >
          <PropertyWizard.Step step={WIZARD_STEPS.ADDITIONAL_INFO}>
            {({ submit, openPreviousStep, previousStep, invalid }) => (
              <AdditionalInformation
                cancel={openPreviousStep}
                cancelText={`Back to ${previousStep}`}
                invalid={invalid}
                submit={submit}
                submitText="CREATE COMP"
              />
            )}
          </PropertyWizard.Step>
        </PropertyWizard>

        <Dialog open={!!compToEdit} onClose={this.closeEdit} maxWidth="md" aria-labelledby="form-dialog-title">
          <Form
            initialValues={compToEdit}
            onSubmit={this.saveComp}
            render={({ handleSubmit, invalid }) => (
              <AdditionalInformation
                cancel={this.closeEdit}
                invalid={invalid}
                submit={handleSubmit}
                submitText="UPDATE"
              />
            )}
          />
        </Dialog>
        {isNewTaxCompModalOpen && (
          <NewTaxCompModal onAdd={this.addNewTaxComp} form={form} onClose={this.toggleNewTaxCompModal} />
        )}
        {isAdvancedSearchOpen && (
          <AdvancedSearchModal
            yearBuilt={yearBuilt}
            taxLiability={projected.includedInExport && projected.taxLiability ? projected.taxLiability : taxLiability}
            grossBuildingArea={grossBuildingArea}
            basis={basis}
            residentialUnitCount={residentialUnitCount}
            onAdd={this.addNewTaxComp}
            onClose={this.toggleAdvancedSearch}
          />
        )}
        <Stack sx={{ pb: 1.5, pr: 0, mr: 0 }} alignItems="flex-end">
          <CheckboxWithLabel fullWidth={false} label="Include in export" name="isComparablesTableAddedToExport" />
        </Stack>
        <Stack alignItems="center" direction="row">
          <Stack alignItems="center" direction="row" spacing={1}>
            <Button variant="contained" onClick={this.handleOpen} data-qa="create-cap-rate-comp-btn">
              Add Tax Comp
            </Button>
            <Button variant="outlined" onClick={this.toggleAdvancedSearch} data-qa="advanced-search-btn">
              Advanced Search
            </Button>
            <CompsImportForm
              import={this.importTaxComps}
              isLoading={isTaxCompsImporting}
              tooltipText="Make sure the Concluded Liability Basis is the same between the report you are importing from and your report or else the import will fail."
            />
          </Stack>
          <Box mx="auto" />
          <DropDownButton
            data-qa="taxable-assessed-value-add-column-btn"
            variant="contained"
            onSelect={this.assessmentAndPerBasisToggle}
            options={addColumnButtonOptions}
          >
            Add Column
          </DropDownButton>
          &nbsp;&nbsp;
          <Button variant="contained" onClick={this.addNew} data-qa="add-blank-row-btn">
            Add Blank Row
          </Button>
        </Stack>
        <Card elevation={0}>
          <Table data-qa="comparable-tax-liabilities-table" size="small">
            <TableHead sx={{ '& .MuiTableCell-head': { fontSize: 12, fontWeight: 700 } }}>
              <TableRow>
                <TableCell>Address</TableCell>
                <TableCell>City</TableCell>
                <TableCell>State</TableCell>
                <TableCell>Year Built</TableCell>
                <TableCell>Est.</TableCell>
                <TableCell>Tax Year</TableCell>
                <TableCell>{basisLabel}</TableCell>
                {isAssessedValueColumnAdded && <TableCell>Assessed Value</TableCell>}
                {isPerBasisColumnAdded && <TableCell>{perBasisTaxLabel}</TableCell>}
                <TableCell>{taxesPerBasisLabel}</TableCell>
                <TableCell>Source Of Information</TableCell>
                <TableCell>Actions</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow>
                <TableCell>Subject</TableCell>
                <TableCell></TableCell>
                <TableCell></TableCell>
                <TableCell>{yearBuilt}</TableCell>
                <TableCell>
                  <CheckboxWithLabel name="isYearBuiltEstimate" disabled />
                </TableCell>
                <TableCell>{taxYear}</TableCell>
                <TableCell>{subjectBasisValue}</TableCell>
                {isAssessedValueColumnAdded && <TableCell>{formatCurrencyInt(taxableAssessedValue)}</TableCell>}
                {isPerBasisColumnAdded && <TableCell>{formatCurrencyFloat(taxableAssessedValuePerBasis)}</TableCell>}
                <TableCell>{formatCurrencyFloat(subjectLiability, 2)}</TableCell>
                <TableCell>Bowery Subject</TableCell>
                <TableCell />
              </TableRow>
              {hasSelectedTaxComps &&
                taxComps.map((comp, index) => {
                  const sourceOfInformation = getSourceOfInformation(comp)
                  return (
                    <TableRow key={index} data-qa={`row-${index}`}>
                      <TableCell data-qa="address-cell">
                        <TextField
                          style={{ minWidth: '200px', marginBottom: '0px' }}
                          name={`taxComps[${index}].address`}
                          margin="normal"
                        />
                      </TableCell>
                      <TableCell data-qa="city-cell">
                        <TextField name={`taxComps[${index}].city`} margin="normal" style={{ marginBottom: '0px' }} />
                      </TableCell>
                      <TableCell data-qa="state-cell">
                        <SelectField
                          sx={{ height: '36px' }}
                          name={`taxComps[${index}].state`}
                          options={STATES_LIST_SHORT}
                        />
                      </TableCell>
                      <TableCell data-qa="yearBuilt-cell">
                        <NumberField
                          allowNegative={false}
                          name={`taxComps[${index}].yearBuilt`}
                          placeholder="Year Built"
                          thousandSeparator={false}
                        />
                      </TableCell>
                      <TableCell data-qa="isYearBuiltEstimate-cell">
                        <CheckboxWithLabel name={`taxComps[${index}].isYearBuiltEstimate`} />
                      </TableCell>
                      <TableCell data-qa="taxYear-cell">
                        <NumberField
                          name={`taxComps[${index}].taxYear`}
                          placeholder="Tax Year"
                          thousandSeparator={false}
                        />
                      </TableCell>
                      <TableCell data-qa="basis-cell">
                        <NumberField
                          decimalScale={2}
                          margin="normal"
                          name={`taxComps[${index}].basis`}
                          placeholder={basisLabel}
                          thousandSeparator
                        />
                      </TableCell>
                      {isAssessedValueColumnAdded && (
                        <TableCell data-qa="taxableAssessedValue-cell">
                          <NumberField
                            name={`taxComps[${index}].taxableAssessedValue`}
                            prefix="$"
                            decimalScale={0}
                            allowNegative={false}
                            placeholder="Assessed Value"
                          />
                        </TableCell>
                      )}
                      {isPerBasisColumnAdded && (
                        <TableCell data-qa="assessmentPerBasis-cell">
                          <NumberField
                            name={`taxComps[${index}].assessmentPerBasisValue`}
                            margin="normal"
                            fullWidth
                            prefix="$"
                            thousandSeparator
                            decimalScale={perUnitAndPsfPrecision}
                            allowNegative={false}
                            placeholder="Per Basis Value"
                          />
                        </TableCell>
                      )}
                      <TableCell data-qa="taxesPerBasis-cell">
                        <NumberField
                          allowNegative={false}
                          decimalScale={2}
                          fullWidth
                          margin="normal"
                          name={`taxComps[${index}].taxesPerBasis`}
                          placeholder={taxesPerBasisLabel}
                          prefix="$"
                          thousandSeparator
                        />
                      </TableCell>
                      <TableCell data-qa="sourceOfInfo-cell">
                        <div> {sourceOfInformation}</div>
                      </TableCell>
                      <TableCell data-qa="delete-btn">
                        <Stack spacing={0.5} direction="row">
                          <IconButton aria-label="edit" onClick={() => this.toggleEdit(comp, index)}>
                            <Edit />
                          </IconButton>
                          <IconButton onClick={() => this.remove(index)} aria-label="remove">
                            <CloseIcon />
                          </IconButton>
                        </Stack>
                      </TableCell>
                    </TableRow>
                  )
                })}
            </TableBody>
          </Table>
        </Card>
      </Paper>
    )
  }
}

const mapDispatchToProps = dispatch => ({
  errorNotification: err => dispatch(errorNotification({ message: err })),
})

export default connect(null, mapDispatchToProps)(SelectedTaxCompsTable)
