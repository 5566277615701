import React from 'react'
import PropTypes from 'prop-types'

import { Grid, Stack, Typography } from '@mui/material'

import { PropertyTypes } from 'shared/constants'
import { CheckboxWithLabel, RadioButtonList } from 'client-shared/components'
import { required } from 'client-shared/utils/validation'

import { TEMPLATE_TYPES, VALUE_CONCLUSION_TYPES } from 'shared/constants/settings'

import { VALUE_CONCLUSION_DESCRIPTIONS } from '../../constants/settings'

import { IncomeTypeLabel, TemplateTypeLabel, ValueConclusionTypeLabel } from './Labels'

const radioItem = item => {
  const value = TEMPLATE_TYPES[item]
  return {
    label: <TemplateTypeLabel value={value} />,
    value,
  }
}

const conclusionItem = item => {
  const value = VALUE_CONCLUSION_TYPES[item]
  return {
    label: (
      <Stack>
        <ValueConclusionTypeLabel value={value} />
        <Typography color="text.primary" variant="caption">
          {VALUE_CONCLUSION_DESCRIPTIONS[value]}
        </Typography>
      </Stack>
    ),
    value: value,
  }
}

const incomeTypeItem = item => ({
  label: <IncomeTypeLabel value={PropertyTypes[item]} />,
  value: PropertyTypes[item],
})

const listFactory = ({ list, itemTemplate }) => list.map(itemTemplate)

const ReportSettings = ({ disabled }) => {
  const templateRadioItems = React.useMemo(
    () => listFactory({ list: ['FREDDIE_MAC', 'BOWERY_WAY'], itemTemplate: radioItem }),
    []
  )

  const incomeTypeItems = React.useMemo(
    () =>
      listFactory({
        list: ['MULTI_FAMILY', 'MIXED_USE', 'COMMERCIAL'].filter(Boolean),
        itemTemplate: incomeTypeItem,
      }),
    []
  )

  const valueConclusionItems = React.useMemo(
    () => listFactory({ list: ['AS_IS', 'AS_STABILIZED', 'AS_COMPLETE'], itemTemplate: conclusionItem }),
    []
  )

  return (
    <Stack spacing={-1}>
      <Typography variant="body2" sx={{ paddingBottom: '8px', color: 'rgba(0, 0, 0, 0.6)' }}>
        Approaches to Value
      </Typography>
      <Grid container paddingBottom={2}>
        <Grid item>
          <CheckboxWithLabel
            name="settings.approaches.income"
            input={{ checked: true }}
            disabled
            label="Income Approach"
          />
        </Grid>
        <Grid item>
          <CheckboxWithLabel name="settings.approaches.improvedSales" label="Sales Comparison Approach" />
        </Grid>
        <Grid item>
          <CheckboxWithLabel name="settings.approaches.cost" label="Cost Approach" />
        </Grid>
        <Grid item>
          <CheckboxWithLabel name="settings.approaches.land" label="Land Valuation" />
        </Grid>
      </Grid>
      <RadioButtonList
        disabled={disabled}
        items={templateRadioItems}
        label="Freddie Mac"
        name="settings.templateType"
        sx={{ mb: 1 / 8 }}
        validate={required}
      />
      <RadioButtonList
        disabled={disabled}
        items={incomeTypeItems}
        label="Income Type"
        name="settings.incomeType"
        sx={{ mb: 1 / 8 }}
        validate={required}
      />
      <RadioButtonList
        disabled={disabled}
        items={valueConclusionItems}
        label="Value Conclusion"
        name="settings.valueConclusionType"
        sx={{ mb: 1 / 4 }}
        validate={required}
      />
    </Stack>
  )
}

ReportSettings.defaultProps = {
  disabled: false,
}

ReportSettings.propTypes = {
  disabled: PropTypes.bool,
}

ReportSettings.displayName = 'ReportSettings'

export default ReportSettings
