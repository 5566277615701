import { createSelector } from 'reselect'
import { get } from 'lodash'

import { COMMERCIAL_RENT_COMPS_PATH } from 'shared/constants/report/keysAndDataPaths'

import { UnsortedCompGroup } from '../constants'

export const DATA_PATH = COMMERCIAL_RENT_COMPS_PATH

export const rentCompGroupsSelector = createSelector(
  state => get(state, 'report.reportData.incomeApproach.commercialIncome.commercialCompGroups.compGroups', []),
  (state, ownProps) => ownProps.selectedRentComps,
  (groups, selectedRentComps) => {
    const availableCompGroups = [UnsortedCompGroup]

    const unsortedRentComps = selectedRentComps
      .filter(unit => !groups.some(group => group._id === unit.commercialCompGroupId))
      .sort((rentCompA, rentCompB) => rentCompA.displayOrder - rentCompB.displayOrder)

    const compGroups = [
      {
        _id: UnsortedCompGroup.value,
        name: UnsortedCompGroup.label,
        units: unsortedRentComps || [],
      },
    ]

    groups.forEach(compGroup => {
      availableCompGroups.push({ value: compGroup._id, label: compGroup.name })

      const unitsInGroup = selectedRentComps
        .filter(unit => unit.commercialCompGroupId === compGroup._id)
        .sort((rentCompA, rentCompB) => rentCompA.displayOrder - rentCompB.displayOrder)

      compGroups.push({
        _id: compGroup._id,
        name: compGroup.name,
        units: unitsInGroup || [],
      })
    })

    return { availableCompGroups, compGroups }
  }
)
