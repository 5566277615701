/**
 * Overriding styles for DialogTitle
 */

const root = () => ({
  fontFamily: 'Nunito Sans',
  fontSize: '20px',
  fontWeight: '600',
})

export default {
  styleOverrides: {
    root,
  },
}
