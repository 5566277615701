import React, { FC, memo } from 'react'

import { get, noop } from 'lodash'
import { ColumnDataTypeEnum, RowBasedTable } from '@bowery-valuation/ui-components'

import { formatCurrencyFloat, formatPercentageString } from 'client-shared/utils/numberFormatters'

// todo - these belong in a shared location
import { CELL_EDITOR_SELECTORS } from 'report/forms/income/TaxInformation/constants'

import { divide } from 'shared/utils/numberOperations'
import { getRowDefinitions } from 'shared/constants/costApproach'
import { getCustomColumnConfig, RowBasedTableColumn } from 'client-shared/utils/rowBasedTable'
import { FormBatch, FormChange } from 'client-shared/utils/form'
import { SubjectBudgetType } from 'shared/types/costApproachTypes'

type SubjectBudgetTableOwnProps = {
  change: FormChange
  batch: FormBatch
  subjectBudget: SubjectBudgetType
  field: string
  title: string
  isEditable?: boolean
  grossBuildingArea: number
  numberOfResidentialUnits: number
}

const SubjectBudgetTable: FC<SubjectBudgetTableOwnProps> = ({
  change,
  batch,
  subjectBudget,
  field,
  title,
  isEditable = true,
  grossBuildingArea,
  numberOfResidentialUnits,
}) => {
  const rowDefinitions = getRowDefinitions(field)
  const totalCosts = get(subjectBudget, `${field}.totalCosts`, 0)

  const getColumns = (title: string): RowBasedTableColumn[] => {
    return [
      {
        name: 'label',
        label: title,
        type: ColumnDataTypeEnum.text,
        permanent: true,
        align: 'left',
        editable: false,
      },
      {
        name: 'total',
        label: 'Total',
        type: ColumnDataTypeEnum.text,
        permanent: true,
        align: 'right',
        editable: params => {
          return isEditable && !!findRowDefinition(params.data)
        },
        cellEditorSelector: () => {
          return CELL_EDITOR_SELECTORS.moneyTwoDecimals
        },
      },
      {
        name: 'totalPerSf',
        label: 'Per SF',
        type: ColumnDataTypeEnum.text,
        permanent: true,
        align: 'right',
        editable: false,
      },
      {
        name: 'totalPerUnit',
        label: 'Per Unit',
        type: ColumnDataTypeEnum.text,
        permanent: true,
        align: 'right',
        editable: false,
      },
      {
        name: 'percentOfTotal',
        label: '%  of Total',
        type: ColumnDataTypeEnum.percent,
        permanent: true,
        align: 'right',
        editable: false,
      },
    ]
  }

  const findRowDefinition = (row: any) => {
    for (const prop in rowDefinitions) {
      const rowDefinition = rowDefinitions[prop]
      if (rowDefinition.fieldPath === row.id) {
        return rowDefinition
      }
    }
    return null
  }

  const handleRowUpdate = (row: any) => {
    const rowDefinition = findRowDefinition(row)
    if (rowDefinition) {
      change(rowDefinition.fieldPath, row.total)
    }
  }
  const handleManyRowsUpdate = (rows: any) => {
    batch(() => {
      rows.forEach((row: any) => {
        handleRowUpdate(row)
      })
    })
  }

  const getLineItemRow = (
    id: string,
    label: string,
    total: number,
    grossBuildingArea: number,
    numberOfResidentialUnits: number
  ) => {
    return {
      readOnly: false,
      suppressMovable: true,
      permanent: false,
      type: ColumnDataTypeEnum.text,
      label,
      id,
      total: formatCurrencyFloat(total ?? 0),
      totalPerSf: formatCurrencyFloat(divide(total, grossBuildingArea)),
      totalPerUnit: formatCurrencyFloat(divide(total, numberOfResidentialUnits)),
      percentOfTotal: formatPercentageString(divide(total, totalCosts), 0),
      rowDef: { hideAction: true },
    }
  }

  const rows = []

  for (const prop in rowDefinitions) {
    const rowDefinition = rowDefinitions[prop]
    rows.push(
      getLineItemRow(
        rowDefinition.fieldPath,
        rowDefinition.label,
        get(subjectBudget, rowDefinition.fieldPath.replace('subjectBudget.', ''), 0),
        grossBuildingArea,
        numberOfResidentialUnits
      )
    )
  }

  rows.push({
    readOnly: true,
    suppressMovable: true,
    permanent: true,
    type: ColumnDataTypeEnum.text,
    id: 'totalCosts',
    label: 'Total',
    total: formatCurrencyFloat(totalCosts),
    totalPerSf: formatCurrencyFloat(divide(totalCosts, grossBuildingArea)),
    totalPerUnit: formatCurrencyFloat(divide(totalCosts, numberOfResidentialUnits)),
    percentOfTotal: '100%',
    rowDef: { summary: true, hideAction: true },
  })

  return (
    <RowBasedTable
      id="subject-budget-table"
      columns={getColumns(title)}
      rows={rows}
      onRowUpdate={handleRowUpdate}
      onManyRowsUpdate={handleManyRowsUpdate}
      getCustomColumnConfig={getCustomColumnConfig}
      actionCellHidden
      hideIndexColumn
      onColumnDragEnd={noop}
      onRowsDragEnd={noop}
      onColumnDelete={noop}
      onColumnUpdate={noop}
      onRowDelete={noop}
    />
  )
}

export default memo(SubjectBudgetTable)
