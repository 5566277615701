import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import { compose, pure } from 'recompose'
import { Toolbar, AppBar } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'

import Logo from '../../../shared/components/Logo'
import { NAV_HEADER_HEIGHT } from '../constants/styles'
import { logoutUser } from '../../../authentication/redux/actions'
import {
  REPORT_ROUTE as reportRoute,
  USER_ROUTE as userRoute,
  ORGANIZATION_ROUTE as organizationRoute,
  LOGOUT_ROUTE as logoutRoute,
} from '../../../core/routes'

const styles = theme => ({
  toolbar: {
    minHeight: NAV_HEADER_HEIGHT,
    marginRight: theme.spacing.unit * 3,
  },
  menuItem: {
    background: 'none',
    color: '#fff',
    border: 0,
    cursor: 'pointer',
    padding: '1px 6px',
  },
  logoWrapper: {
    display: 'flex',
    minWidth: 230,
    alignItems: 'center',
    minHeight: NAV_HEADER_HEIGHT,
    backgroundColor: 'white',
  },
  logo: {
    height: 50,
  },
})

const TopNavigationMenu = ({ classes, children, logoutUser }) => (
  <AppBar position="sticky">
    <Toolbar className={classes.toolbar} disableGutters>
      <div className={classes.logoWrapper}>
        <Logo classes={{ logo: classes.logo }} />
      </div>
      {children}
      <Link to={reportRoute.path} className={classes.menuItem}>
        <reportRoute.icon />
      </Link>
      <Link to={userRoute.path} className={classes.menuItem}>
        <userRoute.icon />
      </Link>
      <Link to={organizationRoute.path} className={classes.menuItem}>
        <organizationRoute.icon />
      </Link>
      <span className={classes.menuItem} onClick={logoutUser}>
        <logoutRoute.icon />
      </span>
    </Toolbar>
  </AppBar>
)

TopNavigationMenu.propTypes = {
  logoutUser: PropTypes.func.isRequired,
}

export default compose(connect(null, { logoutUser }), withStyles(styles), pure)(TopNavigationMenu)
