export const EXPORT_SECTIONS = {
  none: 'None',
  'cover-page': 'Cover Page',
  'letter-of-transmittal': 'Letter Of Transmittal',
  'summary-of-salient-facts-and-conclusions': 'Summary Of Salient Facts And Conclusions',
  'table-of-contents': 'Table Of Contents',
  introduction: 'Introduction',
  'neighborhood-analysis': 'Neighborhood Analysis',
  'zoning-summary': 'Zoning Summary',
  'assessed-value-and-real-estate-taxes': 'Assessed Value And Real Estate Taxes',
  'site-description': 'Site Description',
  'description-of-improvements': 'Description Of Improvements',
  'multifamily-submarket-analysis': 'Multifamily Submarket Analysis',
  'retail-submarket-analysis': 'Retail Submarket Analysis',
  'office-submarket-analysis': 'Office Submarket Analysis',
  'industrial-submarket-analysis': 'Industrial Submarket Analysis',
  'highest-and-best-use': 'Highest And Best Use',
  'appraisal-valuation-process': 'Appraisal Valuation Process',
  'cost-approach': 'Cost Approach',
  'income-capitalization-approach': 'Income Capitalization Approach',
  'reconciliation-and-final-value-opinion': 'Reconciliation And Final Value Opinion',
  'certification-section': 'Certification Section',
  addenda: 'Addenda',
}

export const CREATE_NEW = 'Content that can be used within this report.'
export const SUBSECTION_NAME = 'This is the name that will appear in Webapp.'
export const SUBSECTION_TITLE = 'This is the title that will appear in the exported report.'
export const SUBSECTION_LOCATION = 'The content will appear at the bottom of the selected report section.'
export const EDIT_CARD = 'Edit the card content that can be used within this report.'

export const SPACING = 2
