import React from 'react'
import PropTypes from 'prop-types'
import { compose, pure } from 'recompose'
import { Typography } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'

const styles = theme => ({
  formHeading: {
    textAlign: 'left',
    flex: 1,
    fontWeight: 400,
    paddingTop: theme.spacing.unit,
    paddingBottom: theme.spacing.unit * 2,
  },
})

const FormHeading = ({ classes, formHeading }) => (
  <Typography className={classes.formHeading} variant="h5">
    {formHeading}
  </Typography>
)

FormHeading.propTypes = {
  formHeading: PropTypes.string.isRequired,
}

export default compose(withStyles(styles), pure)(FormHeading)
