import { createAction } from 'redux-actions'

import { contentReuseTemplate } from 'client-shared/types'

import {
  selectNarrativeTemplatePayload,
  setContentLibraryPayload,
  setContentLibraryReceivePayload,
  createNarrativeTemplatePayload,
} from '../types/contentLibraryData'
import {
  SET_CONTENT_LIBRARY_DATA,
  CREATE_NARRATIVE_TEMPLATE,
  UPDATE_NARRATIVE_TEMPLATE,
  SELECT_NARRATIVE_TEMPLATE,
  RELOAD_CONTENT_LIBRARY,
} from '../actionTypes/contentLibraryData'

export const setContentLibrary = createAction<setContentLibraryPayload>(SET_CONTENT_LIBRARY_DATA.ACTION)
export const setContentLibraryRequest = createAction(SET_CONTENT_LIBRARY_DATA.REQUEST)
export const setContentLibraryReceive = createAction<setContentLibraryReceivePayload>(SET_CONTENT_LIBRARY_DATA.RECEIVE)
export const setContentLibraryFail = createAction(SET_CONTENT_LIBRARY_DATA.FAIL)

export const createNarrativeTemplate = createAction<createNarrativeTemplatePayload>(CREATE_NARRATIVE_TEMPLATE.ACTION)
export const createNarrativeTemplateRequest = createAction(CREATE_NARRATIVE_TEMPLATE.REQUEST)
export const createNarrativeTemplateReceive = createAction<contentReuseTemplate>(CREATE_NARRATIVE_TEMPLATE.RECEIVE)
export const createNarrativeTemplateFail = createAction(CREATE_NARRATIVE_TEMPLATE.FAIL)

export const updateNarrativeTemplate = createAction<contentReuseTemplate>(UPDATE_NARRATIVE_TEMPLATE.ACTION)
export const updateNarrativeTemplateRequest = createAction(UPDATE_NARRATIVE_TEMPLATE.REQUEST)
export const updateNarrativeTemplateReceive = createAction<contentReuseTemplate>(UPDATE_NARRATIVE_TEMPLATE.RECEIVE)
export const updateNarrativeTemplateFail = createAction(UPDATE_NARRATIVE_TEMPLATE.FAIL)

export const selectNarrativeTemplate = createAction<selectNarrativeTemplatePayload>(SELECT_NARRATIVE_TEMPLATE.ACTION)

export const reloadContentLibrary = createAction<contentReuseTemplate>(RELOAD_CONTENT_LIBRARY.ACTION)
