import React from 'react'
import { get } from 'lodash'
import PropTypes from 'prop-types'
import { Paper, Typography, Link, Grid } from '@mui/material'

import { FeatureToggle } from '@bowery-valuation/feature-flagger-client'

import residentialVCLossDiscussion from 'shared/utils/textGeneration/incomeApproach/potentialGrossIncome/residentialVCLossDiscussion'
import AutomationCTA from 'client-shared/components/AutomationCTA'

import NumberField from 'client-shared/components/Number/Number'
import { Area, Template } from 'client-shared/components/Template'
import { NarrativeComponent } from 'client-shared/components'
import { divide, roundDownToNearest, roundToNearest } from 'shared/utils/numberOperations'
import { toPercents, fromPercents } from 'client-shared/utils/numberOperations'
import { ENABLE_AUTO_MARKET_VACANCY_RATES } from 'shared/constants/featureFlags'
import { DEFAULT_ALERT_MESSAGES } from 'shared/constants/automation/messages'
import * as Api from 'core/api'

import { connect } from 'react-redux'

import { POTENTIAL_GROSS_INCOME_PATH } from 'shared/constants/report/keysAndDataPaths'

import { saveReport } from '../../../redux/actions/report'

import { Labels } from './PotentialGrossIncomeConstants'

class ResidentialVacancyLoss extends React.PureComponent {
  static propTypes = {
    submarket: PropTypes.string,
    market: PropTypes.string,
    state: PropTypes.string,
    dateOfValue: PropTypes.string,
    reportId: PropTypes.string,
    address: PropTypes.string,
    residentialUnits: PropTypes.array,
    valueAsStabilized: PropTypes.number,
    propertyType: PropTypes.string,
    form: PropTypes.object,
    formDataPath: PropTypes.string,
  }

  automateMarketRates = async () => {
    const { submarket, market, dateOfValue, state, form, occupancyRate, formDataPath } = this.props

    const { costarMetroRate, costarSubmarketRate } = await Api.getMarketVacancyRates(
      state,
      submarket,
      market,
      dateOfValue
    )
    const roundedCoStarSubmarketRate = roundToNearest(costarSubmarketRate, 0.5)
    const roundedCoStarMetroRate = roundToNearest(costarMetroRate, 0.5)

    const vacancyRate = roundedCoStarSubmarketRate ? 100 - roundedCoStarSubmarketRate + 1 : 5
    const submarketVacancyRate = occupancyRate === 1 ? roundDownToNearest(vacancyRate, 5) : vacancyRate
    // default minimum of 2%
    const residentialVCLossPercentage = divide(submarketVacancyRate || 2, 100)

    const formattedCostarSubmarketRate = divide(roundToNearest(roundedCoStarSubmarketRate, 0.5), 100)
    const formattedCostarMetroRate = divide(roundedCoStarMetroRate, 100)

    form.batch(() => {
      form.change('residentialVCLossPercentage', residentialVCLossPercentage)
      form.change('costarMetroRate', formattedCostarMetroRate)
      form.change('costarSubmarketRate', formattedCostarSubmarketRate)
    })

    global.automationSaved = 'potentialGrossIncomeImport'
    this.props.saveReport(formDataPath)
  }

  getDisabledCTAMessage = () => {
    const { submarket, market, dateOfValue, reportId } = this.props
    let propertyMarketLabel = ''
    if (!submarket && !market) {
      propertyMarketLabel = 'Market and Submarket are missing - '
    } else if (!submarket) {
      propertyMarketLabel = 'Submarket is missing'
    } else {
      propertyMarketLabel = 'Market is missing'
    }
    const shouldShowWarning = !submarket || !market || !dateOfValue
    return (
      shouldShowWarning && (
        <div>
          Some values are missing:
          <ul>
            {(!market || !submarket) && (
              <li>
                {propertyMarketLabel}
                <Link style={{ cursor: 'pointer' }} href={`/report/${reportId}/property-market`}>
                  {' '}
                  Edit Property Market
                </Link>
              </li>
            )}
            {!dateOfValue && (
              <li>
                Date of Valuation is missing -
                <Link style={{ cursor: 'pointer' }} href={`/report/${reportId}/job-details#report-details`}>
                  {' '}
                  Edit Job Details
                </Link>
              </li>
            )}
          </ul>
        </div>
      )
    )
  }

  render() {
    const { submarket, market, dateOfValue, address, residentialUnits, valueAsStabilized, propertyType } = this.props
    const { values } = this.props.form.getState()
    const { costarSubmarketRate, costarMetroRate, residentialVCLossPercentage } = values

    return (
      <Paper>
        <Template
          is={`
          'header          header'
          'inputPercentage inputAmount'
          'CSSubmarketRata CSMetroRate'
          'commentary      commentary'
        `}
          gap={2}
        >
          <Area is="header">
            <Grid container>
              <Typography variant="h6">Residential Vacancy and Collection Loss</Typography>
              <FeatureToggle featureFlag={ENABLE_AUTO_MARKET_VACANCY_RATES}>
                <AutomationCTA
                  CTAMessage="Automate the Residential Vacancy and Collection Loss from Market and Submarket Data."
                  successMessage={DEFAULT_ALERT_MESSAGES.SUCCESS}
                  errorMessage={DEFAULT_ALERT_MESSAGES.ERROR}
                  onAutomationRun={this.automateMarketRates}
                  disableCTA={!submarket || !market || !dateOfValue}
                  warningMessage={this.getDisabledCTAMessage()}
                />
              </FeatureToggle>
            </Grid>
          </Area>
          <Area is="inputPercentage">
            <NumberField
              adornment="%"
              decimalScale={2}
              fixedDecimalScale
              format={toPercents}
              label={Labels.RESIDENTIAL_VC_LOSS}
              name="residentialVCLossPercentage"
              normalize={fromPercents}
            />
          </Area>
          <Area is="inputAmount">
            <NumberField
              disabled
              label="Gross Residential Vacancy and Collection Loss"
              name="residentialVCLossAmount"
              prefix="$"
              thousandSeparator
            />
          </Area>
          <Area in="CSSubmarketRata">
            <NumberField
              adornment="%"
              decimalScale={2}
              format={toPercents}
              label="CoStar Submarket Rate"
              name="costarSubmarketRate"
              normalize={fromPercents}
            />
          </Area>
          <Area is="CSMetroRate">
            <NumberField
              adornment="%"
              decimalScale={2}
              format={toPercents}
              label="CoStar Metro Rate"
              name="costarMetroRate"
              normalize={fromPercents}
            />
          </Area>
          <Area is="commentary">
            <NarrativeComponent
              name="residentialVCLossDiscussion"
              title="Residential V&C Loss Discussion"
              generatedText={residentialVCLossDiscussion.generate}
              data={residentialVCLossDiscussion.mapDTO({
                costarSubmarketRate,
                costarMetroRate,
                residentialVCLossPercentage,
                address,
                residentialUnits,
                valueAsStabilized,
                propertyType,
              })}
            />
          </Area>
        </Template>
      </Paper>
    )
  }
}

const mapDispatchToProps = dispatch => ({
  saveReport: formPath => dispatch(saveReport(formPath)),
})

const mapStateToProps = state => ({
  formDataPath: POTENTIAL_GROSS_INCOME_PATH.join('.'),
  propertyType: get(state, 'report.reportData.propertyType'),
})

export default connect(mapStateToProps, mapDispatchToProps)(ResidentialVacancyLoss)
