import { lowerCase } from 'lodash'

import { getPossiblyOtherValue } from '../../../../../../shared/utils/textGeneration'

import { VALUE_CONCLUSION_TYPES } from '../../../constants/settings'

const getFormattedFieldValue = field => getPossiblyOtherValue(field, { formatValue: lowerCase })

const getDefaultWriteup = () => ''

const getKitchenWriteup = ({ valueConclusionType, kitchen, elevatored }) => {
  const { condition, floors, counters, cabinets, stovetops, refrigerators, hasDishwasher } = kitchen
  const formattedFloors = getFormattedFieldValue(floors)
  const formattedCounters = getFormattedFieldValue(counters)
  const formattedCabinets = getFormattedFieldValue(cabinets)
  const formattedStovetops = getFormattedFieldValue(stovetops)
  const formattedRefrigerators = getFormattedFieldValue(refrigerators)

  if (
    !valueConclusionType ||
    valueConclusionType === VALUE_CONCLUSION_TYPES.AS_IS ||
    valueConclusionType === VALUE_CONCLUSION_TYPES.AS_STABILIZED
  ) {
    return (
      `The units generally feature ${lowerCase(
        condition
      )} quality kitchen finishes relative to typical units in similar ${
        elevatored ? 'elevatored' : 'walk-up'
      } buildings in the area.` +
      ` The units typically contain ${formattedFloors} flooring, ${formattedCounters} counter tops,` +
      ` ${formattedCabinets} cabinets, ${formattedStovetops} stovetops, and ${formattedRefrigerators} refrigerators.${
        hasDishwasher ? ' Additionally, units feature a dishwasher.' : ''
      }`
    )
  }
  return (
    `The units will feature ${lowerCase(condition)} quality kitchen finishes relative to typical units in similar ${
      elevatored ? 'elevatored' : 'walk-up'
    } buildings in the subject's area.` +
    ` The units will contain ${formattedFloors} flooring, ${formattedCounters} counter tops,` +
    ` ${formattedCabinets} cabinets, ${formattedStovetops} stovetops, and ${formattedRefrigerators} refrigerators.${
      hasDishwasher ? ' Additionally, units feature a dishwasher.' : ''
    }`
  )
}

const getBedroomWriteup = ({ valueConclusionType, bedroom, elevatored }) => {
  const { condition, walls, floors } = bedroom
  const formattedWalls = getFormattedFieldValue(walls)
  const formattedFloors = getFormattedFieldValue(floors)

  if (
    !valueConclusionType ||
    valueConclusionType === VALUE_CONCLUSION_TYPES.AS_IS ||
    valueConclusionType === VALUE_CONCLUSION_TYPES.AS_STABILIZED
  ) {
    return `The units generally feature ${lowerCase(
      condition
    )} quality bedroom finishes relative to typical units in similar ${
      elevatored ? 'elevatored' : 'walk-up'
    } buildings in the area. The units typically contain ${formattedFloors} flooring, and ${formattedWalls} walls.`
  }
  return `The units will feature ${lowerCase(
    condition
  )} quality bedroom finishes relative to typical units in similar ${
    elevatored ? 'elevatored' : 'walk-up'
  } buildings in the subject's area. The units will contain ${formattedFloors} flooring, and ${formattedWalls} walls.`
}

const getLivingRoomWriteup = ({ valueConclusionType, living, elevatored }) => {
  const { condition, walls, floors } = living
  const formattedWalls = getFormattedFieldValue(walls)
  const formattedFloors = getFormattedFieldValue(floors)

  if (
    !valueConclusionType ||
    valueConclusionType === VALUE_CONCLUSION_TYPES.AS_IS ||
    valueConclusionType === VALUE_CONCLUSION_TYPES.AS_STABILIZED
  ) {
    return `The units generally feature ${lowerCase(
      condition
    )} quality living room finishes relative to typical units in similar ${
      elevatored ? 'elevatored' : 'walk-up'
    } buildings in the area. The units typically contain ${formattedFloors} flooring, and ${formattedWalls} walls.`
  }
  return `The units will feature ${lowerCase(
    condition
  )} quality living room finishes relative to typical units in similar ${
    elevatored ? 'elevatored' : 'walk-up'
  } buildings in the subject's area. The units will contain ${formattedFloors} flooring, and ${formattedWalls} walls.`
}

const getBathroomWriteup = ({ valueConclusionType, bathroom, elevatored, otherBathroomFloors }) => {
  const { condition, floors, tub, sink, toilet } = bathroom
  const formattedFloors = getFormattedFieldValue(floors)
  const formattedTub = getFormattedFieldValue(tub)
  const formattedSink = getFormattedFieldValue(sink)
  const formattedToilet = getFormattedFieldValue(toilet)

  if (
    !valueConclusionType ||
    valueConclusionType === VALUE_CONCLUSION_TYPES.AS_IS ||
    valueConclusionType === VALUE_CONCLUSION_TYPES.AS_STABILIZED
  ) {
    return (
      `The units generally feature ${lowerCase(
        condition
      )} quality bathroom finishes relative to typical units in similar ${
        elevatored ? 'elevatored' : 'walk-up'
      } buildings in the area.` +
      ` The units typically contain ${formattedFloors} flooring, ${formattedTub} tubs, ${formattedSink} sinks,` +
      ` and ${formattedToilet} toilets.`
    )
  }
  return (
    `The units will feature ${lowerCase(condition)} quality bathroom finishes relative to typical units in similar ${
      elevatored ? 'elevatored' : 'walk-up'
    } buildings in the subject's area.` +
    ` The units will contain ${formattedFloors} flooring, ${formattedTub} tubs, ${formattedSink} sinks,` +
    ` and ${formattedToilet} toilets.`
  )
}

const getStairsWriteup = ({ stairs }) => {
  const { numberOfStairs, stairsStart, stairsEnd, stairsLocation } = stairs
  let writeup = `There are no ${lowerCase(stairsLocation)} staircases.`
  if (numberOfStairs === 1) {
    writeup = `There is ${numberOfStairs} ${lowerCase(stairsLocation)} staircase that runs from the ${lowerCase(
      stairsStart
    )} to the ${lowerCase(stairsEnd)}.`
  } else if (numberOfStairs > 1) {
    writeup = `There are ${numberOfStairs} ${lowerCase(stairsLocation)} staircases that run from the ${lowerCase(
      stairsStart
    )} to the ${lowerCase(stairsEnd)}.`
  }
  return writeup
}

export default function getWriteupGenerator(type) {
  switch (type) {
    case 'kitchen':
      return getKitchenWriteup
    case 'bedroom':
      return getBedroomWriteup
    case 'bathroom':
      return getBathroomWriteup
    case 'living':
      return getLivingRoomWriteup
    case 'stairs':
      return getStairsWriteup
    default:
      return getDefaultWriteup
  }
}
