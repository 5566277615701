import UnitMix from '../forms/UnitMix'
import CompSummary from '../forms/CompSummary'
import PropertySummary from '../forms/PropertySummary'
import PropertyConditions from '../forms/PropertyConditions'

export default [
  {
    color: '#00da04',
    prevFormName: null,
    prevFormPath: null,
    key: 'propertySummary',
    path: 'property-summary',
    name: 'Property Summary',
    formComponent: PropertySummary,
    nextFormName: 'Property Conditions',
    nextFormPath: 'property-conditions',
  },
  {
    color: '#ff9600',
    nextFormName: 'Unit Mix',
    nextFormPath: 'unit-mix',
    key: 'propertyConditions',
    path: 'property-conditions',
    name: 'Property Conditions',
    prevFormName: 'Property Summary',
    prevFormPath: 'property-summary',
    formComponent: PropertyConditions,
  },
  {
    key: 'unitMix',
    name: 'Unit Mix',
    color: '#ae0808',
    path: 'unit-mix',
    formComponent: UnitMix,
    nextFormName: 'Comp Summary',
    nextFormPath: 'comp-summary',
    prevFormName: 'Property Conditions',
    prevFormPath: 'property-conditions',
  },
  {
    color: '#ae0808',
    key: 'compSummary',
    nextFormName: null,
    nextFormPath: null,
    name: 'Comp Summary',
    path: 'comp-summary',
    prevFormName: 'Unit Mix',
    prevFormPath: 'unit-mix',
    formComponent: CompSummary,
  },
]
