import Handsontable from 'handsontable'
import { CellCoords } from 'handsontable/es/3rdparty/walkontable/src' // fragile
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'

import ReactDOM from 'react-dom'
import React from 'react'

import { KEY_CODES } from './helpers'

const scrollIntoView = element => {
  if (element.scrollIntoViewIfNeeded) {
    element.scrollIntoViewIfNeeded(false)
  } else {
    element.scrollIntoView(false)
  }
}

class SelectEditor extends Handsontable.editors.SelectEditor {
  init() {
    this.value = null
    this.select = this.hot.rootDocument.createElement('ul')
    this.select.classList.add('htSelectEditor')
    this.select.style.display = 'none'
    this.hot.rootElement.appendChild(this.select)
    this.registerHooks()
  }

  get options() {
    const selectOptions = this.cellProperties.selectOptions || this.cellProperties.source
    let options
    if (typeof selectOptions === 'function') {
      options = this.prepareOptions(selectOptions(this.row, this.col, this.prop))
    } else {
      options = this.prepareOptions(selectOptions)
    }
    return Object.entries(options)
  }

  prepare(row, col, prop, td, originalValue, cellProperties) {
    Handsontable.editors.BaseEditor.prototype.prepare.call(this, row, col, prop, td, originalValue, cellProperties)

    Handsontable.dom.empty(this.select)

    this.options.forEach(([value, label], index) => {
      const optionElement = this.hot.rootDocument.createElement('li')
      optionElement.innerHTML = label
      optionElement.onclick = () => {
        this.setValue(value)
        this.finishEditing()
      }
      this.select.appendChild(optionElement)
    })
  }

  open() {
    super.open()
    this.td = this.getEditedCell()
    const arrow = this.td.querySelector('.htAutocompleteArrow')
    arrow.classList.add('active')
  }

  getValue() {
    return this.value
  }

  setValue(value) {
    this.value = value
  }

  onBeforeKeyDown() {
    const event = window.event

    const currentValue = this.getValue()
    const children = this.select.childNodes
    let initialSelectionIndex = Array.from(children).findIndex(el => el.classList.contains('selected'))
    if (initialSelectionIndex === -1 && currentValue) {
      initialSelectionIndex = this.options.findIndex(([value]) => value === currentValue)
      // eslint-disable-next-line babel/no-unused-expressions
      children[initialSelectionIndex]?.classList.add('selected')
    }

    const optionsCount = this.options.length
    switch (event.keyCode) {
      case KEY_CODES.ARROW_UP: {
        event.preventDefault()
        event.stopPropagation()
        event.isImmediatePropagationEnabled = false

        let currentSelectionIndex
        if (initialSelectionIndex === -1) {
          currentSelectionIndex = 0
        } else if (initialSelectionIndex === 0) {
          currentSelectionIndex = 0
        } else {
          currentSelectionIndex = initialSelectionIndex - 1
        }

        // eslint-disable-next-line babel/no-unused-expressions
        children[initialSelectionIndex]?.classList.remove('selected')
        children[currentSelectionIndex].classList.add('selected')
        scrollIntoView(children[currentSelectionIndex])
        break
      }

      case KEY_CODES.ARROW_DOWN: {
        event.preventDefault()
        event.stopPropagation()
        event.isImmediatePropagationEnabled = false

        let currentSelectionIndex
        if (initialSelectionIndex === -1) {
          currentSelectionIndex = 0
        } else if (initialSelectionIndex === optionsCount - 1) {
          currentSelectionIndex = optionsCount - 1
        } else {
          currentSelectionIndex = initialSelectionIndex + 1
        }

        // eslint-disable-next-line babel/no-unused-expressions
        children[initialSelectionIndex]?.classList.remove('selected')
        children[currentSelectionIndex].classList.add('selected')
        scrollIntoView(children[currentSelectionIndex])
        break
      }

      case KEY_CODES.ENTER:
        event.preventDefault()

        if (initialSelectionIndex === -1) {
        } else {
          const selectedValue = this.options[initialSelectionIndex][0]
          this.setValue(selectedValue)
        }
        break

      default:
        break
    }
  }
}

const selectRenderer = (instance, td, row, col, prop, value, cellProperties) => {
  Handsontable.renderers.TextRenderer.call(this, instance, td, row, col, prop, value, cellProperties)
  td.setAttribute('data-qa', `${prop}-${row}-cell`)

  const { rootDocument } = instance
  const arrowContainer = rootDocument.createElement('DIV')

  arrowContainer.className = 'htAutocompleteArrow'
  ReactDOM.render(<KeyboardArrowDownIcon fontSize="small" className="KeyboardArrowDownIcon" />, arrowContainer)

  if (!td.firstChild) {
    td.appendChild(rootDocument.createTextNode(String.fromCharCode(160)))
  }

  td.insertBefore(arrowContainer, td.firstChild)
  td.classList.add('htAutocomplete')

  if (!instance.acArrowListener) {
    const eventManager = new Handsontable.EventManager(instance)

    instance.acArrowListener = function (event) {
      const targetClassList = event.target.classList
      if (targetClassList.contains('htAutocompleteArrow') || targetClassList.contains('KeyboardArrowDownIcon')) {
        instance.view.wt.getSetting('onCellDblClick', null, new CellCoords(row, col), td)
      }
    }

    eventManager.addEventListener(instance.rootElement, 'mousedown', instance.acArrowListener)

    instance.addHookOnce('afterDestroy', () => {
      eventManager.destroy()
    })
  }

  return td
}

export const registerSelect = () =>
  Handsontable.cellTypes.registerCellType('bowery.select', {
    editor: SelectEditor,
    renderer: selectRenderer,
  })

export const styles = theme => ({
  root: {
    '& td .htAutocompleteArrow': {
      color: theme.palette.grey[900],
      fontSize: 16,
      marginTop: 2,
      marginRight: -4,

      '&:hover': {
        color: theme.palette.grey[900],
      },

      '&.active': {
        transform: 'rotate(180deg)',
        transformOrigin: '10px 10px',
        marginTop: 0,
        marginBottom: 2,
      },
    },

    '& .htSelectEditor': {
      backgroundColor: theme.palette.common.white,
      // Popover
      boxShadow: '0px 2px 8px rgba(0, 15, 68, 0.12)',
      borderRadius: 4,

      paddingInlineStart: 0,
      height: 'auto !important',
      maxHeight: 144,
      marginTop: '44px !important',
      overflowY: 'auto',

      '& li': {
        ...theme.typography.body,
        color: theme.palette.grey[700],
        lineHeight: '36px',
        height: 36,
        display: 'block',
        borderRadius: 4,
        padding: '0px 16px',

        '&:hover, &.selected': {
          backgroundColor: theme.palette.onHover(theme.palette.primary.main),
        },
        '&:active': {
          backgroundColor: theme.palette.onPress(theme.palette.primary.main),
        },
      },
    },
  },
})
