import ObjectID from 'bson-objectid'

export function transformSalesCompToRawOutput(input, oldLocalCopy = {}) {
  const {
    address,
    status,
    propertyInformation,
    saleInformation,
    verificationInformation,
    resourceInformation,
    commentary,
    saleDate,
    jobs,
  } = input
  const { isAutoComp, score } = oldLocalCopy

  const outputDto = {
    _id: oldLocalCopy._id || ObjectID().toString(),
    address: {
      ...address,
      streetAddress: propertyInformation.displayStreetAddress,
      city: propertyInformation.displayCity,
    },
    status,
    propertyInformation,
    saleInformation: {
      ...saleInformation,
      saleDate,
    },
    verificationInformation,
    resourceInformation,
    commentary,
    salesTransactionId: input.id,
    salesTransactionVersion: input.version,
    isLatestVersion: input.isLatestVersion,
    includeInReport: true,
    isBowerySubject: input.isBowerySubject,
    deletedAt: input.deletedAt,
    numberUsedInReports: jobs?.length || 0,
    isAutoComp: !!isAutoComp,
    score: isAutoComp ? score : null,
  }
  return outputDto
}

export const transformSalesCompRawInput = rawInput => {
  const { address, propertyInformation, verificationInformation, resourceInformation, saleInformation, commentary } =
    rawInput
  const { saleStatus, salePrice, seller, buyer, uniqueSaleIdentifier, capRate, propertyRights } = saleInformation
  const inputDto = {
    address,
    saleDate: saleInformation.saleDate,
    saleInformation: {
      saleStatus,
      salePrice,
      seller,
      buyer,
      uniqueSaleIdentifier,
      capRate,
      propertyRights,
    },
    verificationInformation,
    resourceInformation,
    propertyInformation: {
      ...propertyInformation,
      displayStreetAddress: address.streetAddress,
      displayCity: address.city,
    },
    commentary,
    deletedAt: rawInput.deletedAt,
  }

  return inputDto
}
