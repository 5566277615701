import React from 'react'
import { Grid, withStyles } from '@material-ui/core'

import { DatePicker } from '../../../../shared/components'

const styles = theme => {
  return {
    formControl: {
      width: 280,
    },
    formControlRoot: {
      height: 49,
    },
  }
}

class AppraisalHistory extends React.PureComponent {
  render() {
    const { classes } = this.props
    return (
      <Grid item>
        <Grid item>
          <DatePicker name="appraisalDate" label="Date" classes={{ formControl: classes.formControl }} />
        </Grid>
      </Grid>
    )
  }
}

export default withStyles(styles)(AppraisalHistory)
