import { select, put, takeLatest } from 'redux-saga/effects'

import { mapExpenseComps } from '../BoweryCoreFacade'

import { selectExpenseComp, deselectExpenseComp, setSelectedExpenseComps } from './actions'

function* updateExpenseComps() {
  const expenseComps = yield select(state => state.expenseComps.expenseComps)
  const selectedExpenseComps = yield select(state => state.expenseComps.selectedExpenseComps)

  const mappedExpenseComps = mapExpenseComps(selectedExpenseComps, expenseComps)
  yield put(setSelectedExpenseComps(mappedExpenseComps))
}

export default [takeLatest([selectExpenseComp, deselectExpenseComp], updateExpenseComps)]
