import React from 'react'
import PropTypes from 'prop-types'
import { eq } from 'lodash'
import { FieldArray } from 'react-final-form-arrays'

import Table from './Table'

class TableContainer extends React.Component {
  static propTypes = {
    form: PropTypes.object.isRequired,
    fields: PropTypes.object.isRequired,
    getDefaults: PropTypes.func.isRequired,
    canDelete: PropTypes.bool,
  }

  static defaultProps = {
    canDelete: true,
  }

  state = {
    draggingState: false,
  }

  shouldComponentUpdate(nextProps, nextState) {
    return this.props.fields.length !== nextProps.fields.length || nextState !== this.state
  }

  onStartMoveItem = () => this.setState({ draggingState: true })

  onEndMoveItem = ({ source, destination }) => {
    const { fields } = this.props
    this.setState({ draggingState: false })
    if (!destination) {
      return
    }
    fields.move(source.index, destination.index)
  }

  onRemoveItem = index => {
    const { fields, form } = this.props
    const values = fields.value.filter((value, key) => key !== index)

    form.change(fields.name, values)
  }

  onAddItem = () => {
    const { fields } = this.props
    fields.push({})
  }

  onRevertToDefault = () => {
    const { getDefaults, fields, form } = this.props
    const defaults = getDefaults()
    form.change(fields.name, defaults)
  }

  render() {
    const { fields, canDelete, getDefaults, form, meta, ...restProps } = this.props
    const { draggingState } = this.state

    return (
      <Table
        {...restProps}
        fields={fields}
        canDelete={canDelete}
        draggingState={draggingState}
        onAddItem={this.onAddItem}
        onRemoveItem={this.onRemoveItem}
        onEndMoveItem={this.onEndMoveItem}
        onStartMoveItem={this.onStartMoveItem}
        onRevertToDefault={this.onRevertToDefault}
      />
    )
  }
}

export default props => <FieldArray isEqual={eq} name={props.name} component={TableContainer} {...props} />
