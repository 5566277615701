import React from 'react'
import { get } from 'lodash'

import { Button, Stack } from '@mui/material'

import { Field } from 'react-final-form'

import Table from 'client-shared/components/Table/TableComponent'
import { CHANGE_EVENT_INDEX } from 'client-shared/components/Table/constants'
import {
  currencyIntRight,
  totalLabelRenderer,
  totalRowRenderer,
  setQAAttribute,
} from 'client-shared/components/TableHelpers/cellRenderers'
import { formatInt } from 'client-shared/utils/numberFormatters'
import { ButtonSwitch } from 'client-shared/components'

import { EXPENSE_TYPES_LIST, DATA_SOURCE_TYPES_LIST, RENOVATION_COSTS_TYPE_OPTIONS } from './constants'

const naRenderer = (instance, td, row, col, prop, value, cellProperties) => {
  td.innerHTML = 'N/A'
  td.className = 'readOnly'
  return td
}

const renovationPeriodRenderer = (instance, td, row, col, prop, value, cellProperties) => {
  if (value === 1) {
    td.innerHTML = `${formatInt(value || 0)} month`
  } else {
    td.innerHTML = `${formatInt(value || 0)} months`
  }
  td.className = 'alignRight'
  setQAAttribute(td, row, prop)
  return td
}

class TotalBetaTable extends React.PureComponent {
  tableRef = React.createRef()
  getTableData = totalItems => {
    const { form } = this.props
    const totalAmount = get(form.values, 'total.total.amount', 0)
    const renovationPeriod = get(form.values, 'total.total.renovationPeriod', 0)
    const lessAmountSpent = get(form.values, 'total.total.lessAmountSpent', 0)
    const data = []
    totalItems.forEach(item => {
      data.push(item)
    })
    data.push({ item: 'Total: ', renovationPeriod: renovationPeriod, amount: totalAmount, totalRow: true })
    data.push({ item: 'Less Total Amount Spent to Date: ', amount: lessAmountSpent })
    data.push({
      item: 'Net Total Renovation Budget: ',
      amount: totalAmount - lessAmountSpent,
    })
    return data
  }

  getTableColumns = () => {
    const columns = [
      { data: 'item', value: 'Item', width: 250, className: 'alignLeft' },
      {
        data: 'expenseType',
        value: 'Expense Type',
        width: 225,
        type: 'autocomplete',
        source: EXPENSE_TYPES_LIST,
        allowInvalid: false,
        strict: true,
      },
      {
        data: 'dataSource',
        value: 'Data Source',
        width: 225,
        type: 'autocomplete',
        source: DATA_SOURCE_TYPES_LIST,
        allowInvalid: false,
        strict: true,
      },
      { data: 'renovationPeriod', value: 'Time Until Completion', width: 140, type: 'numeric' },
      { data: 'amount', value: 'Amount', type: 'numeric', width: 200, renderer: currencyIntRight },
    ]
    return columns
  }

  beforeChange = (changes = [], source) => {
    const { form } = this.props
    const totalItems = get(form.values, 'total.items', [])
    const numberOfItems = totalItems.length
    form.batch(() => {
      changes.forEach(change => {
        const row = change[CHANGE_EVENT_INDEX.row]
        const col = change[CHANGE_EVENT_INDEX.column]
        const value = change[CHANGE_EVENT_INDEX.newValue]
        if (row < numberOfItems) {
          totalItems[row][col] = value
        } else {
          if (col === 'renovationPeriod') {
            form.change('total.total.renovationPeriod', value)
          } else if (col === 'amount') {
            if (row === numberOfItems) {
              const totalAmount = parseInt(value)
              const lessAmountSpent = get(form.values, 'total.total.lessAmountSpent', 0)
              form.change('total.total.amount', parseInt(value))
              form.change('total.total.netRenovationBudget', totalAmount - lessAmountSpent)
            } else {
              const totalAmount = get(form.values, 'total.total.amount', 0)
              const lessAmountSpent = parseInt(value)
              form.change('total.total.lessAmountSpent', parseInt(value))
              form.change('total.total.netRenovationBudget', totalAmount - lessAmountSpent)
            }
          }
        }
      })
      // On edit/paste, update form value with shallow copy to mark it dirty
      const changeSourceList = ['edit', 'CopyPaste.paste']
      if (changeSourceList.findIndex(changeSource => changeSource === source) >= 0) {
        form.change('total.items', [...totalItems])
      } else {
        form.change('total.items', totalItems)
      }
    })
  }

  setTableRef = ref => {
    this.tableRef = ref
  }

  addTotalRenovation = () => {
    const { form } = this.props
    form.mutators.push('total.items', {})
  }

  //TODO: LOCK TOTAL ROWS
  render() {
    const { form } = this.props
    const totalItems = get(form.values, 'total.items', [{}])
    const data = this.getTableData(totalItems)
    const columns = this.getTableColumns()
    const colHeaders = columns.map(column => column.value)
    return (
      <>
        <Stack spacing={4} alignItems="flex-start" mt={1} mb={3}>
          <Button data-qa="add-btn" variant="contained" onClick={this.addTotalRenovation}>
            Add
          </Button>
          <ButtonSwitch name="renovationCostsType" options={RENOVATION_COSTS_TYPE_OPTIONS} />
        </Stack>
        <Field name="total.items">{() => null}</Field>
        <Field name="isDirty">{() => null}</Field>
        <Table
          setTableRef={ref => {
            this.tableRef = ref
          }}
          colHeaders={colHeaders}
          data={data}
          columns={columns}
          formulas={true}
          beforeChange={this.beforeChange}
          contextMenu={{
            callback: (key, selection, clickEvent) => {
              const { start, end } = selection[0]
              const startRow = start.row
              const endRow = end.row
              if (key === 'row_above') {
                form.mutators.insert('total.items', startRow, {})
              } else if (key === 'row_below') {
                form.mutators.insert('total.items', endRow + 1, {})
              } else if (key === 'remove_row') {
                form.mutators.remove('total.items', startRow)
              }
            },
            items: {
              row_above: {
                name: 'Add row above',
                disabled: () => {
                  const row = this.tableRef.hotInstance.getSelectedLast()[0]
                  if (row > data.length - 3) {
                    return true
                  }
                },
              },
              row_below: {
                name: 'Add row below',
                disabled: () => {
                  const row = this.tableRef.hotInstance.getSelectedLast()[0]
                  if (row > data.length - 4) {
                    return true
                  }
                },
              },
              remove_row: {
                name: 'Remove row',
                disabled: () => {
                  const row = this.tableRef.hotInstance.getSelectedLast()[0]
                  if (row > data.length - 4) {
                    return true
                  }
                },
              },
            },
          }}
          minSpareRows={1}
          cells={(row, col, props) => {
            const cellProperties = {}
            const rowData = data[row]
            const isTotalRow = row > data.length - 5
            if (isTotalRow) {
              cellProperties.readOnly = true
              cellProperties.className = 'readOnly'
              cellProperties.type = 'numeric'
              cellProperties.renderer = totalLabelRenderer
              const isLessAmountRow = row === data.length - 3
              if (props === 'amount' && (rowData.totalRow || isLessAmountRow)) {
                cellProperties.renderer = currencyIntRight
                cellProperties.readOnly = false
              } else if (props === 'renovationPeriod' && rowData.totalRow) {
                cellProperties.readOnly = false
                cellProperties.renderer = renovationPeriodRenderer
              } else if (col === 4) {
                cellProperties.className = 'alignRight'
                cellProperties.renderer = totalRowRenderer
              }
            } else if ((props === 'amount' || props === 'renovationPeriod') && !rowData.totalRow) {
              cellProperties.readOnly = true
              cellProperties.className = 'readOnly'
              cellProperties.renderer = naRenderer
            }

            return cellProperties
          }}
          afterGetRowHeader={(row, TH) => {
            if (row === 0) {
              TH.style =
                'font-weight:normal;border-bottom:1px solid #d7deea;border-top:1px solid #d7deea;text-align: center;vertical-align: middle;border-radius: 0px!important;margin: none; '
            } else {
              TH.style =
                'font-weight:normal;border-bottom:1px solid #d7deea;border-top:1px solid #f8fafc; text-align: center;vertical-align: middle;border-radius: 0px!important;margin: none; '
            }
          }}
        />
      </>
    )
  }
}

export default TotalBetaTable
