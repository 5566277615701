import React from 'react'
import PropTypes from 'prop-types'
import { noop } from 'lodash'

import { compose } from 'recompose'
import { connect } from 'react-redux'
import { Grid, Typography } from '@material-ui/core'

import { findOrCreateProperty, getCompByIdAndVersion } from 'client-shared/utils/compPlex.gql'

import { errorNotification } from 'client-shared/redux/actions/notifications'

import { CompPlexEvents } from 'client-shared/constants/compPlex'
import { WIZARD_STEPS } from 'client-shared/constants/properties'

import { getLocationInfoFromAddress } from 'client-shared/utils/propertyHelper'

import { PropertyWizard } from '../../../../../../shared/components'

import DialogHeader from '../../../../../../shared/components/DialogHeader'

import { transformSalesCompToRawOutput } from './helpers/transformers'
import { DRMBasicSearch } from './BasicSearch'
import { DRMAdvancedSearch } from './AdvancedSearch'
import { DRMSearchResults } from './SearchResults'
import { CompPlexSearchResults } from './CompPlexSearchResults'

const DRM_EDIT_STEP = 'Edit'

const EDIT_COMP_SOURCE_WIZARD = 'create-wizard'

class CreateSalesCompWizard extends React.Component {
  static propTypes = {
    addComp: PropTypes.func.isRequired,
    compPlexRef: PropTypes.any.isRequired,
    editCompProps: PropTypes.object,
    showEditModal: PropTypes.func.isRequired,
    hideEditModal: PropTypes.func.isRequired,
    onClose: PropTypes.func,
    initialValues: PropTypes.object,
    googleAddress: PropTypes.object,
  }

  state = {
    openPreviousStep: null,
  }

  static defaultProps = {
    onClose: noop,
  }

  componentDidMount() {
    const { compPlexRef } = this.props

    if (compPlexRef.current) {
      compPlexRef.current.addEventListener(CompPlexEvents.EDIT_COMP_COMPLETED, this.handleEditModalCompleted)
      compPlexRef.current.addEventListener(CompPlexEvents.EDIT_COMP_CLOSED, this.handleEditModalClosed)
      compPlexRef.current.addEventListener(CompPlexEvents.EDIT_COMP_SELECT_TOGGLED, this.handleEditModalSelected)
    }
  }

  componentWillUnmount() {
    const { compPlexRef } = this.props
    if (compPlexRef.current) {
      compPlexRef.current.removeEventListener(CompPlexEvents.EDIT_COMP_COMPLETED, this.handleEditModalCompleted)
      compPlexRef.current.removeEventListener(CompPlexEvents.EDIT_COMP_CLOSED, this.handleEditModalClosed)
      compPlexRef.current.removeEventListener(CompPlexEvents.EDIT_COMP_SELECT_TOGGLED, this.handleEditModalSelected)
    }
  }

  showEditCompModal = ({ openNextStep, openPreviousStep, salesComp }) => {
    openNextStep(DRM_EDIT_STEP)
    this.props.showEditModal({
      source: EDIT_COMP_SOURCE_WIZARD,
      creating: !salesComp.id,
      salesComp: salesComp,
    })
    // This is a terrible hack, but it's needed so we can call openPreviousStep later in handleEditModalClosed
    this.setState({ openPreviousStep })
  }

  handleEditModalCompleted = event => {
    const { source, salesComp } = event.detail
    if (source !== EDIT_COMP_SOURCE_WIZARD) {
      return
    }
    this.select(salesComp)
  }

  handleEditModalClosed = event => {
    const { source } = event.detail
    if (source !== EDIT_COMP_SOURCE_WIZARD) {
      return
    }

    this.props.hideEditModal()

    const { openPreviousStep } = this.state
    if (openPreviousStep) {
      openPreviousStep()
      this.setState({ openPreviousStep: null })
    }
  }

  handleEditModalSelected = event => {
    const { source } = event.detail
    if (source !== EDIT_COMP_SOURCE_WIZARD) {
      return
    }
    const { editCompProps } = this.props
    if (editCompProps) {
      this.select(editCompProps.salesComp)
    }
  }

  handleCompOrAddressSelected =
    ({ openNextStep, openPreviousStep }) =>
    async property => {
      if (property.id) {
        const salesCompWithPropertyInformation = await getCompByIdAndVersion({ salesTransactionId: property.id })
        this.select(salesCompWithPropertyInformation)
      } else {
        try {
          const {
            user: { fullName },
          } = this.props
          const { address, propertyInformation = {} } = property
          const createdBy = fullName
          const createOrFindPropertyInput = {
            address,
            propertyInformation,
            createdBy,
            requiredFields: [],
          }

          const compplexProperty = await findOrCreateProperty(createOrFindPropertyInput)
          const salesCompWithPropertyInfo = {
            ...property,
            propertyInformation: compplexProperty.propertyInformation,
            propertyAndVersionReference: {
              propertyId: compplexProperty.id,
              versionNumber: compplexProperty.version,
            },
          }
          this.showEditCompModal({ openNextStep, openPreviousStep, salesComp: salesCompWithPropertyInfo })
        } catch (ex) {
          this.props.errorNotification(ex.message || ex)
        }
      }
    }

  onSearchComplete =
    (form, openNextStep) =>
    ({ properties, step = WIZARD_STEPS.SEARCH_RESULTS, locationIdentifier }) => {
      form.change('search.locationIdentifier', locationIdentifier)
      openNextStep(step, { properties, selectedPropertyIndex: null })
    }

  select = values => {
    const { addComp, hideEditModal } = this.props
    const newComp = values

    hideEditModal()

    const localSalesComp = transformSalesCompToRawOutput(newComp)
    addComp(localSalesComp)
  }

  getTitle = () => {
    const { googleAddress } = this.props
    const title = googleAddress ? 'Search Comparable Address' : 'Enter New Comparable Address'
    return title
  }

  DRMBasicSearch = () => {
    return (
      <PropertyWizard.Step
        title={this.getTitle()}
        step={WIZARD_STEPS.BASIC_SEARCH}
        dialogProps={{ onClick: evt => evt.stopPropagation() }}
      >
        {({ openNextStep, form, values, invalid }) => (
          <DRMBasicSearch
            values={values}
            openNextStep={openNextStep}
            onSearchComplete={this.onSearchComplete(form, openNextStep)}
            invalid={invalid}
          />
        )}
      </PropertyWizard.Step>
    )
  }

  CompPlexAddressSearch = () => {
    const { googleAddress, onClose } = this.props
    const transformedAddress = googleAddress && getLocationInfoFromAddress(googleAddress.addressInfo)

    return (
      <PropertyWizard.Step step={WIZARD_STEPS.COMPPLEX_ADDRESS_SEARCH}>
        {({
          openNextStep,
          openPreviousStep,
          form,
          values,
          stepSpecific: { selectedPropertyIndex, onPropertySelect },
        }) => {
          return (
            <CompPlexSearchResults
              selectedPropertyIndex={selectedPropertyIndex}
              openPreviousStep={openPreviousStep}
              onSubmit={this.handleCompOrAddressSelected({ form, values, openNextStep, openPreviousStep })}
              onPropertySelect={onPropertySelect}
              openDetails={salesComp => {
                this.showEditCompModal({ openNextStep, openPreviousStep, salesComp })
              }}
              values={values}
              addressInfo={transformedAddress}
              handleClose={onClose}
            />
          )
        }}
      </PropertyWizard.Step>
    )
  }

  DRMAdvancedSearch = () => {
    return (
      <PropertyWizard.Step
        title={this.getTitle()}
        step={WIZARD_STEPS.ADVANCED_SEARCH}
        dialogProps={{ onClick: evt => evt.stopPropagation() }}
      >
        {({ openNextStep, openPreviousStep, form, values, invalid }) => (
          <DRMAdvancedSearch
            values={values}
            invalid={invalid}
            openNextStep={openNextStep}
            onLocationChange={this.onLocationChange}
            onSearchComplete={this.onSearchComplete(form, openNextStep)}
            onSubmit={this.handleCompOrAddressSelected({ form, values, openNextStep, openPreviousStep })}
          />
        )}
      </PropertyWizard.Step>
    )
  }

  DRMSearchResults = () => {
    const renderSearchResultsTitle = (title, values, handleClose) => {
      const properties = values.properties || []
      const resultsMessage =
        !!properties.length && `${properties.length} Possible Address${properties.length > 1 ? 'es' : ''} Found`

      return (
        <DialogHeader onClose={handleClose}>
          <Grid container justify="space-between" alignItems="center">
            <Grid item>
              <Typography variant="h6">Search Results</Typography>
            </Grid>
            <Grid item>
              <Typography variant="body1">{resultsMessage}</Typography>
            </Grid>
          </Grid>
        </DialogHeader>
      )
    }
    return (
      <PropertyWizard.Step
        step={WIZARD_STEPS.SEARCH_RESULTS}
        defaultTitle={renderSearchResultsTitle}
        dialogProps={{ onClick: evt => evt.stopPropagation() }}
      >
        {({
          openNextStep,
          openPreviousStep,
          form,
          values,
          stepSpecific: { properties, selectedPropertyIndex, onPropertySelect },
        }) => (
          <DRMSearchResults
            properties={properties}
            selectedPropertyIndex={selectedPropertyIndex}
            openPreviousStep={openPreviousStep}
            onSubmit={this.handleCompOrAddressSelected({ form, values, openNextStep, openPreviousStep })}
            onPropertySelect={onPropertySelect}
            openDetails={salesComp => {
              this.showEditCompModal({ openNextStep, openPreviousStep, salesComp })
            }}
            values={values}
          />
        )}
      </PropertyWizard.Step>
    )
  }

  renderEdit = () => {
    // Edit Comp modal is rendered by comp-plex element in parent, after calling showEditModal
    return <PropertyWizard.Step step={DRM_EDIT_STEP}>{() => null}</PropertyWizard.Step>
  }

  render() {
    const { initialValues, editCompProps, onClose, googleAddress } = this.props

    return (
      <PropertyWizard
        open
        dialogProps={{
          style: { display: editCompProps ? 'none' : 'block' },
          onClick: evt => evt.stopPropagation(),
        }}
        onSubmit={noop}
        initialValues={initialValues}
        title={this.getTitle()}
        handleClose={onClose}
        initialStep={googleAddress ? WIZARD_STEPS.COMPPLEX_ADDRESS_SEARCH : WIZARD_STEPS.BASIC_SEARCH}
        customSteps={{
          [WIZARD_STEPS.BASIC_SEARCH]: this.DRMBasicSearch(),
          [WIZARD_STEPS.COMPPLEX_ADDRESS_SEARCH]: this.CompPlexAddressSearch(),
          [WIZARD_STEPS.ADVANCED_SEARCH]: this.DRMAdvancedSearch(),
          [WIZARD_STEPS.SEARCH_RESULTS]: this.DRMSearchResults(),
        }}
      >
        {this.renderEdit()}
      </PropertyWizard>
    )
  }
}

const mapDispatchToProps = dispatch => ({
  errorNotification: error => dispatch(errorNotification({ message: error })),
})

export default compose(
  connect(
    state => ({
      user: state.authentication.user,
    }),
    mapDispatchToProps
  )
)(CreateSalesCompWizard)
