import React from 'react'
import PropTypes from 'prop-types'
import { TableCell, withStyles } from '@material-ui/core'

import { TYPES_LIST_WITH_BLOCKS, PROPERTY_TYPES_WITH_BLOCKS_PROPER_CASE } from 'client-shared/constants'

import {
  TableColumnKeys,
  TABLE_ROW_HEIGHT,
  INSPECTION_DATE_OPTIONS,
  STATES_OPTIONS,
  DUE_DATE_OPTIONS,
  REPORT_STATUS_OPTIONS,
} from '../constants'

import PropertyTypeIcon from '../../../../shared/components/PropertyTypeIcon'

import TextFilterInput from './TextFilterInput'
import DropDownFilterInput from './DropDownFilterInput'
import AutosuggestFilterInput from './AutosuggestFilterInput'

const styles = theme => ({
  root: {
    left: 0,
    right: 0,
    zIndex: 100,
    position: 'absolute',
    height: TABLE_ROW_HEIGHT,
    backgroundColor: '#ffff',
    display: 'flex',
    boxSizing: 'border-box',
    alignItems: 'center',
  },
  cell: {
    padding: 0,
    display: 'flex',
    alignItems: 'center',
    height: TABLE_ROW_HEIGHT,
    flex: '1 1',
    boxSizing: 'border-box',
    borderRight: '1px solid rgba(0, 0, 0, 0.12)',
    '&:last-child': {
      borderRight: 'none',
      paddingRight: 0,
    },
    '& input': {
      paddingLeft: 14,
    },
    '& input + svg': {
      paddingRight: 10,
    },
  },

  propertyTypeContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  propertyTypeIcon: {
    paddingRight: 8,
    cursor: 'pointer',
    fill: 'rgba(0, 0, 0, 0.54)',
  },
})

class ReportsFiltersRow extends React.PureComponent {
  static propTypes = {
    changeReportFilters: PropTypes.func.isRequired,
  }

  state = {
    filters: {
      propertyType: null,
      number: '',
      reportClients: '',
      address: '',
      city: '',
      state: '',
      inspectionDate: '',
      dueDate: '',
      status: '',
      appraisers: '',
    },
  }

  renderPropertyTypeOption = option => {
    const { classes } = this.props

    return (
      <div className={classes.propertyTypeContainer}>
        <PropertyTypeIcon
          propertyType={option.value}
          showTooltip={false}
          classes={{ typeIcon: classes.propertyTypeIcon }}
        />
        {option.label}
      </div>
    )
  }

  renderOption = option => option.label

  onFiltersChange = event => {
    const { changeReportFilters } = this.props
    const { name, value } = event.target

    const filter = { [name]: value }

    this.setState(prevState => ({ filters: { ...prevState.filters, ...filter } }))

    if (name === 'status') {
      if (value === 'Automated') {
        changeReportFilters({ isSystemGenerated: true, status: undefined })
      } else {
        changeReportFilters({ [name]: value, isSystemGenerated: false })
      }
    } else {
      changeReportFilters({ [name]: value })
    }
  }

  render() {
    const { classes } = this.props
    const { filters } = this.state

    return (
      <div className={classes.root}>
        <TableCell className={classes.cell} component="div" style={{ flexBasis: '180px' }}>
          <DropDownFilterInput
            options={REPORT_STATUS_OPTIONS}
            name={TableColumnKeys.STATUS}
            onChange={this.onFiltersChange}
            classes={{ menuContainer: classes.menuContainer }}
            renderOption={this.renderOption}
            value={filters.status}
          />
        </TableCell>
        <TableCell className={classes.cell} component="div" style={{ flexBasis: '117px' }}>
          <DropDownFilterInput
            name={TableColumnKeys.PROPERTY_TYPE}
            options={TYPES_LIST_WITH_BLOCKS}
            onChange={this.onFiltersChange}
            renderOption={this.renderPropertyTypeOption}
            classes={{ menuContainer: classes.menuContainer }}
            value={PROPERTY_TYPES_WITH_BLOCKS_PROPER_CASE[filters.propertyType] || ''}
          />
        </TableCell>
        <TableCell className={classes.cell} component="div" style={{ flexBasis: '141px' }}>
          <TextFilterInput value={filters.number} name={TableColumnKeys.NUMBER} onChange={this.onFiltersChange} />
        </TableCell>

        <TableCell className={classes.cell} component="div" style={{ flexBasis: '267px' }}>
          <TextFilterInput
            value={filters.reportClients}
            name={TableColumnKeys.REPORT_CLIENTS}
            onChange={this.onFiltersChange}
          />
        </TableCell>
        <TableCell className={classes.cell} component="div" style={{ flexBasis: '240px' }}>
          <TextFilterInput value={filters.address} name={TableColumnKeys.ADDRESS} onChange={this.onFiltersChange} />
        </TableCell>
        <TableCell className={classes.cell} component="div" style={{ flexBasis: '163px' }}>
          <TextFilterInput value={filters.city} name={TableColumnKeys.CITY} onChange={this.onFiltersChange} />
        </TableCell>
        <TableCell className={classes.cell} component="div" style={{ flexBasis: '145px' }}>
          <AutosuggestFilterInput
            options={STATES_OPTIONS}
            name={TableColumnKeys.STATE}
            onChange={this.onFiltersChange}
            value={filters.state}
          />
        </TableCell>
        <TableCell className={classes.cell} component="div" style={{ flexBasis: '156px' }}>
          <DropDownFilterInput
            options={INSPECTION_DATE_OPTIONS}
            onChange={this.onFiltersChange}
            name={TableColumnKeys.INSPECTION_DATE}
            renderOption={this.renderOption}
            classes={{ menuContainer: classes.menuContainer }}
            value={filters.inspectionDate}
          />
        </TableCell>
        <TableCell className={classes.cell} component="div" style={{ flexBasis: '167px' }}>
          <DropDownFilterInput
            options={DUE_DATE_OPTIONS}
            onChange={this.onFiltersChange}
            name={TableColumnKeys.DUE_DATE}
            renderOption={this.renderOption}
            classes={{ menuContainer: classes.menuContainer }}
            value={filters.dueDate}
          />
        </TableCell>
        <TableCell className={classes.cell} component="div" style={{ flexBasis: '296px' }}>
          <TextFilterInput
            value={filters.appraisers}
            onChange={this.onFiltersChange}
            name={TableColumnKeys.APPRAISERS}
          />
        </TableCell>
      </div>
    )
  }
}

export default withStyles(styles)(ReportsFiltersRow)
