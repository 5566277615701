import { get } from 'lodash'

/*
 * Increase Over Base Year
 */
export const getTaxLiabilityIncrease = (unitReimbursement, baseYearLiabilityKnown, taxInfo, taxRateHistory) => {
  const { taxLiability } = taxInfo
  return taxLiability - getBaseYearTaxes(unitReimbursement, baseYearLiabilityKnown, taxRateHistory)
}
export const getAnnualReimbursement = (unitReimbursement, baseYearLiabilityKnown, taxInfo, taxRateHistory) => {
  const reimbursementShare = get(unitReimbursement, 'share')
  if (reimbursementShare) {
    return (
      getTaxLiabilityIncrease(unitReimbursement, baseYearLiabilityKnown, taxInfo, taxRateHistory) *
      (reimbursementShare / 100)
    )
  }
  return 0
}
export const getBaseYearTaxes = (unitReimbursement, baseYearLiabilityKnown, taxRateHistory) => {
  if (baseYearLiabilityKnown) {
    return get(unitReimbursement, 'baseYearLiability', 0)
  }
  const baseYearTaxRate = getBaseYearTaxRate(unitReimbursement, taxRateHistory)
  const baseYearAssessment = get(unitReimbursement, 'baseYearAssessment')
  if (baseYearTaxRate && baseYearAssessment) {
    return baseYearTaxRate * baseYearAssessment
  }
  return 0
}
export const getBaseYearTaxRate = (unitReimbursement, taxRateHistory) => {
  const taxRateInfo = taxRateHistory.find(taxRateInfo => taxRateInfo.year === get(unitReimbursement, 'baseYear'))
  return get(taxRateInfo, 'taxRate') || 0
}
export const getBaseYearAssessment = (unitReimbursement, baseYearLiabilityKnown, taxRateHistory) => {
  if (!baseYearLiabilityKnown) {
    return get(unitReimbursement, 'baseYearAssessment', 0)
  }
  const baseYearTaxRate = getBaseYearTaxRate(unitReimbursement, taxRateHistory)
  const baseYearLiability = get(unitReimbursement, 'baseYearLiability')
  if (baseYearTaxRate && baseYearLiability) {
    return baseYearLiability / baseYearTaxRate
  }
  return 0
}
export const getCurrentYearTaxes = taxInfo => {
  return taxInfo.taxLiability
}

/*
 * Current Year Liability
 */
export const getCurrentYearLiabilityGrossReimbursement = unitReimbursement => {
  const currentYearLiability = get(unitReimbursement, 'currentYearLiability', 0)
  const percentOfCurrentYearLiability = get(unitReimbursement, 'percentOfCurrentYearLiability', 0)

  if (currentYearLiability && percentOfCurrentYearLiability) {
    return currentYearLiability * (percentOfCurrentYearLiability / 100)
  }

  return 0
}

export const getCurrentYearLiabilityAdminFee = unitReimbursement => {
  const currentYearLiability = get(unitReimbursement, 'currentYearLiability', 0)
  const adminFeePercent = get(unitReimbursement, 'adminFeePercentage', 0)

  if (currentYearLiability && adminFeePercent) {
    return currentYearLiability * (adminFeePercent / 100)
  }

  return 0
}

export const getAppraiserForecastAdminFee = (appraisersForecast = 0, adminFeePercent) => {
  if (appraisersForecast && adminFeePercent) {
    return appraisersForecast * (adminFeePercent / 100)
  }
  return 0
}
