import {
  GeneratedText,
  gtIfElse,
  gtLocked,
  gtLockedSentence,
  gtTemplate,
  gtText,
} from 'shared/utils/narrative/GeneratedText'

import { mapDTO, mapDataModel } from './mappings'

const generate = () => {
  const typeGt = gtLocked('type')

  const baseWriteup = gtTemplate`This adjustment accounts for the difference in size between each of the 
    comparables and the subject property. We note that there is an inverse relationship between size and value 
    ${typeGt} such that smaller properties will sell for higher prices ${typeGt} and vice versa.`

  const gt = new GeneratedText()

  gt.addParts(
    gtIfElse(
      'hasAdjustments',
      [...baseWriteup, gtLockedSentence('upwardCompsSentence'), gtLockedSentence('downwardCompsSentence')],
      [
        ...baseWriteup,
        gtText(
          ' The subject and all of the comparables are within a reasonable size range, thus no adjustments were necessary.'
        ),
      ]
    )
  )

  return gt.getParts()
}

export default { generate, mapDTO, mapDataModel }
