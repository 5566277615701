import PropTypes from 'prop-types'
import React from 'react'

import { FormGroup, Paper, Stack, Typography } from '@mui/material'
import { get } from 'lodash'

import { ANCHOR_PROPERTY_HISTORY } from 'shared/constants/report/dataCollections'
import propertyContractHistory from 'shared/utils/textGeneration/property/propertyContractHistory'
import recentSalesHistory from 'shared/utils/textGeneration/property/recentSalesHistory'
import { PROPERTY_HISTORY_PATH } from 'shared/constants/report/keysAndDataPaths'
import { GEOGRAPHY_OPTIONS } from 'shared/constants/property/geography'
import {
  CalloutButton,
  CheckboxWithLabel,
  NarrativeComponent,
  UneditableSection,
  UneditableField,
} from 'client-shared/components'
import { getStateAuthorityLink } from 'client-shared/utils/propertyHelper'

import wrapForm from '../../wrapForm'

import AppraisalHistory from './AppraisalHistory'
import ContractHistory from './ContractHistory'
import UneditableSalesHistory from './UneditableSalesHistory'

export const DATA_PATH = PROPERTY_HISTORY_PATH
const heading = 'Property History'

class PropertyHistory extends React.PureComponent {
  static propTypes = {
    streetAddress: PropTypes.string.isRequired,
    block: PropTypes.string,
    lot: PropTypes.string,
    propertyIdentifier: PropTypes.string,
    stateAuthorityLink: PropTypes.string,
    locationInfo: PropTypes.shape({
      isNYCLocation: PropTypes.bool.isRequired,
      isNJLocation: PropTypes.bool.isRequired,
    }),
    currentPath: PropTypes.string,
  }

  static defaultProps = {
    block: null,
    lot: null,
    propertyIdentifier: null,
    stateAuthorityLink: null,
  }

  render() {
    const {
      form: { values },
      stateAuthorityLink,
      streetAddress,
      block,
      lot,
      propertyIdentifier,
      locationInfo,
      currentPath,
    } = this.props
    const wasAppraisedWithinThreeYears = values.wasAppraisedWithinThreeYears

    return (
      <Stack maxWidth={900} spacing={2}>
        {stateAuthorityLink && (
          <CalloutButton
            variant="link"
            text="Visit your state's authority to confirm sales data before continuing."
            linkText="Visit"
            link={stateAuthorityLink}
            target="_blank"
          />
        )}
        <Paper>
          <Stack spacing={2}>
            <Typography variant="h6">Property Historical Information</Typography>
            <UneditableSection
              name="Found In: Subject Property Data"
              formName="Subject Property Data"
              link="subject-property"
              anchor={ANCHOR_PROPERTY_HISTORY}
              returnDataPath={currentPath}
              editDataMessage
            >
              <Stack spacing={1}>
                <UneditableField label="Current Owner" name="currentOwner" />
                <UneditableSalesHistory
                  label="Property Sales History"
                  name="salesHistory"
                  streetAddress={streetAddress}
                  block={block}
                  lot={lot}
                  propertyIdentifier={propertyIdentifier}
                />
              </Stack>
            </UneditableSection>
            <NarrativeComponent
              title="Recent Sales History Discussion"
              generatedText={recentSalesHistory.generate}
              data={recentSalesHistory.mapDTO({
                ...values,
                streetAddress,
                locationInfo,
              })}
              name="salesHistoryDiscussion"
              regenerateOnChange={['orderedSalesHistory']}
            />
            <FormGroup>
              <Stack spacing={1}>
                <Typography variant="subtitle1" sx={{ fontWeight: 400 }}>
                  Property Contract History
                </Typography>
                <CheckboxWithLabel name="isUnderContract" label="The subject is currently in contract" />
                <ContractHistory />
                <NarrativeComponent
                  title="Property Contract History Discussion"
                  generatedText={propertyContractHistory.generate}
                  data={propertyContractHistory.mapDTO(values)}
                  name="contractHistoryDiscussion"
                  // TODO: change this -> add to data
                  regenerateOnChange={['isUnderContract', 'isAssignedContract']}
                />
              </Stack>
            </FormGroup>
            <FormGroup>
              <Stack spacing={1}>
                <Typography variant="subtitle1" sx={{ fontWeight: 400 }}>
                  Property Appraisal History
                </Typography>
                <CheckboxWithLabel
                  name="wasAppraisedWithinThreeYears"
                  label="This property has been appraised in the last three years"
                />
                {wasAppraisedWithinThreeYears && <AppraisalHistory />}
              </Stack>
            </FormGroup>
          </Stack>
        </Paper>
      </Stack>
    )
  }
}

export default wrapForm(
  DATA_PATH,
  {
    heading,
  },
  state => {
    const currentPath = get(state, 'shared.location.form.id')

    const propertySummary = get(state, 'report.reportData.propertyInformation.propertySummary', {})
    const block = get(state, 'report.reportData.block')
    const lot = get(state, 'report.reportData.lot')
    const stateAuthorityLink = getStateAuthorityLink(get(state, 'report'))

    const locationIdentifier = get(state, 'report.reportData.locationIdentifier')

    const isNYCLocation = locationIdentifier === GEOGRAPHY_OPTIONS.NY
    const isNJLocation = locationIdentifier === GEOGRAPHY_OPTIONS.NJ

    return {
      streetAddress: propertySummary.streetAddress,
      locationInfo: {
        isNYCLocation,
        isNJLocation,
      },
      block,
      lot,
      propertyIdentifier: propertySummary.propertyIdentifier,
      stateAuthorityLink,
      currentPath,
    }
  }
)(PropertyHistory)
