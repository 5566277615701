import React from 'react'
import PropTypes from 'prop-types'

import ToggleButton from '@mui/material/ToggleButton'
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup'
import { noop } from 'lodash'

const SwitchButtonGroup = ({ value, onChange, items, disabled, sx }) => {
  const handleOnChange = React.useCallback(
    (_event, newValue) => {
      if (newValue === null) {
        return
      }
      onChange(newValue)
    },
    [onChange]
  )

  return (
    <ToggleButtonGroup exclusive disabled={disabled} onChange={handleOnChange} sx={{ mb: 1, ...sx }} value={value}>
      {items.map(item => {
        const selected = value === item.value
        return (
          <ToggleButton
            key={item.value}
            data-qa={`${item.label}-switch-button`}
            data-qa-isselected={`${selected}`}
            value={item.value}
          >
            {item.label}
          </ToggleButton>
        )
      })}
    </ToggleButtonGroup>
  )
}

SwitchButtonGroup.displayName = 'SwitchButton'

SwitchButtonGroup.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.string.isRequired,
    })
  ).isRequired,
  onChange: PropTypes.func,
}

SwitchButtonGroup.defaultProps = {
  onChange: noop,
}

export default React.memo(SwitchButtonGroup)
