import React from 'react'
import PropTypes from 'prop-types'
import { isEmpty } from 'lodash'
import { compose } from 'redux'

import BoweryDate from '@bowery-valuation/bowery-date'
import { Typography, Stack } from '@mui/material'
import { withFeatureFlag } from '@bowery-valuation/feature-flagger-client'

import { getCommercialPropertyType } from 'shared/helpers/incomeApproach/commercial'
import { USE_COMMERCIAL_SUBTYPE } from 'shared/constants/featureFlags'
import { longDateFormat } from 'client-shared/utils/dateHelper'

import { PreviewSection, AppraisersList } from '../components'

import { EXTRAORDINARY_ASSUMPTIONS_SECTION, HYPOTHETICAL_CONDITIONS_SECTION, SINCERELY_TEXT } from './constants'

import FreddieMacLanguage from './FreddieMacLanguage'

class Preview extends React.PureComponent {
  static propTypes = {
    clientFileNumbers: PropTypes.array,
    occupancy: PropTypes.string,
    reportClients: [
      PropTypes.shape({
        abbr: PropTypes.string.isRequired,
        address: PropTypes.string.isRequired,
        city: PropTypes.string.isRequired,
        client: PropTypes.string.isRequired,
        firstName: PropTypes.string.isRequired,
        lastName: PropTypes.string.isRequired,
        title: PropTypes.string.isRequired,
        prefix: PropTypes.string.isRequired,
      }),
    ],
    assumptions: PropTypes.arrayOf(PropTypes.string),
    conditions: PropTypes.arrayOf(PropTypes.string),
    address: PropTypes.string,
    city: PropTypes.string.isRequired,
    propertyType: PropTypes.string.isRequired,
    propertySubtype: PropTypes.string,
    letterOfTransmittalPurpose: PropTypes.string,
    letterOfTransmittalComplianceParagraph: PropTypes.string,
    letterOfTransmittalValuationPrefix: PropTypes.string,
    letterOfTransmittalCovidLanguage: PropTypes.string,
    letterOfTransmittalOpinionOfValue: PropTypes.string,
    letterOfTransmittalThankYou: PropTypes.string,
    buildingDescriptionAsIs: PropTypes.shape({
      commentary: PropTypes.string.isRequired,
      additionalCommentary: PropTypes.string,
    }),
    buildingDescriptionAsComplete: PropTypes.shape({
      commentary: PropTypes.string.isRequired,
      additionalCommentary: PropTypes.string,
    }),
    locationDescription: PropTypes.string,
    legalPropertyIdentification: PropTypes.string,
    conclusionAsImproved: PropTypes.shape({
      commentary: PropTypes.string,
      additionalCommentary: PropTypes.string,
    }),
    clientGuidelinesDiscussionText: PropTypes.string,
    occupancyRateDiscussion: PropTypes.string,
    reportNumber: PropTypes.string.isRequired,
    salutation: PropTypes.string.isRequired,
    freddieMacLanguage: PropTypes.array.isRequired,
    isFreddieCompliant: PropTypes.bool.isRequired,
    reportState: PropTypes.string.isRequired,
    isFreddieMac: PropTypes.bool.isRequired,
  }

  static defaultProps = {
    clientFileNumbers: [],
    buildingDescriptionAsComplete: {},
    buildingDescriptionAsIs: {},
    occupancyRateDiscussion: '',
    legalPropertyIdentification: null,
    clientGuidelinesDiscussionText: '',
    conclusionAsImproved: {},
    occupancy: null,
    salutation: 'Dear [CLIENT],',
  }

  render() {
    const {
      city,
      address,
      salutation,
      appraisers,
      reportNumber,
      propertyType,
      propertySubtype,
      letterOfTransmittalPurpose,
      letterOfTransmittalComplianceParagraph,
      letterOfTransmittalValuationPrefix,
      letterOfTransmittalCovidLanguage,
      letterOfTransmittalOpinionOfValue,
      letterOfTransmittalThankYou,
      locationDescription,
      conclusionAsImproved,
      assumptions,
      conditions,
      occupancyRateDiscussion,
      buildingDescriptionAsIs,
      clientGuidelinesDiscussionText,
      legalPropertyIdentification,
      buildingDescriptionAsComplete,
      freddieMacLanguage,
      isFreddieCompliant,
      clientFileNumbers,
      reportClients,
      reportState,
      isFreddieMac,
      getClient,
      hideClientName,
      useCommercialSubtype,
    } = this.props

    const propertyTypeToDisplay = getCommercialPropertyType(propertyType, propertySubtype, useCommercialSubtype)

    return (
      <>
        <PreviewSection>
          <Typography>{longDateFormat(new BoweryDate())}</Typography>
        </PreviewSection>
        <PreviewSection>
          <Stack spacing={2} direction="row">
            {reportClients.map(client => {
              return <PreviewSection key={client.id}>{getClient(client, hideClientName)}</PreviewSection>
            })}
          </Stack>
          <PreviewSection>
            {clientFileNumbers && (
              <Typography variant="body1" gutterBottom>
                <span>Re: &nbsp;&nbsp;&nbsp;</span>
                {clientFileNumbers
                  .map(number => {
                    return `Project Number ${number}`
                  })
                  .join(', ')}
              </Typography>
            )}
            <Typography variant="body1" gutterBottom>
              {isEmpty(clientFileNumbers) && <span>Re: &nbsp;&nbsp;&nbsp;</span>} Appraisal File No. {reportNumber}
            </Typography>
            <Typography variant="body1" gutterBottom>
              {propertyTypeToDisplay} Building
            </Typography>
            <Typography variant="body1" gutterBottom>
              {address}
            </Typography>
            <Typography variant="body1" gutterBottom>
              {city}
            </Typography>
          </PreviewSection>
          <PreviewSection>
            <Typography variant="body1" gutterBottom>
              {salutation}
            </Typography>
          </PreviewSection>
          <PreviewSection>
            <Typography variant="body1" gutterBottom>
              {letterOfTransmittalPurpose}
            </Typography>
          </PreviewSection>
          <PreviewSection>
            <Typography variant="body1" gutterBottom>
              {buildingDescriptionAsIs.commentary} {buildingDescriptionAsComplete.commentary} {occupancyRateDiscussion}
            </Typography>
          </PreviewSection>
          <PreviewSection>
            <Typography variant="body1" gutterBottom>
              {legalPropertyIdentification} {locationDescription} {conclusionAsImproved.commentary}{' '}
              {conclusionAsImproved.additionalCommentary}
            </Typography>
          </PreviewSection>
          <PreviewSection>
            <Typography variant="body1" gutterBottom>
              {clientGuidelinesDiscussionText}
            </Typography>
          </PreviewSection>
          <PreviewSection>
            <Typography variant="body1" gutterBottom>
              {letterOfTransmittalComplianceParagraph.map(txt => (
                <p>{txt}</p>
              ))}
            </Typography>
          </PreviewSection>
          <PreviewSection>
            <Typography variant="body1" gutterBottom>
              {letterOfTransmittalValuationPrefix.map(txt => (
                <p>{txt}</p>
              ))}
              <p>[Final Value Conclusion Table Here]</p>
            </Typography>
          </PreviewSection>
          <PreviewSection>
            <Typography variant="body1" gutterBottom>
              {letterOfTransmittalCovidLanguage.map(txt => (
                <p>{txt}</p>
              ))}
            </Typography>
          </PreviewSection>
          <PreviewSection>
            <Typography variant="body1" gutterBottom>
              {EXTRAORDINARY_ASSUMPTIONS_SECTION}
            </Typography>
            <ul>
              {assumptions.map((assumption, index) => (
                <li key={index}>{assumption} </li>
              ))}
            </ul>
            <Typography variant="body1" gutterBottom>
              {HYPOTHETICAL_CONDITIONS_SECTION}
            </Typography>
            <ul>
              {conditions.map((condition, index) => (
                <li key={index}>{condition} </li>
              ))}
            </ul>
          </PreviewSection>
          <PreviewSection>
            <Typography variant="body1" gutterBottom>
              {letterOfTransmittalOpinionOfValue.map(txt => (
                <p>{txt}</p>
              ))}
            </Typography>
          </PreviewSection>
          {isFreddieCompliant && (
            <PreviewSection>
              <FreddieMacLanguage items={freddieMacLanguage} />
            </PreviewSection>
          )}
          <PreviewSection>
            <Typography variant="body1" gutterBottom>
              {letterOfTransmittalThankYou.map(txt => (
                <p>{txt}</p>
              ))}
            </Typography>
          </PreviewSection>
          <PreviewSection>
            <Typography variant="body1" gutterBottom>
              {SINCERELY_TEXT}
            </Typography>
          </PreviewSection>
          <AppraisersList appraisers={appraisers} reportState={reportState} isFreddieMac={isFreddieMac} />
        </PreviewSection>
      </>
    )
  }
}

export default compose(withFeatureFlag(USE_COMMERCIAL_SUBTYPE, 'useCommercialSubtype'))(Preview)
