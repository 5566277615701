import { withStyles } from '@material-ui/core'

import Number from '../Number/Number'

export default withStyles({
  formControl: {
    height: 32,
    width: 'auto',
    margin: 0,
    display: 'inline-flex',
    justifyContent: 'center',
  },
  root: {
    fontSize: 14,
    height: '100%',
    paddingLeft: 0,
    '& fieldset': {
      display: 'none',
    },
  },
  input: {
    height: 'inherit',
    fontSize: 'inherit',
    padding: 0,
  },
  error: {
    color: '#D74F4F',
  },
})(Number)
