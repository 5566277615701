import React from 'react'

import { Field } from 'react-final-form'

import ImageUploadList from 'client-shared/components/ImageUploadList'

const ImageUploadListField = ({ name, ...restProps }) => (
  <Field
    {...restProps}
    name={name}
    render={({ input, ...restInnerProps }) => <ImageUploadList {...restInnerProps} {...input} />}
  />
)

export default ImageUploadListField
