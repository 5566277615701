import { isEmpty } from 'lodash'

export const getDisplayAddress = ({ address, city, state, zip }) => {
  if (!address) {
    return undefined
  }

  const isFullAddress =
    /USA$/.test(address) ||
    (!isEmpty(city) && address.includes(city)) ||
    (!isEmpty(state) && address.includes(state)) ||
    (!isEmpty(zip) && address.includes(zip))

  if (isFullAddress) {
    return address
  }

  let displayAddress = address

  if (city) {
    displayAddress += `, ${city}`
  }
  if (state) {
    displayAddress += `, ${state}`
  }
  if (city && state && zip) {
    displayAddress += ` ${zip}`
  }

  return displayAddress
}
