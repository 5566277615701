import { get, isEqualWith, isNil } from 'lodash'

import { areNarrativesEqual, isNarrativeValue } from 'client-shared/components/NarrativeComponent'

import { formatCurrencyInt, formatCurrencyFloat } from '../../../../shared/utils/numberFormatters'

import { NOT_AVAILABLE } from '../../../constants'

import { BasisTypes } from '../../../../../../shared/constants/report/incomeApproach/expenses'

export const formatCurrencyByBasis =
  (formatBasis = BasisTypes.PER_SF) =>
  value => {
    if (isNil(value)) {
      return NOT_AVAILABLE
    }

    return formatBasis === BasisTypes.PER_UNIT ? formatCurrencyInt(value) : formatCurrencyFloat(value)
  }

export const getCompTotalByBasis =
  (formatBasis = BasisTypes.PER_SF) =>
  (compTotalsMap, type) => {
    const prefix = formatBasis === BasisTypes.PER_SF ? 'psf' : 'unit'
    return get(compTotalsMap, `${prefix}${type}`)
  }

const isEqualCustomizer = (valueA, valueB) => {
  if (isNarrativeValue(valueA) || isNarrativeValue(valueB)) {
    return areNarrativesEqual(valueA, valueB)
  }
}

export const areCustomExpensesEqual = (valueA, valueB) => {
  return (
    valueA &&
    valueB &&
    valueA.length === valueB.length &&
    valueA.every((customExpense, index) => isEqualWith(customExpense, valueB[index], isEqualCustomizer))
  )
}
