import React from 'react'

import { Stack, Tooltip, Typography } from '@mui/material'
import OpenInNewRounded from '@mui/icons-material/OpenInNewRounded'

import arrayMutators from 'final-form-arrays'
import { DragDropContext } from 'react-beautiful-dnd'
import { FieldArray } from 'react-final-form-arrays'
import { eq, get } from 'lodash'

import { CalloutButton, CheckboxWithLabel } from 'client-shared/components'

import { SWOT_ANALYSIS_PATH } from 'shared/constants/report/keysAndDataPaths'

import { TEMPLATE_TYPES } from '../../../constants/settings'
import wrapForm from '../../wrapForm'

import {
  SUBJECT_PROPERTY_CATEGORIES,
  SWOT_ANALYSIS_CALLOUT_TEXT,
  SWOT_ANALYSIS_LINK,
  SWOT_ANALYSIS_LINK_TEXT,
} from './constants'
import SubjectPropertyCategory from './SubjectPropertyCategoryContainer'

const heading = 'SWOT Analysis'
const DATA_PATH = SWOT_ANALYSIS_PATH

class SwotAnalysis extends React.PureComponent {
  state = {
    dragging: false,
  }

  get includeSwotLabel() {
    return (
      <Stack>
        <Typography variant="subtitle1" component="span">
          Include SWOT Analysis in Report
        </Typography>
        <Typography variant="inputLabel" sx={{ fontWeight: 700 }}>
          (Required for Freddie Mac-Compliant Appraisals)
        </Typography>
      </Stack>
    )
  }

  onDragEnd = ({ source, destination, type }) => {
    const { mutators } = this.props.form

    this.setState({ dragging: false })

    if (!destination) {
      return
    }
    mutators.move(type, source.index, destination.index)
  }

  onDragStart = () => this.setState({ dragging: true })

  render() {
    const { form, isFreddieMacReport } = this.props
    const { dragging } = this.state
    const { includeInReport } = form.values

    return (
      <Stack spacing={2} sx={{ maxWidth: 900 }}>
        <CalloutButton
          link={SWOT_ANALYSIS_LINK}
          linkIcon={
            <Tooltip title={SWOT_ANALYSIS_LINK_TEXT}>
              <OpenInNewRounded fontSize="small" />
            </Tooltip>
          }
          target="_blank"
          text={SWOT_ANALYSIS_CALLOUT_TEXT}
          variant="link"
        />
        <CheckboxWithLabel name="includeInReport" label={this.includeSwotLabel} disabled={isFreddieMacReport} />
        <DragDropContext onDragStart={this.onDragStart} onDragEnd={this.onDragEnd}>
          <Stack spacing={1}>
            {SUBJECT_PROPERTY_CATEGORIES.map((category, index) => {
              const values = form.values[category]
              return (
                <FieldArray
                  changeField={form.change}
                  component={SubjectPropertyCategory}
                  disabled={!includeInReport}
                  dragging={dragging}
                  isEqual={eq}
                  key={index}
                  name={category}
                  values={values}
                />
              )
            })}
          </Stack>
        </DragDropContext>
      </Stack>
    )
  }
}

const formOptions = {
  heading,
  initialValues: {
    includeInReport: true,
    strengths: [''],
    weaknesses: [''],
    opportunities: [''],
    threats: [''],
  },
  mutators: {
    ...arrayMutators,
  },
}

export default wrapForm(DATA_PATH, formOptions, state => {
  const templateType = get(state, 'report.reportSettings.templateType')
  return { isFreddieMacReport: templateType === TEMPLATE_TYPES.FREDDIE_MAC }
})(SwotAnalysis)
