import { lowerCase } from 'lodash'

export const getExpense = (key: string) => {
  switch (key) {
    case 'fuel':
      return 'heat'
    case 'gas':
      return 'cooking gas'
    case 'electricity':
      return 'in-unit electricity'
    case 'commonElectricity':
      return 'common area electricity'
    default:
      return lowerCase(key)
  }
}
