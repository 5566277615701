import { startCase } from 'lodash'
import {
  REFINEMENT_IDS,
  MULTIPLIER_IDS,
  BUILDING_INFO_IDS,
  BASE_UNIT_COST_ID,
  AREA_ID,
  DESCRIPTION_ID,
} from 'shared/constants/insurableValue/mvs'

export const ADJUSTED_BASE_COST_ID = 'adjustedBaseCost'
export const TOTAL_MULTIPLIER_ID = 'totalMultiplier'
export const ADJUSTED_REPLACEMENT_COST_ID = 'adjustedReplacementCostNew'
export const REPLACEMENT_COST_NEW_ID = 'replacementCostNew'

export const editableRows = [
  ...Object.values(REFINEMENT_IDS),
  ...Object.values(MULTIPLIER_IDS),
  ...Object.values(BUILDING_INFO_IDS),
  AREA_ID,
  DESCRIPTION_ID,
  BASE_UNIT_COST_ID,
]

export const GRADE_OPTIONS = {
  aboveGrade: 'aboveGrade',
  belowGrade: 'belowGrade',
}

export const GRADE_LIST = Object.values(GRADE_OPTIONS)
export const CLASS_LIST = ['a', 'b', 'c', 'd', 's']
export const CLASS_TYPE_LIST = ['excellent', 'very good', 'good', 'average', 'low cost']
export const FINISHED_LIST = ['finished', 'unfinished']

export const arrayToValueLabelOption = (values: string[]) => {
  return values.map(value => {
    return {
      value,
      label: startCase(value),
    }
  })
}
