import { get, lowerCase, toString, upperFirst } from 'lodash'
import { PathUtils, Text, Value } from 'slate'

import { narrativeFormatters } from '../../../client/src/shared/utils/narrative'

const FIRST_CHILD_NODE_PATH = PathUtils.create([0])

export const updateLockedInlines = (value, data, formatters) => {
  let rootNode = value.document
  const keyToPath = rootNode.getKeysToPathsTable()

  const lockedItems = rootNode.getInlinesByType('locked')
  lockedItems.forEach(inline => {
    const fieldName = inline.get('data').get('fieldName')
    const formatName = inline.get('data').get('format')
    const displayName = inline.get('data').get('displayName') || upperFirst(lowerCase(fieldName))
    const formatterArguments = inline.get('data').get('formatterArguments') || []
    const newValue = get(data, fieldName, inline.get('data').get('value'))

    const textToReplace = inline.getFirstText()

    if (textToReplace) {
      inline = inline.removeNode(FIRST_CHILD_NODE_PATH)
    }
    let error = false
    let replacementText = ''
    if (newValue || newValue === 0) {
      inline = inline.setIn(['data', 'value'], newValue)
      const format = formatters[formatName]

      replacementText = format ? format(newValue, ...formatterArguments) : toString(newValue)
    } else if (newValue === '' || newValue === null || newValue === undefined) {
      replacementText = displayName
      error = true
    }
    inline = inline
      .insertNode(FIRST_CHILD_NODE_PATH, Text.create({ text: `${replacementText}` }))
      .setIn(['data', 'error'], error)

    rootNode = rootNode.replaceNode(keyToPath[inline.key], inline)
  })

  return value.set('document', rootNode)
}

export const updateLockedSentenceInlines = (value, data) => {
  let rootNode = value.document
  const keyToPath = rootNode.getKeysToPathsTable()

  const lockedSentenceItems = rootNode.getInlinesByType('lockedSentence')
  lockedSentenceItems.forEach(inline => {
    const fieldName = inline.get('data').get('fieldName')
    const newValue = get(data, fieldName)

    const textToReplace = inline.getFirstText()

    if (textToReplace) {
      inline = inline.removeNode(FIRST_CHILD_NODE_PATH)
    }
    let replacementText = ''
    if (newValue || newValue === 0) {
      replacementText = toString(newValue)
    }
    inline = inline.insertNode(FIRST_CHILD_NODE_PATH, Text.create({ text: `${replacementText}` }))

    rootNode = rootNode.replaceNode(keyToPath[inline.key], inline)
  })

  return value.set('document', rootNode)
}

export const updateConditionalInlines = (value, data) => {
  let rootNode = value.document

  const conditionals = rootNode.inlines({ onlyTypes: ['conditional'] })
  Array.from(conditionals, ([node]) => node).forEach(conditional => {
    const fieldName = conditional.get('data').get('fieldName')
    const isInverted = conditional.get('data').get('inverted')

    const newValue = get(data, fieldName)
    const display = isInverted ? !newValue : !!newValue

    const path = rootNode.getPath(conditional)
    rootNode = rootNode.replaceNode(path, conditional.setIn(['data', 'display'], display))
  })

  return value.set('document', rootNode)
}

export const updateNodes = (value, data, formatters) => {
  let updatedValue = updateConditionalInlines(value, data)
  updatedValue = updateLockedSentenceInlines(updatedValue, data)
  updatedValue = updateLockedInlines(updatedValue, data, formatters)

  return updatedValue
}

export const updateNarrative = (narrative, data = {}) => {
  const rootNode = get(narrative, 'document')
  if (rootNode) {
    return updateNodes(Value.create(narrative), data, narrativeFormatters).toJS()
  }
  return []
}

export const removeLockedInlineValues = value => {
  let rootNode = value.document
  const keyToPath = rootNode.getKeysToPathsTable()

  const lockedItems = rootNode.getInlinesByType('locked')
  lockedItems.forEach(inline => {
    const fieldName = inline.get('data').get('fieldName')
    const displayName = inline.get('data').get('displayName') || upperFirst(lowerCase(fieldName))

    const textToReplace = inline.getFirstText()
    if (textToReplace) {
      inline = inline.removeNode(FIRST_CHILD_NODE_PATH)
    }

    inline = inline
      .insertNode(FIRST_CHILD_NODE_PATH, Text.create({ text: '' }))
      .setIn(['data', 'value'], undefined)
      .setIn(['data', 'displayName'], displayName)

    rootNode = rootNode.replaceNode(keyToPath[inline.key], inline)
  })

  return value.set('document', rootNode)
}
