import React, { FC } from 'react'
import { Button } from '@mui/material'
import { ColumnAlignmentEnum } from '@bowery-valuation/ui-components'

import { Grid } from 'client-shared/components/_mui5'

import { ATOMIC_UNIT_TYPES } from 'shared/constants/report/genericSubsection'

import { CustomFormApi } from 'final-form'
import ObjectID from 'bson-objectid'

import { makeStyles } from '@mui/styles'

import { FormValues } from '../../types'
import styles from '../../styles'

// @ts-ignore makeStyles doesn't like the strings - it wants enums
const useStyles = makeStyles(styles)

const CreateAtomicUnit: FC<{ form: CustomFormApi<FormValues> }> = ({ form }) => {
  const atomicUnits = form.getState().values.newGenericSubsection.data.atomicUnits || []
  const nextAtomicUnitIndex = atomicUnits.length
  const formPath = `newGenericSubsection.data.atomicUnits[${nextAtomicUnitIndex}]`
  const classes = useStyles()

  const createDefaultTable = () => {
    const columnConfigurations = []
    const rowData = []

    for (let i = 0; i < 4; i++) {
      const newUniqueId = new ObjectID().toString()
      const newColumn = { label: '', name: newUniqueId, type: 'text' as const, align: ColumnAlignmentEnum.left }

      columnConfigurations.push(newColumn)
    }

    for (let index = 0; index < 7; index++) {
      const columnNames = columnConfigurations.map((column: { name: any }) => column.name)
      const newUniqueId = new ObjectID().toString()

      const newRow = columnNames.reduce(
        (rowBuilder: any, columnName: any) => Object.assign(rowBuilder, { [columnName]: '' }),
        {
          id: newUniqueId,
          rowDef: {
            header: index === 0,
          },
        }
      )

      rowData.push(newRow)
    }

    return { columnConfigurations, rowData }
  }

  const createNarrative = () => {
    form.change(`${formPath}.type`, ATOMIC_UNIT_TYPES.NARRATIVE)
  }
  const createTable = () => {
    const defaultTable = createDefaultTable()

    form.batch(() => {
      form.change(`${formPath}`, defaultTable)
      form.change(`${formPath}.type`, ATOMIC_UNIT_TYPES.TABLE)
    })
  }

  return (
    <div className={classes.createAtomicUnit}>
      <Grid container justifyContent="center" spacing={6}>
        <Grid item>
          <Button variant="contained" onClick={createNarrative}>
            Create Paragraph
          </Button>
        </Grid>
        <Grid item>
          <Button variant="contained" onClick={createTable}>
            Create Table
          </Button>
        </Grid>
      </Grid>
    </div>
  )
}

export default CreateAtomicUnit
