import { calculateRentPSFByTimePeriod } from '../../../calculations/rentRoll'
import { BasisTypes } from '../../../constants/report/incomeApproach/expenses'
import ExpensesCalculations from '../../../report-calculations/income-approach/expenses/expenses-calculations'

const formatBuildingGroupingKey = (unitMixItem, unitGroupingType) => {
  const { groupingKey, avgBathrooms } = unitMixItem

  return unitGroupingType === 'bedroomAndBathroom' ? `${groupingKey}_bathrooms_${avgBathrooms ?? 0}` : groupingKey
}

export const getBuildingRentCompsMinMaxAvg = (
  residentialBuildingComparables,
  unitRentPSFTimePeriod,
  unitGroupingType
) => {
  const avgSqftByGroupKey = {}
  const avgRentByGroupKey = {}
  const avgRentPSFByGroupKey = {}

  residentialBuildingComparables.forEach(buildingComp => {
    buildingComp.unitMixItems.forEach(unitMixItem => {
      const { avgSquareFeet, avgRent } = unitMixItem

      const groupingKey = formatBuildingGroupingKey(unitMixItem, unitGroupingType)
      const avgRentPSF = calculateRentPSFByTimePeriod(avgRent, avgSquareFeet, unitRentPSFTimePeriod)

      if (!avgSqftByGroupKey[groupingKey]) {
        avgSqftByGroupKey[groupingKey] = []
      }
      if (avgSquareFeet !== undefined) {
        avgSqftByGroupKey[groupingKey].push(avgSquareFeet)
      }

      if (!avgRentByGroupKey[groupingKey]) {
        avgRentByGroupKey[groupingKey] = []
      }
      if (avgRent !== undefined) {
        avgRentByGroupKey[groupingKey].push(avgRent)
      }

      if (!avgRentPSFByGroupKey[groupingKey]) {
        avgRentPSFByGroupKey[groupingKey] = []
      }
      if (avgRentPSF !== undefined) {
        avgRentPSFByGroupKey[groupingKey].push(avgRentPSF)
      }
    })
  })

  const buildingRentCompStats = Object.keys(avgRentByGroupKey).map(groupingKey => {
    const avgSqftValues = avgSqftByGroupKey[groupingKey]
    const avgRentValues = avgRentByGroupKey[groupingKey]
    const avgRentPSFValues = avgRentPSFByGroupKey[groupingKey]
    const avgGroupSqft =
      avgSqftValues.length > 0
        ? avgSqftValues.reduce((sum, avgSqft) => sum + avgSqft, 0) / avgSqftValues.length
        : undefined

    const avgGroupRent =
      avgRentValues.length > 0 ? avgRentValues.reduce((sum, rent) => sum + rent, 0) / avgRentValues.length : undefined

    const avgGroupRentPSF =
      avgRentPSFValues.length > 0
        ? avgRentPSFValues.reduce((sum, sqft) => sum + sqft, 0) / avgRentPSFValues.length
        : undefined

    return {
      groupingKey,
      minRentMonth: Math.min(...avgRentValues) || undefined,
      maxRentMonth: Math.max(...avgRentValues) || undefined,
      avgRentMonth: avgGroupRent,
      minRentSF: Math.min(...avgRentPSFValues) || undefined,
      maxRentSF: Math.max(...avgRentPSFValues) || undefined,
      avgRentSF: avgGroupRentPSF,
      avgSqft: avgGroupSqft,
    }
  })
  return buildingRentCompStats
}

export const getTotalForecastedValue = (
  forecastedExpenses,
  squareFootage,
  numberOfUnits,
  numberOfRooms,
  includeRETaxes,
  totalRealEstateTaxes
) => {
  const total = forecastedExpenses.reduce(
    (totalForecastedExpenses, forecastedExpense) => {
      const { concludedValue, basis } = forecastedExpense

      const forecastedTotal = ExpensesCalculations.calculateTotalFromPerBasis(concludedValue, basis, {
        squareFootage,
        numberOfUnits,
        numberOfRooms,
      })

      const perSf = ExpensesCalculations.calculateValuePerBasis(forecastedTotal, BasisTypes.PER_SF, {
        squareFootage,
        numberOfUnits,
        numberOfRooms,
      })
      const perUnit = ExpensesCalculations.calculateValuePerBasis(forecastedTotal, BasisTypes.PER_UNIT, {
        squareFootage,
        numberOfUnits,
        numberOfRooms,
      })

      totalForecastedExpenses.sf += perSf
      totalForecastedExpenses.unit += perUnit
      totalForecastedExpenses.total += forecastedTotal

      return totalForecastedExpenses
    },
    { sf: 0, unit: 0, total: 0 }
  )

  if (includeRETaxes) {
    const perSfTaxes = ExpensesCalculations.calculateValuePerBasis(totalRealEstateTaxes, BasisTypes.PER_SF, {
      squareFootage,
      numberOfUnits,
      numberOfRooms,
    })
    const perUnitTaxes = ExpensesCalculations.calculateValuePerBasis(totalRealEstateTaxes, BasisTypes.PER_UNIT, {
      squareFootage,
      numberOfUnits,
      numberOfRooms,
    })
    total.sf += perSfTaxes
    total.unit += perUnitTaxes
    total.total += totalRealEstateTaxes
  }

  return total
}
