import React, { FC, useEffect, useMemo, useState } from 'react'

import { get, isEmpty, isEqual } from 'lodash'
import { Stack, Button, Typography, Paper } from '@mui/material'
import arrayMutators from 'final-form-arrays'
import { MapDispatchToPropsFunction, MapStateToProps } from 'react-redux'
import { makeStyles } from '@mui/styles'

import { fetchChipOptions } from 'core/api'
import { ExtendedFormApi } from 'client-shared/utils/form'
import { GENERIC_SUBSECTION_PATH } from 'shared/constants/report/keysAndDataPaths'

import wrapForm from '../wrapForm'

import { saveReport } from '../../redux/actions/report'

import styles, { Classes } from './styles'
import CreateGenericSubsection from './createGenericSubsection'
import ViewGenericSubsections from './viewGenericSubsection/ViewGenericSubsections'
import { Subsection } from './types'
import { ChipOptionsContext } from './ChipOptionsContext'

import ViewGenericSubsection from './viewGenericSubsection/ViewGenericSubsection'

// TODO: What's the scrolling behavior here?
const useStyles = makeStyles({
  paper: {
    marginLeft: '-24px',
    marginRight: '-32px',
    minHeight: '100vh',
  },
})

type GenericSubsectionStateProps = {
  formPath: string
  reportId: string
  subsections: Subsection[]
}
type GenericSubsectionDispatchProps = {
  saveReport: (formPath: string) => void
}
type GenericSubsectionOwnProps = {
  classes: Classes
  form: ExtendedFormApi
}
type GenericSubsectionProps = GenericSubsectionStateProps & GenericSubsectionDispatchProps & GenericSubsectionOwnProps

const GenericSubsection: FC<GenericSubsectionProps> = ({
  saveReport,
  subsections,
  classes,
  formPath,
  reportId,
  form,
}) => {
  const localClasses = useStyles()

  const [creatingSubsection, setCreatingSubsection] = useState(false)
  const toggleCreation = () => {
    const newGenericSubsection = {
      name: '',
      title: '',
      location: '',
      data: { atomicUnits: [] },
      action: 'CREATE',
    }
    form.change('newGenericSubsection', newGenericSubsection)
    setCreatingSubsection(!creatingSubsection)
  }

  const saveCreatedSubsection = () => {
    saveReport(formPath)
    toggleCreation()
  }

  const [subsectionToEdit, setSubsectionToEdit] = useState<Subsection | null>(null)

  const toggleEdit = (subsection?: Subsection) => {
    const newGS = subsection
      ? {
          title: subsection.title,
          name: subsection.name,
          location: subsection.location,
          data: subsection.data,
          action: 'UPDATE',
          origName: subsection.name,
        }
      : {
          title: '',
          name: '',
          location: '',
          data: { atomicUnits: [] },
          action: 'CREATE',
        }

    if (!isEqual(newGS, form.values.newGenericSubsection)) {
      form.change('newGenericSubsection', newGS)
    }
    setSubsectionToEdit(subsection || null)
  }

  const [chipOptions, setChipOptions] = useState<any>([])
  useEffect(() => {
    const getChipOptions = async () => {
      const options = await fetchChipOptions(reportId)

      setChipOptions(options)
    }

    getChipOptions()
  }, [reportId])
  const chipOptionsContextValue = useMemo(() => {
    return { chipOptions }
  }, [chipOptions])

  if (creatingSubsection) {
    return (
      <ChipOptionsContext.Provider value={chipOptionsContextValue}>
        <CreateGenericSubsection
          classes={classes}
          form={form}
          onSave={saveCreatedSubsection}
          onCancel={toggleCreation}
          subsections={subsections}
        />
      </ChipOptionsContext.Provider>
    )
  }

  if (subsectionToEdit) {
    return (
      <ChipOptionsContext.Provider value={chipOptionsContextValue}>
        <ViewGenericSubsection
          form={form}
          classes={classes}
          onClose={toggleEdit}
          subsection={subsectionToEdit}
          onSave={() => saveReport(formPath)}
        />
      </ChipOptionsContext.Provider>
    )
  }

  return (
    <Stack>
      <>
        <Stack spacing={2} sx={{ marginBottom: 4 }}>
          <Typography variant="h6">Create New Content</Typography>
          <div className={classes.createButtonSection}>
            <Button variant="contained" onClick={toggleCreation}>
              Create Content
            </Button>
          </div>
        </Stack>
        <Paper square className={localClasses.paper}>
          <Stack spacing={2}>
            <Typography variant="h6">Available Content</Typography>
            {isEmpty(subsections) ? (
              <div className={classes.emptyLoad}>
                <Typography variant="h6">
                  There is no content created
                  <br /> Go to "Create New" to get started
                </Typography>
              </div>
            ) : (
              <ViewGenericSubsections classes={classes} subsections={subsections} editSubsection={toggleEdit} />
            )}
          </Stack>
        </Paper>
      </>
    </Stack>
  )
}

const mapStateToProps: MapStateToProps<GenericSubsectionStateProps, GenericSubsectionOwnProps> = (state, _ownProps) => {
  const { formPath } = get(state, 'shared.location.form', {})
  const reportId = get(state, 'report.reportData._id')
  const subsections = get(state, 'report.reportData.genericSubsection.subsections', [])
  return {
    formPath,
    reportId,
    subsections,
  }
}

const mapDispatchToProps: MapDispatchToPropsFunction<
  GenericSubsectionDispatchProps,
  GenericSubsectionOwnProps
> = dispatch => ({
  saveReport: formPath => dispatch(saveReport(formPath)),
})

export default wrapForm(
  GENERIC_SUBSECTION_PATH,
  {
    styles,
    heading: 'Content Reuse',
    mutators: { ...arrayMutators },
  },
  mapStateToProps,
  mapDispatchToProps
)(GenericSubsection)
