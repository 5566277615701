import React from 'react'
import PropTypes from 'prop-types'
import { get, find, findIndex, isEqual, uniqBy } from 'lodash'
import createDecorator from 'final-form-calculate'
import { connect } from 'react-redux'

import { Grid, IconButton } from '@material-ui/core'
import EditIcon from '@mui/icons-material/Edit'
import { withStyles } from '@material-ui/core/styles'

import BoweryDate from '@bowery-valuation/bowery-date'

import { getFullTaxClassName } from 'shared/report-calculations/income-approach/tax/helpers'

import { CustomAdvancedSelectField } from '../../../../shared/components/Select/CustomAdvancedSelect'
import { TAX_JURISDICTION_NYC } from '../../../../shared/constants/taxRates'
import { generateTaxClassLookupValue } from '../../../../../../shared/helpers/taxes'

import { createOrUpdateTaxClass, fetchTaxClassConfigurations } from '../../../../core/api'

import styles from './styles'
import TaxRateEditModal from './TaxRateEditModal'

const caseInsensitiveLocaleCompare = (stringA, stringB) => {
  const locale = window.navigator.language
  const options = { sensitivity: 'base' }

  return stringA.localeCompare(stringB, locale, options)
}

class TaxRatePicker extends React.PureComponent {
  static propTypes = {
    classFieldName: PropTypes.string,
    rateFieldName: PropTypes.string,
    form: PropTypes.object,
    userId: PropTypes.string.isRequired,
    reportId: PropTypes.string.isRequired,
  }
  static defaultProps = {}

  state = {
    isEditRatesModalOpen: false,
  }

  decoratorDestructors = []

  openEditRatesModal = () => {
    this.setState({ isEditRatesModalOpen: true })
  }
  closeEditRatesModal = () => {
    this.setState({ isEditRatesModalOpen: false })
  }

  refreshTaxClassConfigurationOptions = async () => {
    const { userId, form } = this.props

    const { jurisdictionTaxClasses: reportTaxClasses = [], jurisdictionName } = get(form, 'values', {})

    if (jurisdictionName === TAX_JURISDICTION_NYC.name) {
      return
    }

    const userTaxClasses = await fetchTaxClassConfigurations(userId)
    // We want the "user" tax classes to override the "report" tax classes
    let updatedTaxClasses = uniqBy([...userTaxClasses, ...reportTaxClasses], 'lookupValue').reverse()

    const orderList = taxClasses => {
      const defaultTaxClassName = `${jurisdictionName} Class`
      let defaultTaxClassIndex = findIndex(taxClasses, { name: defaultTaxClassName })

      if (defaultTaxClassIndex === -1) {
        defaultTaxClassIndex = 0
      }

      const defaultTaxClass = taxClasses[defaultTaxClassIndex]

      taxClasses.splice(defaultTaxClassIndex, 1)

      const sortedTaxClasses = taxClasses.sort((taxClassA, taxClassB) => {
        const jurisdictionNameA = taxClassA.jurisdictionName || ''
        const jurisdictionNameB = taxClassB.jurisdictionName || ''

        const jurisdictionNameComparison = caseInsensitiveLocaleCompare(jurisdictionNameA, jurisdictionNameB)

        if (jurisdictionNameComparison === 0) {
          const taxClassNameA = taxClassA.name || ''
          const taxClassNameB = taxClassB.name || ''

          return caseInsensitiveLocaleCompare(taxClassNameA, taxClassNameB)
        }

        return jurisdictionNameComparison
      })

      return [defaultTaxClass, ...sortedTaxClasses]
    }

    updatedTaxClasses = orderList(updatedTaxClasses)

    form.change('jurisdictionTaxClasses', updatedTaxClasses)
  }

  saveRates = async ({ taxRates, taxClassName, assessmentDistrict }) => {
    const { form, classFieldName, userId, reportId } = this.props

    const currentTaxClasses = get(form, 'values.jurisdictionTaxClasses', [])
    const currentTaxClass = get(form, `values.${classFieldName}`)
    const currentTaxClassLookupValue = currentTaxClass.lookupValue

    const { jurisdictionState, jurisdictionName } = form.values

    const nameHasChanged = taxClassName !== currentTaxClass.name

    const updatedTaxClass = {
      lookupValue: currentTaxClassLookupValue,
      rateHistory: taxRates,
      name: taxClassName,
    }

    let taxClassToUpdateIndex = findIndex(currentTaxClasses, { lookupValue: currentTaxClassLookupValue })

    if (nameHasChanged) {
      taxClassToUpdateIndex = currentTaxClasses.length
      updatedTaxClass.lookupValue = generateTaxClassLookupValue(userId, jurisdictionState, taxClassName)
    }

    if (jurisdictionName !== TAX_JURISDICTION_NYC.name) {
      await createOrUpdateTaxClass({
        jurisdictionName,
        jurisdictionState,
        taxClass: updatedTaxClass,
        userId,
        reportId,
      })
    }

    const updatedTaxClasses = [...currentTaxClasses]
    updatedTaxClasses[taxClassToUpdateIndex] = updatedTaxClass

    form.batch(() => {
      form.change('jurisdictionTaxClasses', updatedTaxClasses)
      form.change('assessmentDistrict', assessmentDistrict)
      form.change(classFieldName, updatedTaxClass)
    })

    this.closeEditRatesModal()
  }

  componentDidMount() {
    const { form, classFieldName, rateFieldName } = this.props

    this.refreshTaxClassConfigurationOptions()

    this.decoratorDestructors.push(
      createDecorator({
        field: 'jurisdictionTaxClasses',
        updates: {
          [classFieldName]: (value, allValues) => {
            const selectedTaxClass = get(allValues, classFieldName)
            if (!selectedTaxClass) {
              return value[0]
            }
            return find(value, ['lookupValue', selectedTaxClass.lookupValue])
          },
        },
      })(form)
    )

    this.decoratorDestructors.push(
      createDecorator({
        field: classFieldName,
        updates: {
          [rateFieldName]: (value, allValues) => {
            if (!value) {
              return null
            }
            const selectedTaxRate = get(allValues, rateFieldName)
            if (!selectedTaxRate) {
              return get(value, 'rateHistory[0]', null)
            }

            return find(value.rateHistory, ['_id', selectedTaxRate._id]) || get(value, 'rateHistory[0]', null)
          },
        },
      })(form)
    )
  }

  componentWillUnmount() {
    this.decoratorDestructors.forEach(destructor => destructor())
  }

  render() {
    const { form, classes, classFieldName, rateFieldName } = this.props
    const { isEditRatesModalOpen } = this.state
    const taxClasses = get(form, 'values.jurisdictionTaxClasses', [])

    const taxClass = get(form, `values.${classFieldName}`)
    const assessmentDistrict = get(form, 'values.assessmentDistrict')
    const taxRates = get(form, `values.${classFieldName}.rateHistory`, [])
    const jurisdictionName = get(form, 'values.jurisdictionName')
    const jurisdictionState = get(form, 'values.jurisdictionState')

    return (
      <Grid container>
        <Grid item xs={4} style={{ marginRight: 8 }}>
          <CustomAdvancedSelectField
            className={classes.title}
            name={classFieldName}
            label="Class"
            valueKey="lookupValue"
            items={taxClasses}
            renderItemLabel={item => <span>{getFullTaxClassName(item, jurisdictionName, jurisdictionState)}</span>}
            isEqual={isEqual}
            displayEmpty={false}
          />
        </Grid>
        <Grid item xs={4} style={{ marginRight: 8 }}>
          <CustomAdvancedSelectField
            name={rateFieldName}
            label="Rate"
            valueKey="_id"
            items={taxRates}
            renderItemLabel={item => `${new BoweryDate(item.effectiveDate).year}`}
            isEqual={isEqual}
            displayEmpty={false}
          />
        </Grid>
        <Grid
          item
          xs
          style={{
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <IconButton
            variant="contained"
            size="large"
            color="primary"
            className={classes.button}
            onClick={this.openEditRatesModal}
            data-qa="edit-tax-rates_btn"
          >
            <EditIcon />
          </IconButton>

          {isEditRatesModalOpen && (
            <TaxRateEditModal
              jurisdictionName={jurisdictionName}
              jurisdictionState={jurisdictionState}
              taxClass={taxClass}
              assessmentDistrict={assessmentDistrict}
              onSave={this.saveRates}
              onCancel={this.closeEditRatesModal}
              jurisdictionTaxClasses={taxClasses}
            />
          )}
        </Grid>
      </Grid>
    )
  }
}

const mapStateToProps = state => {
  const authentication = get(state, 'authentication')
  const user = get(authentication, 'user', {})

  return { userId: user.id, reportId: get(state, 'report.reportData._id') }
}

export default connect(mapStateToProps)(withStyles(styles)(TaxRatePicker))
