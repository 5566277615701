import React from 'react'
import { Value } from 'slate'
import { Editor, PluginOrPlugins } from 'slate-react'
import { ICellEditor, ICellEditorParams } from '@bowery-valuation/ui-components'

import { isValueJSON } from 'shared/utils/narrative'
import getChipPlugin from 'client-shared/components/NarrativeComponent/plugins/Locked/ChipPlugin'
import { ChipOption } from 'client-shared/components/NarrativeComponent/plugins/Locked/types'

import { addTextToValue, getTableCellEditor, textToValue } from './helpers'

type TableCellSlateEditorProps = ICellEditorParams<{ chipOptions: ChipOption[] }>

type TableCellSlateEditorState = {
  value: Value
  plugins: PluginOrPlugins<Editor>[]
}

const KEY_CODES = {
  BACKSPACE: 8,
  DELETE: 46,
}

const style = {
  // https://github.com/ianstormtaylor/slate/issues/5110#issuecomment-1235143142
  WebkitUserModify: 'read-write' as const,
}

export class TableCellSlateEditor
  extends React.Component<TableCellSlateEditorProps, TableCellSlateEditorState>
  implements ICellEditor
{
  editorRef = React.createRef<Editor>()

  constructor(props: TableCellSlateEditorProps) {
    super(props)
    const { keyPress, charPress, value } = props

    const plugins = this.getPlugins(props)

    const shouldClearCell =
      (keyPress && [KEY_CODES.BACKSPACE, KEY_CODES.DELETE].includes(keyPress)) || (!value && !charPress)

    if (shouldClearCell) {
      this.state = {
        value: textToValue(''),
        plugins,
      }
    } else if (value instanceof Value) {
      const updatedValue = charPress ? addTextToValue(value, charPress) : value

      this.state = {
        value: updatedValue,
        plugins,
      }
    } else if (isValueJSON(value)) {
      const updatedValue = charPress ? addTextToValue(Value.fromJSON(value), charPress) : Value.fromJSON(value)

      this.state = {
        value: updatedValue,
        plugins,
      }
    } else if (!value && charPress) {
      this.state = {
        value: textToValue(charPress),
        plugins,
      }
    } else {
      console.warn('Unrecognized value type in table cell', props)
      this.state = {
        value: textToValue(''),
        plugins,
      }
    }
  }

  private getPlugins(props: TableCellSlateEditorProps) {
    const { inputProps, eGridCell, stopEditing } = props

    const ChipPlugin = getChipPlugin({
      chipOptions: inputProps?.chipOptions || [],
      onOptionsHidden: () => {
        const activeElementIsInTableCell = eGridCell.contains(document.activeElement)

        if (activeElementIsInTableCell) {
          return
        }

        // Make time for onChange to fire before we stop editing
        setImmediate(() => {
          stopEditing()
        })
      },
      onOptionSelected: () => {
        this.editorRef.current?.insertText(' ')
      },
      targetElement: eGridCell,
    })
    const TableCellEditor = getTableCellEditor({ stopGridEditing: stopEditing })
    return [ChipPlugin, TableCellEditor]
  }

  // https://github.com/facebook/react/issues/7835#issuecomment-395504863
  focus() {
    setImmediate(() => {
      this.editorRef.current?.blur()
      this.editorRef.current?.focus()
      this.editorRef.current?.moveToEndOfDocument()
    })
  }

  getValue() {
    return this.state.value
  }

  onChange: Editor['props']['onChange'] = ({ value }) => {
    this.setState({ value })
  }

  afterGuiAttached() {
    this.focus()
  }

  render() {
    const { value, plugins } = this.state

    return (
      <Editor
        value={value}
        onChange={this.onChange}
        ref={this.editorRef}
        tabIndex={0}
        plugins={plugins}
        style={style}
      />
    )
  }
}
