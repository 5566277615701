import { FREDDIE_MAC_LANGUAGE } from '../constants'

export default ({ reportClients }) => {
  const clients = reportClients
    .filter(client => !!client.client)
    .map(client => {
      return client.client
    })
    .join(', ')

  return [
    {
      value: `${clients || 'UNDEFINED'}, Freddie Mac, and any successors and assigns ("Lender");`,
      subItems: [],
    },
    ...FREDDIE_MAC_LANGUAGE,
  ]
}
