import React from 'react'
import PropTypes from 'prop-types'

import { Collapse, List, ListItem, ListItemText } from '@mui/material'
import { withStyles } from '@mui/styles'
import classnames from 'classnames'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'

import { NAV_SIDE_BAR_MENU_ITEM_HEIGHT } from '../../stylesConstants'

import NavigationSideBarMenuItem from './NavigationSideBarMenuItem'

const styles = theme => ({
  root: {
    boxSizing: 'border-box',
    padding: '5px 0 5px 12px',
    height: NAV_SIDE_BAR_MENU_ITEM_HEIGHT,
    marginBottom: theme.spacing(1),
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: 'rgba(65, 96, 211, 0.08)',
      borderRadius: 4,
    },
  },
  itemText: {
    fontSize: 14,
    fontWeight: 'bold',
    fontFamily: 'Nunito Sans',
    color: theme.palette.text.primary,
  },
  icon: {
    marginRight: 10,
  },
  active: {
    color: theme.palette.primary.main,
  },
})

class NavigationSideBarCollapsibleMenuItem extends React.PureComponent {
  static propTypes = {
    id: PropTypes.string.isRequired,
    isActive: PropTypes.bool,
    title: PropTypes.string.isRequired,
    location: PropTypes.string.isRequired,
    forms: PropTypes.arrayOf(
      PropTypes.shape({
        key: PropTypes.string.isRequired,
        path: PropTypes.string.isRequired,
        title: PropTypes.string.isRequired,
      })
    ),
    hash: PropTypes.string,
    onMenuItemClick: PropTypes.func.isRequired,
  }

  static defaultProps = {
    forms: [],
    isActive: false,
  }

  state = {
    isExpanded: this.props.isActive,
  }

  componentWillReceiveProps(nextProps) {
    if (this.state.isExpanded !== nextProps.isActive) {
      this.setState({ isExpanded: nextProps.isActive })
    }
  }

  handleClick = () => {
    this.setState(state => ({ isExpanded: !state.isExpanded }))
  }

  getMenuItemsComponents = () => {
    const { forms, location, hash, onMenuItemClick } = this.props
    const items = forms.map(({ key, path, id, title, anchor = '' }) => {
      return (
        <NavigationSideBarMenuItem
          id={id}
          key={key}
          path={path}
          isActive={path === location && anchor === hash}
          currentPath={location}
          title={title}
          anchor={anchor}
          onItemClick={() => onMenuItemClick(path, anchor)}
          nested
        />
      )
    })
    return items
  }

  render() {
    const { title, id, classes } = this.props
    const { isExpanded } = this.state
    const menuItems = this.getMenuItemsComponents()
    return (
      <React.Fragment>
        <ListItem
          id={id}
          onClick={this.handleClick}
          className={classnames(classes.root, {
            expanded: isExpanded,
          })}
        >
          <ListItemText
            className={classnames(classes.itemText, {
              [classes.active]: isExpanded,
            })}
            disableTypography
            primary={title}
          />
          <KeyboardArrowDownIcon
            fontSize="small"
            sx={{ transition: 'transform 0.3s', transform: isExpanded ? 'rotate(180deg)' : 'none' }}
          />
        </ListItem>
        <Collapse in={isExpanded} timeout={400} unmountOnExit>
          <List component="div" disablePadding>
            {menuItems}
          </List>
        </Collapse>
      </React.Fragment>
    )
  }
}

export default withStyles(styles, {
  name: 'NavigationSideBarCollapsibleMenuItem',
})(NavigationSideBarCollapsibleMenuItem)
