const getEncodedQuery = queryParams =>
  Object.entries(queryParams)
    .map(keyValuePair => keyValuePair.map(encodeURIComponent).join('='))
    .join('&')

export const getDownloadUrl = ({ downloadFolderUrl, reportId, queryParams }) => {
  const { location, number, userId, extension, generatedAt } = queryParams

  const locationQuery = getEncodedQuery(location)
  const downloadQuery = `${locationQuery}&${getEncodedQuery({ number, extension, userId, generatedAt })}`

  const encodedUrl = `${downloadFolderUrl}/${reportId}/?${downloadQuery}`

  return encodedUrl
}
