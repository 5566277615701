import { FormValues, NarrativeValues } from './types'

const mapDTO = (formValues: FormValues): NarrativeValues => {
  const { valueAsIs, hasVacantUnits, percentageOfMarketRent } = formValues

  return {
    valueAsIs,
    hasVacantUnits,
    hasStabilizedIncomeProjection: valueAsIs && hasVacantUnits,
    percentageOfMarketRent,
  }
}

export default mapDTO
