import React from 'react'

import { Grid } from '@mui/material'

import 'moment-timezone'

import NarrativeComponent from '../../shared/components/NarrativeComponent'

import costApproachMethodology from '../../../../shared/utils/textGeneration/cms/reconciliationFinalValue/costApproachMethodology'
import incomeCapitalizationApproachMethodology from '../../../../shared/utils/textGeneration/cms/reconciliationFinalValue/incomeCapitalizationApproachMethodology'
import salesComparisonApproachMethodology from '../../../../shared/utils/textGeneration/cms/reconciliationFinalValue/salesComparisonApproachMethodology'

import withCMSForm from './withCMSForm'

const GRID_CONTAINER_GUTTER = 2
const ReconciliationFinalValue = () => {
  return (
    <Grid container spacing={GRID_CONTAINER_GUTTER}>
      <Grid item xs={12}>
        <NarrativeComponent
          title="Cost Approach Methodology"
          generatedText={costApproachMethodology.generate}
          name="reconciliationFinalValue.costApproachMethodology"
        />
      </Grid>
      <Grid item xs={12}>
        <NarrativeComponent
          title="Income Approach Methodology"
          generatedText={incomeCapitalizationApproachMethodology.generate}
          name="reconciliationFinalValue.incomeCapitalizationApproachMethodology"
        />
      </Grid>
      <Grid item xs={12}>
        <NarrativeComponent
          title="Sales Comparison Approach Methodology"
          generatedText={salesComparisonApproachMethodology.generate}
          name="reconciliationFinalValue.salesComparisonApproachMethodology"
        />
      </Grid>
    </Grid>
  )
}

export default withCMSForm(ReconciliationFinalValue, 'Reconciliation & Final Value Methodology')
