import { lowerCase, get, isNil } from 'lodash'

import BoweryDate from '@bowery-valuation/bowery-date'

import { DISPLAY_DATE_FORMAT } from '../../../../../client/src/shared/constants'

import { GeneratedText, gtConditional, gtLocked, gtTemplate } from '../../../narrative/GeneratedText'

const generate = () => {
  const gt = new GeneratedText()

  gt.t`The purpose of the appraisal is to provide an opinion of As Is Market Value of the ${gtLocked(
    'interestAppraisedAsIsMarketValue',
    lowerCase,
    '[As Is Interest Appraised]'
  )} as of ${gtLocked('dateOfFinalValueAsIs')}`

  const valueAsCompleteConditional = gtConditional('valueAsComplete')
  valueAsCompleteConditional.children.push(
    ...gtTemplate`, the Prospective Market Value Upon Completion of the ${gtLocked(
      'interestAppraisedAsComplete',
      lowerCase,
      '[As Complete Interest Appraised]'
    )} as of ${gtLocked('dateOfFinalValueAsComplete')}`
  )
  gt.addPart(valueAsCompleteConditional)

  const valueAsStabilizedConditional = gtConditional('valueAsStabilized')
  valueAsStabilizedConditional.children.push(
    ...gtTemplate`, and the Prospective Market Value Upon Stabilization of the ${gtLocked(
      'interestAppraisedAsStabilized',
      lowerCase,
      '[As Stabilized Interest Appraised]'
    )} as of ${gtLocked('dateOfFinalValueAsStabilized')}`
  )
  gt.addPart(valueAsStabilizedConditional)

  gt.t`.`

  return gt.getParts()
}

const mapDataFromReport = report => {
  const dateOfFinalValueAsIs = get(report, 'incomeApproach.dateOfFinalValueAsIs')
  const dateOfFinalValueAsStabilized = get(report, 'incomeApproach.dateOfFinalValueAsStabilized')
  const dateOfFinalValueAsComplete = get(report, 'incomeApproach.dateOfFinalValueAsComplete')
  return {
    valueAsStabilized: get(report, 'valueAsStabilized'),
    valueAsComplete: get(report, 'valueAsComplete'),
    dateOfFinalValueAsIs: !isNil(dateOfFinalValueAsIs)
      ? new BoweryDate(dateOfFinalValueAsIs).formatLongDateWithoutLeadingZero()
      : DISPLAY_DATE_FORMAT,
    dateOfFinalValueAsStabilized: !isNil(dateOfFinalValueAsStabilized)
      ? new BoweryDate(dateOfFinalValueAsStabilized).formatLongDateWithoutLeadingZero()
      : DISPLAY_DATE_FORMAT,
    dateOfFinalValueAsComplete: !isNil(dateOfFinalValueAsComplete)
      ? new BoweryDate(dateOfFinalValueAsComplete).formatLongDateWithoutLeadingZero()
      : DISPLAY_DATE_FORMAT,
    interestAppraisedAsIsMarketValue: get(report, 'info.interestAppraisedAsIsMarketValue'),
    interestAppraisedAsStabilized: get(report, 'info.interestAppraisedAsStabilized'),
    interestAppraisedAsComplete: get(report, 'info.interestAppraisedAsComplete'),
  }
}

const mapDataFromFormValues = (values = {}) => {
  const { dateOfFinalValueAsIs, dateOfFinalValueAsStabilized, dateOfFinalValueAsComplete, ...rest } = values

  return {
    dateOfFinalValueAsIs: !isNil(dateOfFinalValueAsIs)
      ? new BoweryDate(dateOfFinalValueAsIs).formatLongDateWithoutLeadingZero()
      : DISPLAY_DATE_FORMAT,
    dateOfFinalValueAsStabilized: !isNil(dateOfFinalValueAsStabilized)
      ? new BoweryDate(dateOfFinalValueAsStabilized).formatLongDateWithoutLeadingZero()
      : DISPLAY_DATE_FORMAT,
    dateOfFinalValueAsComplete: !isNil(dateOfFinalValueAsComplete)
      ? new BoweryDate(dateOfFinalValueAsComplete).formatLongDateWithoutLeadingZero()
      : DISPLAY_DATE_FORMAT,
    ...rest,
  }
}

export default { generate, mapDataFromReport, mapDataFromFormValues }
