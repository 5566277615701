import { put, takeLatest } from 'redux-saga/effects'

import * as Api from '../../../core/api'
import { SEARCH_PROPERTIES } from '../actionTypes/properties'
import { searchPropertiesRequest, searchPropertiesReceive, searchPropertiesFail } from '../actions/properties'

export function* searchProperties(action) {
  try {
    yield put(searchPropertiesRequest())
    const properties = yield Api.searchProperties(action.payload)
    yield put(searchPropertiesReceive(properties))
  } catch (err) {
    yield put(searchPropertiesFail(err))
  }
}

export default [takeLatest(SEARCH_PROPERTIES.ACTION, searchProperties)]
