import React from 'react'
import PropTypes from 'prop-types'

import NarrativeComponent from '../../../../../../shared/components/NarrativeComponent'
import { DebouncedFormValuesSpy } from '../../../../../../shared/components/helpers'
import stabilizedCommercialIncomeDiscussion from '../../../../../../../../shared/utils/textGeneration/incomeApproach/stabilizedCommercialIncomeDiscussion'

class StabilizedIncomeDiscussion extends React.PureComponent {
  static propTypes = {
    form: PropTypes.object.isRequired,
  }

  render() {
    const { form } = this.props

    return (
      <DebouncedFormValuesSpy
        form={form}
        render={updatedForm => {
          return (
            <NarrativeComponent
              name="stabilizedCommercialIncomeDiscussion"
              title="Stabilized Commercial Income Discussion"
              generatedText={stabilizedCommercialIncomeDiscussion.generate}
              data={stabilizedCommercialIncomeDiscussion.mapDTO(updatedForm.values)}
              tooltipText="The following generated text will appear in the Income Approach section of your report."
            />
          )
        }}
      />
    )
  }
}

export default StabilizedIncomeDiscussion
