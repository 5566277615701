import React from 'react'
import { Typography } from '@mui/material'

import { toLower, get } from 'lodash'

import NumberField, { NumberComponent } from 'client-shared/components/Number/Number'
import { Area, Template } from 'client-shared/components/Template'
import { RadioButtonList } from 'client-shared/components'
import { arrayToKeyValuePairs } from 'client-shared/utils/ui/checkboxHelper'
import { formatCurrencyFloat } from 'client-shared/utils/numberFormatters'
import { getUseLabel, getCapitalizedUseLabel } from 'shared/helpers/commercialUses'
import { toPercents, fromPercents } from 'client-shared/utils/numberOperations'

import { SUITABILITY_OPTIONS } from './PotentialGrossIncomeConstants'

class CommercialLossSection extends React.PureComponent {
  render() {
    const { form, use } = this.props

    const capitalizedUseLabel = getCapitalizedUseLabel(use)

    const vcLossPercentageLabel = `${capitalizedUseLabel} V&C Loss Percentage`
    const vcLossAmountLabel = `Gross ${capitalizedUseLabel} Vacancy and Collection Loss`
    const vacancyRateLabel = `${capitalizedUseLabel} Vacancy Rate in Subject Area`
    const subjectSuitabilityLabel = `Suitability of subject's specific location for ${toLower(getUseLabel(use))} space`
    const grossCommercialLossByType = formatCurrencyFloat(get(form.values, `commercialVCLossAmountByType.${use}`, 0))

    return (
      <Template
        is={`
          'header           header'
          'inputPercentage  inputAmount'
          'areaVacancy      sustainability'
          / 3fr             5fr
        `}
        gap={2}
      >
        <Area is="header">
          <Typography variant="subtitle1">{`${capitalizedUseLabel} vacancy and collection loss`}</Typography>
        </Area>
        <Area is="inputPercentage">
          <NumberField
            adornment="%"
            decimalScale={2}
            fixedDecimalScale
            format={toPercents}
            label={vcLossPercentageLabel}
            name={`commercialVCLossPercentageByType.${use}`}
            normalize={fromPercents}
          />
        </Area>
        <Area is="inputAmount">
          <NumberComponent
            disabled
            label={vcLossAmountLabel}
            name={`commercialVCLossAmountByType.${use}`}
            prefix="$"
            thousandSeparator
            value={grossCommercialLossByType}
          />
        </Area>
        <Area is="areaVacancy">
          <NumberField
            adornment="%"
            decimalScale={2}
            format={toPercents}
            label={vacancyRateLabel}
            name={`subjectAreaCommercialVacancy.${use}`}
            normalize={fromPercents}
          />
        </Area>
        <Area is="sustainability">
          <RadioButtonList
            horizontal
            items={arrayToKeyValuePairs(SUITABILITY_OPTIONS)}
            label={subjectSuitabilityLabel}
            name={`commercialSubjectSuitability.${use}`}
          />
        </Area>
      </Template>
    )
  }
}
export default CommercialLossSection
