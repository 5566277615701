import React, { useMemo } from 'react'
import { Field, useField, useForm } from 'react-final-form'
import { isEqual } from 'lodash'

import { TAXABLE_ASSESSED_VALUE_TOTAL_ROW } from '../../../../../../libs/bowery-libs/constants/taxes'

import {
  CELL_EDITOR_SELECTORS,
  ASSESSED_VALUE_COLUMN_NAMES,
  ASSESSED_VALUE_COLUMNS_ORDER,
  EDITABLE_ASSESSED_VALUE_COLUMNS,
} from './constants'

import { addAssessedValueColumn, updateAssessedValueColumn, removeAssessedValueColumn } from './helpers'

export const useRegisteredFields = (fieldPath, columns, rows) => {
  return [
    <Field name={fieldPath} render={() => null} key="root" />,
    ...rows.flatMap((row, rowIndex) => {
      return columns.map(column => {
        return (
          <Field name={`${fieldPath}[${rowIndex}].${column.name}`} render={() => null} key={row.id + column.name} />
        )
      })
    }),
  ]
}

export const useAssessedValueColumns = () => {
  const assessedValueColumnsField = useField('assessedValueColumns', {
    subscription: { value: true },
    allowNull: true,
    isEqual: isEqual,
  })
  const selectedColumns = assessedValueColumnsField.input.value

  const isCellEditable = editParams => {
    const isInTotalRow = editParams.data.id === TAXABLE_ASSESSED_VALUE_TOTAL_ROW
    const isInEditableColumn = EDITABLE_ASSESSED_VALUE_COLUMNS.includes(editParams.colDef.field)
    return !isInTotalRow && isInEditableColumn
  }

  return Object.values(selectedColumns)
    .filter(column => column.isVisible)
    .sort((left, right) => {
      return ASSESSED_VALUE_COLUMNS_ORDER.indexOf(left.name) - ASSESSED_VALUE_COLUMNS_ORDER.indexOf(right.name)
    })
    .map(column => {
      return {
        ...column,
        suppressMovable: true,
        editable: isCellEditable,
        cellEditorSelector: () => {
          if (column.name === ASSESSED_VALUE_COLUMN_NAMES.actual) {
            return CELL_EDITOR_SELECTORS.moneyTwoDecimals
          }
        },
      }
    })
}

export const useAssessedValueColumnsApi = () => {
  const { getFieldState, change } = useForm()

  return useMemo(() => {
    return {
      addColumn: name => {
        const assessedValueColumns = getFieldState('assessedValueColumns').value
        const newColumns = addAssessedValueColumn(assessedValueColumns, name)

        change('assessedValueColumns', newColumns)
      },
      updateColumn: (name, columnUpdate) => {
        const assessedValueColumns = getFieldState('assessedValueColumns').value
        const newColumns = updateAssessedValueColumn(assessedValueColumns, name, columnUpdate)

        change('assessedValueColumns', newColumns)
      },
      deleteColumn: name => {
        const assessedValueColumns = getFieldState('assessedValueColumns').value
        const newColumns = removeAssessedValueColumn(assessedValueColumns, name)

        change('assessedValueColumns', newColumns)
      },
    }
  }, [getFieldState, change])
}
