import { get } from 'lodash'

import { getUnitOfComparisonText } from 'shared/report-calculations/sales-approach/helpers'
import { SALES_APPROACH_TYPES, UNIT_OF_COMPARISON_TYPES } from 'shared/constants/salesApproach'

export type FormValues = {
  unitOfComparison: string
  type: string
}

type MappedData = {
  unitOfComparisonText: string
  isLandValue: boolean
}

const mapData = (unitOfComparison: string, type: string): MappedData => {
  let unitOfComparisonText = getUnitOfComparisonText(unitOfComparison)
  const isLandValue = type === SALES_APPROACH_TYPES.LAND
  if (isLandValue) {
    unitOfComparisonText = getUnitOfComparisonText(UNIT_OF_COMPARISON_TYPES.APPROVED_BUILDABLE_UNITS)
  }
  return {
    isLandValue,
    unitOfComparisonText,
  }
}

export const mapDTO = (formValues: FormValues) => {
  const unitOfComparison = get(formValues, 'unitOfComparison', 'sf')
  const type = formValues.type

  return mapData(unitOfComparison, type)
}

export const mapDataModel = (report: any, parent: any) => {
  if (!parent.parent()) {
    return undefined
  }

  const salesApproach = parent.parent()
  const { type, unitOfComparison } = salesApproach

  return mapData(unitOfComparison, type)
}
