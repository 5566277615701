import { get } from 'lodash'

import { GEOGRAPHY_OPTIONS } from 'shared/constants/property/geography'
import { getHasSoldWithinThreeYears } from 'shared/helpers/property/salesHistory'
import { getLocationIdentifier } from 'shared/utils/report/propertyInformation'

import { orderSalesHistoryByDate } from '../../../report/propertyHistory'

export const mapDTO = values => {
  const orderedSalesHistory = orderSalesHistoryByDate(values.salesHistory)
  return {
    streetAddress: values.streetAddress,
    locationInfo: values.locationInfo,
    currentOwner: values.currentOwner,
    hasSoldWithinThreeYears: getHasSoldWithinThreeYears(orderedSalesHistory),
    orderedSalesHistory,
  }
}

export const mapDataModel = report => {
  const salesHistory = get(report, 'property_history.sales_history', [])

  const locationIdentifier = getLocationIdentifier(
    get(report, 'property_information.state'),
    get(report, 'property_information.zip')
  )

  const locationInfo = {
    isNYCLocation: locationIdentifier === GEOGRAPHY_OPTIONS.NY,
    isNJLocation: locationIdentifier === GEOGRAPHY_OPTIONS.NJ,
  }

  const orderedSalesHistory = orderSalesHistoryByDate(salesHistory)

  return {
    locationInfo,
    streetAddress: get(report, 'property_information.address'),
    currentOwner: get(report, 'property_information.owner'),
    hasSoldWithinThreeYears: getHasSoldWithinThreeYears(orderedSalesHistory),
    orderedSalesHistory,
  }
}
