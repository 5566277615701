import React from 'react'
import Paper from '@mui/material/Paper'

// TODO: implement this using Container? of MUI5
const CardContainer = ({ children, elevation = 1 }) => {
  return (
    <Paper role="none" elevation={elevation}>
      {children}
    </Paper>
  )
}

export default React.memo(CardContainer)
