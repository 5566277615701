import React from 'react'
import PropTypes from 'prop-types'

import { connect } from 'react-redux'
import { Button, DialogActions, DialogContent, DialogTitle, Grid, Typography } from '@mui/material'
import { get } from 'lodash'

import ReportSettingsFieldsContainer from '../../../../../reportsDashboard/components/CreateReportModal/ReportSettingsFieldsContainer'

const SettingsStep = ({ disableSaveButton, onCancel, onSave, blocksConfiguration }) => {
  return (
    <>
      <DialogTitle>
        <Typography variant="h6">Report Settings</Typography>
      </DialogTitle>
      <DialogContent>
        <Grid container>
          <ReportSettingsFieldsContainer showBlocksToggle={false} blocksConfiguration={blocksConfiguration} />
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button data-qa="cancel-btn" name="cancel-btn" onClick={onCancel}>
          Cancel
        </Button>
        <Button
          data-qa="submit-btn"
          disabled={disableSaveButton}
          name="submit-btn"
          onClick={onSave}
          variant="contained"
        >
          Save
        </Button>
      </DialogActions>
    </>
  )
}

SettingsStep.propTypes = {
  blocksConfiguration: PropTypes.object,
  disableSaveButton: PropTypes.bool,
  onCancel: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
}

SettingsStep.defaultProps = {
  disableSaveButton: false,
}

const mapStateToProps = state => {
  return {
    blocksConfiguration: get(state, 'report.reportSettings.blocksConfiguration'),
    isBlocks: get(state, 'report.reportSettings.isBlocks'),
  }
}
export default connect(mapStateToProps)(SettingsStep)
