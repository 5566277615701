import { isNil, isEmpty, isNumber, isNaN, find, isString, toUpper } from 'lodash'

import BoweryDate from '@bowery-valuation/bowery-date'

export const required = value => {
  const isEmptyValue = isString(value) ? value.trim() === '' : isNil(value)
  return isEmptyValue ? 'Required' : undefined
}

export const notEmpty = value => {
  if (typeof value === 'string') {
    return value.trim() === '' ? 'Required' : undefined
  }
  return isEmpty(value) ? 'Required' : undefined
}

export const hasSelectedItems = customMessage => value => {
  const errorMessage = customMessage || 'Required'
  if (Array.isArray(value)) {
    return value.length === 0 ? errorMessage : undefined
  }
  return undefined
}

export const number = value => {
  if (isNil(value)) {
    return
  }

  return !isNumber(value) || isNaN(value) ? 'Must be a number' : undefined
}

export const integer = value => {
  return value % 1 !== 0 ? 'Must be a whole number' : undefined
}

export const positive = value => {
  const numberValidation = number(value)
  if (numberValidation) {
    return numberValidation
  }

  return value < 0 ? `Must be positive` : undefined
}

export const oneOf =
  (values = []) =>
  value => {
    if (isEmpty(value)) {
      return
    }
    return !values.includes(value) ? 'Invalid value' : undefined
  }

export const uniqueWith = values => value => {
  if (isNil(value)) {
    return
  }
  const existingValue = find(values, item => {
    return toUpper(item) === toUpper(value)
  })
  return existingValue ? 'Already exists' : undefined
}

export const email = value => {
  const re =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  return !re.test(String(value).toLowerCase()) ? `Please enter a valid email address` : undefined
}

export const date = value => {
  const message = 'Must be a date'
  if (!value) {
    return message
  }
  const date = new BoweryDate(value)
  if (isEmpty(value) || !date.isValidDate()) {
    return message
  }
  return undefined
}

export const maxNumber = (max, formatter) => value => {
  if (!isNumber(value)) {
    return value
  }

  const formattedValue = formatter ? formatter(value) : value

  return formattedValue > max ? `Max value is ${max}` : undefined
}

export const minNumber = (min, formatter) => value => {
  if (!isNumber(value)) {
    return value
  }

  const formattedValue = formatter ? formatter(value) : value

  return formattedValue < min ? `Min value is ${min}` : undefined
}
