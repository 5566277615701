const numberStyles = {
  '& div': {
    paddingLeft: 0,
    height: 32,
  },
  '& fieldset': {
    display: 'none',
  },
  height: 32,
  margin: 0,
}

export const numberField = {
  '& input': {
    fontSize: 14,
    padding: '0px !important',
    height: 32,
    textAlign: 'right',
  },
  ...numberStyles,
}

const totalTitleCell = {
  fontSize: 14,
  fontWeight: 500,
  color: '#212121',
}

const netAdjustmentsTitleCell = {
  fontSize: 14,
  color: 'rgba(0,0,0,0.54)',
  fontWeight: 500,
  height: 20,
}

const expandedTableTitle = {
  fontSize: 14,
  color: 'rgba(0,0,0,0.54)',
  fontWeight: 'normal',
  height: 20,
  textAlign: 'left',
}

const LEADING_CELL_LEFT_PADDING = 16

const styles = theme => ({
  paper: {
    ...theme.paper,
  },

  discussions_Container: {
    ...theme.paper,
    marginBottom: 0,
    borderBottomRightRadius: 0,
    borderBottomLeftRadius: 0,
  },

  discussions_Toggle_Btn: {
    float: 'right',
  },

  masterTable: {
    display: 'block',
    emptyCells: 'show',
  },

  paper_masterTable: {
    ...theme.paper,
    padding: 0,
    marginBottom: 16,
  },

  masterTable_Thead: {
    flexBasis: '30%',
    flexGrow: 2,

    backgroundColor: '#f1f1f1',
    position: 'relative',
    display: 'block',
  },

  adjustmentGridTitle: {
    fontStyle: 'normal',
    fontSize: '18px',
    lineHeight: '22px',
    fontWeight: 'bold',
  },

  masterTable_Header_Cell: {
    flexBasis: '30%',
    flexGrow: 2,
    padding: '0 !important',
    fontSize: 14,
    color: '#595959',
    textAlign: 'right',
  },

  subject_name_header_text: {
    margin: '16px 5px 0 0',
  },

  masterTable_Header_Cell_Content_Container: {
    display: 'inline-flex',
    alignContent: 'center',
  },

  masterTable_Header_Cell_Adjustment: {
    padding: 16,
    float: 'left',
  },

  masterTable_Header_Cell_Subject: {
    paddingTop: 16,
    paddingBottom: 16,
    paddingLeft: 8,
    float: 'right',
  },

  masterTable_HeaderCell_Address: {
    display: 'block',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },

  masterTable_HeaderCell_Address_Popper: {
    top: '-16px !important',
  },

  masterTable_Header_Cell_Button: {
    color: '#595959',
    padding: 0,
    backgroundColor: 'transparent !important',
  },

  menu: {
    marginTop: '50px',
  },

  menuButton: {
    color: 'grey',
    padding: '5px 10px 5px 0',
    backgroundColor: 'transparent !important',
  },

  clear_adjustments_modal_title: {
    fontSize: '20px',
    fontFamily: 'Nunito Sans',
    fontWeight: '500',
    lineHeight: '1.6',
    letterSpacing: '0.0075em',
  },

  clear_adjustments_modal_actions: {
    padding: '0px 10px 10px',
    fontFamily: 'Nunito Sans',
  },

  clear_adjument_modal_dialog_text: {
    padding: '0 25px 20px',
    fontFamily: 'Nunito Sans',
  },

  masterTable_Header_LeadCell_Container: {
    padding: '16px 8px 0 16px',
  },

  masterTable_Header_Cell_Container: {
    padding: '0 8px 0 0',
  },

  masterTable_Header_Title_Cell: {
    padding: '14px 16px 16px 16px',
  },

  masterTable_Header_Clear_Cell: {
    padding: '7px 0 14px 0',
    textAlign: 'right !important',
  },

  masterTable_Header_TitleRow: {
    backgroundColor: '#F5F5F5',
    width: '100%',
    display: 'flex',
    height: 50,
  },

  masterTable_Header_Row: {
    borderRadius: '4px 4px 0 0',
    width: '100%',
    display: 'flex',
    padding: 0,
    height: 50,
    backgroundColor: '#F5F5F5',
  },

  masterTable_TableBody: {
    display: 'block',
    position: 'relative',
    width: '100%',
    overflowY: 'scroll',
    maxHeight: 600,
  },

  masterTable_TableBody_Row_ExpansionPanel: {
    width: '100%',
    display: 'inline-table',
  },

  masterTable_TableBody_Row: {
    width: '100%',
    display: 'flex',
    height: 'auto',
    minHeight: 48,
  },

  masterTable_TableBody_TableCell_Label: {
    flexBasis: '30%',
    flexGrow: 2,
    padding: 0,
    minWidth: 250,
    display: 'flex',
    alignItems: 'center',
    border: '1px solid #ebebeb',
    fontWeight: 500,
    fontSize: 14,
    color: '#000000',
  },

  masterTable_TableBody_TableCell_Label_TextContainer: {
    marginLeft: 16,
    fontSize: 14,

    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    minWidth: 234,
    width: 234,
  },

  masterTable_TableBody_Editing: {
    fontSize: 12,
    display: 'inline-flex',
    alignItems: 'center',
    minWidth: 150,

    '& div:first-child': {
      height: 'auto',
    },

    '& svg': {
      fontSize: 18,
    },

    '& input': {
      fontSize: 12,
      fontWeight: 500,
      width: 150,
      padding: 0,
      marginLeft: 16,
    },
    '& fieldset': {
      marginLeft: 8,
    },
  },

  masterTable_TableBody_Editable: {
    padding: '0px 8px 0 16px',
    fontSize: 12,
    display: 'flex',
    alignItems: 'center',
    wordBreak: 'break-word',
    minWidth: 150,
    '& svg': {
      fontSize: 18,
    },
  },

  masterTable_TableBody_TableCell_Container_Label: {
    padding: '16px 8px 0 0',
    fontSize: 14,
  },

  masterTable_TableBody_TableCell: {
    padding: 0,
    flexBasis: '30%',
    flexGrow: 2,

    border: '1px solid #ebebeb',
    textAlign: 'right',

    '&:hover': {
      border: '1px solid #3949ab !important',
    },
    '&:focus-within': {
      border: '2px solid #3949ab !important',
    },
  },

  masterTable_TableBody_TableCell_Error: {
    borderStyle: 'solid',
    borderColor: 'red',
    borderWidth: '1px',
  },

  masterTable_TableBody_TableCell_Disabled: {
    padding: 0,
    flexBasis: '30%',
    flexGrow: 2,
    border: '1px solid #ebebeb',
    textAlign: 'right',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    '& div': {
      margin: '16px 14px',
    },
  },

  masterTable_TableBody_TableCell_Container: {},

  /* take this out */
  numberField,
  totalTitleCell,
  totalsTable: {
    height: 228,
    borderRadius: 4,
    backgroundColor: '#e8eaf6',
  },
  netAdjustmentsCell: {
    ...netAdjustmentsTitleCell,
    width: 220,
    textAlign: 'right',
    paddingRight: 4,
    paddingLeft: 0,
  },

  clear_text_button: {
    textTransform: 'none',
    color: '#2F54EB',
    fontSize: '14px',
    fontFamily: 'Nunito Sans',
  },

  add_adjustment_button: {
    textTransform: 'none',
    color: '#2F54EB',
    fontSize: '14px',
  },

  location: {
    fontSize: 14,
    paddingRight: 14,
  },
  iconButton: {
    color: '#595959',
    fontSize: 18,
    padding: '12px 8px 0px 8px',
  },
  expansionPanel: {
    height: 49,
    backgroundColor: '#ebebeb',
    padding: '0px !important',
  },
  expansionTitleContainer: {
    display: 'inline-flex',
    justifyContent: 'space-between',
    width: '98%',
    alignItems: 'center',
    paddingLeft: LEADING_CELL_LEFT_PADDING,
    height: 49,
  },
  expansionTitle: {
    display: 'inline-flex',
    alignItems: 'center',
  },
  footerRow: {
    height: 30,
  },
  expand: {
    transform: 'rotate(0deg)',
    marginLeft: 'auto',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest,
    }),
  },
  expandOpen: {
    transform: 'rotate(180deg)',
  },
  expandedTableCell: {
    ...expandedTableTitle,
    textAlign: 'right',
  },

  compContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'left',
  },

  photoPopover: {
    height: 256,
    width: 256,
  },

  masterTable_Footer: {
    display: 'block',
    width: '100%',

    backgroundColor: '#e8eaf6',
    padding: '0px 0 8px 0',
  },

  masterTable_Footer_Row: {
    display: 'flex',
    height: 32,
    paddingRight: 18,
  },

  masterTable_Footer_Lead_Cell: {
    border: 0,
    flexBasis: '30%',
    flexGrow: 2,
    padding: 0,
    fontSize: 14,
  },

  masterTable_Footer_Cell_Lead_Content: {
    color: 'rgba(0, 0, 0, 0.54)',
    fontSize: 12,
    fontWeight: 500,
    minWidth: 234,
    padding: 0,
    display: 'flex',
    alignItems: 'center',
    paddingLeft: 16,
  },

  masterTable_Footer_Cell_Total: {
    color: 'black',
  },

  masterTable_Footer_Cell: {
    border: 0,
    fontSize: 14,
    textAlign: 'right',
    width: '100%',
    paddingRight: 8,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
  },

  masterTable_Footer_Cell_Content: {
    color: 'rgba(0, 0, 0, 0.54)',
    fontSize: 14,
    textAlign: 'right',
    width: '100%',
    paddingRight: 8,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
  },

  masterTable_Footer_Cell_ContentTotal: {
    color: 'black',
    fontSize: 14,
    textAlign: 'right',
    width: '100%',
    paddingRight: 8,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
  },

  /*******  expansion table  ************/
  expandedTable: {
    display: 'block',
    emptyCells: 'show',
    marginBottom: 16,
  },

  expansionTable_TableBody: {
    display: 'block',
    width: '100%',
    paddingBottom: 8,
  },

  expansionTable_TableBody_Row: {
    width: '100%',
    display: 'flex',
    height: 32,
  },

  expansionTable_TableCell_Label: {
    border: 0,
    flexBasis: '30%',
    flexGrow: 2,
    padding: 0,
    fontSize: 14,
  },

  expansionTable_TableCell_Label_Content: {
    padding: '12px 8px 0 16px',
    color: 'rgba(0, 0, 0, 0.54)',
    minWidth: 250,
  },
  expansionTable_Header_LeadCell_Container: {
    padding: '16px 16px 0 16px',
  },

  expansionTable_Header_LeadCell_Content: {
    textAlign: 'right',
  },

  expansionTable_TableBody_TableCell_Label_TextContainer: {
    padding: '12px 8px 0 16px',
    color: 'rgba(0, 0, 0, 0.54)',
    fontSize: 14,
    textAlign: 'right',
  },

  expansionTable_actionLink: {
    cursor: 'pointer',
  },
  /**************************************/
  discussion: {
    margin: 0,
  },
})

export default styles
