import { handleActions } from 'redux-actions'

import {
  reportSettingsFetchRequest,
  reportSettingsFetchReceive,
  reportSettingsFetchFail,
  clearReportSettings,
} from './actions'

const initialState = {
  isLoading: false,
  isLoaded: false,
  fetchedSettings: null,
}

export const reducer = handleActions(
  {
    [reportSettingsFetchRequest](state) {
      return {
        ...initialState,
        isLoading: true,
      }
    },
    [reportSettingsFetchReceive](state, { payload }) {
      return {
        ...state,
        fetchedSettings: payload.fetchedSettings,
        isLoading: false,
        isLoaded: true,
      }
    },
    [reportSettingsFetchFail](state) {
      return {
        ...state,
        isLoading: false,
      }
    },
    [clearReportSettings](state) {
      return { ...initialState }
    },
  },
  initialState
)
