import React, { FC, memo } from 'react'
import { makeStyles } from '@mui/styles'

import { Theme } from 'core/theme'

import { HighlightSubstrings } from './helpers'

const useStyles = makeStyles<Theme>(({ palette }) => {
  return {
    matchedText: {
      color: palette.primary.main,
    },
  }
})

type HighlightSearchQueryProps = {
  text: unknown
  searchQuery: string
}

const HighlightSearchQuery: FC<HighlightSearchQueryProps> = ({ text, searchQuery }) => {
  const classes = useStyles()

  if (searchQuery === null || searchQuery === undefined || searchQuery === '' || text === null || text === undefined) {
    return <>{text}</>
  }

  try {
    const stringifiedText = text.toString()

    return (
      <HighlightSubstrings
        text={stringifiedText}
        search={searchQuery}
        renderMatch={match => <span className={classes.matchedText}>{match}</span>}
      />
    )
  } catch (error) {
    console.warn('HighlightSearchQuery error', { error, text, searchQuery })
    return <>{text}</>
  }
}

export default memo(HighlightSearchQuery)
