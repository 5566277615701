export const EMPTY_REGULATION = { name: null, actual: null, required: null, conformingStatus: null }

export const OTHER_GEO_SPECIFIC_REGULATION_NAMES = [
  'Maximum Density',
  'Permitted Units',
  'Minimum Lot Size',
  'Minimum Lot Width',
  'Minimum Front Setback',
  'Minimum Setback (One Side)',
  'Minimum Setback (Both Sides)',
  'Minimum Rear Yard',
  'Maximum Height',
  'Maximum Building Coverage',
]
export const OTHER_GEO_DEFAULT_REGULATION = {
  PERMITTED_UNITS: 'Permitted Units',
  MIN_LOT_SIZE: 'Minimum Lot Size',
}
export const OTHER_GEO_SPECIFIC_REGULATION = OTHER_GEO_SPECIFIC_REGULATION_NAMES.map(name => ({
  ...EMPTY_REGULATION,
  name,
}))

export const NY_FREDDIE_SPECIFIC_REGULATION_NAMES = [
  'Minimum Lot Size',
  'Maximum Density',
  'Permitted Units',
  'Minimum Lot Width/Frontage',
  'Maximum Height Setbacks',
  'Front/Street Setbacks',
  'Side Setbacks',
  'Rear Setbacks',
]
export const NY_FREDDIE_SPECIFIC_REGULATION = NY_FREDDIE_SPECIFIC_REGULATION_NAMES.map(name => ({
  ...EMPTY_REGULATION,
  name,
}))
