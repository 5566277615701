import React from 'react'
import PropTypes from 'prop-types'
import { Grid, Paper } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'

const styles = theme => ({
  popoverContainer: {
    padding: theme.spacing.unit,
    overflowY: 'visible',
  },
  triangleWrapper: {
    width: '100%',
    height: 16,
    position: 'relative',
    overflow: 'hidden',
  },
  triangle: {
    position: 'absolute',
    width: 16,
    height: 16,
    background: 'white',
    transform: 'rotate(45deg)',
    boxShadow: '-1px -1px 10px -2px rgba(0, 0, 0, 0.5)',
    top: 10,
  },
})

const AmenitiesPopoverWrapper = ({ children, left, classes, style, ...props }) => {
  return (
    <Grid
      style={{
        ...style,
        overflow: 'visible',
        border: 0,
        boxShadow: 'none',
        backgroundColor: 'transparent',
      }}
      {...props}
    >
      <div className={classes.triangleWrapper}>
        <div className={classes.triangle} style={{ left }} />
      </div>
      <Paper className={classes.popoverContainer}>{children}</Paper>
    </Grid>
  )
}

AmenitiesPopoverWrapper.propTypes = {
  children: PropTypes.oneOfType([PropTypes.element, PropTypes.array]),
  left: PropTypes.number.isRequired,
  style: PropTypes.object.isRequired,
}

export default withStyles(styles)(AmenitiesPopoverWrapper)
