import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { push } from 'react-router-redux'
import { get, findIndex } from 'lodash'
import { compose } from 'recompose'
import { Grid, Paper, withStyles } from '@material-ui/core'

import * as Api from '../../../../../../etc/buildingComparables/api'
import { errorNotification } from '../../../../../../shared/redux/actions/notifications'
import {
  findProperty,
  getDisplayAddress,
  getPropertyTypeFromProperty,
} from '../../../../../../shared/utils/propertyHelper'
import { Loading } from '../../../../../../shared/components'

import { LOCATION_TYPE_OPTIONS } from '../../../../../../shared/constants/properties'
import { STATE_ABBREVIATIONS } from '../../../../../../../../shared/constants/states'

import BuildingCompPanel from './BuildingComps/BuildingCompPanel'
import PropertySearchMap from './BuildingSearch/PropertySearchMap'
import PropertySearchResultList from './BuildingSearch/PropertySearchResultList'

const styles = theme => ({
  searchResults: {
    overflowY: 'hidden',
    height: 600,
  },
})

class ResidentialBuildingComps extends React.Component {
  static propTypes = {
    form: PropTypes.object.isRequired,
    reportId: PropTypes.string.isRequired,
    errorNotification: PropTypes.func.isRequired,
    goToPage: PropTypes.func.isRequired,
    buildingCompProperties: PropTypes.array,
  }

  static defaultProps = {
    buildingCompProperties: [],
  }

  state = {
    isLoading: false,
  }

  addBuildingCompHandler = async compId => {
    const { form, buildingCompProperties, reportId, errorNotification } = this.props
    const { removedBuildingComps = [] } = form.values
    const compIndex = findIndex(removedBuildingComps, comp => comp._id === compId)
    if (compIndex !== -1) {
      form.batch(() => {
        form.mutators.push('selectedBuildingComps', removedBuildingComps[compIndex])
        form.mutators.remove('removedBuildingComps', compIndex)
      })
    } else {
      try {
        const { selectedBuildingComps } = form.values
        const property = buildingCompProperties.find(prop => prop._id === compId)
        const { item } = findProperty(selectedBuildingComps, property)

        if (property && !item) {
          const newBuildingComp = await Api.addBuildingCompToReport(compId, reportId)
          if (newBuildingComp) {
            const updatedSelectedBuildingComps = [...selectedBuildingComps, newBuildingComp]
            form.change('selectedBuildingComps', updatedSelectedBuildingComps)
          }
        }
      } catch (err) {
        errorNotification({
          message:
            `There was an error while trying to add this property,` +
            ` please contact us quoting property Id '${compId}' for assistance.` +
            ` You can continue working with other properties while this issue is resolved.`,
        })
      }
    }
  }

  getBuildingCompRequestBody = (values, locationType) => {
    const stateAbbreviation = STATE_ABBREVIATIONS[values.state]

    if (locationType === LOCATION_TYPE_OPTIONS.BlockLotBorough) {
      let yearRenovated
      if (values.YearAlter1 && values.YearAlter2) {
        yearRenovated = values.YearAlter2
      } else if (values.YearAlter1) {
        yearRenovated = values.YearAlter1
      }

      const propertyType = getPropertyTypeFromProperty(values)

      return {
        isExisting: values.isExist,
        locationInfo: {
          locationType,
          block: values.Block,
          lot: values.Lot,
          borough: values.Borough,
          zipCode: values.ZipCode,
          streetAddress: values.address,
          state: values.state,
          city: values.city,
          censusTract: values.CT2010,
          fullAddressWithZip: getDisplayAddress({
            address: values.address,
            city: values.city,
            state: stateAbbreviation,
            zip: values.ZipCode,
          }),
        },
        propertyInfo: {
          yearBuilt: values.YearBuilt,
          yearRenovated,
          floors: values.NumFloors,
          residentialUnits: values.UnitsRes,
          grossBuildingArea: values.BldgArea,
          type: propertyType,
        },
      }
    }

    return {
      isExisting: false,
      locationInfo: {
        locationType,
        block: values.block,
        lot: values.lot,
        state: values.state,
        city: values.city,
        zipCode: values.zip,
        streetAddress: values.address,
        fullAddressWithZip: getDisplayAddress({
          address: values.address,
          city: values.city,
          state: stateAbbreviation,
          zip: values.zip,
        }),
      },
      propertyInfo: {
        yearBuilt: values.yearBuilt || 0,
        floors: NaN,
        residentialUnits: parseInt(values.NU),
        grossBuildingArea: values.gba || 0,
      },
    }
  }

  createBuildingComp = async ({ shouldRedirect, locationType, property }) => {
    const { reportId, form } = this.props
    this.setState({ isLoading: true })
    try {
      const buildingComp = { ...this.getBuildingCompRequestBody(property, locationType), reportId }
      const response = await Api.createBuildingComp(reportId, buildingComp)
      const newBuildingComp = get(response, 'data', {})
      const buildingCompId = get(newBuildingComp, 'id')
      form.mutators.push('selectedBuildingComps', newBuildingComp)
      this.setState({ isLoading: false })

      if (shouldRedirect) {
        this.props.history.push(`/report/${reportId}/building-comp/${buildingCompId}/property-summary`)
      }
    } catch (err) {
      this.setState({ isLoading: false })
      console.error(
        `There was an error while trying to add this property,`,
        ` please contact us quoting property Id '${reportId}' for assistance.`,
        ` You can continue working with other properties while this issue is resolved.`
      )
    }
  }

  addImportedBuildingComps = importedComps => {
    const { form } = this.props
    this.setState({ isLoading: true })
    const { selectedBuildingComps } = get(form, 'values')
    form.change('selectedBuildingComps', [...selectedBuildingComps, ...importedComps])
    this.setState({ isLoading: false })
  }

  addRemovedBuildingComps = (id, index) => {
    const { form } = this.props
    const { removedBuildingComps = [] } = form.values
    form.batch(() => {
      form.mutators.push('selectedBuildingComps', removedBuildingComps[index])
      form.mutators.remove('removedBuildingComps', index)
    })
  }

  removeCompFromSelectedTable = (compId, index) => {
    const { form } = this.props
    const { selectedBuildingComps = [] } = form.values
    form.batch(() => {
      form.mutators.push('removedBuildingComps', selectedBuildingComps[index])
      form.mutators.remove('selectedBuildingComps', index)
    })
  }

  removeCompFromMapPanel = compId => {
    const { form } = this.props
    const { selectedBuildingComps = [] } = form.values

    const index = findIndex(selectedBuildingComps, comp => comp._id === compId)
    form.batch(() => {
      form.mutators.push('removedBuildingComps', selectedBuildingComps[index])
      form.mutators.remove('selectedBuildingComps', index)
    })
  }

  removeRemovedBuildingComps = async (compId, index) => {
    const { form, reportId } = this.props
    try {
      await Api.removeBuildingCompFromReport([compId], reportId)
      form.mutators.remove('removedBuildingComps', index)
    } catch (err) {
      console.error(
        `There was an error while trying to delete this property,`,
        ` please contact us quoting property Id '${compId}' for assistance.`,
        ` You can continue working with other properties while this issue is resolved.`
      )
    }
  }

  clearAllRemovedBuildingComps = async () => {
    const { form, reportId } = this.props
    const { removedBuildingComps = [] } = form.values
    try {
      await Api.removeBuildingCompFromReport(
        removedBuildingComps.map(comp => comp.id),
        reportId
      )
      form.change('removedBuildingComps', [])
    } catch (err) {
      console.error(
        `There was an error while trying to delete all properties,`,
        ` please contact us for assistance.`,
        ` You can continue working with other properties while this issue is resolved.`
      )
    }
  }

  render() {
    const { form, classes, reportId } = this.props
    const { isLoading } = this.state
    return (
      <Grid container wrap="nowrap" direction="column" spacing={8}>
        {isLoading && <Loading />}
        <Grid item xs={12}>
          <Grid component={Paper} className={classes.searchContainer} container spacing={0}>
            <Grid item xs={5}>
              <PropertySearchMap
                onAddProperty={this.addBuildingCompHandler}
                onRemoveProperty={this.removeCompFromMapPanel}
                form={form}
              />
            </Grid>
            <Grid className={classes.searchResults} item xs={7}>
              <PropertySearchResultList
                addComp={this.addBuildingCompHandler}
                removeComp={this.removeCompFromMapPanel}
                form={form}
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <BuildingCompPanel
            removeComp={this.removeCompFromSelectedTable}
            addRemovedBuildingComps={this.addRemovedBuildingComps}
            removeRemovedBuildingComps={this.removeRemovedBuildingComps}
            clearAllRemoved={this.clearAllRemovedBuildingComps}
            createBuildingComp={this.createBuildingComp}
            form={form}
            addImportedBuildingComps={this.addImportedBuildingComps}
            reportId={reportId}
          />
        </Grid>
      </Grid>
    )
  }
}

const mapStateToProps = state => {
  const reportId = get(state, 'report.reportData._id')
  const buildingCompProperties = get(state, 'buildingComp.properties') || []
  return {
    reportId,
    buildingCompProperties,
  }
}

export default compose(
  connect(mapStateToProps, dispatch => ({
    errorNotification: ({ message }) => dispatch(errorNotification({ message })),
    goToPage: pageUrl => dispatch(push(pageUrl)),
  })),
  withStyles(styles)
)(ResidentialBuildingComps)
