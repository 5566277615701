import { get } from 'lodash'

import { formatPercentageString } from 'shared/utils/formatters/numberFormatters'

const mapData = (
  asIsEntrepreneurialIncentive,
  asCompleteEntrepreneurialIncentive,
  asStabilizedEntrepreneurialIncentive
) => {
  return {
    asIsEntrepreneurialIncentive: formatPercentageString(asIsEntrepreneurialIncentive),
    asCompleteEntrepreneurialIncentive: formatPercentageString(asCompleteEntrepreneurialIncentive),
    asStabilizedEntrepreneurialIncentive: formatPercentageString(asStabilizedEntrepreneurialIncentive),
  }
}

// eslint-disable-next-line no-unused-vars
export const mapDTO = formValues => {
  const asIsEntrepreneurialIncentive = get(formValues, 'conclusion.asIsEntrepreneurialIncentive')
  const asCompleteEntrepreneurialIncentive = get(formValues, 'conclusion.asCompleteEntrepreneurialIncentive')
  const asStabilizedEntrepreneurialIncentive = get(formValues, 'conclusion.asStabilizedEntrepreneurialIncentive')

  return mapData(asIsEntrepreneurialIncentive, asCompleteEntrepreneurialIncentive, asStabilizedEntrepreneurialIncentive)
}

export const mapDataModel = report => {
  const asIsEntrepreneurialIncentive = get(report, 'costApproach.conclusion.asIsEntrepreneurialIncentive')
  const asCompleteEntrepreneurialIncentive = get(report, 'costApproach.conclusion.asCompleteEntrepreneurialIncentive')
  const asStabilizedEntrepreneurialIncentive = get(
    report,
    'costApproach.conclusion.asStabilizedEntrepreneurialIncentive'
  )

  return mapData(asIsEntrepreneurialIncentive, asCompleteEntrepreneurialIncentive, asStabilizedEntrepreneurialIncentive)
}
