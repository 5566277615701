import { get, lowerCase, upperFirst, compact } from 'lodash'

import { OTHER_TYPE, OUTSIDE_LOCATION, ROOF_LOCATION, UNIT_LOCATION } from '../../../../constants/report/utilities'

const normalizeLocation = (location, other) => {
  if (other) {
    return location || '[LOCATION]'
  }
  if (location === ROOF_LOCATION) {
    return 'on the roof'
  }
  if (location === UNIT_LOCATION) {
    return 'in the unit'
  }
  if (location === OUTSIDE_LOCATION) {
    return 'outside'
  }

  return `in the ${lowerCase(location) || '[LOCATION]'}`
}

export const generateSystemLocationSentence = ({ type = '', location, otherType, otherLocation }) => {
  const systemType = type === OTHER_TYPE ? otherType : upperFirst(type)
  const systemLocation = location === OTHER_TYPE ? otherLocation : location
  return `${systemType || '[SYSTEM TYPE]'} located ${normalizeLocation(systemLocation, !!otherLocation)}`
}

export const generateSystemsParagraph = ({ notInspected = false, systems, type, addition }) => {
  if (notInspected) {
    return `There is no ${type} system.`
  }

  if (systems.length === 1 && !systems[0].type && !systems[0].location) {
    return `There is a ${type} system.`
  }

  const systemsSentences = systems.map(
    system => `${generateSystemLocationSentence(system)}${addition ? ` for ${type}` : ''}.`
  )

  return compact(systemsSentences).join(' ')
}

export const generateHotWaterSystemsDiscussion = ({ hotWaterSystems }) => {
  const { systems, inspected } = hotWaterSystems

  return generateSystemsParagraph({ notInspected: !inspected, systems, type: 'hot water' })
}

export const mapDTO = formValues => {
  const { hotWaterSystems } = formValues
  const hotWaterSystemsSentence = generateHotWaterSystemsDiscussion({ hotWaterSystems })
  return {
    hotWaterSystemsSentence,
  }
}

export const mapDataModel = report => {
  const hotWaterSystems = get(report, 'buildingDescription.utilities.hotWaterSystems', {})
  const hotWaterSystemsSentence = generateHotWaterSystemsDiscussion({ hotWaterSystems })
  return {
    hotWaterSystemsSentence,
  }
}
