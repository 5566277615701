import React from 'react'

import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material'
import LoadingButton from '@mui/lab/LoadingButton'

type ConfirmationDialogProps = {
  open: boolean
  isAutomationInProgress: boolean
  onCancel: () => void
  onSubmit: () => void
}

const ConfirmationDialog: React.FC<ConfirmationDialogProps> = ({
  open,
  onCancel,
  onSubmit,
  isAutomationInProgress,
}) => {
  const title = isAutomationInProgress ? 'Automation in Progress' : 'Automation Warning'
  const text = isAutomationInProgress
    ? "Automation may take a few minutes. Do not close this window or refresh the page. You'll be notified when the process is finished."
    : 'The automation process will overwrite any changes you have made to this page. Are you sure you want to continue?'

  return (
    <Dialog open={open} data-qa="run-automation-warning-dialog">
      <DialogTitle sx={{ padding: '24px' }}>{title}</DialogTitle>
      <DialogContent sx={{ padding: '0 24px 24px' }}>
        <DialogContentText>{text}</DialogContentText>
      </DialogContent>
      <DialogActions sx={{ padding: '0 24px 24px' }}>
        <Button onClick={onCancel} disabled={isAutomationInProgress} data-qa="cancel-run-automation-btn">
          Cancel and Manually Input
        </Button>
        <LoadingButton
          onClick={onSubmit}
          variant="contained"
          loading={isAutomationInProgress}
          data-qa="confirm-run-automation-btn"
        >
          Accept and Automate
        </LoadingButton>
      </DialogActions>
    </Dialog>
  )
}

export default ConfirmationDialog
