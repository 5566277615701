import React from 'react'
import PropTypes from 'prop-types'

import CloudUpload from '@mui/icons-material/CloudUpload'
import Button from '@mui/material/Button'
import { withStyles } from '@mui/styles'

const styles = theme => ({
  root: {
    display: 'inline-block',
  },
  input: {
    display: 'none',
  },
})

class FileInput extends React.PureComponent {
  static propTypes = {
    classes: PropTypes.object.isRequired,
  }

  constructor(props) {
    super(props)
    this.fileInputRef = React.createRef()
  }

  componentDidUpdate() {
    if (!this.props.files.length) {
      this.fileInputRef.current.value = ''
    }
  }

  onChooseFile = () => {
    this.fileInputRef.current.click()
  }

  onChangeFiles = () => {
    this.props.onChange(this.fileInputRef.current.files)
  }

  getInputText = () => {
    const { files } = this.props

    switch (files.length) {
      case 0:
        return ''
      case 1:
        return files[0].name
      default:
        return `${files.length} files`
    }
  }

  render() {
    const { classes, title, files, onChange, buttonProps, ...other } = this.props

    return (
      <div className={classes.root}>
        <input
          type="file"
          className={classes.input}
          ref={this.fileInputRef}
          files={files}
          onChange={this.onChangeFiles}
          {...other}
        />
        <Button
          variant="contained"
          onClick={this.onChooseFile}
          sx={{
            borderRadius: 30,
            paddingLeft: 15,
            paddingRight: 15,
            gap: 1,
          }}
        >
          <CloudUpload />
          {title}
        </Button>
      </div>
    )
  }
}

const FileUploadButton = withStyles(styles)(FileInput)

FileUploadButton.displayName = 'FileUploadButton'

FileUploadButton.propTypes = {
  title: PropTypes.string.isRequired,
  files: PropTypes.arrayOf(PropTypes.objectOf(File)).isRequired,
  onChange: PropTypes.func.isRequired,
  buttonProps: PropTypes.object,
}

export default FileUploadButton
