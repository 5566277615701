import ExpensesCalculations from '../../../../../../shared/report-calculations/income-approach/expenses/expenses-calculations.ts'

export const getManagementConcludedValue = (egi, basis, percentOfEgi, numberOfResidentialUnits, squareFootage) => {
  const total = ExpensesCalculations.calculateManagementFees(egi, percentOfEgi)

  return ExpensesCalculations.calculateManagementFeesPerBasis(total, basis, {
    numberOfUnits: numberOfResidentialUnits,
    squareFootage,
  })
}
