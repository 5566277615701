import { get } from 'lodash'

import BoweryDate from '@bowery-valuation/bowery-date'

import { DATE_FORMAT } from '../../../../constants/acas'

export const mapDTO = values => {
  const contractDetails = values.contractDetails
  const assignedContractDetails = values.assignedContractDetails
  return {
    currentOwner: values.currentOwner,
    isUnderContract: values.isUnderContract,
    isAssignedContract: values.isAssignedContract,
    contractDetails,
    contractDetailsDate: contractDetails ? new BoweryDate(contractDetails.date).format(DATE_FORMAT) : '',
    assignedContractDetails,
    assignedContractDetailsDate: assignedContractDetails
      ? new BoweryDate(assignedContractDetails.date).format(DATE_FORMAT)
      : '',
  }
}

export const mapDataModel = report => {
  const contractDetails = get(report, 'property_history.contract_details', {})
  const assignedContractDetails = get(report, 'property_history.assigned_contract_details', {})
  return {
    currentOwner: get(report, 'property_information.owner'),
    isUnderContract: get(report, 'property_history.under_contract', false),
    isAssignedContract: get(report, 'property_history.is_assigned_contract'),
    contractDetails,
    contractDetailsDate: contractDetails ? new BoweryDate(contractDetails.date).format(DATE_FORMAT) : '',
    assignedContractDetails,
    assignedContractDetailsDate: assignedContractDetails
      ? new BoweryDate(assignedContractDetails.date).format(DATE_FORMAT)
      : '',
  }
}
