import currency from '../../../formatters/currencyFormatter'

const ANNUAL_MULTIPLIER = 12

const getStorageConcludingUnitsSentence = ({ isForecastType, isAnnual, storageIncome }) => {
  const units = isAnnual ? 'annually' : 'per unit per month'

  const forecastingChunk = 'As such, based on comparable assets in the marketplace, we have forecasted'
  const ownerReportingChunk = 'The owner reported'

  return (
    `${isForecastType ? forecastingChunk : ownerReportingChunk} ` +
    `storage income of ${currency.format(storageIncome)} ${units}.`
  )
}

const getStorageEquatingSentence = ({ isAnnual, storageIncome, unitCount }) => {
  const value = isAnnual ? storageIncome / unitCount / ANNUAL_MULTIPLIER : storageIncome * unitCount * ANNUAL_MULTIPLIER
  const units = isAnnual ? 'per unit per month' : 'annually'

  return `This income equates to ${currency.format(value)} ${units}.`
}

export const generateStorageIncomeDiscussion = ({ unitCount, isForecastType, isAnnual, storageIncome }) => {
  const paragraph = [
    `The subject property has ${unitCount}` +
      ` storage unit${unitCount === 1 ? '' : 's'} available for lease for the tenants.`,
  ]

  if (isForecastType) {
    paragraph.push('We were not supplied with storage income information')
  }

  const storageConcludingUnitsSentence = getStorageConcludingUnitsSentence({
    isForecastType,
    isAnnual,
    storageIncome,
  })
  const storageEquatingSentence = getStorageEquatingSentence({ isAnnual, storageIncome, unitCount })

  paragraph.push(storageConcludingUnitsSentence)
  paragraph.push(storageEquatingSentence)

  return paragraph.join(' ')
}
