import { find, get, isEqual } from 'lodash'
import arrayMutators from 'final-form-arrays'
import createDecorator from 'final-form-calculate'

import { EXPORT_IN_VALUES } from 'shared/constants/salesApproach'
import { SALES_COMPS_SEARCH_KEY } from 'shared/constants/report/keysAndDataPaths'

import { updateAppraisal } from '../../../../shared/utils/compPlex.gql'
import { STATE_NAMES_SHORT } from '../../../../shared/constants/geography'

import wrapForm from '../../wrapForm'

import { DATA_PATH as LEGACY_BASE_DATA_PATH } from '..'

import { HEADING_NAME, SALES_COMPS_SORT_VALUES } from './constants'
import SalesCompsSearchContainer from './SalesCompsSearchContainer'
import { getSortedComps } from './tools'

import { getSubjectProperty } from './helpers'

const salesCompsDecorator = createDecorator({
  field: 'selectedComps',
  updates: {
    selectedComps: (value, allValues) => {
      const { sortBy } = allValues
      const sorted = getSortedComps(value, sortBy)

      if (!isEqual(value, sorted)) {
        return sorted
      }

      return value
    },
  },
})

const sortSalesCompsDecorator = createDecorator({
  field: 'sortBy',
  updates: {
    selectedComps: (value, allValues) => {
      const { selectedComps } = allValues
      return getSortedComps(selectedComps, value)
    },
  },
})

export const createSalesCompsSearch = baseDataPath => {
  const DATA_PATH = [...baseDataPath, SALES_COMPS_SEARCH_KEY]
  return wrapForm(
    DATA_PATH,
    {
      heading: HEADING_NAME,
      registeredFields: ['removedComps'],
      keepDirtyOnReinitialize: true,
      mutators: {
        ...arrayMutators,
      },
      decorators: [salesCompsDecorator, sortSalesCompsDecorator],
      onPreSave: async (formValues, formApi, reportId, authenticatedUser, reportData) => {
        const jobNumber = reportData.report.reportInformation.reportNumber
        const usedSalesTransactionIds = formValues.selectedComps
          .filter(comp => comp.salesTransactionId)
          .map(comp => comp.salesTransactionId)

        await updateAppraisal({
          reportNumber: reportId,
          jobNumber,
          salesTransactionIds: usedSalesTransactionIds,
        })

        return formValues
      },
    },
    state => {
      const { filters, sortBy, exportLocation } = get(state, 'report.reportData.salesApproach.salesCompsSearch')

      const polygon = get(state, 'salesComps.polygon')

      const propertyInformation = get(state, 'report.reportData.propertyInformation')
      const salesCompsSearchResults = get(state, 'salesComps.searchResults', [])
      const isCustomSelection = get(state, 'salesComps.isCustomSelection', false)
      const isLoading = get(state, 'salesComps.isLoading')

      const capRateConclusionValues = get(state, 'report.reportData.incomeApproach.capRateConclusion')
      const netOperatingIncome = get(capRateConclusionValues, 'netOperatingIncome', 0)
      const grossBuildingArea = get(capRateConclusionValues, 'grossBuildingArea', 0)
      const unitCount = get(capRateConclusionValues, 'unitCount', 0)

      const subjectCoordinates = {
        lat: get(propertyInformation, 'coords.latitude'),
        lng: get(propertyInformation, 'coords.longitude'),
      }

      const subjectProperty = getSubjectProperty(state)

      const appraisers = get(state, 'report.reportData.report.appraisers.selectedAppraisers', [])
      const leadAppraiser = find(appraisers, 'isLeadAppraiser', {})
      const defaultSalesOutlineExportLocation =
        leadAppraiser?.boweryOffice === STATE_NAMES_SHORT.DC ? EXPORT_IN_VALUES.REPORT_BODY : EXPORT_IN_VALUES.ADDENDA

      return {
        propertyInformation,
        salesCompsSearchResults,
        isCustomSelection,
        isLoading,
        filters,
        initialFilters: filters,
        initialValues: {
          newSalesComp: { includeInReport: true },
          sortBy: sortBy || SALES_COMPS_SORT_VALUES.DATE_SOLD,
          exportLocation: exportLocation || defaultSalesOutlineExportLocation,
          ...get(state, `report.reportData.${DATA_PATH.join('.')}`),
        },
        capRateConclusionValues,
        netOperatingIncome,
        grossBuildingArea,
        unitCount,
        subjectProperty,
        subjectCoordinates,
        polygon,
      }
    },
    dispatch => {},
    true
  )(SalesCompsSearchContainer)
}

export default createSalesCompsSearch(LEGACY_BASE_DATA_PATH)
