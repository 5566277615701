import React from 'react'
import PropTypes from 'prop-types'

import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Typography } from '@mui/material'

const WarningDialog = ({
  open,
  title,
  text,
  onSubmit,
  onCancel,
  submitButtonLabel = 'CLEAR',
  cancelButtonLabel = 'CANCEL',
}) => (
  <Dialog aria-labelledby="dialog-title" open={open}>
    <DialogTitle>
      <Typography variant="h6">{title}</Typography>
    </DialogTitle>
    <DialogContent data-qa="dialog-content">
      <DialogContentText>{text}</DialogContentText>
    </DialogContent>
    <DialogActions>
      <Button onClick={onCancel} data-qa="reject-btn">
        {cancelButtonLabel}
      </Button>
      <Button onClick={onSubmit} variant="contained" data-qa="submit-btn" color="error">
        {submitButtonLabel}
      </Button>
    </DialogActions>
  </Dialog>
)

WarningDialog.propTypes = {
  onCancel: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
  open: PropTypes.bool.isRequired,
  submitButtonLabel: PropTypes.string,
  cancelButtonLabel: PropTypes.string,
}

export default WarningDialog
