import React from 'react'
import PropTypes from 'prop-types'
import { get } from 'lodash'
import { connect } from 'react-redux'

import { getPriceDataForComps } from '../calculations'

import SalesCompsTableSummary from './SalesCompsTableSummary'

class SalesCompsTableSummaryContainer extends React.PureComponent {
  render() {
    const { salesComps, compAdjustments, netOperatingIncome, temper, unitOfComparison, incomeAdjustmentLevel } =
      this.props

    const adjustedCompSuperlatives = getPriceDataForComps(
      salesComps,
      true,
      unitOfComparison,
      temper,
      get(netOperatingIncome, 'perUnit', 0),
      get(netOperatingIncome, 'psf', 0),
      incomeAdjustmentLevel,
      compAdjustments
    )

    const unadjustedCompSuperlatives = getPriceDataForComps(
      salesComps,
      false,
      unitOfComparison,
      temper,
      get(netOperatingIncome, 'perUnit', 0),
      get(netOperatingIncome, 'psf', 0),
      incomeAdjustmentLevel
    )

    return (
      <SalesCompsTableSummary
        adjustedCompSuperlatives={adjustedCompSuperlatives}
        unadjustedCompSuperlatives={unadjustedCompSuperlatives}
      />
    )
  }
}

SalesCompsTableSummaryContainer.propTypes = {
  netOperatingIncome: PropTypes.object.isRequired,
  unitOfComparison: PropTypes.string,
  salesComps: PropTypes.array,
  temper: PropTypes.number,
  salesApproachType: PropTypes.string,
}

const mapStateToProps = (state, ownProps) => {
  const { salesApproachType } = ownProps

  const saleValueConclusion = get(state, `report.reportData.salesApproach.${salesApproachType}.saleValueConclusion`, {})
  const { temper, salesComps, unitOfComparison, netOperatingIncome } = saleValueConclusion

  return {
    temper,
    unitOfComparison,
    salesComps,
    netOperatingIncome,
  }
}

export default connect(mapStateToProps, null)(SalesCompsTableSummaryContainer)
