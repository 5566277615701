import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { cloneDeep, get } from 'lodash'
import { Value } from 'slate'
import { Editor } from 'slate-react'

import { getNarrativeByTemplateName } from 'core/api'
import { removeLockedInlineValues, updateNarrative } from 'shared/utils/narrative/updateNarrative'
import { NARRATIVE_COMPONENT } from 'shared/constants/contentReuse'
import {
  setContentLibrary,
  selectNarrativeTemplate,
  createNarrativeTemplate,
  updateNarrativeTemplate,
} from 'client-shared/redux/actions/contentLibraryData'
import { contentReuseTemplate } from 'client-shared/types'
import { ExtendedFormApi } from 'client-shared/utils/form'
import { changeDrawerSection } from 'client-shared/redux/actions/drawer'
import { sectionNameSelector } from 'report/layout/selectors'
import { errorNotification, successNotification } from 'client-shared/redux/actions/notifications'
import { createNarrativeTemplatePayload } from 'client-shared/redux/types/contentLibraryData'
import { useDispatch, useSelector } from 'client-shared/hooks/redux'
import useBoolean from 'client-shared/hooks/useBoolean'
import usePrevious from 'client-shared/hooks/usePrevious'
import { DrawerSections } from 'report/layout/drawer/constants'
import LogoIcon from 'report/images/cl-default_avatar.svg'

import { CreateNarrativeModal } from './CreateNarrativeModal'
import { OverwriteNarrativeModal } from './OverwriteNarrativeModal'
import { UpdateNarrativeModal } from './UpdateNarrativeModal'

export const useContentLibrary = ({
  contentLibraryTemplateTitle: providedContentLibraryTemplateTitle,
  name,
  form,
  editor,
  chipData,
  getGeneratedTextValue,
  modified,
  onChange,
  setInitialNarrative,
}: {
  contentLibraryTemplateTitle?: string
  name: string
  form: ExtendedFormApi
  editor: Editor | null
  chipData: Record<string, any>
  getGeneratedTextValue: () => Value
  modified: boolean
  onChange: (value: { value: Value }) => void
  setInitialNarrative: (value: Value) => void
}) => {
  const [createNarrativeModalIsOpened, onOpenCreateNarrativeTemplate, onCloseCreateNarrativeTemplate] =
    useBoolean(false)
  const [overwriteModalIsOpened, openOverwriteModal, closeOverwriteModal] = useBoolean(false)
  const [updateNarrativeModalIsOpened, updateTemplate, closeUpdateNarrativeModal] = useBoolean(false)
  const [activeTemplate, setActiveTemplate] = useState<contentReuseTemplate | null>(null)
  const dispatch = useDispatch()
  const selectedTemplate = useSelector<contentReuseTemplate>(state =>
    get(state, 'shared.contentLibraryData.selectedTemplate')
  )
  const templateNameFormPath = `${name}.templateName`
  const templateName = get(form.values, templateNameFormPath)

  const drawerState = useSelector(sectionNameSelector)
  const templates = useSelector<contentReuseTemplate[]>(state => get(state, 'shared.contentLibraryData.templates'))

  const username = useSelector<string>(state => get(state, 'authentication.user.username', ''))
  const previousSelectedTemplate = usePrevious(selectedTemplate)

  const updateIsDisabled = useMemo(() => {
    if (!modified) {
      return true
    }

    const authoredByCurrentUser = templateName?.includes(username)

    return !authoredByCurrentUser
  }, [modified, templateName, username])

  const contentLibraryTemplateTitle = useMemo(() => {
    return providedContentLibraryTemplateTitle || name
  }, [providedContentLibraryTemplateTitle, name])

  const resetSelectedTemplate = useCallback(() => {
    dispatch(selectNarrativeTemplate(null))
  }, [dispatch])

  useEffect(() => {
    // if user changes the active template, we need to update the templateName
    if (activeTemplate && templateName !== activeTemplate.name) {
      form.change(templateNameFormPath, activeTemplate.name)
    }
  }, [activeTemplate, form, templateName, templateNameFormPath])

  const changeNarrative = useCallback(() => {
    form.change(templateNameFormPath, selectedTemplate?.name)
    setActiveTemplate(selectedTemplate)
    const updatedNarrative = updateNarrative(selectedTemplate.data, chipData)
    const updatedValue = Value.fromJSON(updatedNarrative)
    setInitialNarrative(updatedValue)
    onChange({ value: updatedValue })
    resetSelectedTemplate()
    dispatch(changeDrawerSection(undefined))
  }, [
    chipData,
    dispatch,
    form,
    onChange,
    resetSelectedTemplate,
    selectedTemplate,
    setInitialNarrative,
    templateNameFormPath,
  ])

  useEffect(() => {
    if (!editor) {
      return
    }

    const shouldReplaceNarrativeTemplate =
      previousSelectedTemplate !== selectedTemplate &&
      selectedTemplate &&
      selectedTemplate.metadata.form === contentLibraryTemplateTitle

    if (!shouldReplaceNarrativeTemplate) {
      return
    }

    if (!modified) {
      changeNarrative()
    } else {
      openOverwriteModal()
    }
  }, [
    changeNarrative,
    contentLibraryTemplateTitle,
    editor,
    modified,
    openOverwriteModal,
    previousSelectedTemplate,
    selectedTemplate,
  ])

  const onCreateNarrative = useCallback(
    (title: string) => {
      const narrativeValue = editor?.value
      if (!narrativeValue) {
        return
      }

      const uniqueTemplateName = `${username}-${Date.now()}`
      const narrativeWithoutChipValues = removeLockedInlineValues(narrativeValue)
      const content: createNarrativeTemplatePayload = {
        name: uniqueTemplateName,
        title: title,
        data: narrativeWithoutChipValues,
        metadata: {
          type: NARRATIVE_COMPONENT,
          form: contentLibraryTemplateTitle,
        },
      }

      dispatch(createNarrativeTemplate(content))
      setInitialNarrative(Value.fromJSON(content.data))
      dispatch(successNotification({ message: 'Narrative template is added to the Content Library' }))
      form.change(templateNameFormPath, content.name)
      onCloseCreateNarrativeTemplate()
    },
    [
      contentLibraryTemplateTitle,
      dispatch,
      editor?.value,
      form,
      onCloseCreateNarrativeTemplate,
      setInitialNarrative,
      templateNameFormPath,
      username,
    ]
  )

  const confirmUpdateNarrative = useCallback(async () => {
    closeUpdateNarrativeModal()
    const narrativeValue = editor?.value.toJSON()
    if (!narrativeValue) {
      return
    }

    try {
      const loadedTemplate = await getNarrativeByTemplateName(templateName)

      const updatedTemplate = cloneDeep(loadedTemplate)
      updatedTemplate.data = narrativeValue

      dispatch(updateNarrativeTemplate(updatedTemplate))
      setInitialNarrative(Value.fromJSON(updatedTemplate.data))
    } catch (error) {
      console.error(error)
      dispatch(errorNotification({ message: 'Failed to update the narrative template' }))
    }
  }, [closeUpdateNarrativeModal, dispatch, editor?.value, setInitialNarrative, templateName])

  const onOpenContentLibrary = () => {
    loadContentLibrary()
    dispatch(changeDrawerSection(DrawerSections.CONTENT_LIBRARY))
  }

  const onLoadContentLibrary = () => {
    if (
      drawerState.isDrawerOpen &&
      templates.length > 0 &&
      templates[0].metadata.form !== contentLibraryTemplateTitle
    ) {
      loadContentLibrary()
    }
  }

  const loadContentLibrary = () => {
    const generatedTextValue = getGeneratedTextValue()
    const payload = {
      name: contentLibraryTemplateTitle,
      activeTemplateName: templateName,
      defaultTemplate: {
        title: 'Default',
        name: contentLibraryTemplateTitle,
        metadata: {
          form: contentLibraryTemplateTitle,
        },
        data: removeLockedInlineValues(generatedTextValue).toJSON(),
        author: {
          username: 'boweryvaluation@boweryvaluation.com',
          fullName: 'Bowery Valuation',
          avatarUrl: LogoIcon,
          initials: 'BV',
        },
        updatedAt: new Date().getTime(),
      },
    }

    dispatch(setContentLibrary(payload))
  }

  const Modals = useMemo(() => {
    return (
      <>
        <OverwriteNarrativeModal
          opened={overwriteModalIsOpened}
          onSave={() => {
            changeNarrative()
            closeOverwriteModal()
          }}
          onClose={() => {
            resetSelectedTemplate()
            closeOverwriteModal()
          }}
        />
        <CreateNarrativeModal
          opened={createNarrativeModalIsOpened}
          onSave={onCreateNarrative}
          onClose={onCloseCreateNarrativeTemplate}
        />
        <UpdateNarrativeModal
          opened={updateNarrativeModalIsOpened}
          onSave={confirmUpdateNarrative}
          onClose={closeUpdateNarrativeModal}
        />
      </>
    )
  }, [
    changeNarrative,
    closeOverwriteModal,
    closeUpdateNarrativeModal,
    confirmUpdateNarrative,
    createNarrativeModalIsOpened,
    onCloseCreateNarrativeTemplate,
    onCreateNarrative,
    overwriteModalIsOpened,
    resetSelectedTemplate,
    updateNarrativeModalIsOpened,
  ])

  return {
    updateIsDisabled,
    updateTemplate,
    onOpenCreateNarrativeTemplate,
    onOpenContentLibrary,
    onLoadContentLibrary,
    Modals,
  }
}
