/**
 * Overriding styles for MuiToggleButtonGroup
 */

import { alpha, darken, lighten } from '@mui/material/styles'

const grouped = ({ theme: { palette }, ownerState: { color } }) => {
  const { main } = palette[color ?? 'primary']

  return {
    color: main,
    backgroundColor: 'white',
    borderColor: main,
    '&.MuiToggleButton-root': {
      borderColor: main,
    },
    '&.Mui-selected': {
      color: 'white',
      backgroundColor: main,
      borderColor: main,
      '&.Mui-disabled': {
        borderColor: alpha(main, 0.3),
        backgroundColor: alpha(main, 0.3),
      },
    },
    '&.Mui-disabled': {
      borderColor: 'inherit',
      '&.Mui-selected': {
        borderColor: 'rgba(0, 0, 0, 0.12)',
        backgroundColor: 'rgba(0, 0, 0, 0.12)',
        color: 'rgba(0, 0, 0, 0.26)',
      },
    },
    '&.Mui-selected:hover': {
      backgroundColor: darken(main, 0.2),
      borderColor: darken(main, 0.2),
    },
  }
}

const defaultVariant = ({ theme: { palette }, ownerState: { color } }) => {
  const { main, contrastText: text } = palette[color ?? 'primary']

  return {
    '& .MuiToggleButtonGroup-grouped': {
      '&.MuiToggleButton-root': {
        color: text,
        backgroundColor: main,
        textTransform: 'none',
      },
      '&:hover': {
        backgroundColor: lighten(main, 0.1),
      },
      '&.Mui-selected': {
        backgroundColor: darken(main, 0.3),
      },
      '&.Mui-selected:hover': {
        backgroundColor: darken(main, 0.2),
      },
    },
  }
}

export default {
  styleOverrides: {
    grouped: [{ padding: '6px 16px' }, grouped],
  },
  variants: [
    {
      props: { variant: 'default' },
      style: defaultVariant,
    },
  ],
}
