import { get } from 'lodash'

export const mapDTO = formValues => ({
  exposureTimeMax: get(formValues, 'exposureTime.max'),
  exposureTimeMin: get(formValues, 'exposureTime.min'),
})

export const mapDataModel = formValues => ({
  exposureTimeMax: get(formValues, 'property_information.exposureTime.to'),
  exposureTimeMin: get(formValues, 'property_information.exposureTime.from'),
})
