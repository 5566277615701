import React, { FC, memo } from 'react'
import { Grid, Typography } from '@mui/material'
import { Button } from 'client-shared/components/_mui5'

import { get, noop, round, dropRight } from 'lodash'
import { ColumnDataTypeEnum, RowBasedTable } from '@bowery-valuation/ui-components'
import { formatCurrencyFloat, formatPercentageString } from 'client-shared/utils/numberFormatters'
import { getCustomColumnConfig, RowBasedTableColumn } from 'client-shared/utils/rowBasedTable'
import { fromCurrency, fromPercents, toPercents } from 'client-shared/utils/numberOperations'
import { DatePicker, Number as NumberField } from 'client-shared/components'
import CostApproachCalculations from 'shared/report-calculations/cost-approach/cost-approach-calculations'
import { FormChange } from 'client-shared/utils/form'
import { DropDown } from 'client-shared/components/DropDown'
import { ROUNDING_ITEMS } from 'client-shared/components/AdjustmentsAndLosses/constants'

import { ConclusionType, DepreciationType, NpvAdjustmentType, SubjectBudgetType } from 'shared/types/costApproachTypes'

import { NPV_ROW_IDS, VALUE_CONCLUSION_TYPES, isNpvRow } from './helpers'

type ValueOpinionsOwnProps = {
  change: FormChange
  conclusion: ConclusionType
  subjectBudget: SubjectBudgetType
  depreciation: DepreciationType
  totalLeaseUpCosts: number
  reconciledValue: number
  landValue: number
}

const placeholderNpvAdjustment: NpvAdjustmentType = {
  name: 'Adjustment',
  value: 0,
}

export const ValueOpinions: FC<ValueOpinionsOwnProps> = ({
  conclusion,
  subjectBudget,
  depreciation,
  reconciledValue,
  totalLeaseUpCosts,
  landValue,
  change,
}) => {
  const asIsNpvAdjustments: NpvAdjustmentType[] = get(conclusion, 'asIsNpvAdjustments', [])
  const asStabilizedNpvAdjustments: NpvAdjustmentType[] = get(conclusion, 'asStabilizedNpvAdjustments', [])
  const asCompleteNpvAdjustments: NpvAdjustmentType[] = get(conclusion, 'asCompleteNpvAdjustments', [])

  const {
    constructionCostsSpentToDate,
    remainingConstructionCosts,

    asIsEntrepreneurialIncentive,
    asIsEntrepreneurialIncentiveValue,
    asIsMarketValue,
    asIsMarketValueRounded,
    asIsMarketValueWithGrowth,

    asCompleteGrowthRate,
    asCompleteMonthsAnnualizedFor,
    asCompleteAnnualizedGrowthRate,
    asCompleteEntrepreneurialIncentive,
    asCompleteEntrepreneurialIncentiveValue,
    asCompleteMarketValue,
    asCompleteMarketValueRounded,
    asCompleteMarketValueWithGrowth,

    asStabilizedGrowthRate,
    asStabilizedMonthsAnnualizedFor,
    asStabilizedAnnualizedGrowthRate,
    asStabilizedEntrepreneurialIncentive,
    asStabilizedEntrepreneurialIncentiveValue,
    asStabilizedMarketValue,
    asStabilizedMarketValueRounded,
  } = CostApproachCalculations.calculateCostApproachFinalValues({
    conclusion,
    subjectBudget,
    totalLeaseUpCosts,
    replacementCostNew: reconciledValue,
    landValue,
  })
  const constructionCostsLabel = depreciation.includeDepreciation
    ? 'Depreciated Costs Spent To Date'
    : 'Construction Costs Spent to Date'

  const asIsRows = [
    {
      readOnly: false,
      suppressMovable: true,
      permanent: false,
      type: ColumnDataTypeEnum.text,
      label: 'Land Value',
      id: 'landValue',
      value: formatCurrencyFloat(landValue),
      roundedFinalValue: '',
      rowDef: { hideAction: true },
    },
    {
      readOnly: false,
      suppressMovable: true,
      permanent: false,
      type: ColumnDataTypeEnum.text,
      label: constructionCostsLabel,
      id: 'constructionCostsSpentToDate',
      value: formatCurrencyFloat(constructionCostsSpentToDate),
      roundedFinalValue: '',
      rowDef: { hideAction: true },
    },
    {
      readOnly: false,
      suppressMovable: true,
      permanent: false,
      type: ColumnDataTypeEnum.text,
      label: `Entrepreneurial Incentive @ ${formatPercentageString(asIsEntrepreneurialIncentive ?? 0)}`,
      id: 'asIsEntrepreneurialIncentive',
      value: formatCurrencyFloat(asIsEntrepreneurialIncentiveValue),
      roundedFinalValue: '',
      rowDef: { hideAction: true },
    },
  ]
  asIsNpvAdjustments?.forEach((npvAdjustment: any, index: number) => {
    asIsRows.push({
      readOnly: false,
      suppressMovable: true,
      permanent: false,
      type: ColumnDataTypeEnum.text,
      label: npvAdjustment.name,
      id: `${NPV_ROW_IDS.AS_IS}_${index}`,
      value: formatCurrencyFloat(npvAdjustment.value),
      roundedFinalValue: '',
      rowDef: { hideAction: true },
    })
  })
  asIsRows.push({
    readOnly: false,
    suppressMovable: true,
    permanent: false,
    type: ColumnDataTypeEnum.text,
    id: 'asIsMarketValue',
    label: 'As Is Market Value',
    value: formatCurrencyFloat(asIsMarketValue),
    roundedFinalValue: formatCurrencyFloat(asIsMarketValueRounded),
    rowDef: { hideAction: true },
  })

  const asCompleteRows = [
    {
      readOnly: false,
      suppressMovable: true,
      permanent: false,
      type: ColumnDataTypeEnum.text,
      id: 'asCompleteAnnualizedGrowthRate',
      label: `Growth Rate of ${formatPercentageString(
        asCompleteGrowthRate ?? 0
      )} annualized for ${asCompleteMonthsAnnualizedFor} months`,
      value: round(asCompleteAnnualizedGrowthRate, 4),
      roundedFinalValue: '',
      rowDef: { hideAction: true },
    },
    {
      readOnly: false,
      suppressMovable: true,
      permanent: false,
      type: ColumnDataTypeEnum.text,
      id: 'asIsMarketValueWithGrowth',
      label: `As Is Market Value with Growth Applied`,
      value: formatCurrencyFloat(asIsMarketValueWithGrowth),
      roundedFinalValue: '',
      rowDef: { hideAction: true },
    },
    {
      readOnly: false,
      suppressMovable: true,
      permanent: false,
      type: ColumnDataTypeEnum.text,
      id: 'remainingConstructionCosts',
      label: `Remaining Construction Costs`,
      value: formatCurrencyFloat(remainingConstructionCosts),
      roundedFinalValue: '',
      rowDef: { hideAction: true },
    },
    {
      readOnly: false,
      suppressMovable: true,
      permanent: false,
      type: ColumnDataTypeEnum.text,
      id: 'asCompleteEntrepreneurialIncentive',
      label: `Entrepreneurial Incentive of ${formatPercentageString(asCompleteEntrepreneurialIncentive ?? 0)}`,
      value: formatCurrencyFloat(asCompleteEntrepreneurialIncentiveValue),
      roundedFinalValue: '',
      rowDef: { hideAction: true },
    },
  ]
  asCompleteNpvAdjustments?.forEach((npvAdjustment: any, index: number) => {
    asCompleteRows.push({
      readOnly: false,
      suppressMovable: true,
      permanent: false,
      type: ColumnDataTypeEnum.text,
      label: npvAdjustment.name,
      id: `${NPV_ROW_IDS.AS_COMPLETE}_${index}`,
      value: formatCurrencyFloat(npvAdjustment.value),
      roundedFinalValue: '',
      rowDef: { hideAction: true },
    })
  })
  asCompleteRows.push({
    readOnly: false,
    suppressMovable: true,
    permanent: false,
    type: ColumnDataTypeEnum.text,
    id: 'asCompleteMarketValue',
    label: 'Prospective Market Value As Complete',
    value: formatCurrencyFloat(asCompleteMarketValue),
    roundedFinalValue: formatCurrencyFloat(asCompleteMarketValueRounded),
    rowDef: { hideAction: true },
  })

  const columns: RowBasedTableColumn[] = [
    {
      name: 'label',
      label: 'Description',
      type: ColumnDataTypeEnum.text,
      permanent: true,
      align: 'left',
      editable: (params: any) => {
        return isNpvRow(params.data.id)
      },
    },
    {
      name: 'value',
      label: 'Value',
      type: ColumnDataTypeEnum.text,
      permanent: true,
      align: 'right',
      editable: (params: any) => {
        return isNpvRow(params.data.id)
      },
    },
    {
      name: 'roundedFinalValue',
      label: 'Final Value (RD)',
      type: ColumnDataTypeEnum.text,
      permanent: true,
      editable: false,
      align: 'right',
    },
  ]

  const asStabilizedRows = [
    {
      readOnly: false,
      suppressMovable: true,
      permanent: false,
      type: ColumnDataTypeEnum.text,
      id: 'asCompleteGrowthRate',
      label: `Growth Rate of ${formatPercentageString(
        asStabilizedGrowthRate ?? 0
      )} annualized for ${asStabilizedMonthsAnnualizedFor} months`,
      value: round(asStabilizedAnnualizedGrowthRate, 4),
      roundedFinalValue: '',
      rowDef: { hideAction: true },
    },
    {
      readOnly: false,
      suppressMovable: true,
      permanent: false,
      type: ColumnDataTypeEnum.text,
      id: 'asCompleteMarketValueWithGrowth',
      label: `As Complete Market Value with Growth Applied`,
      value: formatCurrencyFloat(asCompleteMarketValueWithGrowth),
      roundedFinalValue: '',
      rowDef: { hideAction: true },
    },
    {
      readOnly: false,
      suppressMovable: true,
      permanent: false,
      type: ColumnDataTypeEnum.text,
      id: 'totalLeaseUpCosts',
      label: `Total Lease-up Costs`,
      value: formatCurrencyFloat(totalLeaseUpCosts),
      roundedFinalValue: '',
      rowDef: { hideAction: true },
    },
    {
      readOnly: false,
      suppressMovable: true,
      permanent: false,
      type: ColumnDataTypeEnum.text,
      id: 'asStabilizedEntrepreneurialIncentive',
      label: `Entrepreneurial Incentive of ${formatPercentageString(asStabilizedEntrepreneurialIncentive ?? 0)}`,
      value: formatCurrencyFloat(asStabilizedEntrepreneurialIncentiveValue),
      roundedFinalValue: '',
      rowDef: { hideAction: true },
    },
  ]
  asStabilizedNpvAdjustments?.forEach((npvAdjustment: any, index: number) => {
    asStabilizedRows.push({
      readOnly: false,
      suppressMovable: true,
      permanent: false,
      type: ColumnDataTypeEnum.text,
      label: npvAdjustment.name,
      id: `${NPV_ROW_IDS.AS_STABILIZED}_${index}`,
      value: formatCurrencyFloat(npvAdjustment.value),
      roundedFinalValue: '',
      rowDef: { hideAction: true },
    })
  })
  asStabilizedRows.push({
    readOnly: false,
    suppressMovable: true,
    permanent: false,
    type: ColumnDataTypeEnum.text,
    id: 'asStabilizedMarketValue',
    label: `Prospective Market Value As Stabilized`,
    value: formatCurrencyFloat(asStabilizedMarketValue),
    roundedFinalValue: formatCurrencyFloat(asStabilizedMarketValueRounded),
    rowDef: { hideAction: true },
  })

  const handleNpvUpdate = (row: any, valueConclusion: string) => {
    const { id } = row
    const index = id.split('_')[1]

    change(`conclusion.${valueConclusion}NpvAdjustments[${index}].value`, fromCurrency(row.value))
    change(`conclusion.${valueConclusion}NpvAdjustments[${index}].name`, row.label)
  }

  const handleAddNpvAdjustment = (valueConclusion: string) => {
    switch (valueConclusion) {
      case VALUE_CONCLUSION_TYPES.AS_IS:
        change('conclusion.asIsNpvAdjustments', [...asIsNpvAdjustments, placeholderNpvAdjustment])
        break
      case VALUE_CONCLUSION_TYPES.AS_STABILIZED:
        change('conclusion.asStabilizedNpvAdjustments', [...asStabilizedNpvAdjustments, placeholderNpvAdjustment])
        break
      case VALUE_CONCLUSION_TYPES.AS_COMPLETE:
        change('conclusion.asCompleteNpvAdjustments', [...asCompleteNpvAdjustments, placeholderNpvAdjustment])
        break
    }
  }

  const handleRemoveNpvAdjustment = (valueConclusion: string) => {
    switch (valueConclusion) {
      case VALUE_CONCLUSION_TYPES.AS_IS:
        change('conclusion.asIsNpvAdjustments', dropRight(asIsNpvAdjustments))
        break
      case VALUE_CONCLUSION_TYPES.AS_STABILIZED:
        change('conclusion.asStabilizedNpvAdjustments', dropRight(asStabilizedNpvAdjustments))
        break
      case VALUE_CONCLUSION_TYPES.AS_COMPLETE:
        change('conclusion.asCompleteNpvAdjustments', dropRight(asCompleteNpvAdjustments))
        break
    }
  }

  return (
    <Grid>
      <Typography variant="subtitle1">Value Opinions Via the Cost Approach</Typography>
      <Typography variant="subtitle2" sx={{ marginTop: 2 }}>
        As Is Market Value
      </Typography>
      <Grid container spacing={1}>
        <Grid item xs={3}>
          <NumberField
            adornment="%"
            decimalScale={0}
            format={toPercents}
            label="Entrepreneurial Incentive"
            name="conclusion.asIsEntrepreneurialIncentive"
            normalize={fromPercents}
            testId="conclusion.asIsEntrepreneurialIncentive"
          />
        </Grid>
        <Grid item xs={3}>
          <DropDown
            data-qa="conclusion.roundToTheNearest"
            items={ROUNDING_ITEMS}
            label="Round to the nearest"
            name="conclusion.roundToTheNearest"
            testId="conclusion.roundToTheNearest"
          />
        </Grid>
      </Grid>

      <Grid container justifyContent="flex-end" mt={2}>
        <Grid item>
          <Button size="small" onClick={() => handleAddNpvAdjustment(VALUE_CONCLUSION_TYPES.AS_IS)}>
            Add NPV Adjustment
          </Button>
          <Button
            size="small"
            color="error"
            disabled={!asIsNpvAdjustments.length}
            onClick={() => handleRemoveNpvAdjustment(VALUE_CONCLUSION_TYPES.AS_IS)}
          >
            Remove Last Added
          </Button>
        </Grid>
      </Grid>
      <RowBasedTable
        id="value-opinions"
        columns={columns}
        rows={asIsRows}
        onRowUpdate={row => handleNpvUpdate(row, VALUE_CONCLUSION_TYPES.AS_IS)}
        onManyRowsUpdate={noop}
        getCustomColumnConfig={getCustomColumnConfig}
        actionCellHidden
        hideIndexColumn
        onColumnDragEnd={noop}
        onRowsDragEnd={noop}
        onColumnDelete={noop}
        onColumnUpdate={noop}
        onRowDelete={noop}
      />

      <Typography variant="subtitle2" sx={{ mt: 5 }}>
        Prospective Market Value As Complete
      </Typography>

      <Grid container spacing={1}>
        <Grid item xs={3}>
          <DatePicker name="conclusion.asCompleteDateOfValue" label="Date of Value" />
        </Grid>
        <Grid item xs={3}>
          <NumberField
            adornment="%"
            decimalScale={0}
            format={toPercents}
            label="Growth Rate"
            name="conclusion.asCompleteGrowthRate"
            normalize={fromPercents}
            testId="conclusion.asCompleteGrowthRate"
          />
        </Grid>
        <Grid item xs={3}>
          <NumberField
            adornment="months"
            label="Annualized for"
            name="conclusion.asCompleteMonthsAnnualizedFor"
            testId="conclusion.asCompleteMonthsAnnualizedFor"
          />
        </Grid>
        <Grid item xs={3}>
          <NumberField
            adornment="%"
            decimalScale={0}
            format={toPercents}
            label="Entrepreneurial Incentive"
            name="conclusion.asCompleteEntrepreneurialIncentive"
            normalize={fromPercents}
            testId="conclusion.asCompleteEntrepreneurialIncentive"
          />
        </Grid>
      </Grid>

      <Grid container justifyContent="flex-end" mt={2}>
        <Grid item>
          <Button size="small" onClick={() => handleAddNpvAdjustment(VALUE_CONCLUSION_TYPES.AS_COMPLETE)}>
            Add NPV Adjustment
          </Button>
          <Button
            size="small"
            color="error"
            disabled={!asCompleteNpvAdjustments.length}
            onClick={() => handleRemoveNpvAdjustment(VALUE_CONCLUSION_TYPES.AS_COMPLETE)}
          >
            Remove Last Added
          </Button>
        </Grid>
      </Grid>
      <RowBasedTable
        id="value-opinions-as-is"
        columns={columns}
        rows={asCompleteRows}
        onRowUpdate={row => handleNpvUpdate(row, VALUE_CONCLUSION_TYPES.AS_COMPLETE)}
        onManyRowsUpdate={noop}
        actionCellHidden
        hideIndexColumn
        onColumnDragEnd={noop}
        onRowsDragEnd={noop}
        onColumnDelete={noop}
        onColumnUpdate={noop}
        onRowDelete={noop}
      />

      <Typography variant="subtitle2" sx={{ mt: 5 }}>
        Prospective Market Value As Stabilized
      </Typography>

      <Grid container spacing={1}>
        <Grid item xs={3}>
          <DatePicker name="conclusion.asStabilizedDateOfValue" label="Date of Value" />
        </Grid>
        <Grid item xs={3}>
          <NumberField
            adornment="%"
            decimalScale={0}
            format={toPercents}
            label="Growth Rate"
            name="conclusion.asStabilizedGrowthRate"
            normalize={fromPercents}
            testId="conclusion.asStabilizedGrowthRate"
          />
        </Grid>
        <Grid item xs={3}>
          <NumberField
            adornment="months"
            label="Annualized for"
            name="conclusion.asStabilizedMonthsAnnualizedFor"
            testId="conclusion.asStabilizedMonthsAnnualizedFor"
          />
        </Grid>
        <Grid item xs={3}>
          <NumberField
            adornment="%"
            decimalScale={0}
            format={toPercents}
            label="Entrepreneurial Incentive"
            name="conclusion.asStabilizedEntrepreneurialIncentive"
            normalize={fromPercents}
            testId="conclusion.asStabilizedEntrepreneurialIncentive"
          />
        </Grid>
      </Grid>

      <Grid container justifyContent="flex-end" mt={2}>
        <Grid item>
          <Button size="small" onClick={() => handleAddNpvAdjustment(VALUE_CONCLUSION_TYPES.AS_STABILIZED)}>
            Add NPV Adjustment
          </Button>
          <Button
            size="small"
            color="error"
            disabled={!asStabilizedNpvAdjustments.length}
            onClick={() => handleRemoveNpvAdjustment(VALUE_CONCLUSION_TYPES.AS_STABILIZED)}
          >
            Remove Last Added
          </Button>
        </Grid>
      </Grid>
      <RowBasedTable
        id="value-opinions-as-complete"
        columns={columns}
        rows={asStabilizedRows}
        onRowUpdate={row => handleNpvUpdate(row, VALUE_CONCLUSION_TYPES.AS_STABILIZED)}
        onManyRowsUpdate={noop}
        actionCellHidden
        hideIndexColumn
        onColumnDragEnd={noop}
        onRowsDragEnd={noop}
        onColumnDelete={noop}
        onColumnUpdate={noop}
        onRowDelete={noop}
      />
    </Grid>
  )
}

export default memo(ValueOpinions)
