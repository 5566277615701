import React from 'react'

import { Grid, Stack, Typography } from '@mui/material'
import { get, isNumber } from 'lodash'

import ImageUploadWithStreetView from 'client-shared/components/ImageUpload/ImageUploadWithStreetView'
import { BUILDING_PHOTO } from 'client-shared/constants/imageCategories'
import { CheckboxWithLabel, Number as NumberField, Text } from 'client-shared/components'
import { DatePicker } from 'client-shared/components/DatePicker'
import { DropDown } from 'client-shared/components/DropDown'
import { STATES_LIST } from 'shared/constants/states'
import { TYPES_LIST } from 'client-shared/constants'
import { formatFloat } from 'client-shared/utils/numberFormatters'
import { positive, required } from 'client-shared/utils/validation'

import { BUILDING_LABEL_VALUE_PAIR, PRIMARY_SOURCES } from '../../constants'
import wrapForm from '../wrapForm'

import NeighborhoodAutosuggest from './NeighborhoodAutosuggest'

const DATA_PATH = [BUILDING_LABEL_VALUE_PAIR.PropertySummary.menuItem]

const validateYearBuilt = (value, { yearRenovated }) => {
  if (!isNumber(value)) {
    return value
  }
  if (!isNumber(yearRenovated)) {
    return undefined
  }

  return value > yearRenovated ? `Max value is ${yearRenovated}` : undefined
}

const validateYearRenovated = (value, { yearBuilt }) => {
  if (!isNumber(value)) {
    return value
  }

  return value < yearBuilt ? `Min value is ${yearBuilt}` : undefined
}

const PropertySummary = ({ coordinates, form }) => {
  const address = get(form, 'values.streetAddress', '')

  return (
    <Grid container spacing={2}>
      <Grid item xs={4}>
        <Stack spacing={1}>
          <Typography variant="h5">Location</Typography>
          <Text label="Address" name="streetAddress" validate={required} />
          <Text
            disabled
            format={value => `${formatFloat(value)} mi`}
            label="Distance from subject"
            name="distanceFromSubject"
          />
          <Text label="City" name="city" />
          <DropDown label="State" name="state" items={STATES_LIST} />
          <NumberField label="Block" name="descriptor.block" disabled />
          <NumberField label="Lot" name="descriptor.lot" disabled />
          <Text label="Building Name" name="buildingName" />
          <NeighborhoodAutosuggest />
          <DropDown label="Type" name="type" items={TYPES_LIST} />
          <NumberField
            adornment="SF"
            decimalScale={2}
            label="Gross Building Area"
            min={0}
            name="grossBuildingArea"
            placeholder="Square Feet"
            validate={required}
          />
          <NumberField label="Year Built" min={0} name="yearBuilt" placeholder="YYYY" validate={validateYearBuilt} />
          <NumberField
            label="Year Renovated"
            name="yearRenovated"
            placeholder="YYYY"
            validate={validateYearRenovated}
          />
          <NumberField label="Floors" max={9999} min={0} name="floors" placeholder="#" />
          <NumberField
            label="Residential Units"
            max={9999}
            min={0}
            name="residentialUnitCount"
            placeholder="# of Units"
            validate={required}
          />
          <DatePicker label="Date as of" name="asOfDate" validate={required} />
          <NumberField
            adornment="%"
            decimalScale={2}
            label="Occupancy Level"
            name="occupancyLevel"
            validate={[required, positive]}
          />
          <Text label="Management" name="management" warn={required} />
          <CheckboxWithLabel size="small" name="hasElevator" label="Does the building have an elevator?" />
          <CheckboxWithLabel
            label="Is this property a garden style apartment complex?"
            name="isGardenStyle"
            size="small"
          />
          <DropDown items={PRIMARY_SOURCES} label="Primary Source" name="primarySource" placeholder="None" />
        </Stack>
      </Grid>
      <Grid item xs={8}>
        <Stack spacing={1} sx={{ height: 1 }}>
          <Typography variant="h5">{BUILDING_PHOTO.categoryTitle}</Typography>
          <ImageUploadWithStreetView name="image" address={address} coordinates={coordinates} />
        </Stack>
      </Grid>
    </Grid>
  )
}

const formOptions = {
  enableReinitialize: true,
  heading: BUILDING_LABEL_VALUE_PAIR.PropertySummary.heading,
}

const mapStateToProps = state => ({
  coordinates: get(state, 'buildingComp.propertySummary.coordinates'),
})

export default wrapForm(DATA_PATH, formOptions, mapStateToProps)(PropertySummary)
