import { GeneratedText, gtConditional, gtTemplate } from '../../../narrative/GeneratedText'

import { mapDTO, mapDataModel } from './mappings'

const generate = () => {
  const gt = new GeneratedText()
  gt.t`${gtConditional(
    'isNYCReport',
    gtTemplate`According to the New York City Zoning Code: If a non-complying building or other structure is 
    damaged or destroyed by any means, including any demolition, to the extent of 75 percent or more of its total 
    floor area, such building may be reconstructed only in accordance with the applicable district bulk regulations, 
    except in the case of a one- or two-family residence, such residence may be reconstructed provided that such 
    reconstruction shall not create a new non-compliance nor increase the pre-existing degree of non-compliance with 
    the applicable bulk regulations.\nIf the extent of such damage or destruction is less than 75% percent of the floor 
    area and more than 25 percent of the perimeter walls of such existing building, and the replacement of any portion 
    thereof, shall be considered a development.\nIn the event that any demolition, damage or destruction of an existing 
    building other than one-or two-family residences produces an unsafe condition requiring a Department of Buildings 
    order or permit for further demolition of floor area to remove or rectify the unsafe condition, and the aggregate 
    floor area demolished, damaged or destroyed including that ordered or permitted by the Department of Buildings 
    constitutes 75 percent of more of the total floor area of such building, then such building may be reconstructed 
    only in accordance with the applicable district bulk regulations.`
  )}`

  return gt.getParts()
}

export default { generate, mapDTO, mapDataModel }
