import React from 'react'

import { FieldArray } from 'react-final-form-arrays'
import { get } from 'lodash'
import arrayMutators from 'final-form-arrays'

import { Button, Stack } from '@mui/material'

import wrapForm from 'report/forms/wrapForm'
import { CalloutButton } from 'client-shared/components'

import { Labels, DATA_PATH, REIMBURSEMENT_TYPES } from '../constants'
import {
  updateDollarAmount,
  updateVCLossValues,
  updateIncreaseOverBaseYear,
  updatePercentOfAppraiserForecast,
  updatePercentOfCurrentYearLiability,
} from '../mutators'

import ReimbursementEditModal from './ReimbursementEditModal'
import DollarAmountReimbursementTable from './DollarAmountReimbursementTable'
import IncreaseOverBaseYearReimbursementTable from './IncreaseOverBaseYearReimbursementTable'
import PercentOfCurrentYearLiabilityReimbursementTable from './PercentOfCurrentYearLiabilityReimbursementTable'
import PercentOfAppraiserForecastReimbursementTable from './PercentOfAppraiserForecastReimbursementTable'

class CommercialReimbursementSummary extends React.PureComponent {
  state = {
    isCreateModalOpen: false,
  }

  createReimbursement = newReimbursement => {
    const { form } = this.props

    const currentReimbursements = form.values.reimbursements
    form.change('reimbursements', [...currentReimbursements, newReimbursement])

    this.closeCreateModal()
  }

  openCreateModal = () => {
    this.setState({ isCreateModalOpen: true })
  }
  closeCreateModal = () => {
    this.setState({ isCreateModalOpen: false })
  }

  renderReimbursements = fields => {
    const { form } = this.props
    const { values: formValues } = form
    const { expenseCategories } = formValues

    return fields.map((field, index) => {
      const reimbursement = get(formValues, field)
      const reimbursementType = get(reimbursement, 'type')

      switch (reimbursementType) {
        case REIMBURSEMENT_TYPES.dollarAmount.value:
          return (
            <DollarAmountReimbursementTable
              key={index}
              reimbursement={reimbursement}
              expenseCategories={expenseCategories}
              form={form}
              pathPrefix={field}
              onUpdate={updatedReimbursement => fields.update(index, updatedReimbursement)}
              onDelete={() => fields.remove(index)}
            />
          )

        case REIMBURSEMENT_TYPES.increaseOverBaseYear.value:
          return (
            <IncreaseOverBaseYearReimbursementTable
              key={index}
              reimbursement={reimbursement}
              expenseCategories={expenseCategories}
              form={form}
              pathPrefix={field}
              onUpdate={updatedReimbursement => fields.update(index, updatedReimbursement)}
              onDelete={() => fields.remove(index)}
            />
          )
        case REIMBURSEMENT_TYPES.percentOfCurrentYearLiability.value:
          return (
            <PercentOfCurrentYearLiabilityReimbursementTable
              key={index}
              reimbursement={reimbursement}
              expenseCategories={expenseCategories}
              form={form}
              pathPrefix={field}
              onUpdate={updatedReimbursement => fields.update(index, updatedReimbursement)}
              onDelete={() => fields.remove(index)}
            />
          )
        case REIMBURSEMENT_TYPES.percentOfAppraiserForecast.value:
          return (
            <PercentOfAppraiserForecastReimbursementTable
              key={index}
              reimbursement={reimbursement}
              expenseCategories={expenseCategories}
              form={form}
              pathPrefix={field}
              onUpdate={updatedReimbursement => {
                fields.update(index, updatedReimbursement)
                const { expenseForecast, squareFootage, numberOfResidentialUnits } = formValues
                get(updatedReimbursement, `unitReimbursements`, []).forEach((reimbursement, index) => {
                  const unitReimbursementPath = `${field}.unitReimbursements[${index}]`
                  form.mutators.updatePercentOfAppraiserForecast({
                    unitReimbursementPath,
                    form,
                    expenseForecast,
                    squareFootage,
                    numberOfResidentialUnits,
                  })
                })
              }}
              onDelete={() => fields.remove(index)}
            />
          )
        default:
          return null
      }
    })
  }

  render() {
    const { form } = this.props
    const { units, expenseCategories } = form.values
    const { isCreateModalOpen } = this.state

    const hasUnits = units.length > 0

    return (
      <Stack spacing={2} alignItems="start" minWidth={1360}>
        <Button
          color="primary"
          data-qa="create-reimbursement_btn"
          disabled={!hasUnits}
          onClick={this.openCreateModal}
          variant="contained"
        >
          ADD REIMBURSEMENT
        </Button>
        {isCreateModalOpen && (
          <ReimbursementEditModal
            expenseCategories={expenseCategories}
            isCreateModal={true}
            onCancel={this.closeCreateModal}
            onSave={this.createReimbursement}
            units={units}
          />
        )}
        {!hasUnits && (
          <CalloutButton
            link="./commercial-units"
            linkText="Change"
            text="There are no commercial units to apply a reimbursement to."
            variant="warn"
          />
        )}
        <FieldArray name="reimbursements">{({ fields }) => this.renderReimbursements(fields)}</FieldArray>
      </Stack>
    )
  }
}

const formOptions = {
  heading: Labels.HEADING_NAME,
  initialValues: {},
  mutators: {
    ...arrayMutators,
    updateDollarAmount,
    updateIncreaseOverBaseYear,
    updatePercentOfCurrentYearLiability,
    updatePercentOfAppraiserForecast,
    updateVCLossValues,
  },
}

export default wrapForm(DATA_PATH, formOptions)(CommercialReimbursementSummary)
