import { GeneratedText, gtLocked } from '../../../narrative/GeneratedText'

import { mapDTO, mapDataModel } from './mappings'

const generate = () => {
  const gt = new GeneratedText()
  gt.t`Our analyses, opinions and conclusions were developed, and this report has been prepared,
  in conformance with the Standards of Professional Practice and Code of Professional Ethics of
  the Appraisal Institute, the Uniform Standard of Professional Appraisal Practice (USPAP),
  ${gtLocked(
    'clientCompany',
    null,
    'Client Company'
  )}'s appraisal guidelines, and applicable state appraisal regulations. To report the
    assignment results, we use the Appraisal Report option of Standards Rule 2-2(a) of USPAP.`
  return gt.getParts()
}

export default { generate, mapDTO, mapDataModel }
