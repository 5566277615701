import React from 'react'
import PropTypes from 'prop-types'

import { Card, CardHeader, IconButton } from '@mui/material'
import Close from '@mui/icons-material/Close'

import { connect } from 'react-redux'
import { debounce } from 'lodash'

import { Labels, NotificationTypes } from 'client-shared/constants/notificationsTypes'
import { removeNotification as removeNotificationAction } from 'client-shared/redux/actions/notifications'

const NotificationLabels = new Map(Object.values(NotificationTypes).map(value => [value, Labels[value]]))
const getTitle = type => NotificationLabels.get(type)

const Notification = React.forwardRef(({ notification, onClose, onClick }, ref) => (
  <Card onClick={onClick} ref={ref}>
    <CardHeader
      action={
        <IconButton onClick={onClose}>
          <Close />
        </IconButton>
      }
      sx={{ '.MuiCardHeader-action': { alignSelf: 'center' }, cursor: 'pointer' }}
      subheader={notification.message}
      title={getTitle(notification.type)}
    />
  </Card>
))

const notificationShape = PropTypes.shape({
  id: PropTypes.number.isRequired,
  message: PropTypes.string.isRequired,
  type: PropTypes.oneOf([NotificationTypes.SUCCESS, NotificationTypes.WARNING, NotificationTypes.ERROR]).isRequired,
}).isRequired

Notification.propTypes = {
  notification: notificationShape,
  onClick: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
}

const NotificationTimid = React.forwardRef(({ notification, removeNotification, timeout = 6000 }, ref) => {
  const remove = React.useCallback(() => {
    removeNotification(notification.id)
  }, [notification.id, removeNotification])

  const debouncedRemove = debounce(remove, timeout)
  React.useEffect(() => {
    notification.type !== NotificationTypes.ERROR && debouncedRemove()
  }, [debouncedRemove, notification.type])

  return <Notification notification={notification} onClose={remove} onClick={remove} ref={ref} />
})

NotificationTimid.propTypes = {
  notification: notificationShape,
  removeNotification: PropTypes.func.isRequired,
  timeout: PropTypes.number,
}

const NotificationContainer = ({ removeNotification, notification }) => {
  return <NotificationTimid {...{ notification, removeNotification }} />
}

NotificationContainer.propTypes = {
  notification: notificationShape,
  removeNotification: PropTypes.func.isRequired,
}

export default connect(() => ({}), {
  removeNotification: removeNotificationAction,
})(NotificationContainer)
export { Notification, NotificationTimid }
