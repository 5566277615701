import { get } from 'lodash'
import { put, select, takeLatest } from 'redux-saga/effects'

import {
  fetchLatestInsurableValues as fetchLatestInsurableValuesAction,
  fetchLatestInsurableValuesRequest,
  fetchLatestInsurableValuesReceive,
  fetchLatestInsurableValuesFail,
  updateInsurableValues as updateInsurableValuesAction,
  updateInsurableValuesReceive,
  updateInsurableValuesFail,
} from '../actions/insurableValues'

import * as Api from '../../../core/api'
import { successNotification, errorNotification } from '../../../shared/redux/actions/notifications'

function* fetchInsurableValues() {
  try {
    yield put(fetchLatestInsurableValuesRequest())
    const response = yield Api.fetchLatestInsurableValues()
    yield put(fetchLatestInsurableValuesReceive(response))
  } catch (error) {
    yield put(fetchLatestInsurableValuesFail(error))
  }
}

function* updateInsurableValues({ payload }) {
  try {
    const insurableValuesId = yield select(state => get(state, 'insurableValues.values._id'))
    const values = yield Api.updateInsurableValues(insurableValuesId, payload)
    yield put(updateInsurableValuesReceive(values))
    yield put(successNotification({ message: 'Insurable Values were updated' }))
  } catch (error) {
    yield put(updateInsurableValuesFail(error))
    yield put(errorNotification({ message: `Insurable values updating error: ${error}` }))
  }
}

export default [
  takeLatest(fetchLatestInsurableValuesAction, fetchInsurableValues),
  takeLatest(updateInsurableValuesAction, updateInsurableValues),
]
