import React from 'react'

import { createComposed } from '../tools'

import Default from './Layout'

const Grid = createComposed(Default, {})

const Crab = createComposed(Default, {
  container: 'auto-flow / 1fr auto',
  placeItems: 'center stretch',
})

const TwoColumns = createComposed(Default, {
  container: 'auto-flow / 1fr 1fr',
})

const HorizontalRow = createComposed(Default, {
  gap: 2,
  placeItems: 'center stretch',
})

const VerticalRow = createComposed(HorizontalRow, {
  flexDirection: 'column',
  placeItems: 'stretch',
})

const Memo = {
  Crab: React.memo(Crab),
  Grid: React.memo(Grid),
  HorizontalRow: React.memo(HorizontalRow),
  TwoColumns: React.memo(TwoColumns),
  VerticalRow: React.memo(VerticalRow),
}

export default { ...Memo }
