import { UNIT_OF_COMPARISON_TYPES } from '../constants/salesApproach'
import { getTotalUnitCount } from '../report-calculations/helpers'

export const getUnitCount = (unitOfComaprison, residentialUnits, commercialUnits) => {
  const totalUnits = getTotalUnitCount(residentialUnits, commercialUnits)
  if (unitOfComaprison === UNIT_OF_COMPARISON_TYPES.TOTAL_UNITS) {
    return totalUnits
  }
  return residentialUnits
}
