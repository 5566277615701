import { GEOGRAPHY_OPTIONS } from 'shared/constants/property/geography'

import HttpClientManager from '../xhr/HttpClientManager'

import { ORGANIZATION_FORMS_STRUCTURE } from '../admin/constants/organization'
import { BASE64URL_REGEX, JWT_STORAGE } from '../shared/constants'
import { CMS_FORMS_STRUCTURE } from '../cms/constants/cms'
import { RESPONSE_ERROR_MSG } from '../xhr/constants'

const httpClient = HttpClientManager.createHttpClient({
  baseURL: `${global.env.apiUrl}`,
})
const httpClientS3 = HttpClientManager.createHttpClient({
  baseURL: `${global.env.s3Url}`,
})
const httpClientDocumentImport = HttpClientManager.createHttpClient(
  {
    baseURL: `${global.env.apiUrl}`,
  },
  { retries: 0 }
)

export const getAuthorizationHeader = () => {
  let token = localStorage.getItem(JWT_STORAGE) || null
  if (!token) {
    const query = new URLSearchParams(window.location.search)
    token = query.get('token')
    if (token && BASE64URL_REGEX.test(token)) {
      localStorage.setItem(JWT_STORAGE, token)
    } else {
      token = null
    }
  }
  return { Authorization: token ? `Bearer ${token}` : '' }
}

export const getErrorMessageFromException = (err, defaultMessage = '') => {
  if (!defaultMessage) {
    return err?.response?.data?.message || RESPONSE_ERROR_MSG
  }
  return err?.response?.status === 403 && err?.response?.data?.message ? err?.response?.data?.message : defaultMessage
}

export async function fetchUsers() {
  const response = await httpClient.get('/user/all', {
    headers: getAuthorizationHeader(),
  })
  return response.data
}

export async function fetchMoreReports(params) {
  const response = await httpClient.get('/report/currentUser', {
    headers: getAuthorizationHeader(),
    params,
  })

  const { reports, totalCount } = response.data

  return { reports, totalCount }
}

export async function fetchOrganization(reportId) {
  const response = await httpClient.get('/organization', {
    headers: getAuthorizationHeader(),
  })

  return response.data
}

export function fetchOrganizationForms() {
  return ORGANIZATION_FORMS_STRUCTURE
}

export async function updateOrganization(organizationId, formDataPath, values) {
  const response = await httpClient.put(
    `/organization/${organizationId}`,
    {
      formDataPath,
      values,
    },
    {
      headers: getAuthorizationHeader(),
    }
  )

  return response.data
}

export async function updateUser(userId, formDataPath, values) {
  try {
    const response = await httpClient.patch(
      `/user/${userId}`,
      {
        formDataPath,
        values,
      },
      {
        validateStatus: function (status) {
          return status < 500
        },
        headers: getAuthorizationHeader(),
      }
    )
    if (response.status !== 200) {
      throw Error(response.data.message)
    }
    return {
      ...response.data,
    }
  } catch (error) {
    throw error
  }
}

export async function uploadImages({ images, transformations }) {
  const formData = new FormData()

  images.forEach(image => {
    formData.append('images', image)
  })

  const response = await httpClient.post('/imageUpload', formData, {
    headers: getAuthorizationHeader(),
    params: transformations,
  })

  return response.data
}

export async function deleteImages({ cdnUrls }) {
  return await httpClient.post('/imageUpload/remove', { cdnUrls }, { headers: getAuthorizationHeader() })
}

export async function uploadFile(
  file,
  fileType,
  endpoint,
  parentId,
  reportId,
  onUploadProgress = () => {},
  template = null
) {
  const formData = new FormData()
  formData.append('file', file.uploadFileInfo)

  const config = {
    headers: Object.assign({}, getAuthorizationHeader(), {
      'content-type': 'multipart/form-data',
    }),
    params: {
      name: file.fileName,
      type: fileType,
      parentId,
      reportId,
      template,
      id: file.id,
    },
    onUploadProgress,
  }
  const response = await httpClient.post(`api/${endpoint.replace(/\/$/, '')}`, formData, config)
  return response.data
}

export function cancelFileUpload(endpoint, uploadId) {
  const config = {
    headers: Object.assign({}, getAuthorizationHeader(), {
      'content-type': 'multipart/form-data',
    }),
    params: {
      uploadId,
    },
  }
  return httpClient.post(`api/${endpoint.replace(/\/$/, '')}`, null, config)
}

export async function getFilesByType(fileType, endpoint, parentId) {
  return await httpClient.get(`api/${endpoint}/all?type=${fileType}&parentId=${parentId}`, {
    headers: getAuthorizationHeader(),
  })
}

export async function searchProperties(params) {
  let apiCall = ''
  switch (params.locationIdentifier) {
    case GEOGRAPHY_OPTIONS.NY:
      apiCall = `/api/propertySearch/ny/${params.locationType}`
      break
    case GEOGRAPHY_OPTIONS.NJ:
      apiCall = `/api/propertySearch/nj/${params.locationType}`
      break
    default:
      apiCall = `/api/propertySearch/other/${params.locationType}`
  }

  const response = await httpClient.get(apiCall, {
    params,
    headers: getAuthorizationHeader(),
  })
  return response.data
}

export async function searchNYPropertiesByAddress(params) {
  const response = await httpClient.get('/api/propertySearch/ny/address', {
    params,
    headers: getAuthorizationHeader(),
  })
  return response.data
}

export async function createReport(report) {
  const response = await httpClient.post('/report', report, {
    headers: getAuthorizationHeader(),
  })
  return response.data
}

export async function deleteReport(reportId) {
  const response = await httpClient.delete(`/report/${reportId}`, {
    headers: getAuthorizationHeader(),
  })
  return response.data
}

export async function duplicateReport(reportId, jobId, propertyInfo) {
  const response = await httpClient.post(
    `/report/${reportId}/duplicate`,
    { jobId, propertyInfo },
    {
      headers: getAuthorizationHeader(),
    }
  )

  return response.data
}

export async function updateReportVisibility(reportId, userId, reportVisible) {
  const response = await httpClient.post(
    `/report/${reportId}/reportVisibility`,
    {
      reportVisible,
      userId,
    },
    {
      headers: getAuthorizationHeader(),
    }
  )

  return response.data
}

export async function updateReportAsSalesComp(reportId, subjectAsSalesCompCondition) {
  const response = await httpClient.patch(
    `/report/${reportId}/subjectAsSalesComp`,
    {
      subjectAsSalesCompCondition,
    },
    {
      headers: getAuthorizationHeader(),
    }
  )

  return response.data
}

export async function uploadCSV(file) {
  const response = await httpClient.post('/importCsv', file, {
    headers: { ...getAuthorizationHeader(), 'Content-Type': 'text/csv' },
  })
  return response.data
}

export async function getImportedUnits(units) {
  const response = await httpClient.post(
    '/importCsv/units',
    {
      json: units,
    },
    { headers: getAuthorizationHeader() }
  )
  return response.data
}

export async function fetchClients() {
  const response = await httpClient.get('/client/all', {
    headers: getAuthorizationHeader(),
  })
  return response.data
}

export async function usersCreate(user) {
  const response = await httpClient.post('/user/add', user, {
    headers: getAuthorizationHeader(),
  })
  return response.data
}

export async function fetchOffices() {
  const response = await httpClient.get('/user/offices', {
    headers: getAuthorizationHeader(),
  })

  return response.data
}

export async function fetchLatestInsurableValues() {
  const response = await httpClient.get('/insurableValues/latest', {
    headers: getAuthorizationHeader(),
  })
  return response.data
}

export async function updateInsurableValues(insurableValuesSetId, data) {
  const response = await httpClient.patch(`/insurableValues/${insurableValuesSetId}`, data, {
    headers: getAuthorizationHeader(),
  })
  return response.data
}

export async function usersDelete(userId) {
  const response = await httpClient.delete(`/user/${userId}`, {
    headers: getAuthorizationHeader(),
  })
  return response.data
}

export async function analyzeDocumentText(file) {
  if (!file) {
    return {}
  }

  await httpClientS3.put(`/${file.name}`, file, {
    headers: { 'x-amz-acl': 'public-read' },
  })

  const [, bucket] = global.env.s3Url.match(/\/\/(.+)\.s3/)
  const response = await httpClientDocumentImport.post(
    `/api/files/extractAndUpload`,
    {
      filename: file.name,
      bucket: bucket,
    },
    {
      headers: getAuthorizationHeader(),
      timeout: 120000,
    }
  )
  const { excelWorkbook, txtFile } = response.data
  return { excelWorkbook, txtLink: txtFile }
}

export async function fetchMicrofrontendEntry(name) {
  return await httpClient.get(`/api/microfrontends/entry/${name}`, { headers: getAuthorizationHeader() })
}

export async function fetchCms(reportId) {
  const response = await httpClient.get('/cms', {
    headers: getAuthorizationHeader(),
  })

  return response.data
}

export function fetchCmsForms() {
  return CMS_FORMS_STRUCTURE
}

export async function updateCms(organizationId, formDataPath, values) {
  const response = await httpClient.put(
    `/cms/${organizationId}`,
    {
      formDataPath,
      values,
    },
    {
      headers: getAuthorizationHeader(),
    }
  )

  return response.data
}

export async function createTaxableAssessedValueConfiguration(configuration) {
  const response = await httpClient.post('/tax-table-reuse/taxable-assessed-value-configuration', configuration, {
    headers: getAuthorizationHeader(),
  })

  return response.data
}

export async function fetchTaxableAssessedValueConfigurations(userId) {
  const response = await httpClient.get(`/tax-table-reuse/taxable-assessed-value-configuration?userId=${userId}`, {
    headers: getAuthorizationHeader(),
  })

  return response.data
}

export async function createTaxLiabilityConfiguration(configuration) {
  const response = await httpClient.post('/tax-table-reuse/tax-liability-configuration', configuration, {
    headers: getAuthorizationHeader(),
  })

  return response.data
}

export async function fetchTaxLiabilityConfigurations(userId) {
  const response = await httpClient.get(`/tax-table-reuse/tax-liability-configuration?userId=${userId}`, {
    headers: getAuthorizationHeader(),
  })

  return response.data
}

export async function createOrUpdateTaxClass(configuration) {
  const response = await httpClient.post('/tax-table-reuse/tax-class-configuration', configuration, {
    headers: getAuthorizationHeader(),
  })

  return response.data
}

export async function fetchTaxClassConfigurations(userId) {
  const response = await httpClient.get(`/tax-table-reuse/tax-class-configuration?userId=${userId}`, {
    headers: getAuthorizationHeader(),
  })

  return response.data
}

export async function fetchChipOptions(reportId, chipBank) {
  try {
    if (chipBank?.length) {
      return chipBank
    }

    let response = {}
    if (reportId) {
      response = await httpClient.get(`/api/chip/${reportId}`, {
        headers: getAuthorizationHeader(),
      })
    } else {
      response = await httpClient.get(`/api/chip/`, {
        headers: getAuthorizationHeader(),
      })
    }

    return response.data
  } catch (error) {
    console.warn('Error fetching chip options', error)
    return []
  }
}

export async function fetchChipDataPathOptions() {
  try {
    const response = await httpClient.get(`/api/chip/data-path-options`, {
      headers: getAuthorizationHeader(),
    })

    return response.data
  } catch (error) {
    console.warn('Error fetching chip data path options', error)
    return []
  }
}

export async function createChip(chip) {
  const response = await httpClient.post('/api/chip', chip, {
    headers: getAuthorizationHeader(),
  })

  return response.data
}

export async function updateChip(chip) {
  const response = await httpClient.post(`/api/chip/update`, chip, {
    headers: getAuthorizationHeader(),
  })

  return response.data
}

export async function validateChip(chip) {
  const response = await httpClient.post(`/api/chip/validate`, chip, {
    headers: getAuthorizationHeader(),
  })

  return response.data
}

export async function updateNarrative(content) {
  const response = await httpClient.post(`/api/narrative/update`, content, {
    headers: getAuthorizationHeader(),
  })

  return response.data
}

export async function createNarrative(content) {
  const response = await httpClient.post(`/api/narrative/create`, content, {
    headers: getAuthorizationHeader(),
  })

  return response.data
}

export async function getNarrativeTexts(formName) {
  const response = await httpClient.get(`/api/narrative/getNarrativeTextsForUser/${formName}`, {
    headers: getAuthorizationHeader(),
  })

  return response.data
}

export async function getNarrativeByTemplateName(templateName) {
  const response = await httpClient.get(`/api/narrative/getNarrativeText/${templateName}`, {
    headers: getAuthorizationHeader(),
  })

  return response.data
}

export async function getConversation(reportId) {
  const response = await httpClient.get(`/geri/conversation`, {
    headers: getAuthorizationHeader(),
    params: {
      reportId,
    },
  })

  return response.data
}

export async function saveConversation(messages, reportId, conversationId) {
  const response = await httpClient.post(
    `/geri/conversation`,
    {
      messages,
      reportId,
      conversationId,
    },
    {
      headers: getAuthorizationHeader(),
    }
  )

  return response.data
}

export async function messageGeri(prompt, data, conversationId, reportId) {
  const response = await httpClient.post(
    `/geri/send`,
    {
      type: 'geri',
      prompt,
      data,
      conversationId,
      reportId,
    },
    {
      headers: getAuthorizationHeader(),
      timeout: 70000, // ms
    }
  )

  return response.data
}

export async function geriStatus(messageId) {
  const response = await httpClient.post(
    `/geri/status`,
    {
      messageId,
    },
    {
      headers: getAuthorizationHeader(),
      timeout: 70000, // ms
    }
  )

  return response.data
}

export async function getAverageAssetValues(state, submarket, market, datesOfInterest) {
  const response = await httpClient.post(
    `/api/research/average-asset-values`,
    {
      state,
      submarket,
      market,
      datesOfInterest,
    },
    {
      headers: getAuthorizationHeader(),
    }
  )

  return response.data
}

export async function getMarketVacancyRates(state, submarket, market, dateOfInterest) {
  const response = await httpClient.post(
    `/api/research/market-vacancy-rates`,
    {
      state,
      submarket,
      market,
      dateOfInterest,
    },
    {
      headers: getAuthorizationHeader(),
    }
  )

  return response.data
}

export async function getMedianContractRent(state, county, censusTract) {
  const response = await httpClient.post(
    `/api/research/median-contract-rent`,
    {
      state,
      county,
      censusTract,
    },
    {
      headers: getAuthorizationHeader(),
    }
  )

  return response.data
}
