import { GeneratedText, gtIfElse, gtTemplate, gtLocked, gtConditional } from 'shared/utils/narrative/GeneratedText'

import { mapDTO, mapDataModel } from './mappings'

const complyingStatusForBulk = () => {
  return gtTemplate`${gtIfElse(
    'isComplying',
    gtTemplate`The subject ${gtIfElse('isAsComplete', gtTemplate`will be`, gtTemplate`is`)}${gtConditional(
      'isFreddieMacReport',
      gtTemplate`legally`
    )} complying with regards to bulk regulations.`,
    gtTemplate`The subject ${gtIfElse('isAsComplete', gtTemplate`will be`, gtTemplate`is`)}${gtConditional(
      'isFreddieMacReport',
      gtTemplate`legally`
    )}${gtIfElse(
      'isAsComplete',
      gtTemplate``,
      gtTemplate` pre-existing`
    )} non-complying with regards to bulk regulations.`
  )}`
}

const generateNYCComplyingBulk = () => {
  return gtTemplate`Based on the maximum effective residential FAR of ${gtLocked(
    'floorAreaRatio'
  )} and a lot area of ${gtLocked('siteArea')} ${gtLocked('siteAreaMeasureLabel')},  ${gtLocked(
    'maxBuildableArea'
  )} of buildable area is permitted on site.  ${gtIfElse(
    'isAsComplete',
    gtTemplate`Upon renovation, the subject will contain`,
    gtTemplate`The subject contains`
  )} ${gtLocked('grossBuildingArea')} square feet of gross building area above grade${gtConditional(
    'hasMechanicalDeductions',
    gtTemplate` and, after applying mechanical deductions of ${gtLocked('mechanicalDeductions')}%, ${gtIfElse(
      'isAsComplete',
      gtTemplate`will have`,
      gtTemplate`has`
    )} a zoning floor area of ${gtLocked('zoningFloorArea')} square feet`
  )}. `
}

const generate = () => {
  const gt = new GeneratedText()

  const complyingBulkNYC = generateNYCComplyingBulk()
  const complyingStatus = complyingStatusForBulk()

  gt.t`${gtConditional('isNYCReport', complyingBulkNYC)}${complyingStatus}`

  return gt.getParts()
}

export default { generate, mapDTO, mapDataModel }
