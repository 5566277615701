import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import { isEmpty, get } from 'lodash'
import { Field } from 'react-final-form'
import {
  Grid,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  CircularProgress,
  withStyles,
} from '@material-ui/core'

import * as Api from '../../../core/api'

import Dropzone from '../Dropzone'
import StreetView from '../StreetView'
import ImageItem from '../Image/ImageItem'
import { SUPPORTED_IMAGE_FORMATS } from '../../constants/imageCategories'

const styles = theme => ({
  root: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
  },
  dropzone: {
    display: 'flex',
    backgroundColor: theme.palette.secondary[50],
  },
  hiddenDropzone: {
    display: 'none',
  },
  additionalButtons: {
    paddingTop: 5,
  },
  additionalButton: {
    lineHeight: 1.5,
    fontWeight: 500,
    color: theme.palette.primary[900],
  },
  dialogContent: {
    width: 640,
  },
  streetViewContainer: {
    margin: 0,
  },
  captureScreenButtonWrapper: {
    position: 'relative',
  },
  captureScreenLoading: {
    position: 'absolute',
    left: 'calc(50% - 12px)',
    top: 4,
  },
})

class ImageUploadWithStreetView extends React.PureComponent {
  static propTypes = {
    previewWidth: PropTypes.number,
    address: PropTypes.string.isRequired,
    coordinates: PropTypes.shape({
      latitude: PropTypes.number.isRequired,
      longitude: PropTypes.number.isRequired,
    }).isRequired,
  }

  static defaultProps = {
    previewWidth: 800,
  }

  constructor(props) {
    super(props)

    this.dropZoneRef = React.createRef()

    this.state = {
      isWizardOpen: false,
      shouldShowControls: true,
      isImageSaving: false,

      mapView: {
        location: {},
        heading: '',
        zoom: '',
        pitch: '',
      },
    }
  }

  openWizard = () => {
    this.setState({ isWizardOpen: true })
  }

  closeWizard = () => {
    this.setState({ isWizardOpen: false, isImageSaving: false, shouldShowControls: true })
  }

  uploadImage = async image => {
    const response = await Api.uploadImages({ images: [image] })
    const imageUrl = get(response, 'images.0.cdnUrl')
    this.props.input.onChange({ cdnUrl: imageUrl })
    this.closeWizard()
  }

  onDrop = acceptedImages => {
    if (!isEmpty(acceptedImages)) {
      const [image] = acceptedImages
      this.props.input.onChange({ isLoading: true, cdnUrl: image.preview })
      this.uploadImage(image)
    }
  }

  onRotate = ({ newUrl }) => {
    this.props.input.onChange({ cdnUrl: newUrl })
  }

  onUpload = () => {
    this.dropZoneRef.current.open()
  }

  onDelete = () => {
    this.props.input.onChange({ cdnUrl: '' })
  }

  handlePovChange = details => {
    const { location, pov } = details
    this.setState({
      mapView: {
        location: {
          lat: location.latLng.lat(),
          lng: location.latLng.lng(),
        },
        heading: pov.heading,
        pitch: pov.pitch,
        zoom: pov.zoom,
      },
    })
  }

  captureScreen = async () => {
    this.setState({ shouldShowControls: false, isImageSaving: true })
    const { previewWidth } = this.props
    const { mapView } = this.state
    const googleMapsApiKey = global.env.googleApiKey

    const {
      location: { lat, lng },
      heading,
      pitch,
      zoom,
    } = mapView
    const fieldOfView = 180 / 2 ** zoom

    const streetViewUrl =
      `https://maps.googleapis.com/maps/api/streetview?location=${lat},${lng}&size=${previewWidth}x${previewWidth}` +
      `&heading=${heading}&fov=${fieldOfView}&pitch=${pitch}&key=${googleMapsApiKey}`

    const response = await fetch(streetViewUrl)
    const image = await response.blob()
    this.uploadImage(image)
  }

  render() {
    const { classes, address, coordinates, previewWidth, input } = this.props
    const { isWizardOpen, shouldShowControls, isImageSaving } = this.state

    const imageUrl = get(input, 'value.cdnUrl', '')
    const hasImage = !isEmpty(imageUrl)

    return (
      <React.Fragment>
        <div className={classes.root} data-qa={`${input.name}-image-upload`}>
          <Dropzone
            dropZoneRef={this.dropZoneRef}
            multiple={false}
            accept={SUPPORTED_IMAGE_FORMATS}
            classes={{ dropzone: classnames({ [classes.dropzone]: !hasImage, [classes.hiddenDropzone]: hasImage }) }}
            onDrop={this.onDrop}
          />
          {hasImage && (
            <ImageItem
              url={get(input, 'value.cdnUrl')}
              isLoading={get(input, 'value.isLoading')}
              onDelete={this.onDelete}
              onRotate={this.onRotate}
              onUpload={this.onUpload}
              previewWidth={previewWidth}
              shouldDisplayUploadIcon={false}
            />
          )}

          <Grid container justify="space-between" classes={{ container: classes.additionalButtons }}>
            <Grid item>
              <Button
                onClick={this.openWizard}
                classes={{ root: classes.additionalButton }}
                data-qa="open-wizard-button"
              >
                Open Wizard
              </Button>
            </Grid>
            <Grid item>
              <Button onClick={this.onUpload} classes={{ root: classes.additionalButton }} data-qa="choose-file-button">
                Choose a File
              </Button>
            </Grid>
          </Grid>
        </div>

        <Dialog maxWidth="md" open={isWizardOpen} onClose={this.closeWizard} data-qa="street-view-dialog">
          <DialogTitle>{address}</DialogTitle>
          <DialogContent classes={{ root: classes.dialogContent }}>
            <StreetView
              name={`${input.name}StreetView`}
              coordinates={coordinates}
              classes={{ streetViewContainer: classes.streetViewContainer }}
              withControls={shouldShowControls}
              onPovChange={this.handlePovChange}
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={this.closeWizard} color="primary" data-qa="street-view-close-button">
              Close
            </Button>
            <div className={classes.captureScreenButtonWrapper}>
              <Button
                color="primary"
                onClick={this.captureScreen}
                disabled={isImageSaving}
                data-qa="street-view-screen-button"
              >
                Capture Screen
              </Button>
              {isImageSaving && <CircularProgress size={24} className={classes.captureScreenLoading} />}
            </div>
          </DialogActions>
        </Dialog>
      </React.Fragment>
    )
  }
}

export const StyledImageUploadWithStreetView = withStyles(styles)(ImageUploadWithStreetView)

const StreetViewImageUploadField = ({ children, ...props }) => (
  <Field {...props} component={StyledImageUploadWithStreetView} />
)

export default StreetViewImageUploadField
