import {
  formatMaxDecimalFloat,
  formatInt,
  formatCurrencyFloat,
  formatCurrencyInt,
} from 'shared/utils/formatters/numberFormatters'

import { getTotalUnitCount } from '../helpers'

import { UNIT_OF_COMPARISON_TYPES } from '../../constants/salesApproach'
import { divide } from '../../utils/numberOperations'

import { CompPropertyInformation, SaleInformation } from './types'

const ACRES_CONSTANT = 1 / 43560

const convertSfToAcres = (numberInSf: number) => {
  const numberInAcres = (numberInSf || 0) * ACRES_CONSTANT
  return numberInAcres
}

const convertAcresToSf = (numberInAcres: number) => {
  const numberInSf = divide(numberInAcres, ACRES_CONSTANT)
  return numberInSf
}

export const getSalePriceToUse = (saleInformation: SaleInformation) => {
  const { adjustedSalePrice, salePrice } = saleInformation
  return adjustedSalePrice || salePrice
}

export const getSiteAreaSfAndAcres = (siteArea: number, siteAreaUnit: string) => {
  let siteAreaSf = 0
  let siteAreaAcres = 0

  if (siteAreaUnit === 'sf') {
    siteAreaSf = siteArea
    siteAreaAcres = convertSfToAcres(siteArea)
  } else {
    siteAreaAcres = siteArea
    siteAreaSf = convertAcresToSf(siteArea)
  }
  return { siteAreaSf, siteAreaAcres }
}

export const getUnitOfComparisonText = (unitOfComparison: string) => {
  switch (unitOfComparison) {
    case UNIT_OF_COMPARISON_TYPES.SF:
      return 'square foot'
    case UNIT_OF_COMPARISON_TYPES.RESIDENTIAL_UNITS:
      return 'residential unit'
    case UNIT_OF_COMPARISON_TYPES.TOTAL_UNITS:
      return 'total unit'
    case UNIT_OF_COMPARISON_TYPES.APPROVED_BUILDABLE_UNITS:
      return 'approved buildable unit'
    case UNIT_OF_COMPARISON_TYPES.MAX_BUILDABLE_AREA:
      return 'max buildable area'
    case UNIT_OF_COMPARISON_TYPES.SITE_AREA_SF:
      return 'site area (SF)'
    case UNIT_OF_COMPARISON_TYPES.SITE_AREA_ACRES:
      return 'site area (Acres)'
    case UNIT_OF_COMPARISON_TYPES.LOT:
      return 'lot'
    default:
      return 'basis'
  }
}

export const getUnitOfComparisonTextPlural = (unitOfComparison: string) => {
  switch (unitOfComparison) {
    case UNIT_OF_COMPARISON_TYPES.SF:
      return 'square feet'
    case UNIT_OF_COMPARISON_TYPES.RESIDENTIAL_UNITS:
      return 'residential units'
    case UNIT_OF_COMPARISON_TYPES.TOTAL_UNITS:
      return 'total units'
    case UNIT_OF_COMPARISON_TYPES.APPROVED_BUILDABLE_UNITS:
      return 'approved buildable units'
    case UNIT_OF_COMPARISON_TYPES.MAX_BUILDABLE_AREA:
      return 'max buildable area'
    case UNIT_OF_COMPARISON_TYPES.SITE_AREA_SF:
      return 'site area (SF)'
    case UNIT_OF_COMPARISON_TYPES.SITE_AREA_ACRES:
      return 'site area (Acres)'
    case UNIT_OF_COMPARISON_TYPES.LOT:
      return 'lot'
    default:
      return 'basis'
  }
}

export const getCurrencyFormatter = (unitOfComparison: string) => {
  switch (unitOfComparison) {
    case UNIT_OF_COMPARISON_TYPES.SF:
    case UNIT_OF_COMPARISON_TYPES.MAX_BUILDABLE_AREA:
    case UNIT_OF_COMPARISON_TYPES.SITE_AREA_SF:
      return formatCurrencyFloat
    case UNIT_OF_COMPARISON_TYPES.RESIDENTIAL_UNITS:
    case UNIT_OF_COMPARISON_TYPES.TOTAL_UNITS:
    case UNIT_OF_COMPARISON_TYPES.APPROVED_BUILDABLE_UNITS:
    case UNIT_OF_COMPARISON_TYPES.SITE_AREA_ACRES:
    case UNIT_OF_COMPARISON_TYPES.LOT:
      return formatCurrencyInt
    default:
      return formatCurrencyInt
  }
}

export const getConcludedSaleValueLabel = (unitOfComparison: string) => {
  switch (unitOfComparison) {
    case UNIT_OF_COMPARISON_TYPES.SF:
      return 'Concluded Value Per SF'
    case UNIT_OF_COMPARISON_TYPES.MAX_BUILDABLE_AREA:
      return 'Concluded Value Per Max Buildable Area'
    case UNIT_OF_COMPARISON_TYPES.SITE_AREA_SF:
      return 'Concluded Value Per Site Area (SF)'
    case UNIT_OF_COMPARISON_TYPES.RESIDENTIAL_UNITS:
    case UNIT_OF_COMPARISON_TYPES.TOTAL_UNITS:
      return 'Concluded Value Per Unit'
    case UNIT_OF_COMPARISON_TYPES.APPROVED_BUILDABLE_UNITS:
      return 'Concluded Value Per Approved Buildable Unit'
    case UNIT_OF_COMPARISON_TYPES.SITE_AREA_ACRES:
      return 'Concluded Value Per Site Area (Acres)'
    case UNIT_OF_COMPARISON_TYPES.LOT:
      return 'Concluded Value Per Lot'
    default:
      return 'Concluded Value Per Basis'
  }
}

export const getUnitOfComparisonFormatter = (unitOfComparison: string) => {
  switch (unitOfComparison) {
    case UNIT_OF_COMPARISON_TYPES.SF:
    case UNIT_OF_COMPARISON_TYPES.MAX_BUILDABLE_AREA:
      return (value: number) => formatMaxDecimalFloat(value, 2)
    case UNIT_OF_COMPARISON_TYPES.SITE_AREA_SF:
    case UNIT_OF_COMPARISON_TYPES.SITE_AREA_ACRES:
      return (value: number) => formatMaxDecimalFloat(value, 5)
    case UNIT_OF_COMPARISON_TYPES.RESIDENTIAL_UNITS:
    case UNIT_OF_COMPARISON_TYPES.TOTAL_UNITS:
    case UNIT_OF_COMPARISON_TYPES.APPROVED_BUILDABLE_UNITS:
    case UNIT_OF_COMPARISON_TYPES.LOT:
    default:
      return formatInt
  }
}

export const getBasis = (unitOfComparison: string, propertyInformation: CompPropertyInformation) => {
  const {
    grossBuildingArea = 0,
    residentialUnits = 0,
    commercialUnits = 0,
    siteArea = 0,
    siteAreaUnit = 'sf',
    buildableUnits = 0,
    buildableArea = 0,
  } = propertyInformation
  const totalUnits = getTotalUnitCount(residentialUnits, commercialUnits)
  const { siteAreaSf, siteAreaAcres } = getSiteAreaSfAndAcres(siteArea, siteAreaUnit)
  switch (unitOfComparison) {
    case UNIT_OF_COMPARISON_TYPES.SF:
      return grossBuildingArea
    case UNIT_OF_COMPARISON_TYPES.MAX_BUILDABLE_AREA:
      return buildableArea
    case UNIT_OF_COMPARISON_TYPES.SITE_AREA_SF:
      return siteAreaSf
    case UNIT_OF_COMPARISON_TYPES.RESIDENTIAL_UNITS:
      return residentialUnits
    case UNIT_OF_COMPARISON_TYPES.TOTAL_UNITS:
      return totalUnits
    case UNIT_OF_COMPARISON_TYPES.APPROVED_BUILDABLE_UNITS:
      return buildableUnits
    case UNIT_OF_COMPARISON_TYPES.SITE_AREA_ACRES:
      return siteAreaAcres
    case UNIT_OF_COMPARISON_TYPES.LOT:
      return 1
    default:
      return 1
  }
}

export const getFormattedBasis = (unitOfComparison: string, propertyInformation: CompPropertyInformation) => {
  const {
    grossBuildingArea = 0,
    residentialUnits = 0,
    commercialUnits = 0,
    siteArea = 0,
    siteAreaUnit = 'sf',
    buildableUnits = 0,
    buildableArea = 0,
  } = propertyInformation
  const totalUnits = getTotalUnitCount(residentialUnits, commercialUnits)
  const { siteAreaSf, siteAreaAcres } = getSiteAreaSfAndAcres(siteArea, siteAreaUnit)
  let basis = 0
  switch (unitOfComparison) {
    case UNIT_OF_COMPARISON_TYPES.SF:
      basis = grossBuildingArea
      break
    case UNIT_OF_COMPARISON_TYPES.MAX_BUILDABLE_AREA:
      basis = buildableArea
      break
    case UNIT_OF_COMPARISON_TYPES.SITE_AREA_SF:
      basis = siteAreaSf
      break
    case UNIT_OF_COMPARISON_TYPES.RESIDENTIAL_UNITS:
      basis = residentialUnits
      break
    case UNIT_OF_COMPARISON_TYPES.TOTAL_UNITS:
      basis = totalUnits
      break
    case UNIT_OF_COMPARISON_TYPES.APPROVED_BUILDABLE_UNITS:
      basis = buildableUnits
      break
    case UNIT_OF_COMPARISON_TYPES.SITE_AREA_ACRES:
      basis = siteAreaAcres
      break
    case UNIT_OF_COMPARISON_TYPES.LOT:
      basis = 1
      break
    default:
      basis = 1
  }
  const formatter = getUnitOfComparisonFormatter(unitOfComparison)
  return formatter(basis)
}
