import React from 'react'
import { useForm } from 'react-final-form'

import * as Api from 'report/api'
import { useSelector } from 'client-shared/hooks/redux'
import { DEFAULT_ALERT_MESSAGES } from 'shared/constants/automation/messages'

import AutomationCTA from 'client-shared/components/AutomationCTA'

import { subjectAddressSelector } from '../selectors'

import { useAutomatedZoningIsEnabledForReport } from './tools'

type zone = {
  zone: string
  percentage: number
  commercialOverlay?: string
}
type regulation = {
  name: string
  required: string
}
type zoning = {
  sourceLink: string
  jurisdiction: string
  zones: zone[]
  permittedUses: string[]
  regulations: regulation[]
  requiredParkingSpaces: number
  floorAreaRatio: number
}

const Automation = () => {
  const form = useForm()
  const automatedZoningEnabled = useAutomatedZoningIsEnabledForReport()
  const subjectAddress = useSelector(subjectAddressSelector)

  const updateFormValuesWithAutomatedData = (zoningData: zoning) => {
    form.batch(() => {
      form.change('zones', zoningData.zones)
      if (zoningData.permittedUses.length > 0) {
        form.change('permittedUses', zoningData.permittedUses)
      }
      form.change('regulations', zoningData.regulations)
      form.change('requiredParkingSpaces', zoningData.requiredParkingSpaces)
      form.change('floorAreaRatio', zoningData.floorAreaRatio)
    })
  }

  const getZoningData = async () => {
    const zoningData: zoning = await Api.getZoningData(subjectAddress)

    updateFormValuesWithAutomatedData(zoningData)
    form.change('automationMetadata', {
      sourceName: 'Zoneomics',
      sourceLinks: [zoningData.sourceLink],
      updatedAt: new Date(),
    })
  }

  const onGetZoningDataError = () => {
    form.change('automationMetadata', {
      sourceName: 'Zoneomics',
      updatedAt: new Date(),
      isError: true,
    })
  }

  if (!automatedZoningEnabled) {
    return null
  }

  return (
    <AutomationCTA
      CTAMessage="Update some sections of this page using Zonemics automation."
      successMessage="Your automation ran successfully. Verify the Zoneomics data for accuracy."
      errorMessage={DEFAULT_ALERT_MESSAGES.ERROR}
      onAutomationRun={getZoningData}
      onAutomationError={onGetZoningDataError}
      setGlobalAutomationSavedAfterSuccessfulAutomation="zoningImport"
    />
  )
}

export default Automation
