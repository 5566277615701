import { max, mean, min, sum, uniq } from 'lodash'

import { RentTypes } from 'shared/constants/buildingComparables'
import { getAnnualIncreaseForRentType } from 'shared/report-calculations/income-approach/residential/helpers'
import ResidentialCalculations from 'shared/report-calculations/income-approach/residential/residential-calculations'

export const filterOutVacantUnitsWithZeroRent = unit => !(unit.isVacant && !unit.rent)

export const getResidentialUnitSummary = (units, showDevelopersForecast = false) => {
  const filteredUnits = units.filter(Boolean)
  const unitRents = showDevelopersForecast
    ? filteredUnits.map(unit => unit.rentForecast || 0)
    : filteredUnits.filter(filterOutVacantUnitsWithZeroRent).map(unit => unit.rent || 0)

  const hasVacantUnits = filteredUnits.some(unit => unit.isVacant)
  const hasRentStabilizedUnits = filteredUnits.some(unit => unit.rentType === RentTypes.RENT_STABILIZED)
  const hasRentControlledUnits = filteredUnits.some(unit => unit.rentType === RentTypes.RENT_CONTROLLED)

  return {
    noOfUnits: filteredUnits.length,
    minRent: min(unitRents),
    maxRent: max(unitRents),
    annualRent: sum(unitRents) * 12,
    averageRent: mean(unitRents),
    hasVacantUnits,
    hasRentStabilizedUnits,
    hasRentControlledUnits,
  }
}

export const getBuildingRentSummary = (rentRollUnits, increaseLookupForTypes) => {
  const uniqueRentTypes = uniq(rentRollUnits.map(unit => unit.rentType))

  const rentTypeSummary = uniqueRentTypes.map(rentType => {
    const annualRent = ResidentialCalculations.calculateTotalAnnualRentByRentType(rentType, rentRollUnits)
    const increase = getAnnualIncreaseForRentType(rentType, increaseLookupForTypes)
    const potentialResidentialIncome = ResidentialCalculations.calculateTotalAnnualResidentialIncome(
      rentRollUnits,
      [rentType],
      increaseLookupForTypes
    )

    return {
      rentType,
      annualRent,
      increase,
      pgi: potentialResidentialIncome,
    }
  })

  const totalPotentialResidentialIncome = ResidentialCalculations.calculateTotalAnnualResidentialIncome(
    rentRollUnits,
    uniqueRentTypes,
    increaseLookupForTypes
  )
  const totalAnnualRent = ResidentialCalculations.calculateTotalAnnualRent(rentRollUnits)
  const totalAverageRent = ResidentialCalculations.calculateTotalAverageRent(rentRollUnits)
  return {
    rentTypeSummary,
    totalRent: totalAnnualRent,
    totalAverageRent,
    totalPGI: totalPotentialResidentialIncome,
  }
}
