import { GeneratedText, gtLocked } from '../../../narrative/GeneratedText'

import { mapDTO, mapDataModel } from './mappings'

const generate = () => {
  const conversationDate = gtLocked('conversationDate', 'shortDateFormat', 'Conversation Date')
  const contactName = gtLocked('contactName', null, 'Contact Name')
  const companyName = gtLocked('companyName', null, 'Company Name')
  const propertyType = gtLocked('propertyType', null, 'Property Type')
  const capRateText = gtLocked('capRateText', null, 'Cap Rate Text')

  const gt = new GeneratedText()
  gt.t`On ${conversationDate}, we spoke with ${contactName}, the broker at ${companyName}, who
  indicated that cap rates for ${propertyType} properties comparable to the subject ${capRateText}`

  return gt.getParts()
}

export default { generate, mapDTO, mapDataModel }
