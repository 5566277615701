import { min, max, mean } from 'lodash'

import { RentTypes } from 'shared/constants/buildingComparables'

import { MARKET_BREAKDOWN_TYPES } from 'shared/constants/incomeApproach'
import { divide } from 'shared/utils/numberOperations'

export const getMarketBreakdown = (comps, marketConclusion) => {
  if (!marketConclusion || !comps.length) {
    // default
    return MARKET_BREAKDOWN_TYPES.allMarket
  }
  const numberOfMarketRateComps = comps.length
  const compRents = comps.map(comp => comp.rent)

  const upperRange = marketConclusion * 1.1
  const lowerRange = marketConclusion * 0.9

  let belowMarketConclusion = 0
  let aboveMarketConclusion = 0
  let withinMarketConclusion = 0
  compRents.forEach(rent => {
    if (rent < lowerRange) {
      belowMarketConclusion += 1
    } else if (rent > upperRange) {
      aboveMarketConclusion += 1
    } else {
      withinMarketConclusion += 1
    }
  })

  const percentageOfCompsWithinMarketRange = divide(withinMarketConclusion, numberOfMarketRateComps)
  const percentageOfCompsLowerThanMarketRange = divide(belowMarketConclusion, numberOfMarketRateComps)
  const percentageOfCompsHigherThanMarketRange = divide(aboveMarketConclusion, numberOfMarketRateComps)

  // If all units are within market
  if (percentageOfCompsWithinMarketRange === 1) {
    return MARKET_BREAKDOWN_TYPES.allMarket
    // If all units are below market
  }
  if (percentageOfCompsLowerThanMarketRange === 1) {
    return MARKET_BREAKDOWN_TYPES.allBelow
    // If all units are above market
  }
  if (percentageOfCompsHigherThanMarketRange === 1) {
    return MARKET_BREAKDOWN_TYPES.allAbove
    // If mix of units within, above, and below
  }
  if (
    percentageOfCompsLowerThanMarketRange > 0 &&
    percentageOfCompsWithinMarketRange > 0 &&
    percentageOfCompsHigherThanMarketRange > 0
  ) {
    return MARKET_BREAKDOWN_TYPES.mixAll
    // If mix of units within and above
  }
  if (percentageOfCompsHigherThanMarketRange > 0 && percentageOfCompsWithinMarketRange > 0) {
    return MARKET_BREAKDOWN_TYPES.mixMarketAndAbove
    // If mix of units within and below
  }
  if (percentageOfCompsLowerThanMarketRange > 0 && percentageOfCompsWithinMarketRange > 0) {
    return MARKET_BREAKDOWN_TYPES.mixMarketAndBelow
  }
  return MARKET_BREAKDOWN_TYPES.mixAll
}

export const getSubjectUnitsStats = (units, showDevelopersForecast) => {
  const hasMarketRateUnits = units.some(unit => unit.rentType === RentTypes.MARKET_RATE)

  const marketRateUnits = hasMarketRateUnits ? units.filter(unit => unit.rentType === RentTypes.MARKET_RATE) : units

  const nonVacantUnits = marketRateUnits.filter(unit => !(unit.isVacant && !unit.rent))

  const unitRents = showDevelopersForecast
    ? nonVacantUnits.map(unit => unit.rentForecast)
    : nonVacantUnits.map(unit => unit.rent)
  return {
    min: min(unitRents) || 0,
    max: max(unitRents) || 0,
    avg: unitRents.length ? mean(unitRents) : null,
  }
}
