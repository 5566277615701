import { GeneratedText, gtLocked } from '../../../narrative/GeneratedText'

import { mapDTO, mapDataModel } from './mappings'

const generate = () => {
  const gt = new GeneratedText()

  const remainingCostTypeGt = gtLocked('remainingCostType')
  const totalConstructionBudgetGt = gtLocked('totalConstructionBudget')
  const totalCostsToDateGt = gtLocked('totalCostsToDate')
  const totalCostsToCompleteGt = gtLocked('totalCostsToComplete')
  const asCompleteYear = gtLocked('asCompleteYear')

  gt.t`The following is the total and remaining ${remainingCostTypeGt} (summarized by Bowery), with a breakout of any 
  additional site cost and/or adjustments to the direct cost. This categorized breakout of the development costs 
  below is determined by Bowery, while the actual budget provided is located in the addenda. The subject will be 
  constructed in ${asCompleteYear} with a reported total development budget of ${totalConstructionBudgetGt}. Of 
  this amount, ${totalCostsToDateGt} has been spent on direct and indirect costs associated with approvals, and 
  foundation. Therefore, the remaining budget is ${totalCostsToCompleteGt} as illustrated:`

  return gt.getParts()
}

export default { generate, mapDTO, mapDataModel }
