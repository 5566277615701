import { EXPENSE_COMPARABLES_CATEGORIES } from '../constants/expenses/expenseComparables'

import { UTILITIES_EXPENSES_MODE } from '../constants/expenses'

import { ComparableExpensesRecords } from './comparableExpensesRecords/expensesRecords'
import { HistoricalExpensesRecords } from './historicalExpensesRecords/expensesRecords'

export const updateComparableExpensesByUtilitiesMode = ({
  fromMode,
  toMode,
  expenseCategories,
  comparableExpenses,
  propertyType,
  includeEGI = true,
  includeRETaxes = true,
  // eslint-disable-next-line no-unused-vars
  getResUnitsAndSqft = (record = {}, expenses = []) => {
    return {
      resUnits: record.res_units,
      squareFeet: record.sqft,
    }
  },
}) => {
  if (fromMode === toMode) {
    return {
      expenseCategories,
      comparableExpenses,
    }
  }

  const expenses = ComparableExpensesRecords.from({
    categories: expenseCategories,
    records: comparableExpenses,
    propertyType,
    utilitiesExpensesMode: toMode,
    includeEGI,
    includeRealEstateTaxes: includeRETaxes,
    getResUnitsAndSqft,
  })

  switch (fromMode) {
    case UTILITIES_EXPENSES_MODE.BROKEN_OUT:
      if (toMode === UTILITIES_EXPENSES_MODE.COMBINED_ELECTRICITY_AND_FUEL) {
        expenses
          .removeCategory(EXPENSE_COMPARABLES_CATEGORIES.electricity)
          .removeCategory(EXPENSE_COMPARABLES_CATEGORIES.fuel)
          .addCategory(EXPENSE_COMPARABLES_CATEGORIES.utilities)
          .mergeRemovedCategoriesIntoUtilities([
            EXPENSE_COMPARABLES_CATEGORIES.electricity,
            EXPENSE_COMPARABLES_CATEGORIES.fuel,
          ])
      } else {
        expenses
          .removeCategory(EXPENSE_COMPARABLES_CATEGORIES.electricity)
          .removeCategory(EXPENSE_COMPARABLES_CATEGORIES.fuel)
          .removeCategory(EXPENSE_COMPARABLES_CATEGORIES.waterAndSewer)
          .addCategory(EXPENSE_COMPARABLES_CATEGORIES.utilities)
          .mergeRemovedCategoriesIntoUtilities([
            EXPENSE_COMPARABLES_CATEGORIES.electricity,
            EXPENSE_COMPARABLES_CATEGORIES.fuel,
            EXPENSE_COMPARABLES_CATEGORIES.waterAndSewer,
          ])
      }
      break

    case UTILITIES_EXPENSES_MODE.COMBINED_ELECTRICITY_AND_FUEL:
      if (toMode === UTILITIES_EXPENSES_MODE.BROKEN_OUT) {
        expenses
          .removeCategory(EXPENSE_COMPARABLES_CATEGORIES.utilities)
          .addCategory(EXPENSE_COMPARABLES_CATEGORIES.fuel)
          .addCategory(EXPENSE_COMPARABLES_CATEGORIES.electricity)
          .splitUtilitiesTo([EXPENSE_COMPARABLES_CATEGORIES.electricity, EXPENSE_COMPARABLES_CATEGORIES.fuel])
      } else {
        expenses
          .removeCategory(EXPENSE_COMPARABLES_CATEGORIES.waterAndSewer)
          .mergeRemovedCategoriesIntoUtilities([EXPENSE_COMPARABLES_CATEGORIES.waterAndSewer])
      }
      break
    case UTILITIES_EXPENSES_MODE.COMBINED_ALL:
      if (toMode === UTILITIES_EXPENSES_MODE.COMBINED_ELECTRICITY_AND_FUEL) {
        expenses
          .addCategory(EXPENSE_COMPARABLES_CATEGORIES.waterAndSewer)
          .splitUtilitiesTo([EXPENSE_COMPARABLES_CATEGORIES.waterAndSewer])
      } else {
        expenses
          .addCategory(EXPENSE_COMPARABLES_CATEGORIES.electricity)
          .addCategory(EXPENSE_COMPARABLES_CATEGORIES.fuel)
          .addCategory(EXPENSE_COMPARABLES_CATEGORIES.waterAndSewer)
          .removeCategory(EXPENSE_COMPARABLES_CATEGORIES.utilities)
          .splitUtilitiesTo([
            EXPENSE_COMPARABLES_CATEGORIES.electricity,
            EXPENSE_COMPARABLES_CATEGORIES.fuel,
            EXPENSE_COMPARABLES_CATEGORIES.waterAndSewer,
          ])
      }
      break

    default:
      break
  }

  const { records, categories } = expenses.normalizeCategories()

  return {
    expenseComps: records,
    expenseCategories: categories,
  }
}

export const updateHistoricalExpensesByUtilitiesMode = (fromMode, toMode, expenseCategories, expenseHistory) => {
  if (fromMode === toMode) {
    return {
      expenseCategories,
      expenseHistory,
    }
  }
  const expenses = HistoricalExpensesRecords.from({
    categories: expenseCategories,
    records: expenseHistory,
  })

  switch (fromMode) {
    case UTILITIES_EXPENSES_MODE.BROKEN_OUT:
      if (toMode === UTILITIES_EXPENSES_MODE.COMBINED_ELECTRICITY_AND_FUEL) {
        expenses
          .removeCategory(EXPENSE_COMPARABLES_CATEGORIES.electricity)
          .removeCategory(EXPENSE_COMPARABLES_CATEGORIES.fuel)
          .addCategory(EXPENSE_COMPARABLES_CATEGORIES.utilities)
          .mergeRemovedCategoriesInto(
            [EXPENSE_COMPARABLES_CATEGORIES.electricity, EXPENSE_COMPARABLES_CATEGORIES.fuel],
            EXPENSE_COMPARABLES_CATEGORIES.utilities
          )
      } else {
        expenses
          .removeCategory(EXPENSE_COMPARABLES_CATEGORIES.electricity)
          .removeCategory(EXPENSE_COMPARABLES_CATEGORIES.fuel)
          .removeCategory(EXPENSE_COMPARABLES_CATEGORIES.waterAndSewer)
          .addCategory(EXPENSE_COMPARABLES_CATEGORIES.utilities)
          .mergeRemovedCategoriesInto(
            [
              EXPENSE_COMPARABLES_CATEGORIES.electricity,
              EXPENSE_COMPARABLES_CATEGORIES.fuel,
              EXPENSE_COMPARABLES_CATEGORIES.waterAndSewer,
            ],
            EXPENSE_COMPARABLES_CATEGORIES.utilities
          )
      }
      break

    case UTILITIES_EXPENSES_MODE.COMBINED_ELECTRICITY_AND_FUEL:
      if (toMode === UTILITIES_EXPENSES_MODE.BROKEN_OUT) {
        expenses
          .removeCategory(EXPENSE_COMPARABLES_CATEGORIES.utilities)
          .addCategory(EXPENSE_COMPARABLES_CATEGORIES.fuel)
          .addCategory(EXPENSE_COMPARABLES_CATEGORIES.electricity)
      } else {
        expenses
          .removeCategory(EXPENSE_COMPARABLES_CATEGORIES.waterAndSewer)
          .mergeRemovedCategoriesInto(
            [
              EXPENSE_COMPARABLES_CATEGORIES.electricity,
              EXPENSE_COMPARABLES_CATEGORIES.fuel,
              EXPENSE_COMPARABLES_CATEGORIES.waterAndSewer,
            ],
            EXPENSE_COMPARABLES_CATEGORIES.utilities
          )
      }
      break
    case UTILITIES_EXPENSES_MODE.COMBINED_ALL:
      if (toMode === UTILITIES_EXPENSES_MODE.COMBINED_ELECTRICITY_AND_FUEL) {
        expenses
          .addCategory(EXPENSE_COMPARABLES_CATEGORIES.waterAndSewer)
          .mergeRemovedCategoriesInto(
            [EXPENSE_COMPARABLES_CATEGORIES.electricity, EXPENSE_COMPARABLES_CATEGORIES.fuel],
            EXPENSE_COMPARABLES_CATEGORIES.utilities
          )
      } else {
        expenses
          .addCategory(EXPENSE_COMPARABLES_CATEGORIES.electricity)
          .addCategory(EXPENSE_COMPARABLES_CATEGORIES.fuel)
          .addCategory(EXPENSE_COMPARABLES_CATEGORIES.waterAndSewer)
          .removeCategory(EXPENSE_COMPARABLES_CATEGORIES.utilities)
      }
      break

    default:
      break
  }
  const { records, categories } = expenses.normalizeCategories()
  return {
    expenseHistory: records,
    expenseCategories: categories,
  }
}
