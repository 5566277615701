import React from 'react'
import PropTypes from 'prop-types'
import { Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions } from '@material-ui/core'

import Button from '../Button'

export default class NewCompsSelectionModal extends React.PureComponent {
  static propTypes = {
    items: PropTypes.array.isRequired,
    updateItems: PropTypes.func.isRequired,
    isCustomSelection: PropTypes.bool.isRequired,
  }

  state = {
    isOpen: false,
  }

  componentDidUpdate(prevProps) {
    const { items, isCustomSelection } = this.props
    const isNewSelection = isCustomSelection && items.length && items !== prevProps.items
    if (isNewSelection) {
      this.setState({ isOpen: true })
    }
  }

  close = () => {
    this.setState({ isOpen: false })
  }

  chooseNewComps = () => {
    const { updateItems, items } = this.props
    updateItems(items)
    this.close()
  }

  render() {
    const { isOpen } = this.state
    const { items } = this.props
    const selectedItemsCount = items.length

    return (
      <Dialog open={isOpen} onClose={this.close}>
        <DialogTitle id="form-dialog-title">This polygon includes {selectedItemsCount} units.</DialogTitle>
        <DialogContent>
          <DialogContentText>Would you like to select them?</DialogContentText>{' '}
        </DialogContent>
        <DialogActions>
          <Button onClick={this.close} data-qa="no-btn">
            No
          </Button>
          <Button onClick={this.chooseNewComps} variant="contained" color="primary" data-qa="yes.btn">
            Yes
          </Button>
        </DialogActions>
      </Dialog>
    )
  }
}
