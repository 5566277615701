export const HelpMenuItems = {
  HELP_CENTER: 'Help Center',
  WHAT_IS_NEW: `What's New`,
  KEYBOARD_SHORTCUTS: `Keyboard Shortcuts`,
}
export const UserMenuItems = { PROFILE: 'Profile', ORGANIZATION: 'Organization', LOG_OUT: 'Log Out' }
export const HELP_CENTER_LINK =
  'https://docs.google.com/document/d/1zqkV6kCxowF3xWm_Hsm9kZppjOpdF2PlXdxyYq1Mvgg/edit#heading=h.ddwwiye33bi1 '
export const RELEASE_NOTES_LINK = 'https://docs.google.com/document/d/1dF7mtmCeQ2MMWIL8jBerWTcRtvDeZcRMz-nlpcRRkZ0'
export const PRODUCT_BOARD_LINK =
  'https://bowery.productboard.com/portal/2-bowery-roadmap/tabs/f9bb026a-ab3f-4c1d-9005-01f532a2210e'
