import React from 'react'
import { get } from 'lodash'

import { formatExpenseId } from 'shared/helpers/incomeApproach/expenses/helpers'

import { required } from '../../../shared/utils/validation'
import { NOT_COMPUTABLE_VALUE, UTILITIES_EXPENSES_MODE } from '../../../../../shared/constants/expenses'

import { EXPENSE_COMPARABLES_CATEGORIES } from '../../../../../shared/constants/expenses/expenseComparables'

export const defineReservedNames = utilitiesExpensesMode => {
  switch (utilitiesExpensesMode) {
    case UTILITIES_EXPENSES_MODE.BROKEN_OUT:
      return [EXPENSE_COMPARABLES_CATEGORIES.utilities]
    case UTILITIES_EXPENSES_MODE.COMBINED_ELECTRICITY_AND_FUEL:
      return [EXPENSE_COMPARABLES_CATEGORIES.electricity, EXPENSE_COMPARABLES_CATEGORIES.fuel]
    case UTILITIES_EXPENSES_MODE.COMBINED_ALL:
      return [
        EXPENSE_COMPARABLES_CATEGORIES.electricity,
        EXPENSE_COMPARABLES_CATEGORIES.fuel,
        EXPENSE_COMPARABLES_CATEGORIES.waterAndSewer,
      ]
    default:
      return []
  }
}

export const validateName = (value, expenseCategories = [], reservedNames = []) => {
  const isEmptyValue = required(value)
  if (isEmptyValue) {
    return 'Category name is required'
  }

  const isReservedName = reservedNames.includes(formatExpenseId(value))
  const alreadyExists =
    expenseCategories.filter(expense => formatExpenseId(expense.name) === formatExpenseId(value)).length > 0
  if (alreadyExists || isReservedName) {
    return 'Expense category already exists'
  }

  return null
}

export const rowManagedRenderer = React.memo(props => {
  const { data, value } = props
  const { rowDef } = data
  const inputProps = get(rowDef, 'editor.params.inputProps')
  if (rowDef?.render) {
    return rowDef?.render(value, inputProps)
  }

  if (props.valueFormatted === NOT_COMPUTABLE_VALUE || value === NOT_COMPUTABLE_VALUE) {
    return NOT_COMPUTABLE_VALUE
  }
  if (props.valueFormatted === null) {
    return ''
  }
  return rowDef?.formatValueForDisplay(props.valueFormatted || value, inputProps)
})
