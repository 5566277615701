import { STORAGE_INCOME_PATH } from 'shared/constants/report/keysAndDataPaths'

import { objectToKeyValuePairs } from '../../../../../shared/utils/ui/checkboxHelper'

export const DATA_PATH = STORAGE_INCOME_PATH
export const HEADING = 'Storage'
export const WARNING_TEXT = `You've indicated that the subject has no storage room.`

export const RESIDENTIAL_LOSS_PERCENTAGE = 0.03
export const STORAGE_VC_LOSS_PERCENTAGE = 0.1

export const StorageVCLossTypes = {
  RESIDENTIAL: 'Residential',
  STORAGE_VC: 'Storage V/C',
}
export const VC_LOSS_OPTIONS = objectToKeyValuePairs(StorageVCLossTypes)

export const VC_LOSS_OPTIONS_COMMERCIAL = objectToKeyValuePairs({ STORAGE_VC: 'Storage V/C' })

export const StorageIncomeTypes = {
  ANNUAL: 'Annual',
  MONTHLY_PER_UNIT: 'Monthly Per Unit',
}
export const INCOME_OPTIONS = objectToKeyValuePairs(StorageIncomeTypes)

export const InPlaceOrForecastTypes = {
  OWNER_REPORTED: 'Owner Reported',
  FORECAST: 'Forecast',
}
export const IN_PLACE_OR_FORECAST_OPTIONS = objectToKeyValuePairs(InPlaceOrForecastTypes)
