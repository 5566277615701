import { get, isNil } from 'lodash'
import ACASCalculations from 'shared/report-calculations/income-approach/acas/acas-calculations'
import IncomeApproachCalculations from 'shared/report-calculations/income-approach/income-approach-calculations'
import { LOSS_ITEM_VALUE_TYPE } from 'shared/constants/acas'
import { mapValuesToDisplay } from 'shared/report-calculations/income-approach/acas/helpers'
import { getUnitCount } from 'shared/helpers/basisOfComparison'
import SalesApproachCalculations from 'shared/report-calculations/sales-approach/sales-approach-calculations'
import { getBasis } from 'shared/report-calculations/sales-approach/helpers'

export const recalculateValueForAsStabilizedLossItems = (_value, _fieldName, allValues) => {
  const asCompleteMonthsOfRentLoss = get(allValues, 'asCompleteMonthsOfRentLoss')
  const asStabilizedCommercialRentLossItems = get(allValues, 'asStabilizedCommercialRentLossItems') || []
  const asStabilizedLossItems = get(allValues, 'asStabilizedLossItems') || []
  const asStabilizedMonthsOfRentLoss = get(allValues, 'asStabilizedMonthsOfRentLoss')
  const asStabilizedResRentLossItems = get(allValues, 'asStabilizedResRentLossItems') || []
  const dateOfValuation = get(allValues, 'dateOfValuation')

  const finalDatesOfValue = ACASCalculations.calculateDatesOfFinalValue({
    dateOfValuation,
    asCompleteMonthsOfRentLoss,
    asStabilizedMonthsOfRentLoss,
  })

  return {
    asStabilizedLossItems: asStabilizedLossItems.map(row => calculateEachRow(row)),
    asStabilizedResRentLossItems: asStabilizedResRentLossItems.map(row => calculateEachRow(row)),
    dateOfFinalValueAsComplete: finalDatesOfValue.dateOfFinalValueAsComplete,
    dateOfFinalValueAsStabilized: finalDatesOfValue.dateOfFinalValueAsStabilized,
    asStabilizedCommercialRentLossItems: asStabilizedCommercialRentLossItems.map(row => calculateEachRow(row)),
  }
}
export const recalculateValueForAsCompleteLossItems = (value, fieldName, allValues) => {
  const asCompleteCommercialRentLossItems = get(allValues, 'asCompleteCommercialRentLossItems') || []
  const asCompleteLossItems = get(allValues, 'asCompleteLossItems') || []
  const asCompleteMonthsOfRentLoss = get(allValues, 'asCompleteMonthsOfRentLoss')
  const asCompleteResRentLossItems = get(allValues, 'asCompleteResRentLossItems') || []
  const asStabilizedCommercialRentLossItems = get(allValues, 'asStabilizedCommercialRentLossItems') || []
  const asStabilizedLossItems = get(allValues, 'asStabilizedLossItems') || []
  const asStabilizedMonthsOfRentLoss = get(allValues, 'asStabilizedMonthsOfRentLoss')
  const asStabilizedResRentLossItems = get(allValues, 'asStabilizedResRentLossItems') || []
  const dateOfValuation = get(allValues, 'dateOfValuation')

  const finalDatesOfValue = ACASCalculations.calculateDatesOfFinalValue({
    dateOfValuation,
    asCompleteMonthsOfRentLoss,
    asStabilizedMonthsOfRentLoss,
  })

  return {
    asCompleteCommercialRentLossItems: asCompleteCommercialRentLossItems.map(row => calculateEachRow(row)),
    asCompleteLossItems: asCompleteLossItems.map(row => calculateEachRow(row)),
    asCompleteResRentLossItems: asCompleteResRentLossItems.map(row => calculateEachRow(row)),
    asStabilizedCommercialRentLossItems: asStabilizedCommercialRentLossItems.map(row => calculateEachRow(row)),
    asStabilizedLossItems: asStabilizedLossItems.map(row => calculateEachRow(row)),
    asStabilizedResRentLossItems: asStabilizedResRentLossItems.map(row => calculateEachRow(row)),
    dateOfFinalValueAsComplete: finalDatesOfValue.dateOfFinalValueAsComplete,
    dateOfFinalValueAsStabilized: finalDatesOfValue.dateOfFinalValueAsStabilized,
  }
}

const calculateEachRow = row => {
  if (row.type !== LOSS_ITEM_VALUE_TYPE.COMPUTED_VALUE) {
    return row
  }

  const months = row.months
  const monthlyLoss = row.monthlyLoss || 0
  let amount = 0

  if (!isNil(months) && !isNaN(months)) {
    amount = months * monthlyLoss
  }
  return {
    ...row,
    amount,
    monthlyLoss: row.monthlyLoss,
    name: row.name,
  }
}

export const getSalesApproachCapitalizationConclusion = ({
  saleValueConclusion,
  unitOfComparison,
  propertyInformation,
}) => {
  if (saleValueConclusion && propertyInformation) {
    const capConclusionVal = SalesApproachCalculations.calculateSalePriceFromBasis(
      saleValueConclusion,
      unitOfComparison,
      propertyInformation
    )

    return capConclusionVal ? capConclusionVal : 0
  }
  return null
}

export const recalculateFinalValuesAndDates = (_value, _fieldName, allValues) => {
  const asCompleteCommercialRentLossItems = get(allValues, 'asCompleteCommercialRentLossItems') || []
  const asCompleteLossItems = get(allValues, 'asCompleteLossItems') || []
  const asCompleteMonthsOfRentLoss = get(allValues, 'asCompleteMonthsOfRentLoss')
  const asCompleteNpvAdjustments = get(allValues, 'asCompleteNpvAdjustments') || []
  const asCompleteResRentLossItems = get(allValues, 'asCompleteResRentLossItems') || []
  const asStabilizedCommercialRentLossItems = get(allValues, 'asStabilizedCommercialRentLossItems') || []
  const asStabilizedLossItems = get(allValues, 'asStabilizedLossItems') || []
  const asStabilizedMonthsOfRentLoss = get(allValues, 'asStabilizedMonthsOfRentLoss')
  const asStabilizedNpvAdjustments = get(allValues, 'asStabilizedNpvAdjustments') || []
  const asStabilizedResRentLossItems = get(allValues, 'asStabilizedResRentLossItems') || []
  const dateOfValuation = get(allValues, 'dateOfValuation')
  const npvAdjustments = get(allValues, 'npvAdjustments') || []
  const landDeductions = get(allValues, 'landDeductions') || []
  const roundingFactor = get(allValues, 'roundingFactor') || 0
  const totalUnitCount = get(allValues, 'totalUnits')
  const residentialUnitCount = get(allValues, 'residentialUnits', []).length
  const valueAsComplete = get(allValues, 'valueAsComplete') || false
  const valueAsStabilized = get(allValues, 'valueAsStabilized') || false
  const squareFootage = get(allValues, 'squareFootage') || 0
  const propertyInformation = get(allValues, 'subjectPropertyInformation', {})

  const includeAsCompleteDiscountRate = get(allValues, 'includeAsCompleteDiscountRate', false)
  const asCompleteDiscountPercentage = get(allValues, 'asCompleteDiscountPercentage', 0)
  const asCompleteMonthsDiscounted = get(allValues, 'asCompleteMonthsDiscounted', 0)
  const asCompleteDiscountRate = includeAsCompleteDiscountRate
    ? IncomeApproachCalculations.calculateDiscountRate(asCompleteDiscountPercentage, asCompleteMonthsDiscounted)
    : 0

  const includeAsStabilizedDiscountRate = get(allValues, 'includeAsStabilizedDiscountRate', false)
  const asStabilizedDiscountPercentage = get(allValues, 'asStabilizedDiscountPercentage', 0)
  const asStabilizedMonthsDiscounted = get(allValues, 'asStabilizedMonthsDiscounted', 0)
  const asStabilizedDiscountRate = includeAsStabilizedDiscountRate
    ? IncomeApproachCalculations.calculateDiscountRate(asStabilizedDiscountPercentage, asStabilizedMonthsDiscounted)
    : 0
  let unitCount = totalUnitCount
  let indicatedValue = 0

  const inSalesApproach = get(allValues, 'incomeApproachData', false)
  if (inSalesApproach) {
    const saleValueConclusion = get(allValues, 'saleValueConclusion')
    const unitOfComparison = get(allValues, 'unitOfComparison')
    unitCount = getUnitCount(unitOfComparison, residentialUnitCount, totalUnitCount - residentialUnitCount)
    indicatedValue = getSalesApproachCapitalizationConclusion({
      saleValueConclusion,
      unitOfComparison,
      propertyInformation,
    })
  } else {
    const netOperatingIncome = get(allValues, 'netOperatingIncome.total') || 0
    const concludedCapRate = get(allValues, 'concludedCapRate') || 0

    indicatedValue = IncomeApproachCalculations.calculateIndicatedValue(netOperatingIncome, concludedCapRate)
  }

  const finalDatesOfValue = ACASCalculations.calculateDatesOfFinalValue({
    dateOfValuation,
    asCompleteMonthsOfRentLoss,
    asStabilizedMonthsOfRentLoss,
  })
  const finalValuesOfConclusion = ACASCalculations.calculateFinalValues({
    indicatedValue,
    npvAdjustments,
    asStabilizedNpvAdjustments,
    asCompleteNpvAdjustments,
    asStabilizedResRentLossItems,
    asCompleteResRentLossItems,
    asStabilizedCommercialRentLossItems,
    asCompleteCommercialRentLossItems,
    asStabilizedLossItems,
    asCompleteLossItems,
    roundingFactor,
    squareFootage,
    unitCount,
    includeValueAsComplete: valueAsComplete,
    includeValueAsStabilized: valueAsStabilized,
    asCompleteDiscountRate,
    asStabilizedDiscountRate,
  })
  const finalValuesOfConclusionDisplay = mapValuesToDisplay(finalValuesOfConclusion)

  const basisOfComparison = getBasis(get(allValues, 'unitOfComparison'), get(allValues, 'subjectPropertyInformation'))
  const landValue = basisOfComparison * get(allValues, 'saleValueConclusion')
  const asIsLandValue = SalesApproachCalculations.calculateAsIsLandValue(landValue, landDeductions)

  return {
    ...finalDatesOfValue,
    ...finalValuesOfConclusionDisplay,
    landValue,
    asIsLandValue,
  }
}
