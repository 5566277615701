import React from 'react'

import { Button, Stack, Typography } from '@mui/material'
import ErrorOutline from '@mui/icons-material/ErrorOutline'
import Warning from '@mui/icons-material/Warning'
import CloudDownload from '@mui/icons-material/CloudDownload'
import { get } from 'lodash'

import { ExportStatuses } from 'shared/constants/export'
import { shortDateTimeFormat } from 'client-shared/utils/dateHelper'

import ExportItemProgressBar from '../ExportItemProgressBar'
import ExpansionExportItem from '../ExpansionExportItem'

const COLUMNS = [
  {
    name: 'createdAt',
    title: 'Date',
    width: '25%',
  },
  {
    name: 'progress',
    title: 'Progress',
    width: '25%',
  },
  {
    name: 'status',
    title: 'Status',
    width: '25%',
  },
  {
    name: 'actions',
    title: 'Actions',
    width: '25%',
  },
]

const ExportPhotoGridView = ({ exportItems, lastCompletedExportItemIndex }) => {
  const getExportTableCellContent = React.useCallback(({ column, exportItem, _index, showDownloadButton }) => {
    switch (column.name) {
      case 'createdAt':
        return (
          <Typography data-qa="created-at" variant="body2">
            {shortDateTimeFormat(get(exportItem, 'createdAt'))}
          </Typography>
        )
      case 'progress':
        return (
          <ExportItemProgressBar
            exportItem={exportItem}
            progressBarProps={{ showPercent: false, strokeWidth: 4, trailWidth: 6 }}
          />
        )

      case 'actions':
        return (
          showDownloadButton && (
            <Button
              color="inherit"
              data-qa="download-btn"
              disabled={!exportItem.isLastItemCompleted}
              endIcon={<CloudDownload />}
              onClick={() => window.open(exportItem.downloadUrl)}
              variant="contained"
            >
              Download
            </Button>
          )
        )
      case 'status': {
        const status = exportItem.status === ExportStatuses.CRITICAL_ERROR ? ExportStatuses.ERROR : exportItem.status
        return (
          <Stack alignItems="center" direction="row" spacing={1}>
            <Typography data-qa="status">{status}</Typography>
            {status === ExportStatuses.ERROR && <ErrorOutline />}
            {status === ExportStatuses.WARNING && <Warning />}
          </Stack>
        )
      }
      default:
        return get(exportItem, column.name)
    }
  }, [])

  return (
    <>
      <Stack alignItems="center" direction="row" sx={{ pl: 2, pr: 5, width: 1 }}>
        {COLUMNS.map(column => {
          const { title, width } = column
          return (
            <Stack key={title} boxSizing="border-box" sx={{ width }}>
              {title}
            </Stack>
          )
        })}
      </Stack>

      {exportItems.map((exportItem, index) => (
        <ExpansionExportItem
          columns={COLUMNS}
          exportItem={exportItem}
          getCellContent={getExportTableCellContent}
          index={index}
          key={exportItem._id}
          showDownloadButton={lastCompletedExportItemIndex === index}
        />
      ))}
    </>
  )
}

export default ExportPhotoGridView
