import React from 'react'
import PropTypes from 'prop-types'
import { get } from 'lodash'

import { withStyles } from '@material-ui/core'

import { generateCommercialReimbursementDiscussion } from '../../../../../../../../shared/utils/textGeneration/incomeApproach/commercialReimbursement'
import { EXPENSE_CATEGORIES } from '../../../../../../../../shared/constants/expenses'

import { DollarAmountReimbursementTable } from './DollarAmountReimbursementTable'
import UnitsTableForPercentageReimbursement from './UnitsTableForPercentageReimbursement'
import styles from './styles'

class PercentOfAppraiserForecastReimbursementTable extends DollarAmountReimbursementTable {
  static propTypes = {
    classes: PropTypes.object.isRequired,
    reimbursement: PropTypes.object.isRequired,
    expenseCategories: PropTypes.arrayOf(PropTypes.object).isRequired,
    pathPrefix: PropTypes.string.isRequired,
    form: PropTypes.object.isRequired,
    onUpdate: PropTypes.func.isRequired,
    onDelete: PropTypes.func.isRequired,
  }

  componentDidMount() {
    const { form, pathPrefix } = this.props
    const { expenseForecast, squareFootage, numberOfResidentialUnits, totalRoomCount } = form.values

    get(form.values, `${pathPrefix}.unitReimbursements`, []).forEach((reimbursement, index) => {
      const unitReimbursementPath = `${pathPrefix}.unitReimbursements[${index}]`
      form.mutators.updatePercentOfAppraiserForecast({
        unitReimbursementPath,
        form,
        expenseForecast,
        squareFootage,
        numberOfResidentialUnits,
        totalRoomCount,
      })
    })
    form.reset(form.getState().values)
  }

  onFieldChange = (fieldName, unitReimbursementPath) => {
    const { form } = this.props
    const { expenseForecast, squareFootage, numberOfResidentialUnits, totalRoomCount } = form.values

    form.mutators.updatePercentOfAppraiserForecast({
      unitReimbursementPath,
      form,
      expenseForecast,
      squareFootage,
      numberOfResidentialUnits,
      totalRoomCount,
    })
  }

  renderUnitReimbursementTable() {
    const { pathPrefix, reimbursement, classes } = this.props
    const hasAdminFee = get(reimbursement, 'knownInformation.adminFee')
    return (
      <UnitsTableForPercentageReimbursement
        pathPrefix={pathPrefix}
        reimbursement={reimbursement}
        hasAdminFee={hasAdminFee}
        baseValueFieldName="grossAppraiserForecast"
        baseValueColumnTitle="Appraiser's Forecast (GROSS)"
        baseValueColumnTooltip="These values come from Income > Expense Forecast"
        percentageFieldName="percentOfGrossAppraiserForecast"
        percentageColumnTitle="% of Total"
        percentageCellClassName="percentOfGrossAppraiserForecast"
        onFieldChange={this.onFieldChange}
        getFieldValue={this.getFieldValue}
        classes={classes}
      />
    )
  }

  generateDiscussion = values => {
    const { reimbursement } = this.props

    const expenseType = (EXPENSE_CATEGORIES[reimbursement.expenseType] || reimbursement.expenseType).toLowerCase()

    return generateCommercialReimbursementDiscussion(reimbursement, expenseType)
  }
}

export default withStyles(styles)(PercentOfAppraiserForecastReimbursementTable)
