import React from 'react'
import { PropTypes } from 'prop-types'
import MenuItem from '@material-ui/core/MenuItem'
import { withStyles } from '@material-ui/core/styles'
import { kebabCase, startsWith, endsWith } from 'lodash'

import BrokenImageOutlinedIcon from '@mui/icons-material/BrokenImageOutlined'

import Portal from '../Portal'
import Autosuggest from '../Autosuggest'

import { SIDE_BAR_ICONS } from '../../layout/constants'

import { PORTAL_Z_INDEX } from './constants'

const styles = {
  autosuggestFormControl: {
    display: 'inline',
  },
  autosuggest: {
    borderBottom: '1px solid white',
  },
  autosuggestInput: {
    fontSize: '3em',
    color: 'white',
  },
  autosuggestContainer: {
    width: '100%',
    height: 200,
    '& ul': {
      '& > li[aria-selected="true"]': {
        background: 'rgba(7, 54, 85, 0.7)',
      },
    },
  },
  autosuggestMenuContainer: {
    display: 'flex',
    fontSize: '2em',
    color: 'white',
  },
  tabIcon: {
    height: 27,
    width: 27,
    minHeight: 27,
    minWidth: 27,
    paddingRight: 7,
  },
}

class JumpAheadAutosuggest extends React.PureComponent {
  static propTypes = {
    forms: PropTypes.arrayOf(
      PropTypes.shape({
        name: PropTypes.string.isRequired,
        path: PropTypes.string.isRequired,
        route: PropTypes.string.isRequired,
        section: PropTypes.string.isRequired,
      })
    ),
    closeSearchScreen: PropTypes.func.isRequired,
  }

  static contextTypes = {
    router: PropTypes.shape({
      history: PropTypes.shape({
        push: PropTypes.func.isRequired,
      }).isRequired,
    }).isRequired,
  }

  state = {
    value: '',
  }

  get inputProps() {
    const { classes } = this.props
    return { classes: { root: classes.autosuggestInput }, disableUnderline: true }
  }

  onChange = event => {
    this.setState({
      value: event.target.value,
    })
  }

  renderSuggestionsContainer = options => {
    const { classes } = this.props
    const { children, containerProps } = options
    return (
      <div>
        <Portal zIndex={PORTAL_Z_INDEX}>
          <div {...containerProps} className={classes.autosuggestContainer}>
            {children}
          </div>
        </Portal>
      </div>
    )
  }

  getSectionIcon = section => {
    const { classes } = this.props
    const icon = kebabCase(section)
    const Icon = icon in SIDE_BAR_ICONS ? SIDE_BAR_ICONS[icon] : BrokenImageOutlinedIcon
    return <Icon className={classes.tabIcon} color="#fff" fontSize="large" />
  }

  getSuggestionPart = part => {
    if (part.highlight) {
      return <strong>{part.text}</strong>
    }
    if (part.text === ' ') {
      return <span>&nbsp;</span>
    }
    return (
      <React.Fragment>
        {startsWith(part.text, ' ') && <span>&nbsp;</span>}
        {part.text.trim()}
        {endsWith(part.text, ' ') && <span>&nbsp;</span>}
      </React.Fragment>
    )
  }

  renderMenuItem = (value, isHighlighted, parts) => {
    const { classes, forms } = this.props
    const form = forms.find(form => value === `${form.path}/${form.name}`)

    return (
      <MenuItem selected={isHighlighted} component="div" data-value={`${value}`}>
        <div className={classes.autosuggestMenuContainer}>
          {this.getSectionIcon(form.section)}
          {parts.map((part, index) => (
            <React.Fragment key={index}>{this.getSuggestionPart(part)}</React.Fragment>
          ))}
        </div>
      </MenuItem>
    )
  }

  getSuggestionValue = form => `${form.path}/${form.name}`

  onSuggestionSelected = (event, { suggestion, ...other }) => {
    const { closeSearchScreen } = this.props
    const { history } = this.context.router
    history.push(suggestion.route)
    closeSearchScreen()
  }

  render() {
    const { value } = this.state
    const { classes, forms } = this.props
    return (
      <Autosuggest
        autoFocus
        value={value}
        selectOnBlur={false}
        suggestions={forms}
        onChange={this.onChange}
        inputProps={this.inputProps}
        renderMenuItem={this.renderMenuItem}
        classes={{ container: classes.autosuggest }}
        getSuggestionValue={this.getSuggestionValue}
        onSuggestionSelected={this.onSuggestionSelected}
        renderSuggestionsContainer={this.renderSuggestionsContainer}
        textFieldProps={{ classes: { root: classes.autosuggestFormControl } }}
      />
    )
  }
}

export default withStyles(styles)(JumpAheadAutosuggest)
