import React from 'react'
import PropTypes from 'prop-types'
import { Grid } from '@material-ui/core'
import { get, invert } from 'lodash'

import { STATE_NAMES } from 'shared/constants/states'

import 'leaflet/dist/leaflet.css'
import 'react-leaflet-markercluster/dist/styles.min.css'
import CartoDBConfig from '../../../../shared/utils/cartodbConfig'

import { SubjectAndCompsMapWizard } from '../../../../shared/components/MapWizard'

import { getAddress } from '../../../../shared/utils/geography'

import { Labels } from './SalesCompsMapConstants'

export default class SalesCompsMap extends React.PureComponent {
  static propTypes = {
    city: PropTypes.string.isRequired,
    state: PropTypes.string.isRequired,
    zip: PropTypes.string.isRequired,
    subject: PropTypes.shape({
      coords: PropTypes.shape({
        latitude: PropTypes.number.isRequired,
        longitude: PropTypes.number.isRequired,
      }).isRequired,
      streetAddress: PropTypes.string.isRequired,
      streetName: PropTypes.string.isRequired,
    }).isRequired,
    comps: PropTypes.arrayOf(
      PropTypes.shape({
        address: PropTypes.shape({
          city: PropTypes.string.isRequired,
          state: PropTypes.string.isRequired,
          streetAddress: PropTypes.string.isRequired,
          postalCode: PropTypes.string.isRequired,
          coords: PropTypes.shape({
            latitude: PropTypes.number.isRequired,
            longitude: PropTypes.number.isRequired,
          }).isRequired,
        }).isRequired,
      })
    ).isRequired,
  }

  getMapComps(comps) {
    const mapComps = []
    comps.forEach(saleComp => {
      const latitude = get(saleComp, 'address.coords.latitude', null)
      const longitude = get(saleComp, 'address.coords.longitude', null)
      if (latitude !== null && longitude !== null) {
        const address = getAddress(
          saleComp.dataProtectionCompliantAddress,
          saleComp.address.city,
          saleComp.address.state,
          saleComp.address.postalCode
        )

        mapComps.push({
          coords: [saleComp.address.coords.latitude, saleComp.address.coords.longitude],
          address,
        })
      } else {
        //TODO: report error to user
      }
    })
    return mapComps
  }

  render() {
    const { city, state, zip, subject, comps } = this.props

    const streetAddress = get(subject, 'streetAddress')
    const subjectState = invert(STATE_NAMES)[state] || state
    const address = { addressOne: streetAddress, addressTwo: `${city}, ${subjectState} ${zip}` }

    return (
      <div style={{ padding: 16 }}>
        {/* fix for horizontal scroll */}
        <Grid container wrap="nowrap" direction="column">
          <Grid item>
            <SubjectAndCompsMapWizard
              name="mapUrl"
              label={Labels.SALES_COMPARABLES_MAP}
              clearAvailable
              mapOptions={{
                baseMap: CartoDBConfig.baseMap,
                isZoomOn: true,
                displayCenterButton: true,
                maxZoom: CartoDBConfig.subjectAndComps.maxZoom,
                initialZoom: CartoDBConfig.subjectAndComps.initialZoom,
                defaultLabelOffset: CartoDBConfig.subjectAndComps.defaultLabelOffset,
                width: CartoDBConfig.subjectAndComps.width,
                height: CartoDBConfig.subjectAndComps.height,
                initialCoordinates: {
                  lat: subject.coords.latitude,
                  lng: subject.coords.longitude,
                },
              }}
              subject={{
                coords: [subject.coords.latitude, subject.coords.longitude],
                ...address,
              }}
              comps={this.getMapComps(comps)}
            />
          </Grid>
        </Grid>
      </div>
    )
  }
}
