import React from 'react'
import ReactDOM from 'react-dom'
import PropTypes from 'prop-types'

export default class Portal extends React.Component {
  static propTypes = {
    children: PropTypes.any,
    zIndex: PropTypes.number,
    width: PropTypes.number,
  }

  static defaultProps = {
    zIndex: 1,
  }

  portalRoot = null
  positionNode = null
  wrapperNode = null

  componentWillUpdate() {
    setTimeout(() => this.updateWrapperPosition())
  }

  componentWillMount() {
    this.wrapperNode = document.createElement('div')
    window.addEventListener('resize', this.resize)
  }

  componentDidMount() {
    this.portalRoot = document.getElementById('portal-root')
    this.updateWrapperPosition()
    this.portalRoot.appendChild(this.wrapperNode)
  }

  componentWillUnmount() {
    this.portalRoot.removeChild(this.wrapperNode)
    window.removeEventListener('resize', this.resize)
  }

  resize = () => this.updateWrapperPosition()

  initPositionNode = positionNode => {
    this.positionNode = positionNode
  }

  updateWrapperPosition() {
    const { zIndex, width } = this.props

    if (!this.positionNode) {
      return
    }

    const position = this.positionNode.getBoundingClientRect()
    const offsetTop = position.top + window.pageYOffset
    const offsetLeft = position.left + window.pageXOffset

    this.wrapperNode.style.position = 'absolute'
    this.wrapperNode.style.top = offsetTop + 'px'
    this.wrapperNode.style.left = offsetLeft + 'px'
    this.wrapperNode.style.zIndex = zIndex

    if (width) {
      this.wrapperNode.style.width = width + 'px'
    }
  }

  render() {
    return <div ref={this.initPositionNode}>{ReactDOM.createPortal(this.props.children, this.wrapperNode)}</div>
  }
}
