import React, { createRef } from 'react'
import { getAuthorizationHeader } from 'core/api'
import { compose } from 'recompose'
import { CompPlexEvents, CompPlexExpenseRecordsEvents } from 'client-shared/constants/compPlex'

import { withMicrofrontend } from '../../../../shared/hooks/useMicrofrontend'

export class ComparableExpensesPanel extends React.PureComponent {
  constructor(props) {
    super(props)
    this.compPlexRef = createRef()
  }
  componentDidMount() {
    const { compPlexLoaded, errorNotification } = this.props
    if (!compPlexLoaded) {
      errorNotification('Failed to load expense comps service. Some features will not be available.')
    }

    if (this.compPlexRef.current) {
      this.compPlexRef.current.addEventListener(
        CompPlexExpenseRecordsEvents.EXPENSE_RECORD_ADDED,
        this.handleComparableAdded
      )
      this.compPlexRef.current.addEventListener(
        CompPlexExpenseRecordsEvents.EXPENSE_RECORD_UPDATED,
        this.handleExpenseCompPlexEdit
      )
      this.compPlexRef.current.addEventListener(
        CompPlexExpenseRecordsEvents.EXPENSE_RECORD_CANCELED,
        this.closeCompModal
      )
      this.compPlexRef.current.addEventListener(CompPlexEvents.ERROR, this.handleError)
    }
  }

  componentWillUnmount() {
    if (this.compPlexRef.current) {
      this.compPlexRef.current.removeEventListener(
        CompPlexExpenseRecordsEvents.EXPENSE_RECORD_ADDED,
        this.handleComparableAdded
      )
      this.compPlexRef.current.removeEventListener(
        CompPlexExpenseRecordsEvents.EXPENSE_RECORD_UPDATED,
        this.handleExpenseCompPlexEdit
      )
      this.compPlexRef.current.removeEventListener(
        CompPlexExpenseRecordsEvents.EXPENSE_RECORD_CANCELED,
        this.closeCompModal
      )
      this.compPlexRef.current.removeEventListener(CompPlexEvents.ERROR, this.handleError)
    }
  }

  handleComparableAdded = event => {
    this.props.onComparableAdd(event)
  }
  handleExpenseCompPlexEdit = event => {
    this.props.onComparableEdit(event)
  }

  closeCompModal = () => {
    this.props.closeCompModal()
  }

  handleError = event => {
    const { error } = event.detail
    this.props.errorNotification(error.message || error)
  }

  render() {
    const { isCompModalOpen, compModalData, authenticatedUser } = this.props

    return (
      <comp-plex-expenses
        ref={this.compPlexRef}
        auth-user={JSON.stringify(authenticatedUser)}
        webapp-auth-header={getAuthorizationHeader().Authorization}
        webapp-api-url={global.env.apiUrl}
        is-modal-open={JSON.stringify(isCompModalOpen)}
        comp-modal-data={JSON.stringify(compModalData)}
      />
    )
  }
}

export default compose(withMicrofrontend('compplex', 'compPlexLoaded'))(ComparableExpensesPanel)
