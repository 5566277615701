import { VALUE_CONCLUSION_TYPES } from '../../../report/constants/settings'

import { ADJUSTMENT_TYPES } from './constants'

export const adjustmentTypeOptionsForValueType = valueConclusionType => {
  const adjustmentTypeOptions = [
    {
      label: ADJUSTMENT_TYPES.NPV,
      value: ADJUSTMENT_TYPES.NPV,
    },
  ]

  if (
    valueConclusionType === VALUE_CONCLUSION_TYPES.AS_STABILIZED ||
    valueConclusionType === VALUE_CONCLUSION_TYPES.AS_COMPLETE
  ) {
    adjustmentTypeOptions.push({
      label: ADJUSTMENT_TYPES.AS_STABILIZED,
      value: ADJUSTMENT_TYPES.AS_STABILIZED,
    })
  }

  if (valueConclusionType === VALUE_CONCLUSION_TYPES.AS_COMPLETE) {
    adjustmentTypeOptions.push({
      label: ADJUSTMENT_TYPES.AS_COMPLETE,
      value: ADJUSTMENT_TYPES.AS_COMPLETE,
    })
  }

  return adjustmentTypeOptions
}

export const removeByIndex = (array, index) => {
  return [...array.slice(0, index), ...array.slice(index + 1)]
}
