import React from 'react'
import { isNil } from 'lodash'
import { Button, Grid, Typography } from '@material-ui/core'

import { GEOGRAPHY_OPTIONS, WIZARD_STEPS } from '../../../../../../shared/constants/properties'

import SearchResults from '../../../../../../shared/components/PropertyWizard/SearchResults/SearchResults'

import { Logo } from './helpers/Logo'
import { NewYorkCityResultsTable } from './helpers/NewYorkCityResultsTable'
import { NewJerseyResultsTable } from './helpers/NewJerseyResultsTable'
import { OtherGeographyResultsTable } from './helpers/OtherGeographyResultsTable'

export const DRMSearchResults = ({
  openDetails,
  onSubmit,
  properties,
  selectedPropertyIndex,
  openPreviousStep,
  ...restProps
}) => {
  const tableProps = {
    openDetails,
    onSubmit,
    selectedPropertyIndex,
  }

  const openAdvancedSearch = () => {
    openPreviousStep({ step: WIZARD_STEPS.ADVANCED_SEARCH })
  }

  const handleClick = () => {
    const property = properties[selectedPropertyIndex]
    delete property.taxInfoLink
    onSubmit(property)
  }
  const noResultsFound = properties.length === 0
  const hasExistingComp = properties.some(property => !!property.id)

  const footer = noResultsFound ? (
    <Grid container justify="space-between" alignItems="center">
      <Grid item>
        <Button color="primary" variant="outlined" onClick={openPreviousStep} data-qa="cancel-btn">
          Cancel
        </Button>
      </Grid>
      <Grid item>
        <Button color="primary" variant="contained" onClick={openAdvancedSearch} data-qa="advanced-search-btn">
          Advanced Search
        </Button>
      </Grid>
    </Grid>
  ) : (
    <Grid container justify="space-between" alignItems="center">
      {hasExistingComp && (
        <Grid item xs={6}>
          <Logo></Logo>
          <Typography variant="caption" inline>
            = Comp exists in Bowery database
          </Typography>
        </Grid>
      )}
      <Grid item xs container spacing={16} justify="flex-end">
        <Grid item>
          <Button
            color="primary"
            variant="contained"
            disabled={isNil(selectedPropertyIndex)}
            onClick={handleClick}
            data-qa="create-new-btn"
          >
            Create New
          </Button>
        </Grid>
        <Grid item>
          <Button color="primary" variant="outlined" onClick={openPreviousStep}>
            Cancel
          </Button>
        </Grid>
      </Grid>
    </Grid>
  )

  return (
    <SearchResults
      {...restProps}
      onSubmit={onSubmit}
      properties={properties}
      selectedPropertyIndex={selectedPropertyIndex}
      openPreviousStep={openPreviousStep}
      footer={footer}
      GeographyTable={({ locationIdentifier, ...restProps }) => {
        switch (locationIdentifier) {
          case GEOGRAPHY_OPTIONS.NY:
            return <NewYorkCityResultsTable {...tableProps} {...restProps} />
          case GEOGRAPHY_OPTIONS.NJ:
            return <NewJerseyResultsTable {...tableProps} {...restProps} />
          default:
            return <OtherGeographyResultsTable {...tableProps} {...restProps} />
        }
      }}
    />
  )
}
