import { get, isNil } from 'lodash'
import React from 'react'
import PropTypes from 'prop-types'

import { connect } from 'react-redux'
import { ReactSVG } from 'react-svg'

import { Grid, Typography } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'

import Icon from '@material-ui/core/Icon'

import { formatCurrencyFloat, toPercentageString } from '../../../../shared/utils/numberFormatters'
import { FormInputLabel } from '../../../../shared/components'
import Tooltip from '../../../../shared/components/Tooltip'
import incomeReferenceIconPath from '../../../images/income_reference_icon.svg'

const iconSize = 42
const styles = theme => {
  return {
    root: {
      '& label': {
        'text-transform': 'uppercase',
      },
    },
    label: {
      fontSize: 10,
      margin: '4px 0',
      padding: 0,
    },
    value: {
      fontFamily: theme.typography.fontFamily,
      color: theme.palette.text.primary,
      fontSize: theme.typography.pxToRem(16),
      lineHeight: '1.1875em',
      minWidth: 112,
    },
    fillerIconContainer: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'left',
    },
    fillerIcon: {
      fontSize: 24,
      color: theme.palette.text.primary,
    },
    iconContainer: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'left',
      minWidth: 50,
    },
    icon: {
      width: iconSize,
      height: iconSize,
      fill: 'white',
    },
  }
}

class SalesCompsIncomeApproachConclusion extends React.PureComponent {
  static propTypes = {
    concludedCapRate: PropTypes.number,
    incomeApproachValue: PropTypes.object.isRequired,
  }

  render() {
    const { classes, concludedCapRate, incomeApproachValue } = this.props

    const fillerIcon = (
      <span className={classes.fillerIconContainer}>
        <Icon className={classes.fillerIcon}>remove</Icon>
      </span>
    )

    const capRate = !isNil(concludedCapRate) ? toPercentageString(concludedCapRate) : fillerIcon
    const incomeTotal = !isNil(incomeApproachValue.total) ? formatCurrencyFloat(incomeApproachValue.total) : fillerIcon
    const incomeUnit = !isNil(incomeApproachValue.unit) ? formatCurrencyFloat(incomeApproachValue.unit) : fillerIcon
    const incomePsf = !isNil(incomeApproachValue.psf) ? formatCurrencyFloat(incomeApproachValue.psf) : fillerIcon

    return (
      <Grid container className={classes.root}>
        <Grid item lg={1}>
          <div className={classes.iconContainer}>
            <Tooltip placement="top" text="Income Approach Conclusion">
              <ReactSVG
                src={incomeReferenceIconPath}
                beforeInjection={svg => {
                  svg.classList.add(classes.icon)
                }}
              />
            </Tooltip>
          </div>
        </Grid>
        <Grid item lg={2}>
          <FormInputLabel className={classes.label} label="Cap Rate" />
          <Typography className={classes.value} style={{ minWidth: 60 }} data-qa="cap-rate">
            {capRate}
          </Typography>
        </Grid>
        <Grid item lg={3}>
          <FormInputLabel className={classes.label} label="Income Value" />
          <Typography className={classes.value} data-qa="income-total">
            {incomeTotal}
          </Typography>
        </Grid>
        <Grid item lg={3}>
          <FormInputLabel className={classes.label} label="Per Unit" />
          <Typography className={classes.value} data-qa="income-unit">
            {incomeUnit}
          </Typography>
        </Grid>
        <Grid item lg={2}>
          <FormInputLabel className={classes.label} label="Per SF" />
          <Typography className={classes.value} data-qa="income-psf">
            {incomePsf}
          </Typography>
        </Grid>
      </Grid>
    )
  }
}

const mapStateToProps = state => {
  const formData = get(state, 'report.reportData.salesApproach.salesCompsSearch', {})
  const { incomeApproachValue = {} } = formData
  const concludedCapRate = get(state, 'report.reportData.incomeApproach.capRateConclusion.concludedCapRate')
  return {
    incomeApproachValue,
    concludedCapRate,
  }
}
const mapDispatchToProps = {}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(SalesCompsIncomeApproachConclusion))
