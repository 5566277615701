/* eslint-disable camelcase */
import { get } from 'lodash'
import filterFP from 'lodash/fp/filter'
import flowFP from 'lodash/fp/flow'
import groupByFP from 'lodash/fp/groupBy'
import arrayToSentence from 'array-to-sentence'

import { LeaseStatusTypes, OCCUPIED_UNIT_TYPES } from 'shared/constants/report/incomeApproach/residential'
import { toPercents } from 'shared/utils/numberOperations'

type FormValues = {
  residentialUnits: { leaseStatus: string; rentType: string }[]
  valueAsStabilized: boolean
  propertyType: string
}

type Report = {
  residential: {
    units: { leaseStatus: string; rentType: string }[]
  }
  valueAsStabilized: boolean
  property_information: {
    type: string
  }
}

type NarrativeValues = {
  occupancyRate: string
  occupancyRateIs0: boolean
  vacantUnitSentence: string
  byRentTypeSentence: string | null
  propertyType: string
}

const getNumeralUnitSentence = (quantity: number) => (quantity > 1 ? 'residential units' : 'residential unit')

const getVacantUnitsSentence = (vacantUnits: any[]) => {
  if (vacantUnits.length === 0) {
    return 'and'
  }

  const quantity = vacantUnits.length

  return `with ${vacantUnits.length} vacant ${getNumeralUnitSentence(quantity)}, but`
}

const getByRentTypeSentence = (unitsByRentType: any, valueAsStabilized: boolean) => {
  const rentTypes = Object.keys(unitsByRentType)

  const sentenceParts = rentTypes.map(rentType => {
    const quantity = unitsByRentType[rentType].length
    return `${quantity} ${rentType} ${getNumeralUnitSentence(quantity)}`
  })
  const sentence = arrayToSentence(sentenceParts).toLowerCase()

  if (!sentence) {
    return null
  }

  return `${valueAsStabilized ? 'will contain' : 'contains'} ${sentence}.`
}

const mapData = (formValues: FormValues): NarrativeValues => {
  const { residentialUnits, valueAsStabilized, propertyType } = formValues

  const occupiedUnitsCount = residentialUnits.filter(unit => OCCUPIED_UNIT_TYPES.includes(unit.leaseStatus)).length
  const occupancyRate = occupiedUnitsCount / residentialUnits.length
  const formattedOccupancyRate = `${toPercents(occupancyRate, 0)}%`

  const vacantUnits = residentialUnits.filter(unit => unit.leaseStatus === LeaseStatusTypes.VACANT)
  const unitsByRentType = flowFP([filterFP('rentType'), groupByFP('rentType')])(residentialUnits)

  const vacantUnitSentence = getVacantUnitsSentence(vacantUnits)
  const byRentTypeSentence = getByRentTypeSentence(unitsByRentType, valueAsStabilized)

  return {
    occupancyRate: formattedOccupancyRate,
    occupancyRateIs0: occupancyRate === 0 || Number.isNaN(occupancyRate),
    byRentTypeSentence,
    vacantUnitSentence,
    propertyType,
  }
}

export const mapDTO = (formValues: FormValues): NarrativeValues => {
  return mapData(formValues)
}

export const mapDataModel = (report: Report): NarrativeValues => {
  const valueAsStabilized = get(report, 'valueAsStabilized')
  const residentialUnits = get(report, 'residential.units', [])
  const propertyType = get(report, 'property_information.type')

  const formValues = {
    residentialUnits,
    valueAsStabilized,
    propertyType,
  }

  return mapData(formValues)
}
