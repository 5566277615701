import React from 'react'
import { lowerCase, capitalize } from 'lodash'
import { Button, Box, Dialog, DialogActions, DialogContent, IconButton, Stack, Typography } from '@mui/material'
import { Form } from 'react-final-form'
import CloseIcon from '@mui/icons-material/Close'

import { narrativeFormatters } from 'client-shared/utils/narrative'
import { DropDown, Text } from 'client-shared/components'

import { required } from 'client-shared/utils/validation'

import { SingleSelectChipField } from 'client-shared/components/SelectChip/SingleSelectChipComponent'
import { MultiSelectChipField } from 'client-shared/components/SelectChip/MultiSelectChipComponent'
import { AutosuggestField as Autosuggest } from 'client-shared/components/Autosuggest'

interface ChipFormModalProps {
  onSubmit: (chip: FormData) => void
  chipDataPathOptions: string[]
  form: {
    open: boolean
    onArchive: (chip: FormData) => void
    onClose: () => void
    initialValues?: FormData
    errorMessage?: string
    tags: string[]
  }
}

interface FormData {
  id?: string
  displayName: string
  dataPath: string
  groupName: string
  tags: string[]
  formatter: string
}

const formatterItems = [{ label: 'None', value: 'none' }].concat(
  Object.keys(narrativeFormatters).map(key => {
    return { label: capitalize(lowerCase(key)), value: key }
  })
)

const ChipFormModal = (props: ChipFormModalProps) => {
  const { open, onArchive, onClose, initialValues, tags } = props.form
  const isUpdateMode = !!initialValues

  const handleSubmit = (data: FormData) => {
    try {
      // filter out formatter if it's none
      if (data.formatter === 'none') {
        data.formatter = ''
      }

      props.onSubmit(data)
    } catch (error) {
      console.error('Error adding chip', error)
    }
  }

  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth="md" data-qa="chip-form">
      <Box position="relative">
        <IconButton
          color="inherit"
          onClick={onClose}
          aria-label="close"
          style={{ position: 'absolute', top: 8, right: 8 }}
        >
          <CloseIcon />
        </IconButton>
        <Form
          onSubmit={handleSubmit}
          initialValues={initialValues || { formatter: 'none', tags: [], isArchived: false }}
          render={({ values, handleSubmit, invalid }) => {
            return (
              <>
                <DialogContent dividers>
                  <Stack direction="column" spacing={2}>
                    <Stack direction="column" spacing={1}>
                      <Typography variant="h6">{isUpdateMode ? 'Update a Chip' : 'Register a Chip'}</Typography>
                      <Typography variant="body1">
                        Fill in the required information below to {isUpdateMode ? 'update' : 'register'} a chip to be
                        used in generic subsections.
                      </Typography>
                      {props.form.errorMessage && (
                        <Typography variant="subtitle1" color="error">
                          {props.form.errorMessage}
                        </Typography>
                      )}
                    </Stack>
                    <Stack direction="column" spacing={0}>
                      <Stack direction="column" spacing={1}>
                        <Typography variant="subtitle1">* Data Path (required)</Typography>
                        <Typography variant="body2">Add a data path that will be registered as a chip.</Typography>
                        <Autosuggest
                          validate={required}
                          selectOnBlur={false}
                          displayedSuggestionsCount={10}
                          label="Data Path"
                          name="dataPath"
                          suggestions={props.chipDataPathOptions}
                          textFieldProps={{ disabled: isUpdateMode }}
                          shrink={null}
                        />
                      </Stack>
                      <Stack direction="column" spacing={1}>
                        <Typography variant="subtitle1">* Human Readable Name (required)</Typography>
                        <Typography variant="body2">
                          What would you name the data path that would make sense to appraisers?
                        </Typography>
                        <Text name="displayName" label="Human Readable Name" shrink={null} validate={required} />
                      </Stack>
                    </Stack>
                    <Stack direction="column" spacing={1}>
                      <Typography variant="subtitle1">Group</Typography>
                      <Typography variant="subtitle2">* Group 1 (Required)</Typography>
                      <Stack direction="column" spacing={2}>
                        <Typography variant="body2">
                          Place the data path into a group (this would be visible when surfacing chips).
                        </Typography>
                        <SingleSelectChipField
                          name="groupName"
                          label="Select one existing group or create a new one"
                          options={tags}
                          validate={required}
                          displayError
                        />
                      </Stack>
                    </Stack>
                    <Stack direction="column" spacing={1}>
                      <Typography variant="subtitle2">Additional Groups (optional)</Typography>
                      <Stack direction="column" spacing={2}>
                        <Typography variant="body2">You can add multiple additional groups.</Typography>
                        <MultiSelectChipField
                          name="tags"
                          label="Select an existing group or create a new one"
                          options={tags}
                        />
                      </Stack>
                    </Stack>
                    <Stack direction="column" spacing={1}>
                      <Typography variant="subtitle1">* Formatter (required)</Typography>
                      <Typography variant="body2">Select a format to style the value of the data path.</Typography>
                      <DropDown
                        name="formatter"
                        label="Formatter"
                        shrink={null}
                        items={formatterItems}
                        validate={required}
                      />
                    </Stack>
                  </Stack>
                </DialogContent>
                <Box
                  sx={{
                    position: 'sticky',
                    bottom: 0,
                    zIndex: 1000,
                    backgroundColor: 'white',
                    borderTop: '1px solid rgba(0, 0, 0, 0.12)',
                  }}
                >
                  <DialogActions>
                    {isUpdateMode && (
                      <Button
                        variant="outlined"
                        onClick={() => {
                          onArchive(values)
                        }}
                        data-qa="chip-form-archive"
                      >
                        Archive
                      </Button>
                    )}
                    <Box sx={{ marginLeft: 'auto' }}>
                      <Button variant="outlined" onClick={onClose}>
                        Cancel
                      </Button>
                      <Button
                        variant="contained"
                        color="primary"
                        type="submit"
                        onClick={handleSubmit}
                        disabled={invalid}
                        style={{ marginLeft: '8px' }}
                        data-qa="chip-form-submit"
                      >
                        {isUpdateMode ? 'Save' : 'Create'}
                      </Button>
                    </Box>
                  </DialogActions>
                </Box>
              </>
            )
          }}
        />
      </Box>
    </Dialog>
  )
}

export default ChipFormModal
