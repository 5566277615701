import { GeneratedText } from '../../../../narrative/GeneratedText'

export const BASE_COST_CALCULATION_TABLE_SUMMARY = 'Our estimate considers the following information from MVS:'

const generate = () => {
  const gt = new GeneratedText()

  gt.t`${BASE_COST_CALCULATION_TABLE_SUMMARY}`

  return gt.getParts()
}

export default { generate }
