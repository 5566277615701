import React from 'react'

export interface ChipArchiveModalText {
  getTitle: (chip: any) => React.ReactNode
  body: string
  buttonText: string
}

export const textValueUndefined: ChipArchiveModalText = {
  getTitle: () => {
    return <>An unexpected error occurred.</>
  },
  body: 'The chip is not defined.',
  buttonText: 'Close',
}

export const textValueRestore: ChipArchiveModalText = {
  getTitle: chip => {
    return (
      <>
        Are you sure you want to restore <strong>{chip && chip.displayName}</strong>?
      </>
    )
  },
  body: 'Restoring the chip will make it accessible to appraisers in their future workflow.',
  buttonText: 'Restore',
}

export const textValueArchive: ChipArchiveModalText = {
  getTitle: chip => {
    return (
      <>
        Are you sure you want to archive <strong>{chip && chip.displayName}</strong>?
      </>
    )
  },
  body: 'Archiving the chip will make this inaccessible to appraisers in their future workflow, but continue to be viewable in finished reports.',
  buttonText: 'Archive',
}
