import React, { Component, useEffect, useRef, useState } from 'react'
import { Field } from 'react-final-form'
import { Map, ZoomControl, TileLayer as BaseMap } from 'react-leaflet'
import { Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle } from '@material-ui/core'

import './markerLabel.css'
import PropTypes from 'prop-types'

import { Alert } from '@mui/material'

import { HERE_HYBRID_BASEMAP_CONFIG, MAP_SIZE } from './constants'

import ConnectedIconLabel from './ConnectedIconLabel'
import wrapMap from './wrapMap'

class MaterialSubjectMapWizardErrorBoundary extends Component {
  state = { error: null }

  static getDerivedStateFromError(error) {
    return { error }
  }

  render() {
    const { error } = this.state
    const { children } = this.props

    if (error) {
      return (
        <Alert sx={{ margin: 2 }} severity="error">
          Something went wrong with Subject Map. Reach out to support team for help.
        </Alert>
      )
    }

    return children
  }
}

const propTypesMaterial = {
  isZoomOn: PropTypes.bool.isRequired,
  isImageSaving: PropTypes.bool.isRequired,
  isModalOpen: PropTypes.bool.isRequired,

  captureMap: PropTypes.func.isRequired,
  closeMapWizard: PropTypes.func.isRequired,
  cartoClient: PropTypes.object.isRequired,

  classes: PropTypes.object.isRequired,
  label: PropTypes.string,
  id: PropTypes.string.isRequired,
}

const propTypesMapOptions = {
  mapOptions: PropTypes.shape({
    zoomDelta: PropTypes.number.isRequired,
    zoomSnap: PropTypes.number.isRequired,
    wheelPxPerZoomLevel: PropTypes.number.isRequired,
    maxZoom: PropTypes.number.isRequired,
    initialZoom: PropTypes.number.isRequired,
    initialCoordinates: PropTypes.shape({
      lat: PropTypes.number.isRequired,
      lng: PropTypes.number.isRequired,
    }),
    baseMap: PropTypes.string.isRequired,
    marker: PropTypes.shape({
      coords: PropTypes.arrayOf(PropTypes.number).isRequired,
      labelContent: PropTypes.shape({
        addressOne: PropTypes.string.isRequired,
        addressTwo: PropTypes.string.isRequired,
      }).isRequired,
    }).isRequired,
  }).isRequired,
}

const delayedImgRefetch = event => {
  setTimeout(() => {
    /*
     * We only need to force a refetch in cases when an element doesn't have a `leaflet-tile-loaded`
     * class (indicating that the image was successfully fetched), or if a tile was unloaded from
     * the viewport (its `src` would be replaced with an SVG placeholder that starts with `data:`).
     * */
    if (!event.target?.classList.contains('leaflet-tile-loaded') && event.target?.src?.startsWith('http')) {
      // eslint-disable-next-line no-self-assign
      event.target.src = event.target.src
    }
  }, 1000)
}

const hereApiKey = process.env.REACT_APP_HERE_API_KEY

const MaterialSubjectMapWizard = props => {
  const {
    isZoomOn,
    isImageSaving,
    isModalOpen,

    captureMap,
    closeMapWizard,
    label,
    classes,
    id,
    mapOptions: {
      initialCoordinates,
      initialZoom,
      maxZoom,
      baseMap,
      zoomDelta,
      zoomSnap,
      wheelPxPerZoomLevel,
      marker: { coords, labelContent },
    },
  } = props

  const [refRendered, setRefRendered] = useState(false)
  const mapRef = useRef(null)
  const mutationObserverRef = useRef(null)
  const forceResetFailedTilesRef = useRef(null)

  const useSatelliteBasemap = hereApiKey && !!hereApiKey.trim().length

  const BaseMapProps = {
    attribution: '',
    url: useSatelliteBasemap ? `${HERE_HYBRID_BASEMAP_CONFIG.baseMap}?apiKey=${hereApiKey}` : baseMap,
    ...(useSatelliteBasemap && { subdomains: HERE_HYBRID_BASEMAP_CONFIG.subdomains }),
  }

  const altMapMarkerColor = useSatelliteBasemap ? '#EA4336' : null

  const MapZoomProps = {
    zoom: initialZoom,
    zoomControl: false,
    zoomDelta,
    zoomSnap,
    wheelPxPerZoomLevel,
    maxZoom: useSatelliteBasemap ? HERE_HYBRID_BASEMAP_CONFIG.subject.maxZoom : maxZoom,
    ...(useSatelliteBasemap && { minZoom: HERE_HYBRID_BASEMAP_CONFIG.subject.minZoom }),
  }

  useEffect(() => {
    if (!useSatelliteBasemap) {
      return
    }

    // Waits till `Dialog` component renders an actual map
    if (isModalOpen && !refRendered) {
      return
    }

    // Cleanup on `Dialog` close
    if (!isModalOpen) {
      mutationObserverRef.current?.disconnect()
      mutationObserverRef.current = null
      return
    }

    mutationObserverRef.current = new MutationObserver(() => {
      clearTimeout(forceResetFailedTilesRef.current)
      forceResetFailedTilesRef.current = setTimeout(() => {
        const tilesToReset = document.querySelectorAll('img.leaflet-tile:not(.leaflet-tile-loaded)')

        tilesToReset.forEach(tile => {
          // eslint-disable-next-line no-self-assign
          tile.src = tile.src
          tile.addEventListener('error', delayedImgRefetch)
          tile.addEventListener('load', event => event.target.removeEventListener('error', delayedImgRefetch))
        })
      }, 350)
    })

    const tilesLayers = mapRef.current?.container?.querySelectorAll('.leaflet-layer') || []
    if (!tilesLayers.length) {
      return
    }

    tilesLayers.forEach(layer => {
      mutationObserverRef.current.observe(layer, { childList: true, subtree: true })
    })

    return () => {
      mutationObserverRef.current?.disconnect()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refRendered])

  return (
    <div>
      <Dialog maxWidth="md" open={isModalOpen} onClose={closeMapWizard} data-qa="subject-map">
        <DialogTitle>{label}</DialogTitle>
        <DialogContent>
          <Map
            ref={el => {
              mapRef.current = el
              setRefRendered(!!el)
            }}
            id={id}
            className={id}
            style={MAP_SIZE}
            center={initialCoordinates}
            data-qa="subject-map"
            {...MapZoomProps}
          >
            <BaseMap {...BaseMapProps} />
            {isZoomOn && <ZoomControl />}

            <ConnectedIconLabel
              position={coords}
              labelOffset={0.001}
              icon={props.getSubjectIcon(coords, altMapMarkerColor)}
              connectorColor={altMapMarkerColor}
              label={{
                icon: props.getSubjectLabel(labelContent),
                draggable: true,
              }}
            />
          </Map>
        </DialogContent>
        <DialogActions>
          <Button onClick={closeMapWizard} color="primary" data-qa="subject-map-close-btn">
            Close
          </Button>
          <div className={classes.wrapper}>
            <Button
              color="primary"
              disabled={isImageSaving}
              onClick={captureMap}
              data-qa="subject-map-capture-screen-btn"
            >
              Capture Screen
            </Button>
            {isImageSaving && <CircularProgress size={24} className={classes.buttonProgress} />}
          </div>
        </DialogActions>
      </Dialog>
    </div>
  )
}

MaterialSubjectMapWizard.propTypes = {
  ...propTypesMaterial,
  ...propTypesMapOptions,
}

const MaterialSubjectMapWizardWrapped = wrapMap(MaterialSubjectMapWizard)

export function SubjectMapWizard({ name, ...otherProps }) {
  return (
    <MaterialSubjectMapWizardErrorBoundary>
      <Field
        name={name}
        render={({ input }) => (
          <MaterialSubjectMapWizardWrapped
            {...otherProps}
            id={name}
            value={input.value}
            handleChange={input.onChange}
            onFocus={input.onFocus}
            onBlur={input.onBlur}
          />
        )}
      />
    </MaterialSubjectMapWizardErrorBoundary>
  )
}

SubjectMapWizard.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string,
  ...propTypesMapOptions,
}
