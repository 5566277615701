import { GeneratedText, gtLocked } from '../../../narrative/GeneratedText'

import { mapDTO, mapDataModel } from './mappings'

const generate = () => {
  const gt = new GeneratedText()

  const asIsEntrepreneurialIncentive = gtLocked('asIsEntrepreneurialIncentive')
  const asCompleteEntrepreneurialIncentive = gtLocked('asCompleteEntrepreneurialIncentive')
  const asStabilizedEntrepreneurialIncentive = gtLocked('asStabilizedEntrepreneurialIncentive')

  gt.t`Using the build-up method to estimate our As Is Value we add the land deducting out any demolition costs and
  adding construction costs spent to date along with a ${asIsEntrepreneurialIncentive} entrepreneurial incentive to
  arrive at the As Is Value. We then add the construction costs and entrepreneurial incentive of 
  ${asCompleteEntrepreneurialIncentive} to get the As Complete Value, and add lease up related costs and a 
  entrepreneurial incentive of ${asStabilizedEntrepreneurialIncentive} to reach the As Stabilized Value. 
  For long construction periods, a growth
  factor may be applied.`

  return gt.getParts()
}

export default { generate, mapDTO, mapDataModel }
