import { isEmpty, some, get } from 'lodash'

import { articleGen, articleGenForNumbers } from 'shared/utils/formatters/textFormatters'

import {
  GeneratedText,
  gtLockedSentence,
  gtLocked,
  gtTemplate,
  addConditionals,
  gtConditional,
} from '../../../narrative/GeneratedText'

import {
  formatSquareFeet,
  formatCommercialUnitUse,
  formatCommercialUnitGrade,
} from '../../../formatters/narrativeFormatters'

const generateCommercialUnitWriteup = unit => {
  const { squareFeet, isVacant, grade, use } = unit
  const squareFeetFormatted = squareFeet ? `${formatSquareFeet(squareFeet)} square foot ` : ''
  const useFormatted = () => (isVacant ? 'vacant' : formatCommercialUnitUse(use))
  const gradeFormatted = () => (!isEmpty(grade) ? ` ${formatCommercialUnitGrade(grade)}` : '')
  const formattedUse = useFormatted()

  const article = squareFeetFormatted ? articleGenForNumbers(squareFeet) : articleGen(formattedUse)
  return `${article} ${squareFeetFormatted}${formattedUse} space${gradeFormatted()}`
}

const getUnitsDescription = (units, isFutureTense) => {
  const beVerb = isFutureTense ? 'will be' : 'is'
  let description = ``
  units.forEach((unit, index) => {
    const unitWriteup = generateCommercialUnitWriteup(unit)
    description += ` Commercial unit ${index + 1} ${beVerb} ${unitWriteup}.`
  })
  return description
}

const mapDTO = formValues => {
  const {
    units,
    commercialSquareFootage,
    valueAsComplete,
    commercialUnitCount,
    currentCommercialUnitCount,
  } = formValues
  const unitsFormatted = units
    ? units.map(unit => {
        let gradeInfo = {
          grade: unit.grade,
          otherGrade: unit.otherGrade,
        }
        const noGradeSelected = !some(unit.grade, isSelected => isSelected)
        if (noGradeSelected) {
          gradeInfo = null
        }
        const useInfo = {
          use: unit.use,
          otherUse: unit.otherUse,
        }
        return {
          squareFeet: unit.squareFeet,
          isVacant: unit.isVacant,
          grade: gradeInfo,
          use: useInfo,
        }
      })
    : []
  const isFutureTense = valueAsComplete && currentCommercialUnitCount !== commercialUnitCount
  return {
    units: unitsFormatted,
    unitsCount: commercialUnitCount,
    isOneUnit: commercialUnitCount === 1,
    moreThanOneUnit: commercialUnitCount >= 2,
    oneUnitDescription: commercialUnitCount === 1 ? generateCommercialUnitWriteup(unitsFormatted[0]) : '',
    unitsDescription: commercialUnitCount ? getUnitsDescription(unitsFormatted, isFutureTense) : '',
    isExistCommercialSquareFootage: !commercialSquareFootage,
    isCommercialSquareFootage: commercialSquareFootage > 0 && commercialUnitCount >= 2,
    commercialSquareFootage,
    isFutureTense,
  }
}

const mapDataModel = report => {
  const units = get(report, 'commercial.units', [])
  const valueAsComplete = get(report, 'valueAsComplete')
  const commercialSquareFootage = get(report, 'commercial.totalSquareFootage')
  const commercialUnitCount = units.length
  const currentCommercialUnitCount = get(report, 'property_information.comUnits')
  const unitsFormatted = units
    ? units.map(unit => {
        let gradeInfo = {
          grade: unit.description.grade,
          otherGrade: unit.description.otherGrade || '',
        }
        const noGradeSelected = !some(unit.description.grade, isSelected => isSelected)
        if (noGradeSelected) {
          gradeInfo = null
        }
        const useInfo = {
          use: unit.description.use,
          otherUse: unit.description.otherUse || '',
        }
        return {
          squareFeet: unit.squareFeet,
          isVacant: unit.isVacant,
          grade: gradeInfo,
          use: useInfo,
        }
      })
    : []
  const isFutureTense = valueAsComplete && currentCommercialUnitCount !== commercialUnitCount
  return {
    units: unitsFormatted,
    unitsCount: commercialUnitCount,
    isOneUnit: commercialUnitCount === 1,
    moreThanOneUnit: commercialUnitCount >= 2,
    oneUnitDescription: commercialUnitCount === 1 ? generateCommercialUnitWriteup(unitsFormatted[0]) : '',
    unitsDescription: commercialUnitCount ? getUnitsDescription(unitsFormatted, isFutureTense) : '',
    isExistCommercialSquareFootage: !commercialSquareFootage,
    isCommercialSquareFootage: commercialSquareFootage > 0 && commercialUnitCount >= 2,
    commercialSquareFootage,
    isFutureTense,
  }
}

const generate = formValues => {
  const { isFutureTense } = formValues

  const gt = new GeneratedText()
  const containsVerb = isFutureTense ? 'will contain' : 'contains'
  const gtUnitsCount = gtLocked('unitsCount')
  addConditionals(gt, {
    isOneUnit: [...gtTemplate`The subject ${containsVerb} ${gtLockedSentence('oneUnitDescription')}`],
    moreThanOneUnit: [...gtTemplate`The subject ${containsVerb} ${gtUnitsCount} commercial units`],
    isCommercialSquareFootage: [
      ...gtTemplate` spanning ${gtLocked('commercialSquareFootage', 'formatSquareFeet')} square feet`,
    ],
  })
  gt.t`.`

  const unitsDescription = gtConditional('moreThanOneUnit')
  unitsDescription.children.push(...gtTemplate`${gtLockedSentence('unitsDescription')}`)
  gt.addPart(unitsDescription)

  return gt.getParts()
}

export default { generate, mapDTO, mapDataModel }
