import React from 'react'

import { Paper, Stack } from '@mui/material'
import { get } from 'lodash'

import { getFeatureFlagValue } from '@bowery-valuation/feature-flagger-client'
import { ENABLE_AUTOMATED_INSPECTION_PREFILL } from 'shared/constants/featureFlags'
import PropertyDescription from 'client-shared/components/PropertyDescription'
import { GeneratedComment } from 'client-shared/components'
import { TYPICAL_RESIDENTIAL_CONDITIONS_PATH } from 'shared/constants/report/keysAndDataPaths'

import wrapForm from '../../wrapForm'
import {
  BATHROOM_CONDITIONS_DESCRIPTION,
  BEDROOM_CONDITIONS_DESCRIPTION,
  LIVING_AREA_CONDITIONS_DESCRIPTION,
} from '../../../constants/forms/propertyDescription'

import Stairs from './Stairs'
import TypicalResidentialKitchenConditions from './TypicalResidentialKitchenConditions'
import getWriteupGenerator from './writeupHelper'

export const DATA_PATH = TYPICAL_RESIDENTIAL_CONDITIONS_PATH
const HEADING_NAME = 'Residential Units'

const TOOLTIP_TEXT =
  'The generated text and appraiser commentary will appear in the Description of Improvements section of the report.'

class TypicalResidentialConditions extends React.PureComponent {
  renderPropertyConditions(description, automationMetadata, automatedInspectionPrefillFlag) {
    const { conditions, images, label, name } = description
    const formattedLabel = label ? `${label.replace(/Condition/, 'Description')} Export` : 'Export'

    return (
      <Paper data-qa={`${name}-section-tile`}>
        <PropertyDescription
          conditions={conditions}
          images={images}
          name={name}
          readOnlyImages
          automationMetadata={automationMetadata}
          automatedInspectionPrefillFlag={automatedInspectionPrefillFlag}
        >
          <GeneratedComment
            dataPath={`${name}.writeup`}
            getGeneratedText={getWriteupGenerator(name)}
            isDynamicContent
            label={formattedLabel}
            title="Generated Commentary"
            tooltipText={TOOLTIP_TEXT}
          />
        </PropertyDescription>
      </Paper>
    )
  }

  render() {
    const { form } = this.props
    const { automationMetadata } = form.values
    const numberOfFloors = get(form, 'values.stairs.numberOfFloors')

    const automatedInspectionPrefillFlag = getFeatureFlagValue(ENABLE_AUTOMATED_INSPECTION_PREFILL)

    return (
      <Stack spacing={1} sx={{ maxWidth: 1100 }}>
        <TypicalResidentialKitchenConditions
          getGeneratedText={getWriteupGenerator('kitchen')}
          tooltip={TOOLTIP_TEXT}
          automationMetadata={automationMetadata}
          automatedInspectionPrefillFlag={automatedInspectionPrefillFlag}
        />
        {this.renderPropertyConditions(
          BATHROOM_CONDITIONS_DESCRIPTION,
          automationMetadata,
          automatedInspectionPrefillFlag
        )}
        {this.renderPropertyConditions(
          BEDROOM_CONDITIONS_DESCRIPTION,
          automationMetadata,
          automatedInspectionPrefillFlag
        )}
        {this.renderPropertyConditions(
          LIVING_AREA_CONDITIONS_DESCRIPTION,
          automationMetadata,
          automatedInspectionPrefillFlag
        )}
        <Stairs
          getGeneratedText={getWriteupGenerator('stairs')}
          numberOfFloors={numberOfFloors}
          tooltip={TOOLTIP_TEXT}
          automationMetadata={automationMetadata}
          automatedInspectionPrefillFlag={automatedInspectionPrefillFlag}
        />
      </Stack>
    )
  }
}

const formOptions = {
  heading: HEADING_NAME,
}

export default wrapForm(DATA_PATH, formOptions)(TypicalResidentialConditions)
