import { datadogLogs } from '@datadog/browser-logs'
import { datadogRum } from '@datadog/browser-rum'
import { isNil } from 'lodash'

process.env = window.process.env

const logLevel = process.env.REACT_APP_LOG_LEVEL || 'LOG_DISABLE'

const isProduction = process.env.REACT_APP_APP_ENV === 'production'
const isDevelopment = !isProduction
const appEnv = isDevelopment ? 'development' : process.env.REACT_APP_APP_ENV

const googleApiKey = process.env.GOOGLE_API_KEY || null
const googleMapsVersion = isDevelopment ? 'weekly' : 'quarterly'

const apiUrlConfig = process.env.REACT_APP_BOWERY_API_URL
  ? process.env.REACT_APP_BOWERY_API_URL
  : 'http://localhost:8080'
const apiUrl = (apiUrlConfig.startsWith('/') ? window.location.origin + apiUrlConfig : apiUrlConfig).replace(/\/$/, '')
const marketResearchApiUrl =
  process.env.REACT_APP_MARKET_RESEARCH_API_URL || 'https://market-research.bowery-dev.systems/api/v2'
const nodePath = process.env.REACT_APP_NODE_PATH !== 'undefined' ? process.env.REACT_APP_NODE_PATH : undefined
const localPath = process.env.REACT_APP_PATH !== 'undefined' ? process.env.REACT_APP_PATH : undefined

const isVerbose = process.env.REACT_APP_VERBOSE === 'true'

const templatePath = `/${appEnv}-templates/`

const cartoConfig = {
  apiKey: process.env.REACT_APP_CARTO_API_KEY,
  username: process.env.REACT_APP_CARTO_USERNAME,
}

const allowEditingApprovedReports = process.env.REACT_APP_ALLOW_EDITING_APPROVED_REPORTS

//  LaunchDarkly
const launchDarklyClientSideId = process.env.REACT_APP_LAUNCHDARKLY_CLIENT_SIDE_ID || '61a6442fd60d8e0fc870353a'
const disableLaunchDarklyClient = process.env.REACT_APP_DISABLE_LAUNCHDARKLY_CLIENT === 'true'

//  KnowledgeWorld
const enableKnowledgeworld = process.env.REACT_APP_ENABLE_KNOWLEDGEWORLD === 'true'
const enableExpenseComps = process.env.REACT_APP_ENABLE_EXPENSE_COMPS === 'true'

// DataDog Log Forwarding + RUM
const dataDogApplicationEnvironment = process.env.REACT_APP_APP_ENV

const dataDogClientToken = process.env.REACT_APP_DATA_DOG_CLIENT_TOKEN
if (dataDogClientToken !== undefined) {
  datadogLogs.init({
    clientToken: dataDogClientToken,
    env: dataDogApplicationEnvironment,
    sampleRate: 100,
    forwardConsoleLogs: 'all',
  })

  datadogLogs.addLoggerGlobalContext('service', 'webapp-client')
}

const dataDogRumApplicationId = process.env.REACT_APP_DD_RUM_APP_ID

if (dataDogRumApplicationId !== undefined) {
  datadogRum.init({
    applicationId: process.env.REACT_APP_DD_RUM_APP_ID,
    clientToken: process.env.REACT_APP_DD_RUM_CLIENT_TOKEN,
    service: 'webapp-client',
    env: dataDogApplicationEnvironment,
    version: process.env.DATADOG_VERSION,
    sampleRate: 100,
    sessionReplaySampleRate: 100,
    trackInteractions: true,
    trackResources: true,
    trackLongTasks: true,
    defaultPrivacyLevel: 'mask-user-input',
    enableExperimentalFeatures: ['record_shadow_dom'],
    allowedTracingUrls: [
      'https://app.boweryvaluation.com',
      /https:\/\/webapp\.bowery-.*\.systems/,
      /https:\/\/pr.*-webapp\.bowery-.*\.systems/,
    ],
  })

  datadogRum.startSessionReplayRecording()
}

//
//  Analytics
//
const analyticsEnabled = process.env.REACT_APP_ANALYTICS_ENABLED === 'true' || isProduction

const amplitudeApiKey = process.env.REACT_APP_AMPLITUDE_API_KEY || null
const amplitudeEnabled = analyticsEnabled && !isNil(amplitudeApiKey)
const debugAmplitude = process.env.REACT_APP_DEBUG_AMPLITUDE === 'true'

const salesforceUrl = process.env.REACT_APP_SALESFORCE_URL || 'https://boweryvaluation--uat.sandbox.lightning.force.com'

//
//  Other
//
const s3Url = process.env.REACT_APP_S3_URL

const config = Object.freeze({
  apiUrl,
  marketResearchApiUrl,
  logLevel,
  isProduction,
  isDevelopment,
  isVerbose,
  nodePath,
  appEnv,
  path: localPath,
  templatePath,
  googleApiKey,
  googleMapsVersion,
  cartoConfig,
  analyticsEnabled,
  amplitudeEnabled,
  amplitudeApiKey,
  debugAmplitude,
  enableKnowledgeworld,
  enableExpenseComps,
  allowEditingApprovedReports,
  s3Url,
  launchDarklyClientSideId,
  disableLaunchDarklyClient,
  salesforceUrl,
})

global.env = config

console.log('Init Settings')
console.log(' - API URL:', global.env.apiUrl)
console.log(' - Market Research API URL: ', global.env.marketResearchApiUrl)
console.log(' - Development:', global.env.isDevelopment)
console.log(' - Production:', global.env.isProduction)
console.log(' - Verbose:', global.env.isVerbose)
console.log(' - Template Path:', global.env.templatePath)
console.log(' - Log Level:', global.env.logLevel)
console.log(' - Analytics Enabled:', global.env.analyticsEnabled)

if (global.env.analyticsEnabled) {
  console.log(' - Amplitude Enabled:', global.env.amplitudeEnabled)
  console.log(' - Amplitude Api Key:', global.env.amplitudeApiKey)
  console.log(' - Debug Amplitude:', global.env.debugAmplitude)
}

console.log(' - Knowledgeworld Enabled:', global.env.enableKnowledgeworld)

console.log(' - S3 URL:', global.env.s3Url)
