import { filter, isNaN } from 'lodash'

import { RentTypesUnitsOfMeasure } from './helpers'
import { CommercialReimbursement, CommercialUnit, RentInfo } from './types'

export default {
  calculateTotalAnnualCommercialRentIncome(units: CommercialUnit[]): number {
    if (!units || !units.length) {
      return 0
    }
    const totalCommercialIncome = units.reduce((sum, unit) => {
      const annualRent = unit.annualRent || 0
      return sum + annualRent
    }, 0)
    return totalCommercialIncome
  },
  calculateTotalCommercialIncomeByType(use: string, units: CommercialUnit[]) {
    const unitsByType = filter(units, unit => unit.description.effectiveUse === use)
    return this.calculateTotalAnnualCommercialRentIncome(unitsByType)
  },
  calculateTotalAnnualCommercialReimbursementsIncome(reimbursements: CommercialReimbursement[]): number {
    if (!reimbursements || !reimbursements.length) {
      return 0
    }
    const totalCommercialReimbursements: number = reimbursements.reduce((sum, reimbursement) => {
      const totalAnnual = reimbursement.totalAnnual || 0
      return sum + totalAnnual
    }, 0)
    return totalCommercialReimbursements
  },
  calculateTotalPotentialCommercialIncome(units: CommercialUnit[], reimbursements: CommercialReimbursement[]): number {
    const totalCommercialRentIncome = this.calculateTotalAnnualCommercialRentIncome(units)
    const totalCommercialReimbursementsIncome = this.calculateTotalAnnualCommercialReimbursementsIncome(reimbursements)
    return totalCommercialRentIncome + totalCommercialReimbursementsIncome
  },
  calculateCommercialRentVCLoss(units: CommercialUnit[], vcLossPerecentage: number): number {
    if (!vcLossPerecentage) {
      return 0
    }
    const totalCommercialIncome = this.calculateTotalAnnualCommercialRentIncome(units)
    return totalCommercialIncome * vcLossPerecentage
  },
  calculateTotalCommercialReimbursementVCLoss(reimbursements: CommercialReimbursement[]): number {
    if (!reimbursements || !reimbursements.length) {
      return 0
    }
    return reimbursements.reduce((sum, reimbursement) => {
      const { vcLoss, totalAnnual } = reimbursement
      if (!vcLoss || !totalAnnual) {
        return sum
      }
      const reimbursementVCLoss = vcLoss * totalAnnual
      return sum + reimbursementVCLoss
    }, 0)
  },
  caculateTotalCommercialVCLoss(
    units: CommercialUnit[],
    reimbursements: CommercialReimbursement[],
    commercialVCLossPercentageByType: any
  ): number {
    const reimbursementVCLoss = this.calculateTotalCommercialReimbursementVCLoss(reimbursements)
    const incomeVCLoss = units.reduce((sum, unit) => {
      const {
        annualRent,
        description: { effectiveUse },
      } = unit
      const vcLossPercentage = commercialVCLossPercentageByType[effectiveUse] || 0
      const vcLossAmount = annualRent * vcLossPercentage
      return sum + vcLossAmount
    }, 0)
    return reimbursementVCLoss + incomeVCLoss
  },
  calculateRentsGivenRentType(rentInfo: RentInfo) {
    const annualRent = this.calculateAnnualRent(rentInfo)
    const monthlyRent = this.calculateMonthlyRent(rentInfo)
    const annualRentPsf = this.calculateAnnualRentPsf(rentInfo)
    const monthlyRentPsf = this.calculateMonthlyRentPsf(rentInfo)

    return {
      annualRent,
      annualRentPsf,
      monthlyRent,
      monthlyRentPsf,
    }
  },
  calculateAnnualRent(rentInfo: RentInfo): number {
    const { rentType, baseRent, squareFeet } = rentInfo
    switch (rentType) {
      case RentTypesUnitsOfMeasure.ANNUAL:
        return baseRent
      case RentTypesUnitsOfMeasure.MONTHLY:
        return baseRent * 12
      case RentTypesUnitsOfMeasure.PER_SF_PER_YEAR:
        return baseRent * squareFeet
      case RentTypesUnitsOfMeasure.PER_SF_PER_MONTH:
        return (baseRent * (squareFeet || 0)) / 12
      default:
        return baseRent
    }
  },
  calculateMonthlyRent(rentInfo: RentInfo) {
    const annualRent = this.calculateAnnualRent(rentInfo)
    return annualRent / 12
  },
  calculateAnnualRentPsf(rentInfo: RentInfo) {
    const { squareFeet } = rentInfo
    const annualRent = this.calculateAnnualRent(rentInfo)
    return squareFeet ? annualRent / squareFeet : null
  },
  calculateMonthlyRentPsf(rentInfo: RentInfo) {
    const { squareFeet } = rentInfo
    const monthlyRent = this.calculateMonthlyRent(rentInfo)
    return squareFeet ? monthlyRent / squareFeet : null
  },
  calculateVCLossPercentageAggregate(commercialVCLossAmount: number, commercialIncome: number) {
    if (!commercialVCLossAmount || !commercialIncome) {
      return 0
    }
    return commercialVCLossAmount / commercialIncome
  },
  calculateCommercialVCLossAmountsByType(
    units: CommercialUnit[],
    uniqueUses: string[],
    commercialVCLossPercentages: any
  ) {
    const commercialVCLossAmountByType: any = {}
    if (!uniqueUses || !uniqueUses.length) {
      return commercialVCLossAmountByType
    }
    uniqueUses.forEach((use: string) => {
      const vcLossPercentage = commercialVCLossPercentages[use]
      const commercialIncomeByType = this.calculateTotalCommercialIncomeByType(use, units)
      const commercialVCLossAmountForType = vcLossPercentage * commercialIncomeByType
      commercialVCLossAmountByType[use] = isNaN(commercialVCLossAmountForType) ? 0 : commercialVCLossAmountForType
    })
    return commercialVCLossAmountByType
  },
  calculateAppraiserForecastGrossReimbursement(appraiserForecast: number, grossReimbursementPercent: number): number {
    if (!appraiserForecast || !grossReimbursementPercent) {
      return 0
    }
    const total = appraiserForecast * (grossReimbursementPercent / 100)
    return total < 0 ? 0 : total
  },
}
