import { get, forIn, isNumber } from 'lodash'

export const zoningFloorArea = (grossBuildingArea, mechanicalDeductions) =>
  grossBuildingArea * (1 - mechanicalDeductions / 100)

export const getAdjustedRentPSF = (unit, psfRent, leaseTermsCalcType) => {
  const dateSignedTrendedPricePerSF = getDateSignedTrendedPricePSF(unit, psfRent, leaseTermsCalcType)
  const totalAdjustment = getTotalAdjustment(unit)

  return dateSignedTrendedPricePerSF * ((100 + totalAdjustment) / 100)
}

export const getTotalAdjustment = unit => {
  let sum = 0
  const adjustments = get(unit, 'adjustments', [])

  forIn(adjustments, (value, key) => {
    if (key !== 'dateSigned' && key !== 'leaseTerms') {
      const val = parseFloat(value)
      if (!isNaN(val)) {
        sum += val * 100
      }
    }
  })

  return sum
}

export const getLeaseTermsSubTotalPSF = (unit, psfRent, calcType = '%') => {
  const leaseTerms = get(unit, 'adjustments.leaseTerms', 0)
  let leaseTermsSubTotal = psfRent

  if (isNumber(psfRent) && isNumber(leaseTerms)) {
    if (calcType === '$/SF') {
      leaseTermsSubTotal += leaseTerms
    } else {
      leaseTermsSubTotal = (leaseTerms / 100 + 1) * psfRent
    }
  }

  return leaseTermsSubTotal
}

export const getDateSignedTrendedPricePSF = (unit, psfRent, calcType = '%') => {
  const market = get(unit, 'adjustments.dateSigned', 0)
  let totalPsfRent = psfRent

  if (isNumber(psfRent)) {
    totalPsfRent = getLeaseTermsSubTotalPSF(unit, psfRent, calcType)

    if (isNumber(market)) {
      totalPsfRent = (market + 1) * totalPsfRent
    }
  }

  return totalPsfRent
}

export const isValidNumber = value => isNumber(value) && !isNaN(value)
