import React from 'react'
import PropTypes from 'prop-types'

import { TableHead, TableBody as BaseTableBody } from '@mui/material'

import { TableHeadRow, TableBodyRow } from 'client-shared/components/TableHelpers'

export const TableHeader = ({ columns }) => (
  <TableHead>
    <TableHeadRow columns={columns} />
  </TableHead>
)

TableHeader.propTypes = {
  columns: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
    })
  ).isRequired,
}

export const TableBody = ({ units, name, columns, cellRenderer }) => {
  return (
    <BaseTableBody>
      {units.map((member, index, units) => {
        return (
          <TableBodyRow
            cellComponent={cellRenderer}
            columns={columns}
            index={index}
            key={index}
            unitName={`${name}[${index}]`}
            units={units}
          />
        )
      })}
    </BaseTableBody>
  )
}

TableBody.propTypes = {
  columns: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
    })
  ).isRequired,
  cellRenderer: PropTypes.func.isRequired,
  name: PropTypes.string,
  units: PropTypes.array.isRequired,
}

TableBody.defaultProps = {
  units: [],
}
