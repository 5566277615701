import React, { FC, memo } from 'react'
import { Paper, Stack } from '@mui/material'
import { useForm } from 'react-final-form'
import { get } from 'lodash'

import MvsTable from './MvsTable'
import TotalMvsTable from './TotalMvsTable'
import ExclusionsTable from './ExclusionsTable'

type MvsBuildingContainerOwnProps = {
  form: any
}

export const MvsBuildingContainer: FC<MvsBuildingContainerOwnProps> = ({ form }) => {
  const formApi = useForm()
  const { push, remove, update } = formApi.mutators

  const buildingComponents = get(formApi.getState().values, 'buildingComponents', [])
  const customRefinements = get(formApi.getState().values, 'customRefinements', [])
  const customMultipliers = get(formApi.getState().values, 'customMultipliers', [])
  return (
    <Paper>
      <Stack spacing={5}>
        <MvsTable
          buildingComponents={buildingComponents}
          customRefinements={customRefinements}
          customMultipliers={customMultipliers}
          remove={remove}
          push={push}
          update={update}
          editable={true}
          focus={form.focus}
          formApi={formApi}
        />
        <TotalMvsTable form={form} />
        <ExclusionsTable form={form} />
      </Stack>
    </Paper>
  )
}

export default memo(MvsBuildingContainer)
