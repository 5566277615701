import React from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import { Field } from 'react-final-form'
import Select from 'react-select'

import { FormInputLabel, FormHelperErrorText } from '..'

import styles from './SelectStyles'

const SelectFieldComponent = ({
  input,
  meta: { touched, error },
  dense,
  label,
  classes,
  items,
  onChange,
  ...props
}) => {
  const inErrorState = touched && !!error
  return (
    <div className={dense ? classes.denseRoot : classes.root}>
      <FormInputLabel label={label} error={inErrorState} />
      <Select
        simpleValue
        clearable={false}
        searchable={false}
        value={input.value}
        options={items}
        onChange={onChange || input.onChange}
        optionRenderer={({ label, value }) => <option className={classes.option}>{label || value}</option>}
        className={error ? classes.error : classes.root}
        {...props}
      />
      <FormHelperErrorText inErrorState={inErrorState && !dense} error={error} />
    </div>
  )
}

export const SelectField = withStyles(styles)(({ name, label, ...restProps }) => {
  return <Field name={name} label={label} component={SelectFieldComponent} {...restProps} />
})

SelectField.propTypes = {
  label: PropTypes.string,
  name: PropTypes.string,
  items: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.string.isRequired,
    })
  ).isRequired,
  dense: PropTypes.bool,
  onChange: PropTypes.func,
}

SelectField.defaultProps = {
  onChange: null,
}
