import React from 'react'
import { get, values, memoize, isNumber, sortBy } from 'lodash'
import PropTypes from 'prop-types'

import { Grid, Paper, withStyles } from '@material-ui/core'
import { Button, Stack, Typography, Tooltip } from '@mui/material'
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined'

import { getFeatureFlagValue } from '@bowery-valuation/feature-flagger-client'

import { RESIDENTIAL_RENT_ROLL_PATH } from 'shared/constants/report/keysAndDataPaths'
import { DEFAULT_ALERT_MESSAGES } from 'shared/constants/automation/messages'

import currentRentRollDiscussion from 'shared/utils/textGeneration/incomeApproach/residential/currentRentRollDiscussion'

import * as Socket from 'client-shared/utils/socket'
import AutomationCTA from 'client-shared/components/AutomationCTA'
import AutomationStatus from 'client-shared/components/AutomationStatus'
import { IMPORT_STATUS_READY, IMPORT_STATUS_STARTED } from 'client-shared/components/AutomationCTA/constants'

import { NarrativeComponent, RadioButtonList } from 'client-shared/components'

import DismissibleCallout from '../../../../../../shared/components/DismissibleCallout'

import { CURRENT_RENT_ROLL_DISCUSSION_TOOLTIP, FEATURE_FLAG_AUTOMATED_RENT_ROLL } from '../constants'

import { getUnitsTableColumns } from '../rentRollHelpers'

import * as Api from '../../../../../../report/api'

import { SUBJECT_AS_COMP_OPTIONS } from '../../../../../constants'

import RentRollCsvImporter from './RentRollCsvImporter'
import RentRollTable from './RentRollTable'
import RentRollOptions from './RentRollOptions'

export const DATA_PATH = RESIDENTIAL_RENT_ROLL_PATH

const SUBJECT_AS_COMP_TOOLTIP =
  "Upon draft submission in Salesforce this subject's expense data will be uploaded to the Bowery Database as a residential rent comparable."

const styles = theme => ({
  leftSidebar: {
    maxWidth: 350,
    '@media(min-width: 1530px)': {
      flexBasis: '100%',
      maxWidth: '100%',
    },
    '@media(min-width: 1920px)': {
      flexBasis: '25%',
      minWidth: 300,
      maxWidth: 350,
    },
  },
  paper: theme.paper,
})

class RentRollData extends React.PureComponent {
  getColumnSettings = memoize(getUnitsTableColumns, (...args) => values(args).join('_'))

  state = {
    deletedUnits: [],
  }

  componentDidMount() {
    const { reportNumber } = this.props

    Socket.on(`import-status:rent-roll:${reportNumber}`, this.onImportStatusUpdate)
  }

  componentDidUpdate(prevProps) {
    const { deletedUnits } = this.state
    const { isSubmitting } = this.props

    if (isSubmitting !== prevProps.isSubmitting && deletedUnits.length) {
      this.setState({ deletedUnits: [] })
    }
  }

  componentWillUnmount() {
    const { reportNumber } = this.props

    Socket.off(`import-status:rent-roll:${reportNumber}`, this.onImportStatusUpdate)
  }

  onImportStatusUpdate = ({ status }) => {
    const { form } = this.props

    form.change('rentRollImportStatus', status)
  }

  getCalloutText = () => {
    const { valueAsStabilized } = this.props
    return `Input ${
      valueAsStabilized ? 'As Complete' : 'In-Place'
    } Rents for occupied and employee units if applicable. 
    You will be able to forecast rents for vacant units after completing the Rent Reconciliation.`
  }

  processCSVData = parsedUnits => {
    const { form } = this.props
    form.change('units', parsedUnits)
  }

  onRowDeleteClick = row => {
    const { deletedUnits } = this.state
    const { form } = this.props
    if (!form.values?.units?.length || !isNumber(row)) {
      return
    }

    const copiedUnits = [...form.values.units]
    const deletedUnit = copiedUnits.splice(row, 1)[0]
    if (!deletedUnit) {
      return
    }

    this.setState({ deletedUnits: [...deletedUnits, deletedUnit] })
    form.change('units', copiedUnits)
  }

  onUndoDeleteClick = () => {
    const { deletedUnits } = this.state
    const { form } = this.props

    if (!deletedUnits.length) {
      return
    }

    const copiedUnits = [...deletedUnits]
    const unitToRestore = copiedUnits.pop()

    this.setState({ deletedUnits: copiedUnits })
    form.change('units', sortBy([...form.values.units, unitToRestore], 'index'))
  }

  onAutomationRun = async () => {
    const { reportId } = this.props

    const automatedData = await Api.runAutomation('rent-roll', reportId)
    this.updateFormValuesWithAutomatedData(automatedData)
  }

  updateFormValuesWithAutomatedData = rentRollData => {
    const { form } = this.props

    form.batch(() => {
      form.change('bathrooms', rentRollData.bathrooms)
      form.change('includePerRoomAnalysis', rentRollData.includePerRoomAnalysis)
      form.change('outdoorSpace', rentRollData.outdoorSpace)
      form.change('perUnitSF', rentRollData.perUnitSF)
      form.change('unitCount', rentRollData.unitCount)
      form.change('unitLayout', rentRollData.unitLayout)
      form.change('units', rentRollData.units)
      form.change('automationMetadata', rentRollData.automationMetadata)
    })
  }

  render() {
    const { deletedUnits } = this.state
    const { classes, form, clientPortalJobLink, valueAsStabilized } = this.props
    const {
      perUnitSF,
      bathrooms,
      outdoorSpace,
      unitLayout,
      includePerRoomAnalysis,
      unitRentPSFTimePeriod,
      rentRollImportStatus,
      automationMetadata,
      units,
      address,
    } = form.values
    const csvExampleLink = get(form, 'values.csvExampleLink')
    const showDevelopersForecast = get(form, 'values.showDevelopersForecast', false)

    const automatedRentRollFlag = getFeatureFlagValue(FEATURE_FLAG_AUTOMATED_RENT_ROLL)
    const isRentRollImportStarted = rentRollImportStatus === IMPORT_STATUS_STARTED

    const AUTOMATION_MESSAGES = {
      CTA: (
        <>
          Automate the rent roll table with approved documents from the{' '}
          <a href={clientPortalJobLink} target="_blank" rel="noreferrer">
            <u>Due Diligence Client Portal</u>
          </a>
          .
        </>
      ),
      CTA_LOADING: 'Processing Rent Roll data with approved documents from the Due Diligence Client Portal.',
      ERROR: DEFAULT_ALERT_MESSAGES.ERROR,
      SUCCESS: 'Your automation ran successfully. Verify the rent roll data for accuracy.',
    }

    const columns = this.getColumnSettings(
      perUnitSF,
      bathrooms,
      outdoorSpace,
      unitLayout,
      showDevelopersForecast,
      includePerRoomAnalysis,
      unitRentPSFTimePeriod,
      this.onRowDeleteClick
    )

    return (
      <Grid container spacing={16}>
        {automatedRentRollFlag && (
          <Grid item xs={12} data-qa="residential-rent-roll-automation">
            <AutomationCTA
              CTAMessage={isRentRollImportStarted ? AUTOMATION_MESSAGES.CTA_LOADING : AUTOMATION_MESSAGES.CTA}
              successMessage={AUTOMATION_MESSAGES.SUCCESS}
              errorMessage={AUTOMATION_MESSAGES.ERROR}
              areDocumentsImporting={isRentRollImportStarted}
              disableCTA={rentRollImportStatus !== IMPORT_STATUS_READY}
              onAutomationRun={this.onAutomationRun}
            />
          </Grid>
        )}
        <Grid item xs={12} xl={3} className={classes.leftSidebar}>
          <Grid container direction="column" wrap="nowrap" spacing={16}>
            <Grid item xs={12}>
              <RentRollCsvImporter onParsingComplete={this.processCSVData} csvExampleLink={csvExampleLink} />
            </Grid>
            <Grid item xs={12}>
              <RentRollOptions form={form} />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} xl={9}>
          <Grid container spacing={16}>
            {!automationMetadata?.sourceLinks?.length && (
              <Grid item xs={12}>
                <DismissibleCallout text={this.getCalloutText()} showInfoOutlined={true} />
              </Grid>
            )}

            {automatedRentRollFlag && !!automationMetadata?.sourceLinks?.length && (
              <Grid item xs={12}>
                <Paper className={classes.paper}>
                  <AutomationStatus
                    formPaths={['automationMetadata']}
                    message="The rent roll below was updated from the Due Diligence Portal"
                    sx={{ marginTop: '8px', marginBottom: '16px' }}
                  />
                </Paper>
              </Grid>
            )}
            <Grid item xs={12}>
              <Stack direction="row" justifyContent="space-between">
                <Stack alignItems="center" direction="row" spacing={1}>
                  <Typography variant="subtitle1">Submit subject Rent Roll data to Bowery database?</Typography>
                  <Tooltip title={SUBJECT_AS_COMP_TOOLTIP} placement="right">
                    <InfoOutlinedIcon fontSize="small" />
                  </Tooltip>
                  <RadioButtonList
                    horizontal
                    name="subjectAsResidentialRentCompCondition"
                    items={SUBJECT_AS_COMP_OPTIONS}
                  />
                </Stack>
                <Button disabled={!deletedUnits.length} variant="outlined" onClick={this.onUndoDeleteClick}>
                  Undo Delete
                </Button>
              </Stack>
            </Grid>
            <Grid item xs={12}>
              <RentRollTable columns={columns} showDevelopersForecast={showDevelopersForecast} form={form} />
            </Grid>
            <Grid item xs={12}>
              <Paper className={classes.paper}>
                <NarrativeComponent
                  title="Current Rent Roll Discussion"
                  name="currentRentRollDiscussion"
                  generatedText={currentRentRollDiscussion.generate}
                  data={currentRentRollDiscussion.mapDTO({
                    showDevelopersForecast,
                    residentialUnits: units,
                    address,
                    valueAsStabilized,
                  })}
                  tooltipText={CURRENT_RENT_ROLL_DISCUSSION_TOOLTIP}
                />
              </Paper>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    )
  }
}

RentRollData.propTypes = {
  classes: PropTypes.object.isRequired,
  valueAsStabilized: PropTypes.bool,
  isSubmitting: PropTypes.bool,
  clientPortalJobLink: PropTypes.string,
  reportNumber: PropTypes.string,
  reportId: PropTypes.string,
}

RentRollData.defaultProps = {
  valueAsStabilized: false,
}

export default withStyles(styles)(RentRollData)
