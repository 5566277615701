import React from 'react'
import PropTypes from 'prop-types'

import { eq, sum } from 'lodash'
import { FieldArray } from 'react-final-form-arrays'
import ArrowCircleDown from '@mui/icons-material/ArrowCircleDown'
import {
  Box,
  Button,
  IconButton,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableFooter,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
} from '@mui/material'

import { formatCurrencyFloat } from 'client-shared/utils/numberFormatters'

import DropDown from 'client-shared/components/Table/TableDropDown'
import TableNumberField from 'client-shared/components/Table/TableNumber'
import Text from 'client-shared/components/Table/TableText'
import { Callout, RadioButtonList } from 'client-shared/components'

import {
  BASIS_OF_ANALYSIS,
  BasisOfAnalysisTypes,
  COMPARABLE_TABLE_COLUMNS,
  PARKING_OPERATION_OPTIONS,
  PARKING_TYPE_OPTIONS,
} from './constants'

class ComparableParkingTable extends React.PureComponent {
  addNewRow = () => {
    const { addRow } = this.props
    addRow('comparables', {})
  }

  removeRow = index => {
    const { removeRow } = this.props
    removeRow('comparables', index)
  }

  renderCopyDownIcon = fieldName => {
    const { copyFieldToAll } = this.props

    return (
      <Tooltip title="Copy Down" placement="top">
        <IconButton
          data-qa={`copy-${fieldName}-to-all`}
          onClick={() => copyFieldToAll('comparables', fieldName)}
          size="small"
        >
          <ArrowCircleDown color="info" fontSize="small" />
        </IconButton>
      </Tooltip>
    )
  }

  renderRow = (rowName, index) => {
    const { comparables, basisOfAnalysis } = this.props

    const item = comparables[index]

    if (!item) {
      return null
    }

    return (
      <TableRow key={index}>
        <TableCell>{index + 1}</TableCell>
        <TableCell>
          <Box sx={{ pb: 3 / 4 }}>
            <Text name={`${rowName}.address`} placeholder="Address" />
          </Box>
        </TableCell>
        <TableCell>
          <Stack alignItems="center" direction="row" spacing={1}>
            <DropDown
              disablePortal
              displayEmpty
              items={PARKING_OPERATION_OPTIONS}
              name={`${rowName}.parkingOperation`}
              openOnFocus
              placeholder="Select Operation"
            />
            {index === 0 && this.renderCopyDownIcon('parkingOperation')}
          </Stack>
        </TableCell>
        <TableCell>
          <Stack alignItems="center" direction="row" spacing={1}>
            <DropDown
              disablePortal
              displayEmpty
              items={PARKING_TYPE_OPTIONS}
              name={`${rowName}.parkingType`}
              openOnFocus
              placeholder="Select Type"
            />
            {index === 0 && this.renderCopyDownIcon('parkingType')}
          </Stack>
        </TableCell>
        <TableCell>
          {basisOfAnalysis === BasisOfAnalysisTypes.PER_YEAR ? (
            <Typography variant="body2">{formatCurrencyFloat(item.monthlyRate || 0)}</Typography>
          ) : (
            <Stack alignItems="center" direction="row" spacing={1}>
              <TableNumberField
                adornment="$"
                adornmentPosition="start"
                allowNegative={false}
                decimalScale={2}
                name={`${rowName}.monthlyRate`}
                placeholder="0.00"
                thousandSeparator
              />
              {index === 0 && this.renderCopyDownIcon('monthlyRate')}
            </Stack>
          )}
        </TableCell>
        <TableCell>
          {basisOfAnalysis === BasisOfAnalysisTypes.PER_YEAR ? (
            <Stack alignItems="center" direction="row" spacing={1}>
              <TableNumberField
                adornment="$"
                adornmentPosition="start"
                allowNegative={false}
                decimalScale={2}
                name={`${rowName}.annualRate`}
                placeholder="0.00"
                thousandSeparator
              />
              {index === 0 && this.renderCopyDownIcon('annualRate')}
            </Stack>
          ) : (
            <Typography variant="body2">{formatCurrencyFloat(item.annualRate || 0)}</Typography>
          )}
        </TableCell>
        <TableCell>
          <Button variant="contained" onClick={() => this.removeRow(index)}>
            Delete
          </Button>
        </TableCell>
      </TableRow>
    )
  }

  render() {
    const { coords, comparables } = this.props

    const totalAnnualOtherIncome = sum(comparables.map(item => item.annualRate || 0))
    const parkopediaLink =
      `http://en.parkopedia.com/parking/new_york_ny_united_states/` +
      `?ac=1&amp;country=US&amp;lat=${coords.latitude};lng=-${coords.longitude}`

    return (
      <Stack spacing={2}>
        <Callout
          content={
            <Typography variant="body1">
              Consult{' '}
              <a href={parkopediaLink} target="_blank" rel="noopener noreferrer">
                Parkopedia
              </a>{' '}
              for parking comparables
            </Typography>
          }
          variant="standard"
        />
        <Typography variant="body1">Income Type</Typography>
        <RadioButtonList horizontal items={BASIS_OF_ANALYSIS} label="Basis of Analysis" name="basisOfAnalysis" />
        <Button color="primary" onClick={this.addNewRow} variant="contained">
          ADD NEW COMPARABLE
        </Button>
        <Table size="small">
          <TableHead sx={{ '& .MuiTableCell-head': { fontSize: 14, fontWeight: 700 } }}>
            <TableRow>
              {COMPARABLE_TABLE_COLUMNS.map(column => (
                <TableCell key={column}>{column}</TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            <FieldArray isEqual={eq} name="comparables">
              {({ fields }) => fields.map(this.renderRow)}
            </FieldArray>
          </TableBody>
          <TableFooter>
            <TableRow
              data-qa="total-row"
              sx={{ '& .MuiTableCell-root': { color: 'text.primary', fontSize: 14, fontWeight: 700 } }}
            >
              <TableCell colSpan={5}>Total Annual Other Income:</TableCell>
              <TableCell colSpan={2} data-qa="totalAnnualOtherIncome-cell">
                {formatCurrencyFloat(totalAnnualOtherIncome)}
              </TableCell>
            </TableRow>
          </TableFooter>
        </Table>
      </Stack>
    )
  }
}

ComparableParkingTable.propTypes = {
  addRow: PropTypes.func.isRequired,
  removeRow: PropTypes.func.isRequired,
  copyFieldToAll: PropTypes.func.isRequired,
  comparables: PropTypes.array.isRequired,
  basisOfAnalysis: PropTypes.string,
}

ComparableParkingTable.defaultProps = {
  basisOfAnalysis: BasisOfAnalysisTypes.PER_MONTH,
}

export default ComparableParkingTable
