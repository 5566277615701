import React, { FC } from 'react'

import NarrativeComponent from 'client-shared/components/NarrativeComponent'
import { Box } from '@mui/material'

import defaultSubsectionPlaceholderText from 'shared/utils/textGeneration/genericSubsection'
import { ATOMIC_UNIT_TYPES } from 'shared/constants/report/genericSubsection'

import { get } from 'lodash'

import { CustomFormApi } from 'final-form'

import { makeStyles } from '@mui/styles'

import { FormValues } from '../../types'

import styles from '../../styles'

import Table from './Table'

// @ts-ignore makeStyles doesn't like the strings - it wants enums
const useStyles = makeStyles(styles)

type AtomicUnitProps = {
  form: CustomFormApi<FormValues>
  formPath: string
}

const AtomicUnitComponent: FC<AtomicUnitProps> = ({ form, formPath }) => {
  const classes = useStyles()

  const formValues = form.getState().values
  const atomicUnitType = get(formValues, `${formPath}.type`)

  const narrative = (
    <Box className={classes.createField}>
      <NarrativeComponent
        title="Paragraph(s)"
        generatedText={defaultSubsectionPlaceholderText.generate}
        name={`${formPath}`}
        type="create"
      />
    </Box>
  )

  if (atomicUnitType === ATOMIC_UNIT_TYPES.NARRATIVE) {
    return narrative
  }

  return (
    <Box className={classes.tableField}>
      <Table form={form} formPath={formPath} />
    </Box>
  )
}

export default AtomicUnitComponent
