import React from 'react'

import { Checkbox, FormControlLabel } from '@mui/material'

const CheckboxListItem = ({ checked, label, onChange, onBlur, onFocus, value }) => {
  return (
    <FormControlLabel
      control={<Checkbox checked={checked} onChange={onChange} onBlur={onBlur} onFocus={onFocus} />}
      label={label}
      value={value}
    />
  )
}

export default CheckboxListItem
