import { get } from 'lodash'

import { select, put, takeLatest } from 'redux-saga/effects'

import * as Api from '../../api'

import { ADD_PAGE_BOOKMARK, REMOVE_PAGE_BOOKMARK, REMOVE_ALL_BOOKMARKS, FETCH_BOOKMARKS } from '../actionTypes'

import {
  addPageBookmarkRequest,
  addPageBookmarkReceive,
  addPageBookmarkFail,
  removePageBookmarkRequest,
  removePageBookmarkFail,
  removePageBookmarkReceive,
  removeAllBookmarksRequest,
  removeAllBookmarksReceive,
  removeAllBookmarksFail,
  fetchBookmarksRequest,
  fetchBookmarksReceive,
  fetchBookmarksFail,
} from '../actions/bookmarks'

function* addPageBookmark({ payload: formId }) {
  try {
    yield put(addPageBookmarkRequest())
    const reportId = yield select(state => get(state, 'report.reportData._id'))

    yield put(addPageBookmarkReceive(formId))
    yield Api.addPageBookmark({ reportId, formId })
  } catch (err) {
    yield put(addPageBookmarkFail(err))
  }
}

function* removePageBookmark({ payload: formId }) {
  try {
    yield put(removePageBookmarkRequest())
    const reportId = yield select(state => get(state, 'report.reportData._id'))

    yield put(removePageBookmarkReceive(formId))
    yield Api.removePageBookmark({ reportId, formId })
  } catch (err) {
    yield put(removePageBookmarkFail(err))
  }
}

function* removeAllBookmarks() {
  try {
    yield put(removeAllBookmarksRequest())
    const reportId = yield select(state => get(state, 'report.reportData._id'))

    yield Api.removeAllBookmarks({ reportId })
    yield put(removeAllBookmarksReceive())
  } catch (err) {
    yield put(removeAllBookmarksFail(err))
  }
}

function* fetchBookmarks({ payload: reportId }) {
  try {
    yield put(fetchBookmarksRequest())
    const bookmarks = yield Api.fetchBookmarks(reportId)
    yield put(fetchBookmarksReceive(bookmarks))
  } catch (err) {
    yield put(fetchBookmarksFail(err))
  }
}

export default [
  takeLatest(ADD_PAGE_BOOKMARK.ACTION, addPageBookmark),
  takeLatest(REMOVE_PAGE_BOOKMARK.ACTION, removePageBookmark),
  takeLatest(REMOVE_ALL_BOOKMARKS.ACTION, removeAllBookmarks),
  takeLatest(FETCH_BOOKMARKS.ACTION, fetchBookmarks),
]
