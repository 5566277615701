import React, { useMemo } from 'react'
import { ColumnDataTypeEnum } from '@bowery-valuation/ui-components'
import { find } from 'lodash'

import { EXPENSE_COMPARABLES_CATEGORIES } from '../../../../../../shared/constants/expenses/expenseComparables'

import ColumnHeader from './ColumnHeader'
import { CompModalModes } from './constants'

export const useColumnDefinitions = ({ valueFormatter, averageValueFormatter, openCompModal, latestCompVersions }) => {
  return useMemo(() => {
    return [
      {
        readOnly: true,
        name: 'name',
        label: '',
        type: ColumnDataTypeEnum.text,
        suppressMovable: true,
        minWidth: 305,
        permanent: true,
      },
      item => {
        let availableEditMode = null

        const latestCompVersion = latestCompVersions[item.boweryId]
        if (latestCompVersion) {
          const isOutdated = item.version !== latestCompVersion
          availableEditMode = isOutdated ? CompModalModes.REVIEW : CompModalModes.EDIT
        }

        return {
          label: <ColumnHeader item={item} openCompModal={openCompModal} availableEditMode={availableEditMode} />,
          rowManagedType: true,
          rowManagedReadOnly: true,
          type: ColumnDataTypeEnum.money,
          name: item.key,
          valueFormatter,
          expensePeriod: find(item.expenses, { id: EXPENSE_COMPARABLES_CATEGORIES.expensePeriod })?.value,
          readOnly: false,
          resUnits: find(item.expenses, { id: EXPENSE_COMPARABLES_CATEGORIES.residentialUnits })?.value,
          sf: find(item.expenses, { id: EXPENSE_COMPARABLES_CATEGORIES.squareFeet })?.value,
          availableEditMode,
        }
      },
      {
        name: 'average',
        label: 'Average',
        readOnly: true,
        suppressMovable: true,
        valueFormatter: averageValueFormatter,
        permanent: true,
        rowManagedType: true,
        type: ColumnDataTypeEnum.money,
      },
    ]
  }, [averageValueFormatter, latestCompVersions, openCompModal, valueFormatter])
}
