import React from 'react'
import PropTypes from 'prop-types'

import { TableHead, TableBody, TableRow, TableCell } from '@mui/material'
import { DragDropContext } from 'react-beautiful-dnd'

import { DroppableTable } from 'client-shared/components/DragDropTable'

import SaleRow from './SaleRow'

class SalesTable extends React.PureComponent {
  get salesHistoryRows() {
    const { salesHistory, streetAddress, block, lot, propertyIdentifier, removeSale } = this.props
    return salesHistory.map((_sale, index) => {
      const id = `sale-row-${index}`
      return (
        <SaleRow
          block={block}
          id={id}
          index={index}
          key={_sale._id || id}
          lot={lot}
          propertyIdentifier={propertyIdentifier}
          removeSale={removeSale}
          streetAddress={streetAddress}
        />
      )
    })
  }

  render() {
    const { moveSale, propertyIdentifierType, salesHistory } = this.props
    const noSales = !salesHistory.length
    return (
      <DragDropContext onDragEnd={moveSale}>
        <DroppableTable emptyTableMessage="No sales added" id="sale-history" isEmpty={noSales}>
          <TableHead>
            <TableRow>
              <TableCell />
              <TableCell>Address</TableCell>
              <TableCell>{!!propertyIdentifierType ? propertyIdentifierType : 'Block/Lot'}</TableCell>
              <TableCell>Unique Sale ID</TableCell>
              <TableCell>Sale Date</TableCell>
              <TableCell>Sale Price</TableCell>
              <TableCell>Seller</TableCell>
              <TableCell />
            </TableRow>
          </TableHead>
          <TableBody>{this.salesHistoryRows}</TableBody>
        </DroppableTable>
      </DragDropContext>
    )
  }
}

SalesTable.propTypes = {
  block: PropTypes.string,
  lot: PropTypes.string,
  moveSale: PropTypes.func.isRequired,
  propertyIdentifier: PropTypes.string,
  propertyIdentifierType: PropTypes.string,
  removeSale: PropTypes.func.isRequired,
  salesHistory: PropTypes.array.isRequired,
  streetAddress: PropTypes.string.isRequired,
}

export default SalesTable
