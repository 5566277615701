import React from 'react'
import PropTypes from 'prop-types'

import SearchIcon from '@mui/icons-material/Search'
import { Box, Paper, Stack } from '@mui/material'
import { get, isEqual } from 'lodash'
import PlacesAutocomplete, { geocodeByAddress, getLatLng } from 'react-places-autocomplete'
import { connect } from 'react-redux'

import Autosuggest from '../../shared/components/Autosuggest'
import Portal from '../../shared/components/Portal'
import { DIALOG_Z_INDEX } from '../../shared/constants'
import { errorNotification } from '../../shared/redux/actions/notifications'
import { getLocationInfoFromAddress } from '../../shared/utils/propertyHelper'

class LocationSearch extends React.Component {
  static propTypes = {
    properties: PropTypes.arrayOf(PropTypes.object).isRequired,
    onSelect: PropTypes.func.isRequired,
  }

  state = { address: '' }

  onChange = null
  onBlur = null
  onKeyDown = null
  suggestions = null
  ownInputProps = null

  getOwnInputProps() {
    const { properties } = this.props
    const placeholder = properties.length ? 'Enter comp address' : 'Enter subject address'
    return { placeholder }
  }

  handleChange = (address = '') => {
    this.setState({ address })
  }

  handleSelect = async (event, { suggestionValue }) => {
    try {
      const [addressInfo] = await geocodeByAddress(suggestionValue)
      const locationInfo = getLocationInfoFromAddress(addressInfo)
      const streetNumber = get(locationInfo, 'streetNumber', '')
      const route = get(locationInfo, 'streetName', '')

      const property = {
        formattedAddress: addressInfo.formatted_address,
        address: `${streetNumber} ${route}`,
        city: get(locationInfo, 'city'),
        state: get(locationInfo, 'state'),
        country: get(locationInfo, 'country'),
        zip: get(locationInfo, 'zip'),
        location: await getLatLng(addressInfo),
      }

      this.props.onSelect(property)
      this.setState({ address: '' })
    } catch (error) {
      this.props.createErrorNotification({ message: 'There was an error. Try again.' })
    }
  }

  getSuggestionValue = suggestion => suggestion.description

  cacheAutosuggestProps = ({ onBlur, onChange, onKeyDown, ownInputProps, suggestions }) => {
    if (!this.onChange) {
      this.onChange = onChange
    }
    if (!this.onBlur) {
      this.onBlur = onBlur
    }
    if (!this.onKeyDown) {
      this.onKeyDown = onKeyDown
    }
    if (!isEqual(this.suggestions, suggestions)) {
      this.suggestions = suggestions
    }
    if (!isEqual(this.ownInputProps, ownInputProps)) {
      this.ownInputProps = ownInputProps
    }
    return {
      onBlur: this.onBlur,
      onChange: this.onChange,
      onKeyDown: this.onKeyDown,
      suggestions: this.suggestions,
      inputProps: this.ownInputProps,
    }
  }

  renderSuggestionsContainer = ({ containerProps, children }) => {
    return (
      <Portal zIndex={DIALOG_Z_INDEX + 1}>
        <Paper {...containerProps} square sx={{ padding: 0 }}>
          {children}
        </Paper>
      </Portal>
    )
  }

  render() {
    return (
      <div>
        <PlacesAutocomplete value={this.state.address} onChange={this.handleChange} onSelect={this.handleSelect}>
          {({ getInputProps, suggestions }) => {
            const { onBlur, onChange, onKeyDown, ...inputProps } = getInputProps()
            const ownInputProps = this.getOwnInputProps()

            return (
              <Stack direction="row" alignItems="center" spacing={1}>
                <SearchIcon />
                <Box sx={{ flexGrow: 1 }}>
                  <Autosuggest
                    fullWidth
                    getSuggestionValue={this.getSuggestionValue}
                    onSuggestionSelected={this.handleSelect}
                    selectOnBlur={false}
                    useDirect={true}
                    usePropSuggestionsDirectly={true}
                    renderSuggestionsContainer={this.renderSuggestionsContainer}
                    {...this.cacheAutosuggestProps({ onBlur, onChange, onKeyDown, ownInputProps, suggestions })}
                    {...inputProps}
                  />
                </Box>
              </Stack>
            )
          }}
        </PlacesAutocomplete>
      </div>
    )
  }
}

export default connect(null, { createErrorNotification: errorNotification })(LocationSearch)
