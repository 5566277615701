const EXPORT_MESSAGE_COLUMNS = [
  {
    name: 'templateName',
    title: 'Template Name',
  },
  {
    name: 'details',
    title: 'Details',
  },
]

export { EXPORT_MESSAGE_COLUMNS }
