import React from 'react'
import PropTypes from 'prop-types'

import { FormSpy } from 'react-final-form'
import { Link as RouterLink } from 'react-router-dom'
import { get, sumBy } from 'lodash'

import { FormControl, FormLabel, Paper, Stack, Tab, Tooltip, Typography } from '@mui/material'
import InfoOutlined from '@mui/icons-material/InfoOutlined'
import { TabContext, TabList, TabPanel } from '@mui/lab'

import { Input } from '@ui/Base'
import { Input as InputField } from '@ui/Field'
import { Link } from 'client-shared/components/_mui5'
import { QuickNav } from 'client-shared/components/_mui5/Icons'
import { COMMERCIAL_UNITS_PATH } from 'shared/constants/report/keysAndDataPaths'

import wrapForm from 'report/forms/wrapForm'

import CommercialUnit from './CommercialUnit'
import CommercialUnitSFDiscussion from './CommercialUnitSFDiscussion'

const DATA_PATH = COMMERCIAL_UNITS_PATH

const heading = 'Property Commercial Unit Description'

const CommercialUnits = ({ form, commercialUnitCount }) => {
  const [unitIndex, setUnitIndex] = React.useState(0)
  const handleTabChange = (_event, index) => {
    setUnitIndex(index)
  }

  const {
    values: { units },
    errors,
    submitFailed,
  } = form.getState()
  const showCommercialUnits = commercialUnitCount !== 0

  return (
    <Stack maxWidth="calc(100vw - 454px)" spacing={2}>
      <Paper>
        <Stack spacing={2}>
          <Typography variant="h6">Commercial Unit SF</Typography>
          <Stack spacing={2} width={280}>
            <FormControl>
              <FormLabel>
                <Stack alignItems="center" direction="row" spacing={1}>
                  <Typography>No. of Commercial Units</Typography>
                  <Tooltip placement="right" title="Go To Property Summary">
                    <span>
                      <RouterLink to="./property-summary">
                        <Link>
                          <QuickNav />
                        </Link>
                      </RouterLink>
                    </span>
                  </Tooltip>
                </Stack>
              </FormLabel>
              <Input.Text
                disabled
                value={commercialUnitCount}
                sx={{
                  '&.MuiFormControl-root': {
                    bgcolor: 'grey.100',
                    '.Mui-disabled': { color: 'black' },
                  },
                }}
              />
            </FormControl>
            {units.map((_unit, index) => {
              return (
                <FormControl>
                  <FormLabel>
                    <Typography>{`Commercial Unit ${index + 1} SF`}</Typography>
                  </FormLabel>
                  <InputField.Numbers
                    adornment={{ end: 'SF' }}
                    format={{
                      autoComplete: 'off',
                      decimalScale: 2,
                      thousandSeparator: true,
                    }}
                    name={`units[${index}].squareFeet`}
                  />
                </FormControl>
              )
            })}
            <FormControl>
              <FormLabel>
                <Stack alignItems="center" direction="row" spacing={1}>
                  <Typography>Commercial Gross Leasable Area</Typography>
                  <Tooltip
                    placement="right"
                    title="This value will be deducted from the GBA to determine the Residential Gross Leasable Area."
                  >
                    <InfoOutlined fontSize="small" />
                  </Tooltip>
                </Stack>
              </FormLabel>
              <FormSpy subscription={{ values: true }}>
                {({ values }) => (
                  <InputField.Numbers
                    adornment={{ end: 'SF' }}
                    disabled
                    format={{ decimalScale: 2, thousandSeparator: true }}
                    name="commercialSquareFootage"
                    sx={{
                      '&.MuiFormControl-root': {
                        bgcolor: 'grey.100',
                        '.Mui-disabled': { color: 'black' },
                      },
                    }}
                    initialValue={sumBy(values.units, ({ squareFeet }) => Number(squareFeet))}
                  />
                )}
              </FormSpy>
            </FormControl>
          </Stack>
          <CommercialUnitSFDiscussion form={form} />
        </Stack>
      </Paper>
      {showCommercialUnits && (
        <Paper>
          <TabContext value={unitIndex}>
            <TabList onChange={handleTabChange} scrollButtons={false} variant="scrollable" visibleScrollbar>
              {units.map((_unit, index) => {
                const hasError = !!get(errors, `units[${index}]`)
                const showError = submitFailed && hasError
                return <Tab value={index} label={`Commercial Unit ${index + 1}${showError ? ' *' : ''}`} />
              })}
            </TabList>
            {units.map((_unit, index) => (
              <TabPanel index={index} sx={{ px: 0 }} value={index}>
                <CommercialUnit index={index} form={form} key={index} />
              </TabPanel>
            ))}
          </TabContext>
        </Paper>
      )}
    </Stack>
  )
}

CommercialUnits.propTypes = {
  commercialUnitCount: PropTypes.number,
  form: PropTypes.object.isRequired,
}

export default wrapForm(
  DATA_PATH,
  {
    heading,
    subscription: {
      dirty: true,
      hasValidationErrors: true,
      submitFailed: true,
    },
  },
  state => {
    const commercialUnitCount = get(state, 'report.reportData.propertyInformation.propertySummary.commercialUnitCount')

    return {
      commercialUnitCount,
    }
  }
)(CommercialUnits)
