import React from 'react'
import { useField } from 'react-final-form'

import { useFormControlProps } from '../use-form-control'

import Select from './select'

const formatFieldValue = value => {
  if (value === undefined) {
    return null
  }
  return value
}

const SelectField = ({ name, validate, format, parse, transform, multiple, ...otherSelectProps }) => {
  const fieldConfig = {
    validate,
    format,
    parse: (value, fieldName) => {
      const normalizedValue = formatFieldValue(value)
      return parse ? parse(normalizedValue, fieldName) : normalizedValue
    },
  }

  const field = useField(name, fieldConfig)
  const { onFocus, onBlur, onChange, value } = field.input

  const handleChange = event => {
    const value = event.target.value
    const updatedValue = transform ? transform(value) : value
    onChange(updatedValue)
  }

  const { hasError, helperText } = useFormControlProps(field)

  return (
    <Select
      onChange={handleChange}
      onFocus={onFocus}
      onBlur={onBlur}
      value={value ?? []}
      error={hasError}
      helperText={helperText}
      {...otherSelectProps}
    />
  )
}

export default React.memo(SelectField)
