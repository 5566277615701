import { GeneratedText } from '../../../../narrative/GeneratedText'

export const BASE_COST_SUMMARY = 'The following table is a summary of the MVS calculations:'

const generate = () => {
  const gt = new GeneratedText()

  gt.t`${BASE_COST_SUMMARY}`

  return gt.getParts()
}

export default { generate }
