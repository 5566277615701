import React from 'react'
import PropTypes from 'prop-types'

import { Box, Divider, Typography } from '@mui/material'

import Header from '../../../shared/layout/Header'
import { toPercentageString, formatCurrencyInt, formatInt } from '../../../shared/utils/numberFormatters'

import ReportStatus from '../../../shared/components/ReportStatus'

import ReportInfoLink from './ReportInfoLink'

const ReportInfoTypography = ({ children }) => (
  <Typography variant="body2" color="text.primary" pr={4}>
    {children}
  </Typography>
)

const ReportInfoSection = ({ children }) => <Box sx={{ display: 'flex', alignItems: 'center', ml: 2 }}>{children}</Box>

const ReportHeader = ({
  address,
  basisForSFAnalysis,
  block,
  borough,
  commercialUnitCount,
  concludedCapRate,
  incomeValue,
  isSupportedGeography,
  lot,
  propertyIdentifier,
  residentialUnitCount,
  salesValue,
  squareFootage,
  status,
}) => (
  <Header title={address}>
    <ReportInfoSection>
      <ReportInfoTypography>{borough}</ReportInfoTypography>
      {isSupportedGeography ? (
        <>
          <ReportInfoTypography>Block {block}</ReportInfoTypography>
          <ReportInfoTypography>Lot {lot}</ReportInfoTypography>
        </>
      ) : (
        <ReportInfoTypography>{propertyIdentifier}</ReportInfoTypography>
      )}
    </ReportInfoSection>
    <Divider orientation="vertical" />
    <ReportInfoSection>
      <ReportInfoTypography>
        {basisForSFAnalysis} {formatInt(squareFootage)} SF
      </ReportInfoTypography>
      <ReportInfoTypography>
        {residentialUnitCount} Residential Unit{residentialUnitCount === 1 ? null : 's'}
      </ReportInfoTypography>
      <ReportInfoTypography>
        {commercialUnitCount} Commercial Unit{commercialUnitCount === 1 ? null : 's'}
      </ReportInfoTypography>
    </ReportInfoSection>
    <Divider orientation="vertical" />
    <ReportInfoSection>
      <ReportInfoTypography>Cap Rate {toPercentageString(concludedCapRate, 2)}</ReportInfoTypography>
      <ReportInfoTypography>Income Value {formatCurrencyInt(incomeValue)}</ReportInfoTypography>
      <ReportInfoTypography>Sales Value {formatCurrencyInt(salesValue)}</ReportInfoTypography>
    </ReportInfoSection>
    <Box ml="auto" />
    <ReportStatus status={status} />
    <ReportInfoLink />
  </Header>
)

ReportHeader.propTypes = {
  address: PropTypes.string.isRequired,
  basisForSFAnalysis: PropTypes.string,
  block: PropTypes.string,
  borough: PropTypes.string.isRequired,
  commercialUnitCount: PropTypes.number,
  concludedCapRate: PropTypes.number,
  incomeValue: PropTypes.number,
  isSupportedGeography: PropTypes.bool.isRequired,
  lot: PropTypes.string,
  propertyIdentifier: PropTypes.string.isRequired,
  residentialUnitCount: PropTypes.number,
  salesValue: PropTypes.number,
  squareFootage: PropTypes.number,
  status: PropTypes.string.isRequired,
}

ReportHeader.defaultProps = {
  commercialUnitCount: 0,
  concludedCapRate: 0,
  incomeValue: 0,
  residentialUnitCount: 0,
  salesValue: 0,
  squareFootage: 0,
}

export default React.memo(ReportHeader)
