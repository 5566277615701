import React from 'react'
import PropTypes from 'prop-types'

import { noop } from 'lodash'

import { Card, CardMedia, CardActionArea, CircularProgress, IconButton, Stack, Tooltip } from '@mui/material'
import Delete from '@mui/icons-material/Delete'
import RotateRight from '@mui/icons-material/RotateRight'
import { makeStyles } from '@mui/styles'

import { getResizedImageUrl } from 'client-shared/utils/cloudinary'

import ImagePreviewDialog from './ImagePreviewDialog'

const useStyles = makeStyles({
  root: {
    borderRadius: 1 / 2,
    height: 120,
    width: 120,
    '&:hover $buttonsContainer': {
      visibility: 'initial',
    },
  },
  buttonsContainer: {
    visibility: 'hidden',
  },
})

const ImagePreview = ({ category, isLoading, onDelete, onRotate, readOnly, url, index }) => {
  const [isDialogOpen, setDialogOpened] = React.useState(false)
  const thumbnailUrl = React.useMemo(() => getResizedImageUrl({ url, width: 256 }), [url])

  const classes = useStyles()
  const canEdit = thumbnailUrl && !readOnly
  const canShow = thumbnailUrl && !isLoading

  const openDialog = React.useCallback(() => {
    canShow && setDialogOpened(true)
  }, [canShow])

  const closeDialog = React.useCallback(() => {
    setDialogOpened(false)
  }, [])

  const control = React.useMemo(() => {
    if (!canEdit) {
      return null
    }
    return (
      <Stack
        className={classes.buttonsContainer}
        justifyContent="flex-end"
        height={1}
        data-qa={`${category}-images-image-preview-${index}`}
      >
        <Stack
          direction="row"
          justifyContent="space-between"
          onClick={event => {
            event.stopPropagation()
          }}
          sx={{ bgcolor: '#FFF8', padding: 1 / 4 }}
        >
          {onRotate && (
            <Tooltip placement="top" title="Rotate 90°">
              <IconButton
                aria-label="rotate"
                data-qa={`${category}-images-image-rotate-${index}`}
                onClick={onRotate}
                size="small"
              >
                <RotateRight />
              </IconButton>
            </Tooltip>
          )}
          {onDelete && (
            <Tooltip placement="top" title="Delete">
              <IconButton
                aria-label="delete"
                data-qa={`${category}-images-image-delete-${index}`}
                onClick={onDelete}
                size="small"
              >
                <Delete />
              </IconButton>
            </Tooltip>
          )}
        </Stack>
      </Stack>
    )
  }, [canEdit, category, classes.buttonsContainer, index, onDelete, onRotate])

  return (
    <Card sx={{ flexShrink: 0, padding: 0 }}>
      <CardActionArea>
        <CardMedia className={classes.root} image={thumbnailUrl} onClick={openDialog} src="picture">
          {isLoading ? (
            <Stack alignItems="center" justifyContent="center" height={1}>
              <CircularProgress />
            </Stack>
          ) : (
            control
          )}
        </CardMedia>
      </CardActionArea>
      {isDialogOpen && <ImagePreviewDialog {...{ category, closeDialog, url }} />}
    </Card>
  )
}

ImagePreview.propTypes = {
  category: PropTypes.string,
  isLoading: PropTypes.bool,
  onClick: PropTypes.func,
  onDelete: PropTypes.func,
  onRotate: PropTypes.func,
  readOnly: PropTypes.bool,
  url: PropTypes.string,
}

ImagePreview.defaultProps = {
  category: 'Uncategorized',
  isLoading: false,
  onClick: noop,
  onDelete: noop,
  onRotate: noop,
  readOnly: false,
  url: '',
}

export default ImagePreview
