import { PARKING_INCOME_PATH } from 'shared/constants/report/keysAndDataPaths'

import { arrayToKeyValuePairs, objectToKeyValuePairs } from '../../../../../shared/utils/ui/checkboxHelper'

export const DATA_PATH = PARKING_INCOME_PATH

export const HEADING = 'Parking'
export const WARNING_TEXT = "You've indicated that the subject has no parking facility."

export const ParkingVCLossTypes = {
  RESIDENTIAL: 'Residential',
  PARKING_VC: 'Parking',
}
export const PARKING_VC_LOSS_OPTIONS = objectToKeyValuePairs(ParkingVCLossTypes)
export const VC_LOSS_OPTIONS_COMMERCIAL = objectToKeyValuePairs({ PARKING_VC: 'Parking' })

export const PARKING_OPERATION_OPTIONS = arrayToKeyValuePairs(['Attended', 'Not Attended', 'Self Park'])
export const PARKING_TYPE_OPTIONS = arrayToKeyValuePairs([
  'Surface covered',
  'Surface Open',
  'Surface Non-Gated',
  'Surface Gated',
  'Stacked Parking',
  'Single-story Parking Garage',
  'Multi-level Parking Garage',
  'Underground Parking Garage',
  'Automated Parking Garage',
  'Street Parking',
  'Private Garage',
])

export const PARKING_SETTINGS = [
  { name: 'isParkingFree', label: 'Parking is included as a free amenity' },
  { name: 'hasSupportForParkingComps', label: 'Support with parking income comparables?' },
]

export const COMPARABLE_TABLE_COLUMNS = [
  '#',
  'Address',
  'Parking Operation',
  'Parking Type',
  'Monthly Rate ($)',
  'Annual Income',
  'Actions',
]

export const PARKING_SPACE_TABLE_COLUMNS = ['#', 'Parking Type', 'Monthly Rent', 'Annual Rent']

export const BasisOfAnalysisTypes = {
  PER_MONTH: 'Per Month',
  PER_YEAR: 'Per Year',
}
export const BASIS_OF_ANALYSIS = objectToKeyValuePairs(BasisOfAnalysisTypes)

export const NUMBER_OF_MONTHS = 12
