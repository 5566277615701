import React from 'react'
import PropTypes from 'prop-types'
import { FormLabel } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'
import InfoOutlined from '@mui/icons-material/InfoOutlined'

import Tooltip from '../Tooltip'

const styles = theme => ({
  root: {
    display: 'block',
    margin: theme.spacing.unit / 2,
    marginBottom: 0,
    color: '#505050',
    paddingLeft: 2,
    fontSize: 12,
    fontWeight: 500,
  },
  labelContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  label: {
    paddingRight: 2,
  },
  error: {
    fontWeight: 500,
    color: theme.palette.error[600],
  },
})

const FormInputLabelComponent = ({ label, error, classes, tooltipText, ...props }) => {
  const customLabel =
    tooltipText && label ? (
      <div className={classes.labelContainer}>
        <span className={classes.label}>{label}</span>
        <Tooltip placement="top" text={tooltipText}>
          <InfoOutlined fontSize="inherit" />
        </Tooltip>
      </div>
    ) : (
      label
    )
  return customLabel ? (
    <FormLabel error={error} classes={{ root: classes.root, error: classes.error }} {...props}>
      {customLabel}
    </FormLabel>
  ) : null
}

FormInputLabelComponent.propTypes = {
  classes: PropTypes.object.isRequired,
}

const FormInputLabel = withStyles(styles)(FormInputLabelComponent)

FormInputLabel.displayName = 'FormInputLabel'

FormInputLabel.propTypes = {
  label: PropTypes.node,
  tooltipText: PropTypes.string,
  error: PropTypes.bool,
}

FormInputLabel.defaultProps = {
  label: null,
  tooltipText: '',
  error: false,
}

export default FormInputLabel
