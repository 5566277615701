import { handleActions } from 'redux-actions'

import { updateIn } from 'client-shared/utils/immutability'
import { REVIEW_AND_EXPORT_KEY } from 'shared/constants/report/keysAndDataPaths'

import {
  reportFetchRequest,
  reportFetchReceive,
  reportFetchFail,
  reportUpdateRequest,
  reportUpdateReceive,
  reportUpdateFail,
  reportUpdateComplete,
  reportSettingsUpdateReceive,
  reportSettingsUpdateRequest,
  reportSettingsUpdateFail,
  reportClear,
  updateReportStateRequest,
  updateReportStateFail,
  updateReportStateReceive,
  showConfirmModal,
  hideConfirmModal,
  showFeedbackModal,
  hideFeedbackModal,
  reportStructureUpdate,
  updateNotesReceive,
} from '../actions/report'

const initialState = {
  reportData: null,
  reportStructure: null,
  isLoaded: false,
  isReportStateUpdating: false,
  submitting: false,
  showConfirmModal: false,
  loggingOut: false,
  showFeedbackModal: false,
  feedbackModalTitle: '',
  feedbackModalContent: '',
  feedbackModalPageNameToTrack: '',
}

export const reducer = handleActions(
  {
    [reportFetchRequest](state) {
      return state
    },
    [reportFetchReceive](state, { payload }) {
      return {
        ...state,
        reportData: payload.reportData,
        reportSettings: payload.reportSettings,
        isLoaded: true,
      }
    },
    [reportFetchFail](state) {
      return state
    },
    [reportUpdateRequest](state) {
      return {
        ...state,
        submitting: true,
      }
    },
    [reportUpdateReceive](state, { payload }) {
      return {
        ...state,
        reportData: payload.reportData,
        reportSettings: payload.reportSettings,
      }
    },
    [reportUpdateFail](state) {
      return {
        ...state,
        submitting: false,
      }
    },
    [reportUpdateComplete](state) {
      return {
        ...state,
        submitting: false,
      }
    },
    [reportClear](_state) {
      return { ...initialState }
    },
    [updateReportStateRequest](state) {
      return {
        ...state,
        isReportStateUpdating: true,
      }
    },
    [updateReportStateFail](state) {
      return {
        ...state,
        isReportStateUpdating: false,
      }
    },
    [updateReportStateReceive](state, { payload }) {
      const newState = updateIn(state, ['reportData', REVIEW_AND_EXPORT_KEY], data => ({ ...data, ...payload }))
      return {
        ...newState,
        isReportStateUpdating: false,
      }
    },
    [showConfirmModal](state, { payload: { loggingOut } }) {
      return {
        ...state,
        showConfirmModal: true,
        loggingOut,
      }
    },
    [hideConfirmModal](state) {
      return {
        ...state,
        showConfirmModal: false,
        loggingOut: false,
      }
    },
    [showFeedbackModal](state, { payload: { title, content, pageNameToTrack, csatKey } }) {
      return {
        ...state,
        showFeedbackModal: true,
        feedbackModalTitle: title,
        feedbackModalContent: content,
        feedbackModalPageNameToTrack: pageNameToTrack,
        feedbackModalKey: csatKey,
      }
    },
    [hideFeedbackModal](state) {
      return {
        ...state,
        showFeedbackModal: false,
        feedbackModalTitle: '',
        feedbackModalContent: '',
        feedbackModalPageNameToTrack: '',
        feedbackModalKey: '',
      }
    },
    [reportSettingsUpdateRequest](state) {
      return {
        ...state,
        submitting: true,
      }
    },
    [reportSettingsUpdateReceive](state, { payload }) {
      return {
        ...state,
        reportSettings: payload.reportSettings,
        reportData: payload.reportData,
        submitting: false,
      }
    },
    [reportSettingsUpdateFail](state) {
      return {
        ...state,
        submitting: false,
      }
    },
    [reportStructureUpdate](state, { payload }) {
      return {
        ...state,
        reportStructure: payload,
      }
    },
    [updateNotesReceive](state, { payload }) {
      return {
        ...state,
        reportData: {
          ...state.reportData,
          notes: payload,
        },
      }
    },
  },
  initialState
)
