import { put, takeLatest } from 'redux-saga/effects'

import * as Api from '../../../../../api'

import {
  importCommercialRentComps,
  importCommercialRentCompsRequest,
  importCommercialRentCompsReceive,
  importCommercialRentCompsFail,
} from './actions'
function* fetchCommercialRentComps({ payload }) {
  try {
    const { reportId } = payload
    yield put(importCommercialRentCompsRequest())
    const commercialRentComps = yield Api.fetchCommercialRentCompsByIdAndVersion(reportId)

    yield put(importCommercialRentCompsReceive({ commercialRentComps }))
  } catch (err) {
    yield put(importCommercialRentCompsFail(err))
  }
}
export default [takeLatest(importCommercialRentComps, fetchCommercialRentComps)]
