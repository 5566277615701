import React from 'react'

import PropTypes from 'prop-types'
import { get, sumBy } from 'lodash'
import { Grid, withStyles } from '@material-ui/core'
import { Field } from 'react-final-form'
import {
  ColumnDataTypeEnum,
  RowBasedTable,
  CELL_COLUMN_CONFIGURATIONS,
  useRowsApi,
} from '@bowery-valuation/ui-components'

import { getTaxLiabilityLabel } from 'shared/report-calculations/income-approach/tax/helpers'
import TaxCalculations from 'shared/report-calculations/income-approach/tax/tax-calculations'

import { canUseCaliforniaTaxProjections } from 'shared/helpers/taxes'

import { VALUE_CONCLUSION_TYPES } from 'shared/constants/acas'

import opinionProvided from 'shared/utils/textGeneration/incomeApproach/taxInfo/opinionProvided'

import {
  formatCurrencyFloat,
  formatCurrencyInt,
  formatPercentageString,
} from '../../../../shared/utils/numberFormatters'
import { CheckboxWithLabel, NarrativeComponent } from '../../../../shared/components'

import { LABELS } from '../../../../../../shared/constants/report/incomeApproach/taxes'

import AddTaxRateButton from './AddTaxRateButton'
import AddSpecialAssessmentButton from './AddSpecialAssessmentButton'

import {
  projectedTabNoopFieldValues,
  CELL_EDITOR_SELECTORS,
  valueCellRendererFramework,
  getCustomColumnConfig,
  additionalTaxRateToRow,
  additionalSpecialAssessmentsToRow,
  TOOLTIP_TEXT,
} from './constants'
import styles from './styles'
import { useRegisteredFields } from './hooks'

const ROW_IDS = {
  taxableAssessedValueId: 'taxableAssessedValueId',
}

const FIELD_PATHS = {
  additionalTaxRates: 'projected.opinion.additionalTaxRates',
  additionalSpecialAssessments: 'projected.opinion.additionalSpecialAssessments',
  taxableAssessedValue: 'projected.opinion.taxableAssessedValue',
  taxableAssessedValueLabel: 'projected.opinion.taxableAssessedValueLabel',
}

const columns = [
  {
    name: 'label',
    label: 'Item',
    suppressMovable: true,
    itemIndex: 0,
    type: ColumnDataTypeEnum.text,
    permanent: true,
    align: 'left',
    editable: params => {
      return !!params.data.isEditableRow || params.data.id === ROW_IDS.taxableAssessedValueId
    },
  },
  {
    name: 'value',
    label: 'Value',
    suppressMovable: true,
    itemIndex: 1,
    type: ColumnDataTypeEnum.text,
    permanent: true,
    align: 'right',
    editable: params => {
      return !!params.data.isEditableRow
    },
    cellEditorSelector: ({ data }) => {
      if (data.id === ROW_IDS.taxableAssessedValueId) {
        return CELL_EDITOR_SELECTORS.moneyNoDecimals
      }

      if (data.isAdditionalSpecialAssessmentsRow) {
        return CELL_EDITOR_SELECTORS.moneyTwoDecimals
      }

      if (data.isAdditionalTaxRateRow) {
        return CELL_EDITOR_SELECTORS.taxRate
      }

      return CELL_COLUMN_CONFIGURATIONS[data.type]
    },
    cellRendererFramework: valueCellRendererFramework,
  },
]

const OpinionApproach = ({ taxRate, taxRateName, form, classes }) => {
  const additionalTaxRatesRowsApi = useRowsApi(FIELD_PATHS.additionalTaxRates)
  const additionalSpecialAssessmentRowsApi = useRowsApi(FIELD_PATHS.additionalSpecialAssessments)

  const handleRowUpdate = row => {
    if (row.id === ROW_IDS.taxableAssessedValueId) {
      if (typeof row.value === 'number') {
        form.change(FIELD_PATHS.taxableAssessedValue, row.value)
      }
      form.change(FIELD_PATHS.taxableAssessedValueLabel, row.label)
    }
    if (row.isAdditionalTaxRateRow) {
      additionalTaxRatesRowsApi.updateRow(row)
    }
    if (row.isAdditionalSpecialAssessmentsRow) {
      additionalSpecialAssessmentRowsApi.updateRow(row)
    }
  }

  const handleManyRowsUpdate = rows => {
    rows.forEach(row => {
      handleRowUpdate(row)
    })
  }

  const handleRowDelete = rowId => {
    const formValuesTemp = form.getState().values
    const taxRates = formValuesTemp.projected.opinion.additionalTaxRates || []
    const specialAssessments = formValuesTemp.projected.opinion.additionalSpecialAssessments || []
    const rowIsTaxRate = taxRates.some(row => row.id === rowId)
    const rowIsSpecialAssessment = specialAssessments.some(row => row.id === rowId)

    if (rowIsTaxRate) {
      additionalTaxRatesRowsApi.deleteRow(rowId)
    } else if (rowIsSpecialAssessment) {
      additionalSpecialAssessmentRowsApi.deleteRow(rowId)
    }
  }

  const handleCellFocus = event => {
    const rowIndex = event.rowIndex
    const columnId = event.column.colId
    const row = opinionApproachRows[rowIndex]

    if (row.id === ROW_IDS.taxableAssessedValueId && columnId === 'value') {
      form.focus(FIELD_PATHS.taxableAssessedValue)
    } else if (row.isAdditionalTaxRateRow) {
      const additionalTaxRatesRowIndex = additionalTaxRates.findIndex(({ id }) => id === row.id)
      form.focus(`${FIELD_PATHS.additionalTaxRates}[${additionalTaxRatesRowIndex}].${columnId}`)
    } else if (row.isAdditionalSpecialAssessmentsRow) {
      const additionalSpecialAssessmentsRowIndex = additionalSpecialAssessments.findIndex(({ id }) => id === row.id)
      form.focus(`${FIELD_PATHS.additionalSpecialAssessments}[${additionalSpecialAssessmentsRowIndex}].${columnId}`)
    }
  }

  const { basis, residentialUnitCount, projected, squareFootage, subjectInfo, valueConclusionType } = form.values

  const taxableAssessedValue = get(form, 'values.projected.opinion.taxableAssessedValue', 0)
  const taxableAssessedValueLabel = get(form, 'values.projected.opinion.taxableAssessedValueLabel')
  const includedInExport = get(projected, 'opinion.includedInExport', false)
  const useCaliforniaTaxes = get(projected, 'opinion.useCaliforniaTaxes', false)
  const additionalTaxRates = get(projected, 'opinion.additionalTaxRates', [])
  const additionalSpecialAssessments = get(projected, 'opinion.additionalSpecialAssessments', [])
  const effectiveTaxRate = (taxRate.value || 0) + sumBy(additionalTaxRates, 'value')
  const totalAdditionalSpecialAssessments = sumBy(additionalSpecialAssessments, 'value')
  const canUseCATaxes = canUseCaliforniaTaxProjections(subjectInfo?.state)
  const isCATaxesSelected = includedInExport && canUseCATaxes && useCaliforniaTaxes
  const canUseAsStabilizedValueCA = isCATaxesSelected && valueConclusionType === VALUE_CONCLUSION_TYPES.AS_COMPLETE

  const basisLabel = getTaxLiabilityLabel(basis)
  let taxLiability
  let assessedValue
  let taxesPerBasis
  if (isCATaxesSelected && taxableAssessedValue == null) {
    assessedValue = 'N/A'
    taxLiability = 'N/A'
    taxesPerBasis = 'N/A'
  } else {
    assessedValue = taxableAssessedValue ? formatCurrencyInt(taxableAssessedValue) : '$0'
    taxLiability = TaxCalculations.calculateTaxLiability(
      taxableAssessedValue,
      effectiveTaxRate,
      totalAdditionalSpecialAssessments
    )
    taxesPerBasis = formatCurrencyFloat(
      TaxCalculations.calculateTaxesPerBasis(basis, taxLiability, squareFootage, residentialUnitCount)
    )

    taxLiability = formatCurrencyInt(taxLiability)
  }

  const taxRateRegisteredFields = useRegisteredFields(
    'projected.opinion.additionalTaxRates',
    columns,
    additionalTaxRates
  )
  const specialAssessmentsRegisteredFields = useRegisteredFields(
    'projected.opinion.additionalSpecialAssessments',
    columns,
    additionalSpecialAssessments
  )

  const opinionApproachRows = [
    {
      readOnly: isCATaxesSelected,
      suppressMovable: true,
      permanent: false,
      type: ColumnDataTypeEnum.text,
      label: taxableAssessedValueLabel,
      id: ROW_IDS.taxableAssessedValueId,
      value: assessedValue,
      rowDef: { hideAction: true },
      isEditableRow: !isCATaxesSelected,
    },
    {
      readOnly: true,
      suppressMovable: true,
      permanent: true,
      type: ColumnDataTypeEnum.text,
      label: `${LABELS.TAX_RATE} (${taxRateName})`,
      value: formatPercentageString(taxRate.value, 10),
      id: 'taxRateValue',
      rowDef: { hideAction: true },
    },
    ...additionalTaxRates.map(additionalTaxRateToRow),
    ...additionalSpecialAssessments.map(additionalSpecialAssessmentsToRow),
    {
      readOnly: true,
      suppressMovable: true,
      permanent: true,
      type: ColumnDataTypeEnum.text,
      label: LABELS.TAX_LIABILITY_TOTAL,
      value: taxLiability,
      id: 'taxLiabilityTotal',
      rowDef: { summary: true, hideAction: true },
    },
    {
      readOnly: true,
      suppressMovable: true,
      permanent: true,
      type: ColumnDataTypeEnum.text,
      label: basisLabel,
      value: taxesPerBasis,
      id: 'taxesPerBasis',
      rowDef: { hideAction: true },
    },
  ]

  return (
    <Grid container justify="space-between">
      <Grid item xs={6}>
        <Field name={FIELD_PATHS.taxableAssessedValue}>{() => null}</Field>
        <CheckboxWithLabel name="projected.opinion.includedInExport" label="Include in export" />
        {includedInExport && canUseCATaxes && (
          <CheckboxWithLabel
            name="projected.opinion.useCaliforniaTaxes"
            label="Calculate based on EGI, forecasted expenses, and cap rate conclusion"
          />
        )}
        {canUseAsStabilizedValueCA && (
          <CheckboxWithLabel
            name="projected.opinion.useAsStabilizedValueCA"
            label="Use As Stabilized Value as Taxable Assessed Value"
          />
        )}
      </Grid>
      {includedInExport && (
        <>
          <Grid item>
            <AddTaxRateButton form={form} path="projected.opinion" />
            <AddSpecialAssessmentButton form={form} path="projected.opinion" />
          </Grid>

          <Grid item xs={12}>
            {taxRateRegisteredFields}
            {specialAssessmentsRegisteredFields}
            <RowBasedTable
              id="opinion-approach"
              columns={columns}
              rows={opinionApproachRows}
              {...projectedTabNoopFieldValues}
              onRowUpdate={handleRowUpdate}
              onRowDelete={handleRowDelete}
              onManyRowsUpdate={handleManyRowsUpdate}
              getCustomColumnConfig={getCustomColumnConfig}
              onCellFocused={handleCellFocus}
            />

            <Grid item xl={12} className={classes.discussionContainer}>
              <NarrativeComponent
                title="Opinion Provided Discussion"
                generatedText={opinionProvided.generate}
                name="projected.opinion.discussion"
                tooltipText={TOOLTIP_TEXT}
              />
            </Grid>
          </Grid>
        </>
      )}
    </Grid>
  )
}

OpinionApproach.propTypes = {
  taxableAssessedValue: PropTypes.number,
  taxRate: PropTypes.shape({
    value: PropTypes.number,
    effectiveYear: PropTypes.string,
  }).isRequired,
  taxRateName: PropTypes.string,
}

OpinionApproach.defaultProps = {
  taxableAssessedValue: 0,
}

export default withStyles(styles)(OpinionApproach)
