import React from 'react'
import PropTypes from 'prop-types'

import DuplicateIcon from '@mui/icons-material/FileCopy'
import { Tooltip } from '@mui/material'
import { withStyles } from '@mui/styles'
import { connect } from 'react-redux'
import { compose } from 'recompose'

import { Archive } from '../../../shared/components/_mui5/Icons'
import { ReportTabs } from '../../constants'
import { changeReportVisibility } from '../../redux/actions'

const styles = theme => ({
  reportActions: {
    display: 'flex',
    minWidth: '60px',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  icon: {
    fontSize: 22,
    color: theme.palette.grey[700],
    '&:hover': {
      color: theme.palette.grey[800],
    },
  },
})

class ReportActions extends React.PureComponent {
  static propTypes = {
    userId: PropTypes.string.isRequired,
    currentTab: PropTypes.string.isRequired,
    reportData: PropTypes.object.isRequired,
    changeReportVisibility: PropTypes.func.isRequired,
    openReportModal: PropTypes.func.isRequired,
  }

  get showArchiveIcon() {
    const { currentTab } = this.props

    return currentTab === ReportTabs.MY_REPORTS
  }

  get showUnarchiveIcon() {
    const { userId, reportData, currentTab } = this.props

    if (currentTab === ReportTabs.MY_REPORTS) {
      return null
    }

    return reportData.appraisers.some(appraiser => appraiser.id === userId && !appraiser.reportVisible)
  }

  onDuplicateIconClick = event => {
    const { reportData, openReportModal } = this.props

    event.stopPropagation()
    event.preventDefault()

    openReportModal(reportData)
  }

  onArchiveIconClick = event => {
    const { changeReportVisibility, reportData } = this.props

    event.stopPropagation()
    event.preventDefault()

    changeReportVisibility({ reportVisible: false, reportId: reportData._id })
  }

  onUnarchiveIconClick = event => {
    const { changeReportVisibility, reportData } = this.props

    event.stopPropagation()
    event.preventDefault()

    changeReportVisibility({ reportVisible: true, reportId: reportData._id })
  }

  render() {
    const { classes } = this.props

    return (
      <div className={classes.reportActions}>
        <Tooltip placement="top-end" title="Duplicate Report">
          <DuplicateIcon data-qa="duplicate-btn" className={classes.icon} onClick={this.onDuplicateIconClick} />
        </Tooltip>
        {this.showArchiveIcon && (
          <Tooltip placement="top-end" title="Archive">
            <Archive data-qa="archive-btn" className={classes.icon} onClick={this.onArchiveIconClick} />
          </Tooltip>
        )}
        {this.showUnarchiveIcon && (
          <Tooltip placement="top-end" title="Unarchive">
            <Archive data-qa="unarchive-btn" className={classes.icon} onClick={this.onUnarchiveIconClick} />
          </Tooltip>
        )}
      </div>
    )
  }
}

export default compose(
  withStyles(styles),
  connect(
    state => ({
      currentTab: state.reports.currentTab,
      userId: state.authentication.user._id,
    }),
    {
      changeReportVisibility,
    }
  )
)(ReportActions)
