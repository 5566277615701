import React from 'react'
import PropTypes from 'prop-types'

import FullScreenDialog from '../shared/FullScreenDialog'

import PhotoGridExport from './PhotoGridExport'

const DIALOG_TITLE = 'Photo Grid Export'
const COMFIRM_DIALOG_TITLE = 'Close Photo Grid Export?'
const CONFIRM_DIALOG_CONTENT = 'Photo Grid Export does not save changes. If you close it you will need to start over.'
const DATA_QA_CLOSE_ICON = 'photo-grid-export-close-btn'
const DATA_QA_DIALOG = 'photo-grid-export-dialog'

const PhotoGridExportDialog = ({ open, onClose }) => (
  <FullScreenDialog
    confirm={onClose}
    content={{
      dialogTitle: DIALOG_TITLE,
      confirmDialogTitle: COMFIRM_DIALOG_TITLE,
      confirmDialogContent: CONFIRM_DIALOG_CONTENT,
    }}
    dataQAAttributes={{ closeIcon: DATA_QA_CLOSE_ICON, dialog: DATA_QA_DIALOG }}
    open={open}
  >
    <PhotoGridExport />
  </FullScreenDialog>
)

PhotoGridExportDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
}

export default PhotoGridExportDialog
