import React from 'react'
import PropTypes from 'prop-types'

import { Link } from 'react-router-dom'
import { connect } from 'react-redux'
import { get, kebabCase } from 'lodash'
import { Box, Button, Paper, Stack, Typography } from '@mui/material'

const EditLayout = ({ children, title, redirectTo, buildingCompId, reportId }) => (
  <Paper>
    <Stack direction="row" sx={{ mb: 1 }}>
      <Typography variant="h6">{title}</Typography>
      <Box sx={{ ml: 'auto' }}>
        <Link to={`/report/${reportId}/building-comp/${buildingCompId}/${kebabCase(redirectTo)}`}>
          <Button>EDIT</Button>
        </Link>
      </Box>
    </Stack>
    {children}
  </Paper>
)

EditLayout.propTypes = {
  redirectTo: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  buildingCompId: PropTypes.string.isRequired,
}

export default connect((state, ownProps) => {
  const buildingCompId = get(state, 'buildingComp.id')
  const reportId = get(state, 'buildingComp.reportId')
  return {
    buildingCompId,
    reportId,
    ...ownProps,
  }
})(EditLayout)
