import React from 'react'
import PropTypes from 'prop-types'

import { Accordion, AccordionDetails, AccordionSummary, Box, Stack } from '@mui/material'
import ExpandMore from '@mui/icons-material/ExpandMore'
import { get } from 'lodash'

import { generateMortgageComponentDiscussion } from 'shared/utils/textGeneration/incomeApproach/supportingCapRates'
import { GeneratedComment, Number as NumberField } from 'client-shared/components'
import { fromPercents, toPercents } from 'client-shared/utils/numberOperations'

const NumberOfPaymentsComponent = () => (
  <Stack alignItems="center" direction="row" spacing={1}>
    <NumberField
      adornment="years"
      allowNegative={false}
      data-qa="amortization-term-input"
      label="Amortization Term"
      name="amortizationTerm"
    />
    <Box>x</Box>
    <NumberField allowNegative={false} disabled label="Constant" name="numberOfPaymentsPerYear" />
    <Box>=</Box>
    <NumberField
      allowNegative={false}
      data-qa="number-of-payments-input"
      disabled
      label="Number of Payments"
      name="numberOfPayments"
    />
  </Stack>
)

const EquityRatioComponent = () => (
  <Stack alignItems="center" direction="row" spacing={1}>
    <NumberField name="loanToValueConstant" label="Constant" allowNegative={false} disabled />
    <Box>-</Box>
    <NumberField
      adornment="%"
      allowNegative={false}
      data-qa="loan-to-value-ratio-input"
      decimalScale={2}
      format={toPercents}
      label="Loan to Value Ratio"
      name="loanValueRatio"
      normalize={fromPercents}
    />
    <Box>=</Box>
    <NumberField
      adornment="%"
      allowNegative={false}
      data-qa="equity-ratio-input"
      decimalScale={2}
      disabled
      format={toPercents}
      label="Equity Ratio"
      name="equityRatio"
      normalize={fromPercents}
    />
  </Stack>
)

const SelectedLoanTerms = ({ form }) => {
  const [expanded, setExpanded] = React.useState(false)

  const handleClick = React.useCallback(() => setExpanded(state => !state), [])
  const getMortgageComponentDiscussion = React.useCallback(() => {
    const amortizationTerm = get(form, 'values.amortizationTerm')
    const loanValueRatio = get(form, 'values.loanValueRatio')
    const mortgageConstant = get(form, 'values.mortgageConstant')
    const mortgageRate = get(form, 'values.mortgageRate')
    return generateMortgageComponentDiscussion(loanValueRatio, mortgageRate, amortizationTerm, mortgageConstant)
  }, [form])

  const disabled = !get(form, 'values.bandOfInvestment.includeInExport')

  return (
    <Accordion
      data-qa="selected-loan-terms-section"
      disabled={disabled}
      expanded={expanded && !disabled}
      onChange={handleClick}
    >
      <AccordionSummary data-qa="selected-loan-terms-section-title" expandIcon={<ExpandMore />}>
        Selected Loan Terms
      </AccordionSummary>
      <AccordionDetails>
        <Stack spacing={2}>
          <NumberOfPaymentsComponent />
          <EquityRatioComponent />
          <NumberField
            adornment="%"
            allowNegative={false}
            data-qa="mortgage-rate-input"
            decimalScale={2}
            format={toPercents}
            label="Mortgage Rate"
            name="mortgageRate"
            normalize={fromPercents}
          />
          <NumberField
            allowNegative={false}
            data-qa="mortgage-constant-input"
            decimalScale={4}
            disabled
            label="Mortgage Constant"
            name="mortgageConstant"
          />
          <GeneratedComment
            dataPath="mortgageComponentDiscussion"
            getGeneratedText={getMortgageComponentDiscussion}
            isDynamicContent
            label="Mortgage Component Discussion"
            title="Generated Commentary"
            tooltipText="The following text and generated commentary will appear in the Income Approach of your report."
          />
        </Stack>
      </AccordionDetails>
    </Accordion>
  )
}

SelectedLoanTerms.propTypes = {
  form: PropTypes.object.isRequired,
}

export default SelectedLoanTerms
