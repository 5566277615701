import * as React from 'react'
import PropTypes from 'prop-types'
import { Form } from 'react-final-form'
import {
  Grid,
  Button,
  IconButton,
  Dialog,
  DialogContent,
  Typography,
  InputLabel,
  AppBar,
  Toolbar,
  Box,
} from '@mui/material'

import CloseIcon from '@mui/icons-material/Close'
import { get } from 'lodash'

import { connect } from 'react-redux'
import arrayMutators from 'final-form-arrays'
import { ReactSVG } from 'react-svg'

import { required, hasSelectedItems } from 'client-shared/utils/validation'

import { arrayToKeyValuePairs } from 'client-shared/utils/ui/checkboxHelper'

import { CLIENT_PREFIXES } from 'client-shared/constants'

import { getFormalNamePreview } from 'client-shared/utils/ui/nameHelper'

import { getRoleLabelsById, getRoleOptions, roleIdsToLabels } from 'client-shared/utils/roles'

import { userOfficesFetch } from '../../../redux/actions/user'

import { DropDown } from '../../../../shared/components/DropDown'

import { FILES_ENDPOINT } from '../../../../report/constants'

import { organizationUsersUpdate } from '../../../redux/actions/organization'

import { Callout, CheckboxWithLabel, Text } from '../../../../shared/components'
import FileSelectionField from '../../../../shared/components/FileSelection'

import reportPreviewIcon from '../../../../shared/images/report_preview_icon.svg'

import { ImageUpload } from '../../../../shared/components/ImageUpload'
import CheckboxMultiselect from '../../../../shared/components/Multiselect/CheckboxMultiselect'
import AppraisalInstituteDesignation from '../../../components/AppraisalInstituteDesignation'
import AppraisalCertification from '../../../components/AppraisalCertification'

import AvatarEditor from '../../../components/AvatarEditor/AvatarEditor'

import { ROLE_MANAGEMENT_HELP_TEXT, ROLE_MANAGEMENT_HELP_TEXT_READONLY } from '../../../constants/organization'

import { DATA_PATH } from './Users'

const EDIT_TITLE = 'User Information'

class EditUserModal extends React.PureComponent {
  componentDidMount() {
    const { userOfficesFetch } = this.props

    userOfficesFetch()
  }

  onSubmit = values => {
    const { organizationUsersUpdate, handleClose } = this.props
    organizationUsersUpdate({
      formDataPath: DATA_PATH,
      values,
    })
    handleClose()
  }

  render() {
    const { user, handleClose, userOffices, roleTitles, rolesById, areFilesUploading } = this.props
    const initialValues = {
      ...user,
      roleNames: roleIdsToLabels(user.roles, rolesById),
    }

    return (
      <Dialog fullScreen open onClose={handleClose} aria-labelledby="simple-dialog-title">
        <Form
          onSubmit={this.onSubmit}
          mutators={{
            ...arrayMutators,
          }}
          initialValues={initialValues}
          render={props => {
            const { handleSubmit, values, invalid, form } = props
            const { prefix, suffix, name } = values
            const formalNamePreview = getFormalNamePreview({ prefix, suffix, ...name })

            return (
              <form onSubmit={handleSubmit}>
                <AppBar position="relative" role="toolbar">
                  <Toolbar>
                    <IconButton color="inherit" onClick={handleClose} aria-label="Close">
                      <CloseIcon />
                    </IconButton>
                    <Typography variant="h6" color="inherit" sx={{ flex: 1 }}>
                      Edit Organization User
                    </Typography>
                    <Button color="inherit" type="submit" disabled={invalid || areFilesUploading}>
                      save
                    </Button>
                  </Toolbar>
                </AppBar>
                <DialogContent>
                  <Grid container spacing={5}>
                    <Grid item xs={12} md={12} lg={8}>
                      <Typography variant="h6" mb={2}>
                        {EDIT_TITLE}
                      </Typography>
                      <Grid container>
                        <Grid item xs={7}>
                          <Typography variant="caption" sx={{ color: '#989898' }}>
                            Avatar
                          </Typography>
                          <Box sx={{ width: 'calc(100% - 16px)', display: 'flex' }}>
                            <AvatarEditor avatar={get(values, 'avatar')} change={form.change} />
                          </Box>
                        </Grid>
                        <Grid item xs={5}>
                          <Text name="username" label="Username (email)" disabled />
                          <CheckboxWithLabel name="activated" label="Active User" />
                          {user.googleId ? (
                            <Text
                              id="roleNames"
                              label="Roles"
                              name="roleNames"
                              disabled
                              fullWidth
                              InputLabelProps={{
                                shrink: true,
                              }}
                              helperText={ROLE_MANAGEMENT_HELP_TEXT_READONLY}
                            />
                          ) : (
                            <Box pb={2}>
                              <CheckboxMultiselect
                                label="Roles"
                                name="roles"
                                items={roleTitles}
                                validate={hasSelectedItems('User must have at least one role.')}
                                placeholder="Select role"
                                helperText={ROLE_MANAGEMENT_HELP_TEXT}
                              />
                            </Box>
                          )}

                          <DropDown name="prefix" items={arrayToKeyValuePairs(CLIENT_PREFIXES)} label="Prefix" />
                          <Text name="name.first" label="First Name" validate={required} />
                          <Text name="name.middle" label="Middle Initial" margin="normal" />
                          <Text name="name.last" label="Last Name" validate={required} />
                          <Text
                            name="suffix"
                            label="Suffix"
                            helperText="A suffix should be comma-separated list of certifications"
                          />
                        </Grid>
                      </Grid>

                      <Text name="position" label="Position in Organization" />
                      <DropDown
                        name="boweryOffice"
                        label="Bowery Office"
                        items={arrayToKeyValuePairs(userOffices)}
                        fullWidth
                      />
                      <FileSelectionField
                        name="qualifications"
                        label="Qualifications"
                        fileType="qualifications"
                        fileTypesRestriction={['docx']}
                        uploadEndpoint={FILES_ENDPOINT}
                        parentId={user._id}
                      />
                      <Text name="phone" label="Phone" />
                      <Callout
                        variant="info"
                        icon={
                          <ReactSVG
                            src={reportPreviewIcon}
                            beforeInjection={svg => {
                              svg.setAttribute('fill', '#344a5e')
                            }}
                          />
                        }
                        content={<Typography variant="body1">{formalNamePreview}</Typography>}
                      />

                      <Box>
                        <InputLabel shrink component="legend">
                          Signature
                        </InputLabel>
                        <Box sx={{ height: 150 }} mt={1} mb={1}>
                          <ImageUpload name="signature" />
                        </Box>
                      </Box>
                    </Grid>
                    <Grid item xs={12} md={12} lg={4}>
                      <Box mb={2}>
                        <AppraisalInstituteDesignation />
                      </Box>
                      <AppraisalCertification />
                    </Grid>
                  </Grid>
                </DialogContent>
              </form>
            )
          }}
        />
      </Dialog>
    )
  }
}

EditUserModal.propTypes = {
  user: PropTypes.object.isRequired,
  handleClose: PropTypes.func.isRequired,
  userOfficesFetch: PropTypes.func,
}

EditUserModal.defaultProps = {
  user: {},
}

export default connect(
  state => {
    const roles = get(state, 'authentication.roles', [])
    const roleTitles = getRoleOptions(roles)
    const rolesById = getRoleLabelsById(roles)
    return {
      signature: get(state, 'shared.images.signature'),
      userOffices: get(state, 'user.userOffices'),
      roleTitles,
      rolesById,
      areFilesUploading: get(state, 'shared.areFilesUploading'),
    }
  },
  {
    organizationUsersUpdate,
    userOfficesFetch,
  }
)(EditUserModal)
