export const JOB_NUMBER_LENGTH = 10

export const JOB_NUMBER_MESSAGES = {
  REQUIRED_ERROR: 'Required, e.g., 1234567890',
  PULL_EXTERNAL_VALIDATION_ERROR: 'Your external data must contain a 10 digit Bowery Job #, e.g., 1234567890',
  PULL_EXTERNAL_HELPER_TEXT: 'To pull external data, enter your 10 digit Bowery Job #, e.g., 1234567890',
  PULL_EXTERNAL_VALID: 'Valid Bowery Job #',
  PULL_EXTERNAL_NOT_FOUND: 'Salesforce Job # Not Found',
  TOOLTIP: 'Your Bowery Job # can be found on Salesforce.',
}

export const SYNC_STATUSES = {
  SYNCING: 'SYNCING',
  SYNCED: 'SYNCED',
  SYNC_FAILED: 'SYNC_FAILED',
}

export const PULL_EXTERNAL_DATA_OPTIONS = [
  { value: true, label: 'Yes', type: 'radio' },
  { value: false, label: 'No', type: 'radio' },
]

export const CALLOUT_BTN_LINK = {
  TEXT: 'Bowery Job # can now pull external data into Report Settings',
  BTN_TEXT: 'Visit job list',
  LINK_PROD: 'https://boweryvaluation.lightning.force.com/lightning/o/Job__c/list?filterName=00B1U000008vy7fUAA',
  LINK_REST: 'https://boweryvaluation--uat.lightning.force.com/lightning/o/Job__c/list?filterName=00B1U000008vy7fUAA',
}
