import React from 'react'

import { ColumnDataTypeEnum } from '@bowery-valuation/ui-components'

export const useColumnDefinitions = (includePercentageOfPgiAndEgi, includePerUnit) => {
  return React.useMemo(() => {
    const columns = [
      {
        flex: 1,
        label: 'Income',
        name: 'label',
        permanent: true,
        readOnly: true,
        suppressMovable: true,
        type: ColumnDataTypeEnum.text,
      },
      {
        label: 'Total',
        maxWidth: 150,
        name: 'total',
        permanent: true,
        readOnly: true,
        suppressMovable: true,
        type: ColumnDataTypeEnum.money,
      },
      {
        label: 'PSF',
        maxWidth: 150,
        name: 'psf',
        permanent: true,
        readOnly: true,
        suppressMovable: true,
        type: ColumnDataTypeEnum.money,
      },
    ]
    if (includePerUnit) {
      columns.push({
        label: 'Per Unit',
        maxWidth: 150,
        name: 'perUnit',
        permanent: true,
        readOnly: true,
        suppressMovable: true,
        type: ColumnDataTypeEnum.money,
      })
    }
    if (includePercentageOfPgiAndEgi) {
      columns.push({
        label: '% of PGI',
        maxWidth: 150,
        name: 'percentageOfPgiAndEgi',
        permanent: true,
        readOnly: true,
        suppressMovable: true,
        type: ColumnDataTypeEnum.percent,
      })
    }
    return columns
  }, [includePercentageOfPgiAndEgi, includePerUnit])
}
