import React from 'react'
import PropTypes from 'prop-types'
import DivIcon from 'react-leaflet-div-icon'
import { withStyles } from '@material-ui/core'
import { EditControl } from 'react-leaflet-draw'
import { map, isEmpty, noop, get, head } from 'lodash'
import { Map, TileLayer, FeatureGroup } from 'react-leaflet'
import MarkerClusterGroup from 'react-leaflet-markercluster'
import Domain from '@mui/icons-material/Domain'
import StarRounded from '@mui/icons-material/StarRounded'
import DeleteOutline from '@mui/icons-material/DeleteOutline'

import LeafletMarker from './LeafletMarker'
import LeafletMapControl from './LeafletMapControl'

import './LeafletMap.css'
import 'leaflet/dist/leaflet.css'
import 'leaflet-draw/dist/leaflet.draw.css'
import 'react-leaflet-markercluster/dist/styles.min.css'

const styles = theme => ({
  leafletDivIcon: {
    border: 'none',
    background: 'none',
  },
  plainItem: {
    fill: 'rgba(181, 226, 140, 0.6)',
  },
  markedItem: {
    fill: 'red',
  },
  controlButton: {
    border: '2px solid rgba(0,0,0,0.2)',
    backgroundClip: 'padding-box',
    borderRadius: 3,
    backgroundColor: 'white',
    width: 30,
    height: 30,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: '#f4f4f4',
    },
  },
  centerButtonIcon: {
    fontSize: 18,
    zIndex: 9999,
  },
  centerIcon: {
    color: '#FFB800',
    fontSize: 32,
  },
  trashIcon: {
    fontSize: 18,
    fill: '#464646',
  },
})

const DEFAULT_ZOOM = 13
export const DEFAULT_HEIGHT = 520

const positionShape = PropTypes.shape({
  lat: PropTypes.number.isRequired,
  lng: PropTypes.number.isRequired,
}).isRequired

class LeafletMap extends React.PureComponent {
  static propTypes = {
    classes: PropTypes.object.isRequired,
    height: PropTypes.number,
    center: positionShape,
    zoom: PropTypes.number,
    clusterGroup: PropTypes.arrayOf(
      PropTypes.shape({
        position: positionShape,
        key: PropTypes.string.isRequired,
        hasPopup: PropTypes.bool.isRequired,
        popup: PropTypes.object,
        isMarked: PropTypes.bool.isRequired,
      }).isRequired
    ),
    shouldShowCenterItem: PropTypes.bool,
    onViewportChanged: PropTypes.func,
    onReady: PropTypes.func,
    shouldShowAllControls: PropTypes.bool,
    shouldShowCenterControl: PropTypes.bool,
    shouldShowZoomControls: PropTypes.bool,
    onMouseOver: PropTypes.func,
    onMouseLeave: PropTypes.func,
    zoomDelta: PropTypes.number,
    zoomSnap: PropTypes.number,
    wheelPxPerZoomLevel: PropTypes.number,
  }

  static defaultProps = {
    centerIcon: Domain,
    shouldShowCenterItem: false,
    height: DEFAULT_HEIGHT,
    zoom: DEFAULT_ZOOM,
    clusterGroup: [],
    shouldShowAllControls: true,
    shouldShowCenterControl: false,
    shouldShowZoomControls: false,
    onMouseOver: noop,
    onMouseLeave: noop,
    onReady: noop,
    zoomDelta: 0.3,
    zoomSnap: 0.1,
    wheelPxPerZoomLevel: 20,
  }

  state = {
    showPolygonButton: true,
    polygon: [],
    layer: null,
  }

  getCenterIcon = () => {
    const { center, classes } = this.props

    return (
      <DivIcon className={classes.leafletDivIcon} position={center}>
        <StarRounded className={classes.centerIcon} />
      </DivIcon>
    )
  }

  getMarkers = () => {
    const { clusterGroup } = this.props

    return map(clusterGroup, item => {
      return <LeafletMarker key={item.key} {...item} />
    })
  }

  getPolygonCoordinates = geoJSON => {
    const coordinates = get(geoJSON, 'geometry.coordinates')

    return head(coordinates)
  }

  mapBoundsToPolygonCoordinates = ({ lng, lat }) => [lng, lat]

  onCreatePolygon = event => {
    const { onViewportChanged } = this.props
    const { layer } = event

    if (layer) {
      const geoJSON = layer.toGeoJSON()
      const polygonCoordinates = this.getPolygonCoordinates(geoJSON)

      this.setState({ showPolygonButton: false, polygon: polygonCoordinates, layer })

      onViewportChanged({ coords: polygonCoordinates, isCustomSelection: true })
    }
  }

  onEditPolygon = event => {
    const { onViewportChanged } = this.props
    const { layer } = this.state

    if (layer) {
      const geoJSON = layer.toGeoJSON()
      const polygonCoordinates = this.getPolygonCoordinates(geoJSON)

      this.setState({ polygon: polygonCoordinates })

      onViewportChanged({ coords: polygonCoordinates, isCustomSelection: true })
    }
  }

  onDeletePolygon = () => {
    const { onViewportChanged } = this.props
    const { leafletElement } = this.map
    const { layer } = this.state

    if (leafletElement && layer) {
      leafletElement.removeLayer(layer)

      this.setState({ showPolygonButton: true, polygon: [], layer: null })

      const mapBounds = this.map.leafletElement.getBounds()
      const coords = this.getMapBoundsCoordinates(mapBounds)

      onViewportChanged({ coords, isCustomSelection: false })
    }
  }

  onViewportChanged = () => {
    const { onViewportChanged } = this.props
    const { polygon } = this.state

    if (isEmpty(polygon)) {
      const mapBounds = this.map.leafletElement.getBounds()
      const coords = this.getMapBoundsCoordinates(mapBounds)
      onViewportChanged({ coords, isCustomSelection: false })
    }
  }

  onReady = event => {
    const { onViewportChanged, onReady } = this.props

    const mapBounds = event.target.getBounds()
    const coords = this.getMapBoundsCoordinates(mapBounds)

    onReady(event)
    onViewportChanged({ coords, isCustomSelection: false })
  }

  getMapRef = node => {
    this.map = node
  }

  getPolygonOptions = () => {
    const { showPolygonButton } = this.state
    if (showPolygonButton) {
      return {
        allowIntersection: false,
        shapeOptions: {
          color: '#bada55',
        },
        showArea: true,
        repeatMode: false,
      }
    }
    return false
  }

  getMapBoundsCoordinates = mapBounds => {
    if (!mapBounds) {
      return []
    }

    const coords = [
      mapBounds.getNorthWest(),
      mapBounds.getNorthEast(),
      mapBounds.getSouthEast(),
      mapBounds.getSouthWest(),
    ]

    return coords.map(this.mapBoundsToPolygonCoordinates)
  }

  onSubjectCenter = event => {
    event.preventDefault()

    const { center } = this.props

    this.map.leafletElement.setView([center.lat, center.lng], 15)
  }

  render() {
    const {
      classes,
      onMouseOver,
      onMouseLeave,
      height,
      shouldShowAllControls,
      shouldShowCenterControl,
      shouldShowZoomControls,
      center,
      zoom,
      clusterGroup,
      shouldShowCenterItem,
      zoomDelta,
      zoomSnap,
      wheelPxPerZoomLevel,
    } = this.props

    const { layer } = this.state

    const showZoomControl = shouldShowAllControls || shouldShowZoomControls
    const showPolygonControl = shouldShowAllControls
    const showCenterControl = shouldShowAllControls || shouldShowCenterControl
    const showFeatureGroup = showPolygonControl || showCenterControl

    return (
      <div>
        <Map
          onmouseover={onMouseOver}
          onmouseout={onMouseLeave}
          ref={this.getMapRef}
          style={{ height }}
          center={center}
          zoom={zoom}
          onViewportChanged={this.onViewportChanged}
          whenReady={this.onReady}
          zoomControl={showZoomControl}
          zoomDelta={zoomDelta}
          zoomSnap={zoomSnap}
          wheelPxPerZoomLevel={wheelPxPerZoomLevel}
        >
          {showFeatureGroup && (
            <FeatureGroup>
              {showPolygonControl && (
                <>
                  <EditControl
                    position="topleft"
                    onCreated={this.onCreatePolygon}
                    onEdited={this.onEditPolygon}
                    draw={{
                      rectangle: false,
                      polyline: false,
                      circle: false,
                      marker: false,
                      circlemarker: false,
                      polygon: this.getPolygonOptions(),
                    }}
                    edit={{ edit: !!layer, remove: false }}
                  />
                  {layer && (
                    <LeafletMapControl position="topleft">
                      <div className={classes.controlButton} onClick={this.onDeletePolygon}>
                        <DeleteOutline className={classes.trashIcon} />
                      </div>
                    </LeafletMapControl>
                  )}
                </>
              )}
              {showCenterControl && (
                <LeafletMapControl position="bottomleft">
                  <div className={classes.controlButton} onClick={this.onSubjectCenter}>
                    <StarRounded className={classes.centerButtonIcon} />
                  </div>
                </LeafletMapControl>
              )}
            </FeatureGroup>
          )}

          <TileLayer
            url="https://{s}.basemaps.cartocdn.com/light_all/{z}/{x}/{y}{r}.png"
            attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
          />
          {!!clusterGroup.length && <MarkerClusterGroup>{this.getMarkers()}</MarkerClusterGroup>}

          {shouldShowCenterItem && this.getCenterIcon()}
        </Map>
      </div>
    )
  }
}

export default withStyles(styles)(LeafletMap)
