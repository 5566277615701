import React from 'react'
import PropTypes from 'prop-types'
import { isNil } from 'lodash'
import moment from 'moment'
import {
  Grid,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Button,
  Stack,
  Box,
} from '@mui/material'

import { Form } from 'react-final-form'
import { FORM_ERROR } from 'final-form'

import DatePicker from 'client-shared/components/DatePicker/DatePicker'
import DropDown from 'client-shared/components/DropDown/DropDown'

import {
  AppraisalInstituteDesignationTypes,
  AppraisalInstituteDesignationTypesList,
} from '../../constants/organization'

import AppraisalEducationRequirements from './AppraisalEducationRequirements'

const MAX_YEARS_FROM_NOW = 100
const VALID = {}
const INVALID = { [FORM_ERROR]: '' }

class AppraisalInstituteMembershipModal extends React.PureComponent {
  validate = values => {
    const { designation, educationRequirementsCompleted, validUntil = null } = values
    if (designation === AppraisalInstituteDesignationTypes.NONE || educationRequirementsCompleted === false) {
      return VALID
    }

    if (isNil(educationRequirementsCompleted)) {
      return INVALID
    }

    const dateValue = moment(validUntil)
    const isDateValid = dateValue.isBetween(Date.now(), this.maxDate, 'days', '[]') && dateValue.isValid()

    if (educationRequirementsCompleted && !isDateValid) {
      return INVALID
    }
  }

  get maxDate() {
    return moment().add(MAX_YEARS_FROM_NOW, 'years')
  }

  get initialValues() {
    const { designation, educationRequirementsCompleted, validUntil } = this.props
    return { designation, educationRequirementsCompleted, validUntil }
  }

  render() {
    const { opened, onCancel, onConfirm } = this.props
    return (
      <Dialog fullWidth open={opened} onClose={onCancel}>
        <Form
          initialValues={this.initialValues}
          onSubmit={onConfirm}
          validate={this.validate}
          render={({ values, handleSubmit, invalid }) => {
            const hasDesignation = values.designation !== AppraisalInstituteDesignationTypes.NONE
            return (
              <>
                <DialogTitle>AI Membership</DialogTitle>
                <DialogContent>
                  <Stack spacing={3}>
                    <Grid container justifyContent="space-between">
                      <Grid item xs={5}>
                        <DropDown
                          name="designation"
                          items={AppraisalInstituteDesignationTypesList}
                          label="AI Designation"
                          defaultValue={AppraisalInstituteDesignationTypes.NONE}
                          fullWidth={true}
                        />
                      </Grid>
                      {hasDesignation && values.educationRequirementsCompleted && (
                        <Grid item xs={5}>
                          <DatePicker name="validUntil" label="Valid Until" disablePast maxDate={this.maxDate} />
                        </Grid>
                      )}
                    </Grid>
                    {hasDesignation && (
                      <Box>
                        <DialogContentText>Education Requirement</DialogContentText>
                        <AppraisalEducationRequirements designation={values.designation} />
                      </Box>
                    )}
                  </Stack>
                </DialogContent>
                <DialogActions>
                  <Button onClick={onCancel}>Cancel</Button>
                  <Button disabled={invalid} variant="contained" onClick={handleSubmit}>
                    Confirm
                  </Button>
                </DialogActions>
              </>
            )
          }}
        />
      </Dialog>
    )
  }
}

AppraisalInstituteMembershipModal.propTypes = {
  designation: PropTypes.string,
  educationRequirementsCompleted: PropTypes.bool,
  onCancel: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired,
  opened: PropTypes.bool,
  validUntil: PropTypes.string,
}

AppraisalInstituteMembershipModal.defaultProps = {
  designation: AppraisalInstituteDesignationTypes.NONE,
  educationRequirementsCompleted: null,
  opened: false,
  validUntil: null,
}

export default AppraisalInstituteMembershipModal
