import { get } from 'lodash'
import { createSelector } from 'reselect'

const locationIdentifierSelector = state => {
  return get(state, 'report.reportData.locationIdentifier')
}

export const reportSettingsSelector = createSelector(locationIdentifierSelector, locationIdentifier => {
  return {
    locationIdentifier,
  }
})
