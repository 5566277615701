import React from 'react'

import { Stack, Typography } from '@mui/material'

import { uniq } from 'lodash'

import assumptionsConditions from '../../../../../../shared/utils/textGeneration/final/assumptionsConditions'
import clientIdentification from '../../../../../../shared/utils/textGeneration/reportInformation/clientIdentification'
import definitionOfMarketValue from '../../../../../../shared/utils/textGeneration/reportInformation/definitionOfMarketValue'
import exposureTimeDescription from '../../../../../../shared/utils/textGeneration/property/exposureTimeDescription'
import intendedUser from '../../../../../../shared/utils/textGeneration/reportInformation/intendedUser'
import marketingTimeDescription from '../../../../../../shared/utils/textGeneration/property/marketingTimeDescription'
import NarrativeComponent from '../../../../shared/components/NarrativeComponent'
import propertyContractHistory from '../../../../../../shared/utils/textGeneration/property/propertyContractHistory'
import propertyRights from '../../../../../../shared/utils/textGeneration/reportInformation/propertyRights'
import purposeAndDateOfValue from '../../../../../../shared/utils/textGeneration/incomeApproach/capRateConclusion/purposeAndDateOfValue'
import recentSalesHistory from '../../../../../../shared/utils/textGeneration/property/recentSalesHistory'
import sourceInformation from '../../../../../../shared/utils/textGeneration/final/sourceInformation'

import NarrativeTitle from '../components/NarrativeTitle'
import { PageHeader, TitleSectionHeader } from '../components/Headers'
import SectionHeader from '../components/SectionHeader'

import IntroductionList from './IntroductionList'

import {
  CLIENT_IDENTIFICATION_HEADER,
  DATA_SOURCES_HEADER,
  DEFINITION_OF_MARKET_VALUE_HEADER,
  DEFINITION_OF_MARKET_VALUE_LIST,
  EXPOSURE_TIME_HEADER,
  GENERAL_ASSUMPTION_DISCUSSION_HEADER,
  GENERAL_ASSUMPTION_HEADER,
  GENERAL_ASSUMPTIONS_INTRODUCTION,
  INTENDED_USES_HEADER,
  LIST_TYPE,
  MARKETING_TIME_HEADER,
  PAGE_HEADER,
  PAGE_SECTION_HEADER,
  PROPERTY_HISTORY_HEADER,
  PROPERTY_RIGHTS_APPRAISED_HEADER,
  PURPOSE_AND_DATE_OF_VALUE_HEADER,
  SCOPE_OF_THE_APPRAISAL_HEADER,
  SCOPE_OF_THE_APPRAISAL_INTRODUCTION,
} from './constants'

const Section = ({
  children,
  data,
  formName,
  generatedText,
  link,
  name,
  narrativeTitle,
  title,
  tooltipText,
  trunk,
}) => (
  <Stack>
    <SectionHeader title={title} />
    {children}
    <NarrativeComponent
      title={<NarrativeTitle title={narrativeTitle || title} {...{ formName, link }} />}
      {...{ data, generatedText, name, tooltipText }}
    />
    {trunk}
  </Stack>
)

const Edit = ({ form }) => {
  const { intendedUse, includeMarketingTime, generalAssumptions, appraisalScopeItems } = form.values
  const getReportClients = clients => {
    const organizations = []
    clients.forEach(client => !!client.client && organizations.push(client.client.client))
    return uniq(organizations)
  }
  const clients = getReportClients(form.values.client.reportClients)

  return (
    <Stack spacing={2}>
      <PageHeader title={PAGE_HEADER} />
      <TitleSectionHeader title={PAGE_SECTION_HEADER} />

      <Section
        data={purposeAndDateOfValue.mapDataFromFormValues(form.values.purposeAndDateOfValue)}
        formName="Cap Rate Conclusion"
        generatedText={purposeAndDateOfValue.generate}
        link="cap-rate-conclusion"
        name="purposeAndDateOfValueDiscussion"
        title={PURPOSE_AND_DATE_OF_VALUE_HEADER}
      />

      <Section
        data={clientIdentification.mapDTO(clients)}
        formName="Client"
        generatedText={clientIdentification.generate}
        link="client"
        name="clientIdentificationDiscussion"
        title={CLIENT_IDENTIFICATION_HEADER}
      />

      <Section
        data={intendedUser.mapDTO(clients)}
        formName="Client"
        generatedText={intendedUser.generate}
        link="client"
        name="intendedUserDiscussion"
        title={INTENDED_USES_HEADER}
      >
        {intendedUse && <Typography>{intendedUse}</Typography>}
      </Section>

      <Section
        data={propertyRights.mapDTO(form.values.propertyRightsValues)}
        formName="Key Info"
        generatedText={propertyRights.generate}
        link="report-information"
        name="propertyRights"
        title={PROPERTY_RIGHTS_APPRAISED_HEADER}
      />

      <Stack>
        <SectionHeader title={PROPERTY_HISTORY_HEADER} />
        <Stack spacing={2}>
          <NarrativeComponent
            title={
              <NarrativeTitle
                title="Recent Sales History Discussion"
                link="property-history"
                formName="Property History"
              />
            }
            generatedText={recentSalesHistory.generate}
            data={recentSalesHistory.mapDTO(form.values.propertyHistory)}
            name="salesHistoryDiscussion"
          />
          <NarrativeComponent
            title={
              <NarrativeTitle
                title="Property Contract History Discussion"
                link="property-history"
                formName="Property History"
              />
            }
            generatedText={propertyContractHistory.generate}
            data={propertyContractHistory.mapDTO(form.values.propertyHistory)}
            name="contractHistoryDiscussion"
          />
        </Stack>
      </Stack>

      <Section
        data={exposureTimeDescription.mapDTO(form.values)}
        formName="Market Information"
        generatedText={exposureTimeDescription.generate}
        link="property-market"
        name="exposureTimeDescription"
        title={EXPOSURE_TIME_HEADER}
      />

      {includeMarketingTime && (
        <Section
          data={marketingTimeDescription.mapDTO(form.values)}
          formName="Market Information"
          generatedText={marketingTimeDescription.generate}
          link="property-market"
          name="marketingTimeDescription"
          title={MARKETING_TIME_HEADER}
        />
      )}

      <Section
        formName="Assumptions & Conditions"
        generatedText={assumptionsConditions.generate}
        link="assumptions-conditions"
        name="generalAssumptionsCommentary"
        narrativeTitle={GENERAL_ASSUMPTION_DISCUSSION_HEADER}
        title={GENERAL_ASSUMPTION_HEADER}
        trunk={<IntroductionList intoductionText={GENERAL_ASSUMPTIONS_INTRODUCTION} list={generalAssumptions} />}
      />

      <Section
        formName="Key Info"
        generatedText={definitionOfMarketValue.generate}
        link="report-information"
        name="definitionOfMarketValue"
        title={DEFINITION_OF_MARKET_VALUE_HEADER}
        tooltipText={
          'The following text will appear in the Definition of Market Value in your export; ' +
          'the numbered list of definitions below this paragraph is not able to be edited here'
        }
        trunk={<IntroductionList list={DEFINITION_OF_MARKET_VALUE_LIST} type={LIST_TYPE.OL} />}
      />

      <IntroductionList
        headerText={SCOPE_OF_THE_APPRAISAL_HEADER}
        intoductionText={SCOPE_OF_THE_APPRAISAL_INTRODUCTION}
        list={appraisalScopeItems}
      />

      <Section
        data={sourceInformation.mapDTO(form.values)}
        formName="Source Information"
        generatedText={sourceInformation.generate}
        link="source-info"
        name="sourceInfoDiscussion"
        narrativeTitle="Data Sources Description Export"
        title={DATA_SOURCES_HEADER}
      />
    </Stack>
  )
}

export default Edit
