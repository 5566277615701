import React from 'react'
import PropTypes from 'prop-types'
import { Typography, Grid, Box } from '@mui/material'
import { Field } from 'react-final-form'
import NumberFormat from 'react-number-format'
import BoweryDate from '@bowery-valuation/bowery-date'

const styles = {
  value: {
    typography: 'body1',
    fontWeight: 'bold',
    overflowWrap: 'break-word',
  },
  salesHistoryTable: {
    pl: 3,
  },
}

const UneditableBase = ({ label, input, streetAddress, block, lot, propertyIdentifier }) => {
  return (
    <>
      <Typography>{label}:</Typography>
      <Box sx={styles.salesHistoryTable}>
        {input.value.length ? (
          input.value.map(sale => {
            const date = new BoweryDate(sale.date).formatShortDateWithZeroes()
            return (
              <Grid container>
                <Grid item xs={3}>
                  <Box component="span" sx={styles.value}>
                    {streetAddress}
                  </Box>
                </Grid>
                <Grid item xs={1.5}>
                  <Box component="span" sx={styles.value}>
                    {propertyIdentifier || `${block}/${lot}`}
                  </Box>
                </Grid>
                <Grid item xs={2}>
                  <Box component="span" sx={styles.value}>
                    {date}
                  </Box>
                </Grid>
                <Grid item xs={2.5}>
                  <Box component="span" sx={styles.value}>
                    $<NumberFormat value={sale.price} displayType="text" thousandSeparator decimalScale={0} />
                  </Box>
                </Grid>
                <Grid item xs={3}>
                  <Box component="span" sx={styles.value}>
                    {sale.seller}
                  </Box>
                </Grid>
              </Grid>
            )
          })
        ) : (
          <Box component="span" sx={styles.value}>
            No Sales History
          </Box>
        )}
      </Box>
    </>
  )
}

UneditableBase.propTypes = {
  streetAddress: PropTypes.string.isRequired,
  block: PropTypes.string.isRequired,
  lot: PropTypes.string.isRequired,
  propertyIdentifier: PropTypes.string,
}

const UneditableSalesHistory = React.memo(({ label, name, ...otherProps }) => {
  return <Field label={label} name={name} component={UneditableBase} {...otherProps} />
})

UneditableSalesHistory.propTypes = {
  label: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  thousandSeparator: PropTypes.bool,
  decimalScale: PropTypes.number,
}

export default UneditableSalesHistory
