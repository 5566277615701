import { GeneratedText } from '../../../narrative/GeneratedText'

import { mapDTO, mapDataModel } from './mappings'

const generate = ({ floodHazardString }) => {
  const gt = new GeneratedText()
  gt.t`${floodHazardString}`
  return gt.getParts()
}

export default { generate, mapDTO, mapDataModel }
