import { GeneratedText, gtConditional, gtLocked, gtTemplate } from '../../../narrative/GeneratedText'

import { mapDTO, mapDataModel } from './mappings'

const generate = () => {
  const gt = new GeneratedText()

  const addressGt = gtLocked('address', null, 'Address')
  const interestAppraisedAsIsGt = gtLocked('interestAppraisedAsIsMarketValue', null, 'Interest Appraised')
  const interestAppraisedAsCompleteGt = gtLocked('interestAppraisedAsComplete', null, 'Interest Appraised As Complete')
  const interestAppraisedAsStabilizedGt = gtLocked(
    'interestAppraisedAsStabilized',
    null,
    'Interest Appraised As Stabilized'
  )

  gt.t`In accordance with your request, we have completed an appraisal of ${addressGt}`
  gt.t` for the purpose of advancing an opinion of the As Is Market Value of the ${interestAppraisedAsIsGt}`

  gt.addPart(gtConditional('onlyIncludeAsIs', gtTemplate` in the subject`))

  gt.addPart(
    gtConditional('includeAsComplete', [
      gtConditional('includeAsStabilized', gtTemplate`,`),
      gtConditional('excludeAsStabilized', gtTemplate` and`),
      ...gtTemplate` the Prospective Market Value As Complete of the ${interestAppraisedAsCompleteGt}`,
    ])
  )

  gt.addPart(
    gtConditional(
      'includeAsStabilized',
      gtTemplate` and the Prospective Market Value As Stabilized of the ${interestAppraisedAsStabilizedGt}`
    )
  )

  gt.t`.`
  return gt.getParts()
}

export default { generate, mapDTO, mapDataModel }
