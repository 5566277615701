import React from 'react'

import { withStyles } from '@mui/styles'

import { boldStyles } from '../../styles'

const Bold = ({ classes, children, attributes }) => {
  return (
    <strong {...attributes} className={classes.bold}>
      {children}
    </strong>
  )
}

export default withStyles(boldStyles)(Bold)
