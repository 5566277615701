import React from 'react'
import ReactDOM from 'react-dom'

import { STICKY_FORM_PANEL_WRAPPER_ID } from '../constants'

class StickyFormTopPanel extends React.Component {
  constructor(props) {
    super(props)
    this.element = document.createElement('div')
  }

  componentDidMount() {
    this.stickyPanelWrapperContainer = document.getElementById(STICKY_FORM_PANEL_WRAPPER_ID)
    this.stickyPanelWrapperContainer.appendChild(this.element)
  }

  componentWillUnmount() {
    this.stickyPanelWrapperContainer.removeChild(this.element)
  }

  render() {
    return ReactDOM.createPortal(this.props.children, this.element)
  }
}

export default StickyFormTopPanel
