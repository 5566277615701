import { createAction } from 'redux-actions'
import { defineAction } from 'redux-define'

import { DEFAULT_API_ACTION_STATES } from '../../../../../shared/constants'

const PULL_REPORT_SETTINGS = defineAction('PULL_REPORT_SETTINGS')
const CLEAR_REPORT_SETTINGS = defineAction('CLEAR_REPORT_SETTINGS')
const REPORT_SETTINGS_FETCH = defineAction('REPORT_SETTINGS_FETCH', DEFAULT_API_ACTION_STATES)

export const pullReportSettings = createAction(PULL_REPORT_SETTINGS.ACTION)
export const clearReportSettings = createAction(CLEAR_REPORT_SETTINGS.ACTION)

export const reportSettingsFetch = createAction(REPORT_SETTINGS_FETCH.ACTION)
export const reportSettingsFetchRequest = createAction(REPORT_SETTINGS_FETCH.REQUEST)
export const reportSettingsFetchReceive = createAction(REPORT_SETTINGS_FETCH.RECEIVE)
export const reportSettingsFetchFail = createAction(REPORT_SETTINGS_FETCH.FAIL)
