import React from 'react'
import PropTypes from 'prop-types'

import arrayMutators from 'final-form-arrays'
import { find, forEach, forIn, get, isNil, keyBy } from 'lodash'
import { Link } from 'react-router-dom'

import { Box, Button, Paper, Tooltip, Typography } from '@mui/material'

import Layout from '@ui/Layout'

import wrapForm from 'report/forms/wrapForm'
import { QuickNav } from 'client-shared/components/_mui5/Icons'
import { Table } from 'client-shared/components/_mui5/Table'
import { formatCurrencyInt } from 'client-shared/utils/numberFormatters'
import { getFieldValue } from 'client-shared/components/_mui5/Table/helpers'
import { isRentPsfMonthly } from 'shared/helpers/incomeApproach/commercial'
import { precisionRound } from 'client-shared/utils/numberOperations'

import { COMMERCIAL_PROJECTED_RENT_ROLL_PATH } from 'shared/constants/report/keysAndDataPaths'

import RentRollTable from '../../CommercialRentRoll/components/RentRollTable'
import { updateRent } from '../../CommercialRentRoll/mutators'
import { Labels } from '../constants'

import StabilizedIncomeDiscussion from './StabilizedIncomeDiscussion'

export const DATA_PATH = COMMERCIAL_PROJECTED_RENT_ROLL_PATH

const MARKET_RATE_HEADING = 'Market Rate Conclusions'
const AUTO_FILL_BUTTON = 'AUTO FILL UNITS'
const NUMBER_OF_MONTHS = 12

const rentConclusionsTableColumns = [
  {
    title: 'Commercial Comp Group',
    readOnly: true,
    headerClassName: 'alignLeft',
    cellProps: { className: 'alignLeft' },
  },
  {
    title: 'Forecasted Rent',
    readOnly: true,
    cellProps: { className: 'alignRight' },
  },
]

const CommercialProjectedRentRoll = ({ form, rentConclusions }) => {
  const fillInVacantRents = React.useCallback(() => {
    const units = getFieldValue(form, 'units')
    const rentBasis = getFieldValue(form, 'rentBasis')

    form.batch(() => {
      forEach(units, (unit, index) => {
        const rentConclusion = find(rentConclusions, conclusion => !isNil(conclusion.units[unit._id]))
        if (rentConclusion) {
          const unitPath = `units[${index}]`
          let updatedFieldName
          let marketConclusionValue

          if (isRentPsfMonthly(rentBasis)) {
            updatedFieldName = 'monthlyRentPsf'
            marketConclusionValue = precisionRound(rentConclusion.marketConclusion / NUMBER_OF_MONTHS, 2)
          } else {
            updatedFieldName = 'annualRentPsf'
            marketConclusionValue = rentConclusion.marketConclusion
          }

          form.change(`${unitPath}.${updatedFieldName}`, marketConclusionValue)
          form.mutators.updateRent({ unitPath, updatedFieldName })
        }
        return unit
      })
    })
  }, [form, rentConclusions])

  const forecastData = React.useMemo(
    () => rentConclusions.map(conclusion => [conclusion.unitGroupName, formatCurrencyInt(conclusion.marketConclusion)]),
    [rentConclusions]
  )

  return (
    <Layout.VerticalRow>
      <Paper>
        <Layout.VerticalRow>
          <Layout.Crab>
            <Typography variant="h6">{MARKET_RATE_HEADING}</Typography>
            <Tooltip title="Go To Commercial Rent Reconciliation" placement="top">
              <Box>
                <Link to="./commercial-rent-reconciliation">
                  <QuickNav />
                </Link>
              </Box>
            </Tooltip>
          </Layout.Crab>
          <Box sx={{ mx: -2 }}>
            <Table columns={rentConclusionsTableColumns} data={forecastData} data-qa="rent-conclusions-table" />
          </Box>
        </Layout.VerticalRow>
      </Paper>
      <Paper>
        <Layout.VerticalRow>
          <Typography variant="h6">Commercial Stabilized Income</Typography>
          <Layout.VerticalRow>
            <Layout.Crab>
              <Typography variant="subtitle1">Commercial Stabilized Rent Roll</Typography>
              <Button variant="contained" onClick={fillInVacantRents} data-qa="autofill-button">
                {AUTO_FILL_BUTTON}
              </Button>
            </Layout.Crab>
            <Box sx={{ mx: -2 }}>
              <RentRollTable form={form} isProjectedRentRoll={true} />
            </Box>
          </Layout.VerticalRow>
          <Box p={2}>
            <StabilizedIncomeDiscussion form={form} />
          </Box>
        </Layout.VerticalRow>
      </Paper>
    </Layout.VerticalRow>
  )
}

const formOptions = {
  heading: Labels.COMMERCIAL_PROJECTED_RENT_ROLL,
  mutators: {
    ...arrayMutators,
    updateRent,
  },
  subscription: { submitting: true, pristine: true, dirty: true, hasValidationErrors: true, values: true },
}

CommercialProjectedRentRoll.propTypes = {
  form: PropTypes.object.isRequired,
  rentConclusions: PropTypes.arrayOf(
    PropTypes.shape({
      unitGroupName: PropTypes.string.isRequired,
      units: PropTypes.object.isRequired,
      marketConclusion: PropTypes.number.isRequired,
    })
  ).isRequired,
}

export default wrapForm(DATA_PATH, formOptions, state => {
  const rentReconciliation = get(
    state,
    'report.reportData.incomeApproach.commercialIncome.commercialRentReconciliation'
  )

  const getRentConclusions = rentReconciliation => {
    const conclusions = []
    const reconciliationGroups = get(rentReconciliation, 'reconciliationGroups', { items: [] })
    forIn(reconciliationGroups.items, group => {
      if (!isNil(group.summary.baseUnit) && !isNil(group.summary.marketRentConclusion)) {
        conclusions.push({
          marketConclusion: group.summary.marketRentConclusion,
          unitGroupName: group.name,
          unitGroupKey: group.id,
          units: keyBy([group.summary.baseUnit, ...(group.summary.commercialUnits || [])], '_id'),
        })
      }
    })
    return conclusions
  }

  return {
    rentConclusions: getRentConclusions(rentReconciliation),
  }
})(CommercialProjectedRentRoll)
