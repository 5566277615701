import React from 'react'
import PropTypes from 'prop-types'
import { compose, pure } from 'recompose'
import { withStyles } from '@material-ui/core'

import Autosuggest from '../../../../../shared/components/Autosuggest'

const styles = theme => {
  const text = {
    fontSize: 12,
    lineHeight: 1.43,
  }

  return {
    inputRoot: {
      width: '100%',
      height: 49,
      ...text,
    },
    notchedOutline: {},
  }
}

const AutosuggestFilterInput = ({ classes, name, options, value, onChange }) => {
  return (
    <Autosuggest
      suggestions={options}
      onChange={onChange}
      value={value}
      inputProps={{
        name,
        placeholder: 'State',
        classes: {
          root: classes.inputRoot,
          notchedOutline: classes.notchedOutline,
        },
      }}
      textFieldProps={{
        variant: 'outlined',
      }}
    />
  )
}

AutosuggestFilterInput.propTypes = {
  name: PropTypes.string.isRequired,
  value: PropTypes.string,
  options: PropTypes.arrayOf(PropTypes.string).isRequired,
  onChange: PropTypes.func.isRequired,
}

export default compose(pure, withStyles(styles))(AutosuggestFilterInput)
