import { get, max, min, pick, uniq } from 'lodash'

import { getTotalForecastedValue } from 'shared/utils/report/incomeApproach'

import { getForecastExpenseTotalsPerBasis } from 'shared/utils/report/incomeApproach/expenses'

import {
  DEFAULT_EXPENSES_KEYS,
  ELECTRICITY_FUEL_EXPENSE,
  UTILITIES_EXPENSE,
  ADDITIONAL_EXPENSES_KEYS,
  EXPENSES_IN_ORDER,
  EXPENSES_MODE,
  GROSS_REVENUE,
  REAL_ESTATE_TAXES,
} from 'shared/constants/expenses'
import { EXPENSE_HISTORY_TYPES } from 'shared/constants/expenses/expenseHistory'
import { DEFAULT_EXPENSES } from 'shared/constants/expenses/expenseForecast'
import { divide } from 'shared/utils/numberOperations'

import { ComparableExpensesRecords } from 'shared/utils/comparableExpensesRecords/expensesRecords'

import {
  calculateValuesMean,
  formatExpenseId,
  getRecentHistoricalExpenses,
  isProjectionPeriod,
  mapExpenseComps,
} from 'shared/helpers/incomeApproach/expenses/helpers'

import ExpensesCalculations from 'shared/report-calculations/income-approach/expenses/expenses-calculations'

import { PropertyTypes } from 'shared/constants'

import { generateTotalExpenseRangeSentence, getRealEstateTaxes } from './helpers'

type FormValues = {
  total: any
  squareFootage: number
  numberOfResidentialUnits: number
  numberOfCommercialUnits: number
  propertyType: string
  totalRoomCount: number
  realEstateTaxes: any
  recentHistoricalExpenses: any
  mappedComparableExpenses: any
  electricity: any
  electricityFuel: any
  fuel: any
  waterAndSewer: any
  generalAndAdministrative: any
  insurance: any
  legalAndProfessionalFees: any
  management: any
  miscellaneous: any
  payrollAndBenefits: any
  repairsAndMaintenance: any
  reserves: any
  utilities: any
  customExpenses: any
}

type NarrativeValues = {
  totalForecastedValue: any
  compTotalsMap: any
  totalExpenseRangeSentence: string
  includeRETaxesInOperatingExpenses: boolean
}

type ReportModel = {
  // eslint-disable-next-line camelcase
  property_information: {
    type: string
    basisForSFAnalysis: string
    squareFootageForCalculations: number
  }
  effectiveGrossIncome: number
  expenseDataSource: string
  // eslint-disable-next-line camelcase
  expense_forecast: {
    management: {
      percentOfEgiEnabled: boolean
      percentOfEgi: number
      concludedValue: number
      basis: string
    }
    total: {
      basis: string
      includeRETaxesInOperatingExpenses: boolean
    }
  }
  residential: {
    totalRoomCount: number
  }
}

type ExpenseCategory = { id: any }

const getTotalComps = (basisValue: string, includeRETaxes: boolean, mappedComparableExpenses: any) => {
  const totalComps: any[] = []
  const basisToFieldMap = {
    total: 'value',
    totalPerSF: 'sf',
    totalPerUnit: 'unit',
  }
  mappedComparableExpenses.forEach((comp: any) => {
    let totalPerBasisValue = comp[basisValue]
    if (!includeRETaxes) {
      const realEstateTaxes = comp.expenses.find((expense: any) => expense.id === DEFAULT_EXPENSES_KEYS.realEstateTaxes)
      // @ts-ignore
      const realEstateTaxesPerBasisValue = get(realEstateTaxes, basisToFieldMap[basisValue]) || 0
      totalPerBasisValue -= realEstateTaxesPerBasisValue
    }
    totalComps.push(totalPerBasisValue)
  })
  return totalComps
}

const mapData = (values: FormValues) => {
  const forecastedExpenses = getForecastExpenseTotalsPerBasis(values)

  const {
    squareFootage,
    numberOfResidentialUnits,
    numberOfCommercialUnits,
    propertyType,
    totalRoomCount,
    realEstateTaxes,
    mappedComparableExpenses,
  } = values
  const includeRETaxesInOperatingExpenses = get(values, 'total.includeRETaxesInOperatingExpenses', false)

  const compTotals = getTotalComps('total', includeRETaxesInOperatingExpenses, mappedComparableExpenses)
  const compTotalsPsf = getTotalComps('totalPerSF', includeRETaxesInOperatingExpenses, mappedComparableExpenses)
  const compTotalsUnit = getTotalComps('totalPerUnit', includeRETaxesInOperatingExpenses, mappedComparableExpenses)

  const compTotalsMap = {
    totalAvg: calculateValuesMean(compTotals),
    totalMin: min(compTotals),
    totalMax: max(compTotals),
    psfAvg: calculateValuesMean(compTotalsPsf),
    psfMin: min(compTotalsPsf),
    psfMax: max(compTotalsPsf),
    unitAvg: calculateValuesMean(compTotalsUnit),
    unitMin: min(compTotalsUnit),
    unitMax: max(compTotalsUnit),
  }
  const numberOfUnits = propertyType === PropertyTypes.COMMERCIAL ? numberOfCommercialUnits : numberOfResidentialUnits
  const totalForecastedValue = getTotalForecastedValue(
    forecastedExpenses,
    squareFootage,
    numberOfUnits,
    totalRoomCount,
    includeRETaxesInOperatingExpenses,
    realEstateTaxes
  )

  const recentHistoricalExpenses = get(values, 'recentHistoricalExpenses', [])

  const totalExpenseRangeSentence = generateTotalExpenseRangeSentence(
    recentHistoricalExpenses,
    compTotalsMap,
    totalForecastedValue
  )
  return {
    totalForecastedValue,
    compTotalsMap,
    totalExpenseRangeSentence,
    includeRETaxesInOperatingExpenses,
  }
}

export const mapDTO = (formValues: FormValues): NarrativeValues => {
  return mapData(formValues)
}

export const mapDataModel = (report: ReportModel) => {
  const expenseForecast = get(report, 'expense_forecast')
  if (!expenseForecast) {
    return undefined
  }

  const units = get(report, 'proforma_history.units') || []
  const expensesMode = get(report, 'proforma_history.expensesMode', EXPENSES_MODE.CUSTOM)

  const expenseCategories: ExpenseCategory[] =
    expensesMode === EXPENSES_MODE.STRICT
      ? get(report, 'proforma_history.strictCategories', [])
      : get(report, 'proforma_history.categories', [])
  const expenseHistory = units.map((unit: any) => {
    return {
      key: get(unit, 'id'),
      expenseDate: get(unit, 'date'),
      expensePeriod: EXPENSE_HISTORY_TYPES[get(unit, 'period')],
      expenseYear: get(unit, 'year'),
      expenseMonth: get(unit, 'month'),
      isProjected: unit.isProjected,
      expenses: [unit.grossRevenue || { value: null, id: GROSS_REVENUE.key }, ...unit.expenses],
      hiddenExpenses: unit.hiddenExpenses,
      total: unit.total,
      totalExcludingTaxes: unit.totalExcludingTaxes,
      noi: unit.noi,
    }
  })

  const numberOfResidentialUnits = get(report, 'property_information.resUnits', 0)
  const numberOfCommercialUnits = get(report, 'property_information.comUnits', 0)
  const propertyType = get(report, 'property_information.type', PropertyTypes.COMMERCIAL)
  const numberOfUnits = propertyType === PropertyTypes.COMMERCIAL ? numberOfCommercialUnits : numberOfResidentialUnits
  const squareFootage = get(report, 'property_information.squareFootageForCalculations')

  const { basis, includeRETaxesInOperatingExpenses } = expenseForecast.total

  const managementExpense = expenseForecast.management
  if (managementExpense.percentOfEgiEnabled) {
    const egi = report.effectiveGrossIncome
    const { percentOfEgi } = managementExpense
    const managementBasis = managementExpense.basis
    const totalValue = ExpensesCalculations.calculateManagementFees(egi, percentOfEgi)
    const concludedValue = ExpensesCalculations.calculateManagementFeesPerBasis(totalValue, managementBasis, {
      numberOfUnits,
      squareFootage,
    })
    expenseForecast.management.concludedValue = concludedValue
  }

  // @ts-ignore
  const expenseComps = get(report, 'expenseComps').toObject({ getters: true })

  const utilitiesExpensesMode = get(report, 'utilitiesExpensesMode')
  const showRETaxes = get(expenseComps, 'showRETaxes', false)
  const showEGI = get(expenseComps, 'showEGI', false)

  const { records: mappedComparableExpenses } = ComparableExpensesRecords.from({
    categories: get(expenseComps, 'categories', []),
    records: mapExpenseComps(get(expenseComps, 'comparables', [])),
    propertyType,
    includeRealEstateTaxes: showRETaxes,
    includeEGI: showEGI,
    utilitiesExpensesMode,
  }).normalizeCategories()

  const totalRoomCount = get(report, 'residential.totalRoomCount')
  const customExpenses = get(report, 'expense_forecast.customExpenses', [])
  const realEstateTaxes = getRealEstateTaxes(report)

  const basisMap = {
    sf: squareFootage,
    unit: numberOfUnits,
    room: totalRoomCount,
  }
  const hasExpenseDataSource = get(report, 'hasExpenseDataSource')

  const expenseHistoryTotals = expenseHistory.map(expenseYear => {
    const expenses = get(expenseYear, 'expenses', [])
    const updatedExpenses = expenses.map(expense => {
      const { value } = expense
      if (value) {
        expense.sf = divide(value, basisMap.sf)
        expense.unit = divide(value, basisMap.unit)
      }

      return expense
    })
    expenseYear.expenses = updatedExpenses

    return {
      ...expenseYear,
      totalExpenses: !hasExpenseDataSource
        ? { total: null, sf: null, unit: null }
        : {
            total: expenseYear.totalExcludingTaxes,
            sf: divide(expenseYear.totalExcludingTaxes, basisMap.sf),
            unit: divide(expenseYear.totalExcludingTaxes, basisMap.unit),
          },
    }
  })

  const expenseCategoriesIds = expenseCategories.filter(({ id }) => GROSS_REVENUE.key !== id).map(({ id }) => id)
  const customExpensesNames = customExpenses.map((cat: any) => formatExpenseId(cat.name))
  const defaultCategories = EXPENSES_IN_ORDER.map(expense => expense.category)
  const currentExpenseCategories = uniq([
    ...customExpensesNames,
    ...defaultCategories,
    formatExpenseId(DEFAULT_EXPENSES.management),
    formatExpenseId(DEFAULT_EXPENSES.reserves),
  ])
  if (includeRETaxesInOperatingExpenses) {
    currentExpenseCategories.push(REAL_ESTATE_TAXES.key)
  }

  const recentHistoricalExpenses = expenseHistoryTotals.map(item => {
    const expenses = item.expenses.filter(({ id }) => expenseCategoriesIds.includes(id))
    const expenseInfo = {
      year: item.expenseYear,
      expense: getRecentHistoricalExpenses(expenses, basis, currentExpenseCategories) || null,
      period: item.expensePeriod,
      isProjected: isProjectionPeriod(item.expensePeriod),
    }
    return expenseInfo
  })

  const forecastedValues = pick(expenseForecast, [
    ...Object.keys(DEFAULT_EXPENSES_KEYS),
    ELECTRICITY_FUEL_EXPENSE.key,
    UTILITIES_EXPENSE.key,
    ADDITIONAL_EXPENSES_KEYS.total,
  ])

  const mappedData = mapData({
    squareFootage,
    numberOfResidentialUnits,
    numberOfCommercialUnits,
    propertyType,
    totalRoomCount,
    realEstateTaxes,
    mappedComparableExpenses,
    recentHistoricalExpenses,
    customExpenses,
    ...(forecastedValues as any),
  })
  return mappedData
}
