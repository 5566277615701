export const BOROUGHS = ['MN', 'BK', 'BX', 'QN', 'SI']

export const BOROUGHS_FULL_NAME = ['Brooklyn', 'Bronx', 'Manhattan', 'Queens', 'Staten Island']

export const COUNTIES_FULL_NAME = ['New York', 'Kings', 'Bronx', 'Queens', 'Richmond']

export const BOROUGH_SHORT_CITY_LOOKUP = {
  MN: 'New York',
  BK: 'Brooklyn',
  BX: 'Bronx',
  QN: 'Queens',
  SI: 'Staten Island',
}

export const BOROUGH_CITY_LOOKUP = {
  Manhattan: 'New York',
  Brooklyn: 'Brooklyn',
  Bronx: 'Bronx',
  Queens: 'Queens',
  'Staten Island': 'Staten Island',
}

export const COUNTY_LONG_CITY_LOOKUP = {
  'New York County': 'New York',
  'Kings County': 'Brooklyn',
  'Bronx County': 'Bronx',
  'Queens County': 'Queens',
  'Richmond County': 'Staten Island',
}

export const BOROUGH_STATE_LOOKUP = {
  MN: 'New York, NY',
  SI: 'Staten Island, NY',
  QN: 'Queens, NY',
  BK: 'Brooklyn, NY',
  BX: 'Bronx, NY',
}

export const BOROUGH_OPTIONS = [
  { name: 'Manhattan', value: 'MN' },
  { name: 'Brooklyn', value: 'BK' },
  { name: 'Bronx', value: 'BX' },
  { name: 'Queens', value: 'QN' },
  { name: 'Staten Island', value: 'SI' },
]

export const BOROUGH_NUMBER = {
  MN: '1',
  BK: '3',
  BX: '2',
  QN: '4',
  SI: '5',
}

export const BOROUGH_EXTENSION = [
  { name: 'Manhattan', value: 'manhattan' },
  { name: 'Brooklyn', value: 'brooklyn' },
  { name: 'Bronx', value: 'bronx' },
  { name: 'Queens', value: 'ques' },
  { name: 'Staten Island', value: 'staten-island' },
]

export const BOROUGH_MAP_STYLE_LOOKUP = {
  MN: 'mnmappluto',
  BK: 'bkmappluto',
  BX: 'bxmappluto',
  QN: 'qnmappluto',
  SI: 'simappluto',
}

export const BOROUGH_FILTER_LIST = [
  { value: 'BK', label: 'Brooklyn' },
  { value: 'BX', label: 'Bronx' },
  { value: 'MN', label: 'Manhattan' },
  { value: 'QN', label: 'Queens' },
  { value: 'SI', label: 'Staten Island' },
]
