import React from 'react'
import { get, isObject, startCase } from 'lodash'
import { Field } from 'react-final-form'

import * as Api from '../../../core/api'

import ImageList from './ImageUploadList'

class ImageUploadList extends React.PureComponent {
  uploadImages = async ({ images }) => {
    const { images: uploadedImages } = await Api.uploadImages({ images })
    const oldImages = get(this.props, 'input.value')

    const newImages = [
      ...uploadedImages.map(image => ({ ...image, category: this.categoryName })),
      ...oldImages.filter(image => !image.isLoading),
    ]
    this.props.input.onChange(newImages)
    this.props.input.onFocus()
    this.props.input.onBlur()
  }

  onImagesUpload = ({ images, category }) => {
    this.props.input.onChange([...images.map(image => ({ cdnUrl: image.preview, isLoading: true })), ...this.images])
    this.uploadImages({ images, category })
  }

  onImagesDelete = ({ index }) => {
    const newImages = this.images.filter((image, imageIndex) => imageIndex !== index)
    this.props.input.onChange(newImages)
    this.props.input.onFocus()
    this.props.input.onBlur()
  }

  onImagesUpdate = ({ index, newUrl, isLandscape }) => {
    const newImages = this.images.map((image, searchIndex) => {
      return index === searchIndex ? { ...image, cdnUrl: newUrl, isLandscape } : image
    })
    this.props.input.onChange(newImages)
    this.props.input.onFocus()
    this.props.input.onBlur()
  }

  get categoryTitle() {
    const { category, editable } = this.props
    if (isObject(category)) {
      return category.categoryTitle
    }

    return editable ? category : startCase(category)
  }

  get categoryName() {
    return isObject(this.props.category) ? this.props.category.categoryName : this.props.category
  }

  get images() {
    return this.props.input.value || []
  }

  render() {
    return (
      <ImageList
        categoryName={this.categoryName}
        categoryTitle={this.categoryTitle}
        images={this.images}
        onImagesUpload={this.onImagesUpload}
        onImageDelete={this.onImagesDelete}
        onImageUpdate={this.onImagesUpdate}
        {...this.props}
      />
    )
  }
}

const ImageUploadListField = props => <Field {...props} component={ImageUploadList} />

export default ImageUploadListField
