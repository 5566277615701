import { get, lowerCase } from 'lodash'
import arrayToSentence from 'array-to-sentence'

import {
  BELOW_GRADE_TYPES,
  BELOW_GRADE_ACCESS_OPTIONS,
  OTHER_ACCESS_OPTION,
} from '../../../../constants/property/description'

const getBelowGradeAccess = (access = {}, otherAccess = '') => {
  return arrayToSentence(
    BELOW_GRADE_ACCESS_OPTIONS.filter(accessOption => access[accessOption]).map(accessOption =>
      accessOption === OTHER_ACCESS_OPTION ? otherAccess : accessOption
    )
  )
}

export const mapDTO = formValues => {
  const {
    belowGradeType,
    belowGrade: { access, state, otherAccess },
    valueAsComplete,
  } = formValues

  const belowGradeExists = belowGradeType && belowGradeType !== BELOW_GRADE_TYPES.NONE

  return {
    belowGradeType: lowerCase(belowGradeType),
    valueAsComplete,
    doNotValueAsComplete: !valueAsComplete,
    belowGradeAccess: getBelowGradeAccess(access, otherAccess),
    belowGradeExists,
    belowGradeDoesNotExist: !belowGradeExists,
    state: state ? state.toLowerCase() : undefined,
  }
}

export const mapDataModel = report => {
  const { belowGrade: { access, state, otherAccess } = {}, belowGradeType } = get(report, 'buildingDescription', {})
  const valueAsComplete = get(report, 'valueAsComplete')

  const belowGradeExists = belowGradeType && belowGradeType !== BELOW_GRADE_TYPES.NONE

  return {
    belowGradeType: lowerCase(belowGradeType),
    valueAsComplete,
    doNotValueAsComplete: !valueAsComplete,
    belowGradeAccess: getBelowGradeAccess(access, otherAccess),
    belowGradeExists,
    belowGradeDoesNotExist: !belowGradeExists,
    state: state ? state.toLowerCase() : undefined,
  }
}
