import { noop } from 'lodash'

import { ColumnDataTypeEnum, CELL_COLUMN_CONFIGURATIONS } from '@bowery-valuation/ui-components'

import { formatCurrencyFloat, formatPercentageString } from '../../../../shared/utils/numberFormatters'
import { BASIS_TYPES, INCOME_TYPE_NAMES } from '../../../../../../shared/constants/report/incomeApproach/taxes'

export const DEFAULT_OPTION_VALUE = 'default'
export const DEFAULT_OPTION_LABEL = 'Default'

export const PER_BASIS_TYPES = [BASIS_TYPES.PSF, BASIS_TYPES.PER_UNIT]

export const TAXABLE_ASSESSED_VALUE_TYPE_OPTIONS = [
  { value: 'final', label: 'FINAL' },
  { value: 'tentative', label: 'TENTATIVE' },
]

export const RENOVATION_COST_TYPE_OPTIONS = [
  { label: 'TOTAL', value: 'TOTAL' },
  { label: 'HARD', value: 'HARD' },
]

export const INCOME_TYPE_OPTIONS = [
  { label: INCOME_TYPE_NAMES.EGI, value: INCOME_TYPE_NAMES.EGI },
  { label: INCOME_TYPE_NAMES.PGI, value: INCOME_TYPE_NAMES.PGI },
]

export const ASSESSED_VALUE_COLUMN_NAMES = {
  item: 'item',
  actual: 'actual',
  actualPsf: 'actualPsf',
  actualPerUnit: 'actualPerUnit',
  transitional: 'transitional',
  transitionalPsf: 'transitionalPsf',
  transitionalPerUnit: 'transitionalPerUnit',
}

export const ASSESSED_VALUE_COLUMNS_ORDER = Object.keys(ASSESSED_VALUE_COLUMN_NAMES)

export const ASSESSED_VALUE_COLUMN_OPTIONS = {
  actual: [
    { label: 'Assessment PSF (Actual)', value: ASSESSED_VALUE_COLUMN_NAMES.actualPsf },
    {
      label: 'Assessment Per Unit (Actual)',
      value: ASSESSED_VALUE_COLUMN_NAMES.actualPerUnit,
    },
  ],
  transitional: [
    {
      label: 'Assessment PSF (Transitional)',
      value: ASSESSED_VALUE_COLUMN_NAMES.transitionalPsf,
    },
    { label: 'Assessment Per Unit (Transitional)', value: ASSESSED_VALUE_COLUMN_NAMES.transitionalPerUnit },
  ],
}

export const ASSESSED_VALUE_COLUMNS = {
  item: {
    name: ASSESSED_VALUE_COLUMN_NAMES.item,
    label: 'Item',
    type: ColumnDataTypeEnum.text,
    permanent: true,
  },
  actual: {
    name: ASSESSED_VALUE_COLUMN_NAMES.actual,
    label: 'Actual',
    type: ColumnDataTypeEnum.money,
    permanent: true,
  },
  actualPsf: {
    name: ASSESSED_VALUE_COLUMN_NAMES.actualPsf,
    label: 'Actual PSF',
    type: ColumnDataTypeEnum.money,
    permanent: false,
    readOnly: true,
  },
  actualPerUnit: {
    name: ASSESSED_VALUE_COLUMN_NAMES.actualPerUnit,
    label: 'Actual Per Unit',
    type: ColumnDataTypeEnum.money,
    permanent: false,
    readOnly: true,
  },
  transitional: {
    name: ASSESSED_VALUE_COLUMN_NAMES.transitional,
    label: 'Transitional',
    type: ColumnDataTypeEnum.money,
    permanent: true,
  },
  transitionalPsf: {
    name: ASSESSED_VALUE_COLUMN_NAMES.transitionalPsf,
    label: 'Transitional PSF',
    type: ColumnDataTypeEnum.money,
    permanent: false,
    readOnly: true,
  },
  transitionalPerUnit: {
    name: ASSESSED_VALUE_COLUMN_NAMES.transitionalPerUnit,
    label: 'Transitional Per Unit',
    type: ColumnDataTypeEnum.money,
    permanent: false,
    readOnly: true,
  },
}

export const EDITABLE_ASSESSED_VALUE_COLUMNS = [
  ASSESSED_VALUE_COLUMN_NAMES.item,
  ASSESSED_VALUE_COLUMN_NAMES.actual,
  ASSESSED_VALUE_COLUMN_NAMES.transitional,
]

export const projectedTabNoopFieldValues = {
  onColumnDragEnd: noop,
  onRowsDragEnd: noop,
  onColumnDelete: noop,
  onColumnUpdate: noop,
}

export const MIN_TAX_RATE_EFFECTIVE_YEAR = 1990

export const TOOLTIP_TEXT = 'This text will appear in the Assessed Value & Real Estate Taxes section of your report.'

export const CELL_EDITOR_SELECTORS = {
  taxRate: {
    component: 'numericCellEditor',
    params: {
      ...CELL_COLUMN_CONFIGURATIONS.percent.params,
      inputProps: { formatterProps: { decimalScale: 10 } },
    },
  },
  percentTwoDecimals: {
    component: 'numericCellEditor',
    params: {
      ...CELL_COLUMN_CONFIGURATIONS.percent.params,
      inputProps: { formatterProps: { decimalScale: 2 } },
    },
  },
  percentNoDecimals: {
    component: 'numericCellEditor',
    params: {
      ...CELL_COLUMN_CONFIGURATIONS.percent.params,
      inputProps: { formatterProps: { decimalScale: 0 } },
    },
  },
  moneyNoDecimals: {
    component: 'numericCellEditor',
    params: {
      ...CELL_COLUMN_CONFIGURATIONS.money.params,
      inputProps: { formatterProps: { decimalScale: 0 } },
    },
  },
  moneyTwoDecimals: {
    component: 'numericCellEditor',
    params: {
      ...CELL_COLUMN_CONFIGURATIONS.money.params,
      inputProps: { formatterProps: { decimalScale: 2 } },
    },
  },
}

export const valueCellRendererFramework = ({ value, data }) => {
  if (data.isAdditionalTaxRateRow) {
    return formatPercentageString(value, 10)
  }

  if (data.isAdditionalSpecialAssessmentsRow) {
    return formatCurrencyFloat(value)
  }

  return value ?? null
}

// todo - consolidate with shared helper: client/src/shared/utils/rowBasedTable.ts
export const getCustomColumnConfig = column => {
  return {
    cellEditorSelector: column.cellEditorSelector,
    cellEditorFramework: undefined,
    autoHeight: column.autoHeight || false,
    textWrap: column.autoHeight || false,
  }
}

export const additionalTaxRateToRow = additionalTaxRate => {
  return {
    _id: additionalTaxRate._id,
    id: additionalTaxRate._id,
    label: additionalTaxRate.label,
    value: additionalTaxRate.value,
    type: ColumnDataTypeEnum.percent,
    isAdditionalTaxRateRow: true,
    isEditableRow: true,
    suppressMovable: true,
    permanent: false,
  }
}

export const additionalSpecialAssessmentsToRow = additionalSpecialAssessments => {
  return {
    _id: additionalSpecialAssessments._id,
    id: additionalSpecialAssessments._id,
    label: additionalSpecialAssessments.label,
    value: additionalSpecialAssessments.value,
    type: ColumnDataTypeEnum.money,
    isAdditionalSpecialAssessmentsRow: true,
    isEditableRow: true,
    suppressMovable: true,
    permanent: false,
  }
}
