import React from 'react'
import PropTypes from 'prop-types'
import { get } from 'lodash'

import { Paper, Stack, Typography } from '@mui/material'

import { getTaxRateName } from 'shared/report-calculations/income-approach/tax/helpers'

import { formatPercentageString } from '../../../../shared/utils/numberFormatters'
import { GeneratedComment, CheckboxWithLabel } from '../../../../shared/components'

import { TOOLTIP_TEXT } from './constants'
import TaxRatePicker from './TaxRatePicker'
import OpinionApproach from './OpinionApproach'
import PercentOfRenovationsApproach from './PercentOfRenovationsApproach'
import PercentOfIncomeApproach from './PercentOfIncomeApproach'
import EqualizationApproach from './EqualizationApproach'

class ProjectedLiability extends React.PureComponent {
  render() {
    const { form } = this.props
    const { taxRate, taxableAssessedValue, projected, taxClass, jurisdictionName } = form.values

    const includeProjectedTaxLiabilityInExport = projected.includedInExport

    let projectedTaxRate = taxRate
    let projectedTaxClass = taxClass
    if (includeProjectedTaxLiabilityInExport && projected.overrideTaxClass) {
      projectedTaxRate = projected.taxRate || taxRate
      projectedTaxClass = projected.taxClass || taxClass
    }
    const projectedTaxRateName = getTaxRateName(projectedTaxRate, projectedTaxClass, jurisdictionName)

    const getProjectedTaxLiabilityDiscussion = values => {
      const {
        subjectInfo: { address },
      } = values
      const overrideTaxClass = get(values, 'projected.overrideTaxClass')
      const currentTaxRate = get(values, 'taxRate.value')
      const projectedTaxRate = get(values, 'projected.taxRate.value')

      const projectedTaxClass = get(values, 'projected.taxClass')
      const projectedClassName = get(projectedTaxClass, 'name')

      const taxRate = overrideTaxClass ? projectedTaxRate : currentTaxRate
      const taxRatePercentage = formatPercentageString(taxRate, 10)

      // eslint-disable-next-line max-len
      const overrideTaxClassSentence = `${address} will be designated as a ${projectedClassName} property upon stabilization.`
      const projectedTaxSentence =
        `In order to determine the projected tax liability, ` +
        `we have applied a tax rate of ${taxRatePercentage} to our projection of the assessed value.`

      return overrideTaxClass ? `${overrideTaxClassSentence} ${projectedTaxSentence}` : `${projectedTaxSentence}`
    }

    return (
      <Stack spacing={1}>
        <Paper>
          <Stack spacing={1}>
            <CheckboxWithLabel
              data-qa="projected-includedInExport-checkbox"
              label="Include in export"
              name="projected.includedInExport"
            />

            {projected.includedInExport && (
              <CheckboxWithLabel
                data-qa="projected-overrideTaxClass-checkbox"
                label="Override Tax Class"
                name="projected.overrideTaxClass"
              />
            )}

            {projected.overrideTaxClass && (
              <TaxRatePicker classFieldName="projected.taxClass" form={form} rateFieldName="projected.taxRate" />
            )}

            <GeneratedComment
              dataPath="projected.projectedTaxLiabilityDiscussion"
              getGeneratedText={getProjectedTaxLiabilityDiscussion}
              isDynamicContent
              label="Projected Tax Liability Discussion"
              title="Generated Commentary"
              tooltipText={TOOLTIP_TEXT}
            />
          </Stack>
        </Paper>
        {includeProjectedTaxLiabilityInExport && (
          <Stack spacing={1}>
            <Paper>
              <Typography variant="h6">Opinion Provided</Typography>

              <OpinionApproach form={form} taxRate={projectedTaxRate} taxRateName={projectedTaxRateName} />
            </Paper>
            <Paper>
              <Typography variant="h6">Percent of Renovations</Typography>

              <PercentOfRenovationsApproach
                currentTaxableAssessedValue={taxableAssessedValue}
                form={form}
                taxRate={projectedTaxRate}
                taxRateName={projectedTaxRateName}
              />
            </Paper>
            <Paper>
              <Typography variant="h6">Percent of Income</Typography>

              <PercentOfIncomeApproach form={form} taxRate={projectedTaxRate} taxRateName={projectedTaxRateName} />
            </Paper>
            <Paper>
              <Typography variant="h6">Equalized Market Value</Typography>

              <EqualizationApproach form={form} taxRate={projectedTaxRate} taxRateName={projectedTaxRateName} />
            </Paper>
          </Stack>
        )}
      </Stack>
    )
  }
}

ProjectedLiability.propTypes = {
  taxRate: PropTypes.number,
}

ProjectedLiability.defaultProps = {
  taxRate: 0,
}

export default ProjectedLiability
