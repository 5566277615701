import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { get } from 'lodash'

import { BUILDING_LABEL_VALUE_PAIR, UNIT_MIX_COLUMNS } from '../../../constants'

import { getUnitMixItemWithCalculatedAverages } from '../../UnitMix/helpers'

import { UnitMixTable } from '../../UnitMix/UnitMixTable'

import EditLayout from './EditLayout'

class UnitMix extends React.PureComponent {
  static propTypes = {
    unitMixes: PropTypes.arrayOf(PropTypes.object),
    isEstimatedUnitMixSquareFootage: PropTypes.bool,
  }
  render() {
    return (
      <EditLayout
        title={BUILDING_LABEL_VALUE_PAIR.UnitMix.heading}
        redirectTo={BUILDING_LABEL_VALUE_PAIR.UnitMix.menuItem}
      >
        <UnitMixTable
          columns={UNIT_MIX_COLUMNS}
          unitMix={this.props.unitMixes}
          isEstimatedUnitMixSquareFootage={this.props.isEstimatedUnitMixSquareFootage}
          disabled
        />
      </EditLayout>
    )
  }
}

export default connect(state => {
  const isEstimatedUnitMixSquareFootage = get(state, 'buildingComp.unitMix.isEstimatedUnitMixSquareFootage')
  const unitMixes = get(state, 'buildingComp.unitMix.unitMix', []).map(getUnitMixItemWithCalculatedAverages)
  return {
    unitMixes,
    isEstimatedUnitMixSquareFootage,
  }
})(UnitMix)
