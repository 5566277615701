export const ImageSizes = {
  PPI: 96,
  LANDSCAPE_WIDTH: 6.5,
  LANDSCAPE_HEIGHT: 4.18,
  PORTRAIT_WIDTH: 3.25,
  PORTRAIT_HEIGHT: 4.18,
}

export const CLOUDINARY_REGEX =
  /^(.+\.cloudinary\.com\/[^/]+\/(?:(?:image|video)\/)?(?:(?:upload|fetch)\/)?)(?:((?:[^_/]+_[^,/]+,?)*)\/)?((?:v(?:\d+|\w{1,2})\/)?(?:[^.^\s]+)(?:\.(?:.+))?)$/
export const ANGLE_REGEX = /a_(-?\d+)/

export const QUARTER_ROTATION = 90
export const FULL_ROTATION = 360
export const EXTENSION_REGEX = /\.\w+/
export const WIDTH_REGEX = /w_(\d+)/
export const AVATAR_WIDTH_LIMIT = 1920
export const PUBLIC_ID_CLOUDINARY_REGEX =
  /^.+\.cloudinary\.com\/(?:[^/]+\/)(?:(?:image|video)\/)(?:(?:upload|fetch)\/)(?:(?:[^_/]+_[^,/]+,?)*\/)*((v(?:\d+|\w{1,2})?\/)?[^.^\s]+\..+)?$/
