import { GeneratedText, gtIfElse, gtTemplate } from '../../../narrative/GeneratedText'

import { mapDataModel, mapDTO } from './mappings'

const generate = ({ generatedText }) => {
  const gt = new GeneratedText()

  gt.t`${gtIfElse('hasGeneratedText', gtTemplate`${generatedText}`, gtTemplate``)}`

  return gt.getParts()
}

export default { generate, mapDTO, mapDataModel }
