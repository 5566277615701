import { getPartsFromString } from '../../../../narrative/GeneratedText'

const generate = () => {
  const costAppraochMethodology = `The Cost Approach is traditionally a good indicator of value
  when properties being appraised are new or close to new.`
  const gtParts = getPartsFromString(costAppraochMethodology)

  return gtParts
}

export default { generate }
