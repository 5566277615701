import React from 'react'
import PropTypes from 'prop-types'

import { CardMedia, IconButton, Tooltip, Typography } from '@mui/material'
import { withStyles } from '@mui/styles'
import { get, isEmpty, noop } from 'lodash'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import { ReactSVG } from 'react-svg'
import { compose, pure } from 'recompose'

import internalLinkIcon from '../../../images/internal_link_icon.svg'
import EmptySection from '../EmptySection'
import SectionWrapper from '../SectionWrapper'

const styles = theme => ({
  iconButton: {
    width: 45,
    height: 45,
    padding: '10px 10px 5px 5px',
  },
  tooltip: {
    fontSize: 12,
    color: theme.palette.common.white,
    backgroundColor: theme.palette.grey[600],
  },
  content: {
    height: '100%',
    padding: `0 ${theme.spacing(2)}`,
  },
  photoGroup: {
    padding: `${theme.spacing(1)} 0`,
    boxSizing: 'border-box',
  },
  photoGroupName: {
    fontSize: 16,
    fontWeight: 'normal',
    letterSpacing: 0.15,
    paddingBottom: theme.spacing(1),
    color: '#212121',
  },
  photoWrapper: {
    width: 220,
    height: 220,
    flex: '0 48%',
    marginBottom: '4%',
  },
  photosContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-between',
  },
  photo: {
    height: 0,
    paddingTop: '100%',
    borderRadius: 4,
  },
})

const PhotoGallerySection = ({ classes, reportId, photoGroups }) => (
  <SectionWrapper
    title="Photo Gallery"
    rightElement={
      <Link to={`/report/${reportId}/property-photos`}>
        <Tooltip title="Go To Property Photos" placement="left" classes={{ tooltip: classes.tooltip }}>
          <IconButton onClick={noop} className={classes.iconButton}>
            <ReactSVG src={internalLinkIcon} />
          </IconButton>
        </Tooltip>
      </Link>
    }
  >
    <div className={classes.content}>
      {isEmpty(photoGroups) ? (
        <EmptySection title="This report has no property photos" />
      ) : (
        photoGroups.map(({ categoryName, photos }) => (
          <div className={classes.photoGroup} key={categoryName}>
            <Typography variant="subtitle2" className={classes.photoGroupName}>
              {categoryName}
            </Typography>
            <div className={classes.photosContainer}>
              {photos.map((photoUrl, index) => (
                <div className={classes.photoWrapper} key={index}>
                  <CardMedia className={classes.photo} image={photoUrl} />
                </div>
              ))}
            </div>
          </div>
        ))
      )}
    </div>
  </SectionWrapper>
)

PhotoGallerySection.propTypes = {
  classes: PropTypes.object.isRequired,
  reportId: PropTypes.string.isRequired,
  photoGroups: PropTypes.array.isRequired,
}

const mapStateToProps = state => {
  const reportId = get(state, 'report.reportData._id')
  const propertyPhotos = get(state, 'report.reportData.propertyInformation.propertyPhotos')
  const { defaultCategories, customCategories } = propertyPhotos || {}

  const defaultCategoryGroups = defaultCategories
    .filter(({ photos }) => !isEmpty(photos))
    .map(({ categoryTitle, photos }) => ({
      categoryName: categoryTitle,
      photos: photos.map(photo => photo.cdnUrl),
    }))
  const customCategoryGroups = customCategories.map(({ category, photos }) => ({
    categoryName: category,
    photos: photos.map(photo => photo.cdnUrl),
  }))
  const photoGroups = [...defaultCategoryGroups, ...customCategoryGroups]

  return {
    reportId,
    photoGroups,
  }
}

export default compose(pure, connect(mapStateToProps), withStyles(styles))(PhotoGallerySection)
