import React from 'react'
import PropTypes from 'prop-types'

import { findIndex } from 'lodash'
import { isEventKey } from 'keycode'

import { IconButton, Stack, TextField } from '@mui/material'
import AddCircle from '@mui/icons-material/AddCircle'

import { ImageUploadListField as ImageUploadList } from 'client-shared/components/ImageList'

import DeleteAllPhotosModal from './DeleteAllPhotosModal'

const CUSTOM_CATEGORIES = 'customCategories'
const EXISTING_CATEGORY_ERROR = 'Category already exists!'
const NEW_CATEGORY_ERROR = 'Category Name is Required!'

class CustomCategoriesList extends React.PureComponent {
  state = {
    newImageCategory: '',
    error: '',
    categoryToDelete: '',
  }

  onCategorySave = ({ oldCategory, newCategory, images }) => {
    const { categories, changeCategory, name } = this.props
    const categoryIndex = findIndex(categories, ({ category }) => category === oldCategory)
    changeCategory(`${name}[${categoryIndex}].category`, newCategory)
  }

  onCategoryDelete = categoryToDelete => {
    const { categories, removeCategory, name } = this.props
    const categoryIndex = findIndex(categories, ({ category }) => category === categoryToDelete.category)
    removeCategory(name, categoryIndex)
    this.setState({ categoryToDelete: null })
  }

  onNewImageCategoryChange = event => {
    this.setState({
      newImageCategory: event.target.value,
      error: '',
    })
  }

  onNewImageCategoryKeyDown = event => {
    if (isEventKey(event, 'enter')) {
      this.onCategoryAdd(event)
      event.stopPropagation()
      event.preventDefault()
    }
  }

  showDialog = categoryToDelete => {
    const { categories } = this.props

    const category = categories.find(({ category }) => category === categoryToDelete)
    if (category.hasImages) {
      this.setState({ categoryToDelete })
    } else {
      this.onCategoryDelete(category)
    }
  }

  closeDialog = () => {
    this.setState({ categoryToDelete: null })
  }

  handleDialogDelete = () => {
    const { categories } = this.props
    const { categoryToDelete } = this.state
    const category = categories.find(({ category }) => category === categoryToDelete)
    this.onCategoryDelete(category)
    this.closeDialog()
  }

  onCategoryAdd = () => {
    const { newImageCategory } = this.state
    const { categories, addCategory, name } = this.props

    if (!newImageCategory) {
      return this.setState({ error: NEW_CATEGORY_ERROR })
    }

    const sameNameCategory = categories.find(({ category }) => category === newImageCategory)
    if (sameNameCategory) {
      return this.setState({ error: EXISTING_CATEGORY_ERROR })
    }

    addCategory(name, { category: newImageCategory, photos: [] })
    this.setState({ newImageCategory: '' })
  }

  render() {
    const { categories, name } = this.props
    const { categoryToDelete, error, newImageCategory } = this.state
    const { closeDialog, handleDialogDelete } = this

    return (
      <Stack spacing={2}>
        <Stack alignItems="flex-start" direction="row" spacing={2}>
          <TextField
            data-qa="New Image Category-input"
            error={!!error}
            helperText={error}
            label="New Image Category"
            onChange={this.onNewImageCategoryChange}
            onKeyDown={this.onNewImageCategoryKeyDown}
            value={newImageCategory}
            variant="standard"
          />
          <IconButton aria-label="add" data-qa="add-button" color="success" onClick={this.onCategoryAdd}>
            <AddCircle fontSize="large" />
          </IconButton>
        </Stack>
        {categories.map(({ category, index }) => (
          <ImageUploadList
            categories={categories.map(category => category.category)}
            category={category}
            draggable
            editable
            key={category}
            name={`${name}.${index}.photos`}
            onCategoryDelete={this.showDialog}
            onCategorySave={this.onCategorySave}
          />
        ))}
        <DeleteAllPhotosModal {...{ categoryToDelete, closeDialog, handleDialogDelete }} />
      </Stack>
    )
  }
}

CustomCategoriesList.propTypes = {
  addCategory: PropTypes.func.isRequired,
  categories: PropTypes.arrayOf(PropTypes.object).isRequired,
  changeCategory: PropTypes.func.isRequired,
  name: PropTypes.string,
  removeCategory: PropTypes.func.isRequired,
}

CustomCategoriesList.defaultProps = {
  name: CUSTOM_CATEGORIES,
}

export default CustomCategoriesList
