import {
  GeneratedText,
  gtLocked,
  gtTemplate,
  addConditionals,
  gtIfElse,
  gtConditional,
} from '../../../narrative/GeneratedText'
import { simplePluralize as pluralize, pluralizeBeVerb } from '../../index'

import { mapDTO, mapDataModel } from './mappings'

const generate = values => {
  const { unitsCount, occupiedUnitLeasesWord, forecastedUnitsWord, vacantUnitTermsWord, vacantUnitsVerb } = values
  const unitCountGt = gtLocked('unitsCount', null, 'Unit count')
  const unitsWord = pluralize('unit', unitsCount)
  const termsWord = pluralize('term', unitsCount)
  const unitsVerb = pluralizeBeVerb(unitsCount)

  const gt = new GeneratedText()

  gt.t`${gtIfElse(
    'mentionRenovations',
    gtTemplate`Upon completion of the renovations, the subject will contain ${unitCountGt} commercial ${unitsWord}.`,
    gtTemplate`The subject contains ${unitCountGt} commercial ${unitsWord}.`
  )}`

  addConditionals(gt, {
    forecastedUnits: [
      ...gtTemplate` Commercial ${gtLocked('forecastedUnitsWord', null, 'Units word')} ${gtConditional(
        'isVacantUnits',
        gtTemplate`${gtLocked('vacantUnitsList')} ${gtLocked('vacantUnitsVerb')} currently vacant`
      )}${gtConditional(
        'hasOwnerOccupiedUnits',
        gtTemplate`${gtConditional('isVacantUnits', gtTemplate` and`)} ${gtLocked('ownerOccupiedUnitsList')} ${gtLocked(
          'ownerOccupiedUnitsVerb'
        )} currently owner occupied`
      )}.
      We have forecasted market rent and market lease terms for the ${gtConditional(
        'isVacantUnits',
        gtTemplate`vacant${gtConditional('hasOwnerOccupiedUnits', gtTemplate` and `)}`
      )}${gtConditional(
        'hasOwnerOccupiedUnits',
        gtTemplate`owner occupied`
      )} ${forecastedUnitsWord} based on the occupied unit ${occupiedUnitLeasesWord}, 
      the comparables above, and our research of the subject's market.
      The forecasted lease ${vacantUnitTermsWord} ${vacantUnitsVerb} summarized below.`,
    ],
    noForecastedUnits: [...gtTemplate` The ${termsWord} ${unitsVerb} summarized below.`],
  })

  return gt.getParts()
}

export default { generate, mapDTO, mapDataModel }
