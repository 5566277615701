import PropTypes from 'prop-types'

export const floodDataShape = PropTypes.shape({
  reportId: PropTypes.string,
  floodData: PropTypes.shape({
    panel: PropTypes.string,
    zone: PropTypes.string,
    effectiveDate: PropTypes.any,
    isPulled: PropTypes.bool,
    isLoading: PropTypes.bool,
    isError: PropTypes.bool,
  }),
  floodMap: PropTypes.shape({
    url: PropTypes.string,
    isOverridden: PropTypes.bool,
    isPulled: PropTypes.bool,
    isLoading: PropTypes.bool,
    isError: PropTypes.bool,
  }),
})
