import { defineAction } from 'redux-define'

import { DEFAULT_API_ACTION_STATES } from '../../shared/constants'

export const CREATE_REPORT = defineAction('CREATE_REPORT', DEFAULT_API_ACTION_STATES)

export const INITIALIZE_CREATING_REPORT = defineAction('INITIALIZE_CREATING_REPORT')

export const DELETE_REPORT = defineAction('DELETE_REPORT', DEFAULT_API_ACTION_STATES)

export const REPORTS_FETCH = defineAction('REPORTS_FETCH', DEFAULT_API_ACTION_STATES)

export const REPORTS_CLEAR = defineAction('REPORTS_CLEAR')

export const CHANGE_REPORT_FILTERS = defineAction('CHANGE_REPORT_FILTERS')

export const SET_REPORT_FILTERS = defineAction('SET_REPORT_FILTERS')

export const CHANGE_REPORT_SORTING = defineAction('CHANGE_REPORT_SORTING')

export const CHANGE_REPORT_TAB = defineAction('CHANGE_REPORT_TAB')

export const CLEAR_REPORT_FILTERS = defineAction('CLEAR_REPORT_FILTERS')
export const CLEAR_REPORTS = defineAction('CLEAR_REPORTS')

export const DUPLICATE_REPORT = defineAction('DUPLICATE_REPORT', DEFAULT_API_ACTION_STATES)

export const CHANGE_REPORT_VISIBILITY = defineAction('CHANGE_REPORT_VISIBILITY', DEFAULT_API_ACTION_STATES)
export const SET_SHOW_LOADING = defineAction('SET_SHOW_LOADING')
export const INITIAL_REPORTS_FETCH = defineAction('INITIAL_REPORTS_FETCH')

export const INITIALIZE_CREATED_REPORT = defineAction('INITIALIZE_CREATED_REPORT')
