import { get } from 'lodash'
import { push } from 'react-router-redux'

import { put, takeLatest, select } from 'redux-saga/effects'

import { successNotification, errorNotification } from '../../../shared/redux/actions/notifications'

import { CMS_ORGANIZATION_FETCH, CMS_ORGANIZATION_UPDATE } from '../actionTypes/cms'
import {
  cmsOrganizationFetchRequest,
  cmsOrganizationFetchReceive,
  cmsOrganizationFetchFail,
  cmsOrganizationUpdateRequest,
  cmsOrganizationUpdateReceive,
  cmsOrganizationUpdateFail,
} from '../actions/cms'
import * as Api from '../../../core/api'

function* cmsOrganizationFetch(action) {
  const organizationId = get(action, 'payload')

  if (organizationId) {
    try {
      yield put(cmsOrganizationFetchRequest(organizationId))
      const organizationData = yield Api.fetchOrganization(organizationId)
      const cmsStructure = yield Api.fetchCmsForms(organizationId)

      yield put(
        cmsOrganizationFetchReceive({
          organizationData,
          cmsStructure,
        })
      )
    } catch (err) {
      yield put(cmsOrganizationFetchFail(err))
    }
  } else {
    try {
      yield put(cmsOrganizationFetchRequest())
      const organizationData = yield Api.fetchOrganization()
      const cmsStructure = yield Api.fetchCmsForms()

      yield put(push(`/cms/${organizationData._id}`))

      yield put(
        cmsOrganizationFetchReceive({
          organizationData,
          cmsStructure,
        })
      )
    } catch (err) {
      yield put(cmsOrganizationFetchFail(err))
    }
  }
}

function* updateCmsOrganization({ payload }) {
  const organizationId = yield select(state => state.cms.organizationData._id)
  const { formDataPath, values } = payload
  try {
    yield put(cmsOrganizationUpdateRequest(payload))

    const response = yield Api.updateCms(organizationId, formDataPath, values)

    yield put(cmsOrganizationUpdateReceive(response))
    yield put(successNotification({ message: 'CMS info was updated' }))
  } catch (err) {
    yield put(cmsOrganizationUpdateFail(err))
    yield put(
      errorNotification({
        message:
          `There was an error while trying to save CMS info, please contact us quoting ` +
          `Organization Id '${organizationId}' and form '${formDataPath}' for assistance. ` +
          `You can continue working on other forms while this issue is resolved.`,
      })
    )
  }
}

export default [
  takeLatest(CMS_ORGANIZATION_FETCH.ACTION, cmsOrganizationFetch),
  takeLatest(CMS_ORGANIZATION_UPDATE.ACTION, updateCmsOrganization),
]
