import React from 'react'
import { createMuiTheme, MuiThemeProvider } from '@material-ui/core/styles'

const theme = createMuiTheme({
  overrides: {
    MuiTable: {
      root: {
        border: 'solid 1px rgba(0, 0, 0, 0.38)',
        borderRadius: 4,
        overflow: 'hidden',
        borderCollapse: 'initial',
      },
    },
    MuiTableCell: {
      body: {
        color: 'rgba(0, 0, 0, 0.56)',
        fontSize: 14,
        maxHeight: 50,
        borderBottom: 'solid 1px rgba(0, 0, 0, 0.38)',
      },
      head: {
        color: 'white',
        fontSize: 12,
        fontWeight: 'normal',
        height: 50,
        border: 'none',
        backgroundColor: 'rgba(0, 0, 0, 0.54)',
      },
    },
    MuiTableRow: {
      root: {
        height: 50,
        '&:last-of-type': {
          '& td': {
            borderBottom: 'none',
          },
        },
      },
      head: {
        border: 'none',
        height: 50,
      },
    },
  },
})

export default ({ children }) => (
  <MuiThemeProvider theme={outerTheme => ({ ...outerTheme, ...theme })}>{children}</MuiThemeProvider>
)
