import { GeneratedText, gtIfElse, gtLocked, gtTemplate } from '../../../narrative/GeneratedText'

import { mapDTO, mapDataModel } from './mappings'

const generate = () => {
  const gt = new GeneratedText()

  const reportSenderGt = gtLocked('reportSender', null, 'Sender')
  const reportDateGt = gtLocked('reportDate', 'shortDateFormat', 'Date of Report')

  gt.addParts(
    gtIfElse(
      'hasReport',
      gtTemplate`We did receive a Property Condition Report from ${reportSenderGt} dated ${reportDateGt}.`,
      gtTemplate`No Property Condition Report was given in conjunction with this appraisal.`
    )
  )

  return gt.getParts()
}

export default { generate, mapDTO, mapDataModel }
