import React from 'react'
import PropTypes from 'prop-types'
import { FormControl, withStyles } from '@material-ui/core'
import { isEmpty, get } from 'lodash'

import SingleLineImageList from './SingleLineImageList'
import DraggableSingleImageList from './DraggableSingleImageList'

import ImageListCategory from './ImageListCategory'

const styles = {
  root: {
    marginTop: 16,
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-around',
    overflowX: 'auto',
  },
}

class ImageUploadList extends React.PureComponent {
  static propTypes = {
    images: PropTypes.arrayOf(
      PropTypes.shape({
        category: PropTypes.string,
        cdnUrl: PropTypes.string.isRequired,
        uuid: PropTypes.string,
      })
    ).isRequired,
    onImagesUpload: PropTypes.func,
    onImageDelete: PropTypes.func,
    onImageUpdate: PropTypes.func,
    onCategorySave: PropTypes.func,
    onCategoryDelete: PropTypes.func,
    categoryTitle: PropTypes.string.isRequired,
    categoryName: PropTypes.string.isRequired,
    editable: PropTypes.bool,
    deletable: PropTypes.bool,
    categories: PropTypes.arrayOf(PropTypes.string),
    draggable: PropTypes.bool,
    readOnly: PropTypes.bool,
  }

  static defaultProps = {
    editable: false,
    deletable: true,
    error: '',
    images: [],
    onImagesUpload: () => {},
    onImageDelete: () => {},
    onImageUpdate: () => {},
    onCategorySave: () => {},
    onCategoryDelete: () => {},
    categories: [],
    draggable: false,
  }

  get categoryTitleName() {
    return this.props.input.name.replace('photos', 'categoryTitle')
  }

  onUpload = acceptedImages => {
    if (!isEmpty(acceptedImages)) {
      this.props.onImagesUpload({
        images: acceptedImages,
        category: this.props.categoryName,
      })
    }
  }

  onImageDelete = ({ index }) => {
    this.props.onImageDelete(index)
  }

  rotateImage = ({ index, newUrl }) => {
    const { images } = this.props
    const { isLandscape } = get(images, index, {})
    this.props.onImageUpdate({ newUrl: newUrl, index, isLandscape: !isLandscape })
  }

  onCategorySave = ({ newCategory, oldCategory }) => {
    const { images } = this.props
    this.props.onCategorySave({ newCategory, oldCategory, images })
  }

  render() {
    const {
      classes,
      input,
      images,
      categoryTitle,
      onImageDelete,
      onCategoryDelete,
      readOnly,
      categories,
      draggable,
      categoryName,
      deletable,
    } = this.props
    const disabled = images.some(image => image.isLoading)
    const ImageListComponent = draggable ? DraggableSingleImageList : SingleLineImageList

    return (
      <FormControl className={classes.root}>
        <ImageListCategory
          name={this.categoryTitleName}
          category={categoryTitle}
          onCategorySave={this.onCategorySave}
          categories={categories}
          onCategoryDelete={onCategoryDelete}
          editable={this.props.editable && !disabled}
          deletable={deletable}
        />
        <ImageListComponent
          fieldName={input.name}
          category={categoryName}
          disabled={disabled}
          images={images}
          onDelete={onImageDelete}
          onUpload={this.onUpload}
          onRotate={this.rotateImage}
          readOnly={readOnly}
          categoryTitle={categoryTitle}
        />
      </FormControl>
    )
  }
}

export default withStyles(styles)(ImageUploadList)
