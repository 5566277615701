import React from 'react'

import { Divider } from '@mui/material'
import { withStyles } from '@mui/styles'
import { isEqual } from 'lodash'
import { compose, shouldUpdate } from 'recompose'

import {
  GENERIC_SUBSECTION_KEY,
  PREVIEW_AND_EDIT_KEY,
  REVIEW_AND_EXPORT_KEY,
} from 'shared/constants/report/keysAndDataPaths'

import { SIDE_BAR_ICONS } from '../../../../shared/layout/constants'
import layoutStyles from '../../../../shared/layout/styles'

import NavigationSideBarTab from './NavigationSideBarTab'

const styles = theme => ({
  section: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    paddingBottom: theme.spacing(2),

    '&:nth-child(2) $divider': {
      display: 'none',
    },
  },
  itemContainer: {
    padding: 4,
    borderRadius: 4,
    paddingBottom: theme.spacing(2),
    boxSizing: 'border-box',
    '&:hover': {
      cursor: 'pointer',
    },
  },
  divider: {
    ...layoutStyles.divider,
  },
})

class NavigationSideBarSections extends React.PureComponent {
  get sections() {
    const { sections } = this.props

    const secondTabStartIndex = sections.findIndex(
      section =>
        section.key === REVIEW_AND_EXPORT_KEY ||
        section.key === PREVIEW_AND_EDIT_KEY ||
        section.key === GENERIC_SUBSECTION_KEY
    )

    return [{ tabs: sections.slice(0, secondTabStartIndex) }, { tabs: sections.slice(secondTabStartIndex) }]
  }

  render() {
    const { classes, currentTab, onTabClick } = this.props

    return this.sections.map((section, index) => (
      <div className={classes.section} key={index}>
        {section.tabs.map(({ id, title }) => (
          <NavigationSideBarTab
            key={id}
            id={id}
            isSelected={id === currentTab}
            icon={SIDE_BAR_ICONS[id]}
            onClick={onTabClick}
            title={title}
          />
        ))}
        <Divider className={classes.divider} />
      </div>
    ))
  }
}

export default compose(
  shouldUpdate(
    (props, nextProps) =>
      !isEqual(props.sections, nextProps.sections) || !isEqual(props.currentTab, nextProps.currentTab)
  ),
  withStyles(styles, {
    name: 'NavigationSideBarSection',
  })
)(NavigationSideBarSections)
