import { get, toLower } from 'lodash'

import { formatList } from '../../../../../client/src/shared/utils/textHelpers'
import { DEFERRED_MAINTENANCE_OPTIONS } from '../../../../../client/src/report/constants'
import { formatPropertyCondition } from '../description'

const mapData = ({
  deferredMaintenanceItems = {},
  otherDeferredMaintenanceItems,
  generalCondition = {},
  generalAsStabilizedCondition = {},
  hasDeferredMaintenance,
  valueAsComplete,
}) => {
  const items = Object.entries(DEFERRED_MAINTENANCE_OPTIONS)
    .filter(([key]) => !!deferredMaintenanceItems[key])
    .map(([key, value]) => toLower(key === 'other' ? otherDeferredMaintenanceItems || '' : value))

  return {
    condition: formatPropertyCondition(generalCondition),
    stabilizedCondition: formatPropertyCondition(generalAsStabilizedCondition),
    items: formatList(items),
    hasDeferredMaintenance,
    hasPlannedRenovations: hasDeferredMaintenance && valueAsComplete,
    valueAsComplete,
  }
}

export const mapDTO = formValues => {
  const {
    hasDeferredMaintenance,
    generalCondition,
    generalAsStabilizedCondition,
    deferredMaintenanceItems,
    otherDeferredMaintenanceItems,
    valueAsComplete,
  } = formValues

  return mapData({
    deferredMaintenanceItems,
    otherDeferredMaintenanceItems,
    generalCondition,
    generalAsStabilizedCondition,
    hasDeferredMaintenance,
    valueAsComplete,
  })
}

export const mapDataModel = report => {
  const valueAsComplete = get(report, 'valueAsComplete')
  const buildingDescription = get(report, 'buildingDescription', {})
  const deferredMaintenance = get(buildingDescription, 'deferredMaintenance', {})
  const {
    include: hasDeferredMaintenance,
    items: deferredMaintenanceItems,
    otherItems: otherDeferredMaintenanceItems,
  } = deferredMaintenance
  const { generalCondition, generalAsStabilizedCondition } = buildingDescription

  return mapData({
    deferredMaintenanceItems,
    otherDeferredMaintenanceItems,
    generalCondition,
    generalAsStabilizedCondition,
    hasDeferredMaintenance,
    valueAsComplete,
  })
}
