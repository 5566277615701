import { Link } from '@mui/material'
import React from 'react'

const AutomationWarningMessage = ({ subjectPropertyInfo, reportId }) => {
  const { residentialUnitCount, yearBuilt, coords, dateOfValue } = subjectPropertyInfo
  let editSubjectPropertyLabel = 'Edit Year Built'
  if (!yearBuilt && !coords) {
    editSubjectPropertyLabel = 'Edit Year Built, Coordinates'
  } else if (!coords) {
    editSubjectPropertyLabel = 'Edit Coordinates'
  }
  return (
    <>
      Some comps cannot be automated:
      <li>Subject is missing:</li>
      <ul>
        {!residentialUnitCount && (
          <li>
            <Link style={{ cursor: 'pointer' }} href={`/report/${reportId}/residential-rent-roll`}>
              {' '}
              Edit In-Place Rent Roll
            </Link>
          </li>
        )}
        {(!yearBuilt || !coords) && (
          <li>
            <Link style={{ cursor: 'pointer' }} href={`/report/${reportId}/subject-property#site-details`}>
              {' '}
              {editSubjectPropertyLabel}
            </Link>
          </li>
        )}
        {!dateOfValue && (
          <li>
            <Link style={{ cursor: 'pointer' }} href={`/report/${reportId}/job-details#report-details`}>
              {' '}
              Edit Date of Valuation
            </Link>
          </li>
        )}
      </ul>
    </>
  )
}

export default AutomationWarningMessage
