export default {
  lineItemRow: {
    'background-color': 'rgba(255, 255, 255, 0)',
    borderRight: 'none',
    borderLeft: 'none',
  },
  tableRow: {
    borderRight: 'none',
    borderLeft: 'none',
  },
}
