export const Fragments = {
  EXTRAORDINARY_ASSUMPTIONS_PREFIX: `The value conclusions are subject to the following `,
  EXTRAORDINARY_ASSUMPTIONS_SUFFIX: `. We note the use of this/these extraordinary assumption(s) may have affected the \
assignment results. If the assumption is found to be false as of the effective date of the appraisal, we reserve the \
right to modify our value conclusions:`,
  HYPOTHETICAL_CONDITIONS_PREFIX: `The value conclusions are based on the following `,
  HYPOTHETICAL_CONDITIONS_SUFFIX: `. We note the use of this/these hypothetical conditions(s) may have affected the \
assignment results:`,
}

export const Descriptions = {
  EXTRAORDINARY_ASSUMPTIONS: `${Fragments.EXTRAORDINARY_ASSUMPTIONS_PREFIX}Extraordinary Assumptions\
${Fragments.EXTRAORDINARY_ASSUMPTIONS_SUFFIX}`,
  HYPOTHETICAL_CONDITIONS: `${Fragments.HYPOTHETICAL_CONDITIONS_PREFIX}Hypothetical Conditions\
${Fragments.HYPOTHETICAL_CONDITIONS_SUFFIX}`,
  GENERAL_ASSUMPTIONS: 'We note that our appraisers are not experts in the following domains:',
}

export const GENERAL_ASSUMPTIONS_TOOLTIP_TEXT = 'The following text will appear in the Introduction of your report.'
