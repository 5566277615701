export const IncomeAdjustmentKeys = {
  rentStabilization: 'Rent Stabilization Level',
  income: 'Income Level',
  none: 'None',
}

export const INCOME_ADJUSTMENT_TYPES = [
  IncomeAdjustmentKeys.rentStabilization,
  IncomeAdjustmentKeys.income,
  IncomeAdjustmentKeys.none,
]
