import React from 'react'
import PropTypes from 'prop-types'
import { Paper, Stack } from '@mui/material'

import SwitchButton from '../../../shared/components/SwitchButton'
import { objectToKeyValuePairs } from '../../../shared/utils/ui/checkboxHelper'

const ViewModes = {
  PREVIEW: 'Preview',
  EDIT: 'Edit',
}

const ViewModeOptions = objectToKeyValuePairs(ViewModes)

class PreviewAndEdit extends React.PureComponent {
  static propTypes = {
    onChangeMode: PropTypes.func,
    title: PropTypes.string.isRequired,
    children: PropTypes.func.isRequired,
    section: PropTypes.string.isRequired,
  }

  static defaultProps = {
    onChangeMode: () => {},
  }

  state = {
    currentMode: ViewModes.PREVIEW,
  }

  changeMode = newMode => {
    const { onChangeMode } = this.props

    this.setState({ currentMode: newMode })
    onChangeMode(newMode === ViewModes.EDIT)
  }

  render() {
    const { children } = this.props
    const { currentMode } = this.state

    const isEditMode = currentMode === ViewModes.EDIT

    return (
      <Stack spacing={2} minWidth="25rem" maxWidth="50rem">
        <Stack direction="row" justifyContent="flex-end">
          <SwitchButton value={currentMode} onChange={this.changeMode} items={ViewModeOptions} />
        </Stack>
        <Paper>{children(isEditMode)}</Paper>
      </Stack>
    )
  }
}

export default PreviewAndEdit
