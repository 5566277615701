import React from 'react'
import PropTypes from 'prop-types'
import { compose } from 'recompose'
import ReactMouseTrap from 'react-mousetrap'
import { withStyles, Modal } from '@material-ui/core'
import { connect } from 'react-redux'
import { get } from 'lodash'

import { hideJumpAhead, showJumpAhead } from 'client-shared/redux/actions/jumpAhead'

import JumpAheadAutosuggest from './JumpAheadAutosuggestContainer'
import { SEARCH_SHORTCUTS } from './constants'
import KeyboardShortcuts from './KeyboardShortcuts'

const styles = {
  fullScreenSearch: {
    position: 'fixed',
    top: 0,
    left: 0,
    bottom: 0,
    right: 0,
    background: 'rgba(0, 0, 0, 0.9)',
    padding: '30px 50px',
    color: '#fff',
    zIndex: 9999,
  },
}

class JumpAhead extends React.PureComponent {
  state = {
    formsStructure: PropTypes.string.isRequired,
  }

  get shouldOpenSearchScreen() {
    const elements = [...document.body.querySelectorAll(':scope > [role="presentation"], [role="dialog"]')]

    if (elements.length === 0) {
      return true
    }

    return elements.some(element => {
      const computedStyles = window.getComputedStyle(element)

      return computedStyles.visibility === 'hidden'
    })
  }

  componentDidMount() {
    const { bindShortcut } = this.props
    bindShortcut(SEARCH_SHORTCUTS, this.onShortcutKeyPressed)
  }

  componentWillUnmount() {
    const { unbindShortcut } = this.props
    unbindShortcut(SEARCH_SHORTCUTS)
  }

  onShortcutKeyPressed = () => {
    const { showJumpAhead } = this.props
    if (this.shouldOpenSearchScreen) {
      showJumpAhead()
    }
  }

  onKeyPress = event => {
    const { hideJumpAhead } = this.props
    if (event.key === 'Escape') {
      hideJumpAhead()
    }
  }

  render() {
    const { isJumpAheadOpen, hideJumpAhead, classes, formsStructure } = this.props
    return (
      <Modal open={isJumpAheadOpen} className={classes.fullScreenSearch} onKeyDown={this.onKeyPress}>
        <div>
          <h3>Keyboard Shortcuts:</h3>
          <KeyboardShortcuts />
          <h3>Jump to Section:</h3>
          <p>(type section name, arrow keys to select, enter to go, esc to exit)</p>
          <JumpAheadAutosuggest formsStructure={formsStructure} closeSearchScreen={hideJumpAhead} />
        </div>
      </Modal>
    )
  }
}

export default compose(
  connect(
    state => {
      return {
        isJumpAheadOpen: get(state, 'shared.jumpAhead.isJumpAheadOpen', false),
      }
    },
    {
      showJumpAhead,
      hideJumpAhead,
    }
  ),
  ReactMouseTrap,
  withStyles(styles)
)(JumpAhead)
