import { objectToKeyValuePairs } from 'client-shared/utils/ui/checkboxHelper'
import { OTHER_INCOME_PATH } from 'shared/constants/report/keysAndDataPaths'

export const DATA_PATH = OTHER_INCOME_PATH
export const HEADING = 'Other'

export const VCLossTypes = {
  RESIDENTIAL: 'Residential',
  OTHER: 'Other',
}
export const VC_LOSS_OPTIONS = objectToKeyValuePairs(VCLossTypes)
export const VC_LOSS_OPTIONS_COMMERCIAL = objectToKeyValuePairs({ OTHER: 'Other' })

export const NEW_INCOME_ITEM = {
  otherIncomeVc: null,
  otherIncomeCategory: '',
  vcLossType: VCLossTypes.OTHER,
  otherIncomeAnnualAmount: null,
}

export const INCOME_ITEMS_TABLE_COLUMNS = [
  '#',
  'V/C Loss Type',
  'V/C (%)',
  'Income Category',
  'Annual Amount($)',
  'Actions',
]
