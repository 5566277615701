import {
  GeneratedText,
  gtConditional,
  gtIfElse,
  gtLocked,
  gtLockedSentence,
  gtNewline,
  gtTemplate,
} from 'shared/utils/narrative/GeneratedText'

import { mapDTO, mapDataModel } from './mappings'

const generate = () => {
  const neighborhood = gtLocked('neighborhood', null, 'Neighborhood')
  const city = gtLocked('city', null, 'City')
  const state = gtLocked('state', null, 'State')

  const adjustmentSentences = new GeneratedText()
  const locationSentence = gtLockedSentence('locationSentence')
  const neighborhoodSentence = gtLockedSentence('neighborhoodSentence')

  adjustmentSentences.addPart(
    gtConditional('hasLocationWithinNeighborhoodAdjustment', [gtNewline(), locationSentence, gtNewline()])
  )
  adjustmentSentences.addPart(gtConditional('hasNeighborhoodAdjustment', [neighborhoodSentence, gtNewline()]))

  const gt = new GeneratedText()

  gt.t`The subject property is located in the ${neighborhood} neighborhood of ${city}, ${state}. `

  gt.addParts(
    gtIfElse(
      'hasNoNeighborhoodAdjustments',
      gtTemplate`All comparables used in this analysis are in similar locations compared to the subject property. 
        Therefore, no adjustments were required.`,
      adjustmentSentences.getParts()
    )
  )

  return gt.getParts()
}

export default { generate, mapDTO, mapDataModel }
