import { GeneratedText } from '../../../../narrative/GeneratedText'

import { mapDTO, mapDataModel } from '../mappings'

export const NO_DEPRECIATION_TEXT = 'Overall, no depreciation is warranted.'

const generate = () => {
  const gt = new GeneratedText()

  gt.t`${NO_DEPRECIATION_TEXT}`

  return gt.getParts()
}

export default { generate, mapDTO, mapDataModel }
