import createDecorator from 'final-form-calculate'

import CapRateCalculations from 'shared/report-calculations/income-approach/cap-rate/cap-rate-calculations'

const calculationsManager = createDecorator(
  {
    field: 'amortizationTerm',
    updates: (_value, _fieldName, allValues) => ({
      mortgageConstant: CapRateCalculations.getMortgageConstant(allValues),
      numberOfPayments: CapRateCalculations.getNumberOfPayments(allValues),
    }),
  },
  {
    field: 'loanValueRatio',
    updates: (_value, _fieldName, allValues) => ({
      bandOfInvestmentCapRate: CapRateCalculations.getBandOfInvestmentCapRate(allValues),
      debtCoverageCapRate: CapRateCalculations.getDebtCoverageCapRate(allValues),
      equityComponent: CapRateCalculations.getEquityFunds(allValues),
      equityRatio: CapRateCalculations.getEquityRatio(allValues),
      mortgageFunds: CapRateCalculations.getMortgageFunds(allValues),
    }),
  },
  {
    field: 'mortgageRate',
    updates: (_value, _fieldName, allValues) => ({
      mortgageConstant: CapRateCalculations.getMortgageConstant(allValues),
    }),
  },
  {
    field: 'mortgageConstant',
    updates: (_value, _field, allValues) => ({
      debtCoverageCapRate: CapRateCalculations.getDebtCoverageCapRate(allValues),
      mortgageFunds: CapRateCalculations.getMortgageFunds(allValues),
    }),
  },
  {
    field: 'equityDividendRate',
    updates: (_value, _field, allValues) => ({
      bandOfInvestmentCapRate: CapRateCalculations.getBandOfInvestmentCapRate(allValues),
      equityComponent: CapRateCalculations.getEquityFunds(allValues),
    }),
  },
  {
    field: 'mortgageFunds',
    updates: (_value, _field, allValues) => ({
      bandOfInvestmentCapRate: CapRateCalculations.getBandOfInvestmentCapRate(allValues),
    }),
  },
  {
    field: 'debtCoverageRatio',
    updates: (_value, _field, allValues) => ({
      debtCoverageCapRate: CapRateCalculations.getDebtCoverageCapRate(allValues),
    }),
  }
)

export default calculationsManager
