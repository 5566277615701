import moment from 'moment'
import { get, map } from 'lodash'
import { handleActions } from 'redux-actions'

import {
  fetchExportsFail,
  fetchExportsReceive,
  fetchExportsRequest,
  generateReportRequest,
  generateReportReceive,
  generateReportFail,
  generateReportFromJSONRequest,
  generateReportFromJSONReceive,
  generateReportFromJSONFail,
  updateStatus,
  updateSelectedTemplates,
  updateShouldGenerateSignatures,
  getReportHierarchyReceive,
  getReportHierarchyRequest,
  getReportHierarchyFail,
} from './actions'

const initialState = {
  areExportsLoading: true,
  isExportGenerating: false,
  exportItems: [],
  exportTemplates: [],
  selectedTemplates: [],
  isLoadingTemplates: true,
  shouldGenerateSignatures: false,
}

export const reducer = handleActions(
  {
    [fetchExportsRequest](state) {
      return { ...state, areExportsLoading: true }
    },
    [fetchExportsFail](state) {
      return { ...state, areExportsLoading: false }
    },
    [fetchExportsReceive](state, { payload }) {
      const exportItems = payload.exportItems || []

      return {
        ...state,
        areExportsLoading: false,
        exportItems: exportItems.sort((startDate, endDate) => {
          if (moment(startDate.createdAt).isAfter(moment(endDate.createdAt))) {
            return -1
          }

          if (moment(startDate.createdAt).isBefore(moment(endDate.createdAt))) {
            return 1
          }

          return 0
        }),
        downloadFolderUrl: payload.downloadFolderUrl,
      }
    },
    [generateReportRequest](state) {
      return { ...state, isExportGenerating: true }
    },
    [generateReportFail](state) {
      return { ...state, isExportGenerating: false }
    },
    [generateReportReceive](state, { payload }) {
      return {
        ...state,
        exportItems: [payload],
        isExportGenerating: false,
      }
    },
    [generateReportFromJSONRequest](state) {
      return { ...state, isExportGenerating: true }
    },
    [generateReportFromJSONFail](state) {
      return { ...state, isExportGenerating: false }
    },
    [generateReportFromJSONReceive](state, { payload }) {
      return {
        ...state,
        exportItems: [payload],
        isExportGenerating: false,
      }
    },
    [updateStatus](state, { payload }) {
      const { _id, progress, status, errorMessages, warnings } = payload
      const exportItems = map(state.exportItems, item => {
        if (item._id === _id) {
          return { ...item, progress, status, errorMessages, warnings }
        }
        return item
      })
      return {
        ...state,
        exportItems,
      }
    },
    [updateSelectedTemplates](state, { payload }) {
      return {
        ...state,
        selectedTemplates: payload.selectedTemplates,
      }
    },
    [updateShouldGenerateSignatures](state, { payload }) {
      return {
        ...state,
        shouldGenerateSignatures: payload.shouldGenerateSignatures,
      }
    },
    [getReportHierarchyRequest](state) {
      return {
        ...state,
        isLoadingTemplates: true,
      }
    },
    [getReportHierarchyFail](state) {
      return {
        ...state,
        isLoadingTemplates: false,
      }
    },
    [getReportHierarchyReceive](state, { payload }) {
      const subTemplates = get(payload, 'children', [])
      const exportTemplates = subTemplates.map(child => ({
        value: child.documentPath,
        label: child.displayName,
      }))
      const selectedTemplates = exportTemplates.map(template => template.value)
      return {
        ...state,
        exportTemplates,
        selectedTemplates,
        isLoadingTemplates: false,
      }
    },
  },
  initialState
)
