import React from 'react'
import PropTypes from 'prop-types'

import classnames from 'classnames'
import { withStyles } from '@mui/styles'
import { lightBlue } from '@mui/material/colors'

const style = _theme => ({
  root: {
    backgroundColor: lightBlue[200],
    padding: 16,
    borderWidth: '0 1px 4px 1px',
    borderColor: lightBlue[500],
    '& *': {
      margin: 0,
    },
  },
})

function AlertInfo({ classes, children, className }) {
  return <div className={classnames(classes.root, className)}>{children}</div>
}

AlertInfo.propTypes = {
  classes: PropTypes.object.isRequired,
}

const AlerInfoWithStyles = withStyles(style)(AlertInfo)

AlerInfoWithStyles.displayName = 'AlertInfo'

AlerInfoWithStyles.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
}

export default AlerInfoWithStyles
