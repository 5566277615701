// Adapted from https://usehooks-ts.com/react-hook/use-debounce-callback
import { debounce } from 'lodash'
import { useEffect, useMemo, useRef } from 'react'

import useUnmount from './useUnmount'

export interface DebounceOptions {
  /**
   * Determines whether the function should be invoked on the leading edge of the timeout.
   */
  leading?: boolean
  /**
   * Determines whether the function should be invoked on the trailing edge of the timeout.
   */
  trailing?: boolean
  /**
   * The maximum time the specified function is allowed to be delayed before it is invoked.
   */
  maxWait?: number
}

interface ControlFunctions {
  /**
   * Cancels pending function invocations.
   */
  cancel: () => void
  /**
   * Immediately invokes pending function invocations.
   */
  flush: () => void
  /**
   * Checks if there are any pending function invocations.
   * @returns `true` if there are pending invocations, otherwise `false`.
   */
  isPending: () => boolean
}

interface DebouncedState<T extends (...args: any) => ReturnType<T>> extends ControlFunctions {
  (...args: Parameters<T>): ReturnType<T> | undefined
}

const useDebounce = <T extends (...args: any) => ReturnType<T>>(
  func: T,
  delay = 500,
  options?: DebounceOptions
): DebouncedState<T> => {
  const debouncedFunc = useRef<ReturnType<typeof debounce>>()

  useUnmount(() => {
    if (debouncedFunc.current) {
      debouncedFunc.current.cancel()
    }
  })

  const debounced = useMemo(() => {
    const debouncedFuncInstance = debounce(func, delay, options)

    const wrappedFunc: DebouncedState<T> = (...args: Parameters<T>) => {
      return debouncedFuncInstance(...args)
    }

    wrappedFunc.cancel = () => {
      debouncedFuncInstance.cancel()
    }

    wrappedFunc.isPending = () => {
      return !!debouncedFunc.current
    }

    wrappedFunc.flush = () => {
      return debouncedFuncInstance.flush()
    }

    return wrappedFunc
  }, [func, delay, options])

  useEffect(() => {
    debouncedFunc.current = debounce(func, delay, options)
  }, [func, delay, options])

  return debounced
}

export default useDebounce
