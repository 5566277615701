import { invert } from 'lodash'

import { PropertyTypes } from 'shared/constants'
import { SALES_APPROACH_TYPES } from 'shared/constants/salesApproach'

export const ADJUSTMENT_GROUPS = {
  MARKET: 'market',
  LOCATION: 'location',
  UTILITY: 'utility',
  OTHER: 'other',
}

export const DEFAULT_ADJUSTMENT_LABELS = {
  propertyRights: 'Property Rights',
  financingTerms: 'Financing Terms',
  conditionsOfSale: 'Conditions of Sale',
  marketConditions: 'Market Conditions',
  neighborhood: 'Better/Worse Neighborhood',
  locationInNeighborhood: 'Location within Neighborhood',
  commercial: 'Commercial Space Adjustment',
  onsiteParking: 'Onsite Parking',
  corner: 'Corner Adjustment',
  finishes: 'Finishes Adjustment',
  elevator: 'Elevator Adjustment',
  tenantMix: 'Tenant Mix',
  amenities: 'Amenities Adjustment',
  signage: 'Signage',
  airRights: 'Air Rights Adjustment',
  floorAreaRatio: 'Floor Area Ratio',
  size: 'Size Adjustment',
  stabilizationLevel: 'Rent Stabilization Level Adjustment',
  condition: 'Condition Adjustment',
  averageUnitSize: 'Average Unit Size',
}

export const SITE_CONDITION_OPTIONS = [
  { label: 'Cleared', value: 'cleared' },
  { label: 'Demolition required', value: 'demolitionRequired' },
  { label: 'Paved lot', value: 'pavedLot' },
  { label: 'Ready for development', value: 'readyForDevelopment' },
  { label: 'Site work / clean up required', value: 'siteWorkOrCleanUpRequired' },
]

export const PROPERTY_TYPE_OPTIONS = [
  { label: 'Multifamily', value: 'multifamily' },
  { label: 'Mixed-Use', value: 'mixed-use' },
  { label: 'Retail', value: 'retail' },
  { label: 'Office', value: 'office' },
  { label: 'Land', value: 'land' },
  { label: 'Industrial', value: 'industrial' },
  { label: 'Community Facility', value: 'communityfacility' },
  { label: 'Special Purpose', value: 'specialpurpose' },
  { label: 'Hospitality', value: 'hospitality' },
  { label: 'Residential (Non-commercial)', value: 'residential-(non-commercial)' },
]

export const SALE_CONDITIONS = {
  ARMS_LENGTH: 'armsLength',
  NON_ARMS_LENGTH: 'nonArmsLength',
}

export const SALE_CONDITIONS_OPTIONS = [
  { label: "Arm's Length", value: SALE_CONDITIONS.ARMS_LENGTH },
  { label: "Non-Arm's Length", value: SALE_CONDITIONS.NON_ARMS_LENGTH },
]

export const DEFAULT_ADJUSTMENT_ROWS = [
  {
    title: 'Property Rights',
    name: 'propertyRights',
    groupName: ADJUSTMENT_GROUPS.MARKET,
  },
  {
    title: 'Financing Terms',
    name: 'financingTerms',
    groupName: ADJUSTMENT_GROUPS.MARKET,
  },
  {
    title: 'Conditions of Sale',
    name: 'conditionsOfSale',
    groupName: ADJUSTMENT_GROUPS.MARKET,
  },
  {
    title: 'Market Conditions (Time)',
    name: 'marketConditions',
    groupName: ADJUSTMENT_GROUPS.MARKET,
  },
  {
    title: 'Better/Worse Neighborhood',
    name: 'neighborhood',
    groupName: ADJUSTMENT_GROUPS.LOCATION,
  },
  {
    title: 'Location within Neighborhood',
    name: 'locationInNeighborhood',
    groupName: ADJUSTMENT_GROUPS.LOCATION,
  },
  {
    title: 'Onsite Parking',
    name: 'onsiteParking',
    groupName: ADJUSTMENT_GROUPS.UTILITY,
  },
  {
    title: 'Corner Adjustment',
    name: 'corner',
    groupName: ADJUSTMENT_GROUPS.UTILITY,
  },
  {
    title: 'Finishes Adjustment',
    name: 'finishes',
    groupName: ADJUSTMENT_GROUPS.UTILITY,
  },
  {
    title: 'Elevator Adjustment',
    name: 'elevator',
    groupName: ADJUSTMENT_GROUPS.UTILITY,
  },
  {
    title: 'Tenant Mix',
    name: 'tenantMix',
    groupName: ADJUSTMENT_GROUPS.UTILITY,
  },
  {
    title: 'Amenities Adjustment',
    name: 'amenities',
    groupName: ADJUSTMENT_GROUPS.UTILITY,
  },
  {
    title: 'Signage',
    name: 'signage',
    groupName: ADJUSTMENT_GROUPS.UTILITY,
  },
  {
    title: 'Air Rights Adjustment',
    name: 'airRights',
    groupName: ADJUSTMENT_GROUPS.UTILITY,
  },
  {
    title: 'Floor Area Ratio',
    name: 'floorAreaRatio',
    groupName: ADJUSTMENT_GROUPS.UTILITY,
  },
  {
    title: 'Commercial Space Adjustment',
    name: 'commercial',
    groupName: ADJUSTMENT_GROUPS.OTHER,
  },
  {
    title: 'Size Adjustment',
    name: 'size',
    groupName: ADJUSTMENT_GROUPS.OTHER,
  },
  {
    title: 'Rent Stabilization Level Adjustment',
    name: 'stabilizationLevel',
    groupName: ADJUSTMENT_GROUPS.OTHER,
  },
  {
    title: 'Condition Adjustment',
    name: 'condition',
    groupName: ADJUSTMENT_GROUPS.OTHER,
  },
  {
    title: 'Average Unit Size',
    name: 'averageUnitSize',
    groupName: ADJUSTMENT_GROUPS.OTHER,
  },
]

export const OTHER_ADJUSTMENT_ROW_KEYS = {
  utility: 'utilityAdjustment.otherAdjustment',
  stabilization: 'stabilizationLevel',
  other: 'otherAdjustment',
}

export const MARKET_ROWS = ['propertyRights', 'financingTerms', 'conditionsOfSale', 'marketConditions']
export const LOCATION_ROWS = ['neighborhood', 'locationInNeighborhood']
export const UTILITY_ROWS = {
  default: ['corner', 'finishes', 'elevator', 'amenities', 'airRights'],
  [PropertyTypes.COMMERCIAL]: ['onsiteParking', 'corner', 'finishes', 'tenantMix', 'signage', 'floorAreaRatio'],
}

export const OTHER_ROWS = {
  [SALES_APPROACH_TYPES.IMPROVED]: ['size', 'stabilizationLevel', 'condition', 'commercial', 'averageUnitSize'],
  [SALES_APPROACH_TYPES.LAND]: ['size'],
}

export const getDefaultRows = (isImprovedSales: boolean, isCommercialProperty: boolean) => {
  return {
    market: MARKET_ROWS,
    location: LOCATION_ROWS,
    utility: isCommercialProperty ? UTILITY_ROWS.commercial : UTILITY_ROWS.default,
    other: isImprovedSales ? OTHER_ROWS.improved : OTHER_ROWS.land,
  }
}
export const CUSTOM_ADJUSTMENT_GROUPS = [ADJUSTMENT_GROUPS.UTILITY, ADJUSTMENT_GROUPS.OTHER]

// maintaining these for data-qa path
export const OTHER_ADJUSTMENT_PATH = {
  utility: {
    label: 'utilityAdjustmentLabel',
  },
  other: {
    label: 'otherAdjustmentLabel',
  },
}

// !!! ORDER MATTER !!!
export const DEFAULT_COMP_INFO_ROW_IDS = {
  saleDate: 'saleDate',
  salePrice: 'salePrice',
  capRate: 'capRate',
  propertyRights: 'propertyRights',
  city: 'city',
  market: 'market',
  submarket: 'submarket',
  residentialUnits: 'residentialUnits',
  commercialUnits: 'commercialUnits',
  averageUnitSize: 'averageUnitSize',
  grossBuildingArea: 'grossBuildingArea',
  condition: 'condition',
  yearBuilt: 'yearBuilt',
  propertyType: 'propertyType',
  buildingType: 'buildingType',
  parking: 'parking',
  parkingRatio: 'parkingRatio',
  buildingAmenities: 'buildingAmenities',
  occupancy: 'occupancy',
  landToBuildingRatio: 'landToBuildingRatio',
}

export const ADDITIONAL_COMP_INFO_ROW_IDS = {
  state: 'state',
  zip: 'zip',
  county: 'county',
  propertyId: 'propertyId',
  censusTract: 'censusTract',
  propertyName: 'propertyName',
  neighborhood: 'neighborhood',
  yearRenovated: 'yearRenovated',
  numberOfFloors: 'numberOfFloors',
  siteArea: 'siteArea',
  commercialArea: 'commercialArea',
  unitTypes: 'unitTypes',
  buildableUnits: 'buildableUnits',
  buildableArea: 'buildableArea',
  siteCondition: 'siteCondition',
  floorAreaRatio: 'floorAreaRatio',
  useType: 'useType',
  zoning: 'zoning',
  buildingClass: 'buildingClass',
  exposure: 'exposure',
  access: 'access',
  saleStatus: 'saleStatus',
  adjustedSalePrice: 'adjustedSalePrice',
  salePriceAdjustmentReason: 'salePriceAdjustmentReason',
  salePriceAdjustment: 'salePriceAdjustment',
  buyer: 'buyer',
  seller: 'seller',
  uniqueSaleIdentifier: 'uniqueSaleIdentifier',
  saleCondition: 'saleCondition',
  pricePerUnit: 'pricePerUnit',
  pricePerSF: 'pricePerSF',
  rentStabilizedUnits: 'rentStabilizedUnits',
  netRentableArea: 'netRentableArea',
  tenancy: 'tenancy',
}

export const COMP_INFO_ROW_IDS_TO_LABEL = {
  [DEFAULT_COMP_INFO_ROW_IDS.saleDate]: 'Sale Date',
  [DEFAULT_COMP_INFO_ROW_IDS.salePrice]: 'Sale Price',
  [DEFAULT_COMP_INFO_ROW_IDS.capRate]: 'Cap Rate',
  [DEFAULT_COMP_INFO_ROW_IDS.propertyRights]: 'Property Rights',
  [DEFAULT_COMP_INFO_ROW_IDS.city]: 'City',
  [DEFAULT_COMP_INFO_ROW_IDS.market]: 'Market',
  [DEFAULT_COMP_INFO_ROW_IDS.submarket]: 'Submarket',
  [DEFAULT_COMP_INFO_ROW_IDS.residentialUnits]: '# of Residential Units',
  [DEFAULT_COMP_INFO_ROW_IDS.commercialUnits]: '# of Commercial Units',
  [DEFAULT_COMP_INFO_ROW_IDS.averageUnitSize]: 'Average Unit Size',
  [DEFAULT_COMP_INFO_ROW_IDS.grossBuildingArea]: 'GBA',
  [DEFAULT_COMP_INFO_ROW_IDS.condition]: 'Condition',
  [DEFAULT_COMP_INFO_ROW_IDS.yearBuilt]: 'Year Built',
  [DEFAULT_COMP_INFO_ROW_IDS.propertyType]: 'Property Type',
  [DEFAULT_COMP_INFO_ROW_IDS.buildingType]: 'Elevatored',
  [DEFAULT_COMP_INFO_ROW_IDS.parking]: 'Parking',
  [DEFAULT_COMP_INFO_ROW_IDS.parkingRatio]: 'Parking Ratio',
  [DEFAULT_COMP_INFO_ROW_IDS.buildingAmenities]: 'Amenities',
  [DEFAULT_COMP_INFO_ROW_IDS.occupancy]: 'Occupancy',
  [DEFAULT_COMP_INFO_ROW_IDS.landToBuildingRatio]: 'Land To Building Ratio',

  [ADDITIONAL_COMP_INFO_ROW_IDS.state]: 'State',
  [ADDITIONAL_COMP_INFO_ROW_IDS.zip]: 'Zip',
  [ADDITIONAL_COMP_INFO_ROW_IDS.county]: 'County',
  [ADDITIONAL_COMP_INFO_ROW_IDS.propertyId]: 'Property ID',
  [ADDITIONAL_COMP_INFO_ROW_IDS.censusTract]: 'Census Tract',
  [ADDITIONAL_COMP_INFO_ROW_IDS.propertyName]: 'Property Name',
  [ADDITIONAL_COMP_INFO_ROW_IDS.neighborhood]: 'Neighborhood/District',
  [ADDITIONAL_COMP_INFO_ROW_IDS.yearRenovated]: 'Year Renovated',
  [ADDITIONAL_COMP_INFO_ROW_IDS.numberOfFloors]: '# Floors',
  [ADDITIONAL_COMP_INFO_ROW_IDS.siteArea]: 'Site Area',
  [ADDITIONAL_COMP_INFO_ROW_IDS.commercialArea]: 'Commercial Area',
  [ADDITIONAL_COMP_INFO_ROW_IDS.unitTypes]: 'Unit Types',
  [ADDITIONAL_COMP_INFO_ROW_IDS.buildableUnits]: 'Buildable Units',
  [ADDITIONAL_COMP_INFO_ROW_IDS.buildableArea]: 'Buildable Area',
  [ADDITIONAL_COMP_INFO_ROW_IDS.siteCondition]: 'Site Condition',
  [ADDITIONAL_COMP_INFO_ROW_IDS.floorAreaRatio]: 'Floor Area Ratio (FAR)',
  [ADDITIONAL_COMP_INFO_ROW_IDS.useType]: 'Use Type',
  [ADDITIONAL_COMP_INFO_ROW_IDS.zoning]: 'Zoning',
  [ADDITIONAL_COMP_INFO_ROW_IDS.buildingClass]: 'Building Class',
  [ADDITIONAL_COMP_INFO_ROW_IDS.exposure]: 'Exposure',
  [ADDITIONAL_COMP_INFO_ROW_IDS.access]: 'Access',
  [ADDITIONAL_COMP_INFO_ROW_IDS.saleStatus]: 'Sale Status',
  [ADDITIONAL_COMP_INFO_ROW_IDS.adjustedSalePrice]: 'Adjusted Sale Price',
  [ADDITIONAL_COMP_INFO_ROW_IDS.salePriceAdjustmentReason]: 'Adjustment Reason',
  [ADDITIONAL_COMP_INFO_ROW_IDS.salePriceAdjustment]: 'Sale Price Adjustment',
  [ADDITIONAL_COMP_INFO_ROW_IDS.buyer]: 'Buyer (Grantee)',
  [ADDITIONAL_COMP_INFO_ROW_IDS.seller]: 'Seller (Grantor)',
  [ADDITIONAL_COMP_INFO_ROW_IDS.uniqueSaleIdentifier]: 'Unique Sale Identifier',
  [ADDITIONAL_COMP_INFO_ROW_IDS.saleCondition]: 'Sale Condition',
  [ADDITIONAL_COMP_INFO_ROW_IDS.pricePerUnit]: 'Price per Unit',
  [ADDITIONAL_COMP_INFO_ROW_IDS.pricePerSF]: 'Price per SF',
  [ADDITIONAL_COMP_INFO_ROW_IDS.rentStabilizedUnits]: '# Rent Stabilized Units',
  [ADDITIONAL_COMP_INFO_ROW_IDS.netRentableArea]: 'Net Rentable Area',
  [ADDITIONAL_COMP_INFO_ROW_IDS.tenancy]: 'Tenancy',
}

export const COMP_INFO_ROW_LABEL_TO_IDS = invert(COMP_INFO_ROW_IDS_TO_LABEL)

export const COMP_INFO_ROW_IDS_TO_PROPERTY = {
  [DEFAULT_COMP_INFO_ROW_IDS.saleDate]: 'saleInformation.saleDate',
  [DEFAULT_COMP_INFO_ROW_IDS.salePrice]: 'saleInformation.salePrice',
  [DEFAULT_COMP_INFO_ROW_IDS.capRate]: 'saleInformation.capRate',
  [DEFAULT_COMP_INFO_ROW_IDS.propertyRights]: 'saleInformation.propertyRights',
  [DEFAULT_COMP_INFO_ROW_IDS.city]: 'address.city',
  [DEFAULT_COMP_INFO_ROW_IDS.market]: 'propertyInformation.market',
  [DEFAULT_COMP_INFO_ROW_IDS.submarket]: 'propertyInformation.submarket',
  [DEFAULT_COMP_INFO_ROW_IDS.residentialUnits]: 'propertyInformation.residentialUnits',
  [DEFAULT_COMP_INFO_ROW_IDS.commercialUnits]: 'propertyInformation.commercialUnits',
  [DEFAULT_COMP_INFO_ROW_IDS.averageUnitSize]: 'propertyInformation.averageUnitSize',
  [DEFAULT_COMP_INFO_ROW_IDS.grossBuildingArea]: 'propertyInformation.grossBuildingArea',
  [DEFAULT_COMP_INFO_ROW_IDS.condition]: 'propertyInformation.condition',
  [DEFAULT_COMP_INFO_ROW_IDS.yearBuilt]: 'propertyInformation.yearBuilt',
  [DEFAULT_COMP_INFO_ROW_IDS.propertyType]: 'propertyInformation.propertyType',
  [DEFAULT_COMP_INFO_ROW_IDS.buildingType]: 'propertyInformation.buildingType',
  [DEFAULT_COMP_INFO_ROW_IDS.parking]: 'propertyInformation.parking',
  [DEFAULT_COMP_INFO_ROW_IDS.parkingRatio]: 'parkingRatio',
  [DEFAULT_COMP_INFO_ROW_IDS.buildingAmenities]: 'propertyInformation.buildingAmenities',
  [DEFAULT_COMP_INFO_ROW_IDS.occupancy]: 'propertyInformation.occupancy',
  [DEFAULT_COMP_INFO_ROW_IDS.landToBuildingRatio]: 'propertyInformation.landToBuildingRatio',

  [ADDITIONAL_COMP_INFO_ROW_IDS.state]: 'address.state',
  [ADDITIONAL_COMP_INFO_ROW_IDS.zip]: 'address.postalCode',
  [ADDITIONAL_COMP_INFO_ROW_IDS.county]: 'address.county',
  [ADDITIONAL_COMP_INFO_ROW_IDS.propertyId]: 'address.propertyIdentification.propertyIdentifier',
  [ADDITIONAL_COMP_INFO_ROW_IDS.censusTract]: 'propertyInformation.censusTract',
  [ADDITIONAL_COMP_INFO_ROW_IDS.propertyName]: 'propertyInformation.propertyName',
  [ADDITIONAL_COMP_INFO_ROW_IDS.neighborhood]: 'propertyInformation.neighborhood',
  [ADDITIONAL_COMP_INFO_ROW_IDS.yearRenovated]: 'propertyInformation.yearRenovated',
  [ADDITIONAL_COMP_INFO_ROW_IDS.numberOfFloors]: 'propertyInformation.numberOfFloors',
  [ADDITIONAL_COMP_INFO_ROW_IDS.siteArea]: 'propertyInformation.siteArea',
  [ADDITIONAL_COMP_INFO_ROW_IDS.commercialArea]: 'propertyInformation.commercialArea',
  [ADDITIONAL_COMP_INFO_ROW_IDS.unitTypes]: 'propertyInformation.unitTypes',
  [ADDITIONAL_COMP_INFO_ROW_IDS.buildableUnits]: 'propertyInformation.buildableUnits',
  [ADDITIONAL_COMP_INFO_ROW_IDS.buildableArea]: 'propertyInformation.buildableArea',
  [ADDITIONAL_COMP_INFO_ROW_IDS.siteCondition]: 'propertyInformation.siteCondition',
  [ADDITIONAL_COMP_INFO_ROW_IDS.floorAreaRatio]: 'propertyInformation.floorAreaRatio',
  [ADDITIONAL_COMP_INFO_ROW_IDS.useType]: 'propertyInformation.useType',
  [ADDITIONAL_COMP_INFO_ROW_IDS.zoning]: 'propertyInformation.zoning',
  [ADDITIONAL_COMP_INFO_ROW_IDS.buildingClass]: 'propertyInformation.buildingClass',
  [ADDITIONAL_COMP_INFO_ROW_IDS.exposure]: 'propertyInformation.exposure',
  [ADDITIONAL_COMP_INFO_ROW_IDS.access]: 'propertyInformation.access',
  [ADDITIONAL_COMP_INFO_ROW_IDS.saleStatus]: 'saleInformation.saleStatus',
  [ADDITIONAL_COMP_INFO_ROW_IDS.adjustedSalePrice]: 'saleInformation.adjustedSalePrice',
  [ADDITIONAL_COMP_INFO_ROW_IDS.salePriceAdjustmentReason]: 'saleInformation.salePriceAdjustmentReason',
  [ADDITIONAL_COMP_INFO_ROW_IDS.salePriceAdjustment]: 'saleInformation.salePriceAdjustment',
  [ADDITIONAL_COMP_INFO_ROW_IDS.buyer]: 'saleInformation.buyer',
  [ADDITIONAL_COMP_INFO_ROW_IDS.seller]: 'saleInformation.seller',
  [ADDITIONAL_COMP_INFO_ROW_IDS.uniqueSaleIdentifier]: 'saleInformation.uniqueSaleIdentifier',
  [ADDITIONAL_COMP_INFO_ROW_IDS.saleCondition]: 'saleInformation.saleCondition',
  [ADDITIONAL_COMP_INFO_ROW_IDS.pricePerUnit]: 'pricePerUnit',
  [ADDITIONAL_COMP_INFO_ROW_IDS.pricePerSF]: 'pricePerSF',
  [ADDITIONAL_COMP_INFO_ROW_IDS.rentStabilizedUnits]: 'propertyInformation.rentStabilizedUnits',
  [ADDITIONAL_COMP_INFO_ROW_IDS.netRentableArea]: 'propertyInformation.netRentableArea',
  [ADDITIONAL_COMP_INFO_ROW_IDS.tenancy]: 'propertyInformation.tenancy',
}

export const COMP_INFO_ROW_PROPERTY_TO_IDS = invert(COMP_INFO_ROW_IDS_TO_PROPERTY)
