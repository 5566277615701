import React from 'react'
import { get } from 'lodash'
import PropTypes from 'prop-types'
import { compose } from 'recompose'
import { connect } from 'react-redux'
import { withStyles } from '@material-ui/core'

import Button from '../../shared/components/Button'

import Header from '../../shared/layout/Header'
import { MAIN_BLUE_COLOR } from '../../shared/layout/constants'

import { SPLIT_AFTERNOON, SPLIT_EVENING } from './constants'

const styles = theme => ({
  headerContent: {
    justifyContent: 'flex-end',
    paddingRight: theme.spacing.unit * 2,
  },
  newReportButtonLabel: {
    textTransform: 'none',
  },
  newReportsButton: {
    backgroundColor: MAIN_BLUE_COLOR,
    fontFamily: 'Nunito Sans',
    margin: 0,
  },
})

class ReportsDashboardHeader extends React.PureComponent {
  static propTypes = {
    openReportModal: PropTypes.func.isRequired,
    userFirstName: PropTypes.string.isRequired,
  }

  get headerClasses() {
    const { classes } = this.props

    return {
      headerContent: classes.headerContent,
    }
  }

  get newReportButtonClasses() {
    const { classes } = this.props

    return {
      button: classes.newReportsButton,
      label: classes.newReportButtonLabel,
    }
  }

  get greetingTime() {
    const currentHours = new Date().getHours()

    if (currentHours >= SPLIT_EVENING) {
      return 'Good Evening'
    }

    if (currentHours >= SPLIT_AFTERNOON) {
      return 'Good Afternoon'
    }

    return 'Good Morning'
  }

  render() {
    const { userFirstName, openReportModal } = this.props
    const title = `${this.greetingTime}, ${userFirstName}!`
    return (
      <Header classes={this.headerClasses} title={title}>
        <Button
          onClick={openReportModal}
          variant="contained"
          wide
          classes={this.newReportButtonClasses}
          data-qa="create-report-btn"
        >
          + New Report
        </Button>
      </Header>
    )
  }
}

export default compose(
  withStyles(styles),
  connect(state => ({
    userFirstName: get(state, 'authentication.user.name.first'),
  }))
)(ReportsDashboardHeader)
