import React, { FC, memo } from 'react'
import { Stack, Typography } from '@mui/material'
import { filter, get, noop, sumBy } from 'lodash'
import { RowBasedTableColumn, getCustomColumnConfig } from 'client-shared/utils/rowBasedTable'
import { ColumnDataTypeEnum, RowBasedTable } from '@bowery-valuation/ui-components'
import { formatCurrencyFloat } from 'shared/utils/formatters/numberFormatters'
import { REPLACEMENT_COST_NEW_ID } from 'shared/constants/insurableValue/mvs'

import { GRADE_OPTIONS } from './constants'

type TotalMvsTableOwnProps = {
  form: any
}

export const TotalMvsTable: FC<TotalMvsTableOwnProps> = ({ form }) => {
  const { values } = form
  const buildingComponents = get(values, 'buildingComponents', [])
  const aboveGradeComponents = filter(buildingComponents, { grade: GRADE_OPTIONS.aboveGrade })
  const belowGradeComponents = filter(buildingComponents, { grade: GRADE_OPTIONS.belowGrade })

  const aboveGradeReplacementCostNew = sumBy(aboveGradeComponents, REPLACEMENT_COST_NEW_ID)
  const belowGradeReplacementCostNew = sumBy(belowGradeComponents, REPLACEMENT_COST_NEW_ID)
  const totalReplacementCostNew = aboveGradeReplacementCostNew + belowGradeReplacementCostNew
  const columns: RowBasedTableColumn[] = [
    {
      name: 'label',
      label: 'Building Name',
      type: ColumnDataTypeEnum.text,
      permanent: true,
      align: 'left',
      editable: false,
    },
    {
      name: 'total',
      label: 'Total',
      type: ColumnDataTypeEnum.text,
      permanent: true,
      align: 'right',
      editable: false,
    },
  ]

  const rows = [
    {
      readOnly: false,
      suppressMovable: true,
      permanent: false,
      type: ColumnDataTypeEnum.text,
      label: 'Above Grade Replacement Cost New',
      total: formatCurrencyFloat(aboveGradeReplacementCostNew),
      id: 'aboveGradeReplacementCostNew',
      rowDef: { hideAction: true },
    },
    {
      readOnly: false,
      suppressMovable: true,
      permanent: false,
      type: ColumnDataTypeEnum.text,
      label: 'Below Grade Replacement Cost New',
      total: formatCurrencyFloat(belowGradeReplacementCostNew),
      id: 'belowGradeReplacementCostNew',
      rowDef: { hideAction: true },
    },
    {
      readOnly: false,
      suppressMovable: true,
      permanent: false,
      type: ColumnDataTypeEnum.text,
      label: 'MVS Total Replacement Cost New',
      total: formatCurrencyFloat(totalReplacementCostNew),
      id: 'totalReplacementCostNew',
      rowDef: { hideAction: true, summary: true },
    },
  ]

  return (
    <Stack spacing={1}>
      <Typography variant="h6">MVS Total Replacement Cost New</Typography>
      <RowBasedTable
        id="mvs-total-table"
        columns={columns}
        rows={rows}
        onRowUpdate={noop}
        onManyRowsUpdate={noop}
        getCustomColumnConfig={getCustomColumnConfig}
        actionCellHidden
        hideIndexColumn
        onColumnDragEnd={noop}
        onRowsDragEnd={noop}
        onColumnDelete={noop}
        onColumnUpdate={noop}
        onRowDelete={noop}
      />
    </Stack>
  )
}

export default memo(TotalMvsTable)
