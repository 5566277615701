/* eslint-disable max-len */
export const GLOSSARY_INTRODUCTION =
  'Unless otherwise noted, The Dictionary of Real Estate Appraisal, 6th edition (Chicago: Appraisal Institute, 2015) is the source of the following definitions.'

export const DEFAULT_TERMS = [
  {
    term: 'As Is Market Value',
    definition:
      'The estimate of the market value of real property in its current physical condition, use, and zoning as of the appraisal date, according to the Interagency Appraisal and Evaluation Guidelines (Federal Deposit Insurance Corporation: 2010). Note: The use of the “as is” phrase is specific to appraisal regulations pursuant to FIRREA applying to appraisals prepared for regulated lenders in the United States.',
  },
  {
    term: 'Arm’s Length Transaction',
    definition: 'A transaction between unrelated parties who are each acting in his or her own best interest.',
  },
  {
    term: 'Condominium',
    definition: 'A multiunit structure, or a unit within such a structure, with a condominium form of ownership.',
  },
  {
    term: 'Deferred Maintenance',
    definition:
      'Items of wear and tear on a property that should be fixed now to protect the value or income-producing ability of the property, such as a broken window, a dead tree, a leak in the roof, or a faulty roof that must be completely replaced. These items are almost always curable.',
  },
  {
    term: 'Depreciation',
    definition:
      'A loss in property value from any cause; the difference between the cost of an improvement on the effective date of the appraisal and the market value of the improvements on the same date.',
  },
  {
    term: 'Direct Capitalization',
    definition: `A method used to convert an estimate of a single year's net operating income expectancy into an indication of value in one direct step, either by dividing the income estimate by an appropriate rate or by multiplying the income estimate by an appropriate factor. This technique employs capitalization rates and multipliers extracted from sales. Only the first year's income is considered. Yield and value change are implied, but not identified overall. This method is most useful when the property is already operating on a stabilized basis, according to The Appraisal of Real Estate, 14th Edition (Appraisal Institute: 2013).`,
  },
  {
    term: 'Discounted Cash Flow',
    definition:
      'The procedure in which a discount rate is applied to a set of projected income streams and a reversion. The analysis specifics the quantity and timing of the reversion, and discounts each to its present value at a specified yield rate.',
  },
  {
    term: 'Effective Date',
    definition:
      '(1) The date on which the appraisal or review applies. (2) In a lease document, the date upon which the lease goes into effect.',
  },
  {
    term: 'Effective Gross Income',
    definition:
      'The anticipated income from all operations of real property adjusted for vacancy and collection losses.',
  },
  {
    term: 'Entrepreneurial Profit',
    definition: `(1) A market-derived figure that represents the amount an entrepreneur receives for his or her contribution to a project and risk; the difference between the total cost of a property (cost of development) and its market value (property value after completion), which represents the entrepreneur’s compensation for the risk and expertise associated with development. An entrepreneur is motivated by the prospect of future value enhancement (i.e., the entrepreneurial incentive). An entrepreneur who successfully creates value through new development, expansion, renovation, or an innovative change of use is rewarded by entrepreneurial profit. Entrepreneurs may also fail and suffer losses. (2) In economics, the actual return on successful management practices, often identified with coordination, the fourth factor of production following land, labor, and capital; also called entrepreneurial return or entrepreneurial reward.`,
  },
  {
    term: 'Equity Dividend',
    definition:
      'The portion of net income that remains after debt service is paid; this is returned to the equity position.',
  },
  {
    term: 'Excess Land',
    definition:
      'Land that is not needed to serve or support the existing use. The highest and best use of the excess land may or may not be the same as the highest and best use of the improved parcel. Excess land has the potential to be sold separately and is valued separately.',
  },
  {
    term: 'Exposure Time',
    definition:
      '(1) The time a property remains on the market. (2) The estimated length of time that the property interest being appraised would have been offered on the market prior to the hypothetical consummation of a sale at market value on the effective date of the appraisal. Note: Exposure time is a retrospective.',
  },
  {
    term: 'Extraordinary Assumption',
    definition:
      'An assumption, directly related to a specific assignment, as of the effective date of the assignment results, which, if found to be false, could alter the appraiser’s opinions or conclusions.',
  },
  {
    term: 'Fee Simple Interest',
    definition:
      'Absolute ownership unencumbered by any other interest or estate, subject only to the limitations imposed by the governmental powers of taxation, eminent domain, police power, and escheat.',
  },
  {
    term: 'Financial Feasibility',
    definition:
      'An analysis to determine which of those uses deemed possible and legal can provide a net return to the owner of the site.',
  },
  {
    term: 'Gross Building Area',
    definition:
      'Total floor area of a building, excluding unenclosed areas, measured from the exterior of the walls of the above grade area. This includes mezzanines and basements if and when typically included in the market area of the type of property involved.',
  },
  {
    term: 'Highest and Best Use',
    definition:
      '(1) The reasonably probable use of property that results in the highest value. The four criteria that the highest and best use must meet are legal permissibility, physical possibility, financial feasibility, and maximum productivity. (2) The use of an asset that maximizes its potential and that is possible, legally permissible, and financially feasible. The highest and best use may be for continuation of an asset’s existing use or for some alternative use. This is determined by the use that a market participant would have in mind for the asset when formulating the price that it would be willing to bid, according to David Parker’s International Valuation Standards (John Wiley & Sons, Ltd: 2016). (3) The highest and most profitable use for which the property is adaptable and needed or likely to be needed in the reasonably near future, according to the Uniform Appraisal Standards for Federal Land Acquisitions (The Appraisal Foundation: 2016).',
  },
  {
    term: 'Hypothetical Condition',
    definition:
      'A condition, directly related to a specific assignment, which is contrary to what is known by the appraiser to exist on the effective date of the assignment results but is used for the purpose of analysis.',
  },
  {
    term: 'Insurable Value',
    definition: 'A type of value for insurance purposes.',
  },
  {
    term: 'Leased Fee Interest',
    definition:
      'The ownership interest held by the lessor, which includes the right to receive the contract rent specified in the lease plus the reversionary right when the lease expires.',
  },
  {
    term: 'Leasehold Interest',
    definition:
      'The right held by the lessee to use and occupy real estate for a stated term and under the conditions specified in the lease.',
  },
  {
    term: 'Legally Permissible Use',
    definition:
      'An investigation into existing zoning regulations, lease terms, and deed restrictions on the site to determine which uses are legally permitted.',
  },
  {
    term: 'Liquidation Value (also known as Disposition Value)',
    definition:
      'The most probable price that a specified interest in real property should bring under the following conditions:' +
      '\n\u2022 Consummation of a sale within a short time period.' +
      '\n\u2022 The property is subjected to market conditions prevailing as of the date of valuation.' +
      '\n\u2022 Both the buyer and seller are acting prudently and knowledgeably.' +
      '\n\u2022 The seller is under extreme compulsion to sell.' +
      '\n\u2022 The buyer is typically motivated.' +
      '\n\u2022 Both parties are acting in what they consider to be their best interests.' +
      '\n\u2022 A normal marketing effort is not possible due to the brief exposure time.' +
      '\n\u2022 Payment will be made in cash in US dollars or in terms of financial arrangements comparable thereto.' +
      '\n\u2022 The price represents the normal consideration for the property sold, unaffected by special or creative financing or sales concessions granted by anyone associated with the sale.',
  },
  {
    term: 'Marketing Time',
    definition:
      'An opinion of the amount of time it might take to sell a real or personal property interest at the concluded market value level during the period immediately after the effective date of an appraisal. Marketing time differs from exposure time, which is always presumed to precede the effective date of an appraisal.',
  },
  {
    term: 'Market Rent',
    definition:
      'The most probable rent that property should bring in a competitive and open market reflecting the conditions and restrictions of a specified lease agreement, including the rental adjustment and revaluation, permitted uses, use restrictions, expense obligations, term, concessions, renewal and purchase options, and tenant improvements (TIs).',
  },
  {
    term: 'Net Operating Income',
    definition:
      'The anticipated net income remaining after all operating expenses are deducted from effective gross income.',
  },
  {
    term: 'Net Rentable Area',
    definition:
      'For office or retail buildings, the tenant’s pro rata portion of the entire office floor, excluding elements of the building that penetrate through the floor to the areas below. The rentable area of a floor is computed by measuring to the inside finished surface of the dominant portion of the permanent building walls, excluding any major vertical penetrations of the floor. Alternatively, the amount of space on which the rent is based; calculated according to local practice.',
  },
  {
    term: 'Physically Possible Use',
    definition: 'An analysis to determine those uses of the subject which can be deemed physically possible.',
  },
  {
    term: 'Potential Gross Income',
    definition:
      'The total potential income attributable to the real property at full occupancy before operating expenses are deducted. It may refer to the level of rental income prevailing in the market or that contractually determine by existing leases.',
  },
  {
    term: 'Property Rights Appraised',
    definition:
      'The ownership interest held by the lessor, which includes the right to receive the contract rent specified in the lease plus the reversionary right when the lease expires.',
  },
  {
    term: 'Prospective Market Value “As Completed” and “As Stabilized”',
    definition:
      'A prospective market value may be appropriate for the valuation of a property interest related to a credit decision for a proposed development or renovation project. According to USPAP, an appraisal with a prospective market value reflects an effective date that is subsequent to the date of the appraisal report. Prospective value opinions are intended to reflect the current expectations and perceptions of market participants, based on available data. Two prospective value opinions may be required to reflect the time frame during which development, construction, and occupancy will occur. The prospective market value—as completed— reflects the property’s market value as of the time that development is expected to be completed. The prospective market value—as stabilized— reflects the property’s market value as of the time the property is projected to achieve stabilized occupancy. For an income-producing property, stabilized occupancy is the occupancy level that a property is expected to achieve after the property is exposed to the market for lease over a reasonable period of time and at comparable terms and conditions to other similar properties.',
  },
  {
    term: 'Prospective Opinion of Value',
    definition:
      'A value opinion effective as of a specified future date. The term does not define a type of value. Instead, it identifies a value opinion as being effective at some specific future date. An opinion of value as of a prospective date is frequently sought in connection with projects that are proposed, under construction, or under conversion to a new use, or those that have not yet achieved sellout or a stabilized level of long-term occupancy.',
  },
  {
    term: 'Replacement Cost for Insurance Purposes',
    definition:
      'The estimated cost, at current prices as of the effective date of valuation, of a substitute for the building being valued, using modern materials and current standards, design, and layout for insurance coverage purposes guaranteeing that damaged property is replaced with new property (i.e., depreciation is not deducted).',
  },
  {
    term: 'Replacement Costs',
    definition:
      'The estimated cost to construct, at current prices as of a specific date, a substitute for a building or other improvements, using modern materials and current standards, design, and layout.',
  },
  {
    term: 'Reproduction Costs',
    definition:
      'The estimated cost to construct, at current prices as of the effective date of the appraisal, an exact duplicate or replica of the building being appraised, using the same materials, construction standards, design, layout, and quality of workmanship and embodying all the deficiencies, super-adequacies, and obsolescence of the subject building.',
  },
  {
    term: 'Retrospective Value Opinion',
    definition: `A value opinion effective as of a specified historical date. The term retrospective does not define a type of value. Instead, it identifies a value opinion as being effective at some specific prior date. Value as of a historical date is frequently sought in connection with property tax appeals, damage models, lease renegotiation, deficiency judgments, estate tax, and condemnation. Inclusion of the type of value with this term is appropriate, e.g., "retrospective market value opinion".`,
  },
  {
    term: 'Reversion',
    definition: 'A lump-sum benefit an investor expects to receive upon the termination of the investment.',
  },
  {
    term: 'Stabilized Income',
    definition:
      '(1) An estimate of income, either current or forecasted, that presumes the property is at stabilized occupancy. (2) The forecast of the subject property’s yearly average income (or average- equivalent income) expected for the economic life of the subject property. (3) Projected income that is subject to change but has been adjusted to reflect an equivalent, stable annual income.',
  },
  {
    term: 'Stabilized Occupancy',
    definition:
      '(1) The occupancy of a property that would be expected at a particular point in time, considering its relative competitive strength and supply and demand conditions at the time, and presuming it is priced at market rent and has had reasonable market exposure. A property is at stabilized occupancy when it is capturing its appropriate share of market demand. (2) An expression of the average or typical occupancy that would be expected for a property over a specified projection period or over its economic life.',
  },
  {
    term: 'Surplus Land',
    definition:
      'Land that is not currently needed to support the existing use but cannot be separated from the property and sold off for another use. Surplus land does not have an independent highest and best use and may or may not contribute value to the improved parcel.',
  },
  {
    term: 'Yield Capitalization',
    definition: `The capitalization method used to convert future benefits into present value by discounting each future benefit at an appropriate yield rate. This method explicitly considers a series of cash flows (net income over a holding period) over time together with any reversion value or resale proceeds. Since this technique explicitly reflects the investment's income pattern, it is especially suited to multi-tenant properties with varying leasing schedules as well as properties that are not operating at stabilized occupancy, according to The Appraisal of Real Estate, 14th Edition (Appraisal Institute: 2013).`,
  },
]
