import React from 'react'
import PropTypes from 'prop-types'
import BoweryDate from '@bowery-valuation/bowery-date'
import { Typography } from '@mui/material'

import { RadioButtonList } from '../../../../../shared/components'

import { SALE_CONDITIONS_OPTIONS } from './constants'

class SaleCondition extends React.PureComponent {
  static propTypes = {
    salesHistoryItem: PropTypes.object.isRequired,
    name: PropTypes.string.isRequired,
  }

  render() {
    const { salesHistoryItem, name } = this.props
    return (
      <>
        <Typography variant="subtitle1">
          {salesHistoryItem.date ? new BoweryDate(salesHistoryItem.date).format('MM-DD-YYYY') : ''} Sale Condition
        </Typography>
        <RadioButtonList
          name={name}
          horizontal
          items={SALE_CONDITIONS_OPTIONS}
          helperText="Required to submit subject to the Sales Comp database"
        />
      </>
    )
  }
}

export default SaleCondition
