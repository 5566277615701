import { Column } from '@bowery-valuation/ui-components/dist/row-based-table/types/column'

export interface RowBasedTableColumn extends Column {
  editable: boolean | ((params: any) => boolean)
  suppressMovable?: boolean
  id?: string
  cellEditorSelector?: (data: any) => any
  cellRendererFramework?: ({ value, data }: any) => any
  cellStyle?: any
  minWidth?: number
  maxWidth?: number
  autoHeight?: boolean
  textWrap?: boolean
}

export const getCustomColumnConfig = (column: RowBasedTableColumn) => {
  return {
    cellEditorSelector: column.cellEditorSelector,
    cellRendererFramework: column.cellRendererFramework,
    cellEditorFramework: undefined,
    editable: column.editable,
    autoHeight: column.autoHeight || true,
    textWrap: column.textWrap || true,
    cellStyle: { 'white-space': 'normal', 'text-wrap': 'wrap' },
  }
}
