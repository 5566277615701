import React from 'react'
import PropTypes from 'prop-types'
import { withFeatureFlag } from '@bowery-valuation/feature-flagger-client'
import ChevronLeft from '@mui/icons-material/ChevronLeft'
import ChevronRight from '@mui/icons-material/ChevronRight'
import { Box, Drawer, Stack } from '@mui/material'
import { withStyles } from '@mui/styles'
import classnames from 'classnames'
import ReactMouseTrap from 'react-mousetrap'

import { compose } from 'recompose'

import { connect } from 'react-redux'

import { changeDrawerSection } from 'client-shared/redux/actions/drawer'
import { FEATURE_FLAG_AI_APPRAISAL_ASSISTANT } from 'shared/constants/featureFlags'

import { HEADER_HEIGHT } from '../../../shared/layout/constants'
import MenuIcon from '../../../shared/layout/MenuIcon'

import { sectionNameSelector } from '../selectors'

import {
  BOOKMARKS_SHORTCUTS,
  DrawerMenuItems,
  DrawerSections,
  GERI_SHORTCUTS,
  GOOGLE_MAPS_SHORTCUTS,
  Layout,
  MAP_GALLERY_SHORTCUTS,
  NOTES_SHORTCUTS,
  PHOTO_GALLERY_SHORTCUTS,
} from './constants'
import {
  BookmarksSection,
  ContentLibrarySection,
  GoogleMapSection,
  MapGallerySection,
  NotesSection,
  PhotoGallerySection,
} from './sections'
import ChatInterface from './sections/Chat'

const drawerHeight = `calc(100vh - ${Layout.MENU_WIDTH}px)`

const styles = theme => {
  return {
    drawerOpen: {
      width: Layout.DRAWER_WIDTH,
      height: drawerHeight,
      marginTop: HEADER_HEIGHT,
      flexDirection: 'row',
      transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
    },
    drawerClose: {
      overflowX: 'hidden',
      height: drawerHeight,
      marginTop: HEADER_HEIGHT,
      transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
    },
    content: {
      height: '100%',
      flexGrow: 1,
    },
  }
}

class RightDrawer extends React.PureComponent {
  static propTypes = {
    sectionName: PropTypes.string,
    isDrawerOpen: PropTypes.bool.isRequired,
  }

  static defaultProps = { isDrawerOpen: false, sectionName: undefined }

  componentDidMount() {
    const { bindShortcut } = this.props
    bindShortcut(GOOGLE_MAPS_SHORTCUTS, this.onShortcutKeyPressed)
    bindShortcut(MAP_GALLERY_SHORTCUTS, this.onShortcutKeyPressed)
    bindShortcut(PHOTO_GALLERY_SHORTCUTS, this.onShortcutKeyPressed)
    bindShortcut(BOOKMARKS_SHORTCUTS, this.onShortcutKeyPressed)
    bindShortcut(NOTES_SHORTCUTS, this.onShortcutKeyPressed)
    bindShortcut(GERI_SHORTCUTS, this.onShortcutKeyPressed)
  }

  componentWillUnmount() {
    const { unbindShortcut } = this.props
    unbindShortcut(GOOGLE_MAPS_SHORTCUTS)
    unbindShortcut(MAP_GALLERY_SHORTCUTS)
    unbindShortcut(PHOTO_GALLERY_SHORTCUTS)
    unbindShortcut(BOOKMARKS_SHORTCUTS)
    unbindShortcut(NOTES_SHORTCUTS)
    unbindShortcut(GERI_SHORTCUTS)
  }

  onShortcutKeyPressed = (event, combo) => {
    const { changeDrawerSection, isDrawerOpen, sectionName, enableGeri } = this.props
    event.preventDefault()
    let newSection = undefined
    if (GOOGLE_MAPS_SHORTCUTS.includes(combo)) {
      newSection = DrawerSections.GOOGLE_MAPS
    } else if (MAP_GALLERY_SHORTCUTS.includes(combo)) {
      newSection = DrawerSections.MAP_GALLERY
    } else if (PHOTO_GALLERY_SHORTCUTS.includes(combo)) {
      newSection = DrawerSections.PHOTO_GALLERY
    } else if (BOOKMARKS_SHORTCUTS.includes(combo)) {
      newSection = DrawerSections.BOOKMARKS
    } else if (NOTES_SHORTCUTS.includes(combo)) {
      newSection = DrawerSections.NOTES
    } else if (GERI_SHORTCUTS.includes(combo) && enableGeri) {
      newSection = DrawerSections.GERI
    }

    if (isDrawerOpen && newSection === sectionName) {
      changeDrawerSection(undefined)
    } else if (newSection) {
      changeDrawerSection(newSection)
    }
  }

  toggleDrawer = () => {
    const { changeDrawerSection, isDrawerOpen } = this.props

    if (isDrawerOpen) {
      changeDrawerSection(undefined)
    } else {
      changeDrawerSection(DrawerSections.GOOGLE_MAPS)
    }
  }

  openDrawer = sectionName => {
    const { changeDrawerSection } = this.props
    changeDrawerSection(sectionName)
  }

  closeDrawer = () => {
    const { changeDrawerSection } = this.props

    changeDrawerSection(undefined)
  }

  renderDrawerSectionContent = () => {
    const selectedSection = this.props.sectionName

    switch (selectedSection) {
      case DrawerSections.GOOGLE_MAPS:
        return <GoogleMapSection />
      case DrawerSections.MAP_GALLERY:
        return <MapGallerySection />
      case DrawerSections.PHOTO_GALLERY:
        return <PhotoGallerySection />
      case DrawerSections.BOOKMARKS:
        return <BookmarksSection />
      case DrawerSections.NOTES:
        return <NotesSection />
      case DrawerSections.CONTENT_LIBRARY:
        return <ContentLibrarySection />
      case DrawerSections.GERI:
        return <ChatInterface />
      default:
        return null
    }
  }

  render() {
    const { classes, sectionName, isDrawerOpen, changeDrawerSection, enableGeri } = this.props

    const drawerPaperClasses = classnames({
      [classes.drawerOpen]: isDrawerOpen,
      [classes.drawerClose]: !isDrawerOpen,
    })

    return (
      <Drawer
        anchor="right"
        variant="permanent"
        open={isDrawerOpen}
        classes={{ paper: drawerPaperClasses }}
        PaperProps={{ role: 'toolbar', sx: { padding: 0, flexDirection: 'row', justifyContent: 'end' } }}
      >
        <Stack pt={2} sx={{ borderRight: ({ palette }) => `1px solid ${palette.divider}` }}>
          {DrawerMenuItems.map(item => {
            if (item.name === DrawerSections.GERI && !enableGeri) {
              return null
            }

            return (
              <Box p={2} textAlign="center" key={item.name}>
                <MenuIcon
                  key={item.name}
                  onClick={() => changeDrawerSection(item.name)}
                  tooltipTitle={item.tooltip}
                  tooltipPosition="left"
                  icon={item.icon}
                  isActive={item.name === sectionName}
                />
              </Box>
            )
          })}
          <Box p={2} mt="auto" textAlign="center" onClick={this.toggleDrawer} sx={{ cursor: 'pointer' }}>
            {isDrawerOpen ? <ChevronRight /> : <ChevronLeft />}
          </Box>
        </Stack>

        {isDrawerOpen && <div className={classes.content}>{this.renderDrawerSectionContent()}</div>}
      </Drawer>
    )
  }
}

export default compose(
  withFeatureFlag(FEATURE_FLAG_AI_APPRAISAL_ASSISTANT, 'enableGeri'),
  connect(
    state => ({
      ...sectionNameSelector(state),
    }),
    {
      changeDrawerSection,
    }
  ),
  ReactMouseTrap,
  withStyles(styles)
)(RightDrawer)
