import numeral from 'numeral'

import { formatFloat } from 'client-shared/utils/numberFormatters'

import { GEOGRAPHY_OPTIONS } from 'shared/constants/property/geography'
import { formatSiteArea } from 'shared/utils/formatters'

export const getPropertyIdentificationTableData = ({ siteArea, siteAreaMeasure, address }) => {
  switch (address.locationIdentifier) {
    case GEOGRAPHY_OPTIONS.NY:
      return {
        order: ['siteArea', 'county'],
        headers: { siteArea: 'Site Area', county: 'County' },
        values: { siteArea: formatSiteArea({ siteArea, siteAreaMeasure }), county: address.county },
      }

    case GEOGRAPHY_OPTIONS.NJ:
    default:
      return {
        order: ['siteArea', 'city', 'county'],
        headers: {
          siteArea: 'Site Area',
          city: 'City',
          county: 'County',
        },
        values: {
          siteArea: numeral(formatFloat(siteArea)).format('0,0.[0000]'),
          city: address.city,
          county: address.county,
        },
      }
  }
}
