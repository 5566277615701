import { useEffect } from 'react'

import useScript, { SCRIPT_LOADING_STATUS } from '../../shared/hooks/useScript'

const googleMapApiUrl = `https://maps.googleapis.com/maps/api/js?v=${global.env.googleMapsVersion}&key=${global.env.googleApiKey}&libraries=places`

const mergeStatuses = statuses => {
  let allIdle = true
  let hasError = false
  for (const status of statuses) {
    switch (status) {
      case SCRIPT_LOADING_STATUS.IDLE:
        break
      case SCRIPT_LOADING_STATUS.READY:
        allIdle = false
        break
      case SCRIPT_LOADING_STATUS.LOADING:
        return SCRIPT_LOADING_STATUS.LOADING
      case SCRIPT_LOADING_STATUS.ERROR:
        allIdle = false
        hasError = true
        break
      default:
        break
    }
  }
  if (allIdle) {
    return SCRIPT_LOADING_STATUS.IDLE
  } else if (hasError) {
    return SCRIPT_LOADING_STATUS.ERROR
  } else {
    return SCRIPT_LOADING_STATUS.READY
  }
}

const useExternalApiScripts = errorNotification => {
  const googleMapApiStatus = useScript(googleMapApiUrl)

  useEffect(() => {
    if (googleMapApiStatus === SCRIPT_LOADING_STATUS.ERROR) {
      const errorMessage = 'Failed to load Google API script. Google services will not be available.'

      errorNotification(errorMessage)
      console.error(errorMessage)
    }
  }, [errorNotification, googleMapApiStatus])

  return mergeStatuses([googleMapApiStatus])
}

export default useExternalApiScripts
