export const TemplateTypes = {
  FREDDIE_MAC: 'freddie-mac',
  BOWERY_WAY: 'bowery-way',
}
export const TEMPLATE_TYPE_LIST = [TemplateTypes.FREDDIE_MAC, TemplateTypes.BOWERY_WAY]

export const ValueConclusionTypes = {
  AS_IS: 'AS_IS',
  AS_STABILIZED: 'AS_STABILIZED',
  AS_COMPLETE: 'AS_COMPLETE',
}
export const VALUE_CONCLUSION_TYPES = [
  ValueConclusionTypes.AS_IS,
  ValueConclusionTypes.AS_STABILIZED,
  ValueConclusionTypes.AS_COMPLETE,
]

export const DEFAULT_TEMPLATE_TYPE = 'bowery-way'
export const DEFAULT_VALUE_CONCLUSION_TYPE = 'AS_IS'
