export const PropertyTypes = {
  MULTI_FAMILY: 'multifamily',
  MIXED_USE: 'mixed-use',
  COMMERCIAL: 'commercial',
}

export const PROPERTY_TYPES_PROPER_CASE = {
  [PropertyTypes.MULTI_FAMILY]: 'Multifamily',
  [PropertyTypes.MIXED_USE]: 'Mixed Use',
  [PropertyTypes.COMMERCIAL]: 'Commercial',
}

export const propertyTypeOptions = Object.keys(PROPERTY_TYPES_PROPER_CASE).map(key => {
  return {
    label: PROPERTY_TYPES_PROPER_CASE[key],
    value: key,
  }
})

export const CompPropertyTypes = {
  multifamily: 'Multifamily',
  'mixed-use': 'Mixed-Use',
  retail: 'Retail',
  office: 'Office',
  land: 'Land',
  industrial: 'Industrial',
  communityFacility: 'Community Facility',
  specialpurpose: 'Special Purpose',
  hospitality: 'Hospitality',
  'residential-(non-commercial)': 'Residential (Non-commercial)',
}

export const COMMERCIAL_PROPERTY_SUBTYPES = [
  'retail',
  'office',
  'land',
  'industrial',
  'communityFacility',
  'specialpurpose',
]

export const COMMERCIAL_SUBTYPES_PROPER_CASE = {
  retail: 'Retail',
  office: 'Office',
  land: 'Land',
  industrial: 'Industrial',
  communityFacility: 'Community Facility',
  specialpurpose: 'Special Purpose',
}

export const COMMERCIAL_PROPERTY_TYPES = {
  multiFamily: 'Multifamily',
  residentialCondo: 'Residential Condo',
  industrialCondo: 'Industrial Condo',
  officeCondo: 'Office Condo',
  mixedUseCommercialApartment: 'Mixed-Use Commercial/Apartment',
  retailSpace: 'Retail',
  officeSpace: 'Office',
  industrialSpace: 'Industrial',
}

export const COMPARABLE_TYPES = {
  MULTI_FAMILY: 'multifamily',
  MIXED_USE: 'mixed-use',
  RETAIL: 'retail',
  OFFICE: 'office',
  LAND: 'land',
  INDUSTRIAL: 'industrial',
  COMMUNITY_FACILITY: 'communityfacility',
  SPECIAL_PURPOSE: 'specialpurpose',
  HOSPITALITY: 'hospitality',
  RESIDENTIAL_NON_COMMERCIAL: 'residential-(non-commercial)',
}

export const COMPARABLE_TYPE_OPTIONS = [
  {
    label: 'Multifamily',
    value: COMPARABLE_TYPES.MULTI_FAMILY,
  },
  {
    label: 'Mixed-Use',
    value: COMPARABLE_TYPES.MIXED_USE,
  },
  {
    label: 'Retail',
    value: COMPARABLE_TYPES.RETAIL,
  },
  {
    label: 'Office',
    value: COMPARABLE_TYPES.OFFICE,
  },
  {
    label: 'Land',
    value: COMPARABLE_TYPES.LAND,
  },
  {
    label: 'Industrial',
    value: COMPARABLE_TYPES.INDUSTRIAL,
  },
  {
    label: 'Community Facility',
    value: COMPARABLE_TYPES.COMMUNITY_FACILITY,
  },
  {
    label: 'Special Purpose',
    value: COMPARABLE_TYPES.SPECIAL_PURPOSE,
  },
  {
    label: 'Hospitality',
    value: COMPARABLE_TYPES.HOSPITALITY,
  },
  {
    label: 'Residential (Non-commercial)',
    value: COMPARABLE_TYPES.RESIDENTIAL_NON_COMMERCIAL,
  },
]
