import React from 'react'
import PropTypes from 'prop-types'

import { Typography } from '@mui/material'
import { withStyles } from '@mui/styles'
import { compose, pure } from 'recompose'

import { Layout } from './constants'

const styles = theme => ({
  root: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
  header: {
    flexBasis: Layout.DRAWER_HEADER_HEIGHT,
    position: 'sticky',
    display: 'flex',
    alignItems: 'center',
    padding: '0 16px',
    boxSizing: 'border-box',
    borderBottom: `1px solid ${theme.palette.grey[300]}`,
    justifyContent: 'space-between',
  },
  content: {
    flexBasis: `calc(100% - ${Layout.DRAWER_HEADER_HEIGHT}px)`,
    overflowY: 'auto',
  },
  title: {
    fontSize: 20,
    fontWeight: 700,
    letterSpacing: 0.25,
    color: '#212121',
  },
})

const SectionWrapper = ({ classes, title, rightElement, children }) => {
  return (
    <div className={classes.root}>
      <div className={classes.header}>
        <Typography variant="h5" className={classes.title}>
          {title}
        </Typography>
        <div>{rightElement}</div>
      </div>
      <div className={classes.content}>{children}</div>
    </div>
  )
}

SectionWrapper.propTypes = {
  classes: PropTypes.object.isRequired,
  title: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
  rightElement: PropTypes.node,
}

SectionWrapper.defaultProps = {
  rightElement: null,
}

export default compose(pure, withStyles(styles))(SectionWrapper)
