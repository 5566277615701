import React from 'react'
import PropTypes from 'prop-types'

import ReactDropzone from 'react-dropzone'

import { Button, Stack, Typography } from '@mui/material'

const Dropzone = ({ onDrop, dropZoneRef, helperText, acceptedFileTypeString, ...props }) => {
  return (
    <ReactDropzone style={{ width: '100%' }} ref={dropZoneRef} onDrop={onDrop} {...props}>
      <Stack
        alignItems="center"
        justifyContent="center"
        spacing={2}
        sx={{ border: '1px dashed lightgrey', height: 165 }}
      >
        <Typography variant="caption">{helperText}</Typography>
        <Button variant="contained">Upload Files</Button>
        <Typography variant="caption">{acceptedFileTypeString}</Typography>
      </Stack>
    </ReactDropzone>
  )
}

Dropzone.displayName = 'Dropzone'

Dropzone.propTypes = {
  onDrop: PropTypes.func.isRequired,
  helperText: PropTypes.string,
  acceptedFileTypeString: PropTypes.string,
}

Dropzone.defaultProps = {
  helperText: 'Drag and drop or browse files below',
}

export default Dropzone
