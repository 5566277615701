import { GeneratedText } from '../../../narrative/GeneratedText'

const generate = () => {
  const gt = new GeneratedText()
  gt.t`The site is similar to others in the vicinity, and there are no known negative external factors.
  Based on its current use, it is functionally adequate.`

  return gt.getParts()
}

export default { generate }
