import React from 'react'
import { useField, FieldProps, FieldRenderProps } from 'react-final-form'

import Switch, { SwitchProps } from './Switch'

export type SwitchFieldProps = SwitchProps & FieldProps<boolean, FieldRenderProps<boolean>>

const SwitchField: React.FC<SwitchFieldProps> = ({ name, ...otherProps }) => {
  const field = useField(name, { type: 'checkbox' })
  const { checked, onChange } = field.input

  return <Switch checked={checked} onChange={onChange} {...otherProps} />
}

export default React.memo(SwitchField)
