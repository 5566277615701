import createDecorator from 'final-form-calculate'

import { get, sumBy } from 'lodash'
import {
  calculateCAOpinionTaxableAssessedValue,
  calculateTaxInformation,
  canUseCaliforniaTaxProjections,
} from 'shared/helpers/taxes'

import { getActualTaxRate } from 'shared/report-calculations/income-approach/tax/helpers'

import TaxCalculations from 'shared/report-calculations/income-approach/tax/tax-calculations'

import {
  generateTaxableAssessedValueTable,
  parseTaxableAssessedValueTable,
} from '../../../../../../shared/helpers/taxes'

import { BASIS_TYPES } from '../../../../../../shared/constants/report/incomeApproach/taxes'

import { getNewAssessedValueColumns } from './helpers'

// Decorator that handles length changes should come first, otherwise it will return an outdated value
const currentLiability = [
  createDecorator({
    field: 'assessedValueTable',
    updates: {
      assessedValueTable: (value, allValues, prevValues) => {
        if (!prevValues.assessedValueTable || prevValues.assessedValueTable.length === value.length) {
          return value
        }

        const { assessedValueTable, squareFootage, residentialUnitCount } = allValues
        const { taxItems } = parseTaxableAssessedValueTable(assessedValueTable)

        return generateTaxableAssessedValueTable(taxItems, squareFootage, residentialUnitCount)
      },
    },
  }),
  createDecorator({
    field: [
      /assessedValueTable\[\d+\].+/,
      'additionalSpecialAssessments',
      'taxRate',
      'additionalTaxRates',
      'hasTransitionalAssessedValue',
    ],
    updates: (value, fieldName, allValues) => {
      const { assessedValueTable } = allValues
      const {
        taxRate,
        additionalTaxRates,
        additionalSpecialAssessments,
        hasTransitionalAssessedValue,
        squareFootage,
        residentialUnitCount,
      } = allValues

      const { taxItems } = parseTaxableAssessedValueTable(assessedValueTable)

      const updatedAssessedValueTable = generateTaxableAssessedValueTable(taxItems, squareFootage, residentialUnitCount)

      const { taxLiability, taxableAssessedValue, usesTransitionalAssessedValue } = calculateTaxInformation(
        taxItems,
        taxRate,
        hasTransitionalAssessedValue,
        additionalTaxRates,
        additionalSpecialAssessments
      )

      const assessedValueColumns = getNewAssessedValueColumns(
        allValues.assessedValueColumns,
        hasTransitionalAssessedValue
      )

      return {
        assessedValueTable: updatedAssessedValueTable,
        assessedValueColumns,
        taxLiability,
        taxableAssessedValue,
        usesTransitionalAssessedValue,
      }
    },
  }),
]

const projectedLiability = [
  createDecorator({
    field: ['projected.overrideTaxClass', 'projected.concludedLiabilityPerBasis'],
    updates: {
      'projected.taxClass': (value, allValues) => {
        const overrideTaxClass = get(allValues, 'projected.overrideTaxClass', false)
        const currentTaxClass = get(allValues, 'taxClass')
        const taxClass = get(allValues, 'projected.taxClass') || currentTaxClass
        return overrideTaxClass ? taxClass : null
      },
      'projected.taxLiability': (value, allValues) => {
        const squareFootage = get(allValues, 'squareFootage', 1)
        const residentialUnitCount = get(allValues, 'residentialUnitCount', 0)
        const basis = get(allValues, 'basis', BASIS_TYPES.PSF)
        const concludedLiabilityType = get(allValues, 'projected.concludedLiabilityType', basis)
        const concludedLiabilityPerBasis = get(allValues, 'projected.concludedLiabilityPerBasis', 0)
        const taxLiability = TaxCalculations.calculateLiabilityFromPerBasisValue(
          concludedLiabilityType,
          concludedLiabilityPerBasis,
          squareFootage,
          residentialUnitCount
        )
        return taxLiability
      },
    },
  }),
  createDecorator({
    field: ['projected.taxLiability', 'basis'],
    updates: {
      'projected.concludedLiabilityPerBasis': (value, allValues) => {
        const squareFootage = get(allValues, 'squareFootage', 1)
        const taxLiability = get(allValues, 'projected.taxLiability', 0)
        const residentialUnitCount = get(allValues, 'residentialUnitCount', 0)
        const basis = get(allValues, 'basis', BASIS_TYPES.PSF)
        const concludedLiabilityPerBasis = TaxCalculations.calculateTaxesPerBasis(
          basis,
          taxLiability,
          squareFootage,
          residentialUnitCount
        )

        return concludedLiabilityPerBasis
      },
    },
  }),
  createDecorator({
    field: [
      'taxRate',
      'projected.opinion.useCaliforniaTaxes',
      'projected.opinion.useAsStabilizedValueCA',
      'projected.opinion.additionalSpecialAssessments',
    ],
    updates: {
      'projected.opinion.taxableAssessedValue': (value, allValues) => {
        const {
          taxRate,
          projected,
          subjectInfo,
          valueConclusionType,
          effectiveGrossIncome,
          totalExpensesNetTaxes,
          concludedCapRate,
          saleValueConclusionInformation,
        } = allValues

        const useCaliforniaTaxes = get(projected, 'opinion.useCaliforniaTaxes', false)
        const includedInExport = get(projected, 'opinion.includedInExport', false)
        const useAsStabilizedValueCA = get(projected, 'opinion.useAsStabilizedValueCA', true)
        const isCATaxesSelected =
          includedInExport && canUseCaliforniaTaxProjections(subjectInfo?.state) && useCaliforniaTaxes

        const taxableAssessedValue = get(projected, 'opinion.taxableAssessedValue')
        if (!isCATaxesSelected) {
          return taxableAssessedValue
        }

        const additionalSpecialAssessments = get(projected, 'opinion.additionalSpecialAssessments', [])
        const asCompleteValueInformation = get(saleValueConclusionInformation, 'asCompleteValueInformation', {})
        const asStabilizedValueInformation = get(saleValueConclusionInformation, 'asStabilizedValueInformation', {})

        return calculateCAOpinionTaxableAssessedValue({
          effectiveGrossIncome,
          totalExpensesNetTaxes,
          additionalSpecialAssessments,
          taxRate: taxRate?.value,
          concludedCapRate,
          valueConclusionType,
          asStabilizedValueInformation,
          asCompleteValueInformation,
          useAsStabilizedValueCA,
        })
      },
    },
  }),
  createDecorator({
    field: ['projected.includedInExport'],
    updates: {
      'projected.overrideTaxClass': (value, allValues) => {
        const overrideTaxClass = get(allValues, 'projected.overrideTaxClass')
        if (!value) {
          return false
        }
        return overrideTaxClass
      },
    },
  }),
]

const equalizationApproach = [
  createDecorator({
    field: ['projected.equalization.marketValue', 'projected.equalization.equalizationRatio'],
    updates: {
      'projected.equalization.taxableAssessedValue': (value, allValues) => {
        const marketValue = get(allValues, 'projected.equalization.marketValue', 0)
        const equalizationRatio = get(allValues, 'projected.equalization.equalizationRatio', 0)

        return marketValue * equalizationRatio
      },
    },
  }),
  createDecorator({
    field: ['projected.includedInExport'],
    updates: {
      'projected.equalization.includedInExport': (value, allValues) => {
        const equalization = get(allValues, 'projected.equalization.includedInExport')
        if (!value) {
          return false
        }
        return equalization
      },
    },
  }),
]

const percentOfIncomeApproach = [
  createDecorator({
    field: [
      'projected.percentOfIncome.income',
      'projected.percentOfIncome.liabilityRatio',
      'taxRate',
      'projected.taxRate',
      'projected.percentOfIncome.additionalTaxRates',
      /projected\.percentOfIncome\.additionalTaxRates\[\d+\]\.value/,
    ],
    updates: {
      'projected.percentOfIncome.taxableAssessedValue': (value, allValues) => {
        const income = get(allValues, 'projected.percentOfIncome.income', 0)
        const liabilityRatio = get(allValues, 'projected.percentOfIncome.liabilityRatio', 0)
        const additionalTaxRates = get(allValues, 'projected.percentOfIncome.additionalTaxRates', [])
        const actualTaxRate = getActualTaxRate(allValues.taxRate, allValues.projected)
        const effectiveTaxRate = actualTaxRate.value + sumBy(additionalTaxRates, 'value')
        const taxLiability = TaxCalculations.calculateTaxLiabilityByLiabilityRatio(income, liabilityRatio)
        const taxableAssessedValue = TaxCalculations.calculateTaxableAssessedValue(taxLiability, effectiveTaxRate)
        return taxableAssessedValue
      },
    },
  }),
  createDecorator({
    field: ['projected.includedInExport'],
    updates: {
      'projected.percentOfIncome.includedInExport': (value, allValues) => {
        const percentOfIncome = get(allValues, 'projected.percentOfIncome.includedInExport')
        if (!value) {
          return false
        }
        return percentOfIncome
      },
    },
  }),
]

const percentOfRenovationsApproach = [
  createDecorator({
    field: ['projected.percentOfRenovations.assessmentRatio', 'projected.percentOfRenovations.netRenovationCost'],
    updates: {
      'projected.percentOfRenovations.taxableAssessedValue': (value, allValues) => {
        const { taxableAssessedValue } = allValues
        const assessmentRatio = get(allValues, 'projected.percentOfRenovations.assessmentRatio', 0.15)
        const netRenovationCost = get(allValues, 'projected.percentOfRenovations.netRenovationCost', 0)
        const increaseInTaxableAssessedValue = netRenovationCost * assessmentRatio
        return taxableAssessedValue + increaseInTaxableAssessedValue
      },
    },
  }),
  createDecorator({
    field: ['projected.includedInExport'],
    updates: {
      'projected.percentOfRenovations.includedInExport': (value, allValues) => {
        const percentOfRenovations = get(allValues, 'projected.percentOfRenovations.includedInExport')
        if (!value) {
          return false
        }
        return percentOfRenovations
      },
    },
  }),
]

const opinionApproach = [
  createDecorator({
    field: ['projected.includedInExport'],
    updates: {
      'projected.opinion.includedInExport': (value, allValues) => {
        const opinion = get(allValues, 'projected.opinion.includedInExport')
        if (!value) {
          return false
        }
        return opinion
      },
    },
  }),
]

const taxComps = [
  createDecorator({
    field: /taxComps\[\d+\]\.taxableAssessedValue/,
    updates: (value, name, allValues) => {
      const assessmentPerBasisName = name.replace('taxableAssessedValue', 'assessmentPerBasisValue')
      const previousAssessmentPerBasis = get(allValues, assessmentPerBasisName)
      const basisName = name.replace('taxableAssessedValue', 'basis')
      const basis = get(allValues, basisName, 0)
      const assessmentPerBasisValue = TaxCalculations.calculateTaxableAssessedValuePerBasis(value, basis)

      const taxComps = get(allValues, 'taxComps', [])

      if (taxComps.length < 1 || isNaN(assessmentPerBasisValue)) {
        return {}
      }

      return {
        [assessmentPerBasisName]: previousAssessmentPerBasis || assessmentPerBasisValue,
      }
    },
  }),

  createDecorator({
    field: /taxComps\[\d+\]\.basis/,
    updates: (value, name, allValues) => {
      const assessmentPerBasisName = name.replace('basis', 'assessmentPerBasisValue')
      const previousAssessmentPerBasis = get(allValues, assessmentPerBasisName)
      const taxableAssessedValueName = name.replace('basis', 'taxableAssessedValue')
      const taxableAssessedValue = get(allValues, taxableAssessedValueName, 0)
      const assessmentPerBasisValue = TaxCalculations.calculateTaxableAssessedValuePerBasis(taxableAssessedValue, value)

      const taxComps = get(allValues, 'taxComps', [])

      if (taxComps.length < 1 || isNaN(assessmentPerBasisValue)) {
        return {}
      }

      return {
        [assessmentPerBasisName]: previousAssessmentPerBasis || assessmentPerBasisValue,
      }
    },
  }),
]

export default [
  ...currentLiability,
  ...projectedLiability,
  ...equalizationApproach,
  ...percentOfIncomeApproach,
  ...percentOfRenovationsApproach,
  ...opinionApproach,
  ...taxComps,
]
