import { createAction } from 'redux-actions'

import { SEARCH_PROPERTIES, CLEAR_PROPERTIES, SEARCH_PROPERTIES_CLEAR } from '../actionTypes/properties'

export const searchProperties = createAction(SEARCH_PROPERTIES.ACTION)
export const searchPropertiesRequest = createAction(SEARCH_PROPERTIES.REQUEST)
export const searchPropertiesReceive = createAction(SEARCH_PROPERTIES.RECEIVE)
export const searchPropertiesFail = createAction(SEARCH_PROPERTIES.FAIL)

export const clearProperties = createAction(CLEAR_PROPERTIES.ACTION)

export const searchPropertiesClear = createAction(SEARCH_PROPERTIES_CLEAR.ACTION)
