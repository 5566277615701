import { withStyles } from '@mui/styles'

import NumberField from '../Number/Number'

export default withStyles({
  root: {
    '& fieldset': {
      display: 'none',
    },
    fontSize: '14px !important',
    display: 'inline-flex',
    alignItems: 'flex-end !important',
  },
  input: {
    padding: '0px !important',
    textAlign: 'end',
  },
})(NumberField)
