import { arrayToKeyValuePairs } from '../../../../shared/utils/ui/checkboxHelper'

export const ADDITIONAL_CAP_RATES_COMPS_TEXT =
  'We have supplemented our capitalization rate comparables with additional ' +
  'comparables not used in our sales comparison approach. ' +
  'The additional rates are good indicators for capitalization rates for the subject and are recent, however, ' +
  'there are differences regarding the comparables which do not justify utilizing the comparable in ' +
  'the sales comparison approach (i.e. location, size, etc.).'

export const CONDITIONS = arrayToKeyValuePairs(['Inferior', 'Similar', 'Superior'])

export const CAP_RATE_COMP_HEADERS = [
  { id: 'photo', padding: 'none', label: 'Photo', style: { width: 40 } },
  { id: 'type', padding: 'none', label: 'Type' },
  { id: 'distance', padding: 'none', label: 'Distance' },
  { id: 'address', padding: 'none', label: 'Address', style: { minWidth: 150 } },
  { id: 'status', padding: 'none', label: 'Status' },
  { id: 'saleDate', padding: 'none', label: 'Date Sold' },
  { id: 'yearBuilt', padding: 'none', label: 'Year Built' },
  { id: 'totalUnits', padding: 'none', label: 'Total Units' },
  { id: 'gba', padding: 'none', label: 'GBA (SF)' },
  { id: 'salePrice', padding: 'none', label: 'Sale Price' },
  { id: 'pricePerUnit', padding: 'none', label: '$/Unit' },
  { id: 'pricePerSquareFoot', padding: 'none', label: '$/SF' },
  { id: 'capRate', padding: 'none', label: 'Cap Rate' },
  { id: 'sourceOfInformation', padding: 'none', label: 'Source Of Information' },
]

export const REMOVED_COMP_HEADERS = CAP_RATE_COMP_HEADERS.filter(header => header.id !== 'sourceIcon')
