import React from 'react'

import { FormControl, FormHelperText, InputLabel, MenuItem, Select } from '@mui/material'

import { useMeta, useSx } from '../hooks'
import { border } from '../sXmodifiers'

import { useOptions } from './hooks'

const Default = ({ custom, fullWidth, helperTextSx, hint, input, label, meta, options, size, sx, ...props }) => {
  const sxModified = useSx({ custom, modifiers: { border }, sx })
  const normalizedOptions = useOptions(options)
  const { error, helperText } = useMeta({ hint, meta })

  return (
    <FormControl {...{ error, fullWidth, size }}>
      {label && <InputLabel>{label}</InputLabel>}
      <Select {...props} {...input} label={label} sx={sxModified}>
        {normalizedOptions.map(({ id, label, value }) => (
          <MenuItem key={id} value={value}>
            {label}
          </MenuItem>
        ))}
      </Select>
      {helperText === undefined ? null : <FormHelperText sx={helperTextSx}>{helperText}</FormHelperText>}
    </FormControl>
  )
}

export default Default
