import { get, isEmpty } from 'lodash'

import { formatList } from '../../../../../client/src/shared/utils/textHelpers'

import { SURROUNDING_USES } from '../../../../constants/report/property'

export const mapDTO = formValues => {
  const { surroundingUses } = formValues
  const uses = []

  Object.keys(surroundingUses).forEach(use => {
    if (surroundingUses[use]) {
      const useLabel = SURROUNDING_USES[use]
      uses.push(useLabel.toLowerCase())
    }
  })
  const surroundingUsesString = formatList(uses)

  return {
    surroundingUsesString,
  }
}

export const mapDataModel = report => {
  const { surroundingUses } = get(report, 'siteDescription', { surroundingUses: {} })
  const uses = []
  if (!isEmpty(surroundingUses)) {
    Object.keys(surroundingUses.toObject()).forEach(use => {
      if (surroundingUses[use]) {
        const useLabel = SURROUNDING_USES[use]
        uses.push(useLabel.toLowerCase())
      }
    })
  }
  const surroundingUsesString = formatList(uses)
  return {
    surroundingUsesString,
  }
}
