import React from 'react'
import PropTypes from 'prop-types'

import { get, includes, inRange } from 'lodash'

import BoweryDate from '@bowery-valuation/bowery-date'
import * as Socket from 'client-shared/utils/socket'
import { STATE_ABBREVIATIONS, STATE_NAMES_SHORT } from 'shared/constants/states'
import { FILES_ENDPOINT } from 'report/constants'
import { PROPERTY_MARKET_PATH } from 'shared/constants/report/keysAndDataPaths'

import * as Api from 'report/api'
import * as FileApi from 'core/api'
import wrapForm from 'report/forms/wrapForm'

import PropertyMarket from './PropertyMarket'

const DATA_PATH = PROPERTY_MARKET_PATH
const HEADING = 'Property Market'

const unavailableReports = [
  'areaAndEconomicAnalysis',
  'neighborhoodDescription',
  'multifamilyMarketAnalysis',
  'multifamilySubMarketAnalysis',
  'retailMarketAnalysis',
  'retailSubMarketAnalysis',
  'officeMarketAnalysis',
  'officeSubMarketAnalysis',
  'industrialMarketAnalysis',
  'industrialSubMarketAnalysis',
]

const marketAnalysesUseItems = [
  {
    name: 'multifamily',
    label: 'Multifamily',
  },
  {
    name: 'retail',
    label: 'Retail',
  },
  {
    name: 'office',
    label: 'Office',
  },
  {
    name: 'industrial',
    label: 'Industrial',
  },
]

const DROPBOX_ERROR_TEXT = 'Cannot retrieve file. Contact Research team.'

const validateYear = year => inRange(year, 999, 10000)
const validateState = state => Object.values(STATE_NAMES_SHORT).includes(state)
const validateQuarter = quarter => quarter && /^Q[1-4]$/.test(quarter)

class PropertyMarketContainer extends React.PureComponent {
  state = {
    areaItems: [],
    filesToUpload: 0,
    isLoading: false,
    marketItems: [],
    markets: {},
    neighborhoodItems: [],
    submarketItems: [],
    unavailableReports: [],
  }

  componentDidMount() {
    this.getNeighborhoodAndMarkets()
    Socket.on('uploadFile:success', this.onUploadFileSuccess)
  }

  componentWillUnmount() {
    Socket.off('uploadFile:success', this.onUploadFileSuccess)
  }

  onUploadFileSuccess = uploadedFile => {
    const { isLoading, filesToUpload } = this.state
    if (isLoading && filesToUpload) {
      const { form } = this.props
      form.change(uploadedFile.type, { name: uploadedFile.name, fileName: uploadedFile.name, id: uploadedFile._id })
      form.focus(uploadedFile.type)
      const filesLeftToUpload = filesToUpload - 1
      if (filesLeftToUpload > 0) {
        this.setState({ filesToUpload: filesLeftToUpload })
      } else {
        this.setState({ isLoading: false, filesToUpload: 0 })
      }
    }
  }

  getNeighborhoodAndMarkets = async () => {
    const { form } = this.props
    const { marketYear, marketQuarter, neighborhoodYear, marketState } = form.values
    if (
      [
        validateYear(marketYear),
        validateQuarter(marketQuarter),
        validateYear(neighborhoodYear),
        validateState(marketState),
      ].every(Boolean)
    ) {
      const { neighborhoods, markets, areas } = await Api.getMarketInformation({
        marketQuarter,
        marketState,
        marketYear,
        neighborhoodYear,
      })
      const { submarketItems, marketItems } = this.formatMarketsAndSubmarkets(markets)
      this.setState({
        areaItems: areas,
        marketItems,
        markets,
        neighborhoodItems: neighborhoods,
        submarketItems,
      })
    }
  }

  setSubmarketItems = macroMarket => {
    const { markets } = this.state
    const submarkets = markets[macroMarket]
    if (submarkets) {
      this.setState({ submarketItems: submarkets })
    }
  }

  getMarketReports = async () => {
    this.setState({ isLoading: true })
    const { form, authenticatedUser, reportId } = this.props
    const parentId = authenticatedUser.organizationId
    const {
      marketAnalysisUses = {},
      marketArea,
      marketQuarter,
      marketState,
      marketYear,
      marketNeighborhood,
      neighborhoodYear,
    } = form.values

    const uses = []
    for (const [key, value] of Object.entries(marketAnalysisUses)) {
      if (value) {
        uses.push(key)
      }
    }

    const markets = []
    uses.forEach(use => {
      markets.push({
        market_name: form.values[`${use}Market`],
        submarket_name: form.values[`${use}Submarket`] || '',
        use,
      })
    })

    try {
      const allMarketReports = await Api.getAllMarketReports({
        marketArea,
        marketQuarter,
        marketState,
        marketYear,
        markets,
        marketNeighborhood,
        neighborhoodYear,
      })

      let updatedUnavailableReport = unavailableReports
      let availableReports = false
      let filesToUpload = 0

      const reportsToUpload = []
      for (const report of allMarketReports) {
        if (report.available) {
          filesToUpload += 1
          availableReports = true
          const reportDocument = await Api.getMarketReport(report.id)
          const reportToUpload = {
            fileName: reportDocument.name,
            id: null,
            name: reportDocument.name,
            originalname: report.name,
            type: report.type,
            uploadFileInfo: reportDocument,
          }
          updatedUnavailableReport = updatedUnavailableReport.filter(type => type !== report.type)
          reportsToUpload.push(reportToUpload)
        } else if (!includes(updatedUnavailableReport, report.type)) {
          updatedUnavailableReport.push(report.type)
        }
      }

      updatedUnavailableReport.forEach(reportType => {
        form.change(reportType, null)
        form.focus(reportType)
      })
      this.setState(
        {
          reportToUpload: null,
          unavailableReports: updatedUnavailableReport,
          isLoading: availableReports,
          filesToUpload,
        },
        () => {
          // The FileApi upload function call is done in a callback here as we need the 'uploadFile:success' socket
          // message to be broadcast & received AFTER the 'filesToUpload' state variable has been set.
          reportsToUpload.forEach(report => {
            FileApi.uploadFile(report, report.type, FILES_ENDPOINT, parentId, reportId)
          })
        }
      )
    } catch (error) {
      this.setState({ isLoading: false })
      console.error(error)
    }
  }

  removeFile = fileName => {
    const { form } = this.props
    if (get(form.values, fileName)) {
      form.change(fileName, null)
    }
  }

  formatMarketsAndSubmarkets = (markets = {}) => {
    const submarketItems = []
    const marketItems = Object.keys(markets).map(market => {
      markets[market].forEach(submarket => {
        submarketItems.push(submarket)
      })
      return market
    })
    marketItems.sort()
    submarketItems.sort()

    return { submarketItems, marketItems }
  }

  getErrorText = fileName => {
    const { form } = this.props
    const { unavailableReports: unavailableReportsInner, isLoading } = this.state
    const file = get(form.values, fileName)
    if (includes(unavailableReportsInner, fileName) && !file && !isLoading) {
      return DROPBOX_ERROR_TEXT
    } else {
      return null
    }
  }

  render() {
    const { authenticatedUser, form } = this.props

    const {
      includeMarketingTime,
      marketAnalysisUses = {},
      marketNeighborhood,
      marketYear,
      neighborhoodYear,
      marketQuarter,
      marketState,
      marketArea,
    } = form.values

    const uses = []
    for (const [key, value] of Object.entries(marketAnalysisUses)) {
      if (value) {
        uses.push(key)
      }
    }
    const allUsesHaveInput = uses.every(use => {
      return form.values[`${use}Market`]
    })
    const canInsertFromDropbox =
      allUsesHaveInput &&
      marketNeighborhood &&
      neighborhoodYear &&
      marketYear &&
      marketQuarter &&
      marketState &&
      marketArea

    const usesForAnalysis = []
    marketAnalysesUseItems.forEach(use => {
      const { name } = use
      if (name && marketAnalysisUses[name]) {
        usesForAnalysis.push(use)
      }
    })
    const { neighborhoodItems = [], marketItems = [], areaItems = [], submarketItems = [], isLoading } = this.state
    const { getErrorText, getMarketReports, getNeighborhoodAndMarkets, removeFile } = this
    return (
      <PropertyMarket
        {...{
          areaItems,
          authenticatedUser,
          canInsertFromDropbox,
          form,
          getErrorText,
          getMarketReports,
          getNeighborhoodAndMarkets,
          includeMarketingTime,
          isLoading,
          marketAnalysesUseItems,
          marketAnalysisUses,
          marketItems,
          neighborhoodItems,
          removeFile,
          submarketItems,
          usesForAnalysis,
        }}
      />
    )
  }
}

const mapStateToProps = state => {
  const reportId = get(state, 'report.reportData._id')
  const reportType = get(state, 'report.reportData.propertyType')
  const authenticatedUser = get(state, 'authentication.user')
  const formData = get(state, 'report.reportData.propertyInformation.propertyMarket')
  const initialState = STATE_ABBREVIATIONS[get(state, 'report.reportData.state', '')]
  const dateOfValuationString = get(state, 'report.reportData.report.reportInformation.dateOfValuation')
  const dateOfValuation = new BoweryDate(dateOfValuationString)
  let initialYear = dateOfValuation.year
  const currentQuarter = dateOfValuation.quarter

  // needs to be previous quarter
  let initialQuarter = currentQuarter - 1
  if (initialQuarter === 0) {
    initialQuarter = 4
    initialYear -= 1
  }
  const initialNeighborhoodYear = new BoweryDate().year
  const initialValues = {
    ...formData,
    neighborhoodYear: formData.neighborhoodYear || initialNeighborhoodYear,
    marketState: formData.marketState || initialState,
    marketYear: formData.marketYear || initialYear,
    marketQuarter: formData.marketQuarter || `Q${initialQuarter}`,
  }

  return {
    initialValues,
    reportId,
    reportType,
    authenticatedUser,
  }
}

PropertyMarketContainer.propTypes = {
  authenticatedUser: PropTypes.object.isRequired,
  form: PropTypes.object.isRequired,
  reportType: PropTypes.string.isRequired,
}

export default wrapForm(DATA_PATH, { heading: HEADING }, mapStateToProps)(PropertyMarketContainer)
