import React from 'react'
import PropTypes from 'prop-types'

import { get } from 'lodash'
import { compose } from 'recompose'
import { connect } from 'react-redux'

import { withStyles } from '@material-ui/core'

import { EXPENSE_CATEGORIES } from '../../../../../../../../shared/constants/expenses'

import { formatCurrencyFloat } from '../../../../../../shared/utils/numberFormatters'
import { CalloutButton } from '../../../../../../shared/components'

import { DollarAmountReimbursementTable } from './DollarAmountReimbursementTable'
import UnitsTableForPercentageReimbursement from './UnitsTableForPercentageReimbursement'
import styles from './styles'

class PercentOfCurrentYearLiabilityReimbursementTable extends DollarAmountReimbursementTable {
  static propTypes = {
    classes: PropTypes.object.isRequired,
    reimbursement: PropTypes.object.isRequired,
    expenseCategories: PropTypes.arrayOf(PropTypes.object).isRequired,
    pathPrefix: PropTypes.string.isRequired,
    form: PropTypes.object.isRequired,
    onUpdate: PropTypes.func.isRequired,
    onDelete: PropTypes.func.isRequired,

    taxInfo: PropTypes.object.isRequired,
  }

  componentDidMount() {
    const { form, pathPrefix, taxInfo } = this.props
    get(form.values, `${pathPrefix}.unitReimbursements`, []).forEach((reimbursement, index) => {
      const unitReimbursementPath = `${pathPrefix}.unitReimbursements[${index}]`
      form.mutators.updatePercentOfCurrentYearLiability({ unitReimbursementPath, form, taxInfo })
    })
    form.reset(form.getState().values)
  }

  onFieldChange = (fieldName, unitReimbursementPath) => {
    const { form, taxInfo } = this.props
    form.mutators.updatePercentOfCurrentYearLiability({ unitReimbursementPath, form, taxInfo })
  }

  renderUnitReimbursementTable() {
    const { pathPrefix, reimbursement, classes } = this.props
    const hasAdminFee = get(reimbursement, 'knownInformation.adminFee')
    return (
      <UnitsTableForPercentageReimbursement
        pathPrefix={pathPrefix}
        reimbursement={reimbursement}
        hasAdminFee={hasAdminFee}
        baseValueFieldName="currentYearLiability"
        baseValueColumnTitle="Current Year Liability"
        baseValueColumnTooltip="These values come from Income > Tax Info"
        percentageFieldName="percentOfCurrentYearLiability"
        percentageColumnTitle="% of Current Year Liability"
        percentageCellClassName="percentOfCurrentYearLiabilityCell"
        onFieldChange={this.onFieldChange}
        getFieldValue={this.getFieldValue}
        classes={classes}
      >
        <CalloutButton
          link="./tax-information"
          linkText="Change"
          sx={{ my: 2 }}
          text="Input Current Year Liability on Income > Tax Info. "
          variant="link"
        />
      </UnitsTableForPercentageReimbursement>
    )
  }

  generateDiscussion = values => {
    const { reimbursement } = this.props

    const expenseType = (EXPENSE_CATEGORIES[reimbursement.expenseType] || reimbursement.expenseType).toLowerCase()
    const totalAnnual = formatCurrencyFloat(reimbursement.totalAnnual)

    return `According to our projections, the total ${expenseType} reimbursement is ${totalAnnual} per year.`
  }
}

const mapStateToProps = state => {
  const taxInfo = get(
    state,
    'report.reportData.incomeApproach.commercialIncome.commercialReimbursementSummary.taxInfo',
    {}
  )

  return {
    taxInfo,
  }
}

export default compose(withStyles(styles), connect(mapStateToProps))(PercentOfCurrentYearLiabilityReimbursementTable)
