import { arrayToKeyValuePairs } from 'shared/utils/formatters'

export const LandCompatibilityOptions = {
  gross: 'Gross',
  allowed: 'Allowed',
  approved: 'Approved',
  planned: 'Planned',
  usable: 'Usable',
}

export const ParagraphSiteAreaMeasureLabels = {
  sf: 'square feet',
  acre: 'acres',
}

export const NON_COMPLYING_STATUS = 'Non-complying'

export const LAND_COMPATIBILITY_OPTIONS_TYPES = Object.values(LandCompatibilityOptions)

export const PROPERTY_USES = {
  RESIDENTIAL: 'residential',
  COMMERCIAL: 'commercial',
  INDUSTRIAL: 'industrial',
  COMMUNITY_FACILITY: 'communityFacility',
}

export const PRE_EXISTING_YEAR = 1961

export const PROPERTY_USES_OPTIONS = arrayToKeyValuePairs([
  'Residential',
  'Commercial',
  'Community Facility',
  'Industrial',
])

export const USES = [
  'Residential',
  'Commercial',
  'Community Facility',
  'Industrial',
  'Single-family',
  'Two-family',
  'Multifamily',
  'Group living',
  'Personal care home',
  'Single-room residence',
  'Passive recreation',
  'Community center',
  'Family day care',
  'Group day care',
  'Day care center education facilities',
  'Fraternal organization',
  'Hospital',
  'Libraries and cultural exhibits',
  'Religious assembly',
  'Safety concerns',
  'Transit station',
  'Building or tower mounted antenna',
  'Business and professional office',
  'Sole or group medical',
  'Dental',
  'Health practitioner',
  'Government',
  'Building supplies and equipment retail',
  'Consumer goods',
  'Food',
  'Beverages and groceries',
  'Pet and pet supply stores',
  'Sundries',
  'Pharmaceuticals',
  'Convenience sales',
  'Wearing apparel and accessories',
  'Animal service commercial services',
  'Building services',
  'Prepared food shop',
  'Take-out restaurant',
  'Sit down restaurant',
  'Financial services',
  'Funeral and mortuary services',
  'Maintenance and repair of consumer goods',
  'Marina',
  'Structured parking',
  'Personal services',
  'Radio',
  'Television and recording services',
  'Visitor accommodations',
  'Commissaries and catering services',
  'Personal vehicle repair and maintenance',
  'Vehicle equipment and supplies sales and rental',
  'Artist studios and artisan industrial',
  'Research and development',
  'Community garden and market or community-supported farm',
]

export const USES_OPTIONS = arrayToKeyValuePairs(USES)

export const ZONE_NAMES = ['zoneOne', 'zoneTwo', 'zoneThree', 'zoneFour']
export const WaiverBasisValues = {
  LOT_SIZE: 'lotSize',
  PARKING_SPACE_NUMBER: 'parkingSpaceNumber',
  OWNER_RECEIVED: 'ownerReceived',
}

export const WAIVER_BASIS_OPTIONS = [
  { value: WaiverBasisValues.LOT_SIZE, label: 'Lot Size' },
  { value: WaiverBasisValues.PARKING_SPACE_NUMBER, label: 'Number of Parking Spaces' },
  { value: WaiverBasisValues.OWNER_RECEIVED, label: 'Owner received' },
]

export const DEFAULT_WAIVER_BASIS_SENTENCE = 'Parking requirements are waived for the subject, though.'

export const WaiverBasisCommentaryParts = {
  [WaiverBasisValues.LOT_SIZE]:
    'Parking requirements are waived for the subject, though, as its lot size is under the set threshold.',
  [WaiverBasisValues.PARKING_SPACE_NUMBER]:
    'Parking requirements are waived for the subject, though, as its total number of parking spaces is under the set threshold.',
  [WaiverBasisValues.OWNER_RECEIVED]:
    'Parking requirements are waived for the subject, though, as the property owner received a waiver regarding the number of required parking spaces.',
}
