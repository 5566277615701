import React from 'react'

import { get } from 'lodash'
import { Field } from 'react-final-form'

import Table from 'client-shared/components/Table/TableComponent'
import {
  COMMERCIAL_CONDITION_TYPES,
  COMMERCIAL_UNIT_TYPE,
  READY_FOR_OCCUPANCY_TYPES,
  RESIDENTIAL_CONDITION_TYPES,
} from 'shared/constants/report/property'
import { dropdownCellRenderer, readOnlyRenderer } from 'client-shared/components/TableHelpers/cellRenderers'

import { tableRowHeaderBase } from '../constants'

class InspectedUnitsTable extends React.PureComponent {
  getTableColumns = () => {
    const columns = [
      { data: 'number', value: 'Unit #', readOnly: true, renderer: readOnlyRenderer },
      { data: 'unitType', value: 'Unit Type', readOnly: true },
      { data: 'leaseStatus', value: 'Lease Status', readOnly: true },
      {
        allowInvalid: false,
        data: 'condition',
        strict: true,
        type: 'autocomplete',
        value: 'Condition',
        renderer: dropdownCellRenderer,
      },
      {
        allowInvalid: false,
        data: 'readyForOccupancy',
        source: READY_FOR_OCCUPANCY_TYPES,
        strict: true,
        type: 'autocomplete',
        value: 'Ready For Occupancy',
        renderer: dropdownCellRenderer,
      },
    ]
    return columns
  }

  getTableRowHeaders = units => {
    const rowHeaders = units.map((unit, index) => {
      return index + 1
    })
    return rowHeaders
  }

  beforeChange = (changes = []) => {
    const { form } = this.props
    form.change('units', [...form.values.units])
  }

  render() {
    const { form } = this.props
    const units = get(form, 'values.units', [])
    const columns = this.getTableColumns()
    const colHeaders = columns.map(column => column.value)
    const rowHeaders = this.getTableRowHeaders(units)

    const conditionColumnIndex = columns.findIndex(column => column.data === 'condition')
    const cellOverrides = units.map((unit, unitIndex) => {
      return {
        col: conditionColumnIndex,
        row: unitIndex,
        source: unit.unitType === COMMERCIAL_UNIT_TYPE ? COMMERCIAL_CONDITION_TYPES : RESIDENTIAL_CONDITION_TYPES,
      }
    })

    return (
      <>
        <Field name="units">{() => null}</Field>
        <Table
          data={units}
          columns={columns}
          cell={cellOverrides}
          colHeaders={colHeaders}
          stretchH="all"
          rowHeaders={rowHeaders}
          beforeChange={this.beforeChange}
          beforePaste={(data, coords) => {
            const numberOfUnits = units.length
            const { startRow } = coords[0]
            const addedRows = data.length
            const rowsToDelete = numberOfUnits - startRow - addedRows
            if (rowsToDelete > 0) {
              data.splice(rowsToDelete)
            }
          }}
          afterGetRowHeader={(row, TH) => {
            if (row === 0) {
              TH.style = `${tableRowHeaderBase}border-top:1px solid #d7deea;`
            } else {
              TH.style = `${tableRowHeaderBase}border-top:1px solid #f8fafc;`
            }
          }}
        />
      </>
    )
  }
}

export default InspectedUnitsTable
