import { groupBy, invert } from 'lodash'

export const STATE_NAMES_SHORT = {
  NY: 'NY',
  AL: 'AL',
  AK: 'AK',
  AZ: 'AZ',
  AR: 'AR',
  CA: 'CA',
  CO: 'CO',
  CT: 'CT',
  DE: 'DE',
  DC: 'DC',
  FL: 'FL',
  GA: 'GA',
  HI: 'HI',
  ID: 'ID',
  IL: 'IL',
  IN: 'IN',
  IA: 'IA',
  KS: 'KS',
  KY: 'KY',
  LA: 'LA',
  ME: 'ME',
  MD: 'MD',
  MA: 'MA',
  MI: 'MI',
  MN: 'MN',
  MS: 'MS',
  MO: 'MO',
  MT: 'MT',
  NE: 'NE',
  NV: 'NV',
  NH: 'NH',
  NJ: 'NJ',
  NM: 'NM',
  NC: 'NC',
  ND: 'ND',
  OH: 'OH',
  OK: 'OK',
  OR: 'OR',
  PA: 'PA',
  PR: 'PR',
  RI: 'RI',
  SC: 'SC',
  SD: 'SD',
  TN: 'TN',
  TX: 'TX',
  UT: 'UT',
  VT: 'VT',
  VA: 'VA',
  WA: 'WA',
  WV: 'WV',
  WI: 'WI',
  WY: 'WY',
}

export const STATE_NAMES = {
  NY: 'New York',
  AL: 'Alabama',
  AK: 'Alaska',
  AZ: 'Arizona',
  AR: 'Arkansas',
  CA: 'California',
  CO: 'Colorado',
  CT: 'Connecticut',
  DE: 'Delaware',
  DC: 'District Of Columbia',
  FL: 'Florida',
  GA: 'Georgia',
  HI: 'Hawaii',
  ID: 'Idaho',
  IL: 'Illinois',
  IN: 'Indiana',
  IA: 'Iowa',
  KS: 'Kansas',
  KY: 'Kentucky',
  LA: 'Louisiana',
  ME: 'Maine',
  MD: 'Maryland',
  MA: 'Massachusetts',
  MI: 'Michigan',
  MN: 'Minnesota',
  MS: 'Mississippi',
  MO: 'Missouri',
  MT: 'Montana',
  NE: 'Nebraska',
  NV: 'Nevada',
  NH: 'New Hampshire',
  NJ: 'New Jersey',
  NM: 'New Mexico',
  NC: 'North Carolina',
  ND: 'North Dakota',
  OH: 'Ohio',
  OK: 'Oklahoma',
  OR: 'Oregon',
  PA: 'Pennsylvania',
  PR: 'Puerto Rico',
  RI: 'Rhode Island',
  SC: 'South Carolina',
  SD: 'South Dakota',
  TN: 'Tennessee',
  TX: 'Texas',
  UT: 'Utah',
  VT: 'Vermont',
  VA: 'Virginia',
  WA: 'Washington',
  WV: 'West Virginia',
  WI: 'Wisconsin',
  WY: 'Wyoming',
}

export const STATE_ABBREVIATIONS = invert(STATE_NAMES)

export const STATE_OPTIONS = [
  { name: STATE_NAMES.NY, abbr: STATE_NAMES_SHORT.NY, id: 1 },
  { name: STATE_NAMES.AL, abbr: STATE_NAMES_SHORT.AL, id: 2 },
  { name: STATE_NAMES.AK, abbr: STATE_NAMES_SHORT.AK, id: 3 },
  { name: STATE_NAMES.AZ, abbr: STATE_NAMES_SHORT.AZ, id: 4 },
  { name: STATE_NAMES.AR, abbr: STATE_NAMES_SHORT.AR, id: 5 },
  { name: STATE_NAMES.CA, abbr: STATE_NAMES_SHORT.CA, id: 6 },
  { name: STATE_NAMES.CO, abbr: STATE_NAMES_SHORT.CO, id: 7 },
  { name: STATE_NAMES.CT, abbr: STATE_NAMES_SHORT.CT, id: 8 },
  { name: STATE_NAMES.DE, abbr: STATE_NAMES_SHORT.DE, id: 9 },
  { name: STATE_NAMES.DC, abbr: STATE_NAMES_SHORT.DC, id: 10 },
  { name: STATE_NAMES.FL, abbr: STATE_NAMES_SHORT.FL, id: 11 },
  { name: STATE_NAMES.GA, abbr: STATE_NAMES_SHORT.GA, id: 12 },
  { name: STATE_NAMES.HI, abbr: STATE_NAMES_SHORT.HI, id: 13 },
  { name: STATE_NAMES.ID, abbr: STATE_NAMES_SHORT.ID, id: 14 },
  { name: STATE_NAMES.IL, abbr: STATE_NAMES_SHORT.IL, id: 15 },
  { name: STATE_NAMES.IN, abbr: STATE_NAMES_SHORT.IN, id: 16 },
  { name: STATE_NAMES.IA, abbr: STATE_NAMES_SHORT.IA, id: 17 },
  { name: STATE_NAMES.KS, abbr: STATE_NAMES_SHORT.KS, id: 18 },
  { name: STATE_NAMES.KY, abbr: STATE_NAMES_SHORT.KY, id: 19 },
  { name: STATE_NAMES.LA, abbr: STATE_NAMES_SHORT.LA, id: 20 },
  { name: STATE_NAMES.ME, abbr: STATE_NAMES_SHORT.ME, id: 21 },
  { name: STATE_NAMES.MD, abbr: STATE_NAMES_SHORT.MD, id: 22 },
  { name: STATE_NAMES.MA, abbr: STATE_NAMES_SHORT.MA, id: 23 },
  { name: STATE_NAMES.MI, abbr: STATE_NAMES_SHORT.MI, id: 24 },
  { name: STATE_NAMES.MN, abbr: STATE_NAMES_SHORT.MN, id: 25 },
  { name: STATE_NAMES.MS, abbr: STATE_NAMES_SHORT.MS, id: 26 },
  { name: STATE_NAMES.MO, abbr: STATE_NAMES_SHORT.MO, id: 27 },
  { name: STATE_NAMES.MT, abbr: STATE_NAMES_SHORT.MT, id: 28 },
  { name: STATE_NAMES.NE, abbr: STATE_NAMES_SHORT.NE, id: 29 },
  { name: STATE_NAMES.NV, abbr: STATE_NAMES_SHORT.NV, id: 30 },
  { name: STATE_NAMES.NH, abbr: STATE_NAMES_SHORT.NH, id: 31 },
  { name: STATE_NAMES.NJ, abbr: STATE_NAMES_SHORT.NJ, id: 32 },
  { name: STATE_NAMES.NM, abbr: STATE_NAMES_SHORT.NM, id: 33 },
  { name: STATE_NAMES.NC, abbr: STATE_NAMES_SHORT.NC, id: 34 },
  { name: STATE_NAMES.ND, abbr: STATE_NAMES_SHORT.ND, id: 35 },
  { name: STATE_NAMES.OH, abbr: STATE_NAMES_SHORT.OH, id: 36 },
  { name: STATE_NAMES.OK, abbr: STATE_NAMES_SHORT.OK, id: 37 },
  { name: STATE_NAMES.OR, abbr: STATE_NAMES_SHORT.OR, id: 38 },
  { name: STATE_NAMES.PA, abbr: STATE_NAMES_SHORT.PA, id: 39 },
  { name: STATE_NAMES.PR, abbr: STATE_NAMES_SHORT.PR, id: 40 },
  { name: STATE_NAMES.RI, abbr: STATE_NAMES_SHORT.RI, id: 41 },
  { name: STATE_NAMES.SC, abbr: STATE_NAMES_SHORT.SC, id: 42 },
  { name: STATE_NAMES.SD, abbr: STATE_NAMES_SHORT.SD, id: 43 },
  { name: STATE_NAMES.TN, abbr: STATE_NAMES_SHORT.TN, id: 44 },
  { name: STATE_NAMES.TX, abbr: STATE_NAMES_SHORT.TX, id: 45 },
  { name: STATE_NAMES.UT, abbr: STATE_NAMES_SHORT.UT, id: 46 },
  { name: STATE_NAMES.VT, abbr: STATE_NAMES_SHORT.VT, id: 47 },
  { name: STATE_NAMES.VA, abbr: STATE_NAMES_SHORT.VA, id: 48 },
  { name: STATE_NAMES.WA, abbr: STATE_NAMES_SHORT.WA, id: 49 },
  { name: STATE_NAMES.WI, abbr: STATE_NAMES_SHORT.WI, id: 50 },
  { name: STATE_NAMES.WV, abbr: STATE_NAMES_SHORT.WV, id: 51 },
  { name: STATE_NAMES.WY, abbr: STATE_NAMES_SHORT.WY, id: 52 },
]

export const STATES_LIST = STATE_OPTIONS.map(option => {
  return {
    label: option.name,
    value: option.name,
  }
})

export const STATES_LIST_SHORT = STATE_OPTIONS.map(option => {
  return {
    label: option.abbr,
    value: option.name,
  }
})

export const LOCALES_OPTIONS = [
  { name: 'Anniston', state: STATE_NAMES.AL },
  { name: 'Auburn', state: STATE_NAMES.AL },
  { name: 'Bessemer', state: STATE_NAMES.AL },
  { name: 'Birmingham', state: STATE_NAMES.AL },
  { name: 'Dothan', state: STATE_NAMES.AL },
  { name: 'Florence', state: STATE_NAMES.AL },
  { name: 'Gadsden', state: STATE_NAMES.AL },
  { name: 'Huntsville', state: STATE_NAMES.AL },
  { name: 'Mobile', state: STATE_NAMES.AL },
  { name: 'Montgomery', state: STATE_NAMES.AL },
  { name: 'Opelika', state: STATE_NAMES.AL },
  { name: 'Phenix City', state: STATE_NAMES.AL },
  { name: 'Sheffield', state: STATE_NAMES.AL },
  { name: 'Tuscaloosa', state: STATE_NAMES.AL },
  { name: 'Anchorage', state: STATE_NAMES.AK },
  { name: 'Fairbanks', state: STATE_NAMES.AK },
  { name: 'Juneau', state: STATE_NAMES.AK },
  { name: 'Kenai Peninsula', state: STATE_NAMES.AK },
  { name: 'Ketchikan', state: STATE_NAMES.AK },
  { name: 'Kodiak', state: STATE_NAMES.AK },
  { name: 'Mat-Su Valley', state: STATE_NAMES.AK },
  { name: 'Sitka', state: STATE_NAMES.AK },
  { name: 'Apache County', state: STATE_NAMES.AZ },
  { name: 'Bullhead City', state: STATE_NAMES.AZ },
  { name: 'Casa Grande', state: STATE_NAMES.AZ },
  { name: 'Cochise County', state: STATE_NAMES.AZ },
  { name: 'Coconino County', state: STATE_NAMES.AZ },
  { name: 'Douglas', state: STATE_NAMES.AZ },
  { name: 'Flagstaff', state: STATE_NAMES.AZ },
  { name: 'Gila County', state: STATE_NAMES.AZ },
  { name: 'Graham County', state: STATE_NAMES.AZ },
  { name: 'Greenlee County', state: STATE_NAMES.AZ },
  { name: 'Kingman', state: STATE_NAMES.AZ },
  { name: 'La Paz County', state: STATE_NAMES.AZ },
  { name: 'Lake Havasu', state: STATE_NAMES.AZ },
  { name: 'Maricopa County', state: STATE_NAMES.AZ },
  { name: 'Mohave County', state: STATE_NAMES.AZ },
  { name: 'Navajo County', state: STATE_NAMES.AZ },
  { name: 'Nogales', state: STATE_NAMES.AZ },
  { name: 'Phoenix', state: STATE_NAMES.AZ },
  { name: 'Pima County', state: STATE_NAMES.AZ },
  { name: 'Pinal County', state: STATE_NAMES.AZ },
  { name: 'Prescott', state: STATE_NAMES.AZ },
  { name: 'Santa Cruz County', state: STATE_NAMES.AZ },
  { name: 'Sedona', state: STATE_NAMES.AZ },
  { name: 'Tucson', state: STATE_NAMES.AZ },
  { name: 'Yavapai County', state: STATE_NAMES.AZ },
  { name: 'Yuma', state: STATE_NAMES.AZ },
  { name: 'Yuma County', state: STATE_NAMES.AZ },
  { name: 'Blytheville', state: STATE_NAMES.AR },
  { name: 'Fayetteville', state: STATE_NAMES.AR },
  { name: 'Fort Smith', state: STATE_NAMES.AR },
  { name: 'Hot Springs', state: STATE_NAMES.AR },
  { name: 'Jonesboro', state: STATE_NAMES.AR },
  { name: 'Little Rock', state: STATE_NAMES.AR },
  { name: 'Texarkana', state: STATE_NAMES.AR },
  { name: 'West Memphis', state: STATE_NAMES.AR },
  { name: 'Alameda County', state: STATE_NAMES.CA },
  { name: 'Alpine County', state: STATE_NAMES.CA },
  { name: 'Amador County', state: STATE_NAMES.CA },
  { name: 'Antelope Valley', state: STATE_NAMES.CA },
  { name: 'Atascadero', state: STATE_NAMES.CA },
  { name: 'Bakersfield', state: STATE_NAMES.CA },
  { name: 'Barstow', state: STATE_NAMES.CA },
  { name: 'Big Bear', state: STATE_NAMES.CA },
  { name: 'Bishop', state: STATE_NAMES.CA },
  { name: 'Blythe', state: STATE_NAMES.CA },
  { name: 'Butte County', state: STATE_NAMES.CA },
  { name: 'Calaveras County', state: STATE_NAMES.CA },
  { name: 'Coalinga', state: STATE_NAMES.CA },
  { name: 'Colusa County', state: STATE_NAMES.CA },
  { name: 'Contra Costa County', state: STATE_NAMES.CA },
  { name: 'Del Norte County', state: STATE_NAMES.CA },
  { name: 'El Dorado County', state: STATE_NAMES.CA },
  { name: 'Eureka', state: STATE_NAMES.CA },
  { name: 'Fresno', state: STATE_NAMES.CA },
  { name: 'Gilroy', state: STATE_NAMES.CA },
  { name: 'Glenn County', state: STATE_NAMES.CA },
  { name: 'Goleta', state: STATE_NAMES.CA },
  { name: 'Hanford', state: STATE_NAMES.CA },
  { name: 'Hesperia', state: STATE_NAMES.CA },
  { name: 'Huntington Beach', state: STATE_NAMES.CA },
  { name: 'Imperial County', state: STATE_NAMES.CA },
  { name: 'Indio', state: STATE_NAMES.CA },
  { name: 'Laguna Beach', state: STATE_NAMES.CA },
  { name: 'Lake Arrowhead', state: STATE_NAMES.CA },
  { name: 'Lake County', state: STATE_NAMES.CA },
  { name: 'Lake Tahoe', state: STATE_NAMES.CA },
  { name: 'Lompoc', state: STATE_NAMES.CA },
  { name: 'Los Angeles', state: STATE_NAMES.CA },
  { name: 'Madera', state: STATE_NAMES.CA },
  { name: 'Mammoth Lakes', state: STATE_NAMES.CA },
  { name: 'Marin County', state: STATE_NAMES.CA },
  { name: 'Mariposa County', state: STATE_NAMES.CA },
  { name: 'Marysville', state: STATE_NAMES.CA },
  { name: 'Mendocino County', state: STATE_NAMES.CA },
  { name: 'Merced', state: STATE_NAMES.CA },
  { name: 'Modesto', state: STATE_NAMES.CA },
  { name: 'Modoc County', state: STATE_NAMES.CA },
  { name: 'Mono County', state: STATE_NAMES.CA },
  { name: 'Monterey', state: STATE_NAMES.CA },
  { name: 'Napa County', state: STATE_NAMES.CA },
  { name: 'Nevada County', state: STATE_NAMES.CA },
  { name: 'Newport Beach', state: STATE_NAMES.CA },
  { name: 'Orange Co. (x/beaches)', state: STATE_NAMES.CA },
  { name: 'Oxnard', state: STATE_NAMES.CA },
  { name: 'Palm Springs', state: STATE_NAMES.CA },
  { name: 'Paso Robles', state: STATE_NAMES.CA },
  { name: 'Placer County', state: STATE_NAMES.CA },
  { name: 'Plumas County', state: STATE_NAMES.CA },
  { name: 'Redding', state: STATE_NAMES.CA },
  { name: 'Riverside', state: STATE_NAMES.CA },
  { name: 'Sacramento', state: STATE_NAMES.CA },
  { name: 'Salinas', state: STATE_NAMES.CA },
  { name: 'San Benito County', state: STATE_NAMES.CA },
  { name: 'San Bernardino', state: STATE_NAMES.CA },
  { name: 'San Clemente', state: STATE_NAMES.CA },
  { name: 'San Diego', state: STATE_NAMES.CA },
  { name: 'San Francisco', state: STATE_NAMES.CA },
  { name: 'San Jose', state: STATE_NAMES.CA },
  { name: 'San Luis Obispo', state: STATE_NAMES.CA },
  { name: 'San Mateo County', state: STATE_NAMES.CA },
  { name: 'Santa Barbara', state: STATE_NAMES.CA },
  { name: 'Santa Clara County', state: STATE_NAMES.CA },
  { name: 'Santa Cruz County', state: STATE_NAMES.CA },
  { name: 'Santa Maria', state: STATE_NAMES.CA },
  { name: 'Santa Rosa', state: STATE_NAMES.CA },
  { name: 'Sierra County', state: STATE_NAMES.CA },
  { name: 'Siskiyou County', state: STATE_NAMES.CA },
  { name: 'Solano County', state: STATE_NAMES.CA },
  { name: 'Stockton', state: STATE_NAMES.CA },
  { name: 'Susanville', state: STATE_NAMES.CA },
  { name: 'Tehama County', state: STATE_NAMES.CA },
  { name: 'Trinity County', state: STATE_NAMES.CA },
  { name: 'Tulare County', state: STATE_NAMES.CA },
  { name: 'Tuolumne County', state: STATE_NAMES.CA },
  { name: 'Ventura County', state: STATE_NAMES.CA },
  { name: 'Victorville', state: STATE_NAMES.CA },
  { name: 'Watsonville', state: STATE_NAMES.CA },
  { name: 'Yolo County', state: STATE_NAMES.CA },
  { name: 'Yuba City', state: STATE_NAMES.CA },
  { name: 'Aspen', state: STATE_NAMES.CO },
  { name: 'Boulder', state: STATE_NAMES.CO },
  { name: 'Colorado Springs', state: STATE_NAMES.CO },
  { name: 'Costilla County', state: STATE_NAMES.CO },
  { name: 'Denver', state: STATE_NAMES.CO },
  { name: 'Durango', state: STATE_NAMES.CO },
  { name: 'Eagle Co. (x/resort areas)', state: STATE_NAMES.CO },
  { name: 'Fort Collins', state: STATE_NAMES.CO },
  { name: 'Grand Junction', state: STATE_NAMES.CO },
  { name: 'Greeley', state: STATE_NAMES.CO },
  { name: 'Gunnison County', state: STATE_NAMES.CO },
  { name: 'Kit Carson County', state: STATE_NAMES.CO },
  { name: 'Logan County', state: STATE_NAMES.CO },
  { name: 'Longmont', state: STATE_NAMES.CO },
  { name: 'Loveland', state: STATE_NAMES.CO },
  { name: 'Moffat County', state: STATE_NAMES.CO },
  { name: 'Montrose County', state: STATE_NAMES.CO },
  { name: 'Prowers County', state: STATE_NAMES.CO },
  { name: 'Pueblo', state: STATE_NAMES.CO },
  { name: 'Steamboat Springs', state: STATE_NAMES.CO },
  { name: 'Vail', state: STATE_NAMES.CO },
  { name: 'Bridgeport', state: STATE_NAMES.CT },
  { name: 'Bristol', state: STATE_NAMES.CT },
  { name: 'Danbury', state: STATE_NAMES.CT },
  { name: 'Fairfield', state: STATE_NAMES.CT },
  { name: 'Greenwich', state: STATE_NAMES.CT },
  { name: 'Hartford', state: STATE_NAMES.CT },
  { name: 'Meriden', state: STATE_NAMES.CT },
  { name: 'Middletown', state: STATE_NAMES.CT },
  { name: 'Milford', state: STATE_NAMES.CT },
  { name: 'New Britain', state: STATE_NAMES.CT },
  { name: 'New Haven', state: STATE_NAMES.CT },
  { name: 'New London', state: STATE_NAMES.CT },
  { name: 'Norwich', state: STATE_NAMES.CT },
  { name: 'Stamford', state: STATE_NAMES.CT },
  { name: 'Waterbury', state: STATE_NAMES.CT },
  { name: 'Windsor Locks', state: STATE_NAMES.CT },
  { name: 'Dover', state: STATE_NAMES.DE },
  { name: 'Wilmington', state: STATE_NAMES.DE },
  { name: 'DIST. OF COLUMBIA', state: STATE_NAMES.DE },
  { name: 'Bradenton', state: STATE_NAMES.FL },
  { name: 'Brevard County', state: STATE_NAMES.FL },
  { name: 'Broward County', state: STATE_NAMES.FL },
  { name: 'Dade County', state: STATE_NAMES.FL },
  { name: 'Daytona Beach', state: STATE_NAMES.FL },
  { name: 'Fort Myers', state: STATE_NAMES.FL },
  { name: 'Fort Pierce', state: STATE_NAMES.FL },
  { name: 'Gainesville', state: STATE_NAMES.FL },
  { name: 'Jacksonville', state: STATE_NAMES.FL },
  { name: 'Key West', state: STATE_NAMES.FL },
  { name: 'Lakeland', state: STATE_NAMES.FL },
  { name: 'Marathon', state: STATE_NAMES.FL },
  { name: 'Miami', state: STATE_NAMES.FL },
  { name: 'Naples', state: STATE_NAMES.FL },
  { name: 'Ocala', state: STATE_NAMES.FL },
  { name: 'Orlando', state: STATE_NAMES.FL },
  { name: 'Palm Beach', state: STATE_NAMES.FL },
  { name: 'Panama City', state: STATE_NAMES.FL },
  { name: 'Pensacola', state: STATE_NAMES.FL },
  { name: 'Pinellas County', state: STATE_NAMES.FL },
  { name: 'Sarasota', state: STATE_NAMES.FL },
  { name: 'Tallahassee', state: STATE_NAMES.FL },
  { name: 'Tampa', state: STATE_NAMES.FL },
  { name: 'Vero Beach', state: STATE_NAMES.FL },
  { name: 'Albany', state: STATE_NAMES.GA },
  { name: 'Athens', state: STATE_NAMES.GA },
  { name: 'Atlanta', state: STATE_NAMES.GA },
  { name: 'Augusta', state: STATE_NAMES.GA },
  { name: 'Columbus', state: STATE_NAMES.GA },
  { name: 'Macon', state: STATE_NAMES.GA },
  { name: 'Rome', state: STATE_NAMES.GA },
  { name: 'Savannah', state: STATE_NAMES.GA },
  { name: 'Valdosta', state: STATE_NAMES.GA },
  { name: 'Hilo', state: STATE_NAMES.HI },
  { name: 'Kauai', state: STATE_NAMES.HI },
  { name: 'Maui', state: STATE_NAMES.HI },
  { name: 'Oahu', state: STATE_NAMES.HI },
  { name: 'Boise', state: STATE_NAMES.ID },
  { name: 'Caldwell', state: STATE_NAMES.ID },
  { name: 'Coeur d’ Alene', state: STATE_NAMES.ID },
  { name: 'Idaho Falls', state: STATE_NAMES.ID },
  { name: 'Lewiston', state: STATE_NAMES.ID },
  { name: 'Moscow', state: STATE_NAMES.ID },
  { name: 'Pocatello', state: STATE_NAMES.ID },
  { name: 'Twin Falls', state: STATE_NAMES.ID },
  { name: 'Alton', state: STATE_NAMES.IL },
  { name: 'Aurora', state: STATE_NAMES.IL },
  { name: 'Belleville', state: STATE_NAMES.IL },
  { name: 'Bloomington', state: STATE_NAMES.IL },
  { name: 'Carbondale', state: STATE_NAMES.IL },
  { name: 'Centralia', state: STATE_NAMES.IL },
  { name: 'Champaign', state: STATE_NAMES.IL },
  { name: 'Chicago', state: STATE_NAMES.IL },
  { name: 'Danville', state: STATE_NAMES.IL },
  { name: 'De Kalb', state: STATE_NAMES.IL },
  { name: 'Decatur', state: STATE_NAMES.IL },
  { name: 'East St. Louis', state: STATE_NAMES.IL },
  { name: 'Elgin', state: STATE_NAMES.IL },
  { name: 'Evanston', state: STATE_NAMES.IL },
  { name: 'Galesburg', state: STATE_NAMES.IL },
  { name: 'Joliet', state: STATE_NAMES.IL },
  { name: 'Kankakee', state: STATE_NAMES.IL },
  { name: 'Marion', state: STATE_NAMES.IL },
  { name: 'Moline', state: STATE_NAMES.IL },
  { name: 'Normal', state: STATE_NAMES.IL },
  { name: 'Peoria', state: STATE_NAMES.IL },
  { name: 'Quincy', state: STATE_NAMES.IL },
  { name: 'Rock Island', state: STATE_NAMES.IL },
  { name: 'Rockford', state: STATE_NAMES.IL },
  { name: 'Skokie', state: STATE_NAMES.IL },
  { name: 'Springfield', state: STATE_NAMES.IL },
  { name: 'Urbana', state: STATE_NAMES.IL },
  { name: 'Waukegan', state: STATE_NAMES.IL },
  { name: 'Anderson', state: STATE_NAMES.IN },
  { name: 'Bloomington', state: STATE_NAMES.IN },
  { name: 'Columbus', state: STATE_NAMES.IN },
  { name: 'Elkhart', state: STATE_NAMES.IN },
  { name: 'Evansville', state: STATE_NAMES.IN },
  { name: 'Fort Wayne', state: STATE_NAMES.IN },
  { name: 'Gary', state: STATE_NAMES.IN },
  { name: 'Hammond', state: STATE_NAMES.IN },
  { name: 'Indianapolis', state: STATE_NAMES.IN },
  { name: 'Kokomo', state: STATE_NAMES.IN },
  { name: 'Lafayette', state: STATE_NAMES.IN },
  { name: 'Logansport', state: STATE_NAMES.IN },
  { name: 'Marion', state: STATE_NAMES.IN },
  { name: 'Michigan City', state: STATE_NAMES.IN },
  { name: 'Muncie', state: STATE_NAMES.IN },
  { name: 'Richmond', state: STATE_NAMES.IN },
  { name: 'South Bend', state: STATE_NAMES.IN },
  { name: 'Terre Haute', state: STATE_NAMES.IN },
  { name: 'Burlington', state: STATE_NAMES.IA },
  { name: 'Cedar Rapids', state: STATE_NAMES.IA },
  { name: 'Council Bluffs', state: STATE_NAMES.IA },
  { name: 'Davenport', state: STATE_NAMES.IA },
  { name: 'Des Moines', state: STATE_NAMES.IA },
  { name: 'Dubuque', state: STATE_NAMES.IA },
  { name: 'Fort Dodge', state: STATE_NAMES.IA },
  { name: 'Iowa City', state: STATE_NAMES.IA },
  { name: 'Mason City', state: STATE_NAMES.IA },
  { name: 'Sioux City', state: STATE_NAMES.IA },
  { name: 'Waterloo', state: STATE_NAMES.IA },
  { name: 'Dodge City', state: STATE_NAMES.KS },
  { name: 'Fort Scott', state: STATE_NAMES.KS },
  { name: 'Garden City', state: STATE_NAMES.KS },
  { name: 'Goodland', state: STATE_NAMES.KS },
  { name: 'Hays', state: STATE_NAMES.KS },
  { name: 'Kansas City', state: STATE_NAMES.KS },
  { name: 'Lawrence', state: STATE_NAMES.KS },
  { name: 'Liberal', state: STATE_NAMES.KS },
  { name: 'Manhattan', state: STATE_NAMES.KS },
  { name: 'Olathe', state: STATE_NAMES.KS },
  { name: 'Overland Park', state: STATE_NAMES.KS },
  { name: 'Pittsburg', state: STATE_NAMES.KS },
  { name: 'Salina', state: STATE_NAMES.KS },
  { name: 'Topeka', state: STATE_NAMES.KS },
  { name: 'Wichita', state: STATE_NAMES.KS },
  { name: 'Ashland', state: STATE_NAMES.KY },
  { name: 'Bowling Green', state: STATE_NAMES.KY },
  { name: 'Covington', state: STATE_NAMES.KY },
  { name: 'Frankfort', state: STATE_NAMES.KY },
  { name: 'Lexington', state: STATE_NAMES.KY },
  { name: 'Louisville', state: STATE_NAMES.KY },
  { name: 'Newport', state: STATE_NAMES.KY },
  { name: 'Owensboro', state: STATE_NAMES.KY },
  { name: 'Paducah', state: STATE_NAMES.KY },
  { name: 'Alexandria', state: STATE_NAMES.LA },
  { name: 'Baton Rouge', state: STATE_NAMES.LA },
  { name: 'Lafayette', state: STATE_NAMES.LA },
  { name: 'Lake Charles', state: STATE_NAMES.LA },
  { name: 'Monroe', state: STATE_NAMES.LA },
  { name: 'New Orleans', state: STATE_NAMES.LA },
  { name: 'Shreveport', state: STATE_NAMES.LA },
  { name: 'Auburn', state: STATE_NAMES.ME },
  { name: 'Augusta', state: STATE_NAMES.ME },
  { name: 'Bangor', state: STATE_NAMES.ME },
  { name: 'Biddeford', state: STATE_NAMES.ME },
  { name: 'Caribou', state: STATE_NAMES.ME },
  { name: 'Lewiston', state: STATE_NAMES.ME },
  { name: 'Portland', state: STATE_NAMES.ME },
  { name: 'Presque Isle', state: STATE_NAMES.ME },
  { name: 'Waterville', state: STATE_NAMES.ME },
  { name: 'Anne Arundel County', state: STATE_NAMES.MD },
  { name: 'Baltimore', state: STATE_NAMES.MD },
  { name: 'Bethesda', state: STATE_NAMES.MD },
  { name: 'Cumberland', state: STATE_NAMES.MD },
  { name: 'Eastern Shore Area', state: STATE_NAMES.MD },
  { name: 'Hagerstown', state: STATE_NAMES.MD },
  { name: 'Silver Spring', state: STATE_NAMES.MD },
  { name: 'Boston', state: STATE_NAMES.MA },
  { name: 'Cape Cod', state: STATE_NAMES.MA },
  { name: 'Fall River', state: STATE_NAMES.MA },
  { name: 'Holyoke', state: STATE_NAMES.MA },
  { name: 'Lawrence', state: STATE_NAMES.MA },
  { name: 'Lowell', state: STATE_NAMES.MA },
  { name: 'Lynn', state: STATE_NAMES.MA },
  { name: 'Methuen', state: STATE_NAMES.MA },
  { name: 'Natick', state: STATE_NAMES.MA },
  { name: 'New Bedford', state: STATE_NAMES.MA },
  { name: 'Pittsfield', state: STATE_NAMES.MA },
  { name: 'Springfield', state: STATE_NAMES.MA },
  { name: 'Worcester', state: STATE_NAMES.MA },
  { name: 'Adrian', state: STATE_NAMES.MI },
  { name: 'Alpena', state: STATE_NAMES.MI },
  { name: 'Ann Arbor', state: STATE_NAMES.MI },
  { name: 'Battle Creek', state: STATE_NAMES.MI },
  { name: 'Bay City', state: STATE_NAMES.MI },
  { name: 'Detroit', state: STATE_NAMES.MI },
  { name: 'Escanaba', state: STATE_NAMES.MI },
  { name: 'Flint', state: STATE_NAMES.MI },
  { name: 'Grand Rapids', state: STATE_NAMES.MI },
  { name: 'Ishpeming', state: STATE_NAMES.MI },
  { name: 'Jackson', state: STATE_NAMES.MI },
  { name: 'Kalamazoo', state: STATE_NAMES.MI },
  { name: 'Lansing', state: STATE_NAMES.MI },
  { name: 'Marquette', state: STATE_NAMES.MI },
  { name: 'Monroe', state: STATE_NAMES.MI },
  { name: 'Muskegon', state: STATE_NAMES.MI },
  { name: 'Niles', state: STATE_NAMES.MI },
  { name: 'Pontiac', state: STATE_NAMES.MI },
  { name: 'Port Huron', state: STATE_NAMES.MI },
  { name: 'Saginaw', state: STATE_NAMES.MI },
  { name: 'Sault Ste. Marie', state: STATE_NAMES.MI },
  { name: 'Traverse City', state: STATE_NAMES.MI },
  { name: 'Ypsilanti', state: STATE_NAMES.MI },
  { name: 'Austin', state: STATE_NAMES.MN },
  { name: 'Brainerd', state: STATE_NAMES.MN },
  { name: 'Duluth', state: STATE_NAMES.MN },
  { name: 'Hibbing', state: STATE_NAMES.MN },
  { name: 'Mankato', state: STATE_NAMES.MN },
  { name: 'Minneapolis', state: STATE_NAMES.MN },
  { name: 'Moorhead', state: STATE_NAMES.MN },
  { name: 'Rochester', state: STATE_NAMES.MN },
  { name: 'St. Cloud', state: STATE_NAMES.MN },
  { name: 'St. Paul', state: STATE_NAMES.MN },
  { name: 'Biloxi', state: STATE_NAMES.MS },
  { name: 'Columbus', state: STATE_NAMES.MS },
  { name: 'Greenville', state: STATE_NAMES.MS },
  { name: 'Gulfport', state: STATE_NAMES.MS },
  { name: 'Hattiesburg', state: STATE_NAMES.MS },
  { name: 'Jackson', state: STATE_NAMES.MS },
  { name: 'Laurel', state: STATE_NAMES.MS },
  { name: 'Meridian', state: STATE_NAMES.MS },
  { name: 'Natchez', state: STATE_NAMES.MS },
  { name: 'Tupelo', state: STATE_NAMES.MS },
  { name: 'Vicksburg', state: STATE_NAMES.MS },
  { name: 'Cape Girardeau', state: STATE_NAMES.MO },
  { name: 'Columbia', state: STATE_NAMES.MO },
  { name: 'Independence', state: STATE_NAMES.MO },
  { name: 'Jefferson City', state: STATE_NAMES.MO },
  { name: 'Joplin', state: STATE_NAMES.MO },
  { name: 'Kansas City', state: STATE_NAMES.MO },
  { name: 'Rolla', state: STATE_NAMES.MO },
  { name: 'Springfield', state: STATE_NAMES.MO },
  { name: 'St. Joseph', state: STATE_NAMES.MO },
  { name: 'St. Louis', state: STATE_NAMES.MO },
  { name: 'Billings', state: STATE_NAMES.MT },
  { name: 'Bozeman', state: STATE_NAMES.MT },
  { name: 'Butte', state: STATE_NAMES.MT },
  { name: 'Great Falls', state: STATE_NAMES.MT },
  { name: 'Helena', state: STATE_NAMES.MT },
  { name: 'Lewistown', state: STATE_NAMES.MT },
  { name: 'Missoula', state: STATE_NAMES.MT },
  { name: 'Grand Island', state: STATE_NAMES.NE },
  { name: 'Lincoln', state: STATE_NAMES.NE },
  { name: 'Norfolk', state: STATE_NAMES.NE },
  { name: 'North Platte', state: STATE_NAMES.NE },
  { name: 'Omaha', state: STATE_NAMES.NE },
  { name: 'Carson City', state: STATE_NAMES.NV },
  { name: 'Elko', state: STATE_NAMES.NV },
  { name: 'Fallon', state: STATE_NAMES.NV },
  { name: 'Las Vegas', state: STATE_NAMES.NV },
  { name: 'Lincoln County', state: STATE_NAMES.NV },
  { name: 'Nye County', state: STATE_NAMES.NV },
  { name: 'Reno', state: STATE_NAMES.NV },
  { name: 'Sparks', state: STATE_NAMES.NV },
  { name: 'Tahoe Area', state: STATE_NAMES.NV },
  { name: 'Concord', state: STATE_NAMES.NV },
  { name: 'Dover', state: STATE_NAMES.NV },
  { name: 'Keene', state: STATE_NAMES.NV },
  { name: 'Laconia', state: STATE_NAMES.NV },
  { name: 'Littleton', state: STATE_NAMES.NV },
  { name: 'Manchester', state: STATE_NAMES.NV },
  { name: 'Nashua', state: STATE_NAMES.NV },
  { name: 'Portsmouth', state: STATE_NAMES.NV },
  { name: 'Rochester', state: STATE_NAMES.NV },
  { name: 'Salem', state: STATE_NAMES.NV },
  { name: 'Asbury Park', state: STATE_NAMES.NJ },
  { name: 'Atlantic City', state: STATE_NAMES.NJ },
  { name: 'Bayonne', state: STATE_NAMES.NJ },
  { name: 'Camden', state: STATE_NAMES.NJ },
  { name: 'Clifton', state: STATE_NAMES.NJ },
  { name: 'East Orange', state: STATE_NAMES.NJ },
  { name: 'Edison', state: STATE_NAMES.NJ },
  { name: 'Elizabeth', state: STATE_NAMES.NJ },
  { name: 'Fairlawn', state: STATE_NAMES.NJ },
  { name: 'Hackensack', state: STATE_NAMES.NJ },
  { name: 'Irvington', state: STATE_NAMES.NJ },
  { name: 'Jersey City', state: STATE_NAMES.NJ },
  { name: 'Lakewood', state: STATE_NAMES.NJ },
  { name: 'Morristown', state: STATE_NAMES.NJ },
  { name: 'New Brunswick', state: STATE_NAMES.NJ },
  { name: 'Newark', state: STATE_NAMES.NJ },
  { name: 'Passaic', state: STATE_NAMES.NJ },
  { name: 'Paterson', state: STATE_NAMES.NJ },
  { name: 'Plainfield', state: STATE_NAMES.NJ },
  { name: 'Somerville', state: STATE_NAMES.NJ },
  { name: 'Teaneck', state: STATE_NAMES.NJ },
  { name: 'Trenton', state: STATE_NAMES.NJ },
  { name: 'Vineland', state: STATE_NAMES.NJ },
  { name: 'West Orange', state: STATE_NAMES.NJ },
  { name: 'Alamogordo', state: STATE_NAMES.NM },
  { name: 'Albuquerque', state: STATE_NAMES.NM },
  { name: 'Carlsbad', state: STATE_NAMES.NM },
  { name: 'Clovis', state: STATE_NAMES.NM },
  { name: 'Farmington', state: STATE_NAMES.NM },
  { name: 'Gallup', state: STATE_NAMES.NM },
  { name: 'Hobbs', state: STATE_NAMES.NM },
  { name: 'Las Cruces', state: STATE_NAMES.NM },
  { name: 'Los Alamos', state: STATE_NAMES.NM },
  { name: 'Portales', state: STATE_NAMES.NM },
  { name: 'Roswell', state: STATE_NAMES.NM },
  { name: 'Santa Fe', state: STATE_NAMES.NM },
  { name: 'Taos', state: STATE_NAMES.NM },
  { name: 'Albany', state: STATE_NAMES.NY },
  { name: 'Amsterdam', state: STATE_NAMES.NY },
  { name: 'Auburn', state: STATE_NAMES.NY },
  { name: 'Binghamton', state: STATE_NAMES.NY },
  { name: 'Buffalo', state: STATE_NAMES.NY },
  { name: 'Elmira', state: STATE_NAMES.NY },
  { name: 'Ithaca', state: STATE_NAMES.NY },
  { name: 'Jamestown', state: STATE_NAMES.NY },
  { name: 'Kingston', state: STATE_NAMES.NY },
  { name: 'Niagara Falls', state: STATE_NAMES.NY },
  { name: 'Plattsburgh', state: STATE_NAMES.NY },
  { name: 'Poughkeepsie', state: STATE_NAMES.NY },
  { name: 'Rochester', state: STATE_NAMES.NY },
  { name: 'Rome', state: STATE_NAMES.NY },
  { name: 'Schenectady', state: STATE_NAMES.NY },
  { name: 'Syracuse', state: STATE_NAMES.NY },
  { name: 'Troy', state: STATE_NAMES.NY },
  { name: 'Utica', state: STATE_NAMES.NY },
  { name: 'Watertown', state: STATE_NAMES.NY },
  { name: 'Bronx', state: STATE_NAMES.NY },
  { name: 'Brooklyn', state: STATE_NAMES.NY },
  { name: 'Manhattan', state: STATE_NAMES.NY },
  { name: 'Nassau County', state: STATE_NAMES.NY },
  { name: 'Orange County', state: STATE_NAMES.NY },
  { name: 'Putnam County', state: STATE_NAMES.NY },
  { name: 'Queens', state: STATE_NAMES.NY },
  { name: 'Rockland County', state: STATE_NAMES.NY },
  { name: 'Staten Island', state: STATE_NAMES.NY },
  { name: 'Suffolk County', state: STATE_NAMES.NY },
  { name: 'Westchester County', state: STATE_NAMES.NY },
  { name: 'Yonkers', state: STATE_NAMES.NY },
  { name: 'Asheville', state: STATE_NAMES.NC },
  { name: 'Charlotte', state: STATE_NAMES.NC },
  { name: 'Durham', state: STATE_NAMES.NC },
  { name: 'Fayetteville', state: STATE_NAMES.NC },
  { name: 'Gastonia', state: STATE_NAMES.NC },
  { name: 'Goldsboro', state: STATE_NAMES.NC },
  { name: 'Greensboro', state: STATE_NAMES.NC },
  { name: 'Greenville', state: STATE_NAMES.NC },
  { name: 'Hickory', state: STATE_NAMES.NC },
  { name: 'Jacksonville', state: STATE_NAMES.NC },
  { name: 'Raleigh', state: STATE_NAMES.NC },
  { name: 'Rocky Mount', state: STATE_NAMES.NC },
  { name: 'Wilmington', state: STATE_NAMES.NC },
  { name: 'Winston-Salem', state: STATE_NAMES.NC },
  { name: 'Bismarck', state: STATE_NAMES.ND },
  { name: 'Fargo', state: STATE_NAMES.ND },
  { name: 'Grand Forks', state: STATE_NAMES.ND },
  { name: 'Jamestown', state: STATE_NAMES.ND },
  { name: 'Mandan', state: STATE_NAMES.ND },
  { name: 'Minot', state: STATE_NAMES.ND },
  { name: 'Williston', state: STATE_NAMES.ND },
  { name: 'Akron', state: STATE_NAMES.OH },
  { name: 'Canton', state: STATE_NAMES.OH },
  { name: 'Cincinnati', state: STATE_NAMES.OH },
  { name: 'Cleveland', state: STATE_NAMES.OH },
  { name: 'Columbus', state: STATE_NAMES.OH },
  { name: 'Dayton', state: STATE_NAMES.OH },
  { name: 'East Liverpool', state: STATE_NAMES.OH },
  { name: 'Hamilton', state: STATE_NAMES.OH },
  { name: 'Lima', state: STATE_NAMES.OH },
  { name: 'Lorain County', state: STATE_NAMES.OH },
  { name: 'Mansfield', state: STATE_NAMES.OH },
  { name: 'Marion', state: STATE_NAMES.OH },
  { name: 'Middletown', state: STATE_NAMES.OH },
  { name: 'Newark', state: STATE_NAMES.OH },
  { name: 'Portsmouth', state: STATE_NAMES.OH },
  { name: 'Springfield', state: STATE_NAMES.OH },
  { name: 'Toledo', state: STATE_NAMES.OH },
  { name: 'Youngstown', state: STATE_NAMES.OH },
  { name: 'Ardmore', state: STATE_NAMES.OK },
  { name: 'Bartlesville', state: STATE_NAMES.OK },
  { name: 'Enid', state: STATE_NAMES.OK },
  { name: 'Lawton', state: STATE_NAMES.OK },
  { name: 'Norman', state: STATE_NAMES.OK },
  { name: 'Oklahoma City', state: STATE_NAMES.OK },
  { name: 'Tulsa', state: STATE_NAMES.OK },
  { name: 'Albany', state: STATE_NAMES.OR },
  { name: 'Altamont', state: STATE_NAMES.OR },
  { name: 'Astoria', state: STATE_NAMES.OR },
  { name: 'Bend', state: STATE_NAMES.OR },
  { name: 'Coos Bay', state: STATE_NAMES.OR },
  { name: 'Corvallis', state: STATE_NAMES.OR },
  { name: 'Eugene', state: STATE_NAMES.OR },
  { name: 'Grants Pass', state: STATE_NAMES.OR },
  { name: 'Klamath Falls', state: STATE_NAMES.OR },
  { name: 'Medford', state: STATE_NAMES.OR },
  { name: 'North Bend', state: STATE_NAMES.OR },
  { name: 'Pendleton', state: STATE_NAMES.OR },
  { name: 'Portland', state: STATE_NAMES.OR },
  { name: 'Roseburg', state: STATE_NAMES.OR },
  { name: 'Salem', state: STATE_NAMES.OR },
  { name: 'Springfield', state: STATE_NAMES.OR },
  { name: 'The Dalles', state: STATE_NAMES.OR },
  { name: 'Allentown', state: STATE_NAMES.PA },
  { name: 'Altoona', state: STATE_NAMES.PA },
  { name: 'Bethlehem', state: STATE_NAMES.PA },
  { name: 'Easton', state: STATE_NAMES.PA },
  { name: 'Erie', state: STATE_NAMES.PA },
  { name: 'Harrisburg', state: STATE_NAMES.PA },
  { name: 'Johnstown', state: STATE_NAMES.PA },
  { name: 'Lancaster', state: STATE_NAMES.PA },
  { name: 'Norristown', state: STATE_NAMES.PA },
  { name: 'Philadelphia', state: STATE_NAMES.PA },
  { name: 'Pittsburgh', state: STATE_NAMES.PA },
  { name: 'Reading', state: STATE_NAMES.PA },
  { name: 'Scranton', state: STATE_NAMES.PA },
  { name: 'State College', state: STATE_NAMES.PA },
  { name: 'Wilkes-Barre', state: STATE_NAMES.PA },
  { name: 'Williamsport', state: STATE_NAMES.PA },
  { name: 'York', state: STATE_NAMES.PA },
  { name: 'Newport', state: STATE_NAMES.RI },
  { name: 'Providence', state: STATE_NAMES.RI },
  { name: 'Warwick', state: STATE_NAMES.RI },
  { name: 'Anderson', state: STATE_NAMES.SC },
  { name: 'Charleston', state: STATE_NAMES.SC },
  { name: 'Columbia', state: STATE_NAMES.SC },
  { name: 'Florence', state: STATE_NAMES.SC },
  { name: 'Greenville', state: STATE_NAMES.SC },
  { name: 'Myrtle Beach', state: STATE_NAMES.SC },
  { name: 'Rock Hill', state: STATE_NAMES.SC },
  { name: 'Spartanburg', state: STATE_NAMES.SC },
  { name: 'Aberdeen', state: STATE_NAMES.SD },
  { name: 'Brookings', state: STATE_NAMES.SD },
  { name: 'Huron', state: STATE_NAMES.SD },
  { name: 'Mitchell', state: STATE_NAMES.SD },
  { name: 'Pierre', state: STATE_NAMES.SD },
  { name: 'Rapid City', state: STATE_NAMES.SD },
  { name: 'Sioux Falls', state: STATE_NAMES.SD },
  { name: 'Vermillion', state: STATE_NAMES.SD },
  { name: 'Watertown', state: STATE_NAMES.SD },
  { name: 'Yankton', state: STATE_NAMES.SD },
  { name: 'Bristol', state: STATE_NAMES.TN },
  { name: 'Chattanooga', state: STATE_NAMES.TN },
  { name: 'Columbia', state: STATE_NAMES.TN },
  { name: 'Jackson', state: STATE_NAMES.TN },
  { name: 'Johnson City', state: STATE_NAMES.TN },
  { name: 'Kingsport', state: STATE_NAMES.TN },
  { name: 'Knoxville', state: STATE_NAMES.TN },
  { name: 'Memphis', state: STATE_NAMES.TN },
  { name: 'Nashville', state: STATE_NAMES.TN },
  { name: 'Abilene', state: STATE_NAMES.TX },
  { name: 'Amarillo', state: STATE_NAMES.TX },
  { name: 'Austin', state: STATE_NAMES.TX },
  { name: 'Baytown', state: STATE_NAMES.TX },
  { name: 'Beaumont', state: STATE_NAMES.TX },
  { name: 'Cameron County', state: STATE_NAMES.TX },
  { name: 'Corpus Christi', state: STATE_NAMES.TX },
  { name: 'Dallas', state: STATE_NAMES.TX },
  { name: 'El Paso', state: STATE_NAMES.TX },
  { name: 'Fort Worth', state: STATE_NAMES.TX },
  { name: 'Galveston', state: STATE_NAMES.TX },
  { name: 'Hidalgo County', state: STATE_NAMES.TX },
  { name: 'Houston', state: STATE_NAMES.TX },
  { name: 'Laredo', state: STATE_NAMES.TX },
  { name: 'Longview', state: STATE_NAMES.TX },
  { name: 'Lubbock', state: STATE_NAMES.TX },
  { name: 'Marshall', state: STATE_NAMES.TX },
  { name: 'Midland', state: STATE_NAMES.TX },
  { name: 'Odessa', state: STATE_NAMES.TX },
  { name: 'Port Arthur', state: STATE_NAMES.TX },
  { name: 'San Angelo', state: STATE_NAMES.TX },
  { name: 'San Antonio', state: STATE_NAMES.TX },
  { name: 'Texas City', state: STATE_NAMES.TX },
  { name: 'Tyler', state: STATE_NAMES.TX },
  { name: 'Victoria', state: STATE_NAMES.TX },
  { name: 'Waco', state: STATE_NAMES.TX },
  { name: 'Wichita Falls', state: STATE_NAMES.TX },
  { name: 'Cedar City', state: STATE_NAMES.UT },
  { name: 'Ogden', state: STATE_NAMES.UT },
  { name: 'Orem', state: STATE_NAMES.UT },
  { name: 'Provo', state: STATE_NAMES.UT },
  { name: 'Salt Lake City', state: STATE_NAMES.UT },
  { name: 'St. George', state: STATE_NAMES.UT },
  { name: 'Barre', state: STATE_NAMES.VT },
  { name: 'Brattleboro', state: STATE_NAMES.VT },
  { name: 'Burlington', state: STATE_NAMES.VT },
  { name: 'Montpelier', state: STATE_NAMES.VT },
  { name: 'Rutland', state: STATE_NAMES.VT },
  { name: 'Alexandria', state: STATE_NAMES.VA },
  { name: 'Arlington', state: STATE_NAMES.VA },
  { name: 'Charlottesville', state: STATE_NAMES.VA },
  { name: 'Chesapeake', state: STATE_NAMES.VA },
  { name: 'Danville', state: STATE_NAMES.VA },
  { name: 'Fredericksburg', state: STATE_NAMES.VA },
  { name: 'Hampton', state: STATE_NAMES.VA },
  { name: 'Lynchburg', state: STATE_NAMES.VA },
  { name: 'Newport News', state: STATE_NAMES.VA },
  { name: 'Norfolk', state: STATE_NAMES.VA },
  { name: 'Petersburg', state: STATE_NAMES.VA },
  { name: 'Portsmouth', state: STATE_NAMES.VA },
  { name: 'Richmond', state: STATE_NAMES.VA },
  { name: 'Roanoke', state: STATE_NAMES.VA },
  { name: 'Virginia Beach', state: STATE_NAMES.VA },
  { name: 'Winchester', state: STATE_NAMES.VA },
  { name: 'Bellingham', state: STATE_NAMES.WA },
  { name: 'Clallam County', state: STATE_NAMES.WA },
  { name: 'Everett', state: STATE_NAMES.WA },
  { name: 'Island County', state: STATE_NAMES.WA },
  { name: 'Kitsap County', state: STATE_NAMES.WA },
  { name: 'Longview', state: STATE_NAMES.WA },
  { name: 'Olympia', state: STATE_NAMES.WA },
  { name: 'Pasco (Tri-cities)', state: STATE_NAMES.WA },
  { name: 'Seattle', state: STATE_NAMES.WA },
  { name: 'Spokane', state: STATE_NAMES.WA },
  { name: 'Tacoma', state: STATE_NAMES.WA },
  { name: 'Vancouver', state: STATE_NAMES.WA },
  { name: 'Walla Walla', state: STATE_NAMES.WA },
  { name: 'Wenatchee', state: STATE_NAMES.WA },
  { name: 'Yakima', state: STATE_NAMES.WA },
  { name: 'Beckley', state: STATE_NAMES.WV },
  { name: 'Bluefield', state: STATE_NAMES.WV },
  { name: 'Charleston', state: STATE_NAMES.WV },
  { name: 'Clarksburg', state: STATE_NAMES.WV },
  { name: 'Fairmont', state: STATE_NAMES.WV },
  { name: 'Huntington', state: STATE_NAMES.WV },
  { name: 'Morgantown', state: STATE_NAMES.WV },
  { name: 'Parkersburg', state: STATE_NAMES.WV },
  { name: 'Wheeling', state: STATE_NAMES.WV },
  { name: 'Appleton', state: STATE_NAMES.WI },
  { name: 'Beloit', state: STATE_NAMES.WI },
  { name: 'Eau Claire', state: STATE_NAMES.WI },
  { name: 'Fond du Lac', state: STATE_NAMES.WI },
  { name: 'Green Bay', state: STATE_NAMES.WI },
  { name: 'Janesville', state: STATE_NAMES.WI },
  { name: 'Kenosha', state: STATE_NAMES.WI },
  { name: 'La Crosse', state: STATE_NAMES.WI },
  { name: 'Madison', state: STATE_NAMES.WI },
  { name: 'Manitowoc', state: STATE_NAMES.WI },
  { name: 'Milwaukee', state: STATE_NAMES.WI },
  { name: 'Oshkosh', state: STATE_NAMES.WI },
  { name: 'Racine', state: STATE_NAMES.WI },
  { name: 'Sheboygan', state: STATE_NAMES.WI },
  { name: 'Superior', state: STATE_NAMES.WI },
  { name: 'Wausau', state: STATE_NAMES.WI },
  { name: 'Casper', state: STATE_NAMES.WY },
  { name: 'Cheyenne', state: STATE_NAMES.WY },
  { name: 'Cody', state: STATE_NAMES.WY },
  { name: 'Laramie', state: STATE_NAMES.WY },
  { name: 'Rock Springs', state: STATE_NAMES.WY },
  { name: 'Sheridan', state: STATE_NAMES.WY },
]

export const LOCALES_LIST = LOCALES_OPTIONS.map(option => {
  return {
    label: option.name,
    value: option.name,
    state: option.state,
  }
})

export const STATE_LOCALES = groupBy(LOCALES_LIST, 'state')
