import { get, lowerCase, partition } from 'lodash'
import * as Api from 'core/api'
import { precisionRound } from 'shared/utils/numberOperations'
import { formatList } from 'shared/utils/textHelpers'

import { SaleStatus } from 'shared/constants/report/sales/salesStatus'

import { CONDITION_ADJUSTMENT_MAP } from './constants'
type Nullable<T> = {
  [K in keyof T]: T[K] | null
}

const SUBJECT_FIELDS = {
  residentialUnits: 'Residential Units',
  grossBuildingArea: 'Gross Building Area',
  condition: 'Condition',
  county: 'County',
  state: 'State',
  censusTract: 'Census Tract',
  yearBuilt: 'Year Built',
  submarket: 'Submarket',
  market: 'Market',
  dateOfValue: 'Date of Value',
} as const
type subjectFields = (typeof SUBJECT_FIELDS)[keyof typeof SUBJECT_FIELDS]

const SUBJECT_HREFS: { [key in subjectFields]: string } = {
  [SUBJECT_FIELDS.residentialUnits]: '/report/:reportId/subject-property',
  [SUBJECT_FIELDS.grossBuildingArea]: '/report/:reportId/subject-property',
  [SUBJECT_FIELDS.condition]: '/report/:reportId/subject-property',
  [SUBJECT_FIELDS.county]: '/report/:reportId/subject-property',
  [SUBJECT_FIELDS.state]: '/report/:reportId/subject-property',
  [SUBJECT_FIELDS.censusTract]: '/report/:reportId/property-summary',
  [SUBJECT_FIELDS.yearBuilt]: '/report/:reportId/subject-property',
  [SUBJECT_FIELDS.submarket]: '/report/:reportId/property-market',
  [SUBJECT_FIELDS.market]: '/report/:reportId/property-market',
  [SUBJECT_FIELDS.dateOfValue]: '/report/:reportId/job-details',
}
type compPropertyInformation = {
  residentialUnits: number | null
  grossBuildingArea: number | null
  condition: string | null
  yearBuilt: number | null
  county: string | null
  censusTract: string | null
}

type comp = {
  _id: string
  salesTransactionId: string
  propertyInformation: compPropertyInformation
  saleInformation: { saleDate: string; salePrice: number; saleStatus: string }
  address: { streetAddress: string; state: string; county: string }
}
type subject = {
  residentialUnits: number
  gba: number
  condition: string
  yearBuilt: number
  state?: string
  county?: string
  censusTract?: string
  submarket?: string
  market?: string
  dateOfValue?: string
}
type warning =
  | { isSubject: false; index: number; address: string; field: string; adjustment: string; isSystemIssue?: boolean }
  | {
      isSubject: true
      index?: never
      address: string
      field: subjectFields
      adjustment: string
      isSystemIssue?: never
    }
  | {
      isSubject: true
      index?: never
      address: string
      field: string
      adjustment: string
      isSystemIssue?: true
    }

function calculateAdjustment(compDifferencePercentage: number, maxMagnitude: number, sign = 1) {
  const maxAdjustment = 0.1
  const roundToNearestPercent = 5

  // precisionRound function inherits from Math.round.
  // for negative numbers, Math.round rounds to the nearest integer towards 0.
  // I.e. -0.5 rounds to 0, -1.5 rounds to -1.
  const adjustment = (maxAdjustment * compDifferencePercentage) / maxMagnitude / roundToNearestPercent
  const roundedAdjustment = Math.sign(adjustment) * precisionRound(Math.abs(adjustment), 2) || 0
  // Sometimes the product of sign * roundToNearestPercent * roundedAdjustment is -0 (negative zero).
  // That's why we need to add zero to it to make it a positive zero.
  const zero = 0
  return sign * roundToNearestPercent * roundedAdjustment + zero
}

export const calculateSizeAdjustment = (comps: comp[], subject: subject) => {
  const compDifferencePercentageWhichAlsoIncludesSubject = comps.map(comp => {
    if (comp.propertyInformation.residentialUnits === null) {
      return 0
    }
    return comp.propertyInformation.residentialUnits / subject.residentialUnits - 1
  })
  const maxMagnitude = Math.max(
    -Math.min(...compDifferencePercentageWhichAlsoIncludesSubject),
    Math.max(...compDifferencePercentageWhichAlsoIncludesSubject)
  )

  return compDifferencePercentageWhichAlsoIncludesSubject.map(compDifferencePercentage => {
    return calculateAdjustment(compDifferencePercentage, maxMagnitude)
  })
}

export const getSizeWarnings = (comps: comp[], subject: subject) => {
  const warnings: warning[] = []
  comps.forEach((comp, index) => {
    if (!comp.propertyInformation.residentialUnits) {
      warnings.push({
        index,
        address: comp.address.streetAddress,
        field: 'Residential Units',
        adjustment: 'Size Adjustment',
        isSubject: false,
      })
    }
  })

  if (!subject.residentialUnits) {
    warnings.push({
      address: 'Subject',
      field: 'Residential Units',
      adjustment: 'Size Adjustment',
      isSubject: true,
    })
  }

  return warnings
}

export const calculateAverageUnitSizeAdjustment = (comps: comp[], subject: subject) => {
  const subjectAverageUnitSize = subject.gba / (subject.residentialUnits || 1)

  const compDifferencePercentageWhichAlsoIncludesSubject = comps.map(comp => {
    // If either the comp or subject has an undefined condition, we do not adjust
    if (
      comp.propertyInformation.grossBuildingArea === null ||
      comp.propertyInformation.grossBuildingArea === 0 ||
      comp.propertyInformation.residentialUnits === null ||
      comp.propertyInformation.residentialUnits === 0
    ) {
      return 0
    }
    const compAverageUnitSize = comp.propertyInformation.grossBuildingArea / comp.propertyInformation.residentialUnits
    return precisionRound(compAverageUnitSize / subjectAverageUnitSize - 1, 2)
  })
  const maxMagnitude = Math.max(
    -Math.min(...compDifferencePercentageWhichAlsoIncludesSubject),
    Math.max(...compDifferencePercentageWhichAlsoIncludesSubject)
  )

  return compDifferencePercentageWhichAlsoIncludesSubject.map(compDifferencePercentage => {
    return calculateAdjustment(compDifferencePercentage, maxMagnitude, -1)
  })
}

export const getAverageUnitSizeWarnings = (comps: comp[], subject: subject) => {
  const warnings: warning[] = []
  comps.forEach((comp, index) => {
    if (!comp.propertyInformation.residentialUnits) {
      warnings.push({
        index,
        address: comp.address.streetAddress,
        field: 'Residential Units',
        adjustment: 'Average Unit Size Adjustment',
        isSubject: false,
      })
    }
    if (!comp.propertyInformation.grossBuildingArea) {
      warnings.push({
        index,
        address: comp.address.streetAddress,
        field: 'Gross Building Area',
        adjustment: 'Average Unit Size Adjustment',
        isSubject: false,
      })
    }
  })

  if (!subject.residentialUnits) {
    warnings.push({
      address: 'Subject',
      field: 'Residential Units',
      adjustment: 'Average Unit Size Adjustment',
      isSubject: true,
    })
  }
  if (!subject.gba) {
    warnings.push({
      address: 'Subject',
      field: 'Gross Building Area',
      adjustment: 'Average Unit Size Adjustment',
      isSubject: true,
    })
  }

  return warnings
}

export const calculateConditionAdjustment = (comps: comp[], subject: subject) => {
  const subjectConditionNumber = get(CONDITION_ADJUSTMENT_MAP, lowerCase(subject.condition), 0)

  const compDifferencePercentageWhichAlsoIncludesSubject = comps.map(comp => {
    if (comp.propertyInformation.condition === null) {
      return 0
    }
    const compConditionNumber = get(CONDITION_ADJUSTMENT_MAP, lowerCase(comp.propertyInformation.condition), 0)
    // If either the comp or subject has an undefined condition, we do not adjust
    if (compConditionNumber === 0 || subjectConditionNumber === 0) {
      return 0
    }
    return precisionRound(compConditionNumber / subjectConditionNumber - 1, 2)
  })
  const maxMagnitude = Math.max(
    -Math.min(...compDifferencePercentageWhichAlsoIncludesSubject),
    Math.max(...compDifferencePercentageWhichAlsoIncludesSubject)
  )

  return compDifferencePercentageWhichAlsoIncludesSubject.map(compDifferencePercentage => {
    return calculateAdjustment(compDifferencePercentage, maxMagnitude, -1)
  })
}

export const getConditionWarnings = (comps: comp[], subject: subject) => {
  const warnings: warning[] = []
  comps.forEach((comp, index) => {
    if (
      !comp.propertyInformation.condition ||
      !get(CONDITION_ADJUSTMENT_MAP, lowerCase(comp.propertyInformation.condition))
    ) {
      warnings.push({
        index,
        address: comp.address.streetAddress,
        field: 'Condition',
        adjustment: 'Condition Adjustment',
        isSubject: false,
      })
    }
  })

  if (!get(CONDITION_ADJUSTMENT_MAP, lowerCase(subject.condition))) {
    warnings.push({
      address: 'Subject',
      field: 'Condition',
      adjustment: 'Condition Adjustment',
      isSubject: true,
    })
  }

  return warnings
}

export const groupAdjustmentWarningsByAddress = (warnings: warning[]) => {
  const groupedWarnings: {
    index?: number
    address: string
    isSubject: boolean
    adjustmentWarnings: { adjustment: string; fields: string[] }[]
    warningLinks?: { href: string; fields: string }[]
  }[] = []
  const [systemWarnings, userAddressableWarnings] = partition(warnings, warning => warning.isSystemIssue)

  userAddressableWarnings.forEach(warning => {
    const { index, address, field, adjustment, isSubject } = warning
    if (address === undefined) {
      return
    }

    const existingGroup = groupedWarnings.find(group => group.address === address)
    if (!existingGroup) {
      groupedWarnings.push({
        index,
        address,
        isSubject,
        adjustmentWarnings: [
          {
            adjustment,
            fields: [field],
          },
        ],
      })

      return
    }

    const existingAdjustment = existingGroup.adjustmentWarnings.find(
      existingAdjustment => existingAdjustment.adjustment === adjustment
    )

    if (!existingAdjustment) {
      existingGroup.adjustmentWarnings.push({
        adjustment,
        fields: [field],
      })

      return
    }

    existingAdjustment.fields.push(field)
  })

  const subjectWarning = groupedWarnings.find(warning => warning.isSubject)
  if (subjectWarning) {
    subjectWarning.warningLinks = subjectWarning.adjustmentWarnings
      .flatMap(warning => warning.fields)
      .reduce<{ href: string; fields: string[] }[]>((warningLinks, field) => {
        const href = SUBJECT_HREFS[field as subjectFields]
        const existingLink = warningLinks.find(link => link.href === href)

        if (existingLink) {
          existingLink.fields.push(field)
        } else {
          warningLinks.push({
            href,
            fields: [field],
          })
        }

        return warningLinks
      }, [])
      .map(link => {
        let { href } = link
        if (!href) {
          console.warn('No href found for warning link', link)
          href = Object.values(SUBJECT_HREFS)[0]
        }
        return {
          href,
          fields: formatList(link.fields),
        }
      })
  }

  return {
    subjectWarning,
    compWarnings: groupedWarnings.filter(warning => !warning.isSubject),
    systemWarnings,
  }
}

export const getAverageAssetValues = async (comps: comp[], subject: subject) => {
  const { state, submarket, market, dateOfValue } = subject

  if (!state || !submarket || !market || !dateOfValue) {
    return { subjectAssetValue: 0, compAssetValues: comps.map(() => 0) }
  }
  const datesOfInterest = [dateOfValue, ...comps.map(comp => comp.saleInformation.saleDate)]

  const assetValues = await Api.getAverageAssetValues(state, submarket, market, datesOfInterest).catch(err => {
    console.error('Error getting market conditions', err)
    return [0, ...comps.map(() => 0)]
  })

  const [subjectAssetValue, ...compAssetValues] = assetValues

  return { subjectAssetValue, compAssetValues }
}

export const calculateMarketConditionAdjustment = async (comps: comp[], subject: subject) => {
  const { subjectAssetValue, compAssetValues } = await getAverageAssetValues(comps, subject)
  const compPercentageDifference = comps.map((comp, index) => {
    return compAssetValues[index] / subjectAssetValue - 1
  })
  const maxMagnitude = Math.max(-Math.min(...compPercentageDifference), Math.max(...compPercentageDifference))

  return compPercentageDifference.map(compDifferencePercentage => {
    return calculateAdjustment(compDifferencePercentage, maxMagnitude, -1)
  })
}

const getMarketConditionWarnings = async (comps: comp[], subject: subject) => {
  const warnings: warning[] = []
  comps.forEach((comp, index) => {
    if (!comp.saleInformation?.saleDate) {
      warnings.push({
        index,
        address: comp.address.streetAddress,
        field: 'Sale Date',
        adjustment: 'Market Conditions Adjustment',
        isSubject: false,
      })
    }
  })

  if (!subject.dateOfValue) {
    warnings.push({
      address: 'Subject',
      field: 'Date of Value',
      adjustment: 'Market Conditions Adjustment',
      isSubject: true,
    })
  }

  if (!subject.market) {
    warnings.push({
      address: 'Subject',
      field: 'Market',
      adjustment: 'Market Conditions Adjustment',
      isSubject: true,
    })
  }

  if (!subject.submarket) {
    warnings.push({
      address: 'Subject',
      field: 'Submarket',
      adjustment: 'Market Conditions Adjustment',
      isSubject: true,
    })
  }

  if (!warnings.length) {
    const { subjectAssetValue, compAssetValues } = await getAverageAssetValues(comps, subject)
    if (!subjectAssetValue) {
      warnings.push({
        address: 'Subject',
        field: 'Average Asset Value',
        adjustment: 'Market Conditions Adjustment',
        isSubject: true,
        isSystemIssue: true,
      })
    }

    compAssetValues.forEach((compAssetValue: number, index: number) => {
      if (!compAssetValue) {
        warnings.push({
          index,
          address: comps[index].address.streetAddress,
          field: 'Average Asset Value',
          adjustment: 'Market Conditions Adjustment',
          isSubject: false,
          isSystemIssue: true,
        })
      }
    })
  }

  return warnings
}

const isSubjectValid = ({ state, county, censusTract }: Nullable<Partial<subject>>) => {
  return state && county && censusTract
}

const purifyCounty = (county?: string | null) => county?.replace(' County', '')

const getMedianContractRentForLocation = (
  state?: string,
  county?: string,
  censusTract?: string | null
): Promise<number> => {
  if (!isSubjectValid({ state, county, censusTract })) {
    return Promise.resolve(0)
  }

  const pureCounty = purifyCounty(county)
  return Api.getMedianContractRent(state, pureCounty, censusTract).catch(error => {
    console.error(`Error fetching median contract rent for ${state}, ${pureCounty}, ${censusTract}:`, error)
    return 0
  })
}

export const getMedianContractRents = async (comps: comp[], subject: subject) => {
  if (!isSubjectValid(subject)) {
    return { subjectMedianContractRent: 0, compMedianContractRents: comps.map(() => 0) }
  }

  const subjectRentPromise = await getMedianContractRentForLocation(subject.state, subject.county, subject.censusTract)
  const compRentsPromises: Promise<number>[] = comps.map(comp =>
    getMedianContractRentForLocation(comp.address.state, comp.address.county, comp.propertyInformation.censusTract)
  )

  const compRents = await Promise.all(compRentsPromises.map(promise => promise.catch(err => 0)))

  return {
    subjectMedianContractRent: subjectRentPromise,
    compMedianContractRents: compRents,
  }
}

export const calculateLocationAdjustment = async (comps: comp[], subject: subject) => {
  const { subjectMedianContractRent, compMedianContractRents } = await getMedianContractRents(comps, subject)
  const compPercentageDifference = comps.map((comp, index) => {
    return compMedianContractRents[index] / subjectMedianContractRent - 1
  })
  const maxMagnitude = Math.max(-Math.min(...compPercentageDifference), Math.max(...compPercentageDifference))

  return compPercentageDifference.map(compDifferencePercentage => {
    return calculateAdjustment(compDifferencePercentage, maxMagnitude, -1)
  })
}

const getLocationWarnings = async (comps: comp[], subject: subject) => {
  const warnings = []
  comps.forEach((comp, index) => {
    if (!comp.address.state) {
      warnings.push({
        index,
        address: comp.address.streetAddress,
        field: 'State',
        adjustment: 'Location Adjustment',
        isSubject: false,
      })
    }
    if (!comp.address.county) {
      warnings.push({
        index,
        address: comp.address.streetAddress,
        field: 'County',
        adjustment: 'Location Adjustment',
        isSubject: false,
      })
    }
    if (!comp.propertyInformation.censusTract) {
      warnings.push({
        index,
        address: comp.address.streetAddress,
        field: 'Census Tract',
        adjustment: 'Location Adjustment',
        isSubject: false,
      })
    }
  })

  if (!subject.state) {
    warnings.push({
      address: 'Subject',
      field: 'State',
      adjustment: 'Location Adjustment',
      isSubject: true,
    })
  }
  if (!subject.county) {
    warnings.push({
      address: 'Subject',
      field: 'County',
      adjustment: 'Location Adjustment',
      isSubject: true,
    })
  }
  if (!subject.censusTract) {
    warnings.push({
      address: 'Subject',
      field: 'Census Tract',
      adjustment: 'Location Adjustment',
      isSubject: true,
    })
  }

  if (!warnings.length) {
    const { subjectMedianContractRent, compMedianContractRents } = await getMedianContractRents(comps, subject)
    if (!subjectMedianContractRent) {
      warnings.push({
        address: 'Subject',
        field: 'Median Contract Rent',
        adjustment: 'Location Adjustment',
        isSubject: true,
        isSystemIssue: true,
      })
    }

    compMedianContractRents.forEach((compMedianContractRent: number, index: number) => {
      if (!compMedianContractRent) {
        warnings.push({
          index,
          address: comps[index].address.streetAddress,
          field: 'Median Contract Rent',
          adjustment: 'Location Adjustment',
          isSubject: false,
          isSystemIssue: true,
        })
      }
    })
  }

  return warnings
}

export const calculateConditionsOfSaleAdjustment = (comps: comp[]) => {
  return comps.map(comp => {
    const saleStatus = get(comp, 'saleInformation.saleStatus', SaleStatus.TRANSACTION)
    if (saleStatus === SaleStatus.LISTING) {
      return -0.05
    } else {
      return 0
    }
  })
}

export const dynamicAdjustmentDefinitions = {
  size: 'size',
  unitSize: 'averageUnitSize',
  condition: 'condition',
  location: 'neighborhood',
  market: 'marketConditions',
  conditionsOfSale: 'conditionsOfSale',
}

export const adjustmentDefinitions = [
  {
    adjustmentType: 'size',
    calculateAdjustments: calculateSizeAdjustment,
    getWarnings: getSizeWarnings,
  },
  {
    adjustmentType: 'marketConditions',
    calculateAdjustments: calculateMarketConditionAdjustment,
    getWarnings: getMarketConditionWarnings,
  },
  {
    adjustmentType: 'averageUnitSize',
    calculateAdjustments: calculateAverageUnitSizeAdjustment,
    getWarnings: getAverageUnitSizeWarnings,
  },
  {
    adjustmentType: 'condition',
    calculateAdjustments: calculateConditionAdjustment,
    getWarnings: getConditionWarnings,
  },
  {
    adjustmentType: 'neighborhood',
    calculateAdjustments: calculateLocationAdjustment,
    getWarnings: getLocationWarnings,
  },
  {
    adjustmentType: 'conditionsOfSale',
    calculateAdjustments: calculateConditionsOfSaleAdjustment,
    getWarnings: () => [],
  },
]
