import { connect } from 'react-redux'

import { selectRemovedBuildingComps, selectShowPerRoomAnalysis } from '../../redux/selectors'

import BuildingCompList from './BuildingCompList'

const mapStateToProps = (state, ownProps) => {
  return {
    items: selectRemovedBuildingComps(state, ownProps),
    showPerRoomAnalysis: selectShowPerRoomAnalysis(state),
  }
}

export default connect(mapStateToProps)(BuildingCompList)
