import createDecorator from 'final-form-calculate'

export const decorators = [
  createDecorator(
    {
      field: 'building.hasStorageUnits',
      updates: value => {
        if (!value) {
          return { 'building.storageUnitCount': 0 }
        }
        return {}
      },
    },
    {
      field: 'building.hasParking',
      updates: value => {
        if (!value) {
          return { 'building.parkingSpaceCount': 0 }
        }
        return {}
      },
    },
    {
      field: 'building.hasOutdoorSpace',
      updates: value => {
        if (!value) {
          return {
            'building.outdoorSpace.tennisCourts': false,
            'building.outdoorSpace.garden': false,
            'building.outdoorSpace.roofDeck': false,
            'building.outdoorSpace.terrace': false,
            'building.outdoorSpace.backyard': false,
            'building.outdoorSpace.other': false,
          }
        }
        return {}
      },
    },
    {
      field: 'building.outdoorSpace.other',
      updates: value => {
        if (!value) {
          return { 'building.otherOutdoorSpace': '' }
        }
        return {}
      },
    },
    {
      field: 'building.hasOtherAmenities',
      updates: value => {
        if (!value) {
          return { 'building.otherBuildingAmenity': '' }
        }
        return {}
      },
    }
  ),
]
