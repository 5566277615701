import { get, groupBy } from 'lodash'

import { SaleStatus } from 'shared/constants/report/sales/salesStatus'

import { dateSort } from '../../client/src/shared/utils/dateHelper'

export const sortByScoreAsc = comps => {
  const groupedComps = groupBy(comps, comp => {
    if (comp.score?.total) {
      return 'compsWithScores'
    }
    return 'compsWithoutScores'
  })
  const { compsWithScores = [], compsWithoutScores = [] } = groupedComps
  const sortedArray = compsWithScores.sort((compOne, compTwo) => {
    const scoreOne = get(compOne, 'score.total') || 0
    const scoreTwo = get(compTwo, 'score.total') || 0
    return scoreOne - scoreTwo
  })
  return sortedArray.concat(compsWithoutScores)
}

export const sortCompsArray = array => {
  const compsByStatus = groupBy(array, comp => {
    const { saleInformation } = comp
    const { saleStatus } = saleInformation
    if (saleStatus === SaleStatus.UNDER_CONTRACT) {
      return 'inContract'
    }
    if (saleStatus === SaleStatus.LISTING) {
      return 'listing'
    }
    return 'sold'
  })
  const { inContract = [], listing = [], sold = [] } = compsByStatus

  return []
    .concat(inContract)
    .concat(listing)
    .concat(dateSort(sold, 'saleInformation.saleDate'))
}
