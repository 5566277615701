import { get, isEmpty, isNumber, mean, sum } from 'lodash'
import createDecorator from 'final-form-calculate'

import {
  NET_OPERATING_INCOME,
  TOTAL_OPERATING_EXPENSES,
  TOTAL_OPERATING_EXPENSES_WITHOUT_TAXES,
} from 'shared/constants/expenses/expenseHistory'
import { isProjectionPeriod } from 'shared/helpers/incomeApproach/expenses/helpers'

import { getExpensePeriodDate } from 'shared/helpers/expenseDate'

import BoweryDate from '@bowery-valuation/bowery-date'
import { MONTH_KEYS } from 'client-shared/utils/expenses/constants'

import { isProjectionOrActualPeriod } from './helpers'
import { GROSS_REVENUE, REAL_ESTATE_TAXES } from './constants'

const valueForRow = (item, row) => {
  return item?.expenses?.find(expense => expense.id === row.id)?.value
}

const filterRows = (column, check) => {
  if (!column?.expenses) {
    return
  }

  const filteredRows = column.expenses.filter(check)
  return filteredRows.map(row => valueForRow(column, row))?.filter(isNumber)
}

const getYearlyNOI = (expenseItem, realEstateTaxesIds, parentCategoriesIds) => {
  if (!expenseItem) {
    return 0
  }
  const grossRevenue = expenseItem.expenses.find(expense => expense.id === GROSS_REVENUE)
  let income = 0
  if (grossRevenue) {
    income = grossRevenue.value || 0
  }

  let taxes = 0
  const realEstateTaxes = expenseItem.expenses.find(expense => realEstateTaxesIds.includes(expense.id))
  if (realEstateTaxes) {
    taxes = realEstateTaxes.value || 0
  }

  const expenses =
    sum(
      filterRows(expenseItem, row => ![...parentCategoriesIds, ...realEstateTaxesIds, GROSS_REVENUE].includes(row.id))
    ) || 0
  return income - taxes - expenses
}

export const setExpenseDate = createDecorator({
  field: ['expenseMonth', 'expenseYear'],
  updates: {
    expenseDate: (_value, allValues) => {
      const expenseYear = get(allValues, 'expenseYear')
      const expenseMonth = get(allValues, 'expenseMonth')
      const date = getExpensePeriodDate(expenseMonth, expenseYear)

      return date || undefined
    },
  },
})

export const defaultDate = createDecorator({
  field: 'expensePeriod',
  updates: {
    expenseMonth: expensePeriod => {
      if (isProjectionOrActualPeriod(expensePeriod)) {
        return MONTH_KEYS.DECEMBER
      }
      return undefined
    },
    expenseYear: expensePeriod => {
      if (isProjectionPeriod(expensePeriod)) {
        return new BoweryDate().addYearsToDate(1).year
      }
      return ''
    },
  },
})

const totalsKeys = [TOTAL_OPERATING_EXPENSES.key, TOTAL_OPERATING_EXPENSES_WITHOUT_TAXES.key, NET_OPERATING_INCOME.key]

// Do NOT update `value` of any expense items - triggers recursion
export const rowDecorator = createDecorator({
  field: 'expenseHistory',
  updates: (_, __, allValues, previousValue) => {
    const totalsToUpdate = []
    const rowsToUpdate = []

    /*
     * Should update everything in cases:
     * if initial render - `previousValue` is {} or undefined
     * if number of years changed - deleted or added new (`runAutomation` can add years with data)
     */
    const shouldUpdateAll =
      !previousValue?.expenseCategories || allValues?.expenseHistory?.length !== previousValue?.expenseHistory?.length

    if (shouldUpdateAll) {
      rowsToUpdate.push(...(allValues?.expenseCategories.map(({ id }) => id) || []))
      totalsToUpdate.push(...totalsKeys)
    } else {
      // Forms array of rows that did change
      allValues?.expenseHistory?.forEach(expenseYear => {
        const previousYear = previousValue?.expenseHistory?.find(({ key }) => key === expenseYear.key)
        if (isEmpty(previousYear)) {
          rowsToUpdate.push(...expenseYear.expenses.map(({ id }) => id))
          totalsToUpdate.push(...totalsKeys)
          return
        }

        expenseYear.expenses.forEach(expense => {
          const previousExpense = previousYear.expenses.find(({ id }) => id === expense.id)
          if (!previousExpense || previousExpense.value !== expense.value) {
            rowsToUpdate.push(expense.id)
          }
        })

        totalsKeys.forEach(key => {
          if (expenseYear[key] !== previousYear[key]) {
            totalsToUpdate.push(key)
          }
        })
      })

      // Additional check as fallback
      allValues?.expenseCategories.forEach(({ id, isOpen }) => {
        const previousCategory = previousValue?.expenseCategories?.find(category => id === category.id)
        /*
         * Hack - `isOpen === false` to always recalculate collapsed categories,
         * data in `allValues` and `previousValue` in some cases identical
         */
        if (!previousCategory || isOpen === false) {
          rowsToUpdate.push(id)
        }
      })
    }

    const uniqueRowsToUpdate = [...new Set(rowsToUpdate)]
    const uniqueTotalsToUpdate = [...new Set(totalsToUpdate)]

    const updates = {}
    uniqueRowsToUpdate.forEach(id => {
      const expenseCategoryIndex = allValues.expenseCategories.findIndex(category => id === category.id)
      if (expenseCategoryIndex !== -1) {
        const values = allValues.expenseHistory
          .map(({ expenses }) => expenses.find(expense => id === expense.id)?.value)
          .filter(isNumber)

        updates[`expenseCategories[${expenseCategoryIndex}].average`] = values.length ? mean(values) : null
      }
    })

    uniqueTotalsToUpdate.forEach(totalId => {
      const values = allValues.expenseHistory.map(expenseYear => expenseYear[totalId]).filter(isNumber)

      updates[`${totalId}.average`] = values.length ? mean(values) : null
    })

    return updates
  },
})

export const columnDecorator = createDecorator({
  field: 'expenseHistory',
  updates: (_, __, allValues) => {
    if (!allValues.expenseHistory?.length) {
      return {}
    }

    // `Parent` categories is excluded from calculations - subcategories values used instead
    const parentCategoriesIds = allValues.expenseCategories
      .filter(({ hasSubcategories }) => hasSubcategories)
      .map(({ id }) => id)

    const realEstateCategory = allValues.expenseCategories.find(({ id }) => id === REAL_ESTATE_TAXES)
    const realEstateTaxesIds = !realEstateCategory?.hasSubcategories
      ? [REAL_ESTATE_TAXES]
      : allValues.expenseCategories.filter(({ parentId }) => parentId === REAL_ESTATE_TAXES).map(({ id }) => id)

    const updates = {}
    allValues.expenseHistory.forEach((expenseYear, index) => {
      const total = sum(filterRows(expenseYear, row => ![...parentCategoriesIds, GROSS_REVENUE].includes(row.id))) || 0
      const totalExcludingTaxes =
        sum(
          filterRows(
            expenseYear,
            row => ![...parentCategoriesIds, ...realEstateTaxesIds, GROSS_REVENUE].includes(row.id)
          )
        ) || 0
      const noi = getYearlyNOI(expenseYear, realEstateTaxesIds, parentCategoriesIds) || 0

      // Checks to avoid unnecessary changes and rerenders
      if (expenseYear.total !== total) {
        updates[`expenseHistory[${index}].${TOTAL_OPERATING_EXPENSES.key}`] = total
      }

      if (expenseYear.totalExcludingTaxes !== totalExcludingTaxes) {
        updates[`expenseHistory[${index}].${TOTAL_OPERATING_EXPENSES_WITHOUT_TAXES.key}`] = totalExcludingTaxes
      }

      if (expenseYear.noi !== noi) {
        updates[`expenseHistory[${index}].${NET_OPERATING_INCOME.key}`] = noi
      }
    })

    return updates
  },
})
