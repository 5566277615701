import React from 'react'
import PropTypes from 'prop-types'

import { FieldArray } from 'react-final-form-arrays'
import { get, findIndex } from 'lodash'
import { Table, TableBody, TableCell, TableHead, TableRow, Button, Grid, Paper, Fab } from '@mui/material'
import Save from '@mui/icons-material/Save'
import SettingsRounded from '@mui/icons-material/SettingsRounded'

import { Form } from 'react-final-form'
import arrayMutators from 'final-form-arrays'
import { connect } from 'react-redux'

import { organizationUpdate } from '../../redux/actions/organization'
import FileSelectionField from '../../../shared/components/FileSelection'

import { FILES_ENDPOINT } from '../../../report/constants'

import FormHeader from '../../../shared/components/FormHeader'

export const FORM_NAME = 'organizationUploads'
const heading = 'Organization Uploads'

class UploadsList extends React.PureComponent {
  remove = (fields, index) => {
    fields.remove(index)
  }

  render() {
    const { fields, organizationId } = this.props

    const values = get(fields, 'value', [])
    return (
      <>
        <FileSelectionField
          name="submarketsDescription"
          label="UPLOAD SUBMARKETS DESCRIPTIONS"
          fileType="multifamilySubmarket"
          uploadEndpoint={FILES_ENDPOINT}
          parentId={organizationId}
          margin="normal"
          onChange={value => {
            if (findIndex(fields, { id: value.id }) === -1) {
              fields.push(value)
            }
          }}
        />
        <Table size="small">
          <TableHead sx={{ '& .MuiTableCell-head': { fontSize: 12, fontWeight: 700 } }}>
            <TableRow>
              <TableCell>#</TableCell>
              <TableCell>Name</TableCell>
              <TableCell>Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody sx={{ '& .MuiTableCell-root': { fontSize: 13 } }}>
            {values.map((item, index) => {
              return (
                <TableRow key={index}>
                  <TableCell>{index + 1}</TableCell>
                  <TableCell>{item.name}</TableCell>
                  <TableCell>
                    <Button variant="contained" onClick={() => this.remove(fields, index)}>
                      Delete
                    </Button>
                  </TableCell>
                </TableRow>
              )
            })}
          </TableBody>
        </Table>
      </>
    )
  }
}

UploadsList.propTypes = {
  organizationId: PropTypes.string.isRequired,
}

class Uploads extends React.PureComponent {
  handleSubmit = values => {
    this.props.organizationUpdate({ formDataPath: FORM_NAME, values })
  }

  render() {
    const { initialValues, organizationId } = this.props

    return (
      <Grid container>
        <Grid item xs={12}>
          <FormHeader title={heading} icon={<SettingsRounded />} section={this.props.section} />
        </Grid>
        <Grid item xs={12} md={6}>
          <Paper>
            <Form
              onSubmit={this.handleSubmit}
              initialValues={initialValues}
              mutators={{
                ...arrayMutators,
              }}
              render={props => {
                const { handleSubmit } = props
                return (
                  <form onSubmit={handleSubmit}>
                    <FieldArray
                      name="descriptions.submarkets"
                      component={UploadsList}
                      organizationId={organizationId}
                    />
                    <Fab type="submit" color="success" sx={{ position: 'fixed', bottom: 40, right: 40 }}>
                      <Save sx={{ color: 'white' }} />
                    </Fab>
                  </form>
                )
              }}
            />
          </Paper>
        </Grid>
      </Grid>
    )
  }
}

Uploads.propTypes = {
  organizationUpdate: PropTypes.func,
}

export default connect(
  state => {
    return {
      initialValues: {
        ...get(state, 'organization.organizationData.organizationInfo'),
      },
      organizationId: get(state, 'organization.organizationData._id'),
    }
  },
  {
    organizationUpdate,
  }
)(Uploads)
