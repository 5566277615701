import { handleActions } from 'redux-actions'

import { NotificationTypes } from '../../constants/notificationsTypes'
import {
  warningNotification,
  errorNotification,
  removeNotification,
  successNotification,
} from '../actions/notifications'

import { removeByIndex } from '../../utils/immutability'

let nextId = 1

const getNextId = () => {
  return nextId++
}

const initialState = []

export const reducer = handleActions(
  {
    [successNotification](state, { payload: { id, message } }) {
      return [...state, { type: NotificationTypes.SUCCESS, id: id || getNextId(), message }]
    },
    [warningNotification](state, { payload: { id, message } }) {
      return [...state, { type: NotificationTypes.WARNING, id: id || getNextId(), message }]
    },
    [errorNotification](state, { payload: { id, message } }) {
      return [...state, { type: NotificationTypes.ERROR, id: id || getNextId(), message }]
    },
    [removeNotification](state, { payload }) {
      const notificationIndex = state.findIndex(notification => notification.id === payload)
      return removeByIndex(state, notificationIndex)
    },
  },
  initialState
)
