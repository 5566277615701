import React from 'react'

import { get } from 'lodash'

import ACASCalculations from 'shared/report-calculations/income-approach/acas/acas-calculations'
import { GEOGRAPHY_OPTIONS } from 'shared/constants/property/geography'

import PreviewAndEdit from '../PreviewAndEdit'
import wrapForm from '../../wrapForm'

import IntroductionEdit from './Edit'
import IntroductionPreview from './Preview'
import { FORM_HEADING, DATA_PATH } from './constants'

const Introduction = ({ title, section, form }) => {
  const { values } = form

  return (
    <PreviewAndEdit title={title} section={section}>
      {isEdit =>
        isEdit ? (
          <IntroductionEdit form={form} />
        ) : (
          <IntroductionPreview
            purposeAndDateOfValueDiscussion={values?.purposeAndDateOfValueDiscussion}
            intendedUse={values?.intendedUse}
            intendedUserDiscussion={values?.intendedUserDiscussion}
            clientIdentificationDiscussion={values?.clientIdentificationDiscussion}
            definitionOfMarketValue={values?.definitionOfMarketValue}
            salesHistoryDiscussion={values?.salesHistoryDiscussion}
            contractHistoryDiscussion={values?.contractHistoryDiscussion}
            exposureTimeDescription={values?.exposureTimeDescription}
            includeMarketingTime={values?.includeMarketingTime}
            marketingTimeDescription={values?.marketingTimeDescription}
            appraisalScopeItems={values?.appraisalScopeItems}
            generalAssumptions={values?.generalAssumptions}
            generalAssumptionsCommentary={values?.generalAssumptionsCommentary}
            sourceInfoDiscussion={values?.sourceInfoDiscussion}
            propertyRights={values?.propertyRights}
          />
        )
      }
    </PreviewAndEdit>
  )
}

export default wrapForm(
  DATA_PATH,
  {
    heading: FORM_HEADING,
  },
  state => {
    const locationIdentifier = get(state, 'report.reportData.locationIdentifier')

    const propertyHistory = {
      salesHistory: get(state, 'report.reportData.propertyInformation.propertyHistory.salesHistory'),
      currentOwner: get(state, 'report.reportData.propertyInformation.propertyHistory.currentOwner'),
      hasSoldWithinThreeYears: get(state, 'report.reportData.dataCollections.subjectProperty.hasSoldWithinThreeYears'),
      streetAddress: get(state, 'report.reportData.propertyInformation.propertySummary.streetAddress'),
      locationInfo: {
        isNYCLocation: locationIdentifier === GEOGRAPHY_OPTIONS.NY,
        isNJLocation: locationIdentifier === GEOGRAPHY_OPTIONS.NJ,
      },
      isUnderContract: get(state, 'report.reportData.propertyInformation.propertyHistory.isUnderContract'),
      isAssignedContract: get(state, 'report.reportData.propertyInformation.propertyHistory.isAssignedContract'),
      contractDetails: get(state, 'report.reportData.propertyInformation.propertyHistory.contractDetails'),
      assignedContractDetails: get(
        state,
        'report.reportData.propertyInformation.propertyHistory.assignedContractDetails'
      ),
    }
    const reportInformation = get(state, 'report.reportData.report.reportInformation', {})
    const capRateConclusion = get(state, 'report.reportData.incomeApproach.capRateConclusion', {})

    const purposeAndDateOfValue = {
      valueAsComplete: get(state, 'report.reportSettings.valueAsComplete'),
      valueAsStabilized: get(state, 'report.reportSettings.valueAsStabilized'),
      interestAppraisedAsIsMarketValue: get(
        state,
        'report.reportData.report.reportInformation.interestAppraisedAsIsMarketValue'
      ),
      interestAppraisedAsStabilized: get(
        state,
        'report.reportData.report.reportInformation.interestAppraisedAsStabilized'
      ),
      interestAppraisedAsComplete: get(state, 'report.reportData.report.reportInformation.interestAppraisedAsComplete'),
      ...ACASCalculations.calculateDatesOfFinalValue({
        dateOfValuation: reportInformation.dateOfValuation,
        asCompleteMonthsOfRentLoss: capRateConclusion.asCompleteMonthsOfRentLoss,
        asStabilizedMonthsOfRentLoss: capRateConclusion.asStabilizedMonthsOfRentLoss,
      }),
    }

    const propertyRightsValues = {
      interestAppraisedAsIsMarketValue: get(
        state,
        'report.reportData.report.reportInformation.interestAppraisedAsIsMarketValue'
      ),
      interestAppraisedAsStabilized: get(
        state,
        'report.reportData.report.reportInformation.interestAppraisedAsStabilized'
      ),
      interestAppraisedAsComplete: get(state, 'report.reportData.report.reportInformation.interestAppraisedAsComplete'),
      reportValueConclusionType: get(state, 'report.reportSettings.valueConclusionType'),
    }

    const appraisalScopeItems = get(state, 'report.reportData.final.appraisalScope.items', [])

    return {
      initialValues: {
        ...get(state, 'report.reportData.previewAndEdit.introduction', {}),
        purposeAndDateOfValue,
        client: get(state, 'report.reportData.report.client', {}),
        propertyHistory,
        locationIdentifier,
        appraisers: get(state, 'report.reportData.report.appraisers.selectedAppraisers', []),
        inspectionDate: get(state, `report.reportData.report.reportInformation.inspectionDate`),
        ...get(state, 'report.reportData.propertyInformation.propertyMarket', {}),
        propertyRightsValues,
        propertyRights: get(state, 'report.reportData.report.reportInformation.propertyRights', {}),
        appraisalScopeItems,
        reportTemplate: get(state, `report.reportSettings.templateType`, null),
      },
    }
  }
)(Introduction)
