import React from 'react'
import { get, startCase } from 'lodash'
import PropTypes from 'prop-types'
import { Table, TableBody, TableCell, TableHead, TableRow, withStyles } from '@material-ui/core'

import { tableHeaderRow, numberField } from '../style'
import { Number } from '../../../../../shared/components'
import { formatInt, formatCurrencyFloat } from '../../../../../shared/utils/numberFormatters'
import { DatePicker } from '../../../../../shared/components/DatePicker'
import { NOT_AVAILABLE } from '../../../../constants'

import { EXPENSE_COMPS_DEFAULT_FILTERS, SQUARE_FEET_POSTFIX } from './constants'

const styles = {
  tableCell: {
    fontSize: 14,
  },
  tableHeaderRow,
  numberField: {
    ...numberField,
    '& input': {
      ...numberField['& input'],
      textAlign: 'left',
    },
  },
  staticCell: {
    fontSize: 12,
    color: 'gray',
  },
}

class CompModalFiltersTable extends React.PureComponent {
  static propTypes = {
    filters: PropTypes.array,
    propertySummary: PropTypes.object.isRequired,
    totalOperatingExpenses: PropTypes.object.isRequired,
    subjectExpenses: PropTypes.object.isRequired,
  }

  static defaultProps = {
    filters: [],
  }

  getStaticCell(item) {
    const { classes } = this.props
    const { propertySummary, totalOperatingExpenses } = this.props

    let value = get(propertySummary, item.propertyName)
    if (item.isNumeric) {
      value = formatInt(value, false)
    }
    if (item.isTotal) {
      const psf = formatCurrencyFloat(get(totalOperatingExpenses, 'psf') || 0)
      const perUnit = formatCurrencyFloat(get(totalOperatingExpenses, 'perUnit') || 0)

      value = `${psf}/SF ${perUnit}/Unit`
    }
    if (item.isString) {
      value = startCase(value)
    }
    return <span className={classes.staticCell}>{value}</span>
  }

  render() {
    const { filters, classes, subjectExpenses } = this.props
    return (
      <Table>
        <TableHead>
          <TableRow>
            <TableCell />
            <TableCell padding="dense">
              <strong className={classes.tableHeaderRow}>Subject</strong>
            </TableCell>
            <TableCell padding="dense">
              <strong className={classes.tableHeaderRow}>Comp Min.</strong>
            </TableCell>
            <TableCell padding="dense">
              <strong className={classes.tableHeaderRow}>Comp Max.</strong>
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {EXPENSE_COMPS_DEFAULT_FILTERS.map((item, index) => {
            const isDate = item.name === 'expenseYear'
            return (
              <TableRow key={`${item.name}.index`} data-qa={`row-${index}`}>
                <TableCell className={classes.tableCell} padding="dense">
                  {item.title}
                </TableCell>
                <TableCell className={classes.tableCell} padding="dense">
                  {this.getStaticCell(item)}
                </TableCell>
                {isDate ? (
                  <TableCell padding="dense">
                    <DatePicker
                      name={`filters.${item.name}.min`}
                      classes={{ formControl: classes.numberField }}
                      clearable
                      keyboard={false}
                      dateFormat="MM/DD/YYYY"
                    />
                  </TableCell>
                ) : (
                  <TableCell padding="dense">
                    <Number
                      placeholder="min"
                      decimalScale={2}
                      classes={{ formControl: classes.numberField }}
                      name={`filters.${item.name}.min`}
                      allowNegative={false}
                    />
                  </TableCell>
                )}
                {isDate ? (
                  <TableCell padding="dense">
                    <DatePicker
                      name={`filters.${item.name}.max`}
                      classes={{ formControl: classes.numberField }}
                      clearable
                      keyboard={false}
                      dateFormat="MM/DD/YYYY"
                    />
                  </TableCell>
                ) : (
                  <TableCell padding="dense">
                    <Number
                      placeholder="max"
                      decimalScale={2}
                      classes={{ formControl: classes.numberField }}
                      name={`filters.${item.name}.max`}
                      allowNegative={false}
                    />
                  </TableCell>
                )}
              </TableRow>
            )
          })}
          <TableRow>
            <TableCell className={classes.tableCell} padding="dense">
              Details
            </TableCell>
            <TableCell className={classes.tableCell} padding="dense">
              <i>Subject</i> Pro Forma
            </TableCell>
            <TableCell />
            <TableCell />
          </TableRow>
          {filters.map(item => {
            const subjectExpense = get(subjectExpenses, item.name, {})
            return (
              <TableRow key={`${item.searchResultName}.index`}>
                <TableCell className={classes.root} padding="dense">{`${item.title}${SQUARE_FEET_POSTFIX}`}</TableCell>
                <TableCell className={classes.tableCell} padding="dense">
                  <span className={classes.staticCell}>
                    {subjectExpense.reported ? formatCurrencyFloat(subjectExpense.sf) : NOT_AVAILABLE}
                  </span>
                </TableCell>
                <TableCell padding="dense">
                  <Number
                    placeholder="min"
                    decimalScale={2}
                    classes={{ formControl: classes.numberField }}
                    name={`filters.${item.searchResultName}.min`}
                    allowNegative={false}
                  />
                </TableCell>
                <TableCell padding="dense">
                  <Number
                    classes={{ formControl: classes.numberField }}
                    decimalScale={2}
                    placeholder="max"
                    name={`filters.${item.searchResultName}.max`}
                    allowNegative={false}
                  />
                </TableCell>
              </TableRow>
            )
          })}
        </TableBody>
      </Table>
    )
  }
}

export default withStyles(styles)(CompModalFiltersTable)
