import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { findIndex, find, get } from 'lodash'
import { Paper, Button, Box, Stack } from '@mui/material'
import { connect } from 'react-redux'

import { checkUserHasInspectorRoleOnly } from 'shared/helpers/userHelper'

import { getRoleIdsByConstant } from 'client-shared/utils/roles'
import { Callout } from 'client-shared/components'
import NarrativeComponent from 'client-shared/components/NarrativeComponent'

import certificationAssistance from 'shared/utils/textGeneration/reportInformation/appraiser/certificationAssistance'
import certificationInspection from 'shared/utils/textGeneration/reportInformation/appraiser/certificationInspection'

import AppraisersTable from './AppraisersTable'
import {
  getLeadAppraisersLabelValuePairs,
  getSearchAppraisersSuggestions,
  sortAppraisers,
  getLeadAppraisers,
} from './appraisersHelper'
import AddAppraiserModal from './AddAppraiserModal'

const AppraisersTableContainer = props => {
  const [isOpenModal, setIsOpenModal] = useState(false)
  const openModal = () => {
    setIsOpenModal(true)
  }
  const closeModal = () => setIsOpenModal(false)

  const { form, appraisers, isFreddieMac, reportState, isJobDetails, currentPath } = props
  const { selectedAppraisers, externalInspectors } = form.values
  const appraisersList = [...selectedAppraisers, ...externalInspectors]

  const getFieldIndexByAppraiserId = appraiserId => {
    return findIndex(appraisersList, appraiser => appraiser.id === appraiserId)
  }
  const onAddAppraiser = appraiser => {
    const { roles, form } = props
    const appraiserRoles = get(appraiser, 'roles', [])
    const signReport = !checkUserHasInspectorRoleOnly(roles.INSPECTOR, appraiserRoles)

    if (appraiser.isExternal) {
      form.mutators.push('externalInspectors', appraiser)
    } else {
      form.mutators.push('selectedAppraisers', { ...appraiser, signReport })
    }
  }
  const onRemoveAppraiser = id => {
    const { form } = props
    const newSelectedAppraisers = selectedAppraisers.filter(appraiser => appraiser.id !== id)
    const newExternalInspectors = externalInspectors.filter(appraiser => appraiser.id !== id)

    form.batch(() => {
      form.change('selectedAppraisers', newSelectedAppraisers)
      form.change('externalInspectors', newExternalInspectors)
    })
  }
  const onInspectedClick = id => {
    const { fields } = props
    const fieldIndex = getFieldIndexByAppraiserId(id)
    const appraiser = fields.value[fieldIndex]
    fields.update(fieldIndex, { ...appraiser, isInspected: !appraiser.isInspected })
  }

  const onSignReportClick = inspector => {
    const { fields } = props
    const fieldIndex = getFieldIndexByAppraiserId(inspector.id)
    const appraiser = appraisersList[fieldIndex]
    if (inspector.isExternal) {
      const updatedExternalInspectors = externalInspectors.map(appraiser => {
        if (appraiser.id === inspector.id) {
          appraiser.signReport = !appraiser.signReport
        }
        return appraiser
      })

      form.change('externalInspectors', updatedExternalInspectors)
    } else {
      fields.update(fieldIndex, { ...appraiser, signReport: !appraiser.signReport })
    }
  }
  const onLeadAppraiserChange = event => {
    const { fields, appraisers } = props

    const leadAppraisers = getLeadAppraisers(appraisers, selectedAppraisers)
    const newLeadAppraiser = find(leadAppraisers, appraiser => appraiser.id === event.target.value)
    const currentLeadAppraiser = find(selectedAppraisers, 'isLeadAppraiser')
    const fieldIndex = getFieldIndexByAppraiserId(currentLeadAppraiser.id)

    fields.update(fieldIndex, { ...newLeadAppraiser, signReport: true })
  }

  const leadAppraisersLabelValuePairs = getLeadAppraisersLabelValuePairs(appraisers, selectedAppraisers)
  const appraisersSuggestions = getSearchAppraisersSuggestions(appraisers, appraisersList)
  const sortedAppraisers = sortAppraisers(appraisersList)

  const freddieCalloutContent =
    'The appraisal must be signed and inspected by a state certified appraiser who is approved by the Seller/Servicer.'

  return (
    <>
      <Stack data-qa="appraisers-section-tile" spacing={2} component={isJobDetails ? '' : Paper}>
        {isJobDetails && (
          <Box sx={{ textAlign: 'right' }}>
            <Button data-qa="addAppraiserInspector" variant="contained" onClick={openModal}>
              Add appraiser / inspector
            </Button>
          </Box>
        )}
        <Stack spacing={3}>
          <AppraisersTable
            form={form}
            appraisers={sortedAppraisers}
            onRemoveAppraiser={onRemoveAppraiser}
            onInspectedClick={onInspectedClick}
            onSignReportClick={onSignReportClick}
            onLeadAppraiserChange={onLeadAppraiserChange}
            leadAppraisersLabelValuePairs={leadAppraisersLabelValuePairs}
            reportState={reportState}
            currentPath={currentPath}
            userInJobDetailsSection={isJobDetails}
          />
          {isFreddieMac && <Callout content={freddieCalloutContent} variant="info" sx={{ mb: 1 }} />}

          {!isJobDetails && (
            <React.Fragment>
              <NarrativeComponent
                padded={false}
                title="Certification Assistance"
                generatedText={certificationAssistance.generate}
                data={certificationAssistance.mapDTO({ ...form.values, isFreddieMac, appraisers: sortedAppraisers })}
                form={form}
                name="certificationAssistance"
              />
              <NarrativeComponent
                title="Certification Inspection"
                generatedText={certificationInspection.generate}
                data={certificationInspection.mapDTO({ ...form.values, isFreddieMac, appraisers: sortedAppraisers })}
                name="certificationInspection"
              />
            </React.Fragment>
          )}
        </Stack>
      </Stack>

      {isOpenModal && (
        <AddAppraiserModal
          closeModal={closeModal}
          isOpenModal={isOpenModal}
          onAddAppraiser={onAddAppraiser}
          appraisersSuggestions={appraisersSuggestions}
        />
      )}
    </>
  )
}

AppraisersTableContainer.propTypes = {
  appraisers: PropTypes.arrayOf(
    PropTypes.shape({
      fullName: PropTypes.string.isRequired,
      educationRequirementsCompleted: PropTypes.bool,
      suffix: PropTypes.string,
      stateCertificationNumber: PropTypes.string,
      designation: PropTypes.string,
      isLeadAppraiser: PropTypes.bool,
      isInspected: PropTypes.bool,
      isJobDetails: PropTypes.bool,
    })
  ).isRequired,
  isFreddieMac: PropTypes.bool.isRequired,
  currentPath: PropTypes.string.isRequired,
}

export default connect(state => {
  const currentPath = get(state, 'shared.location.form.id')
  const roles = get(state, 'authentication.roles', [])
  const rolesByConstant = getRoleIdsByConstant(roles)
  return {
    roles: rolesByConstant,
    currentPath,
  }
})(AppraisersTableContainer)
