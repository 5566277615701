import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import { Field } from 'react-final-form'
import Chip from '@material-ui/core/Chip'
import { isNil, get, take, takeRight, takeWhile, trimStart, noop, isEmpty, trim, isEqual } from 'lodash'
import {
  FormControl,
  FormHelperText,
  FormLabel,
  RootRef,
  InputBase,
  Checkbox,
  ClickAwayListener,
  Tooltip,
  Button,
  withStyles,
} from '@material-ui/core'
import ArrowDropDown from '@mui/icons-material/ArrowDropDown'

import Portal from '../Portal'

const MENU_ITEM_HEIGHT = 32
const SHOWN_MENU_ITEM_COUNT = 4

const COMPONENT_MARGIN = 44
const CHIP_MARGIN = 46
const ICON_WIDTH = 22
const CHARACTER_WIDTH = 6.2
const ADDITIONAL_CHIP_WIDTH = 54

const COMMA_SPLITTING_REGEX = /[,;]+/

const greyTextColor = 'rgba(0, 0, 0, 0.54)'

const styles = theme => ({
  selectWrapper: {
    position: 'relative',
    display: 'inline-flex',
    flexDirection: 'column',
    marginTop: theme.spacing.unit * 2,
    marginBottom: theme.spacing.unit / 2,
    outline: 'none',
    backgroundColor: theme.palette.common.white,
    '&:focus-within $select': {
      padding: '3px 39px 3px 11px',
      borderWidth: 2,
      borderColor: theme.palette.primary.main,
    },
    '&:focus-within $selectButton': {
      fill: theme.palette.primary.main,
    },
  },
  errorSelect: {
    '& $select, &:focus-within $select, &:hover $select': {
      borderColor: theme.palette.error.main,
    },
  },
  select: {
    zIndex: 2,
    display: 'flex',
    flexWrap: 'nowrap',
    height: 49,
    boxSizing: 'border-box',
    padding: '4px 30px 4px 12px',
    borderStyle: 'solid',
    borderWidth: 1,
    borderRadius: 4,
    borderColor: 'rgba(0, 0, 0, 0.23)',
    backgroundColor: theme.palette.common.white,
    cursor: 'pointer',
    outline: 'none',
    '&:hover': {
      borderColor: theme.palette.primary.main,
    },
  },
  opened: {
    height: 'auto',
    flexWrap: 'wrap',
  },
  selectLabel: {
    zIndex: 3,
    position: 'absolute',
    transformOrigin: 'top left',
    transform: 'translateX(8px) translateY(-8px) scale(0.75)',
    color: greyTextColor,
    padding: '0 8px',
    backgroundColor: theme.palette.common.white,
  },
  selectButton: {
    zIndex: 3,
    position: 'absolute',
    right: 0,
    padding: 4,
    top: '50%',
    transform: 'translateY(-50%)',
    cursor: 'pointer',
    color: greyTextColor,
    minWidth: 'auto',
    backgroundColor: 'rgba(0,0,0,0)',
    '&:hover': {
      backgroundColor: 'rgba(0,0,0,0)',
    },
  },
  closeSelectButton: {
    transform: 'translateY(-50%) rotate(180deg)',
  },

  placeholder: {
    color: theme.palette.secondary[500],
    padding: 10,
  },
  chip: {
    margin: theme.spacing.unit / 2,
    borderRadius: 6,
    maxWidth: 445,
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: 'rgb(206, 206, 206)',
    },
  },
  chipIcon: {
    '&, & svg': {
      fontSize: 14,
      padding: '0 6px 0 3px',
      width: 20,
      height: 20,
    },
  },
  chipLabel: {
    display: 'inline',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  chipDeleteIcon: {
    fontSize: 18,
    color: 'rgba(0, 0, 0, 0.38)',
  },
  input: {
    padding: '0 5px',
    caretColor: theme.palette.primary[500],
    height: MENU_ITEM_HEIGHT,
    margin: theme.spacing.unit / 2,
  },
  tooltip: {
    padding: 0,
  },
  tooltipItem: {
    margin: 0,
    padding: 0,
    fontSize: 13,
  },

  paper: {
    padding: '8px 0',
    borderRadius: 4,
    backgroundColor: theme.palette.common.white,
    boxShadow: '0px 1px 5px 0px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 3px 1px -2px rgba(0,0,0,0.12)',
  },
  errorPaper: {
    marginTop: -20,
  },
  menu: {
    maxHeight: MENU_ITEM_HEIGHT * SHOWN_MENU_ITEM_COUNT,
    overflow: 'auto',
    '&::-webkit-scrollbar': {
      width: 3,
    },
    '&::-webkit-scrollbar-track': {
      position: 'absolute',
    },
    '&::-webkit-scrollbar-thumb': {
      width: 3,
      backgroundColor: theme.palette.secondary[500],
      borderRadius: 2,
    },
  },
  menuItem: {
    display: 'flex',
    height: MENU_ITEM_HEIGHT,
    padding: theme.spacing.unit / 2,
    boxSizing: 'border-box',
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: theme.palette.secondary[200],
    },
  },
  selectedMenuItem: {
    backgroundColor: theme.palette.primary[50],
    '& $menuItemCheckbox': {
      color: theme.palette.primary[900],
    },
  },
  currentMenuItem: {
    backgroundColor: theme.palette.secondary[200],
  },
  menuItemCheckbox: {
    padding: '0 16px 0 12px',
  },
  menuItemLabel: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    lineHeight: '24px',
  },
  emptyMenu: {
    fontFamily: theme.typography.fontFamily,
    paddingLeft: 50,
    margin: '5px 0',
  },
  formControl: {
    minHeight: 84,
  },
})

const PROPS = {
  label: PropTypes.string,
  placeholder: PropTypes.string,
  items: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
      icon: PropTypes.element,
    })
  ).isRequired,

  handleChange: PropTypes.func,
  itemLabelSelector: PropTypes.func,
  noResultsText: PropTypes.string,
  isNonPredefinedValueAllowable: PropTypes.bool,
  maxChips: PropTypes.number,
  required: PropTypes.bool,
  withIcons: PropTypes.bool,
}

const DEFAULT_PROPS = {
  label: null,
  placeholder: null,
  items: [],
  noResultsText: null,
  handleChange: noop,
  itemLabelSelector: label => label,
  isNonPredefinedValueAllowable: false,
  maxChips: null,
  required: false,
  withIcons: false,
}

class MultipleDropDown extends React.Component {
  constructor(props) {
    super(props)
    this.containerRef = React.createRef()
    this.inputRef = React.createRef()
    this.menuRef = React.createRef()
  }

  static propTypes = {
    classes: PropTypes.object.isRequired,
    input: PropTypes.shape({
      name: PropTypes.string,
      value: PropTypes.array,
      onChange: PropTypes.func,
    }).isRequired,
    ...PROPS,
  }

  static defaultProps = DEFAULT_PROPS

  state = {
    isOpen: false,
    inputText: '',
    matches: [],
    currentItemIndex: 0,
    shownChipCount: 0,
  }

  componentDidMount() {
    const { items, input } = this.props
    this.updateShownChipCount(input.value)
    this.setState({ matches: items })
  }

  componentDidUpdate(prevProps) {
    const { items } = this.props
    if (!isEqual(items, prevProps.items)) {
      this.setState({ matches: items })
    }
  }

  open = () => {
    this.setState({ isOpen: true })
  }

  close = () => {
    this.setState({ isOpen: false })
    this.resetSearch()
    this.setSelectedMenuItem(0)

    const { value } = this.props.input
    this.updateShownChipCount(value)
  }

  onSelectButtonClick = event => {
    this.setState(prevState => ({ isOpen: !prevState.isOpen }))
    this.resetSearch()
    this.setSelectedMenuItem(0)
    event.stopPropagation()
  }

  resetSearch = () => {
    const { items } = this.props
    this.setState({ inputText: '', matches: items })
  }

  calculateChipWidth = (text = '') => {
    const { withIcons } = this.props
    const spaceAroundLabel = CHIP_MARGIN + (withIcons ? ICON_WIDTH : 0)
    return text.length * CHARACTER_WIDTH + spaceAroundLabel
  }

  onValueSelection = value => {
    const { input } = this.props

    const result = input.value.slice()

    if (result.includes(value)) {
      result.splice(result.indexOf(value), 1)
    } else {
      result.push(value)
    }

    this.changeValues(result)
  }

  onInputTextSelect = value => {
    const { input, items, isNonPredefinedValueAllowable } = this.props

    if (isNil(value)) {
      return
    }

    const result = input.value.slice()

    const newValue = value.split(COMMA_SPLITTING_REGEX).map(trim).filter(Boolean)

    newValue.forEach(val => {
      const selectedItem = items.find(item => item.label.toLowerCase() === val.toLowerCase())

      if (selectedItem) {
        if (result.includes(selectedItem.value)) {
          result.splice(result.indexOf(selectedItem.value), 1)
        } else {
          result.push(selectedItem.value)
        }
      } else if (isNonPredefinedValueAllowable) {
        result.push(val)
      }
    })

    this.changeValues(result)
  }

  changeValues = values => {
    const { input, handleChange } = this.props
    const { isOpen } = this.state

    input.onChange(values)
    handleChange(values)
    this.resetSearch()

    if (!isOpen) {
      values.length ? this.updateShownChipCount(values) : this.open()
    }

    if (this.inputRef.current) {
      this.inputRef.current.focus()
    }
  }

  updateShownChipCount = values => {
    const { itemLabelSelector, maxChips } = this.props

    const labels = values.map(itemLabelSelector)
    const componentWidth = get(this.containerRef, 'current.offsetWidth', 0)
    const widthForChips = componentWidth - COMPONENT_MARGIN

    if (isEmpty(values)) {
      return this.setState({ shownChipCount: 0 })
    }

    const shownChips = takeWhile(labels, (value, index) => {
      const labelsBefore = take(labels, index + 1)
      const labelWidths = labelsBefore.map(this.calculateChipWidth)
      const labelChipsWidth = labelWidths.reduce((acc, item) => acc + item, 0)

      const shouldDisplayMoreChips = labels.length > index + 1
      const hasNoSpaceForAdditionalChip = widthForChips - labelChipsWidth <= ADDITIONAL_CHIP_WIDTH
      const shouldDisplayAdditionalSpace = shouldDisplayMoreChips && hasNoSpaceForAdditionalChip

      const widthForLabelChips = shouldDisplayAdditionalSpace ? widthForChips - ADDITIONAL_CHIP_WIDTH : widthForChips

      return labelChipsWidth <= widthForLabelChips || index === 0
    })

    const shownChipCount = shownChips.length

    if (maxChips && shownChipCount > maxChips) {
      return this.setState({ shownChipCount: maxChips })
    }

    this.setState({ shownChipCount })
  }

  changeCurrentItemIndex = diff => {
    const { currentItemIndex, matches } = this.state

    const menuList = get(this, 'menuRef.current.firstElementChild')
    if (!menuList) {
      return
    }
    let index = isNil(currentItemIndex) ? 0 : currentItemIndex + diff

    if (index < 0) {
      index = 0
      return this.setSelectedMenuItem(index || 0)
    } else if (index === matches.length) {
      index = matches.length - 1
      return this.setSelectedMenuItem(index || 0)
    }

    if (matches.length - index < SHOWN_MENU_ITEM_COUNT) {
      menuList.scrollTop = MENU_ITEM_HEIGHT * (matches.length - SHOWN_MENU_ITEM_COUNT)
    } else {
      menuList.scrollTop = MENU_ITEM_HEIGHT * index
    }

    this.setState({ currentItemIndex: index })
  }

  renderInput = () => {
    const { classes, input } = this.props
    const { inputText } = this.state

    return (
      <InputBase
        key="input"
        autoFocus
        className={classes.input}
        inputRef={this.inputRef}
        value={inputText}
        onChange={this.inputTextChange}
        onBlur={() => input.onBlur()}
        onFocus={() => input.onFocus()}
        data-qa="input"
      />
    )
  }

  renderChip = value => {
    const { classes, items, isNonPredefinedValueAllowable } = this.props
    const predefinedItemInfo = items.find(item => item.value === value)

    const shouldRenderChip = value !== undefined && (predefinedItemInfo || isNonPredefinedValueAllowable)
    const shouldShowTooltip = isNonPredefinedValueAllowable && !predefinedItemInfo
    if (!shouldRenderChip) {
      return null
    }

    const label = get(predefinedItemInfo, 'label', value)
    const icon = get(predefinedItemInfo, 'icon', null)

    const chip = (
      <Chip
        key={value}
        name="chip"
        label={label}
        icon={icon}
        data-qa={`${value}-chip`}
        onDelete={event => {
          if (!event.shouldBeIgnored) {
            this.onValueSelection(value)
          }
        }}
        classes={{
          root: classes.chip,
          icon: classes.chipIcon,
          label: classes.chipLabel,
          deleteIcon: classes.chipDeleteIcon,
        }}
      />
    )

    return shouldShowTooltip ? (
      <Tooltip
        key={value}
        placement="top"
        title={
          <p key={label} className={classes.tooltipItem}>
            {`${value} is not a recognized use.`}
          </p>
        }
      >
        {chip}
      </Tooltip>
    ) : (
      chip
    )
  }

  renderHiddenChipCount = hiddenChipLabels => {
    const { classes } = this.props

    return hiddenChipLabels.length ? (
      <Tooltip
        key="tooltip"
        placement="top"
        title={
          <div className={classes.tooltip}>
            {hiddenChipLabels.map(label => {
              return (
                <p key={label} className={classes.tooltipItem}>
                  {label}
                </p>
              )
            })}
          </div>
        }
      >
        <Chip
          label={`+${hiddenChipLabels.length}`}
          classes={{
            root: classes.chip,
            label: classes.chipLabel,
          }}
          data-qa="hidden-chip-count"
        />
      </Tooltip>
    ) : null
  }

  renderSelectedValues = () => {
    const { classes, placeholder, input, itemLabelSelector } = this.props
    const { isOpen, shownChipCount } = this.state

    const chipCount = isOpen ? input.value.length : shownChipCount

    if (!chipCount && !isOpen) {
      return <span className={classes.placeholder}>{placeholder}</span>
    }

    const elements = take(input.value, chipCount).map(this.renderChip)

    if (isOpen) {
      elements.push(this.renderInput())
    } else {
      const hiddenChipCount = input.value.length - chipCount
      const hiddenChipLabels = takeRight(input.value, hiddenChipCount).map(itemLabelSelector)
      elements.push(this.renderHiddenChipCount(hiddenChipLabels))
    }

    return elements
  }

  onKeyDown = event => {
    const { isOpen, currentItemIndex, matches, inputText } = this.state
    const inputElement = this.inputRef.current

    switch (event.key) {
      case 'Enter':
        event.preventDefault()
        if (!isOpen) {
          this.open()
        } else {
          const selectedItem = matches[currentItemIndex]
          if (selectedItem) {
            this.onValueSelection(selectedItem.value)
          } else if (inputText) {
            this.onInputTextSelect(inputText)
          }
        }
        break
      case 'Escape':
        return this.close()
      case 'ArrowDown':
        return this.changeCurrentItemIndex(1)
      case 'ArrowUp':
        return this.changeCurrentItemIndex(-1)
      case 'Backspace':
        if (inputElement && !inputText) {
          if (inputElement !== document.activeElement) {
            event.shouldBeIgnored = false
            break
          }

          const lastChip = inputElement.parentElement.previousSibling
          if (lastChip) {
            event.shouldBeIgnored = true
            lastChip.focus()
          }
        }
        break
      case 'Tab':
        if (document.activeElement === inputElement) {
          this.close()
        }
        break
      default:
    }
  }

  onClickAway = event => {
    const menu = this.menuRef.current

    const shouldBeClosed = menu && (!menu.contains(event.target) || menu === event.target)
    if (shouldBeClosed) {
      this.close()
    }
  }

  setSelectedMenuItem = index => {
    this.setState({ currentItemIndex: index })
  }

  inputTextChange = event => {
    const value = trimStart(event.target.value)
    const { items, isNonPredefinedValueAllowable } = this.props

    const matches = value ? items.filter(item => item.label.toLowerCase().includes(value.toLowerCase().trim())) : items
    const newCurrentItemIndex = isNonPredefinedValueAllowable ? null : 0
    this.setState({ inputText: value, matches, currentItemIndex: newCurrentItemIndex })
  }

  renderMenu = () => {
    const { classes, width, input, noResultsText, meta, disablePortal } = this.props
    const { touched, error } = meta || {}
    const { isOpen, matches, currentItemIndex } = this.state

    const inErrorState = touched && !!error

    if (!isOpen) {
      return null
    }

    const containerWidth = get(this.containerRef, 'current.offsetWidth', 0)
    const containerHeight = get(this.containerRef, 'current.offsetHeight', 0)
    const menu = (
      <div ref={this.menuRef} className={classNames(classes.paper, { [classes.errorPaper]: inErrorState })}>
        <div className={classes.menu} data-qa={`${input.name}-menu`}>
          {matches.length ? (
            matches.map((item, index) => {
              const isSelected = input.value.includes(item.value)

              return (
                <div
                  key={item.value}
                  onMouseOver={() => this.setSelectedMenuItem(index)}
                  onClick={() => this.onValueSelection(item.value)}
                  className={classNames(classes.menuItem, {
                    [classes.selectedMenuItem]: isSelected,
                    [classes.currentMenuItem]: index === currentItemIndex,
                  })}
                >
                  <Checkbox
                    checked={isSelected}
                    value={isSelected.toString()}
                    className={classes.menuItemCheckbox}
                    data-qa={item.value}
                  />
                  <span className={classes.menuItemLabel}>{item.label}</span>
                </div>
              )
            })
          ) : (
            <p key="no-results" className={classNames(classes.emptyMenu)} data-qa="no-results">
              {noResultsText}
            </p>
          )}
        </div>
      </div>
    )

    return disablePortal ? (
      <div style={{ top: containerHeight, position: 'absolute', zIndex: 1301 }}>{menu}</div>
    ) : (
      <Portal zIndex={1301} width={width || containerWidth}>
        {menu}
      </Portal>
    )
  }

  render() {
    const { classes, label, input, required, meta } = this.props
    const { touched, error } = meta || {}
    const { isOpen } = this.state

    const inErrorState = touched && !!error
    const errorText = inErrorState ? error : ''

    return (
      <RootRef rootRef={this.containerRef}>
        <ClickAwayListener onClickAway={this.onClickAway}>
          <FormControl
            variant="outlined"
            fullWidth
            required={required}
            error={inErrorState}
            data-qa={`${input.name}-form-control`}
            className={classes.formControl}
          >
            <div
              tabIndex={0}
              className={classNames(classes.selectWrapper, { [classes.errorSelect]: inErrorState })}
              onClick={this.open}
              onKeyDown={this.onKeyDown}
            >
              <FormLabel className={classes.selectLabel}>{label}</FormLabel>
              <Button
                onClick={this.onSelectButtonClick}
                className={classNames(classes.selectButton, {
                  [classes.closeSelectButton]: isOpen,
                })}
                data-qa={`${input.name}-arrow-button`}
              >
                <ArrowDropDown />
              </Button>
              <div
                className={classNames(classes.select, {
                  [classes.opened]: isOpen,
                })}
                data-qa="chips-wrapper"
              >
                {this.renderSelectedValues()}
              </div>
              {inErrorState && <FormHelperText>{errorText}</FormHelperText>}
            </div>
            {this.renderMenu()}
          </FormControl>
        </ClickAwayListener>
      </RootRef>
    )
  }
}

const MultipleDropDownWithStyles = withStyles(styles)(MultipleDropDown)

export const MultipleDropDownComponent = ({ value, onChange, onBlur, onFocus, ...otherProps }) => {
  const input = { value, onChange, onBlur, onFocus }
  return <MultipleDropDownWithStyles input={input} {...otherProps} />
}

MultipleDropDownComponent.propTypes = {
  value: PropTypes.array.isRequired,
  onChange: PropTypes.func.isRequired,
  onBlur: PropTypes.func,
  onFocus: PropTypes.func,
}

MultipleDropDownComponent.defaultProps = {
  onBlur: noop,
  onFocus: noop,
}

export default class extends React.PureComponent {
  static displayName = 'MultipleDropDown'

  static propTypes = {
    name: PropTypes.string.isRequired,
    ...PROPS,
  }

  static defaultProps = DEFAULT_PROPS

  render() {
    const { name, label, ...props } = this.props
    return (
      <Field
        name={name}
        label={label}
        component={MultipleDropDownWithStyles}
        format={value => value || []}
        {...props}
      />
    )
  }
}
