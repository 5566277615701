import React from 'react'
import PropTypes from 'prop-types'

import CloudUpload from '@mui/icons-material/CloudUpload'
import { grey } from '@mui/material/colors'
import { withStyles } from '@mui/styles'
import ReactDropzone from 'react-dropzone'

const styles = theme => ({
  dropzone: {
    textAlign: 'center',
    width: '100%',
    display: 'flex',
    height: 'inherit',
    flex: 1,
  },
  dropzoneIcon: {
    fontSize: 72,
    color: grey[500],
    width: 36,
    height: 36,
  },
  dropzoneText: {
    fontSize: theme.typography.fontSize,
    fontFamily: theme.typography.fontFamily,
  },
  dropzoneArea: {
    '&:hover': {
      border: '1px solid #41647d',
      backgroundColor: '#d5dce1',
      cursor: 'pointer',
    },
    width: '100%',
    border: '1px dashed gray',
    borderRadius: 3,
  },
  clickArea: {
    flex: 1,
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
  },
})

const DropzoneComponent = ({ classes, onDrop, dropZoneRef, helperText, ...props }) => {
  return (
    <ReactDropzone className={classes.dropzone} ref={dropZoneRef} onDrop={onDrop} {...props}>
      <div className={classes.dropzoneArea}>
        <div className={classes.clickArea}>
          <CloudUpload className={classes.dropzoneIcon} />
          <span className={classes.dropzoneText}>{helperText}</span>
        </div>
      </div>
    </ReactDropzone>
  )
}

const Dropzone = withStyles(styles)(DropzoneComponent)

Dropzone.displayName = 'Dropzone'

Dropzone.propTypes = {
  onDrop: PropTypes.func.isRequired,
  helperText: PropTypes.string,
}

Dropzone.defaultProps = {
  helperText: 'Click here or drag file to upload',
}

export default Dropzone
