import { GeneratedText } from '../../../narrative/GeneratedText'

import { mapDTO, mapDataModel } from './mappings'

export const CONSTRUCTION_COST_COMPS_TEXT =
  'To further test the MVS forecast, we have also surveyed the following construction cost comparables from the vicinity, which are inclusive of all direct (including site costs) and indirect costs:'

const generate = () => {
  const gt = new GeneratedText()

  gt.t`${CONSTRUCTION_COST_COMPS_TEXT}`

  return gt.getParts()
}

export default { generate, mapDTO, mapDataModel }
