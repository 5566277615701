import { MONTHS } from 'shared/constants/expenses'
import { DEFAULT_EXPENSES, EXPENSE_IDS_MAP } from 'shared/constants/expenses/expenseForecast'
import { EXPENSE_HISTORY_TYPE_TITLES } from 'shared/constants/expenses/expenseHistory'

import { arrayToKeyValuePairs } from '../ui/checkboxHelper'
import { PropertyTypes as PT } from '../../../../../shared/constants'
import { DEFAULT_EXPENSES_KEYS } from '../../../../../shared/constants/expenses'

const invertObject = obj => {
  const inverse = {}

  Object.keys(obj).forEach(key => {
    const value = obj[key]
    inverse[value] = key
    return key
  })

  return inverse
}

export const INVERTED_EXPENSES = invertObject(DEFAULT_EXPENSES)

export const OTHER_EXPENSES = [
  'Address',
  'Source of Information',
  'Source Name',
  'Source URL',
  'Location',
  'Expense Period',
  'Expense Year',
  'Expense Month',
  'Square Feet',
  'Residential Units',
  'Egi',
]

export const OTHER_FREDDIE_MAC_EXPENSES = [
  'Address',
  'Source of Information',
  'Source Name',
  'Source URL',
  'Location',
  'Expense Period',
  'Expense Year',
  'Expense Month',
  'Square Feet',
  'Residential Units',
  'Property Type',
  'Year Built',
  'Egi',
]

export const TOTAL_EXPENSE_ROWS = [
  'Total Operating Expenses',
  'Total Operating Expenses Per SF',
  'Total Operating Expenses Per Unit',
]

export const EXPENSE_HISTORY_KEYS = ['Actual', 'Actual T12', 'Annualized Historical', 'Projection']

export const EXPENSE_PERIOD_VALUES = arrayToKeyValuePairs([
  EXPENSE_HISTORY_TYPE_TITLES.ACTUAL,
  EXPENSE_HISTORY_TYPE_TITLES.ACTUAL_T_12,
  EXPENSE_HISTORY_TYPE_TITLES.ANNUALIZED_HISTORICAL,
  EXPENSE_HISTORY_TYPE_TITLES.PROJECTION,
])

export const BUILDING_TYPES = [
  { label: 'Any', value: '' },
  { label: 'Mixed-Use', value: PT.MIXED_USE },
  { label: 'Multifamily', value: PT.MULTI_FAMILY },
  { label: 'Commercial', value: PT.COMMERCIAL },
]

export const EXPENSE_PERIODS_WITHOUT_PROJECTED = arrayToKeyValuePairs([
  EXPENSE_HISTORY_TYPE_TITLES.ACTUAL,
  EXPENSE_HISTORY_TYPE_TITLES.ACTUAL_T_12,
  EXPENSE_HISTORY_TYPE_TITLES.ANNUALIZED_HISTORICAL,
])

const DEFAULT_EXPENSES_PROPERTIES = [
  {
    searchResultName: DEFAULT_EXPENSES_KEYS.insurance,
    name: 'insurance',
    title: DEFAULT_EXPENSES.insurance,
  },
  {
    searchResultName: DEFAULT_EXPENSES_KEYS.electricity,
    name: 'electricity',
    title: DEFAULT_EXPENSES.electricity,
  },
  {
    searchResultName: DEFAULT_EXPENSES_KEYS.fuel,
    name: 'fuel',
    title: DEFAULT_EXPENSES.fuel,
  },
  {
    searchResultName: DEFAULT_EXPENSES_KEYS.waterAndSewer,
    name: 'waterAndSewer',
    title: DEFAULT_EXPENSES.waterAndSewer,
  },
  {
    searchResultName: DEFAULT_EXPENSES_KEYS.repairsAndMaintenance,
    name: 'repairsAndMaintenance',
    title: DEFAULT_EXPENSES.repairsAndMaintenance,
  },
  {
    searchResultName: DEFAULT_EXPENSES_KEYS.payrollAndBenefits,
    name: 'payrollAndBenefits',
    title: DEFAULT_EXPENSES.payrollAndBenefits,
  },
  {
    searchResultName: DEFAULT_EXPENSES_KEYS.generalAndAdministrative,
    name: 'generalAndAdministrative',
    title: DEFAULT_EXPENSES.generalAndAdministrative,
  },
  {
    searchResultName: DEFAULT_EXPENSES_KEYS.legalAndProfessionalFees,
    name: 'legalAndProfessionalFees',
    title: DEFAULT_EXPENSES.legalAndProfessionalFees,
  },
  {
    searchResultName: DEFAULT_EXPENSES_KEYS.miscellaneous,
    name: 'miscellaneous',
    title: DEFAULT_EXPENSES.miscellaneous,
  },
  {
    searchResultName: DEFAULT_EXPENSES_KEYS.management,
    name: 'management',
    title: DEFAULT_EXPENSES.management,
  },
  {
    searchResultName: DEFAULT_EXPENSES_KEYS.reserves,
    name: 'reserves',
    title: DEFAULT_EXPENSES.reserves,
  },
]

export const EXPENSE_STRUCTURES = ['Tenant', 'Owner', 'Varies By Rent Type', 'Do Not Discuss']
export const EXPENSE_STRUCTURES_AFTER_BOTH = ['Tenant', 'Owner', 'Do Not Discuss']
export default DEFAULT_EXPENSES_PROPERTIES

export const MONTH_OPTIONS = arrayToKeyValuePairs(MONTHS)

export const MONTH_KEYS = {
  JANUARY: 'January',
  FEBRUARY: 'February',
  MARCH: 'March',
  APRIL: 'April',
  MAY: 'May',
  JUNE: 'June',
  JULY: 'July',
  AUGUST: 'August',
  SEPTEMBER: 'September',
  OCTOBER: 'October',
  NOVEMBER: 'November',
  DECEMBER: 'December',
}

export const MONTH_TO_NUMBER = {
  january: 1,
  february: 2,
  march: 3,
  april: 4,
  may: 5,
  june: 6,
  july: 7,
  august: 8,
  september: 9,
  october: 10,
  november: 11,
  december: 12,
}

export {
  /** @deprecated Prefer importing this from the shared folder */
  MONTHS,
  EXPENSE_IDS_MAP,
  DEFAULT_EXPENSES,
}
