import React from 'react'
import { Route, Switch } from 'react-router'

import CmsContainer from './forms/CmsContainer'

import ChipBankContainer from './chipBank/forms/ChipBankContainer.tsx'

class Router extends React.Component {
  render() {
    return (
      <React.Fragment>
        <Switch>
          <Route path="/cms/chip-bank" component={ChipBankContainer} />
          <Route path="/cms/:id?/" component={CmsContainer} />
        </Switch>
      </React.Fragment>
    )
  }
}
export default Router
