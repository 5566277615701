import { get } from 'lodash'

const getFormById = formId => document.getElementById(`${formId}-final-form`)

export const submit = formId => {
  const form = getFormById(formId)
  if (form) {
    form.dispatchEvent(new Event('submit', { cancelable: true, bubbles: true }))
  }
}

export const willShowSaveModal = formId => {
  const form = getFormById(formId)
  if (form) {
    const showSaveModal = form.getAttribute('show-save-modal')
    return showSaveModal === 'true'
  }
}

export const isAnyFieldDirtyAndVisited = (dirtyFields, visited, values) => {
  for (const key in dirtyFields) {
    if (dirtyFields[key] && visited[key]) {
      return true
    }

    if (dirtyFields[key] && Array.isArray(get(values, key))) {
      return true
    }
  }

  return false
}

export const isFormInvalid = formId => {
  const form = getFormById(formId)
  if (form) {
    const isInvalid = form.getAttribute('invalid')

    return isInvalid === 'true'
  }
}
