const numberStyles = {
  '& div': {
    paddingLeft: 0,
    height: 20,
    fontFamily: 'Nunito Sans',
    marginTop: 0,
  },
  '& fieldset': {
    display: 'none',
  },
  height: 36,
  marginTop: 0,
  marginBottom: 0,
  paddingTop: 0,
  paddingBottom: 0,
  fontSize: 14,
}

export default theme => {
  const textElements = '& td, input, span, div, p, li'
  const numberCell = {
    '& input': {
      padding: 0,
      textAlign: 'right',
    },
    ...numberStyles,
    '& div:first-child': {
      'margin-top': 0,
    },
    textAlign: 'right',
  }
  const header = {
    borderColor: '#FOF4F9',
    borderRadius: 4,
    backgroundColor: '#f8fafc',
    height: 36,
    borderBottom: 'none',
  }
  const headerCell = {
    fontSize: 12,
    fontFamily: 'Nunito Sans',
    color: '#222222',
    fontWeight: 'bold',
    backgroundColor: '#f8fafc',
    textAlign: 'right',
    borderBottom: 'none',
    paddingRight: 16,
    minWidth: 200,
  }
  const tableContainer = {
    fontFamily: 'Nunito Sans',
    width: '100%',
    borderColor: '#FOF4F9',
    borderWidth: '1px',
    borderStyle: 'solid',
    overflow: 'hidden',
    border: `1px solid #F0F4F9`,
    borderRadius: 4,
    [textElements]: {
      fontSize: '14px',
    },
    '& tfoot': {
      [textElements]: {
        fontWeight: 500,
      },
    },
  }

  const paper = { ...theme.paper }

  return {
    gridContainer: {
      width: '100%',
      paddingBottom: 24,
    },

    paper: {
      ...paper,
      [textElements]: {
        fontFamily: 'Nunito Sans',
      },
    },
    basisPaper: {
      ...paper,
      [textElements]: {
        fontFamily: 'Nunito Sans',
      },
      paddingBottom: 0,
      marginBottom: 0,
    },
    tabContainer: {
      marginBottom: theme.spacing.unit * 2,
      fontFamily: 'Nunito Sans',
      '& button': {
        flexShrink: 0,
      },
    },
    tabLabel: {
      fontSize: 14,
      fontFamily: 'Nunito Sans',
      letterSpacing: 1.3,
      padding: 'inherit',
    },

    subSectionWrapper: {
      paddingBottom: 0,
      marginBottom: 16,
    },

    tableTitleContainer: {
      fontFamily: 'Nunito Sans',
      height: 36,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-start',
    },

    tableContainer,
    removedCompsContainer: {
      padding: 16,
      marginBottom: 24,
    },

    header,
    tableHeaderRow: {
      '& td': {
        fontSize: 12,
      },
      ...header,
    },

    link: {
      paddingLeft: 8,
      paddingRight: 4,
      alignItems: 'center',
    },

    leadHeaderCell: {
      fontSize: 12,
      fontFamily: 'Nunito Sans',
      backgroundColor: '#f8fafc',
      color: '#222222',
      fontWeight: 'bold',
      textAlign: 'left',
      minWidth: 150,
      paddingLeft: 16,
      borderBottom: 'none',
    },

    addressHeaderCell: {
      fontSize: 12,
      fontFamily: 'Nunito Sans',
      color: '#222222',
      fontWeight: 'bold',
      textAlign: 'left',
      minWidth: 200,
      paddingLeft: 16,
      borderBottom: 'none',
    },

    headerCell,
    numberHeaderCell: {
      ...headerCell,
      width: 200,
      minWidth: 100,
    },

    headerCellText: {
      ...headerCell,
      textAlign: 'left',
      width: 100,
      minWidth: 100,
      paddingLeft: 16,
    },

    headerCellActions: {
      ...headerCell,
      textAlign: 'left',
      width: 200,
      minWidth: 100,
      paddingLeft: 16,
    },

    disabledLabelCell: {
      border: `1px solid #F0F4F9`,
      fontSize: 14,
      fontFamily: 'Nunito Sans',
      color: '#617083',
      backgroundColor: '#f8fafc',
      textAlign: 'left',
      height: 36,
    },

    tableRow: {
      fontFamily: 'Nunito Sans',
      borderColor: '#FOF4F9',
      borderRadius: 4,
      height: 36,
    },

    totalRow: {
      fontFamily: 'Nunito Sans',
      fontWeight: 'bold',
      border: `1px solid #F0F4F9`,
      fontSize: 14,

      textAlign: 'left',
      height: 36,
      borderTop: `2px solid ${'#f8fafc'}`,
    },

    disabled: {
      fontFamily: 'Nunito Sans',
      color: '#617083',
      backgroundColor: '#f8fafc',
    },

    totalDisabledLabel: {
      fontFamily: 'Nunito Sans',
      color: '#617083',
      backgroundColor: '#f8fafc',
      fontWeight: 'bold',
      textAlign: 'left',
      borderBottom: 'none',
      height: 36,
      paddingLeft: 16,
    },

    totalDisabled: {
      fontFamily: 'Nunito Sans',
      color: '#617083',
      backgroundColor: '#f8fafc',
      fontWeight: 'bold',
      textAlign: 'right',
      height: 36,
      borderBottom: 'none',
      paddingRight: 16,
    },

    inputNumberCell: {
      ...numberCell,
      backgroundColor: '#ffffff',
      height: 36,
      textAlign: 'right',
      color: '#222222',
      border: `1px solid #F0F4F9`,
      fontSize: 14,
      fontFamily: 'Nunito Sans',
      paddingRight: 16,
      paddingLeft: 16,
    },

    inputTextCell: {
      '& div': {
        paddingLeft: 16,
        textAlign: 'left',
        height: 20,
        fontFamily: 'Nunito Sans',
        marginTop: 0,
      },
      '& input': {
        padding: 0,
        textAlign: 'left',
      },
      '& fieldset': {
        display: 'none',
      },
      height: 36,
      marginTop: 0,
      marginBottom: 0,
      paddingTop: 0,
      paddingBottom: 0,
      fontSize: 14,
      backgroundColor: '#ffffff',
      textAlign: 'left',
      color: '#222222',
      border: `1px solid #F0F4F9`,
      fontFamily: 'Nunito Sans',
      paddingLeft: 16,
    },

    disabledNumberCell: {
      ...numberCell,
      backgroundColor: '#F8FAFC',
      height: 36,
      textAlign: 'right',
      color: '#617083',
      border: `1px solid #F0F4F9`,
      fontSize: 14,
      fontFamily: 'Nunito Sans',
      paddingRight: 16,
    },

    disabledTextCell: {
      backgroundColor: '#F8FAFC',
      height: 36,
      textAlign: 'left',
      color: '#617083',
      border: `1px solid #F0F4F9`,
      fontSize: 14,
      fontFamily: 'Nunito Sans',
      paddingLeft: 16,
    },

    actionsCellContainer: {
      display: 'flex',
      justifyContent: 'left',
      '& div': {},
    },

    checkbox: {
      fontFamily: 'Nunito Sans',
      height: 20,
      paddingBottom: 20,
      textAlign: 'left',
    },

    taxRate: {
      fontFamily: 'Nunito Sans',
      '& input': {
        paddingLeft: 1,
        paddingRight: 0,
        textAlign: 'right',
      },
      ...numberStyles,
      '& div:first-child': {
        'margin-top': 0,
      },
      width: 100,
    },
    numberCell,
    numberCellLeading: {
      ...numberCell,
      textAlign: 'left',
    },

    addressCell: {
      textAlign: 'left',
      height: 36,
      paddingLeft: 16,
    },

    addTaxCompsBtnContainer: {
      height: 84,
      padding: 0,
    },
    addTaxCompsBtn: {
      fontFamily: 'Nunito Sans',
      marginTop: 8,
      height: 56,
      width: '100%',
    },
    addTaxAdvanceBtn: {
      fontFamily: 'Nunito Sans',
      padding: '8px 0 16px 6px !important',
      fontSize: 14,
      fontWeight: 'bold',
    },
    btn: {
      fontFamily: 'Nunito Sans',
      marginRight: 8,
    },
    tooltipIcon: {
      width: 15,
      height: 15,
      paddingLeft: 3,
    },
    tooltipText: {
      fontFamily: 'Nunito Sans',
      fontSize: 12,
    },
    tooltipLink: {
      fontFamily: 'Nunito Sans',
      textDecoration: 'underline',
      color: 'white',
      fontWeight: 400,
    },

    saveDialog: {
      marginRight: 0,
      marginTop: 0,
      paddingRight: 24,
      paddingBottom: 16,
    },
    cancelButton: {
      fontFamily: 'Nunito Sans',
      marginRight: 16,
      textTransform: 'uppercase',
    },
    button: {
      textTransform: 'uppercase',
    },
    discussionContainer: {
      [textElements]: {
        fontFamily: 'Nunito Sans',
      },
      marginTop: 16,
    },

    addBlankRowBtn: { float: 'right', margin: 0 },

    iconButton: {
      fontSize: 6,
    },

    tableContentContainer: {
      height: '24px !important',
      paddingTop: 16,
    },
    sourceOfInformationCell: {
      height: '24px !important',
      paddingTop: 16,
      color: '#9e9e9e',
    },
    compYearBuiltEst: {
      paddingTop: 5,
    },

    title: {
      fontFamily: 'Nunito Sans',
    },
    dialogText: {
      fontFamily: 'Nunito Sans',
      color: theme.palette.text.primary,
    },
    yearBuiltEstHeaderCell: {
      ...headerCell,
      width: 30,
      minWidth: 30,
    },

    isNewCompYearBuiltEst: {
      marginBottom: 19,
      '& label': {
        marginTop: 0,
      },
    },

    subjectCheckbox: {
      marginLeft: 8,
    },

    concludedTaxLiabilityFormControl: {
      height: 'auto',
      minHeight: 'auto',
    },

    concludedTaxLiabilityHelperText: {
      marginBottom: 10,
    },

    removedCompTableCell: {
      paddingLeft: 16,
      paddingRight: 16,
    },
    removedCompCheckboxCell: {
      '& div > label': {
        marginLeft: 0,
      },
    },
    addButton: {
      color: 'white',
      backgroundColor: '#21B586',
      '&:hover': {
        background: '#087461',
      },
      margin: '0 16px',
    },
    removeButton: {
      color: 'white',
      backgroundColor: '#D25441',
      '&:hover': {
        background: '#9E211B',
      },
    },
  }
}
