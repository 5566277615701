import React, { FC, memo, useMemo } from 'react'

import { noop } from 'lodash'
import { ColumnDataTypeEnum, RowBasedTable } from '@bowery-valuation/ui-components'

import { formatCurrencyInt } from 'client-shared/utils/numberFormatters'

import { getCustomColumnConfig, RowBasedTableColumn } from 'client-shared/utils/rowBasedTable'
import { MarshallValuationServiceType } from 'shared/types/costApproachTypes'
import { BuildingComponent } from 'report/forms/property/InsurableValue/types'

type MvsBaseCostEstimateTableOwnProps = {
  marshallValuationService?: MarshallValuationServiceType
  buildingComponents?: BuildingComponent[]
  mvsTotalSiteImprovementsCosts: number
  mvsDirectCost: number
}

const MvsBaseCostEstimateTable: FC<MvsBaseCostEstimateTableOwnProps> = ({
  marshallValuationService,
  buildingComponents = [],
  mvsTotalSiteImprovementsCosts,
  mvsDirectCost,
}) => {
  const getColumns = (): RowBasedTableColumn[] => {
    return [
      {
        name: 'label',
        label: 'Components',
        type: ColumnDataTypeEnum.text,
        permanent: true,
        align: 'left',
        editable: false,
        suppressMovable: true,
      },
      {
        name: 'total',
        label: 'Total',
        type: ColumnDataTypeEnum.text,
        permanent: true,
        align: 'right',
        editable: false,
        suppressMovable: true,
      },
    ]
  }
  const rows = buildingComponents.map((buildingComponent: any, index: number) => {
    return {
      readOnly: false,
      suppressMovable: true,
      permanent: true,
      type: ColumnDataTypeEnum.text,
      id: `buildingComponent_${index}`,
      label: `Building ${index + 1}`,
      total: formatCurrencyInt(buildingComponent.replacementCostNew),
      rowDef: { summary: false, hideAction: true },
    }
  })

  rows.push({
    readOnly: false,
    suppressMovable: true,
    permanent: true,
    type: ColumnDataTypeEnum.text,
    id: 'siteImprovements',
    label: 'Site Improvements / Additions',
    total: formatCurrencyInt(mvsTotalSiteImprovementsCosts),
    rowDef: { summary: false, hideAction: true },
  })
  rows.push({
    readOnly: false,
    suppressMovable: true,
    permanent: true,
    type: ColumnDataTypeEnum.text,
    id: 'total',
    label: 'Total Replacement Cost New',
    total: formatCurrencyInt(mvsDirectCost),
    rowDef: { summary: true, hideAction: true },
  })
  const columns = useMemo(() => getColumns(), [])

  return (
    <RowBasedTable
      id="mvs-base-cost-estimate-table"
      columns={columns}
      rows={rows}
      onRowUpdate={noop}
      onManyRowsUpdate={noop}
      getCustomColumnConfig={getCustomColumnConfig}
      actionCellHidden
      hideIndexColumn
      onColumnDragEnd={noop}
      onRowsDragEnd={noop}
      onColumnDelete={noop}
      onColumnUpdate={noop}
      onRowDelete={noop}
    />
  )
}

export default memo(MvsBaseCostEstimateTable)
