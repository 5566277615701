import { getPartsFromString } from '../../../../narrative/GeneratedText'

const generate = () => {
  const salesComparisonApproachMethodology = `The Sales Comparison Approach is reliable when few differences
  exist between the comparable sales and the subject, and the sales
  data collected is credible and accurate. Similar property types in
  competitive locations tend to sell within a consistent range, and this
  factor makes valuation on a per square foot and per unit basis a strong predictor of value.`
  const gtParts = getPartsFromString(salesComparisonApproachMethodology)

  return gtParts
}

export default { generate }
