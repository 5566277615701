import { isPlainObject, sortBy, toString } from 'lodash'

import { narrativeFormatters } from 'client-shared/utils/narrative'

import { ChipOption } from './types'

export const formatChipValue = (chip: ChipOption): string | string[] | null | unknown => {
  try {
    const formatter = chip.formatter && (narrativeFormatters as Record<string, Function>)[chip.formatter]
    let value = chip.value

    if (formatter) {
      value = formatter(value)
    }

    if (isPlainObject(value)) {
      console.warn('Chip value is an object', value, formatter)
      return null
    }

    return value
  } catch (error) {
    console.warn('Error formatting chip value', error)
    return null
  }
}

export const searchChipOptions = (searchQuery: string, chips: ChipOption[]) => {
  const regex = RegExp(`${searchQuery}`, 'i')
  const matchingOptions: {
    chip: ChipOption
    matchesValue: boolean
    matchesDisplayName: boolean
    matchesGroupName: boolean
    matchesTags: boolean
  }[] = []

  chips.forEach(chip => {
    const { displayName, tags, groupName } = chip
    const matchesDisplayName = regex.test(displayName)
    const matchesGroupName = regex.test(groupName)
    let matchesValue = false
    let matchesTags = false

    let formattedValue = formatChipValue(chip)
    if (formattedValue === undefined || formattedValue === null) {
      formattedValue = ''
    }

    if (Array.isArray(formattedValue)) {
      matchesValue = formattedValue.some(item => item && regex.test(item))
    } else {
      matchesValue = regex.test(toString(formattedValue))
    }

    if (Array.isArray(tags)) {
      matchesTags = tags.some(tag => tag && regex.test(tag))
    }

    if (matchesValue || matchesDisplayName || matchesGroupName || matchesTags) {
      matchingOptions.push({
        chip,
        matchesValue,
        matchesDisplayName,
        matchesGroupName,
        matchesTags,
      })
    }
  })

  const sortedOptions = sortBy(matchingOptions, [
    'matchesTags',
    'matchesGroupName',
    'matchesDisplayName',
    'matchesValue',
    'chip.displayName',
    'chip.groupName',
  ])

  return sortedOptions.map(({ chip }) => {
    return {
      ...chip,
      tags: chip.tags?.filter(tag => regex.test(tag)),
    }
  })
}

const getSelectionLocation = (targetElement?: HTMLElement) => {
  if (targetElement) {
    const targetElementRect = targetElement.getBoundingClientRect()
    return {
      bottom: targetElementRect.bottom,
      left: targetElementRect.left,
    }
  }

  const fallbackLocation = { bottom: 0, left: 0 }

  const selection = window.getSelection()
  if (!selection || selection.rangeCount === 0) {
    const activeElementRect = document.activeElement?.getBoundingClientRect()
    return activeElementRect || fallbackLocation
  }

  const range = selection.getRangeAt(0)
  if (!range) {
    return fallbackLocation
  }

  const rect = range.getBoundingClientRect()
  return {
    bottom: rect.bottom,
    left: rect.left,
  }
}

export const getHoverSuggestStyles = ({
  isEditing,
  targetElement,
}: {
  isEditing: boolean
  targetElement?: HTMLElement
}) => {
  if (isEditing) {
    const selectionLocation = getSelectionLocation(targetElement)

    return {
      top: `${selectionLocation.bottom + window.scrollY + 4}px`,
      left: `${selectionLocation.left + window.scrollX}px`,
      zIndex: 1000,
    }
  }

  return {}
}
