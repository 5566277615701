import React from 'react'
import PropTypes from 'prop-types'
import { Field } from 'react-final-form'
import { compose } from 'recompose'
import DeleteOutlined from '@mui/icons-material/DeleteOutlined'
import Edit from '@mui/icons-material/Edit'
import Save from '@mui/icons-material/Save'
import { withStyles, Typography, TextField, IconButton, Button } from '@material-ui/core'

import { isEventKey } from 'keycode'
import withFormApi from 'client-shared/hocs/withFormApi'

const styles = theme => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    height: 36,
  },
  container: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    '& span, svg': {
      color: '#009688',
    },
  },
  iconButton: {
    display: 'flex',
    padding: 0,
    marginLeft: 4,
    width: 36,
    height: 36,
  },
  button: {
    marginLeft: 4,
  },
})

const NEW_CATEGORY_ERROR = 'Category Name is Required!'
const EXISTING_CATEGORY_ERROR = 'Category already exists!'

class ImageListCategory extends React.PureComponent {
  static propTypes = {
    name: PropTypes.string,
    onCategorySave: PropTypes.func,
    onCategoryDelete: PropTypes.func,
    category: PropTypes.string,
    categories: PropTypes.array,
    errorText: PropTypes.string,
    editable: PropTypes.bool,
    deletable: PropTypes.bool,
    isDisabled: PropTypes.bool,
    id: PropTypes.number,
  }
  static defaultProps = {
    editable: true,
    category: '',
    categories: [],
    deletable: true,
    isDisabled: false,
    onCategorySave: () => {},
    onCategoryDelete: () => {},
    id: null,
  }
  state = {
    error: '',
    category: this.props.category,
    isEditingCategory: false,
  }

  componentDidUpdate(prevProps) {
    if (this.props.category !== this.state.category && !this.state.isEditingCategory) {
      this.setState({ category: this.props.category })
    }
  }

  toggleEdit = () => {
    this.setState(state => ({ isEditingCategory: !state.isEditingCategory }))
  }

  onCategoryUpdate = event => {
    this.setState({ category: event.target.value })
  }

  onCategoryKeyDown = event => {
    if (isEventKey(event, 'enter')) {
      this.onCategorySave(event)
      event.stopPropagation()
      event.preventDefault()
    }
  }

  onCategoryDelete = () => {
    this.props.onCategoryDelete(this.props.category)
  }

  onCategorySave = () => {
    const { category: newCategory } = this.state
    const { category: oldCategory, categories, errorText, id } = this.props

    const hasSameCategoryName = categories.includes(newCategory) && newCategory !== oldCategory
    if (hasSameCategoryName) {
      return this.setState({ error: errorText || EXISTING_CATEGORY_ERROR })
    }
    if (!newCategory) {
      return this.setState({ error: NEW_CATEGORY_ERROR })
    }

    if (newCategory !== oldCategory) {
      this.props.onCategorySave({ newCategory, oldCategory, id })
    }

    this.setState({ error: null })
    this.toggleEdit()
  }

  onFocus = () => {
    this.props.form.focus(this.props.name)
  }
  onBlur = () => {
    this.props.form.blur(this.props.name)
  }

  render() {
    const { classes, category, name } = this.props
    const { error } = this.state
    const isEditing = this.props.editable && this.state.isEditingCategory

    return (
      <div className={classes.root}>
        <Field name={name} render={() => null} />
        {isEditing ? (
          <div className={classes.container}>
            <TextField
              onChange={this.onCategoryUpdate}
              onKeyDown={this.onCategoryKeyDown}
              onFocus={this.onFocus}
              onBlur={this.onBlur}
              value={this.state.category}
              error={!!error}
              helperText={error}
            />
            <Button variant="text" size="small" onClick={this.onCategorySave} className={classes.button}>
              <Save color="action" />
              <Typography variant="button">
                <span>SAVE</span>
              </Typography>
            </Button>
          </div>
        ) : (
          <React.Fragment>
            <Typography variant="subtitle1">{category}</Typography>
            {this.props.editable && (
              <React.Fragment>
                <IconButton
                  aria-label="edit"
                  className={classes.iconButton}
                  onClick={this.toggleEdit}
                  disabled={this.props.isDisabled}
                >
                  <Edit color="primary" />
                </IconButton>
                {this.props.deletable && (
                  <IconButton aria-label="delete" className={classes.iconButton} onClick={this.onCategoryDelete}>
                    <DeleteOutlined color="error" />
                  </IconButton>
                )}
              </React.Fragment>
            )}
          </React.Fragment>
        )}
      </div>
    )
  }
}

export default compose(withFormApi, withStyles(styles))(ImageListCategory)
