import { isNil, isString } from 'lodash'

import { JOB_NUMBER_MESSAGES, JOB_NUMBER_LENGTH } from '../constants'

export const parseJobNumber = value => {
  if (isNil(value)) {
    return value
  }

  return value.toString().replace(/\D/g, '').substring(0, JOB_NUMBER_LENGTH)
}

export const validateJobNumber = value => {
  const isEmptyValue = isString(value) ? value.trim() === '' : isNil(value)

  if (isEmptyValue) {
    return JOB_NUMBER_MESSAGES.PULL_EXTERNAL_VALIDATION_ERROR
  }

  return new RegExp(`^\\d{${JOB_NUMBER_LENGTH}}$`).test(value)
    ? undefined
    : JOB_NUMBER_MESSAGES.PULL_EXTERNAL_VALIDATION_ERROR
}

export const requiredJobNumber = value => {
  const isEmptyValue = isString(value) ? value.trim() === '' : isNil(value)
  return isEmptyValue ? JOB_NUMBER_MESSAGES.REQUIRED_ERROR : undefined
}
