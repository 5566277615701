import { toLower, isEmpty } from 'lodash'
import arrayToSentence from 'array-to-sentence'

import { formatList } from '../../../../shared/utils/textHelpers'

import { REASONS_OF_VALUE_CHANGE } from './constants'

export const generateContractOfSaleReconcilliation = (
  isAssignedContract,
  isPriceConsistentWithAsIs,
  isAssignmentPriceConsistentWithAsIs
) => {
  const consistencySentences = []
  if (isPriceConsistentWithAsIs) {
    consistencySentences.push(
      'We have reviewed a copy of the contract of sale and the contract price is consistent with our as is value conclusion; thus, we consider the terms market oriented.'
    )
  } else {
    consistencySentences.push(
      'We have reviewed a copy of the contract of sale and the contract price is generally inconsistent with our as is value conclusion; thus we do not consider the terms market oriented.'
    )
  }

  if (isAssignedContract) {
    if (isAssignmentPriceConsistentWithAsIs) {
      consistencySentences.push(
        'Further, the assignment price is generally consistent with our as is value conclusion.'
      )
    } else {
      consistencySentences.push(
        'Further, the assignment price is generally inconsistent with our as is value conclusion.'
      )
    }
  }

  return consistencySentences.join(' ')
}

const getContractSentenceSeparator = partsLength => {
  const separator = {
    separator: ', ',
    lastSeparator: ', and ',
  }
  if (partsLength === 2) {
    separator.lastSeparator = ' and '
  }

  return separator
}

const getValueAndDirection = value => {
  const valueDirection = value[0] === '-' ? 'depreciated' : 'appreciated'
  const moduleValue = value[0] === '-' ? value.slice(1) : value
  return [moduleValue, valueDirection]
}

export const generateSalesHistoryReconcilliation = (
  contract,
  assignedContract,
  recentSalePrice,
  finalValue,
  reasonsOfValueChange,
  numberOfTurnovers,
  changeInValue
) => {
  const [moduleChangeInSaleValue, saleValueDirection] = getValueAndDirection(changeInValue(recentSalePrice, finalValue))
  const contractSentenceParts = [
    `Finally, we note that since the time of the previous sale, the subject's value has ` +
      `${saleValueDirection} ${moduleChangeInSaleValue} from the sale price`,
  ]
  if (contract) {
    const [moduleChangeInContractValue, changeInContractValueDirection] = getValueAndDirection(
      changeInValue(contract.price, finalValue)
    )
    if (changeInContractValueDirection === saleValueDirection) {
      contractSentenceParts.push(`${moduleChangeInContractValue} from the contract price`)
    } else {
      contractSentenceParts.push(
        `${changeInContractValueDirection} ${moduleChangeInContractValue} from the contract price`
      )
    }
  }

  if (!!assignedContract) {
    const changeInAssignedContractValue = changeInValue(assignedContract.price, finalValue)
    contractSentenceParts.push(`${changeInAssignedContractValue} from the assignment price`)
  }

  const contractSentence = `${arrayToSentence(
    contractSentenceParts,
    getContractSentenceSeparator(contractSentenceParts.length)
  )}.`

  const reasons = []
  Object.entries(REASONS_OF_VALUE_CHANGE).forEach(([key, name]) => {
    if (!!reasonsOfValueChange[key]) {
      if (name === 'Unit Turnover' && numberOfTurnovers > 1) {
        reasons.push(`${numberOfTurnovers} units turned over`)
      } else {
        reasons.push(toLower(name))
      }
    }
  })

  let reasonsSentence = ''
  if (!isEmpty(reasons)) {
    reasonsSentence =
      ` Based on discussions with the owner and our research of the residential market since that time, ` +
      `this increase in value is primarily attributable to ${formatList(reasons)}.`
  }

  return `${contractSentence}${reasonsSentence}`
}
