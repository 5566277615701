import React from 'react'
import PropTypes from 'prop-types'
import { Button, Typography, TableBody, Grid } from '@mui/material'
import { withStyles } from '@mui/styles'

import { DroppableTable } from 'client-shared/components/DragDropTable'

import ItemRow from './ItemRow'

const styles = theme => ({
  wrapper: {
    padding: '0 16px',
  },
  addBtn: {
    width: 100,
    margin: '8px 0',
  },
  table: {
    minHeight: 50,
  },
  emptyList: {
    border: `1px solid ${theme.palette.grey[300]}`,
    borderLeft: 'none',
    borderRight: 'none',
    boxSizing: 'border-box',
    padding: 16,
    height: 56,
  },
  tableActions: {
    margin: '10px 0',
  },
})

class Table extends React.PureComponent {
  static defaultProps = {
    revertButton: null,
  }

  render() {
    const {
      classes,
      listName,
      placeholder,
      fields,
      description,
      onAddItem,
      onOpenClearModal,
      setInputRef,
      onInsertItem,
      onRemoveItem,
      isDragging,
      canRemoveItem,
      revertButton,
    } = this.props

    const isEmptyList = !fields.length

    return (
      <React.Fragment>
        <div className={classes.wrapper}>
          <Typography type="body1">{description}</Typography>

          <Grid container justifyContent="space-between" className={classes.tableActions}>
            <Grid item>
              <Button
                onClick={onAddItem}
                className={classes.addBtn}
                variant="contained"
                size="small"
                data-qa={`${listName}-add-btn`}
              >
                Add
              </Button>
            </Grid>
            <Grid item>
              <Button
                onClick={onOpenClearModal}
                disabled={isEmptyList}
                size="small"
                data-qa={`${listName}-clear-all-btn`}
              >
                Clear all
              </Button>
              {revertButton}
            </Grid>
          </Grid>
        </div>

        {isEmptyList ? (
          <div className={classes.emptyList}>
            <Typography variant="body1">None.</Typography>
          </div>
        ) : (
          <DroppableTable id={listName} type={listName} classes={{ root: classes.table }}>
            <TableBody data-qa={`${listName}-table-body`}>
              {fields.map((name, index) => (
                <ItemRow
                  key={name}
                  name={name}
                  index={index}
                  listName={listName}
                  placeholder={placeholder}
                  isDragging={isDragging}
                  setInputRef={setInputRef}
                  onRemoveItem={onRemoveItem}
                  onInsertItem={onInsertItem}
                  canRemoveItem={canRemoveItem}
                />
              ))}
            </TableBody>
          </DroppableTable>
        )}
      </React.Fragment>
    )
  }
}

Table.propTypes = {
  classes: PropTypes.object.isRequired,
  listName: PropTypes.string.isRequired,
  fields: PropTypes.object.isRequired,
  description: PropTypes.string.isRequired,
  onAddItem: PropTypes.func.isRequired,
  onOpenClearModal: PropTypes.func.isRequired,
  setInputRef: PropTypes.func.isRequired,
  onInsertItem: PropTypes.func.isRequired,
  onRemoveItem: PropTypes.func.isRequired,
  isDragging: PropTypes.bool.isRequired,
  canRemoveItem: PropTypes.func,
  revertButton: PropTypes.node,
}

export default withStyles(styles)(Table)
