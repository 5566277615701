import { filter, get, map } from 'lodash'

import { formatList } from 'shared/utils/textHelpers'

import { LeaseStatusTypes } from 'shared/constants/incomeApproach/commercialIncome'

import { GENERAL_CONDITIONS } from '../../../../constants/property/description'
import { VALUE_CONCLUSION_TYPES } from '../../../../constants/acas'
import { simplePluralize as pluralize, pluralizeBeVerb } from '../../index'

const mapData = (units, formValues) => {
  const unitsWithNumbers = units.map((unit, index) => {
    unit.number = index + 1
    return unit
  })
  const vacantUnits = filter(unitsWithNumbers, unit => unit.leaseStatus === LeaseStatusTypes.Vacant)
  const ownerOccupiedUnits = filter(unitsWithNumbers, unit => unit.leaseStatus === LeaseStatusTypes.OwnerOccupied)
  const occupiedUnits = filter(unitsWithNumbers, unit => unit.leaseStatus === LeaseStatusTypes.Occupied)

  const ownerOccupiedUnitsList = formatList(map(ownerOccupiedUnits, 'number'))
  const vacantUnitsList = formatList(map(vacantUnits, 'number'))
  const vacantUnitsLength = vacantUnits.length
  const isVacantUnits = !!vacantUnitsLength
  const noVacantUnits = !vacantUnitsLength

  const vacantUnitsVerb = pluralizeBeVerb(vacantUnitsLength)
  const vacantUnitTermsWord = pluralize('term', vacantUnitsLength)

  const ownerOccupiedUnitsLength = ownerOccupiedUnits.length
  const hasOwnerOccupiedUnits = !!ownerOccupiedUnitsLength
  const noOwnerOccupiedUnits = !ownerOccupiedUnitsLength
  const ownerOccupiedUnitsWord = pluralize('unit', ownerOccupiedUnitsLength)
  const ownerOccupiedUnitsVerb = pluralizeBeVerb(ownerOccupiedUnitsLength)
  const ownerOccupiedUnitTermsWord = pluralize('term', ownerOccupiedUnitsLength)
  const occupiedUnitLeasesWord = pluralize('lease', occupiedUnits.length)

  const conclusionType = get(formValues, 'valueConclusionType')
  const valueAsIs = conclusionType === VALUE_CONCLUSION_TYPES.AS_IS
  const mentionRenovations =
    !valueAsIs && get(formValues, 'propertyCondition.value') === GENERAL_CONDITIONS.underRenovation

  const forecastedUnitsWord = pluralize('unit', vacantUnitsLength + ownerOccupiedUnitsLength)
  return {
    unitsCount: units.length,
    mentionRenovations,
    isVacantUnits,
    noVacantUnits,
    occupiedUnitLeasesWord,
    forecastedUnitsWord,
    vacantUnitsList,
    vacantUnitTermsWord,
    vacantUnitsVerb,
    hasOwnerOccupiedUnits,
    noOwnerOccupiedUnits,
    ownerOccupiedUnitsWord,
    ownerOccupiedUnitsVerb,
    ownerOccupiedUnitTermsWord,
    ownerOccupiedUnitsList,
    noForecastedUnits: noVacantUnits && noOwnerOccupiedUnits,
    forecastedUnits: isVacantUnits || hasOwnerOccupiedUnits,
  }
}

export const mapDTO = formValues => {
  const { units } = formValues
  return mapData(units, formValues)
}

export const mapDataModel = report => {
  const propertyCondition = get(report, 'buildingDescription.generalCondition')
  const valueConclusionType = get(report, 'valueConclusionType')
  const units = report.commercial?.projectedRentRoll || []
  const projectedUnits = units.map(unit => {
    return {
      ...unit,
      leaseStatus: unit.lease?.status,
    }
  })
  return mapData(projectedUnits, {
    propertyCondition,
    valueConclusionType,
  })
}
