import { gtConditional, gtIfElse, gtLocked, gtTemplate } from 'shared/utils/narrative/GeneratedText'

import { mapDTO, mapDataModel } from './mappings'

const generate = () => {
  const lossFactorGt = gtLocked('lossFactor', 'formatPercentageString')
  const basisForSFAnalysisGt = gtLocked('basisForSFAnalysis', 'formatLowerCase')
  const average = { ...gtConditional('showPerUnitSF', gtTemplate` average`), inverted: true }

  const nonGbaSentence = gtTemplate`The ${basisForSFAnalysisGt} and${average} square footage per unit
  was provided by the owner.`

  const gbaSentence = gtIfElse(
    'showPerUnitSF',
    gtTemplate`The total leasable area and average square footage per unit was provided by the owner
  based on a loss factor of ${lossFactorGt} of the gross building area.`,
    gtTemplate`We estimated the total leasable area and resulting average square footage per unit based on a
    loss factor of ${lossFactorGt} applied to the gross building area.`
  )

  return gtIfElse('usingDefaultBasisForSFAnalysis', gbaSentence, nonGbaSentence)
}

export default { generate, mapDTO, mapDataModel }
