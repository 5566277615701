import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { get, capitalize } from 'lodash'
import { compose, pure } from 'recompose'
import { Grid, Typography } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'

const styles = {
  root: {
    marginLeft: 36,
  },
  text: {
    color: 'white',
    fontSize: 15,
    fontWeight: 700,
    paddingRight: 8,
  },
  address: {
    color: 'white',
    fontSize: 15,
    fontWeight: 400,
  },
}

const BuildingFormLocation = ({ address, classes }) => {
  const formattedAddress = address.split(' ').map(capitalize).join(' ')
  return (
    <Grid container alignItems="flex-end" className={classes.root}>
      <Grid item>
        <Typography className={classes.text} type="body1">
          Building Comparable
        </Typography>
      </Grid>
      <Grid item>
        <Typography className={classes.address} type="body1">
          {formattedAddress}
        </Typography>
      </Grid>
    </Grid>
  )
}
BuildingFormLocation.propTypes = {
  address: PropTypes.string,
}
BuildingFormLocation.defaultProps = {
  address: '',
}

export default compose(
  connect(state => ({ address: get(state, 'buildingComp.propertySummary.streetAddress') })),
  withStyles(styles),
  pure
)(BuildingFormLocation)
