import React from 'react'
import PropTypes from 'prop-types'

import get from 'lodash/get'
import { connect } from 'react-redux'

import Button from '@mui/material/Button'
import InfoIcon from '@mui/icons-material/InfoOutlined'
import Paper from '@mui/material/Paper'
import Stack from '@mui/material/Stack'
import Tooltip from '@mui/material/Tooltip'
import Typography from '@mui/material/Typography'

import { DEFAULT_APPROACH_SETTINGS } from 'shared/constants/report'

import { Template } from '../../../../shared/components/Template'
import { ToolSelectionCheckboxes } from '../../../../reportsDashboard/components/CreateReportModal/BlocksReportFlow'
import { reportSettingsUpdate } from '../../../redux/actions/report'
import {
  TemplateTypeLabel,
  ValueConclusionTypeLabel,
  IncomeTypeLabel,
  ApproachesLabel,
} from '../../../components/ReportSettings/Labels'
import { VALUE_CONCLUSION_DESCRIPTIONS } from '../../../constants/settings'

import ReportSettingsModal from './ReportSettingsModalContainer'

const ReportSettingsItem = ({ children, title }) => (
  <Template is="auto-flow/ 130px auto" placeItems="end start">
    <Typography color="text.secondary" variant="body2" sx={{ pb: 1 / 4 }}>
      {title}
    </Typography>
    <Typography component="span" variant="body1" sx={{ display: 'flex' }}>
      {children}
    </Typography>
  </Template>
)
class ReportSettingsSection extends React.PureComponent {
  static propTypes = {
    blocksConfiguration: PropTypes.object.isRequired,
    incomeType: PropTypes.string.isRequired,
    isBlocks: PropTypes.bool.isRequired,
    templateType: PropTypes.string.isRequired,
    updateSettings: PropTypes.func.isRequired,
    valueConclusionType: PropTypes.string.isRequired,
  }

  state = {
    modalOpen: false,
  }

  openModal = () => {
    this.setState({
      modalOpen: true,
    })
  }

  closeModal = () => {
    this.setState({
      modalOpen: false,
    })
  }

  render() {
    const { blocksConfiguration, incomeType, isBlocks, templateType, updateSettings, valueConclusionType, approaches } =
      this.props
    const { modalOpen } = this.state

    return (
      <Paper>
        <Stack spacing={2}>
          <ReportSettingsModal
            blocksConfiguration={blocksConfiguration}
            incomeType={incomeType}
            isBlocks={isBlocks}
            onClose={this.closeModal}
            open={modalOpen}
            templateType={templateType}
            updateSettings={updateSettings}
            valueConclusionType={valueConclusionType}
            approaches={approaches}
          />
          <Stack spacing={1}>
            <Stack direction="row" justifyContent="space-between">
              <Typography variant="h6">Report Settings</Typography>
              <Button data-qa="cancel-btn" name="cancel-btn" onClick={this.openModal}>
                Change
              </Button>
            </Stack>
            <Typography color="text,secondary" variant="body2">
              The following specifications have been applied to your report.
            </Typography>
          </Stack>
          {isBlocks && <ToolSelectionCheckboxes blocksConfiguration={blocksConfiguration} disabled />}
          {!isBlocks && (
            <Stack spacing={1}>
              <ReportSettingsItem title="Freddie Mac">
                <TemplateTypeLabel value={templateType} />
              </ReportSettingsItem>
              <ReportSettingsItem title="Income Type">
                <IncomeTypeLabel value={incomeType} />
              </ReportSettingsItem>
              <ReportSettingsItem title="Value Conclusion">
                <ValueConclusionTypeLabel value={valueConclusionType} />
                <Tooltip
                  placement="right"
                  title={VALUE_CONCLUSION_DESCRIPTIONS[valueConclusionType]}
                  sx={{ mx: 1, placeSelf: 'center' }}
                >
                  <InfoIcon />
                </Tooltip>
              </ReportSettingsItem>
              <ReportSettingsItem title="Approaches">
                <ApproachesLabel approaches={approaches} />
              </ReportSettingsItem>
            </Stack>
          )}
        </Stack>
      </Paper>
    )
  }
}

const mapStateToProps = state => {
  return {
    blocksConfiguration: get(state, 'report.reportSettings.blocksConfiguration'),
    incomeType: get(state, 'report.reportSettings.incomeType'),
    isBlocks: get(state, 'report.reportSettings.isBlocks'),
    templateType: get(state, 'report.reportSettings.templateType'),
    valueConclusionType: get(state, 'report.reportSettings.valueConclusionType'),
    approaches: get(state, 'report.reportSettings.approaches', DEFAULT_APPROACH_SETTINGS),
  }
}

const mapDispatchToProps = {
  updateSettings: reportSettingsUpdate,
}

export default connect(mapStateToProps, mapDispatchToProps)(ReportSettingsSection)
