import {
  generateCompsIndicesSentence,
  getComps,
  getCompsFromFormValues,
} from 'shared/utils/textGeneration/salesApproach'
import { ADJUSTMENT_GROUPS } from 'shared/helpers/salesApproach/salesCompAdjustmentConstants'
import { hasAdjustments } from 'shared/helpers/salesApproach/salesCompAdjustments'
import { getLastWordSeparator } from 'shared/utils/sentenceBuilder'

const mapData = (upwardComps, downwardComps, compAdjustments) => {
  const hasMarketConditionsAdjustments = hasAdjustments(compAdjustments, 'marketConditions', ADJUSTMENT_GROUPS.MARKET)

  const hasUpwardComps = upwardComps.length > 0
  const hasDownwardComps = downwardComps.length > 0

  let upwardCompsSentence = ''
  if (hasUpwardComps) {
    const hasMultipleUpwardComps = upwardComps.length > 1

    upwardCompsSentence = ` Comparable${hasMultipleUpwardComps ? 's' : ''} ${generateCompsIndicesSentence(
      upwardComps,
      getLastWordSeparator
    )} received${hasMultipleUpwardComps ? '' : ' a'} minor adjustment${
      hasMultipleUpwardComps ? 's' : ''
    } to account for moderate market appreciation.`
  }

  let downwardCompsSentence = ''
  if (hasDownwardComps) {
    const hasMultipleDownwardComps = downwardComps.length > 1

    downwardCompsSentence = ` Comparable${hasMultipleDownwardComps ? 's' : ''} ${generateCompsIndicesSentence(
      downwardComps,
      getLastWordSeparator
    )} received${hasMultipleDownwardComps ? '' : ' a'} minor adjustment${
      hasMultipleDownwardComps ? 's' : ''
    } to account for moderate market depreciation.`
  }

  return {
    hasMarketConditionsAdjustments,
    upwardCompsSentence,
    downwardCompsSentence,
    hasUpwardComps,
    hasDownwardComps,
  }
}

export const mapDTO = formValues => {
  const { upwardComps, downwardComps } = getCompsFromFormValues(
    formValues,
    'marketConditions',
    ADJUSTMENT_GROUPS.MARKET
  )
  const { compAdjustments } = formValues

  return mapData(upwardComps, downwardComps, compAdjustments)
}

export const mapDataModel = (report, parent) => {
  if (!parent.parent()) {
    return undefined
  }

  const salesApproach = parent.parent()

  const { salesComps = {}, salesCompsAdjustments = {} } = salesApproach
  const { selectedComps = [] } = salesComps
  const { compAdjustments = [] } = salesCompsAdjustments

  const { upwardComps, downwardComps } = getComps(
    compAdjustments,
    selectedComps,
    'marketConditions',
    ADJUSTMENT_GROUPS.MARKET
  )

  return mapData(upwardComps, downwardComps, compAdjustments)
}
