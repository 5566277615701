import { RECENT_RENOVATIONS, RECENT_GUT_RENOVATION, DO_NOT_DISCUSS } from 'shared/constants/report/property'

import { arrayToKeyValuePairs } from '../../../../shared/utils/ui/checkboxHelper'

export const EXPENSE_TYPES_LIST = ['Soft Financing', 'Soft', 'Hard']
export const EXPENSE_TYPES = arrayToKeyValuePairs(EXPENSE_TYPES_LIST)

export const DATA_SOURCE_TYPES_LIST = ['Owner’s Estimate', 'Marshall & Swift Estimate', 'Appraiser’s Forecast']
export const DATA_SOURCE_TYPES = arrayToKeyValuePairs(DATA_SOURCE_TYPES_LIST)

export const DEFAULT_ITEMIZED_ROWS = ['Unit Renovations', 'Heating System', 'New Facade', 'New Roof']

export const DEFAULT_TOTAL_ROWS = ['Unit Renovations', 'Heating System', 'New Facade', 'New Roof']

export const RenovationCostTypes = {
  ITEMIZED: 'Itemized',
  TOTAL: 'Total',
}

export const ProspectiveRenovationTypes = {
  NO_RENOVATIONS: 'No Renovations',
  GUT_RENOVATION: 'Gut Renovation',
  RENOVATION: 'Renovation',
}

export const PROSPECTIVE_RENOVATION_TYPES = arrayToKeyValuePairs([
  ProspectiveRenovationTypes.RENOVATION,
  ProspectiveRenovationTypes.GUT_RENOVATION,
  ProspectiveRenovationTypes.NO_RENOVATIONS,
])

export const DEFAULT_ITEMIZED_ITEMS = DEFAULT_ITEMIZED_ROWS.map(row => ({ item: row }))
export const DEFAULT_TOTAL_ITEMS = DEFAULT_TOTAL_ROWS.map(row => ({ item: row }))

export const RENOVATION_COSTS_CALLOUT_TEXT =
  'Generated Commentary shown below will be displayed in export. Update table Renovation Cost above to modify.'

export const HEADING = 'Renovation Description'

export const MODAL_TITLE = 'Revert Table To Default'

export const MODAL_TEXT = 'If you revert table to default all data entry will be lost. This action cannot be undone.'

export const RENOVATION_COSTS_TYPE_OPTIONS = [
  { value: RenovationCostTypes.ITEMIZED, label: 'Itemized' },
  { value: RenovationCostTypes.TOTAL, label: 'Total' },
]

export const ITEMIZED_RENOVATION_LOCATION_CALLOUT_TEXT =
  'Itemized Renovation Costs will appear in the Addenda of the export.'

export {
  /** @deprecated Prefer importing this from the shared folder */
  RECENT_RENOVATIONS,
  /** @deprecated Prefer importing this from the shared folder */
  RECENT_GUT_RENOVATION,
  /** @deprecated Prefer importing this from the shared folder */
  DO_NOT_DISCUSS,
}
