import { get } from 'lodash'
import arrayToSentence from 'array-to-sentence'

import { getStateCertifiedAppraisers } from '../../../../../../client/src/report/forms/reportInformation/Appraisers/appraisersHelper'
import { TemplateTypes } from '../../../../../../libs/bowery-libs/constants/report/settings'

export const mapDTO = formValues => {
  const { appraisers, isFreddieMac, inspectionDate, selectedAppraisers, reportState } = formValues

  const appraisersForDiscussion = isFreddieMac
    ? getStateCertifiedAppraisers(selectedAppraisers, reportState)
    : appraisers

  const appraisersWithInspection = appraisersForDiscussion.filter(appraiser => appraiser.isInspected)
  const appraisersWithoutInspection = appraisersForDiscussion.filter(appraiser => !appraiser.isInspected)
  const appraisersWithInspectionVerb = appraisersWithInspection.length > 1 ? ' have' : ' has'
  const appraisersWithoutInspectionVerb = appraisersWithoutInspection.length > 1 ? ' have not' : ' has not'
  const appraisersWithInspectionString = arrayToSentence(appraisersWithInspection.map(appraiser => appraiser.fullName))
  const appraisersWithoutInspectionString = arrayToSentence(
    appraisersWithoutInspection.map(appraiser => appraiser.fullName)
  )

  const isAppraisersWithInspectionGreaterThanZero = appraisersWithInspection.length > 0
  const isAppraisersWithoutInspectionGreaterThanZero = appraisersWithoutInspection.length > 0

  return {
    inspectionDate,
    isAppraisersWithInspectionGreaterThanZero,
    isAppraisersWithoutInspectionGreaterThanZero,
    appraisersWithInspection,
    appraisersWithoutInspection,
    appraisersWithInspectionVerb,
    appraisersWithoutInspectionVerb,
    appraisersWithInspectionString,
    appraisersWithoutInspectionString,
  }
}

export const mapDataModel = report => {
  const templateType = get(report, 'templateType', '')
  const { state } = get(report, 'property_information', {})
  const { appraisers } = get(report, 'organizationInfo', { appraisers: [] })
  const isFreddieMac = templateType === TemplateTypes.FREDDIE_MAC
  const reportState = state
  const inspectionDate = get(report, 'inspection.inspectionDate')

  const appraisersForDiscussion = isFreddieMac ? getStateCertifiedAppraisers(appraisers, reportState) : appraisers
  const externalInspectors = isFreddieMac ? [] : get(report, 'external_inspectors', [])

  const appraisersWithInspection = appraisersForDiscussion.filter(appraiser => appraiser.performedInspection)
  const appraisersWithoutInspection = appraisersForDiscussion.filter(appraiser => !appraiser.performedInspection)

  const reportInspectorsCount = appraisersWithInspection.length + externalInspectors.length
  const appraisersWithInspectionVerb = reportInspectorsCount > 1 ? ' have' : ' has'
  const appraisersWithoutInspectionVerb = appraisersWithoutInspection.length > 1 ? ' have not' : ' has not'

  const isAppraisersWithInspectionGreaterThanZero = reportInspectorsCount > 0
  const isAppraisersWithoutInspectionGreaterThanZero = appraisersWithoutInspection.length > 0

  const appraisersWithInspectionString = arrayToSentence([
    ...appraisersWithInspection.map(obj => obj.appraiser.fullName),
    ...externalInspectors.map(externalInspector => externalInspector.fullName),
  ])
  const appraisersWithoutInspectionString = arrayToSentence(
    appraisersWithoutInspection.map(obj => obj.appraiser.fullName)
  )

  return {
    inspectionDate,
    isAppraisersWithInspectionGreaterThanZero,
    isAppraisersWithoutInspectionGreaterThanZero,
    appraisersWithInspection,
    appraisersWithInspectionVerb,
    appraisersWithoutInspection,
    appraisersWithoutInspectionVerb,
    appraisersWithInspectionString,
    appraisersWithoutInspectionString,
  }
}
