import React from 'react'
import PropTypes from 'prop-types'
import { TableRow } from '@material-ui/core'

import { Number } from '../../../../../../shared/components'
import { toPercents, fromPercents } from '../../../../../../shared/utils/numberOperations'

import { Labels } from '../CommercialRentReconciliationConstants'
import { Cell, TotalCell, BaseUnitCell, SubjectUnitCell, HeadingCell } from '../TableCells'

import FieldCells from './components/FieldCells'

export default class SizeAdjustments extends React.PureComponent {
  static propTypes = {
    fieldPrefix: PropTypes.string.isRequired,
    baseUnitSquareFeet: PropTypes.number,
    units: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
    rentComps: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
    baseUnitPerSFRent: PropTypes.number,
    disableInputs: PropTypes.bool.isRequired,
  }

  render() {
    const { fieldPrefix, baseUnitSquareFeet, units, rentComps, disableInputs } = this.props

    return (
      <React.Fragment>
        <TableRow>
          <HeadingCell>{Labels.SIZE_ADJUSTMENTS}</HeadingCell>
        </TableRow>
        <TableRow data-qa="square-feet-row">
          <Cell data-qa="row-label-cell">{Labels.SQUARE_FEET}</Cell>
          <BaseUnitCell data-qa="base-unit-cell">{baseUnitSquareFeet}</BaseUnitCell>
          <FieldCells units={units} rentComps={rentComps} fieldType="squareFeet" />
        </TableRow>
        <TableRow data-qa="size-adjustments-row">
          <TotalCell data-qa="row-label-cell">{Labels.ADJUSTMENT}</TotalCell>
          <BaseUnitCell data-qa="base-unit-cell" />
          {units.map((unit, index) => (
            <SubjectUnitCell data-qa={`subject-unit-${index}-cell`} key={unit._id}>
              <Number
                name={`${fieldPrefix}.commercialUnits[${index}].adjustments.size`}
                decimalScale={2}
                adornment="%"
                format={toPercents}
                normalize={fromPercents}
                disabled={disableInputs}
              />
            </SubjectUnitCell>
          ))}
          {rentComps.map((rentComp, index) => (
            <Cell data-qa={`rent-comp-${index}-cell`} key={rentComp._id}>
              <Number
                name={`${fieldPrefix}.rentComps[${index}].adjustments.size`}
                decimalScale={2}
                adornment="%"
                format={toPercents}
                normalize={fromPercents}
                disabled={disableInputs}
              />
            </Cell>
          ))}
        </TableRow>
      </React.Fragment>
    )
  }
}
