import { GeneratedText, gtConditional, gtLocked, gtTemplate } from 'shared/utils/narrative/GeneratedText'

const generate = () => {
  const gt = new GeneratedText()

  const projectedGt = { ...gtConditional('valueAsIs', gtTemplate` projected at`), inverted: true }
  const percentageOfMarketRentGt = gtLocked('percentageOfMarketRent', 'formatPercentageString', null, [0])

  const stabilizedIncomeProjection = gtConditional(
    'hasStabilizedIncomeProjection',
    gtTemplate` and stabilized income projection`
  )

  gt.t`Based on our market rent conclusions${stabilizedIncomeProjection},
  the residential rent roll is${projectedGt} ${percentageOfMarketRentGt} of market.`

  return gt.getParts()
}

export default generate
