import React from 'react'
import PropTypes from 'prop-types'

import { DragDropContext } from 'react-beautiful-dnd'
import { Button, Grid, IconButton, Paper, Stack, TableBody, Tooltip, Typography } from '@mui/material'
import Undo from '@mui/icons-material/Undo'

import WarningDialog from 'client-shared/components/WarningDialog'
import { DismissibleCallout } from 'client-shared/components'
import { DroppableTable } from 'client-shared/components/DragDropTable'

import AppraisalScopeItemRow from './AppraisalScopeItemRow'
import { MODAL_TEXT, MODAL_TITLE, NEW_FEATURE_CALLOUT_TEXT, TABLE_HEADER } from './AppraisalScopeConstants'

class AppraisalScope extends React.Component {
  state = {
    dragging: false,
    modalOpen: false,
  }

  constructor(props) {
    super(props)
    this.inputs = new Map()
  }

  setInputRef = (index, ref) => {
    this.inputs.set(index, ref)
  }

  onInsertItem = index => {
    const { fields } = this.props
    const nextElementIndex = fields.value ? fields.value.length : 1

    fields.push('')
    this.focusInput(nextElementIndex)
  }

  focusInput = index => {
    setTimeout(() => {
      const input = this.inputs.get(index)
      if (input) {
        input.focus()
      }
    })
  }

  openModal = () => {
    this.setState({ modalOpen: true })
  }

  closeModal = () => {
    this.setState({ modalOpen: false })
  }

  onDragStart = () => {
    this.setState({ dragging: true })
  }

  onDragEnd = ({ source, destination }) => {
    this.setState({ dragging: false })
    if (!destination) {
      return
    }
    const input = this.inputs.get(destination.index)
    input.focus()

    this.props.fields.move(source.index, destination.index)
  }

  onRevertToDefault = () => {
    const { form } = this.props
    form.change('items', form.values.defaults)
  }

  onRemoveItem = index => {
    const { form } = this.props

    const values = form.values.items.filter((value, key) => key !== index)
    form.change('items', values)
  }

  onClearAllItems = () => {
    const { form } = this.props
    form.change('items', [''])
    this.closeModal()
  }

  onItemAdd = () => {
    this.props.fields.push('')
  }

  render() {
    const { fields } = this.props
    const { modalOpen, dragging } = this.state
    return (
      <Stack sx={{ width: 800 }}>
        <WarningDialog
          text={MODAL_TEXT}
          title={MODAL_TITLE}
          onCancel={this.closeModal}
          onSubmit={this.onClearAllItems}
          open={modalOpen}
        />
        <Stack spacing={3}>
          <DismissibleCallout text={NEW_FEATURE_CALLOUT_TEXT} />

          <Paper data-qa="scope-container">
            <Stack sx={{ padding: 2, paddingRight: 3 }}>
              <Typography variant="h6" paragraph>
                {TABLE_HEADER}
              </Typography>
              <Grid container alignItems="center" justifyContent="space-between">
                <Grid item>
                  <Button data-qa="add-btn" variant="contained" size="small" onClick={this.onItemAdd}>
                    Add
                  </Button>
                </Grid>
                <Grid item>
                  <Button wide onClick={this.openModal} size="small" data-qa="clear-all-btn">
                    CLEAR ALL
                  </Button>
                  <Tooltip title="Revert to Default" placement="top">
                    <IconButton onClick={this.onRevertToDefault} data-qa="revert-to-default">
                      <Undo fontSize="small" />
                    </IconButton>
                  </Tooltip>
                </Grid>
              </Grid>
            </Stack>
            <DragDropContext onDragStart={this.onDragStart} onDragEnd={this.onDragEnd}>
              <DroppableTable id="scope-table" type="scope-table">
                <TableBody data-qa="scope-table-body">
                  {fields.map((name, index) => (
                    <AppraisalScopeItemRow
                      key={name}
                      name={name}
                      index={index}
                      dragging={dragging}
                      singleValue={fields.length === 1}
                      onRemoveItem={this.onRemoveItem}
                      setInputRef={this.setInputRef}
                      onInsertItem={this.onInsertItem}
                    />
                  ))}
                </TableBody>
              </DroppableTable>
            </DragDropContext>
          </Paper>
        </Stack>
      </Stack>
    )
  }
}

AppraisalScope.propTypes = {
  form: PropTypes.object.isRequired,
  fields: PropTypes.object.isRequired,
}

export default AppraisalScope
