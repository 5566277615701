export const INCOME_POTENTIAL_TYPES = {
  0: 'Significant',
  1: 'Typical',
  2: 'Moderate',
  3: 'Limited',
}

export const AREA_CONDITIONS_TYPES = {
  0: 'Moderately Appreciating',
  1: 'Growing',
  2: 'Continually Strong',
}

export const RISK_TYPES = {
  0: 'Secure',
  1: 'Short-term Risk',
  2: 'Moderate Risk',
  3: 'Do Not Discuss',
}
