import { createTheme } from '@mui/material/styles'

import * as components from './components'
import palette, { Palette } from './palette'
import typography from './typography'

const themeDefinition = {
  palette,
  typography,
  components: components as any,
}

const theme = createTheme(themeDefinition)

export type Theme = typeof theme & { palette: Palette }

export default theme
