import { createAction } from 'redux-actions'

import {
  INITIALIZE_CREATING_REPORT,
  DELETE_REPORT,
  REPORTS_FETCH,
  REPORTS_CLEAR,
  CREATE_REPORT,
  CHANGE_REPORT_FILTERS,
  CHANGE_REPORT_SORTING,
  CHANGE_REPORT_TAB,
  SET_REPORT_FILTERS,
  CLEAR_REPORT_FILTERS,
  CLEAR_REPORTS,
  DUPLICATE_REPORT,
  CHANGE_REPORT_VISIBILITY,
  SET_SHOW_LOADING,
  INITIAL_REPORTS_FETCH,
  INITIALIZE_CREATED_REPORT,
} from './actionTypes'

export const initializeCreatingReport = createAction(INITIALIZE_CREATING_REPORT.ACTION)

export const createReport = createAction(CREATE_REPORT.ACTION)
export const createReportRequest = createAction(CREATE_REPORT.REQUEST)
export const createReportReceive = createAction(CREATE_REPORT.RECEIVE)
export const createReportFail = createAction(CREATE_REPORT.FAIL)

export const deleteReport = createAction(DELETE_REPORT.ACTION)
export const deleteReportRequest = createAction(DELETE_REPORT.REQUEST)
export const deleteReportReceive = createAction(DELETE_REPORT.RECEIVE)
export const deleteReportFail = createAction(DELETE_REPORT.FAIL)

export const duplicateReport = createAction(DUPLICATE_REPORT.ACTION)
export const duplicateReportRequest = createAction(DUPLICATE_REPORT.REQUEST)
export const duplicateReportReceive = createAction(DUPLICATE_REPORT.RECEIVE)
export const duplicateReportFail = createAction(DUPLICATE_REPORT.FAIL)

export const changeReportVisibility = createAction(CHANGE_REPORT_VISIBILITY.ACTION)
export const changeReportVisibilityRequest = createAction(CHANGE_REPORT_VISIBILITY.REQUEST)
export const changeReportVisibilityReceive = createAction(CHANGE_REPORT_VISIBILITY.RECEIVE)
export const changeReportVisibilityFail = createAction(CHANGE_REPORT_VISIBILITY.FAIL)

export const fetchMoreReports = createAction(REPORTS_FETCH.ACTION)

export const fetchMoreReportsRequest = createAction(REPORTS_FETCH.REQUEST)
export const fetchMoreReportsReceive = createAction(REPORTS_FETCH.RECEIVE)
export const fetchMoreReportsFail = createAction(REPORTS_FETCH.FAIL)

export const reportsClear = createAction(REPORTS_CLEAR.ACTION)

export const changeReportFilters = createAction(CHANGE_REPORT_FILTERS.ACTION)
export const changeReportSorting = createAction(CHANGE_REPORT_SORTING.ACTION)
export const changeReportTab = createAction(CHANGE_REPORT_TAB.ACTION)

export const setReportFilters = createAction(SET_REPORT_FILTERS.ACTION)
export const clearReportFilters = createAction(CLEAR_REPORT_FILTERS.ACTION)
export const clearReports = createAction(CLEAR_REPORTS.ACTION)
export const setShowLoading = createAction(SET_SHOW_LOADING.ACTION)
export const initialReportsFetch = createAction(INITIAL_REPORTS_FETCH.ACTION)

export const initializeCreatedReport = createAction(INITIALIZE_CREATED_REPORT.ACTION)
