import React, { useState } from 'react'
import { useFieldArray } from 'react-final-form-arrays'

import { Button } from '../../../../shared/components'
import useFieldValue from '../../../utils/useFieldValue'

import SaveDialog from './SaveDialog'

const ComparableMapActions = ({ hasImage, openMapWizard, mapIndex }) => {
  const maps = useFieldArray('maps', {})
  const map = useFieldValue(`maps[${mapIndex}]`)
  const [showSaveDialog, setShowSaveDialog] = useState(false)

  const removeMap = () => {
    maps.fields.remove(mapIndex)
  }

  const openSaveDialog = () => setShowSaveDialog(true)
  const closeSaveDialog = () => setShowSaveDialog(false)

  const updateMapLabel = ({ label }) => {
    maps.fields.update(mapIndex, { ...map, label })
    closeSaveDialog()
  }

  return (
    <>
      {hasImage ? (
        <>
          <SaveDialog
            open={showSaveDialog}
            initialLabel={map.label}
            onSave={updateMapLabel}
            onCancel={closeSaveDialog}
            title="Save Map"
          />
          <Button color="primary" onClick={openMapWizard} data-qa="edit-btn">
            Edit
          </Button>
          <Button color="primary" onClick={openSaveDialog} data-qa="rename-btn">
            Rename
          </Button>
          <Button color="primary" onClick={removeMap} data-qa="clear-btn">
            Clear
          </Button>
        </>
      ) : (
        <Button color="primary" onClick={openMapWizard} data-qa="open-btn">
          Open Wizard
        </Button>
      )}
    </>
  )
}

export default ComparableMapActions
