import React from 'react'
import PropTypes from 'prop-types'

import { Grid, Typography } from '@mui/material'

import SectionHeader from '../components/SectionHeader'

import { LIST_TYPE } from './constants'

const IntroductionList = ({ headerText, intoductionText, list, type }) => {
  const ListTag = type
  return (
    <Grid item xs={12}>
      {headerText && <SectionHeader title={headerText} />}
      <Typography>
        {intoductionText}
        <ListTag style={{ margin: 0 }}>
          {list.map((item, index) => (
            <li key={`${index}_${item}`}>{item}</li>
          ))}
        </ListTag>
      </Typography>
    </Grid>
  )
}

IntroductionList.propTypes = {
  type: PropTypes.oneOf([LIST_TYPE.OL, LIST_TYPE.UL]),
}

IntroductionList.defaultProps = {
  type: LIST_TYPE.UL,
}

export default IntroductionList
