import React from 'react'
import PropTypes from 'prop-types'

import { Box, Button, TableCell } from '@mui/material'
import { get, startCase } from 'lodash'
import { ErrorOutline } from '@mui/icons-material'

import { Tooltip } from 'client-shared/components/_mui5'
import { TIME_PERIODS } from 'shared/constants/report/incomeApproach'
import { calculateRentPSFByTimePeriod } from 'shared/calculations/rentRoll'
import { formatCurrencyFloat, formatCurrencyInt, formatInt } from 'client-shared/utils/numberFormatters'
import { getSourceOfInformation } from 'client-shared/components/SourceOfInformation/SourceOfInformation'

import Badge from 'report/forms/sales/SalesCompsSearch/Badge'
import AutoCompIcon from 'report/components/AutoCompIcon'

import { getDisplayAddress } from 'shared/utils/propertyHelpers'

import { AUTOMATED_COMPS_TOOLTIP } from '../../constants'

class RentCompItem extends React.PureComponent {
  openEditModal = unit => {
    const { showEditModal } = this.props
    const { leaseId } = unit
    if (leaseId) {
      showEditModal(unit)
    }
  }

  removeComp = () => {
    const { removeComp, unit } = this.props
    removeComp(unit)
  }

  getTotalRooms(unit) {
    return get(unit, 'rooms') || unit.bedrooms + 2
  }

  getRentPerRoom(unit) {
    const rentPerRoom = unit.rent / this.getTotalRooms(unit)
    return formatCurrencyInt(rentPerRoom)
  }

  getRentPerSfPerTimePeriod(unit, timePeriod) {
    const monthlyRent = parseFloat(unit.rent)
    const sqft = parseInt(unit.sqft, 10)
    const rentPSFPerTimePeriod = calculateRentPSFByTimePeriod(monthlyRent, sqft, timePeriod)

    if (!Number.isFinite(rentPSFPerTimePeriod)) {
      return '$NaN'
    }

    if (timePeriod === TIME_PERIODS.MONTHLY) {
      return formatCurrencyFloat(rentPSFPerTimePeriod)
    }

    return formatCurrencyInt(rentPSFPerTimePeriod)
  }

  render() {
    const { index, showBathrooms, showAmenities, showPerRoomAnalysis, showPerUnitSF, unit, unitRentPSFTimePeriod } =
      this.props
    const displayOutdated = unit.leaseId && !unit.isLatestVersion

    return (
      <>
        <TableCell>
          <AutoCompIcon comp={unit} tooltipMessage={AUTOMATED_COMPS_TOOLTIP} />
        </TableCell>
        <TableCell data-qa="col-index">{index}</TableCell>
        <TableCell data-qa="col-unitAddress">
          {unit.fullAddressWithZip || getDisplayAddress({ address: unit.address, city: unit.city, state: unit.state })}{' '}
          {displayOutdated && (
            <Tooltip placement="top" title="Outdated">
              <Box component="span" sx={{ position: 'relative', top: 4, left: 8 }}>
                <ErrorOutline color="error" fontSize="small" />
              </Box>
            </Tooltip>
          )}
        </TableCell>
        <TableCell data-qa="col-unit-cp-status">{unit.status ? <Badge type={unit.status} /> : '-'}</TableCell>
        {showPerRoomAnalysis && (
          <TableCell data-qa="col-rooms" align="center">
            {this.getTotalRooms(unit)}
          </TableCell>
        )}
        <TableCell align="center" data-qa="col-bedrooms">
          {unit.bedrooms}
        </TableCell>
        {showBathrooms && (
          <TableCell align="center" data-qa="col-bathrooms">
            {unit.bathrooms}
          </TableCell>
        )}
        {showAmenities && (
          <TableCell align="center" data-qa="col-bathrooms">
            {unit.amenities?.map(amenity => startCase(amenity)).join(', ')}
          </TableCell>
        )}
        <TableCell align="center" data-qa="col-rent">
          {formatCurrencyInt(unit.rent)}
        </TableCell>
        {showPerRoomAnalysis && (
          <TableCell align="center" data-qa="col-rentPerRoom">
            {this.getRentPerRoom(unit)}
          </TableCell>
        )}
        {showPerUnitSF && (
          <>
            <TableCell align="center" data-qa="col-sqft">
              {formatInt(unit.sqft)}
            </TableCell>
            <TableCell align="center" data-qa="col-rentPerSf">
              {this.getRentPerSfPerTimePeriod(unit, unitRentPSFTimePeriod)}
            </TableCell>
          </>
        )}
        <TableCell align="center" data-qa="col-source-of-info">
          {getSourceOfInformation(unit)}
        </TableCell>
        <TableCell data-qa="col-edit">
          <Button onClick={() => this.openEditModal(unit)} color="primary">
            {unit.leaseId ? 'Details' : 'Edit'}
          </Button>
        </TableCell>
        <TableCell data-qa="col-remove">
          <Button onClick={this.removeComp} color="primary">
            Remove
          </Button>
        </TableCell>
      </>
    )
  }
}

RentCompItem.propTypes = {
  index: PropTypes.number.isRequired,
  removeComp: PropTypes.func.isRequired,
  showBathrooms: PropTypes.bool.isRequired,
  showPerRoomAnalysis: PropTypes.bool.isRequired,
  showPerUnitSF: PropTypes.bool.isRequired,
  unit: PropTypes.object,
  unitRentPSFTimePeriod: PropTypes.string.isRequired,
  showEditModal: PropTypes.func.isRequired,
}

RentCompItem.defaultProps = {
  showBathrooms: false,
  showPerUnitSF: false,
  unit: {},
}

export default RentCompItem
