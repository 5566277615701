import React from 'react'
import PropTypes from 'prop-types'

import { Button, Dialog, DialogActions, DialogContentText, DialogContent, DialogTitle } from '@mui/material'

import { Labels } from '../constants'

export default class RemoveGroupDialog extends React.PureComponent {
  static propTypes = {
    onCancel: PropTypes.func.isRequired,
    onSubmit: PropTypes.func.isRequired,
  }

  render() {
    const { onCancel, onSubmit } = this.props

    return (
      <Dialog open aria-labelledby="remove-group-dialog">
        <DialogTitle id="remove-group-dialog">{Labels.DELETE_COMP_GROUP}</DialogTitle>
        <DialogContent>
          <DialogContentText>{Labels.ARE_YOU_SURE_YOU_WANT_TO_DELETE_COMP_GROUP}</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={onCancel}>{Labels.CANCEL}</Button>
          <Button onClick={onSubmit}>{Labels.OK}</Button>
        </DialogActions>
      </Dialog>
    )
  }
}
