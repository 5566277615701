import { isNaN, isUndefined } from 'lodash'

import { divide, roundToFourDecimals } from '../../utils/numberOperations'

export default {
  calculatePotentialGrossIncome(commercialIncome: number, residentialIncome: number, miscIncome: number): number {
    return commercialIncome + residentialIncome + miscIncome
  },
  calculateEffectiveGrossIncome(potentialGrossIncome: number, vcLosses: number): number {
    return potentialGrossIncome - vcLosses
  },
  calculateNetOperatingIncome(effectiveGrossIncome: number, totalOperatingExpenses: number, taxes: number): number {
    return effectiveGrossIncome - totalOperatingExpenses - taxes
  },
  calculateStabilizedOccupancyRate(effectiveGrossIncome: number, potentialGrossIncome: number): number {
    return effectiveGrossIncome / potentialGrossIncome
  },
  calculateTotalOperatingExpensesIncludingTaxes(totalOperatingExpenses: number, taxes: number): number {
    return totalOperatingExpenses + taxes
  },
  calculateTotalVCLosses(commercialVCLosses: number, residentialVCLosses: number, miscVCLosses: number): number {
    return commercialVCLosses + residentialVCLosses + miscVCLosses
  },
  calculateEffectiveMonthlyIncome(income: number, vcLossPercentage: number) {
    if (isUndefined(income) || isUndefined(vcLossPercentage)) {
      return 0
    }
    if (vcLossPercentage > 0) {
      income *= 1.0 - vcLossPercentage
    }

    return income / 12
  },
  calculateVCLossAmount(income: number, vcLossPercentage: number): number {
    const vcLossAmount = income * vcLossPercentage
    return isNaN(vcLossAmount) ? 0 : vcLossAmount
  },
  calculateIndicatedValue(netOperatingIncome: number, capRate: number): number {
    return divide(netOperatingIncome, capRate)
  },
  calculateDiscountRate(percentage: number, months: number): number {
    const discountRate = divide(1, (1 + percentage / 12) ** months)
    return roundToFourDecimals(discountRate)
  },
}
