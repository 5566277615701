import { GeneratedText } from '../../../../narrative/GeneratedText'

import { mapDTO, mapDataModel } from '../mappings'

export const TOTAL_DEPRECIATION_TEXT = 'The following table illustrates total depreciation and obsolescence:'

const generate = () => {
  const gt = new GeneratedText()

  gt.t`${TOTAL_DEPRECIATION_TEXT}`

  return gt.getParts()
}

export default { generate, mapDTO, mapDataModel }
