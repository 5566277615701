import React, { FC, memo, useMemo } from 'react'

import { divide, noop } from 'lodash'
import { ColumnDataTypeEnum, RowBasedTable } from '@bowery-valuation/ui-components'

import { formatCurrencyFloat, formatCurrencyInt } from 'client-shared/utils/numberFormatters'
import { getCustomColumnConfig, RowBasedTableColumn } from 'client-shared/utils/rowBasedTable'

type ComparisonTableOwnProps = {
  grossBuildingArea: number
  numberOfResidentialUnits: number
  mvsDirectCost: number
  subjectBudgetDirectCost: number
}

const ComparisonTable: FC<ComparisonTableOwnProps> = ({
  grossBuildingArea,
  numberOfResidentialUnits,
  mvsDirectCost,
  subjectBudgetDirectCost,
}) => {
  const getColumns = (): RowBasedTableColumn[] => {
    return [
      {
        name: 'label',
        label: 'Cost Estimate',
        type: ColumnDataTypeEnum.text,
        permanent: true,
        align: 'left',
        editable: false,
        suppressMovable: true,
      },
      {
        name: 'total',
        label: 'Subject Total',
        type: ColumnDataTypeEnum.text,
        permanent: true,
        align: 'right',
        editable: false,
        suppressMovable: true,
      },
      {
        name: 'totalPerUnit',
        label: 'Per Unit',
        type: ColumnDataTypeEnum.text,
        permanent: true,
        align: 'right',
        editable: false,
        suppressMovable: true,
      },
      {
        name: 'totalPerSf',
        label: 'Per SF',
        type: ColumnDataTypeEnum.text,
        permanent: true,
        align: 'right',
        editable: false,
        suppressMovable: true,
      },
    ]
  }
  const rows = [
    {
      readOnly: false,
      suppressMovable: true,
      permanent: true,
      type: ColumnDataTypeEnum.text,
      id: 'mvs',
      label: 'MVS Direct Cost',
      total: formatCurrencyInt(mvsDirectCost),
      totalPerSf: formatCurrencyFloat(divide(mvsDirectCost, grossBuildingArea)),
      totalPerUnit: formatCurrencyInt(divide(mvsDirectCost, numberOfResidentialUnits)),
      rowDef: { summary: false, hideAction: true },
    },
    {
      readOnly: true,
      suppressMovable: true,
      permanent: true,
      type: ColumnDataTypeEnum.text,
      id: 'subject',
      label: "Budget / Developer's Direct Cost",
      total: formatCurrencyInt(subjectBudgetDirectCost),
      totalPerSf: formatCurrencyFloat(divide(subjectBudgetDirectCost, grossBuildingArea)),
      totalPerUnit: formatCurrencyInt(divide(subjectBudgetDirectCost, numberOfResidentialUnits)),
      rowDef: { summary: false, hideAction: true },
    },
  ]
  const columns = useMemo(() => getColumns(), [])

  return (
    <RowBasedTable
      id="comparison-mvs-table"
      columns={columns}
      rows={rows}
      onRowUpdate={noop}
      onManyRowsUpdate={noop}
      getCustomColumnConfig={getCustomColumnConfig}
      actionCellHidden
      hideIndexColumn
      onColumnDragEnd={noop}
      onRowsDragEnd={noop}
      onColumnDelete={noop}
      onColumnUpdate={noop}
      onRowDelete={noop}
    />
  )
}

export default memo(ComparisonTable)
