import { DEFAULT_APPROACH_SETTINGS } from 'shared/constants/report'

export const CREATE_MODAL_BUTTONS = {
  ADVANCED_SEARCH: 'Advanced Search',
  NEXT: 'Next',
  STANDARD_SEARCH: 'Standard Search',
  SEARCH: 'Search',
  BACK_TO_SEARCH: 'Back to Search',
  BACK_TO_RESULTS: 'Back to Results',
}

// add new blocks report checkboxes here:
export const TOOL_SELECTION_ITEMS = {
  propertyPhotos: 'Subject Photos',
  propertyMaps: 'Maps',
  comparableMaps: 'Comparable Maps',
  reportInformation: 'Provided Document Upload',
}

export const REPORT_TYPE_LABELS = [
  { label: 'REPORT SETTINGS', value: false },
  { label: 'BOWERY BLOCKS', value: true },
]

export const TOOLTIP_TEXT = 'These Selections will be the functionality you have access to for this report in the app'

export const FORM_INITIAL_VALUES = {
  settings: {
    pullExternalData: true,
    valueConclusionType: '',
    incomeType: '',
    templateType: '',
    approaches: DEFAULT_APPROACH_SETTINGS,
  },
}
