import { ReportStates } from 'shared/constants'

import { arrayToKeyValuePairs } from 'client-shared/utils/ui/checkboxHelper'

export const TableColumnKeys = {
  PROPERTY_TYPE: 'propertyType',
  NUMBER: 'number',
  REPORT_CLIENTS: 'reportClients',
  ADDRESS: 'address',
  CITY: 'city',
  STATE: 'state',
  INSPECTION_DATE: 'inspectionDate',
  DUE_DATE: 'dueDate',
  STATUS: 'status',
  APPRAISERS: 'appraisers',
  ACTIONS: 'actions',
}

export const TABLE_COLUMNS = [
  { dataKey: TableColumnKeys.STATUS, label: 'Status', width: 180, flexGrow: 1.0 },
  { dataKey: TableColumnKeys.PROPERTY_TYPE, label: 'Type', width: 117, flexGrow: 1.0 },
  { dataKey: TableColumnKeys.NUMBER, label: 'Report #', width: 141, flexGrow: 1.0 },
  { dataKey: TableColumnKeys.REPORT_CLIENTS, label: 'Clients', disableSort: true, width: 267, flexGrow: 1.0 },
  { dataKey: TableColumnKeys.ADDRESS, label: 'Address', width: 240, flexGrow: 1.0 },
  { dataKey: TableColumnKeys.CITY, label: 'City/Town', width: 163, flexGrow: 1.0 },
  { dataKey: TableColumnKeys.STATE, label: 'State', width: 145, flexGrow: 1.0 },
  { dataKey: TableColumnKeys.INSPECTION_DATE, label: 'Inspection', width: 156, flexGrow: 1.0 },
  { dataKey: TableColumnKeys.DUE_DATE, label: 'Due Date', width: 167, flexGrow: 1.0 },
  { dataKey: TableColumnKeys.APPRAISERS, label: 'Appraisers', disableSort: true, width: 181, flexGrow: 1.0 },
  { dataKey: TableColumnKeys.ACTIONS, label: '', disableSort: true, width: 115 },
]

export const TABLE_ROW_HEIGHT = 49

export const InspectionDate = {
  UPCOMING: 'Upcoming',
  NOT_SCHEDULED: 'Not Scheduled',
  COMPLETED: 'Completed',
}

export const INSPECTION_DATE_OPTIONS = arrayToKeyValuePairs([
  InspectionDate.NOT_SCHEDULED,
  InspectionDate.UPCOMING,
  InspectionDate.COMPLETED,
])

export const DueDate = {
  OVERDUE: 'Overdue',
  DUE_TODAY: 'Due Today',
  DUE_TOMORROW: 'Due Tomorrow',
  DUE_THIS_WEEK: 'Due This Week',
  DUE_NEXT_WEEK: 'Due Next Week',
}

export const DUE_DATE_OPTIONS = arrayToKeyValuePairs([
  DueDate.OVERDUE,
  DueDate.DUE_TODAY,
  DueDate.DUE_TOMORROW,
  DueDate.DUE_THIS_WEEK,
  DueDate.DUE_NEXT_WEEK,
])

export const STATES_OPTIONS = [
  'Alabama',
  'Alaska',
  'Arizona',
  'Arkansas',
  'California',
  'Colorado',
  'Connecticut',
  'Delaware',
  'District Of Columbia',
  'Florida',
  'Georgia',
  'Hawaii',
  'Idaho',
  'Illinois',
  'Indiana',
  'Iowa',
  'Kansas',
  'Kentucky',
  'Louisiana',
  'Maine',
  'Maryland',
  'Massachusetts',
  'Michigan',
  'Minnesota',
  'Mississippi',
  'Missouri',
  'Montana',
  'Nebraska',
  'Nevada',
  'New Hampshire',
  'New Jersey',
  'New Mexico',
  'New York',
  'North Carolina',
  'North Dakota',
  'Ohio',
  'Oklahoma',
  'Oregon',
  'Pennsylvania',
  'Puerto Rico',
  'Rhode Island',
  'South Carolina',
  'South Dakota',
  'Tennessee',
  'Texas',
  'Utah',
  'Vermont',
  'Virginia',
  'Washington',
  'West Virginia',
  'Wisconsin',
  'Wyoming',
]

export const REPORT_STATUS_OPTIONS = arrayToKeyValuePairs([
  ReportStates.DRAFT,
  ReportStates.REVIEW,
  ReportStates.APPROVED,
  ReportStates.SUBMITTED,
  ReportStates.AUTOMATED,
])

export const AVATAR_IMAGE_WIDTH = 100
export const AVATAR_ICON_SIZE = 32
export const DISPLAYED_APPRAISERS_NUMBER = 3
export const MIN_LOADER_BATCH_SIZE = 300
export const INFINITE_SCROLL_PREFETCH_THRESHOLD = 150
