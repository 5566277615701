import React from 'react'
import PropTypes from 'prop-types'

import { compose, onlyUpdateForPropTypes, setPropTypes } from 'recompose'

import Remove from '@mui/icons-material/Remove'
import { Stack, TableCell, TextField as TextInputField } from '@mui/material'

import { CheckboxWithLabel, DropDown, IconButton, Number as NumberField, Switch, Text } from 'client-shared/components'
import { RentTypesList } from 'shared/constants/buildingComparables'
import { formatCurrencyFloat } from 'client-shared/utils/numberFormatters'
import { required } from 'client-shared/utils/validation'

import { bathroomsValidation, roomsValidation } from '../validation'
import { RentRollOutdoorSpacesList } from '../../../../../report/constants'
import { RENTROLL_COLUMN_NAMES } from '../../../constants'

const RentRollCell = ({
  column,
  index,
  isDisabledInclude,
  isNotReportedSquareFeet,
  isRequired,
  onRemoveUnit,
  rentPerRoom,
  unitName,
}) => {
  switch (column) {
    case RENTROLL_COLUMN_NAMES.INCLUDE:
      return (
        <TableCell>
          <Switch name={unitName} disabled={!isDisabledInclude} />
        </TableCell>
      )
    case RENTROLL_COLUMN_NAMES.REMOVE:
      return (
        <TableCell>
          <IconButton onClick={() => onRemoveUnit(index)}>
            <Remove />
          </IconButton>
        </TableCell>
      )
    case RENTROLL_COLUMN_NAMES.INDEX:
      return <TableCell>{index + 1}</TableCell>

    case RENTROLL_COLUMN_NAMES.OUTDOOR:
      return (
        <TableCell>
          <DropDown name={unitName} items={RentRollOutdoorSpacesList} />
        </TableCell>
      )
    case RENTROLL_COLUMN_NAMES.RENT_TYPE:
      return (
        <TableCell>
          <DropDown name={unitName} items={RentTypesList} />
        </TableCell>
      )

    case RENTROLL_COLUMN_NAMES.SQUARE_FOOTAGE:
      return (
        <TableCell>
          <Stack alignItems="center" direction="row" spacing={1}>
            <NumberField
              adornment="SF"
              decimalScale={2}
              disabled={isNotReportedSquareFeet}
              name={unitName}
              thousandSeparator=","
              warn={isNotReportedSquareFeet ? [] : [required]}
            />
            <CheckboxWithLabel
              fullWidth={false}
              label="NR"
              name={unitName.replace(RENTROLL_COLUMN_NAMES.SQUARE_FOOTAGE, 'isNotReportedSquareFeet')}
            />
          </Stack>
        </TableCell>
      )
    case RENTROLL_COLUMN_NAMES.ROOMS:
    case RENTROLL_COLUMN_NAMES.BEDROOMS:
      const validations = []

      if (column === RENTROLL_COLUMN_NAMES.ROOMS) {
        validations.push(roomsValidation)
      } else if (column === RENTROLL_COLUMN_NAMES.BEDROOMS) {
        validations.push(required)
      } else if (isRequired) {
        validations.push(required)
      }

      return (
        <TableCell>
          <NumberField warn={validations} name={unitName} />
        </TableCell>
      )
    case RENTROLL_COLUMN_NAMES.BATHROOMS: {
      const validations = [bathroomsValidation]
      return (
        <TableCell>
          <NumberField decimalScale={1} name={unitName} validate={validations} allowNegative={false} />
        </TableCell>
      )
    }
    case RENTROLL_COLUMN_NAMES.RENT:
      return (
        <TableCell>
          <NumberField
            decimalScale={2}
            thousandSeparator
            name={unitName}
            warn={required}
            adornment="$"
            adornmentPosition="start"
          />
        </TableCell>
      )
    case RENTROLL_COLUMN_NAMES.RENT_PER_ROOM:
      return (
        <TableCell>
          <TextInputField
            disabled
            size="small"
            style={{ maxWidth: 100 }}
            value={formatCurrencyFloat(rentPerRoom) || '-'}
          />
        </TableCell>
      )

    default:
      return (
        <TableCell>
          <Text size="small" style={{ maxWidth: 80 }} name={unitName} />
        </TableCell>
      )
  }
}

export default compose(
  onlyUpdateForPropTypes,
  setPropTypes({
    onRemoveUnit: PropTypes.func.isRequired,
    index: PropTypes.number.isRequired,
    unitName: PropTypes.string,
    isRequired: PropTypes.bool,
    column: PropTypes.string.isRequired,
    isDisabledInclude: PropTypes.bool,
    rentPerRoom: PropTypes.number.isRequired,
    isNotReportedSquareFeet: PropTypes.bool,
  })
)(RentRollCell)
