import { isEmpty, lowerCase } from 'lodash'

import { STATE_NAMES } from 'shared/constants/states'
import { formatList } from 'shared/utils/textHelpers'

import { Conditions, LocationDetails } from './constants'
import { getUnitType } from './tools'

const GeneralLocationDescription = {
  [Conditions.INFERIOR]: 'is inferior to the comparables',
  [Conditions.LOW_END]: 'competes towards the lower end of the range',
  [Conditions.MIDDLE]: 'competes towards the middle of the range',
  [Conditions.HIGH_END]: 'competes towards the higher end of the range',
  [Conditions.SUPERIOR]: 'is superior to the comparables',
}

const LocationProxinityToTransportText = {
  [Conditions.INFERIOR]: 'as the subject has inferior proximity to transport links than the comparables',
  [Conditions.LOW_END]:
    'as the subject competes towards the lower end of the range in terms of proximity to transport links',
  [Conditions.MIDDLE]: 'as the subject and comparables have similar proximity to transport links',
  [Conditions.HIGH_END]:
    'as the subject competes towards the higher end of the range in terms of proximity to transport links',
  [Conditions.SUPERIOR]: 'as the subject has superior proximity to transport links than the comparables',
}

const LocationParkViewText = {
  [Conditions.INFERIOR]: 'The subject, unlike the majority of the comparables, has inferior park views.',
  [Conditions.LOW_END]: 'The subject competes towards the lower end of the range in terms of park views.',
  [Conditions.MIDDLE]: 'The subject and comparables have similar park views.',
  [Conditions.HIGH_END]: 'The subject competes towards the higher end of the range in terms of park views.',
  [Conditions.SUPERIOR]: 'The subject, unlike the majority of the comparables, has superior park views.',
}

const LocationNeighborhood = {
  [Conditions.INFERIOR]:
    'Furthermore, the subject is located in an inferior neighborhood than that of the comparables.',
  [Conditions.LOW_END]:
    'Furthermore, the comparables are located in slightly superior neighborhoods than the subject’s neighborhood.',
  [Conditions.MIDDLE]: 'Furthermore, the subject and comparables are located in similar neighborhoods.',
  [Conditions.HIGH_END]:
    'Furthermore, the comparables are located in slightly inferior neighborhoods than the subject’s neighborhood.',
  [Conditions.SUPERIOR]: 'Furthermore, the subject is located in a superior neighborhood than that of the comparables.',
}

const GeneralDiscussionDescription = {
  [Conditions.INFERIOR]: 'are inferior to the comparables',
  [Conditions.LOW_END]: 'compete towards the lower end of the range',
  [Conditions.MIDDLE]: 'compete towards the middle of the range',
  [Conditions.HIGH_END]: 'compete towards the higher end of the range',
  [Conditions.SUPERIOR]: 'are superior to the comparables',
}

const getLocationInNeighborhoodSentence = (generalLocation, neighborhood = '[NEIGHBORHOOD]') => {
  switch (generalLocation) {
    case Conditions.INFERIOR: {
      return `The subject’s area within ${neighborhood} is inferior than that of the comparables.`
    }
    case Conditions.LOW_END: {
      return `The subject’s area within ${neighborhood} is slightly inferior than that of the comparables.`
    }
    case Conditions.MIDDLE: {
      return `The subject and comparables are located in similar areas within ${neighborhood}.`
    }
    case Conditions.HIGH_END: {
      return `The subject’s area within ${neighborhood} is slightly superior than that of the comparables.`
    }
    case Conditions.SUPERIOR: {
      return `The subject’s area within ${neighborhood} is superior than that of the comparables.`
    }
    default: {
      return ''
    }
  }
}

export const generateLocationDiscussionCommentary = (neighborhood, location = {}) => {
  const { general, details = [] } = location
  const locationText = GeneralLocationDescription[general]

  const commentarySentences = []
  const firstSentence = `The subject property ${locationText || '[LOCATION]'} in terms of locational appeal`

  if (details.includes(LocationDetails.PROXIMITY_TO_TRANSPORT)) {
    commentarySentences.push(`${firstSentence} ${LocationProxinityToTransportText[general]}.`)
  } else {
    commentarySentences.push(`${firstSentence}.`)
  }

  if (details.includes(LocationDetails.PARK_VIEWS)) {
    commentarySentences.push(LocationParkViewText[general])
  }

  if (details.includes(LocationDetails.NEIGHBORHOOD)) {
    commentarySentences.push(LocationNeighborhood[general])
  }

  if (details.includes(LocationDetails.LOCATION_IN_NEIGHBORHOOD)) {
    const locationInNeighborhoodSentence = getLocationInNeighborhoodSentence(general, neighborhood)
    commentarySentences.push(locationInNeighborhoodSentence)
  }

  return commentarySentences.join(' ')
}

export const generateSizeDiscussionCommentary = (unitGroupName, size) => {
  const unitType = getUnitType(unitGroupName)
  const sizeText = GeneralDiscussionDescription[size]
  return `The subject’s ${unitType} units ${sizeText || '[SIZE]'} in terms of size.`
}

export const generateConditionDiscussionCommentary = (unitGroupName, condition) => {
  const unitType = getUnitType(unitGroupName)
  const conditionText = GeneralDiscussionDescription[condition]
  return `Moreover, the subject’s ${unitType} units ${conditionText || '[CONDITION]'} in terms of condition.`
}

export const generateFinishesDiscussionCommentary = (unitGroupName, finishes) => {
  const { general, details = [] } = finishes
  const additionalFinishes = formatList(details.map(lowerCase))
  const unitType = getUnitType(unitGroupName)

  switch (general) {
    case Conditions.INFERIOR: {
      const sentence = `The subject’s ${unitType} units have inferior interior finishes`
      return isEmpty(details) ? `${sentence}.` : `${sentence} as they have inferior ${additionalFinishes}.`
    }

    case Conditions.LOW_END: {
      // eslint-disable-next-line max-len
      const sentence = `The subject’s ${unitType} units compete towards the lower end of the range in terms of interior finishes`
      return isEmpty(details)
        ? `${sentence}.`
        : `${sentence} as the subject’s finishes such as ${additionalFinishes}` +
            ` compete towards the lower end of the range.`
    }

    case Conditions.MIDDLE: {
      // eslint-disable-next-line max-len
      const sentence = `The subject’s ${unitType} units compete towards the middle of the range in terms of interior finishes`
      return isEmpty(details)
        ? `${sentence}.`
        : `${sentence} as the subject and comparables have similar interior finishes such as ${additionalFinishes}.`
    }

    case Conditions.HIGH_END: {
      // eslint-disable-next-line max-len
      const sentence = `The subject’s ${unitType} units compete towards the higher end of the range in terms of interior finishes`
      return isEmpty(details)
        ? `${sentence}.`
        : `${sentence} as the subject’s finishes such as ${additionalFinishes}` +
            ` compete towards the higher end of the range.`
    }

    case Conditions.SUPERIOR: {
      const sentence = `The subject’s ${unitType} units have superior interior finishes`
      return isEmpty(details) ? `${sentence}.` : `${sentence} as they have superior ${additionalFinishes}.`
    }

    default: {
      return ''
    }
  }
}

export const generateAmenitiesDiscussionCommentary = (unitGroupName, amenities) => {
  const { general, details = [] } = amenities
  const additionalAmenities = formatList(details.map(lowerCase))
  const unitType = getUnitType(unitGroupName)

  switch (general) {
    case Conditions.INFERIOR: {
      const sentence = `The subject’s ${unitType} units have inferior amenities`
      return isEmpty(details) ? `${sentence}.` : `${sentence} as they have inferior ${additionalAmenities}.`
    }

    case Conditions.LOW_END: {
      // eslint-disable-next-line max-len
      const sentence = `The subject’s ${unitType} units compete towards the lower end of the range in terms of amenities`
      return isEmpty(details)
        ? `${sentence}.`
        : `${sentence} as the subject’s amenities such as ${additionalAmenities}` +
            ` compete towards the lower end of the range.`
    }

    case Conditions.MIDDLE: {
      // eslint-disable-next-line max-len
      const sentence = `The subject’s ${unitType} units compete towards the middle of the range in terms of amenities`
      return isEmpty(details)
        ? `${sentence}.`
        : `${sentence} as the subject and comparables have similar amenities such as ${additionalAmenities}.`
    }

    case Conditions.HIGH_END: {
      // eslint-disable-next-line max-len
      const sentence = `The subject’s ${unitType} units compete towards the higher end of the range in terms of amenities`
      return isEmpty(details)
        ? `${sentence}.`
        : `${sentence} as the subject’s amenities such as ${additionalAmenities}` +
            ` compete towards the higher end of the range.`
    }

    case Conditions.SUPERIOR: {
      const sentence = `The subject’s ${unitType} units have superior amenities`
      return isEmpty(details) ? `${sentence}.` : `${sentence} as they have superior ${additionalAmenities}.`
    }

    default: {
      return ''
    }
  }
}

export const generateIntroduction = ({ state, neighborhood, surroundingUses }) => {
  let usesDesc = ''
  const uses = []
  for (var x in surroundingUses) {
    if (surroundingUses.hasOwnProperty(x) && surroundingUses[x]) {
      uses.push(x)
    }
  }
  if (uses.length === 1) {
    usesDesc = `a primarily ${uses[0]} section of `
  } else if (uses.length > 1) {
    usesDesc = `a mixed-use section of `
  }
  const generatedText =
    'Important considerations in determining potential rental value include location,' +
    ' access to transportation and neighborhood amenities, and building design and condition.' +
    ` The subject is in ${usesDesc}${neighborhood},` +
    ` a neighborhood that has seen significant investment in the past 5 to 10 years.` +
    ` The site has good access to ${
      state === STATE_NAMES.NY ? 'mass ' : ' '
    }transportation, and the block is appealing.` +
    ' The comparables are similar in their physical and locational appeal and are good indicators of value.' +
    ' Thus, our analysis of the forecast of market rents is presented:'
  return generatedText
}
