import React from 'react'
import PropTypes from 'prop-types'

import { lightBlue } from '@mui/material/colors'
import { withStyles } from '@mui/styles'
import { camelCase, isNumber } from 'lodash'

const styles = theme => ({
  container: {
    position: 'relative',
    width: '100%',
    height: theme.spacing(7),
  },
  line: {
    position: 'absolute',
    top: '50%',
    left: 0,
    width: '100%',
    height: 2,
    marginTop: -1,
    background: lightBlue[200],
  },
  dot: {
    position: 'absolute',
    top: '50%',
    width: 10,
    height: 10,
    marginTop: -5,
    marginLeft: -5,
    borderRadius: '50%',
    border: '2px solid',
  },
  tooltip: {
    position: 'absolute',
    left: 12,
    bottom: 10,
    padding: 4,
    border: '2px solid',
  },
  lowerTip: {
    position: 'absolute',
    top: 14,
    left: -14,
    bottom: 'auto',
    padding: 5,
    textAlign: 'center',
  },
})

class ValuesLine extends React.PureComponent {
  calcPercent = val => {
    const { min, max } = this.props

    if (max === min) {
      return 0
    }

    if (isNumber(val) && !isNaN(val)) {
      return 100 * ((val - min) / (max - min))
    }

    return 0
  }

  getToolTip(item) {
    const { classes } = this.props
    const tooltip = !!item.tooltip.formatter ? item.tooltip.formatter(item.value) : item.value

    if (!tooltip) {
      return null
    }

    return (
      <div className={classes.tooltip} style={{ ...item.tooltip.style }}>
        {tooltip}
      </div>
    )
  }

  getLowerTip(item) {
    const { classes } = this.props
    const lowerTip = item.lowerTip.formatter(item.value)

    if (!lowerTip) {
      return null
    }

    return (
      <div className={classes.lowerTip} style={{ ...item.lowerTip.style }} data-qa={`${camelCase(lowerTip)}-lowtip`}>
        {lowerTip}
      </div>
    )
  }

  render() {
    const { classes, items } = this.props

    return (
      <div className={classes.container}>
        <div className={classes.line} />
        {items.map((item, index) => {
          return (
            <div
              key={index}
              className={classes.dot}
              style={{
                left: `${this.calcPercent(item.value)}%`,
                ...item.style,
              }}
            >
              {item.tooltip && this.getToolTip(item)}
              {item.lowerTip && this.getLowerTip(item)}
            </div>
          )
        })}
      </div>
    )
  }
}

const StyledValuesLine = withStyles(styles)(ValuesLine)

StyledValuesLine.displayName = 'ValuesLine'

StyledValuesLine.propTypes = {
  items: PropTypes.array.isRequired,
  min: PropTypes.number,
  max: PropTypes.number,
}

export default StyledValuesLine
