import React from 'react'
import { InputAdornment, OutlinedInput } from '@mui/material'
import { merge } from 'lodash'
import { makeStyles } from '@mui/styles'

import NumberFormat from 'react-number-format'

import InputWrapper from '../InputWrapper'
import { textAndNumberStyles } from '../shared'

const useStyles = makeStyles(theme => {
  const baseStyles = textAndNumberStyles(theme)

  return merge(baseStyles, {
    outlineInput: {
      padding: '10.5px 14px',

      '& input': {
        padding: 0,
      },
    },
  })
})

const NumberField = ({
  prefix,
  decimalScale,
  fixedDecimalScale,
  allowNegative,
  thousandSeparator,
  value,
  ...restProps
}) => (
  <NumberFormat
    {...restProps}
    decimalScale={decimalScale}
    fixedDecimalScale={fixedDecimalScale}
    allowNegative={allowNegative}
    thousandSeparator={thousandSeparator}
    value={value}
  />
)

const Number = ({
  name,
  type,
  label,
  helperText,
  placeholder,
  tooltipText,
  value,
  onChange,
  onBlur,
  onFocus,
  startAdornment,
  endAdornment,
  required = false,
  disabled = false,
  error = false,
  className,
  extraTitleElements,

  decimalScale,
  fixedDecimalScale = false,
  allowNegative = false,
  thousandSeparator = true,
  ...restProps
}) => {
  const classes = useStyles()

  let startAdornmentMarkup = null
  if (startAdornment) {
    startAdornmentMarkup = <InputAdornment position="start">{startAdornment}</InputAdornment>
  }

  let endAdornmentMarkup = null
  if (endAdornment) {
    endAdornmentMarkup = <InputAdornment position="end">{endAdornment}</InputAdornment>
  }

  const inputProps = {
    decimalScale,
    fixedDecimalScale,
    allowNegative,
    thousandSeparator,
    value,
  }

  const inputElementMarkup = (
    <OutlinedInput
      name={name}
      type={type}
      value={value}
      onBlur={onBlur}
      onChange={onChange}
      onFocus={onFocus}
      placeholder={placeholder}
      startAdornment={startAdornmentMarkup}
      endAdornment={endAdornmentMarkup}
      inputComponent={NumberField}
      inputProps={inputProps}
      classes={{
        root: classes.outlineInput,
        error: classes.error,
        disabled: classes.disabled,
        input: classes.nativeInput,
      }}
      margin="dense"
    />
  )

  return (
    <InputWrapper
      label={label}
      disabled={disabled}
      tooltipText={tooltipText}
      error={error}
      required={required}
      helperText={helperText}
      extraTitleElements={extraTitleElements}
      {...restProps}
    >
      {inputElementMarkup}
    </InputWrapper>
  )
}

export default React.memo(Number)
