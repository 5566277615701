import { get, set, isNaN } from 'lodash'
import createDecorator from 'final-form-calculate'

import ResidentialCalculations from 'shared/report-calculations/income-approach/residential/residential-calculations'

const pgi = [
  createDecorator({
    field: /rentTypeSummary\[\d]\.increase/,
    updates: (value, fieldPath, allValues) => {
      const summaryItemPath = fieldPath.replace('.increase', '')
      const summaryItem = get(allValues, summaryItemPath)
      const { increase } = summaryItem
      if (!isNaN(increase)) {
        const residentialPGIByType = ResidentialCalculations.calculatePotentialResidentialIncomeByType(summaryItem)

        const updatePath = summaryItemPath + '.pgi'
        set(allValues, updatePath, residentialPGIByType)

        const totalPGI = ResidentialCalculations.calculateTotalPotentialResidentialIncomeFromSummary(
          allValues.rentTypeSummary
        )

        return {
          [updatePath]: residentialPGIByType,
          totalPGI,
        }
      }
      return {}
    },
  }),
]

export default [...pgi]
