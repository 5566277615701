import React from 'react'
import { connect } from 'react-redux'

const HideIfIsCMS = ({ isCMS, children }) => {
  if (isCMS) {
    return null
  }
  return <>{children}</>
}

const mapStateToProps = _state => {
  const path = window.location.pathname
  const isCMS = path.substring(0, 4) === '/cms'

  return {
    isCMS,
  }
}

export default connect(mapStateToProps)(HideIfIsCMS)
