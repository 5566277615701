import { startCase, lowerCase, upperFirst, get, camelCase, toLower, replace } from 'lodash'

const CAPITALIZED_ABBREVIATIONS = ['llc', 'lp', 'llp'].reduce((array, current) => {
  array.push(current, `${current.split('').join('.')}.`)
  return array
}, [])
/*
Input: "20 NE Washington St."
capitalCase: "20 Ne Washington St"
toCapitalCase: "20 Ne Washington St."
titleCase: "20 NE Washington St."
Input: "20 N.E. Washington St."
capitalCase: "20 N E Washington St"
toCapitalCase: "20 N.e. Washington St."
titleCase: "20 N.E. Washington St."
Input: "27-29 Mo-Cap Dr"
capitalCase: "27 29 Mo Cap Dr"
toCapitalCase: "27-29 Mo-cap Dr"
titleCase: "27-29 Mo-Cap Dr"
Input: "Happy hours llc"
capitalCase: "Happy Hours Llc"
toCapitalCase: "Happy Hours LLC"
titleCase: "Happy Hours Llc"
*/
export const capitalCase = value => startCase(lowerCase(value))
export const toCapitalCase = value =>
  (value || '')
    .toLowerCase()
    .split(' ')
    .reduce(
      (words, word) => `${words} ${CAPITALIZED_ABBREVIATIONS.includes(word) ? word.toUpperCase() : upperFirst(word)}`,
      ''
    )
    .trim()
export const capitalizeFirstLetter = string => string.charAt(0).toUpperCase() + string.slice(1)
export const titleCase = string =>
  string
    .split(' ')
    .map(word => capitalizeFirstLetter(word))
    .join(' ')
export const getLabelFromValue = (constants = [], value = null) => {
  return get(constants.find(item => item.value === value), 'label') || ''
}

export const handleOtherUseLabel = (constants = [], value = null) => {
  const labelValue = getLabelFromValue(constants, value)
  if (labelValue === '') {
    return value
  }
  return labelValue
}

export const formatId = name => {
  return camelCase(toLower(replace(name, '&', 'And')))
}

export const articleGen = (word = '') => {
  // this is not a fool-proof article generator, but works for this use case.
  const vowels = ['a', 'e', 'i', 'o', 'u']
  if (word.length && vowels.includes(word[0].toLowerCase())) {
    return 'an'
  }
  return 'a'
}

export const articleGenForNumbers = (number = 0) => {
  if (!number) {
    return 'a'
  }
  const numAsString = number.toString() || ''
  if (numAsString[0] === '8' || number === 11) {
    return 'an'
  }
  return 'a'
}
