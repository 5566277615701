import React from 'react'
import PropTypes from 'prop-types'
import ObjectID from 'bson-objectid'
import { Button, Paper, Stack, Typography } from '@mui/material'
import { Template } from 'client-shared/components/Template'

import { removeByIndex } from 'client-shared/utils/immutability'

import { Labels } from './constants'
import LandAdjustmentsTableView from './LandAdjustmentsTableView'

class LandAdjustmentsTable extends React.PureComponent {
  constructor(props) {
    super(props)
    this.concludedCapRate = React.createRef()
  }

  addDeduction = () => {
    const { change } = this.props
    const newLandDeduction = {
      _id: ObjectID().toString(),
      name: '',
      value: 0,
    }

    const { landDeductions } = this.props.values
    change('landDeductions', [...landDeductions, newLandDeduction])
  }

  removeDeduction = (_, index) => {
    const { change, values } = this.props
    const { landDeductions } = values
    change('landDeductions', removeByIndex(landDeductions, index))
  }

  onConcludedCapRateRowClick = () => {
    if (this.concludedCapRate) {
      this.concludedCapRate.current.focus()
    }
  }

  render() {
    const { dataSourceType, title, values } = this.props
    const { onConcludedCapRateRowClick, removeDeduction } = this

    return (
      <Paper data-qa="landAdjustmentsTable-section">
        <Stack spacing={2}>
          <Template is="auto-flow / 1fr auto" placeItems="center start">
            <Typography variant="h6">{title}</Typography>
          </Template>
          <Button data-qa="add-deduction-btn" onClick={this.addDeduction} variant="outlined">
            {Labels.ADD_DEDUCTION}
          </Button>
          <LandAdjustmentsTableView
            {...{
              dataSourceType,
              onConcludedCapRateRowClick,
              removeDeduction,
              values,
            }}
          />
        </Stack>
      </Paper>
    )
  }
}

LandAdjustmentsTable.propTypes = {
  dataSourceType: PropTypes.number.isRequired,
  title: PropTypes.string.isRequired,
  values: PropTypes.object.isRequired,
}

export default LandAdjustmentsTable
