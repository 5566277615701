import { isArray } from 'lodash'

export const updateIn = (data, path, updater) => {
  if (path.length) {
    const nextPropName = path.shift()
    const nextData = data[nextPropName]

    if (isArray(data)) {
      const index = parseInt(nextPropName, 10)

      return replaceByIndex(data, updateIn(nextData, path, updater), index)
    } else {
      return {
        ...data,
        [nextPropName]: updateIn(nextData, path, updater),
      }
    }
  }

  return updater(data)
}

export const setIn = (data, path, value) => {
  return updateIn(data, path, () => value)
}

export const insertByIndex = (array, item, index) => {
  return [...array.slice(0, index), item, ...array.slice(index)]
}

export const removeByIndex = (array, index) => {
  return [...array.slice(0, index), ...array.slice(index + 1)]
}

export const replaceByIndex = (array, item, index) => {
  return [...array.slice(0, index), item, ...array.slice(index + 1)]
}
