import React from 'react'
import { Map } from 'leaflet'
import PropTypes from 'prop-types'
import { grey } from '@material-ui/core/colors'
import { Marker, Polyline } from 'react-leaflet'

export default class ConnectedIconLabel extends React.PureComponent {
  constructor(props) {
    super(props)

    const labelPosition = [props.position[0] + props.labelOffset, props.position[1] + props.labelOffset]
    this.initialLabelPosition = labelPosition
    this.state = {
      iconPosition: props.position,
      labelPosition,
      lastMapBoundsLatLng: null,
    }
  }

  static propTypes = {
    position: PropTypes.arrayOf(PropTypes.number).isRequired,
    icon: PropTypes.element.isRequired,
    label: PropTypes.shape({
      icon: PropTypes.any.isRequired,
      draggable: PropTypes.bool,
    }).isRequired,
    labelOffset: PropTypes.number,
    connectorColor: PropTypes.string,
  }

  static defaultProps = {
    labelOffset: 0.001,
  }

  static contextTypes = {
    map: PropTypes.instanceOf(Map),
  }

  mapBoundsContainLatLng = latLng => {
    const map = this.context.map
    return map.getBounds().contains(latLng)
  }

  onLabelDrag = marker => {
    const { latlng: latLng } = marker
    let lastMapBoundsLatLng = null

    if (this.mapBoundsContainLatLng(latLng)) {
      lastMapBoundsLatLng = latLng
    }

    this.setState(prevState => ({
      lastMapBoundsLatLng: lastMapBoundsLatLng || prevState.lastMapBoundsLatLng,
      labelPosition: [latLng.lat, latLng.lng],
    }))
  }

  onLabelDragend = ({ target: marker }) => {
    const { lastMapBoundsLatLng } = this.state
    const latLng = marker.getLatLng()

    if (!this.mapBoundsContainLatLng(latLng)) {
      marker.setLatLng(lastMapBoundsLatLng)
    }
  }

  render() {
    const { icon, label, connectorColor } = this.props
    const { iconPosition, labelPosition } = this.state
    const { initialLabelPosition } = this

    return (
      <React.Fragment>
        {icon}
        <Marker {...label} position={initialLabelPosition} onMove={this.onLabelDrag} onDragend={this.onLabelDragend} />
        <Polyline positions={[iconPosition, labelPosition]} color={connectorColor || grey[900]} weight={2} />
      </React.Fragment>
    )
  }
}
