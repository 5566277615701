import React from 'react'
import PropTypes from 'prop-types'
import { Form } from 'react-final-form'
import { makeStyles } from '@mui/styles'
import { Dialog, DialogActions, DialogContent, DialogTitle, Typography } from '@material-ui/core'

import { required } from 'client-shared/utils/validation'
import { DIALOG_Z_INDEX } from 'client-shared/constants'

import { Text, Button } from '../../../../shared/components'

const useStyles = makeStyles({
  dialog: {
    // override .MuiModal rule:
    '&&': {
      zIndex: DIALOG_Z_INDEX,
    },
  },
  dialogActions: {
    justifyContent: 'space-between',
  },
})

const SaveDialog = ({
  open,
  initialLabel,
  placeholder,
  onSave,
  onCancel,
  title,
  validate,
  label,
  saveButtonText,
  description,
}) => {
  const classes = useStyles()

  return (
    open && (
      <Form
        onSubmit={onSave}
        initialValues={{
          label: initialLabel,
        }}
        render={({ handleSubmit, invalid, form }) => {
          const cancelSave = () => {
            onCancel()
          }

          const save = ({ label }) => {
            handleSubmit({ label })
          }

          return (
            <Dialog fullWidth open={open} className={classes.dialog} maxWidth="sm">
              <DialogTitle>{title}</DialogTitle>
              <DialogContent>
                {description && (
                  <Typography>
                    {description}
                    <br />
                    <br />
                  </Typography>
                )}
                <Text placeholder={placeholder} name="label" label={label} required validate={validate} />
              </DialogContent>
              <DialogActions className={classes.dialogActions}>
                <Button onClick={cancelSave} color="primary">
                  Cancel
                </Button>
                <Button disabled={invalid} onClick={save} color="primary" variant="contained">
                  {saveButtonText}
                </Button>
              </DialogActions>
            </Dialog>
          )
        }}
      />
    )
  )
}

SaveDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  validate: PropTypes.func,
  label: PropTypes.string,
  saveButtonText: PropTypes.string,
  description: PropTypes.string,
  placeholder: PropTypes.string,
}

SaveDialog.defaultProps = {
  validate: required,
  label: 'Title',
  saveButtonText: 'Save',
}

export default SaveDialog
