import { get, flatMap, keyBy, orderBy, meanBy, uniq } from 'lodash'
import { createSelector } from 'reselect'

import { sortUnitGroups } from 'shared/helpers/incomeApproach'

import { calculateDistance } from '../../../../../../shared/utils/geography'
import { getPropertySubtype, findProperty } from '../../../../../../shared/utils/propertyHelper'

const selectFormValues = (state, props) => get(props, 'form.values', {})

const getSqft = comp => {
  const sqft = get(comp, 'sqft')
  return parseFloat(sqft)
}

const sortRentComps = (rentComps, selectedSortType) => {
  switch (selectedSortType) {
    case 'Newest':
      return orderBy(rentComps, ['dateOfValue'], ['desc'])
    case 'Oldest':
      return orderBy(rentComps, ['dateOfValue'], ['asc'])
    case 'Largest':
      return orderBy(rentComps, getSqft, ['desc'])
    case 'Smallest':
      return orderBy(rentComps, getSqft, ['asc'])
    default:
      return rentComps
  }
}

const getBedroomCountAbbreviatedLabel = bedroomCount => {
  const labels = {
    0: 'S',
    1: '1B',
    2: '2B',
    3: '3B',
    4: '4B',
    5: '5B',
    6: '6B',
    7: '7B',
    8: '8B',
    9: '9B',
  }
  return labels[bedroomCount] || '9+'
}

const getBedroomCountsLabel = unitMix => {
  const labels = unitMix
    .filter(unitMixItem => unitMixItem.groupingParameters && unitMixItem.groupingParameters.length)
    .map(unitMixItem => unitMixItem.groupingParameters.find(parameter => parameter.path === 'bedrooms').value)
    .sort()
    .map(getBedroomCountAbbreviatedLabel)

  return uniq(labels).join(',')
}

export const selectSubject = createSelector(
  state => get(state, 'report.reportData.incomeApproach.residentialIncome.residentialRentComps') || {},
  formValues => {
    const subject = get(formValues, 'subject', {})
    const unitMix = get(subject, 'unitMix', [])
    return {
      ...subject,
      id: get(subject, '_id'),
      bedroomCounts: getBedroomCountsLabel(unitMix),
      averageRent: meanBy(unitMix, 'avgRent'),
      residentialUnits: get(subject, 'residentialUnits'),
    }
  }
)

const mapProperty = property => {
  const unitMix = sortUnitGroups(get(property, 'unitMixItems', []))

  const amenities = get(property, 'property.propertyAmenities.amenities', {})
  const image = get(property, 'property.image.cdnUrl')
  const subType = getPropertySubtype(get(property, 'property.floors'), get(property, 'property.isGardenStyle'))
  return {
    id: get(property, '_id'),
    _id: get(property, '_id'),
    propertyId: get(property, 'property.previousRevisionId'),
    address: get(property, 'property.location.streetAddress'),
    fullAddressWithZip: get(property, 'property.location.fullAddressWithZip'),
    units: get(property, 'property.residentialUnitCount'),
    bedroomCounts: getBedroomCountsLabel(unitMix),
    averageRent: meanBy(unitMix, 'avgRent'),
    amenities,
    unitMix,
    subType,
    image,
  }
}

const mapBuildingComps = (buildingComp, subject) => {
  const buildingCompCoords = get(buildingComp, 'property.location.coords', {})
  const distanceFromSubject =
    get(buildingComp, 'distanceFromSubject') || calculateDistance(buildingCompCoords, subject.coords)
  return {
    ...mapProperty(buildingComp),
    propertyId: get(buildingComp, 'property._id'),
    elevator: get(buildingComp, 'property.hasElevator'),
    residentialUnits: get(buildingComp, 'residentialUnits'),
    asOfDate: get(buildingComp, 'property.asOfDate'),
    distance: distanceFromSubject,
  }
}

export const selectSelectedBuildingCompsFromFormValues = createSelector(selectFormValues, formValues =>
  get(formValues, 'selectedBuildingComps', [])
)

export const selectSelectedBuildingComps = createSelector(
  selectSelectedBuildingCompsFromFormValues,
  selectSubject,
  (selectedBuildingComps, subject) => {
    const mappedSelectedBuildingComps = selectedBuildingComps.map(buildingComp =>
      mapBuildingComps(buildingComp, subject)
    )
    return mappedSelectedBuildingComps
  }
)

export const selectRemovedBuildingComps = createSelector(selectFormValues, selectSubject, (formValues, subject) => {
  const removedBuildingComps = get(formValues, 'removedBuildingComps', [])
  return removedBuildingComps.map(buildingComp => mapBuildingComps(buildingComp, subject))
})

export const selectPropertySearchResults = createSelector(
  state => get(state, 'buildingComp.properties', []),
  selectSelectedBuildingCompsFromFormValues,
  (properties, selectedBuildingComps) => {
    return properties.map(property => {
      const selectedProperty = findProperty(selectedBuildingComps, property)
      return {
        ...mapProperty(property),
        isSelected: !!selectedProperty.item,
      }
    })
  }
)

export const selectPropertiesDetails = createSelector(
  state => get(state, 'buildingComp.properties', []),
  selectSelectedBuildingCompsFromFormValues,
  (properties, selectedBuildingComps) =>
    properties.map(property => {
      const { units, address, id, bedroomCounts, averageRent, amenities, subType, image } = mapProperty(property)
      const selectedProperty = findProperty(selectedBuildingComps, property)
      return {
        item: {
          units,
          address,
          id,
          bedroomCounts,
          averageRent,
          amenities,
          subType,
          image,
        },
        meta: {
          isMarked: !!selectedProperty.item,
          id,
          position: {
            lat: get(property, 'property.location.coords.latitude'),
            lng: get(property, 'property.location.coords.longitude'),
          },
        },
      }
    })
)

const selectSelectedUnitsById = createSelector(selectFormValues, formValues => {
  const unitCompGroups = get(formValues, 'unitCompGroups', [])
  const selectedUnits = flatMap(unitCompGroups, group => group.units)
  return keyBy(selectedUnits, '_id')
})

const selectRentComps = createSelector(
  state => get(state, 'rentComps.rentCompsByCoords', []),
  selectSelectedUnitsById,
  (rentComps, selectedUnitsById) =>
    rentComps.map(rentComp => {
      const selectedRentComp = selectedUnitsById[rentComp._id]
      return {
        ...rentComp,
        isMarked: !!selectedRentComp,
        position: rentComp.position,
      }
    })
)

const selectSortedRentComps = createSelector(
  selectRentComps,
  state => get(state, 'rentComps.filters.sortType'),
  (rentComps, sortType) => {
    return sortRentComps(rentComps, sortType)
  }
)

export const selectShowPerRoomAnalysis = state =>
  get(state, 'report.reportData.incomeApproach.residentialIncome.residentialRentRoll.includePerRoomAnalysis', false)

export const selectRentCompSettings = createSelector(
  state => get(state, 'report.reportData.incomeApproach.residentialIncome.residentialRentComps.unitGroupingType'),
  state => get(state, 'report.reportData.incomeApproach.residentialIncome.residentialRentRoll'),
  (unitGroupingType, residentialRentRoll = {}) => {
    return {
      groupingType: unitGroupingType,
      showOutdoorSpace: get(residentialRentRoll, 'outdoorSpace', false),
      showPerRoomAnalysis: get(residentialRentRoll, 'includePerRoomAnalysis', false),
    }
  }
)

export const selectRentCompSearchState = createSelector(
  selectSortedRentComps,
  state => get(state, 'rentComps'),
  selectSelectedUnitsById,
  state => get(state, 'report.reportData.incomeApproach.residentialIncome.residentialRentComps.unitGroups'),
  state => get(state, 'report.reportData.incomeApproach.residentialIncome.residentialRentRoll'),
  state => get(state, 'report.reportData.propertyInformation.coords'),
  (
    rentComps = [],
    rentCompsState,
    selectedUnitsById,
    unitGroups = {},
    residentialRentRoll = {},
    subjectPropertyCoords = {}
  ) => {
    return {
      selectedUnitsById,
      results: rentComps,
      resultsById: keyBy(rentComps, '_id'),
      isLoading: rentCompsState.isLoading,
      moreResultsAvailable: rentCompsState.moreResultsAvailable,
      filters: rentCompsState.filters,
    }
  }
)
