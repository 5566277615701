import createDecorator from 'final-form-calculate'
import { get } from 'lodash'

import IncomeApproachCalculations from 'shared/report-calculations/income-approach/income-approach-calculations'
import { fromCurrency } from 'client-shared/utils/numberOperations'

import { adjustmentsAndLossesCalculations } from '../../../../shared/components/AdjustmentsAndLosses'

export const concludedCapRateDecorator = createDecorator({
  field: [
    /concludedCapRate/,
    /roundingFactor/,
    /asStabilizedDiscountPercentage/,
    /asStabilizedMonthsDiscounted/,
    /asCompleteDiscountPercentage/,
    /asCompleteMonthsDiscounted/,
    /includeAsStabilizedDiscountRate/,
    /includeAsCompleteDiscountRate/,
  ],
  updates: adjustmentsAndLossesCalculations.recalculateFinalValuesAndDates,
})

export const asStabilizedDiscountRateDecorator = createDecorator(
  {
    field: [/asStabilizedDiscountPercentage/, /asStabilizedMonthsDiscounted/],
    updates: (value, fieldName, allValues) => {
      const asStabilizedDiscountPercentage = get(allValues, 'asStabilizedDiscountPercentage', 0)
      const asStabilizedMonthsDiscounted = get(allValues, 'asStabilizedMonthsDiscounted', 0)
      const asStabilizedDiscountRate = IncomeApproachCalculations.calculateDiscountRate(
        asStabilizedDiscountPercentage,
        asStabilizedMonthsDiscounted
      )
      const finalValueAsStabilized = fromCurrency(get(allValues, 'finalValueAsStabilized', 0))

      const asStabilizedMarketValueWithDiscount = asStabilizedDiscountRate * finalValueAsStabilized
      return {
        asStabilizedDiscountRate,
        asStabilizedMarketValueWithDiscount,
      }
    },
  },
  {
    field: /includeAsStabilizedDiscountRate/,
    updates: (value, fieldName, allValues) => {
      if (!value) {
        return {
          asStabilizedDiscountRate: 0,
          asStabilizedMarketValueWithDiscount: 0,
          asStabilizedMonthsDiscounted: 0,
          asStabilizedDiscountPercentage: 0,
        }
      }
      return {}
    },
  }
)

export const asCompleteDiscountRateDecorator = createDecorator(
  {
    field: [/asCompleteDiscountPercentage/, /asCompleteMonthsDiscounted/],
    updates: (value, fieldName, allValues) => {
      const asCompleteDiscountPercentage = get(allValues, 'asCompleteDiscountPercentage', 0)
      const asCompleteMonthsDiscounted = get(allValues, 'asCompleteMonthsDiscounted', 0)
      const asCompleteDiscountRate = IncomeApproachCalculations.calculateDiscountRate(
        asCompleteDiscountPercentage,
        asCompleteMonthsDiscounted
      )
      const finalValueAsComplete = fromCurrency(get(allValues, 'finalValueAsComplete', 0))

      const asCompleteMarketValueWithDiscount = asCompleteDiscountRate * finalValueAsComplete
      return {
        asCompleteDiscountRate,
        asCompleteMarketValueWithDiscount,
      }
    },
  },

  {
    field: /includeAsCompleteDiscountRate/,
    updates: (value, fieldName, allValues) => {
      if (!value) {
        return {
          asCompleteDiscountRate: 0,
          asCompleteMarketValueWithDiscount: 0,
          asCompleteMonthsDiscounted: 0,
          asCompleteDiscountPercentage: 0,
        }
      }
      return {}
    },
  }
)
