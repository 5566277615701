/**
 * Overriding styles for MuiPaper
 */

const roleDependentPadding = ({ role }) => {
  return { padding: role ? 0 : 16 }
}

export default {
  styleOverrides: {
    root: [roleDependentPadding],
  },
}
