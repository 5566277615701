import { handleActions } from 'redux-actions'

import {
  addPageBookmark,
  addPageBookmarkReceive,
  removePageBookmark,
  removePageBookmarkReceive,
  removeAllBookmarksReceive,
  fetchBookmarksReceive,
} from '../actions/bookmarks'

const initialState = {
  bookmarks: [],
}

export const reducer = handleActions(
  {
    [addPageBookmark](state) {
      return {
        ...state,
      }
    },
    [addPageBookmarkReceive](state, { payload }) {
      return {
        ...state,
        bookmarks: [...state.bookmarks, payload],
      }
    },
    [removePageBookmark](state) {
      return {
        ...state,
      }
    },
    [removePageBookmarkReceive](state, { payload }) {
      const { bookmarks } = state

      return {
        ...state,
        bookmarks: bookmarks.filter(bookmark => bookmark !== payload),
      }
    },
    [removeAllBookmarksReceive](state) {
      return {
        ...state,
        bookmarks: [],
      }
    },
    [fetchBookmarksReceive](state, { payload }) {
      return {
        ...state,
        bookmarks: payload,
      }
    },
  },
  initialState
)
