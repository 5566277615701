import { createAction } from 'redux-actions'
import { defineAction } from 'redux-define'

import { DEFAULT_API_ACTION_STATES } from '../../../../../shared/constants'

const IMPORT_ZONING = defineAction('IMPORT_ZONING')
const REPORT_ZONING_FETCH = defineAction('REPORT_ZONING_FETCH', DEFAULT_API_ACTION_STATES)

export const importZoning = createAction(IMPORT_ZONING.ACTION)

export const reportZoningFetch = createAction(REPORT_ZONING_FETCH.ACTION)
export const reportZoningFetchRequest = createAction(REPORT_ZONING_FETCH.REQUEST)
export const reportZoningFetchReceive = createAction(REPORT_ZONING_FETCH.RECEIVE)
export const reportZoningFetchFail = createAction(REPORT_ZONING_FETCH.FAIL)
