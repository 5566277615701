/**
 * Overriding styles for MuiButton
 */

export default {
  styleOverrides: {
    root: [
      {
        padding: '6px 16px',
      },
    ],
  },
}
