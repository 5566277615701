import React from 'react'
import PropTypes from 'prop-types'

import { Button, Paper, Stack, Typography } from '@mui/material'
import { shortDateFormat } from 'client-shared/utils/dateHelper'
import { Area, Template } from 'client-shared/components/Template'
import { default as ReportStatusChip } from 'client-shared/components/ReportStatus'

import { ReportStates } from '../../constants'

const ReportStateButton = ({ children, dataQa, onClick }) => (
  <Button color="primary" data-qa={dataQa} onClick={onClick} variant="outlined">
    {children}
  </Button>
)

const ReportStatus = ({
  approvedAt,
  approvedBy,
  canSetApprove,
  canSetDraft,
  canSetReview,
  canSetSubmitted,
  nextFormPath,
  reportState,
  showApprovedBy,
  showSubmittedBy,
  submittedAt,
  submittedBy,
  updateReportState,
}) => {
  const handleReportUpdate = React.useCallback(
    state => {
      updateReportState({
        currentState: reportState,
        nextFormPath,
        nextState: state,
      })
    },
    [nextFormPath, reportState, updateReportState]
  )

  const canEdit = [canSetDraft, canSetReview, canSetSubmitted, canSetApprove].some(Boolean)

  return (
    <Paper>
      <Stack spacing={3}>
        <Typography variant="h6">Report Status</Typography>
        <Template
          is={`
              '           title       title    title'
              '  editStateLabel     actions  actions'
              'reportStateLabel reportState        .'
              /            auto        auto      1fr
          `}
          gap={2}
          placeItems="center start"
        >
          <Area is="title">
            <Typography variant="subtitle1">Report Information</Typography>
          </Area>
          <Area is="reportStateLabel">
            <Typography variant="body1">Current State</Typography>
          </Area>
          <Area is="reportState" sx={{ minWidth: 108 }}>
            <Typography data-qa="report-status">
              <ReportStatusChip status={reportState} variant="body1" />
            </Typography>
          </Area>
          <Area is="editStateLabel" show={canEdit}>
            <Typography variant="body1">Edit State</Typography>
          </Area>
          <Area is="actions">
            <Stack direction="row" spacing={2}>
              {canSetDraft && (
                <ReportStateButton dataQa="draft-btn" onClick={() => handleReportUpdate(ReportStates.DRAFT)}>
                  Make Draft
                </ReportStateButton>
              )}
              {canSetReview && (
                <ReportStateButton dataQa="review-btn" onClick={() => handleReportUpdate(ReportStates.REVIEW)}>
                  Make Reviewed
                </ReportStateButton>
              )}
              {canSetSubmitted && (
                <ReportStateButton dataQa="submit-btn" onClick={() => handleReportUpdate(ReportStates.SUBMITTED)}>
                  Make Submitted
                </ReportStateButton>
              )}
              {canSetApprove && (
                <ReportStateButton dataQa="approve-btn" onClick={() => handleReportUpdate(ReportStates.APPROVED)}>
                  Make Approved
                </ReportStateButton>
              )}
            </Stack>
          </Area>
        </Template>
        <Template
          is={`
              'submittedByLabel submittedBy    . submittedDateLabel submittedDate'
              ' approvedByLabel  approvedBy    .  approvedDateLabel  approvedDate'
              /            auto         1fr 16px               auto           1fr
          `}
          gap={1}
        >
          {showSubmittedBy && (
            <>
              <Area is="submittedByLabel">
                <Typography variant="body1">Submitted By</Typography>
              </Area>
              <Area is="submittedBy">
                <Typography data-qa="submitted-by">{submittedBy}</Typography>
              </Area>
              <Area is="submittedDateLabel">
                <Typography variant="body1">Date Submitted</Typography>
              </Area>
              <Area is="submittedDate">
                <Typography data-qa="submitted-date">{shortDateFormat(submittedAt)}</Typography>
              </Area>
            </>
          )}
          {showApprovedBy && (
            <>
              <Area is="approvedByLabel">
                <Typography variant="body1">Approved By</Typography>
              </Area>
              <Area is="approvedBy">
                <Typography data-qa="approved-by">{approvedBy}</Typography>
              </Area>
              <Area is="approvedDateLabel">
                <Typography variant="body1">Date Approved</Typography>
              </Area>
              <Area is="approvedDate">
                <Typography data-qa="approved-at">{shortDateFormat(approvedAt)}</Typography>
              </Area>
            </>
          )}
        </Template>
      </Stack>
    </Paper>
  )
}

ReportStatus.propTypes = {
  approvedAt: PropTypes.string,
  approvedBy: PropTypes.string,
  canSetApprove: PropTypes.bool,
  canSetDraft: PropTypes.bool,
  canSetReview: PropTypes.bool,
  canSetSubmitted: PropTypes.bool,
  nextFormPath: PropTypes.string.isRequired,
  reportState: PropTypes.string.isRequired,
  showApprovedBy: PropTypes.bool,
  showSubmittedBy: PropTypes.bool,
  submittedAt: PropTypes.string,
  submittedBy: PropTypes.string,
  updateReportState: PropTypes.func.isRequired,
}

ReportStatus.defaultProps = {
  canSetApprove: false,
  canSetDraft: false,
  canSetReview: false,
  canSetSubmitted: false,
  showApprovedBy: false,
  showSubmittedBy: false,
}

export default ReportStatus
