import React from 'react'
import PropTypes from 'prop-types'

import { Accordion, AccordionDetails, AccordionSummary, Stack } from '@mui/material'
import ExpandMore from '@mui/icons-material/ExpandMore'
import { get } from 'lodash'

import { CheckboxWithLabel } from 'client-shared/components/Checkbox'
import { DatePicker, Number as NumberField, Text } from 'client-shared/components'
import { PercentageRange } from 'client-shared/components/Range/InputRanges'
import { fromPercents, toPercents } from 'client-shared/utils/numberOperations'
import NarrativeComponent from 'client-shared/components/NarrativeComponent'
import personalSurveysDiscussion from 'shared/utils/textGeneration/incomeApproach/personalSurveysDiscussion'

const PersonalSurveys = ({ form }) => {
  const [expanded, setExpanded] = React.useState(false)

  const handleClick = React.useCallback(() => setExpanded(state => !state), [])

  const getCapRateValue = React.useCallback(() => {
    const { isCapRateRange } = get(form, 'values.personalSurveys', {})

    return isCapRateRange ? (
      <PercentageRange
        betweenLabel="–"
        maxLabel="Max Cap Rate"
        minLabel="Min Cap Rate"
        name="personalSurveys.capRate"
      />
    ) : (
      <NumberField
        adornment="%"
        data-qa="capRateValue-input"
        decimalScale={2}
        format={toPercents}
        label="Cap Rate"
        name="personalSurveys.capRate.value"
        normalize={fromPercents}
      />
    )
  }, [form])

  const disabled = !get(form, 'values.personalSurveys.includeInExport')

  return (
    <Accordion
      data-qa="personal-surveys-ratio-section"
      disabled={disabled}
      expanded={expanded && !disabled}
      onChange={handleClick}
    >
      <AccordionSummary data-qa="selected-loan-terms-section-title" expandIcon={<ExpandMore />}>
        Personal Surveys
      </AccordionSummary>
      <AccordionDetails>
        <Stack spacing={2}>
          <Stack direction="row" spacing={2}>
            <Stack spacing={1} sx={{ minWidth: 0.4 }}>
              <Text data-qa="contactName-input" fullWidth label="Contact Name" name="personalSurveys.contactName" />
              <Text data-qa="companyName-input" fullWidth label="Company/Firm" name="personalSurveys.companyName" />
              <CheckboxWithLabel
                data-qa="isCapRateRange-checkbox"
                label="Cap Rate Recorded As Range"
                name="personalSurveys.isCapRateRange"
              />
              {getCapRateValue()}
            </Stack>
            <Stack spacing={1}>
              <DatePicker
                data-qa="conversationDate-date"
                label="Date of Conversation"
                name="personalSurveys.conversationDate"
              />
              <NarrativeComponent
                title="Suggested Opinion Paragraph"
                generatedText={personalSurveysDiscussion.generate}
                data={personalSurveysDiscussion.mapDTO(form.values)}
                name="personalSurveysDiscussion"
                tooltipText="The following generated text will appear in the Income Capitalization section of your report."
              />
            </Stack>
          </Stack>
        </Stack>
      </AccordionDetails>
    </Accordion>
  )
}

PersonalSurveys.propTypes = {
  form: PropTypes.object.isRequired,
}

export default PersonalSurveys
