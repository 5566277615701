import { isNil, get, isNumber } from 'lodash'

export const maxRentValidation = (value, values, fields, unitName) => {
  if (isNil(value)) {
    return
  }

  const unit = get(values, unitName.replace('.maxRent', ''))

  if (value < unit.avgRent) {
    return 'Must be more than Avg Rent'
  }

  if (value < unit.minRent) {
    return 'Must be more than Min Rent'
  }
}

export const minRentValidation = (value, values, fields, unitName) => {
  if (isNil(value)) {
    return
  }

  const unit = get(values, unitName.replace('.minRent', ''))

  if (value > unit.avgRent) {
    return 'Must be less than Avg Rent'
  }

  if (value > unit.maxRent) {
    return 'Must be less than Max Rent'
  }
}

export const avgRentValidation = (value, values, fields, unitName) => {
  if (isNil(value)) {
    return
  }

  const unit = get(values, unitName.replace('.avgRent', ''))

  if (value < unit.minRent) {
    return 'Must be more than Min Rent'
  }

  if (value > unit.maxRent) {
    return 'Must be less than Max Rent'
  }
}

export const roomsValidation = (value, values, fields, unitName) => {
  if (isNil(value)) {
    return
  }

  const unit = get(values, unitName.replace('.roomCount', ''))
  const rooms = unit.bedroomCount + unit.bathroomCount

  return value < rooms ? 'Must be more than bedrooms and bathrooms' : undefined
}

export const bathroomsValidation = value => {
  return isNumber(value) && value % 0.5 !== 0 ? 'Must be a whole or half number' : undefined
}
