import { get, capitalize, startCase } from 'lodash'

import React from 'react'
import PropTypes from 'prop-types'
import { Table, TableBody, TableRow, TableCell, Grid, Link } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'
import Dash from '@mui/icons-material/Remove'

import BoweryDate from '@bowery-valuation/bowery-date'
import { divide } from 'shared/utils/numberOperations'
import { getUnitCount } from 'shared/helpers/basisOfComparison'
import { isCornerLot } from 'shared/utils/report'
import { getSalePriceToUse } from 'shared/report-calculations/sales-approach/helpers'

import { getTotalUnitCount } from 'shared/report-calculations/helpers'

import { formatCurrencyFloat, formatPercentageString } from '../../../../../shared/utils/numberFormatters'

import styles from '../styles'
import { EXPANDED_TABLE_TITLES, ExpandedRowKeys } from '../constants'

class ExpandedTable extends React.PureComponent {
  static propTypes = {
    rows: PropTypes.array.isRequired,
    showEditModal: PropTypes.func.isRequired,
  }

  onEditComp = compToEdit => event => {
    event.stopPropagation()
    this.props.showEditModal(compToEdit)
  }

  getSubjectPropertyInfo = rowName => {
    const { formValues } = this.props
    const { subjectProperty, incomeApproachValue, inspectionDate, unitOfComparison } = formValues
    const { residentialUnits, commercialUnits } = subjectProperty
    let cellValue = ''
    switch (rowName) {
      case ExpandedRowKeys.saleDate:
        cellValue = new BoweryDate(inspectionDate).formatShortDateWithZeroes()
        break

      case ExpandedRowKeys.salePrice:
        cellValue = formatCurrencyFloat(incomeApproachValue.total)
        break

      case ExpandedRowKeys.pricePerUnit:
        const basisOfComparisonUnitCount = getUnitCount(unitOfComparison, residentialUnits, commercialUnits)
        const pricePerUnit = divide(incomeApproachValue.total, basisOfComparisonUnitCount)
        cellValue = formatCurrencyFloat(pricePerUnit)
        break

      case ExpandedRowKeys.pricePerSF:
        cellValue = formatCurrencyFloat(incomeApproachValue.psf)
        break

      case ExpandedRowKeys.capRate:
        cellValue = formatPercentageString(subjectProperty.capRate, 2)
        break

      case ExpandedRowKeys.neighborhood:
        cellValue = subjectProperty.neighborhood
        break

      case ExpandedRowKeys.buildingType:
        cellValue = capitalize(subjectProperty.buildingType)
        break

      case ExpandedRowKeys.elevatored:
        cellValue = subjectProperty.elevatored ? 'Elevator' : 'Walk-up'
        break

      case ExpandedRowKeys.corner:
        const corner = subjectProperty.corner
        cellValue = isCornerLot(corner) ? 'Corner' : 'Mid-Block'
        break

      case ExpandedRowKeys.airRights:
        cellValue = subjectProperty.airRights
        break

      case ExpandedRowKeys.commercialUnits:
        cellValue = subjectProperty.commercialUnits
        break

      case ExpandedRowKeys.commercialAreaRatio:
        cellValue = formatPercentageString(subjectProperty.commercialArea / subjectProperty.squareFootage, 2)
        break
      case ExpandedRowKeys.squareFootage:
        cellValue = subjectProperty.squareFootage
        break

      case ExpandedRowKeys.residentialUnits:
        cellValue = subjectProperty.residentialUnits
        break

      case ExpandedRowKeys.incomeLevel:
        cellValue = formatPercentageString(formValues.temper, 2)
        break

      case ExpandedRowKeys.yearBuilt:
        cellValue = subjectProperty.yearBuilt
        break

      case ExpandedRowKeys.city:
        cellValue = subjectProperty.city
        break

      case ExpandedRowKeys.condition:
        cellValue = startCase(subjectProperty.condition)
        break

      default:
        cellValue = <Dash />
    }

    return cellValue || <Dash />
  }

  renderCellValue = rowName => {
    const { formValues, classes } = this.props
    const { selectedComps } = formValues

    const cellValues = selectedComps.map((comp, index) => {
      const cellKey = comp.id
      let cellValue = ''
      const saleInformation = get(selectedComps, `[${index}].saleInformation`, {})
      const salePrice = getSalePriceToUse(saleInformation)
      const gba = get(selectedComps, `[${index}].propertyInformation.grossBuildingArea`, 1)
      const residentialUnitCount = get(selectedComps, `[${index}].propertyInformation.residentialUnits`, 0)
      const commercialUnitCount = get(selectedComps, `[${index}].propertyInformation.commercialUnits`, 0)
      const buildingType = get(selectedComps, `[${index}].propertyInformation.buildingType`, 'N/A')

      switch (rowName) {
        case ExpandedRowKeys.saleDate:
          const inContract = comp.inContract
          const listing = comp.listing
          const saleDate = get(selectedComps, `[${index}].saleInformation.saleDate`, 0)
          if (inContract) {
            cellValue = 'In-Contract'
          } else if (listing) {
            cellValue = 'Listing'
          } else {
            cellValue = new BoweryDate(saleDate).formatShortDateWithZeroes()
          }
          break

        case ExpandedRowKeys.salePrice:
          cellValue = formatCurrencyFloat(salePrice)
          break

        case ExpandedRowKeys.pricePerUnit:
          const basisOfComparisonUnitCount = getTotalUnitCount(residentialUnitCount, commercialUnitCount)

          const pricePerUnit = salePrice / basisOfComparisonUnitCount

          cellValue = Number.isFinite(pricePerUnit) ? formatCurrencyFloat(pricePerUnit) : <Dash />
          break

        case ExpandedRowKeys.pricePerSF:
          const pricePerSF = salePrice / gba
          cellValue = Number.isFinite(pricePerSF) ? formatCurrencyFloat(pricePerSF) : <Dash />
          break

        case ExpandedRowKeys.capRate:
          const capRate = get(selectedComps, `[${index}].saleInformation.capRate`)
          const capRateString = capRate ? formatPercentageString(capRate, 2) : 'N/A'
          cellValue = capRateString
          break

        case ExpandedRowKeys.propertyRights:
          const propertyRights = get(selectedComps, `[${index}].saleInformation.propertyRights`)
          cellValue = propertyRights ? startCase(propertyRights) : <Dash />
          break

        case ExpandedRowKeys.propertyDescription:
          cellValue = (
            <Link className={classes.expansionTable_actionLink} onClick={this.onEditComp(comp)}>
              View
            </Link>
          )
          break

        case ExpandedRowKeys.neighborhood:
          const neighborhood = get(selectedComps, `[${index}].propertyInformation.neighborhood`, 'N/A')
          cellValue = neighborhood
          break

        case ExpandedRowKeys.buildingType:
          cellValue = capitalize(buildingType)
          break

        case ExpandedRowKeys.elevatored:
          const elevatored = buildingType === 'elevator'
          cellValue = elevatored ? 'Elevator' : 'Walk-up'
          break

        case ExpandedRowKeys.corner:
        case ExpandedRowKeys.airRights:
          break

        case ExpandedRowKeys.commercialUnits:
          cellValue = commercialUnitCount
          break

        case ExpandedRowKeys.commercialAreaRatio:
          const commercialArea = get(selectedComps, `[${index}].propertyInformation.commercialArea`, 0)
          cellValue = formatPercentageString(commercialArea / gba, 2)
          break

        case ExpandedRowKeys.squareFootage:
          cellValue = gba
          break

        case ExpandedRowKeys.residentialUnits:
          cellValue = residentialUnitCount
          break

        case ExpandedRowKeys.incomeLevel:
          cellValue = formatPercentageString(formValues.temper, 2)
          break

        case ExpandedRowKeys.yearBuilt:
          cellValue = get(selectedComps, `[${index}].propertyInformation.yearBuilt`)
          break

        case ExpandedRowKeys.city:
          cellValue = comp.address.city
          break

        case ExpandedRowKeys.condition:
          cellValue = startCase(comp.propertyInformation.condition)
          break

        default:
          cellValue = <Dash />
      }

      return (
        <TableCell padding="none" className={classes.expansionTable_TableCell_Label} key={cellKey}>
          <div
            className={classes.expansionTable_TableBody_TableCell_Label_TextContainer}
            data-qa={`${rowName}.value[${index + 1}]`}
          >
            {cellValue || <Dash />}
          </div>
        </TableCell>
      )
    })
    return cellValues
  }

  render() {
    const { rows, classes } = this.props
    return (
      <Table className={classes.expandedTable} onClick={event => event.stopPropagation()}>
        <TableBody className={classes.expansionTable_TableBody}>
          {rows.map(rowName => {
            const rowTitle = EXPANDED_TABLE_TITLES[rowName]
            return (
              <TableRow className={classes.expansionTable_TableBody_Row} data-qa={rowTitle} key={rowName}>
                <TableCell padding="none" className={classes.expansionTable_TableCell_Label}>
                  <Grid container className={classes.expansionTable_TableCell_Label_Content}>
                    <Grid item xs={6}>
                      <div data-qa={`${rowName}.label`}>{rowTitle}</div>
                    </Grid>
                    <Grid item xs={6}>
                      <div className={classes.expansionTable_Header_LeadCell_Content} data-qa={`${rowName}.value[0]`}>
                        {this.getSubjectPropertyInfo(rowName)}
                      </div>
                    </Grid>
                  </Grid>
                </TableCell>
                {this.renderCellValue(rowName)}
              </TableRow>
            )
          })}
        </TableBody>
      </Table>
    )
  }
}

export default withStyles(styles)(ExpandedTable)
