import React from 'react'
import PropTypes from 'prop-types'

import { pure, compose } from 'recompose'
import { Typography } from '@mui/material'
import { withStyles } from '@mui/styles'

import { filter, get, isNil } from 'lodash'

import { STATE_NAMES } from '../../../../../../shared/constants/states'

import PreviewSection from './PreviewSection'

const styles = {
  root: {
    width: 700,
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-between',
  },
  appraiser: {
    width: 320,
  },
}

const AppraisersList = ({ classes, appraisers, reportState, isFreddieMac }) => (
  <div className={classes.root}>
    {filter(appraisers, appraiser => {
      const appraiserCertificationInState = get(appraiser, 'certificationState')
      return (
        !isFreddieMac ||
        (!isNil(reportState) &&
          !isNil(appraiserCertificationInState) &&
          reportState === STATE_NAMES[appraiserCertificationInState])
      )
    }).map((appraiser, index) => (
      <PreviewSection key={index} classes={{ root: classes.appraiser }}>
        <Typography data-qa="full-name" variant="body1">
          {appraiser.fullName}
        </Typography>
        <Typography data-qa="position" variant="body1">
          {appraiser.position}
        </Typography>
        {appraiser.stateCertificationNumber &&
          appraiser.licenses.map(license => (
            <Typography data-qa="state-certification-number" variant="body1">
              {license.state}
              {license.licenseLabel}
              {license.licenseNumber}
            </Typography>
          ))}
        <Typography data-qa="email" variant="body1">
          {appraiser.email}
        </Typography>
        <Typography data-qa="phone-number" variant="body1">
          {appraiser.phoneNumber}
        </Typography>
      </PreviewSection>
    ))}
  </div>
)

AppraisersList.propTypes = {
  appraisers: PropTypes.arrayOf(
    PropTypes.shape({
      email: PropTypes.string,
      state: PropTypes.string,
      fullName: PropTypes.string,
      position: PropTypes.string,
      phoneNumber: PropTypes.string,
      stateCertificationNumber: PropTypes.string,
      licenses: PropTypes.arrayOf(
        PropTypes.shape({
          state: PropTypes.string,
          licenseNumber: PropTypes.string,
          licenseLabel: PropTypes.string,
        })
      ),
    })
  ).isRequired,
  reportState: PropTypes.string.isRequired,
  isFreddieMac: PropTypes.bool.isRequired,
}

export default compose(withStyles(styles), pure)(AppraisersList)
