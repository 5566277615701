import React from 'react'
import PropTypes from 'prop-types'
import { compose, shouldUpdate } from 'recompose'
import { isEqual, get } from 'lodash'
import { withStyles } from '@material-ui/core/styles'
import { List, AutoSizer } from 'react-virtualized'
import 'react-virtualized/styles.css'

import { connect } from 'react-redux'

import { PropertiesSearchResultsListColumns } from '../../constants'

import { selectPropertySearchResults, selectSubject, selectShowPerRoomAnalysis } from '../../redux/selectors'

import { propertySearchResultsPropTypes } from './propTypes'
import PropertiesSearchResultsItem from './PropertySearchResultItem'
import PropertiesSearchResultsListHeader from './PropertySearchResultListHeader'
import PropertiesSearchResultsListNoData from './PropertySearchResultListNoData'

const DEFAULT_ROW_HEIGHT = 50
const LIST_HEIGHT = 552
const HIGH_SCROLL_DIFFERENCE = 552

const styles = theme => ({
  root: {
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
    overflow: 'hidden',
  },
  propertyRowLoading: {
    height: DEFAULT_ROW_HEIGHT - 5,
    backgroundColor: theme.palette.primary[50],
  },
})

class PropertySearchResultList extends React.Component {
  state = {
    expanded: [],
  }
  static propTypes = {
    items: PropTypes.arrayOf(propertySearchResultsPropTypes),
    removeComp: PropTypes.func.isRequired,
    editComp: PropTypes.func,
    addComp: PropTypes.func,
    isLoading: PropTypes.bool,
    form: PropTypes.object,
  }

  static defaultProps = {
    items: [],
  }

  toggleDetailsExpand = (index, height) => {
    this.setState(prevState => {
      const exp = [...prevState.expanded]
      exp[index] = height
      return {
        expanded: exp,
      }
    })
    this.recomputeRowHeights(index)
  }

  toggleSubjectDetails = () => {
    this.setState(prevState => ({
      isSubjectExpanded: !prevState.isSubjectExpanded,
    }))
  }

  recomputeRowHeights = index => {
    this.list.recomputeRowHeights(index)
    this.list.forceUpdateGrid()
  }

  getRowHeight = ({ index }) => {
    return this.state.expanded[index] || DEFAULT_ROW_HEIGHT
  }

  rowRenderer = ({ index, key, style, isScrolling, isVisible }) => {
    const { subject, addComp, removeComp, showPerRoomAnalysis, classes } = this.props
    const items = [{ ...subject, isSubject: true }, ...this.props.items]

    const content =
      isScrolling && this.fastScroll ? (
        <div className={classes.propertyRowLoading} />
      ) : (
        <PropertiesSearchResultsItem
          onAddCompItem={addComp}
          onRemoveCompItem={removeComp}
          index={index}
          item={items[index]}
          isSubject={items[index].isSubject}
          showPerRoomAnalysis={showPerRoomAnalysis}
          onToggleDetails={this.toggleDetailsExpand}
          isExpanded={this.state.expanded[index] > DEFAULT_ROW_HEIGHT}
          columns={PropertiesSearchResultsListColumns}
        />
      )

    return (
      <div key={key} style={style}>
        {content}
      </div>
    )
  }

  handleListScroll = ({ scrollTop }) => {
    this.fastScroll = Math.abs(scrollTop - (this.scroll || 0)) > HIGH_SCROLL_DIFFERENCE
    this.scroll = scrollTop
  }

  addListRef = element => (this.list = element)

  render() {
    const { classes, items } = this.props
    const isEmpty = items.length === 0

    return (
      <div className={classes.root}>
        <AutoSizer disableHeight>
          {({ width }) => {
            return (
              <React.Fragment>
                <PropertiesSearchResultsListHeader width={width} />
                <List
                  onScroll={this.handleListScroll}
                  overscanRowCount={10}
                  ref={this.addListRef}
                  style={{ outline: 'none' }}
                  width={width}
                  height={LIST_HEIGHT}
                  rowCount={items.length + 1}
                  rowHeight={this.getRowHeight}
                  rowRenderer={this.rowRenderer}
                />
                {isEmpty ? <PropertiesSearchResultsListNoData width={width} /> : null}
              </React.Fragment>
            )
          }}
        </AutoSizer>
      </div>
    )
  }
}

const PropertiesSearchResultsListComponent = compose(
  withStyles(styles),
  shouldUpdate((props, nextProps) => {
    const result = !isEqual(
      nextProps.items.map(({ id, isSelected }) => ({ id, isSelected })),
      props.items.map(({ id, isSelected }) => ({ id, isSelected }))
    )
    return result || props.isLoading !== nextProps.isLoading
  })
)(PropertySearchResultList)

const mapStateToProps = (state, ownProps) => {
  return {
    isLoading: get(state, 'buildingComp.isLoading'),
    items: selectPropertySearchResults(state, ownProps),
    subject: selectSubject(state),
    showPerRoomAnalysis: selectShowPerRoomAnalysis(state),
  }
}

export default connect(mapStateToProps)(PropertiesSearchResultsListComponent)
