import React from 'react'
import PropTypes from 'prop-types'

import { get, uniq } from 'lodash'
import { Paper, Stack, Typography } from '@mui/material'

import clientGuidelines from 'shared/utils/textGeneration/reportInformation/clientGuidelines'
import clientIdentification from 'shared/utils/textGeneration/reportInformation/clientIdentification'
import intendedUser from 'shared/utils/textGeneration/reportInformation/intendedUser'
import { ANCHOR_CLIENTS } from 'shared/constants/report/dataCollections'
import { CLIENT_PATH } from 'shared/constants/report/keysAndDataPaths'

import arrayMutators from 'final-form-arrays'

import { NarrativeComponent, UneditableSection, UneditableField } from 'client-shared/components'

import wrapForm from '../../wrapForm'

const HEADING = 'Client'
const DATA_PATH = CLIENT_PATH
const CLIENT_GUIDELINES_DISCUSSION_TOOLTIP =
  'The following generated commentary will appear in the Letter of Transmittal of your report.'

const Client = ({ form, currentPath }) => {
  const { reportClients } = form.values

  const getReportOrganizations = clients => {
    const organizations = []
    clients.filter(client => !!client.client).forEach(client => organizations.push(client.client.client))
    return uniq(organizations)
  }
  const companies = getReportOrganizations(reportClients)

  return (
    <Stack spacing={2} minWidth="25rem" maxWidth="45rem">
      <Paper data-qa="client-section-tile">
        <Stack spacing={2.5}>
          <UneditableSection
            name="Found In: Job Details Data"
            formName="Job Details Data"
            link="job-details"
            anchor={ANCHOR_CLIENTS}
            returnDataPath={currentPath}
            editDataMessage
          >
            <Stack spacing={2}>
              {reportClients.length > 0 ? (
                reportClients.map((client, idx) => {
                  return (
                    <div>
                      <UneditableField label="Client" name={`reportClients[${idx}].client.fullName`} />
                      <UneditableField label="Client File Number" name={`reportClients[${idx}].clientFileNumber`} />
                      <UneditableField
                        label="NYCB Application Number"
                        name={`reportClients[${idx}].applicationNumber`}
                      />
                    </div>
                  )
                })
              ) : (
                <div>
                  <Typography>Client:</Typography>
                  <Typography>Client File Number:</Typography>
                  <Typography>NYCB Application Number:</Typography>
                </div>
              )}
            </Stack>
          </UneditableSection>
          <NarrativeComponent
            title="Intended User"
            generatedText={intendedUser.generate}
            data={intendedUser.mapDTO(companies)}
            name="intendedUserDiscussion"
          />
          <NarrativeComponent
            title="Identification of the Client"
            generatedText={clientIdentification.generate}
            data={clientIdentification.mapDTO(companies)}
            name="clientIdentificationDiscussion"
          />
          <NarrativeComponent
            title="Client Guidelines Discussion"
            generatedText={clientGuidelines.generate}
            data={clientGuidelines.mapDTO(companies)}
            name="clientGuidelinesDiscussion"
            tooltipText={CLIENT_GUIDELINES_DISCUSSION_TOOLTIP}
          />
        </Stack>
      </Paper>
    </Stack>
  )
}

Client.propTypes = {
  form: PropTypes.shape({
    values: PropTypes.shape({
      client: PropTypes.shape({
        fullName: PropTypes.string.isRequired,
        client: PropTypes.string.isRequired,
      }),
    }).isRequired,
    reportClients: PropTypes.arrayOf(
      PropTypes.shape({
        applicationNumber: PropTypes.string,
        clientFileNumber: PropTypes.string,
        client: PropTypes.shape({
          fullName: PropTypes.string.isRequired,
          client: PropTypes.string.isRequired,
        }),
      })
    ),
  }).isRequired,
  currentPath: PropTypes.string.isRequired,
}
const formOptions = {
  heading: HEADING,
  mutators: {
    ...arrayMutators,
  },
}

export default wrapForm(DATA_PATH, formOptions, state => ({
  currentPath: get(state, 'shared.location.form.id'),
}))(Client)
