export const NotificationTypes = {
  SUCCESS: 'SUCCESS',
  WARNING: 'WARNING',
  ERROR: 'ERROR',
}

export const Labels = {
  SUCCESS: 'Success',
  WARNING: 'Warning',
  ERROR: 'Error',
}
