import React from 'react'
import PropTypes from 'prop-types'
import { isEmpty } from 'lodash'

import Sidebar from '../../../shared/layout/Sidebar'

import { NavigationSideBarSections, NavigationSideBarSectionsV2 } from './NavigationSideBarSections'
import NavigationSideBarMenu from './NavigationSideBarMenu'

import { ReviewAndExportPage, GenericSubsectionPage } from './constants'

class ReportNavigationSideBar extends React.Component {
  static propTypes = {
    sections: PropTypes.array,
    currentForm: PropTypes.object,
    isBlocks: PropTypes.bool,
  }

  static defaultProps = {
    sections: [],
    currentForm: {},
  }

  static contextTypes = {
    router: PropTypes.shape({
      history: PropTypes.shape({
        push: PropTypes.func.isRequired,
      }).isRequired,
    }).isRequired,
  }

  state = {
    section: this.props.currentForm.section,
  }

  componentDidUpdate(prevProps, prevState) {
    const { currentForm } = this.props
    const { section } = this.state

    if (currentForm.key !== prevProps.currentForm.key && section !== currentForm.section) {
      this.openSection(currentForm.section)
    }
  }

  get section() {
    const { currentForm } = this.props
    const { section } = this.state

    return section === ReviewAndExportPage.key ? currentForm.section : section
  }

  openSection = section => {
    const { history } = this.context.router
    if (section === ReviewAndExportPage.key || section === GenericSubsectionPage.key) {
      history.push(section)
    }

    this.setState({ section })
  }

  renderMenu = () => {
    const { sections } = this.props
    const activeForm = sections.find(form => form.section === this.section) || {}
    const shouldShowMenu = !isEmpty(activeForm.forms)

    if (shouldShowMenu) {
      return <NavigationSideBarMenu forms={activeForm.forms} />
    }

    return null
  }

  render() {
    const { sections, isBlocks } = this.props

    return (
      <Sidebar renderMenu={this.renderMenu}>
        {!isBlocks ? (
          <NavigationSideBarSectionsV2 currentTab={this.section} sections={sections} onTabClick={this.openSection} />
        ) : (
          <NavigationSideBarSections currentTab={this.section} sections={sections} onTabClick={this.openSection} />
        )}
      </Sidebar>
    )
  }
}

export default ReportNavigationSideBar
