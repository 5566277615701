import { defineAction } from 'redux-define'

import { DEFAULT_API_ACTION_STATES } from '../../shared/constants'

export const REPORT_UPDATE = defineAction('REPORT_UPDATE', [...DEFAULT_API_ACTION_STATES, 'COMPLETE'])
export const REPORT_FETCH = defineAction('REPORT_FETCH', DEFAULT_API_ACTION_STATES)
export const REPORT_CLEAR = defineAction('REPORT_CLEAR', DEFAULT_API_ACTION_STATES)

export const INITIALIZE_CREATING_REPORT = defineAction('INITIALIZE_CREATING_REPORT')
export const UPDATE_CREATING_REPORT_STATUS = defineAction('UPDATE_CREATING_REPORT_STATUS', DEFAULT_API_ACTION_STATES)

export const CREATE_REPORT = defineAction('CREATE_REPORT', DEFAULT_API_ACTION_STATES)
export const DELETE_REPORT = defineAction('DELETE_REPORT', DEFAULT_API_ACTION_STATES)

export const REPORT_CHANGE_COMP_TYPE = defineAction('REPORT_CHANGE_COMP_TYPE', DEFAULT_API_ACTION_STATES)
export const UPDATE_REPORT_STATE = defineAction('UPDATE_REPORT_STATE', DEFAULT_API_ACTION_STATES)

export const SHOW_CONFIRM_MODAL = defineAction('SHOW_CONFIRM_MODAL')
export const HIDE_CONFIRM_MODAL = defineAction('HIDE_CONFIRM_MODAL')

export const SHOW_FEEDBACK_MODAL = defineAction('SHOW_FEEDBACK_MODAL')
export const HIDE_FEEDBACK_MODAL = defineAction('HIDE_FEEDBACK_MODAL')

export const NAVIGATION_CONFIRMATION = defineAction('NAVIGATION_CONFIRMATION')

export const REPORT_SETTINGS_UPDATE = defineAction('REPORT_SETTINGS_UPDATE', DEFAULT_API_ACTION_STATES)

export const REPORT_STRUCTURE_UPDATE = defineAction('REPORT_STRUCTURE_UPDATE')

export const REPORT_SAVE = defineAction('REPORT_SAVE')

export const ADD_PAGE_BOOKMARK = defineAction('ADD_PAGE_BOOKMARK', DEFAULT_API_ACTION_STATES)
export const REMOVE_PAGE_BOOKMARK = defineAction('REMOVE_PAGE_BOOKMARK', DEFAULT_API_ACTION_STATES)
export const REMOVE_ALL_BOOKMARKS = defineAction('REMOVE_ALL_BOOKMARKS', DEFAULT_API_ACTION_STATES)
export const FETCH_BOOKMARKS = defineAction('FETCH_BOOKMARKS', DEFAULT_API_ACTION_STATES)

export const UPDATE_NOTES = defineAction('UPDATE_NOTES', DEFAULT_API_ACTION_STATES)
