import React from 'react'
import PropTypes from 'prop-types'
import { get } from 'lodash'
import { Typography, Grid, Paper, Stack } from '@mui/material'

import { BUILDING_FACADE } from 'client-shared/constants/imageCategories'

import { ANCHOR_PROPERTY_PHOTOS } from 'shared/constants/report/dataCollections'

import DragDropWrapper from '../../property/PropertyPhotos/DragDropWrapper'
import DefaultCategoriesList from '../../property/PropertyPhotos/DefaultCategoriesList'
import { mapPropertyPhotos } from '../../property/PropertyPhotos/helpers'

class PropertyPhotos extends React.PureComponent {
  static propTypes = {
    classes: PropTypes.object.isRequired,
    form: PropTypes.object.isRequired,
  }

  getDefaultCategories = () => {
    const categories = get(this.props, 'form.values.defaultCategories', [])

    return categories.filter(category => category.categoryName === BUILDING_FACADE.categoryName).map(mapPropertyPhotos)
  }

  render() {
    const { form, classes } = this.props

    return (
      <Paper id={ANCHOR_PROPERTY_PHOTOS} elevation={0} className={classes.noBackground}>
        <Grid item xs={12}>
          <Typography variant="h6">Property Photos</Typography>
        </Grid>

        <Grid item xs={12}>
          <Stack spacing={3} width="calc(99vw - 440px)">
            <DragDropWrapper formMutators={form.mutators}>
              <DefaultCategoriesList
                categories={this.getDefaultCategories()}
                changeCategory={form.change}
                name="defaultCategories"
              />
            </DragDropWrapper>
          </Stack>
        </Grid>
      </Paper>
    )
  }
}

export default PropertyPhotos
