import { get, isNil, isNumber } from 'lodash'
import createDecorator from 'final-form-calculate'

import arrayMutators from 'final-form-arrays'

import { EXPENSE_FORECAST_PATH } from 'shared/constants/report/keysAndDataPaths'

import { GROSS_REVENUE } from 'shared/constants/expenses'

import wrapForm from '../../wrapForm'

import ExpenseForecast from './ExpenseForecast'

import { getManagementConcludedValue } from './managementFeeCalculations'

const DATA_PATH = EXPENSE_FORECAST_PATH

const expenseForecastCalculations = createDecorator(
  {
    field: ['management.percentOfEgi', 'management.basis'],
    updates: {
      'management.concludedValue': (value, allValues) => {
        const { management, egi, numberOfResidentialUnits, squareFootage } = allValues
        const { basis, concludedValue, percentOfEgiEnabled, percentOfEgi } = management

        if (!percentOfEgiEnabled) {
          return concludedValue
        }
        return getManagementConcludedValue(egi, basis, percentOfEgi, numberOfResidentialUnits, squareFootage)
      },
    },
  },
  {
    field: ['management.concludedValue', 'management.basis'],
    updates: {
      'management.percentOfEgi': (value, allValues) => {
        const { management, egi, numberOfResidentialUnits, squareFootage } = allValues
        const { basis, concludedValue, percentOfEgi, percentOfEgiEnabled } = management
        if (!isNil(egi) && isNumber(egi) && egi > 0) {
          if (percentOfEgiEnabled) {
            return percentOfEgi
          }

          const basisLookup = {
            unit: numberOfResidentialUnits,
            sf: squareFootage,
          }

          const totalManagementFees = concludedValue * basisLookup[basis]
          const percentageOfEgi = totalManagementFees / egi
          return percentageOfEgi.toFixed(4)
        } else {
          return null
        }
      },
    },
  }
)

const FORM_OPTIONS = {
  heading: 'Expense Forecast',
  keepDirtyOnReinitialize: true,
  decorators: [expenseForecastCalculations],
  mutators: { ...arrayMutators },
}

export default wrapForm(DATA_PATH, FORM_OPTIONS, state => {
  const { formData, compTotalsMap, expenseComps, ...expenseForecastBasis } = get(
    state,
    'report.reportData.incomeApproach.expenseForecast',
    {}
  )
  const { expenseStructure, expenseStructureByRentTypes, rentTypes } = get(
    state,
    'report.reportData.incomeApproach.residentialIncome.residentialExpenseStructure'
  )

  const { expenseHistory, isStrictExpensesMode, expenseCategories } = get(
    state,
    'report.reportData.incomeApproach.expenseHistory'
  )
  const expenseCategoriesIds = expenseCategories.filter(({ id }) => GROSS_REVENUE.key !== id).map(({ id }) => id)

  const { egi, numberOfResidentialUnits, squareFootage, management } = formData
  const { percentOfEgiEnabled, percentOfEgi, basis } = management
  const yearBuilt = get(state, 'report.reportData.dataCollections.subjectProperty.yearBuilt')

  let managementConcludedValue = management.concludedValue
  if (percentOfEgiEnabled) {
    managementConcludedValue = getManagementConcludedValue(
      egi,
      basis,
      percentOfEgi,
      numberOfResidentialUnits,
      squareFootage
    )
  }
  return {
    initialValues: { ...formData, management: { ...management, concludedValue: managementConcludedValue } },
    compTotalsMap,
    expenseComps,
    expenseHistory: {
      expenseHistory,
      expenseStructure,
      expenseStructureByRentTypes,
      rentTypes,
    },
    expenseForecastBasis,
    reportId: get(state, 'report.reportData._id'),
    isStrictExpensesMode,
    expenseCategoriesIds,
    yearBuilt,
  }
})(ExpenseForecast)
