import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import { Link } from 'react-router-dom'
import { FieldArray } from 'react-final-form-arrays'
import { get } from 'lodash'
import { compose } from 'recompose'
import { connect } from 'react-redux'

import { Table, TableBody, TableCell, TableHead, TableRow, withStyles } from '@material-ui/core'
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined'
import RepeatOutlinedIcon from '@mui/icons-material/RepeatOutlined'

import BoweryDate from '@bowery-valuation/bowery-date'

import { EXPENSE_CATEGORIES } from '../../../../../../../../shared/constants/expenses'

import TableCheckbox from '../../../../../../shared/components/Table/TableCheckbox'
import TableDropDown from '../../../../../../shared/components/Table/TableDropDown'
import TableNumber from '../../../../../../shared/components/Table/TableNumber'
import { CalloutButton, Tooltip } from '../../../../../../shared/components'
import { formatCurrencyFloat, formatPercentageString } from '../../../../../../shared/utils/numberFormatters'
import { positive } from '../../../../../../shared/utils/validation'

import { DollarAmountReimbursementTable } from './DollarAmountReimbursementTable'
import styles from './styles'

const TAX_INFO_TOOLTIP_TEXT = 'This value comes from Income > Tax Info'

class IncreaseOverBaseYearReimbursementTable extends DollarAmountReimbursementTable {
  static propTypes = {
    classes: PropTypes.object.isRequired,
    reimbursement: PropTypes.object.isRequired,
    expenseCategories: PropTypes.arrayOf(PropTypes.object).isRequired,
    pathPrefix: PropTypes.string.isRequired,
    form: PropTypes.object.isRequired,
    onUpdate: PropTypes.func.isRequired,
    onDelete: PropTypes.func.isRequired,

    taxInfo: PropTypes.object.isRequired,
    taxRateHistory: PropTypes.arrayOf(
      PropTypes.shape({
        year: PropTypes.string.isRequired,
        taxRate: PropTypes.number.isRequired,
      })
    ).isRequired,
  }

  componentDidMount() {
    const { form, pathPrefix, taxInfo, taxRateHistory } = this.props
    get(form.values, `${pathPrefix}.unitReimbursements`, []).forEach((reimbursement, index) => {
      const unitReimbursementPath = `${pathPrefix}.unitReimbursements[${index}]`
      form.mutators.updateIncreaseOverBaseYear({ unitReimbursementPath, form, taxInfo, taxRateHistory })
    })
    form.reset(form.getState().values)
  }

  get baseYearOptions() {
    const { taxRateHistory } = this.props
    const baseYearsItems = taxRateHistory.map(taxRateInfo => ({ value: taxRateInfo.year, label: taxRateInfo.year }))

    const emptyItem = { value: null, label: '' }
    return [emptyItem, ...baseYearsItems]
  }

  onFieldChange = (fieldName, unitReimbursementPath) => {
    const { form, taxInfo, taxRateHistory } = this.props
    form.mutators.updateIncreaseOverBaseYear({ unitReimbursementPath, form, taxInfo, taxRateHistory })
  }

  renderUnitReimbursementTable() {
    const { reimbursement, pathPrefix, classes } = this.props
    const baseYearLiabilityKnown = reimbursement.knownInformation.baseYearValueType === 'Base Year Liability'

    return (
      <React.Fragment>
        <CalloutButton
          link="./tax-information"
          linkText="Change"
          sx={{ mb: 1 }}
          text="Input Current Year Liability on Income > Tax Info. "
          variant="link"
        />
        <Table className={classes.reimbursementTable}>
          <TableHead>
            <TableRow>
              <TableCell className={classes.enabledColumn} />
              <TableCell>Tenant</TableCell>
              <TableCell className={classnames(classes.headerWithTooltip, classes.baseYearCell)}>
                Base Year
                <Tooltip text="These values come from Income > Tax Info" placement="top">
                  <InfoOutlinedIcon fontSize="small" />
                </Tooltip>
                <Tooltip text="Add a Base Year" placement="top">
                  <Link to="./tax-information">
                    <RepeatOutlinedIcon color="#fff" fontSize="large" />
                  </Link>
                </Tooltip>
              </TableCell>
              <TableCell>
                <div className={classes.headerWithTooltip}>
                  Current Year Liability
                  <Tooltip text={TAX_INFO_TOOLTIP_TEXT} placement="top">
                    <InfoOutlinedIcon fontSize="small" />
                  </Tooltip>
                </div>
              </TableCell>
              <TableCell>Base Year Assessment</TableCell>
              <TableCell>
                <div className={classes.headerWithTooltip}>
                  Base Year Tax Rate
                  <Tooltip text={TAX_INFO_TOOLTIP_TEXT} placement="top">
                    <InfoOutlinedIcon fontSize="small" />
                  </Tooltip>
                </div>
              </TableCell>
              <TableCell>
                <div className={classes.headerWithTooltip}>
                  Base Year Liability
                  {!baseYearLiabilityKnown && (
                    <Tooltip text={TAX_INFO_TOOLTIP_TEXT} placement="top">
                      <InfoOutlinedIcon fontSize="small" />
                    </Tooltip>
                  )}
                </div>
              </TableCell>
              <TableCell>
                <div className={classes.headerWithTooltip}>
                  Increase
                  <Tooltip text="This is your Current Year Liability - Base Year Liability." placement="top">
                    <InfoOutlinedIcon fontSize="small" />
                  </Tooltip>
                </div>
              </TableCell>
              <TableCell>Reimbursement Share</TableCell>
              <TableCell>Annual Reimbursement</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <FieldArray name={`${pathPrefix}.unitReimbursements`}>
              {({ fields }) =>
                fields.map((field, index) => this.renderUnitReimbursementRow(field, index, baseYearLiabilityKnown))
              }
            </FieldArray>
            <TableRow>
              <TableCell colSpan={8} />
              <TableCell>Total:</TableCell>
              <TableCell>{formatCurrencyFloat(reimbursement.totalAnnual)}</TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </React.Fragment>
    )
  }

  renderUnitReimbursementRow(unitReimbursementPath, index, baseYearLiabilityKnown) {
    const { classes } = this.props
    const enabled = this.getFieldValue(`${unitReimbursementPath}.enabled`)

    return (
      <TableRow key={index}>
        <TableCell className={classes.enabledColumn}>
          <TableCheckbox
            name={`${unitReimbursementPath}.enabled`}
            handleChange={() => this.onFieldChange('enabled', unitReimbursementPath)}
          />
        </TableCell>
        <TableCell>{this.getFieldValue(`${unitReimbursementPath}.unit.tenantName`)}</TableCell>
        <TableCell>
          <TableDropDown
            name={`${unitReimbursementPath}.baseYear`}
            items={this.baseYearOptions}
            displayEmpty
            disabled={!enabled}
            onChange={() => this.onFieldChange('baseYear', unitReimbursementPath)}
          />
        </TableCell>
        <TableCell>
          {formatCurrencyFloat(this.getFieldValue(`${unitReimbursementPath}.currentYearLiability`, 0))}
        </TableCell>
        <TableCell>
          {!baseYearLiabilityKnown ? (
            <TableNumber
              name={`${unitReimbursementPath}.baseYearAssessment`}
              decimalScale={2}
              allowNegative={false}
              prefix="$"
              validate={positive}
              thousandSeparator
              handleChange={() => this.onFieldChange('baseYearAssessment', unitReimbursementPath)}
              disabled={!enabled}
            />
          ) : (
            formatCurrencyFloat(this.getFieldValue(`${unitReimbursementPath}.baseYearAssessment`, 0))
          )}
        </TableCell>
        <TableCell>{formatPercentageString(this.getFieldValue(`${unitReimbursementPath}.baseYearTaxRate`))}</TableCell>
        <TableCell>
          {baseYearLiabilityKnown ? (
            <TableNumber
              name={`${unitReimbursementPath}.baseYearLiability`}
              decimalScale={2}
              allowNegative={false}
              prefix="$"
              validate={positive}
              thousandSeparator
              handleChange={() => this.onFieldChange('baseYearLiability', unitReimbursementPath)}
              disabled={!enabled}
            />
          ) : (
            formatCurrencyFloat(this.getFieldValue(`${unitReimbursementPath}.baseYearLiability`, 0))
          )}
        </TableCell>
        <TableCell>{formatCurrencyFloat(this.getFieldValue(`${unitReimbursementPath}.increase`, 0))}</TableCell>
        <TableCell className={classes.shareCell}>
          <TableNumber
            name={`${unitReimbursementPath}.share`}
            decimalScale={4}
            allowNegative={false}
            adornment="%"
            validate={positive}
            thousandSeparator
            handleChange={() => this.onFieldChange('share', unitReimbursementPath)}
            disabled={!enabled}
          />
        </TableCell>
        <TableCell className={classes.annualCell}>
          {formatCurrencyFloat(this.getFieldValue(`${unitReimbursementPath}.annual`, 0))}
        </TableCell>
      </TableRow>
    )
  }

  generateDiscussion = values => {
    const { reimbursement } = this.props

    const totalAnnual = formatCurrencyFloat(reimbursement.totalAnnual)
    const expenseType = (EXPENSE_CATEGORIES[reimbursement.expenseType] || reimbursement.expenseType).toLowerCase()

    return `According to our projections, the total ${expenseType} reimbursement is ${totalAnnual} per year.`
  }
}

const mapStateToProps = state => {
  const taxInfo = get(
    state,
    `report.reportData.incomeApproach.commercialIncome.commercialReimbursementSummary.taxInfo`,
    {}
  )
  const rateHistory = get(state, 'report.reportData.incomeApproach.taxInformation.taxClass.rateHistory', [])

  const taxRateHistory = rateHistory.reduce((taxRates, yearInfo) => {
    const year = yearInfo.effectiveDate ? new BoweryDate(yearInfo.effectiveDate).format('YYYY') : null
    taxRates.push({
      year,
      taxRate: yearInfo.value,
    })
    return taxRates
  }, [])

  return {
    taxInfo,
    taxRateHistory,
  }
}

export default compose(withStyles(styles), connect(mapStateToProps))(IncreaseOverBaseYearReimbursementTable)
