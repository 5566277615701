import React from 'react'
import PropTypes from 'prop-types'

import { Button } from '@mui/material'
import { formatCurrencyFloat } from 'client-shared/utils/numberFormatters'
import { Number as NumberField } from 'client-shared/components'
import { Area, Template } from 'client-shared/components/Template'
import { DropDown } from 'client-shared/components/DropDown'

import { VALUE_CONCLUSION_TYPES } from '../../../report/constants/settings'

import { Labels, ROUNDING_OPTIONS } from './constants'
import { adjustmentTypeOptionsForValueType } from './helpers'

const roundingOptionsKeyValuePairs = ROUNDING_OPTIONS.map(value => ({
  label: formatCurrencyFloat(value),
  value,
}))

const AdjustmentsAndLossesTableHeaderActions = ({
  commercialUnitsAdded,
  onAddAdjustment,
  openCommercialRentLossModal,
  openRentLossModal,
  residentialUnitsAdded,
  roundingFactorIsEditable,
  valueConclusionType,
  values,
}) => {
  const addAdjustmentOfSelectedType = React.useCallback(() => {
    const { adjustmentTypeToAdd } = values
    onAddAdjustment(adjustmentTypeToAdd)
  }, [onAddAdjustment, values])

  const adjustmentTypeOptions = adjustmentTypeOptionsForValueType(valueConclusionType)
  const asIsReport = valueConclusionType === VALUE_CONCLUSION_TYPES.AS_IS
  const valueAsStabilized = valueConclusionType !== VALUE_CONCLUSION_TYPES.AS_IS
  const valueAsComplete = valueConclusionType === VALUE_CONCLUSION_TYPES.AS_COMPLETE

  const disableRentLossModal = asIsReport || !residentialUnitsAdded()
  const disableCommercialRentLossModal = asIsReport || !commercialUnitsAdded()

  return (
    <Template
      is={` 'adjustmentTypeToAdd actions'
            '                  . actions'
            '             inputs actions'
            /                1fr    auto
          `}
      gap={2}
      placeItems="center stretch"
    >
      <Area is="adjustmentTypeToAdd">
        <DropDown
          data-qa="adjustmentType-input"
          items={adjustmentTypeOptions}
          label="Adjustment Type"
          name="adjustmentTypeToAdd"
        />
      </Area>
      <Area is="actions" gap={1} sx={{ flexDirection: 'column' }}>
        <Button data-qa="add-btn" onClick={addAdjustmentOfSelectedType} variant="outlined">
          {Labels.ADD_ADJUSTMENT}
        </Button>
        <Button data-qa="add-btn" disabled={disableRentLossModal} onClick={openRentLossModal} variant="outlined">
          {Labels.ADD_RENT_LOSS}
        </Button>
        <Button
          data-qa="add-btn"
          disabled={disableCommercialRentLossModal}
          onClick={openCommercialRentLossModal}
          variant="outlined"
        >
          {Labels.ADD_COMMERCIAL_RENT_LOSS}
        </Button>
      </Area>
      <Area is="inputs" gap={2}>
        {valueAsComplete && (
          <NumberField
            allowNegative={false}
            fullWidth
            label="Months of Rent Loss for As Complete Date"
            name="asCompleteMonthsOfRentLoss"
          />
        )}
        {valueAsStabilized && (
          <NumberField
            allowNegative={false}
            fullWidth
            label="Months of Rent Loss for As Stabilized Date"
            name="asStabilizedMonthsOfRentLoss"
          />
        )}
        <DropDown
          data-qa="roundingFactor-input"
          disabled={!roundingFactorIsEditable}
          items={roundingOptionsKeyValuePairs}
          label={Labels.ROUND_TO_NEAREST}
          name="roundingFactor"
        />
      </Area>
    </Template>
  )
}

AdjustmentsAndLossesTableHeaderActions.propTypes = {
  onAddAdjustment: PropTypes.func.isRequired,
  openRentLossModal: PropTypes.func.isRequired,
  residentialUnitsAdded: PropTypes.func.isRequired,
  roundingFactorIsEditable: PropTypes.bool,
  valueConclusionType: PropTypes.string.isRequired,
  values: PropTypes.object.isRequired,
}

AdjustmentsAndLossesTableHeaderActions.defaultProps = {
  roundingFactorIsEditable: true,
}

export default AdjustmentsAndLossesTableHeaderActions
