function mapData() {
  return {}
}

// eslint-disable-next-line no-unused-vars
export const mapDTO = formValues => {
  return mapData()
}

// eslint-disable-next-line no-unused-vars
export const mapDataModel = report => {
  return mapData()
}
