import {
  Button,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
  DialogActions,
  IconButton,
  Stack,
} from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'

import React from 'react'

import {
  ChipArchiveModalText,
  textValueArchive,
  textValueRestore,
  textValueUndefined,
} from './ChipArchiveModalTextInterface'

interface ChipArchiveModalProps {
  chip: any
  open: boolean
  onClose: () => void
  onArchive: (chip: any) => void
}

const ChipArchiveModal = (props: ChipArchiveModalProps) => {
  const { chip, open, onClose, onArchive } = props

  const getTextValue = (): ChipArchiveModalText => {
    if (chip === undefined) {
      return textValueUndefined
    } else if (chip?.isArchived === true) {
      return textValueRestore
    } else {
      return textValueArchive
    }
  }

  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth="sm" data-qa="archive-form">
      <Stack direction="column" justifyContent="flex-end" p={2}>
        <DialogTitle style={{ padding: '0 32px 16px 0' }}>
          {getTextValue().getTitle(chip)}
          <IconButton
            color="inherit"
            onClick={onClose}
            aria-label="close"
            style={{ position: 'absolute', top: 8, right: 8 }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent style={{ paddingLeft: '0' }}>
          <DialogContentText>{getTextValue().body}</DialogContentText>
        </DialogContent>
      </Stack>
      <DialogActions>
        <Button onClick={onClose}>Cancel</Button>
        <Button
          variant="contained"
          color="error"
          onClick={() => {
            if (chip === undefined) {
              onClose()
            } else {
              onArchive(chip)
            }
          }}
          data-qa="archive-button"
        >
          {getTextValue().buttonText}
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default ChipArchiveModal
