import React from 'react'
import PropTypes from 'prop-types'

import { Fab, Paper, Stack } from '@mui/material'
import Save from '@mui/icons-material/Save'
import SettingsRounded from '@mui/icons-material/SettingsRounded'
import { connect } from 'react-redux'
import { get } from 'lodash'
import arrayMutators from 'final-form-arrays'
import { Form } from 'react-final-form'

import { Text, DropDown } from 'client-shared/components'

import FormHeader from 'client-shared/components/FormHeader'

import { organizationUpdate } from '../../redux/actions/organization'

export const formName = 'organizationInfo'
const heading = 'Organization Information'

const DEFAULT_ROWS_COUNT = 5

class Information extends React.PureComponent {
  getLeadAppraisers = () => {
    const { initialValues } = this.props
    const { leadAppraisers } = initialValues

    return leadAppraisers.map(item => ({
      label: item.fullName,
      value: item.id,
    }))
  }

  handleSubmit = values => {
    this.props.organizationUpdate({ formDataPath: formName, values })
  }

  render() {
    const { initialValues } = this.props
    return (
      <Stack maxWidth={600}>
        <FormHeader title={heading} icon={<SettingsRounded />} section={this.props.section} />
        <Paper>
          <Form
            onSubmit={this.handleSubmit}
            initialValues={initialValues}
            mutators={{
              ...arrayMutators,
            }}
            render={props => {
              const { handleSubmit } = props
              return (
                <form onSubmit={handleSubmit}>
                  <Stack spacing={1}>
                    <Text name="name" label="Organization Name" />
                    <Text name="address" label="Organization Address" rows={DEFAULT_ROWS_COUNT} />
                    <Text name="phones" label="Organization Phones" />
                    <Text name="relianceLanguage" label="Reliance Language" rows={DEFAULT_ROWS_COUNT} />
                    <DropDown label="Main Lead Appraiser" name="mainLeadAppraiser" items={this.getLeadAppraisers()} />
                  </Stack>
                  <Fab type="submit" color="success" sx={{ position: 'fixed', bottom: 40, right: 40 }}>
                    <Save sx={{ color: 'white' }} />
                  </Fab>
                </form>
              )
            }}
          />
        </Paper>
      </Stack>
    )
  }
}

Information.propTypes = {
  updateOrganizationInfo: PropTypes.func,
  leadAppraisers: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      fullName: PropTypes.string.isRequired,
    })
  ),
}

Information.defaultProps = {
  leadAppraisers: [],
}

export default connect(
  state => {
    return {
      initialValues: get(state, `organization.organizationData.organizationInfo`, {}),
    }
  },
  {
    organizationUpdate,
  }
)(Information)
