import { camelCase, upperFirst } from 'lodash'
import { MARKET_BREAKDOWN_TYPES } from 'shared/constants/incomeApproach'

export const Labels = {
  N_A: 'N/A',
  RESIDENTIAL_RENT_RECONCILIATION: 'Residential Rent Reconciliation',
  SUBJECT: 'Subject',
  MARKET_RATE: 'Market Rate',
  COMPARABLES: 'Comparables',
  MIN: 'Min',
  MAX: 'Max',
  AVG: 'Avg',
  MARKET_CONCLUSION: 'Market Conclusion',
  SUBJECT_HAS_NO_MARKET_RATE: 'Please note the subject has no free-market',
  UNITS: 'units',
  PER_MONTH: 'Per Month',
  PER_SF_PER_YEAR: 'Per SF/Year',
  PER_SF_PER_MONTH: 'Per SF/Month',
  RENT_ROLL_SF_REQUIRED: 'Rent roll square footage required',
  ALL: 'All',
  COMPS_NEED_SF: 'comps need square footage',
  CANNOT_CONCLUDE_PER_SF: 'Cannot conclude Per SF',
  HOW_SUBJECTS_MARKET_RATE: `How do the subject's market rate`,
  UNITS_COMPARE_TO_THE_MARKET: 'units compare to the market?',
  MARKET_COMPARISON_TO_APPLY_PLACEHOLDER: 'Select the market comparison that applies',
  INCLUDE_MARKET_RENT_FORECAST_DISCUSSION: 'Include Market Rent Forecast Detailed Discussion',
  HOW_DOES_SUBJECT_COMPARE_TO_RENT_COMPS_IN_TERMS_OF_SIZE:
    "How does the size of the subject's market rate compare to the comps?",
  HOW_DOES_SUBJECT_COMPARE_TO_RENT_COMPS_IN_TERMS_OF_BUILD_COND:
    "How does the building condition of the subject's market rate compare to the comps?",
  HOW_DOES_SUBJECT_COMPARE_TO_RENT_COMPS_IN_TERMS_OF_LOCATION:
    "How does the location of the subject's market rate compare to the comps?",
  LOCATIONS_DETAILS_OPTIONAL: 'Location Details (Optional)',
  THE_SUBJECT_IS: 'The subject is',
  AS_COMPARED_TO_COMPS_IN_THE_FOLLOWING_WAYS: 'as compared to the comps in the following ways:',
  BETTER_WORSE_NEIGHBORHOOD: 'Better/Worse Neighborhood',
  LOCATION_WITHIN_NEIGHBORHOOD: 'Location Within Neighborhood',
  HOW_DOES_SUBJECT_COMPARE_TO_RENT_COMPS_IN_TERMS_OF_FINISHES:
    "How do the finishes of the subject's market rate compare to the comps?",
  FINISHES_DETAILS_OPTIONAL: 'Finishes Details (Optional)',
  HOW_DOES_SUBJECT_COMPARE_TO_RENT_COMPS_IN_TERMS_OF_AMENITIES:
    "How do the amenities of the subject's market rate compare to the comps?",
  AMENITIES_DETAILS_OPTIONAL: 'Amenities Details (Optional)',
  MAJORITY_HAVE: 'Majority Have',
  ALL_HAVE: 'All Have',
  COMPS_HAVE_PRIVATE_OUTDOOR_SPACE_AND_SUBJECT_DOES_NOT: 'Comps have private outdoor space and subject does not',
  COMPS_HAVE_COMMON_OUTDOOR_SPACE_AND_SUBJECT_DOES_NOT: 'Comps have common outdoor space and subject does not',
  COMPS_HAVE_AN_ELEVATOR_AND_SUBJECT_DOES_NOT: 'Comps have an elevator and subject does not',
  COMPS_HAVE_DOORMAN_AND_SUBJECT_DOES_NOT: 'Comps have a doorman and subject does not',
  SUBJECT_HAS_PRIVATE_OUTDOOR_AND_COMPS_DO_NOT: 'Subject has private outdoor space and comps do not',
  SUBJECT_HAS_COMMON_OUTDOOR_SPACE_AND_COMPS_DO_NOT: 'Subject has common outdoor space and comps do not',
  SUBJECT_HAS_AN_ELEVATOR_AND_COMPS_DO_NOT: 'Subject has an elevator and comps do not',
  SUBJECT_HAS_A_DOORMAN_AND_COMPS_DO_NOT: 'Subject has a doorman and comps do not',
  OTHER_AMENITIES: 'Other Amenities',
  MARKET_RENT_FORECAST_DETAILS: 'Market Rent Forecast Details',
}

export const Conditions = {
  DO_NOT_DISCUSS: 'Do not discuss',
  MIDDLE: 'Middle',
  INFERIOR: 'Inferior',
  LOW_END: 'Low End',
  BELOW_RANGE: 'Below Range',
  HIGH_END: 'High End',
  SUPERIOR: 'Superior',
}

export const UNIT_CONDITIONS = [
  Conditions.DO_NOT_DISCUSS,
  Conditions.INFERIOR,
  Conditions.LOW_END,
  Conditions.MIDDLE,
  Conditions.HIGH_END,
  Conditions.SUPERIOR,
]

export const SF_CONDITIONS = [
  Conditions.DO_NOT_DISCUSS,
  Conditions.BELOW_RANGE,
  Conditions.LOW_END,
  Conditions.MIDDLE,
  Conditions.HIGH_END,
  Conditions.SUPERIOR,
]

export const MARKET_BREAKDOWN = [
  MARKET_BREAKDOWN_TYPES.allMarket,
  MARKET_BREAKDOWN_TYPES.allBelow,
  MARKET_BREAKDOWN_TYPES.allAbove,
  MARKET_BREAKDOWN_TYPES.mixMarketAndBelow,
  MARKET_BREAKDOWN_TYPES.mixAll,
  MARKET_BREAKDOWN_TYPES.mixMarketAndAbove,
]

export const MARKET_BREAKDOWN_OPTIONS = MARKET_BREAKDOWN.map(option => ({
  value: option,
  label: upperFirst(option),
}))

export const LocationDetails = {
  PROXIMITY_TO_TRANSPORT: 'proximityToTransport',
  PARK_VIEWS: 'parkViews',
  NEIGHBORHOOD: 'neighborhood',
  LOCATION_IN_NEIGHBORHOOD: 'locationInNeighborhood',
}

export const LOCATION_DETAILS_OPTIONS = [
  { value: LocationDetails.PROXIMITY_TO_TRANSPORT, label: 'Proximity to transport' },
  { value: LocationDetails.PARK_VIEWS, label: 'Park views' },
  { value: LocationDetails.NEIGHBORHOOD, label: 'Neighborhood' },
  { value: LocationDetails.LOCATION_IN_NEIGHBORHOOD, label: 'Location in neighborhood' },
]

export const FINISHES_DETAILS = ['Exposed brick', 'Counter tops', 'Flooring', 'Appliances', 'Bathroom Finishes']
export const FINISHES_DETAILS_OPTIONS = FINISHES_DETAILS.map(detail => ({ value: camelCase(detail), label: detail }))

export const AMENITIES_DETAILS = ['Private Outdoor Space', 'Common Outdoor Space', 'Doorman', 'Elevator']
export const AMENITIES_DETAILS_OPTIONS = AMENITIES_DETAILS.map(detail => ({
  value: camelCase(detail),
  label: detail,
}))

export const GENERATED_TEXT_TOOLTIP =
  'The following generated text will appear in the Rent Reconciliation section of the Income Approach of the report.'
