import { isNumber, values } from 'lodash'

export const keyValuePairsToCheckboxValues = (keyVal, keyName = 'name') => {
  const arr = []
  const keys = Object.keys(keyVal)
  for (let i = 0; i < keys.length; i++) {
    arr.push({ [keyName]: keys[i], label: keyVal[keys[i]] })
  }
  return arr
}

export const arrayToKeyValuePairs = (values, shouldLowerCastValue = false) => {
  const arr = []
  for (const value of values) {
    let formattedValue = value
    if (shouldLowerCastValue && !isNumber(value)) {
      formattedValue = value.toLocaleLowerCase()
    }
    arr.push({ value: formattedValue, label: value.toString() })
  }
  return arr
}

export const objectToKeyValuePairs = (obj, shouldLowerCastValue = false) => {
  return arrayToKeyValuePairs(values(obj), shouldLowerCastValue)
}
