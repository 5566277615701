import { GeneratedText, gtLocked, gtIfElse, gtTemplate, gtConditional } from 'shared/utils/narrative/GeneratedText'

import { mapDTO } from './mappings'

const generate = () => {
  const gt = new GeneratedText()

  const unitsLabel = gtLocked('unitsLabel')
  const bedroomsLabel = gtLocked('bedroomsLabel')
  const rentTypeList = gtLocked('rentTypeList')
  const basisWord = gtLocked('basisWord')

  // Market Rent Sentence
  const min = gtLocked('min')
  const max = gtLocked('max')
  const avg = gtLocked('avg')
  const psfMin = gtLocked('psfMin')
  const psfMax = gtLocked('psfMax')
  const psfAvg = gtLocked('psfAvg')
  const psfCompMin = gtLocked('psfCompMin')
  const psfCompMax = gtLocked('psfCompMax')
  const psfCompAvg = gtLocked('psfCompAvg')
  const psfMarketConclusion = gtLocked('psfMarketConclusion')

  const psfRangeSentence = gtConditional('showMonthlyAndPSF', gtTemplate` or ${psfMin} to ${psfMax} psf`)
  const psfAvgSentence = gtConditional('showMonthlyAndPSF', gtTemplate` or ${psfAvg} psf`)
  const psfCompRangeSentence = gtConditional('showMonthlyAndPSF', gtTemplate` or ${psfCompMin} to ${psfCompMax} psf`)
  const psfCompAvgSentence = gtConditional('showMonthlyAndPSF', gtTemplate` or ${psfCompAvg} psf`)
  const psfMarketConclusionSentence = gtConditional('showMonthlyAndPSF', gtTemplate` or ${psfMarketConclusion} psf`)

  const marketRateUnitsVerb = gtIfElse('moreThanOneMarketRateUnit', gtTemplate`are`, gtTemplate`is`)
  const unitsStatsPhrase = gtIfElse(
    'unitStatsMinGreaterThanZero',
    gtTemplate`leased at ${min} per ${basisWord}.`,
    gtTemplate`currently vacant.`
  )
  const comparingPhrase = gtIfElse(
    'minEqualsMax',
    gtTemplate`${marketRateUnitsVerb} ${unitsStatsPhrase}`,
    gtTemplate`range from ${min} to ${max} per ${basisWord}${psfRangeSentence} 
    with an average of ${avg} per ${basisWord}${psfAvgSentence}. 
    The subject's rents vary slightly due to minor differences in terms of finishes, layout, and size, 
    as well as how long each tenant has been in place.`
  )

  const noMarketRentSentence = gtIfElse(
    'asIs',
    gtTemplate`The subject has no market rate ${bedroomsLabel} units, as they are all currently ${rentTypeList}. 
    Currently, the in-place rental range is from ${min} to ${max} per month${psfRangeSentence}.`,
    gtTemplate`The subject will have no market rate ${bedroomsLabel} units, as they all will be ${rentTypeList}.`
  )
  const marketRentSentence = gtIfElse(
    'hasMarketRateUnits',
    gtTemplate`The subject's market rate ${unitsLabel} ${comparingPhrase}`,
    gtTemplate`${noMarketRentSentence}`
  )

  // Rent Comp Range Sentence
  const compMin = gtLocked('compMin')
  const compMax = gtLocked('compMax')
  const compAvg = gtLocked('compAvg')

  const compRangeSentence = gtTemplate`Comparable ${bedroomsLabel} units range 
  from ${compMin} to ${compMax} per ${basisWord}${psfCompRangeSentence} 
  with an average of ${compAvg} per ${basisWord}${psfCompAvgSentence}.`

  // Rent Conclusion Sentence
  const pluralizedRents = gtLocked('pluralizedRents')
  const marketConclusion = gtLocked('marketConclusion')
  const rentConclusionPhrase = gtConditional(
    'hasMarketRateUnits',
    gtTemplate` the subject's in-place ${pluralizedRents} and`
  )

  const psfTimePeriod = gtConditional(
    'hasSqft',
    gtIfElse('annualTimePeriod', gtTemplate` per year`, gtTemplate` per month`)
  )
  const rentConclusionSentence = gtTemplate`Based on${rentConclusionPhrase} the range 
  of the comparables, we forecast a market rent of ${marketConclusion} 
  per ${basisWord}${psfTimePeriod}${psfMarketConclusionSentence}.`

  // Subject Market Orientation Sentence
  const marketBreakdownSentence = gtLocked('marketBreakdownSentence')
  const subjectMarketOrientationSentence = gtConditional('hasMarketRateUnits', gtTemplate` ${marketBreakdownSentence}.`)

  gt.t`${marketRentSentence} ${compRangeSentence} ${rentConclusionSentence}${subjectMarketOrientationSentence}`

  return gt.getParts()
}

export default { generate, mapDTO }
