import Conclusion from 'report/forms/cost/Conclusion'
import ConstructionCostComps from 'report/forms/cost/ConstructionCostComps/components/ConstructionCostComps'
import Depreciation from 'report/forms/cost/Depreciation'
import EntrepreneurialIncentive from 'report/forms/cost/EntrepreneurialIncentive'
import FurnitureFixturesAndEquipment from 'report/forms/cost/FurnitureFixturesAndEquipment/FurnitureFixturesAndEquipment'
import MarshallValuationService from 'report/forms/cost/MarshallValuationService'
import Reconciliation from 'report/forms/cost/Reconciliation'
import SubjectBudget from 'report/forms/cost/SubjectBudget'
import {
  CONCLUSION_KEY,
  CONSTRUCTION_COST_COMPS_KEY,
  COST_APPROACH_KEY,
  DEPRECIATION_KEY,
  EI_KEY,
  FFE_KEY,
  MVS_KEY,
  RECONCILIATION_KEY,
  SUBJECT_BUDGET_KEY,
} from 'shared/constants/report/keysAndDataPaths'

export default [
  {
    key: COST_APPROACH_KEY,
    name: 'Cost',
  },
  {
    key: SUBJECT_BUDGET_KEY,
    name: 'Subject Budget',
    formComponent: SubjectBudget,
  },
  {
    key: MVS_KEY,
    name: 'Marshall Valuation Service',
    formComponent: MarshallValuationService,
  },
  {
    key: CONSTRUCTION_COST_COMPS_KEY,
    name: 'Construction Cost Comps',
    formComponent: ConstructionCostComps,
  },
  {
    key: RECONCILIATION_KEY,
    name: 'Reconciliation',
    formComponent: Reconciliation,
  },
  {
    key: FFE_KEY,
    name: 'Furniture, Fixtures, & Equipment',
    formComponent: FurnitureFixturesAndEquipment,
  },
  {
    key: EI_KEY,
    name: 'Entrepreneurial Incentive',
    formComponent: EntrepreneurialIncentive,
  },
  {
    key: DEPRECIATION_KEY,
    name: 'Depreciation',
    formComponent: Depreciation,
  },
  {
    key: CONCLUSION_KEY,
    name: 'Conclusion',
    formComponent: Conclusion,
  },
]
