import React, { FC } from 'react'
import { Paper, Stack, Typography } from '@mui/material'
import { ExtendedFormApi } from 'client-shared/utils/form'
import { EI_PATH } from 'shared/constants/report/keysAndDataPaths'
import wrapForm from 'report/forms/wrapForm'

import { NarrativeComponent } from 'client-shared/components'
import entrepreneurialIncentive from 'shared/utils/textGeneration/costApproach/entrepreneurialIncentive'

import { PAPER_WIDTH } from '../../helpers'

type EntrepreneurialIncentiveProps = {
  form: ExtendedFormApi
}

const EntrepreneurialIncentive: FC<EntrepreneurialIncentiveProps> = ({ form }) => {
  const { values } = form
  const { asCompleteEntrepreneurialIncentive } = values
  return (
    <Paper id="entrepreneurial-incentive" sx={{ maxWidth: PAPER_WIDTH }}>
      <Stack>
        <Typography variant="h5">Entrepreneurial Incentive</Typography>
        <NarrativeComponent
          data={entrepreneurialIncentive.mapDTO({ conclusion: { asCompleteEntrepreneurialIncentive } })}
          generatedText={entrepreneurialIncentive.generate}
          name="entrepreneurialIncentive"
          title=""
        />
      </Stack>
    </Paper>
  )
}

export default wrapForm(EI_PATH, {
  heading: 'Entrepreneurial Incentive',
})(EntrepreneurialIncentive)
