import React from 'react'
import PropTypes from 'prop-types'

import { Divider, Grid, Stack, Typography } from '@mui/material'
import { chunk } from 'lodash'

const LabelValueList = ({ labelKeysPairs, rowsPerColumn, renderListValue }) => {
  const chunkLength = rowsPerColumn || labelKeysPairs.length
  const chunks = chunk(labelKeysPairs, chunkLength)
  return (
    <Stack direction="row" spacing={2} divider={<Divider orientation="vertical" flexItem />}>
      {chunks.map((recordChunk, recordChunkIndex) => (
        <Stack key={recordChunkIndex} spacing={1} width={1}>
          {recordChunk.map((pair, pairIndex) => (
            <Grid container key={pairIndex}>
              <Grid item xs={6}>
                <Typography variant="body1">{pair.label}:</Typography>
              </Grid>
              <Grid item xs={6} alignSelf="center">
                {renderListValue(pair)}
              </Grid>
            </Grid>
          ))}
        </Stack>
      ))}
    </Stack>
  )
}

LabelValueList.propTypes = {
  labelKeysPairs: PropTypes.array.isRequired,
  renderListValue: PropTypes.func.isRequired,
  rowsPerColumn: PropTypes.number,
}

export { LabelValueList }
