import React from 'react'
import PropTypes from 'prop-types'

import { TableCell } from '@mui/material'

import { DraggableTableRowV2 } from 'client-shared/components/DragDropTable'
import { Text } from 'client-shared/components'

import { SUBJECT_PROPERTY_SINGULAR_CATEGORIES } from './constants'

const SubjectPropertyDraggableRow = ({
  disabled,
  dragging,
  index,
  name,
  onFocus,
  onInsertProperty,
  onRemoveProperty,
  propertyCategory,
  setInputRef,
  singleValue,
}) => {
  const inputPlaceHolder = React.useMemo(
    () => `Write a property ${SUBJECT_PROPERTY_SINGULAR_CATEGORIES[propertyCategory.replace('swotAnalysis.', '')]}.`,
    [propertyCategory]
  )

  const handleRemoveProperty = () => {
    onRemoveProperty(index)
  }

  const handleKeyPress = event => {
    if (event.key === 'Enter') {
      onInsertProperty(index)
      event.preventDefault()
    }
  }

  const innerSetInputRef = ref => {
    setInputRef(index, ref)
  }

  const handleFocus = () => {
    onFocus(index)
  }

  return (
    <DraggableTableRowV2
      draggingState={dragging}
      id={name}
      index={index}
      onRemove={handleRemoveProperty}
      singleValue={singleValue}
    >
      <TableCell>
        <Text
          data-qa={`input-${index}`}
          disabled={disabled}
          inputRef={innerSetInputRef}
          margin="none"
          multiline
          name={`${name}`}
          onKeyPress={handleKeyPress}
          placeholder={inputPlaceHolder}
          rowsMax={Infinity}
          InputProps={{
            onFocus: handleFocus,
          }}
          sx={{
            '& .MuiOutlinedInput-root': {
              '& fieldset': {
                border: 'none',
              },
            },
            width: 1,
          }}
        />
      </TableCell>
    </DraggableTableRowV2>
  )
}

SubjectPropertyDraggableRow.propTypes = {
  disabled: PropTypes.bool.isRequired,
  dragging: PropTypes.bool.isRequired,
  index: PropTypes.number.isRequired,
  name: PropTypes.string.isRequired,
  onFocus: PropTypes.func.isRequired,
  onInsertProperty: PropTypes.func.isRequired,
  onRemoveProperty: PropTypes.func.isRequired,
  propertyCategory: PropTypes.string.isRequired,
  setInputRef: PropTypes.func.isRequired,
  singleValue: PropTypes.bool.isRequired,
}

export default SubjectPropertyDraggableRow
