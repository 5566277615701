import React from 'react'
import PropTypes from 'prop-types'

import { Button, Menu, MenuItem } from '@mui/material'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'

const DropDownButton = ({ children, options, onSelect, variant = 'contained', ...props }) => {
  const [anchorEl, setAnchorEl] = React.useState(null)
  const handleClick = React.useCallback(event => {
    setAnchorEl(event.currentTarget)
  }, [])
  const handleClose = React.useCallback(() => {
    setAnchorEl(null)
  }, [])
  const handleMenuItemClick = React.useCallback(
    option => () => {
      if (typeof onSelect === 'function') {
        onSelect(option)
      }
      handleClose()
    },
    [handleClose, onSelect]
  )

  const open = Boolean(anchorEl)

  return (
    <>
      <Button
        data-qa={props['data-qa']}
        size="small"
        endIcon={
          <KeyboardArrowDownIcon sx={{ transition: 'transform 0.3s', transform: open ? 'rotate(-180deg)' : 'none' }} />
        }
        onClick={handleClick}
        variant={variant}
      >
        {children}
      </Button>
      <Menu
        anchorEl={anchorEl}
        data-qa="dropdown-menu-list"
        onClose={handleClose}
        open={open}
        PaperProps={{ role: 'menubar' }}
      >
        {options.map(option => (
          <MenuItem
            key={option.label}
            disabled={option.disabled}
            data-qa={`${option.label}-menu-item`}
            onClick={handleMenuItemClick(option)}
          >
            {option.label}
          </MenuItem>
        ))}
      </Menu>
    </>
  )
}

DropDownButton.displayName = 'DropDownButton'

DropDownButton.propTypes = {
  children: PropTypes.node.isRequired,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      disabled: PropTypes.bool,
      value: PropTypes.string.isRequired,
    })
  ).isRequired,
  onSelect: PropTypes.func.isRequired,
}

export default DropDownButton
