export const ORGANIZATION_FORMS_STRUCTURE = {
  organizationInfo: true,
  organizationUploads: true,
  organizationUsers: true,
  organizationNewUser: true,
  organizationClients: true,
  organizationNewClient: true,
  settings: true,
  manageComparables: {
    rentComparables: true,
    uploadRentComparables: true,
    commercialComparables: true,
    uploadCommercialComparables: true,
    salesComparables: true,
    uploadSalesComparables: true,
    comparableExpenses: true,
  },
  archivedComparables: {
    rentArchivedComparables: true,
    commercialRentArchivedComparables: true,
    salesArchivedComparables: true,
    archivedComparableExpenses: true,
  },
  insurableValues: true,
}
export const AppraisalInstituteDesignationTypes = {
  NONE: 'none',
  DESIGNATED_MEMBER: 'designated member',
  CANDIDATE: 'candidate',
  PRACTICING_AFFILIATE: 'practicing affiliate',
}
export const AppraisalInstituteDesignationTypesList = [
  { label: 'Designated Member', value: AppraisalInstituteDesignationTypes.DESIGNATED_MEMBER },
  { label: 'Candidate', value: AppraisalInstituteDesignationTypes.CANDIDATE },
  { label: 'Practicing Affiliate', value: AppraisalInstituteDesignationTypes.PRACTICING_AFFILIATE },
]
export const AppraisalInstituteEducationStatus = {
  COMPLETED: 'COMPLETED',
  NOT_COMPLETED: 'NOT_COMPLETED',
}

export const ROLE_MANAGEMENT_HELP_TEXT_READONLY = 'Roles are now managed in Google Workspace Admin.'
export const ROLE_MANAGEMENT_HELP_TEXT =
  'Roles are now managed in Google Workspace Admin and selections here will be ' +
  'overridden if the username matches a bowery email address.'

export const VIEW_ONLY_TEXT = 'View Only'
