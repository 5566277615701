import React, { ComponentProps, FC, useCallback, useEffect, useState } from 'react'
import { makeStyles } from '@mui/styles'
import SearchIcon from '@mui/icons-material/Search'

import { Theme } from 'core/theme'

import Text from '../Text/Text'

const useStyles = makeStyles<Theme>(theme => ({
  searchBox: {
    width: '420px',
    alignSelf: 'center',
    marginTop: '16px',
    marginBottom: '8px',
  },
  outlineInput: {
    width: '100%',
    backgroundColor: theme.palette.background.paper,
    '& ::placeholder': {
      color: '#4E5664',
      opacity: 1,
    },
  },
}))

type Props = {
  onChange: (newSearchQuery: string) => void
  placeholder?: string
}

const SearchBox: FC<Props> = ({ onChange, placeholder }) => {
  const classes = useStyles()
  const [searchQuery, setSearchQuery] = useState('')

  useEffect(() => {
    onChange(searchQuery)
  }, [onChange, searchQuery])

  const handleChange: ComponentProps<typeof Text>['onChange'] = useCallback((event): void => {
    'value' in event.target && setSearchQuery(event.target.value)
  }, [])

  return (
    <Text
      value={searchQuery}
      onChange={handleChange}
      startAdornment={<SearchIcon />}
      placeholder={placeholder}
      className={classes.searchBox}
      classes={{ outlineInput: classes.outlineInput }}
    />
  )
}

export default SearchBox
