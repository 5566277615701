import React from 'react'
import PropTypes from 'prop-types'
import { Typography } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'

import ProgressBar from '../ProgressBar'

import { DEFAULT_FINISHING_THRESHOLD, UPLOAD_STATUSES } from './constants'

const PROGRESS_BAR_COLORS = {
  [UPLOAD_STATUSES.PENDING]: '#4260D3',
  [UPLOAD_STATUSES.COMPLETE]: '#2c877c',
  [UPLOAD_STATUSES.ERROR]: '#D74F4F',
}

const styles = {
  progressBar: {
    width: 150,
    maxHeight: 10,
    borderRadius: 5,
    '& .rc-progress-line-path': {
      // important is used to override transition of svg path inline styles
      transition: 'stroke-dashoffset 0.03s ease 0s, stroke 0.03s linear 0s, 0.03s, 3s !important',
    },
  },
}

class FileUploadProgressBar extends React.PureComponent {
  static propTypes = {
    uploadStatus: PropTypes.string.isRequired,
    progress: PropTypes.number.isRequired,
    finishingThreshold: PropTypes.number,
  }

  static defaultProps = {
    finishingThreshold: DEFAULT_FINISHING_THRESHOLD,
  }

  getProgressBarMarkup = () => {
    const { classes, uploadStatus, progressBarProps, progress, finishingThreshold } = this.props
    const isPendingStatus = uploadStatus === UPLOAD_STATUSES.PENDING
    let uploadMessage
    if (isPendingStatus) {
      if (progress < finishingThreshold) {
        uploadMessage = 'Uploading file...'
      } else {
        uploadMessage = 'Finishing...'
      }
    } else if (uploadStatus === UPLOAD_STATUSES.COMPLETE) {
      uploadMessage = 'Done!'
    } else {
      uploadMessage = 'Oops...'
    }

    return (
      <React.Fragment>
        <ProgressBar
          data-qa={`progress-${progress}-percentages`}
          {...progressBarProps}
          className={classes.progressBar}
          strokeColor={PROGRESS_BAR_COLORS[uploadStatus]}
          percent={progress}
        />
        <Typography variant="caption">{uploadMessage}</Typography>
      </React.Fragment>
    )
  }

  render() {
    return this.getProgressBarMarkup()
  }
}

export default withStyles(styles)(FileUploadProgressBar)
