export const styles = theme => ({
  optionsList: {
    marginTop: theme.spacing.unit * 2,
    '& label': {
      transform: 'none',
    },
    '& [role=radiogroup]': {
      marginTop: theme.spacing.unit * 2,
    },
    '& > div': {
      minHeight: 'auto',
    },
  },
})
