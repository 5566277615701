import React, { createRef } from 'react'
import PropTypes from 'prop-types'

import { Box, Stack } from '@mui/material'
import { blueGrey } from '@material-ui/core/colors'
import { withStyles } from '@material-ui/core/styles'
import { Grid, Paper, TableCell } from '@material-ui/core'

import { connect } from 'react-redux'

import { find, get, isEmpty, pick } from 'lodash'

import { errorNotification } from 'client-shared/redux/actions/notifications'
import MultiSelectField from 'client-shared/components/_mui5/Select/Multi/multi-select-field'
import { mapCompPlexResidentialLeaseToRentComp } from 'shared/helpers/compplex'
import AutoCompIcon from 'report/components/AutoCompIcon'
import { CompPlexEvents, CompPlexResidentialLeaseEvents } from 'client-shared/constants/compPlex'
import { getAuthorizationHeader } from 'core/api'
import { compose } from 'redux'
import { withMicrofrontend } from 'client-shared/hooks/useMicrofrontend'

import { REMOVED_RESIDENTIAL_COMPS_TABLE_COLUMNS, AUTOMATED_COMPS_TOOLTIP } from '../constants'
import { CheckboxWithLabel } from '../../../../../../shared/components'

import { formatCurrencyInt } from '../../../../../../shared/utils/numberFormatters'
import { getSourceOfInformation } from '../../../../../../shared/components/SourceOfInformation/SourceOfInformation'
import {
  findUnitCompGroupAndAddComps,
  findUnitCompGroupAndRemoveComp,
  findUnitCompGroupAndUpdateComp,
} from '../../ResidentialRentRoll/tools'

import { importRentComps } from '../redux/actions'
import { selectRentCompSettings, selectRentCompSearchState } from '../redux/selectors'

import CompsImportForm from '../../../../../../shared/components/CompsImportForm'

import RemovedCompsTable from '../../../../../components/RemovedCompsTable'

import { getRemovedCompActions } from '../../../../../utils/columnHelpers'

import RentCompsTable from './UnitComps/RentCompsTable'

import { checkIfResidentialRentCompsAreLatestVersion } from './compplex-helpers'

const styles = theme => ({
  mapPanel: {
    padding: 0,
    maxHeight: 520,
    display: 'initial',
  },
  titleRoot: {
    paddingLeft: theme.spacing.unit * 2,
    '&$titleExpanded': {
      minHeight: '48px',
    },
  },
  titleContent: {
    color: theme.palette.text.primary,
    fontFamily: theme.typography.fontFamily,
    '&$titleExpanded': {
      margin: '12px 0',
    },
  },
  titleExpanded: {},
  searchResultsWrapper: {
    height: 420,
    overflowY: 'scroll',
    overflowX: 'hidden',
    padding: '0 0 0 0',
  },
  searchResults: {
    padding: 8,
    backgroundColor: blueGrey[100],
  },
  modalContainer: {
    padding: theme.spacing.unit,
  },
  searchSummaryInfo: {
    paddingLeft: theme.spacing.unit,
  },
  mapWrapper: {
    position: 'relative',
    display: 'flex',
    height: 750,
  },
  inlineCheckbox: {
    display: 'inline',
    marginLeft: 10,
  },
  inlineCheckboxLabel: {
    marginTop: 0,
  },
  noSearchResults: {
    width: '100%',
    userSelect: 'none',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: blueGrey[100],
  },
  addRentButton: {
    height: 36,
  },
  actionIcon: {
    color: theme.palette.common.white,
  },
  removedCompsTableCell: {
    padding: '4px 16px',
    fontSize: '14px',
    fontFamily: 'Nunito Sans',
  },
  addButton: {
    backgroundColor: '#21B586',
    '&:hover': {
      background: '#087461',
    },
    marginRight: '5px',
  },
  removeButton: {
    backgroundColor: '#D25441',
    '&:hover': {
      background: '#9E211B',
    },
    marginLeft: '24px',
    marginRight: '-14px',
  },
})

class ResidentialUnitComps extends React.PureComponent {
  static propTypes = {
    classes: PropTypes.object.isRequired,
    reportId: PropTypes.string.isRequired,
    form: PropTypes.object.isRequired,
    importRentComps: PropTypes.func.isRequired,
    importedRentComps: PropTypes.array,
    groupingType: PropTypes.string.isRequired,
    selectedUnitsById: PropTypes.object.isRequired,
    isLoading: PropTypes.bool.isRequired,
    moreResultsAvailable: PropTypes.bool.isRequired,
  }

  static defaultProps = {
    importedRentComps: [],
  }

  state = {
    isMapOpened: false,
    editModalComp: null,
    editLeaseId: null,
    editModalToggle: false,
  }

  constructor() {
    super()
    this.compPlexRef = createRef()
  }

  componentDidMount() {
    const { compPlexLoaded, errorNotification } = this.props
    if (!compPlexLoaded) {
      errorNotification('Failed to load rent comps service. Some features will not be available.')
    }

    if (this.compPlexRef.current) {
      this.compPlexRef.current.addEventListener(CompPlexResidentialLeaseEvents.EDIT_COMP_SAVED, this.handleAddComp)
      this.compPlexRef.current.addEventListener(CompPlexResidentialLeaseEvents.FOUND_COMP_ADDED, this.handleAddComp)
      this.compPlexRef.current.addEventListener(CompPlexResidentialLeaseEvents.EDIT_COMP_UPDATED, this.handleUpdateComp)
      this.compPlexRef.current.addEventListener(CompPlexResidentialLeaseEvents.EDIT_COMP_CLOSED, this.hideEditModal)
      this.compPlexRef.current.addEventListener(CompPlexResidentialLeaseEvents.MAP_COMP_ADDED, this.handleAddComp)
      this.compPlexRef.current.addEventListener(
        CompPlexResidentialLeaseEvents.MAP_COMP_REMOVED,
        this.handleMapCompRemoved
      )
      this.compPlexRef.current.addEventListener(CompPlexEvents.ERROR, this.handleError)
      this.compPlexRef.current.addEventListener(
        CompPlexResidentialLeaseEvents.MAP_EDIT_MODAL_OPENED,
        this.handleMapEditModalOpen
      )
      this.compPlexRef.current.addEventListener(
        CompPlexResidentialLeaseEvents.MAP_EDIT_MODAL_CLOSED,
        this.handleMapEditModalClosed
      )
      this.checkIfCompsAreLatestVersion()
    }
  }

  componentWillUnmount() {
    if (this.compPlexRef.current) {
      this.compPlexRef.current.removeEventListener(CompPlexResidentialLeaseEvents.EDIT_COMP_SAVED, this.handleAddComp)
      this.compPlexRef.current.removeEventListener(CompPlexResidentialLeaseEvents.FOUND_COMP_ADDED, this.handleAddComp)
      this.compPlexRef.current.removeEventListener(
        CompPlexResidentialLeaseEvents.EDIT_COMP_UPDATED,
        this.handleUpdateComp
      )
      this.compPlexRef.current.removeEventListener(CompPlexResidentialLeaseEvents.EDIT_COMP_CLOSED, this.hideEditModal)
      this.compPlexRef.current.removeEventListener(CompPlexResidentialLeaseEvents.MAP_COMP_ADDED, this.handleAddComp)
      this.compPlexRef.current.removeEventListener(
        CompPlexResidentialLeaseEvents.MAP_COMP_REMOVED,
        this.handleMapCompRemoved
      )
      this.compPlexRef.current.removeEventListener(CompPlexEvents.ERROR, this.handleError)
      this.compPlexRef.current.removeEventListener(
        CompPlexResidentialLeaseEvents.MAP_EDIT_MODAL_OPENED,
        this.handleMapEditModalOpen
      )
      this.compPlexRef.current.removeEventListener(
        CompPlexResidentialLeaseEvents.MAP_EDIT_MODAL_CLOSED,
        this.handleMapEditModalClosed
      )
    }
  }

  componentDidUpdate(prevProps) {
    const { importedRentComps } = this.props

    const shouldAddImportedComps = importedRentComps !== prevProps.importedRentComps && !isEmpty(importedRentComps)

    if (shouldAddImportedComps) {
      this.addImportedRentComps(importedRentComps)
    }
  }

  checkIfCompsAreLatestVersion = () => {
    const { form } = this.props
    const { unitCompGroups } = form.values
    unitCompGroups.forEach(async (compGroup, index) => {
      const { units = [] } = compGroup
      const updatedUnits = await checkIfResidentialRentCompsAreLatestVersion(units)
      compGroup.units = updatedUnits || []
      form.mutators.update('unitCompGroups', index, compGroup)
    })
  }

  handleError = event => {
    const { error } = event.detail
    this.props.errorNotification(error.message || error)
  }

  handleAddComp = event => {
    const { residentialRentComp } = event.detail
    const mappedResidentialRentComp = mapCompPlexResidentialLeaseToRentComp(residentialRentComp)
    this.addComp(mappedResidentialRentComp)
  }

  handleMapCompRemoved = event => {
    const { leaseId } = event.detail
    const { form } = this.props
    const { unitCompGroups } = form.values

    let unitToRemove
    unitCompGroups.forEach(compGroup => {
      const { units } = compGroup
      unitToRemove = find(units, unit => unit.leaseId === leaseId)
      if (unitToRemove) {
        this.removeComp(unitToRemove)
      }
    })
  }

  handleUpdateComp = event => {
    const {
      detail: { residentialRentComp },
    } = event
    const { editLeaseId } = this.state
    const webappComp = mapCompPlexResidentialLeaseToRentComp(residentialRentComp, editLeaseId)
    this.updateComp(webappComp)
    this.setState({ editLeaseId: null })
  }

  addImportedRentComps = importedRentComps => {
    this.addComps(importedRentComps)
  }

  toggleMap = () => {
    this.setState(prevState => ({ isMapOpened: !prevState.isMapOpened }))
  }

  addComps = units => {
    const { form, groupingType, selectedUnitsById } = this.props
    const { unitCompGroups } = form.values

    const updatedUnitCompGroups = findUnitCompGroupAndAddComps(unitCompGroups, groupingType, units, selectedUnitsById)
    form.change('unitCompGroups', updatedUnitCompGroups)
  }

  addComp = unit => {
    const { form, groupingType, selectedUnitsById } = this.props
    const { unitCompGroups, removedRentComps } = form.values
    const updatedUnitCompGroups = findUnitCompGroupAndAddComps(unitCompGroups, groupingType, [unit], selectedUnitsById)
    if (!removedRentComps.findIndex(comp => comp._id === unit._id)) {
      const updatedRemovedRentComps = removedRentComps.filter(comp => !comp._id === unit._id)
      form.batch(() => {
        form.change('unitCompGroups', updatedUnitCompGroups)
        form.change('removedRentComps', updatedRemovedRentComps)
      })
    } else {
      form.change('unitCompGroups', updatedUnitCompGroups)
    }
  }

  updateComp = unit => {
    const { form, groupingType, selectedUnitsById } = this.props
    const { unitCompGroups } = form.values

    const updatedUnitCompGroups = findUnitCompGroupAndUpdateComp(unitCompGroups, groupingType, unit, selectedUnitsById)
    form.change('unitCompGroups', updatedUnitCompGroups)
  }

  reorderComps = updatedUnitCompGroups => {
    const { form } = this.props
    form.change('unitCompGroups', updatedUnitCompGroups)
  }

  removeComp = unit => {
    const { form, groupingType, selectedUnitsById } = this.props
    const { unitCompGroups } = form.values
    const updatedUnitCompGroups = findUnitCompGroupAndRemoveComp(unitCompGroups, groupingType, unit, selectedUnitsById)

    form.batch(() => {
      form.change('unitCompGroups', updatedUnitCompGroups)
      form.mutators.push('removedRentComps', unit)
    })
  }

  deleteRemovedComp = compIndex => {
    const { form } = this.props
    const { removedRentComps } = form.values
    const newRemovedRentComps = removedRentComps.filter((value, index) => index !== compIndex)

    form.change('removedRentComps', newRemovedRentComps)
  }

  unremoveComp = compIndex => {
    const { form, groupingType, selectedUnitsById } = this.props
    const { removedRentComps, unitCompGroups } = form.values
    const newRemovedRentComps = removedRentComps.filter((value, index) => index !== compIndex)

    const updatedUnitCompGroups = findUnitCompGroupAndAddComps(
      unitCompGroups,
      groupingType,
      [removedRentComps[compIndex]],
      selectedUnitsById
    )
    form.batch(() => {
      form.change('unitCompGroups', updatedUnitCompGroups)
      form.change('removedRentComps', newRemovedRentComps)
    })
  }

  removedCompsTableRowCell = column => {
    const {
      comp,
      column: { name, defaultValue },
    } = column
    const { classes } = this.props
    let cellContent
    let align = 'left'

    switch (name) {
      case 'isAutoComp':
        align = 'left'
        cellContent = <AutoCompIcon comp={comp} tooltipMessage={AUTOMATED_COMPS_TOOLTIP} />
        break
      case 'bedrooms':
        align = 'right'
        cellContent = get(comp, name, defaultValue)
        break
      case 'sourceName':
        cellContent = getSourceOfInformation(comp)
        break
      case 'rent':
        cellContent = formatCurrencyInt(get(comp, name, defaultValue))
        align = 'right'
        break
      case 'actions':
        cellContent = getRemovedCompActions(column, classes, this.unremoveComp, this.deleteRemovedComp)
        break
      default:
        cellContent = get(comp, name, defaultValue)
    }

    return (
      <TableCell align={align} data-qa={`removed-comps-table-${name}-cell`} className={classes.removedCompsTableCell}>
        {cellContent}
      </TableCell>
    )
  }
  showEditModal = lease => {
    this.setState({ editModalComp: lease, editLeaseId: lease._id, editModalToggle: true })
  }

  hideEditModal = () => {
    this.setState({ editModalComp: null, editModalToggle: false })
  }

  getEditModalProps = () => {
    const { editModalComp, editModalToggle } = this.state
    return {
      showEditModal: editModalToggle,
      leaseId: editModalComp?.leaseId || null,
      leaseVersionNumber: editModalComp?.leaseVersionNumber || null,
    }
  }

  getSelectedRentCompIds = () => {
    const { form } = this.props
    const { unitCompGroups } = form.values
    const selectedRentCompIds = []
    unitCompGroups?.forEach(compGroup => {
      compGroup?.units.forEach(unit => {
        if (unit.leaseId) {
          selectedRentCompIds.push({ id: unit.leaseId })
        }
      })
    })
    return selectedRentCompIds
  }
  render() {
    const {
      classes,
      form,
      areReportRentCompsLoading,
      importRentComps,
      authenticatedUser,
      subjectCoordinates,
      reportId,
      jobNumber,
    } = this.props
    const {
      unitCompGroups,
      showSFForComps,
      showDevelopersForecast,
      unitRentPSFTimePeriod,
      fieldsToDisplayInExport,
      automationMetadata,
    } = form.values
    const showBathrooms = fieldsToDisplayInExport?.includes('bathrooms')
    const showAmenities = fieldsToDisplayInExport?.includes('amenities')
    const editModalProps = this.getEditModalProps()
    const subjectProps = {
      coordinates: subjectCoordinates,
    }

    const reportData = { jobNumber, reportId }
    const selectedRentCompIds = this.getSelectedRentCompIds()

    return (
      <Grid container wrap="nowrap" direction="column" spacing={8}>
        <Grid item xs={12}>
          <comp-plex-residential-lease
            ref={this.compPlexRef}
            auth-user={JSON.stringify(authenticatedUser)}
            webapp-auth-header={getAuthorizationHeader().Authorization}
            webapp-api-url={global.env.apiUrl}
            edit-lease-props={JSON.stringify(editModalProps)}
            subject={JSON.stringify(subjectProps)}
            selected-comps={JSON.stringify(selectedRentCompIds)}
            meta-data={JSON.stringify(reportData)}
          />
        </Grid>

        <Grid item xs={12}>
          <Paper className={classes.modalContainer}>
            <Stack direction="row" alignItems="center" spacing={2}>
              <CompsImportForm
                tooltipText="Use this to copy over Residential Rent Comps saved to another report."
                tooltipPosition="top-end"
                import={importRentComps}
                isLoading={areReportRentCompsLoading}
                dialogContent="Enter the ID or URL of the report you would like to import comps from"
              />
              <Box sx={{ display: 'inline', alignItems: 'center', minWidth: '200px' }}>
                <MultiSelectField
                  name="fieldsToDisplayInExport"
                  label="Table Settings"
                  placeholder="Table Settings"
                  sx={{ width: '225px' }}
                  multiple
                  options={[
                    { label: 'Display Amenities', value: 'amenities' },
                    { label: 'Display Bathrooms', value: 'bathrooms' },
                  ]}
                />
              </Box>
              <Box sx={{ display: 'inline', alignItems: 'center' }}>
                <CheckboxWithLabel name="showSFForComps" label="Display square footage for comps?" />
              </Box>
              <Box sx={{ display: 'inline', alignItems: 'center' }}>
                <CheckboxWithLabel name="exportResidentialRentCompPhotos" label="Do you want comp photos to export?" />
              </Box>
            </Stack>
          </Paper>
        </Grid>
        <Grid item xs={12}>
          <RentCompsTable
            automationMetadata={automationMetadata}
            unitCompGroups={unitCompGroups}
            showPerUnitSF={showSFForComps}
            showDevelopersForecast={showDevelopersForecast}
            unitRentPSFTimePeriod={unitRentPSFTimePeriod}
            showBathrooms={showBathrooms}
            showAmenities={showAmenities}
            reorderComps={this.reorderComps}
            removeComp={this.removeComp}
            showEditModal={this.showEditModal}
            hideEditModal={this.hideEditModal}
          />
        </Grid>
        <Grid item xs={12}>
          <RemovedCompsTable
            title="Removed Comp"
            form={form}
            cellComponent={this.removedCompsTableRowCell}
            columns={REMOVED_RESIDENTIAL_COMPS_TABLE_COLUMNS}
            fieldName="removedRentComps"
          />
        </Grid>
      </Grid>
    )
  }
}

const mapStateToProps = (state, ownProps) => {
  const reportNumber = get(state, 'report.reportData.report.reportInformation.reportNumber')
  const reportId = get(state, 'report.reportData._id')

  const propertyInformation = get(state, 'report.reportData.propertyInformation')

  const subjectCoordinates = {
    lat: get(propertyInformation, 'coords.latitude'),
    lng: get(propertyInformation, 'coords.longitude'),
  }

  return {
    ...selectRentCompSettings(state, ownProps),
    ...selectRentCompSearchState(state, ownProps),
    importedRentComps: get(state, 'rentComps.importedRentComps'),
    areReportRentCompsLoading: get(state, 'rentComps.areReportRentCompsLoading'),
    authenticatedUser: pick(get(state, 'authentication.user'), ['id', 'username', 'fullName']),
    jobNumber: reportNumber,
    reportId,
    subjectCoordinates,
  }
}

const mapDispatchToProps = dispatch => {
  return {
    importRentComps: reportId => dispatch(importRentComps({ reportId })),
    errorNotification: error => dispatch(errorNotification({ message: error })),
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(compose(withStyles(styles), withMicrofrontend('compplex', 'compPlexLoaded'))(ResidentialUnitComps))
