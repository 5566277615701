import React from 'react'

import { Form } from 'react-final-form'
import { difference, get, isEmpty } from 'lodash'

import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material'

import { Auto } from '@ui/Field'
import { ADDITIONAL_EXPENSES_CATEGORIES_LIST, EXPENSE_CATEGORIES } from 'shared/constants/expenses'

const defaultExpenseList = [...Object.values(EXPENSE_CATEGORIES), ...ADDITIONAL_EXPENSES_CATEGORIES_LIST]

const AddExpenseDialog = ({
  currentExpense = '',
  excludedExpenses = [],
  isEdit = false,
  name = 'Default Name',
  onCancel,
  onSave,
  validate,
}) => {
  const suggestions = difference(defaultExpenseList, excludedExpenses)

  const [inputValue, setInputValue] = React.useState(currentExpense)
  const validateOnlyDirty = React.useCallback((value, _values, { dirty }) => dirty && validate(value), [validate])
  const parse = React.useCallback(value => value ?? '', [])
  const onChange = React.useCallback(([_event, currentValue, _reason, _details], onChangeField) => {
    const nextValue = get(currentValue, 'value', currentValue || '')
    onChangeField(nextValue)
    setInputValue(nextValue)
  }, [])
  const onInputChange = React.useCallback((event, onChangeField) => {
    const nextValue = get(event, 'target.value', '')
    onChangeField(nextValue)
    setInputValue(nextValue)
  }, [])
  const getOptionDisabled = React.useCallback(({ value }) => value === null, [])
  const normalizeStringValue = React.useCallback(value => value?.toLowerCase()?.trim(), [])
  const isOptionEqualToValue = React.useCallback(
    (option, value) => normalizeStringValue(option?.label || option) === normalizeStringValue(value),
    [normalizeStringValue]
  )
  const filterOptions = React.useCallback(
    options => {
      const availableOptions = options.filter(option =>
        normalizeStringValue(option).includes(normalizeStringValue(inputValue))
      )
      const canOnlyCreate = isEmpty(availableOptions) && inputValue
      const selectedExisted = availableOptions.map(normalizeStringValue).includes(normalizeStringValue(inputValue))
      if (selectedExisted) {
        return [
          { value: null, label: `${inputValue} selected from existed` },
          { value: null, label: `Enter new category name${!isEmpty(options) ? ' or select category below' : ''}` },
          ...availableOptions,
        ]
      }
      if (canOnlyCreate) {
        return [{ value: inputValue, label: `${inputValue} will be created` }]
      }
      if (inputValue) {
        return [
          { value: inputValue, label: `${inputValue} will be created` },
          { value: null, label: `...or select category below` },
          ...availableOptions,
        ]
      }
      return [
        { value: null, label: `Enter new category name${!isEmpty(options) ? ' or select category below' : ''}` },
        ...availableOptions,
      ]
    },
    [inputValue, normalizeStringValue]
  )

  return (
    <Form initialValues={{ expenseName: currentExpense }} onSubmit={onSave}>
      {({ handleSubmit, invalid, pristine }) => (
        <Dialog data-qa="custom-expense-dialog" fullWidth maxWidth="sm" open>
          <DialogTitle>{name}</DialogTitle>
          <DialogContent>
            <Auto.FreeSolo
              data-qa="custom-expense-name-input"
              autoSelect
              name="expenseName"
              options={suggestions}
              placeholder="Enter Custom Expense..."
              validate={validateOnlyDirty}
              {...{
                filterOptions,
                getOptionDisabled,
                inputValue,
                isOptionEqualToValue,
                onChange,
                onInputChange,
                parse,
                value: inputValue,
              }}
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={onCancel}>Cancel</Button>
            <Button
              data-qa="custom-expense-submit-btn"
              disabled={pristine || invalid}
              onClick={handleSubmit}
              variant="contained"
            >
              {!isEdit ? 'Add' : 'Save'}
            </Button>
          </DialogActions>
        </Dialog>
      )}
    </Form>
  )
}

export default AddExpenseDialog
