import React from 'react'
import { once } from 'lodash'
import { withStyles } from '@material-ui/core/styles'
import Close from '@mui/icons-material/Close'
import Add from '@mui/icons-material/Add'

import { Button, IconButton } from '../../../../../../../shared/components'
import { formatCurrencyFloat } from '../../../../../../../shared/utils/numberFormatters'
import { PropertiesSearchResultsColumnNames } from '../../constants'

import AmenitiesList from '../AmenitiesList'

import SearchResultsCell from './SearchResultsCell'

const styles = theme => ({
  button: {
    margin: theme.spacing.unit / 2,
    padding: 4,
  },
  squareRemoveButton: {
    borderRadius: 0,
    margin: theme.spacing.unit / 2,
    padding: 4,
  },
  actionsContainer: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
  },
})

const PropertiesSearchResultsCellComponent = ({
  onAddCompItem,
  onRemoveCompItem,
  isSelected,
  toggleDetailsExpand,
  isExpanded,
  isSubject,
  dataKey,
  cellData,
  classes,
  width,
}) => {
  let cellComponent = null
  const addClickHandler = once(onAddCompItem)

  switch (dataKey) {
    case PropertiesSearchResultsColumnNames.AVERAGE_RENT:
      cellComponent = formatCurrencyFloat(cellData)
      break
    case PropertiesSearchResultsColumnNames.AMENITIES:
      cellComponent = <AmenitiesList amenities={cellData} />
      break
    case PropertiesSearchResultsColumnNames.ACTIONS:
      const detailsLabel = isExpanded ? 'HIDE' : 'DETAILS'

      const actionButton = isSelected ? (
        <IconButton color="error" className={classes.squareRemoveButton} onClick={onRemoveCompItem}>
          <Close />
        </IconButton>
      ) : (
        <IconButton className={classes.squareRemoveButton} onClick={addClickHandler}>
          <Add />
        </IconButton>
      )

      cellComponent = (
        <div className={classes.actionsContainer}>
          <Button className={classes.button} onClick={toggleDetailsExpand}>
            {detailsLabel}
          </Button>
          {!isSubject && actionButton}
        </div>
      )
      break
    case PropertiesSearchResultsColumnNames.ADDRESS:
      cellComponent = isSubject ? `Subj. ${cellData}` : cellData
      break
    default:
      cellComponent = cellData || '-'
      break
  }

  return (
    <SearchResultsCell isSubject={isSubject} width={width}>
      {cellComponent}
    </SearchResultsCell>
  )
}
export const PropertySearchResultCell = withStyles(styles)(PropertiesSearchResultsCellComponent)
