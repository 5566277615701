import React from 'react'
import PropTypes from 'prop-types'
import { Field } from 'react-final-form'
import { get, camelCase } from 'lodash'
import { compose, setPropTypes, defaultProps } from 'recompose'

import { IconButton, TextField, InputAdornment, FormControl, withStyles, Grid } from '@material-ui/core'
import FileUploadIcon from '@mui/icons-material/CloudUpload'
import DeleteOutline from '@mui/icons-material/DeleteOutline'

import FileSectionModal from './FileSelectionModal'

const styles = theme => ({
  formControl: {
    height: 84,
  },
  button: {
    marginTop: 8,
  },
  textField: {
    width: '99%',
  },
})

class MaterialFieldInput extends React.PureComponent {
  state = {
    isOpenModal: false,
  }

  get inputDescription() {
    const { name, fileName } = get(this.props.input, 'value') || {}
    return fileName || name || ''
  }

  getInputAdornment = () => (
    <InputAdornment position="end">
      <IconButton size="small">
        <FileUploadIcon />
      </IconButton>
    </InputAdornment>
  )

  handleClick = event => {
    this.props.input.onFocus()
    this.setState({
      isOpenModal: true,
    })
  }

  handleClose = () => {
    this.setState({ isOpenModal: false })
  }

  onValueSelected = value => {
    const { onChange, input } = this.props
    if (onChange) {
      onChange(value)
    }
    input.onChange(value)
    this.setState({
      isOpenModal: false,
    })
  }

  onRemoveFile = fileName => {
    const { onDelete } = this.props
    this.props.input.onFocus()
    if (onDelete) {
      onDelete(fileName)
    }
  }

  render() {
    const {
      fileType,
      label,
      classes,
      uploadEndpoint,
      cancelEndpoint,
      parentId,
      fileTypesRestriction,
      helperText,
      errorText,
      children,
      maxUploadSize,
      onDelete,
      fileName,
      template,
      ...other
    } = this.props

    const { isOpenModal } = this.state
    const error = !!errorText
    return (
      <React.Fragment>
        <FormControl className={classes.formControl} fullWidth>
          <Grid container xs={12}>
            <Grid item xs={onDelete ? 11 : 12}>
              <TextField
                onClick={this.handleClick}
                value={this.inputDescription}
                label={label}
                InputLabelProps={{ shrink: true }}
                InputProps={{ endAdornment: this.getInputAdornment() }}
                helperText={error ? errorText : helperText}
                error={error}
                type="text"
                disabled
                variant="outlined"
                margin="dense"
                data-qa={`file-selection-${camelCase(label)}-input`}
                className={classes.textField}
                {...other}
              />
            </Grid>
            {onDelete && (
              <Grid item xs={1}>
                <IconButton
                  variant="contained"
                  color="textSecondary"
                  onClick={() => this.onRemoveFile(fileName)}
                  className={classes.button}
                >
                  <DeleteOutline className={classes.icon} />
                </IconButton>
              </Grid>
            )}
            {children}
          </Grid>
        </FormControl>
        {isOpenModal && (
          <FileSectionModal
            label={label}
            handleClose={this.handleClose}
            onValueSelected={this.onValueSelected}
            fileType={fileType}
            fileTypesRestriction={fileTypesRestriction}
            uploadEndpoint={uploadEndpoint}
            cancelEndpoint={cancelEndpoint}
            parentId={parentId}
            maxUploadSize={maxUploadSize}
            template={template}
          />
        )}
      </React.Fragment>
    )
  }
}

const FileSelectionInputField = ({
  name,
  label,
  fileType,
  uploadEndpoint,
  cancelEndpoint,
  parentId,
  fileTypesRestriction,
  ...other
}) => (
  <Field
    name={name}
    label={label}
    fileType={fileType}
    uploadEndpoint={uploadEndpoint}
    cancelEndpoint={cancelEndpoint}
    parentId={parentId}
    component={MaterialFieldInput}
    fileTypesRestriction={fileTypesRestriction}
    fileName={name}
    {...other}
  />
)

export default compose(
  withStyles(styles),
  setPropTypes({
    name: PropTypes.string.isRequired,
    parentId: PropTypes.string,
    fileType: PropTypes.string.isRequired,
    uploadEndpoint: PropTypes.string.isRequired,
    cancelEndpoint: PropTypes.string,
    label: PropTypes.string,
    fileTypesRestriction: PropTypes.array,
    helperText: PropTypes.string,
    errorText: PropTypes.string,
    onChange: PropTypes.func,
    maxUploadSize: PropTypes.number,
    onDelete: PropTypes.func,
    template: PropTypes.string,
  }),
  defaultProps({
    fileTypesRestriction: [],
    maxUploadSize: null,
  })
)(FileSelectionInputField)
