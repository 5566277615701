import React from 'react'

import { Typography } from '@mui/material'

import Layout from '@ui/Layout'

import propertyConditionReport from 'shared/utils/textGeneration/property/propertyConditionReport'
import { CheckboxWithLabel, DatePicker, NarrativeComponent, Text } from 'client-shared/components'

const PropertyConditionReport = ({ hasPropertyConditionReport, propertyCondition }) => (
  <Layout.VerticalRow>
    <Typography variant="h6">Property Condition Report</Typography>
    <CheckboxWithLabel
      data-qa="has-property-condition-checkbox"
      label="Property condition report was given."
      margin="normal"
      name="propertyCondition.hasReport"
    />
    {!!hasPropertyConditionReport && (
      <Layout.TwoColumns gap={2}>
        <Text name="propertyCondition.reportSender" placeholder="CBRE" label="Sender" />
        <DatePicker name="propertyCondition.reportDate" label="Date of Report" />
      </Layout.TwoColumns>
    )}
    <NarrativeComponent
      data={propertyConditionReport.mapDTO({ propertyCondition })}
      generatedText={propertyConditionReport.generate}
      name="propertyCondition.reportCommentary"
      title="Property Condition Report"
    />
  </Layout.VerticalRow>
)

export default PropertyConditionReport
