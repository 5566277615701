import {
  RESIDENTIAL_INCOME_KEY,
  RESIDENTIAL_RENT_ROLL_KEY,
  RESIDENTIAL_UNIT_GROUPS_KEY,
  RESIDENTIAL_RENT_COMPS_KEY,
  RESIDENTIAL_RENT_COMPS_MAP_KEY,
  RESIDENTIAL_BUILDING_RENT_COMPS_KEY,
  RESIDENTIAL_RENT_RECONCILIATION_KEY,
  RESIDENTIAL_PROJECTED_RENT_ROLL_KEY,
  RESIDENTIAL_PROJECTED_RENT_ROLL_SUMMARY_KEY,
  RESIDENTIAL_EXPENSE_STRUCTURE_KEY,
} from 'shared/constants/report/keysAndDataPaths'

import ResidentialUnitRentCompsMap from '../../../forms/income/residential/ResidentialRentCompsMap'
import ResidentialRentComps from '../../../forms/income/residential/ResidentialRentComps'
import ResidentialProjectedRentRoll from '../../../forms/income/residential/ResidentialProjectedRentRoll'
import ResidentialRentReconciliation from '../../../forms/income/residential/ResidentialRentReconciliation'
import ResidentialRentRoll from '../../../forms/income/residential/ResidentialRentRoll'
import ResidentialProjectedRentRollSummary from '../../../forms/income/residential/ResidentialProjectedRentRollSummary'
import ResidentialBuildingRentCompsMap from '../../../forms/income/residential/ResidentialBuildingRentCompsMap'
import ResidentialUnitGroups from '../../../forms/income/residential/ResidentialUnitGroups'
import ResidentialExpenseStructure from '../../../forms/income/residential/ResidentialExpenseStructure'

export default [
  {
    key: RESIDENTIAL_INCOME_KEY,
    name: 'Residential',
  },
  {
    key: RESIDENTIAL_RENT_ROLL_KEY,
    name: 'In-Place Rent Roll',
    formComponent: ResidentialRentRoll,
  },
  {
    key: RESIDENTIAL_UNIT_GROUPS_KEY,
    name: 'Unit Groups',
    formComponent: ResidentialUnitGroups,
  },
  {
    key: RESIDENTIAL_RENT_COMPS_KEY,
    name: 'Rent Comps',
    formComponent: ResidentialRentComps,
  },
  {
    key: RESIDENTIAL_RENT_COMPS_MAP_KEY,
    name: 'Rent Comps Map',
    formComponent: ResidentialUnitRentCompsMap,
  },
  {
    key: RESIDENTIAL_BUILDING_RENT_COMPS_KEY,
    name: 'Rent Comps Map',
    formComponent: ResidentialBuildingRentCompsMap,
  },
  {
    key: RESIDENTIAL_RENT_RECONCILIATION_KEY,
    name: 'Residential Rent Reconciliation',
    formComponent: ResidentialRentReconciliation,
  },
  {
    key: RESIDENTIAL_PROJECTED_RENT_ROLL_KEY,
    name: 'Stabilized Rent Roll',
    formComponent: ResidentialProjectedRentRoll,
  },
  {
    key: RESIDENTIAL_PROJECTED_RENT_ROLL_SUMMARY_KEY,
    name: 'Stabilized Rent Roll Summary',
    formComponent: ResidentialProjectedRentRollSummary,
  },
  {
    key: RESIDENTIAL_EXPENSE_STRUCTURE_KEY,
    name: 'Residential Expense Structure',
    formComponent: ResidentialExpenseStructure,
  },
]
