import { GeneratedText, gtLocked, gtConditional, gtTemplate } from '../../../narrative/GeneratedText'

import { mapDTO, mapDataModel } from './mappings'

const generate = () => {
  const gt = new GeneratedText()
  gt.t`In this appraisal we provide an opinion of As Is Market Value of the ${gtLocked(
    'interestAppraisedAsIsMarketValue',
    null,
    'Interest Appraised As Is Market Value selection'
  )}`

  const valueAsCompleteConditional = gtConditional('valueAsComplete')
  // eslint-disable-next-line no-constant-condition
  valueAsCompleteConditional.children.push(...gtTemplate`${'valueAsComplete' ? ',' : ' and'}`)
  valueAsCompleteConditional.children.push(
    ...gtTemplate` the Prospective Market Value Upon Completion of the proposed renovations of the ${gtLocked(
      'interestAppraisedAsComplete',
      null,
      'Interest Appraised As Complete selection'
    )}`
  )
  gt.addPart(valueAsCompleteConditional)

  const valueAsStabilizedConditional = gtConditional('valueAsStabilized')
  valueAsStabilizedConditional.children.push(
    ...gtTemplate` and the Prospective Market Value Upon Stabilization of occupancy of the ${gtLocked(
      'interestAppraisedAsStabilized',
      null,
      'Interest Appraised As Stabilized selection'
    )}`
  )
  gt.addPart(valueAsStabilizedConditional)

  gt.t`.`
  return gt.getParts()
}

export default { generate, mapDTO, mapDataModel }
