import { GeneratedText, gtConditional, gtIfElse, gtTemplate } from 'shared/utils/narrative/GeneratedText'

import { mapDTO, mapDataModel } from './mappings'

const generate = () => {
  const gt = new GeneratedText()

  const vacantPhrase = gtConditional('hasVacantUnits', gtTemplate` and forecast market rent for the vacant units`)
  const asIsPhrase = gtTemplate` gauge the reasonableness of the contract rents${vacantPhrase}`

  const forecastPhrase = gtIfElse('valueAsIs', asIsPhrase, gtTemplate` forecast the market rents`)

  gt.t`In order to${forecastPhrase}, we have examined the following rental activity in the submarket:`

  return gt.getParts()
}

export default { generate, mapDTO, mapDataModel }
