import io from 'socket.io-client'

import HttpClientManager from '../../xhr/HttpClientManager'

const socket = io(global.env.apiUrl, {
  transports: ['websocket'],
})

socket.connect()

socket
  .on('connect', () => console.log('Socket opened'))
  .on('init', socketId => {
    HttpClientManager.setSocketId(socketId)
    console.log('Socket initialized')
  })

export const on = (eventName, callback) => {
  socket.on(eventName, callback)
}

export const emit = (eventName, callback) => {
  socket.emit(eventName, (...args) => {
    if (callback) {
      callback(...args)
    }
  })
}

export const off = (eventName, callback) => {
  socket.off(eventName, callback)
}
