import {
  identifierColumnsNYC,
  identifierColumnsNJ,
  identifierColumnsOther,
  GEOGRAPHY_OPTIONS,
} from '../../constants/properties'

const NEW_JERSEY_STRATEGY = {
  uniqueIdentifiers: identifierColumnsNJ,
}

const NEW_YORK_CITY_STRATEGY = {
  uniqueIdentifiers: identifierColumnsNYC,
}

const OTHER_STRATEGY = {
  uniqueIdentifiers: identifierColumnsOther,
}

export const DEFAULT_STRATEGY = {
  uniqueIdentifiers: [],
}

const strategies = {
  [GEOGRAPHY_OPTIONS.NY]: NEW_YORK_CITY_STRATEGY,
  [GEOGRAPHY_OPTIONS.NJ]: NEW_JERSEY_STRATEGY,
  [GEOGRAPHY_OPTIONS.OTHER]: OTHER_STRATEGY,
}

export const getStrategy = locationIdentifier => {
  return strategies[locationIdentifier] || DEFAULT_STRATEGY
}
