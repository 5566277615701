import { get } from 'lodash'

import { DEFAULT_SF_ANALYSIS_BASIS } from '../../../../constants/report'

import { mapValues } from '../buildingDescription/mappings'

const mapCurrentValues = (values, valueAsComplete) => {
  const result = mapValues(values, valueAsComplete)
  const isMultiStory = values.floors !== 1
  return {
    ...result,
    isMultiStory,
  }
}

export const mapDTO = formValues => {
  const unmappedFormValues = {
    ...get(formValues, 'current', {}),
    basisForSFAnalysis: get(formValues, 'basisForSFAnalysis', DEFAULT_SF_ANALYSIS_BASIS),
  }

  return mapCurrentValues(unmappedFormValues, formValues.valueAsComplete)
}

export const mapDataModel = report => {
  const currentBuildingDescriptionValues = get(report, 'property_information.current', {})
  const basisForSFAnalysis = get(report, 'property_information.basisForSFAnalysis', DEFAULT_SF_ANALYSIS_BASIS)

  currentBuildingDescriptionValues.basisForSFAnalysis = basisForSFAnalysis

  return mapCurrentValues(currentBuildingDescriptionValues, report.valueAsComplete)
}
