export const Labels = {
  COMMERCIAL_RENT_COMPS: 'Rent Comps',
  DROP_ANY_RENT_ROLL_UNIT_HERE: 'Drop any rent roll unit here',
  RENT_PER_SF: 'Rent/SF',
  RENT_PER_SF_PER_MONTH: 'Rent/SF/Month',
}
export const DEFAULT_SORT_TYPE = 'Newest'
export const UNIT_TYPE_LABEL = 'Subject'
export const UNSORTED_GROUP_ID = 'unsorted_group'
export const UNSORTED_GROUP = 'Unsorted'

export const UnsortedCompGroup = {
  value: UNSORTED_GROUP_ID,
  label: UNSORTED_GROUP,
}

export const REMOVED_COMMERCIAL_COMPS_TABLE_COLUMNS = [
  { name: 'address', title: 'Unit Address' },
  { name: 'status', title: 'Status ' },
  { name: 'use', title: 'Use' },
  { name: 'tenantName', title: 'Tenant Name' },
  { name: 'dateSigned', title: 'Date Signed' },
  { name: 'squareFeet', title: 'Square Feet' },
  { name: 'rentPsf' },
  { name: 'sourceOfInformation', title: 'Source' },
  { name: 'actions', title: 'Actions' },
]
