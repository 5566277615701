import { get, compact, toLower } from 'lodash'

import { formatList } from 'shared/utils/textHelpers'

import { formatInt } from '../../../../../shared/utils/numberFormatters'

import { INCOME_POTENTIAL_LOOKUP } from './constants'

const getConditionsSentence = (conditionConclusion, transition) => {
  switch (conditionConclusion) {
    case 'Inferior':
      return (
        `${transition}the comparables are in slightly inferior condition overall and thus carry greater` +
        ` risk of necessary capital improvements, lending to higher cap rates.`
      )
    case 'Similar':
      return (
        `${transition}the subject property and comparables are in similar condition overall, with similar` +
        ` risk of necessary capital improvements during the holding period.`
      )
    case 'Superior':
      return (
        `${transition}the comparables are in slightly superior condition overall and thus carry less` +
        ` risk of necessary capital improvements, lending to lower cap rates.`
      )
    default:
      return ''
  }
}

const getLocationSentence = (locationConclusion, transition) => {
  switch (locationConclusion) {
    case 'Inferior':
      return (
        `${transition}, the comparables are in slightly inferior locations overall,` +
        ` lending to higher capitalization rates.`
      )
    case 'Similar':
      return `${transition}, the subject property and comparables are in similar locations overall.`
    case 'Superior':
      return (
        `${transition}, the comparables are in slightly superior locations overall,` +
        ` lending to lower capitalization rates.`
      )
    default:
      return ''
  }
}

export const getCapRateCompsDiscussion = ({
  subjectIncomePotential,
  comparableIncomePotential,
  comparablePropertyConditions,
  comparablePropertyLocations,
}) => {
  const potentialSentence = get(INCOME_POTENTIAL_LOOKUP, [subjectIncomePotential, comparableIncomePotential], '')
  const transitionCheck =
    comparableIncomePotential === comparablePropertyConditions &&
    comparablePropertyLocations !== comparablePropertyConditions
  const locationsSentence = getLocationSentence(
    comparablePropertyLocations,
    transitionCheck ? 'On the other hand' : 'Additionally'
  )
  const conditionsSentence = getConditionsSentence(
    comparablePropertyConditions,
    comparableIncomePotential === comparablePropertyConditions ? 'Further, ' : 'On the other hand, '
  )

  const sentences = compact([potentialSentence, locationsSentence, conditionsSentence])
  return sentences.join(' ')
}

export const getHighAndBestUse = ({ discussFeasibleConversion, specificPropertyTypes, rentControlledTenants }) => {
  if (!discussFeasibleConversion) {
    return ''
  }
  const asImprovedFeasiblePropertyTypes = specificPropertyTypes.map(toLower)
  return (
    `Moreover, the subject is currently encumbered by ${rentControlledTenants} stabilized ${
      rentControlledTenants === 1 ? 'lease' : 'leases'
    }; ` +
    "however, given the characteristics of the subject's location, the highest and best use, " +
    `unencumbered, is conversion to ${formatList(asImprovedFeasiblePropertyTypes)}. As such, there is ` +
    "limited risk associated with the asset's cash flow and an investor would require a lower " +
    'return due to the potential for a significant value spike if it were to become unencumbered.'
  )
}

export const getTaxGap = ({ discussAssessmentGap }) => {
  if (!discussAssessmentGap) {
    return ''
  }
  return (
    "On the other hand, the subject's transitional assessment is significantly lower than its actual assessment." +
    "This implies that the subject's taxable assessment will likely increase as the transitional assessment trends " +
    'towards the actual assessment, causing an investor to require a higher return.'
  )
}

export const getAirRights = ({ unusedAirRights, discussAirRights }) => {
  if (!discussAirRights) {
    return ''
  }
  return (
    `The subject property also contains ${formatInt(unusedAirRights)} square feet of additional air rights,` +
    ' which provide further future potential to an investor, lending to a lower capitalization rate.'
  )
}

export const getAdditionalIncomeSpikesDiscussion = unusedAirRights => formValues => {
  const highAndBestUse = getHighAndBestUse(formValues)
  const taxGap = getTaxGap(formValues)
  const airRights = getAirRights({ ...formValues, unusedAirRights })
  const sentences = compact([highAndBestUse, taxGap, airRights])
  return sentences.join(' ')
}

export const getAirRightsCalloutContent = ({ unusedAirRights, grossBuildingAreaPerFloor }) => {
  return `Your property has ${formatInt(unusedAirRights)} SF of unused air rights. GBA/No. of Floors = ${formatInt(
    grossBuildingAreaPerFloor
  )} SF. If your unused air rights exceed the GBA/No. of Floors, Bowery recommends
  that you discuss them.`
}
