import { get } from 'lodash'
import { connect } from 'react-redux'

import { hideConfirmModal } from '../../redux/actions/report'

import FormConfirmModal from './FormConfirmModal'

const mapStateToProps = state => {
  const showConfirmModal = get(state, 'report.showConfirmModal', false)
  const loggingOut = get(state, 'report.loggingOut', false)

  return {
    showConfirmModal,
    loggingOut,
  }
}

const mapDispatchToProps = dispatch => ({
  onSubmit: () => dispatch(hideConfirmModal({ submit: true, navigate: true })),
  onCancel: () => dispatch(hideConfirmModal({ navigate: false })),
  onReject: () => dispatch(hideConfirmModal({ navigate: true })),
})

export default connect(mapStateToProps, mapDispatchToProps)(FormConfirmModal)
