import { Plugin } from 'slate-react'

import { UPDATE_INLINE_NODES_COMMAND } from '../constants'

export default {
  commands: {
    updateInlineNodes(editor, data, formatters) {
      editor.controller.command(UPDATE_INLINE_NODES_COMMAND, data, formatters)
      return editor
    },
  },
} as Plugin

declare module 'slate-react' {
  interface Editor {
    updateInlineNodes(data: any, formatters: any): Editor
  }
}
