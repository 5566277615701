import { isNil, isNaN } from 'lodash'
import numeral from 'numeral'

export const CurrencyFormats = {
  currencyWithNoDecimalPlaces: '$0,0',
  currencyWithTwoDecimalPlaces: '$0,0.00',
}

export const formatInt = value => {
  if (isNil(value)) {
    return value
  }

  return numeral(value).format('0,0')
}
export const formatMaxDecimalFloat = (value, maxDecimalPlaces = 2) => {
  if (isNil(value)) {
    return '0'
  }

  const fractionalPart = '0'.repeat(maxDecimalPlaces)
  const format = `0,0.[${fractionalPart}]`

  return numeral(value).format(format)
}
export const formatFloat = (value, decimalPlaces = 2) => {
  if (isNil(value)) {
    return value
  }

  const fractionalPart = '0'.repeat(decimalPlaces)
  const format = `0,0.${fractionalPart}`

  return numeral(value).format(format)
}

export const formatPercentageFloat = (value, maxDecimal = 4) => {
  if (isNil(value)) {
    return value
  }

  return parseFloat((value * 100).toFixed(maxDecimal))
}
export const formatPercentageString = (value, maxDecimal = 4) => {
  if (isNil(value)) {
    return value
  }

  return `${parseFloat((value * 100).toFixed(maxDecimal)).toString()}%`
}

export const formatCurrencyInt = value => {
  const { currencyWithNoDecimalPlaces } = CurrencyFormats

  if (isNil(value)) {
    return value
  }

  return numeral(Math.round(value)).format(currencyWithNoDecimalPlaces)
}

export const formatCurrencyFloat = value => {
  const { currencyWithTwoDecimalPlaces } = CurrencyFormats

  if (isNil(value)) {
    return value
  }

  return numeral(Number(value).toFixed(2)).format(currencyWithTwoDecimalPlaces)
}

export const formatToNumericString = (value = '') => {
  if (isNil(value)) {
    return value
  }

  return value.toString().replace(/\D/g, '')
}

export const toPercentageString = (value, decimalPlaces = 2) => {
  const fullValue = value * 100
  if (isNaN(fullValue)) {
    return ''
  }
  return isNil(value) ? '' : `${fullValue.toFixed(decimalPlaces)}%`
}
