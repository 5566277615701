import React from 'react'
import { get, isEmpty } from 'lodash'
import { Grid, Paper, Typography, Divider } from '@material-ui/core'
import createDecorator from 'final-form-calculate'
import { UTILITIES_PATH } from 'shared/constants/report/keysAndDataPaths'
import { getFeatureFlagValue } from '@bowery-valuation/feature-flagger-client'
import { ENABLE_AUTOMATED_INSPECTION_PREFILL } from 'shared/constants/featureFlags'

import heatingAndCoolingSystemsDiscussion from 'shared/utils/textGeneration/property/heatingAndCoolingSystemsDiscussion'

import { DismissibleCallout, NarrativeComponent } from 'client-shared/components'

import wrapForm from '../../wrapForm'

import GasMeters from './GasMeters'
import ElectricMeters from './ElectricMeters'
import HotWaterSystems from './HotWaterSystems'
import HeatingSystems from './HeatingSystems'
import CoolingSystems from './CoolingSystems'

export const DATA_PATH = UTILITIES_PATH
const heading = 'Utilities Description'

const styles = theme => ({
  paper: theme.paper,
})

const AS_COMPLETE_MESSAGE =
  "Please select the construction characteristics of the property's utilities upon completion of the prospective renovations."

class Utilities extends React.PureComponent {
  render() {
    const { classes, form } = this.props
    const { values } = form
    const { heatingSystems, coolingSystems, hotWaterSystems, valueAsComplete, automationMetadata } = values
    const automatedInspectionPrefillFlag = getFeatureFlagValue(ENABLE_AUTOMATED_INSPECTION_PREFILL)

    return (
      <Grid container direction="column" spacing={16}>
        {valueAsComplete && (
          <Grid item xs={9}>
            <DismissibleCallout text={AS_COMPLETE_MESSAGE} />
          </Grid>
        )}
        <Grid item xs={9}>
          <Paper className={classes.paper}>
            <Grid direction="column" container spacing={8}>
              <Grid item>
                <Typography variant="h6">Heating and Cooling System(s)</Typography>
              </Grid>
              <Grid item>
                <HeatingSystems
                  {...heatingSystems}
                  automationMetadata={automationMetadata}
                  automatedInspectionPrefillFlag={automatedInspectionPrefillFlag}
                />
                <Divider />
              </Grid>
              <Grid item>
                <CoolingSystems
                  {...coolingSystems}
                  automationMetadata={automationMetadata}
                  automatedInspectionPrefillFlag={automatedInspectionPrefillFlag}
                />
                <Divider />
              </Grid>
              <Grid item>
                <NarrativeComponent
                  data={heatingAndCoolingSystemsDiscussion.mapDTO({ heatingSystems, coolingSystems })}
                  generatedText={heatingAndCoolingSystemsDiscussion.generate}
                  name="heatingAndCoolingSystemsDiscussion"
                  title="Heating and Cooling Systems Discussion"
                  tooltipText="The following generated text will appear in the Description of Improvements' Utilities section of your report."
                />
              </Grid>
            </Grid>
          </Paper>
        </Grid>
        <Grid item xs={9}>
          <GasMeters form={form} automatedInspectionPrefillFlag={automatedInspectionPrefillFlag} />
        </Grid>
        <Grid item xs={9}>
          <ElectricMeters form={form} automatedInspectionPrefillFlag={automatedInspectionPrefillFlag} />
        </Grid>
        <Grid item xs={9}>
          <HotWaterSystems
            form={form}
            inspected={get(hotWaterSystems, 'inspected')}
            location={get(hotWaterSystems, 'location')}
            systems={get(hotWaterSystems, 'systems')}
            count={get(hotWaterSystems, 'count')}
            automatedInspectionPrefillFlag={automatedInspectionPrefillFlag}
          />
        </Grid>
      </Grid>
    )
  }
}

const createUpdate = type => {
  return {
    field: [`${type}.inspected`, `${type}.count`],
    updates: (value, name, allValues) => {
      const systems = get(allValues, `${type}.systems`, [])
      if (name.includes('inspected')) {
        return value ? { [`${type}.count`]: systems?.length || 1, [`${type}.systems`]: [] } : { [`${type}.count`]: 0 }
      }
      return {
        [`${type}.systems`]: isEmpty(systems) ? [{}] : systems.slice(0, value),
      }
    },
  }
}

const decorator = createDecorator(
  createUpdate('coolingSystems'),
  createUpdate('heatingSystems'),
  createUpdate('hotWaterSystems')
)

export default wrapForm(DATA_PATH, {
  styles,
  heading,
  decorators: [decorator],
  registeredFields: ['gasMeters', 'electricMeters', 'coolingSystems', 'heatingSystems', 'hotWaterSystems'],
})(Utilities)
