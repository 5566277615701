import { get } from 'lodash'
import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { compose } from 'recompose'
import { FormGroup, Stack, Typography, Grid, Paper } from '@mui/material'

import { Text, CalloutButton } from 'client-shared/components'
import { getStateAuthorityLink } from 'client-shared/utils/propertyHelper'

import { ANCHOR_PROPERTY_HISTORY } from 'shared/constants/report/dataCollections'

import SalesHistory from './SalesHistory'

class PropertyHistory extends React.PureComponent {
  static propTypes = {
    classes: PropTypes.object.isRequired,
    form: PropTypes.object.isRequired,
    streetAddress: PropTypes.string,
    block: PropTypes.string,
    lot: PropTypes.string,
    propertyIdentifier: PropTypes.string,
    propertyIdentifierType: PropTypes.string,
    stateAuthorityLink: PropTypes.string,
  }

  render() {
    const { form, streetAddress, block, lot, propertyIdentifier, propertyIdentifierType, stateAuthorityLink, classes } =
      this.props
    const salesHistory = get(form, 'values.salesHistory', [])

    return (
      <Paper id={ANCHOR_PROPERTY_HISTORY} elevation={0} className={classes.noBackground}>
        <Grid item xs={12}>
          <Typography variant="h6">Property History</Typography>
        </Grid>

        <Grid item xs={12} sx={{ marginTop: '16px', marginBottom: '16px' }}>
          {stateAuthorityLink && (
            <CalloutButton
              variant="link"
              text="Visit your state's authority to confirm sales data before continuing."
              linkText="Visit"
              link={stateAuthorityLink}
              target="_blank"
            />
          )}
        </Grid>

        <Grid item xs={6}>
          <Text name="currentOwner" label="Current Owner" data-qa="current-owner" />
        </Grid>

        <Grid item xs={12}>
          <FormGroup>
            <Stack spacing={1}>
              <Typography variant="subtitle2" sx={{ marginTop: '24px', marginBottom: '16px' }}>
                Sales History
              </Typography>
              <SalesHistory
                block={block}
                lot={lot}
                propertyIdentifier={propertyIdentifier}
                propertyIdentifierType={propertyIdentifierType}
                salesHistory={salesHistory}
                streetAddress={streetAddress}
                form={form}
              />
            </Stack>
          </FormGroup>
        </Grid>
      </Paper>
    )
  }
}

const mapStateToProps = state => {
  const propertySummary = get(state, 'report.reportData.propertyInformation.propertySummary', {})
  const block = get(state, 'report.reportData.block')
  const lot = get(state, 'report.reportData.lot')

  const stateAuthorityLink = getStateAuthorityLink(get(state, 'report'))

  return {
    streetAddress: propertySummary.streetAddress,
    block,
    lot,
    propertyIdentifier: propertySummary.propertyIdentifier,
    propertyIdentifierType: propertySummary.propertyIdentifierType,
    stateAuthorityLink,
  }
}

export default compose(connect(mapStateToProps))(PropertyHistory)
