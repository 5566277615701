import React from 'react'

import arrayMutators from 'final-form-arrays'
import { FieldArray } from 'react-final-form-arrays'
import { get, eq } from 'lodash'

import { SOURCE_INFO_PATH } from 'shared/constants/report/keysAndDataPaths'

import wrapForm from '../../wrapForm'

import { FORM_HEADING } from './constants'
import SourceInfoTableContainer from './SourceInfoTableContainer'

const DATA_PATH = SOURCE_INFO_PATH

const SourceInfoContainer = ({
  form,
  defaultDataSources,
  locationIdentifier,
  appraisers,
  inspectionDate,
  reportTemplate,
}) => {
  const { selectedDataSources } = form.values
  const resetDataSources = () => {
    form.change('selectedDataSources', defaultDataSources)
    form.change('expenseDataSource', 'Owner')
  }

  return (
    <FieldArray
      appraisers={appraisers}
      component={SourceInfoTableContainer}
      form={form}
      inspectionDate={inspectionDate}
      isEqual={eq}
      locationIdentifier={locationIdentifier}
      name="selectedDataSources"
      reportTemplate={reportTemplate}
      resetDataSources={resetDataSources}
      selectedDataSources={selectedDataSources}
    />
  )
}

export default wrapForm(
  DATA_PATH,
  {
    heading: FORM_HEADING,
    mutators: {
      ...arrayMutators,
    },
  },
  (state, ownProps, _form) => {
    return {
      ...ownProps,
      defaultDataSources: get(state, `report.reportData.final.sourceInfo.defaultDataSources`),
      locationIdentifier: get(state, 'report.reportData.locationIdentifier'),
      appraisers: [
        ...get(state, 'report.reportData.report.appraisers.selectedAppraisers', []),
        ...get(state, 'report.reportData.report.appraisers.externalInspectors', []),
      ],
      inspectionDate: get(state, `report.reportData.report.reportInformation.inspectionDate`),
      reportTemplate: get(state, `report.reportSettings.templateType`, null),
    }
  }
)(SourceInfoContainer)
