import React from 'react'
import PropTypes from 'prop-types'

import { ReactSVG } from 'react-svg'
import { CircularProgress, Fab, FormControl, Grid, Paper, Typography, Box, Stack } from '@mui/material'
import { withStyles } from '@mui/styles'

import Save from '@mui/icons-material/Save'
import SettingsRounded from '@mui/icons-material/SettingsRounded'
import { get, isEmpty } from 'lodash'

import { compose } from 'recompose'

import { Form } from 'react-final-form'
import arrayMutators from 'final-form-arrays'
import { connect } from 'react-redux'

import { FILES_ENDPOINT } from '../../../report/constants'

import { Callout, Text, DropDown } from '../../../shared/components'
import { Text as TextComponent } from '../../../shared/components/Text/Text'
import { ImageUpload } from '../../../shared/components/ImageUpload'
import FormHeader from '../../../shared/components/FormHeader'

import { arrayToKeyValuePairs } from '../../../shared/utils/ui/checkboxHelper'
import { getFormalNamePreview } from '../../../shared/utils/ui/nameHelper'
import reportPreviewIcon from '../../../shared/images/report_preview_icon.svg'

import AppraisalInstituteDesignation from '../../components/AppraisalInstituteDesignation'
import AppraisalCertification from '../../components/AppraisalCertification'
import AvatarEditor from '../../components/AvatarEditor'
import { userOfficesFetch, userUpdate } from '../../redux/actions/user'

import { CLIENT_PREFIXES } from '../../../shared/constants'
import { required } from '../../../shared/utils/validation'
import FileSelectionField from '../../../shared/components/FileSelection'
import { getRoleLabelsById, roleIdsToLabels } from '../../../shared/utils/roles'
import { fetchRoles } from '../../../authentication/api'

export const FORM_NAME = 'updateUserSettings'
const heading = 'Change User Settings'

const styles = theme => ({
  displayIcon: {
    width: 24,
    height: 24,
    fill: '#344A5E',
    '& *': {
      fill: '#344A5E',
    },
  },
})

class Settings extends React.PureComponent {
  async componentDidMount() {
    const { userOfficesFetch, initialValues } = this.props
    let { rolesById } = this.props

    userOfficesFetch()

    if (isEmpty(rolesById)) {
      const roles = await fetchRoles()
      rolesById = getRoleLabelsById(roles)
    }

    if (initialValues.roles && !isEmpty(rolesById)) {
      initialValues.roleNames = roleIdsToLabels(initialValues.roles, rolesById)
    } else {
      initialValues.roleNames = ''
    }
  }

  handleSubmit = values => {
    this.props.userUpdate({
      formDataPath: FORM_NAME,
      values,
    })
  }

  render() {
    const { classes, isLoaded, initialValues, userOffices, areFilesUploading } = this.props
    const userId = initialValues._id

    return (
      <Grid container spacing={0.5}>
        <Grid item xs={12}>
          <FormHeader title={heading} icon={<SettingsRounded />} section={this.props.section} />
        </Grid>

        {!isLoaded && (
          <Box sx={{ position: 'fixed', top: '50%', left: '50%' }}>
            <CircularProgress size={100} color="success" />
          </Box>
        )}
        {isLoaded && (
          <Grid item xs={12} md={12} lg={8}>
            <Form
              onSubmit={this.handleSubmit}
              mutators={{
                ...arrayMutators,
              }}
              initialValues={initialValues}
              render={props => {
                const { handleSubmit, values, form } = props
                const { prefix, suffix, name } = values
                const formalNamePreview = getFormalNamePreview({ prefix, suffix, ...name })
                return (
                  <form onSubmit={handleSubmit}>
                    <Stack spacing={2}>
                      <Paper>
                        <Grid container spacing={1}>
                          <Grid item xs={12}>
                            <Typography variant="h6">User Information</Typography>
                          </Grid>
                          <Grid item xs={7}>
                            <Typography variant="caption" sx={{ color: '#989898' }}>
                              Avatar
                            </Typography>
                            <FormControl sx={{ width: 'calc(100% - 16px)', display: 'flex' }}>
                              <AvatarEditor avatar={get(values, 'avatar')} change={form.change} />
                            </FormControl>
                          </Grid>
                          <Grid item xs={5} sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
                            <Text
                              id="name"
                              label="Username"
                              name="username"
                              disabled
                              InputLabelProps={{
                                shrink: true,
                              }}
                            />
                            <TextComponent
                              label="Roles"
                              input={{ name: 'roleNames', value: initialValues.roleNames }}
                              disabled
                              fullWidth
                              InputLabelProps={{
                                shrink: true,
                              }}
                            />
                            <DropDown
                              name="prefix"
                              label="Prefix"
                              items={arrayToKeyValuePairs(CLIENT_PREFIXES)}
                              fullWidth
                            />
                            <Text name="name.first" label="First Name" validate={required} />
                            <Text name="name.middle" label="Middle Initial" />
                            <Text name="name.last" label="Last Name" />
                            <Text
                              name="suffix"
                              label="Suffix"
                              tooltiptext="A suffix should be comma-separated list of certifications"
                            />
                          </Grid>
                          <Grid item xs={12} sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
                            <Callout
                              variant="info"
                              icon={
                                <ReactSVG
                                  src={reportPreviewIcon}
                                  beforeInjection={svg => {
                                    svg.classList.add(classes.displayIcon)
                                  }}
                                />
                              }
                              content={formalNamePreview}
                            />
                            <Text name="position" label="Position in Organization" />
                            <DropDown
                              name="boweryOffice"
                              label="Bowery Office"
                              items={arrayToKeyValuePairs(userOffices)}
                              fullWidth
                            />
                            <FileSelectionField
                              name="qualifications"
                              label="Qualifications"
                              fileType="qualifications"
                              fileTypesRestriction={['docx']}
                              uploadEndpoint={FILES_ENDPOINT}
                              parentId={userId}
                            />
                            <Text name="phone" label="Phone" fullWidth />
                            <Typography variant="caption" sx={{ color: '#989898' }}>
                              Signature
                            </Typography>
                            <FormControl fullWidth sx={{ height: 200 }}>
                              <ImageUpload name="signature" />
                            </FormControl>
                          </Grid>
                        </Grid>
                      </Paper>
                      <Paper>
                        <AppraisalInstituteDesignation />
                      </Paper>
                      <Paper>
                        <AppraisalCertification />
                      </Paper>
                    </Stack>
                    <Fab
                      disabled={areFilesUploading}
                      type="submit"
                      color="success"
                      sx={{ position: 'fixed', bottom: 40, right: 40 }}
                    >
                      <Save sx={{ color: 'white' }} />
                    </Fab>
                  </form>
                )
              }}
            />
          </Grid>
        )}
      </Grid>
    )
  }
}

Settings.propTypes = {
  userUpdate: PropTypes.func,
  userOfficesFetch: PropTypes.func,
  areFilesUploading: PropTypes.bool,
}

const mapStateToProps = state => {
  const authentication = get(state, 'authentication')
  const user = get(authentication, 'user', {})
  const userOffices = get(state, 'user.userOffices')
  const roles = get(state, 'authentication.roles', [])
  const rolesById = getRoleLabelsById(roles)

  return {
    username: user.username,
    isLoaded: authentication.isLoaded,
    userOffices,
    rolesById,
    initialValues: user,
    areFilesUploading: get(state, 'shared.areFilesUploading'),
  }
}

export default compose(
  connect(mapStateToProps, {
    userUpdate,
    userOfficesFetch,
  }),
  withStyles(styles)
)(Settings)
