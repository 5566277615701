import { get, isEmpty } from 'lodash'

import { getCurrencyFormatter, getUnitOfComparisonText } from 'shared/report-calculations/sales-approach/helpers'

import SalesAdjustmentService from '../../../../../libs/bowery-libs/services/sales-adjustment'

const mapData = ({
  saleValueConclusion,
  compAdjustments,
  unitOfComparison,
  npvAdjustments,
  asCompleteNpvAdjustments,
  asStabilizedNpvAdjustments,
  salesComps,
  temper,
  netOperatingIncome,
  incomeAdjustmentLevel,
}) => {
  const salesAdjustmentService = new SalesAdjustmentService()

  const showAdjustments = !!compAdjustments.length

  const adjustedCompSuperlatives = salesAdjustmentService.getPriceDataForComps(
    salesComps,
    showAdjustments,
    unitOfComparison,
    temper,
    get(netOperatingIncome, 'perUnit', 0),
    get(netOperatingIncome, 'psf', 0),
    incomeAdjustmentLevel,
    compAdjustments
  )
  const adjustments = [...npvAdjustments, ...asCompleteNpvAdjustments, ...asStabilizedNpvAdjustments]

  const formatCurrency = getCurrencyFormatter(unitOfComparison)
  const calculationUnits = getUnitOfComparisonText(unitOfComparison)

  const adjustedMin = formatCurrency(adjustedCompSuperlatives.min)
  const adjustedMedian = formatCurrency(adjustedCompSuperlatives.median)
  const adjustedAvg = formatCurrency(adjustedCompSuperlatives.avg)
  const adjustedMax = formatCurrency(adjustedCompSuperlatives.max)
  const valueConclusion = formatCurrency(saleValueConclusion)
  const noAdjustments = isEmpty(adjustments)
  return {
    showAdjustments,
    adjustedMin,
    adjustedMax,
    adjustedMedian,
    adjustedAvg,
    valueConclusion,
    calculationUnits,
    noAdjustments,
  }
}

export const mapDTO = formValues => {
  return mapData(formValues)
}

export const mapDataModel = (report, parent) => {
  if (!parent.parent()) {
    return undefined
  }

  const salesApproach = parent.parent()

  const {
    salesCompsAdjustments = {},
    salesComps = {},
    unitOfComparison,
    valueConclusionInformation = {},
  } = salesApproach
  const { compAdjustments = [], incomeAdjustmentLevel, temper } = salesCompsAdjustments
  const { selectedComps = [] } = salesComps
  const {
    conclusionValue,
    asIsValueInformation = {},
    asCompleteValueInformation = {},
    asStabilizedValueInformation = {},
  } = valueConclusionInformation

  return mapData({
    saleValueConclusion: conclusionValue,
    incomeAdjustmentLevel,
    compAdjustments,
    unitOfComparison,
    salesComps: selectedComps,
    netOperatingIncome: get(report, 'netOperatingIncome', {}),
    temper,
    npvAdjustments: asIsValueInformation.npvAdjustments || [],
    asCompleteNpvAdjustments: asCompleteValueInformation.npvAdjustments || [],
    asStabilizedNpvAdjustments: asStabilizedValueInformation.npvAdjustments || [],
  })
}
