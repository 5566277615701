import React from 'react'
import PropTypes from 'prop-types'
import { Paper } from '@mui/material'

import PropertyDescription from '../../../../shared/components/PropertyDescription'
import { KITCHEN_CONDITIONS_DESCRIPTION } from '../../../constants/forms/propertyDescription'
import { GeneratedComment, CheckboxWithLabel } from '../../../../shared/components'

class TypicalResidentialKitchenConditions extends React.PureComponent {
  static propTypes = {
    tooltip: PropTypes.string,
    getGeneratedText: PropTypes.func.isRequired,
    automationMetadata: PropTypes.object,
    automatedInspectionPrefillFlag: PropTypes.bool,
  }

  render() {
    const { images, conditions, label } = KITCHEN_CONDITIONS_DESCRIPTION
    const { automationMetadata, automatedInspectionPrefillFlag } = this.props
    return (
      <Paper data-qa="typical-kitchen-conditions-section-tile">
        <PropertyDescription
          images={images}
          conditions={conditions}
          readOnlyImages
          automationMetadata={automationMetadata}
          automatedInspectionPrefillFlag={automatedInspectionPrefillFlag}
        >
          <CheckboxWithLabel name="kitchen.hasDishwasher" label="Units feature a dishwasher" />
          <GeneratedComment
            label="Kitchen Description Export"
            title={label}
            getGeneratedText={this.props.getGeneratedText}
            dataPath="kitchen.writeup"
            isDynamicContent
            tooltipText={this.props.tooltip}
          />
        </PropertyDescription>
      </Paper>
    )
  }
}

export default TypicalResidentialKitchenConditions
