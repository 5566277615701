export const calculateMiddleRanges = (maxValue, minValue) => {
  const interval = (maxValue - minValue) / 3
  const ranges = {
    lowerEnd: {
      min: minValue,
      max: minValue + interval,
    },
    middle: {
      min: minValue + interval,
      max: minValue + 2 * interval,
    },
    higherEnd: {
      min: minValue + 2 * interval,
      max: maxValue,
    },
  }
  return ranges
}

export const getSurveySuperlatives = surveys => {
  const superlatives = {}

  superlatives.min = Math.min(surveys.pwc?.min, surveys.rerc?.min) || 0
  superlatives.max = Math.max(surveys.pwc?.max, surveys.rerc?.max) || 0

  const middleRanges = calculateMiddleRanges(superlatives.max, superlatives.min)
  const superlativesDefault = { min: 0, max: 0 }

  superlatives.lowerEnd = middleRanges.lowerEnd || superlativesDefault
  superlatives.middle = middleRanges.middle || superlativesDefault
  superlatives.higherEnd = middleRanges.higherEnd || superlativesDefault

  return superlatives
}
