import React, { useCallback, useEffect, useState } from 'react'
import { get, find } from 'lodash'
import { connect } from 'react-redux'
import { Box, Grid } from '@mui/material'
import ObjectID from 'bson-objectid'

import PropTypes from 'prop-types'

import { Button } from 'client-shared/components/_mui5'
import { DropDown } from 'client-shared/components'

import useFieldValue from '../../../../utils/useFieldValue'
import SaveDialog from '../../../property/ComparableMaps/SaveDialog'
import { createTaxLiabilityConfiguration, fetchTaxLiabilityConfigurations } from '../../../../../core/api'

import { DEFAULT_OPTION_VALUE, DEFAULT_OPTION_LABEL } from '../constants'

import LoadTableConfirmDialog from './LoadTableConfirmDialog'
import { validateTaxConfigurationName } from './helpers'

const mapToTableRows = taxLiabilityRowSchema => {
  return taxLiabilityRowSchema.map(item => {
    const id = new ObjectID().toJSON()
    return { label: item.label, value: item.value, _id: id, id }
  })
}

const mapToTaxLiabilityRowSchema = tableRows => {
  return tableRows.map(row => ({
    label: row.label,
    value: row.value,
  }))
}

const TaxLiabilityConfiguration = ({ form, userId, reportId }) => {
  const { additionalSpecialAssessments, additionalTaxRates } = form.values

  const selectedConfigurationValue = useFieldValue('taxLiabilityConfiguration')

  const [allFetchedConfigurations, setAllFetchedConfigurations] = useState([])
  const [allConfigurationOptions, setAllConfigurationOptions] = useState([])
  const [showConfirmDialog, setShowConfirmDialog] = useState(false)
  const [showSaveDialog, setShowSaveDialog] = useState(false)

  const openSaveDialog = () => setShowSaveDialog(true)
  const closeSaveDialog = () => setShowSaveDialog(false)

  const loadTaxConfiguration = ({ confirm }) => {
    if ((additionalTaxRates.length || additionalSpecialAssessments.length) && !confirm) {
      setShowConfirmDialog(true)
      return
    }

    let updatedTaxRates = []
    let updatedSpecialAssessments = []
    let updatedTaxClass
    let taxRate

    const selectedConfiguration =
      find(allFetchedConfigurations, { _id: selectedConfigurationValue }) || DEFAULT_OPTION_VALUE
    const { jurisdictionTaxClasses } = form.values

    if (selectedConfiguration !== DEFAULT_OPTION_VALUE) {
      updatedTaxRates = mapToTableRows(selectedConfiguration.additionalTaxRates)
      updatedSpecialAssessments = mapToTableRows(selectedConfiguration.additionalSpecialAssessments)

      updatedTaxClass = find(jurisdictionTaxClasses, ['lookupValue', selectedConfiguration.taxClassLookupValue])
      taxRate = find(updatedTaxClass.rateHistory, ['effectiveDate', selectedConfiguration.taxClassRateYear])
    } else {
      updatedTaxClass = jurisdictionTaxClasses[0]
      taxRate = updatedTaxClass.rateHistory[0]
    }

    if (!taxRate) {
      taxRate = updatedTaxClass.rateHistory[0]
    }

    const { change, batch } = form
    batch(() => {
      change('additionalTaxRates', updatedTaxRates)
      change('additionalSpecialAssessments', updatedSpecialAssessments)
      change('taxClass', updatedTaxClass)
      change('taxRate', taxRate)
    })
  }

  const refreshTaxLiabilityConfigurationOptions = useCallback(async () => {
    const results = await fetchTaxLiabilityConfigurations(userId)
    setAllFetchedConfigurations(results)

    const userConfigurationOptions = results.map(result => {
      return {
        label: result.name,
        value: result._id,
      }
    })

    const defaultConfigurationOption = [{ label: DEFAULT_OPTION_LABEL, value: DEFAULT_OPTION_VALUE }]
    const refreshedConfigurationOptions = [...defaultConfigurationOption, ...userConfigurationOptions]

    setAllConfigurationOptions(refreshedConfigurationOptions)
  }, [userId])

  const createAndRefreshOptions = async ({ label }) => {
    closeSaveDialog()

    const { taxClass, taxRate } = form.values

    const newConfiguration = await createTaxLiabilityConfiguration({
      name: label,
      additionalSpecialAssessments: mapToTaxLiabilityRowSchema(additionalSpecialAssessments),
      additionalTaxRates: mapToTaxLiabilityRowSchema(additionalTaxRates),
      taxClassLookupValue: taxClass.lookupValue,
      taxClassRateYear: taxRate.effectiveDate,
      userId,
      reportId,
    })

    refreshTaxLiabilityConfigurationOptions()

    const { change } = form
    change('taxLiabilityConfiguration', newConfiguration._id)
  }

  useEffect(() => {
    refreshTaxLiabilityConfigurationOptions()
  }, [refreshTaxLiabilityConfigurationOptions])

  const validate = validateTaxConfigurationName(allConfigurationOptions)

  function handleConfirmLoad(confirm) {
    setShowConfirmDialog(false)

    if (confirm) {
      loadTaxConfiguration({ confirm: true })
    }
  }

  return (
    <Grid container spacing={2}>
      <Grid item xs={8}>
        <DropDown
          size="small"
          name="taxLiabilityConfiguration"
          items={allConfigurationOptions}
          defaultValue={DEFAULT_OPTION_VALUE}
        />
      </Grid>
      <Grid item xs={4}>
        <Box display="flex" justifyContent="center" alignItems="center" sx={{ paddingTop: '14px' }}>
          <Button onClick={loadTaxConfiguration}>Load</Button>
          &nbsp;&nbsp;
          <Button onClick={openSaveDialog}>Save</Button>
          <SaveDialog
            open={showSaveDialog}
            placeholder="Enter a table name"
            title="Save Tax Liability"
            label="Table Name"
            description="Create a unique name for the table. Name the table in a way that is easily found by you and others."
            saveButtonText="Save Table"
            onSave={createAndRefreshOptions}
            onCancel={closeSaveDialog}
            validate={validate}
          />
          <LoadTableConfirmDialog open={showConfirmDialog} onConfirm={handleConfirmLoad} />
        </Box>
      </Grid>
    </Grid>
  )
}

TaxLiabilityConfiguration.propTypes = {
  form: PropTypes.object.isRequired,
  reportId: PropTypes.string.isRequired,
  userId: PropTypes.string.isRequired,
}

const mapStateToProps = state => {
  const authentication = get(state, 'authentication')
  const user = get(authentication, 'user', {})

  return { userId: user.id, reportId: get(state, 'report.reportData._id') }
}

export default connect(mapStateToProps)(TaxLiabilityConfiguration)
