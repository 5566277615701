import { LOCATION_CHANGE } from 'react-router-redux'

import { REPORT_FETCH } from '../report/redux/actionTypes'
import { CREATE_REPORT, DUPLICATE_REPORT } from '../reportsDashboard/redux/actionTypes'
import { buildingCompRentRollUploadCsvReceive } from '../etc/buildingComparables/redux/actions'

import {
  detectClickBackButtonEvent,
  registerCreateReportEvent,
  registerOpenReportEvent,
  registerUploadBuildingCompRentRollCSVEvent,
} from './events/old'

export default {
  [LOCATION_CHANGE]: detectClickBackButtonEvent,
  [CREATE_REPORT.RECEIVE]: registerCreateReportEvent,
  [DUPLICATE_REPORT.RECEIVE]: registerCreateReportEvent,
  [REPORT_FETCH.RECEIVE]: registerOpenReportEvent,
  [buildingCompRentRollUploadCsvReceive.toString()]: registerUploadBuildingCompRentRollCSVEvent,
}
