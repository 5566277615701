import React from 'react'
import PropTypes from 'prop-types'
import { max, min, isNil } from 'lodash'
import { Grid, Typography } from '@mui/material'
import { red, blue, green } from '@mui/material/colors'

import ValuesLine from '../../../../shared/components/ValuesLine'

import { getExpensePeriodLabel } from './ForecastItem'

class ForecastItemLines extends React.PureComponent {
  static propTypes = {
    historicalExpenseYear: PropTypes.number,
    mostRecentHistorical: PropTypes.number,
    compMin: PropTypes.number,
    compAvg: PropTypes.number,
    compMax: PropTypes.number,
    basisValue: PropTypes.number,
    formatter: PropTypes.func.isRequired,
    expenseDataSource: PropTypes.string,
  }

  getItem = (value, colorBase, lowerTip) => {
    const item = {
      value,
      style: {
        backgroundColor: colorBase[300],
        borderColor: colorBase[700],
      },
      tooltip: {
        formatter: this.props.formatter,
        style: {
          backgroundColor: colorBase[50],
          borderColor: colorBase[300],
        },
      },
    }

    if (lowerTip) {
      item.lowerTip = {
        formatter: () => lowerTip,
        style: {
          backgroundColor: colorBase[50],
          borderColor: colorBase[300],
        },
      }
    }

    return item
  }

  getCompRangeItems = () => {
    const { compMin, compAvg, compMax } = this.props
    const compItems = []
    if (compMin !== compAvg && !isNil(compAvg)) {
      compItems.push(this.getItem(compMin, red, 'Min'))
    }
    if (compAvg) {
      compItems.push(this.getItem(compAvg, red, 'Avg'))
    }
    compItems.push(this.getItem(compMax, red, 'Max'))
    return compItems
  }

  render() {
    const { recentHistoricalExpenses, compMin, compAvg, compMax, basisValue, expenseDataSource } = this.props

    const allValues = [compMin, compAvg, compMax, basisValue].concat(
      recentHistoricalExpenses.map(historicalExpense => historicalExpense.expense)
    )

    const minValue = min(allValues)
    const maxValue = max(allValues)
    const orderedRecentHistoricalExpenses = recentHistoricalExpenses.sort(
      (expenseA, expenseB) => expenseA.year - expenseB.year
    )

    return (
      <Grid container direction="column" wrap="nowrap" spacing={3}>
        {!!orderedRecentHistoricalExpenses.length &&
          orderedRecentHistoricalExpenses.map((historicalExpense, index) => {
            return historicalExpense.expense ? (
              <Grid key={index} item container alignItems="center">
                <Grid item xs={3}>
                  <Typography>
                    {getExpensePeriodLabel(historicalExpense.year, historicalExpense.period, expenseDataSource)}
                  </Typography>
                </Grid>
                <Grid item xs={9} data-qa={`${historicalExpense.year}-historical-values-line`}>
                  <ValuesLine min={minValue} max={maxValue} items={[this.getItem(historicalExpense.expense, blue)]} />
                </Grid>
              </Grid>
            ) : null
          })}
        <Grid item container alignItems="center">
          <Grid item xs={3}>
            <Typography>Comparable Range</Typography>
          </Grid>
          <Grid item xs={9} data-qa="comparable-range-values-line">
            <ValuesLine min={minValue} max={maxValue} items={this.getCompRangeItems()} />
          </Grid>
        </Grid>

        <Grid item container alignItems="center">
          <Grid item xs={3}>
            <Typography>Appraiser's Forecast</Typography>
          </Grid>
          <Grid item xs={9} data-qa="appraisers-forecast-values-line">
            <ValuesLine min={minValue} max={maxValue} items={[this.getItem(basisValue, green)]} />
          </Grid>
        </Grid>
      </Grid>
    )
  }
}

export default ForecastItemLines
