export const LeaseStatusTypes = {
  OCCUPIED: 'Occupied',
  VACANT: 'Vacant',
  EMPLOYEE: 'Employee',
}

export const OCCUPIED_UNIT_TYPES = [LeaseStatusTypes.OCCUPIED, LeaseStatusTypes.EMPLOYEE]

export const MarketConclusionUnits = {
  SF: 'SF',
  UNIT: 'Unit',
}
