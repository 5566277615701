import React from 'react'
import { ReactSVG } from 'react-svg'

import BikeRoom from '../../../shared/images/bike_room_rounded.svg'
import BuildingManagement from '../../../shared/images/building_management_rounded.svg'
import CoveredParking from '../../../shared/images/covered_parking_rounded.svg'
import Doorman from '../../../shared/images/doorman_rounded.svg'
import Garden from '../../../shared/images/garden_rounded.svg'
import Gym from '../../../shared/images/gym_rounded.svg'
import Laundry from '../../../shared/images/laundry_rounded.svg'
import Lounge from '../../../shared/images/lounge_rounded.svg'
import OutdoorSpace from '../../../shared/images/outdoor_space_rounded.svg'
import Parking from '../../../shared/images/parking_rounded.svg'
import Pool from '../../../shared/images/pool_rounded.svg'
import RecRoom from '../../../shared/images/rec_room_rounded.svg'
import StorageRoom from '../../../shared/images/storage_room_rounded.svg'
import Superintendent from '../../../shared/images/superintendent_rounded.svg'
import TennisCourt from '../../../shared/images/tennis_court_rounded.svg'

export const AMENITY_OPTIONS = [
  {
    label: 'Fitness Center',
    value: 'fitnessCenter',
    icon: <ReactSVG src={Gym} />,
  },
  {
    label: 'Laundry Room',
    value: 'laundryRoom',
    icon: <ReactSVG src={Laundry} />,
  },
  {
    label: 'Washer/Dryer in Units',
    value: 'washerAndDryerInUnits',
    icon: null,
  },
  {
    label: 'Bike Room',
    value: 'bikeRoom',
    icon: <ReactSVG src={BikeRoom} />,
  },
  {
    label: 'Doorman/Concierge',
    value: 'doorman',
    icon: <ReactSVG src={Doorman} />,
  },
  {
    label: 'Storage Units',
    value: 'storageUnits',
    icon: <ReactSVG src={StorageRoom} />,
  },
  {
    label: 'Covered Parking',
    value: 'coveredParking',
    icon: <ReactSVG src={CoveredParking} />,
  },
  {
    label: 'Uncovered Parking',
    value: 'uncoveredParking',
    icon: <ReactSVG src={Parking} />,
  },
  {
    label: 'Pool',
    value: 'pool',
    icon: <ReactSVG src={Pool} />,
  },
  {
    label: 'Shared Recreation Room',
    value: 'sharedRecreationRoom',
    icon: <ReactSVG src={RecRoom} />,
  },
  {
    label: 'Playground',
    value: 'playground',
    icon: null,
  },
  {
    label: 'Tennis Courts',
    value: 'tennisCourts',
    icon: <ReactSVG src={TennisCourt} />,
  },
  {
    label: 'Shared Garden',
    value: 'sharedGarden',
    icon: <ReactSVG src={Garden} />,
  },
  {
    label: 'Shared Roof Deck',
    value: 'sharedRoofDeck',
    icon: null,
  },
  {
    label: 'Shared Common Outdoor Space',
    value: 'sharedCommonOutdoorSpace',
    icon: <ReactSVG src={OutdoorSpace} />,
  },
  {
    label: 'On Site Superintendent',
    value: 'onSiteSuperintendent',
    icon: <ReactSVG src={Superintendent} />,
  },
  {
    label: 'On Site Building Management',
    value: 'onSiteBuildingManagement',
    icon: <ReactSVG src={BuildingManagement} />,
  },
  {
    label: 'Common Lounge Space',
    value: 'commonLoungeSpace',
    icon: <ReactSVG src={Lounge} />,
  },
]
