export const UNCATEGORIZED_UNIT_COMP_GROUP_KEY = {
  name: 'Uncategorized',
  key: 'uncategorized',
}

export const MARKET_BREAKDOWN_TYPES = {
  allMarket: 'all market oriented',
  allBelow: 'all below market',
  allAbove: 'all above market',
  mixMarketAndBelow: 'mix of market and below market',
  mixAll: 'mix of below market, market and above market',
  mixMarketAndAbove: 'mix of market and above market',
}

export const getBedroomLabel = bedrooms => {
  if (bedrooms < 0) {
    return null
  }

  return bedrooms === 0 ? 'Studio' : `${bedrooms} BR`
}

export const getUnitLayoutLabel = unitLayout => unitLayout || ''

export const getOutdoorSpaceLabel = outdoorSpace => {
  if (!outdoorSpace || outdoorSpace === 'None') {
    return 'without Outdoor Space'
  }

  return `with ${outdoorSpace}`
}

export const getBathroomLabel = bathrooms => {
  return `/ ${bathrooms} BA`
}

export const SUBJECT_UNIT_GROUPING_TYPES = {
  bedroom: [{ path: 'bedrooms', label: getBedroomLabel }],
  bedroomAndUnitLayout: [
    { path: 'bedrooms', label: getBedroomLabel },
    { path: 'unitLayout', label: getUnitLayoutLabel },
  ],
  bedroomAndOutdoorSpace: [
    { path: 'bedrooms', label: getBedroomLabel },
    { path: 'outdoor', label: getOutdoorSpaceLabel },
  ],
  bedroomAndBathroom: [{ path: 'bedrooms', label: getBedroomLabel }, { path: 'bathrooms', label: getBathroomLabel }],
}

export const SUBJECT_UNIT_GROUPING_LIST_OPTIONS = {
  bedroom: { value: 'bedroom', label: 'None' },
  bedroomAndUnitLayout: { value: 'bedroomAndUnitLayout', label: 'Unit Type' },
  bedroomAndOutdoorSpace: { value: 'bedroomAndOutdoorSpace', label: 'Outdoor Space' },
  bedroomAndBathroom: { value: 'bedroomAndBathroom', label: 'Bathroom Count' },
}

export const SQUARE_FOOTAGE_FIELD = {
  grossBuildingArea: 'grossBuildingArea',
  squareFootageForCalculations: 'squareFootageForCalculations',
}

export const CONCLUDED_LIABILITY_TYPES = {
  PSF: 'PSF',
  TOTAL: 'Total',
}

export const BEDROOM_TYPES = [
  { label: 'Studio', value: 0 },
  { label: '1 Bedroom', value: 1 },
  { label: '2 Bedroom', value: 2 },
  { label: '3 Bedroom', value: 3 },
  { label: '4 Bedroom', value: 4 },
  { label: '5 Bedroom', value: 5 },
  { label: '6 Bedroom', value: 6 },
  { label: '7 Bedroom', value: 7 },
  { label: '8 Bedroom', value: 8 },
  { label: '9 Bedroom', value: 9 },
]
