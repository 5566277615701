import createDecorator from 'final-form-calculate'
import { get } from 'lodash'

import { getRowDefinitions } from 'shared/constants/costApproach'

export const updateTotalCosts = (value, name, allValues) => {
  let directCosts = get(allValues, getRowDefinitions('constructionBudget').directCosts.fieldPath, 0)
  let directCostsAdjustment = get(allValues, getRowDefinitions('constructionBudget').directCostsAdjustment.fieldPath, 0)
  let siteImprovementCosts = get(allValues, getRowDefinitions('constructionBudget').siteImprovementCosts.fieldPath, 0)
  let demolitionCosts = get(allValues, getRowDefinitions('constructionBudget').demolitionCosts.fieldPath, 0)
  let indirectCosts = get(allValues, getRowDefinitions('constructionBudget').indirectCosts.fieldPath, 0)
  let financingCosts = get(allValues, getRowDefinitions('constructionBudget').financingCosts.fieldPath, 0)

  directCosts -= get(allValues, getRowDefinitions('costsToDate').directCosts.fieldPath, 0)
  directCostsAdjustment -= get(allValues, getRowDefinitions('costsToDate').directCostsAdjustment.fieldPath, 0)
  siteImprovementCosts -= get(allValues, getRowDefinitions('costsToDate').siteImprovementCosts.fieldPath, 0)
  demolitionCosts -= get(allValues, getRowDefinitions('costsToDate').demolitionCosts.fieldPath, 0)
  indirectCosts -= get(allValues, getRowDefinitions('costsToDate').indirectCosts.fieldPath, 0)
  financingCosts -= get(allValues, getRowDefinitions('costsToDate').financingCosts.fieldPath, 0)

  const totalCosts =
    directCosts + directCostsAdjustment + siteImprovementCosts + demolitionCosts + indirectCosts + financingCosts
  return {
    'subjectBudget.costsToComplete.directCosts': directCosts,
    'subjectBudget.costsToComplete.directCostsAdjustment': directCostsAdjustment,
    'subjectBudget.costsToComplete.siteImprovementCosts': siteImprovementCosts,
    'subjectBudget.costsToComplete.demolitionCosts': demolitionCosts,
    'subjectBudget.costsToComplete.indirectCosts': indirectCosts,
    'subjectBudget.costsToComplete.financingCosts': financingCosts,
    'subjectBudget.costsToComplete.totalCosts': totalCosts,
  }
}

export const updateTotalCostsCategory = (key, value, name, allValues) => {
  const directCosts = get(allValues, getRowDefinitions(key).directCosts.fieldPath, 0)
  const directCostsAdjustment = get(allValues, getRowDefinitions(key).directCostsAdjustment.fieldPath, 0)
  const siteImprovementCosts = get(allValues, getRowDefinitions(key).siteImprovementCosts.fieldPath, 0)
  const demolitionCosts = get(allValues, getRowDefinitions(key).demolitionCosts.fieldPath, 0)
  const indirectCosts = get(allValues, getRowDefinitions(key).indirectCosts.fieldPath, 0)
  const financingCosts = get(allValues, getRowDefinitions(key).financingCosts.fieldPath, 0)

  const totalCosts =
    directCosts + directCostsAdjustment + siteImprovementCosts + demolitionCosts + indirectCosts + financingCosts
  const totalCostsField = `subjectBudget.${key}.totalCosts`
  return {
    [totalCostsField]: totalCosts,
  }
}

const createSubjectBudgetDecorators = key => {
  return [
    createDecorator({
      field: 'subjectBudget.' + key,
      updates: (value, name, allValues) => {
        return updateTotalCostsCategory(key, value, name, allValues)
      },
    }),
    createDecorator({
      field: 'subjectBudget.' + key,
      updates: updateTotalCosts,
    }),
  ]
}

const subjectBudgetTotals = [
  ...createSubjectBudgetDecorators('constructionBudget'),
  ...createSubjectBudgetDecorators('costsToDate'),
]

export default [...subjectBudgetTotals]
