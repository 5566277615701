import React, { FC } from 'react'
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Stack,
  Typography,
} from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'
import { Form } from 'react-final-form'
import { required } from 'client-shared/utils/validation'
import { Text } from 'client-shared/components'

type Props = {
  opened?: boolean
  initialData?: Partial<FormData>
  onSave: (title: string) => void
  onClose: () => void
}

type FormData = {
  title: string
}

type Labels = {
  title: string
  body: string
  actionButton: string
}

const createLabels: Labels = {
  title: 'Save as New Template',
  body: 'Create a unique name for the template. Name the template in a way that is easily found by you and others.',
  actionButton: 'Save',
}

const editLabels: Labels = {
  title: 'Edit Content Title',
  body: 'Edit the title for this content block. Name the block in a way that is easily found by you and others.',
  actionButton: 'Update Title',
}

export const CreateNarrativeModal: FC<Props> = ({ opened = false, initialData, onSave, onClose }) => {
  const labels = initialData ? editLabels : createLabels

  const handleSubmit = (data: FormData) => {
    try {
      onClose()
      onSave(data.title)
    } catch (error) {
      console.error('Error creating a narrative template', error)
    }
  }

  return (
    <Dialog open={opened} onClose={onSave} fullWidth maxWidth="sm" data-qa="create-narrative-form">
      <Stack direction="column" justifyContent="flex-end" p={2}>
        <DialogTitle style={{ padding: '0 32px 16px 0' }}>
          {labels.title}
          <IconButton
            color="inherit"
            onClick={onClose}
            aria-label="close"
            style={{ position: 'absolute', top: 8, right: 8 }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <Form
          onSubmit={handleSubmit}
          initialValues={initialData}
          render={({ values, handleSubmit, invalid }) => {
            return (
              <>
                <DialogContent dividers>
                  <Stack direction="column" spacing={1}>
                    <Typography variant="body1">{labels.body}</Typography>
                    <Text name="title" label="Enter a template name" shrink={null} validate={required} />
                  </Stack>
                </DialogContent>
                <Box
                  sx={{
                    position: 'sticky',
                    bottom: 0,
                    zIndex: 1000,
                    backgroundColor: 'white',
                    borderTop: '1px solid rgba(0, 0, 0, 0.12)',
                  }}
                >
                  <DialogActions>
                    <Box sx={{ marginLeft: 'auto' }}>
                      <Button variant="outlined" onClick={onClose}>
                        Cancel
                      </Button>
                      <Button
                        variant="contained"
                        color="primary"
                        type="submit"
                        onClick={handleSubmit}
                        disabled={invalid}
                        style={{ marginLeft: '8px' }}
                        data-qa="create-narrative-submit"
                      >
                        {labels.actionButton}
                      </Button>
                    </Box>
                  </DialogActions>
                </Box>
              </>
            )
          }}
        />
      </Stack>
    </Dialog>
  )
}
