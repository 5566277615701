import React from 'react'
import { withStyles } from '@mui/styles'

const styles = () => ({
  readOnly: {
    opacity: 0.7,
    pointerEvents: 'none',
  },
})

const LockedSentence = ({ classes, children, attributes }) => {
  const { ...restAttributes } = attributes
  return (
    <span {...restAttributes} contentEditable={false} className={classes.readOnly}>
      {children}
    </span>
  )
}

export default withStyles(styles)(LockedSentence)
