import React from 'react'
import PropTypes from 'prop-types'

import { Grid, Typography } from '@mui/material'
import { connect } from 'react-redux'
import { get } from 'lodash'

import { AMENITY_OPTIONS } from '../../../constants/markup'
import { BUILDING_LABEL_VALUE_PAIR, PROPERTY_CONDITION_LABEL, PropertiesConditionKeys } from '../../../constants'
import { LabelValueList } from '../../../components/LabelValueList'

import EditLayout from './EditLayout'

class PropertyConditions extends React.PureComponent {
  renderListValue = ({ label, key }) => {
    if (label === PROPERTY_CONDITION_LABEL.AVAILABLE_AMENITIES) {
      return this.renderAmenities()
    }
    return this.renderCondition(key)
  }
  renderCondition = key => {
    const { conditions } = this.props
    return <Typography type="body2">{conditions[key]}</Typography>
  }
  renderAmenities = () => {
    const { amenities = [] } = this.props
    return amenities.map((amenity, index) => {
      const { label } = AMENITY_OPTIONS.find(({ value }) => amenity === value) || {}
      return (
        <Typography key={index} type="body2">
          {label}
        </Typography>
      )
    })
  }
  render() {
    return (
      <EditLayout
        title={BUILDING_LABEL_VALUE_PAIR.PropertyConditions.heading}
        redirectTo={BUILDING_LABEL_VALUE_PAIR.PropertyConditions.menuItem}
      >
        <Grid item>
          <LabelValueList labelKeysPairs={PropertiesConditionKeys} renderListValue={this.renderListValue} />
        </Grid>
      </EditLayout>
    )
  }
}

PropertyConditions.propTypes = {
  conditions: PropTypes.object.isRequired,
  amenities: PropTypes.array.isRequired,
}
PropertyConditions.defaultProps = {
  conditions: {},
  amenities: [],
}

export default connect(state => {
  const propertyConditions = get(state, 'buildingComp.propertyConditions')
  return {
    ...propertyConditions,
  }
})(PropertyConditions)
