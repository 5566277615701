import React from 'react'
import PropTypes from 'prop-types'

import { Card, CardMedia, Grid, Typography } from '@mui/material'
import { connect } from 'react-redux'
import { get, isNil } from 'lodash'

import BoweryDate from '@bowery-valuation/bowery-date'
import { MONTH_DAY_YEAR_DATE_FORMAT } from 'client-shared/constants'
import { formatFloat } from 'client-shared/utils/numberFormatters'
import { getPropertySubtype } from 'client-shared/utils/propertyHelper'

import { BUILDING_LABEL_VALUE_PAIR, PROPERTY_SUMMARY_KEYS, PROPERTY_SUMMARY_LABEL } from '../../../constants'
import { LabelValueList } from '../../../components/LabelValueList'
import noImage from '../../../images/no_image.png'

import EditLayout from './EditLayout'

class PropertySummary extends React.PureComponent {
  renderListValue = ({ label, key }) => {
    const { propertySummary } = this.props
    const value = get(propertySummary, key)
    let formattedValue = null
    switch (label) {
      case PROPERTY_SUMMARY_LABEL.DISTANCE_FROM_SUBJECT:
        formattedValue = this.formatValue(formatFloat(value), 'mi')
        break
      case PROPERTY_SUMMARY_LABEL.GROSS_BUILDING_AREA:
        formattedValue = this.formatValue(value, 'SF')
        break
      case PROPERTY_SUMMARY_LABEL.OCCUPANCY_LEVEL:
        formattedValue = this.formatValue(value, '%')
        break
      case PROPERTY_SUMMARY_LABEL.PROPERTY_HAS_ELEVATOR:
        formattedValue = value ? 'Yes' : 'No'
        break
      case PROPERTY_SUMMARY_LABEL.DATE_AS_OF:
        formattedValue = value ? new BoweryDate(value).formatShortDateWithZeroes() : MONTH_DAY_YEAR_DATE_FORMAT
        break
      case PROPERTY_SUMMARY_LABEL.PROPERTY_SUB_TYPE:
        const isGardenStyle = get(propertySummary, 'isGardenStyle')
        const floors = get(propertySummary, 'floors')
        formattedValue = getPropertySubtype(floors, isGardenStyle)
        break
      default:
        formattedValue = value
    }
    return this.renderValue(formattedValue)
  }
  formatValue = (value, postfix) => {
    return !isNil(value) ? `${value} ${postfix}` : ''
  }
  renderValue = value => {
    return <Typography variant="body2">{value}</Typography>
  }
  render() {
    const { backgroundImage, propertySummary } = this.props
    const imageUrl = get(propertySummary, 'image.cdnUrl') || backgroundImage

    return (
      <EditLayout
        redirectTo={BUILDING_LABEL_VALUE_PAIR.PropertySummary.menuItem}
        title={BUILDING_LABEL_VALUE_PAIR.PropertySummary.heading}
      >
        <Grid container spacing={2}>
          <Grid item xs={4}>
            <Card>
              <CardMedia sx={{ minHeight: 256 }} image={imageUrl} />
            </Card>
          </Grid>

          <Grid item xs={8}>
            <LabelValueList
              labelKeysPairs={PROPERTY_SUMMARY_KEYS}
              renderListValue={this.renderListValue}
              rowsPerColumn={10}
            />
          </Grid>
        </Grid>
      </EditLayout>
    )
  }
}

PropertySummary.propTypes = {
  propertySummary: PropTypes.object,
}
PropertySummary.defaultProps = {
  backgroundImage: noImage,
  propertySummary: {},
}

export default connect(state => ({
  propertySummary: get(state, 'buildingComp.propertySummary'),
}))(PropertySummary)
