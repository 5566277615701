export const CompModalModes = {
  EDIT: 'edit',
  REVIEW: 'review',
}

export const ColumnHeaderLinkLabel = {
  [CompModalModes.EDIT]: 'View Details',
  [CompModalModes.REVIEW]: 'Review Comp',
}

export const BASIS_MAP = {
  Gross: 'value',
  'Per Unit': 'unit',
  'Per Square Foot': 'sf',
}
