export default {
  baseMap: 'https://{s}.basemaps.cartocdn.com/light_all/{z}/{x}/{y}{r}.png',
  zoning: {
    maxZoom: 18,
    initialZoom: 18,
    mapLayers: [
      {
        name: 'qnmappluto',
        style: 'nyc.borough',
        isCustom: false,
      },
      {
        name: 'bxmappluto',
        style: 'nyc.borough',
        isCustom: false,
      },
      {
        name: 'bkmappluto',
        style: 'nyc.borough',
        isCustom: false,
      },
      {
        name: 'mnmappluto',
        style: 'nyc.borough',
        isCustom: false,
      },
      {
        name: 'simappluto',
        style: 'nyc.borough',
        isCustom: false,
      },
      {
        name: 'nyzd_2017',
        style: 'ny.zone',
        isCustom: false,
      },
      {
        name: 'nyco_pluto_2017',
        style: 'ny.co',
        isCustom: false,
      },
    ],
    legendItems: [
      { name: 'C1-1', value: '#A6CEE3' },
      { name: 'C1-2', value: '#1F78B4' },
      { name: 'C1-3', value: '#B2DF8A' },
      { name: 'C1-4', value: '#33A02C' },
      { name: 'C1-5', value: '#FB9A99' },
      { name: 'C2-1', value: '#E31A1C' },
      { name: 'C2-2', value: '#FDBF6F' },
      { name: 'C2-3', value: '#FF7F00' },
      { name: 'C2-4', value: '#CAB2D6' },
      { name: 'C2-5', value: '#6A3D9A' },
    ],
  },
  flood: {
    maxZoom: 18,
    initialZoom: 15,
    legendItems: [
      { name: '0.2 PCT ANNUAL CHANCE', value: '#FF9900' },
      { name: 'A', value: '#F84F40' },
      { name: 'AE', value: '#850200' },
      { name: 'AO', value: '#33A02C' },
      { name: 'AH', value: '#00E6FF' },
      { name: 'VE', value: '#E31A1C' },
      { name: 'X', value: '#FFFFFF' },
      { name: 'D', value: '#F2E673' },
    ],
    mapLayers: [
      {
        name: 's_fld_haz_ar',
        style: 'national.flood',
        isCustom: false,
      },
      {
        name: 'ocean_county_fld_haz_ar',
        style: 'national.flood',
        isCustom: false,
      },
      {
        name: 'atlantic_county_fld_haz_ar',
        style: 'national.flood',
        isCustom: false,
      },
      {
        name: 'burlington_county_fld_haz_ar',
        style: 'national.flood',
        isCustom: false,
      },
      {
        name: 'camden_county_fld_haz_ar',
        style: 'national.flood',
        isCustom: false,
      },
      {
        name: 'cape_may_county_fld_haz_ar',
        style: 'national.flood',
        isCustom: false,
      },
      {
        name: 'cumberland_county_fld_haz_ar',
        style: 'national.flood',
        isCustom: false,
      },
      {
        name: 'gloucester_county_fld_haz_ar',
        style: 'national.flood',
        isCustom: false,
      },
      {
        name: 'salem_county_fld_haz_ar',
        style: 'national.flood',
        isCustom: false,
      },
      {
        name: 'hunterdon_county_fld_haz_ar',
        style: 'national.flood',
        isCustom: false,
      },
      {
        name: 'mercer_county_fld_haz_ar',
        style: 'national.flood',
        isCustom: false,
      },
      {
        name: 'middlesex_county_fld_haz_ar',
        style: 'national.flood',
        isCustom: false,
      },
      {
        name: 'monmouth_county_fld_haz_ar',
        style: 'national.flood',
        isCustom: false,
      },
      {
        name: 'somerset_county_fld_haz_ar',
        style: 'national.flood',
        isCustom: false,
      },
      {
        name: 'bergen_county_fld_haz_ar',
        style: 'national.flood',
        isCustom: false,
      },
      {
        name: 'essex_county_fld_haz_ar',
        style: 'national.flood',
        isCustom: false,
      },
      {
        name: 'hudson_county_fld_haz_ar',
        style: 'national.flood',
        isCustom: false,
      },
      {
        name: 'passaic_county_fld_haz_ar',
        style: 'national.flood',
        isCustom: false,
      },
      {
        name: 'union_county_fld_haz_ar',
        style: 'national.flood',
        isCustom: false,
      },
      {
        name: 'warren_county_fld_haz_ar',
        style: 'national.flood',
        isCustom: false,
      },
      {
        name: 'sussex_county_fld_haz_ar',
        style: 'national.flood',
        isCustom: false,
      },
    ],
    viz_json: 'https://nbisaacs.cartodb.com/api/v2/viz/1c612ed0-c9eb-11e5-af71-0e674067d321/viz.json',
    sublayers: {
      mnmappluto: {
        getSql: block => `SELECT * FROM mnmappluto where block = ${block} and block <> ${block}`,
      },
    },
  },
  tax: {
    maxZoom: 25,
    initialZoom: 19,
    mapLayers: [
      {
        name: 'dtm_01',
        style: 'dtm_01',
        isCustom: false,
      },
      {
        name: 'mnmappluto',
        style: 'nyc.mn.tax',
        isCustom: false,
      },
      {
        name: 'dtm_02',
        style: 'dtm_02',
        isCustom: false,
      },
      {
        name: 'bxmappluto',
        style: 'nyc.bx.tax',
        isCustom: false,
      },
      {
        name: 'dtm_04',
        style: 'dtm_04',
        isCustom: false,
      },
      {
        name: 'qnmappluto',
        style: 'nyc.qn.tax',
        isCustom: false,
      },
      {
        name: 'bkmappluto',
        style: 'nyc.bk.tax',
        isCustom: false,
      },
      {
        name: 'dtm_03',
        style: 'dtm_03',
        isCustom: false,
      },
      {
        name: 'simappluto',
        style: 'nyc.si.tax',
        isCustom: false,
      },
      {
        name: 'dtm_05',
        style: 'dtm_05',
        isCustom: false,
      },
    ],
  },
  subject: {
    maxZoom: 18,
    initialZoom: 17,
  },
  subjectAndComps: {
    width: 640,
    height: 400,
    defaultLabelOffset: 0.00095,
    maxZoom: 18,
    initialZoom: 12,
    initialCoordinates: {
      lat: 40.7535427,
      lng: -73.99229389999999,
    },
  },
  sales_comparables: {
    viz_json: 'https://ratomskiysasha.cartodb.com/api/v2/viz/ea321a1e-20fc-11e6-912f-0ecd1babdde5/viz.json',
    initial_zoom: 17,
    base_map: 'https://{s}.basemaps.cartocdn.com/light_all/{z}/{x}/{y}{r}.png',
    sublayers: {
      comparables: {
        getSql: comparables => {
          let whereStatement = ''
          if (comparables.length > 0) {
            whereStatement = ' where'
            let comparableIndex = 0
            comparables.forEach(comparable => {
              if (comparable.block !== undefined && comparable.lot !== undefined) {
                if (comparableIndex !== 0) {
                  whereStatement += ' or '
                }
              }
              whereStatement += ` (block = ${comparable.block} and lot = ${comparable.lot})`
              comparableIndex += 1
            })
          }
          return `SELECT * FROM mnmappluto${whereStatement}`
        },
      },
    },
  },
  rent_comparables: {
    viz_json: 'https://ratomskiysasha.cartodb.com/api/v2/viz/ea321a1e-20fc-11e6-912f-0ecd1babdde5/viz.json',
    initial_zoom: 17,
    base_map: 'https://{s}.basemaps.cartocdn.com/light_all/{z}/{x}/{y}{r}.png',
    sublayers: {
      comparables: {
        getSql: comparables => {
          let whereStatement = ''
          if (comparables.length > 0) {
            whereStatement = ' where'
            let comparableIndex = 0
            comparables.forEach(comparable => {
              if (comparable.block !== undefined && comparable.lot !== undefined) {
                if (comparableIndex !== 0) {
                  whereStatement += ' or '
                }
              }
              whereStatement += ` (block = ${comparable.block} and lot = ${comparable.lot})`
              comparableIndex += 1
            })
          }

          return `SELECT * FROM mnmappluto${whereStatement}`
        },
      },
    },
  },
  commercial_rent_comparables: {
    viz_json: 'https://ratomskiysasha.cartodb.com/api/v2/viz/ea321a1e-20fc-11e6-912f-0ecd1babdde5/viz.json',
    initial_zoom: 17,
    base_map: 'https://{s}.basemaps.cartocdn.com/light_all/{z}/{x}/{y}{r}.png',
    sublayers: {
      comparables: {
        getSql: comparables => {
          let whereStatement = ''
          if (comparables.length > 0) {
            whereStatement = ' where'
            let comparableIndex = 0
            comparables.forEach(comparable => {
              if (comparable.block !== undefined && comparable.lot !== undefined) {
                if (comparableIndex !== 0) {
                  whereStatement += ' or '
                }
              }
              whereStatement += ` (block = ${comparable.block} and lot = ${comparable.lot})`
              comparableIndex += 1
            })
          }

          return `SELECT * FROM mnmappluto${whereStatement}`
        },
      },
    },
  },
}
