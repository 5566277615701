import { sumBy } from 'lodash'

import ObjectID from 'bson-objectid'

import { LOSS_ITEM_VALUE_TYPE, RENT_LOSS_AMOUNT_FIELD_NAMES } from '../../constants/acas'

export const makeDefaultRentLossItemArray = (
  units,
  vcLossPercentage,
  rentFieldName = RENT_LOSS_AMOUNT_FIELD_NAMES.RESIDENTIAL
) => {
  const defaultRentLossItemArray = []
  const vacantUnits = units.filter(unit => unit.isVacant)

  if (vacantUnits.length > 0) {
    const defaultRentLossItem = {
      type: LOSS_ITEM_VALUE_TYPE.COMPUTED_VALUE,
      monthlyLoss: sumBy(vacantUnits, rentFieldName) * (1 - vcLossPercentage),
      months: '0',
      units: vacantUnits.map(unit => unit._id.toString()),
      _id: ObjectID().toString(),
    }
    defaultRentLossItemArray.push(defaultRentLossItem)
  }

  return defaultRentLossItemArray
}
