import React from 'react'
import PropTypes from 'prop-types'

import { Table, TableBody, TableCell, TableHead, TableRow } from '@mui/material'

import SourceInfoTableRow from './SourceInfoTableRow'

const SourceInfoTable = ({ dataSources, expenseDataSource, onAddSource, onRemove, onRemoveSource }) => (
  <Table data-qa="data-sources-table" size="small">
    <TableHead sx={{ '& .MuiTableCell-head': { fontSize: 12, fontWeight: 700 } }}>
      <TableRow>
        <TableCell> Data </TableCell>
        <TableCell> Source/Verification </TableCell>
        <TableCell />
      </TableRow>
    </TableHead>
    <TableBody>
      {dataSources.map((dataSource, index) => (
        <SourceInfoTableRow
          dataSource={dataSource}
          dataSourceIndex={index}
          expenseDataSource={dataSource.data === 'Expense Data' && expenseDataSource}
          key={index}
          onAddSource={onAddSource}
          onRemove={onRemove}
          onRemoveSource={onRemoveSource}
        />
      ))}
    </TableBody>
  </Table>
)

SourceInfoTable.propTypes = {
  dataSources: PropTypes.arrayOf(
    PropTypes.shape({
      data: PropTypes.string.isRequired,
      expenseDataSource: PropTypes.string,
      id: PropTypes.string,
      isCustom: PropTypes.bool,
      sources: PropTypes.arrayOf(PropTypes.string),
    }).isRequired
  ),
  onAddSource: PropTypes.func.isRequired,
  onRemove: PropTypes.func.isRequired,
  onRemoveSource: PropTypes.func.isRequired,
}

SourceInfoTable.defaultProps = {
  dataSources: [],
  expenseDataSource: '',
}

export default SourceInfoTable
