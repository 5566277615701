import React, { PureComponent } from 'react'
import { find } from 'lodash'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import { mapProps } from 'recompose'
import { FormControl, MenuItem, TextField, withStyles } from '@material-ui/core'
import { Field } from 'react-final-form'

const styles = theme => ({
  formControl: {
    minWidth: 100,
  },
  menu: {
    width: 200,
  },
  select: {
    backgroundColor: theme.palette.common.white,
  },
  menuPaper: {
    marginTop: 105,
  },
  menuOption: {
    outline: 'none',
    cursor: 'pointer',
    backgroundColor: theme.palette.common.white,
    '&:hover': {
      backgroundColor: theme.palette.secondary[100],
    },
  },
  selectedMenuOption: {
    backgroundColor: theme.palette.secondary[100],
  },
  placeholder: {
    color: 'rgba(0, 0, 0, 0.32)',
  },
})

class MaterialDropDown extends PureComponent {
  getMenuItems = () => {
    const { items, isNative, classes, value, name } = this.props
    let menuItems

    if (isNative) {
      menuItems = items.map(option => (
        <option
          key={option.value}
          value={option.value}
          className={classnames(classes.menuOption, { [classes.selectedMenuOption]: option.value === value })}
          data-qa={`${name}-${option.value}-select-option`}
        >
          {option.label || option.value}
        </option>
      ))
    } else {
      menuItems = items.map(option => (
        <MenuItem
          key={option.value}
          value={option.value}
          disabled={option.disabled}
          data-qa={`${name}-${option.value}-select-option`}
        >
          {option.label || option.value}
        </MenuItem>
      ))
    }

    return menuItems
  }

  getMenuProps = () => {
    const { isNative, classes } = this.props

    const menuProps = {
      className: classes.menu,
      getContentAnchorEl: null,
      anchorOrigin: {
        vertical: 'bottom',
        horizontal: 'left',
      },
    }
    if (isNative) {
      menuProps.classes = { paper: classes.menuPaper }
    }
    return menuProps
  }

  renderValue = value => {
    const { placeholder, items, classes } = this.props

    const option = find(items, item => item.value === value)
    if (!option && placeholder) {
      return <span className={classes.placeholder}>{placeholder}</span>
    }
    return !!option && option.label
  }

  render() {
    const {
      classes,
      fullWidth,
      label,
      onChange,
      name,
      value,
      inErrorState,
      error,
      disabled,
      isNative,
      required,
      placeholder,
      margin,
      helperText,
      size,
      selectDisplayPropsClassName,
      shrink,
      ...props
    } = this.props
    const errorText = inErrorState ? error : ''
    const shouldLabelShrink = shrink ? true : undefined

    const menuProps = this.getMenuProps()
    const selectDisplayProps = { 'data-qa': 'select-value' }
    if (selectDisplayPropsClassName) {
      selectDisplayProps.className = selectDisplayPropsClassName
    }

    return (
      <FormControl
        className={classes.formControl}
        size={size}
        variant="outlined"
        fullWidth={fullWidth}
        error={inErrorState}
        data-qa={`${name}-form-control`}
      >
        <TextField
          data-qa={`${name}-select`}
          disabled={disabled}
          select
          label={label}
          className={classes.textField}
          value={value}
          onChange={onChange}
          SelectProps={{
            'data-qa': `${name}-select-list`,
            native: !!isNative,
            displayEmpty: true,
            className: classes.select,
            MenuProps: menuProps,
            renderValue: this.renderValue,
            SelectDisplayProps: selectDisplayProps,
          }}
          error={inErrorState}
          helperText={inErrorState ? errorText : helperText}
          margin={margin}
          size={size}
          variant="outlined"
          InputLabelProps={{
            shrink: shouldLabelShrink,
          }}
          required={required}
          {...props}
        >
          {this.getMenuItems()}
        </TextField>
      </FormControl>
    )
  }
}

export const MaterialDropDownWithStyles = withStyles(styles)(MaterialDropDown)

const DropDownComponent = mapProps(({ input, meta, ...props }) => {
  const { name, value, onChange, onFocus, onBlur } = input
  const { touched, error } = meta

  return {
    name,
    value,
    onChange,
    onFocus,
    onBlur,
    inErrorState: touched && !!error,
    error,
    ...props,
  }
})(MaterialDropDownWithStyles)

class DropDown extends PureComponent {
  static propTypes = {
    name: PropTypes.string.isRequired,
    items: PropTypes.arrayOf(
      PropTypes.shape({
        label: PropTypes.string,
        value: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.bool]).isRequired,
        disabled: PropTypes.bool,
      })
    ).isRequired,
    label: PropTypes.string,
    margin: PropTypes.oneOf(['none', 'dense', 'normal']),
    fullWidth: PropTypes.bool,
    multiple: PropTypes.bool,
    required: PropTypes.bool,
    placeholder: PropTypes.string,
    helperText: PropTypes.string,
    selectDisplayPropsClassName: PropTypes.string,
    size: PropTypes.oneOf(['small', 'normal']),
    shrink: PropTypes.bool,
  }

  static defaultProps = {
    fullWidth: true,
    margin: 'dense',
    size: 'normal',
    shrink: true,
  }

  render() {
    const { name, label, ...props } = this.props
    return <Field name={name} label={label} component={DropDownComponent} {...props} />
  }
}

export default DropDown
