import { createAction } from 'redux-actions'

import {
  ORGANIZATION_FETCH,
  ORGANIZATION_UPDATE,
  ORGANIZATION_CLEAR,
  ORGANIZATION_FORMS_FETCH,
  ORGANIZATION_USERS_FETCH,
  ORGANIZATION_USERS_CLEAR,
  ORGANIZATION_USERS_CREATE,
  ORGANIZATION_USERS_UPDATE,
  ORGANIZATION_USERS_DELETE,
} from '../actionTypes/organization'

export const organizationFetch = createAction(ORGANIZATION_FETCH.ACTION)
export const organizationFetchRequest = createAction(ORGANIZATION_FETCH.REQUEST)
export const organizationFetchReceive = createAction(ORGANIZATION_FETCH.RECEIVE)
export const organizationFetchFail = createAction(ORGANIZATION_FETCH.FAIL)

export const organizationUpdate = createAction(ORGANIZATION_UPDATE.ACTION)
export const organizationUpdateRequest = createAction(ORGANIZATION_UPDATE.REQUEST)
export const organizationUpdateReceive = createAction(ORGANIZATION_UPDATE.RECEIVE)
export const organizationUpdateFail = createAction(ORGANIZATION_UPDATE.FAIL)

export const organizationClear = createAction(ORGANIZATION_CLEAR.ACTION)

export const organizationFormsFetch = createAction(ORGANIZATION_FORMS_FETCH.ACTION)
export const organizationFormsFetchRequest = createAction(ORGANIZATION_FORMS_FETCH.REQUEST)
export const organizationFormsFetchReceive = createAction(ORGANIZATION_FORMS_FETCH.RECEIVE)
export const organizationFormsFetchFail = createAction(ORGANIZATION_FORMS_FETCH.FAIL)

export const organizationUsersClear = createAction(ORGANIZATION_USERS_CLEAR.ACTION)
export const organizationUsersCreate = createAction(ORGANIZATION_USERS_CREATE.ACTION)
export const organizationUsersCreateRequest = createAction(ORGANIZATION_USERS_CREATE.REQUEST)
export const organizationUsersCreateReceive = createAction(ORGANIZATION_USERS_CREATE.RECEIVE)
export const organizationUsersCreateFail = createAction(ORGANIZATION_USERS_CREATE.FAIL)

export const organizationUsersUpdate = createAction(ORGANIZATION_USERS_UPDATE.ACTION)
export const organizationUsersUpdateRequest = createAction(ORGANIZATION_USERS_UPDATE.REQUEST)
export const organizationUsersUpdateReceive = createAction(ORGANIZATION_USERS_UPDATE.RECEIVE)
export const organizationUsersUpdateFail = createAction(ORGANIZATION_USERS_UPDATE.FAIL)

export const organizationUsersDelete = createAction(ORGANIZATION_USERS_DELETE.ACTION)
export const organizationUsersDeleteRequest = createAction(ORGANIZATION_USERS_DELETE.REQUEST)
export const organizationUsersDeleteReceive = createAction(ORGANIZATION_USERS_DELETE.RECEIVE)
export const organizationUsersFetchReceive = createAction(ORGANIZATION_USERS_FETCH.RECEIVE)
export const organizationUsersDeleteFail = createAction(ORGANIZATION_USERS_DELETE.FAIL)

export const organizationUsersFetch = createAction(ORGANIZATION_USERS_FETCH.ACTION)
export const organizationUsersFetchRequest = createAction(ORGANIZATION_USERS_FETCH.REQUEST)
export const organizationUsersFetchFail = createAction(ORGANIZATION_USERS_FETCH.FAIL)
