import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import { compose, pure } from 'recompose'
import { Grid, Button } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'
import ChevronLeft from '@mui/icons-material/ChevronLeft'
import ChevronRight from '@mui/icons-material/ChevronRight'

const styles = theme => ({
  container: {
    padding: theme.spacing.unit * 3,
  },
})

const FormFooter = ({ classes, handleSubmit, nextFormName, prevFormName, prevFormPath }) => (
  <Grid container className={classes.container} spacing={16} justify="flex-end">
    <Grid item>
      {prevFormPath && (
        <Link to={prevFormPath}>
          <Button size="small" type="submit">
            <ChevronLeft />
            {prevFormName}
          </Button>
        </Link>
      )}
    </Grid>
    <Grid item>
      {nextFormName && (
        <Button size="small" variant="contained" color="primary" type="submit" onClick={handleSubmit}>
          {nextFormName}
          <ChevronRight />
        </Button>
      )}
    </Grid>
  </Grid>
)

FormFooter.defaultProps = {
  prevFormPath: null,
}

FormFooter.propTypes = {
  prevFormPath: PropTypes.string,
  nextFormName: PropTypes.string,
  prevFormName: PropTypes.string,
  handleSubmit: PropTypes.func.isRequired,
}

export default compose(pure, withStyles(styles))(FormFooter)
