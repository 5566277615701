import { get } from 'lodash'

import { createSelector } from 'reselect'

import { getAddress } from '../../../../../../shared/utils/geography'

export const subjectPropertyAddressSelector = createSelector(
  state => get(state, 'report.reportData.propertyInformation.propertySummary.streetAddress'),
  state => get(state, 'report.reportData.propertyInformation.propertySummary.city'),
  state => get(state, 'report.reportData.zip'),
  state => get(state, 'report.reportData.state'),
  state => get(state, 'report.reportData.propertyInformation.coords'),
  (streetAddress, city, zip, state, coords) => {
    const address = getAddress(streetAddress, city, state, zip)
    return {
      subjectPropertyAddress: address,
      subjectPropertyCoords: {
        lat: get(coords, 'latitude'),
        lng: get(coords, 'longitude'),
      },
    }
  }
)
