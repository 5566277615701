export const areSameComps = (compA, compB) => {
  if ((compA._id || compB._id) && compA._id === compB._id) {
    return true
  }
  if (compA.salesTransactionId || compB.salesTransactionId) {
    return compA.salesTransactionId === compB.salesTransactionId
  }

  return compA.address === compB.address && compA.zip === compB.zip && compA.saleDate === compB.saleDate
}
