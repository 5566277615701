import React from 'react'
import { Button, Paper, Stack, Typography } from '@mui/material'
import { differenceWith, get, pick, isString, isEmpty } from 'lodash'

import arrayMutators from 'final-form-arrays'
import createDecorator from 'final-form-calculate'
import { compose } from 'redux'

import * as Api from 'report/api'
import CompsImportForm from 'client-shared/components/CompsImportForm'
import wrapForm from 'report/forms/wrapForm'
import { RadioButtonList, GeneratedComment } from 'client-shared/components'
import { compareProperties } from 'client-shared/utils/propertyHelper'
import { errorNotification } from 'client-shared/redux/actions/notifications'
import { withMicrofrontend } from 'client-shared/hooks/useMicrofrontend'
import { CAP_RATE_COMPS_PATH } from 'shared/constants/report/keysAndDataPaths'

import { getSubjectProperty } from 'report/forms/sales/SalesCompsSearch/helpers'
import LocalCapRateCompModal from 'report/forms/final/CapRateComps/CapRateCompModal/LocalCapRateCompModal'

import { SALES_COMPS_SORT_VALUES } from '../../sales/SalesCompsSearch/constants'

import RemovedCapRateCompsTable from './RemovedCapRateCompsTable'
import SelectedCapRateCompsTable from './SelectedCapRateCompsTable'
import {
  checkIfCompsAreLatestVersion,
  getComparableCapRatesGeneratedText,
  getRemovedCapRateComps,
  getSaleStatus,
  getSelectedCapRateComps,
  mapCapRateCompToCompPlexSalesComp,
  mapCompPlexSalesCompToCapRateComp,
  transformSalesCompToRawOutput,
  mapWebappSalesCompToCapRateComp,
  areSameCapRateComps,
} from './helpers'

import { CONDITIONS } from './constants'
import SalesCompSearch from './SalesCompSearch'
import CreateComp from './CreateComp'

const heading = 'Cap Rate Comps'

const inContractCheckboxDecorator = createDecorator({
  field: /capRateComps\[\d+\]\.listing/,
  updates: (value, name, allValues) => {
    const inContractField = name.replace('listing', 'inContract')
    const inContractValue = get(allValues, inContractField)
    /*
      this decorator gets called on item remove that is an undesired behavior
      therefore, we added a condition to check if the item exists by the field path or not
    */
    if (inContractValue) {
      return {
        [inContractField]: value ? inContractValue : false,
      }
    }
    return {}
  },
})

const saleStatusDecorator = createDecorator({
  field: [/capRateComps\[\d+\]\.listing/, /capRateComps\[\d+\]\.inContract/],
  updates: (value, name, allValues) => {
    let inContractField, listingField, saleStatusField
    if (name.includes('inContract')) {
      inContractField = name
      listingField = name.replace('inContract', 'listing')
      saleStatusField = name.replace('inContract', 'saleStatus')
    } else {
      listingField = name
      inContractField = name.replace('listing', 'inContract')
      saleStatusField = name.replace('listing', 'saleStatus')
    }

    const inContractValue = get(allValues, inContractField)
    const listingValue = get(allValues, listingField)

    return {
      [saleStatusField]: getSaleStatus({ inContract: inContractValue, listing: listingValue }),
    }
  },
})

class CapRateComps extends React.PureComponent {
  compPlexRef = React.createRef()

  state = {
    isCapRateCompsImporting: false,
    editCompProps: null,
    localCapRateCompToEdit: null,
  }

  componentDidMount() {
    const { compPlexLoaded, errorNotification } = this.props
    if (!compPlexLoaded) {
      errorNotification('Failed to load cap rate comps service. Some features will not be available.')
    }
  }

  navigateToEditModal = compId => {
    const {
      history,
      match: { path },
    } = this.props
    // This URL is parsed in SalesCompSearch.jsx
    history.push(`${path}/details/${compId}`, { skipSave: true })
  }

  navigateToLocalEditModal = comp => {
    this.setState({ localCapRateCompToEdit: mapCapRateCompToCompPlexSalesComp(comp) })
  }

  navigateAwayFromLocalEditModal = () => {
    this.setState({ localCapRateCompToEdit: null })
  }

  removeComp = comp => {
    const { address, saleDate } = comp
    const { form } = this.props
    const { capRateComps } = form.values

    const removedCompIndex = capRateComps.findIndex(value => value.address === address && value.saleDate === saleDate)

    form.batch(() => {
      form.mutators.remove('capRateComps', removedCompIndex)
      form.mutators.push('removedCapRateComps', capRateComps[removedCompIndex])
    })
  }

  addComp = async comp => {
    const { form } = this.props
    const { capRateComps, removedCapRateComps } = form.values
    let transformedComp = comp
    if (comp.salesTransactionId && !comp.salesEventId) {
      transformedComp = transformSalesCompToRawOutput(comp)
    }
    const updatedSelectedComps = getSelectedCapRateComps(capRateComps, [transformedComp])
    const updatedRemovedComps = getRemovedCapRateComps(removedCapRateComps, [transformedComp])

    const selectedCompsWithLatestInfo = await checkIfCompsAreLatestVersion(updatedSelectedComps)

    form.batch(() => {
      form.change('capRateComps', selectedCompsWithLatestInfo)
      form.change('removedCapRateComps', updatedRemovedComps)
    })
  }

  updateComp = updatedComp => {
    const { form } = this.props
    const { capRateComps } = form.values

    const capRateComp = mapCompPlexSalesCompToCapRateComp(updatedComp)

    // Sale date is editable in this modal, so we can't use sale date to check equality
    const compIndex = capRateComps.findIndex(value => value.address === capRateComp.address)

    form.mutators.update('capRateComps', compIndex, capRateComp)
    this.navigateAwayFromLocalEditModal()
  }

  deleteRemovedComp = comp => {
    const { address, saleDate } = comp
    const { form } = this.props
    const { removedCapRateComps } = form.values

    const deletedCompIndex = removedCapRateComps.findIndex(
      value => value.address === address && value.saleDate === saleDate
    )

    form.mutators.remove('removedCapRateComps', deletedCompIndex)
  }

  clearRemovedTable = () => {
    const { form } = this.props
    form.change('removedCapRateComps', [])
  }

  setEditCompProps = editCompProps => {
    this.setState({ editCompProps })
  }

  importCapRateComps = reportId => {
    const { form, errorNotification } = this.props
    const { capRateComps } = form.values

    this.setState({ isCapRateCompsImporting: true }, async () => {
      try {
        const importedCapRateComps = await Api.fetchCapRateCompsByReportId(reportId)
        const mappedImportedCapRateComps = importedCapRateComps.map(comp => {
          return {
            ...comp,
            elevatored: comp.elevator,
          }
        })
        const newCapRateComps = differenceWith(mappedImportedCapRateComps, capRateComps, compareProperties)

        const newCompsWithLatestInfo = await checkIfCompsAreLatestVersion(newCapRateComps)

        form.mutators.concat('capRateComps', newCompsWithLatestInfo)
      } catch (error) {
        errorNotification(error.response.data.error)
      }

      this.setState({ isCapRateCompsImporting: false })
    })
  }

  addFromSalesComps = async () => {
    const { form, salesCompsWithCapRate } = this.props
    const { capRateComps, removedCapRateComps } = form.values

    const newCapRateComps = salesCompsWithCapRate.map(salesComp => {
      let capRateComp = salesComp
      if (salesComp.salesTransactionId && !salesComp.salesEventId) {
        capRateComp = mapWebappSalesCompToCapRateComp(salesComp)
      }
      return capRateComp
    })

    const updatedSelectedComps = getSelectedCapRateComps(capRateComps, newCapRateComps)
    const updatedRemovedComps = getRemovedCapRateComps(removedCapRateComps, newCapRateComps)

    const selectedCompsWithLatestInfo = await checkIfCompsAreLatestVersion(updatedSelectedComps)

    form.batch(() => {
      form.change('capRateComps', selectedCompsWithLatestInfo)
      form.change('removedCapRateComps', updatedRemovedComps)
    })
  }

  getCustomDetailsButton = comp => {
    if (!comp) {
      return null
    }

    if (comp.salesEventId && comp.salesEventVersion) {
      return null
    }

    return () => (
      <Button variant="text" onClick={() => this.navigateToLocalEditModal(comp)}>
        Edit Comp
      </Button>
    )
  }

  render() {
    const { form, authenticatedUser, subjectProperty, subjectCoordinates, salesCompsWithCapRate } = this.props
    const { isCapRateCompsImporting, localCapRateCompToEdit, editCompProps } = this.state

    const { capRateComps, removedCapRateComps } = form.values

    const removedCapRateCompsCount = removedCapRateComps.length
    const removedTableDisabled = removedCapRateCompsCount === 0
    const addFromSalesCompsDisabled = !salesCompsWithCapRate.some(
      salesComp =>
        !capRateComps.some(capRateComp => areSameCapRateComps(capRateComp, mapWebappSalesCompToCapRateComp(salesComp)))
    )

    return (
      <>
        <Stack minWidth={1360} spacing={2} sx={{ mb: 2 }}>
          <Paper>
            <Stack alignItems="center" direction="row" spacing={2}>
              <Typography variant="h6">Comp Database</Typography>
              <CreateComp
                compPlexRef={this.compPlexRef}
                editCompProps={editCompProps}
                setEditCompProps={this.setEditCompProps}
                addComp={this.addComp}
              />
              <Button
                variant="contained"
                data-qa="add-from-sales-comps-btn"
                onClick={this.addFromSalesComps}
                disabled={addFromSalesCompsDisabled}
              >
                Add From Sales Comps
              </Button>
              <LocalCapRateCompModal
                open={!!localCapRateCompToEdit}
                onSubmit={this.updateComp}
                initialValues={localCapRateCompToEdit}
                onClose={this.navigateAwayFromLocalEditModal}
              />
              <CompsImportForm
                tooltipText="Use this to copy over cap rate comparables saved to another report."
                import={this.importCapRateComps}
                isLoading={isCapRateCompsImporting}
                openDialogButtonProps={{
                  sx: { height: '30px' },
                }}
              />
            </Stack>
            <SalesCompSearch
              form={form}
              addComp={this.addComp}
              removeComp={this.removeComp}
              editCompProps={editCompProps}
              setEditCompProps={this.setEditCompProps}
              compPlexRef={this.compPlexRef}
            />
          </Paper>
          <Paper>
            <SelectedCapRateCompsTable
              title={`Selected Comps (${capRateComps.length})`}
              addComp={this.addComp}
              removeComp={this.removeComp}
              editCompProps={editCompProps}
              navigateToEditModal={this.navigateToEditModal}
              salesComps={capRateComps}
              sortSalesComps={SALES_COMPS_SORT_VALUES.DATE_SOLD}
              subjectProperty={subjectProperty}
              subjectCoordinates={subjectCoordinates}
              getCustomDetailsButton={this.getCustomDetailsButton}
              form={form}
              authenticatedUser={authenticatedUser}
              fieldName="capRateComps"
            />
          </Paper>
          <RemovedCapRateCompsTable
            title={`Removed Comps (${removedCapRateCompsCount})`}
            addComp={this.addComp}
            removeComp={this.deleteRemovedComp}
            salesComps={removedCapRateComps}
            subjectProperty={subjectProperty}
            subjectCoordinates={subjectCoordinates}
            disabled={removedTableDisabled}
            clearRemovedTable={this.clearRemovedTable}
          />
        </Stack>
        <Stack maxWidth={900} spacing={2}>
          <Paper>
            <GeneratedComment
              dataPath="comparableCapRatesDiscussion"
              getGeneratedText={getComparableCapRatesGeneratedText}
              isDynamicContent
              label="Comparable Cap Rates Discussion"
              title="Generated commentary"
              tooltipText="The following text and generated commentary will appear in the Income Approach of your report."
            />
          </Paper>
          <Paper>
            <Typography variant="subtitle1">SUBJECT PROPERTY</Typography>
            <RadioButtonList
              horizontal
              items={CONDITIONS}
              label="How does the income potential of the cap rate comps compare to the subject property?"
              name="comparableIncomePotential"
            />
            <RadioButtonList
              horizontal
              items={CONDITIONS}
              label="How do the conditions of the cap rate comps compare to the subject property?"
              name="comparablePropertyConditions"
            />
            <RadioButtonList
              horizontal
              items={CONDITIONS}
              label="How do the locations of the cap rate comps compare to the subject property?"
              name="comparablePropertyLocations"
            />
          </Paper>
        </Stack>
      </>
    )
  }
}

const mapStateToProps = state => {
  const propertyInformation = get(state, 'report.reportData.propertyInformation')

  const subjectCoordinates = {
    lat: get(propertyInformation, 'coords.latitude'),
    lng: get(propertyInformation, 'coords.longitude'),
  }

  const subjectProperty = getSubjectProperty(state)

  const allSelectedSalesComps =
    get(state, 'report.reportData.salesApproach.improved.salesCompsSearch.selectedComps') || []
  const salesCompsWithCapRate = allSelectedSalesComps
    .map(comp => {
      const { saleInformation } = comp
      if (isString(saleInformation.capRate)) {
        return {
          ...comp,
          saleInformation: {
            ...saleInformation,
            capRate:
              saleInformation.capRate === 'N/A' || isEmpty(saleInformation.capRate)
                ? null
                : parseFloat(saleInformation.capRate),
          },
        }
      }
      return comp
    })
    .filter(comp => !!get(comp, 'saleInformation.capRate'))

  return {
    authenticatedUser: pick(get(state, 'authentication.user'), ['id', 'username', 'fullName']),
    salesCompsWithCapRate,
    subjectProperty,
    subjectCoordinates,
  }
}

const mapDispatchToProps = dispatch => ({
  errorNotification: err => dispatch(errorNotification({ message: err })),
})

export default compose(
  wrapForm(
    CAP_RATE_COMPS_PATH,
    {
      heading,
      mutators: {
        ...arrayMutators,
      },
      decorators: [inContractCheckboxDecorator, saleStatusDecorator],
      keepDirtyOnReinitialize: true,
    },
    mapStateToProps,
    mapDispatchToProps
  ),
  withMicrofrontend('compplex', 'compPlexLoaded')
)(CapRateComps)
