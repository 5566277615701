import React, { FC } from 'react'

import { Form } from 'react-final-form'
import { get } from 'lodash'

import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material'

import { Close } from '@mui/icons-material'

import { Auto } from '@ui/Field'

import { required } from 'client-shared/utils/validation'
import {
  ADDITIONAL_COMP_INFO_ROW_IDS,
  COMP_INFO_ROW_IDS_TO_LABEL,
  COMP_INFO_ROW_IDS_TO_PROPERTY,
  COMP_INFO_ROW_PROPERTY_TO_IDS,
} from 'shared/helpers/salesApproach/salesCompAdjustmentConstants'

type CompInfoKeys = keyof typeof COMP_INFO_ROW_IDS_TO_PROPERTY

type AddComparableInformationDialogProps = {
  onSave: (data: { compInfoName: string }) => void
  onCancel: () => void
  showInExport: { [key in CompInfoKeys]: boolean }
}

const validateCompInfoName = (value: string, showInExport: { [key in CompInfoKeys]: boolean }) => {
  const isEmptyValue = required(value)
  if (isEmptyValue) {
    return 'Category name is required'
  }

  const formattedValue = value.toLowerCase().trim()

  const nonExistentValue = !Object.values(COMP_INFO_ROW_IDS_TO_LABEL)
    .map(value => value.toLowerCase())
    .includes(formattedValue)
  if (nonExistentValue) {
    return `Please select a value from a list`
  }

  const allExistingCategoriesLabels = Object.keys(showInExport)
    .map(id => COMP_INFO_ROW_IDS_TO_LABEL[COMP_INFO_ROW_PROPERTY_TO_IDS[id]]?.toLowerCase())
    .filter(Boolean)

  const isAlreadyExists = allExistingCategoriesLabels.includes(formattedValue)
  if (isAlreadyExists) {
    return `${value} already exists in the table`
  }

  return null
}

const AddComparableInformationDialog: FC<AddComparableInformationDialogProps> = ({
  onSave,
  onCancel,
  showInExport,
}) => {
  const [compInfoName, setCompInfoName] = React.useState('')

  const getSubcategoriesSuggestions = React.useCallback(() => {
    const result = Object.entries(ADDITIONAL_COMP_INFO_ROW_IDS)
      .filter(([_, value]) => !Object.keys(showInExport).includes(COMP_INFO_ROW_IDS_TO_PROPERTY[value]))
      .map(([_, value]) => COMP_INFO_ROW_IDS_TO_LABEL[value])
      .filter(Boolean)

    return result
  }, [showInExport])

  const validateOnlyDirty = React.useCallback(
    (value, _values, { dirty }) => dirty && validateCompInfoName(value, showInExport),
    [showInExport]
  )

  const parse = React.useCallback(value => value ?? '', [])

  const onChange = React.useCallback(([_event, currentValue, _reason, _details], onChangeField) => {
    const nextValue = get(currentValue, 'value', currentValue || '')
    onChangeField(nextValue)
    setCompInfoName(nextValue)
  }, [])

  const onInputChange = React.useCallback((event, onChangeField) => {
    const nextValue = get(event, 'target.value', '')
    onChangeField(nextValue)
    setCompInfoName(nextValue)
  }, [])

  const normalizeStringValue = React.useCallback(value => value?.toLowerCase()?.trim(), [])
  const isOptionEqualToValue = React.useCallback(
    (option, value) => normalizeStringValue(option?.label || option) === normalizeStringValue(value),
    [normalizeStringValue]
  )

  const filterOptions = React.useCallback(
    options => {
      const availableOptions = options.filter((option: string) =>
        normalizeStringValue(option).includes(normalizeStringValue(compInfoName))
      )

      return availableOptions
    },
    [compInfoName, normalizeStringValue]
  )

  return (
    <Form onSubmit={onSave}>
      {({ handleSubmit, invalid, pristine }) => (
        <Dialog fullWidth maxWidth="sm" open onClose={onCancel}>
          <DialogTitle>Add Comparable Information</DialogTitle>
          <Close
            onClick={onCancel}
            sx={{
              position: 'absolute',
              width: 24,
              cursor: 'pointer',
              right: 16,
              top: 18,
              color: '#979797',
            }}
          />
          <DialogContent>
            <Auto.FreeSolo
              data-qa="comp-info-row-name-input"
              autoSelect
              size="small"
              name="compInfoName"
              options={getSubcategoriesSuggestions()}
              placeholder="Enter Category Name"
              validate={validateOnlyDirty}
              sx={{ mb: '8px' }}
              {...{
                initialValue: compInfoName,
                inputValue: compInfoName,
                onChange,
                onInputChange,
                parse,
                filterOptions,
                isOptionEqualToValue,
                value: compInfoName,
              }}
            />
          </DialogContent>
          <DialogActions sx={{ padding: '2px 24px 20px 24px' }}>
            <Button onClick={onCancel}>Cancel</Button>
            <Button
              data-qa="submit-changes-btn"
              disabled={pristine || invalid}
              onClick={handleSubmit}
              variant="contained"
            >
              Add
            </Button>
          </DialogActions>
        </Dialog>
      )}
    </Form>
  )
}

export default AddComparableInformationDialog
