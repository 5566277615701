import React from 'react'
import { Form } from 'react-final-form'
import { FORM_ERROR } from 'final-form'
import PropTypes from 'prop-types'
import { uniqBy, orderBy, some, isEmpty, noop, get, find } from 'lodash'
import ObjectID from 'bson-objectid'
import {
  Button,
  Card,
  Dialog,
  DialogTitle,
  DialogContent,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  withStyles,
} from '@material-ui/core'

import BoweryDate from '@bowery-valuation/bowery-date'

import { getFullTaxClassName } from 'shared/report-calculations/income-approach/tax/helpers'

import { STATE_NAMES } from '../../../../shared/constants/geography'

import { fromPercents, toPercents } from '../../../../shared/utils/numberOperations'
import { CheckboxWithLabel } from '../../../../shared/components/Checkbox'
import { Text, Number } from '../../../../shared/components'
import { formatPercentageFloat } from '../../../../shared/utils/numberFormatters'
import { TAX_JURISDICTION_NYC } from '../../../../shared/constants/taxRates'

import styles from './styles'
import TaxRateYearField from './TaxRateYearField'
import { MIN_TAX_RATE_EFFECTIVE_YEAR } from './constants'

class TaxRateEditModal extends React.PureComponent {
  static propTypes = {
    onCancel: PropTypes.func,
    onSave: PropTypes.func.isRequired,
    taxClass: PropTypes.object.isRequired,
    assessmentDistrict: PropTypes.object.isRequired,
    jurisdictionName: PropTypes.string.isRequired,
    jurisdictionState: PropTypes.string.isRequired,
    jurisdictionTaxClasses: PropTypes.object.isRequired,
  }
  static defaultProps = {
    onCancel: noop,
  }

  onSubmit = ({ taxRates, taxClassName, assessmentDistrict }, form, complete) => {
    const { onSave } = this.props
    onSave({
      taxRates: orderBy(taxRates, ['effectiveDate', 'value'], ['desc', 'desc']),
      taxClassName,
      assessmentDistrict,
    })
  }

  validate = ({ taxRates }) => {
    const hasDuplicateTaxRateDates = uniqBy(taxRates, rate => rate.effectiveDate).length !== taxRates.length
    const hasEmptyValues = some(taxRates, rate => isEmpty(rate.effectiveDate) || rate.value === 0)

    const errors = []
    if (hasDuplicateTaxRateDates || hasEmptyValues) {
      errors[FORM_ERROR] = 'A tax rate is empty or there are duplicate effective dates'
    }
    return errors
  }

  taxClassNameValidate = taxClassName => {
    const { jurisdictionTaxClasses, taxClass } = this.props

    if (taxClassName === '') {
      return 'Tax Class name required'
    }

    const existingTaxClassNames = jurisdictionTaxClasses.map(taxClass => {
      return { name: taxClass.name.trim().toLowerCase() }
    })

    const taxClassNameToValidate = taxClassName.trim().toLowerCase()

    const hasDuplicateTaxClassName = find(existingTaxClassNames, ['name', taxClassNameToValidate])
    if (hasDuplicateTaxClassName && taxClassName !== taxClass.name) {
      return 'Tax Class name already exists'
    }

    return undefined
  }

  addRate = form => {
    const taxRates = get(form.getState().values, 'taxRates', [])

    const newRate = {
      _id: ObjectID().toString(),
      effectiveDate: null,
      value: 0,
    }

    form.change('taxRates', [newRate, ...taxRates])
  }

  removeRate = (rateToRemove, form) => {
    const taxRates = get(form.getState().values, 'taxRates', [])
    const updatedTaxRates = taxRates.filter(taxRate => taxRate !== rateToRemove)

    form.change('taxRates', updatedTaxRates)
  }

  showEqualizationRate = jurisdictionState => {
    return jurisdictionState !== STATE_NAMES.NY
  }
  allowChangingTaxClassName = jurisdictionName => {
    return jurisdictionName !== TAX_JURISDICTION_NYC.name
  }

  render() {
    const { classes, onCancel, taxClass, jurisdictionName, jurisdictionState, assessmentDistrict } = this.props

    const showEqualizationRate = this.showEqualizationRate(jurisdictionState)
    const allowChangingTaxClassName = this.allowChangingTaxClassName(jurisdictionName)

    return (
      <Dialog
        open
        disableBackdropClick
        maxWidth="md"
        onClose={onCancel}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        data-qa="taxRate-edit-modal"
      >
        <DialogTitle id="alert-dialog-title">{`${getFullTaxClassName(
          taxClass,
          jurisdictionName,
          jurisdictionState
        )} Tax Rates`}</DialogTitle>
        <div data-qa="taxRate-edit-modal-content">
          <Form
            onSubmit={this.onSubmit}
            validate={this.validate}
            initialValues={{ taxRates: taxClass.rateHistory, taxClassName: taxClass.name, assessmentDistrict }}
            render={({ handleSubmit, form, submitting, pristine, values, invalid }) => (
              <form onSubmit={handleSubmit}>
                <DialogContent className={classes.subSectionWrapper}>
                  {showEqualizationRate && (
                    <Grid container>
                      <Grid item xs={6}>
                        <Number
                          name="assessmentDistrict.equalizationRate"
                          label="Equalization Rate"
                          decimalScale={2}
                          fixedDecimalScale
                          suffix="%"
                          format={value => toPercents(value, 2)}
                          normalize={fromPercents}
                          data-qa="projected-equalization-equalizationRatio-input"
                        />
                      </Grid>
                    </Grid>
                  )}
                  <Grid container justify="flex-start" alignItems="center">
                    <Grid item xs={6} style={{ marginRight: 16 }}>
                      <TaxRateYearField
                        data-qa="projected-taxClass-list"
                        label="Last Reassessment Year"
                        name="assessmentDistrict.lastReassessmentDate"
                        form={form}
                        min={MIN_TAX_RATE_EFFECTIVE_YEAR}
                        max={new BoweryDate().year}
                      />
                    </Grid>
                    <Grid item xs={5}>
                      <CheckboxWithLabel
                        className={classes.checkbox}
                        name="assessmentDistrict.plannedReassessment"
                        label="Planned Reassessment"
                        data-qa="projected-includedInExport-checkbox"
                      />
                    </Grid>
                  </Grid>
                  {allowChangingTaxClassName && (
                    <Grid container justify="flex-start">
                      <Grid item xs={6}>
                        <Text
                          label="Tax Class Name"
                          name="taxClassName"
                          data-qa="tax-class-name"
                          validate={this.taxClassNameValidate}
                        />
                      </Grid>
                    </Grid>
                  )}
                  <Grid container justify="space-between" alignItems="center">
                    <Grid item xs={10} style={{ marginBottom: 8 }}>
                      <Typography color="primary" variant="subtitle1">
                        Tax Rates
                      </Typography>
                    </Grid>
                    <Grid container item xs={2} style={{ marginBottom: 8 }} justify="flex-end" alignContent="flex-end">
                      <Button
                        variant="contained"
                        color="primary"
                        className={classes.button}
                        onClick={() => this.addRate(form)}
                        data-qa="edit-tax-rates_btn"
                      >
                        ADD
                      </Button>
                    </Grid>
                    <Grid item xs={12}>
                      <Card elevation={0} className={classes.tableContainer}>
                        <Table>
                          <TableHead>
                            <TableRow data-qa="head-row">
                              <TableCell className={classes.headerCellLeading}>Year</TableCell>
                              <TableCell className={classes.headerCell}>Tax Rate</TableCell>
                              <TableCell className={classes.headerCell} />
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {(values.taxRates || []).map((rate, index) => {
                              return (
                                <TableRow data-qa={`tax-rate-row-${index}`} key={index}>
                                  <TableCell className={classes.numberCellLeading} data-qa="tax-rate-label-cell">
                                    <TaxRateYearField
                                      name={`taxRates[${index}].effectiveDate`}
                                      form={form}
                                      min={MIN_TAX_RATE_EFFECTIVE_YEAR}
                                      max={new BoweryDate().year}
                                      data-qa="tax-rate-effective-year-input"
                                    />
                                  </TableCell>
                                  <TableCell className={classes.numberCell} data-qa="tax-rate-value-cell">
                                    <Number
                                      name={`taxRates[${index}].value`}
                                      decimalScale={10}
                                      suffix="%"
                                      format={value => formatPercentageFloat(value, 10)}
                                      normalize={fromPercents}
                                      thousandSeparator
                                      data-qa="tax-rate-value-input"
                                    />
                                  </TableCell>
                                  <TableCell className={classes.numberCell} data-qa="tax-rate-remove-cell">
                                    <Button
                                      variant="contained"
                                      color="primary"
                                      className={classes.button}
                                      onClick={() => this.removeRate(rate, form)}
                                      data-qa="edit-tax-rates_btn"
                                      disabled={values.taxRates.length === 1}
                                    >
                                      DELETE
                                    </Button>
                                  </TableCell>
                                </TableRow>
                              )
                            })}
                          </TableBody>
                        </Table>
                      </Card>
                    </Grid>
                  </Grid>
                </DialogContent>
                <div className={classes.saveDialog}>
                  <Grid container justify="flex-end" alignItems="center">
                    <Button color="primary" onClick={onCancel} data-qa="cancel-btn" className={classes.cancelButton}>
                      Cancel
                    </Button>
                    <Button
                      variant="contained"
                      className={classes.button}
                      type="submit"
                      color="primary"
                      autoFocus
                      data-qa="save-btn"
                      disabled={invalid}
                    >
                      Save
                    </Button>
                  </Grid>
                </div>
              </form>
            )}
          />
        </div>
      </Dialog>
    )
  }
}

export default withStyles(styles)(TaxRateEditModal)
