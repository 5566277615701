export const REMAINING_COST_TYPE_BUDGET = 'budget'
export const REMAINING_COST_TYPE_ACTUAL_COST = 'actual cost'

export type RowDefinition = {
  fieldPath: string
  label: string
}

type RowDefinitions = {
  [key: string]: RowDefinition
}

export const getRowDefinitions = (key: string): RowDefinitions => {
  return {
    directCosts: {
      fieldPath: `subjectBudget.${key}.directCosts`,
      label: 'Direct Costs',
    },
    directCostsAdjustment: {
      fieldPath: `subjectBudget.${key}.directCostsAdjustment`,
      label: 'Adjustment to Direct Costs',
    },
    siteImprovementCosts: {
      fieldPath: `subjectBudget.${key}.siteImprovementCosts`,
      label: 'Site Improvement Costs',
    },
    demolitionCosts: {
      fieldPath: `subjectBudget.${key}.demolitionCosts`,
      label: 'Demolition Costs',
    },
    indirectCosts: {
      fieldPath: `subjectBudget.${key}.indirectCosts`,
      label: 'Indirect Costs',
    },
    financingCosts: {
      fieldPath: `subjectBudget.${key}.financingCosts`,
      label: 'Financing Costs',
    },
  }
}

export const getDepreciationRowDefinitions = (key: string): RowDefinitions => {
  return {
    physical: {
      fieldPath: `depreciation.${key}.physical`,
      label: 'Physical',
    },
    functionalObsolescence: {
      fieldPath: `depreciation.${key}.functionalObsolescence`,
      label: 'Functional Obsolescence',
    },
    externalObsolescence: {
      fieldPath: `depreciation.${key}.externalObsolescence`,
      label: 'External Obsolescence',
    },
  }
}

export const StartingValueLabel: { [key: string]: string } = {
  siteImprovementDepreciation: 'Estimated Total Site Improvements',
  'replacementCostDepreciations[0]': 'Estimated Total Replacement Cost',
}

export const TotalLabel: { [key: string]: string } = {
  siteImprovementDepreciation: 'Depreciated Site Improvements Total',
  'replacementCostDepreciations[0]': 'Depreciated Replacement Cost',
}
