import { GeneratedText, gtIfElse, gtTemplate, gtLockedSentence, gtLocked } from 'shared/utils/narrative/GeneratedText'

import { mapDTO, mapDataModel } from './mappings'

const generateNYCConformingUse = () => {
  return gtTemplate`The ${gtLockedSentence('propertyUsesSentence')} property is in ${gtIfElse(
    'selectedOneZone',
    gtTemplate`a ${gtLockedSentence('zonesSentence')} zone`,
    gtTemplate`${gtLockedSentence('zonesSentence')} zones`
  )}, which permit${gtIfElse('selectedOneZone', gtTemplate`s`, gtTemplate``)} ${gtLockedSentence(
    'zoneAllowableUsesSentence'
  )}${gtIfElse('areMultipleAllowableUses', gtTemplate``, gtTemplate`s`)} as of right${gtLockedSentence(
    'commercialOverlaySentence'
  )}${gtIfElse(
    'isAsComplete',
    gtTemplate`Upon completion of the renovations the subject will be `,
    gtTemplate`The subject is `
  )}${gtIfElse('isPreExisting', gtTemplate`pre-existing `, gtTemplate``)}${gtIfElse(
    'isFreddieMacReport',
    gtTemplate`legally `,
    gtTemplate``
  )}${gtIfElse(
    'isConformingWithAllowableUses',
    gtTemplate`conforming`,
    gtTemplate`non-conforming`
  )} with regards to the allowable uses.`
}

const generateNJConformingUse = () => {
  return gtTemplate`The ${gtLockedSentence('zonesSentence')} zone${gtIfElse(
    'selectedMultipleZones',
    gtTemplate`s`,
    gtTemplate``
  )} permit${gtIfElse('selectedOneZone', gtTemplate`s`, gtTemplate``)} ${gtLockedSentence(
    'permittedUsesSentence'
  )} as of right${gtLockedSentence('commercialOverlaySentence')}${gtLocked('fullAddress')} is a ${gtLockedSentence(
    'currentUsesSentence'
  )} property, ${gtIfElse(
    'isAsComplete',
    gtTemplate`upon completion of the renovations will be `,
    gtTemplate`is `
  )}${gtIfElse('isPreExisting', gtTemplate`pre-existing `, gtTemplate``)}${gtIfElse(
    'isFreddieMacReport',
    gtTemplate`legally `,
    gtTemplate``
  )}${gtIfElse(
    'isConformingWithAllowableUses',
    gtTemplate`conforming`,
    gtTemplate`non-conforming`
  )} with regards to the allowable uses.`
}

const generate = () => {
  const gt = new GeneratedText()

  const conformingUseNYC = generateNYCConformingUse()
  const conformingUseNJ = generateNJConformingUse()

  gt.t`${gtIfElse('isNYCReport', conformingUseNYC, conformingUseNJ)}`

  return gt.getParts()
}

export default { generate, mapDTO, mapDataModel }
