import React from 'react'
import PropTypes from 'prop-types'
import { get } from 'lodash'

import { Typography } from '@material-ui/core'

import { compose } from 'recompose'

import { SALES_COMPS_MAP_KEY } from 'shared/constants/report/keysAndDataPaths'

import wrapForm from '../../wrapForm'
import { DATA_PATH as LEGACY_BASE_DATA_PATH } from '..'

import SalesCompsMap from './SalesCompsMap'
import { Labels } from './SalesCompsMapConstants'

class SalesCompsMapContainer extends React.PureComponent {
  static propTypes = {
    state: PropTypes.string.isRequired,
    city: PropTypes.string.isRequired,
    zip: PropTypes.string.isRequired,
    subject: PropTypes.object.isRequired,
    salesComps: PropTypes.array.isRequired,
  }

  render() {
    const { state, city, zip, subject, salesComps } = this.props
    const hasSalesComps = salesComps.length > 0
    return (
      <div>
        {hasSalesComps ? (
          <SalesCompsMap state={state} city={city} zip={zip} subject={subject} comps={salesComps} />
        ) : (
          <Typography type="subtitle1">You need to add some Sales Comps</Typography>
        )}
      </div>
    )
  }
}

export const createSalesCompsMapContainer = baseDataPath => {
  const DATA_PATH = [...baseDataPath, SALES_COMPS_MAP_KEY]
  const salesApproachPath = baseDataPath.join('.')
  return compose(
    wrapForm(
      DATA_PATH,
      { heading: Labels.SALES_COMPARABLES_MAP },
      state => {
        const salesComps = get(state, `report.reportData.${salesApproachPath}.salesCompsSearch.selectedComps`, [])
        return {
          salesComps,
          state: get(state, 'report.reportData.state'),
          city: get(state, 'report.reportData.city'),
          zip: get(state, 'report.reportData.zip'),
          subject: {
            coords: get(state, 'report.reportData.propertyInformation.coords'),
            streetAddress: get(state, 'report.reportData.propertyInformation.propertySummary.streetAddress'),
            streetName: get(state, 'report.reportData.propertyInformation.propertySummary.streetName'),
          },
        }
      },
      {}
    )
  )(SalesCompsMapContainer)
}

export default createSalesCompsMapContainer(LEGACY_BASE_DATA_PATH)
