import { withStyles } from '@material-ui/core/styles'

import Tooltip from '../../../../../shared/components/Tooltip/Tooltip'

const LightTooltip = withStyles(theme => ({
  root: {
    backgroundColor: theme.palette.common.white,
    color: 'rgba(0, 0, 0, 0.87)',
    boxShadow: theme.shadows[1],
    fontSize: 11,
    marginBottom: -8,
  },
  popper: {},
  childrenWrapper: {},
}))(Tooltip)

export default LightTooltip
