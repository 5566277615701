import { GeneratedText, gtIfElse, gtLocked, gtLockedSentence, gtTemplate } from 'shared/utils/narrative/GeneratedText'

import { PropertyTypes } from 'shared/constants'

import { mapDTO, mapDataModel } from './mappings'

export const getNoOccupancyCommentary = () => {
  return gtTemplate`We note that the subject is currently vacant, but ${gtLockedSentence('byRentTypeSentence')}`
}
export const getPartialOccupancyCommentary = () => {
  const occupancyRateGt = gtLocked('occupancyRate')
  return gtTemplate`We note that the subject is ${occupancyRateGt} occupied ${gtLockedSentence(
    'vacantUnitSentence'
  )} ${gtLockedSentence('byRentTypeSentence')}`
}

const generate = ({ propertyType }: { propertyType: string }) => {
  const gt = new GeneratedText()

  if (propertyType !== PropertyTypes.COMMERCIAL) {
    const noOccupancyCommentary = getNoOccupancyCommentary()
    const partialOccupancyCommentary = getPartialOccupancyCommentary()

    gt.t`${gtIfElse('occupancyRateIs0', noOccupancyCommentary, partialOccupancyCommentary)}`
  }

  return gt.getParts()
}

export default { generate, mapDTO, mapDataModel }
