import React from 'react'
import PropTypes from 'prop-types'
import { compose, pure } from 'recompose'

import { Typography } from '@mui/material'
import { withStyles } from '@mui/styles'

const styles = theme => ({
  list: {
    paddingLeft: 16,
  },
  sublist: {
    paddingLeft: 3,
    listStyleType: 'disc',
  },
})

const FreddieMacLanguage = ({ classes, items }) => (
  <React.Fragment>
    <ol className={classes.list}>
      {items.map(({ value, subItems }, index) => (
        <li key={index}>
          <Typography variant="body1" gutterBottom>
            {value}
          </Typography>
          {subItems.length > 0 && (
            <ul className={classes.sublist}>
              {subItems.map((item, index) => (
                <li key={index}>
                  <Typography variant="body1" gutterBottom>
                    {item}
                  </Typography>
                </li>
              ))}
            </ul>
          )}
        </li>
      ))}
    </ol>
    <Typography>
      In addition, this report, or a reference to this report, may be included or quoted in any offering circular,
      information circular, offering memorandum, registration statement, private placement memorandum, prospectus, or
      sales brochure (in either electronic or hard copy format) in connection with a securitization or transaction
      involving such debt (or portion thereof) and/or securities.
    </Typography>
  </React.Fragment>
)

PropTypes.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.shape({
      item: PropTypes.arrayOf(PropTypes.string),
      subItems: PropTypes.arrayOf(PropTypes.string),
    }).isRequired
  ).isRequired,
}

export default compose(pure, withStyles(styles))(FreddieMacLanguage)
