import React from 'react'
import PropTypes from 'prop-types'
import { Table, TableHead, TableBody, TableRow, TableCell, Typography } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'

import { TableHeadRow } from '../../../../../../shared/components/TableHelpers'

import noImage from '../../../../../images/no_image_available_stand_in.svg'
import { NO_UNIT_MIX_DATA_LABEL } from '../constants'

import ImageCarousel from '../../../../../../shared/components/ImageCarousel'

import UnitMixItem from './UnitMixItem'

const NO_DATA_ROW_SPAN = 2

const styles = theme => ({
  root: {
    width: '100%',
    backgroundColor: theme.palette.secondary[200],
  },
  headerCell: {
    fontWeight: 400,
    color: '#fff',
    fontSize: 16,
    backgroundColor: theme.palette.secondary[400],
  },
  hidden: {
    height: 0,
    padding: 0,
    '&:last-child': {
      padding: 0,
    },
  },
  imageContainer: {
    padding: theme.spacing.unit * 2,
    paddingLeft: theme.spacing.unit * 3,
    maxHeight: 180,
    maxWidth: 230,
    overflow: 'hidden',
  },
  headerRowClass: {},
  row: {
    height: 50,
  },
})

class UnitMix extends React.PureComponent {
  static propTypes = {
    items: PropTypes.arrayOf(
      PropTypes.shape({
        groupingKey: PropTypes.string.isRequired,
        groupingName: PropTypes.string.isRequired,
        units: PropTypes.number.isRequired,
        avgBathrooms: PropTypes.number,
        avgRooms: PropTypes.number.isRequired,
        minRent: PropTypes.number.isRequired,
        avgRent: PropTypes.number.isRequired,
        maxRent: PropTypes.number.isRequired,
        avgRentPerRoom: PropTypes.number.isRequired,
      })
    ),
    image: PropTypes.arrayOf(PropTypes.string),
    imageWidth: PropTypes.number,
    imageHeight: PropTypes.number,
    columns: PropTypes.array.isRequired,
  }
  static defaultProps = {
    items: [],
    imageWidth: 190,
    imageHeight: 150,
  }

  get imageCell() {
    const { classes, columns, image, items, imageWidth, imageHeight } = this.props
    const rowSpan = items.length === 0 ? NO_DATA_ROW_SPAN : items.length + 1
    const colSpan = columns.length - 1
    return (
      <TableRow className={classes.hidden}>
        <TableCell padding="dense" className={classes.imageContainer} rowSpan={rowSpan}>
          <ImageCarousel images={[image]} fallbackUrl={noImage} width={imageWidth} height={imageHeight} />
        </TableCell>
        <TableCell className={classes.hidden} colSpan={colSpan} />
      </TableRow>
    )
  }

  get noDataRow() {
    const { classes, columns } = this.props
    return (
      <TableRow className={classes.row}>
        <TableCell padding="none" colSpan={columns.length - 1}>
          <Typography align="center" type="subtitle1">
            {NO_UNIT_MIX_DATA_LABEL}
          </Typography>
        </TableCell>
      </TableRow>
    )
  }

  render() {
    const { classes, items, columns, onLoad } = this.props

    return (
      <Table className={classes.root} onLoad={onLoad}>
        <TableHead>
          <TableHeadRow rowClass={classes.headerRowClass} columns={columns} cellClass={classes.headerCell} />
        </TableHead>
        <TableBody>
          {this.imageCell}
          {items.length === 0
            ? this.noDataRow
            : items.map((item, index) => (
                <UnitMixItem key={index} columns={columns} item={item} classes={{ row: classes.row }} index={index} />
              ))}
        </TableBody>
      </Table>
    )
  }
}

export default withStyles(styles)(UnitMix)
