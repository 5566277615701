import { getPartsFromString } from '../../../../narrative/GeneratedText'

const generate = () => {
  const incomeCapitalizationApproachMethodology = `The Income Capitalization Approach is a strong indicator of
    value when market rents, vacancy rates, stabilized expenses,
    and/or capitalization/discount rates are based on reliable market data.
    In this case, given the depth of the market, there are numerous transactions
    from which to glean points of analysis, lending credibility to the results
    of the approach.`
  const gtParts = getPartsFromString(incomeCapitalizationApproachMethodology)

  return gtParts
}

export default { generate }
