import { escapeRegExp, trim } from 'lodash'

import { buildSlateWriteup } from 'shared/utils/textGeneration/writeupBuilder'
import { contentReuseTemplate } from 'client-shared/types'

export const searchTemplates = (searchQuery: string | undefined, templates: contentReuseTemplate[]) => {
  const trimmedSearchQuery = trim(searchQuery)
  if (trimmedSearchQuery.length === 0) {
    return templates
  }

  const regex = new RegExp(escapeRegExp(trimmedSearchQuery), 'i')

  const matchingTemplates = templates.filter(template => {
    if (regex.test(template.title) || regex.test(template.author.fullName) || regex.test(template.author.username)) {
      return true
    }

    const narrativeString = buildSlateWriteup({ narrative: template.data }).join(' ')
    return regex.test(narrativeString)
  })

  return matchingTemplates
}

export const formatTime = (time: number) => {
  const now = new Date().getTime()
  const date = new Date(time).getTime()
  const diffMilliseconds = now - date

  const oneDayInMilliseconds = 24 * 60 * 60 * 1000
  const oneWeekInMilliseconds = 7 * oneDayInMilliseconds

  const lessThanOneDay = diffMilliseconds < oneDayInMilliseconds
  const moreThanOneWeek = diffMilliseconds > oneWeekInMilliseconds

  if (lessThanOneDay) {
    return new Date(time)
      .toLocaleTimeString('en-US', {
        hour: '2-digit',
        minute: '2-digit',
      })
      .toLowerCase()
  }

  if (moreThanOneWeek) {
    const [{ value: month }, , { value: day }, , { value: year }] = new Intl.DateTimeFormat('en-US', {
      year: '2-digit',
      month: '2-digit',
      day: '2-digit',
    }).formatToParts(time)

    return `${year}/${month}/${day}`
  }

  return new Date(time).toLocaleDateString('en-US', { weekday: 'long' })
}
