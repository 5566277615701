import React from 'react'

import { Accordion, AccordionDetails, AccordionSummary, Typography } from '@mui/material'
import ExpandMore from '@mui/icons-material/ExpandMore'

import { CheckboxWithLabel } from 'client-shared/components/Checkbox'

const IncludeMethodsOfAnalyses = () => (
  <Accordion data-qa="include-methods-of-analyses-section" defaultExpanded={true}>
    <AccordionSummary data-qa="include-methods-of-analyses-title" expandIcon={<ExpandMore />}>
      Include The Following In The Report
    </AccordionSummary>
    <AccordionDetails>
      <Typography variant="body2">
        Selecting / unselecting these options will also reflect on the sections below.
      </Typography>
      <CheckboxWithLabel name="bandOfInvestment.includeInExport" label="Band of Investment" />
      <CheckboxWithLabel name="personalSurveys.includeInExport" label="Personal Surveys" />
    </AccordionDetails>
  </Accordion>
)

export default IncludeMethodsOfAnalyses
