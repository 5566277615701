import React from 'react'

import arrayMutators from 'final-form-arrays'
import { Stack } from '@mui/material'
import { get, isEmpty } from 'lodash'

import * as Socket from 'client-shared/utils/socket'
import wrapForm from 'report/forms/wrapForm'

import { BUILDING_FACADE } from 'client-shared/constants/imageCategories'

import { PROPERTY_PHOTOS_PATH } from 'shared/constants/report/keysAndDataPaths'

import CategoriesWrapper from './CategoriesWrapper'
import CustomCategoriesList from './CustomCategoriesList'
import DefaultCategoriesList from './DefaultCategoriesList'
import DragDropWrapper from './DragDropWrapper'
import { mapPropertyPhotos } from './helpers'

const DATA_PATH = PROPERTY_PHOTOS_PATH

class PropertyPhotos extends React.PureComponent {
  uneditableCategories = []

  componentDidMount() {
    // defaulting to none so the event id always has a proper format
    const reportId = get(this.props, 'reportId', 'none')
    Socket.on(`propertyPhotos:uploaded:${reportId}`, this.onUploadedPhotos)
    if (!this.props.isBlocks) {
      this.uneditableCategories = [BUILDING_FACADE.categoryName]
    }
  }

  componentWillUnmount() {
    // defaulting to none so the event id always has a proper format
    const reportId = get(this.props, 'reportId', 'none')
    Socket.off(`propertyPhotos:uploaded:${reportId}`, this.onUploadedPhotos)
  }

  onUploadedPhotos = data => {
    const { form } = this.props

    const { defaultCategories, customCategories } = data

    form.batch(() => {
      form.change('defaultCategories', defaultCategories)
      form.change('customCategories', customCategories)
    })
  }

  getDefaultCategories = () => {
    const categories = get(
      this.props,
      'form.values.defaultCategories',
      get(this.props, 'properties.defaultCategories', [])
    )

    return categories.map(mapPropertyPhotos)
  }

  getCustomCategories = () => {
    const categories = get(
      this.props,
      'form.values.customCategories',
      get(this.props, 'properties.customCategories', [])
    )

    return categories.map(({ category, photos }, index) => ({
      index,
      hasImages: !isEmpty(photos),
      category,
    }))
  }

  render() {
    const { form, currentPath } = this.props

    const defaultCategories = this.getDefaultCategories()
    const customCategories = this.getCustomCategories()

    return (
      <Stack spacing={3} width="calc(99vw - 440px)">
        <DragDropWrapper formMutators={form.mutators}>
          <CategoriesWrapper title="DEFAULT PHOTOS">
            <DefaultCategoriesList
              categories={defaultCategories}
              changeCategory={form.change}
              name="defaultCategories"
              uneditableCategories={this.uneditableCategories}
              currentPath={currentPath}
            />
          </CategoriesWrapper>
          <CategoriesWrapper title="CUSTOM PHOTOS">
            <CustomCategoriesList
              addCategory={form.mutators.push}
              categories={customCategories}
              changeCategory={form.change}
              removeCategory={form.mutators.remove}
            />
          </CategoriesWrapper>
        </DragDropWrapper>
      </Stack>
    )
  }
}

export default wrapForm(
  DATA_PATH,
  {
    heading: 'Photos',
    mutators: {
      ...arrayMutators,
    },
  },
  state => {
    const currentPath = get(state, 'shared.location.form.id')
    const isBlocks = get(state, 'report.reportSettings.isBlocks')

    return {
      reportId: get(state, 'report.reportData._id'),
      currentPath,
      isBlocks,
    }
  }
)(PropertyPhotos)
