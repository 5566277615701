import { GeneratedText, gtLockedSentence } from '../../../narrative/GeneratedText'

import { mapDTO, mapDataModel } from './mappings'

const generate = () => {
  const gt = new GeneratedText()

  gt.addPart(gtLockedSentence('commonSystemsParagraph'))
  gt.addPart(gtLockedSentence('heatingOnlySystemsParagraph'))
  gt.addPart(gtLockedSentence('coolingOnlySystemsParagraph'))

  return gt.getParts()
}

export default { generate, mapDTO, mapDataModel }
