import { GeneratedText, gtLocked } from '../../../narrative/GeneratedText'

import { mapDTO, mapDataModel } from './mappings'

const generate = () => {
  const gt = new GeneratedText()
  const conditionGt = gtLocked('condition', null, 'General Condition')
  const yearBuildGt = gtLocked('yearBuilt')

  gt.t`The subject was constructed in ${yearBuildGt} and based on our inspection,
    the subject is ${conditionGt}. The building features a functional design with typical site coverage.
    No significant functional obsolescence was noted during our inspection.
    The layout, site coverage of the improvements and positioning of the improvements
    as well as the construction quality of the improvements, interior layout, building mechanicals,
    building-wide and unit amenities and finishes are functional by market standards.`

  return gt.getParts()
}
export default { generate, mapDTO, mapDataModel }
