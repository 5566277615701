import { get } from 'lodash'
import { contentReuseTemplate } from 'client-shared/types'

type metadataType = {
  metadata: {
    type: string
  }
}

export type createNarrativeTemplatePayload = Omit<contentReuseTemplate, 'author' | 'updatedAt'> & metadataType

export type setContentLibraryPayload = {
  name: string
  activeTemplateName?: string
  defaultTemplate: contentReuseTemplate
}

export type setContentLibraryReceivePayload = contentReuseTemplate[]

export type selectNarrativeTemplatePayload = contentReuseTemplate | null

export type Payload = setContentLibraryReceivePayload | selectNarrativeTemplatePayload | setContentLibraryPayload

export const isSetContentLibraryPayload = (payload: Payload): payload is setContentLibraryPayload => {
  return get(payload, 'name') !== undefined && isContentReuseTemplate(get(payload, 'defaultTemplate'))
}

export const isSetContentLibraryReceivePayload = (payload: Payload): payload is setContentLibraryReceivePayload => {
  return (
    Array.isArray(payload) &&
    (payload.length === 0 || payload.every(element => get(element, 'title') && get(element, 'name')))
  )
}

export const isSelectNarrativeTemplatePayload = (payload: Payload): payload is selectNarrativeTemplatePayload => {
  return (get(payload, 'name') !== undefined && get(payload, 'data') !== undefined) || payload === null
}

export const isContentReuseTemplate = (payload: unknown): payload is contentReuseTemplate => {
  return (
    typeof payload === 'object' &&
    payload !== null &&
    typeof get(payload, 'title') === 'string' &&
    typeof get(payload, 'name') === 'string' &&
    typeof get(payload, 'metadata') === 'object' &&
    typeof get(payload, 'data') === 'object' &&
    typeof get(payload, 'author') === 'object'
  )
}
