import { get, isNil, isEmpty } from 'lodash'

import BoweryDate from '@bowery-valuation/bowery-date'

// TODO: move the util outside of client
import { GEOGRAPHY_OPTIONS } from 'shared/constants/property/geography'

import { getLocationIdentifier } from 'shared/utils/report/propertyInformation'

import { formatList } from '../../../../../client/src/shared/utils/textHelpers'

import { TEMPLATE_TYPES } from '../../../../../client/src/report/constants/settings'

import { DATE_FORMAT } from '../../../../constants/acas'

export const mapDTO = formValues => {
  const { appraisers, locationIdentifier, inspectionDate, reportTemplate } = formValues
  const isFreddieMac = !isNil(reportTemplate) && reportTemplate === TEMPLATE_TYPES.FREDDIE_MAC
  const isAppraiserCertifiedInStateAndInspected = (appraiser, reportState) => {
    const appraiserCerts = get(appraiser, 'appraisalCertifications') || []
    if (isEmpty(appraiserCerts)) {
      return false
    }
    const selectedCertificationState = get(appraiser, 'certification.state')
    return selectedCertificationState === reportState && appraiser.isInspected
  }

  const reportInspectors =
    appraisers
      .filter(appraiser =>
        isFreddieMac ? isAppraiserCertifiedInStateAndInspected(appraiser, locationIdentifier) : appraiser.isInspected
      )
      .map(appraiser => appraiser.fullName) || []
  const isNYLocationIdentifier = locationIdentifier === GEOGRAPHY_OPTIONS.NY
  const isOtherGeographyLocationIdentifier = locationIdentifier !== GEOGRAPHY_OPTIONS.NY
  return {
    inspectionDate: inspectionDate ? new BoweryDate(inspectionDate).format(DATE_FORMAT) : '',
    isNYLocationIdentifier,
    isOtherGeographyLocationIdentifier,
    reportInspectors: formatList(reportInspectors),
  }
}

export const mapDataModel = report => {
  const reportTemplate = get(report, 'templateType', null)
  const isFreddieMac = !isNil(reportTemplate) && reportTemplate === TEMPLATE_TYPES.FREDDIE_MAC

  const state = get(report, 'property_information.state')
  const zip = get(report, 'property_information.zip')
  const locationIdentifier = getLocationIdentifier(state, zip)

  const isAppraiserCertifiedInState = (appraiser, reportState) => {
    const appraiserCerts = get(appraiser, 'appraiser.appraisalCertifications') || []
    if (isEmpty(appraiserCerts)) {
      return false
    }
    const selectedCertificationState = get(appraiser, 'certification.state')
    return selectedCertificationState === reportState
  }

  const boweryAppraisers = get(report, 'organizationInfo.appraisers', [])
    .filter(appraiser =>
      isFreddieMac
        ? isAppraiserCertifiedInState(appraiser, locationIdentifier) && appraiser.performedInspection
        : appraiser.performedInspection
    )
    .map(appraiser => appraiser.appraiser.fullName)

  const externalInspectors = get(report, 'external_inspectors', [])
    .filter(appraiser =>
      isFreddieMac
        ? isAppraiserCertifiedInState(appraiser, locationIdentifier) && appraiser.isInspected
        : appraiser.isInspected
    )
    .map(appraiser => appraiser.fullName)

  const reportInspectors = [...boweryAppraisers, ...externalInspectors]

  const inspectionDate = get(report, 'inspection.inspectionDate')
  const isNYLocationIdentifier = locationIdentifier === GEOGRAPHY_OPTIONS.NY
  const isOtherGeographyLocationIdentifier = locationIdentifier !== GEOGRAPHY_OPTIONS.NY

  const result = {
    inspectionDate: inspectionDate ? new BoweryDate(inspectionDate).format(DATE_FORMAT) : '',
    isNYLocationIdentifier,
    isOtherGeographyLocationIdentifier,
    reportInspectors: formatList(reportInspectors),
  }
  return result
}
