import React from 'react'
import PropTypes from 'prop-types'

import ArrowCircleDown from '@mui/icons-material/ArrowCircleDown'
import InfoOutlined from '@mui/icons-material/InfoOutlined'
import { Box, IconButton, TableCell, TableRow, Tooltip } from '@mui/material'
import { Link } from 'react-router-dom'
import { isNil, get, invert } from 'lodash'

import { fromPercents, toPercents } from 'client-shared/utils/numberOperations'
import { formatCurrencyFloat } from 'client-shared/utils/numberFormatters'
import { LOSS_ITEM_KEYS, LOSS_ITEM_VALUE_TYPE } from 'shared/constants/acas'
import { Template } from 'client-shared/components/Template'

import TableNumberField from '../Table/TableNumberRightAligned'

import { LOSS_ITEM_DATA_QA } from './constants'

class LossItemTableRow extends React.PureComponent {
  getTooltipTitle = lossItem => {
    const { epTotal } = this.props
    const dollarValue = lossItem.amount * epTotal

    const entrepreneurialProfitPercentValue = toPercents(lossItem.amount)
    const renovationBudgetDollarValue = formatCurrencyFloat(epTotal)
    const entrepreneurialProfitDollarValue = formatCurrencyFloat(dollarValue)

    return (
      `Entrepreneurial profit is equal to ${entrepreneurialProfitPercentValue}% ` +
      `of the sum of all of the line items above, ${renovationBudgetDollarValue}, which totals ` +
      `to ${entrepreneurialProfitDollarValue}`
    )
  }

  render() {
    const { index, name, fillDownLossPeriod, hasMonths, lossItem, isEditable } = this.props

    let amountPrefix = '$'
    if (lossItem.amount !== 0) {
      amountPrefix = `-${amountPrefix}`
    }

    let copyDownDisabled = true
    const months = get(lossItem, 'months')

    if (!isNil(months) && months > 0) {
      copyDownDisabled = false
    }
    const lossItemNameKey = invert(LOSS_ITEM_KEYS)[lossItem.name]
    const cellDataQA = `${name.split('[')[0]}.${LOSS_ITEM_DATA_QA[lossItemNameKey]}`
    const amountCellDataQA = `${cellDataQA}.amount-cell`
    const renovationPeriodCellDataQA = `${cellDataQA}.renovation-period-cell`
    return (
      <TableRow id={name} ref={this.rowRef}>
        <TableCell data-qa={`${name}.cell`}>
          {lossItem.name === LOSS_ITEM_KEYS.RENOVATION_BUDGET ? (
            <Link to={{ pathname: './renovations', state: { skipConfirmation: false } }}>{lossItem.name}</Link>
          ) : (
            <span>Less {lossItem.name}</span>
          )}
        </TableCell>
        <TableCell align="right" data-qa={renovationPeriodCellDataQA}>
          <Template is="auto-flow / 120px" placeItems="center start" show={hasMonths} sx={{ position: 'relative' }}>
            <TableNumberField
              adornment="months"
              allowNegative={false}
              autoComplete="off"
              disabled={!isEditable}
              margin="none"
              maxLength={3}
              name={`${name}.months`}
              noPx
              noPy
              placeholder="0"
              right
            />
            {index === 0 && isEditable && (
              <Tooltip title="Copy Down" placement="top">
                <Box sx={{ position: 'absolute', left: 108 }}>
                  <IconButton data-qa="copy-to-all" disabled={copyDownDisabled} onClick={fillDownLossPeriod}>
                    <ArrowCircleDown color="info" fontSize="small" />
                  </IconButton>
                </Box>
              </Tooltip>
            )}
          </Template>
        </TableCell>
        {lossItem.name === LOSS_ITEM_KEYS.ENTREPRENEURIAL_PROFIT && (
          <TableCell align="right" data-qa={amountCellDataQA}>
            <Template is="auto-flow / auto auto" placeContent="end" placeItems="center" sx={{ position: 'relative' }}>
              <TableNumberField
                allowNegative={false}
                autoComplete="off"
                decimalScale={2}
                disabled={!isEditable}
                format={toPercents}
                margin="none"
                name={`${name}.amount`}
                noPx
                noPy
                normalize={fromPercents}
                placeholder="0%"
                right
                suffix="%"
                thousandSeparator
              />
              <Box sx={{ position: 'absolute', right: -24, top: 0 }}>
                <Tooltip title={this.getTooltipTitle(lossItem)} placement="top">
                  <InfoOutlined fontSize="small" />
                </Tooltip>
              </Box>
            </Template>
          </TableCell>
        )}
        {lossItem.name !== LOSS_ITEM_KEYS.ENTREPRENEURIAL_PROFIT && (
          <TableCell align="right" data-qa={amountCellDataQA}>
            <TableNumberField
              allowNegative={false}
              autoComplete="off"
              decimalScale={2}
              disabled={
                !isEditable ||
                LOSS_ITEM_VALUE_TYPE.USER_ENTERED !== lossItem.type ||
                lossItem.name === LOSS_ITEM_KEYS.RENOVATION_BUDGET
              }
              margin="none"
              name={`${name}.amount`}
              noPx
              noPy
              normalize={Math.abs}
              placeholder="$0.00"
              prefix={amountPrefix}
              right
              thousandSeparator
            />
          </TableCell>
        )}
        <TableCell />
        <TableCell />
      </TableRow>
    )
  }
}

LossItemTableRow.propTypes = {
  fillDownLossPeriod: PropTypes.func.isRequired,
  hasMonths: PropTypes.bool.isRequired,
  index: PropTypes.number.isRequired,
  name: PropTypes.string.isRequired,
}

export default LossItemTableRow
