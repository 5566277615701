import React from 'react'
import PropTypes from 'prop-types'

import { Typography } from '@mui/material'
import { withStyles } from '@mui/styles'

const styles = {
  emptyContent: {
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
}

const EmptySection = ({ classes, title }) => {
  return (
    <div className={classes.emptyContent}>
      <Typography variant="body1">{title}</Typography>
    </div>
  )
}

EmptySection.propTypes = {
  classes: PropTypes.object.isRequired,
  title: PropTypes.string.isRequired,
}

export default withStyles(styles)(EmptySection)
