import './config.js'
import React from 'react'
import { render } from 'react-dom'
import Mousetrap from 'mousetrap'

import 'typeface-roboto'
import './fixLeaflet'

import { initReportForms } from 'report/constants/forms/reportFormsInstance'
import reportForms from 'report/constants/forms/reportForms'

import App from './App'

Mousetrap.prototype.stopCallback = function () {
  return false
}

initReportForms(reportForms)

render(<App />, document.getElementById('root'))
