import React from 'react'
import PropTypes from 'prop-types'

import { isEmpty } from 'lodash'
import { Dialog } from '@mui/material'

import {
  AS_COMPLETE_AFFECTED_PAGES,
  AS_STABILIZED_AFFECTED_PAGES,
  INCOME_TYPE_AFFECTED_PAGES,
  TEMPLATE_TYPE_AFFECTED_PAGES,
  VALUE_CONCLUSION_TYPES,
} from '../../../constants/settings'

import { ConfirmationStep, SettingsStep } from './Steps'

const STEPS = {
  CONFIRMATION: 'CONFIRMATION',
  SETTINGS: 'SETTINGS',
}

class ReportSettingsModal extends React.PureComponent {
  state = {
    affectedPages: [],
    step: STEPS.SETTINGS,
  }

  get initialValues() {
    const { incomeType, valueConclusionType, templateType, blocksConfiguration, isBlocks, approaches } = this.props
    return {
      settings: { incomeType, valueConclusionType, templateType, blocksConfiguration, isBlocks, approaches },
    }
  }

  get affectedPages() {
    const { initialValues, values, dirtyFields } = this.props
    const affectedPages = []
    if (dirtyFields['settings.templateType']) {
      affectedPages.push(...TEMPLATE_TYPE_AFFECTED_PAGES)
    }

    if (dirtyFields['settings.incomeType'] && INCOME_TYPE_AFFECTED_PAGES.hasOwnProperty(values.settings.incomeType)) {
      affectedPages.push(...INCOME_TYPE_AFFECTED_PAGES[values.settings.incomeType])
    }

    if (dirtyFields['settings.valueConclusionType']) {
      const asCompleteChanges = [
        initialValues.settings.valueConclusionType,
        values.settings.valueConclusionType,
      ].includes(VALUE_CONCLUSION_TYPES.AS_COMPLETE)

      if (asCompleteChanges) {
        affectedPages.push(...AS_COMPLETE_AFFECTED_PAGES)
      } else {
        affectedPages.push(...AS_STABILIZED_AFFECTED_PAGES)
      }
    }

    return affectedPages
  }

  onConfirmationCancel = () => {
    this.setState({
      step: STEPS.SETTINGS,
    })
  }

  onSaveSettings = () => {
    const { onSubmit } = this.props
    if (isEmpty(this.affectedPages)) {
      onSubmit()
    } else {
      this.setState({
        affectedPages: this.affectedPages,
        step: STEPS.CONFIRMATION,
      })
    }
  }

  render() {
    const { onClose, onSubmit, pristine, values } = this.props
    const { affectedPages, step } = this.state
    const { settings } = values

    const salesApproachSelected = settings?.approaches?.improvedSales || settings?.approaches?.land
    const disableSaveButton = pristine || !salesApproachSelected
    return (
      <Dialog open={true} onClose={onClose} maxWidth="md" aria-labelledby="form-dialog-title">
        {step === STEPS.CONFIRMATION && (
          <ConfirmationStep onCancel={this.onConfirmationCancel} affectedPages={affectedPages} onSubmit={onSubmit} />
        )}
        {step === STEPS.SETTINGS && (
          <SettingsStep onCancel={onClose} onSave={this.onSaveSettings} disableSaveButton={disableSaveButton} />
        )}
      </Dialog>
    )
  }
}

ReportSettingsModal.propTypes = {
  dirtyFields: PropTypes.object.isRequired,
  initialValues: PropTypes.object.isRequired,
  onClose: PropTypes.func.isRequired,
  onReset: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  pristine: PropTypes.bool,
  values: PropTypes.object.isRequired,
}

ReportSettingsModal.defaultProps = {
  pristine: false,
}

ReportSettingsModal.displayName = 'ReportSettingsModal'

export default ReportSettingsModal
