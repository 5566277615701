import { handleActions } from 'redux-actions'

import { contentReuseTemplate } from 'client-shared/types'
import { replaceByIndex } from 'client-shared/utils/immutability'

import {
  selectNarrativeTemplate,
  setContentLibrary,
  setContentLibraryReceive,
  reloadContentLibrary,
} from '../actions/contentLibraryData'
import {
  Payload,
  setContentLibraryReceivePayload,
  isSetContentLibraryPayload,
  isSetContentLibraryReceivePayload,
  isSelectNarrativeTemplatePayload,
  isContentReuseTemplate,
} from '../types/contentLibraryData'

export type State = {
  templates: setContentLibraryReceivePayload
  activeTemplateName: string | null
  selectedTemplate: contentReuseTemplate | null
}

const initialState: State = {
  templates: [],
  activeTemplateName: null,
  selectedTemplate: null,
}

export const reducer = handleActions<State, Payload>(
  {
    [setContentLibrary.toString()](state, { payload }) {
      if (!isSetContentLibraryPayload(payload)) {
        return state
      }

      const defaultTemplate = payload.defaultTemplate
      return {
        ...initialState,
        templates: [defaultTemplate],
        activeTemplateName: payload.activeTemplateName || defaultTemplate.name,
      }
    },
    [setContentLibraryReceive.toString()](state, { payload }) {
      if (!isSetContentLibraryReceivePayload(payload)) {
        return state
      }

      const builtTemplates = [...payload]

      if (state.templates.length > 0 && state.templates[0].metadata.form === payload[0].metadata.form) {
        builtTemplates.unshift(state.templates[0])
      }

      const data = {
        ...state,
        templates: builtTemplates,
        selectedTemplate: null,
      }

      return data
    },
    [selectNarrativeTemplate.toString()](state, { payload }) {
      if (!isSelectNarrativeTemplatePayload(payload)) {
        return state
      }

      const data = {
        ...state,
        activeTemplateName: payload?.name || state.activeTemplateName,
        selectedTemplate: payload,
      }

      return data
    },
    [reloadContentLibrary.toString()](state, { payload }) {
      if (!isContentReuseTemplate(payload)) {
        return state
      }

      const itemIndex = state.templates.findIndex(template => {
        return template.name === payload.name
      })

      if (state.templates.length === 0 || itemIndex < 0) {
        return {
          ...state,
          templates: [payload, ...state.templates],
        }
      }

      return {
        ...state,
        templates: replaceByIndex(state.templates, payload, itemIndex),
      }
    },
  },
  initialState
)
