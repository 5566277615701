import { STATE_NAMES } from 'shared/constants/states'
import { GEOGRAPHY_OPTIONS } from 'shared/constants/property/geography'

export const PropertySubtypes = {
  GARDEN_STYLE: 'Garden Style',
  LOW_RISE: 'Low-Rise',
  MID_RISE: 'Mid-Rise',
  HIGH_RISE: 'High-Rise',
}

export const PROPERTY_SEARCH_STRATEGY = {
  NY_ADDRESS: 'NY_ADDRESS',
  BBL: 'BBL',
  NJ_ADDRESS: 'NJ_ADDRESS',
  BLQ: 'BLQ',
  OTHER_ADDRESS: 'OTHER_ADDRESS',
  OTHER_ADDRESS_IDENTIFIER: 'OTHER_ADDRESS_ID',
}

export const identifierColumnsNJ = [
  { title: 'Block', name: 'block' },
  { title: 'Lot', name: 'lot' },
  { title: 'Qualifier', name: 'qual' },
]
export const identifierColumnsNYC = [
  { title: 'Borough', name: 'borough' },
  { title: 'Block', name: 'block' },
  { title: 'Lot', name: 'lot' },
]

export const identifierColumnsOther = [{ title: 'Unique Property Identifier', name: 'propertyIdentifier' }]

export const getIdentifiers = locationIdentifier => {
  switch (locationIdentifier) {
    case GEOGRAPHY_OPTIONS.NJ:
      return identifierColumnsNJ
    case GEOGRAPHY_OPTIONS.NY:
      return identifierColumnsNYC
    default:
      return identifierColumnsOther
  }
}

export const SEARCH_OPTIONS = {
  ADDRESS: 'address',
  BBL: 'bbl',
  BLQ: 'blq',
  UNIQUE_IDENTIFER: 'uniqueId',
}

export const LOCATION_TYPE_OPTIONS = {
  BlockLotBorough: 'BlockLotBorough',
  DistrictBlockLot: 'DistrictBlockLot',
  Other: 'Other',
}

export const GEOGRAPHY_OPTIONS_LIST = Object.values(GEOGRAPHY_OPTIONS)

export const GEOGRAPHY_OPTIONS_LABEL_VALUE_PAIR = [
  { label: STATE_NAMES.NY, value: STATE_NAMES.NY },
  { label: STATE_NAMES.NJ, value: STATE_NAMES.NJ },
  { label: 'Other', value: 'Other' },
]

export const PROPERTY_SEARCH_STEPS = {
  BASIC_SEARCH: 'Basic Search',
  SEARCH_RESULTS: 'Search Results',
  ADVANCED_SEARCH: 'Advanced Search',
  SOURCE_INFORMATION: 'Additional Information',
  COMMERCIAL_COMP: 'Commercial Unit Details',
}

export const LABELS = {
  ADVANCED_SEARCH: 'Advanced Search',
  BACK_TO_SEARCH: 'Back to Search',
  NEXT: 'Next',
  SEARCH: 'Search',
  STANDARD_SEARCH: 'Standard Search',
  SELECT_COMP: 'Select',
  CANCEL: 'Cancel',
}

export const WIZARD_STEPS = {
  BASIC_SEARCH: 'Basic Search',
  ADVANCED_SEARCH: 'Advanced Search',
  SEARCH_RESULTS: 'Search Results',
  PROPERTY_DETAILS: 'Property Details',
  ADDITIONAL_INFO: 'Additional Info',
  COMPPLEX_ADDRESS_SEARCH: 'Address Search Results',
}

export {
  /** @deprecated Prefer importing this from the shared folder */
  GEOGRAPHY_OPTIONS,
}
