import { GeneratedText } from '../../../../narrative/GeneratedText'

export const FINAL_ESTIMATE_SUMMARY =
  "In order to fully compare the MVS costs to the total development budget and the construction cost comparables we forecast the subject's indirect costs.  To local market typically reflects an indirect cost range of 3% to 10%  of the direct costs. The following table is a summary of the MVS direct costs, demolition costs (if applicable), plus any our estimate of indirect or other additional costs:"

const generate = () => {
  const gt = new GeneratedText()

  gt.t`${FINAL_ESTIMATE_SUMMARY}`

  return gt.getParts()
}

export default { generate }
