import createDecorator from 'final-form-calculate'

import { PropertyTypes } from 'shared/constants'
import { getHasSoldWithinThreeYears } from 'shared/helpers/property/salesHistory'

import { orderSalesHistoryByDate } from '../../../../../../shared/utils/report/propertyHistory'
import { SiteAreaValues, SF_TO_ACRE_RATIO } from '../../../constants'

export const hasSoldWithinThreeYearsDecorator = createDecorator({
  field: /salesHistory\.\d+.date/,
  updates: {
    hasSoldWithinThreeYears: (value, allValues) => {
      const salesHistory = allValues.salesHistory
      if (!salesHistory.length) {
        return false
      }
      const orderedSalesHistory = orderSalesHistoryByDate(salesHistory)
      return getHasSoldWithinThreeYears(orderedSalesHistory)
    },
  },
})

export const orderedSalesHistoryDecorator = createDecorator({
  field: 'salesHistory',
  updates: {
    orderedSalesHistory: (value, allValues) => {
      const salesHistory = allValues.salesHistory
      return orderSalesHistoryByDate(salesHistory)
    },
  },
})

export const siteAreaDecorator = createDecorator({
  field: ['siteArea', 'siteAreaMeasure', 'displayInAcresAndSF'],
  updates: (value, fieldName, allValues) => {
    if (!!allValues.displayInAcresAndSF) {
      const siteArea = allValues.siteArea
      const siteAreaMeasureIsSF = allValues.siteAreaMeasure === SiteAreaValues.SF
      const altSiteAreaMeasure = siteAreaMeasureIsSF ? SiteAreaValues.ACRE : SiteAreaValues.SF
      const altSiteArea = siteAreaMeasureIsSF ? siteArea * SF_TO_ACRE_RATIO : siteArea / SF_TO_ACRE_RATIO
      return {
        altSiteArea,
        altSiteAreaMeasure,
      }
    }
    return {
      altSiteArea: allValues.altSiteArea,
      altSiteAreaMeasure: allValues.altSiteAreaMeasure,
    }
  },
})

export const propertyTypeDecorator = createDecorator({
  field: 'current.propertyType',
  updates: value => {
    const updates = {}

    if (value !== PropertyTypes.MULTI_FAMILY && value !== PropertyTypes.COMMERCIAL) {
      updates['current.propertySubtype'] = null
    }

    return updates
  },
})
