import { get } from 'lodash'

import { GeneratedText, gtLocked } from '../../../narrative/GeneratedText'

import { mapDTO, mapDataModel } from './mappings'

const generate = values => {
  const { orderedSalesHistory, hasSoldWithinThreeYears } = values

  const gt = new GeneratedText()

  const currentOwnerGt = gtLocked('currentOwner', null, 'Current owner')
  const locationInfoGt = gtLocked('locationInfo', 'formatStateSentence')
  const streetAddressGt = gtLocked('streetAddress', null, 'Address')

  gt.t`According to ${locationInfoGt} the owner of ${streetAddressGt} is ${currentOwnerGt}.`

  const mostRecentSaleDate = get(orderedSalesHistory, '0.date')

  if (hasSoldWithinThreeYears && mostRecentSaleDate) {
    const salePriceGt = gtLocked('orderedSalesHistory[0].price', 'formatCurrencyInt', 'Price')
    const saleDateGt = gtLocked('orderedSalesHistory[0].date', 'shortDateFormat')
    const sellerGt = gtLocked('orderedSalesHistory[0].seller', null, 'Seller')

    gt.t` The subject property has last sold for ${salePriceGt} on ${saleDateGt} `
    gt.t`from ${sellerGt} to ${currentOwnerGt}.`
  } else {
    gt.t` The subject property has, to the best of our knowledge, not sold within the past three years.`
  }

  return gt.getParts()
}

export default { generate, mapDTO, mapDataModel }
