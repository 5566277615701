export const LayoutZIndexes = {
  NAVIGATION_SIDE_BAR: 1200,
  LOADING: 1000,
  TOP_REPORT_HEADER: 1210,
  TOP_REPORT_HEADER_REPORT_INFO: 1199,
}

export const NAV_VERTICAL_TABS_COLLAPSED_WIDTH = 60
export const NAV_HEADER_HEIGHT = 60
export const HEADER_WIDTH = 250
export const HEADER_HEIGHT = 64
export const NAV_SIDE_BAR_MENU_ITEM_HEIGHT = 40
