import React, { FC } from 'react'
import { useForm } from 'react-final-form'

import rentRollUnitDistributionSummary from 'shared/utils/textGeneration/incomeApproach/residential/rentRollUnitDistributionSummary'

import { NarrativeComponent } from '../../../../../../shared/components'

const TOOLTIP_TEXT =
  "The following text will appear in the Description of Improvements' Residential Unit Distribution Summary section of your report."

type RentRollUnitDistributionSummaryProps = {
  showPerUnitSF: boolean
  basisForSFAnalysis: string
}

const RentRollUnitDistributionSummary: FC<RentRollUnitDistributionSummaryProps> = ({
  showPerUnitSF,
  basisForSFAnalysis,
}) => {
  const formApi = useForm()
  const values = formApi.getState().values
  const grossLeasableAreaPercentage = values.grossLeasableAreaPercentage || 0.5

  return (
    <NarrativeComponent
      name="rentRollUnitDistributionSummary"
      title="Residential Unit Distribution Summary"
      generatedText={rentRollUnitDistributionSummary.generate}
      data={rentRollUnitDistributionSummary.mapDTO({
        showPerUnitSF,
        basisForSFAnalysis,
        grossLeasableAreaPercentage,
      })}
      tooltipText={TOOLTIP_TEXT}
    />
  )
}

export default RentRollUnitDistributionSummary
