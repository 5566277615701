import React from 'react'
import PropTypes from 'prop-types'
import { TableCell } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'

import { TableBodyRow } from '../../../../../../shared/components/TableHelpers'
import { formatCurrencyFloat, formatInt } from '../../../../../../shared/utils/numberFormatters'

const styles = theme => ({
  row: {
    color: theme.palette.text.primary,
    fontWeight: 400,
  },
})

class UnitMixItem extends React.PureComponent {
  static propTypes = {
    item: PropTypes.shape({
      groupingKey: PropTypes.string.isRequired,
      groupingName: PropTypes.string.isRequired,
      units: PropTypes.number.isRequired,
      avgBathrooms: PropTypes.number,
      avgRooms: PropTypes.number.isRequired,
      minRent: PropTypes.number.isRequired,
      avgRent: PropTypes.number.isRequired,
      maxRent: PropTypes.number.isRequired,
      avgRentPerRoom: PropTypes.number.isRequired,
    }),
    columns: PropTypes.array,
  }

  renderCell = ({ item, column: { name } }) => {
    switch (name) {
      case 'image':
        return null
      case 'avgRent':
      case 'minRent':
      case 'maxRent':
      case 'avgRentPerRoom':
        return <TableCell padding="dense">{formatCurrencyFloat(item[name]) || '-'}</TableCell>
      case 'units':
      case 'avgRooms':
      case 'avgBathrooms':
        return <TableCell padding="dense">{formatInt(item[name]) || '-'}</TableCell>
      case 'unitGroupName':
        return <TableCell padding="dense">{item['groupingName'] || '-'}</TableCell>
      default:
        return <TableCell padding="dense">{item[name] || '-'}</TableCell>
    }
  }

  render() {
    const { item, columns, classes } = this.props

    return (
      <React.Fragment>
        <TableBodyRow rowClass={classes.row} columns={columns} cellComponent={this.renderCell} item={item} />
      </React.Fragment>
    )
  }
}

export default withStyles(styles)(UnitMixItem)
