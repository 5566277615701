import React from 'react'

import arrayMutators from 'final-form-arrays'

import PreviewAndEdit from '../PreviewAndEdit'
import wrapForm from '../../wrapForm'

import Edit from './Edit'
import Preview from './Preview'
import { FORM_HEADING, DATA_PATH } from './constants'

class Certification extends React.PureComponent {
  state = {
    isEdit: false,
  }

  onChangeMode = isEdit => this.setState({ isEdit })

  render() {
    const { form, title, section } = this.props
    const { values } = form

    return (
      <React.Fragment>
        <PreviewAndEdit title={title} section={section} onChangeMode={this.onChangeMode}>
          {isEdit =>
            isEdit ? (
              <Edit requirements={values.requirements} appraisers={values.appraisers} form={form} />
            ) : (
              <Preview
                appraisers={values.appraisers}
                requirements={[...values.requirements, ...values.additionalRequirements]}
                form={form}
              />
            )
          }
        </PreviewAndEdit>
      </React.Fragment>
    )
  }
}

export default wrapForm(DATA_PATH, {
  heading: FORM_HEADING,
  mutators: {
    ...arrayMutators,
  },
  initialValues: {
    requirements: [],
    additionalRequirements: [],
  },
})(Certification)
