import React from 'react'

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
  Typography,
} from '@mui/material'
import Close from '@mui/icons-material/Close'

const DeleteAllPhotosModal = ({ categoryToDelete, closeDialog, handleDialogDelete }) => (
  <Dialog aria-labelledby="form-dialog-title" onEscapeKeyDown={closeDialog} open={!!categoryToDelete}>
    <DialogTitle id="form-dialog-title">
      <Typography variant="h6">Delete {categoryToDelete}?</Typography>
      <IconButton
        onClick={closeDialog}
        sx={{
          position: 'absolute',
          right: 8,
          top: 8,
        }}
      >
        <Close />
      </IconButton>
    </DialogTitle>
    <DialogContent>
      <DialogContentText>All uploaded photos will be deleted as well.</DialogContentText>
    </DialogContent>
    <DialogActions>
      <Button onClick={closeDialog}>Cancel</Button>
      <Button wide variant="contained" onClick={handleDialogDelete} color="error">
        Delete
      </Button>
    </DialogActions>
  </Dialog>
)

export default DeleteAllPhotosModal
