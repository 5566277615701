import { SaleStatus } from 'shared/constants/report/sales/salesStatus'

export const CompPlexInputEvents = {
  IMPORT_CSV: 'compplex-import-csv',
  OVERRIDE_SMART_FILTERS: 'compplex-override-smart-filters',
}

export const CompPlexEvents = {
  EDIT_COMP_UPDATED: 'compplex-edit-comp-updated',
  EDIT_COMP_UPDATE_FAILED: 'compplex-edit-comp-update-failed',
  EDIT_COMP_SAVED: 'compplex-edit-comp-saved',
  EDIT_COMP_COMPLETED: 'compplex-edit-comp-completed',
  EDIT_COMP_SELECT_TOGGLED: 'compplex-edit-comp-select-toggled',
  EDIT_COMP_CLOSED: 'compplex-edit-comp-closed',
  IMPORT_COMPS_COMPLETED: 'compplex-import-comps-completed',
  IMPORT_COMPS_CLOSED: 'compplex-import-comps-closed',
  IMPORT_CSV_COMPLETED: 'compplex-import-csv-completed',
  MAP_COMP_ADDED: 'compplex-map-comp-added',
  MAP_COMP_REMOVED: 'compplex-map-comp-removed',
  MAP_SEARCH_RESULTS_UPDATED: 'compplex-map-search-results-updated',
  ERROR: 'compplex-error',
  GOOGLE_ADDRESS_SEARCHED: 'google-address-searched',
}

export const CompPlexCommercialLeaseEvents = {
  EDIT_COMP_SAVED: 'compplex-crc-edit-comp-saved',
  EDIT_COMP_UPDATED: 'compplex-crc-edit-comp-updated',
  EDIT_COMP_CLOSED: 'compplex-crc-edit-comp-closed',
  FOUND_COMP_ADDED: 'compplex-crc-found-comp-added',
  MAP_COMP_ADDED: 'compplex-crc-map-comp-added',
  MAP_COMP_REMOVED: 'compplex-crc-map-comp-removed',
  MAP_EDIT_MODAL_OPENED: 'compplex-crc-map-edit-modal-opened',
  MAP_EDIT_MODAL_CLOSED: 'compplex-crc-map-edit-modal-closed',
}

export const CompPlexResidentialLeaseEvents = {
  EDIT_COMP_SAVED: 'compplex-rrc-edit-comp-saved',
  EDIT_COMP_UPDATED: 'compplex-rrc-edit-comp-updated',
  EDIT_COMP_CLOSED: 'compplex-rrc-edit-comp-closed',
  FOUND_COMP_ADDED: 'compplex-rrc-found-comp-added',
  MAP_COMP_ADDED: 'compplex-rrc-map-comp-added',
  MAP_COMP_REMOVED: 'compplex-rrc-map-comp-removed',
  MAP_EDIT_MODAL_OPENED: 'compplex-rrc-map-edit-modal-opened',
  MAP_EDIT_MODAL_CLOSED: 'compplex-rrc-map-edit-modal-closed',
}

export const CompPlexExpenseRecordsEvents = {
  EXPENSE_RECORD_ADDED: 'expense-record-added',
  EXPENSE_RECORD_UPDATED: 'expense-record-updated',
  EXPENSE_RECORD_CANCELED: 'expense-record-canceled',
}

export const SALE_STATUS_OPTIONS = [
  { label: 'Listing', value: SaleStatus.LISTING },
  { label: 'Under Contract', value: SaleStatus.UNDER_CONTRACT },
  { label: 'Transaction', value: SaleStatus.TRANSACTION },
]
