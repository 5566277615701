import React from 'react'
import PropTypes from 'prop-types'

import { noop } from 'lodash'

import { TableCell } from '@mui/material'

import Text from 'client-shared/components/Table/TableText'
import { DraggableTableRowV2 } from 'client-shared/components/DragDropTable'

class ItemRow extends React.Component {
  setInputRef = ref => {
    const { setInputRef, index } = this.props
    setInputRef(index, ref)
  }

  onKeyPress = event => {
    const { onInsertItem, listName, index } = this.props

    if (event.key === 'Enter') {
      onInsertItem(listName, index + 1)
      event.preventDefault()
    }
  }

  onRemoveItem = () => {
    const { onRemoveItem, listName, index } = this.props
    onRemoveItem(listName, index)
  }

  render() {
    const { canRemoveItem, index, isDragging, name, placeholder } = this.props
    const canDelete = canRemoveItem(name)
    return (
      <DraggableTableRowV2
        canDelete={canDelete}
        draggingState={isDragging}
        id={name}
        index={index}
        onRemove={this.onRemoveItem}
        singleValue={false}
      >
        <TableCell>
          <Text
            data-qa={`input-${index}`}
            inputRef={this.setInputRef}
            multiline
            name={name}
            onKeyPress={this.onKeyPress}
            placeholder={placeholder}
            rowsMax={Infinity}
          />
        </TableCell>
      </DraggableTableRowV2>
    )
  }
}

ItemRow.propTypes = {
  canRemoveItem: PropTypes.func,
  index: PropTypes.number.isRequired,
  isDragging: PropTypes.bool.isRequired,
  listName: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  onInsertItem: PropTypes.func.isRequired,
  onRemoveItem: PropTypes.func.isRequired,
  placeholder: PropTypes.string.isRequired,
  setInputRef: PropTypes.func.isRequired,
}

ItemRow.defaultProps = {
  canRemoveItem: noop,
}

export default ItemRow
