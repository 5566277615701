import React from 'react'
import { compose } from 'recompose'

import wrapForm from '../../../wrapForm'

import { DATA_PATH } from './constants'
import { expenseStructureDecorator } from './decorators'
import ResidentialExpenseStructure from './ResidentialExpenseStructure'

const formOptions = {
  initialValues: {},
  decorators: [expenseStructureDecorator],
}

export default compose(wrapForm(DATA_PATH, formOptions), React.memo)(ResidentialExpenseStructure)
