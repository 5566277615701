import { select, put, takeLatest } from 'redux-saga/effects'

import { clientsFetchRequest, clientsFetchReceive, clientsFetchFail } from '../actions/clients'
import { CLIENTS_FETCH } from '../actionTypes/clients'
import * as Api from '../../../core/api'

function* fetchClients() {
  try {
    const clientsLoaded = yield select(state => state.clients.isLoaded)

    if (!clientsLoaded) {
      yield put(clientsFetchRequest())
      const clients = yield Api.fetchClients()
      yield put(clientsFetchReceive(clients))
    }
  } catch (err) {
    yield put(clientsFetchFail(err))
  }
}

export default [takeLatest(CLIENTS_FETCH.ACTION, fetchClients)]
