import React from 'react'
import PropTypes from 'prop-types'
import { get } from 'lodash'
import { Grid, Paper, withStyles, Typography } from '@material-ui/core'

import { LOCATIONS, METERS_TYPES, OTHER_TYPE } from 'shared/constants/report/utilities'
import electricMetersDiscussion from 'shared/utils/textGeneration/property/electricMetersDiscussion'

import { arrayToKeyValuePairs } from 'client-shared/utils/ui/checkboxHelper'
import NarrativeComponent from 'client-shared/components/NarrativeComponent'
import AutomationStatus from 'client-shared/components/AutomationStatus'

import { Text, DropDown, CheckboxWithLabel } from '../../../../shared/components'

import { METERS_TOOLTIP } from './constants'

const styles = theme => ({
  paper: theme.paper,
  checkboxRoot: {
    height: 48,
  },
  checkBoxLabel: {
    marginTop: 0,
  },
})

class ElectricMeters extends React.PureComponent {
  static propTypes = {
    form: PropTypes.object,
  }

  get utilityTypes() {
    return arrayToKeyValuePairs(METERS_TYPES)
  }

  get locationTypes() {
    return arrayToKeyValuePairs(LOCATIONS)
  }

  render() {
    const { classes, form, automatedInspectionPrefillFlag } = this.props
    const { automationMetadata } = form.values
    const electricMeters = get(form.values, 'electricMeters')
    const { inspected, location } = electricMeters

    return (
      <Paper className={classes.paper}>
        <Grid container spacing={8}>
          <Grid item xs={12}>
            <Typography variant="h6">Electric Meters</Typography>
          </Grid>
          <Grid item xs={12}>
            {automatedInspectionPrefillFlag && automationMetadata && (
              <AutomationStatus
                formPaths={['automationMetadata']}
                message="We automated the information below from Mobile Inspection app"
              />
            )}
          </Grid>
          <Grid item xs={12}>
            <CheckboxWithLabel name="electricMeters.inspected" label="There are electric meters" />
          </Grid>
          {inspected && (
            <React.Fragment>
              <Grid item xs={6}>
                <DropDown name="electricMeters.type" label="Select Electric Meters type" items={this.utilityTypes} />
              </Grid>
              <Grid item xs={6}>
                <DropDown
                  name="electricMeters.location"
                  label="Select Electric Meters location"
                  items={this.locationTypes}
                />
              </Grid>
              {location === OTHER_TYPE && (
                <React.Fragment>
                  <Grid item xs={6} />
                  <Grid item xs={6}>
                    <Text name="electricMeters.otherLocation" label="Electric Meters Location" />
                  </Grid>
                </React.Fragment>
              )}
            </React.Fragment>
          )}
          <Grid item xs={12}>
            <NarrativeComponent
              title="Electric Meters Discussion"
              generatedText={electricMetersDiscussion.generate}
              data={electricMetersDiscussion.mapDTO(form.values)}
              name="electricMetersDiscussion"
              tooltipText={METERS_TOOLTIP}
            />
          </Grid>
        </Grid>
      </Paper>
    )
  }
}

export default withStyles(styles)(ElectricMeters)
