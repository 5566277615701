import React from 'react'
import { compose, pure } from 'recompose'
import { withStyles } from '@mui/styles'

import { AppraisersList } from '../components'

import CertificationRequirements from './CertificationRequirements'

const styles = {
  appraisersList: {
    padding: '5px 25px',
    '& p': {
      color: 'rgba(0, 0, 0, 0.6)',
    },
  },
}

const Preview = ({ classes, requirements, appraisers, form }) => {
  return (
    <React.Fragment>
      <CertificationRequirements requirements={requirements} hasOnlyOneAppraiser={appraisers.length === 1} />
      <AppraisersList
        classes={{ root: classes.appraisersList }}
        appraisers={appraisers}
        isFreddieMac={form.values.isFreddieMacReport}
        reportState={form.values.reportState}
      />
    </React.Fragment>
  )
}

Preview.propTypes = {}

Preview.defaultProps = {}

export default compose(withStyles(styles), pure)(Preview)
