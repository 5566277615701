import React from 'react'

import CommercialIcon from '@mui/icons-material/BusinessRounded'
import MultiFamilyIcon from '@mui/icons-material/HomeRounded'
import DashIcon from '@mui/icons-material/Remove'
import MixedUseIcon from '@mui/icons-material/StoreRounded'
import { Tooltip } from '@mui/material'

import BlocksIcon from '../BlocksIcon'

import { PropertyTypes as PT, PROPERTY_TYPES_PROPER_CASE } from '../../../../../shared/constants'

const iconColor = 'rgba(0, 0, 0, 0.54)'
const DEFAULT_PLACEMENT = 'top'

const PropertyTypeIcon = ({ propertyType, showTooltip = true, placement = DEFAULT_PLACEMENT, arrow }) => {
  const getIcon = () => {
    switch (propertyType) {
      case PT.MULTI_FAMILY: {
        return <MultiFamilyIcon sx={{ fill: iconColor }} />
      }
      case PT.MIXED_USE: {
        return <MixedUseIcon sx={{ fill: iconColor }} />
      }
      case PT.COMMERCIAL: {
        return <CommercialIcon sx={{ fill: iconColor }} />
      }
      case 'blocks': {
        // Temporary code while dashboard designs in progress
        return (
          <span>
            <BlocksIcon sx={{ fill: iconColor }} />
          </span>
        )
      }
      default: {
        return <DashIcon sx={{ fill: iconColor }} />
      }
    }
  }

  if (showTooltip) {
    let propertyTypeText = PROPERTY_TYPES_PROPER_CASE[propertyType]
    if (!propertyTypeText) {
      propertyTypeText = 'Unknown'

      if (propertyType === 'blocks') {
        propertyTypeText = 'Blocks'
      }
    }

    return (
      <Tooltip placement={placement} title={propertyTypeText} arrow={arrow}>
        {getIcon()}
      </Tooltip>
    )
  } else {
    return getIcon()
  }
}

export default PropertyTypeIcon
