import React from 'react'
import PropTypes from 'prop-types'

import RootRef from '@material-ui/core/RootRef'

import { get } from 'lodash'

import { TableCell, Grid } from '@material-ui/core'

import CameraAlt from '@mui/icons-material/CameraAlt'

import { NO_IMAGE_AVAILABLE } from '../../../../constants'

import ImageTooltip from '../../../../../shared/components/Tooltip/ImageTooltip'

import LightTooltip from './LightTooltip'
import DropDownMenu from './DropDownMenu'

class CompHeaderCell extends React.PureComponent {
  state = {
    domRef: {},
  }

  static propTypes = {
    address: PropTypes.string.isRequired,
    comp: PropTypes.object.isRequired,
    classes: PropTypes.object.isRequired,
    editComp: PropTypes.func.isRequired,
    clearComp: PropTypes.func.isRequired,
  }

  onRefChange = node => {
    this.setState({ domRef: { current: node } })
  }

  render() {
    const { classes, address, comp, editComp, clearComp } = this.props
    const { domRef } = this.state

    return (
      <RootRef rootRef={this.onRefChange}>
        <TableCell className={classes.masterTable_Header_Cell} data-qa="comp-header-cell">
          <Grid container>
            <Grid item xs={8} style={{ paddingTop: 16 }}>
              <LightTooltip
                classes={{
                  childrenWrapper: classes.masterTable_HeaderCell_Address,
                  popper: classes.masterTable_HeaderCell_Address_Popper,
                }}
                text={address}
                placement="top"
              >
                {address}
              </LightTooltip>
            </Grid>
            <Grid item xs={2} style={{ paddingTop: 12 }}>
              <ImageTooltip
                placement="left"
                popperTemplate={
                  <img
                    src={get(comp, 'propertyInformation.photo.cdnUrl', NO_IMAGE_AVAILABLE)}
                    alt="Sales Comp"
                    className={classes.photoPopover}
                  />
                }
              >
                <CameraAlt />
              </ImageTooltip>
            </Grid>
            <Grid item xs={2} style={{ paddingTop: 10, paddingRight: 8 }}>
              <DropDownMenu
                editComp={editComp}
                clearComp={clearComp}
                comp={comp}
                classes={classes}
                anchorRef={domRef}
              />
            </Grid>
          </Grid>
        </TableCell>
      </RootRef>
    )
  }
}

export default CompHeaderCell
