import React from 'react'

import { Grid } from '@mui/material'

import 'moment-timezone'

import { getPartsFromString } from 'shared/utils/narrative/GeneratedText'

import { DEFAULT_USPAP_REQUIREMENTS } from 'shared/constants/previewAndEdit/certification'

import NarrativeComponent from '../../shared/components/NarrativeComponent'

import withCMSForm from './withCMSForm'

const GRID_CONTAINER_GUTTER = 2

const Certification = () => {
  return (
    <Grid container spacing={GRID_CONTAINER_GUTTER}>
      {Object.values(DEFAULT_USPAP_REQUIREMENTS).map((requirement, index) => {
        const requirementTitle = `Certification Bullet Point ${index + 1}`
        const requirementName = `certification.bulletPoint${index + 1}`
        const getRequirementName = () => getPartsFromString(requirement)
        return (
          <Grid item xs={12}>
            <NarrativeComponent title={requirementTitle} generatedText={getRequirementName} name={requirementName} />
          </Grid>
        )
      })}
    </Grid>
  )
}

export default withCMSForm(Certification, 'Certification')
