import React from 'react'
import PropTypes from 'prop-types'
import { TableSortLabel as MuiTableSortLabel, withStyles } from '@material-ui/core'

import { SORT_DIRECTIONS } from '../../constants'

const styles = theme => ({
  sortCell: {
    '&, &:hover': {
      color: 'inherit',
    },
  },
  activeSortCell: {
    '&, &:focus, &:hover': {
      color: 'inherit',
    },
    '& $sortIcon': {
      fill: theme.palette.primary.main,
    },
  },
  sortIcon: {
    opacity: 1,
    marginBottom: 2,
  },
})

class TableSortLabel extends React.PureComponent {
  static propTypes = {
    sort: PropTypes.func.isRequired,
    active: PropTypes.bool.isRequired,
    children: PropTypes.node.isRequired,
    dataKey: PropTypes.string.isRequired,
    direction: PropTypes.string,
  }

  static defaultProps = {
    direction: SORT_DIRECTIONS.ASC,
  }

  state = {
    sortDirection: this.props.direction,
  }

  toggleSortDirection = event => {
    const { active, dataKey, sort } = this.props
    const { sortDirection } = this.state

    if (!active) {
      sort({ sortBy: dataKey, sortDirection })
      return
    }

    if (sortDirection === SORT_DIRECTIONS.ASC) {
      this.setState({ sortDirection: SORT_DIRECTIONS.DESC })
    } else {
      this.setState({ sortDirection: SORT_DIRECTIONS.ASC })
    }
  }

  componentDidUpdate(prevProps, prevState) {
    const { sort, dataKey } = this.props
    const { sortDirection } = this.state

    if (prevState.sortDirection !== sortDirection) {
      sort({ sortBy: dataKey, sortDirection })
    }
  }

  render() {
    const { classes, children, active } = this.props
    const { sortDirection } = this.state

    return (
      <MuiTableSortLabel
        active={active}
        direction={sortDirection}
        classes={{
          root: classes.sortCell,
          active: classes.activeSortCell,
          icon: classes.sortIcon,
        }}
        onClick={this.toggleSortDirection}
      >
        {children}
      </MuiTableSortLabel>
    )
  }
}

export default withStyles(styles)(TableSortLabel)
