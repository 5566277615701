import React, { useCallback, useState } from 'react'
import { pure } from 'recompose'
import { get } from 'lodash'
import PropTypes from 'prop-types'

import { Grid, Button, Link } from '@mui/material'

import { useFieldArray } from 'react-final-form-arrays'

import { useDispatch } from 'client-shared/hooks/redux'
import { EXPENSE_FORECAST_PATH } from 'shared/constants/report/keysAndDataPaths'
import { FEATURE_FLAG_AUTOMATED_EXPENSE_FORECAST } from 'shared/constants/featureFlags'
import { FeatureToggle } from '@bowery-valuation/feature-flagger-client'

import AutomationCTA from 'client-shared/components/AutomationCTA'
import { IMPORT_STATUS_READY } from 'client-shared/components/AutomationCTA/constants'

import { EXPENSES_IN_ORDER, REAL_ESTATE_TAXES, STRICT_EXPENSE_CATEGORIES } from 'shared/constants/expenses'

import { BasisTypes } from 'shared/constants/report/incomeApproach/expenses'

import useBoolean from 'client-shared/hooks/useBoolean'

import { saveReport as saveReportAction } from '../../../redux/actions/report'
import AddExpenseDialog from '../../../components/AddExpenseDialog'
import { validateName } from '../helpers'

import * as Api from '../../../api'

import { areCustomExpensesEqual } from './tools'
import ForecastItem from './ForecastItem'
import TotalForecastItem from './TotalForecastItem'

import DeleteExpenseForecastDialog from './DeleteExpenseForecastModal'
import SourceLinksModal from './SourceLinksModal'

const DATA_PATH = EXPENSE_FORECAST_PATH

const ExpenseForecast = ({
  form,
  expenseForecastBasis,
  compTotalsMap,
  expenseHistory,
  reportId,
  isStrictExpensesMode,
  yearBuilt,
  expenseCategoriesIds,
}) => {
  const emptyName = { name: '' }

  const [showSaveDialog, setShowSaveDialog] = useState(false)
  const [showDeleteDialog, setShowDeleteDialog] = useState(false)
  const [isEdit, setIsEdit] = useState(false)
  const [currentExpense, setCurrentExpense] = useState({ expense: emptyName })
  const [isSourceLinksModalOpen, openSourceLinksModal, closeSourceLinksModal] = useBoolean(false)
  const customExpenses = useFieldArray('customExpenses', {
    isEqual: areCustomExpensesEqual,
  })

  const formState = form.getState()
  const formValues = formState.values

  const { expenseForecastAutomationMetadata } = formValues

  const dispatch = useDispatch()
  const saveReport = useCallback(() => {
    const formDataPath = DATA_PATH.join('.')
    dispatch(saveReportAction(formDataPath))
  }, [dispatch])
  const defaultExpenseNames = [
    ...EXPENSES_IN_ORDER.map(expense => expense.name.toLowerCase()),
    REAL_ESTATE_TAXES.name.toLowerCase(),
  ]

  const getAutomationWarningMessages = () => {
    const result = []

    if (!isStrictExpensesMode) {
      result.push('Expense history table is not using strict mode.')
    }

    if (!yearBuilt) {
      result.push('No year built.')
    }

    return result
  }

  const validateExpenseName = value => {
    return validateName(value, customExpenses.fields.value, defaultExpenseNames)
  }

  const addCustomExpenses = (name, expense) => {
    const defaultExpense = EXPENSES_IN_ORDER.find(expense => expense.name === name)
    if (defaultExpense) {
      form.change(`${defaultExpense.category}.skip`, false)
    } else {
      customExpenses.fields.push(expense)
    }
  }

  const handleSave = modalFormValues => {
    if (isEdit) {
      customExpenses.fields.update(currentExpense.index, {
        ...currentExpense.expense,
        name: modalFormValues.expenseName,
      })

      setCurrentExpense({ expense: emptyName })
      setIsEdit(false)
    } else {
      addCustomExpenses(modalFormValues.expenseName, {
        name: modalFormValues.expenseName,
        basis: BasisTypes.PER_SF,
      })
    }
    setShowSaveDialog(false)
  }

  const handleUpdateModalOpen = index => {
    setIsEdit(true)
    const expenseToEdit = customExpenses.fields.value[index]
    setCurrentExpense({ expense: expenseToEdit, index })
    setShowSaveDialog(true)
  }

  const handleDeleteModalOpen = index => {
    setShowDeleteDialog(true)
    const expenseToDelete = customExpenses.fields.value[index]
    setCurrentExpense({ expense: expenseToDelete, index })
  }

  const handleDeleteModalConfirm = () => {
    customExpenses.fields.remove(currentExpense.index)
    setCurrentExpense({ expense: emptyName })

    setShowDeleteDialog(false)
  }

  const handleCancel = () => {
    setShowSaveDialog(false)
    if (isEdit) {
      setCurrentExpense({ expense: emptyName })
      setIsEdit(false)
    }
  }

  const excludedExpenses = [...defaultExpenseNames, ...customExpenses.fields.value.map(expense => expense.name)]

  const getAutomationSuccessMessage = () => {
    const sourceLinks = get(expenseForecastAutomationMetadata, 'sourceLinks', [])
    return (
      <div>
        <div>
          Your automation ran successfully.{' '}
          {sourceLinks.length > 0 ? 'The following recent similar reports were used:' : ''}
        </div>
        <ul>
          {sourceLinks.map(report => (
            <li key={report.id}>
              <Link style={{ cursor: 'pointer' }} href={`/report/${report.id}/expense-forecast`} target="_blank">
                {report.address}
              </Link>
            </li>
          ))}
        </ul>
      </div>
    )
  }

  const runExpenseForecastAutomation = useCallback(async () => {
    const automatedData = await Api.runExpenseForecastAutomation(reportId)

    form.batch(() => {
      const strictExpenseCategories = Object.keys(STRICT_EXPENSE_CATEGORIES)

      strictExpenseCategories.forEach(strictCategory => {
        const amount = get(automatedData.expenseForecast, strictCategory, 0)

        form.change(`${strictCategory}.concludedValue`, amount)
        form.change(`${strictCategory}.basis`, 'unit')
      })

      form.change('management.percentOfEgiEnabled', true)
    })

    form.change('management.percentOfEgi', get(automatedData.expenseForecast, 'managementPercentOfEgi'))

    const recentSimilarReports = get(automatedData, 'recentSimilarReports', [])

    const automationMetadata = {
      sourceName: 'webapp',
      sourceLinks: recentSimilarReports,
      updatedAt: new Date(),
      importStatus: IMPORT_STATUS_READY,
    }

    form.change('expenseForecastAutomationMetadata', automationMetadata)

    global.automationSaved = 'autoExpenseForecast'
    saveReport()
  }, [form, reportId, saveReport])

  const warnings = getAutomationWarningMessages()

  return (
    <>
      <Grid>
        {showSaveDialog && (
          <AddExpenseDialog
            currentExpense={currentExpense.expense.name}
            excludedExpenses={excludedExpenses}
            isEdit
            name={`${isEdit ? 'Edit' : 'Add'} Custom Expense Category`}
            onCancel={handleCancel}
            onSave={handleSave}
            validate={validateExpenseName}
          />
        )}
        <DeleteExpenseForecastDialog
          open={showDeleteDialog}
          onClose={() => setShowDeleteDialog(false)}
          onConfirm={handleDeleteModalConfirm}
        />
        <FeatureToggle featureFlag={FEATURE_FLAG_AUTOMATED_EXPENSE_FORECAST}>
          <AutomationCTA
            CTAMessage="Automate the Expense Forecast"
            successMessage={getAutomationSuccessMessage()}
            errorMessage="We're sorry, the automation did not run. Please fill out this page as usual."
            onAutomationRun={runExpenseForecastAutomation}
            disableCTA={warnings.length > 0}
            warningTitle={warnings.length > 0 && 'Cannot Run Expense Forecast Automation'}
            warningMessage={
              warnings.length > 0 && (
                <div className="automation-warning">
                  <ul>
                    {warnings.map(warning => {
                      return <li key={warning}>{warning}</li>
                    })}
                  </ul>
                </div>
              )
            }
          />
        </FeatureToggle>
        <Button variant="contained" onClick={() => setShowSaveDialog(true)} sx={{ marginBottom: 3 }}>
          Add Expense Category +
        </Button>
        <Grid container wrap="nowrap" direction="column" spacing={3}>
          {EXPENSES_IN_ORDER.map(({ category, name }) =>
            get(form.values, `${category}.skip`) ? null : (
              <Grid key={category} item lg={11} md={12} sm={12}>
                <ForecastItem
                  form={form}
                  category={category}
                  categoryName={name}
                  selectedExpenseForecast={form.values[category]}
                  totalRoomCount={get(form, 'values.totalRoomCount', 0)}
                  numberOfUnits={get(form, 'values.numberOfUnits', 0)}
                  expenseDataSource={get(form, 'values.expenseDataSource', '')}
                  expenseForecastBasis={expenseForecastBasis[category]}
                  expenseHistory={expenseHistory}
                  automationMetadata={expenseForecastAutomationMetadata}
                  onAutomationSourceLinkClick={openSourceLinksModal}
                />
              </Grid>
            )
          )}
          {customExpenses.fields.value.map((currentCustomExpense, index) => {
            const { name } = currentCustomExpense

            return (
              <Grid key={`${name}`} item lg={11} md={12} sm={12}>
                <ForecastItem
                  form={form}
                  category={`customExpenses[${index}]`}
                  categoryName={name}
                  forecastItemIndex={index}
                  selectedExpenseForecast={currentCustomExpense}
                  expenseForecastBasis={expenseForecastBasis[name]}
                  expenseHistory={expenseHistory}
                  totalRoomCount={get(form, 'values.totalRoomCount', 0)}
                  numberOfUnits={get(form, 'values.numberOfUnits', 0)}
                  expenseDataSource={get(form, 'values.expenseDataSource', '')}
                  handleUpdate={handleUpdateModalOpen}
                  handleDelete={handleDeleteModalOpen}
                  isCustom
                />
              </Grid>
            )
          })}
          <Grid item lg={11} md={12} sm={12}>
            <TotalForecastItem form={form} compTotalsMap={compTotalsMap} expenseCategoriesIds={expenseCategoriesIds} />
          </Grid>
        </Grid>
      </Grid>
      {expenseForecastAutomationMetadata && (
        <SourceLinksModal
          open={isSourceLinksModalOpen}
          onClose={closeSourceLinksModal}
          sourceReports={expenseForecastAutomationMetadata.sourceLinks}
          generateSourceLink={reportId => `/report/${reportId}/expense-forecast`}
        />
      )}
    </>
  )
}

ExpenseForecast.propTypes = {
  form: PropTypes.object,
  expenseForecastBasis: PropTypes.object,
  compTotalsMap: PropTypes.object,
  expenseHistory: PropTypes.object,
  reportId: PropTypes.string,
  isStrictExpensesMode: PropTypes.bool,
  yearBuilt: PropTypes.number,
  numberOfRecentSimilarReports: PropTypes.number,
  expenseCategoriesIds: PropTypes.arrayOf(PropTypes.string.isRequired),
}

export default pure(ExpenseForecast)
