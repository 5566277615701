import { ClassNameMap } from '@material-ui/core/styles/withStyles'

const styles = {
  createButtonSection: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
  },
  emptyLoad: {
    textAlign: 'center',
  },
  createFields: {
    marginBottom: '50px',
  },
  createField: {
    marginTop: '10px',
    maxWidth: 700,
  },
  tableField: {
    maxWidth: '100%',
  },
  create_buttons: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'flex-end',
  },
  reuseModal: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '650px',
    backgroundColor: 'white',
    padding: '30px',
  },
  reuseModalFields: {
    marginBottom: '50px',
    width: '550px',
    display: 'flex',
    flexDirection: 'column',
    gap: '10px',
  },
  reuseModalButtons: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'flex-end',
  },
  subsectionGrid: {
    width: '100%',
    marginTop: '20px',
    display: 'flex',
    alignContent: 'flex-start',
  },
  cardBody: {
    height: '160px',
    wordBreak: 'break-word',
  },
  textEditor: {
    width: '100%',
    minHeight: 136,
    boxSizing: 'border-box',
    padding: 8,
    borderRadius: 4,
    transitionDuration: '0.3s',
    transitionTimingFunction: 'ease',
    fontFamily: 'Nunito Sans',
    color: 'lightgray',
  },
  textEditorWrapper: {
    border: '1px solid lightgray',
  },
  editorBox: {
    marginTop: '10px',
    marginBottom: '10px',
  },
  cardTextTruncate: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  cardButtons: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  atomicTypeChipLabel: {
    marginBottom: '10px',
    display: 'flex',
    maxWidth: 'fit-content',
    background: 'rgba(32, 181, 134, 0.08)',
  },
  atomicUnit: { marginBottom: '24px' },
  createModal: {
    marginTop: '10px',
  },
  tableTitle: {
    marginTop: '16px',
  },
  tableTitleList: {
    marginTop: '0px',
  },
  createAtomicUnit: { height: 320, display: 'flex', alignItems: 'center' },
  tableTooltip: { marginBottom: '3px' },
  tableBox: { minHeight: '320px' },
  subsectionCard: { width: 300, borderRadius: 2, padding: 0 },
  subsectionCardContent: { padding: '16px' },
  subsectionTooltip: { margin: '0' },
  subsectionPage: { marginBottom: 4 },
}

export type Classes = ClassNameMap<keyof typeof styles>

export default styles
