import { get } from 'lodash'

import createDecorator from 'final-form-calculate'

import ACASCalculations from 'shared/report-calculations/income-approach/acas/acas-calculations'
import { mapValuesToDisplay } from 'shared/report-calculations/income-approach/acas/helpers'
import SalesApproachCalculations from 'shared/report-calculations/sales-approach/sales-approach-calculations'
import IncomeApproachCalculations from 'shared/report-calculations/income-approach/income-approach-calculations'

const updateACASValues = (field, value, allValues) => {
  const roundingFactor = get(allValues, 'roundingFactor')

  const unitOfComparison = get(allValues, 'unitOfComparison')

  const dateOfValuation = get(allValues, 'dateOfValuation')

  const npvAdjustments = get(allValues, 'npvAdjustments')

  const asStabilizedNpvAdjustments = get(allValues, 'asStabilizedNpvAdjustments')
  const asCompleteNpvAdjustments = get(allValues, 'asCompleteNpvAdjustments')

  const asStabilizedResRentLossItems = get(allValues, 'asStabilizedResRentLossItems')
  const asCompleteResRentLossItems = get(allValues, 'asCompleteResRentLossItems')
  const asStabilizedCommercialRentLossItems = get(allValues, 'asStabilizedCommercialRentLossItems')
  const asCompleteCommercialRentLossItems = get(allValues, 'asCompleteCommercialRentLossItems')
  const asStabilizedLossItems = get(allValues, 'asStabilizedLossItems')
  const asCompleteLossItems = get(allValues, 'asCompleteLossItems')
  const asCompleteMonthsOfRentLoss = get(allValues, 'asCompleteMonthsOfRentLoss')
  const asStabilizedMonthsOfRentLoss = get(allValues, 'asStabilizedMonthsOfRentLoss')

  const saleValueConclusion = get(allValues, 'saleValueConclusion')
  const grossBuildingArea = get(allValues, 'squareFootage')
  const totalUnitCount = get(allValues, 'totalUnits')
  const propertyInformation = get(allValues, 'subjectPropertyInformation')

  const indicatedValue = SalesApproachCalculations.calculateSalePriceFromBasis(
    saleValueConclusion,
    unitOfComparison,
    propertyInformation
  )

  const includeValueAsComplete = get(allValues, 'valueAsComplete')
  const includeValueAsStabilized = get(allValues, 'valueAsStabilized')

  const asCompleteDiscountPercentage = get(allValues, 'asCompleteDiscountPercentage', 0)
  const asCompleteMonthsDiscounted = get(allValues, 'asCompleteMonthsDiscounted', 0)
  const asCompleteDiscountRate = IncomeApproachCalculations.calculateDiscountRate(
    asCompleteDiscountPercentage,
    asCompleteMonthsDiscounted
  )

  const asStabilizedDiscountPercentage = get(allValues, 'asStabilizedDiscountPercentage', 0)
  const asStabilizedMonthsDiscounted = get(allValues, 'asStabilizedMonthsDiscounted', 0)
  const asStabilizedDiscountRate = IncomeApproachCalculations.calculateDiscountRate(
    asStabilizedDiscountPercentage,
    asStabilizedMonthsDiscounted
  )
  const acasDollarAmounts = ACASCalculations.calculateFinalValues({
    indicatedValue,
    npvAdjustments,
    asStabilizedNpvAdjustments,
    asCompleteNpvAdjustments,
    asStabilizedResRentLossItems,
    asCompleteResRentLossItems,
    asStabilizedCommercialRentLossItems,
    asCompleteCommercialRentLossItems,
    asStabilizedLossItems,
    asCompleteLossItems,
    roundingFactor,
    squareFootage: grossBuildingArea,
    unitCount: totalUnitCount,
    includeValueAsComplete,
    includeValueAsStabilized,
    asCompleteDiscountRate,
    asStabilizedDiscountRate,
  })
  const acasDisplayDollarAmounts = mapValuesToDisplay(acasDollarAmounts)

  const acasDates = ACASCalculations.calculateDatesOfFinalValue({
    dateOfValuation,
    asCompleteMonthsOfRentLoss,
    asStabilizedMonthsOfRentLoss,
  })

  const results = {
    ...acasDisplayDollarAmounts,
    ...acasDates,
  }

  return results
}

export const acasDecorator = createDecorator({
  field: ['saleValueConclusion'],
  updates: updateACASValues,
})
