import React from 'react'
import PropTypes from 'prop-types'
import { compose } from 'recompose'
import { connect } from 'react-redux'
import InfoIcon from '@mui/icons-material/InfoOutlined'
import { Grid, Typography, DialogContent, withStyles } from '@material-ui/core'
import { isEmpty } from 'lodash'

import { DEFAULT_APPROACH_SETTINGS } from 'shared/constants/report'

import * as Socket from '../../../shared/utils/socket'
import Tooltip from '../../../shared/components/Tooltip'
import DialogHeader from '../../../shared/components/DialogHeader'
import { createReport, initializeCreatedReport } from '../../redux/actions'
import { errorNotification } from '../../../shared/redux/actions/notifications'
import { getAddressDetails, getDisplayAddress } from '../../../shared/utils/propertyHelper'
import { Loading, PropertyWizard, PropertyWizardHeader } from '../../../shared/components'

import { ReportSettings } from './ReportSettings'
import { FORM_INITIAL_VALUES } from './constants'

const styles = theme => ({
  createReportButton: {
    width: 157,
    height: 36,
    margin: '23px 0 0 5px',
    fontSize: 14,
    fontWeight: 500,
    lineHeight: 1.14,
    letterSpacing: 1.3,
  },
  callout: {
    marginLeft: 24,
    marginRight: 24,
    marginBottom: 4,
  },
  tooltipIcon: {
    color: theme.palette.secondary[700],
    marginLeft: 6,
    marginTop: 4,
    '&:hover': {
      cursor: 'pointer',
    },
  },
  tooltip: {
    backgroundColor: theme.palette.grey[600],
    padding: '8px 16px',
  },
  inputTooltip: {
    backgroundColor: theme.palette.grey[600],
    padding: '8px 16px',
    marginBottom: 0,
  },
  content: {
    maxWidth: 714,
  },
  dialogHeader: {
    paddingBottom: 8,
  },
})
const REPORT_SETTINGS_STEP = 'Report Settings'
class CreateReportModal extends React.PureComponent {
  static propTypes = {
    open: PropTypes.bool.isRequired,
    closeReportModal: PropTypes.func.isRequired,
    errorNotification: PropTypes.func.isRequired,
  }

  state = {
    isCreatingReport: false,
    reportNumber: null,
  }

  onCreatedReport = report => {
    if (report.report_number === this.state.reportNumber) {
      this.setState({ isCreatingReport: false })
      this.props.initializeCreatedReport(report)
      this.unsubscribeFromSockets()
    }
  }

  onCreateReportFailed = error => {
    if (error.report_number === this.state.reportNumber) {
      this.props.errorNotification({ message: 'Report could not be created.' })
      this.setState({ isCreatingReport: false })
      this.unsubscribeFromSockets()
    }
  }

  unsubscribeFromSockets = () => {
    Socket.off(`report:created`, this.onCreatedReport)
    Socket.off(`report:create-failed`, this.onCreateReportFailed)
  }

  required = value => (value ? undefined : 'Required')

  mapReportInformation = values => {
    const { settings, reportNumber } = values
    const { isBlocks, pullExternalData, templateType, incomeType, valueConclusionType, approaches } = settings

    return {
      ...values,
      templateType,
      valueConclusionType,
      incomeType,
      reportNumber,
      valid: true,
      settings: {
        ...settings,
        approaches: isEmpty(approaches) ? DEFAULT_APPROACH_SETTINGS : approaches,
        pullExternalData: isBlocks ? true : pullExternalData,
      },
    }
  }

  onSubmit = values => {
    const { createReport } = this.props
    const report = this.mapReportInformation(values)
    const { reportNumber } = values

    this.setState({ isCreatingReport: true, reportNumber: reportNumber })
    Socket.on(`report:created`, this.onCreatedReport)
    Socket.on(`report:create-failed`, this.onCreateReportFailed)
    createReport({ report })
  }

  renderTitle = (title, values, defaultTitle, handleClose) => {
    return <React.Fragment>{defaultTitle(title, values, handleClose)}</React.Fragment>
  }

  renderSettingsTitle = (title, values, defaultTitle, handleClose) => {
    const { classes } = this.props
    const { address, state, city, locationIdentifier } = values
    const details = getAddressDetails(locationIdentifier, values)
    const fullAddress = getDisplayAddress({ address, city, state })

    return (
      <React.Fragment>
        <DialogHeader className={classes.dialogHeader} onClose={handleClose} disableTypography>
          <PropertyWizardHeader
            details={details}
            address={fullAddress}
            title={
              <Grid container>
                <Grid item>
                  <Typography variant="h6">Report Settings</Typography>
                </Grid>
                <Grid item>
                  <Tooltip
                    classes={{ root: classes.tooltip }}
                    text="Report Settings will be editable on the Settings & Export page within your report."
                    placement="right"
                  >
                    <InfoIcon className={classes.tooltipIcon} />
                  </Tooltip>
                </Grid>
              </Grid>
            }
          />
        </DialogHeader>
      </React.Fragment>
    )
  }

  render() {
    const { open, closeReportModal, classes } = this.props
    const { isCreatingReport } = this.state

    return (
      <Grid container>
        <PropertyWizard
          open={open}
          initialValues={FORM_INITIAL_VALUES}
          onSubmit={this.onSubmit}
          renderTitle={this.renderTitle}
          title="Find Subject Property"
          handleClose={closeReportModal}
          nextStep={REPORT_SETTINGS_STEP}
        >
          <PropertyWizard.Step renderTitle={this.renderSettingsTitle} step={REPORT_SETTINGS_STEP}>
            {({ openNextStep, submit, invalid, openPreviousStep, previousStep }) => (
              <React.Fragment>
                {isCreatingReport && <Loading />}
                <DialogContent className={classes.content} data-qa="create-report-settings-modal">
                  <ReportSettings
                    required={this.required}
                    previousStep={previousStep}
                    openPreviousStep={openPreviousStep}
                    invalid={invalid}
                    openNextStep={openNextStep}
                    submit={submit}
                  />
                </DialogContent>
              </React.Fragment>
            )}
          </PropertyWizard.Step>
        </PropertyWizard>
      </Grid>
    )
  }
}

export default compose(
  connect(null, {
    createReport,
    initializeCreatedReport,
    errorNotification,
  }),
  withStyles(styles)
)(CreateReportModal)
