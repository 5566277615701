import React from 'react'
import PropTypes from 'prop-types'

import { Paper } from '@mui/material'
import { withStyles } from '@material-ui/core'

import { get } from 'lodash'
import { connect } from 'react-redux'

import { ANCHOR_REPORT_DETAILS } from 'shared/constants/report/dataCollections'

import Purpose from 'report/forms/dataCollections/jobDetails/Purpose'
import Engagement from 'report/forms/dataCollections/jobDetails/Engagement'

import { styles } from '../styles'

const ReportDetails = ({ form, authenticatedUser, valueAsComplete, valueAsStabilized, classes }) => {
  return (
    <Paper id={ANCHOR_REPORT_DETAILS} elevation={0} className={classes.noBackground}>
      <Purpose valueAsComplete={valueAsComplete} valueAsStabilized={valueAsStabilized} />
      <Engagement authenticatedUser={authenticatedUser} form={form} />
    </Paper>
  )
}

ReportDetails.propTypes = {
  form: PropTypes.object.isRequired,
  classes: PropTypes.object.isRequired,
  authenticatedUser: PropTypes.object.isRequired,
  valueAsComplete: PropTypes.bool,
  valueAsStabilized: PropTypes.bool,
}

export default connect(state => {
  const valueAsStabilized = get(state, 'report.reportSettings.valueAsStabilized')
  const valueAsComplete = get(state, 'report.reportSettings.valueAsComplete')
  const authenticatedUser = get(state, 'authentication.user')
  return {
    valueAsStabilized,
    valueAsComplete,
    authenticatedUser,
  }
})(withStyles(styles)(ReportDetails))
