import { delay } from 'redux-saga'
import { noop } from 'lodash'
import { push } from 'react-router-redux'

import { select, put, takeLatest, throttle } from 'redux-saga/effects'

import * as Api from '../../core/api'

import { DefaultLoadReportsIndexRange, REPORTS_FETCH_THROTTLE } from '../constants'

import {
  CREATE_REPORT,
  DELETE_REPORT,
  REPORTS_FETCH,
  CHANGE_REPORT_FILTERS,
  DUPLICATE_REPORT,
  CHANGE_REPORT_VISIBILITY,
  SET_REPORT_FILTERS,
  CHANGE_REPORT_TAB,
  CHANGE_REPORT_SORTING,
  INITIAL_REPORTS_FETCH,
  CLEAR_REPORT_FILTERS,
  INITIALIZE_CREATED_REPORT,
} from './actionTypes'

import {
  fetchMoreReportsRequest,
  fetchMoreReportsReceive,
  fetchMoreReportsFail,
  createReportRequest,
  createReportReceive,
  createReportFail,
  deleteReportRequest,
  deleteReportReceive,
  deleteReportFail,
  setReportFilters,
  duplicateReportRequest,
  duplicateReportReceive,
  duplicateReportFail,
  changeReportVisibilityRequest,
  changeReportVisibilityReceive,
  changeReportVisibilityFail,
  setShowLoading,
} from './actions'

const FILTERS_DELAY = 300

function* changeReportFilters(action) {
  yield delay(FILTERS_DELAY)
  yield put(setReportFilters(action.payload))
}

function* initialReportsFetch() {
  yield put(setShowLoading(true))

  yield fetchMoreReports({
    payload: {
      isInitialFetchRequest: true,
      indexRange: DefaultLoadReportsIndexRange,
    },
  })
}

function* fetchMoreReports({ payload: { indexRange, succeed = noop, fail = noop, isInitialFetchRequest = false } }) {
  const { startIndex, stopIndex } = indexRange

  try {
    const { sorting, filters, currentTab } = yield select(state => state.reports)

    yield put(fetchMoreReportsRequest(isInitialFetchRequest))
    const { reports: nextReports, totalCount } = yield Api.fetchMoreReports({
      sorting,
      filters,
      currentTab,
      from: startIndex,
      size: stopIndex,
    })

    yield put(fetchMoreReportsReceive({ reports: nextReports, totalCount, indexRange }))

    succeed()
  } catch (err) {
    yield put(fetchMoreReportsFail(err))

    fail(err)
  }
}

function* createReport(action) {
  try {
    yield put(createReportRequest(action))
    const { report } = action.payload
    yield Api.createReport(report)
  } catch (err) {
    yield put(createReportFail(err))
  }
}

function* initializeCreatedReport(action) {
  try {
    const report = action.payload
    const isBlocks = report.isBlocks
    if (isBlocks) {
      yield put(push(`/report/${report._id}/review-and-export`))
    } else {
      yield put(push(`/report/${report._id}/job-details`))
    }
    yield put(createReportReceive(report))
  } catch (err) {
    yield put(createReportFail(err))
  }
}

function* deleteReport(action) {
  try {
    yield put(deleteReportRequest(action))
    const { reportId } = action.payload
    const status = yield Api.deleteReport(reportId)
    yield put(deleteReportReceive(status))
  } catch (err) {
    yield put(deleteReportFail(err))
  }
}

function* duplicateReport(action) {
  try {
    yield put(duplicateReportRequest(action))
    const { reportId, jobId, propertyInfo } = action.payload
    const newReport = yield Api.duplicateReport(reportId, jobId, propertyInfo)
    if (newReport._id) {
      yield put(push(`/report/${newReport._id}/report-information`))
    }
    yield put(duplicateReportReceive(newReport))
  } catch (err) {
    yield put(duplicateReportFail(err))
  }
}

function* changeReportVisibility(action) {
  try {
    yield put(changeReportVisibilityRequest(action))
    const { reportVisible, reportId } = action.payload

    const userId = yield select(state => state.authentication.user._id)
    yield Api.updateReportVisibility(reportId, userId, reportVisible)

    yield put(changeReportVisibilityReceive({ reportVisible, reportId, userId }))
  } catch (err) {
    yield put(changeReportVisibilityFail(err))
  }
}

export default [
  takeLatest(CHANGE_REPORT_FILTERS.ACTION, changeReportFilters),
  takeLatest(CREATE_REPORT.ACTION, createReport),
  takeLatest(DELETE_REPORT.ACTION, deleteReport),
  takeLatest(DUPLICATE_REPORT.ACTION, duplicateReport),
  takeLatest(CHANGE_REPORT_VISIBILITY.ACTION, changeReportVisibility),
  takeLatest(INITIAL_REPORTS_FETCH.ACTION, initialReportsFetch),
  throttle(REPORTS_FETCH_THROTTLE, REPORTS_FETCH.ACTION, fetchMoreReports),
  takeLatest(
    [CLEAR_REPORT_FILTERS.ACTION, SET_REPORT_FILTERS.ACTION, CHANGE_REPORT_TAB.ACTION, CHANGE_REPORT_SORTING.ACTION],
    initialReportsFetch
  ),
  takeLatest(INITIALIZE_CREATED_REPORT.ACTION, initializeCreatedReport),
]
