import React from 'react'
import PropTypes from 'prop-types'
import { DragDropContext } from 'react-beautiful-dnd'

import { FeatureToggle } from '@bowery-valuation/feature-flagger-client'
import {
  Avatar,
  Icon,
  Paper,
  TableBody,
  TableCell,
  TableHead,
  Button,
  TableRow,
  Tooltip,
  Typography,
  Stack,
  IconButton,
  Box,
} from '@mui/material'
import StarRounded from '@mui/icons-material/StarRounded'
import RemoveCircle from '@mui/icons-material/RemoveCircle'
import ErrorOutline from '@mui/icons-material/ErrorOutline'
import { forEach, get, isEqual, isNil, isNumber, noop } from 'lodash'
import { FieldArray } from 'react-final-form-arrays'

import { DraggableTableRow, DroppableTable } from 'client-shared/components/DragDropTable'
import { getSalePriceToUse } from 'shared/report-calculations/sales-approach/helpers'
import { divide } from 'shared/utils/numberOperations'
import PropertyTypeIcon from 'client-shared/components/PropertyTypeIcon'
import { Area, Template } from 'client-shared/components/Template'
import { DropDown } from 'client-shared/components'
import { NOT_AVAILABLE } from 'report/constants'
import { calculateDistance } from 'client-shared/utils/geography'
import {
  formatCurrencyFloat,
  formatCurrencyInt,
  formatInt,
  toPercentageString,
} from 'client-shared/utils/numberFormatters'

import { SALES_APPROACH_TYPES, EXPORT_IN_VALUES } from 'shared/constants/salesApproach'

import AutoModeIcon from '@mui/icons-material/AutoModeRounded'

import { FEATURE_FLAG_AUTO_SALES_COMPS_RULE_EDIT } from 'shared/constants/featureFlags'

import Badge from './Badge'
import {
  EXPORT_IN_DROPDOWN_ITEMS,
  HEADER_COLUMNS,
  SALES_COMPS_SORT_DROPDOWN_ITEMS,
  SALES_COMPS_SORT_VALUES,
  SALES_COMP_STOCK_PHOTO,
} from './constants'
import { checkIfCompsAreLatestVersion, getFormattedSaleDate } from './helpers'
import { getSaleCompFullAddress } from './tools'
import { getSourceOfInformation } from './RemovedSalesCompsTable'

class MaterialTableHeader extends React.Component {
  render() {
    const { sortBy } = this.props
    return (
      <TableHead>
        <TableRow>
          <TableCell>{sortBy === SALES_COMPS_SORT_VALUES.CUSTOM && 'Order'}</TableCell>
          {HEADER_COLUMNS.map(row => {
            return (
              <TableCell padding={row.padding} key={row.id} style={{ ...row.style }}>
                {row.label}
              </TableCell>
            )
          }, this)}
          <TableCell>Actions</TableCell>
        </TableRow>
      </TableHead>
    )
  }
}

const EnhancedTableHead = MaterialTableHeader

class SelectedSalesCompsTable extends React.Component {
  state = {
    selected: [],
    draggingId: '',
  }

  componentWillUpdate(nextProps, nextState, nextContext) {
    if (this.props.salesComps.length < nextProps.salesComps.length) {
      this.setState({
        order: 'asc',
        orderBy: 'dateSold',
      })
    }
  }

  static propTypes = {
    form: PropTypes.object.isRequired,
    fields: PropTypes.shape({
      value: PropTypes.array,
      push: PropTypes.func,
      update: PropTypes.func,
    }),
    title: PropTypes.string.isRequired,
    removeComp: PropTypes.func.isRequired,
    navigateToEditModal: PropTypes.func.isRequired,
    subjectCoordinates: PropTypes.shape({
      lat: PropTypes.number,
      lng: PropTypes.number,
    }),
    salesComps: PropTypes.array,
    subjectProperty: PropTypes.object,
    authenticatedUser: PropTypes.object.isRequired,
    fieldName: PropTypes.string.isRequired,
    getCustomDetailsButton: PropTypes.func,
  }

  static defaultProps = {
    getCustomDetailsButton: noop,
  }

  componentDidMount() {
    this.checkIfCompsAreLatestVersion()
  }

  checkIfCompsAreLatestVersion = async () => {
    const { form, fields, salesComps } = this.props

    const updatedComps = await checkIfCompsAreLatestVersion(salesComps)

    form.batch(() => {
      form.reset(form.getState().values)
      updatedComps.forEach((updatedComp, index) => {
        fields.update(index, updatedComp)
      })
    })
  }

  fillTableRowTemplate = ({
    compId,
    compIndex,
    photoElement,
    type,
    distance,
    streetAddress,
    saleDate,
    yearBuilt,
    totalUnits,
    gba,
    salePrice,
    saleStatus,
    pricePerUnit,
    pricePerSquareFoot,
    capRate,
    actions,
    displaySourceOfInformation,
    displayNumberUsedInReports,
    state,
    city,
    zip,
    isDrmComp,
    isLatestVersion,
    status,
    sourceIcon,
    isDeleted,
  }) => {
    const { sortBy } = this.props

    const fillerIcon = <Icon>remove</Icon>

    const formattedGBA = !isNil(gba) ? `${formatInt(gba)}` : fillerIcon
    const isUneditable = compId && (!isDrmComp || !isLatestVersion)
    return (
      <DraggableTableRow
        data-qa={isNumber(compIndex) ? `selected-comps-row-${compIndex}` : 'property-row'}
        id={`selected-comps-${compId}`}
        index={isNumber(compIndex) ? compIndex : -1}
        isDragDisabled={sortBy !== SALES_COMPS_SORT_VALUES.CUSTOM || !isNumber(compIndex)}
        key={`selected-comps-${compId}`}
      >
        <TableCell data-qa="sourceIcon">{sourceIcon}</TableCell>
        <TableCell data-qa="photo-element">{photoElement}</TableCell>
        <TableCell data-qa="property-type">
          <PropertyTypeIcon propertyType={type} />
        </TableCell>
        <TableCell data-qa="distance">{distance || fillerIcon}</TableCell>
        <TableCell data-qa="address">
          {getSaleCompFullAddress({ streetAddress, state, city, zip })}

          {isUneditable && (
            <Tooltip placement="top" title="Outdated">
              <Box component="span" sx={{ position: 'relative', top: 4, left: 8 }}>
                <ErrorOutline color="error" fontSize="small" />
              </Box>
            </Tooltip>
          )}

          {isDeleted && (
            <Tooltip placement="top" title="This comp was deleted from our database by a user.">
              <Box component="span" sx={{ position: 'relative', top: 4, left: 8 }}>
                <RemoveCircle color="error" fontSize="small" />
              </Box>
            </Tooltip>
          )}
        </TableCell>
        <TableCell data-qa="status">{status ? <Badge type={status} /> : fillerIcon}</TableCell>
        <TableCell data-qa="sale-date">{getFormattedSaleDate(saleDate, saleStatus, fillerIcon)}</TableCell>
        <TableCell data-qa="year-built">{yearBuilt || fillerIcon}</TableCell>
        <TableCell data-qa="total-units">{totalUnits || fillerIcon}</TableCell>
        <TableCell data-qa="formatted-gba">{formattedGBA}</TableCell>
        <TableCell data-qa="sale-price">{salePrice || fillerIcon}</TableCell>
        <TableCell data-qa="price-per-unit">{formatCurrencyInt(pricePerUnit) || fillerIcon}</TableCell>
        <TableCell data-qa="price-per-square-foot">{formatCurrencyFloat(pricePerSquareFoot) || fillerIcon}</TableCell>
        <TableCell data-qa="cap-rate">{toPercentageString(capRate) || fillerIcon}</TableCell>
        <TableCell data-qa="source-of-information">{displaySourceOfInformation || fillerIcon}</TableCell>
        <TableCell data-qa="source-of-information">{displayNumberUsedInReports || fillerIcon}</TableCell>
        <TableCell data-qa="selected-comp-actions">{actions || ''}</TableCell>
      </DraggableTableRow>
    )
  }

  renderTableBodyContent = () => {
    const { salesComps, removeComp, subjectProperty, subjectCoordinates, navigateToEditModal } = this.props
    const salesCompsTableRows = []
    const photoElement = (
      <div>
        <StarRounded />
      </div>
    )

    const { propertySummary } = subjectProperty
    const { streetAddress, city, state, zip } = propertySummary

    const subject = this.fillTableRowTemplate({
      ...subjectProperty,
      streetAddress,
      city,
      state,
      zip,
      photoElement,
      type: subjectProperty.type,
      gba: formatInt(subjectProperty.gba),
      distance: null,
    })

    salesCompsTableRows.push(subject)
    const salesCompsToRender = salesComps
      .filter(comp => !comp.isRemoved)
      .map(salesComp => {
        const { propertyInformation, address, saleInformation } = salesComp
        const { residentialUnits, commercialUnits, grossBuildingArea } = propertyInformation
        const totalUnits = residentialUnits + (commercialUnits || 0)
        const salePrice = getSalePriceToUse(saleInformation)
        return {
          ...salesComp,
          totalUnits,
          distance: calculateDistance(subjectCoordinates, address.coords),
          pricePerUnit: divide(salePrice, totalUnits),
          pricePerSquareFoot: divide(salePrice, grossBuildingArea),
          capRate: !isNil(saleInformation.capRate) ? saleInformation.capRate : NOT_AVAILABLE,
          saleDate: saleInformation.saleDate,
          compId: salesComp._id,
          photoUrl: get(propertyInformation, 'photo.cdnUrl') || SALES_COMP_STOCK_PHOTO,
        }
      })

    forEach(salesCompsToRender, (salesComp, index) => {
      salesCompsTableRows.push(this.renderTableRow({ salesComp, index, removeComp, navigateToEditModal }))
    })
    return salesCompsTableRows
  }

  getScoreDisplay = ({ score }) => {
    if (!score?.total) {
      return ''
    }
    return `Total Score: ${Math.round(score.total)}${Object.keys(score).reduce((out, key) => {
      if (key === 'total') {
        return out
      }
      let title = key.replace(/([A-Z])/g, ' $1')
      title = title.charAt(0).toUpperCase() + title.slice(1)
      return `${out}\n${title}: ${Math.round(score[key])}`
    }, '')}`
  }

  renderTableRow = ({ salesComp, index, removeComp, navigateToEditModal }) => {
    const CustomDetailsButton = this.props.getCustomDetailsButton(salesComp)

    const actions = (
      <Stack alignItems="center" direction="row" spacing={1}>
        {CustomDetailsButton ? (
          <CustomDetailsButton />
        ) : (
          <Button
            variant="text"
            sx={{ padding: '2px' }}
            onClick={() => navigateToEditModal(salesComp.compId)}
            data-qa="details-btn"
          >
            Details
          </Button>
        )}
        <Tooltip placement="top" title="Remove">
          <IconButton
            aria-label="Remove"
            sx={{ padding: '2px' }}
            data-qa="selected-comp-remove-btn"
            onClick={() => removeComp(salesComp)}
          >
            <RemoveCircle color="error" />
          </IconButton>
        </Tooltip>
      </Stack>
    )

    const sourceIcon = salesComp.isAutoComp ? (
      <Tooltip
        placement="top"
        title={
          <>
            This Sales Comp was added automatically.
            <FeatureToggle featureFlag={FEATURE_FLAG_AUTO_SALES_COMPS_RULE_EDIT} checkForValue={true}>
              <div style={{ whiteSpace: 'pre-line' }}>{this.getScoreDisplay(salesComp)}</div>
            </FeatureToggle>
          </>
        }
      >
        <Stack color="#673AB7">
          <AutoModeIcon />
        </Stack>
      </Tooltip>
    ) : null

    const photoTooltip = (
      <Avatar
        alt="Sales Comp"
        src={salesComp.photoUrl}
        sx={{ width: 100, height: 100, objectFit: 'contain' }}
        variant="square"
      />
    )

    const photoElement = (
      <Tooltip placement="left" title={photoTooltip}>
        <Avatar
          alt="Sales Comp"
          src={salesComp.photoUrl}
          sx={{ width: 36, height: 36, objectFit: 'cover' }}
          variant="square"
        />
      </Tooltip>
    )

    const displaySourceOfInformation = getSourceOfInformation(salesComp)
    const displayNumberUsedInReports = salesComp.numberUsedInReports && (
      <Button
        variant="text"
        size="small"
        sx={{ textDecoration: 'underline', padding: 0, minWidth: '4px' }}
        onClick={() => navigateToEditModal(salesComp.compId, true)}
      >
        {salesComp.numberUsedInReports}
      </Button>
    )
    const { propertyInformation, address, saleInformation, status } = salesComp
    const { grossBuildingArea, residentialUnits, commercialUnits } = propertyInformation
    const salePrice = getSalePriceToUse(saleInformation)
    const pricePerSF = divide(salePrice, grossBuildingArea)
    const totalUnits = residentialUnits + (commercialUnits || 0)
    const pricePerUnit = divide(salePrice, totalUnits)
    return this.fillTableRowTemplate({
      compId: salesComp.compId || index,
      compIndex: index,
      type: propertyInformation.propertyType,
      streetAddress: address.streetAddress,
      city: address.city,
      state: address.state,
      zip: address.postalCode,
      yearBuilt: propertyInformation.yearBuilt,
      totalUnits,
      gba: formatInt(propertyInformation.grossBuildingArea),
      salePrice: formatCurrencyInt(salePrice),
      distance: `${salesComp.distance} mi`,
      photoElement,
      photoTooltip,
      saleDate: saleInformation.saleDate,
      saleStatus: saleInformation.saleStatus,
      pricePerUnit,
      pricePerSquareFoot: pricePerSF,
      capRate: saleInformation.capRate,
      displayNumberUsedInReports,
      actions,
      sourceIcon,
      displaySourceOfInformation,
      isDrmComp: !!(salesComp.salesTransactionId && salesComp.salesTransactionVersion),
      isLatestVersion: salesComp.isLatestVersion,
      status: status,
      isDeleted: !!salesComp.deletedAt,
    })
  }

  onDragStart = event => {
    this.setState({ draggingId: event.draggableId })
  }

  moveCard = ({ source, destination }) => {
    const { form, salesComps } = this.props

    this.setState({ draggingId: '' })

    if (!destination) {
      return
    }

    if (destination.index === 0) {
      // trying to move a comp above property row
      return
    }

    const [comp] = salesComps.splice(source.index, 1)
    salesComps.splice(destination.index - 1, 0, comp)

    form.change('selectedComps', salesComps)
  }

  getExportInDropdownItems = () => {
    const {
      form: {
        values: { type },
      },
    } = this.props
    if (type === SALES_APPROACH_TYPES.LAND) {
      return EXPORT_IN_DROPDOWN_ITEMS.filter(item => item.value !== EXPORT_IN_VALUES.ADDENDA)
    }
    return EXPORT_IN_DROPDOWN_ITEMS
  }

  render() {
    const { title, sortBy } = this.props

    return (
      <Paper>
        <Template
          is={`
            'title tools'
            'table table'
            /auto  1fr
          `}
          gap={2}
        >
          <Area is="title">
            <Typography variant="h6" gutterBottom>
              {title}
            </Typography>
          </Area>
          <Area is="tools" gap={2} placeContent="flex-end" placeItems="center">
            <DropDown
              data-qa="sortSalesComps-input"
              disabled={false}
              fullWidth={false}
              items={SALES_COMPS_SORT_DROPDOWN_ITEMS}
              label="Sort"
              margin="none"
              name="sortBy"
            />
            <DropDown
              data-qa="salesOutlineExportLocation-input"
              disabled={false}
              fullWidth={false}
              items={this.getExportInDropdownItems()}
              label="Export In"
              margin="none"
              name="exportLocation"
            />
          </Area>
          <Area is="table">
            <DragDropContext onDragStart={this.onDragStart} onDragEnd={this.moveCard}>
              <DroppableTable id="selected-sales-comps-table" isDropDisabled={false}>
                <EnhancedTableHead sortBy={sortBy} />
                <TableBody>{this.renderTableBodyContent()}</TableBody>
              </DroppableTable>
            </DragDropContext>
          </Area>
        </Template>
      </Paper>
    )
  }
}

const SalesCompsFieldArray = props => (
  <FieldArray isEqual={isEqual} name={props.fieldName} component={SelectedSalesCompsTable} {...props} />
)

export default SalesCompsFieldArray
