import React from 'react'
import { Route, Switch } from 'react-router'

import BuildingComp from './layout/BuildingComps'

class Router extends React.Component {
  render() {
    return (
      <React.Fragment>
        <Switch>
          <Route path="/report/:reportId/building-comp/:buildingCompId" component={BuildingComp} />
        </Switch>
      </React.Fragment>
    )
  }
}
export default Router
