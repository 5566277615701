import React from 'react'
import PropTypes from 'prop-types'

import arrayMutators from 'final-form-arrays'
import { Form } from 'react-final-form'
import { connect } from 'react-redux'
import { get, head, snakeCase, sortBy, startCase, toPairs } from 'lodash'

import { Fab, Grid, Paper, Tab } from '@mui/material'
import Save from '@mui/icons-material/Save'
import SettingsRounded from '@mui/icons-material/SettingsRounded'
import { TabContext, TabList } from '@mui/lab'

import FormHeader from 'client-shared/components/FormHeader'

import { fetchLatestInsurableValues, updateInsurableValues } from '../../../redux/actions/insurableValues'

import ConstantsTable from './ConstantsTable'
import ConstantsTab from './ConstantsTab'

import { TabKeys, TABS, HEADER, LOCAL_MULTIPLIERS_COLUMNS } from './constants'

const mapObjectToArray = object => {
  const array = toPairs(object).map(([key, value]) => ({ key, value }))
  const sortedArray = array.sort((first, second) => first.key - second.key)
  return sortedArray
}

const mapArrayToObject = arr => {
  return arr.reduce((acc, item) => {
    acc[item.key] = item.value
    return acc
  }, {})
}

const mapGeographyMultipliersToArray = geographyMultipliers => {
  const result = {}

  toPairs(geographyMultipliers).forEach(([geography, multipliers]) => {
    const multipliersArray = toPairs(multipliers).map(([name, values]) => ({ ...values, name: startCase(name) }))
    const sortedMultipliersArray = sortBy(multipliersArray, ['name'])
    result[geography] = sortedMultipliersArray
  })

  return result
}

const mapGeographyMultipliersToObject = geographyMultipliers => {
  const result = {}

  toPairs(geographyMultipliers).forEach(([geography, multipliers]) => {
    const mappedMultipliers = multipliers.reduce((acc, item) => {
      const { name, ...classes } = item
      const state = snakeCase(name)
      acc[state] = classes
      return acc
    }, {})

    result[geography] = mappedMultipliers
  })

  return result
}

class InsurableValues extends React.PureComponent {
  state = {
    selectedTab: TabKeys.CONSTANTS,
  }

  componentDidMount() {
    this.props.fetchInsurableValues()
  }

  changeTab = (event, newSelectedTab) => {
    this.setState({ selectedTab: newSelectedTab })
  }

  mapInsurableValuesForSaving = values => {
    const { valuesDate, costMultipliers, foundationsFactor, sprinklers, architectsFees, localMultipliers } = values

    return {
      validFrom: valuesDate,
      value: {
        add_sprinklers: mapArrayToObject(sprinklers),
        architect_fees: mapArrayToObject(architectsFees),
        current_cost_multiplier: head(costMultipliers),
        foundations_factor: head(foundationsFactor),
        local_multiplier: mapGeographyMultipliersToObject(localMultipliers),
      },
    }
  }

  handleSubmit = values => {
    const mappedValues = this.mapInsurableValuesForSaving(values)
    const { updateInsurableValues } = this.props
    updateInsurableValues(mappedValues)
  }

  render() {
    const { selectedTab } = this.state
    const { initialValues } = this.props

    return (
      <Grid container>
        <Grid item xs={12}>
          <FormHeader title={HEADER} icon={<SettingsRounded />} />
        </Grid>
        <Grid item md={7} sx={{ marginBottom: 2 }}>
          <TabContext value={selectedTab}>
            <TabList onChange={this.changeTab} variant="fullWidth">
              {TABS.map(({ label, value }) => (
                <Tab label={label} value={value} key={value} data-qa={`${value}-tab`} />
              ))}
            </TabList>
          </TabContext>
        </Grid>
        <Grid item md={7}>
          <Paper>
            <Form
              initialValues={initialValues}
              mutators={{ ...arrayMutators }}
              onSubmit={this.handleSubmit}
              render={({ handleSubmit, values }) => {
                const { sprinklers, architectsFees } = values
                return (
                  <form onSubmit={handleSubmit} data-qa={`${selectedTab}-form`}>
                    {selectedTab === TabKeys.CONSTANTS && (
                      <ConstantsTab sprinklers={sprinklers} architectsFees={architectsFees} />
                    )}
                    {selectedTab === TabKeys.NEW_YORK && (
                      <ConstantsTable
                        headerColumns={LOCAL_MULTIPLIERS_COLUMNS}
                        label="Local Multipliers"
                        labelTooltip="Latest values can be found in Section 99, Page 6 of Marshall & Swift"
                        name="localMultipliers.ny"
                      />
                    )}
                    {selectedTab === TabKeys.NEW_JERSEY && (
                      <ConstantsTable
                        headerColumns={LOCAL_MULTIPLIERS_COLUMNS}
                        label="Local Multipliers"
                        labelTooltip="Latest values can be found in Section 99, Page 6 of Marshall & Swift"
                        name="localMultipliers.nj"
                      />
                    )}
                    <Fab
                      color="success"
                      data-qa="save-btn"
                      sx={{ position: 'fixed', bottom: 40, right: 40 }}
                      type="submit"
                    >
                      <Save sx={{ color: 'white' }} />
                    </Fab>
                  </form>
                )
              }}
            />
          </Paper>
        </Grid>
      </Grid>
    )
  }
}

InsurableValues.propTypes = {
  initialValues: PropTypes.shape({
    architectsFees: PropTypes.array,
    costMultipliers: PropTypes.array,
    foundationsFactor: PropTypes.array,
    sprinklers: PropTypes.array,
    localMultipliers: PropTypes.shape({
      ny: PropTypes.array,
      nj: PropTypes.array,
    }),
  }),
  fetchInsurableValues: PropTypes.func.isRequired,
  updateInsurableValues: PropTypes.func.isRequired,
}

const mapStateToProps = state => {
  const valuesDate = get(state, 'insurableValues.values.validFrom')
  const costMultiplierObject = get(state, 'insurableValues.values.value.current_cost_multiplier', {})
  const foundationsFactorObject = get(state, 'insurableValues.values.value.foundations_factor', {})
  const localMultiplier = get(state, 'insurableValues.values.value.local_multiplier', {})
  const sprinklers = get(state, 'insurableValues.values.value.add_sprinklers', {})
  const architectsFees = get(state, 'insurableValues.values.value.architect_fees', {})

  return {
    initialValues: {
      architectsFees: mapObjectToArray(architectsFees),
      costMultipliers: [costMultiplierObject],
      foundationsFactor: [foundationsFactorObject],
      localMultipliers: mapGeographyMultipliersToArray(localMultiplier),
      sprinklers: mapObjectToArray(sprinklers),
      valuesDate,
    },
  }
}

export default connect(mapStateToProps, {
  fetchInsurableValues: fetchLatestInsurableValues,
  updateInsurableValues,
})(InsurableValues)
