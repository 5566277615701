import { handleActions } from 'redux-actions'

import {
  clearProperties,
  searchPropertiesRequest,
  searchPropertiesFail,
  searchPropertiesReceive,
  searchPropertiesClear,
} from '../actions/properties'

const initialState = {
  isLoading: false,
  isLoaded: false,
  properties: [],
}

export const reducer = handleActions(
  {
    [searchPropertiesRequest](state, { payload }) {
      return {
        ...state,
        isLoading: true,
      }
    },
    [searchPropertiesReceive](state, { payload }) {
      return {
        ...state,
        isLoading: false,
        isLoaded: true,
        properties: payload,
      }
    },
    [searchPropertiesFail](state, { payload }) {
      return {
        ...state,
        isLoading: false,
      }
    },
    [searchPropertiesClear]() {
      return {
        ...initialState,
        isLoaded: false,
      }
    },
    [clearProperties](state) {
      return {
        ...initialState,
      }
    },
  },
  initialState
)
