import React from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core'

import Number from '../Number/Number'
import { toPercents, fromPercents } from '../../utils/numberOperations'

const styles = {
  wrapper: {
    display: 'flex',
  },
  betweenLabel: {
    textAlign: 'center',
    margin: '25px 15px',
  },
}

const numberProps = {
  thousandSeparator: true,
  allowNegative: false,
}

const percentageProps = {
  decimalScale: 2,
  format: toPercents,
  normalize: fromPercents,
  adornment: '%',
}

const InputRangeField = ({
  classes,
  name,
  minLabel,
  maxLabel,
  betweenLabel,
  minPlaceholder,
  maxPlaceholder,
  ...props
}) => (
  <div className={classes.wrapper}>
    <Number
      data-qa={`${name}-min-input`}
      name={`${name}.min`}
      label={minLabel}
      placeholder={minPlaceholder}
      {...props}
    />
    {betweenLabel && <div className={classes.betweenLabel}>{betweenLabel}</div>}
    <Number
      data-qa={`${name}-max-input`}
      name={`${name}.max`}
      label={maxLabel}
      placeholder={maxPlaceholder}
      {...props}
    />
  </div>
)

InputRangeField.propTypes = {
  classes: PropTypes.object.isRequired,
  name: PropTypes.string.isRequired,
  minLabel: PropTypes.string,
  maxLabel: PropTypes.string,
  betweenLabel: PropTypes.string,
  minPlaceholder: PropTypes.string,
  maxPlaceholder: PropTypes.string,
}

InputRangeField.defaultProps = {
  minLabel: '',
  maxLabel: '',
  betweenLabel: null,
  minPlaceholder: '',
  maxPlaceholder: '',
}

const InputRangeFieldWithStyles = withStyles(styles)(InputRangeField)

export const NumberRange = ({ ...props }) => {
  return <InputRangeFieldWithStyles {...numberProps} {...props} />
}

export const PercentageRange = ({ ...props }) => {
  return <InputRangeFieldWithStyles {...percentageProps} {...props} />
}

export default InputRangeFieldWithStyles
