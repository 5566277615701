import { withStyles } from '@material-ui/core'

import Text from '../Text'

export default withStyles({
  formControl: {
    margin: 0,
    fontSize: '14px',
    '& fieldset': {
      display: 'none',
    },
  },
  root: {
    fontSize: 'inherit',
    margin: 0,
    height: 'inherit',
  },
  input: {
    fontSize: 'inherit',
    padding: 0,
    paddingLeft: 1,
  },
})(Text)
