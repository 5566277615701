import { get } from 'lodash'

export const mapDTO = formValues => {
  const {
    address,
    interestAppraisedAsIsMarketValue,
    interestAppraisedAsComplete,
    interestAppraisedAsStabilized,
  } = formValues

  const includeAsComplete = !!interestAppraisedAsComplete
  const includeAsStabilized = !!interestAppraisedAsStabilized
  const excludeAsStabilized = !includeAsStabilized
  const onlyIncludeAsIs = !includeAsComplete && !includeAsStabilized

  return {
    address,
    onlyIncludeAsIs,
    includeAsComplete,
    includeAsStabilized,
    excludeAsStabilized,
    interestAppraisedAsIsMarketValue,
    interestAppraisedAsComplete,
    interestAppraisedAsStabilized,
  }
}

export const mapDataModel = report => {
  const address = get(report, 'property_information.address')
  const { interestAppraisedAsIsMarketValue, interestAppraisedAsComplete, interestAppraisedAsStabilized } = get(
    report,
    'info',
    {}
  )

  const includeAsComplete = !!interestAppraisedAsComplete
  const includeAsStabilized = !!interestAppraisedAsStabilized
  const excludeAsStabilized = !includeAsStabilized
  const onlyIncludeAsIs = !includeAsComplete && !includeAsStabilized

  return {
    address,
    onlyIncludeAsIs,
    includeAsComplete,
    includeAsStabilized,
    excludeAsStabilized,
    interestAppraisedAsIsMarketValue,
    interestAppraisedAsComplete,
    interestAppraisedAsStabilized,
  }
}
