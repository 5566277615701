import React from 'react'

import InfoIcon from '@mui/icons-material/InfoOutlined'
import { Grid, Tooltip, Typography } from '@mui/material'
import { makeStyles } from '@mui/styles'
import { Field } from 'react-final-form'

import { CheckboxWithLabel } from '../../../shared/components'
import { objectToKeyValuePairs } from '../../../shared/components/helpers'
import SwitchButton from '../../../shared/components/SwitchButton/SwitchButton'

import { REPORT_TYPE_LABELS, TOOLTIP_TEXT, TOOL_SELECTION_ITEMS } from './constants'

const checkboxItems = objectToKeyValuePairs(TOOL_SELECTION_ITEMS)

const useStyles = makeStyles(theme => ({
  createModal: {
    display: 'flex',
    height: 440,
    justifyContent: 'center',
    alignContent: 'flex-start',
  },
  tooltipIcon: {
    color: theme.palette.secondary[700],
    marginLeft: 6,
    '&:hover': {
      cursor: 'pointer',
    },
  },
  tooltip: {
    backgroundColor: theme.palette.grey[600],
    padding: '8px 16px',
  },
  label: {
    width: 137,
  },
  toolSelectionContainer: {
    paddingTop: 10,
  },
  blocksReportButtons: {
    paddingBottom: 10,
  },
  checkboxItem: {
    height: 44,
  },
}))

const SwitchButtonAdapter = ({ input: { onChange, value = false }, label, ...rest }) => (
  <SwitchButton
    label={label}
    items={REPORT_TYPE_LABELS}
    value={value}
    onChange={newValue => onChange(newValue)}
    {...rest}
  />
)

export const BlocksReportButtons = ({ name }) => {
  const classes = useStyles()

  return (
    <Grid item className={classes.blocksReportButtons}>
      <Field name={name} component={SwitchButtonAdapter} defaultValue={false} />
    </Grid>
  )
}

export const ToolSelection = () => {
  const classes = useStyles()

  return (
    <Grid container className={classes.toolSelectionContainer}>
      <Typography variant="body1">Tool Selection</Typography>
      <Tooltip classes={{ root: classes.tooltip }} title={TOOLTIP_TEXT} placement="right">
        <InfoIcon className={classes.tooltipIcon} />
      </Tooltip>
    </Grid>
  )
}

const atLeastOneChecked = (value, allValues) => {
  const blocksConfiguration = allValues.settings.blocksConfiguration
  return Object.values(blocksConfiguration).filter(val => val).length === 0
}

export const ToolSelectionCheckboxes = ({ blocksConfiguration, disabled }) => {
  const classes = useStyles()

  if (blocksConfiguration) {
    return (
      <Grid container>
        <ToolSelection />
        {checkboxItems.map(item => {
          return (
            <CheckboxWithLabel
              label={item.label}
              name={`settings.blocksConfiguration.${item.value}`}
              key={item.label}
              initialValue={blocksConfiguration[item.value]}
              disabled={disabled}
            />
          )
        })}
      </Grid>
    )
  }
  return (
    <Grid container>
      <ToolSelection />
      <Grid container className={classes.createModal}>
        {checkboxItems.map(item => {
          return (
            <CheckboxWithLabel
              label={item.label}
              name={`settings.blocksConfiguration.${item.value}`}
              key={item.label}
              validate={atLeastOneChecked}
              defaultValue={true}
            />
          )
        })}
      </Grid>
    </Grid>
  )
}
