import { gtTemplate, gtConditional, gtIfElse, gtLocked, GeneratedText } from 'shared/utils/narrative/GeneratedText'

import { mapDTO, mapDataModel } from './mappings'

const generate = () => {
  const gt = new GeneratedText()
  const downwardCompsSentence = gtLocked('downwardCompsSentence')
  const upwardCompsSentence = gtLocked('upwardCompsSentence')

  const downwardsMultiples = gtConditional('hasMultipleDownwardAdjustments', gtTemplate`s`)
  const downwardsAdjustmentSentence = gtConditional(
    'hasDownwardAdjustments',
    gtTemplate` Comparable${downwardsMultiples} ${downwardCompsSentence} ${gtIfElse(
      'hasMultipleDownwardAdjustments',
      gtTemplate`have`,
      gtTemplate`has`
    )} a larger average unit size as compared to the subject, and thus required ${gtIfElse(
      'hasDownwardAdjustments',
      gtTemplate``,
      gtTemplate` a`
    )} negative adjustment${downwardsMultiples}.`
  )

  const upwardsMultiples = gtConditional('hasMultipleUpwardAdjustments', gtTemplate`s`)

  const upwardsAdjustmentSentence = gtConditional(
    'hasUpwardAdjustments',
    gtTemplate` Comparable${upwardsMultiples} ${upwardCompsSentence} ${gtIfElse(
      'hasMultipleUpwardAdjustments',
      gtTemplate`have`,
      gtTemplate`has`
    )}  a smaller average unit size as compared to the subject, and thus required${gtIfElse(
      'hasMultipleUpwardAdjustments',
      gtTemplate``,
      gtTemplate` a`
    )} positive adjustment${upwardsMultiples}.`
  )
  const adjustmentsSentences = gtIfElse(
    'hasAdjustments',
    gtTemplate`The comparable properties are analyzed on price per unit basis; however, the average unit 
    size can vary among the comparable properties. Typically there is a direct correlation between the average 
    size of a unit and the price per unit. ${downwardsAdjustmentSentence}${upwardsAdjustmentSentence}`,
    gtTemplate` The comparables have an average unit size similar condition to the subject, and no adjustment 
    was warranted.`
  )

  gt.t`${adjustmentsSentences}`
  return gt.getParts()
}

export default { generate, mapDTO, mapDataModel }
