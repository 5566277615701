import React from 'react'
import PropTypes from 'prop-types'

import Loading from '../../Loading'
import * as Api from '../../../../core/api'

const withSearch = Component => {
  return class extends React.PureComponent {
    static propTypes = {
      values: PropTypes.object,
      onSearchComplete: PropTypes.func.isRequired,
    }

    static defaultProps = {
      values: {},
    }

    state = {
      isLoading: false,
    }

    searchProperties = async () => {
      const { values, onSearchComplete } = this.props
      const query = Component.buildQuery(values)
      if (!query) {
        return
      }

      this.setState({ isLoading: true })
      try {
        const properties = await Api.searchProperties(query)
        onSearchComplete({ properties, locationIdentifier: query.locationIdentifier })
      } catch (error) {
        console.log(error)
      }
      this.setState({ isLoading: false })
    }

    render() {
      const { isLoading } = this.state
      return (
        <React.Fragment>
          {isLoading && <Loading />}
          <Component {...this.props} searchProperties={this.searchProperties} />
        </React.Fragment>
      )
    }
  }
}

export default withSearch
