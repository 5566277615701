import { GeneratedText } from '../../../../narrative/GeneratedText'

export const INDIRECT_COSTS_INTRO =
  'Several indirect cost items are not included in the base unit building cost derived through the MVS cost guide. These items include:\n' +
  "\u2022 Developer's overhead (general and administrative costs)\n" +
  '\u2022 Professional fees\n' +
  '\u2022 Property taxes\n' +
  '\u2022 Legal and insurance costs\n' +
  '\u2022 Local development fees and contingencies\n' +
  '\u2022 Lease-up and marketing costs\n' +
  '\u2022 Miscellaneous costs'

const generate = () => {
  const gt = new GeneratedText()

  gt.t`${INDIRECT_COSTS_INTRO}`

  return gt.getParts()
}

export default { generate }
