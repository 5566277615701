import React, { useState } from 'react'
import PropTypes from 'prop-types'

import { connect } from 'react-redux'
import OpenInNewOutlinedIcon from '@mui/icons-material/OpenInNewOutlined'
import TakeoutDiningOutlinedIcon from '@mui/icons-material/TakeoutDiningOutlined'
import MonetizationOnOutlinedIcon from '@mui/icons-material/MonetizationOnOutlined'
import { get } from 'lodash'
import { Box, Link, Paper, Popper, Stack } from '@mui/material'
import { GEOGRAPHY_OPTIONS } from 'shared/constants/property/geography'

import { BOROUGH_NUMBER } from '../../../shared/constants/borough'
import { Layout as DrawerConstants } from '../drawer/constants'

import { DROPBOX_LINK_TITLE, SF_LINK_TITLE, ZOLA_LINK_TITLE, ZOLA_LOT_LINK } from './constants'

const ReportInfoLink = props => {
  const [open, setOpen] = useState(false)
  const [anchorEl, setAnchorEl] = useState(null)

  const handleHover = state => event => {
    setAnchorEl(event.currentTarget)
    setOpen(state)
  }

  const buildLinks = () => {
    const { isNYCReport, borough, block, lot, salesforceLink, dropboxLink } = props
    const links = []

    if (isNYCReport) {
      const boroughNumber = BOROUGH_NUMBER[borough]
      const zolaUrl = `${ZOLA_LOT_LINK}/${boroughNumber}/${block}/${lot}`

      links.push({
        url: zolaUrl,
        title: ZOLA_LINK_TITLE,
        icon: OpenInNewOutlinedIcon,
      })
    }

    if (salesforceLink) {
      links.push({
        url: salesforceLink,
        title: SF_LINK_TITLE,
        icon: MonetizationOnOutlinedIcon,
      })
    }

    if (dropboxLink) {
      links.push({
        url: dropboxLink,
        title: DROPBOX_LINK_TITLE,
        icon: TakeoutDiningOutlinedIcon,
      })
    }

    return links.map(link => {
      const Icon = link.icon
      return (
        <Link
          href={link.url}
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            gap: '8px',
            paddingY: '16px',
            borderBottom: '1px solid #E5E5E5',
            fontWeight: 700,
            '&:last-child': { borderBottom: 0 },
            '&:hover': { color: '#010E44' },
          }}
          underline="none"
          target="_blank"
          rel="noreferrer"
          key={link.url}
        >
          {link.title}
          <Icon fontSize="19px" />
        </Link>
      )
    })
  }

  const links = buildLinks()

  return (
    <Box
      onMouseLeave={handleHover(false)}
      sx={{
        display: 'flex',
        justifyContent: 'center',
        width: DrawerConstants.MENU_WIDTH,
      }}
    >
      {links?.length > 0 && (
        <Box>
          <Box sx={{ color: open ? '#303F9F' : '#222222' }}>
            <OpenInNewOutlinedIcon cursor="pointer" onMouseEnter={handleHover(true)} />
          </Box>
          <Popper disablePortal open={open} anchorEl={anchorEl} placement="bottom-end">
            <Paper sx={{ paddingY: 0 }}>
              <Stack>{links}</Stack>
            </Paper>
          </Popper>
        </Box>
      )}
    </Box>
  )
}

ReportInfoLink.propTypes = {
  block: PropTypes.string,
  borough: PropTypes.string,
  isNYCReport: PropTypes.bool.isRequired,
  lot: PropTypes.string,
  salesforceLink: PropTypes.string,
  dropboxLink: PropTypes.string,
}

ReportInfoLink.defaultProps = {
  block: null,
  borough: null,
  lot: null,
}

export default connect(state => {
  const reportData = get(state, 'report.reportData') || {}
  const { address, city, block, borough, lot, salesforceLink, dropboxLink } = reportData

  const locationIdentifier = reportData.locationIdentifier

  return {
    isNYCReport: locationIdentifier === GEOGRAPHY_OPTIONS.NY,
    address,
    city,
    block,
    lot,
    borough,
    salesforceLink,
    dropboxLink,
  }
})(ReportInfoLink)
