import React from 'react'

import { isNumber, first, sumBy, get } from 'lodash'

import { RentRollColumns, RENTROLL_COLUMN_NAMES, BUILDING_LABEL_VALUE_PAIR } from '../../constants'

import { RentRollTable } from './RentRollTable'
import { calculateRentPerRoomFromUnit } from './helpers'

export const DATA_PATH = [BUILDING_LABEL_VALUE_PAIR.UnitMix.menuItem]

class RentRollTableContainer extends React.PureComponent {
  componentDidMount() {
    if (this.props.fields.length === 0) {
      this.props.fields.push({})
    }
  }

  onAddUnit = () => {
    const { fields } = this.props
    fields.push({})
  }

  onRemoveUnit = index => {
    const { form, fields } = this.props
    const { units } = form.values

    if (isNumber(index)) {
      return form.change(
        'units',
        units.filter((item, key) => key !== index)
      )
    }

    fields.pop()
  }

  onChangeUnitsCount = value => {
    const { fields } = this.props
    let length = fields.length
    while (length < value) {
      fields.push({})
      ++length
    }
    while (length > value) {
      fields.pop()
      --length
    }
  }

  onImportCsv = files => {
    this.props.buildingCompRentRollUploadCsv({
      dataPath: DATA_PATH,
      file: first(files),
    })
  }

  render() {
    const {
      fields,
      form: { values },
      ...props
    } = this.props
    const units = get(values, 'units') || []

    return (
      <RentRollTable
        columns={RentRollColumns}
        getUnitRentPerRoom={calculateRentPerRoomFromUnit}
        name={fields.name}
        onAddUnit={this.onAddUnit}
        onChangeUnitsCount={this.onChangeUnitsCount}
        onImportCsv={this.onImportCsv}
        onRemoveUnit={this.onRemoveUnit}
        rentTotalAnnual={sumBy(units, RENTROLL_COLUMN_NAMES.RENT) * 12 || 0}
        rentTotalMonthly={sumBy(units, RENTROLL_COLUMN_NAMES.RENT) || 0}
        units={units}
        {...props}
      />
    )
  }
}

export default RentRollTableContainer
