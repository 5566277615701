import BoweryDate from '@bowery-valuation/bowery-date'

import { SentenceBuilder } from '../../../../../../../libs/bowery-libs/export-schemas/helpers'

export const generateFloodHazardDiscussion = values => {
  const { floodData } = values
  const { panel, zone, effectiveDate } = floodData

  switch (zone) {
    case 'A':
      return getZoneAParagraph(panel, zone, effectiveDate)
    case 'AE':
      return getZoneAEParagraph(panel, zone, effectiveDate)
    case 'AO':
      return getZoneAOParagraph(panel, zone, effectiveDate)
    case 'Open Water':
      return getOpenWaterSentence()
    case 'X':
      return getZoneXParagraph(panel, zone, effectiveDate)
    case 'VE':
      return getZoneVEParagraph(panel, zone, effectiveDate)
    case '20 PCT ANNUAL CHANCE FLOOD HAZARD':
      return getFloodHazardParagraph(panel, zone, effectiveDate)
    default:
      return getSubjectLocationSentence(panel, zone, effectiveDate)
  }
}

const getSubjectLocationSentence = (panel, zone, effectiveDate) => {
  let sentence = `According to National Flood Insurance Program Rate Map dated ${
    effectiveDate ? new BoweryDate(effectiveDate).format('MMMM D, YYYY') : 'September 5, 2007'
  } Community Panel ${panel} the subject is`

  if (!zone) {
    sentence += ' not located in a flood zone.'
    return sentence
  }

  sentence += ' located within a'

  if (zone === '20 PCT ANNUAL CHANCE FLOOD HAZARD') {
    sentence += ' 0.20% Annual Chance of Flood Hazard Zone.'
  } else {
    sentence += ` Zone ${zone} flood zone.`
  }

  return sentence
}

const getInsurancePurchaseSentence = floodZone => {
  if (floodZone === '20 PCT ANNUAL CHANCE FLOOD HAZARD') {
    return 'Insurance purchase is not required in these zones.'
  }

  if (floodZone === 'X') {
    return ''
  }

  return 'Mandatory flood insurance purchase requirements apply.'
}

const getOpenWaterSentence = () => {
  return SentenceBuilder.createFrom([
    'Uh oh - you’re underwater!',
    'Please confirm that the subject’s flood information is correct:',
  ])
}

const getZoneAParagraph = (panel, zone, effectiveDate) => {
  const paragraphArr = [getSubjectLocationSentence(panel, zone, effectiveDate)]

  paragraphArr.push(
    'Zone A is the flood insurance rate zone that corresponds to the 1-percent annual chance floodplains that are determined in the Flood Insurance Study by approximate methods of analysis.'
  )

  paragraphArr.push(
    'Because detailed hydraulic analyses are not performed for such areas, no Base Flood Elevations or depths are shown within this zone.'
  )

  paragraphArr.push(getInsurancePurchaseSentence(zone))

  return paragraphArr.join(' ')
}

const getZoneAEParagraph = (panel, zone, effectiveDate) => {
  const paragraphArr = [getSubjectLocationSentence(panel, zone, effectiveDate)]

  paragraphArr.push(
    'Zones AE is the flood insurance rate zone that corresponds to the 1-percent annual chance floodplains that are determined in the Flood Insurance Study by detailed methods of analysis.'
  )

  paragraphArr.push(
    'In most instances, Base Flood Elevations derived from the detailed hydraulic analyses are shown at selected intervals within this zone.'
  )

  paragraphArr.push(getInsurancePurchaseSentence(zone))

  return paragraphArr.join(' ')
}

const getZoneAOParagraph = (panel, zone, effectiveDate) => {
  const paragraphArr = [getSubjectLocationSentence(panel, zone, effectiveDate)]

  paragraphArr.push(
    'Zone AO is the flood insurance rate zone that corresponds to the areas of 1-percent shallow flooding (usually sheet flow on sloping terrain) where average depths are between 1 and 3 feet.'
  )

  paragraphArr.push('Average flood depths derived from the detailed hydraulic analyses are shown within this zone.')

  paragraphArr.push('In addition, alluvial fan flood hazards are shown as Zone AO on the Flood Insurance Rate Map.')

  paragraphArr.push(getInsurancePurchaseSentence(zone))

  return paragraphArr.join(' ')
}

const getZoneXParagraph = (panel, zone, effectiveDate) => {
  const paragraphArr = [getSubjectLocationSentence(panel, zone, effectiveDate)]

  paragraphArr.push('Zone X is an area of minimal flooding.')

  paragraphArr.push(
    'These areas are determined to be outside the 500-year floodplain and are determined to be outside the 1% and 0.2% annual chance floodplains.'
  )

  paragraphArr.push(getInsurancePurchaseSentence(zone))

  return paragraphArr.join(' ')
}

const getZoneVEParagraph = (panel, zone, effectiveDate) => {
  const paragraphArr = [getSubjectLocationSentence(panel, zone, effectiveDate)]

  paragraphArr.push(
    'Zone VE is the flood insurance rate zone that corresponds to areas within the 1-percent annual chance coastal floodplain that have additional hazards associated with storm waves.'
  )

  paragraphArr.push(
    'Base Flood Elevations derived from the detailed hydraulic analyses are shown at selected intervals within this zone.'
  )

  paragraphArr.push(getInsurancePurchaseSentence(zone))

  return paragraphArr.join(' ')
}

const getFloodHazardParagraph = (panel, zone, effectiveDate) => {
  const paragraphArr = [getSubjectLocationSentence(panel, zone, effectiveDate)]

  paragraphArr.push('This zone was previously identified as ‘X100’.')

  paragraphArr.push(
    'Areas outside the 1-percent annual chance floodplain, areas of 1% annual chance sheet flow flooding where average depths are less than 1 foot, areas of 1% annual chance stream flooding where the contributing drainage area is less than 1 square mile, or areas protected from the 1% annual chance flood by levees.'
  )

  paragraphArr.push('No Base Flood Elevations or depths are shown within this zone.')

  paragraphArr.push(getInsurancePurchaseSentence(zone))

  return paragraphArr.join(' ')
}
