import React from 'react'
import PropTypes from 'prop-types'
import { get } from 'lodash'
import { Grid, withStyles } from '@material-ui/core'

import {
  ColumnDataTypeEnum,
  RowBasedTable,
  CELL_COLUMN_CONFIGURATIONS,
  useRowsApi,
} from '@bowery-valuation/ui-components'

import { Field } from 'react-final-form'

import { getTaxLiabilityLabel } from 'shared/report-calculations/income-approach/tax/helpers'

import TaxCalculations from 'shared/report-calculations/income-approach/tax/tax-calculations'

import {
  formatCurrencyFloat,
  formatCurrencyInt,
  formatPercentageString,
  toPercentageString,
} from '../../../../shared/utils/numberFormatters'
import { GeneratedComment, CheckboxWithLabel, ButtonSwitch } from '../../../../shared/components'

import { LABELS } from '../../../../../../shared/constants/report/incomeApproach/taxes'

import { useRegisteredFields } from './hooks'

import AddTaxRateButton from './AddTaxRateButton'

import {
  INCOME_TYPE_OPTIONS,
  CELL_EDITOR_SELECTORS,
  valueCellRendererFramework,
  getCustomColumnConfig,
  projectedTabNoopFieldValues,
} from './constants'
import styles from './styles'

const getPercentOfIncomeApproachWriteup = values => {
  const taxLiabilityRatio = toPercentageString(get(values, 'projected.percentOfIncome.liabilityRatio'))
  return (
    'The percent of income method takes a percentage of the effective gross income multiplied by a target tax' +
    ` liability percentage to calculate a projected tax liability. We have applied a ${taxLiabilityRatio} target` +
    ' tax liability percentage to project a tax liability and thus an implied taxable assessed value.'
  )
}

const ROW_IDS = {
  income: 'income',
  liabilityRatio: 'liabilityRatio',
}

const FIELD_PATHS = {
  additionalTaxRates: 'projected.percentOfIncome.additionalTaxRates',
  liabilityRatio: 'projected.percentOfIncome.liabilityRatio',
  income: 'projected.percentOfIncome.income',
}

const columns = [
  {
    name: 'label',
    label: 'Item',
    suppressMovable: true,
    itemIndex: 0,
    type: ColumnDataTypeEnum.text,
    permanent: true,
    align: 'left',
    editable: params => {
      return !!params.data.isEditableRow
    },
  },
  {
    name: 'value',
    label: 'Value',
    suppressMovable: true,
    itemIndex: 1,
    type: ColumnDataTypeEnum.text,
    permanent: true,
    align: 'right',
    editable: params => {
      return (
        !!params.data.isEditableRow || params.data.id === ROW_IDS.income || params.data.id === ROW_IDS.liabilityRatio
      )
    },
    cellEditorSelector: ({ data }) => {
      if (data.id === ROW_IDS.liabilityRatio) {
        return CELL_EDITOR_SELECTORS.percentTwoDecimals
      }

      if (data.id === ROW_IDS.income) {
        return CELL_EDITOR_SELECTORS.moneyNoDecimals
      }

      if (data.isAdditionalTaxRateRow) {
        return CELL_EDITOR_SELECTORS.taxRate
      }

      return CELL_COLUMN_CONFIGURATIONS[data.type]
    },
    cellRendererFramework: valueCellRendererFramework,
  },
]

const PercentOfIncomeApproach = ({ taxRate, taxRateName, form, classes }) => {
  const additionalTaxRatesRowsApi = useRowsApi(FIELD_PATHS.additionalTaxRates)

  const handleRowUpdate = row => {
    if (row.id === ROW_IDS.liabilityRatio) {
      form.change(FIELD_PATHS.liabilityRatio, row.value)
    } else if (row.id === ROW_IDS.income) {
      form.change(FIELD_PATHS.income, row.value)
    } else if (row.isAdditionalTaxRateRow) {
      additionalTaxRatesRowsApi.updateRow(row)
    }
  }
  const handleManyRowsUpdate = rows => {
    rows.forEach(row => {
      handleRowUpdate(row)
    })
  }
  const handleRowDelete = rowId => {
    const formValuesTemp = form.getState().values
    const taxRates = formValuesTemp.projected.percentOfIncome.additionalTaxRates || []
    const rowIsTaxRate = taxRates.some(row => row.id === rowId)

    if (rowIsTaxRate) {
      additionalTaxRatesRowsApi.deleteRow(rowId)
    }
  }
  const handleCellFocus = event => {
    const rowIndex = event.rowIndex
    const columnId = event.column.colId
    const row = percentOfIncomeRows[rowIndex]

    if (row.isAdditionalTaxRateRow) {
      const additionalTaxRatesRowIndex = additionalTaxRates.findIndex(({ id }) => id === row.id)
      form.focus(`${FIELD_PATHS.additionalTaxRates}[${additionalTaxRatesRowIndex}].${columnId}`)
    } else if (row.id === ROW_IDS.income) {
      form.focus(FIELD_PATHS.income)
    } else if (row.id === ROW_IDS.liabilityRatio) {
      form.focus(FIELD_PATHS.liabilityRatio)
    }
  }

  const { basis, residentialUnitCount, projected, squareFootage } = form.values
  const taxableAssessedValue = get(form, 'values.projected.percentOfIncome.taxableAssessedValue')
  const income = get(form, 'values.projected.percentOfIncome.income')
  const liabilityRatio = get(form, 'values.projected.percentOfIncome.liabilityRatio')
  const additionalTaxRates = get(form, 'values.projected.percentOfIncome.additionalTaxRates', [])
  const taxLiability = TaxCalculations.calculateTaxLiabilityByLiabilityRatio(income, liabilityRatio)
  const includedInExport = get(projected, 'percentOfIncome.includedInExport', false)
  const basisLabel = getTaxLiabilityLabel(basis)
  const taxesPerBasis = TaxCalculations.calculateTaxesPerBasis(basis, taxLiability, squareFootage, residentialUnitCount)
  const incomeType = get(form, 'values.projected.percentOfIncome.incomeType')
  const incomeTotals = get(form, 'values.incomeTotals', {})
  const taxRateRegisteredFields = useRegisteredFields(
    'projected.percentOfIncome.additionalTaxRates',
    columns,
    additionalTaxRates
  )

  const percentOfIncomeRows = [
    {
      readOnly: false,
      suppressMovable: true,
      permanent: false,
      type: ColumnDataTypeEnum.text,
      label: LABELS.INCOME,
      id: ROW_IDS.income,
      value: income ? formatCurrencyInt(income) : '$0',
      rowDef: { hideAction: true },
    },
    {
      readOnly: false,
      suppressMovable: true,
      permanent: false,
      type: ColumnDataTypeEnum.text,
      label: LABELS.INCOME_TAX_RATE,
      value: liabilityRatio ? formatPercentageString(liabilityRatio) : '0.00%',
      id: ROW_IDS.liabilityRatio,
      rowDef: { hideAction: true },
    },
    {
      readOnly: true,
      permanent: true,
      type: ColumnDataTypeEnum.text,
      suppressMovable: true,
      label: LABELS.TAX_LIABILITY_TOTAL,
      id: 'taxLiabilityTotal',
      value: formatCurrencyInt(taxLiability),
      rowDef: { summary: true, hideAction: true },
    },
    {
      readOnly: true,
      permanent: true,
      suppressMovable: true,
      type: ColumnDataTypeEnum.text,
      label: basisLabel,
      id: 'taxesPerBasis',
      value: formatCurrencyFloat(taxesPerBasis),
      rowDef: { hideAction: true },
    },
    {
      readOnly: true,
      permanent: true,
      suppressMovable: true,
      type: ColumnDataTypeEnum.text,
      label: `${LABELS.TAX_RATE} (${taxRateName})`,
      id: 'taxRateValue',
      value: formatPercentageString(taxRate.value, 10),
      rowDef: { hideAction: true },
    },
    ...additionalTaxRates.map(rate => {
      return {
        id: rate._id,
        _id: rate._id,
        label: rate.label,
        value: rate.value,
        isAdditionalTaxRateRow: true,
        type: ColumnDataTypeEnum.percent,
        suppressMovable: true,
        permanent: false,
        isEditableRow: true,
      }
    }),
    {
      readOnly: true,
      suppressMovable: true,
      permanent: true,
      type: ColumnDataTypeEnum.text,
      label: LABELS.IMPLIED_TAXABLE_ASSESSED_VALUE,
      value: formatCurrencyInt(taxableAssessedValue),
      id: 'impliedTaxableAssessedValue',
      rowDef: { hideAction: true },
    },
  ]

  return (
    <Grid container justify="space-between" alignItems="center">
      <Grid item xs={12}>
        <Field name={FIELD_PATHS.income}>{() => null}</Field>
        <Field name={FIELD_PATHS.liabilityRatio}>{() => null}</Field>
        <CheckboxWithLabel name="projected.percentOfIncome.includedInExport" label="Include in export" />
      </Grid>
      {includedInExport && (
        <>
          <Grid item xs={6} className={classes.buttonSwitch}>
            <ButtonSwitch
              name="projected.percentOfIncome.incomeType"
              options={INCOME_TYPE_OPTIONS}
              label={() => <span>Income Type</span>}
              annotation={() => <span>{`${incomeType} = ${formatCurrencyInt(incomeTotals[incomeType])}`}</span>}
            />
          </Grid>
          <Grid item>
            <AddTaxRateButton form={form} path="projected.percentOfIncome" />
          </Grid>
          <Grid item xs={12}>
            {taxRateRegisteredFields}
            <RowBasedTable
              id="percent-of-income"
              columns={columns}
              rows={percentOfIncomeRows}
              {...projectedTabNoopFieldValues}
              onRowDelete={handleRowDelete}
              onRowUpdate={handleRowUpdate}
              onManyRowsUpdate={handleManyRowsUpdate}
              getCustomColumnConfig={getCustomColumnConfig}
              onCellFocused={handleCellFocus}
            />

            <Grid item xl={12} className={classes.discussionContainer}>
              <GeneratedComment
                className={classes.title}
                isDynamicContent
                dataPath="projected.percentOfIncome.discussion"
                title="Generated Commentary"
                label="Percent of Income Discussion"
                getGeneratedText={getPercentOfIncomeApproachWriteup}
              />
            </Grid>
          </Grid>
        </>
      )}
    </Grid>
  )
}

PercentOfIncomeApproach.propTypes = {
  taxRate: PropTypes.shape({
    value: PropTypes.number,
    effectiveYear: PropTypes.string,
  }).isRequired,
  taxRateName: PropTypes.string,
}

export default withStyles(styles)(PercentOfIncomeApproach)
