import React from 'react'
import PropTypes from 'prop-types'

import { Button, IconButton, Table, TableBody, TableCell, TableHead, TableRow } from '@mui/material'
import { withStyles } from '@mui/styles'
import classnames from 'classnames'
import { eq, get, sortBy, startCase, upperFirst } from 'lodash'
import { FieldArray } from 'react-final-form-arrays'
import Create from '@mui/icons-material/Create'
import Cancel from '@mui/icons-material/Cancel'

import TableThemeProvider from '../../../components/TableThemeProvider'

import TermModal from './TermModal'

const styles = theme => ({
  table: {
    border: 'solid 1px rgba(0, 0, 0, 0.38)',
    borderRadius: 4,
    overflow: 'hidden',
    borderCollapse: 'initial',
    borderBottom: 'none',
  },
  addButton: {
    width: 96,
    letterSpacing: 1.3,
    margin: 0,
    marginBottom: 16,
  },
  headerCell: {
    lineHeight: 1.33,
    letterSpacing: 0.4,
    backgroundColor: 'rgba(0, 0, 0, 0.54)',
    color: 'white',
    fontSize: 12,
    fontWeight: 400,
    '&:last-child': {
      verticalAlign: 'middle',
      padding: '0 8px 0 0',
    },
  },
  termCell: {
    width: 200,
    fontWeight: 'bold',
    color: 'rgba(0, 0, 0, 0.56)',
  },
  bodyCell: {
    lineHeight: 1.43,
    letterSpacing: 0.3,
    padding: 16,
    verticalAlign: 'top',
    wordBreak: 'break-word',
    color: 'rgba(0, 0, 0, 0.56)',
    borderBottom: 'solid 1px rgba(0, 0, 0, 0.38)',
  },
  definitionContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  definitionTextContainer: {
    display: 'inline-block',
  },
  actionsContainer: {
    display: 'flex',
    alignItems: 'center',
    paddingLeft: 16,
  },
})

class Edit extends React.PureComponent {
  static propTypes = {
    classes: PropTypes.object.isRequired,
    change: PropTypes.func.isRequired,
    removeTerm: PropTypes.func.isRequired,
    terms: PropTypes.arrayOf(
      PropTypes.shape({
        term: PropTypes.string.isRequired,
        definition: PropTypes.string.isRequired,
      })
    ),
  }

  static defaultProps = {
    terms: [],
  }

  state = {
    isTermModalOpen: false,
    termIndex: -1,
  }

  toggleTermModal = (index = -1) => {
    this.setState(prevState => ({ isTermModalOpen: !prevState.isTermModalOpen, termIndex: index }))
  }

  updateTerm = ({ term, definition }, index) => {
    const { terms, change } = this.props

    if (index >= 0) {
      terms.splice(index, 1)
    }
    const newGlossary = [...terms, { term: startCase(term), definition: upperFirst(definition) }]
    const sortedGlossary = sortBy(newGlossary, item => item.term)
    change('terms', sortedGlossary)

    this.toggleTermModal()
  }

  renderTerms = ({ fields }) => {
    const values = get(fields, 'value', [])
    return values.map((item, index) => this.renderRow(item, index, fields.remove))
  }

  renderRow = (item, index, removeTerm) => {
    const { classes } = this.props
    return (
      <TableRow key={index}>
        <TableCell className={classnames(classes.bodyCell, classes.termCell)}>{item.term}</TableCell>
        <TableCell className={classes.bodyCell}>
          <React.Fragment>
            <div className={classes.definitionContainer}>
              <div className={classes.definitionTextContainer}>{item.definition}</div>
              {!item.isDefault && (
                <div className={classes.actionsContainer}>
                  <IconButton onClick={() => this.toggleTermModal(index)}>
                    <Create />
                  </IconButton>
                  <IconButton onClick={() => removeTerm(index)}>
                    <Cancel color="error" />
                  </IconButton>
                </div>
              )}
            </div>
          </React.Fragment>
        </TableCell>
      </TableRow>
    )
  }

  render() {
    const { isTermModalOpen, termIndex } = this.state
    const { classes, terms } = this.props

    return (
      <React.Fragment>
        <Button onClick={() => this.toggleTermModal()} variant="contained" size="medium" className={classes.addButton}>
          Add
        </Button>
        <TermModal
          isOpen={isTermModalOpen}
          updateTerm={term => this.updateTerm(term, termIndex)}
          close={this.toggleTermModal}
          term={terms[termIndex]}
          termIndex={termIndex}
        />

        <TableThemeProvider>
          <Table className={classes.table}>
            <TableHead>
              <TableRow>
                <TableCell className={classes.headerCell}>Term</TableCell>
                <TableCell className={classes.headerCell}>Definition</TableCell>
              </TableRow>
            </TableHead>

            <TableBody>
              <FieldArray isEqual={eq} name="terms">
                {this.renderTerms}
              </FieldArray>
            </TableBody>
          </Table>
        </TableThemeProvider>
      </React.Fragment>
    )
  }
}

export default withStyles(styles)(Edit)
