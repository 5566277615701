import React from 'react'
import {
  Dialog,
  Typography,
  Grid,
  DialogTitle,
  DialogContent,
  Table,
  TableHead,
  TableBody,
  TableCell,
  TableRow,
  Checkbox,
} from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'
import { find, get, xor, uniq, includes, concat, cloneDeep } from 'lodash'

import { getCapitalizedUseLabel } from 'shared/helpers/commercialUses'
import { formatCurrencyInt } from 'client-shared/utils/numberFormatters'
import { RENT_LOSS_TYPE } from 'shared/constants/acas'

import { Button, SwitchButton } from '../.'

import '../../styles/fonts.css'
import { VALUE_CONCLUSION_TYPES } from '../../../report/constants/settings'

const styles = theme => {
  return {
    title: {
      '& h6': {
        fontFamily: 'Nunito Sans',
      },
    },
    subtitle: {
      fontFamily: 'Nunito Sans',
      fontSize: 14,
      color: '#222222',
      padding: '0px 4px 16px 4px',
    },
    modalContainer: {
      width: '100%',
    },
    disabledCell: {
      backgroundColor: '#F8FAFC',
      color: '#617083',
      fontFamily: 'Nunito Sans',
      fontSize: 14,
      padding: '0px 8px 0px 16px',
      lineHeight: '36px',
    },
    readOnlyCell: {
      fontFamily: 'Nunito Sans',
      fontSize: 14,
      background: '#FFFFFF',
      color: '#222222',
      padding: '0px 8px 0px 16px',
      lineHeight: '36px',
    },

    headerCell: {
      textAligh: 'left',
      fontFamily: 'Nunito Sans',
      fontSize: 12,
      fontWeight: 'bold',
      padding: '4px 8px 4px 16px',
      color: '#222222',
    },
    addButton: {
      background: '#4160D3',
      color: '#FFFFFF',
      borderRadius: 4,
      padding: '8px 16px',
      fontFamily: 'Nunito Sans',
      fontSize: 14,
    },
    cancelButton: {
      background: '#FFFFFF',
      color: '#4160D3',
      borderRadius: 4,
      padding: '8px 16px',
      fontFamily: 'Nunito Sans',
      fontSize: 14,
      borderColor: '#4160D3',
    },
    toggleButton: {
      width: '25%',
      fontFamily: 'Nunito Sans',
      fontSize: 14,
      background: '#FFFFFF',
      color: '#4160D3',
      borderColor: '#4160D3',
      padding: '4px 4px',
    },
    toggleSelectedButton: {
      width: '25%',
      fontFamily: 'Nunito Sans',
      fontSize: 14,
      background: '#4160D3',
      color: '#FFFFFF',
      padding: '4px 4px',
    },
  }
}

const collectionNameToggle = [
  { label: 'As Stabilized', value: 'asStabilizedCommercialRentLossItems' },
  { label: 'As Complete', value: 'asCompleteCommercialRentLossItems' },
]
class CommercialRentLossModal extends React.PureComponent {
  constructor(props) {
    super(props)
    const { editMode, collectionName, index, valueConclusionType } = this.props
    let collection = 'asCompleteCommercialRentLossItems'
    if (editMode) {
      collection = collectionName
    } else if (valueConclusionType === VALUE_CONCLUSION_TYPES.AS_STABILIZED) {
      collection = 'asStabilizedCommercialRentLossItems'
    }
    const lossItems = get(this.props, collection)
    const selectedUnits = editMode ? get(lossItems, `${index}.units`, null) : []
    const initialUnits = cloneDeep(selectedUnits)

    this.state = {
      selectedUnits,
      collection,
      disabled: true,
      initialUnits,
    }
  }

  closeCommercialLossModal = () => {
    const { closeCommercialRentLossModal } = this.props
    this.setState({ selectedUnits: [], collection: null, disabled: true })
    closeCommercialRentLossModal()
  }

  addCommercialUnits = () => {
    const { selectedUnits, collection } = this.state
    const { onAddRentLoss, commercialUnits, commercialVCLossPercentageAggregate } = this.props
    const unitIds = selectedUnits || []

    onAddRentLoss(collection, commercialUnits, unitIds, commercialVCLossPercentageAggregate, RENT_LOSS_TYPE.COMMERCIAL)
    this.setState({ selectedUnits: [], collection: null, disabled: true })
  }

  updateCommercialUnits = () => {
    const { selectedUnits } = this.state
    const { collectionName, index, onEditRentLoss, commercialUnits, commercialVCLossPercentageAggregate } = this.props

    onEditRentLoss(
      collectionName,
      index,
      commercialUnits,
      selectedUnits,
      commercialVCLossPercentageAggregate,
      RENT_LOSS_TYPE.COMMERCIAL
    )
    this.closeCommercialLossModal()
  }

  setCollection = value => {
    this.setState({ selectedUnits: [], collection: value, disabled: true })
  }

  updateUnitsSelected = event => {
    const { initialUnits } = this.state
    let { selectedUnits } = this.state
    const { editMode } = this.props
    const checked = event.target.checked
    const unitId = find(event.target.attributes, attribute => attribute.name === 'id')?.value

    if (unitId) {
      if (checked) {
        selectedUnits = selectedUnits.concat([unitId])
      } else {
        selectedUnits = selectedUnits.filter(selectedUnit => selectedUnit !== unitId.toString())
      }
      let disabled = false
      if (editMode) {
        disabled = xor(selectedUnits, initialUnits).length === 0
      } else {
        disabled = selectedUnits.length === 0
      }
      this.setState({ selectedUnits, disabled })
    }
  }

  getUnitsInRentLoss = collection => {
    let unitsInCollection = []
    collection.forEach(lossItem => {
      if (lossItem.units && lossItem.units.length > 0) {
        unitsInCollection = concat(unitsInCollection, lossItem.units)
      }
    })
    return uniq(unitsInCollection)
  }

  render() {
    const { collection, selectedUnits, disabled } = this.state
    const {
      classes,
      commercialUnits,
      editMode,
      index,
      valueConclusionType,
      asStabilizedCommercialRentLossItems,
      asCompleteCommercialRentLossItems,
    } = this.props

    const commercialRentLossItems = get(this.props, collection)
    const unitsInAsStabilized = this.getUnitsInRentLoss(asStabilizedCommercialRentLossItems)
    const unitsInAsComplete = this.getUnitsInRentLoss(asCompleteCommercialRentLossItems)

    return (
      <Dialog
        open
        disableBackdropClick
        maxWidth="md"
        onCancel={this.closeCommercialLossModal}
        className={classes.modalContainer}
      >
        <DialogTitle className={classes.title}>Add New Commercial Rent Loss</DialogTitle>
        <DialogContent>
          {valueConclusionType === VALUE_CONCLUSION_TYPES.AS_COMPLETE && !editMode && (
            <>
              <Typography className={classes.subtitle} variant="body2">
                Select the value conclusion you would like to apply rent loss to.
              </Typography>
              <SwitchButton
                value={collection}
                onChange={this.setCollection}
                items={collectionNameToggle}
                classes={{ button: classes.toggleButton, selectedButton: classes.toggleSelectedButton }}
                disabled={editMode}
              />
            </>
          )}
          <Typography className={classes.subtitle} variant="body2">
            Select the commercial units you would like to apply rent loss to.
          </Typography>
          <Table>
            <TableHead>
              <TableCell className={classes.headerCell}>Unit Number</TableCell>
              <TableCell className={classes.headerCell}>Tenant</TableCell>
              <TableCell className={classes.headerCell}>Use</TableCell>
              <TableCell className={classes.headerCell}>Monthly Rent ($)</TableCell>
              <TableCell className={classes.headerCell}>Lease Status</TableCell>
              <TableCell className={classes.headerCell}>Rent Loss</TableCell>
            </TableHead>
            <TableBody>
              {editMode ? (
                <>
                  {commercialUnits.map((unit, unitIndex) => {
                    const unitsInRentLossItems = this.getUnitsInRentLoss(commercialRentLossItems)
                    const disabled =
                      includes(unitsInRentLossItems, unit._id.toString()) &&
                      !includes(commercialRentLossItems[index].units, unit._id.toString())
                    const className = disabled ? classes.disabledCell : classes.readOnlyCell
                    unit.number = unitIndex + 1
                    return (
                      <TableRow key={`editing[${unitIndex}]`}>
                        <TableCell className={className}>{unit.number}</TableCell>
                        <TableCell className={className}>{unit.tenantName || 'N/A'}</TableCell>
                        <TableCell className={className}>{getCapitalizedUseLabel(unit.use)}</TableCell>
                        <TableCell className={className}>{formatCurrencyInt(unit.monthlyRent || 0)}</TableCell>
                        <TableCell className={className}>{unit.leaseStatus}</TableCell>
                        <TableCell className={className}>
                          {disabled ? (
                            <Checkbox disabled defaultChecked={true} />
                          ) : (
                            <Checkbox
                              id={unit._id.toString()}
                              onChange={this.updateUnitsSelected}
                              defaultChecked={selectedUnits.includes(unit._id.toString())}
                            />
                          )}
                        </TableCell>
                      </TableRow>
                    )
                  })}
                </>
              ) : (
                <>
                  {collection === 'asStabilizedCommercialRentLossItems' ? (
                    <>
                      {commercialUnits.map((unit, unitIndex) => {
                        const unitId = unit._id.toString()
                        const disabled = includes(unitsInAsStabilized, unitId)
                        const className = disabled ? classes.disabledCell : classes.readOnlyCell
                        unit.number = unitIndex + 1
                        return (
                          <TableRow key={`asStabilized[${unitIndex}]`}>
                            <TableCell className={className}>{unit.number}</TableCell>
                            <TableCell className={className}>{unit.tenantName || 'N/A'}</TableCell>
                            <TableCell className={className}>{getCapitalizedUseLabel(unit.use)}</TableCell>
                            <TableCell className={className}>{formatCurrencyInt(unit.monthlyRent || 0)}</TableCell>
                            <TableCell className={className}>{unit.leaseStatus}</TableCell>
                            <TableCell className={className}>
                              {disabled ? (
                                <Checkbox disabled checked />
                              ) : (
                                <Checkbox
                                  id={unit._id.toString()}
                                  onChange={this.updateUnitsSelected}
                                  defaultChecked={false}
                                />
                              )}
                            </TableCell>
                          </TableRow>
                        )
                      })}
                    </>
                  ) : (
                    <>
                      {commercialUnits.map((unit, unitIndex) => {
                        const unitId = unit._id.toString()
                        const disabled = includes(unitsInAsComplete, unitId)
                        const className = disabled ? classes.disabledCell : classes.readOnlyCell
                        unit.number = unitIndex + 1
                        return (
                          <TableRow key={`asComplete[${unitIndex}]`}>
                            <TableCell className={className}>{unit.number}</TableCell>
                            <TableCell className={className}>{unit.tenantName || 'N/A'}</TableCell>
                            <TableCell className={className}>{getCapitalizedUseLabel(unit.use)}</TableCell>
                            <TableCell className={className}>{formatCurrencyInt(unit.monthlyRent || 0)}</TableCell>
                            <TableCell className={className}>{unit.leaseStatus}</TableCell>
                            <TableCell className={className}>
                              {disabled ? (
                                <Checkbox disabled checked />
                              ) : (
                                <Checkbox
                                  id={unit._id.toString()}
                                  onChange={this.updateUnitsSelected}
                                  defaultChecked={false}
                                />
                              )}
                            </TableCell>
                          </TableRow>
                        )
                      })}
                    </>
                  )}
                </>
              )}
            </TableBody>
          </Table>
          <Grid container>
            <Grid container direction="row" justify="flex-end">
              <Grid item>
                <Button variant="outlined" onClick={this.closeCommercialLossModal} className={classes.cancelButton}>
                  Cancel
                </Button>
                {editMode ? (
                  <Button
                    variant="contained"
                    disabled={disabled}
                    onClick={this.updateCommercialUnits}
                    className={classes.addButton}
                  >
                    Update
                  </Button>
                ) : (
                  <Button
                    variant="contained"
                    disabled={disabled}
                    onClick={this.addCommercialUnits}
                    className={classes.addButton}
                  >
                    Add
                  </Button>
                )}
              </Grid>
            </Grid>
          </Grid>
        </DialogContent>
      </Dialog>
    )
  }
}

export default withStyles(styles)(CommercialRentLossModal)
