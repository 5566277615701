import React from 'react'
import { get } from 'lodash'

import arrayMutators from 'final-form-arrays'

import { TabContext, TabList } from '@mui/lab'
import { Grid, Tab } from '@mui/material'

import { RESIDENTIAL_PROJECTED_RENT_ROLL_SUMMARY_PATH } from 'shared/constants/report/keysAndDataPaths'

import wrapForm from '../../../../wrapForm'
import { STATE_NAMES } from '../../../../../../../../shared/constants/states'
import { Labels, RentRollSummaryTabs, RENT_ROLL_SUMMARY_TABS } from '../constants'

import decorators from '../decorators'

import RentRollSummaryData from './RentRollSummaryData'
import RentRollSummaryDiscussion from './RentRollSummaryDiscussion'

export const DATA_PATH = RESIDENTIAL_PROJECTED_RENT_ROLL_SUMMARY_PATH

class ResidentialProjectedRentRollSummary extends React.PureComponent {
  state = {
    selectedTab: RentRollSummaryTabs.SUMMARY_DATA,
  }

  changeTab = (event, newSelectedTab) => {
    this.setState({ selectedTab: newSelectedTab })
  }

  renderTab = () => {
    const { selectedTab } = this.state
    const { valueAsIs, valueAsStabilized, gbaHeading, showPerUnitSF, isNYReport, form } = this.props

    switch (selectedTab) {
      case RentRollSummaryTabs.SUMMARY_DATA:
      default: {
        return (
          <RentRollSummaryData
            formValues={form.values}
            gbaHeading={gbaHeading}
            showPerUnitSF={showPerUnitSF}
            isNYReport={isNYReport}
          />
        )
      }

      case RentRollSummaryTabs.DISCUSSION: {
        return (
          <RentRollSummaryDiscussion
            formValues={form.values}
            valueAsIs={valueAsIs}
            valueAsStabilized={valueAsStabilized}
            showPerUnitSF={showPerUnitSF}
            isNYReport={isNYReport}
          />
        )
      }
    }
  }

  render() {
    const { selectedTab } = this.state
    return (
      <Grid container spacing={2}>
        <Grid item sm={8}>
          <TabContext value={selectedTab}>
            <TabList onChange={this.changeTab} variant="fullWidth">
              {RENT_ROLL_SUMMARY_TABS.map((tab, index) => (
                <Tab key={index} {...tab} data-qa={`${tab.value}-tab`} />
              ))}
            </TabList>
          </TabContext>
        </Grid>
        <Grid item sm={8}>
          {this.renderTab()}
        </Grid>
      </Grid>
    )
  }
}

const formOptions = {
  decorators: [...decorators],
  mutators: { ...arrayMutators },
}

export default wrapForm(DATA_PATH, formOptions, state => {
  const isNYReport = get(state, 'report.reportData.state', '') === STATE_NAMES.NY
  const valueAsStabilized = get(state, 'report.reportSettings.valueAsStabilized')
  const valueAsIs = !(get(state, 'report.reportSettings.valueAsComplete') || valueAsStabilized)
  const showPerUnitSF =
    get(state, 'report.reportData.incomeApproach.residentialIncome.residentialRentRoll.perUnitSF') || false

  const commercialArea = get(state, 'report.reportData.propertyInformation.commercialUnits.commercialSquareFootage', 0)
  const gbaHeading = commercialArea > 0 ? Labels.GRA : Labels.GLA

  return {
    isNYReport,
    valueAsStabilized,
    valueAsIs,
    showPerUnitSF,
    gbaHeading,
  }
})(ResidentialProjectedRentRollSummary)
