import orgStyles from '../../admin/constants/organizationStyles'

export default {
  ...orgStyles,
  formHeader: {
    fontWeight: 600,
    paddingLeft: 0,
    paddingBottom: 10,
  },
}
