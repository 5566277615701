import React from 'react'
import PropTypes from 'prop-types'

import { Typography } from '@mui/material'
import { useFeatureFlag } from '@bowery-valuation/feature-flagger-client'
import { pure } from 'recompose'

import { getCommercialPropertyType } from 'shared/helpers/incomeApproach/commercial'
import { longDateFormat } from 'client-shared/utils/dateHelper'
import { USE_COMMERCIAL_SUBTYPE } from 'shared/constants/featureFlags'

import { PreviewSection } from '../components'

import AppraisersList from './AppraisersList'

const Preview = ({
  city,
  reportClients,
  applicationNumbers,
  address,
  appraisers,
  datesOfValue,
  buildingName,
  propertyType,
  propertySubtype,
  dateSectionName,
  buildingDescriptor,
  getClients,
  hideClientName,
}) => {
  const useCommercialSubtype = useFeatureFlag(USE_COMMERCIAL_SUBTYPE)
  const propertyTypeToDisplay = getCommercialPropertyType(propertyType, propertySubtype, useCommercialSubtype)
  const { dateOfFinalValueAsIs, dateOfFinalValueAsStabilized, dateOfFinalValueAsComplete } = datesOfValue
  return (
    <>
      <PreviewSection name="APPRAISAL REPORT">
        <Typography variant="body1" gutterBottom data-qa="building-name">
          {buildingName}
        </Typography>
        <Typography variant="body1" gutterBottom data-qa="address">
          {address}
        </Typography>
        <Typography variant="body1" gutterBottom data-qa="city">
          {city}
        </Typography>
        <Typography variant="body1" gutterBottom data-qa="application-number">
          {applicationNumbers &&
            applicationNumbers
              .map(number => {
                return `NYCB Application No. ${number}`
              })
              .join(', ')}
        </Typography>
        <Typography variant="body1" gutterBottom data-qa="property-type">
          {propertyTypeToDisplay}
          {buildingDescriptor ? ` ${buildingDescriptor}` : ''}
        </Typography>
      </PreviewSection>
      <PreviewSection name="REQUESTED BY">{getClients(reportClients, hideClientName)}</PreviewSection>
      <PreviewSection name={dateSectionName}>
        {dateOfFinalValueAsStabilized && (
          <Typography variant="body1" gutterBottom data-qa="as-stabilized">
            As Stabilized: {dateOfFinalValueAsStabilized ? longDateFormat(dateOfFinalValueAsStabilized) : ''}
          </Typography>
        )}
        {dateOfFinalValueAsComplete && (
          <Typography variant="body1" gutterBottom data-qa="as-complete">
            As Complete: {dateOfFinalValueAsComplete ? longDateFormat(dateOfFinalValueAsComplete) : ''}
          </Typography>
        )}
        {dateOfFinalValueAsIs && (
          <Typography variant="body1" gutterBottom data-qa="as-is">
            As Is: {dateOfFinalValueAsIs ? longDateFormat(dateOfFinalValueAsIs) : ''}
          </Typography>
        )}
      </PreviewSection>
      <PreviewSection name="PREPARED BY">
        <AppraisersList appraisers={appraisers} />
      </PreviewSection>
    </>
  )
}

Preview.propTypes = {
  address: PropTypes.string,
  applicationNumber: PropTypes.string,
  applicationNumbers: PropTypes.array,
  city: PropTypes.string.isRequired,
  propertyType: PropTypes.string.isRequired,
  reportClients: [
    PropTypes.shape({
      abbr: PropTypes.string.isRequired,
      address: PropTypes.string.isRequired,
      city: PropTypes.string.isRequired,
      client: PropTypes.string.isRequired,
      firstName: PropTypes.string.isRequired,
      lastName: PropTypes.string.isRequired,
      title: PropTypes.string,
    }),
  ],
  datesOfValue: PropTypes.shape({
    dateOfFinalValueAsIs: PropTypes.string,
    dateOfFinalValueAsStabilized: PropTypes.string,
    dateOfFinalValueAsComplete: PropTypes.string,
  }),
  dateSectionName: PropTypes.string.isRequired,
}

Preview.defaultProps = {
  address: '',
  applicationNumbers: [],
  client: null,
  reportClients: [],
  datesOfValue: {},
}

export default pure(Preview)
