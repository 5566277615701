import React, { FC, memo } from 'react'
import { get, noop } from 'lodash'
import { Grid, Typography } from '@mui/material'
import { ColumnDataTypeEnum, RowBasedTable } from '@bowery-valuation/ui-components'

import { formatCurrencyFloat } from 'client-shared/utils/numberFormatters'
import { RowBasedTableColumn } from 'client-shared/utils/rowBasedTable'
import { SubjectBudgetType } from 'shared/types/costApproachTypes'

type SubjectAdjustedDirectCostsProps = {
  subjectBudget: SubjectBudgetType
}

const SubjectAdjustedDirectCosts: FC<SubjectAdjustedDirectCostsProps> = ({ subjectBudget }) => {
  const constructionDirectCosts = get(subjectBudget, `constructionBudget.directCosts`, 0)
  const constructionDirectCostsAdjustment = get(subjectBudget, `constructionBudget.directCostsAdjustment`, 0)
  const constructionSiteImprovementCosts = get(subjectBudget, `constructionBudget.siteImprovementCosts`, 0)

  // todo - this is needed for Subject and MVS calculation
  const subjectAdjustedDirectCosts =
    constructionDirectCosts + constructionDirectCostsAdjustment + constructionSiteImprovementCosts

  const columns: RowBasedTableColumn[] = [
    {
      name: 'label',
      label: 'Cost Category',
      type: ColumnDataTypeEnum.text,
      permanent: true,
      align: 'left',
      editable: false,
      suppressMovable: true,
    },
    {
      name: 'costs',
      label: 'Costs',
      type: ColumnDataTypeEnum.text,
      permanent: true,
      align: 'right',
      editable: false,
      suppressMovable: true,
    },
  ]

  const rows = [
    {
      readOnly: true,
      suppressMovable: true,
      permanent: false,
      type: ColumnDataTypeEnum.text,
      label: 'Developer Reported Direct Costs',
      id: 'subjectDirectCosts',
      costs: formatCurrencyFloat(constructionDirectCosts),
      rowDef: { hideAction: true },
    },
    {
      readOnly: true,
      suppressMovable: true,
      permanent: false,
      type: ColumnDataTypeEnum.text,
      label: 'Adjustment to Direct Costs',
      id: 'subjectDirectCostsAdjustment',
      costs: formatCurrencyFloat(constructionDirectCostsAdjustment),
      rowDef: { hideAction: true },
    },
    {
      readOnly: true,
      suppressMovable: true,
      permanent: false,
      type: ColumnDataTypeEnum.text,
      label: 'Site Improvement Costs',
      id: 'subjectSiteImprovementCosts',
      costs: formatCurrencyFloat(constructionSiteImprovementCosts),
      rowDef: { hideAction: true },
    },
    {
      readOnly: true,
      suppressMovable: true,
      permanent: false,
      type: ColumnDataTypeEnum.text,
      label: "Budget / Developer's Total Direct Costs",
      id: 'subjectAdjustedDirectCosts',
      costs: formatCurrencyFloat(subjectAdjustedDirectCosts),
      rowDef: { hideAction: true, summary: true },
    },
  ]

  return (
    <Grid>
      <Typography variant="subtitle1" sx={{ paddingBottom: 2 }}>
        Budget / Developer's Direct Costs
      </Typography>
      <RowBasedTable
        id="subject-adjusted-hard-costs-table"
        columns={columns}
        rows={rows}
        onRowUpdate={noop}
        onManyRowsUpdate={noop}
        actionCellHidden
        hideIndexColumn
        onColumnDragEnd={noop}
        onRowsDragEnd={noop}
        onColumnDelete={noop}
        onColumnUpdate={noop}
        onRowDelete={noop}
      />
    </Grid>
  )
}

export default memo(SubjectAdjustedDirectCosts)
