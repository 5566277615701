import React from 'react'
import { pure, compose } from 'recompose'
import { Sparklines } from 'react-sparklines'

import { Grid, withTheme } from '@material-ui/core'

import { formatCurrencyFloat } from '../../../../../../../shared/utils/numberFormatters'
import SparklinesLine from '../../../../../../../shared/components/Sparklines/SparklinesLine'
import SparklinesPoint from '../../../../../../../shared/components/Sparklines/SparklinesPoint'

const AverageRentCell = ({ theme, averageRent, totals, averageBucketIndex }) => {
  const sparklinesLineStyle = {
    strokeWidth: 4,
    stroke: theme.palette.primary[600],
    fill: 'none',
  }

  const sparklinesPointStyle = {
    strokeWidth: 4,
    stroke: theme.palette.primary[600],
    fill: 'white',
  }

  return (
    <Grid container direction="row" justify="space-between" alignItems="center" wrap="nowrap" spacing={0}>
      <Grid item>
        <span>{formatCurrencyFloat(averageRent)}</span>
      </Grid>
      <Grid item>
        {!!totals && (
          <Sparklines
            svgHeight={36}
            height={36}
            svgWidth={100}
            preserveAspectRatio="xMidYMid meet"
            data={totals}
            margin={36}
          >
            <SparklinesLine accentColor={theme.palette.error[600]} style={sparklinesLineStyle} />
            <SparklinesPoint style={sparklinesPointStyle} size={8} pointIndex={averageBucketIndex} />
          </Sparklines>
        )}
      </Grid>
    </Grid>
  )
}

export default compose(withTheme(), pure)(AverageRentCell)
