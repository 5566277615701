import { GeneratedText, gtLocked } from '../../../narrative/GeneratedText'

import { mapDTO, mapDataModel } from './mappings'

const generate = () => {
  const gt = new GeneratedText()

  gt.t`It is our opinion that given the current economic conditions, a`
  gt.t` marketing time for the subject property is between ${gtLocked('marketingTimeMin', null, 'Marketing Time Min')}`
  gt.t` months and ${gtLocked('marketingTimeMax', null, 'Marketing Time Max')} months.`
  gt.t` This conclusion considers the property’s relative market position, as well as our market value conclusion`
  gt.t` and it is predicated on interviews with brokers, other real estate industry sources`
  gt.t` and on information obtained in the verification process.`

  return gt.getParts()
}

export default { generate, mapDTO, mapDataModel }
