import React from 'react'
import { compose } from 'recompose'
import { withRouter } from 'react-router-dom'

import UploadFileOutlinedIcon from '@mui/icons-material/UploadFileOutlined'
import InsertPhotoOutlinedIcon from '@mui/icons-material/InsertPhotoOutlined'
import ScreenSearchDesktopOutlinedIcon from '@mui/icons-material/ScreenSearchDesktopOutlined'
import MapOutlinedIcon from '@mui/icons-material/MapOutlined'
import ArticleOutlinedIcon from '@mui/icons-material/ArticleOutlined'
import Keyboard from '@mui/icons-material/KeyboardOutlined'
import { Divider } from '@mui/material'
import { withStyles } from '@mui/styles'
import { FeatureToggle } from '@bowery-valuation/feature-flagger-client'

import { CP_PROPERTY_SEARCH } from 'shared/constants/featureFlags'
import { showJumpAhead } from 'client-shared/redux/actions/jumpAhead'

import DataExtractionTool from 'client-utils/DataExtractionTool'
import PropertySearch from 'client-utils/PropertySearch'
import MapMaker from 'client-utils/MapMaker'
import PhotoGridExport from 'client-utils/PhotoGridExport'

import { connect } from 'react-redux'

import { SIDEBAR_WIDTH } from '../constants'
import HideIfIsBlocks from '../../hocs/HideIfIsBlocks'
import HideIfIsCMS from '../../hocs/HideIfIsCMS'
import MenuIcon from '../MenuIcon'

import ShowSection from '../../../shared/components/ShowSection'

import UserMenu from './components/UserMenu'

const SidebarItem = withStyles(
  theme => ({
    root: {
      paddingTop: theme.spacing(1.5),
    },
  }),
  { name: 'SidebarItem' }
)(({ children, classes, ...restProps }) => (
  <div {...restProps} className={classes.root}>
    {children}
  </div>
))

const styles = () => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    marginTop: 'auto',
    position: 'relative',
  },
  productBoardImage: {
    width: 18,
    height: 16,
  },
  divider: {
    backgroundColor: '#D7DEEA',
    width: SIDEBAR_WIDTH,
  },
})

class Dashboard extends React.PureComponent {
  state = {
    mapMakerOpen: false,
    photoGridOpen: false,
    dataExtractionToolOpen: false,
    propertySearchOpen: false,
  }

  openMapMaker = () => this.setState({ mapMakerOpen: true })

  closeMapMaker = () => this.setState({ mapMakerOpen: false })

  openPropertySearch = () => {
    this.setState({ propertySearchOpen: true })
  }

  closePropertySearch = () => this.setState({ propertySearchOpen: false })

  openPhotoGrid = () => this.setState({ photoGridOpen: true })

  closePhotoGrid = () => this.setState({ photoGridOpen: false })

  openDataExtractionTool = () => this.setState({ dataExtractionToolOpen: true })

  closeDataExtractionTool = () => this.setState({ dataExtractionToolOpen: false })

  openCMS = () => {
    const { history } = this.props
    history.push(`/cms`, { skipSave: true })
  }

  render() {
    const { classes, showJumpAhead } = this.props

    const { mapMakerOpen, photoGridOpen, dataExtractionToolOpen, propertySearchOpen } = this.state

    return (
      <div className={classes.root}>
        <HideIfIsCMS>
          <Divider className={classes.divider} />
          <FeatureToggle featureFlag={CP_PROPERTY_SEARCH}>
            <SidebarItem data-qa="openPropertySearchBtn">
              <MenuIcon
                onClick={this.openPropertySearch}
                tooltipTitle="Property Search"
                icon={ScreenSearchDesktopOutlinedIcon}
                label="Property Search"
              />
            </SidebarItem>
          </FeatureToggle>
          <SidebarItem data-qa="openKeyboardShortcutsBtn">
            <MenuIcon
              onClick={showJumpAhead}
              tooltipTitle="Keyboard Shortcuts (cmd/ctrl + i)"
              label="Shortcuts"
              icon={Keyboard}
            />
          </SidebarItem>
          <HideIfIsBlocks>
            <SidebarItem data-qa="openMapMakerBtn">
              <MenuIcon onClick={this.openMapMaker} tooltipTitle="Map Maker" label="Map Maker" icon={MapOutlinedIcon} />
            </SidebarItem>
            <SidebarItem data-qa="openPhotoGridExportBtn">
              <MenuIcon
                onClick={this.openPhotoGrid}
                tooltipTitle="Photo Grid Export"
                label="Photo Grid"
                icon={InsertPhotoOutlinedIcon}
              />
            </SidebarItem>
            <SidebarItem data-qa="openDataExtractionToolBtn">
              <MenuIcon
                onClick={this.openDataExtractionTool}
                tooltipTitle="Data Extraction Tool"
                icon={UploadFileOutlinedIcon}
                label="Data Extraction"
              />
            </SidebarItem>
          </HideIfIsBlocks>
          <ShowSection permission="cms.edit">
            <SidebarItem data-qa="linkToCms">
              <MenuIcon
                onClick={this.openCMS}
                tooltipTitle="Content Management System"
                label="CMS"
                icon={ArticleOutlinedIcon}
              />
            </SidebarItem>
          </ShowSection>
        </HideIfIsCMS>
        <SidebarItem>
          <UserMenu />
        </SidebarItem>

        <MapMaker open={mapMakerOpen} onClose={this.closeMapMaker} />
        <PhotoGridExport open={photoGridOpen} onClose={this.closePhotoGrid} />
        <DataExtractionTool open={dataExtractionToolOpen} onClose={this.closeDataExtractionTool} />
        <PropertySearch open={propertySearchOpen} onClose={this.closePropertySearch} />
      </div>
    )
  }
}

export default compose(connect(null, { showJumpAhead }), withStyles(styles), withRouter)(Dashboard)
