import React from 'react'

import { Divider } from '@mui/material'
import { withStyles } from '@mui/styles'
import { isEqual } from 'lodash'
import { compose, shouldUpdate } from 'recompose'

import {
  GENERIC_SUBSECTION_KEY,
  PREVIEW_AND_EDIT_KEY,
  REPORT_KEY,
  REVIEW_AND_EXPORT_KEY,
} from 'shared/constants/report/keysAndDataPaths'

import { SIDE_BAR_ICONS } from '../../../../shared/layout/constants'
import layoutStyles from '../../../../shared/layout/styles'

import NavigationSideBarTab from './NavigationSideBarTab'

const styles = theme => ({
  section: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    paddingBottom: theme.spacing(2),
  },
  divider: {
    ...layoutStyles.divider,
  },
  tab: {
    cursor: 'pointer',
  },
})

class NavigationSideBarSectionsV2 extends React.PureComponent {
  get sections() {
    const { sections } = this.props

    const dataCollectionsStartIndex = 0

    const reportStartIndex = sections.findIndex(section => section.key === REPORT_KEY)

    const exportTabStartIndex = sections.findIndex(
      section =>
        section.key === REVIEW_AND_EXPORT_KEY ||
        section.key === PREVIEW_AND_EDIT_KEY ||
        section.key === GENERIC_SUBSECTION_KEY
    )

    return [
      { tabs: sections.slice(dataCollectionsStartIndex, reportStartIndex) },
      { tabs: sections.slice(reportStartIndex, exportTabStartIndex) },
      { tabs: sections.slice(exportTabStartIndex) },
    ]
  }

  render() {
    const { classes, currentTab, onTabClick } = this.props

    return this.sections.map((section, index) => {
      const isLastSection = index === this.sections.length - 1

      return (
        <div className={classes.section} key={index}>
          {section.tabs.map(({ id, title }) => (
            <NavigationSideBarTab
              key={id}
              id={id}
              isSelected={id === currentTab}
              className={classes.tab}
              icon={SIDE_BAR_ICONS[id]}
              onClick={onTabClick}
              title={title}
            />
          ))}
          {!isLastSection && <Divider className={classes.divider} />}
        </div>
      )
    })
  }
}

export default compose(
  shouldUpdate(
    (props, nextProps) =>
      !isEqual(props.sections, nextProps.sections) || !isEqual(props.currentTab, nextProps.currentTab)
  ),
  withStyles(styles, {
    name: 'NavigationSideBarSection',
  })
)(NavigationSideBarSectionsV2)
