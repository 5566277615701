import React from 'react'
import PropTypes from 'prop-types'
import { Paper, Grid, withStyles } from '@material-ui/core'

import rentRollSummaryDiscussion from 'shared/utils/textGeneration/incomeApproach/residential/projectedRentRollSummaryDiscussion'

import { NarrativeComponent } from '../../../../../../shared/components'

import styles from './styles'
import PotentialGrossDiscussion from './PotentialGrossResidentialIncomeDiscussion'
import StabilizedDiscussion from './RentStabilizedDiscussion'
import ControlledIncreasesDiscussion from './RentControlledDiscussion'
import UnitDistributionSummary from './UnitDistributionSummary'
import { ResidentialUnitSummaryPropType } from './RentRollSummaryData'

const TOOLTIP_TEXT =
  "The following text will appear in the Income Approach's Base Residential Income section of your report."

class RentRollSummaryDiscussion extends React.PureComponent {
  static propTypes = {
    valueAsIs: PropTypes.bool.isRequired,
    valueAsStabilized: PropTypes.bool.isRequired,
    showPerUnitSF: PropTypes.bool.isRequired,
    isNYReport: PropTypes.bool.isRequired,

    formValues: PropTypes.shape({
      buildingSummary: ResidentialUnitSummaryPropType.isRequired,
      totalAverageRent: PropTypes.number,
      percentageOfMarketRent: PropTypes.number,
      basisForSFAnalysis: PropTypes.string,
    }).isRequired,
  }

  render() {
    const { classes, formValues, showPerUnitSF, isNYReport, valueAsIs, valueAsStabilized } = this.props
    const { percentageOfMarketRent, basisForSFAnalysis, totalAverageRent } = formValues
    const { hasRentControlledUnits, hasRentStabilizedUnits, hasVacantUnits } = formValues.buildingSummary

    return (
      <Grid container spacing={16}>
        <Grid item xs={12}>
          <Paper className={classes.paper}>
            <NarrativeComponent
              name="rentRollSummaryDiscussion"
              title={`${valueAsStabilized ? 'Stabilized Rent Roll' : 'Projected Rent Roll'} Summary Discussion`}
              generatedText={rentRollSummaryDiscussion.generate}
              data={rentRollSummaryDiscussion.mapDTO({ valueAsStabilized, totalAverageRent, hasVacantUnits })}
              tooltipText={TOOLTIP_TEXT}
            />
          </Paper>
        </Grid>

        <Grid item xs={12}>
          <Paper className={classes.paper}>
            <PotentialGrossDiscussion
              valueAsIs={valueAsIs}
              percentageOfMarketRent={percentageOfMarketRent}
              hasVacantUnits={hasVacantUnits}
            />
          </Paper>
        </Grid>

        <Grid item xs={12}>
          <Paper className={classes.paper}>
            <UnitDistributionSummary showPerUnitSF={showPerUnitSF} basisForSFAnalysis={basisForSFAnalysis} />
          </Paper>
        </Grid>

        {(hasRentControlledUnits || hasRentStabilizedUnits) && (
          <Grid item xs={12}>
            <Paper className={classes.paper}>
              <StabilizedDiscussion isNYReport={isNYReport} />
            </Paper>
          </Grid>
        )}

        {hasRentControlledUnits && (
          <Grid item xs={12}>
            <Paper className={classes.paper}>
              <ControlledIncreasesDiscussion isNYReport={isNYReport} values={formValues} />
            </Paper>
          </Grid>
        )}
      </Grid>
    )
  }
}

export default withStyles(styles)(RentRollSummaryDiscussion)
