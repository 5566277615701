import React from 'react'

import { Grid, Switch, Typography } from '@mui/material'
import { pure } from 'recompose'

const SwitchListItem = ({ checked, label, width, onChange, value }) => {
  const rowStyle = { width }
  return (
    <Grid style={rowStyle} item>
      <Grid container spacing={0} justifyContent="space-between" alignItems="center">
        <Grid item>
          <Typography>{label}</Typography>
        </Grid>
        <Grid item>
          <Switch checked={checked} value={value} onChange={onChange} />
        </Grid>
      </Grid>
    </Grid>
  )
}

export default pure(SwitchListItem)
