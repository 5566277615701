import React from 'react'
import PropTypes from 'prop-types'

import LinkIcon from '@mui/icons-material/Launch'
import { Stack, Tooltip, Typography } from '@mui/material'
import { Link as ReactRouterLink } from 'react-router-dom'

import RefHolder from '../../../../shared/utils/RefHolder'

const Link = RefHolder(ReactRouterLink)

const NarrativeTitle = ({ title, link, formName }) => {
  return (
    <Stack direction="row" alignItems="center" spacing={1}>
      <Typography noWrap variant="subtitle1">
        {title}
      </Typography>
      <Tooltip title={`Go To ${formName}`} placement="top">
        <Link to={link}>
          <LinkIcon color="secondary" />
        </Link>
      </Tooltip>
    </Stack>
  )
}

NarrativeTitle.propTypes = {
  title: PropTypes.string.isRequired,
  link: PropTypes.string,
  formName: PropTypes.string,
}

export default React.memo(NarrativeTitle)
