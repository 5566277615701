import {
  REPORT_KEY,
  REPORT_INFORMATION_KEY,
  APPRAISERS_KEY,
  CLIENT_KEY,
} from 'shared/constants/report/keysAndDataPaths'

import Appraisers from '../../../forms/reportInformation/Appraisers'
import KeyInfo from '../../../forms/reportInformation/KeyInfo'
import Client from '../../../forms/reportInformation/Client'

export default [
  {
    key: REPORT_KEY,
    name: 'Report',
  },
  {
    key: REPORT_INFORMATION_KEY,
    name: 'Key Info',
    formComponent: KeyInfo,
  },
  {
    key: APPRAISERS_KEY,
    name: 'Appraiser',
    formComponent: Appraisers,
  },
  {
    key: CLIENT_KEY,
    name: 'Client',
    formComponent: Client,
  },
]
