import { createAction } from 'redux-actions'
import { defineAction } from 'redux-define'

import { DEFAULT_API_ACTION_STATES } from '../../../../shared/constants'

//Action Types
const EXPORT_INIT = defineAction('EXPORT_INIT')
const EXPORT_FETCH_EXPORTS = defineAction('EXPORT_FETCH_EXPORTS', DEFAULT_API_ACTION_STATES)
const EXPORT_UPDATE_STATUS = defineAction('EXPORT_UPDATE_STATUS')
const EXPORT_GENERATE_REPORT = defineAction('EXPORT_GENERATE_REPORT', DEFAULT_API_ACTION_STATES)
const EXPORT_GENERATE_REPORT_FROM_JSON = defineAction('EXPORT_GENERATE_REPORT_FROM_JSON', DEFAULT_API_ACTION_STATES)
const EXPORT_GET_HIERARCHY = defineAction('EXPORT_GET_HIERARCHY', DEFAULT_API_ACTION_STATES)
const UPDATE_SELECTED_TEMPLATES = defineAction('UPDATE_SELECTED_TEMPLATES')
const EXPORT_GENERATE_XML = defineAction('EXPORT_GENERATE_XML')
const UPDATE_SHOULD_GENERATE_SIGNATURES = defineAction('UPDATE_SHOULD_GENERATE_SIGNATURES')

//Actions
export const init = createAction(EXPORT_INIT.ACTION)

export const fetchExports = createAction(EXPORT_FETCH_EXPORTS.ACTION)
export const fetchExportsRequest = createAction(EXPORT_FETCH_EXPORTS.REQUEST)
export const fetchExportsReceive = createAction(EXPORT_FETCH_EXPORTS.RECEIVE)
export const fetchExportsFail = createAction(EXPORT_FETCH_EXPORTS.FAIL)

export const updateStatus = createAction(EXPORT_UPDATE_STATUS.ACTION)

export const generateReport = createAction(EXPORT_GENERATE_REPORT.ACTION)
export const generateReportRequest = createAction(EXPORT_GENERATE_REPORT.REQUEST)
export const generateReportReceive = createAction(EXPORT_GENERATE_REPORT.RECEIVE)
export const generateReportFail = createAction(EXPORT_GENERATE_REPORT.FAIL)

export const generateReportFromJSON = createAction(EXPORT_GENERATE_REPORT_FROM_JSON.ACTION)
export const generateReportFromJSONRequest = createAction(EXPORT_GENERATE_REPORT_FROM_JSON.REQUEST)
export const generateReportFromJSONReceive = createAction(EXPORT_GENERATE_REPORT_FROM_JSON.RECEIVE)
export const generateReportFromJSONFail = createAction(EXPORT_GENERATE_REPORT_FROM_JSON.FAIL)

export const getReportHierarchy = createAction(EXPORT_GET_HIERARCHY.ACTION)
export const getReportHierarchyRequest = createAction(EXPORT_GET_HIERARCHY.REQUEST)
export const getReportHierarchyReceive = createAction(EXPORT_GET_HIERARCHY.RECEIVE)
export const getReportHierarchyFail = createAction(EXPORT_GET_HIERARCHY.FAIL)

export const generateXML = createAction(EXPORT_GENERATE_XML.ACTION)

export const updateSelectedTemplates = createAction(UPDATE_SELECTED_TEMPLATES.ACTION)

export const updateShouldGenerateSignatures = createAction(UPDATE_SHOULD_GENERATE_SIGNATURES.ACTION)
