export const REASONS_OF_VALUE_CHANGE = {
  marketAppreciation: 'Market Appreciation',
  capitalExpenditure: 'Capital Expenditure',
  offMarketTransaction: 'Off-Market Transaction',
  unitTurnover: 'Unit Turnover',
}

export const REASONS_OF_VALUE_CHANGE_OPTIONS = Object.entries(REASONS_OF_VALUE_CHANGE).reduce(
  (options, [key, value]) => {
    options[key] = value
    return options
  },
  {}
)

export const WARNING_TEXT = `You’ve indicated that the subject is not in contract and has not recently sold.`
