export default {
  subsectionWrapper: {
    padding: 16,
    marginBottom: 16,
  },
  submitButton: {
    color: 'white',
    backgroundColor: '#009688',
    position: 'fixed',
    bottom: 40,
    right: 40,
    '&:hover': {
      backgroundColor: '#00786D',
    },
  },
  title: {
    marginBottom: 16,
  },
}
