import React from 'react'
import PropTypes from 'prop-types'

import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Typography } from '@mui/material'
import { withStyles } from '@mui/styles'
import { Form } from 'react-final-form'

import { Text } from '../../../../shared/components'
import { required } from '../../../../shared/utils/validation'

const styles = theme => ({
  paper: {
    width: 480,
  },
  title: {
    lineHeight: 'normal',
    letterSpacing: 0.3,
    color: theme.palette.grey[900],
  },
  formControl: {
    height: 'auto',
  },
  input: {
    lineHeight: 1.75,
    letterSpacing: 0.5,
    color: theme.palette.grey[600],
  },
  actions: {
    justifyContent: 'space-between',
    margin: 8,
  },
  actionButton: {
    letterSpacing: 1.3,
  },
})

const TermModal = ({ classes, isOpen, close, updateTerm, term, termIndex }) => (
  <Dialog
    open={isOpen}
    disableBackdropClick
    onClose={close}
    maxWidth="md"
    aria-labelledby="form-dialog-title"
    classes={{ paper: classes.paper }}
  >
    <DialogTitle disableTypography>
      <Typography variant="h6" className={classes.title}>
        {!!term ? 'Edit term' : 'Add new term'}
      </Typography>
    </DialogTitle>
    <Form
      onSubmit={term => updateTerm(term, termIndex)}
      initialValues={term}
      render={({ handleSubmit, invalid }) => (
        <React.Fragment>
          <DialogContent sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
            <Text label="Term" name="term" classes={{ input: classes.input }} validate={required} required />
            <Text
              label="Definition"
              name="definition"
              rows={4}
              classes={{ formControl: classes.formControl, input: classes.input }}
              validate={required}
              required
            />
          </DialogContent>
          <DialogActions classes={{ root: classes.actions, action: classes.actionButton }}>
            <Button onClick={close} size="medium">
              Cancel
            </Button>
            <Button onClick={handleSubmit} size="medium" variant="contained" disabled={invalid}>
              {!!term ? 'Update' : 'Create'}
            </Button>
          </DialogActions>
        </React.Fragment>
      )}
    />
  </Dialog>
)

TermModal.propTypes = {
  classes: PropTypes.object.isRequired,
  isOpen: PropTypes.bool.isRequired,
  close: PropTypes.func.isRequired,
  updateTerm: PropTypes.func.isRequired,
}

export default withStyles(styles)(TermModal)
