import React from 'react'
import { useForm } from 'react-final-form'
import PropTypes from 'prop-types'

import { Typography, Stack, Box } from '@mui/material'

import DropDown from 'client-shared/components/DropDown/NativeDropDownField'
import { RadioButtonList, Text } from 'client-shared/components'

import { arrayToKeyValuePairs } from 'client-shared/utils/ui/checkboxHelper'

import {
  INTEREST_APPRAISED_AS_COMPLETE,
  INTEREST_APPRAISED_AS_IS_MARKET_VALUE,
  INTEREST_APPRAISED_AS_STABILIZED,
} from 'shared/constants'
import { REPORT_PURPOSE } from 'report/constants'

import { OTHER_INTENDED_USE } from '../../../../../../libs/bowery-libs/constants/report'

const DEFAULT_ROWS_COUNT = 5

const reportPurpose = arrayToKeyValuePairs(REPORT_PURPOSE)
const interestAppraisedAsIsMarketValueOptions = arrayToKeyValuePairs(INTEREST_APPRAISED_AS_IS_MARKET_VALUE)
const interestAppraisedAsCompleteOptions = arrayToKeyValuePairs(INTEREST_APPRAISED_AS_COMPLETE)
const interestAppraisedAsStabilizedOptions = arrayToKeyValuePairs(INTEREST_APPRAISED_AS_STABILIZED)

const Purpose = ({ valueAsComplete, valueAsStabilized }) => {
  const { intendedUse } = useForm().getState().values

  const otherIntendedUseTextInput = React.useRef(null)

  const onIntendedUseChange = React.useCallback(event => {
    const { value } = event.target
    if (value === OTHER_INTENDED_USE && otherIntendedUseTextInput.current) {
      setTimeout(() => {
        otherIntendedUseTextInput.current.focus()
      }, 0)
    }
  }, [])

  return (
    <Stack spacing={1}>
      <Stack spacing={2}>
        <Typography gutterBottom id="purpose" variant="h6">
          Purpose
        </Typography>
        <Box sx={{ maxWidth: 300 }}>
          <DropDown
            name="intendedUse"
            items={reportPurpose}
            displayEmpty
            placeholder="Select Intended Use"
            onChange={onIntendedUseChange}
          />
        </Box>
        <Text
          name="otherIntendedUse"
          label="Other"
          rows={DEFAULT_ROWS_COUNT}
          rootRef={otherIntendedUseTextInput}
          style={{ display: intendedUse === OTHER_INTENDED_USE ? 'block' : 'none' }}
        />
        <Stack>
          <Typography gutterBottom variant="subtitle1">
            Interest Appraised
          </Typography>
          <RadioButtonList
            label="Interest Appraised As Is Market Value"
            name="interestAppraisedAsIsMarketValue"
            items={interestAppraisedAsIsMarketValueOptions}
            horizontal
          />
          {valueAsComplete && (
            <RadioButtonList
              label="Interest Appraised As Complete"
              name="interestAppraisedAsComplete"
              items={interestAppraisedAsCompleteOptions}
              horizontal
            />
          )}
          {valueAsStabilized && (
            <RadioButtonList
              label="Interest Appraised As Stabilized"
              name="interestAppraisedAsStabilized"
              items={interestAppraisedAsStabilizedOptions}
              horizontal
            />
          )}
        </Stack>
      </Stack>
    </Stack>
  )
}

Purpose.propTypes = {
  valueAsComplete: PropTypes.bool,
  valueAsStabilized: PropTypes.bool,
}

export default Purpose
