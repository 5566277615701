import { DEFAULT_EXPENSE_CATEGORIES, GROSS_REVENUE } from '..'

export const DEFAULT_EXPENSE_HISTORY_CATEGORIES = {
  [GROSS_REVENUE.key]: GROSS_REVENUE.name,
  ...DEFAULT_EXPENSE_CATEGORIES,
}

export const TOTAL_OPERATING_EXPENSES = { name: 'Total Operating Expenses', key: 'total' }
export const TOTAL_OPERATING_EXPENSES_WITHOUT_TAXES = {
  name: 'Total Operating Expenses (Excl. RE Taxes)',
  key: 'totalExcludingTaxes',
}
export const NET_OPERATING_INCOME = { name: 'Net Operating Income', key: 'noi' }

export const EXPENSE_HISTORY_TYPE_TITLES = {
  ACTUAL: 'Actual',
  ACTUAL_T_12: 'Actual T12',
  ANNUALIZED_HISTORICAL: 'Annualized Historical',
  PROJECTION: 'Projection',
}

export const EXPENSE_HISTORY_TYPE_KEYS = {
  ACTUAL: 'actual',
  ACTUAL_T_12: 'actualT12',
  ANNUALIZED_HISTORICAL: 'annualizedHistorical',
  PROJECTION: 'projection',
}

export const EXPENSE_HISTORY_TYPES = {
  [EXPENSE_HISTORY_TYPE_KEYS.ACTUAL]: EXPENSE_HISTORY_TYPE_TITLES.ACTUAL,
  [EXPENSE_HISTORY_TYPE_KEYS.ACTUAL_T_12]: EXPENSE_HISTORY_TYPE_TITLES.ACTUAL_T_12,
  [EXPENSE_HISTORY_TYPE_KEYS.ANNUALIZED_HISTORICAL]: EXPENSE_HISTORY_TYPE_TITLES.ANNUALIZED_HISTORICAL,
  [EXPENSE_HISTORY_TYPE_KEYS.PROJECTION]: EXPENSE_HISTORY_TYPE_TITLES.PROJECTION,
}
