import React from 'react'
import PropTypes from 'prop-types'

import { Paper, Stack, TableBody, TableCell, TableHead, TableRow, Typography } from '@mui/material'
import { keyBy, maxBy, meanBy, minBy, valuesIn } from 'lodash'

import { DragDropContext } from 'react-beautiful-dnd'

import { connect } from 'react-redux'

import { DroppableTable, DraggableTableRow } from 'client-shared/components/DragDropTable'
import AutomationStatus from 'client-shared/components/AutomationStatus'
import { formatCurrencyInt } from 'client-shared/utils/numberFormatters'
import { getRentPSFLabel } from 'shared/helpers/rentRoll'

import RentRollUnitGroupHeader from '../RentRollUnitGroupHeader'

import { selectRentCompSettings } from '../../redux/selectors'

import RentCompItem from './RentCompItem'

class RentCompsTable extends React.PureComponent {
  state = {
    draggingId: '',
  }

  moveCard = ({ source, destination }) => {
    const { unitCompGroups, reorderComps } = this.props
    this.setState({ draggingId: '' })
    if (!destination) {
      return
    }

    const unitCompGroupsByKey = keyBy(unitCompGroups, group => group.unitGroupKey)
    const sourceUnitCompGroup = unitCompGroupsByKey[source.droppableId]
    const destinationUnitCompGroup = unitCompGroupsByKey[destination.droppableId]
    const [unit] = sourceUnitCompGroup.units.splice(source.index, 1)
    destinationUnitCompGroup.units.splice(destination.index, 0, unit)

    reorderComps(valuesIn(unitCompGroups))
  }

  getGroupItemId = unit => {
    return `${unit.bedrooms}-group-${unit.address}-${unit._id}`
  }

  getTotalRow = (label, value) => {
    const { showBathrooms, showPerUnitSF } = this.props
    const colSpan = 4 + Number(showBathrooms) + Number(showPerUnitSF) * 2

    return (
      <TableRow>
        <TableCell colSpan={5}>{label}</TableCell>
        <TableCell align="center">{value}</TableCell>
        <TableCell colSpan={colSpan} />
      </TableRow>
    )
  }

  onDragStart = event => {
    this.setState({ draggingId: event.draggableId })
  }

  render() {
    const {
      removeComp,
      showBathrooms,
      showAmenities,
      showDevelopersForecast,
      showPerRoomAnalysis,
      showPerUnitSF,
      unitCompGroups,
      unitRentPSFTimePeriod,
      showEditModal,
      automationMetadata,
    } = this.props
    const { draggingId } = this.state

    return (
      <DragDropContext onDragStart={this.onDragStart} onDragEnd={this.moveCard}>
        <Stack spacing={2}>
          {unitCompGroups.map((compGroup, index) => {
            const droppableBedroomCount = compGroup.unitGroupKey.replace(/^bedrooms_(\d+).*/, '$1')
            const draggingBedroomCount = draggingId.replace(/^(\d+).*/, '$1')
            const isDropDisabled =
              compGroup.unitGroupKey === 'uncategorized' ? false : droppableBedroomCount !== draggingBedroomCount

            return (
              <Paper key={index} data-qa={`rent-comps-type-${compGroup.unitGroupKey}`}>
                <Typography variant="h6">{compGroup.unitGroupName}</Typography>
                <RentRollUnitGroupHeader
                  showDevelopersForecast={showDevelopersForecast}
                  unitGroupKey={compGroup.unitGroupKey}
                />
                {automationMetadata && (
                  <AutomationStatus
                    formPaths={['automationMetadata']}
                    message="We automated the information below from the In-Place Rent Roll"
                    showSourceLinks={false}
                    sx={{ marginTop: '16px', marginBottom: '16px' }}
                  />
                )}
                <DroppableTable id={compGroup.unitGroupKey} isDropDisabled={isDropDisabled}>
                  <TableHead>
                    <TableRow>
                      <TableCell>Move</TableCell>
                      <TableCell></TableCell>
                      <TableCell>#</TableCell>
                      <TableCell>Unit Address</TableCell>
                      <TableCell>Status</TableCell>
                      {showPerRoomAnalysis && <TableCell align="center"># Rooms</TableCell>}
                      <TableCell align="center"># Bedrooms</TableCell>
                      {showBathrooms && <TableCell align="center"># Bathrooms</TableCell>}
                      {showAmenities && <TableCell align="center">Amenities</TableCell>}
                      <TableCell align="center">Monthly Rent</TableCell>
                      {showPerRoomAnalysis && <TableCell align="center">Rent/Room</TableCell>}
                      {showPerUnitSF && (
                        <>
                          <TableCell align="center">SF</TableCell>
                          <TableCell align="center">{getRentPSFLabel(unitRentPSFTimePeriod)}</TableCell>
                        </>
                      )}
                      <TableCell align="center">Source of Information</TableCell>
                      <TableCell colSpan={2} />
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {compGroup.units.map((unit, index) => (
                      <DraggableTableRow id={this.getGroupItemId(unit)} index={index} key={this.getGroupItemId(unit)}>
                        <RentCompItem
                          index={index + 1}
                          removeComp={removeComp}
                          showBathrooms={showBathrooms}
                          showAmenities={showAmenities}
                          showPerRoomAnalysis={showPerRoomAnalysis}
                          showPerUnitSF={showPerUnitSF}
                          unit={unit}
                          unitRentPSFTimePeriod={unitRentPSFTimePeriod}
                          showEditModal={showEditModal}
                        />
                      </DraggableTableRow>
                    ))}
                    {compGroup.units.length > 0 && (
                      <>
                        {this.getTotalRow('Min', formatCurrencyInt(minBy(compGroup.units, 'rent').rent))}
                        {this.getTotalRow('Average', formatCurrencyInt(meanBy(compGroup.units, 'rent')))}
                        {this.getTotalRow('Max', formatCurrencyInt(maxBy(compGroup.units, 'rent').rent))}
                      </>
                    )}
                  </TableBody>
                </DroppableTable>
              </Paper>
            )
          })}
        </Stack>
      </DragDropContext>
    )
  }
}

RentCompsTable.propTypes = {
  removeComp: PropTypes.func.isRequired,
  reorderComps: PropTypes.func.isRequired,
  showBathrooms: PropTypes.bool.isRequired,
  showPerRoomAnalysis: PropTypes.bool.isRequired,
  showPerUnitSF: PropTypes.bool.isRequired,
  unitCompGroups: PropTypes.arrayOf(
    PropTypes.shape({
      unitGroupName: PropTypes.string,
      unitGroupKey: PropTypes.string,
      units: PropTypes.arrayOf(
        PropTypes.shape({
          bedrooms: PropTypes.number,
          units: PropTypes.arrayOf(
            PropTypes.shape({
              _id: PropTypes.string,
              address: PropTypes.string.isRequired,
              bedrooms: PropTypes.number.isRequired,
              mongoId: PropTypes.string,
              rent: PropTypes.number.isRequired,
              rentPerRoom: PropTypes.number.isRequired,
              rentPerSf: PropTypes.number,
              rooms: PropTypes.number.isRequired,
              sf: PropTypes.number,
              leaseId: PropTypes.string,
              leaseVersionNumber: PropTypes.number,
            })
          ),
        })
      ),
    })
  ),
  unitRentPSFTimePeriod: PropTypes.string.isRequired,
  showEditModal: PropTypes.func.isRequired,
}

RentCompsTable.defaultProps = {
  unitCompGroups: [],
}

const mapStateToProps = state => {
  return {
    ...selectRentCompSettings(state),
  }
}
export default connect(mapStateToProps)(RentCompsTable)
