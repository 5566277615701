import { required } from 'client-shared/utils/validation'
import { toLower } from 'lodash'

export const validateTaxConfigurationName = allConfigurationOptions => value => {
  const requiredMessage = required(value)

  if (requiredMessage) {
    return requiredMessage
  }

  if (allConfigurationOptions.filter(option => toLower(option.label) === toLower(value)).length > 0) {
    return 'Table name already exists'
  }

  return undefined
}
