import React from 'react'
import PropTypes from 'prop-types'
import { pure, compose } from 'recompose'
import { Typography, withStyles, Table, TableHead, TableBody, TableRow, TableCell, Grid } from '@material-ui/core'

import { VALUE_CONCLUSION_TYPES } from 'shared/constants/settings'
import typicalIncomeSpikesDiscussion from 'shared/utils/textGeneration/final/typicalIncomeSpikes'

import { toPercentageString } from '../../../../../shared/utils/numberFormatters'
import TableThemeProvider from '../../../../components/TableThemeProvider'
import { arrayToKeyValuePairs } from '../../../../../shared/utils/ui/checkboxHelper'
import {
  Callout,
  RadioButtonList,
  GeneratedComment,
  CheckboxWithLabel,
  NarrativeComponent,
} from '../../../../../shared/components'
import { CAP_RATE_DISCUSSION_TOOLTIP } from '../../constants'
import { getAdditionalIncomeSpikesDiscussion, getAirRightsCalloutContent } from '../discussions'

const INCOME_POTENTIAL = arrayToKeyValuePairs(['Significant', 'Typical', 'Moderate', 'Limited'])
const CURRENT_MARKET_CONDITION = arrayToKeyValuePairs(['Moderately Appreciating', 'Growing', 'Continually Strong'])
const CASH_FLOW_RISK_PROFILE = arrayToKeyValuePairs(['Secure', 'Short-term Risk', 'Moderate Risk', 'Do Not Discuss'])

const styles = theme => ({
  title: {
    lineHeight: 1,
    marginBottom: 20,
  },
  tableTitle: {
    color: 'rgba(0, 0, 0, 0.87)',
    lineHeight: 1.33,
    letterSpacing: 0.4,
    marginBottom: 8,
    display: 'flex',
    alignItems: 'center',
  },
  caption: {
    color: 'rgba(0, 0, 0, 0.6)',
    lineHeight: 1.33,
    letterSpacing: 0.4,
    marginBottom: 8,
  },

  icon: {
    width: 18,
    height: 18,
    fill: 'rgba(0, 0, 0, 0.54)',
    '&:hover': {
      fill: theme.palette.primary[900],
    },
    marginLeft: 8,
  },
  iconLink: {
    width: 18,
    height: 18,
    display: 'inline-block',
  },
  calloutRoot: {
    backgroundColor: '#e8eaf6',
    alignItems: 'flex-start',
    margin: '8px 0',
  },
  calloutText: {
    color: 'rgba(0, 0, 0, 0.6)',
    lineHeight: 1.75,
    letterSpacing: 0.4,
    fontSize: 16,
    paddingRight: 50,
  },
  checkboxControlRoot: {
    height: 'auto',
    width: 'auto',
  },
  checkboxFormControlLabel: {
    marginTop: 0,
    height: 'auto',
  },
  checkboxRoot: {
    padding: '6px 12px',
  },
  generatedComment: {
    height: 'auto',
  },
})

const StyledRadioButtonsList = withStyles({
  formControl: {
    height: 'auto',
    minHeight: 'auto',
  },
  group: {
    marginBottom: 5,
  },
  radioItem: {
    paddingRight: 10,
  },
  radioItemLabel: {
    fontSize: 16,
    color: 'rgba(0, 0, 0, 0.6)',
    lineHeight: 1.75,
  },
  label: {
    color: 'rgba(0, 0, 0, 0.87)!important',
    letterSpacing: 0.4,
  },
})(RadioButtonList)
class IncomeSpikes extends React.Component {
  static propTypes = {
    concludedCapRate: PropTypes.number,
    occupancyLevel: PropTypes.number,
    percentageOfMarketRent: PropTypes.number,
    specificPropertyTypes: PropTypes.arrayOf(PropTypes.string),
    rentControlledTenants: PropTypes.number,
    unusedAirRights: PropTypes.number,
    grossBuildingAreaPerFloor: PropTypes.number,
    propertyCondition: PropTypes.string,
    asStabilizedPropertyCondition: PropTypes.string.isRequired,
    actualPerTransitionalAssessmentGap: PropTypes.number,
    valueConclusionType: PropTypes.string.isRequired,
  }

  static defaultProps = {
    concludedCapRate: 0,
    occupancyLevel: 0,
    percentageOfMarketRent: 0,
    specificPropertyTypes: [],
    rentControlledTenants: 0,
    unusedAirRights: 0,
    grossBuildingAreaPerFloor: 0,
    propertyCondition: 'N/A',
    asStabilizedPropertyCondition: 'N/A',
    actualPerTransitionalAssessmentGap: 0,
  }

  get calloutContent() {
    const { unusedAirRights, grossBuildingAreaPerFloor } = this.props
    return getAirRightsCalloutContent({ unusedAirRights, grossBuildingAreaPerFloor })
  }

  get getPropertyCondition() {
    const { propertyCondition, asStabilizedPropertyCondition, valueConclusionType } = this.props

    return valueConclusionType === VALUE_CONCLUSION_TYPES.AS_COMPLETE
      ? asStabilizedPropertyCondition
      : propertyCondition
  }

  render() {
    const {
      classes,
      occupancyLevel,
      percentageOfMarketRent,
      concludedCapRate,
      specificPropertyTypes,
      rentControlledTenants,
      actualPerTransitionalAssessmentGap,
      formValues,
    } = this.props

    return (
      <div data-qa="income-spikes">
        <Typography className={classes.title} variant="h6">
          Income Spikes
        </Typography>
        <Grid container spacing={16}>
          <Grid item xs={12}>
            <Typography className={classes.tableTitle} variant="caption">
              Income Spike Potential
            </Typography>
            <TableThemeProvider>
              <Table data-qa="income-spike-potential" padding="checkbox">
                <TableHead>
                  <TableRow>
                    <TableCell align="right">Cap Rate Conclusion</TableCell>
                    <TableCell align="right">Occupancy Level</TableCell>
                    <TableCell align="right">Residential Income Percentage of Market Rates</TableCell>
                    <TableCell>Property Condition</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow>
                    <TableCell data-qa="concluded-cap-rate" align="right">
                      {toPercentageString(concludedCapRate || 0)}
                    </TableCell>
                    <TableCell data-qa="occupancy-level" align="right">
                      {toPercentageString(occupancyLevel, 0)}
                    </TableCell>
                    <TableCell data-qa="percentage-of-market-rent" align="right">
                      {toPercentageString(percentageOfMarketRent)}
                    </TableCell>
                    <TableCell data-qa="property-condition">{this.getPropertyCondition}</TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableThemeProvider>
          </Grid>
          <Grid item xs={12}>
            <StyledRadioButtonsList
              label="What is the subject's potential for future income spikes?"
              name="subjectIncomePotential"
              horizontal={true}
              items={INCOME_POTENTIAL}
            />
            <StyledRadioButtonsList
              label="What are the subject area's current market conditions?"
              name="currentMarketConditions"
              horizontal={true}
              items={CURRENT_MARKET_CONDITION}
            />
            <StyledRadioButtonsList
              label="What is the cash flow's risk profile?"
              name="cashFlowRiskProfile"
              horizontal={true}
              items={CASH_FLOW_RISK_PROFILE}
            />
            <NarrativeComponent
              title="Typical Income Spikes Discussion"
              name="typicalIncomeSpikesDiscussion"
              generatedText={typicalIncomeSpikesDiscussion.generate}
              data={typicalIncomeSpikesDiscussion.mapDTO(formValues)}
            />
          </Grid>

          <Grid item xs={12}>
            <Typography className={classes.tableTitle} variant="caption">
              Additional Income Spike Potential
            </Typography>
            <TableThemeProvider>
              <Table data-qa="additional-income-spikes-potential" padding="checkbox">
                <TableHead>
                  <TableRow>
                    <TableCell align="right">Rent Stabilized Tenants</TableCell>
                    <TableCell align="right">Most Financially Feasible Property Type</TableCell>
                    <TableCell align="right">Actual/Transitional Assessment Gap</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow>
                    <TableCell data-qa="rent-stabilized-tenants" align="right">
                      {rentControlledTenants}
                    </TableCell>
                    <TableCell data-qa="specific-property-types" align="right">
                      {specificPropertyTypes.join(', ') || 'N/A'}
                    </TableCell>
                    <TableCell data-qa="actual-per-transitional-gap" align="right">
                      {toPercentageString(actualPerTransitionalAssessmentGap)}
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableThemeProvider>
          </Grid>
          <Grid item xs={12}>
            <CheckboxWithLabel
              name="discussFeasibleConversion"
              label="Do you want to discuss the most financially feasible conversion?"
            />
            <CheckboxWithLabel
              name="discussAssessmentGap"
              label="Do you want to discuss Actual/Transitional Assessment gap?"
            />
            <Callout
              classes={{ root: classes.calloutRoot }}
              variant="info"
              content={<Typography className={classes.calloutText}>{this.calloutContent}</Typography>}
            />

            <CheckboxWithLabel name="discussAirRights" label="Do you want to discuss unused air rights?" />
          </Grid>
          <Grid item xs={12}>
            <GeneratedComment
              label="Additional Income Spikes Discussion"
              title="Generated Commentary"
              dataPath="additionalIncomeSpikesDiscussion"
              tooltipText={CAP_RATE_DISCUSSION_TOOLTIP}
              isDynamicContent
              getGeneratedText={getAdditionalIncomeSpikesDiscussion(this.props.unusedAirRights)}
            />
          </Grid>
        </Grid>
      </div>
    )
  }
}

export default compose(withStyles(styles), pure)(IncomeSpikes)
