import * as React from 'react'
import PropTypes from 'prop-types'

import { withStyles } from '@mui/styles'
import clsx from 'clsx'

export const styles = theme => ({
  root: {
    display: 'block',
    backgroundColor: 'rgba(0, 0, 0, 0.05)',
    height: '1.2em',
  },
  text: {
    marginTop: 0,
    marginBottom: 0,
    height: 'auto',
    transformOrigin: '0 60%',
    transform: 'scale(1, 0.60)',
    borderRadius: theme.shape.borderRadius,
    '&:empty:before': {
      content: '"\\00a0"',
    },
  },
  rect: {},
  circle: {
    borderRadius: '50%',
  },
  pulse: {
    animation: '$pulse 1.5s ease-in-out 0.5s infinite',
  },
  '@keyframes pulse': {
    '0%': {
      opacity: 1,
    },
    '50%': {
      opacity: 0.4,
    },
    '100%': {
      opacity: 1,
    },
  },
  '@keyframes wave': {
    '0%': {
      transform: 'translateX(-100%)',
    },
    '60%': {
      transform: 'translateX(100%)',
    },
    '100%': {
      transform: 'translateX(100%)',
    },
  },
  wave: {
    position: 'relative',
    overflow: 'hidden',
    '&::after': {
      animation: 'wave 1.6s linear 0.5s infinite',
      background: `linear-gradient(90deg, transparent, rgba(0, 0, 0, 0.1), transparent)`,
      content: '""',
      position: 'absolute',
      transform: 'translateX(-100%)', // Avoid flash during server-side hydration
      bottom: 0,
      left: 0,
      right: 0,
      top: 0,
    },
  },
  withChildren: {
    '& > *': {
      visibility: 'hidden',
    },
  },
  fitContent: {
    maxWidth: 'fit-content',
  },
  heightAuto: {
    height: 'auto',
  },
})

const Skeleton = React.forwardRef(function Skeleton(props, ref) {
  const {
    animation = 'wave',
    classes,
    className,
    component: Component = 'span',
    height,
    variant = 'text',
    width,
    ...other
  } = props

  const hasChildren = Boolean(other.children)

  return (
    <Component
      ref={ref}
      className={clsx(
        classes.root,
        classes[variant],
        {
          [classes[animation]]: animation !== false,
          [classes.withChildren]: hasChildren,
          [classes.fitContent]: hasChildren && !width,
          [classes.heightAuto]: hasChildren && !height,
        },
        className
      )}
      {...other}
      style={{
        width,
        height,
        ...other.style,
      }}
    />
  )
})

Skeleton.propTypes = {
  animation: PropTypes.oneOf(['pulse', 'wave', false]),
  children: PropTypes.node,
  classes: PropTypes.object.isRequired,
  className: PropTypes.string,
  component: PropTypes.elementType,
  height: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  variant: PropTypes.oneOf(['text', 'rect', 'circle']),
  width: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
}

export default withStyles(styles, { name: 'Skeleton' })(Skeleton)
