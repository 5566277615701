import { divide } from '../../../utils/numberOperations'

import { BASIS_TYPES } from '../../../constants/report/incomeApproach/taxes'

export default {
  calculateValuePerBasis(basis: string, value: number, squareFootage: number, unitCount: number) {
    if (basis === BASIS_TYPES.PER_UNIT) {
      return divide(value, unitCount)
    }
    return divide(value, squareFootage)
  },
  calculateTaxesPerBasis(basis: string, taxLiability: number, squareFootage: number, unitCount: number) {
    return this.calculateValuePerBasis(basis, taxLiability, squareFootage, unitCount)
  },
  calculateTaxesPerBasisValue(basisValue: number, taxLiability: number) {
    return divide(taxLiability, basisValue)
  },
  calculateTaxLiability(taxableAssessedValue: number, effectiveTaxRate: number, additionalSpecialAssessments?: number) {
    if (!taxableAssessedValue || !effectiveTaxRate) {
      return additionalSpecialAssessments || 0
    }
    return taxableAssessedValue * effectiveTaxRate + (additionalSpecialAssessments || 0)
  },
  calculateTaxLiabilityByLiabilityRatio(income: number, liabilityRatio: number) {
    return income && liabilityRatio ? income * liabilityRatio : 0
  },
  calculateLiabilityFromPerBasisValue(
    basis: string,
    taxLiabilityPerBasis: number,
    squareFootage: number,
    unitCount: number
  ): number {
    if (!taxLiabilityPerBasis) {
      return 0
    }
    if (basis === BASIS_TYPES.PER_UNIT && unitCount) {
      return taxLiabilityPerBasis * unitCount
    }
    if (basis === BASIS_TYPES.PSF && squareFootage) {
      return taxLiabilityPerBasis * squareFootage
    }
    return 0
  },
  calculateTaxableAssessedValue(taxLiability: number, taxRate: number) {
    return divide(taxLiability, taxRate)
  },
  calculateTaxableAssessedValuePerBasis(taxableAssessedValue: number, basisValue: number) {
    return divide(taxableAssessedValue, basisValue)
  },
}
