import React from 'react'
import PropTypes from 'prop-types'

import { Alert } from '@mui/material'
import InfoOutlined from '@mui/icons-material/InfoOutlined'
import Close from '@mui/icons-material/Close'

const DismissibleCallout = ({ text, html, showInfoOutlined }) => {
  const [visible, setVisible] = React.useState(true)

  const handleClose = React.useCallback(() => setVisible(false), [])

  if (!visible) {
    return null
  }
  return (
    <Alert
      action={<Close data-qa="close-icon" onClick={handleClose} sx={{ cursor: 'pointer' }} />}
      color="secondary"
      icon={showInfoOutlined ? <InfoOutlined fontSize="small" /> : false}
    >
      {text || html}
    </Alert>
  )
}

DismissibleCallout.displayName = 'DismissibleCallout'

DismissibleCallout.propTypes = {
  html: PropTypes.object,
  showInfoOutlined: PropTypes.bool,
  text: PropTypes.string,
}

DismissibleCallout.defaultProps = {
  showInfoOutlined: true,
}

export default DismissibleCallout
