import React from 'react'

import { upperFirst } from 'lodash'

import { Box, Button, Paper, Stack, Typography } from '@mui/material'

import FileSelectionField from 'client-shared/components/FileSelection'
import Layout from '@ui/Layout'
import ShowSection from 'client-shared/components/ShowSection'
import { Auto, Input } from '@ui/Field'
import { CheckboxGroup, Loading } from 'client-shared/components'
import { OnChange } from 'react-final-form-listeners'
import { SECTIONS } from 'shared/helpers/propertyType'
import { STATE_OPTIONS } from 'shared/constants/states'

import { FILES_ENDPOINT } from 'report/constants'

const InsertedDocuments = ({
  areaItems,
  authenticatedUser,
  canInsertFromDropbox,
  getErrorText,
  getMarketReports,
  getNeighborhoodAndMarkets,
  isLoading,
  marketAnalysesUseItems,
  marketAnalysisUses,
  marketItems,
  neighborhoodItems,
  removeFile,
  submarketItems,
  usesForAnalysis,
}) => {
  return (
    <Paper data-qa="inserted-documents-section-tile">
      {isLoading && <Loading />}
      <Stack spacing={1}>
        <Box sx={{ maxWidth: 1 / 2, miWidth: 300 }}>
          <Typography variant="h6" gutterBottom>
            Inserted Documents
          </Typography>
          <ShowSection section={SECTIONS.HAS_RESIDENTIAL}>
            <Typography variant="subtitle2">Rent Stabilization</Typography>
            <Typography component="div" gutterBottom variant="caption">
              Only upload if you have Rent Stabilized units
            </Typography>
            <FileSelectionField
              fileType="rentStabilizationLaws"
              fileTypesRestriction={['docx']}
              label="Summary of Rent Stabilization Laws"
              name="rentStabilizationLaws"
              onDelete={removeFile}
              parentId={authenticatedUser.organizationId}
              uploadEndpoint={FILES_ENDPOINT}
            />
          </ShowSection>
        </Box>
        <Layout.Grid
          container={`
          'header         header          header          header'
          'neighborhood   area            state           year'
          'marketQuarter  subYear         .               .'
          'marketAnalysis marketAnalysis  marketAnalysis  marketAnalysis'
          'marketSuggest  marketSuggest   marketSuggest   marketSuggest'
          'action         .               .               .'
          /1fr            1fr             1fr             1fr
        `}
          gap={2}
        >
          <Layout.VerticalRow item="header" gap={0}>
            <Typography variant="subtitle2">Market Research</Typography>
            <Typography noWrap variant="caption">
              *All Market Research fields are required to pull the Market Analyses from DropBox.
            </Typography>
          </Layout.VerticalRow>
          <Layout.Grid item="neighborhood">
            <Auto.Text
              label="Neighborhood"
              name="marketNeighborhood"
              data-qa="marketNeighborhood-form-control"
              options={neighborhoodItems}
            />
          </Layout.Grid>
          <Layout.Grid item="area">
            <Auto.Text label="Area" name="marketArea" data-qa="marketArea-form-control" options={areaItems} />
          </Layout.Grid>
          <Layout.Grid item="state">
            <Auto.ComboBox
              label="State"
              options={STATE_OPTIONS.map(item => item.abbr)}
              name="marketState"
              data-qa="marketState-form-control"
            />
            <OnChange name="marketState">{getNeighborhoodAndMarkets}</OnChange>
          </Layout.Grid>
          <Layout.Grid item="year">
            <Input.Numbers
              hint="This is typically the current year."
              format={{ allowNegative: false, format: '####', mask: ['Y', 'Y', 'Y', 'Y'], placeholder: 'YYYY' }}
              fullWidth
              label="Neighborhood Year"
              name="neighborhoodYear"
              data-qa="neighborhoodYear-form-control"
              sx={{ minWidth: 240 }}
            />
            <OnChange name="neighborhoodYear">{getNeighborhoodAndMarkets}</OnChange>
          </Layout.Grid>
          <Layout.VerticalRow gap={0} item="marketQuarter">
            <Auto.ComboBox
              label="Submarket & Market Quarter"
              name="marketQuarter"
              data-qa="marketQuarter-form-control"
              options={['Q1', 'Q2', 'Q3', 'Q4']}
            />
            <OnChange name="marketQuarter">{getNeighborhoodAndMarkets}</OnChange>
          </Layout.VerticalRow>
          <Layout.Grid item="subYear">
            <Input.Numbers
              format={{ allowNegative: false, format: '####', mask: ['Y', 'Y', 'Y', 'Y'], placeholder: 'YYYY' }}
              fullWidth
              label="Submarket & Market Year"
              name="marketYear"
              data-qa="marketYear-form-control"
            />
            <OnChange name="marketYear">{getNeighborhoodAndMarkets}</OnChange>
          </Layout.Grid>
          <Layout.Grid item="marketAnalysis">
            <CheckboxGroup items={marketAnalysesUseItems} horizontal groupName="marketAnalysisUses" />
          </Layout.Grid>
          <Layout.Grid item="marketSuggest">
            <Stack sx={{ maxWidth: 600 }} spacing={2} width={1}>
              {Object.keys(marketAnalysisUses).map(use => {
                if (marketAnalysisUses[use]) {
                  const label = upperFirst(use)
                  return (
                    <Stack direction="row" spacing={2}>
                      <Auto.Text label={`${label} Market`} name={`${use}Market`} options={marketItems} />
                      <Auto.Text label={`${label} Submarket`} name={`${use}Submarket`} options={submarketItems} />
                    </Stack>
                  )
                } else {
                  return null
                }
              })}
            </Stack>
          </Layout.Grid>
          <Layout.Grid item="action" placeItems="flex-start" sx={{ height: '64px' }}>
            <Button variant="contained" onClick={getMarketReports} disabled={!canInsertFromDropbox}>
              PULL FROM DROPBOX
            </Button>
          </Layout.Grid>
        </Layout.Grid>
        <Layout.Grid container="auto-flow / 1fr 1fr 1fr" gap={4} sx={{ pr: 1 }}>
          <Layout.VerticalRow>
            <FileSelectionField
              errorText={getErrorText('areaAndEconomicAnalysis')}
              fileType="areaAndEconomicAnalysis"
              fileTypesRestriction={['docx']}
              helperText="This file will appear in the Addenda."
              label="Area & Economic Analysis"
              name="areaAndEconomicAnalysis"
              onDelete={removeFile}
              parentId={authenticatedUser.organizationId}
              uploadEndpoint={FILES_ENDPOINT}
            />
            <FileSelectionField
              errorText={getErrorText('neighborhoodDescription')}
              fileType="neighborhoodDescription"
              fileTypesRestriction={['docx']}
              helperText="This file will appear after the Introduction."
              label="Neighborhood & Demographic Overview"
              name="neighborhoodDescription"
              onDelete={removeFile}
              parentId={authenticatedUser.organizationId}
              uploadEndpoint={FILES_ENDPOINT}
            />
          </Layout.VerticalRow>
          {usesForAnalysis.map(use => {
            const { label, name } = use
            return (
              <Layout.VerticalRow>
                <FileSelectionField
                  errorText={getErrorText(`${name}MarketAnalysis`)}
                  fileType={`${name}MarketAnalysis`}
                  fileTypesRestriction={['docx']}
                  helperText="This file will appear in the Addenda."
                  label={`${label} Market Analysis`}
                  name={`${name}MarketAnalysis`}
                  onDelete={removeFile}
                  parentId={authenticatedUser.organizationId}
                  uploadEndpoint={FILES_ENDPOINT}
                />
                <FileSelectionField
                  errorText={getErrorText(`${name}SubMarketAnalysis`)}
                  fileType={`${name}SubMarketAnalysis`}
                  fileTypesRestriction={['docx']}
                  helperText="This file will appear before Highest & Best Use."
                  label={`${label} Submarket Analysis`}
                  name={`${name}SubMarketAnalysis`}
                  onDelete={removeFile}
                  parentId={authenticatedUser.organizationId}
                  uploadEndpoint={FILES_ENDPOINT}
                />
              </Layout.VerticalRow>
            )
          })}
        </Layout.Grid>
      </Stack>
    </Paper>
  )
}

export default InsertedDocuments
