import { getPartsFromString } from '../../narrative/GeneratedText'

const generate = () => {
  const defaultSubsectionPlaceholderText = ``
  const gtParts = getPartsFromString(defaultSubsectionPlaceholderText)

  return gtParts
}

export default { generate }
