import React from 'react'
import { compose, pure } from 'recompose'
import { Typography } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'

import noImage from '../../../../../../images/no_image_available_stand_in.svg'

import { NO_SEARCH_RESULTS_LABEL, RESTRICTED_FILTER_LABEL } from '../../constants'

const styles = theme => ({
  noData: {
    marginTop: -440,
    width: '100%',
    height: 220,
  },
  image: {
    maxHeight: 150,
  },
})

const PropertySearchResultListNoData = ({ width, classes }) => {
  return (
    <div className={classes.noData} style={{ width }}>
      <img src={noImage || ''} className={classes.image} alt="" />
      <Typography align="center" gutterBottom variant="subtitle1">
        <b>{NO_SEARCH_RESULTS_LABEL}</b>
      </Typography>
      <Typography align="center" variant="subtitle1">
        {RESTRICTED_FILTER_LABEL}
      </Typography>
    </div>
  )
}

export default compose(withStyles(styles), pure)(PropertySearchResultListNoData)
