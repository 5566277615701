import React from 'react'
import PropTypes from 'prop-types'
import Slider from 'react-slick/lib'
import { withStyles } from '@material-ui/core/styles'

import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'

const styles = theme => ({
  slider: {
    '& .slick-arrow': {
      zIndex: 10,
      height: 25,
      opacity: 0,
      '&:before': {
        opacity: 0.5,
        fontSize: 25,
      },
      '&:hover:before': {
        opacity: 1,
      },
      '&.slick-prev': {
        left: 0,
        '&:before': {
          content: '"‹"',
        },
      },
      '&.slick-next': {
        right: 0,
        '&:before': {
          content: '"›"',
        },
      },
    },
    '& .slick-dots': {
      zIndex: 20,
      bottom: 6,
      '& li': {
        margin: 2,
        width: 'auto',
        height: 'auto',
      },
      '& .slick-active $sliderDot': {
        backgroundColor: '#fff',
      },
    },
    '&:hover .slick-arrow': {
      opacity: 1,
    },
  },
  sliderDot: {
    width: 3,
    height: 3,
    borderRadius: '50%',
    border: '1px solid #fff',
  },
})

const imageStyle = {
  backgroundSize: 'cover',
  backgroundRepeat: 'no-repeat',
  backgroundPosition: 'center',
}

class ImageCarousel extends React.PureComponent {
  static propTypes = {
    images: PropTypes.arrayOf(PropTypes.object),
    fallbackUrl: PropTypes.string.isRequired,
    width: PropTypes.number.isRequired,
    height: PropTypes.number.isRequired,
  }

  render() {
    const { fallbackUrl, width, height, classes } = this.props
    let { images } = this.props

    if (!images || !images.length) {
      images = [fallbackUrl]
    }

    if (images.length === 1) {
      return (
        <div
          data-qa="photo"
          style={{
            width,
            height,
            ...imageStyle,
            backgroundImage: `url(${images[0]})`,
          }}
        />
      )
    }
    return (
      <div
        style={{
          width,
          height,
        }}
      >
        <Slider
          dots
          infinite
          speed={500}
          slidesToShow={1}
          slidesToScroll={1}
          className={classes.slider}
          customPaging={() => <div className={classes.sliderDot} />}
        >
          {images.map((image, index) => (
            <React.Fragment key={index}>
              <div
                data-qa="photo"
                style={{
                  width,
                  height,
                  ...imageStyle,
                  backgroundImage: `url(${image})`,
                }}
              />
            </React.Fragment>
          ))}
        </Slider>
      </div>
    )
  }
}

const StyledImageCarousel = withStyles(styles)(ImageCarousel)

StyledImageCarousel.propTypes = {
  images: PropTypes.arrayOf(PropTypes.object),
  fallbackUrl: PropTypes.string.isRequired,
  width: PropTypes.number.isRequired,
  height: PropTypes.number.isRequired,
}

export default StyledImageCarousel
