import React from 'react'
import PropTypes from 'prop-types'

import { connect } from 'react-redux'
import { find, head } from 'lodash'

import { compose } from 'recompose'

import { rentCompGroupsSelector } from './redux/selectors'
import RentCompGroupsView from './RentCompGroupsView'

class RentCompGroups extends React.PureComponent {
  state = {
    droppableId: '',
  }

  openEditModal = unit => {
    const { showEditModal } = this.props
    showEditModal(unit)
  }

  onDragStart = event => {
    this.setState({ droppableId: event.source.droppableId })
  }

  onDragEnd = ({ source, destination, draggableId }) => {
    const { compGroups, selectedRentComps, onUpdateAllComps } = this.props
    if (!destination) {
      return
    }
    if (source.droppableId === destination.droppableId && source.index === destination.index) {
      return
    }

    const destCompGroup = find(compGroups, group => group._id === destination.droppableId)
    const srcCompGroup = find(compGroups, group => group._id === source.droppableId)

    const [removed] = srcCompGroup.units.splice(source.index, 1)
    destCompGroup.units.splice(destination.index, 0, removed)
    const newSelectedRentComps = selectedRentComps.map(rentComp => {
      let rentCompGroupId = rentComp.commercialCompGroupId
      const rentCompId = rentComp.leaseId
      if (draggableId === rentCompId) {
        rentCompGroupId = destination.droppableId
      }

      const compGroup = find(compGroups, group => group._id === rentCompGroupId) || head(compGroups)
      const rentCompIndex = compGroup.units.indexOf(rentComp)
      const displayOrder = rentCompIndex >= 0 ? rentCompIndex : compGroup.units.length
      return { ...rentComp, commercialCompGroupId: rentCompGroupId, displayOrder: displayOrder }
    })
    onUpdateAllComps(
      newSelectedRentComps.sort((rentCompA, rentCompB) => rentCompA.displayOrder - rentCompB.displayOrder)
    )
  }

  render() {
    const { onRemoveComp, compGroups, rentPsfLabel, form } = this.props

    const { rentRollRentBasis } = form.values
    const { onDragEnd, onDragStart, openEditModal } = this

    return (
      <RentCompGroupsView
        {...{
          compGroups,
          onDragEnd,
          onDragStart,
          onRemoveComp,
          openEditModal,
          rentPsfLabel,
          rentRollRentBasis,
        }}
      />
    )
  }
}

RentCompGroups.propTypes = {
  onUpdateAllComps: PropTypes.func.isRequired,
  selectedRentComps: PropTypes.arrayOf(PropTypes.object.isRequired),
  rentPsfLabel: PropTypes.string.isRequired,
}

RentCompGroups.defaultProps = {
  selectedRentComps: [],
}

export default compose(connect(rentCompGroupsSelector))(RentCompGroups)
