import React from 'react'
import PropTypes from 'prop-types'
import { Field } from 'react-final-form'
import { compose } from 'redux'
import ReactAutosuggest from 'react-autosuggest'
import match from 'autosuggest-highlight/match'
import parse from 'autosuggest-highlight/parse'
import { FormControl, Paper, Typography, withStyles, withTheme, IconButton } from '@material-ui/core'
import { TextField } from '@mui/material'
import MenuItem from '@material-ui/core/MenuItem'
import { assignWith, isFunction, isNil, includes, get, isEmpty } from 'lodash'
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined'

import { formatId } from 'client-shared/components/helpers'

import Portal from '../Portal'

const formControlHeight = 84
const textFieldHeight = 48
const denseTopMargin = 8
const menuContainerTopMargin = 4

const styles = theme => ({
  container: {
    flexGrow: 1,
    position: 'relative',
  },
  suggestionsList: {
    margin: 0,
    padding: 0,
    listStyleType: 'none',
  },
  noSuggestionsMessageContainer: {
    padding: 10,
  },
  formControl: {
    height: formControlHeight,
  },
  textField: {
    height: textFieldHeight,
  },
  suggestionsContainer: {
    marginTop: textFieldHeight - formControlHeight + denseTopMargin + menuContainerTopMargin,
    maxHeight: 230,
    overflowY: 'auto',
  },
  clearIcon: {
    color: 'rgba(34, 34, 34, 0.54)',
    width: 16,
    height: 16,
  },
})

const replaceValue = (value, suggestions) => {
  return suggestions.map(suggestion => {
    if (formatId(value) === formatId(suggestion)) {
      return value
    }
    return suggestion
  })
}

const addValueToSuggestions = (value, suggestions) => {
  return includes(suggestions.map(formatId), formatId(value))
    ? replaceValue(value, suggestions)
    : [...suggestions, value]
}

class Autosuggest extends React.PureComponent {
  static propTypes = {
    classes: PropTypes.object.isRequired,
    suggestions: PropTypes.array.isRequired,
    onChange: PropTypes.func.isRequired,
    onFocus: PropTypes.func,
    value: PropTypes.string,
    getSuggestionValue: PropTypes.func,
    onSuggestionSelected: PropTypes.func,
    modifyCurrentSearchSet: PropTypes.func,
    selectOnBlur: PropTypes.bool,
    inputProps: PropTypes.object,
    textFieldProps: PropTypes.object,
    caseSensitive: PropTypes.bool,
    focusOnMount: PropTypes.bool,
    highlightFirstSuggestion: PropTypes.bool,
    noSuggestionsMessage: PropTypes.string,
    renderSuggestionsContainer: PropTypes.func,
    renderMenuItem: PropTypes.func,
    autoFocus: PropTypes.bool,
    usePropSuggestionsDirectly: PropTypes.bool,
    required: PropTypes.bool,
    label: PropTypes.string,
    onSuggestionsFetchRequested: PropTypes.func,
    handleSuggestionsClearRequested: PropTypes.func,
    displayedSuggestionsCount: PropTypes.number,
    selfLearning: PropTypes.bool,
    showClearButton: PropTypes.bool,
  }

  static defaultProps = {
    displayedSuggestionsCount: Number.MAX_VALUE,
    onFocus: () => {},
    value: '',
    getSuggestionValue: value => value,
    modifyCurrentSearchSet: () => {},
    selectOnBlur: true,
    inputProps: {},
    textFieldProps: {},
    caseSensitive: false,
    focusOnMount: false,
    highlightFirstSuggestion: true,
    noSuggestionsMessage: null,
    renderSuggestionsContainer: null,
    renderMenuItem: null,
    autoFocus: false,
    usePropSuggestionsDirectly: false,
    required: false,
    label: '',
    onSuggestionsFetchRequested: null,
    onSuggestionsClearRequested: null,
    selfLearning: false,
    showClearButton: false,
  }

  state = {
    currentSuggestions: [],
  }

  constructor(props) {
    super(props)
    this.reactAutosuggest = React.createRef()
  }

  componentDidMount() {
    if (this.props.focusOnMount) {
      const input = get(this.reactAutosuggest, 'current.input', null)
      if (input !== null) {
        input.focus()
      }
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.usePropSuggestionsDirectly && this.props.suggestions !== prevProps.suggestions) {
      this.setState({ currentSuggestions: this.props.suggestions })
    }
  }

  get suggestionsContainerWidth() {
    return get(this.reactAutosuggest, 'current.input.clientWidth', 0)
  }

  onClearButtonClick = () => {
    this.props.onChange({ target: { value: '' } })
  }

  renderInput = autosuggestInputProps => {
    const { classes, required, label, name, inputProps, autoFocus, textFieldProps, showClearButton, value, shrink } =
      this.props
    const { ref, ...otherAutosuggestInputProps } = autosuggestInputProps
    const isClearButtonVisible = showClearButton && !isEmpty(value)
    const shouldLabelShrink = shrink ? true : undefined

    const mergedInputProps = assignWith(
      {},
      { onBlur: this.onInputBlur },
      inputProps,
      otherAutosuggestInputProps,
      isClearButtonVisible
        ? {
            endAdornment: (
              <IconButton className={classes.clearIcon} onClick={this.onClearButtonClick}>
                <CloseOutlinedIcon />
              </IconButton>
            ),
          }
        : {},
      (objValue, srcValue) => {
        if (isFunction(objValue) && isFunction(srcValue)) {
          return (...parameters) => {
            objValue(...parameters)
            srcValue(...parameters)
          }
        }
      }
    )

    return (
      <FormControl className={classes.formControl} variant="outlined" fullWidth data-qa={`${name}-form-control`}>
        <TextField
          {...textFieldProps}
          data-qa="autosuggest-text-input-field"
          variant="outlined"
          margin="dense"
          inputRef={ref}
          InputProps={mergedInputProps}
          autoFocus={autoFocus}
          InputLabelProps={{
            shrink: shouldLabelShrink,
          }}
          label={label}
          required={required}
          fullWidth
          className={classes.textField}
        />
      </FormControl>
    )
  }

  getMatches = (value, query) => {
    const { caseSensitive } = this.props

    return caseSensitive ? match(value, query) : match(value.toUpperCase(), query.toUpperCase())
  }

  renderSuggestion = (suggestion, { query: inputQuery, isHighlighted }) => {
    const { getSuggestionValue, renderMenuItem } = this.props

    const query = inputQuery.trim()
    const value = getSuggestionValue(suggestion)

    const matches = this.getMatches(value, query)
    const parts = parse(value, matches)

    return renderMenuItem ? (
      renderMenuItem(value, isHighlighted, parts)
    ) : (
      <MenuItem selected={isHighlighted} component="div" title={`${value}`}>
        <div>
          {parts.map((part, index) => {
            return part.highlight ? <strong key={index}>{part.text}</strong> : <span key={index}>{part.text}</span>
          })}
        </div>
      </MenuItem>
    )
  }

  renderNoSuggestionsMessage = () => {
    const { classes, value: inputValue, noSuggestionsMessage } = this.props
    const { currentSuggestions } = this.state
    const showNoSuggestionsMessage = noSuggestionsMessage && inputValue && currentSuggestions.length === 0
    return (
      showNoSuggestionsMessage && (
        <div className={classes.noSuggestionsMessageContainer}>
          <Typography variant="caption">{noSuggestionsMessage}</Typography>
        </div>
      )
    )
  }

  renderSuggestionsContainer = ({ containerProps, children }) => {
    const { theme, classes } = this.props
    return (
      <Portal zIndex={theme.zIndex.modal + 1} width={this.suggestionsContainerWidth}>
        <Paper {...containerProps} className={classes.suggestionsContainer} square>
          {children}
          {this.renderNoSuggestionsMessage()}
        </Paper>
      </Portal>
    )
  }

  getCurrentSuggestions = inputQuery => {
    const { suggestions = [], getSuggestionValue, displayedSuggestionsCount } = this.props

    const query = inputQuery.trim()
    const isExactMatch = includes(suggestions, query)
    if (isExactMatch) {
      return [query]
    }

    let currentSuggestions = suggestions
    if (query.length !== 0) {
      currentSuggestions = suggestions.filter(suggestion => {
        const value = getSuggestionValue(suggestion)
        return this.getMatches(value, query).length > 0
      })
    }

    return currentSuggestions
      .sort((suggestionA, suggestionB) => {
        const matchesLeft = this.getMatches(getSuggestionValue(suggestionA), query)
        const matchesRight = this.getMatches(getSuggestionValue(suggestionB), query)

        return matchesRight.length - matchesLeft.length
      })
      .slice(0, displayedSuggestionsCount)
  }

  onInputBlur = event => {
    const { getSuggestionValue, onSuggestionSelected, selectOnBlur } = this.props
    const { currentSuggestions } = this.state
    const { highlightedSuggestionIndex } = this.reactAutosuggest.current.state
    if (
      selectOnBlur &&
      !isNil(highlightedSuggestionIndex) &&
      currentSuggestions[highlightedSuggestionIndex] &&
      formatId(getSuggestionValue(currentSuggestions[highlightedSuggestionIndex])) !== formatId(event.target.value)
    ) {
      event.target.value = getSuggestionValue(currentSuggestions[highlightedSuggestionIndex])
      onSuggestionSelected(event, { suggestion: event.target.value })
    }
  }

  getRefreshedSuggestions = ({ value }) => {
    const { modifyCurrentSearchSet, usePropSuggestionsDirectly } = this.props
    if (usePropSuggestionsDirectly) {
      return
    }
    const currentSuggestions = this.getCurrentSuggestions(value)
    this.setState({
      currentSuggestions,
    })
    modifyCurrentSearchSet(currentSuggestions)
  }

  handleSuggestionsClearRequested = () => {
    const { modifyCurrentSearchSet, usePropSuggestionsDirectly } = this.props
    if (usePropSuggestionsDirectly) {
      return
    }
    this.setState({
      currentSuggestions: [],
    })
    modifyCurrentSearchSet([])
  }

  render() {
    const {
      classes,
      getSuggestionValue,
      onSuggestionSelected,
      renderSuggestionsContainer,
      value,
      onChange,
      highlightFirstSuggestion,
      selectOnBlur,
      onFocus,
      onBlur,
      onSuggestionsFetchRequested,
      onSuggestionsClearRequested,
      selfLearning,
    } = this.props

    const currentSuggestions = selfLearning
      ? addValueToSuggestions(value, this.state.currentSuggestions)
      : this.state.currentSuggestions

    return (
      <ReactAutosuggest
        ref={this.reactAutosuggest}
        theme={{
          container: classes.container,
          suggestionsList: classes.suggestionsList,
        }}
        suggestions={currentSuggestions}
        getSuggestionValue={getSuggestionValue}
        renderInputComponent={this.renderInput}
        renderSuggestionsContainer={renderSuggestionsContainer || this.renderSuggestionsContainer}
        renderSuggestion={this.renderSuggestion}
        inputProps={{
          value,
          onChange,
          onFocus,
          onBlur,
        }}
        highlightFirstSuggestion={highlightFirstSuggestion || selectOnBlur}
        onSuggestionSelected={onSuggestionSelected}
        onSuggestionsFetchRequested={onSuggestionsFetchRequested || this.getRefreshedSuggestions}
        onSuggestionsClearRequested={onSuggestionsClearRequested || this.handleSuggestionsClearRequested}
      />
    )
  }
}

const AutosuggestComponent = compose(withStyles(styles), withTheme())(Autosuggest)

function AutosuggestComponentWrap({ input, meta: { touched, error }, handleChange, textFieldProps, ...custom }) {
  const inErrorState = touched && !!error
  const errorText = inErrorState ? error : ''

  const onSuggestionSelected = (event, { suggestion }) => {
    event.persist()
    event.target.name = input.name
    event.target.value = suggestion
    input.onChange(event)
    handleChange(event)
  }

  return (
    <AutosuggestComponent
      value={input.value}
      onChange={input.onChange}
      onFocus={input.onFocus}
      onBlur={input.onBlur}
      onSuggestionSelected={onSuggestionSelected}
      textFieldProps={{
        error: inErrorState,
        helperText: errorText,
        ...textFieldProps,
      }}
      name={input.name}
      {...custom}
    />
  )
}

export function AutosuggestField({ name, label, suggestions, ...restProps }) {
  return (
    <Field name={name} label={label} component={AutosuggestComponentWrap} suggestions={suggestions} {...restProps} />
  )
}

AutosuggestField.propTypes = {
  name: PropTypes.string.isRequired,
  suggestions: PropTypes.array.isRequired,
  label: PropTypes.string,
  handleChange: PropTypes.func,
  shrink: PropTypes.bool,
}

AutosuggestField.defaultProps = {
  handleChange: () => {},
  shrink: true,
}
