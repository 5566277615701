import { get } from 'lodash'

export const mapDTO = formValues => {
  const { hasReport, reportSender, reportDate } = get(formValues, 'propertyCondition', {})

  return {
    hasReport,
    reportSender,
    reportDate,
  }
}

export const mapDataModel = report => {
  const { hasReport, reportSender, reportDate } = get(report, 'buildingDescription.propertyCondition', {})

  return {
    hasReport,
    reportSender,
    reportDate,
  }
}
