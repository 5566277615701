import { get, join } from 'lodash'

export const mapDTO = clients => {
  const clientCompanies = join(clients, ', ')
  return {
    clientCompany: clientCompanies,
  }
}

export const mapDataModel = report => {
  const reportClients = get(report, 'reportClients', [])
  const clients = join(
    reportClients
      .filter(reportClient => !!reportClient.client)
      .map(reportClient => {
        return reportClient.client.client
      }),
    ', '
  )
  return {
    clientCompany: clients,
  }
}
