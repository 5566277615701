import React from 'react'
import PropTypes from 'prop-types'
import { get } from 'lodash'

import { compose } from 'recompose'
import { connect } from 'react-redux'
import RefreshOutlined from '@mui/icons-material/RefreshOutlined'
import { Grid, Typography, IconButton, withStyles } from '@material-ui/core'
import DragHandleOutlinedIcon from '@mui/icons-material/DragHandleOutlined'
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined'
import { DataDisplay } from 'client-shared/components/DataDisplay'
import { formatInt } from 'client-shared/utils/numberFormatters'

import { Callout, DropDown } from '../../../../shared/components'
import Number from '../../../../shared/components/Number/Number'

import { complyingBulkInputsSelector } from './selectors'
import { complyingBulkInputStyles } from './helpers'
import {
  COMPLYING_TEXT,
  LAND_COMPATIBILITY_OPTION_TYPES,
  NON_COMPLYING_TEXT,
  ShortSiteAreaMeasureLabels,
} from './constants'

class ComplyingBulkInputsNYC extends React.PureComponent {
  static propTypes = {
    classes: PropTypes.object.isRequired,
    form: PropTypes.object.isRequired,
    floorAreaRatio: PropTypes.string,
    maxBuildableArea: PropTypes.number,
    siteArea: PropTypes.number,
    siteAreaDecimalPartLength: PropTypes.number.isRequired,
    hasValidZonePercentages: PropTypes.bool.isRequired,
    zoningFloorArea: PropTypes.number,
    calculatedFloorAreaRatio: PropTypes.number,
  }

  static defaultProps = {
    floorAreaRatio: null,
    maxBuildableArea: 0,
    zoningFloorArea: 0,
    calculatedFloorAreaRatio: 0,
  }

  componentDidMount() {
    const { floorAreaRatio } = this.props
    if (floorAreaRatio === null) {
      this.resetFAR()
    }
  }

  get FARHelperText() {
    const { hasValidZonePercentages, calculatedFloorAreaRatio } = this.props
    return `Calculated FAR: ${hasValidZonePercentages ? calculatedFloorAreaRatio.toFixed(2) : ''}`
  }

  get calloutContent() {
    const { zoningFloorArea, maxBuildableArea } = this.props
    const isComplying = zoningFloorArea <= maxBuildableArea
    return <Typography>{isComplying ? COMPLYING_TEXT : NON_COMPLYING_TEXT}</Typography>
  }

  get FARInputAdornment() {
    const { classes, calculatedFloorAreaRatio, floorAreaRatio } = this.props

    const usingCalculatedFAR = calculatedFloorAreaRatio === floorAreaRatio

    return (
      <IconButton
        onClick={this.resetFAR}
        disabled={usingCalculatedFAR}
        classes={{
          root: classes.refreshFARButton,
          disabled: classes.refreshFARButtonDisabled,
        }}
      >
        <RefreshOutlined />
      </IconButton>
    )
  }

  resetFAR = () => {
    const { form, calculatedFloorAreaRatio, hasValidZonePercentages } = this.props
    if (hasValidZonePercentages) {
      form.change('floorAreaRatio', calculatedFloorAreaRatio)
    }
  }

  render() {
    const { classes, siteAreaMeasure, siteArea, maxBuildableArea, zoningFloorArea, form } = this.props
    const grossBuildingArea = get(form, 'values.grossBuildingArea')
    const siteAreaMeasureLabel = ShortSiteAreaMeasureLabels[siteAreaMeasure]

    return (
      <React.Fragment>
        <Grid container justify="space-between" space={2} direction="row">
          <Grid item xs={3}>
            <DataDisplay title="Gross Building Area" data={`${formatInt(grossBuildingArea)} SF`} />
          </Grid>
          <Grid item xs={0.5}>
            <CloseOutlinedIcon fontSize="14px" fixedWidth className={classes.formulaIcon} />
          </Grid>
          <Grid item container xs={4} justify="space-between">
            <Grid item xs={1}>
              <Typography variant="body1" className={classes.formulaText}>
                ( 1 -
              </Typography>
            </Grid>
            <Grid item xs={9}>
              <Number
                name="mechanicalDeductions"
                label="Mechanical Deductions"
                adornment="%"
                placeholder="0"
                thousandSeparator
                decimalScale={2}
                data-qa="mechanicalDeductions-input"
                size="small"
              />
            </Grid>
            <Grid item xs={1}>
              <Typography variant="body1" className={classes.formulaText}>
                )
              </Typography>
            </Grid>
          </Grid>
          <Grid item xs={0.5}>
            <DragHandleOutlinedIcon fontSize="14px" fixedWidth className={classes.formulaIcon} />
          </Grid>
          <Grid item xs={3}>
            <DataDisplay title="Zoning Floor Area" data={`${formatInt(zoningFloorArea)} SF`} />
          </Grid>
        </Grid>
        <Grid container justify="space-between" space={2}>
          <Grid item xs={3}>
            <DataDisplay title="Site Area" data={`${formatInt(siteArea)} ${siteAreaMeasureLabel}`} />
          </Grid>
          <Grid item xs={0.5}>
            <CloseOutlinedIcon fontSize="14px" fixedWidth className={classes.formulaIcon} />
          </Grid>
          <Grid item container justify="center" xs={4}>
            <Grid item xs={9}>
              <Number
                name="floorAreaRatio"
                size="small"
                label="Floor Area Ratio (FAR)"
                placeholder="0"
                thousandSeparator
                decimalScale={2}
                helperText={this.FARHelperText}
                adornment={this.FARInputAdornment}
                data-qa="floorAreaRatio-input"
              />
            </Grid>
          </Grid>
          <Grid item xs={0.5}>
            <DragHandleOutlinedIcon fontSize="14px" fixedWidth className={classes.formulaIcon} />
          </Grid>
          <Grid item xs={3}>
            <DataDisplay
              title="Maximum Buildable Area"
              data={`${formatInt(maxBuildableArea) || 0} ${siteAreaMeasureLabel}`}
            />
          </Grid>
        </Grid>
        <Grid container>
          <Grid item xs={3}>
            <Number
              name="buildableUnits"
              label="Buildable Units"
              size="small"
              placeholder="0"
              decimalScale={0}
              adornment={
                <DropDown
                  name="buildableUnitsType"
                  items={LAND_COMPATIBILITY_OPTION_TYPES}
                  size="small"
                  classes={{
                    formControl: classes.dropDownFormControl,
                    select: classes.dropDownSelect,
                  }}
                />
              }
              adornmentPosition="end"
              data-qa="buildableUnits-input"
              classes={{ adornedEnd: classes.dropDownAdornedEnd }}
            />
          </Grid>
        </Grid>

        <Callout variant="info" content={this.calloutContent} />
      </React.Fragment>
    )
  }
}

export default compose(
  withStyles(complyingBulkInputStyles),
  connect(complyingBulkInputsSelector)
)(ComplyingBulkInputsNYC)
