import React from 'react'
import PropTypes from 'prop-types'
import { times } from 'lodash'
import { TableRow } from '@material-ui/core'

import { Labels } from '../CommercialRentReconciliationConstants'
import { Cell, TotalCell, BaseUnitCell, TotalBaseUnitCell, SubjectUnitCell, HeadingCell } from '../TableCells'
import { Number } from '../../../../../../shared/components'
import { toPercents, fromPercents } from '../../../../../../shared/utils/numberOperations'

import FieldCells from './components/FieldCells'

export default class UtilityAdjustments extends React.PureComponent {
  static propTypes = {
    fieldPrefix: PropTypes.string.isRequired,
    baseUnitFrontage: PropTypes.string,
    baseUnitCeilingHeight: PropTypes.string,
    baseUnitLocation: PropTypes.string,
    units: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
    rentComps: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
    disableInputs: PropTypes.bool.isRequired,
  }

  render() {
    const {
      fieldPrefix,
      baseUnitFrontage,
      baseUnitCeilingHeight,
      baseUnitLocation,
      units,
      rentComps,
      colsCount,
      disableInputs,
    } = this.props

    return (
      <React.Fragment>
        <TableRow>
          <HeadingCell>{Labels.UTILITY_ADJUSTMENTS}</HeadingCell>
        </TableRow>
        <TableRow data-qa="frontage-row">
          <Cell data-qa="row-label-cell">{Labels.FRONTAGE}</Cell>
          <BaseUnitCell data-qa="base-unit-cell">{baseUnitFrontage || Labels.NA}</BaseUnitCell>
          <FieldCells units={units} rentComps={rentComps} fieldType="frontage" />
        </TableRow>
        <TableRow data-qa="utility-adjustments-frontage-row">
          <TotalCell data-qa="row-label-cell">{Labels.ADJUSTMENT}</TotalCell>
          <TotalBaseUnitCell data-qa="base-unit-cell" />
          {units.map((unit, index) => (
            <SubjectUnitCell data-qa={`subject-unit-${index}-cell`} key={unit._id}>
              <Number
                name={`${fieldPrefix}.commercialUnits[${index}].adjustments.frontage`}
                decimalScale={2}
                adornment="%"
                format={toPercents}
                normalize={fromPercents}
                disabled={disableInputs}
              />
            </SubjectUnitCell>
          ))}
          {rentComps.map((rentComp, index) => (
            <Cell data-qa={`rent-comp-${index}-cell`} key={rentComp._id}>
              <Number
                name={`${fieldPrefix}.rentComps[${index}].adjustments.frontage`}
                decimalScale={2}
                adornment="%"
                format={toPercents}
                normalize={fromPercents}
                disabled={disableInputs}
              />
            </Cell>
          ))}
        </TableRow>
        <TableRow>
          {times(colsCount, index => (
            <Cell key={index}>&nbsp;</Cell>
          ))}
        </TableRow>
        <TableRow data-qa="ceiling-height-row">
          <Cell data-qa="row-label-cell">{Labels.CEILING_HEIGHT}</Cell>
          <BaseUnitCell data-qa="base-unit-cell">{baseUnitCeilingHeight || Labels.NA}</BaseUnitCell>
          <FieldCells units={units} rentComps={rentComps} fieldType="ceilingHeight" />
        </TableRow>
        <TableRow data-qa="utility-adjustments-ceiling-height-row">
          <TotalCell data-qa="row-label-cell">{Labels.ADJUSTMENT}</TotalCell>
          <TotalBaseUnitCell data-qa="base-unit-cell" />
          {units.map((unit, index) => (
            <SubjectUnitCell data-qa={`subject-unit-${index}-cell`} key={unit._id}>
              <Number
                name={`${fieldPrefix}.commercialUnits[${index}].adjustments.ceilingHeight`}
                adornment="%"
                format={toPercents}
                normalize={fromPercents}
                disabled={disableInputs}
              />
            </SubjectUnitCell>
          ))}
          {rentComps.map((rentComp, index) => (
            <Cell data-qa={`rent-comp-${index}-cell`} key={rentComp._id}>
              <Number
                name={`${fieldPrefix}.rentComps[${index}].adjustments.ceilingHeight`}
                adornment="%"
                format={toPercents}
                normalize={fromPercents}
                disabled={disableInputs}
              />
            </Cell>
          ))}
        </TableRow>
        <TableRow>
          {times(colsCount, index => (
            <Cell key={index}>&nbsp;</Cell>
          ))}
        </TableRow>
        <TableRow data-qa="corner-row">
          <Cell data-qa="row-label-cell">{Labels.CORNER}</Cell>
          <BaseUnitCell data-qa="base-unit-cell">{baseUnitLocation || Labels.NA}</BaseUnitCell>
          <FieldCells units={units} rentComps={rentComps} fieldType="location" />
        </TableRow>
        <TableRow data-qa="utility-adjustments-corner-row">
          <TotalCell data-qa="row-label-cell">{Labels.ADJUSTMENT}</TotalCell>
          <TotalBaseUnitCell data-qa="base-unit-cell" />
          {units.map((unit, index) => (
            <SubjectUnitCell data-qa={`subject-unit-${index}-cell`} key={unit._id}>
              <Number
                name={`${fieldPrefix}.commercialUnits[${index}].adjustments.location`}
                adornment="%"
                format={toPercents}
                normalize={fromPercents}
                disabled={disableInputs}
              />
            </SubjectUnitCell>
          ))}
          {rentComps.map((rentComp, index) => (
            <Cell data-qa={`rent-comp-${index}-cell`} key={rentComp._id}>
              <Number
                name={`${fieldPrefix}.rentComps[${index}].adjustments.location`}
                adornment="%"
                format={toPercents}
                normalize={fromPercents}
                disabled={disableInputs}
              />
            </Cell>
          ))}
        </TableRow>
      </React.Fragment>
    )
  }
}
