import React from 'react'
import PropTypes from 'prop-types'

import OpenInNewOutlinedIcon from '@mui/icons-material/OpenInNewOutlined'
import { Button, Stack } from '@mui/material'
import { withStyles } from '@mui/styles'
import { ContentState, convertToRaw, EditorState } from 'draft-js'
import draftToHtml from 'draftjs-to-html'
import htmlToDraft from 'html-to-draftjs'
import { get } from 'lodash'
import { Editor } from 'react-draft-wysiwyg'
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css'
import { connect } from 'react-redux'
import { compose, pure } from 'recompose'

import MenuIcon from '../../../../../shared/layout/MenuIcon'
import FormatBold from '../../../../images/format_bold.svg'
import FormatItalic from '../../../../images/format_italic.svg'
import FormatList from '../../../../images/format_list_bulleted.svg'
import FormatStrikethrough from '../../../../images/format_strikethrough.svg'
import FormatUnderlined from '../../../../images/format_underlined.svg'
import { updateNotes as updateNotesAction } from '../../../../redux/actions/report'
import SectionWrapper from '../../SectionWrapper'

import { Layout } from '../../constants'

import Emoji from './Emoji'

const styles = theme => ({
  toolbar: {
    padding: 0,
    borderRadius: 0,
    marginBottom: 0,
    border: 0,
    borderBottom: `1px solid ${theme.palette.grey[300]}`,
    '& .rdw-emoji-wrapper': {
      marginBottom: 0,
    },
  },
  optionWrapper: {
    margin: 0,
    width: 36,
    height: 36,
    border: 0,
    padding: 0,
    borderRight: `1px solid ${theme.palette.grey[300]}`,
    borderRadius: 0,
    '&:hover, &.rdw-option-active': {
      backgroundColor: 'rgba(0, 0, 0, 0.08)',
      border: 0,
      borderRight: `1px solid ${theme.palette.grey[300]}`,
      boxShadow: 'none',
    },
  },
  editor: {
    fontFamily: theme.typography.fontFamily,
    paddingLeft: 10,
    paddingRight: 10,
    width: Layout.DRAWER_WIDTH - Layout.MENU_WIDTH,
  },
  section: {
    marginBottom: 0,
    height: 36,
  },
  link: {
    paddingRight: 16,
    verticalAlign: 'middle',
  },
})

class NotesSection extends React.Component {
  emojiComponent = null

  static propTypes = {
    notes: PropTypes.string,
  }

  static defaultProps = {
    notes: '',
  }

  constructor(props) {
    super(props)
    const draftState = htmlToDraft(props.notes)
    if (draftState) {
      const contentState = ContentState.createFromBlockArray(draftState.contentBlocks)
      const editorState = EditorState.createWithContent(contentState)
      this.state = {
        editorState,
      }
    }
  }

  componentDidMount() {
    const { classes } = this.props

    this.emojiComponent = props => (
      <Emoji
        onExpandEvent={props.onExpandEvent}
        onChange={props.onChange}
        classes={{ optionWrapper: classes.optionWrapper }}
        expanded={props.expanded}
        config={props.config}
        onOpen={this.onEmojiModalOpen}
      />
    )
  }

  componentWillUnmount() {
    this.handleUpdateNotes({ showSuccessNotification: false })
  }

  onEditorStateChange = editorState => {
    this.setState({
      editorState,
    })
  }

  handleUpdateNotes = ({ showSuccessNotification }) => {
    const { editorState } = this.state
    const { updateNotes } = this.props
    const htmlContent = draftToHtml(convertToRaw(editorState.getCurrentContent()))
    updateNotes({ htmlContent, showSuccessNotification })
  }

  onSubmit = () => {
    this.handleUpdateNotes({ showSuccessNotification: true })
  }

  get toolbarOptions() {
    const { classes } = this.props
    return {
      options: ['inline', 'list', 'emoji'],
      inline: {
        options: ['bold', 'italic', 'underline', 'strikethrough'],
        className: classes.section,
        bold: {
          className: classes.optionWrapper,
          icon: FormatBold,
        },
        italic: {
          className: classes.optionWrapper,
          icon: FormatItalic,
        },
        underline: {
          className: classes.optionWrapper,
          icon: FormatUnderlined,
        },
        strikethrough: {
          className: classes.optionWrapper,
          icon: FormatStrikethrough,
        },
      },
      list: {
        options: ['unordered'],
        className: classes.section,
        unordered: {
          className: classes.optionWrapper,
          icon: FormatList,
        },
      },
      emoji: {
        component: this.emojiComponent,
      },
    }
  }

  onEmojiModalOpen = () => {
    const { editorState } = this.state
    this.setState({
      editorState: EditorState.setInlineStyleOverride(editorState, editorState.getCurrentInlineStyle().clear()),
    })
  }

  render() {
    const { classes } = this.props
    const { editorState } = this.state
    const suggestedTextLink =
      'https://docs.google.com/document/d/1WEWmMkIKRx5_9QoC8Mde1ZX0D2Vm7W1gf85kGx6uakU/edit?ts=5e3309d3'
    return (
      <SectionWrapper
        title="Notes"
        rightElement={
          <Stack direction="row" alignItems="center" justifyContent="flex-end" spacing={1}>
            <a className={classes.link} href={suggestedTextLink} target="_blank" rel="noopener noreferrer">
              <MenuIcon icon={OpenInNewOutlinedIcon} tooltipTitle="Bowery-Way Suggested Text" />
            </a>
            <Button onClick={this.onSubmit} variant="contained">
              Save Notes
            </Button>
          </Stack>
        }
      >
        <Editor
          toolbar={this.toolbarOptions}
          editorState={editorState}
          toolbarClassName={classes.toolbar}
          editorClassName={classes.editor}
          onEditorStateChange={this.onEditorStateChange}
        />
      </SectionWrapper>
    )
  }
}

const mapStateToProps = state => {
  return {
    notes: get(state, 'report.reportData.notes') || '',
  }
}

export default compose(
  pure,
  connect(mapStateToProps, { updateNotes: updateNotesAction }),
  withStyles(styles)
)(NotesSection)
