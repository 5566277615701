import React from 'react'

import {
  Button,
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
  Paper,
} from '@mui/material'

import { visuallyHidden } from '@mui/utils'
import { makeStyles } from '@mui/styles'
import { ThemeOptions } from '@mui/material/styles'

import { partition } from 'lodash'

import { CHIP_TABLE_COLUMNS } from './constants'
import { TableData } from './TableData'

const styles = (theme: ThemeOptions) => ({
  headerCell: {
    textAlign: 'left' as const,
    fontFamily: 'Nunito Sans',
    fontSize: 14,
    fontWeight: 'bold',
    color: theme.palette?.text?.primary,
  },
  normalBodyCell: {
    color: theme.palette?.text?.primary,
  },
  archivedBodyCell: {
    color: theme.palette?.text?.disabled,
  },
})

const useStyles = makeStyles(styles)

function descendingComparator<T>(left: T, right: T, orderBy: keyof T) {
  const leftValue = left[orderBy]
  const rightValue = right[orderBy]

  // Check for null or undefined values
  if (leftValue === null || leftValue === undefined) {
    return 1
  }
  if (rightValue === null || rightValue === undefined) {
    return -1
  }

  // Compare strings, considering empty strings as lesser values
  if (typeof leftValue === 'string' && typeof rightValue === 'string') {
    if (leftValue === '') {
      return 1
    }
    if (rightValue === '') {
      return -1
    }
    if (rightValue < leftValue) {
      return -1
    }
    if (rightValue > leftValue) {
      return 1
    }

    return 0
  }

  // Handle cases where types don't match (returning 0 keeps their relative order unchanged)
  return 0
}

type Order = 'asc' | 'desc'

function getComparator<Key extends keyof any>(
  order: Order,
  orderBy: Key
): (
  left: { [key in Key]: string | boolean | string[] },
  right: { [key in Key]: string | boolean | string[] }
) => number {
  return order === 'desc'
    ? (left, right) => descendingComparator(left, right, orderBy)
    : (left, right) => -descendingComparator(left, right, orderBy)
}

function stableSort<T>(array: readonly T[], comparator: (a: T, b: T) => number) {
  const stabilizedThis = array.map((el, index) => [el, index] as [T, number])
  stabilizedThis.sort((left, right) => {
    const order = comparator(left[0], right[0])
    if (order !== 0) {
      return order
    }
    return left[1] - right[1]
  })
  return stabilizedThis.map(el => el[0])
}

function pushArchivedToEnd(array: readonly TableData[]) {
  const [archived, notArchived] = partition(array, chip => chip.isArchived)
  return notArchived.concat(archived)
}

interface ChipTableProps {
  chips: TableData[]
  onArchiveModalOpen: (chip: TableData) => void
  onEditModalOpen: (index: number, chip: TableData) => void
}

const DEFAULT_ORDER = 'asc'
const DEFAULT_ORDER_BY = 'displayName'

const ChipTable = (props: ChipTableProps) => {
  const { chips, onArchiveModalOpen, onEditModalOpen } = props
  const classes = useStyles()

  const [order, setOrder] = React.useState<Order>(DEFAULT_ORDER)
  const [orderBy, setOrderBy] = React.useState<keyof TableData>(DEFAULT_ORDER_BY)

  const sortedChips = React.useMemo(() => {
    let sortedRows = stableSort(chips, getComparator(order, orderBy))
    sortedRows = pushArchivedToEnd(sortedRows)

    return sortedRows
  }, [chips, order, orderBy])

  const handleRequestSort = React.useCallback(
    (newOrderBy: keyof TableData) => {
      const isAsc = orderBy === newOrderBy && order === 'asc'
      const toggledOrder = isAsc ? 'desc' : 'asc'
      setOrder(toggledOrder)
      setOrderBy(newOrderBy)
    },
    [order, orderBy]
  )

  const createSortHandler = (newOrderBy: keyof TableData) => () => {
    handleRequestSort(newOrderBy)
  }

  const getChipCellContent = ({ column, chip }: { column: any; chip: any }) => {
    switch (column.name) {
      case 'humanReadableName':
        return chip.displayName
      case 'dataPath':
        return chip.dataPath
      case 'formatter':
        return chip.formatter
      case 'group':
        return chip.groupName
      default:
        return null
    }
  }

  return (
    <TableContainer component={Paper}>
      <Table size="medium">
        <TableHead>
          <TableRow key="headRow">
            {CHIP_TABLE_COLUMNS.map(headCell => (
              <TableCell
                key={headCell.id}
                align="left"
                padding="normal"
                sortDirection={orderBy === headCell.id ? order : false}
              >
                <TableSortLabel
                  active={orderBy === headCell.id}
                  direction={orderBy === headCell.id ? order : 'asc'}
                  className={classes.headerCell}
                  onClick={createSortHandler(headCell.id)}
                  data-qa={`sort-${headCell.id}`}
                >
                  {headCell.title}
                  {orderBy === headCell.id ? (
                    <Box component="span" sx={visuallyHidden}>
                      {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                    </Box>
                  ) : null}
                </TableSortLabel>
              </TableCell>
            ))}
            <TableCell key="edit" align="left" padding="normal"></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {sortedChips.length === 0 ? (
            <TableRow key="emptyRow">
              <TableCell key="emptyCell" align="center" colSpan={CHIP_TABLE_COLUMNS.length + 1}>
                No Data.
              </TableCell>
            </TableRow>
          ) : (
            sortedChips.map((chip: TableData, index: number) => (
              <TableRow key={chip.id} data-qa={`row-${index}`}>
                {CHIP_TABLE_COLUMNS.map(column => (
                  <TableCell
                    key={column.name}
                    data-qa={`col-${column.name}`}
                    className={chip.isArchived ? classes.archivedBodyCell : classes.normalBodyCell}
                  >
                    {getChipCellContent({ column, chip })}
                  </TableCell>
                ))}
                <TableCell>
                  <Button
                    variant="outlined"
                    disabled={chip.isArchived}
                    onClick={() => {
                      onEditModalOpen(index, chip)
                    }}
                    data-qa="edit-button"
                  >
                    Edit
                  </Button>
                  <Button
                    variant="text"
                    onClick={() => {
                      onArchiveModalOpen(chip)
                    }}
                    data-qa="archive-button"
                  >
                    {chip.isArchived ? 'Restore' : 'Archive'}
                  </Button>
                </TableCell>
              </TableRow>
            ))
          )}
        </TableBody>
      </Table>
    </TableContainer>
  )
}

export default ChipTable
