import { get } from 'lodash'

const getSurveyOfCompetitiveRatesValues = surveyOfCompetitiveRates => {
  const tenYearTreasuryBondRate = get(surveyOfCompetitiveRates, 'tenYearTreasuryBond.rate')
  const suggestedMortgage = get(surveyOfCompetitiveRates, 'suggestedMortgage', {})
  return {
    tenYearTreasuryBondRate,
    suggestedMortgageMinRate: suggestedMortgage.minRate,
    suggestedMortgageMaxRate: suggestedMortgage.maxRate,
    suggestedMortgageMinBasisPoints: suggestedMortgage.minBasisPoints,
    suggestedMortgageMaxBasisPoints: suggestedMortgage.maxBasisPoints,
  }
}

export const mapDTO = formValues => {
  const surveyOfCompetitiveRates = get(formValues, 'surveyOfCompetitiveRates', {})
  return getSurveyOfCompetitiveRatesValues(surveyOfCompetitiveRates)
}

export const mapDataModel = report => {
  const surveyOfCompetitiveRates = get(report, 'surveyOfCompetitiveRates', {})
  return getSurveyOfCompetitiveRatesValues(surveyOfCompetitiveRates)
}
