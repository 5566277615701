import React from 'react'
import PropTypes from 'prop-types'
import { FormSpy } from 'react-final-form'
import { withStyles } from '@material-ui/core'
import { OnChange } from 'react-final-form-listeners'
import { compose, fromRenderProps, mapProps } from 'recompose'

import { Text } from '../components'
import { required } from '../utils/validation'

const styles = {
  root: {},
}

const OTHER_OPTION_VALUE = 'Other'

const withOtherField = WrappedComponent => {
  class WithOtherField extends React.PureComponent {
    static propTypes = {
      selectedOption: PropTypes.shape({
        value: PropTypes.string,
        other: PropTypes.string,
      }),
      name: PropTypes.string.isRequired,
      changeValue: PropTypes.func.isRequired,
      otherInputProps: PropTypes.object,
    }

    static defaultProps = {
      otherInputProps: {},
    }

    onSelectedOptionChange = (value, prevValue) => {
      const { name, changeValue } = this.props

      if (prevValue === OTHER_OPTION_VALUE) {
        changeValue(`${name}.other`, null)
      }
    }

    render() {
      const { classes, name, selectedOption, changeValue, otherInputProps, ...props } = this.props

      return (
        <div className={classes.root}>
          <WrappedComponent name={`${name}.value`} {...props} />
          {selectedOption.value === OTHER_OPTION_VALUE && (
            <Text label="Other" required validate={required} {...otherInputProps} name={`${name}.other`} />
          )}
          <OnChange name={`${name}.value`}>{this.onSelectedOptionChange}</OnChange>
        </div>
      )
    }
  }

  return compose(
    fromRenderProps(FormSpy, ({ form, values }) => ({
      changeValue: form.change,
      values,
    })),
    mapProps(({ values, name, ...props }) => ({
      selectedOption: values[name],
      name,
      ...props,
    })),
    withStyles(styles)
  )(WithOtherField)
}

export default withOtherField
