import React from 'react'
import { get } from 'lodash'
import PropTypes from 'prop-types'
import { Grid, InputLabel, Paper } from '@material-ui/core'

import { GENERAL_PROPERTY_DESCRIPTION_PATH } from 'shared/constants/report/keysAndDataPaths'

import { notEmpty } from 'client-shared/utils/validation'

import wrapForm from '../../wrapForm'
import StreetView from '../../../../shared/components/StreetView'

import { GRID_CONTAINER_GUTTER } from '../../../constants'

import SiteInspection from './SiteInspection'
import DescriptionOfImprovements from './DescriptionOfImprovements'
import PropertyConditionReport from './PropertyConditionReport'
import DeferredMaintenance from './DeferredMaintenance'
import EconomicLife from './EconomicLife'

export const DATA_PATH = GENERAL_PROPERTY_DESCRIPTION_PATH
const heading = 'Property Description'

const styles = theme => ({
  paper: theme.paper,
})

class GeneralPropertyDescription extends React.PureComponent {
  static propTypes = {
    coords: PropTypes.shape({
      latitude: PropTypes.number.isRequired,
      longitude: PropTypes.number.isRequired,
    }).isRequired,
    valueAsComplete: PropTypes.bool.isRequired,
    yearBuilt: PropTypes.number.isRequired,
    currentPath: PropTypes.string,
  }

  validateOtherType = field => (value, allValues) => {
    const isOtherType = allValues[field].other
    return isOtherType ? notEmpty(value) : null
  }

  render() {
    const { coords, valueAsComplete, inspectionDate, appraiser, yearBuilt, form, classes, currentPath } = this.props

    const {
      belowGradeType,
      belowGrade,
      externalWalls = {},
      inspectedLocations = {},
      windowTypes = {},
      buildingSecurity = {},
      propertyCondition,
      automationMetadata,
    } = form.values

    const hasOtherBelowGradeAccess = !!get(form, 'values.belowGrade.access.other', false)
    const hasPropertyConditionReport = get(form, 'values.propertyCondition.hasReport')
    const hasDeferredMaintenance = get(form, 'values.hasDeferredMaintenance', false)
    const hasOtherDeferredMaintenanceItems = !!get(form, 'values.deferredMaintenanceItems.other', false)

    return (
      <Grid container spacing={GRID_CONTAINER_GUTTER}>
        <Grid item xs={12}>
          <Paper className={classes.paper}>
            <InputLabel> Property Street View </InputLabel>
            <Grid item xs={6}>
              <StreetView name="propertyStreetView" coordinates={coords} />
            </Grid>
          </Paper>
        </Grid>
        <Paper className={classes.paper} data-qa="description-section-tile">
          <SiteInspection
            valueAsComplete={valueAsComplete}
            inspectionDate={inspectionDate}
            appraiser={appraiser}
            inspectedLocations={inspectedLocations}
            change={form.change}
            validateOther={this.validateOtherType}
            formValues={form.values}
            currentPath={currentPath}
            automationMetadata={automationMetadata}
          />
        </Paper>

        <Paper className={classes.paper} data-qa="description-of-improvements-tile">
          <DescriptionOfImprovements
            valueAsComplete={valueAsComplete}
            externalWalls={externalWalls}
            windowTypes={windowTypes}
            buildingSecurity={buildingSecurity}
            belowGradeType={belowGradeType}
            belowGrade={belowGrade}
            otherBelowGradeAccess={hasOtherBelowGradeAccess}
            change={form.change}
            validateOther={this.validateOtherType}
            automationMetadata={automationMetadata}
          />
        </Paper>
        <Grid xs={12}>
          <Paper className={classes.paper}>
            <PropertyConditionReport
              hasPropertyConditionReport={hasPropertyConditionReport}
              propertyCondition={propertyCondition}
            />
          </Paper>
        </Grid>
        <Grid xs={12}>
          <Paper className={classes.paper}>
            <DeferredMaintenance
              hasDeferredMaintenance={hasDeferredMaintenance}
              hasOtherItems={hasOtherDeferredMaintenanceItems}
              change={form.change}
              validateOther={this.validateOtherType}
              automationMetadata={automationMetadata}
            />
          </Paper>
        </Grid>

        <Paper className={classes.paper} data-qa="economic-life-tile">
          <EconomicLife valueAsComplete={valueAsComplete} yearBuilt={yearBuilt} />
        </Paper>
      </Grid>
    )
  }
}

const mapStateToProps = state => {
  const currentPath = get(state, 'shared.location.form.id')

  return {
    coords: get(state.report, 'reportData.propertyInformation.coords'),
    inspectionDate: get(state, 'report.reportData.report.reportInformation.inspectionDate'),
    valueAsComplete: get(state, 'report.reportSettings.valueAsComplete'),
    yearBuilt: get(state, 'report.reportData.propertyInformation.propertySummary.yearBuilt'),
    currentPath,
  }
}

export default wrapForm(DATA_PATH, { styles, heading }, mapStateToProps)(GeneralPropertyDescription)
