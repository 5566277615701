export const CAP_RATE_CONCLUSION = 'Cap Rate Conclusion'

export const CAP_RATE_CONCLUSION_SUMMARY_TABLE_TITLE = 'Cap Rate Conclusion Summary Table'

export const TOOLTIP_TEXT = 'The following generated commentary will appear in the Introduction of your report.'

export const CAP_RATE_DISCUSSION_TOOLTIP =
  'The generated commentary and appraiser commentary will appear in the Income Capitalization Conclusion section of the Income Approach of your report.'

export const WARNING_TEXT = `The comparable cap rates will appear after you complete the Cap Rate Comps section.`
