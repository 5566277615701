import React from 'react'
import PropTypes from 'prop-types'
import { times, get } from 'lodash'
import { Grid, Paper, withStyles, Typography } from '@material-ui/core'

import NarrativeComponent from 'client-shared/components/NarrativeComponent'
import AutomationStatus from 'client-shared/components/AutomationStatus'

import hotWaterSystemsDiscussion from 'shared/utils/textGeneration/property/hotWaterSystemsDiscussion'

import { HOT_WATER_SYSTEM_ITEMS, LOCATIONS, OTHER_TYPE } from '../../../../../../shared/constants/report/utilities'

import { arrayToKeyValuePairs } from '../../../../shared/utils/ui/checkboxHelper'

import { Text, Number, DropDown, CheckboxWithLabel } from '../../../../shared/components'

const styles = theme => ({
  paper: theme.paper,
  checkboxRoot: {
    height: 56,
  },
  checkBoxLabel: {
    marginTop: 0,
  },
})

class HotWaterSystems extends React.PureComponent {
  static propTypes = {
    inspected: PropTypes.bool,
    systems: PropTypes.arrayOf(PropTypes.object),
    count: PropTypes.number,
    location: PropTypes.string,
    form: PropTypes.object.isRequired,
  }

  static defaultProps = {
    count: 0,
    location: '',
    inspected: false,
  }

  get locationTypes() {
    return arrayToKeyValuePairs(LOCATIONS)
  }

  renderSystemRow = index => {
    const { systems } = this.props
    const isOtherType = get(systems, `${index}.type`) === OTHER_TYPE
    const isOtherLocation = get(systems, `${index}.location`) === OTHER_TYPE
    return (
      <Grid container spacing={16} key={index}>
        <Grid item xs={12}>
          <Typography variant="subtitle1">{`Hot Water System ${index + 1}`}</Typography>
        </Grid>
        <Grid item xs={6}>
          <DropDown
            name={`hotWaterSystems.systems.${index}.type`}
            label="Select Hot Water Type"
            items={HOT_WATER_SYSTEM_ITEMS}
          />
        </Grid>
        <Grid item xs={6}>
          <DropDown
            name={`hotWaterSystems.systems.${index}.location`}
            label="Select Hot Water Location"
            items={this.locationTypes}
          />
        </Grid>
        <Grid item xs={6}>
          {isOtherType && <Text name={`hotWaterSystems.systems.${index}.otherType`} label="Hot Water Type" />}
        </Grid>
        <Grid item xs={6}>
          {isOtherLocation && (
            <Text name={`hotWaterSystems.systems.${index}.otherLocation`} label="Hot Water System Location" />
          )}
        </Grid>
      </Grid>
    )
  }

  render() {
    const { form, classes, inspected, count, automatedInspectionPrefillFlag } = this.props
    const { automationMetadata } = form.values

    return (
      <Paper className={classes.paper}>
        <Grid direction="column" container spacing={8}>
          <Grid item>
            <Typography variant="h6">Hot Water System(s)</Typography>
          </Grid>
          <Grid item>
            {automatedInspectionPrefillFlag && automationMetadata && (
              <AutomationStatus
                formPaths={['automationMetadata']}
                message="We automated the information below from Mobile Inspection app"
              />
            )}
          </Grid>
          <Grid item>
            <Grid container direction="column">
              <Grid item>
                <CheckboxWithLabel name="hotWaterSystems.inspected" label="There is a water systems(s)" />
              </Grid>
              {inspected && (
                <React.Fragment>
                  <Grid item xs={3}>
                    <Number label="Number of Hot Water Systems" name="hotWaterSystems.count" allowNegative={false} />
                  </Grid>
                  <Grid item>{times(count, this.renderSystemRow)}</Grid>
                </React.Fragment>
              )}
            </Grid>
          </Grid>
          <Grid item>
            <NarrativeComponent
              title="Hot Water System Discussion"
              generatedText={hotWaterSystemsDiscussion.generate}
              data={hotWaterSystemsDiscussion.mapDTO(form.values)}
              name="hotWaterSystemsDiscussion"
              tooltipText="The following generated text will appear in the Description of Improvements' Utilities section of your report."
            />
          </Grid>
        </Grid>
      </Paper>
    )
  }
}

export default withStyles(styles)(HotWaterSystems)
