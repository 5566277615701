import React from 'react'
import PropTypes from 'prop-types'

import { Accordion, AccordionDetails, AccordionSummary, Box, Stack } from '@mui/material'
import ExpandMore from '@mui/icons-material/ExpandMore'
import { get } from 'lodash'

import { GeneratedComment, Number as NumberField } from 'client-shared/components'
import { fromPercents, toPercents } from 'client-shared/utils/numberOperations'
import { generateDebtCoverageRatioDiscussion } from 'shared/utils/textGeneration/incomeApproach/supportingCapRates'

const CoverageCapRateRateComponent = () => (
  <Stack alignItems="center" direction="row" spacing={1}>
    <NumberField allowNegative={false} decimalScale={2} label="Debt Coverage Ratio" name="debtCoverageRatio" />
    <Box>x</Box>
    <NumberField
      adornment="%"
      allowNegative={false}
      decimalScale={2}
      disabled
      format={toPercents}
      label="Loan To Value Ratio"
      name="loanValueRatio"
      normalize={fromPercents}
    />
    <Box>=</Box>
    <NumberField allowNegative={false} decimalScale={4} disabled label="Mortgage Constant" name="mortgageConstant" />
  </Stack>
)

const DebtCoverageRatio = ({ form }) => {
  const getDebtCoverageRatioDiscussion = React.useCallback(() => {
    const debtCoverageCapRate = get(form, 'values.debtCoverageCapRate')
    return generateDebtCoverageRatioDiscussion(debtCoverageCapRate)
  }, [form])

  return (
    <Accordion data-qa="debt-coverage-ratio-section">
      <AccordionSummary data-qa="selected-loan-terms-section-title" expandIcon={<ExpandMore />}>
        Debt Coverage Ratio
      </AccordionSummary>
      <AccordionDetails>
        <Stack spacing={2}>
          <CoverageCapRateRateComponent />
          <NumberField
            adornment="%"
            allowNegative={false}
            decimalScale={2}
            disabled
            format={toPercents}
            label="Debt Coverage Ratio Indicated Overall Rate"
            name="debtCoverageCapRate"
            normalize={fromPercents}
          />
          <GeneratedComment
            dataPath="debtCoverageRatioDiscussion"
            getGeneratedText={getDebtCoverageRatioDiscussion}
            isDynamicContent
            label="Debt Coverage Ratio Discussion"
            title="Generated Commentary"
            tooltipText="The following text and generated commentary will appear in the Income Approach of your report."
          />
        </Stack>
      </AccordionDetails>
    </Accordion>
  )
}

DebtCoverageRatio.propTypes = {
  form: PropTypes.object.isRequired,
}

export default DebtCoverageRatio
