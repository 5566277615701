import React from 'react'

import { ReactSVG } from 'react-svg'
import { Button, FormControl, Grid, Typography, withStyles } from '@material-ui/core'
import { Paper, Stack } from '@mui/material'
import Save from '@mui/icons-material/Save'
import SettingsRounded from '@mui/icons-material/SettingsRounded'
import { Form } from 'react-final-form'
import { get } from 'lodash'
import { compose } from 'recompose'
import { connect } from 'react-redux'
import arrayMutators from 'final-form-arrays'

import { organizationUsersCreate } from '../../../redux/actions/organization'
import { userOfficesFetch } from '../../../redux/actions/user'

import style from '../../../constants/organizationStyles'

import { Callout, Text, DropDown } from '../../../../shared/components'
import { ImageUpload } from '../../../../shared/components/ImageUpload'
import AppraisalInstituteDesignation from '../../../components/AppraisalInstituteDesignation'

import { arrayToKeyValuePairs } from '../../../../shared/utils/ui/checkboxHelper'
import reportPreviewIcon from '../../../../shared/images/report_preview_icon.svg'

import { CLIENT_PREFIXES } from '../../../../shared/constants'
import { email, hasSelectedItems, required } from '../../../../shared/utils/validation'

import { getFormalNamePreview } from '../../../../shared/utils/ui/nameHelper'
import FormHeader from '../../../../shared/components/FormHeader'
import CheckboxMultiselect from '../../../../shared/components/Multiselect/CheckboxMultiselect'

import AppraisalCertification from '../../../components/AppraisalCertification'
import AvatarEditor from '../../../components/AvatarEditor/AvatarEditor'
import { getRoleOptions } from '../../../../shared/utils/roles'
import { ROLE_MANAGEMENT_HELP_TEXT } from '../../../constants/organization'

const heading = 'Organization New User'

const styles = theme => ({
  ...style,
  formControl: {
    height: 200,
    width: '100%',
    marginTop: 8,
    marginBottom: 8,
  },
  certificateWrapper: {
    height: 800,
    width: '100%',
    marginTop: 8,
    marginBottom: 8,
  },

  title: {
    paddingBottom: 16,
  },
  roles: {
    fontSize: 2,
  },
  formalNamePreview: {
    marginTop: 16,
    marginBottom: 0,
  },
  iconWrapper: {
    paddingRight: 16,
  },
  displayIcon: {
    width: 28,
    height: 28,
    fill: '#344A5E',
    '& *': {
      fill: '#344A5E',
    },
  },
  loadingContainer: {
    position: 'fixed',
    top: '50%',
    left: '50%',
    '& svg': {
      color: '#009688',
    },
  },
  avatarFormControl: {
    width: 'calc(100% - 16px)',
    display: 'flex',
  },
  roleFormControl: {
    paddingBottom: 16,
  },
  imageTitle: {
    fontSize: 12,
    fontWeight: 'normal',
    color: '#989898',
    fontFamily: theme.typography.fontFamily,
  },
})

class CreateNewUser extends React.PureComponent {
  componentDidMount() {
    const { userOfficesFetch } = this.props

    userOfficesFetch()
  }

  handleSubmit = values => {
    const { organizationUsersCreate, organizationId } = this.props
    organizationUsersCreate({
      user: { organizationId, ...values },
    })
  }

  render() {
    const { classes, userOffices, roleTitles } = this.props

    return (
      <React.Fragment>
        <FormHeader
          classes={{ root: classes.formHeader }}
          title={heading}
          icon={<SettingsRounded />}
          section={this.props.section}
        />
        <Form
          onSubmit={this.handleSubmit}
          mutators={{
            ...arrayMutators,
          }}
          initialValues={{ roles: [], prefix: '', suffix: '' }}
          render={props => {
            const { handleSubmit, values, invalid, form } = props
            const { name, prefix, suffix } = values
            const formalNamePreview = getFormalNamePreview({ prefix, suffix, ...name })

            return (
              <form onSubmit={handleSubmit}>
                <Stack sx={{ minWidth: 600, maxWidth: 900 }}>
                  <Stack spacing={2}>
                    <Paper>
                      <Typography className={classes.title} variant="h6">
                        User Information
                      </Typography>
                      <Grid container>
                        <Grid item xs={7}>
                          <div>
                            <Typography variant="caption" className={classes.imageTitle}>
                              Avatar
                            </Typography>
                            <FormControl className={classes.avatarFormControl}>
                              <AvatarEditor avatar={get(values, 'avatar')} change={form.change} />
                            </FormControl>
                          </div>
                        </Grid>
                        <Grid item xs={5}>
                          <Text
                            id="name"
                            label="Username"
                            name="username"
                            margin="normal"
                            validate={email}
                            InputLabelProps={{
                              shrink: true,
                            }}
                          />
                          <div className={classes.roleFormControl}>
                            <CheckboxMultiselect
                              label="Roles"
                              name="roles"
                              items={roleTitles}
                              validate={hasSelectedItems('User must have at least one role.')}
                              placeholder="Select role"
                              helperText={ROLE_MANAGEMENT_HELP_TEXT}
                            />
                          </div>
                          <DropDown
                            name="prefix"
                            label="Prefix"
                            items={arrayToKeyValuePairs(CLIENT_PREFIXES)}
                            margin="normal"
                          />
                          <Text name="name.first" label="First Name" validate={required} />
                          <Text name="name.middle" label="Middle Initial" />
                          <Text name="name.last" label="Last Name" validate={required} />
                          <Text
                            name="suffix"
                            label="Suffix"
                            margin="normal"
                            tooltipText="A suffix should be comma-separated list of certifications"
                            fullWidth
                          />
                        </Grid>
                      </Grid>
                      <Grid item xs={12} lg={12}>
                        <Grid item xs={6} lg={4} />
                        <Callout
                          variant="info"
                          icon={
                            <ReactSVG
                              src={reportPreviewIcon}
                              beforeInjection={svg => {
                                svg.classList.add(classes.displayIcon)
                              }}
                            />
                          }
                          content={formalNamePreview}
                        />
                        <Text name="position" label="Position in Organization" margin="normal" />
                        <DropDown
                          name="boweryOffice"
                          label="Bowery Office"
                          items={arrayToKeyValuePairs(userOffices)}
                          fullWidth
                        />
                        <Text name="phone" label="Phone" margin="normal" />
                        <Typography variant="caption" className={classes.imageTitle}>
                          Signature
                        </Typography>
                        <FormControl className={classes.formControl}>
                          <ImageUpload name="signature" />
                        </FormControl>
                      </Grid>
                    </Paper>
                    <Paper>
                      <AppraisalInstituteDesignation />
                    </Paper>
                    <Paper>
                      <AppraisalCertification />
                    </Paper>
                  </Stack>
                  <Button
                    type="submit"
                    variant="fab"
                    className={classes.submitButton}
                    color="primary"
                    disabled={invalid}
                  >
                    <Save />
                  </Button>
                </Stack>
              </form>
            )
          }}
        />
      </React.Fragment>
    )
  }
}

export default compose(
  connect(
    state => {
      const roles = get(state, 'authentication.roles', [])
      const roleTitles = getRoleOptions(roles)
      return {
        organizationId: get(state, 'organization.organizationData._id'),
        userOffices: get(state, 'user.userOffices'),
        roleTitles,
      }
    },
    {
      organizationUsersCreate,
      userOfficesFetch,
    }
  ),
  withStyles(styles)
)(CreateNewUser)
