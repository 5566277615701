import React from 'react'
import { connect } from 'react-redux'
import { get } from 'lodash'

import { GEOGRAPHY_OPTIONS } from 'shared/constants/property/geography'

import NewYorkCity from './geographies/NewYorkCity'
import NewJersey from './geographies/NewJersey'

export default connect(state => {
  return {
    propertyState: get(state, 'report.reportData.state'),
  }
})(({ propertyState, ...restProps }) => {
  if (propertyState === GEOGRAPHY_OPTIONS.NJ) {
    return <NewJersey {...restProps} />
  }
  return <NewYorkCity {...restProps} />
})
