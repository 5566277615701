import { handleActions } from 'redux-actions'

import { DEFAULT_SORT_TYPE } from '../constants'

import {
  fetchRentCompsRequest,
  fetchRentCompsReceive,
  fetchRentCompsFail,
  importRentCompsFail,
  importRentCompsReceive,
  importRentCompsRequest,
} from './actions'

const initialState = {
  rentComps: [],
  rentCompsByCoords: [],
  isLoading: false,
  isLoaded: false,
  rentCompSearchResults: [],
  moreResultsAvailable: false,
  areSearchResultsLoading: false,
  areSearchResultsLoaded: false,
  polygon: [],
  isCustomSelection: false,
  filters: {
    polygon: [],
    sortType: DEFAULT_SORT_TYPE,
  },
  importedRentComps: [],
  areReportRentCompsLoading: false,
}

export const reducer = handleActions(
  {
    [fetchRentCompsRequest](state, { payload }) {
      return {
        ...state,
        isLoading: true,
      }
    },
    [fetchRentCompsReceive](state, { payload }) {
      return {
        ...state,
        rentComps: payload,
        isLoading: false,
        isLoaded: true,
      }
    },
    [fetchRentCompsFail](state) {
      return {
        ...state,
        isLoading: false,
      }
    },
    [importRentCompsRequest](state) {
      return {
        ...state,
        areReportRentCompsLoading: true,
      }
    },
    [importRentCompsReceive](state, { payload }) {
      return {
        ...state,
        areReportRentCompsLoading: false,
        importedRentComps: payload.rentComps,
      }
    },
    [importRentCompsFail](state) {
      return {
        ...state,
        areReportRentCompsLoading: false,
      }
    },
  },
  initialState
)
