import { get, startsWith, lowerCase } from 'lodash'

import { PROPERTY_USES } from '../../../../constants/property/zoning'

const zonesAmount = 4

export const getZones = property => {
  const zones = []

  for (let zoneIndex = 1; zoneIndex <= zonesAmount; zoneIndex++) {
    const zone = get(property, `ZoneDist${zoneIndex}`)
    if (zone) {
      zones.push({ zone })
    }
  }

  if (zones.length === 1) {
    zones[0].percentage = 100
  }

  return zones
}

export const getZonesAllowableUses = (zones = []) => {
  const zonesAllowableUses = new Set()
  zones.forEach(zone => {
    if (startsWith(zone, 'R')) {
      zonesAllowableUses.add(PROPERTY_USES.RESIDENTIAL)
      zonesAllowableUses.add(lowerCase(PROPERTY_USES.COMMUNITY_FACILITY))
    }
    if (startsWith(zone, 'C')) {
      zonesAllowableUses.add(PROPERTY_USES.COMMERCIAL)
    }
    if (startsWith(zone, 'M')) {
      zonesAllowableUses.add(PROPERTY_USES.INDUSTRIAL)
    }
  })
  return [...zonesAllowableUses]
}
