import { EXPORT_IN_VALUES } from 'shared/constants/salesApproach'

import { NO_IMAGE_AVAILABLE } from '../../../constants'

export const DEFAULT_COMPUTED_PANEL_LABEL = "Subject's Market Rate"

export const HEADING_NAME = 'Find Sales Comparables'

export const SALES_COMP_STOCK_PHOTO = NO_IMAGE_AVAILABLE

export const HEADER_COLUMNS = [
  { id: 'sourceIcon', padding: 'none', label: '' },
  { id: 'photo', padding: 'none', label: 'Photo', style: { width: 40 } },
  { id: 'type', padding: 'none', label: 'Type' },
  { id: 'distance', padding: 'none', label: 'Distance' },
  { id: 'address', padding: 'none', label: 'Address', style: { minWidth: 150 } },
  { id: 'status', padding: 'none', label: 'Status' },
  { id: 'saleDate', padding: 'none', label: 'Date Sold' },
  { id: 'yearBuilt', padding: 'none', label: 'Year Built' },
  { id: 'totalUnits', padding: 'none', label: 'Total Units' },
  { id: 'gba', padding: 'none', label: 'GBA (SF)' },
  { id: 'salePrice', padding: 'none', label: 'Sale Price' },
  { id: 'pricePerUnit', padding: 'none', label: '$/Unit' },
  { id: 'pricePerSquareFoot', padding: 'none', label: '$/SF' },
  { id: 'capRate', padding: 'none', label: 'Cap Rate' },
  { id: 'sourceOfInformation', padding: 'none', label: 'Source Of Information' },
  { id: 'numberUsedInReports', padding: 'none', label: 'Used In Reports', style: { width: 40 } },
]

export const SALES_COMPS_SORT_VALUES = {
  DATE_SOLD: 'Date Sold',
  CUSTOM: 'Custom',
  RELEVANCE: 'Relevance',
}
export const SALES_COMPS_SORT_DROPDOWN_ITEMS = [
  { label: SALES_COMPS_SORT_VALUES.DATE_SOLD, value: SALES_COMPS_SORT_VALUES.DATE_SOLD },
  { label: SALES_COMPS_SORT_VALUES.CUSTOM, value: SALES_COMPS_SORT_VALUES.CUSTOM },
  { label: SALES_COMPS_SORT_VALUES.RELEVANCE, value: SALES_COMPS_SORT_VALUES.RELEVANCE },
]

export const EXPORT_IN_DROPDOWN_ITEMS = [
  { label: EXPORT_IN_VALUES.ADDENDA, value: EXPORT_IN_VALUES.ADDENDA },
  { label: EXPORT_IN_VALUES.REPORT_BODY, value: EXPORT_IN_VALUES.REPORT_BODY },
]
