import React from 'react'
import PropTypes from 'prop-types'
import { Typography, Box } from '@mui/material'
import { Field } from 'react-final-form'
import NumberFormat from 'react-number-format'
import { capitalize } from 'lodash'
import BoweryDate from '@bowery-valuation/bowery-date'

const styles = {
  value: {
    fontWeight: 'bold',
    ml: 1,
  },
}

const UneditableBase = ({ label, input, thousandSeparator, decimalScale, date, formatter, prefix = '' }) => {
  let value = input.value
  const isNumberToFormat = thousandSeparator || decimalScale || decimalScale === 0
  const firstLetterNotCapitalized = value && typeof value === 'string' && value[0] !== value[0].toUpperCase()
  const isDate = !!date

  if (isNumberToFormat) {
    value = (
      <NumberFormat
        decimalScale={decimalScale}
        thousandSeparator={thousandSeparator}
        value={input.value}
        displayType="text"
      />
    )
  } else if (formatter) {
    value = formatter(value)
  } else if (firstLetterNotCapitalized) {
    // Note: we should not capitalize all strings - doing so would convert 'New York' to 'New york'
    value = capitalize(value)
  }

  if (isDate) {
    if (!value) {
      value = ''
    } else {
      const boweryDate = new BoweryDate(value)
      if (!boweryDate.isValidDate()) {
        value = ''
      } else {
        value = new BoweryDate(value).formatShortDateWithZeroes()
      }
    }
  }

  return (
    <Typography>
      {label}:
      <Box component="span" sx={styles.value}>
        {prefix}
        {value}
      </Box>
    </Typography>
  )
}

const UneditableField = React.memo(({ label, name, ...otherProps }) => {
  return <Field label={label} name={name} component={UneditableBase} {...otherProps} />
})

UneditableField.propTypes = {
  label: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  thousandSeparator: PropTypes.bool,
  decimalScale: PropTypes.number,
  date: PropTypes.bool,
  formatter: PropTypes.func,
  prefix: PropTypes.string,
}

export default UneditableField
