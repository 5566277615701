import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import { compose } from 'recompose'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import { Field } from 'react-final-form'
import OpenInNew from '@mui/icons-material/OpenInNew'
import {
  Grid,
  Typography,
  Paper,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  withStyles,
} from '@material-ui/core'
import AutomationStatus from 'client-shared/components/AutomationStatus'
import { withAutomatedZoningIsEnabledForReport } from 'report/forms/property/Zoning/Automation'

import { WAIVER_BASIS_OPTIONS } from 'shared/constants/property/zoning'
import parkingConforming from 'shared/utils/textGeneration/property/parkingConforming'
import { GEOGRAPHY_OPTIONS } from 'shared/constants/property/geography'

import Number from '../../../../shared/components/Table/TableNumber'
import { BOROUGH_NUMBER } from '../../../../shared/constants/borough'
import { toPercents, fromPercents } from '../../../../shared/utils/numberOperations'
import {
  Callout,
  CheckboxWithLabel,
  RadioButtonList,
  BooleanRadioButtonList,
  NarrativeComponent,
} from '../../../../shared/components'

import { PropertyTypes } from '../../../../../../shared/constants'

import { parkingConformitySelector } from './selectors'
import {
  PARKING_CONFORMITY_OPTIONS,
  NJ_STATE_AUTHORITY_LINK,
  STATE_AUTHORITY,
  NYC_PLANNING,
  TEXT_GENERATION_TOOLTIP,
} from './constants'

const styles = theme => ({
  paper: theme.paper,
  sectionTitle: {
    letterSpacing: 0.2,
    fontSize: 16,
  },
  callout: {
    backgroundColor: theme.palette.primary[50],
    margin: 0,
  },
  link: {
    color: theme.palette.primary.main,
  },
  rightTableCell: {
    '&, & input, & p': {
      textAlign: 'right',
    },
  },
  linkIcon: {
    color: theme.palette.secondary[600],
    height: 24,
    width: 24,
  },
  tableTitle: {
    marginBottom: 5,
  },
  table: {
    borderRadius: 5,
    overflow: 'hidden',
    borderCollapse: 'inherit',
    border: `1px solid ${theme.palette.secondary[300]}`,
  },
  tableHeader: {
    backgroundColor: theme.palette.secondary[600],
  },
  tableHeaderCell: {
    color: theme.palette.common.white,
  },
  leftCell: {
    '&, & input': {
      textAlign: 'left',
    },
  },
})

class Parking extends React.PureComponent {
  static propTypes = {
    classes: PropTypes.object.isRequired,
    reportId: PropTypes.string.isRequired,
    propertyType: PropTypes.string.isRequired,
    isNYCReport: PropTypes.bool.isRequired,
    isFreddieMacReport: PropTypes.bool.isRequired,
    actualParkingSpaces: PropTypes.number.isRequired,
    residentialUnits: PropTypes.number.isRequired,
    grossBuildingArea: PropTypes.number.isRequired,
    requiredParkingSpaces: PropTypes.number.isRequired,
    requiredParkingPerUnit: PropTypes.number.isRequired,
    requiredParkingPerSF: PropTypes.number.isRequired,
    areWaivedParkingRequirements: PropTypes.bool.isRequired,
    automatedZoningEnabled: PropTypes.bool.isRequired,
    address: PropTypes.shape({
      state: PropTypes.string,
      borough: PropTypes.string,
      county: PropTypes.string,
      city: PropTypes.string,
      block: PropTypes.number,
      lot: PropTypes.string,
      locationIdentifier: PropTypes.oneOf(Object.values(GEOGRAPHY_OPTIONS)).isRequired,
    }).isRequired,
  }

  getCalloutContent = () => {
    const { classes, isNYCReport, isNJReport, address } = this.props

    let linkPath
    if (isNYCReport) {
      const boroughNumber = BOROUGH_NUMBER[address.borough]
      linkPath = `https://zola.planning.nyc.gov/lot/${boroughNumber}/${address.block}/${address.lot}`
    } else if (isNJReport) {
      linkPath = NJ_STATE_AUTHORITY_LINK
    } else {
      return `Visit your ${STATE_AUTHORITY} for information about parking requirements within your zone.`
    }

    const linkText = isNYCReport ? NYC_PLANNING : STATE_AUTHORITY

    return (
      <React.Fragment>
        Visit your
        <a href={linkPath} target="_blank" rel="noopener noreferrer" className={classes.link}>
          {` ${linkText} `}
        </a>
        for information about parking requirements within your zone.
      </React.Fragment>
    )
  }

  renderParkingTable = () => {
    const {
      classes,
      address,
      residentialUnits,
      actualParkingSpaces,
      requiredParkingSpaces,
      propertyType,
      grossBuildingArea,
    } = this.props
    const tableHeaderCellClass = classnames(classes.tableHeaderCell, classes.rightTableCell)

    const shouldDisplayRequiredParkingPerUnits = address.locationIdentifier === GEOGRAPHY_OPTIONS.NY

    const isCommercial = propertyType === PropertyTypes.COMMERCIAL

    return (
      <Table data-qa="parkingTable" classes={{ root: classes.table }}>
        <TableHead classes={{ root: classes.tableHeader }}>
          <TableRow>
            {isCommercial ? (
              <>
                <TableCell classes={{ root: classes.tableHeaderCell }}>GBA</TableCell>
                {shouldDisplayRequiredParkingPerUnits && (
                  <TableCell classes={{ root: tableHeaderCellClass }}># of Spaces Required/1,000 SF</TableCell>
                )}
              </>
            ) : (
              <>
                <TableCell classes={{ root: classes.tableHeaderCell }}>Residential Units</TableCell>
                {shouldDisplayRequiredParkingPerUnits && (
                  <TableCell classes={{ root: tableHeaderCellClass }}>
                    Required Parking % Per Residential Units
                  </TableCell>
                )}
              </>
            )}
            <TableCell classes={{ root: tableHeaderCellClass }}>Actual Parking Spaces</TableCell>
            <TableCell classes={{ root: tableHeaderCellClass }}>Required Parking</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          <TableRow>
            {isCommercial ? (
              <>
                <TableCell data-qa="parkingTable-gba">
                  {new Intl.NumberFormat('en-US').format(grossBuildingArea)}
                </TableCell>
                {shouldDisplayRequiredParkingPerUnits && (
                  <TableCell data-qa="parkingTable-requiredParkingPerSF" classes={{ root: classes.rightTableCell }}>
                    <Number name="requiredParkingPerSF" placeholder="0" decimalScale={2} min={0} />
                  </TableCell>
                )}
              </>
            ) : (
              <>
                <TableCell data-qa="parkingTable-residentialUnits">{residentialUnits}</TableCell>
                {shouldDisplayRequiredParkingPerUnits && (
                  <TableCell data-qa="parkingTable-requiredParkingPerUnit" classes={{ root: classes.rightTableCell }}>
                    <Number
                      name="requiredParkingPerUnit"
                      placeholder="0"
                      adornment="%"
                      format={toPercents}
                      normalize={fromPercents}
                    />
                  </TableCell>
                )}
              </>
            )}
            <TableCell data-qa="parkingTable-actualParkingSpaces" classes={{ root: classes.rightTableCell }}>
              {actualParkingSpaces}
            </TableCell>
            <TableCell data-qa="parkingTable-requiredParkingSpaces" classes={{ root: classes.rightTableCell }}>
              {shouldDisplayRequiredParkingPerUnits ? (
                Math.ceil(requiredParkingSpaces)
              ) : (
                <Number name="requiredParkingSpaces" placeholder="0" />
              )}
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    )
  }

  render() {
    const { classes, form, reportId, areWaivedParkingRequirements, automatedZoningEnabled } = this.props
    const formValues = form.getState().values

    return (
      <Paper className={classes.paper}>
        <Grid container spacing={16} data-qa="parking-section-tile">
          <Grid item xs={12}>
            <Typography variant="h6">Parking Conforming</Typography>
          </Grid>

          <Grid item xs={12}>
            <Callout
              content={<Typography variant="body1">{this.getCalloutContent()}</Typography>}
              sx={{ mb: 1 }}
              variant="link"
            />
          </Grid>

          <Grid item xs={12}>
            <Grid container direction="row" justify="space-between" alignItems="center" className={classes.tableTitle}>
              <Grid item>
                <Typography variant="subtitle2">Parking</Typography>
              </Grid>
              <Grid item>
                <Link to={`/report/${reportId}/amenities`}>
                  <OpenInNew className={classes.linkIcon} />
                </Link>
              </Grid>
              <Grid item xs={12}>
                {automatedZoningEnabled && (
                  <AutomationStatus formPaths={['automationMetadata']} sx={{ marginTop: '8px', marginBottom: '8px' }} />
                )}
              </Grid>
            </Grid>

            {this.renderParkingTable()}
          </Grid>

          <Grid item xs={12}>
            <CheckboxWithLabel
              size="small"
              name="areWaivedParkingRequirements"
              label="Waive parking requirements."
              handleChange={(event, areWaivedParkingRequirements) => {
                const clearWaiverBasis = !areWaivedParkingRequirements
                if (clearWaiverBasis) {
                  form.change('waiverBasis', null)
                }
              }}
            />
            <Field name="waiverBasis">{() => null}</Field>
            {areWaivedParkingRequirements && (
              <RadioButtonList label="Basis for Waiver" name="waiverBasis" items={WAIVER_BASIS_OPTIONS} />
            )}
          </Grid>

          <Grid item xs={12}>
            <BooleanRadioButtonList
              label="Compliance"
              name="isConformingWithParkingRequirements"
              items={PARKING_CONFORMITY_OPTIONS}
            />
          </Grid>

          <Grid item xs={12}>
            <NarrativeComponent
              title="Parking Conformity Discussion"
              name="parkingDiscussion"
              generatedText={parkingConforming.generate}
              data={parkingConforming.mapDTO(formValues)}
              tooltipText={TEXT_GENERATION_TOOLTIP}
            />
          </Grid>
        </Grid>
      </Paper>
    )
  }
}

export default compose(
  withStyles(styles),
  connect(parkingConformitySelector),
  withAutomatedZoningIsEnabledForReport
)(Parking)
