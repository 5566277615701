// Breaks dependency cycle on reportForms
let reportForms = null

export function initReportForms(_reportForms) {
  reportForms = _reportForms
}

export function getReportForms() {
  if (reportForms === null) {
    throw new Error('reportForms not initialized.')
  }
  return reportForms
}
