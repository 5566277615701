import { handleActions } from 'redux-actions'

import { clearSearchBuildingCompProperties, setSelectedBuildingComp } from '../actions'

const initialState = {
  isLoaded: false,
  isPropertiesLoaded: false,
}

export const reducer = handleActions(
  {
    [clearSearchBuildingCompProperties](state) {
      return {
        ...state,
        properties: [],
        isLoaded: false,
        isPropertiesLoaded: false,
      }
    },
    [setSelectedBuildingComp](state, { payload }) {
      return {
        ...state,
        selectedComp: payload,
      }
    },
  },
  initialState
)
