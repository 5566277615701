import { get } from 'lodash'

import { formatList } from '../../../../../client/src/shared/utils/textHelpers'

function mapData(fullAddress, zones) {
  const selectedZones = []
  zones.forEach(({ zone, commercialOverlay }) => {
    if (commercialOverlay) {
      selectedZones.push(`a ${zone} zone with a ${commercialOverlay} commercial overlay`)
    } else if (zone) {
      selectedZones.push(`a ${zone} zone`)
    }
  })
  const zoningPhrase = `${fullAddress || '[Address]'} is in ${formatList(selectedZones) || '[Zone]'}.`

  return { zoningPhrase }
}

export const mapDTO = formValues => {
  const { fullAddress, zones = [] } = formValues

  return mapData(fullAddress, zones)
}

export const mapDataModel = report => {
  const address = get(report, 'property_information.address') || {}
  const zones = get(report, 'zoning.zones') || []

  return mapData(address, zones)
}
