import React from 'react'
import { noop } from 'lodash'
import { useForm } from 'react-final-form'
import PropTypes from 'prop-types'
import {
  ColumnDataTypeEnum,
  RowBasedTable,
  CELL_COLUMN_CONFIGURATIONS,
  GRID_COLUMNS_CONFIG,
  useRowsApi,
} from '@bowery-valuation/ui-components'

import TaxCalculations from 'shared/report-calculations/income-approach/tax/tax-calculations'

import {
  getTaxLiabilityLabel,
  getBasisLabel,
  getTaxRateName,
  getFormattedBasisValue,
} from 'shared/report-calculations/income-approach/tax/helpers'

import { LABELS } from '../../../../../../shared/constants/report/incomeApproach/taxes'
import { formatCurrencyFloat, formatPercentageString } from '../../../../shared/utils/numberFormatters'

import { useRegisteredFields } from './hooks'
import {
  CELL_EDITOR_SELECTORS,
  valueCellRendererFramework,
  additionalTaxRateToRow,
  additionalSpecialAssessmentsToRow,
} from './constants'

import '@bowery-valuation/ui-components/dist/style.css'

const getRows = (formValues, basisForSFAnalysis) => {
  const {
    taxLiability,
    taxableAssessedValue,
    taxRate,
    additionalSpecialAssessments,
    additionalTaxRates,
    taxClass,
    jurisdictionName,
    basis,
    residentialUnitCount,
    squareFootage,
  } = formValues

  const taxRateName = getTaxRateName(taxRate, taxClass, jurisdictionName)
  const taxesPerBasisLabel = getTaxLiabilityLabel(basis)
  const taxesPerBasis = TaxCalculations.calculateTaxesPerBasis(basis, taxLiability, squareFootage, residentialUnitCount)
  const basisLabel = getBasisLabel(basis, basisForSFAnalysis)
  const basisValue = getFormattedBasisValue(basis, squareFootage, residentialUnitCount)

  return [
    {
      id: 'taxableAssessedValue',
      value: formatCurrencyFloat(taxableAssessedValue),
      label: 'Taxable Assessed Value',
      type: ColumnDataTypeEnum.text,
      readOnly: true,
      suppressMovable: true,
      permanent: true,
      rowDef: { hideAction: true },
    },
    {
      id: 'taxRateValue',
      value: formatPercentageString(taxRate.value, 10),
      label: `${LABELS.TAX_RATE} (${taxRateName})`,
      type: ColumnDataTypeEnum.text,
      readOnly: true,
      suppressMovable: true,
      permanent: true,
      rowDef: { hideAction: true },
    },
    ...additionalTaxRates.map(additionalTaxRateToRow),
    ...additionalSpecialAssessments.map(additionalSpecialAssessmentsToRow),
    {
      id: 'taxLiabilityTotal',
      value: formatCurrencyFloat(taxLiability),
      label: LABELS.TAX_LIABILITY_TOTAL,
      type: ColumnDataTypeEnum.text,
      readOnly: true,
      suppressMovable: true,
      permanent: true,
      rowDef: { summary: true, hideAction: true },
    },
    {
      id: 'basis',
      value: basisValue,
      label: basisLabel,
      type: ColumnDataTypeEnum.text,
      readOnly: true,
      suppressMovable: true,
      permanent: true,
      rowDef: { hideAction: true },
    },
    {
      id: 'taxesPerBasis',
      value: formatCurrencyFloat(taxesPerBasis),
      label: taxesPerBasisLabel,
      type: ColumnDataTypeEnum.text,
      readOnly: true,
      suppressMovable: true,
      permanent: true,
      rowDef: { hideAction: true },
    },
  ]
}

const getColumns = () => {
  return [
    {
      name: 'label',
      label: 'Item',
      suppressMovable: true,
      itemIndex: 0,
      type: ColumnDataTypeEnum.text,
      permanent: true,
      align: 'left',
      readOnlyEdit: true,
      editable: ({ data }) => !!data.isEditableRow,
    },
    {
      name: 'value',
      label: 'Value',
      suppressMovable: true,
      itemIndex: 1,
      type: ColumnDataTypeEnum.text,
      permanent: true,
      align: 'right',
      readOnlyEdit: true,
      editable: ({ data }) => !!data.isEditableRow,
      cellEditorSelector: ({ data }) => {
        if (data.isAdditionalTaxRateRow) {
          return CELL_EDITOR_SELECTORS.taxRate
        }

        if (data.isAdditionalSpecialAssessmentsRow) {
          return CELL_EDITOR_SELECTORS.moneyTwoDecimals
        }

        return CELL_COLUMN_CONFIGURATIONS[data.type]
      },
      cellRendererFramework: valueCellRendererFramework,
    },
  ]
}

const TaxLiabilityTable = ({ formValues, basisForSFAnalysis }) => {
  const additionalSpecialAssessmentRowsApi = useRowsApi('additionalSpecialAssessments')
  const additionalTaxRateRowsApi = useRowsApi('additionalTaxRates')
  const form = useForm()

  const { additionalSpecialAssessments, additionalTaxRates } = formValues
  const rows = getRows(formValues, basisForSFAnalysis)
  const columns = getColumns()

  const taxRateRegisteredFields = useRegisteredFields('additionalTaxRates', columns, additionalTaxRates)
  const specialAssessmentsRegisteredFields = useRegisteredFields(
    'additionalSpecialAssessments',
    columns,
    additionalSpecialAssessments
  )

  const handleRowDelete = rowId => {
    const taxRates = form.getFieldState('additionalTaxRates')?.value || []
    const specialAssessments = form.getFieldState('additionalSpecialAssessments')?.value || []
    const rowIsTaxRate = taxRates.some(row => row.id === rowId)
    const rowIsSpecialAssessment = specialAssessments.some(row => row.id === rowId)

    if (rowIsTaxRate) {
      additionalTaxRateRowsApi.deleteRow(rowId)
    } else if (rowIsSpecialAssessment) {
      additionalSpecialAssessmentRowsApi.deleteRow(rowId)
    }
  }
  const handleRowUpdate = row => {
    if (row.isAdditionalTaxRateRow) {
      additionalTaxRateRowsApi.updateRow(row)
    } else if (row.isAdditionalSpecialAssessmentsRow) {
      additionalSpecialAssessmentRowsApi.updateRow(row)
    }
  }

  const handleManyRowsUpdate = rows => {
    rows.forEach(row => {
      handleRowUpdate(row)
    })
  }

  const getCustomColumnConfig = column => {
    return {
      cellEditorSelector: column.cellEditorSelector,
      cellEditorFramework: undefined,
    }
  }
  const parseCellFromClipboard = params => {
    if (params.node.data.isAdditionalTaxRateRow && params.column.colId === 'value') {
      return GRID_COLUMNS_CONFIG[params.node.data.type].parsePastedValue(params.value)
    }

    return params.value
  }

  const handleCellFocus = event => {
    const rowIndex = event.rowIndex
    const columnId = event.column.colId
    const row = rows[rowIndex]

    if (row.isAdditionalTaxRateRow) {
      const additionalTaxRatesRowIndex = additionalTaxRates.findIndex(({ id }) => id === row.id)
      form.focus(`additionalTaxRates[${additionalTaxRatesRowIndex}].${columnId}`)
    } else if (row.isAdditionalSpecialAssessmentsRow) {
      const additionalSpecialAssessmentsRowIndex = additionalSpecialAssessments.findIndex(({ id }) => id === row.id)
      form.focus(`additionalSpecialAssessments[${additionalSpecialAssessmentsRowIndex}].${columnId}`)
    }
  }

  return (
    <>
      {taxRateRegisteredFields}
      {specialAssessmentsRegisteredFields}
      <RowBasedTable
        id="tax-liability"
        columns={columns}
        rows={rows}
        onRowDelete={handleRowDelete}
        onRowUpdate={handleRowUpdate}
        onManyRowsUpdate={handleManyRowsUpdate}
        onColumnDragEnd={noop}
        onRowsDragEnd={noop}
        onColumnDelete={noop}
        onColumnUpdate={noop}
        getCustomColumnConfig={getCustomColumnConfig}
        parseCellFromClipboard={parseCellFromClipboard}
        onCellFocused={handleCellFocus}
      />
    </>
  )
}

TaxLiabilityTable.propTypes = {
  formValues: PropTypes.object.isRequired,
}

export default TaxLiabilityTable
