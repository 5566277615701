import React from 'react'
import { Icon } from '@mui/material'

import blocksIcon from '../../images/blocks-icon.svg'

const BlocksIcon = props => (
  <Icon {...props}>
    <img src={blocksIcon} alt="Bowery Blocks Icon" />
  </Icon>
)

export default BlocksIcon
