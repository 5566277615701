import React, { useState } from 'react'
import MoreHorizIcon from '@mui/icons-material/MoreHoriz'
import {
  Box,
  Button,
  Select,
  FormControl,
  FormHelperText,
  InputLabel,
  Grid,
  Popover,
  MenuItem,
  Typography,
  IconButton,
} from '@mui/material'

import usePrevious from 'client-shared/hooks/usePrevious'
import { BASIS_OF_COMPARISON_OPTIONS } from 'shared/constants/expenses'

const options = Object.values(BASIS_OF_COMPARISON_OPTIONS)
const helperText = 'When viewing on per Unit or SF basis, values are display-only'
const label = 'Expense Item Basis of Comparison'

const ExpenseDetails = ({ title = 'Expense Details', setBasis, formValue }) => {
  const [basisOfComparison, setBasisOfComparison] = useState(formValue)
  const [anchorEl, setAnchorEl] = useState(null)
  const open = Boolean(anchorEl)
  const handleClick = event => {
    setAnchorEl(event.currentTarget)
  }

  const prevValue = usePrevious(basisOfComparison)
  const handleClose = (isSave = false) => {
    isSave ? setBasis(basisOfComparison) : setBasisOfComparison(prevValue)
    setAnchorEl(null)
  }

  const handleChange = event => {
    setBasisOfComparison(event.target.value)
  }

  return (
    <Box>
      <IconButton aria-label="menu" onClick={handleClick} sx={{ padding: '5px' }}>
        <MoreHorizIcon />
      </IconButton>
      <Popover
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        PaperProps={{
          style: {
            boxSizing: 'border-box',
            minWidth: '505px',
            padding: '16px 8px 8px 24px',
          },
        }}
      >
        <Typography variant="h6" sx={{ mb: '13px' }}>
          {title}
        </Typography>
        <Box>
          <FormControl fullWidth size="small" variant="outlined">
            <InputLabel sx={{ fontSize: '16px' }} id="expense-details-select-label">
              {label}
            </InputLabel>
            <Select
              labelId="expense-select-label"
              id="select"
              value={basisOfComparison}
              label={label}
              onChange={handleChange}
              MenuProps={{
                PaperProps: {
                  role: 'selectBar',
                },
              }}
            >
              {options.map(option => (
                <MenuItem
                  key={option}
                  selected={option === BASIS_OF_COMPARISON_OPTIONS.GROSS}
                  value={option}
                  disabled={option === basisOfComparison}
                >
                  {option}
                </MenuItem>
              ))}
            </Select>
            <FormHelperText>{helperText}</FormHelperText>
          </FormControl>
        </Box>
        <Grid container sx={{ mt: '25px' }} spacing={1} justifyContent="flex-end">
          <Button onClick={() => handleClose()} data-qa="expense-details-reject-btn">
            CANCEL
          </Button>
          <Button onClick={() => handleClose(true)} variant="contained" data-qa="expense-details-submit-btn">
            SAVE
          </Button>
        </Grid>
      </Popover>
    </Box>
  )
}

export default ExpenseDetails
