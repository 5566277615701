import { includes } from 'lodash'

export const VALUE_CONCLUSION_TYPES = {
  AS_IS: 'asIs',
  AS_STABILIZED: 'asStabilized',
  AS_COMPLETE: 'asComplete',
}

export const NPV_ROW_IDS = {
  AS_IS: 'asIsNpv',
  AS_STABILIZED: 'asStabilizedNpv',
  AS_COMPLETE: 'asCompleteNpv',
}

export const isNpvRow = (id: string) => {
  return (
    includes(id, NPV_ROW_IDS.AS_IS) || includes(id, NPV_ROW_IDS.AS_COMPLETE) || includes(id, NPV_ROW_IDS.AS_STABILIZED)
  )
}
