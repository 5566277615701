import React from 'react'
import ReactDOM from 'react-dom'

import { REPORTS_DASHBOARD_MENU_WRAPPER_ID } from './constants'

class ReportsDashboardTopMenu extends React.Component {
  constructor(props) {
    super(props)

    this.element = document.createElement('div')
  }

  componentDidMount() {
    this.reportsDashboardWrapperContainer = document.getElementById(REPORTS_DASHBOARD_MENU_WRAPPER_ID)
    this.reportsDashboardWrapperContainer.appendChild(this.element)
  }

  componentWillUnmount() {
    this.reportsDashboardWrapperContainer.removeChild(this.element)
  }

  render() {
    const { children } = this.props

    return ReactDOM.createPortal(children, this.element)
  }
}

export default ReportsDashboardTopMenu
