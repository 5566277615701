import React from 'react'

import Divider from '@mui/material/Divider'
import Grid from '@mui/material/Grid'

import ErrorOutline from '@mui/icons-material/ErrorOutline'
import Warning from '@mui/icons-material/Warning'

import MessagesContainer from './MessagesContainer'
import MessagesTable from './MessagesTable'

const ExportItemDetails = ({ errorsMessages, warningsMessages }) => (
  <Grid container direction="row" spacing={2}>
    <MessagesContainer messages={errorsMessages} messagesType="Errors" icon={<ErrorOutline color="error" />}>
      <MessagesTable messages={errorsMessages} />
      <Divider />
    </MessagesContainer>
    <MessagesContainer messages={warningsMessages} messagesType="Warnings" icon={<Warning color="warning" />}>
      <MessagesTable messages={warningsMessages} />
    </MessagesContainer>
  </Grid>
)

export default ExportItemDetails
