import React from 'react'
import PropTypes from 'prop-types'

import { withStyles } from '@mui/styles'
import classnames from 'classnames'
import { get } from 'lodash'
import { Redirect, Route, Switch } from 'react-router'

import LoadingWithDelay from 'client-shared/components/Loading/LoadingWithDelay'

import { Confirmation, JumpAhead, Notifications } from '../../shared/components'
import { NOTIFICATION_Z_INDEX } from '../../shared/constants'
import { HEADER_HEIGHT } from '../../shared/layout/constants'
import * as Socket from '../../shared/utils/socket'

import { DEFAULT_REPORT_REDIRECT, REPORT_LANDING_PAGE, STICKY_FORM_PANEL_WRAPPER_ID } from './constants'
import FormConfirmModal from './FormConfirmModal'
import FormLayout from './FormLayout'
import Header from './Header'
import Sidebar from './sidenav'
import { LayoutZIndexes } from './stylesConstants'

const styles = theme => ({
  formPanel: {
    width: '100%',
  },
  root: {
    backgroundColor: '#fafafa',
    display: 'flex',
    position: 'relative',
  },
  topNavigation: {
    height: HEADER_HEIGHT,
  },
  notifications: {
    position: 'fixed',
    top: HEADER_HEIGHT,
    right: 0,
    zIndex: NOTIFICATION_Z_INDEX,
  },
  pageContentWrapper: {
    flex: 1,
  },
  stickyFormPanel: {
    position: 'sticky',
    zIndex: LayoutZIndexes.TOP_REPORT_HEADER_REPORT_INFO,
    overflow: 'hidden',
    boxShadow: '0px 1px 4px -1px rgba(0,0,0,0.4)',
  },
  pageContent: {
    padding: 24,
    flex: 1,
    position: 'relative',
    boxSizing: 'border-box',
    display: 'flex',
  },
  container: {
    display: 'flex',
    flexDirection: 'row',
  },
  containerRoot: {
    flex: 1,
  },
})

class Layout extends React.Component {
  static propTypes = {
    match: PropTypes.shape({
      params: PropTypes.shape({
        id: PropTypes.string.isRequired,
      }).isRequired,
    }).isRequired,
    forms: PropTypes.array.isRequired,
    reportStructure: PropTypes.object,
    history: PropTypes.object.isRequired,
  }

  componentWillMount() {
    const { match, reportFetch, locationSet } = this.props

    const reportId = get(match, 'params.id')
    if (reportId) {
      reportFetch(reportId)
    }
    locationSet({ form: 'Report', hasLogo: false })
  }

  componentDidMount() {
    const { match } = this.props
    const reportId = get(match, 'params.id')

    Socket.on(`floodData:pulled:${reportId}`, this.setPulledFloodData)
  }

  componentWillUnmount() {
    const { match } = this.props
    const reportId = get(match, 'params.id')

    Socket.off(`floodData:pulled:${reportId}`, this.setPulledFloodData)
    this.props.clearPulledFloodData()

    this.props.reportClear()
    document.body.style.cursor = 'default'
  }

  setPulledFloodData = pulledFloodData => {
    this.props.setPulledFloodData(pulledFloodData)
  }

  getQueryWithoutLaunchSource() {
    const params = new URLSearchParams(window.location.search)
    const source = params.get('launch_source')
    if (source === null) {
      return null
    }
    params.delete('launch_source')
    return params.toString()
  }

  renderRoutes = () => {
    const { forms } = this.props
    const routes = forms
      .filter(form => form.showForm)
      .map(form => (
        <Route
          key={form.path}
          path={form.path}
          render={props => (
            <form.formComponent
              {...props}
              nextFormPath={form.nextFormPath}
              prevFormPath={form.prevFormPath}
              title={form.title}
              id={form.id}
              section={form.section}
              forms={forms}
            />
          )}
        />
      ))
    return routes
  }

  render() {
    const { forms, reportLoaded, classes, submitting } = this.props
    const isSaving = submitting || !reportLoaded

    if (reportLoaded) {
      const updatedQuery = this.getQueryWithoutLaunchSource()
      if (updatedQuery !== null) {
        return <Redirect to={{ search: updatedQuery }} />
      }
    }

    return (
      <div className={classes.root}>
        <LoadingWithDelay isLoading={isSaving} position="fixed" />
        <div className={classes.notifications}>
          <Notifications />
        </div>
        <Confirmation />
        <FormConfirmModal />
        {reportLoaded && (
          <div className={classes.containerRoot}>
            <Header />
            <JumpAhead formsStructure="report.reportStructure" />
            <div className={classes.container}>
              <Sidebar forms={forms} />
              <FormLayout>
                <div className={classes.pageContentWrapper}>
                  <div id={STICKY_FORM_PANEL_WRAPPER_ID} className={classes.stickyFormPanel} />
                  <div className={classes.pageContent}>
                    <div className={classnames('form-panel', classes.formPanel)}>
                      <Switch>
                        {this.renderRoutes()}
                        <Redirect exact from={REPORT_LANDING_PAGE} to={DEFAULT_REPORT_REDIRECT} />
                      </Switch>
                    </div>
                  </div>
                </div>
              </FormLayout>
            </div>
          </div>
        )}
      </div>
    )
  }
}

export default withStyles(styles)(Layout)
