import React from 'react'
import PropTypes from 'prop-types'
import { compose } from 'recompose'
import { connect } from 'react-redux'

import { transformSalesCompToRawOutput } from '../CreateSalesComp/drm/helpers/transformers'

import { CompPlexEvents } from '../../../../../shared/constants/compPlex'

import { trackAnalyticsData } from '../../../../../analytics/redux/actions'

import { SALES_EVENTS_RESULTS_LABELS } from './constants'

class ImportSalesComps extends React.PureComponent {
  static propTypes = {
    importComps: PropTypes.func.isRequired,
    compPlexRef: PropTypes.shape({ current: PropTypes.instanceOf(Element) }).isRequired,
  }

  componentDidMount() {
    const { compPlexRef } = this.props
    if (compPlexRef.current) {
      compPlexRef.current.addEventListener(CompPlexEvents.IMPORT_COMPS_COMPLETED, this.handleImportModalCompleted)
    }
  }

  componentWillUnmount() {
    const { compPlexRef } = this.props
    if (compPlexRef.current) {
      compPlexRef.current.removeEventListener(CompPlexEvents.IMPORT_COMPS_COMPLETED, this.handleImportModalCompleted)
    }
  }

  reconcileAdjustmentsWithComps = (transactionIds, comps) => {
    const compsWithAdjustments = comps.map(comp => {
      const { adjustments } = transactionIds.find(item => item === comp.salesTransactionId) || {}
      return adjustments ? { ...comp, adjustments } : comp
    })
    return compsWithAdjustments
  }

  handleImportModalCompleted = event => {
    const { selectedComps, groupedSalesTransactionIds, salesTransactionIds } = event.detail
    const { importComps } = this.props

    const mappedComps = selectedComps.map(comp => transformSalesCompToRawOutput(comp))
    const mappedCompsWithAdjustments = this.reconcileAdjustmentsWithComps(salesTransactionIds, mappedComps)

    const newOrUpdatedComps = importComps(mappedCompsWithAdjustments)
    if (newOrUpdatedComps) {
      const selectedTransactionIds = groupedSalesTransactionIds.find(
        eventId => eventId.label === SALES_EVENTS_RESULTS_LABELS.SELECTED_FOR_REPORT
      )
      const removedTransactionIds = groupedSalesTransactionIds.find(
        eventId => eventId.label === SALES_EVENTS_RESULTS_LABELS.REMOVED_FROM_REPORT
      )

      this.dispatchImportCompsSuccessEvent(mappedCompsWithAdjustments, selectedTransactionIds, removedTransactionIds)
    }
  }

  dispatchImportCompsSuccessEvent(mappedComps, selectedTransactionIds, removedTransactionIds) {
    const { dispatchImportSalesCompsSuccess } = this.props
    dispatchImportSalesCompsSuccess(
      mappedComps,
      selectedTransactionIds?.transactionIds,
      removedTransactionIds?.transactionIds
    )
  }

  render() {
    return null
  }
}

const mapDispatchToProps = dispatch => ({
  dispatchImportSalesCompsSuccess: (salesComps, selectedTransactionIds, removedTransactionIds) =>
    dispatch(trackAnalyticsData({ salesComps, selectedTransactionIds, removedTransactionIds })),
})

export default compose(connect(null, mapDispatchToProps))(ImportSalesComps)
