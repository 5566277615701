import React, { KeyboardEvent } from 'react'
import { Node } from 'slate'
import { Editor, PluginOrPlugins } from 'slate-react'
import Hotkeys from 'slate-hotkeys'

import { updateConditionalInlines } from '../../../../../../../shared/utils/narrative/updateNarrative'
import { EVENT_HANDLED, UPDATE_INLINE_NODES_COMMAND } from '../constants'

import { isConditional, isHiddenConditional } from '../../helpers'

import ConditionalInline from './ConditionalInline'

const getFormattedVisibleText = (nodes = []) =>
  nodes.reduce(
    (acc, node) =>
      // @ts-ignore
      acc.concat(...(node?.nodes?.filter(node => !isHiddenConditional(node)) || []).map(({ text }) => text)),
    ''
  )

export default {
  onCommand(command, editor, next) {
    const { type, args } = command
    if (type === UPDATE_INLINE_NODES_COMMAND) {
      const [data] = args
      const updatedValue = updateConditionalInlines(editor.value, data)
      editor.controller.setValue(updatedValue)
    }

    if (type === 'deleteAtRange') {
      const { selection, document, fragment } = editor.value

      const hasSelectedText =
        selection?.start?.key !== selection?.end?.key || selection?.start?.offset !== selection?.end?.offset
      if (hasSelectedText) {
        const allVisibleText = getFormattedVisibleText(document?.nodes as any)
        const fragmentText = getFormattedVisibleText(fragment?.nodes as any)

        if (allVisibleText === fragmentText) {
          // TODO: We should remove conditional before calling next, or it should happen automatically,
          // but I couldn't figure out how to make that happen.
          next()
          editor.removeAllConditionals()
          return
        }
      }
    }

    return next()
  },
  renderInline(props, editor, next) {
    const { attributes, node, children } = props
    if (node.type !== 'conditional') {
      return next()
    }

    const display = node.get('data').get('display')

    return <ConditionalInline attributes={{ ...attributes, display }}>{children}</ConditionalInline>
  },
  onKeyDown(event, editor, next) {
    if (editor.previousHiddenConditional() || editor.nextHiddenConditional() || editor.hiddenConditionalAncestor()) {
      switch (true) {
        case Hotkeys.isMoveBackward(event):
          editor.moveFocusBeforeConditional()
          return next()

        case Hotkeys.isMoveForward(event):
          editor.moveFocusAfterConditional()
          return next()

        case Hotkeys.isDeleteBackward(event):
          event.preventDefault()
          editor.moveFocusBeforeConditional()
          editor.deleteBackward(1)
          return EVENT_HANDLED

        case Hotkeys.isDeleteForward(event):
          event.preventDefault()
          editor.moveFocusAfterConditional()
          editor.deleteForward(1)
          return EVENT_HANDLED

        default:
          break
      }
    }

    return next()
  },
  onKeyUp(event: KeyboardEvent, editor: Editor, next: () => any) {
    if (editor.previousHiddenConditional() || editor.nextHiddenConditional() || editor.hiddenConditionalAncestor()) {
      switch (true) {
        case Hotkeys.isMoveBackward(event):
          event.preventDefault()
          editor.moveFocusBeforeConditional()
          return EVENT_HANDLED

        case Hotkeys.isMoveForward(event):
          event.preventDefault()
          editor.moveFocusAfterConditional()
          return EVENT_HANDLED

        default:
          break
      }
    }

    return next()
  },
  commands: {
    moveFocusAfterConditional(editor) {
      const { value } = editor
      const { document } = value

      const hiddenConditionalAncestor = editor.hiddenConditionalAncestor()
      if (hiddenConditionalAncestor) {
        const nextNode = document.getNextNode(hiddenConditionalAncestor.key)
        if (nextNode) {
          editor.moveToStartOfNode(nextNode)
          editor.moveFocusAfterConditional()
        }
      } else if (editor.nextHiddenConditional()) {
        const node = value.focusText
        const nextNode = node && document.getNextNode(node.key)
        const nodeTwoAhead = nextNode && document.getNextNode(nextNode.key)
        if (nodeTwoAhead) {
          editor.moveToStartOfNode(nodeTwoAhead)
          editor.moveFocusAfterConditional()
        }
      }

      return editor
    },
    moveFocusBeforeConditional(editor) {
      const { value } = editor
      const { document } = value

      const hiddenConditionalAncestor = editor.hiddenConditionalAncestor()
      if (hiddenConditionalAncestor) {
        const previousNode = document.getPreviousNode(hiddenConditionalAncestor.key)
        if (previousNode) {
          editor.moveToEndOfNode(previousNode)
          editor.moveFocusBeforeConditional()
        }
      } else if (editor.previousHiddenConditional()) {
        const node = value.focusText
        const previousNode = node && document.getPreviousNode(node.key)
        const nodeTwoBack = previousNode && document.getPreviousNode(previousNode.key)
        if (nodeTwoBack) {
          editor.moveToEndOfNode(nodeTwoBack)
          editor.moveFocusBeforeConditional()
        }
      }

      return editor
    },
    removeAllConditionals(editor) {
      editor.value.document.filterDescendants(isConditional).forEach(conditionalNode => {
        if (!conditionalNode) {
          return
        }

        try {
          editor.removeNodeByKey(conditionalNode.key)
        } catch (error) {
          console.warn(
            'Error removing conditional, possibly because it was part of an already-deleted node',
            conditionalNode.toJS(),
            error
          )
        }
      })

      return editor
    },
  },
  queries: {
    hiddenConditionalAncestor(editor) {
      const {
        value: { anchorText, document },
      } = editor

      const ancestors = document.getAncestors(anchorText?.key) || []
      const hiddenAncestor = ancestors.find(isHiddenConditional)

      return hiddenAncestor
    },
    previousHiddenConditional(editor) {
      const { value } = editor
      const { selection, document } = value
      const isAtTheStartOfText = selection.focus.isAtStartOfNode(value.focusText)

      const getPreviousSiblingWithoutEmptyText = (node: Node): Node | null => {
        const previousSibling = document.getPreviousNode(node.key)
        if (previousSibling && previousSibling.text === '') {
          return getPreviousSiblingWithoutEmptyText(previousSibling)
        }
        return previousSibling
      }

      const previousNode = value.focusText && getPreviousSiblingWithoutEmptyText(value.focusText)
      const previousNodeIsConditional = previousNode && isHiddenConditional(previousNode)

      return isAtTheStartOfText && previousNodeIsConditional
    },
    nextHiddenConditional(editor) {
      const { value } = editor
      const { selection, document } = value
      const isAtTheEndOfText = selection.focus.isAtEndOfNode(value.focusText)

      const getNextSiblingWithoutEmptyText = (node: Node): Node | null => {
        const nextSibling = document.getNextNode(node.key)
        if (nextSibling && nextSibling.text === '') {
          return getNextSiblingWithoutEmptyText(nextSibling)
        }
        return nextSibling
      }

      const nextNode = value.focusText && getNextSiblingWithoutEmptyText(value.focusText)
      const nextNodeIsConditional = nextNode && isHiddenConditional(nextNode)

      return isAtTheEndOfText && nextNodeIsConditional
    },
  },
} as PluginOrPlugins

declare module 'slate-react' {
  interface Editor {
    moveFocusAfterConditional(): Editor
    moveFocusBeforeConditional(): Editor
    removeAllConditionals(): Editor
    hiddenConditionalAncestor(): Node
    previousHiddenConditional(): boolean
    nextHiddenConditional(): boolean
  }
}
