import createDecorator from 'final-form-calculate'

import { StorageVCLossTypes } from './constants'

export const storageVCLossWatcher = createDecorator({
  field: ['storageVCLossType'],
  updates: (value, fieldName, allValues) => {
    const { storageVCLossType } = allValues
    const vcLossPercentage =
      storageVCLossType === StorageVCLossTypes.RESIDENTIAL
        ? allValues.residentialVCLossPercentage
        : allValues.storageVCLossPercentage
    return { vcLossPercentage }
  },
})
