import { uniqBy, head, get } from 'lodash'
import arrayToSentence from 'array-to-sentence'

import { PRE_EXISTING_YEAR, PROPERTY_USES_OPTIONS, USES_OPTIONS, ZONE_NAMES } from 'shared/constants/property/zoning'

import { getLocationIdentifier } from 'shared/utils/report/propertyInformation'
import { getZonesAllowableUses } from 'shared/utils/report/propertyInformation/zoning'

import { getLastWordSeparator } from 'shared/utils/sentenceBuilder'
import { TEMPLATE_TYPES } from 'shared/constants/report'

import { GEOGRAPHY_OPTIONS } from 'shared/constants/property/geography'

const formatUses = (uses: string[], usesOptions: { value: string; label: string }[]) => {
  return uses
    .map(use => {
      const useOption = usesOptions.find(option => option.value === use)
      return useOption ? useOption.label.toLowerCase() : use.toLowerCase()
    })
    .filter(Boolean)
}

type FormValues = {
  isFreddieMacReport: boolean
  isAsComplete: boolean
  propertyUses: string[]
  zoneNames: string[]
  isConformingWithAllowableUses: boolean
  commercialOverlays: string[]
  yearBuilt: number
  fullAddress: string
  permittedUses: string[]
  currentUses: string[]
  isNYCReport: boolean
}

const mapData = ({
  isFreddieMacReport,
  isAsComplete,
  propertyUses,
  zoneNames = [],
  isConformingWithAllowableUses,
  commercialOverlays,
  yearBuilt,
  fullAddress,
  permittedUses,
  currentUses,
  isNYCReport,
}: FormValues) => {
  const selectedOneZone = zoneNames.length === 1
  const selectedMultipleZones = zoneNames.length > 1

  const zonesSeparator = getLastWordSeparator(zoneNames)
  const zonesSentence = arrayToSentence(zoneNames, zonesSeparator)

  const uniqZones = uniqBy(zoneNames, head)
  const zonesAllowableUses = getZonesAllowableUses(uniqZones)
  const zonesAllowableUsesSeparator = getLastWordSeparator(zonesAllowableUses)
  const zoneAllowableUsesSentence = arrayToSentence(zonesAllowableUses, zonesAllowableUsesSeparator)
  const areMultipleAllowableUses = zonesAllowableUses.length > 1

  const commercialOverlaysSeparator = getLastWordSeparator(commercialOverlays)
  const commercialOverlaysList = arrayToSentence(commercialOverlays, commercialOverlaysSeparator)
  const commercialOverlaySentence = commercialOverlays.length
    ? `, as well as commercial uses due to the ${commercialOverlaysList} commercial ${
        commercialOverlays.length > 1 ? 'overlays' : 'overlay'
      }. `
    : '. '

  const isPreExisting = !isAsComplete && !isConformingWithAllowableUses && yearBuilt < PRE_EXISTING_YEAR

  const propertyUsesList = formatUses(propertyUses, PROPERTY_USES_OPTIONS)
  const propertyUsesSentence = arrayToSentence(propertyUsesList, getLastWordSeparator(propertyUsesList))

  const permittedUsesList = formatUses(permittedUses, USES_OPTIONS)

  const permittedUsesSentence =
    arrayToSentence(permittedUsesList, getLastWordSeparator(permittedUsesList)) || '[PERMITTED USE]'

  const currentUsesList = formatUses(currentUses, USES_OPTIONS)
  const currentUsesSentence = arrayToSentence(currentUsesList, getLastWordSeparator(currentUsesList)) || '[CURRENT USE]'

  return {
    selectedOneZone,
    selectedMultipleZones,
    zonesSentence,
    areMultipleAllowableUses,
    commercialOverlaySentence,
    propertyUsesSentence,
    zoneAllowableUsesSentence,
    isPreExisting,
    isFreddieMacReport,
    isAsComplete,
    isConformingWithAllowableUses,
    fullAddress,
    permittedUsesSentence,
    currentUsesSentence,
    isNYCReport,
  }
}

export const mapDTO = (formValues: FormValues) => {
  return mapData(formValues)
}

export const mapDataModel = (report: any) => {
  const zoning = get(report, 'zoning', {})
  const zones: any = zoning.zones || []
  const zoneNames = zones.map((zoneItem: any, index: number) => zoneItem.zone || `[${ZONE_NAMES[index]}]`)
  const commercialOverlays = zones.flatMap((zone: any) => zone.commercialOverlay || '')
  const permittedUses = zoning.permittedUses || []
  const propertyUses = zoning.propertyUses || []
  const currentUses = zoning.currentUses || []
  const isConformingWithAllowableUses = zoning.isConformingWithAllowableUses
  const fullAddress = get(report, 'property_information.address')
  const isAsComplete = get(report, 'valueAsComplete')
  const templateType = report.templateType
  const isFreddieMacReport = templateType === TEMPLATE_TYPES.FREDDIE_MAC
  const yearBuilt = get(report, 'property_information.yearBuilt')

  const state = get(report, 'property_information.state')
  const zip = get(report, 'property_information.zip')
  const locationIdentifier = getLocationIdentifier(state, zip)
  const isNYCReport = locationIdentifier === GEOGRAPHY_OPTIONS.NY

  return mapData({
    isFreddieMacReport,
    isAsComplete,
    propertyUses,
    zoneNames,
    isConformingWithAllowableUses,
    commercialOverlays,
    yearBuilt,
    fullAddress,
    permittedUses,
    currentUses,
    isNYCReport,
  })
}
