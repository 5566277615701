import React from 'react'
import PropTypes from 'prop-types'
import arrayMutators from 'final-form-arrays'
import { eq, get, startCase, lowerCase } from 'lodash'
import { FieldArray } from 'react-final-form-arrays'
import { DragDropContext } from 'react-beautiful-dnd'
import createDecorator from 'final-form-calculate'

import { Grid, Typography, IconButton, Paper, Stack } from '@mui/material'
import Undo from '@mui/icons-material/Undo'

import WarningDialog from 'client-shared/components/WarningDialog'
import NarrativeComponent from 'client-shared/components/NarrativeComponent'
import { GeneratedComment, CheckboxWithLabel } from 'client-shared/components'

import { ASSUMPTIONS_AND_CONDITIONS_PATH } from 'shared/constants/report/keysAndDataPaths'

import {
  DEFAULT_GENERAL_ASSUMPTIONS,
  DISCLAIMER_EXTRAORDINARY_ASSUMPTION,
} from '../../../../../../shared/constants/report/final/assumptionsConditions'
import assumptionsConditions from '../../../../../../shared/utils/textGeneration/final/assumptionsConditions'

import wrapForm from '../../wrapForm'

import Group from './Group'
import { Descriptions } from './constants'

const heading = 'Assumptions/Conditions'
export const DATA_PATH = ASSUMPTIONS_AND_CONDITIONS_PATH

const styles = {
  commentaryWrapper: {
    padding: '16px 16px',
  },
  subtitle: {
    fontWeight: 700,
    paddingBottom: 8,
  },
  paper: {
    width: '100%',
  },
  checkbox: {
    margin: '8px 8px',
  },
}

const extraordinaryAssumptionsDecorator = createDecorator({
  field: 'extraordinaryAssumptions',
  updates: {
    disclaimerExtraordinaryAssumption: (value, allValues) => {
      if (!!value.length && !allValues.disclaimerExtraordinaryAssumption) {
        return { commentary: DISCLAIMER_EXTRAORDINARY_ASSUMPTION }
      } else if (!value.length && !!allValues.disclaimerExtraordinaryAssumption) {
        return null
      }
      return allValues.disclaimerExtraordinaryAssumption
    },
  },
})

class AssumptionsConditions extends React.PureComponent {
  inputs = new Map()

  state = {
    isModalOpen: false,
    isDragging: false,
    openedModal: '',
  }

  setInputRef = (index, ref) => {
    this.inputs.set(index, ref)
  }

  focusInput = index => {
    setTimeout(() => {
      const input = this.inputs.get(index)
      if (input) {
        input.focus()
      }
    })
  }

  onDragStart = () => {
    this.setState({ isDragging: true })
  }

  onDragEnd = ({ source, destination, type }) => {
    const { mutators } = this.props.form

    this.setState({ isDragging: false })

    if (destination) {
      mutators.move(type, source.index, destination.index)
    }
  }

  onOpenModal = name => {
    this.setState({ isModalOpen: true, openedModal: name })
  }

  onCloseModal = () => {
    this.setState({ isModalOpen: false })
  }

  onAddItem = fieldName => {
    const { form } = this.props
    form.mutators.push(fieldName, '')
  }

  onInsertItem = (fieldName, index) => {
    const { mutators } = this.props.form
    mutators.push(fieldName, '')
    this.focusInput(index)
  }

  onRemoveItem = (fieldName, index) => {
    const { form } = this.props

    const values = form.values[fieldName].filter((value, key) => key !== index)
    form.change(fieldName, values)
  }

  onClearGroup = () => {
    const { form } = this.props
    const { openedModal } = this.state
    form.change(openedModal, [])
    this.onCloseModal()
  }

  onRevertGeneralAssumptions = () => {
    const { form } = this.props
    form.change('generalAssumptions', DEFAULT_GENERAL_ASSUMPTIONS)
  }

  getGeographicCompetency = () => {
    const { appraisers } = this.props
    const moreThanOneAppraiser = appraisers.length > 1
    return (
      `As detailed above, in order to acquire geographic competency, the ${
        moreThanOneAppraiser ? 'appraisers have' : 'appraiser has'
      } researched the local market, examined supply and demand factors, economic and development activity, ` +
      `and interviewed local market participants and real estate brokers.`
    )
  }
  getDisclaimerExtraordinaryAssumptionText = () => {
    return DISCLAIMER_EXTRAORDINARY_ASSUMPTION
  }

  getRevertGeneralAssumptionsButton = () => (
    <IconButton onClick={this.onRevertGeneralAssumptions} data-qa="generalAssumptions-revert-btn">
      <Undo fontSize="small" />
    </IconButton>
  )

  render() {
    const { isModalOpen, openedModal, isDragging } = this.state
    const { classes, form } = this.props
    const extraordinaryAssumptions = get(form, 'values.extraordinaryAssumptions')
    const showGeographicCompetency = get(form, 'values.showGeographicCompetency')
    const hasDisclaimerAssumption = !!extraordinaryAssumptions.length

    return (
      <Stack spacing={2}>
        <WarningDialog
          text={`If you clear all ${lowerCase(openedModal)} your data will be lost. This action cannot be undone.`}
          title={`Clear ALL ${startCase(openedModal)}`}
          onCancel={this.onCloseModal}
          onSubmit={this.onClearGroup}
          open={isModalOpen}
        />
        <DragDropContext onDragStart={this.onDragStart} onDragEnd={this.onDragEnd}>
          <Grid item xs={12}>
            <FieldArray name="extraordinaryAssumptions" isEqual={eq}>
              {({ fields }) => (
                <Group
                  listName="extraordinaryAssumptions"
                  placeholder="Write an extraordinary assumption."
                  description={Descriptions.EXTRAORDINARY_ASSUMPTIONS}
                  fields={fields}
                  isDragging={isDragging}
                  onAddItem={() => this.onAddItem('extraordinaryAssumptions')}
                  onOpenClearModal={() => this.onOpenModal('extraordinaryAssumptions')}
                  setInputRef={this.setInputRef}
                  onInsertItem={this.onInsertItem}
                  onRemoveItem={this.onRemoveItem}
                ></Group>
              )}
            </FieldArray>
          </Grid>
          {hasDisclaimerAssumption && (
            <Grid xs={12}>
              <GeneratedComment
                isDynamicContent
                dataPath="disclaimerExtraordinaryAssumption"
                title="Generated Commentary"
                label="Disclaimer Extraordinary Assumption"
                getGeneratedText={this.getDisclaimerExtraordinaryAssumptionText}
                tooltipText="The following text will appear in the Assumptions and Conditions section of your report."
              />
            </Grid>
          )}
          <Grid item xs={12}>
            <FieldArray name="hypotheticalConditions" isEqual={eq}>
              {({ fields }) => (
                <Group
                  listName="hypotheticalConditions"
                  placeholder="Write a hypothetical condition."
                  description={Descriptions.HYPOTHETICAL_CONDITIONS}
                  fields={fields}
                  isDragging={isDragging}
                  onAddItem={() => this.onAddItem('hypotheticalConditions')}
                  onOpenClearModal={() => this.onOpenModal('hypotheticalConditions')}
                  setInputRef={this.setInputRef}
                  onInsertItem={this.onInsertItem}
                  onRemoveItem={this.onRemoveItem}
                />
              )}
            </FieldArray>
          </Grid>

          <Grid item xs={12}>
            <FieldArray name="generalAssumptions" key="generalAssumptions" isEqual={eq}>
              {({ fields }) => (
                <Group
                  listName="generalAssumptions"
                  placeholder="Write a general assumption."
                  description={Descriptions.GENERAL_ASSUMPTIONS}
                  fields={fields}
                  isDragging={isDragging}
                  onAddItem={() => this.onAddItem('generalAssumptions')}
                  onOpenClearModal={() => this.onOpenModal('generalAssumptions')}
                  revertButton={this.getRevertGeneralAssumptionsButton()}
                  setInputRef={this.setInputRef}
                  onInsertItem={this.onInsertItem}
                  onRemoveItem={this.onRemoveItem}
                >
                  <div className={classes.commentaryWrapper}>
                    <Typography variant="subtitle1" className={classes.subtitle}>
                      Data Assumptions
                    </Typography>
                    <NarrativeComponent
                      title="General Assumptions Discussion"
                      generatedText={assumptionsConditions.generate}
                      name="generalAssumptionsCommentary"
                    />
                    <Typography variant="subtitle1" className={classes.subtitle}>
                      Technical Expertise
                    </Typography>
                  </div>
                </Group>
              )}
            </FieldArray>
          </Grid>
        </DragDropContext>
        <Paper className={classes.paper}>
          <Grid container className={classes.checkbox}>
            <CheckboxWithLabel name="showGeographicCompetency" label="Include Geographic Competency in Report" />
          </Grid>
          {showGeographicCompetency && (
            <div className={classes.commentaryWrapper}>
              <GeneratedComment
                isDynamicContent
                title="Generated Commentary"
                label="Geographic Competency"
                dataPath="geographicCompetencyCommentary"
                getGeneratedText={this.getGeographicCompetency}
                tooltipText="This text will export in the Introduction of your report following the Scope of the Appraisal."
              />
            </div>
          )}
        </Paper>
      </Stack>
    )
  }
}

AssumptionsConditions.propTypes = {
  form: PropTypes.object.isRequired,
}

const formOptions = {
  heading,
  styles,
  mutators: {
    ...arrayMutators,
  },
  decorators: [extraordinaryAssumptionsDecorator],
}

const mapStateToProps = state => {
  return {
    appraisers: get(state, 'report.reportData.report.appraisers.selectedAppraisers', []),
  }
}

export default wrapForm(DATA_PATH, formOptions, mapStateToProps)(AssumptionsConditions)
