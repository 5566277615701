import { GeneratedText, gtConditional, gtIfElse, gtLocked, gtTemplate, gtText } from '../../../narrative/GeneratedText'

import { mapDTO, mapDataModel } from './mappings'

const generate = () => {
  const gt = new GeneratedText()

  const conditionGt = gtLocked('condition')
  const stabilizedConditionGt = gtLocked('stabilizedCondition', null, 'Stabilized Condition')
  const deferredMaintenanceItemsGt = gtLocked('items', null, 'Items of Deferred Maintenance')

  gt.t`The subject is ${conditionGt}. During our visit to the building, we noticed`

  gt.addParts(
    gtIfElse(
      'hasDeferredMaintenance',
      gtTemplate` the following items of deferred maintenance: ${deferredMaintenanceItemsGt}.`,
      [gtText(' no significant items of deferred maintenance.')]
    )
  )

  gt.addPart(
    gtConditional('hasPlannedRenovations', [
      gtText(
        ' There are planned renovations, though, and it is an extraordinary assumption of the appraisal that all deferred maintenance will be cured.'
      ),
    ])
  )

  gt.addPart(
    gtConditional('valueAsComplete', gtTemplate` We assume that the property will be ${stabilizedConditionGt}.`)
  )

  return gt.getParts()
}

export default { generate, mapDTO, mapDataModel }
