import { arrayToKeyValuePairs } from '../../../shared/utils/ui/checkboxHelper'
import {
  TYPICAL_BATHROOM,
  TYPICAL_BEDROOM,
  TYPICAL_KITCHEN,
  TYPICAL_LIVING_ROOM,
} from '../../../shared/constants/imageCategories'

import {
  REFRIGERATORS,
  BATHROOM_FLOORS,
  CABINETS,
  COUNTERTOPS,
  KITCHEN_FLOORS,
  LIVING_ROOM_FLOORS,
  BEDROOM_FLOORS,
  SINKS,
  TUBS,
  WALLS,
  TOILETS,
  STOVETOPS,
} from '../../../../../shared/constants/property/typicalResidentialConditions'

import { CONDITION_OPTIONS_ARR } from '..'

const conditionOptions = arrayToKeyValuePairs(CONDITION_OPTIONS_ARR)

const bathroomFloors = arrayToKeyValuePairs(BATHROOM_FLOORS)
const sinks = arrayToKeyValuePairs(SINKS)
const tubs = arrayToKeyValuePairs(TUBS)
const toilets = arrayToKeyValuePairs(TOILETS)

const kitchenFloors = arrayToKeyValuePairs(KITCHEN_FLOORS)
const countertops = arrayToKeyValuePairs(COUNTERTOPS)
const cabinets = arrayToKeyValuePairs(CABINETS)
const stovetops = arrayToKeyValuePairs(STOVETOPS)
const refrigerators = arrayToKeyValuePairs(REFRIGERATORS)

const bedroomFloors = arrayToKeyValuePairs(BEDROOM_FLOORS)
const livingRoomFloors = arrayToKeyValuePairs(LIVING_ROOM_FLOORS)
const walls = arrayToKeyValuePairs(WALLS)

export const BATHROOM_CONDITIONS_DESCRIPTION = {
  name: 'bathroom',
  label: 'Bathroom Condition',
  images: {
    name: 'bathroom.photos',
    category: TYPICAL_BATHROOM,
  },
  conditions: [
    {
      name: 'bathroom.condition',
      label: 'General Bathroom Condition',
      items: conditionOptions,
    },
    {
      name: 'bathroom.floors.value',
      otherName: 'bathroom.floors.other',
      label: 'Bathroom Flooring',
      items: bathroomFloors,
    },
    {
      name: 'bathroom.tub.value',
      otherName: 'bathroom.tub.other',
      label: 'Bathtub',
      items: tubs,
    },
    {
      name: 'bathroom.sink.value',
      otherName: 'bathroom.sink.other',
      label: 'Sink',
      items: sinks,
    },
    {
      name: 'bathroom.toilet.value',
      otherName: 'bathroom.toilet.other',
      label: 'Toilet',
      items: toilets,
    },
  ],
}

export const LIVING_AREA_CONDITIONS_DESCRIPTION = {
  name: 'living',
  label: 'Living Area Room Condition',
  images: {
    name: 'living.photos',
    category: TYPICAL_LIVING_ROOM,
  },
  conditions: [
    {
      name: 'living.condition',
      label: 'General Living Area Condition',
      items: conditionOptions,
    },
    {
      name: 'living.floors.value',
      otherName: 'living.floors.other',
      label: 'Living Room Flooring',
      items: livingRoomFloors,
    },
    {
      name: 'living.walls.value',
      otherName: 'living.walls.other',
      label: 'Living Room Walls',
      items: walls,
    },
  ],
}

export const BEDROOM_CONDITIONS_DESCRIPTION = {
  name: 'bedroom',
  label: 'Bedroom Condition',
  images: {
    name: 'bedroom.photos',
    category: TYPICAL_BEDROOM,
  },
  conditions: [
    {
      name: 'bedroom.condition',
      label: 'General Bedroom Condition',
      items: conditionOptions,
    },
    {
      name: 'bedroom.floors.value',
      otherName: 'bedroom.floors.other',
      label: 'Bedroom Flooring',
      items: bedroomFloors,
    },
    {
      name: 'bedroom.walls.value',
      otherName: 'bedroom.walls.other',
      label: 'Bedroom Walls',
      items: walls,
    },
  ],
}

export const KITCHEN_CONDITIONS_DESCRIPTION = {
  label: 'Kitchen Condition',
  name: 'kitchen',
  images: {
    name: 'kitchen.photos',
    category: TYPICAL_KITCHEN,
  },
  conditions: [
    {
      name: 'kitchen.condition',
      label: 'General Kitchen Condition',
      items: conditionOptions,
    },
    {
      name: 'kitchen.floors.value',
      otherName: 'kitchen.floors.other',
      label: 'Kitchen Flooring',
      items: kitchenFloors,
    },
    {
      name: 'kitchen.counters.value',
      otherName: 'kitchen.counters.other',
      label: 'Counter Tops',
      items: countertops,
    },
    {
      name: 'kitchen.cabinets.value',
      otherName: 'kitchen.cabinets.other',
      label: 'Cabinetry',
      items: cabinets,
    },
    {
      name: 'kitchen.stovetops.value',
      otherName: 'kitchen.stovetops.other',
      label: 'Stovetops',
      items: stovetops,
    },
    {
      name: 'kitchen.refrigerators.value',
      otherName: 'kitchen.refrigerators.other',
      label: 'Refrigerators',
      items: refrigerators,
    },
  ],
}
