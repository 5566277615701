import React from 'react'
import PropTypes from 'prop-types'
import { get } from 'lodash'

import { Grid, Typography, Card, withStyles, Paper } from '@material-ui/core'

import { STATE_NAMES } from 'client-shared/constants/geography'
import { getPartsFromString } from 'shared/utils/narrative/GeneratedText'
import currentTaxLiabilityDiscussion from 'shared/utils/textGeneration/incomeApproach/taxInfo/currentTaxLiability'

import {
  has6To20PercentTaxLimitation,
  has8To30PercentTaxLimitation,
} from 'shared/report-calculations/income-approach/tax/helpers'

import { GeneratedComment } from '../../../../shared/components'
import NarrativeComponent from '../../../../shared/components/NarrativeComponent'

import TaxLiabilityTable from './TaxLiabilityTable'
import TaxableAssessedValueTable from './TaxableAssessedValueTable'
import TaxRatePicker from './TaxRatePicker'
import AddTaxRateButton from './AddTaxRateButton'
import AddSpecialAssessmentButton from './AddSpecialAssessmentButton'
import { TOOLTIP_TEXT } from './constants'
import styles from './styles'
import TaxLiabilityConfiguration from './TaxTableReuse/TaxLiabilityConfiguration'

const getTaxLimitationDiscussion = values => {
  const has620 = has6To20PercentTaxLimitation(get(values.taxClass, 'lookupValue'))
  const has830 = has8To30PercentTaxLimitation(get(values.taxClass, 'lookupValue'))
  if (has830) {
    return "We note that the subject benefits from being an 8-30% limitation building, meaning that the subject's tax assessment cannot increase more than 8% in a given year or 30% total over a five-year period. We typically project tax increases at 3% annually and the current taxes are such that we do not believe that the 8% maximum annual increases will have a significant effect on value. Therefore, we believe our capitalization rate would remain similar for this property when compared to comparables without the 8-30% tax limitation."
  } else if (has620) {
    return "We note that the subject benefits from being a 6-20% limitation building, meaning that the subject's tax assessment cannot increase more than 6% in a given year or 20% total over a five-year period. We typically project tax increases at 3% annually and the current taxes are such we do not believe that the 6% maximum annual increases will have a significant effect on value. Therefore, we believe our capitalization rate would remain similar for this property when compared to comparables without the 6-20% tax limitation."
  }
}

const generateTaxCalculationDiscussion = () => {
  const gtParts = getPartsFromString('')

  return gtParts
}

class CurrentLiability extends React.PureComponent {
  static propTypes = {
    form: PropTypes.object.isRequired,
  }

  render() {
    const { classes, form, isNYCReport, basisForSFAnalysis } = this.props

    const {
      taxClass,
      subjectInfo: { isOther, state, borough },
    } = form.values

    let has620 = false
    let has830 = false
    if (has6To20PercentTaxLimitation(get(taxClass, 'lookupValue'))) {
      has620 = true
    } else if (has8To30PercentTaxLimitation(get(taxClass, 'lookupValue'))) {
      has830 = true
    }

    return (
      <Grid container>
        <Grid item xs={12}>
          <TaxableAssessedValueTable isNYCReport={isNYCReport} isOther={isOther} has830={has830} />
        </Grid>
        <Grid item xs={12}>
          <div style={{ marginTop: '8px' }} />
          <Grid container>
            <Grid item xs={8} />
            <Grid item xs={4}>
              <TaxLiabilityConfiguration form={form}></TaxLiabilityConfiguration>
            </Grid>
          </Grid>
          <div style={{ marginBottom: '8px' }} />
          <Paper className={classes.paper}>
            <Grid container justify="space-between">
              <Grid item xs={12}>
                <div className={classes.tableTitleContainer}>
                  <Typography className={classes.title} color="primary" variant="subtitle1">
                    Tax Liability
                  </Typography>
                </div>
              </Grid>
              <Grid item xs={6}>
                <TaxRatePicker classFieldName="taxClass" rateFieldName="taxRate" form={form} />
              </Grid>
              <Grid item>
                <AddTaxRateButton form={form} displayUnconditionally />
                <AddSpecialAssessmentButton form={form} displayUnconditionally />
              </Grid>
              <Grid item xs={12}>
                <Card elevation={0} className={classes.tableContainer}>
                  <TaxLiabilityTable formValues={form.values} basisForSFAnalysis={basisForSFAnalysis} />
                </Card>
              </Grid>
            </Grid>
          </Paper>
        </Grid>
        {state === STATE_NAMES.NY && !!borough && (
          <Grid item xs={12}>
            <Paper className={classes.paper}>
              <NarrativeComponent
                title="Tax Calculation Discussion"
                generatedText={generateTaxCalculationDiscussion}
                name="taxCalculationDiscussion"
                tooltipText="The following text will appear below the Tax Liability table in your export"
              />
            </Paper>
          </Grid>
        )}
        <Grid item xs={12}>
          <Paper className={classes.paper}>
            <Grid item xl={12}>
              <Paper className={classes.paper}>
                <NarrativeComponent
                  title="Current Tax Liability Discussion"
                  generatedText={currentTaxLiabilityDiscussion.generate}
                  data={currentTaxLiabilityDiscussion.mapDTO(form.values)}
                  name="currentTaxLiabilityDiscussion"
                  tooltipText={TOOLTIP_TEXT}
                />
              </Paper>
            </Grid>
            {(has620 || has830) && (
              <Grid item xl={12} className={classes.discussionContainer}>
                <GeneratedComment
                  isDynamicContent
                  className={classes.title}
                  dataPath="taxLimitationDiscussion"
                  title="Generated Commentary"
                  label="Tax Limitation Discussion"
                  getGeneratedText={getTaxLimitationDiscussion}
                  tooltipText={TOOLTIP_TEXT}
                />
              </Grid>
            )}
          </Paper>
        </Grid>
      </Grid>
    )
  }
}

export default withStyles(styles)(CurrentLiability)
