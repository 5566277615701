import { get } from 'lodash'

export const mapSubjectProperty = state => {
  const corner = get(state, 'report.reportData.propertyInformation.propertyMaps.corner')
  const valueAsComplete = get(state, 'report.reportSettings.valueAsComplete')

  const condition = get(state, 'report.reportData.propertyInformation.generalPropertyDescription.generalCondition')
  const asStabilizedCondition = get(
    state,
    'report.reportData.propertyInformation.generalPropertyDescription.generalAsStabilizedCondition'
  )
  const propertyType = get(state, 'report.reportData.propertyType')
  const { city, yearBuilt, isElevator, residentialUnitCount, grossBuildingArea, squareFootage, censusTract } = get(
    state,
    'report.reportData.propertyInformation.propertySummary'
  )
  const neighborhood = get(state, 'report.reportData.propertyInformation.propertyMarket.marketNeighborhood')
  const address = get(state, 'report.reportData.address')
  const county = get(state, 'report.reportData.county')
  const commercialUnits = get(state, 'report.reportData.propertyInformation.commercialUnits.units', []).length
  const zoningAirRights = get(state, 'report.reportData.final.capRateReconciliation.airRights')
  const concludedCapRate = get(state, 'report.reportData.incomeApproach.capRateConclusion.concludedCapRate')
  const commercialArea = get(state, 'report.reportData.propertyInformation.commercialUnits.commercialSquareFootage')

  const addressState = get(state, 'report.reportData.propertyInformation.propertySummary.state')
  const submarket = get(state, 'report.reportData.propertyInformation.propertyMarket.submarket')
  const market = get(state, 'report.reportData.propertyInformation.propertyMarket.macroMarket')

  const subjectCondition = valueAsComplete
    ? asStabilizedCondition?.other || asStabilizedCondition?.value
    : condition?.other || condition?.value

  const dateOfValue = get(state, 'report.reportData.report.reportInformation.dateOfValuation')
  return {
    address,
    corner,
    city,
    neighborhood,
    yearBuilt,
    county,
    censusTract,
    gba: grossBuildingArea,
    squareFootage,
    capRate: concludedCapRate,
    airRights: zoningAirRights,
    buildingType: propertyType,
    elevatored: isElevator,
    commercialArea,
    commercialUnits,
    residentialUnits: residentialUnitCount,
    condition: subjectCondition,
    dateOfValue,
    submarket,
    market,
    state: addressState,
  }
}
