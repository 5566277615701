import React from 'react'
import { head } from 'lodash'
import PropTypes from 'prop-types'
import { compose, pure, mapProps } from 'recompose'
import { Avatar } from '@mui/material'
import { withStyles } from '@mui/styles'

import { getResizedImageUrl } from '../../../../shared/utils/cloudinary'

import { AVATAR_IMAGE_WIDTH } from './constants'

const styles = {
  root: {
    display: 'flex',
    flexWrap: 'wrap',
    maxWidth: 720,
  },
  appraiser: {
    width: 180,
  },
  fullName: {
    fontSize: 16,
    lineHeight: 1.75,
    letterSpacing: 0.5,
    color: 'rgba(0, 0, 0, 0.87)',
    marginTop: 8,
  },
  avatar: {
    cursor: 'pointer',
    fontSize: 20,
    lineHeight: 1.33,
    letterSpacing: 0.4,
    margin: '0 2px',
    width: 80,
    height: 80,
    backgroundColor: '#979797',
    boxShadow: '0 0 1px 0 rgba(0, 0, 0, 0.5)',
  },
}
const AppraisersList = ({ classes, appraisers }) => (
  <div className={classes.root}>
    {appraisers.map((appraiser, index) => (
      <div className={classes.appraiser} key={index}>
        <Avatar src={appraiser.avatarUrl} alt={appraiser.fullName} className={classes.avatar}>
          {appraiser.initials}
        </Avatar>
        <div className={classes.fullName}>
          {appraiser.fullName}
          {appraiser.suffix ? `, ${appraiser.suffix}` : ''}
        </div>
      </div>
    ))}
  </div>
)

AppraisersList.propTypes = {
  appraisers: PropTypes.arrayOf(
    PropTypes.shape({
      fullName: PropTypes.string.isRequired,
      initials: PropTypes.string.isRequired,
      avatarUrl: PropTypes.string.isRequired,
      suffix: PropTypes.string,
    })
  ),
}

export default compose(
  pure,
  mapProps(({ appraisers = [] }) => {
    const mappedAppraisers = appraisers.map(({ avatarUrl, fullName, suffix }) => ({
      fullName,
      suffix,
      avatarUrl: getResizedImageUrl({ url: avatarUrl, width: AVATAR_IMAGE_WIDTH }),
      initials: fullName.split(' ').map(head).join(''),
    }))

    return {
      appraisers: mappedAppraisers,
    }
  }),
  withStyles(styles)
)(AppraisersList)
