export const STICKY_FORM_PANEL_WRAPPER_ID = 'STICKY_FORM_PANEL_WRAPPER_ID'

export const REPORT_LANDING_PAGE = '/report/:id'

export const DEFAULT_REPORT_REDIRECT = '/report/:id/review-and-export'

export const BACK_SHORTCUTS = ['ctrl+shift+,', 'command+shift+,']
export const FORWARD_SHORTCUTS = ['ctrl+shift+.', 'command+shift+.']
export const SAVE_SHORTCUTS = ['ctrl+s', 'command+s']
export const SAVE_CONTINUE_SHORTCUTS = ['ctrl+shift+s', 'command+shift+s']
