import React from 'react'
import PropTypes from 'prop-types'

import { List, ListItem, ListItemText } from '@mui/material'
import { withStyles } from '@mui/styles'
import classnames from 'classnames'
import { get } from 'lodash'
import { connect } from 'react-redux'
import { NavLink } from 'react-router-dom'
import { compose } from 'recompose'

const styles = ({ palette }) => ({
  root: {
    padding: '16px 8px',
  },
  title: {
    fontSize: 20,
  },
  listItem: {
    fontSize: 14,
  },
  list: {
    fontFamily: 'Nunito Sans',
    color: palette.text.main,
    '&:hover': {
      backgroundColor: 'rgba(65, 96, 211, 0.08)',
      borderRadius: 4,
    },
  },
  activeItem: {
    color: palette.primary.main,
  },
  item: {
    '& div': {
      color: '#617083',
    },
  },
})

const SidebarMenu = ({ title = '', forms, itemKey, itemId, location, classes }) => (
  <List className={classes.root}>
    {title && (
      <ListItem key={title} className={classnames(classes.list, classes.title)}>
        <ListItemText disableTypography primary={title} />
      </ListItem>
    )}
    {forms.map(form => {
      const isActive = location.includes(form.path)
      return (
        <ListItem
          key={form.path}
          component={NavLink}
          to={`/${itemKey}/${itemId}/${form.path}`}
          className={classnames(classes.listItem, classes.list, {
            [classes.activeItem]: isActive,
            [classes.item]: !isActive,
          })}
        >
          <ListItemText disableTypography primary={form.title} />
        </ListItem>
      )
    })}
  </List>
)

SidebarMenu.propTypes = {
  title: PropTypes.string,
  classes: PropTypes.object.isRequired,
  forms: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string.isRequired,
      formComponent: PropTypes.func.isRequired,
      path: PropTypes.string.isRequired,
    })
  ).isRequired,
  itemKey: PropTypes.string.isRequired,
  itemId: PropTypes.string.isRequired,
}

export default compose(
  connect(state => ({
    location: get(state, 'router.location.pathname'),
  })),
  withStyles(styles, { name: 'SidebarMenu' })
)(SidebarMenu)
