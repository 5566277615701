import { put, takeLatest } from 'redux-saga/effects'

import * as Api from '../../../../api'

import {
  importZoning as importZoningAction,
  reportZoningFetchRequest,
  reportZoningFetchReceive,
  reportZoningFetchFail,
} from './actions'

const mapImportedZoning = ({ zones = [], permittedUses = [], regulations = [] }) => ({
  zones: zones.map(({ zone, commercialOverlay }) => ({
    commercialOverlay,
    zone,
    percentage: '',
  })),
  permittedUses,
  regulations: regulations.map(({ name, required }) => ({
    name,
    required,
  })),
})

function* importZoning({ payload }) {
  try {
    const { reportId } = payload
    yield put(reportZoningFetchRequest())
    const zoning = yield Api.fetchZoningByReportId(reportId)
    const importedZoning = mapImportedZoning(zoning)

    yield put(reportZoningFetchReceive({ importedZoning }))
  } catch (err) {
    yield put(reportZoningFetchFail(err))
  }
}

export default [takeLatest(importZoningAction, importZoning)]
