import React, { FC, useMemo } from 'react'

import { divide, get, noop } from 'lodash'
import { ColumnDataTypeEnum, RowBasedTable } from '@bowery-valuation/ui-components'

import { getCustomColumnConfig, RowBasedTableColumn } from 'client-shared/utils/rowBasedTable'
import { formatCurrencyFloat } from 'client-shared/utils/numberFormatters'
import { CELL_EDITOR_SELECTORS } from 'report/forms/income/TaxInformation/constants'
import { fromCurrency } from 'client-shared/utils/numberOperations'
import { FormBatch, FormChange } from 'client-shared/utils/form'
import { ReconciliationType } from 'shared/types/costApproachTypes'

type ReconciliationTableOwnProps = {
  change: FormChange
  batch: FormBatch
  grossBuildingArea: number
  subjectBudgetTotal: number
  mvsTotalReplacementCostNew: number
  reconciliation: ReconciliationType
}

const ReconciliationTable: FC<ReconciliationTableOwnProps> = ({
  change,
  batch,
  grossBuildingArea,
  subjectBudgetTotal,
  mvsTotalReplacementCostNew,
  reconciliation,
}) => {
  const reconciledValue = get(reconciliation, 'reconciledValue', 0)
  const costComparables = get(reconciliation, 'costComparables', { min: 0, max: 0, avg: 0 })
  const getColumns = (): RowBasedTableColumn[] => {
    return [
      {
        name: 'costEstimate',
        label: 'Cost Estimate',
        type: ColumnDataTypeEnum.text,
        permanent: true,
        align: 'left',
        editable: false,
        suppressMovable: true,
      },
      {
        name: 'total',
        label: 'Total',
        type: ColumnDataTypeEnum.text,
        permanent: true,
        align: 'right',
        editable: params => {
          return params.data.id === 'reconciledValue'
        },
        cellEditorSelector: () => {
          return CELL_EDITOR_SELECTORS.moneyNoDecimals
        },
        suppressMovable: true,
      },
      {
        name: 'min',
        label: '',
        type: ColumnDataTypeEnum.text,
        permanent: true,
        align: 'right',
        editable: params => {
          return params.data.id === 'costComparables'
        },
        cellEditorSelector: () => {
          return CELL_EDITOR_SELECTORS.moneyTwoDecimals
        },
        suppressMovable: true,
      },
      {
        name: 'psf',
        label: 'PSF',
        type: ColumnDataTypeEnum.text,
        permanent: true,
        align: 'right',
        editable: params => {
          return params.data.id === 'costComparables'
        },
        cellEditorSelector: () => {
          return CELL_EDITOR_SELECTORS.moneyTwoDecimals
        },
        suppressMovable: true,
      },
      {
        name: 'max',
        label: '',
        type: ColumnDataTypeEnum.text,
        permanent: true,
        align: 'right',
        editable: params => {
          return params.data.id === 'costComparables'
        },
        cellEditorSelector: () => {
          return CELL_EDITOR_SELECTORS.moneyTwoDecimals
        },
        suppressMovable: true,
      },
    ]
  }

  const handleRowUpdate = (row: any) => {
    if (row.id === 'reconciledValue') {
      change('reconciliation.reconciledValue', fromCurrency(row.total))
    }
    if (row.id === 'costComparables') {
      batch(() => {
        change('reconciliation.costComparables.min', fromCurrency(row.min))
        change('reconciliation.costComparables.avg', fromCurrency(row.psf))
        change('reconciliation.costComparables.max', fromCurrency(row.max))
      })
    }
  }
  const handleManyRowsUpdate = (rows: any) => {
    batch(() => {
      rows.forEach((row: any) => {
        handleRowUpdate(row)
      })
    })
  }

  const rows = [
    {
      readOnly: true,
      suppressMovable: true,
      permanent: true,
      type: ColumnDataTypeEnum.text,
      id: 'subjectBudgetTotal',
      costEstimate: 'Subject Budget Total',
      total: formatCurrencyFloat(subjectBudgetTotal),
      psf: formatCurrencyFloat(divide(subjectBudgetTotal, grossBuildingArea)),
      min: null,
      max: null,
      rowDef: { hideAction: true },
    },
    {
      readOnly: true,
      suppressMovable: true,
      permanent: true,
      type: ColumnDataTypeEnum.text,
      id: 'mvsCalculatedTotal',
      costEstimate: 'MVS Total Replacement Costs',
      total: formatCurrencyFloat(mvsTotalReplacementCostNew),
      psf: formatCurrencyFloat(divide(mvsTotalReplacementCostNew, grossBuildingArea)),
      min: null,
      max: null,
      rowDef: { hideAction: true },
    },
    {
      readOnly: false,
      suppressMovable: true,
      permanent: true,
      type: ColumnDataTypeEnum.text,
      id: 'costComparables',
      costEstimate: 'Cost Comparables (Min/Avg/Max)',
      total: null,
      psf: formatCurrencyFloat(costComparables?.avg ?? 0),
      min: formatCurrencyFloat(costComparables?.min ?? 0),
      max: formatCurrencyFloat(costComparables?.max ?? 0),
      rowDef: { hideAction: true },
    },
    {
      readOnly: false,
      suppressMovable: true,
      permanent: true,
      type: ColumnDataTypeEnum.text,
      id: 'reconciledValue',
      costEstimate: 'Reconciled Replacement Cost New',
      total: formatCurrencyFloat(reconciledValue),
      psf: formatCurrencyFloat(divide(reconciledValue, grossBuildingArea)),
      min: null,
      max: null,
      rowDef: { hideAction: true },
    },
  ]
  const columns = useMemo(() => getColumns(), [])

  return (
    <RowBasedTable
      id="reconciliation-table"
      columns={columns}
      rows={rows}
      onRowUpdate={handleRowUpdate}
      onManyRowsUpdate={handleManyRowsUpdate}
      getCustomColumnConfig={getCustomColumnConfig}
      actionCellHidden
      hideIndexColumn
      onColumnDragEnd={noop}
      onRowsDragEnd={noop}
      onColumnDelete={noop}
      onColumnUpdate={noop}
      onRowDelete={noop}
    />
  )
}

export default ReconciliationTable
