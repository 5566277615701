import React from 'react'
import PropTypes from 'prop-types'

import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import toLower from 'lodash/toLower'
import startCase from 'lodash/startCase'

const palette = {
  approved: {
    color: '#1a237e',
    bgcolor: '#e8eaf6',
  },
  blocks: {
    color: '#842b0b',
    bgcolor: '#ffccb2',
  },
  draft: {
    color: '#1b5e20',
    bgcolor: '#e8f5e9',
  },
  review: {
    color: '#006064',
    bgcolor: '#e0f7fa',
  },
  submitted: {
    color: '#4a148c',
    bgcolor: '#f3e5f5',
  },
  automated: {
    color: '#ffffff',
    bgcolor: '#673ab7',
  },
}

const ReportStatus = ({ status, variant }) => {
  const { bgcolor, color } = palette[toLower(status)]
  return (
    <Box sx={{ bgcolor, borderRadius: 1, color, px: 2, py: 0.5 }}>
      <Typography variant={variant}>{startCase(status)}</Typography>
    </Box>
  )
}

ReportStatus.propTypes = {
  status: PropTypes.string.isRequired,
}

ReportStatus.defaultProps = {
  variant: 'caption',
}

ReportStatus.displayName = 'ReportStatus'

export default React.memo(ReportStatus)
