import React from 'react'
import PropTypes from 'prop-types'
import { SparklinesSpots } from 'react-sparklines'

class SparklinesPoint extends React.Component {
  static propTypes = {
    pointIndex: PropTypes.number.isRequired,
    style: PropTypes.object.isRequired,
    size: PropTypes.number,
  }

  static defaultProps = {
    size: 1,
  }

  render() {
    const { points, pointIndex, size, height, style, ...props } = this.props
    if (pointIndex === -1) {
      return null
    }
    // eslint-disable-next-line id-length
    const point = points.slice(pointIndex, pointIndex + 1).map(point => ({ ...point, y: height - point.y }))
    return <SparklinesSpots size={size} {...props} points={point} style={style} />
  }
}

export default SparklinesPoint
