import React from 'react'
import PropTypes from 'prop-types'

import classnames from 'classnames'
import { Link } from 'react-router-dom'
import { withStyles } from '@mui/styles'

import lightThemeLogo from '../../images/bowery-logo.svg'
import darkThemeLogo from '../../images/bowery-logo-white.png'

const styles = () => ({
  logo: {
    paddingTop: 3,
    paddingLeft: 10,
  },
})

class Logo extends React.PureComponent {
  static propTypes = {
    darkTheme: PropTypes.bool,
  }

  static defaultProps = {
    darkTheme: false,
  }

  getThemeLogo = isDarkTheme => {
    return isDarkTheme ? darkThemeLogo : lightThemeLogo
  }

  render() {
    const { classes } = this.props
    return (
      <Link to="/reports">
        <img
          src={this.getThemeLogo(this.props.darkTheme)}
          className={classnames('main-logo', classes.logo)}
          alt="Bowery"
        />
      </Link>
    )
  }
}

export default withStyles(styles)(Logo)
