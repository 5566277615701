import React from 'react'

import { Stack, Typography } from '@mui/material'

import { buildSlateWriteup } from 'shared/utils/textGeneration/writeupBuilder'

import { PageHeader, TitleSectionHeader } from '../components/Headers'
import SectionHeader from '../components/SectionHeader'

import IntroductionList from './IntroductionList'

import {
  CLIENT_IDENTIFICATION_HEADER,
  DATA_SOURCES_HEADER,
  DEFINITION_OF_MARKET_VALUE_HEADER,
  DEFINITION_OF_MARKET_VALUE_LIST,
  EXPOSURE_TIME_HEADER,
  GENERAL_ASSUMPTION_HEADER,
  GENERAL_ASSUMPTIONS_INTRODUCTION,
  INTENDED_USES_HEADER,
  LIST_TYPE,
  MARKETING_TIME_HEADER,
  PAGE_HEADER,
  PAGE_SECTION_HEADER,
  PROPERTY_HISTORY_HEADER,
  PROPERTY_RIGHTS_APPRAISED_HEADER,
  PURPOSE_AND_DATE_OF_VALUE_HEADER,
  SCOPE_OF_THE_APPRAISAL_HEADER,
  SCOPE_OF_THE_APPRAISAL_INTRODUCTION,
} from './constants'

const PreviewText = ({ children, content }) => (
  <Typography sx={{ wordBreak: 'break-word' }}>{content || children}</Typography>
)

const Section = ({ children, content, title, trunk }) => (
  <Stack>
    <SectionHeader title={title} />
    {children}
    <PreviewText content={content} />
    {trunk}
  </Stack>
)

const Preview = ({
  purposeAndDateOfValueDiscussion,
  intendedUse,
  intendedUserDiscussion,
  clientIdentificationDiscussion,
  salesHistoryDiscussion,
  contractHistoryDiscussion,
  definitionOfMarketValue,
  exposureTimeDescription,
  includeMarketingTime,
  marketingTimeDescription,
  appraisalScopeItems,
  generalAssumptions,
  generalAssumptionsCommentary,
  sourceInfoDiscussion,
  propertyRights,
}) => {
  return (
    <Stack spacing={2}>
      <PageHeader title={PAGE_HEADER} />
      <TitleSectionHeader title={PAGE_SECTION_HEADER} />

      <Section content={buildSlateWriteup(purposeAndDateOfValueDiscussion)} title={PURPOSE_AND_DATE_OF_VALUE_HEADER} />

      <Section content={buildSlateWriteup(clientIdentificationDiscussion)} title={CLIENT_IDENTIFICATION_HEADER} />

      <Section content={buildSlateWriteup(intendedUserDiscussion)} title={INTENDED_USES_HEADER}>
        {intendedUse && <Typography>{intendedUse}</Typography>}
      </Section>

      <Section content={buildSlateWriteup(propertyRights)} title={PROPERTY_RIGHTS_APPRAISED_HEADER} />

      <Stack>
        <SectionHeader title={PROPERTY_HISTORY_HEADER} />
        <PreviewText content={buildSlateWriteup(salesHistoryDiscussion)} />
        <PreviewText content={buildSlateWriteup(contractHistoryDiscussion)} />
      </Stack>

      <Section content={buildSlateWriteup(exposureTimeDescription)} title={EXPOSURE_TIME_HEADER} />

      {includeMarketingTime && (
        <Section content={buildSlateWriteup(marketingTimeDescription)} title={MARKETING_TIME_HEADER} />
      )}

      <Section content={buildSlateWriteup(generalAssumptionsCommentary)} title={GENERAL_ASSUMPTION_HEADER} />

      <IntroductionList intoductionText={GENERAL_ASSUMPTIONS_INTRODUCTION} list={generalAssumptions} />

      <Section
        content={buildSlateWriteup(definitionOfMarketValue)}
        title={DEFINITION_OF_MARKET_VALUE_HEADER}
        trunk={<IntroductionList list={DEFINITION_OF_MARKET_VALUE_LIST} type={LIST_TYPE.OL} />}
      />

      <IntroductionList
        headerText={SCOPE_OF_THE_APPRAISAL_HEADER}
        intoductionText={SCOPE_OF_THE_APPRAISAL_INTRODUCTION}
        list={appraisalScopeItems}
      />

      <Section content={buildSlateWriteup(sourceInfoDiscussion)} title={DATA_SOURCES_HEADER} />
    </Stack>
  )
}

Preview.defaultProps = {
  generalAssumptions: [],
}

export default Preview
