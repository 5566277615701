import React, { useCallback } from 'react'
import PropTypes from 'prop-types'

import { List } from '@mui/material'
import { withStyles } from '@mui/styles'
import { find, get, isArray, isEmpty } from 'lodash'
import { connect } from 'react-redux'
import { matchPath } from 'react-router'
import { compose } from 'recompose'

import { HEADER_HEIGHT } from 'client-shared/layout/constants'

import { setNavigationScrolling } from 'client-shared/redux/actions/scroll'

import { LayoutZIndexes } from '../../stylesConstants'

import { MENU_TITLE_IMPROVED_SALES } from '../../../constants/forms/sections/improvedSales'

import { REPORT_STRUCTURE_TYPES } from '../../../../../../libs/bowery-libs/constants/report'

import NavigationSideBarCollapsibleMenuItem from './NavigationSideBarCollapsibleMenuItem'
import NavigationSideBarMenuItem from './NavigationSideBarMenuItem'

const styles = theme => ({
  root: {
    padding: `${theme.spacing(2)} ${theme.spacing(1)} 0 ${theme.spacing(1)}`,
    overflowY: 'auto',
    zIndex: LayoutZIndexes.NAVIGATION_SIDE_BAR,
    width: '100%',
    backgroundColor: theme.palette.common.white,
    boxSizing: 'border-box',
  },
})

const isActive = (forms, currentPath) =>
  find(forms, form => !!matchPath(currentPath, { path: form.path, exact: true, strict: false }))

const NavigationSideBarMenu = ({ classes, forms, location, hash, setNavigationScrolling }) => {
  const activeCollapsibleForm = forms.find(form => isActive(form.forms, location))?.key

  const handleOnePagerScroll = useCallback(
    (path, anchor) => {
      if (location === path && anchor) {
        const elementId = anchor.replace('#', '')
        const elementToScrollTo = document.getElementById(elementId)

        if (elementToScrollTo) {
          setNavigationScrolling({ navigationScrolling: elementId })

          setTimeout(() => {
            const y = elementToScrollTo.getBoundingClientRect().top + window.scrollY - HEADER_HEIGHT
            window.scrollTo({ top: y, behavior: 'smooth' })
          }, 0)
        }
      }
    },
    [location, setNavigationScrolling]
  )

  return (
    <List className={classes.root}>
      {forms.map(({ forms, path, title, key, id, type, anchor = '' }) => {
        const hasNestedItems = isArray(forms) && !isEmpty(forms)

        if (hasNestedItems) {
          if ([REPORT_STRUCTURE_TYPES.ONE_PAGER_SECTION].includes(type)) {
            return (
              <>
                <NavigationSideBarMenuItem
                  key={path + anchor}
                  id={id}
                  path={path}
                  isActive={path === location && anchor === hash}
                  currentPath={location}
                  anchor={anchor}
                  title={title}
                  onItemClick={() => handleOnePagerScroll(path, anchor)}
                />
                <List sx={{ pl: 3 }} component="div" disablePadding>
                  {forms.map(({ id, title, anchor = '' }) => {
                    return (
                      <NavigationSideBarMenuItem
                        key={path + anchor}
                        id={id}
                        path={path}
                        isActive={path === location && anchor === hash}
                        currentPath={location}
                        anchor={anchor}
                        title={title}
                        onItemClick={() => handleOnePagerScroll(path, anchor)}
                        nested
                      />
                    )
                  })}
                </List>
              </>
            )
          } else {
            const shouldExpandAccordion = activeCollapsibleForm === key || title === MENU_TITLE_IMPROVED_SALES

            return (
              <NavigationSideBarCollapsibleMenuItem
                forms={forms}
                key={key}
                id={key}
                location={location}
                hash={hash}
                isActive={shouldExpandAccordion}
                title={title}
                onMenuItemClick={(itemPath, itemAnchor) => handleOnePagerScroll(itemPath, itemAnchor)}
              />
            )
          }
        }

        return (
          <NavigationSideBarMenuItem
            key={path + anchor}
            id={id}
            path={path}
            isActive={path === location && anchor === hash}
            currentPath={location}
            anchor={anchor}
            title={title}
            onItemClick={() => handleOnePagerScroll(path, anchor)}
          />
        )
      })}
    </List>
  )
}

NavigationSideBarMenu.propTypes = {
  forms: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.string.isRequired,
      path: PropTypes.string,
      title: PropTypes.string.isRequired,
      isActive: PropTypes.bool,
    })
  ).isRequired,
  location: PropTypes.string.isRequired,
  hash: PropTypes.string,
}

export default compose(
  withStyles(styles, {
    name: 'NavigationSideBarMenu',
  }),
  connect(
    state => ({
      location: get(state, 'router.location.pathname'),
      hash: get(state, 'router.location.hash'),
    }),
    {
      setNavigationScrolling,
    }
  )
)(NavigationSideBarMenu)
