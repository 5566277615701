import { createAction } from 'redux-actions'

import { ADD_PAGE_BOOKMARK, REMOVE_PAGE_BOOKMARK, REMOVE_ALL_BOOKMARKS, FETCH_BOOKMARKS } from '../actionTypes'

export const addPageBookmark = createAction(ADD_PAGE_BOOKMARK.ACTION)
export const addPageBookmarkRequest = createAction(ADD_PAGE_BOOKMARK.REQUEST)
export const addPageBookmarkReceive = createAction(ADD_PAGE_BOOKMARK.RECEIVE)
export const addPageBookmarkFail = createAction(ADD_PAGE_BOOKMARK.FAIL)

export const removePageBookmark = createAction(REMOVE_PAGE_BOOKMARK.ACTION)
export const removePageBookmarkRequest = createAction(REMOVE_PAGE_BOOKMARK.REQUEST)
export const removePageBookmarkReceive = createAction(REMOVE_PAGE_BOOKMARK.RECEIVE)
export const removePageBookmarkFail = createAction(REMOVE_PAGE_BOOKMARK.FAIL)

export const removeAllBookmarks = createAction(REMOVE_ALL_BOOKMARKS.ACTION)
export const removeAllBookmarksRequest = createAction(REMOVE_ALL_BOOKMARKS.REQUEST)
export const removeAllBookmarksReceive = createAction(REMOVE_ALL_BOOKMARKS.RECEIVE)
export const removeAllBookmarksFail = createAction(REMOVE_ALL_BOOKMARKS.FAIL)

export const fetchBookmarks = createAction(FETCH_BOOKMARKS.ACTION)
export const fetchBookmarksRequest = createAction(FETCH_BOOKMARKS.REQUEST)
export const fetchBookmarksReceive = createAction(FETCH_BOOKMARKS.RECEIVE)
export const fetchBookmarksFail = createAction(FETCH_BOOKMARKS.FAIL)
