import { get, isEmpty } from 'lodash'
import { delay } from 'redux-saga'
import { push } from 'react-router-redux'

import { put, takeLatest, select, call, take, fork, cancel } from 'redux-saga/effects'

import { errorNotification } from '../../../../shared/redux/actions/notifications'

import {
  buildingCompFetchRequest,
  buildingCompFetchReceive,
  buildingCompFetchFail,
  buildingCompUpdateRequest,
  buildingCompUpdateReceive,
  buildingCompUpdateFail,
  buildingCompRentRollUploadCsvFail,
  buildingCompRentRollUploadCsvReceive,
  buildingCompRentRollUploadCsvRequest,
  searchProperties,
  searchPropertiesRequest,
  searchPropertiesReceive,
  searchPropertiesFail,
  searchNeighborhoodByBoroughRequest,
  searchNeighborhoodByBoroughReceive,
  searchNeighborhoodByBoroughFail,
  editSelectedBuildingComps,
  buildingCompFetch,
  updateBuildingComp,
  buildingCompRentRollUploadCsv,
  searchNeighborhoodByBorough,
} from '../actions'
import * as Api from '../../api'
import * as CoreApi from '../../../../core/api'

const DEFAULT_DELAY = 500

const mapAmenitiesToObject = amenities => {
  return amenities.reduce((prev, next) => {
    prev[next] = next
    return prev
  }, {})
}

function* buildingCompFetchHandler(action) {
  const { reportId, buildingCompId } = get(action, 'payload')
  try {
    yield put(buildingCompFetchRequest({ reportId }))
    const buildingCompData = yield Api.fetchBuildingComp(reportId, buildingCompId)
    yield put(buildingCompFetchReceive(buildingCompData))
  } catch (err) {
    yield put(buildingCompFetchFail(err))
  }
}

function* goToForm(reportId, buildingCompId, formPath) {
  yield put(push(`/report/${reportId}/building-comp/${buildingCompId}/${formPath}`))
}

function* updateBuildingCompHandler({ payload }) {
  const reportId = yield select(state => state.buildingComp.reportId)
  const buildingCompId = yield select(state => state.buildingComp.id)
  const { formDataPath, values, nextFormPath } = payload
  try {
    yield put(buildingCompUpdateRequest(payload))
    if (nextFormPath) {
      yield goToForm(reportId, buildingCompId, nextFormPath)
    }
    const response = yield Api.updateBuildingComp(reportId, buildingCompId, formDataPath, values)

    yield put(buildingCompUpdateReceive(response))
  } catch (err) {
    yield put(buildingCompUpdateFail(err))
    const message =
      `There was an error while trying to save this form, please contact us quoting Building Comp Id` +
      ` '${buildingCompId}' and form '${
        formDataPath[formDataPath.length - 1]
      }' for assistance. You can continue working on other forms while this issue is resolved.`
    yield put(
      errorNotification({
        message,
      })
    )
  }
}

function* searchPropertiesHandler(action) {
  let task
  while (true) {
    const data = yield take(searchProperties)
    if (task) {
      yield cancel(task)
    }
    task = yield fork(handleSearchProperties, data)
  }
}

function* handleSearchProperties(action) {
  yield call(delay, DEFAULT_DELAY)
  try {
    yield put(searchPropertiesRequest())
    const reportId = yield select(state => get(state, 'report.reportData._id'))
    const {
      filters: { amenities, bedroomCounts },
      polygon,
    } = yield select(state => state.buildingComp || {})
    const unitGroupingType = yield select(state =>
      get(state, 'report.reportData.incomeApproach.residentialIncome.residentialUnitGroups.groupingType')
    )

    const properties = yield Api.searchProperties(reportId, {
      unitGroupingType,
      amenities: mapAmenitiesToObject(amenities),
      bedroomCounts,
      polygon,
    })
    yield put(searchPropertiesReceive({ properties, isCustomSelection: polygon.isCustomSelection }))
  } catch (err) {
    yield put(searchPropertiesFail(err))
  }
}

function* editSelectedBuildingCompHandler(action) {
  const buildingCompId = get(action, 'payload')
  const reportId = yield select(state => get(state, 'report.reportData._id'))
  yield goToForm(reportId, buildingCompId, 'property-summary')
}

function* buildingCompRentRollUploadCsvHandler(action) {
  const { file, dataPath } = action.payload

  if (!file) {
    return
  }

  try {
    yield put(buildingCompRentRollUploadCsvRequest())

    const { err, json } = yield CoreApi.uploadCSV(file)

    if (err) {
      yield put(buildingCompRentRollUploadCsvFail(err))
      return
    }

    const { units, invalidItems, err: uploadErr } = yield CoreApi.getImportedUnits(json)
    if (uploadErr) {
      yield put(buildingCompRentRollUploadCsvFail(err))
      return
    }
    yield put(buildingCompRentRollUploadCsvReceive({ dataPath, units, invalidItems }))
  } catch (err) {
    yield put(buildingCompRentRollUploadCsvFail(err))
  }
}

function* searchNeighborhoodByBoroughHandler(action) {
  const { neighborhood, dataPath } = action.payload
  yield put(searchNeighborhoodByBoroughRequest())
  try {
    if (isEmpty(neighborhood)) {
      return yield put(searchNeighborhoodByBoroughReceive({ neighborhood: [], dataPath }))
    }
    const borough = yield select(state => get(state, 'buildingComp.propertySummary.descriptor.borough'))
    yield call(delay, 100)
    const neighborhoodSuggest = yield Api.findNeighborhoodByBorough(neighborhood, borough)
    yield put(
      searchNeighborhoodByBoroughReceive({
        neighborhood: neighborhoodSuggest,
        dataPath,
      })
    )
  } catch (err) {
    yield put(searchNeighborhoodByBoroughFail(err))
  }
}

export default [
  fork(searchPropertiesHandler),
  takeLatest(editSelectedBuildingComps, editSelectedBuildingCompHandler),
  takeLatest(buildingCompFetch, buildingCompFetchHandler),
  takeLatest(updateBuildingComp, updateBuildingCompHandler),
  takeLatest(buildingCompRentRollUploadCsv, buildingCompRentRollUploadCsvHandler),
  takeLatest(searchNeighborhoodByBorough, searchNeighborhoodByBoroughHandler),
]
