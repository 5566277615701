import React from 'react'
import PropTypes from 'prop-types'

import { TableRow } from '@mui/material'
import { kebabCase, isNil, get } from 'lodash'

import { columnShape } from './propTypes'

export class TableBodyRow extends React.PureComponent {
  static propTypes = {
    cellComponent: PropTypes.func.isRequired,
    columns: PropTypes.arrayOf(columnShape).isRequired,
    rowClass: PropTypes.string,
  }

  render() {
    const { cellComponent, columns, index, rowClass, ...restProps } = this.props

    const CellComponent = cellComponent

    let qaAttr = get(restProps, 'data-qa')
    if (!qaAttr) {
      qaAttr = `row-${!isNil(index) ? index : kebabCase(restProps.label)}`
    }

    return (
      <TableRow data-qa={qaAttr} className={rowClass}>
        {columns.map(column => (
          <CellComponent key={column.name} column={column} index={index} {...restProps} />
        ))}
      </TableRow>
    )
  }
}
