import React, { useState } from 'react'
import { IconButton, Menu, MenuItem } from '@material-ui/core'

import Delete from '@mui/icons-material/DeleteOutlined'
import Edit from '@mui/icons-material/Edit'
import ArrowDown from '@mui/icons-material/ArrowDropDown'

const DropDownMenu = ({ clearComp, editComp, classes, comp, anchorRef }) => {
  const [isOpen, setIsOpen] = useState(false)
  const [menuItemWidth, setWidth] = useState(null)

  const anchorEl = anchorRef.current

  const toggleMenu = event => {
    if (!isOpen) {
      setWidth(anchorEl.clientWidth)
    }
    setIsOpen(!isOpen)
  }
  const launchEdit = () => {
    editComp(comp)
    toggleMenu()
  }
  const handleClear = () => {
    clearComp(comp)
    toggleMenu()
  }
  return (
    <>
      <IconButton
        size="small"
        className={classes.masterTable_Header_Cell_Button}
        onClick={toggleMenu}
        aria-label="edit"
      >
        <ArrowDown />
      </IconButton>
      <Menu
        open={isOpen}
        onClose={toggleMenu}
        anchorEl={anchorEl}
        disableAutoFocusItem
        PaperProps={{
          role: 'menubar',
          style: {
            marginTop: '50px',
            width: menuItemWidth,
          },
        }}
      >
        <MenuItem onClick={launchEdit}>
          <IconButton size="small" className={classes.menuButton} aria-label="edit">
            <Edit />
          </IconButton>
          Edit Comparable
        </MenuItem>
        <MenuItem onClick={handleClear}>
          <IconButton size="small" className={classes.menuButton} aria-label="clear">
            <Delete />
          </IconButton>
          Clear Adjustment on Comp
        </MenuItem>
      </Menu>
    </>
  )
}

export default DropDownMenu
