import React from 'react'
import PropTypes from 'prop-types'

import { grey } from '@mui/material/colors'
import { Map } from 'leaflet'
import { Marker, Polyline } from 'react-leaflet'

const connectorStyle = { color: grey[900], weight: 2 }

export default class ConnectedIconLabel extends React.PureComponent {
  constructor(props) {
    super(props)

    const labelPosition = [props.icon.position[0] + props.labelOffset, props.icon.position[1]]
    this.initialLabelPosition = labelPosition
    this.state = {
      iconPosition: props.icon.position,
      labelPosition,
      lastMapBoundsLatLng: null,
    }
  }

  static propTypes = {
    icon: PropTypes.shape({
      position: PropTypes.arrayOf(PropTypes.number).isRequired,
      icon: PropTypes.any.isRequired,
    }).isRequired,
    label: PropTypes.shape({
      icon: PropTypes.any.isRequired,
    }).isRequired,
    labelOffset: PropTypes.number,
  }

  static defaultProps = {
    labelOffset: 0.001,
  }

  static contextTypes = {
    map: PropTypes.instanceOf(Map),
  }

  mapBoundsContainLatLng = latLng => {
    const map = this.context.map
    return map.getBounds().contains(latLng)
  }

  onIconDrag = marker => {
    this.setState({
      iconPosition: [marker.latlng.lat, marker.latlng.lng],
    })
  }

  onLabelDrag = marker => {
    const { latlng: latLng } = marker
    let lastMapBoundsLatLng = null

    if (this.mapBoundsContainLatLng(latLng)) {
      lastMapBoundsLatLng = latLng
    }

    this.setState(prevState => ({
      lastMapBoundsLatLng: lastMapBoundsLatLng || prevState.lastMapBoundsLatLng,
      labelPosition: [latLng.lat, latLng.lng],
    }))
  }

  onLabelDragend = ({ target: marker }) => {
    const { lastMapBoundsLatLng } = this.state
    const latLng = marker.getLatLng()

    if (!this.mapBoundsContainLatLng(latLng)) {
      marker.setLatLng(lastMapBoundsLatLng)
    }
  }

  render() {
    const { icon, label } = this.props
    const { iconPosition, labelPosition } = this.state
    const { initialLabelPosition } = this

    return (
      <React.Fragment>
        <Marker {...icon} onMove={this.onIconDrag} />
        <Marker {...label} position={initialLabelPosition} onMove={this.onLabelDrag} onDragend={this.onLabelDragend} />
        <Polyline positions={[iconPosition, labelPosition]} {...connectorStyle} />
      </React.Fragment>
    )
  }
}
