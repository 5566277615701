import React from 'react'

import { Field } from 'react-final-form'
import { Autocomplete, Paper, TextField } from '@mui/material'

const CustomPaper = props => {
  return <Paper {...props} sx={{ padding: 0 }} />
}

const AutocompleteField = ({ id, label, name, options, ...props }) => {
  const autocompleteId = id || name

  return (
    <Field name={name}>
      {({ input }) => (
        <Autocomplete
          PaperComponent={CustomPaper}
          id={autocompleteId}
          onChange={(_event, value) => input.onChange(value)}
          value={!!input.value ? input.value : null}
          options={options}
          renderInput={params => <TextField {...{ label, ...params }} />}
          {...props}
        />
      )}
    </Field>
  )
}

export default AutocompleteField
