import { getPartsFromString } from '../../narrative/GeneratedText'

export const INCOME_APPROACH_TEXT = {
  description1: `In the Income Capitalization Approach, a property's capacity to generate future benefits is analyzed; \
the forecasted income is capitalized into an indication of present value. Definitions of commonly used measures of \
anticipated benefits are defined in the Glossary of Terms within the Addenda.`,
  description2: `The Income Capitalization Approach supports two methodologies: direct and yield capitalization. \
Investors in the local market typically utilize a direct capitalization when making investment decisions for this \
asset class, therefore we conclude that the direct capitalization method is appropriate to apply to the subject.`,
}

export const getIncomeApproachDescription1 = () => getPartsFromString(INCOME_APPROACH_TEXT.description1)
export const getIncomeApproachDescription2 = () => getPartsFromString(INCOME_APPROACH_TEXT.description2)
