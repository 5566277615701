import React from 'react'
import PropTypes from 'prop-types'
import { TableCell } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'

const styles = {
  noDataCell: {
    textAlign: 'center',
    padding: '40px 0',
  },
}

const NoDataCellBase = ({ loading, label, colSpan, classes }) => (
  <TableCell className={classes.noDataCell} colSpan={colSpan}>
    <big>{loading ? '' : label}</big>
  </TableCell>
)

NoDataCellBase.propTypes = {
  loading: PropTypes.bool.isRequired,
  label: PropTypes.string.isRequired,
  colSpan: PropTypes.number.isRequired,
  classes: PropTypes.object.isRequired,
}

export default withStyles(styles, { name: 'NoDataCell' })(NoDataCellBase)
