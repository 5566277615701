import React from 'react'
import PropTypes from 'prop-types'
import { ListItemText } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'

const classes = {
  root: {
    display: 'block',
    textAlign: 'left',
    padding: '5px',
  },
}

const plainItemStyles = theme => ({
  ...classes,
  primary: {
    fontSize: 14,
    fontWeight: 400,
    color: '#828282',
  },
})

const headingItemStyles = theme => ({
  ...classes,
  primary: {
    fontSize: 14,
    fontWeight: 400,
    color: theme.palette.text.primary,
  },
})

const SideBarItemText = withStyles(plainItemStyles, {
  name: 'SideBarItemText',
})(ListItemText)

const SideBarItemHeadingText = withStyles(headingItemStyles, {
  name: 'SideBarItemHeadingText',
})(ListItemText)

export default class NavigationSideBarItemText extends React.PureComponent {
  static propTypes = {
    text: PropTypes.string,
    isHeading: PropTypes.bool,
  }

  static defaultProps = {
    text: '',
    isHeading: false,
  }

  render() {
    const { isHeading, color } = this.props
    const text = <span style={{ color }}>{this.props.text}</span>
    if (isHeading) {
      return <SideBarItemHeadingText primary={text} />
    } else {
      return <SideBarItemText primary={text} />
    }
  }
}
