import { SIDEBAR_WIDTH } from './constants'

const styles = {
  border: '1px solid #D7DEEA',
  link: {
    color: 'inherit',
    textDecoration: 'none',
  },
  menuPopupItem: {
    color: 'inherit',
    textDecoration: 'none',
    padding: '8px 16px',
    width: '100%',
  },
  divider: {
    backgroundColor: '#D7DEEA',
    width: SIDEBAR_WIDTH,
    paddingTop: '0px',
  },
}

export default styles
