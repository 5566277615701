import React from 'react'

import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Button,
  Stack,
  Checkbox,
  FormControlLabel,
  Typography,
} from '@mui/material'

const ExportConfirmModal = ({ open, onSave, onCancel, handleChange }) => {
  return (
    <Dialog fullWidth open={open} data-qa="export-confirm-dialog">
      <DialogTitle>
        <Typography variant="h6" component="span">
          Subject eligible for Sales Comparable database
        </Typography>
      </DialogTitle>
      <DialogContent>
        <DialogContentText sx={{ color: 'rgba(34, 34, 34, 0.87)' }}>
          This subject has either sold in the past 3 years or is currently in contract. Would you like to submit this
          subject property to the Bowery Sales Comparable database? The Comparable will be created upon draft submission
          in Salesforce.
        </DialogContentText>
      </DialogContent>
      <Stack flexDirection="row" justifyContent="space-between" sx={{ paddingLeft: '24px' }}>
        <FormControlLabel
          control={<Checkbox />}
          label="Don't ask me again"
          onChange={handleChange}
          data-qa="export-confirm-checkbox"
        />
        <DialogActions>
          <Button onClick={onCancel} data-qa="export-confirm-reject-btn">
            NO
          </Button>
          <Button variant="contained" onClick={onSave} data-qa="export-confirm-submit-btn">
            YES
          </Button>
        </DialogActions>
      </Stack>
    </Dialog>
  )
}

export default ExportConfirmModal
