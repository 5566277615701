import { intersectionWith, isEmpty, isEqual, max, mean, min, xorWith, invert, get } from 'lodash'
import ObjectID from 'bson-objectid'

import BoweryDate from '@bowery-valuation/bowery-date'

import { toPercentageString } from 'client-shared/utils/numberFormatters'
import { SaleStatus } from 'shared/constants/report/sales/salesStatus'
import { STATE_NAMES } from 'shared/constants/states'
import { checkIfLatestSalesCompVersion } from 'client-shared/utils/compPlex.gql'
import { getSalePriceToUse } from 'shared/report-calculations/sales-approach/helpers'
import { getCapRateSourceName, getCapRateSourceOfInformation } from 'shared/helpers/compplex'

import { getRemovedSalesComps, getSelectedSalesComps } from '../../sales/SalesCompsSearch/tools'

import { ADDITIONAL_CAP_RATES_COMPS_TEXT } from './constants'

const mapComps = (comps = []) => {
  return comps.map(comp => ({
    address: comp.address,
    streetAddress: comp.streetAddress,
    block: comp.block,
    capRate: comp.capRate,
    gba: comp.gba,
    lot: comp.lot,
    saleDate: comp.saleDate ? new BoweryDate(comp.saleDate).format('MM DD YYYY') : null,
    salePrice: comp.salePrice,
    type: comp.type,
    yearBuilt: comp.yearBuilt,
  }))
}

const checkCompsDifference = values => {
  const capRateComps = mapComps(values.capRateComps)
  const salesComps = mapComps(values.salesComps)

  const sameComps = intersectionWith(capRateComps, salesComps, isEqual)
  const difference = xorWith(sameComps, capRateComps, isEqual)

  return !isEmpty(difference)
}

export const getSaleCompFullAddress = ({ streetAddress, city, state, zip, address }) => {
  const stateAbbreviations = invert(STATE_NAMES)
  return `${streetAddress || address}${city ? ', ' : ''}${city || ''}${state ? ', ' : ''}${
    stateAbbreviations[state] || ''
  }${zip ? ', ' : ''}${zip || ''}`
}

export const getFormattedSaleDate = (listing, inContract, saleDate, fillerIcon) => {
  if (inContract) {
    return 'In-Contract'
  } else if (listing) {
    return 'Listing'
  } else {
    return saleDate ? new BoweryDate(saleDate).formatShortDateWithZeroes() : fillerIcon
  }
}

export const getComparableCapRatesGeneratedText = values => {
  const { capRateComps, propertyType } = values

  const capRates = capRateComps.map(capRateComp => capRateComp.capRate)
  const minCapRate = toPercentageString(min(capRates)) || '[Min Cap Rate]'
  const maxCapRate = toPercentageString(max(capRates)) || '[Max Cap Rate]'
  const averageCapRate = toPercentageString(mean(capRates)) || '[Average Cap Rate]'

  let generatedText =
    `We analyzed sales of comparable ${propertyType} assets within the subject’s periphery and they ` +
    `exhibit overall capitalization rates from ${minCapRate} to ${maxCapRate} with an average of ${averageCapRate}.`

  if (checkCompsDifference(values)) {
    generatedText += ` ${ADDITIONAL_CAP_RATES_COMPS_TEXT}`
  }
  return generatedText
}

export const areSameCapRateComps = (compA, compB) => {
  if (compA.salesEventId || compB.salesEventId) {
    return compA.salesEventId === compB.salesEventId
  }

  return compA.streetAddress === compB.streetAddress && compA.saleDate === compB.saleDate
}

export const getSelectedCapRateComps = (selectedComps, newSelectedComps) => {
  return getSelectedSalesComps(selectedComps, newSelectedComps, areSameCapRateComps)
}

export const getRemovedCapRateComps = (removedComps, newSelectedComps) => {
  return getRemovedSalesComps(removedComps, newSelectedComps, areSameCapRateComps)
}

export const checkIfCompsAreLatestVersion = async selectedComps => {
  const defaultResponse = { isLatestVersion: false }
  const updates = selectedComps.map(async comp => {
    try {
      const { salesEventId, salesEventVersion } = comp
      const { isLatestVersion, deletedAt } =
        salesEventId && salesEventVersion
          ? await checkIfLatestSalesCompVersion({
              version: salesEventVersion,
              salesTransactionId: salesEventId,
            })
          : defaultResponse

      return {
        ...comp,
        isLatestVersion,
        deletedAt,
      }
    } catch (error) {
      console.warn(`Checking latest version for cap rate comp ${comp._id}`, error)
      return {
        ...comp,
        ...defaultResponse,
      }
    }
  })

  const updatedComps = await Promise.all(updates)
  return updatedComps
}

export const mapCapRateCompToCompPlexSalesComp = comp => {
  if (!comp) {
    return null
  }

  return {
    saleDate: comp.saleDate,
    fullAddress: comp.address,
    address: {
      streetAddress: comp.streetAddress,
      city: comp.city,
      state: comp.state,
      postalCode: comp.zip,
      propertyIdentification: {
        propertyIdentifierType: comp.propertyIdentifierType,
        propertyIdentifier: comp.propertyIdentifier,
      },
      coords: comp.coords,
    },
    propertyInformation: {
      displayStreetAddress: comp.streetAddress,
      displayCity: comp.city,
      propertyType: comp.type,
      yearBuilt: comp.yearBuilt,
      grossBuildingArea: comp.gba,
      grossLeasableArea: comp.grossLeasableArea,
      netRentableArea: comp.netRentableArea,
      netLeasableArea: comp.netLeasableArea,
      residentialUnits: comp.residentialUnits,
      commercialUnits: comp.commercialUnits,
      condition: comp.condition,
      numberOfFloors: comp.numberOfFloors,
    },
    saleInformation: {
      saleStatus: comp.saleStatus,
      capRate: comp.capRate,
      salePrice: comp.salePrice,
      buyer: comp.grantee,
      seller: comp.grantor,
    },
    resourceInformation: {
      sources: [
        {
          type: comp.sourceOfInformation,
          url: comp.sourceUrl,
        },
      ],
    },
  }
}

export const getSaleStatusAttributes = saleStatus => {
  const statusAttributes = {
    listing: false,
    inContract: false,
  }

  switch (saleStatus) {
    case SaleStatus.LISTING:
      statusAttributes.listing = true
      break
    case SaleStatus.UNDER_CONTRACT:
      statusAttributes.listing = true
      statusAttributes.inContract = true
      break
    case SaleStatus.TRANSACTION:
    default:
      break
  }

  return statusAttributes
}

function getSaleDetails(input) {
  const saleInformationInput = input.saleInformation
  const verificationInformationInput = input.verificationInformation
  const resourceInformationInput = input.resourceInformation
  const statusAttributes = getSaleStatusAttributes(saleInformationInput.saleStatus)
  const saleDate = input.saleDate ?? saleInformationInput.saleDate

  const getSourceOfInformation = rawInput => {
    if (rawInput.sources?.[0] && rawInput.sources?.[0].url) {
      return 'externalDatabase'
    }

    if (!rawInput.sources?.[0]) {
      return null
    }

    if (rawInput.sources?.[0].type === 'bowerySubject' || rawInput.sources?.[0].type === 'externalDatabase') {
      return rawInput.sources?.[0].type
    }

    return 'other'
  }

  const getSourceName = rawInput => {
    if (!rawInput.sources?.[0]) {
      return null
    }
    if (rawInput.sources[0].type === 'costar') {
      return 'CoStar'
    }
    if (rawInput.sources[0].type === 'externalDatabase') {
      return 'External Database'
    }
    if (rawInput.sources[0].type === 'bowerySubject' && rawInput.sources[0].url) {
      return 'Bowery Subject'
    }
    return rawInput.sources[0].type
  }

  const saleDetails = {
    saleDate: saleInformationInput.saleStatus !== SaleStatus.LISTING ? saleDate : null,
    salePrice: saleInformationInput.adjustedSalePrice || saleInformationInput.salePrice,
    adjustedSalePrice: saleInformationInput.adjustedSalePrice,
    deedSalePrice: saleInformationInput.salePrice,
    salePricePerSF: input.pricePerSF,
    salePricePerUnit: input.pricePerUnit,
    salePriceAdjustment: saleInformationInput.salePriceAdjustment,
    adjustmentReason: saleInformationInput.salePriceAdjustmentReason,
    propertyRights: saleInformationInput.propertyRights,
    grantor: saleInformationInput.seller,
    grantee: saleInformationInput.buyer,
    uniqueSaleIdentifier: saleInformationInput.uniqueSaleIdentifier,
    confirmed: verificationInformationInput?.confirmed,
    verified: verificationInformationInput?.verified,
    contact: verificationInformationInput?.contact
      ? {
          name: verificationInformationInput.contact.name,
          phoneNumber: verificationInformationInput.contact.phoneNumber,
          email: verificationInformationInput.contact.email,
          company: verificationInformationInput.contact.company,
          role: verificationInformationInput.contact.role,
        }
      : {},
    verifiedDate: verificationInformationInput?.verifiedDate || null,
    status: input.status || verificationInformationInput?.status || null,
    listingAsOfDate: saleInformationInput.saleStatus === SaleStatus.LISTING ? saleDate : null,
    sourceOfInformation: getSourceOfInformation(resourceInformationInput),
    sourceName: getSourceName(resourceInformationInput),
    sourceUrl: resourceInformationInput.sources?.[0] ? resourceInformationInput.sources[0].url : null,
    capRate: saleInformationInput.capRate,
    // capRateReported: saleInformationInput.capRateReported, // TODO
    // ...pick(input, [
    //   'sourceOfInformation',
    // ]),
    ...statusAttributes,
  }

  return saleDetails
}
function getWebappCommercialUses(input = []) {
  const commercialUses = {
    medicalOffice: false,
    undetermined: false,
    communityFacility: false,
    retail: false,
    office: false,
  }

  input.forEach(use => {
    commercialUses[use] = true
  })
  return commercialUses
}

function getPropertyDetails(input) {
  const { commentary, propertyInformation, saleInformation } = input
  const commercialUses = getWebappCommercialUses(input.propertyInformation?.commercialUses)
  const propertyDetails = {
    photo: propertyInformation?.photo
      ? {
          name: propertyInformation?.photo.fileName,
          cdnUrl: propertyInformation?.photo.cdnUrl,
        }
      : null,
    propertyDescription: {
      commentary: commentary?.commentary,
      isGeneratedCommentaryOverridden: commentary?.isOverridden,
      additionalCommentary: commentary?.additionalCommentary,
    },
    amenities: propertyInformation?.buildingAmenities,
    submarket: propertyInformation?.submarket,
    market: propertyInformation?.market,
    propertyName: propertyInformation?.propertyName,
    parking: propertyInformation?.parking,
    tenancy: propertyInformation?.tenancy,
    access: propertyInformation?.access,
    exposure: propertyInformation?.exposure,
    financialInformation: {
      netOperatingIncome: saleInformation.netOperatingIncome,
      effectiveGrossIncomeMultiplier: saleInformation.effectiveGrossIncomeMultiplier,
      potentialGrossIncome: saleInformation.potentialGrossIncome,
      grossIncomeMultiplier: saleInformation.potentialGrossIncomeMultiplier,
      effectiveGrossIncome: saleInformation.effectiveGrossIncome,
      expenseRatio: saleInformation.expenseRatio,
    },
    type: propertyInformation?.propertyType,
    gba: propertyInformation?.grossBuildingArea,
    yearBuilt: propertyInformation?.yearBuilt,
    isYearBuiltEstimate: propertyInformation?.isYearBuiltEstimate,
    yearRenovated: propertyInformation?.yearRenovated,
    numStories: propertyInformation?.numberOfFloors,
    elevator: propertyInformation?.buildingType === 'elevator',
    condition: propertyInformation?.condition,
    residentialUnits: propertyInformation?.residentialUnits,
    commercialUnits: propertyInformation?.commercialUnits,
    commercialArea: propertyInformation?.commercialArea,
    commercialUses,
    unitTypes: propertyInformation?.unitTypes,
    occupancyRate: propertyInformation?.occupancy,
    buildingClass: propertyInformation?.buildingClass,
    netRentableArea: propertyInformation?.netRentableArea,
    averageUnitSize: propertyInformation?.averageUnitSize,
    landToBuildingRatio: propertyInformation?.landToBuildingRatio,
  }

  return propertyDetails
}

export function transformSalesCompToRawOutput(input, oldLocalCopy = {}) {
  const address = {
    address: input.propertyInformation.displayStreetAddress,
    streetAddress: input.propertyInformation.displayStreetAddress,
    city: input.propertyInformation.displayCity,
    state: input.address.state,
    zip: input.address.postalCode,
    coords: input.address.coords,
    propertyIdentifier: input.address.propertyIdentification?.propertyIdentifier,
    propertyIdentifierType: input.address.propertyIdentification?.propertyIdentifierType,
  }
  const propertyInformation = {
    siteArea: input.propertyInformation?.siteArea,
    siteAreaUnit: input.propertyInformation?.siteAreaUnit,
    zoning: input.propertyInformation?.zoning,
    neighborhood: input.propertyInformation?.neighborhood,
  }
  const saleDetails = getSaleDetails(input)
  const propertyDetails = getPropertyDetails(input)

  const outputDto = {
    _id: oldLocalCopy._id || ObjectID().toString(),
    ...oldLocalCopy,
    ...address,
    ...propertyInformation,
    ...saleDetails,

    ...propertyDetails,

    internalNotes: input.commentary?.internalNotes,

    salesEventId: input.id || input.salesTransactionId,
    salesEventVersion: input.version || input.salesTransactionVersion,
    isLatestVersion: input.isLatestVersion,
    includeInReport: true,
    isBowerySubject: input.isBowerySubject,
    deletedAt: input.deletedAt,
  }

  return outputDto
}

export const getSaleStatus = rawInput => {
  if (rawInput.inContract) {
    return SaleStatus.UNDER_CONTRACT
  }

  if (rawInput.listing) {
    return SaleStatus.LISTING
  }

  return SaleStatus.TRANSACTION
}

// TODO: reorder fields
export const mapCompPlexSalesCompToCapRateComp = (compPlexSalesComp, existingWebappSalesComp = {}) => {
  const salesComp = transformSalesCompToRawOutput(compPlexSalesComp, existingWebappSalesComp)

  const SOURCE_OTHER = 'other'
  const SOURCE_NAME_OTHER = 'Other'

  const isSourceOfInformationOther = salesComp.sourceName === SOURCE_OTHER

  return {
    _id: salesComp._id,
    salesEventId: salesComp.salesEventId,
    salesEventVersion: salesComp.salesEventVersion,
    address: salesComp.address,
    streetAddress: salesComp.streetAddress,
    capRate: salesComp.capRate,
    city: salesComp.city,
    coords: salesComp.coords,
    elevator: salesComp.elevator,
    gba: salesComp.gba,
    inContract: salesComp.inContract,
    listing: salesComp.listing,
    pricePerSF: salesComp.salePricePerSF,
    saleDate: salesComp.saleDate || salesComp.listingAsOfDate,
    salePrice: salesComp.salePrice,
    sourceName: isSourceOfInformationOther ? SOURCE_NAME_OTHER : salesComp.sourceName,
    sourceOfInformation: isSourceOfInformationOther ? SOURCE_OTHER : salesComp.sourceOfInformation,
    sourceUrl: salesComp.sourceUrl,
    state: salesComp.state,
    type: salesComp.type,
    yearBuilt: salesComp.yearBuilt,
    zip: salesComp.zip,
    photo: salesComp.photo,
    residentialUnits: salesComp.residentialUnits,
    commercialUnits: salesComp.commercialUnits,
    isLatestVersion: salesComp.isLatestVersion,
    propertyIdentifierType: salesComp.propertyIdentifierType,
    propertyIdentifier: salesComp.propertyIdentifier,
    grossLeasableArea: compPlexSalesComp.propertyInformation?.grossLeasableArea,
    netRentableArea: salesComp.netRentableArea,
    netLeasableArea: compPlexSalesComp.propertyInformation?.netLeasableArea,
    saleStatus: compPlexSalesComp.saleInformation?.saleStatus,
    status: salesComp.status,
    condition: salesComp.condition,
    numberOfFloors: salesComp.numStories,
    grantee: salesComp.grantee,
    grantor: salesComp.grantor,
    deletedAt: salesComp.deletedAt,
  }
}

const getSaleDateForCapRateComp = saleDate => {
  const parsedDate = new BoweryDate(saleDate)

  if (saleDate && parsedDate.isValidDate()) {
    return parsedDate.toISOString()
  }

  return null
}

export const mapWebappSalesCompToCapRateComp = salesComp => {
  const capRateCompId = get(salesComp, '_id', ObjectID().toString())
  const { saleInformation, propertyInformation, address, resourceInformation } = salesComp
  const {
    propertyType,
    residentialUnits,
    commercialUnits,
    yearBuilt,
    buildingType,
    grossBuildingArea,
    neighborhood,
    photo,
    grossLeasableArea,
    netRentableArea,
    netLeasableArea,
    condition,
    numberOfFloors,
  } = propertyInformation
  const { capRate, saleDate, saleStatus, seller, buyer } = saleInformation
  const salePrice = getSalePriceToUse(saleInformation)

  const formattedAddress = `${address.streetAddress}, ${address.city}, ${address.state} ${address.postalCode}`

  const capRateComp = {
    _id: capRateCompId,
    salesEventId: salesComp.salesTransactionId,
    salesEventVersion: salesComp.salesTransactionVersion,
    address: formattedAddress,
    streetAddress: address.streetAddress,
    type: propertyType,
    saleDate: getSaleDateForCapRateComp(saleDate),
    residentialUnits,
    commercialUnits,
    yearBuilt,
    elevator: buildingType === 'elevator',
    capRate,
    gba: grossBuildingArea,
    borough: neighborhood,
    city: address.city,
    state: address.state,
    zip: address.postalCode,
    salePrice,
    coords: address.coords,
    saleStatus,
    status: salesComp.status,
    photo,
    sourceOfInformation: getCapRateSourceOfInformation(resourceInformation),
    sourceName: getCapRateSourceName(resourceInformation),
    sourceUrl: resourceInformation?.sources?.[0]?.url || null,
    propertyIdentifierType: address?.propertyIdentification?.propertyIdentifierType || null,
    propertyIdentifier: address?.propertyIdentification?.propertyIdentifier || null,
    grossLeasableArea,
    netRentableArea,
    netLeasableArea,
    condition,
    numberOfFloors,
    grantor: seller,
    grantee: buyer,
  }

  return capRateComp
}
