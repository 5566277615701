import React from 'react'
import { withStyles } from '@material-ui/core'
import { ReactSVG } from 'react-svg'

import LogoIcon from '../../../../../../../report/images/bowery_icon.svg'

const logoStyles = {
  root: {
    display: 'inline',
    verticalAlign: 'middle',
    '& div': {
      display: 'inline',
    },
  },
  logoIcon: {
    width: 29,
    height: 29,
    '& *': {
      fill: '#2F54EB',
    },
  },
}

export const Logo = withStyles(logoStyles)(({ classes }) => {
  return (
    <div className={classes.root}>
      <ReactSVG
        src={LogoIcon}
        beforeInjection={svg => {
          svg.classList.add(classes.logoIcon)
        }}
      />
    </div>
  )
})
