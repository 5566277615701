import React from 'react'
import { ReactSVG } from 'react-svg'

import { withStyles } from '@mui/styles'
import { Link } from 'react-router-dom'
import { compose } from 'recompose'

import LogoIcon from '../../../report/images/bowery_icon.svg'
import { SIDEBAR_WIDTH } from '../constants'
import layoutStyles from '../styles'

const styles = ({ palette }) => ({
  root: {
    minWidth: SIDEBAR_WIDTH,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderRight: layoutStyles.border,
    boxSizing: 'border-box',
  },
  logoIcon: {
    width: 45,
    height: 45,
    '& *': {
      fill: palette.primary.main,
    },
  },
})

const Logo = ({ classes }) => (
  <span className={classes.root}>
    <Link to="/reports">
      <ReactSVG
        src={LogoIcon}
        beforeInjection={svg => {
          svg.classList.add(classes.logoIcon)
        }}
      />
    </Link>
  </span>
)

export default compose(React.memo, withStyles(styles))(Logo)
