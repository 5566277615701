import React from 'react'
import PropTypes from 'prop-types'

import { Stack } from '@mui/material'
import { get } from 'lodash'

import { SUPPORTING_CAP_RATES_PATH } from 'shared/constants/report/keysAndDataPaths'

import wrapForm from '../../wrapForm'
import { TEMPLATE_TYPES } from '../../../constants/settings'

import BandOfInvestments from './BandOfInvestments'
import DebtCoverageRatio from './DebtCoverageRatio'
import IncomeCapitalizationIntroduction from './IncomeCapitalizationIntroduction'
import PersonalSurveys from './PersonalSurveys'
import SelectedLoanTerms from './SelectedLoanTerms'

import calculationsManager from './decorators'
import IncludeMethodsOfAnalyses from './IncludeMethodsOfAnalyses'

export const DATA_PATH = SUPPORTING_CAP_RATES_PATH

const SupportingCapRates = ({ form, isFreddieMacReport }) => (
  <Stack spacing={2}>
    <IncludeMethodsOfAnalyses />
    <IncomeCapitalizationIntroduction isFreddieMacReport={isFreddieMacReport} />
    <SelectedLoanTerms form={form} />
    <BandOfInvestments form={form} />
    {isFreddieMacReport && <DebtCoverageRatio form={form} />}
    <PersonalSurveys form={form} />
  </Stack>
)

const formOptions = {
  decorators: [calculationsManager],
  heading: 'Supporting Cap Rates',
}

SupportingCapRates.propTypes = {
  isFreddieMacReport: PropTypes.bool.isRequired,
}

export default wrapForm(
  DATA_PATH,
  formOptions,
  state => {
    const isFreddieMacReport = get(state, 'report.reportSettings.templateType') === TEMPLATE_TYPES.FREDDIE_MAC

    return {
      isFreddieMacReport,
    }
  },
  {}
)(SupportingCapRates)
