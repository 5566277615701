import { get, isEmpty } from 'lodash'

import { generateFloodHazardDiscussion } from '../../../../../client/src/report/forms/property/SiteDescription/floodHazardDiscussion'

export const mapDTO = formValues => {
  const floodHazardString = generateFloodHazardDiscussion(formValues)

  return {
    floodHazardString,
  }
}

export const mapDataModel = report => {
  const floodData = get(report, 'flood_data', {})
  let floodHazardString = ''
  if (!isEmpty(floodData)) {
    floodHazardString = generateFloodHazardDiscussion({ floodData })
  }
  return {
    floodHazardString,
  }
}
