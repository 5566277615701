import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import { withStyles } from '@material-ui/core/styles'

const styles = theme => ({
  row: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
  },
  headerRow: {
    boxSizing: 'border-box',
    fontWeight: 500,
    fontSize: 14,
    color: theme.palette.text.primary,
    boxShadow: '0px 0px 6px 0px rgba(0,0,0,0.45)',
  },
  subjectRow: {
    backgroundColor: theme.palette.primary[50],
  },
})

const SearchResultsRow = ({ children, isHeader, isSubject, classes, height, ...props }) => {
  return (
    <div
      className={classnames([
        classes.row,
        {
          [classes.subjectRow]: isSubject,
          [classes.headerRow]: isHeader,
        },
      ])}
      style={{ height }}
    >
      {children}
    </div>
  )
}

SearchResultsRow.propTypes = {
  isSubject: PropTypes.bool,
  isHeader: PropTypes.bool,
  height: PropTypes.number,
}

SearchResultsRow.defaultProps = {
  height: 48,
}

export default withStyles(styles)(SearchResultsRow)
