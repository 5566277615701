/*
 * See github issue here: https://github.com/PaulLeCam/react-leaflet/issues/255
 * Hopefully should be fixed in leaflet library soon
 */

import L from 'leaflet'

L.Icon.Default.imagePath = '.'
// OR
delete L.Icon.Default.prototype._getIconUrl

L.Icon.Default.mergeOptions({
  iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png'),
  iconUrl: require('leaflet/dist/images/marker-icon.png'),
  shadowUrl: require('leaflet/dist/images/marker-shadow.png'),
})
