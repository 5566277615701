import React from 'react'
import PropTypes from 'prop-types'

import { ClickAwayListener, Grow, MenuItem, MenuList, Paper, Popper } from '@mui/material'
import { withStyles } from '@mui/styles'

const styles = theme => ({
  root: {},
  menuList: {
    zIndex: 9999,
    backgroundColor: theme.palette.common.white,
    boxShadow: '0px 2px 8px rgba(0, 15, 68, 0.12)',
    borderRadius: 4,
    width: 200,
  },
  menuIcon: {
    cursor: 'pointer',
  },
  menuItem: {
    height: 36,
    fontSize: 14,
    fontFamily: 'Nunito Sans',
    color: theme.palette.text.main,
    boxSizing: 'border-box',
    '&:hover': {
      background: 'rgba(65, 96, 211, 0.08)',
      borderRadius: 4,
    },
  },
})

class PopupMenu extends React.PureComponent {
  anchorEl = React.createRef()

  state = {
    open: false,
  }

  get popperModifiers() {
    const { offsetX, offsetY } = this.props
    return [
      {
        name: 'offset',
        enabled: true,
        options: {
          offset: [offsetX, offsetY],
        },
      },
    ]
  }

  handleToggle = () => this.setState(state => ({ open: !state.open }))

  handleClose = event => {
    if (this.anchorEl.current.contains(event.target)) {
      return
    }

    this.setState({ open: false })
  }

  render() {
    const { classes, placement, renderMenuItem, renderMenuIcon, menuItems } = this.props
    const { open } = this.state

    return (
      <div className={classes.root}>
        <div className={classes.menuIcon} ref={this.anchorEl} aria-haspopup="true" onClick={this.handleToggle}>
          {renderMenuIcon()}
        </div>
        <Popper
          placement={placement}
          open={open}
          anchorEl={this.anchorEl.current}
          transition
          modifiers={this.popperModifiers}
        >
          {({ TransitionProps }) => (
            <Grow {...TransitionProps}>
              <Paper classes={{ root: classes.menuList }}>
                <ClickAwayListener onClickAway={this.handleClose}>
                  <MenuList disablePadding>
                    {menuItems.map((item, index) => (
                      <MenuItem
                        key={index}
                        disableGutters
                        onClick={this.handleClose}
                        classes={{ root: classes.menuItem }}
                      >
                        {renderMenuItem(item, index)}
                      </MenuItem>
                    ))}
                  </MenuList>
                </ClickAwayListener>
              </Paper>
            </Grow>
          )}
        </Popper>
      </div>
    )
  }
}

const StyledPopupMenu = withStyles(styles)(PopupMenu)

StyledPopupMenu.displayName = 'PopupMenu'

StyledPopupMenu.propTypes = {
  placement: PropTypes.string,
  renderMenuIcon: PropTypes.func.isRequired,
  renderMenuItem: PropTypes.func.isRequired,
  menuItems: PropTypes.arrayOf(PropTypes.any.isRequired).isRequired,
  offsetX: PropTypes.number,
  offsetY: PropTypes.number,
}

StyledPopupMenu.defaultProps = {
  placement: 'right-end',
  offsetX: 8,
  offsetY: 20,
}

export default StyledPopupMenu
