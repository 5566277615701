import { Transformation, Cloudinary } from 'cloudinary-core'

import {
  CLOUDINARY_REGEX,
  WIDTH_REGEX,
  ANGLE_REGEX,
  FULL_ROTATION,
  QUARTER_ROTATION,
  PUBLIC_ID_CLOUDINARY_REGEX,
} from '../constants/images'

export const cloudinary = new Cloudinary({ cloud_name: process.env.REACT_APP_CLOUDINARY_CLOUD_NAME })

export const getResizedImageUrl = ({ url = '', width }) => {
  const matches = url.match(CLOUDINARY_REGEX)
  if (!matches) {
    return url
  }

  const [, urlStart, transformations = '', urlEnd] = matches
  if (transformations.includes('c_crop')) {
    return `${urlStart}${transformations}/w_${width},h_${width}/${urlEnd}`
  }

  const [hasHeight] = transformations.match(WIDTH_REGEX) || []
  const newTransformations = !!hasHeight
    ? transformations.replace(WIDTH_REGEX, `w_${width}`)
    : `w_${width},${transformations}`

  return `${urlStart}${newTransformations}/${urlEnd}`
}

export const getRotatedImageUrl = ({ url }) => {
  const [, urlStart, transformations = '', urlEnd] = url.match(CLOUDINARY_REGEX)
  const [hasAngle, currentAngle = 0] = transformations.match(ANGLE_REGEX) || []
  const newAngle = (parseInt(currentAngle, 10) + QUARTER_ROTATION) % FULL_ROTATION
  const newTransformations = !!hasAngle
    ? transformations.replace(ANGLE_REGEX, `a_${newAngle}`)
    : `a_${newAngle},${transformations}`
  return `${urlStart}${newTransformations}/${urlEnd}`
}

const coordinatesCalculation = ({ angle, rotation, imageWidth, imageHeight, x, y, size }) => {
  const radian = (angle / 180) * Math.PI

  const hasInitialOrientation = rotation === 0 || rotation === 180
  const centerX = hasInitialOrientation ? imageWidth / 2 : imageHeight / 2
  const centerY = hasInitialOrientation ? imageHeight / 2 : imageWidth / 2
  //eslint-disable-next-line
  const d = Math.sqrt(centerX ** 2 + centerY ** 2)

  const angle_x = Math.acos(centerX / d)
  let xp, yp, finalX, finalY

  if (angle > 0) {
    xp = d * Math.cos(angle_x - radian) - centerX
    yp = d * Math.sin(angle_x + radian) - centerY
  } else {
    xp = d * Math.cos(angle_x + radian) - centerX
    yp = d * Math.sin(angle_x - radian) - centerY
  }

  if (hasInitialOrientation) {
    finalX = x + xp
    finalY = y + yp
  } else {
    finalX = centerX - size / 2 + (x - centerY + size / 2) + xp
    finalY = centerY - size / 2 + (y - centerX + size / 2) + yp
  }
  return [finalX, finalY]
}

export const getCroppedImage = ({ url, options }) => {
  const { angle: rotation, cropOptions, croppedArea, rotation: angle } = options
  const [, publicId] = url.match(PUBLIC_ID_CLOUDINARY_REGEX)
  const { width, height } = cropOptions
  const size = Math.max(width, height)

  const imageWidth = (width * 100) / croppedArea.width
  const imageHeight = (height * 100) / croppedArea.height

  const [x, y] = coordinatesCalculation({
    angle,
    rotation,
    x: cropOptions.x,
    y: cropOptions.y,
    size,
    imageWidth,
    imageHeight,
  })

  const transformations = Transformation.new()
    .angle(angle + rotation)
    .x(Math.round(x))
    .y(Math.round(y))
    .crop('crop')
    .radius('max')
    .width(size)
    .height(size)
    .fetchFormat('png')

  return cloudinary.url(publicId, transformations)
}

export const getOriginalImageUrl = ({ url }) => {
  const matches = url.match(PUBLIC_ID_CLOUDINARY_REGEX)
  if (!matches) {
    return url
  }
  const [, publicId] = matches
  return cloudinary.url(publicId, {})
}
