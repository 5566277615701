import React from 'react'
import PropTypes from 'prop-types'
import { FormHelperText, withStyles } from '@material-ui/core'
import Info from '@mui/icons-material/Info'

const styles = theme => ({
  root: {
    margin: theme.spacing.unit / 2,
    marginTop: 0,
  },
  helperTextContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  error: {
    color: theme.palette.error[600],
  },
  icon: {
    height: 14,
    width: 14,
    marginRight: 2,
  },
})

const FormHelperErrorTextComponent = ({ error, inErrorState, classes, ...props }) => {
  return inErrorState ? (
    <FormHelperText
      error={inErrorState}
      classes={{
        root: classes.root,
        error: classes.error,
      }}
    >
      <span className={classes.helperTextContainer}>
        <Info className={classes.icon} />
        {error}
      </span>
    </FormHelperText>
  ) : null
}

FormHelperErrorTextComponent.propTypes = {
  classes: PropTypes.object.isRequired,
}

const FormHelperErrorText = withStyles(styles)(FormHelperErrorTextComponent)

FormHelperErrorText.displayName = 'FormHelperErrorText'

FormHelperErrorText.propTypes = {
  error: PropTypes.string,
  inErrorState: PropTypes.bool,
}

FormHelperErrorText.defaultProps = {
  error: '',
  inErrorState: false,
}

export default FormHelperErrorText
