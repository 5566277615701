import React, { FC, useCallback, useMemo } from 'react'
import { ColumnDataTypeEnum, RowBasedTable } from '@bowery-valuation/ui-components'
import { Stack, Typography } from '@mui/material'
import { RowBasedTableColumn, getCustomColumnConfig } from 'client-shared/utils/rowBasedTable'
import { cloneDeep, get, noop } from 'lodash'

import { EXCLUSION_TABLE_IDS, LABELS } from 'shared/constants/insurableValue/mvs'

import { formatCurrencyFloat, formatPercentageString } from 'shared/utils/formatters/numberFormatters'

import { useForm } from 'react-final-form'

import ObjectID from 'bson-objectid'

import Link from 'client-shared/components/Link'

import { calculateExclusionTotals } from './helpers'

import TableStyles from './styles'

type ExclusionsTableProps = {
  form: any
}

const ExclusionsTable: FC<ExclusionsTableProps> = ({ form }) => {
  const formApi = useForm()
  const buildingComponents = get(formApi.getState().values, 'buildingComponents', [])
  const customExclusions = get(formApi.getState().values, 'customExclusions', [])

  const totals = useMemo(() => {
    return calculateExclusionTotals(buildingComponents, customExclusions)
  }, [buildingComponents, customExclusions])

  const handleAddExclusionLineItem = useCallback(() => {
    const newExclusion = {
      id: new ObjectID().toString(),
      label: 'Custom Exclusion',
    }
    formApi.mutators.push('customExclusions', newExclusion)
  }, [formApi])

  const columns = useMemo((): RowBasedTableColumn[] => {
    const columns: RowBasedTableColumn[] = []

    columns.push({
      name: 'label',
      label: 'Less Insurance Exclusions',
      type: ColumnDataTypeEnum.text,
      permanent: true,
      align: 'left',
      editable: (params: { data: { id: string } }) => {
        return params.data.id.startsWith(EXCLUSION_TABLE_IDS.customExclusion)
      },
      cellStyle: ({ value, data }: { value: any; data: any }) => {
        const { id } = data
        if (id === 'addExclusion') {
          return TableStyles.lineItemRow
        }
        return TableStyles.tableRow
      },
      cellRendererFramework: ({ value, data }) => {
        const { id, type } = data
        if (id === 'addExclusion') {
          const disableAddExclusion = buildingComponents.length === 0
          const { inputProps } = type
          return (
            <Link
              variant="text"
              size="small"
              sx={{
                textTransform: 'none',
                fontWeight: 400,
                textDecoration: 'underline',
                color: 'rgba(3, 24, 110, 1)',
                paddingLeft: 0,
                '&:hover': {
                  cursor: 'pointer',
                },
              }}
              onClick={inputProps.onClick}
              disable={disableAddExclusion}
            >
              {inputProps.label}
            </Link>
          )
        }
        return value ?? null
      },
    })

    for (const [index, buildingComponent] of buildingComponents.entries()) {
      columns.push({
        name: buildingComponent.id,
        id: new ObjectID().toString(),
        label: `Building Component ${index + 1}`,
        type: ColumnDataTypeEnum.text,
        permanent: true,
        align: 'right',
        editable: (params: { data: { id: string } }) => {
          const fieldName = params.data.id
          if (fieldName === EXCLUSION_TABLE_IDS.totalExclusionsValue) {
            return false
          }
          if (fieldName.startsWith(EXCLUSION_TABLE_IDS.customExclusion)) {
            return true
          }
          if (fieldName === EXCLUSION_TABLE_IDS.totalExclusionPercentageInput) {
            return customExclusions.length === 0
          }
          return false
        },
        cellStyle: ({ value, data }: { value: any; data: any }) => {
          const { id } = data
          if (id === 'addExclusion') {
            return TableStyles.lineItemRow
          }
          return TableStyles.tableRow
        },
      })
    }

    columns.push({
      name: 'totalValue',
      label: 'Total',
      type: ColumnDataTypeEnum.text,
      permanent: true,
      align: 'right',
      editable: false,
      cellStyle: ({ value, data }: { value: any; data: any }) => {
        const { id } = data
        if (id === 'addExclusion') {
          return TableStyles.lineItemRow
        }
        return TableStyles.tableRow
      },
    })

    return columns
  }, [buildingComponents, customExclusions.length])

  const rows = useMemo((): any[] => {
    const tableRows = []

    const getTotalRow = (
      rowId: string,
      type: string,
      formatter?: any,
      defaultValue?: any,
      summary: boolean = false
    ) => {
      const label = LABELS[rowId]
      const row: any = {
        readOnly: false,
        suppressMovable: true,
        permanent: false,
        type,
        label,
        totalValue: totals[rowId],
        id: rowId,
        rowDef: { hideAction: true, summary },
      }
      for (const buildingComponent of buildingComponents) {
        const cellValueId = buildingComponent.id
        let cellValue = get(buildingComponent, rowId, defaultValue)

        if (formatter) {
          cellValue = formatter(cellValue)
        }

        row[cellValueId] = cellValue
      }
      return row
    }

    for (const customExclusion of customExclusions) {
      const customExclusionRow: any = {
        readOnly: false,
        suppressMovable: true,
        permanent: false,
        type: ColumnDataTypeEnum.percent,
        label: customExclusion.label,
        id: `${EXCLUSION_TABLE_IDS.customExclusion}-${customExclusion.id}`,
        totalValue: totals[customExclusion.id],
        isEditableRow: true,
        action: 'Delete',
      }

      for (const buildingComponent of buildingComponents) {
        const cellValueId = buildingComponent.id
        const cellValue = formatPercentageString(customExclusion[cellValueId] || 0)
        customExclusionRow[cellValueId] = cellValue
      }

      tableRows.push(customExclusionRow)
    }

    tableRows.push({
      readOnly: false,
      suppressMovable: true,
      permanent: false,
      type: {
        rowType: 'button',
        inputProps: {
          onClick: handleAddExclusionLineItem,
          label: 'Add Exclusion',
        },
      },
      id: 'addExclusion',
      rowDef: { hideAction: true },
      isEditableRow: true,
    })

    const totalExclusionPercentageInputRow = getTotalRow(
      EXCLUSION_TABLE_IDS.totalExclusionPercentageInput,
      ColumnDataTypeEnum.numeric,
      formatPercentageString,
      0
    )
    tableRows.push(totalExclusionPercentageInputRow)

    const totalExclusionValueRow = getTotalRow(
      EXCLUSION_TABLE_IDS.totalExclusionsValue,
      ColumnDataTypeEnum.numeric,
      formatCurrencyFloat,
      0,
      true
    )
    tableRows.push(totalExclusionValueRow)

    const totalIndicatedInsurableReplacementCost = getTotalRow(
      EXCLUSION_TABLE_IDS.indicatedInsurableReplacementCost,
      ColumnDataTypeEnum.numeric,
      formatCurrencyFloat,
      0,
      true
    )
    tableRows.push(totalIndicatedInsurableReplacementCost)

    const roundedIndicatedInsurableReplacementCost = getTotalRow(
      EXCLUSION_TABLE_IDS.roundedIndicatedInsurableReplacementCost,
      ColumnDataTypeEnum.numeric,
      formatCurrencyFloat,
      0,
      true
    )
    tableRows.push(roundedIndicatedInsurableReplacementCost)

    return tableRows
  }, [buildingComponents, customExclusions, handleAddExclusionLineItem, totals])

  const handleRemoveExclusionLineItem = useCallback(
    (rowId: string) => {
      const exclusionId = rowId.split('-')[1]
      const exclusions = get(formApi.getState().values, 'customExclusions', [])
      const index = exclusions.findIndex((exclusion: any) => exclusion.id === exclusionId)
      formApi.mutators.remove('customExclusions', index)
    },
    [formApi]
  )

  const handleTotalExclusionRowUpdate = useCallback(
    (row: any) => {
      const fieldName = row.id
      const updatedBuildingComponents = cloneDeep(buildingComponents)
      for (const buildingComponent of updatedBuildingComponents) {
        const cellValue = get(row, buildingComponent.id, '0').replace('%', '')
        buildingComponent[fieldName] = cellValue / 100
      }
      form.change('buildingComponents', updatedBuildingComponents)
    },
    [buildingComponents, form]
  )

  const handleCustomExclusionRowUpdate = useCallback(
    (row: any) => {
      const exclusionId = row.id.split('-')[1]
      const label = row.label
      const exclusionIndex = customExclusions.findIndex((exclusion: any) => exclusion.id === exclusionId)
      const updatedExclusion: any = { label, id: exclusionId }
      for (const buildingComponent of buildingComponents) {
        const cellValue = row[buildingComponent.id].replace('%', '')
        updatedExclusion[buildingComponent.id] = cellValue / 100
      }
      formApi.mutators.update('customExclusions', exclusionIndex, updatedExclusion)
    },
    [buildingComponents, customExclusions, formApi.mutators]
  )

  const handleRowUpdate = useCallback(
    (row: any) => {
      const { id }: { id: string } = row

      const istotalExclusionPercentageInputEditable = customExclusions.length === 0
      const isCustomExclusionRow = id.startsWith(EXCLUSION_TABLE_IDS.customExclusion)

      if (istotalExclusionPercentageInputEditable) {
        return handleTotalExclusionRowUpdate(row)
      }
      if (isCustomExclusionRow) {
        return handleCustomExclusionRowUpdate(row)
      }
    },
    [customExclusions.length, handleCustomExclusionRowUpdate, handleTotalExclusionRowUpdate]
  )

  return (
    <Stack spacing={1}>
      <Typography variant="h6">Exclusion Table</Typography>
      <RowBasedTable
        id="exclusions-table"
        columns={columns}
        rows={rows}
        onRowUpdate={handleRowUpdate}
        onRowDelete={handleRemoveExclusionLineItem}
        onManyRowsUpdate={noop}
        getCustomColumnConfig={getCustomColumnConfig}
        hideIndexColumn
        onColumnDragEnd={noop}
        onRowsDragEnd={noop}
        onColumnDelete={noop}
        onColumnUpdate={noop}
      />
    </Stack>
  )
}

export default ExclusionsTable
