import React from 'react'
import { get } from 'lodash'

import EditIcon from '@mui/icons-material/Edit'
import ArrowUpIcon from '@mui/icons-material/KeyboardArrowUp'
import ArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import { Stack, IconButton, Tooltip } from '@mui/material'

import { ColumnDataTypeEnum } from '@bowery-valuation/ui-components'

import palette from 'client-shared/components/_mui5/_theme/palette'

import { getExpensePeriodLabel } from '../ExpenseForecast/ForecastItem'

export const useColumnDefinitions = ({
  expenseDataSource,
  columnValueFormatter,
  averageValueFormatter,
  onEditSubcategory = () => {},
  onCategoryViewChange = () => {},
}) =>
  React.useMemo(
    () => [
      {
        label: 'Operating Expenses',
        minWidth: 305,
        name: 'name',
        permanent: true,
        readOnly: true,
        rowManagedReadOnly: true,
        suppressMovable: true,
        type: ColumnDataTypeEnum.text,
        cellRendererFramework: ({ data, value }) => {
          let { parentId, hasSubcategories, isOpen } = data

          // To get actual data from reactive store, if `form` is provided. Needed for Strict mode
          if (data.form) {
            const targetCategory = get(data.form.getState().values, 'expenseCategories', []).find(
              ({ id }) => id === data.id
            )
            if (targetCategory) {
              ;({ parentId, hasSubcategories, isOpen } = targetCategory)
            }
          }

          if (parentId) {
            return (
              <Stack direction="row" alignItems="center" gap={1} sx={{ ml: '24px' }}>
                <Tooltip title="Edit subcategory" placement="left">
                  <IconButton
                    data-qa="edit-subcategory-icon"
                    variant="contained"
                    size="small"
                    sx={{
                      color: '#888888',
                      '&:hover': {
                        color: palette.primary.main,
                      },
                    }}
                    onClick={() => onEditSubcategory(data.id)}
                  >
                    <EditIcon
                      sx={{
                        fontSize: 17,
                      }}
                    />
                  </IconButton>
                </Tooltip>
                <span data-qa="subcategory-name">{value}</span>
              </Stack>
            )
          }

          if (hasSubcategories) {
            const ViewToggleIcon = isOpen ? ArrowUpIcon : ArrowDownIcon

            return (
              <Stack direction="row" alignItems="center" justifyContent="space-between">
                <span>{value}</span>
                <Tooltip title={`${isOpen ? 'Collapse' : 'Expend'} subcategories`} placement="right">
                  <IconButton
                    data-qa="change-category-view-state-icon"
                    variant="contained"
                    size="small"
                    sx={{
                      color: '#888888',
                    }}
                    onClick={() => onCategoryViewChange(data.id)}
                  >
                    <ViewToggleIcon
                      sx={{
                        fontSize: 22,
                      }}
                    />
                  </IconButton>
                </Tooltip>
              </Stack>
            )
          }

          return value
        },
      },
      item => {
        return {
          label: getExpensePeriodLabel(item.expenseYear, item.expensePeriod, expenseDataSource),
          name: item.key,
          readOnly: false,
          rowManagedReadOnly: true,
          rowManagedType: true,
          valueFormatter: columnValueFormatter,
          type: ColumnDataTypeEnum.money,
        }
      },
      {
        label: 'Average',
        name: 'average',
        permanent: true,
        readOnly: true,
        suppressMovable: true,
        rowManagedType: true,
        valueFormatter: averageValueFormatter,
        type: ColumnDataTypeEnum.money,
      },
    ],
    [averageValueFormatter, columnValueFormatter, expenseDataSource, onCategoryViewChange, onEditSubcategory]
  )
