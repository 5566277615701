import { RENOVATION_COST_TYPE } from '../../../../client/src/shared/constants/costs'
import { RENOVATIONS_ASSESSMENT_RATIO_DEFAULT } from '../../../../client/src/shared/constants/taxes'
import { INCOME_TYPES } from '../../../../client/src/shared/constants/income'

export const LABELS = {
  IMPLIED_TAXABLE_ASSESSED_VALUE: 'Taxable Assessed Value (Implied)',
  PROVIDED_TAXABLE_ASSESSED_VALUE: 'Taxable Assessed Value (Provided)',
  TAX_RATE: 'Tax Rate',
  GROSS_BUILDING_AREA: 'GBA',
  NUMBER_OF_UNITS: 'Number of Units',
  NET_RENOVATIONS: 'Net Renovations',
  ASSESSMENT_RATIO: 'Assessment Ratio',
  TAV: 'TAV',
  INCREASE_IN_TAV: 'Increase in TAV',
  CURRENT_TAV: 'Current TAV',
  INCOME: 'Income',
  INCOME_TAX_RATE: 'Tax Liability Ratio',
  PROJECTED_MARKET_VALUE: 'Estimated Market Value',
  EQUALIZATION_RATIO: 'Equalization Ratio',
  TAX_LIABILITY_PSF: 'Tax Liability (PSF)',
  TAX_LIABILITY_PER_UNIT: 'Tax Liability (Per Unit)',
  TAX_LIABILITY_TOTAL: 'Tax Liability (Total)',
}

export const BASIS_TYPES = {
  PER_UNIT: 'Per Unit',
  PSF: 'PSF',
}

export const BASIS_LABELS = {
  TAXES_PER_UNIT: 'Taxes/Unit',
  TAXES_PSF: 'Taxes PSF',
  SQUARE_FEET: 'Square Feet',
  NUMBER_OF_UNITS: 'Number of Units',
  UNITS: 'Units',
  PER_SQUARE_FOOT: '/ square foot',
  PER_UNIT: '/ unit',
  ASSESSED_PSF: 'Assessed PSF',
  ASSESSED_PER_UNIT: 'Assessed Per Unit',
}

export const PROJECTED_LIABILITY_DEFAULT = {
  includedInExport: false,
  basis: BASIS_TYPES.PSF,
  concludedLiabilityPerBasis: 0,
  overrideTaxClass: false,
  taxClass: null,
  taxRate: null,
  taxLiability: 0,
  opinion: {
    taxableAssessedValue: 0,
    includedInExport: false,
  },
  percentOfRenovations: {
    renovationCostType: RENOVATION_COST_TYPE.TOTAL,
    netRenovationCost: 0,
    assessmentRatio: RENOVATIONS_ASSESSMENT_RATIO_DEFAULT,
    taxableAssessedValue: 0,
    includedInExport: false,
  },
  percentOfIncome: {
    incomeType: INCOME_TYPES.EGI,
    income: 0,
    liabilityRatio: 0,
    taxableAssessedValue: 0,
    includedInExport: false,
  },
  equalization: {
    marketValue: 0,
    equalizationRatio: 0,
    taxableAssessedValue: 0,
    includedInExport: false,
  },
}

export const INCOME_TYPE_NAMES = {
  EGI: 'EGI',
  PGI: 'PGI',
}
