import { handleActions } from 'redux-actions'

import { trackAnalyticsData } from './actions'

const initialState = {
  metaData: null,
}

export const reducer = handleActions(
  {
    [trackAnalyticsData](state, { payload }) {
      return {
        ...state,
        metaData: {
          ...payload,
        },
      }
    },
  },
  initialState
)
