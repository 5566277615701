/*
  PLEASE DO NOT EDIT THIS FILE
  If you need to change something, speak to Richard or Madeline
*/

/*
 Date must be an array of arrays. If data is empty or [], table doesn't display, a row should be added and hidden.
 */

import { HotTable } from '@handsontable/react'
import Handsontable from 'handsontable'
import React from 'react'

import PropTypes from 'prop-types'
import { isEmpty } from 'lodash'

import 'handsontable/dist/handsontable.full.css'

import './styles.css'
import { withStyles } from '@material-ui/core'

const HOT_TABLE_KEY = '4077a-953f7-bc503-c4728-d9745'
export const DEFAULT_ROW_HEIGHT = 39
export const SCROLL_BAR_HEIGHT = 16
const HEADER_ROW_EXTRA_HEIGHT = 10

const styles = {
  tableClassName: {
    '& .ht_master.handsontable': {
      overflow: 'auto !important',
    },
    '& .handsontableEditor.autocompleteEditor': {
      '& .ht_master': {
        overflow: 'visible !important',
      },
      '& .wtHolder': {
        height: 'auto !important',
        paddingBottom: 2,
      },
      height: 'auto !important',
    },
    overflow: 'visible !important',
    position: 'relative',
  },
}

class Table extends React.PureComponent {
  setTableRef = ref => {
    return ref
  }

  get numberOfRows() {
    return this.props.data.length + 1 + Number(this.props.minSpareRows || 0)
  }

  render() {
    const {
      afterGetRowHeader,
      beforeChange,
      beforePaste,
      beforeRowMove,
      bindRowsWithHeaders,
      cells,
      classes,
      colHeaders,
      columnSummary,
      columns,
      contextMenu,
      data,
      fixedRowsBottom,
      fixedRowsTop,
      formulas,
      height,
      manualRowMove,
      maxHeight,
      mergeCells,
      rowHeaderWidth,
      rowHeaders,
      setTableRef = this.setTableRef,
      stretchH,
      width,
    } = this.props

    if (manualRowMove) {
      Handsontable.hooks.add('beforeRowMove', beforeRowMove)
    }
    const defaultHeight =
      Math.min(this.numberOfRows * DEFAULT_ROW_HEIGHT + HEADER_ROW_EXTRA_HEIGHT, maxHeight) + SCROLL_BAR_HEIGHT + 1
    return (
      <HotTable
        {...this.props}
        afterGetRowHeader={afterGetRowHeader}
        autoWrapCol={false}
        autoWrapRow={false}
        beforeChange={beforeChange}
        beforePaste={beforePaste}
        bindRowsWithHeaders={bindRowsWithHeaders}
        cells={cells}
        className={classes.tableClassName}
        colHeaders={colHeaders}
        columnSummary={columnSummary}
        columns={columns}
        contextMenu={contextMenu}
        data={isEmpty(data) ? [[]] : data}
        fixedRowsBottom={defaultHeight < maxHeight ? 0 : fixedRowsBottom}
        fixedRowsTop={fixedRowsTop || 0}
        formulas={formulas}
        height={height || `${defaultHeight}px`}
        hiddenRows={{ rows: [isEmpty(data) && 0] }}
        licenseKey={HOT_TABLE_KEY}
        manualRowMove={manualRowMove}
        mergeCells={mergeCells}
        readOnlyCellClassName="readOnly"
        ref={ref => setTableRef(ref)}
        rowHeaderWidth={rowHeaderWidth}
        rowHeaders={rowHeaders}
        rowHeight={DEFAULT_ROW_HEIGHT - 1}
        stretchH={stretchH}
        width={width}
      />
    )
  }
}

Table.propTypes = {
  afterGetRowHeader: PropTypes.func,
  beforeChange: PropTypes.func.isRequired,
  beforePaste: PropTypes.func,
  beforeRowMove: PropTypes.func,
  bindRowsWithHeaders: PropTypes.bool,
  cells: PropTypes.func,
  colHeaders: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.string), PropTypes.func]),
  columns: PropTypes.array,
  contextMenu: PropTypes.bool,
  data: PropTypes.array.isRequired,
  fixedRowsTop: PropTypes.number,
  formulas: PropTypes.bool,
  height: PropTypes.string,
  manualRowMove: PropTypes.bool,
  maxHeight: PropTypes.number,
  mergeCells: PropTypes.array,
  rowHeaderWidth: PropTypes.number,
  rowHeaders: PropTypes.array,
  setTableRef: PropTypes.func,
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
}

Table.defaultProps = {
  bindRowsWithHeaders: false,
  contextMenu: false,
  data: [[]],
  fixedRowsBottom: 0,
  formulas: false,
  manualRowMove: false,
  maxHeight: 576,
  mergeCells: [],
}

export default withStyles(styles)(Table)
