import {
  ANCHOR_APPRAISERS,
  ANCHOR_CLIENTS,
  ANCHOR_PROPERTY_DESCRIPTION,
  ANCHOR_PROPERTY_HISTORY,
  ANCHOR_PROPERTY_PHOTOS,
  ANCHOR_REPORT_DETAILS,
  ANCHOR_SITE_DETAILS,
} from 'shared/constants/report/dataCollections'

import {
  REVIEW_AND_EXPORT_KEY,
  GENERIC_SUBSECTION_KEY,
  REPORT_KEY,
  POTENTIAL_GROSS_INCOME_KEY,
  TAX_INFORMATION_KEY,
  EXPENSE_HISTORY_KEY,
  COMPARABLE_EXPENSES_KEY,
  EXPENSE_FORECAST_KEY,
  PRO_FORMA_KEY,
  SUPPORTING_CAP_RATES_KEY,
  CAP_RATE_CONCLUSION_KEY,
  DATA_COLLECTIONS_KEY,
  JOB_DETAILS_KEY,
  SUBJECT_PROPERTY_KEY,
} from 'shared/constants/report/keysAndDataPaths'

import ReviewAndExport from '../../forms/ReviewAndExport'
import GenericSubsection from '../../forms/genericSubsection'

import SubjectPropertyContainer from '../../forms/dataCollections/SubjectProperty'
import JobDetailsContainer from '../../forms/dataCollections/jobDetails'

import ExpenseForecast from '../../forms/income/ExpenseForecast'
import SupportingCapRates from '../../forms/income/SupportingCapRates'
import CapRateConclusion from '../../forms/income/CapRateConclusion'
import PotentialGrossIncome from '../../forms/income/PotentialGrossIncome'
import TaxInformation from '../../forms/income/TaxInformation'
import ExpenseHistory from '../../forms/income/ExpenseHistory'
import ComparableExpenses from '../../forms/income/ComparableExpenses'
import ProForma from '../../forms/income/ProForma'

import ReportInformation from './sections/reportInformation'
import PropertyInformation from './sections/propertyInformation'
import IncomeApproach from './sections/incomeApproach'
import ResidentialIncome from './sections/residentialIncome'
import CommercialIncome from './sections/commercialIncome'
import MiscellaneousIncome from './sections/miscellaneousIncome'
import Final from './sections/final'
import PreviewAndEdit from './sections/previewAndEdit'
import CostApproach from './sections/costApproach'
import SalesApproach from './sections/salesApproach'

export default [
  {
    key: REVIEW_AND_EXPORT_KEY,
    name: 'Settings & Export',
    formComponent: ReviewAndExport,
  },
  {
    key: GENERIC_SUBSECTION_KEY,
    name: 'Content Reuse',
    formComponent: GenericSubsection,
  },
  {
    key: REPORT_KEY,
    name: 'Report',
  },
  {
    key: 'paymentInfo',
    name: 'Fee & Due Date',
  },

  ...ReportInformation,
  ...PropertyInformation,
  ...IncomeApproach,
  ...ResidentialIncome,
  ...CommercialIncome,
  ...MiscellaneousIncome,
  ...Final,
  {
    key: POTENTIAL_GROSS_INCOME_KEY,
    name: 'Potential Gross Income',
    formComponent: PotentialGrossIncome,
  },
  {
    key: TAX_INFORMATION_KEY,
    name: 'Tax Info',
    formComponent: TaxInformation,
  },
  {
    key: EXPENSE_HISTORY_KEY,
    name: 'Expense History',
    formComponent: ExpenseHistory,
  },
  {
    key: COMPARABLE_EXPENSES_KEY,
    name: 'Comparable Expenses',
    formComponent: ComparableExpenses,
  },
  {
    key: EXPENSE_FORECAST_KEY,
    name: 'Expense Forecast',
    formComponent: ExpenseForecast,
  },
  {
    key: PRO_FORMA_KEY,
    name: 'Pro Forma',
    formComponent: ProForma,
  },
  {
    key: SUPPORTING_CAP_RATES_KEY,
    name: 'Supporting Cap Rates',
    formComponent: SupportingCapRates,
  },
  {
    key: CAP_RATE_CONCLUSION_KEY,
    name: 'Cap Rate Conclusion',
    formComponent: CapRateConclusion,
  },
  {
    key: DATA_COLLECTIONS_KEY,
    name: 'High level data of Job Details & Property Details',
  },
  { key: JOB_DETAILS_KEY, name: 'Job Details Data', formComponent: JobDetailsContainer },
  {
    key: 'reportDetails',
    name: 'Report Details',
    formComponent: JobDetailsContainer,
    link: 'job-details',
    anchor: '#' + ANCHOR_REPORT_DETAILS,
  },
  {
    key: 'jobDetailsAppraisers',
    name: 'Appraisers',
    formComponent: JobDetailsContainer,
    link: 'job-details',
    anchor: '#' + ANCHOR_APPRAISERS,
  },
  {
    key: 'jobDetailsClients',
    name: 'Clients',
    formComponent: JobDetailsContainer,
    link: 'job-details',
    anchor: '#' + ANCHOR_CLIENTS,
  },
  {
    key: SUBJECT_PROPERTY_KEY,
    name: 'Subject Property Data',
    formComponent: SubjectPropertyContainer,
  },
  {
    key: 'siteDetails',
    name: 'Site Details',
    formComponent: SubjectPropertyContainer,
    link: 'subject-property',
    anchor: '#' + ANCHOR_SITE_DETAILS,
  },
  {
    key: 'propertyDescription',
    name: 'Property Description',
    formComponent: SubjectPropertyContainer,
    link: 'subject-property',
    anchor: '#' + ANCHOR_PROPERTY_DESCRIPTION,
  },
  {
    key: 'subjectPropertyHistory',
    name: 'Property History',
    formComponent: SubjectPropertyContainer,
    link: 'subject-property',
    anchor: '#' + ANCHOR_PROPERTY_HISTORY,
  },
  {
    key: 'subjectPropertyPhotos',
    name: 'Property Photos',
    formComponent: SubjectPropertyContainer,
    link: 'subject-property',
    anchor: '#' + ANCHOR_PROPERTY_PHOTOS,
  },
  ...Final,
  ...PreviewAndEdit,
  ...CostApproach,
  ...SalesApproach,
]
