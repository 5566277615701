import React from 'react'
import PropTypes from 'prop-types'

import { Paper } from '@mui/material'

import { generateIncomeCapitalizationIntroduction } from 'shared/utils/textGeneration/incomeApproach/supportingCapRates'
import { GeneratedComment } from 'client-shared/components'

const IncomeCapitalizationIntroduction = ({ isFreddieMacReport }) => {
  const getIncomeCapitalizationIntroduction = React.useCallback(
    ({ bandOfInvestment, personalSurveys }) => {
      return generateIncomeCapitalizationIntroduction({
        bandOfInvestment,
        isFreddieMac: isFreddieMacReport,
        personalSurveys,
      })
    },
    [isFreddieMacReport]
  )

  return (
    <Paper>
      <GeneratedComment
        dataPath="incomeCapitalizationIntroduction"
        getGeneratedText={getIncomeCapitalizationIntroduction}
        isDynamicContent
        label="Income Capitalization Introduction"
        title="Generated Commentary"
        tooltipText="The following text and generated commentary will appear in the Income Approach of your report."
      />
    </Paper>
  )
}

IncomeCapitalizationIntroduction.propTypes = {
  isFreddieMacReport: PropTypes.bool.isRequired,
}

export default IncomeCapitalizationIntroduction
