import { get } from 'lodash'

import { formatCurrencyFloat } from 'shared/utils/formatters/numberFormatters'

const mapData = (
  remainingCostType,
  totalConstructionBudget,
  totalCostsToDate,
  totalCostsToComplete,
  asCompleteYear
) => {
  return {
    remainingCostType,
    totalConstructionBudget: formatCurrencyFloat(totalConstructionBudget),
    totalCostsToDate: formatCurrencyFloat(totalCostsToDate),
    totalCostsToComplete: formatCurrencyFloat(totalCostsToComplete),
    asCompleteYear,
  }
}

export const mapDTO = formValues => {
  const remainingCostType = get(formValues, 'subjectBudget.remainingCostType')
  const totalConstructionBudget = get(formValues, 'subjectBudget.constructionBudget.totalCosts', 0)
  const totalCostsToDate = get(formValues, 'subjectBudget.costsToDate.totalCosts', 0)
  const totalCostsToComplete = get(formValues, 'subjectBudget.costsToComplete.totalCosts', 0)
  const asCompleteYear = get(formValues, 'asCompleteYear')

  return mapData(remainingCostType, totalConstructionBudget, totalCostsToDate, totalCostsToComplete, asCompleteYear)
}

export const mapDataModel = report => {
  const remainingCostType = get(report, 'costApproach.subjectBudget.remainingCostType')
  const totalConstructionBudget = get(report, 'costApproach.subjectBudget.constructionBudget.totalCosts', 0)
  const totalCostsToDate = get(report, 'costApproach.subjectBudget.costsToDate.totalCosts', 0)
  const totalCostsToComplete = get(report, 'costApproach.subjectBudget.costsToComplete.totalCosts', 0)
  const asCompleteDateOfValue = get(report, 'costApproach.conclusion.asCompleteDateOfValue')
  const asCompleteYear = asCompleteDateOfValue?.getFullYear() || null

  return mapData(remainingCostType, totalConstructionBudget, totalCostsToDate, totalCostsToComplete, asCompleteYear)
}
