import { get } from 'lodash'
import BoweryDate from '@bowery-valuation/bowery-date'

import { SaleStatus } from 'shared/constants/report/sales/salesStatus'

import { checkIfLatestSalesCompVersion } from '../../../../shared/utils/compPlex.gql'

export const checkIfCompsAreLatestVersion = async selectedComps => {
  const updates = selectedComps.map(async salesComp => {
    const { salesTransactionId, salesTransactionVersion } = salesComp
    const { isLatestVersion, deletedAt } = salesTransactionVersion
      ? await checkIfLatestSalesCompVersion({
          version: salesTransactionVersion,
          salesTransactionId,
        })
      : { isLatestVersion: false }

    return {
      ...salesComp,
      isLatestVersion,
      deletedAt,
    }
  })

  const updatedComps = await Promise.all(updates)
  return updatedComps
}

export const getFormattedSaleDate = (saleDate, saleStatus, fillerIcon) => {
  switch (saleStatus) {
    case SaleStatus.UNDER_CONTRACT:
      return 'In Contract'
    case SaleStatus.LISTING:
      return 'Listing'
    default:
      return saleDate ? new BoweryDate(saleDate).formatShortDateWithZeroes() : fillerIcon
  }
}

export const getSubjectProperty = state => {
  const propertyInformation = get(state, 'report.reportData.propertyInformation')
  const propertySummary = get(propertyInformation, 'propertySummary', {})

  const type = get(state, 'report.reportData.propertyType')

  return {
    type,
    streetAddress: propertySummary.streetAddress,
    address: propertySummary.streetAddress,
    city: propertySummary.city,
    state: propertySummary.state,
    zip: get(state, 'report.reportData.zip'),
    yearBuilt: propertySummary.yearBuilt,
    residentialUnits: propertySummary.residentialUnitCount,
    gba: propertySummary.grossBuildingArea,
  }
}
