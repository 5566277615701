import React from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '@mui/styles'

export const DataDisplayProps = {
  title: PropTypes.any,
  data: PropTypes.any,
  unit: PropTypes.any,
}
const styles = theme => ({
  title: {
    fontFamily: 'Nunito Sans',
    fontSize: '14px',
    lineHeight: '24px',
    fontWeight: 700,
    color: 'rgba(34, 34, 34, 0.87)',
  },
  data: {
    fontFamily: 'Nunito Sans',
    fontSize: '14px',
    lineHeight: '22px',
    color: 'rgba(34, 34, 34, 0.87)',
  },
})

class DataDisplay extends React.PureComponent {
  render() {
    const { title, data, unit, classes } = this.props

    return (
      <div>
        <span className={classes.title}>{title}</span>
        <div className={classes.data}>
          {data} {data ? unit : <span>&nbsp;</span>}
        </div>
      </div>
    )
  }
}

export default withStyles(styles)(DataDisplay)
