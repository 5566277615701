import React from 'react'

import { Field } from 'react-final-form'
import { isArray } from 'lodash'

const composeValidators =
  (...validators) =>
  (...args) =>
    validators.reduce((error, validator) => error || validator(...args), undefined)

const useValidators = validate =>
  React.useMemo(() => (isArray(validate) ? composeValidators(...validate) : validate), [validate])

const defaultNumberParse = value => {
  const parsed = Number.parseFloat(value.replace(/[^0-9-.]/g, ''))
  return Number.isNaN(parsed) ? null : parsed
}

const withField =
  Component =>
  ({ initialValue, name, parse, validate, ...props }) => {
    const validateCombined = useValidators(validate)

    return (
      <Field
        {...{ initialValue, name, parse, validate: validateCombined }}
        render={({ input, meta }) => <Component {...{ ...props, input, meta }} />}
      />
    )
  }

export { defaultNumberParse, withField }
