import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import { get, startCase } from 'lodash'
import { compose } from 'redux'

import { IconButton, Table, TableCell, TableBody, TableHead, TableRow, Typography } from '@material-ui/core'
import OpenIcon from '@mui/icons-material/OpenInNewRounded'
import { TabContext, TabList, TabPanel } from '@mui/lab'
import { Grid, Paper, Tab } from '@mui/material'
import { withFeatureFlag } from '@bowery-valuation/feature-flagger-client'

import { HIGHEST_AND_BEST_USE_PATH } from 'shared/constants/report/keysAndDataPaths'
import { USE_COMMERCIAL_SUBTYPE } from 'shared/constants/featureFlags'

import { getZonesAllowableUses } from 'shared/utils/report/propertyInformation/zoning'

import { PROPERTY_USES } from 'shared/constants/property/zoning'

import { buildSlateWriteup } from '../../../../../../../shared/utils/textGeneration/writeupBuilder'

import wrapForm from '../../../wrapForm'
import { keyValuePairsToCheckboxValues } from '../../../../../shared/utils/ui/checkboxHelper'
import { formatInt, formatFloat } from '../../../../../shared/utils/numberFormatters'
import { zoningFloorArea as zoningFloorAreaCalculator } from '../../../../../shared/utils/calculations'
import { getCommercialOverlays } from '../../../../../shared/utils/zoningHelper'

import { Tooltip, GeneratedComment, CheckboxWithLabel, MultipleDropDown } from '../../../../../shared/components'
import { SITE_AREA_SF_UNIT_OF_MEASURE } from '../../../../../../../shared/constants/report'

import * as shared from './shared'

const DATA_PATH = HIGHEST_AND_BEST_USE_PATH
class HighestAndBestUse extends React.PureComponent {
  static propTypes = {
    allowableUses: PropTypes.arrayOf(PropTypes.string).isRequired,
    siteArea: PropTypes.number.isRequired,
    siteAreaMeasure: PropTypes.string.isRequired,
    propertyCondition: PropTypes.object.isRequired,
    streetName: PropTypes.string.isRequired,
    currentBuildingDescription: PropTypes.string.isRequired,
    prospectiveBuildingDescription: PropTypes.string.isRequired,
    floorAreaRatio: PropTypes.string.isRequired,
    zoningFloorArea: PropTypes.number.isRequired,
    maxBuildableArea: PropTypes.number.isRequired,
    grossBuildingArea: PropTypes.number.isRequired,
    commercialOverlays: PropTypes.arrayOf(PropTypes.string).isRequired,
    isComplying: PropTypes.bool.isRequired,
    isConformingWithAllowableUses: PropTypes.bool.isRequired,
    isAsComplete: PropTypes.bool.isRequired,
    propertyType: PropTypes.string.isRequired,
    propertySubtype: PropTypes.string,
  }

  state = {
    selectedTab: 'legally',
  }

  renderLegally = () => {
    const { classes, form, floorAreaRatio, maxBuildableArea, siteArea, siteAreaMeasure, commercialOverlays } =
      this.props
    const {
      zones,
      geographySpecific: { preclusionsExist },
    } = form.values
    console.log('this.props: ', this.props)
    const zonesFormatted = zones.join(', ')
    const allowableUses = getZonesAllowableUses(zones)
    if (commercialOverlays.length && !allowableUses.includes(PROPERTY_USES.COMMERCIAL)) {
      allowableUses.push(PROPERTY_USES.COMMERCIAL)
    }
    const allowableUsesFormatted = allowableUses.map(startCase).join(', ')
    const siteAreaFormatted = siteAreaMeasure === SITE_AREA_SF_UNIT_OF_MEASURE ? formatInt(siteArea) : siteArea
    const maxBuildableAreaFormatted = formatInt(maxBuildableArea)

    return (
      <Paper className={classes.paper} data-qa="legally-permissible-section">
        <Typography variant="h6" className={classes.sectionTitle}>
          Legally Permissible
        </Typography>
        <div className={classes.tableTitleContainer}>
          <Typography variant="subtitle1">Zoning Information</Typography>
          <Tooltip text="Go To Zoning" placement="top">
            <Link to="./zoning">
              <IconButton className={classes.icon}>
                <OpenIcon fontSize="small" />
              </IconButton>
            </Link>
          </Tooltip>
        </div>
        <div className={classes.table}>
          <Table padding="dense" data-qa="zoning-information-table">
            <TableHead>
              <TableRow>
                <TableCell>Zone</TableCell>
                <TableCell>Allowable Uses</TableCell>
                <TableCell align="right">As of Right FAR</TableCell>
                <TableCell align="right">Site Area</TableCell>
                <TableCell align="right">Max Buildable Area</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow>
                <TableCell data-qa="zones">{zonesFormatted || '-'}</TableCell>
                <TableCell data-qa="allowable-uses">{allowableUsesFormatted || '-'}</TableCell>
                <TableCell data-qa="floor-area-ratio" align="right">
                  {floorAreaRatio || '-'}
                </TableCell>
                <TableCell data-qa="site-area" align="right">
                  {siteAreaFormatted || '-'}
                </TableCell>
                <TableCell data-qa="zoning-floor-area" align="right">
                  {maxBuildableAreaFormatted || '-'}
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </div>

        <CheckboxWithLabel
          name="geographySpecific.preclusionsExist"
          label="There are preclusions that could hinder development."
        />
        {preclusionsExist && (
          <MultipleDropDown
            name="geographySpecific.preclusions"
            label="What could preclude development?"
            placeholder="Select all preclusions that apply"
            items={keyValuePairsToCheckboxValues(shared.PRECLUSIONS, 'value')}
            itemLabelSelector={value => shared.PRECLUSIONS[value]}
          />
        )}
        <GeneratedComment
          dataPath="commentaries.legallyPermissibleAsVacant"
          getGeneratedText={() => shared.generateLegallyPermissibleAsVacantCommentary(this.props)}
          isDynamicContent
          title="Generated Commentary"
          label="As Vacant Discussion"
          tooltipText={shared.DEFAULT_TOOLTIP_TEXT}
        />

        <GeneratedComment
          dataPath="commentaries.legallyPermissibleAsImproved"
          getGeneratedText={() => shared.generateLegallyPermissibleAsImprovedCommentary(this.props)}
          isDynamicContent
          title="Generated Commentary"
          label="As Improved Discussion"
          tooltipText={shared.DEFAULT_TOOLTIP_TEXT}
        />
      </Paper>
    )
  }

  render() {
    const { classes } = this.props
    const { selectedTab } = this.state

    return (
      <React.Fragment>
        <TabContext value={selectedTab}>
          <TabList
            onChange={(event, newTab) => {
              this.setState({ selectedTab: newTab })
            }}
            className={classes.tabs}
          >
            <Tab label="Legally" value="legally" data-qa="legally-tab" />
            <Tab label="Physically" value="physically" data-qa="physically-tab" />
            <Tab label="Financially" value="financially" data-qa="financially-tab" />
            <Tab label="Highest Use" value="highestUse" data-qa="highestUse-tab" />
            <Tab label="Probable Buyer" value="probableBuyer" data-qa="probableBuyer-tab" />
          </TabList>
          <Grid container>
            <Grid item sm={7}>
              <TabPanel sx={{ padding: 0 }} value="legally">
                {this.renderLegally()}
              </TabPanel>
              <TabPanel sx={{ padding: 0 }} value="physically">
                {shared.renderPhysically(this.props)}
              </TabPanel>
              <TabPanel sx={{ padding: 0 }} value="financially">
                {shared.renderFinancially(this.props)}
              </TabPanel>
              <TabPanel sx={{ padding: 0 }} value="highestUse">
                {shared.renderHighestUse(this.props)}
              </TabPanel>
              <TabPanel sx={{ padding: 0 }} value="probableBuyer">
                {shared.renderProbableBuyer(this.props)}
              </TabPanel>
            </Grid>
          </Grid>
        </TabContext>
      </React.Fragment>
    )
  }
}

export default compose(
  wrapForm(
    DATA_PATH,
    {
      styles: shared.styles,
      heading: shared.HEADING,
      initialValues: shared.INITIAL_VALUES,
    },
    state => {
      const propertyInformation = get(state, 'report.reportData.propertyInformation')
      const propertySummary = get(propertyInformation, 'propertySummary')
      const propertyType = get(state, 'report.reportData.propertyType')
      const propertySubtype = get(propertySummary, 'propertySubtype')
      console.log('propertyInformation: ', propertyInformation)
      const isAsComplete = get(state, 'report.reportSettings.valueAsComplete')
      const propertyCondition = get(propertyInformation, 'generalPropertyDescription.generalCondition')
      const asStabilizedPropertyCondition = get(
        propertyInformation,
        'generalPropertyDescription.generalAsStabilizedCondition'
      )
      const streetName = get(propertySummary, 'streetName')
      const isConformingWithAllowableUses = get(propertyInformation, 'zoning.isConformingWithAllowableUses')
      const isComplying = get(propertyInformation, 'zoning.isComplyingWithRegardToBulk')

      const zoningInfo = get(propertyInformation, 'zoning', {})
      const mechanicalDeductions = get(zoningInfo, 'mechanicalDeductions', 0)
      const grossBuildingArea = get(zoningInfo, 'grossBuildingArea', 0)
      const siteArea = get(propertySummary, 'siteArea', 0)
      const siteAreaMeasure = get(propertySummary, 'siteAreaMeasure', SITE_AREA_SF_UNIT_OF_MEASURE)
      const floorAreaRatio = formatFloat(get(zoningInfo, 'floorAreaRatio', 0))
      const commercialOverlays = getCommercialOverlays(zoningInfo)
      const zoningFloorArea = zoningFloorAreaCalculator(grossBuildingArea, mechanicalDeductions)
      const maxBuildableArea = siteArea * floorAreaRatio
      const zones = get(state, 'report.reportData.final.highestAndBestUse.zones')

      let buildingDescriptionCommentary = buildSlateWriteup(get(propertySummary, 'buildingDescription')).join(' ') || ''
      let prospectiveBuildingDescriptionCommentary = ''
      if (isAsComplete) {
        buildingDescriptionCommentary =
          buildSlateWriteup(get(propertySummary, 'current.buildingDescription')).join(' ') || ''
        prospectiveBuildingDescriptionCommentary =
          buildSlateWriteup(get(propertySummary, 'buildingDescription')).join(' ') || ''
      }
      const currentBuildingDescription = `${buildingDescriptionCommentary}`.trim()
      const prospectiveBuildingDescription = `${prospectiveBuildingDescriptionCommentary}`.trim()

      return {
        allowableUses: getZonesAllowableUses(zones),
        siteArea,
        siteAreaMeasure,
        propertyCondition,
        asStabilizedPropertyCondition,
        streetName,
        currentBuildingDescription,
        prospectiveBuildingDescription,
        floorAreaRatio,
        zoningFloorArea,
        maxBuildableArea,
        grossBuildingArea,
        commercialOverlays,
        isComplying,
        isConformingWithAllowableUses,
        isAsComplete,
        propertyType,
        propertySubtype,
      }
    }
  ),
  withFeatureFlag(USE_COMMERCIAL_SUBTYPE, 'useCommercialSubtype')
)(HighestAndBestUse)
