import React, { useCallback, useEffect, useState } from 'react'

import { Box, CircularProgress } from '@mui/material'

import { debounce } from 'lodash'

// position 'fixed' should be chosen for Layout, in case of scrolling backdrop will be on the whole page
// pay attention to zIndex, it used to be 1000 for all cases except Layout

const LoadingWithDelay = ({ isLoading, delay = 300, position = 'absolute' }) => {
  const [loading, setLoading] = useState(false)
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const loaderHandler = useCallback(debounce(setLoading, delay), [])

  useEffect(() => {
    !isLoading && setLoading(false)
    loaderHandler(isLoading)
  }, [isLoading, loaderHandler])

  return (
    <>
      {loading && (
        <Box
          sx={{
            backgroundColor: 'rgba(255, 255, 255, 0.6)',
            height: '100%',
            left: 0,
            minHeight: 40,
            position,
            top: 0,
            width: '100%',
            zIndex: 5000,
          }}
          data-qa="loading-modal"
        >
          <CircularProgress
            size={40}
            sx={{
              position,
              top: 'calc(50% - 20px)',
              left: 'calc(50% - 20px)',
            }}
          />
        </Box>
      )}
    </>
  )
}

export default LoadingWithDelay
