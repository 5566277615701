import { GeneratedText } from '../../../../narrative/GeneratedText'

import { mapDTO, mapDataModel } from '../mappings'

export const INTRODUCTION_TEXT =
  'There are essentially three sources of accrued depreciation:\n' +
  '\u2022 Physical deterioration, both curable (short-lived and long-lived components) and incurable;\n' +
  '\u2022 Functional obsolescence, both curable and incurable; and\n' +
  '\u2022 External obsolescence'

const generate = () => {
  const gt = new GeneratedText()

  gt.t`${INTRODUCTION_TEXT}`

  return gt.getParts()
}

export default { generate, mapDTO, mapDataModel }
