export const BUILDING_TYPE = {
  APARTMENT: 'Apartment Building',
  LUXURY: 'Luxury Building',
}

export const BUILDING_TYPE_OPTIONS = [
  { value: BUILDING_TYPE.APARTMENT, label: 'Apartment Building' },
  { value: BUILDING_TYPE.LUXURY, label: 'Luxury Building' },
]

/* eslint-disable id-length */
export const BUILDING_CLASS = {
  A: 'A',
  B: 'B',
  C: 'C',
  D: 'D',
}
/* eslint-enable id-length */

export const BUILDING_CLASS_LABELS = {
  [BUILDING_CLASS.A]: 'A',
  [BUILDING_CLASS.B]: 'B',
  [BUILDING_CLASS.C]: 'C',
  [BUILDING_CLASS.D]: 'D - Masonry',
}

export const BUILDING_CLASS_OPTIONS = [
  { value: BUILDING_CLASS.A, label: BUILDING_CLASS_LABELS.A },
  { value: BUILDING_CLASS.B, label: BUILDING_CLASS_LABELS.B },
  { value: BUILDING_CLASS.C, label: BUILDING_CLASS_LABELS.C },
  { value: BUILDING_CLASS.D, label: BUILDING_CLASS_LABELS.D },
]

export const INSURABLE_TYPE = {
  EXCELLENT: 'Excellent',
  GOOD: 'Good',
  AVERAGE: 'Average',
  LOW_COST: 'Low Cost',
}

export const TYPE_OPTIONS = [
  { value: INSURABLE_TYPE.EXCELLENT, label: 'Excellent' },
  { value: INSURABLE_TYPE.GOOD, label: 'Good' },
  { value: INSURABLE_TYPE.AVERAGE, label: 'Average' },
  { value: INSURABLE_TYPE.LOW_COST, label: 'Low Cost' },
]

export const BASEMENT_TYPE_OPTIONS = [
  { value: 'Residential Units', label: 'Residential Units' },
  { value: 'Finished', label: 'Finished' },
  { value: 'Parking', label: 'Parking' },
  { value: 'Unfinished', label: 'Unfinished' },
]

export const BASEMENT_CLASS = {
  AB: 'A-B',
  CDS: 'CDS',
}

export const BASEMENT_CLASS_OPTIONS = [
  { value: BASEMENT_CLASS.AB, label: BASEMENT_CLASS.AB },
  { value: BASEMENT_CLASS.CDS, label: BASEMENT_CLASS.CDS },
]

export const CAP_RATE_DISCUSSION_TOOLTIP =
  "The following generated commentary will appear in the Income Approach's Income Capitalization Conclusion of your report."

export const SiteAreaLabels = {
  sf: 'square feet',
  acre: 'acres',
}

export const tableRowHeaderBase =
  'font-weight:normal;border-bottom:1px solid #d7deea;text-align: center;vertical-align: middle;border-radius: 0px!important;margin: none; '
