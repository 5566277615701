import React from 'react'
import PropTypes from 'prop-types'

import arrayMutators from 'final-form-arrays'
import { get, forIn, isNil } from 'lodash'

import { Stack } from '@mui/material'

import wrapForm from 'report/forms/wrapForm'

import { RESIDENTIAL_PROJECTED_RENT_ROLL_PATH } from 'shared/constants/report/keysAndDataPaths'

import ProjectedRentRollData from './ProjectedRentRollData'
import ProjectedRentRollDiscussion from './ProjectedRentRollDiscussion'

export const DATA_PATH = RESIDENTIAL_PROJECTED_RENT_ROLL_PATH

const ResidentialProjectedRentRoll = ({ form, groupingType, rentConclusions, valueAsStabilized }) => {
  const { showDevelopersForecast, units } = form.values

  return (
    <Stack spacing={2}>
      <ProjectedRentRollData
        form={form}
        groupingType={groupingType}
        rentConclusions={rentConclusions}
        valueAsStabilized={valueAsStabilized}
      />
      <ProjectedRentRollDiscussion residentialUnits={units} showDevelopersForecast={showDevelopersForecast} />
    </Stack>
  )
}

const formOptions = {
  decorators: [],
  mutators: { ...arrayMutators },
}

ResidentialProjectedRentRoll.propTypes = {
  groupingType: PropTypes.string.isRequired,
  rentConclusions: PropTypes.array,
  valueAsStabilized: PropTypes.bool,
}

ResidentialProjectedRentRoll.defaultProps = {
  rentConclusions: [],
}

export default wrapForm(DATA_PATH, formOptions, state => {
  const rentReconciliation = get(
    state,
    'report.reportData.incomeApproach.residentialIncome.residentialRentReconciliation'
  )
  const { groupingType } = get(state, 'report.reportData.incomeApproach.residentialIncome.residentialUnitGroups')
  const valueAsStabilized = get(state, 'report.reportSettings.valueAsStabilized')

  const getRentConclusions = rentReconciliation => {
    const conclusions = []
    const { rentReconciliationGroups = {} } = rentReconciliation || {}
    forIn(rentReconciliationGroups, group => {
      if (!isNil(group.marketConclusion) && !isNil(group.marketConclusionUnit)) {
        conclusions.push({
          marketConclusion: group.marketConclusion,
          marketConclusionUnit: group.marketConclusionUnit,
          unitGroupKey: group.unitGroupKey,
          unitGroupName: group.unitGroupName,
        })
      }
    })
    return conclusions
  }

  return {
    groupingType,
    rentConclusions: getRentConclusions(rentReconciliation),
    valueAsStabilized,
  }
})(ResidentialProjectedRentRoll)
