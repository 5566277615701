import { toNumber, isNaN, camelCase } from 'lodash'
import Handsontable from 'handsontable'

import { formatCurrencyFloat, formatFloat } from '../../../../../shared/utils/numberFormatters'

import { isValidBathroomCount } from './rentRollValidators'

const formatBathroomCount = value => {
  const parsedValue = toNumber(value)

  return isNaN(parsedValue) ? value : parsedValue
}

export const bathroomCountRenderer = (instance, td, row, col, prop, value, cellProperties) => {
  td.innerHTML = formatBathroomCount(value)
  td.className = 'alignRight'

  if (!isValidBathroomCount(value)) {
    td.className = `${td.className} invalid`
  }

  setQAAttribute(td, row, prop)

  return td
}

export const rentPerSquareFootRenderer = (instance, td, row, col, prop, value, cellProperties) => {
  let displayValue = '$NaN'

  if (Number.isFinite(value)) {
    displayValue = formatCurrencyFloat(value)
  }

  td.innerHTML = displayValue
  td.className = 'readOnly'
  setQAAttribute(td, row, prop)

  return td
}

const setTotalQAAttribute = (td, rowValue, prop) => {
  const totalRowName = camelCase(rowValue['#'])
  td.setAttribute('data-qa', `${totalRowName}-${prop}`)
}

export const totalRowRenderer = rowValue => (instance, td, row, col, prop, value, cellProperties) => {
  td.innerHTML = formatCurrencyFloat(value)
  td.className = 'totalRow'
  setTotalQAAttribute(td, rowValue, prop)

  return td
}

export const totalRowTitleRenderer = rowValue => (instance, td, row, col, prop, value, cellProperties) => {
  td.innerHTML = value
  td.className = 'totalRowLeft'
  td.colSpan = cellProperties.colSpan
  setTotalQAAttribute(td, rowValue, prop)

  return td
}

const setQAAttribute = (td, row, prop) => td.setAttribute('data-qa', `${prop}-${row}-cell`)

export const formatPerSquareFootageFloatRight = (instance, td, row, col, prop, value, cellProperties) => {
  let tableDisplayValue = value

  if (value === 0) {
    tableDisplayValue = null
  }

  td.innerHTML = formatFloat(tableDisplayValue)
  td.className = 'alignRight'
  setQAAttribute(td, row, prop)

  return td
}

export const deleteActionRenderer = (instance, td, row, col, prop, value, cellProperties, onRowDeleteClick) => {
  const deleteButton = document.createElement('span')
  Object.assign(deleteButton.style, {
    cursor: 'pointer',
    color: '#D34141',
    fontSize: '14px',
  })
  deleteButton.textContent = 'Delete'

  deleteButton.addEventListener('click', () => {
    onRowDeleteClick(row)
  })

  Handsontable.dom.empty(td)

  td.className = 'alignCenter'
  td.appendChild(deleteButton)

  setQAAttribute(td, row, prop)

  return td
}
