import React from 'react'
import PropTypes from 'prop-types'

import ImageModal from './ImageModal'

const withImageListModal = ImageListComponent => {
  return class extends React.PureComponent {
    static propTypes = {
      images: PropTypes.arrayOf(
        PropTypes.shape({
          cdnUrl: PropTypes.string.isRequired,
          uuid: PropTypes.string,
          isLoading: PropTypes.bool,
        })
      ),
      category: PropTypes.string,
    }

    static defaultProps = {
      images: [],
      category: '',
    }

    state = {
      openModal: false,
      openedImageIndex: 0,
    }

    openImageModal = ({ index }) => {
      this.setState({
        openModal: true,
        openedImageIndex: index,
      })
    }

    hideModal = () => {
      this.setState({
        openModal: false,
      })
    }

    render() {
      const { images, category } = this.props
      const { openModal, openedImageIndex } = this.state

      return (
        <>
          <ImageListComponent openImageModal={this.openImageModal} hideModal={this.hideModal} {...this.props} />
          {openModal && (
            <ImageModal
              openedImageIndex={openedImageIndex}
              category={category}
              images={images}
              onHideModal={this.hideModal}
            />
          )}
        </>
      )
    }
  }
}

export default withImageListModal
