import { GeneratedText, gtConditional, gtIfElse, gtLocked, gtTemplate } from 'shared/utils/narrative/GeneratedText'

import { mapDTO, mapDataModel } from './mappings'

const generate = () => {
  const downwardCompsSentence = gtLocked('downwardCompsSentence')
  const upwardCompsSentence = gtLocked('upwardCompsSentence')

  const downwardsMultiples = gtConditional('hasMultipleDownwardAdjustments', gtTemplate`s`)
  const downwardsAdjustmentSentence = gtConditional(
    'hasDownwardAdjustments',
    gtTemplate` Comparable${downwardsMultiples} ${downwardCompsSentence} ${gtIfElse(
      'hasMultipleDownwardAdjustments',
      gtTemplate`have`,
      gtTemplate`has a`
    )} superior financing terms, and thus required ${gtIfElse(
      'hasMultipleDownwardAdjustments',
      gtTemplate``,
      gtTemplate` a`
    )} downward adjustment${downwardsMultiples}.`
  )

  const upwardsMultiples = gtConditional('hasMultipleUpwardAdjustments', gtTemplate`s`)

  const upwardsAdjustmentSentence = gtConditional(
    'hasUpwardAdjustments',
    gtTemplate` Comparable${upwardsMultiples} ${upwardCompsSentence} ${gtIfElse(
      'hasMultipleUpwardAdjustments',
      gtTemplate`have`,
      gtTemplate`has`
    )} inferior financing terms, and thus required ${gtIfElse(
      'hasMultipleUpwardAdjustments',
      gtTemplate``,
      gtTemplate` an`
    )} upward adjustment${upwardsMultiples}.`
  )

  const containsAdjustmentsDiscussion = gtTemplate`${upwardsAdjustmentSentence}${downwardsAdjustmentSentence}`
  const doesNotContainAdjustmentsDiscussion = gtTemplate` To the best of our knowledge, all of the comparables 
  used in this analysis were accomplished with cash or market-oriented financing. Therefore, no adjustments were 
  required.`

  const discussion = gtIfElse(
    'shouldGenerateWriteup',
    gtTemplate`${containsAdjustmentsDiscussion}`,
    gtTemplate`${doesNotContainAdjustmentsDiscussion}`
  )

  const gt = new GeneratedText()
  gt.t`The purpose of adjusting for financing terms is to determine cash equivalent sale 
  prices for the comparables in accordance with the definition of market value for this report.${discussion}`

  return gt.getParts()
}

export default { generate, mapDTO, mapDataModel }
