import React from 'react'
import PropTypes from 'prop-types'
import { DragDropContext } from 'react-beautiful-dnd'

import {
  Avatar,
  Icon,
  Paper,
  TableBody,
  TableCell,
  TableHead,
  Button,
  TableRow,
  Tooltip,
  Typography,
  Stack,
  IconButton,
  Box,
} from '@mui/material'
import StarRounded from '@mui/icons-material/StarRounded'
import RemoveCircle from '@mui/icons-material/RemoveCircle'
import ErrorOutline from '@mui/icons-material/ErrorOutline'
import { forEach, get, isEqual, isNil, isNumber, noop } from 'lodash'
import { FieldArray } from 'react-final-form-arrays'

import { DroppableTable } from 'client-shared/components/DragDropTable'
import { getSourceOfInformation } from 'client-shared/components/SourceOfInformation'

import PropertyTypeIcon from 'client-shared/components/PropertyTypeIcon'
import { Area, Template } from 'client-shared/components/Template'
import { NOT_AVAILABLE } from 'report/constants'
import { calculateDistance } from 'client-shared/utils/geography'
import {
  formatCurrencyFloat,
  formatCurrencyInt,
  formatInt,
  toPercentageString,
} from 'client-shared/utils/numberFormatters'

import Badge from '../../sales/SalesCompsSearch/Badge'
import { SALES_COMP_STOCK_PHOTO } from '../../sales/SalesCompsSearch/constants'

import { checkIfCompsAreLatestVersion, getFormattedSaleDate, getSaleCompFullAddress } from './helpers'
import { CAP_RATE_COMP_HEADERS } from './constants'

class MaterialTableHeader extends React.Component {
  render() {
    return (
      <TableHead>
        <TableRow>
          {CAP_RATE_COMP_HEADERS.map(row => {
            return (
              <TableCell padding={row.padding} key={row.id} style={{ ...row.style }}>
                {row.label}
              </TableCell>
            )
          }, this)}
          <TableCell>Actions</TableCell>
        </TableRow>
      </TableHead>
    )
  }
}

const EnhancedTableHead = MaterialTableHeader

class SelectedCapRateCompsTable extends React.Component {
  state = {
    selected: [],
    draggingId: '',
  }

  componentWillUpdate(nextProps, nextState, nextContext) {
    if (this.props.salesComps.length < nextProps.salesComps.length) {
      this.setState({
        order: 'asc',
        orderBy: 'dateSold',
      })
    }
  }

  static propTypes = {
    form: PropTypes.object.isRequired,
    fields: PropTypes.shape({
      value: PropTypes.array,
      push: PropTypes.func,
      update: PropTypes.func,
    }),
    title: PropTypes.string.isRequired,
    removeComp: PropTypes.func.isRequired,
    navigateToEditModal: PropTypes.func.isRequired,
    subjectCoordinates: PropTypes.shape({
      lat: PropTypes.number,
      lng: PropTypes.number,
    }),
    salesComps: PropTypes.array,
    subjectProperty: PropTypes.object,
    authenticatedUser: PropTypes.object.isRequired,
    fieldName: PropTypes.string.isRequired,
    getCustomDetailsButton: PropTypes.func,
  }

  static defaultProps = {
    getCustomDetailsButton: noop,
  }

  componentDidMount() {
    this.checkIfCompsAreLatestVersion()
  }

  checkIfCompsAreLatestVersion = async () => {
    const { form, fields, salesComps } = this.props

    const updatedComps = await checkIfCompsAreLatestVersion(salesComps)

    form.batch(() => {
      form.reset(form.getState().values)
      updatedComps.forEach((updatedComp, index) => {
        fields.update(index, updatedComp)
      })
    })
  }

  fillTableRowTemplate = ({
    compId,
    compIndex,
    photoElement,
    type,
    distance,
    streetAddress,
    address,
    saleDate,
    yearBuilt,
    totalUnits,
    gba,
    salePrice,
    pricePerUnit,
    pricePerSquareFoot,
    capRate,
    actions,
    displaySourceOfInformation,
    listing,
    inContract,
    state,
    city,
    zip,
    isDrmComp,
    isLatestVersion,
    status,
    isDeleted,
  }) => {
    const fillerIcon = <Icon>remove</Icon>

    const formattedGBA = !isNil(gba) ? `${formatInt(gba)}` : fillerIcon
    const isUneditable = compId && (!isDrmComp || !isLatestVersion)

    return (
      <TableRow
        data-qa={isNumber(compIndex) ? `selected-comps-row-${compIndex}` : 'property-row'}
        id={`selected-comps-${compId}`}
        key={`selected-comps-${compId}`}
      >
        <TableCell data-qa="photo-element">{photoElement}</TableCell>
        <TableCell data-qa="property-type">
          <PropertyTypeIcon propertyType={type} />
        </TableCell>
        <TableCell data-qa="distance">{distance || fillerIcon}</TableCell>
        <TableCell data-qa="address">
          {getSaleCompFullAddress({ streetAddress, state, city, zip, address })}
          {isUneditable && (
            <Tooltip placement="top" title="Outdated">
              <Box component="span" sx={{ position: 'relative', top: 4, left: 8 }}>
                <ErrorOutline color="error" fontSize="small" />
              </Box>
            </Tooltip>
          )}

          {isDeleted && (
            <Tooltip placement="top" title="This comp was deleted from our database by a user.">
              <Box component="span" sx={{ position: 'relative', top: 4, left: 8 }}>
                <RemoveCircle color="error" fontSize="small" />
              </Box>
            </Tooltip>
          )}
        </TableCell>
        <TableCell data-qa="status">{status ? <Badge type={status} /> : fillerIcon}</TableCell>
        <TableCell data-qa="sale-date">{getFormattedSaleDate(listing, inContract, saleDate, fillerIcon)}</TableCell>
        <TableCell data-qa="year-built">{yearBuilt || fillerIcon}</TableCell>
        <TableCell data-qa="total-units">{totalUnits || fillerIcon}</TableCell>
        <TableCell data-qa="formatted-gba">{formattedGBA}</TableCell>
        <TableCell data-qa="sale-price">{salePrice || fillerIcon}</TableCell>
        <TableCell data-qa="price-per-unit">{formatCurrencyInt(pricePerUnit) || fillerIcon}</TableCell>
        <TableCell data-qa="price-per-square-foot">{formatCurrencyFloat(pricePerSquareFoot) || fillerIcon}</TableCell>
        <TableCell data-qa="cap-rate">{toPercentageString(capRate) || fillerIcon}</TableCell>
        <TableCell data-qa="source-of-information">{displaySourceOfInformation || fillerIcon}</TableCell>
        <TableCell data-qa="selected-comp-actions">{actions || ''}</TableCell>
      </TableRow>
    )
  }

  renderTableBodyContent = () => {
    const { salesComps, removeComp, subjectProperty, subjectCoordinates, navigateToEditModal } = this.props
    const salesCompsTableRows = []
    const photoElement = (
      <div>
        <StarRounded />
      </div>
    )
    const subject = this.fillTableRowTemplate({
      ...subjectProperty,
      photoElement,
      type: subjectProperty.type,
      gba: formatInt(subjectProperty.gba),
      distance: null,
    })

    salesCompsTableRows.push(subject)
    const salesCompsToRender = salesComps
      .filter(comp => !comp.isRemoved)
      .map(salesComp => {
        const totalUnits = salesComp.residentialUnits + (salesComp.commercialUnits || 0)
        return {
          ...salesComp,
          totalUnits,
          distance: calculateDistance(subjectCoordinates, salesComp.coords),
          pricePerUnit: salesComp.salePrice / totalUnits,
          pricePerSquareFoot: salesComp.salePrice / salesComp.gba,
          capRate: !isNil(salesComp.capRate) ? salesComp.capRate : NOT_AVAILABLE,
          saleDate: salesComp.saleDate,
          compId: salesComp._id,
          photoUrl: get(salesComp, 'photo.cdnUrl') || SALES_COMP_STOCK_PHOTO,
        }
      })

    forEach(salesCompsToRender, (salesComp, index) => {
      salesCompsTableRows.push(this.renderTableRow({ salesComp, index, removeComp, navigateToEditModal }))
    })
    return salesCompsTableRows
  }

  renderTableRow = ({ salesComp, index, removeComp, navigateToEditModal }) => {
    const CustomDetailsButton = this.props.getCustomDetailsButton(salesComp)

    const actions = (
      <Stack alignItems="center" direction="row" spacing={1}>
        {CustomDetailsButton ? (
          <CustomDetailsButton />
        ) : (
          <Button variant="text" onClick={() => navigateToEditModal(salesComp.compId)} data-qa="details-btn">
            Details
          </Button>
        )}
        <Tooltip placement="top" title="Remove">
          <IconButton aria-label="Remove" data-qa="selected-comp-remove-btn" onClick={() => removeComp(salesComp)}>
            <RemoveCircle color="error" />
          </IconButton>
        </Tooltip>
      </Stack>
    )

    const photoTooltip = (
      <Avatar
        alt="Sales Comp"
        src={salesComp.photoUrl}
        sx={{ width: 100, height: 100, objectFit: 'contain' }}
        variant="square"
      />
    )

    const photoElement = (
      <Tooltip placement="left" title={photoTooltip}>
        <Avatar
          alt="Sales Comp"
          src={salesComp.photoUrl}
          sx={{ width: 36, height: 36, objectFit: 'cover' }}
          variant="square"
        />
      </Tooltip>
    )

    const displaySourceOfInformation = getSourceOfInformation(salesComp)
    return this.fillTableRowTemplate({
      compId: salesComp.compId || index,
      compIndex: index,
      type: salesComp.type,
      streetAddress: salesComp.streetAddress,
      address: salesComp.address,
      city: salesComp.city,
      state: salesComp.state,
      zip: salesComp.zip,
      yearBuilt: salesComp.yearBuilt,
      totalUnits: salesComp.totalUnits,
      gba: formatInt(salesComp.gba),
      salePrice: formatCurrencyInt(salesComp.salePrice),
      distance: `${salesComp.distance} mi`,
      photoElement,
      photoTooltip,
      saleDate: salesComp.saleDate,
      pricePerUnit: salesComp.pricePerUnit,
      pricePerSquareFoot: salesComp.pricePerSquareFoot,
      capRate: salesComp.capRate,
      actions,
      displaySourceOfInformation,
      listing: salesComp.listing,
      inContract: salesComp.inContract,
      isDrmComp: !!(salesComp.salesEventId && salesComp.salesEventVersion),
      isLatestVersion: salesComp.isLatestVersion,
      status: salesComp.status,
      isDeleted: !!salesComp.deletedAt,
    })
  }

  onDragStart = event => {
    this.setState({ draggingId: event.draggableId })
  }

  moveCard = ({ source, destination }) => {
    const { form, salesComps } = this.props

    this.setState({ draggingId: '' })

    if (!destination) {
      return
    }

    if (destination.index === 0) {
      // trying to move a comp above property row
      return
    }

    const [comp] = salesComps.splice(source.index, 1)
    salesComps.splice(destination.index - 1, 0, comp)

    form.change('selectedComps', salesComps)
  }

  render() {
    const { title } = this.props

    return (
      <Paper>
        <Template
          is={`
            'title tools'
            'table table'
            /auto  1fr
          `}
          gap={2}
        >
          <Area is="title">
            <Typography variant="h6" gutterBottom>
              {title}
            </Typography>
          </Area>
          <Area is="table">
            <DragDropContext onDragStart={this.onDragStart} onDragEnd={this.moveCard}>
              <DroppableTable id="selected-sales-comps-table" isDropDisabled={false}>
                <EnhancedTableHead />
                <TableBody>{this.renderTableBodyContent()}</TableBody>
              </DroppableTable>
            </DragDropContext>
          </Area>
        </Template>
      </Paper>
    )
  }
}

const CapRateCompsFieldArray = props => (
  <FieldArray isEqual={isEqual} name="capRateComps" component={SelectedCapRateCompsTable} {...props} />
)

export default CapRateCompsFieldArray
