import { isNil } from 'lodash'
import Handsontable from 'handsontable'

import { formatCurrencyInt, formatCurrencyFloat, formatInt } from '../../utils/numberFormatters'

export const setQAAttribute = (td, row, prop) => td.setAttribute('data-qa', `${prop}-${row}-cell`)

export const currencyFloatReadOnly = (instance, td, row, col, prop, value, cellProperties) => {
  td.innerHTML = formatCurrencyFloat(value)
  td.className = 'readOnly'
  setQAAttribute(td, row, prop)

  return td
}

export const currencyIntReadOnly = (instance, td, row, col, prop, value, cellProperties) => {
  td.innerHTML = formatCurrencyInt(value)
  td.className = 'readOnly'
  setQAAttribute(td, row, prop)

  return td
}

export const totalRowRenderer = (instance, td, row, col, prop, value, cellProperties) => {
  td.innerHTML = formatCurrencyFloat(value)
  td.className = 'totalRow'
  setQAAttribute(td, row, prop)

  return td
}

export const totalRowTitleRenderer = (instance, td, row, col, prop, value, cellProperties) => {
  td.innerHTML = value
  td.className = 'totalRowLeft'
  td.colSpan = cellProperties.colSpan
  setQAAttribute(td, row, prop)

  return td
}

export const totalLabelRenderer = (instance, td, row, col, prop, value, cellProperties) => {
  td.innerHTML = value
  td.className = 'totalRow'
  setQAAttribute(td, row, prop)

  return td
}

export const currencyFloatRight = (instance, td, row, col, prop, value, cellProperties) => {
  if (value !== '') {
    td.innerHTML = formatCurrencyFloat(value)
    td.className = `${cellProperties.className || ''} alignRight`
  }
  setQAAttribute(td, row, prop)

  return td
}

export const currencyIntRight = (instance, td, row, col, prop, value, cellProperties) => {
  if (value !== '') {
    td.innerHTML = formatCurrencyInt(value)
    td.className = 'alignRight'
  }
  setQAAttribute(td, row, prop)

  return td
}

export const currencyParsedFloat = (instance, td, row, col, prop, value, cellProperties) => {
  if (isNil(value)) {
    td.innerHTML = ''
  } else {
    td.innerHTML = formatCurrencyFloat(value)
  }
  td.className = `${cellProperties.className || ''} alignRight`
  setQAAttribute(td, row, prop)

  return td
}

export const formatIntRight = (instance, td, row, col, prop, value, cellProperties) => {
  td.innerHTML = formatInt(value)
  td.className = 'alignRight'
  setQAAttribute(td, row, prop)

  return td
}

export const formatIntReadOnly = (instance, td, row, col, prop, value, cellProperties) => {
  td.innerHTML = formatInt(value)
  td.className = 'readOnly'
  setQAAttribute(td, row, prop)

  return td
}

export const readOnlyRenderer = (instance, td, row, col, prop, value, cellProperties) => {
  td.innerHTML = value
  td.className = 'readOnly'
  setQAAttribute(td, row, prop)

  return td
}

export const dropdownCellRenderer = (instance, td, row, col, prop, value, cellProperties) => {
  Handsontable.renderers.DropdownRenderer.call(this, instance, td, row, col, prop, value, cellProperties)
  setQAAttribute(td, row, prop)

  return td
}

export const numberRenderer = (instance, td, row, col, prop, value, cellProperties) => {
  Handsontable.renderers.NumericRenderer.call(this, instance, td, row, col, prop, value, cellProperties)
  setQAAttribute(td, row, prop)

  return td
}
