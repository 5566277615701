import React from 'react'
import PropTypes from 'prop-types'
import Typography from '@mui/material/Typography'
import { withStyles } from '@mui/styles'

import ProgressBar from '../../../../shared/components/ProgressBar'

import CountdownTimer from './CountdownTimer'
import { ONE_HUNDRED_PERCENT, EIGHTY_PERCENT, PREPARING_REPORT_MESSAGE } from './constants'

const styles = {
  progressBar: {
    maxWidth: 210,
    maxHeight: 10,
    borderRadius: 5,
    '& .rc-progress-line-path': {
      // important is used to override transition of svg path inline styles
      transition: 'stroke-dashoffset 0.03s ease 0s, stroke 0.03s linear 0s, 0.03s, 3s !important',
    },
  },
}

class ExportItemProgressBar extends React.PureComponent {
  static propTypes = {
    isPendingStatus: PropTypes.bool.isRequired,
    progressBarColor: PropTypes.string.isRequired,
    remainingReportGenerationTime: PropTypes.number.isRequired,
    averageReportGenerationTime: PropTypes.number.isRequired,
  }

  getPercentages = remainingSeconds => {
    const { averageReportGenerationTime } = this.props
    return Math.floor(EIGHTY_PERCENT - (remainingSeconds * EIGHTY_PERCENT) / averageReportGenerationTime)
  }

  getProgressBarMarkup = remainingSeconds => {
    const { classes, isPendingStatus, progressBarColor, progressBarProps } = this.props
    let percentages = null
    let showPreparingReportMessage = false
    if (isPendingStatus && remainingSeconds !== 0) {
      percentages = this.getPercentages(remainingSeconds)
    } else if (!isPendingStatus) {
      percentages = ONE_HUNDRED_PERCENT
    } else {
      showPreparingReportMessage = true
      percentages = EIGHTY_PERCENT
    }

    return (
      <React.Fragment>
        <ProgressBar
          data-qa={`progress-${percentages}-percentages`}
          {...progressBarProps}
          className={classes.progressBar}
          strokeColor={progressBarColor}
          percent={percentages}
        />
        {showPreparingReportMessage && <Typography variant="caption">{PREPARING_REPORT_MESSAGE} </Typography>}
      </React.Fragment>
    )
  }

  render() {
    const { remainingReportGenerationTime, isPendingStatus } = this.props
    return remainingReportGenerationTime === 0 || !isPendingStatus ? (
      this.getProgressBarMarkup(0)
    ) : (
      <CountdownTimer id="export-time-countdown" approximateReportGenerationTime={remainingReportGenerationTime}>
        {({ remainingSeconds }) => this.getProgressBarMarkup(remainingSeconds)}
      </CountdownTimer>
    )
  }
}

export default withStyles(styles)(ExportItemProgressBar)
