import { lowerCase, toLower } from 'lodash'
import numeral from 'numeral'

import { USES_VALUES } from '../../../libs/bowery-libs/constants/report/commercial-unit'
import { UNIT_GRADES } from '../../../client/src/report/constants'
import { formatList } from '../../../client/src/shared/utils/textHelpers'

export const formatSquareFeet = squareFeet => numeral(squareFeet).format('0,0.[00]')

export const formatCommercialUnitUse = ({ use, otherUse }) => {
  if (use === USES_VALUES.OTHER && otherUse) {
    return toLower(otherUse)
  }
  return use
}

export const formatCommercialUnitGrade = ({ grade, otherGrade }) => {
  const selectedGrades = Object.entries(grade)
    .filter(([key, value]) => key !== 'other' && !!value)
    // eslint-disable-next-line no-unused-vars
    .map(([key, value]) => lowerCase(UNIT_GRADES[key]))

  if (otherGrade) {
    selectedGrades.push(otherGrade)
  }
  return formatList(selectedGrades)
}
