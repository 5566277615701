export const styles = () => ({
  cardSpacing: {
    marginBottom: '16px',
  },
  noBackground: {
    background: 'none',
  },
  dropdown: {
    width: 300,
  },
})
