import React from 'react'
import PropTypes from 'prop-types'
import {
  Typography,
  Dialog,
  withStyles,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
} from '@material-ui/core'
import Close from '@mui/icons-material/Close'

import { Button } from '@mui/material'

import { DIALOG_Z_INDEX } from '../../constants'

const styles = theme => ({
  confirmDialogActionsContainer: {
    display: 'flex',
  },
  confirmDialogRoot: {
    zIndex: DIALOG_Z_INDEX + 1,
  },
  confirmDialogBody: {
    width: 405,
  },
  confirmDialogText: {
    color: theme.palette.text.primary,
  },
  confirmDialogTitle: {
    maxWidth: 350,
    color: theme.palette.primary[900],
  },
  confirmDialogContent: {
    paddingBottom: 10,
  },
  cancelIcon: {
    position: 'absolute',
    marginTop: 23,
    width: 24,
    cursor: 'pointer',
    color: theme.palette.primary[900],
    right: 0,
    marginRight: 14,
  },
})

class CloseConfirmDialog extends React.PureComponent {
  static propTypes = {
    open: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    onConfirm: PropTypes.func.isRequired,
    title: PropTypes.string.isRequired,
    text: PropTypes.string.isRequired,
    confirmButtonText: PropTypes.string,
  }

  closeConfirmDialog = () => {
    this.props.onClose()
  }

  handleConfirmDialogDelete = () => {
    this.closeConfirmDialog()
    this.props.onConfirm()
  }

  render() {
    const { classes, open, title, text, confirmButtonText = 'Discard and Close' } = this.props

    return (
      <Dialog
        aria-labelledby="confirm-close-dialog-title"
        open={!!open}
        onEscapeKeyDown={this.closeConfirmDialog}
        classes={{ root: classes.confirmDialogRoot }}
      >
        <div className={classes.confirmDialogBody}>
          <Close className={classes.cancelIcon} onClick={this.closeConfirmDialog} />
          <DialogTitle id="confirm-close-dialog-title" disableTypography>
            <Typography className={classes.confirmDialogTitle} variant="h6">
              {title}
            </Typography>
          </DialogTitle>
          <DialogContent className={classes.confirmDialogContent}>
            <DialogContentText className={classes.confirmDialogText}>{text}</DialogContentText>
          </DialogContent>
          <DialogActions classes={{ root: classes.confirmDialogActionsContainer }}>
            <Button onClick={this.closeConfirmDialog} color="primary">
              Cancel
            </Button>
            <Button wide variant="contained" onClick={this.handleConfirmDialogDelete} color="error">
              {confirmButtonText}
            </Button>
          </DialogActions>
        </div>
      </Dialog>
    )
  }
}

export default withStyles(styles)(CloseConfirmDialog)
