import React from 'react'
import PropTypes from 'prop-types'

import DeleteIcon from '@mui/icons-material/DeleteOutline'
import DragHandleIcon from '@mui/icons-material/DragHandleOutlined'
import EditIcon from '@mui/icons-material/EditOutlined'
import StarIcon from '@mui/icons-material/StarRounded'
import {
  Avatar,
  IconButton,
  ListItem as BaseListItem,
  ListItemAvatar,
  ListItemSecondaryAction,
  ListItemText,
} from '@mui/material'
import { withStyles } from '@mui/styles'

const styles = theme => ({
  listItem: {
    paddingRight: '48px',
    '&:hover': {
      '& $showOnHover': {
        visibility: 'visible',
      },
    },
  },
  avatar: {
    color: 'gray',
    backgroundColor: 'white',
    width: 60,
    marginRight: '12px',
  },
  avatarIcon: {
    width: 24,
    textAlign: 'center',
  },
  dragHandle: {
    marginLeft: -16,
    flex: 1,
  },
  showOnHover: {
    visibility: 'hidden',
  },
})

class ListItem extends React.PureComponent {
  static propTypes = {
    streetAddress: PropTypes.string.isRequired,
    city: PropTypes.string.isRequired,
    state: PropTypes.string.isRequired,
    index: PropTypes.number,
    isSubject: PropTypes.bool.isRequired,
    onRemove: PropTypes.func.isRequired,
    onEdit: PropTypes.func.isRequired,
    dragHandleProps: PropTypes.object,
    compIndex: PropTypes.number.isRequired,
  }

  static defaultProps = {
    isSubject: false,
    dragHandleProps: {},
  }

  get avatar() {
    const { classes, isSubject, compIndex } = this.props
    return (
      <React.Fragment>
        <DragHandleIcon data-qa="drag-icon" className={`${classes.showOnHover} ${classes.dragHandle}`} />
        <span className={classes.avatarIcon}>{isSubject ? <StarIcon /> : compIndex}</span>
      </React.Fragment>
    )
  }

  render() {
    const { classes, streetAddress, city, state, country, isSubject, onRemove, onEdit, dragHandleProps, ...restProps } =
      this.props

    const countryText = isSubject ? ` ${country}` : ''
    const secondaryText = `${city}, ${state}${countryText}`
    return (
      <BaseListItem
        data-qa={isSubject ? 'subject-item' : 'comp-item'}
        {...restProps}
        classes={{ container: classes.listItem }}
      >
        <ListItemAvatar {...dragHandleProps}>
          <Avatar className={classes.avatar}>{this.avatar}</Avatar>
        </ListItemAvatar>
        <ListItemText
          primary={streetAddress}
          secondary={secondaryText}
          primaryTypographyProps={{ 'data-qa': 'address-label', overflow: 'hidden', textOverflow: 'ellipsis' }}
          secondaryTypographyProps={{ 'data-qa': 'city-label', overflow: 'hidden', textOverflow: 'ellipsis' }}
        />
        <ListItemSecondaryAction>
          <IconButton data-qa="edit-btn" aria-label="Edit" onClick={onEdit} className={classes.showOnHover}>
            <EditIcon />
          </IconButton>
          <IconButton data-qa="delete-btn" aria-label="Delete" onClick={onRemove} className={classes.showOnHover}>
            <DeleteIcon />
          </IconButton>
        </ListItemSecondaryAction>
      </BaseListItem>
    )
  }
}

export default withStyles(styles)(ListItem)
