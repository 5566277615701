import { GeneratedText, gtLocked, gtIfElse, gtTemplate, gtLockedSentence } from 'shared/utils/narrative/GeneratedText'

import { mapDTO, mapDataModel } from './mappings'

export const generate = () => {
  const gt = new GeneratedText()

  gt.t`Based on the subject’s current zoning regulations, ${gtLocked('requiredParkingSpaces')} parking ${gtIfElse(
    'isSingleRequiredParkingSpace',
    gtTemplate`space is`,
    gtTemplate`spaces are`
  )} required on the subject property. ${gtLockedSentence('waiverBasisSentence')}The subject has ${gtLocked(
    'actualParkingSpaces'
  )} available and is${gtIfElse('isFreddieMacReport', gtTemplate` legally `, gtTemplate` `)}${gtIfElse(
    'isConformingWithParkingRequirements',
    gtTemplate`complying`,
    gtTemplate`non-complying`
  )} with regards to parking regulations.`

  return gt.getParts()
}

export default { generate, mapDTO, mapDataModel }
