import {
  COMMERCIAL_INCOME_KEY,
  COMMERCIAL_RENT_ROLL_KEY,
  COMMERCIAL_STABILIZED_LEASE_STRUCTURE_KEY,
  COMMERCIAL_COMP_GROUPS_KEY,
  COMMERCIAL_REIMBURSEMENT_SUMMARY_KEY,
  COMMERCIAL_RENT_COMPS_KEY,
  COMMERCIAL_RENT_COMPS_MAP_KEY,
  COMMERCIAL_RENT_RECONCILIATION_KEY,
  COMMERCIAL_PROJECTED_RENT_ROLL_KEY,
  COMMERCIAL_COMP_GROUPS_DISCUSSION_KEY,
} from 'shared/constants/report/keysAndDataPaths'

import CommercialRentComps from '../../../forms/income/commercial/CommercialRentComps'
import CommercialRentReconciliation from '../../../forms/income/commercial/CommercialRentReconciliation'
import CommercialRentRoll from '../../../forms/income/commercial/CommercialRentRoll'
import CommercialReimbursementSummary from '../../../forms/income/commercial/CommercialReimbursementSummary'
import CommercialRentCompsMap from '../../../forms/income/commercial/CommercialRentCompsMap'
import CommercialCompGroups from '../../../forms/income/commercial/CommercialCompGroups'
import CommercialCompGroupsDiscussion from '../../../forms/income/commercial/CommercialCompGroupsDiscussion'
import CommercialProjectedRentRoll from '../../../forms/income/commercial/CommercialProjectedRentRoll/components/CommercialProjectedRentRoll'
import CommercialStabilizedLeaseStructure from '../../../forms/income/commercial/CommercialStabilizedLeaseStructure'

export default [
  {
    key: COMMERCIAL_INCOME_KEY,
    name: 'Commercial',
  },
  {
    key: COMMERCIAL_RENT_ROLL_KEY,
    name: 'In-Place Rent Roll',
    formComponent: CommercialRentRoll,
  },
  {
    key: COMMERCIAL_STABILIZED_LEASE_STRUCTURE_KEY,
    name: 'Stabilized Lease Structure',
    formComponent: CommercialStabilizedLeaseStructure,
  },
  {
    key: COMMERCIAL_COMP_GROUPS_KEY,
    name: 'Comp Groups',
    formComponent: CommercialCompGroups,
  },
  {
    key: COMMERCIAL_REIMBURSEMENT_SUMMARY_KEY,
    name: 'Reimbursement Summary',
    formComponent: CommercialReimbursementSummary,
  },
  {
    key: COMMERCIAL_RENT_COMPS_KEY,
    name: 'Rent Comps',
    formComponent: CommercialRentComps,
  },
  {
    key: COMMERCIAL_RENT_COMPS_MAP_KEY,
    name: 'Rent Comps Map',
    formComponent: CommercialRentCompsMap,
  },
  {
    key: COMMERCIAL_RENT_RECONCILIATION_KEY,
    name: 'Rent Reconciliation',
    formComponent: CommercialRentReconciliation,
  },
  {
    key: COMMERCIAL_PROJECTED_RENT_ROLL_KEY,
    name: 'Stabilized Rent Roll',
    formComponent: CommercialProjectedRentRoll,
  },
  {
    key: COMMERCIAL_COMP_GROUPS_DISCUSSION_KEY,
    name: 'Comp Groups Discussion',
    formComponent: CommercialCompGroupsDiscussion,
  },
]
