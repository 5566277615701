import React from 'react'

import VerifiedIcon from '@mui/icons-material/Verified'

import { Typography, Stack } from '../_mui5'

const VerifiedCallout = ({ children, sx }) => {
  return (
    <Stack direction="row" sx={{ marginY: 1, color: 'success.dark', ...sx }} spacing={1} alignItems="center">
      <VerifiedIcon />
      <Typography variant="body2">{children}</Typography>
    </Stack>
  )
}

export default VerifiedCallout
