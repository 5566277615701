import { blueGrey } from '@material-ui/core/colors'

import { DEFAULT_HEIGHT } from '../../../../../../shared/components/Map/LeafletMap'

export default theme => ({
  root: {
    marginTop: 30,
  },
  map: {
    position: 'relative',
    zIndex: 1,
    left: 0,
  },
  mapPanel: {
    padding: '0 0 0 0',
    maxHeight: 568,
  },
  rentCompItem: {
    marginBottom: 8,
    textAlign: 'left',
  },
  rentCompFilterWrapper: {
    padding: '8px 16px 16px 0',
  },
  filtersWrapper: {
    position: 'relative',
    width: 300,
    zIndex: '999',
    top: -505,
    right: 0,
    maxHeight: 480,
    float: 'right',
    marginRight: 15,
    marginTop: 0,
  },
  filters: {
    overflowY: 'scroll',
    overflowX: 'hidden',
    maxHeight: 384,
  },
  searchResultsWrapper: {
    height: DEFAULT_HEIGHT,
    overflow: 'hidden',
    minWidth: 'calc(100%/3)',
  },
  searchResults: {
    padding: 8,
    backgroundColor: blueGrey[100],
  },
  newUnitModal: {
    padding: 25,
  },
  computedPanelContainer: {
    marginBottom: 30,
  },
  paper: {
    ...theme.paper,
    marginTop: 16,
  },
  buttonWrapper: {
    paddingRight: 20,
    display: 'inline-block',
  },
  noRows: {
    position: 'absolute',
    top: '0',
    bottom: '0',
    left: '0',
    right: '0',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  actionIcon: {
    color: theme.palette.common.white,
  },
  removedCompsTableCell: {
    padding: '4px 16px',
    fontSize: '14px',
    fontFamily: 'Nunito Sans',
  },
  statusBadge: {
    marginLeft: '0px',
  },
  addButton: {
    backgroundColor: '#21B586',
    '&:hover': {
      background: '#087461',
    },
    marginRight: '5px',
  },
  removeButton: {
    backgroundColor: '#D25441',
    '&:hover': {
      background: '#9E211B',
    },
    marginLeft: '24px',
    marginRight: '-14px',
  },
})
