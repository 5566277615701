import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { Form } from 'react-final-form'
import { get, isFunction } from 'lodash'
import { bindActionCreators, compose } from 'redux'
import { withStyles } from '@material-ui/core/styles'

import { BASE_PATH } from '../constants'
import FormFooter from '../layout/FormFooter'
import FormHeading from '../layout/FormHeading'
import { Loading } from '../../../shared/components'
import { updateBuildingComp } from '../redux/actions'

const formStyles = {
  padding: 16,
  marginLeft: 250,
}

export default function wrapForm(
  formDataPath,
  formOptions = {},
  mapStateToProps = () => ({}),
  mapDispatchToProps = () => ({})
) {
  return FormComponent => {
    const formPath = formDataPath.join('.')
    const { styles = {}, initialValues = {} } = formOptions

    class FormContainer extends React.Component {
      static propTypes = {
        nextFormPath: PropTypes.string,
        prevFromPath: PropTypes.string,
        nextFormName: PropTypes.string,
        prevFormName: PropTypes.string,
        onSubmit: PropTypes.func.isRequired,
        updateBuildingComp: PropTypes.func.isRequired,
      }

      static defaultProps = {
        nextFormPath: null,
        prevFromPath: null,
        nextFormName: null,
        prevFormName: null,
      }

      initialized = false

      componentDidMount() {
        window.scrollTo(0, 0)
      }

      shouldComponentUpdate(nextProps, nextState) {
        if (!this.initialized) {
          this.initialized = true
          return true
        }
        return false
      }

      onSubmit = values => {
        const { updateBuildingComp, nextFormPath } = this.props

        updateBuildingComp(formDataPath, values, nextFormPath)
      }

      get formId() {
        return `${formPath}-final-form`
      }

      render() {
        const { initialValues } = this.props
        const { heading, ...otherFormOptions } = formOptions

        const { nextFormName, prevFormName, buildingComp, prevFormPath } = this.props
        const isLoading = get(buildingComp, 'isLoading')

        if (isLoading) {
          return <Loading />
        }

        return (
          <Form
            onSubmit={this.onSubmit}
            {...otherFormOptions}
            initialValues={initialValues}
            render={({ values, form, handleSubmit, dirty }) => (
              <form id={this.formId} onSubmit={handleSubmit} dirty={dirty.toString()} style={formStyles}>
                <FormHeading formHeading={heading} />
                <FormComponent {...this.props} form={{ values, ...form }} />
                <FormFooter
                  handleSubmit={handleSubmit}
                  prevFormPath={prevFormPath}
                  nextFormName={nextFormName}
                  prevFormName={prevFormName}
                />
              </form>
            )}
          />
        )
      }
    }

    const customMapStateToProps = (state, ownProps, ...otherParams) => ({
      initialValues: {
        ...initialValues,
        ...get(state, `${BASE_PATH}.${formPath}`),
      },
      buildingComp: get(state, BASE_PATH),
      ...mapStateToProps(state, ownProps, ...otherParams),
    })

    const customMapDispatchToProps = (dispatch, ownProps) => {
      const customMap = isFunction(mapDispatchToProps)
        ? mapDispatchToProps(dispatch, ownProps)
        : bindActionCreators(mapDispatchToProps, dispatch)
      return {
        ...customMap,
        updateBuildingComp: (formDataPath, values, nextFormPath) =>
          dispatch(updateBuildingComp({ formDataPath, values, nextFormPath })),
      }
    }

    return compose(withStyles(styles), connect(customMapStateToProps, customMapDispatchToProps))(FormContainer)
  }
}
