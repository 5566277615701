import React from 'react'

import arrayMutators from 'final-form-arrays'
import { Paper, Stack, Typography } from '@mui/material'
import InfoOutlined from '@mui/icons-material/InfoOutlined'
import { isEmpty } from 'lodash'

import { CalloutButton, GeneratedComment } from 'client-shared/components'
import { PropertyTypes } from 'shared/constants'
import { generateUnitInspectionDiscussion } from 'shared/utils/textGeneration/property/unitInspection'
import { UNIT_INSPECTION_PATH } from 'shared/constants/report/keysAndDataPaths'

import wrapForm from '../../wrapForm'

import InspectedUnitsTable from './InspectedUnitsTable'

export const DATA_PATH = UNIT_INSPECTION_PATH
const TOOLTIP_TEXT =
  "The following generated text will appear in the Description of Improvements' Unit Inspection section of your report."

const UnitInspection = ({ form, reportSettings }) => {
  const {
    values: { units },
  } = form
  const { incomeType } = reportSettings

  const noUnitsInspected = isEmpty(units)

  const changeButtonLink =
    incomeType === PropertyTypes.COMMERCIAL ? './commercial-rent-roll' : './residential-rent-roll'

  return (
    <Paper sx={{ maxWidth: 1000 }}>
      <Stack spacing={2}>
        <Typography variant="h6">Unit Inspection</Typography>
        {noUnitsInspected && (
          <CalloutButton
            icon={<InfoOutlined />}
            text="You have not marked any units as inspected on your Rent Roll."
            link={changeButtonLink}
            linkText="Change"
            variant="info"
          />
        )}
        <InspectedUnitsTable form={form} />

        <GeneratedComment
          dataPath="unitInspectionDiscussion"
          getGeneratedText={generateUnitInspectionDiscussion}
          isDynamicContent
          label="Unit Inspection Discussion"
          title="Generated Commentary"
          tooltipText={TOOLTIP_TEXT}
        />
      </Stack>
    </Paper>
  )
}

export default wrapForm(DATA_PATH, {
  heading: 'Unit Inspection',
  mutators: { ...arrayMutators },
})(UnitInspection)
