import { invert } from 'lodash'

export const BELOW_GRADE_STATE_OPTIONS = ['Finished', 'Unfinished', 'Partially Finished']
export const BELOW_GRADE_ACCESS_CHECKBOX_OPTIONS = {
  interior: 'Interior',
  exterior: 'Exterior',
  other: 'Other',
}

export const UNDER_RENOVATION = 'Under Renovation'

export const BELOW_GRADE_ACCESS_KEYS = invert(BELOW_GRADE_ACCESS_CHECKBOX_OPTIONS)
