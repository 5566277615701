import { invert } from 'lodash'

export const OTHER_ACCESS_OPTION = 'other'

export const BELOW_GRADE_ACCESS_OPTIONS = ['interior', 'exterior', OTHER_ACCESS_OPTION]

export const BELOW_GRADE_TYPES = { BASEMENT: 'Basement', CELLAR: 'Cellar', NONE: 'None' }

export const INSPECTED_LOCATIONS_OPTIONS = {
  buildingFacade: 'Building Facade',
  exteriorEntrance: 'Exterior Entrance',
  interiorHallways: 'Interior Hallways',
  commonAreas: 'Common Areas',
  mechanicalAndElectricalSystems: 'Mechanical/electrical systems',
  stairs: 'Stairs',
  roof: 'Roof',
  other: 'Other(s)',
}

export const INSPECTED_LOCATION_KEYS = invert(INSPECTED_LOCATIONS_OPTIONS)

export const OBSERVATIONS_OPTIONS = {
  noHazardousSubstances: 'We observed no hazardous substances on the improvements',
  noAdverseConditions: 'We observed no adverse environmental or physical conditions',
}

export const GENERAL_CONDITIONS = {
  fair: 'Fair',
  satisfactory: 'Satisfactory',
  good: 'Good',
  excellent: 'Excellent',
  average: 'Average',
  poor: 'Poor',
  underRenovation: 'Under Renovation',
  shell: 'Shell',
  vacantLand: 'Vacant Land',
  other: 'Other',
}

export const FLOOR_STARTS = ['Basement', 'Ground Floor']

export const FLOOR_ENDS = ['X Floor', 'Roof']

export const STAIRS_LOCATION = ['Interior', 'Exterior']
