import React from 'react'
import PropTypes from 'prop-types'
import arrayMutators from 'final-form-arrays'
import createDecorator from 'final-form-calculate'
import { Tab } from '@mui/material'
import { TabContext, TabList } from '@mui/lab'

import { get } from 'lodash'
import Automation from 'report/forms/property/Zoning/Automation'

import { ZONING_PATH } from 'shared/constants/report/keysAndDataPaths'
import { GEOGRAPHY_OPTIONS } from 'shared/constants/property/geography'

import AutomationStatus from 'client-shared/components/AutomationStatus'

import wrapForm from '../../wrapForm'

import { PropertyTypes } from '../../../../../../shared/constants'

import ParkingTab from './ParkingTab'
import InformationTab from './InformationTab'
import ConformingUseTab from './ConformingUseTab'
import ComplyingBulkTab from './ComplyingBulkTab'
import { ZoningTabs, ZONING_TABS, ZONING_FIELD_NAMES } from './constants'
import { importZoning } from './redux/actions'

const heading = 'Zoning Description'

const styles = theme => ({
  wrapper: {
    width: 900,
  },
  paper: theme.paper,
  tabContainer: {
    marginBottom: theme.spacing.unit * 2,
    '& button': {
      flexShrink: 0,
    },
  },
  tabLabel: {
    fontSize: 14,
    letterSpacing: 1.3,
    padding: 'inherit',
  },
})

class Zoning extends React.PureComponent {
  state = {
    currentTab: ZoningTabs.INFORMATION,
  }

  componentDidUpdate(prevProps) {
    const { importedZoning } = this.props
    const shouldAddZoningToFormValues = importedZoning !== prevProps.importedZoning

    if (shouldAddZoningToFormValues) {
      this.addImportedZoningToFormValues()
    }
  }

  importZoning = reportId => {
    const { importZoning } = this.props

    importZoning({ reportId })
  }

  addImportedZoningToFormValues = () => {
    const { form, importedZoning } = this.props

    form.change('zones', importedZoning.zones)
    form.change('permittedUses', importedZoning.permittedUses)
    form.change('regulations', importedZoning.regulations)
  }

  onChangeTab = (event, value) => {
    this.setState({ currentTab: value })
  }

  renderTabContent = () => {
    const { form, isLoading, currentPath } = this.props
    const { currentTab } = this.state

    let tabComponent = null

    switch (currentTab) {
      case ZoningTabs.USES: {
        tabComponent = <ConformingUseTab form={form} />
        break
      }
      case ZoningTabs.BULK: {
        tabComponent = <ComplyingBulkTab form={form} />
        break
      }
      case ZoningTabs.PARKING: {
        tabComponent = <ParkingTab form={form} />
        break
      }
      default: {
        tabComponent = (
          <InformationTab
            form={form}
            importZoning={this.importZoning}
            isLoading={isLoading}
            currentPath={currentPath}
          />
        )
      }
    }

    return tabComponent
  }

  render() {
    const { classes, form } = this.props
    const { currentTab } = this.state
    const { automationMetadata } = form.values
    return (
      <div className={classes.wrapper}>
        {automationMetadata && (
          <AutomationStatus
            formPaths={['automationMetadata']}
            message="We automated the information below from Zoneomics"
          />
        )}
        <Automation />
        <TabContext value={currentTab}>
          <TabList
            onChange={this.onChangeTab}
            classes={{ root: classes.tabContainer }}
            scrollButtons={false}
            variant="fullWidth"
            visibleScrollbar
          >
            {ZONING_TABS.map(({ label, value, key }) => (
              <Tab key={key} value={value} label={label} classes={{ root: classes.tabLabel }} data-qa={`${key}-tab`} />
            ))}
          </TabList>
          {this.renderTabContent()}
        </TabContext>
      </div>
    )
  }
}

const requiredParkingSpacesWatcher = createDecorator(
  {
    field: ['requiredParkingPerUnit'],
    updates: (value, fieldName, allValues) => {
      const { residentialUnits = 0, locationIdentifier = '', propertyType } = allValues

      const shouldDisplayRequiredParkingPerUnits = locationIdentifier === GEOGRAPHY_OPTIONS.NY
      const isCommercial = propertyType === PropertyTypes.COMMERCIAL

      if (shouldDisplayRequiredParkingPerUnits && !isCommercial) {
        return {
          requiredParkingSpaces: value * residentialUnits,
        }
      } else {
        return false
      }
    },
  },
  {
    field: ['requiredParkingPerSF'],
    updates: (value, fieldName, allValues) => {
      const { grossBuildingArea = 0, locationIdentifier = '', propertyType } = allValues

      const shouldDisplayRequiredParkingPerUnits = locationIdentifier === GEOGRAPHY_OPTIONS.NY
      const isCommercial = propertyType === PropertyTypes.COMMERCIAL

      if (shouldDisplayRequiredParkingPerUnits && isCommercial) {
        return {
          requiredParkingSpaces: value > 0 ? value * (grossBuildingArea / 1000) : 0,
        }
      } else {
        return false
      }
    },
  }
)

Zoning.propTypes = {
  classes: PropTypes.object.isRequired,
  form: PropTypes.object.isRequired,
  isLoading: PropTypes.bool.isRequired,
  importedZoning: PropTypes.shape({
    zones: PropTypes.arrayOf(
      PropTypes.shape({
        commercialOverlay: PropTypes.string,
        zone: PropTypes.string,
      })
    ),
    permittedUses: PropTypes.arrayOf(PropTypes.string),
    regulations: PropTypes.arrayOf(
      PropTypes.shape({
        regulation: PropTypes.string,
      })
    ),
  }),
}

const formOptions = {
  heading,
  styles,
  enableReinitialize: true,
  mutators: { ...arrayMutators },
  decorators: [requiredParkingSpacesWatcher],
  registeredFields: ZONING_FIELD_NAMES,
}

export default wrapForm(
  ZONING_PATH,
  formOptions,
  state => {
    const currentPath = get(state, 'shared.location.form.id')

    const locationIdentifier = get(state, 'report.reportData.locationIdentifier')
    return {
      currentPath,
      isLoading: state.zoning.isLoading,
      importedZoning: state.zoning.importedZoning,
      initialValues: {
        ...get(state, `report.reportData.${ZONING_PATH.join('.')}`),
        locationIdentifier: locationIdentifier,
        propertyType: get(state, 'report.reportData.propertyType'),
      },
    }
  },
  { importZoning }
)(Zoning)
