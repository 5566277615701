import React from 'react'

import { capitalize } from 'lodash'

import { CircularProgress, Dialog, DialogContent, DialogTitle, IconButton, Stack, Typography } from '@mui/material'
import Close from '@mui/icons-material/Close'

import { getResizedImageUrl } from 'client-shared/utils/cloudinary'

const ImagePreviewDialog = ({ category, closeDialog, url }) => {
  const [imageLoaded, setImageLoaded] = React.useState(false)
  const previewUrl = React.useMemo(() => getResizedImageUrl({ url, width: 768 }), [url])

  return (
    <Dialog maxWidth={false} open>
      <DialogTitle>
        <Typography>{capitalize(category)}</Typography>
        <IconButton
          onClick={closeDialog}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
          }}
        >
          <Close />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <Stack sx={{ minHeight: 576, minWidth: 800 }} alignItems="center" justifyContent="center">
          <img src={previewUrl} onLoad={() => setImageLoaded(true)} alt={category} />
          {!imageLoaded && <CircularProgress />}
        </Stack>
      </DialogContent>
      <DialogContent></DialogContent>
    </Dialog>
  )
}

export default ImagePreviewDialog
