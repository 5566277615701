import React from 'react'

import { CircularProgress } from '@mui/material'
import { withStyles } from '@mui/styles'

import { LayoutZIndexes } from '../../../report/layout/stylesConstants'

const styles = theme => ({
  root: {
    position: 'absolute',
    top: 0,
    left: 0,
    zIndex: LayoutZIndexes.LOADING,
    width: '100%',
    height: '100%',
    background: 'rgba(255, 255, 255, 0.6)',
    minHeight: 40,
  },
  icon: {
    position: 'absolute',
    top: 'calc(50% - 20px)',
    left: 'calc(50% - 20px)',
  },
})

const Loading = ({ classes }) => (
  <div className={classes.root} data-qa="loading-modal">
    <CircularProgress size={40} className={classes.icon} />
  </div>
)

const StyledLoading = withStyles(styles)(Loading)

StyledLoading.displayName = 'Loading'

export default StyledLoading
