import { GeneratedText, gtConditional, gtLocked, gtTemplate } from '../../../narrative/GeneratedText'

import { mapDTO, mapDataModel } from './mappings'

const generate = () => {
  const gt = new GeneratedText()

  const belowGradeTypeGt = gtLocked('belowGradeType', null)
  const belowGradeAccessGt = gtLocked('belowGradeAccess', null)
  const stateGt = gtLocked('state', null)
  const isVerb = [
    gtConditional('valueAsComplete', gtTemplate` will be`),
    gtConditional('doNotValueAsComplete', gtTemplate` is`),
  ]

  const belowGradeDoesNotExistSentence = gtConditional('belowGradeDoesNotExist', [
    ...gtTemplate`The subject`,
    gtConditional('valueAsComplete', gtTemplate` will`),
    gtConditional('doNotValueAsComplete', gtTemplate` does`),
    ...gtTemplate` not contain a basement or a cellar.`,
  ])

  const belowGradeExistsSentence = gtConditional('belowGradeExists', [
    ...gtTemplate`The ${belowGradeTypeGt}`,
    ...isVerb,
    ...gtTemplate` accessed from the ${belowGradeAccessGt} of the building, and`,
    ...isVerb,
    ...gtTemplate` ${stateGt}.`,
  ])

  gt.addParts([belowGradeDoesNotExistSentence, belowGradeExistsSentence])

  return gt.getParts()
}

export default { generate, mapDTO, mapDataModel }
