import React from 'react'
import { Form } from 'react-final-form'
import { makeStyles } from '@mui/styles'

import { capitalizeFirstLetter } from '../../../../../../../shared/utils/formatters/textFormatters'
import Text from '../../../../../shared/components/Text'
import Grid from '../../../../../shared/components/_mui5/Grid'
import Button from '../../../../../shared/components/_mui5/Button'
import { required } from '../../../../../shared/utils/validation'

const useStyles = makeStyles({
  root: {
    width: '100%',
  },
  inputRoot: {
    paddingTop: 8,
    paddingBottom: 8,
  },
})

const EditColumnForm = ({ onSubmit, onCancel, colDef }) => {
  const initialValues = {
    name: colDef.field,
    label: colDef.headerName,
    type: colDef.type,
    permanent: colDef.permanent,
  }

  const classes = useStyles()

  return (
    <Form onSubmit={onSubmit} initialValues={initialValues}>
      {({ handleSubmit, invalid }) => {
        return (
          <Grid container spacing={1} className={classes.root}>
            <Grid item xs={12}>
              <Text
                name="label"
                label="Name of Column"
                fullWidth
                validate={required}
                parse={capitalizeFirstLetter}
                classes={{ input: classes.inputRoot }}
              />
            </Grid>

            <Grid item xs={12} container justifyContent="end" spacing={1}>
              <Grid item>
                <Button type="button" data-qa="cancel-submit-btn" onClick={onCancel}>
                  Cancel
                </Button>
              </Grid>
              <Grid item>
                <Button
                  onClick={handleSubmit}
                  data-qa="form-submit-btn"
                  disabled={invalid}
                  variant="contained"
                  type="submit"
                >
                  Save
                </Button>
              </Grid>
            </Grid>
          </Grid>
        )
      }}
    </Form>
  )
}

export default React.memo(EditColumnForm)
