import { find } from 'lodash'

import { RentType, RentTypeAnnualIncrease, ResidentialUnit } from './types'

export const getAnnualIncreaseForRentType = (rentType: RentType, rentTypeAnnualIncreases: RentTypeAnnualIncrease[]) => {
  const rentTypeIncrease = find(rentTypeAnnualIncreases, increaseItem => increaseItem.rentType === rentType)
  return rentTypeIncrease ? rentTypeIncrease.increase : 1
}

// This still depends on the shape of "units" - think through a better way to do this, maybe send in "rents"??
export const filterUnitsByRentType = (rentType: RentType, units: ResidentialUnit[]) => {
  return units.filter(unit => unit.rentType === rentType)
}
