import { get } from 'lodash'
import { connect } from 'react-redux'

import { reportFetch, reportClear } from '../redux/actions/report'

import { locationSet } from '../../shared/redux/actions/location'
import { setPulledFloodData, clearPulledFloodData } from '../../shared/redux/actions/pulledFloodData'

import Layout from './Layout'
import { formsListSelector } from './selectors'

const mapStateToProps = state => {
  const username = get(state, 'authentication.user.username')

  return {
    submitting: get(state, 'report.submitting'),
    reportLoaded: get(state, 'report.isLoaded'),
    forms: formsListSelector(state),
    username,
  }
}

export default connect(mapStateToProps, {
  reportFetch,
  locationSet,
  reportClear,
  setPulledFloodData,
  clearPulledFloodData,
})(Layout)
