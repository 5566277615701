import Amplitude from 'amplitude-js'
import { isNil, startCase } from 'lodash'

import moment from 'moment'

let INITIALIZED_AMPLITUDE = false
export let amplitude = null

export const initializedAmplitude = () => INITIALIZED_AMPLITUDE

export const initAmplitude = () => {
  Amplitude.getInstance().init(global.env.amplitudeApiKey, null, null, instance => {
    INITIALIZED_AMPLITUDE = true
    amplitude = instance
  })
}

export const setAmplitudeUser = user => {
  if (initializedAmplitude() && user?._id) {
    amplitude.setUserId(user._id)

    const identify = new amplitude.Identify()
      .set('name', user?.fullName)
      .set('first_name', user?.name?.first)
      .set('last_name', user?.name?.last)
      .set('email', user?.username)
      .set('position', user?.position)

    amplitude.identify(identify)
  }
}

const wrapAmplitudeEvent = event => {
  const renamedProperties = {}

  for (const prop in event.properties) {
    renamedProperties[startCase(prop)] = event.properties[prop]
  }

  const amplitudeEvent = {
    type: event.label,
    properties: {
      Timestamp: moment(event.timestamp).toISOString(),
      'Report ID': event.reportId,
      ...renamedProperties,
    },
    unix_timestamp_ms: moment(event.timestamp).valueOf(),
  }

  if (event.path) {
    amplitudeEvent.properties['Path'] = event.path
  }

  return amplitudeEvent
}

export const dispatchEvent = event => {
  if (event && global.env.analyticsEnabled && initializedAmplitude()) {
    const amplitudeEvent = wrapAmplitudeEvent(event)
    amplitude.logEventWithTimestamp(
      amplitudeEvent.type,
      amplitudeEvent.properties,
      amplitudeEvent.unix_timestamp_ms,
      (status, body, message) => {
        if (global.env.debugAmplitude && !isNil(status) && parseInt(status) !== 200) {
          console.log('Sent event: ', event)
          console.log('status: ', status)
          console.log('body:\n', body)
          console.log('message: ', message)
        }
      }
    )
  }
}
