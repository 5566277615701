import React from 'react'
import PropTypes from 'prop-types'

import classNames from 'classnames'
import { includes, filter, concat } from 'lodash'
import { Checkbox, FormControl, FormControlLabel, FormGroup, FormLabel } from '@mui/material'
import { Field } from 'react-final-form'

const MultiSelectCheckboxGroupItem = ({ input, items, label, margin, fullWidth, required, classes }) => (
  <FormControl className={classes.root} required={required}>
    {label && (
      <FormLabel component="legend" className={classes.label}>
        {label}
      </FormLabel>
    )}
    <FormGroup className={classes.container} data-qa={`${input.name}-checkbox-group`} row={fullWidth}>
      {items.map(item => {
        const checked = includes(input.value, item.value)

        const onChange = () => {
          const nextValue = checked
            ? filter(input.value, value => value !== item.value)
            : concat(input.value, [item.value])
          input.onChange(nextValue)
        }

        return (
          <FormControlLabel
            key={item.value}
            control={
              <Checkbox
                checked={checked}
                onChange={onChange}
                label={item.label}
                data-qa={`${item.value}-checkbox${checked ? '-checked' : ''}`}
                value={checked.toString()}
                className={classNames(classes.checkbox, {
                  [classes.checkedCheckbox]: checked,
                })}
              />
            }
            label={item.label}
          />
        )
      })}
    </FormGroup>
  </FormControl>
)

MultiSelectCheckboxGroupItem.propTypes = {
  input: PropTypes.shape({
    name: PropTypes.string,
    value: PropTypes.array,
    onChange: PropTypes.func,
  }).isRequired,
  items: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.any,
      label: PropTypes.string,
    })
  ).isRequired,
  classes: PropTypes.object,
  required: PropTypes.bool,
  label: PropTypes.string,
}

MultiSelectCheckboxGroupItem.defaultProps = {
  classes: {},
  required: false,
}

export const MultiSelectCheckboxGroup = ({ name, ...restProps }) => {
  return <Field name={name} component={MultiSelectCheckboxGroupItem} format={value => value || []} {...restProps} />
}

MultiSelectCheckboxGroup.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string,
  items: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
    })
  ).isRequired,
  margin: PropTypes.oneOf(['none', 'dense', 'normal']),
  fullWidth: PropTypes.bool,
  required: PropTypes.bool,
}

MultiSelectCheckboxGroup.defaultProps = {
  label: '',
  margin: 'none',
  fullWidth: false,
  required: false,
}
