import React from 'react'
import PropTypes from 'prop-types'
import { Typography } from '@material-ui/core'

import { SECONDS_IN_MINUTE, TICK_INTERVAL } from './constants'

class CountdownTimer extends React.PureComponent {
  constructor(props) {
    super(props)
    this.timer = 0
    this.state = {
      remainingSeconds: props.approximateReportGenerationTime,
    }
  }

  static propTypes = {
    approximateReportGenerationTime: PropTypes.number.isRequired,
    children: PropTypes.func,
    id: PropTypes.string,
  }

  static defaultProps = {
    children: () => {},
  }

  componentDidMount() {
    const { remainingSeconds } = this.state
    if (remainingSeconds !== 0) {
      this.timer = setInterval(this.countDown, TICK_INTERVAL)
    }
  }

  componentWillUnmount() {
    clearInterval(this.timer)
  }

  countDown = () => {
    const { remainingSeconds } = this.state
    if (remainingSeconds === 0) {
      clearInterval(this.timer)
      return
    }
    this.setState({
      remainingSeconds: remainingSeconds - 1,
    })
  }

  getFormattedRemainingTime = () => {
    const { remainingSeconds } = this.state
    const minutes = Math.floor(remainingSeconds / SECONDS_IN_MINUTE)
    const seconds = remainingSeconds - minutes * SECONDS_IN_MINUTE
    return {
      minutes,
      seconds,
    }
  }

  render() {
    const { id, children } = this.props
    const { remainingSeconds } = this.state
    const { minutes, seconds } = this.getFormattedRemainingTime()
    return (
      <React.Fragment>
        {children({ remainingSeconds })}
        {remainingSeconds !== 0 && (
          <Typography id={id} variant="caption">
            {!!minutes && `${minutes} ${minutes > 1 ? 'minutes' : 'minute'} `}
            {seconds} seconds remaining
          </Typography>
        )}
      </React.Fragment>
    )
  }
}

export default CountdownTimer
