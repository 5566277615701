import { find, max, mean, min, sumBy } from 'lodash'

import { divide } from '../../../../shared/utils/numberOperations'

import {
  getAdjustment,
  getAdjustmentGroup,
  getAdjustmentValue,
  getTotalUtilityAdjustments,
} from '../../../../shared/helpers/salesApproach/salesCompAdjustments'
import { IncomeAdjustmentKeys } from '../../../../shared/constants/report/sales/salesAdjustment'

import { getTrendAdjustedPricePerBasis } from '../../../../client/src/report/forms/sales/calculations'
import SalesApproachCalculations from '../../../../shared/report-calculations/sales-approach/sales-approach-calculations'
import { getBasis, getSalePriceToUse } from '../../../../shared/report-calculations/sales-approach/helpers'

export default class {
  getTotalLocationAdjustment(adjustments) {
    const neighborhoodAdjustment = getAdjustment(adjustments, 'neighborhood')
    const locationInNeighborhoodAdjustment = getAdjustment(adjustments, 'locationInNeighborhood')
    return (neighborhoodAdjustment?.value || 0) + (locationInNeighborhoodAdjustment?.value || 0)
  }

  getTotalUtilityAdjustment(adjustments) {
    return getTotalUtilityAdjustments(adjustments)
  }

  estimateSalesComparableNoi(capRate, salesPrice) {
    if (!capRate || !salesPrice) {
      return 'N/A'
    }

    return capRate * salesPrice
  }

  estimateNetOperatingIncomePerBasis(capRate, salesPrice, unitOfComaprison, propertyInformation) {
    const netOperatingIncome = capRate * salesPrice
    const basis = getBasis(unitOfComaprison, propertyInformation)
    return netOperatingIncome ? parseFloat(divide(netOperatingIncome, basis).toFixed(0)) : 0
  }

  getNoiAdjustment(
    capRate,
    salesPrice,
    propertyInformation,
    unitOfComparison,
    temper,
    subjectNetOperatingIncome,
    subjectPropertyInformation
  ) {
    if (!capRate || !subjectNetOperatingIncome) {
      return 0
    }
    const netOperatingIncomePerBasis = this.estimateNetOperatingIncomePerBasis(
      capRate,
      salesPrice,
      unitOfComparison,
      propertyInformation
    )
    const subjectNetOperatingIncomePerBasis = SalesApproachCalculations.calculateNetOperatingIncomePerBasis(
      subjectNetOperatingIncome,
      unitOfComparison,
      subjectPropertyInformation
    )

    const percentAdjustment =
      -1 * divide(netOperatingIncomePerBasis - subjectNetOperatingIncomePerBasis, netOperatingIncomePerBasis) * temper

    // need to round netOperatingIncome adjustments to nearest 5%
    const roundedPercentage = parseFloat((0.05 * Math.round(percentAdjustment / 0.05)).toFixed(2))
    return roundedPercentage
  }

  getTotalNonMarketAdjustmentsPercentage(
    totalUtilityAdjustment = 0,
    totalLocationAdjustment = 0,
    noiAdjustment = 0,
    otherAdjustments = [],
    incomeAdjustmentLevel = null
  ) {
    const location = totalLocationAdjustment
    const utility = totalUtilityAdjustment
    const income = noiAdjustment
    const stabilizationLevel = getAdjustmentValue(otherAdjustments, 'stabilizationLevel')
    const otherAdjustmentsWithoutStabilizationLevel = sumBy(otherAdjustments, 'value') - stabilizationLevel || 0

    let incomeAdjustment = 0
    if (incomeAdjustmentLevel === IncomeAdjustmentKeys.income) {
      incomeAdjustment = income
    } else if (incomeAdjustmentLevel === IncomeAdjustmentKeys.rentStabilization) {
      incomeAdjustment = stabilizationLevel
    }

    return location + incomeAdjustment + utility + otherAdjustmentsWithoutStabilizationLevel
  }

  getAdjustedSalesPricePerBasis(trendAdjustedPricePerBasis, totalNonMarketAdjustmentsPercentage) {
    return (1 + totalNonMarketAdjustmentsPercentage) * trendAdjustedPricePerBasis
  }

  // this method should only be used when preparing XML files for Greystone
  getTotalAdjustedSalesPrice(adjustedPricePerUnitOrPerSquareFoot, unitOfComparison, propertyInformation) {
    const salePrice = SalesApproachCalculations.calculateSalePriceFromBasis(
      adjustedPricePerUnitOrPerSquareFoot,
      unitOfComparison,
      propertyInformation
    )
    return parseFloat(salePrice.toFixed(2))
  }

  getTrendAdjustedPricePerBasis(comp, adjustments, unitOfComaprison) {
    return getTrendAdjustedPricePerBasis(comp, adjustments, unitOfComaprison)
  }

  getSalePricePerUnitOrPerSquareFoot(salesComp, unitOfComaprison) {
    const { propertyInformation, saleInformation } = salesComp
    const salePrice = getSalePriceToUse(saleInformation)
    return SalesApproachCalculations.calculateSalePricePerBasis(salePrice, unitOfComaprison, propertyInformation)
  }

  createAdjustedCompsObject(
    comps,
    compAdjustments,
    shouldReturnAdjustedValues,
    unitOfComparison,
    temper,
    subjectNetOperatingIncome,
    subjectPropertyInformation,
    incomeAdjustmentLevel
  ) {
    const adjustedComps = comps.map(comp => {
      const { propertyInformation, saleInformation } = comp
      const compAdjustment = find(compAdjustments, adjustment => adjustment.compId === comp._id?.toString())
      const adjustments = compAdjustment?.adjustments || []
      const { capRate, salePrice } = saleInformation

      if (shouldReturnAdjustedValues) {
        const noiAdjustment = this.getNoiAdjustment(
          capRate,
          salePrice,
          propertyInformation,
          unitOfComparison,
          temper,
          subjectNetOperatingIncome,
          subjectPropertyInformation
        )
        const utilityAdjustment = this.getTotalUtilityAdjustment(adjustments)
        const locationAdjustment = this.getTotalLocationAdjustment(adjustments)
        const otherAdjustments = getAdjustmentGroup(adjustments, 'other')
        const nonMarketAdjustmentsPercentage = this.getTotalNonMarketAdjustmentsPercentage(
          utilityAdjustment,
          locationAdjustment,
          noiAdjustment,
          otherAdjustments,
          incomeAdjustmentLevel
        )
        const trendAdjustedPrice = this.getTrendAdjustedPricePerBasis(comp, adjustments, unitOfComparison)

        return this.getAdjustedSalesPricePerBasis(trendAdjustedPrice, nonMarketAdjustmentsPercentage)
      }
      return this.getSalePricePerUnitOrPerSquareFoot(comp, unitOfComparison)
    })
    const getMedianCompRent = () => {
      adjustedComps.sort((compA, compB) => compA - compB)
      const middleIndex = Math.floor((adjustedComps.length - 1) / 2)
      if (adjustedComps.length % 2) {
        return adjustedComps[middleIndex]
      }
      return mean([adjustedComps[middleIndex], adjustedComps[middleIndex + 1]])
    }

    return {
      min: min(adjustedComps),
      avg: mean(adjustedComps),
      median: getMedianCompRent(),
      max: max(adjustedComps),
    }
  }

  getPriceDataForComps = (
    comps,
    shouldReturnAdjustedValues,
    unitOfComparison,
    temper,
    subjectNetOperatingIncome,
    subjectPropertyInformation,
    incomeAdjustmentLevel,
    compAdjustments
  ) => {
    const adjustedComps = comps.map(comp => {
      const { propertyInformation, saleInformation } = comp
      const { capRate } = saleInformation
      const salePrice = parseFloat(getSalePriceToUse(saleInformation))
      const compId = comp.id || comp._id.toString()
      const adjustments =
        find(compAdjustments, adjustment => adjustment?.compId.toString() === compId)?.adjustments || []

      if (shouldReturnAdjustedValues) {
        const noiAdjustment = this.getNoiAdjustment(
          capRate,
          salePrice,
          propertyInformation,
          unitOfComparison,
          temper,
          subjectNetOperatingIncome,
          subjectPropertyInformation
        )
        const utilityAdjustment = this.getTotalUtilityAdjustment(adjustments)
        const locationAdjustment = this.getTotalLocationAdjustment(adjustments)
        const otherAdjustments = getAdjustmentGroup(adjustments, 'other')
        const trendAdjustedPricePerBasis = this.getTrendAdjustedPricePerBasis(comp, adjustments, unitOfComparison)
        const totalNonMarketAdjustmentsPercentage = this.getTotalNonMarketAdjustmentsPercentage(
          utilityAdjustment,
          locationAdjustment,
          noiAdjustment,
          otherAdjustments,
          incomeAdjustmentLevel
        )

        return this.getAdjustedSalesPricePerBasis(trendAdjustedPricePerBasis, totalNonMarketAdjustmentsPercentage)
      }
      return SalesApproachCalculations.calculateSalePricePerBasis(salePrice, unitOfComparison, propertyInformation)
    })

    const getMedianCompRent = compRents => {
      compRents.sort((compA, compB) => compA - compB)
      const middleIndex = Math.floor((compRents.length - 1) / 2)

      if (compRents.length % 2) {
        return compRents[middleIndex]
      }
      return mean([compRents[middleIndex], compRents[middleIndex + 1]])
    }

    return {
      min: min(adjustedComps),
      avg: mean(adjustedComps),
      median: getMedianCompRent(adjustedComps),
      max: max(adjustedComps),
    }
  }
}
