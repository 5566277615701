import { toLower, camelCase } from 'lodash'

import { Labels as PgiLabels } from '../../client/src/report/forms/income/PotentialGrossIncome/PotentialGrossIncomeConstants'
import { toPercentageString } from '../../client/src/shared/utils/numberFormatters'
import { USES } from '../../libs/bowery-libs/constants/report/commercial-unit'
import { toCapitalCase } from '../utils/formatters/textFormatters'

const DEFAULT_COMMERCIAL_INCOME_USE_LABELS = {
  retail: PgiLabels.LESS_RETAIL_VC_LOSS,
  office: PgiLabels.LESS_OFFICE_VC_LOSS,
  medical: PgiLabels.LESS_MEDICAL_VC_LOSS,
  community: PgiLabels.LESS_COMMUNITY_VC_LOSS,
  industrial: PgiLabels.LESS_INDUSTRIAL_VC_LOSS,
  undetermined: PgiLabels.LESS_UNDETERMINED_VC_LOSS,
}

export const UnitTypesLabel = {
  retail: 'retail',
  office: 'office',
  medical: 'medical office',
  community: 'community facility',
  industrial: 'industrial',
  undetermined: 'undetermined',
}

export const getUseLabel = use => {
  return UnitTypesLabel[use] || use
}

export const getCapitalizedUseLabel = use => {
  return toCapitalCase(getUseLabel(use))
}

export const getLowerCasedUseLabel = use => {
  return toLower(getUseLabel(use))
}

export const getCommercialIncomeLabel = use => {
  const defaultUseLabels = {
    retail: PgiLabels.POTENTIAL_RETAIL_INCOME,
    office: PgiLabels.POTENTIAL_OFFICE_INCOME,
    medical: PgiLabels.POTENTIAL_MEDICAL_INCOME,
    community: PgiLabels.POTENTIAL_COMMUNITY_INCOME,
    industrial: PgiLabels.POTENTIAL_INDUSTRIAL_INCOME,
    undetermined: PgiLabels.POTENTIAL_UNDETERMINED_INCOME,
  }
  return defaultUseLabels[use] || `Potential ${toCapitalCase(use)} Income`
}

export const getCommercialLossLabel = (use, lossPercentage) => {
  const label = DEFAULT_COMMERCIAL_INCOME_USE_LABELS[use] || `Less ${toCapitalCase(use)} V/C Loss`
  return `${label} @ ${toPercentageString(lossPercentage)}`
}

export const getCommercialLossRowName = use => {
  const label = DEFAULT_COMMERCIAL_INCOME_USE_LABELS[use] || `Less ${toCapitalCase(use)} V/C Loss`
  return camelCase(label)
}

export const getUsesInOrder = includedUses => {
  return Object.keys(includedUses).sort((useA, useB) => {
    const useAIndex = USES.indexOf(useA)
    const useBIndex = USES.indexOf(useB)

    if (useAIndex > -1 && useBIndex > -1) {
      return useAIndex - useBIndex
    }

    if (useAIndex === -1 && useBIndex === -1) {
      return 0
    }

    if (useAIndex === -1) {
      return 1
    }

    return -1
  })
}
