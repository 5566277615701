import React from 'react'
import PropTypes from 'prop-types'

import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Typography } from '@mui/material'
import { withStyles } from '@mui/styles'

const styles = theme => ({
  dialogContent: {
    width: 424,
  },
})

class FormConfirmModal extends React.PureComponent {
  static propTypes = {
    showConfirmModal: PropTypes.bool.isRequired,
    loggingOut: PropTypes.bool.isRequired,
    onSubmit: PropTypes.func.isRequired,
    onCancel: PropTypes.func.isRequired,
    onReject: PropTypes.func.isRequired,
  }

  handleKeyPress = event => {
    if (event.key === 'Enter' && event.target.name !== 'form-confirm-reject-btn') {
      this.props.onSubmit()
    }
  }

  render() {
    const { classes, showConfirmModal, loggingOut, onSubmit, onCancel, onReject } = this.props
    let message = 'Would you like to save before continuing?'
    let rejectText = 'No'
    let submitText = 'Yes'
    if (loggingOut) {
      message = 'Are you sure you want to log out?'
      rejectText = 'Log Out'
      submitText = 'Save & Log Out'
    }

    return (
      <Dialog
        aria-labelledby="form-dialog-title"
        open={showConfirmModal}
        onKeyPress={this.handleKeyPress}
        onClose={onCancel}
      >
        <div className={classes.dialogContent} data-qa="form-confirm-dialog">
          <DialogTitle id="form-dialog-title">
            <Typography variant="h6" component="span">
              You have unsaved changes.
            </Typography>
          </DialogTitle>
          <DialogContent className={classes.content}>
            <DialogContentText>{message}</DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button data-qa="form-confirm-reject-btn" name="form-confirm-reject-btn" onClick={onReject} variant="text">
              {rejectText}
            </Button>
            <Button
              data-qa="form-confirm-submit-btn"
              name="form-confirm-submit-btn"
              onClick={onSubmit}
              variant="contained"
            >
              {submitText}
            </Button>
          </DialogActions>
        </div>
      </Dialog>
    )
  }
}

export default withStyles(styles)(FormConfirmModal)
