import React from 'react'
import PropTypes from 'prop-types'

import ImportFromReportDialog from '../ImportFromReportDialog'

const CompsImportForm = props => {
  return (
    <ImportFromReportDialog
      {...props}
      openDialogButtonProps={{
        label: 'Import Comps',
        ...props.openDialogButtonProps,
      }}
    />
  )
}
CompsImportForm.propTypes = {
  dialogContent: PropTypes.string,
}

CompsImportForm.defaultProps = {
  dialogContent: 'Input the ID of the report you would like to import comps from',
}

export default CompsImportForm
