import { GeneratedText, gtConditional, gtLocked, gtTemplate } from '../../../../narrative/GeneratedText'

import { mapDTO, mapDataModel } from './mappings'

const generate = () => {
  const gt = new GeneratedText()
  const assistantAppraisersGt = gtLocked('appraiserAssistantsPhrase', null, 'Assistant Appraisers')
  gt.addPart(
    gtConditional(
      'isAppraiserCountGreaterThanZero',
      gtTemplate`${assistantAppraisersGt} provided significant real property appraisal assistance to
  the person signing this certification.`
    )
  )
  gt.addPart(
    gtConditional(
      'isAppraiserCountEqualToZero',
      gtTemplate`No one provided significant real property appraisal assistance
      to the person signing this certification.`
    )
  )
  return gt.getParts()
}

export default { generate, mapDTO, mapDataModel }
