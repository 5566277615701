import React, { useCallback, useEffect, useMemo } from 'react'
import { connect } from 'react-redux'
import { get, pick } from 'lodash'
import PropTypes from 'prop-types'
import { matchPath, withRouter } from 'react-router'
import { compose } from 'recompose'

import { CompPlexEvents } from 'client-shared/constants/compPlex'
import { errorNotification } from 'client-shared/redux/actions/notifications'
import { getAuthorizationHeader } from 'core/api'
import EditSalesComp from 'report/forms/final/CapRateComps/EditSalesComp'
import { EDIT_COMP_SOURCE_LOCATION } from 'report/forms/sales/SalesCompsSearch/SalesCompsSearchContainer'

import { mapCompPlexSalesCompToCapRateComp, transformSalesCompToRawOutput } from './helpers'

const useListeners = (compPlexRef, listeners) => {
  const { handleError, handleMapCompAdded, handleMapCompRemoved, handleMapSearchResultsUpdated } = listeners

  useEffect(() => {
    const compPlex = compPlexRef.current

    if (compPlex) {
      compPlex.addEventListener(CompPlexEvents.ERROR, handleError)
      compPlex.addEventListener(CompPlexEvents.MAP_COMP_ADDED, handleMapCompAdded)
      compPlex.addEventListener(CompPlexEvents.MAP_COMP_REMOVED, handleMapCompRemoved)
      compPlex.addEventListener(CompPlexEvents.MAP_SEARCH_RESULTS_UPDATED, handleMapSearchResultsUpdated)
    }

    return () => {
      if (compPlex) {
        compPlex.removeEventListener(CompPlexEvents.ERROR, handleError)
        compPlex.removeEventListener(CompPlexEvents.MAP_COMP_ADDED, handleMapCompAdded)
        compPlex.removeEventListener(CompPlexEvents.MAP_COMP_REMOVED, handleMapCompRemoved)
        compPlex.removeEventListener(CompPlexEvents.MAP_SEARCH_RESULTS_UPDATED, handleMapSearchResultsUpdated)
      }
    }
  }, [compPlexRef, handleError, handleMapCompAdded, handleMapCompRemoved, handleMapSearchResultsUpdated])
}

const SalesCompSearch = ({
  form,
  addComp,
  removeComp,
  editCompProps,
  setEditCompProps,
  compPlexRef,
  authenticatedUser,
  subjectCoordinates,
  subjectProps,
  match,
  location,
  errorNotification,
  reportId,
}) => {
  const { reportNumber } = form.values
  const reportData = { jobNumber: reportNumber, reportId }

  const selectedCompIds = useMemo(() => {
    return form.values.capRateComps
      .map(comp => ({
        salesTransactionId: comp.salesEventId,
      }))
      .filter(comp => comp.salesTransactionId)
  }, [form.values.capRateComps])

  const handleError = useCallback(
    event => {
      const { error } = event.detail
      errorNotification(error.message || error)
    },
    [errorNotification]
  )
  const handleMapCompAdded = useCallback(
    event => {
      const { salesComp } = event.detail
      const capRateComp = mapCompPlexSalesCompToCapRateComp(salesComp)
      addComp(capRateComp)
    },
    [addComp]
  )
  const handleMapCompRemoved = useCallback(
    event => {
      const { saleTransactionId } = event.detail
      const { capRateComps } = form.values
      const comp = capRateComps.find(comp => comp.salesEventId === saleTransactionId)

      if (comp) {
        removeComp(comp)
      }
    },
    [form.values, removeComp]
  )

  const handleMapSearchResultsUpdated = useCallback(
    event => {
      // TODO: Confirm whether it's possible to have the same comp multiple times (within or across selected/removed)
      const syncCompsWithEditedSearchResult = comp => {
        const selectedComps = form.values.capRateComps
        const removedComps = form.values.removedCapRateComps

        const outdatedSelectedCompIndex = selectedComps.findIndex(
          salesComp => salesComp.salesEventId === comp.salesEventId
        )
        if (outdatedSelectedCompIndex >= 0) {
          form.change(`capRateComps.${outdatedSelectedCompIndex}`, comp)
          return
        }

        const outdatedRemovedCompIndex = removedComps.findIndex(
          salesComp => salesComp.salesEventId === comp.salesEventId
        )
        if (outdatedRemovedCompIndex >= 0) {
          form.change(`removedCapRateComps.${outdatedRemovedCompIndex}`, comp)
        }
      }

      const { salesComp } = event.detail
      const localSalesComp = transformSalesCompToRawOutput(salesComp)
      syncCompsWithEditedSearchResult(localSalesComp)
    },
    [form]
  )

  useListeners(compPlexRef, {
    handleError,
    handleMapCompAdded,
    handleMapCompRemoved,
    handleMapSearchResultsUpdated,
  })

  const getCompIdToEditFromLocation = useCallback(() => {
    // This URL is navigated to in CapRateComps.jsx
    const pathMatch = matchPath(location.pathname, { path: `${match.path}/details/:salesCompId` })
    return pathMatch ? pathMatch.params.salesCompId : null
  }, [location.pathname, match.path])

  const getLocalCompToEditFromLocation = useCallback(() => {
    const selectedCapRateComps = form.values.capRateComps
    const salesCompId = getCompIdToEditFromLocation()
    if (!salesCompId) {
      return null
    }

    return selectedCapRateComps.find(salesComp => salesCompId === salesComp._id) || null
  }, [form.values.capRateComps, getCompIdToEditFromLocation])

  const editCompPropsFromLocation = useMemo(() => {
    const localSalesComp = getLocalCompToEditFromLocation()
    if (!localSalesComp) {
      return null
    }

    if (localSalesComp.salesEventId && localSalesComp.salesEventVersion) {
      return {
        source: EDIT_COMP_SOURCE_LOCATION,
        salesTransactionId: localSalesComp.salesEventId,
        salesTransactionVersion: localSalesComp.salesEventVersion,
        requireLatest: true,
      }
    } else {
      return null
    }
  }, [getLocalCompToEditFromLocation])

  return (
    <>
      <comp-plex
        ref={compPlexRef}
        edit-comp-props={JSON.stringify(editCompPropsFromLocation || editCompProps)}
        subject={JSON.stringify(subjectProps)}
        auth-user={JSON.stringify(authenticatedUser)}
        webapp-auth-header={getAuthorizationHeader().Authorization}
        webapp-api-url={global.env.apiUrl}
        subject-coordinates={JSON.stringify(subjectCoordinates)}
        selected-comps={JSON.stringify(selectedCompIds)}
        hide-job-search-tab="true"
        comp-type="cap-rate"
        meta-data={JSON.stringify(reportData)}
      ></comp-plex>
      <EditSalesComp
        form={form}
        compPlexRef={compPlexRef}
        selectedComps={form.values.capRateComps}
        setEditCompProps={setEditCompProps}
        removeComp={removeComp}
        getCompIdToEditFromLocation={getCompIdToEditFromLocation}
        getLocalCompToEditFromLocation={getLocalCompToEditFromLocation}
      />
    </>
  )
}

SalesCompSearch.propTypes = {
  compPlexRef: PropTypes.object.isRequired,
  form: PropTypes.object.isRequired,
  addComp: PropTypes.func.isRequired,
  removeComp: PropTypes.func.isRequired,
  editCompProps: PropTypes.object,
  setEditCompProps: PropTypes.func.isRequired,

  authenticatedUser: PropTypes.object.isRequired,
  subjectCoordinates: PropTypes.object.isRequired,
  subjectProps: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired,
  errorNotification: PropTypes.func.isRequired,
  reportId: PropTypes.string,
}

export default compose(
  withRouter,
  connect(
    state => {
      const propertyInformation = get(state, 'report.reportData.propertyInformation')
      const propertySummary = get(propertyInformation, 'propertySummary', {})

      const subjectCoordinates = {
        lat: get(propertyInformation, 'coords.latitude'),
        lng: get(propertyInformation, 'coords.longitude'),
      }
      const subjectProps = {
        coordinates: subjectCoordinates,
        propertyType: get(state, 'report.reportData.propertyType'),
        marketAnalysisUses: propertyInformation.propertyMarket?.marketAnalysisUses || {},
        residentialUnits: propertySummary.residentialUnitCount,
        grossBuildingArea: propertySummary.grossBuildingArea,
      }

      const reportId = get(state, 'report.reportData._id')

      return {
        authenticatedUser: pick(get(state, 'authentication.user'), ['id', 'username', 'fullName']),
        subjectCoordinates,
        subjectProps,
        reportId,
      }
    },
    dispatch => ({
      errorNotification: error => dispatch(errorNotification({ message: error })),
    })
  )
)(SalesCompSearch)
