import { ColumnDataTypeEnum } from '@bowery-valuation/ui-components'
import { divide } from 'lodash'

import { formatCurrencyFloat, formatPercentageString } from 'shared/utils/formatters/numberFormatters'
import InsurableValueCalculations from 'shared/report-calculations/insurable-value/insurable-value-calculations'

import {
  REFINEMENT_IDS,
  MULTIPLIER_IDS,
  BUILDING_INFO_IDS,
  BASE_UNIT_COST_ID,
  AREA_ID,
  EXCLUSION_TABLE_IDS,
} from 'shared/constants/insurableValue/mvs'

import { CLASS_LIST, CLASS_TYPE_LIST, FINISHED_LIST, GRADE_LIST, arrayToValueLabelOption } from './constants'
import { BuildingComponent } from './types'

export const normalizeIndex = (index: number) => index - 1

export const getType = (id: string) => {
  switch (id) {
    case REFINEMENT_IDS.hvacClimate:
    case REFINEMENT_IDS.elevator:
    case REFINEMENT_IDS.sprinklers:
    case BASE_UNIT_COST_ID:
      return ColumnDataTypeEnum.money
    case MULTIPLIER_IDS.stories:
    case MULTIPLIER_IDS.height:
    case MULTIPLIER_IDS.floorArea:
    case MULTIPLIER_IDS.currentCost:
    case MULTIPLIER_IDS.local:
    case MULTIPLIER_IDS.complexSites:
    case BUILDING_INFO_IDS.mvsSection:
    case BUILDING_INFO_IDS.mvsPage:
    case AREA_ID:
      return ColumnDataTypeEnum.numeric
    case BUILDING_INFO_IDS.grade:
      return {
        rowType: ColumnDataTypeEnum.select,
        inputProps: { options: arrayToValueLabelOption(GRADE_LIST) },
      }
    case BUILDING_INFO_IDS.buildingClass:
      return {
        rowType: ColumnDataTypeEnum.select,
        inputProps: { options: arrayToValueLabelOption(CLASS_LIST) },
      }
    case BUILDING_INFO_IDS.classType:
      return {
        rowType: ColumnDataTypeEnum.select,
        inputProps: {
          options: arrayToValueLabelOption(CLASS_TYPE_LIST),
        },
      }
    case BUILDING_INFO_IDS.finished:
      return {
        rowType: ColumnDataTypeEnum.select,
        inputProps: {
          options: arrayToValueLabelOption(FINISHED_LIST),
        },
      }
    default:
      return ColumnDataTypeEnum.text
  }
}

export const calculateExclusionTotals = (buildingComponents: BuildingComponent[], customExclusions: any[]): any => {
  const totalReplacementCostNew = buildingComponents.reduce(
    (total: number, buildingComponent: any) => total + buildingComponent.replacementCostNew,
    0
  )

  const totalExclusionValue = buildingComponents.reduce((total: number, buildingComponent: any) => {
    return (
      total +
      InsurableValueCalculations.calculateTotalExclusionsValue(
        buildingComponent.replacementCostNew,
        buildingComponent.exclusionPercentage
      )
    )
  }, 0)

  const totalExclusionPercentage = InsurableValueCalculations.calculateExclusionPercentage(
    totalExclusionValue,
    totalReplacementCostNew
  )

  const indicatedInsurableReplacementCost = InsurableValueCalculations.calculateIndicatedInsurableReplacementCost(
    totalReplacementCostNew,
    totalExclusionValue
  )

  const customExclusionTotals: any = {}
  for (const customExclusion of customExclusions) {
    const rowId = customExclusion.id
    const totalCustomExclusionValue = buildingComponents.reduce((total: number, buildingComponent: any) => {
      const lineItemPercentage = customExclusion[buildingComponent.id] || 0
      return (
        total +
        InsurableValueCalculations.calculateTotalExclusionsValue(
          buildingComponent.replacementCostNew,
          lineItemPercentage
        )
      )
    }, 0)
    customExclusionTotals[rowId] = formatPercentageString(
      divide(totalCustomExclusionValue, totalReplacementCostNew) || 0,
      2
    )
  }

  return {
    ...customExclusionTotals,
    [EXCLUSION_TABLE_IDS.totalExclusionPercentageInput]: formatPercentageString(totalExclusionPercentage, 2),
    [EXCLUSION_TABLE_IDS.totalExclusionsValue]: formatCurrencyFloat(totalExclusionValue),
    [EXCLUSION_TABLE_IDS.indicatedInsurableReplacementCost]: formatCurrencyFloat(
      indicatedInsurableReplacementCost.value
    ),
    [EXCLUSION_TABLE_IDS.roundedIndicatedInsurableReplacementCost]: formatCurrencyFloat(
      indicatedInsurableReplacementCost.rounded
    ),
  }
}
