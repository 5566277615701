import { REMAINING_COST_TYPE_ACTUAL_COST, REMAINING_COST_TYPE_BUDGET } from 'shared/constants/costApproach'

import SubjectBudget from './components/SubjectBudget'

export const remainingCostOptions = [
  { label: 'Budget', value: REMAINING_COST_TYPE_BUDGET },
  { label: 'Actual Cost', value: REMAINING_COST_TYPE_ACTUAL_COST },
]

export default SubjectBudget
