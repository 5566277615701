import { GeneratedText, gtParagraph } from './generatedText'

export const HIGHEST_AND_BEST_USE = {
  firstParagraph:
    'In determining highest and best use, we have considered the current trends of supply and demand ' +
    'on the market, current zoning regulations and other possible restrictions, and neighboring land uses.',
  secondParagraph:
    'It is to be recognized that in cases where a site has existing improvements on it, the highest ' +
    'and best use may be determined to be different from the existing use. The existing use will continue, however, ' +
    'unless and until land value in its highest and best use exceeds the total value of the property in its existing ' +
    'use.',
  thirdParagraph:
    'In estimating highest and best use, alternative uses that meet the four criteria of legally ' +
    'permissible, physically possible, financially feasible, and the maximally productive use, are considered and ' +
    'tested for the subject site as if vacant and as improved.',
}

export const getHighestAndBestUseIntroduction = () => {
  const gt = new GeneratedText()
  gt.addPart(gtParagraph(HIGHEST_AND_BEST_USE.firstParagraph))
  gt.addPart(gtParagraph(HIGHEST_AND_BEST_USE.secondParagraph))
  gt.addPart(gtParagraph(HIGHEST_AND_BEST_USE.thirdParagraph))
  return gt.getParts()
}
