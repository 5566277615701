import React from 'react'
import PropTypes from 'prop-types'
import { withStyles, MenuItem, TextField, FormControl } from '@material-ui/core'
import { Field } from 'react-final-form'
import { find } from 'lodash'

import { FormInputLabel, FormHelperErrorText } from '..'

import styles from './SelectStyles'

class SelectFieldComponent extends React.PureComponent {
  static propTypes = {
    name: PropTypes.string,
    label: PropTypes.string,
    value: PropTypes.object,
    valueKey: PropTypes.string,
    items: PropTypes.arrayOf(PropTypes.object),
    displayEmpty: PropTypes.bool,
    renderItemLabel: PropTypes.func,
    onChange: PropTypes.func,
    dense: PropTypes.bool,
  }

  static defaultProps = {
    name: null,
    value: null,
    valueKey: 'id',
    items: [],
    displayEmpty: true,
    renderItemLabel: item => {
      return <span>{JSON.stringify(item)}</span>
    },
    onChange: () => {},
    dense: false,
  }

  state = {
    selectedValue: null,
    itemLabels: [],
  }

  cacheLabels = items => {
    const { renderItemLabel } = this.props
    const itemLabels = items.map(renderItemLabel)

    this.setState({ itemLabels })
  }

  setSelectedValue = value => {
    const { items, valueKey } = this.props

    const selectedValue = find(items, [valueKey, value]) || null
    this.setState({ selectedValue })

    return selectedValue
  }

  handleChange = (event, child) => {
    const { input, onChange } = this.props

    const selectedValue = this.setSelectedValue(event.target.value)
    input.onChange(selectedValue)
    onChange(selectedValue)
  }

  componentDidMount() {
    const { items, input, valueKey } = this.props

    if (input.value) {
      this.setSelectedValue(input.value[valueKey])
    }

    this.cacheLabels(items)
  }

  componentDidUpdate(prevProps, prevState) {
    const { items, input, valueKey } = this.props

    if (prevProps.items !== items) {
      this.cacheLabels(items)
      if (input.value) {
        this.setSelectedValue(input.value[valueKey])
      }
    }

    if (prevProps.input.value !== input.value) {
      this.setSelectedValue(input.value[valueKey])
    }
  }

  render() {
    const {
      classes,
      items,
      valueKey,
      displayEmpty,
      dense,
      label,
      meta: { touched, error },
      input: { name, onFocus, onBlur },
    } = this.props

    const { selectedValue, itemLabels } = this.state

    const inErrorState = touched && !!error

    return (
      <FormControl className={classes.root}>
        <FormInputLabel error={inErrorState} />
        <TextField
          label={label}
          select
          fullWidth
          variant="outlined"
          value={!selectedValue ? 'undefined' : selectedValue[valueKey]}
          onChange={this.handleChange}
          onFocus={onFocus}
          onBlur={onBlur}
          name={name}
          data-qa={`${name}-select`}
          SelectProps={{
            displayEmpty,
            SelectDisplayProps: {
              'data-qa': 'select-value',
            },
          }}
        >
          {displayEmpty && <MenuItem value="" />}

          {items.map((item, index) => {
            return (
              <MenuItem key={index} value={item[valueKey]}>
                {itemLabels[index]}
              </MenuItem>
            )
          })}
        </TextField>
        <FormHelperErrorText inErrorState={inErrorState && !dense} error={error} />
      </FormControl>
    )
  }
}

export const CustomAdvancedSelectField = withStyles(styles)(({ name, label, onChange, ...restProps }) => {
  return <Field name={name} label={label} component={SelectFieldComponent} onChange={onChange} {...restProps} />
})
