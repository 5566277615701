import React from 'react'
import arrayMutators from 'final-form-arrays'
import { FieldArray } from 'react-final-form-arrays'

import { APPRAISAL_SCOPE_PATH } from 'shared/constants/report/keysAndDataPaths'

import wrapForm from '../../wrapForm'

import AppraisalScope from './AppraisalScope'
import { HEADING } from './AppraisalScopeConstants'

export const DATA_PATH = APPRAISAL_SCOPE_PATH

const AppraisalScopeContainer = props => <FieldArray name="items" component={AppraisalScope} {...props} />

export default wrapForm(DATA_PATH, {
  heading: HEADING,
  mutators: { ...arrayMutators },
})(AppraisalScopeContainer)
