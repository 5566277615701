import React from 'react'
import PropTypes from 'prop-types'
import { compose, pure } from 'recompose'
import { startCase } from 'lodash'
import { Typography } from '@mui/material'

import { AppraisalInstituteDesignationTypes } from '../../constants/organization'

const AppraisalEducationStatusText = ({ designation, educationRequirementsCompleted, accented, ...props }) => {
  const educationProgram =
    designation === AppraisalInstituteDesignationTypes.DESIGNATED_MEMBER
      ? 'continuing education program'
      : 'Standards and Ethics Education Requirements'

  return (
    <Typography variant="body2" {...props}>
      I have{' '}
      {!educationRequirementsCompleted && (
        <Typography
          variant="body1"
          component="span"
          sx={{ color: accented && 'red', display: 'inline-block', fontWeight: 700 }}
        >
          NOT
        </Typography>
      )}{' '}
      completed the {educationProgram} for {startCase(designation)}s of the Appraisal Institute.
    </Typography>
  )
}

AppraisalEducationStatusText.propTypes = {
  accented: PropTypes.bool,
  designation: PropTypes.string.isRequired,
  educationRequirementsCompleted: PropTypes.bool.isRequired,
}

AppraisalEducationStatusText.defaultProps = {
  accented: false,
}

export default compose(pure)(AppraisalEducationStatusText)
