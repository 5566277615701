import React, { Component } from 'react'
import { connect } from 'react-redux'
import { get, forIn, kebabCase } from 'lodash'

import { Loading } from 'client-shared/components'

import { hasPermission } from 'shared/helpers/permissions'

import PageLayout from '../../layout/PageLayout'
import { organizationFetch } from '../../redux/actions/organization'

import organizationForms from './organizationStructure'

class OrganizationContainer extends Component {
  componentWillMount() {
    const { organizationLoaded, organizationFetch, organizationIdParam } = this.props

    if (!organizationLoaded) {
      organizationFetch(organizationIdParam)
    }
  }

  generateUserForms = () => {
    const items = []
    const { userPermissions } = this.props
    forIn(organizationForms, ({ name, formComponent, permission = null }, key) => {
      if (!permission || hasPermission(permission, userPermissions)) {
        items.push({
          title: name,
          formComponent,
          path: kebabCase(key),
        })
      }
    })
    return items
  }

  render() {
    const { organizationLoaded, organizationData } = this.props

    const forms = this.generateUserForms()
    const organizationId = get(organizationData, '_id')

    return organizationLoaded ? (
      <PageLayout header="Organization" itemKey="organization" itemId={organizationId} forms={forms} />
    ) : (
      <Loading />
    )
  }
}

export default connect(
  (state, ownProps) => ({
    organizationLoaded: get(state, 'organization.organizationData'),
    organizationData: get(state, 'organization.organizationData'),
    organizationIdParam: get(ownProps, 'match.params.id'),
    userPermissions: get(state, 'authentication.user.permissions', []),
  }),
  {
    organizationFetch,
  }
)(OrganizationContainer)
