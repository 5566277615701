import {
  GeneratedText,
  gtLocked,
  gtLockedSentence,
  gtTemplate,
  addConditionals,
  gtIfElse,
} from '../../../narrative/GeneratedText'

import { mapDTO, mapDataModel } from './mappings'

const generate = values => {
  const gt = new GeneratedText()

  const buildingWillBeGt = gtIfElse(
    'isCommercialUse',
    gtTemplate`the building improvements will consist of`,
    gtTemplate`the improved building will be`
  )
  const floorsGt = gtLocked('floors', 'formatNaturalNumber')
  const buildingTypeGt = gtLocked('buildingType', 'formatBuilding')
  const propertyTypeGt = gtLocked('propertyType')
  const residentialUnitCountGt = gtLocked('residentialUnitCount', 'formatNaturalNumber')
  const residentialUnitWord = gtLockedSentence('residentialUnitWord')
  const commercialUnitCountGt = gtLocked('commercialUnitCount', 'formatNaturalNumber')
  const commercialUnitWord = gtLockedSentence('commercialUnitWord')
  const grossBuildingAreaGt = gtLocked('grossBuildingArea', 'formatInt')
  const basisForSFAnalysisWord = gtLocked('basisForSFAnalysis')
  const basisForSFAnalysisValueGt = gtLocked('basisForSFAnalysisValue', 'formatInt')

  if (values.valueAsComplete) {
    gt.t`Upon completion of renovation, ${buildingWillBeGt} `
  } else {
    gt.t`The subject is `
  }

  gt.t`a ${floorsGt}-story`
  addConditionals(gt, {
    showBuildingType: [...gtTemplate`, ${buildingTypeGt}`],
  })

  addConditionals(gt, {
    isMultiFamilyUse: [
      ...gtTemplate`, ${propertyTypeGt} building consisting of ${residentialUnitCountGt} residential
      ${residentialUnitWord}`,
    ],
    isPropertyMixedUse: [
      ...gtTemplate`, ${propertyTypeGt} building consisting of ${residentialUnitCountGt} residential
      ${residentialUnitWord} and ${commercialUnitCountGt} commercial ${commercialUnitWord}`,
    ],
    isCommercialUse: [...gtTemplate`, ${propertyTypeGt} building`],
  })

  gt.t` with ${grossBuildingAreaGt} square feet of gross building area`

  addConditionals(gt, {
    nonDefaultBasisSelected: [
      ...gtTemplate` and ${basisForSFAnalysisValueGt} square feet of ${basisForSFAnalysisWord}`,
    ],
  })

  gt.t`.`

  return gt.getParts()
}

export default { generate, mapDTO, mapDataModel }
