import React from 'react'
import PropTypes from 'prop-types'
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material'

const ConfirmDialog = ({ title, text, cancelText, confirmText, open, onConfirm }) => {
  const handleCancel = React.useCallback(() => {
    onConfirm(false)
  }, [onConfirm])

  const handleConfirm = React.useCallback(() => {
    onConfirm(true)
  }, [onConfirm])

  return (
    <Dialog open={open} aria-labelledby="confirm-dialog-title" aria-describedby="confirm-dialog-description">
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        <DialogContentText>{text}</DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleCancel} color="primary" autoFocus>
          {cancelText}
        </Button>
        <Button onClick={handleConfirm} color="error">
          {confirmText}
        </Button>
      </DialogActions>
    </Dialog>
  )
}

ConfirmDialog.propTypes = {
  title: PropTypes.string,
  open: PropTypes.bool.isRequired,
  onConfirm: PropTypes.func.isRequired,
  text: PropTypes.string,
  cancelText: PropTypes.string,
  confirmText: PropTypes.string,
}

ConfirmDialog.defaultProps = {
  title: 'Are you sure?',
  text: 'You are going to perform irreversible action.',
  cancelText: 'Cancel',
  confirmText: 'Delete',
}

export default ConfirmDialog
