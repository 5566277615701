import { get, sumBy, filter, find } from 'lodash'

import { getAppraiserForecastGrossReimbursement } from 'shared/calculations/commercial'
import ExpensesCalculations from 'shared/report-calculations/income-approach/expenses/expenses-calculations'

import {
  getAnnualReimbursement,
  getBaseYearTaxes,
  getCurrentYearTaxes,
  getBaseYearAssessment,
  getBaseYearTaxRate,
  getTaxLiabilityIncrease,
  getAppraiserForecastAdminFee,
  getCurrentYearLiabilityAdminFee,
  getCurrentYearLiabilityGrossReimbursement,
} from './tools'

export const updateDollarAmount = ([data], state, utils) => {
  const { unitReimbursementPath, form } = data

  const reimbursementPath = unitReimbursementPath.substring(0, unitReimbursementPath.indexOf('.unitReimbursements['))
  const monthlyAmountKnown =
    get(state, `formState.values.${reimbursementPath}.knownInformation.dollarAmountType`) === 'Monthly'

  let annualValue = get(state, `formState.values.${unitReimbursementPath}.annual`, 0)
  if (monthlyAmountKnown) {
    const monthlyValue = get(state, `formState.values.${unitReimbursementPath}.monthly`, 0)
    const MONTHS_IN_YEAR = 12
    annualValue = (monthlyValue || 0) * MONTHS_IN_YEAR
  }
  if (annualValue < 0) {
    annualValue = 0
  }

  utils.changeValue(state, `${unitReimbursementPath}.annual`, () => annualValue)

  form.mutators.updateVCLossValues({ reimbursementPath })
}

export const updateVCLossValues = ([data], state, utils) => {
  const { reimbursementPath } = data

  const unitReimbursements = get(state, `formState.values.${reimbursementPath}.unitReimbursements`, [])
  const includedUnitReimbursements = filter(
    unitReimbursements,
    unitReimbursement => unitReimbursement.enabled && unitReimbursement.annual > 0
  )
  const totalAnnual = sumBy(includedUnitReimbursements, 'annual')

  const vcLoss = get(state, `formState.values.${reimbursementPath}.vcLoss`, 0)
  const grossVCLoss = totalAnnual * vcLoss
  const effectiveReimbursement = totalAnnual - grossVCLoss

  utils.changeValue(state, `${reimbursementPath}.grossVcLoss`, () => grossVCLoss)
  utils.changeValue(state, `${reimbursementPath}.effectiveAnnual`, () => effectiveReimbursement)
  utils.changeValue(state, `${reimbursementPath}.totalAnnual`, () => totalAnnual)
}

export const updateIncreaseOverBaseYear = ([data], state, utils) => {
  const { unitReimbursementPath, form, taxInfo, taxRateHistory } = data

  const reimbursementPath = unitReimbursementPath.substring(0, unitReimbursementPath.indexOf('.unitReimbursements['))

  const unitReimbursement = get(state, `formState.values.${unitReimbursementPath}`, {})
  const baseYearLiabilityKnown =
    get(state, `formState.values.${reimbursementPath}.knownInformation.baseYearValueType`) === 'Base Year Liability'

  const baseYearAssessment = getBaseYearAssessment(unitReimbursement, baseYearLiabilityKnown, taxRateHistory)
  const baseYearTaxRate = getBaseYearTaxRate(unitReimbursement, taxRateHistory)
  const baseYearLiability = getBaseYearTaxes(unitReimbursement, baseYearLiabilityKnown, taxRateHistory)
  const increase = getTaxLiabilityIncrease(unitReimbursement, baseYearLiabilityKnown, taxInfo, taxRateHistory)
  let annual = getAnnualReimbursement(unitReimbursement, baseYearLiabilityKnown, taxInfo, taxRateHistory)
  if (annual < 0) {
    annual = 0
  }
  const currentYearLiability = getCurrentYearTaxes(taxInfo)

  utils.changeValue(state, `${unitReimbursementPath}.baseYearAssessment`, () => baseYearAssessment)
  utils.changeValue(state, `${unitReimbursementPath}.baseYearTaxRate`, () => baseYearTaxRate)
  utils.changeValue(state, `${unitReimbursementPath}.baseYearLiability`, () => baseYearLiability)
  utils.changeValue(state, `${unitReimbursementPath}.increase`, () => increase)
  utils.changeValue(state, `${unitReimbursementPath}.annual`, () => annual)
  utils.changeValue(state, `${unitReimbursementPath}.currentYearLiability`, () => currentYearLiability)

  form.mutators.updateVCLossValues({ reimbursementPath })
}

export const updatePercentOfCurrentYearLiability = ([data], state, utils) => {
  const { unitReimbursementPath, form, taxInfo } = data

  const unitReimbursement = get(state, `formState.values.${unitReimbursementPath}`, {})
  const reimbursementPath = unitReimbursementPath.substring(0, unitReimbursementPath.indexOf('.unitReimbursements['))
  const reimbursement = get(state, `formState.values.${reimbursementPath}`, {})
  const hasAdminFee = get(reimbursement, 'knownInformation.adminFee')

  const currentYearLiability = getCurrentYearTaxes(taxInfo)
  const annualLessAdminFee = getCurrentYearLiabilityGrossReimbursement(unitReimbursement)
  const adminFee = hasAdminFee ? getCurrentYearLiabilityAdminFee(unitReimbursement) : 0
  let annual = annualLessAdminFee + adminFee
  if (annual < 0) {
    annual = 0
  }
  utils.changeValue(state, `${unitReimbursementPath}.currentYearLiability`, () => currentYearLiability)
  utils.changeValue(state, `${unitReimbursementPath}.annualLessAdminFee`, () => annualLessAdminFee)
  utils.changeValue(state, `${unitReimbursementPath}.adminFee`, () => adminFee)
  utils.changeValue(state, `${unitReimbursementPath}.annual`, () => annual)

  form.mutators.updateVCLossValues({ reimbursementPath })
}

const getExpenseForecastCategory = (expenseForecast, expenseType) => {
  return (
    get(expenseForecast, `${expenseType}`) ||
    find(expenseForecast.customExpenses, expense => expense.name === expenseType) || {
      concludedValue: 0,
    }
  )
}

export const updatePercentOfAppraiserForecast = ([data], state, utils) => {
  const { unitReimbursementPath, form, expenseForecast, squareFootage, numberOfResidentialUnits, totalRoomCount } = data

  const unitReimbursement = get(state, `formState.values.${unitReimbursementPath}`, {})
  const reimbursementPath = unitReimbursementPath.substring(0, unitReimbursementPath.indexOf('.unitReimbursements['))
  const reimbursement = get(state, `formState.values.${reimbursementPath}`, {})
  const hasAdminFee = get(reimbursement, 'knownInformation.adminFee')

  const expenseType = get(reimbursement, 'expenseType')
  const { concludedValue: concludedExpenseValue, basis: expenseBasis } = getExpenseForecastCategory(
    expenseForecast,
    expenseType
  )
  const grossAppraiserForecast = ExpensesCalculations.calculateTotalFromPerBasis(concludedExpenseValue, expenseBasis, {
    squareFootage,
    numberOfUnits: numberOfResidentialUnits,
    numberOfRooms: totalRoomCount,
  })
  const grossReimbursementPercent = get(unitReimbursement, 'percentOfGrossAppraiserForecast', 0)
  const adminFeePercent = get(unitReimbursement, 'adminFeePercentage', 0)

  const annualLessAdminFee = getAppraiserForecastGrossReimbursement(grossAppraiserForecast, grossReimbursementPercent)
  const adminFee = hasAdminFee ? getAppraiserForecastAdminFee(grossAppraiserForecast, adminFeePercent) : 0
  let annual = annualLessAdminFee + adminFee
  if (annual < 0) {
    annual = 0
  }
  utils.changeValue(state, `${unitReimbursementPath}.grossAppraiserForecast`, () => grossAppraiserForecast)
  utils.changeValue(state, `${unitReimbursementPath}.annualLessAdminFee`, () => annualLessAdminFee)
  utils.changeValue(state, `${unitReimbursementPath}.adminFee`, () => adminFee)
  utils.changeValue(state, `${unitReimbursementPath}.annual`, () => annual)

  form.mutators.updateVCLossValues({ reimbursementPath })
}
