import { combineReducers } from 'redux'

import { reducer as confirmation } from './confirmation'
import { reducer as notifications } from './notifications'
import { reducer as location } from './location'
import { reducer as properties } from './properties'
import { reducer as pulledFloodData } from './pulledFloodData'
import { reducer as areFilesUploading } from './areFilesUploading'
import { reducer as contentLibraryData } from './contentLibraryData'
import { reducer as drawer } from './drawer'
import { reducer as jumpAhead } from './jumpAhead'
import { reducer as scroll } from './scroll'

const reducer = combineReducers({
  notifications,
  confirmation,
  location,
  properties,
  pulledFloodData,
  areFilesUploading,
  contentLibraryData,
  drawer,
  jumpAhead,
  scroll,
})

export default reducer
