import MomentUtils from 'material-ui-pickers/utils/moment-utils'

// TODO: This workaround is needed for fixing moment utils bugs.
// The issue has been reported https://github.com/dmtrKovalenko/material-ui-pickers/issues/668
// This class can be removed after the ^1.0.0-rc.18 release of material-ui-pickers package.

export default class extends MomentUtils {
  parse(value, format) {
    if (!value) {
      return null
    }
    return super.parse(value, format)
  }
  isValid(date) {
    if (!date) {
      return false
    }
    return super.isValid(date)
  }
  isNull(date) {
    if (!date) {
      return true
    }
    return super.isNull(date)
  }
}
