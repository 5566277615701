import React from 'react'
import PropTypes from 'prop-types'
import { Grid, withStyles, Typography } from '@material-ui/core'
import { times, get } from 'lodash'

import AutomationStatus from 'client-shared/components/AutomationStatus'

import {
  HEATING_SYSTEM_ITEMS,
  LOCATIONS,
  OIL_HEATING_TYPES,
  OTHER_TYPE,
  OIL_TYPES,
} from '../../../../../../shared/constants/report/utilities'

import { arrayToKeyValuePairs } from '../../../../shared/utils/ui/checkboxHelper'

import { CheckboxWithLabel } from '../../../../shared/components/Checkbox'
import { DropDown } from '../../../../shared/components/DropDown'
import { Text, Number } from '../../../../shared/components'

const styles = theme => ({
  checkboxRoot: {
    height: 56,
  },
  checkBoxLabel: {
    marginTop: 0,
  },
})

class HeatingSystems extends React.PureComponent {
  static propTypes = {
    inspected: PropTypes.bool,
    systems: PropTypes.arrayOf(PropTypes.object),
    count: PropTypes.number,
    location: PropTypes.string,
  }

  static defaultProps = {
    count: 0,
    location: '',
    inspected: false,
  }

  get locationTypes() {
    return arrayToKeyValuePairs(LOCATIONS)
  }

  get oilTypes() {
    return arrayToKeyValuePairs(OIL_TYPES, true)
  }

  renderSystemRow = index => {
    const { systems } = this.props
    const system = get(systems, index, {})
    const isOtherType = system.type === OTHER_TYPE
    const isOtherLocation = system.location === OTHER_TYPE
    const isOilType = OIL_HEATING_TYPES.includes(system.type)
    const isOtherOilType = system.oilType === OTHER_TYPE

    return (
      <Grid container spacing={16} key={index}>
        <Grid item xs={12}>
          <Typography variant="subtitle1">{`Heating System ${index + 1}`}</Typography>
        </Grid>
        <Grid item xs={6}>
          <DropDown
            name={`heatingSystems.systems.${index}.type`}
            label="Select Heating System Type"
            items={HEATING_SYSTEM_ITEMS}
          />
        </Grid>
        <Grid item xs={6}>
          <DropDown
            name={`heatingSystems.systems.${index}.location`}
            label="Select Heating System Location"
            items={this.locationTypes}
          />
        </Grid>
        <Grid item xs={6}>
          {isOtherType && <Text name={`heatingSystems.systems.${index}.otherType`} label="Heating System Type" />}
          {isOilType && (
            <DropDown
              name={`heatingSystems.systems.${index}.oilType`}
              label="Select Heating System Oil Type"
              items={this.oilTypes}
            />
          )}
        </Grid>
        <Grid item xs={6}>
          {isOtherLocation && (
            <Text name={`heatingSystems.systems.${index}.otherLocation`} label="Heating System Location" />
          )}
        </Grid>
        <Grid item xs={6}>
          {isOtherOilType && (
            <Text name={`heatingSystems.systems.${index}.otherOilType`} label="Heating System Oil Type" />
          )}
        </Grid>
      </Grid>
    )
  }

  render() {
    const { count, inspected, automationMetadata, automatedInspectionPrefillFlag } = this.props
    return (
      <Grid container direction="column">
        <Grid item>
          <Typography variant="subtitle1">Heating System(s)</Typography>
        </Grid>
        <Grid item>
          {automatedInspectionPrefillFlag && automationMetadata && (
            <AutomationStatus
              formPaths={['automationMetadata']}
              message="We automated the information below from Mobile Inspection app"
              sx={{ marginTop: '8px', marginBottom: '8px' }}
            />
          )}
        </Grid>
        <Grid item>
          <CheckboxWithLabel name="heatingSystems.inspected" label="There is a heating system(s)" />
        </Grid>
        {inspected && (
          <React.Fragment>
            <Grid item xs={3}>
              <Number label="Number of Heating Systems" name="heatingSystems.count" allowNegative={false} />
            </Grid>
            <Grid item>{times(count, this.renderSystemRow)}</Grid>
          </React.Fragment>
        )}
      </Grid>
    )
  }
}

export default withStyles(styles)(HeatingSystems)
