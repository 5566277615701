import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { compose } from 'recompose'
import { get } from 'lodash'

import { Route, Redirect, matchPath, Switch, withRouter } from 'react-router'

import { LOGIN_PATH } from '../shared/constants'

import { fetchUser } from './redux/actions'

import Login from './Login'

class AuthSwitch extends React.Component {
  static propTypes = {
    location: PropTypes.shape({
      pathname: PropTypes.string,
    }).isRequired,
    isAuthenticated: PropTypes.bool,
    fetchUser: PropTypes.func.isRequired,
  }

  static defaultProps = {
    isAuthenticated: false,
  }

  componentWillMount() {
    this.props.fetchUser()
  }

  componentDidUpdate() {
    const referrer = get(this.props.location, 'state.referrer')
    if (referrer && this.props.isAuthenticated) {
      this.props.history.replace(referrer)
    }
  }

  get shouldRenderRedirectRoute() {
    const { isAuthenticated, isLoading, isLoaded, location } = this.props
    return !isLoading && !isLoaded && !isAuthenticated && !matchPath(location.pathname, { path: LOGIN_PATH })
  }

  render() {
    const { children, isLoaded, location } = this.props
    return (
      <React.Fragment>
        <Switch location={location}>
          <Route path={LOGIN_PATH} component={Login} />
        </Switch>
        {this.shouldRenderRedirectRoute && (
          <Redirect push to={{ pathname: LOGIN_PATH, state: { referrer: location.pathname + location.search } }} />
        )}
        {isLoaded && !this.shouldRenderRedirectRoute && children}
      </React.Fragment>
    )
  }
}

export default compose(
  // withRouter must be the first here
  // https://github.com/remix-run/react-router/blob/v4.1.1/packages/react-router/docs/guides/blocked-updates.md
  withRouter,
  connect(
    state => {
      const { isLoading, isLoaded, isAuthenticated } = get(state, 'authentication', {})
      return {
        isAuthenticated,
        isLoading,
        isLoaded,
      }
    },
    {
      fetchUser,
    }
  )
)(AuthSwitch)
