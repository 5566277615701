import React from 'react'

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  DialogContentText,
  Stack,
  Tooltip,
} from '@mui/material'

import InfoOutlined from '@mui/icons-material/InfoOutlined'

import Text from '../Text'
import Loading from '../Loading'

const ImportFromReportDialogView = ({
  closeModal,
  dialogContent,
  handleSubmit,
  isLoading,
  openDialogButtonProps,
  openModal,
  showModal,
  tooltipPosition,
  tooltipText,
}) => (
  <Stack alignItems="center" direction="row" spacing={2}>
    <Button
      sx={{ minWidth: 150 }}
      variant="contained"
      {...openDialogButtonProps}
      data-qa="import-btn"
      onClick={openModal}
    >
      {openDialogButtonProps.label}
    </Button>
    {tooltipText && (
      <Tooltip title={tooltipText} placement={tooltipPosition}>
        <InfoOutlined />
      </Tooltip>
    )}
    {showModal && (
      <Dialog
        aria-labelledby="simple-dialog-title"
        data-qa="import-modal"
        disableBackdropClick
        maxWidth="lg"
        onClose={closeModal}
        open
      >
        {isLoading && <Loading />}
        <DialogTitle id="simple-dialog-title">Enter Report Unique ID</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">{dialogContent}</DialogContentText>
          <Text name="reportSource" placeholder="e.g. 5d7ffcfb3005a300143711e6" />
        </DialogContent>
        <DialogActions>
          <Button onClick={closeModal} color="inherit" data-qa="cancel-btn">
            Close
          </Button>
          <Button type="submit" onClick={handleSubmit} variant="contained" color="primary" data-qa="import-btn">
            Import
          </Button>
        </DialogActions>
      </Dialog>
    )}
  </Stack>
)

export default ImportFromReportDialogView
