import { handleActions } from 'redux-actions'

import {
  fetchLatestInsurableValuesFail,
  fetchLatestInsurableValuesReceive,
  fetchLatestInsurableValuesRequest,
  updateInsurableValuesReceive,
  updateInsurableValuesFail,
} from '../actions/insurableValues'

const initialState = {
  values: {},
  isLoading: false,
}

export const reducer = handleActions(
  {
    [fetchLatestInsurableValuesRequest](state) {
      return {
        ...state,
        values: {},
        isLoading: true,
      }
    },
    [fetchLatestInsurableValuesReceive](state, { payload }) {
      return {
        ...state,
        values: payload,
        isLoading: false,
      }
    },
    [fetchLatestInsurableValuesFail](state) {
      return {
        ...state,
        values: {},
        isLoading: false,
      }
    },

    [updateInsurableValuesReceive](state, { payload }) {
      return {
        ...state,
        values: payload,
      }
    },
    [updateInsurableValuesFail](state) {
      return {
        ...state,
      }
    },
  },
  initialState
)
