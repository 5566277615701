import { toPairs, flattenDeep } from 'lodash'
import { Value, Inline, Document, Block, Text } from 'slate'

export function gtText(text) {
  return { type: 'text', text: `${text}` }
}

export function gtParagraph(text) {
  return { type: 'paragraph', text }
}

export function gtLocked(name, formatter, displayName, formatterArguments) {
  if (formatter) {
    return { type: 'locked', name, format: formatter, displayName, formatterArguments }
  }
  return { type: 'locked', name, displayName }
}

export function gtLockedSentence(name) {
  return { type: 'lockedSentence', name }
}

export function gtConditional(name, children = []) {
  return { type: 'conditional', name, children }
}

export function gtNewline() {
  return gtText('\n\n')
}

export function gtIfElse(name, ifChildren = [], elseChildren = []) {
  return [gtConditional(name, ifChildren), { inverted: true, ...gtConditional(name, elseChildren) }]
}

export function gtTemplate(strings, ...values) {
  const nodes = []
  const push = expr => {
    switch (typeof expr) {
      case 'string':
        // replace single newline and surrounding whitespace with a single space, preserve double newlines
        nodes.push(gtText(expr.replace(/[^\S\n]*\n[^\S\n]+/g, ' ')))
        return
      case 'object':
        if (Array.isArray(expr)) {
          expr.forEach(push)
          return
        }
        nodes.push(expr)
        return
      default:
        nodes.push(gtText(expr))
    }
  }

  const text = strings[0]
  if (text) {
    push(text)
  }

  values.forEach((expr, index) => {
    push(expr)

    const str = strings[index + 1]
    if (str) {
      push(str)
    }
  })

  return nodes
}

export class GeneratedText {
  constructor() {
    this.parts = []
  }

  static fromParts(parts) {
    const generatedText = new GeneratedText()
    generatedText.parts = parts
    return generatedText
  }

  // eslint-disable-next-line id-length
  t(...args) {
    return this.template(...args)
  }

  template(strings, ...values) {
    this.parts.push(...gtTemplate(strings, ...values))
    return this
  }

  addPart(part) {
    this.parts.push(part)
    return this
  }

  addNewLine() {
    return this.addPart(gtNewline())
  }

  addParts(parts) {
    this.parts.push(...parts)
    return this
  }

  getParts() {
    return this.parts
  }

  toValue() {
    // if the part.type is paragraph level, we want to Block.create a new entry to the top level Document nodes.
    // else, if part.type is anything else, we want to add to the existing paragraph node that we are in.
    let currentParagraph = { type: 'paragraph', nodes: [] }
    const docNodes = []

    this.parts.forEach((part, idx) => {
      if (part.type === 'paragraph') {
        if (currentParagraph.nodes.length > 0) {
          docNodes.push(Block.create(currentParagraph))
          currentParagraph = { type: 'paragraph', nodes: [] }
        }
        currentParagraph.nodes.push(Text.create({ text: part.text }))
      } else {
        currentParagraph.nodes.push(...this.partToNodes(part))
      }
      if (idx === this.parts.length - 1) {
        docNodes.push(Block.create(currentParagraph))
      }
    })

    return Value.create({
      document: Document.create({ nodes: docNodes }),
    })
  }

  partToNodes(part) {
    switch (part.type) {
      case 'locked': {
        return [
          Text.create({
            text: '',
          }),
          Inline.create({
            type: 'locked',
            data: {
              fieldName: part.name || '',
              displayName: part.displayName,
              format: part.format,
              formatterArguments: part.formatterArguments,
            },
            nodes: [Text.create({ text: part.text })],
          }),
          Text.create({
            text: '',
          }),
        ]
      }
      case 'lockedSentence': {
        return [
          Text.create({
            text: '',
          }),
          Inline.create({
            type: 'lockedSentence',
            data: {
              fieldName: part.name || '',
            },
            nodes: [Text.create({ text: part.text })],
          }),
          Text.create({
            text: '',
          }),
        ]
      }
      case 'conditional': {
        return [
          Text.create({
            text: '',
          }),
          Inline.create({
            type: 'conditional',
            nodes: flattenDeep(part.children.map(this.partToNodes.bind(this))),
            data: {
              fieldName: part.name || '',
              inverted: part.inverted,
            },
          }),
          Text.create({
            text: '',
          }),
        ]
      }
      default: {
        return [
          Text.create({
            text: part.text,
          }),
        ]
      }
    }
  }
}

export const generatedTextToValue = generatedText => {
  return GeneratedText.fromParts(generatedText).toValue()
}

export function formatNaturalNumber(value) {
  return value >= 0 ? value : null
}

export const getPartsFromString = text => {
  const gt = new GeneratedText()
  gt.t`${text}`
  return gt.getParts()
}

export const addConditionals = (generatedText, rules) => {
  toPairs(rules).forEach(([conditionalValuePath, conditionalPart]) =>
    generatedText.addPart(gtConditional(conditionalValuePath, [...gtTemplate`${conditionalPart}`]))
  )
}
