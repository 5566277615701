import React from 'react'
import { compose } from 'recompose'
import { get } from 'lodash'
import PropTypes from 'prop-types'
import arrayMutators from 'final-form-arrays'

import { getFeatureFlagValue } from '@bowery-valuation/feature-flagger-client'
import { FormControl, Grid, Paper, Typography } from '@material-ui/core'
import InfoOutlined from '@mui/icons-material/InfoOutlined'
import descriptionOfImprovementsSummary from 'shared/utils/textGeneration/property/descriptionOfImprovementsSummary'

import { ENABLE_AUTOMATED_INSPECTION_PREFILL } from 'shared/constants/featureFlags'

import { GEOGRAPHY_OPTIONS } from 'shared/constants/property/geography'

import AutomationStatus from 'client-shared/components/AutomationStatus'

import locationDescription from '../../../../../../../shared/utils/textGeneration/property/locationDescription'
import transportationDiscussion from '../../../../../../../shared/utils/textGeneration/property/transportationDiscussion'
import surroundingUsesDiscussion from '../../../../../../../shared/utils/textGeneration/property/surroundingUsesDiscussion'

import siteDescriptionConclusion from '../../../../../../../shared/utils/textGeneration/property/siteDescriptionConclusion'
import { isCornerLot } from '../../../../../../../shared/utils/report'
import floodHazardDiscussion from '../../../../../../../shared/utils/textGeneration/property/floodHazardDiscussion'

import wrapForm from '../../../wrapForm'
import { SiteAreaTypes, SITE_DESCRIPTOR_TOPOGRAPHY } from '../../../../constants'
import { formatInt } from '../../../../../shared/utils/numberFormatters'
import { CheckboxWithLabel, ImageUpload, Tooltip } from '../../../../../shared/components'
import NarrativeComponent, { NarrativeComponentWithAlert } from '../../../../../shared/components/NarrativeComponent'

import Table from '../Table'

import { STATE_NAMES } from '../../../../../../../libs/bowery-libs/constants/geography'

import withPulledFloodData from './withPulledFloodData'
import { floodWriteupShape } from './propTypes'

import { DATA_PATH, SITE_DESCRIPTORS_TOOLTIP, LOCATION_TOOLTIP_TEXT } from './shared'

const heading = 'Site Description'

const styles = theme => ({
  paper: theme.paper,
  sectionTitle: {
    letterSpacing: 0.2,
    fontSize: 16,
  },
  spacedSectionTitle: {
    letterSpacing: 0.2,
    fontSize: 16,
    marginBottom: 8,
  },
  checkboxWrapper: {
    '& > div': {
      height: 'auto',
    },
    '& label': {
      marginTop: 'auto',
    },
  },
  table: {
    marginBottom: 24,
  },
  transportationImageFormControl: {
    marginBottom: 32,
    width: 200,
    height: 200,
  },
  tooltipIcon: {
    fontSize: 18,
    marginLeft: 8,
    verticalAlign: 'text-top',
  },
})

class SiteDescription extends React.PureComponent {
  static propTypes = {
    siteArea: PropTypes.string.isRequired,
    propertyShape: PropTypes.string.isRequired,
    frontageDescription: PropTypes.string.isRequired,
    primaryAccessStreetName: PropTypes.string.isRequired,
    borough: PropTypes.string.isRequired,
    coords: PropTypes.shape({
      latitude: PropTypes.number.isRequired,
      longitude: PropTypes.number.isRequired,
    }).isRequired,
    initializeSiteDescriptors: PropTypes.bool.isRequired,
    initializeUtilities: PropTypes.bool.isRequired,
    floodWriteupProps: floodWriteupShape.isRequired,
    propertyState: PropTypes.string.isRequired,
    classes: PropTypes.any,
    form: PropTypes.any,
    neighborhood: PropTypes.string,
    submarket: PropTypes.string,
  }

  componentWillMount() {
    const { form, initializeSiteDescriptors, initializeUtilities } = this.props

    if (initializeSiteDescriptors || initializeUtilities) {
      form.batch(() => {
        if (initializeSiteDescriptors) {
          form.change('siteDescriptors', this.getDefaultSiteDescriptors())
        }
        if (initializeUtilities) {
          form.change('utilities', this.getDefaultUtilities())
        }
        form.reset(form.getState().values)
      })
    }
  }

  getDefaultSiteDescriptors = () => {
    const { primaryAccessStreetName, propertyState } = this.props
    const defaults = [
      {
        name: 'Access',
        description: `The primary access is from ${primaryAccessStreetName}`,
      },
      {
        name: `${SITE_DESCRIPTOR_TOPOGRAPHY}`,
        description: 'Generally level at street grade',
      },
      {
        name: 'Drainage',
        description: 'Assumed adequate',
      },
      {
        name: 'Paving',
        description: 'All roads are paved with asphalt and are in satisfactory condition.',
      },
      {
        name: 'Street Lighting',
        description: 'Adequate',
      },
      {
        name: 'Hazardous Substances',
        description:
          'We observed no evidence of toxic or hazardous substances during our inspection of the site. However, environmental issues are beyond our scope of expertise. It is assumed that the property is not adversely affected by environmental hazards.',
      },
      {
        name: 'Easements, Encroachments, and Restrictions',
        description:
          'Based upon a review of the tax map, deed, legal description, title policy and/or property survey, there do not appear to be any easements, encroachments, or restrictions that would adversely affect value. We know of no deed restrictions, private or public, that further limit the subject property’s use. It is noted that any further research required to determine whether or not such restrictions exist, is beyond the scope of this appraisal assignment. Our valuation assumes no adverse impacts from easements, encroachments or restrictions, and further assumes that the subject has clear and marketable title.',
      },
    ]

    if (propertyState === STATE_NAMES.CA) {
      defaults.push({
        name: 'Seismic Hazards (Earthquake)',
        description:
          'All properties in California are subject to some degree of seismic risk. The Alquist-Priolo special Studies Zone Act of 1972 was enacted by the State of California to regulate development near active earthquake faults. The Act required the State Geologist to delineate “special studies zones” along known active faults in California. Cities and Counties affected by the identified zones must limit certain development projects within the zones unless geologic investigation demonstrates that the sites are not threatened by surface displacement from future faulting. According to “Fault-Rupture Hazard Zones in California” published in 2018 by the California Department of Conservation, Department of Mines and Geology, the subject is not within an area affected by the Alquist-Priolo Special Studies Zone Act. Related development limitations do not apply. The subject is not within 1,000 feet of a Alquist Priolo earthquake zone.',
      })
    }

    return defaults
  }

  getDefaultUtilities = () => {
    const { borough } = this.props
    const GAS_PROVIDER_LOOKUP = {
      BK: 'Con Ed and/or National Grid',
      BX: 'Con Ed and/or National Grid',
      MN: 'Con Ed',
      QN: 'Con Ed',
      SI: 'Con Ed and/or National Grid',
    }
    const ELECTRIC_PROVIDER_LOOKUP = {
      BK: 'Con Ed',
      BX: 'Con Ed',
      MN: 'Con Ed',
      QN: 'Con Ed',
      SI: 'Con Ed',
    }

    const utilities = [
      { name: 'Water/Sewer and Refuse', description: 'City' },
      { name: 'Police & Fire Protection', description: 'City' },
    ]
    const gasProvider = GAS_PROVIDER_LOOKUP[borough]
    const electricityProvider = ELECTRIC_PROVIDER_LOOKUP[borough]

    utilities.push({ name: 'Gas', description: gasProvider })
    utilities.push({ name: 'Electricity', description: electricityProvider })

    return utilities
  }

  render() {
    const { classes, form, siteArea, propertyShape, frontageDescription, neighborhood, submarket, floodWriteupProps } =
      this.props

    const { automationMetadata, locationIdentifier } = form.values
    const automatedInspectionPrefillFlag = getFeatureFlagValue(ENABLE_AUTOMATED_INSPECTION_PREFILL)

    const transportationDiscussionData = transportationDiscussion.mapDTO({ ...form.values })

    return (
      <Grid container>
        <Grid item xs={8}>
          <Paper className={classes.paper}>
            <Grid container spacing={16} direction="column">
              <Grid item xs={12}>
                <Typography variant="h6">Site Information</Typography>
              </Grid>
              {automatedInspectionPrefillFlag && automationMetadata && (
                <Grid item xs={12}>
                  <AutomationStatus
                    formPaths={['automationMetadata']}
                    message="We automated the information below from Mobile Inspection app"
                    sx={{ marginTop: '-4px' }}
                  />
                </Grid>
              )}
              <Grid item xs={12}>
                <Typography variant="subtitle2" className={classes.spacedSectionTitle}>
                  Location Within Market
                </Typography>
                <NarrativeComponent
                  name="locationDescription"
                  title="Location Description Export"
                  generatedText={locationDescription.generate}
                  data={locationDescription.mapDTO({ neighborhood, submarket })}
                  tooltipText={LOCATION_TOOLTIP_TEXT}
                />
              </Grid>
              <Grid item xs={12}>
                <Typography variant="subtitle2" className={classes.spacedSectionTitle}>
                  Transportation
                </Typography>
                <NarrativeComponentWithAlert
                  name="transportationDiscussion"
                  title="Transportation Discussion"
                  generatedText={transportationDiscussion.generate}
                  data={transportationDiscussionData}
                  tooltipText="This generated text and appraiser commentary will appear in the Site Description of your report."
                  showErrorAlert={
                    !transportationDiscussionData.hasGeneratedText && locationIdentifier !== GEOGRAPHY_OPTIONS.NY
                  }
                  errorAlertText="No transportation information was found for your neighborhood"
                />
                <FormControl className={classes.transportationImageFormControl}>
                  <Typography variant="subtitle2" className={classes.spacedSectionTitle}>
                    StreetEasy Image Upload
                  </Typography>
                  <ImageUpload name="transportationImage" />
                </FormControl>
              </Grid>
              <Grid item xs={12}>
                <Typography variant="subtitle2" className={classes.sectionTitle}>
                  Surrounding Uses
                </Typography>
                <Grid container>
                  <Grid item className={classes.checkboxWrapper}>
                    <CheckboxWithLabel name="surroundingUses.residential" label="Residential" />
                  </Grid>
                  <Grid item className={classes.checkboxWrapper}>
                    <CheckboxWithLabel name="surroundingUses.commercial" label="Commercial" />
                  </Grid>
                  <Grid item className={classes.checkboxWrapper}>
                    <CheckboxWithLabel name="surroundingUses.industrial" label="Industrial" />
                  </Grid>
                </Grid>
                <NarrativeComponent
                  name="surroundingUsesDiscussion"
                  title="Surrounding Uses Discussion"
                  generatedText={surroundingUsesDiscussion.generate}
                  data={surroundingUsesDiscussion.mapDTO(form.values)}
                  tooltipText="This text will appear in the Site Description of your report."
                />
              </Grid>
              <Grid item xs={12}>
                <Typography variant="subtitle2" className={classes.spacedSectionTitle}>
                  Site Descriptors
                  <Tooltip title={SITE_DESCRIPTORS_TOOLTIP} placement="top-start">
                    <InfoOutlined className={classes.tooltipIcon} />
                  </Tooltip>
                </Typography>
                <Typography inline variant="body1">
                  Site Area,{' '}
                </Typography>
                <Typography inline variant="body2" data-qa="site-descriptor-site-area">
                  {siteArea}
                </Typography>
                <br />
                <Typography inline variant="body1">
                  Shape,{' '}
                </Typography>
                <Typography inline variant="body2" data-qa="site-descriptor-property-shape">
                  {propertyShape}
                </Typography>
                <br />
                <Typography inline variant="body1">
                  Frontage,{' '}
                </Typography>
                <Typography inline variant="body2" data-qa="site-descriptor-frontage">
                  {frontageDescription}
                </Typography>
                <Table
                  name="siteDescriptors"
                  form={form}
                  getDefaults={this.getDefaultSiteDescriptors}
                  classes={{ tableContainer: classes.table }}
                />
              </Grid>
              <Grid item xs={12}>
                <Typography variant="subtitle2" className={classes.spacedSectionTitle}>
                  Flood Hazard Status
                </Typography>
                <NarrativeComponentWithAlert
                  name="floodHazardDiscussion"
                  title="Flood Hazard Status Discussion"
                  generatedText={floodHazardDiscussion.generate}
                  data={floodHazardDiscussion.mapDTO(form.values)}
                  tooltipText="This generated text will appear in the Site Description of your report."
                  {...floodWriteupProps}
                />
              </Grid>
              <Grid item xs={12}>
                <Typography variant="subtitle2" className={classes.sectionTitle}>
                  Utilities
                </Typography>
                <Table
                  name="utilities"
                  form={form}
                  getDefaults={this.getDefaultUtilities}
                  classes={{ tableContainer: classes.table }}
                />
              </Grid>
              <Grid item xs={12}>
                <Typography variant="subtitle2" className={classes.spacedSectionTitle}>
                  Conclusion
                </Typography>
                <NarrativeComponent
                  title="Site Description Conclusion"
                  generatedText={siteDescriptionConclusion.generate}
                  name="conclusionDiscussion"
                  tooltipText="This generated text and appraiser commentary will appear in the Site Description of your report."
                />
              </Grid>
            </Grid>
          </Paper>
          <Paper className={classes.paper} data-qa="description-of-improvements">
            <NarrativeComponent
              title="Description of Improvements Summary Discussion"
              data={descriptionOfImprovementsSummary.mapDTO(form.values)}
              generatedText={descriptionOfImprovementsSummary.generate}
              name="descriptionOfImprovementsSummaryDiscussion"
              tooltipText="The following text will appear in the Summary section in the Description of Improvements in your export"
            />
          </Paper>
        </Grid>
      </Grid>
    )
  }
}

export default compose(
  wrapForm(DATA_PATH, { styles, heading, mutators: { ...arrayMutators } }, state => {
    const formPath = DATA_PATH.join('.')
    const { siteDescriptors, utilities } = get(state, `report.reportData.${formPath}`)
    const borough = get(state, 'report.reportData.borough')
    const { siteArea, siteAreaMeasure } = get(state, 'report.reportData.propertyInformation.propertySummary')
    const streetName = get(state, 'report.reportData.propertyInformation.propertySummary.streetName', '')
    const propertyState = get(state, 'report.reportData.propertyInformation.propertySummary.state', '')
    const coords = get(state, 'report.reportData.propertyInformation.coords', {})
    const { marketNeighborhood, submarket } = get(state, 'report.reportData.propertyInformation.propertyMarket')
    const condition = get(state, 'report.reportData.propertyInformation.generalPropertyDescription.generalCondition')
    const yearBuilt = get(state, 'report.reportData.propertyInformation.propertySummary.yearBuilt')

    const {
      propertyFrontage,
      streetOneName,
      streetOneFrontage,
      streetTwoName,
      streetTwoFrontage,
      streetThreeName,
      streetThreeFrontage,
      corner,
      isRectangular,
    } = get(state, 'report.reportData.propertyInformation.propertyMaps', {})

    const isOnCornerLot = isCornerLot(corner)
    const propertyShape = isRectangular ? 'Rectangular' : 'Irregular'
    const initializeSiteDescriptors = !siteDescriptors.length
    const initializeUtilities = !utilities.length

    let siteAreaText
    if (siteAreaMeasure === SiteAreaTypes.SF.toLowerCase()) {
      siteAreaText = `${formatInt(siteArea)} square feet`
    } else if (siteArea === 1) {
      siteAreaText = `${siteArea} acre`
    } else {
      siteAreaText = `${siteArea} acres`
    }

    let frontageDescription
    if (isOnCornerLot) {
      frontageDescription = [
        [streetOneName, streetOneFrontage],
        [streetTwoName, streetTwoFrontage],
        [streetThreeName, streetThreeFrontage],
      ]
        .filter(([streetName, frontage]) => streetName || frontage)
        .map(([streetName, frontage]) => `${streetName}: ${frontage} ft`)
        .join('; ')
    } else {
      frontageDescription = `${streetName}: ${propertyFrontage} ft`
    }

    return {
      primaryAccessStreetName: streetName,
      siteArea: siteAreaText,
      borough,
      propertyShape,
      frontageDescription,
      initializeSiteDescriptors,
      initializeUtilities,
      coords,
      neighborhood: marketNeighborhood,
      submarket,
      condition,
      yearBuilt,
      propertyState,
    }
  }),
  withPulledFloodData
)(SiteDescription)
