import { get, lowerCase } from 'lodash'

import { ADJUSTMENT_GROUPS } from 'shared/helpers/salesApproach/salesCompAdjustmentConstants'
import { mapDataModelForAdjustmentsNarrative } from 'shared/utils/textGeneration/salesApproach'

const mapData = ({ compAdjustments, selectedComps, interestAppraised }) => {
  const adjustmentData = mapDataModelForAdjustmentsNarrative(
    { compAdjustments, selectedComps },
    'propertyRights',
    ADJUSTMENT_GROUPS.MARKET
  )
  return {
    ...adjustmentData,
    interestAppraised: lowerCase(interestAppraised),
  }
}

export const mapDTO = formValues => {
  return mapData(formValues)
}

export const mapDataModel = (report, parent) => {
  if (!parent.parent()) {
    return undefined
  }

  const salesApproach = parent.parent()
  const { salesComps = {}, salesCompsAdjustments = {} } = salesApproach
  const { selectedComps = [] } = salesComps
  const { compAdjustments = [] } = salesCompsAdjustments
  const interestAppraised = get(report, 'info.interestAppraised')
  return mapData({ selectedComps, compAdjustments, interestAppraised })
}
