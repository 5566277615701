import { withStyles } from '@material-ui/core'

import { DatePicker } from '../DatePicker'

export default withStyles({
  formControl: {
    height: 32,
    width: 'auto',
    margin: 0,
    display: 'inline-flex',
    justifyContent: 'center',
  },
  root: {
    fontSize: '14px',
    height: '100%',
    paddingLeft: 0,
    '& fieldset': {
      display: 'none',
    },
  },
  inlineDatePicker: {
    '& div': {
      color: 'inherit',
      fontSize: 'inherit',
      lineHeight: 'inherit',
      '&:before': {
        borderBottom: 'none',
      },
      '&:after': {
        borderBottom: 'none',
      },
      '&:hover:not(.disabled):not(.focused):not(.error)': {
        '&:before': {
          borderBottom: 'none',
        },
        '&:after': {
          borderBottom: 'none',
        },
      },
    },
  },
})(DatePicker)
