import { createAction } from 'redux-actions'
import { defineAction } from 'redux-define'

import { DEFAULT_API_ACTION_STATES } from '../../../../../../shared/constants'

/*
 * Action types
 */
const RENT_COMPS_FETCH = defineAction('RENT_COMPS_FETCH', DEFAULT_API_ACTION_STATES)

const IMPORT_RENT_COMPS = defineAction('IMPORT_RENT_COMPS', DEFAULT_API_ACTION_STATES)
/*
 * Actions
 */

export const fetchRentComps = createAction(RENT_COMPS_FETCH.ACTION)
export const fetchRentCompsRequest = createAction(RENT_COMPS_FETCH.REQUEST)
export const fetchRentCompsReceive = createAction(RENT_COMPS_FETCH.RECEIVE)
export const fetchRentCompsFail = createAction(RENT_COMPS_FETCH.FAIL)

export const importRentComps = createAction(IMPORT_RENT_COMPS.ACTION)
export const importRentCompsRequest = createAction(IMPORT_RENT_COMPS.REQUEST)
export const importRentCompsReceive = createAction(IMPORT_RENT_COMPS.RECEIVE)
export const importRentCompsFail = createAction(IMPORT_RENT_COMPS.FAIL)
