import { GeneratedText, gtIfElse, gtLocked, gtTemplate } from 'shared/utils/narrative/GeneratedText'
import {
  getNoOccupancyCommentary,
  getPartialOccupancyCommentary,
} from 'shared/utils/textGeneration/incomeApproach/residential/occupancyRateDiscussion'

import { mapDTO, mapDataModel } from './mappings'

const generate = () => {
  const submarketRateGt = gtLocked('submarketRate', 'formatPercentageString', 'CoStar Submarket Rate')
  const metroRateGt = gtLocked('metroRate', 'formatPercentageString', 'CoStar Metro Rate')
  const addressGt = gtLocked('address')
  const vcLossGt = gtLocked('residentialVCLossPercentage', null, 'Residential Vacancy and Collection Loss')

  const vacancyLossSentence = gtTemplate`CoStar reports the submarket rate near ${submarketRateGt}
  and metro area rate near ${metroRateGt}. Based on ${addressGt}'s current and historical operating results,
  macro market conditions, and investor expectations, a ${vcLossGt} residential vacancy and collection loss
  has been applied.`

  const noOccupancyCommentary = getNoOccupancyCommentary()
  const partialOccupancyCommentary = getPartialOccupancyCommentary()
  const occupancySentence = gtIfElse('occupancyRateIs0', noOccupancyCommentary, partialOccupancyCommentary)

  const gt = new GeneratedText()
  gt.t`${vacancyLossSentence} ${occupancySentence}`

  return gt.getParts()
}

export default { generate, mapDTO, mapDataModel }
