import React from 'react'
import PropTypes from 'prop-types'

import { connect } from 'react-redux'
import { get, isNil } from 'lodash'
import { Form, FormSpy } from 'react-final-form'
import { Grid, Button, withStyles, Typography } from '@material-ui/core'
import { Paper } from '@mui/material'
import { Redirect } from 'react-router'
import { GoogleButton } from 'react-google-button'

import Logo from '../shared/components/Logo'
import Text from '../shared/components/Text'

import { required, email } from '../shared/utils/validation'

import { BASE64URL_REGEX, JWT_STORAGE } from '../shared/constants'

import Loading from '../shared/components/Loading'

import { loginPageLittleMan, loginPageFlowerPot } from './images'

import { loginUser, authClearError } from './redux/actions'

const styles = theme => ({
  root: {
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    height: '100vh',
    background: 'linear-gradient(#fafafa 65%, rgba(2, 48, 82, 0.06) 35%)',
  },
  logoContainer: {
    textAlign: 'center',
  },
  logo: {
    height: 58,
    marginBottom: 16,
    float: 'none',
  },
  loginParent: {},
  loginContainer: {
    display: 'flex',
    color: 'white',
    width: 800,
    margin: `auto`,
    height: 200,
    justifyContent: 'center',
  },

  formTitleContainer: {
    display: 'flex',
    justifyContent: 'center',
    paddingTop: 48,
    marginBottom: 24,
  },

  errorMessageContainer: {
    height: 42,
  },
  errorMessage: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: 285,
    textAlign: 'center',
    height: 41,
    borderRadius: 4,
    backgroundColor: 'rgba(241, 208, 208, 0.5)',
  },

  usernameField: {
    marginBottom: 2,
  },
  passwordField: {
    marginBottom: 2,
  },

  buttonsSection: {
    display: 'flex',
    justifyContent: 'center',
    marginBottom: 16,
  },

  decorationsContainer: {
    width: 642,
    height: 480,
    margin: 'auto',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'flex-end',
    marginTop: -240,
  },

  littleman: {
    margin: '-166px -16px',
  },
  flowerPot: {
    margin: '-120px -42px',
  },
})

class LoginForm extends React.PureComponent {
  static propTypes = {
    login: PropTypes.func.isRequired,
    isAuthenticated: PropTypes.bool,
    authenticationError: PropTypes.string,
    hasToken: PropTypes.bool,
  }

  state = {
    isLoading: false,
  }

  static defaultProps = {
    isAuthenticated: false,
    authenticationError: '',
    hasToken: false,
  }

  componentDidMount = () => {
    const { hasToken } = this.props
    if (hasToken) {
      this.setState({ isLoading: true })
    }
  }

  onFormChange = () => {
    if (!isNil(this.props.authenticationError)) {
      this.props.authClearError()
    }
  }

  onSubmit = values => {
    this.props.login(values)
  }

  render() {
    const { classes, isAuthenticated, authenticationError, hasToken } = this.props
    const { isLoading } = this.state

    return (
      <Form
        onSubmit={this.onSubmit}
        render={props => {
          const { handleSubmit, submitting, invalid, pristine } = props
          return (
            <form onSubmit={handleSubmit}>
              <div className={classes.root}>
                <div className={classes.logoContainer}>
                  <Logo classes={{ logo: classes.logo }} />
                </div>
                <div className={classes.loginParent}>
                  <div className={classes.loginContainer}>
                    <Paper
                      sx={{
                        width: 428,
                        height: 458,
                        padding: 0,
                      }}
                    >
                      {isLoading && <Loading />}
                      {!hasToken && (
                        <Grid container justify="center" alignItems="center" spacing={0}>
                          <Grid item xs={8}>
                            <div className={classes.formTitleContainer}>
                              <Typography variant="h5">Sign In</Typography>
                            </div>
                          </Grid>
                          <Grid item xs={8}>
                            <div className={classes.buttonsSection}>
                              <GoogleButton
                                onClick={() => {
                                  window.location.href = window.location.origin + '/auth/google'
                                }}
                              />
                            </div>
                          </Grid>
                          <Grid item xs={8}>
                            <Typography variant="body2" align="center">
                              OR
                            </Typography>
                          </Grid>
                          {authenticationError && (
                            <Grid item xs={8}>
                              <div className={classes.errorMessageContainer}>
                                <div className={classes.errorMessage}>
                                  <Typography variant="caption">{authenticationError}</Typography>
                                </div>
                              </div>
                            </Grid>
                          )}
                          <Grid item xs={8} className={classes.usernameField}>
                            <Text name="username" label="Email" validate={email} displayError={true} />
                          </Grid>
                          <Grid item xs={8} className={classes.passwordField}>
                            <Text
                              type="password"
                              name="password"
                              label="Password"
                              displayError={false}
                              validate={required}
                            />
                          </Grid>
                          <Grid item xs={8}>
                            <div className={classes.buttonsSection}>
                              <Button
                                type="submit"
                                disabled={submitting || invalid || pristine}
                                variant="contained"
                                color="primary"
                              >
                                Sign In
                              </Button>
                            </div>
                            <Typography variant="body2" align="center">
                              New to Bowery? Contact your Admin.
                            </Typography>
                          </Grid>
                        </Grid>
                      )}
                      {isAuthenticated && <Redirect push to="/reports" />}
                    </Paper>
                  </div>
                  <div className={classes.decorationsContainer}>
                    <div className={classes.littleman}>
                      <img src={loginPageLittleMan} alt="littleman" />
                    </div>
                    <div className={classes.flowerPot}>
                      <img src={loginPageFlowerPot} alt="flowerPot" />
                    </div>
                  </div>
                </div>
              </div>
              <FormSpy onChange={this.onFormChange} subscription={{ values: true }} />
            </form>
          )
        }}
      />
    )
  }
}

function mapStateToProps(state, ownProps) {
  let hasToken = false
  const query = new URLSearchParams(window.location.search)
  const token = query.get('token')
  if (token && BASE64URL_REGEX.test(token)) {
    localStorage.setItem(JWT_STORAGE, token)
    hasToken = true
  }

  const isAuthenticated = get(state, 'authentication.isAuthenticated', false)
  let authenticationError = get(state, 'authentication.authenticationError', '')

  if (!authenticationError) {
    authenticationError = query.get('errorMessage') || ''
  }

  if (!hasToken && (!isAuthenticated || authenticationError)) {
    localStorage.removeItem(JWT_STORAGE)
  }

  return {
    isAuthenticated,
    authenticationError,
    hasToken,
  }
}

export default connect(mapStateToProps, {
  login: loginUser,
  authClearError,
})(withStyles(styles)(LoginForm))
