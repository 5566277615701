import { connect } from 'react-redux'
import { kebabCase, get, isObject, startCase, isNil } from 'lodash'

import { getReportForms } from '../../../report/constants/forms/reportFormsInstance'
import { getFormName } from '../../../report/layout/helpers'

import JumpAheadAutosuggest from './JumpAheadAutosuggest'

const normalizeFormRoute = path => {
  const form = getReportForms().find(form => form.key === path)
  const routePath = form.link || kebabCase(path)
  const routeAnchor = form.anchor || ''
  return routePath + routeAnchor
}

const mapStateToProps = (state, { formsStructure, ...ownProps }) => {
  const reportStructure = get(state, formsStructure)
  const reportSettings = get(state, 'report.reportSettings')
  const forms = []

  const getForms = (reportStructure, formPath, section) => {
    for (const path in reportStructure) {
      const formSection = formPath ? section : path

      if ('descendants' in reportStructure) {
        return getForms(reportStructure.descendants, formPath, formSection)
      }

      if (isObject(reportStructure[path])) {
        getForms(reportStructure[path], `${formPath}/${startCase(path)}`, formSection)

        if (!isNil(formPath) && !('descendants' in reportStructure[path])) {
          if (reportStructure[path].showForm) {
            const formInfo = getReportForms().find(reportForm => reportForm.key === path)
            const name = getFormName(formInfo, reportSettings)

            forms.push({ name, path: formPath, route: normalizeFormRoute(path), section: formSection })
          }
        }
      }
    }
  }

  getForms(reportStructure, '')

  return {
    forms,
    ...ownProps,
  }
}

export default connect(mapStateToProps)(JumpAheadAutosuggest)
