import React from 'react'
import { get, isNil } from 'lodash'
import arrayMutators from 'final-form-arrays'
import { FieldArray } from 'react-final-form-arrays'

import { APPRAISERS_PATH } from 'shared/constants/report/keysAndDataPaths'

import wrapForm from '../../wrapForm'

import { TEMPLATE_TYPES } from '../../../constants/settings'

import { FORM_HEADING } from './constants'
import AppraisersTableContainer from './AppraisersTableContainer'

const DATA_PATH = APPRAISERS_PATH

const AppraisersContainer = ({ ...props }) => {
  return <FieldArray name="selectedAppraisers" {...props} component={AppraisersTableContainer} />
}

const mapStateToProps = state => {
  const appraisers = get(state, `report.reportData.report.appraisers.allAppraisers`)
  const selectedAppraisers = get(state, `report.reportData.report.appraisers.selectedAppraisers`, [])
  const externalInspectors = get(state, `report.reportData.report.appraisers.externalInspectors`, [])
  const inspectionDate = get(state, 'report.reportData.report.reportInformation.inspectionDate')

  const reportTemplate = get(state, `report.reportSettings.templateType`) || null
  const isFreddieMac = !isNil(reportTemplate) && reportTemplate === TEMPLATE_TYPES.FREDDIE_MAC

  const certificationAssistance = get(state, 'report.reportData.report.appraisers.certificationAssistance')
  const certificationInspection = get(state, 'report.reportData.report.appraisers.certificationInspection')

  const reportState = get(state, 'report.reportData.state') || ''
  return {
    appraisers: appraisers,
    isFreddieMac,
    certificationAssistance,
    certificationInspection,
    reportState,
    initialValues: {
      selectedAppraisers,
      externalInspectors,
      certificationAssistance,
      certificationInspection,
      inspectionDate,
      reportState,
    },
  }
}

export default wrapForm(
  DATA_PATH,
  {
    heading: FORM_HEADING,
    mutators: {
      ...arrayMutators,
    },
    registeredFields: ['externalInspectors'],
  },
  mapStateToProps
)(AppraisersContainer)
