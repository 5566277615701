import { createAction } from 'redux-actions'
import { defineAction } from 'redux-define'

import { DEFAULT_API_ACTION_STATES } from '../../../../../shared/constants'

const EXPENSE_ADDRESSES_FETCH = defineAction('EXPENSE_ADDRESSES_FETCH', DEFAULT_API_ACTION_STATES)

export const expenseAddressesFetch = createAction(EXPENSE_ADDRESSES_FETCH.ACTION)
export const expenseAddressesFetchRequest = createAction(EXPENSE_ADDRESSES_FETCH.REQUEST)
export const expenseAddressesFetchReceive = createAction(EXPENSE_ADDRESSES_FETCH.RECEIVE)
export const expenseAddressesFetchFail = createAction(EXPENSE_ADDRESSES_FETCH.FAIL)
