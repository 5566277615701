import React from 'react'
import { get, isNil } from 'lodash'

import { Typography } from '@mui/material'

import { TEMPLATE_TYPES } from 'shared/constants/settings'

import PreviewAndEdit from '../PreviewAndEdit'
import wrapForm from '../../wrapForm'

import LetterOfTransmittalEdit from './Edit'
import LetterOfTransmittalPreview from './Preview'
import { FORM_HEADING, DATA_PATH } from './constants'
import { generateFreddieMacLanguage } from './writeupHelpers'

class LetterOfTransmittal extends React.PureComponent {
  render() {
    const { form, title, section, clientLink, reportState, isFreddieMac } = this.props
    const { values, change } = form

    const freddieMacLanguage = generateFreddieMacLanguage(values)

    const getClient = (client, hideClientName) => {
      return (
        <>
          {!hideClientName && (
            <Typography variant="body1" gutterBottom>{`${client.firstName} ${client.lastName}${
              client.suffix ? `, ${client.suffix}` : ''
            }`}</Typography>
          )}
          <Typography variant="body1" gutterBottom>
            {client.title}
          </Typography>
          <Typography variant="body1" gutterBottom>
            {client.client}
          </Typography>
          <Typography variant="body1" gutterBottom>
            {client.address}
          </Typography>
          <Typography variant="body1" gutterBottom>{`${client.city}, ${client.abbr} ${client.zip}`}</Typography>
        </>
      )
    }

    return (
      <PreviewAndEdit title={title} section={section}>
        {isEdit =>
          isEdit ? (
            <LetterOfTransmittalEdit
              change={change}
              {...values}
              freddieMacLanguage={freddieMacLanguage}
              clientLink={clientLink}
              reportState={reportState}
              isFreddieMac={isFreddieMac}
              getClient={getClient}
            />
          ) : (
            <LetterOfTransmittalPreview
              change={change}
              reportState={reportState}
              isFreddieMac={isFreddieMac}
              freddieMacLanguage={freddieMacLanguage}
              {...values}
              getClient={getClient}
            />
          )
        }
      </PreviewAndEdit>
    )
  }
}
const mapStateToProps = (state, ownProps) => {
  const reportState = get(state, 'report.reportData.state') || ''

  const reportTemplate = get(state, `report.reportSettings.templateType`) || null
  const isFreddieMac = !isNil(reportTemplate) && reportTemplate === TEMPLATE_TYPES.FREDDIE_MAC

  return {
    clientLink: `client`,
    reportState,
    isFreddieMac,
  }
}

export default wrapForm(
  DATA_PATH,
  {
    heading: FORM_HEADING,
    registeredFields: ['reportDate', 'salutation'],
  },
  mapStateToProps
)(LetterOfTransmittal)
