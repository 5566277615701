import React from 'react'
import PropTypes from 'prop-types'
import tinycolor from 'tinycolor2'
import { ListItem, List } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'

import Collapse from '@material-ui/core/Collapse'

import NavigationSideBarItemText from './NavigationSideBarItemText'
import { NAV_ITEM_HEIGHT } from './constants'

const styles = theme => ({
  root: {
    padding: 0,
    paddingLeft: theme.spacing.unit * 2,
    paddingRight: theme.spacing.unit,
    height: NAV_ITEM_HEIGHT,
    borderBottom: '1px solid rgba(0, 0, 0, 0.05)',
  },
})

class NavigationSideBarCollapsedItem extends React.PureComponent {
  static propTypes = {
    title: PropTypes.string.isRequired,
    iconClass: PropTypes.string,
    children: PropTypes.node,
    currentPath: PropTypes.string.isRequired,
    isOpened: PropTypes.bool,
  }

  static defaultProps = {
    iconClass: '',
    children: null,
    isOpened: false,
  }

  componentWillReceiveProps(newProps) {
    const { isOpened } = newProps
    if (isOpened) {
      const { open } = this.state
      if (isOpened !== open) {
        this.setState({ open: isOpened })
      }
    }
  }

  state = { open: this.props.isOpened }

  handleClick = () => {
    this.setState({ open: !this.state.open })
  }

  getStyle() {
    const { color, isNested } = this.props
    const borderColor = isNested ? tinycolor(color).setAlpha(0.5) : color
    const backgroundColor = isNested ? '#f8f8f8' : 'inherit'
    return {
      borderLeft: `5px solid ${borderColor}`,
      backgroundColor,
    }
  }

  render() {
    const { classes, title, children, isNested, color } = this.props
    const textColor = this.state.open && isNested ? color : 'inherit'

    return (
      <div>
        <ListItem button className={classes.root} style={this.getStyle()} onClick={this.handleClick}>
          <NavigationSideBarItemText text={title} isHeading={true} color={textColor} />
        </ListItem>
        <Collapse component="li" in={this.state.open} timeout="auto" unmountOnExit>
          <List dense disablePadding>
            {children}
          </List>
        </Collapse>
      </div>
    )
  }
}

export default withStyles(styles)(NavigationSideBarCollapsedItem)
