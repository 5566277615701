import React from 'react'

import { Grid } from '@mui/material'

import 'moment-timezone'

import NarrativeComponent from '../../shared/components/NarrativeComponent'

import { getHighestAndBestUseIntroduction } from '../constants/text/highestAndBestUse'

import withCMSForm from './withCMSForm'

const GRID_CONTAINER_GUTTER = 2

const HighestAndBestUse = () => {
  return (
    <Grid container spacing={GRID_CONTAINER_GUTTER}>
      <Grid item xs={12}>
        <NarrativeComponent
          title="Introduction"
          generatedText={getHighestAndBestUseIntroduction}
          name="highestAndBestUse.introduction"
        />
      </Grid>
    </Grid>
  )
}

export default withCMSForm(HighestAndBestUse, 'Highest and Best Use')
