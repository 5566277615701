import { GeneratedText } from '../../../narrative/GeneratedText'

const generate = () => {
  const gt = new GeneratedText()
  gt.t`The most probable price which a property should bring in a competitive `
  gt.t`and open market under all conditions requisite `
  gt.t`to a fair sale, the buyer and seller each acting prudently, `
  gt.t`knowledgeably and assuming the price is not affected by `
  gt.t`undue stimulus. Implicit in this definition is the consummation `
  gt.t`of a sale as of a specified date and the passing of title `
  gt.t`from seller to buyer under conditions whereby:`
  return gt.getParts()
}

export default { generate }
